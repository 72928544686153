import { Divider } from "@components/shared"
import { DialogForm, DialogFormProps } from "@components/shared/dialog-form"
import { FormField } from "@components/shared/form/form-field"
import {
  getPermissionScopeDescription,
  getPermissionScopeName,
} from "@components/shared/permission-scope"
import { TextInput } from "@components/shared/text-input"
import Toggle from "@components/shared/toggle"
import { ServerFeatureFlag, useComputedFeatureFlags } from "@contexts/feature-flag-context"
import { IPermissionScopeEnum } from "@elara/db"
import i18n from "@i18n"
import { cn } from "@utils"
import { Field, FieldProps } from "formik"

type FeatureLabelProps = {
  children: React.ReactNode
  className?: string
}

const FeatureLabel = (props: FeatureLabelProps) => (
  <h6
    className={cn(
      "absolute -top-2 left-3 rounded-full bg-blue-600 px-3 py-0.5 text-[11px] font-bold leading-none text-white",
      props.className
    )}>
    {props.children}
  </h6>
)

const ScopeOption = (props: {
  scope: IPermissionScopeEnum
  scopes: IPermissionScopeEnum[]
  title: string
  description: string
  field: string
  disabled?: boolean
}) => (
  <label className="flex items-center pl-2">
    <Field name={`options.${props.scope}.${props.field}`} type="checkbox">
      {({ field }: FieldProps) => (
        <Toggle
          {...field}
          withLabel={false}
          disabled={!props.scopes.includes(props.scope) || props.disabled}
        />
      )}
    </Field>

    <div className="prose prose-sm prose-zinc ml-4">
      <h6 className="font-medium">{props.title}</h6>
      <p className="my-0 text-xs">{props.description}</p>
    </div>
  </label>
)

const AssigendOnlyOption = (props: {
  scope: IPermissionScopeEnum
  scopes: IPermissionScopeEnum[]
}) => (
  <ScopeOption
    scope={props.scope}
    scopes={props.scopes}
    field="assigned_only"
    title={i18n.t("settings:permissions.options.others.title")}
    description={i18n.t("settings:permissions.options.others.description")}
  />
)

type ScopeBoxProps = {
  scope: IPermissionScopeEnum
  hideToggle?: boolean
}

const ScopeBox = (props: ScopeBoxProps) => (
  <label className="flex items-center justify-between">
    <div>
      <div className="prose prose-sm prose-zinc">
        <h6 className="font-medium">{getPermissionScopeName(props.scope)}</h6>
        <p className="my-0 text-xs leading-normal">
          {getPermissionScopeDescription(props.scope)}
        </p>
      </div>
    </div>

    {!props.hideToggle && (
      <Field name="scopes" type="checkbox" value={props.scope}>
        {({ field }: FieldProps) => <Toggle withLabel={false} {...field} />}
      </Field>
    )}
  </label>
)

export type PermissionFormValues = {
  roleName: string
  scopes: IPermissionScopeEnum[]
  features: Partial<Record<ServerFeatureFlag, boolean>>
  options: Partial<Record<IPermissionScopeEnum, Record<string, unknown>>>
}

export type PermissionRoleFormProps = {
  initialValues?: Partial<PermissionFormValues>
  onSubmit: (values: PermissionFormValues) => Promise<void> | void
}

const PermissionRoleForm = (
  props: Omit<DialogFormProps<PermissionFormValues>, "children"> & {
    computedFeatureFlags: ReturnType<typeof useComputedFeatureFlags>
  }
) => (
  <DialogForm
    {...props}
    className="sm:max-w-2xl"
    formikConfig={{
      ...props.formikConfig,
      validate: (values) => {
        if (!values.roleName) {
          return {
            roleName: i18n.t("common:forms.is_required", {
              field: i18n.t("settings:profile.fields.designation"),
            }),
          }
        }

        return {}
      },
    }}>
    {(formik) => {
      return (
        <div className="mb-4 space-y-6">
          <FormField
            name="roleName"
            label={i18n.t("settings:profile.fields.designation")}
            hasErrorPlaceholder={!!formik.errors.roleName}>
            <TextInput size="small" />
          </FormField>

          {/* Basic */}
          <div className="relative grid gap-y-2 rounded border border-dashed p-3">
            <FeatureLabel>{i18n.t("settings:feature_flags.basic.title")}</FeatureLabel>

            <ScopeBox scope={IPermissionScopeEnum.AppUser} hideToggle />
            <ScopeOption
              field="assigned_only"
              scopes={formik.values.scopes}
              scope={IPermissionScopeEnum.AppUser}
              title={i18n.t("settings:permissions.options.app_user.title")}
              description={i18n.t("settings:permissions.options.app_user.description")}
            />
          </div>

          {/* <Divider /> */}

          {/* Tasks */}
          <div className="relative grid gap-y-2 rounded border border-dashed p-3">
            <FeatureLabel>{i18n.t("settings:feature_flags.tasks.title")}</FeatureLabel>

            <label className="flex items-center justify-between">
              <div>
                <div className="prose prose-sm prose-zinc">
                  <h6 className="font-medium">
                    {i18n.t("settings:feature_flags.tasks.permission")}
                  </h6>
                  <p className="my-0 text-xs leading-normal">
                    {i18n.t("settings:feature_flags.tasks.description")}
                  </p>
                </div>
              </div>

              <FormField name="features.tasks">
                <Toggle withLabel={false} />
              </FormField>
            </label>

            <Divider />

            <ScopeBox scope={IPermissionScopeEnum.AppWorkOrderCreate} />
            <ScopeOption
              scopes={formik.values.scopes}
              scope={IPermissionScopeEnum.AppWorkOrderCreate}
              field="owned_only"
              title={i18n.t(
                "settings:permissions.options.app_work_order_create.owned_only.title"
              )}
              description={i18n.t(
                "settings:permissions.options.app_work_order_create.owned_only.description"
              )}
              disabled={!!formik.values.options.app_work_order_create?.template_only}
            />

            <ScopeOption
              scopes={formik.values.scopes}
              scope={IPermissionScopeEnum.AppWorkOrderCreate}
              field="template_only"
              title={i18n.t(
                "settings:permissions.options.app_work_order_create.template_only.title"
              )}
              description={i18n.t(
                "settings:permissions.options.app_work_order_create.template_only.description"
              )}
            />
            <Divider />
            <ScopeBox scope={IPermissionScopeEnum.AppWorkOrderEdit} />
            <Divider />
            <ScopeBox scope={IPermissionScopeEnum.AppWorkOrderApproval} />
            <AssigendOnlyOption
              scopes={formik.values.scopes}
              scope={IPermissionScopeEnum.AppWorkOrderApproval}
            />
            <Divider />
            <ScopeBox scope={IPermissionScopeEnum.AppWorkOrderDelete} />
            <Divider />
            <ScopeBox scope={IPermissionScopeEnum.AppDataEntry} />
            <AssigendOnlyOption
              scopes={formik.values.scopes}
              scope={IPermissionScopeEnum.AppDataEntry}
            />
          </div>

          {/* Preventive Maintenance */}
          <div className="relative grid gap-y-2 rounded border border-dashed p-3">
            <FeatureLabel>
              {i18n.t("settings:feature_flags.maintenance.title")}
            </FeatureLabel>

            <label className="flex items-center justify-between">
              <div>
                <div className="prose prose-sm prose-zinc">
                  <h6 className="font-medium">
                    {i18n.t("settings:feature_flags.maintenance.permission")}
                  </h6>
                  <p className="my-0 text-xs leading-normal">
                    {i18n.t("settings:feature_flags.maintenance.description")}
                  </p>
                </div>
              </div>

              <FormField name="features.maintenance">
                <Toggle withLabel={false} />
              </FormField>
            </label>
          </div>

          {/* Service Requests */}
          <div className="relative grid gap-y-2 rounded border border-dashed p-3">
            <FeatureLabel>
              {i18n.t("settings:feature_flags.service_requests.title")}
            </FeatureLabel>

            <label className="flex items-center justify-between">
              <div>
                <div className="prose prose-sm prose-zinc">
                  <h6 className="font-medium">
                    {i18n.t("settings:feature_flags.service_requests.permission")}
                  </h6>
                  <p className="my-0 text-xs leading-normal">
                    {i18n.t("settings:feature_flags.service_requests.description")}
                  </p>
                </div>
              </div>

              <FormField name="features.service_requests">
                <Toggle withLabel={false} />
              </FormField>
            </label>

            <Divider />

            <ScopeBox scope={IPermissionScopeEnum.AppServiceRequestCreate} />
            <ScopeBox scope={IPermissionScopeEnum.AppServiceRequestManage} />
          </div>

          {/* Analytics */}
          <div className="relative grid gap-y-2 rounded border border-dashed p-3">
            <FeatureLabel>
              {i18n.t("settings:feature_flags.advanced_analytics.title")}
            </FeatureLabel>

            <label className="flex items-center justify-between">
              <div>
                <div className="prose prose-sm prose-zinc">
                  <h6 className="font-medium">
                    {i18n.t("settings:feature_flags.advanced_analytics.permission")}
                  </h6>
                  <p className="my-0 text-xs leading-normal">
                    {i18n.t("settings:feature_flags.advanced_analytics.description")}
                  </p>
                </div>
              </div>

              <FormField name="features.advanced_analytics">
                <Toggle withLabel={false} />
              </FormField>
            </label>
          </div>

          {/* Objects */}
          <div className="relative grid gap-y-2 rounded border border-dashed p-3">
            <FeatureLabel>{i18n.t("settings:feature_flags.objects.title")}</FeatureLabel>

            <label className="flex items-center justify-between">
              <div>
                <div className="prose prose-sm prose-zinc">
                  <h6 className="font-medium">
                    {i18n.t("settings:feature_flags.objects.permission")}
                  </h6>
                  <p className="my-0 text-xs leading-normal">
                    {i18n.t("settings:feature_flags.objects.description")}
                  </p>
                </div>
              </div>

              <FormField name="features.objects">
                <Toggle withLabel={false} />
              </FormField>
            </label>
            <Divider />

            <ScopeBox scope={IPermissionScopeEnum.AppAssetCreate} />
            <Divider />
            <ScopeBox scope={IPermissionScopeEnum.AppAssetEdit} />
            <Divider />
            <ScopeBox scope={IPermissionScopeEnum.AppAssetArchiveDelete} />
            <Divider />
            <ScopeBox scope={IPermissionScopeEnum.AppManufacturerCreateEditDelete} />
          </div>

          {/* Materials */}
          <div className="relative grid gap-y-2 rounded border border-dashed p-3">
            <FeatureLabel>{i18n.t("settings:feature_flags.materials.title")}</FeatureLabel>

            <label className="flex items-center justify-between">
              <div>
                <div className="prose prose-sm prose-zinc">
                  <h6 className="font-medium">
                    {i18n.t("settings:feature_flags.materials.permission")}
                  </h6>
                  <p className="my-0 text-xs leading-normal">
                    {i18n.t("settings:feature_flags.materials.description")}
                  </p>
                </div>
              </div>

              <FormField name="features.materials">
                <Toggle withLabel={false} />
              </FormField>
            </label>
          </div>

          {/* Meters */}
          <div className="relative grid gap-y-2 rounded border border-dashed p-3">
            <FeatureLabel>{i18n.t("settings:feature_flags.meters.title")}</FeatureLabel>

            <label className="flex items-center justify-between">
              <div>
                <div className="prose prose-sm prose-zinc">
                  <h6 className="font-medium">
                    {i18n.t("settings:feature_flags.meters.permission")}
                  </h6>
                  <p className="my-0 text-xs leading-normal">
                    {i18n.t("settings:feature_flags.meters.description")}
                  </p>
                </div>
              </div>

              <FormField name="features.meters">
                <Toggle withLabel={false} />
              </FormField>
            </label>
          </div>

          {/* Contacts */}
          <div className="relative grid gap-y-2 rounded border border-dashed p-3">
            <FeatureLabel>{i18n.t("settings:feature_flags.contacts.title")}</FeatureLabel>

            <label className="flex items-center justify-between">
              <div>
                <div className="prose prose-sm prose-zinc">
                  <h6 className="font-medium">
                    {i18n.t("settings:feature_flags.contacts.permission")}
                  </h6>
                  <p className="my-0 text-xs leading-normal">
                    {i18n.t("settings:feature_flags.contacts.description")}
                  </p>
                </div>
              </div>

              <FormField name="features.contacts">
                <Toggle withLabel={false} />
              </FormField>
            </label>
          </div>

          {/* Account Management */}
          <div className="relative grid gap-y-2 rounded border border-dashed p-3">
            <FeatureLabel>
              {i18n.t("settings:feature_flags.account_management.title")}
            </FeatureLabel>

            <ScopeBox scope={IPermissionScopeEnum.AppAccountManagement} />
            <Divider />
            <ScopeBox scope={IPermissionScopeEnum.AppFullAccess} />
          </div>
        </div>
      )
    }}
  </DialogForm>
)

export default PermissionRoleForm
