import { useUser } from "@contexts/user-context"
import { useTeamsQuery } from "@graphql/documents/team.generated"
import { useMemo } from "react"

export const useMyTeams = () => {
  const user = useUser()

  const [teamsQueryRes] = useTeamsQuery({ requestPolicy: "cache-only" })

  const myTeams = useMemo(
    () =>
      teamsQueryRes.data?.team
        ?.filter((t) => !t.archived_at && t.members.some((m) => m.user?.id === user.id))
        ?.sort((a, b) => a.name.localeCompare(b.name)) ?? [],
    [teamsQueryRes.data, user.id]
  )

  return myTeams
}
