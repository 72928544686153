// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import { AssetGroupFragmentDoc } from './fragments.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IAssetGroupsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IAssetGroupsQuery = { asset_group: Array<{ __typename: 'asset_group', id: uuid, name: string }> };

export type IAssetGroupsWithAssetCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IAssetGroupsWithAssetCountQuery = { asset_group: Array<{ __typename: 'asset_group', id: uuid, name: string, assets_aggregate: { __typename: 'asset_aggregate', aggregate: { __typename: 'asset_aggregate_fields', count: number } | null } }> };

export type IDeleteAssetGroupMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type IDeleteAssetGroupMutation = { delete_asset_group_by_pk: { __typename: 'asset_group', id: uuid, name: string } | null };

export type IInsertAssetGroupMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
}>;


export type IInsertAssetGroupMutation = { insert_asset_group_one: { __typename: 'asset_group', id: uuid, name: string } | null };

export type IInsertAssetGroupManyMutationVariables = Types.Exact<{
  objects: Array<Types.IAssetGroupInsertInput> | Types.IAssetGroupInsertInput;
}>;


export type IInsertAssetGroupManyMutation = { insert_asset_group: { __typename: 'asset_group_mutation_response', returning: Array<{ __typename: 'asset_group', id: uuid, name: string }> } | null };

export type ICreateAssetGroupMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
}>;


export type ICreateAssetGroupMutation = { insert_asset_group_one: { __typename: 'asset_group', id: uuid, name: string } | null };

export type IAssetGroupEditMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  group?: Types.InputMaybe<Types.IAssetGroupSetInput>;
}>;


export type IAssetGroupEditMutation = { update_asset_group_by_pk: { __typename: 'asset_group', id: uuid, name: string } | null };

export type IAssetGroupDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type IAssetGroupDeleteMutation = { delete_asset_group_by_pk: { __typename: 'asset_group', id: uuid } | null };

export type IUpdateAssetGroupNameMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  name: Types.Scalars['String']['input'];
}>;


export type IUpdateAssetGroupNameMutation = { update_asset_group_by_pk: { __typename: 'asset_group', id: uuid, name: string } | null };


export const AssetGroupsDocument = gql`
    query AssetGroups {
  asset_group {
    ...AssetGroup
  }
}
    ${AssetGroupFragmentDoc}`;

export function useAssetGroupsQuery(options?: Omit<Urql.UseQueryArgs<IAssetGroupsQueryVariables>, 'query'>) {
  return Urql.useQuery<IAssetGroupsQuery, IAssetGroupsQueryVariables>({ query: AssetGroupsDocument, ...options });
};
export const AssetGroupsWithAssetCountDocument = gql`
    query AssetGroupsWithAssetCount {
  asset_group {
    ...AssetGroup
    assets_aggregate(where: {archived_at: {_is_null: true}}) {
      aggregate {
        count
      }
    }
  }
}
    ${AssetGroupFragmentDoc}`;

export function useAssetGroupsWithAssetCountQuery(options?: Omit<Urql.UseQueryArgs<IAssetGroupsWithAssetCountQueryVariables>, 'query'>) {
  return Urql.useQuery<IAssetGroupsWithAssetCountQuery, IAssetGroupsWithAssetCountQueryVariables>({ query: AssetGroupsWithAssetCountDocument, ...options });
};
export const DeleteAssetGroupDocument = gql`
    mutation DeleteAssetGroup($id: uuid!) {
  delete_asset_group_by_pk(id: $id) {
    ...AssetGroup
  }
}
    ${AssetGroupFragmentDoc}`;

export function useDeleteAssetGroupMutation() {
  return Urql.useMutation<IDeleteAssetGroupMutation, IDeleteAssetGroupMutationVariables>(DeleteAssetGroupDocument);
};
export const InsertAssetGroupDocument = gql`
    mutation InsertAssetGroup($name: String!) {
  insert_asset_group_one(object: {name: $name}) {
    ...AssetGroup
  }
}
    ${AssetGroupFragmentDoc}`;

export function useInsertAssetGroupMutation() {
  return Urql.useMutation<IInsertAssetGroupMutation, IInsertAssetGroupMutationVariables>(InsertAssetGroupDocument);
};
export const InsertAssetGroupManyDocument = gql`
    mutation InsertAssetGroupMany($objects: [asset_group_insert_input!]!) {
  insert_asset_group(
    objects: $objects
    on_conflict: {constraint: asset_group_name_location_id_key, update_columns: []}
  ) {
    returning {
      id
      name
    }
  }
}
    `;

export function useInsertAssetGroupManyMutation() {
  return Urql.useMutation<IInsertAssetGroupManyMutation, IInsertAssetGroupManyMutationVariables>(InsertAssetGroupManyDocument);
};
export const CreateAssetGroupDocument = gql`
    mutation CreateAssetGroup($name: String!) {
  insert_asset_group_one(object: {name: $name}) {
    id
    name
  }
}
    `;

export function useCreateAssetGroupMutation() {
  return Urql.useMutation<ICreateAssetGroupMutation, ICreateAssetGroupMutationVariables>(CreateAssetGroupDocument);
};
export const AssetGroupEditDocument = gql`
    mutation AssetGroupEdit($id: uuid!, $group: asset_group_set_input) {
  update_asset_group_by_pk(pk_columns: {id: $id}, _set: $group) {
    id
    name
  }
}
    `;

export function useAssetGroupEditMutation() {
  return Urql.useMutation<IAssetGroupEditMutation, IAssetGroupEditMutationVariables>(AssetGroupEditDocument);
};
export const AssetGroupDeleteDocument = gql`
    mutation AssetGroupDelete($id: uuid!) {
  delete_asset_group_by_pk(id: $id) {
    id
  }
}
    `;

export function useAssetGroupDeleteMutation() {
  return Urql.useMutation<IAssetGroupDeleteMutation, IAssetGroupDeleteMutationVariables>(AssetGroupDeleteDocument);
};
export const UpdateAssetGroupNameDocument = gql`
    mutation UpdateAssetGroupName($id: uuid!, $name: String!) {
  update_asset_group_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {
    ...AssetGroup
  }
}
    ${AssetGroupFragmentDoc}`;

export function useUpdateAssetGroupNameMutation() {
  return Urql.useMutation<IUpdateAssetGroupNameMutation, IUpdateAssetGroupNameMutationVariables>(UpdateAssetGroupNameDocument);
};