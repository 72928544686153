// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ISubmitSetupInfoMutationVariables = Types.Exact<{
  locationId: Types.Scalars['uuid']['input'];
  info: Types.Scalars['json']['input'];
  data?: Types.InputMaybe<Types.Scalars['String']['input']>;
  keepUser?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type ISubmitSetupInfoMutation = { update_location_by_pk: { __typename: 'location', id: uuid, setup_info: {} | null } | null, setupDemoData: { __typename: 'SetupDemoDataOutput', success: boolean } | null };


export const SubmitSetupInfoDocument = gql`
    mutation SubmitSetupInfo($locationId: uuid!, $info: json!, $data: String, $keepUser: Boolean) {
  update_location_by_pk(pk_columns: {id: $locationId}, _set: {setup_info: $info}) {
    id
    setup_info
  }
  setupDemoData(data: $data, keepUser: $keepUser) {
    success
  }
}
    `;

export function useSubmitSetupInfoMutation() {
  return Urql.useMutation<ISubmitSetupInfoMutation, ISubmitSetupInfoMutationVariables>(SubmitSetupInfoDocument);
};