import { useConfirmModal } from "@components/shared"
import toast from "@components/shared/toast"
import { cleanBlockElement } from "@components/work-order/block/block-element-form"
import { BlockGroupFormHandle } from "@components/work-order/block/block-group-form"
import { BlockElementFragment } from "@components/work-order/block/elements/block-element-types"
import {
  getInitialWorkOrderFormValues,
  valuesToWorkOrderInsertInput,
} from "@components/work-order/create-task-form.hooks"
import { useOpenModal } from "@contexts/modal-context"
import { useUser } from "@contexts/user-context"
import { IWorkOrderStatusEnum, IWorkOrderTypeEnum, uuid } from "@elara/db"
import {
  IWorkOrderFragment,
  IWorkOrderTemplateFragment,
} from "@graphql/documents/fragments.generated"
import { useTeamsQuery } from "@graphql/documents/team.generated"
import {
  IUpdateWorkOrderPartiallyMutation,
  IUpdateWorkOrderPartiallyMutationVariables,
  UpdateWorkOrderPartiallyDocument,
  useCreateWorkOrderTemplateMutation,
  useInsertWorkOrderMutation,
  useUpdateWorkOrderPartiallyMutation,
} from "@graphql/documents/work-order.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { add } from "date-fns"
import { useRef } from "react"
import { useClient } from "urql"
import { v4 } from "uuid"

export const useConvertTaskToTemplate = () => {
  const user = useUser()

  const [teamsQueryRes] = useTeamsQuery({ requestPolicy: "cache-first" })
  const allowedTeamIds = teamsQueryRes.data?.team?.map((t) => t.id) ?? []

  const [, createWorkOrder] = useInsertWorkOrderMutation()
  const [, createTemplate] = useCreateWorkOrderTemplateMutation()

  const createScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderCreate)

  return async (workOrder: IWorkOrderFragment) => {
    const initialValues = workOrderToTemplate(workOrder)
    const formValues = getInitialWorkOrderFormValues(
      initialValues,
      allowedTeamIds,
      user.id,
      { cleanBlockElementIds: true }
    )
    const workOrderData = valuesToWorkOrderInsertInput(formValues, {
      includeBlockElements: true,
    })

    const createWorkOrderRes = await createWorkOrder(
      {
        data: {
          ...workOrderData,
          id: v4(),
          type: IWorkOrderTypeEnum.Template,
        },
      },
      createScope.context()
    )

    const createTemplateRes = await createTemplate(
      {
        data: {
          name: `${workOrder.name} (Vorlage)`,
          description: workOrder.description,
          work_order_id: createWorkOrderRes.data?.insert_work_order_one?.id,
        },
      },
      createScope.context()
    )
    const template = createTemplateRes.data
      ?.insert_work_order_template_one as IWorkOrderTemplateFragment

    return template
  }
}

type UseDeleteWorkOrderModal = {
  workOrderId?: uuid
  isTemplate?: boolean
  afterDelete?: () => void
}

export const useDeleteWorkOrder = ({
  workOrderId,
  isTemplate,
  afterDelete,
}: UseDeleteWorkOrderModal) => {
  const [, updateWorkOrderPartiallyMutation] = useUpdateWorkOrderPartiallyMutation()
  const { context } = usePermissionScope(IPermissionScopeEnum.AppWorkOrderDelete)

  const TemplateConfirmModalProps = {
    title: i18n.t("common:delete_token", {
      token: i18n.t("common:template", { count: 1 }),
    }),
    content: i18n.t("common:messages.token_delete_confirmation", {
      token: i18n.t("common:template", { count: 1 }),
    }),
    okText: i18n.t("common:delete"),
    cancelText: i18n.t("common:cancel"),
  }

  const WorkOrderConfirmModalProps = {
    title: i18n.t("common:delete_token", {
      token: i18n.t("common:task", { count: 1 }),
    }),
    content: i18n.t("common:messages.token_delete_confirmation", {
      token: i18n.t("common:task", { count: 1 }),
    }),
    okText: i18n.t("common:delete"),
    cancelText: i18n.t("common:cancel"),
  }

  const ConfirmModalProps = isTemplate
    ? TemplateConfirmModalProps
    : WorkOrderConfirmModalProps

  const errorMessage = i18n.t("common:messages.token_delete_failure", {
    token: isTemplate
      ? i18n.t("common:template", { count: 1 })
      : i18n.t("common:task", { count: 1 }),
  })

  const successMsg = i18n.t("common:messages.token_delete_success", {
    token: isTemplate
      ? i18n.t("common:template", { count: 1 })
      : i18n.t("common:task", { count: 1 }),
  })

  const modal = useConfirmModal({
    ...ConfirmModalProps,
    onOk: async () => {
      if (!workOrderId) return
      const res = await updateWorkOrderPartiallyMutation(
        {
          id: workOrderId,
          changes: { deleted_at: new Date().toISOString() },
        },
        context()
      )
      if (res.error) toast.error(errorMessage)
      else {
        toast.success(successMsg)
        afterDelete?.()
      }
    },
  })

  return {
    deleteConfirmModal: modal.component,
    delete: modal.show,
  }
}

function workOrderToTemplate(
  workOrder: IWorkOrderFragment
): Omit<IWorkOrderFragment, "id"> {
  return {
    ...workOrder,
    type: IWorkOrderTypeEnum.Template,
    status: IWorkOrderStatusEnum.Open,
    due_date: null,
    due_time: null,
    recurrence_info: null,
    block_groups: (workOrder.block_groups ?? []).map((g) => ({
      ...g,
      elements: (g.elements ?? []).map((e) => cleanBlockElement(e as BlockElementFragment)),
    })) as unknown as IWorkOrderFragment["block_groups"],
  }
}

function copyWorkOrderInitialValues(
  workOrder: IWorkOrderFragment
): Omit<IWorkOrderFragment, "id"> {
  return {
    ...workOrder,
    status: IWorkOrderStatusEnum.Open,
    block_groups: workOrder.block_groups.map((g) => ({
      ...g,
      elements: g.elements.map((e) => cleanBlockElement(e as BlockElementFragment)),
    })) as unknown as IWorkOrderFragment["block_groups"],
  }
}

export function useTaskOptions(options: { workOrder: IWorkOrderFragment }) {
  const { workOrder } = options
  const editWorkOrderScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)

  const client = useClient()
  const blockGroupFormHandle = useRef<BlockGroupFormHandle>(null)

  const openModal = useOpenModal()

  const onCopyWorkOrder = () => {
    const initialValues = copyWorkOrderInitialValues(workOrder)
    const open = () =>
      openModal({
        task_create: { open: true, payload: { initialValues } },
      })

    open()
  }

  const onSetSubtaskToTask = async () => {
    if (!workOrder.id) return
    const res = await client
      .mutation<
        IUpdateWorkOrderPartiallyMutation,
        IUpdateWorkOrderPartiallyMutationVariables
      >(
        UpdateWorkOrderPartiallyDocument,
        {
          id: workOrder.id,
          changes: {
            parent_work_order_id: null,
          },
        },
        editWorkOrderScope.context()
      )
      .toPromise()
    if (!res.data?.update_work_order_by_pk) {
      toast.error(
        i18n.t("common:messages.token_update_failure", {
          token: i18n.t("common:task", { count: 1 }),
        })
      )
    }
  }

  const onCreateShareLink = async () => {
    if (!workOrder.id) return

    openModal({
      task_public_access: {
        open: true,
        payload: {
          taskId: workOrder.id,
          mode: workOrder.public_view_expiration ? "revoke" : "create",
          initialValues: {
            expiresIn: workOrder.public_view_expiration
              ? new Date(workOrder.public_view_expiration)
              : add(new Date(), { days: 7 }),
          },
        },
      },
    })
  }

  return {
    onCopyWorkOrder,
    onSetSubtaskToTask,
    onCreateShareLink,
    blockGroupFormHandle,
  }
}
