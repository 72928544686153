import classNames from "classnames"
import { ReactNode } from "react"

type PageTitleProps = {
  variant?: "h1" | "h2"
  title: ReactNode
  subtitle?: string
  action?: ReactNode
  className?: string
}

const PageTitle: React.FC<PageTitleProps> = (props) => (
  <div className={classNames("py-3 w-full", props.className)}>
    <div className="flex h-8 justify-between gap-x-4 gap-y-2 sm:items-center">
      <div className="flex items-center space-x-2 text-gray-700">
        <h1
          className={classNames(
            "text-2xl font-bold tracking-tight text-gray-700 line-clamp-1 break-all",
            props.variant === "h2" && "!text-xl"
          )}>
          {props.title}
        </h1>
      </div>

      {props.action}
    </div>

    {props.subtitle && (
      <h3 className="mt-1 text-xs leading-relaxed text-gray-500">{props.subtitle}</h3>
    )}
  </div>
)

export default PageTitle
