// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import { WorkOrderExpenseFragmentDoc } from '../../../graphql/documents/fragments.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ICreateExpenseMutationVariables = Types.Exact<{
  data: Types.IWorkOrderExpenseInsertInput;
}>;


export type ICreateExpenseMutation = { insert_work_order_expense_one: { __typename: 'work_order_expense', work_order: { __typename: 'work_order', id: uuid, expenses: Array<{ __typename: 'work_order_expense', id: uuid, created_at: string, description: string | null, amount: number, expense_date: string, work_order_id: uuid, created_by: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null }, uploads: Array<{ __typename: 'upload', id: uuid, file_name: string, file_size: number, mime_type: string, created_at: string, thumbnail_url: string | null, url: string | null }> | null }> } } | null };

export type IUpdateExpenseMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  data: Types.IWorkOrderExpenseSetInput;
}>;


export type IUpdateExpenseMutation = { update_work_order_expense_by_pk: { __typename: 'work_order_expense', id: uuid, created_at: string, description: string | null, amount: number, expense_date: string, work_order_id: uuid, created_by: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null }, uploads: Array<{ __typename: 'upload', id: uuid, file_name: string, file_size: number, mime_type: string, created_at: string, thumbnail_url: string | null, url: string | null }> | null } | null };

export type IDeleteExpenseMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type IDeleteExpenseMutation = { delete_work_order_expense_by_pk: { __typename: 'work_order_expense', work_order: { __typename: 'work_order', id: uuid, expenses: Array<{ __typename: 'work_order_expense', id: uuid, created_at: string, description: string | null, amount: number, expense_date: string, work_order_id: uuid, created_by: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null }, uploads: Array<{ __typename: 'upload', id: uuid, file_name: string, file_size: number, mime_type: string, created_at: string, thumbnail_url: string | null, url: string | null }> | null }> } } | null };


export const CreateExpenseDocument = gql`
    mutation CreateExpense($data: work_order_expense_insert_input!) {
  insert_work_order_expense_one(object: $data) {
    work_order {
      id
      expenses {
        ...WorkOrderExpense
      }
    }
  }
}
    ${WorkOrderExpenseFragmentDoc}`;

export function useCreateExpenseMutation() {
  return Urql.useMutation<ICreateExpenseMutation, ICreateExpenseMutationVariables>(CreateExpenseDocument);
};
export const UpdateExpenseDocument = gql`
    mutation UpdateExpense($id: uuid!, $data: work_order_expense_set_input!) {
  update_work_order_expense_by_pk(pk_columns: {id: $id}, _set: $data) {
    ...WorkOrderExpense
  }
}
    ${WorkOrderExpenseFragmentDoc}`;

export function useUpdateExpenseMutation() {
  return Urql.useMutation<IUpdateExpenseMutation, IUpdateExpenseMutationVariables>(UpdateExpenseDocument);
};
export const DeleteExpenseDocument = gql`
    mutation DeleteExpense($id: uuid!) {
  delete_work_order_expense_by_pk(id: $id) {
    work_order {
      id
      expenses {
        ...WorkOrderExpense
      }
    }
  }
}
    ${WorkOrderExpenseFragmentDoc}`;

export function useDeleteExpenseMutation() {
  return Urql.useMutation<IDeleteExpenseMutation, IDeleteExpenseMutationVariables>(DeleteExpenseDocument);
};