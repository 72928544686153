import Button from "@components/shared/button"
import { Dialog } from "@components/shared/dialog"
import { useAnalytics } from "@contexts/analytics-context"
import { useFeature, useFeatureFlags } from "@contexts/feature-flag-context"
import i18n from "@i18n"
import { PropsWithChildren } from "react"

export function FeaturePreview(props: PropsWithChildren) {
  const { posthog } = useAnalytics()
  const { updateClientFeatureFlag } = useFeatureFlags()

  const hasTaskDetailPage = useFeature("task_detail")
  const hasImprovedDataView = useFeature("data_view")

  const onToggleTaskDetail = () => {
    if (!hasTaskDetailPage) {
      posthog?.capture("enable_feature_preview", { feature: "task_detail" })
    } else {
      posthog?.capture("disable_feature_preview", { feature: "task_detail" })
    }

    updateClientFeatureFlag("task_detail", !hasTaskDetailPage)
  }

  const onToggleImprovedDataView = () => {
    if (!hasImprovedDataView) {
      posthog?.capture("enable_feature_preview", { feature: "data_view" })
    } else {
      posthog?.capture("disable_feature_preview", { feature: "data_view" })
    }

    updateClientFeatureFlag("data_view", !hasImprovedDataView)
  }

  return (
    <Dialog
      title={i18n.t("common:feature_preview.title")}
      trigger={props.children}
      positionOffset={96}
      position="top"
      content={
        <div className="space-y-4">
          <div className="flex items-center justify-between space-x-4 border-b pb-4">
            <div className="flex-1">
              <h4 className="text-sm font-medium">
                {i18n.t("common:feature_preview.task_detail.title")}
              </h4>
              <p className="mt-0.5 text-xs text-gray-500">
                {i18n.t("common:feature_preview.task_detail.description")}
              </p>
            </div>

            <Button type="secondary" onClick={onToggleTaskDetail}>
              {hasTaskDetailPage
                ? i18n.t("common:feature_preview.disable")
                : i18n.t("common:feature_preview.enable")}
            </Button>
          </div>

          <div className="flex items-center justify-between space-x-4">
            <div className="flex-1">
              <h4 className="text-sm font-medium">
                {i18n.t("common:feature_preview.data_view.title")}
              </h4>
              <p className="mt-0.5 text-xs text-gray-500">
                {i18n.t("common:feature_preview.data_view.description")}
              </p>
            </div>

            <Button type="secondary" onClick={onToggleImprovedDataView}>
              {hasImprovedDataView
                ? i18n.t("common:feature_preview.disable")
                : i18n.t("common:feature_preview.enable")}
            </Button>
          </div>
        </div>
      }
    />
  )
}
