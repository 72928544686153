import { DataViewTabs as NewDataViewTabs } from "@components/new-data-view/data-view-tabs"
import { Button } from "@components/shared"
import { DataViewTabs } from "@components/shared/data-view/data-view-tabs"
import { useFeature } from "@contexts/feature-flag-context"
import { openModal } from "@contexts/modal-context"
import { IViewDataTypeEnum } from "@elara/db"
import i18n from "@i18n"
import { Plus } from "@phosphor-icons/react"

const MaintenancePage = () => {
  const newDataView = useFeature("data_view")
  if (newDataView) {
    return (
      <NewDataViewTabs
        dataType={IViewDataTypeEnum.Maintenance}
        title={i18n.t("common:task", { count: 2 })}
        action={
          <Button icon={Plus} onClick={() => openModal("maintenance")}>
            {i18n.t("common:new_token", {
              context: "male",
              token: i18n.t("maintenance:fields.schedule"),
            })}
          </Button>
        }
      />
    )
  }

  return (
    <DataViewTabs
      dataType={IViewDataTypeEnum.Maintenance}
      title={i18n.t("maintenance:title")}
      action={
        <Button icon={Plus} onClick={() => openModal("maintenance")}>
          {i18n.t("common:new_token", {
            context: "male",
            token: i18n.t("maintenance:fields.schedule"),
          })}
        </Button>
      }
    />
  )
}

export default MaintenancePage
