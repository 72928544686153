import { Data } from "@elara/select"
import { useCallbackRef, useStickyState } from "@hooks"
import { forEachTreeList, TreeLike } from "@utils/tree"
import { useCallback, useMemo } from "react"

import { LayoutData } from "../data-view-types"

export type UseBatchSelectReturnType<D extends Data> = {
  getNodeSelect: (item: D) => {
    select: () => void
    unselect: () => void
    toggle: () => void
    isSelected: boolean
  }
  selectAll: () => void
  unselectAll: () => void
  hasSelectedItem: boolean
  selectedIds: string[]
  getSelectedItems: () => D[]
}
export const useBatchSelect = <D extends Data>(options: {
  storageId: string
  data: LayoutData<D>
  dataId: (d: D) => string
}): UseBatchSelectReturnType<D> => {
  const [batchSelectState, setBatchSelectState] = useStickyState<Record<string, boolean>>(
    {},
    `DataViewBatchSelect:${options.storageId}`,
    "component"
  )

  const key = options.dataId

  const select = useCallback((item: D) => {
    setBatchSelectState((items) => ({ ...items, [key(item)]: true }))
  }, [])

  const unselect = useCallbackRef((item: TreeLike<D>) => {
    setBatchSelectState((items) => {
      const update: Record<string, boolean> = { [key(item)]: false }
      if (item.children) {
        forEachTreeList(item.children, (child) => {
          update[key(child)] = false
        })
      }
      return { ...items, ...update }
    })
  })

  const toggle = useCallbackRef((item: TreeLike<D>) => {
    setBatchSelectState((items) => {
      if (items[key(item)]) {
        const update: Record<string, boolean> = { [key(item)]: false }
        if (item.children) {
          forEachTreeList(item.children, (child) => {
            update[key(child)] = false
          })
        }
        return { ...items, ...update }
      }
      return { ...items, [key(item)]: true }
    })
  })

  const isSelected = useCallback(
    (item: D) => !!batchSelectState[key(item)],
    [batchSelectState]
  )

  const getNodeSelect = useCallback(
    (item: D) => ({
      select: () => select(item),
      unselect: () => unselect(item),
      toggle: () => toggle(item),
      isSelected: isSelected(item),
    }),
    [isSelected]
  )

  const selectAll = useCallbackRef(() => {
    const newState: Record<string, boolean> = {}
    if (options.data.type === "ungrouped") {
      forEachTreeList(options.data.items, (item) => {
        newState[key(item)] = true
      })
    } else if (options.data.type === "grouped") {
      options.data.groups.forEach((group) => {
        forEachTreeList(group.items, (item) => {
          newState[key(item)] = true
        })
      })
    }
    setBatchSelectState(newState)
  })

  const unselectAll = useCallbackRef(() => {
    setBatchSelectState({})
  })

  const selectedIds = useMemo(
    () => Object.keys(batchSelectState).filter((k) => batchSelectState[k]),
    [batchSelectState]
  )

  const getSelectedItems = useCallbackRef(() => {
    const items: D[] = []
    if (options.data.type === "ungrouped") {
      forEachTreeList(options.data.items, (item) => {
        if (batchSelectState[key(item)]) {
          items.push(item)
        }
      })
    } else if (options.data.type === "grouped") {
      options.data.groups.forEach((group) => {
        forEachTreeList(group.items, (item) => {
          if (batchSelectState[key(item)]) {
            items.push(item)
          }
        })
      })
    }
    return items
  })

  const value = useMemo(
    () => ({
      getNodeSelect,
      selectAll,
      unselectAll,
      getSelectedItems,
      selectedIds,
      hasSelectedItem: selectedIds.length > 0,
    }),
    [getNodeSelect, selectAll, unselectAll, batchSelectState]
  )

  return value
}
