import Highcharts from "highcharts"
import HighchartsMore from "highcharts/highcharts-more"
import HighchartsAccessibility from "highcharts/modules/accessibility"
import HighchartsDrilldown from "highcharts/modules/drilldown"
import HighchartsExportData from "highcharts/modules/export-data"
import HighchartsExporting from "highcharts/modules/exporting"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import colors from "tailwindcss/colors"

type Props = { children: JSX.Element | null }

const langDE = {
  decimalPoint: ",",
  thousandsSep: ".",
  loading: "Daten werden geladen...",
  months: [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ],
  weekdays: [
    "Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
  ],
  shortMonths: [
    "Jan",
    "Feb",
    "Mär",
    "Apr",
    "Mai",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Dez",
  ],
  exportButtonTitle: "Exportieren",
  printButtonTitle: "Drucken",
  rangeSelectorFrom: "Von",
  rangeSelectorTo: "Bis",
  rangeSelectorZoom: "Zeitraum",
  viewFullscreen: "Vollbild",
  exitFullscreen: "Vollbild beenden",
  viewData: "Daten anzeigen",
  hideData: "Daten ausblenden",
  noData: "Keine Daten vorhanden",
  printChart: "Diagramm drucken",
  downloadPNG: "Download als PNG-Bild",
  downloadJPEG: "Download als JPEG-Bild",
  downloadPDF: "Download als PDF-Dokument",
  downloadSVG: "Download als SVG-Bild",
  downloadCSV: "Download als CSV-Dokument",
  downloadXLS: "Download als XLS-Dokument",
  resetZoom: "Zoom zurücksetzen",
  resetZoomTitle: "Zoom zurücksetzen",
}

const HighchartsWrapper = ({ children }: Props) => {
  const { i18n } = useTranslation()
  const language = i18n.language

  useEffect(() => {
    // Plugins
    HighchartsMore(Highcharts)
    HighchartsDrilldown(Highcharts)
    HighchartsExporting(Highcharts)
    HighchartsExportData(Highcharts)
    HighchartsAccessibility(Highcharts)

    // Global Options
    Highcharts.setOptions({
      lang: language.includes("de") ? langDE : Highcharts.defaultOptions.lang,
      colors: ["#003D99", "#983E9A", "#E24E85", "#FF7D68", "#FFBA58", "#F9F871"],
      title: {
        y: 20,
        margin: 0,
        align: "left",
        style: {
          fontSize: "14px",
          color: colors.zinc[500],
          fontWeight: "normal",
        },
      },
      plotOptions: {
        pie: { shadow: false, center: ["50%", "50%"] },
        line: { color: "#003D99" },
        area: {
          threshold: null,
          marker: { enabled: false },
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, "#003D99"],
              [1, Highcharts.color("#003D99").setOpacity(0).get("rgba") as string],
            ],
          },
        },
        packedbubble: {
          layoutAlgorithm: {
            splitSeries: false,
            gravitationalConstant: 0.02,
          },
        },
      },
      chart: { marginTop: 64, style: { fontFamily: "Inter, sans-serif" } },
      credits: { enabled: false },
    })
  }, [language])

  return children
}

export default HighchartsWrapper
