import { ContactMultiSelect } from "@components/shared/contact-select"
import { DialogRootProps } from "@components/shared/dialog"
import { DialogForm } from "@components/shared/dialog-form"
import { FormField } from "@components/shared/form/form-field"
import toast from "@components/shared/toast"
import { useUpdateAssetSupportMutation } from "@graphql/documents/asset.generated"
import { IAssetDetailFragment } from "@graphql/documents/fragments.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"

type FormValues = {
  support_contact_ids: string[]
}

type Props = {
  initialValues: FormValues
  asset: IAssetDetailFragment | null
  title: string
} & Required<Pick<DialogRootProps, "isOpen" | "onOpenChange">>

const AssetSupportFormDialog = ({ title, asset, ...props }: Props) => {
  const editScope = usePermissionScope(IPermissionScopeEnum.AppManufacturerCreateEditDelete)

  const [, updateAssetSupport] = useUpdateAssetSupportMutation()

  const onSubmit = async (values: FormValues) => {
    if (!asset) return

    const res = await updateAssetSupport(
      { assetId: asset.id, contactIds: values.support_contact_ids },
      editScope.context()
    )

    if (!res.data?.update_asset_by_pk || res.error) {
      const error = i18n.t("common:messages.token_update_failure", {
        token: i18n.t("assets:fields.support"),
      })
      toast.error(error)
      return
    }

    toast.success(
      i18n.t("common:messages.token_update_success", {
        token: i18n.t("assets:fields.support"),
      })
    )
  }

  return (
    <DialogForm<FormValues>
      title={title}
      formikConfig={{
        initialValues: props.initialValues,
        onSubmit,
      }}
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}>
      {() => (
        <FormField
          name="support_contact_ids"
          hasErrorPlaceholder={false}
          className="my-6"
          label={i18n.t("common:contact", { count: 1 })}>
          {({ field, helpers }) => (
            <ContactMultiSelect value={field.value} onChange={helpers.setValue} />
          )}
        </FormField>
      )}
    </DialogForm>
  )
}

export default AssetSupportFormDialog
