import { useComposedRefs } from "@hooks/use-compose-refs"
import { useElementSize } from "@hooks/use-element-size"
import { DotsThree } from "@phosphor-icons/react"
import * as Tabs from "@radix-ui/react-tabs"
import { TabsTrigger } from "@radix-ui/react-tabs"
import classNames from "classnames"
import React, { ReactNode, useEffect, useRef, useState } from "react"

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./dropdown"
import HorizontalTabs from "./horizontal-tabs"
import TouchTargetSize from "./touch-target-size"

const Root = React.forwardRef<
  React.ElementRef<typeof Tabs.Root>,
  React.ComponentProps<typeof Tabs.Root>
>((props, forwardedRef) => {
  return <Tabs.Root {...props} ref={forwardedRef} />
})

type ListProps = React.ComponentProps<typeof Tabs.List> & {
  triggers: { value: string; label: string; icon?: ReactNode }[]
}

const List = React.forwardRef<React.ElementRef<typeof Tabs.List>, ListProps>(
  ({ triggers, ...props }, forwardedRef) => {
    const [element, setElement] = useState<React.ElementRef<typeof Tabs.List> | null>(null)

    const width = useRef<Record<string, number>>({})
    const ref = useComposedRefs(forwardedRef, (e) => setElement(e))
    const size = useElementSize(element)
    const [hiddenTriggers, setHiddenTriggers] = useState<string[]>([])

    useEffect(() => {
      if (!size) return

      let totalWidth = 36 // width of the menu + padding
      let hidden: string[] = []
      for (const trigger of triggers) {
        const triggerWidth = width.current[trigger.value]
        if (triggerWidth) {
          totalWidth += triggerWidth
          if (totalWidth > size.width) {
            hidden.push(trigger.value)
          }
        }
      }
      setHiddenTriggers(hidden)
    }, [size?.width])

    return (
      <HorizontalTabs.List {...props} ref={ref}>
        {triggers.map((trigger) => {
          if (hiddenTriggers.includes(trigger.value)) return null

          return (
            <HorizontalTabs.Trigger
              value={trigger.value}
              key={trigger.value}
              ref={(e) => {
                if (!e) return
                width.current[trigger.value] = e.offsetWidth
              }}>
              <div className="flex items-center space-x-2">
                <span>{trigger.icon}</span>
                <span>{trigger.label}</span>
              </div>
            </HorizontalTabs.Trigger>
          )
        })}

        {hiddenTriggers.length > 0 && (
          <div className=" flex shrink basis-full justify-start border-b border-gray-200">
            <DropdownMenu>
              <DropdownMenuTrigger className="group relative text-gray-700">
                <div className="rounded-md p-1 group-hover:bg-gray-100 group-radix-state-open:bg-gray-100">
                  <DotsThree size={20} weight="bold" />
                </div>
                <TouchTargetSize />
              </DropdownMenuTrigger>
              <DropdownMenuContent side="bottom" align="end">
                {triggers
                  .filter((trigger) => hiddenTriggers.includes(trigger.value))
                  .map((trigger) => {
                    return (
                      <DropdownMenuItem key={trigger.value}>
                        <TabsTrigger
                          value={trigger.value}
                          className="flex items-center space-x-2">
                          <span>{trigger.icon}</span>
                          <span>{trigger.label}</span>
                        </TabsTrigger>
                      </DropdownMenuItem>
                    )
                  })}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}
      </HorizontalTabs.List>
    )
  }
)

const Content = React.forwardRef<
  React.ElementRef<typeof Tabs.Content>,
  React.ComponentProps<typeof Tabs.Content>
>((props, forwardedRef) => {
  return (
    <Tabs.Content
      {...props}
      ref={forwardedRef}
      className={classNames("flex flex-col flex-1 min-h-0", props.className)}
    />
  )
})

export default {
  Root,
  List,
  Content,
}
