import { DialogRootProps } from "@components/shared/dialog"
import { DialogForm } from "@components/shared/dialog-form"
import { FormField } from "@components/shared/form/form-field"
import FormLabel from "@components/shared/form/form-label"
import RichText from "@components/shared/rich-text"
import { TextInput } from "@components/shared/text-input"
import { IServiceRequestStatusEnum } from "@elara/db"
import { IServiceRequestFragment } from "@graphql/documents/fragments.generated"
import { useUpdateServiceRequestMutation } from "@graphql/documents/service-request.generated"
import i18n from "@i18n"

import { useServiceRequestScope } from "../hooks/use-service-request-scope"
import { ServiceRequestPrioritySelect, ServiceRequestStatusTag } from "../status"

type FormValues = Partial<IServiceRequestFragment>

type Props = {
  initialValues: FormValues
} & Required<Pick<DialogRootProps, "isOpen" | "onOpenChange">>

const EditServiceRequestFormDialog = ({ isOpen, onOpenChange, initialValues }: Props) => {
  const context = useServiceRequestScope()
  const [, updateServiceRequest] = useUpdateServiceRequestMutation()

  const onSubmit = async (values: FormValues) => {
    const response = await updateServiceRequest(
      {
        id: +initialValues.id!,
        object: {
          title: values.title,
          priority: values.priority,
          description: values.description,
        },
      },
      context
    )

    // TODO: Handle changes to uploads, assets, assignees, and teams

    if (!response.error) return
  }

  return (
    <DialogForm<FormValues>
      closeIcon
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      title={i18n.t("common:edit_token", {
        token: i18n.t("common:service_request", { count: 1 }),
      })}
      formikConfig={{
        initialValues,
        onSubmit,
      }}>
      {(form) => (
        <div className="space-y-4">
          <div className="flex items-center space-x-4">
            <FormField name="status" hasErrorPlaceholder={false}>
              <ServiceRequestStatusTag
                status={form.values.status ?? IServiceRequestStatusEnum.New}
              />
            </FormField>

            <FormField name="priority" hasErrorPlaceholder={false}>
              {({ field, helpers }) => (
                <ServiceRequestPrioritySelect
                  value={field.value ?? ""}
                  onValueChange={helpers.setValue}
                />
              )}
            </FormField>
          </div>

          <FormField name="title" hasErrorPlaceholder={false}>
            {({ field, hasError }) => (
              <FormLabel label={i18n.t("service-request:fields.title")} required>
                <TextInput {...field} hasError={hasError} className="w-full" />
              </FormLabel>
            )}
          </FormField>

          <FormField name="description" hasErrorPlaceholder={false}>
            {({ field, helpers, hasError }) => (
              <FormLabel label={i18n.t("service-request:fields.description")} required>
                <RichText
                  showToolbar
                  hasError={hasError}
                  content={field.value ?? ""}
                  className="min-h-[160px] w-full rounded border"
                  onUpdate={({ editor }) => {
                    const value = editor.getHTML()
                    helpers.setValue(value)
                  }}
                />
              </FormLabel>
            )}
          </FormField>
        </div>
      )}
    </DialogForm>
  )
}

export default EditServiceRequestFormDialog
