import WorkOrderDetail from "@components/work-order/work-order-detail"
import { IMaintenanceFragment } from "@graphql/documents/fragments.generated"
import { useOutletContext } from "react-router-dom"

export function MaintenanceTask() {
  const { maintenance } = useOutletContext<{ maintenance: IMaintenanceFragment }>()
  return <WorkOrderDetail isTemplate asPage={false} workOrderId={maintenance.template_id} />
}

export default MaintenanceTask
