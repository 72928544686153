import PageTitle from "@components/page/page-title"
import { Button, EmptyStateProps } from "@components/shared"
import { FilterState } from "@components/shared/data-view/data-view-types"
import HorizontalPills from "@components/shared/horizontal-pills"
import { Select } from "@components/shared/select"
import { useDataWorkOrderDataView, useTaskData } from "@components/work-order/use-task-data"
import { WorkOrderDataView } from "@components/work-order/work-order-data-view"
import { ModalActionType, ModalContext, openModal } from "@contexts/modal-context"
import { usePage } from "@contexts/page-context"
import { useUser } from "@contexts/user-context"
import { Where } from "@elara/select"
import {
  ITeamFragment,
  IWorkOrderDataViewFragment,
} from "@graphql/documents/fragments.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import { useMyTeams } from "@hooks/use-my-teams"
import i18n from "@i18n"
import { Plus, Rows } from "@phosphor-icons/react"
import { useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"

type MyTaskPreFilter = "assigned" | "assigned_teams" | "created" | "collaborated"

const noDataMap: Record<MyTaskPreFilter, EmptyStateProps> = {
  assigned: {
    icon: Rows,
    title: i18n.t("common:no_token", {
      context: "plural",
      token: i18n.t("common:task", { count: 2 }),
    }),
    message: i18n.t("tasks:filters.assigned.empty_message"),
  },
  assigned_teams: {
    icon: Rows,
    title: i18n.t("common:no_token", {
      context: "plural",
      token: i18n.t("common:task", { count: 2 }),
    }),
    message: i18n.t("tasks:filters.assigned_teams.empty_message"),
  },
  created: {
    icon: Rows,
    title: i18n.t("common:no_token", {
      context: "plural",
      token: i18n.t("common:task", { count: 2 }),
    }),
    message: i18n.t("tasks:filters.created.empty_message"),
  },
  collaborated: {
    icon: Rows,
    title: i18n.t("common:no_token", {
      context: "plural",
      token: i18n.t("common:task", { count: 2 }),
    }),
    message: i18n.t("tasks:filters.collaborated.empty_message"),
  },
}

const fixedFilters = (preFilter: MyTaskPreFilter, teams?: ITeamFragment[]) => {
  let fixedFilters: FilterState[] = []

  if (preFilter === "assigned") {
    fixedFilters.push({
      type: "select",
      id: "assignees",
      selectedValues: [{ type: "current_user" }],
    })
  } else if (preFilter === "assigned_teams") {
    if (teams && teams.length > 0) {
      fixedFilters.push({
        type: "select",
        id: "teams",
        selectedValues: teams.map((team) => team.id),
      })
    }
  } else if (preFilter === "created") {
    fixedFilters.push({
      type: "select",
      id: "createdBy",
      selectedValues: ["current_user"],
    })
  } else if (preFilter === "collaborated") {
    fixedFilters.push({
      type: "select",
      id: "collaborator",
      selectedValues: ["current_user"],
    })
  }
  return fixedFilters
}

export const MyTasksPage = () => {
  usePage({ id: "my-tasks", title: i18n.t("tasks:my_tasks") })

  const params = useParams()
  const assignee = params.assignee as "me" | "teams" | "collaborator"

  const user = useUser()
  const myTeams = useMyTeams()
  const navigate = useNavigate()
  const { dispatch } = useContext(ModalContext)
  const taskData = useTaskData({ myTasks: true })

  const creationScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderCreate)

  const dataView = (preFilter: MyTaskPreFilter) => (
    <WorkOrderDataView
      data={taskData.data}
      headerClassName="px-3"
      fixedFilters={taskData.fixedFilters.concat(fixedFilters(preFilter, myTeams))}
      configId="my-tasks:active"
      defaultConfig={{
        groupBy: "dueDate",
        orderBy: [
          { id: "dueDate", dir: "asc" },
          { id: "status", dir: "asc" },
          { id: "name", dir: "asc" },
        ],
      }}
      useData={(filterWhere) => {
        let where: Where<IWorkOrderDataViewFragment> = {}

        if (assignee === "me") {
          where = { assignees: { user_id: { _eq: user.id } } }
        } else if (assignee === "teams") {
          where = { assigned_teams: { team_id: { _in: myTeams.map((t) => t.id) } } }
        } else if (assignee === "collaborator") {
          where = { collaborators: { user_id: { _eq: user.id } } }
        }

        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useDataWorkOrderDataView({ _and: [filterWhere ?? {}, where] })
      }}
      noData={{
        ...noDataMap[preFilter],
        actions: creationScope.hasScope ? (
          <Button
            icon={Plus}
            onClick={() => {
              dispatch({
                type: ModalActionType.SET_MODAL_STATE,
                payload: {
                  select_template: {
                    open: true,
                    payload: {
                      initialTaskValues:
                        preFilter === "collaborated"
                          ? { collaborator_ids: [user.id] }
                          : { assignee_ids: [user.id] },
                    },
                  },
                },
              })
            }}
            type="primary"
            size="small"
            className="mx-auto mt-3">
            {i18n.t("tasks:labels.new_task")}
          </Button>
        ) : null,
      }}
    />
  )

  return (
    <HorizontalPills.Root
      value={assignee}
      className="bg-gray-50 @container/header"
      onValueChange={(value) => navigate("../" + value)}>
      <div className="flex items-center justify-start px-3 @mobile/header:px-6">
        <div className="mr-4">
          <PageTitle title={i18n.t("common:task", { count: 2 })} />
        </div>

        <HorizontalPills.List className="hidden @mobile/header:block">
          <HorizontalPills.Trigger value="me">
            {i18n.t("tasks:assigned_page.assigned_to_me")}
          </HorizontalPills.Trigger>
          <HorizontalPills.Trigger value="teams">
            {i18n.t("tasks:assigned_page.assigned_to_team")}
          </HorizontalPills.Trigger>
          <HorizontalPills.Trigger value="collaborator">
            {i18n.t("tasks:assigned_page.collaborator")}
          </HorizontalPills.Trigger>
        </HorizontalPills.List>

        <Button
          className="ml-auto"
          onClick={() => openModal("select_template")}
          type="primary"
          icon={Plus}>
          {i18n.t("tasks:labels.new_task")}
        </Button>
      </div>

      <div className="px-3 pb-2 @mobile/header:hidden">
        <Select
          value={assignee}
          onValueChange={(value) => navigate("../" + value)}
          items={[
            { value: "me", label: i18n.t("tasks:assigned_page.assigned_to_me") },
            { value: "teams", label: i18n.t("tasks:assigned_page.assigned_to_team") },
            { value: "collaborator", label: i18n.t("tasks:assigned_page.collaborator") },
          ]}
          contentProps={{
            position: "popper",
            side: "bottom",
            align: "start",
            sideOffset: 4,
            className: "w-[var(--radix-select-trigger-width)]",
          }}
        />
      </div>

      <HorizontalPills.Content
        value="me"
        className="flex min-h-0 flex-1 flex-col border-t border-gray-200 bg-white pt-3 @mobile/header:mx-3 @mobile/header:rounded-md @mobile/header:border">
        {dataView("assigned")}
      </HorizontalPills.Content>
      <HorizontalPills.Content
        value="teams"
        className="flex min-h-0 flex-1 flex-col border-t border-gray-200 bg-white pt-3 @mobile/header:mx-3 @mobile/header:rounded-md @mobile/header:border">
        {dataView("assigned_teams")}
      </HorizontalPills.Content>
      <HorizontalPills.Content
        value="collaborator"
        className="flex min-h-0 flex-1 flex-col border-t border-gray-200 bg-white pt-3 @mobile/header:mx-3 @mobile/header:rounded-md @mobile/header:border">
        {dataView("collaborated")}
      </HorizontalPills.Content>
    </HorizontalPills.Root>
  )
}

export default MyTasksPage
