import { useDisclosure } from "@hooks"
import i18n from "@i18n"
import { useEffect, useState } from "react"

import { AlertDialog, AlertDialogProps } from "./alert-dialog"

type AlertEventProps = Omit<AlertDialogProps, "open" | "onOpenChange">

export const AlertDialogProvider = () => {
  const [alertProps, setAlertProps] = useState<AlertEventProps | null>(null)

  const disclosure = useDisclosure({
    onClose: () => setAlertProps(null),
  })

  useEffect(() => {
    const listener = (e: Event) => {
      if (e instanceof CustomEvent && e.detail) {
        setAlertProps(e.detail)
        disclosure.changeOpen(true)
      }
    }

    window.addEventListener("alert-dialog", listener)

    return () => window.removeEventListener("alert-dialog", listener)
  }, [])

  if (!alertProps) return null

  return (
    <AlertDialog
      open={disclosure.isOpen}
      onOpenChange={disclosure.changeOpen}
      {...alertProps}
    />
  )
}

export const alertDialog = async (props: AlertEventProps) => {
  return new Promise<boolean>((resolve) => {
    window.dispatchEvent(
      new CustomEvent("alert-dialog", {
        detail: {
          ...props,
          async onAction() {
            await props.onAction?.()
            resolve(true)
          },
          async onCancel() {
            await props.onCancel?.()
            resolve(false)
          },
        },
      })
    )
  })
}

export const discardWarning = async (props: Partial<AlertEventProps>) => {
  return new Promise<boolean>((resolve) => {
    window.dispatchEvent(
      new CustomEvent("alert-dialog", {
        detail: {
          ...props,
          title: props.title ?? i18n.t("common:dialogs.discard_changes.title"),
          description: props.title ?? i18n.t("common:dialogs.discard_changes.description"),
          onAction() {
            props.onAction?.()
            resolve(true)
          },
          onCancel() {
            props.onCancel?.()
            resolve(false)
          },
        },
      })
    )
  })
}
