import i18n from "@i18n"
import classNames from "classnames"

export const StockBadge = (props: { quantity: number | null; minQuantity: number }) => {
  const quantity = props.quantity ?? 0
  const minQuantity = props.minQuantity

  let status = "in_stock"
  if (quantity <= 0 && minQuantity === 0) {
    status = "non_stock"
  } else if (quantity <= 0 && minQuantity > 0) {
    status = "out_of_stock"
  } else if (quantity > 0 && quantity < minQuantity) {
    status = "low_stock"
  }

  return (
    <span
      className={classNames(
        `inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium min-w-0 max-w-full`,
        {
          "bg-gray-100 text-gray-800": status == "non_stock",
          "bg-red-100 text-red-800": status === "out_of_stock",
          "bg-orange-100 text-orange-800": status == "low_stock",
          "bg-green-100 text-green-800": status == "in_stock",
        }
      )}>
      <span className="truncate">
        {status === "non_stock" && i18n.t("consumables:labels.stock.non_stock")}
        {status === "out_of_stock" && i18n.t("consumables:labels.stock.out_of_stock")}
        {status == "low_stock" && i18n.t("consumables:labels.stock.low_stock")}
        {status == "in_stock" && i18n.t("consumables:labels.stock.in_stock")}
      </span>
    </span>
  )
}
