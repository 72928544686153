import { X } from "@phosphor-icons/react"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import { cn } from "@utils"
import React, { PropsWithChildren, ReactNode } from "react"

import TouchTargetSize from "./touch-target-size"

export type DialogRootProps = DialogPrimitive.DialogProps & { isOpen?: boolean }
export const DialogRoot = ({
  open,
  isOpen,
  ...props
}: React.PropsWithChildren<DialogRootProps>) => (
  <DialogPrimitive.Root {...props} open={open ?? isOpen} />
)

export type DialogContentProps = DialogPrimitive.DialogContentProps & {
  position?: "center" | "top"
  positionOffset?: number
}

export const DialogContent = React.forwardRef<HTMLDivElement, DialogContentProps>(
  ({ children, position = "center", positionOffset = 0, ...props }, forwardedRef) => {
    return (
      <DialogPrimitive.Portal>
        <div
          className={cn(
            "fixed inset-0 isolate flex items-end justify-center sm:items-center",
            { "items-start sm:items-start": position === "top" }
          )}
          style={
            {
              "--position-offset": `${positionOffset}px`,
            } as React.CSSProperties
          }>
          <div className="fixed inset-0 bg-black/50 backdrop-blur-sm transition-all fade-in duration-100 " />
          <DialogPrimitive.Content
            {...props}
            ref={forwardedRef}
            onEscapeKeyDown={(e) => e.stopPropagation()}
            className={cn(
              "fixed isolate",
              "animate-in data-[state=open]:fade-in-90 data-[state=open]:slide-in-from-bottom-20 sm:zoom-in-90 data-[state=open]:sm:slide-in-from-bottom-0",
              "p-4 sm:p-6 w-full sm:max-w-md bg-white flex flex-col min-h-0 overflow-y-auto overscroll-none focus:outline-none",
              "max-h-[calc(100%_-_var(--position-offset))] rounded-t-lg sm:rounded-lg sm:w-[95%] mt-[calc(var(--position-offset)/2)] sm:mt-[var(--position-offset)] sm:max-h-[calc(95%_-_var(--position-offset))]",
              { "rounded-b-lg w-[95%]": position === "top" },

              props.className
            )}>
            {children}
          </DialogPrimitive.Content>
        </div>
      </DialogPrimitive.Portal>
    )
  }
)

export type DialogContentHeaderProps = {
  title: ReactNode
  closeIcon?: boolean
}
export function DialogContentHeader(props: DialogContentHeaderProps) {
  const { closeIcon = true } = props
  return (
    <div className="sticky -top-4 z-10 -mx-4 -mt-4 flex justify-between bg-white/80 p-4 backdrop-blur-sm sm:-top-6 sm:-mx-6 sm:-mt-6 sm:p-6 sm:pb-4">
      <DialogPrimitive.DialogTitle className="text-base font-semibold leading-none sm:text-lg sm:leading-none">
        {props.title}
      </DialogPrimitive.DialogTitle>
      {closeIcon && (
        <DialogPrimitive.Close className="relative -m-1 self-start rounded text-2xl text-gray-500 ring-gray-400 ring-offset-2 hover:text-gray-700 active:ring-2 sm:-m-2">
          <X size={16} weight="bold" />
          <TouchTargetSize />
        </DialogPrimitive.Close>
      )}
    </div>
  )
}

type DialogContentFooterProps = PropsWithChildren<{ className?: string }>
export function DialogContentFooter(props: DialogContentFooterProps) {
  return (
    <div
      className={cn(
        "sticky z-10 bg-gray-50/80 backdrop-blur-sm -mx-4 -mb-4 -bottom-4 px-4 py-3 sm:-bottom-6 sm:px-6 sm:-mx-6 sm:-mb-6 flex justify-end items-center space-x-4",
        props.className
      )}>
      {props.children}
    </div>
  )
}

export const DialogTrigger = DialogPrimitive.Trigger
export const DialogClose = DialogPrimitive.Close

export type DialogProps = Pick<DialogRootProps, "isOpen" | "onOpenChange"> &
  Omit<DialogContentProps, "content"> &
  Partial<DialogContentHeaderProps> & {
    trigger?: React.ReactNode | null
    content?: React.ReactNode | null | JSX.Element
    contentAsChild?: boolean
  }

export const Dialog = React.forwardRef<HTMLDivElement, DialogProps>(
  (
    {
      isOpen,
      onOpenChange,
      title,
      closeIcon,
      children,
      trigger,
      content,
      contentAsChild,
      ...contentProps
    },
    ref
  ) => {
    return (
      <DialogPrimitive.Root onOpenChange={onOpenChange} open={isOpen} modal>
        {(trigger || !contentAsChild) && (
          <DialogTrigger asChild>{trigger ?? children}</DialogTrigger>
        )}
        <DialogContent {...contentProps} ref={ref}>
          {title && <DialogContentHeader title={title} closeIcon={closeIcon} />}
          {content ?? (contentAsChild ? children : null)}
        </DialogContent>
      </DialogPrimitive.Root>
    )
  }
)
