import { IRecurrencePatternEnum, RecurrenceInfo } from "@elara/db"
import i18n from "@i18n"
import Icons from "@resources/icons"
import { formatDate, getAbbreviatedWeekNames } from "@utils/date"
import { Link } from "react-router-dom"

type WorkOrderRecurrenceInfoProps = {
  dueDate: Date
  recurrence: RecurrenceInfo
  rootId?: string | null
  shouldShowInstancesDetailLink?: Boolean
}

const getWeekdayString = (weekday: number) => getAbbreviatedWeekNames()[weekday - 1]

const getWeekdaysString = (weekdays: number[]) => {
  if (weekdays.length === 0) return ""
  if (weekdays.length === 1) return getWeekdayString(weekdays[0])

  // We want to get:
  // Mo. und Di.
  // Mo., Di. und Do.
  const sortedWeekdayStrs = weekdays
    .slice()
    .sort((a, b) => a - b)
    .map(getWeekdayString)
  const firstDaysStr = sortedWeekdayStrs.slice(0, -1).join(", ")
  const lastDayStr = sortedWeekdayStrs.pop()

  return `${firstDaysStr} ${i18n.t("common:and")} ${lastDayStr}`
}

export const getWorkOrderRecurrenceInfo = (
  recurrence: RecurrenceInfo | null,
  dueDate: Date | null | undefined,
  exactDate: boolean = true
): string => {
  if (!recurrence || !recurrence.recurrencePattern) {
    return i18n.t("tasks:filters.recurrence.one_off_task")
  }

  switch (recurrence.recurrencePattern) {
    case IRecurrencePatternEnum.Hourly: {
      if (recurrence.interval == 1) {
        return i18n.t("calendar:recurrence.display.every_hour")
      } else {
        return i18n.t("calendar:recurrence.display.every_x_hours_other", {
          count: recurrence.interval ?? 1,
        })
      }
    }
    case IRecurrencePatternEnum.Daily:
      return i18n.t("calendar:recurrence.display.every_x_days", {
        count: recurrence.interval ?? 1,
      })
    case IRecurrencePatternEnum.Weekly: {
      const weekdays = recurrence.weekdays as number[]
      if (recurrence.interval == 1) {
        return i18n.t("calendar:recurrence.display.every_week_on", {
          weeks: getWeekdaysString(weekdays),
        })
      } else {
        return i18n.t("calendar:recurrence.display.every_x_weeks_on", {
          context: "other",
          count: recurrence.interval ?? 1,
          weeks: getWeekdaysString(weekdays),
        })
      }
    }
    case IRecurrencePatternEnum.Monthly: {
      const endOfMonthNotice =
        (recurrence.day ?? 0) >= 29
          ? `(${i18n.t("calendar:recurrence.display.or_last_day_of_the_month")})`
          : ""
      if (recurrence.interval == 1) {
        if (exactDate) {
          return (
            i18n.t("calendar:recurrence.display.every_month_on", {
              day: recurrence.day,
            }) + `. ${endOfMonthNotice}`
          )
        }

        return i18n.t("calendar:recurrence.display.every_month")
      } else {
        if (exactDate) {
          return (
            i18n.t("calendar:recurrence.display.every_x_months_on", {
              count: recurrence.interval ?? 1,
              day: recurrence.day,
            }) + `. ${endOfMonthNotice}`
          )
        }

        return i18n.t("calendar:recurrence.display.every_x_months", {
          count: recurrence.interval ?? 1,
        })
      }
    }
    case IRecurrencePatternEnum.Yearly: {
      if (!dueDate) {
        return i18n.t("common:without_token", { token: i18n.t("calendar:tokens.due_date") })
      } else if (recurrence.interval == 1) {
        if (exactDate) {
          return i18n.t("calendar:recurrence.display.every_year_on", {
            date: formatDate(dueDate, "dd.MM."),
          })
        }

        return i18n.t("calendar:recurrence.display.every_x_year", {
          count: recurrence.interval ?? 1,
        })
      } else {
        if (exactDate) {
          return i18n.t("calendar:recurrence.display.every_x_years_on", {
            count: recurrence.interval ?? 1,
            date: formatDate(dueDate, "dd.MM."),
          })
        }

        return i18n.t("calendar:recurrence.display.every_x_years", {
          count: recurrence.interval ?? 1,
        })
      }
    }
    default:
      throw new Error("Unhandled Recurrence Display")
  }
}

export const WorkOrderRecurrenceInfo = ({
  dueDate,
  recurrence,
  rootId,
  shouldShowInstancesDetailLink = true,
}: WorkOrderRecurrenceInfoProps) => (
  <div className="flex flex-wrap items-center">
    {getWorkOrderRecurrenceInfo(recurrence, dueDate)}
    {rootId && shouldShowInstancesDetailLink && (
      <Link
        className="ml-2"
        to={`/recurring-work-order/${rootId}`}
        style={{ display: "inline-flex", alignItems: "center" }}>
        <Icons.RightArrow height={20} width={20} />
        <span className="text-sm text-blue-700">
          {i18n.t("tasks:filters.recurrence.to_overview")}
        </span>
      </Link>
    )}
  </div>
)
