import ScrollArea from "@components/shared/scroll-area"
import { useFeature } from "@contexts/feature-flag-context"
import { useUser } from "@contexts/user-context"
import { IPermissionScopeEnum } from "@elara/db"
import i18n from "@i18n"
import classNames from "classnames"
import { Link, useLocation } from "react-router-dom"

const MenuLink = (props: React.PropsWithChildren<{ to: string }>) => {
  const location = useLocation()

  return (
    <Link
      to={props.to}
      className={classNames(
        "pl-2 sm:pl-4 py-1 hover:bg-gray-50 hover:text-gray-900 border-l-2 break-all border-transparent hover:border-blue-500 flex items-center",
        { "!border-blue-700 bg-gray-50": location.pathname.includes(props.to) }
      )}>
      {props.children}
    </Link>
  )
}

const SettingsMenu = () => {
  const user = useUser()
  const hasImportFeature = useFeature("import_tool")
  const hasMaterialsFeature = useFeature("materials")

  const hasAccountManagementScope = user.allowedScopes.some(
    (a) => a.scope === IPermissionScopeEnum.AppAccountManagement
  )

  return (
    <ScrollArea
      vertical
      viewportAsChild
      className="shrink-0 border-gray-200 sm:basis-[256px] sm:border-r">
      <div className="flex min-w-0 flex-col space-y-2 py-3 text-sm font-medium text-gray-700">
        <span className="text-gray-500 sm:ml-3">{i18n.t("settings:account.title")}</span>
        <MenuLink to="profile">{i18n.t("settings:profile.title")}</MenuLink>
        <MenuLink to="notifications">{i18n.t("common:notification_other")}</MenuLink>
        <span className="!mt-5 text-gray-500 sm:ml-3">
          {i18n.t("settings:operations.title")}
        </span>
        <MenuLink to="general">{i18n.t("settings:general.title")}</MenuLink>
        {hasAccountManagementScope && (
          <MenuLink to="user-management">
            {i18n.t("settings:user_management.title")}
          </MenuLink>
        )}
        {hasAccountManagementScope && (
          <MenuLink to="api">{i18n.t("settings:api.title")}</MenuLink>
        )}
        <hr className="mx-4 border-gray-100" />
        <MenuLink to="asset">{i18n.t("common:asset_other")}</MenuLink>
        <MenuLink to="categories">{i18n.t("tasks:fields.category", { count: 2 })}</MenuLink>
        {hasMaterialsFeature && (
          <MenuLink to="consumables">{i18n.t("common:consumable", { count: 2 })}</MenuLink>
        )}
        <MenuLink to="location">{i18n.t("assets:fields.location", { count: 2 })}</MenuLink>
        <MenuLink to="teams">{i18n.t("common:team", { count: 2 })}</MenuLink>
        {hasImportFeature && <MenuLink to="import">Import</MenuLink>}
      </div>
    </ScrollArea>
  )
}

export default SettingsMenu
