import { PopoverContent, PopoverTrigger } from "@components/shared/popover"
import { useCallbackRef } from "@hooks"
import { useComposedRefs } from "@hooks/use-compose-refs"
import { useControllableState } from "@hooks/use-controllable-state"
import {
  Popover,
  PopoverContentProps,
  PopoverProps,
  PopoverTriggerProps,
} from "@radix-ui/react-popover"
import React, { PropsWithChildren } from "react"

import { useDataViewFilterContext } from "./context"
import { FilterMenu } from "./filter-menu"
import { useDataViewFilterMaxListHeight } from "./hooks"

const DataViewAddFilterContext = React.createContext<{
  setRef: (ref: HTMLButtonElement | null) => void
  listMaxHeight: number
  closeMenu: () => void
}>({
  listMaxHeight: Number.MAX_SAFE_INTEGER,
  setRef: () => {},
  closeMenu: () => {},
})

export const DataViewAddFilter = (props: PropsWithChildren<PopoverProps>) => {
  const [listMaxHeight, ref] = useDataViewFilterMaxListHeight()
  const [open, setOpen] = useControllableState({
    defaultProp: false,
    onChange: props.onOpenChange,
    prop: props.open,
  })
  const closeMenu = useCallbackRef(() => setOpen(false))
  return (
    <DataViewAddFilterContext.Provider value={{ setRef: ref, listMaxHeight, closeMenu }}>
      <Popover open={open} onOpenChange={setOpen}>
        {props.children}
      </Popover>
    </DataViewAddFilterContext.Provider>
  )
}

export const DataViewAddFilterContent = React.forwardRef(
  (
    props: React.PropsWithChildren<PopoverContentProps>,
    fwdRef: React.Ref<HTMLDivElement>
  ) => {
    const ctx = React.useContext(DataViewAddFilterContext)
    const dataViewFilter = useDataViewFilterContext()

    const { schema, dispatch } = dataViewFilter

    return (
      <PopoverContent
        {...props}
        dialog={{ onOpenAutoFocus: (e) => e.preventDefault() }}
        ref={fwdRef}>
        <FilterMenu
          listMaxHeight={ctx.listMaxHeight}
          onClose={ctx.closeMenu}
          schema={schema}
          onAddState={(state) => {
            dispatch({ type: "addFilterState", state })
            ctx.closeMenu()
          }}
        />
      </PopoverContent>
    )
  }
)

export const DataViewAddFilterTrigger = React.forwardRef(
  (props: PopoverTriggerProps, fwdRef: React.Ref<HTMLButtonElement>) => {
    const ctx = React.useContext(DataViewAddFilterContext)
    const ref = useComposedRefs(fwdRef, ctx.setRef)
    return <PopoverTrigger ref={ref} {...props} />
  }
)
