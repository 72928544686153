import { forwardRef } from "react"

import { InsetObjectValues, View, ViewProps } from "./view"

export type InsetProps = InsetObjectValues & ViewProps

export const Inset = forwardRef<HTMLDivElement, InsetProps>((props, ref) => {
  const { left, right, top, bottom, x, y, ...restProps } = props
  return <View ref={ref} inset={{ left, right, top, bottom, x, y }} {...restProps} />
})
