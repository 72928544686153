import { VStack } from "@components/layout"
import { useInsertCustomerFeedbackMutation } from "@graphql/documents/customer-feedback.generated"
import { IUploadDataFragment } from "@graphql/documents/upload.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import Icons from "@resources/icons"
import { colors } from "@styles"
import { useState } from "react"
import { Trans } from "react-i18next"

import { DialogForm } from "./dialog-form"
import Footer from "./footer"
import { FormField } from "./form/form-field"
import TextArea from "./text-area"
import toast from "./toast"
import Uploads from "./uploads"

export const FeedbackModal = (props: {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
}) => {
  const [textAreaValue, setTextAreaValue] = useState("")
  const scope = usePermissionScope(IPermissionScopeEnum.AppUser)

  const [, insertCustomerFeedback] = useInsertCustomerFeedbackMutation()

  return (
    <DialogForm
      isOpen={props.isOpen}
      closeIcon
      onOpenChange={props.onOpenChange}
      formikConfig={{
        initialValues: { text: "", uploads: [] as IUploadDataFragment[] },
        validateOnBlur: false,
        validate: (values) => {
          if (!values.text) {
            return {
              text: i18n.t("common:forms.is_required", {
                field: i18n.t("feedback:fields.message"),
              }),
            }
          }

          return {}
        },
        onSubmit: async (values) => {
          try {
            const res = await insertCustomerFeedback({
              text: values.text,
              uploadIds: values.uploads.map((u) => u.id),
            })

            if (res.error || !res.data?.insert_customer_feedback) {
              toast.error(i18n.t("feedback:messages.error"))
              throw new Error(i18n.t("feedback:messages.error"))
            } else {
              toast.success({
                title: i18n.t("feedback:messages.success"),
                body: i18n.t("feedback:messages.success_body"),
                params: { duration: 5000 },
              })
            }
          } catch (error) {
            toast.error(i18n.t("feedback:messages.error"))
            throw error
          }
        },
      }}
      className="!w-full !max-w-xl"
      title={i18n.t("feedback:dialog.title")}
      cancelText={i18n.t("common:cancel")}
      okText={i18n.t("common:send")}
      okProps={{ icon: Icons.MailSend }}>
      {(formik) => (
        <VStack space={16} inset={{ y: 16 }}>
          <p className="text-sm text-gray-700">{i18n.t("feedback:dialog.description")}</p>

          <FormField name="text" hasErrorPlaceholder={false}>
            <TextArea
              size="small"
              value={textAreaValue}
              className="min-h-[10rem]"
              style={{ color: colors.dark }}
              placeholder={i18n.t("feedback:dialog.placeholder")}
              onChange={(e) => setTextAreaValue(e.currentTarget.value)}
            />
          </FormField>

          <Uploads
            scope={scope}
            accept={{
              "image/jpeg": [".jpg", ".jpeg"],
              "image/png": [".png"],
            }}
            uploads={formik.values.uploads}
            onUploadFinished={(u, uploads) => {
              formik.setFieldValue("uploads", uploads.concat([u]))
            }}
            allowQuickDeleteOfPictures
            onDelete={(id, uploads) =>
              formik.setFieldValue(
                "uploads",
                uploads.filter((u) => u.id !== id)
              )
            }
          />

          <Footer>
            <Trans i18n={i18n} i18nKey="feedback:dialog.hint">
              Sie erreichen uns auch unter
              <a href="mailto:support@getelara.de">support@getelara.de</a> oder
              <a href="tel:+491635213965">+49 163 521 3965</a>.
            </Trans>
          </Footer>
        </VStack>
      )}
    </DialogForm>
  )
}

export default FeedbackModal
