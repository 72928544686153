import { Flex, HStack } from "@components/layout"
import Icons from "@resources/icons"
import { colors } from "@styles"
import { cn } from "@utils"
import { ReactElement, ReactNode } from "react"

type ModeValues = "warning" | "info" | "danger"

type DisabledMessageProps = {
  mode: ModeValues
  message: ReactNode
  hasIcon?: boolean
}

const modeToColor = (mode: ModeValues) => {
  switch (mode) {
    case "info":
      return "border-blue-500"
    case "warning":
      return "border-orange-500"
    case "danger":
      return "border-red-500"
    default:
      return "border-blue-500"
  }
}

const DisabledMessage = ({ mode, message, hasIcon }: DisabledMessageProps) => {
  const modeToIcon: Record<string, ReactElement> = {
    info: <Icons.Info color={colors.blueMedium} fontSize={24} height={24} width={24} />,
    warning: (
      <Icons.Warning color={colors.orangeMedium} fontSize={24} height={24} width={24} />
    ),
    danger: <Icons.Warning color={colors.redDeep} fontSize={24} height={24} width={24} />,
  }

  return (
    <HStack
      inset={{ x: 8, y: 12 }}
      space={12}
      className={cn(modeToColor(mode), "border rounded bg-white")}>
      <Flex style={{ minWidth: 32 }} align="center" justify="center">
        {hasIcon && modeToIcon[mode]}
      </Flex>

      <div>
        {typeof message === "string" ? (
          <span className="text-sm text-gray-800">{message}</span>
        ) : (
          message
        )}
      </div>
    </HStack>
  )
}

export default DisabledMessage
