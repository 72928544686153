// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import { CommentFragmentDoc } from './fragments.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IGetCommentsQueryVariables = Types.Exact<{
  where: Types.ICommentBoolExp;
}>;


export type IGetCommentsQuery = { comment: Array<{ __typename: 'comment', id: uuid, content: string, created_at: string, edited_at: string | null, attachments: Array<{ __typename: 'comment_x_upload', upload: { __typename: 'upload', id: uuid, file_name: string, file_size: number, mime_type: string, created_at: string, thumbnail_url: string | null, url: string | null } }>, created_by: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } }> };

export type IEditCommentMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  asset_id?: Types.InputMaybe<Types.Scalars['uuid']['input']>;
  work_order_id?: Types.InputMaybe<Types.Scalars['uuid']['input']>;
  content: Types.Scalars['String']['input'];
  attachment_ids: Array<Types.Scalars['uuid']['input']> | Types.Scalars['uuid']['input'];
  user_mention_ids: Types.Scalars['json']['input'];
  attachments: Array<Types.ICommentXUploadInsertInput> | Types.ICommentXUploadInsertInput;
}>;


export type IEditCommentMutation = { delete_comment_x_upload: { __typename: 'comment_x_upload_mutation_response', affected_rows: number } | null, insert_comment_one: { __typename: 'comment', id: uuid, content: string, created_at: string, edited_at: string | null, attachments: Array<{ __typename: 'comment_x_upload', upload: { __typename: 'upload', id: uuid, file_name: string, file_size: number, mime_type: string, created_at: string, thumbnail_url: string | null, url: string | null } }>, created_by: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } } | null };

export type ISendCommmentMutationVariables = Types.Exact<{
  asset_id?: Types.InputMaybe<Types.Scalars['uuid']['input']>;
  work_order_id?: Types.InputMaybe<Types.Scalars['uuid']['input']>;
  content: Types.Scalars['String']['input'];
  user_mention_ids: Types.Scalars['json']['input'];
  attachments: Array<Types.ICommentXUploadInsertInput> | Types.ICommentXUploadInsertInput;
}>;


export type ISendCommmentMutation = { insert_comment_one: { __typename: 'comment', id: uuid, content: string, created_at: string, edited_at: string | null, work_order: { __typename: 'work_order', id: uuid, comments: Array<{ __typename: 'comment', id: uuid, content: string, created_at: string, edited_at: string | null, attachments: Array<{ __typename: 'comment_x_upload', upload: { __typename: 'upload', id: uuid, file_name: string, file_size: number, mime_type: string, created_at: string, thumbnail_url: string | null, url: string | null } }>, created_by: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } }> } | null, attachments: Array<{ __typename: 'comment_x_upload', upload: { __typename: 'upload', id: uuid, file_name: string, file_size: number, mime_type: string, created_at: string, thumbnail_url: string | null, url: string | null } }>, created_by: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } } | null };

export type IDeleteCommmentMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  now: Types.Scalars['timestamptz']['input'];
}>;


export type IDeleteCommmentMutation = { update_comment_by_pk: { __typename: 'comment', id: uuid } | null };


export const GetCommentsDocument = gql`
    query GetComments($where: comment_bool_exp!) {
  comment(where: $where, order_by: {created_at: desc}) {
    ...Comment
  }
}
    ${CommentFragmentDoc}`;

export function useGetCommentsQuery(options: Omit<Urql.UseQueryArgs<IGetCommentsQueryVariables>, 'query'>) {
  return Urql.useQuery<IGetCommentsQuery, IGetCommentsQueryVariables>({ query: GetCommentsDocument, ...options });
};
export const EditCommentDocument = gql`
    mutation EditComment($id: uuid!, $asset_id: uuid, $work_order_id: uuid, $content: String!, $attachment_ids: [uuid!]!, $user_mention_ids: json!, $attachments: [comment_x_upload_insert_input!]!) {
  delete_comment_x_upload(
    where: {_and: {comment_id: {_eq: $id}, upload_id: {_nin: $attachment_ids}}}
  ) {
    affected_rows
  }
  insert_comment_one(
    object: {id: $id, asset_id: $asset_id, work_order_id: $work_order_id, user_mention_ids: $user_mention_ids, attachments: {data: $attachments, on_conflict: {constraint: comment_x_upload_comment_id_upload_id_key}}, content: $content, edited_at: "now()"}
    on_conflict: {constraint: comment_pkey, update_columns: [content, user_mention_ids, edited_at]}
  ) {
    ...Comment
  }
}
    ${CommentFragmentDoc}`;

export function useEditCommentMutation() {
  return Urql.useMutation<IEditCommentMutation, IEditCommentMutationVariables>(EditCommentDocument);
};
export const SendCommmentDocument = gql`
    mutation SendCommment($asset_id: uuid, $work_order_id: uuid, $content: String!, $user_mention_ids: json!, $attachments: [comment_x_upload_insert_input!]!) {
  insert_comment_one(
    object: {asset_id: $asset_id, work_order_id: $work_order_id, attachments: {data: $attachments}, content: $content, user_mention_ids: $user_mention_ids}
  ) {
    ...Comment
    work_order {
      id
      comments {
        ...Comment
      }
    }
  }
}
    ${CommentFragmentDoc}`;

export function useSendCommmentMutation() {
  return Urql.useMutation<ISendCommmentMutation, ISendCommmentMutationVariables>(SendCommmentDocument);
};
export const DeleteCommmentDocument = gql`
    mutation DeleteCommment($id: uuid!, $now: timestamptz!) {
  update_comment_by_pk(pk_columns: {id: $id}, _set: {deleted_at: $now}) {
    id
  }
}
    `;

export function useDeleteCommmentMutation() {
  return Urql.useMutation<IDeleteCommmentMutation, IDeleteCommmentMutationVariables>(DeleteCommmentDocument);
};