import Icons from "@resources/icons"

import Image from "./image"

export function isImage(mimeType?: string | null | undefined) {
  return mimeType?.startsWith("image")
}
export function isPdf(mimeType?: string | null | undefined) {
  return mimeType?.startsWith("application/pdf")
}

type DocumentCardIconProps = {
  folder?: boolean
  mimeType?: string
  thumbnailUrl?: string | null
  height?: number
  width?: number
  url?: string
  previewVisible: boolean
  onPreviewVisibleChange?: (visible: boolean) => void
}

export const DocumentCardIcon = (props: DocumentCardIconProps) => {
  const { height = 32, width = 32 } = props

  if (isImage(props.mimeType)) {
    const thumbnail = props?.mimeType?.includes("svg")
      ? props.url
      : props.thumbnailUrl || props.url

    return (
      <Image
        src={thumbnail}
        height={height}
        width={width}
        preview={{
          src: props.url,
        }}
        style={{ borderRadius: 4, flexShrink: 0 }}
      />
    )
  } else if (isPdf(props.mimeType)) {
    return (
      <Icons.FilePdf height={height} width={width} className="shrink-0 text-gray-700" />
    )
  } else if (props.folder) {
    return <Icons.Folder height={height} width={width} className="shrink-0 text-gray-700" />
  }

  return <Icons.File height={height} width={width} className="shrink-0 text-gray-700" />
}
