import { AssetMultiSelect } from "@components/asset"
import { Button, UserMultiSelect } from "@components/shared"
import { FormField } from "@components/shared/form/form-field"
import { RichTextFormField } from "@components/shared/form/rich-text-form-field"
import { popoverCompactTriggerClasses } from "@components/shared/multi-select"
import { SelectPopover, SelectTrigger } from "@components/shared/single-select"
import { IconSpacer } from "@components/shared/spacers"
import { TeamMultiSelect } from "@components/shared/team-select"
import { TextInput } from "@components/shared/text-input"
import toast from "@components/shared/toast"
import {
  IAssetXWorkOrderConstraint,
  IPermissionScopeEnum,
  IWorkOrderStatusEnum,
  IWorkOrderXTeamConstraint,
  IWorkOrderXUserConstraint,
  IWorkOrderXWorkOrderCategoryConstraint,
  RecurrenceInfo,
  uuid,
} from "@elara/db"
import { IWorkOrderDataViewFragment } from "@graphql/documents/fragments.generated"
import {
  IInsertSubtaskMutation,
  IInsertSubtaskMutationVariables,
  InsertSubtaskDocument,
} from "@graphql/documents/work-order.generated"
import { usePermissionScope } from "@hooks"
import { useMyTeams } from "@hooks/use-my-teams"
import i18n from "@i18n"
import { Plus } from "@phosphor-icons/react"
import { isDateOverdue, toDateString } from "@utils"
import classNames from "classnames"
import { Form, Formik, FormikProps } from "formik"
import React, { useState } from "react"
import { useClient } from "urql"
import * as yup from "yup"

import { WorkOrderCategoryMultiSelect } from "../work-order-category-select"
import { RelativeDateValue } from "../work-order-data-view-filter-configuration"
import { WorkOrderDueDatePopover } from "../work-order-due-date-popover"
import {
  statusIcon,
  translateWorkOrderStatus,
  WorkOrderStatusTag,
} from "../work-order-status"

type SubtaskFormValues = {
  name: string
  description: string | null
  status: IWorkOrderStatusEnum
  asset_ids: uuid[]
  categories: uuid[]
  assignees: uuid[]
  assigned_teams: uuid[]
  dueDate: Date | null
  dueTime: string | null
  recurrenceInfo: RecurrenceInfo | null
  reminderConfig: RelativeDateValue | null
}

export type SubtaskFormHandle = FormikProps<SubtaskFormValues>

const SubtaskFormValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required(i18n.t("common:forms.is_required", { field: i18n.t("tasks:fields.title") })),
  description: yup.string(),
  categories: yup.array().of(yup.string().uuid()).nullable(),
  assets_ids: yup.array().of(yup.string().uuid()),
  dueDate: yup.date().nullable(),
  dueTime: yup.string().nullable(),
  recurrenceInfo: yup.object().nullable(),
})

export type SubtaskFormProps = {
  parent: IWorkOrderDataViewFragment
  onCancel?: () => void
  onSubmit?: () => void
}

export const SubtaskForm = React.forwardRef<SubtaskFormHandle, SubtaskFormProps>(
  (props, forwardedRef) => {
    const client = useClient()
    const teams = useMyTeams()

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const workOrderCreateScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderCreate)

    const assignedTeams = props.parent?.assigned_teams
      .map((t) => t.team_id)
      .filter((teamId) => teams.some((t) => t.id === teamId))

    return (
      <Formik
        initialValues={{
          name: "",
          description: "",
          status: IWorkOrderStatusEnum.Open,
          asset_ids: props.parent?.assets?.map((a) => a.asset_id),
          categories: [],
          assignees: [],
          assigned_teams: assignedTeams,
          dueDate: null,
          dueTime: null,
          recurrenceInfo: null,
          reminderConfig: null,
        }}
        innerRef={forwardedRef}
        enableReinitialize
        validationSchema={SubtaskFormValidationSchema}
        validateOnBlur
        validateOnChange={false}
        onSubmit={async (values) => {
          let isSuccess = false
          try {
            setIsLoading(true)
            const res = await client
              .mutation<IInsertSubtaskMutation, IInsertSubtaskMutationVariables>(
                InsertSubtaskDocument,
                {
                  data: {
                    name: values.name,
                    description: values.description,
                    status: values.status,
                    assets: {
                      data: values.asset_ids.map((asset_id) => ({ asset_id })),
                      on_conflict: {
                        constraint: IAssetXWorkOrderConstraint.AssetXWorkOrderPkey,
                        update_columns: [],
                      },
                    },
                    categories: {
                      data: values.categories.map((category_id) => ({
                        work_order_category_id: category_id,
                      })),
                      on_conflict: {
                        constraint:
                          IWorkOrderXWorkOrderCategoryConstraint.WorkOrderXWorkOrderCategoryPkey,
                        update_columns: [],
                      },
                    },
                    assignees: {
                      data: values.assignees.map((id) => ({
                        user_id: id,
                      })),
                      on_conflict: {
                        constraint: IWorkOrderXUserConstraint.WorkOrderXUserPkey,
                      },
                    },
                    assigned_teams: {
                      data: values.assigned_teams.map((id) => ({ team_id: id })),
                      on_conflict: {
                        constraint: IWorkOrderXTeamConstraint.WorkOrderXTeamPkey,
                      },
                    },
                    due_date: toDateString(values.dueDate),
                    due_time: values.dueTime,
                    recurrence_info: values.recurrenceInfo ?? null,
                    reminder_config: values.reminderConfig,
                    parent_work_order_id: props.parent.id,
                  },
                },
                workOrderCreateScope.context()
              )
              .toPromise()

            if (res.data?.insert_work_order_one) {
              isSuccess = true
            } else {
              toast.error("Aufgabe konnte nicht erstellt werden.")
            }
          } finally {
            setIsLoading(false)
            if (isSuccess) {
              props.onSubmit?.()
            }
          }
        }}>
        {(formik) => {
          return (
            <Form className="flex !w-full flex-col text-sm">
              <FormField name="name" noStyle>
                <TextInput
                  placeholder={i18n.t("tasks:fields.task_name")}
                  autoComplete="nofill"
                  autoFocus
                  className="mb-1"
                />
              </FormField>
              <RichTextFormField
                hasErrorPlaceholder={false}
                className="mb-1"
                placeholder={i18n.t("common:enter_token", {
                  token: i18n.t("tasks:fields.description"),
                })}
                name="description"
                editorProps={{
                  attributes: { class: "min-h-[3rem] focus-visible:outline-none" },
                }}
              />
              <div className="flex flex-row flex-wrap gap-2">
                <FormField name="status" hasErrorPlaceholder={false}>
                  {({ field, helpers }) => (
                    <SelectPopover
                      {...field}
                      placeholder={i18n.t("tasks:status.none")}
                      size="small"
                      onChange={(val) => helpers.setValue(val)}
                      items={[
                        IWorkOrderStatusEnum.Planned,
                        IWorkOrderStatusEnum.Open,
                        IWorkOrderStatusEnum.InProgress,
                        IWorkOrderStatusEnum.ReadyForApproval,
                        IWorkOrderStatusEnum.Done,
                        IWorkOrderStatusEnum.OnHold,
                        IWorkOrderStatusEnum.Canceled,
                      ].map((value) => ({
                        value,
                        searchValue: translateWorkOrderStatus(value),
                        label: <WorkOrderStatusTag status={value} />,
                      }))}
                      isClearable={false}>
                      <SelectTrigger
                        className={classNames(
                          popoverCompactTriggerClasses,
                          "!border-white hover:!border-gray-200 !h-8 focus:!ring-0 !text-gray-900 flex justify-start items-center box-border"
                        )}>
                        <IconSpacer preset="compactSelect" icon={statusIcon(field.value)}>
                          <span className="truncate">
                            {translateWorkOrderStatus(field.value)}
                          </span>
                        </IconSpacer>
                      </SelectTrigger>
                    </SelectPopover>
                  )}
                </FormField>
                <FormField name="asset_ids" hasErrorPlaceholder={false}>
                  {({ field, helpers }) => (
                    <AssetMultiSelect
                      {...field}
                      onChange={(val) => helpers.setValue(val)}
                      title={i18n.t("common:asset", { count: 2 })}
                      classForTrigger="!h-8"
                      compact
                    />
                  )}
                </FormField>
                <FormField name="assigned_teams" hasErrorPlaceholder={false}>
                  {({ field, helpers }) => (
                    <TeamMultiSelect
                      {...field}
                      onChange={helpers.setValue}
                      classForTrigger="!h-8"
                      title={i18n.t("common:team", { count: 2 })}
                      compact
                    />
                  )}
                </FormField>
                <FormField name="assignees" hasErrorPlaceholder={false}>
                  {({ field, helpers }) => (
                    <UserMultiSelect
                      {...field}
                      onChange={(val) => helpers.setValue(val)}
                      classForTrigger="!h-8"
                      title={i18n.t("tasks:fields.assignee", { count: 2 })}
                      compact
                    />
                  )}
                </FormField>
                <FormField name="categories" hasErrorPlaceholder={false}>
                  {({ field, helpers }) => (
                    <WorkOrderCategoryMultiSelect
                      {...field}
                      onChange={(val) => helpers.setValue(val)}
                      classForTrigger="!h-8"
                      title={i18n.t("tasks:fields.category", { count: 2 })}
                      compact
                    />
                  )}
                </FormField>
                <WorkOrderDueDatePopover
                  showCalendarIcon={!!formik.values.dueDate}
                  popoverContentProps={{
                    align: "start",
                    side: "right",
                    sideOffset: 8,
                    alignOffset: -10,
                  }}
                  markAsOverdue={isDateOverdue(formik.values.dueDate?.toISOString())}
                  className={classNames(
                    popoverCompactTriggerClasses,
                    "!h-8 focus-within:ring-0 hover:ring-0 focus-visible:ring-gray-300 hover:!text-gray-700",
                    {
                      "font-medium": !!formik.values.dueDate,
                      "all-children:hover:text-gray-700": !formik.values.dueDate,
                    }
                  )}
                  trigger={
                    <IconSpacer icon={<Plus className="shrink-0" />} preset="compactSelect">
                      <span className="min-w-0 truncate">
                        {i18n.t("tasks:fields.due_date")}
                      </span>
                    </IconSpacer>
                  }
                  fromForm={{
                    dueDate: formik.values.dueDate
                      ? formik.values.dueDate?.toISOString()
                      : null,
                    dueTime: formik.values.dueTime,
                    recurrenceInfo: formik.values.recurrenceInfo,
                    reminderConfig: formik.values.reminderConfig,
                    onSubmit: (dueDatePopoverValues) =>
                      formik.setValues({
                        ...formik.values,
                        dueDate: dueDatePopoverValues.dueDate,
                        dueTime: dueDatePopoverValues.dueTime || null,
                        reminderConfig: dueDatePopoverValues.reminderConfig,
                        recurrenceInfo: dueDatePopoverValues.recurrenceInfo,
                      }),
                  }}
                  compact
                />
              </div>
              <div className="my-3 flex justify-end gap-x-3">
                <Button
                  htmlType="button"
                  className="self-end"
                  disabled={isLoading}
                  onClick={() => {
                    props.onCancel?.()
                  }}
                  type="tertiary"
                  size="small">
                  {i18n.t("common:cancel")}
                </Button>
                <Button
                  htmlType="submit"
                  className="self-end"
                  isLoading={isLoading}
                  size="small">
                  {i18n.t("common:save")}
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    )
  }
)

export default SubtaskForm
