import * as RadioGroup from "@radix-ui/react-radio-group"
import { cn } from "@utils"
import classNames from "classnames"
import { ReactNode } from "react"

export type RadioGroupPanelProps = {
  value?: string
  onValueChange: (value: string) => void
  options: {
    value: string
    label: ReactNode
    description?: ReactNode
    className?: string
    disabled?: boolean
  }[]
  indicatorClassName?: string
}

export function RadioGroupPanel(props: RadioGroupPanelProps) {
  return (
    <RadioGroup.Root
      value={props.value}
      onValueChange={props.onValueChange}
      orientation="vertical">
      <div className="flex flex-col space-y-3">
        {props.options.map((option) => (
          <RadioGroup.Item
            key={option.value}
            value={option.value}
            disabled={option.disabled}
            className={cn(
              "radix-state-delayed-open:border-transparent border-gray-300 hover:bg-gray-50",
              "relative block cursor-pointer rounded-lg border bg-white text-gray-900 px-4 py-3 shadow-sm focus:outline-none group text-start",
              "disabled:opacity-60 disabled:pointer-events-none",
              option.className
            )}>
            <span className={classNames("block text-sm font-medium")}>{option.label}</span>

            {option.description && (
              <div className={classNames("block text-sm text-gray-500")}>
                {option.description}
              </div>
            )}
            <RadioGroup.Indicator
              className={cn(
                "radix-state-checked:border-blue-500 border-transparent",
                "pointer-events-none border-2 absolute -inset-px rounded-lg",
                props.indicatorClassName
              )}
            />
          </RadioGroup.Item>
        ))}
      </div>
    </RadioGroup.Root>
  )
}

export default RadioGroupPanel
