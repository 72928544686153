import { Button, Icons } from "@components/shared"
import { TextInput } from "@components/shared/text-input"
import { uuid } from "@elara/db"
import {
  IInsertConsumableGroupMutation,
  IInsertConsumableGroupMutationVariables,
  InsertConsumableGroupDocument,
  IUpdateConsumableGroupMutation,
  IUpdateConsumableGroupMutationVariables,
  UpdateConsumableGroupDocument,
} from "@graphql/documents/consumable-group.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { X } from "@phosphor-icons/react"
import { px } from "@styles"
import { Formik } from "formik"
import { useState } from "react"
import { useClient } from "urql"

import { FormField } from "../../../components/shared/form/form-field"
import toast from "../../../components/shared/toast"

type ConsumableGroupCreateEditFormProps = {
  id?: uuid
  label?: string
  onCancel: () => void
  afterSubmitSuccess: () => void | Promise<void>
}

const ConsumableGroupCreateEditForm = (props: ConsumableGroupCreateEditFormProps) => {
  const client = useClient()

  const [isLoading, setIsLoading] = useState(false)

  const editScope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)
  const createScope = usePermissionScope(IPermissionScopeEnum.AppAssetCreate)

  return (
    <Formik
      initialValues={{ label: props.label ?? "" }}
      onSubmit={async (values) => {
        let isSuccessful = false

        try {
          setIsLoading(true)

          if (props.id) {
            // Edit
            const res = await client
              .mutation<
                IUpdateConsumableGroupMutation,
                IUpdateConsumableGroupMutationVariables
              >(
                UpdateConsumableGroupDocument,
                { id: props.id, group: { name: props.label } },
                editScope.context()
              )
              .toPromise()

            if (!res.data?.update_consumable_group_by_pk) {
              toast.error(
                i18n.t("common:messages.token_update_failure", {
                  token: i18n.t("consumables:fields.group", { count: 1 }),
                })
              )
            } else {
              isSuccessful = true
            }
          } else {
            // Create
            const res = await client
              .mutation<
                IInsertConsumableGroupMutation,
                IInsertConsumableGroupMutationVariables
              >(
                InsertConsumableGroupDocument,
                { name: values.label },
                createScope.context()
              )

              .toPromise()

            if (!res.data?.insert_consumable_group_one) {
              toast.error(
                i18n.t("common:messages.token_create_failure", {
                  token: i18n.t("consumables:fields.group", { count: 1 }),
                })
              )
            } else {
              isSuccessful = true
            }
          }
        } finally {
          setIsLoading(false)

          if (isSuccessful) {
            await props.afterSubmitSuccess()
          }
        }
      }}>
      {(formik) => (
        <div className="flex flex-1 items-center justify-between">
          <FormField
            name="label"
            label={i18n.t("consumables:fields.group", { count: 1 })}
            noStyle>
            <TextInput
              value={props.label}
              className="mx-2 inline-flex flex-1"
              placeholder={i18n.t("common:name")}
              autoComplete="nofill"
              autoFocus={true}
            />
          </FormField>

          <Button
            size="small"
            type="tertiary"
            icon={X}
            onClick={props.onCancel}
            style={{ marginRight: px(4), marginLeft: px(4) }}
          />

          <Button
            type="tertiary"
            icon={Icons.Check}
            isLoading={isLoading}
            disabled={!formik.values.label.length}
            size="small"
            htmlType="submit"
            onClick={formik.submitForm}
            style={{ marginRight: px(4) }}
          />
        </div>
      )}
    </Formik>
  )
}

export default ConsumableGroupCreateEditForm
