import { WorkOrderStatusTag } from "@components/work-order/work-order-status"
import { ISearchEverywhereQuery } from "@graphql/documents/search.generated"
import { Shapes } from "@phosphor-icons/react"
import Icons from "@resources/icons"
import classNames from "classnames"
import { useEffect, useRef } from "react"
import { ArrayElement } from "src/types"

export type SearchResultElement = ArrayElement<
  ISearchEverywhereQuery["search_asset"] | ISearchEverywhereQuery["search_work_order"]
>

type SearchResultProps = {
  isActive?: boolean
  result: SearchResultElement
  onClick: (result: SearchResultElement) => void
}

const SearchResult: React.FC<SearchResultProps> = ({
  result,
  onClick,
  isActive = false,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  const isAsset = result.__typename === "asset"

  useEffect(() => {
    if (isActive) {
      ref.current?.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }, [isActive])

  return (
    <div
      ref={ref}
      onClick={() => onClick(result)}
      className={classNames(
        "px-5 py-2 flex items-center gap-2 border-t cursor-pointer group hover:bg-gray-100/75 transition-colors",
        { "bg-gray-200/75 hover:bg-gray-200/75": isActive }
      )}>
      {isAsset ? <Shapes /> : <WorkOrderStatusTag short status={result.status} />}
      <h6 className="truncate text-sm font-medium">{result.name}</h6>
      {!isAsset && (
        <span className="text-sm text-gray-600">#{result.work_order_number}</span>
      )}
      {isAsset && <span className="text-sm">{result.public_id}</span>}
      {isAsset && <span className="text-sm">{result.position}</span>}
      <div className="flex-1" />
      <div
        className={classNames(
          "opacity-0 group-hover:opacity-100 cursor-pointer transition-opacity",
          { "opacity-100": isActive }
        )}>
        <Icons.Link />
      </div>
    </div>
  )
}

export default SearchResult
