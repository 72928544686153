// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ITasksDataGridQueryVariables = Types.Exact<{
  startDate: Types.Scalars['timestamptz']['input'];
  endDate: Types.Scalars['timestamptz']['input'];
}>;


export type ITasksDataGridQuery = { work_order: Array<{ __typename: 'work_order', id: uuid, name: string, description: string | null, work_order_number: number, status: Types.IWorkOrderStatusEnum, priority: Types.IWorkOrderPriorityEnum | null, due_date: string | null, created_at: string, completed_at: string | null, assets: Array<{ __typename: 'asset_x_work_order', asset: { __typename: 'asset', id: uuid, name: string } }>, completed_by: { __typename: 'user', id: uuid, first_name: string, last_name: string } | null, block_groups: Array<{ __typename: 'block_group', id: uuid, elements: Array<{ __typename: 'block_element', id: uuid, response: {} | null }> }>, comments: Array<{ __typename: 'comment', id: uuid, content: string }>, categories: Array<{ __typename: 'work_order_x_work_order_category', category: { __typename: 'work_order_category', id: uuid, label: string } }> }> };


export const TasksDataGridDocument = gql`
    query TasksDataGrid($startDate: timestamptz!, $endDate: timestamptz!) {
  work_order(
    where: {_and: [{created_at: {_gte: $startDate}}, {created_at: {_lte: $endDate}}]}
  ) {
    id
    name
    description
    work_order_number
    status
    priority
    due_date
    created_at
    completed_at
    assets {
      asset {
        id
        name
      }
    }
    completed_by {
      id
      first_name
      last_name
    }
    block_groups {
      id
      elements {
        id
        response
      }
    }
    comments {
      id
      content
    }
    categories {
      category {
        id
        label
      }
    }
  }
}
    `;

export function useTasksDataGridQuery(options: Omit<Urql.UseQueryArgs<ITasksDataGridQueryVariables>, 'query'>) {
  return Urql.useQuery<ITasksDataGridQuery, ITasksDataGridQueryVariables>({ query: TasksDataGridDocument, ...options });
};