import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area"
import { cn } from "@utils"
import classNames from "classnames"
import React from "react"

const ScrollBar = ({
  hideScrollbar,
  ...props
}: ScrollAreaPrimitive.ScrollAreaScrollbarProps & {
  hideScrollbar?: boolean
}) => (
  <ScrollAreaPrimitive.Scrollbar
    {...props}
    className={classNames(
      "z-10 flex touch-none select-none bg-gray-100 p-0.5",
      "transition duration-150 ease-out",
      "radix-orientation-horizontal:h-2.5",
      "radix-orientation-horizontal:flex-col radix-orientation-vertical:w-2.5",
      { hidden: hideScrollbar }
    )}>
    <ScrollAreaPrimitive.Thumb className="relative flex-1 rounded bg-gray-400 hover:bg-gray-600">
      {/* <TouchTargetSize /> */}
    </ScrollAreaPrimitive.Thumb>
  </ScrollAreaPrimitive.Scrollbar>
)

type ScrollAreaProps = ScrollAreaPrimitive.ScrollAreaProps &
  (
    | { horizontal: boolean; vertical?: boolean }
    | { horizontal?: boolean; vertical: boolean }
  ) & {
    hideScrollbar?: boolean
    viewportAsChild?: boolean
    horizontalClassName?: string
    viewPortClassName?: string
  }

export const ScrollArea = React.forwardRef<HTMLDivElement, ScrollAreaProps>(
  (
    {
      children,
      vertical,
      horizontal,
      hideScrollbar,
      viewportAsChild,
      horizontalClassName,
      viewPortClassName,
      ...props
    },
    forwardedRef
  ) => {
    return (
      <ScrollAreaPrimitive.Root
        {...props}
        className={classNames(
          "isolate min-w-0 min-h-0",
          { "flex flex-col flex-1": vertical },
          props.className
        )}>
        <ScrollAreaPrimitive.Viewport
          className={cn("!flex w-full flex-1 flex-col", viewPortClassName)}
          ref={forwardedRef}
          asChild={viewportAsChild}>
          {children}
        </ScrollAreaPrimitive.Viewport>
        {vertical && <ScrollBar hideScrollbar={hideScrollbar} orientation="vertical" />}
        {horizontal && (
          <ScrollBar
            orientation="horizontal"
            hideScrollbar={hideScrollbar}
            className={horizontalClassName}
          />
        )}
        <ScrollAreaPrimitive.Corner className="bg-gray-100" />
      </ScrollAreaPrimitive.Root>
    )
  }
)

export default ScrollArea
