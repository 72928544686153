import { AssetDataView } from "@components/asset/asset-data-view"
import { selectAsset } from "@components/select/asset-multi-select-dialog"
import { Button } from "@components/shared"
import { useAssetDataViewQuery } from "@graphql/documents/asset.generated"
import { useSetConsumableAssetsMutation } from "@graphql/documents/consumable.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks/use-permission-scope"
import i18n from "@i18n"
import { Plus, Shapes } from "@phosphor-icons/react"
import toast from "react-hot-toast"

import { TabProps } from "../detail"

const ObjectsTab = ({ consumable }: TabProps) => {
  const editScope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)
  const [, updateConsumable] = useSetConsumableAssetsMutation()

  const [assetDataViewRes, refetchAssets] = useAssetDataViewQuery({
    requestPolicy: "cache-first",
    variables: { where: { asset_consumables: { consumable_id: { _eq: consumable.id } } } },
  })
  const assets = assetDataViewRes.data?.asset ?? null

  const handleModifyObjects = async () => {
    if (!assets) return
    const assetIds = assets.map((asset) => asset?.id)?.filter(Boolean) ?? []
    const newAssetIds = await selectAsset({
      type: "multiple",
      initialValues: { assetIds },
    })

    if (!newAssetIds) return

    const mutationRes = await updateConsumable(
      {
        id: consumable.id,
        assetIds: newAssetIds,
        assetXConsumable: newAssetIds.map((id) => ({
          asset_id: id,
          consumable_id: consumable.id,
        })),
      },
      editScope.context()
    )

    if (mutationRes.error) {
      toast.error(i18n.t("common:generic_toast_error"))
      throw new Error("update failed")
    } else {
      refetchAssets({ requestPolicy: "network-only" })
    }
  }

  return (
    <AssetDataView
      data={assets}
      className="h-full"
      headerClassName="mt-3 mx-3"
      configId="materials:assets"
      defaultConfig={{ groupBy: null }}
      noData={{
        icon: () => <Shapes />,
        title: i18n.t("consumables:messages.objects_empty_state.title"),
        message: i18n.t("consumables:messages.objects_empty_state.description"),
        actions: (
          <Button icon={Plus} onClick={handleModifyObjects}>
            {i18n.t("common:add_token", { token: i18n.t("common:asset", { count: 1 }) })}
          </Button>
        ),
      }}
      actions={
        <Button onClick={handleModifyObjects} type="secondary">
          {i18n.t("consumables:actions.update_objects")}
        </Button>
      }
    />
  )
}

export default ObjectsTab
