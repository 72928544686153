// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import { SubtaskTagFragmentDoc } from '../../graphql/documents/fragments.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ISetTaskAsSubtaskMutationVariables = Types.Exact<{
  taskId: Types.Scalars['uuid']['input'];
  parentId: Types.Scalars['uuid']['input'];
}>;


export type ISetTaskAsSubtaskMutation = { update_work_order_by_pk: { __typename: 'work_order', id: uuid, parent_work_order: { __typename: 'work_order', id: uuid, subtasks: Array<{ __typename: 'work_order', id: uuid, name: string, status: Types.IWorkOrderStatusEnum, priority: Types.IWorkOrderPriorityEnum | null, work_order_number: number, parent_work_order_id: uuid | null, sort_order: number, reminder_config: {} | null, due_date: string | null, due_time: string | null, recurrence_info: {} | null, assignees: Array<{ __typename: 'work_order_x_user', user_id: uuid, user: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } }> }> } | null } | null };


export const SetTaskAsSubtaskDocument = gql`
    mutation SetTaskAsSubtask($taskId: uuid!, $parentId: uuid!) {
  update_work_order_by_pk(
    pk_columns: {id: $taskId}
    _set: {parent_work_order_id: $parentId}
  ) {
    id
    parent_work_order {
      id
      subtasks {
        ...SubtaskTag
      }
    }
  }
}
    ${SubtaskTagFragmentDoc}`;

export function useSetTaskAsSubtaskMutation() {
  return Urql.useMutation<ISetTaskAsSubtaskMutation, ISetTaskAsSubtaskMutationVariables>(SetTaskAsSubtaskDocument);
};