import { Button, Icons } from "@components/shared"
import { alertDialog } from "@components/shared/alert-dialog-provider"
import { FormFieldController } from "@components/shared/form/form-field-controller"
import HorizontalTabs from "@components/shared/horizontal-tabs"
import LoadingSpinner from "@components/shared/loading-spinner"
import { TextArea } from "@components/shared/text-area"
import { TextInput } from "@components/shared/text-input"
import Toggle from "@components/shared/toggle"
import WorkOrderDetail from "@components/work-order/work-order-detail"
import { IPermissionScopeEnum, IWorkOrderTemplateInsertInput } from "@elara/db"
import { IWorkOrderTemplateFragment } from "@graphql/documents/fragments.generated"
import {
  useCopyWorkOrderMutation,
  useCreateWorkOrderTemplateMutation,
  useDeleteWorkOrderTemplateMutation,
  useUpdateWorkOrderTemplateMutation,
  useWorkOrderTemplatesQuery,
} from "@graphql/documents/work-order.generated"
import { usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { Warning } from "@phosphor-icons/react"
import { useForm } from "react-hook-form"
import { Link, Navigate, useNavigate, useParams } from "react-router-dom"

const MetadataTab = (props: { template: IWorkOrderTemplateFragment }) => {
  const navigate = useNavigate()

  const { control, handleSubmit, formState, reset } = useForm({
    defaultValues: {
      name: props.template.name,
      description: props.template.description || "",
      allow_modification_in_task_form: props.template.allow_modification_in_task_form,
    },
  })

  const [, copyWorkOrder] = useCopyWorkOrderMutation()
  const [, createWorkOrderTemplate] = useCreateWorkOrderTemplateMutation()
  const [, updateWorkOrderTemplate] = useUpdateWorkOrderTemplateMutation()
  const [, deleteWorkOrderTemplate] = useDeleteWorkOrderTemplateMutation()

  const createScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderCreate)
  const editScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)

  const onDelete = async () => {
    await alertDialog({
      title: i18n.t("tasks:template.alert.title"),
      description: i18n.t("tasks:template.alert.description"),
      actionText: i18n.t("delete"),
      danger: true,
      onAction: async () => {
        await deleteWorkOrderTemplate(
          { templateId: props.template.id },
          editScope.context()
        )
        navigate(`/settings/template`)
      },
    })
  }

  const onDuplicate = async () => {
    const copyWorkOrderData = {
      id: props.template.work_order.id,
      options: {
        newWorkOrderName: `Vorlage: ${props.template.name} (Kopie)`,
        copySteps: true,
        copySubtasks: true,
        copyDocuments: true,
      },
    }

    const copyWorkOrderRes = await copyWorkOrder(copyWorkOrderData, createScope.context())
    const workOrderId = copyWorkOrderRes.data?.copyWorkOrder?.id

    if (!workOrderId) {
      throw new Error("Task could not be duplicated, ID was not returned.")
    }

    const data: IWorkOrderTemplateInsertInput = {
      name: `${props.template.name} (Kopie)`,
      description: props.template.description,
      work_order_id: workOrderId,
    }

    const createTemplateRes = await createWorkOrderTemplate({ data }, createScope.context())
    const templateId = createTemplateRes.data?.insert_work_order_template_one?.id

    if (!templateId) {
      throw new Error("Template could not be duplicated, ID was not returned.")
    }

    navigate(`/template/${templateId}`)
    // toast.success(`Vorlage "${templateName}" wurde erfolgreich kopiert.`)
  }

  const onSubmit = handleSubmit(async (data) => {
    await updateWorkOrderTemplate(
      { templateId: props.template.id, data },
      editScope.context()
    )
    reset(data)
  })

  return (
    <form onSubmit={onSubmit} className="flex max-w-md flex-col p-3">
      <FormFieldController
        control={control}
        name="name"
        label={i18n.t("common:name")}
        render={({ field }) => <TextInput {...field} />}
      />

      <FormFieldController
        control={control}
        name="description"
        label={i18n.t("common:description")}
        render={({ field }) => <TextArea rows={2} {...field} />}
      />
      <FormFieldController
        control={control}
        name="allow_modification_in_task_form"
        label={""}
        render={({ field }) => (
          <label className="space-x-2 text-sm text-gray-700">
            <Toggle checked={field.value} onChange={field.onChange} />
            <span>{i18n.t("tasks:template.allow_modification_in_task_form_label")}</span>
          </label>
        )}
      />
      {formState.isDirty && (
        <div className="mb-3 flex items-center space-x-2 rounded bg-yellow-100 px-3 py-2 text-sm text-yellow-600">
          <Warning weight="fill" />
          <span>{i18n.t("common:unsaved_changes")}</span>
        </div>
      )}

      <div className="flex items-center self-end">
        <Button
          color="red"
          type="tertiary"
          disabled={!editScope.hasScope}
          onClick={onDelete}>
          {i18n.t("common:delete")}
        </Button>

        <Button
          color="gray"
          type="tertiary"
          disabled={!createScope.hasScope}
          onClick={onDuplicate}>
          {i18n.t("common:actions.duplicate")}
        </Button>

        <Button
          type="primary"
          className="ml-2"
          htmlType="submit"
          icon={Icons.Check}
          disabled={!editScope.hasScope}
          isLoading={formState.isSubmitting}>
          {i18n.t("common:save")}
        </Button>
      </div>
    </form>
  )
}

const TemplateDetailPage = () => {
  const { templateId } = useParams()

  const [templatesQueryRes] = useWorkOrderTemplatesQuery({ requestPolicy: "cache-first" })

  if (!templateId) {
    return <Navigate to="/" />
  }

  const template = templatesQueryRes.data?.work_order_template?.find(
    (t) => t.id === templateId
  )

  if (!template) {
    return <LoadingSpinner showLogo={false} />
  }

  return (
    <div className="flex min-h-0 flex-1 flex-col bg-gray-50">
      <div className="m-3 mb-1.5">
        <h2 className="text-2xl font-bold tracking-tight text-gray-700">{template.name}</h2>
        <h3 className="mt-1 text-sm text-gray-500">
          {i18n.t("tasks:template.description")}
          <Link to={`/task/template`} className="ml-2 text-sm font-medium text-blue-600">
            {i18n.t("tasks:template.link")}
          </Link>
        </h3>
      </div>

      <div className="flex min-h-0 flex-col border border-gray-200 bg-white sm:m-3 sm:rounded">
        <HorizontalTabs.Root defaultValue="details" className=" flex min-h-0 flex-col">
          <HorizontalTabs.List>
            <HorizontalTabs.Trigger value="metadata">
              {i18n.t("information")}
            </HorizontalTabs.Trigger>
            <HorizontalTabs.Trigger value="details">
              {i18n.t("task", { count: 1 })}
            </HorizontalTabs.Trigger>
          </HorizontalTabs.List>

          <HorizontalTabs.Content value="metadata">
            <MetadataTab template={template} />{" "}
          </HorizontalTabs.Content>
          <HorizontalTabs.Content value="details" className=" flex min-h-0 flex-1 flex-col">
            <WorkOrderDetail
              isTemplate
              asPage={false}
              workOrderId={template.work_order?.id}
            />
          </HorizontalTabs.Content>
        </HorizontalTabs.Root>
      </div>
    </div>
  )
}

export default TemplateDetailPage
