import clsx from "clsx"

type StatisticProps = {
  children: React.ReactNode
  className?: string
}

const Statistic = ({ children, className }: StatisticProps) => (
  <span className={clsx("text-2xl font-bold text-gray-600", className)}>{children}</span>
)

export default Statistic
