import {
  ComboboxMultiSelect,
  ComboboxSelect,
  Item,
} from "@components/shared/combobox-select"
import LoadingIndicator from "@components/shared/loading-indicator"
import { Data } from "@elara/select"
import { useCallbackRef } from "@hooks"
import i18n from "@i18n"
import React, { useEffect, useState } from "react"
import { useClient } from "urql"

import { FilterState, SelectStateWithId } from "../data-view-types"
import { defaultValueToString, Schema, SelectSchemaStatement } from "./schema"

type FilterMenuProps<D extends Data> = {
  onClose: () => void
  listMaxHeight?: number
  schema: Schema<D>
  selectedFilter?: string | null
  onAddState?: (state: FilterState, keepOpen: boolean) => void
  filterState?: FilterState | null
  onFilterStateChange?: (state: FilterState, keepOpen: boolean) => void
}

export const FilterMenu = <D extends Data>(
  props: React.PropsWithChildren<FilterMenuProps<D>>
) => {
  const [selectedFilter, setSelectedFilter] = useState<string>(
    props.selectedFilter ?? props.filterState?.id ?? "__no_filter__"
  )

  const id = selectedFilter
  if (id !== "__no_filter__") {
    const state = props.filterState ?? {
      type: props.schema[id].type,
      id,
      selectedItems: [],
    }
    const schemaStatement = props.schema[id]
    if (state.type !== "select" || schemaStatement?.type !== "select") return null
    return (
      <SelectedFilterMenu
        schemaStatement={schemaStatement}
        state={state as SelectStateWithId}
        listMaxHeight={props.listMaxHeight}
        closeMenu={props.onClose}
        onStateChange={(state, keepOpen) => {
          if (props.filterState) {
            props.onFilterStateChange?.(state, keepOpen)
          } else {
            props.onAddState?.(state, keepOpen)
          }
        }}
      />
    )
  } else {
    const items = Object.keys(props.schema).map((id) => {
      const s = props.schema[id]
      return {
        value: id,
        label: s.label,
        searchValue: s.label,
        icon: s.icon ? <span className="text-gray-500">{s.icon}</span> : null,
      }
    })

    return (
      <ComboboxSelect
        items={items}
        listMaxHeight={props.listMaxHeight}
        placeholder={i18n.t("common:search")}
        valueToString={(v) => v}
        value={selectedFilter}
        onChange={(selectedValue) => {
          const schemaStatement = props.schema[selectedValue]
          if (schemaStatement.type === "singleton") {
            props.onAddState?.(
              { id: selectedValue, type: "singleton", negated: false },
              false
            )
            props.onClose()
          } else {
            setSelectedFilter(selectedValue)
          }
        }}
      />
    )
  }
}

type SelectedFilterMenuProps<D extends Data> = {
  schemaStatement: SelectSchemaStatement<D>
  state: SelectStateWithId
  closeMenu: () => void
  listMaxHeight?: number
  onStateChange: (state: SelectStateWithId, keepOpen: boolean) => void
}

const SelectedFilterMenu = <D extends Data>(props: SelectedFilterMenuProps<D>) => {
  const { schemaStatement, state } = props
  const [items, setItems] = useState<Item<any>[] | null>(null)
  const client = useClient()
  useEffect(() => {
    const fetchItems = async () => {
      const items = await schemaStatement.getItems?.(client)
      setItems(items)
    }
    fetchItems()
  }, [state.id])

  const onStateChange = useCallbackRef(props.onStateChange)

  if (!items)
    return (
      <div className="flex w-32 items-center justify-center p-4">
        <LoadingIndicator size={24} />
      </div>
    )
  if (schemaStatement.singleSelectOnly) {
    return (
      <ComboboxSelect
        valueToString={schemaStatement.valueToString ?? defaultValueToString}
        items={items}
        groupBy={schemaStatement.isGrouped}
        placeholder={schemaStatement.label}
        value={state?.selectedValues?.[0] ?? null}
        listMaxHeight={props.listMaxHeight}
        onCustomOnClick={async (selectedItem) => {
          props.closeMenu()
          if (schemaStatement.onCustomClick) {
            try {
              const item = await schemaStatement.onCustomClick(selectedItem.value)
              onStateChange({ ...state, selectedValues: [item.value] }, false)
            } catch {}
          }
        }}
        onChange={(value) => {
          onStateChange({ ...state, selectedValues: [value] }, false)
        }}
      />
    )
  }

  return (
    <ComboboxMultiSelect
      valueToString={schemaStatement.valueToString ?? defaultValueToString}
      items={items}
      groupBy={schemaStatement.isGrouped}
      placeholder={schemaStatement.label}
      className="max-w-xs"
      listMaxHeight={props.listMaxHeight}
      value={state?.selectedValues ?? []}
      onCustomOnClick={props.closeMenu}
      onChange={(selectedValues, _selectedItems, keepOpen) => {
        props.onStateChange({ ...state, selectedValues }, keepOpen)
      }}
    />
  )
}
