export function useSubdomain() {
  // Figure out whether we are on a subdomain
  const regex = /([\w-_]*)\.(elaratest\.de|elara\.digital|elara\.local)/g
  const match = regex.exec(window.location.hostname)
  let subdomain = "www"
  if (match && match.length >= 1) {
    subdomain = match[1]
  }
  return subdomain === "www" ? null : subdomain
}
