import { Button, LoadingIndicator } from "@components/shared"
import ScrollArea from "@components/shared/scroll-area"
import { useUser } from "@contexts/user-context"
import {
  ISubmitSetupInfoMutation,
  ISubmitSetupInfoMutationVariables,
  SubmitSetupInfoDocument,
} from "@graphql/documents/business.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { useLogout } from "@models/authentication"
import classNames from "classnames"
import { Form, Formik, FormikProps } from "formik"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useClient } from "urql"
import * as Yup from "yup"

const schema = Yup.object().shape({
  sampleData: Yup.string().required(),
})

const SetupSelectBadge = (
  props: React.PropsWithChildren<{ onClick?: () => void; isSelected?: boolean }>
) => (
  <div
    className={classNames(
      "px-8 py-4 font-medium text-center border border-gray-300 rounded cursor-pointer select-none",
      {
        "bg-blue-100 border-blue-500": props.isSelected,
        "border-gray-300 bg-gray-50 ": !props.isSelected,
      }
    )}
    onClick={props.onClick}>
    {props.children}
  </div>
)

const SetupPage = () => {
  const user = useUser()
  const client = useClient()
  const logout = useLogout()
  const navigate = useNavigate()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const scope = usePermissionScope(IPermissionScopeEnum.AppAccountManagement)

  return (
    <div className="flex min-h-0 flex-1 flex-col">
      <Formik
        initialValues={{
          role: "",
          otherRole: "",
          size: "",
          sampleData: "maintenance" as "none" | "maintenance" | "demo",
        }}
        validationSchema={schema}
        onSubmit={async (values) => {
          setIsSubmitting(true)
          const res = await client
            .mutation<ISubmitSetupInfoMutation, ISubmitSetupInfoMutationVariables>(
              SubmitSetupInfoDocument,
              {
                info: { role: values.role, otherRole: values.otherRole, size: values.size },
                locationId: user.location.id,
                data: values.sampleData === "none" ? null : values.sampleData,
              },
              scope.context()
            )
            .toPromise()
          if (!res.data?.update_location_by_pk) {
            setIsSubmitting(false)
          } else {
            // Fake that we have more work to do to give them
            // a chance to read the message properly
            setTimeout(() => {
              navigate("/")
            }, 2000)
          }
        }}>
        {(formik: FormikProps<any>) => {
          if (isSubmitting) {
            return (
              <>
                <div className="flex justify-end p-1">
                  <Button type="tertiary" color="gray">
                    {i18n.t("setup:actions.logout")}
                  </Button>
                </div>
                <div className="flex flex-1 items-center justify-center">
                  <LoadingIndicator size={24} />
                  <span className="ml-2 font-medium">
                    {i18n.t("setup:messages.setup_in_progress")}
                  </span>
                </div>
              </>
            )
          }
          return (
            <ScrollArea vertical>
              <div className="flex justify-end p-1">
                <Button type="tertiary" color="gray" onClick={logout}>
                  {i18n.t("setup:actions.logout")}
                </Button>
              </div>
              <div className="flex justify-center p-3 sm:p-6">
                <Form className="text-sm">
                  <div className="mt-6 max-w-lg">
                    <h2
                      className="max-w-2xl text-3xl font-bold"
                      dangerouslySetInnerHTML={{
                        __html: i18n.t("setup:messages.welcome", {
                          name: `${user.profile?.first_name} ${user.profile?.last_name}`,
                        }),
                      }}></h2>

                    <p className="mt-1 text-gray-600"></p>

                    <div className="mt-3 space-y-3">
                      <SetupSelectBadge
                        onClick={() => formik.setFieldValue("sampleData", "maintenance")}
                        isSelected={formik.values.sampleData === "maintenance"}>
                        {i18n.t("setup:actions.example_data_maintenance")}
                      </SetupSelectBadge>
                      <SetupSelectBadge
                        onClick={() => formik.setFieldValue("sampleData", "demo")}
                        isSelected={formik.values.sampleData === "demo"}>
                        Showcase
                        <br />
                        <span className="text-red-700">
                          {i18n.t("setup:actions.showcase_additional_users")}
                        </span>
                      </SetupSelectBadge>
                      <SetupSelectBadge
                        onClick={() => formik.setFieldValue("sampleData", "none")}
                        isSelected={formik.values.sampleData === "none"}>
                        {i18n.t("setup:actions.empty_environment")}
                      </SetupSelectBadge>
                    </div>
                  </div>

                  <div className="my-6 flex justify-center">
                    <Button
                      className="!h-12 w-32"
                      htmlType="submit"
                      disabled={!formik.isValid}>
                      {i18n.t("setup:actions.continue")}
                    </Button>
                  </div>
                </Form>
              </div>
            </ScrollArea>
          )
        }}
      </Formik>
    </div>
  )
}

export default SetupPage
