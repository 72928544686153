import ConsumableDataView from "@components/consumable/consumable-data-view"
import { selectConsumable } from "@components/select/consumable-multi-select-dialog"
import { Button } from "@components/shared"
import {
  ISetAssetConsumablesMutation,
  ISetAssetConsumablesMutationVariables,
  SetAssetConsumablesDocument,
  useConsumablesQuery,
} from "@graphql/documents/consumable.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { Nut, Plus } from "@phosphor-icons/react"
import { useClient } from "urql"

import { useAsset } from "./$id"

const ObjectMaterials = () => {
  const { asset } = useAsset()

  const [queryRes, fetchData] = useConsumablesQuery({
    variables: { where: { consumable_assets: { asset_id: { _eq: asset.id } } } },
  })
  const consumables = queryRes?.data?.consumable ?? null
  const client = useClient()
  const scope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)
  const onAddConsumable = async () => {
    const ids = await selectConsumable({
      type: "multiple",
      initialValues: {
        consumableIds: consumables?.map((c) => c.id) ?? [],
      },
    })
    if (ids) {
      await client
        .mutation<ISetAssetConsumablesMutation, ISetAssetConsumablesMutationVariables>(
          SetAssetConsumablesDocument,
          {
            assetId: asset.id,
            consumableIds: ids,
            assetXConsumable: ids.map((id) => ({
              consumable_id: id,
              asset_id: asset.id,
            })),
          },
          scope.context()
        )
        .toPromise()
      fetchData({ requestPolicy: "network-only" })
    }
  }
  return (
    <div className="mt-3 flex min-h-0 flex-1 flex-col">
      <ConsumableDataView
        headerClassName="mx-3"
        data={queryRes?.data?.consumable ?? null}
        configId="assets:materials"
        noData={{
          icon: Nut,
          title: i18n.t("assets:messages.consumables.empty_state.title"),
          message: i18n.t("assets:messages.consumables.empty_state.description"),
          actions: (
            <Button icon={Plus} onClick={onAddConsumable}>
              {i18n.t("common:add_token", {
                token: i18n.t("common:consumable", { count: 1 }),
              })}
            </Button>
          ),
        }}
        actions={
          <Button
            icon={Plus}
            onClick={onAddConsumable}
            disabled={!scope.hasScope}
            type="secondary">
            {i18n.t("assets:actions.update_consumable")}
          </Button>
        }
      />
    </div>
  )
}

export default ObjectMaterials
