// Function to convert a structured shortcode string to a JavaScript object.
export function formatShortcodeToObject(input: string): any {
  const isDigit = (char: string) => /^\d+$/.test(char) // Regex to check if a string is entirely numeric

  // Helper function to unescape string values.
  const unescapeString = (str: string) => str.replace(/\\"/g, '"').replace(/^"|"$/g, "")

  // Recursive function to parse a structured string.
  function parseValue(index: number): [any, number] {
    if (input[index] === "[") {
      let array = [] // We start by assuming we're parsing an array
      let obj: any = {}
      let useObject = false
      index++ // Skip the opening bracket

      while (input[index] !== "]") {
        let key = ""
        // Extract the key
        while (input[index] !== "=" && input[index] !== "]" && input[index] !== ",") {
          key += input[index++]
        }
        if (input[index] === "]") {
          // Handle empty object or array
          break
        }
        index++ // Skip the equals sign or comma if not at the end

        let value: any
        ;[value, index] = parseValue(index) // Parse the value

        // Check if the key is numeric and we are actually in an array context
        if (isDigit(key)) {
          array[parseInt(key, 10)] = value
        } else {
          useObject = true // Since key is not numeric, we use an object
          obj[key] = value
        }

        if (input[index] === ",") {
          index++ // Skip the comma if not at the end
        }
      }
      index++ // Skip the closing bracket

      return [useObject ? obj : array, index]
    } else if (input[index] === '"') {
      let str = ""
      index++ // Skip the opening quote
      while (input[index] !== '"') {
        if (input[index] === "\\" && input[index + 1] === '"') {
          index++ // Skip the escape character
        }
        str += input[index++]
      }
      index++ // Skip the closing quote
      return [unescapeString(str), index]
    } else {
      let literal = ""
      while (index < input.length && input[index] !== "]" && input[index] !== ",") {
        literal += input[index++]
      }
      // Determine the type of the literal
      if (literal === "null") {
        return [null, index]
      } else if (literal === "true") {
        return [true, index]
      } else if (literal === "false") {
        return [false, index]
      } else if (literal === "undefined") {
        return [undefined, index]
      } else if (!isNaN(Number(literal))) {
        return [Number(literal), index]
      } else {
        return [literal, index]
      }
    }
  }

  // Start parsing from the first character
  const [result] = parseValue(0)

  return result
}
