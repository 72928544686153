import { DataViewTabs } from "@components/shared/data-view/data-view-tabs"
import { IViewDataTypeEnum } from "@elara/db"
import i18n from "@i18n"

const ObjectArchivePage = () => (
  <DataViewTabs
    dataType={IViewDataTypeEnum.AssetArchive}
    title={i18n.t("assets:tabs.archive")}
  />
)

export default ObjectArchivePage
