import { Select, SelectProps } from "@components/shared/select"
import {
  translateWorkOrderPriority,
  WorkOrderPriorityBadge,
} from "@components/work-order/work-order-priority"
import { IServiceRequestStatusEnum, IWorkOrderPriorityEnum } from "@elara/db"
import i18n from "@i18n"
import Icons from "@resources/icons"
import { cn } from "@utils"

export function statusOrderValue(status: IServiceRequestStatusEnum) {
  switch (status) {
    case IServiceRequestStatusEnum.New:
      return 0
    case IServiceRequestStatusEnum.InProgress:
      return 1
    case IServiceRequestStatusEnum.Done:
      return 2
    case IServiceRequestStatusEnum.Rejected:
      return 3
    default:
      return 4
  }
}
export function statusIcon(status: IServiceRequestStatusEnum) {
  switch (status) {
    case IServiceRequestStatusEnum.New:
      return <Icons.ProgressPlanned className="text-gray-500" />
    case IServiceRequestStatusEnum.InProgress:
      return <Icons.ProgressInProgress className="text-blue-500" />
    case IServiceRequestStatusEnum.Done:
      return <Icons.ProgressDone className="text-green-500" />
    case IServiceRequestStatusEnum.Rejected:
      return <Icons.Rejected className="text-gray-500" />
    default:
      return ""
  }
}

export function translateServiceRequestStatus(status: IServiceRequestStatusEnum | null) {
  switch (status) {
    case IServiceRequestStatusEnum.New:
      return i18n.t("service-request:status.new")
    case IServiceRequestStatusEnum.InProgress:
      return i18n.t("service-request:status.in_progress")
    case IServiceRequestStatusEnum.Done:
      return i18n.t("service-request:status.done")
    case IServiceRequestStatusEnum.Rejected:
      return i18n.t("service-request:status.rejected")
    default:
      return ""
  }
}

const Badge = ({
  children,
  className,
}: {
  className?: string
  children: React.ReactNode
}) => (
  <span
    className={cn("rounded-xl px-2.5 py-0.5 text-xs font-bold text-green-50", className)}>
    {children}
  </span>
)

export const ServiceRequestStatusTag = ({
  status,
}: {
  status: IServiceRequestStatusEnum | null
}) => {
  switch (status) {
    case IServiceRequestStatusEnum.New:
      return (
        <Badge className="bg-gray-600 ring-gray-600">
          {translateServiceRequestStatus(IServiceRequestStatusEnum.New)}
        </Badge>
      )
    case IServiceRequestStatusEnum.InProgress:
      return (
        <Badge className="bg-blue-600 ring-blue-600">
          {translateServiceRequestStatus(IServiceRequestStatusEnum.InProgress)}
        </Badge>
      )
    case IServiceRequestStatusEnum.Done:
      return (
        <Badge className="bg-green-600 ring-green-600">
          {translateServiceRequestStatus(IServiceRequestStatusEnum.Done)}
        </Badge>
      )
    case IServiceRequestStatusEnum.Rejected:
      return (
        <Badge className="bg-red-600 ring-red-600">
          {translateServiceRequestStatus(IServiceRequestStatusEnum.Rejected)}
        </Badge>
      )
    default:
      return null
  }
}

export const ServiceRequestStatusSelect = (props: Omit<SelectProps, "items">) => (
  <Select
    {...props}
    items={[
      {
        label: translateServiceRequestStatus(IServiceRequestStatusEnum.New),
        value: IServiceRequestStatusEnum.New,
      },
      {
        label: translateServiceRequestStatus(IServiceRequestStatusEnum.InProgress),
        value: IServiceRequestStatusEnum.InProgress,
      },
      {
        label: translateServiceRequestStatus(IServiceRequestStatusEnum.Done),
        value: IServiceRequestStatusEnum.Done,
      },
      {
        label: translateServiceRequestStatus(IServiceRequestStatusEnum.Rejected),
        value: IServiceRequestStatusEnum.Rejected,
      },
    ]}>
    <ServiceRequestStatusTag status={props.value as IServiceRequestStatusEnum} />
  </Select>
)

export const ServiceRequestPrioritySelect = (props: Omit<SelectProps, "items">) => (
  <Select
    {...props}
    className={cn("border-none", props.className)}
    items={[
      {
        label: translateWorkOrderPriority(null),
        value: "",
      },
      {
        label: translateWorkOrderPriority(IWorkOrderPriorityEnum.Low),
        value: IWorkOrderPriorityEnum.Low,
      },
      {
        label: translateWorkOrderPriority(IWorkOrderPriorityEnum.Medium),
        value: IWorkOrderPriorityEnum.Medium,
      },
      {
        label: translateWorkOrderPriority(IWorkOrderPriorityEnum.High),
        value: IWorkOrderPriorityEnum.High,
      },
    ]}>
    <WorkOrderPriorityBadge priority={props.value as IWorkOrderPriorityEnum} />
  </Select>
)
