import { AssetSingleSelect } from "@components/asset/asset-select"
import { TextArea } from "@components/shared"
import { DialogForm } from "@components/shared/dialog-form"
import { FormField } from "@components/shared/form/form-field"
import Tabs from "@components/shared/horizontal-tabs"
import { SelectPopover } from "@components/shared/single-select"
import { TextInput } from "@components/shared/text-input"
import toast from "@components/shared/toast"
import Toggle from "@components/shared/toggle"
import { ToggleGroup, ToggleGroupItem } from "@components/shared/toggle-group"
import { Tooltip } from "@components/shared/tooltip"
import { IMeterInsertInput, IMeterTypeEnum } from "@elara/db"
import { useOrderBy } from "@elara/select"
import { useUpsertMeterMutation } from "@graphql/documents/asset-meter.generated"
import {
  IMeterDataViewFragment,
  IMeterFragment,
} from "@graphql/documents/fragments.generated"
import { useWorkOrderTemplatesQuery } from "@graphql/documents/work-order.generated"
import { uuid } from "@graphql/scalars"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { Info } from "@phosphor-icons/react"
import { Trans } from "react-i18next"
import { Link } from "react-router-dom"

type FormValues = {
  asset_id: uuid | null
  description: string
  first_interval_task_at: number | null
  interval: number | null
  is_deviation: boolean
  meter_type: IMeterTypeEnum | "no_type"
  name: string
  range_end: number | null
  range_start: number | null
  norm_value: number | null
  showOptions: boolean
  template_id: string | null
  unit: string
}

export type AssetAddEditMeterProps = {
  assetId?: uuid
  meter?: IMeterDataViewFragment | IMeterFragment
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  afterSubmit?: (meter: IMeterDataViewFragment) => void
}

export const AssetAddEditMeterForm = ({
  assetId,
  meter,
  isOpen,
  onOpenChange,
  afterSubmit,
}: AssetAddEditMeterProps) => {
  const [templatesQueryRes] = useWorkOrderTemplatesQuery({ requestPolicy: "cache-first" })
  const [templates] = useOrderBy(templatesQueryRes.data?.work_order_template ?? [], {
    name: "asc",
  })

  const editScope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)

  const [, upsertMeter] = useUpsertMeterMutation()

  const onSubmit = async (values: FormValues) => {
    let data: IMeterInsertInput = {
      asset_id: values.asset_id,
      description: values.description,
      id: meter?.id,
      meter_type: values.meter_type === "no_type" ? null : values.meter_type,
      name: values.name,
      template_id: values.meter_type === "no_type" ? null : values.template_id,
      unit: values.unit,
      first_interval_task_at: values.first_interval_task_at,
      interval: values.interval,
      is_deviation: values.is_deviation,
      norm_value: values.norm_value,
      range_start:
        typeof values.range_start === "number"
          ? values.is_deviation
            ? -values.range_start
            : values.range_start
          : null,
      range_end: typeof values.range_start === "number" ? values.range_end : null,
    }

    const res = await upsertMeter({ data }, editScope.context())

    if (!res.data?.insert_meter_one) {
      toast.error(`Zähler konnte nicht ${meter ? "bearbeitet" : "erstellt"} werden`)
      throw new Error("Zähler konnte nicht erstellt werden")
    } else {
      if (!meter?.id) {
        toast.success({
          title: `Zähler erstellt`,
          params: { duration: 5000, position: "bottom-right" },
          body: (
            <span>
              <Link
                to={`/object/${data.asset_id}/meter/${res.data.insert_meter_one.id}`}
                className="mr-1 text-blue-700">
                Zähler {data.name}
              </Link>

              {i18n.t("created")}
            </span>
          ),
        })
      }
      afterSubmit?.(res.data.insert_meter_one)
    }
  }

  return (
    <DialogForm
      isOpen={isOpen}
      position="top"
      positionOffset={96}
      onOpenChange={onOpenChange}
      title={
        meter
          ? i18n.t("common:edit_token", { token: i18n.t("common:meter", { count: 1 }) })
          : i18n.t("common:create_token", { token: i18n.t("common:meter", { count: 1 }) })
      }
      formikConfig={{
        initialValues: {
          asset_id: assetId ?? null,
          description: meter?.description ?? "",
          first_interval_task_at: meter?.first_interval_task_at ?? null,
          interval: meter?.interval ?? null,
          is_deviation: !!meter?.is_deviation,
          meter_type: meter?.meter_type ?? "no_type",
          name: meter?.name ?? "",
          range_end: meter?.range_end ?? null,
          range_start: meter?.range_start
            ? !!meter?.is_deviation
              ? Math.abs(meter.range_start)
              : meter.range_start
            : null,
          showOptions: !!meter?.template_id,
          template_id: meter?.template_id ?? null,
          unit: meter?.unit ?? "",
          norm_value: meter?.norm_value ?? null,
        },
        onSubmit,
        validateOnChange: false,
        validate: (values) => {
          const errs: Record<string, string> = {}
          if (!values.name) {
            errs.name = i18n.t("common:forms.is_required", {
              field: i18n.t("meters:fields.name"),
            })
          }
          if (!values.unit) {
            errs.unit = i18n.t("common:forms.is_required", {
              field: i18n.t("meters:fields.unit"),
            })
          }
          if (!values.asset_id) {
            errs.asset_id = i18n.t("common:forms.is_required", {
              field: i18n.t("common:asset", { count: 1 }),
            })
          }

          if (
            (values.meter_type === IMeterTypeEnum.Meter &&
              !(
                values.first_interval_task_at !== null &&
                values.interval &&
                values.template_id
              )) ||
            (values.meter_type === IMeterTypeEnum.Measurement &&
              values.showOptions &&
              !values.template_id)
          ) {
            errs.template_id = i18n.t("common:forms.incomplete")
          }
          return errs
        },
      }}>
      {({ values, setFieldValue, getFieldProps, errors, touched }) => {
        // Checks if first_interval_task_at has been changed in the Edit Meter form. If yes, it will display a warning.
        const first_interval_task_at_changed = (() => {
          if (meter) {
            return (
              meter?.first_interval_task_at !==
              getFieldProps("first_interval_task_at").value
            )
          }
          return false
        })()

        return (
          <div className="py-4">
            <div className="grid grid-cols-3 gap-2">
              <FormField
                name="asset_id"
                className="col-span-3"
                label={i18n.t("common:asset", { count: 1 })}>
                {({ field, helpers }) => (
                  <AssetSingleSelect
                    value={field.value}
                    isClearable={false}
                    onChange={helpers.setValue}
                  />
                )}
              </FormField>
              <FormField label={i18n.t("common:name")} name="name" className="col-span-2">
                <TextInput autoComplete="nofill" />
              </FormField>
              <FormField label={i18n.t("meters:fields.unit")} name="unit">
                <TextInput autoComplete="nofill" />
              </FormField>
              <FormField
                label={i18n.t("common:description")}
                optional
                name="description"
                className="col-span-3"
                hasErrorPlaceholder={false}>
                <TextArea
                  placeholder={i18n.t("meters:fields.placeholders.description")}
                  autoComplete="nofill"
                />
              </FormField>
            </div>
            <div className="mt-3 flex items-center text-sm font-semibold text-gray-700">
              {i18n.t("meters:fields.configuration")}{" "}
              <Tooltip
                content={
                  <Trans
                    i18n={i18n}
                    className="text-sm font-normal text-gray-600"
                    i18nKey="meters:messages.meter_type_description">
                    <p>
                      Es werden zwei Konfigurationen für Zähler unterstützt:
                      <span className="font-medium">Interval</span> und
                      <span className="font-medium">Toleranzbereich</span>.
                    </p>
                    <p>
                      Ein Beispiel für <span className="font-medium">Interval</span> sind
                      Betriebsstunden. Für einen Betriebsstundenzähler kann eine regelmäßige
                      Aufgabe hinterlegt werden wie eine Wartung alle 500 Stunden.
                    </p>
                    <p>
                      Ein Beispiel für einen
                      <span className="font-medium">Toleranzbereich</span> ist die
                      Kontrollmessung von Waagen. Es kann eine Aufgabe hinterlegt werden,
                      die erstellt wird bei Messungen außerhalb des Toleranzbereiches.
                    </p>
                  </Trans>
                }>
                <Info className="ml-1 h-5 w-5 text-gray-500" />
              </Tooltip>
            </div>
            <Tabs.Root
              value={values.meter_type}
              onValueChange={(value) =>
                setFieldValue("meter_type", value as IMeterTypeEnum)
              }>
              <ToggleGroup
                type="single"
                value={values.meter_type}
                onValueChange={(value) =>
                  value && setFieldValue("meter_type", value as IMeterTypeEnum)
                }>
                <ToggleGroupItem value={"no_type"}>
                  {i18n.t("common:without_token", {
                    token: i18n.t("meters:fields.configuration"),
                  })}
                </ToggleGroupItem>
                <ToggleGroupItem value={IMeterTypeEnum.Meter}>
                  {i18n.t("meters:fields.interval")}
                </ToggleGroupItem>
                <ToggleGroupItem value={IMeterTypeEnum.Measurement}>
                  {i18n.t("meters:fields.tolerance_range")}
                </ToggleGroupItem>
              </ToggleGroup>

              <Tabs.Content value={IMeterTypeEnum.Meter}>
                <div className="my-4 grid gap-4 rounded border p-4">
                  <div className="flex items-center text-sm text-gray-700">
                    <Trans
                      i18n={i18n}
                      i18nKey="meters:labels.interval.first_task_at"
                      values={{
                        unit: values.unit || `[${i18n.t("meters:fields.unit")}]`,
                      }}>
                      <span>Erste Aufgabe geplant bei</span>
                      <TextInput
                        type="number"
                        autoComplete="nofill"
                        className="mx-2 max-w-[6rem] flex-1 text-right"
                        {...getFieldProps("first_interval_task_at")}
                      />
                      <span>Std.</span>
                    </Trans>
                  </div>

                  <div className="text-sm text-gray-700">
                    <div className="mb-2 font-medium">
                      {i18n.t("meters:labels.interval.period.title")}
                    </div>
                    <Trans
                      i18n={i18n}
                      i18nKey="meters:labels.interval.period.content"
                      className="flex items-center gap-2"
                      values={{ unit: values.unit || `[${i18n.t("meters:fields.unit")}]` }}>
                      <span className="text-sm">Alle</span>
                      <TextInput
                        type="number"
                        autoComplete="nofill"
                        className="mx-1 max-w-[6rem] flex-1"
                        {...getFieldProps("interval")}
                      />
                      <span className="text-sm">Std.</span>
                    </Trans>
                  </div>

                  <div className="text-sm">
                    <span className="mb-2 inline-block font-medium text-gray-700">
                      {i18n.t("meters:labels.template")}
                    </span>
                    <SelectPopover<uuid>
                      {...getFieldProps("template_id")}
                      placeholder={i18n.t("common:select_token", {
                        token: i18n.t("common:template", { count: 1 }),
                      })}
                      popoverContentProps={{ align: "end" }}
                      items={templates.map((t) => ({
                        value: t.id as uuid,
                        label: t.name!,
                        searchValue: t.name!,
                      }))}
                      value={values.template_id}
                      onChange={(value) => setFieldValue("template_id", value)}
                    />
                  </div>
                  {first_interval_task_at_changed && (
                    <div className="rounded bg-yellow-100 px-3 py-2 text-sm text-yellow-800">
                      {i18n.t("meters:messages.first_interval_task_at_change")}
                    </div>
                  )}
                  {errors.template_id && touched.template_id && (
                    <div className="rounded bg-red-50 px-3 py-2 text-sm text-red-700">
                      {errors.template_id}
                    </div>
                  )}
                </div>
              </Tabs.Content>

              <Tabs.Content value={IMeterTypeEnum.Measurement}>
                <div className="my-4 grid grid-cols-3 gap-4 rounded border p-4">
                  <FormField name="is_deviation" noStyle>
                    {({ field, helpers }) => (
                      <label className="col-span-3 text-sm text-gray-700">
                        <Toggle
                          checked={field.value}
                          onChange={(e) => {
                            helpers.setValue(e.target.checked)

                            setFieldValue(
                              "range_start",
                              values.range_start !== null ? -values.range_start : null
                            )
                          }}
                        />
                        <span className="ml-2">
                          {i18n.t("meters:labels.tolerance_range.record_deviation")}
                        </span>
                      </label>
                    )}
                  </FormField>

                  <div className="col-span-3 grid grid-cols-1 gap-4">
                    {values.is_deviation ? (
                      <>
                        <FormField
                          optional
                          name="norm_value"
                          hasErrorPlaceholder={false}
                          className="whitespace-nowrap"
                          label={i18n.t("meters:fields.standard_value")}>
                          <TextInput type="number" autoComplete="nofill" />
                        </FormField>
                        <div>
                          <div className="mb-1 text-sm font-medium text-gray-700">
                            {i18n.t("meters:fields.tolerance")}
                          </div>
                          <div className="flex items-center space-x-1">
                            <span className="font-medium text-gray-600">-</span>
                            <TextInput
                              type="number"
                              autoComplete="nofill"
                              className="w-16"
                              min={0}
                              {...getFieldProps("range_start")}
                            />{" "}
                            <span className="font-medium text-gray-600">/</span>{" "}
                            <span className="font-medium text-gray-600">+</span>
                            <TextInput
                              type="number"
                              autoComplete="nofill"
                              className="w-16"
                              min={0}
                              {...getFieldProps("range_end")}
                            />
                            <span className="text-gray-600">{values.unit}</span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <FormField
                          label={i18n.t("meters:fields.min_value")}
                          name="range_start"
                          hasErrorPlaceholder={false}>
                          <div className="flex items-center">
                            <TextInput
                              type="number"
                              autoComplete="nofill"
                              className="max-w-full"
                              {...getFieldProps("range_start")}
                            />
                          </div>
                        </FormField>

                        <FormField
                          label={i18n.t("meters:fields.max_value")}
                          name="range_end"
                          hasErrorPlaceholder={false}>
                          <TextInput
                            type="number"
                            autoComplete="nofill"
                            className="max-w-full"
                            {...getFieldProps("range_end")}
                          />
                        </FormField>
                      </>
                    )}
                  </div>

                  {
                    <>
                      <label className="col-span-3 flex items-center text-sm text-gray-700">
                        <Toggle
                          checked={values.showOptions}
                          disabled={
                            values.range_start === null && values.range_end === null
                          }
                          onChange={(e) => setFieldValue("showOptions", e.target.checked)}
                        />
                        <div className="ml-2">
                          {i18n.t("meters:labels.tolerance_range.should_create_task")}
                        </div>
                      </label>
                      {values.showOptions && (
                        <div className="col-span-3 rounded border p-4">
                          <div className="col-span-3">
                            <span className="mb-2 inline-block text-sm font-medium text-gray-700">
                              {i18n.t("meters:labels.template")}
                            </span>

                            <SelectPopover<uuid>
                              {...getFieldProps("template_id")}
                              placeholder={i18n.t("common:select_token", {
                                token: i18n.t("common:template", { count: 1 }),
                              })}
                              popoverContentProps={{ align: "end" }}
                              items={templates.map((t) => ({
                                value: t.id,
                                label: t.name,
                                searchValue: t.name,
                              }))}
                              value={values.template_id}
                              onChange={(value) => setFieldValue("template_id", value)}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  }
                </div>
                {errors.template_id && touched.template_id && (
                  <div className="col-span-3 rounded bg-red-50 px-3 py-2 text-sm text-red-700">
                    {errors.template_id}
                  </div>
                )}
              </Tabs.Content>
            </Tabs.Root>
          </div>
        )
      }}
    </DialogForm>
  )
}

export default AssetAddEditMeterForm
