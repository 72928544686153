import { Flex } from "@components/layout"
import {
  ArrowClockwise,
  ArrowCounterClockwise,
  DownloadSimple,
  MagnifyingGlassMinus,
  MagnifyingGlassPlus,
  TrashSimple,
  X,
} from "@phosphor-icons/react"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import { useControllableState } from "@radix-ui/react-use-controllable-state"
import { CSSProperties, MouseEventHandler, ReactNode, useState } from "react"
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch"

const calculateRotation = (previousValue: number, increment: number) => {
  if (increment > 0 && previousValue === 180) return -90
  else if (increment < 0 && previousValue === -180) return 90
  else if (increment < 0 && previousValue === -90) return 180
  return previousValue + increment
}

const useImagePreview = () => {
  const [rotation, setRotation] = useState(0)

  return {
    rotation,
    setRotation,
  }
}

const ButtonContainer = ({
  children,
  onClick,
}: {
  children: ReactNode
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}) => {
  return (
    <div
      className="flex cursor-pointer items-center justify-center text-white hover:text-grey-4"
      style={{ height: "44px", width: "44px" }}
      onClick={onClick}>
      {children}
    </div>
  )
}

type ImagePreviewProps = {
  src: string | null | undefined
  isVisible: boolean
  hidePreview?: () => void
  onDelete?: () => void
}

const ImagePreview = (props: ImagePreviewProps & { alt?: string }) => {
  const { src, alt } = props

  const { rotation, setRotation } = useImagePreview()

  if (!props.isVisible) return null

  return (
    <TransformWrapper initialScale={1} centerZoomedOut doubleClick={{ disabled: true }}>
      {({ zoomIn, zoomOut, ..._ }) => (
        <div className="fixed inset-0 flex min-h-0 min-w-0 cursor-default flex-col bg-black/50">
          <Flex
            justify="flex-end"
            align="center"
            className="!absolute top-0 z-10 h-12 w-screen bg-black/[.2] px-2"
            row
            onClick={(e) => e.stopPropagation()}>
            <ButtonContainer onClick={() => setRotation(calculateRotation(rotation, -90))}>
              <ArrowCounterClockwise size={18} />
            </ButtonContainer>
            <ButtonContainer onClick={() => setRotation(calculateRotation(rotation, 90))}>
              <ArrowClockwise size={18} />
            </ButtonContainer>
            <ButtonContainer onClick={() => zoomIn()}>
              <MagnifyingGlassPlus size={18} />
            </ButtonContainer>
            <ButtonContainer onClick={() => zoomOut()}>
              <MagnifyingGlassMinus size={18} />
            </ButtonContainer>
            <ButtonContainer>
              <a href={`${src}?download=1`} className="flex items-center">
                <DownloadSimple size={18} className="mr-2" />
              </a>
            </ButtonContainer>

            {/* Too many modals for now */}
            {props.onDelete && (
              <ButtonContainer onClick={props.onDelete}>
                <TrashSimple size={18} />
              </ButtonContainer>
            )}

            <ButtonContainer
              onClick={(e) => {
                e.stopPropagation()
                props.hidePreview?.()
                setRotation(0)
              }}>
              <X size={18} />
            </ButtonContainer>
          </Flex>
          <TransformComponent
            // Had a bunch of problems with bugs in prod due to css source order changes and accidental overwrite
            // so let's go ballistic here...
            contentClass="!pt-12 !flex-1 !flex !flex-col !items-center !justify-center !w-full !grow !overflow-hidden"
            wrapperClass="!flex-1 !flex !flex-col !grow !w-full">
            <div className="relative flex w-full flex-1 grow flex-col items-center justify-center">
              <div
                className="absolute inset-0"
                onClick={(e) => {
                  e.stopPropagation()
                  props.hidePreview?.()
                  setRotation(0)
                }}
              />
              <img
                src={src ?? undefined}
                alt={alt}
                style={{
                  // Subtract 3rem for the h-12 height of the action bar
                  maxHeight: rotation % 180 == 0 ? "calc(100vh - 3rem)" : "100vw",
                  maxWidth: rotation % 180 == 0 ? "100vw" : "calc(100vh - 3rem)",
                  transform: `rotate(${rotation}deg)`,
                  objectFit: "contain",
                  pointerEvents: "auto",
                }}
                className="z-10 "
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          </TransformComponent>
        </div>
      )}
    </TransformWrapper>
  )
}

type ImageProps = {
  src: string | null | undefined
  preview?: {
    src: string | null | undefined
    isVisible?: boolean
    onVisibleChange?: (isVisible: boolean) => void
    onDelete?: () => void
  } | null
  alt?: string
  height?: number
  width?: number
  style?: CSSProperties
  containerStyle?: CSSProperties
  imageClasses?: string
  containerClasses?: string
  placeholder?: ReactNode
  onClick?: MouseEventHandler<HTMLImageElement>
}

const Image = (props: ImageProps) => {
  const {
    src,
    alt,
    height,
    width,
    style,
    imageClasses,
    containerClasses,
    containerStyle,
    preview,
    placeholder,
    onClick,
  } = props

  const [isPreviewVisible, onPreviewVisibleChange] = useControllableState({
    prop: props.preview?.isVisible,
    defaultProp: false,
    onChange: props.preview?.onVisibleChange,
  })

  const imageComputedClasses = `object-cover ${imageClasses ?? ""}`
  const containerComputedClasses = `cursor-zoom-in w-full h-full ${containerClasses ?? ""}`

  return (
    <DialogPrimitive.Root open={isPreviewVisible} onOpenChange={onPreviewVisibleChange}>
      <div
        className={containerComputedClasses}
        style={{ width, height, ...containerStyle }}>
        {src ? (
          <img
            className={imageComputedClasses}
            style={{ width, height, ...style }}
            src={src || undefined}
            alt={alt}
            onClick={(e) => {
              onClick?.(e)
              onPreviewVisibleChange?.(true)
            }}
          />
        ) : (
          placeholder
        )}
        {preview && isPreviewVisible && (
          <DialogPrimitive.Portal>
            <DialogPrimitive.Content
              onKeyDown={(e) => {
                if (e.key == "Escape") {
                  onPreviewVisibleChange?.(false)
                  e.stopPropagation()
                  e.preventDefault()
                }
              }}>
              <ImagePreview
                {...preview}
                isVisible={isPreviewVisible!}
                hidePreview={() => onPreviewVisibleChange?.(false)}
                alt={alt}
              />
            </DialogPrimitive.Content>
          </DialogPrimitive.Portal>
        )}
      </div>
    </DialogPrimitive.Root>
  )
}

export default Image
