import { AssetSingleSelect } from "@components/asset/asset-select"
import { TextArea } from "@components/shared"
import { DialogForm } from "@components/shared/dialog-form"
import { FormField } from "@components/shared/form/form-field"
import RadioGroupPanel from "@components/shared/radio-group-panel"
import { TextInput } from "@components/shared/text-input"
import { TextInputWithUnit } from "@components/shared/text-input-with-unit"
import toast from "@components/shared/toast"
import { IMeterInsertInput, IMeterTypeEnum } from "@elara/db"
import { useUpsertMeterMutation } from "@graphql/documents/asset-meter.generated"
import {
  IMeterDataViewFragment,
  IMeterFragment,
} from "@graphql/documents/fragments.generated"
import { uuid } from "@graphql/scalars"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { PropsWithChildren } from "react"
import { Link } from "react-router-dom"

type FormValues = {
  asset_id: uuid | null
  description: string
  meter_type: IMeterTypeEnum
  name: string
  range_start: number | null
  range_end: number | null
  unit: string
}

export type AssetAddEditMeterProps = PropsWithChildren<{
  assetId?: uuid
  meter?: IMeterDataViewFragment | IMeterFragment
  isOpen?: boolean
  onOpenChange?: (isOpen: boolean) => void
  afterSubmit?: (meter: IMeterDataViewFragment) => void
}>

export const AssetAddEditMeterForm = ({
  assetId,
  meter,
  isOpen,
  onOpenChange,
  afterSubmit,
  children,
}: AssetAddEditMeterProps) => {
  const editScope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)

  const [, upsertMeter] = useUpsertMeterMutation()

  const onSubmit = async (values: FormValues) => {
    let data: IMeterInsertInput = {
      asset_id: values.asset_id,
      description: values.description,
      id: meter?.id,
      meter_type: values.meter_type,
      name: values.name,
      unit: values.unit,
      range_start: values.range_start,
      range_end: values.range_end,
    }

    const res = await upsertMeter({ data }, editScope.context())

    if (!res.data?.insert_meter_one) {
      toast.error(`Zähler konnte nicht ${meter ? "bearbeitet" : "erstellt"} werden`)
      throw new Error("Zähler konnte nicht erstellt werden")
    } else {
      if (!meter?.id) {
        toast.success({
          title: `Zähler erstellt`,
          params: { duration: 5000, position: "bottom-right" },
          body: (
            <span>
              <Link
                to={`/object/${data.asset_id}/meter/${res.data.insert_meter_one.id}`}
                className="mr-1 text-blue-700">
                Zähler {data.name}
              </Link>

              {i18n.t("created")}
            </span>
          ),
        })
      }
      afterSubmit?.(res.data.insert_meter_one)
    }
  }

  return (
    <DialogForm
      isOpen={isOpen}
      position="top"
      trigger={children}
      positionOffset={96}
      onOpenChange={onOpenChange}
      title={
        meter
          ? i18n.t("common:edit_token", { token: i18n.t("common:meter", { count: 1 }) })
          : i18n.t("common:create_token", { token: i18n.t("common:meter", { count: 1 }) })
      }
      formikConfig={{
        initialValues: {
          asset_id: assetId ?? null,
          description: meter?.description ?? "",
          meter_type: meter?.meter_type ?? IMeterTypeEnum.Meter,
          name: meter?.name ?? "",
          unit: meter?.unit ?? "",
          range_end: meter?.range_end ?? null,
          range_start: meter?.range_start ?? null,
        },
        onSubmit,
        validateOnChange: false,
        validate: (values) => {
          const errs: Record<string, string> = {}
          if (!values.name) {
            errs.name = i18n.t("common:forms.is_required", {
              field: i18n.t("meters:fields.name"),
            })
          }
          if (!values.unit) {
            errs.unit = i18n.t("common:forms.is_required", {
              field: i18n.t("meters:fields.unit"),
            })
          }
          if (!values.asset_id) {
            errs.asset_id = i18n.t("common:forms.is_required", {
              field: i18n.t("common:asset", { count: 1 }),
            })
          }

          return errs
        },
      }}>
      {(formik) => {
        return (
          <div className="py-4">
            <div className="grid grid-cols-3 gap-2 gap-y-0">
              <FormField
                name="asset_id"
                className="col-span-3"
                label={i18n.t("common:asset", { count: 1 })}>
                {({ field, helpers }) => (
                  <AssetSingleSelect
                    value={field.value}
                    isClearable={false}
                    onChange={helpers.setValue}
                  />
                )}
              </FormField>
              <FormField label={i18n.t("common:name")} name="name" className="col-span-2">
                <TextInput autoComplete="nofill" />
              </FormField>
              <FormField label={i18n.t("meters:fields.unit")} name="unit">
                <TextInput autoComplete="nofill" />
              </FormField>
              <FormField
                label={i18n.t("common:description")}
                optional
                name="description"
                className="col-span-3">
                <TextArea autoComplete="nofill" />
              </FormField>
              <FormField
                name="meter_type"
                label={i18n.t("meters:fields.type")}
                className="col-span-full">
                {({ field, helpers }) => (
                  <RadioGroupPanel
                    value={field.value}
                    onValueChange={helpers.setValue}
                    options={[
                      {
                        value: IMeterTypeEnum.Meter,
                        label: i18n.t("meters:fields.meter_type.meter"),
                        description: i18n.t("meters:fields.meter_type.meter_description"),
                      },
                      {
                        value: IMeterTypeEnum.Measurement,
                        label: i18n.t("meters:fields.meter_type.measurement"),
                        description: i18n.t(
                          "meters:fields.meter_type.measurement_description"
                        ),
                      },
                    ]}
                  />
                )}
              </FormField>
              {formik.values.meter_type === IMeterTypeEnum.Measurement && (
                <>
                  <p className="col-span-full mb-2 text-sm text-gray-500">
                    {i18n.t("meters:labels.tolerance_range.permissible_range")}
                  </p>

                  <FormField
                    label={i18n.t("meters:fields.min_value")}
                    name="range_start"
                    className="col-span-full">
                    <TextInputWithUnit
                      type="number"
                      autoComplete="nofill"
                      unit={formik.values.unit}
                    />
                  </FormField>

                  <FormField
                    label={i18n.t("meters:fields.max_value")}
                    name="range_end"
                    className="col-span-full">
                    <TextInputWithUnit
                      type="number"
                      autoComplete="nofill"
                      unit={formik.values.unit}
                    />
                  </FormField>
                </>
              )}
            </div>
          </div>
        )
      }}
    </DialogForm>
  )
}

export default AssetAddEditMeterForm
