// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ICustomViewFragment = { __typename: 'custom_view', id: uuid, name: string, description: string | null, config: {}, data_type: Types.IViewDataTypeEnum, created_at: string, shared: boolean, icon: {} | null, shared_team_id: uuid | null };

export type IUserFavoriteFragment = { __typename: 'user_favorite', id: uuid, user_id: uuid, default_view_id: string | null, custom_view_id: uuid | null, project_id: uuid | null, asset_id: uuid | null, sort_order: number, custom_view: { __typename: 'custom_view', id: uuid, name: string, icon: {} | null } | null, project: { __typename: 'project', id: uuid, name: string, icon: {} | null } | null, asset: { __typename: 'asset', id: uuid, name: string } | null };

export type IViewConfigFragment = { __typename: 'view_config', id: uuid, data_type: Types.IViewDataTypeEnum, user_id: uuid, config: {}, config_id: string, detail_of_type: Types.IViewDataTypeEnum | null };

export type ICustomViewByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type ICustomViewByIdQuery = { custom_view_by_pk: { __typename: 'custom_view', id: uuid, name: string, description: string | null, config: {}, data_type: Types.IViewDataTypeEnum, created_at: string, shared: boolean, icon: {} | null, shared_team_id: uuid | null } | null };

export type ICustomViewsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ICustomViewsQuery = { custom_view: Array<{ __typename: 'custom_view', id: uuid, name: string, description: string | null, config: {}, data_type: Types.IViewDataTypeEnum, created_at: string, shared: boolean, icon: {} | null, shared_team_id: uuid | null }>, user_favorite: Array<{ __typename: 'user_favorite', id: uuid, user_id: uuid, default_view_id: string | null, custom_view_id: uuid | null, project_id: uuid | null, asset_id: uuid | null, sort_order: number, custom_view: { __typename: 'custom_view', id: uuid, name: string, icon: {} | null } | null, project: { __typename: 'project', id: uuid, name: string, icon: {} | null } | null, asset: { __typename: 'asset', id: uuid, name: string } | null }> };

export type IUserFavoritesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IUserFavoritesQuery = { user_favorite: Array<{ __typename: 'user_favorite', id: uuid, user_id: uuid, default_view_id: string | null, custom_view_id: uuid | null, project_id: uuid | null, asset_id: uuid | null, sort_order: number, custom_view: { __typename: 'custom_view', id: uuid, name: string, icon: {} | null } | null, project: { __typename: 'project', id: uuid, name: string, icon: {} | null } | null, asset: { __typename: 'asset', id: uuid, name: string } | null }> };

export type IViewConfigByTypeQueryVariables = Types.Exact<{
  configId: Types.Scalars['String']['input'];
}>;


export type IViewConfigByTypeQuery = { view_config: Array<{ __typename: 'view_config', id: uuid, data_type: Types.IViewDataTypeEnum, user_id: uuid, config: {}, config_id: string, detail_of_type: Types.IViewDataTypeEnum | null }> };

export type ICreateCustomViewMutationVariables = Types.Exact<{
  data: Types.ICustomViewInsertInput;
}>;


export type ICreateCustomViewMutation = { insert_custom_view_one: { __typename: 'custom_view', id: uuid, name: string, description: string | null, config: {}, data_type: Types.IViewDataTypeEnum, created_at: string, shared: boolean, icon: {} | null, shared_team_id: uuid | null } | null };

export type IDeleteCustomViewMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type IDeleteCustomViewMutation = { delete_custom_view_by_pk: { __typename: 'custom_view', id: uuid, name: string, description: string | null, config: {}, data_type: Types.IViewDataTypeEnum, created_at: string, shared: boolean, icon: {} | null, shared_team_id: uuid | null } | null };

export type IUpdateCustomViewByIdMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  data: Types.ICustomViewSetInput;
}>;


export type IUpdateCustomViewByIdMutation = { update_custom_view_by_pk: { __typename: 'custom_view', id: uuid, name: string, description: string | null, config: {}, data_type: Types.IViewDataTypeEnum, created_at: string, shared: boolean, icon: {} | null, shared_team_id: uuid | null } | null };

export type IInsertUserFavoriteMutationVariables = Types.Exact<{
  data: Types.IUserFavoriteInsertInput;
}>;


export type IInsertUserFavoriteMutation = { insert_user_favorite_one: { __typename: 'user_favorite', id: uuid, user_id: uuid, default_view_id: string | null, custom_view_id: uuid | null, project_id: uuid | null, asset_id: uuid | null, sort_order: number, custom_view: { __typename: 'custom_view', id: uuid, name: string, icon: {} | null } | null, project: { __typename: 'project', id: uuid, name: string, icon: {} | null } | null, asset: { __typename: 'asset', id: uuid, name: string } | null } | null };

export type IDeleteUserFavoriteMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type IDeleteUserFavoriteMutation = { delete_user_favorite_by_pk: { __typename: 'user_favorite', id: uuid } | null };

export type IDeleteUserFavoriteViewMutationVariables = Types.Exact<{
  userId: Types.Scalars['uuid']['input'];
  viewId: Types.Scalars['uuid']['input'];
}>;


export type IDeleteUserFavoriteViewMutation = { delete_user_favorite: { __typename: 'user_favorite_mutation_response', affected_rows: number } | null };

export type IUpdateUserFavoriteSortOrderMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  sortOrder: Types.Scalars['numeric']['input'];
}>;


export type IUpdateUserFavoriteSortOrderMutation = { update_user_favorite_by_pk: { __typename: 'user_favorite', id: uuid, sort_order: number } | null };

export type ISaveViewConfigMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['uuid']['input']>;
  dataType: Types.IViewDataTypeEnum;
  detailOfType?: Types.InputMaybe<Types.IViewDataTypeEnum>;
  config: Types.Scalars['json']['input'];
  configId: Types.Scalars['String']['input'];
}>;


export type ISaveViewConfigMutation = { insert_view_config_one: { __typename: 'view_config', id: uuid, data_type: Types.IViewDataTypeEnum, user_id: uuid, config: {}, config_id: string, detail_of_type: Types.IViewDataTypeEnum | null } | null };

export type ISaveCustomViewConfigMutationVariables = Types.Exact<{
  customViewId: Types.Scalars['uuid']['input'];
  config: Types.Scalars['json']['input'];
}>;


export type ISaveCustomViewConfigMutation = { update_custom_view_by_pk: { __typename: 'custom_view', id: uuid, name: string, description: string | null, config: {}, data_type: Types.IViewDataTypeEnum, created_at: string, shared: boolean, icon: {} | null, shared_team_id: uuid | null } | null };

export type IDeleteViewConfigMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type IDeleteViewConfigMutation = { delete_view_config_by_pk: { __typename: 'view_config', id: uuid } | null };

export const CustomViewFragmentDoc = gql`
    fragment CustomView on custom_view {
  id
  name
  description
  config
  data_type
  created_at
  shared
  icon
  shared_team_id
}
    `;
export const UserFavoriteFragmentDoc = gql`
    fragment UserFavorite on user_favorite {
  id
  user_id
  default_view_id
  custom_view_id
  custom_view {
    id
    name
    icon
  }
  project_id
  project {
    id
    name
    icon
  }
  asset_id
  asset {
    id
    name
  }
  sort_order
}
    `;
export const ViewConfigFragmentDoc = gql`
    fragment ViewConfig on view_config {
  id
  data_type
  user_id
  config
  config_id
  detail_of_type
}
    `;
export const CustomViewByIdDocument = gql`
    query CustomViewById($id: uuid!) {
  custom_view_by_pk(id: $id) {
    ...CustomView
  }
}
    ${CustomViewFragmentDoc}`;

export function useCustomViewByIdQuery(options: Omit<Urql.UseQueryArgs<ICustomViewByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<ICustomViewByIdQuery, ICustomViewByIdQueryVariables>({ query: CustomViewByIdDocument, ...options });
};
export const CustomViewsDocument = gql`
    query CustomViews {
  custom_view {
    ...CustomView
  }
  user_favorite {
    ...UserFavorite
  }
}
    ${CustomViewFragmentDoc}
${UserFavoriteFragmentDoc}`;

export function useCustomViewsQuery(options?: Omit<Urql.UseQueryArgs<ICustomViewsQueryVariables>, 'query'>) {
  return Urql.useQuery<ICustomViewsQuery, ICustomViewsQueryVariables>({ query: CustomViewsDocument, ...options });
};
export const UserFavoritesDocument = gql`
    query UserFavorites {
  user_favorite {
    ...UserFavorite
  }
}
    ${UserFavoriteFragmentDoc}`;

export function useUserFavoritesQuery(options?: Omit<Urql.UseQueryArgs<IUserFavoritesQueryVariables>, 'query'>) {
  return Urql.useQuery<IUserFavoritesQuery, IUserFavoritesQueryVariables>({ query: UserFavoritesDocument, ...options });
};
export const ViewConfigByTypeDocument = gql`
    query ViewConfigByType($configId: String!) {
  view_config(where: {config_id: {_eq: $configId}}) {
    ...ViewConfig
  }
}
    ${ViewConfigFragmentDoc}`;

export function useViewConfigByTypeQuery(options: Omit<Urql.UseQueryArgs<IViewConfigByTypeQueryVariables>, 'query'>) {
  return Urql.useQuery<IViewConfigByTypeQuery, IViewConfigByTypeQueryVariables>({ query: ViewConfigByTypeDocument, ...options });
};
export const CreateCustomViewDocument = gql`
    mutation CreateCustomView($data: custom_view_insert_input!) {
  insert_custom_view_one(object: $data) {
    ...CustomView
  }
}
    ${CustomViewFragmentDoc}`;

export function useCreateCustomViewMutation() {
  return Urql.useMutation<ICreateCustomViewMutation, ICreateCustomViewMutationVariables>(CreateCustomViewDocument);
};
export const DeleteCustomViewDocument = gql`
    mutation DeleteCustomView($id: uuid!) {
  delete_custom_view_by_pk(id: $id) {
    ...CustomView
  }
}
    ${CustomViewFragmentDoc}`;

export function useDeleteCustomViewMutation() {
  return Urql.useMutation<IDeleteCustomViewMutation, IDeleteCustomViewMutationVariables>(DeleteCustomViewDocument);
};
export const UpdateCustomViewByIdDocument = gql`
    mutation UpdateCustomViewById($id: uuid!, $data: custom_view_set_input!) {
  update_custom_view_by_pk(pk_columns: {id: $id}, _set: $data) {
    ...CustomView
  }
}
    ${CustomViewFragmentDoc}`;

export function useUpdateCustomViewByIdMutation() {
  return Urql.useMutation<IUpdateCustomViewByIdMutation, IUpdateCustomViewByIdMutationVariables>(UpdateCustomViewByIdDocument);
};
export const InsertUserFavoriteDocument = gql`
    mutation InsertUserFavorite($data: user_favorite_insert_input!) {
  insert_user_favorite_one(object: $data) {
    ...UserFavorite
  }
}
    ${UserFavoriteFragmentDoc}`;

export function useInsertUserFavoriteMutation() {
  return Urql.useMutation<IInsertUserFavoriteMutation, IInsertUserFavoriteMutationVariables>(InsertUserFavoriteDocument);
};
export const DeleteUserFavoriteDocument = gql`
    mutation DeleteUserFavorite($id: uuid!) {
  delete_user_favorite_by_pk(id: $id) {
    id
  }
}
    `;

export function useDeleteUserFavoriteMutation() {
  return Urql.useMutation<IDeleteUserFavoriteMutation, IDeleteUserFavoriteMutationVariables>(DeleteUserFavoriteDocument);
};
export const DeleteUserFavoriteViewDocument = gql`
    mutation DeleteUserFavoriteView($userId: uuid!, $viewId: uuid!) {
  delete_user_favorite(where: {user_id: $userId, custom_view_id: $viewId}) {
    affected_rows
  }
}
    `;

export function useDeleteUserFavoriteViewMutation() {
  return Urql.useMutation<IDeleteUserFavoriteViewMutation, IDeleteUserFavoriteViewMutationVariables>(DeleteUserFavoriteViewDocument);
};
export const UpdateUserFavoriteSortOrderDocument = gql`
    mutation UpdateUserFavoriteSortOrder($id: uuid!, $sortOrder: numeric!) {
  update_user_favorite_by_pk(
    pk_columns: {id: $id}
    _set: {sort_order: $sortOrder}
  ) {
    id
    sort_order
  }
}
    `;

export function useUpdateUserFavoriteSortOrderMutation() {
  return Urql.useMutation<IUpdateUserFavoriteSortOrderMutation, IUpdateUserFavoriteSortOrderMutationVariables>(UpdateUserFavoriteSortOrderDocument);
};
export const SaveViewConfigDocument = gql`
    mutation SaveViewConfig($id: uuid, $dataType: view_data_type_enum!, $detailOfType: view_data_type_enum, $config: json!, $configId: String!) {
  insert_view_config_one(
    object: {id: $id, data_type: $dataType, detail_of_type: $detailOfType, config: $config, config_id: $configId}
    on_conflict: {constraint: view_config_pkey, update_columns: config}
  ) {
    ...ViewConfig
  }
}
    ${ViewConfigFragmentDoc}`;

export function useSaveViewConfigMutation() {
  return Urql.useMutation<ISaveViewConfigMutation, ISaveViewConfigMutationVariables>(SaveViewConfigDocument);
};
export const SaveCustomViewConfigDocument = gql`
    mutation SaveCustomViewConfig($customViewId: uuid!, $config: json!) {
  update_custom_view_by_pk(
    pk_columns: {id: $customViewId}
    _set: {config: $config}
  ) {
    ...CustomView
  }
}
    ${CustomViewFragmentDoc}`;

export function useSaveCustomViewConfigMutation() {
  return Urql.useMutation<ISaveCustomViewConfigMutation, ISaveCustomViewConfigMutationVariables>(SaveCustomViewConfigDocument);
};
export const DeleteViewConfigDocument = gql`
    mutation DeleteViewConfig($id: uuid!) {
  delete_view_config_by_pk(id: $id) {
    id
  }
}
    `;

export function useDeleteViewConfigMutation() {
  return Urql.useMutation<IDeleteViewConfigMutation, IDeleteViewConfigMutationVariables>(DeleteViewConfigDocument);
};