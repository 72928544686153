import { Item } from "@components/shared/combobox-select"
import {
  MultiSelectPopover,
  MultiSelectPopoverProps,
  MultiSelectSummary,
} from "@components/shared/multi-select"
import { SelectPopover, SelectPopoverProps } from "@components/shared/single-select"
import { uuid } from "@elara/db"
import {
  useAssetSelectDataQuery,
  useInsertAssetMutation,
} from "@graphql/documents/asset.generated"
import { IAssetSelectDataFragment } from "@graphql/documents/fragments.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { Shapes } from "@phosphor-icons/react"
import { buildAssetsTree } from "@utils/build-trees"
import { TreeLike } from "@utils/tree"
import { ReactNode, useMemo, useState } from "react"
import toast from "react-hot-toast"
import { v4 } from "uuid"

export type SelectItem = TreeLike<{
  value: uuid
  searchValue: string
  label: ReactNode
  children?: SelectItem[]
}>

export const useAssetSelect = (
  args: {
    value?: uuid[]
    recommendedItems?: uuid[]
    onChange?: (value: uuid[]) => void
    assetIsSelectable?: (asset: IAssetSelectDataFragment) => boolean
  } = {}
) => {
  const { onChange, assetIsSelectable } = args
  const [queryResult] = useAssetSelectDataQuery({ requestPolicy: "cache-first" })

  const [, insertAsset] = useInsertAssetMutation()
  const createAssetScope = usePermissionScope(IPermissionScopeEnum.AppAssetCreate)

  const [assetName, setAssetName] = useState<string | null>(null)
  const [assetId, setAssetId] = useState<uuid | null>(null)
  const [newAsset, setNewAsset] = useState<SelectItem[]>([])

  const always = () => true

  const options: SelectItem[] = useMemo(() => {
    const assets = (queryResult.data?.asset ?? [])
      .filter(assetIsSelectable ?? always)
      .filter((a) => !a.archived_at)
    return buildAssetsTree(assets)
  }, [queryResult.data?.asset, assetIsSelectable])

  const items: SelectItem[] = useMemo(() => {
    if (args.recommendedItems && args.recommendedItems.length > 0) {
      const recommendedItems = (queryResult.data?.asset ?? [])
        .filter(assetIsSelectable ?? always)
        .filter((a) => !a.archived_at)
        .filter((a) => args.recommendedItems!.includes(a.id))
        .map((a) => ({
          label: (
            <span>
              {a.name} <span className="text-gray-500">{a.public_id}</span>{" "}
              {a.place && <span className="text-gray-500">{a.place?.name}</span>}
            </span>
          ),
          value: a.id,
          searchValue: `${a.name} ${a.public_id} ${a.place?.name ?? ""}`.trim(),
        }))

      return [
        {
          value: "recommended",
          searchValue: "recommended",
          label: i18n.t("assets:select.recommended"),
          children: recommendedItems,
        },
        {
          value: "all",
          searchValue: "all",
          label: i18n.t("assets:select.all"),
          children: options,
        },
      ]
    }

    return options
  }, [args.recommendedItems, options])

  const onCreate = async (inputValue: string) => {
    const id = v4()
    setAssetName(inputValue)
    setAssetId(id)
    newAsset?.push({
      value: id,
      label: <span>{inputValue}</span>,
      searchValue: `${inputValue}`,
    })
    return id
  }

  const onChangeDuringAssetCreation = async () => {
    try {
      if (assetId && assetName) {
        const res = await insertAsset(
          { data: { id: assetId, name: assetName } },
          createAssetScope.context()
        )

        if (res.error) {
          toast.error(i18n.t("assets:messages.create_error"))
        } else {
          onChange?.([assetId].concat(args.value ?? []))
        }
      }
    } finally {
      setAssetId(null)
      setNewAsset([])
    }
  }

  return {
    assetId,
    assetName,
    newAsset,
    items,
    groupBy: args.recommendedItems?.length ? true : false,
    onCreate,
    onChangeDuringAssetCreation,
    valueToString: (s: string) => s,
    title: i18n.t("common:asset", { count: 1 }),
    placeholder: i18n.t("common:select_token", {
      token: i18n.t("common:asset", { count: 1 }),
    }),
    renderCompactSelectedValues: (values: TreeLike<Item<string>>[]) => (
      <MultiSelectSummary
        firstItemLabel={values[0]?.searchValue}
        countLabel={i18n.t("common:asset", { count: 2 })}
        limit={3}
        mode="count"
        items={values}
        itemsToIcons={() => <Shapes className="!text-gray-700" />}
        iconBgClass="bg-gray-100"
        iconBorderClass="border-gray-100"
      />
    ),
  }
}

export type AssetSingleSelectProps = Partial<SelectPopoverProps<string>> & {
  recommendedItems?: uuid[]
  assetIsSelectable?: (asset: IAssetSelectDataFragment) => boolean
}

export function AssetSingleSelect({
  assetIsSelectable,
  recommendedItems,
  ...props
}: AssetSingleSelectProps) {
  const selectProps = useAssetSelect({ recommendedItems, assetIsSelectable })
  return <SelectPopover {...selectProps} {...props} />
}

export type AssetMultiSelectProps = Partial<MultiSelectPopoverProps<string>> & {
  recommendedItems?: uuid[]
  assetIsSelectable?: (asset: IAssetSelectDataFragment) => boolean
}

export function AssetMultiSelect({ recommendedItems, ...props }: AssetMultiSelectProps) {
  const selectProps = useAssetSelect({
    value: props.value,
    onChange: props.onChange,
    recommendedItems,
  })

  const { assetId, items, newAsset, onChangeDuringAssetCreation } = selectProps

  return (
    <MultiSelectPopover
      {...selectProps}
      {...props}
      items={assetId ? newAsset : items}
      retainedItems={assetId ? items : undefined}
      onChange={assetId ? onChangeDuringAssetCreation : props.onChange}
    />
  )
}
