// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IAssetsWithOperatingScheduleQueryVariables = Types.Exact<{
  assetGroupId: Types.Scalars['uuid']['input'];
}>;


export type IAssetsWithOperatingScheduleQuery = { asset_group_by_pk: { __typename: 'asset_group', id: uuid, name: string, assets: Array<{ __typename: 'asset', id: uuid, operating_schedules: Array<{ __typename: 'asset_operating_schedule', id: uuid, schedule: {}, valid_from: string, valid_until: string | null }> }> } | null };

export type IAvailabilityDataGridQueryVariables = Types.Exact<{
  startDate: Types.Scalars['timestamptz']['input'];
  endDate: Types.Scalars['timestamptz']['input'];
}>;


export type IAvailabilityDataGridQuery = { asset_state_log: Array<{ __typename: 'asset_state_log', id: uuid, started_at: string, ended_at: string | null, asset: { __typename: 'asset', id: uuid, name: string, group: { __typename: 'asset_group', id: uuid, name: string } | null } }> };


export const AssetsWithOperatingScheduleDocument = gql`
    query AssetsWithOperatingSchedule($assetGroupId: uuid!) {
  asset_group_by_pk(id: $assetGroupId) {
    id
    name
    assets {
      id
      operating_schedules(limit: 1, order_by: {valid_from: desc}) {
        id
        schedule
        valid_from
        valid_until
      }
    }
  }
}
    `;

export function useAssetsWithOperatingScheduleQuery(options: Omit<Urql.UseQueryArgs<IAssetsWithOperatingScheduleQueryVariables>, 'query'>) {
  return Urql.useQuery<IAssetsWithOperatingScheduleQuery, IAssetsWithOperatingScheduleQueryVariables>({ query: AssetsWithOperatingScheduleDocument, ...options });
};
export const AvailabilityDataGridDocument = gql`
    query AvailabilityDataGrid($startDate: timestamptz!, $endDate: timestamptz!) {
  asset_state_log(
    where: {_and: [{started_at: {_gte: $startDate}}, {started_at: {_lte: $endDate}}]}
  ) {
    id
    started_at
    ended_at
    asset {
      id
      name
      group {
        id
        name
      }
    }
  }
}
    `;

export function useAvailabilityDataGridQuery(options: Omit<Urql.UseQueryArgs<IAvailabilityDataGridQueryVariables>, 'query'>) {
  return Urql.useQuery<IAvailabilityDataGridQuery, IAvailabilityDataGridQueryVariables>({ query: AvailabilityDataGridDocument, ...options });
};