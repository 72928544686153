import LoadingSpinner from "@components/shared/loading-spinner"
import { Select } from "@components/shared/select"
import { WorkOrderActivitySection } from "@components/work-order/work-order-activity-section"
import { IWorkOrderFragment } from "@graphql/documents/fragments.generated"
import { useStickyState } from "@hooks"
import i18n from "@i18n"
import { CommentField } from "@pages/new-task.$id.comments/comment-field"
import { useOutletContext } from "react-router-dom"

type ActivityDir = "newest-first" | "oldest-first"

export default function TaskComments() {
  const { task } = useOutletContext<{ task: IWorkOrderFragment }>()
  const [activityDir, setActivityDir] = useStickyState<ActivityDir>(
    "oldest-first",
    "task-detail-activity-dir"
  )

  if (!task) return <LoadingSpinner />

  return (
    <div className="px-3">
      <div className="mx-auto max-w-screen-md space-y-3 pt-3">
        <div className="mb-4 flex w-full items-center justify-between print:hidden">
          <h3 className="font-semibold">{i18n.t("common:activity", { count: 2 })}</h3>
          <Select
            className="w-min whitespace-nowrap pr-2"
            value={activityDir}
            onValueChange={(v) => setActivityDir(v as ActivityDir)}
            items={[
              {
                value: "newest-first",
                label: i18n.t("tasks:activity.sort_by.newest_first"),
              },
              {
                value: "oldest-first",
                label: i18n.t("tasks:activity.sort_by.oldest_first"),
              },
            ]}
          />
        </div>
        <WorkOrderActivitySection workOrder={task} order={activityDir} hideActivityForm />
        <div className="sticky bottom-0 z-10 border-t border-gray-200 bg-white/90 py-3 backdrop-blur-sm print:hidden">
          <CommentField workOrderId={task.id} activityDir={activityDir} />
        </div>
      </div>
    </div>
  )
}
