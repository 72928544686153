import { useConsumableSelect } from "@components/consumable/select/consumable-select"
import { Button } from "@components/shared"
import { ComboboxMultiSelect, ComboboxSelect } from "@components/shared/combobox-select"
import { DialogForm } from "@components/shared/dialog-form"
import { scanCode } from "@components/shared/qrcode/scanner/scanner-dialog-provider"
import { useDisclosure, UseDisclosureReturnType } from "@hooks"
import i18n from "@i18n"
import { Scan } from "@phosphor-icons/react"
import { useEffect, useState } from "react"

type EventDetails = {
  type: "single" | "multiple"
  initialValues?: { consumableIds: string[] }
  onSelect: (consumableIds: string[]) => void
  onCancel: () => void
}

const ConsumableMultiSelectDialogForm = (props: {
  disclosure: UseDisclosureReturnType
  eventProps: EventDetails
}) => {
  const dialog = props.disclosure
  const { eventProps } = props
  const selectProps = useConsumableSelect()
  const [counter, setCounter] = useState(0)

  const handleScanQRCode = async () => {
    const url = await scanCode()
    if (!url) return

    // regex match consumable/:uuid in url
    const code = url.match(/consumable\/([a-z0-9-]+)/)?.[1]
    if (!code) return

    return selectProps.items.find((item) => item.value === code)
  }

  return (
    <DialogForm
      className="sm:max-w-2xl"
      isOpen={dialog.isOpen}
      onOpenChange={dialog.changeOpen}
      okText={i18n.t("common:select")}
      okProps={{ icon: undefined }}
      formikConfig={{
        initialValues: eventProps.initialValues ?? { consumableIds: [] },
        onSubmit: (values) => {
          eventProps.onSelect(values.consumableIds)
        },
      }}>
      {(formik) => {
        const onScan = async () => {
          const item = await handleScanQRCode()

          if (!item) return

          formik.setFieldValue("consumableIds", [
            ...formik.values.consumableIds,
            item.value,
          ])
          setCounter((c) => c + 1)
        }

        if (eventProps.type === "single") {
          return (
            <ComboboxSelect
              {...selectProps}
              key={counter}
              placeholder={i18n.t("consumables:labels.search_for_material")}
              inputProps={{
                className:
                  "flex items-center gap-2 py-0.5 pr-1 border rounded mb-4 sticky top-0 bg-white z-10",
                endAdornment: (
                  <Button icon={Scan} color="gray" type="tertiary" onClick={onScan}>
                    {i18n.t("qrcode:actions.scan")}
                  </Button>
                ),
              }}
              value={formik.values.consumableIds[0]}
              onChange={(value) => {
                formik.setFieldValue("consumableIds", [value])
                eventProps.onSelect([value])
                dialog.changeOpen(false)
              }}
            />
          )
        }

        return (
          <ComboboxMultiSelect
            {...selectProps}
            key={counter}
            placeholder={i18n.t("consumables:labels.search_for_material")}
            inputProps={{
              className:
                "flex items-center gap-2 py-0.5 pr-1 border rounded mb-4 sticky top-0 bg-white z-10",
              endAdornment: (
                <Button icon={Scan} color="gray" type="tertiary" onClick={onScan}>
                  {i18n.t("qrcode:actions.scan")}
                </Button>
              ),
            }}
            value={formik.values.consumableIds}
            onChange={(value) => formik.setFieldValue("consumableIds", value)}
          />
        )
      }}
    </DialogForm>
  )
}

const ConsumableMultiSelectDialog = () => {
  const dialog = useDisclosure()

  const [eventProps, setEventProps] = useState<EventDetails>({
    type: "multiple",
    initialValues: { consumableIds: [] },
    onSelect: () => {},
    onCancel: () => {},
  })

  useEffect(() => {
    const listener = (e: Event) => {
      if (e instanceof CustomEvent && e.detail) {
        dialog.changeOpen(true)
        setEventProps(e.detail)
      }
    }

    window.addEventListener("select-consumable", listener)

    return () => window.removeEventListener("select-consumable", listener)
  }, [])

  return <ConsumableMultiSelectDialogForm disclosure={dialog} eventProps={eventProps} />
}

export default ConsumableMultiSelectDialog

type SelectConsumableProps = {
  type?: "single" | "multiple"
  initialValues?: { consumableIds: string[] }
}

export const selectConsumable = async (props: SelectConsumableProps = {}) =>
  new Promise<string[] | null>((resolve) => {
    window.dispatchEvent(
      new CustomEvent<EventDetails>("select-consumable", {
        detail: {
          type: props.type ?? "multiple",
          initialValues: props.initialValues,
          onSelect(result: string[]) {
            resolve(result)
          },
          onCancel() {
            resolve(null)
          },
        },
      })
    )
  })
