// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IAssetCustomFieldNamesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IAssetCustomFieldNamesQuery = { assetCustomFieldNames: { __typename: 'AssetCustomFieldNamesData', names: Array<string> } | null };

export type IMaintenanceTriggersQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.IWorkOrderBoolExp>;
}>;


export type IMaintenanceTriggersQuery = { maintenance_trigger: Array<{ __typename: 'maintenance_trigger', id: uuid, type: Types.IMaintenanceTriggerTypeEnum, maintenance_id: uuid, payload: {} | null, maintenance: { __typename: 'maintenance', id: uuid, name: string, description: string | null, paused: boolean } | null }> };

export type IConsumablesCountQueryVariables = Types.Exact<{
  where: Types.IConsumableBoolExp;
}>;


export type IConsumablesCountQuery = { consumable_aggregate: { __typename: 'consumable_aggregate', aggregate: { __typename: 'consumable_aggregate_fields', count: number } | null } };

export type IConsumableGroupsCountQueryVariables = Types.Exact<{
  where: Types.IConsumableBoolExp;
}>;


export type IConsumableGroupsCountQuery = { consumable_group: Array<{ __typename: 'consumable_group', id: uuid, name: string, consumables_aggregate: { __typename: 'consumable_aggregate', aggregate: { __typename: 'consumable_aggregate_fields', count: number } | null } }>, without_group: { __typename: 'consumable_aggregate', aggregate: { __typename: 'consumable_aggregate_fields', count: number } | null } };

export type IMaintenancesCountQueryVariables = Types.Exact<{
  where: Types.IMaintenanceBoolExp;
}>;


export type IMaintenancesCountQuery = { maintenance_aggregate: { __typename: 'maintenance_aggregate', aggregate: { __typename: 'maintenance_aggregate_fields', count: number } | null } };

export type IMetersCountQueryVariables = Types.Exact<{
  where: Types.IMeterBoolExp;
}>;


export type IMetersCountQuery = { meter_aggregate: { __typename: 'meter_aggregate', aggregate: { __typename: 'meter_aggregate_fields', count: number } | null } };

export type IMetersGroupyByAssetSummaryQueryVariables = Types.Exact<{
  where: Types.IMeterBoolExp;
}>;


export type IMetersGroupyByAssetSummaryQuery = { asset: Array<{ __typename: 'asset', id: uuid, name: string, group: { __typename: 'asset_group', id: uuid, name: string } | null, meters_aggregate: { __typename: 'meter_aggregate', aggregate: { __typename: 'meter_aggregate_fields', count: number } | null } }> };


export const AssetCustomFieldNamesDocument = gql`
    query AssetCustomFieldNames {
  assetCustomFieldNames {
    names
  }
}
    `;

export function useAssetCustomFieldNamesQuery(options?: Omit<Urql.UseQueryArgs<IAssetCustomFieldNamesQueryVariables>, 'query'>) {
  return Urql.useQuery<IAssetCustomFieldNamesQuery, IAssetCustomFieldNamesQueryVariables>({ query: AssetCustomFieldNamesDocument, ...options });
};
export const MaintenanceTriggersDocument = gql`
    query MaintenanceTriggers($where: work_order_bool_exp) {
  maintenance_trigger(
    where: {type: {_eq: time}, maintenance: {paused: {_eq: false}, template: $where}}
  ) {
    id
    type
    maintenance_id
    maintenance {
      id
      name
      description
      paused
    }
    payload
  }
}
    `;

export function useMaintenanceTriggersQuery(options?: Omit<Urql.UseQueryArgs<IMaintenanceTriggersQueryVariables>, 'query'>) {
  return Urql.useQuery<IMaintenanceTriggersQuery, IMaintenanceTriggersQueryVariables>({ query: MaintenanceTriggersDocument, ...options });
};
export const ConsumablesCountDocument = gql`
    query ConsumablesCount($where: consumable_bool_exp!) {
  consumable_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

export function useConsumablesCountQuery(options: Omit<Urql.UseQueryArgs<IConsumablesCountQueryVariables>, 'query'>) {
  return Urql.useQuery<IConsumablesCountQuery, IConsumablesCountQueryVariables>({ query: ConsumablesCountDocument, ...options });
};
export const ConsumableGroupsCountDocument = gql`
    query ConsumableGroupsCount($where: consumable_bool_exp!) {
  consumable_group {
    id
    name
    consumables_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  without_group: consumable_aggregate(
    where: {_and: [$where, {group_id: {_is_null: true}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

export function useConsumableGroupsCountQuery(options: Omit<Urql.UseQueryArgs<IConsumableGroupsCountQueryVariables>, 'query'>) {
  return Urql.useQuery<IConsumableGroupsCountQuery, IConsumableGroupsCountQueryVariables>({ query: ConsumableGroupsCountDocument, ...options });
};
export const MaintenancesCountDocument = gql`
    query MaintenancesCount($where: maintenance_bool_exp!) {
  maintenance_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

export function useMaintenancesCountQuery(options: Omit<Urql.UseQueryArgs<IMaintenancesCountQueryVariables>, 'query'>) {
  return Urql.useQuery<IMaintenancesCountQuery, IMaintenancesCountQueryVariables>({ query: MaintenancesCountDocument, ...options });
};
export const MetersCountDocument = gql`
    query MetersCount($where: meter_bool_exp!) {
  meter_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

export function useMetersCountQuery(options: Omit<Urql.UseQueryArgs<IMetersCountQueryVariables>, 'query'>) {
  return Urql.useQuery<IMetersCountQuery, IMetersCountQueryVariables>({ query: MetersCountDocument, ...options });
};
export const MetersGroupyByAssetSummaryDocument = gql`
    query MetersGroupyByAssetSummary($where: meter_bool_exp!) {
  asset(where: {archived_at: {_is_null: true}}) {
    id
    name
    group {
      id
      name
    }
    meters_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
}
    `;

export function useMetersGroupyByAssetSummaryQuery(options: Omit<Urql.UseQueryArgs<IMetersGroupyByAssetSummaryQueryVariables>, 'query'>) {
  return Urql.useQuery<IMetersGroupyByAssetSummaryQuery, IMetersGroupyByAssetSummaryQueryVariables>({ query: MetersGroupyByAssetSummaryDocument, ...options });
};