import { useBreakpoint } from "@contexts/breakpoints"
import classNames from "classnames"
import React from "react"

export type TextAreaProps = { hasError?: boolean; size?: "small" | "large" } & Omit<
  JSX.IntrinsicElements["textarea"],
  "size"
>

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ size: givenSize, hasError, ...props }, forwardedRef) => {
    const bp = useBreakpoint()
    const size = givenSize ?? (bp.sm ? "small" : "large")

    return (
      <textarea
        {...props}
        ref={forwardedRef}
        className={classNames(
          props.className,
          "border focus:ring-1 rounded px-2 align-middle",
          {
            "text-base p-1": size === "large",
            "text-sm p-1": size === "small",
            "border-gray-200 focus:ring-blue-700 focus:border-blue-700 placeholder:text-gray-400 ":
              !hasError,
            "border-red-700 focus:ring-red-700 focus:border-red-700 placeholder:text-red-300":
              hasError,
          }
        )}
      />
    )
  }
)

export default TextArea
