import { ReactComponent as SteeringWheel } from "@resources/team-icons/001-steering wheel.svg"
import { ReactComponent as OilBarrel } from "@resources/team-icons/002-oil barrel.svg"
import { ReactComponent as Electricity } from "@resources/team-icons/006-electricity.svg"
import { ReactComponent as Train } from "@resources/team-icons/006-train.svg"
import { ReactComponent as Car } from "@resources/team-icons/008-car.svg"
import { ReactComponent as Fire } from "@resources/team-icons/009-fire.svg"
import { ReactComponent as Airplane } from "@resources/team-icons/014-airplane.svg"
import { ReactComponent as Helicopter } from "@resources/team-icons/015-helicopter.svg"
import { ReactComponent as SolarPanel } from "@resources/team-icons/015-solar-panel.svg"
import { ReactComponent as GolfCart } from "@resources/team-icons/016-golf cart.svg"
import { ReactComponent as WindPower } from "@resources/team-icons/016-wind-power.svg"
import { ReactComponent as Forklift } from "@resources/team-icons/017-forklift.svg"
import { ReactComponent as Board } from "@resources/team-icons/021-board.svg"
import { ReactComponent as Rocket } from "@resources/team-icons/022-rocket.svg"
import { ReactComponent as SailingBoat } from "@resources/team-icons/025-sailing boat.svg"
import { ReactComponent as HotAirBalloon } from "@resources/team-icons/030-hot air balloon.svg"
import { ReactComponent as Scale } from "@resources/team-icons/065-scale.svg"
import { ReactComponent as Flask } from "@resources/team-icons/079-flask.svg"
import { ReactComponent as Battery } from "@resources/team-icons/battery.svg"
import { ReactComponent as Biohazard } from "@resources/team-icons/biohazard.svg"
import { ReactComponent as Briefcase } from "@resources/team-icons/briefcase.svg"
import { ReactComponent as Broom } from "@resources/team-icons/broom.svg"
import { ReactComponent as Calculator } from "@resources/team-icons/calculator.svg"
import { ReactComponent as ChargingStation } from "@resources/team-icons/charging-station.svg"
import { ReactComponent as ChartSimple } from "@resources/team-icons/chart-simple.svg"
import { ReactComponent as ChessKnight } from "@resources/team-icons/chess-knight.svg"
import { ReactComponent as CubeIcon } from "@resources/team-icons/cube.svg"
import { ReactComponent as Cutlery } from "@resources/team-icons/cutlery.svg"
import { ReactComponent as Display } from "@resources/team-icons/display.svg"
import { ReactComponent as Droplet } from "@resources/team-icons/droplet.svg"
import { ReactComponent as Fan } from "@resources/team-icons/fan.svg"
import { ReactComponent as Gamepad } from "@resources/team-icons/gamepad.svg"
import { ReactComponent as Gear } from "@resources/team-icons/gear.svg"
import { ReactComponent as Hammer } from "@resources/team-icons/hammer.svg"
import { ReactComponent as Helmet } from "@resources/team-icons/helmet.svg"
import { ReactComponent as Industry } from "@resources/team-icons/industry.svg"
import { ReactComponent as Lens } from "@resources/team-icons/lens.svg"
import { ReactComponent as ListCheck } from "@resources/team-icons/list-check.svg"
import { ReactComponent as OilCan } from "@resources/team-icons/oil-can.svg"
import { ReactComponent as Pencil } from "@resources/team-icons/pencil.svg"
import { ReactComponent as Recycle } from "@resources/team-icons/recycle.svg"
import { ReactComponent as ScrewDriverWrench } from "@resources/team-icons/screwdriver-wrench.svg"
import { ReactComponent as Seeding } from "@resources/team-icons/seeding.svg"
import { ReactComponent as Truck } from "@resources/team-icons/truck.svg"
import { ReactComponent as UserGroupIcon } from "@resources/team-icons/user-group.svg"
import { cn } from "@utils"

export type IconData = {
  name: string
  color: string
} | null

export const teamIcons = {
  UserGroupIcon,
  Gear,
  Gamepad,
  SteeringWheel,
  Board,
  Flask,
  Biohazard,
  Recycle,
  Battery,
  Seeding,
  Droplet,
  Fire,
  Electricity,
  WindPower,
  SolarPanel,
  ChargingStation,
  OilBarrel,
  OilCan,
  Car,
  Forklift,
  GolfCart,
  Truck,
  Train,
  SailingBoat,
  HotAirBalloon,
  Helicopter,
  Airplane,
  Rocket,
  // Brush,
  Broom,
  Pencil,
  Scale,
  Hammer,
  ScrewDriverWrench,
  CubeIcon,
  Fan,
  Cutlery,
  Helmet,
  ChessKnight,
  Lens,
  Calculator,
  ListCheck,
  ChartSimple,
  Briefcase,
  Display,
  Industry,
  // Motorcycle,
  // Biogas,
  // Process,
  // Calculate,
  // Skateboard,
  // RacingCar,
  // Zeppelin,
  // Sun,
  //Caravan,
  // Hoverboard,
  // Joystick,
  //Submarine,
  //SaltShaker,
  //Spatula,
  //Film,
  //Tool,
  //Chess,
  //Tape,
}

export const teamIconColors = [
  "#525252",
  "#656fce",
  "#2563eb",
  "#9333ea",
  "#e11d48",
  "#f97316",
  "#eab308",
  "#65a30d",
  "#0d9488",
]

export const DEFAULT_TEAM_ICON_NAME = "UserGroupIcon"
export const DEFAULT_TEAM_ICON_COLOR = teamIconColors[0]
export const DEFAULT_FAVORITE_ICON_NAME = "Display"

export const TeamIcon = (props: {
  name?: string | null
  className?: string
  color?: string | null
}) => {
  const Icon = teamIcons[(props.name ?? DEFAULT_TEAM_ICON_NAME) as keyof typeof teamIcons]

  if (Icon) {
    return (
      <Icon
        className={cn("h-[1em] w-[1em]", props.className)}
        style={{ color: props.color ?? DEFAULT_TEAM_ICON_COLOR }}
      />
    )
  }

  return null
}

export const randomIcon = () => {
  const keys = Object.keys(teamIcons) as Array<keyof typeof teamIcons>
  const name = keys[Math.floor(Math.random() * keys.length)]
  const color = teamIconColors[Math.floor(Math.random() * teamIconColors.length)]
  return { name, color }
}
