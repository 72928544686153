import {
  IMutationRootDeleteContactByPkArgs,
  IMutationRootInsertContactOneArgs,
} from "@elara/db"
import {
  ContactsDocument,
  IContactsQuery,
  IContactsQueryVariables,
} from "@graphql/documents/contact.generated"
import { IContactFragment } from "@graphql/documents/fragments.generated"
import { OptimisticMutationResolver, UpdateResolver } from "@urql/exchange-graphcache"

type Mutations = {
  [fieldName: string]: UpdateResolver
}
type Optimistic = {
  [fieldName: string]: OptimisticMutationResolver
}

const optimistic: Optimistic = {}

const mutations: Mutations = {
  insert_contact_one(
    result: { insert_contact_one: IContactFragment },
    args: IMutationRootInsertContactOneArgs,
    cache
  ) {
    const contact = result.insert_contact_one

    if (!contact) return

    cache.updateQuery<IContactsQuery, IContactsQueryVariables>(
      {
        query: ContactsDocument,
      },
      (data) => {
        if (!data || !data.contact) return data
        if (!data.contact.some((c) => c.id === contact?.id)) {
          data.contact.push(contact)
          data.contact.sort((a, b) => a.name.localeCompare(b.name))
        }
        return data
      }
    )
  },
  delete_contact_by_pk(_parent, args: IMutationRootDeleteContactByPkArgs, cache) {
    cache.updateQuery<IContactsQuery>({ query: ContactsDocument }, (data) => {
      if (data) {
        data.contact = data.contact.filter((c) => c.id !== args.id)
      }
      return data
    })
  },
}

export default {
  optimistic,
  mutations,
}
