import { ArrowSquareOut } from "@phosphor-icons/react"
import { isLink } from "@utils"

type DetectExternalLinkProps = {
  text: string | null | number
  className?: string
}

export const DetectExternalLink = (props: DetectExternalLinkProps) => {
  const text = props.text
  if (typeof text === "string") {
    const textIsLink = isLink(text)

    if (textIsLink || text.startsWith("www.")) {
      let href = text
      if (!textIsLink) {
        href = "https://" + href
      }

      return (
        <span className="inline-flex items-center">
          <a href={href} target="_blank" rel="noreferrer" className="truncate underline">
            {props.text}
          </a>
          <ArrowSquareOut className="inline-block shrink-0" />
        </span>
      )
    }
  }

  return <span className={props.className}>{props.text}</span>
}
