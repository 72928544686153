import { BlockElementConsumables, uuid } from "@elara/db"
import { IBlockElementTypeEnum } from "@elara/db/src/db.generated"
import { Control } from "react-hook-form"
import { v4 } from "uuid"

import { AssetStateForm } from "./elements/block-element-asset-state"
import { CheckboxForm } from "./elements/block-element-checkbox"
import { ChoiceForm } from "./elements/block-element-choice"
import { ConsumablesForm as NewConsumableForm } from "./elements/block-element-consumable-list"
import { HeadingForm } from "./elements/block-element-heading"
import { InspectionForm } from "./elements/block-element-inspection"
import { MediaForm } from "./elements/block-element-media"
import { MeterReadingForm } from "./elements/block-element-meter-reading"
import { ParagraphForm } from "./elements/block-element-paragraph"
import { TextFieldForm } from "./elements/block-element-textfield"
import { ToleranceCheckForm } from "./elements/block-element-tolerance-check"
import { BlockElementFormItem } from "./elements/block-element-types"
import { YesNoForm } from "./elements/block-element-yes-no"

export function cleanBlockElement(
  e: BlockElementFormItem,
  _assetIds: uuid[] = []
): BlockElementFormItem {
  if (e.element_type === IBlockElementTypeEnum.MeterReading) {
    return { ...e, config: { ...e.config, meter_reading_id: v4() }, id: v4() }
  } else if (e.element_type === IBlockElementTypeEnum.AssetState) {
    return {
      ...e,
      config: {
        ...e.config,
        // The block state element supports to select an option "current asset" that tries to automatically prefill the correct asset
        // We can set the value then simply to null since the element form logic kicks in and selects automatically an asset
        asset_id: (e.config.asset_id === "current_asset"
          ? null
          : e.config.asset_id) as unknown as string,
      },
      id: v4(),
    }
  } else if (e.element_type === IBlockElementTypeEnum.Consumables) {
    const e_ = e as unknown as BlockElementConsumables
    return {
      ...e,
      id: v4(),
      config: {
        ...e.config,
        consumables: e_.config.consumables.map((c) => ({
          ...c,
          consumable_log_id: v4(),
        })),
      },
    }
  } else {
    return { ...e, id: v4() }
  }
}

export function BlockElementForm(props: {
  index: number
  element: BlockElementFormItem
  control: Control<any>
  field: string
  isTemplate: boolean
  selectedAssetIds: string[]
}) {
  const elementType = props.element.element_type

  const elementProps = {
    index: props.index,
    field: props.field,
    control: props.control,
    isTemplate: props.isTemplate,
    selectedAssetIds: props.selectedAssetIds,
  }
  switch (props.element.element_type) {
    case IBlockElementTypeEnum.AssetState:
      return <AssetStateForm {...elementProps} addCurrentAsset={props.isTemplate} />
    case IBlockElementTypeEnum.Consumables:
      return <NewConsumableForm {...elementProps} />
    case IBlockElementTypeEnum.Checkbox:
      return <CheckboxForm {...elementProps} />
    case IBlockElementTypeEnum.Text:
      return <TextFieldForm {...elementProps} />
    case IBlockElementTypeEnum.Heading:
      return <HeadingForm {...elementProps} />
    case IBlockElementTypeEnum.Paragraph:
      return <ParagraphForm {...elementProps} />
    case IBlockElementTypeEnum.Choice:
      return <ChoiceForm {...elementProps} />
    case IBlockElementTypeEnum.Inspection:
      return <InspectionForm {...elementProps} />
    case IBlockElementTypeEnum.MeterReading:
      return <MeterReadingForm {...elementProps} />
    case IBlockElementTypeEnum.YesNo:
      return <YesNoForm {...elementProps} />
    case IBlockElementTypeEnum.Media:
      return <MediaForm {...elementProps} />
    case IBlockElementTypeEnum.ToleranceCheck:
      return <ToleranceCheckForm {...elementProps} />

    default:
      throw new Error("Unexpected type: " + elementType)
  }
}
