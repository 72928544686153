import { Link, LinkProps, Location, useLocation, useNavigate } from "react-router-dom"
export type LocationState = { backgroundLocation: Location } | null

export const useNavigateWithBackgroundLocation = () => {
  const location = useLocation()
  const navigate = useNavigate()

  return (to: string) =>
    navigate(to, {
      state: {
        backgroundLocation:
          (location.state as LocationState)?.backgroundLocation ?? location,
      },
    })
}

export const LinkWithBackgroundLocation = (props: LinkProps) => {
  const location = useLocation()

  return (
    <Link
      {...props}
      state={{
        backgroundLocation:
          (location.state as LocationState)?.backgroundLocation ?? location,
      }}
    />
  )
}
