import i18n from "@i18n"
import { useCallback, useState } from "react"

import Button from "./button"
import { Dialog, DialogClose, DialogContentFooter } from "./dialog"
import { TextInput } from "./text-input"

interface UserInputConfirmationDialogProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  title: string
  message: string
  okText: string
  cancelText: string
}

export const UserInputConfirmationDialog = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  okText,
  cancelText,
}: UserInputConfirmationDialogProps) => {
  const [inputValue, setInputValue] = useState("")

  return (
    <Dialog
      contentAsChild
      className="max-w-md"
      title={title}
      isOpen={isOpen}
      onOpenChange={onClose}>
      <p className="py-3 text-sm text-gray-600">{message}</p>
      <TextInput
        placeholder={i18n.t("settings:import.confirmation_placeholder", { okText: okText })}
        className="mb-3"
        defaultValue=""
        onChange={(e) => setInputValue(e.target.value)}
      />
      <DialogContentFooter className="flex justify-end space-x-3">
        <DialogClose asChild>
          <Button onClick={onClose} type="secondary">
            {cancelText}
          </Button>
        </DialogClose>
        <Button color="red" disabled={inputValue.trim() !== okText} onClick={onConfirm}>
          {okText}
        </Button>
      </DialogContentFooter>
    </Dialog>
  )
}

interface UseUserInputConfirmationDialogParams {
  title: string
  message: string
  okText: string
  cancelText: string
  onConfirm: () => void
}

export const useUserInputConfirmationDialog = ({
  title,
  message,
  onConfirm,
  okText,
  cancelText,
}: UseUserInputConfirmationDialogParams) => {
  const [isOpen, setIsOpen] = useState(false)

  const show = useCallback(() => {
    setIsOpen(true)
  }, [])

  const close = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleConfirm = useCallback(() => {
    close()
    onConfirm()
  }, [close, onConfirm])

  const component = (
    <UserInputConfirmationDialog
      isOpen={isOpen}
      onClose={close}
      onConfirm={handleConfirm}
      title={title}
      message={message}
      okText={okText}
      cancelText={cancelText}
    />
  )

  return {
    show,
    component,
  }
}

export default UserInputConfirmationDialog
