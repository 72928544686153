import {
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuRoot,
  ContextMenuTrigger,
} from "@components/shared/context-menu"
import { ISubtaskTagFragment } from "@graphql/documents/fragments.generated"
import { Draggable } from "@hello-pangea/dnd"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { DotsSixVertical, Trash } from "@phosphor-icons/react"
import { useNavigateWithBackgroundLocation } from "@utils/location"
import classNames from "classnames"
import React from "react"
import { useClient } from "urql"

import { WorkOrderDueDatePopover } from "../work-order-due-date-popover"
import { WorkOrderNumber } from "../work-order-number"
import WorkOrderStatusDropdown from "../work-order-status"
import {
  DeleteSubtaskDocument,
  IDeleteSubtaskMutation,
  IDeleteSubtaskMutationVariables,
  IRemoveSubtaskMutation,
  IRemoveSubtaskMutationVariables,
  RemoveSubtaskDocument,
} from "./action.generated"

type SubtaskItemProps = {
  subtask: ISubtaskTagFragment
  index: number
}

export const SubtaskItem = ({
  index,
  subtask,
}: React.PropsWithChildren<SubtaskItemProps>) => {
  const client = useClient()
  const edit = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)
  const deleteScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderDelete)
  const navigate = useNavigateWithBackgroundLocation()
  return (
    <Draggable draggableId={index.toString()} index={index} key={index}>
      {(provided, snapshot) => (
        <ContextMenuRoot>
          <ContextMenuTrigger asChild>
            <div className="group" onClick={() => navigate(`/task/${subtask.id}`)}>
              <div
                ref={provided.innerRef}
                className={classNames(
                  "group relative flex items-center py-1 pr-2 bg-white rounded hover:bg-gray-100 cursor-pointer text-sm select-none group-radix-state-open:bg-gray-100 ",
                  {
                    "bg-gray-100": snapshot.isDragging,
                  }
                )}
                {...provided.draggableProps}
                {...provided.dragHandleProps}>
                <div className="mx-0.5 box-border flex h-6 items-center justify-center rounded text-gray-400 hover:text-gray-700">
                  <div className="h-[1em] w-[1em] group-hover:hidden" />
                  <DotsSixVertical
                    weight="bold"
                    className="hidden h-[1em] w-[1em] cursor-grabbing group-hover:flex"
                  />
                </div>
                <div className="flex flex-1 items-center justify-between">
                  <div className={classNames("relative flex items-center min-w-0 gap-x-2")}>
                    <div
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}>
                      <WorkOrderStatusDropdown workOrder={subtask} short />
                    </div>
                    <WorkOrderNumber workOrderNumber={subtask.work_order_number} />{" "}
                    <span className="font-medium">{subtask.name}</span>
                  </div>

                  <div
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}>
                    <WorkOrderDueDatePopover workOrder={subtask} />
                  </div>
                </div>
              </div>
            </div>
          </ContextMenuTrigger>
          <ContextMenuContent>
            <ContextMenuItem
              onSelect={async () => {
                await client
                  .mutation<IRemoveSubtaskMutation, IRemoveSubtaskMutationVariables>(
                    RemoveSubtaskDocument,
                    { subtaskId: subtask.id, parentTaskId: subtask.parent_work_order_id! },
                    edit.context()
                  )
                  .toPromise()
                await client
                  .mutation<IDeleteSubtaskMutation, IDeleteSubtaskMutationVariables>(
                    DeleteSubtaskDocument,
                    { subtaskId: subtask.id },
                    deleteScope.context()
                  )
                  .toPromise()
              }}>
              <Trash className="mr-2" /> {i18n.t("tasks:actions.delete_subtask")}
            </ContextMenuItem>
          </ContextMenuContent>
        </ContextMenuRoot>
      )}
    </Draggable>
  )
}
