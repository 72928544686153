import { HStack } from "@components/layout"
import { CheckboxInput } from "@components/shared/form/checkbox-input"
import { RichTextFormController } from "@components/shared/form/rich-text-form-controller"
import { StaticRichText } from "@components/shared/static-rich-text"
import { BlockElementCheckbox } from "@elara/db"
import i18n from "@i18n"
import { ChangeEvent } from "react"

import { BlockElementFormProps, BlockElementProps } from "./block-element-types"

export const CheckboxForm = (props: BlockElementFormProps) => {
  return (
    <HStack align="center" space={8} flex="1">
      <CheckboxInput disabled />

      <RichTextFormController
        className="max-h-36"
        placeholder={i18n.t("tasks:checklist.checkbox.placeholder")}
        name={`${props.field}.${props.index}.config.description`}
        control={props.control}
        noStyle
      />
    </HStack>
  )
}

export const CheckboxBlockElement = (props: BlockElementProps<BlockElementCheckbox>) => {
  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    props.updateResponse?.(props.element.id, e.target.checked ? { checked: true } : null)
  }

  return (
    <label className="flex flex-1 cursor-pointer select-none items-center">
      <CheckboxInput
        checked={props.element.response?.checked || false}
        onChange={onChange}
      />
      <StaticRichText className="ml-2" content={props.element.config.description} />
    </label>
  )
}
