import { ServiceRequestStatusTag } from "@components/service-request/status"
import WorkOrderCategoryQuickEdit from "@components/work-order/work-order-category-quick-edit"
import { WorkOrderDueDatePopover } from "@components/work-order/work-order-due-date-popover"
import { WorkOrderPriorityInfoSelect } from "@components/work-order/work-order-priority-info-select"
import { WorkOrderQuickAssigneeSelect } from "@components/work-order/work-order-quick-assignee-select"
import { WorkOrderQuickCollaboratorSelect } from "@components/work-order/work-order-quick-collaborator-select"
import { WorkOrderQuickTeamSelect } from "@components/work-order/work-order-quick-team-select"
import {
  IWorkOrderDataViewFragment,
  IWorkOrderFragment,
} from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { TaskDescription } from "@pages/new-task.$id.details/description"
import TaskDocuments from "@pages/new-task.$id.details/documents"
import { TaskObjects } from "@pages/new-task.$id.details/objects"
import { cn } from "@utils"
import { Link, useOutletContext } from "react-router-dom"

import { Subtasks } from "./subtasks"

const InfoLabel = (props: React.PropsWithChildren<{ className?: string }>) => (
  <div className="flex min-h-[32px] w-full place-items-center">
    <span className={cn("text-sm font-medium text-gray-700", props.className)}>
      {props.children}
    </span>
  </div>
)

const InfoValue = (props: React.PropsWithChildren<{ className?: string }>) => (
  <div className="inline-flex items-center">
    <div
      className={cn(
        "inline-flex min-h-[32px] items-center justify-start ",
        props.className
      )}>
      {props.children}
    </div>
  </div>
)

export default function TaskIdDetails(props: { template?: boolean }) {
  const { dataViewTask, task } = useOutletContext<{
    task: IWorkOrderFragment | null
    dataViewTask: IWorkOrderDataViewFragment
  }>()
  const workOrder = dataViewTask
  return (
    <div className="flex-1 bg-white px-3">
      <div className=" mx-auto box-border max-w-screen-md pt-4">
        <div>
          <TaskDescription task={dataViewTask} />
          {task && <TaskDocuments task={task} documents={task.documents} />}
          {task && !props.template && <Subtasks task={task} />}
        </div>
        <hr className="col-span-full my-2" />
        <TaskObjects workOrder={workOrder} />
        <hr className="col-span-full my-2" />

        <div className="grid grid-cols-[auto_1fr] items-center justify-start gap-x-3 gap-y-2 text-sm">
          <InfoLabel>{i18n.t("common:team", { count: 1 })}</InfoLabel>
          <InfoValue>
            <WorkOrderQuickTeamSelect
              workOrder={workOrder}
              popoverContentProps={{
                side: "bottom",
                sideOffset: 8,
                align: "start",
                alignOffset: 0,
              }}
            />
          </InfoValue>
          <InfoLabel>{i18n.t("tasks:fields.assignee", { count: 1 })}</InfoLabel>
          <InfoValue>
            <WorkOrderQuickAssigneeSelect
              workOrder={workOrder}
              popoverContentProps={{
                side: "bottom",
                sideOffset: 8,
                align: "start",
                alignOffset: 0,
              }}
            />
          </InfoValue>
          <InfoLabel>{i18n.t("tasks:fields.collaborator", { count: 1 })}</InfoLabel>
          <InfoValue>
            <WorkOrderQuickCollaboratorSelect
              workOrder={workOrder}
              popoverContentProps={{
                side: "bottom",
                sideOffset: 8,
                align: "start",
                alignOffset: 0,
              }}
            />
          </InfoValue>

          <hr className="col-span-full" />
          <InfoLabel>{i18n.t("tasks:fields.priority")}</InfoLabel>
          <InfoValue className="">
            <WorkOrderPriorityInfoSelect workOrder={workOrder} />
          </InfoValue>

          <InfoLabel>{i18n.t("tasks:fields.category", { count: 1 })}</InfoLabel>
          <InfoValue>
            <WorkOrderCategoryQuickEdit
              workOrder={workOrder}
              popoverContentProps={{
                side: "bottom",
                sideOffset: 8,
                align: "start",
                alignOffset: 0,
              }}
            />
          </InfoValue>

          <InfoLabel>{i18n.t("tasks:fields.due_date")}</InfoLabel>
          <InfoValue>
            <WorkOrderDueDatePopover
              workOrder={workOrder}
              textColor="lighter"
              showRecurrenceInfo
              popoverContentProps={{
                side: "bottom",
                sideOffset: 8,
                align: "start",
                alignOffset: 0,
              }}
              className={cn("font-medium min-h-[32px]", {
                "text-gray-700": workOrder.due_date,
                "text-gray-500": !workOrder.due_date,
              })}
            />
          </InfoValue>

          {task?.previous_work_order?.id && (
            <>
              <InfoLabel>{i18n.t("tasks:previous_task")}</InfoLabel>
              <InfoValue>
                <Link
                  to={`/task/` + task?.previous_work_order?.id}
                  className="rounded px-2 py-1 font-medium text-gray-500 hover:border-gray-700 hover:bg-gray-100 hover:text-gray-700">
                  <span>#{task?.previous_work_order.work_order_number}</span>{" "}
                </Link>
              </InfoValue>
            </>
          )}

          {task?.next_work_order?.id && (
            <>
              <InfoLabel>{i18n.t("tasks:next_task")}</InfoLabel>
              <InfoValue>
                <Link
                  to={`/task/` + task?.next_work_order?.id}
                  className="rounded px-2 py-1 font-medium text-gray-500 hover:border-gray-700 hover:bg-gray-100 hover:text-gray-700">
                  <span>#{task?.next_work_order.work_order_number}</span>
                </Link>
              </InfoValue>
            </>
          )}

          {task?.maintenance && (
            <>
              <InfoLabel>{i18n.t("maintenance:fields.schedule")}</InfoLabel>
              <InfoValue>
                <Link
                  to={`/maintenance/` + task.maintenance.id}
                  className="rounded px-2 py-1 font-medium text-gray-500 hover:border-gray-700 hover:bg-gray-100 hover:text-gray-700">
                  <span>{task.maintenance.name}</span>
                </Link>
              </InfoValue>
            </>
          )}

          {task?.service_request && (
            <>
              <InfoLabel>{i18n.t("common:service_request", { count: 1 })}</InfoLabel>
              <InfoValue>
                <Link
                  to={`/service-request/` + task.service_request.id}
                  className="rounded px-2 py-1 font-medium text-gray-500 hover:border-gray-700 hover:bg-gray-100 hover:text-gray-700">
                  <span className="ml-1 mr-2">{task.service_request.title}</span>
                  <ServiceRequestStatusTag status={task.service_request.status} />
                </Link>
              </InfoValue>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
