import { AssetCreateFormDialog } from "@components/asset/asset-create-form-dialog"
import { CustomView as NewCustomView } from "@components/new-data-view/data-view-custom-view"
import { DefaultView as NewDefaultView } from "@components/new-data-view/data-view-default-view"
import { Button } from "@components/shared"
import CustomView from "@components/shared/custom-view"
import { DataViewConfiguration } from "@components/shared/data-view/data-view"
import { FilterState } from "@components/shared/data-view/data-view-types"
import DefaultViewComponent from "@components/shared/default-view"
import LoadingSpinner from "@components/shared/loading-spinner"
import {
  DEFAULT_FAVORITE_ICON_NAME,
  DEFAULT_TEAM_ICON_COLOR,
  TeamIcon,
} from "@components/shared/team-icons"
import { UserFavorite } from "@components/shared/user-favorite"
import { allDefaultViews, DefaultView } from "@components/view/default-views"
import { AssigneeValueType } from "@components/work-order/work-order-data-view-filter-configuration"
import { useFeature } from "@contexts/feature-flag-context"
import { openModal } from "@contexts/modal-context"
import { usePage } from "@contexts/page-context"
import { getUser } from "@contexts/user-context"
import { IViewDataTypeEnum, IWorkOrderStatusEnum, uuid } from "@elara/db"
import {
  ICustomViewFragment,
  useCustomViewByIdQuery,
} from "@graphql/documents/custom-view.generated"
import i18n from "@i18n"
import React, { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { validate } from "uuid"

const CustomViewPage = () => {
  const { viewId } = useParams()
  const navigate = useNavigate()

  const [viewQueryRes] = useCustomViewByIdQuery({
    variables: { id: viewId! },
    pause: !viewId || !validate(viewId),
    requestPolicy: "cache-and-network",
  })

  let view:
    | { type: "customView"; view: ICustomViewFragment }
    | { type: "defaultView"; view: DefaultView }
    | null = null

  if (viewId && validate(viewId)) {
    view = viewQueryRes.data?.custom_view_by_pk
      ? { type: "customView", view: viewQueryRes.data?.custom_view_by_pk }
      : null
  } else if (viewId && !validate(viewId)) {
    const defaultView = allDefaultViews().find((v) => v.id === viewId)
    if (defaultView) {
      view = { type: "defaultView", view: defaultView }
    }
  }

  usePage({
    id: "custom-view",
    title: view?.view.name ?? "",
    isSubPage: true,
  })

  const [isEditing, setIsEditing] = useState(false)

  const hasDataViewFeature = useFeature("data_view")

  const CustomViewComp = hasDataViewFeature ? NewCustomView : CustomView
  const DefaultViewComp = hasDataViewFeature ? NewDefaultView : DefaultViewComponent

  if (!view) return <LoadingSpinner />

  return (
    <div className="mt-2 flex min-h-0 flex-1 flex-col">
      <div className="mx-3 mb-2 flex flex-row items-center">
        <div
          className="mr-1 flex min-w-0 shrink cursor-pointer flex-row items-center rounded px-2 py-1 hover:bg-gray-100"
          onClick={() => setIsEditing(true)}>
          {view.type === "defaultView" ? (
            <div className="mr-1.5">
              {view.view.icon ? React.createElement(view.view.icon) : null}
            </div>
          ) : (
            <TeamIcon
              className="mr-1.5"
              {...(view.view.icon
                ? view.view.icon
                : { name: DEFAULT_FAVORITE_ICON_NAME, color: DEFAULT_TEAM_ICON_COLOR })}
            />
          )}
          <h1
            className="truncate text-lg font-semibold"
            onClick={() => navigate(`/view/${view?.view.id}/edit`, { replace: true })}>
            {view.view.name}
          </h1>
        </div>

        {view.type === "defaultView" && <UserFavorite defaultViewId={view.view.id} />}
        {view.type === "customView" && <UserFavorite customViewId={view.view.id} />}

        <div className="flex-1" />

        {view.view.data_type === IViewDataTypeEnum.Asset && (
          <AssetCreateFormDialog>
            <Button type="primary">{i18n.t("assets:new_asset")}</Button>
          </AssetCreateFormDialog>
        )}
        {view.view.data_type === IViewDataTypeEnum.Workorder && (
          <Button
            onClick={() => {
              let status = IWorkOrderStatusEnum.Open
              const asset_ids: uuid[] = []
              const team_ids: uuid[] = []
              const category_ids: uuid[] = []
              const assignee_ids: uuid[] = []

              try {
                const user = getUser()
                if (view) {
                  const config = view.view.config as DataViewConfiguration<string>
                  const filters = (config.filters ?? []) as FilterState[]

                  for (let filter of filters) {
                    if (
                      filter.id === "assets" &&
                      filter.type == "select" &&
                      !filter.negated
                    ) {
                      asset_ids.push(...(filter.selectedValues as uuid[]))
                    } else if (
                      filter.id === "category" &&
                      filter.type == "select" &&
                      !filter.negated
                    ) {
                      category_ids.push(...(filter.selectedValues as uuid[]))
                    } else if (
                      filter.id === "assignees" &&
                      filter.type == "select" &&
                      !filter.negated
                    ) {
                      const ids = (filter.selectedValues as AssigneeValueType[])
                        .map((v) => (v.type === "user" ? v.id : user.id))
                        .filter(Boolean) as uuid[]
                      assignee_ids.push(...ids)
                    } else if (
                      filter.id === "teams" &&
                      filter.type == "select" &&
                      !filter.negated
                    ) {
                      team_ids.push(...(filter.selectedValues as uuid[]))
                    } else if (
                      filter.id === "status" &&
                      filter.type == "select" &&
                      !filter.negated
                    ) {
                      if (filter.selectedValues.length === 1) {
                        status = filter.selectedValues[0] as IWorkOrderStatusEnum
                      }
                    }
                  }
                }
              } catch {}

              openModal("select_template", {
                initialTaskValues: {
                  asset_ids,
                  category_ids,
                  assignee_ids,
                  team_ids,
                  status,
                },
              })
            }}>
            {i18n.t("tasks:new_task")}
          </Button>
        )}
      </div>

      {view.type === "customView" ? (
        <CustomViewComp
          edit={isEditing}
          key={isEditing ? 1 : 0}
          onEditViewFinish={() => setIsEditing(false)}
          viewId={viewId!}
        />
      ) : (
        <DefaultViewComp dataType={view.view.data_type} {...view.view} />
      )}
    </div>
  )
}

export default CustomViewPage
