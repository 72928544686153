import { SelectPopover, SelectPopoverProps } from "@components/shared/single-select"
import {
  useAssetPlacesQuery,
  useCreateAssetPlaceMutation,
} from "@graphql/documents/asset-place.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { naturalCompare } from "@utils"
import { useCallback, useMemo } from "react"

const usePlaceSelect = (): Omit<SelectPopoverProps<string>, "value"> => {
  const [assetPlaces] = useAssetPlacesQuery({ requestPolicy: "cache-first" })

  const items = useMemo(
    () =>
      (assetPlaces.data?.place ?? [])
        .slice()
        .sort((a, b) => naturalCompare(a.name, b.name))
        .map((place) => ({
          value: place.id,
          searchValue: place.name,
          label: <> {place.name} </>,
        })),
    [assetPlaces.data?.place]
  )

  const createScope = usePermissionScope(IPermissionScopeEnum.AppAssetCreate)
  const [, createAssetPlace] = useCreateAssetPlaceMutation()
  const onCreate = useCallback(
    async (name: string): Promise<string> => {
      const result = await createAssetPlace({ name }, createScope.context())
      const id = result.data?.insert_place_one?.id
      if (result.error || !id) throw result.error
      return id
    },
    [createScope, createAssetPlace]
  )

  return {
    name: i18n.t("assets:fields.location", { count: 1 }),
    placeholder: i18n.t("common:select_token", {
      token: i18n.t("assets:fields.location", { count: 1 }),
    }),
    items,
    onCreate,
  }
}

export function AssetPlaceSingleSelect(props: Omit<SelectPopoverProps<string>, "items">) {
  const selectProps = usePlaceSelect()
  return <SelectPopover {...selectProps} {...props} />
}
