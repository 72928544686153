import { Flex } from "@components/layout"
import {
  createSchemaStatement,
  defaultValueToItem,
  generalSelectStateToWhere,
  SchemaStatement,
  selectedItemsToWhere,
  SelectSchemaStatement,
  selectStateToWhere,
  SingletonSchemaStatement,
} from "@components/shared/data-view/data-view-filter.hooks"
import { SelectItem, SelectState } from "@components/shared/data-view/data-view-types"
import { teamItems } from "@components/shared/team-select"
import { UserAvatar } from "@components/shared/user-avatar"
import { hasFeature } from "@contexts/feature-flag-context"
import {
  IWorkOrderPriorityEnum,
  IWorkOrderStatusEnum,
  IWorkOrderTypeEnum,
  uuid,
} from "@elara/db"
import { Data, orderBy, RelativeComparisonValue, Where } from "@elara/select"
import {
  AssetSelectDataDocument,
  IAssetSelectDataQuery,
} from "@graphql/documents/asset.generated"
import {
  AssetGroupsDocument,
  IAssetGroupsQuery,
} from "@graphql/documents/asset-group.generated"
import {
  IUserTagFragment,
  IWorkOrderDataViewFragment,
} from "@graphql/documents/fragments.generated"
import { ITeamsQuery, TeamsDocument } from "@graphql/documents/team.generated"
import {
  IUserSelectDataQuery,
  UserSelectDataDocument,
} from "@graphql/documents/user.generated"
import {
  IWorkOrderCategoriesQuery,
  WorkOrderCategoriesDocument,
} from "@graphql/documents/work-order.generated"
import {} from "@graphql/documents/workforce.generated"
import i18n from "@i18n"
import {
  ArrowCounterClockwise,
  ArrowsClockwise,
  CalendarBlank,
  CalendarCheck,
  CalendarPlus,
  CaretDoubleUp,
  CircleDashed,
  DiamondsFour,
  List,
  Shapes,
  Swatches,
  User,
  UserCircle,
  UsersFour,
  UsersThree,
} from "@phosphor-icons/react"
import Icons from "@resources/icons"
import { naturalCompare } from "@utils"
import { buildAssetsTree } from "@utils/build-trees"
import { formatDate } from "@utils/date"
import { formatISO } from "date-fns"
import { dequal } from "dequal"
import { ReactNode } from "react"

import { CategoryColor } from "./work-order-category-select"
import { WorkOrderPriorityBadge } from "./work-order-priority"
import { translateWorkOrderStatus, WorkOrderStatusTag } from "./work-order-status"
import { translateEntryTypes } from "./work-order-type"

function userAvatars(data: IUserTagFragment[]) {
  return data
    .map((a) => ({
      label: `${a.first_name} ${a.last_name}`,
      value: a.id,
      searchValue: `${a.first_name} ${a.last_name}`,
      icon: <UserAvatar user={a} />,
    }))
    .sort((a, b) => naturalCompare(a.searchValue, b.searchValue))
}

function negatedRelativeComparison(
  relative: "_since_last" | "_until_next"
): "_since_last" | "_until_next" {
  return relative === "_since_last" ? "_until_next" : "_since_last"
}
function relativeToCustomDateComparision(relative: "_since_last" | "_until_next") {
  return relative === "_since_last" ? "_gte" : "_lte"
}
function relativeToOperatorLabel(relative: "_since_last" | "_until_next") {
  return relative === "_since_last"
    ? i18n.t("data-view:filters.since")
    : i18n.t("data-view:filters.until")
}

const negNumber = (x: number | undefined) => (typeof x === "undefined" ? undefined : -x)
function negateRelativeComparisonValue(
  value: { type: "relative" } & RelativeComparisonValue
) {
  return {
    ...value,
    days: negNumber(value.days),
    weeks: negNumber(value.weeks),
    months: negNumber(value.months),
    years: negNumber(value.years),
  }
}

export type RelativeDateValue =
  | { type: "customDate"; dateTime: string }
  | { type: "noDueDate" }
  | ({ type: "relative" } & RelativeComparisonValue)

function relativeDate<K extends string>(options: {
  defaultRelative: "_since_last" | "_until_next"
  key: K
  promptForDate?: () => Promise<Date>
  label: string
  getItems: () => Promise<SelectItem<RelativeDateValue>[]>
  isGrouped?: boolean
  allowNull?: boolean
  icon?: ReactNode
}) {
  return createSchemaStatement<any, RelativeDateValue>({
    type: "select",
    icon: options.icon,
    label: options.label,
    isGrouped: options.isGrouped,
    valueToString: (v) =>
      v.type === "relative"
        ? `${v.type}_${v.days}_${v.months}_${v.weeks}_${v.years}`
        : v.type === "customDate"
        ? `custom_${v.dateTime}`
        : i18n.t("common:without_token", { token: i18n.t("calendar:tokens.date") }),
    singleSelectOnly: true,
    toWhere: (state) => {
      const value = state.selectedValues[0]
      if (!value) return {}

      const wrapNullable = (
        where: Where<IWorkOrderDataViewFragment>
      ): Where<IWorkOrderDataViewFragment> => {
        if (options.allowNull) {
          return { _or: [where, { [options.key]: { _is_null: !state.negated } }] }
        }
        return where
      }

      if (value.type === "customDate") {
        return wrapNullable({
          [options.key]: {
            [state.negated
              ? relativeToCustomDateComparision(
                  negatedRelativeComparison(options.defaultRelative)
                )
              : relativeToCustomDateComparision(options.defaultRelative)]:
              value.dateTime.slice(0, 10),
          },
        })
      }

      if (value.type === "noDueDate") {
        return {
          [options.key]: state.negated ? { _is_null: false } : { _is_null: true },
        }
      }

      return wrapNullable({
        [options.key]: {
          [state.negated
            ? negatedRelativeComparison(options.defaultRelative)
            : options.defaultRelative]: state.negated
            ? negateRelativeComparisonValue(value)
            : value,
        },
      })
    },
    valueToItem: (v, items) => {
      if (v.type === "customDate") {
        return {
          label: formatDate(new Date(v.dateTime), "P"),
          value: v,
          searchValue: "",
        }
      }
      return defaultValueToItem(v, items)
    },
    onCustomClick: async (selectedValue) => {
      if (selectedValue.type == "customDate" && options.promptForDate) {
        const date = await options.promptForDate()
        const dateTime = formatISO(date)
        return {
          label: formatDate(date, "P"),
          value: { type: "customDate" as "customDate", dateTime },
          searchValue: "",
        }
      } else {
        throw new Error()
      }
    },
    operatorLabel(negated) {
      return negated
        ? relativeToOperatorLabel(negatedRelativeComparison(options.defaultRelative))
        : relativeToOperatorLabel(options.defaultRelative)
    },
    getItems: async () => [
      ...(await options.getItems()),
      {
        label: i18n.t("calendar:relative_dates.custom_date"),
        searchValue: i18n.t("calendar:relative_dates.custom_date"),
        value: { type: "customDate", dateTime: new Date().toISOString() },
        customOnClick: true,
      },
    ],
  })
}

export function pastRelativeDate<K extends string>(
  key: K,
  label: string,
  allowNull?: boolean,
  icon?: ReactNode
) {
  return (promptForDate?: () => Promise<Date>) =>
    relativeDate({
      promptForDate,
      label,
      key,
      defaultRelative: "_since_last",
      allowNull,
      icon,
      getItems: async () => [
        {
          label: i18n.t("calendar:relative_dates.today"),
          searchValue: i18n.t("calendar:relative_dates.today"),
          value: { type: "relative", days: 0 },
        },
        {
          label: i18n.t("calendar:relative_dates.x_token_ago", {
            count: 12,
            token: i18n.t("calendar:tokens.hour", { count: 12 }),
          }),
          searchValue: i18n.t("calendar:relative_dates.x_token_ago", {
            count: 2,
            token: i18n.t("calendar:tokens.hour", { count: 12 }),
          }),
          value: { type: "relative", hours: 12 },
        },
        {
          label: i18n.t("calendar:relative_dates.yesterday"),
          searchValue: i18n.t("calendar:relative_dates.yesterday"),
          value: { type: "relative", days: 1 },
        },
        {
          label: i18n.t("calendar:relative_dates.x_token_ago", {
            count: 2,
            token: i18n.t("calendar:tokens.day", { count: 2 }),
          }),
          searchValue: i18n.t("calendar:relative_dates.x_token_ago", {
            count: 2,
            token: i18n.t("calendar:tokens.day", { count: 2 }),
          }),
          value: { type: "relative", days: 2 },
        },
        {
          label: i18n.t("calendar:relative_dates.x_token_ago", {
            count: 1,
            token: i18n.t("calendar:tokens.week", { count: 1 }),
          }),
          searchValue: i18n.t("calendar:relative_dates.x_token_ago", {
            count: 1,
            token: i18n.t("calendar:tokens.week", { count: 1 }),
          }),
          value: { type: "relative", weeks: 1 },
        },
        {
          label: i18n.t("calendar:relative_dates.x_token_ago", {
            count: 2,
            token: i18n.t("calendar:tokens.week", { count: 2 }),
          }),
          searchValue: i18n.t("calendar:relative_dates.x_token_ago", {
            count: 2,
            token: i18n.t("calendar:tokens.week", { count: 2 }),
          }),
          value: { type: "relative", weeks: 2 },
        },
        {
          label: i18n.t("calendar:relative_dates.x_token_ago", {
            count: 3,
            token: i18n.t("calendar:tokens.week", { count: 3 }),
          }),
          searchValue: i18n.t("calendar:relative_dates.x_token_ago", {
            count: 3,
            token: i18n.t("calendar:tokens.week", { count: 3 }),
          }),
          value: { type: "relative", weeks: 3 },
        },
        {
          label: i18n.t("calendar:relative_dates.x_token_ago", {
            count: 1,
            token: i18n.t("calendar:tokens.month", { count: 1 }),
          }),
          searchValue: i18n.t("calendar:relative_dates.x_token_ago", {
            count: 1,
            token: i18n.t("calendar:tokens.month", { count: 1 }),
          }),
          value: { type: "relative", months: 1 },
        },
        {
          label: i18n.t("calendar:relative_dates.x_token_ago", {
            count: 2,
            token: i18n.t("calendar:tokens.month", { count: 2 }),
          }),
          searchValue: i18n.t("calendar:relative_dates.x_token_ago", {
            count: 2,
            token: i18n.t("calendar:tokens.month", { count: 2 }),
          }),
          value: { type: "relative", months: 2 },
        },
        {
          label: i18n.t("calendar:relative_dates.x_token_ago", {
            count: 3,
            token: i18n.t("calendar:tokens.month", { count: 3 }),
          }),
          searchValue: i18n.t("calendar:relative_dates.x_token_ago", {
            count: 3,
            token: i18n.t("calendar:tokens.month", { count: 3 }),
          }),
          value: { type: "relative", months: 3 },
        },
        {
          label: i18n.t("calendar:relative_dates.x_token_ago", {
            count: 6,
            token: i18n.t("calendar:tokens.month", { count: 6 }),
          }),
          searchValue: i18n.t("calendar:relative_dates.x_token_ago", {
            count: 6,
            token: i18n.t("calendar:tokens.month", { count: 6 }),
          }),
          value: { type: "relative", months: 6 },
        },
        {
          label: i18n.t("calendar:relative_dates.x_token_ago", {
            count: 1,
            token: i18n.t("calendar:tokens.year", { count: 1 }),
          }),
          searchValue: i18n.t("calendar:relative_dates.x_token_ago", {
            count: 1,
            token: i18n.t("calendar:tokens.year", { count: 1 }),
          }),
          value: { type: "relative", years: 1 },
        },
      ],
    })
}

export function genericAssets<D extends Data = IWorkOrderDataViewFragment>(
  toWhere: (state: SelectState<string | null>) => Where<D>
) {
  return createSchemaStatement<D, string | null>({
    type: "select",
    icon: <Shapes />,
    label: i18n.t("common:asset", { count: 1 }),
    multiSelectedLabel: i18n.t("common:asset", { count: 2 }),
    getItems: async (client) => {
      const queryRes = await client.query<IAssetSelectDataQuery>(
        AssetSelectDataDocument,
        {},
        {
          requestPolicy: "cache-first",
        }
      )
      if (!queryRes?.data) return null
      return [
        {
          label: (
            <span className="text-gray-500">
              {i18n.t("common:no_token", {
                token: i18n.t("common:asset", { count: 1 }),
              })}
            </span>
          ) as ReactNode,
          value: null as string | null,
          searchValue: i18n.t("common:no_token", {
            token: i18n.t("common:asset", { count: 1 }),
          }),
        },
      ].concat(buildAssetsTree(queryRes?.data?.asset))
    },
    toWhere,
  })
}

export function assets() {
  return genericAssets<IWorkOrderDataViewFragment>((state) => {
    let whereClause = { _or: [] } as Where<IWorkOrderDataViewFragment>

    if (state.selectedValues.includes(null)) {
      if (state.negated) {
        whereClause._or?.push({ assets: {} })
      } else {
        whereClause._or?.push({ _not: { assets: {} } })
      }

      return whereClause
    }

    if (state.negated) {
      whereClause._or?.push({ _not: { assets: {} } })

      whereClause._or?.push({
        assets: {
          asset: {
            _and: [
              {
                _not: generalSelectStateToWhere(
                  ["id"],
                  { ...state, negated: false },
                  {
                    isOneToMany: true,
                  }
                ),
              },
              {
                _not: generalSelectStateToWhere(
                  ["parent_ids"],
                  { ...state, negated: false },
                  {
                    isOneToMany: true,
                    operator: "_has_keys_any",
                  }
                ) as Where<IWorkOrderDataViewFragment>,
              },
            ],
          },
        },
      })
    } else {
      whereClause._or?.push({
        assets: {
          asset: {
            _or: [
              generalSelectStateToWhere(["id"], state, {
                isOneToMany: true,
              }),
              generalSelectStateToWhere(["parent_ids"], state, {
                isOneToMany: true,
                operator: "_has_keys_any",
              }) as Where<IWorkOrderDataViewFragment>,
            ],
          },
        },
      })
    }

    return whereClause
  })
}

export function genericAssetGroups<D extends Data = IWorkOrderDataViewFragment>(
  toWhere: (state: SelectState<string | null>) => Where<D>
) {
  return createSchemaStatement<D, string | null>({
    type: "select",
    icon: <DiamondsFour className="h-[1em] w-[1em]" />,
    label: i18n.t("assets:fields.group", { count: 1 }),
    multiSelectedLabel: i18n.t("assets:fields.group", { count: 2 }),
    getItems: async (client) => {
      const queryRes = await client.query<IAssetGroupsQuery>(
        AssetGroupsDocument,
        {},
        { requestPolicy: "cache-first" }
      )
      const groups = queryRes?.data?.asset_group
      if (!groups) return null
      return orderBy(groups, { name: "asc" }).map((a) => ({
        label: a.name,
        value: a.id,
        searchValue: a.name,
      }))
    },
    toWhere,
  })
}

export function assetGroups() {
  return genericAssetGroups<IWorkOrderDataViewFragment>(
    (state) =>
      generalSelectStateToWhere(["assets", "asset", "group", "id"], state, {
        isOneToMany: true,
      }) as Where<IWorkOrderDataViewFragment>
  )
}

type AssigneeUserTeamValueType = { type: "user"; id: string | null }
export type AssigneeValueType = AssigneeUserTeamValueType | { type: "current_user" }

export function assignees(currentUserId: string) {
  return createSchemaStatement<IWorkOrderDataViewFragment, AssigneeValueType>({
    type: "select",
    icon: <UserCircle />,
    label: i18n.t("tasks:fields.assignee", { count: 1 }),
    multiSelectedLabel: i18n.t("tasks:fields.assignee", { count: 2 }),
    valueToString: (v) => (v.type === "user" ? `user_id:${v.id}` : v.type),
    toWhere: (state) => {
      if (!state.selectedValues.length) return {}

      const selectedUsers = state.selectedValues
        .filter((t) => t.type === "user")
        .map((t) => (t as AssigneeUserTeamValueType).id)

      const users = selectedUsers.filter(Boolean) as string[]
      const hasCurrentUser = state.selectedValues.some((t) => t.type === "current_user")
      if (hasCurrentUser) {
        users.push(currentUserId)
      }
      const hasNoUser = selectedUsers.some((id) => id === null)
      const nonNegatedCond = {
        _or: [
          hasNoUser ? { _not: { assignees: {} } } : { _not: {} },
          {
            assignees: {
              user: {
                id: selectedItemsToWhere(users, false, { emptyIsTrue: false }),
              },
            },
          },
        ],
      }
      if (state.negated) {
        return { _not: nonNegatedCond }
      } else {
        return nonNegatedCond
      }
    },
    getItems: async (client) => {
      const queryRes = await client.query<IUserSelectDataQuery>(
        UserSelectDataDocument,
        {},
        {
          requestPolicy: "cache-first",
        }
      )
      return [
        {
          value: { type: "current_user" as "current_user" },
          icon: <Icons.User />,
          label: i18n.t("tasks:filters.users.current_user"),
          searchValue: "",
        },
        {
          value: { type: "user", id: null } as AssigneeUserTeamValueType,
          icon: <Icons.User className="text-gray-400" />,
          label: (
            <span className="text-gray-500">{i18n.t("tasks:filters.users.nobody")}</span>
          ) as ReactNode,
          searchValue: "",
        },
      ].concat(
        queryRes?.data?.location_member
          ? userAvatars(
              queryRes?.data?.location_member
                ?.map((u) => u.user)
                .filter((user) => !user.deleted_at)
            ).map((item) => ({
              ...item,
              value: { type: "user", id: item.value } as AssigneeUserTeamValueType,
            }))
          : []
      )
    },
  })
}

export function teams() {
  return createSchemaStatement<IWorkOrderDataViewFragment, uuid>({
    type: "select",
    isGrouped: true,
    icon: <UsersFour />,
    label: i18n.t("common:team", { count: 1 }),
    multiSelectedLabel: i18n.t("common:team", { count: 2 }),
    toWhere: (state) => {
      return generalSelectStateToWhere(["assigned_teams", "team", "id"], state, {
        isOneToMany: true,
      }) as Where<IWorkOrderDataViewFragment>
    },
    valueToString: (v) => v,
    getItems: async (client) => {
      const queryRes = await client
        .query<ITeamsQuery>(TeamsDocument, {}, { requestPolicy: "cache-first" })
        .toPromise()
      return teamItems(queryRes?.data?.team ?? [], undefined).items
    },
  })
}

export function createdBy(currentUserId: uuid) {
  return createSchemaStatement<IWorkOrderDataViewFragment, string | "current_user">({
    type: "select",
    icon: <User />,
    label: i18n.t("tasks:fields.created_by"),
    multiSelectedLabel: i18n.t("common:employee", { count: 2 }),
    toWhere: (state) => {
      const indexCurrentUser = state.selectedValues.indexOf("current_user")
      const hasCurrentUser = state.selectedValues.some((t) => t === "current_user")
      if (hasCurrentUser) {
        state.selectedValues.splice(indexCurrentUser, 1, currentUserId)
      }
      return generalSelectStateToWhere(
        ["created_by", "id"],
        state
      ) as Where<IWorkOrderDataViewFragment>
    },
    getItems: async (client) => {
      const queryRes = await client.query<IUserSelectDataQuery>(
        UserSelectDataDocument,
        {},
        {
          requestPolicy: "cache-first",
        }
      )
      return [
        {
          value: "current_user",
          icon: <Icons.User />,
          label: i18n.t("tasks:filters.users.current_user"),
          searchValue: "",
        },
      ].concat(
        queryRes?.data?.location_member
          ? userAvatars(
              queryRes?.data?.location_member
                ?.map((u) => u.user)
                ?.filter((user) => !user.deleted_at)
            )
          : []
      )
    },
  })
}

export function collaborator(currentUserId: uuid) {
  return createSchemaStatement<IWorkOrderDataViewFragment, string | "current_user" | null>({
    type: "select",
    icon: <UsersThree />,
    label: i18n.t("tasks:fields.collaborator", { count: 1 }),
    multiSelectedLabel: i18n.t("tasks:fields.collaborator", { count: 2 }),
    toWhere: (state) => {
      const indexCurrentUser = state.selectedValues.indexOf("current_user")
      const hasCurrentUser = state.selectedValues.some((t) => t === "current_user")
      if (hasCurrentUser) {
        state.selectedValues.splice(indexCurrentUser, 1, currentUserId)
      }
      return generalSelectStateToWhere(["collaborators", "user_id"], state, {
        isOneToMany: true,
      }) as Where<IWorkOrderDataViewFragment>
    },
    getItems: async (client) => {
      const queryRes = await client.query<IUserSelectDataQuery>(
        UserSelectDataDocument,
        {},
        {
          requestPolicy: "cache-first",
        }
      )
      return [
        {
          value: "current_user",
          icon: <Icons.User />,
          label: i18n.t("tasks:filters.users.current_user"),
          searchValue: "",
        },
        {
          value: null,
          icon: <Icons.User className="text-gray-400" />,
          label: (
            <span className="text-gray-500">{i18n.t("tasks:filters.users.nobody")}</span>
          ) as ReactNode,
          searchValue: "",
        },
      ].concat(
        queryRes?.data?.location_member
          ? userAvatars(
              queryRes?.data?.location_member
                ?.map((u) => u.user)
                ?.filter((user) => !user.deleted_at)
            ).map((item) => ({
              ...item,
              value: item.value,
            }))
          : []
      )
    },
  })
}

export const createdAt = pastRelativeDate(
  "created_at",
  i18n.t("tasks:fields.created_at"),
  false,
  <CalendarPlus />
)

export function category() {
  return createSchemaStatement<IWorkOrderDataViewFragment, string | null>({
    type: "select",
    icon: <Swatches />,
    label: i18n.t("tasks:fields.category", { count: 1 }),
    multiSelectedLabel: i18n.t("tasks:fields.category", { count: 2 }),
    toWhere: (state) =>
      generalSelectStateToWhere(["categories", "work_order_category_id"], state, {
        isOneToMany: true,
      }) as Where<IWorkOrderDataViewFragment>,
    getItems: async (client) => {
      const queryRes = await client.query<IWorkOrderCategoriesQuery>(
        WorkOrderCategoriesDocument,
        {},
        {
          requestPolicy: "cache-first",
        }
      )
      const categories = queryRes?.data?.work_order_category

      if (!categories) return null

      return [
        {
          value: null as string | null,
          label: (
            <span className="text-gray-500">
              {i18n.t("common:no_token", {
                context: "female",
                token: i18n.t("tasks:fields.category", { count: 1 }),
              })}
            </span>
          ) as ReactNode,
          searchValue: i18n.t("common:no_token", {
            context: "female",
            token: i18n.t("tasks:fields.category", { count: 1 }),
          }),
        },
      ].concat(
        orderBy(categories, { label: "asc" }).map((category) => ({
          label: (
            <Flex row align="center" justify="space-between">
              <CategoryColor color={category.color} className="mr-2" />
              <span>{category.label}</span>
            </Flex>
          ),
          value: category.id,
          searchValue: category.label,
          color: category.color,
        }))
      )
    },
  })
}

export function dueDate(
  promptForDate?: () => Promise<Date>
): SchemaStatement<IWorkOrderDataViewFragment> {
  const config = relativeDate({
    promptForDate,
    key: "due_date",
    icon: <CalendarBlank />,
    label: i18n.t("tasks:fields.due_date"),
    defaultRelative: "_until_next",
    getItems: async () => [
      {
        label: (
          <span className="text-gray-500">
            {i18n.t("common:without_token", { token: i18n.t("calendar:tokens.due_date") })}
          </span>
        ),
        searchValue: i18n.t("common:without_token", {
          token: i18n.t("calendar:tokens.due_date"),
        }),
        value: { type: "noDueDate" },
      },
      {
        label: i18n.t("calendar:relative_dates.overdue"),
        searchValue: i18n.t("calendar:relative_dates.overdue"),
        value: { type: "relative", days: -1 },
      },
      {
        label: i18n.t("calendar:relative_dates.today"),
        searchValue: i18n.t("calendar:relative_dates.today"),
        value: { type: "relative", days: 0 },
      },
      {
        label: i18n.t("calendar:relative_dates.tomorrow"),
        searchValue: i18n.t("calendar:relative_dates.tomorrow"),
        value: { type: "relative", days: 1 },
      },
      {
        label: i18n.t("calendar:relative_dates.x_token_from_today", {
          count: 2,
          token: i18n.t("calendar:tokens.day", { count: 2 }),
        }),
        searchValue: i18n.t("calendar:relative_dates.x_token_from_today", {
          count: 2,
          token: i18n.t("calendar:tokens.day", { count: 2 }),
        }),
        value: { type: "relative", days: 2 },
      },
      {
        label: i18n.t("calendar:relative_dates.x_token_from_today", {
          count: 1,
          token: i18n.t("calendar:tokens.week", { count: 1 }),
        }),
        searchValue: i18n.t("calendar:relative_dates.x_token_from_today", {
          count: 1,
          token: i18n.t("calendar:tokens.week", { count: 1 }),
        }),
        value: { type: "relative", weeks: 1 },
      },
      {
        label: i18n.t("calendar:relative_dates.x_token_from_today", {
          count: 2,
          token: i18n.t("calendar:tokens.week", { count: 2 }),
        }),
        searchValue: i18n.t("calendar:relative_dates.x_token_from_today", {
          count: 2,
          token: i18n.t("calendar:tokens.week", { count: 2 }),
        }),
        value: { type: "relative", weeks: 2 },
      },
      {
        label: i18n.t("calendar:relative_dates.x_token_from_today", {
          count: 3,
          token: i18n.t("calendar:tokens.week", { count: 3 }),
        }),
        searchValue: i18n.t("calendar:relative_dates.x_token_from_today", {
          count: 3,
          token: i18n.t("calendar:tokens.week", { count: 3 }),
        }),
        value: { type: "relative", weeks: 3 },
      },
      {
        label: i18n.t("calendar:relative_dates.x_token_from_today", {
          count: 1,
          token: i18n.t("calendar:tokens.month", { count: 1 }),
        }),
        searchValue: i18n.t("calendar:relative_dates.x_token_from_today", {
          count: 1,
          token: i18n.t("calendar:tokens.month", { count: 1 }),
        }),
        value: { type: "relative", months: 1 },
      },
      {
        label: i18n.t("calendar:relative_dates.x_token_from_today", {
          count: 2,
          token: i18n.t("calendar:tokens.month", { count: 2 }),
        }),
        searchValue: i18n.t("calendar:relative_dates.x_token_from_today", {
          count: 2,
          token: i18n.t("calendar:tokens.month", { count: 2 }),
        }),
        value: { type: "relative", months: 2 },
      },
      {
        label: i18n.t("calendar:relative_dates.x_token_from_today", {
          count: 3,
          token: i18n.t("calendar:tokens.month", { count: 3 }),
        }),
        searchValue: i18n.t("calendar:relative_dates.x_token_from_today", {
          count: 3,
          token: i18n.t("calendar:tokens.month", { count: 3 }),
        }),
        value: { type: "relative", months: 3 },
      },
      {
        label: i18n.t("calendar:relative_dates.x_token_from_today", {
          count: 6,
          token: i18n.t("calendar:tokens.month", { count: 6 }),
        }),
        searchValue: i18n.t("calendar:relative_dates.x_token_from_today", {
          count: 6,
          token: i18n.t("calendar:tokens.month", { count: 6 }),
        }),
        value: { type: "relative", months: 6 },
      },
      {
        label: i18n.t("calendar:relative_dates.x_token_from_today", {
          count: 1,
          token: i18n.t("calendar:tokens.year", { count: 1 }),
        }),
        searchValue: i18n.t("calendar:relative_dates.x_token_from_today", {
          count: 1,
          token: i18n.t("calendar:tokens.year", { count: 1 }),
        }),
        value: { type: "relative", years: 1 },
      },
    ],
  }) as SelectSchemaStatement<IWorkOrderDataViewFragment, RelativeDateValue>
  return {
    ...config,
    operatorLabel: (
      negated: boolean,
      multiple: boolean,
      state: SelectState<RelativeDateValue>
    ) => {
      const isOverdue = dequal(state.selectedValues[0], { type: "relative", days: -1 })
      const hasNoDueDate = dequal(state.selectedValues[0], { type: "noDueDate" })
      if (isOverdue || hasNoDueDate) {
        return negated ? i18n.t("data-view:filters.is_not") : i18n.t("data-view:filters.is")
      }
      return config.operatorLabel?.(negated, multiple, state) ?? ""
    },
  } as SchemaStatement<IWorkOrderDataViewFragment>
}
export const completedAt = pastRelativeDate(
  "completed_at",
  i18n.t("tasks:fields.completed_at"),
  true,
  <CalendarCheck />
)

export const lastUpdated = pastRelativeDate(
  "last_update",
  i18n.t("tasks:fields.last_update"),
  false,
  <ArrowsClockwise />
)

export function priority() {
  return createSchemaStatement<
    Pick<IWorkOrderDataViewFragment, "priority">,
    IWorkOrderPriorityEnum | null
  >({
    type: "select",
    label: i18n.t("tasks:fields.priority"),
    icon: <CaretDoubleUp />,
    multiSelectedLabel: i18n.t("tasks:fields.priority"),
    valueToString: (v) => v ?? "no_priority",
    toWhere: (state) =>
      generalSelectStateToWhere(["priority"], state) as Where<
        Pick<IWorkOrderDataViewFragment, "priority">
      >,
    getItems: async () => [
      {
        value: IWorkOrderPriorityEnum.High,
        searchValue: i18n.t("tasks:priority.high"),
        label: <WorkOrderPriorityBadge priority={IWorkOrderPriorityEnum.High} />,
      },
      {
        value: IWorkOrderPriorityEnum.Medium,
        searchValue: i18n.t("tasks:priority.medium"),
        label: <WorkOrderPriorityBadge priority={IWorkOrderPriorityEnum.Medium} />,
      },
      {
        value: IWorkOrderPriorityEnum.Low,
        searchValue: i18n.t("tasks:priority.low"),
        label: <WorkOrderPriorityBadge priority={IWorkOrderPriorityEnum.Low} />,
      },
      {
        value: null,
        searchValue: i18n.t("tasks:priority.none"),
        label: <span className="text-gray-500">{i18n.t("tasks:priority.none")}</span>,
      },
    ],
  })
}

type DataViewType = "work_order" | "logbook"

export function status(dataView: DataViewType) {
  return createSchemaStatement<
    Pick<IWorkOrderDataViewFragment, "status">,
    IWorkOrderStatusEnum
  >({
    type: "select",
    icon: <CircleDashed />,
    label: i18n.t("tasks:fields.status"),
    multiSelectedLabel: i18n.t("tasks:fields.status"),
    toWhere: (state) => {
      return { status: selectStateToWhere(state) } as Where<
        Pick<IWorkOrderDataViewFragment, "status">
      >
    },
    getItems: async () =>
      [
        IWorkOrderStatusEnum.Planned,
        IWorkOrderStatusEnum.Open,
        IWorkOrderStatusEnum.InProgress,
        IWorkOrderStatusEnum.ReadyForApproval,
        IWorkOrderStatusEnum.Done,
        IWorkOrderStatusEnum.OnHold,
        IWorkOrderStatusEnum.Canceled,
      ]
        .filter((status) => {
          if (dataView === "logbook") {
            if (
              status === IWorkOrderStatusEnum.Done ||
              status === IWorkOrderStatusEnum.Canceled
            )
              return status
          }
          return status
        })
        .map((status) => {
          return {
            label: <WorkOrderStatusTag status={status} />,
            value: status,
            searchValue: translateWorkOrderStatus(status),
          }
        }),
  })
}

export function recurrence(): SingletonSchemaStatement<IWorkOrderDataViewFragment> {
  return {
    type: "singleton",
    label: i18n.t("data-view:filters.recurring"),
    badgeLabel: i18n.t("common:task", { count: 1 }),
    icon: <ArrowCounterClockwise />,
    toWhere: (state) => {
      if (hasFeature("maintenance")) {
        return { maintenance_id: { _is_null: state.negated } }
      }
      return { recurrence_info: { _is_null: state.negated } }
    },
  }
}

export function type() {
  return createSchemaStatement<
    Pick<IWorkOrderDataViewFragment, "type">,
    IWorkOrderTypeEnum
  >({
    type: "select",
    label: i18n.t("tasks:fields.type"),
    icon: <List />,
    getItems: async () =>
      Object.values(IWorkOrderTypeEnum)
        .map((type) => {
          const translated = translateEntryTypes(type)

          return {
            value: type,
            label: translated,
            searchValue: translated,
          }
        })
        .sort((a, b) => naturalCompare(a.label, b.label)),
    toWhere: (state) =>
      generalSelectStateToWhere(["type"], state) as Where<IWorkOrderDataViewFragment>,
  })
}

export function completedBy(currentUserId: uuid) {
  return createSchemaStatement<IWorkOrderDataViewFragment, string | "current_user">({
    type: "select",
    icon: <CalendarCheck />,
    label: i18n.t("tasks:fields.completed_by"),
    multiSelectedLabel: i18n.t("common:employee", { count: 1 }),
    toWhere: (state) => {
      const selectedValues = state.selectedValues
        .filter((t) => t !== "current_user")
        .map((t) => t)
      const hasCurrentUser = state.selectedValues.some((t) => t === "current_user")
      if (hasCurrentUser) {
        selectedValues.push(currentUserId)
      }
      return { completed_by: { id: selectedItemsToWhere(selectedValues, state.negated) } }
    },
    getItems: async (client) => {
      const queryRes = await client.query<IUserSelectDataQuery>(
        UserSelectDataDocument,
        {},
        {
          requestPolicy: "cache-first",
        }
      )
      return [
        {
          value: "current_user",
          icon: <Icons.User />,
          label: i18n.t("tasks:filters.users.current_user"),
          searchValue: "",
        },
      ].concat(
        queryRes?.data?.location_member
          ? userAvatars(
              queryRes?.data?.location_member
                ?.map((u) => u.user)
                ?.filter((user) => !user.deleted_at)
            )
          : []
      )
    },
  })
}
