import SettingsPageLayout from "@components/settings/page-layout"
import { Button, Icons } from "@components/shared"
import CurrencySelect from "@components/shared/currency-select"
import { Dialog, DialogClose, DialogContentFooter } from "@components/shared/dialog"
import { FormField } from "@components/shared/form/form-field"
import { ImageUploadFormElement } from "@components/shared/image-upload-form-element"
import LanguageSelect from "@components/shared/language-select"
import { TextInput } from "@components/shared/text-input"
import { TimezoneSelect } from "@components/shared/timezone-select"
import toast from "@components/shared/toast"
import { usePage } from "@contexts/page-context"
import { useUser } from "@contexts/user-context"
import {
  IUpdateGeneralSettingsMutation,
  IUpdateGeneralSettingsMutationVariables,
  UpdateGeneralSettingsDocument,
} from "@graphql/documents/settings.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { useLogout } from "@models/authentication"
import { TrashSimple } from "@phosphor-icons/react"
import { Form, Formik } from "formik"
import { useState } from "react"
import { Trans } from "react-i18next"
import { gql, useClient } from "urql"
import * as yup from "yup"

const DeleteOrgModalContent = (props: { orgName: string; orgId: string }) => {
  const user = useUser()
  const logout = useLogout()
  const client = useClient()
  const scope = usePermissionScope(IPermissionScopeEnum.AppAccountManagement)

  const [inputValue, setInputValue] = useState("")

  const deleteOrg = async () => {
    if (inputValue !== props.orgName) return
    const res = await client
      .mutation(
        gql`
          mutation DeleteLocation($id: uuid!) {
            update_location_by_pk(pk_columns: { id: $id }, _set: { deleted_at: "now()" }) {
              id
              deleted_at
            }
          }
        `,
        { id: user.location.id },
        scope.context()
      )
      .toPromise()
    if (res.data?.update_location_by_pk) {
      logout()
    }
  }

  return (
    <>
      <p className="pt-3 text-sm text-gray-600">
        <Trans
          i18nKey={"settings:general.delete.confirm_1"}
          i18n={i18n}
          values={{ name: props.orgName }}
          components={{ strong: <span className="font-medium" /> }}
        />
      </p>
      <p className="mt-3 pb-3 text-sm text-gray-600">
        <Trans
          i18nKey={"settings:general.delete.confirm_2"}
          i18n={i18n}
          values={{ name: props.orgName }}
          components={{ strong: <span className="font-medium" /> }}
        />
      </p>
      <TextInput
        placeholder={props.orgName}
        className="mb-3"
        defaultValue=""
        onChange={(e) => setInputValue(e.target.value)}
      />

      <DialogContentFooter className="flex justify-end space-x-3">
        <DialogClose asChild>
          <Button type="secondary">{i18n.t("common:cancel")}</Button>
        </DialogClose>
        <Button color="red" disabled={inputValue !== props.orgName} onClick={deleteOrg}>
          {i18n.t("common:delete")}
        </Button>
      </DialogContentFooter>
    </>
  )
}

const SettingsGeneral = () => {
  usePage({
    isSubPage: true,
    id: "settings/content",
    title: i18n.t("settings:general.title"),
  })

  const user = useUser()
  const client = useClient()
  const adminScope = usePermissionScope(IPermissionScopeEnum.AppAccountManagement)

  return (
    <SettingsPageLayout
      title={i18n.t("settings:general.title")}
      description={i18n.t("settings:general.description")}>
      <Formik
        validationSchema={yup.object().shape({
          name: yup.string().required(i18n.t("common:required")),
        })}
        initialValues={{
          name: user.location.name,
          currency: user.location.settings.currency,
          lang: user.location.settings.lang,
          logo: user.location.logo,
          timezone: user.location.settings.timezone,
        }}
        onSubmit={async (values, helpers) => {
          try {
            helpers.setSubmitting(true)
            const res = await client
              .mutation<
                IUpdateGeneralSettingsMutation,
                IUpdateGeneralSettingsMutationVariables
              >(
                UpdateGeneralSettingsDocument,
                {
                  locationId: user.location.id,
                  name: values.name,
                  logoId: values.logo?.id ?? null,
                  settings: {
                    currency: values.currency,
                    lang: values.lang,
                    timezone: values.timezone,
                  },
                },
                adminScope.context()
              )
              .toPromise()
            if (res.error) {
              toast.error(i18n.t("common:generic_toast_error"))
            }
          } finally {
            helpers.setSubmitting(false)
          }
        }}>
        {(formik) => (
          <Form className="flex flex-col">
            <FormField name="logo" label={i18n.t("settings:general.logo")}>
              {({ field, helpers }) => (
                <div className="col-span-12">
                  <ImageUploadFormElement
                    fallback={<Icons.Info />}
                    uploadLabel={i18n.t("settings:general.upload_logo")}
                    editLabel={i18n.t("settings:general.edit_logo")}
                    upload={field.value}
                    onUploadChange={helpers.setValue}
                  />
                </div>
              )}
            </FormField>
            <FormField name="name" label={i18n.t("common:name")}>
              <TextInput autoComplete="nofill" />
            </FormField>

            <FormField name="lang" label={i18n.t("settings:general.default_language")}>
              {({ field, helpers }) => (
                <LanguageSelect value={field.value} onValueChange={helpers.setValue} />
              )}
            </FormField>
            <FormField name="currency" label={i18n.t("settings:general.currency")}>
              {({ field, helpers }) => (
                <CurrencySelect value={field.value} onValueChange={helpers.setValue} />
              )}
            </FormField>
            <FormField name="timezone" label={i18n.t("settings:general.timezone")}>
              {({ field, helpers }) => (
                <TimezoneSelect value={field.value} onChange={helpers.setValue} />
              )}
            </FormField>

            <div className="flex justify-end">
              <Button
                type="primary"
                htmlType="submit"
                icon={Icons.Check}
                isLoading={formik.isSubmitting}
                disabled={formik.isSubmitting}>
                {i18n.t("common:update")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      {(window.location.origin.includes("localhost") ||
        window.location.origin.includes("elaratest.de")) &&
        adminScope.hasScope && (
          <>
            <hr />

            <div className="mb-4 font-semibold text-gray-600">
              {i18n.t("settings:organization.danger_zone")}
            </div>

            <Dialog
              contentAsChild
              className="max-w-md"
              title={i18n.t("settings:general.delete_location", {
                name: user.location.name,
              })}
              trigger={
                <Button type="primary" color="red" icon={TrashSimple} className="max-w-fit">
                  {i18n.t("settings:general.delete_location", { name: user.location.name })}
                </Button>
              }>
              <DeleteOrgModalContent
                orgId={user.orgId}
                orgName={user.location.name ?? "-"}
              />
            </Dialog>
          </>
        )}
    </SettingsPageLayout>
  )
}

export default SettingsGeneral
