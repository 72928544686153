import Card from "@components/analytics/card"
import Statistic from "@components/analytics/statistic"
import { AssetGroupSingleSelect, AssetMultiSelect } from "@components/asset"
import { UserMultiSelect } from "@components/shared"
import { Item } from "@components/shared/combobox-select"
import DateRangePicker, { getRange } from "@components/shared/date-range-picker"
import ScrollArea from "@components/shared/scroll-area"
import { SelectPopover } from "@components/shared/single-select"
import { TeamMultiSelect } from "@components/shared/team-select"
import { WorkOrderCategoryMultiSelect } from "@components/work-order/work-order-category-select"
import {
  compareWorkOrderPriority,
  priorityColor,
  translateWorkOrderPriority,
} from "@components/work-order/work-order-priority"
import { useFeature } from "@contexts/feature-flag-context"
import { IWorkOrderPriorityEnum, uuid } from "@elara/db"
import { groupBy } from "@elara/select"
import {
  ITaskCompletionFragment,
  useTaskCompletionQuery,
} from "@graphql/documents/analytics.generated"
import i18n from "@i18n"
import { sortDate } from "@utils"
import { DateRange } from "@utils/date"
import { TreeLike } from "@utils/tree"
import {
  Chart as ChartJS,
  Filler,
  LineElement,
  PointElement,
  RadialLinearScale,
} from "chart.js"
import { differenceInDays, isBefore, startOfDay } from "date-fns"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { RefObject, useImperativeHandle, useMemo, useState } from "react"
import { useOutletContext } from "react-router-dom"
import * as ss from "simple-statistics"

import { RefPayload } from ".."

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler)

type RepetitionFilter = "all" | "recurring" | "non-recurring"

const repetitionFilters: TreeLike<Item<RepetitionFilter>>[] = [
  {
    value: "all",
    label: i18n.t("analytics:filters.repetition.options.all"),
    searchValue: i18n.t("analytics:filters.repetition.options.all"),
  },
  {
    value: "recurring",
    label: i18n.t("analytics:filters.repetition.options.recurring"),
    searchValue: i18n.t("analytics:filters.repetition.options.recurring"),
  },
  {
    value: "non-recurring",
    label: i18n.t("analytics:filters.repetition.options.non-recurring"),
    searchValue: i18n.t("analytics:filters.repetition.options.non-recurring"),
  },
]

const TaskCompletion = () => {
  const ref = useOutletContext<RefObject<RefPayload>>()

  const [assets, setAssets] = useState<uuid[]>([])
  const [assetGroup, setAssetGroup] = useState<uuid | null>(null)
  const [categories, setCategories] = useState<uuid[]>([])
  const [userAssignees, setUserAssignees] = useState<uuid[]>([])
  const [teamAssignees, setTeamAssignees] = useState<uuid[]>([])
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null)
  const [repetitionFilter, setRepetitionFilter] = useState<RepetitionFilter>("all")
  const [dateRange, setDateRange] = useState<DateRange | null>(getRange("last-30-days"))

  useImperativeHandle(ref, () => ({ ref: containerRef, dateRange: dateRange }), [
    containerRef,
    dateRange,
  ])

  const hasAdvancedAnalytics = useFeature("advanced_analytics")

  const [taskCompletionRes] = useTaskCompletionQuery({
    variables: {
      where: {
        ...(repetitionFilter !== "all"
          ? { recurrence_info: { _is_null: repetitionFilter === "non-recurring" } }
          : {}),
        ...(assets.length > 0 ? { assets: { asset_id: { _in: assets } } } : {}),
        ...(assetGroup ? { assets: { asset: { group_id: { _eq: assetGroup } } } } : {}),
        ...(categories.length > 0
          ? { categories: { work_order_category_id: { _in: categories } } }
          : {}),
        ...(userAssignees.length > 0
          ? { assignees: { user_id: { _in: userAssignees } } }
          : {}),
        ...(teamAssignees.length > 0
          ? { assigned_teams: { team_id: { _in: teamAssignees } } }
          : {}),
        ...(dateRange
          ? {
              completed_at: {
                _gte: dateRange?.start.toISOString(),
                _lte: dateRange?.end?.toISOString(),
              },
            }
          : { completed_at: { _is_null: false } }),
      },
    },
  })
  const taskCompletion = taskCompletionRes.data?.work_order ?? []

  const completionCount: number = useMemo(() => taskCompletion.length, [taskCompletion])

  const complianceCount: number = useMemo(() => {
    return taskCompletion.reduce((acc, task) => {
      if (
        task.completed_at &&
        task.due_date &&
        isBefore(new Date(task.completed_at), new Date(task.due_date))
      ) {
        acc += 1
      }

      return acc
    }, 0)
  }, [taskCompletion])

  const avgCycleTime: number = useMemo(() => {
    const completionDays = taskCompletion.reduce((acc, task) => {
      if (task.completed_at) {
        const difference = differenceInDays(
          new Date(task.completed_at),
          new Date(task.created_at)
        )

        acc.push(difference)
      }

      return acc
    }, [] as number[])

    const daySum = completionDays.reduce((acc, days) => acc + days, 0)
    return daySum / completionDays.length || 0
  }, [taskCompletion])

  const groupByUser = useMemo(() => {
    const dataArr = groupBy(taskCompletion, {
      groupId: (task) => task.completed_by_id,
    }).sort((a, b) => b.items.length - a.items.length)

    return dataArr.map((x) => ({
      name: `${x.items[0].completed_by?.first_name} ${x.items[0].completed_by?.last_name}`,
      value: x.items.length,
    }))
  }, [taskCompletion])

  const groupByUserChartOptions = useMemo<Highcharts.Options>(() => {
    return {
      chart: { type: "pie" },
      title: {
        text: i18n.t(
          "analytics:metrics.task_completion.statistics.grouped_by.completed_user"
        ),
      },
      series: [
        {
          type: "pie",
          name: i18n.t("common:task", { count: 2 }),
          data: groupByUser.map((data) => ({
            name: data.name,
            y: data.value,
          })),
        },
      ],
    } satisfies Highcharts.Options
  }, [groupByUser])

  const groupByPriority = useMemo(() => {
    const dataArr = groupBy(taskCompletion, { groupId: (task) => task.priority }).sort(
      (a, b) =>
        compareWorkOrderPriority(
          a.groupId as IWorkOrderPriorityEnum,
          b.groupId as IWorkOrderPriorityEnum
        )
    )

    return dataArr.map((x) => ({
      id: x.groupId!,
      name: translateWorkOrderPriority(x.items[0].priority),
      value: x.items.length,
    }))
  }, [taskCompletion])

  const groupByPriorityChartOptions = useMemo<Highcharts.Options>(() => {
    return {
      chart: { type: "pie" },
      title: {
        text: i18n.t("analytics:metrics.task_completion.statistics.grouped_by.priority"),
      },
      plotOptions: {
        pie: { showInLegend: true },
      },
      legend: {
        layout: "vertical",
        align: "right",
        floating: true,
      },
      series: [
        {
          type: "pie",
          name: i18n.t("common:task", { count: 2 }),
          data: groupByPriority.map((data) => ({
            name: data.name,
            y: data.value,
            color: priorityColor(data.id as IWorkOrderPriorityEnum),
          })),
        },
      ],
    } satisfies Highcharts.Options
  }, [groupByUser])

  const groupByCategory = useMemo(() => {
    return taskCompletion
      .reduce((acc, task) => {
        task.categories.forEach(({ category }) => {
          const group = acc.find((group) => group.label === category.label)
          if (group) {
            group.items.push(task)
          } else {
            acc.push({
              label: category.label,
              items: [task],
            })
          }
        })

        return acc
      }, [] as { label: string; items: ITaskCompletionFragment[] }[])
      .sort((a, b) => b.items.length - a.items.length)
  }, [taskCompletion])

  const groupByCategoryChartOptions = useMemo<Highcharts.Options>(() => {
    return {
      chart: { type: "column" },
      title: {
        text: i18n.t("analytics:metrics.task_completion.statistics.grouped_by.category"),
      },
      xAxis: { categories: groupByCategory.map((x) => x.label) },
      yAxis: { title: { text: i18n.t("common:task", { count: 2 }) } },
      series: [
        {
          type: "column",
          name: i18n.t("common:task", { count: 2 }),
          data: groupByCategory.map((data) => ({
            name: data.label,
            y: data.items.length,
          })),
        },
      ],
    } satisfies Highcharts.Options
  }, [groupByCategory])

  const timeSeriesData = useMemo(() => {
    return taskCompletion
      .sort((a, b) => sortDate(new Date(a.completed_at!), new Date(b.completed_at!)))
      .reduce((acc, task) => {
        const date = startOfDay(new Date(task.completed_at!)).getTime()

        if (acc[date]) {
          acc[date] += 1
        } else {
          acc[date] = 1
        }

        return acc
      }, {} as { [key: number]: number })
  }, [taskCompletion])

  const linearRegression = useMemo(() => {
    if (Object.keys(timeSeriesData).length === 0) return []

    // Convert the unix timestamps to number of days since the first timestamp
    const timestamps = Object.keys(timeSeriesData).map(Number)
    const minTimestamp = Math.min(...timestamps)
    const daysSinceStart = timestamps.map((t) => (t - minTimestamp) / (1000 * 60 * 60 * 24))

    // Prepare data for regression
    const points = daysSinceStart.map((day, i) => [day, timeSeriesData[timestamps[i]]])

    // Perform linear regression
    const regressionLine = ss.linearRegression(points)

    // Calculate the Unix timestamp for each of the next 30 days
    const lastTimestamp = Math.max(...timestamps)
    const futureTimestamps = Array.from(
      { length: 7 },
      (_, i) => lastTimestamp + i * 24 * 60 * 60 * 1000
    )

    // Predict values for each of these timestamps
    const futurePredictions = {} as { [key: number]: number }

    futureTimestamps.forEach((timestamp, i) => {
      const day = daysSinceStart[daysSinceStart.length - 1] + i
      futurePredictions[timestamp] = Math.max(
        Math.round(regressionLine.m * day + regressionLine.b),
        0
      )
    })

    return futurePredictions
  }, [timeSeriesData])

  const timeSeriesChartOptions = useMemo<Highcharts.Options>(() => {
    return {
      title: {
        text: i18n.t(
          "analytics:metrics.task_completion.statistics.grouped_by_completed_at.title"
        ),
      },
      legend: { enabled: false },
      chart: { zooming: { type: "x" } },
      xAxis: { type: "datetime" },
      yAxis: { title: { text: i18n.t("common:task", { count: 2 }) } },
      series: [
        {
          type: "area",
          name: i18n.t("common:task", { count: 2 }),
          data: Object.entries(timeSeriesData).map(([x, y]) => [Number(x), y]),
        },
        {
          type: "line",
          name: i18n.t("analytics:metrics.projected"),
          dashStyle: "Dash",
          allowPointSelect: false,
          marker: { enabled: false },
          data: Object.entries(linearRegression).map(([x, y]) => [Number(x), y]),
        },
      ],
    } satisfies Highcharts.Options
  }, [timeSeriesData])

  return (
    <ScrollArea vertical>
      <div className="grid gap-4 p-4">
        <div className="max-w-full md:max-w-lg">
          <span className="mb-1 block text-xs font-medium">
            {i18n.t("analytics:metrics.task_completion.date_range")}
          </span>
          <DateRangePicker
            onRangeChange={setDateRange}
            onlyFixedDateRange={!hasAdvancedAnalytics}
          />
        </div>
        <div className="grid grid-cols-1 items-start gap-4 sm:grid-cols-2 md:grid-cols-6 lg:grid-cols-8">
          <div className="col-span-2">
            <span className="mb-1 block text-xs font-medium">
              {i18n.t("assets:fields.group", { count: 1 })}
            </span>
            <AssetGroupSingleSelect value={assetGroup} onChange={setAssetGroup} />
          </div>
          <div>
            <span className="mb-1 block text-xs font-medium">
              {i18n.t("analytics:filters.repetition.title")}
            </span>
            <SelectPopover<RepetitionFilter>
              isClearable={false}
              value={repetitionFilter}
              items={repetitionFilters}
              onChange={(value) => value && setRepetitionFilter(value)}
            />
          </div>
          <div>
            <span className="mb-1 block text-xs font-medium">
              {i18n.t("common:asset", { count: 2 })}
            </span>
            <AssetMultiSelect compact value={assets} onChange={setAssets} />
          </div>
          <div>
            <span className="mb-1 block text-xs font-medium">
              {i18n.t("tasks:fields.category", { count: 2 })}
            </span>
            <WorkOrderCategoryMultiSelect
              compact
              value={categories}
              onChange={setCategories}
            />
          </div>
          <div>
            <span className="mb-1 block text-xs font-medium">
              {i18n.t("tasks:fields.assignee", { count: 2 })}
            </span>
            <UserMultiSelect compact value={userAssignees} onChange={setUserAssignees} />
          </div>
          <div>
            <span className="mb-1 block text-xs font-medium">
              {i18n.t("common:team", { count: 2 })}
            </span>
            <TeamMultiSelect compact value={teamAssignees} onChange={setTeamAssignees} />
          </div>
        </div>

        <hr />

        <div ref={setContainerRef} className="grid gap-4">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
            <Card
              cardClassName="h-full"
              title={i18n.t(
                "analytics:metrics.task_completion.statistics.completion_count.title"
              )}>
              <Statistic>{completionCount}</Statistic>
            </Card>
            <Card
              cardClassName="h-full"
              title={i18n.t(
                "analytics:metrics.task_completion.statistics.compliance_count.title"
              )}
              tooltip={i18n.t(
                "analytics:metrics.task_completion.statistics.compliance_count.tooltip"
              )}>
              <Statistic>{complianceCount}</Statistic>
            </Card>
            <Card
              title={i18n.t(
                "analytics:metrics.task_completion.statistics.avg_cycle_time.title"
              )}
              tooltip={i18n.t(
                "analytics:metrics.task_completion.statistics.avg_cycle_time.tooltip"
              )}>
              <Statistic>
                {Math.ceil(avgCycleTime)}{" "}
                {i18n.t("calendar:tokens.day", { count: avgCycleTime })}
              </Statistic>
            </Card>
          </div>

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
            <Card cardClassName="px-4 col-span-2 lg:col-span-2">
              <HighchartsReact highcharts={Highcharts} options={groupByUserChartOptions} />
            </Card>

            <Card cardClassName="px-4 col-span-2 lg:col-span-3">
              <HighchartsReact
                highcharts={Highcharts}
                options={groupByPriorityChartOptions}
              />
            </Card>

            <Card cardClassName="px-4 col-span-2 lg:col-span-3">
              <HighchartsReact highcharts={Highcharts} options={timeSeriesChartOptions} />
            </Card>

            <Card cardClassName="px-4 col-span-2 lg:col-span-2">
              <HighchartsReact
                highcharts={Highcharts}
                options={groupByCategoryChartOptions}
              />
            </Card>
          </div>
        </div>
      </div>
    </ScrollArea>
  )
}

export default TaskCompletion
