import PageViewLayout from "@components/page/page-view-layout"
import CustomView from "@components/shared/custom-view"
import DefaultView from "@components/shared/default-view"
import LoadingSpinner from "@components/shared/loading-spinner"
import { IViewDataTypeEnum } from "@elara/db"
import { useCustomViewsQuery } from "@graphql/documents/custom-view.generated"
import * as Tabs from "@radix-ui/react-tabs"
import { ReactNode } from "react"

import { useDataViewTabs } from "./data-view-tabs.hooks"
import { DataViewTabsTabBar } from "./data-view-tabs-tab-bar"

function DataViewTabsContent(props: {
  dataType: IViewDataTypeEnum
  action?: React.ReactNode
}) {
  const dataViewTabs = useDataViewTabs(props.dataType)

  const { tabs } = dataViewTabs

  if (dataViewTabs.isFetching) {
    return <LoadingSpinner />
  }

  return (
    <Tabs.Root
      value={dataViewTabs.selectedTab?.id ?? ""}
      onValueChange={(id) => id && dataViewTabs.selectTab(tabs.find((t) => t?.id === id)!)}
      className="flex min-h-0 min-w-0 flex-1 flex-col overflow-hidden">
      <DataViewTabsTabBar dataType={props.dataType} dataViewTabs={dataViewTabs} />

      {tabs.map((tab) => {
        if (!tab) return null

        return (
          <Tabs.Content
            key={tab.id}
            value={tab.id}
            className="flex min-h-0 flex-1 flex-col pt-3">
            {tab.type === "custom_view" && (
              <CustomView
                viewId={tab.id}
                key={tab.id}
                onCreateView={dataViewTabs.createView}
                action={props.action}
              />
            )}
            {tab.type === "default_view" && (
              <DefaultView
                data_type={props.dataType}
                onCreateView={dataViewTabs.createView}
                id={tab.id}
                config={tab.defaultView?.config!}
                action={props.action}
              />
            )}
          </Tabs.Content>
        )
      })}
    </Tabs.Root>
  )
}

export const DataViewTabs = (props: {
  dataType: IViewDataTypeEnum
  title: ReactNode
  subtitle?: string
  action?: React.ReactNode
}) => {
  const [customViewsQueryRes] = useCustomViewsQuery({ requestPolicy: "cache-first" })

  if (customViewsQueryRes.fetching) {
    return <LoadingSpinner showLogo />
  }

  let content = null

  switch (props.dataType) {
    case IViewDataTypeEnum.Asset:
      content = <DataViewTabsContent dataType={IViewDataTypeEnum.Asset} />
      break
    case IViewDataTypeEnum.AssetState:
      content = <DataViewTabsContent dataType={IViewDataTypeEnum.AssetState} />
      break
    case IViewDataTypeEnum.AssetArchive:
      content = <DataViewTabsContent dataType={IViewDataTypeEnum.AssetArchive} />
      break
    case IViewDataTypeEnum.Workorder:
      content = <DataViewTabsContent dataType={IViewDataTypeEnum.Workorder} />
      break
    case IViewDataTypeEnum.Meter:
      content = <DataViewTabsContent dataType={IViewDataTypeEnum.Meter} />
      break
    case IViewDataTypeEnum.Consumable:
      content = <DataViewTabsContent dataType={IViewDataTypeEnum.Consumable} />
      break
    case IViewDataTypeEnum.Contact:
      content = <DataViewTabsContent dataType={IViewDataTypeEnum.Contact} />
      break
    case IViewDataTypeEnum.ServiceRequest:
      content = <DataViewTabsContent dataType={IViewDataTypeEnum.ServiceRequest} />
      break
    case IViewDataTypeEnum.Maintenance:
      content = <DataViewTabsContent dataType={IViewDataTypeEnum.Maintenance} />
      break
    case IViewDataTypeEnum.MaintenanceArchive:
      content = <DataViewTabsContent dataType={IViewDataTypeEnum.MaintenanceArchive} />
      break
    default:
      throw new Error(`Unknown data type: ${props.dataType}`)
  }

  return (
    <PageViewLayout title={props.title} subtitle={props.subtitle} action={props.action}>
      {content}
    </PageViewLayout>
  )
}
