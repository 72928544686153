import { RelativeComparisonValue } from "@elara/select"
import i18n from "@i18n"
import { startOfDay, sub } from "date-fns"

import { useTaskData, UseTaskDataOptions } from "./use-task-data"

export type LogbookHistoryLength =
  | "1_week"
  | "1_month"
  | "3_months"
  | "6_months"
  | "1_year"
  | "all"

export type LogbookHistoryOption = {
  value: LogbookHistoryLength
  searchValue: string
  label: string
  duration: RelativeComparisonValue
}

export const logbookHistoryOptions: LogbookHistoryOption[] = [
  {
    value: "1_week" as LogbookHistoryLength,
    searchValue: i18n.t("tasks:logbook.history.one_week"),
    label: i18n.t("tasks:logbook.history.one_week"),
    duration: { weeks: 1 },
  },
  {
    value: "1_month" as LogbookHistoryLength,
    searchValue: i18n.t("tasks:logbook.history.one_month"),
    label: i18n.t("tasks:logbook.history.one_month"),
    duration: { months: 1 },
  },
  {
    value: "3_months" as LogbookHistoryLength,
    searchValue: i18n.t("tasks:logbook.history.three_months"),
    label: i18n.t("tasks:logbook.history.three_months"),
    duration: { months: 3 },
  },
  {
    value: "6_months" as LogbookHistoryLength,
    searchValue: i18n.t("tasks:logbook.history.six_months"),
    label: i18n.t("tasks:logbook.history.six_months"),
    duration: { months: 6 },
  },
  {
    value: "1_year" as LogbookHistoryLength,
    searchValue: i18n.t("tasks:logbook.history.one_year"),
    label: i18n.t("tasks:logbook.history.one_year"),
    duration: { years: 1 },
  },
  {
    value: "all" as LogbookHistoryLength,
    searchValue: i18n.t("tasks:logbook.history.all"),
    label: i18n.t("tasks:logbook.history.all"),
    duration: {},
  },
]

function logbookHistoryTimestamp(value: LogbookHistoryLength) {
  if (value === "all") return "1900-01-01T00:00:00.000Z"
  return sub(
    startOfDay(new Date()),
    logbookHistoryOptions.find((v) => v.value === value)?.duration ?? {}
  ).toISOString()
}

export const useLogbookData = (
  options: UseTaskDataOptions & {
    historyLength: LogbookHistoryLength
  }
) => {
  const completedAtSince = logbookHistoryTimestamp(options.historyLength)
  return useTaskData({ ...options, logbook: true, completedAtSince })
}
