import { Flex } from "@components/layout"
import { SelectItem } from "@components/shared/data-view/data-view-types"
import { CheckboxInput } from "@components/shared/form/checkbox-input"
import { DateTimeNative } from "@components/shared/native-date-time-pickers"
import { IconSpacer } from "@components/shared/spacers"
import { TextInput } from "@components/shared/text-input"
import { useFeature } from "@contexts/feature-flag-context"
import { IPermissionScopeEnum, IRecurrencePatternEnum, RecurrenceInfo } from "@elara/db"
import i18n from "@i18n"
import { ArrowsClockwise, Bell, Clock } from "@phosphor-icons/react"
import classNames from "classnames"
import { isBefore, setHours, subDays } from "date-fns"
import { dequal } from "dequal"
import { Field, Form, FormikProvider, useFormik } from "formik"
import React, { ReactNode, useEffect, useMemo } from "react"

import { IWorkOrderDataViewFragment } from "../../graphql/documents/fragments.generated"
import {
  useUpdateDueDateTimeMutation,
  useUpdateDueDateTimeWithRecurrenceMutation,
} from "../../graphql/documents/work-order.generated"
import { useCallbackRef, usePermissionScope } from "../../hooks"
import { isDateOverdue, parseDate } from "../../utils"
import { formatDate, getAbbreviatedWeekNames, setTimeFromDueTime } from "../../utils/date"
import { Icons } from "../shared"
import { FormField } from "../shared/form/form-field"
import {
  PopoverContent,
  PopoverContentProps,
  PopoverRoot,
  PopoverTrigger,
} from "../shared/popover"
import { SelectPopover } from "../shared/single-select"
import { RelativeDateValue } from "./work-order-data-view-filter-configuration"
import { WorkOrderDueDateCalendar } from "./work-order-due-date-calendar"
import { getWorkOrderRecurrenceInfo } from "./work-order-recurrence-info"

type FormValues = {
  recurrenceInfo: RecurrenceInfo | null
  reminderConfig: RelativeDateValue | null
  dueDate: Date | null
  dueTime: string | null
}

const relativeDateItems: SelectItem<RelativeDateValue>[] = [
  {
    label: i18n.t("calendar:relative_dates.start_of_day"),
    searchValue: i18n.t("calendar:relative_dates.start_of_day"),
    value: { type: "relative", days: 0 },
  },
  {
    label: i18n.t("calendar:relative_dates.x_token_before", {
      count: 1,
      token: i18n.t("calendar:tokens.day", { count: 1 }),
    }),
    searchValue: i18n.t("calendar:relative_dates.x_token_before", {
      count: 1,
      token: i18n.t("calendar:tokens.day", { count: 1 }),
    }),
    value: { type: "relative", days: 1 },
  },
  {
    label: i18n.t("calendar:relative_dates.x_token_before", {
      count: 2,
      token: i18n.t("calendar:tokens.day", { count: 2 }),
    }),
    searchValue: i18n.t("calendar:relative_dates.x_token_before", {
      count: 2,
      token: i18n.t("calendar:tokens.day", { count: 2 }),
    }),
    value: { type: "relative", days: 2 },
  },
  {
    label: i18n.t("calendar:relative_dates.x_token_before", {
      count: 1,
      token: i18n.t("calendar:tokens.week", { count: 1 }),
    }),
    searchValue: i18n.t("calendar:relative_dates.x_token_before", {
      count: 1,
      token: i18n.t("calendar:tokens.week", { count: 1 }),
    }),
    value: { type: "relative", weeks: 1 },
  },
  {
    label: i18n.t("calendar:relative_dates.x_token_before", {
      count: 2,
      token: i18n.t("calendar:tokens.week", { count: 2 }),
    }),
    searchValue: i18n.t("calendar:relative_dates.x_token_before", {
      count: 2,
      token: i18n.t("calendar:tokens.week", { count: 2 }),
    }),
    value: { type: "relative", weeks: 2 },
  },
  {
    label: i18n.t("calendar:relative_dates.x_token_before", {
      count: 3,
      token: i18n.t("calendar:tokens.week", { count: 3 }),
    }),
    searchValue: i18n.t("calendar:relative_dates.x_token_before", {
      count: 3,
      token: i18n.t("calendar:tokens.week", { count: 3 }),
    }),
    value: { type: "relative", weeks: 3 },
  },
  {
    label: i18n.t("calendar:relative_dates.x_token_before", {
      count: 1,
      token: i18n.t("calendar:tokens.month", { count: 1 }),
    }),
    searchValue: i18n.t("calendar:relative_dates.x_token_before", {
      count: 1,
      token: i18n.t("calendar:tokens.month", { count: 1 }),
    }),
    value: { type: "relative", months: 1 },
  },
  {
    label: i18n.t("calendar:relative_dates.x_token_before", {
      count: 2,
      token: i18n.t("calendar:tokens.month", { count: 2 }),
    }),
    searchValue: i18n.t("calendar:relative_dates.x_token_before", {
      count: 2,
      token: i18n.t("calendar:tokens.month", { count: 2 }),
    }),
    value: { type: "relative", months: 2 },
  },
  {
    label: i18n.t("calendar:relative_dates.x_token_before", {
      count: 4,
      token: i18n.t("calendar:tokens.month", { count: 4 }),
    }),
    searchValue: i18n.t("calendar:relative_dates.x_token_before", {
      count: 4,
      token: i18n.t("calendar:tokens.month", { count: 4 }),
    }),
    value: { type: "relative", months: 4 },
  },
  {
    label: i18n.t("calendar:relative_dates.x_token_before", {
      count: 6,
      token: i18n.t("calendar:tokens.month", { count: 6 }),
    }),
    searchValue: i18n.t("calendar:relative_dates.x_token_before", {
      count: 6,
      token: i18n.t("calendar:tokens.month", { count: 6 }),
    }),
    value: { type: "relative", months: 6 },
  },
  {
    label: i18n.t("calendar:relative_dates.x_token_before", {
      count: 8,
      token: i18n.t("calendar:tokens.month", { count: 8 }),
    }),
    searchValue: i18n.t("calendar:relative_dates.x_token_before", {
      count: 8,
      token: i18n.t("calendar:tokens.month", { count: 8 }),
    }),
    value: { type: "relative", months: 8 },
  },
  {
    label: i18n.t("calendar:relative_dates.x_token_before", {
      count: 10,
      token: i18n.t("calendar:tokens.month", { count: 10 }),
    }),
    searchValue: i18n.t("calendar:relative_dates.x_token_before", {
      count: 10,
      token: i18n.t("calendar:tokens.month", { count: 10 }),
    }),
    value: { type: "relative", months: 10 },
  },
  {
    label: i18n.t("calendar:relative_dates.x_token_before", {
      count: 12,
      token: i18n.t("calendar:tokens.month", { count: 12 }),
    }),
    searchValue: i18n.t("calendar:relative_dates.x_token_before", {
      count: 12,
      token: i18n.t("calendar:tokens.month", { count: 12 }),
    }),
    value: { type: "relative", months: 12 },
  },
]

const makeMonthlyDayOptionValues = () => {
  const suffix = i18n.language === "de-DE" ? "." : "th"
  const dayOptions: { value: number; label: string }[] = []

  dayOptions.push({
    value: 1,
    label: `1${i18n.language === "de-DE" ? "." : "st"}`,
  })
  dayOptions.push({
    value: 2,
    label: `2${i18n.language === "de-DE" ? "." : "nd"}`,
  })
  for (let i = 3; i <= 31; i++) {
    dayOptions.push({ value: i, label: `${i}${suffix}` })
  }

  return dayOptions
}

const DueDateForm = (props: WorkOrderDueDatePopoverProps) => {
  const hasMaintenanceFeature = useFeature("maintenance")

  const [, updateDueDateTime] = useUpdateDueDateTimeMutation()
  const [, updateDueDateTimeWithRecurrence] = useUpdateDueDateTimeWithRecurrenceMutation()
  const scope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)

  const { workOrder, fromForm } = props

  const reminderItems = useMemo(
    () => [
      {
        label: i18n.t("calendar:relative_dates.custom_date"),
        searchValue: i18n.t("calendar:relative_dates.custom_date"),
        value: { type: "customDate" as "customDate", dateTime: new Date().toISOString() },
      },
      ...relativeDateItems,
    ],
    []
  )

  const submitChange = async (values: FormValues) => {
    if (props.workOrder && values.recurrenceInfo && values.dueDate) {
      await updateDueDateTimeWithRecurrence(
        {
          workOrderId: props.workOrder.id,
          dueDate: formatDate(values.dueDate, "yyyy-MM-dd"),
          dueTime: values.dueTime || null,
          reminderConfig: values.reminderConfig || null,
          recurrenceInfo: values.recurrenceInfo,
        },
        scope.context()
      )
    } else if (props.workOrder) {
      await updateDueDateTime(
        {
          workOrderId: props.workOrder.id,
          dueDate: values.dueDate ? formatDate(values.dueDate, "yyyy-MM-dd") : null,
          dueTime: values.dueTime || null,
          reminderConfig: values.reminderConfig,
        },
        scope.context()
      )
    } else if (props.fromForm) {
      props.fromForm.onSubmit(values)
    }
  }

  const initialValues = {
    dueDate: parseDate(workOrder ? workOrder.due_date : fromForm?.dueDate),
    dueTime: (workOrder ? workOrder.due_time : fromForm?.dueTime) ?? null,
    recurrenceInfo: (workOrder
      ? workOrder.recurrence_info
      : fromForm?.recurrenceInfo) as FormValues["recurrenceInfo"],
    reminderConfig: (workOrder
      ? workOrder.reminder_config
      : null) as FormValues["reminderConfig"],
  }

  const formikBag = useFormik<FormValues>({
    initialValues,
    enableReinitialize: true,
    onSubmit: submitChange,
  })

  const { recurrenceInfo } = formikBag.values

  useEffect(() => {
    const { values } = formikBag
    if (
      (values.dueDate ? formatDate(values.dueDate, "yyyy-MM-dd") : null) !==
        (initialValues.dueDate ? formatDate(initialValues.dueDate, "yyyy-MM-dd") : null) ||
      (values.dueTime || null) !== initialValues.dueTime ||
      !dequal(values.recurrenceInfo, initialValues.recurrenceInfo) ||
      !dequal(values.reminderConfig, initialValues.reminderConfig)
    ) {
      submitChange(values)
    }
  }, [formikBag.values])

  const loopMonthly = makeMonthlyDayOptionValues().map((i) => ({
    ...i,
    searchValue: i.label,
  }))

  const updateWeekDay = (day: number) => {
    if (
      recurrenceInfo?.recurrencePattern === IRecurrencePatternEnum.Weekly ||
      recurrenceInfo?.recurrencePattern === IRecurrencePatternEnum.Hourly
    ) {
      const weekdays = recurrenceInfo?.weekdays ?? []
      if (weekdays.includes(day)) {
        formikBag.setFieldValue(
          "recurrenceInfo.weekdays",
          weekdays.filter((d: number) => d !== day)
        )
      } else {
        formikBag.setFieldValue("recurrenceInfo.weekdays", weekdays.concat(day))
      }
    }
  }

  const changeDueDate = useCallbackRef((date: Date) => {
    formikBag.setValues((v) => ({
      ...v,
      dueDate: date,
      reminderConfig: v.dueDate === null ? { type: "relative", days: 0 } : v.reminderConfig,
    }))
  })

  return (
    <FormikProvider value={formikBag}>
      <Form>
        <div className="mb-2 flex h-11 items-baseline justify-between border-b pb-3 text-sm font-medium">
          {formikBag.values.dueDate ? (
            <span className="inline-flex h-8 items-center">
              {formatDate(formikBag.values.dueDate, "P")}
            </span>
          ) : (
            <span className="inline-flex h-7 items-center text-gray-500">
              {i18n.t("common:without_token", {
                token: i18n.t("calendar:tokens.due_date"),
              })}
            </span>
          )}
          {formikBag.values.dueTime && (
            <Flex row align="baseline" className="h-8">
              <span className="mr-2 text-gray-600">{i18n.t("calendar:tokens.at")}</span>
              <FormField noStyle label="Fälligkeitsuhrzeit" name="dueTime">
                <input step={60} className="rounded border px-1.5 py-0.5" type="time" />
              </FormField>
            </Flex>
          )}
        </div>
        <div className="grid grid-cols-1">
          <FormField noStyle name="dueDate">
            {({ field }) => {
              return (
                <WorkOrderDueDateCalendar
                  dueDate={field.value}
                  activeDate={field.value}
                  changeDueDate={changeDueDate}
                />
              )
            }}
          </FormField>

          {(!!recurrenceInfo || !!formikBag.values.reminderConfig) && (
            <div className="grid gap-y-2 py-2">
              {!hasMaintenanceFeature && recurrenceInfo && (
                <>
                  <div className="col-span-2 flex items-center gap-2">
                    <p className="text-sm">{i18n.t("calendar:recurrence.prefix")}</p>

                    <TextInput
                      min={1}
                      size="small"
                      type="number"
                      className="w-[50px] text-right"
                      value={recurrenceInfo.interval || 1}
                      onChange={(e) =>
                        formikBag.setFieldValue(
                          "recurrenceInfo.interval",
                          e.target.valueAsNumber
                        )
                      }
                    />

                    <SelectPopover
                      className="w-[110px]"
                      onChange={(recurrencePattern) => {
                        if (recurrencePattern === IRecurrencePatternEnum.Monthly) {
                          formikBag.setFieldValue("recurrenceInfo", {
                            ...recurrenceInfo,
                            recurrencePattern,
                            day: formikBag.values.dueDate?.getDate(),
                          })
                        } else if (recurrencePattern === IRecurrencePatternEnum.Weekly) {
                          const day = formikBag.values.dueDate?.getDay()
                          formikBag.setFieldValue("recurrenceInfo", {
                            ...recurrenceInfo,
                            recurrencePattern,
                            weekdays: [day],
                          })
                        } else if (recurrencePattern === IRecurrencePatternEnum.Hourly) {
                          const day = formikBag.values.dueDate?.getDay()
                          formikBag.setFieldValue("recurrenceInfo", {
                            ...recurrenceInfo,
                            recurrencePattern,
                            weekdays: [day],
                            startTime: "08:00",
                            endTime: "16:00",
                          })
                        } else {
                          formikBag.setFieldValue("recurrenceInfo", {
                            ...recurrenceInfo,
                            recurrencePattern,
                          })
                        }
                      }}
                      value={recurrenceInfo?.recurrencePattern ?? null}
                      isClearable={false}
                      items={[
                        {
                          label: i18n.t("calendar:tokens.hour", {
                            count: Number(recurrenceInfo?.interval),
                          }),
                          searchValue: i18n.t("calendar:tokens.hour", { count: 2 }),
                          value: IRecurrencePatternEnum.Hourly,
                        },
                        {
                          label: i18n.t("calendar:tokens.day", {
                            count: Number(recurrenceInfo?.interval),
                          }),
                          searchValue: i18n.t("calendar:tokens.day", { count: 2 }),
                          value: IRecurrencePatternEnum.Daily,
                        },
                        {
                          label: i18n.t("calendar:tokens.week", {
                            count: Number(recurrenceInfo?.interval),
                          }),
                          searchValue: i18n.t("calendar:tokens.week", { count: 2 }),
                          value: IRecurrencePatternEnum.Weekly,
                        },
                        {
                          label: i18n.t("calendar:tokens.month", {
                            count: Number(recurrenceInfo?.interval),
                          }),
                          searchValue: i18n.t("calendar:tokens.month", { count: 2 }),
                          value: IRecurrencePatternEnum.Monthly,
                        },
                        {
                          label: i18n.t("calendar:tokens.year", {
                            count: Number(recurrenceInfo?.interval),
                          }),
                          searchValue: i18n.t("calendar:tokens.year", { count: 2 }),
                          value: IRecurrencePatternEnum.Yearly,
                        },
                      ]}
                    />

                    <p className="text-sm">{i18n.t("calendar:recurrence.suffix")}</p>
                  </div>

                  {recurrenceInfo?.recurrencePattern === IRecurrencePatternEnum.Weekly && (
                    <div className="col-span-2 flex flex-row justify-between text-sm">
                      {getAbbreviatedWeekNames().map((day, idx) => (
                        <div key={day} className="flex flex-col">
                          <p className="my-1">{day}</p>
                          <CheckboxInput
                            checked={(
                              (recurrenceInfo?.weekdays ?? []) as number[]
                            ).includes((idx + 1) % 7)}
                            onChange={() => updateWeekDay((idx + 1) % 7)}
                          />
                        </div>
                      ))}
                    </div>
                  )}

                  {recurrenceInfo?.recurrencePattern === IRecurrencePatternEnum.Monthly && (
                    <div className="col-span-2 flex items-center gap-2">
                      <p className="text-sm">
                        {i18n.t("calendar:recurrence.monthly.prefix")}
                      </p>

                      <SelectPopover
                        className="w-[80px]"
                        items={loopMonthly}
                        isClearable={false}
                        value={recurrenceInfo?.day}
                        onChange={(day) =>
                          formikBag.setFieldValue("recurrenceInfo.day", day)
                        }
                      />

                      <p className="text-sm">
                        {i18n.t("calendar:recurrence.monthly.suffix")}
                      </p>
                    </div>
                  )}
                  {recurrenceInfo?.recurrencePattern === IRecurrencePatternEnum.Hourly && (
                    <div className="col-span-2 text-sm">
                      <div className="flex items-center justify-between gap-2">
                        <span>{i18n.t("calendar:recurrence.hourly.prefix")}</span>
                        <Field
                          type="time"
                          name="recurrenceInfo.startTime"
                          className="rounded border px-1.5 py-0.5"
                        />
                        <span>{i18n.t("common:and")}</span>
                        <Field
                          type="time"
                          name="recurrenceInfo.endTime"
                          className="rounded border px-1.5 py-0.5"
                        />
                      </div>
                      <div className="flex flex-row items-end justify-between">
                        <span className="-mb-0.5">{i18n.t("calendar:tokens.on")}</span>
                        {getAbbreviatedWeekNames().map((day, idx) => (
                          <div key={day} className="flex flex-col">
                            <p className="my-1">{day}</p>
                            <CheckboxInput
                              checked={(
                                (recurrenceInfo?.weekdays ?? []) as number[]
                              ).includes((idx + 1) % 7)}
                              onChange={() => updateWeekDay((idx + 1) % 7)}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              )}

              {formikBag.values.reminderConfig && (
                <>
                  <div className="col-span-2 flex items-center gap-2">
                    <span className="text-sm">
                      {i18n.t("calendar:messages.reminder_prefix")}
                    </span>

                    <SelectPopover
                      className="flex-1"
                      items={reminderItems}
                      isClearable={false}
                      value={
                        (formikBag.values.reminderConfig.type === "customDate"
                          ? reminderItems[0].value
                          : formikBag.values.reminderConfig) as RelativeDateValue
                      }
                      onChange={(relativeDateItem) => {
                        if (
                          relativeDateItem?.type === "customDate" &&
                          formikBag.values.dueDate
                        ) {
                          return formikBag.setFieldValue("reminderConfig", {
                            type: "customDate",
                            dateTime: setHours(
                              subDays(formikBag.values.dueDate, 1),
                              6
                            ).toISOString(),
                          })
                        }

                        formikBag.setFieldValue("reminderConfig", relativeDateItem)
                      }}
                    />
                  </div>

                  {formikBag.values.reminderConfig?.type === "customDate" && (
                    <div className="col-span-2">
                      <DateTimeNative
                        className="flex-1"
                        value={new Date(formikBag.values.reminderConfig?.dateTime)}
                        onChange={(val) => {
                          formikBag.setFieldValue("reminderConfig", {
                            type: "customDate",
                            dateTime: val?.toISOString(),
                          })
                        }}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          )}

          <div>
            <div className="mt-2 flex justify-between">
              <div className="flex items-center">
                <button
                  type={"button"}
                  className="p-1 disabled:opacity-50"
                  disabled={!formikBag.values.dueDate}
                  onClick={() =>
                    formikBag.values.dueTime
                      ? formikBag.setFieldValue("dueTime", null)
                      : formikBag.setFieldValue("dueTime", `${new Date().getHours()}:00`)
                  }>
                  <Clock
                    className={classNames(
                      "mr-1.5 bg-white text-gray-600 rounded box-content",
                      {
                        "ring-1 ring-offset-2 p-0.5 ring-gray-700 bg-gray-50 ":
                          formikBag.values.dueTime,
                      }
                    )}
                  />
                </button>

                {!hasMaintenanceFeature && (
                  <button
                    type={"button"}
                    className="p-1 disabled:opacity-50"
                    disabled={!formikBag.values.dueDate}
                    onClick={() =>
                      recurrenceInfo
                        ? formikBag.setFieldValue("recurrenceInfo", null)
                        : formikBag.setFieldValue("recurrenceInfo", {
                            recurrencePattern: IRecurrencePatternEnum.Monthly,
                            day:
                              formikBag.values.dueDate?.getDate() ?? new Date().getDate(),
                            weekdays: [],
                            interval: 1,
                          })
                    }>
                    <ArrowsClockwise
                      className={classNames(
                        "mr-1.5 bg-white text-gray-600 rounded box-content",
                        {
                          "ring-1 ring-offset-2 p-0.5 ring-gray-700 bg-gray-50":
                            recurrenceInfo,
                        }
                      )}
                    />
                  </button>
                )}

                <button
                  type={"button"}
                  className="p-1 disabled:opacity-50"
                  onClick={() =>
                    formikBag.setFieldValue(
                      "reminderConfig",
                      formikBag.values.reminderConfig ? null : { type: "relative", days: 0 }
                    )
                  }
                  disabled={!formikBag.values.dueDate}>
                  <Bell
                    className={classNames(
                      "mr-1.5 bg-white text-gray-600 rounded box-content",
                      {
                        "ring-1 ring-offset-2 p-0.5 ring-gray-700 bg-gray-50":
                          formikBag.values.reminderConfig,
                      }
                    )}
                  />
                </button>
              </div>
              <div className="flex">
                <button
                  type="button"
                  className="flex items-center rounded bg-gray-100 px-3 py-1.5 text-center text-sm font-medium text-gray-700"
                  onClick={() => {
                    formikBag.setValues({
                      dueDate: null,
                      dueTime: null,
                      recurrenceInfo: null,
                      reminderConfig: null,
                    })
                  }}>
                  {i18n.t("common:without_token", {
                    token: i18n.t("calendar:tokens.date"),
                  })}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </FormikProvider>
  )
}

type WorkOrderDueDatePopoverProps = {
  workOrder?: Pick<
    IWorkOrderDataViewFragment,
    "due_date" | "due_time" | "recurrence_info" | "reminder_config" | "id"
  >
  fromForm?: {
    dueDate: string | null
    dueTime: string | null
    recurrenceInfo: RecurrenceInfo | null
    reminderConfig: FormValues["reminderConfig"]
    onSubmit: (values: FormValues) => void
  }
  className?: string
  trigger?: ReactNode
  showCalendarIcon?: boolean
  popoverContentProps?: PopoverContentProps
  textColor?: "base" | "lighter"
  markAsOverdue?: boolean
  compact?: boolean
  showRecurrenceInfo?: boolean
}

const reminderIsActive = (
  reminder: FormValues["reminderConfig"] | null,
  dueDateTime: Date
) => {
  if (!reminder) return false

  const reminderDateTime =
    reminder?.type === "customDate" ? new Date(reminder.dateTime) : dueDateTime

  return isBefore(new Date(), reminderDateTime)
}

export const WorkOrderDueDate = ({
  workOrder,
  fromForm,
  showRecurrenceInfo,
  ...props
}: WorkOrderDueDatePopoverProps & {
  iconSize?: number
  mode?: "select" | "static"
  showRecurrenceInfo?: boolean
}) => {
  const hasMaintenanceFeature = useFeature("maintenance")

  const dueDate = parseDate(workOrder ? workOrder.due_date : fromForm?.dueDate)
  const dueTime = setTimeFromDueTime(
    dueDate,
    workOrder ? workOrder.due_time : fromForm?.dueTime
  )

  const recurrence = (
    workOrder ? workOrder?.recurrence_info : fromForm?.recurrenceInfo
  ) as RecurrenceInfo | null

  const reminder = (
    workOrder ? workOrder?.reminder_config : fromForm?.reminderConfig
  ) as FormValues["reminderConfig"]

  const showReminderIcon = dueDate && reminderIsActive(reminder, dueTime ?? dueDate)
  const showCalendarIcon = props.showCalendarIcon ?? true

  const mode = props.mode ?? "select"

  return (
    <>
      {props.trigger && !(workOrder?.due_date || fromForm?.dueDate) ? (
        props.trigger
      ) : (
        <div
          className={classNames(
            "flex min-w-0 shrink",
            mode === "static" ? "flex-row items-center" : "flex-col"
          )}>
          <IconSpacer
            preset={
              mode === "static" ? "dueDateRow" : props.compact ? "compactSelect" : "select"
            }
            className={classNames(props.className, "text-sm", {
              "text-gray-900": dueDate && props.textColor === "base",
              "text-gray-700": dueDate && props.textColor === "lighter",
              "tracking-tight": dueDate,
              "text-gray-500": !dueDate,
            })}
            icon={
              showCalendarIcon ? (
                <Icons.Calendar
                  className={classNames({
                    "text-red-600": !props.markAsOverdue
                      ? false
                      : isDateOverdue(
                          workOrder ? workOrder.due_date : fromForm?.dueDate,
                          workOrder ? workOrder.due_time : fromForm?.dueTime
                        ),
                    "w-[1em] h-[1em]": !props.iconSize,
                  })}
                  width={props.iconSize}
                  height={props.iconSize}
                />
              ) : null
            }>
            <span className="truncate pt-[0.5px]">
              {dueDate
                ? formatDate(dueDate, "P")
                : i18n.t("calendar:relative_dates.no_date")}
              {dueTime && `, ${formatDate(dueTime, "p")}`}
            </span>

            {((!showRecurrenceInfo && recurrence?.recurrencePattern) ||
              showReminderIcon) && (
              <div className="flex flex-row pl-2 pt-[0.5px]">
                {showReminderIcon && <Bell className="text-gray-700" />}
                {!showRecurrenceInfo && recurrence?.recurrencePattern && (
                  <span className="ml-1">
                    <Icons.Repeat className="text-gray-700" />
                  </span>
                )}
              </div>
            )}
          </IconSpacer>

          {!hasMaintenanceFeature &&
            recurrence?.recurrencePattern &&
            showRecurrenceInfo && (
              <div
                className={classNames(
                  "flex items-center text-start text-xs text-gray-500",
                  mode === "static" ? "ml-1" : "leading-none"
                )}>
                <span
                  className={classNames(
                    "flex justify-center",
                    mode === "static" ? "mr-1" : "w-[34px]"
                  )}>
                  <Icons.Repeat className="text-gray-700" />
                </span>
                {getWorkOrderRecurrenceInfo(recurrence, dueDate, false)}
              </div>
            )}
        </div>
      )}
    </>
  )
}

export const WorkOrderDueDatePopover = React.memo((props: WorkOrderDueDatePopoverProps) => {
  const { workOrder, fromForm } = props

  if (!workOrder && !fromForm) return null

  return (
    <PopoverRoot modal>
      <PopoverTrigger
        onClick={(e) => {
          e.stopPropagation()
        }}
        asChild>
        <button
          type="button"
          className={classNames("datepicker_label flex items-center", props.className, {
            "rounded hover:border-gray-300 border border-transparent": !props.trigger,
          })}>
          <WorkOrderDueDate
            {...props}
            className={undefined}
            textColor={props.textColor ?? "base"}
            workOrder={workOrder}
            fromForm={fromForm}
            showCalendarIcon={props.showCalendarIcon}
            showRecurrenceInfo={props.showRecurrenceInfo}
          />
        </button>
      </PopoverTrigger>
      <PopoverContent
        alignOffset={4}
        sideOffset={12}
        onClick={(e) => e.stopPropagation()}
        className={"min-w-[320px] rounded border bg-white p-3"}
        dialog={{ className: "bg-white border rounded pt-5 px-5" }}
        {...props.popoverContentProps}>
        <DueDateForm {...props} />
      </PopoverContent>
    </PopoverRoot>
  )
})
