import * as Tabs from "@radix-ui/react-tabs"
import { cn } from "@utils"
import classNames from "classnames"
import React from "react"

const Root = React.forwardRef<
  React.ElementRef<typeof Tabs.Root>,
  React.ComponentProps<typeof Tabs.Root>
>((props, forwardedRef) => {
  return <Tabs.Root {...props} ref={forwardedRef} />
})

const List = React.forwardRef<
  React.ElementRef<typeof Tabs.List>,
  React.ComponentProps<typeof Tabs.List>
>(({ children, ...props }, forwardedRef) => {
  return (
    <Tabs.List
      {...props}
      ref={forwardedRef}
      className={classNames(
        "relative snap-mandatory snap-x flex min-h-0 overflow-x-auto scrollbar-hide",
        props.className
      )}>
      <div className="w-3 border-b border-b-gray-200 sm:w-0" />
      {children}
      <div className="w-3 shrink-0 grow border-b border-b-gray-200 sm:w-0" />
    </Tabs.List>
  )
})

const Content = React.forwardRef<
  React.ElementRef<typeof Tabs.Content>,
  React.ComponentProps<typeof Tabs.Content>
>((props, forwardedRef) => {
  return (
    <Tabs.Content
      {...props}
      ref={forwardedRef}
      className={classNames("flex flex-col flex-1 min-h-0", props.className)}
    />
  )
})

const Trigger = React.forwardRef<
  React.ElementRef<typeof Tabs.Trigger>,
  React.ComponentProps<typeof Tabs.Trigger>
>((props, forwardedRef) => {
  return (
    <Tabs.Trigger
      {...props}
      ref={forwardedRef}
      title={typeof props.children === "string" ? props.children : undefined}
      className={cn(
        "snap-center border-b pb-px border-gray-200",
        "transition-colors duration-200 ease-in-out",
        "radix-state-active:pb-0 radix-state-active:border-b-2 radix-state-active:border-blue-600 group",
        props.className
      )}>
      <div
        className={classNames(
          "font-medium m-1 whitespace-nowrap rounded-lg px-3 py-1.5 text-sm text-gray-500 transition-colors hover:bg-gray-100 ",
          "group-radix-state-active:text-blue-700 group-radix-state-active:text-bold group-radix-state-active:!bg-transparent"
        )}
        data-title={props.children}>
        {props.children}
      </div>
    </Tabs.Trigger>
  )
})

export default {
  Root,
  List,
  Content,
  Trigger,
}
