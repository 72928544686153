import { useMemo } from "react"
import { Data, DataValue, FieldPrimitiveValue, useDeepMemo, _unsafeGetField } from "./data"

export type OrderByDirection = "asc" | "desc"
export type OrderByNullPosition = "nulls_first" | "nulls_last"
export type OrderByOrderOperator =
  | "asc"
  | "asc_nulls_first"
  | "asc_nulls_last"
  | "desc"
  | "desc_nulls_first"
  | "desc_nulls_last"

type OrderByOrderOperatorFunc<T> = {
  transform: (v: T) => number | string | null
  dir: OrderByOrderOperator
}
export type OrderByOrderOperatorValue<T> =
  | OrderByOrderOperator
  | OrderByOrderOperatorFunc<T>

type OrderByTransformation<D extends Data> = Partial<{
  [P in keyof D as D[P] extends Array<Data>
    ? never
    : P]: D[P] extends FieldPrimitiveValue | null
    ? OrderByOrderOperatorValue<D[P]>
    : D[P] extends Data
    ? OrderByTransformation<D[P]>
    : D[P] extends infer D1 | null
    ? D1 extends Data
      ? OrderByTransformation<D1>
      : never
    : never
}>
export type OrderBy<D extends Data> =
  | OrderByTransformation<Required<D>>
  | OrderByOrderOperatorFunc<D>

export type GeneralOrderBy<T extends {}> = OrderBy<{
  [P in keyof T]: T[P] extends DataValue | null ? T[P] : never
}>

export function orderByOperator(
  dir: OrderByDirection,
  nulls: OrderByNullPosition
): OrderByOrderOperator {
  return `${dir}_${nulls}`
}

const collator = new Intl.Collator("de-DE", { numeric: true })

export function compare<T extends FieldPrimitiveValue>(
  a: T | null,
  b: T | null,
  dir: OrderByDirection,
  nulls: OrderByNullPosition,
  transform?: (v: T | null) => number | string | null
): number {
  if (transform) return compare(transform(a), transform(b), dir, nulls)
  if (a === b) return 0
  if (a === null) {
    return nulls === "nulls_first" ? -1 : 1
  }
  if (b === null) return nulls === "nulls_first" ? 1 : -1

  const multiplier = dir === "asc" ? 1 : -1

  if (typeof a === "string" && typeof b === "string") {
    return multiplier * collator.compare(a, b)
  }

  return multiplier * (a < b ? -1 : 1)
}

type ComparisonItem = {
  keys: string[]
  transform?: (v: any) => number | string | null
  dir: OrderByDirection
  nulls: OrderByNullPosition
}

export function orderByComparisonList<D extends Data>(
  orderBy: OrderBy<D>
): ComparisonItem[] {
  const comparisonList: ComparisonItem[] = []

  function addToComparisonList<D1 extends Data>(partialKeys: string[], obj: OrderBy<D1>) {
    for (let [key, value] of Object.entries(obj)) {
      // If string ok then OrderByOrderOperator
      const keys = partialKeys.concat([key])
      if (typeof value === "string") {
        const [dir, firstOrLast = "first"] = value?.split("_nulls_")
        comparisonList.push({
          keys,
          dir: dir as OrderByDirection,
          nulls: `nulls_${firstOrLast}` as OrderByNullPosition,
        })
      } else if (
        typeof value === "object" &&
        typeof (value as Partial<OrderByOrderOperatorFunc<any>>)?.transform ===
          "function" &&
        typeof (value as Partial<OrderByOrderOperatorFunc<any>>)?.dir === "string"
      ) {
        const val = value as OrderByOrderOperatorFunc<any>
        const [dir, firstOrLast = "first"] = val.dir.split("_nulls_")
        comparisonList.push({
          keys,
          transform: val.transform,
          dir: dir as OrderByDirection,
          nulls: `nulls_${firstOrLast}` as OrderByNullPosition,
        })
      } else {
        addToComparisonList(keys, value as OrderBy<{}>)
      }
    }
  }

  addToComparisonList([], orderBy)

  return comparisonList
}

export function orderByCompare<D extends Data>(orderBy: OrderBy<D>) {
  const comparisonList = orderByComparisonList(orderBy)

  return (a: D, b: D) => {
    for (let comparison of comparisonList) {
      const fieldA = _unsafeGetField(a, comparison.keys)
      const fieldB = _unsafeGetField(b, comparison.keys)
      const cmp = compare(
        fieldA,
        fieldB,
        comparison.dir,
        comparison.nulls,
        comparison.transform
      )
      if (cmp != 0) return cmp
    }
    return 0
  }
}

export function orderByCompareList<D extends Data>(orderBys: OrderBy<D>[]) {
  const comparisonLists = orderBys.map((orderBy) => orderByComparisonList(orderBy))

  return (a: D, b: D) => {
    for (let comparisonList of comparisonLists) {
      for (let comparison of comparisonList) {
        const fieldA = _unsafeGetField(a, comparison.keys)
        const fieldB = _unsafeGetField(b, comparison.keys)

        const cmp = compare(
          fieldA,
          fieldB,
          comparison.dir,
          comparison.nulls,
          comparison.transform
        )

        if (cmp != 0) return cmp
      }
    }
    return 0
  }
}

export function orderBy<T extends {}>(data: Array<T>, orderBy: GeneralOrderBy<T>) {
  const compare = orderByCompare(orderBy) as (a: T, b: T) => number

  return data.slice().sort(compare)
}

/**
 * Apply the specified `orderBy` conditions to the given data. Performs memorization on the ordered data
 * and the `orderBy` parameter. On the `orderBy` parameter we perform deep equality check.
 *
 * @param data Data to be filtered
 * @param orderBy Declarative ordering description
 * @returns [orderData, compare] where `orderData = data.sort(compare)`.
 */
export function useOrderBy<D extends Data>(data: Array<D>, orderBy: OrderBy<D>) {
  const compare = useOrderByCompare(orderBy)
  const value: [D[], (a: D, b: D) => number] = useMemo(
    () => [data.slice().sort(compare), compare],
    [data, compare]
  )
  return value
}

export function useOrderByCompare<D extends Data>(orderBy: OrderBy<D>) {
  return useDeepMemo(() => orderByCompare(orderBy), [orderBy])
}

export function useOrderByCompareList<D extends Data>(orderBys: OrderBy<D>[]) {
  return useDeepMemo(() => orderByCompareList(orderBys), [orderBys])
}
