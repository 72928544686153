import { uuid } from "@elara/db"
import { useUserSelectDataQuery } from "@graphql/documents/user.generated"
import { SuggestionProps } from "@tiptap/suggestion"
import classNames from "classnames"
import { matchSorter } from "match-sorter"
import React, {
  forwardRef,
  KeyboardEvent,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react"

export type MentionItem = { id: uuid; label: string }

const RichtTextMentionList = forwardRef<unknown, SuggestionProps<MentionItem>>(
  (props, ref) => {
    const [queryRes] = useUserSelectDataQuery({ requestPolicy: "cache-first" })
    const users = useMemo(
      () =>
        queryRes.data?.location_member
          ?.map((u) => u.user)
          ?.filter((u) => !u.deleted_at)
          .map((u) => ({
            id: u.id,
            label: `${u.first_name} ${u.last_name}`,
          })) ?? [],
      [queryRes.data?.location_member]
    )
    const items = useMemo(
      () => matchSorter(users, props.query, { keys: ["label"] }).slice(0, 8),
      [props.query, users]
    )

    const [selectedIndex, setSelectedIndex] = useState(0)

    const refs = useMemo(
      () =>
        items.reduce((acc, item) => {
          acc[item.id] = React.createRef<HTMLButtonElement>()
          return acc
        }, {} as Record<uuid, React.RefObject<HTMLButtonElement>>),
      [items]
    )

    const selectItem = (index: number) => {
      const item = items[index]

      if (item) {
        props.command(item)
      }
    }

    const scrollItemIntoView = (newIndex: number) => {
      const newItem = items[newIndex]
      if (newItem.id) {
        refs[newItem.id].current?.scrollIntoView({ behavior: "auto", block: "nearest" })
      }
    }

    const upHandler = () => {
      const newIndex = (selectedIndex + items.length - 1) % items.length
      setSelectedIndex(newIndex)
      scrollItemIntoView(newIndex)
    }

    const downHandler = () => {
      setSelectedIndex((selectedIndex + 1) % items.length)
      scrollItemIntoView((selectedIndex + 1) % items.length)
    }

    const enterHandler = () => {
      selectItem(selectedIndex)
    }

    useEffect(() => setSelectedIndex(0), [items])

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }: { event: KeyboardEvent }) => {
        if (event.key === "ArrowUp") {
          upHandler()
          return true
        }

        if (event.key === "ArrowDown") {
          downHandler()
          return true
        }

        if (event.key === "Enter") {
          enterHandler()
          return true
        }

        return false
      },
    }))

    return (
      <div className="flex max-h-36 flex-col overflow-auto rounded border border-gray-200 bg-white/70 py-2 shadow backdrop-blur-sm">
        {items.length ? (
          items.map((item, index) => (
            <button
              type="button"
              ref={refs[item.id]}
              className={classNames("text-left px-2 p-1", {
                "bg-gray-100": selectedIndex === index,
              })}
              key={index}
              onClick={() => selectItem(index)}>
              {item.label}
            </button>
          ))
        ) : (
          <div className="p-1 px-2 text-left text-gray-500">Kein Treffer</div>
        )}
      </div>
    )
  }
)

export default RichtTextMentionList
