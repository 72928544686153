import Button from "@components/shared/button"
import TouchTargetSize from "@components/shared/touch-target-size"
import { closeModal, openModal } from "@contexts/modal-context"
import { AppWorkOrderCreateScope } from "@contexts/user-context"
import { IPermissionScopeEnum, IWorkOrderTypeEnum } from "@elara/db"
import { IWorkOrderTemplateFragment } from "@graphql/documents/fragments.generated"
import {
  TemplatedUsedDocument,
  TemplateListDocument,
  useTemplateListQuery,
} from "@graphql/documents/templates.generated"
import { Transition } from "@headlessui/react"
import { usePermissionScope } from "@hooks"
import i18n from "@i18n"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import Icons from "@resources/icons"
import { splitArray } from "@utils"
import classNames from "classnames"
import { Fragment, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useClient } from "urql"

import { InitialFormValuesInput } from "./create-task-form.hooks"

const TemplateListOption = (props: {
  template: IWorkOrderTemplateFragment
  onSelect: () => void
}) => (
  <label
    key={props.template.id}
    className="flex cursor-pointer items-center justify-between rounded-md border border-gray-300 px-4 py-3 focus-within:border-blue-500 hover:border-gray-600 hover:bg-gray-50 focus-within:hover:border-blue-600">
    <div className="mr-2">
      <div className="text-sm font-medium text-gray-800">{props.template.name}</div>
      {props.template.description && (
        <div className="mt-1 line-clamp-2 text-xs text-gray-500">
          {props.template.description}
        </div>
      )}
    </div>
    <Button onClick={props.onSelect} className="shrink-0">
      {i18n.t("select")}
    </Button>
  </label>
)

const TemplateList = (props: { initialTaskValues?: InitialFormValuesInput }) => {
  const client = useClient()
  const navigate = useNavigate()

  const [queryRes] = useTemplateListQuery({ requestPolicy: "cache-and-network" })
  const templates =
    queryRes.data?.templates?.sort((a, b) => a.name?.localeCompare(b.name ?? "") ?? 0) ?? []
  const recentlyUsed = queryRes?.data?.recently_used ?? []
  const [recentlyUsedTemplates, otherTemplates] = splitArray(templates, (t) =>
    recentlyUsed.some((r) => r.entity_id === t.id)
  )

  const createScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderCreate)

  const selectTemplate = async (template: IWorkOrderTemplateFragment) => {
    // Make sure that the provided asset ids from the application context
    // and the assets from the template are merged and not overwritten
    let assetIds = (props.initialTaskValues?.asset_ids ?? []).concat(
      template.work_order.assets.map((asset) => asset.asset_id)
    )

    closeModal("select_template")
    openModal("task_create", {
      initialValues: {
        ...template.work_order,
        ...props.initialTaskValues,
        asset_ids: assetIds,
        type: IWorkOrderTypeEnum.WorkOrder,
        templated_used_id: template.id,
        template_name: template.name,
      },
      template: {
        id: template.id,
        name: template.name,
        allow_modification_in_task_form: !!template.allow_modification_in_task_form,
      },
    })

    await client.mutation(TemplatedUsedDocument, { templateId: template.id }).toPromise()
    await client.query(TemplateListDocument, {}).toPromise()
  }

  const handleCreateFromScratch = () => {
    closeModal("select_template")
    openModal("task_create", { initialValues: { ...props.initialTaskValues } })
  }

  const handleCreateTemplate = () => {
    closeModal("select_template")
    navigate("/settings/template")
  }

  useEffect(() => {
    if (!queryRes.fetching) {
      if (templates.length === 0) {
        handleCreateFromScratch()
      }
    }
  }, [queryRes, templates])

  const canOnlySelectTemplate = !!(createScope.scope as AppWorkOrderCreateScope)?.options
    ?.template_only

  if (queryRes.fetching) return null

  return (
    <div className="flex flex-col gap-y-4 p-6">
      <h3 className="font-medium text-gray-800">
        {i18n.t("templates:tasks.select_dialog.title")}
      </h3>
      <p className="-mt-2 text-sm text-gray-500">
        {i18n.t("templates:tasks.select_dialog.description")}
      </p>

      {!canOnlySelectTemplate && (
        <label
          onClick={handleCreateFromScratch}
          className="flex cursor-pointer items-center justify-between rounded-md border border-dashed border-gray-300 border-x-blue-500 px-4 py-3 focus-within:border-blue-500 hover:border-gray-400 focus-within:hover:border-blue-500">
          <div className="mr-2 flex items-center space-x-2 text-gray-600">
            <Icons.NewRecord className="h-6 w-6" />
            <span className="text-sm font-medium">
              {i18n.t("templates:tasks.select_dialog.options.new_task_from_scratch")}
            </span>
          </div>
          <Button className="shrink-0">{i18n.t("common:select")}</Button>
        </label>
      )}

      {recentlyUsedTemplates.length > 0 && (
        <>
          <h3 className="-mb-1.5 text-sm font-medium text-gray-700">
            {i18n.t("templates:tasks.select_dialog.labels.recently_used")}
          </h3>
          {recentlyUsedTemplates
            .sort(
              (a, b) =>
                recentlyUsed
                  .find((v) => v.entity_id === b.id)
                  ?.timestamp?.localeCompare(
                    recentlyUsed.find((v) => v.entity_id === a.id)?.timestamp ?? ""
                  ) ?? 0
            )
            .map((t) => (
              <TemplateListOption
                key={t.id}
                template={t}
                onSelect={() => selectTemplate(t)}
              />
            ))}
        </>
      )}

      {otherTemplates.length > 0 && (
        <>
          <h3 className="-mb-1.5 text-sm font-medium text-gray-700">
            {recentlyUsedTemplates.length
              ? i18n.t("templates:tasks.select_dialog.labels.more_templates")
              : i18n.t("common:template", { count: 2 })}
          </h3>
          {otherTemplates.map((t) => (
            <TemplateListOption
              template={t}
              key={t.id}
              onSelect={() => selectTemplate(t)}
            />
          ))}
        </>
      )}

      {!canOnlySelectTemplate && (
        <div className="pt-1 text-sm text-gray-500">
          {i18n.t("templates:tasks.select_dialog.labels.missing_template")}{" "}
          <button
            type="button"
            className="relative font-medium text-blue-600 hover:underline"
            onClick={handleCreateTemplate}>
            {i18n.t("templates:tasks.select_dialog.labels.create_template")}
            <TouchTargetSize />
          </button>
        </div>
      )}
    </div>
  )
}

const SelectTaskTemplateDialog = (props: {
  onOpenChange: (open: boolean) => void
  initialTaskValues?: InitialFormValuesInput
}) => (
  <DialogPrimitive.Root open onOpenChange={props.onOpenChange}>
    <DialogPrimitive.Portal forceMount className="isolate">
      <Transition.Root show className="isolate">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <DialogPrimitive.Overlay forceMount className="fixed inset-0 z-20 bg-black/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95">
          <DialogPrimitive.Content
            forceMount
            className={classNames(
              "fixed z-50 bg-white",
              "w-[95vw] max-w-2xl rounded-lg md:w-full",
              "top-[10vh] left-[50%] -translate-x-[50%]",
              "flex flex-col min-h-0 max-h-[80vh]",
              "focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75",
              "overflow-y-auto"
            )}>
            <TemplateList initialTaskValues={props.initialTaskValues} />
          </DialogPrimitive.Content>
        </Transition.Child>
      </Transition.Root>
    </DialogPrimitive.Portal>
  </DialogPrimitive.Root>
)

export default SelectTaskTemplateDialog
