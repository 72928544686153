// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IAssetGroupSummaryQueryVariables = Types.Exact<{
  where: Types.IAssetBoolExp;
}>;


export type IAssetGroupSummaryQuery = { asset_group: Array<{ __typename: 'asset_group', id: uuid, name: string, assets_aggregate: { __typename: 'asset_aggregate', aggregate: { __typename: 'asset_aggregate_fields', count: number } | null } }>, asset_aggregate: { __typename: 'asset_aggregate', aggregate: { __typename: 'asset_aggregate_fields', count: number } | null } };


export const AssetGroupSummaryDocument = gql`
    query AssetGroupSummary($where: asset_bool_exp!) {
  asset_group {
    id
    name
    assets_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  asset_aggregate(where: {_and: [$where, {group_id: {_is_null: true}}]}) {
    aggregate {
      count
    }
  }
}
    `;

export function useAssetGroupSummaryQuery(options: Omit<Urql.UseQueryArgs<IAssetGroupSummaryQueryVariables>, 'query'>) {
  return Urql.useQuery<IAssetGroupSummaryQuery, IAssetGroupSummaryQueryVariables>({ query: AssetGroupSummaryDocument, ...options });
};