import { Flex, FlexProps } from "@components/layout"
import Button from "@components/shared/button"
import { Item } from "@components/shared/combobox-select"
import Divider from "@components/shared/divider"
import {
  PopoverClose,
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from "@components/shared/popover"
import { Select } from "@components/shared/select"
import { SelectPopover } from "@components/shared/single-select"
import Toggle from "@components/shared/toggle"
import { useBreakpoint } from "@contexts/breakpoints"
import { openModal } from "@contexts/modal-context"
import { IViewDataTypeEnum } from "@elara/db"
import { Data } from "@elara/select"
import { useDisclosure } from "@hooks"
import i18n from "@i18n"
import { DownloadSimple, FadersHorizontal } from "@phosphor-icons/react"
import Icons from "@resources/icons"
import { naturalCompare } from "@utils"
import classNames from "classnames"
import download from "downloadjs"
import { parse } from "json2csv"
import React, { ReactNode, useState } from "react"

import { useDataViewConfigContext } from "./data-view-config"
import { CustomizeColumnsDrawer } from "./data-view-customize-columns-drawer"
import { useDataViewTreeContext } from "./data-view-tree-toggle"
import { CustomDataConfig, DataViewLayoutType } from "./data-view-types"

const MenuPopoverHeader = (props: { isSubMenuVisible: boolean; onBack: () => void }) => (
  <div className="mb-3 flex w-full flex-row items-center justify-between px-4 font-medium">
    {props.isSubMenuVisible && (
      <Icons.LeftArrow
        height={20}
        width={20}
        className="mr-2 cursor-pointer rounded text-grey-3 hover:bg-grey-5"
        onClick={props.onBack}
      />
    )}

    <span>{i18n.t("data-view:options.title")}</span>

    <PopoverClose>
      <Icons.Close className="h-5 w-5 cursor-pointer rounded-full p-0.5 text-grey-2 hover:bg-grey-5" />
    </PopoverClose>
  </div>
)

const LayoutCard = (props: {
  layout: DataViewLayoutType
  className?: string
  selectedLayout: DataViewLayoutType
  onSelect: (layout: DataViewLayoutType) => void
}) => {
  const bp = useBreakpoint()

  if (bp.renderMobile && props.layout === "table") return null

  return (
    <div
      className={classNames(
        "flex flex-col items-center border rounded px-4 py-1.5 cursor-pointer ",
        props.className,
        {
          "border-blue-400 bg-blue-50 text-blue-600": props.selectedLayout === props.layout,
          "border-gray-200 text-gray-700 hover:bg-gray-100 hover:border-gray-500":
            props.selectedLayout !== props.layout,
        }
      )}
      onClick={() => props.onSelect(props.layout)}>
      {props.layout === "table" && <Icons.Columns className="h-5 w-5" />}
      {props.layout === "list" && <Icons.List className="h-5 w-5" />}
      {props.layout === "calendar" && <Icons.Calendar className="h-5 w-5" />}
      {props.layout === "kanban" && <Icons.Card className="h-5 w-5" />}
      <span className="mt-1 text-xs">
        {i18n.t(`data-view:options.layout.${props.layout}`)}
      </span>
    </div>
  )
}

export const OptionsMenuItem = (props: React.PropsWithChildren<FlexProps>) => (
  <Flex
    row
    align="center"
    {...props}
    className={classNames(
      "px-4 h-9",
      { "hover:bg-grey-6 cursor-pointer": !!props.onClick },
      props.className
    )}
  />
)

type DataViewOptionsMenuContentProps<D extends Data> = {
  dataType: IViewDataTypeEnum
  closePopover: () => void
  onOpenTableMenu: () => void
  availableLayoutTypes: DataViewLayoutType[]
  historyFilterItems?: Item<any>[]
  historyFilterLabel?: string
  updateHistoryFilter?: (value: any) => void
  historyFilterValue?: any
  customDataConfigMenuOptions?: (
    config: CustomDataConfig | null,
    updateConfig: (config: CustomDataConfig) => void
  ) => ReactNode
  fetchAllRows: () => Promise<D[]>
  includeSubitemsLabel: string | null
}

const DataViewOptionsMenuContent = <D extends Data>(
  props: DataViewOptionsMenuContentProps<D>
) => {
  const [subMenu, setSubMenu] = useState<"layout" | null>(null)
  const ctx = useDataViewConfigContext()
  const tree = useDataViewTreeContext()

  const configActions = ctx.actions

  const handleOnSortState = (value: string) => {
    const selectedColumn = ctx.columns?.find((column) => column.id === value)
    ctx.actions.updateOrderBy(value, selectedColumn?.defaultSortDirection! ?? "asc")
  }

  const onSelectLayout = (layout: DataViewLayoutType) => {
    ctx.actions.updateLayoutType(layout)
    setSubMenu(null)
  }

  const groupByColumns = ctx.columns
    ?.filter((column) => !!column.groupBySummary)
    .sort((a, b) => naturalCompare(a.Header, b.Header))

  const canSumColumn =
    ctx.layoutType === "table" && ctx.columns.some((c) => !!c.summationValue)

  const exportToCSV = async () => {
    const rows = await props.fetchAllRows()

    // TODO: Probably best to perform just the normal, non paginated query
    // But need to put some limits in place then
    const jsonObj = rows.map((item) => {
      let result: Record<string, string> = {}

      ctx.renderedColumns.forEach((column) => {
        if (column.Header === "") return
        result[column.Header] = column.toText(item)
      })

      return result
    })

    const filename = `elara-export-${new Date().toISOString().slice(0, 10)}.csv`
    const csv = parse(jsonObj)

    download(csv, filename, "text/csv")

    props.closePopover()
  }

  const generateQRCodes = async () => {
    let ids: string[] = []
    const rows = await props.fetchAllRows()
    rows.forEach((row) => {
      ids.push((row as unknown as { id: string }).id)
    })

    openModal("bulk_generate_qrcode", { ids, type: props.dataType })

    props.closePopover()
  }

  const mainMenu = (
    <>
      <div className="mx-4 mb-2 grid auto-cols-auto grid-flow-col gap-3">
        {props.availableLayoutTypes.map((layout) => (
          <LayoutCard
            key={layout}
            layout={layout}
            onSelect={onSelectLayout}
            selectedLayout={ctx.layoutType}
          />
        ))}
      </div>

      <OptionsMenuItem>
        <Icons.Sort />
        <span className="flex-1 whitespace-nowrap px-2">
          {i18n.t("data-view:options.sort-by.title")}
        </span>
        <Select
          className="min-w-0 flex-1 truncate"
          value={ctx.config.orderBy?.[0]?.id}
          onValueChange={handleOnSortState}
          items={ctx.columns
            ?.filter((column) => !column.disableSortBy && column.orderBy)
            .sort((a, b) => naturalCompare(a.Header, b.Header))
            .map((column) => ({ value: column.id, label: column.Header }))}
        />
      </OptionsMenuItem>

      {groupByColumns.length > 0 && (
        <OptionsMenuItem>
          <Icons.Category className="shrink-0" />
          <span className="flex-1 whitespace-nowrap px-2">
            {i18n.t("data-view:options.group-by.title")}
          </span>
          <Select
            className="min-w-0 flex-1 truncate"
            value={ctx.config.groupBy ?? "NO_GROUPING"}
            onValueChange={(selectedValue: string) => {
              const groupBy = selectedValue === "NO_GROUPING" ? null : selectedValue
              ctx.actions.updateGroupBy(groupBy)
            }}
            items={groupByColumns
              .map((column) => ({ value: column.id as string, label: column.Header }))
              .concat([
                {
                  value: "NO_GROUPING",
                  label: i18n.t("data-view:options.group-by.none"),
                },
              ])}
          />
        </OptionsMenuItem>
      )}

      {props.historyFilterItems && props.historyFilterLabel && (
        <OptionsMenuItem>
          <span className="flex-1 truncate">{props.historyFilterLabel}</span>
          <SelectPopover
            className="w-40"
            isClearable={false}
            items={props.historyFilterItems}
            value={props.historyFilterValue}
            onChange={props.updateHistoryFilter}
          />
        </OptionsMenuItem>
      )}

      {props.customDataConfigMenuOptions?.(
        ctx.config.customDataConfig ?? null,
        configActions.updateCustomDataConfig
      )}

      {ctx.layoutType === "calendar" && (
        <>
          <Divider className="my-2" />

          <div className="flex flex-col">
            <OptionsMenuItem
              onClick={() =>
                ctx.actions.updateCalendar({
                  ...ctx.config.calendarConfig,
                  showWeekends: !ctx.config.calendarConfig?.showWeekends,
                })
              }>
              <span className="flex-1">
                {i18n.t("data-view:options.calendar.show_weekends")}
              </span>
              <Toggle readOnly checked={ctx.config.calendarConfig?.showWeekends} />
            </OptionsMenuItem>
            <OptionsMenuItem
              onClick={() =>
                ctx.actions.updateCalendar({
                  ...ctx.config.calendarConfig,
                  hideUpcomingMaintenanceTasks:
                    !ctx.config.calendarConfig?.hideUpcomingMaintenanceTasks,
                })
              }>
              <span className="flex-1">
                {i18n.t("data-view:options.calendar.show_upcoming_maintenance_tasks")}
              </span>
              <Toggle
                readOnly
                checked={!ctx.config.calendarConfig?.hideUpcomingMaintenanceTasks}
              />
            </OptionsMenuItem>

            {/* <OptionsMenuItem>
              <Icons.Layout className="shrink-0" />
              <span className="flex-1 pl-2">
                {i18n.t("data-view:options.calendar.layout")}
              </span>
              <ToggleGroup
                type="single"
                defaultValue="dayGrid"
                value={ctx.config.calendarConfig?.type}
                onValueChange={(type: CalendarType) =>
                  ctx.actions.updateCalendar({
                    ...ctx.config.calendarConfig,
                    type,
                  })
                }>
                <ToggleGroupItem value="dayGrid">
                  {i18n.t("data-view:options.layout.grid")}
                </ToggleGroupItem>
                <ToggleGroupItem value="list">
                  {i18n.t("data-view:options.layout.list")}
                </ToggleGroupItem>
              </ToggleGroup>
            </OptionsMenuItem> */}
          </div>
        </>
      )}

      {ctx.layoutType === "table" && (
        <>
          <Divider className="my-2" />

          <div className="flex flex-col">
            <OptionsMenuItem
              onClick={() => {
                props.onOpenTableMenu()
                props.closePopover()
              }}>
              <Icons.Columns className="shrink-0" />
              <span className="flex-1 pl-2">
                {i18n.t("data-view:options.table.columns")}
              </span>
              <div className="flex flex-row flex-nowrap items-center">
                <Icons.RightArrow height={16} width={16} className="text-grey-3" />
              </div>
            </OptionsMenuItem>

            <OptionsMenuItem onClick={exportToCSV}>
              <DownloadSimple className="shrink-0" />
              <span className="pl-2">{i18n.t("data-view:actions.export_to_csv")}</span>
            </OptionsMenuItem>

            {(props.dataType === IViewDataTypeEnum.Asset ||
              props.dataType === IViewDataTypeEnum.Consumable) && (
              <OptionsMenuItem onClick={generateQRCodes}>
                <Icons.QRCode className="shrink-0" />
                <span className="pl-2">{i18n.t("qrcode:dialogs.bulk_generate.title")}</span>
              </OptionsMenuItem>
            )}

            {canSumColumn && (
              <OptionsMenuItem
                onClick={() =>
                  ctx.actions.updateShowSummation(!ctx.config.showSummation ?? false)
                }>
                <span className="flex-1">
                  {i18n.t("data-view:options.aggregation.title")}
                </span>
                <Toggle readOnly checked={ctx.config.showSummation ?? false} />
              </OptionsMenuItem>
            )}
          </div>
        </>
      )}

      {!!tree && (
        <OptionsMenuItem
          onClick={() =>
            ctx.actions.updateIncludeSubitems(!(ctx.config.includeSubitems ?? false))
          }>
          <span className="flex-1">{props.includeSubitemsLabel}</span>
          <Toggle readOnly checked={ctx.config.includeSubitems ?? false} />
        </OptionsMenuItem>
      )}

      {!ctx.isConfigPersisted && (
        <>
          <Divider className="my-2 mb-0" />

          <Flex row justify="space-between" className="mt-1 w-full px-4">
            <Button
              type="tertiary"
              size="small"
              onClick={ctx.resetConfig}
              className="!font-medium !text-gray-700">
              {i18n.t("common:reset")}
            </Button>
            <Button
              type="tertiary"
              size="small"
              onClick={() => ctx.save()}
              className="!font-medium">
              {i18n.t("common:save")}
            </Button>
          </Flex>
        </>
      )}
    </>
  )

  return (
    <Flex col flex="1" className="mb-2 mt-4 text-sm">
      {<MenuPopoverHeader isSubMenuVisible={!!subMenu} onBack={() => setSubMenu(null)} />}

      {!subMenu && mainMenu}
    </Flex>
  )
}

export const DataViewOptionsMenu = <D extends Data>(
  props: Omit<DataViewOptionsMenuContentProps<D>, "closePopover" | "onOpenTableMenu">
) => {
  const bp = useBreakpoint()
  const menu = useDisclosure()
  const columnsDrawer = useDisclosure()

  return (
    <>
      <PopoverRoot open={menu.isOpen} onOpenChange={menu.changeOpen} modal>
        <PopoverTrigger asChild>
          {!bp.sm || !bp.md ? (
            <Button type="secondary" icon={FadersHorizontal} />
          ) : (
            <Button type="secondary" icon={FadersHorizontal}>
              <span className="ml-1 hidden text-sm font-medium md:inline-block">
                {i18n.t("data-view:options.title")}
              </span>
            </Button>
          )}
        </PopoverTrigger>
        <PopoverContent className="mt-1.5 w-[400px] rounded" align="end" side="bottom">
          <DataViewOptionsMenuContent
            {...props}
            closePopover={menu.onClose}
            onOpenTableMenu={columnsDrawer.onOpen}
          />
        </PopoverContent>
      </PopoverRoot>
      <CustomizeColumnsDrawer
        isOpen={columnsDrawer.isOpen}
        onClose={columnsDrawer.onClose}
      />
    </>
  )
}
