import { PopoverContent, PopoverRoot, PopoverTrigger } from "@components/shared/popover"
import ScrollArea from "@components/shared/scroll-area"
import TouchTargetSize from "@components/shared/touch-target-size"
import { WorkOrderStatusTag } from "@components/work-order/work-order-status"
import { IAssetDataViewFragment } from "@graphql/documents/fragments.generated"
import { Rows } from "@phosphor-icons/react"
import { LinkWithBackgroundLocation } from "@utils/location"

export const AssetActiveTasksBadge = (props: { asset: IAssetDataViewFragment }) => {
  const wos = props.asset.active_work_orders
    .filter((w) => !!w.work_order)
    .sort((a, b) => b.work_order.work_order_number - a.work_order.work_order_number)

  const trigger = (
    <button
      type="button"
      onClick={(e) => e.stopPropagation()}
      className="relative flex items-center rounded border border-gray-200 bg-gray-50 px-1 py-0.5 font-medium text-gray-500 hover:border-gray-300 radix-state-open:border-gray-300 ">
      <Rows size={14} className="mr-1.5" />
      {wos.length ?? 0}
      <TouchTargetSize />
    </button>
  )

  if (!wos.length) return trigger

  const content = (
    <ScrollArea vertical viewportAsChild className="max-h-[300px]">
      <div className="space-y-2 p-1 text-sm">
        {wos.map(({ work_order }) => {
          if (!work_order) return null
          return (
            <LinkWithBackgroundLocation
              key={work_order.id}
              to={`/task/${work_order.id}`}
              className="group flex items-center space-x-2 rounded-md px-2 py-1 text-gray-600 hover:bg-gray-100 hover:text-gray-600">
              <WorkOrderStatusTag status={work_order.status} short />
              <span>#{work_order.work_order_number}</span>
              <span className="font-medium text-gray-600 group-hover:text-gray-800">
                {work_order.name}
              </span>
            </LinkWithBackgroundLocation>
          )
        })}
      </div>
    </ScrollArea>
  )

  return (
    <PopoverRoot>
      <PopoverTrigger asChild>{trigger}</PopoverTrigger>
      <PopoverContent align="end" side="right" sideOffset={4}>
        {content}
      </PopoverContent>
    </PopoverRoot>
  )
}
