import { IUploadDataFragment } from "@graphql/documents/upload.generated"
import { useFileUploads } from "@hooks/use-file-uploads"
import i18n from "@i18n"
import { TrashSimple, UploadSimple } from "@phosphor-icons/react"
import * as Avatar from "@radix-ui/react-avatar"
import classNames from "classnames"
import React, { useRef } from "react"
import { FileUpload } from "src/types"

import ActionMenu from "./action-menu"
import Button from "./button"
import Image from "./image"
import LoadingIndicator from "./loading-indicator"

type ImageUploadProps = {
  upload: IUploadDataFragment | null
  onUploadChange: (upload: IUploadDataFragment | null) => void
  fallback: React.ReactNode
  uploadLabel?: string
  editLabel?: string
}

export const ImageUploadFormElement = (props: ImageUploadProps) => {
  const uploadTriggerRef = useRef<HTMLInputElement>(null)
  const { isUploading, uploads, uploadFile } = useFileUploads(
    props.upload ? [props.upload] : [],
    {
      onUploadFinished: props.onUploadChange,
    }
  )
  const currentUpload: FileUpload | null = uploads[uploads.length - 1] ?? null

  const onClickUploadAvatar = () => {
    return uploadTriggerRef?.current?.click()
  }

  return (
    <div className="flex items-center">
      <Avatar.Root className="relative isolate inline-flex h-16 w-16 items-center justify-center overflow-hidden rounded border bg-gray-50">
        <Avatar.Image src={currentUpload?.data?.thumbnail_url ?? undefined} asChild>
          <Image
            src={currentUpload?.data?.thumbnail_url ?? undefined}
            preview={currentUpload?.data?.url ? { src: currentUpload?.data?.url } : null}
            imageClasses="object-cover w-full h-full"
          />
        </Avatar.Image>
        <Avatar.Fallback className="flex h-full w-full items-center justify-center text-gray-300">
          {props.fallback}
        </Avatar.Fallback>
        <div
          className={classNames(
            "z-10 absolute inset-0 bg-black/10 opacity-0 pointer-events-none flex items-center justify-center",
            {
              "opacity-100 pointer-events-auto": isUploading,
            }
          )}>
          <LoadingIndicator size={16} />
        </div>
      </Avatar.Root>
      <input
        type="file"
        ref={uploadTriggerRef}
        hidden
        multiple={false}
        accept="image/*"
        onChange={(e) => {
          const file = e.target.files?.item(0)
          if (file) {
            uploadFile(file)
          }
          if (uploadTriggerRef.current?.value) {
            uploadTriggerRef.current.value = ""
          }
        }}
      />
      {!currentUpload ? (
        <Button
          type="secondary"
          size="small"
          onClick={onClickUploadAvatar}
          className="ml-2">
          {props.uploadLabel ?? i18n.t("common:upload")}
        </Button>
      ) : (
        <ActionMenu
          items={[
            {
              label: i18n.t("common:upload"),
              icon: <UploadSimple />,
              key: "upload_photo",
              action: onClickUploadAvatar,
            },
            {
              label: i18n.t("common:delete"),
              icon: <TrashSimple />,
              key: "delete_photo",
              action: () => {
                props.onUploadChange(null)
              },
            },
          ]}>
          <Button type="secondary" size="small" className="ml-2">
            {props.editLabel ?? i18n.t("common:change")}
          </Button>
        </ActionMenu>
      )}
    </div>
  )
}
