import AssetSupportFormDialog from "@components/asset/asset-support-form-dialog"
import { Flex, VStack } from "@components/layout"
import { Button } from "@components/shared"
import { ContactTag } from "@components/shared/contact-tag"
import { SectionHeader } from "@components/shared/section-header"
import { IAssetDetailFragment } from "@graphql/documents/fragments.generated"
import { IPermissionScopeEnum, useDisclosure, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { Headset } from "@phosphor-icons/react"

type Props = {
  asset: IAssetDetailFragment | null
}

const AssetSupport = ({ asset }: Props) => {
  const supportDialog = useDisclosure()

  const editScope = usePermissionScope(IPermissionScopeEnum.AppManufacturerCreateEditDelete)

  return (
    <VStack space={12}>
      <Flex row align="center" justify="space-between">
        <SectionHeader>{i18n.t("assets:fields.support")}</SectionHeader>

        {editScope.hasScope && (
          <Button
            color="gray"
            type="tertiary"
            icon={Headset}
            onClick={supportDialog.onOpen}>
            <span className="hidden sm:inline">
              {i18n.t("common:edit_token", { token: i18n.t("assets:fields.support") })}
            </span>
            <span className="inline sm:hidden">{i18n.t("common:edit")}</span>
          </Button>
        )}
      </Flex>
      {asset?.support_contacts?.length ? (
        <div className="grid grid-cols-1 items-start gap-2 @mobile/page:grid-cols-2">
          {asset?.support_contacts?.map((c) => (
            <ContactTag contact={c} key={c.id} expanded />
          ))}
        </div>
      ) : (
        <span className="text-sm text-gray-500">
          {i18n.t("common:no_token_created_yet", {
            token: i18n.t("common:contact", { count: 2 }),
          })}
        </span>
      )}

      <AssetSupportFormDialog
        asset={asset}
        title={i18n.t("common:edit_token", { token: i18n.t("assets:fields.support") })}
        isOpen={supportDialog.isOpen}
        onOpenChange={supportDialog.changeOpen}
        initialValues={{
          support_contact_ids: asset?.support_contacts?.map((c) => c.id) ?? [],
        }}
      />
    </VStack>
  )
}

export default AssetSupport
