import { IBlockElementTypeEnum } from "@elara/db"
import {
  assetStateSchema,
  consumablesSchema,
  checkboxSchema,
  textFieldSchema,
  headingSchema,
  paragraphSchema,
  choiceSchema,
  inspectionSchema,
  meterReadingSchema,
  yesNoSchema,
  toleranceCheckSchema,
  mediaSchema,
} from "./block-groups.js"

export function elementSchema(
  elementType: IBlockElementTypeEnum,
  options: { isTemplate: boolean }
) {
  switch (elementType) {
    case IBlockElementTypeEnum.AssetState:
      return assetStateSchema({ isTemplate: options.isTemplate })
    case IBlockElementTypeEnum.Consumables:
      return consumablesSchema
    case IBlockElementTypeEnum.Checkbox:
      return checkboxSchema
    case IBlockElementTypeEnum.Text:
      return textFieldSchema
    case IBlockElementTypeEnum.Heading:
      return headingSchema
    case IBlockElementTypeEnum.Paragraph:
      return paragraphSchema
    case IBlockElementTypeEnum.Choice:
      return choiceSchema
    case IBlockElementTypeEnum.Inspection:
      return inspectionSchema
    case IBlockElementTypeEnum.MeterReading:
      return meterReadingSchema({ isTemplate: options.isTemplate })
    case IBlockElementTypeEnum.YesNo:
      return yesNoSchema
    case IBlockElementTypeEnum.ToleranceCheck:
      return toleranceCheckSchema
    case IBlockElementTypeEnum.Media:
      return mediaSchema
    default:
      console.warn("Unexpected type: " + elementType)
      return null
  }
}
