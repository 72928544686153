import { FileExplorerContent } from "@components/shared/file-explorer"

import { useAsset } from "./$id"

export function AssetInformationDocuments() {
  const { asset } = useAsset()
  return (
    <div className="flex min-h-0 w-full max-w-4xl flex-1 flex-col">
      <FileExplorerContent asset={asset} key={asset.id} />
    </div>
  )
}

export default AssetInformationDocuments
