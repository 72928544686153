import "chartjs-adapter-date-fns"

import {
  ArcElement,
  BarController,
  BarElement,
  Chart,
  DoughnutController,
  LinearScale,
  SubTitle,
  TimeScale,
  Tooltip,
} from "chart.js"
import { createRoot } from "react-dom/client"

import App from "./App"

Chart.register(
  ArcElement,
  BarElement,
  BarController,
  DoughnutController,
  LinearScale,
  TimeScale,
  Tooltip,
  SubTitle
)

const root = createRoot(document.getElementById("root")!)
root.render(<App />)
