export function groupBy<D>(
  data: D[],
  options: { groupId: (d: D) => string[] | string | null; groupIds?: string[] }
) {
  const grouped: Record<string, D[]> = {}
  options.groupIds?.forEach((groupId) => {
    grouped[groupId] = []
  })
  const withoutGroup: D[] = []

  for (let d of data) {
    const id = options.groupId(d)
    if (id) {
      if (Array.isArray(id)) {
        if (id.length === 0) {
          withoutGroup.push(d)
        } else {
          id.forEach((i) => {
            if (grouped[i]) {
              grouped[i].push(d)
            } else {
              grouped[i] = [d]
            }
          })
        }
      } else {
        if (grouped[id]) {
          grouped[id].push(d)
        } else {
          grouped[id] = [d]
        }
      }
    } else {
      withoutGroup.push(d)
    }
  }

  const groups = Object.entries(grouped).map(([groupId, items]) => {
    return { groupId, items } as { groupId: string | null; items: D[] }
  })
  if (withoutGroup.length) {
    groups.push({ groupId: null, items: withoutGroup })
  }
  return groups
}
