// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ISaveTaskDescriptionMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  description: Types.Scalars['String']['input'];
}>;


export type ISaveTaskDescriptionMutation = { update_work_order_by_pk: { __typename: 'work_order', id: uuid, description: string | null } | null };

export type IUpdateTaskStatusMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  status: Types.IWorkOrderStatusEnum;
}>;


export type IUpdateTaskStatusMutation = { update_work_order_by_pk: { __typename: 'work_order', id: uuid, status: Types.IWorkOrderStatusEnum } | null };

export type IUpdateTaskNameMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  name: Types.Scalars['String']['input'];
}>;


export type IUpdateTaskNameMutation = { update_work_order_by_pk: { __typename: 'work_order', id: uuid, name: string } | null };


export const SaveTaskDescriptionDocument = gql`
    mutation SaveTaskDescription($id: uuid!, $description: String!) {
  update_work_order_by_pk(
    pk_columns: {id: $id}
    _set: {description: $description}
  ) {
    id
    description
  }
}
    `;

export function useSaveTaskDescriptionMutation() {
  return Urql.useMutation<ISaveTaskDescriptionMutation, ISaveTaskDescriptionMutationVariables>(SaveTaskDescriptionDocument);
};
export const UpdateTaskStatusDocument = gql`
    mutation UpdateTaskStatus($id: uuid!, $status: work_order_status_enum!) {
  update_work_order_by_pk(pk_columns: {id: $id}, _set: {status: $status}) {
    id
    status
  }
}
    `;

export function useUpdateTaskStatusMutation() {
  return Urql.useMutation<IUpdateTaskStatusMutation, IUpdateTaskStatusMutationVariables>(UpdateTaskStatusDocument);
};
export const UpdateTaskNameDocument = gql`
    mutation UpdateTaskName($id: uuid!, $name: String!) {
  update_work_order_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {
    id
    name
  }
}
    `;

export function useUpdateTaskNameMutation() {
  return Urql.useMutation<IUpdateTaskNameMutation, IUpdateTaskNameMutationVariables>(UpdateTaskNameDocument);
};