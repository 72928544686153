import {
  SelectPopover,
  SelectPopoverProps,
  SelectTrigger,
} from "@components/shared/single-select"
import { useAssetStateVariantsQuery } from "@graphql/documents/asset-state.generated"
import i18n from "@i18n"
import { CaretDown } from "@phosphor-icons/react"

import AssetStateVariantLabel, {
  compareAssetStateVariants,
  textForAssetStateVariantLabel,
} from "./asset-state-variant-label"

export function useAssetStateVariants() {
  const [assetStateVariants] = useAssetStateVariantsQuery()
  const variants = assetStateVariants.data?.asset_state_variant ?? []

  return {
    items: variants.sort(compareAssetStateVariants).map((v) => ({
      value: v.id,
      searchValue: textForAssetStateVariantLabel(v),
      label: <AssetStateVariantLabel variant={v} />,
    })),
    variants,
  }
}

export function AssetStateVariantSelect(
  props: Omit<SelectPopoverProps<string | null>, "items" | "children">
) {
  const { items, variants } = useAssetStateVariants()

  return (
    <SelectPopover
      items={items}
      nullOption={
        !props.isClearable
          ? undefined
          : {
              label: (
                <div className="isolate inline-flex min-w-0 items-center whitespace-nowrap rounded-lg bg-gray-50 px-2 py-1 text-sm font-medium text-gray-500 ring-1 ring-transparent focus:outline-none">
                  <span className="truncate">
                    {i18n.t("common:select_token", {
                      token: i18n.t("assets:fields.state"),
                    })}
                  </span>
                </div>
              ),
              searchValue: i18n.t("common:select_token", {
                token: i18n.t("assets:fields.state"),
              }),
            }
      }
      {...props}>
      {({ selectedItem }) => {
        const variant = variants.find((v) => v.id === selectedItem?.value)

        return (
          <SelectTrigger className="group flex">
            {variant ? (
              <AssetStateVariantLabel
                variant={variant}
                className="items-center hover:ring-current group-radix-state-open:ring-current">
                <CaretDown weight="bold" className="ml-2" />
              </AssetStateVariantLabel>
            ) : (
              <div className="isolate inline-flex min-w-0 items-center whitespace-nowrap rounded-lg bg-gray-50 px-2 py-1 text-sm font-medium text-gray-500 ring-1 ring-transparent hover:ring-current focus:outline-none group-radix-state-open:ring-current">
                <span className="truncate">
                  {i18n.t("common:select_token", {
                    token: i18n.t("assets:fields.state"),
                  })}
                </span>
                <CaretDown weight="bold" className="ml-2" />
              </div>
            )}
          </SelectTrigger>
        )
      }}
    </SelectPopover>
  )
}
