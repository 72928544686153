// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import { PermissionRoleFragmentDoc } from './fragments.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IPermissionScopesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IPermissionScopesQuery = { permission_scope: Array<{ __typename: 'permission_scope', scope: string }> };

export type IPermissionRoleByIdQueryVariables = Types.Exact<{
  roleId: Types.Scalars['uuid']['input'];
}>;


export type IPermissionRoleByIdQuery = { permission_role_by_pk: { __typename: 'permission_role', name: string, scopes: Array<{ __typename: 'permission_role_x_permission_scope', scope: Types.IPermissionScopeEnum }> } | null };

export type IPermissionRolesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IPermissionRolesQuery = { permission_role: Array<{ __typename: 'permission_role', id: uuid, name: string, is_admin: boolean, can_delete: boolean | null, scopes: Array<{ __typename: 'permission_role_x_permission_scope', role_id: uuid, scope: Types.IPermissionScopeEnum, options: {} | null }>, features: Array<{ __typename: 'permission_role_x_feature_flag', enabled: boolean, feature_flag: string, permission_role_id: uuid }> }> };

export type ILocationMembersWithPermissionRolesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ILocationMembersWithPermissionRolesQuery = { permission_role: Array<{ __typename: 'permission_role', id: uuid, name: string, is_admin: boolean, can_delete: boolean | null, scopes: Array<{ __typename: 'permission_role_x_permission_scope', role_id: uuid, scope: Types.IPermissionScopeEnum, options: {} | null }>, features: Array<{ __typename: 'permission_role_x_feature_flag', enabled: boolean, feature_flag: string, permission_role_id: uuid }> }>, location_member: Array<{ __typename: 'location_member', location_id: uuid, user_id: uuid, user: { __typename: 'user', id: uuid, first_name: string, last_name: string, email: string | null }, permission_role: { __typename: 'permission_role', id: uuid, name: string, scopes: Array<{ __typename: 'permission_role_x_permission_scope', scope: Types.IPermissionScopeEnum, permission_scope: { __typename: 'permission_scope', scope: string, inherited_role: string } }> } | null }> };

export type IUpdateLocationMemberPermissionRoleMutationVariables = Types.Exact<{
  locationId: Types.Scalars['uuid']['input'];
  userId: Types.Scalars['uuid']['input'];
  roleId?: Types.InputMaybe<Types.Scalars['uuid']['input']>;
}>;


export type IUpdateLocationMemberPermissionRoleMutation = { update_location_member_by_pk: { __typename: 'location_member', location_id: uuid, user_id: uuid, permission_role: { __typename: 'permission_role', id: uuid, name: string } | null } | null, syncUserToKeycloak: { __typename: 'SyncUserToKeycloakOutput', userId: uuid } | null };

export type ICreatePermissionRoleMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  features: Array<Types.IPermissionRoleXFeatureFlagInsertInput> | Types.IPermissionRoleXFeatureFlagInsertInput;
  scopes: Array<Types.IPermissionRoleXPermissionScopeInsertInput> | Types.IPermissionRoleXPermissionScopeInsertInput;
}>;


export type ICreatePermissionRoleMutation = { insert_permission_role_one: { __typename: 'permission_role', id: uuid, name: string, is_admin: boolean, can_delete: boolean | null, scopes: Array<{ __typename: 'permission_role_x_permission_scope', role_id: uuid, scope: Types.IPermissionScopeEnum, options: {} | null }>, features: Array<{ __typename: 'permission_role_x_feature_flag', enabled: boolean, feature_flag: string, permission_role_id: uuid }> } | null };

export type IUpdatePermissionRoleMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  name: Types.Scalars['String']['input'];
  scopesToKeep: Array<Types.IPermissionScopeEnum> | Types.IPermissionScopeEnum;
  scopes: Array<Types.IPermissionRoleXPermissionScopeInsertInput> | Types.IPermissionRoleXPermissionScopeInsertInput;
}>;


export type IUpdatePermissionRoleMutation = { delete_permission_role_x_permission_scope: { __typename: 'permission_role_x_permission_scope_mutation_response', affected_rows: number } | null, insert_permission_role_one: { __typename: 'permission_role', id: uuid, name: string, is_admin: boolean, can_delete: boolean | null, scopes: Array<{ __typename: 'permission_role_x_permission_scope', role_id: uuid, scope: Types.IPermissionScopeEnum, options: {} | null }>, features: Array<{ __typename: 'permission_role_x_feature_flag', enabled: boolean, feature_flag: string, permission_role_id: uuid }> } | null };

export type IDeletePermissionRoleMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type IDeletePermissionRoleMutation = { delete_permission_role_by_pk: { __typename: 'permission_role', id: uuid } | null };


export const PermissionScopesDocument = gql`
    query PermissionScopes {
  permission_scope {
    scope
  }
}
    `;

export function usePermissionScopesQuery(options?: Omit<Urql.UseQueryArgs<IPermissionScopesQueryVariables>, 'query'>) {
  return Urql.useQuery<IPermissionScopesQuery, IPermissionScopesQueryVariables>({ query: PermissionScopesDocument, ...options });
};
export const PermissionRoleByIdDocument = gql`
    query PermissionRoleById($roleId: uuid!) {
  permission_role_by_pk(id: $roleId) {
    name
    scopes {
      scope
    }
  }
}
    `;

export function usePermissionRoleByIdQuery(options: Omit<Urql.UseQueryArgs<IPermissionRoleByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<IPermissionRoleByIdQuery, IPermissionRoleByIdQueryVariables>({ query: PermissionRoleByIdDocument, ...options });
};
export const PermissionRolesDocument = gql`
    query PermissionRoles {
  permission_role {
    ...PermissionRole
  }
}
    ${PermissionRoleFragmentDoc}`;

export function usePermissionRolesQuery(options?: Omit<Urql.UseQueryArgs<IPermissionRolesQueryVariables>, 'query'>) {
  return Urql.useQuery<IPermissionRolesQuery, IPermissionRolesQueryVariables>({ query: PermissionRolesDocument, ...options });
};
export const LocationMembersWithPermissionRolesDocument = gql`
    query LocationMembersWithPermissionRoles {
  permission_role {
    ...PermissionRole
  }
  location_member {
    location_id
    user_id
    user {
      id
      first_name
      last_name
      email
    }
    permission_role {
      id
      name
      scopes {
        scope
        permission_scope {
          scope
          inherited_role
        }
      }
    }
  }
}
    ${PermissionRoleFragmentDoc}`;

export function useLocationMembersWithPermissionRolesQuery(options?: Omit<Urql.UseQueryArgs<ILocationMembersWithPermissionRolesQueryVariables>, 'query'>) {
  return Urql.useQuery<ILocationMembersWithPermissionRolesQuery, ILocationMembersWithPermissionRolesQueryVariables>({ query: LocationMembersWithPermissionRolesDocument, ...options });
};
export const UpdateLocationMemberPermissionRoleDocument = gql`
    mutation UpdateLocationMemberPermissionRole($locationId: uuid!, $userId: uuid!, $roleId: uuid) {
  update_location_member_by_pk(
    pk_columns: {location_id: $locationId, user_id: $userId}
    _set: {permission_role_id: $roleId}
  ) {
    location_id
    user_id
    permission_role {
      id
      name
    }
  }
  syncUserToKeycloak(userId: $userId) {
    userId
  }
}
    `;

export function useUpdateLocationMemberPermissionRoleMutation() {
  return Urql.useMutation<IUpdateLocationMemberPermissionRoleMutation, IUpdateLocationMemberPermissionRoleMutationVariables>(UpdateLocationMemberPermissionRoleDocument);
};
export const CreatePermissionRoleDocument = gql`
    mutation CreatePermissionRole($name: String!, $features: [permission_role_x_feature_flag_insert_input!]!, $scopes: [permission_role_x_permission_scope_insert_input!]!) {
  insert_permission_role_one(
    object: {name: $name, scopes: {data: $scopes}, features: {data: $features}}
  ) {
    ...PermissionRole
  }
}
    ${PermissionRoleFragmentDoc}`;

export function useCreatePermissionRoleMutation() {
  return Urql.useMutation<ICreatePermissionRoleMutation, ICreatePermissionRoleMutationVariables>(CreatePermissionRoleDocument);
};
export const UpdatePermissionRoleDocument = gql`
    mutation UpdatePermissionRole($id: uuid!, $name: String!, $scopesToKeep: [permission_scope_enum!]!, $scopes: [permission_role_x_permission_scope_insert_input!]!) {
  delete_permission_role_x_permission_scope(
    where: {role_id: {_eq: $id}, scope: {_nin: $scopesToKeep}}
  ) {
    affected_rows
  }
  insert_permission_role_one(
    object: {id: $id, name: $name, scopes: {data: $scopes, on_conflict: {constraint: permission_role_x_permission_scope_pkey, update_columns: [options]}}}
    on_conflict: {constraint: permission_role_pkey, update_columns: [name]}
  ) {
    ...PermissionRole
  }
}
    ${PermissionRoleFragmentDoc}`;

export function useUpdatePermissionRoleMutation() {
  return Urql.useMutation<IUpdatePermissionRoleMutation, IUpdatePermissionRoleMutationVariables>(UpdatePermissionRoleDocument);
};
export const DeletePermissionRoleDocument = gql`
    mutation DeletePermissionRole($id: uuid!) {
  delete_permission_role_by_pk(id: $id) {
    id
  }
}
    `;

export function useDeletePermissionRoleMutation() {
  return Urql.useMutation<IDeletePermissionRoleMutation, IDeletePermissionRoleMutationVariables>(DeletePermissionRoleDocument);
};