import Button from "@components/shared/button"
import CircularProgress from "@components/shared/circular-progress"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@components/shared/dropdown"
import { openElaraCommandDialog } from "@components/shared/elara-command"
import toast from "@components/shared/toast"
import SubtaskForm from "@components/work-order/subtask/subtask-form"
import SubtaskList from "@components/work-order/subtask/subtask-list"
import { IPermissionScopeEnum, IWorkOrderStatusEnum } from "@elara/db"
import { IWorkOrderFragment } from "@graphql/documents/fragments.generated"
import { useDisclosure } from "@hooks"
import { usePermissionScope } from "@hooks/use-permission-scope"
import i18n from "@i18n"
import { CaretDown, DotsThreeVertical, PictureInPicture, Plus } from "@phosphor-icons/react"
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@radix-ui/react-collapsible"
import Icons from "@resources/icons"
import { useRegisterCommands } from "@stores/command"
import { useClient } from "urql"

import {
  ISetTaskAsSubtaskMutation,
  ISetTaskAsSubtaskMutationVariables,
  SetTaskAsSubtaskDocument,
} from "./actions.generated"

export const Subtasks = (props: { task: IWorkOrderFragment }) => {
  const addSubtask = useDisclosure()
  const editScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)
  const client = useClient()
  const scope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)

  useRegisterCommands("2_subtasks", [
    {
      type: "page",
      page: "page:task:insert_subtask",
      value: "task:insert_subtask",
      searchValue: i18n.t("command:task.subtask_insert.search"),
      group: props.task.name,
      icon: <PictureInPicture />,
      description: i18n.t("command:task.subtask_insert.description"),
      subcommands: (searchResults) =>
        searchResults
          .filter((c) => c.value.startsWith("open_task:"))
          .map((c) => ({
            ...c,
            action: async () => {
              if (c.meta?.task) {
                const res = await client
                  .mutation<ISetTaskAsSubtaskMutation, ISetTaskAsSubtaskMutationVariables>(
                    SetTaskAsSubtaskDocument,
                    {
                      taskId: c.meta.task.id,
                      parentId: props.task.id,
                    },
                    scope.context()
                  )
                  .toPromise()
                if (res.error) {
                  toast.error(i18n.t("common:generic_toast_error"))
                }
              }
            },
          })),
    },
  ])

  if (!props.task.subtasks.length) {
    if (!editScope.hasScope) return null
    return (
      <div>
        <Button
          type="tertiary"
          icon={() => <Icons.AddSubTask className="text-lg" />}
          size="extra-small"
          color="gray"
          onClick={addSubtask.onOpen}>
          {i18n.t("common:add_token", { token: i18n.t("common:subtask", { count: 1 }) })}
        </Button>
        {addSubtask.isOpen && (
          <div className="mb-2 rounded-lg bg-gray-50 p-3">
            <SubtaskForm
              parent={props.task}
              onCancel={addSubtask.onClose}
              onSubmit={addSubtask.onClose}
            />
          </div>
        )}
      </div>
    )
  }
  const nCompletedSubtasks = props.task.subtasks.reduce(
    (total, s) =>
      total +
      Number([IWorkOrderStatusEnum.Done, IWorkOrderStatusEnum.Canceled].includes(s.status)),
    0
  )
  return (
    <div className="">
      <Collapsible defaultOpen className="group/collapse">
        <div className="mb-2 flex items-center justify-between pb-0.5 group-data-[state=open]/collapse:border-b">
          <div className="flex items-center">
            <CollapsibleTrigger asChild>
              <Button
                icon={CaretDown}
                size="extra-small"
                type="tertiary"
                color="gray"
                className="radix-state-closed:-rotate-90 radix-state-open:bg-white radix-state-open:hover:bg-gray-100"
              />
            </CollapsibleTrigger>
            <span className="ml-1 text-sm font-medium text-gray-900">
              {i18n.t("tasks:details.subtask_title")}
            </span>
            <div className="ml-2 inline-flex items-center rounded border py-1 pl-1 pr-1.5 text-xs">
              <CircularProgress
                size={16}
                progress={nCompletedSubtasks / props.task.subtasks.length}
              />
              <span className="ml-1 text-gray-500">
                {nCompletedSubtasks}/{props.task.subtasks.length}
              </span>
            </div>
          </div>
          <div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  icon={DotsThreeVertical}
                  type="tertiary"
                  size="extra-small"
                  color="gray"
                />
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" side="left">
                <DropdownMenuItem
                  onSelect={() => openElaraCommandDialog("page:task:insert_subtask")}>
                  {i18n.t("tasks:details.subtask_insert")}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <Button
              icon={Plus}
              size="extra-small"
              type="tertiary"
              color="gray"
              onClick={addSubtask.onOpen}
            />
          </div>
        </div>
        <CollapsibleContent>
          <SubtaskList workOrder={props.task} />
        </CollapsibleContent>
      </Collapsible>

      {addSubtask.isOpen && (
        <div className="my-2 rounded-lg bg-gray-50 p-3">
          <SubtaskForm
            parent={props.task}
            onCancel={addSubtask.onClose}
            onSubmit={addSubtask.onClose}
          />
        </div>
      )}
    </div>
  )
}
