import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { useMemo } from "react"

type Props = {
  data: { name: string; value: number }[]
}

export const SystemAvailabilityChart = ({ data }: Props) => {
  const options = useMemo<Highcharts.Options>(() => {
    return {
      chart: { type: "pie" },
      title: { text: "System Availability" },
      legend: { enabled: false },
      tooltip: { enabled: false },
      credits: { enabled: false },
      colors: ["#059669", "#0284c7"],
      navigation: { buttonOptions: { enabled: false } },
      plotOptions: {
        pie: {
          animation: false,
          dataLabels: {
            enabled: true,
            distance: "-50%",
            color: "white",
            shadow: false,
            style: { fontSize: "12px", textOutline: "none" },
            format: "{point.name}<br/>{point.percentage:.1f}%",
          },
        },
      },
      series: [
        {
          type: "pie",
          name: "System Availability",
          data: data.map((item) => [item.name, item.value]),
        },
      ],
    } satisfies Highcharts.Options
  }, [data])

  return <HighchartsReact highcharts={Highcharts} options={options} />
}
