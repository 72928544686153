import { useUser } from "@contexts/user-context"
import { Properties, PropertyConfig, PropertyConfigWithId } from "@elara/db"
import { useConsumableConfigQuery } from "@graphql/documents/consumable.generated"
import * as Yup from "yup"

export const useConsumableProperties = () => {
  const { location } = useUser()
  const [consumableConfigQueryRes] = useConsumableConfigQuery({
    variables: { locationId: location.id },
    requestPolicy: "cache-first",
  })
  const properties = Object.entries(
    consumableConfigQueryRes?.data?.consumable_config_by_pk?.properties ?? {}
  ).map(([id, c]) => ({ ...(c as PropertyConfig), id })) as PropertyConfigWithId[]

  return properties
}

export function yupPropertiesSchema(properties: PropertyConfigWithId[]) {
  return Yup.object().shape(
    Object.fromEntries(
      properties.map((prop) => {
        let cond: any = Yup.mixed()
        if (prop.type === "text") {
          cond = Yup.string()
        } else if (prop.type === "number") {
          cond = Yup.number()
        }
        if (!prop.required) {
          cond = cond.nullable().notRequired()
        } else {
          cond = cond
            .required("Ein Wert muss angegeben werden.")
            .typeError("Ein Wert muss angegeben werden.")
        }

        return [
          prop.id,
          Yup.object().shape({ value: cond, type: Yup.string().equals([prop.type]) }),
        ]
      })
    )
  )
}

export function propertiesFormValues(
  properties: PropertyConfigWithId[],
  values: Properties
) {
  return Object.fromEntries(
    properties
      .map((prop) => {
        const value = values[prop.id]
        if (!value || value.type !== prop.type)
          return [prop.id, { value: null, type: prop.type }]
        return [prop.id, value]
      })
      .filter(Boolean) as [string, Properties[string]][]
  )
}
