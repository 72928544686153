import retryFetchWrapper from "fetch-retry"

const retryFetch = retryFetchWrapper(fetch)

function retryDelay(attempt: number) {
  return Math.pow(2, attempt) * 1000
}

export const fetchWithRetry = (input: RequestInfo | URL, init?: RequestInit) =>
  retryFetch(input, {
    ...init,
    // Retry on any network error
    retryOn(attempt, error, response) {
      // Give up if our next retry would be more than 2 minutes
      if (retryDelay(attempt) > 120 * 1000) {
        return false
      }
      // retry on any network error, or 4xx or 5xx status codes
      if (error !== null || (response && response.status >= 400)) {
        return true
      }
      return false
    },
    // Exponential backoff
    retryDelay,
  })
