// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import { UploadDataFragmentDoc } from './upload.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IAssetSearchEverywhereResultFragment = { __typename: 'asset', id: uuid, name: string, position: string | null, public_id: string, avatar: { __typename: 'upload', id: uuid, file_name: string, file_size: number, mime_type: string, created_at: string, thumbnail_url: string | null, url: string | null } | null };

export type ISearchEverywhereQueryVariables = Types.Exact<{
  text: Types.Scalars['String']['input'];
  workOrderNumber: Types.Scalars['Int']['input'];
  assetPublicId: Types.IStringComparisonExp;
}>;


export type ISearchEverywhereQuery = { search_asset: Array<{ __typename: 'asset', id: uuid, name: string, position: string | null, public_id: string, avatar: { __typename: 'upload', id: uuid, file_name: string, file_size: number, mime_type: string, created_at: string, thumbnail_url: string | null, url: string | null } | null }>, asset: Array<{ __typename: 'asset', id: uuid, name: string, position: string | null, public_id: string, avatar: { __typename: 'upload', id: uuid, file_name: string, file_size: number, mime_type: string, created_at: string, thumbnail_url: string | null, url: string | null } | null }>, search_work_order: Array<{ __typename: 'work_order', id: uuid, name: string, status: Types.IWorkOrderStatusEnum, work_order_number: number }>, search_maintenance: Array<{ __typename: 'maintenance', id: uuid, name: string, description: string | null, paused: boolean }>, work_order: Array<{ __typename: 'work_order', id: uuid, name: string, status: Types.IWorkOrderStatusEnum, work_order_number: number }> };

export const AssetSearchEverywhereResultFragmentDoc = gql`
    fragment AssetSearchEverywhereResult on asset {
  __typename
  id
  name
  position
  public_id
  avatar {
    ...UploadData
  }
}
    ${UploadDataFragmentDoc}`;
export const SearchEverywhereDocument = gql`
    query SearchEverywhere($text: String!, $workOrderNumber: Int!, $assetPublicId: String_comparison_exp!) {
  search_asset(args: {search: $text}) {
    ...AssetSearchEverywhereResult
  }
  asset(where: {public_id: $assetPublicId}) {
    ...AssetSearchEverywhereResult
  }
  search_work_order(args: {search: $text}) {
    __typename
    id
    name
    status
    work_order_number
  }
  search_maintenance(args: {search: $text}) {
    __typename
    id
    name
    description
    paused
  }
  work_order(where: {work_order_number: {_eq: $workOrderNumber}}) {
    id
    name
    status
    work_order_number
  }
}
    ${AssetSearchEverywhereResultFragmentDoc}`;

export function useSearchEverywhereQuery(options: Omit<Urql.UseQueryArgs<ISearchEverywhereQueryVariables>, 'query'>) {
  return Urql.useQuery<ISearchEverywhereQuery, ISearchEverywhereQueryVariables>({ query: SearchEverywhereDocument, ...options });
};