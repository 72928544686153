import { cn } from "@utils"
import classNames from "classnames"
import React from "react"

export type TextInputWithUnitProps = JSX.IntrinsicElements["input"] & {
  unit: string
  inputClassName?: string
}

export const TextInputWithUnit = React.forwardRef<HTMLInputElement, TextInputWithUnitProps>(
  ({ unit, className, inputClassName, ...props }, forwardedRef) => {
    return (
      <label
        className={classNames(
          "relative flex items-center rounded-md border border-gray-200 bg-white focus-within:ring-1 focus-within:ring-blue-700 focus-within:border-blue-700",
          className
        )}>
        {/* <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span className="text-gray-500 sm:text-sm">$</span>
        </div> */}
        <input
          type="text"
          ref={forwardedRef}
          className={cn("block w-full rounded-md py-1.5 pl-2 sm:text-sm", inputClassName)}
          {...props}
        />
        <div className="pointer-events-none flex h-full items-center pr-3">
          <span className="truncate text-gray-500 sm:text-sm">{unit}</span>
        </div>
      </label>
    )
  }
)
