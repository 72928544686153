import { DataViewTabs } from "@components/shared/data-view/data-view-tabs"
import { IViewDataTypeEnum } from "@elara/db"
import i18n from "@i18n"

const ObjectActivityPage = () => (
  <DataViewTabs
    dataType={IViewDataTypeEnum.AssetState}
    title={i18n.t("assets:tabs.activity")}
  />
)

export default ObjectActivityPage
