import { Select, SelectProps } from "./select"

const currencies = [
  { label: "AUD $ (Australian Dollar)", value: "AUD" },
  { label: "EUR € (Euro)", value: "EUR" },
  { label: "GBP £ (Pound Sterling)", value: "GBP" },
  { label: "USD $ (US Dollar)", value: "USD" },
  { label: "CHF (Schweizer Franken)", value: "CHF" },
  { label: "BRL R$ (Brazilian Real)", value: "BRL" },
  { label: "CNY ¥ (Chinese Yuan)", value: "CNY" },
]

export function CurrencySelect(props: Omit<SelectProps, "items">) {
  return <Select items={currencies} {...props} />
}

export default CurrencySelect
