import { CaretDown } from "@phosphor-icons/react"
import { cn } from "@utils"

import { useDataViewConfigContext } from "./data-view-config"
import { useDataViewTableContext } from "./data-view-table"

const ResizeHandle = (props: {
  isActive: boolean
  height?: number
  onPointerDown: (e: React.PointerEvent) => void
  right?: number
}) => {
  return (
    <div
      className={cn(
        "absolute top-0 block w-2 cursor-col-resize bottom-0 border-r-2 border-solid border-r-grey-5 m-auto ",
        {
          "!border-r-blue-400": props.isActive,
        }
      )}
      onPointerDown={props.onPointerDown}
      style={{ height: props.height, right: props.right ?? 0 }}
    />
  )
}

const OrderIndicator = (props: { order: "desc" | "asc" | null }) => {
  return (
    <CaretDown
      className={cn("ml-2 h-3 w-3 text-gray-400 mr-2 shrink-0", {
        "rotate-180": props.order === "desc",
      })}
    />
  )
}

export const DataViewTableHeader = () => {
  const table = useDataViewTableContext()
  const ctx = useDataViewConfigContext()
  // const hasBatchSelectFeature = useFeature("batch_select")

  return (
    <div className="sticky !top-0 z-[1] box-border flex ">
      {ctx.renderedColumns.map((c, index) => {
        const order =
          ctx.config.orderBy?.[0]?.id === c.id ? ctx.config.orderBy?.[0]?.dir ?? null : null

        return (
          <div
            key={c.id}
            className="relative flex shrink-0 cursor-pointer touch-none items-center overflow-hidden border-y border-gray-100 bg-gray-50 px-2 py-2.5 text-left text-sm font-medium first:rounded-tl last:rounded-tr"
            style={{
              width: `var(--col-${index}-width)`,
              flexGrow: index === ctx.renderedColumns.length - 1 ? 1 : 0,
            }}
            {...table.getHeaderProps(c)}>
            {/* {hasBatchSelectFeature && index === 0 && (
              <label className="flex h-8 w-8 items-center justify-center">
                <CheckboxInput
                  checked={props.batchSelect.hasSelectedItem}
                  onChange={(e) => {
                    if (e.target.checked) {
                      props.batchSelect.selectAll()
                    } else {
                      props.batchSelect.unselectAll()
                    }
                  }}
                />
              </label>
            )} */}
            <div className="overflow-hidden text-ellipsis whitespace-nowrap">
              {c.Header}
            </div>
            {c.orderBy && <OrderIndicator order={order} />}
            <ResizeHandle
              {...table.getResizeHandlerProps(c)}
              height={20}
              right={index == ctx.renderedColumns.length - 1 ? 8 : 0}
            />
          </div>
        )
      })}
    </div>
  )
}
