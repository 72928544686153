import { ActionMenu, Button, Icons } from "@components/shared"
import AlertBox from "@components/shared/alert-box"
import { Collapse, CollapsePanel } from "@components/shared/collapse"
import { CollapsibleTabsLink } from "@components/shared/collapsible-tabs-links"
import LoadingSpinner from "@components/shared/loading-spinner"
import { usePage } from "@contexts/page-context"
import { IWorkOrderStatusEnum } from "@elara/db"
import { IMaintenanceFragment } from "@graphql/documents/fragments.generated"
import {
  useMaintenanceByIdQuery,
  useTasksByMaintenanceIdQuery,
} from "@graphql/documents/maintenance.generated"
import i18n from "@i18n"
import { Archive, Copy, Pause, Play, TrashSimple } from "@phosphor-icons/react"
import { formatDate } from "@utils/date"
import { useMemo } from "react"
import { Outlet, useParams } from "react-router-dom"

import { useMaintenance } from "./hooks"

type PageProps = { id?: string }

enum Tab {
  Details = "details",
  Triggers = "triggers",
  History = "history",
  Calendar = "calendar",
  Task = "task",
}

export type TabProps = {
  maintenance: IMaintenanceFragment
}

const MaintenanceDetailPage = (props: PageProps) => {
  const { id = props.id } = useParams() as { id: string }

  const {
    archiveMaintenance,
    reactivateMaintenance,
    deleteMaintenance,
    duplicateMaintenance,
    togglePauseState,
  } = useMaintenance({ id: id! })

  const [maintenanceRes] = useMaintenanceByIdQuery({ variables: { id: id! } })
  const maintenance = maintenanceRes.data?.maintenance_by_pk

  const [tasksRes] = useTasksByMaintenanceIdQuery({ variables: { maintenanceId: id! } })
  const maintenanceTasks = tasksRes.data?.work_order ?? []

  const pageTitle = useMemo(() => {
    return maintenance?.name ?? i18n.t("maintenance:title")
  }, [maintenance?.name])

  usePage({
    id: "maintenance",
    title: pageTitle,
    isSubPage: true,
    paused: !!props.id,
  })

  const isArchived = useMemo(() => {
    return maintenance?.archived_at !== null
  }, [maintenance])

  // Allow deleting a maintenance schedule only if there are no tasks with done or cancelled status
  const canDelete = useMemo(() => {
    return !maintenanceTasks.some(
      (t) =>
        t.status === IWorkOrderStatusEnum.Done || t.status === IWorkOrderStatusEnum.Canceled
    )
  }, [maintenanceTasks])

  const isDraft = useMemo(() => {
    if (maintenance) return maintenance.paused && maintenanceTasks.length === 0
    return false
  }, [maintenance, isArchived])

  if (!maintenance) {
    return <LoadingSpinner showLogo={false} />
  }

  return (
    <div className="flex min-h-0 flex-1 flex-col bg-gray-50">
      <div className="m-3 mb-1.5">
        <div className="flex items-center justify-between">
          <h2 className="text-2xl font-bold tracking-tight text-gray-700">
            {maintenance.name}
          </h2>

          <div className="flex items-center gap-x-3">
            {isArchived && (
              <Button
                type="secondary"
                icon={Icons.Reactivate}
                onClick={reactivateMaintenance}>
                {i18n.t("common:actions.reactivate")}
              </Button>
            )}

            {!isArchived && (
              <Button
                type="secondary"
                icon={maintenance.paused ? Play : Pause}
                onClick={() => togglePauseState(maintenance)}>
                {maintenance.paused
                  ? isDraft
                    ? i18n.t("maintenance:fields.status.release")
                    : i18n.t("maintenance:fields.status.resume")
                  : i18n.t("maintenance:fields.status.pause")}
              </Button>
            )}

            <ActionMenu
              items={[
                {
                  key: "duplicate",
                  label: i18n.t("common:actions.duplicate"),
                  icon: <Copy />,
                  action: () => duplicateMaintenance(maintenance),
                },
                {
                  key: "delete",
                  label: i18n.t("common:delete"),
                  icon: <TrashSimple />,
                  disabled: !canDelete,
                  tooltip: !canDelete
                    ? i18n.t("maintenance:messages.delete_disabled")
                    : undefined,
                  action: deleteMaintenance,
                },
                ...(!isArchived
                  ? [
                      {
                        key: "archive",
                        label: i18n.t("common:actions.archive"),
                        icon: <Archive />,
                        action: archiveMaintenance,
                      },
                    ]
                  : []),
              ]}
            />
          </div>
        </div>
        <h3 className="mt-1 text-sm text-gray-500">{maintenance.description}</h3>

        {isArchived && (
          <AlertBox type="archive" className="mt-2 max-w-4xl">
            <p className="leading-relaxed">
              {i18n.t("maintenance:messages.status.archived", {
                archivedAt: formatDate(new Date(maintenance.archived_at!), "PPP"),
              })}
            </p>
          </AlertBox>
        )}

        {!isArchived && isDraft && (
          <AlertBox type="warning" className="mt-2 max-w-4xl">
            <Collapse>
              <CollapsePanel
                name="draft"
                headerClassName="font-medium"
                header={i18n.t("maintenance:messages.status.draft")}>
                <p className="ml-5 mt-1 leading-relaxed">
                  {i18n.t("maintenance:messages.status.draft_explained")}
                </p>
              </CollapsePanel>
            </Collapse>
          </AlertBox>
        )}

        {!isArchived && !isDraft && maintenance.paused && (
          <AlertBox type="info" className="mt-2 max-w-4xl">
            <p className="leading-relaxed">
              {i18n.t("maintenance:messages.status.paused")}
            </p>
          </AlertBox>
        )}
      </div>

      <section className="flex min-h-0 flex-1 flex-col">
        <CollapsibleTabsLink
          triggers={[
            { value: Tab.Details, label: i18n.t("maintenance:tabs.details") },
            { value: Tab.Task, label: i18n.t("common:template_one") },
            { value: Tab.History, label: i18n.t("maintenance:tabs.history") },
            { value: Tab.Calendar, label: i18n.t("maintenance:tabs.calendar") },
          ]}
        />
        <div className="flex min-h-0 flex-1 flex-col bg-white">
          <Outlet context={{ maintenance }} />
        </div>
      </section>
    </div>
  )
}

export default MaintenanceDetailPage
