import { useTreeList } from "@hooks/use-tree-list"
import Icons from "@resources/icons"
import { cn } from "@utils"
import classNames from "classnames"
import { ReactNode } from "react"
import { Checkbox, Item as GridListItem } from "react-aria-components"

import { Item, ItemTreeElement } from "./combobox-select.hook"

type ComboboxSelectItemProps<V> = {
  item: ItemTreeElement<V>
  valueToString: (value: V) => string
  isGroupByHeader?: boolean
  getNodeDisclosure: ReturnType<typeof useTreeList<Item<V>>>["getNodeDisclosure"]
  indentLevel?: number
  onSelect?: (item: ItemTreeElement<V>, keepOpen?: boolean) => void
}

function ComboboxSelectItemChildren<V>(props: ComboboxSelectItemProps<V>) {
  const { item } = props

  const { isOpen } = props.getNodeDisclosure(item)
  return (
    <>
      {isOpen &&
        item.children?.map((child) => (
          <ComboboxSelectItem
            {...props}
            indentLevel={(props.indentLevel ?? 0) + 1}
            key={props.valueToString(child.value)}
            item={child}
          />
        ))}
    </>
  )
}

export function ComboboxSelectItem<V>(props: {
  item: ItemTreeElement<V>
  valueToString: (value: V) => string
  header?: ReactNode
  getNodeDisclosure: ReturnType<typeof useTreeList<Item<V>>>["getNodeDisclosure"]
  indentLevel?: number
  onSelect?: (item: ItemTreeElement<V>, keepOpen?: boolean) => void
  size?: "large" | "small"
}) {
  const { item, indentLevel = 0, size = "small" } = props
  const hasToggle = !!item.children?.length

  const { isOpen, toggle } = props.getNodeDisclosure(item)

  return (
    <>
      <GridListItem
        key={props.valueToString(item.value)}
        id={props.valueToString(item.value)}
        aria-label={item.searchValue}>
        {(itemProps) => {
          const hasCheckbox = itemProps.selectionMode === "multiple"
          return (
            <div>
              {props.header ? <div className="mb-1">{props.header}</div> : null}
              <div
                className={cn(
                  "flex min-h-[2.5rem] cursor-default select-none items-center pr-3 py-1.5 text-sm outline-none aria-selected:bg-gray-300 box-border border-transparent",
                  { "bg-gray-100": itemProps.isFocused || itemProps.isHovered },
                  { "bg-gray-100 font-medium": !hasCheckbox && itemProps.isSelected },
                  { "border-gray-400": itemProps.isSelected && itemProps.isFocused },
                  { "opacity-60": itemProps.isDisabled }
                )}
                style={{
                  paddingLeft:
                    size === "large"
                      ? hasToggle
                        ? `${indentLevel}rem`
                        : `${indentLevel + 2}rem`
                      : `${indentLevel + 1}rem`,
                }}>
                {hasToggle && (
                  <button
                    className={classNames(
                      "w-8 h-8 p-2 transform transition-transform duration-100 focus-visible:ring-2 rounded-lg ring-black",
                      {
                        "-rotate-90": !isOpen,
                      }
                    )}
                    onKeyDown={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      toggle()
                    }}
                    onPointerDown={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      toggle()
                    }}
                    onPointerUp={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    onClickCapture={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                    }}>
                    <Icons.Down height={16} width={16} />
                  </button>
                )}

                {hasCheckbox && (
                  <Checkbox
                    slot="selection"
                    className={cn(
                      itemProps.isSelected ? "bg-blue-700" : "bg-white",
                      "w-5 h-5 flex items-center justify-center cursor-pointer rounded form-checkbox box-border text-white border-grey-5 focus:outline-none focus:ring-transparent disabled:bg-gray-50 disabled:cursor-not-allowed mr-2"
                    )}>
                    <svg className="h-3 w-3 stroke-current" viewBox="0 0 18 18">
                      <polyline
                        points="1 9 7 14 15 4"
                        fill="none"
                        strokeWidth={3}
                        strokeDasharray={22}
                        strokeDashoffset={itemProps.isSelected ? 44 : 66}
                        style={{
                          transition: "all 400ms",
                        }}
                      />
                    </svg>
                  </Checkbox>
                )}

                <div
                  className={classNames("flex items-center gap-2", {
                    "py-1": !(itemProps.selectionMode === "multiple"),
                  })}>
                  {item.icon && (
                    <span className="inline-flex overflow-hidden rounded-full text-lg">
                      {item.icon}
                    </span>
                  )}
                  <span className="min-h-[18px] break-all text-gray-700">{item.label}</span>
                </div>
              </div>
            </div>
          )
        }}
      </GridListItem>
      <ComboboxSelectItemChildren {...props} />
    </>
  )
}
