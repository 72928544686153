import React, { useEffect, useMemo, useState } from "react"

import { useAnalytics } from "./analytics-context"

type PageState = {
  id: string | null
  isSubPage: boolean
  title: string
}

type PageContextValue = {
  state: PageState
  setState: (state: PageState) => void
}

export const PageContext = React.createContext<PageContextValue>({
  state: { id: null, title: "", isSubPage: false },
  setState: () => {},
})

export const PageProvider = (props: React.PropsWithChildren<{}>) => {
  const [state, setState] = useState<PageState>({ id: null, isSubPage: false, title: "" })

  const value = useMemo(() => ({ state, setState }), [state])

  return <PageContext.Provider value={value}>{props.children}</PageContext.Provider>
}

let mountedPage: string | null = null

export const usePage = (options: {
  id: string
  isSubPage?: boolean
  title: string
  paused?: boolean
}) => {
  const { posthog } = useAnalytics()
  const { state, setState } = React.useContext(PageContext)
  const { id, title, isSubPage = false } = options

  useEffect(() => {
    if (options.paused) return

    if (mountedPage) {
      console.warn(
        "Attempted to mount new page with id =",
        id,
        " while page with id = ",
        state.id,
        " is still mounted"
      )
      return
    }
    mountedPage = id
    posthog?.capture("$pageview", { path: id.startsWith("/") ? id : "/" + id })
    setState({ title, isSubPage, id })
    window.document.title = title ? `${title} | Elara` : "Elara"

    return () => {
      posthog?.capture("$pageleave", { path: id.startsWith("/") ? id : "/" + id })
      mountedPage = null
    }
  }, [options.id, options.paused])

  useEffect(() => {
    if (options.paused) return

    setState({ ...state, title })
    window.document.title = title ? `${title} | Elara` : "Elara"
  }, [title, options.paused])
}
