import { getMeterReadingTriggerDigest } from "@components/maintenance/components/meter-reading-trigger-digest"
import { getTimeIntervalTriggerDigest } from "@components/maintenance/components/time-interval-trigger-digest"
import { AssetTag } from "@components/shared/asset-tag"
import {
  createSchema,
  createSchemaStatement,
  generalSelectStateToWhere,
} from "@components/shared/data-view/data-view-filter.hooks"
import { Tooltip } from "@components/shared/tooltip"
import {
  genericAssetGroups,
  genericAssets,
} from "@components/work-order/work-order-data-view-filter-configuration"
import {
  IMaintenanceTriggerTypeEnum,
  IViewDataTypeEnum,
  MeterReadingTriggerOptions,
  TimeIntervalTriggerOptions,
} from "@elara/db"
import { Where } from "@elara/select"
import {
  IMaintenanceFragment,
  IWorkOrderDataViewFragment,
} from "@graphql/documents/fragments.generated"
import {
  IMaintenanceQuery,
  IMaintenanceQueryVariables,
  MaintenanceDocument,
} from "@graphql/documents/maintenance.generated"
import i18n from "@i18n"
import { Pause } from "@phosphor-icons/react"
import { cn, parseDate } from "@utils"
import { formatDate } from "@utils/date"
import { useNavigateWithBackgroundLocation } from "@utils/location"
import { Fragment, useMemo } from "react"
import { useClient } from "urql"

import { DataView } from "../data-view"
import { Column, DataViewConfiguration } from "../data-view-types"
import { MaintenanceDataViewCalendar } from "./maintenance-data-view-calendar"
import {
  IMaintenancesCountQuery,
  IMaintenancesCountQueryVariables,
  MaintenancesCountDocument,
} from "./queries.generated"
import { DataViewImplementation } from "./types"

type MaintenanceColumnOptions = {}

export type MaintenanceColumnIds =
  | "name"
  | "createdAt"
  | "description"
  | "triggers"
  | "objects"
  | "object_groups"
  | "nextAt"
  | "archivedAt"

const columnDefinitions: Column<
  IMaintenanceFragment,
  MaintenanceColumnIds,
  MaintenanceColumnOptions
>[] = [
  {
    id: "name",
    Header: i18n.t("maintenance:fields.name"),
    defaultWidth: 200,
    calendarView: {
      default: true,
      // This is hacky, I don't need it but to make the calendar view work I need to define it
      event: ({ id, name }) => {
        return {
          id,
          title: name,
        }
      },
    },
    Cell: ({ name, paused }) => (
      <span className="inline-flex items-center space-x-1 font-medium text-gray-800">
        <span className={cn({ "text-gray-500": paused })}>{name}</span>
        {paused && (
          <Tooltip
            content={
              <p className="text-xs">{i18n.t("maintenance:messages.status.paused")}</p>
            }
            className="inline-flex">
            <Pause className="text-gray-400" weight="fill" />
          </Tooltip>
        )}
      </span>
    ),
    toText: ({ name }) => name,
    orderBy: (dir) => ({ name: dir }),
    searchQuery: (value) => ({ name: { _ilike: `%${value}%` } }),
  },
  {
    id: "description",
    Header: i18n.t("maintenance:fields.description"),
    defaultWidth: 300,
    Cell: ({ description }) => description,
    orderBy: (dir) => ({ description: dir }),
    toText: ({ description }) => description ?? "",
    searchQuery: (value) => ({ description: { _ilike: `%${value}%` } }),
  },
  {
    id: "createdAt",
    Header: i18n.t("common:created_at"),
    defaultWidth: 300,
    Cell: ({ created_at }) => formatDate(parseDate(created_at), "Pp"),
    orderBy: (dir) => ({ created_at: dir }),
    toText: ({ created_at }) => created_at ?? "",
  },
  {
    id: "triggers",
    Header: i18n.t("maintenance:fields.triggers"),
    defaultWidth: 200,
    Cell: ({ triggers }) =>
      triggers
        .map((t) => {
          if (t.type === IMaintenanceTriggerTypeEnum.Time) {
            return `${i18n.t(
              "maintenance:labels.triggers.time.digest_prefix"
            )}: ${getTimeIntervalTriggerDigest(
              t.payload as TimeIntervalTriggerOptions["payload"]
            )}`
          } else {
            return `${i18n.t(
              "maintenance:labels.triggers.meter.digest_prefix"
            )}: ${getMeterReadingTriggerDigest(
              t.payload as MeterReadingTriggerOptions["payload"],
              t.meter_unit
            )}`
          }
        })
        .join(", "),
    toText: ({ triggers }) =>
      triggers
        .map((t) => {
          if (t.type === IMaintenanceTriggerTypeEnum.Time) {
            return `${i18n.t(
              "maintenance:labels.triggers.time.digest_prefix"
            )}: ${getTimeIntervalTriggerDigest(
              t.payload as TimeIntervalTriggerOptions["payload"]
            )}`
          } else {
            return `${i18n.t(
              "maintenance:labels.triggers.meter.digest_prefix"
            )}: ${getMeterReadingTriggerDigest(
              t.payload as MeterReadingTriggerOptions["payload"],
              t.meter_unit
            )}`
          }
        })
        .join(", "),
  },
  {
    id: "objects",
    Header: i18n.t("maintenance:data-view.columns.objects"),
    defaultWidth: 300,
    groupBy: {
      id: (row) => row.template?.assets?.map((a) => a.asset.id) ?? [],
      label: (groupId, row) => {
        if (!row) return null
        const a = row.template?.assets.find((a) => a.asset.id === groupId)?.asset

        if (!a) {
          return i18n.t("common:without_token", {
            token: i18n.t("common:asset", { count: 1 }),
          })
        }

        return <AssetTag asset={a} />
      },
    },
    Cell: (row) => (
      <div className="flex flex-wrap gap-1">
        {row.template?.assets.map((a) => (
          <AssetTag key={a.asset.id} asset={a.asset} />
        ))}
      </div>
    ),
    toText: (row) => row.template?.assets.map((a) => a.asset.name).join(", ") ?? "",
    searchQuery: (value) => ({
      template: { assets: { asset: { name: { _ilike: `%${value}%` } } } },
    }),
  },
  {
    id: "object_groups",
    Header: i18n.t("maintenance:data-view.columns.object_groups"),
    defaultWidth: 300,
    groupBy: {
      id: (row) =>
        Array.from(
          new Set(
            row.template?.assets
              .filter((a) => !!a.asset.group)
              .map((a) => a.asset.group!.id)
          )
        ) ?? [],
      label: (groupId, row) => {
        if (!row) return null

        const a = row.template?.assets
          .filter((a) => !!a.asset.group)
          .find((a) => a.asset.group!.id === groupId)?.asset.group

        return (
          a?.name ??
          i18n.t("common:without_token", {
            token: i18n.t("assets:fields.group", { count: 1 }),
          })
        )
      },
    },
    Cell: (row) => {
      const assetGroups = Array.from(
        new Set(
          row.template?.assets
            .filter((a) => !!a.asset.group)
            .map((a) => a.asset.group!.name)
        )
      )

      return <div className="flex flex-wrap gap-1">{assetGroups.join(", ")}</div>
    },
    toText: (row) => {
      const assetGroups = Array.from(
        new Set(
          row.template?.assets
            .filter((a) => !!a.asset.group)
            .map((a) => a.asset.group!.name)
        )
      )

      return assetGroups.join(", ") ?? ""
    },
    searchQuery: (value) => ({
      template: { assets: { asset: { group: { name: { _ilike: `%${value}%` } } } } },
    }),
  },
  {
    id: "archivedAt",
    Header: i18n.t("maintenance:fields.archived_at"),
    defaultWidth: 300,
    Cell: ({ archived_at }) =>
      archived_at ? formatDate(parseDate(archived_at), "P") : null,
    toText: ({ archived_at }) =>
      archived_at ? formatDate(parseDate(archived_at), "P") : "",
  },
  {
    id: "nextAt",
    Header: i18n.t("maintenance:data-view.columns.next_at"),
    defaultWidth: 300,
    Cell: (row) => (
      <div className="flex flex-wrap gap-1">
        {row.triggers
          .map((t) => {
            if (t.type === IMaintenanceTriggerTypeEnum.Time) {
              const p = t.payload as TimeIntervalTriggerOptions["payload"]
              return (
                <Fragment key={t.id}>
                  {p.nextAt
                    ? formatDate(parseDate(p.nextAt), p.nextTime ? "Pp" : "P")
                    : null}
                </Fragment>
              )
            } else {
              const p = t.payload as MeterReadingTriggerOptions["payload"]
              if (p.variant === "interval") {
                return (
                  <Fragment key={t.id}>
                    {p.nextAt ? (
                      <span>
                        {p.nextAt} {t.meter_unit}{" "}
                        <span className="text-gray-600">
                          (aktuell {t.meter_last_reading} {t.meter_unit})
                        </span>
                      </span>
                    ) : null}
                  </Fragment>
                )
              }
              return null
            }
          })
          .filter(Boolean)}
      </div>
    ),
    toText: (row) => row.template?.assets.map((a) => a.asset.name).join(", ") ?? "",
  },
]

const useFilterSchema = () => {
  const schema = useMemo(
    () =>
      createSchema<IMaintenanceFragment>({
        assets: genericAssets<IMaintenanceFragment>(
          (state) =>
            generalSelectStateToWhere(["template", "assets", "asset", "id"], state, {
              isOneToMany: true,
            }) as Where<IMaintenanceFragment>
        ),
        assetGroups: genericAssetGroups<IMaintenanceFragment>(
          (state) =>
            generalSelectStateToWhere(
              ["template", "assets", "asset", "group", "id"],
              state,
              { isOneToMany: true }
            ) as Where<IMaintenanceFragment>
        ),
        maintenanceType: createSchemaStatement<IMaintenanceFragment, string>({
          type: "select",
          label: i18n.t("maintenance:fields.triggers"),
          multiSelectedLabel: i18n.t("maintenance:fields.triggers"),
          getItems: async () => {
            return [
              {
                value: IMaintenanceTriggerTypeEnum.Time,
                label: i18n.t("maintenance:labels.triggers.time.title"),
                searchValue: i18n.t("maintenance:labels.triggers.time.title"),
              },
              {
                value: IMaintenanceTriggerTypeEnum.Meter,
                label: i18n.t("maintenance:labels.triggers.meter.title"),
                searchValue: i18n.t("maintenance:labels.triggers.meter.title"),
              },
            ]
          },
          toWhere: (state) => {
            const cond = generalSelectStateToWhere(["triggers", "type"], state, {
              isOneToMany: true,
            }) satisfies Where<IMaintenanceFragment>
            return state.negated ? { _not: cond } : cond
          },
        }),
        paused: createSchemaStatement<IMaintenanceFragment, string>({
          type: "singleton",
          label: i18n.t("maintenance:fields.is_paused"),
          badgeLabel: i18n.t("maintenance:fields.is_paused"),

          operatorLabel: (negated) =>
            negated ? i18n.t("data-view:filters.has_no") : i18n.t("data-view:filters.has"),
          toWhere: (state) => {
            const cond = { paused: { _eq: true } } satisfies Where<IMaintenanceFragment>
            return state.negated ? { _not: cond } : cond
          },
        }),
      }),
    []
  )

  return { schema }
}

export const maintenanceDataViewDefaultConfig = {
  columnOrder: ["name", "triggers", "objects", "object_groups", "nextAt"],
  orderBy: [{ id: "name", dir: "asc" }],
} as DataViewConfiguration<MaintenanceColumnIds>

export const maintenanceArchiveDataViewDefaultConfig = {
  columnOrder: ["name", "archivedAt", "triggers", "objects", "object_groups"],
  orderBy: [{ id: "archivedAt", dir: "desc" }],
} as DataViewConfiguration<MaintenanceColumnIds>

const maintenanceListItem = (maintenance: IMaintenanceFragment) => (
  <div className="group inline-flex flex-col rounded py-3">
    <div className="flex items-center">
      <div className="leading-loose">
        <p className="text-sm font-medium">{maintenance.name}</p>
        <p className="text-xs opacity-50">{maintenance.description}</p>
      </div>
    </div>
  </div>
)

const MaintenanceDataView = ({
  defaultConfig,
  archive,
  ...props
}: DataViewImplementation<
  IMaintenanceFragment,
  MaintenanceColumnIds,
  IMaintenanceQuery,
  IMaintenanceQueryVariables
> & { archive?: boolean }) => {
  const filter = useFilterSchema()
  const navigateWithBackgroundLocation = useNavigateWithBackgroundLocation()
  const client = useClient()

  const selectMaintenance = (item: IMaintenanceFragment) => {
    navigateWithBackgroundLocation(`/maintenance/${item.id}`)
  }
  const selectTask = (item: IWorkOrderDataViewFragment) => {
    navigateWithBackgroundLocation(`/task/${item.id}`)
  }

  return (
    <DataView<
      IMaintenanceFragment,
      MaintenanceColumnIds,
      IMaintenanceQuery,
      IMaintenanceQueryVariables
    >
      columns={columnDefinitions}
      defaultSearchColumn="name"
      dataType={
        archive ? IViewDataTypeEnum.MaintenanceArchive : IViewDataTypeEnum.Maintenance
      }
      defaultConfig={
        defaultConfig ??
        (archive
          ? maintenanceArchiveDataViewDefaultConfig
          : maintenanceDataViewDefaultConfig)
      }
      configId={
        props.configId ?? props.customView?.id ?? `maintenance:archive=${!!archive}:default`
      }
      renderListItem={maintenanceListItem}
      configStickyness="localStorage"
      schema={filter.schema}
      tieBraker={[{ id: "createdAt", dir: "desc" }]}
      getId={(r) => r.id}
      getData={(data) => data?.maintenance ?? []}
      query={MaintenanceDocument}
      chunkSize={30}
      onSelect={selectMaintenance}
      where={{
        archived_at: { _is_null: !archive },
      }}
      numberOfRows={(where) =>
        client
          .query<IMaintenancesCountQuery, IMaintenancesCountQueryVariables>(
            MaintenancesCountDocument,
            { where: where as any }
          )
          .toPromise()
          .then(({ data }) => data?.maintenance_aggregate?.aggregate?.count ?? 0)
      }
      calendar={
        <MaintenanceDataViewCalendar
          onSelectMaintenance={selectMaintenance}
          onSelectTask={selectTask}
        />
      }
      {...props}
    />
  )
}

export default MaintenanceDataView
