import { useComposedRefs } from "@hooks/use-compose-refs"
import { useElementSize } from "@hooks/use-element-size"
import { DotsThree } from "@phosphor-icons/react"
import * as Tabs from "@radix-ui/react-tabs"
import { cn } from "@utils"
import { useNavigateWithBackgroundLocation } from "@utils/location"
import React, { ReactNode, useEffect, useRef, useState } from "react"
import { NavLink, useLocation } from "react-router-dom"

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./dropdown"
import TouchTargetSize from "./touch-target-size"

type ListProps = React.ComponentProps<typeof Tabs.List> & {
  triggers: { value: string; label: ReactNode; icon?: ReactNode }[]
}

export const CollapsibleTabsLink = React.forwardRef<
  React.ElementRef<typeof Tabs.List>,
  ListProps
>(({ triggers, ...props }, forwardedRef) => {
  const [element, setElement] = useState<React.ElementRef<typeof Tabs.List> | null>(null)

  const navigate = useNavigateWithBackgroundLocation()
  const width = useRef<Record<string, number>>({})
  const ref = useComposedRefs(forwardedRef, (e) => setElement(e))
  const size = useElementSize(element)
  const [hiddenTriggers, setHiddenTriggers] = useState<string[]>([])
  const location = useLocation()

  useEffect(() => {
    if (!size) return

    let totalWidth = 36 // width of the menu + padding
    let hidden: string[] = []
    for (const trigger of triggers) {
      const triggerWidth = width.current[trigger.value]
      if (triggerWidth) {
        totalWidth += triggerWidth
        if (totalWidth > size.width) {
          hidden.push(trigger.value)
        }
      }
    }
    setHiddenTriggers(hidden)
  }, [size?.width])

  return (
    <div
      {...props}
      ref={ref}
      className="relative flex min-h-0 snap-x snap-mandatory overflow-x-auto scrollbar-hide">
      <div className="w-3 border-b border-b-gray-200 sm:w-0" />

      {triggers.map((trigger) => {
        if (hiddenTriggers.includes(trigger.value)) return null

        return (
          <NavLink
            key={trigger.value}
            state={location.state}
            to={trigger.value}
            ref={(e) => {
              if (!e) return
              width.current[trigger.value] = e.offsetWidth
            }}
            className={({ isActive }) =>
              cn(
                "snap-center border-b pb-px border-gray-200",
                "transition-colors duration-200 ease-in-out",
                {
                  "pb-0 border-b-2 border-blue-600 group nav-active [&>div]:!text-blue-700 [&>div]:text-bold [&>div]:!bg-transparent":
                    isActive,
                },
                props.className
              )
            }>
            <div
              className={cn(
                "font-medium m-1 whitespace-nowrap rounded-lg px-3 py-1.5 text-sm text-gray-500 transition-colors hover:bg-gray-100"
              )}>
              <div className="flex items-center space-x-2">
                <span>{trigger.icon}</span>
                <span>{trigger.label}</span>
              </div>
            </div>
          </NavLink>
        )
      })}

      {hiddenTriggers.length > 0 && (
        <div className=" flex shrink basis-full justify-start border-b border-gray-200">
          <DropdownMenu>
            <DropdownMenuTrigger className="group relative text-gray-700">
              <div className="rounded-md p-1 group-hover:bg-gray-100 group-radix-state-open:bg-gray-100">
                <DotsThree size={20} weight="bold" />
              </div>
              <TouchTargetSize />
            </DropdownMenuTrigger>
            <DropdownMenuContent side="bottom" align="end">
              {triggers
                .filter((trigger) => hiddenTriggers.includes(trigger.value))
                .map((trigger) => {
                  return (
                    <DropdownMenuItem
                      key={trigger.value}
                      onSelect={() => {
                        navigate(trigger.value)
                      }}>
                      <span className="flex items-center space-x-2">
                        <span>{trigger.icon}</span>
                        <span>{trigger.label}</span>
                      </span>
                    </DropdownMenuItem>
                  )
                })}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      )}
      <div className="w-3 shrink-0 grow border-b border-b-gray-200 sm:w-0" />
    </div>
  )
})
