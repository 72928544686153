import Icons from "@resources/icons"
import classNames from "classnames"

export const OpenCloseToggleIcon = (props: { isOpen: boolean; className?: string }) => {
  return (
    <div
      className={classNames(
        "transform transition-transform duration-50",
        {
          "-rotate-90": !props.isOpen,
        },
        props.className
      )}>
      <Icons.Down />
    </div>
  )
}
