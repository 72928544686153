import { Data, OrderBy, Where } from "@elara/select"
import { useEffect, useLayoutEffect, useRef } from "react"
import React from "react"
import {
  AnyVariables,
  TypedDocumentNode,
  useClient,
  useQuery,
  UseQueryResponse,
} from "urql"

import { useDataViewRefreshData } from "./lib/hooks"

export type DataViewFetcherRenderProps<D extends Data, R, V extends AnyVariables> = {
  rows: D[]
  isFetching: boolean
  isStale: boolean
  error: unknown
  reexecuteQuery: UseQueryResponse<R, V>[1]
}
export type DataViewFetcherProps<D extends Data, R, V extends AnyVariables> = {
  query: TypedDocumentNode<R, V>
  where: Where<D>
  orderBy: OrderBy<D>[]
  limit: number
  offset: number
  getData: (data: R | undefined) => D[]
  render?: (props: DataViewFetcherRenderProps<D, R, V>) => React.ReactElement
  onRenderPropsChange?: (props: DataViewFetcherRenderProps<D, R, V>) => void
  delay?: number
}

function useLaggyData<T>(data: T | undefined) {
  const laggyDataRef = useRef<T | undefined>()
  useEffect(() => {
    // Update ref if data is defined
    if (!!data) {
      laggyDataRef.current = data
    }
  }, [data])
  if (data === undefined) {
    return laggyDataRef.current
  }
  return data
}

export function DataViewFetcher<D extends Data, R, V extends AnyVariables>(
  props: DataViewFetcherProps<D, R, V>
) {
  const variables = {
    where: props.where,
    orderBy: props.orderBy,
    limit: props.limit,
    offset: props.offset || 0,
  }

  const [paused, setPaused] = React.useState(!!props.delay)
  const [queryRes, reexecuteQuery] = useQuery<R, V>({
    query: props.query,
    variables: variables as unknown as V,
    pause: paused,
  })

  useDataViewRefreshData(() => {
    reexecuteQuery({ requestPolicy: "network-only" })
  })

  const client = useClient()
  // Delay the initial query if delay is set
  useLayoutEffect(() => {
    if (!props.delay) return
    const res = client.readQuery<R, V>(props.query, variables as unknown as V)
    if (res?.data) {
      setPaused(false)
    } else {
      if (props.delay) {
        setTimeout(() => {
          setPaused(false)
        }, props.delay)
      }
    }
  }, [])

  // We add a ref to store the data from the last query
  // in case the query variables change (in particular if the cursor changes)
  // This way we avoid a "flicker" where queryRes.data is null for a short time (while the query is loading)
  // (and the UI would possible jump around).
  const data = useLaggyData(queryRes.data)

  const allRows = data ? props.getData(queryRes.data) : ([] as D[])
  if (allRows.length === 0) {
  }
  const rows = allRows.slice(0, props.limit)

  const renderProps = {
    rows,
    reexecuteQuery,
    isFetching: queryRes.fetching,
    isStale: queryRes.stale,
    error: queryRes.error,
  }
  useEffect(() => {
    // Don't trigger the render props on the first mount where urql is still setting things
    // up. This might override preload data
    if (!queryRes.fetching && !queryRes.stale && !queryRes.error && !queryRes.data) {
      return
    }
    props.onRenderPropsChange?.(renderProps)
  }, [queryRes])

  return <>{props.render?.(renderProps)}</>
}
