import { Language } from "@elara/db"
import i18n from "@i18n"

type LocaleRecord = {
  code: Language
  name: string
}

export const localesList: LocaleRecord[] = [
  { code: "de-DE", name: "Deutsch (Deutschland)" },
  { code: "en-AU", name: "English (New Zealand)" },
  { code: "en-US", name: "English (United States)" },
  { code: "pl-PL", name: "Polish (Poland)" },
  { code: "pt-BR", name: "Portuguese (Brazil)" },
  { code: "zh-CN", name: "Chinese (Simplified)" },
]

export const useLocaleOptions = () => {
  return localesList.map((language) => ({
    value: language.code,
    label: language.name,
  }))
}

export const changeAppLanguage = async (code: string, shouldReload: boolean = true) => {
  await i18n.changeLanguage(code)
  document.documentElement.setAttribute("lang", code)

  shouldReload && window.location.reload()
}
