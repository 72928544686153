import { uuid } from "@elara/db"
import { IFileFolderFragment } from "@graphql/documents/file-folder.generated"
import {
  IDocumentCardDataFragment,
  IUploadDataFragment,
} from "@graphql/documents/upload.generated"
import { useDeleteFile, useDisclosure, useEditFileName } from "@hooks"
import i18n from "@i18n"
import {
  DotsThreeVertical,
  DownloadSimple,
  TextAUnderline,
  TrashSimple,
  X,
} from "@phosphor-icons/react"
import Icons from "@resources/icons"
import { colors, px } from "@styles"
import classNames from "classnames"
import React, { FC, useCallback, useEffect, useRef, useState } from "react"

import ActionMenu from "./action-menu"
import Button from "./button"
import CircularProgress from "./circular-progress"
import { useConfirmModal } from "./confirm-modal"
import { DocumentCardIcon, isImage } from "./document-card-icon"
import { TextInput } from "./text-input"

type DocumentCardActionMenuProps = {
  downloadUrl?: string
  onDelete?: () => void
  onEdit?: () => void
}

const DocumentCardActionMenu: FC<DocumentCardActionMenuProps> = (props) => {
  if (!props.downloadUrl && !props.onDelete && !props.onEdit) return null

  return (
    <ActionMenu
      items={[
        {
          key: "download",
          label: props.downloadUrl && (
            <a href={props.downloadUrl} style={{ display: "flex", alignItems: "center" }}>
              <DownloadSimple className="mr-2" /> {i18n.t("common:download")}
            </a>
          ),
        },
        {
          key: "rename",
          icon: <TextAUnderline />,
          label: props.onEdit && i18n.t("common:rename"),
          action: props.onEdit,
        },
        {
          key: "delete",
          icon: <TrashSimple />,
          label: props.onDelete && i18n.t("common:delete"),
          action: props.onDelete,
        },
      ]}>
      <Button
        type="tertiary"
        icon={DotsThreeVertical}
        style={{ color: colors.grey3, margin: 4 }}
      />
    </ActionMenu>
  )
}

const useEditDocumentName = (
  id: string,
  name: string,
  sendToServer: boolean,
  onEditFileName?: (
    id: IDocumentCardDataFragment["id"],
    newFileName: string
  ) => Promise<void> | void
) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [isEditing, setIsEditing] = useState(false)
  const [editedName, setEditedName] = useState(name)
  useEffect(() => {
    if (isEditing && name !== editedName) {
      setEditedName(name)
    }
  }, [name, isEditing])

  const editFileName = useEditFileName()
  const startEdit = useCallback(() => {
    setIsEditing(true)
    // Set focus after a timeout to allow for rendering of the input element
    setTimeout(() => inputRef.current?.focus(), 200)
  }, [inputRef])

  const saveEdit = async () => {
    setIsEditing(false)
    if (sendToServer) {
      await editFileName(id, editedName)
    }
    onEditFileName?.(id, editedName)
  }

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedName(e.target.value)
  }, [])

  const stopEdit = () => {
    setIsEditing(false)
  }

  return { isEditing, inputRef, onChange, startEdit, saveEdit, editedName, stopEdit }
}

/**
 * On mobile we cannot simply open a new tab since this
 * doesn't work as expected if the users have Elara installed on their home screen.
 * However, if we have an `<a href="{{url}}" target="_blank"> where `url`
 * is *another* domain, then a new browser window opens in a modal.
 * So to get an inline file viewer going we have to access the file via another
 * domain. Easiest is to directly link to Azure.
 * Now the **fun part**: iOS ignores all programmatic clicks. So we cannot simply create an `a` tag lazily
 * with the external URL but we have to do it preemptively :sigh:
 */
function useExternalDocumentUrl(document: IUploadDataFragment | undefined) {
  const [externalUrl, setExternalUrl] = useState(document?.url)
  useEffect(() => {
    const fetchUrl = async () => {
      setExternalUrl(document?.url)
      if (
        document?.url &&
        !isImage(document?.mime_type) &&
        !document.url.startsWith("data:")
      ) {
        const res = await fetch(document.url.replace("/api/storage", "/api/storage/direct"))
        const { url } = await res.json()
        setExternalUrl(url)
      }
    }
    fetchUrl()
  }, [document?.url])

  return externalUrl
}

export type DocumentCardProps = {
  document?: IUploadDataFragment
  folder?: IFileFolderFragment["sub_folders"][number]
  uploadProgress?: number
  allowDelete?: boolean
  allowEdit?: boolean
  onOpenFileFolder?: (id: uuid) => void
  onDelete?: (id: IDocumentCardDataFragment["id"]) => Promise<void> | void
  onEditFileName?: (
    id: IDocumentCardDataFragment["id"],
    newFileName: string
  ) => Promise<void> | void
  sendEditToServer?: boolean
  sendDeleteToServer?: boolean
  className?: string
}

export const DocumentCard = (props: DocumentCardProps) => {
  const { document, folder, sendDeleteToServer = true, sendEditToServer = true } = props

  const imagePreview = useDisclosure()

  const id = document?.id || folder?.id || ""
  const name = document?.file_name || folder?.name || ""

  const edit = useEditDocumentName(id, name, sendEditToServer!, props.onEditFileName)
  const allowEdit = !!((props.allowEdit && sendEditToServer) || props.onEditFileName)

  const deleteFile = useDeleteFile()
  const onDelete = useCallback(async () => {
    await props.onDelete?.(id)
    if (sendDeleteToServer) {
      await deleteFile(id)
    }
  }, [id, props.onDelete, deleteFile])
  const allowDelete = !!((props.allowDelete && sendDeleteToServer) || props.onDelete)

  const confirm = useConfirmModal({
    title: i18n.t("common:delete_token", { token: name }),
    content: i18n.t("common:messages.token_delete_confirmation", {
      token: i18n.t("assets:documents.fields.document", { count: 1 }),
    }),
    okText: i18n.t("common:delete"),
    cancelText: i18n.t("common:cancel"),
    onOk: onDelete,
  })

  const numberOfFolderSubItems =
    (folder?.files_aggregate.aggregate?.count ?? 0) +
    (folder?.sub_folders_aggregate?.aggregate?.count ?? 0)

  const externalUrl = useExternalDocumentUrl(document)
  if (!document && !folder) return null

  let onRowClick = undefined
  if (!edit.isEditing) {
    if (document && isImage(document.mime_type)) {
      onRowClick = imagePreview.onOpen
    } else if (folder && props.onOpenFileFolder) {
      onRowClick = () => props.onOpenFileFolder?.(folder.id)
    }
  }

  const uploadProgress = props.uploadProgress ?? 1
  const isUploading = uploadProgress < 1

  const rowProps = {
    className: "flex items-center flex-1 py-2 pl-3 overflow-hidden cursor-pointer",
    role: "button",
    children: (
      <>
        <DocumentCardIcon
          folder={!!folder}
          mimeType={document?.mime_type}
          thumbnailUrl={document?.thumbnail_url}
          url={document?.url!}
          previewVisible={imagePreview.isOpen}
          onPreviewVisibleChange={imagePreview.changeOpen}
        />
        {edit.isEditing ? (
          <>
            <TextInput
              ref={edit.inputRef}
              value={edit.editedName}
              onChange={edit.onChange}
              className="mx-2 inline-flex flex-1"
            />
            <Button
              size="small"
              type="tertiary"
              className="mr-1"
              icon={X}
              onClick={edit.stopEdit}
            />
            <Button
              type="tertiary"
              icon={Icons.Check}
              onMouseDown={(e) => e.stopPropagation()}
              onTouchStart={(e) => e.stopPropagation()}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                edit.saveEdit()
              }}
              size="small"
              style={{ marginRight: px(4) }}
            />
          </>
        ) : (
          <>
            <span className="ml-2 line-clamp-1 flex-1 whitespace-normal break-all text-sm text-gray-700 hover:text-gray-700">
              {name}
            </span>
            {folder && (
              <span className="px-3 text-sm text-gray-700">
                {numberOfFolderSubItems}{" "}
                {i18n.t("assets:documents.fields.document", {
                  count: numberOfFolderSubItems,
                })}
              </span>
            )}
            {isUploading ? <CircularProgress size={20} progress={uploadProgress} /> : null}
          </>
        )}
      </>
    ),
  }

  const linkToExternalOnClick =
    document && externalUrl && !folder && !isImage(document.mime_type) && !edit.isEditing

  const row = linkToExternalOnClick ? (
    <a href={externalUrl} target="_blank" rel="noreferrer" {...rowProps} />
  ) : (
    <div {...rowProps} onClick={onRowClick} />
  )

  return (
    <div className={classNames(props.className)}>
      <div className="box-border flex h-14 items-center rounded border border-gray-200 bg-white hover:bg-gray-50">
        {row}
        {!edit.isEditing && !isUploading && (
          <DocumentCardActionMenu
            downloadUrl={document && `${document.url}?download=1`}
            onEdit={allowEdit ? edit.startEdit : undefined}
            onDelete={allowDelete ? confirm.show : undefined}
          />
        )}
      </div>
      {allowDelete && confirm.component}
    </div>
  )
}

export default DocumentCard
