import { Flex, Inset, VStack } from "@components/layout"
import { ActionMenu, Button, Icons, TimeAgo } from "@components/shared"
import { IUserInfo, UserAvatar } from "@components/shared/avatar"
import HorizontalPills from "@components/shared/horizontal-pills"
import { RightClickMenu } from "@components/shared/right-click-menu"
import { StaticRichText } from "@components/shared/static-rich-text"
import { WorkOrderStatusTag } from "@components/work-order/work-order-status"
import {
  INotificationTypeEnum,
  IWorkOrderStatusEnum,
  NotificationPayload,
  uuid,
} from "@elara/db"
import {
  INotificationsSubscription,
  useDeleteReadNotificationsMutation,
  useMarkAllNotificationAsReadMutation,
  useMarkNotificationAsReadMutation,
  useMarkNotificationAsUnreadMutation,
} from "@graphql/documents/notification.generated"
import i18n from "@i18n"
import {
  DotsThreeVertical,
  EnvelopeSimple,
  EnvelopeSimpleOpen,
  Tabs,
  Timer,
  TrashSimple,
} from "@phosphor-icons/react"
import { cn } from "@utils"
import { formatDate } from "@utils/date"
import classNames from "classnames"
import React, { useCallback, useMemo, useState } from "react"
import { Trans } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Virtuoso } from "react-virtuoso"

type NotificationObject = {
  __typename: "notification"
  id: uuid
  read_at: string | null
  seen_at: string | null
  created_at: string
  work_order_id: uuid
} & NotificationPayload

const WorkOrderNotificationBody = (props: { notification: NotificationObject }) => {
  const { notification } = props

  switch (notification.type) {
    case INotificationTypeEnum.WorkOrderOverdueTomorrow:
      return (
        <span className="text-sm">
          {i18n.t("notifications:display.work_order_overdue_tomorrow", {
            context: notification.payload.workOrderDueTime ? "with_time" : undefined,
            dueTime: notification.payload.workOrderDueTime.slice(0, -3),
          })}
        </span>
      )
    case INotificationTypeEnum.WorkOrderCommented:
      return (
        <div className="">
          <span className="text-sm">
            {i18n.t("notifications:display.work_order_commented", {
              name: `${notification.payload.commentedBy.firstName} ${notification.payload.commentedBy.lastName}`,
            })}
          </span>
          <StaticRichText
            content={notification.payload.comment}
            className="line-clamp-2 max-h-10 text-sm opacity-75"
          />
        </div>
      )
    case INotificationTypeEnum.WorkOrderAssignedUser:
      return (
        <span className="text-sm">
          {i18n.t("notifications:display.work_order_assigned_user", {
            name: `${notification.payload.assignedBy.firstName} ${notification.payload.assignedBy.lastName}`,
          })}
        </span>
      )
    case INotificationTypeEnum.WorkOrderAssignedTeam:
      return (
        <span className="text-sm">
          {i18n.t("notifications:display.work_order_assigned_team", {
            name: `${notification.payload.assignedBy.firstName} ${notification.payload.assignedBy.lastName}`,
            teamName: notification.payload.teamName,
          })}
        </span>
      )
    case INotificationTypeEnum.WorkOrderDone:
      return (
        <span className="text-sm">
          <Trans
            i18n={i18n}
            i18nKey="notifications:display.work_order_status_change"
            values={{
              name: `${notification.payload.completedBy.firstName} ${notification.payload.completedBy.lastName}`,
            }}
            components={{
              status: <WorkOrderStatusTag status={IWorkOrderStatusEnum.Done} />,
            }}
          />
        </span>
      )
    case INotificationTypeEnum.WorkOrderCancelled:
      return (
        <span className="text-sm">
          <Trans
            i18n={i18n}
            i18nKey="notifications:display.work_order_status_change"
            values={{
              name: `${notification.payload.cancelledBy.firstName} ${notification.payload.cancelledBy.lastName}`,
            }}
            components={{
              status: (
                <WorkOrderStatusTag
                  iconSpacing={4}
                  status={IWorkOrderStatusEnum.Canceled}
                />
              ),
            }}
          />
        </span>
      )
    case INotificationTypeEnum.WorkOrderReadyForApproval:
      return (
        <span className="text-sm">
          <Trans
            i18n={i18n}
            i18nKey="notifications:display.work_order_ready_for_approval"
            components={{
              status: <WorkOrderStatusTag status={IWorkOrderStatusEnum.ReadyForApproval} />,
            }}
          />
        </span>
      )
    case INotificationTypeEnum.WorkOrderDueDateReminder:
      return (
        <span className="text-sm">
          {i18n.t("notifications:display.work_order_due_date_reminder", {
            context: notification.payload.workOrderDueTime ? "with_time" : undefined,
            dueTime: notification.payload.workOrderDueTime.slice(0, -3),
            dueDate: formatDate(new Date(notification.payload.workOrderDueDate), "P"),
          })}
        </span>
      )
    default:
      return null
  }
}

type NotificationType = "work-order" | "consumable"

type NotificationsListRowProps = {
  isActive: boolean
  notification: NotificationObject
  markNotificationAsRead: (notificationId: uuid) => void
  markNotificationAsUnread: (notificationId: uuid) => void
  deleteNotifications: (notificationId: uuid) => void
  onRowClick: (
    type: NotificationType,
    itemId: uuid,
    notificationId: uuid,
    isRead: boolean
  ) => void
  onRowClickNewTab: (
    type: NotificationType,
    itemId: uuid,
    notificationId: uuid,
    isRead: boolean
  ) => void
}

const UnreadIndicator = (props: JSX.IntrinsicElements["div"]) => (
  <div
    {...props}
    className={classNames(
      "absolute bottom-0 right-0",
      "border-solid border-b-[16px] border-l-[16px] border-b-blue-500 border-l-transparent"
    )}
  />
)

const NotificationAvatar = (props: { notification: NotificationPayload }) => {
  const { notification } = props

  switch (notification.type) {
    case INotificationTypeEnum.WorkOrderAssignedUser:
    case INotificationTypeEnum.WorkOrderAssignedTeam:
      const { assignedBy } = notification.payload
      return (
        <UserAvatar
          size="small"
          user={
            {
              first_name: assignedBy.firstName,
              last_name: assignedBy.lastName,
              avatar: { thumbnail_url: assignedBy.avatarUrl },
            } as IUserInfo
          }
        />
      )
    case INotificationTypeEnum.WorkOrderCommented:
      const { commentedBy } = notification.payload
      return (
        <UserAvatar
          size="small"
          user={
            {
              first_name: commentedBy.firstName,
              last_name: commentedBy.lastName,
              avatar: { thumbnail_url: commentedBy.avatarUrl },
            } as IUserInfo
          }
        />
      )
    case INotificationTypeEnum.WorkOrderDone:
      const { completedBy } = notification.payload
      return (
        <UserAvatar
          size="small"
          user={
            {
              first_name: completedBy.firstName,
              last_name: completedBy.lastName,
              avatar: { thumbnail_url: completedBy.avatarUrl },
            } as IUserInfo
          }
        />
      )
    case INotificationTypeEnum.WorkOrderCancelled:
      const { cancelledBy } = notification.payload
      return (
        <UserAvatar
          size="small"
          user={
            {
              first_name: cancelledBy.firstName,
              last_name: cancelledBy.lastName,
              avatar: { thumbnail_url: cancelledBy.avatarUrl },
            } as IUserInfo
          }
        />
      )
    case INotificationTypeEnum.WorkOrderOverdueTomorrow:
    case INotificationTypeEnum.WorkOrderDueDateReminder:
      return <Timer size={20} className="text-red-600" />
    case INotificationTypeEnum.WorkOrderReadyForApproval:
      return <Icons.ProgressReady height={20} width={20} className="text-teal-500" />
    default:
      return null
  }
}

const NotificationsListRow = ({
  isActive = false,
  notification,
  markNotificationAsRead,
  markNotificationAsUnread,
  deleteNotifications,
  onRowClick,
  onRowClickNewTab,
}: NotificationsListRowProps) => {
  if (notification.type === INotificationTypeEnum.ConsumableLowStock) {
    const { id, payload, read_at, created_at } = notification
    const { consumable } = payload

    return (
      <div
        onClick={() => onRowClick("consumable", consumable.id, id, !!read_at)}
        className={cn(
          "relative space-x-2 py-3 px-2 cursor-pointer hover:bg-gray-50 border-l-2 border-transparent border-t border-t-gray-100",
          {
            "bg-blue-50": isActive,
          }
        )}>
        <div className="flex flex-col gap-1">
          <div className="flex justify-between text-sm text-gray-500">
            <TimeAgo date={new Date(created_at)} />
          </div>
          <h3 className={classNames("text-sm line-clamp-2", { "font-semibold": !read_at })}>
            {i18n.t("notifications:display.consumable_low_stock", {
              name: consumable.name,
            })}
          </h3>
        </div>

        {read_at === null ? (
          <UnreadIndicator
            onClick={(e) => {
              e.stopPropagation()
              markNotificationAsRead(id)
            }}
          />
        ) : null}
      </div>
    )
  }

  if (notification.type === INotificationTypeEnum.UserAddedToAPrivateTeam) {
    return null
  }

  const { id, payload, read_at, created_at } = notification
  const { title, workOrderId, workOrderNumber } = payload

  const rightClickMenuItems = [
    {
      type: "label",
      key: "workordernumber",
      label: `#${workOrderNumber}`,
      separator: true,
    },
    {
      type: "item",
      key: "newtab",
      icon: <Tabs />,
      label: i18n.t("notifications:context_menu.open_in_new_tab"),
      action: () => {
        onRowClickNewTab("work-order", workOrderId, id, !!read_at)
      },
    },
    {
      type: "item",
      key: "markasread",
      icon: <EnvelopeSimpleOpen />,
      label: i18n.t("notifications:context_menu.mark_as_read"),
      action: () => {
        markNotificationAsRead(id)
      },
      disabled: read_at === null ? false : true,
    },
    {
      type: "item",
      key: "markasunread",
      icon: <EnvelopeSimple />,
      label: i18n.t("notifications:context_menu.mark_as_unread"),
      action: () => {
        markNotificationAsUnread(id)
      },
      disabled: read_at === null ? true : false,
    },
    {
      type: "item",
      key: "delete",
      icon: <TrashSimple />,
      label: i18n.t("common:delete"),
      action: () => {
        deleteNotifications(id)
      },
    },
  ]

  if (!rightClickMenuItems.filter(Boolean).length) return null

  return (
    <RightClickMenu items={rightClickMenuItems}>
      <div
        onClick={() => onRowClick("work-order", workOrderId, id, !!read_at)}
        className={cn(
          "relative space-x-2 py-3 px-2 cursor-pointer hover:bg-gray-50 border-l-2 border-transparent border-t border-t-gray-100",
          {
            "bg-blue-50": isActive,
          }
        )}>
        <div>
          <div className="mb-1 flex justify-between text-sm text-gray-500">
            <span>#{workOrderNumber}</span>
            <TimeAgo date={new Date(created_at)} />
          </div>
          <Flex row justify="min-w-0">
            <h3
              className={classNames("text-sm line-clamp-2", { "font-semibold": !read_at })}>
              {title}
            </h3>
          </Flex>
        </div>

        <Flex row align="center">
          <Inset right={8}>
            <NotificationAvatar notification={notification} />
          </Inset>
          <div>
            <WorkOrderNotificationBody notification={notification} />
          </div>
        </Flex>

        {read_at === null ? (
          <UnreadIndicator
            onClick={(e) => {
              e.stopPropagation()
              markNotificationAsRead(id)
            }}
          />
        ) : null}
      </div>
    </RightClickMenu>
  )
}

type NotificationsProps = {
  notifications: INotificationsSubscription["notification"] | undefined
  toggleDialog?: () => void
}

export const NotificationsList = ({
  notifications,
  selectedTab,
  markAsRead,
  toggleDialog,
  markAsUnread,
  activeNotification,
  selectNotification,
  onDeleteNotification,
}: NotificationsProps & {
  selectedTab: "all" | "unread"
  activeNotification?: uuid | null
  markAsRead: (ids: uuid[]) => void
  markAsUnread: (ids: uuid[]) => void
  onDeleteNotification: (id: uuid) => void
  selectNotification?: (id: uuid, type: NotificationType, itemId: uuid) => void
}) => {
  const shownNotifications =
    selectedTab === "all"
      ? notifications ?? []
      : notifications?.filter((notification) => !notification.read_at) ?? []

  const navigate = useNavigate()

  const markNotificationAsRead = useCallback(
    (notificationId: uuid) => {
      markAsRead([notificationId])
    },
    [notifications]
  )

  const markNotificationAsUnread = useCallback(
    (notificationId: uuid) => {
      markAsUnread([notificationId])
    },
    [notifications]
  )

  const onRowClick = useCallback(
    (type: NotificationType, itemId: uuid, notificationId: uuid, isRead: boolean) => {
      if (selectNotification) {
        selectNotification(notificationId, type, itemId)
      } else {
        navigate(`/${type}/${itemId}`)
      }

      toggleDialog && toggleDialog()

      if (!isRead) {
        markNotificationAsRead(notificationId)
      }
    },
    [notifications, toggleDialog, markAsRead]
  )

  const onRowClickNewTab = useCallback(
    (type: NotificationType, itemId: uuid, notificationId: uuid, isRead: boolean) => {
      window.open(`/${type}/${itemId}`, "_blank")

      toggleDialog && toggleDialog()

      if (!isRead) {
        markNotificationAsRead(notificationId)
      }
    },
    [notifications, toggleDialog, markAsRead]
  )

  if (!notifications) return null

  if (!notifications.length)
    return (
      <div className="mx-auto my-3 text-sm text-gray-500">
        {i18n.t("notifications:messages.no_notifications")}
      </div>
    )

  return (
    <Virtuoso
      data={shownNotifications}
      className="!mt-0 h-full flex-1 overflow-x-hidden"
      itemContent={(_, notification) => (
        <NotificationsListRow
          notification={notification as NotificationObject}
          onRowClick={onRowClick}
          onRowClickNewTab={onRowClickNewTab}
          markNotificationAsRead={markNotificationAsRead}
          markNotificationAsUnread={markNotificationAsUnread}
          isActive={activeNotification === notification.id}
          deleteNotifications={onDeleteNotification}
        />
      )}
    />
  )
}

export const NotificationsDialogContent = React.forwardRef<
  HTMLDivElement,
  NotificationsProps & {
    activeNotification?: uuid | null
    selectNotification?: (id: uuid, type: NotificationType, itemId: uuid) => void
    onDeleteNotification: (notificationId: uuid) => void
    onDeleteAllNotification?: () => void
  }
>((props, forwardedRef) => {
  const { notifications, toggleDialog, activeNotification, selectNotification } = props
  const [selectedTab, setSelectedTab] = useState<"all" | "unread">("all")
  const [, markAllAsRead] = useMarkAllNotificationAsReadMutation()
  const [, markAsReadMutation] = useMarkNotificationAsReadMutation()
  const [, markAsUnreadMutation] = useMarkNotificationAsUnreadMutation()
  const [, deleteReadNotifications] = useDeleteReadNotificationsMutation()

  const markAsRead = useCallback(
    (ids: uuid[]) => markAsReadMutation({ ids, now: new Date().toISOString() }),
    [notifications, markAsReadMutation]
  )

  const markAsUnread = useCallback(
    (ids: uuid[]) => markAsUnreadMutation({ ids }),
    [notifications, markAsUnreadMutation]
  )

  const allRead = useMemo(
    () => !!notifications?.every((notification) => notification.read_at),
    [notifications]
  )
  const hasSomeRead = !!notifications?.some((n) => n.read_at)

  return (
    <VStack space={20} ref={forwardedRef} flex="1">
      <Flex justify="space-between" row className="border-b border-gray-200 px-3 py-2">
        <div className="flex items-baseline">
          <HorizontalPills.Root
            value={selectedTab}
            onValueChange={(v) => v && setSelectedTab(v as "all" | "unread")}>
            <HorizontalPills.List>
              <HorizontalPills.Trigger value="all">
                {i18n.t("notifications:tabs.all")}
              </HorizontalPills.Trigger>

              <HorizontalPills.Trigger value="unread">
                {i18n.t("notifications:tabs.unread")}
              </HorizontalPills.Trigger>
            </HorizontalPills.List>
          </HorizontalPills.Root>
        </div>

        <ActionMenu
          items={[
            {
              key: "markAllAsRead",
              label: i18n.t("notifications:actions.mark_all_as_read"),
              disabled: allRead,
              icon: <EnvelopeSimpleOpen />,
              action: () => markAllAsRead({ now: new Date().toISOString() }),
            },
            {
              key: "deleteAllRead",
              label: i18n.t("notifications:actions.delete_all_read"),
              disabled: !hasSomeRead,
              icon: <TrashSimple />,
              action: async () => {
                await deleteReadNotifications({})
                props.onDeleteAllNotification?.()
              },
            },
          ]}>
          <Button type="tertiary" color="gray" size="small" icon={DotsThreeVertical} />
        </ActionMenu>
      </Flex>

      <NotificationsList
        notifications={notifications}
        selectedTab={selectedTab}
        markAsRead={markAsRead}
        markAsUnread={markAsUnread}
        onDeleteNotification={props.onDeleteNotification}
        toggleDialog={toggleDialog}
        selectNotification={selectNotification}
        activeNotification={activeNotification}
      />
    </VStack>
  )
})
