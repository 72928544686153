import React from "react"
import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  Path,
  useController,
} from "react-hook-form"

type FieldErrorConfig = {
  touched?: boolean | Record<string, boolean>
  error: string | Record<string, string> | undefined
}

type FormFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = Path<TFieldValues>
> = ControllerProps<TFieldValues, TName> & {
  className?: string
  hint?: string | React.ReactNode
  label?: string
  name: string
  hasErrorPlaceholder?: boolean
  optional?: boolean
  noStyle?: boolean
  errorConfig?: FieldErrorConfig
}

const checkErrorFromConfig = ({ error, touched }: FieldErrorConfig) => {
  if (!error) return false

  // touched is a boolean
  if (touched === undefined || touched === true) return true

  // touched is an object
  // every field must have a value of true
  if (typeof touched === "object" && Object.values(touched).filter(Boolean).length) {
    return true
  }

  return false
}

const formatError = (error: string | Record<string, string> | undefined): string =>
  typeof error === "string" ? error : Object.values(error ?? {})[0]

export const FormFieldController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = Path<TFieldValues>
>({
  hasErrorPlaceholder = true,
  hint,
  label,
  optional,
  className,
  noStyle,
  errorConfig,
  ...controllerProps
}: FormFieldProps<TFieldValues, TName>) => {
  const { fieldState } = useController({
    name: controllerProps.name,
    control: controllerProps.control,
  })

  if (noStyle) {
    return <Controller {...(controllerProps as any)} />
  }

  const showError = errorConfig
    ? checkErrorFromConfig(errorConfig)
    : !!fieldState.error && (fieldState.isTouched || fieldState.isDirty)
  const showHint = !!hint && !showError

  return (
    <div className={className}>
      {label && (
        <div className="mb-1 flex items-baseline justify-between text-sm">
          <label className="font-medium text-gray-700" htmlFor={controllerProps.name}>
            {label}
          </label>
          {optional && <span className="text-xs text-gray-500">Optional</span>}
        </div>
      )}

      <div className="flex flex-col">
        <Controller {...(controllerProps as any)} />
        {showError && (
          <span className="pt-1 text-xs text-red-600">
            {errorConfig
              ? formatError(errorConfig.error)
              : formatError(fieldState.error?.message)}
          </span>
        )}
        {showHint && <span className="pt-1 text-xs text-gray-600">{hint}</span>}
        {!showHint && !showError && hasErrorPlaceholder && <span className="h-5 w-full" />}
      </div>
    </div>
  )
}
