import { cn } from "@utils"
import React from "react"

export type ViewProps = {
  scrollY?: boolean
  ellipsis?: boolean
} & React.HTMLAttributes<HTMLDivElement>

export const Box = React.forwardRef<HTMLDivElement, ViewProps>(
  ({ children, scrollY, ellipsis, ...otherProps }, ref) => (
    <div
      {...otherProps}
      className={cn(
        "relative flex flex-col overflow-hidden",
        {
          truncate: ellipsis,
          "overflow-y-auto overscroll-y-contain": scrollY,
        },
        otherProps.className
      )}
      ref={ref as any}>
      {children}
    </div>
  )
)

export default Box
