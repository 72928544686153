import { cn } from "@utils"
import React from "react"

export const SectionHeader = (props: React.PropsWithChildren<{ className?: string }>) => (
  <h2
    className={cn(
      "text-base font-medium leading-none text-gray-800 @mobile/page:text-lg",
      props.className
    )}>
    {props.children}
  </h2>
)
