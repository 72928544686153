import { Button } from "@components/shared"
import { ToggleGroup, ToggleGroupItem } from "@components/shared/toggle-group"
import i18n from "@i18n"
import { DownloadSimple } from "@phosphor-icons/react"
import QR from "qrcode"
import { PropsWithChildren, useLayoutEffect, useRef, useState } from "react"

const logo = btoa(
  `<svg viewBox="0 0 244 44" width="244" height="44" fill="#000000" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M233.328 43.0873H243.746L225.471 0.510681H215.683L197.448 43.0898H207.616L211.398 34.0295H229.549L233.328 43.0873ZM214.589 26.4758L220.555 12.2128L226.478 26.4758H214.589Z" />
  <path
    d="M160.442 43.0873V31.2158H169.086L176.69 43.0873H187.111L177.783 29.3704C180.558 28.1544 182.699 26.4328 184.213 24.1677C185.767 21.8597 186.524 19.1345 186.524 15.9467C186.524 12.7993 185.767 10.0311 184.253 7.72561C182.742 5.41754 180.598 3.61508 177.783 2.35613C175.012 1.09719 171.734 0.467712 167.952 0.467712H150.748V43.0469L160.442 43.0873ZM160.442 8.52193H167.406C170.39 8.52193 172.615 9.1514 174.169 10.4508C175.723 11.7097 176.48 13.5122 176.48 15.9037C176.48 18.2522 175.723 20.0977 174.169 21.3996C172.615 22.699 170.347 23.3284 167.406 23.3284H160.442V8.52193Z" />
  <path
    d="M126.765 43.0873H137.186L118.908 0.510681H109.12L90.8853 43.0898H101.053L104.835 34.0295H122.986L126.765 43.0873ZM108.026 26.4758L113.993 12.2128L119.916 26.4758H108.026Z" />
  <path d="M59.8705 0H49.9963V43.0873H81.7173V35.0331H59.8705V0Z" />
  <path d="M32.6878 0.510681H0V9.23735H32.6878V0.510681Z" />
  <path d="M8.74021 25.7629H27.018V17.0388H0V43.1733H32.6878V34.4466H8.74021V25.7629Z" />
</svg>`
)

const mappin = btoa(
  `<svg xmlns="http://www.w3.org/2000/svg" width="192" height="192" fill="#000000" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M128,16a88.1,88.1,0,0,0-88,88c0,75.3,80,132.2,83.4,134.6a8.3,8.3,0,0,0,9.2,0C136,236.2,216,179.3,216,104A88.1,88.1,0,0,0,128,16Zm0,56a32,32,0,1,1-32,32A32,32,0,0,1,128,72Z"></path></svg>`
)
// @description: wrapText wraps HTML canvas text onto a canvas of fixed width
// @param ctx - the context for the canvas we want to wrap text on
// @param text - the text we want to wrap.
// @param x - the X starting point of the text on the canvas.
// @param y - the Y starting point of the text on the canvas.
// @param maxWidth - the width at which we want line breaks to begin - i.e. the maximum width of the canvas.
// @param lineHeight - the height of each line, so we can space them below each other.
// @returns an array of [ lineText, x, y ] for all lines
const wrapText = function (
  ctx: CanvasRenderingContext2D,
  text: string,
  x: number,
  y: number,
  maxWidth: number,
  lineHeight: number
) {
  // First, start by splitting all of our text into words, but splitting it into an array split by spaces
  let words = text.split(" ")
  let line = "" // This will store the text of the current line
  let testLine = "" // This will store the text when we add a word, to test if it's too long
  let lineArray: [string, number, number][] = [] // This is an array of lines, which the function will return

  // Lets iterate over each word
  for (var n = 0; n < words.length; n++) {
    // Create a test line, and measure it..
    testLine += `${words[n]} `
    let metrics = ctx.measureText(testLine)
    let testWidth = metrics.width
    // If the width of this test line is more than the max width
    if (testWidth > maxWidth && n > 0) {
      // Then the line is finished, push the current line into "lineArray"
      lineArray.push([line, x, y])
      // Increase the line height, so a new line is started
      y += lineHeight
      // Update line and test line to use this word as the first word on the next line
      line = `${words[n]} `
      testLine = `${words[n]} `
    } else {
      // If the test line is still less than the max width, then add the word to the current line
      line += `${words[n]} `
    }
    // If we never reach the full max width, then there is only one line.. so push it into the lineArray so we return something
    if (n === words.length - 1) {
      lineArray.push([line, x, y])
    }
  }
  // Return the line array
  return lineArray
}

export async function drawRectangularQRCode(params: {
  ctx: CanvasRenderingContext2D
  qrCodeUrl: string
  pretitle?: string
  title: string
  identifier: string
  location: string
  height: number
  offset?: { x: number; y: number }
  ImageConstructor?: typeof Image
}) {
  const height = 240
  const width = 480
  const scale = params.height / 240
  const { ctx } = params
  const Image = params.ImageConstructor || window.Image

  ctx.save()

  ctx.translate(params.offset?.x || 0, params.offset?.y || 0)
  // Normalize coordinate system to use CSS pixels.
  ctx.scale(scale, scale)

  ctx.save()
  ctx.fillStyle = "#fff"
  ctx.fillRect(0, 0, width, height)

  await new Promise<void>((resolve) => {
    ctx.fillStyle = "#000"
    ctx.translate(20, 60)
    ctx.scale(0.25, 0.25)
    QR.toDataURL(
      params.qrCodeUrl,
      { scale: 2, width: 160 * 4, margin: 0, errorCorrectionLevel: "H" },
      (error, dataURL) => {
        if (error) console.error(error)
        const img = new Image()
        img.onload = function () {
          ctx.drawImage(img, 0, 0)
          resolve()
        }
        img.src = dataURL
      }
    )
  })

  ctx.restore()

  await new Promise<void>((resolve) => {
    const img = new Image()

    img.onload = function () {
      ctx.drawImage(img, 20, 20, 122, 22)
      resolve()
    }
    img.src = "data:image/svg+xml;base64," + logo
  })

  ctx.textAlign = "start"

  let cursorY = 32

  if (params.pretitle) {
    ctx.fillStyle = "#444"
    ctx.font = "normal 400 18px 'Inter'"
    const wrappedPreTitle = wrapText(ctx, params.pretitle, 200, cursorY, 260, 20)
    wrappedPreTitle.forEach(([line, x, y]) => {
      ctx.fillText(line, x, y)
      cursorY = y + 14
    })
  }

  ctx.fillStyle = "#000"
  ctx.font = "normal 600 20px 'Inter'"
  const wrappedTitle = wrapText(ctx, params.title, 200, cursorY + 14, 260, 20)
  wrappedTitle.forEach(([line, x, y]) => {
    ctx.fillText(line, x, y)
    cursorY = y + 14
  })

  ctx.font = "normal 400 20px 'Inter'"
  ctx.textAlign = "start"
  ctx.fillStyle = "#0a0a0a"
  const wrappedSubtitle = wrapText(ctx, params.identifier || "", 200, cursorY, 260, 20)

  wrappedSubtitle.forEach(([line, x, y]) => {
    ctx.fillText(line, x, y)
    cursorY = y + 14
  })

  if (params.location) {
    ctx.font = "normal 400 16px 'Inter'"
    ctx.textAlign = "start"
    ctx.fillStyle = "#0a0a0a"

    await new Promise<void>((resolve) => {
      const img = new Image()

      img.onload = function () {
        ctx.drawImage(img, 200, cursorY - 16, 16, 16)
        resolve()
      }
      img.src = "data:image/svg+xml;base64," + mappin
    })

    const wrappedLocation = wrapText(ctx, params.location, 220, cursorY, 260, 20)
    wrappedLocation.forEach(([line, x, y]) => {
      ctx.fillText(line, x, y)
      cursorY = y
    })
  }

  ctx.restore()
}

export async function drawSquareQRCode(params: {
  ctx: CanvasRenderingContext2D
  qrCodeUrl: string
  identifier: string
  location: string
  height: number
  offset?: { x: number; y: number }
  ImageConstructor?: typeof Image
}) {
  const width = 240
  const height = 240
  const Image = params.ImageConstructor || window.Image

  const { ctx } = params
  const scale = params.height / width

  ctx.save()

  ctx.translate(params.offset?.x || 0, params.offset?.y || 0)

  ctx.scale(scale, scale)

  ctx.save()
  ctx.fillStyle = "#fff"
  ctx.fillRect(0, 0, width, height)

  await new Promise<void>((resolve) => {
    ctx.fillStyle = "#000"
    ctx.translate(40, 20)
    ctx.scale(0.25, 0.25)
    QR.toDataURL(
      params.qrCodeUrl,
      { scale: 2, width: 160 * 4, margin: 0 },
      (error, dataURL) => {
        if (error) console.error(error)
        const img = new Image()
        img.onload = function () {
          ctx.drawImage(img, 0, 0)
          resolve()
        }
        img.src = dataURL
      }
    )
  })

  ctx.restore()

  ctx.font = "normal 600 16px 'Inter'"
  ctx.textAlign = "center"
  ctx.fillStyle = "#0a0a0a"
  ctx.fillText(params.identifier || "", 120, 200)

  if (params.location) {
    ctx.font = "normal 400 12px 'Inter'"
    ctx.textAlign = "center"
    ctx.fillStyle = "#0a0a0a"
    const wrappedLocation = wrapText(ctx, params.location, 10, 220, 220, 12)
    wrappedLocation.forEach(([line, _x, y]) => {
      ctx.fillText(line, 120, y)
    })
  }

  ctx.restore()
}

export const QRCode = (
  props: PropsWithChildren<{
    location: string
    identifier: string
    pretitle?: string
    title: string
    relativeUrl: string
  }>
) => {
  const [variant, setVariant] = useState<"square" | "wide">("wide")

  const canvasRef = useRef<HTMLCanvasElement>(null)

  const renderedHeight = 156
  const renderedWidth = variant === "square" ? renderedHeight : 2 * renderedHeight
  let renderer = variant === "square" ? drawSquareQRCode : drawRectangularQRCode

  const draw = async (scale: number) => {
    const height = scale * renderedHeight
    const width = scale * renderedWidth
    const canvas = canvasRef.current
    const ctx = canvas?.getContext("2d")
    if (canvas && ctx) {
      canvas.height = height
      canvas.width = width

      let qrCodeUrl = window.location.origin + props.relativeUrl

      await renderer({
        ctx,
        height,
        qrCodeUrl,
        pretitle: props.pretitle,
        title: props.title,
        identifier: props.identifier,
        location: props.location,
      })
    }
  }

  useLayoutEffect(() => {
    const scale = window.devicePixelRatio || 2
    draw(scale)

    return () => {
      // cleanup
      canvasRef.current
        ?.getContext("2d")
        ?.clearRect(0, 0, 2 * scale * renderedHeight, 2 * scale * renderedHeight)
    }
  }, [variant, props.title, props.identifier, props.location, props.relativeUrl])

  const download = async () => {
    const canvas = canvasRef.current
    if (!canvas) return
    // redraw with higher resolution for download
    await draw(8)
    const data = canvas.toDataURL("image/png")
    draw(window.devicePixelRatio || 2)

    // generate and append fake url element to page to download the QR Code
    const link = document.createElement("a")
    if (typeof link.download === "string") {
      link.href = data
      link.download = `${props.identifier}.png`

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      window.open(data)
    }
  }

  return (
    <div className="flex flex-wrap gap-3">
      <div style={{ width: 2 * renderedHeight }} className="group">
        <div className="group relative inline-block">
          <canvas
            ref={canvasRef}
            className="mx-auto rounded-lg border"
            style={{ width: renderedWidth, height: renderedHeight }}
          />
          <div
            role={"button"}
            onClick={download}
            className="absolute inset-0 hidden items-center justify-center bg-white/30 font-medium text-gray-700 backdrop-blur-sm group-hover:flex">
            <DownloadSimple className="mr-2 h-5 w-5" />
            {i18n.t("download")}
          </div>
        </div>
      </div>
      <div>
        <div className="flex items-center gap-x-3">
          <span className=" text-sm font-medium text-gray-600">
            {i18n.t("common:format")}
          </span>
          <ToggleGroup
            type="single"
            value={variant}
            onValueChange={(v) => v && setVariant(v as "square" | "wide")}>
            <ToggleGroupItem value="square">
              {i18n.t("assets:qr_code.formats.compact")}
            </ToggleGroupItem>
            <ToggleGroupItem value="wide">
              {i18n.t("assets:qr_code.formats.large")}
            </ToggleGroupItem>
          </ToggleGroup>
          <Button type="secondary" onClick={download} icon={DownloadSimple}>
            {i18n.t("common:download")}
          </Button>
        </div>
        {props.children}
      </div>
    </div>
  )
}
