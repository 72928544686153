import { useCallback } from "react"

import {
  CalendarConfig,
  CustomDataConfig,
  DataViewConfiguration,
  DataViewLayoutType,
  FilterState,
  KanbanConfig,
} from "../data-view-types"

export type DataViewConfigAction<Id extends string> =
  | { type: "reset"; initialConfig: DataViewConfiguration<Id> }
  | { type: "updateColumnOrder"; columnOrder: Id[] }
  | { type: "updateGroupBy"; groupBy: Id | null }
  | { type: "updateFilters"; filters: FilterState[] }
  | { type: "updateKanban"; config: KanbanConfig }
  | { type: "updateCalendar"; config: CalendarConfig }
  | { type: "updateHistoryFilter"; historyFilter: FilterState }
  | { type: "updateShowSummation"; showSummation: boolean }
  | { type: "updateLayoutType"; layout: DataViewLayoutType }
  | { type: "updateIncludeSubitems"; includeSubitems: boolean }
  | { type: "updateCustomDataConfig"; config: CustomDataConfig }
  | { type: "updateOrderBy"; id: Id; dir?: "asc" | "desc"; defaultDir?: "asc" | "desc" }

function configurationReducer<Id extends string>(
  state: DataViewConfiguration<Id>,
  action: DataViewConfigAction<Id>
): DataViewConfiguration<Id> {
  switch (action.type) {
    case "reset":
      return action.initialConfig
    case "updateColumnOrder":
      return { ...state, columnOrder: action.columnOrder }
    case "updateGroupBy":
      return { ...state, groupBy: action.groupBy }
    case "updateShowSummation":
      return { ...state, showSummation: action.showSummation }
    case "updateFilters":
      return { ...state, filters: action.filters }
    case "updateKanban":
      return { ...state, kanbanConfig: action.config }
    case "updateCalendar":
      return { ...state, calendarConfig: action.config }
    case "updateHistoryFilter":
      return { ...state, historyFilter: action.historyFilter }
    case "updateLayoutType":
      return { ...state, layoutType: action.layout }
    case "updateIncludeSubitems":
      return { ...state, includeSubitems: action.includeSubitems }
    case "updateOrderBy":
      let { orderBy } = state
      const { id, dir, defaultDir } = action
      if (dir) {
        orderBy = [{ id, dir }]
      } else if (orderBy?.[0]?.id !== id) {
        orderBy = [{ id, dir: defaultDir ?? "asc" }]
      } else if (orderBy?.[0]?.dir === "asc") {
        orderBy = [{ id, dir: "desc" }]
      } else {
        orderBy = [{ id, dir: "asc" }]
      }
      return { ...state, orderBy }
    case "updateCustomDataConfig":
      return { ...state, customDataConfig: { ...state.customDataConfig, ...action.config } }
    default:
      return state
  }
}

export function useDataViewConfigActions<Id extends string>(
  changeConfig: (
    updater: (prev: DataViewConfiguration<Id>) => DataViewConfiguration<Id>
  ) => void
) {
  const dispatch = useCallback(
    (action: DataViewConfigAction<Id>) =>
      changeConfig((state) => configurationReducer(state, action)),
    []
  )
  const updateOrderBy = useCallback(
    (id: Id, dir?: "asc" | "desc") => dispatch({ type: "updateOrderBy", id, dir }),
    []
  )

  const updateGroupBy = useCallback(
    (groupBy: Id | null) => dispatch({ type: "updateGroupBy", groupBy }),
    []
  )

  const updateColumnOrder = useCallback(
    (columnOrder: Id[]) => dispatch({ type: "updateColumnOrder", columnOrder }),
    []
  )

  const updateFilters = useCallback(
    (filters: FilterState[]) => dispatch({ type: "updateFilters", filters }),
    []
  )

  const updateKanban = useCallback(
    (config: KanbanConfig) => dispatch({ type: "updateKanban", config }),
    []
  )

  const updateCalendar = useCallback(
    (config: CalendarConfig) => dispatch({ type: "updateCalendar", config }),
    []
  )

  const updateHistoryFilter = useCallback(
    (historyFilter: FilterState) =>
      dispatch({ type: "updateHistoryFilter", historyFilter }),
    []
  )
  const updateShowSummation = useCallback(
    (showSummation: boolean) => dispatch({ type: "updateShowSummation", showSummation }),
    []
  )
  const updateLayoutType = useCallback((layout: DataViewLayoutType) => {
    dispatch({ type: "updateLayoutType", layout })
  }, [])

  const updateCustomDataConfig = useCallback(
    (config: CustomDataConfig) => dispatch({ type: "updateCustomDataConfig", config }),
    []
  )

  const updateIncludeSubitems = useCallback(
    (includeSubitems: boolean) =>
      dispatch({ type: "updateIncludeSubitems", includeSubitems }),
    []
  )

  const actions = {
    updateOrderBy,
    updateGroupBy,
    updateColumnOrder,
    updateFilters,
    updateKanban,
    updateCalendar,
    updateHistoryFilter,
    updateShowSummation,
    updateLayoutType,
    updateCustomDataConfig,
    updateIncludeSubitems,
  }

  return actions
}
