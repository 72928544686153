import { MaintenanceTrigger } from "@elara/db"
import { IMaintenanceFragment } from "@graphql/documents/fragments.generated"
import React, { useLayoutEffect } from "react"
import { gql, useClient } from "urql"

import ScheduledEventItem from "./scheduled-event-item"

export const ScheduledEventItemById = (props: {
  id: string
  triggerId: string
  date: Date | null
}) => {
  const [data, setData] = React.useState<{
    maintenance: Pick<IMaintenanceFragment, "id" | "name" | "description" | "paused">
    trigger: MaintenanceTrigger
  } | null>(null)
  const client = useClient()
  useLayoutEffect(() => {
    const res = client.readQuery(
      gql`
        query Fetch($id: uuid!, $triggerId: uuid!) {
          maintenance_by_pk(id: $id) {
            id
            name
            description
            paused
          }
          maintenance_trigger_by_pk(id: $triggerId) {
            id
            type
            payload
          }
        }
      `,
      { id: props.id, triggerId: props.triggerId }
    )

    if (res?.data?.maintenance_by_pk && res.data?.maintenance_trigger_by_pk) {
      setData({
        maintenance: res.data.maintenance_by_pk,
        trigger: res.data.maintenance_trigger_by_pk,
      })
    }
  }, [props.id])

  if (!data) return <span>{props.id}</span>

  return (
    <ScheduledEventItem
      maintenance={data.maintenance}
      trigger={data.trigger}
      date={props.date}
    />
  )
}
