import { Data, Where } from "@elara/select"
import { useCallbackRef } from "@hooks"
import { useEffect, useId, useState } from "react"
import useSWR, { unstable_serialize } from "swr"
import { AnyVariables, TypedDocumentNode, useClient } from "urql"
import useDeepCompareEffect from "use-deep-compare-effect"

import { useDataViewConfigContext } from "./data-view-config"
import { useDataViewGroupByContext } from "./data-view-group-by"
import { useDataViewSortContext } from "./data-view-sort"
import { useDataViewTreeContext } from "./data-view-tree-toggle"
import { useDataViewRefreshData, useDataViewWhere } from "./lib/hooks"
import type { ChunkMeta } from "./lib/row-mapper"
import { createRowMapper, getChunkMeta, LookUpItem } from "./lib/row-mapper"
import { andWhere } from "./lib/utils"

function useForceRerender() {
  const [, setTick] = useState(0)
  const id = useId()
  useEffect(() => {
    const f = () => setTick((t) => t + 1)
    window.addEventListener(`force-rerender:${id}`, f)
    return () => {
      window.removeEventListener(`force-rerender:${id}`, f)
    }
  }, [id])

  const tick = useCallbackRef(() => {
    queueMicrotask(() => {
      window.dispatchEvent(new Event(`force-rerender:${id}`))
    })
  })
  return tick
}

const INVALIDATE_GROUP_BY_SUMMARY_EVENT = "INVALIDATE_GROUP_BY_SUMMARY_EVENT"

export function invalidateGroupBySummary() {
  window.dispatchEvent(new CustomEvent(INVALIDATE_GROUP_BY_SUMMARY_EVENT))
}

export type Chunk = {
  meta: ChunkMeta
  data: null | {
    rows: Data[]
  }
}

function useGroupBySummary<D extends Data>(where: Where<D>) {
  const ctx = useDataViewConfigContext()
  const client = useClient()

  const groupByColumn = ctx.columns.find((c) => c.id === ctx.config.groupBy)

  const fetcher = useCallbackRef(() => {
    return groupByColumn!.groupBySummary!(client, where)
  })

  const groupByQuery = useSWR(
    (groupByColumn?.groupBySummary ?? null) &&
      unstable_serialize(["groupBy", ctx.config.groupBy, where]),
    fetcher,
    { keepPreviousData: true, revalidateOnFocus: false }
  )

  useDataViewRefreshData(() => {
    groupByQuery.mutate()
  })

  useEffect(() => {
    const listener = () => groupByQuery.mutate()
    window.addEventListener(INVALIDATE_GROUP_BY_SUMMARY_EVENT, listener)
    return () => {
      window.removeEventListener(INVALIDATE_GROUP_BY_SUMMARY_EVENT, listener)
    }
  }, [groupByQuery.mutate])

  return groupByQuery.data ?? null
}

type UseDataViewChunkedFetcherArgs<D extends Data, R, V extends AnyVariables> = {
  chunkSize: number
  where?: Where<D>
  numberOfRows: (where: Where<D>) => Promise<number>
  query: TypedDocumentNode<R, V>
  getData: (data: R | undefined) => D[]
}

export function useDataViewChunkedFetcher<D extends Data, R, V extends AnyVariables>(
  props: UseDataViewChunkedFetcherArgs<D, R, V>
) {
  const groupBy = useDataViewGroupByContext()
  const sort = useDataViewSortContext()

  const tree = useDataViewTreeContext()
  const client = useClient()

  const where = useDataViewWhere<D>(props.where)

  let groupBySummary = useGroupBySummary<D>(where)

  const fetchNumberOfRows = useCallbackRef(() => props.numberOfRows(where))
  const numberOfRowsQuery = useSWR(
    groupBySummary ? null : ["numberOfRows", where],
    fetchNumberOfRows
  )
  useDataViewRefreshData(() => {
    numberOfRowsQuery.mutate()
  })

  const update = useForceRerender()
  // Chunks and mountedChunks are modified in place and rerenders are triggered manually
  // This is to avoid unnecessary rerenders

  // Chunks are the chunks that have been requested
  const [chunks, setChunks] = useState(new Map<string, Chunk>())
  // Mounted chunks are the chunks for which currently at least one row is mounted = rendered
  // We use this to have better control of when to request data.
  // The main use case is the following: Assume you have a long list, and a user scrubbs through it
  // quickly towards the end, glossing over most rows.
  // Naively we would request all chunks that are visible on the screen even for a tiny split second.
  // This would result in a lot of requests that are not necessary.
  // Using mountedChunks we can avoid this by only requesting chunks that are actually rendered.
  // Coupled together with a slight delay (e.g. 100ms) before we start fetching data, we can avoid
  // unnecessary requests.
  const [mountedChunks, setMountedChunks] = useState(new Map<string, Set<number>>())

  const key = unstable_serialize([props.where, groupBy.groupBy, sort.orderByStatements])
  useEffect(() => {
    setChunks(new Map())
    setMountedChunks(new Map())
  }, [key])

  // Marks a row as mounted
  const mountItem = useCallbackRef((chunkKey: string, lookUpItem: LookUpItem) => {
    if (lookUpItem.type === "group") return

    if (!mountedChunks.has(chunkKey)) {
      mountedChunks.set(chunkKey, new Set())
    }
    const chunk = mountedChunks.get(chunkKey)
    const beforeSize = chunk?.size ?? 0
    chunk?.add(lookUpItem.k)
    const afterSize = chunk?.size ?? 0
    if (beforeSize !== afterSize && afterSize === 1 && beforeSize === 0) {
      update()
    }
  })

  // Marks a row as unmounted
  const unmountItem = useCallbackRef((chunkKey: string, lookUpItem: LookUpItem) => {
    if (lookUpItem.type === "group") return
    if (mountedChunks.has(chunkKey)) {
      const chunk = mountedChunks.get(chunkKey)
      const beforeSize = chunk?.size ?? 0
      chunk?.delete(lookUpItem.k)
      const afterSize = chunk?.size ?? 0
      if (beforeSize !== afterSize && afterSize === 0) {
        mountedChunks.delete(chunkKey)
        update()
      }
    }
  })

  // This does not register the row, but rather returns the row if it is already registered
  // otherwise it returns null
  const __internal_getRow = (lookUpItem: LookUpItem) => {
    if (lookUpItem.type === "group") return null
    const chunkMeta = getChunkMeta({
      lookUpItem,
      chunkSize: props.chunkSize,
      childrenQuery: tree?.childrenQuery as any,
    })

    if (!chunkMeta) return null

    if (chunks.has(chunkMeta.key)) {
      const chunk = chunks.get(chunkMeta.key)
      if (!chunk?.data) return null

      const row = chunk.data.rows[lookUpItem.k % props.chunkSize]

      if (row) {
        return row
      }
    }
    return null
  }

  const rowMapper = createRowMapper({
    getRow: __internal_getRow,
    groupBySummary,
    numberOfRows: numberOfRowsQuery.data ?? 0,
    isGroupCollapsed: groupBy.isGroupCollapsed,
    numberOfChildren: tree?.numberOfChildren,
    showChildren: tree ? (d) => tree.isExpanded(tree.getId(d)) : undefined,
  })

  const getRow = (lookUpItem: LookUpItem) => {
    if (lookUpItem.type === "group") return null
    const chunkMeta = getChunkMeta({
      lookUpItem,
      chunkSize: props.chunkSize,
      childrenQuery: tree?.childrenQuery as any,
    })

    if (!chunkMeta) return null

    if (chunks.has(chunkMeta.key)) {
      mountItem(chunkMeta.key, lookUpItem)
      const chunk = chunks.get(chunkMeta.key)
      if (!chunk?.data) return null

      const row = chunk.data.rows[lookUpItem.k % props.chunkSize]

      if (row) {
        return row
      }
    } else {
      chunks.set(chunkMeta.key, { meta: chunkMeta, data: null })
      update()
    }
    return null
  }

  // Hook up logic to keep track of mounted chunks
  const useRegisterRow = (rowIdx: number) => {
    const lookUpItem = rowMapper.getItem(rowIdx)
    const chunkInfo = getChunkMeta({
      lookUpItem,
      chunkSize: props.chunkSize,
      childrenQuery: tree?.childrenQuery as undefined | ((d: Data) => Where<Data>),
    })
    useDeepCompareEffect(() => {
      if (chunkInfo) {
        mountItem(chunkInfo.key, lookUpItem)
        return () => {
          unmountItem(chunkInfo.key, lookUpItem)
        }
      }
    }, [[rowIdx, chunkInfo]])
  }

  const preload = async (meta: ChunkMeta) => {
    let queryWhere = meta.query.where as Where<D>
    if (!meta.query.subQuery) {
      queryWhere = andWhere<D>(queryWhere, where)
    }
    const variables = {
      where: queryWhere,
      orderBy: sort.orderByStatements,
      limit: meta.query.limit,
      offset: meta.query.offset || 0,
    } as unknown as V

    return client
      .query(props.query, variables)
      .toPromise()
      .then((r) => props.getData(r.data))
  }

  const preloadChildren = async (row: Data, level: number) => {
    const lookUpItem = {
      type: "child" as const,
      parentRow: row,
      k: 1,
      level: level + 1,
    }
    const chunk = getChunkMeta({
      lookUpItem,
      chunkSize: props.chunkSize,
      childrenQuery: tree?.childrenQuery,
    })

    if (chunk) {
      const rows = await preload(chunk)

      let d = chunks.get(chunk.key)
      if (!d) {
        chunks.set(chunk.key, { meta: chunk, data: null })
        d = chunks.get(chunk.key)!
      }
      d.data = { rows }
    }
  }

  return {
    chunks,
    getRow,
    update,
    mountedChunks,
    useRegisterRow,
    size: rowMapper.size,
    getItem: rowMapper.getItem,
    where,
    preloadChildren,
  }
}
