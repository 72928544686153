import { Center } from "@components/layout"
import { LoadingIndicator } from "@components/shared"
import {
  ICustomViewFragment,
  useCustomViewByIdQuery,
} from "@graphql/documents/custom-view.generated"
import { uuid } from "@graphql/scalars"
import { ReactElement } from "react"

export const DataViewCustomViewFetcher = (props: {
  customViewId: uuid
  render: (view: ICustomViewFragment) => ReactElement
}) => {
  const { customViewId } = props

  const [viewQueryRes] = useCustomViewByIdQuery({
    variables: { id: customViewId! },
    pause: !customViewId,
    requestPolicy: "cache-first",
  })

  if (viewQueryRes.fetching)
    return (
      <Center flex="1">
        <LoadingIndicator size={24} />
      </Center>
    )

  if (viewQueryRes.fetching || !customViewId)
    return (
      <Center flex="1">
        <LoadingIndicator size={24} />
      </Center>
    )

  const view = viewQueryRes?.data?.custom_view_by_pk
  if (!view) {
    return <div>Cannot find view with id {customViewId}</div>
  }

  return props.render(view)
}
