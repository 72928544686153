import { cn } from "@utils"
import { LicenseManager } from "ag-grid-enterprise"
import { AgGridReact } from "ag-grid-react"
import { ComponentPropsWithoutRef, LegacyRef } from "react"

type DataGridProps<T> = Omit<
  ComponentPropsWithoutRef<typeof AgGridReact<T>>,
  "dataTypeDefinitions"
> & {
  gridRef?: LegacyRef<AgGridReact<T>>
  wrapperClassName?: string
}

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY)

const DataGrid = <T,>({ gridRef, wrapperClassName, ...props }: DataGridProps<T>) => (
  <div className={cn("h-full ag-theme-quartz", wrapperClassName)}>
    <AgGridReact<T> ref={gridRef} {...props} />
  </div>
)

DataGrid.displayName = AgGridReact.name

export { DataGrid }
