import { Data } from "@elara/select"
import { IWorkOrderFragment } from "@graphql/documents/fragments.generated"
import { useDeleteWorkOrdersMutation } from "@graphql/documents/work-order.generated"
import { IPermissionScopeEnum, usePermissionScope, useStickyState } from "@hooks"
import i18n from "@i18n"
import { useCallback, useEffect, useRef, useState } from "react"

import { useConfirmModal } from "../confirm-modal"
import { Column } from "./data-view-types"

const clamp = (x: number, a: number, b: number) => Math.min(Math.max(x, a), b)

export function useColumn<D extends Data, Id extends string, Options extends {}>(options: {
  id: string
  toggleSortState: (id: Id) => void
}) {
  // Column resizing
  const columnRefs = useRef({} as Record<Id, HTMLTableCellElement | null>)
  const [columnWidths, setColumnWidths] = useStickyState(
    {} as Record<Id, number | null>,
    options.id + "_columnWidths"
  )
  const [activeResizeColumn, setActiveResizeColumn] = useState<Column<
    D,
    Id,
    Options
  > | null>(null)

  const getResizeHandlerProps = useCallback(
    (column: Column<D, Id, Options>) => {
      return {
        isActive: column.id === activeResizeColumn?.id,
        onPointerDown: () => {
          setActiveResizeColumn(column)
        },
      }
    },
    [activeResizeColumn]
  )

  const getColumnWidth = useCallback(
    (column: Column<D, Id, Options>, isLastCol: boolean) => {
      let width: string
      if (columnWidths[column.id]) {
        width = `${columnWidths[column.id]}px`
      } else if (column.defaultWidth) {
        width = `${column.defaultWidth}px`
        // }
        //  else if (column.maxWidth) {
        //   // It is not possible to nest minmax, therefore
        //   // we ignore maxWidth if `isLastCol`
        //   width = "120px"
        //   if (isLastCol) {
        //     width = `0px`
        //   } else {
        //     width = `minmax(0px, ${column.maxWidth}px)`
        //   }
      } else {
        // width = `min-content`
        width = "120px"
      }
      if (isLastCol) {
        width = `minmax(${width}, 1fr)`
      }

      return width
    },
    [columnWidths]
  )

  useEffect(() => {
    if (!activeResizeColumn) return

    const moveHandler = (e: PointerEvent) => {
      const ref = columnRefs.current[activeResizeColumn.id]
      if (!ref) return

      const boundingRect = ref.getBoundingClientRect()
      let width = clamp(
        e.clientX - boundingRect.x,
        activeResizeColumn.minWidth ?? 25,
        activeResizeColumn.maxWidth ?? Number.POSITIVE_INFINITY
      )
      if (width !== columnWidths[activeResizeColumn.id]) {
        setColumnWidths((widths) => ({ ...widths, [activeResizeColumn.id]: width }))
      }
    }
    const upHandler = () => setActiveResizeColumn(null)

    window.addEventListener("pointermove", moveHandler)
    window.addEventListener("pointerup", upHandler)
    return () => {
      window.removeEventListener("pointermove", moveHandler)
      window.removeEventListener("pointerup", upHandler)
    }
  }, [activeResizeColumn])

  const getHeaderProps = useCallback(
    (column: Column<D, Id, Options>) => {
      return {
        ref: (r: HTMLTableCellElement | null) => {
          columnRefs.current[column.id] = r
        },
        onClick: () => options.toggleSortState(column.id),
      }
    },
    [columnRefs, options.toggleSortState]
  )

  return { getHeaderProps, getResizeHandlerProps, getColumnWidth }
}

export function useDeleteSelectedItems<D extends Data>() {
  const workOrderDeleteScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderDelete)

  const [, deleteWorkOrders] = useDeleteWorkOrdersMutation()

  const onDelete = async (items: D[]) => {
    if (items[0].type === "work_order") {
      const workOrders = items as unknown as IWorkOrderFragment[]
      const workOrderIds = workOrders.map((wo) => wo.id)

      await deleteWorkOrders({ ids: workOrderIds }, workOrderDeleteScope.context())
    }
  }

  const deleteConfirmModal = useConfirmModal({
    title: i18n.t("data-view:dialogs.delete_multiple.title"),
    content: i18n.t("data-view:dialogs.delete_multiple.content"),
    okText: i18n.t("common:delete"),
    cancelText: i18n.t("common:cancel"),
    onOk: onDelete,
  })

  const onClick = (items: D[]) => deleteConfirmModal.show(items)

  return { modal: deleteConfirmModal.component, onDelete: onClick }
}
