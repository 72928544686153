import { DialogForm } from "@components/shared/dialog-form"
import { FormField } from "@components/shared/form/form-field"
import { TextInput } from "@components/shared/text-input"
import toast from "@components/shared/toast"
import { useEditPasswordMutation } from "@graphql/documents/user.generated"
import i18n from "@i18n"
import * as yup from "yup"

type ChangePasswordFormProps = {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
}

const Schema = yup.object().shape({
  new_password: yup
    .string()
    .min(8, i18n.t("settings:profile.change_password.error_min_length")),
  repeat_password: yup
    .string()
    .test(
      "passwords-match",
      i18n.t("settings:profile.change_password.confirm_password_not_match"),
      function (value) {
        return this.parent.new_password === value
      }
    ),
})

const ChangePasswordForm = (props: ChangePasswordFormProps) => {
  const [, editPassword] = useEditPasswordMutation()

  return (
    <DialogForm
      title={i18n.t("settings:profile.change_password.title")}
      {...props}
      formikConfig={{
        initialValues: {
          new_password: "",
          repeat_password: "",
        },
        validationSchema: Schema,
        onSubmit: async (values) => {
          const result = await editPassword({ newPassword: values.new_password })

          if (result.error) {
            if (result.error.message === "[GraphQL] Invalid password") {
              toast.error(i18n.t("settings:profile.change_password.invalid_password"))
            } else {
              toast.error(i18n.t("settings:profile.change_password.change_password_failed"))
            }
            throw result.error
          }
          toast.success(i18n.t("settings:profile.change_password.change_password_success"))
        },
      }}>
      {() => (
        <div className="flex flex-col space-y-3 py-3">
          <FormField
            name="new_password"
            label={i18n.t("settings:profile.change_password.new_password")}>
            <TextInput type="password" autoComplete="new-password" id="new-password" />
          </FormField>

          <FormField
            name="repeat_password"
            label={i18n.t("settings:profile.change_password.confirm_new_password")}>
            <TextInput type="password" autoComplete="new-password" />
          </FormField>
        </div>
      )}
    </DialogForm>
  )
}

export default ChangePasswordForm
