import { useEffect } from "react"

import useCallbackRef from "./use-callback-ref"

export function useKeyboardListener(cb: (e: KeyboardEvent) => void) {
  const memo = useCallbackRef(cb)
  useEffect(() => {
    window.addEventListener("keydown", memo)
    return () => window.removeEventListener("keydown", memo)
  }, [])
}
