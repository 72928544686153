import { IUserTagFragment } from "@graphql/documents/fragments.generated"
import { useUserSelectDataQuery } from "@graphql/documents/user.generated"
import i18n from "@i18n"
import { lexCompare, sortBy } from "@utils"
import { TreeLike } from "@utils/tree"
import classNames from "classnames"
import { useMemo } from "react"

import { UserAvatar } from "./avatar"
import { Item } from "./combobox-select"
import {
  MultiSelectPopover,
  MultiSelectPopoverProps,
  MultiSelectSummary,
} from "./multi-select"
import { SelectPopover, SelectPopoverProps } from "./single-select"

const always = () => true

const useUserSelect = (
  userIsSelectable?: (user: IUserTagFragment) => boolean,
  isListView?: boolean,
  config?: {
    countLabel?: string
    placeholder?: string
    noDataPlaceholder?: string
  }
) => {
  const [queryResult] = useUserSelectDataQuery({ requestPolicy: "cache-first" })

  const users = useMemo(
    () =>
      (queryResult.data?.location_member?.map((u) => u.user) ?? []).filter(
        userIsSelectable ?? always
      ),
    [queryResult.data?.location_member, userIsSelectable]
  )

  const userItem = (user: IUserTagFragment) => ({
    value: user.id as string | null,
    label: (
      <span
        className={classNames("overflow-hidden text-ellipsis whitespace-nowrap text-sm", {
          "line-through opacity-50": !!user.deleted_at,
        })}>
        {user.first_name} {user.last_name}
      </span>
    ),
    icon: <UserAvatar size={16} user={user} />,
    searchValue: `${user.first_name} ${user.last_name}`,
  })
  const items = useMemo(() => {
    const i = users.filter((u) => !u.deleted_at).map(userItem)
    return sortBy(i, "searchValue", lexCompare)
  }, [users])
  const retainedItems = useMemo(() => {
    return users.filter((u) => !!u.deleted_at).map(userItem)
  }, [users])

  return {
    items,
    retainedItems,
    valueToString: (s: string | null) =>
      s ?? (i18n.t("tasks:filters.users.nobody") as string),
    placeholder: config?.placeholder,
    noDataPlaceholder: config?.noDataPlaceholder,
    size: "small" as "small" | "large",
    title: i18n.t("common:employee", { count: 1 }),
    renderCompactSelectedValues: (values: TreeLike<Item<string>>[]) => {
      const selectedUsers = users.filter((u) => values.some((v) => v.value === u.id))
      const firstUser = selectedUsers[0]

      return (
        <MultiSelectSummary
          items={selectedUsers}
          limit={3}
          itemsToIcons={(user) => (
            <UserAvatar user={user} key={user.id} className="!border-white" size={20} />
          )}
          mode={isListView ? "summary" : "count"}
          firstItemLabel={
            <span
              className={classNames({ "line-through opacity-50": !!firstUser.deleted_at })}>
              {firstUser?.first_name ?? ""} {firstUser?.last_name ?? ""}
            </span>
          }
          countLabel={config?.countLabel}
          iconBorderClass="border-gray-100"
        />
      )
    },
  }
}

export type UserSingleSelectProps = Partial<SelectPopoverProps<string | null>> & {
  userIsSelectable?: (user: IUserTagFragment) => boolean
}

export function UserSingleSelect({ userIsSelectable, ...props }: UserSingleSelectProps) {
  const selectProps = useUserSelect(userIsSelectable)
  return <SelectPopover<string | null> {...selectProps} {...props} />
}

export type UserMultiSelectProps = Partial<MultiSelectPopoverProps<string>> & {
  userIsSelectable?: (user: IUserTagFragment) => boolean
  isListView?: boolean
  config?: {
    countLabel?: string
    placeholder?: string
    noDataPlaceholder?: string
  }
}

export function UserMultiSelect({
  userIsSelectable,
  isListView,
  config = {
    countLabel: i18n.t("tasks:fields.assignee", { count: 2 }),
    placeholder: i18n.t("common:search_token", {
      token: i18n.t("tasks:fields.assignee", { count: 1 }),
    }),
    noDataPlaceholder: i18n.t("common:no_token_found", {
      token: i18n.t("tasks:fields.assignee", { count: 2 }),
    }),
  },
  ...props
}: UserMultiSelectProps) {
  const selectProps = useUserSelect(
    userIsSelectable,
    isListView,
    config
  ) as MultiSelectPopoverProps<string>
  return <MultiSelectPopover {...selectProps} {...props} />
}
