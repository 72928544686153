import { IMutationRootDeleteAssetByPkArgs } from "@elara/db"
import {
  AssetDetailsDocument,
  IAssetDetailsQuery,
} from "@graphql/documents/asset.generated"
import { UpdateResolver } from "@urql/exchange-graphcache"

type Mutations = {
  [fieldName: string]: UpdateResolver
}

const mutations: Mutations = {
  delete_asset_by_pk(_parent, args: IMutationRootDeleteAssetByPkArgs, cache) {
    cache.updateQuery<IAssetDetailsQuery>({ query: AssetDetailsDocument }, (data) => {
      /* TODO this part is incomplete, cache refresh does still not work here
      if (data) {
        data.asset_by_pk = data.asset_by_pk?.filter((c) => c.id !== args.id)
      }
      */
      return data
    })
  },
}

export default {
  mutations,
}
