import { Flex, FlexProps } from "@components/layout"
import { useBreakpoint } from "@contexts/breakpoints"
import { openModal } from "@contexts/modal-context"
import { IViewDataTypeEnum } from "@elara/db"
import { Data } from "@elara/select"
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from "@hello-pangea/dnd"
import { useDisclosure } from "@hooks"
import i18n from "@i18n"
import { DownloadSimple, FadersHorizontal } from "@phosphor-icons/react"
import Icons from "@resources/icons"
import { naturalCompare } from "@utils"
import { reorder } from "@utils/sorting"
import { flattenTreeList } from "@utils/tree"
import classNames from "classnames"
import download from "downloadjs"
import { parse } from "json2csv"
import React, { ReactNode, useMemo, useState } from "react"

import Button from "../button"
import { Item } from "../combobox-select"
import Divider from "../divider"
import { CheckboxInput } from "../form/checkbox-input"
import { PopoverClose, PopoverContent, PopoverRoot, PopoverTrigger } from "../popover"
import { Select } from "../select"
import { SelectPopover } from "../single-select"
import Toggle from "../toggle"
import { ToggleGroup, ToggleGroupItem } from "../toggle-group"
import { UseDataViewReturnType } from "./data-view.hooks"
import { useDataViewConfigContext } from "./data-view-config"
import {
  CalendarType,
  Column,
  CustomDataConfig,
  DataViewLayoutType,
  GroupOrderType,
} from "./data-view-types"

const MenuPopoverHeader = (props: { isSubMenuVisible: boolean; onBack: () => void }) => (
  <div className="mb-3 flex w-full flex-row items-center justify-between px-4 font-medium">
    {props.isSubMenuVisible && (
      <Icons.LeftArrow
        height={20}
        width={20}
        className="mr-2 cursor-pointer rounded text-grey-3 hover:bg-grey-5"
        onClick={props.onBack}
      />
    )}

    <span>{i18n.t("data-view:options.title")}</span>

    <PopoverClose>
      <Icons.Close className="h-5 w-5 cursor-pointer rounded-full p-0.5 text-grey-2 hover:bg-grey-5" />
    </PopoverClose>
  </div>
)

const LayoutCard = (props: {
  layout: DataViewLayoutType
  className?: string
  selectedLayout: DataViewLayoutType
  onSelect: (layout: DataViewLayoutType) => void
}) => {
  const bp = useBreakpoint()

  if (bp.renderMobile && props.layout === "table") return null

  return (
    <div
      className={classNames(
        "flex flex-col items-center border rounded px-4 py-1.5 cursor-pointer ",
        props.className,
        {
          "border-blue-400 bg-blue-50 text-blue-600": props.selectedLayout === props.layout,
          "border-gray-200 text-gray-700 hover:bg-gray-100 hover:border-gray-500":
            props.selectedLayout !== props.layout,
        }
      )}
      onClick={() => props.onSelect(props.layout)}>
      {props.layout === "table" && <Icons.Columns className="h-5 w-5" />}
      {props.layout === "list" && <Icons.List className="h-5 w-5" />}
      {props.layout === "calendar" && <Icons.Calendar className="h-5 w-5" />}
      {props.layout === "kanban" && <Icons.Card className="h-5 w-5" />}
      <span className="mt-1 text-xs">
        {i18n.t(`data-view:options.layout.${props.layout}`)}
      </span>
    </div>
  )
}

export const OptionsMenuItem = (props: React.PropsWithChildren<FlexProps>) => (
  <Flex
    row
    align="center"
    {...props}
    className={classNames(
      "px-4 h-9",
      { "hover:bg-grey-6 cursor-pointer": !!props.onClick },
      props.className
    )}
  />
)

type DataViewOptionsMenuContentProps<
  D extends Data,
  Id extends string,
  Options extends {}
> = {
  dataView: UseDataViewReturnType<D, Id>
  dataType: IViewDataTypeEnum
  columns: Column<D, Id, Options>[]
  closePopover: () => void
  onOpenTableMenu: () => void
  availableLayoutTypes: DataViewLayoutType[]
  historyFilterItems?: Item<any>[]
  historyFilterLabel?: string
  updateHistoryFilter?: (value: any) => void
  historyFilterValue?: any
  customDataConfigMenuOptions?: (
    config: CustomDataConfig | null,
    updateConfig: (config: CustomDataConfig) => void
  ) => ReactNode
}

const DataViewOptionsMenuContent = <D extends Data, Id extends string, Options extends {}>(
  props: DataViewOptionsMenuContentProps<D, Id, Options>
) => {
  const [subMenu, setSubMenu] = useState<"layout" | null>(null)
  const ctx = useDataViewConfigContext()
  const configActions = ctx.actions

  const kanbanGroupOrder = useMemo(() => {
    const groupById = props.dataView.config.groupBy
    const kanbanConfig = props.dataView.config.kanbanConfig

    return groupById && kanbanConfig && kanbanConfig[groupById]
      ? kanbanConfig[groupById]
      : []
  }, [props.dataView.config.groupBy, props.dataView.config.kanbanConfig])

  const handleOnSortState = (value: string) => {
    const selectedColumn = props.columns?.find((column) => column.id === value)
    props.dataView.updateOrderBy(
      value as Id,
      selectedColumn?.defaultSortDirection! ?? "asc"
    )
  }

  const onToggleGroup = (groupId: string, checked: boolean) => {
    const groupById = props.dataView.config.groupBy
    const newGroupOrder = kanbanGroupOrder.reduce((acc, group) => {
      if (group.groupId === groupId) {
        group.hidden = !checked
      }

      acc.push(group)

      return acc
    }, [] as GroupOrderType[])

    if (groupById) {
      props.dataView.updateKanban({
        ...props.dataView.config.kanbanConfig,
        [groupById]: newGroupOrder,
      })
    }
  }

  const onDragEndGroup: OnDragEndResponder = (result) => {
    const groupById = props.dataView.config.groupBy

    if (!result.destination || !groupById) return

    const newGroupOrder = reorder(
      kanbanGroupOrder,
      result.source.index,
      result.destination.index
    )

    props.dataView.updateKanban({ [groupById]: newGroupOrder })
  }

  const onSelectLayout = (layout: DataViewLayoutType) => {
    props.dataView.updateLayoutType(layout)
    setSubMenu(null)
  }

  const groupByColumns = props.columns
    ?.filter((column) => !!column.groupBy)
    .sort((a, b) => naturalCompare(a.Header, b.Header))

  const canSumColumn =
    props.dataView.layoutType === "table" && props.columns.some((c) => !!c.summationValue)

  const exportToCSV = () => {
    const jsonObj = props.dataView.originalData.map((item) => {
      let result: Record<string, string> = {}

      props.columns.forEach((column) => {
        if (column.Header === "") return
        result[column.Header] = column.toText(item)
      })

      return result
    })

    const filename = `elara-export-${new Date().toISOString().slice(0, 10)}.csv`
    const csv = parse(jsonObj)

    download(csv, filename, "text/csv")

    props.closePopover()
  }

  const generateQRCodes = async () => {
    let ids: string[] = []

    if (props.dataView.data.type === "grouped") {
      props.dataView.data.groups.forEach((group) => {
        const result = flattenTreeList(group.items).map(({ id }) => id) as string[]
        ids.push(...result)
      })
    } else if (props.dataView.data.type === "ungrouped") {
      ids = flattenTreeList(props.dataView.data.items).map(({ id }) => id) as string[]
    }

    openModal("bulk_generate_qrcode", { ids, type: props.dataType })

    props.closePopover()
  }

  const mainMenu = (
    <>
      <div className="mx-4 mb-2 grid auto-cols-auto grid-flow-col gap-3">
        {props.availableLayoutTypes.map((layout) => (
          <LayoutCard
            key={layout}
            layout={layout}
            onSelect={onSelectLayout}
            selectedLayout={props.dataView.layoutType}
          />
        ))}
      </div>

      <OptionsMenuItem>
        <Icons.Sort />
        <span className="flex-1 whitespace-nowrap px-2">
          {i18n.t("data-view:options.sort-by.title")}
        </span>
        <Select
          className="min-w-0 flex-1 truncate"
          value={props.dataView.config.orderBy?.[0]?.id}
          onValueChange={handleOnSortState}
          items={props.columns
            ?.filter((column) => !column.disableSortBy && column.orderBy)
            .sort((a, b) => naturalCompare(a.Header, b.Header))
            .map((column) => ({ value: column.id, label: column.Header }))}
        />
      </OptionsMenuItem>

      {groupByColumns.length > 0 && (
        <OptionsMenuItem>
          <Icons.Category className="shrink-0" />
          <span className="flex-1 whitespace-nowrap px-2">
            {i18n.t("data-view:options.group-by.title")}
          </span>
          <Select
            className="min-w-0 flex-1 truncate"
            value={props.dataView.config.groupBy ?? "NO_GROUPING"}
            onValueChange={(selectedValue: string) => {
              const groupBy = selectedValue === "NO_GROUPING" ? null : (selectedValue as Id)
              props.dataView.updateGroupBy(groupBy)
            }}
            items={groupByColumns
              .map((column) => ({ value: column.id as string, label: column.Header }))
              .concat([
                {
                  value: "NO_GROUPING",
                  label: i18n.t("data-view:options.group-by.none"),
                },
              ])}
          />
        </OptionsMenuItem>
      )}

      {props.historyFilterItems && props.historyFilterLabel && (
        <OptionsMenuItem>
          <span className="flex-1 truncate">{props.historyFilterLabel}</span>
          <SelectPopover
            className="w-40"
            isClearable={false}
            items={props.historyFilterItems}
            value={props.historyFilterValue}
            onChange={props.updateHistoryFilter}
          />
        </OptionsMenuItem>
      )}

      {props.customDataConfigMenuOptions?.(
        props.dataView.config.customDataConfig ?? null,
        configActions.updateCustomDataConfig
      )}

      {props.dataView.layoutType === "calendar" && (
        <>
          <Divider className="my-2" />

          <div className="flex flex-col">
            <OptionsMenuItem
              onClick={() =>
                props.dataView.updateCalendar({
                  ...props.dataView.config.calendarConfig,
                  showWeekends: !props.dataView.config.calendarConfig?.showWeekends,
                })
              }>
              <span className="flex-1">
                {i18n.t("data-view:options.calendar.show_weekends")}
              </span>
              <Toggle
                readOnly
                checked={props.dataView.config.calendarConfig?.showWeekends}
              />
            </OptionsMenuItem>
            <OptionsMenuItem
              onClick={() =>
                props.dataView.updateCalendar({
                  ...props.dataView.config.calendarConfig,
                  hideUpcomingMaintenanceTasks:
                    !props.dataView.config.calendarConfig?.hideUpcomingMaintenanceTasks,
                })
              }>
              <span className="flex-1">
                {i18n.t("data-view:options.calendar.show_upcoming_maintenance_tasks")}
              </span>
              <Toggle
                readOnly
                checked={
                  !props.dataView.config.calendarConfig?.hideUpcomingMaintenanceTasks
                }
              />
            </OptionsMenuItem>

            <OptionsMenuItem>
              <Icons.Layout className="shrink-0" />
              <span className="flex-1 pl-2">
                {i18n.t("data-view:options.calendar.layout")}
              </span>
              <ToggleGroup
                type="single"
                defaultValue="dayGrid"
                value={props.dataView.config.calendarConfig?.type}
                onValueChange={(type: CalendarType) =>
                  props.dataView.updateCalendar({
                    ...props.dataView.config.calendarConfig,
                    type,
                  })
                }>
                <ToggleGroupItem value="dayGrid">
                  {i18n.t("data-view:options.layout.grid")}
                </ToggleGroupItem>
                <ToggleGroupItem value="list">
                  {i18n.t("data-view:options.layout.list")}
                </ToggleGroupItem>
              </ToggleGroup>
            </OptionsMenuItem>
          </div>
        </>
      )}

      {props.dataView.layoutType === "table" && (
        <>
          <Divider className="my-2" />

          <div className="flex flex-col">
            <OptionsMenuItem
              onClick={() => {
                props.onOpenTableMenu()
                props.closePopover()
              }}>
              <Icons.Columns className="shrink-0" />
              <span className="flex-1 pl-2">
                {i18n.t("data-view:options.table.columns")}
              </span>
              <div className="flex flex-row flex-nowrap items-center">
                <Icons.RightArrow height={16} width={16} className="text-grey-3" />
              </div>
            </OptionsMenuItem>

            <OptionsMenuItem onClick={exportToCSV}>
              <DownloadSimple className="shrink-0" />
              <span className="pl-2">{i18n.t("data-view:actions.export_to_csv")}</span>
            </OptionsMenuItem>

            {(props.dataType === IViewDataTypeEnum.Asset ||
              props.dataType === IViewDataTypeEnum.Consumable) && (
              <OptionsMenuItem onClick={generateQRCodes}>
                <Icons.QRCode className="shrink-0" />
                <span className="pl-2">{i18n.t("qrcode:dialogs.bulk_generate.title")}</span>
              </OptionsMenuItem>
            )}

            {canSumColumn && (
              <OptionsMenuItem
                onClick={() =>
                  props.dataView.updateShowSummation(
                    !props.dataView.config.showSummation ?? false
                  )
                }>
                <span className="flex-1">
                  {i18n.t("data-view:options.aggregation.title")}
                </span>
                <Toggle readOnly checked={props.dataView.config.showSummation ?? false} />
              </OptionsMenuItem>
            )}
          </div>
        </>
      )}

      {props.dataView.layoutType === "kanban" && (
        <>
          <Divider className="my-2" />

          <div className="flex max-h-[200px] flex-col overflow-y-scroll px-4 py-1">
            <DragDropContext onDragEnd={onDragEndGroup}>
              <Droppable droppableId="kanbanColumns">
                {(provided, _snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {kanbanGroupOrder.map((group, index) => (
                      <Draggable
                        index={index}
                        key={group.groupId}
                        draggableId={group.groupId!}>
                        {(provided, _snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="group flex items-center justify-between py-1">
                            <div className="flex min-w-0 items-center gap-2">
                              <div className="cursor-pointer opacity-25 transition-opacity group-hover:opacity-75">
                                <Icons.Drag />
                              </div>
                              <span className="truncate font-medium">{group.label}</span>
                            </div>
                            <CheckboxInput
                              size="small"
                              checked={!group.hidden}
                              onChange={(e) =>
                                onToggleGroup(group.groupId!, e.target.checked)
                              }
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </>
      )}

      {!props.dataView.isConfigPersisted && (
        <>
          <Divider className="my-2 mb-0" />

          <Flex row justify="space-between" className="mt-1 w-full px-4">
            <Button
              type="tertiary"
              size="small"
              onClick={ctx.resetConfig}
              className="!font-medium !text-gray-700">
              {i18n.t("common:reset")}
            </Button>
            <Button
              type="tertiary"
              size="small"
              onClick={() => ctx.save()}
              className="!font-medium">
              {i18n.t("common:save")}
            </Button>
          </Flex>
        </>
      )}
    </>
  )

  return (
    <Flex col flex="1" className="mb-2 mt-4 text-sm">
      {<MenuPopoverHeader isSubMenuVisible={!!subMenu} onBack={() => setSubMenu(null)} />}

      {!subMenu && mainMenu}
    </Flex>
  )
}

export const DataViewOptionsMenu = <D extends Data, Id extends string, Options extends {}>(
  props: Omit<DataViewOptionsMenuContentProps<D, Id, Options>, "closePopover">
) => {
  const bp = useBreakpoint()
  const menu = useDisclosure()

  return (
    <PopoverRoot open={menu.isOpen} onOpenChange={menu.changeOpen} modal>
      <PopoverTrigger asChild>
        {!bp.sm || !bp.md ? (
          <Button type="secondary" icon={FadersHorizontal} />
        ) : (
          <Button type="secondary" icon={FadersHorizontal}>
            <span className="ml-1 hidden text-sm font-medium md:inline-block">
              {i18n.t("data-view:options.title")}
            </span>
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent className="mt-1.5 w-[400px] rounded" align="end" side="bottom">
        <DataViewOptionsMenuContent {...props} closePopover={menu.onClose} />
      </PopoverContent>
    </PopoverRoot>
  )
}
