import { z } from "zod"

// Maintenance Enums
export enum Frequency {
  minutely = "minutely",
  secondly = "secondly",
  hourly = "hourly",
  daily = "daily",
  weekly = "weekly",
  monthly = "monthly",
  yearly = "yearly",
}

export enum Weekday {
  MO = "MO",
  TU = "TU",
  WE = "WE",
  TH = "TH",
  FR = "FR",
  SA = "SA",
  SU = "SU",
}

export enum Month {
  JAN = "JAN",
  FEB = "FEB",
  MAR = "MAR",
  APR = "APR",
  MAY = "MAY",
  JUN = "JUN",
  JUL = "JUL",
  AUG = "AUG",
  SEP = "SEP",
  OCT = "OCT",
  NOV = "NOV",
  DEC = "DEC",
}

export function weekdayToIsoWeekday(d: Weekday) {
  switch (d) {
    case Weekday.MO:
      return 1
    case Weekday.TU:
      return 2
    case Weekday.WE:
      return 3
    case Weekday.TH:
      return 4
    case Weekday.FR:
      return 5
    case Weekday.SA:
      return 6
    case Weekday.SU:
      return 7
  }
}
export function isoWeekdayToWeekday(d: number) {
  switch (d) {
    case 1:
      return Weekday.MO
    case 2:
      return Weekday.TU
    case 3:
      return Weekday.WE
    case 4:
      return Weekday.TH
    case 5:
      return Weekday.FR
    case 6:
      return Weekday.SA
    case 7:
      return Weekday.SU
    default:
      throw new Error(`Invalid weekday: ${d}`)
  }
}

export function monthToIsoMonth(d: Month) {
  switch (d) {
    case Month.JAN:
      return 1
    case Month.FEB:
      return 2
    case Month.MAR:
      return 3
    case Month.APR:
      return 4
    case Month.MAY:
      return 5
    case Month.JUN:
      return 6
    case Month.JUL:
      return 7
    case Month.AUG:
      return 8
    case Month.SEP:
      return 9
    case Month.OCT:
      return 10
    case Month.NOV:
      return 11
    case Month.DEC:
      return 12
    default:
      throw new Error(`Invalid month: ${d}`)
  }
}
export function isoMonthToMonth(d: number) {
  switch (d) {
    case 1:
      return Month.JAN
    case 2:
      return Month.FEB
    case 3:
      return Month.MAR
    case 4:
      return Month.APR
    case 5:
      return Month.MAY
    case 6:
      return Month.JUN
    case 7:
      return Month.JUL
    case 8:
      return Month.AUG
    case 9:
      return Month.SEP
    case 10:
      return Month.OCT
    case 11:
      return Month.NOV
    case 12:
      return Month.DEC
    default:
      throw new Error(`Invalid month: ${d}`)
  }
}

export enum MonthlyModifier {
  by_date = "by_date",
  by_weekday = "by_weekday",
  first_of_month = "first_of_month",
  last_of_month = "last_of_month",
}

export enum Ends {
  never = "never",
  on = "on",
}

// Time Interval Trigger
export const TimeIntervalTriggerSchema = z.object({
  type: z.literal("time"),
  payload: z.object({
    rrule: z.string().optional(),
    nextAt: z.string().datetime().nullable(),
    createNextAt: z.string().datetime().optional().nullable(),
    nextTime: z.string().datetime().optional().nullable(),
    nextDate: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
    timeZone: z.string().optional(),
    repetition: z.object({
      frequency: z.nativeEnum(Frequency),
      interval: z.number(),
      hourly: z
        .object({
          from: z.string().optional(),
          to: z.string().optional(),
          weekdays: z.array(z.nativeEnum(Weekday)).optional(),
        })
        .optional(),
      weekly: z.object({ weekdays: z.array(z.nativeEnum(Weekday)).optional() }).optional(),
      monthly: z
        .object({
          modifier: z.nativeEnum(MonthlyModifier),
          bymonth: z.array(z.nativeEnum(Month)).optional(),
        })
        .optional(),
    }),
    createNextOnlyIfLastComplete: z.boolean(),
    createBefore: z.object({
      years: z.number().optional(),
      months: z.number().optional(),
      weeks: z.number().optional(),
      days: z.number().optional(),
      hours: z.number().optional(),
      minutes: z.number().optional(),
      seconds: z.number().optional(),
    }),
    ends: z.object({
      value: z.nativeEnum(Ends),
      date: z.string().datetime().optional(),
      count: z.number().optional(),
    }),
  }),
})

export type TimeIntervalTriggerOptions = z.infer<typeof TimeIntervalTriggerSchema>

// Meter Reading Trigger
const MeterReadingTriggerBase = z.object({
  assetId: z.string(),
  meterId: z.string(),
  onlyOneOpenTask: z.boolean(),
  dueAfter: z.object({
    years: z.number().optional(),
    months: z.number().optional(),
    weeks: z.number().optional(),
    days: z.number().optional(),
    hours: z.number().optional(),
    minutes: z.number().optional(),
    seconds: z.number().optional(),
  }),
})

const MeterReadingIntervalSchema = MeterReadingTriggerBase.extend({
  variant: z.literal("interval"),
  nextAt: z.number(),
  every: z.number(),
})

export type MeterReadingIntervalTriggerOptions = z.infer<typeof MeterReadingIntervalSchema>

const MeterReadingRangeSchema = MeterReadingTriggerBase.extend({
  variant: z.literal("range"),
  min: z.number(),
  max: z.number(),
})

export type MeterReadingRangeTriggerOptions = z.infer<typeof MeterReadingRangeSchema>

export const MeterReadingTriggerSchema = z.object({
  type: z.literal("meter"),
  payload: z.discriminatedUnion("variant", [
    MeterReadingIntervalSchema,
    MeterReadingRangeSchema,
  ]),
})

export type MeterReadingTriggerOptions = z.infer<typeof MeterReadingTriggerSchema>

export type MaintenanceTrigger = TimeIntervalTriggerOptions | MeterReadingTriggerOptions

// Maintenance Schema
export const MaintenanceSchema = z.object({
  name: z.string().optional(),
  description: z.string().nullable().optional(),
  triggers: z
    .discriminatedUnion("type", [TimeIntervalTriggerSchema, MeterReadingTriggerSchema])
    .array(),
})
