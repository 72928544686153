import { SelectPopover, SelectPopoverProps } from "@components/shared/single-select"
import {
  useConsumableGroupsQuery,
  useInsertConsumableGroupMutation,
} from "@graphql/documents/consumable-group.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { naturalCompare } from "@utils"
import { useCallback, useMemo } from "react"

const useConsumableGroupSelect = (): Omit<SelectPopoverProps<string>, "value"> => {
  const [groups, fetch] = useConsumableGroupsQuery({ requestPolicy: "cache-first" })

  const createScope = usePermissionScope(IPermissionScopeEnum.AppAssetCreate)
  const [, createGroup] = useInsertConsumableGroupMutation()

  const items = useMemo(
    () =>
      (groups.data?.consumable_group ?? [])
        .slice()
        .sort((a, b) => naturalCompare(a.name, b.name))
        .map((group) => ({
          value: group.id,
          searchValue: group.name,
          label: <> {group.name} </>,
        })),
    [groups.data?.consumable_group]
  )

  const onCreate = useCallback(
    async (name: string): Promise<string> => {
      const result = await createGroup({ name }, createScope.context())
      const id = result.data?.insert_consumable_group_one?.id
      if (result.error || !id) throw result.error
      await fetch({ requestPolicy: "network-only" })
      return id
    },
    [createScope, createGroup]
  )

  return {
    items,
    name: i18n.t("consumables:fields.group", { count: 1 }),
    onCreate,
    placeholder: i18n.t("common:select_token", {
      token: i18n.t("consumables:fields.group", { count: 1 }),
    }),
  }
}

export const ConsumableGroupSingleSelect = (
  props: Omit<SelectPopoverProps<string>, "items">
) => {
  const groupProps = useConsumableGroupSelect()

  return <SelectPopover {...groupProps} {...props} />
}
