import React, { createContext, useContext, useMemo, useState } from "react"

const DragFilesContext = createContext<{ isDragging: boolean }>({ isDragging: false })

const DragFilesProvider = (props: React.PropsWithChildren<{}>) => {
  const [isDragging, setIsDragging] = useState(false)

  React.useEffect(() => {
    let dragCounter = 0
    const handleDrag = (event: DragEvent) => {
      event.preventDefault()
      event.stopPropagation()
    }
    const handleDragIn = (event: DragEvent) => {
      event.preventDefault()
      event.stopPropagation()
      dragCounter += 1
      if (event.dataTransfer?.types.some((t) => t === "Files")) {
        setIsDragging(true)
      }
    }
    const handleDragOut = (event: DragEvent) => {
      event.preventDefault()
      event.stopPropagation()
      dragCounter -= 1
      if (dragCounter > 0) return
      setIsDragging(false)
    }
    const handleDrop = (event: DragEvent) => {
      event.preventDefault()
      event.stopPropagation()
      setIsDragging(false)
      if (event.dataTransfer?.files && event.dataTransfer.files.length > 0) {
        dragCounter = 0
      }
    }

    window.addEventListener("dragenter", handleDragIn)
    window.addEventListener("dragleave", handleDragOut)
    window.addEventListener("dragover", handleDrag)
    window.addEventListener("drop", handleDrop)
    return function cleanUp() {
      window.removeEventListener("dragenter", handleDragIn)
      window.removeEventListener("dragleave", handleDragOut)
      window.removeEventListener("dragover", handleDrag)
      window.removeEventListener("drop", handleDrop)
    }
  }, [])

  const value = useMemo(() => ({ isDragging }), [isDragging])

  return (
    <DragFilesContext.Provider value={value}>{props.children}</DragFilesContext.Provider>
  )
}

export const useDragFilesContext = () => useContext(DragFilesContext)

export default DragFilesProvider
