import { PropsWithChildren } from "react"
import React from "react"

export function DataViewList(
  props: PropsWithChildren<{ containerRef: React.Ref<HTMLDivElement> }>
) {
  return (
    <div className="flex-1" ref={props.containerRef}>
      {props.children}
    </div>
  )
}
