import { useUser } from "@contexts/user-context"
import {
  DeleteUserFavoriteDocument,
  IDeleteUserFavoriteMutation,
  IDeleteUserFavoriteMutationVariables,
  IInsertUserFavoriteMutation,
  IInsertUserFavoriteMutationVariables,
  InsertUserFavoriteDocument,
  UserFavoritesDocument,
  useUserFavoritesQuery,
} from "@graphql/documents/custom-view.generated"
import { useCallbackRef } from "@hooks"
import { Star } from "@phosphor-icons/react"
import colors from "tailwindcss/colors"
import { useClient } from "urql"
import { v4 } from "uuid"

export const useUserFavorite = (props: {
  defaultViewId?: string | null
  customViewId?: string | null
  projectId?: string | null
  assetId?: string | null
}) => {
  const user = useUser()
  const client = useClient()

  const [favoriteQueryRes] = useUserFavoritesQuery({ requestPolicy: "cache-only" })
  const favorites = favoriteQueryRes.data?.user_favorite
  const favorite = favorites?.find(
    (v) =>
      (props.defaultViewId && v.default_view_id === props.defaultViewId) ||
      (props.customViewId && v.custom_view?.id === props.customViewId) ||
      (props.projectId && v.project_id === props.projectId) ||
      (props.assetId && v.asset_id === props.assetId)
  )

  const toggleFavorite = useCallbackRef(async () => {
    if (!favorite) {
      const sortOrder =
        (favorites?.reduce((m, f) => Math.min(m, f.sort_order), 0) ?? 0) -
        (1 + Math.random() / 100)

      await client
        .mutation<IInsertUserFavoriteMutation, IInsertUserFavoriteMutationVariables>(
          InsertUserFavoriteDocument,
          {
            data: {
              default_view_id: props.defaultViewId ?? null,
              custom_view_id: props.customViewId ?? null,
              project_id: props.projectId ?? null,
              asset_id: props.assetId ?? null,
              user_id: user.id,
              id: v4(),
              sort_order: sortOrder || 0,
            },
          }
        )
        .toPromise()
    } else {
      await client
        .mutation<IDeleteUserFavoriteMutation, IDeleteUserFavoriteMutationVariables>(
          DeleteUserFavoriteDocument,
          { id: favorite.id }
        )
        .toPromise()
    }
    await client.query(UserFavoritesDocument, {}).toPromise()
  })

  return { toggleFavorite, isFavorite: !!favorite }
}

export const UserFavorite = (props: {
  defaultViewId?: string | null
  customViewId?: string | null
  projectId?: string | null
  assetId?: string | null
}) => {
  const { toggleFavorite, isFavorite } = useUserFavorite(props)

  return (
    <div
      className="group -m-2 flex h-8 w-8 items-center justify-center"
      onClick={async (e) => {
        e.stopPropagation()
        await toggleFavorite()
      }}>
      <Star size={16} weight={isFavorite ? "fill" : "duotone"} color={colors.yellow[400]} />
    </div>
  )
}
