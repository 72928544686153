import { UserTag } from "@components/shared"
import { StaticRichText } from "@components/shared/static-rich-text"
import { TeamTag } from "@components/shared/team-tag"
import { InspectionRadioChoices } from "@components/work-order/block/elements/block-element-inspection"
import { BlockElementFormItem } from "@components/work-order/block/elements/block-element-types"
import { WorkOrderPriorityBadge } from "@components/work-order/work-order-priority"
import { WorkOrderStatusTag } from "@components/work-order/work-order-status"
import { IBlockElementTypeEnum } from "@elara/db"
import i18n from "@i18n"
import { Cube, File, Swatches } from "@phosphor-icons/react"
import { cn } from "@utils"
import axios from "axios"
import { format, setDefaultOptions } from "date-fns"
import { de, enUS } from "date-fns/locale"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { ITaskAPIResult } from "./api-types"

const SectionHeading = ({ children }: { children: React.ReactNode }) => (
  <p className="mb-1.5 text-xs font-medium text-gray-600">{children}</p>
)

const ChecklistElements = ({ checklist }: { checklist: BlockElementFormItem[] }) => {
  let widgets: React.ReactNode[] = []

  for (const element of checklist) {
    switch (element.element_type) {
      case IBlockElementTypeEnum.YesNo:
        widgets.push(
          <>
            <StaticRichText className="font-medium" content={element.config.description} />
            {element.response?.yes_no_response && (
              <p className="max-w-fit rounded border px-4 py-1">
                {i18n.t(
                  `tasks:checklist.yes_no.choices.${element.response.yes_no_response}`
                )}
              </p>
            )}
          </>
        )
        break

      case IBlockElementTypeEnum.Choice:
        widgets.push(
          <>
            <StaticRichText
              className="mb-2 font-medium"
              content={element.config.description}
            />
            <div className="space-y-2">
              {element.config.options.map((option) => (
                <div key={option.id} className="flex items-center gap-1.5">
                  <input
                    readOnly
                    type="checkbox"
                    checked={
                      element.response?.selected_options.some((x) => x === option.id) ??
                      false
                    }
                  />
                  <p className="text-sm">{option.label}</p>
                </div>
              ))}
            </div>
          </>
        )
        break

      case IBlockElementTypeEnum.Checkbox:
        widgets.push(
          <>
            <div className="flex items-center gap-1.5">
              <input
                readOnly
                type="checkbox"
                checked={element.response?.checked ?? false}
              />
              <StaticRichText content={element.config.description} />
            </div>
          </>
        )
        break

      case IBlockElementTypeEnum.Inspection:
        widgets.push(
          <div>
            <StaticRichText
              className="mb-2 font-medium"
              content={element.config.description}
            />

            <InspectionRadioChoices value={element.response?.inspection_result} />

            {element.response?.note && (
              <p className="mt-2 rounded bg-gray-100 px-4 py-2">{element.response.note}</p>
            )}
          </div>
        )
        break

      case IBlockElementTypeEnum.Text:
        widgets.push(<StaticRichText content={element.config.description} />)
        break

      case IBlockElementTypeEnum.Media:
        widgets.push(<StaticRichText content={element.config.description} />)
        break
    }
  }

  return (
    <>
      {widgets.map((widget, index) => {
        const checklistItem = checklist[index]

        return (
          <div key={checklistItem.id} className="border border-dashed p-3.5">
            <div className="space-y-1.5">{widget}</div>
          </div>
        )
      })}
    </>
  )
}

const PublicTaskDetailPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [task, setTask] = useState<ITaskAPIResult | null>(null)

  useEffect(() => {
    const locale = i18n.resolvedLanguage || navigator.language
    const localeIsEnglish = locale.includes("en")

    // Set date-fns locale
    setDefaultOptions({
      locale: localeIsEnglish ? enUS : de,
    })

    const fetchTask = async () => {
      try {
        const { data } = await axios(`/api/public/v1/task/${id}`)
        setTask(data)
      } catch (e) {
        navigate("/")
      }
    }

    fetchTask()
  }, [id])

  if (!task) return null

  return (
    <div className="flex flex-col space-y-10 overflow-y-scroll bg-gray-50 py-10 text-sm antialiased">
      <div className="mx-auto w-full max-w-3xl flex-1 rounded bg-white p-8 shadow-lg">
        <div className="space-y-6 text-sm">
          <div className="space-y-1">
            <div className="flex items-center gap-2">
              <p className="text-gray-400">#{task.work_order_number}</p>

              {/* Status */}
              <WorkOrderStatusTag status={task.status} className="scale-90" />

              {/* Priority */}
              <WorkOrderPriorityBadge priority={task.priority} className="-ml-2 scale-90" />

              {/* Categories */}
              <div className="flex items-center gap-2">
                {task.categories.map((category) => (
                  <div
                    key={id}
                    style={{ background: category.color ?? "inherit" }}
                    className="rounded-lg px-2.5 text-xs font-medium text-white">
                    {category.label}
                  </div>
                ))}
              </div>
            </div>

            {/* Title */}
            <h1 className="text-xl font-medium">{task.name}</h1>

            {/* Metadata */}
            <div className="text-xs leading-relaxed text-gray-500">
              <p>
                {i18n.t("tasks:fields.created_at")}{" "}
                <span className="font-medium">
                  {format(new Date(task.created_at), "PPPp")}
                </span>
                {" | "}
                {i18n.t("tasks:fields.created_by")}{" "}
                <span className="font-medium">
                  {task.created_by.first_name} {task.created_by.last_name}
                </span>
              </p>

              {task.updated_at && (
                <p>
                  {i18n.t("tasks:fields.last_update")}{" "}
                  <span className="font-medium">
                    {format(new Date(task.updated_at), "PPPp")}
                  </span>
                </p>
              )}
            </div>
          </div>

          {/* Description */}
          <div className="rounded bg-gray-50 p-4">
            <StaticRichText content={task.description ?? ""} />
          </div>

          <hr />

          <div className="grid grid-cols-3">
            {/* Left Column */}
            <div className="col-span-2 space-y-6">
              {/* Documents */}
              {task.documents.length > 0 && (
                <div>
                  <SectionHeading>{i18n.t("common:document", { count: 2 })}</SectionHeading>

                  <div className="flex items-center gap-4">
                    {task.documents.map((document) => (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        key={document.id}
                        href={document.url}>
                        <div
                          className={cn(
                            "relative rounded border bg-gray-100",
                            "transition-[width] w-24 h-24 hover:w-36"
                          )}>
                          {document.thumbnail_url && (
                            <img
                              src={document.thumbnail_url}
                              className="relative z-10 h-full w-full object-cover"
                            />
                          )}

                          <p className="absolute inset-x-0 bottom-1 z-10 mx-1 max-w-fit truncate rounded bg-white/80 p-1 text-[10px] font-medium leading-none">
                            {document.file_name}
                          </p>

                          <div className="absolute inset-0 flex items-center justify-center">
                            <File weight="thin" size={36} className="text-gray-500" />
                          </div>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              )}

              {/* Objects */}
              {task.objects.length > 0 && (
                <div>
                  <SectionHeading>{i18n.t("common:asset", { count: 2 })}</SectionHeading>

                  {task.objects.map((object: any) => (
                    <div key={object.id} className="flex items-center gap-3">
                      <div className="relative aspect-square h-16 w-16 overflow-hidden rounded border bg-gray-100">
                        {object.avatar && object.avatar.thumbnail_url && (
                          <img
                            src={object.avatar.thumbnail_url}
                            className="relative z-10 h-full w-full object-cover"
                          />
                        )}
                        <div className="absolute inset-0 flex items-center justify-center">
                          <Cube size={24} className="text-gray-500" />
                        </div>
                      </div>
                      <div className="leading-snug">
                        <p className="text-xs text-gray-500">{object.public_id}</p>
                        <p className="font-medium">{object.name}</p>
                        {object.group && (
                          <div className="mt-0.5 flex items-center gap-1 text-gray-700">
                            <Swatches size={14} />
                            <p className="text-xs font-medium">{object.group}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Right Column */}
            <div className="col-span-1 space-y-4">
              {/* Team */}
              {task.teams.length > 0 && (
                <div className="-mb-1">
                  <SectionHeading>{i18n.t("common:team", { count: 2 })}</SectionHeading>
                  <div className="-ml-1 inline-flex scale-90">
                    {task.teams.map((team) => (
                      <TeamTag key={team.id} team={team} />
                    ))}
                  </div>
                </div>
              )}

              {/* Assignees */}
              {task.assignees.length > 0 && (
                <div>
                  <SectionHeading>
                    {i18n.t("tasks:fields.assignee", { count: 2 })}
                  </SectionHeading>
                  <div className="-ml-2 inline-flex scale-90">
                    {task.assignees.map((assignee) => (
                      <UserTag key={assignee.id} user={{ ...assignee, avatar: null }} />
                    ))}
                  </div>
                </div>
              )}

              {/* Collaborators */}
              {task.collaborators.length > 0 && (
                <div>
                  <SectionHeading>
                    {i18n.t("tasks:fields.collaborator", { count: 2 })}
                  </SectionHeading>
                  <div className="-ml-2 inline-flex scale-90">
                    {task.collaborators.map((collaborator) => (
                      <UserTag
                        key={collaborator.id}
                        user={{ ...collaborator, avatar: null }}
                      />
                    ))}
                  </div>
                </div>
              )}

              {/* Due Date */}
              {task.due_date && (
                <div>
                  <SectionHeading>{i18n.t("tasks:fields.due_date")}</SectionHeading>
                  <p className="text-sm">{format(new Date(task.due_date), "PPPp")}</p>
                </div>
              )}
            </div>
          </div>

          {/* Checklist */}
          {task.checklist && (
            <>
              <hr />

              <div className="max-w-lg">
                <SectionHeading>{i18n.t("tasks:fields.checklist")}</SectionHeading>

                <div className="mt-4 grid gap-4">
                  <ChecklistElements checklist={task.checklist} />
                </div>
              </div>
            </>
          )}

          {/* Comments */}
          {task.comments.length > 0 && (
            <>
              <hr />

              <div className="max-w-lg">
                <SectionHeading>
                  {i18n.t("tasks:fields.comment", { count: 2 })}
                </SectionHeading>
                <div className="mt-2 grid gap-5">
                  {task.comments.map((comment) => (
                    <div key={comment.id}>
                      <StaticRichText
                        content={comment.content}
                        className="rounded-lg border border-gray-200/50 bg-gray-50 px-4 py-3"
                      />
                      <div className="mt-2 inline-flex scale-90 items-center">
                        <UserTag user={{ ...comment.createdBy, avatar: null }} />

                        <p className="ml-3 text-sm opacity-75">
                          {format(new Date(comment.created_at), "Pp")}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <p className="text-center text-xs">
        Powered by <img className="ml-1 inline pb-0.5" src="/logo.svg" width={64} />
      </p>
    </div>
  )
}

export default PublicTaskDetailPage
