import { Button, TextArea, useConfirmModal } from "@components/shared"
import { DialogRootProps } from "@components/shared/dialog"
import { DialogForm } from "@components/shared/dialog-form"
import { FormField } from "@components/shared/form/form-field"
import { SelectPopover } from "@components/shared/single-select"
import { TextInput } from "@components/shared/text-input"
import {
  useContactsQuery,
  useDeleteContactMutation,
  useUpsertContactMutation,
} from "@graphql/documents/contact.generated"
import { IContactFragment } from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { unique } from "@utils"
import { useState } from "react"
import * as Yup from "yup"

type FormValues = Partial<IContactFragment>

type Props = {
  initialValues?: FormValues
} & Required<Pick<DialogRootProps, "isOpen" | "onOpenChange">>

const ContactSchema = Yup.object().shape({
  company: Yup.string().required(
    i18n.t("common:forms.is_required", {
      field: i18n.t("contacts:fields.company_name"),
    })
  ),
  name: Yup.string().required(
    i18n.t("common:forms.is_required", { field: i18n.t("common:name") })
  ),
  position: Yup.string().nullable(),
  note: Yup.string().nullable(),
  email: Yup.string().nullable(),
  phone: Yup.string().nullable(),
  address: Yup.string().nullable(),
})

const CreateEditContactFormDialog: React.FC<Props> = (props) => {
  const [newCompany, setNewCompany] = useState("")
  const newCompanyValue = { value: newCompany, label: newCompany, searchValue: newCompany }

  const [contactsRes] = useContactsQuery({ requestPolicy: "cache-only" })
  const contacts = contactsRes.data?.contact || []
  const companies = unique(
    contacts
      .map((c) => ({ label: c.company, value: c.company, searchValue: c.company }))
      .sort((a, b) => a.label.localeCompare(b.label))
  )

  const deleteModal = useConfirmModal({
    title: i18n.t("common:delete_token", { token: i18n.t("common:contact", { count: 1 }) }),
    content: i18n.t("contacts:dialogs.delete.content"),
    okText: i18n.t("common:delete"),
    cancelText: i18n.t("common:cancel"),
    onOk: async () => {
      if (props.initialValues && props.initialValues.id) {
        await deleteContact({ id: props.initialValues.id })
        props.onOpenChange(false)
      }
    },
  })

  const [, upsertContact] = useUpsertContactMutation()
  const [, deleteContact] = useDeleteContactMutation()

  const onSubmit = async (values: FormValues) => {
    await upsertContact({
      data: {
        id: values.id ?? undefined,
        name: values.name,
        position: values.position,
        company: values.company,
        note: values.note,
        email: values.email,
        phone: values.phone,
        address: values.address,
      },
    })
  }

  const onDelete = () => deleteModal.show()

  return (
    <>
      <DialogForm<FormValues>
        {...props}
        closeIcon
        footerActions={
          props.initialValues?.id ? (
            <Button color="red" type="tertiary" className="mr-auto" onClick={onDelete}>
              {i18n.t("common:delete")}
            </Button>
          ) : null
        }
        title={
          props.initialValues?.id
            ? i18n.t("common:edit_token", { token: i18n.t("common:contact", { count: 1 }) })
            : i18n.t("common:create_token", {
                token: i18n.t("common:contact", { count: 1 }),
              })
        }
        formikConfig={{
          onSubmit,
          validationSchema: ContactSchema,
          initialValues: props.initialValues ?? {},
        }}>
        {(_formik) => (
          <div className="my-4 grid grid-cols-2 gap-x-4">
            <FormField
              name="company"
              label={i18n.t("contacts:fields.company")}
              className="col-span-2">
              {({ field, helpers }) => (
                <SelectPopover
                  items={
                    newCompany.length > 0 ? companies.concat(newCompanyValue) : companies
                  }
                  searchPlaceholder={i18n.t("contacts:messages.create_or_select_company")}
                  value={field.value}
                  placeholder={i18n.t("contacts:messages.create_or_search_company")}
                  onChange={(value) => helpers.setValue(value)}
                  onCreate={async (value) => {
                    setNewCompany(value)
                    return Promise.resolve(value)
                  }}
                />
              )}
            </FormField>

            <FormField name="name" label={i18n.t("contacts:fields.name")}>
              <TextInput required />
            </FormField>

            <FormField name="position" label={i18n.t("contacts:fields.position")} optional>
              <TextInput />
            </FormField>

            <FormField
              name="email"
              label={i18n.t("contacts:fields.email")}
              optional
              className="col-span-2">
              <TextInput type="email" />
            </FormField>

            <FormField
              name="phone"
              label={i18n.t("contacts:fields.phone")}
              optional
              className="col-span-2">
              <TextInput type="tel" />
            </FormField>

            <FormField
              name="address"
              label={i18n.t("contacts:fields.address")}
              optional
              className="col-span-2">
              <TextInput />
            </FormField>

            <FormField
              name="note"
              label={i18n.t("contacts:fields.note")}
              className="col-span-2"
              optional>
              <TextArea />
            </FormField>
          </div>
        )}
      </DialogForm>
      {deleteModal.component}
    </>
  )
}

export default CreateEditContactFormDialog
