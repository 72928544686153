import { UserSingleSelect } from "@components/shared"
import { DialogForm } from "@components/shared/dialog-form"
import { FormField } from "@components/shared/form/form-field"
import { DateTimeNative } from "@components/shared/native-date-time-pickers"
import toast from "@components/shared/toast"
import { useAnalytics } from "@contexts/analytics-context"
import { IPermissionScopeEnum, uuid } from "@elara/db"
import { useUpdateWorkOrderPartiallyMutation } from "@graphql/documents/work-order.generated"
import { usePermissionScope } from "@hooks"
import i18n from "@i18n"
import * as yup from "yup"

interface WorkOrderFormValues {
  completedAt: Date
  completedById: uuid
}

type WorkOrderCompletedEditFormProps = {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  workOrderId: uuid
  completedAt: string
  completedById: uuid
  autoFocus?: string
}

export const WorkOrderCompletedEditForm = (props: WorkOrderCompletedEditFormProps) => {
  const { isOpen } = props
  const [, updateWorkOrderPartiallyMutation] = useUpdateWorkOrderPartiallyMutation()
  const editScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)
  const { posthog } = useAnalytics()

  return (
    <DialogForm
      title={i18n.t("tasks:dialogs.change_completed_time.title")}
      className="!max-w-[600px]"
      isOpen={isOpen}
      closeIcon
      onOpenChange={props.onOpenChange}
      formikConfig={{
        initialValues: {
          completedAt: new Date(props.completedAt),
          completedById: props.completedById,
        },
        validationSchema: yup.object().shape({
          completedAt: yup
            .date()
            .max(
              new Date(),
              i18n.t("tasks:dialogs.change_completed_time.errors.time_in_future")
            )
            .nullable()
            .required(i18n.t("tasks:dialogs.change_completed_time.errors.required")),
          completedById: yup.string().required(),
        }),
        onSubmit: async (values: WorkOrderFormValues) => {
          const result = await updateWorkOrderPartiallyMutation(
            {
              id: props.workOrderId,
              changes: {
                completed_at: values.completedAt.toISOString(),
                completed_by_id: values.completedById,
              },
            },
            editScope.context()
          )
          if (
            values.completedById !== props.completedById ||
            values.completedAt.getTime() !== new Date(props.completedAt).getTime()
          ) {
            posthog?.capture("edit_work_order", { isDoneTimeEdit: true })
          }

          if (result.error) {
            toast.error(
              i18n.t("common:messages.token_update_failure", {
                token: i18n.t("common:entry", { count: 1 }),
              })
            )
          }
        },
      }}>
      {() => (
        <div className="px-3 py-4">
          <FormField
            label={i18n.t("tasks:dialogs.change_completed_time.completed_on")}
            name="completedAt"
            optional>
            {({ field, helpers, hasError }) => (
              <DateTimeNative
                {...field}
                clearable
                hasError={hasError}
                onChange={helpers.setValue}
                autoFocus={props.autoFocus === "completedAt"}
                value={field.value === null ? null : new Date(field.value)}
              />
            )}
          </FormField>
          <FormField
            label={i18n.t("tasks:dialogs.change_completed_time.completed_by")}
            name="completedById"
            optional>
            {({ field, helpers }) => (
              <UserSingleSelect {...field} onChange={helpers.setValue} />
            )}
          </FormField>
        </div>
      )}
    </DialogForm>
  )
}
