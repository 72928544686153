import { ISearchEverywhereQuery } from "@graphql/documents/search.generated"
import { useEffect } from "react"
import { create } from "zustand"

type CommandDefBase = {
  value: string
  description: string
  group: string
  icon?: React.ReactNode
  searchValue: string
  label?: React.ReactNode
  searchLabel?: React.ReactNode
}

export type ActionCommandDef = {
  type: "action"
  meta?: {
    task?: ISearchEverywhereQuery["search_work_order"][number]
    object?: ISearchEverywhereQuery["search_asset"][number]
    maintenance?: ISearchEverywhereQuery["search_maintenance"][number]
  }
  action: () => void
} & CommandDefBase

export type PageCommandDef = {
  type: "page"
  page: string
  subcommands: (searchResults: ActionCommandDef[]) => ActionCommandDef[]
} & CommandDefBase

export type CommandDef = PageCommandDef | ActionCommandDef

interface CommandStore {
  commands: Record<string, CommandDef[]>
  registerCommands: (key: string, commands: CommandDef[]) => void
  unregisterCommands: (key: string) => void
}

export const useCommandStore = create<CommandStore>((set) => ({
  commands: { global: [] },
  registerCommands: (key, commands) => {
    set((state) => {
      state.commands[key] = commands
      return state
    })
  },
  unregisterCommands: (key) => {
    set((state) => {
      delete state.commands[key]
      return state
    })
  },
}))

export const useRegisterCommands = (
  key: string,
  commands: CommandDef[],
  deps: unknown[] = []
) => {
  const registerCommands = useCommandStore((state) => state.registerCommands)
  const unregisterCommands = useCommandStore((state) => state.unregisterCommands)

  useEffect(() => {
    registerCommands(key, commands)

    return () => {
      unregisterCommands(key)
    }
  }, deps)
}
