import toast from "@components/shared/toast"
import { uuid } from "@elara/db"
import {
  AddBlockElementUploadDocument,
  DeleteBlockElementUploadDocument,
  IAddBlockElementUploadMutation,
  IAddBlockElementUploadMutationVariables,
  IDeleteBlockElementUploadMutation,
  IDeleteBlockElementUploadMutationVariables,
} from "@graphql/documents/block.generated"
import { IUploadDataFragment } from "@graphql/documents/upload.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { useClient } from "urql"

export const useBlockPermission = () => {
  const dataEntryScope = usePermissionScope(IPermissionScopeEnum.AppDataEntry)
  const editScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)

  return editScope.hasScope ? editScope : dataEntryScope
}

export const useResponseUploads = (
  elementId: uuid,
  options: { blockResponseUpdate?: boolean }
) => {
  const scope = usePermissionScope(IPermissionScopeEnum.AppDataEntry)
  const client = useClient()

  const addUpload = async (upload: IUploadDataFragment) => {
    if (!scope.hasScope) {
      toast.error(i18n.t("common:missing_permission"))
      return
    } else if (options.blockResponseUpdate) {
      toast.error(i18n.t("tasks:messages.filling_not_allowed"))
      return
    }
    await client
      .mutation<IAddBlockElementUploadMutation, IAddBlockElementUploadMutationVariables>(
        AddBlockElementUploadDocument,
        { uploadId: upload.id, elementId },
        scope.context()
      )
      .toPromise()
  }

  const deleteUpload = async (uploadId: string) => {
    if (!scope.hasScope) {
      toast.error(i18n.t("common:missing_permission"))
      return
    } else if (options.blockResponseUpdate) {
      toast.error(i18n.t("tasks:messages.filling_not_allowed"))
      return
    }
    await client
      .mutation<
        IDeleteBlockElementUploadMutation,
        IDeleteBlockElementUploadMutationVariables
      >(DeleteBlockElementUploadDocument, { uploadId, elementId }, scope.context())
      .toPromise()
  }

  return { addUpload, deleteUpload, scope }
}
