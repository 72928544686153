import MeterType from "@components/asset/asset-meter/asset-meter-type"
import { AssetMeterMaintenanceList } from "@components/asset/asset-meter-maintenance-list"
import { AssetMeterReadingList } from "@components/asset/asset-meter-reading-list"
import { AssetWithParents } from "@components/asset/asset-with-parents"
import {
  MeterActionMenu,
  useMeterActions,
} from "@components/asset/detail/meter/meter-action-menu"
import { HStack } from "@components/layout"
import { Button, useConfirmModal } from "@components/shared"
import { Collapse, CollapsePanel } from "@components/shared/collapse"
import Tabs from "@components/shared/horizontal-tabs"
import LoadingSpinner from "@components/shared/loading-spinner"
import { useBreakpoint } from "@contexts/breakpoints"
import { usePage } from "@contexts/page-context"
import { IMeterTypeEnum, uuid } from "@elara/db"
import {
  IAssetMeterDetailsMeterFragment,
  useAssetMeterDetailsQuery,
  useScheduledTaskQuery,
} from "@graphql/documents/asset-meter.generated"
import i18n from "@i18n"
import { Plus } from "@phosphor-icons/react"
import { useEffect } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"

const ScheduledTaskTab = (props: { meterId: uuid; emptyPlaceholder: React.ReactNode }) => {
  const [scheduledTaskQueryRes] = useScheduledTaskQuery({
    variables: { meterId: props.meterId! },
    pause: !props.meterId,
    requestPolicy: "cache-and-network",
  })
  const scheduledTask = scheduledTaskQueryRes?.data?.scheduled_task

  if (!scheduledTask) return <LoadingSpinner />
  if (!scheduledTask?.length) return <>{props.emptyPlaceholder}</>
  return <AssetMeterMaintenanceList data={scheduledTask} />
}

const ReadingsHistory = (props: { meter: IAssetMeterDetailsMeterFragment }) => {
  const bp = useBreakpoint()
  const navigate = useNavigate()
  const location = useLocation()

  const { meter } = props

  const {
    setReadingMeterId,
    onDeleteMeter,
    onEditMeter,
    onAddReading,
    onEditReading,
    onDeleteMeterReading,
    addMeterDialog,
    addReadingDialog,
  } = useMeterActions(meter.asset_id)

  usePage({
    id: "meters",
    title: "Zählerwerte",
    isSubPage: true,
  })

  useEffect(() => {
    setReadingMeterId(meter.id)
  }, [])

  const deleteModal = useConfirmModal({
    title: i18n.t("common:delete_token", { token: meter?.name }),
    content: i18n.t("common:messages.token_delete_confirmation", {
      token: i18n.t("common:meter", { count: 1 }),
    }),
    okText: i18n.t("common:delete"),
    cancelText: i18n.t("common:cancel"),
    onOk: () => {
      onDeleteMeter(meter?.id!)
      navigate(-1)
    },
  })

  if (!meter) return <LoadingSpinner />

  return (
    <div className="flex min-h-0 flex-1 flex-col">
      <div className="flex flex-col space-y-2 p-3">
        <div className="inline-flex">
          <AssetWithParents
            withLink
            showAvatar
            asset={meter.asset}
            linkToSubpage="meters"
          />
        </div>
        <HStack justify="space-between">
          <span className="grow-0">
            <span className="line-clamp-2 text-base font-semibold sm:text-lg">
              {meter?.name}
            </span>{" "}
          </span>

          <div className="flex gap-x-3">
            <Button
              type="primary"
              size="small"
              icon={Plus}
              onClick={() => onAddReading(meter?.id!)}>
              {i18n.t("meters:fields.reading", { count: 1 })}
            </Button>

            <MeterActionMenu
              onEdit={() => onEditMeter(meter!)}
              onDelete={deleteModal.show}
            />
          </div>
        </HStack>

        <Collapse defaultOpenPanels={bp.renderMobile ? [] : ["collapse"]}>
          <CollapsePanel
            headerClassName="text-sm font-medium text-gray-600 hover:text-gray-900"
            header={i18n.t("meters:fields.details")}
            name="collapse">
            <dl className="mt-2 grid max-w-2xl grid-cols-1 gap-4 sm:grid-cols-3">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {i18n.t("meters:fields.unit")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">{meter.unit}</dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  {i18n.t("meters:fields.configuration")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {meter.meter_type ? (
                    <MeterType type={meter.meter_type} />
                  ) : (
                    i18n.t("common:no_token", { token: i18n.t("meters:fields.type") })
                  )}
                </dd>
              </div>
              {meter.meter_type === IMeterTypeEnum.Measurement && (
                <>
                  {meter.is_deviation ? (
                    <>
                      {meter.norm_value !== null && (
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            {i18n.t("meters:fields.standard_value")}
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {meter.norm_value} {meter.unit}
                          </dd>
                        </div>
                      )}
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          {i18n.t("meters:fields.tolerance")}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {meter.range_start} / +{meter.range_end} {meter.unit}
                        </dd>
                      </div>
                    </>
                  ) : (
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        {i18n.t("meters:fields.tolerance_range")}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {meter.range_start} - {meter.range_end} {meter.unit}
                      </dd>
                    </div>
                  )}
                  {meter.template_id && (
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        {i18n.t("meters:labels.deviation_tasks.templates")}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        <Link
                          to={"/task/template/" + meter.template?.id}
                          className="-mx-1 rounded p-1 hover:bg-gray-100 hover:text-gray-900 hover:ring-1 hover:ring-gray-400">
                          # {meter.template?.work_order.work_order_number} -{" "}
                          {meter.template?.name}
                        </Link>
                      </dd>
                    </div>
                  )}
                </>
              )}
              {meter.meter_type === IMeterTypeEnum.Meter && meter.template_id && (
                <>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      {i18n.t("meters:labels.task_interval.title")}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {i18n.t("meters:labels.task_interval.message", {
                        unit: meter.unit,
                        interval: meter.interval,
                        firstInterval: meter.first_interval_task_at,
                      })}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      {i18n.t("meters:labels.regular_tasks.templates")}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <Link
                        to={"/task/template/" + meter.template?.id}
                        className="-mx-1 rounded p-1 hover:bg-gray-100 hover:text-gray-900 hover:ring-1 hover:ring-gray-400">
                        # {meter.template?.work_order.work_order_number} -{" "}
                        {meter.template?.name}
                      </Link>
                    </dd>
                  </div>
                </>
              )}
              {meter.description && (
                <div className="sm:col-span-2">
                  <dt className="text-sm font-medium text-gray-500">
                    {i18n.t("common:description")}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">{meter.description}</dd>
                </div>
              )}
            </dl>
          </CollapsePanel>
        </Collapse>
      </div>

      {addMeterDialog}
      {addReadingDialog}
      {deleteModal.component}

      <div className="flex min-h-0 flex-1 flex-col">
        <Tabs.Root
          defaultValue="history"
          orientation="horizontal"
          className="flex min-h-0 flex-1 flex-col"
          onValueChange={(val) => navigate(val, { replace: true })}
          value={location.pathname.split("/")[5] || "history"}>
          <Tabs.List>
            <Tabs.Trigger value="history">
              {i18n.t("meters:fields.reading", { count: 2 })}
            </Tabs.Trigger>
            {meter.meter_type && (
              <Tabs.Trigger value="tasks">
                {meter.meter_type === IMeterTypeEnum.Measurement
                  ? i18n.t("meters:labels.deviation_tasks.task_list")
                  : i18n.t("meters:labels.regular_tasks.task_list")}
              </Tabs.Trigger>
            )}
          </Tabs.List>

          <Tabs.Content value="history">
            <AssetMeterReadingList
              meter={meter}
              data={meter?.meter_readings ?? null}
              onEdit={(reading) => onEditReading(reading)}
              onDelete={onDeleteMeterReading}
            />
          </Tabs.Content>

          <Tabs.Content value="tasks">
            <ScheduledTaskTab
              meterId={meter.id}
              emptyPlaceholder={
                <div className="mx-auto mt-8 max-w-sm text-center font-medium">
                  {meter.meter_type === IMeterTypeEnum.Measurement
                    ? i18n.t("meters:labels.deviation_tasks.empty_state")
                    : i18n.t("meters:labels.regular_tasks.empty_state", {
                        unit: meter.unit,
                        firstInterval: meter.first_interval_task_at,
                      })}
                </div>
              }
            />
          </Tabs.Content>
        </Tabs.Root>
      </div>
    </div>
  )
}

const MeterDetailPage = () => {
  const { meterId } = useParams()

  const [queryRes] = useAssetMeterDetailsQuery({
    variables: { meterId: meterId! },
    pause: !meterId,
  })
  const meter = queryRes.data?.meter_by_pk

  if (!meter) return <LoadingSpinner />

  return <ReadingsHistory meter={meter} />
}

export default MeterDetailPage
