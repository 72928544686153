import { BlockElementPayload, IBlockElementTypeEnum } from "@elara/db"
import {
  assetStateSchema,
  checkboxSchema,
  choiceSchema,
  consumablesSchema,
  headingSchema,
  initialBlockElementAssetState,
  initialBlockElementCheckbox,
  initialBlockElementChoice,
  initialBlockElementConsumables,
  initialBlockElementHeading,
  initialBlockElementInspection,
  initialBlockElementMedia,
  initialBlockElementMeterReading,
  initialBlockElementParagraph,
  initialBlockElementText,
  initialBlockElementToleranceCheck,
  initialBlockElementYesNo,
  inspectionSchema,
  mediaSchema,
  meterReadingSchema,
  paragraphSchema,
  textFieldSchema,
  toleranceCheckSchema,
  yesNoSchema,
} from "@elara/utils"
import { Schema } from "yup"

export const mapElementTypeToInitialData = (
  type: IBlockElementTypeEnum,
  configOverride?: any
): BlockElementPayload => {
  let result = {}

  switch (type) {
    case IBlockElementTypeEnum.AssetState:
      result = initialBlockElementAssetState()
      break

    case IBlockElementTypeEnum.Checkbox:
      result = initialBlockElementCheckbox()
      break

    case IBlockElementTypeEnum.Choice:
      result = initialBlockElementChoice()
      break

    case IBlockElementTypeEnum.Consumables:
      result = initialBlockElementConsumables()
      break

    case IBlockElementTypeEnum.Heading:
      result = initialBlockElementHeading()
      break

    case IBlockElementTypeEnum.Inspection:
      result = initialBlockElementInspection()
      break

    case IBlockElementTypeEnum.Media:
      result = initialBlockElementMedia()
      break

    case IBlockElementTypeEnum.MeterReading:
      result = initialBlockElementMeterReading()
      break

    case IBlockElementTypeEnum.Paragraph:
      result = initialBlockElementParagraph()
      break

    case IBlockElementTypeEnum.Text:
      result = initialBlockElementText()
      break

    case IBlockElementTypeEnum.ToleranceCheck:
      result = initialBlockElementToleranceCheck()
      break

    case IBlockElementTypeEnum.YesNo:
      result = initialBlockElementYesNo()
      break
  }

  if (configOverride === undefined) return result as BlockElementPayload

  return { ...result, config: configOverride } as BlockElementPayload
}

export const mapElementTypeToSchema = (type: IBlockElementTypeEnum): Schema | undefined => {
  switch (type) {
    case IBlockElementTypeEnum.AssetState:
      return assetStateSchema({ isTemplate: false })

    case IBlockElementTypeEnum.Checkbox:
      return checkboxSchema

    case IBlockElementTypeEnum.Choice:
      return choiceSchema

    case IBlockElementTypeEnum.Consumables:
      return consumablesSchema

    case IBlockElementTypeEnum.Heading:
      return headingSchema

    case IBlockElementTypeEnum.Inspection:
      return inspectionSchema

    case IBlockElementTypeEnum.Media:
      return mediaSchema

    case IBlockElementTypeEnum.MeterReading:
      return meterReadingSchema({ isTemplate: false })

    case IBlockElementTypeEnum.Paragraph:
      return paragraphSchema

    case IBlockElementTypeEnum.Text:
      return textFieldSchema

    case IBlockElementTypeEnum.ToleranceCheck:
      return toleranceCheckSchema

    case IBlockElementTypeEnum.YesNo:
      return yesNoSchema
  }
}
