import { Tooltip } from "@components/shared/tooltip"
import { Icon as PhosphorIcon } from "@phosphor-icons/react"
import { cn } from "@utils"
import React from "react"
import { Icon } from "src/types"

import LoadingIndicator from "./loading-indicator"
import TouchTargetSize from "./touch-target-size"

type ButtonSize = "extra-small" | "small"
type ButtonType = "primary" | "secondary" | "tertiary"
type ButtonColor = "blue" | "gray" | "red"
type HTMLButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>
export type ButtonProps = Omit<HTMLButtonProps, "type" | "color" | "ref"> & {
  htmlType?: HTMLButtonProps["type"]
  color?: ButtonColor
  type?: ButtonType
  size?: ButtonSize
  isLoading?: boolean
  icon?: Icon | PhosphorIcon
  disabledReason?: string
  tooltip?: string
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size = "small",
      type = "primary",
      color = type === "secondary" ? "gray" : "blue",
      htmlType = "button",
      icon,
      children,
      isLoading,
      disabledReason,
      tooltip,
      ...props
    },
    forwardedRef
  ) => {
    let iconSize = 16

    if (size === "extra-small") {
      iconSize = 14
    }

    const ButtonComponent = (
      <button
        {...props}
        type={htmlType}
        ref={forwardedRef}
        className={cn(
          "relative rounded-md cursor-pointer select-none inline-flex items-center justify-center box-border print:hidden",
          "font-medium border transition-colors disabled:opacity-60 disabled:cursor-not-allowed focus:outline-none truncate",
          {
            "h-8 px-2.5 text-xs": size === "extra-small",
            "h-8 px-3 text-sm": size === "small",
            "bg-blue-500 text-white hover:bg-blue-700 radix-state-open:bg-blue-700 border-blue-500 shadow-sm focus-visible:ring-1 ring-offset-1 focus-visible:ring-blue-500":
              type === "primary" && color === "blue",
            "bg-gray-100 text-gray-700 border-gray-200 hover:text-gray-900 radix-state-open:text-gray-900 hover:bg-gray-200 radix-state-open:bg-gray-200 focus-visible:ring-1 ring-offset-1 focus-visible:ring-gray-500":
              type === "primary" && color === "gray",
            "bg-red-500 text-white hover:bg-red-700 radix-state-open:bg-red-700 border-red-500 shadow-sm focus-visible:ring-2 ring-offset-2 focus-visible:ring-red-500":
              type === "primary" && color === "red",
            "border-blue-300 text-blue-600 focus-visible:border-blue-400 hover:bg-blue-100  hover:border-blue-400 radix-state-open:bg-blue-100 radix-state-open:border-blue-400":
              type === "secondary" && color === "blue",
            "border-gray-300 bg-white text-gray-600 focus-visible:border-gray-400 hover:bg-gray-100  hover:border-gray-400  radix-state-open:bg-gray-100 radix-state-open:border-gray-400":
              type === "secondary" && color === "gray",
            "border-red-300 text-red-600 focus-visible:border-red-400 hover:bg-red-100  hover:border-red-400 radix-state-open:bg-red-100 radix-state-open:border-red-400":
              type === "secondary" && color === "red",
            "border-transparent text-gray-600 hover:bg-gray-100 radix-state-open:bg-gray-100 hover:text-gray-800 radix-state-open:text-gray-800 focus-visible:ring-current focus-visible:ring-1 ring-current-color":
              type === "tertiary" && color === "gray",
            "border-transparent text-blue-600 hover:bg-gray-100 radix-state-open:bg-gray-100 hover:text-blue-800 radix-state-open:text-blue-800 focus-visible:ring-current focus-visible:ring-1 ring-current-color":
              type === "tertiary" && color === "blue",
            "border-transparent text-red-600 hover:bg-gray-100 radix-state-open:bg-gray-100 hover:text-red-800 radix-state-open:text-red-800 focus-visible:ring-current focus-visible:ring-1 ring-current-color":
              type === "tertiary" && color === "red",
            "": type === "primary",
            "!px-0 w-8 justify-center rounded": icon && !children,
          },
          props.className
        )}>
        {icon && !isLoading && (
          <span className={cn({ "-ml-0.5 mr-1 inline-flex items-center": !!children })}>
            {React.createElement(icon, {
              size: iconSize,
              width: iconSize,
              height: iconSize,
            })}
          </span>
        )}

        {isLoading && (
          <div
            style={{ height: iconSize }}
            className={cn("-ml-0.5", { "mr-1": !!children })}>
            <LoadingIndicator size={iconSize} />
          </div>
        )}

        {children}
        <TouchTargetSize horizontal vertical />
      </button>
    )

    if (tooltip) {
      return (
        <Tooltip content={tooltip} asChild contentProps={{ side: "bottom", sideOffset: 8 }}>
          {ButtonComponent}
        </Tooltip>
      )
    }

    if (disabledReason && props.disabled) {
      return (
        <Tooltip
          content={disabledReason}
          asChild
          contentProps={{ side: "bottom", sideOffset: 8 }}>
          <span tabIndex={0} className="cursor-not-allowed">
            {ButtonComponent}
          </span>
        </Tooltip>
      )
    }

    return ButtonComponent
  }
)

export default Button
