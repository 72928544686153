import {
  IMutationRoot,
  IMutationRootDeleteServiceRequestByPkArgs,
  IMutationRootInsertServiceRequestOneArgs,
  IMutationRootUpdateServiceRequestByPkArgs,
} from "@elara/db"
import {
  IServiceRequestFragment,
  ServiceRequestFragmentDoc,
} from "@graphql/documents/fragments.generated"
import {
  IServiceRequestsQuery,
  ServiceRequestsDocument,
} from "@graphql/documents/service-request.generated"
import { OptimisticMutationResolver, UpdateResolver } from "@urql/exchange-graphcache"

type Mutations = Partial<Record<keyof IMutationRoot, UpdateResolver>>
type Optimistic = Partial<Record<keyof IMutationRoot, OptimisticMutationResolver>>

const optimistic: Optimistic = {
  update_service_request_by_pk(args: IMutationRootUpdateServiceRequestByPkArgs, cache) {
    const id = args.pk_columns.id
    const request = cache.readFragment(ServiceRequestFragmentDoc, { id })

    return { __typename: "service_request", ...request, ...args._set }
  },
}

const mutations: Mutations = {
  insert_service_request_one(
    result: { insert_service_request_one: IServiceRequestFragment },
    _args: IMutationRootInsertServiceRequestOneArgs,
    cache
  ) {
    const service_request = result.insert_service_request_one

    if (!service_request) return

    cache.updateQuery<IServiceRequestsQuery>({ query: ServiceRequestsDocument }, (data) => {
      if (!data || !data.service_request) return data

      if (!data.service_request.some((t) => t.id === service_request?.id)) {
        data.service_request.push(service_request)
        data.service_request.sort((a, b) => a.title.localeCompare(b.title))
      }

      return data
    })
  },
  delete_service_request_by_pk(
    _parent,
    args: IMutationRootDeleteServiceRequestByPkArgs,
    cache
  ) {
    cache.updateQuery<IServiceRequestsQuery>({ query: ServiceRequestsDocument }, (data) => {
      if (data) {
        data.service_request = data.service_request.filter((t) => t.id !== args.id)
      }

      return data
    })
  },
}

export default {
  optimistic,
  mutations,
}
