import SettingsPageLayout from "@components/settings/page-layout"
import { ActionMenu, Button } from "@components/shared"
import { alertDialog } from "@components/shared/alert-dialog-provider"
import { CopyClipboard } from "@components/shared/copy-clipboard"
import { Dialog, DialogContentFooter } from "@components/shared/dialog"
import { UserAvatar } from "@components/shared/user-avatar"
import { usePage } from "@contexts/page-context"
import { useUser } from "@contexts/user-context"
import { IPermissionScopeEnum } from "@elara/db"
import { orderBy } from "@elara/select"
import { IUsersTableRowFragment } from "@graphql/documents/fragments.generated"
import {
  ApproveLocationMemberDocument,
  IApproveLocationMemberMutation,
  IApproveLocationMemberMutationVariables,
} from "@graphql/documents/settings.generated"
import {
  IRemoveLocationMemberMutation,
  IRemoveLocationMemberMutationVariables,
  RemoveLocationMemberDocument,
  useResetPasswordMutation,
  UserSelectDataDocument,
} from "@graphql/documents/user.generated"
import { useUsersTableQuery } from "@graphql/documents/workforce.generated"
import { useDisclosure, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import {
  ArrowsClockwise,
  DotsThreeVertical,
  PencilSimpleLine,
  TrashSimple,
} from "@phosphor-icons/react"
import classNames from "classnames"
import { useMemo, useState } from "react"
import { useClient } from "urql"

import { EditUserForm } from "./create-and-edit-form-user"

function ApproveLocationMemberButton(props: { userId: string; locationId: string }) {
  const client = useClient()
  const accountManagementScope = usePermissionScope(
    IPermissionScopeEnum.AppAccountManagement
  )
  const [loading, setLoading] = useState(false)
  return (
    <Button
      className="mt-1"
      size="extra-small"
      isLoading={loading}
      onClick={async () => {
        setLoading(true)
        await client
          .mutation<
            IApproveLocationMemberMutation,
            IApproveLocationMemberMutationVariables
          >(
            ApproveLocationMemberDocument,
            {
              locationId: props.locationId,
              userId: props.userId,
            },
            accountManagementScope.context()
          )
          .toPromise()
        setLoading(false)
      }}>
      {i18n.t("settings:user_management.users.approve_user")}
    </Button>
  )
}

const SettingsUsers = () => {
  usePage({
    isSubPage: true,
    id: "settings/users",
    title: i18n.t("settings:user_management.title"),
  })

  const user = useUser()
  const client = useClient()

  const [, resetPassword] = useResetPasswordMutation()

  const accountManagementScope = usePermissionScope(
    IPermissionScopeEnum.AppAccountManagement
  )
  const usrScope = usePermissionScope(IPermissionScopeEnum.AppUser)
  const context = useMemo(
    () =>
      accountManagementScope.hasScope
        ? accountManagementScope.context()
        : usrScope.context(),
    [accountManagementScope.hasScope]
  )

  const [result, refetchUsers] = useUsersTableQuery({
    variables: { location_id: user.location.id },
    context,
  })
  const users = orderBy(result.data?.location_member ?? [], {
    user: { first_name: "asc", last_name: "asc" },
  })
  const [editUser, setEditUser] = useState<IUsersTableRowFragment | null>(null)
  const [creating, setCreating] = useState<boolean>(false)
  const createUser = useDisclosure({
    onClose: () => {
      setEditUser(null)
      setCreating(false)
      refetchUsers({ requestPolicy: "network-only" })
      client.query(UserSelectDataDocument, {}).toPromise()
    },
  })

  const [tempPassword, setTempPassword] = useState<string | null>(null)
  const tempPasswordDialog = useDisclosure({ onClose: () => setTempPassword(null) })

  return (
    <SettingsPageLayout
      title={i18n.t("settings:user_management.title")}
      description={i18n.t("settings:user_management.description")}
      buttonText={i18n.t("common:new_token", {
        context: "male",
        token: i18n.t("common:employee", { count: 1 }),
      })}
      buttonProps={{
        onClick: () => {
          setCreating(true)
          setEditUser(null)
          createUser.onOpen()
        },
      }}>
      <div className="grid grid-cols-12 text-sm">
        {users.map((u) => (
          <div
            key={u.user_id}
            className="col-span-12 grid grid-cols-12 items-center border-b border-gray-100 py-3">
            <div className="col-span-6 flex min-w-0 items-center">
              <UserAvatar user={u.user} className="shrink-0 text-2xl" />
              <div className="mx-3 min-w-0">
                <div
                  className={classNames("font-medium truncate", {
                    "line-through": !!u.user.deleted_at,
                    "opacity-60": !u.approved_at,
                  })}>
                  {u.user.first_name} {u.user.last_name}
                </div>
                <div
                  className={classNames("text-gray-600 truncate", {
                    "line-through": !!u.user.deleted_at,
                    "opacity-60": !u.approved_at,
                  })}>
                  {u.user.email}
                </div>
                {!u.approved_at && (
                  <ApproveLocationMemberButton
                    locationId={user.location.id}
                    userId={u.user_id}
                  />
                )}
              </div>
            </div>
            <div className="col-span-5 flex min-w-0 shrink flex-col items-start truncate pl-2">
              {u.permission_role?.name}
            </div>
            <div className="col-span-1">
              {accountManagementScope.hasScope && !u.user.deleted_at ? (
                <ActionMenu
                  className="flex items-center"
                  items={[
                    {
                      key: "edit",
                      label: i18n.t("edit"),
                      action: () => {
                        setEditUser(u)
                        createUser.onOpen()
                      },
                      icon: <PencilSimpleLine />,
                    },
                    {
                      key: "delete",
                      label: i18n.t("common:remove"),
                      action: () => {
                        alertDialog({
                          danger: true,
                          title: i18n.t(
                            "settings:user_management.users.delete_confirmation.title"
                          ),
                          description: i18n.t(
                            "settings:user_management.users.delete_confirmation.content"
                          ),
                          cancelText: i18n.t("common:cancel"),
                          actionText: i18n.t("common:remove"),
                          onAction: async () => {
                            await client
                              .mutation<
                                IRemoveLocationMemberMutation,
                                IRemoveLocationMemberMutationVariables
                              >(
                                RemoveLocationMemberDocument,
                                { userId: u.user.id, locationId: user.location.id },
                                accountManagementScope.context()
                              )
                              .toPromise()
                            refetchUsers({ requestPolicy: "network-only" })
                          },
                        })
                      },
                      icon: <TrashSimple />,
                    },
                    {
                      key: "reset",
                      label: i18n.t("settings:user_management.reset_password"),
                      action: async () => {
                        const res = await resetPassword(
                          { userId: u.user_id },
                          accountManagementScope.context()
                        )
                        const pw = res.data?.resetUserPassword?.password
                        if (pw) {
                          setTempPassword(pw)
                          tempPasswordDialog.onOpen()
                        }
                      },
                      icon: <ArrowsClockwise size={16} />,
                    },
                  ]}>
                  <Button type="tertiary" icon={DotsThreeVertical} />
                </ActionMenu>
              ) : (
                <div />
              )}
            </div>
          </div>
        ))}
      </div>

      <EditUserForm
        isOpen={createUser.isOpen}
        onOpenChange={createUser.changeOpen}
        user={editUser}
        isCreate={creating}
        onCreateUser={(newPassword) => {
          setTempPassword(newPassword)
          tempPasswordDialog.onOpen()
        }}
      />

      <Dialog
        contentAsChild
        title={i18n.t("settings:user_management.new_single_use_password")}
        isOpen={tempPasswordDialog.isOpen}
        onOpenChange={tempPasswordDialog.changeOpen}>
        <div className="space-y-4 py-4 text-sm">
          <p>
            {i18n.t("settings:user_management.new_single_use_password_description_first")}
          </p>
          <div className="mt-4 flex items-center justify-center">
            <code className="mr-3">{tempPassword}</code>{" "}
            <CopyClipboard text={tempPassword!} />
          </div>
          <p className="text-gray-600">
            {i18n.t("settings:user_management.new_single_use_password_description_second")}
          </p>
        </div>
        <DialogContentFooter>
          <Button type="primary" onClick={tempPasswordDialog.onClose} autoFocus>
            {i18n.t("common:ok")}
          </Button>
        </DialogContentFooter>
      </Dialog>
    </SettingsPageLayout>
  )
}

export default SettingsUsers
