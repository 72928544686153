import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group"
import { cn } from "@utils"
import React from "react"

export const ToggleGroup = React.forwardRef<
  HTMLDivElement,
  | ToggleGroupPrimitive.ToggleGroupSingleProps
  | ToggleGroupPrimitive.ToggleGroupMultipleProps
>((props, forwardedRef) => (
  <ToggleGroupPrimitive.Root
    {...props}
    ref={forwardedRef}
    className={cn(
      "inline-flex bg-gray-200 rounded shadow-sm space-x-px isolate text-sm border border-gray-300 ",
      props.className
    )}
  />
))

export const ToggleGroupItem = React.forwardRef<
  HTMLButtonElement,
  ToggleGroupPrimitive.ToggleGroupItemProps
>((props, forwardedRef) => (
  <ToggleGroupPrimitive.Item
    {...props}
    ref={forwardedRef}
    className={cn(
      "hover:bg-gray-100 grow px-2 text-gray-700 text-sm font-medium data-[state=on]:bg-gray-100 data-[state=on]:ring-gray-600 data-[state=on]:ring-1 z-10 data-[state=on]:text-gray-700 flex h-8 box-border items-center justify-center bg-gray-50 leading-4 first:rounded-l last:rounded-r focus-visible:z-10 focus-visible:shadow-[0_0_0_1px] focus-visible:shadow-black focus:outline-none",
      props.className
    )}
  />
))
