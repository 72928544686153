import { LoadingIndicator } from "@components/shared"
import Button from "@components/shared/button"
import i18n from "@i18n"
import TaskIdChecklist from "@pages/new-task.$id.checklist/route"
import TaskComments from "@pages/new-task.$id.comments/route"
import TaskIdDetails from "@pages/new-task.$id.details/route"
import TaskReport from "@pages/new-task.$id.report/route"
import { Chat, Info, ListChecks, Notepad, X } from "@phosphor-icons/react"
import { Portal } from "@radix-ui/react-portal"
import { useEffect } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"

export default function TaskPrintPage(props: { template?: boolean }) {
  const navigate = useNavigate()
  const location = useLocation()

  const sections = [
    { component: <TaskIdDetails />, label: i18n.t("tasks:header.details"), icon: <Info /> },
    {
      component: <TaskIdChecklist />,
      label: <span>{i18n.t("tasks:header.checklist")}</span>,
      icon: <ListChecks />,
    },
    ...(!props.template
      ? [
          {
            component: <TaskReport />,
            label: i18n.t("tasks:header.report"),
            icon: <Notepad />,
          },
          {
            component: <TaskComments />,
            label: i18n.t("tasks:header.comments"),
            icon: <Chat />,
          },
        ]
      : []),
  ]

  useEffect(() => {
    const afterPrint = () => {
      navigate("details", { replace: true, state: location.state })
    }

    window.addEventListener("afterprint", afterPrint)

    setTimeout(() => window.print(), 300)

    return () => {
      window.removeEventListener("afterprint", afterPrint)
    }
  }, [])

  return (
    <div>
      <Portal>
        <div className="fixed inset-0 flex items-center justify-center bg-white print:hidden">
          <LoadingIndicator size={20} />
          <span className="ml-2 text-sm font-medium text-gray-700">Preparing print...</span>

          <Link
            to="details"
            className="absolute right-2 top-2 rounded-lg p-4 hover:bg-gray-100">
            <X />
          </Link>
        </div>
      </Portal>
      <Button onClick={() => window.print()}>Print</Button>
      <div className="mt-6 space-y-6 pb-20 print:[&>*]:break-inside-avoid">
        {sections.map((section, index) => (
          <div key={index} className={""}>
            <h3
              key={index}
              className="mx-auto w-full max-w-screen-md text-start text-lg font-semibold">
              {section.label}
            </h3>
            {section.component}
          </div>
        ))}
      </div>
    </div>
  )
}
