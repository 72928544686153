import { AssetWithParents } from "@components/asset/asset-with-parents"
import {
  createSchemaStatement,
  generalSelectStateToWhere,
  selectStateToWhere,
} from "@components/new-data-view/data-view-filter/schema"
import { UserTag } from "@components/shared"
import { DataView, DataViewProps } from "@components/shared/data-view/data-view"
import { createSchema } from "@components/shared/data-view/data-view-filter.hooks"
import { Column, DataViewConfiguration } from "@components/shared/data-view/data-view-types"
import { TeamTag } from "@components/shared/team-tag"
import { WorkOrderAssignedEntities } from "@components/work-order/work-order-assigned-entities"
import {
  genericAssetGroups,
  genericAssets,
} from "@components/work-order/work-order-data-view-filter-configuration"
import {
  translateWorkOrderPriority,
  WorkOrderPriorityBadge,
} from "@components/work-order/work-order-priority"
import {
  IServiceRequestStatusEnum,
  IViewDataTypeEnum,
  IWorkOrderPriorityEnum,
} from "@elara/db"
import { Where } from "@elara/select"
import { IServiceRequestFragment } from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { CaretDoubleUp, CircleDashed } from "@phosphor-icons/react"
import { naturalCompare, parseDate } from "@utils"
import { formatDate } from "@utils/date"
import { LinkWithBackgroundLocation } from "@utils/location"
import { useNavigate } from "react-router-dom"

import { ServiceRequestStatusTag, translateServiceRequestStatus } from "./status"

type ServiceRequestColumnOptions = {}

export type ServiceRequestColumnIds =
  | "id"
  | "title"
  | "status"
  | "priority"
  | "created_at"
  | "created_by"
  | "assets"
  | "assignees"
  | "assigned_teams"

const columnDefinitions: Column<
  IServiceRequestFragment,
  ServiceRequestColumnIds,
  ServiceRequestColumnOptions
>[] = [
  {
    id: "id",
    key: "id",
    Header: i18n.t("service-request:fields.id"),
    defaultWidth: 80,
    Cell: ({ id }) => id,
    toText: ({ id }) => `${id}`,
    orderBy: (dir) => ({ id: dir }),
  },
  {
    id: "title",
    key: "title",
    Header: i18n.t("service-request:fields.title"),
    defaultWidth: 200,
    Cell: ({ title }) => title,
    toText: ({ title }) => title,
    orderBy: (dir) => ({ title: dir }),
  },
  {
    id: "status",
    key: "status",
    Header: i18n.t("service-request:fields.status"),
    defaultWidth: 150,
    Cell: ({ status }) => <ServiceRequestStatusTag status={status} />,
    toText: ({ status }) => translateServiceRequestStatus(status),
    orderBy: (dir) => ({ status: dir }),
    groupBy: {
      id: ({ status }) => status,
      label: (_id, row) => row?.status && <ServiceRequestStatusTag status={row.status} />,
    },
  },
  {
    id: "priority",
    key: "priority",
    Header: i18n.t("service-request:fields.priority"),
    defaultWidth: 150,
    Cell: ({ priority }) => <WorkOrderPriorityBadge priority={priority} />,
    toText: ({ priority }) => translateWorkOrderPriority(priority),
    orderBy: (dir) => ({ priority: dir }),
    groupBy: {
      id: ({ priority }) => priority,
      label: (_id, row) => <WorkOrderPriorityBadge priority={row?.priority ?? null} />,
    },
  },
  {
    id: "created_at",
    key: "created_at",
    Header: i18n.t("service-request:fields.created_at"),
    Cell: ({ created_at }) => (created_at ? formatDate(parseDate(created_at), "P") : null),
    toText: ({ created_at }) => (created_at ? formatDate(parseDate(created_at), "P") : ""),
    orderBy: (dir) => ({ created_at: dir }),
    defaultSortDirection: "desc",
    calendarView: {
      event: (row) => ({
        id: `${row.id}`,
        title: row.title,
        start: new Date(row.created_at),
      }),
    },
  },
  {
    id: "created_by",
    key: "created_by",
    Header: i18n.t("service-request:fields.created_by"),
    toText: (row) => `${row.created_by.first_name} ${row.created_by.last_name}`,
    Cell: (row) => <UserTag user={row.created_by} />,
    groupBy: {
      id: (row) => row.created_by_id,
      label: (_id, row) => row && <UserTag user={row.created_by} />,
      labelText: (_id, row) => {
        const user = row?.created_by
        return user ? `${user.first_name} ${user.last_name}` : ""
      },
    },
  },
  {
    id: "assets",
    key: "assets",
    Header: i18n.t("common:asset", { count: 2 }),
    defaultWidth: 300,
    toText: (row) => row.assets.map((a) => a.asset.name).join(", "),
    Cell: (row) => {
      const assets = row.assets
        .map((a) => a.asset)
        .filter(Boolean)
        .sort((a, b) => naturalCompare(a.name, b.name))

      if (!assets.length) return null

      return (
        <>
          <LinkWithBackgroundLocation
            key={assets[0].id}
            to={`/object/${assets[0].id}`}
            onClick={(e) => e.stopPropagation()}
            className="flex cursor-pointer flex-wrap items-center break-all rounded bg-white px-2 py-0.5 ring-gray-300
          hover:text-gray-900 hover:ring-1">
            <AssetWithParents asset={assets[0]} showAvatar />
          </LinkWithBackgroundLocation>

          {assets.length > 1 && (
            <span className="ml-2 mt-1 text-xs text-gray-500">
              +{row.assets.length - 1} {i18n.t("common:more")}
            </span>
          )}
        </>
      )
    },
    groupBy: {
      id: (row) => row.assets.map((a) => a.asset?.id ?? ""),
      label: (id, row) =>
        row?.assets.find((r) => r.asset?.id === id)?.asset?.name ??
        i18n.t("tasks:filters.asset.none"),
      labelText: (id, row) =>
        row?.assets.find((r) => r.asset?.id === id)?.asset?.name ??
        i18n.t("tasks:filters.asset.none"),
    },
  },
  {
    id: "assignees",
    key: "assignees",
    Header: i18n.t("tasks:fields.assignee", { count: 2 }),
    defaultWidth: 240,
    Cell: (row) => (
      <WorkOrderAssignedEntities inline assignees={row.assignees} assigned_teams={[]} />
    ),
    toText: (row) =>
      row.assignees.map((a) => `${a.user.first_name} ${a.user.last_name}`).join(", "),
  },
  {
    id: "assigned_teams",
    key: "assigned_teams",
    Header: i18n.t("common:team", { count: 2 }),
    defaultWidth: 240,
    toText: (row) => row.assigned_teams.map((t) => t.team?.name).join(", "),
    Cell: (row) => (
      <WorkOrderAssignedEntities
        inline
        assignees={[]}
        assigned_teams={row.assigned_teams}
      />
    ),
    groupBy: {
      id: (row) => row.assigned_teams.map((t) => `team_${t.team?.id}`),
      label: (id, row) => {
        if (id?.startsWith("team_")) {
          const teamId = id.substring(5)
          const team = row?.assigned_teams.find((r) => r.team?.id === teamId)?.team
          return team ? <TeamTag team={team} /> : null
        }

        return i18n.t("tasks:filters.team.none")
      },
      labelText: (id, row) => {
        if (id?.startsWith("team_")) {
          const teamId = id.substring(5)
          const team = row?.assigned_teams.find((r) => r.team?.id === teamId)?.team
          return team?.name ?? ""
        }

        return i18n.t("tasks:filters.team.none")
      },
      orderValue: (id, row) => {
        if (id?.startsWith("team_")) {
          const teamId = id.substring(5)
          const team = row?.assigned_teams.find((r) => r.team?.id === teamId)?.team
          return team?.name ?? null
        }

        return null
      },
    },
  },
]

type Props = Omit<
  DataViewProps<
    IServiceRequestFragment,
    ServiceRequestColumnIds,
    ServiceRequestColumnOptions
  >,
  | "columnDefinitions"
  | "dataType"
  | "list_item"
  | "defaultConfig"
  | "filter"
  | "onSelect"
  | "dataId"
  | "dataSearchValue"
> &
  Partial<
    Pick<
      DataViewProps<
        IServiceRequestFragment,
        ServiceRequestColumnIds,
        ServiceRequestColumnOptions
      >,
      "defaultConfig"
    >
  >

const useFilterSchema = () => {
  const schema = createSchema<IServiceRequestFragment>({
    // Priority
    priority: createSchemaStatement<
      Pick<IServiceRequestFragment, "priority">,
      IWorkOrderPriorityEnum | null
    >({
      type: "select",
      label: i18n.t("tasks:fields.priority"),
      icon: <CaretDoubleUp />,
      multiSelectedLabel: i18n.t("tasks:fields.priority"),
      valueToString: (v) => v ?? "no_priority",
      toWhere: (state) =>
        generalSelectStateToWhere(["priority"], state) as Where<
          Pick<IServiceRequestFragment, "priority">
        >,
      getItems: async () => [
        {
          value: IWorkOrderPriorityEnum.High,
          searchValue: i18n.t("tasks:priority.high"),
          label: <WorkOrderPriorityBadge priority={IWorkOrderPriorityEnum.High} />,
        },
        {
          value: IWorkOrderPriorityEnum.Medium,
          searchValue: i18n.t("tasks:priority.medium"),
          label: <WorkOrderPriorityBadge priority={IWorkOrderPriorityEnum.Medium} />,
        },
        {
          value: IWorkOrderPriorityEnum.Low,
          searchValue: i18n.t("tasks:priority.low"),
          label: <WorkOrderPriorityBadge priority={IWorkOrderPriorityEnum.Low} />,
        },
        {
          value: null,
          searchValue: i18n.t("tasks:priority.none"),
          label: <span className="text-gray-500">{i18n.t("tasks:priority.none")}</span>,
        },
      ],
    }),

    // Status
    status: createSchemaStatement<Pick<IServiceRequestFragment, "status">, string | null>({
      type: "select",
      icon: <CircleDashed />,
      label: i18n.t("tasks:fields.status"),
      multiSelectedLabel: i18n.t("tasks:fields.status"),
      toWhere: (state) => {
        return { status: selectStateToWhere(state) } as Where<
          Pick<IServiceRequestFragment, "status">
        >
      },
      getItems: async () =>
        [
          IServiceRequestStatusEnum.New,
          IServiceRequestStatusEnum.InProgress,
          IServiceRequestStatusEnum.Rejected,
          IServiceRequestStatusEnum.Done,
        ].map((status) => {
          return {
            value: status,
            label: <ServiceRequestStatusTag status={status} />,
            searchValue: translateServiceRequestStatus(status),
          }
        }),
    }),

    // Assets
    assets: genericAssets<IServiceRequestFragment>(
      (state) =>
        generalSelectStateToWhere(["assets", "asset", "id"], state, {
          isOneToMany: true,
        }) as Where<IServiceRequestFragment>
    ),

    // Asset Groups
    assetGroups: genericAssetGroups<IServiceRequestFragment>(
      (state) =>
        generalSelectStateToWhere(["assets", "asset", "group", "id"], state, {
          isOneToMany: true,
        }) as Where<IServiceRequestFragment>
    ),
  })

  return { schema }
}

export const servicerequestDataViewDefaultConfig = {
  columnOrder: [
    "id",
    "title",
    "status",
    "priority",
    "created_at",
    "created_by",
    "assets",
    "assignees",
    "assigned_teams",
  ],
  orderBy: [{ id: "created_at", dir: "desc" }],
} as DataViewConfiguration<ServiceRequestColumnIds>

const serviceRequestListItem = (servicerequest: IServiceRequestFragment) => (
  <div className="group inline-flex flex-col rounded py-3">
    <div className="flex items-center">
      <div className="leading-loose">
        <p className="text-sm font-medium">{servicerequest.title}</p>
      </div>
    </div>
  </div>
)

const ServiceRequestDataView: React.FC<Props> = ({ defaultConfig, ...props }) => {
  const navigate = useNavigate()
  const filter = useFilterSchema()

  const onSelect = (item: IServiceRequestFragment) => {
    navigate(`/service-request/${item.id}`)
  }

  return (
    <DataView
      {...props}
      dataId={({ id }) => `${id}`}
      dataSearchValue={({ title }) => `${title}`}
      columnDefinitions={columnDefinitions}
      dataType={IViewDataTypeEnum.ServiceRequest}
      listItem={serviceRequestListItem}
      filterSchema={filter.schema}
      searchPlaceholder={i18n.t("common:search")}
      onSelect={onSelect}
      defaultConfig={{
        columnOrder: [
          "id",
          "title",
          "status",
          "priority",
          "created_at",
          "created_by",
          "assets",
          "assignees",
          "assigned_teams",
        ],
        orderBy: [{ id: "created_at", dir: "desc" }],
        ...defaultConfig,
      }}
      baseOrderBy={[{ id: "created_at", dir: "desc" }]}
    />
  )
}

export default ServiceRequestDataView
