import { dataViewRefreshData } from "@components/new-data-view/lib/hooks"
import { hasFeature } from "@contexts/feature-flag-context"
import {
  IMutationRootCopyWorkOrderArgs,
  IMutationRootDeleteAssetXWorkOrderByPkArgs,
  IMutationRootDeleteWorkOrderByPkArgs,
  IMutationRootDeleteWorkOrderReportByPkArgs,
  IMutationRootDeleteWorkOrderXCollaboratorByPkArgs,
  IMutationRootDeleteWorkOrderXTeamByPkArgs,
  IMutationRootDeleteWorkOrderXUploadByPkArgs,
  IMutationRootDeleteWorkOrderXUserByPkArgs,
  IMutationRootDeleteWorkOrderXWorkOrderCategoryByPkArgs,
  IMutationRootInsertAssetXWorkOrderOneArgs,
  IMutationRootInsertWorkOrderArgs,
  IMutationRootInsertWorkOrderOneArgs,
  IMutationRootInsertWorkOrderXCollaboratorOneArgs,
  IMutationRootInsertWorkOrderXTeamOneArgs,
  IMutationRootInsertWorkOrderXUploadOneArgs,
  IMutationRootInsertWorkOrderXUserOneArgs,
  IMutationRootInsertWorkOrderXWorkOrderCategoryOneArgs,
  IMutationRootUpdateWorkOrderArgs,
  IMutationRootUpdateWorkOrderByPkArgs,
  uuid,
} from "@elara/db"
import { buildWherePredicate, Where } from "@elara/select"
import {
  Cache,
  Data,
  OptimisticMutationResolver,
  UpdateResolver,
} from "@urql/exchange-graphcache"

import {
  IProjectFragment,
  IWorkOrderCategoryFragment,
  IWorkOrderDataViewFragment,
  IWorkOrderFragment,
  ProjectFragmentDoc,
  WorkOrderDataViewFragmentDoc,
} from "../documents/fragments.generated"
import {
  IAddNewDocumentToWorkOrderMutation,
  IQuickAssetWorkOrderChangeResultFragment,
  IQuickAssigneeWorkOrderChangeResultFragment,
  IQuickCategoryWorkOrderChangeResultFragment,
  IQuickCollaboratorWorkOrderChangeResultFragment,
  IQuickTeamWorkOrderChangeResultFragment,
  ITaskDataViewQuery,
  ITaskDataViewQueryVariables,
  IWorkOrderCategoriesQuery,
  IWorkOrderDetailsQuery,
  IWorkOrderDetailsQueryVariables,
  QuickAssetWorkOrderChangeResultFragmentDoc,
  QuickAssigneeWorkOrderChangeResultFragmentDoc,
  QuickCategoryWorkOrderChangeResultFragmentDoc,
  QuickCollaboratorWorkOrderChangeResultFragmentDoc,
  QuickTeamWorkOrderChangeResultFragmentDoc,
  TaskDataViewDocument,
  WorkOrderCategoriesDocument,
  WorkOrderDetailsDocument,
} from "../documents/work-order.generated"

type Mutations = {
  [fieldName: string]: UpdateResolver
}

const predicateCache: Record<string, (data: IWorkOrderDataViewFragment) => boolean> = {}

async function addToTaskDataViewQuery(workOrder: IWorkOrderDataViewFragment, cache: Cache) {
  if (!workOrder) return

  if (hasFeature("data_view")) {
    dataViewRefreshData()
    return
  }

  const fields = cache.inspectFields("query_root").filter((f) => {
    return f.fieldName === "work_order"
  })

  for (let field of fields) {
    const where = (field.arguments?.where ??
      null) as Where<IWorkOrderDataViewFragment> | null
    if (where) {
      let predicate = predicateCache[field.fieldKey]
      if (!predicate) {
        predicate = buildWherePredicate<IWorkOrderDataViewFragment>(where)
        predicateCache[field.fieldKey] = predicate
      }
      const variables = { where: where as any } as any
      if (field.arguments?.order_by) {
        variables.orderBy = field.arguments?.order_by
      }
      if (field.arguments?.limit) {
        variables.limit = field.arguments?.limit
      }
      if (predicate(workOrder)) {
        cache.updateQuery<ITaskDataViewQuery, ITaskDataViewQueryVariables>(
          {
            query: TaskDataViewDocument,
            variables,
          },
          (data) => {
            if (!data?.work_order.some((w) => w.id === workOrder.id)) {
              data?.work_order.push(workOrder)
              return data
            } else {
              return null
            }
          }
        )
      } else {
        cache.updateQuery<ITaskDataViewQuery, ITaskDataViewQueryVariables>(
          {
            query: TaskDataViewDocument,
            variables,
          },
          (data) => {
            const workOrders = data?.work_order ?? []
            const filtered = workOrders.filter((w) => w.id !== workOrder.id)
            if (data && filtered.length !== workOrders.length) {
              data.work_order = filtered
              return data
            }
            return null
          }
        )
      }
    }
  }
}

function deleteTaskFromQuery(workOrderId: uuid, cache: Cache) {
  const fields = cache.inspectFields("query_root").filter((f) => {
    return f.fieldName === "work_order"
  })

  for (let field of fields) {
    const where = (field.arguments?.where ??
      null) as Where<IWorkOrderDataViewFragment> | null
    if (where) {
      cache.updateQuery<ITaskDataViewQuery, ITaskDataViewQueryVariables>(
        {
          query: TaskDataViewDocument,
          variables: { where: where as any },
        },
        (data) => {
          if (data?.work_order.some((w) => w.id === workOrderId)) {
            data.work_order = data.work_order.filter((w) => w.id !== workOrderId)
            return data
          } else {
            return null
          }
        }
      )
    }
  }
}

// TODO:
// function addToTemplatesQuery(workOrder: IWorkOrder, cache: Cache) {
//   if (workOrder.type === IWorkOrderTypeEnum.Template) {
//     cache.updateQuery<IWorkOrderTemplatesQuery, IWorkOrderTemplatesQueryVariables>(
//       { query: WorkOrderTemplatesDocument },
//       (data) => {
//         if (!data?.work_order.some((w) => w.id === workOrder.id)) {
//           data?.work_order.push(workOrder)
//         }
//         return data
//       }
//     )
//   }
// }

function handleTask(workOrder: IWorkOrderDataViewFragment, cache: Cache) {
  // addToTemplatesQuery(workOrder, cache)
  addToTaskDataViewQuery(workOrder, cache)
}

const optimistic: {
  [fieldName: string]: OptimisticMutationResolver
} = {
  update_work_order_by_pk(variables: IMutationRootUpdateWorkOrderByPkArgs, cache: Cache) {
    const data = {
      __typename: "work_order",
      ...cache.readFragment(WorkOrderDataViewFragmentDoc, {
        id: variables.pk_columns?.id!,
      }),
      ...variables._set,
    } as IWorkOrderDataViewFragment

    if (data.project_id) {
      const project = cache.readFragment(ProjectFragmentDoc, {
        id: data.project_id,
      })
      data.project = { __typename: "project", ...project } as IProjectFragment
    } else {
      data.project = null
    }

    return data
  },

  // Categories
  insert_work_order_x_work_order_category_one(
    variables: IMutationRootInsertWorkOrderXWorkOrderCategoryOneArgs,
    cache
  ) {
    const work_order = cache.readFragment(QuickCategoryWorkOrderChangeResultFragmentDoc, {
      id: variables.object.work_order_id,
    }) as IQuickCategoryWorkOrderChangeResultFragment | null

    work_order?.categories?.push({
      __typename: "work_order_x_work_order_category",
      work_order_category_id: variables.object.work_order_category_id!,
      category: {
        __typename: "work_order_category",
        id: variables.object.work_order_category_id!,
      },
    })

    return {
      __typename: "work_order_x_work_order_category",
      work_order,
    }
  },
  delete_work_order_x_work_order_category_by_pk(
    variables: IMutationRootDeleteWorkOrderXWorkOrderCategoryByPkArgs,
    cache: any
  ) {
    const work_order = cache.readFragment(QuickCategoryWorkOrderChangeResultFragmentDoc, {
      id: variables.work_order_id,
    }) as IQuickCategoryWorkOrderChangeResultFragment | null

    return {
      __typename: "work_order_x_work_order_category",
      work_order: {
        ...work_order,
        categories:
          work_order?.categories?.filter(
            (c) => c.category.id !== variables.work_order_category_id
          ) ?? [],
      },
    }
  },

  // Assignees
  insert_work_order_x_user_one(vars: IMutationRootInsertWorkOrderXUserOneArgs, cache) {
    const work_order = cache.readFragment(QuickAssigneeWorkOrderChangeResultFragmentDoc, {
      id: vars.object.work_order_id,
    }) as IQuickAssigneeWorkOrderChangeResultFragment | null
    work_order?.assignees?.push({
      __typename: "work_order_x_user",
      user_id: vars.object.user_id!,
      user: { __typename: "user", id: vars.object.user_id! },
    })
    return { __typename: "work_order_x_user", work_order }
  },
  delete_work_order_x_user_by_pk(vars: IMutationRootDeleteWorkOrderXUserByPkArgs, cache) {
    const work_order = cache.readFragment(QuickAssigneeWorkOrderChangeResultFragmentDoc, {
      id: vars.work_order_id,
    }) as IQuickAssigneeWorkOrderChangeResultFragment | null

    return {
      __typename: "work_order_x_user",
      work_order: {
        ...work_order,
        assignees: work_order?.assignees?.filter((at) => at.user.id !== vars.user_id),
      },
    }
  },

  // Collaborators
  insert_work_order_x_collaborator_one(
    vars: IMutationRootInsertWorkOrderXCollaboratorOneArgs,
    cache
  ) {
    const work_order = cache.readFragment(
      QuickCollaboratorWorkOrderChangeResultFragmentDoc,
      {
        id: vars.object.work_order_id,
      }
    ) as IQuickCollaboratorWorkOrderChangeResultFragment | null
    work_order?.collaborators?.push({
      __typename: "work_order_x_collaborator",
      user_id: vars.object.user_id!,
      user: { __typename: "user", id: vars.object.user_id! },
    })
    return { __typename: "work_order_x_collaborator", work_order }
  },
  delete_work_order_x_collaborator_by_pk(
    vars: IMutationRootDeleteWorkOrderXCollaboratorByPkArgs,
    cache
  ) {
    const work_order = cache.readFragment(
      QuickCollaboratorWorkOrderChangeResultFragmentDoc,
      {
        id: vars.work_order_id,
      }
    ) as IQuickCollaboratorWorkOrderChangeResultFragment | null

    return {
      __typename: "work_order_x_collaborator",
      work_order: {
        ...work_order,
        collaborators: work_order?.collaborators?.filter(
          (at) => at.user.id !== vars.user_id
        ),
      },
    }
  },

  // Teams
  insert_work_order_x_team_one(vars: IMutationRootInsertWorkOrderXTeamOneArgs, cache) {
    const work_order = cache.readFragment(QuickTeamWorkOrderChangeResultFragmentDoc, {
      id: vars.object.work_order_id,
    }) as IQuickTeamWorkOrderChangeResultFragment | null
    work_order?.assigned_teams?.push({
      __typename: "work_order_x_team",
      team_id: vars.object.team_id!,
      team: { __typename: "team", id: vars.object.team_id! },
    })
    return { __typename: "work_order_x_team", work_order }
  },
  delete_work_order_x_team_by_pk(vars: IMutationRootDeleteWorkOrderXTeamByPkArgs, cache) {
    const work_order = cache.readFragment(QuickTeamWorkOrderChangeResultFragmentDoc, {
      id: vars.work_order_id,
    }) as IQuickTeamWorkOrderChangeResultFragment | null

    return {
      __typename: "work_order_x_team",
      work_order: {
        ...work_order,
        assigned_teams: work_order?.assigned_teams?.filter(
          (at) => at.team?.id !== vars.team_id
        ),
      },
    }
  },

  // Assets/Objects
  insert_asset_x_work_order_one(vars: IMutationRootInsertAssetXWorkOrderOneArgs, cache) {
    const work_order = cache.readFragment(QuickAssetWorkOrderChangeResultFragmentDoc, {
      id: vars.object.work_order_id,
    }) as IQuickAssetWorkOrderChangeResultFragment | null
    work_order?.assets?.push({
      __typename: "asset_x_work_order",
      asset_id: vars.object.asset_id!,
      asset: { __typename: "asset", id: vars.object.asset_id! },
    })
    return { __typename: "asset_x_work_order", work_order }
  },
  delete_asset_x_work_order_by_pk(vars: IMutationRootDeleteAssetXWorkOrderByPkArgs, cache) {
    const work_order = cache.readFragment(QuickAssetWorkOrderChangeResultFragmentDoc, {
      id: vars.work_order_id,
    }) as IQuickAssetWorkOrderChangeResultFragment | null

    return {
      __typename: "asset_x_work_order",
      work_order: {
        ...work_order,
        assets: work_order?.assets?.filter((at) => at.asset.id !== vars.asset_id),
      },
    }
  },
}

const mutations: Mutations = {
  // Delete
  delete_work_order_x_work_order_category_by_pk(
    _result,
    args: IMutationRootDeleteWorkOrderXWorkOrderCategoryByPkArgs,
    cache
  ) {
    cache.invalidate({
      __typename: "work_order_x_work_order_category",
      work_order_id: args.work_order_id,
      work_order_category_id: args.work_order_category_id,
    })
  },
  delete_work_order_category_by_pk(
    _result,
    args: IMutationRootDeleteWorkOrderByPkArgs,
    cache
  ) {
    cache.invalidate({
      __typename: "work_order_category",
      id: args.id,
    })
  },
  delete_work_order_x_upload_by_pk(
    _result,
    args: IMutationRootDeleteWorkOrderXUploadByPkArgs,
    cache
  ) {
    cache.invalidate({ __typename: "upload", id: args.upload_id })
  },
  delete_work_order_report_by_pk(
    _result,
    args: IMutationRootDeleteWorkOrderReportByPkArgs,
    cache
  ) {
    cache.invalidate({ __typename: "work_order_report", id: args.id })
  },

  // update
  update_work_order_by_pk(
    result: {
      update_work_order_by_pk: Pick<IWorkOrderFragment, "id"> & Partial<IWorkOrderFragment>
    },
    args: IMutationRootUpdateWorkOrderByPkArgs,
    cache
  ) {
    if (args._set?.deleted_at) {
      cache.invalidate({ __typename: "work_order", id: args.pk_columns.id })
      deleteTaskFromQuery(args.pk_columns.id, cache)
      return
    }

    const workOrder = result.update_work_order_by_pk
    if (!workOrder) return

    const fullWorkOrder = cache.readFragment<IWorkOrderDataViewFragment>(
      WorkOrderDataViewFragmentDoc,
      { id: workOrder.id } as Data
    )
    if (!fullWorkOrder) return

    // // If a work order is updated, let's see whether we can add it to the logbook
    handleTask(fullWorkOrder, cache)
  },

  update_work_order(
    result: { update_work_order: { returning: { id: uuid }[] } | null },
    args: IMutationRootUpdateWorkOrderArgs,
    cache
  ) {
    if (args._set?.deleted_at) {
      for (let deletedItem of result.update_work_order?.returning || []) {
        deleteTaskFromQuery(deletedItem.id, cache)
      }
    }
  },

  // Insert
  insert_work_order_one(
    result: { insert_work_order_one: IWorkOrderFragment },
    args: IMutationRootInsertWorkOrderOneArgs,
    cache
  ) {
    const workOrder = result.insert_work_order_one
    // Only do something if no update is happening
    if (args.object.id || !workOrder) return
    handleTask(workOrder, cache)
  },

  insert_work_order(
    result: { insert_work_order: { returning: IWorkOrderFragment[] } },
    args: IMutationRootInsertWorkOrderArgs,
    cache
  ) {
    if (result.insert_work_order.returning.length) {
      result.insert_work_order.returning.forEach((workOrder) => {
        if (!workOrder) return
        handleTask(workOrder, cache)
      })
    }
  },

  insert_work_order_category_one(
    result: { insert_work_order_category_one?: IWorkOrderCategoryFragment },
    args,
    cache
  ) {
    const category = result.insert_work_order_category_one
    if (!category) return

    cache.updateQuery<IWorkOrderCategoriesQuery>(
      {
        query: WorkOrderCategoriesDocument,
      },
      (data) => {
        if (!data || !data.work_order_category) return data

        const categories = data.work_order_category
        categories.push(category)

        return data
      }
    )
  },

  copyWorkOrder(
    result: { copyWorkOrder: { id: uuid; work_order: IWorkOrderFragment } },
    args: IMutationRootCopyWorkOrderArgs,
    cache
  ) {
    const workOrderId = result.copyWorkOrder.id
    const workOrder = result.copyWorkOrder.work_order

    // Only do something if we did duplicate
    if (!args.workOrderId || !workOrderId) return

    handleTask(workOrder, cache)
  },

  insert_work_order_x_upload_one(
    result: IAddNewDocumentToWorkOrderMutation,
    args: IMutationRootInsertWorkOrderXUploadOneArgs,
    cache
  ) {
    cache.updateQuery<IWorkOrderDetailsQuery, IWorkOrderDetailsQueryVariables>(
      {
        query: WorkOrderDetailsDocument,
        variables: { id: args.object.work_order_id! },
      },
      (data) => {
        if (!data?.work_order_by_pk || !result?.insert_work_order_x_upload_one) return data

        const documents = data.work_order_by_pk.documents
        documents.unshift(result?.insert_work_order_x_upload_one)

        return {
          work_order_by_pk: {
            ...data.work_order_by_pk,
            documents,
          },
        }
      }
    )
  },

  work_order_from_stream(
    result: { work_order_from_stream: IWorkOrderFragment[] },
    _args,
    cache
  ) {
    result?.work_order_from_stream?.forEach((workOrder) => {
      if (!workOrder) return
      handleTask(workOrder, cache)
    })
  },
}

const Subscription: { [fieldName: string]: UpdateResolver } = {}

export default {
  optimistic,
  mutations,
  Subscription,
}
