import { Flex, VStack } from "@components/layout"
import { Button } from "@components/shared"
import { PictureCard } from "@components/shared/picture-card"
import PictureUpload from "@components/shared/picture-upload"
import { SectionHeader } from "@components/shared/section-header"
import toast from "@components/shared/toast"
import { IPermissionScopeEnum, uuid } from "@elara/db"
import {
  useAddNewDocumentToAssetMutation,
  useDeleteAssetDocumentMutation,
  useEditAssetAvatarMutation,
  useEditAssetPartiallyMutation,
  useSetAssetAvatarMutation,
  useSetExistingPictureAsAvatarMutation,
} from "@graphql/documents/asset.generated"
import { IAssetDetailFragment } from "@graphql/documents/fragments.generated"
import { usePermissionScope } from "@hooks"
import { useFileUploads } from "@hooks/use-file-uploads"
import i18n from "@i18n"
import { Image, ImageSquare, X } from "@phosphor-icons/react"
import { colors } from "@styles"
import { lexCompare } from "@utils"
import cx from "classnames"
import React, { ChangeEvent, useRef, useState } from "react"
import { FileUpload } from "src/types"

const sortPictures = (uploads: FileUpload[], avatar_first?: boolean) => {
  const avatar = avatar_first ? uploads.find((u) => u.data.is_avatar) : null

  const sorted = uploads
    ?.filter((a) => !avatar_first || !a.data.is_avatar)
    .sort((a, b) => lexCompare(a.data.created_at, b.data.created_at))

  if (avatar_first && avatar) {
    sorted.unshift(avatar)
  }

  return sorted
}

const usePhotosSectionProps = (
  asset: IAssetDetailFragment,
  editScope: ReturnType<typeof usePermissionScope>
) => {
  const [, editAssetPartially] = useEditAssetPartiallyMutation()
  const [, addNewDocumentToAsset] = useAddNewDocumentToAssetMutation()
  const [, deleteAssetDocument] = useDeleteAssetDocumentMutation()

  const [, setAssetAvatar] = useSetAssetAvatarMutation()
  const [, editAssetAvatar] = useEditAssetAvatarMutation()
  const [, setExistingPictureAsAvatar] = useSetExistingPictureAsAvatarMutation()

  const onSetAssetAvatar = async (uploadId: string) => {
    if (asset.avatar) {
      await setAssetAvatar(
        { asset_id: asset.id, old_upload_id: asset.avatar.id, new_upload_id: uploadId },
        editScope.context()
      )
    } else {
      await editAssetAvatar({ assetId: asset.id, avatarId: uploadId }, editScope.context())
    }
  }

  const firstAvatarUploadInProgress = useRef(false)

  const { uploads, uploadFile } = useFileUploads(
    asset.documents.map(({ document, is_avatar }) => ({ ...document, is_avatar })),
    {
      onUploadFinished: async (upload) => {
        const isAvatar = !asset.documents.length && !firstAvatarUploadInProgress.current

        if (isAvatar) firstAvatarUploadInProgress.current = true

        const res = await addNewDocumentToAsset(
          { assetId: asset.id, uploadId: upload.id, isAvatar: isAvatar },
          editScope.context()
        )

        firstAvatarUploadInProgress.current = false

        if (res.error) toast.error("Das Bild konnte nicht hochgeladen werden.")
      },
    }
  )

  const onDeletePicture = async (pictureId: uuid, isAvatar: boolean) => {
    await deleteAssetDocument(
      { asset_id: asset.id, upload_id: pictureId },
      editScope.context()
    )

    if (isAvatar && uploads.length > 0) {
      await setExistingPictureAsAvatar(
        {
          assetId: asset.id,
          uploadId: uploads[0].data.id,
        },
        editScope.context()
      )
    }
  }

  const onUploadAvatar = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return

    const fileId = await uploadFile(e.target.files[0])

    await editAssetPartially(
      { asset_id: asset?.id, changes: { avatar_id: fileId } },
      editScope.context()
    )
  }

  const onUploadPictures = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return

    for (const file of Object.values(e.target.files)) {
      uploadFile(file)
    }
  }

  return {
    uploads,
    onDropFile: uploadFile,
    onUploadAvatar,
    onUploadPictures,
    onDeletePicture,
    scope: editScope,
    onSetAssetAvatar,
  }
}

const AssetPhotos = (props: { asset: IAssetDetailFragment }) => {
  const editScope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)

  const {
    uploads,
    scope,
    onDeletePicture,
    onDropFile,
    onUploadPictures,
    onSetAssetAvatar,
  } = usePhotosSectionProps(props.asset, editScope)

  const inputFileRef: React.RefObject<HTMLInputElement> = useRef(null)

  const [inEditMode, setinEditMode] = useState(false)

  const content = (
    <VStack space={12} className="relative">
      <Flex
        justify="space-between"
        align="center"
        row
        className="col-span-2 @mobile/page:col-span-4">
        <SectionHeader>{i18n.t("common:photo", { count: 2 })}</SectionHeader>

        {scope.hasScope &&
          (inEditMode ? (
            <Button
              size="small"
              type="tertiary"
              icon={X}
              onClick={() => {
                setinEditMode(false)
              }}>
              {i18n.t("common:close")}
            </Button>
          ) : uploads.length ? (
            <Button
              size="small"
              color="gray"
              type="tertiary"
              icon={ImageSquare}
              onClick={() => {
                setinEditMode(true)
              }}>
              {i18n.t("common:forms.photos.manage")}
            </Button>
          ) : null)}
      </Flex>

      <VStack space={20}>
        <div className="relative">
          {scope.hasScope && (
            <PictureUpload.Dropzone
              accept={{
                "image/jpeg": [".jpg", ".jpeg"],
                "image/png": [".png"],
              }}
              onDropFile={onDropFile}
              onFilesRejected={(err) => {
                if (err.length) {
                  toast.error({
                    title: i18n.t("common:forms.photos.errors.only_images_allowed.title"),
                    body: (
                      <span className="text-sm text-gray-700">
                        {i18n.t("common:forms.photos.errors.only_images_allowed.message")}
                      </span>
                    ),
                    params: {
                      duration: 5000,
                    },
                  })
                }
              }}
            />
          )}

          <div
            className={cx("grid gap-3", { "pl-0.5": inEditMode })}
            style={{
              gridTemplateColumns: "repeat(auto-fill, 6rem)",
              gridAutoRows: "6rem",
            }}>
            {sortPictures(uploads, !inEditMode).map((upload: FileUpload) => (
              <PictureCard
                upload={upload}
                key={upload.data?.id as string}
                containerClasses="h-full"
                allowDeleteOnPreview
                inEditMode={inEditMode}
                onDelete={(id) => onDeletePicture?.(id, upload.data.is_avatar ?? false)}
                isAvatar={upload.data.is_avatar && inEditMode}
                onClick={inEditMode ? () => onSetAssetAvatar(upload.data.id) : undefined}
              />
            ))}

            <label className="flex cursor-pointer flex-col items-center justify-center overflow-hidden rounded border-2 border-dashed border-grey-5 px-px hover:bg-gray-100">
              <input
                multiple
                type="file"
                accept="image/*"
                ref={inputFileRef}
                onChange={onUploadPictures}
                style={{ display: "none" }}
              />
              {scope.hasScope && (
                <>
                  <Image size={24} color={colors.grey3} />
                  <span className="mt-2 w-full text-center text-xs">
                    {i18n.t("common:add_token", {
                      token: i18n.t("common:photo", { count: 1 }),
                    })}
                  </span>
                </>
              )}
            </label>
          </div>
        </div>
      </VStack>
    </VStack>
  )

  return content
}

export default AssetPhotos
