import AssetAddEditMeterReadingsForm from "@components/asset/detail/meter/create-and-edit-meter-readings-form"
import AssetAddEditMeterForm from "@components/asset/detail/meter/new-create-and-edit-meter-form"
import { HStack } from "@components/layout"
import { ActionMenu, Button } from "@components/shared"
import { alertDialog } from "@components/shared/alert-dialog-provider"
import CollapsibleTabs from "@components/shared/collapsible-tabs"
import LoadingSpinner from "@components/shared/loading-spinner"
import { useMeterDetailQuery } from "@graphql/documents/asset-meter.generated"
import { useDeleteMeterMutation } from "@graphql/documents/asset-meter.generated"
import { IMeterDetailFragment } from "@graphql/documents/fragments.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import {
  DotsThreeVertical,
  PencilSimpleLine,
  Plus,
  TrashSimple,
} from "@phosphor-icons/react"
import { useParams } from "react-router-dom"

import { MeterDetails } from "./$id.details"
import { MeterReadingsHistory } from "./$id.readings"

type MeterActionMenuProps = {
  meter: IMeterDetailFragment
}

export const MeterActionMenu = ({ meter }: MeterActionMenuProps) => {
  const deleteMetersScope = usePermissionScope(IPermissionScopeEnum.AppAssetArchiveDelete)

  const [, deleteMeter] = useDeleteMeterMutation()

  const deleteMeterDialog = () => {
    alertDialog({
      danger: true,
      cancelText: i18n.t("common:discard"),
      actionText: i18n.t("common:delete"),
      title: i18n.t("meters:actions.delete_meter.title"),
      description: i18n.t("meters:actions.delete_meter.description", {
        meterName: meter.name,
        interpolation: { escapeValue: false },
      }),
      onAction: () => {
        deleteMeter({ id: meter.id }, deleteMetersScope.context())
      },
    })
  }
  if (!deleteMetersScope.hasScope) return null

  return (
    <ActionMenu
      items={[
        deleteMetersScope.hasScope
          ? {
              key: "delete",
              icon: <TrashSimple />,
              label: i18n.t("common:delete"),
              action: deleteMeterDialog,
            }
          : null,
      ]}>
      <Button color="gray" type="secondary" icon={DotsThreeVertical} />
    </ActionMenu>
  )
}

const MeterDetail = (props: { meter: IMeterDetailFragment }) => {
  const { meter } = props

  return (
    <div className="flex min-h-0 flex-1 flex-col bg-gray-50">
      <div className="flex flex-col space-y-2 p-3">
        <div className="inline-flex"></div>
        <HStack justify="space-between">
          <div className="grow-0">
            <h2 className="line-clamp-2 text-base font-semibold sm:text-lg">
              {meter?.name}
            </h2>
            <h3 className="mt-2 text-sm leading-none text-gray-500">{meter.description}</h3>
          </div>

          <div className="flex gap-x-3">
            <AssetAddEditMeterReadingsForm meterId={meter.id}>
              <Button type="primary" size="small" icon={Plus}>
                {i18n.t("meters:fields.reading", { count: 1 })}
              </Button>
            </AssetAddEditMeterReadingsForm>

            <AssetAddEditMeterForm assetId={meter.asset_id} meter={meter}>
              <Button size="small" type="secondary" icon={PencilSimpleLine}>
                {i18n.t("edit")}
              </Button>
            </AssetAddEditMeterForm>

            <MeterActionMenu meter={meter} />
          </div>
        </HStack>
      </div>

      <section className="flex min-h-0 flex-1 flex-col">
        <CollapsibleTabs.Root
          defaultValue="details"
          className="flex min-h-0 flex-1 flex-col">
          <CollapsibleTabs.List
            triggers={[
              { value: "details", label: i18n.t("maintenance:tabs.details") },
              { value: "readings", label: i18n.t("meters:fields.reading", { count: 2 }) },
            ]}
          />

          <div className="flex min-h-0 flex-1 flex-col bg-white">
            <CollapsibleTabs.Content value={"details"}>
              <MeterDetails meter={meter} />
            </CollapsibleTabs.Content>
            <CollapsibleTabs.Content value={"readings"}>
              <MeterReadingsHistory meter={meter} />
            </CollapsibleTabs.Content>
          </div>
        </CollapsibleTabs.Root>
      </section>
    </div>
  )

  //   return (
  //     <div className="flex min-h-0 flex-1 flex-col">
  //       <div className="flex flex-col space-y-2 p-3">
  //         <div className="inline-flex">
  //           <AssetWithParents
  //             withLink
  //             showAvatar
  //             asset={meter.asset}
  //             linkToSubpage="meters"
  //           />
  //         </div>
  //         <HStack justify="space-between">
  //           <span className="grow-0">
  //             <span className="line-clamp-2 text-base font-semibold sm:text-lg">
  //               {meter?.name}
  //             </span>{" "}
  //           </span>

  //           <div className="flex gap-x-3">
  //             <Button
  //               type="primary"
  //               size="small"
  //               icon={Plus}
  //               onClick={() => onAddReading(meter?.id!)}>
  //               {i18n.t("meters:fields.reading", { count: 1 })}
  //             </Button>

  //             <MeterActionMenu
  //               onEdit={() => onEditMeter(meter!)}
  //               onDelete={deleteModal.show}
  //             />
  //           </div>
  //         </HStack>

  //       <div className="flex min-h-0 flex-1 flex-col">
  //         <Tabs.Root
  //           defaultValue="history"
  //           orientation="horizontal"
  //           className="flex min-h-0 flex-1 flex-col"
  //           onValueChange={(val) => navigate(val, { replace: true })}
  //           value={location.pathname.split("/")[5] || "history"}>
  //           <Tabs.List>
  //             <Tabs.Trigger value="history">
  //               {i18n.t("meters:fields.reading", { count: 2 })}
  //             </Tabs.Trigger>
  //             {meter.meter_type && (
  //               <Tabs.Trigger value="tasks">
  //                 {meter.meter_type === IMeterTypeEnum.Measurement
  //                   ? i18n.t("meters:labels.deviation_tasks.task_list")
  //                   : i18n.t("meters:labels.regular_tasks.task_list")}
  //               </Tabs.Trigger>
  //             )}
  //           </Tabs.List>

  //           <Tabs.Content value="history">
  //             <AssetMeterReadingList
  //               meter={meter}
  //               data={meter?.meter_readings ?? null}
  //               onEdit={(reading) => onEditReading(reading)}
  //               onDelete={onDeleteMeterReading}
  //             />
  //           </Tabs.Content>

  //           {
  //             <Tabs.Content value="tasks">
  //               <ScheduledTaskTab
  //                 meterId={meter.id}
  //                 emptyPlaceholder={
  //                   <div className="mx-auto mt-8 max-w-sm text-center font-medium">
  //                     {meter.meter_type === IMeterTypeEnum.Measurement
  //                       ? i18n.t("meters:labels.deviation_tasks.empty_state")
  //                       : i18n.t("meters:labels.regular_tasks.empty_state", {
  //                           unit: meter.unit,
  //                           firstInterval: meter.first_interval_task_at,
  //                         })}
  //                   </div>
  //                 }
  //               />
  //             </Tabs.Content>
  //           }
  //         </Tabs.Root>
  //       </div>
  //     </div>
  //   )
}

const MeterDetailPage = () => {
  const { meterId } = useParams()

  const [queryRes] = useMeterDetailQuery({
    variables: { id: meterId! },
    pause: !meterId,
  })
  const meter = queryRes.data?.meter_by_pk

  if (!meter) return <LoadingSpinner />

  return <MeterDetail meter={meter} />
}

export default MeterDetailPage
