import { Button } from "@components/shared"
import { IPermissionScopeEnum, LocationSettings, UserSettings, uuid } from "@elara/db"
import { useGetUserProfileQuery } from "@graphql/documents/basic.generated"
import { IUserProfileFragment } from "@graphql/documents/fragments.generated"
import { IAvatarFragment } from "@graphql/documents/upload.generated"
import i18n from "@i18n"
import { getLocalTimeZone } from "@internationalized/date"
import { useLogout } from "@models/authentication"
import LoadingPage from "@pages/loading"
import * as Sentry from "@sentry/react"
import React, { PropsWithChildren, useMemo } from "react"

import { FullyAuthenticatedState, useAuthenticationStateContext } from "./auth-context"

export type AppWorkOrderCreateScope = {
  scope: IPermissionScopeEnum.AppWorkOrderCreate
  options?: { template_only?: boolean; owned_only?: boolean }
}

type AllowedScope = { role: string } & (
  | {
      scope: Exclude<
        IPermissionScopeEnum,
        | IPermissionScopeEnum.AppDataEntry
        | IPermissionScopeEnum.AppWorkOrderApproval
        | IPermissionScopeEnum.AppWorkOrderCreate
      >
    }
  | {
      scope: IPermissionScopeEnum.AppDataEntry | IPermissionScopeEnum.AppWorkOrderApproval
      options?: { assigned_only?: boolean }
    }
  | AppWorkOrderCreateScope
)

type UserProfile = Omit<IUserProfileFragment, "settings"> & {
  settings: UserSettings
}

type UserContextType = {
  id: uuid
  allowedScopes: AllowedScope[]
  location: {
    id: string
    name: string
    setupInfo: {} | null
    settings: LocationSettings
    features: Record<string, boolean>
    logo: IAvatarFragment | null
  }
  orgId: uuid
  permissionRole: { id: uuid; name: string } | null
  profile: UserProfile
}

function useRegisterSentry(info: FullyAuthenticatedState) {
  Sentry.setUser({ id: info.userInfo.id })
  Sentry.setTag("org_id", info.hasuraClaims["x-hasura-org-id"])
  Sentry.setTag("location_id", info.hasuraClaims["x-hasura-location-id"])
}

// Global variable for accessing our context value outside of a React component
const UserContextValueRef: { current: UserContextType } = { current: undefined! }
export function getUser() {
  return UserContextValueRef.current
}

export function hasPermissionScope(scope: IPermissionScopeEnum) {
  return getUser().allowedScopes.some((s) => s.scope === scope)
}
export const UserContext = React.createContext<UserContextType>(undefined!)

const InnerUserProvider = (props: PropsWithChildren<{ info: FullyAuthenticatedState }>) => {
  const logout = useLogout()

  useRegisterSentry(props.info)

  const [result] = useGetUserProfileQuery({
    variables: { id: props.info.userInfo.id, location_id: props.info.dataLocationId },
    requestPolicy: "cache-and-network",
  })

  const value = useMemo(() => {
    if (!result.data) return null

    const profile = result.data.user_by_pk as UserProfile
    const locationMember = result.data.location_member_by_pk
    const location = result.data.location_by_pk

    if (!profile || !locationMember || !location) return null

    const allowedScopes =
      locationMember?.permission_role?.scopes?.map((s) => {
        const { scope, inherited_role } = s.permission_scope
        return {
          scope: scope as IPermissionScopeEnum,
          role: inherited_role,
          options: s.options ?? {},
        } as AllowedScope
      }) ?? []

    let permissionRole = locationMember?.permission_role
      ? {
          id: locationMember.permission_role.id,
          name: locationMember.permission_role.name,
        }
      : null

    return {
      id: props.info.userInfo.id!,
      allowedScopes,
      location: {
        id: props.info.dataLocationId,
        name: location.name,
        settings: location.settings as LocationSettings,
        features: (location.features ?? {}) as Record<string, boolean>,
        setupInfo: location.setup_info ?? null,
        deleted_at: location.deleted_at,
        logo: location.logo,
      },
      orgId: props.info.hasuraClaims["x-hasura-org-id"],
      profile,
      permissionRole,
    }
  }, [result.data])

  if (!value) {
    return <LoadingPage />
  }

  if (value.location.deleted_at) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <div className="flex flex-col items-center">
          <span>{i18n.t("common:messages.location_deleted")}</span>
          <Button onClick={logout} className="mt-3">
            {i18n.t("settings:profile.logout")}
          </Button>
        </div>
      </div>
    )
  }

  UserContextValueRef.current = value

  return <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
}

export const UserProvider = (props: PropsWithChildren<{}>) => {
  const authState = useAuthenticationStateContext()

  if (authState.state.stage !== "fully_authenticated") {
    return <LoadingPage />
  }

  return <InnerUserProvider info={authState.state}>{props.children}</InnerUserProvider>
}

export const useUser = () => {
  const value = React.useContext(UserContext)
  if (!value) console.warn("`useUser` can only be used inside a `UserContext`.")
  if (!value.id) console.warn("`useUser` can only be used inside an authenticated route.")
  return value
}

export const useLocationTimeZone = () => {
  const { location } = useUser()
  return location.settings.timezone ?? getLocalTimeZone()
}
export const useLocation = () => {
  const { location } = useUser()
  return location
}

export const getLocationTimeZone = () => {
  const { location } = getUser()
  return location.settings.timezone ?? getLocalTimeZone()
}
