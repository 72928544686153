import { VStack } from "@components/layout"
import { Button } from "@components/shared"
import { CheckboxInput, CheckboxInputProps } from "@components/shared/form/checkbox-input"
import { RadioInput, RadioInputProps } from "@components/shared/form/radio-input"
import { RichTextFormController } from "@components/shared/form/rich-text-form-controller"
import { StaticRichText } from "@components/shared/static-rich-text"
import { TextInput } from "@components/shared/text-input"
import { BlockElementChoice } from "@elara/db"
import i18n from "@i18n"
import { Plus, X } from "@phosphor-icons/react"
import { nanoid } from "nanoid"
import { ChangeEvent, useRef } from "react"
import { Controller, useFieldArray, useWatch } from "react-hook-form"

import { BlockElementFormProps, BlockElementProps } from "./block-element-types"

export const ChoiceForm = (props: BlockElementFormProps) => {
  const {
    append: push,
    remove,
    fields,
  } = useFieldArray({
    name: `${props.field}.${props.index}.config.options`,
    control: props.control,
  })

  const isMultiple = useWatch({
    control: props.control,
    name: `${props.field}.${props.index}.config.multiple`,
  })

  const initialChoicesLength = useRef(fields.length)

  return (
    <VStack space={8} flex="1">
      <RichTextFormController
        control={props.control}
        className="max-h-36"
        placeholder={i18n.t("tasks:checklist.choice.placeholder")}
        name={`${props.field}.${props.index}.config.description`}
        noStyle
      />

      <VStack space={8}>
        {/* <span className="text-sm font-medium text-gray-700">Auswahloptionen</span> */}
        {fields.map((option, index) => {
          return (
            <div className="flex items-center" key={option.id}>
              <ChoiceOptionBox multiple={isMultiple} disabled className="mr-2" />
              <Controller
                name={`${props.field}.${props.index}.config.options[${index}].label`}
                control={props.control}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    placeholder={`Option ${index + 1}`}
                    autoComplete="nofill"
                    autoFocus={index >= initialChoicesLength.current}
                  />
                )}
              />

              <Button size="small" type="tertiary" icon={X} onClick={() => remove(index)} />
            </div>
          )
        })}
        <div className="flex w-full flex-wrap justify-between gap-y-2">
          <Button
            size="small"
            type="tertiary"
            icon={Plus}
            onClick={() => push({ label: "", id: nanoid(5) })}>
            {i18n.t("common:add")}
          </Button>
          <label className="flex items-center">
            <Controller
              name={`${props.field}.${props.index}.config.multiple`}
              control={props.control}
              render={({ field }) => <CheckboxInput {...field} />}
            />
            <span className="ml-2 text-sm">
              {i18n.t("tasks:checklist.choice.multiple_selection")}
            </span>
          </label>
        </div>
      </VStack>
    </VStack>
  )
}

const ChoiceOptionBox = ({
  multiple,
  ...otherProps
}: ({ multiple: boolean } & CheckboxInputProps) | RadioInputProps) =>
  multiple ? <CheckboxInput {...otherProps} /> : <RadioInput {...otherProps} />

export const ChoiceBlockElement = (props: BlockElementProps<BlockElementChoice>) => {
  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    let selectedOptions = props.element.response?.selected_options ?? []
    const selectedOptionId = e.target.value
    // If checked add if necessary
    if (e.target.checked) {
      if (props.element.config.multiple) {
        if (!selectedOptions.includes(selectedOptionId)) {
          selectedOptions.push(selectedOptionId)
        }
      } else {
        selectedOptions = [selectedOptionId]
      }
      // If not checked remove
    } else {
      selectedOptions = selectedOptions.filter((id) => id !== selectedOptionId)
    }

    props.updateResponse?.(
      props.element.id,
      selectedOptions.length ? { selected_options: selectedOptions } : null
    )
  }

  return (
    <VStack space={8}>
      <StaticRichText content={props.element.config.description} />

      {props.element.config.options.map((option) => {
        const isChecked =
          props.element.response?.selected_options?.includes(option.id) ?? false
        return (
          <label className="flex cursor-pointer items-center" key={option.id}>
            <ChoiceOptionBox
              multiple={props.element.config.multiple}
              checked={isChecked}
              onChange={onChange}
              value={option.id}
            />
            <span className="ml-2 text-sm">{option.label}</span>
          </label>
        )
      })}
    </VStack>
  )
}
