import { defaultDuration, getDurationOptions } from "@components/maintenance/types"
import { cn } from "@utils"
import { useEffect, useState } from "react"

import { Select } from "./select"
import { TextInput } from "./text-input"

interface DurationInputProps {
  value: Duration
  onChange: (value: Duration) => void
  className?: string
}

const DurationInput = ({ value, onChange, className }: DurationInputProps) => {
  const [interval, setInterval] = useState<number>(1)
  const [duration, setDuration] = useState<keyof Duration>("days")

  useEffect(() => {
    if (!value) return

    Object.entries(value).forEach(([key, value]) => {
      if (value > 0) {
        setInterval(value)
        setDuration(key as keyof Duration)
      }
    })
  }, [value])

  return (
    <div className={cn("flex items-center gap-x-2", className)}>
      <TextInput
        min={1}
        type="number"
        className="w-14"
        value={interval}
        onChange={(e) => {
          onChange({ ...defaultDuration, [duration]: e.target.valueAsNumber })
        }}
      />

      <Select
        value={duration}
        items={getDurationOptions(interval)}
        onValueChange={(value) => onChange({ ...defaultDuration, [value]: interval })}
      />
    </div>
  )
}

export default DurationInput
