import PageViewLayout from "@components/page/page-view-layout"
import {
  ServiceRequestPrioritySelect,
  ServiceRequestStatusTag,
} from "@components/service-request/status"
import { Button } from "@components/shared"
import { FormFieldController } from "@components/shared/form/form-field-controller"
import FormLabel from "@components/shared/form/form-label"
import RichText from "@components/shared/rich-text"
import { TextInput } from "@components/shared/text-input"
import toast from "@components/shared/toast"
import Uploads from "@components/shared/uploads"
import { SelectObject } from "@components/work-order/create-task-form"
import { FeatureGuard } from "@contexts/feature-flag-context"
import {
  IPermissionScopeEnum,
  IServiceRequestStatusEnum,
  IWorkOrderPriorityEnum,
} from "@elara/db"
import { useInsertServiceRequestMutation } from "@graphql/documents/service-request.generated"
import { zodResolver } from "@hookform/resolvers/zod"
import { usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { Plus } from "@phosphor-icons/react"
import { FormProvider, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { z } from "zod"

const uploadSchema = z.object({
  __typename: z.literal("upload").default("upload"),
  id: z.string().uuid(),
  file_name: z.string(),
  file_size: z.number(),
  mime_type: z.string(),
  created_at: z.string(),
  url: z.string().nullable(),
  thumbnail_url: z.string().nullable(),
})

const FormSchema = z.object({
  title: z.string().min(1),
  description: z.string().nullable(),
  userIds: z.array(z.string().uuid()),
  teamIds: z.array(z.string().uuid()),
  assetIds: z.array(z.string().uuid()),
  uploads: z.array(uploadSchema),
  priority: z.nativeEnum(IWorkOrderPriorityEnum).nullable(),
  status: z.nativeEnum(IServiceRequestStatusEnum).default(IServiceRequestStatusEnum.New),
})

type FormValues = z.infer<typeof FormSchema>

export default function ServiceRequestCreatePage() {
  const navigate = useNavigate()

  const [, insertServiceRequest] = useInsertServiceRequestMutation()
  const createScope = usePermissionScope(IPermissionScopeEnum.AppServiceRequestCreate)

  const form = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      title: "",
      description: "",
      userIds: [],
      teamIds: [],
      uploads: [],
      assetIds: [],
      priority: null,
      status: IServiceRequestStatusEnum.New,
    },
  })

  const onSubmit = form.handleSubmit(async (values) => {
    const response = await insertServiceRequest(
      {
        object: {
          title: values.title,
          description: values.description,
          priority: values.priority,
          status: IServiceRequestStatusEnum.New,
          assets: { data: values.assetIds.map((asset_id) => ({ asset_id })) },
          assignees: { data: values.userIds.map((user_id) => ({ user_id })) },
          uploads: { data: values.uploads.map(({ id }) => ({ upload_id: id })) },
          assigned_teams: { data: values.teamIds.map((team_id) => ({ team_id })) },
        },
      },
      createScope.context()
    )

    if (!response.error) {
      toast.success("A service request has been created successfully.")
      navigate("/service-request")
    }
  })

  return (
    <PageViewLayout
      title={i18n.t("common:create_token", {
        token: i18n.t("common:service_request", { count: 1 }),
      })}>
      <div className="m-6 max-w-2xl">
        <FormProvider {...form}>
          <form onSubmit={onSubmit} className="space-y-4">
            <div className="flex items-center space-x-4">
              <div className="flex items-center space-x-2">
                <span className="text-sm font-medium opacity-70">
                  {i18n.t("service-request:fields.status")}
                </span>
                <ServiceRequestStatusTag status={IServiceRequestStatusEnum.New} />
              </div>

              <div className="flex items-center">
                <span className="text-sm font-medium opacity-70">
                  {i18n.t("service-request:fields.priority")}
                </span>
                <FormFieldController
                  name="priority"
                  control={form.control}
                  hasErrorPlaceholder={!!form.formState.errors.priority}
                  render={({ field }) => (
                    <ServiceRequestPrioritySelect
                      value={field.value ?? ""}
                      onValueChange={field.onChange}
                    />
                  )}
                />
              </div>
            </div>

            <FormFieldController
              name="title"
              className="flex-1"
              control={form.control}
              hasErrorPlaceholder={!!form.formState.errors.title}
              render={({ field, fieldState }) => (
                <FormLabel label={i18n.t("service-request:fields.title")} required>
                  <TextInput {...field} hasError={!!fieldState.error} className="w-full" />
                </FormLabel>
              )}
            />

            <FormFieldController
              name="description"
              control={form.control}
              hasErrorPlaceholder={!!form.formState.errors.description}
              render={({ field, fieldState }) => (
                <FormLabel label={i18n.t("service-request:fields.description")} required>
                  <RichText
                    showToolbar
                    content={field.value ?? ""}
                    hasError={!!fieldState.error}
                    className="min-h-[160px] w-full rounded border"
                    onUpdate={({ editor }) => {
                      const value = editor.getHTML()
                      field.onChange(value)
                    }}
                  />
                </FormLabel>
              )}
            />

            <FormFieldController
              name="uploads"
              control={form.control}
              hasErrorPlaceholder={false}
              render={({ field }) => (
                <FormLabel label={i18n.t("service-request:fields.uploads")}>
                  <Uploads
                    scope={createScope}
                    uploads={field.value}
                    placeholderType="zone"
                    allowQuickDeleteOfPictures
                    boundDropzoneToContainer={false}
                    onUploadFinished={(u, uploads) => field.onChange(uploads.concat([u]))}
                    onDelete={(id, uploads) => {
                      field.onChange(uploads.filter((u) => u.id !== id))
                    }}
                    onEditFileName={(id, fileName, uploads) => {
                      field.onChange(
                        uploads.map((u) => {
                          if (u.id !== id) return u
                          return { ...u, file_name: fileName }
                        })
                      )
                    }}
                  />
                </FormLabel>
              )}
            />

            <FeatureGuard id="objects">
              <FormFieldController
                name="assetIds"
                control={form.control}
                hasErrorPlaceholder={!!form.formState.errors.assetIds}
                render={({ field }) => (
                  <FormLabel label={i18n.t("common:asset", { count: 2 })}>
                    <SelectObject assetIds={field.value} changeAssetIds={field.onChange} />
                  </FormLabel>
                )}
              />
            </FeatureGuard>

            <div className="flex sm:justify-end">
              <Button
                htmlType="submit"
                icon={() => <Plus />}
                className="flex-1 sm:flex-initial sm:px-8">
                <span>
                  {i18n.t("common:create_token", {
                    token: i18n.t("common:service_request", { count: 1 }),
                  })}
                </span>
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </PageViewLayout>
  )
}
