import { spacing, SpacingValue } from "@styles"
import { cn } from "@utils"
import React from "react"

export type InsetObjectValues = {
  x?: SpacingValue
  y?: SpacingValue
  top?: SpacingValue
  left?: SpacingValue
  bottom?: SpacingValue
  right?: SpacingValue
}
export type InsetValues = SpacingValue | InsetObjectValues

function insetCss(inset?: InsetValues) {
  if (!inset) return {}
  if (typeof inset === "number") return { padding: spacing[inset] }

  const left = inset.left ?? inset.x
  const right = inset.right ?? inset.x
  const top = inset.top ?? inset.y
  const bottom = inset.bottom ?? inset.y

  let style: React.CSSProperties = {}
  if (left) style.paddingLeft = spacing[left]
  if (right) style.paddingRight = spacing[right]
  if (top) style.paddingTop = spacing[top]
  if (bottom) style.paddingBottom = spacing[bottom]

  return style
}

export type ViewProps = {
  // This allows to customize the rendered html element
  // But I really don't understand the typescript bindings and the any was the only way I got it to work
  as?: any
  inset?: InsetValues
  flex?: React.CSSProperties["flex"]
} & Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "ref"
>

export const View = React.forwardRef<HTMLDivElement, ViewProps>(
  ({ inset, flex, className, ...props }, ref) => {
    return (
      <div
        className={cn(
          "items-stretch flex box-border basis-auto flex-col shrink-0 min-h-0 min-w-0 relative",
          className
        )}
        ref={ref}
        {...props}
        style={{ ...insetCss(inset), flex, ...props.style }}
      />
    )
  }
)

export default View
