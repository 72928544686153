import { DataViewConfiguration } from "@components/shared/data-view/data-view-types"
import { DefaultView, defaultViews } from "@components/view/default-views"
import { useUser } from "@contexts/user-context"
import { IViewDataTypeEnum, View } from "@elara/db"
import {
  ICustomViewFragment,
  useCustomViewsQuery,
} from "@graphql/documents/custom-view.generated"
import {
  IUpdateUserViewsInCollectionMutation,
  IUpdateUserViewsInCollectionMutationVariables,
  UpdateUserViewsInCollectionDocument,
  useViewCollectionForUserQuery,
} from "@graphql/documents/view-collection.generated"
import { useCallbackRef } from "@hooks"
import { useMyTeams } from "@hooks/use-my-teams"
import { useEffect, useRef, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Icon } from "src/types"
import { useClient } from "urql"
import { v4, validate as validateUuid } from "uuid"

import { resetDataViewConfiguration } from "./data-view-config"

export type CollectionView = {
  id: string
  name: string
  icon?: {} | null
  config: DataViewConfiguration<any>
  standard: boolean
}

export type CollectionViewSelection =
  | {
      type: "custom_view"
      id: string
      name: string
      icon: {} | null
      customView: ICustomViewFragment
    }
  | {
      type: "default_view"
      id: string
      name: string
      icon: Icon | null
      defaultView: CollectionView
    }

export function useAvailableViews(dataTypes: IViewDataTypeEnum[]) {
  const myTeams = useMyTeams()

  const [customViewsQueryRes] = useCustomViewsQuery({
    requestPolicy: "cache-first",
  })

  const customViews = (customViewsQueryRes?.data?.custom_view ?? [])
    .filter((v) => !v.shared_team_id || myTeams.some((t) => t.id === v.shared_team_id))
    .filter((v) => dataTypes.includes(v.data_type))

  const defaultCollectionViews = dataTypes.flatMap((data) =>
    defaultViews(data).map((d) => ({
      id: d.id,
      icon: d.icon || null,
      dataType: data,
      name: d.name,
      type: "default",
    }))
  )

  return customViews
    .map((c) => ({
      id: c.id,
      icon: c.icon,
      dataType: c.data_type,
      name: c.name,
      type: c.shared ? (c.shared_team_id ? "team" : "public") : "private",
    }))
    .concat(defaultCollectionViews)
}

export function useDataViewTabs(dataType: IViewDataTypeEnum) {
  const user = useUser()
  const client = useClient()
  const navigate = useNavigate()
  const sentUpdateRequest = useRef(false)

  const [viewCollectionQueryRes, refetchViewCollection] = useViewCollectionForUserQuery({
    variables: { userId: user.id },
    requestPolicy: "cache-and-network",
  })

  const myTeams = useMyTeams()

  const [customViewsQueryRes, fetchCustomViews] = useCustomViewsQuery({
    requestPolicy: "cache-first",
  })

  const viewCollection = viewCollectionQueryRes?.data?.view_collection?.find(
    (t) => t.data_type === dataType
  )

  const customViews = (customViewsQueryRes?.data?.custom_view ?? []).filter(
    (v) =>
      (!v.shared_team_id || myTeams.some((t) => t.id === v.shared_team_id)) &&
      v.data_type === dataType
  )

  const defaultCollectionViews = defaultViews(dataType)
  const standardCollectionViews: View[] = defaultCollectionViews
    .filter((v) => v.standard)
    .map((v) => ({ type: "default_view", id: v.id }))

  const collectionViews = (viewCollection?.views ?? standardCollectionViews) as View[]

  const tabs = collectionViews
    .map(({ type, id }) => {
      if (type === "custom_view") {
        const view = customViews.find((v) => v.id === id)
        if (!view) return null
        return { type, id, name: view.name, icon: view.icon, customView: view }
      } else {
        const v = defaultCollectionViews.find((v) => v.id === id) as DefaultView
        if (!v) return null
        return { type, id, name: v?.name, icon: v?.icon, defaultView: v }
      }
    })
    .filter(Boolean) as CollectionViewSelection[]

  const isFetching = viewCollectionQueryRes.fetching || customViewsQueryRes.fetching

  const [searchParams, setSearchParam] = useSearchParams()

  const [selectedTab, setSelectedTab] = useState<CollectionViewSelection | null>(null)
  const selectTab = (tab: CollectionViewSelection) => {
    setSelectedTab(tab)
    setSearchParam({ view: tab.id }, { replace: true })
  }

  useEffect(() => {
    if (!selectedTab && tabs[0] && !isFetching) {
      const viewId = searchParams.get("view")
      const tab = tabs.find((t) => t.id === viewId)

      if (tab) {
        selectTab(tab)
      } else if (viewId && validateUuid(viewId)) {
        navigate(`/view/${viewId}`, { replace: true })
      } else {
        selectTab(tabs[0])
      }
    }
  }, [isFetching, tabs[0]])

  const updateViewsInCollection = async (views: View[]) => {
    client
      .mutation<
        IUpdateUserViewsInCollectionMutation,
        IUpdateUserViewsInCollectionMutationVariables
      >(UpdateUserViewsInCollectionDocument, {
        id: viewCollection?.id ?? v4(),
        dataType: dataType,
        userId: user.id,
        views,
      })
      .toPromise()
  }

  useEffect(() => {
    if (!viewCollectionQueryRes.fetching && !viewCollectionQueryRes.stale) {
      // If no access to any tabs
      if (!tabs.length) {
        if (!sentUpdateRequest.current) {
          sentUpdateRequest.current = true

          updateViewsInCollection(standardCollectionViews).then(() => {
            refetchViewCollection({ requestPolicy: "network-only" })
          })
        }
      }
    }
  }, [viewCollectionQueryRes])

  const addViewToCollection = useCallbackRef(async (view: CollectionViewSelection) => {
    await updateViewsInCollection(
      (collectionViews ?? []).concat({ id: view.id, type: view.type })
    )
    const previousSelectedConfigId = selectedTab?.id

    selectTab(view)
    await refetchViewCollection({ requestPolicy: "network-only" })

    if (previousSelectedConfigId) {
      resetDataViewConfiguration(selectedTab.id)
    }
  })

  const createView = useCallbackRef(async (view: ICustomViewFragment) => {
    await addViewToCollection({
      type: "custom_view",
      id: view.id,
      name: view.name,
      icon: view.icon,
      customView: view,
    })

    fetchCustomViews({ requestPolicy: "network-only" })
  })

  return {
    collectionViews,
    createView,
    addViewToCollection,
    customViews,
    defaultCollectionViews,
    isFetching,
    tabs,
    updateViewsInCollection,
    viewCollection,
    selectTab,
    selectedTab,
  }
}

export type UseDataViewTabs = ReturnType<typeof useDataViewTabs>
