import { Button, useConfirmModal } from "@components/shared"
import { DialogRootProps } from "@components/shared/dialog"
import { DialogForm } from "@components/shared/dialog-form"
import { FormField } from "@components/shared/form/form-field"
import { SelectPopover } from "@components/shared/single-select"
import { TextInput } from "@components/shared/text-input"
import Toggle from "@components/shared/toggle"
import { useUser } from "@contexts/user-context"
import { PropertyConfig } from "@elara/db"
import {
  useDeleteConsumablePropertyMutation,
  useUpsertConsumablePropertyMutation,
} from "@graphql/documents/consumable.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { Warning } from "@phosphor-icons/react"
import classNames from "classnames"
import { v4 } from "uuid"
import * as Yup from "yup"

type FormValues = Partial<PropertyConfig & { id: string }>

type Props = {
  initialValues?: FormValues | null
  afterInsert?: () => void
  afterDelete?: () => void
} & Required<Pick<DialogRootProps, "isOpen" | "onOpenChange">>

const ConsumablePropertySchema = Yup.object().shape({
  name: Yup.string().required(
    i18n.t("common:forms.is_required", { field: i18n.t("common:name") })
  ),
})

const CreateEditConsumablePropertyFormDialog: React.FC<Props> = (props) => {
  const { location } = useUser()
  const scope = usePermissionScope(IPermissionScopeEnum.AppAccountManagement)

  const [, upsertProperty] = useUpsertConsumablePropertyMutation()
  const [, deleteProperty] = useDeleteConsumablePropertyMutation()

  const deleteModal = useConfirmModal({
    title: i18n.t("common:delete_token", {
      token: i18n.t("consumables:fields.property_one"),
    }),
    content: i18n.t("consumables:messages.property_delete_confirmation"),
    okText: i18n.t("common:delete"),
    cancelText: i18n.t("common:cancel"),
    onOk: async () => {
      if (props.initialValues && props.initialValues.id) {
        await deleteProperty(
          { locationId: location.id, propertyId: props.initialValues.id },
          scope.context()
        )
        props.onOpenChange(false)
      }
    },
  })

  const onSubmit = async (values: FormValues) => {
    const data = {
      [values.id ?? v4()]: {
        name: values.name,
        type: values.type,
        required: values.required,
      },
    }
    await upsertProperty({ locationId: location.id, data }, scope.context())
  }

  const onDelete = () => deleteModal.show()

  return (
    <>
      <DialogForm<FormValues>
        {...props}
        closeIcon
        footerActions={
          props.initialValues?.id ? (
            <Button color="red" type="tertiary" className="mr-auto" onClick={onDelete}>
              {i18n.t("common:delete")}
            </Button>
          ) : null
        }
        className="md:!max-w-sm"
        title={
          props.initialValues?.id
            ? i18n.t("common:edit_token", {
                token: i18n.t("consumables:fields.property", { count: 1 }),
              })
            : i18n.t("common:create_token", {
                token: i18n.t("consumables:fields.property", { count: 1 }),
              })
        }
        formikConfig={{
          onSubmit,
          validationSchema: ConsumablePropertySchema,
          initialValues: props.initialValues ?? {
            name: "",
            required: false,
            type: "text",
          },
        }}>
        {(formik) => (
          <div className="my-4 grid grid-cols-1 gap-x-4">
            <FormField
              name="name"
              label={i18n.t("consumables:fields.name")}
              className="col-span-1">
              <TextInput required />
            </FormField>
            <FormField
              name="type"
              label={i18n.t("consumables:fields.type")}
              className="col-span-1"
              hint={
                formik.initialValues.type !== formik.values.type ? (
                  <span className="bg-yellow-50 font-medium text-yellow-600">
                    <Warning size={12} className="mr-1 inline" />{" "}
                    {i18n.t("consumables:messages.type_change_warning")}
                  </span>
                ) : undefined
              }>
              {({ field: { value }, helpers }) => (
                <SelectPopover
                  value={value}
                  onChange={helpers.setValue}
                  isClearable={false}
                  items={[
                    {
                      label: i18n.t("common:text"),
                      searchValue: i18n.t("common:text"),
                      value: "text",
                    },
                    {
                      label: i18n.t("common:number"),
                      searchValue: i18n.t("common:number"),
                      value: "number",
                    },
                  ]}
                />
              )}
            </FormField>
            <FormField
              type="checkbox"
              name="required"
              label={i18n.t("common:forms.required")}
              className={classNames("col-span-1", {
                "mt-3": formik.initialValues.type !== formik.values.type,
              })}>
              {({ field, helpers }) => (
                <Toggle
                  checked={field.value}
                  onChange={(e) => helpers.setValue(e.target.checked)}
                />
              )}
            </FormField>
          </div>
        )}
      </DialogForm>
      {deleteModal.component}
    </>
  )
}

export default CreateEditConsumablePropertyFormDialog
