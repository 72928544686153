import { Button } from "@components/shared"
import { FormFieldController } from "@components/shared/form/form-field-controller"
import { TextInput } from "@components/shared/text-input"
import { TextInputWithUnit } from "@components/shared/text-input-with-unit"
import { ToggleGroup, ToggleGroupItem } from "@components/shared/toggle-group"
import { BlockElementToleranceCheck } from "@elara/db"
import i18n from "@i18n"
import { Check, Plus, X } from "@phosphor-icons/react"
import { formatLocaleNumber, parseLocaleNumber } from "@utils"
import { nanoid } from "nanoid"
import React, { ReactNode, useEffect, useState } from "react"
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form"
import { useDebouncedCallback } from "use-debounce"

import { BlockElementFormProps, BlockElementProps } from "./block-element-types"

const AddToleranceCheckFormRowButton = (
  props: Pick<BlockElementFormProps, "control"> & {
    append: (value: any) => void
    arrayName: string
  }
) => {
  const watchFieldArray = useWatch({
    name: props.arrayName,
    control: props.control,
  })
  return (
    <Button
      icon={Plus}
      type="secondary"
      onClick={() => {
        const field = watchFieldArray[watchFieldArray.length - 1]
        props.append({
          nominal_value: null,
          max_tolerance: "",
          tolerance_type: "absolute",
          ...watchFieldArray[watchFieldArray.length - 1],
          id: nanoid(5),
          meta:
            !field?.meta || field?.meta === `${watchFieldArray.length}`
              ? `${watchFieldArray.length + 1}`
              : field?.meta,
        })
      }}>
      {i18n.t("tasks:checklist.tolerance_check.add_row")}
    </Button>
  )
}

export const ToleranceCheckForm = (props: BlockElementFormProps) => {
  const { fields, remove, append } = useFieldArray({
    name: `${props.field}.${props.index}.config.measurements`,
    control: props.control,
  })
  const unit = useWatch({
    name: `${props.field}.${props.index}.config.unit`,
    control: props.control,
  })

  return (
    <div className="flex flex-col">
      <FormFieldController
        control={props.control}
        label={i18n.t("tasks:checklist.tolerance_check.measurement_unit")}
        name={`${props.field}.${props.index}.config.unit`}
        render={({ field }) => (
          <TextInput className="inline-flex w-1/2 min-w-[12rem]" {...field} />
        )}
      />
      <h3 className="mb-3 text-sm font-medium text-gray-700">
        {i18n.t("tasks:checklist.tolerance_check.measurements")}
      </h3>

      <div className="grid grid-cols-[1fr_auto_auto_auto] gap-x-3 gap-y-1.5 text-sm">
        <div className="text-gray-600">
          {i18n.t("tasks:checklist.tolerance_check.meta")}
        </div>
        <div className=" text-gray-600">
          {i18n.t("tasks:checklist.tolerance_check.nominal_value")}
        </div>
        <div className=" text-gray-600">
          {i18n.t("tasks:checklist.tolerance_check.max_tolerance")}
        </div>
        <div />
        {fields.map((field, index) => {
          return (
            <React.Fragment key={field.id}>
              <TextInput
                {...props.control.register(
                  `${props.field}.${props.index}.config.measurements.${index}.meta`
                )}
                autoComplete="nofill"
                className="grow basis-24"
              />

              <TextInputWithUnit
                inputClassName="basis-6 grow max-w-[6rem]"
                unit={unit}
                type="number"
                step="any"
                autoComplete="nofill"
                {...props.control.register(
                  `${props.field}.${props.index}.config.measurements.${index}.nominal_value`,
                  { valueAsNumber: true }
                )}
              />

              <div className="flex w-full max-w-[140px] space-x-2">
                <Controller
                  control={props.control}
                  name={`${props.field}.${props.index}.config.measurements.${index}.max_tolerance`}
                  render={({ field, formState }) => {
                    return (
                      <TextInput
                        type="number"
                        step="any"
                        autoComplete="nofill"
                        className={"inline-flex min-w-0 flex-1 "}
                        hasError={
                          // @ts-ignore
                          !!formState?.errors?.[props.field]?.[props.index]?.config
                            ?.measurements?.[index]?.max_tolerance
                        }
                        {...field}
                        onChange={(e) => {
                          field.onChange(e.target.valueAsNumber)
                        }}
                      />
                    )
                  }}
                />
                <Controller
                  control={props.control}
                  name={`${props.field}.${props.index}.config.measurements.${index}.tolerance_type`}
                  render={({ field }) => (
                    <ToggleGroup
                      type="single"
                      value={field.value}
                      onValueChange={field.onChange}>
                      <ToggleGroupItem value="relative">%</ToggleGroupItem>
                      <ToggleGroupItem value="absolute">
                        {unit ?? `[${i18n.t("meters:fields.unit")}]`}
                      </ToggleGroupItem>
                    </ToggleGroup>
                  )}
                />
              </div>

              <Button type="tertiary" onClick={() => remove(index)} icon={X} color="gray" />
            </React.Fragment>
          )
        })}
      </div>
      <div className="mt-2">
        <AddToleranceCheckFormRowButton
          control={props.control}
          append={append}
          arrayName={`${props.field}.${props.index}.config.measurements`}
        />
      </div>
    </div>
  )
}

function checkIfInToleranceRange(values: {
  nominalValue: string | number
  toleranceValue: string | number
  toleranceType: "absolute" | "relative"
  maxTolerance: number
}) {
  const tolerance =
    typeof values.toleranceValue === "number"
      ? values.toleranceValue
      : parseLocaleNumber(values.toleranceValue || "-")
  const nominal =
    typeof values.nominalValue === "number"
      ? values.nominalValue
      : parseLocaleNumber(values.nominalValue || "-")

  if (!isNaN(tolerance) && !isNaN(nominal)) {
    if (values.toleranceType === "absolute") {
      return Math.abs(tolerance) <= values.maxTolerance ? "in-range" : "out-of-range"
    } else {
      // relative
      return Math.abs(tolerance) <= Math.abs(nominal) * (values.maxTolerance / 100)
        ? "in-range"
        : "out-of-range"
    }
  }

  return "unknown"
}

const ToleranceCheckEntryRow = (props: {
  config: BlockElementToleranceCheck["config"]["measurements"][number]
  unit: string
  mobileHeader?: ReactNode
}) => {
  const form = useFormContext()
  const c = props.config
  const rowValues = useWatch({
    name: `${c.id}`,
  })

  function updateOtherFields(
    currentField: "actual" | "nominal" | "tolerance",
    value: string
  ) {
    const v = parseLocaleNumber(value || "-")
    // Try to update tolerance
    let nominal =
      currentField === "nominal" ? v : parseLocaleNumber(rowValues.nominal || "-")

    let actual = currentField === "actual" ? v : parseLocaleNumber(rowValues.actual || "-")
    let tolerance =
      currentField === "tolerance" ? v : parseLocaleNumber(rowValues.tolerance || "-")

    if (currentField === "actual") {
      actual = v
      if (!isNaN(nominal)) {
        tolerance = Math.round((actual - nominal) * 1e8) / 1e8
      } else if (!isNaN(tolerance)) {
        nominal = Math.round((actual - tolerance) * 1e8) / 1e8
      }
    } else if (currentField === "tolerance") {
      tolerance = v
      if (!isNaN(nominal)) {
        actual = Math.round((nominal + tolerance) * 1e8) / 1e8
      } else if (!isNaN(actual)) {
        nominal = Math.round((actual - tolerance) * 1e8) / 1e8
      }
    } else if (currentField === "nominal") {
      nominal = v
      if (!isNaN(actual)) {
        tolerance = Math.round((actual - nominal) * 1e8) / 1e8
      } else if (!isNaN(tolerance)) {
        actual = Math.round((nominal + tolerance) * 1e8) / 1e8
      }
    }

    if (!isNaN(nominal) && (!isNaN(tolerance) || !isNaN(actual))) {
      currentField !== "actual" &&
        form.setValue(`${c.id}.actual`, formatLocaleNumber(actual))

      currentField !== "tolerance" &&
        form.setValue(`${c.id}.tolerance`, formatLocaleNumber(tolerance))

      currentField !== "nominal" &&
        form.setValue(`${c.id}.nominal`, formatLocaleNumber(nominal))

      form.setValue(
        `${c.id}.inRange`,
        checkIfInToleranceRange({
          maxTolerance: c.max_tolerance,
          nominalValue: nominal,
          toleranceType: c.tolerance_type,
          toleranceValue: tolerance,
        })
      )
    } else {
      form.setValue(`${c.id}.inRange`, "unknown")
      if (currentField == "tolerance" && value === "") {
        form.setValue(`${c.id}.actual`, "")
      }
      if (currentField == "actual" && value === "") {
        form.setValue(`${c.id}.tolerance`, "")
      }
      if (currentField == "nominal" && value === "") {
        form.setValue(`${c.id}.tolerance`, "")
      }
    }
  }

  return (
    <React.Fragment key={c.id}>
      <div className="text-sm text-gray-700">{c.meta}</div>

      {c.nominal_value === null ? (
        <Controller
          name={`${c.id}.nominal`}
          control={form.control}
          render={({ field }) => (
            <TextInputWithUnit
              inputClassName="@mobile:w-16"
              unit={props.unit}
              {...field}
              onChange={(e) => {
                field.onChange(e)
                updateOtherFields("nominal", e.target.value)
              }}
            />
          )}
        />
      ) : (
        <span className="text-center text-sm font-medium text-gray-700 @mobile:text-right">
          <span>{c.nominal_value}</span> <span>{props.unit}</span>
        </span>
      )}
      <div className="max-w-[8rem] text-center text-sm font-medium text-gray-700 @xl:max-w-none @mobile:text-right">
        {c.max_tolerance}
        <span className="pl-1">{c.tolerance_type === "absolute" ? props.unit : "%"}</span>
      </div>
      {props.mobileHeader}

      <Controller
        name={`${c.id}.actual`}
        control={form.control}
        render={({ field }) => (
          <TextInputWithUnit
            inputClassName="@mobile:w-16"
            unit={props.unit}
            {...field}
            onChange={(e) => {
              field.onChange(e)
              updateOtherFields("actual", e.target.value)
            }}
          />
        )}
      />
      <Controller
        name={`${c.id}.tolerance`}
        control={form.control}
        render={({ field }) => (
          <TextInputWithUnit
            inputClassName="@mobile:w-16"
            unit={props.unit}
            {...field}
            onChange={(e) => {
              field.onChange(e)
              updateOtherFields("tolerance", e.target.value)
            }}
          />
        )}
      />
      <Controller
        name={`${c.id}.inRange`}
        control={form.control}
        render={({ field }) => {
          const inRange = field.value
          if (inRange === "in-range") {
            return (
              <div className="flex items-center justify-center text-green-700">
                <Check size={16} weight="bold" />
              </div>
            )
          } else if (inRange === "out-of-range") {
            return (
              <div className="flex items-center justify-center text-red-700">
                <X size={16} weight="bold" />
              </div>
            )
          } else {
            return <div></div>
          }
        }}
      />
    </React.Fragment>
  )
}

export const ToleranceCheckBlockElement = (
  props: BlockElementProps<BlockElementToleranceCheck>
) => {
  const measurements = props.element.response?.measurements ?? {}

  const defaultValues = Object.fromEntries(
    props.element.config.measurements.map((c) => {
      /*The attribute *key* is deprecated and no longer in use.
       *id* has a similar function and acts here as a replacement. */
      c = { ...c, id: c.id ?? c.key }
      const nominal = measurements[c.id]?.nominal_value ?? c.nominal_value ?? ""
      const actual = measurements[c.id]?.actual_value ?? ""
      let tolerance: number | "" = ""
      if (typeof actual == "number" && typeof nominal == "number") {
        tolerance = Math.round((actual - nominal) * 1e8) / 1e8
      }
      return [
        c.id,
        {
          nominal: nominal === "" ? "" : formatLocaleNumber(nominal),
          actual: actual === "" ? "" : formatLocaleNumber(actual),
          tolerance: tolerance === "" ? "" : formatLocaleNumber(tolerance),
          inRange: checkIfInToleranceRange({
            maxTolerance: c.max_tolerance,
            nominalValue: nominal,
            toleranceType: c.tolerance_type,
            toleranceValue: tolerance,
          }),
        },
      ]
    })
  )

  const form = useForm({
    defaultValues: defaultValues,
  })

  const watch = useWatch({
    control: form.control,
  })

  const [formValues, setFormValues] = useState<typeof defaultValues | null>(null)
  const debounce = useDebouncedCallback(setFormValues, 1000)

  useEffect(() => {
    debounce(form.getValues())
  }, [watch])

  useEffect(() => {
    const values = formValues
    if (!values || !form.formState.isDirty) return
    let hasException = false
    let complete = true
    const measurements = Object.fromEntries(
      Object.entries(values).map(([id, value]) => {
        const nominal = parseLocaleNumber(value.nominal || "-")
        const actual = parseLocaleNumber(value.actual || "-")

        if (value.inRange === "out-of-range") {
          hasException = true
        } else if (value.inRange === "unknown") {
          complete = false
        }

        return [
          id,
          {
            nominal_value: isNaN(nominal) ? null : nominal,
            actual_value: isNaN(actual) ? null : actual,
          },
        ]
      })
    )
    props.updateResponse?.(props.element.id, { measurements, hasException, complete })
    // const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    //   props.updateResponse?.(props.element.id, e.target.checked ? { checked: true } : null)
    // }
  }, [formValues])

  return (
    <div className="@container">
      <FormProvider {...form}>
        <div className="grid grid-cols-[auto_auto_auto] items-center gap-3 @mobile:grid-cols-[minmax(0,1fr)_auto_min-content_auto_auto_auto]">
          <div className="hidden text-sm font-medium text-gray-600 @mobile:inline-block">
            {i18n.t("tasks:checklist.tolerance_check.meta")}
          </div>
          <div className="hidden text-center text-sm font-medium text-gray-600 @mobile:inline-block">
            {i18n.t("tasks:checklist.tolerance_check.nominal_value")}
          </div>
          <div className="hidden basis-16 text-end text-sm font-medium text-gray-600 @xl:whitespace-nowrap @mobile:inline-block">
            {i18n.t("tasks:checklist.tolerance_check.max_tolerance")}
          </div>
          <div className="hidden text-center text-sm font-medium text-gray-600 @mobile:inline-block">
            {i18n.t("tasks:checklist.tolerance_check.actual_value")}
          </div>
          <div className="hidden text-center text-sm font-medium text-gray-600 @mobile:inline-block">
            {i18n.t("tasks:checklist.tolerance_check.tolerance")}
          </div>
          <div className="hidden text-sm font-medium text-gray-600 @mobile:inline-block">
            {i18n.t("tasks:checklist.tolerance_check.ok")}
          </div>

          {props.element.config.measurements.map((c) => {
            /*The attribute *key* is deprecated and no longer in use.
             *id* has a similar function and acts here as a replacement. */
            c = { ...c, id: c.id ?? c.key }
            return (
              <>
                <div className="text-sm font-medium text-gray-600 @mobile:hidden">
                  {i18n.t("tasks:checklist.tolerance_check.meta")}
                </div>
                <div className="text-center text-sm font-medium text-gray-600 @mobile:hidden">
                  {i18n.t("tasks:checklist.tolerance_check.nominal_value")}
                </div>
                <div className="basis-16 text-center text-sm font-medium text-gray-600 @xl:whitespace-nowrap @mobile:hidden @mobile:text-end">
                  {i18n.t("tasks:checklist.tolerance_check.max_tolerance")}
                </div>
                <ToleranceCheckEntryRow
                  config={c}
                  unit={props.element.config.unit}
                  key={c.id}
                  mobileHeader={
                    <>
                      <div className="text-center text-sm font-medium text-gray-600 @mobile:hidden">
                        {i18n.t("tasks:checklist.tolerance_check.actual_value")}
                      </div>
                      <div className="text-center text-sm font-medium text-gray-600 @mobile:hidden">
                        {i18n.t("tasks:checklist.tolerance_check.tolerance")}
                      </div>
                      <div className="text-center text-sm font-medium text-gray-600 @mobile:hidden">
                        {i18n.t("tasks:checklist.tolerance_check.ok")}
                      </div>
                    </>
                  }
                />
                <hr className="col-span-full my-3 last:hidden @mobile:hidden"></hr>
              </>
            )
          })}
        </div>
      </FormProvider>
    </div>
  )
}
