import { HStack } from "@components/layout"
import { TextInput } from "@components/shared/text-input"
import { BlockElementHeading } from "@elara/db"
import i18n from "@i18n"
import { Controller } from "react-hook-form"

import { BlockElementFormProps, BlockElementProps } from "./block-element-types"

export const HeadingForm = (props: BlockElementFormProps) => {
  return (
    <HStack align="center" space={8} flex="1">
      <Controller
        name={`${props.field}.${props.index}.config.description`}
        control={props.control}
        render={({ field }) => (
          <TextInput
            autoComplete="nofill"
            className="w-full flex-1 text-lg font-semibold"
            placeholder={i18n.t("common:enter_token", {
              token: i18n.t("tasks:fields.headline"),
            })}
            {...field}
          />
        )}
      />
    </HStack>
  )
}

export const HeadingBlockElement = (props: BlockElementProps<BlockElementHeading>) => {
  return (
    <h2 className="break-words text-lg font-semibold">
      {props.element.config.description}
    </h2>
  )
}
