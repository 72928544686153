import { HStack } from "@components/layout"
import { useBreakpoint } from "@contexts/breakpoints"
import { useDisclosure } from "@hooks"
import i18n from "@i18n"
import { CaretDown, CaretUp, Plus } from "@phosphor-icons/react"
import Icons from "@resources/icons"
import { cn } from "@utils"
import { findAllTreeList, TreeLike } from "@utils/tree"
import classNames from "classnames"
import { dequal } from "dequal"
import React, { cloneElement, isValidElement, ReactNode } from "react"

import { ComboboxMultiSelect, ComboboxMultiSelectProps, Item } from "./combobox-select"
import {
  PopoverAnchor,
  PopoverContent,
  PopoverContentProps,
  PopoverRoot,
  PopoverTrigger,
} from "./popover"
import { IconSpacer } from "./spacers"
import TouchTargetSize from "./touch-target-size"

export function buildTagsSummary<T>(
  items: T[],
  limit: number,
  mapItemsToNodes: (item: T, idx: number) => ReactNode
) {
  return items.slice(0, limit).map(mapItemsToNodes)
}

export const MultiSelectSummary = <T,>({
  mode,
  firstItemLabel,
  countLabel,
  items,
  limit,
  itemsToIcons,
  spaceBetweenIconsClass,
  iconBgClass,
  iconBorderClass,
  className,
  isStaticRow,
}: {
  mode: "count" | "summary"
  firstItemLabel: ReactNode
  limit: number
  items: T[]
  itemsToIcons: (item: T) => ReactNode
  spaceBetweenIcons?: number
  countLabel?: string
  spaceBetweenIconsClass?: string
  iconBgClass?: string
  iconBorderClass?: string
  className?: string
  isStaticRow?: boolean
}) => {
  if (!items.length) return null

  const icons = (
    <div className="z-50 flex min-w-0 items-center px-1">
      {items
        .slice(0, limit)
        .map(itemsToIcons)
        .map((iconNode, idx) =>
          isValidElement(iconNode)
            ? cloneElement(
                iconNode,
                {
                  key: idx,
                  className: classNames(
                    iconNode.props.className,
                    `${iconBgClass ?? "bg-white"} ${iconBorderClass ?? "border-white"} ${
                      spaceBetweenIconsClass ?? "!-ml-1"
                    } !rounded-full !border-[1px] !pl-0`
                  ),
                } as React.HTMLAttributes<HTMLElement>,
                null
              )
            : null
        )}
    </div>
  )

  const content =
    mode === "count" ? (
      <IconSpacer
        icon={icons}
        preset={isStaticRow ? "row" : "compactSelect"}
        className="isolate min-w-0">
        <span
          className={classNames(
            "overflow-hidden text-sm text-ellipsis text-left truncate shrink-[2]",
            className
          )}>
          {items.length === 1 ? firstItemLabel : `${items.length} ${countLabel}`}
        </span>
      </IconSpacer>
    ) : (
      <IconSpacer
        icon={icons}
        preset={isStaticRow ? "row" : "compactSelect"}
        className="isolate min-w-0">
        <div
          className={classNames(
            "overflow-hidden text-sm text-ellipsis text-left truncate shrink-[6]",
            className
          )}>
          <span>{firstItemLabel}</span>
        </div>
        {items.length > 1 && <div className="ml-1 text-sm">{`+${items.length - 1}`}</div>}
      </IconSpacer>
    )

  return content
}

type SelectedBadgeProps<T> = {
  item: TreeLike<Item<T>>
  onRemove?: (item: Item<T>) => void
  className?: string
}

export const SelectedBadge = <T,>({ item, onRemove, className }: SelectedBadgeProps<T>) => (
  <IconSpacer
    preset="select"
    icon={item.icon}
    className={classNames(
      "justify-between min-w-0 text-sm truncate bg-white border border-gray-200 rounded items-center select-none py-1",
      className
    )}>
    <span className="mr-1 inline-flex min-w-0 truncate font-medium text-gray-700">
      {item.label}
    </span>
    {onRemove && (
      <button
        type="button"
        className="relative shrink-0 rounded-r pl-1 text-lg text-gray-500 hover:text-gray-900 print:hidden"
        onClick={() => {
          onRemove(item)
        }}>
        <TouchTargetSize />
        <Icons.Close height={16} width={16} />
      </button>
    )}
  </IconSpacer>
)

export type MultiSelectPopoverProps<T> = React.PropsWithChildren<
  Omit<ComboboxMultiSelectProps<T>, "onChange" | "placeholder"> & {
    placeholder?: string
    className?: string
    classForTrigger?: string
    comboBoxKey?: string
    retainedItems?: ComboboxMultiSelectProps<T>["items"]
    compact?: boolean
    title?: string
    isOpen?: boolean
    disabled?: boolean
    showArrow?: boolean
    onChange?: (selectedValues: T[]) => void
    onOpenChange?: (isOpen: boolean) => void
    renderCompactSelectedValues?: (selectedValues: TreeLike<Item<T>>[]) => ReactNode
    popoverContentProps?: PopoverContentProps
  }
>

export const popoverTriggerClasses =
  "relative font-medium rounded border border-white hover:border-gray-200 text-sm text-gray-500 hover:text-gray-700 h-8 min-w-0 focus-visible:border-gray-300"

export const popoverCompactTriggerClasses =
  "relative rounded border flex flex-col justify-center !cursor-pointer !border-white text-gray-500 hover:text-gray-700 bg-gray-100 hover:!border-gray-200 text-sm h-8 min-w-0 focus-visible:!border-gray-300"

export const MultiSelectPopover = <T,>({
  items,
  value,
  className,
  classForTrigger,
  compact = false,
  title,
  retainedItems,
  comboBoxKey,
  isOpen,
  disabled,
  showArrow,
  renderCompactSelectedValues,
  onChange,
  onOpenChange,
  onCreate,
  popoverContentProps,
  ...props
}: MultiSelectPopoverProps<T>) => {
  const multiSelectPopover = useDisclosure({
    isOpen,
    onOpenChange,
  })

  const bp = useBreakpoint()
  const selectedItems = findAllTreeList(
    retainedItems ? items.concat(retainedItems) : items,
    (item) => value?.some?.((v) => dequal(v, item.value)) ?? false
  )

  const onRemoveBadge = (item: Item<T>) =>
    onChange?.(value?.filter((v) => !dequal(v, item.value))!)

  const Trigger = (
    <PopoverTrigger
      disabled={disabled}
      className={classNames(
        compact ? popoverCompactTriggerClasses : popoverTriggerClasses,
        classForTrigger,
        "flex flex-col justify-center shrink-0"
      )}
      style={{ padding: compact ? "0" : undefined }}>
      <TouchTargetSize />
      {props.children ? (
        props.children
      ) : (
        <IconSpacer
          icon={<Plus className="mr-1.5 h-[1em] w-[1em] shrink-0" />}
          preset={compact ? "compactSelect" : "emptyMultiSelectTrigger"}>
          <span className="min-w-0 truncate">{title ?? i18n.t("select")}</span>
        </IconSpacer>
      )}
    </PopoverTrigger>
  )

  return (
    <PopoverRoot
      open={multiSelectPopover.isOpen}
      onOpenChange={!disabled ? multiSelectPopover.changeOpen : undefined}>
      {!compact && (
        <div className="flex min-w-0 flex-1 flex-row flex-wrap gap-2">
          <PopoverAnchor className="-mr-2" />
          {selectedItems.map((item) => (
            <div className="flex min-w-0" key={props.valueToString(item.value)}>
              <SelectedBadge
                item={item}
                onRemove={onRemoveBadge}
                key={props.valueToString(item.value)}
              />
            </div>
          ))}
          <div className="flex min-w-0 print:hidden">{props.children ?? Trigger}</div>
        </div>
      )}

      {compact && (
        <div className="flex min-w-0">
          <div className="flex min-w-0">
            {selectedItems.length ? (
              <PopoverTrigger
                className={classNames(popoverCompactTriggerClasses, classForTrigger, {
                  "!text-gray-900 overflow-hidden": selectedItems.length,
                })}>
                <TouchTargetSize />
                {value ? (
                  <HStack space={8} className="max-w-full">
                    {renderCompactSelectedValues?.(selectedItems)}
                    {showArrow &&
                      (value.length && multiSelectPopover.isOpen ? (
                        <CaretUp className="h-4 w-4 text-gray-700 opacity-50" />
                      ) : (
                        <CaretDown className="h-4 w-4 text-gray-700 opacity-50" />
                      ))}
                  </HStack>
                ) : null}
              </PopoverTrigger>
            ) : (
              Trigger
            )}
          </div>
        </div>
      )}

      <PopoverContent
        sideOffset={4}
        side="bottom"
        align="start"
        alignOffset={0}
        className={cn("rounded-t", className)}
        {...popoverContentProps}>
        <ComboboxMultiSelect
          className="max-w-sm"
          key={comboBoxKey}
          value={value}
          items={items}
          onChange={async (selectedValues, _selectedItems, keepOpen) => {
            onChange?.(selectedValues)
            multiSelectPopover.changeOpen(keepOpen)
          }}
          onCreate={onCreate}
          {...props}
          placeholder={props.placeholder ?? i18n.t("common:search")}
          listMaxHeight={bp.mobile ? window.innerHeight : 250}
        />
      </PopoverContent>
    </PopoverRoot>
  )
}
