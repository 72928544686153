import { IPermissionScopeEnum } from "@elara/db"
import i18n from "@i18n"

export function getPermissionScopeName(scope: IPermissionScopeEnum): string {
  return i18n.t(`settings:permissions.scope.${scope}.title`, { defaultValue: "" })
}

export function getPermissionScopeDescription(scope: IPermissionScopeEnum) {
  return i18n.t(`settings:permissions.scope.${scope}.description`, { defaultValue: "" })
}
