import { AssetSingleSelect } from "@components/asset"
import AssetAddEditMeterForm from "@components/asset/detail/meter/create-and-edit-meter-form"
import NewAddEditMeterForm from "@components/asset/detail/meter/new-create-and-edit-meter-form"
import { VStack } from "@components/layout"
import Button from "@components/shared/button"
import { FormFieldController } from "@components/shared/form/form-field-controller"
import { SelectPopover } from "@components/shared/single-select"
import { TextInput } from "@components/shared/text-input"
import { Tooltip } from "@components/shared/tooltip"
import { useFeature } from "@contexts/feature-flag-context"
import { useUser } from "@contexts/user-context"
import { BlockElementMeterReading, IMeterTypeEnum, IPermissionScopeEnum } from "@elara/db"
import { useOrderBy } from "@elara/select"
import { useMetersByAssetIdQuery } from "@graphql/documents/asset.generated"
import { useUpsertMeterReadingMutation } from "@graphql/documents/asset-meter.generated"
import { uuid } from "@graphql/scalars"
import { useDisclosure, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { Plus, Shapes, Warning } from "@phosphor-icons/react"
import Icons from "@resources/icons"
import { useEffect, useMemo, useState } from "react"
import { useWatch } from "react-hook-form"
import { Link } from "react-router-dom"

import { BlockElementFormProps, BlockElementProps } from "./block-element-types"

type MeterSelectFormProps = {
  value: uuid
  assetId?: uuid
  onChange: (value: uuid | null) => void
}

const MeterSelectForm = ({ assetId, ...props }: MeterSelectFormProps) => {
  const addMeterDialog = useDisclosure()

  const [queryRes] = useMetersByAssetIdQuery({
    variables: { assetId: assetId! },
    requestPolicy: "cache-and-network",
    pause: !assetId,
  })
  const [meters] = useOrderBy(queryRes?.data?.meter ?? [], { name: "asc" })
  const items =
    meters.map((m) => ({
      searchValue: m.name,
      label: m.name,
      value: m.id,
    })) ?? []

  // If we get a query response for a new asset id, select the first value
  useEffect(() => {
    if (!queryRes.data?.asset_by_pk?.id) return

    if (meters?.[0] && !meters.some((m) => m.id === props.value)) {
      props.onChange?.(meters[0].id)
    } else if (!meters?.some((m) => m.id === props.value)) {
      props.onChange?.(null)
    }
  }, [queryRes.data?.asset_by_pk?.id])

  const hasMaintenanceFeature = useFeature("maintenance")

  return (
    <>
      <SelectPopover
        disabled={!assetId}
        size="small"
        items={items}
        placeholder={i18n.t("common:select_token", {
          token: i18n.t("common:meter", { count: 1 }),
        })}
        {...props}
      />
      {queryRes.data?.asset_by_pk?.name && (
        <Button
          type="tertiary"
          onClick={addMeterDialog.onOpen}
          disabled={!assetId}
          icon={Plus}
          size="small"
          className="mt-2 self-start">
          {i18n.t("meters:messages.create_meter_for_object", {
            object: queryRes.data?.asset_by_pk?.name,
          })}
        </Button>
      )}
      {assetId &&
        (hasMaintenanceFeature ? (
          <NewAddEditMeterForm
            assetId={assetId}
            afterSubmit={(meter) => props.onChange(meter.id)}
            isOpen={addMeterDialog.isOpen}
            onOpenChange={addMeterDialog.changeOpen}
          />
        ) : (
          <AssetAddEditMeterForm
            assetId={assetId}
            afterSubmit={(meter) => props.onChange(meter.id)}
            isOpen={addMeterDialog.isOpen}
            onOpenChange={addMeterDialog.changeOpen}
          />
        ))}
    </>
  )
}

export const MeterReadingForm = (props: BlockElementFormProps) => {
  const assetId = useWatch({
    name: `${props.field}.${props.index}.config.asset_id`,
    control: props.control,
  })

  return (
    <VStack space={8} flex="1">
      <div>
        <FormFieldController
          name={`${props.field}.${props.index}.config.asset_id`}
          control={props.control}
          hint={
            assetId === null && props.isTemplate
              ? i18n.t("meters:messages.dynamic_reading")
              : undefined
          }
          label={i18n.t("common:asset", { count: 1 })}
          render={({ field }) => (
            <AssetSingleSelect
              value={field.value}
              size="small"
              onChange={field.onChange}
              recommendedItems={props.selectedAssetIds}
              placeholder={props.isTemplate ? i18n.t("common:dynamic") : undefined}
              nullOption={
                props.isTemplate
                  ? {
                      searchValue: "Kein",
                      label: (
                        <span className="text-gray-500">{i18n.t("common:dynamic")}</span>
                      ),
                    }
                  : undefined
              }
            />
          )}
        />

        <FormFieldController
          name={`${props.field}.${props.index}.config.meter_id`}
          label={i18n.t("common:meter", { count: 1 })}
          control={props.control}
          render={({ field }) => (
            <MeterSelectForm
              assetId={assetId}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </div>
    </VStack>
  )
}

export const MeterReadingBlockElement = (
  props: BlockElementProps<BlockElementMeterReading>
) => {
  const user = useUser()

  const meter = props.element.relationship_data?.meter
  const meterReading = props.element.relationship_data?.meter_reading
  const [, upsertMeterReading] = useUpsertMeterReadingMutation()
  const scope = usePermissionScope(IPermissionScopeEnum.AppDataEntry)

  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState<string | number>(meterReading?.value ?? "")

  const submit = async () => {
    if (typeof value !== "number") return
    setIsLoading(true)
    const res = await upsertMeterReading(
      {
        data: {
          meter_id: props.element.config.meter_id,
          id: props.element.config.meter_reading_id,
          measured_by_id: user.id,
          value,
        },
      },
      scope.context()
    )

    const meterReadingId = res.data?.insert_meter_reading_one?.id
    if (meterReadingId) {
      await props.updateResponse(props.element.id, {
        meter_reading_id: meterReadingId,
        value,
      })
    }
    setIsLoading(false)
  }

  useEffect(() => {
    setValue(meterReading?.value ?? "")
  }, [meterReading?.value])

  const isOutsideRange = useMemo(() => {
    if (typeof value !== "number") return false
    if (meter && meter.range_end && value > meter.range_end) {
      return true
    } else if (meter && meter.range_start && value < meter.range_start) {
      return false
    }

    return false
  }, [meter, value])

  return (
    <VStack space={4} className="w-full">
      <div className="flex flex-wrap items-center gap-2">
        <span className="text-sm font-medium text-gray-700">
          {i18n.t("meters:fields.reading", { count: 1 })}
        </span>
        {!props.element.config.asset_id ? (
          <Tooltip content={i18n.t("meters:messages.dynamic_reading")}>
            <span className="text-sm text-gray-500">{i18n.t("common:dynamic")}</span>
          </Tooltip>
        ) : (
          <>
            <Link
              to={`/object/${props.element.relationship_data?.asset?.id}/meter/${meter?.id}`}
              className="rounded p-1 text-sm font-medium text-gray-800 hover:bg-gray-100 hover:text-black">
              {meter?.name}
            </Link>
            <div className="flex items-center">
              <div>
                {props.element.relationship_data?.asset?.thumbnail_url ? (
                  <img
                    src={props.element.relationship_data?.asset?.thumbnail_url!}
                    className="mr-2 h-6 w-6 shrink-0 rounded"
                  />
                ) : (
                  <div className="mr-2 flex h-6 w-6 shrink-0 items-center justify-center rounded border border-gray-200 bg-gray-50 text-gray-300">
                    <Shapes />
                  </div>
                )}
              </div>
              <div className="truncate text-sm font-medium text-gray-600">
                {props.element.relationship_data?.asset?.name}
              </div>
            </div>
          </>
        )}
      </div>
      <div>
        <div className="mb-1 font-medium">
          {meter?.meter_type === IMeterTypeEnum.Measurement &&
            (meter?.range_start !== null || meter?.range_end !== null) && (
              <div>
                {meter.is_deviation ? (
                  <span className="text-sm text-gray-500">
                    <span>
                      {i18n.t("meters:fields.standard_value")}: {meter?.norm_value}{" "}
                      {meter?.unit}
                    </span>
                    <span className="mx-1">•</span>
                    <span>
                      {i18n.t("meters:fields.tolerance")}: {meter?.range_start ?? "-"} / +
                      {meter?.range_end ?? "-"} {meter?.unit}
                    </span>
                  </span>
                ) : (
                  <span className="text-sm text-gray-600">
                    {i18n.t("meters:fields.tolerance_range")}:{" "}
                    {meter?.range_start ?? "offen"} - {meter?.range_end ?? "offen"}{" "}
                    {meter?.unit}
                  </span>
                )}
              </div>
            )}
        </div>
        <div className="flex flex-wrap items-center gap-2">
          <TextInput
            placeholder={
              meter?.meter_type === IMeterTypeEnum.Measurement && meter.is_deviation
                ? i18n.t("meters:types.deviation")
                : i18n.t("meters:types.measurement")
            }
            className="w-32 text-right"
            disabled={props.blockResponseUpdate}
            type="number"
            value={value}
            onChange={(e) => {
              const value = e.target.valueAsNumber
              if (isNaN(value)) {
                setValue("")
              } else {
                setValue(value)
              }
            }}
          />
          <div className="text-sm text-gray-600">{meter?.unit}</div>

          <Button
            type="secondary"
            icon={Icons.Check}
            onClick={submit}
            isLoading={isLoading}
            disabled={typeof value !== "number"}>
            {i18n.t("common:save")}
          </Button>
        </div>

        {typeof value === "number" && isOutsideRange && (
          <div>
            <div className="mt-2 inline-flex items-center rounded bg-orange-100 px-2 py-1 text-sm text-orange-700">
              <Warning size={16} className="mr-2" />
              {i18n.t("meters:messages.outside_tolerance_range", {
                rangeStart: meter?.range_start ?? "-",
                rangeEnd: meter?.range_end ?? "-",
                unit: meter?.unit,
              })}
            </div>
          </div>
        )}
        {value !== meterReading?.value && typeof value === "number" && (
          <div className="mt-2 inline-flex items-center rounded bg-yellow-100 px-2 py-1 text-sm text-yellow-800">
            <Warning size={16} className="mr-2" />
            {i18n.t("meters:messages.reading_not_saved_yet")}
          </div>
        )}
      </div>
    </VStack>
  )
}
