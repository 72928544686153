import { MaintenanceInnerForm } from "@components/maintenance/components/maintenance-inner-form"
import MeterReadingTriggerDigest from "@components/maintenance/components/meter-reading-trigger-digest"
import TimeIntervalTriggerDigest from "@components/maintenance/components/time-interval-trigger-digest"
import { MaintenanceSchema } from "@components/maintenance/types"
import { prepareTimeIntervalTriggerPayload } from "@components/maintenance/utils"
import { AssetTag, Button } from "@components/shared"
import { alertDialog } from "@components/shared/alert-dialog-provider"
import ScrollArea from "@components/shared/scroll-area"
import toast from "@components/shared/toast"
import {
  IMaintenanceTriggerTypeEnum,
  MeterReadingTriggerOptions,
  TimeIntervalTriggerOptions,
} from "@elara/db"
import { IMaintenanceFragment } from "@graphql/documents/fragments.generated"
import {
  useDeleteMaintenanceTriggerMutation,
  useUpdateMaintenanceMutation,
} from "@graphql/documents/maintenance.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks/use-permission-scope"
import i18n from "@i18n"
import { toCalendarDate } from "@internationalized/date"
import { Pencil } from "@phosphor-icons/react"
import { parseDate } from "@utils"
import { formatDate, formatDuration } from "@utils/date"
import { now, parseAbsolute } from "@utils/tzdate"
import { Formik } from "formik"
import React, { useState } from "react"
import { PropsWithChildren } from "react"
import { Trans } from "react-i18next"
import { Link, useOutletContext } from "react-router-dom"
import { toFormikValidationSchema } from "zod-formik-adapter"

const EditDetails = (props: {
  maintenance: IMaintenanceFragment
  closeEdit: () => void
}) => {
  const { maintenance } = props

  const [, updateMaintenance] = useUpdateMaintenanceMutation()
  const [, deleteTrigger] = useDeleteMaintenanceTriggerMutation()

  const workOrderEditScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)
  const workOrderDeleteScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderDelete)

  const handleDelete = (triggerId: string | undefined, callback: () => void) => {
    alertDialog({
      danger: true,
      title: i18n.t("maintenance:dialogs.delete_trigger.title"),
      description: i18n.t("maintenance:dialogs.delete_trigger.message"),
      actionText: i18n.t("common:delete"),
      onAction: async () => {
        if (triggerId) {
          await deleteTrigger({ id: triggerId }, workOrderDeleteScope.context())
        }
        callback?.()
      },
    })
  }

  return (
    <ScrollArea vertical viewportAsChild>
      <Formik
        enableReinitialize
        initialValues={{
          ...maintenance,
          triggers: maintenance.triggers.map((t) => {
            if (t.type === "time") {
              const payload = t.payload as TimeIntervalTriggerOptions["payload"]
              return {
                ...t,
                payload: {
                  ...t.payload,
                  nextDate: toCalendarDate(
                    payload.nextAt ? parseAbsolute(payload.nextAt) : now()
                  ).toString(),
                  nextTime: payload.nextTime && payload.nextAt,
                },
              }
            }
            return t
          }),
        }}
        validationSchema={toFormikValidationSchema(MaintenanceSchema)}
        onSubmit={async (values) => {
          const triggers = (values.triggers ?? []).map((t) => {
            const trigger = { ...t }

            if (trigger.type === IMaintenanceTriggerTypeEnum.Time) {
              trigger.payload = prepareTimeIntervalTriggerPayload(
                trigger.payload as TimeIntervalTriggerOptions["payload"]
              )
            }

            return trigger
          })

          const res = await updateMaintenance(
            {
              id: maintenance.id,
              maintenance: {
                name: values.name,
                description: values.description,
                paused: maintenance.paused,
                create_individual_tasks_for_objects:
                  values.create_individual_tasks_for_objects,
              },
              triggers: triggers.map((trigger) => ({
                payload: trigger.payload,
                id: trigger.id,
                type: trigger.type,
                maintenance_id: maintenance.id,
              })),
            },
            workOrderEditScope.context()
          )

          if (res.error) {
            toast.error(i18n.t("common:generic_toast_error"))
          } else {
            toast.success(i18n.t("maintenance:messages.update_success"))
            props.closeEdit()
          }
        }}>
        {() => (
          <MaintenanceInnerForm
            editForm
            className="m-5"
            onDiscard={props.closeEdit}
            handleDelete={handleDelete}
          />
        )}
      </Formik>
    </ScrollArea>
  )
}

const MaintenanceIntervalInfo = (props: PropsWithChildren<{}>) => (
  <div className="space-y-1 rounded-lg rounded-t-none bg-gray-100 p-3">
    <div className="mb-2 text-xs font-medium text-gray-600">
      {i18n.t("maintenance:labels.triggers.maintenance_interval")}
    </div>
    {props.children}
  </div>
)

const InformationTab = () => {
  const { maintenance } = useOutletContext<{ maintenance: IMaintenanceFragment }>()
  const [edit, setEdit] = useState(false)

  if (edit) {
    return <EditDetails maintenance={maintenance} closeEdit={() => setEdit(false)} />
  }

  return (
    <div className="flex @container">
      <div className="box-content flex shrink basis-[42rem] flex-col space-y-4 p-4">
        <div className="flex items-center justify-between">
          <h2 className="text-base font-medium @lg:text-lg @2xl:font-bold @3xl:text-xl">
            {i18n.t("maintenance:labels.details.title")}
          </h2>
          <Button type="secondary" onClick={() => setEdit(true)} icon={Pencil}>
            {i18n.t("common:edit")}
          </Button>
        </div>

        {(maintenance.template?.assets ?? []).length > 0 && (
          <div>
            <h2 className="mb-3 text-lg font-semibold text-gray-800">
              {i18n.t("common:asset", { count: maintenance.template!.assets.length || 1 })}
            </h2>
            <div>
              {maintenance.template?.assets.map(({ asset }) => (
                <AssetTag asset={asset} key={asset.id} withLink />
              ))}
            </div>
          </div>
        )}

        <div>
          <h2 className="mb-3 text-lg font-semibold text-gray-800">
            {i18n.t("maintenance:fields.triggers")}
          </h2>

          <div className="flex flex-col space-y-4">
            {maintenance.triggers.length === 0 && (
              <div className="flex items-center justify-center text-gray-500">
                {/* <Icons.Empty className="w-12 h-12" /> */}
                <div className="text-center">
                  <h3 className="text-sm font-medium">
                    {i18n.t("maintenance:triggers.no_triggers")}
                  </h3>
                  <p className="mt-1 text-sm">
                    {i18n.t("maintenance:triggers.add_trigger")}
                  </p>
                  <Button onClick={() => setEdit(true)} className="mt-3">
                    {i18n.t("maintenance:triggers.add_trigger_button")}
                  </Button>
                </div>
              </div>
            )}
            {maintenance.triggers.map((trigger, idx) => {
              let el = <></>

              if (trigger.type === IMaintenanceTriggerTypeEnum.Time) {
                const payload = trigger.payload as TimeIntervalTriggerOptions["payload"]
                const nextAt = parseDate(payload.nextAt)
                // const createNextAt = createNext(payload.createNextAt)
                el = (
                  <div className="space-y-1 rounded-lg bg-gray-50 pt-4 text-sm">
                    <div className="px-3 pb-3 text-base font-medium leading-none text-gray-700">
                      {i18n.t("maintenance:labels.triggers.time.title")}
                    </div>
                    <MaintenanceIntervalInfo>
                      <TimeIntervalTriggerDigest
                        payload={payload}
                        className="font-medium text-gray-800"
                      />
                      {payload.nextAt && (
                        <div>
                          <Trans
                            i18n={i18n}
                            i18nKey="maintenance:labels.triggers.time.next_at"
                            values={{
                              nextAt: formatDate(nextAt, payload.nextTime ? "Pp" : "P"),
                            }}
                            components={{
                              bold: <span className="font-medium " />,
                            }}
                          />
                        </div>
                      )}
                      <hr className="!my-3" />
                      <div className="text-gray-600">
                        {i18n.t("maintenance:labels.triggers.time.create_before", {
                          duration: formatDuration(payload.createBefore),
                        })}
                      </div>
                    </MaintenanceIntervalInfo>
                  </div>
                )
              } else if (trigger.type === IMaintenanceTriggerTypeEnum.Meter) {
                const payload = trigger.payload as MeterReadingTriggerOptions["payload"]

                el = (
                  <div className="space-y-1 rounded-lg bg-gray-50 pt-4 text-sm">
                    <div className="px-3 pb-1 text-base font-medium leading-none text-gray-700">
                      {i18n.t("maintenance:labels.triggers.meter.title")}
                    </div>
                    <div className="px-3 pb-3">
                      <Link
                        to={`/meter/${payload.meterId}`}
                        className="-mx-1 my-1 inline-flex items-center rounded-lg p-1 font-medium text-gray-800 hover:bg-gray-100">
                        {trigger.meter?.name}
                      </Link>
                      {trigger.meter?.asset && <AssetTag asset={trigger.meter?.asset} />}
                    </div>
                    <MaintenanceIntervalInfo>
                      <div className="text-sm text-gray-800">
                        <MeterReadingTriggerDigest
                          payload={payload}
                          unit={trigger.meter?.unit}
                          className="font-medium"
                        />
                      </div>
                      {payload.variant === "interval" && (
                        <>
                          <div className="text-gray-700">
                            <Trans
                              i18n={i18n}
                              i18nKey="maintenance:labels.triggers.meter.next_at"
                              values={{
                                nextAt: `${payload.nextAt} ${trigger.meter?.unit}`,
                              }}
                              components={{ bold: <span className="font-medium " /> }}
                            />
                          </div>
                          <div className="text-gray-600">
                            {i18n.t("maintenance:labels.triggers.meter.lastAt", {
                              value: `${trigger.meter?.last_readings?.[0]?.value} ${trigger.meter?.unit}`,
                              measuredAt: formatDate(
                                parseDate(trigger.meter?.last_readings?.[0]?.measured_at),
                                "P"
                              ),
                            })}
                          </div>
                        </>
                      )}
                      <hr className="!my-3" />
                      <div className="text-gray-600">
                        {i18n.t("maintenance:labels.triggers.meter.due_after", {
                          duration: formatDuration(payload.dueAfter),
                        })}
                      </div>
                    </MaintenanceIntervalInfo>
                  </div>
                )
              }

              return (
                <React.Fragment key={trigger.id}>
                  {idx > 0 && (
                    <div className="flex items-center space-x-3 text-xs">
                      <div className="h-px flex-1 bg-gray-200" />
                      <span className="font-medium text-gray-500">oder</span>
                      <div className="h-px flex-1 bg-gray-200" />
                    </div>
                  )}
                  {el}
                </React.Fragment>
              )
            })}
          </div>
        </div>
      </div>
      <div className="flex-1" />
    </div>
  )
}

export default InformationTab
