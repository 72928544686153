import { StaticRichText } from "@components/shared/static-rich-text"
import { useFeature } from "@contexts/feature-flag-context"
import { useDisclosure } from "@hooks"
import i18n from "@i18n"
import {
  ArrowClockwise,
  ArrowCounterClockwise,
  CaretDown,
  CaretLeft,
  CaretRight,
  Code,
  HighlighterCircle,
  ListBullets,
  ListNumbers,
  MagicWand,
  TextAlignCenter,
  TextAlignLeft,
  TextAlignRight,
  TextB,
  TextItalic,
  TextStrikethrough,
  TextUnderline,
} from "@phosphor-icons/react"
import { Microphone } from "@phosphor-icons/react"
import Highlight from "@tiptap/extension-highlight"
import Link from "@tiptap/extension-link"
import Mention from "@tiptap/extension-mention"
import Placeholder from "@tiptap/extension-placeholder"
import TextAlign from "@tiptap/extension-text-align"
import Underline from "@tiptap/extension-underline"
import {
  Editor,
  EditorContent,
  EditorOptions,
  Extension,
  JSONContent,
  markPasteRule,
  PasteRuleMatch,
  useEditor,
} from "@tiptap/react"
import StarterKit from "@tiptap/starter-kit"
import { cn } from "@utils"
import React, { useEffect, useImperativeHandle, useRef, useState } from "react"

import Button from "./button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./dropdown"
import { useLiveTranscription } from "./live-transcription"
import LoadingIndicator from "./loading-indicator"
import { PopoverAnchor, PopoverContent, PopoverRoot } from "./popover"
import { MentionItem } from "./rich-text-mention-list"
import suggestion from "./rich-text-suggest"
import { Tooltip } from "./tooltip"

function findMentions(content: JSONContent) {
  const mentions: { id: string; label: string }[] = []
  const recurse = (c: JSONContent) => {
    if (c.type === "mention" && c.attrs && typeof c.attrs === "object") {
      const { id, label } = c.attrs
      if (!mentions.some((m) => m.id === id)) {
        mentions.push({ id, label })
      }
    } else if (c.content) {
      c.content.forEach(recurse)
    }
  }
  recurse(content)
  return mentions
}

export type RichTextHandle = {
  getContent: () => { html: string; mentions: MentionItem[] }
  // Removes all content
  clearContent: () => void
  setContent: (content: string) => void
  editor: Editor | null
}

export type RichTextProps = Partial<EditorOptions> & {
  id?: string
  className?: string
  toolbarClassName?: string
  contentClassName?: string
  placeholder?: string
  showToolbar?: boolean
  hasError?: boolean
  showSendButton?: boolean
  footerAction?: React.ReactNode
  onSubmit?: () => void
  showFooterIfNotEmpty?: boolean
}

const NAIVE_URI_REGEX = /\w+:(\/\/)[^\s]+/g

const CustomLink = Link.extend({
  addPasteRules() {
    return [
      markPasteRule({
        find: (text) => {
          const r = new RegExp(NAIVE_URI_REGEX)
          const matches: PasteRuleMatch[] = []
          let m: RegExpExecArray | null = null
          while (null != (m = r.exec(text))) {
            matches.push({ text: m[0], index: m.index, data: { href: m[0] } })
          }
          return matches
        },
        type: this.type,
        getAttributes: (match) => ({ href: match?.data?.href }),
      }),
    ]
  },
})

type MenuBarProps = {
  editor: Editor | null
  toolbarClassName?: string
}

const IconButton: React.FC<
  React.PropsWithChildren<{ isActive?: boolean; onClick?: () => void; className?: string }>
> = ({ isActive = false, onClick, className, children }) => {
  return (
    <span
      onClick={onClick}
      className={cn(
        "cursor-pointer hover:bg-gray-200 rounded py-1 px-1.5 h-6",
        { "bg-gray-900 hover:bg-gray-900 text-white": isActive },
        className
      )}>
      {children}
    </span>
  )
}

const MenuBar: React.FC<MenuBarProps> = ({ editor, toolbarClassName }) => {
  if (!editor) return null

  return (
    <div
      className={cn(
        "sticky top-0 z-10 rounded-t-sm gap-2 bg-gray-50 overflow-y-auto py-2 px-2 shrink-0 hidden sm:flex",
        toolbarClassName
      )}>
      <IconButton
        isActive={editor.isActive("bold")}
        onClick={() => editor.chain().focus().toggleBold().run()}>
        <Tooltip asChild content="Bold">
          <TextB className="block cursor-pointer" />
        </Tooltip>
      </IconButton>

      <IconButton
        isActive={editor.isActive("italic")}
        onClick={() => editor.chain().focus().toggleItalic().run()}>
        <TextItalic className="block" />
      </IconButton>

      <IconButton
        isActive={editor.isActive("strike")}
        onClick={() => editor.chain().focus().toggleStrike().run()}>
        <TextStrikethrough className="block" />
      </IconButton>

      <IconButton
        isActive={editor.isActive("underline")}
        onClick={() => editor.chain().focus().toggleUnderline().run()}>
        <TextUnderline className="block" />
      </IconButton>

      <IconButton
        isActive={editor.isActive("highlight")}
        onClick={() => editor.chain().focus().toggleHighlight().run()}>
        <HighlighterCircle className="block" />
      </IconButton>

      <div className="mx-1 h-6 border-[0.5px] border-gray-200" />

      <IconButton
        isActive={editor.isActive({ textAlign: "left" })}
        onClick={() => editor.chain().focus().setTextAlign("left").run()}>
        <TextAlignLeft className="block" />
      </IconButton>

      <IconButton
        isActive={editor.isActive({ textAlign: "center" })}
        onClick={() => editor.chain().focus().setTextAlign("center").run()}>
        <TextAlignCenter className="block" />
      </IconButton>

      <IconButton
        isActive={editor.isActive({ textAlign: "right" })}
        onClick={() => editor.chain().focus().setTextAlign("right").run()}>
        <TextAlignRight className="block" />
      </IconButton>

      <div className="mx-1 h-6 border-[0.5px] border-gray-200" />

      <IconButton
        isActive={editor.isActive("bulletList")}
        onClick={() => editor.chain().focus().toggleBulletList().run()}>
        <ListBullets className="block" />
      </IconButton>

      <IconButton
        isActive={editor.isActive("orderedList")}
        onClick={() => editor.chain().focus().toggleOrderedList().run()}>
        <ListNumbers className="block" />
      </IconButton>

      <IconButton
        isActive={editor.isActive("code")}
        onClick={() => editor.chain().focus().toggleCode().run()}>
        <Code className="block" />
      </IconButton>

      <div className="mx-1 h-6 border-[0.5px] border-gray-200" />

      <IconButton onClick={() => editor.chain().focus().undo().run()}>
        <ArrowCounterClockwise className="block" />
      </IconButton>

      <IconButton onClick={() => editor.chain().focus().redo().run()}>
        <ArrowClockwise className="block" />
      </IconButton>
    </div>
  )
}

const RichTextDictateButton = (props: { editor: Editor | null }) => {
  const { editor } = props
  const transcribe = useLiveTranscription()
  const selectionUpdateRef = useRef<(() => void) | null>(null)

  const startTranscription = () => {
    if (!editor) return
    let from = editor.view.state.selection.from
    let to = editor.view.state.selection.to ?? from
    let ignoreSelectionUpdate = false
    const updateSelection = () => {
      if (ignoreSelectionUpdate) return
      const newTo = editor.view.state.selection.to
      if (newTo !== to) {
        from = editor.view.state.selection.from
        to = newTo
        transcribe.resetTranscript()
      }
    }
    selectionUpdateRef.current = updateSelection

    transcribe.startTranscribing((transcript) => {
      ignoreSelectionUpdate = true
      editor.commands.insertContentAt({ from, to }, transcript, { updateSelection: true })
      to = editor.view.state.selection.from!
      ignoreSelectionUpdate = false
      editor.commands.focus(to)
    })
  }
  const stopTranscription = () => {
    if (editor && selectionUpdateRef.current) {
      editor.off("selectionUpdate", selectionUpdateRef.current)
    }
    return transcribe.stopTranscribing()
  }

  useEffect(() => {
    editor?.on("blur", stopTranscription)
    return () => {
      editor?.off("blur", stopTranscription)
    }
  }, [editor])

  return (
    <div className="relative inline-flex items-center">
      <Tooltip asChild content={i18n.t("common:dictate_feature")}>
        <button
          type="button"
          onClick={() => {
            if (!editor) return
            if (transcribe.transcribing) {
              stopTranscription()
            } else {
              startTranscription()
            }
          }}
          className={cn(
            "m-0.5 inline-block rounded-full p-1.5 text-lg text-blue-600 ring-blue-600 hover:bg-blue-100 bg-blue-50 transition-colors duration-200",
            {
              "bg-blue-600 text-white hover:bg-blue-600 hover:ring-2":
                transcribe.transcribing,
            }
          )}>
          <Microphone weight="fill" className="h-5 w-5" />
        </button>
      </Tooltip>

      {transcribe.transcribing && (
        <>
          <span className="pointer-events-none absolute left-9 top-0.5 z-40 block whitespace-nowrap rounded-lg bg-gray-300/40 p-1.5 text-xs text-gray-950 opacity-0 backdrop-blur-sm animate-in fade-in-100 duration-3000 ease-in-out">
            {i18n.t("common:messages.speak_now")}
          </span>
        </>
      )}
    </div>
  )
}

type WritingState = {
  action: "improve" | "fix"
  content: string
  completions: string[]
  page: number
} | null

const AIWritingAssitant = ({ editor }: { editor: Editor | null }) => {
  const disclosure = useDisclosure()
  const [writingState, setWritingState] = useState<WritingState>(null)

  const sendRequest = async (action: "improve" | "fix") => {
    const content = editor?.getHTML() ?? ""
    disclosure.changeOpen(true)
    setWritingState({ action, content, completions: [], page: 0 })

    const res = await fetch("/api/writing/" + action, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ content }),
    })

    if (!res.body) {
      setWritingState(null)
      return
    }

    try {
      const reader = res.body.getReader()
      const decoder = new TextDecoder("utf-8")

      let partialChunk = ""
      while (true) {
        const { value, done } = await reader.read()
        if (done) break

        const chunk = partialChunk + decoder.decode(value)
        partialChunk = ""
        const lines = chunk.split("}{")
        const parsedLines = lines
          .map((line, idx) => {
            if (idx === 0 && lines.length === 1) return line
            return idx === 0
              ? line + "}"
              : idx === lines.length - 1
              ? "{" + line
              : "{" + line + "}"
          })
          .map((line) => line.replace(/^data: /, "").trim()) // Remove the "data: " prefix
          .filter((line) => line !== "" && line !== "[DONE]") // Remove empty lines and "[DONE]"
          // Remove trailing commas
          // eslint-disable-next-line
          .map((line) => {
            try {
              return JSON.parse(line)
            } catch (e) {
              partialChunk += line
              return null
            }
          })
          .filter((a) => !!a)

        for (const parsedLine of parsedLines) {
          const { choices } = parsedLine
          setWritingState((s) => {
            if (!s) return null

            const completions = s.completions.slice()
            choices.forEach((c: any) => {
              completions[c.index] = (completions[c.index] ?? "") + (c.delta.content ?? "")
            })
            return {
              ...s,
              completions,
            }
          })
        }
      }
    } catch (e) {
      setWritingState(null)
    }
  }

  const currentCompletion = writingState?.completions?.[writingState?.page]

  return (
    <PopoverRoot
      alwaysPopover
      modal={false}
      open={!!writingState}
      onOpenChange={(open) => {
        if (!open && writingState) {
          setWritingState(null)
        }
      }}>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger asChild disabled={!!editor?.isEmpty}>
          <PopoverAnchor asChild>
            <Button type="secondary" icon={MagicWand}>
              {i18n.t("ai_writing_assistant")}
              <CaretDown className="ml-1" weight="bold" />
            </Button>
          </PopoverAnchor>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          <DropdownMenuItem
            onSelect={async (e) => {
              e.preventDefault()
              sendRequest("fix")
            }}>
            {i18n.t("common:actions.fix_spelling")}
          </DropdownMenuItem>
          <DropdownMenuItem
            onSelect={async (e) => {
              e.preventDefault()
              sendRequest("improve")
            }}>
            {i18n.t("common:actions.improve_writing")}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <PopoverContent
        className="min-w-[280px] max-w-xl bg-white/80 p-3 text-sm backdrop-blur"
        align="start"
        side="top"
        sideOffset={-32}>
        <div className="flex flex-col">
          {currentCompletion ? (
            <div className="">
              <div className="flex items-center justify-between">
                <span className="font-medium ">
                  {i18n.t("common:suggestion_other")}{" "}
                  <span className="tabular-nums">
                    {writingState.page + 1} / {writingState.completions.length}{" "}
                  </span>
                </span>
                <div className=" space-x-2">
                  <Button
                    color="gray"
                    type="tertiary"
                    icon={CaretLeft}
                    size="extra-small"
                    disabled={writingState.page === 0}
                    onClick={() => {
                      setWritingState((s) => s && { ...s, page: s.page - 1 })
                    }}
                  />
                  <Button
                    color="gray"
                    type="tertiary"
                    icon={CaretRight}
                    size="extra-small"
                    disabled={writingState.page + 1 === writingState.completions?.length}
                    onClick={() => {
                      setWritingState((s) => s && { ...s, page: s.page + 1 })
                    }}
                  />
                </div>
              </div>

              {currentCompletion && (
                <StaticRichText
                  className="mt-2 text-gray-600"
                  content={currentCompletion}
                />
              )}
              <div className="mt-2 flex items-center justify-end space-x-3">
                <Button type="tertiary" color="gray" onClick={() => setWritingState(null)}>
                  {i18n.t("discard")}
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    if (!currentCompletion) return
                    editor?.commands?.setContent(currentCompletion)
                    setWritingState(null)
                  }}>
                  {i18n.t("accept")}
                </Button>
              </div>
            </div>
          ) : (
            <div className="flex justify-center">
              <LoadingIndicator size={20} className="mx-auto" />
            </div>
          )}
        </div>
      </PopoverContent>
    </PopoverRoot>
  )
}

const RichText = React.forwardRef(
  ({ extensions, footerAction, ...props }: RichTextProps, forwardedRef) => {
    const editor = useEditor({
      editorProps: {
        attributes: {
          class: "prose md:prose-sm",
        },
        ...props.editorProps,
      },
      extensions: [
        StarterKit,
        Underline,
        Highlight,
        TextAlign.configure({
          defaultAlignment: "left",
          types: ["heading", "paragraph"],
          alignments: ["left", "center", "right"],
        }),
        CustomLink.configure({
          HTMLAttributes: {
            class: "text-blue-700 hover:underline",
          },
        }),
        ...(props.placeholder
          ? [
              Placeholder.configure({
                placeholder: props.placeholder,
                emptyNodeClass:
                  "text-editor-placeholder-color first:before:content-[attr(data-placeholder)] first:before:pointer-none first:before:h-0 first:before:float-left",
              }),
            ]
          : []),
        Mention.configure({
          HTMLAttributes: {
            class: "font-semibold",
          },
          suggestion,
        }),
        ...(extensions ?? []),
        Extension.create({
          addKeyboardShortcuts: () => ({
            "Mod-Enter": () => {
              props.onSubmit?.()
              return true
            },
          }),
        }),
      ],
      ...props,
    })

    useImperativeHandle<unknown, RichTextHandle>(
      forwardedRef,
      () => ({
        getContent() {
          const html = editor?.getHTML() ?? "<p></p>"
          const mentions = findMentions(editor?.getJSON() ?? [])
          return { html, mentions }
        },
        clearContent() {
          editor?.commands?.clearContent()
        },
        setContent(content: string) {
          editor?.commands?.setContent(content)
        },
        editor,
      }),
      [editor]
    )

    const hasTranscription = useFeature("voice_transcripts")
    const hasAIWriting = useFeature("ai_writing_assistant")

    const style = {
      "--text-editor-placeholder-color": props.hasError ? "#EF4444" : "rgb(113 113 122)",
      wordBreak: "break-word",
    } as React.CSSProperties

    return (
      <div
        className={cn(
          props.className,
          "flex flex-col text-base md:text-sm break-words min-h-0"
        )}
        onClick={() => {
          editor?.isFocused || editor?.commands?.focus()
        }}
        style={style}>
        {props.showToolbar && (
          <MenuBar editor={editor} toolbarClassName={props.toolbarClassName} />
        )}

        <EditorContent
          id={props.id}
          editor={editor}
          onKeyDown={(e) => {
            if (e.key === "Tab") {
              editor?.commands?.blur?.()
            }
          }}
          className={cn("overflow-auto min-h-0 p-2", props.contentClassName)}
        />

        {(hasTranscription || hasAIWriting || footerAction) && (
          <div className="flex items-center px-3 pb-3">
            <div
              className="flex items-center space-x-3"
              onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
              }}>
              {hasTranscription && <RichTextDictateButton editor={editor} />}
              {hasAIWriting && <AIWritingAssitant editor={editor} />}
            </div>

            {(!editor?.isEmpty || props.showSendButton) &&
              props.showFooterIfNotEmpty &&
              footerAction}
          </div>
        )}
      </div>
    )
  }
)

export default RichText
