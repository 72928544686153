import { ActionMenu, Button } from "@components/shared"
import toast from "@components/shared/toast"
import { uuid } from "@elara/db"
import {
  useDeleteWorkOrderCategoryMutation,
  useInsertWorkOrderCategoryMutation,
  useUpdateWorkOrderCategoryLabelMutation,
  WorkOrderCategoriesDocument,
} from "@graphql/documents/work-order.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { DotsThreeVertical, PencilSimpleLine, TrashSimple } from "@phosphor-icons/react"
import { useCallback, useEffect, useRef, useState } from "react"
import { useClient } from "urql"
import { v4 } from "uuid"

type CategoryActionMenuProps = {
  onEdit: () => void
  onDelete: () => void
  inCreateMode?: boolean
}
export const CategoryActionMenu = ({
  onEdit,
  onDelete,
  inCreateMode,
}: CategoryActionMenuProps) => {
  const editCategoryScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)
  const deleteCategoryScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderDelete)

  if (!editCategoryScope.hasScope && !deleteCategoryScope.hasScope) return null
  return (
    <ActionMenu
      items={[
        editCategoryScope.hasScope
          ? {
              key: "edit",
              icon: <PencilSimpleLine />,
              label: i18n.t("edit"),
              action: onEdit,
              disabled: inCreateMode,
            }
          : null,
        deleteCategoryScope.hasScope
          ? {
              key: "delete",
              icon: <TrashSimple />,
              label: i18n.t("common:delete"),
              action: onDelete,
            }
          : null,
      ]}>
      <Button
        size="small"
        type="tertiary"
        icon={DotsThreeVertical}
        className="!text-gray-600"
      />
    </ActionMenu>
  )
}

export const useCategoryEdit = (label: string, categoryId?: uuid) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const [isEditing, setIsEditing] = useState(false)
  const [isCreating, setIsCreating] = useState(false)

  const [editedLabel, setEditedLabel] = useState(label)

  const client = useClient()
  const [, updateWorkOrderCategoryLabel] = useUpdateWorkOrderCategoryLabelMutation()
  const [, insertWorkOrderCategory] = useInsertWorkOrderCategoryMutation()
  const [, deleteWorkOrderCategory] = useDeleteWorkOrderCategoryMutation()

  const createCategoryScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderCreate)
  const editCategoryScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)
  const deleteCategoryScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderDelete)

  useEffect(() => {
    if (isEditing && label !== editedLabel) {
      setEditedLabel(label)
    }
  }, [label, isEditing])

  const startEdit = useCallback(() => {
    setIsEditing(true)
    // to set focus ring
    setTimeout(() => inputRef.current?.focus(), 200)
  }, [inputRef])

  const startCreate = useCallback(() => {
    setIsCreating(true)
    // to set focus ring
    setTimeout(() => inputRef.current?.focus(), 200)
  }, [inputRef])

  const stopEdit = () => {
    setIsEditing(false)
  }

  const stopCreate = () => {
    setIsCreating(false)
  }

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedLabel(e.target.value)
  }, [])

  const onEditOrCreateCategory = async (color: string) => {
    if (isEditing && editedLabel.length === 0) {
      toast.warning(
        i18n.t(
          i18n.t("common:forms.is_required", {
            field: i18n.t("tasks:fields.category", { count: 1 }),
          })
        )
      )
      return
    }
    if (isEditing) {
      setIsEditing(false)
      const res = await updateWorkOrderCategoryLabel(
        { id: categoryId!, label: editedLabel, color: color },
        editCategoryScope.context()
      )
      if (res.error) {
        toast.error(
          i18n.t("common:messages.token_update_failure", {
            token: i18n.t("tasks:fields.category", { count: 1 }),
          })
        )
      }
    } else if (isCreating) {
      const res = await insertWorkOrderCategory(
        { label: editedLabel, color: color, id: v4() },
        createCategoryScope.context()
      )
      setEditedLabel("")
      if (res.error) {
        toast.error(
          i18n.t("common:messages.token_update_failure", {
            token: i18n.t("tasks:fields.category", { count: 1 }),
          })
        )
      }
      setIsCreating(false)
      await client
        .query(WorkOrderCategoriesDocument, {}, { requestPolicy: "network-only" })
        .toPromise()
    }
  }

  const onDeleteCategory = async () => {
    const res = await deleteWorkOrderCategory(
      { id: categoryId! },
      deleteCategoryScope.context()
    )
    if (res && res.data?.delete_work_order_category_by_pk) {
      toast.success(
        i18n.t("common:messages.token_delete_success", {
          token: i18n.t("tasks:fields.category", { count: 1 }),
        })
      )
      await client
        .query(WorkOrderCategoriesDocument, {}, { requestPolicy: "network-only" })
        .toPromise()
    }
    if (res.error) {
      toast.error(
        i18n.t("common:messages.token_delete_failure", {
          token: i18n.t("tasks:fields.category", { count: 1 }),
        })
      )
    }
  }

  return {
    inputRef,
    isEditing,
    isCreating,
    editedLabel,
    startEdit,
    startCreate,
    stopEdit,
    stopCreate,
    onChange,
    onEditOrCreateCategory,
    onDeleteCategory,
  }
}
