import { getUser } from "@contexts/user-context"
import { useCallbackRef } from "@hooks"
import { useEffect, useRef, useState } from "react"
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition"
// @ts-ignore
import createSpeechServicesPonyfill from "web-speech-cognitive-services"

const TRANSCRIPTION_AUTH_TOKEN = { current: null, expiresAt: 0 }
async function getTranscriptionAuthToken() {
  if (TRANSCRIPTION_AUTH_TOKEN.expiresAt < Date.now() + 1000 * 60 * 2) {
    const { token, expiresAt } = await fetch("/api/transcript/issue-token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json())
    TRANSCRIPTION_AUTH_TOKEN.current = token
    TRANSCRIPTION_AUTH_TOKEN.expiresAt = expiresAt
  }

  return TRANSCRIPTION_AUTH_TOKEN.current
}

const setupCognitionService = async () => {
  const { SpeechRecognition: AzureSpeechRecognition } = createSpeechServicesPonyfill({
    credentials: {
      region: "germanywestcentral",
      authorizationToken: await getTranscriptionAuthToken(),
    },
  })

  SpeechRecognition.applyPolyfill(AzureSpeechRecognition)
}

export function useLiveTranscription(options: { timeout?: number } = {}) {
  const { timeout = 5000 } = options
  const [transcribing, setTranscribing] = useState(false)
  const { transcript, resetTranscript, browserSupportsSpeechRecognition, finalTranscript } =
    useSpeechRecognition({ transcribing, clearTranscriptOnListen: true })

  const cbRef = useRef<((transcript: string) => void) | null>(null)

  const stopTranscribing = useCallbackRef(async () => {
    try {
      await SpeechRecognition.abortListening()
    } catch {
      try {
        const rec = SpeechRecognition.getRecognition()
        rec?.stop()
      } catch {}
    } finally {
      setTranscribing(false)
      resetTranscript()
    }

    cbRef.current = null
  })

  const startTranscribing = useCallbackRef(async (cb?: (transcript: string) => void) => {
    try {
      if (!transcribing) {
        await setupCognitionService()

        await SpeechRecognition.startListening({
          language: getUser().profile.language,
          continuous: true,
        })
        setTranscribing(true)
      }
      cbRef.current = cb ?? null
    } catch {}
  })

  useEffect(() => {
    if (transcribing) {
      const timeoutRef = setTimeout(() => {
        stopTranscribing()
        const rec = SpeechRecognition.getRecognition()
        rec?.stop()
      }, timeout)

      cbRef.current?.(transcript)
      return () => {
        clearTimeout(timeoutRef)
      }
    }
  }, [transcript, transcribing])

  useEffect(() => {
    // Fetch new auth token on mount to make first use faster
    getTranscriptionAuthToken()

    return () => {
      stopTranscribing()
    }
  }, [])

  const isProcessing = transcribing && transcript !== finalTranscript
  return {
    startTranscribing,
    stopTranscribing,
    transcribing,
    transcript,
    isProcessing,
    resetTranscript,
    browserSupportsSpeechRecognition,
    getSpeechRecognition: SpeechRecognition.getRecognition,
  }
}
