import { Data, Where } from "@elara/select"
import { StickyType, useCallbackRef, useDeepMemo } from "@hooks"
import { unique } from "@utils"
import { Dispatch, RefCallback, useMemo, useState } from "react"

import { FilterState } from "../data-view-types"
import { Schema, whereStatementFromStates } from "./schema"

type ViewFilterAction =
  | { type: "removeFilterState"; index: number }
  | { type: "updateFilterState"; index: number; state: FilterState }
  | { type: "addFilterState"; state: FilterState }
  | { type: "setFilterStates"; states: FilterState[] }
  | { type: "clearFilter" }

export type UseDataViewFilterReturnType<D extends Data> = {
  schema: Schema<D>

  hasFiltersAdded: boolean
  dispatch: Dispatch<ViewFilterAction>
  whereStatement: Where<D> | null
  fixedWhereStatement: Where<D> | null
  stateWhereStatement: Where<D> | null
  configFilters: FilterState[]
  filterStates: FilterState[]
  clearFilter: () => void
}

export function useDataViewFilter<D extends Data>(
  filterStates: FilterState[],
  onFilterStateChange: (filters: FilterState[]) => void,
  options: {
    schema: Schema<D>
    hiddenFixedFilters: FilterState[]
    fixedFilters: FilterState[]
    filterStickyness: StickyType
    customConfigWhere: Where<D> | null
  }
): UseDataViewFilterReturnType<D> {
  // Compute where statements
  const customWhere = useDeepMemo(
    () => (options.customConfigWhere ?? {}) as Where<D>,
    [options.customConfigWhere]
  )
  const fixedFilters = useDeepMemo(
    () => options.hiddenFixedFilters.concat(options.fixedFilters),
    [options.hiddenFixedFilters, options.fixedFilters]
  )

  // Compute where statements split into to general where statement
  // and decomposed into fixed and currently aplied state where statements
  const whereStatement = useMemo(() => {
    const filters = fixedFilters.concat(filterStates)
    if (!filters.length) return null
    return {
      _and: [whereStatementFromStates(options.schema, filters), customWhere],
    } as Where<D>
  }, [options.schema, filterStates, fixedFilters, customWhere])
  const fixedWhereStatement = useMemo(() => {
    if (!fixedFilters.length) return null
    return {
      _and: [whereStatementFromStates(options.schema, fixedFilters), customWhere],
    } as Where<D>
  }, [options.schema, fixedFilters, customWhere])
  const stateWhereStatement = useMemo(() => {
    if (!filterStates.length) return null
    return whereStatementFromStates(options.schema, filterStates)
  }, [options.schema, filterStates])

  const configFilters = useDeepMemo(
    () => unique(options.fixedFilters.concat(filterStates)),
    [filterStates, options.fixedFilters]
  )

  const dispatch = useCallbackRef((action: ViewFilterAction) => {
    switch (action.type) {
      case "updateFilterState":
        const updatedFilterStates = filterStates.slice()
        updatedFilterStates[action.index] = action.state
        onFilterStateChange(updatedFilterStates)
        break
      case "removeFilterState":
        onFilterStateChange(
          filterStates.slice(0, action.index).concat(filterStates.slice(action.index + 1))
        )
        break
      case "addFilterState":
        onFilterStateChange(filterStates.concat(action.state))
        break
      case "clearFilter":
        onFilterStateChange([])
        break
      case "setFilterStates":
        onFilterStateChange(action.states)
        break
      default:
        throw new Error("Unexpected action")
    }
  })

  const clearFilter = useCallbackRef(() => dispatch({ type: "clearFilter" }))

  const hasFiltersAdded = filterStates.length > 0

  return {
    schema: options.schema,
    dispatch,
    filterStates,
    whereStatement,
    fixedWhereStatement,
    stateWhereStatement,
    configFilters,
    clearFilter,
    hasFiltersAdded,
  }
}

export const useDataViewFilterMaxListHeight = (): [number, RefCallback<HTMLElement>] => {
  const [maxListHeight, setListMaxHeight] = useState(150)
  const ref = (node: HTMLElement) => {
    if (node) {
      window.visualViewport &&
        setListMaxHeight(
          window.visualViewport.height -
            node.getBoundingClientRect().y +
            // Subtract 36 to account for the height of the trigger
            -36 +
            // Subtract 64 to account for the size of the input element and some spacing
            -64
        )
    }
  }
  return [maxListHeight, ref]
}
