import {
  AssetActivityDataView,
  assetActivityDataViewDefaultConfig,
} from "@components/asset/asset-activity-data-view"

import { useAsset } from "./$id"

const ObjectInsightActivity = () => {
  const { asset } = useAsset()
  return (
    <AssetActivityDataView
      assetId={asset.id}
      headerClassName="px-3"
      configId="detail-asset-activity"
      maxHistory="all"
      defaultConfig={{
        ...assetActivityDataViewDefaultConfig,
        historyFilter: {
          type: "select",
          id: "timestamp",
          selectedValues: [{ type: "relative", days: 30 }],
        },
      }}
    />
  )
}

export default ObjectInsightActivity
