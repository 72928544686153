import { AssetSingleSelect } from "@components/asset"
import AlertBox from "@components/shared/alert-box"
import DurationInput from "@components/shared/duration-input"
import { FormField } from "@components/shared/form/form-field"
import Tabs from "@components/shared/horizontal-tabs"
import { Select } from "@components/shared/select"
import { StaticRichText } from "@components/shared/static-rich-text"
import { TextInput } from "@components/shared/text-input"
import Toggle from "@components/shared/toggle"
import { ToggleGroup, ToggleGroupItem } from "@components/shared/toggle-group"
import { Tooltip } from "@components/shared/tooltip"
import { MeterReadingTriggerOptions } from "@elara/db"
import { useAutoAnimate } from "@formkit/auto-animate/react"
import {
  IMetersByAssetIdQuery,
  IMetersByAssetIdQueryVariables,
  MetersByAssetIdDocument,
  useMetersByAssetIdQuery,
} from "@graphql/documents/asset.generated"
import { useMemoRef } from "@hooks"
import i18n from "@i18n"
import { Info, TrashSimple } from "@phosphor-icons/react"
import { cn } from "@utils"
import { formatDate } from "@utils/date"
import { useField } from "formik"
import { Trans } from "react-i18next"
import colors from "tailwindcss/colors"
import { useClient } from "urql"

const MinRangeInput = ({ prefix }: { prefix: string }) => (
  <FormField noStyle name={`${prefix}.min`}>
    <TextInput type="number" autoComplete="nofill" className="mx-1.5 w-20" />
  </FormField>
)

const MaxRangeInput = ({ prefix }: { prefix: string }) => (
  <FormField noStyle name={`${prefix}.max`}>
    <TextInput type="number" autoComplete="nofill" className="mx-1.5 w-20" />
  </FormField>
)

const RangeContent = (props: { prefix: string; unit: string }) => (
  <div className="my-3 text-sm font-medium leading-loose text-gray-700">
    <Trans
      i18n={i18n}
      i18nKey={"maintenance:labels.triggers.meter.range_options.label"}
      components={{
        min: <MinRangeInput prefix={props.prefix} />,
        max: <MaxRangeInput prefix={props.prefix} />,
      }}
      values={{ unit: props.unit }}
    />
  </div>
)

interface MeterReadingTriggerFormProps {
  prefix: string
  onDelete?: () => void
}

const MeterReadingTriggerForm = ({ prefix, onDelete }: MeterReadingTriggerFormProps) => {
  const client = useClient()
  const [animationParent] = useAutoAnimate()

  const [{ value: payload }, payloadMeta, payloadHelpers] = useField<
    MeterReadingTriggerOptions["payload"]
  >(`${prefix}`)

  const payloadRef = useMemoRef(payload)

  const [metersRes] = useMetersByAssetIdQuery({
    pause: !payload?.assetId,
    variables: { assetId: payload?.assetId ?? "" },
  })
  const meters = metersRes.data?.meter ?? []
  const selectedMeter = meters.find((m) => m.id === payload?.meterId) ?? null

  return (
    <div
      ref={animationParent}
      className={cn("relative rounded border p-4", {
        "border-red-500": payloadMeta.touched && payloadMeta.error,
      })}>
      <div className="absolute inset-x-2 -top-2 flex items-center justify-between">
        <p className="bg-white px-2 text-xs">
          {i18n.t("maintenance:labels.triggers.meter.title")}
        </p>

        {onDelete && (
          <div
            onClick={onDelete}
            className="mx-2 cursor-pointer bg-white text-red-300 hover:text-red-500">
            <TrashSimple />
          </div>
        )}
      </div>

      {/* {isLocalhostOrTest && (
        <pre className="mb-3 overflow-x-scroll rounded bg-gray-900 p-5 text-xs text-gray-50">
          <code>{JSON.stringify(payload, null, 2)}</code>
        </pre>
      )} */}

      <FormField name={`${prefix}.assetId`} label={i18n.t("common:asset", { count: 1 })}>
        {({ field }) => (
          <AssetSingleSelect
            isClearable={false}
            value={field.value}
            assetIsSelectable={(asset) =>
              Boolean(
                asset.meters_aggregate.aggregate &&
                  asset.meters_aggregate.aggregate.count > 0
              )
            }
            onChange={async (assetId) => {
              if (!assetId) return

              payloadHelpers.setValue({ ...payload, assetId })

              const res = await client
                .query<IMetersByAssetIdQuery, IMetersByAssetIdQueryVariables>(
                  MetersByAssetIdDocument,
                  { assetId }
                )
                .toPromise()
              const meters = res.data?.meter ?? []

              // Check that a meter is not already been selected or asset has been changed
              if (
                meters.length > 0 &&
                payloadRef.current.assetId === assetId &&
                !payloadRef.current.meterId
              ) {
                payloadHelpers.setValue({
                  ...payloadRef.current,
                  assetId,
                  meterId: meters[0].id,
                })
              }
            }}
          />
        )}
      </FormField>

      {!metersRes.fetching && payload?.assetId && meters.length === 0 && (
        <AlertBox type="danger">{i18n.t("meters:messages.no_meters_for_asset")}</AlertBox>
      )}

      {!metersRes.fetching && payload?.assetId && meters.length > 0 && (
        <FormField name={`${prefix}.meterId`} label={i18n.t("common:meter", { count: 1 })}>
          {({ field, helpers }) => {
            return (
              <Select
                value={field.value}
                placeholder={i18n.t("meters:messages.select_placeholder")}
                items={meters.map((m) => ({ value: m.id, label: m.name }))}
                onValueChange={helpers.setValue}
              />
            )
          }}
        </FormField>
      )}

      {selectedMeter && (
        <div>
          <Tabs.Root value={payload.variant}>
            <div className="mb-2 flex items-center gap-2">
              <span className="text-sm font-medium text-gray-600">
                {i18n.t("meters:fields.configuration")}
              </span>
              <Tooltip
                content={
                  <Trans
                    i18n={i18n}
                    className="text-sm font-normal text-gray-600"
                    i18nKey="meters:messages.meter_type_description">
                    <p>
                      Es werden zwei Konfigurationen für Zähler unterstützt:
                      <span className="font-medium">Interval</span> und
                      <span className="font-medium">Toleranzbereich</span>.
                    </p>
                    <p>
                      Ein Beispiel für <span className="font-medium">Interval</span> sind
                      Betriebsstunden. Für einen Betriebsstundenzähler kann eine regelmäßige
                      Aufgabe hinterlegt werden wie eine Wartung alle 500 Stunden.
                    </p>
                    <p>
                      Ein Beispiel für einen
                      <span className="font-medium">Toleranzbereich</span> ist die
                      Kontrollmessung von Waagen. Es kann eine Aufgabe hinterlegt werden,
                      die erstellt wird bei Messungen außerhalb des Toleranzbereiches.
                    </p>
                  </Trans>
                }>
                <Info size={20} color={colors.gray[500]} />
              </Tooltip>
            </div>

            <FormField noStyle name={`${prefix}.variant`}>
              {({ field, helpers }) => (
                <ToggleGroup
                  type="single"
                  value={field.value}
                  onValueChange={(newValue) => {
                    if (newValue) helpers.setValue(newValue)
                  }}>
                  <ToggleGroupItem value="range">
                    {i18n.t("maintenance:labels.triggers.meter.types.range")}
                  </ToggleGroupItem>
                  <ToggleGroupItem value="interval">
                    {i18n.t("maintenance:labels.triggers.meter.types.interval")}
                  </ToggleGroupItem>
                </ToggleGroup>
              )}
            </FormField>

            <Tabs.Content value="range">
              <RangeContent prefix={prefix} unit={selectedMeter.unit} />
            </Tabs.Content>

            <Tabs.Content value="interval">
              <div className="my-4 space-y-4">
                <div className="text-sm text-gray-700">
                  <div className="mb-2 font-medium">
                    {i18n.t("maintenance:labels.triggers.meter.interval_options.title")}
                  </div>

                  <div className="flex items-center gap-1.5">
                    <span>
                      {i18n.t("maintenance:labels.triggers.meter.interval_options.every")}
                    </span>
                    <FormField noStyle name={`${prefix}.every`}>
                      <TextInput
                        type="number"
                        autoComplete="nofill"
                        className="mx-1 max-w-[6rem]"
                      />
                    </FormField>
                    <span>
                      <span>{selectedMeter.unit || i18n.t("meters:fields.unit")}</span>
                    </span>
                  </div>
                </div>

                <div className="text-sm text-gray-700">
                  <div className="flex items-center gap-1.5">
                    <span>
                      {i18n.t(
                        "maintenance:labels.triggers.meter.interval_options.first_task_at"
                      )}
                    </span>
                    <FormField noStyle name={`${prefix}.nextAt`}>
                      <TextInput
                        type="number"
                        autoComplete="nofill"
                        className="mx-1 max-w-[6rem]"
                      />
                    </FormField>
                    <span>
                      <span>{selectedMeter.unit || i18n.t("meters:fields.unit")}</span>
                    </span>
                  </div>
                </div>
              </div>
            </Tabs.Content>
          </Tabs.Root>

          {selectedMeter.last_readings.length > 0 && (
            <AlertBox type="info" className="mb-4">
              <StaticRichText
                content={i18n.t<string>("meters:messages.last_meter_reading", {
                  value: selectedMeter.last_readings[0].value,
                  unit: selectedMeter.unit,
                  date: formatDate(
                    new Date(selectedMeter.last_readings[0].measured_at),
                    "Pp"
                  ),
                })}
              />
            </AlertBox>
          )}

          <FormField
            name={`${prefix}.dueAfter`}
            label={i18n.t("maintenance:labels.triggers.meter.due_after_label")}>
            {({ field, helpers }) => (
              <DurationInput
                value={field.value}
                onChange={helpers.setValue}
                className="max-w-[200px]"
              />
            )}
          </FormField>

          <label className="flex items-center">
            <FormField noStyle name={`${prefix}.onlyOneOpenTask`}>
              {({ field, helpers }) => (
                <Toggle
                  checked={field.value}
                  onChange={(e) => helpers.setValue(e.target.checked)}
                />
              )}
            </FormField>
            <p className="ml-3 whitespace-pre-wrap text-[13px]">
              {i18n.t("maintenance:fields.only_one_open_task")}
            </p>
          </label>
        </div>
      )}
    </div>
  )
}

export default MeterReadingTriggerForm
