import { CSSProperties, forwardRef } from "react"

import { View, ViewProps } from "./view"

function flexDirection(
  row?: boolean,
  col?: boolean,
  direction?: CSSProperties["flexDirection"]
): CSSProperties["flexDirection"] {
  if (row) return "row"
  if (col) return "column"
  return direction ?? "column"
}

export type FlexProps = {
  align?: CSSProperties["alignItems"]
  col?: boolean
  direction?: CSSProperties["flexDirection"]
  justify?: CSSProperties["justifyContent"]
  row?: boolean
  overflow?: CSSProperties["overflow"]
  wrap?: boolean | CSSProperties["flexWrap"]
} & ViewProps

export const Flex = forwardRef(
  (
    { align, justify, row, col, direction, children, wrap, overflow, ...props }: FlexProps,
    ref
  ) => {
    return (
      <View
        ref={ref as any}
        {...props}
        style={{
          flexDirection: flexDirection(row, col, direction),
          alignItems: align,
          justifyContent: justify,
          flexWrap: wrap === true ? "wrap" : wrap ? wrap : "nowrap",
          overflow,
          ...props.style,
        }}>
        {children}
      </View>
    )
  }
)

export type SpacerProps = ViewProps

export const Spacer = forwardRef<HTMLDivElement, SpacerProps>((props, ref) => (
  <View ref={ref} {...props} flex="1" />
))

export default Flex
