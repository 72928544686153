import { useUser } from "@contexts/user-context"
import type { Currency } from "@elara/db"
import React from "react"

export function currencySymbol(currency: Currency) {
  switch (currency) {
    case "AUD":
      return "$"
    case "USD":
      return "$"
    case "EUR":
      return "€"
    case "GBP":
      return "£"
    case "CHF":
      return "CHF"
    default:
      return currency
  }
}

export default React.forwardRef<HTMLSpanElement, JSX.IntrinsicElements["span"]>(
  (props, forwardedRef) => {
    const user = useUser()

    return (
      <span ref={forwardedRef} {...props}>
        {currencySymbol(user.location.settings.currency)}{" "}
      </span>
    )
  }
)
