import { ConsumableFormRowItem } from "@components/consumable/consumable-form-row-item"
import { ConsumableStorageLocation } from "@components/consumable/consumable-storage-location"
import { selectConsumable } from "@components/select/consumable-multi-select-dialog"
import { Button } from "@components/shared"
import { AssetName } from "@components/shared/asset-name"
import { CheckboxInput } from "@components/shared/form/checkbox-input"
import { Tooltip } from "@components/shared/tooltip"
import TouchTargetSize from "@components/shared/touch-target-size"
import { BlockElementConsumables, IPermissionScopeEnum } from "@elara/db"
import {
  AssetTagByIdDocument,
  IAssetTagByIdQuery,
  IAssetTagByIdQueryVariables,
} from "@graphql/documents/asset.generated"
import {
  DeleteConsumableLogDocument,
  IDeleteConsumableLogMutation,
  IDeleteConsumableLogMutationVariables,
  IInsertConsumableLogMutation,
  IInsertConsumableLogMutationVariables,
  InsertConsumableLogDocument,
  useConsumablesQuery,
} from "@graphql/documents/consumable.generated"
import { IAssetTagFragment } from "@graphql/documents/fragments.generated"
import { usePermissionScope } from "@hooks/use-permission-scope"
import i18n from "@i18n"
import { Plus, Shapes } from "@phosphor-icons/react"
import { LinkWithBackgroundLocation } from "@utils/location"
import { useEffect, useState } from "react"
import { useFieldArray, useWatch } from "react-hook-form"
import { useClient } from "urql"
import { v4 } from "uuid"

import { BlockElementFormProps, BlockElementProps } from "./block-element-types"

export const ConsumablesForm = (props: BlockElementFormProps) => {
  const [consumablesQueryRes] = useConsumablesQuery({ requestPolicy: "cache-first" })
  const consumables = consumablesQueryRes.data?.consumable ?? []
  const { fields, remove, append } = useFieldArray({
    name: `${props.field}.${props.index}.config.consumables`,
    control: props.control,
  })
  const client = useClient()
  const assetIds = useWatch({ control: props.control, name: "asset_ids" }) as string[]
  const [assets, setAsset] = useState<IAssetTagFragment[]>([])
  useEffect(() => {
    setAsset(
      assetIds
        .map((id) => {
          return client.readQuery<IAssetTagByIdQuery, IAssetTagByIdQueryVariables>(
            AssetTagByIdDocument,
            { id }
          )?.data?.asset_by_pk
        })
        .filter((a) => a!) as IAssetTagFragment[]
    )
  }, [assetIds])

  return (
    <div className="flex min-w-0 flex-col">
      <div className="mb-2 flex items-center justify-between">
        <h3 className="shrink-0 truncate font-medium">
          {i18n.t("tasks:checklist.consumables.title")}
        </h3>
        <Button
          type="tertiary"
          icon={Plus}
          color="gray"
          onClick={async () => {
            const consumableIds = await selectConsumable({
              initialValues: {
                consumableIds: fields.map((c) => (c as any).consumable_id),
              },
            })

            if (!consumableIds) return

            const newIds = consumableIds.filter(
              (id) => !fields.find((f: any) => f.consumable_id === id)
            )
            const deletedIds: number[] = []
            fields.forEach((field: any, index) => {
              if (!consumableIds.find((id) => id === field.consumable_id)) {
                deletedIds.push(index)
              }
            })
            remove(deletedIds)

            append(
              newIds.map((id) => ({
                quantity: 1,
                consumable_id: id,
                place_id:
                  consumables.find((c) => c.id === id)?.storage_locations[0]?.place_id ??
                  null,
                consumable_log_id: v4(),
                asset_id: assetIds[0] ?? null,
              }))
            )
          }}>
          {i18n.t("common:add_token", {
            token: i18n.t("common:consumable", { count: 1 }),
          })}
        </Button>
      </div>

      <div className="flex flex-col divide-y divide-gray-100 rounded-lg bg-gray-50 p-3">
        {fields.map(({ consumable_id, id }: any, index) => {
          const consumable = consumables.find((c) => c.id === consumable_id)
          if (!consumable) return null
          return (
            <ConsumableFormRowItem
              key={id}
              control={props.control}
              name={`${props.field}.${props.index}.config.consumables.${index}`}
              onRemove={() => remove(index)}
              assets={assets}
              consumable={consumable}
              storageLocations={consumable.storage_locations}
            />
          )
        })}
      </div>
    </div>
  )
}

export const ConsumablesBlockElement = (
  props: BlockElementProps<BlockElementConsumables>
) => {
  const client = useClient()

  const dataEntryScope = usePermissionScope(IPermissionScopeEnum.AppDataEntry)
  const [consumablesQueryRes] = useConsumablesQuery({ requestPolicy: "cache-first" })
  const consumables = consumablesQueryRes.data?.consumable ?? []

  return (
    <div>
      <div className="mb-2 font-medium">{i18n.t("tasks:checklist.consumables.title")}</div>

      <table className="min-w-full divide-y divide-gray-300 text-sm">
        <thead>
          <tr>
            <th scope="col" className="w-0"></th>
            <th
              scope="col"
              className="w-full pb-2 pr-3 text-left text-sm font-medium text-gray-500">
              {i18n.t("common:consumable", { count: 1 })}
            </th>
            <th
              scope="col"
              className="px-3 pb-2 text-left text-sm font-medium text-gray-500">
              {i18n.t("consumables:fields.quantity")}
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-100">
          {props.element.config.consumables.map(
            ({ consumable_id, quantity, consumable_log_id, asset_id, place_id }) => {
              const consumable = consumables.find((c) => c.id === consumable_id)
              const hasConsumed = props.element.response?.consumed?.some(
                (c) => c.consumable_log_id === consumable_log_id
              )

              return (
                <tr key={consumable_log_id}>
                  <td className="px-4 py-2">
                    <CheckboxInput
                      id={consumable_log_id}
                      checked={hasConsumed}
                      onChange={async (e) => {
                        if (e.target.checked && !hasConsumed) {
                          await props.updateResponse(props.element.id, {
                            ...props.element.response,
                            consumed: (props.element.response?.consumed ?? []).concat([
                              { consumable_log_id },
                            ]),
                          })
                          await client
                            .mutation<
                              IInsertConsumableLogMutation,
                              IInsertConsumableLogMutationVariables
                            >(
                              InsertConsumableLogDocument,
                              {
                                data: {
                                  id: consumable_log_id,
                                  consumable_id,
                                  task_id: props.element.group.work_order?.id,
                                  asset_id: asset_id ?? props.element.config.asset_id,
                                  adjustment: -quantity,
                                  place_id:
                                    place_id ??
                                    consumable?.storage_locations[0]?.place_id ??
                                    null,
                                  cost_per_unit: consumable?.cost ?? 0,
                                },
                              },
                              dataEntryScope.context()
                            )
                            .toPromise()
                        } else if (!e.target.checked && hasConsumed) {
                          await props.updateResponse(props.element.id, {
                            ...props.element.response,
                            consumed: (props.element.response?.consumed ?? []).filter(
                              (c) => c.consumable_log_id !== consumable_log_id
                            ),
                          })
                          await client
                            .mutation<
                              IDeleteConsumableLogMutation,
                              IDeleteConsumableLogMutationVariables
                            >(
                              DeleteConsumableLogDocument,
                              {
                                id: consumable_log_id,
                              },
                              dataEntryScope.context()
                            )
                            .toPromise()
                        }
                      }}
                    />
                  </td>
                  <td className="py-2">
                    <div className="flex items-center">
                      <LinkWithBackgroundLocation
                        to={`/consumable/${consumable_id}`}
                        className="group line-clamp-2 block hover:text-gray-900">
                        <span className="font-medium group-hover:underline">
                          {consumable?.name}
                        </span>
                        <span className="ml-1 text-gray-500">{consumable?.public_id}</span>
                      </LinkWithBackgroundLocation>

                      {asset_id && (
                        <span className="relative ml-2 flex items-center text-gray-500 hover:text-gray-700">
                          <Tooltip
                            content={<AssetName assetId={asset_id} />}
                            delayDuration={50}>
                            <Shapes />
                            <TouchTargetSize />
                          </Tooltip>
                        </span>
                      )}
                    </div>
                    <div className="flex items-center">
                      <ConsumableStorageLocation
                        storageLocation={
                          consumable?.storage_locations?.find(
                            (s) => s.place_id === place_id
                          ) ?? null
                        }
                      />
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-2">
                    <span className="">
                      {quantity}
                      <span className="ml-1 text-gray-500">{consumable?.unit}</span>
                    </span>
                  </td>
                </tr>
              )
            }
          )}
          <tr>
            <td />
            <td className="flex py-2">
              <span className="mr-1 font-medium">{i18n.t("consumables:labels.total")}</span>
              {props.element.config.consumables.length} {i18n.t("consumable", { count: 2 })}
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
