import { useMemo } from "react"

import useCallbackRef from "./use-callback-ref"
import { useControllableState } from "./use-controllable-state"

export type UseDisclosureOptions = {
  initialValue?: boolean
  onClose?: () => void
  onOpen?: () => void
  isOpen?: boolean
  onOpenChange?: (isOpen: boolean) => void
}

export const useDisclosure = (options: UseDisclosureOptions = {}) => {
  const [isOpen, setOpen] = useControllableState({
    defaultProp: options.initialValue ?? false,
    prop: options.isOpen,
    onChange: options.onOpenChange,
  })

  const onClose = useCallbackRef(() => {
    if (isOpen) {
      setOpen(false)
      options.onClose?.()
    }
  })

  const onOpen = useCallbackRef(() => {
    if (!isOpen) {
      setOpen(true)
      options.onOpen?.()
    }
  })

  const toggle = useCallbackRef(() => {
    const newIsOpen = !isOpen
    setOpen(newIsOpen)
    if (newIsOpen) {
      options.onOpen?.()
    } else {
      options.onClose?.()
    }
  })

  const changeOpen = useCallbackRef((newIsOpen: boolean) => {
    if (newIsOpen !== isOpen) {
      setOpen(newIsOpen)
      if (newIsOpen) {
        options.onOpen?.()
      } else {
        options.onClose?.()
      }
    }
  })

  const value = useMemo(
    () => ({
      isOpen,
      onClose,
      onOpen,
      toggle,
      changeOpen,
    }),
    [isOpen]
  )

  return value
}

export type UseDisclosureReturnType = ReturnType<typeof useDisclosure>
