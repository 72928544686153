import { SubpageLayout } from "@components/page/subpage-layout"
import i18n from "@i18n"

import { useAsset } from "./$id"

const ObjectActivity = () => {
  const context = useAsset()
  const pages = [
    {
      path: "reliability",
      label: i18n.t("assets:tabs.reliability"),
    },
    {
      path: "activity",
      label: i18n.t("assets:tabs.activity"),
    },
  ]
  return <SubpageLayout pages={pages} context={context} />
}

export default ObjectActivity
