import MeterType from "@components/asset/asset-meter/asset-meter-type"
import { AssetWithParents } from "@components/asset/asset-with-parents"
import MeterReadingTriggerDigest from "@components/maintenance/components/meter-reading-trigger-digest"
import CreateEditMaintenanceFormDialog from "@components/maintenance/dialogs/create-edit-maintenance-form-dialog"
import { getDefaultMeterReadingTriggerOptions } from "@components/maintenance/types"
import { getUserName } from "@components/shared/user-tag"
import {
  IMaintenanceTriggerTypeEnum,
  IMeterTypeEnum,
  MeterReadingTriggerOptions,
} from "@elara/db"
import { orderBy } from "@elara/select"
import {
  IMaintenanceFragment,
  IMeterDetailFragment,
} from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { Pause, Plus } from "@phosphor-icons/react"
import { cn } from "@utils"
import { formatDate } from "@utils/date"
import { LinkWithBackgroundLocation } from "@utils/location"
import { useMemo } from "react"

export const MeterDetails = (props: { meter: IMeterDetailFragment }) => {
  const { meter } = props
  const lastReading = meter.last_reading?.[0]

  const initialMaintenanceValues = useMemo(() => {
    const options = getDefaultMeterReadingTriggerOptions()

    const triggers = [
      {
        ...options,
        payload: {
          ...options.payload,
          meterId: meter.id,
          assetId: meter.asset_id,
          variant: meter.meter_type === IMeterTypeEnum.Measurement ? "range" : "interval",
          min: meter.range_start ?? undefined,
          max: meter.range_end ?? undefined,
        },
      },
    ]

    return { name: "", triggers }
  }, [meter])

  return (
    <dl className="grid max-w-2xl grid-cols-1 gap-4 p-4 sm:grid-cols-3">
      {lastReading && (
        <div className="col-span-2 inline-block rounded-lg bg-blue-50 p-3">
          <div className="text-sm text-blue-700">
            {i18n.t("meters:fields.last_reading")}{" "}
            <span className="font-medium">
              {lastReading.value} {meter.unit}
            </span>
          </div>
          <div className="text-sm text-blue-700 opacity-70">
            {i18n.t("meters:fields.measured_by")} {getUserName(lastReading.measured_by)} (
            {formatDate(new Date(lastReading.measured_at), "Pp")})
          </div>
        </div>
      )}
      <div className="col-span-full">
        <dt className="text-sm font-medium text-gray-500">{i18n.t("common:asset_one")}</dt>
        <dd className="mt-1 text-sm text-gray-900">
          <div className="inline-block">
            <AssetWithParents
              withLink
              showAvatar
              asset={meter.asset}
              linkToSubpage="meters"
            />
          </div>
        </dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">
          {i18n.t("meters:fields.unit")}
        </dt>
        <dd className="mt-1 text-sm text-gray-900">{meter.unit}</dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">
          {i18n.t("meters:fields.type")}
        </dt>
        <dd className="mt-1 text-sm text-gray-900">
          <MeterType type={meter.meter_type ?? IMeterTypeEnum.Meter} />
        </dd>
      </div>
      {meter.meter_type === IMeterTypeEnum.Measurement && (
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">
            {i18n.t("meters:fields.tolerance_range")}
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {meter.range_start} - {meter.range_end} {meter.unit}
          </dd>
        </div>
      )}

      <div className="col-span-full mt-4">
        <h4 className="mb-3 text-sm font-medium text-gray-500">
          {i18n.t("meters:labels.connected_preventive_maintenance")}
        </h4>
        <div className="space-y-2">
          {orderBy(meter.maintenance_triggers, {
            maintenance: { paused: "asc", name: "asc" },
          }).map((t) => {
            if (!t.maintenance) return null
            if (t.type !== IMaintenanceTriggerTypeEnum.Meter) return null
            return (
              <LinkWithBackgroundLocation
                to={"/maintenance/" + t.maintenance.id}
                className={cn("group block rounded-lg bg-gray-50 hover:bg-gray-100", {
                  "opacity-75": t.maintenance.paused,
                })}
                key={t.id}>
                <div className="flex items-center p-3 text-sm font-medium text-gray-700">
                  {t.maintenance.paused && (
                    <Pause weight="fill" className="mr-1 text-gray-500" />
                  )}{" "}
                  {t.maintenance?.name}
                </div>
                <div className="space-y-1 rounded-lg rounded-t-none bg-gray-100 p-3 text-sm group-hover:bg-gray-200">
                  <div className="mb-2 text-xs font-medium text-gray-600">
                    {i18n.t("maintenance:labels.triggers.maintenance_interval")}
                  </div>
                  <MeterReadingTriggerDigest
                    payload={t.payload as MeterReadingTriggerOptions["payload"]}
                    unit={meter.unit}
                  />
                </div>
              </LinkWithBackgroundLocation>
            )
          })}

          <label className="col-span-2 flex h-12 cursor-pointer items-center justify-center rounded-lg border border-dashed border-gray-200 p-4 text-sm font-medium text-gray-600 hover:border-gray-300 hover:bg-gray-50">
            <CreateEditMaintenanceFormDialog
              initialValues={
                initialMaintenanceValues as unknown as Partial<IMaintenanceFragment>
              }>
              <button className="flex items-center">
                <Plus weight="bold" className="mr-1 h-5 w-5 text-gray-500" />
                <span>
                  {i18n.t("common:add_token", { token: i18n.t("maintenance:title") })}
                </span>
              </button>
            </CreateEditMaintenanceFormDialog>
          </label>
        </div>
      </div>
    </dl>
  )
}
