import { InitialFormValuesInput } from "@components/work-order/create-task-form.hooks"
import { useDataWorkOrderDataView } from "@components/work-order/use-task-data"
import {
  WorkOrderDataView,
  workOrderDataViewDefaultConfig,
} from "@components/work-order/work-order-data-view"
import { useOpenModal } from "@contexts/modal-context"
import { IWorkOrderTypeEnum } from "@elara/db"
import { Where } from "@elara/select"
import { IWorkOrderDataViewFragment } from "@graphql/documents/fragments.generated"
import { useCallback } from "react"

import { useAsset } from "./$id"

const AssetOverview = () => {
  const { asset } = useAsset()

  const openModal = useOpenModal()

  const useData = useCallback(
    (where: Where<IWorkOrderDataViewFragment> | null) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useDataWorkOrderDataView(
        {
          _and: [
            where ?? {},
            {
              type: { _in: [IWorkOrderTypeEnum.WorkOrder, IWorkOrderTypeEnum.ReportWork] },
              assets: {
                asset: {
                  _or: [{ id: { _eq: asset.id } }, { parent_ids: { _has_key: asset.id } }],
                },
              },
            },
          ],
        },
        { requestPolicy: "cache-and-network" }
      )
    },
    [asset.id]
  )

  const createWorkOrder = (initialValues: InitialFormValuesInput = {}) => {
    openModal({ task_create: { open: true, payload: { initialValues } } })
  }

  return (
    <WorkOrderDataView
      data={null}
      useData={useData}
      headerClassName="px-3 pt-3"
      configId="object:id:tasks"
      initialValuesForCreate={{ asset_ids: [asset.id!] }}
      onDateClick={(key, date) => createWorkOrder({ [key]: date, asset_ids: [asset.id!] })}
      defaultConfig={{
        ...workOrderDataViewDefaultConfig,
        historyFilter: {
          type: "select",
          id: "completedAt",
          selectedValues: [{ type: "relative", years: 1 }],
        },
      }}
    />
  )
}

export default AssetOverview
