import { useBreakpoint } from "@contexts/breakpoints"
import { Transition } from "@headlessui/react"
import { useDisclosure } from "@hooks"
import { useStickyState } from "@hooks/use-sticky-state"
import classNames from "classnames"
import React, { useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"

import { Portal } from "../shared/portal"
import { NavigationMenu } from "./navigation-menu"

const minWidth = 250,
  maxWidth = 450

export const Sidebar = (
  props: React.PropsWithChildren<{
    className?: string
    isMenuOpen: boolean
    onMenuClose: () => void
  }>
) => {
  const bp = useBreakpoint()
  const menu = useDisclosure()
  const location = useLocation()

  const [width, setWidth] = useStickyState(280, "page:sidebar-width")

  const [collapsed, changeCollapsed] = useStickyState(false, "page:sidebar-collapsed")

  const isDragging = useRef(false)
  const dragStart = useRef({ clientX: 0, width })

  useEffect(() => {
    props.onMenuClose()
  }, [location.pathname])

  useEffect(() => {
    const onPointerMove = (e: PointerEvent) => {
      if (!isDragging.current) return

      const width = e.clientX - dragStart.current.clientX + dragStart.current.width

      setWidth(width)
    }

    const onPointerUp = () => (isDragging.current = false)

    document.addEventListener("pointermove", onPointerMove)
    document.addEventListener("pointerup", onPointerUp)

    return () => {
      document.removeEventListener("pointermove", onPointerMove)
      document.removeEventListener("pointerup", onPointerUp)
    }
  }, [])

  const onPointerDown = (e: React.PointerEvent) => {
    isDragging.current = true
    dragStart.current = { clientX: e.clientX, width }
  }

  if (bp.lg) {
    return (
      <aside
        className={classNames(
          "relative flex w-48 min-h-screen max-h-screen shrink-0 grow-0 select-none flex-col text-sm print:hidden",
          { "transition-[width,min-width,max-width]": !isDragging.current }
        )}
        style={
          collapsed
            ? { width: 48, minWidth: 0, maxWidth: 48 }
            : { width, minWidth, maxWidth }
        }>
        <div className="flex min-h-0 min-w-0 flex-1 flex-col border-r border-gray-100">
          <NavigationMenu collapsed={collapsed} onChangeCollapsed={changeCollapsed} />
        </div>
        {!collapsed && (
          <div
            onPointerDown={onPointerDown}
            className="group absolute inset-y-0 -right-3 z-20 w-3 cursor-col-resize touch-none">
            <div className="absolute inset-y-0 left-0 w-1 bg-transparent transition-colors group-hover:bg-gray-600/40" />
          </div>
        )}
      </aside>
    )
  }

  return (
    <>
      <aside
        style={{ width: 48, minWidth: 0, maxWidth: 48 }}
        className="relative flex w-48 shrink-0 grow-0 select-none flex-col text-sm print:hidden">
        <div className="flex min-h-0 min-w-0 flex-1 flex-col border-r border-gray-100">
          <NavigationMenu collapsed onChangeCollapsed={() => menu.changeOpen(true)} />
        </div>
      </aside>

      <Portal>
        <Transition
          show={menu.isOpen}
          style={{ width, minWidth, maxWidth }}
          className="fixed inset-y-0 left-0 z-[1] flex flex-col bg-white shadow-lg shadow-gray-200"
          enter="transition duration-150"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition duration-75"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full">
          <div className="fixed inset-0" onClick={menu.onClose} />
          <NavigationMenu
            collapseOnSelect
            collapsed={false}
            onChangeCollapsed={() => menu.changeOpen(false)}
          />
        </Transition>
      </Portal>
    </>
  )
}

export default Sidebar
