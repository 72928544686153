import { Center, VStack } from "@components/layout"
import { useBreakpoint } from "@contexts/breakpoints"
import { useFeature } from "@contexts/feature-flag-context"
import { IViewDataTypeEnum } from "@elara/db"
import { Data, Where } from "@elara/select"
import { EventInput } from "@fullcalendar/core"
import { ICustomViewFragment } from "@graphql/documents/custom-view.generated"
import { StickyType, useCallbackRef, useDisclosure } from "@hooks"
import { useElementSize } from "@hooks/use-element-size"
import i18n from "@i18n"
import { Plus } from "@phosphor-icons/react"
import Icons from "@resources/icons"
import { unique } from "@utils"
import classNames from "classnames"
import React, { ReactNode, useEffect, useImperativeHandle, useMemo, useRef } from "react"

import Button from "../button"
import { Item } from "../combobox-select"
import EmptyState, { EmptyStateProps } from "../empty-state"
import LoadingIndicator from "../loading-indicator"
import ScrollArea from "../scroll-area"
import SearchIconField from "../search-icon-field"
import { SelectPopover } from "../single-select"
import { useDataView, UseDataViewReturnType } from "./data-view.hooks"
import DataViewCalendar from "./data-view-calendar"
import { useDataViewConfigContext } from "./data-view-config"
import { CustomizeColumnsDrawer } from "./data-view-customize-columns-drawer"
import DataViewFilterRoot, {
  DataViewFilterBadges,
  DataViewFilterMenuPopover,
  DataViewFilterTrigger,
  useDataViewFilterMaxListHeight,
} from "./data-view-filter"
import { Schema } from "./data-view-filter.hooks"
import DataViewKanban from "./data-view-kanban"
import DataViewList from "./data-view-list"
import { DataViewOptionsMenu } from "./data-view-options-menu"
import DataViewTable from "./data-view-table"
import {
  Column,
  CustomDataConfig,
  DataOrderConfig,
  DataViewConfiguration,
  DataViewLayoutType,
  FilterState,
} from "./data-view-types"
export * from "./data-view.hooks"
export type { Column, DataViewConfiguration } from "./data-view-types"

const useFilterControl = (params: {
  dataView: UseDataViewReturnType<any, any>
  hasCustomView: boolean
}) => {
  const { dataView, hasCustomView } = params
  const showMenu = useDisclosure()
  const showFilterBadges = useDisclosure()

  const isDefaultViewWithActiveFilters =
    !hasCustomView && dataView.dataViewFilter.hasFiltersAdded

  const isCustomViewWithChangedFilters = hasCustomView && !dataView.areFiltersPersisted
  const isCustomViewWithChangedConfig = hasCustomView && !dataView.isConfigPersisted

  const shouldShowFilterBadges =
    showFilterBadges.isOpen &&
    (dataView.dataViewFilter.hasFiltersAdded || isCustomViewWithChangedFilters)

  const shouldShowFilterActionButtons =
    isDefaultViewWithActiveFilters || isCustomViewWithChangedConfig

  const highlightFilterButton =
    dataView.dataViewFilter.hasFiltersAdded &&
    (!dataView.isConfigPersisted || !hasCustomView)

  return {
    showMenu,
    showFilterBadges,
    shouldShowFilterActionButtons,
    shouldShowFilterBadges,
    highlightFilterButton,
  }
}

type UseFilterControlReturnType = ReturnType<typeof useFilterControl>

const DataViewFilterButton = <D extends Data, Id extends string>(props: {
  actions?: ReactNode
  hasFilterConfigured?: boolean
  hasFiltersStored: boolean
  dataView: UseDataViewReturnType<D, Id>
  filterControl: UseFilterControlReturnType
}) => {
  const { filterControl, dataView } = props
  const [dataViewFilterMaxListHeight, dataViewFilterRef] = useDataViewFilterMaxListHeight()
  const innerButton = (onClick?: () => void) => (
    <button
      type="button"
      className={classNames(
        "isolate group flex h-8 relative items-center justify-center whitespace-nowrap rounded font-medium border px-2 text-center align-middle text-sm sm:!ml-0",
        {
          "bg-blue-50 text-blue-600 border-blue-300 hover:border-blue-400 hover:text-blue-800 hover:bg-blue-100":
            filterControl.showFilterBadges.isOpen,
          "text-gray-600 hover:border-gray-400 hover:text-gray-800 border-gray-300 hover:bg-gray-100":
            !filterControl.showFilterBadges.isOpen,
        }
      )}
      ref={dataViewFilterRef}
      onClick={onClick}>
      <span
        className={classNames(
          "mr-1.5 rounded font-medium text-xs px-1 border border-dashed",
          {
            "border-blue-300 text-blue-600 group-hover:bg-blue-100 group-hover:text-blue-800":
              filterControl.showFilterBadges.isOpen || filterControl.highlightFilterButton,
            "border-gray-300 text-gray-500 group-hover:bg-gray-100 group-hover:text-gray-800":
              !filterControl.showFilterBadges.isOpen &&
              !filterControl.highlightFilterButton,
          }
        )}>
        {dataView.dataViewFilter.filterStates.length}
      </span>
      {i18n.t("data-view:filter", { count: dataView.dataViewFilter.filterStates.length })}
    </button>
  )

  if (
    !filterControl.showMenu.isOpen &&
    (dataView.dataViewFilter.hasFiltersAdded || props.hasFiltersStored)
  ) {
    return innerButton(filterControl.showFilterBadges.toggle)
  }

  return (
    <DataViewFilterMenuPopover
      side="left"
      align="start"
      sideOffset={4}
      alignOffset={0}
      onOpen={() => {
        filterControl.showMenu.changeOpen(true)
        filterControl.showFilterBadges.onOpen()
      }}
      onClose={() => {
        filterControl.showMenu.changeOpen(false)
      }}
      listMaxHeight={dataViewFilterMaxListHeight}>
      <DataViewFilterTrigger>
        {dataView.dataViewFilter.hasFiltersAdded ? (
          innerButton()
        ) : (
          <button
            type="button"
            ref={dataViewFilterRef}
            className="flex h-8 items-center justify-center whitespace-nowrap rounded border border-dashed border-gray-400 px-2 text-center align-middle text-sm text-gray-600 hover:border-gray-600 hover:text-gray-800 sm:!ml-0">
            <Plus className="mr-1" />
            {i18n.t("data-view:filter", { count: 1 })}
          </button>
        )}
      </DataViewFilterTrigger>
    </DataViewFilterMenuPopover>
  )
}

const DataViewActions = <D extends Data, Id extends string, O extends {}>(
  props: {
    dataView: UseDataViewReturnType<D, Id>
    enableKanbanView?: boolean
    enableCalendarView?: boolean
  } & CoreDataViewProps<D, Id, O>
) => {
  const { dataView, allowToSaveCustomView = false } = props

  const bp = useBreakpoint()

  const columnsDisclosure = useDisclosure()
  const hasKanbanViewFeature = useFeature("kanban_view")
  const filterControl = useFilterControl({ dataView, hasCustomView: !!props.customView })
  const configCtx = useDataViewConfigContext()

  const hasFilterConfigured =
    !!props.filterSchema && Object.keys(props.filterSchema).length > 0
  const hasFiltersStored = !!configCtx.resettedConfig.filters?.length

  const defaultCalendarColumnDef = props.columnDefinitions.find(
    (col) => col.calendarView?.default === true
  )
  const supportsCalendarView = !!defaultCalendarColumnDef

  const availableLayoutTypes: DataViewLayoutType[] = ["table"]

  if (props.listItem) {
    availableLayoutTypes.push("list")
  }

  if (supportsCalendarView) {
    availableLayoutTypes.push("calendar")
  }

  if (props.enableKanbanView && hasKanbanViewFeature) {
    availableLayoutTypes.push("kanban")
  }

  const updateHistoryFilter = (v: any) =>
    dataView.updateHistoryFilter({
      ...dataView.config.historyFilter,
      selectedValues: [v],
    } as any)
  const historyFilterValue =
    dataView?.config?.historyFilter?.type === "select"
      ? dataView.config.historyFilter.selectedValues[0]
      : undefined

  const historyFilterMenuPoint =
    (bp.lg || (bp.md && !props.actions)) &&
    dataView.config.historyFilter &&
    props.historyFilterItems &&
    props.useData ? (
      <div className="flex items-center space-x-2 rounded border border-gray-200 bg-gray-100 pl-1 text-sm text-gray-700">
        <span className="whitespace-nowrap font-medium">{props.historyFilterLabel}</span>
        <SelectPopover<any>
          className="-m-px translate-x-px"
          items={props.historyFilterItems}
          value={historyFilterValue}
          isClearable={false}
          onChange={updateHistoryFilter}
        />
      </div>
    ) : null

  const actionRowRef = useRef<HTMLDivElement | null>(null)
  const el = useElementSize(actionRowRef.current)

  return (
    <>
      <div className={props.headerClassName}>
        <div
          ref={actionRowRef}
          className={classNames("flex gap-2 flex-wrap items-center relative")}>
          {bp.mobile ? (
            <div className="h-8 w-8">
              <div className="absolute z-10 bg-white">
                <SearchIconField
                  alwaysExpanded={false}
                  value={dataView.searchValue}
                  onChange={dataView.setSearchValue}
                  placeholder={props.searchPlaceholder ?? i18n.t("common:search")}
                  maxWidth={
                    bp.mobile
                      ? (el?.width ?? Math.min(window.innerWidth, 120)) - 32
                      : Math.min(window.innerWidth, 120)
                  }
                />
              </div>
            </div>
          ) : (
            <SearchIconField
              alwaysExpanded={bp.renderDesktop}
              value={dataView.searchValue}
              onChange={dataView.setSearchValue}
              placeholder={props.searchPlaceholder ?? i18n.t("common:search")}
              maxWidth={Math.min(window.innerWidth, 120)}
            />
          )}

          {historyFilterMenuPoint}

          <div className="order-3 flex flex-1 flex-row items-center justify-end gap-2">
            {dataView.isDataLoading && <LoadingIndicator size={20} />}
            {hasFilterConfigured && (
              <DataViewFilterButton
                {...props}
                filterControl={filterControl}
                hasFiltersStored={hasFiltersStored}
              />
            )}
            {
              <DataViewOptionsMenu
                dataView={dataView}
                customDataConfigMenuOptions={props.customDataConfigMenuOptions}
                dataType={props.dataType}
                columns={props.columnDefinitions}
                onOpenTableMenu={columnsDisclosure.onOpen}
                availableLayoutTypes={availableLayoutTypes}
                historyFilterItems={props.historyFilterItems}
                historyFilterLabel={props.historyFilterLabel}
                updateHistoryFilter={updateHistoryFilter}
                historyFilterValue={historyFilterValue}
              />
            }
            {props.actions && <div className="order-7 sm:order-none">{props.actions}</div>}
          </div>
        </div>
        {filterControl.shouldShowFilterBadges && (
          <div className="mt-3 flex flex-col gap-3 sm:flex-row">
            <DataViewFilterBadges className="flex-1" allowToAddToEmptyList />

            {filterControl.shouldShowFilterActionButtons && (
              <div className="flex w-full justify-end space-x-0.5 sm:w-auto sm:self-center">
                {(!dataView.isConfigPersisted || !dataView.areFiltersPersisted) && (
                  <Button type="tertiary" color="gray" onClick={configCtx.resetConfig}>
                    {i18n.t("data-view:filters.reset")}
                  </Button>
                )}
                {allowToSaveCustomView && (
                  <Button
                    size="small"
                    icon={Icons.Collection}
                    type="primary"
                    onClick={() => configCtx.save()}>
                    {props.customView
                      ? i18n.t("common:save")
                      : i18n.t("views:actions.save_view")}
                  </Button>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      <CustomizeColumnsDrawer
        isOpen={columnsDisclosure.isOpen}
        onClose={columnsDisclosure.onClose}
        onSave={(cols) => {
          dataView.updateColumnOrder(cols)
          columnsDisclosure.onClose()
        }}
        columnOrder={dataView.config.columnOrder}
        columnDefinitions={props.columnDefinitions}
      />
    </>
  )
}

export type BasicDataViewProps<D extends Data, Id extends string, Options extends {}> = {
  configId: string
  dataType: IViewDataTypeEnum

  // DATA
  data: D[] | null | undefined
  useData?: (where: Where<D> | null) => {
    data: D[] | null
    isLoading: boolean
    events?: (range: { from: Date; to: Date }) => EventInput[]
  }

  dataId: (data: D) => string
  dataSearchValue: (data: D) => string
  options?: Options

  // FILTER
  filterSchema?: Schema<D>
  configStickyness?: StickyType
  hiddenFixedFilters?: FilterState[]
  fixedFilters?: FilterState[]

  /**
   * baseOrderBy is used to break ties. Assume you order work orders by category,
   * then you will have many ties in the ordering (i.e. for all WO in the same categoy),
   * and you don’t want them to be sorted randomly.
   * So the baseOrderBy is always appended to the chosen orderBy to make it basically a multi-column sort
   */
  baseOrderBy?: DataOrderConfig<Id>[]
  columnDefinitions: Column<D, Id, Options>[]
  customView?: ICustomViewFragment
  defaultConfig: DataViewConfiguration<Id>
  customDataConfigToWhere?: (config: CustomDataConfig | null) => Where<D> | null
  customDataConfigMenuOptions?: (
    config: CustomDataConfig | null,
    updateConfig: (config: CustomDataConfig) => void
  ) => ReactNode
  getParentItemId?: (data: D) => string | null
  listItem?: (data: D, options?: Options) => ReactNode
  cardItem?: (data: D, options?: Options) => ReactNode
  eventItem?: (data: D | null, event: EventInput, options?: Options) => ReactNode
  onSelect: (item: D) => void
  onKeyboardSelect?: (item: D) => void
  selectedDataId?: string | null
  searchPlaceholder?: string
  cellClass?: string
  className?: string
  headerClassName?: string
  compact?: boolean
  hasNoDataToView?: boolean
  noData?: EmptyStateProps
  containerRef?: React.RefObject<HTMLDivElement>
  allowToSaveCustomView?: boolean
  contextMenu?: (row: D) => ReactNode
  historyFilterItems?: Item<any>[]
  historyFilterLabel?: string

  // create and edit custom view -- internal
  createViewForm?: ReactNode
  isEditView?: boolean
  onEditViewFinish?: () => void
  hideActionRow?: boolean
  actions?: React.ReactNode

  // Calendar View Callbacks
  calendarCustomEvents?: (range: { from: Date; to: Date }) => EventInput[]
  onDateClick?: (key: keyof D, date: Date) => void
  onDateDragDrop?: (key: keyof D, row: D, newDate: Date) => void

  // Kanban View Callbacks
  onDragDrop?: (key: keyof D, row: D, sourceId: string, targetId: string) => void
  innerRef?: React.RefObject<{ dataView: UseDataViewReturnType<D, Id> }>
}

export type CoreDataViewHandle<D extends Data, Id extends string> = {
  dataView: UseDataViewReturnType<D, Id>
}

export type CoreDataViewProps<
  D extends Data,
  Id extends string,
  Options extends {}
> = BasicDataViewProps<D, Id, Options> & {
  initialFilters?: FilterState[]
  onFiltersChange?: (filters: FilterState[]) => void
}

export function CoreDataView<D extends Data, Id extends string, Options extends {}>(
  props: CoreDataViewProps<D, Id, Options>
) {
  const { filterSchema = {}, hiddenFixedFilters = [] } = props
  const fixedFilters = unique(props.fixedFilters ?? [])

  const hasKanbanViewFeature = useFeature("kanban_view")

  const dataId = useCallbackRef(props.dataId)
  const dataSearchValue = useCallbackRef(props.dataSearchValue)
  const getParentItemId = useCallbackRef(props.getParentItemId)

  // Local storage should not distinu

  const dataView = useDataView<D, Id, Options>(props.data ?? null, {
    useData: props.useData,
    customDataConfigToWhere: props.customDataConfigToWhere,
    configId: props.configId,
    isCustomView: !!props.customView,
    columns: props.columnDefinitions,
    dataId,
    dataSearchValue,
    getParentItemId,
    filterSchema,
    hiddenFixedFilters,
    fixedFilters,
    configStickyness: props.configStickyness,
    onFiltersChange: props.onFiltersChange,
    baseOrderBy: props.baseOrderBy,
  })

  const defaultCalendarColumnDef = props.columnDefinitions.find(
    (col) => col.calendarView?.default === true
  )
  const defaultKanbanColumnDef = props.columnDefinitions.find(
    (col) => col.kanbanView?.default === true
  )
  const orderByColumnDef = props.columnDefinitions.find((col) => col.id === "updatedAt")
  const supportsOrderByUpdatedAt = !!orderByColumnDef
  const supportsKanbanView = !!defaultKanbanColumnDef
  const supportsCalendarView = !!defaultCalendarColumnDef

  const currentGroupedColumnDef: Column<D, Id, Options> | undefined = useMemo(() => {
    if (dataView.config.groupBy) {
      return props.columnDefinitions.find((col) => col.id === dataView.config.groupBy)
    }
  }, [props.columnDefinitions, dataView.config.groupBy])

  // Handle the case that a non-memoizd on select is passed
  const onSelect = useCallbackRef(props.onSelect)

  // Switch from Calendar View to Table View if there are no default calendar columms
  // If there is a default column configured, switch grouping to that column.
  useEffect(() => {
    if (
      dataView.layoutType === "calendar" &&
      defaultCalendarColumnDef &&
      !supportsCalendarView
    ) {
      dataView.updateLayoutType("table")
    } else if (dataView.layoutType === "calendar" && defaultCalendarColumnDef) {
      dataView.updateGroupBy(defaultCalendarColumnDef.id)
    }
  }, [dataView.layoutType, defaultCalendarColumnDef, supportsCalendarView])

  // Switch from Kanban View to Table View if there are no default kanban columms
  // Switch orderBy to `updatedAt` if the layout type is Kanban
  useEffect(() => {
    if (dataView.layoutType === "kanban" && defaultKanbanColumnDef) {
      dataView.updateGroupBy(defaultKanbanColumnDef.id)
    }

    if (dataView.layoutType === "kanban" && supportsOrderByUpdatedAt) {
      dataView.updateOrderBy("updatedAt" as Id, "desc")
    }
  }, [dataView.layoutType, defaultKanbanColumnDef, supportsOrderByUpdatedAt])

  const hideCalendarView = useMemo(
    () =>
      dataView.hasNoSearchMatch ||
      dataView.hasNoFilterMatch ||
      (dataView.hasNoData && props.noData),
    [
      dataView.hasNoSearchMatch,
      dataView.hasNoFilterMatch,
      dataView.hasNoData && props.noData,
    ]
  )

  useImperativeHandle(props.innerRef, () => ({ dataView }), [dataView])

  return (
    <>
      <VStack space={12} flex="1" className={props.className}>
        <DataViewFilterRoot {...dataView.dataViewFilter}>
          <DataViewActions
            {...props}
            dataView={dataView}
            key={dataView.configId}
            enableKanbanView={supportsKanbanView}
            enableCalendarView={supportsCalendarView}
          />
        </DataViewFilterRoot>

        {!dataView.originalData.length && dataView.isDataLoading && (
          <Center flex="1">
            <LoadingIndicator size={24} />
          </Center>
        )}

        {!(!dataView.originalData.length && dataView.isDataLoading) && (
          <div className="relative flex min-h-0 flex-1 flex-col">
            {dataView.layoutType === "table" && (
              <DataViewTable<D, Id, Options>
                columnDefinitions={props.columnDefinitions}
                dataView={dataView}
                cellClass={props.cellClass}
                id={props.configId}
                options={props.options}
                onRowClick={onSelect}
                onKeyboardSelect={props.onKeyboardSelect}
                dataId={dataId}
                contextMenu={props.contextMenu}
                dataType={props.dataType}
              />
            )}
            {dataView.layoutType === "list" && props.listItem && (
              <DataViewList<D, Id, Options>
                dataView={dataView}
                id={props.configId}
                listItem={props.listItem}
                options={props.options}
                onRowClick={onSelect}
                onKeyboardSelect={props.onKeyboardSelect}
                dataId={dataId}
                dataType={props.dataType}
              />
            )}
            {dataView.layoutType === "calendar" &&
              currentGroupedColumnDef?.calendarView &&
              !hideCalendarView && (
                <DataViewCalendar<D, Id, Options>
                  id={props.configId}
                  dataView={dataView}
                  onEventClick={onSelect}
                  dataType={props.dataType}
                  eventItem={props.eventItem}
                  onDateClick={props.onDateClick}
                  onDateDragDrop={props.onDateDragDrop}
                  columnDef={currentGroupedColumnDef}
                  customEvents={
                    dataView.config.calendarConfig?.hideUpcomingMaintenanceTasks
                      ? undefined
                      : dataView.events
                  }
                />
              )}
            {dataView.layoutType === "kanban" &&
              currentGroupedColumnDef &&
              hasKanbanViewFeature && (
                <DataViewKanban<D, Id, Options>
                  id={props.configId}
                  dataView={dataView}
                  onCardClick={onSelect}
                  dataType={props.dataType}
                  cardItem={props.cardItem}
                  onDragDrop={props.onDragDrop}
                  columnDef={currentGroupedColumnDef}
                />
              )}
            {dataView.hasNoSearchMatch && (
              <div className="absolute inset-x-0 inset-y-6 z-10 flex select-none items-center justify-center text-gray-500">
                <div className="flex items-center">
                  <Icons.Search className="mr-1" />{" "}
                  {i18n.t("common:no_token", {
                    context: "plural",
                    token: i18n.t("common:result", { count: 2 }),
                  })}
                </div>
              </div>
            )}
            {dataView.hasNoFilterMatch && (
              <div className="absolute inset-x-0 inset-y-6 z-10 flex select-none items-center justify-center">
                <EmptyState
                  icon={Icons.FilterNoResult}
                  title={i18n.t("common:no_token", {
                    context: "plural",
                    token: i18n.t("common:result", { count: 2 }),
                  })}
                  message={i18n.t("data-view:messages.no_results_found")}
                  actions={
                    <Button
                      onClick={dataView.resetFilterAndSearch}
                      size="small"
                      type="tertiary">
                      {i18n.t("data-view:filters.reset")}
                    </Button>
                  }
                />
              </div>
            )}

            {dataView.hasNoData && props.noData && (
              <div
                className={classNames(
                  "absolute inset-x-0 inset-y-6 select-none flex min-h-0 flex-col ",
                  {
                    " top-[45px]": dataView.layoutType === "table",
                  }
                )}>
                <ScrollArea vertical viewportAsChild>
                  <div className="mx-auto mt-8">
                    <EmptyState {...props.noData} />
                  </div>
                </ScrollArea>
              </div>
            )}
          </div>
        )}
      </VStack>
    </>
  )
}
