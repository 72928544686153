import {
  DataViewChunked,
  DataViewChunkedProps,
} from "@components/new-data-view/data-view-chunked"
import { DataViewChunkedVirtualizedRowRenderer } from "@components/new-data-view/data-view-chunked-virtualized-row-renderer"
import {
  DataViewConfig,
  DataViewConfigProps,
  useDataViewConfigContext,
} from "@components/new-data-view/data-view-config"
import {
  DataViewFilter,
  DataViewFilterProps,
  useDataViewFilterContext,
} from "@components/new-data-view/data-view-filter"
import {
  DataViewAddFilter,
  DataViewAddFilterContent,
  DataViewAddFilterTrigger,
} from "@components/new-data-view/data-view-filter/add-filter"
import { DataViewFilterBadges } from "@components/new-data-view/data-view-filter/components"
import { DataViewGroupBy } from "@components/new-data-view/data-view-group-by"
import { DataViewSort, DataViewSortProps } from "@components/new-data-view/data-view-sort"
import { DataViewTable } from "@components/new-data-view/data-view-table"
import { DataViewTableHeader } from "@components/new-data-view/data-view-table-header"
import { DataViewTableRow } from "@components/new-data-view/data-view-table-row"
import {
  DataViewTree,
  DataViewTreeProps,
} from "@components/new-data-view/data-view-tree-toggle"
import Button from "@components/shared/button"
import toast from "@components/shared/toast"
import { IViewDataTypeEnum } from "@elara/db"
import { Data } from "@elara/select"
import { useElementSize } from "@hooks/use-element-size"
import i18n from "@i18n"
import { ArrowCounterClockwise, ArrowsClockwise } from "@phosphor-icons/react"
import { cn } from "@utils"
import { PropsWithChildren, ReactElement, useEffect, useRef, useState } from "react"
import { AnyVariables } from "urql"

import { DataViewList } from "./data-view-list"
import { DataViewListItem } from "./data-view-list-item"
import { DataViewOptionsMenu } from "./data-view-options-menu"
import {
  DataViewSearch,
  DataViewSearchField,
  DataViewSearchProps,
} from "./data-view-search"
import { DataViewTableFooter } from "./data-view-table-footer"
import { DataViewLayoutType } from "./data-view-types"
import { useDataViewRefreshData, useFetchAllRows } from "./lib/hooks"

function ActionBar<D extends Data, R, V extends AnyVariables = AnyVariables>(
  props: {
    dataType: IViewDataTypeEnum
    hasCalendar: boolean
    includeSubitemsLabel: string | null
  } & Pick<DataViewChunkedProps<D, R, V>, "getData" | "query" | "where">
) {
  const ctx = useDataViewConfigContext()
  const filters = useDataViewFilterContext()
  const hasFilterConfigured = filters.filterStates.length > 0
  const [showBadges, setShowBadges] = useState(false)
  const [actionBar, setActionBar] = useState<HTMLDivElement | null>(null)
  const actionBarSize = useElementSize(actionBar)
  const refreshData = useDataViewRefreshData()

  const prevFilterCount = useRef(filters.filterStates.length)
  useEffect(() => {
    if (prevFilterCount.current !== filters.filterStates.length) {
      if (prevFilterCount.current === 0 && filters.filterStates.length > 0) {
        setShowBadges(true)
      } else if (filters.filterStates.length === 0 && prevFilterCount.current > 0) {
        setShowBadges(false)
      }
    }
    prevFilterCount.current = filters.filterStates.length
  }, [filters.filterStates.length])

  const fetchAllRows = useFetchAllRows({
    getData: props.getData,
    query: props.query,
    where: props.where,
  })

  const availableLayoutTypes = ["table", "list"] as DataViewLayoutType[]
  if (props.hasCalendar) {
    availableLayoutTypes.push("calendar")
  }

  const showSaveButtonInFirstRow = (actionBarSize?.width ?? 0) > 800
  const configActionButtons = (
    <>
      <Button
        type="tertiary"
        color="gray"
        onClick={ctx.resetConfig}
        icon={ArrowCounterClockwise}>
        {i18n.t("data-view:actions.reset_view")}
      </Button>
      <Button onClick={() => ctx.save()}>{i18n.t("data-view:actions.save_view")}</Button>
    </>
  )

  return (
    <div className="px-3" ref={setActionBar}>
      <div className={cn("flex gap-2 flex-wrap items-center relative")}>
        <DataViewSearchField />
        <Button
          type="secondary"
          size="extra-small"
          onClick={() => {
            refreshData()
            toast.success({
              title: i18n.t("data-view:messages.updating_data"),
              params: { position: "bottom-right" },
            })
          }}
          icon={ArrowsClockwise}
        />

        <div className="flex-1" />

        {!ctx.isConfigPersisted && showSaveButtonInFirstRow && configActionButtons}

        {/* {dataView.isDataLoading && <LoadingIndicator size={20} />} */}
        {hasFilterConfigured ? (
          <Button
            type="secondary"
            onClick={() => setShowBadges((s) => !s)}
            className={cn({ "bg-blue-50 border-blue-500 text-blue-700": showBadges })}>
            <span
              className={cn(
                "mr-2 inline-block min-w-[1.25em] rounded-md bg-blue-50 px-[0.25em] py-[0.125em] text-center align-middle font-medium leading-none text-blue-700"
              )}>
              {filters.filterStates.length}
            </span>
            Filter
          </Button>
        ) : (
          <DataViewAddFilter>
            <DataViewAddFilterTrigger asChild>
              <Button type="secondary">Add Filter</Button>
            </DataViewAddFilterTrigger>
            <DataViewAddFilterContent align="start" />
          </DataViewAddFilter>
        )}
        <DataViewOptionsMenu
          fetchAllRows={fetchAllRows}
          dataType={props.dataType}
          includeSubitemsLabel={props.includeSubitemsLabel}
          availableLayoutTypes={availableLayoutTypes}
        />
      </div>
      {showBadges && (
        <div className="mt-3 flex flex-col gap-3">
          <DataViewFilterBadges className="flex-1" allowToAddToEmptyList />
          {!ctx.isConfigPersisted && !showSaveButtonInFirstRow && showBadges && (
            <div className=" flex justify-end space-x-3">{configActionButtons}</div>
          )}
          {/* {filterControl.shouldShowFilterActionButtons && (
              <div className="flex w-full justify-end space-x-0.5 sm:w-auto sm:self-center">
                {(!dataView.isConfigPersisted || !dataView.areFiltersPersisted) && (
                  <Button type="tertiary" color="gray" onClick={configCtx.resetConfig}>
                    {i18n.t("data-view:filters.reset")}
                  </Button>
                )}
                {allowToSaveCustomView && (
                  <Button
                    size="small"
                    icon={Icons.Collection}
                    type="primary"
                    onClick={() => configCtx.save()}>
                    {props.customView
                      ? i18n.t("common:save")
                      : i18n.t("views:actions.save_view")}
                  </Button>
                )}
              </div>
            )} */}
        </div>
      )}

      {!ctx.isConfigPersisted && !showSaveButtonInFirstRow && !showBadges && (
        <div className="mt-3 flex justify-end space-x-3">{configActionButtons}</div>
      )}
    </div>
  )
}

function ConditionalWrapper({
  condition,
  wrapper,
  children,
}: PropsWithChildren<{
  condition: boolean
  wrapper: (children: React.ReactNode) => ReactElement
}>) {
  return <>{condition ? wrapper(children) : children}</>
}

type DataViewContentProps<D extends Data, R, V extends AnyVariables> = Pick<
  DataViewChunkedProps<D, R, V>,
  "getData" | "query" | "chunkSize" | "where" | "tree" | "chunkSize" | "numberOfRows"
> & {
  containerRef: (el: HTMLDivElement | null) => void
  height: number
  onSelect: (d: D) => void
  renderListItem: (d: D) => React.ReactNode
  calendar?: React.ReactNode
}

function DataViewContent<D extends Data, R, V extends AnyVariables>(
  props: DataViewContentProps<D, R, V>
) {
  const ctx = useDataViewConfigContext()
  if (ctx.layoutType === "table") {
    return (
      <DataViewChunked<D, R, V>
        getData={props.getData}
        query={props.query}
        numberOfRows={props.numberOfRows}
        chunkSize={props.chunkSize}
        where={props.where}>
        <DataViewTable containerRef={props.containerRef}>
          <DataViewChunkedVirtualizedRowRenderer<D>
            overscan={10}
            style={{ height: props.height }}
            rowHeight={49}
            slot={{
              header: <DataViewTableHeader />,
              footer: <DataViewTableFooter />,
            }}
            render={(item) => <DataViewTableRow item={item} onRowClick={props.onSelect} />}
          />
        </DataViewTable>
      </DataViewChunked>
    )
  } else if (ctx.layoutType === "list") {
    return (
      <DataViewChunked<D, R, V>
        getData={props.getData}
        query={props.query}
        numberOfRows={props.numberOfRows}
        chunkSize={props.chunkSize}
        where={props.where}>
        <DataViewList containerRef={props.containerRef}>
          <DataViewChunkedVirtualizedRowRenderer<D>
            overscan={10}
            style={{ height: props.height }}
            rowHeight={49}
            render={(item) => (
              <DataViewListItem
                item={item}
                onRowClick={props.onSelect}
                render={props.renderListItem}
              />
            )}
          />
        </DataViewList>
      </DataViewChunked>
    )
  } else if (ctx.layoutType === "calendar") {
    return <>{props.calendar}</>
  }
  return null
}

export type DataViewProps<
  D extends Data,
  Id extends string,
  R,
  V extends AnyVariables
> = Pick<
  DataViewConfigProps<D, Id>,
  | "columns"
  | "customView"
  | "configId"
  | "configStickyness"
  | "dataType"
  | "defaultConfig"
  | "storeAllConfigChangesInCustomView"
  | "onCreateView"
  | "initialValuesForSaveAsCustomView"
> &
  Pick<DataViewFilterProps<D>, "schema"> &
  Pick<
    DataViewChunkedProps<D, R, V>,
    "getData" | "query" | "chunkSize" | "where" | "tree" | "chunkSize" | "numberOfRows"
  > &
  Pick<DataViewTreeProps<D>, "getId"> &
  Pick<DataViewSortProps<Id>, "tieBraker"> &
  Pick<DataViewSearchProps<Id>, "defaultSearchColumn"> &
  Pick<DataViewContentProps<D, R, V>, "onSelect" | "renderListItem"> & {
    calendar?: React.ReactNode
  }

export function DataView<D extends Data, Id extends string, R, V extends AnyVariables>(
  props: DataViewProps<D, Id, R, V>
) {
  const [container, setContainer] = useState<HTMLDivElement | null>(null)
  const size = useElementSize(container)
  return (
    <div className="flex min-h-0 min-w-0 flex-1 flex-col space-y-3">
      <DataViewConfig
        columns={props.columns}
        customView={props.customView}
        dataType={props.dataType}
        defaultConfig={props.defaultConfig}
        configId={props.configId}
        storeAllConfigChangesInCustomView={props.storeAllConfigChangesInCustomView}
        onCreateView={props.onCreateView}
        initialValuesForSaveAsCustomView={props.initialValuesForSaveAsCustomView}
        configStickyness={props.configStickyness}>
        <DataViewFilter<D> schema={props.schema}>
          <DataViewGroupBy>
            <DataViewSort<D, Id> tieBraker={props.tieBraker}>
              <DataViewSearch defaultSearchColumn={props.defaultSearchColumn}>
                <ConditionalWrapper
                  condition={!!props.tree}
                  wrapper={(children) => (
                    <DataViewTree getId={props.getId} {...props.tree!}>
                      {children}
                    </DataViewTree>
                  )}>
                  <ActionBar<D, R, V>
                    dataType={props.dataType}
                    query={props.query}
                    getData={props.getData}
                    where={props.where}
                    hasCalendar={!!props.calendar}
                    includeSubitemsLabel={props.tree?.includeSubitemsLabel ?? null}
                  />

                  <div
                    className="flex min-h-0 flex-1 flex-col overflow-hidden"
                    ref={setContainer}>
                    <DataViewContent
                      getData={props.getData}
                      query={props.query}
                      numberOfRows={props.numberOfRows}
                      chunkSize={props.chunkSize}
                      where={props.where}
                      containerRef={setContainer}
                      height={size?.height ?? 0}
                      onSelect={props.onSelect}
                      renderListItem={props.renderListItem}
                      calendar={props.calendar}
                    />
                  </div>
                </ConditionalWrapper>
              </DataViewSearch>
            </DataViewSort>
          </DataViewGroupBy>
        </DataViewFilter>
      </DataViewConfig>
    </div>
  )
}
