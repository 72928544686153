import { useUser } from "@contexts/user-context"
import {
  BlockElementMeterReading,
  IAssetXWorkOrderConstraint,
  IBlockElementTypeEnum,
  IBlockGroupConstraint,
  IBlockGroupInsertInput,
  IBlockGroupTypeEnum,
  IRecurrencePatternEnum,
  IWorkOrderInsertInput,
  IWorkOrderPriorityEnum,
  IWorkOrderStatusEnum,
  IWorkOrderTypeEnum,
  IWorkOrderXCollaboratorConstraint,
  IWorkOrderXTeamConstraint,
  IWorkOrderXUploadConstraint,
  IWorkOrderXUserConstraint,
  IWorkOrderXWorkOrderCategoryConstraint,
  RecurrenceInfo,
  uuid,
} from "@elara/db"
import {
  IBlockGroupFragment,
  IWorkOrderFragment,
} from "@graphql/documents/fragments.generated"
import { useTeamsQuery } from "@graphql/documents/team.generated"
import { IUploadDataFragment } from "@graphql/documents/upload.generated"
import i18n from "@i18n"
import { parseDate, toDateString, unique } from "@utils"
import * as date from "date-fns"
import { useEffect, useMemo } from "react"
import { UseFormReturn } from "react-hook-form"
import { Client, gql, useClient } from "urql"
import * as yup from "yup"

import { cleanBlockElement } from "./block/block-element-form"
import { BlockElementFormItem } from "./block/elements/block-element-types"
import { RelativeDateValue } from "./work-order-data-view-filter-configuration"

export interface WorkOrderFormValues {
  id?: uuid
  name: string
  template_name: string
  categories: uuid[]
  priority: IWorkOrderPriorityEnum | null
  status: IWorkOrderStatusEnum | null
  description: string | null
  asset_ids: uuid[]
  only_assigned: boolean | null
  assignees: uuid[]
  assigned_teams: uuid[]
  collaborators: uuid[]
  multiple_tasks: boolean | null
  uploads: IUploadDataFragment[]
  procedure_elements: BlockElementFormItem[]
  info_elements: BlockElementFormItem[]
  project_id: uuid | null
  service_request_id: number | null
  templated_used_id: uuid | null
  type?: IWorkOrderTypeEnum
  calendar: {
    dueDate: Date | null
    dueTime: string | null
    recurrenceInfo: RecurrenceInfo | null
    reminderConfig: RelativeDateValue | null
  }
  completed: boolean
  completed_by_id: uuid
  reportTime: {
    started_at: Date
    duration: {
      hours: number
      minutes: number
    }
    description: string
    user_id: uuid
  }[]
}

export type InitialFormValuesInput = Partial<
  IWorkOrderFragment & {
    asset_ids: uuid[]
    assignee_ids: uuid[]
    collaborator_ids: uuid[]
    templated_used_id?: uuid | null
    team_ids: uuid[]
    category_ids: uuid[]
    project_id: uuid | null
    multiple_tasks: boolean | null
    service_request_id: number | null
  }
> | null

export function getInitialWorkOrderFormValues(
  existingWorkOrder: InitialFormValuesInput,
  userTeamIds: uuid[],
  userId: uuid,
  options: { isTemplate?: boolean; cleanBlockElementIds?: boolean } = {
    isTemplate: false,
    cleanBlockElementIds: false,
  }
): WorkOrderFormValues {
  const assetIds = existingWorkOrder?.asset_ids?.length
    ? existingWorkOrder?.asset_ids
    : existingWorkOrder?.assets?.map(({ asset }) => asset.id) ?? []

  const getElements = (
    groups: IBlockGroupFragment[] | null | undefined,
    type: IBlockGroupTypeEnum
  ) => {
    return (
      (groups?.find((g) => g.group_type === type)?.elements ??
        []) as unknown[] as BlockElementFormItem[]
    ).map((e) => (options.cleanBlockElementIds ? cleanBlockElement(e, assetIds) : e))
  }

  const assignedTeams =
    (existingWorkOrder?.team_ids?.length
      ? existingWorkOrder?.team_ids
      : existingWorkOrder?.assigned_teams?.map((t) => t.team?.id)
    )?.filter((id) => id && userTeamIds?.includes(id)) ?? []

  return {
    id: existingWorkOrder?.id,
    name: existingWorkOrder?.name ?? "",
    template_name: existingWorkOrder?.template_name ?? "",
    status: existingWorkOrder?.status ?? IWorkOrderStatusEnum.Open,
    asset_ids: assetIds,
    categories: existingWorkOrder?.category_ids?.length
      ? existingWorkOrder?.category_ids
      : existingWorkOrder?.categories?.map((category) => category.work_order_category_id) ??
        [],
    only_assigned: existingWorkOrder?.only_assigned ?? false,
    calendar: {
      dueDate: parseDate(existingWorkOrder?.due_date),
      dueTime: existingWorkOrder?.due_time ?? null,
      reminderConfig: null,
      recurrenceInfo: (existingWorkOrder?.recurrence_info ?? null) as
        | WorkOrderFormValues["calendar"]["recurrenceInfo"]
        | null,
    },
    project_id: existingWorkOrder?.project_id ?? null,
    service_request_id: existingWorkOrder?.service_request_id ?? null,
    priority: existingWorkOrder?.priority ?? null,
    description: existingWorkOrder?.description ?? "",

    assignees: existingWorkOrder?.assignee_ids?.length
      ? existingWorkOrder?.assignee_ids
      : existingWorkOrder?.assignees?.map((u) => u.user.id) ?? [],
    assigned_teams: assignedTeams,
    collaborators: existingWorkOrder?.collaborator_ids?.length
      ? existingWorkOrder?.collaborator_ids
      : existingWorkOrder?.collaborators?.map((u) => u.user.id) ?? [],
    multiple_tasks: existingWorkOrder?.multiple_tasks ?? false,
    uploads: existingWorkOrder?.documents?.map((d) => d.document) ?? [],
    procedure_elements: getElements(
      existingWorkOrder?.block_groups,
      IBlockGroupTypeEnum.Procedure
    ),
    info_elements:
      getElements(existingWorkOrder?.block_groups, IBlockGroupTypeEnum.Info) ?? [],
    type: existingWorkOrder?.type,
    templated_used_id: existingWorkOrder?.templated_used_id ?? null,

    completed: existingWorkOrder?.status === IWorkOrderStatusEnum.Done,
    completed_by_id: userId,
    reportTime: [],
  }
}

export const workOrderFormSchema = yup.object().shape({
  name: yup.string().required(
    i18n.t("common:forms.is_required", {
      field: i18n.t("tasks:fields.task_name"),
    })
  ),
  template_name: yup.string(),
  description: yup.string(),
  category: yup.string().uuid().nullable(),
  categories: yup.array().of(yup.string().uuid()).nullable(),
  // priority: yup.mixed().oneOf([...Object.values(IWorkOrderPriorityEnum)]),
  assets_ids: yup.array().of(yup.string().uuid()),
  dueDate: yup.date().nullable(),
  dueTime: yup.string().nullable(),
  only_assigned: yup.boolean().required(),
  assigned_entities: yup.array().of(
    yup.object().shape({
      type: yup.string().oneOf(["user", "team"]),
      id: yup.string().uuid(),
    })
  ),
  recurrence: yup
    .object()
    .shape({
      recurrence_pattern: yup.mixed().oneOf([...Object.values(IRecurrencePatternEnum)]),
      interval: yup.number().nullable(),
      weekdays: yup
        .array()
        .of(yup.number())
        .when("recurrence_pattern", {
          is: "weekly",
          then: (weekdays) =>
            weekdays.min(1, i18n.t("calendar:messages.day_must_be_selected")),
        }),
      day: yup.number().nullable(),
    })
    .test(
      "recurrenceHasDueDate",
      i18n.t("calendar:messages.no_recurring_due_date"),
      (value, context) => !(value?.recurrence_pattern && !context.parent.dueDate)
    )
    .nullable(),
  info_elements: yup
    .array()
    .required()
    .of(
      yup.object().shape({
        config: yup.object().shape({
          required: yup.boolean(),
        }),
        response: yup.object().nullable(),
        uploads: yup.array().optional(),
      })
    )
    .test(
      "info_elements tests",
      i18n.t("tasks:messages.info_elements_required"),
      (value) =>
        !value?.some(
          (val) =>
            val.config.required && val.response === null && (val.uploads?.length ?? 0) === 0
        )
    ),
})

export function valuesToWorkOrderInsertInput(
  values: WorkOrderFormValues,
  options: { includeBlockElements: boolean }
): IWorkOrderInsertInput {
  const procedureBlockGroup: IBlockGroupInsertInput = {
    group_type: IBlockGroupTypeEnum.Procedure,
    elements: {
      data: values.procedure_elements.map((e, position) => ({
        position,
        config: e.config,
        element_type: e.element_type,
        config_upload_ids: e.config_uploads?.map((u) => u.id),
      })),
    },
  }

  const infoBlockGroup: IBlockGroupInsertInput = {
    group_type: IBlockGroupTypeEnum.Info,
    elements: {
      data: values.info_elements.map((e, position) => ({
        // Need the id here to handle uploads. Assumes that the id is a new uuid
        id: e.id,
        position,
        config: e.config,
        response: e.response,
        element_type: e.element_type,
        config_upload_ids: e.config_uploads?.map((u) => u.id),
        response_last_edited_at: e.response_last_edited_at,
        response_last_edited_by_id:
          // @ts-ignore
          e.response_last_edited_by?.id || e.response_last_edited_by_id || null,
      })),
    },
  }

  return {
    id: values.id,
    type: values.type,
    assets: {
      data: values.asset_ids.map((asset_id) => ({ asset_id })),
      on_conflict: {
        constraint: IAssetXWorkOrderConstraint.AssetXWorkOrderPkey,
        update_columns: [],
      },
    },
    template_name: values.template_name || null,
    description: values.description,
    due_date: toDateString(values.calendar.dueDate),
    due_time: values.calendar.dueTime,
    name: values.name ?? "",
    priority: values.priority || null,
    status: values.completed ? IWorkOrderStatusEnum.Done : values.status || null,
    categories: {
      data: values.categories.map((category_id) => ({
        work_order_category_id: category_id,
      })),
      on_conflict: {
        constraint: IWorkOrderXWorkOrderCategoryConstraint.WorkOrderXWorkOrderCategoryPkey,
        update_columns: [],
      },
    },
    project_id: values.project_id,
    service_request_id: values.service_request_id,
    only_assigned: values.only_assigned || false,
    templated_used_id: values.templated_used_id ?? null,
    recurrence_info: values.calendar.recurrenceInfo,
    assignees: {
      data: values.assignees.map((id) => ({
        user_id: id,
      })),
      on_conflict: { constraint: IWorkOrderXUserConstraint.WorkOrderXUserPkey },
    },
    assigned_teams: {
      data: values.assigned_teams.map((id) => ({ team_id: id })),
      on_conflict: { constraint: IWorkOrderXTeamConstraint.WorkOrderXTeamPkey },
    },
    collaborators: {
      data: values.collaborators.map((id) => ({ user_id: id })),
      on_conflict: {
        constraint: IWorkOrderXCollaboratorConstraint.WorkOrderXCollaboratorPkey,
        update_columns: [],
      },
    },
    documents: {
      data: values.uploads.map((d) => ({ upload_id: d.id })),
      on_conflict: {
        constraint: IWorkOrderXUploadConstraint.WorkOrderXUploadPkey,
        update_columns: [],
      },
    },
    block_groups: options.includeBlockElements
      ? {
          data: [
            ...(values.procedure_elements.length ? [procedureBlockGroup] : []),
            ...(values.info_elements.length ? [infoBlockGroup] : []),
          ],
          on_conflict: {
            constraint: IBlockGroupConstraint.GroupWorkOrderIdGroupTypeKey,
            update_columns: [],
          },
        }
      : undefined,
    reminder_config: values.calendar.reminderConfig,
    reports: values.reportTime.length
      ? {
          data: values.reportTime.map((report) => {
            const started_at = report.started_at.toISOString()
            const finished_at = date
              .add(report.started_at, {
                hours: report.duration.hours,
                minutes: report.duration.minutes,
              })
              .toISOString()

            return {
              started_at,
              finished_at,
              user_id: values.completed_by_id,
              description: report.description,
            }
          }),
        }
      : undefined,
    completed_by_id: values.completed ? values.completed_by_id : null,
    created_by_api: false,
  }
}

export function mergeMeterReadingAssetsAndAssetIds(values: WorkOrderFormValues) {
  const assetsFromMeterReadings = values.procedure_elements
    .filter((e) => e.element_type === IBlockElementTypeEnum.MeterReading)
    .map((e) => (e as BlockElementMeterReading).config.asset_id)
  return unique(values.asset_ids.concat(assetsFromMeterReadings))
}

export const useCreateTaskFormDefaultValues = (
  initialFormValuesInput: InitialFormValuesInput,
  options: {
    isTemplate?: boolean
    cleanBlockElementIds?: boolean
  } = {}
) => {
  const user = useUser()
  const client = useClient()

  // A user can archive teams or make them private.
  // Therefore it can happen that we load in a work order that
  // has teams assigned that the current user either cannot see (if
  // they are private and they are not a member of) or where
  // the team got archieved and there also cannot be selected anymore
  // To fix this we check against the available teams query and only load the relevant ones in
  const [teamsQueryRes] = useTeamsQuery({ requestPolicy: "cache-first" })

  const defaultValues = useMemo(() => {
    const allowedTeamIds = teamsQueryRes?.data?.team?.map((t) => t.id) ?? []
    const initialValues = { ...initialFormValuesInput }

    return getInitialWorkOrderFormValues(initialValues, allowedTeamIds, user.id, options)
  }, [client, user.id, options])

  return defaultValues
}

const handleDynamicProcedureElements = async (
  form: UseFormReturn<WorkOrderFormValues, any>,
  client: Client
) => {
  const values = form.getValues()
  const assetIds = values.asset_ids

  const queryRes = await client
    .query(
      gql`
        query GetMeters($assetIds: [uuid!]!) {
          meter(where: { asset_id: { _in: $assetIds } }, limit: 1) {
            id
            asset_id
          }
        }
      `,
      { assetIds }
    )
    .toPromise()

  const meter = queryRes.data.meter[0]
  if (!meter) return

  values.procedure_elements.forEach((element, index) => {
    if (
      element.element_type === IBlockElementTypeEnum.MeterReading &&
      element.config.asset_id === null
    ) {
      form.setValue(`procedure_elements.${index}.config.asset_id`, meter.asset_id)
      // form.resetField(`procedure_elements.${index}.config.asset_id`, {
      //   defaultValue: meter.asset_id,
      // })
      form.setValue(`procedure_elements.${index}.config.meter_id`, meter.id)
    }
  })
}

export const useHandleDynamicProcedureElements = (
  form: UseFormReturn<WorkOrderFormValues, any>
) => {
  const client = useClient()
  useEffect(() => {
    handleDynamicProcedureElements(form, client)
  }, [])
}
