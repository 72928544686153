import PageViewLayout from "@components/page/page-view-layout"
import { Button } from "@components/shared"
import DateRangePicker from "@components/shared/date-range-picker"
import HorizontalTabs from "@components/shared/horizontal-tabs"
import { DateRange } from "@utils/date"
import { GridApi } from "ag-grid-community"
import { exportMultipleSheetsAsExcel } from "ag-grid-enterprise"
import { endOfDay, startOfDay, subDays } from "date-fns"
import Highcharts from "highcharts"
import jsPDF from "jspdf"
import { useEffect, useState } from "react"
import { createSvg2png, initialize } from "svg2png-wasm"

import { AssetDowntimeDataGrid } from "./grids/asset-downtime"
import { AvailabilityDataGrid } from "./grids/availability"
import { ConsumablesDataGrid } from "./grids/consumables"
import { TasksDataGrid } from "./grids/tasks"

type Tab = "availability" | "tasks" | "materials" | "asset-downtime"

export const ReportsPage = () => {
  const [activeTab, setActiveTab] = useState<Tab>("availability")
  const [dateRange, setDateRange] = useState<DateRange>({
    start: subDays(startOfDay(new Date()), 30),
    end: endOfDay(new Date()),
  })

  const [tasksGridApi, setTasksGridApi] = useState<GridApi | null>(null)
  const [consumablesGridApi, setConsumablesGridApi] = useState<GridApi | null>(null)
  const [availabilityGridApi, setAvailabilityGridApi] = useState<GridApi | null>(null)
  const [assetDowntimeGridApi, setAssetDowntimeGridApi] = useState<GridApi | null>(null)

  useEffect(() => {
    ;(async () => {
      try {
        await initialize(fetch("https://unpkg.com/svg2png-wasm/svg2png_wasm_bg.wasm"))
      } catch (error) {}
    })()
  }, [])

  const onExportToExcel = async () => {
    const filename = `Elara-Report-${new Date().toISOString()}`

    if (
      !tasksGridApi ||
      !consumablesGridApi ||
      !availabilityGridApi ||
      !assetDowntimeGridApi
    ) {
      return
    }

    const sheets: string[] = []
    const svgStrings: string[] = []
    const pdf = new jsPDF({ unit: "px", format: "a4" })

    const fontBuffer = await fetch(
      "https://cdn.jsdelivr.net/npm/inter-font@3.19.0/ttf/Inter-Regular.ttf"
    ).then((res) => res.arrayBuffer())

    const svg2png = createSvg2png({
      fonts: [new Uint8Array(fontBuffer)],
    })

    for (const chart of Highcharts.charts) {
      if (!chart) continue
      const svgString = chart.getSVG()
      svgStrings.push(svgString)
    }

    const pngs = await Promise.all(svgStrings.map((svg) => svg2png(svg, { scale: 2 })))

    pngs.forEach((png, index) => {
      pdf.addImage(png, "PNG", 0, 0, 400, 250)
      if (index !== pngs.length - 1) {
        pdf.addPage()
      }
    })

    sheets.push(availabilityGridApi.getSheetDataForExcel({ sheetName: "Availability" })!)
    sheets.push(tasksGridApi.getSheetDataForExcel({ sheetName: "Tasks" })!)
    sheets.push(consumablesGridApi.getSheetDataForExcel({ sheetName: "Consumables" })!)
    sheets.push(assetDowntimeGridApi.getSheetDataForExcel({ sheetName: "Asset Downtime" })!)

    pdf.save(`${filename}.pdf`)
    exportMultipleSheetsAsExcel({ fileName: `${filename}.xlsx`, data: sheets })
  }

  return (
    <PageViewLayout title="Reports">
      <div className="flex items-center justify-between p-4">
        <DateRangePicker range={dateRange} onRangeChange={setDateRange} />

        <Button type="secondary" onClick={onExportToExcel}>
          Export to Excel
        </Button>
      </div>

      <HorizontalTabs.Root
        value={activeTab}
        className="h-[calc(100vh-175px)]"
        onValueChange={(tab) => setActiveTab(tab as Tab)}>
        <HorizontalTabs.List>
          <HorizontalTabs.Trigger value="availability">Availability</HorizontalTabs.Trigger>
          <HorizontalTabs.Trigger value="tasks">Tasks</HorizontalTabs.Trigger>
          <HorizontalTabs.Trigger value="materials">Materials</HorizontalTabs.Trigger>
          <HorizontalTabs.Trigger value="asset-downtime">
            Asset Downtime
          </HorizontalTabs.Trigger>
        </HorizontalTabs.List>
        <HorizontalTabs.Content
          forceMount
          value="availability"
          className="h-full"
          hidden={activeTab !== "availability"}>
          <AvailabilityDataGrid
            dateRange={dateRange}
            gridApi={availabilityGridApi}
            setGridApi={setAvailabilityGridApi}
          />
        </HorizontalTabs.Content>
        <HorizontalTabs.Content
          forceMount
          value="tasks"
          className="h-full"
          hidden={activeTab !== "tasks"}>
          <TasksDataGrid
            dateRange={dateRange}
            gridApi={tasksGridApi}
            setGridApi={setTasksGridApi}
          />
        </HorizontalTabs.Content>
        <HorizontalTabs.Content
          forceMount
          value="materials"
          className="h-full"
          hidden={activeTab !== "materials"}>
          <ConsumablesDataGrid
            dateRange={dateRange}
            gridApi={consumablesGridApi}
            setGridApi={setConsumablesGridApi}
          />
        </HorizontalTabs.Content>
        <HorizontalTabs.Content
          forceMount
          value="asset-downtime"
          className="h-full"
          hidden={activeTab !== "asset-downtime"}>
          <AssetDowntimeDataGrid
            dateRange={dateRange}
            gridApi={assetDowntimeGridApi}
            setGridApi={setAssetDowntimeGridApi}
          />
        </HorizontalTabs.Content>
      </HorizontalTabs.Root>
    </PageViewLayout>
  )
}
