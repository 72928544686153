import { DotsSixVertical } from "@phosphor-icons/react"
import classNames from "classnames"
import React from "react"

export const DragHandle = React.forwardRef<
  HTMLDivElement,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>((props, ref) => (
  <div
    ref={ref}
    {...props}
    className={classNames(
      props.className,
      "box-border inline-flex justify-center h-8 p-2 mr-1 text-gray-400 rounded "
    )}>
    <DotsSixVertical size={16} weight="bold" />
  </div>
))
