import { CaretRight } from "@phosphor-icons/react"
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@radix-ui/react-collapsible"

type Props = React.PropsWithChildren<{
  title: string
  action?: React.ReactNode
  defaultOpen?: boolean
}>

const CollapsibleSection = ({ title, action, children, defaultOpen }: Props) => {
  return (
    <Collapsible defaultOpen={defaultOpen}>
      <div className="flex items-center justify-between">
        <CollapsibleTrigger className="group flex items-center rounded py-1 pl-1 pr-2 hover:bg-gray-100">
          <CaretRight
            size={16}
            className="transition-transform group-radix-state-open:rotate-90"
          />
          <div className="ml-1 font-semibold text-gray-800">{title}</div>
        </CollapsibleTrigger>
        {action}
      </div>
      <CollapsibleContent className="mt-3">{children}</CollapsibleContent>
    </Collapsible>
  )
}

export default CollapsibleSection
