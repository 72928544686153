import { AssetWithParents } from "@components/asset/asset-with-parents"
import { UserSingleSelect } from "@components/shared"
import AlertBox from "@components/shared/alert-box"
import { DialogForm } from "@components/shared/dialog-form"
import { FormField } from "@components/shared/form/form-field"
import { DateTimeNative } from "@components/shared/native-date-time-pickers"
import { TextInput } from "@components/shared/text-input"
import toast from "@components/shared/toast"
import { useBreakpoint } from "@contexts/breakpoints"
import { useUser } from "@contexts/user-context"
import { IMeterTypeEnum, IPermissionScopeEnum, uuid } from "@elara/db"
import {
  useMeterDataViewByIdQuery,
  useUpsertMeterReadingMutation,
} from "@graphql/documents/asset-meter.generated"
import { IMeterReadingFragment } from "@graphql/documents/fragments.generated"
import { usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { PropsWithChildren, useCallback } from "react"
import * as yup from "yup"

export const NEW_METER_READING_EVENT = "new-meter-reading"

const ReadingSchema = yup.object().shape({
  value: yup
    .number()
    .typeError(i18n.t("meters:messages.value_must_be_number"))
    .required(
      i18n.t("common:forms.is_required", {
        field: i18n.t("meters:fields.reading", { count: 1 }),
      })
    ),
  measured_at: yup.date().required(
    i18n.t("common:forms.is_required", {
      field: i18n.t("meters:fields.measured_at"),
    })
  ),
})

type MeterReadingFormFields = {
  value: number | undefined
  measured_at: Date
  measured_by_id: uuid
}

export type AssetAddEditMeterProps = PropsWithChildren<{
  meterId: uuid
  meterReading?: IMeterReadingFragment
  isOpen?: boolean
  onOpenChange?: (isOpen: boolean) => void
}>

export const AssetAddEditMeterReadingsForm = ({
  children,
  meterId,
  meterReading,
  isOpen,
  onOpenChange,
}: AssetAddEditMeterProps) => {
  const user = useUser()

  const [meterRes] = useMeterDataViewByIdQuery({
    variables: { id: meterId },
    requestPolicy: "cache-first",
  })
  const meter = meterRes?.data?.meter_by_pk

  const dataEntryScope = usePermissionScope(IPermissionScopeEnum.AppDataEntry)
  const [, upsertMeterReading] = useUpsertMeterReadingMutation()

  const validateReading = useCallback(
    (value: number | undefined) => {
      if (meter?.meter_type === IMeterTypeEnum.Measurement) {
        if (value) {
          if (meter.range_start && value < meter.range_start) {
            return false
          }

          if (meter.range_end && value > meter.range_end) {
            return false
          }
        }
      }

      return true
    },
    [meter]
  )

  const onSubmit = async (values: MeterReadingFormFields) => {
    const res = await upsertMeterReading(
      {
        data: {
          meter_id: meterId,
          id: meterReading?.id,
          value: values.value,
          measured_at: values.measured_at.toISOString(),
          measured_by_id: values.measured_by_id,
        },
      },
      dataEntryScope.context()
    )

    if (!meterReading?.id) {
      window.dispatchEvent(
        new CustomEvent(NEW_METER_READING_EVENT, { detail: { meterId } })
      )
    }

    if (!res.data) {
      toast.error(
        i18n.t("common:messages.token_create_failure", {
          token: i18n.t("meters:types.measurement"),
        })
      )
      throw new Error(
        i18n.t("common:messages.token_create_failure", {
          token: i18n.t("meters:types.measurement"),
        })
      )
    } else {
      toast.success(
        i18n.t("common:messages.token_create_success", {
          token: i18n.t("meters:types.measurement"),
        })
      )
    }
  }

  const bp = useBreakpoint()
  const size = bp.sm ? "small" : "large"
  return (
    <DialogForm
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      trigger={children}
      title={
        meterReading
          ? i18n.t("common:edit_token", {
              token: i18n.t("meters:types.measurement"),
            })
          : i18n.t("common:add_token", {
              token: i18n.t("meters:types.measurement"),
            })
      }
      formikConfig={{
        onSubmit,
        validationSchema: ReadingSchema,
        initialValues: {
          value: meterReading?.value,
          measured_at: meterReading?.measured_at
            ? new Date(meterReading?.measured_at)
            : new Date(),
          measured_by_id: meterReading?.measured_by.id ?? user.id,
        },
      }}>
      {({ touched, values: { value } }) => (
        <div className="mt-4">
          {meter && (
            <div>
              <div className="mb-1 text-sm font-medium">
                {i18n.t("common:asset", { count: 1 })}
              </div>
              <AssetWithParents asset={meter?.asset} showAvatar />
            </div>
          )}
          <div className="mt-3">
            <div className="text-sm font-medium">
              {i18n.t("common:meter", { count: 1 })}
            </div>
            <div className="mt-1 text-sm">{meter?.name}</div>
          </div>
          <div className="mt-3 flex w-full flex-1 items-center self-stretch">
            <FormField
              label={
                meter?.is_deviation
                  ? i18n.t("meters:types.deviation")
                  : i18n.t("meters:types.measurement")
              }
              name="value"
              className="flex-1">
              <TextInput type="number" step="0.001" />
            </FormField>
            <span className="ml-2 text-gray-700 sm:text-sm">{meter?.unit}</span>
          </div>

          {!validateReading(value) && touched.value && (
            <AlertBox type="warning" className="mb-4">
              {i18n.t("meters:messages.outside_tolerance_range", {
                rangeStart: meter?.range_start ?? "-",
                rangeEnd: meter?.range_end ?? "-",
                unit: meter?.unit,
              })}
              {meter?.template_id && (
                <p>{i18n.t("meters:messages.task_created_on_save")}</p>
              )}
            </AlertBox>
          )}

          <FormField label={i18n.t("meters:fields.measured_at")} name="measured_at">
            {({ field, helpers }) => (
              <DateTimeNative {...field} onChange={(val) => helpers.setValue(val)} />
            )}
          </FormField>
          <FormField label={i18n.t("meters:fields.measured_by")} name="measured_by_id">
            {({ field, helpers }) => (
              <UserSingleSelect
                {...field}
                isClearable={false}
                placeholder={i18n.t("common:select_token", {
                  token: i18n.t("common:employee", { count: 1 }),
                })}
                onChange={helpers.setValue}
                size={size}
              />
            )}
          </FormField>
        </div>
      )}
    </DialogForm>
  )
}

export default AssetAddEditMeterReadingsForm
