import { Flex } from "@components/layout"
import { useConfirmModal } from "@components/shared"
import { IWorkOrderCategoryFragment } from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { categoryColors, colors } from "@styles"
import classNames from "classnames"
import { useState } from "react"

import { CategoryActionMenu, useCategoryEdit } from "./category-action-menu"
import CategoryEditCreateForm from "./category-edit-create-form"
import { transformColors } from "./color-picker"

type CategoryCardProps = {
  category: IWorkOrderCategoryFragment
  onEditMode?: (editing: boolean) => void
}

const CategoryCard = ({ category, onEditMode }: CategoryCardProps) => {
  const [currentColor, setCurrentColor] = useState<string>(category.color ?? colors.dark)
  const editCategory = useCategoryEdit(category.label, category.id)

  const deleteCategoryModal = useConfirmModal({
    title: i18n.t("common:delete_token", {
      token: i18n.t("tasks:fields.category", { count: 1 }),
    }),
    content: i18n.t("settings:categories.delete_category_confirmation"),
    okText: i18n.t("common:delete"),
    cancelText: i18n.t("common:cancel"),
    onOk: () => {
      editCategory.onDeleteCategory()
    },
  })

  return (
    <Flex
      row
      align="center"
      justify="space-between"
      className={classNames(
        "text-sm bg-gray-50 hover:text-black border border-gray-100 rounded cursor-pointer px-4 py-1 mb-2",
        {
          "!py-3": editCategory.isEditing,
        }
      )}>
      {editCategory.isEditing ? (
        <CategoryEditCreateForm
          colors={transformColors(categoryColors)}
          currentColor={currentColor}
          onChangeColor={(color) => {
            setCurrentColor(color)
          }}
          label={editCategory.editedLabel}
          inputRef={editCategory.inputRef}
          onChange={editCategory.onChange}
          stopEditOrCreate={() => {
            editCategory.stopEdit()
            setCurrentColor(category.color ?? colors.dark)
            onEditMode?.(false)
          }}
          onEditOrCreateCategory={() => {
            editCategory.onEditOrCreateCategory(currentColor)
            onEditMode?.(false)
          }}
        />
      ) : (
        <Flex row align="center">
          <div
            className="mr-2 h-3 w-3 rounded-full"
            style={{ backgroundColor: currentColor ?? "transparent" }}></div>
          <span className="mx-1 text-sm">{category.label}</span>
        </Flex>
      )}
      {deleteCategoryModal.component}
      <CategoryActionMenu
        onEdit={() => {
          editCategory.startEdit()
          onEditMode?.(true)
        }}
        onDelete={deleteCategoryModal.show}
      />
    </Flex>
  )
}

export default CategoryCard
