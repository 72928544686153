import toast from "@components/shared/toast"
import { useFileUpload } from "@contexts/file-upload-context"
import React from "react"
import { useCallback, useRef } from "react"

export const useCustomRequest = () => {
  const { uploadFile } = useFileUpload()
  const customRequest = React.useCallback(
    async ({ file }: any) => {
      try {
        await uploadFile(file as File)
      } catch {
        toast.error({
          title: `Die Datei konnte nicht hochgeladen werden.`,
          body: `${(file as File)?.name}`,
        })
      }
    },
    [uploadFile]
  )
  return customRequest
}

export const useUploadAction = () => {
  const customRequest = useCustomRequest()
  const ref = useRef<HTMLInputElement>(null)
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files
      if (files) {
        Array.from(files).forEach((file) => {
          customRequest({ file })
        })
      }
    },
    [customRequest]
  )

  const inputProps = {
    type: "file",
    ref,
    style: { display: "none" },
    onChange,
  }

  const triggerUpload = useCallback(() => {
    if (ref.current) {
      ref.current.click()
    }
  }, [])

  const buttonProps = {
    onClick: triggerUpload,
  }

  return { buttonProps, inputProps, triggerUpload }
}
