import CircularProgress from "@components/shared/circular-progress"
import { Tooltip } from "@components/shared/tooltip"
import { IBlockGroupTypeEnum, ProgressInfo } from "@elara/db"
import { IWorkOrderDataViewFragment } from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { Warning } from "@phosphor-icons/react"
import Icons from "@resources/icons"
import classNames from "classnames"

import { WorkOrderNumber } from "./work-order-number"
import { WorkOrderPriorityBadgeWithDropdown } from "./work-order-priority"
import { WorkOrderStatusTagWithDropdown } from "./work-order-status"
import { WorkOrderSubtaskTag } from "./work-order-subtask"

export const WorkOrderCompact = (props: {
  workOrder: IWorkOrderDataViewFragment
  className?: string
}) => {
  const progressInfo = (props.workOrder.block_groups?.find(
    (g) => g.group_type === IBlockGroupTypeEnum.Procedure
  )?.progress_info ?? null) as ProgressInfo | null
  return (
    <div className={classNames("flex min-w-0 items-center gap-x-2", props.className)}>
      <WorkOrderPriorityBadgeWithDropdown
        workOrder={props.workOrder}
        priority={props.workOrder.priority}
        hasLabel={false}
      />

      <WorkOrderStatusTagWithDropdown
        short
        status={props.workOrder.status}
        workOrder={props.workOrder}
      />
      <WorkOrderNumber workOrderNumber={props.workOrder.work_order_number} />
      <div className="flex min-w-0 items-center space-x-2">
        <span className="min-w-0 truncate font-medium">{props.workOrder.name}</span>
        {progressInfo && progressInfo.inspection.flag && (
          <Tooltip content={i18n.t("tasks:messages.inspection_flag_warning")}>
            <Warning size={20} weight="duotone" className="text-yellow-400 " />
          </Tooltip>
        )}
        {progressInfo &&
          (progressInfo.inspection.fail || progressInfo.tolerance_check_failed) && (
            <Tooltip content={i18n.t("tasks:messages.inspection_flag_fail")}>
              <Warning size={20} weight="duotone" className="text-red-400 " />
            </Tooltip>
          )}
        {progressInfo && progressInfo.total > 0 && (
          <Tooltip
            content={i18n.t("tasks:checklist.status", {
              count: progressInfo.total_with_response,
              total: progressInfo.total,
            })}>
            <div className="inline-flex items-center rounded border py-1 pl-1 pr-1.5 text-xs">
              <CircularProgress
                size={16}
                progress={progressInfo.percent_with_response / 100}
              />
              <span className="ml-1 text-gray-500">
                {progressInfo.total_with_response}/{progressInfo.total}
              </span>
            </div>
          </Tooltip>
        )}
        <WorkOrderSubtaskTag subtasks={props.workOrder.subtasks} />
        {props.workOrder.parent_task && (
          <span className="-ml-1 inline-flex min-w-0 shrink-[9999] items-center truncate text-gray-600">
            <Icons.RightNext className="mr-1 inline-block shrink-0" /> #
            <span className="min-w-0 truncate">
              {props.workOrder.parent_task?.work_order_number}{" "}
              {props.workOrder.parent_task.name}
            </span>
          </span>
        )}
      </div>
    </div>
  )
}
