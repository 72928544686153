import { useAllNotificationsQuery } from "@graphql/documents/notification.generated"
import { Bell, HardDrives, List, Plus, Shapes } from "@phosphor-icons/react"
import classNames from "classnames"
import { useMemo } from "react"
import { Link, useLocation } from "react-router-dom"

import { useTriggerRecordTask } from "./header/record-task-button"

const TabIcon = (props: React.PropsWithChildren<{ className?: string; path: string }>) => {
  const { pathname } = useLocation()

  return (
    <Link
      to={props.path}
      className={classNames(
        "flex items-center justify-center w-14 h-14",
        { "text-blue-700": pathname.includes(props.path) },
        props.className
      )}>
      {props.children}
    </Link>
  )
}

const PageFooter = () => {
  const [notificationRes] = useAllNotificationsQuery({ requestPolicy: "cache-first" })
  const notifications = notificationRes.data?.notification ?? []

  const unreadNotificationCount = useMemo(
    () =>
      notifications?.filter((notification) => notification.read_at === null).length ?? 0,
    [notifications]
  )
  const recordTask = useTriggerRecordTask()

  return (
    <div
      className="relative box-content flex h-14 justify-evenly border-t print:hidden sm:hidden"
      style={{ paddingBottom: "env(safe-area-inset-bottom)" }}>
      <TabIcon path="/menu">
        <List size={20} />
      </TabIcon>

      <TabIcon path="/task/assigned/me">
        <HardDrives size={20} />
      </TabIcon>

      <TabIcon path="/object">
        <Shapes size={20} />
      </TabIcon>

      <TabIcon path="/notifications">
        <div className="relative">
          <Bell size={20} />
          {unreadNotificationCount > 0 ? (
            <div className="absolute -right-3.5 -top-2.5 rounded bg-blue-700 px-1.5 py-0.5 text-[10px] font-medium text-white">
              {unreadNotificationCount}
            </div>
          ) : null}
        </div>
      </TabIcon>

      <div onClick={recordTask} className="flex h-14 w-14 items-center justify-center">
        <button
          className="h-5 w-5 rounded bg-gray-700 text-white transition-colors"
          type="button">
          <Plus size={20} className="mx-auto" />
        </button>
      </div>
    </div>
  )
}

export default PageFooter
