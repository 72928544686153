// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import { AssetStateVariantFragmentDoc, AssetStateLogFragmentDoc, AssetCurrentStateFragmentDoc } from './fragments.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IAssetStateVariantsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IAssetStateVariantsQuery = { asset_state_variant: Array<{ __typename: 'asset_state_variant', id: uuid, type: Types.IAssetStateVariantTypeEnum, subtype: string, planned: boolean, location_id: uuid | null }> };

export type IAssetReliabilityHistoryQueryVariables = Types.Exact<{
  assetId: Types.Scalars['uuid']['input'];
  from: Types.Scalars['timestamptz']['input'];
  to: Types.Scalars['timestamptz']['input'];
}>;


export type IAssetReliabilityHistoryQuery = { asset_state_log: Array<{ __typename: 'asset_state_log', downtime_in_operating_hours: number | null, id: uuid, note: string | null, started_at: string, ended_at: string | null, asset_state_variant_id: uuid, created_by_id: uuid, created_at: string, work_order_id: uuid | null, asset_state_variant: { __typename: 'asset_state_variant', id: uuid, subtype: string, type: Types.IAssetStateVariantTypeEnum, planned: boolean }, asset: { __typename: 'asset', id: uuid, name: string, public_id: string } }>, asset_by_pk: { __typename: 'asset', id: uuid, availability: {} | null, current_state: { __typename: 'asset_current_state', id: uuid | null, asset_id: uuid | null, asset_state_variant_id: uuid | null, note: string | null, started_at: string | null, ended_at: string | null, created_at: string | null, created_by_id: uuid | null, work_order_id: uuid | null, asset_state_variant: { __typename: 'asset_state_variant', id: uuid, type: Types.IAssetStateVariantTypeEnum, subtype: string, planned: boolean, location_id: uuid | null } | null } | null } | null };

export type INewAssetStateLogMutationVariables = Types.Exact<{
  data: Types.IAssetStateLogInsertInput;
}>;


export type INewAssetStateLogMutation = { insert_asset_state_log_one: { __typename: 'asset_state_log', id: uuid, asset: { __typename: 'asset', id: uuid, current_state: { __typename: 'asset_current_state', id: uuid | null, asset_id: uuid | null, asset_state_variant_id: uuid | null, note: string | null, started_at: string | null, ended_at: string | null, created_at: string | null, created_by_id: uuid | null, work_order_id: uuid | null, asset_state_variant: { __typename: 'asset_state_variant', id: uuid, type: Types.IAssetStateVariantTypeEnum, subtype: string, planned: boolean, location_id: uuid | null } | null } | null } } | null };

export type IAddAssetStateVariantMutationVariables = Types.Exact<{
  data: Types.IAssetStateVariantInsertInput;
}>;


export type IAddAssetStateVariantMutation = { insert_asset_state_variant_one: { __typename: 'asset_state_variant', id: uuid, type: Types.IAssetStateVariantTypeEnum, subtype: string, planned: boolean, location_id: uuid | null } | null };

export type IUpdateAssetStateVariantMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  data: Types.IAssetStateVariantSetInput;
}>;


export type IUpdateAssetStateVariantMutation = { update_asset_state_variant_by_pk: { __typename: 'asset_state_variant', id: uuid, type: Types.IAssetStateVariantTypeEnum, subtype: string, planned: boolean, location_id: uuid | null } | null };

export type IDeleteAssetStateVariantMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type IDeleteAssetStateVariantMutation = { delete_asset_state_variant_by_pk: { __typename: 'asset_state_variant', id: uuid } | null };

export type IUpsertAssetStateLogMutationVariables = Types.Exact<{
  data: Types.IAssetStateLogInsertInput;
}>;


export type IUpsertAssetStateLogMutation = { insert_asset_state_log_one: { __typename: 'asset_state_log', id: uuid, note: string | null, started_at: string, ended_at: string | null, asset_state_variant_id: uuid, created_by_id: uuid, created_at: string, work_order_id: uuid | null, asset: { __typename: 'asset', id: uuid, name: string, public_id: string, current_state: { __typename: 'asset_current_state', id: uuid | null, asset_id: uuid | null, asset_state_variant_id: uuid | null, note: string | null, started_at: string | null, ended_at: string | null, created_at: string | null, created_by_id: uuid | null, work_order_id: uuid | null, asset_state_variant: { __typename: 'asset_state_variant', id: uuid, type: Types.IAssetStateVariantTypeEnum, subtype: string, planned: boolean, location_id: uuid | null } | null } | null }, asset_state_variant: { __typename: 'asset_state_variant', id: uuid, subtype: string, type: Types.IAssetStateVariantTypeEnum, planned: boolean } } | null };

export type IDeleteAssetStateMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type IDeleteAssetStateMutation = { delete_asset_state_log_by_pk: { __typename: 'asset_state_log', id: uuid } | null };


export const AssetStateVariantsDocument = gql`
    query AssetStateVariants {
  asset_state_variant {
    ...AssetStateVariant
  }
}
    ${AssetStateVariantFragmentDoc}`;

export function useAssetStateVariantsQuery(options?: Omit<Urql.UseQueryArgs<IAssetStateVariantsQueryVariables>, 'query'>) {
  return Urql.useQuery<IAssetStateVariantsQuery, IAssetStateVariantsQueryVariables>({ query: AssetStateVariantsDocument, ...options });
};
export const AssetReliabilityHistoryDocument = gql`
    query AssetReliabilityHistory($assetId: uuid!, $from: timestamptz!, $to: timestamptz!) {
  asset_state_log(
    where: {asset_id: {_eq: $assetId}, started_at: {_gte: $from}}
    order_by: {started_at: desc}
  ) {
    ...AssetStateLog
    downtime_in_operating_hours
  }
  asset_by_pk(id: $assetId) {
    id
    availability(args: {start: $from, end: $to})
    current_state {
      ...AssetCurrentState
    }
  }
}
    ${AssetStateLogFragmentDoc}
${AssetCurrentStateFragmentDoc}`;

export function useAssetReliabilityHistoryQuery(options: Omit<Urql.UseQueryArgs<IAssetReliabilityHistoryQueryVariables>, 'query'>) {
  return Urql.useQuery<IAssetReliabilityHistoryQuery, IAssetReliabilityHistoryQueryVariables>({ query: AssetReliabilityHistoryDocument, ...options });
};
export const NewAssetStateLogDocument = gql`
    mutation NewAssetStateLog($data: asset_state_log_insert_input!) {
  insert_asset_state_log_one(object: $data) {
    id
    asset {
      id
      current_state {
        ...AssetCurrentState
      }
    }
  }
}
    ${AssetCurrentStateFragmentDoc}`;

export function useNewAssetStateLogMutation() {
  return Urql.useMutation<INewAssetStateLogMutation, INewAssetStateLogMutationVariables>(NewAssetStateLogDocument);
};
export const AddAssetStateVariantDocument = gql`
    mutation AddAssetStateVariant($data: asset_state_variant_insert_input!) {
  insert_asset_state_variant_one(object: $data) {
    ...AssetStateVariant
  }
}
    ${AssetStateVariantFragmentDoc}`;

export function useAddAssetStateVariantMutation() {
  return Urql.useMutation<IAddAssetStateVariantMutation, IAddAssetStateVariantMutationVariables>(AddAssetStateVariantDocument);
};
export const UpdateAssetStateVariantDocument = gql`
    mutation UpdateAssetStateVariant($id: uuid!, $data: asset_state_variant_set_input!) {
  update_asset_state_variant_by_pk(pk_columns: {id: $id}, _set: $data) {
    ...AssetStateVariant
  }
}
    ${AssetStateVariantFragmentDoc}`;

export function useUpdateAssetStateVariantMutation() {
  return Urql.useMutation<IUpdateAssetStateVariantMutation, IUpdateAssetStateVariantMutationVariables>(UpdateAssetStateVariantDocument);
};
export const DeleteAssetStateVariantDocument = gql`
    mutation DeleteAssetStateVariant($id: uuid!) {
  delete_asset_state_variant_by_pk(id: $id) {
    id
  }
}
    `;

export function useDeleteAssetStateVariantMutation() {
  return Urql.useMutation<IDeleteAssetStateVariantMutation, IDeleteAssetStateVariantMutationVariables>(DeleteAssetStateVariantDocument);
};
export const UpsertAssetStateLogDocument = gql`
    mutation UpsertAssetStateLog($data: asset_state_log_insert_input!) {
  insert_asset_state_log_one(
    object: $data
    on_conflict: {constraint: asset_state_log_pkey, update_columns: [asset_state_variant_id, note, started_at, ended_at, work_order_id]}
  ) {
    ...AssetStateLog
    asset {
      id
      current_state {
        ...AssetCurrentState
      }
    }
  }
}
    ${AssetStateLogFragmentDoc}
${AssetCurrentStateFragmentDoc}`;

export function useUpsertAssetStateLogMutation() {
  return Urql.useMutation<IUpsertAssetStateLogMutation, IUpsertAssetStateLogMutationVariables>(UpsertAssetStateLogDocument);
};
export const DeleteAssetStateDocument = gql`
    mutation DeleteAssetState($id: uuid!) {
  delete_asset_state_log_by_pk(id: $id) {
    id
  }
}
    `;

export function useDeleteAssetStateMutation() {
  return Urql.useMutation<IDeleteAssetStateMutation, IDeleteAssetStateMutationVariables>(DeleteAssetStateDocument);
};