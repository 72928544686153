import { IUserTagFragment } from "@graphql/documents/fragments.generated"
import classNames from "classnames"

const getInitials = (firstName?: string, lastName?: string) => {
  const firstInitial = firstName?.charAt(0).toUpperCase() ?? ""
  const lastInitial = lastName?.charAt(0).toUpperCase() ?? ""

  return firstInitial + lastInitial
}

export const UserAvatar = (props: {
  user: Partial<IUserTagFragment>
  className?: string
}) => {
  if (props.user.avatar?.thumbnail_url) {
    return (
      <img
        src={props.user.avatar?.thumbnail_url}
        className={classNames(
          "w-[1em] h-[1em] rounded-full border border-solid border-gray-100 inline-block",
          props.className
        )}
      />
    )
  }
  return (
    <div
      className={classNames(
        "w-[1em] h-[1em] rounded-full border inline-flex justify-center items-center box-border border-gray-100 bg-gray-400",
        props.className
      )}>
      <span
        style={{ fontSize: "0.5em", textShadow: "rgb(0 0 0 / 40%) 0px 1px 2px" }}
        className="tracking-wide text-gray-50">
        {getInitials(props.user.first_name, props.user.last_name)}
      </span>
    </div>
  )
}
