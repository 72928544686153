import SearchContainer from "@components/page/search/search-container"
import Portal from "@components/shared/portal"
import { uuid } from "@elara/db"
import i18n from "@i18n"

export const WorkOrderSelectDialog = (props: {
  onSelect?: (id: uuid) => void
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
}) => {
  if (!props.isOpen) return null

  return (
    <Portal>
      <SearchContainer
        changeOpen={props.onOpenChange}
        placeholder={i18n.t("common:search_token", {
          token: i18n.t("common:task", { count: 1 }),
        })}
        filters={{ asset: false, work_order: true }}
        onSelect={(item) => props.onSelect?.(item.id)}
      />
    </Portal>
  )
}

export default WorkOrderSelectDialog
