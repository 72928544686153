// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import { TaskConsumableUsedFragmentDoc } from '../../../graphql/documents/fragments.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IChangeMaterialAdjustmentMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  newAdjustment: Types.Scalars['numeric']['input'];
}>;


export type IChangeMaterialAdjustmentMutation = { update_consumable_log_by_pk: { __typename: 'consumable_log', id: uuid, adjustment: number } | null };

export type IDeleteMaterialLogMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type IDeleteMaterialLogMutation = { delete_consumable_log_by_pk: { __typename: 'consumable_log', id: uuid, task: { __typename: 'work_order', id: uuid, consumables_used: Array<{ __typename: 'consumable_log', id: uuid, asset_id: uuid | null, consumable_id: uuid, adjustment_at: string, adjustment: number, place_id: uuid | null, cost_per_unit: number, consumable: { __typename: 'consumable', id: uuid, cost: number, location: string | null, name: string, public_id: string, quantity: number | null, unit: string, storage_locations: Array<{ __typename: 'consumable_storage_location', place_id: uuid, area: string | null, quantity: number | null, place: { __typename: 'place', id: uuid, name: string, assets_aggregate: { __typename: 'asset_aggregate', aggregate: { __typename: 'asset_aggregate_fields', count: number } | null } } }>, avatar: { __typename: 'upload', id: uuid, file_name: string, file_size: number, mime_type: string, created_at: string, thumbnail_url: string | null, url: string | null } | null } | null }> } | null } | null };


export const ChangeMaterialAdjustmentDocument = gql`
    mutation ChangeMaterialAdjustment($id: uuid!, $newAdjustment: numeric!) {
  update_consumable_log_by_pk(
    pk_columns: {id: $id}
    _set: {adjustment: $newAdjustment}
  ) {
    id
    adjustment
  }
}
    `;

export function useChangeMaterialAdjustmentMutation() {
  return Urql.useMutation<IChangeMaterialAdjustmentMutation, IChangeMaterialAdjustmentMutationVariables>(ChangeMaterialAdjustmentDocument);
};
export const DeleteMaterialLogDocument = gql`
    mutation DeleteMaterialLog($id: uuid!) {
  delete_consumable_log_by_pk(id: $id) {
    id
    task {
      id
      consumables_used {
        ...TaskConsumableUsed
      }
    }
  }
}
    ${TaskConsumableUsedFragmentDoc}`;

export function useDeleteMaterialLogMutation() {
  return Urql.useMutation<IDeleteMaterialLogMutation, IDeleteMaterialLogMutationVariables>(DeleteMaterialLogDocument);
};