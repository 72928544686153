import { useCallback, useLayoutEffect, useRef, useState } from "react"
import { useDebouncedCallback } from "use-debounce"

export const useDebouncedPersistingText = (
  value: string,
  persist: (value: string) => Promise<void> | void,
  debounceIntervalInMs: number
): [string, (value: string) => void] => {
  const [state, setState] = useState(value)
  const sentValue = useRef<string | null>(null)

  useLayoutEffect(() => {
    if (sentValue.current !== value) {
      setState(value ?? "")
    }
    sentValue.current = null
  }, [sentValue, value])

  const debouncedPersistCb = useCallback(
    async (response: string) => {
      sentValue.current = response
      try {
        await persist(response)
      } catch {
        sentValue.current = null
        setState(response)
      }
    },
    [persist, sentValue]
  )
  const persistResponse = useDebouncedCallback(debouncedPersistCb, debounceIntervalInMs)

  const onChange = useCallback(
    (value: string) => {
      setState(value)
      persistResponse(value)
    },
    [persistResponse]
  )
  return [state, onChange]
}
