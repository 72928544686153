import { Flex } from "@components/layout"
import { uuid } from "@elara/db"
import { IAssetGroupFragment } from "@graphql/documents/fragments.generated"
import classNames from "classnames"
import { t } from "i18next"

import { AssetGroupsActionMenu } from "./asset-groups-action-menu"
import AssetGroupsEditCreateForm from "./asset-groups-edit-create-form"

type AssetGroupsCardProps = {
  assetGroup: IAssetGroupFragment & {
    assets_aggregate?: { aggregate: { count: number } | null }
  }
  onEdit: (id: uuid) => void
  onDelete: (id: uuid) => void
  onCancel: (id: uuid) => void
  isEditing: boolean
}

const AssetGroupsCard = (props: AssetGroupsCardProps) => {
  return (
    <div
      className={classNames(
        "flex items-center justify-between text-sm bg-gray-50 hover:text-black border border-gray-100 rounded cursor-pointer px-4 py-2 mb-2",
        { "!py-4": props.isEditing }
      )}>
      {props.isEditing ? (
        <AssetGroupsEditCreateForm
          id={props.assetGroup.id}
          label={props.assetGroup.name}
          onCancel={() => props.onCancel(props.assetGroup.id)}
          afterSubmitSuccess={() => props.onCancel(props.assetGroup.id)}
        />
      ) : (
        <>
          <Flex row align="center">
            <span className="mx-1 text-sm">{props.assetGroup.name}</span>
            <span className="ml-2 text-gray-500">
              {props.assetGroup.assets_aggregate?.aggregate?.count}{" "}
              {t("asset", {
                count: props.assetGroup.assets_aggregate?.aggregate?.count ?? 0,
              })}
            </span>
          </Flex>
          <AssetGroupsActionMenu
            onEdit={() => props.onEdit(props.assetGroup.id)}
            onDelete={() => props.onDelete(props.assetGroup.id)}
          />
        </>
      )}
    </div>
  )
}

export default AssetGroupsCard
