import LoadingSpinner from "@components/shared/loading-spinner"
import ScrollArea from "@components/shared/scroll-area"
import { usePage } from "@contexts/page-context"
import {
  useTaskDataViewByPkQuery,
  useWorkOrderDetailsQuery,
} from "@graphql/documents/work-order.generated"
import { useEffect, useRef } from "react"
import { Outlet, useParams } from "react-router-dom"

import { Header } from "./components/header"

export default function TaskDetailPage(props: {
  template?: boolean
  maintenance?: boolean
  workOrderId?: string
}) {
  const params = useParams()
  const workOrderId = props.workOrderId ?? params.workOrderId

  const [dataViewQuery] = useTaskDataViewByPkQuery({
    variables: { id: workOrderId! },
    requestPolicy: "cache-only",
  })
  const [query] = useWorkOrderDetailsQuery({ variables: { id: workOrderId! } })
  const scrollRef = useRef<HTMLDivElement>(null)
  const dataViewTask = dataViewQuery.data?.work_order_by_pk
  const task = query.data?.work_order_by_pk

  usePage({
    id: "task/detail/print",
    title: !task ? "Elara" : `#${task?.work_order_number} ${task?.name}`,
    paused: props.template,
  })

  useEffect(() => {
    const handler = (e: Event) => {
      if (e instanceof CustomEvent) {
        scrollRef.current?.scrollTo(e.detail)
      }
    }
    window.addEventListener("task-detail-page-scroll-to", handler)
    return () => {
      window.removeEventListener("task-detail-page-scroll-to", handler)
    }
  }, [])

  if (dataViewQuery.fetching || (!dataViewTask && query.fetching)) {
    return <LoadingSpinner />
  }

  if (!dataViewTask && !task) {
    return <div>Task not found</div>
  }

  return (
    <div className="flex min-h-0 flex-1 flex-col ">
      <ScrollArea vertical viewportAsChild ref={scrollRef}>
        <Header
          template={props.template}
          task={task ?? null}
          taskDataView={dataViewTask ?? task!}
          key={dataViewTask?.id ?? task?.id!}
        />

        <Outlet context={{ task, dataViewTask }} />
      </ScrollArea>
    </div>
  )
}
