import { DateRange } from "@utils/date"
import { stripHtml } from "@utils/string"
import { GridReadyEvent } from "ag-grid-community"
import { ColDef, GridApi } from "ag-grid-enterprise"
import { useMemo, useState } from "react"

import { DataGrid } from "../components/data-grid"
import { elaraLogo } from "../logos/elara"
import {
  IConsumablesDataGridQuery,
  useConsumablesDataGridQuery,
} from "../queries/consumables.generated"

type TData = IConsumablesDataGridQuery["consumable_log"]
type TRow = TData[number]

type Props = {
  dateRange: DateRange
  gridApi: GridApi | null
  setGridApi: (api: GridApi) => void
}

export const ConsumablesDataGrid = ({ dateRange, setGridApi }: Props) => {
  const [queryRes] = useConsumablesDataGridQuery({
    variables: {
      startDate: dateRange.start.toISOString(),
      endDate: (dateRange.end ?? new Date()).toISOString(),
    },
  })
  const data = queryRes.data?.consumable_log ?? []

  const [colDefs, _setColDefs] = useState<ColDef<TRow>[]>([
    {
      field: "id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "consumable.name",
      headerName: "Material Name",
      initialWidth: 300,
    },
    {
      field: "consumable.description",
      headerName: "Material Description",
      valueFormatter: (params) => stripHtml(params.value as string),
    },
    {
      field: "adjustment",
      headerName: "Adjustment",
    },
    {
      field: "cost_per_unit",
      headerName: "Cost Per Unit",
    },
    {
      field: "asset.name",
      headerName: "Object",
    },
    {
      field: "place.name",
      headerName: "Storage Location",
    },
    {
      field: "task.name",
      headerName: "Task Name",
    },
    {
      field: "task.description",
      headerName: "Task Description",
      valueFormatter: (params) => stripHtml(params.value as string),
    },
  ])

  const defaultColDef = useMemo<ColDef<TRow>>(() => {
    return {
      filter: true,
      sortable: true,
      resizable: true,
    }
  }, [])

  const onGridReady = (params: GridReadyEvent) => setGridApi(params.api)

  return (
    <DataGrid<TRow>
      rowData={data}
      columnDefs={colDefs}
      rowSelection="multiple"
      defaultColDef={defaultColDef}
      onGridReady={onGridReady}
      rowGroupPanelShow="onlyWhenGrouping"
      statusBar={{
        statusPanels: [{ statusPanel: "agTotalRowCountComponent", align: "right" }],
      }}
      excelStyles={[
        {
          id: "header",
          alignment: { vertical: "Center" },
          interior: {
            color: "#2A55B0",
            pattern: "Solid",
          },
          font: {
            fontName: "Calibri",
            color: "#FFFFFF",
            bold: true,
          },
        },
        {
          id: "cell",
          font: { fontName: "Calibri" },
          alignment: { vertical: "Center" },
        },
      ]}
      defaultExcelExportParams={{
        rowHeight: (params) => (params.rowIndex === 1 ? 80 : 30),
        appendContent: [
          {
            cells: [
              {
                styleId: "header",
                mergeAcross: 1,
                data: {
                  type: "String",
                  value: `Report generated at ${new Date().toLocaleString()}`,
                },
              },
            ],
          },
        ],
        prependContent: [
          {
            cells: [
              {
                mergeAcross: 1,
                data: {
                  type: "String",
                  value: elaraLogo,
                },
              },
            ],
          },
        ],
        addImageToCell: (rowIndex, _col, value) => {
          if (rowIndex !== 1) return

          return {
            image: {
              id: "logo",
              width: 190,
              height: 45,
              base64: value,
              imageType: "png",
              position: { offsetX: 150, offsetY: 20 },
            },
          }
        },
      }}
    />
  )
}
