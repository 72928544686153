import { AssetWithParents } from "@components/asset/asset-with-parents"
import { Flex, VStack } from "@components/layout"
import { AssetMultiSelectDialogForm } from "@components/select/asset-multi-select-dialog"
import { Button, Icons, UserMultiSelect, UserSingleSelect } from "@components/shared"
import { AlertDialogContent, AlertDialogRoot } from "@components/shared/alert-dialog"
import {
  DialogContent,
  DialogContentFooter,
  DialogContentHeader,
  DialogRoot,
  DialogTrigger,
} from "@components/shared/dialog"
import { ErrorBoundary } from "@components/shared/error-boundary"
import { CheckboxInput } from "@components/shared/form/checkbox-input"
import { FormFieldController } from "@components/shared/form/form-field-controller"
import { RichTextFormController } from "@components/shared/form/rich-text-form-controller"
import Image from "@components/shared/image"
import { popoverCompactTriggerClasses } from "@components/shared/multi-select"
import { DateTimeNative } from "@components/shared/native-date-time-pickers"
import { SelectPopover, SelectTrigger } from "@components/shared/single-select"
import { IconSpacer } from "@components/shared/spacers"
import { TeamMultiSelect } from "@components/shared/team-select"
import { TextInput } from "@components/shared/text-input"
import { TextInputWithUnit } from "@components/shared/text-input-with-unit"
import toast from "@components/shared/toast"
import { Tooltip } from "@components/shared/tooltip"
import TouchTargetSize from "@components/shared/touch-target-size"
import Uploads from "@components/shared/uploads"
import { useUser } from "@contexts/user-context"
import {
  IPermissionScopeEnum,
  IWorkOrderInsertInput,
  IWorkOrderStatusEnum,
} from "@elara/db"
import { assetStateSchema } from "@elara/utils"
import { useAssetTagByIdQuery } from "@graphql/documents/asset.generated"
import { useInsertMultipleWorkOrdersMutation } from "@graphql/documents/work-order.generated"
import { yupResolver } from "@hookform/resolvers/yup"
import { useDisclosure, usePermissionScope } from "@hooks"
import { useControllableState } from "@hooks/use-controllable-state"
import i18n from "@i18n"
import {
  ClockClockwise,
  NotePencil,
  Plus,
  Question,
  Shapes,
  Swatches,
  TrashSimple,
  X,
} from "@phosphor-icons/react"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import { cn, isDateOverdue, objectKeysToIndexString } from "@utils"
import classNames from "classnames"
import React, { PropsWithChildren, useImperativeHandle, useRef, useState } from "react"
import {
  Control,
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form"
import { Trans } from "react-i18next"
import { Link } from "react-router-dom"
import { ValidationError } from "yup"

import { BlockElement } from "./block/block-element"
import { BlockGroupElementsFormItem, validateBlockElements } from "./block/block-group-form"
import { BlockElementFragment } from "./block/elements/block-element-types"
import {
  InitialFormValuesInput,
  mergeMeterReadingAssetsAndAssetIds,
  useCreateTaskFormDefaultValues,
  useHandleDynamicProcedureElements,
  valuesToWorkOrderInsertInput,
  workOrderFormSchema,
  WorkOrderFormValues,
} from "./create-task-form.hooks"
import { WorkOrderCategoryMultiSelect } from "./work-order-category-select"
import { WorkOrderDueDatePopover } from "./work-order-due-date-popover"
import { PrioritySelect } from "./work-order-form-v2/work-order-form-priority-select"
import { getPriorityIcon, translateWorkOrderPriority } from "./work-order-priority"
import { translateWorkOrderStatus, WorkOrderStatusTag } from "./work-order-status"

const Label = (props: React.PropsWithChildren<{ className?: string }>) => (
  <span
    className={classNames(
      "text-sm font-medium text-gray-600 sm:mt-px inline-flex items-center",
      props.className
    )}>
    {props.children}
  </span>
)

const InfoSection = (
  props: React.PropsWithChildren<{ control: Control<WorkOrderFormValues> }>
) => {
  const value = useWatch({ control: props.control, name: "info_elements" })

  if (value.length > 0) {
    return (
      <>
        <hr />
        <h3 className="text-sm font-medium text-gray-600">{i18n.t("common:metadata")}</h3>
        {props.children}
      </>
    )
  }

  return null
}

const ChecklistHeader = (props: { control: Control<WorkOrderFormValues> }) => {
  const value = useWatch({ control: props.control, name: "procedure_elements" })

  if (value.length === 0) return null

  return (
    <h3 className="text-sm font-medium text-gray-600">
      {i18n.t("tasks:fields.checklist")}
    </h3>
  )
}

export const SelectObjectElement = (props: {
  assetId: string
  disabled?: boolean
  onRemove?: () => void
}) => {
  const [{ data }] = useAssetTagByIdQuery({
    variables: { id: props.assetId },
    requestPolicy: "cache-only",
  })
  const asset = data?.asset_by_pk

  if (!asset) return null

  return (
    <div className="flex min-w-0 rounded bg-gray-50 p-2 hover:bg-gray-100 hover:text-gray-900">
      <div className="mr-2 shrink-0">
        {asset.avatar ? (
          <Image
            src={asset.avatar?.thumbnail_url}
            preview={{ src: asset.avatar?.url }}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
            imageClasses="w-20 h-20 rounded shrink-0"
          />
        ) : (
          <div className="flex h-20 w-20 shrink-0 items-center justify-center rounded border border-dashed text-xl text-gray-300">
            <Shapes />
          </div>
        )}
      </div>

      <div className="flex h-20 min-w-0 flex-col justify-evenly">
        <AssetWithParents asset={asset} />

        <div className="flex min-w-0 items-center text-sm text-gray-500">
          <div className="w-6 shrink-0">
            <Swatches className="mx-auto" />{" "}
          </div>
          <span className="truncate">
            {asset.group?.name ?? i18n.t("common:without_group")}
          </span>
        </div>
        {
          <div className="flex min-w-0 items-center text-sm text-gray-500">
            <div className="w-6 shrink-0">
              <Icons.Location className="mx-auto text-base" />{" "}
            </div>
            <span className="truncate">
              {asset.place?.name ?? i18n.t("common:without_location")}
            </span>
          </div>
        }
      </div>
      <div className="flex-1" />

      {props.onRemove && (
        <Button
          icon={X}
          color="gray"
          type="tertiary"
          htmlType="button"
          disabled={props.disabled}
          className="ml-1 shrink-0 hover:!bg-gray-200"
          onClick={(e) => {
            e.stopPropagation()
            e?.preventDefault?.()
            if (!asset?.id) return

            props?.onRemove?.()
          }}
        />
      )}
    </div>
  )
}
export const SelectObject = (props: {
  changeAssetIds: (assetIds: string[]) => void
  assetIds: string[]
  disabled?: boolean
}) => {
  const dialog = useDisclosure()

  return (
    <>
      {props.assetIds.length > 0 ? (
        <div
          className={cn(
            "mt-3 flex min-h-0 min-w-0 max-w-full flex-1 flex-col gap-2 disabled:cursor-not-allowed"
          )}>
          {props.assetIds.map((id) => {
            return (
              <SelectObjectElement
                key={id}
                assetId={id}
                disabled={props.disabled}
                onRemove={() =>
                  props.changeAssetIds(props.assetIds.filter((aid) => aid !== id))
                }
              />
            )
          })}

          <div className="self-start">
            <button
              type="button"
              onClick={dialog.onOpen}
              disabled={props.disabled}
              className="relative flex h-8 min-w-0 items-center rounded border border-white px-1.5 py-0.5 text-sm text-gray-500 hover:border-gray-200 hover:text-gray-700 focus-visible:border-gray-300 disabled:cursor-not-allowed disabled:opacity-50">
              <Plus className="mr-1" />{" "}
              {i18n.t("common:add_token", {
                token: i18n.t("common:asset", { count: 1 }),
              })}
            </button>
          </div>
        </div>
      ) : (
        <button
          type="button"
          onClick={dialog.onOpen}
          disabled={props.disabled}
          className={cn(
            "flex w-full items-center disabled:cursor-not-allowed disabled:opacity-50 justify-center gap-2 rounded border border-dashed p-5 text-sm text-gray-500 hover:border-gray-400 hover:bg-gray-50 hover:text-gray-700 radix-state-open:border-gray-400 radix-state-open:bg-gray-50 radix-state-open:text-gray-700"
          )}>
          <Shapes size={20} className="text-gray-400" />
          {i18n.t("tasks:labels.asset_select")}
        </button>
      )}

      <AssetMultiSelectDialogForm
        assetIds={props.assetIds}
        onAssetIdsChange={props.changeAssetIds}
        isOpen={dialog.isOpen}
        onOpenChange={dialog.changeOpen}
      />
    </>
  )
}

const ReportTimeForm = (props: {
  control: Control<WorkOrderFormValues>
  index: number
  remove: (index: number) => void
}) => {
  const { control, index } = props
  const [showDescription, changeShowDescription] = useState(false)
  return (
    <div className="">
      <div className="grid gap-x-6 gap-y-2 pt-2 sm:grid-cols-2">
        <div className="col-span-1 flex flex-col">
          <Label className="pb-1">{i18n.t("tasks:time_report.started_at")}</Label>
          <Controller
            control={control}
            name={`reportTime.${index}.started_at`}
            render={({ field }) => (
              <DateTimeNative value={field.value} onChange={field.onChange} />
            )}
          />
        </div>
        <div className="col-span-1 flex flex-col">
          <Label className="pb-1">{i18n.t("tasks:time_report.duration")}</Label>
          <div className="flex items-center space-x-4">
            <TextInputWithUnit
              type="number"
              min="0"
              {...control.register(`reportTime.${index}.duration.hours`, {
                valueAsNumber: true,
              })}
              unit="Stunden"
            />
            <TextInputWithUnit
              type="number"
              min="0"
              {...control.register(`reportTime.${index}.duration.minutes`, {
                valueAsNumber: true,
              })}
              unit="Minuten"
            />
          </div>
        </div>
      </div>

      {showDescription && (
        <>
          <Label className="pt-2">{i18n.t("tasks:time_report.description")}</Label>
          <RichTextFormController
            control={control}
            name={`reportTime.${index}.description`}
            placeholder={i18n.t("tasks:time_report.description_placeholder")}
            editorProps={{
              attributes: {
                class: "min-h-[2.5rem] focus-visible:outline-none",
              },
            }}
          />
        </>
      )}

      <div className="-mx-2 flex justify-between">
        {showDescription ? (
          <div />
        ) : (
          <Button
            color="gray"
            type="tertiary"
            className="mt-2"
            icon={NotePencil}
            onClick={() => changeShowDescription(true)}>
            {i18n.t("tasks:time_report.add_note")}
          </Button>
        )}
        <Button
          color="gray"
          type="tertiary"
          className="mt-2"
          icon={TrashSimple}
          onClick={() => props.remove(index)}>
          {i18n.t("common:remove")}
        </Button>
      </div>
    </div>
  )
}

const ReportTimeSection = (props: { control: Control<WorkOrderFormValues> }) => {
  const { control } = props
  const user = useUser()
  const { append, fields, remove } = useFieldArray({
    control: control,
    name: "reportTime",
  })
  const addReport = () =>
    append({
      started_at: new Date(),
      duration: {
        hours: 0,
        minutes: 0,
      },
      user_id: user.id,
      description: "",
    })

  if (!fields.length) {
    return (
      <button
        className="flex w-full items-center rounded border border-gray-100 bg-gray-50 p-3 text-gray-500 hover:border-gray-300 hover:bg-gray-100 radix-state-open:border-gray-300 radix-state-open:bg-gray-100 sm:col-span-3"
        type="button"
        onClick={addReport}>
        <div className="flex items-center">
          <ClockClockwise size={24} weight="fill" className="mr-3 text-gray-500" />

          <div>
            <div className="mb-1 text-start text-sm font-medium text-blue-500">
              {i18n.t("tasks:time_report.title")}
            </div>
            <div className="text-start text-xs">
              {i18n.t("tasks:time_report.title_subheader")}
            </div>
          </div>
        </div>
      </button>
    )
  }

  return (
    <div className="rounded bg-gray-50 p-3">
      <h4 className="mb-2 flex text-sm font-medium text-gray-800">
        <ClockClockwise size={20} weight="fill" className="mr-1 text-gray-600" />
        {i18n.t("tasks:time_report.title")}
      </h4>
      <div className="">
        {fields.map((field, index) => {
          return (
            <ReportTimeForm
              key={field.id}
              control={control}
              index={index}
              remove={remove}
            />
          )
        })}
      </div>
    </div>
  )
}

export type CreateTaskFormProps = PropsWithChildren<{
  onSubmit: () => void
  initialValues?: InitialFormValuesInput
  template: { id: string; name: string; allow_modification_in_task_form: boolean } | null
}>

export type CreateTaskFormHandle = {
  isDirty: () => boolean
}

const CreateTaskForm = React.forwardRef(
  (props: CreateTaskFormProps, forwardRef: React.Ref<CreateTaskFormHandle>) => {
    const user = useUser()

    const defaultValues = useCreateTaskFormDefaultValues(props.initialValues ?? {}, {
      // This option is required to make sure that the block element ids are unique
      cleanBlockElementIds: true,
    })

    const form = useForm({
      defaultValues,
      resolver: yupResolver<any>(workOrderFormSchema),
    })

    useHandleDynamicProcedureElements(form)

    const { control, formState, handleSubmit, register, watch } = form

    const [, createMultipleWorkOrders] = useInsertMultipleWorkOrdersMutation()
    const createScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderCreate)

    const isFromTemplate = !!props.template?.id

    let canModifyAllValues = isFromTemplate
      ? props.template?.allow_modification_in_task_form
      : true

    useImperativeHandle(
      forwardRef,
      () => ({
        isDirty: () => {
          return formState.isDirty
        },
      }),
      [formState.isDirty]
    )

    const onSubmit = handleSubmit(
      async (values) => {
        // We ideally just want to use
        //  yup.object().shape({ elements: yup.array(yup.mixed().oneOf([...elementSchemas])) })
        // BUT there is a bug in yup where oneOf compares using referential identity
        // which does not work for objects...
        // see https://github.com/jquense/yup/issues/1393
        // So we have to do this manually here
        const procedueValidation = await validateBlockElements(values.procedure_elements, {
          isTemplate: false,
        })
        if (Object.keys(procedueValidation).length > 0) {
          toast.error(i18n.t("tasks:messages.form_validation_error"))
          objectKeysToIndexString(procedueValidation).forEach(({ key, value }) => {
            form.setError(`procedure_elements.${key}` as any, { message: value })
          })
          return
        }

        let data = [] as IWorkOrderInsertInput[]

        if (values.multiple_tasks && values.asset_ids.length > 1) {
          values.asset_ids.forEach((asset) => {
            let singleTaskValue = { ...values, asset_ids: [asset] }
            let newAssetId = mergeMeterReadingAssetsAndAssetIds(singleTaskValue)
            data.push(
              valuesToWorkOrderInsertInput(
                { ...values, asset_ids: newAssetId, id: undefined },
                { includeBlockElements: true }
              )
            )
          })
        } else {
          const newAssetIds = mergeMeterReadingAssetsAndAssetIds(values)
          data.push(
            valuesToWorkOrderInsertInput(
              { ...values, id: undefined, asset_ids: newAssetIds },
              { includeBlockElements: true }
            )
          )
        }

        const result = await createMultipleWorkOrders({ data }, createScope.context())

        if (result.error || !result.data?.insert_work_order) {
          const errorMessage = i18n.t("common:messages.token_create_failure", {
            token: i18n.t("common:task", { count: 1 }),
          })

          toast.error(errorMessage)
          return
        }

        result.data?.insert_work_order.returning.forEach((workOrder) => {
          const label = i18n.t("common:task", { count: 1 })

          toast.success({
            title: i18n.t("tasks:task_created"),
            params: { duration: 5000, position: "bottom-right" },
            body: (
              <Trans
                i18n={i18n}
                i18nKey="common:open_item"
                values={{ item: `${label} #${workOrder?.work_order_number}` }}>
                <Link className="text-blue-700" to={`/task/${workOrder?.id}`}>
                  Task #123
                </Link>
                öffnen
              </Trans>
            ),
          })
        })

        props.onSubmit()
      },
      async () => {
        try {
          await assetStateSchema({ isTemplate: false }).validate(
            form.getValues("procedure_elements")[0]
          )
        } catch (err) {
          if (err instanceof ValidationError) {
            console.error("Validation Error:", err.errors)
          }
        }

        toast.error(i18n.t("tasks:messages.form_validation_error"))
      }
    )

    const inputs = {
      title: (
        <FormFieldController
          control={control}
          name="name"
          hasErrorPlaceholder={!!formState.errors.name}
          render={({ field, fieldState: { error } }) => (
            <TextInput
              {...field}
              hasError={!!error}
              autoComplete="nofill"
              className="w-full font-medium"
              placeholder={i18n.t("tasks:fields.task_name")}
            />
          )}
        />
      ),
      description: (
        <RichTextFormController
          control={control}
          name="description"
          placeholder={i18n.t("tasks:fields.description")}
          editorProps={{
            attributes: {
              class: "min-h-[4rem] focus-visible:outline-none",
            },
          }}
        />
      ),
      status: (
        <Controller
          control={control}
          name="status"
          render={({ field }) => (
            <SelectPopover
              size="small"
              disabled={!canModifyAllValues}
              isClearable={false}
              onChange={field.onChange}
              popoverContentProps={{
                align: "start",
                side: "bottom",
                sideOffset: 4,
              }}
              value={field.value}
              items={[
                IWorkOrderStatusEnum.Planned,
                IWorkOrderStatusEnum.Open,
                IWorkOrderStatusEnum.InProgress,
                IWorkOrderStatusEnum.ReadyForApproval,
                IWorkOrderStatusEnum.Done,
                IWorkOrderStatusEnum.OnHold,
                IWorkOrderStatusEnum.Canceled,
              ].map((value) => ({
                value,
                searchValue: translateWorkOrderStatus(value),
                label: <WorkOrderStatusTag status={value} />,
              }))}
            />
          )}
        />
      ),
      uploads: (
        <Controller
          control={control}
          name="uploads"
          render={({ field }) => (
            <Uploads
              uploads={field.value}
              onUploadFinished={(u, uploads) => field.onChange(uploads.concat([u]))}
              allowQuickDeleteOfPictures
              onDelete={(id, uploads) => field.onChange(uploads.filter((u) => u.id !== id))}
              onEditFileName={(id, fileName, uploads) =>
                field.onChange(
                  uploads.map((u) => (u.id !== id ? u : { ...u, file_name: fileName }))
                )
              }
              boundDropzoneToContainer={false}
              placeholderType="zone"
              scope={createScope}
            />
          )}
        />
      ),
      assets: (
        <Controller
          control={control}
          name="asset_ids"
          render={({ field }) => (
            <SelectObject
              assetIds={field.value}
              changeAssetIds={field.onChange}
              disabled={!canModifyAllValues}
            />
          )}
        />
      ),
      assignees: (
        <Controller
          control={control}
          name="assignees"
          render={({ field }) => (
            <UserMultiSelect
              compact
              value={field.value}
              onChange={field.onChange}
              title={i18n.t("common:employee", { count: 1 })}
            />
          )}
        />
      ),
      assigned_teams: (
        <Controller
          control={control}
          name="assigned_teams"
          render={({ field }) => (
            <TeamMultiSelect
              compact
              value={field.value}
              onChange={field.onChange}
              title={i18n.t("common:team", { count: 1 })}
            />
          )}
        />
      ),
      collaborators: (
        <Controller
          control={control}
          name="collaborators"
          render={({ field }) => (
            <UserMultiSelect
              compact
              value={field.value}
              onChange={field.onChange}
              title={i18n.t("tasks:fields.collaborator_one")}
            />
          )}
        />
      ),
      calendar: (
        <Controller
          control={control}
          name="calendar"
          render={({ field }) => (
            <WorkOrderDueDatePopover
              markAsOverdue={isDateOverdue(field.value.dueDate?.toISOString())}
              className={classNames(
                popoverCompactTriggerClasses,
                "!h-8 focus-within:ring-0 hover:ring-0 focus-visible:ring-gray-300 hover:!text-gray-700",
                {
                  "font-medium": !!field.value.dueDate,
                  "all-children:hover:text-gray-700": !field.value.dueDate,
                }
              )}
              trigger={
                <IconSpacer icon={<Plus className="shrink-0" />} preset="compactSelect">
                  <span className="min-w-0 truncate">{i18n.t("due_date")}</span>
                </IconSpacer>
              }
              fromForm={{
                ...field.value,
                dueDate: field.value.dueDate ? field.value.dueDate?.toISOString() : null,
                dueTime: field.value.dueTime ? field.value.dueTime : null,
                onSubmit: field.onChange,
              }}
            />
          )}
        />
      ),
      categories: (
        <Controller
          control={control}
          name="categories"
          render={({ field }) => (
            <WorkOrderCategoryMultiSelect
              value={field.value}
              onChange={field.onChange}
              compact
            />
          )}
        />
      ),
      infoElements: (
        <Controller
          control={control}
          name="info_elements"
          render={({ field, fieldState }) => (
            <>
              <VStack space={12}>
                {fieldState.error && (
                  <Flex
                    row
                    className="select-none rounded border border-red-500 bg-red-50 px-4 py-2 text-xs text-red-600 hover:bg-red-100">
                    <Icons.Warning height={18} width={18} className="mr-4" />
                    {fieldState.error.message}
                  </Flex>
                )}
                {field.value.map((element, index) => (
                  <BlockElement
                    key={element.id}
                    hideResponseInfo
                    element={element as BlockElementFragment}
                    validationError={
                      !!fieldState.error &&
                      element.config.required &&
                      element.response === null
                    }
                    updateUploads={async (elementId, uploads) => {
                      const e = field.value.slice()
                      //@ts-ignore
                      e[index] = {
                        ...e[index],
                        uploads: uploads.map((u) => ({
                          __typename: "block_element_x_upload",
                          element_id: elementId,
                          upload_id: u.id,
                          upload: u,
                        })),
                      }
                      field.onChange(e)
                    }}
                    updateResponse={async (_id, response) => {
                      const e = field.value.slice()
                      //@ts-ignore
                      e[index] = {
                        ...e[index],
                        response,
                        //@ts-ignore
                        response_last_edited_by_id: user.id,
                        response_last_edited_at: new Date().toISOString(),
                      }
                      field.onChange(e)
                    }}
                  />
                ))}
              </VStack>
            </>
          )}
        />
      ),
      procedureElements: (
        <BlockGroupElementsFormItem
          name="procedure_elements"
          control={control}
          isTemplate={false}
          selectedAssetIds={form.watch("asset_ids")}
          placeholderButton={
            <button
              className="flex w-full items-center rounded border border-gray-100 bg-gray-50 p-3 text-gray-500 hover:border-gray-300 hover:bg-gray-100 radix-state-open:border-gray-300 radix-state-open:bg-gray-100"
              type="button">
              <div className="flex items-center">
                <Icons.CheckList className="mr-3 h-6 w-6 text-gray-500" />
                <div>
                  <div className="mb-1 text-start text-sm font-medium text-blue-500">
                    {i18n.t("common:add_token", {
                      token: i18n.t("tasks:fields.checklist"),
                    })}
                  </div>
                  <div className="text-start text-xs">
                    {i18n.t("tasks:labels.checklist_description")}
                  </div>
                </div>
              </div>
            </button>
          }
        />
      ),
      priority: (
        <Controller
          control={control}
          name="priority"
          render={({ field }) => (
            <PrioritySelect
              value={field.value}
              size="small"
              onChange={(val) => field.onChange(val)}
              popoverContentProps={{
                align: "start",
                side: "bottom",
                sideOffset: 4,
              }}>
              {(_) => (
                <SelectTrigger
                  className={classNames(popoverCompactTriggerClasses, "!h-8", {
                    "!font-medium text-gray-900": field.value,
                  })}>
                  <IconSpacer
                    preset="compactSelect"
                    icon={
                      !field.value ? (
                        <Plus className="shrink-0" />
                      ) : (
                        getPriorityIcon({ priority: field.value, size: 20 })
                      )
                    }>
                    <span className="truncate">
                      {field.value
                        ? translateWorkOrderPriority(field.value)
                        : i18n.t("tasks:fields.priority")}
                    </span>
                  </IconSpacer>
                </SelectTrigger>
              )}
            </PrioritySelect>
          )}
        />
      ),
      completed: (
        <label className="flex h-8 cursor-pointer select-none items-center text-sm text-gray-600">
          <CheckboxInput {...register("completed")} size="large" />
          <span className="pl-2 text-gray-600">{i18n.t("tasks:fields.status_label")}</span>
        </label>
      ),
      multipleTasks: (
        <label className="flex h-8 cursor-pointer select-none items-center text-sm text-gray-600">
          <CheckboxInput {...register("multiple_tasks")} size="large" />
          <span className="pl-2 text-gray-600">
            {i18n.t("tasks:create_form.multiple_tasks_per_object")}
          </span>
        </label>
      ),
      onlyAssigned: (
        <label className="flex h-8 cursor-pointer items-center space-x-2 text-sm text-gray-600 sm:col-span-2">
          <CheckboxInput {...register("only_assigned")} size="large" />
          <span className="inline-block select-none truncate">
            {i18n.t("tasks:labels.only_assigned")}
          </span>
          <div className="relative inline-flex">
            <Tooltip content={i18n.t("tasks:labels.only_assigned_hint")}>
              <Question size={16} weight="light" />
              <TouchTargetSize vertical horizontal />
            </Tooltip>
          </div>
        </label>
      ),
    }

    return (
      <FormProvider {...form}>
        <form className="flex min-h-0 flex-1 flex-col gap-y-4" onSubmit={onSubmit}>
          <div className="space-y-2">
            {inputs.title}
            {inputs.description}
          </div>

          <div className="space-y-2">
            <Label>{i18n.t("tasks:fields.uploads")}</Label>
            <div className="col-span-2">{inputs.uploads}</div>
          </div>

          <div
            className={cn("w-full space-y-2", {
              "opacity-50 cursor-not-allowed relative": !canModifyAllValues,
            })}>
            {!canModifyAllValues && <div className="absolute inset-0 z-10" />}
            <Label>{i18n.t("common:asset", { count: 2 })}</Label>

            {inputs.assets}

            {watch("asset_ids").length > 1 && inputs.multipleTasks}
          </div>

          <div
            className={cn("grid grid-cols-1 gap-y-1 sm:grid-cols-3 sm:gap-y-3", {
              "opacity-50 cursor-not-allowed relative": !canModifyAllValues,
            })}>
            {!canModifyAllValues && <div className="absolute inset-0 z-10" />}
            <Label className="pt-2 sm:pt-0">
              {i18n.t("tasks:fields.category", { count: 2 })}
            </Label>
            <div className="sm:col-span-2">{inputs.categories}</div>
            <Label className="pt-2 sm:pt-0">{i18n.t("tasks:fields.status")}</Label>
            <div className="sm:col-span-2">{inputs.completed}</div>
          </div>
          {watch("completed") && (
            <div className="grid grid-cols-1 gap-y-1 sm:grid-cols-3 sm:gap-y-3">
              <Label className="pt-2 sm:pt-0">{i18n.t("tasks:fields.completed_by")}</Label>
              <div className="sm:col-span-2">
                <Controller
                  control={control}
                  name="completed_by_id"
                  render={({ field }) => (
                    <UserSingleSelect
                      value={field.value}
                      onChange={field.onChange}
                      isClearable={false}
                    />
                  )}
                />
              </div>
              {/* Collect time report */}
              <div className="pt-2 sm:col-span-3 sm:pt-0">
                <ReportTimeSection control={control} />
              </div>
            </div>
          )}

          <InfoSection control={control}>{inputs.infoElements}</InfoSection>

          <hr />

          <div
            className={cn("grid grid-cols-1 gap-y-1 sm:grid-cols-3 sm:gap-y-3", {
              "opacity-50 cursor-not-allowed relative": !canModifyAllValues,
            })}>
            {!canModifyAllValues && <div className="absolute inset-0 z-10" />}
            <h3 className="text-sm font-medium text-gray-500 sm:col-span-3">
              {i18n.t("tasks:create_form.sections.assignments_plannings")}
            </h3>

            <Label className="pt-1 sm:pt-0">{i18n.t("tasks:fields.priority")}</Label>
            <div className="sm:col-span-2">{inputs.priority}</div>

            <Label className="pt-1 sm:pt-0">{i18n.t("tasks:fields.due_date")}</Label>
            <div className="sm:col-span-2">{inputs.calendar}</div>

            <Label className="pt-1 sm:pt-0">{i18n.t("tasks:fields.assigned_teams")}</Label>
            <div className="sm:col-span-2">{inputs.assigned_teams}</div>

            <Label className="pt-1 sm:pt-0">{i18n.t("tasks:fields.assignees")}</Label>
            <div className="sm:col-span-2">{inputs.assignees}</div>

            <Label className="pt-1 sm:pt-0">
              {i18n.t("tasks:fields.collaborator", { count: 2 })}
            </Label>
            <div className="sm:col-span-2">{inputs.collaborators}</div>

            <Label className="pt-1 sm:pt-0">{i18n.t("tasks:fields.visibility")}</Label>
            {inputs.onlyAssigned}
          </div>

          <hr />
          <ChecklistHeader control={control} />
          {canModifyAllValues ? (
            inputs.procedureElements
          ) : (
            <div className={"relative cursor-not-allowed opacity-50"}>
              <div className="absolute inset-0 z-10" />
              {inputs.procedureElements}
            </div>
          )}

          <DialogContentFooter>
            <DialogPrimitive.Close asChild>
              <Button type="secondary" size="small">
                {i18n.t("common:cancel")}
              </Button>
            </DialogPrimitive.Close>
            <Button
              type="primary"
              size="small"
              className="ml-4"
              htmlType="submit"
              disabled={formState.isSubmitting}>
              {i18n.t("common:save")}
            </Button>
          </DialogContentFooter>
        </form>
      </FormProvider>
    )
  }
)

type CreateTaskFormDialogProps = Omit<CreateTaskFormProps, "onSubmit"> & {
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

export const CreateTaskFormDialog = ({
  open: _open,
  onOpenChange: _onOpenChange,
  ...props
}: CreateTaskFormDialogProps) => {
  const formHandle = useRef<CreateTaskFormHandle>(null)
  const alert = useDisclosure()
  const [open, _changeOpen] = useControllableState({
    prop: _open,
    defaultProp: false,
    onChange: _onOpenChange,
  })

  const changeOpen = (open: boolean) => {
    if (!open && formHandle.current?.isDirty?.()) {
      alert.changeOpen(true)
    } else {
      _changeOpen(open)
    }
  }

  return (
    <DialogRoot open={open} onOpenChange={changeOpen}>
      {props.children && <DialogTrigger asChild>{props.children}</DialogTrigger>}
      <DialogContent className={classNames("sm:max-w-2xl overflow-y-auto overscroll-none")}>
        <DialogContentHeader
          title={
            <>
              <span>{i18n.t("tasks:labels.new_task")}</span>
              {props.template?.name && (
                <>
                  {" "}
                  <span className="text-gray-400">-</span>{" "}
                  <span className="truncate text-gray-500">{props.template?.name}</span>
                </>
              )}
            </>
          }
        />

        <ErrorBoundary
          buttonOnClick={() => _changeOpen(false)}
          buttonText={i18n.t("common:actions.close_window")}>
          <div className="flex min-h-0 flex-col pt-1">
            <CreateTaskForm
              {...props}
              onSubmit={() => _changeOpen(false)}
              ref={formHandle}
            />
          </div>
        </ErrorBoundary>

        <AlertDialogRoot open={alert.isOpen} onOpenChange={alert.changeOpen}>
          <AlertDialogContent
            {...{
              title: i18n.t("tasks:discard.title"),
              description: i18n.t("tasks:discard.message"),
              actionText: i18n.t("common:discard"),
              onAction() {
                _changeOpen(false)
              },
              onCancel() {
                alert.changeOpen(false)
              },
            }}
          />
        </AlertDialogRoot>
      </DialogContent>
    </DialogRoot>
  )
}

export default CreateTaskFormDialog
