import { VStack } from "@components/layout"
import PageTitle from "@components/page/page-title"
import { Button } from "@components/shared"
import { CaretLeft, Plus } from "@phosphor-icons/react"
import { Link } from "react-router-dom"

type Props = React.PropsWithChildren<{
  title: string
  description?: string
  buttonText?: string
  buttonProps?: React.ComponentProps<typeof Button>
  backlink?: string
  actionButton?: React.ReactNode
}>

const SettingsPageLayout: React.FC<Props> = ({
  title,
  description,
  buttonText,
  buttonProps,
  backlink,
  children,
  actionButton,
}) => {
  const ActionButton = () => {
    if (buttonText) {
      return (
        <Button size="small" type="tertiary" icon={Plus} {...buttonProps}>
          {buttonText}
        </Button>
      )
    }

    return null
  }

  return (
    <VStack flex="1" space={4} className="mx-auto w-full max-w-screen-md  space-y-4 ">
      <div className="sticky top-0 isolate z-10 bg-white">
        <div className="flex items-center gap-2">
          {backlink && (
            <Link
              to="/settings/teams"
              className="-ml-2 inline-flex items-center rounded p-2 text-gray-700 hover:bg-gray-100 hover:text-gray-700">
              <CaretLeft size={20} />
            </Link>
          )}

          <PageTitle
            variant="h2"
            title={title}
            subtitle={description}
            action={actionButton ?? <ActionButton />}
          />
        </div>

        <div className="block">
          <div className="mt-2 h-px bg-gray-200" />
        </div>
      </div>

      {children}
    </VStack>
  )
}

export default SettingsPageLayout
