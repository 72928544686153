import { useBreakpoint } from "@contexts/breakpoints"
import { useDetectPrint } from "@contexts/detect-print"
import { useCallbackRef, useDisclosure, useStickyState } from "@hooks"
import i18n from "@i18n"
import { ArrowsOutSimple, CaretDoubleRight, X } from "@phosphor-icons/react"
import { useEffect, useRef } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

import Button from "./button"
import Portal from "./portal"
import { Tooltip } from "./tooltip"

const minWidth = 375

const PeekLayout = () => {
  const bp = useBreakpoint()
  const navigate = useNavigate()
  const location = useLocation()
  const isPrinting = useDetectPrint()

  const [width, setWidth] = useStickyState(1024, "peek-container:task")
  const locationState = location.state as { backgroundLocation?: Location } | null

  const isDragging = useRef(false)
  const dragStart = useRef({ clientX: 0, width })
  const peekContainerRef = useRef<HTMLDivElement>(null)

  const onOpenChange = useCallbackRef((open: boolean) => {
    if (!open && locationState?.backgroundLocation) {
      navigate(locationState.backgroundLocation, { replace: true })
    }
  })

  const disclosure = useDisclosure({
    initialValue: true,
    onOpenChange,
  })

  useEffect(() => {
    const escapeListener = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        disclosure.changeOpen(false)
      }
      if (e.key === "Enter" && e.metaKey) {
        navigate(location.pathname, { replace: true })
      }
    }

    document.addEventListener("keypress", escapeListener)

    return () => document.removeEventListener("keypress", escapeListener)
  })

  useEffect(() => {
    const onPointerMove = (e: PointerEvent) => {
      if (!isDragging.current) return

      const width = -(e.clientX - dragStart.current.clientX) + dragStart.current.width
      setWidth(width)
    }

    const onPointerUp = () => {
      isDragging.current = false
    }

    document.addEventListener("pointermove", onPointerMove)
    document.addEventListener("pointerup", onPointerUp)

    return () => {
      document.removeEventListener("pointermove", onPointerMove)
      document.removeEventListener("pointerup", onPointerUp)
    }
  }, [])

  const onPointerDown = (e: React.PointerEvent) => {
    isDragging.current = true
    if (peekContainerRef.current) {
      dragStart.current = {
        clientX: e.clientX,
        width: peekContainerRef.current.getBoundingClientRect().width,
      }
    } else {
      dragStart.current = {
        clientX: e.clientX,
        width,
      }
    }
  }

  const peek = (
    <div
      ref={peekContainerRef}
      style={bp.lg && !isPrinting ? { width, minWidth, maxWidth: "80vw" } : {}}
      className=" relative flex min-h-0 min-w-0 flex-1 flex-col bg-transparent shadow-lg print:shadow-none">
      <div
        onPointerDown={onPointerDown}
        className="group absolute inset-y-0 left-0 z-20 w-4 cursor-col-resize touch-none">
        <div className="absolute inset-y-0 left-0 w-0.5 bg-transparent transition-colors group-hover:bg-gray-500" />
      </div>
      <div className="box-border flex h-10 min-h-0 min-w-0 items-center justify-start gap-0.5 border-gray-200 bg-gray-50 px-3 print:hidden max-sm:px-1">
        <Tooltip content={i18n.t("close")} asChild>
          <Button
            color="gray"
            type="tertiary"
            size="extra-small"
            icon={CaretDoubleRight}
            className="max-lg:hidden"
            onClick={disclosure.onClose}
          />
        </Tooltip>

        <Tooltip content="Open in full page" asChild>
          <Button
            color="gray"
            type="tertiary"
            size="extra-small"
            icon={ArrowsOutSimple}
            onClick={() => navigate(location.pathname, { replace: true })}
          />
        </Tooltip>

        <div className="flex-1" />
        <Tooltip content={i18n.t("close")} asChild>
          <Button
            icon={X}
            color="gray"
            type="tertiary"
            size="extra-small"
            className="lg:hidden"
            onClick={disclosure.onClose}
          />
        </Tooltip>
      </div>
      <div className="flex min-h-0 min-w-0 flex-1 flex-col border-gray-100 bg-white print:overflow-visible print:border-none lg:border-t-2">
        <Outlet />
      </div>
    </div>
  )

  if (!disclosure.isOpen) return null

  return (
    <Portal>
      <div
        onClick={disclosure.onClose}
        className="fixed inset-0 bg-black/30 animate-in fade-in-0 print:hidden lg:top-0 "
      />
      <div className="absolute inset-x-0 bottom-0 top-4 flex min-h-0 flex-col overflow-hidden rounded-t-xl animate-in slide-in-from-bottom-full duration-75 print:bottom-auto print:overflow-visible lg:inset-y-0 lg:left-auto lg:rounded-t-none lg:slide-in-from-bottom-0 lg:slide-in-from-right-1/4">
        {peek}
      </div>
    </Portal>
  )
}

export default PeekLayout
