import { SelectPopover, SelectPopoverProps } from "@components/shared/single-select"
import { usePermissionRolesQuery } from "@graphql/documents/permissions.generated"
import i18n from "@i18n"

export function PermissionRoleSelect(props: Omit<SelectPopoverProps<string>, "items">) {
  const [permissionRolesQueryRes] = usePermissionRolesQuery({
    requestPolicy: "cache-first",
  })
  const permissionRoles = permissionRolesQueryRes?.data?.permission_role?.slice() ?? []
  const items = permissionRoles
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((r) => ({ value: r.id, label: r.name, searchValue: r.name }))

  return (
    <SelectPopover
      placeholder={i18n.t("settings:permissions.labels.select_placeholder")}
      {...props}
      items={items}
    />
  )
}
