import { useUser } from "@contexts/user-context"
import React from "react"

import { currencySymbol } from "./currency"
import { TextInputWithUnit } from "./text-input-with-unit"

export const CurrencyInput = React.forwardRef<
  React.ElementRef<typeof TextInputWithUnit>,
  Omit<React.ComponentPropsWithoutRef<typeof TextInputWithUnit>, "unit">
>((props, forwardedRef) => {
  const user = useUser()

  return (
    <TextInputWithUnit
      ref={forwardedRef}
      type="number"
      step="0.01"
      unit={currencySymbol(user.location.settings.currency)}
      {...props}
    />
  )
})
