import { getLocationTimeZone } from "@contexts/user-context"
import { parseDate as parseDateInternationalized } from "@internationalized/date"
import { ClassValue, clsx } from "clsx"
import { format } from "date-fns"
import { dequal } from "dequal"
import i18next from "i18next"
import React from "react"
import { twMerge } from "tailwind-merge"

export type Serialized<T> = T extends string | number | boolean | null | undefined
  ? T
  : T extends Function
  ? string
  : T extends Date
  ? string
  : T extends Array<infer U>
  ? Array<Serialized<U>>
  : T extends object
  ? { [P in keyof T]: Serialized<T[P]> }
  : never

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const getFullName = (
  firstName: string | undefined | null,
  lastName: string | undefined | null
) => `${firstName ?? ""} ${lastName ?? ""}`

type FormattingOptions = {
  short?: boolean
  includeTime?: boolean
}
const defaultFormattingOptions: FormattingOptions = {
  short: true,
  includeTime: false,
}

export function isDateOverdue(
  dueDate: string | null | undefined,
  dueTime?: string | null | undefined
) {
  if (!dueDate) return false

  const today = new Date()
  const todayDate = format(today, "yyyy-MM-dd")

  const dateIsPast = todayDate > dueDate

  if (dueTime && todayDate === dueDate) {
    return format(today, "HH:mm:ss") >= dueTime
  }

  return dateIsPast
}

export function parseDate(date: string | Date | null | undefined): Date | null {
  if (date) {
    if (date instanceof Date) return date

    // Check if date is in format YYYY-MM-DD and make sure that the date is parsed as the start of the day
    // in the `local` timezone.
    // This is just a stop gap. We should actually use `getLocationTimeZone` here but most surrounding code
    // is not ready for that yet.
    if (date.match(/^\d{4}-\d{2}-\d{2}$/)) {
      return parseDateInternationalized(date).toDate(getLocationTimeZone())
    } else {
      return new Date(date)
    }
  }
  return null
}

export function toDateString(date: Date | null | undefined) {
  if (date) {
    const month = date.getMonth() + 1
    const day = date.getDate()

    return `${date.getFullYear()}-${month < 10 ? `0${month}` : month}-${
      day < 10 ? `0${day}` : day
    }`
  }
  return null
}

export function parseTime(time: string | null | undefined) {
  if (time) {
    const [hour, minutes, seconds] = time.split(":").map((s) => parseInt(s))
    return new Date(2021, 0, 1, hour, minutes, seconds ?? 0)
  }
  return null
}

export function toTimeString(time: Date | null | undefined) {
  if (time) {
    return `${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`
  }
  return null
}

export const getFormattedDate = (
  date: Date | null | undefined,
  options?: FormattingOptions
) => {
  if (!date) return ""
  const formattingOptions = { ...defaultFormattingOptions, ...options }

  const formattedDate = new Date(date).toLocaleDateString(undefined, {
    day: "2-digit",
    month: "2-digit",
    year: formattingOptions.short ? "2-digit" : "numeric",
  })

  if (formattingOptions.includeTime)
    return `${formattedDate} - ${new Date(date)
      .toLocaleTimeString("de-DE", {
        hour12: false,
      })
      .slice(0, -3)}`

  return formattedDate
}

const collator = new Intl.Collator("de-DE", { numeric: true })

export function naturalCompare(a: string | undefined | null, b: string | undefined | null) {
  return collator.compare(a ?? "", b ?? "")
}

export function lexCompare(a: string | undefined | null, b: string | undefined | null) {
  return (a ?? "").localeCompare(b ?? "")
}

export const sortDate = (a: Date | undefined | null, b: Date | undefined | null) => {
  if (!a && !b) {
    return 0
    // eslint-disable-next-line
  } else if (!a) {
    return -1
  } else if (!b) {
    return 1
  }
  return a.getTime() - b.getTime()
}

export function parseLocaleNumber(str: string) {
  const lang = i18next.languages[0]

  if (lang === "de-DE") {
    return Number(str.replace(/,/g, "."))
  } else {
    return Number(str.replace(/,/g, "."))
  }
}

export function formatLocaleNumber(x: number, options: Intl.NumberFormatOptions = {}) {
  const lang = i18next.languages[0]

  if (lang === "de-DE") {
    return Intl.NumberFormat("de-DE", options).format(x)
  } else {
    return Intl.NumberFormat("en-US", options).format(x)
  }
}

export function splitIntoChunks<T = any>(data: T[], chunkSize: number): Array<Array<T>> {
  const chunks: Array<Array<T>> = []
  let i = 0
  while (i < data.length) {
    const chunk = []
    let currentChunkSize = 0
    while (currentChunkSize < chunkSize && i < data.length) {
      chunk.push(data[i])
      currentChunkSize += 1
      i += 1
    }
    if (chunk.length) {
      chunks.push(chunk)
    }
  }
  return chunks
}

export function formatBytes(bytes: number, decimals: number = 2) {
  if (bytes === 0) return "0 Bytes"

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

export function sortBy<T extends object, K extends keyof T>(
  values: T[],
  by: K,
  compare: (a: T[K], b: T[K]) => number,
  dir: "desc" | "asc" = "asc"
): T[] {
  const sortedValues = values.slice()
  sortedValues.sort((a, b) => {
    const cmp = compare(a[by], b[by])
    return dir === "desc" ? -cmp : cmp
  })
  return sortedValues
}

/**
 * Ecapes a string to represent a regular expression by escaping special characters
 * @param regexString
 * @returns
 */
export function escapeRegExp(regexString: string) {
  return regexString.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")
}

/**
 * Generic version of React.memo. Not really correctly typed but get's the job done
 */
export const genericReactMemo: <T>(component: T) => T = React.memo

export function formatNumber(value: string | number) {
  if (typeof value === "number") value.toLocaleString()
  return Number(value).toLocaleString()
}

export function unique<T>(items: T[]): T[] {
  return items.filter((value, index) => items.findIndex((v) => dequal(v, value)) === index)
}

type GetFieldType<Obj, Path> = Path extends `${infer Left}.${string}`
  ? Left extends keyof Obj
    ? Obj[Left]
    : undefined
  : Path extends keyof Obj
  ? Obj[Path]
  : undefined

export function getValue<
  TData,
  TPath extends string,
  TDefault = GetFieldType<TData, TPath>
>(
  data: TData,
  path: TPath,
  defaultValue?: TDefault
): GetFieldType<TData, TPath> | TDefault {
  const value = path
    .split(/[.[\]]/)
    .filter(Boolean)
    .reduce<GetFieldType<TData, TPath>>((value, key) => (value as any)?.[key], data as any)
  return value !== undefined ? value : (defaultValue as TDefault)
}

export function isAppleiOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  )
}

// function to capitalize each word in a string
export function capitalize(str: string) {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
  )
}

export function getInitials(name: string): string {
  const names = name.split(" ")

  if (names.length === 1) {
    return names[0].charAt(0).toLocaleUpperCase()
  }

  return names[0].charAt(0).toLocaleUpperCase() + names[1].charAt(0).toLocaleUpperCase()
}

export const isLocalhostOrTest =
  window.location.origin.includes("localhost") ||
  window.location.origin.includes("elaratest.de")

const NAIVE_URI_REGEX = /\w+:(\/\/)[^\s]+/g
export const isLink = (text: string) => NAIVE_URI_REGEX.test(text)

// Returns for a given array two lists, one with the elements that satisfy the predicate and one with the elements that don't
export function splitArray<T>(xs: T[], predicate: (x: T) => boolean): [T[], T[]] {
  const left: T[] = []
  const right: T[] = []
  xs.forEach((x) => (predicate(x) ? left.push(x) : right.push(x)))
  return [left, right]
}

/** A function that converts an object
 *  { a : {b : { c : "MESSAGE" }}} to a string
 * "a.b.c" and returns {key: "a.b.c", value: "MESEAGE"}
 *  */
export function objectKeysToIndexString(object: any): { key: string; value: any }[] {
  const keys = Object.keys(object)
  const returnValues: { key: string; value: any }[] = []
  for (const key of keys) {
    const value = object[key]
    if (typeof value === "object") {
      objectKeysToIndexString(value).forEach((x) =>
        returnValues.push({
          key: `${key}.${x.key}`,
          value: x.value,
        })
      )
    } else {
      returnValues.push({ key, value })
    }
  }
  return returnValues
}
