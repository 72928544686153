import { Button } from "@components/shared"
import { alertDialog } from "@components/shared/alert-dialog-provider"
import { useDataWorkOrderDataView } from "@components/work-order/use-task-data"
import { WorkOrderDataView } from "@components/work-order/work-order-data-view"
import { Where } from "@elara/select"
import {
  IMaintenanceFragment,
  IWorkOrderDataViewFragment,
} from "@graphql/documents/fragments.generated"
import { useToggleMaintenancePausedMutation } from "@graphql/documents/maintenance.generated"
import { useTaskDataViewQuery } from "@graphql/documents/work-order.generated"
import i18n from "@i18n"
import { Pause, Play, Rows } from "@phosphor-icons/react"
import { useOutletContext } from "react-router-dom"

const HistoryTab = () => {
  const { maintenance } = useOutletContext<{ maintenance: IMaintenanceFragment }>()

  const [tasksRes] = useTaskDataViewQuery({
    variables: { where: { maintenance_id: { _eq: maintenance.id } } },
  })
  const tasks = tasksRes.data?.work_order ?? []

  const [togglePausedMutation, togglePaused] = useToggleMaintenancePausedMutation()

  const togglePauseState = async () => {
    if (!maintenance) return

    if (maintenance.paused) {
      await togglePaused({
        id: maintenance.id,
        paused: !maintenance.paused,
      })
    } else {
      alertDialog({
        title: i18n.t("maintenance:pause_dialog.title"),
        description: i18n.t("maintenance:pause_dialog.description"),
        actionText: i18n.t("maintenance:pause_dialog.action"),
        onAction: async () => {
          await togglePaused({
            id: maintenance.id,
            paused: !maintenance.paused,
          })
        },
      })
    }
  }

  return (
    <div className="my-3 flex min-h-0 flex-1 flex-col">
      <WorkOrderDataView
        data={tasks}
        headerClassName="mx-3"
        defaultConfig={{
          historyFilter: undefined,
          columnOrder: ["task", "dueDate"],
          orderBy: [
            {
              id: "workOrderNumber",
              dir: "desc",
            },
          ],
          groupBy: null,
          filters: [],
        }}
        configId={`tasks:maintenance-${maintenance.id}`}
        historyFilterLabel={undefined}
        historyFilterItems={undefined}
        noData={{
          icon: Rows,
          title: i18n.t("maintenance:messages.history.empty_state.title"),
          message: i18n.t("maintenance:messages.history.empty_state.message"),
          actions: [
            ...(maintenance.paused
              ? [
                  <Button
                    type="secondary"
                    onClick={togglePauseState}
                    icon={maintenance.paused ? Play : Pause}
                    disabled={togglePausedMutation.fetching}
                    isLoading={togglePausedMutation.fetching}>
                    {maintenance.paused
                      ? i18n.t("maintenance:fields.status.resume")
                      : i18n.t("maintenance:fields.status.pause")}
                  </Button>,
                ]
              : []),
          ],
        }}
        useData={(filterWhere) => {
          let where: Where<IWorkOrderDataViewFragment> = {}

          if (maintenance) {
            where = { maintenance_id: { _eq: maintenance.id } }
          }

          // eslint-disable-next-line react-hooks/rules-of-hooks
          return useDataWorkOrderDataView({ _and: [filterWhere ?? {}, where] })
        }}
      />
    </div>
  )
}

export default HistoryTab
