import toast from "@components/shared/toast"
import { useWorkOrderIsAssigned } from "@components/work-order/work-order-is-assigned"
import { useUser } from "@contexts/user-context"
import { IPermissionScopeEnum } from "@elara/db"
import i18n from "@i18n"
import { useMemo } from "react"
export { IPermissionScopeEnum } from "@elara/db"

export type PermissionScopeObject = ReturnType<typeof usePermissionScope>

class PermissionError extends Error {
  scope: IPermissionScopeEnum

  constructor(scope: IPermissionScopeEnum) {
    super()
    this.message = `PermissionError: ${scope}`
    this.scope = scope
  }
}

export function usePermissionScope<S extends IPermissionScopeEnum>(scope: S) {
  const { allowedScopes } = useUser()
  const isAssignedToWorkOrder = useWorkOrderIsAssigned()

  const values = useMemo(() => {
    const match = allowedScopes.find((s) => s.scope === scope)
    let hasScope = !!match
    if (
      match &&
      (match.scope === IPermissionScopeEnum.AppDataEntry ||
        match.scope === IPermissionScopeEnum.AppWorkOrderApproval)
    ) {
      const isAssignedOnly = !!match.options?.assigned_only
      hasScope = !isAssignedOnly || isAssignedToWorkOrder
    }
    const context = () => {
      if (!hasScope) {
        toast.error({
          title: i18n.t("common:missing_permission"),
          params: { duration: 5000 },
          body: i18n.t("messages.lack_of_permissions"),
        })
        throw new PermissionError(scope)
      }
      return {
        fetchOptions: {
          headers: { "x-hasura-role": match?.role ?? "usr" },
        },
      }
    }
    return { hasScope, context, scope: match ? match : null }
  }, [allowedScopes.length, scope])

  return values
}
