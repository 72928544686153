import { Center } from "@components/layout"
import {
  IFileFolderUploadFragment,
  ISubFolderUploadFragment,
} from "@graphql/documents/file-folder.generated"
import { useDisclosure } from "@hooks"
import i18n from "@i18n"
import {
  Check,
  DotsThreeVertical,
  DownloadSimple,
  TextAUnderline,
  TrashSimple,
  X,
} from "@phosphor-icons/react"
import Icons from "@resources/icons"
import { colors, px } from "@styles"
import { cn, formatBytes } from "@utils"
import {
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useRef,
  useState,
} from "react"

import ActionMenu from "./action-menu"
import Box from "./box"
import { Button } from "./button"
import Divider from "./divider"
import { DocumentCardIcon } from "./document-card-icon"
import EmptyState from "./empty-state"
import { InlineEditButtons } from "./inline-edit-buttons"
import { TextInput, TextInputProps } from "./text-input"

const LeftContainer = (props: PropsWithChildren<{ onClick?: () => void }>) => {
  const { onClick, children } = props
  return (
    <div
      className={cn("flex items-center w-4/5 ", { "cursor-pointer": props.onClick })}
      onClick={onClick}>
      {children}
    </div>
  )
}

const NameContainer = (props: PropsWithChildren<{}>) => (
  <div className="flex w-[62%] items-center truncate first-of-type:mr-8">
    {props.children}
  </div>
)

type FolderRowProps = {
  item: ISubFolderUploadFragment
  hasEditRights: boolean
  onClick: (id: string) => void
  onDelete: (id: string) => void
  onRename: (id: string, folderName: string) => Promise<void>
}

const FolderRow = ({
  item,
  hasEditRights,
  onClick,
  onDelete,
  onRename,
}: FolderRowProps) => {
  const [isInRenameMode, setIsInRenameMode] = useState(false)
  const [folderName, setFolderName] = useState(item.name)
  const [isLoading, setIsLoading] = useState(false)

  const onRenameEnter = useCallback(async () => {
    setIsLoading(true)
    await onRename(item.id, folderName)
    setIsLoading(false)
    setIsInRenameMode(false)
  }, [folderName, setIsLoading, setIsInRenameMode, onRename, item.id])

  const onCancel = useCallback(() => {
    setFolderName(item.name)
    setIsInRenameMode(false)
  }, [setFolderName, setIsInRenameMode, item.name])

  const MenuActions = [
    {
      label: i18n.t("common:rename"),
      icon: <TextAUnderline />,
      key: `edit_name/${item.id}`,
      action: () => {
        setIsInRenameMode(true)
      },
    },
    {
      label: i18n.t("common:delete"),
      icon: <TrashSimple />,
      key: `delete_action/${item.id}`,
      action: () => onDelete(item.id),
    },
  ]

  const rowRef = useRef(null)

  const numberOfSubItems =
    (item.files_aggregate.aggregate?.count ?? 0) +
    (item.sub_folders_aggregate?.aggregate?.count ?? 0)

  return (
    <div className="flex items-center justify-between p-2" ref={rowRef}>
      <LeftContainer onClick={() => !isInRenameMode && onClick?.(item.id)}>
        <NameContainer>
          <div className="h-6 w-6 shrink-0">
            {<Icons.Folder color={colors.blueDeep} className="h-5 w-5" />}
          </div>
          {!isInRenameMode ? (
            <span className="truncate text-sm">{item.name}</span>
          ) : (
            <RenameInput
              value={folderName}
              onChange={(e) => {
                setFolderName(e.currentTarget.value)
              }}
              onEnter={onRenameEnter}
              onCancel={onCancel}
            />
          )}
        </NameContainer>
        <span className="text-sm">
          {numberOfSubItems}{" "}
          {i18n.t("assets:documents.fields.document", {
            count: numberOfSubItems,
          })}
        </span>
      </LeftContainer>
      <div className="flex">
        {hasEditRights &&
          (isInRenameMode ? (
            <InlineEditButtons
              isLoading={isLoading}
              okIsDisabled={isLoading || !folderName.length}
              onOk={onRenameEnter}
              onCancel={onCancel}
            />
          ) : (
            <ActionMenu items={MenuActions}>
              <Button
                size="small"
                type="tertiary"
                icon={DotsThreeVertical}
                style={{ color: colors.grey3 }}
              />
            </ActionMenu>
          ))}
      </div>
    </div>
  )
}

type RenameInputProps = TextInputProps & {
  onCancel: () => void
  onEnter: () => void
}

const RenameInput = ({ onEnter, onCancel, style, ...props }: RenameInputProps) => {
  return (
    <TextInput
      autoFocus
      {...props}
      style={{ ...style, height: 32 }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onEnter()
        } else if (e.key === "Escape") {
          onCancel()
        }
      }}
    />
  )
}

type FileRowProps = {
  item: IFileFolderUploadFragment
  hasEditRights: boolean
  onDelete: (body: { upload_id: string; folder_id: string }) => void
  onRename: (id: string, fileName: string) => Promise<void>
}

const FileRow = ({ item, hasEditRights, onDelete, onRename }: FileRowProps) => {
  const imagePreview = useDisclosure()

  const [isInRenameMode, setIsInRenameMode] = useState(false)
  const [fileName, setFileName] = useState(item.file.file_name)
  const [isLoading, setIsLoading] = useState(false)

  const onRenameEnter = useCallback(async () => {
    setIsLoading(true)
    await onRename(item.file.id, fileName)
    setIsLoading(false)
    setIsInRenameMode(false)
  }, [onRename, setIsLoading, setIsInRenameMode, item.file.id, fileName])

  const onCancel = useCallback(() => {
    setFileName(item.file.file_name)
    setIsInRenameMode(false)
  }, [setFileName, setIsInRenameMode, item.file.file_name])

  const MenuActions = [
    {
      label: i18n.t("common:rename"),
      icon: <TextAUnderline />,
      key: `edit_name/${item.file.id}`,
      action: () => {
        setIsInRenameMode(true)
      },
    },
    {
      label: i18n.t("common:delete"),
      icon: <TrashSimple />,
      key: `delete_action/${item.file.id}`,
      action: () =>
        onDelete({
          upload_id: item.file.id,
          folder_id: item.file_folder_id,
        }),
    },
    {
      key: "download",
      label: item.file.url && (
        <a
          href={`${item.file.url}?download=1`}
          style={{ display: "flex", alignItems: "center" }}>
          <DownloadSimple style={{ marginRight: px(8) }} /> {i18n.t("common:download")}
        </a>
      ),
    },
  ]
  const isImage = item.file.mime_type?.startsWith("image")
  const rowRef = useRef(null)
  const leftSideContent = (
    <LeftContainer
      onClick={() => {
        if (isImage && !isInRenameMode) {
          imagePreview.onOpen()
        }
      }}>
      <NameContainer>
        {
          <DocumentCardIcon
            mimeType={item.file.mime_type}
            thumbnailUrl={item.file.thumbnail_url}
            url={item.file.url!}
            previewVisible={imagePreview.isOpen}
            onPreviewVisibleChange={imagePreview.changeOpen}
            height={24}
            width={24}
          />
        }
        {!isInRenameMode ? (
          <span className="truncate pr-4 text-sm">{item.file.file_name}</span>
        ) : (
          <RenameInput
            value={fileName}
            onChange={(e) => {
              setFileName(e.currentTarget.value)
            }}
            onEnter={onRenameEnter}
            onCancel={onCancel}
          />
        )}
      </NameContainer>
      <span className="truncate text-sm">{formatBytes(item.file.file_size)}</span>
    </LeftContainer>
  )

  return (
    <div className="flex items-center justify-between p-2" ref={rowRef}>
      {isImage || isInRenameMode ? (
        leftSideContent
      ) : (
        <a
          href={item.file.url!}
          target="_blank"
          rel="noreferrer"
          style={{ display: "contents" }}>
          {leftSideContent}
        </a>
      )}
      <div className="flex">
        {hasEditRights &&
          (isInRenameMode ? (
            <InlineEditButtons
              isLoading={isLoading}
              okIsDisabled={isLoading || !fileName.length}
              onOk={onRenameEnter}
              onCancel={onCancel}
            />
          ) : (
            <ActionMenu items={MenuActions}>
              <Button
                size="small"
                type="tertiary"
                icon={DotsThreeVertical}
                style={{ color: colors.grey3 }}
              />
            </ActionMenu>
          ))}
      </div>
    </div>
  )
}

type CreateFolderRowProps = {
  onCreate: (name: string) => void
}

const CreateFolderRow = ({ onCreate }: CreateFolderRowProps) => {
  const [inputValue, setInputValue] = useState("")

  return (
    <div className="flex items-center justify-between p-2">
      <LeftContainer>
        <NameContainer>
          <div className="h-6 w-6 shrink-0">
            {<Icons.Folder color={colors.blueDeep} height={24} width={24} />}
          </div>
          <RenameInput
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.currentTarget.value)
            }}
            onEnter={() => onCreate?.(inputValue)}
            onCancel={() => setInputValue("")}
          />
          <Button type="tertiary" onClick={() => onCreate?.("")} icon={X} />
          <Button type="tertiary" onClick={() => onCreate?.(inputValue)} icon={Check} />
        </NameContainer>
      </LeftContainer>
    </div>
  )
}

const EmptyFolderState = () => {
  return (
    <EmptyState
      icon={Icons.EmptyFolder}
      message={i18n.t("assets:documents.messages.empty_folder")}
    />
  )
}

type FilesListProps = {
  children: (ReactElement<FileRowProps | FolderRowProps | CreateFolderRowProps> | null)[]
}

interface FilesListCompundParts {
  FileRow: FunctionComponent<FileRowProps>
  FolderRow: FunctionComponent<FolderRowProps>
  CreateFolderRow: FunctionComponent<CreateFolderRowProps>
}

const FilesList: FunctionComponent<FilesListProps> & FilesListCompundParts = ({
  children,
}) => {
  if (!children.length || children.every((c) => c === null))
    return (
      <Center style={{ height: "100%" }}>
        <EmptyFolderState />
      </Center>
    )
  return (
    <div>
      <div className="sticky top-0 z-10 bg-white pt-3">
        <div className="flex items-center justify-between p-2">
          <LeftContainer>
            <NameContainer>
              <span className="text-sm text-gray-500">
                {i18n.t("assets:documents.fields.filename")}
              </span>
            </NameContainer>
            <span className="text-sm text-gray-500">
              {i18n.t("assets:documents.fields.size")}
            </span>
          </LeftContainer>
        </div>
        <Divider />
      </div>
      <Box>{children}</Box>
    </div>
  )
}

FilesList.FileRow = FileRow
FilesList.FolderRow = FolderRow
FilesList.CreateFolderRow = CreateFolderRow

export default FilesList
