export const parseQRCodeUrl = (url: string): string | false => {
  // Check if result is a valid URL from one of the supported domains
  const domains = [".elara.digital", ".elaratest.de", "localhost"]

  // Check if url is a valid URL, from the whitelisted domains
  try {
    const parsedUrl = new URL(url)

    if (!domains.some((d) => parsedUrl.hostname.includes(d))) return false

    return parsedUrl.pathname
  } catch {
    // Ignore if result is not a valid URL
    return false
  }
}
