import { Icons } from "@components/shared"
import { DialogForm } from "@components/shared/dialog-form"
import { FormField } from "@components/shared/form/form-field"
import { ImageUploadFormElement } from "@components/shared/image-upload-form-element"
import { Select } from "@components/shared/select"
import { TextInput } from "@components/shared/text-input"
import toast from "@components/shared/toast"
import { useUser } from "@contexts/user-context"
import { IPermissionScopeEnum, uuid } from "@elara/db"
import { useGetUserProfileQuery } from "@graphql/documents/basic.generated"
import { usePermissionRolesQuery } from "@graphql/documents/permissions.generated"
import {
  useEditUserProfileMutation,
  useUpdateUserEmailMutation,
} from "@graphql/documents/user.generated"
import { useUpdateUserPermissionRoleMutation } from "@graphql/documents/workforce.generated"
import { usePermissionScope } from "@hooks"
import i18n from "@i18n"

export type EditUserProfileFormProps = {
  userId?: uuid | null
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
}

export const EditUserProfileForm = (props: EditUserProfileFormProps) => {
  const user = useUser()

  const id = props.userId ?? user.id

  const [userProfileResult] = useGetUserProfileQuery({
    variables: {
      id: id!,
      location_id: user.location.id,
    },
    pause: !id || !props.isOpen,
  })
  const profile = userProfileResult?.data?.user_by_pk
  const permissionRoleId =
    userProfileResult?.data?.location_member_by_pk?.permission_role?.id
  const isOwner = userProfileResult?.data?.location_member_by_pk?.is_owner ?? false

  const [, editUserProfile] = useEditUserProfileMutation()
  const [, updateUserEmail] = useUpdateUserEmailMutation()

  const userScope = usePermissionScope(IPermissionScopeEnum.AppUser)
  const accountManagementScope = usePermissionScope(
    IPermissionScopeEnum.AppAccountManagement
  )

  const [, updatePermissionRole] = useUpdateUserPermissionRoleMutation()

  const [permissionRolesQueryRes] = usePermissionRolesQuery({
    requestPolicy: "cache-first",
    pause: !props.isOpen,
  })

  const permissionRoles = permissionRolesQueryRes?.data?.permission_role?.slice() ?? []
  const roleOptions = permissionRoles
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((r) => ({ value: r.id, label: r.name }))

  const allowRoleChange = !!(accountManagementScope.hasScope && props.userId && !isOwner)

  if (!profile || !permissionRoleId) return null

  return (
    <DialogForm
      formikConfig={{
        initialValues: {
          first_name: profile.first_name,
          last_name: profile.last_name,
          phone: profile.phone,
          avatar: profile.avatar,
          email: profile.email,
          permissionRoleId,
          username: profile.username,
        },
        onSubmit: async (values) => {
          const location_id = user.location.id
          if (!id || !location_id) return toast.error(i18n.t("generic_toast_error"))

          const result = await editUserProfile(
            {
              id,
              set: {
                first_name: values.first_name || null,
                last_name: values.last_name || null,
                avatar_id: values.avatar?.id || null,
                phone: values.phone || null,
                email: values.email,
                username: values.email,
              },
            },
            userScope.context()
          )

          if (result.error || !result.data) {
            toast.error(i18n.t("generic_toast_error"))
            throw result.error
          }

          if (values.email && values.email !== profile?.email) {
            const emailUpdateResult = await updateUserEmail({
              userId: id,
              email: values.email,
            })
            if (emailUpdateResult.error || !emailUpdateResult.data) {
              toast.error(i18n.t("generic_toast_error"))
              throw result.error
            }
          }

          if (accountManagementScope.hasScope) {
            const roleRes = await updatePermissionRole(
              {
                user_id: id,
                location_id,
                permissionRoleId: values.permissionRoleId,
              },
              accountManagementScope.context()
            )
            if (roleRes.error) {
              toast.error(i18n.t("settings:profile.edit_profile.role_change_error"))
              throw roleRes.error
            }
          }

          return result.data
        },
      }}
      title={i18n.t("settings:profile.edit_profile.title")}
      {...props}>
      {() => (
        <div className="flex w-full flex-col space-y-3">
          <FormField name="avatar" label="Avatar" className="col-span-2">
            {({ field, helpers }) => (
              <ImageUploadFormElement
                fallback={<Icons.User />}
                upload={field.value}
                onUploadChange={helpers.setValue}
              />
            )}
          </FormField>

          <FormField
            name="first_name"
            label={i18n.t("settings:profile.edit_profile.first_name")}>
            <TextInput type="text" />
          </FormField>

          <FormField
            name="last_name"
            label={i18n.t("settings:profile.edit_profile.last_name")}>
            <TextInput type="text" />
          </FormField>

          <FormField name="email" label={i18n.t("email")}>
            <TextInput type="email" />
          </FormField>

          <FormField name="phone" label={i18n.t("settings:profile.fields.phone")}>
            <TextInput type="tel" />
          </FormField>

          <FormField name="permissionRoleId" label={i18n.t("role")}>
            {({ field, helpers }) => (
              <Select
                items={roleOptions}
                value={field.value}
                disabled={!allowRoleChange}
                onValueChange={helpers.setValue}
              />
            )}
          </FormField>
        </div>
      )}
    </DialogForm>
  )
}

export default EditUserProfileForm
