import { SelectPopover, SelectPopoverProps } from "@components/shared/single-select"
import { IconData, TeamIcon } from "@components/shared/team-icons"
import { getUser, hasPermissionScope } from "@contexts/user-context"
import { IPermissionScopeEnum } from "@elara/db"
import { ITeamFragment } from "@graphql/documents/fragments.generated"
import { useTeamsQuery } from "@graphql/documents/team.generated"
import i18n from "@i18n"
import Icons from "@resources/icons"
import { useMemo } from "react"

export function teamItems(teams: ITeamFragment[]) {
  const userId = getUser().id
  const isAdmin = hasPermissionScope(IPermissionScopeEnum.AppAccountManagement)
  const myTeams = teams
    .filter(
      (t) => t && !t.archived_at && (isAdmin || t.members.some((m) => m.user.id === userId))
    )
    .map((t) => ({
      team: t,
      sort_order: t.members.find((m) => m.user.id === userId)?.sort_order!,
    }))
    .sort((a, b) => a.sort_order - b.sort_order)
    .map(({ team }) => {
      const icon = team.icon as IconData

      return {
        value: team?.id,
        label: <span className="truncate">{team.name}</span>,
        icon: <TeamIcon name={icon?.name} color={icon?.color} />,
        searchValue: team.name,
      }
    })

  const defaultOptions = [
    {
      value: "private",
      label: <span className="truncate">{i18n.t("views:sharing.private")}</span>,
      icon: <Icons.Lock style={{ color: "#525252" }} className="h-[1em] w-[1em]" />,
      searchValue: i18n.t("views:sharing.private"),
    },
    {
      value: "allUsers",
      label: <span className="truncate">{i18n.t("views:sharing.all_users")}</span>,
      icon: <Icons.Team style={{ color: "#525252" }} className="h-[1em] w-[1em]" />,
      searchValue: i18n.t("views:sharing.all_users"),
    },
  ]

  return [
    {
      value: "other_options",
      label: "",
      searchValue: "",
      children: defaultOptions,
    },
    {
      value: "my_teams",
      label: isAdmin ? i18n.t("common:team_other") : i18n.t("teams:my_teams"),
      searchValue: isAdmin ? i18n.t("common:team_other") : i18n.t("teams:my_teams"),
      children: myTeams,
    },
  ]
}

const useCustomViewShareSelect = () => {
  const [queryResult] = useTeamsQuery({ requestPolicy: "cache-first" })
  const teams = useMemo(() => queryResult.data?.team ?? [], [queryResult.data?.team])
  const items = useMemo(() => teamItems(queryResult.data?.team ?? []), [teams])

  return {
    name: i18n.t("views:sharing.label"),
    placeholder: i18n.t("common:select_token", {
      token: i18n.t("views:sharing.visibility"),
    }),
    noDataPlaceholder: i18n.t("common:no_token_found", {
      token: i18n.t("common:team", { count: 2 }),
    }),
    items,
    groupBy: true,
    valueToString: (s: string) => s,
  }
}

export type CustomViewShareSelectProps = Partial<SelectPopoverProps<string>>

export function CustomViewShareSelect({ ...props }: CustomViewShareSelectProps) {
  const selectProps = useCustomViewShareSelect()
  return <SelectPopover isClearable={false} {...selectProps} {...props} />
}
