import { exportWorkOrdersAsCSV } from "@components/work-order/work-order-export"
import { useFeature } from "@contexts/feature-flag-context"
import { IViewDataTypeEnum } from "@elara/db"
import { Data } from "@elara/select"
import { IWorkOrderFragment } from "@graphql/documents/fragments.generated"
import { useCallbackRef, useDeepMemo } from "@hooks"
import useVirtuosoScrollRestoration from "@hooks/use-virtuoso-scroll-restoration"
import i18n from "@i18n"
import { CaretDown, TrashSimple, X } from "@phosphor-icons/react"
import Icons from "@resources/icons"
import { ErrorBoundary } from "@sentry/react"
import { cn, genericReactMemo } from "@utils"
import { FilteredTreeLike, Tree } from "@utils/tree"
import { default as classNames, default as cx } from "classnames"
import React, {
  ReactElement,
  ReactNode,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { Virtuoso, VirtuosoHandle } from "react-virtuoso"
import { v4 as uuidv4 } from "uuid"

import Button from "../button"
import { ContextMenuContent, ContextMenuRoot, ContextMenuTrigger } from "../context-menu"
import { CheckboxInput } from "../form/checkbox-input"
import { OpenCloseToggleIcon } from "../open-close-toggle"
import ScrollArea from "../scroll-area"
import { Tooltip } from "../tooltip"
import { tokenMap, UseDataViewReturnType } from "./data-view.hooks"
import { UseBatchSelectReturnType } from "./data-view-hooks/data-view-use-batch-select"
import { useDataViewFlatData } from "./data-view-hooks/data-view-use-flat-data"
import { useKeyboardNavigation } from "./data-view-hooks/data-view-use-keyboard-navigation"
import { useColumn, useDeleteSelectedItems } from "./data-view-table.hooks"
import { Column, DataOrderConfig } from "./data-view-types"

type DataViewTableRowProps<D extends Data, Id extends string, Options extends {}> = {
  row: FilteredTreeLike<Tree<D>>
  columns: Column<D, Id, Options>[]
  options: Options
  onRowClick: (row: FilteredTreeLike<Tree<D>>) => void
  selectedDataId?: string | null
  dataId: (d: D) => string
  getNodeDisclosure: UseDataViewReturnType<D, Id>["getNodeDisclosure"]
  getBatchNodeSelect: UseBatchSelectReturnType<D>["getNodeSelect"]
  level?: number
  cellClass?: string
  contextMenu?: (data: D) => ReactNode
}

const DataViewTableRow = genericReactMemo(
  <D extends Data, Id extends string, Options extends {}>(
    props: DataViewTableRowProps<D, Id, Options>
  ) => {
    const { level = 0 } = props
    const disclosure = props.getNodeDisclosure(props.row)
    const batchSelect = props.getBatchNodeSelect(props.row)
    const isSelected = props.selectedDataId === props.dataId(props.row)

    const ref = useRef<HTMLDivElement>(null)
    const hasBatchSelectFeature = useFeature("batch_select")

    useLayoutEffect(() => {
      if (isSelected) {
        ref.current?.focus()
      }
    }, [isSelected])

    const wrap = (e: ReactElement) => {
      if (!props.contextMenu) return e

      return (
        <ContextMenuRoot>
          <ContextMenuTrigger>{e}</ContextMenuTrigger>
          <ContextMenuContent className="rounded border border-gray-100 bg-white py-1 text-sm text-gray-700 drop-shadow-lg">
            {props.contextMenu(props.row)}
          </ContextMenuContent>
        </ContextMenuRoot>
      )
    }

    return (
      <>
        {wrap(
          <div
            className={classNames("flex flex-row text-sm group divide-x divide-gray-100", {
              "bg-gray-50": isSelected,
            })}
            ref={ref}
            tabIndex={isSelected ? 0 : -1}
            onClick={() => props.onRowClick(props.row)}
            key={typeof props.row.id === "string" ? props.row.id : uuidv4()}>
            {props.columns.map((col, idx) => {
              const cell = col.Cell?.(props.row, props.options)
              return (
                <div
                  key={col?.id}
                  className={cx(
                    "flex items-center pr-2 py-2 border-b border-gray-100 group-hover:bg-grey-7 cursor-pointer overflow-hidden flex-shrink-0"
                  )}
                  style={{
                    width: `var(--col-${idx}-width)`,
                    paddingLeft: idx === 0 ? level * 22 + 8 : 8,
                    flexGrow: idx === props.columns.length - 1 ? 1 : 0,
                  }}>
                  {hasBatchSelectFeature && idx === 0 && (
                    <label
                      onClick={(event) => event.stopPropagation()}
                      className="mr-1 flex h-full w-8 items-center justify-center">
                      <CheckboxInput
                        checked={batchSelect.isSelected}
                        onChange={batchSelect.toggle}
                      />
                    </label>
                  )}
                  {idx === 0 && !!props.row.children?.length && (
                    <div
                      className="-my-1.5 -ml-1.5 mr-0.5 flex w-8 shrink-0 items-center justify-center self-stretch rounded hover:bg-gray-100"
                      onClick={(e) => {
                        e.stopPropagation()
                        disclosure.toggle()
                      }}>
                      <OpenCloseToggleIcon isOpen={disclosure.isOpen} />
                    </div>
                  )}
                  {cell &&
                    (typeof cell === "string" ? (
                      <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                        {cell}
                      </span>
                    ) : (
                      <ErrorBoundary>
                        <div
                          className={classNames(
                            "min-w-0 flex-1 first:pl-1",
                            props.cellClass
                          )}>
                          {cell}
                        </div>
                      </ErrorBoundary>
                    ))}
                </div>
              )
            })}
          </div>
        )}

        {/* {disclosure.isOpen &&
          props.row.children?.map((childRow, idx) => (
            <DataViewTableRow
              key={(childRow as { id?: string }).id ?? idx}
              row={childRow}
              columns={props.columns}
              options={props.options}
              cellClass={props.cellClass}
              selectedDataId={props.selectedDataId}
              dataId={props.dataId}
              onRowClick={props.onRowClick}
              getNodeDisclosure={props.getNodeDisclosure}
              getBatchNodeSelect={props.getBatchNodeSelect}
              level={level + 1}
              contextMenu={props.contextMenu}
            />
          ))} */}
      </>
    )
  }
)

const ResizeHandle = (props: {
  isActive: boolean
  height?: number
  onPointerDown: (e: React.PointerEvent) => void
  right?: number
}) => {
  return (
    <div
      className={cx(
        "absolute top-0 block w-2 cursor-col-resize bottom-0 border-r-2 border-solid border-r-grey-5 m-auto ",
        {
          "!border-r-blue-400": props.isActive,
        }
      )}
      onPointerDown={props.onPointerDown}
      style={{ height: props.height, right: props.right ?? 0 }}
    />
  )
}

const OrderIndicator = (props: { order: "desc" | "asc" | null }) => {
  return (
    <CaretDown
      className={cn("ml-2 h-3 w-3 text-gray-400 mr-2 shrink-0", {
        "rotate-180": props.order === "desc",
      })}
    />
  )
}

type DataViewTableHeaderProps<D extends Data, Id extends string, Options extends {}> = {
  columns: Column<D, Id, Options>[]
  getHeaderProps: (column: Column<D, Id, Options>) => {
    ref: (r: HTMLTableCellElement | null) => void
    onClick: () => void
  }
  getResizeHandlerProps: (column: Column<D, Id, Options>) => {
    isActive: boolean
    onPointerDown: () => void
  }
  batchSelect: UseBatchSelectReturnType<D>
  orderBy?: DataOrderConfig<Id>[]
}

const DataViewTableHeader = genericReactMemo(
  <D extends Data, Id extends string, Options extends {}>(
    props: DataViewTableHeaderProps<D, Id, Options>
  ) => {
    const hasBatchSelectFeature = useFeature("batch_select")

    return (
      <div className="sticky !top-0 z-[1] box-border flex border-y border-gray-100">
        {props.columns.map((c, index) => {
          const order =
            props.orderBy?.[0]?.id === c.id ? props.orderBy?.[0]?.dir ?? null : null

          return (
            <div
              key={c.id}
              className="relative flex shrink-0 cursor-pointer touch-none items-center overflow-hidden border-gray-100 bg-gray-50 px-2 py-2.5 text-left text-sm font-medium first:rounded-tl last:rounded-tr"
              style={{
                width: `var(--col-${index}-width)`,
                flexGrow: index === props.columns.length - 1 ? 1 : 0,
              }}
              {...props.getHeaderProps(c)}>
              {hasBatchSelectFeature && index === 0 && (
                <label className="flex h-8 w-8 items-center justify-center">
                  <CheckboxInput
                    checked={props.batchSelect.hasSelectedItem}
                    onChange={(e) => {
                      if (e.target.checked) {
                        props.batchSelect.selectAll()
                      } else {
                        props.batchSelect.unselectAll()
                      }
                    }}
                  />
                </label>
              )}
              <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                {c.Header}
              </div>
              {c.orderBy && <OrderIndicator order={order} />}
              <ResizeHandle
                {...props.getResizeHandlerProps(c)}
                height={20}
                right={index == props.columns.length - 1 ? 8 : 0}
              />
            </div>
          )
        })}
      </div>
    )
  }
)

type DataViewTableProps<D extends Data, Id extends string, Options extends {}> = {
  id: string
  dataType: IViewDataTypeEnum
  dataView: UseDataViewReturnType<D, Id>
  columnDefinitions: Column<D, Id, Options>[]
  onKeyboardSelect?: (row: FilteredTreeLike<Tree<D>>) => void
  onRowClick: (row: FilteredTreeLike<Tree<D>>) => void
  dataId: (d: D) => string
  options?: Options
  containerRef?: React.RefObject<HTMLDivElement>
  cellClass?: string
  contextMenu?: (row: D) => ReactNode
}

const DataViewTable = genericReactMemo(
  <D extends Data, Id extends string, Options extends {}>(
    props: DataViewTableProps<D, Id, Options>
  ) => {
    const hasBatchSelectFeature = useFeature("batch_select")

    const { dataView, columnDefinitions } = props

    const options = useDeepMemo(() => props.options ?? ({} as Options), [props.options])
    const columns = useMemo(
      () =>
        dataView.config.columnOrder
          .map((colId) => columnDefinitions.find((c) => c.id === colId))
          .filter(Boolean) as Column<D, Id, Options>[],

      [columnDefinitions, dataView.config.columnOrder]
    )
    const { startIndex, onRangeUpdate } = useVirtuosoScrollRestoration(props.id)

    const { modal, onDelete } = useDeleteSelectedItems()

    const { getHeaderProps, getResizeHandlerProps, getColumnWidth } = useColumn<
      D,
      Id,
      Options
    >({
      id: props.id,
      toggleSortState: dataView.updateOrderBy,
    })

    const columnWidths = useMemo(() => {
      return columns
        .map((c) => getColumnWidth(c, false))
        .reduce((style, width, idx) => {
          return { ...style, [`--col-${idx}-width`]: width }
        }, {}) as React.CSSProperties
    }, [columns, getColumnWidth])

    const { data } = dataView

    const flatData = useDataViewFlatData(
      data,
      dataView.isGroupCollapsed,
      dataView.isNodeOpen
    )

    const virtuosoRef = useRef<VirtuosoHandle>(null)
    const [customScrollParent, setCustomScrollParent] = useState<HTMLElement | null>(null)

    const keyboardNavigation = useKeyboardNavigation({
      containerRef: props.containerRef,
      flatData,
      navigate: dataView.navigate,
      virtuosoRef,
      onSelect: props.onKeyboardSelect,
    })

    const onRowClick = useCallbackRef((row: FilteredTreeLike<Tree<D>>) => {
      keyboardNavigation.onClick(row)
      props.onRowClick(row)
    })

    const selectedRowDataId = keyboardNavigation.selectedRow
      ? props.dataId(keyboardNavigation.selectedRow)
      : null

    const itemContent = (_index: number, dataItem: (typeof flatData)[number]) => {
      if (!dataItem) return null

      if (dataItem.type === "header") {
        return (
          <DataViewTableHeader
            getResizeHandlerProps={getResizeHandlerProps}
            batchSelect={dataView.batchSelect}
            getHeaderProps={getHeaderProps}
            columns={columns}
            orderBy={dataView.config.orderBy}
          />
        )
      } else if (dataItem.type === "item") {
        const row = (
          <ErrorBoundary fallback={<div className="h-9 bg-white" />}>
            <DataViewTableRow
              cellClass={props.cellClass}
              row={dataItem.item}
              columns={columns}
              options={options}
              onRowClick={onRowClick}
              level={dataItem.level}
              selectedDataId={selectedRowDataId}
              dataId={props.dataId}
              contextMenu={props.contextMenu}
              getNodeDisclosure={dataView.getNodeDisclosure}
              getBatchNodeSelect={dataView.batchSelect.getNodeSelect}
            />
          </ErrorBoundary>
        )
        return row
      } else {
        return (
          <div
            onClick={() => dataView.toggleGroup(dataItem.groupId)}
            className="inset-y-[45px] z-10 flex h-11 cursor-pointer select-none items-center border-b border-gray-100 bg-gray-50 px-3 text-sm font-medium text-gray-900 hover:bg-gray-100"
            style={{
              minWidth: `calc(${columns
                .map((c) => getColumnWidth(c, false))
                .join(" + ")} )`,
            }}>
            <div className="mr-1 flex items-center self-stretch">
              <OpenCloseToggleIcon
                isOpen={!dataView.isGroupCollapsed(dataItem.groupId)}
                className="shrink-0 text-lg"
              />
            </div>
            {dataItem.label}
            <span className="ml-2 rounded border border-gray-300 px-1 text-center text-xs text-gray-500">
              {dataItem.length}
            </span>
          </div>
        )
      }
    }

    return (
      <>
        <ScrollArea
          vertical
          horizontal
          type="auto"
          className="relative h-full flex-1 overflow-auto"
          style={{ ...columnWidths }}
          ref={setCustomScrollParent}>
          {customScrollParent && (
            <Virtuoso
              customScrollParent={customScrollParent}
              className="h-full select-none"
              topItemCount={1}
              initialTopMostItemIndex={startIndex ?? 0}
              data={flatData}
              ref={virtuosoRef}
              rangeChanged={onRangeUpdate}
              itemContent={itemContent}
              overscan={40}
            />
          )}

          {/* Aggregation Footer */}
          {dataView.config.showSummation && (
            <div className="sticky bottom-0 flex h-10">
              {columns.map((c, index) => {
                const val = flatData.reduce(
                  (s, row) =>
                    row.type === "item" ? s + (c.summationValue?.(row.item) ?? 0) : s,
                  0
                )

                return (
                  <div
                    key={c.id}
                    className="relative flex shrink-0 cursor-pointer items-center overflow-hidden border-y border-gray-100 bg-white p-2 py-1.5 text-left text-sm font-medium first:rounded-tl first:border-l-0 last:rounded-tr"
                    style={{
                      width: `var(--col-${index}-width)`,
                      flexGrow: index === columns.length - 1 ? 1 : 0,
                      borderLeftWidth: c.summationValue ? 1 : 0,
                    }}>
                    {c.summationValue && (
                      <div className="flex flex-wrap items-baseline gap-x-1">
                        <div className="text-xs text-gray-600">Summe</div>
                        <div className="overflow-hidden text-ellipsis whitespace-nowrap font-medium">
                          {Number.isInteger(val) ? val : val.toFixed(2)}
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          )}
        </ScrollArea>

        {/* Batch Selection Command Center */}
        {hasBatchSelectFeature && (
          <div
            className={classNames(
              "absolute bottom-12 left-1/2 flex -translate-x-1/2 items-center justify-center rounded-full bg-white px-4 py-1 drop-shadow-2xl",
              "opacity-0 pointer-events-none transition-[opacity] hover:opacity-100",
              { "opacity-90 pointer-events-auto": dataView.batchSelect.hasSelectedItem }
            )}>
            <p className="pr-2 text-sm font-medium">
              {i18n.t("data-view:batch_select.n_items_selected", {
                count: dataView.batchSelect.selectedIds.length,
                type: i18n.t(`common:${tokenMap[props.dataType]}`, {
                  count: dataView.batchSelect.selectedIds.length,
                }),
              })}
            </p>

            <div className="mx-2 h-6 border-r border-gray-100" />

            {/* Work Orders Batch Select Actions */}
            {[IViewDataTypeEnum.Workorder, IViewDataTypeEnum.Logbook].includes(
              props.dataType
            ) && (
              <Button
                type="tertiary"
                icon={Icons.Export}
                onClick={() =>
                  exportWorkOrdersAsCSV(
                    dataView.batchSelect.getSelectedItems() as unknown as IWorkOrderFragment[]
                  )
                }>
                {i18n.t("data-view:batch_select.export_csv")}
              </Button>
            )}

            {[IViewDataTypeEnum.Workorder, IViewDataTypeEnum.Logbook].includes(
              props.dataType
            ) && (
              <Button
                type="tertiary"
                color="red"
                icon={TrashSimple}
                onClick={() => {
                  onDelete(
                    dataView.batchSelect.getSelectedItems() as unknown as IWorkOrderFragment[]
                  )
                  dataView.batchSelect.unselectAll()
                }}>
                Delete All
              </Button>
            )}

            <div className="mx-2 h-6 border-r border-gray-100" />

            <Tooltip content={i18n.t("data-view:batch_select.clear_all")} asChild>
              <Button type="tertiary" icon={X} onClick={dataView.batchSelect.unselectAll} />
            </Tooltip>
          </div>
        )}

        {modal}
      </>
    )
  }
)

export default DataViewTable
