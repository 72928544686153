// @ts-ignore-next-line
import twColors from "tailwindcss/colors"

export const colors = {
  greenDeep: "#48a75d",
  greenMedium: "#b9eabb",
  greenLight: "#eaf9e8",
  blueDark: "#042960",
  blueDeep: "#003d99",
  blueMedium: "#9dc2f9",
  blueLight: "#e6f0ff",
  yellowDeep: "#E2CD0C",
  yellowmedium: "#FDF182",
  yellowLight: "#FFFCE3",
  orangeDeep: "#ff9a3e",
  orangeMedium: "#ffc48e",
  orangeLight: "#fff2e6",
  redDark: "#F44336",
  redDeep: "#f16060",
  redMedium: "#fcbbbb",
  redLight: "#fdf0f0",
  elaraBlue: "003d99",
  dark: "#272727",
  white: "#ffffff",
  grey1: twColors.gray["900"],
  grey2: twColors.gray["700"],
  grey3: twColors.gray["500"],
  grey4: twColors.gray["400"],
  grey5: twColors.gray["200"],
  grey6: twColors.gray["100"],
  grey7: twColors.gray["50"],
}
export type Colors = typeof colors

// Only for work order categories
export const categoryColors = {
  gray: "#73777B",
  lightGray: "#C8C2BC",
  blue: "#4199fd",
  deepBlue: "#5e6ad2",
  green: "#2da815",
  deepGreen: "#0f783c",
  yellow: "#FFD24C",
  brown: "#784c2d",
  orange: "#FF9351",
  purple: "#de6fff",
  pink: "#FF74bc",
  red: "#FF3737",
}

export type CategoryColors = typeof categoryColors

export const spacing = {
  0: "0px",
  2: "2px",
  4: "4px",
  8: "8px",
  12: "12px",
  16: "16px",
  20: "20px",
  24: "24px",
  32: "32px",
  36: "36px",
  40: "40px",
  48: "48px",
  56: "56px",
  64: "64px",
  72: "72px",
  76: "76px",
}
export type SpacingValue = keyof typeof spacing

export function px(val: SpacingValue) {
  return spacing[val]
}

export function rowGap(val: keyof typeof spacing) {
  return `& > *:not(:first-child) {
    margin-left: ${px(val)} 
  }`
}

export function colGap(val: keyof typeof spacing) {
  return `& > *:not(:first-child) {
    margin-top: ${px(val)} 
  }`
}
