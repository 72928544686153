import {
  IMutationRoot,
  IMutationRootDeleteMaintenanceByPkArgs,
  IMutationRootInsertMaintenanceOneArgs,
  IMutationRootUpdateMaintenanceByPkArgs,
} from "@elara/db"
import {
  IMaintenanceFragment,
  MaintenanceFragmentDoc,
} from "@graphql/documents/fragments.generated"
import {
  IMaintenanceQuery,
  MaintenanceDocument,
} from "@graphql/documents/maintenance.generated"
import { OptimisticMutationResolver, UpdateResolver } from "@urql/exchange-graphcache"

type Mutations = Partial<Record<keyof IMutationRoot, UpdateResolver>>
type Optimistic = Partial<Record<keyof IMutationRoot, OptimisticMutationResolver>>

const optimistic: Optimistic = {
  update_maintenance_by_pk(args: IMutationRootUpdateMaintenanceByPkArgs, cache) {
    const id = args.pk_columns.id
    const template = cache.readFragment(MaintenanceFragmentDoc, { id })

    return { __typename: "maintenance", ...template, ...args._set }
  },
}

const mutations: Mutations = {
  insert_maintenance_one(
    result: { insert_maintenance_one: IMaintenanceFragment },
    _args: IMutationRootInsertMaintenanceOneArgs,
    cache
  ) {
    const maintenance = result.insert_maintenance_one

    if (!maintenance) return

    cache.updateQuery<IMaintenanceQuery>({ query: MaintenanceDocument }, (data) => {
      if (!data || !data.maintenance) return data

      if (!data.maintenance.some((t) => t.id === maintenance?.id)) {
        data.maintenance.push(maintenance)
        data.maintenance.sort((a, b) => a.name.localeCompare(b.name))
      }

      return data
    })
  },
  delete_maintenance_by_pk(_parent, args: IMutationRootDeleteMaintenanceByPkArgs, cache) {
    cache.updateQuery<IMaintenanceQuery>({ query: MaintenanceDocument }, (data) => {
      if (data) {
        data.maintenance = data.maintenance.filter((t) => t.id !== args.id)
      }

      return data
    })
  },
}

export default {
  optimistic,
  mutations,
}
