import { View } from "@components/layout"
import AssetMultiSelectDialog from "@components/select/asset-multi-select-dialog"
import ConsumableMultiSelectDialog from "@components/select/consumable-multi-select-dialog"
import { AlertDialogProvider } from "@components/shared/alert-dialog-provider"
import { ScannerDialogProvider } from "@components/shared/qrcode/scanner/scanner-dialog-provider"
import { useBreakpoint } from "@contexts/breakpoints"
import { useDisclosure } from "@hooks"
import i18n from "@i18n"
import { ArrowsClockwise, CaretDoubleDown } from "@phosphor-icons/react"
import { cn } from "@utils"
import { Outlet } from "react-router-dom"
import PullToRefresh from "react-simple-pull-to-refresh"

import PageFooter from "./page-footer"
import SidebarV3 from "./page-sidebar"

const PullingContent = (
  <div className="flex flex-col items-center justify-center gap-y-3 py-3 text-gray-600">
    <CaretDoubleDown size={24} />
    <div className="text-sm font-medium">{i18n.t("common:actions.pull_to_refresh")}</div>
  </div>
)

const RefreshingContent = (
  <div className="flex items-center justify-center py-3 text-gray-600">
    <ArrowsClockwise size={24} className="animate-spin" />
  </div>
)

const PageLayout = (props: { isInBackground?: boolean }) => {
  const menuDisclosure = useDisclosure()
  const { renderMobile } = useBreakpoint()

  const onRefresh = async () => {
    window.location.reload()
  }

  return (
    <PullToRefresh
      onRefresh={onRefresh}
      pullDownThreshold={100}
      maxPullDownDistance={120}
      isPullable={renderMobile}
      pullingContent={PullingContent}
      refreshingContent={RefreshingContent}>
      <div
        className={cn(
          "isolate flex h-full flex-1 flex-col items-stretch justify-items-stretch overflow-hidden print:overflow-visible",
          {
            "print:hidden": props.isInBackground,
          }
        )}>
        <div className="flex min-h-0 min-w-0 flex-1 flex-col">
          <div className="flex min-h-0 min-w-0 flex-1 flex-row">
            {!renderMobile && (
              <SidebarV3
                isMenuOpen={menuDisclosure.isOpen}
                onMenuClose={menuDisclosure.onClose}
              />
            )}

            <View
              flex="1"
              className="isolate z-10 overflow-y-auto print:overflow-y-visible">
              <Outlet />
            </View>
          </div>

          {renderMobile && <PageFooter />}
        </div>

        <AlertDialogProvider />
        <ScannerDialogProvider />
        <AssetMultiSelectDialog />
        <ConsumableMultiSelectDialog />
      </div>
    </PullToRefresh>
  )
}

export default PageLayout
