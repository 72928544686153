import { useBlockFileUpload } from "@contexts/file-upload-context"
import { IFileFolderInsertInput, uuid } from "@elara/db"
import {
  IFileFolderFragment,
  useAddFolderToFileFolderMutation,
} from "@graphql/documents/file-folder.generated"
import { IUploadDataFragment } from "@graphql/documents/upload.generated"
import { PermissionScopeObject } from "@hooks/use-permission-scope"
import React, { useRef } from "react"

export type Directory = {
  files: IUploadDataFragment[]
  sub_folders: Record<string, Directory>
}

export function insertUpload(dir: Directory, path: string[], upload: IUploadDataFragment) {
  if (!path.length) {
    dir.files.push(upload)
    return
  }

  const [subDirName, ...subDirPath] = path
  if (!dir.sub_folders[subDirName]) {
    dir.sub_folders[subDirName] = { files: [], sub_folders: {} } as Directory
  }

  insertUpload(dir.sub_folders[subDirName], subDirPath, upload)
}

export function createDirectoryStructure(
  blockUploadResult: { upload: IUploadDataFragment; file: File }[]
): Directory {
  const root: Directory = { files: [], sub_folders: {} }
  blockUploadResult.forEach(({ file, upload }) => {
    const path = file.webkitRelativePath.split("/").slice(0, -1)
    insertUpload(root, path, upload)
  })
  return root
}

function directoryToFileFolderInsertInput(
  dir: Directory,
  rootFolderId: uuid,
  name: string,
  id: uuid
) {
  return _directoryToFileFolderInsertInput(dir, rootFolderId, name, id)
}
function _directoryToFileFolderInsertInput(
  dir: Directory,
  rootFolderId: uuid,
  name?: string,
  id?: uuid
): IFileFolderInsertInput {
  return {
    id,
    name,
    root_folder_id: rootFolderId,
    files: {
      data: dir.files.map((file) => ({ upload_id: file.id, root_folder_id: rootFolderId })),
    },
    sub_folders: {
      data: Object.entries(dir.sub_folders).map(([name, subdir]) => {
        return _directoryToFileFolderInsertInput(subdir, rootFolderId, name)
      }),
    },
  }
}

type UseFolderUploadOptions = {
  rootFolderId: uuid
  permissionScope: PermissionScopeObject
  currentFolder?: IFileFolderFragment | null
  onUpload?: (data: IFileFolderInsertInput) => void
}

export const useFolderUpload = (options: UseFolderUploadOptions) => {
  const { rootFolderId, currentFolder } = options
  const { blockFileUpload, blockFileUploadProgress } = useBlockFileUpload()

  const [, addFolderToFileFolder] = useAddFolderToFileFolderMutation()

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return

    const acceptedFiles = Array.from(e.target.files)

    const uploads = await blockFileUpload(acceptedFiles)
    const directory = createDirectoryStructure(uploads)
    const data = directoryToFileFolderInsertInput(
      directory,
      rootFolderId,
      currentFolder?.name || "root",
      currentFolder?.id || rootFolderId
    )

    const res = await addFolderToFileFolder({ data }, options.permissionScope.context())
    if (res.error) throw res.error
  }
  const uploadFolderRef = useRef<HTMLInputElement>(null)

  const inputProps = {
    type: "file",
    style: { display: "none" },
    ref: uploadFolderRef,
    onChange: onUpload,
    webkitdirectory: "",
    directory: "",
  }

  const buttonProps = {
    onClick: () => uploadFolderRef.current?.click(),
  }

  return { onUpload, inputProps, buttonProps, blockFileUploadProgress }
}
