import { useCallback } from "react"

// Store all positions with the map location.key : scrollPosition
const positions = new Map<string, number>()

export const useVirtuosoScrollRestoration = (key: string) => {
  const onRangeUpdate = useCallback(
    ({ startIndex }: { startIndex: number }) => {
      positions.set(key, startIndex)
    },
    [key]
  )

  return { startIndex: positions.get(key), onRangeUpdate }
}

export default useVirtuosoScrollRestoration
