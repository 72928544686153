import { uuid } from "@elara/db"
import { IPermissionRoleFragment } from "@graphql/documents/fragments.generated"
import {
  IDeletePermissionRoleMutationVariables,
  IPermissionRolesQuery,
  PermissionRolesDocument,
} from "@graphql/documents/permissions.generated"
import { UpdateResolver } from "@urql/exchange-graphcache"

type Mutations = {
  [fieldName: string]: UpdateResolver
}

const mutations: Mutations = {
  // Insert
  insert_permission_role_one(
    result: { insert_permission_role_one?: IPermissionRoleFragment },
    _args,
    cache
  ) {
    const role = result.insert_permission_role_one
    if (!role) return

    cache.updateQuery<IPermissionRolesQuery>({ query: PermissionRolesDocument }, (data) => {
      if (
        !data ||
        !data.permission_role ||
        data.permission_role.some((r) => r.id === role.id)
      ) {
        return data
      }

      data.permission_role.push(role)

      return data
    })
  },
  // Delete
  delete_permission_role_by_pk(
    result: { delete_permission_role_by_pk?: { id: uuid } | null },
    args: IDeletePermissionRoleMutationVariables,
    cache
  ) {
    const id = result.delete_permission_role_by_pk?.id
    if (!id) return
    cache.invalidate({ __typename: "permission_role", id: args.id })
  },
}

export default {
  mutations,
}
