import { useBreakpoint } from "@contexts/breakpoints"
import { cn } from "@utils"
import React from "react"

export type AutoResizeTextAreaProps = {
  hasError?: boolean
  size?: "small" | "large"
  containerClassName?: string
} & Omit<JSX.IntrinsicElements["textarea"], "size">

export const AutoResizeTextArea = React.forwardRef<
  HTMLTextAreaElement,
  AutoResizeTextAreaProps
>(({ size: givenSize, hasError, containerClassName, ...props }, forwardedRef) => {
  const bp = useBreakpoint()
  const size = givenSize ?? (bp.sm ? "small" : "large")
  const ref = React.useRef<HTMLDivElement>(null)

  const className = cn(
    "border focus:ring-1 rounded px-2 align-middle resize-none col-start-1 row-start-1",
    {
      "text-base p-1": size === "large",
      "text-sm p-1": size === "small",
      "border-gray-200 focus:ring-blue-700 focus:border-blue-700 placeholder:text-gray-400  ":
        !hasError,
      "border-red-700 focus:ring-red-700 focus:border-red-700 placeholder:text-red-300":
        hasError,
    },
    props.className
  )
  const [value, setValue] = React.useState(props.value ?? props.defaultValue ?? "")
  return (
    <div className={cn("grid", containerClassName)}>
      <textarea
        {...props}
        ref={forwardedRef}
        onInput={(e) => {
          setValue(e.currentTarget.value)
        }}
        className={cn("overflow-hidden", className)}
      />
      <div ref={ref} className={cn(className, "whitespace-pre-wrap invisible")}>
        {/* The extra whitespace is not a mistake */}
        {value}{" "}
      </div>
    </div>
  )
})

export default AutoResizeTextArea
