import toast from "@components/shared/toast"
import { uuid } from "@elara/db"
import {
  IInsertUploadMutation,
  IInsertUploadMutationVariables,
  InsertUploadDocument,
  IUploadDataFragment,
} from "@graphql/documents/upload.generated"
import axios, { AxiosProgressEvent, AxiosResponse } from "axios"
import { useCallback, useLayoutEffect, useRef, useState } from "react"
import { FileUpload } from "src/types"
import { useClient } from "urql"
import { v4 } from "uuid"

import { useTaskQueue } from "./use-task-queue"
export { useEditFileNameMutation } from "@graphql/documents/upload.generated"

export interface StorageData {
  url: string
  thumbnailUrl?: string
}

type OnUploadProgressHandler = (event: AxiosProgressEvent) => void

const uploadFilesToStorage = async (
  files: File[],
  onUploadProgress?: OnUploadProgressHandler
): Promise<StorageData[]> => {
  // Prepare form data
  const form = new FormData()
  files.forEach((file) => form.append("files", file))
  // Perform upload request
  const res: AxiosResponse<StorageData[]> = await axios.request({
    url: `/api/storage/upload`,
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress,
    timeout: 120000,
    data: form,
  })

  return res.data
}

export const useLowLevelFileUpload = () => {
  const client = useClient()

  const upload = useCallback(
    async (
      file: File,
      id = v4(),
      onUploadProgress?: OnUploadProgressHandler
    ): Promise<IUploadDataFragment> => {
      const [{ url, thumbnailUrl }] = await uploadFilesToStorage([file], onUploadProgress)
      const variables = {
        url,
        thumbnailUrl,
        mimeType: file.type,
        fileName: file.name,
        fileSize: file.size,
        id,
      }
      const res = await client
        .mutation<IInsertUploadMutation, IInsertUploadMutationVariables>(
          InsertUploadDocument,
          variables
        )
        .toPromise()
      if (res.data?.insert_upload_one) {
        return res.data.insert_upload_one
      }
      throw res.error
    },
    [client]
  )
  return upload
}

const getPreviewUrl = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result as string)
    reader.onerror = () => reject()
    reader.readAsDataURL(file)
  })
}

type UseFileUploadsReturnType = {
  isUploading: boolean
  uploads: FileUpload[]
  uploadFile: (file: File, optional?: string) => Promise<uuid>
}

export const useFileUploads = (
  uploads: IUploadDataFragment[],
  options: {
    concurrentUploads?: boolean
    onChange?: (upload: IUploadDataFragment) => void
    onUploadStart?: (upload: IUploadDataFragment) => void
    onUploadFailed?: (upload: IUploadDataFragment) => void
    onUploadFinished?: (
      upload: IUploadDataFragment,
      uploads: IUploadDataFragment[]
    ) => void | Promise<void>
  } = {}
): UseFileUploadsReturnType => {
  const [activeUploads, setActiveUploads] = useState<Record<uuid, FileUpload>>({})

  const lowLevelUpload = useLowLevelFileUpload()
  const queue = useTaskQueue({ shouldProcess: true })

  const latestUploads = useRef(uploads)
  useLayoutEffect(() => {
    latestUploads.current = uploads
  })

  const uploadFile = async (file: File) => {
    const id = v4()
    const previewUrl = await getPreviewUrl(file)
    const data: IUploadDataFragment = {
      __typename: "upload",
      id,
      file_size: file.size,
      mime_type: file.type,
      file_name: file.name,
      url: previewUrl,
      thumbnail_url: previewUrl,
      created_at: new Date().toISOString(),
    }

    setActiveUploads((activeUploads) => ({
      ...activeUploads,
      [id]: { progress: 0, data },
    }))

    options.onChange?.(data)
    options.onUploadStart?.(data)

    let completeTask: () => void
    const taskCompleted = new Promise<uuid>((resolve) => {
      completeTask = () => resolve(id)
    })
    const task = async () => {
      try {
        const uploadedData = await lowLevelUpload(file, id, (e) => {
          if (!e.total) return
          const progress = e.loaded / e.total
          setActiveUploads((activeUploads) => {
            const updatedActive = { ...activeUploads }
            updatedActive[id] = { progress, data }
            return updatedActive
          })
        })
        await options.onUploadFinished?.(
          // use the original data when image since then we can use the local data and don't need to load the image
          // otherwise use the remove file name
          data.mime_type?.startsWith("image") ? data : uploadedData,
          latestUploads.current
        )
      } catch (e) {
        console.warn(e)
        toast.error("Datei konnte nicht hochgeladen werden")
        options.onUploadFailed?.(data)
      } finally {
        setActiveUploads((active) => {
          const updatedActive = { ...active }
          delete updatedActive[data.id]
          return updatedActive
        })
        completeTask()
      }
    }

    if (options.concurrentUploads) {
      queue.addTask(task)
    } else {
      task()
    }

    return taskCompleted
  }

  const fileUploads = uploads
    .map((data) => ({ progress: 1, data }))
    .concat(
      Object.values(activeUploads).filter(
        // Filter out all active uploads that are already included in uploads
        (activeUpload) => !uploads.some((u) => u.id === activeUpload.data.id)
      )
    )
    .sort((a, b) => a.data.created_at.localeCompare(b.data.created_at))

  const isUploading = fileUploads.some((u) => u.progress < 1)

  return {
    uploads: fileUploads,
    uploadFile,
    isUploading,
  }
}
