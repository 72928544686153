import { Flex } from "@components/layout"
import { uuid } from "@elara/db"
import { IConsumableGroupFragment } from "@graphql/documents/fragments.generated"
import classNames from "classnames"
import { t } from "i18next"

import ConsumableGroupActionMenu from "./action-menu"
import ConsumableGroupCreateEditForm from "./create-edit-form"

type ConsumableGroupCardProps = {
  isEditing: boolean
  onEdit: (id: uuid) => void
  onDelete: (id: uuid) => void
  onCancel: (id: uuid) => void
  consumableGroup: IConsumableGroupFragment & {
    consumables_aggregate?: { aggregate: { count: number } | null }
  }
}

const ConsumableGroupCard = (props: ConsumableGroupCardProps) => (
  <Flex
    row
    align="center"
    justify="space-between"
    className={classNames(
      "text-sm bg-gray-50 hover:text-black border border-gray-100 rounded cursor-pointer px-4 py-2 ",
      { "!py-4": props.isEditing }
    )}>
    {props.isEditing ? (
      <ConsumableGroupCreateEditForm
        id={props.consumableGroup.id}
        label={props.consumableGroup.name}
        onCancel={() => props.onCancel(props.consumableGroup.id)}
        afterSubmitSuccess={() => props.onCancel(props.consumableGroup.id)}
      />
    ) : (
      <>
        <Flex row align="center">
          <span className="mx-1 text-sm">{props.consumableGroup.name}</span>
          <span className="ml-2 text-gray-500">
            {props.consumableGroup.consumables_aggregate?.aggregate?.count}{" "}
            {t("common:consumable", {
              count: props.consumableGroup.consumables_aggregate?.aggregate?.count ?? 0,
            })}
          </span>
        </Flex>
        <ConsumableGroupActionMenu
          onEdit={() => props.onEdit(props.consumableGroup.id)}
          onDelete={() => props.onDelete(props.consumableGroup.id)}
        />
      </>
    )}
  </Flex>
)

export default ConsumableGroupCard
