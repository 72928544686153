import i18n from "@i18n"
import {
  FieldConfig,
  FieldHelperProps,
  FieldInputProps,
  FieldMetaProps,
  useField,
} from "formik"
import React from "react"

type FieldErrorConfig = {
  touched?: boolean | Record<string, boolean>
  error: string | Record<string, string> | undefined
}

type FormFieldProps<V = any> = {
  className?: string
  hint?: string | React.ReactNode
  label?: string
  name: string
  hasErrorPlaceholder?: boolean
  optional?: boolean
  noStyle?: boolean
  errorConfig?: FieldErrorConfig
  children:
    | ((props: {
        field: FieldInputProps<V>
        meta: FieldMetaProps<V>
        helpers: FieldHelperProps<V>
        hasError: boolean
      }) => React.ReactNode)
    | React.ReactNode
} & Omit<FieldConfig<V>, "children">

const checkErrorFromConfig = ({ error, touched }: FieldErrorConfig) => {
  if (!error) return false

  // touched is a boolean
  if (touched === undefined || touched === true) return true

  // touched is an object
  // every field must have a value of true
  if (typeof touched === "object" && Object.values(touched).filter(Boolean).length) {
    return true
  }

  return false
}

const formatError = (error: string | Record<string, string> | undefined): string =>
  typeof error === "string" ? error : Object.values(error ?? {})[0]

export const FormField = ({
  hasErrorPlaceholder = true,
  hint,
  label,
  name,
  optional,
  children,
  className,
  noStyle,
  errorConfig,
  ...fieldProps
}: FormFieldProps) => {
  const [field, meta, helpers] = useField({ id: name, name, ...fieldProps })

  const showError = errorConfig
    ? checkErrorFromConfig(errorConfig)
    : !!meta.error && meta.touched
  const showHint = !!hint && !showError

  const formField = React.isValidElement(children)
    ? React.cloneElement(children, { ...field, hasError: showError } as any)
    : typeof children === "function"
    ? children({ field, meta, helpers, hasError: showError })
    : children

  if (noStyle) return <>{formField}</>

  return (
    <div className={className}>
      {label && (
        <div className="mb-1 flex items-baseline justify-between text-sm">
          <label className="truncate font-medium text-gray-600" htmlFor={name}>
            {label}
          </label>
          {optional && (
            <span className="text-xs text-gray-500">{i18n.t("common:optional")}</span>
          )}
        </div>
      )}

      <div className="flex flex-col">
        {formField}
        {showError && (
          <span className="pt-1 text-xs text-red-600">
            {errorConfig ? formatError(errorConfig.error) : formatError(meta.error)}
          </span>
        )}
        {showHint && <span className="pt-1 text-xs text-gray-600">{hint}</span>}
        {!showHint && !showError && hasErrorPlaceholder && <span className="h-5 w-full" />}
      </div>
    </div>
  )
}
