import { DataView, DataViewProps } from "@components/shared/data-view/data-view"
import { createSchema } from "@components/shared/data-view/data-view-filter.hooks"
import { Column, DataViewConfiguration } from "@components/shared/data-view/data-view-types"
import { openModal } from "@contexts/modal-context"
import { IViewDataTypeEnum } from "@elara/db"
import { IContactFragment } from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import classNames from "classnames"

type ContactColumnOptions = {}

export type ContactColumnIds =
  | "company"
  | "name"
  | "position"
  | "phone"
  | "email"
  | "address"
  | "note"

const columnDefinitions: Column<
  IContactFragment,
  ContactColumnIds,
  ContactColumnOptions
>[] = [
  {
    id: "company",
    Header: i18n.t("contacts:fields.company"),
    defaultWidth: 200,
    toText: ({ company }) => company,
    Cell: ({ company }) => <span className="font-medium">{company}</span>,
    orderBy: (dir) => ({ company: dir }),
    groupBy: {
      id: ({ company }) => company,
      label: (_id, row) =>
        row?.company ??
        i18n.t("common:without_token", { token: i18n.t("contacts:fields.company") }),
    },
  },
  {
    id: "name",
    Header: i18n.t("contacts:fields.name"),
    defaultWidth: 200,
    Cell: ({ name }) => name,
    toText: ({ name }) => name,
    orderBy: (dir) => ({ name: dir }),
  },
  {
    id: "position",
    Header: i18n.t("contacts:fields.position"),
    defaultWidth: 200,
    Cell: ({ position }) => position,
    orderBy: (dir) => ({ name: dir }),
    toText: ({ position }) => position ?? "",
  },
  {
    id: "phone",
    Header: i18n.t("contacts:fields.phone"),
    defaultWidth: 200,
    toText: ({ phone }) => phone ?? "",
    Cell: ({ phone }) =>
      phone && (
        <a href={`tel:${phone}`} onClick={(e) => e.stopPropagation()} className="flex">
          <span className="truncate">{phone}</span>
        </a>
      ),
  },
  {
    id: "email",
    Header: i18n.t("contacts:fields.email"),
    defaultWidth: 200,
    toText: ({ email }) => email ?? "",
    Cell: ({ email }) =>
      email && (
        <a href={`mailto:${email}`} onClick={(e) => e.stopPropagation()} className="flex">
          <span className="truncate">{email}</span>
        </a>
      ),
  },
  {
    id: "address",
    Header: i18n.t("contacts:fields.address"),
    defaultWidth: 300,
    Cell: ({ address }) => address,
    toText: ({ address }) => address ?? "",
  },
  {
    id: "note",
    Header: i18n.t("contacts:fields.note"),
    defaultWidth: 300,
    Cell: ({ note }) => note,
    toText: ({ note }) => note ?? "",
  },
]

type Props = Omit<
  DataViewProps<IContactFragment, ContactColumnIds, ContactColumnOptions>,
  | "columnDefinitions"
  | "dataType"
  | "list_item"
  | "defaultConfig"
  | "filter"
  | "onSelect"
  | "dataId"
  | "dataSearchValue"
> &
  Partial<
    Pick<
      DataViewProps<IContactFragment, ContactColumnIds, ContactColumnOptions>,
      "defaultConfig"
    >
  >

const useFilterSchema = () => {
  const schema = createSchema<IContactFragment>({})

  return { schema }
}

export const contactDataViewDefaultConfig = {
  columnOrder: ["company", "name", "position", "phone", "email", "address", "note"],
  orderBy: [{ id: "company", dir: "asc" }],
} as DataViewConfiguration<ContactColumnIds>

const contactListItem = (contact: IContactFragment) => {
  return (
    <div className="group inline-flex flex-col rounded py-3">
      <div className="flex items-center">
        <div className="leading-loose">
          <p className="text-sm font-medium">{contact.company}</p>
          <p className="text-xs font-medium opacity-75">{contact.name}</p>
          <p className="text-xs opacity-50">{contact.position}</p>
        </div>
      </div>

      {(contact.phone || contact.email || contact.address || contact.note) && (
        <>
          <div className={classNames("flex flex-wrap gap-3 text-xs mt-2")}>
            {contact.phone && (
              <div>
                <div className="mb-0.5 font-medium opacity-75">
                  {i18n.t("contacts:fields.phone")}
                </div>
                <a href={`tel:${contact.phone}`} onClick={(e) => e.stopPropagation()}>
                  {contact.phone}
                </a>
              </div>
            )}
            {contact.email && (
              <div>
                <div className="mb-0.5 font-medium opacity-75">
                  {i18n.t("contacts:fields.email")}
                </div>
                <a href={`mailto:${contact.email}`} onClick={(e) => e.stopPropagation()}>
                  {contact.email}
                </a>
              </div>
            )}
            {contact.address && (
              <div className="mt-2 text-xs">
                <div className="mb-0.5 font-medium opacity-75">
                  {i18n.t("contacts:fields.address")}
                </div>
                <div>{contact.address}</div>
              </div>
            )}
          </div>
          {contact.note && (
            <div className="mt-2 text-xs">
              <div className="mb-0.5 font-medium opacity-75">
                {i18n.t("contacts:fields.note")}
              </div>
              <div>{contact.note}</div>
            </div>
          )}
        </>
      )}

      {!(contact.phone || contact.email || contact.address || contact.note) && (
        <div className={classNames("grid gap-2 text-xs mt-2")}>
          <p className="text-gray-500">
            {i18n.t("contacts:messages.no_contact_information")}
          </p>
        </div>
      )}
    </div>
  )
}

const ContactDataView: React.FC<Props> = ({ defaultConfig, ...props }) => {
  const filter = useFilterSchema()

  const onSelect = (item: IContactFragment) => {
    openModal("contact", { initialValues: item })
  }

  return (
    <DataView
      {...props}
      dataId={({ id }) => id}
      dataSearchValue={({ name, company, note }) => `${name} ${company} ${note ?? ""}`}
      columnDefinitions={columnDefinitions}
      dataType={IViewDataTypeEnum.Contact}
      listItem={contactListItem}
      filterSchema={filter.schema}
      searchPlaceholder={i18n.t("common:search")}
      onSelect={onSelect}
      defaultConfig={{
        columnOrder: ["company", "name", "position", "phone", "email", "address", "note"],
        orderBy: [{ id: "company", dir: "asc" }],
        ...defaultConfig,
      }}
      baseOrderBy={[{ id: "name", dir: "asc" }]}
    />
  )
}

export default ContactDataView
