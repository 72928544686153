import { ActionMenu, Button } from "@components/shared"
import { DialogForm } from "@components/shared/dialog-form"
import { DragHandle } from "@components/shared/drag-handle"
import { IconData, TeamIcon } from "@components/shared/team-icons"
import { uuid, View } from "@elara/db"
import { orderBy } from "@elara/select"
import {
  ICustomViewFragment,
  useDeleteCustomViewMutation,
} from "@graphql/documents/custom-view.generated"
import { DragDropContext, Draggable, Droppable, DropResult } from "@hello-pangea/dnd"
import i18n from "@i18n"
import { DotsThreeVertical, Plus, TrashSimple, X } from "@phosphor-icons/react"
import Icons from "@resources/icons"
import classNames from "classnames"
import { FieldArray } from "formik"
import React, { useEffect, useState } from "react"
import { Icon } from "src/types"

import { DefaultView } from "./default-views"

export const TeamViewsDialog = (props: {
  isOpen: boolean
  viewsInCollection: View[]
  customViews: ICustomViewFragment[]
  updateViewsInCollection: (views: View[]) => void
  onOpenChange: (isOpen: boolean) => void
  defaultCollectionViews: DefaultView[]
}) => {
  const [customViews, setCustomViews] = useState(props.customViews)
  const [, deleteCustomView] = useDeleteCustomViewMutation()

  const handleDelete = (id: uuid, formik: any) => {
    deleteCustomView({ id: id }).then(() => {
      const updatedCustomViews = customViews.filter((v) => v.id !== id)
      setCustomViews(updatedCustomViews)
      const updatedViews = formik.values.views.filter((view: View) => view.id !== id)
      formik.setFieldValue("views", updatedViews)
    })
  }

  useEffect(() => {
    setCustomViews(props.customViews)
  }, [props.customViews])

  return (
    <DialogForm
      title={i18n.t("views:labels.all_views")}
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
      className="lg:max-h-[75vh]"
      formikConfig={{
        initialValues: {
          views: props.viewsInCollection,
        },
        onSubmit: async (values) => {
          props.updateViewsInCollection(values.views)
        },
      }}>
      {(formik) => {
        // Shared Views
        const sharedViews = orderBy(customViews, { name: "asc" }).filter(
          (v) => !formik.values.views.find((view) => view.id === v.id) && v.shared
        )

        // Private Views
        const privateViews = orderBy(customViews, { name: "asc" }).filter(
          (v) => !formik.values.views.find((view) => view.id === v.id) && !v.shared
        )

        // Default Views
        const defaultViews = props.defaultCollectionViews
          .sort((a, b) => a.name.localeCompare(b.name))
          .filter((v) => !formik.values.views.find((view) => view.id === v.id))

        return (
          <div className="py-3 text-sm">
            <FieldArray name="views">
              {(helpers) => {
                const onDragEnd = (result: DropResult) => {
                  const startIndex = result.source.index
                  const targetIndex = result.destination?.index
                  if (result.reason === "DROP" && targetIndex !== undefined) {
                    helpers.move(startIndex, targetIndex)
                  }
                }

                return (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <h3 className="mb-2 font-medium text-gray-700">
                      {i18n.t("views:labels.selected_views")}
                    </h3>

                    {!formik.values.views.length && (
                      <p className="py-2 text-gray-500">
                        {i18n.t("common:no_token_selected_yet", {
                          token: i18n.t("common:view", { count: 2 }),
                        })}
                      </p>
                    )}

                    <Droppable droppableId="block">
                      {(droppableProvided) => (
                        <div
                          className="mr-1 flex flex-col sm:-mr-6"
                          {...droppableProvided.droppableProps}
                          ref={droppableProvided.innerRef}>
                          {formik.values.views.map(({ type, id }, index) => {
                            const item = (
                              icon: Icon | IconData,
                              name: string | undefined
                            ) => (
                              <Draggable draggableId={id} index={index} key={id}>
                                {(provided, snapshot) => (
                                  <div
                                    className={classNames(
                                      "group ml-2 sm:-mx-2 flex w-full select-none items-center rounded px-2 hover:bg-gray-100",
                                      { "bg-gray-100": snapshot.isDragging }
                                    )}
                                    ref={provided.innerRef}
                                    data-dragging={snapshot.isDragging}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}>
                                    <DragHandle className="-ml-8 -mr-0.5" />

                                    {type === "default_view" && icon && (
                                      <div className="mr-2 shrink-0">
                                        {React.createElement(icon as Icon)}
                                      </div>
                                    )}

                                    {type === "custom_view" && (
                                      <TeamIcon
                                        {...(icon as IconData)}
                                        className="mr-2 shrink-0"
                                      />
                                    )}

                                    <p className="truncate pr-2">{name}</p>
                                    {type === "default_view" && (
                                      <span className="mt-0.5 hidden text-xs text-gray-500 sm:inline">
                                        {i18n.t("views:labels.default_view")}
                                      </span>
                                    )}
                                    <div className="flex-1" />
                                    <button
                                      className={classNames(
                                        "-m-1 rounded p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-700",
                                        { "opacity-0": snapshot.isDragging }
                                      )}
                                      type="button"
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        helpers.remove(index)
                                      }}>
                                      <X size={20} />
                                    </button>
                                  </div>
                                )}
                              </Draggable>
                            )

                            if (type === "custom_view") {
                              const v = customViews.find((v) => v.id === id)
                              if (!v) return null
                              return item(v?.icon as IconData, v?.name)
                            } else {
                              const v = props.defaultCollectionViews.find(
                                (v) => v.id === id
                              )
                              return item(v?.icon as Icon, v?.name)
                            }
                          })}
                          {droppableProvided.placeholder}
                        </div>
                      )}
                    </Droppable>

                    {(customViews.length > 0 || defaultViews.length > 0) && (
                      <hr className="my-3" />
                    )}

                    {sharedViews.length > 0 && (
                      <div className="mt-3 w-full space-y-0.5">
                        <h3 className="mb-2 font-medium text-gray-700">
                          {i18n.t("views:labels.shared_views")}
                        </h3>

                        <div>
                          {sharedViews.map((v) => (
                            <div
                              className="group -ml-4 flex h-8 cursor-pointer items-center rounded pl-2 hover:bg-gray-50 sm:-ml-7 sm:-mr-3"
                              onClick={() => {
                                helpers.push({ type: "custom_view", id: v.id })
                                setCustomViews([...customViews, v])
                              }}>
                              <Plus className="mr-2 shrink-0 text-gray-300 group-hover:text-gray-400" />
                              <TeamIcon
                                {...(v.icon as IconData)}
                                className="mr-2 shrink-0"
                              />
                              <p className="truncate pr-2">{v.name}</p>
                              <div className="flex-1" />
                              <ActionMenu
                                className="self-end"
                                items={[
                                  {
                                    key: "delete",
                                    label: i18n.t("delete"),
                                    icon: <TrashSimple />,
                                    action: () => {
                                      handleDelete(v.id, formik)
                                    },
                                  },
                                ]}>
                                <Button
                                  color="gray"
                                  type="tertiary"
                                  className="-mx-1"
                                  icon={DotsThreeVertical}
                                />
                              </ActionMenu>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {privateViews.length > 0 && (
                      <div className="mt-3 w-full space-y-0.5">
                        <h3 className="mb-2 font-medium text-gray-700">
                          {i18n.t("views:labels.private_views")}
                        </h3>

                        <div>
                          {privateViews.map((v) => (
                            <div
                              className="group -ml-4 flex h-8 cursor-pointer items-center rounded pl-2 hover:bg-gray-50 sm:-ml-7 sm:-mr-3"
                              onClick={() =>
                                helpers.push({ type: "custom_view", id: v.id })
                              }>
                              <Plus className="mr-2 shrink-0 text-gray-300 group-hover:text-gray-400" />
                              <Icons.Lock className="mr-2 shrink-0" />
                              <p className="truncate pr-2">{v.name}</p>
                              <div className="flex-1" />
                              <ActionMenu
                                items={[
                                  {
                                    key: "delete",
                                    label: i18n.t("delete"),
                                    icon: <TrashSimple />,
                                    action: () => {
                                      handleDelete(v.id, formik)
                                    },
                                  },
                                ]}>
                                <Button
                                  color="gray"
                                  type="tertiary"
                                  className="-mx-1"
                                  icon={DotsThreeVertical}
                                />
                              </ActionMenu>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {defaultViews.length > 0 && (
                      <div className="mt-3 w-full space-y-0.5">
                        <h3 className="mb-2 font-medium text-gray-700">
                          {i18n.t("views:labels.default_views")}
                        </h3>

                        <div>
                          {defaultViews.map((v) => (
                            <div
                              className="group -ml-4 flex h-8 cursor-pointer items-center rounded pl-2 hover:bg-gray-50 sm:-ml-7 sm:-mr-3"
                              onClick={() =>
                                helpers.push({ type: "default_view", id: v.id })
                              }>
                              <Plus className="mr-2 shrink-0 text-gray-300 group-hover:text-gray-400" />
                              {v.icon && (
                                <div className="mr-2 shrink-0">
                                  {React.createElement(v.icon)}
                                </div>
                              )}
                              {v.name}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </DragDropContext>
                )
              }}
            </FieldArray>
          </div>
        )
      }}
    </DialogForm>
  )
}
