import { IViewDataTypeEnum } from "@elara/db"
import {
  ICustomViewFragment,
  ISaveCustomViewConfigMutation,
  ISaveCustomViewConfigMutationVariables,
  ISaveViewConfigMutation,
  ISaveViewConfigMutationVariables,
  SaveCustomViewConfigDocument,
  SaveViewConfigDocument,
  // useSaveCustomViewConfigMutation,
  // useSaveViewConfigMutation,
  useViewConfigByTypeQuery,
} from "@graphql/documents/custom-view.generated"
import {
  setStickyStateValue,
  StickyType,
  useCallbackRef,
  useDeepMemo,
  useDisclosure,
  useStickyState,
} from "@hooks"
import { dequal } from "dequal"
import React, { PropsWithChildren, useCallback, useMemo, useState } from "react"
import { useClient } from "urql"

import LoadingSpinner from "../loading-spinner"
import {
  CalendarConfig,
  CustomDataConfig,
  DataViewConfiguration,
  DataViewLayoutType,
  FilterState,
  KanbanConfig,
} from "./data-view-types"
import { DataViewUpdateCustomViewConfig } from "./data-view-update-custom-view-config"

type DataViewConfigAction<Id extends string> =
  | { type: "reset"; initialConfig: DataViewConfiguration<Id> }
  | { type: "updateColumnOrder"; columnOrder: Id[] }
  | { type: "updateGroupBy"; groupBy: Id | null }
  | { type: "updateFilters"; filters: FilterState[] }
  | { type: "updateKanban"; config: KanbanConfig }
  | { type: "updateCalendar"; config: CalendarConfig }
  | { type: "updateHistoryFilter"; historyFilter: FilterState }
  | { type: "updateShowSummation"; showSummation: boolean }
  | { type: "updateLayoutType"; layout: DataViewLayoutType }
  | { type: "updateCustomDataConfig"; config: CustomDataConfig }
  | { type: "updateOrderBy"; id: Id; dir?: "asc" | "desc"; defaultDir?: "asc" | "desc" }

function configurationReducer<Id extends string>(
  state: DataViewConfiguration<Id>,
  action: DataViewConfigAction<Id>
): DataViewConfiguration<Id> {
  switch (action.type) {
    case "reset":
      return action.initialConfig
    case "updateColumnOrder":
      return { ...state, columnOrder: action.columnOrder }
    case "updateGroupBy":
      return { ...state, groupBy: action.groupBy }
    case "updateShowSummation":
      return { ...state, showSummation: action.showSummation }
    case "updateFilters":
      return { ...state, filters: action.filters }
    case "updateKanban":
      return { ...state, kanbanConfig: action.config }
    case "updateCalendar":
      return { ...state, calendarConfig: action.config }
    case "updateHistoryFilter":
      return { ...state, historyFilter: action.historyFilter }
    case "updateLayoutType":
      return { ...state, layoutType: action.layout }
    case "updateOrderBy":
      let { orderBy } = state
      const { id, dir, defaultDir } = action
      if (dir) {
        orderBy = [{ id, dir }]
      } else if (orderBy?.[0]?.id !== id) {
        orderBy = [{ id, dir: defaultDir ?? "asc" }]
      } else if (orderBy?.[0]?.dir === "asc") {
        orderBy = [{ id, dir: "desc" }]
      } else {
        orderBy = [{ id, dir: "asc" }]
      }
      return { ...state, orderBy }
    case "updateCustomDataConfig":
      return { ...state, customDataConfig: { ...state.customDataConfig, ...action.config } }
    default:
      return state
  }
}

function useDataViewConfigActions<Id extends string>(
  changeConfig: (
    updater: (prev: DataViewConfiguration<Id>) => DataViewConfiguration<Id>
  ) => void
) {
  const dispatch = useCallback(
    (action: DataViewConfigAction<Id>) =>
      changeConfig((state) => configurationReducer(state, action)),
    []
  )
  const updateOrderBy = useCallback(
    (id: Id, dir?: "asc" | "desc") => dispatch({ type: "updateOrderBy", id, dir }),
    []
  )

  const updateGroupBy = useCallback(
    (groupBy: Id | null) => dispatch({ type: "updateGroupBy", groupBy }),
    []
  )

  const updateColumnOrder = useCallback(
    (columnOrder: Id[]) => dispatch({ type: "updateColumnOrder", columnOrder }),
    []
  )

  const updateFilters = useCallback(
    (filters: FilterState[]) => dispatch({ type: "updateFilters", filters }),
    []
  )

  const updateKanban = useCallback(
    (config: KanbanConfig) => dispatch({ type: "updateKanban", config }),
    []
  )

  const updateCalendar = useCallback(
    (config: CalendarConfig) => dispatch({ type: "updateCalendar", config }),
    []
  )

  const updateHistoryFilter = useCallback(
    (historyFilter: FilterState) =>
      dispatch({ type: "updateHistoryFilter", historyFilter }),
    []
  )
  const updateShowSummation = useCallback(
    (showSummation: boolean) => dispatch({ type: "updateShowSummation", showSummation }),
    []
  )
  const updateLayoutType = useCallback((layout: DataViewLayoutType) => {
    dispatch({ type: "updateLayoutType", layout })
  }, [])

  const updateCustomDataConfig = useCallback(
    (config: CustomDataConfig) => dispatch({ type: "updateCustomDataConfig", config }),
    []
  )

  const actions = {
    updateOrderBy,
    updateGroupBy,
    updateColumnOrder,
    updateFilters,
    updateKanban,
    updateCalendar,
    updateHistoryFilter,
    updateShowSummation,
    updateLayoutType,
    updateCustomDataConfig,
  }

  return actions
}

type DataViewConfigContextValue<Id extends string> = {
  id: string
  config: DataViewConfiguration<Id>
  resettedConfig: DataViewConfiguration<Id>
  isConfigPersisted: boolean
  storeConfig: (config?: DataViewConfiguration<Id>) => Promise<void>
  saveAsCustomView: () => void
  save: (config?: DataViewConfiguration<Id>) => void
  resetConfig: () => Promise<void>
  actions: ReturnType<typeof useDataViewConfigActions<Id>>
}

const DataViewConfigContext = React.createContext<DataViewConfigContextValue<string>>(
  undefined!
)

export function useDataViewConfigContext<Id extends string>() {
  const context = React.useContext(DataViewConfigContext)
  if (!context) {
    throw new Error("useDataViewConfigContext must be used within a DataViewConfig")
  }
  return context as unknown as DataViewConfigContextValue<Id>
}

export type DataViewConfigProps<Id extends string> = {
  customView?: ICustomViewFragment
  defaultConfig: DataViewConfiguration<Id>
  dataType: IViewDataTypeEnum
  configId: string
  configStickyness?: StickyType
  storeAllConfigChangesInCustomView?: boolean
  initialValuesForSaveAsCustomView?: Partial<ICustomViewFragment>
  onCreateView?: (customView: ICustomViewFragment) => void
  isEditView?: boolean
  onEditViewFinish?: () => void
}

function getLocalStorageId(configId: string) {
  return `DataView:Configuration:${configId}-v4`
}

export function resetDataViewConfiguration(configId: string) {
  setStickyStateValue(getLocalStorageId(configId), undefined)
}

export function DataViewConfig<Id extends string>(
  props: PropsWithChildren<DataViewConfigProps<Id>>
) {
  const [queryRes, refetchConfig] = useViewConfigByTypeQuery({
    variables: { configId: props.configId },
    requestPolicy: "cache-first",
    pause: !!props.customView,
  })
  const client = useClient()

  const [stickyConfig, setStickyConfig] = useStickyState<DataViewConfiguration<Id> | null>(
    null,
    getLocalStorageId(props.configId),
    props.configStickyness ?? "localStorage"
  )

  const memo = useDeepMemo(() => {
    let config = { ...props.defaultConfig }
    let resettedConfig = { ...props.defaultConfig }
    if (props.customView) {
      Object.assign(config, props.customView.config)
      Object.assign(resettedConfig, props.customView.config)
    } else {
      const isFetchingStoredConfig = queryRes.fetching
      if (isFetchingStoredConfig) return null

      const storedConfig = queryRes.data?.view_config?.[0]
      // If we have a stored config, we use that as the base config
      if (storedConfig) {
        Object.assign(config, storedConfig.config)
        Object.assign(resettedConfig, storedConfig.config)
      }
    }
    // If we have stored something in local storage we use that as the base config
    if (stickyConfig) {
      Object.assign(config, stickyConfig)
    }
    return { config, resettedConfig }
  }, [props.customView, queryRes, props.configId, stickyConfig])

  // We only store the actual diff to the default config to make it easier to change the default config
  const diffedConfig = useMemo(() => {
    if (!memo) return null
    const { config } = memo
    const diffedConfig = Object.keys(config).reduce((c, key_) => {
      const key = key_ as keyof DataViewConfiguration<Id>
      if (!dequal(config[key], props.defaultConfig[key])) {
        return { ...c, [key]: config[key] }
      }
      return c
    }, {} as Partial<DataViewConfiguration<Id>>)
    return diffedConfig
  }, [memo])

  const changeConfig = useCallbackRef(
    (update: (prevState: DataViewConfiguration<Id>) => DataViewConfiguration<Id>) => {
      setStickyConfig((state) => {
        if (!state) {
          if (!memo) return null
          return update(memo.config)
        }

        return update(state)
      })
    }
  )

  const storeConfig = useCallbackRef(async (config: DataViewConfiguration<Id>) => {
    if (!diffedConfig) return

    if (!props.customView) {
      await client.mutation<ISaveViewConfigMutation, ISaveViewConfigMutationVariables>(
        SaveViewConfigDocument,
        {
          id: queryRes.data?.view_config?.[0]?.id,
          dataType: props.dataType,
          config: diffedConfig,
          configId: props.configId,
        }
      )
      refetchConfig({ requestPolicy: "cache-and-network" })
    } else {
      await client.mutation<
        ISaveCustomViewConfigMutation,
        ISaveCustomViewConfigMutationVariables
      >(SaveCustomViewConfigDocument, { customViewId: props.customView.id, config })
    }
  })

  const resetConfig = useCallbackRef(async () => {
    if (!memo) return
    // Clean sticky config
    setStickyConfig(null)
  })

  const actions = useDataViewConfigActions<Id>(changeConfig)

  const dialog = useDisclosure({
    initialValue: !!props.isEditView,
    onClose: () => {
      props.isEditView && props.onEditViewFinish?.()
    },
  })

  const [updateCustomViewConfig, setUpdateCustomViewConfig] =
    useState<DataViewConfiguration<Id> | null>(
      props.isEditView ? (props.customView?.config as DataViewConfiguration<Id>) : null
    )

  const saveAsCustomView = () => {
    if (!memo?.config) return
    setUpdateCustomViewConfig(memo.config)
    dialog.onOpen()
  }

  const save = () => {
    if (props.customView || props.storeAllConfigChangesInCustomView) {
      saveAsCustomView()
    } else {
      if (!memo?.config) return
      storeConfig(memo.config)
    }
  }

  if (memo === null) return <LoadingSpinner />

  const value = {
    id: props.configId,
    config: memo.config,
    resettedConfig: memo.resettedConfig,
    isConfigPersisted: dequal(memo.config, memo.resettedConfig),
    changeConfig,
    saveAsCustomView,
    save,
    resetConfig,
    actions,
  } as unknown as DataViewConfigContextValue<string>

  return (
    <DataViewConfigContext.Provider value={value}>
      {props.children}
      {updateCustomViewConfig && (
        <DataViewUpdateCustomViewConfig
          isOpen={dialog.isOpen}
          dataType={props.dataType}
          config={updateCustomViewConfig}
          isDefaultView={props.storeAllConfigChangesInCustomView || !props.customView?.id}
          isEditView={props.isEditView}
          onOpenChange={dialog.changeOpen}
          onCreateView={props.onCreateView}
          customView={{ ...props.initialValuesForSaveAsCustomView, ...props.customView }}
        />
      )}
    </DataViewConfigContext.Provider>
  )
}
