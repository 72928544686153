import { HStack } from "@components/layout"
import { useDisclosure } from "@hooks"
import { PropsWithChildren, ReactNode, useCallback, useRef } from "react"

import Button, { ButtonProps } from "./button"
import { Dialog, DialogProps } from "./dialog"

type ConfirmModalProps<T = any> = PropsWithChildren<{
  cancelText?: ReactNode
  cancelButtonProps?: ButtonProps
  okButtonProps?: ButtonProps
  okText?: ReactNode
  onCancel?: (arg: T) => void
  onOk?: (arg: T) => void | Promise<void>
  content?: React.ReactNode | null
}> &
  Omit<DialogProps, "onOk" | "onCancel" | "children" | "content">

export const ConfirmModal = ({
  content,
  children,
  cancelText,
  cancelButtonProps,
  okButtonProps,
  okText,
  onCancel,
  onOk,
  ...otherProps
}: ConfirmModalProps) => {
  return (
    <Dialog contentAsChild {...otherProps}>
      <div className="py-4 text-sm text-gray-700">{content || children}</div>
      <HStack space={16} justify="flex-end" className="pb-4">
        {cancelText && (
          <Button type="tertiary" onClick={onCancel} {...cancelButtonProps}>
            {cancelText}
          </Button>
        )}
        {okText && (
          <Button type="primary" onClick={onOk} {...okButtonProps}>
            {okText}
          </Button>
        )}
      </HStack>
    </Dialog>
  )
}

export const useConfirmModal = <T,>(
  props: Omit<ConfirmModalProps<T>, "isOpen" | "onOpenChange">
) => {
  const confirm = useDisclosure()
  const okArg = useRef<T | null>(null)

  const show = useCallback((onOkArg?: T) => {
    okArg.current = onOkArg ?? null
    confirm.changeOpen(true)
  }, [])

  const onCancel = useCallback(() => {
    confirm.changeOpen(false)
    return props.onCancel?.(okArg.current!)
  }, [props.onCancel])
  const onOk = useCallback(() => {
    confirm.changeOpen(false)
    return props.onOk?.(okArg.current!)
  }, [props.onOk])

  const component = (
    <ConfirmModal
      {...props}
      closeIcon
      isOpen={confirm.isOpen}
      onOpenChange={confirm.changeOpen}
      onCancel={onCancel}
      onOk={onOk}
    />
  )

  return { component, show }
}

export default ConfirmModal
