import { SelectPopover, SelectPopoverProps } from "@components/shared/single-select"
import { uuid } from "@elara/db"
import { useConsumablesCompactQuery } from "@graphql/documents/consumable.generated"
import i18n from "@i18n"
import { TreeLike } from "@utils/tree"

export type SelectItem = TreeLike<{
  value: uuid
  searchValue: string
  label: JSX.Element
  children?: SelectItem[]
}>

export const useConsumableSelect = () => {
  const [queryResult] = useConsumablesCompactQuery({ requestPolicy: "cache-first" })
  const items =
    queryResult.data?.consumable?.map((c) => ({
      value: c.id,
      label: (
        <span>
          {c.name}
          <span className="ml-1 text-gray-500">{c.public_id}</span>
        </span>
      ),
      searchValue: `${c.name} ${c.public_id}`,
    })) ?? []
  return {
    items,
    valueToString: (s: string) => s,
    placeholder: i18n.t("common:search_token", {
      token: i18n.t("common:consumable", { count: 1 }),
    }),
    noDataPlaceholder: i18n.t("common:no_token_found", {
      token: i18n.t("common:consumable", { count: 2 }),
    }),
    groupBy: false,
    title: i18n.t("common:consumable", { count: 1 }),
  }
}
export type ConsumableSingleSelectProps = Partial<SelectPopoverProps<string>>
export function ConsumableSingleSelect({ ...props }: ConsumableSingleSelectProps) {
  const selectProps = useConsumableSelect()
  // eslint-disable-next-line react/jsx-no-undef
  return <SelectPopover {...selectProps} {...props} />
}
