import { useControllableState as useControllableStatePrimitive } from "@radix-ui/react-use-controllable-state"
import { Dispatch, SetStateAction } from "react"

import useCallbackRef from "./use-callback-ref"

type UseControllableStateOptions<T> = {
  prop?: T
  defaultProp?: T
  onChange?: (state: T) => void
}

export function useControllableState<T>(options: UseControllableStateOptions<T>) {
  const [state, setState] = useControllableStatePrimitive(options)
  const setStateCb = useCallbackRef((isOpen: T) => setState(isOpen))
  return [state, setStateCb] as readonly [T, Dispatch<SetStateAction<T>>]
}
