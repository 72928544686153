export const CircularProgress = (props: { size: number; progress: number }) => {
  const r = props.size / 2 - 2
  return (
    <svg className="-rotate-90" style={{ width: props.size, height: props.size }}>
      <circle
        className="text-gray-200"
        strokeWidth="2"
        stroke="currentColor"
        fill="transparent"
        r={r}
        cx={props.size / 2}
        cy={props.size / 2}
      />
      <circle
        className="text-blue-500"
        strokeWidth="2"
        strokeDasharray={2 * r * Math.PI}
        strokeDashoffset={2 * r * Math.PI - props.progress * 2 * r * Math.PI}
        strokeLinecap="round"
        stroke="currentColor"
        fill="transparent"
        r={r}
        cx={props.size / 2}
        cy={props.size / 2}
      />
    </svg>
  )
}

export default CircularProgress
