import { cn } from "@utils"

export function StaticRichText({
  content,
  ...props
}: JSX.IntrinsicElements["div"] & { content: string }) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: content }}
      {...props}
      className={cn("prose prose-sm prose-zinc", props.className)}
    />
  )
}
