import { IWorkOrderFragment } from "@graphql/documents/fragments.generated"
import { formatDate } from "@utils/date"
import { LinkWithBackgroundLocation } from "@utils/location"
import classNames from "classnames"

import { WorkOrderStatusTag } from "./work-order-status"

type Props = {
  workOrder: Pick<
    IWorkOrderFragment,
    "id" | "status" | "work_order_number" | "due_date" | "due_time" | "name"
  >
  singleLine?: boolean
  hideStatus?: boolean
  showDueDate?: boolean
}

const WorkOrderTag = ({ workOrder, singleLine, hideStatus, showDueDate }: Props) => (
  <LinkWithBackgroundLocation to={`/task/${workOrder.id}`}>
    <div className="inline-flex cursor-pointer items-center gap-2 rounded border border-transparent px-2 py-1 transition-colors hover:border-gray-200 hover:text-gray-800">
      {!hideStatus && <WorkOrderStatusTag short status={workOrder.status} />}
      <div
        className={classNames("inline-flex flex-col items-start gap-y-0.5", {
          "!flex-row-reverse gap-x-1": singleLine,
        })}>
        <p
          className={classNames("text-gray-500", {
            "text-xs leading-3": !singleLine,
          })}>
          #{workOrder.work_order_number}
        </p>
        <p className="truncate font-medium">{workOrder.name}</p>
        {!singleLine && showDueDate && workOrder.due_date && (
          <p className="text-[11px] leading-3 text-gray-500">
            {formatDate(new Date(workOrder.due_date), "PPP")}
          </p>
        )}
      </div>
    </div>
  </LinkWithBackgroundLocation>
)

export default WorkOrderTag
