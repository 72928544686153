import i18n from "@i18n"
import Icons from "@resources/icons"
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  RowData,
  Table as TableType,
  TableOptions,
  useReactTable,
} from "@tanstack/react-table"
import classNames from "classnames"
import React, { Ref, useImperativeHandle } from "react"

import ScrollArea from "./scroll-area"

declare module "@tanstack/react-table" {
  // eslint-disable-next-line unused-imports/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    className?: string
  }
}

type Props<TData extends RowData> = {
  className?: string
  noStyle?: boolean
  compact?: boolean
  options: Omit<TableOptions<TData>, "getCoreRowModel">
  growLastColumn?: boolean
}

export type TableHandle<TData extends RowData> = {
  table: TableType<TData>
}

const Table = React.forwardRef(
  <TData extends RowData>(
    { noStyle, compact, options, className, growLastColumn = true }: Props<TData>,
    forwardedRef: Ref<TableHandle<TData>>
  ) => {
    const table = useReactTable({
      ...options,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
    })

    const hasPagination = table.getPageCount() > 1

    const hasFooter = table.getFooterGroups().some((footerGroup) => {
      return footerGroup.headers.some((header) => {
        return header.column.columnDef.footer !== undefined
      })
    })

    useImperativeHandle(
      forwardedRef,
      () => ({
        table,
      }),
      [table]
    )

    return (
      <div className={className}>
        <ScrollArea
          horizontal
          vertical
          viewportAsChild
          type="auto"
          className="min-h-0 flex-1">
          <table className="text-sm">
            <thead className="sticky top-0 z-10 truncate whitespace-nowrap border-b shadow-sm">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        scope="col"
                        key={header.id}
                        className={classNames(
                          "table-cell text-left text-sm font-medium text-gray-900 first:rounded-tl last:rounded-tr whitespace-nowrap",
                          compact ? "px-3 py-1.5" : "p-3",
                          { "last:w-full": growLastColumn },
                          { "bg-gray-50": !noStyle },
                          header.column.columnDef.meta?.className
                        )}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    )
                  })}
                </tr>
              ))}
            </thead>

            <tbody className="border-b border-gray-200">
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className={classNames({ "even:bg-gray-50": !noStyle })}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={classNames(
                        "table-cell truncate",
                        compact ? "px-3 py-1.5" : "p-3"
                      )}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>

            {hasFooter && (
              <tfoot>
                {table.getFooterGroups().map((footerGroup) => (
                  <tr key={footerGroup.id}>
                    {footerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        className={classNames(
                          "sticky bottom-0 z-10 table-cell backdrop-blur-sm bg-gray-100/75 px-3 py-1.5 text-left text-sm font-medium text-gray-900"
                        )}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.footer, header.getContext())}
                      </th>
                    ))}
                  </tr>
                ))}
              </tfoot>
            )}
          </table>
        </ScrollArea>

        {hasPagination && (
          <nav className="flex items-center justify-between border-t border-gray-200 bg-white px-3 pt-1">
            <div className="hidden sm:block">
              <p className="text-sm text-gray-700">
                {i18n.t("analytics:legend.page_count", {
                  page: table.getState().pagination.pageIndex + 1,
                  numPages: table.getPageCount(),
                })}
              </p>
            </div>
            <div className="flex flex-1 justify-between space-x-4 sm:justify-end">
              <div
                onClick={table.getCanPreviousPage() ? table.previousPage : undefined}
                className={classNames(
                  "cursor-pointer relative inline-flex items-center rounded-md aspect-square",
                  "border border-gray-300 bg-white px-2 text-lg font-medium text-gray-700 hover:bg-gray-50",
                  !table.getCanPreviousPage()
                    ? "cursor-not-allowed opacity-50 hover:bg-white"
                    : ""
                )}>
                <Icons.LeftPrevious />
              </div>
              <div
                onClick={table.getCanNextPage() ? table.nextPage : undefined}
                className={classNames(
                  "cursor-pointer relative inline-flex items-center rounded-md aspect-square",
                  "border border-gray-300 bg-white px-2 text-lg font-medium text-gray-700 hover:bg-gray-50",
                  !table.getCanNextPage()
                    ? "cursor-not-allowed opacity-50 hover:bg-white"
                    : ""
                )}>
                <Icons.RightNext />
              </div>
            </div>
          </nav>
        )}
      </div>
    )
  }
)

export default Table
