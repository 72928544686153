/* eslint-disable simple-import-sort/imports */
import deLocale from "@fullcalendar/core/locales/de"
import enLocale from "@fullcalendar/core/locales/en-au"
import FullCalendar from "@fullcalendar/react"
import { EventInput } from "@fullcalendar/core"
import interactionPlugin from "@fullcalendar/interaction"
import dayGridPlugin from "@fullcalendar/daygrid"
import listPlugin from "@fullcalendar/list"
import multiMonthPlugin from "@fullcalendar/multimonth"
import rrulePlugin from "@fullcalendar/rrule"
import luxon2Plugin from "@fullcalendar/luxon2"

import { IViewDataTypeEnum } from "@elara/db"
import { Data } from "@elara/select"
import { useStickyState } from "@hooks"
import i18n from "@i18n"
import { formatDate } from "@utils/date"
import { FilteredTreeLike, Tree } from "@utils/tree"
import classNames from "classnames"
import { add, startOfDay, startOfMonth, startOfWeek, sub } from "date-fns"
import { useEffect, useMemo, useRef } from "react"

import { Icons } from ".."
import Button from "../button"
import ScrollArea from "../scroll-area"
import { ToggleGroup, ToggleGroupItem } from "../toggle-group"
import { UseDataViewReturnType } from "./data-view.hooks"
import { CalendarRange, Column } from "./data-view-types"
import { CalendarType } from "./data-view-types"
import { useLocationTimeZone } from "@contexts/user-context"

type DataViewCalendarProps<D extends Data, Id extends string, Options extends {}> = {
  id: string
  dataType: IViewDataTypeEnum
  columnDef: Column<D, Id, Options>
  dataView: UseDataViewReturnType<D, Id>

  customEvents?: (range: { from: Date; to: Date }) => EventInput[]
  // Callbacks
  onDateClick?: (key: keyof D, date: Date) => void
  onEventClick: (row: FilteredTreeLike<Tree<D>>) => void
  eventItem?: (data: D | null, e: EventInput, options?: Options) => React.ReactNode
  onDateDragDrop?: (key: keyof D, row: D, newDate: Date) => void
}

const DataViewCalendar = <D extends Data, Id extends string, Options extends {}>(
  props: DataViewCalendarProps<D, Id, Options>
) => {
  const tz = useLocationTimeZone()
  const calendarRef = useRef<FullCalendar | null>(null)

  const [cursorDate, setCursorDate] = useStickyState(
    startOfMonth(new Date()).toISOString(),
    "DataView::CalendarConfiguration::CursorDate"
  )

  const calendarConfig = useMemo(
    () => props.dataView.config.calendarConfig || {},
    [props.dataView.config.calendarConfig]
  )
  const calendarApi = useMemo(() => calendarRef.current?.getApi(), [calendarRef.current])

  const events: EventInput[] = useMemo(() => {
    const events = props.dataView.treeList.reduce((acc: EventInput[], item) => {
      if (!props.columnDef.calendarView) return acc
      acc.push({ ...props.columnDef.calendarView.event(item) })
      return acc
    }, [])

    if (props.customEvents) {
      let key = "months" as keyof Duration
      if (calendarConfig.range === "Day") {
        key = "days"
      } else if (calendarConfig.range === "Week") {
        key = "weeks"
      }

      const d = new Date(cursorDate)
      const range = {
        from: sub(d, { [key]: 1 }),
        to: add(d, { [key]: 2 }),
      }

      const customEvents = props.customEvents(range)

      return events.concat(customEvents)
    }

    return events
  }, [props.dataView.data, cursorDate, props.customEvents])

  useEffect(() => {
    if (calendarApi && calendarConfig) {
      const date = cursorDate ? new Date(cursorDate) : new Date()
      const type = calendarConfig.type || "dayGrid"
      const range = calendarConfig.range || "Month"

      setTimeout(() => {
        calendarApi.gotoDate(date)
        calendarApi.changeView(`${type}${range}`)
      }, 0)

      if (type !== calendarConfig.type || range !== calendarConfig.range) {
        props.dataView.updateCalendar({ ...calendarConfig, type, range })
      }
    }
  }, [calendarApi, calendarConfig])

  const dateString = (() => {
    const date = cursorDate ? new Date(cursorDate) : new Date()
    let dateStr = ""

    if (calendarConfig.range === "Day") {
      dateStr = formatDate(date, "PPP")
    } else if (calendarConfig.range === "Week") {
      dateStr =
        i18n.t("calendar:relative_dates.week_number", {
          week: formatDate(date, "w"),
        }) +
        ", " +
        formatDate(date, "yyyy")
    } else {
      dateStr = formatDate(date, "MMMM yyyy")
    }

    return dateStr
  })()

  const prev = () => {
    if (!calendarApi) return
    let date = cursorDate ? new Date(cursorDate) : new Date()

    if (calendarConfig.range === "Day") date = sub(startOfDay(date), { days: 1 })
    else if (calendarConfig.range === "Week") date = sub(startOfWeek(date), { weeks: 1 })
    else date = sub(startOfMonth(date), { months: 1 })

    calendarApi.gotoDate(date)
    setCursorDate(date.toISOString())
  }

  const today = () => {
    if (!calendarApi) return
    const date = new Date()

    calendarApi.gotoDate(date)
    setCursorDate(date.toISOString())
  }

  const next = () => {
    if (!calendarApi) return
    let date = cursorDate ? new Date(cursorDate) : new Date()

    if (calendarConfig.range === "Day") date = add(startOfDay(date), { days: 1 })
    else if (calendarConfig.range === "Week") date = add(startOfWeek(date), { weeks: 1 })
    else date = add(startOfMonth(date), { months: 1 })

    calendarApi.gotoDate(date)
    setCursorDate(date.toISOString())
  }

  return (
    <div className="mb-5 h-full px-3">
      <header className="mb-3 grid grid-cols-2 items-center gap-y-3 sm:grid-cols-3">
        <div className="ml-auto flex grow basis-0 items-center sm:ml-0">
          <Button
            type="secondary"
            icon={Icons.LeftPrevious}
            onClick={prev}
            className="rounded-none rounded-l"
          />
          <Button type="secondary" onClick={today} className="rounded-none border-x-0">
            {i18n.t("calendar:relative_dates.today")}
          </Button>
          <Button
            type="secondary"
            icon={Icons.RightNext}
            onClick={next}
            className="rounded-none rounded-r"
          />
        </div>

        <div className="order-first sm:order-none sm:text-center">
          <h2 className="text-lg font-medium">{dateString}</h2>
        </div>

        <div className="flex items-center gap-2 sm:ml-auto">
          <div className="flex items-center">
            <ToggleGroup
              type="single"
              value={calendarConfig.range}
              onValueChange={(range: CalendarRange) => {
                let type: CalendarType = "dayGrid"

                if (!range) return
                if (range === "Day") type = "list"
                if (range === "Year") type = "multiMonth"

                if (calendarConfig.range === "Month" && range === "Week") {
                  const today = new Date()
                  if (today.getMonth() === new Date(cursorDate).getMonth()) {
                    setCursorDate(today.toISOString())
                  }
                }

                props.dataView.updateCalendar({ ...calendarConfig, type, range })
              }}>
              <ToggleGroupItem value="Day" className="capitalize">
                {i18n.t("calendar:tokens.day", { count: 1 })}
              </ToggleGroupItem>
              <ToggleGroupItem value="Week" className="capitalize">
                {i18n.t("calendar:tokens.week", { count: 1 })}
              </ToggleGroupItem>
              <ToggleGroupItem value="Month" className="capitalize">
                {i18n.t("calendar:tokens.month", { count: 1 })}
              </ToggleGroupItem>
              <ToggleGroupItem value="Year" className="capitalize">
                {i18n.t("calendar:tokens.year", { count: 1 })}
              </ToggleGroupItem>
            </ToggleGroup>
          </div>
        </div>
      </header>

      <ScrollArea vertical>
        <FullCalendar
          firstDay={1}
          events={events}
          viewClassNames="mb-3"
          headerToolbar={false}
          locale={i18n.language}
          eventInteractive={false}
          initialDate={cursorDate}
          eventStartEditable={true}
          initialView="dayGridMonth"
          locales={[deLocale, enLocale]}
          height={`calc(100vh - 220px)`}
          ref={(ref) => {
            if (!ref) return
            calendarRef.current = ref
          }}
          defaultTimedEventDuration={{ second: 1 }}
          weekends={calendarConfig?.showWeekends ?? false}
          dayMaxEvents={calendarConfig.range === "Month" && 3}
          timeZone={tz}
          plugins={[
            luxon2Plugin,
            interactionPlugin,
            dayGridPlugin,
            listPlugin,
            rrulePlugin,
            multiMonthPlugin,
          ]}
          dayCellContent={(info) => (
            <span className="text-sm font-medium text-gray-700">{info.dayNumberText}</span>
          )}
          dayCellClassNames={classNames({
            "cursor-cell": calendarConfig.type === "dayGrid" && props.onDateClick,
          })}
          weekNumbers={
            calendarConfig?.type === "dayGrid" && calendarConfig?.range === "Month"
          }
          weekNumberContent={(info) =>
            i18n.t("calendar:relative_dates.week_number", { week: info.num })
          }
          eventDrop={(info) => {
            const id = info.event.id
            const key = props.columnDef.key
            const newDate = info.event.start
            const item = props.dataView.treeList.find((item) => item.id === id)

            if (key && item && newDate && props.onDateDragDrop) {
              props.onDateDragDrop(key, item, newDate)
            }
          }}
          dateClick={(info) => {
            if (!props.columnDef.key) return null

            props.onDateClick?.(props.columnDef.key, info.date)
          }}
          eventContent={(info) => {
            if (!props.eventItem) return null
            const id = info.event.id
            const item = props.dataView.treeList.find((item) => item.id === id) ?? null

            return props.eventItem(item, info)
          }}
          eventClick={(info) => {
            const id = info.event.id
            const item = props.dataView.treeList.find((item) => item.id === id)

            if (item) props.onEventClick(item)
          }}
        />
      </ScrollArea>
    </div>
  )
}

export default DataViewCalendar
