// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import { AssetPlaceFragmentDoc } from './fragments.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IAssetPlacesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IAssetPlacesQuery = { place: Array<{ __typename: 'place', id: uuid, name: string }> };

export type ICreateAssetPlaceMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
}>;


export type ICreateAssetPlaceMutation = { insert_place_one: { __typename: 'place', id: uuid, name: string } | null };

export type ICreateAssetPlaceManyMutationVariables = Types.Exact<{
  objects: Array<Types.IPlaceInsertInput> | Types.IPlaceInsertInput;
}>;


export type ICreateAssetPlaceManyMutation = { insert_place: { __typename: 'place_mutation_response', returning: Array<{ __typename: 'place', id: uuid, name: string }> } | null };


export const AssetPlacesDocument = gql`
    query AssetPlaces {
  place {
    ...AssetPlace
  }
}
    ${AssetPlaceFragmentDoc}`;

export function useAssetPlacesQuery(options?: Omit<Urql.UseQueryArgs<IAssetPlacesQueryVariables>, 'query'>) {
  return Urql.useQuery<IAssetPlacesQuery, IAssetPlacesQueryVariables>({ query: AssetPlacesDocument, ...options });
};
export const CreateAssetPlaceDocument = gql`
    mutation CreateAssetPlace($name: String!) {
  insert_place_one(object: {name: $name}) {
    ...AssetPlace
  }
}
    ${AssetPlaceFragmentDoc}`;

export function useCreateAssetPlaceMutation() {
  return Urql.useMutation<ICreateAssetPlaceMutation, ICreateAssetPlaceMutationVariables>(CreateAssetPlaceDocument);
};
export const CreateAssetPlaceManyDocument = gql`
    mutation CreateAssetPlaceMany($objects: [place_insert_input!]!) {
  insert_place(
    objects: $objects
    on_conflict: {constraint: place_location_id_name_key, update_columns: []}
  ) {
    returning {
      id
      name
    }
  }
}
    `;

export function useCreateAssetPlaceManyMutation() {
  return Urql.useMutation<ICreateAssetPlaceManyMutation, ICreateAssetPlaceManyMutationVariables>(CreateAssetPlaceManyDocument);
};