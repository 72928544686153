import Card from "@components/analytics/card"
import Statistic from "@components/analytics/statistic"
import { AssetGroupSingleSelect, AssetMultiSelect } from "@components/asset"
import { UserMultiSelect } from "@components/shared"
import DateRangePicker, { getRange } from "@components/shared/date-range-picker"
import ScrollArea from "@components/shared/scroll-area"
import { TeamMultiSelect } from "@components/shared/team-select"
import { ToggleGroup, ToggleGroupItem } from "@components/shared/toggle-group"
import { WorkOrderCategoryMultiSelect } from "@components/work-order/work-order-category-select"
import {
  compareWorkOrderPriority,
  priorityColor,
  priorityColors,
  translateWorkOrderPriority,
} from "@components/work-order/work-order-priority"
import { useFeature } from "@contexts/feature-flag-context"
import { IWorkOrderPriorityEnum, IWorkOrderStatusEnum, uuid } from "@elara/db"
import { groupBy } from "@elara/select"
import {
  ITaskActiveFragment,
  useTaskActiveQuery,
} from "@graphql/documents/analytics.generated"
import {
  IAssetGroupFragment,
  IAssetTagFragment,
} from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { differenceInDays } from "date-fns"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { RefObject, useImperativeHandle, useMemo, useState } from "react"
import { useOutletContext } from "react-router-dom"

import { RefPayload } from ".."

type DueDateRangeTypes = "with_due_date" | "without_due_date" | "all"

const TaskActive = () => {
  const ref = useOutletContext<RefObject<RefPayload>>()
  const hasAdvancedAnalytics = useFeature("advanced_analytics")

  const [assets, setAssets] = useState<uuid[]>([])
  const [categories, setCategories] = useState<uuid[]>([])
  const [userAssignees, setUserAssignees] = useState<uuid[]>([])
  const [teamAssignees, setTeamAssignees] = useState<uuid[]>([])
  const [assetGroup, setAssetGroup] = useState<uuid | null>(null)
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null)
  const [dueDateRange, changeDueDateRange] = useState({
    type: "with_due_date" as DueDateRangeTypes,
    dateRange: getRange("last-30-days"),
  })

  useImperativeHandle(
    ref,
    () => ({ ref: containerRef, dateRange: dueDateRange.dateRange }),
    [containerRef, dueDateRange.dateRange]
  )

  const [taskActiveRes] = useTaskActiveQuery({
    variables: {
      where: {
        ...(assets.length > 0 ? { assets: { asset_id: { _in: assets } } } : {}),
        ...(assetGroup ? { assets: { asset: { group_id: { _eq: assetGroup } } } } : {}),
        ...(categories.length > 0
          ? { categories: { work_order_category_id: { _in: categories } } }
          : {}),
        ...(userAssignees.length > 0
          ? { assignees: { user_id: { _in: userAssignees } } }
          : {}),
        ...(teamAssignees.length > 0
          ? { assigned_teams: { team_id: { _in: teamAssignees } } }
          : {}),
        ...(dueDateRange.type === "with_due_date" && dueDateRange?.dateRange
          ? {
              due_date: {
                _gte: dueDateRange?.dateRange?.start?.toISOString(),
                _lte: dueDateRange?.dateRange?.end?.toISOString(),
              },
            }
          : {}),
        ...(dueDateRange.type === "without_due_date"
          ? {
              due_date: { _is_null: true },
            }
          : {}),
        completed_at: { _is_null: true },
      },
    },
  })
  const taskActive: ITaskActiveFragment[] = taskActiveRes.data?.work_order ?? []

  const count = useMemo(() => taskActive.length, [taskActive])

  const recurringCount = useMemo(() => {
    return taskActive.filter((task) => task.recurrence_info !== null).length
  }, [taskActive])

  const reactiveCount = useMemo(() => {
    return taskActive.filter((task) => task.recurrence_info === null).length
  }, [taskActive])

  const overdueCount = useMemo(() => {
    const today = new Date().toISOString()
    return taskActive.filter((task) => task.due_date && today < task.due_date).length
  }, [taskActive])

  const averageTaskAge = useMemo(() => {
    if (taskActive.length === 0) return 0

    const taskArr = taskActive.filter((task) => task.recurrence_info === null)
    const taskDaysSum = taskArr.reduce((acc, task) => {
      return (acc += differenceInDays(new Date(), new Date(task.created_at)))
    }, 0)

    return taskArr.length ? Math.ceil(taskDaysSum / taskArr.length) : 0
  }, [taskActive])

  const assignedTasks = useMemo(() => {
    const assigneeTaskMap = taskActive.reduce((acc, task) => {
      task.assignees.forEach((assignee) => {
        if (acc[assignee.user_id]) {
          acc[assignee.user_id].push(task)
        } else {
          acc[assignee.user_id] = [task]
        }
      })

      return acc
    }, {} as Record<uuid, ITaskActiveFragment[]>)

    return Object.entries(assigneeTaskMap).map(([userId, tasks]) => {
      const user = tasks[0].assignees.find((assignee) => assignee.user_id === userId)!.user
      const username = user.first_name + " " + user.last_name

      const incompleteTasks = tasks.filter((task) => {
        return ![IWorkOrderStatusEnum.Canceled, IWorkOrderStatusEnum.Done].includes(
          task.status
        )
      })

      const averageTaskAge = incompleteTasks.length
        ? Math.ceil(
            incompleteTasks.reduce((acc, task) => {
              return (acc += differenceInDays(new Date(), new Date(task.created_at)))
            }, 0) / incompleteTasks.length
          )
        : 0

      return {
        user,
        username,
        averageTaskAge,
        incompleteCount: incompleteTasks.length,
      }
    })
  }, [taskActive])

  const assignedTasksChartOptions = useMemo<Highcharts.Options>(() => {
    return {
      chart: { type: "pie" },
      title: {
        text: i18n.t("analytics:metrics.task_active.statistics.grouped_by.assignee"),
      },
      series: [
        {
          name: "Incomplete Tasks",
          type: "pie",
          size: "60%",
          dataLabels: {
            shadow: false,
            enabled: true,
            alignTo: "plotEdges",
          },
          data: assignedTasks.map((task) => ({
            name: task.username,
            y: task.incompleteCount,
            dataLabels: {
              enabled: task.incompleteCount > 0,
            },
          })),
        },
        {
          name: "Average Task Lifespan",
          innerSize: "60%",
          type: "pie",
          dataLabels: {
            shadow: false,
            enabled: true,
            alignTo: "plotEdges",
          },
          data: assignedTasks.map((task) => ({
            name: task.username,
            y: task.averageTaskAge,
            dataLabels: {
              enabled: task.averageTaskAge > 0,
            },
          })),
        },
      ],
    }
  }, [assignedTasks])

  const groupByPriority = useMemo(() => {
    const dataArr = groupBy(taskActive, { groupId: (task) => task.priority }).sort((a, b) =>
      compareWorkOrderPriority(
        a.groupId as IWorkOrderPriorityEnum,
        b.groupId as IWorkOrderPriorityEnum
      )
    )

    return dataArr.map((x) => ({
      id: x.groupId!,
      name: translateWorkOrderPriority(x.items[0].priority),
      value: x.items.length,
    }))
  }, [taskActive])

  const groupByPriorityChartOptions = useMemo<Highcharts.Options>(() => {
    return {
      chart: { type: "pie" },
      colors: priorityColors,
      plotOptions: { pie: { showInLegend: true } },
      legend: { floating: true, align: "right", layout: "vertical" },
      title: {
        text: i18n.t("analytics:metrics.task_active.statistics.grouped_by.priority"),
      },
      series: [
        {
          name: i18n.t("common:priority", { count: 1 }),
          type: "pie",
          data: groupByPriority.map((data) => ({
            name: data.name,
            y: data.value,
            color: priorityColor(data.id as IWorkOrderPriorityEnum),
          })),
        },
      ],
    }
  }, [groupByPriority])

  const groupByAsset = useMemo(() => {
    const dataArr = taskActive.reduce((acc, task) => {
      task.assets.forEach((asset) => {
        if (acc[asset.asset_id]) {
          acc[asset.asset_id].push(task)
        } else {
          acc[asset.asset_id] = [task]
        }
      })

      return acc
    }, {} as Record<uuid, ITaskActiveFragment[]>)

    return Object.entries(dataArr)
      .map(([assetId, tasks]) => {
        const asset = tasks[0].assets.find((asset) => asset.asset_id === assetId)!
          .asset as IAssetTagFragment

        return {
          id: assetId,
          value: tasks.length,
          asset,
          assetGroup: asset.group as IAssetGroupFragment,
        }
      })
      .sort((a, b) => b.value - a.value)
  }, [taskActive])

  const groupByAssetChartOptions = useMemo<Highcharts.Options>(() => {
    return {
      chart: { type: "packedbubble" },
      title: { text: i18n.t("analytics:metrics.task_active.statistics.grouped_by.asset") },
      tooltip: {
        useHTML: true,
        pointFormat: "<b>{point.name}:</b> {point.value}",
      },
      series: groupBy(groupByAsset, {
        groupId: (row) => row.assetGroup?.id ?? null,
      }).map((data) => ({
        type: "packedbubble",
        name:
          data.items[0].assetGroup?.name ??
          i18n.t("common:no_token", { token: i18n.t("assets:fields.group", { count: 1 }) }),
        data: data.items.map((item) => ({
          name: item.asset.name,
          value: item.value,
        })),
      })),
    }
  }, [groupByAsset])

  return (
    <ScrollArea vertical>
      <div className="grid gap-4 p-4">
        <div className="max-w-full md:max-w-lg">
          <ToggleGroup
            type="single"
            value={dueDateRange.type}
            onValueChange={(type) =>
              type && changeDueDateRange((s) => ({ ...s, type: type as DueDateRangeTypes }))
            }>
            <ToggleGroupItem value="with_due_date">
              {i18n.t("analytics:filters.due_date_range")}
            </ToggleGroupItem>
            <ToggleGroupItem value="without_due_date">
              {i18n.t("analytics:filters.without_due_date")}
            </ToggleGroupItem>
            <ToggleGroupItem value="all">{i18n.t("all")}</ToggleGroupItem>
          </ToggleGroup>

          {dueDateRange.type === "with_due_date" && (
            <div className="mt-2">
              <span className="mb-1 block text-xs font-medium">
                {i18n.t("calendar:relative_dates.date_range")}
              </span>
              <DateRangePicker
                future
                onlyFixedDateRange={!hasAdvancedAnalytics}
                range={dueDateRange.dateRange}
                onRangeChange={(dateRange) =>
                  changeDueDateRange((s) => ({ ...s, dateRange }))
                }
              />
            </div>
          )}
        </div>
        <div className="grid grid-cols-1 items-start gap-4 sm:grid-cols-2 md:grid-cols-6 lg:grid-cols-8">
          <div className="col-span-2">
            <span className="mb-1 block text-xs font-medium">
              {i18n.t("assets:fields.group", { count: 1 })}
            </span>
            <AssetGroupSingleSelect value={assetGroup} onChange={setAssetGroup} />
          </div>
          <div>
            <span className="mb-1 block text-xs font-medium">
              {i18n.t("common:asset", { count: 2 })}
            </span>
            <AssetMultiSelect compact value={assets} onChange={setAssets} />
          </div>
          <div>
            <span className="mb-1 block text-xs font-medium">
              {i18n.t("tasks:fields.category", { count: 2 })}
            </span>
            <WorkOrderCategoryMultiSelect
              compact
              value={categories}
              onChange={setCategories}
            />
          </div>
          <div>
            <span className="mb-1 block text-xs font-medium">
              {i18n.t("tasks:fields.assignee", { count: 2 })}
            </span>
            <UserMultiSelect compact value={userAssignees} onChange={setUserAssignees} />
          </div>
          <div>
            <span className="mb-1 block text-xs font-medium">
              {i18n.t("common:team", { count: 2 })}
            </span>
            <TeamMultiSelect compact value={teamAssignees} onChange={setTeamAssignees} />
          </div>
        </div>

        <hr />

        <div ref={setContainerRef} className="grid gap-4">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
            <Card
              title={i18n.t("analytics:metrics.task_active.statistics.total_count.title")}>
              <Statistic>{count}</Statistic>
            </Card>

            <Card
              title={i18n.t(
                "analytics:metrics.task_active.statistics.recurring_tasks.title"
              )}>
              <Statistic>{recurringCount}</Statistic>
            </Card>

            <Card
              title={i18n.t(
                "analytics:metrics.task_active.statistics.reactive_tasks.title"
              )}>
              <Statistic>{reactiveCount}</Statistic>
            </Card>

            <Card
              title={i18n.t(
                "analytics:metrics.task_active.statistics.overdue_tasks.title"
              )}>
              <Statistic>{overdueCount}</Statistic>
            </Card>

            <Card
              title={i18n.t(
                "analytics:metrics.task_active.statistics.average_task_age.title"
              )}>
              <Statistic>{averageTaskAge}</Statistic>
            </Card>
          </div>

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
            <Card cardClassName="px-4 col-span-2 lg:col-span-2">
              <HighchartsReact
                highcharts={Highcharts}
                options={assignedTasksChartOptions}
              />
            </Card>

            <Card cardClassName="px-4 col-span-2 lg:col-span-3">
              <HighchartsReact
                highcharts={Highcharts}
                options={groupByPriorityChartOptions}
              />
            </Card>

            <Card cardClassName="px-4 col-span-2 lg:col-span-3">
              <HighchartsReact highcharts={Highcharts} options={groupByAssetChartOptions} />
            </Card>
          </div>
        </div>
      </div>
    </ScrollArea>
  )
}

export default TaskActive
