export const reorder = <T>(list: Array<T>, startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const shuffle = <T>(array: T[]): T[] => {
  let currentIndex = array.length,
    randomIndex

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
  }

  return array
}

export function calculateSortOrder(
  startIndex: number,
  targetIndex: number,
  currentSortOrder: number[]
) {
  let sortOrder = 0
  if (targetIndex === 0) {
    sortOrder = currentSortOrder[0] - (1 + Math.random() / 10)
  } else if (targetIndex === currentSortOrder.length - 1) {
    sortOrder = currentSortOrder[targetIndex] + (1 + Math.random() / 10)
  } else {
    sortOrder =
      (currentSortOrder[targetIndex + (startIndex < targetIndex ? 1 : -1)] +
        currentSortOrder[targetIndex]) /
      2
  }

  return sortOrder
}
