import { useDisclosure, useOutsideClickListener } from "@hooks"
import Icons from "@resources/icons"
import classNames from "classnames"
import React, { useCallback, useImperativeHandle, useRef } from "react"

export type SearchIconFieldProps = {
  placeholder?: string
  value?: string
  maxWidth: number
  alwaysExpanded?: boolean
  onChange?: (value: string) => void
  onBlur?: () => void
  onFocus?: () => void
}

export const SearchIconField = React.forwardRef((props: SearchIconFieldProps, ref) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef(null)

  const focus = useCallback(() => {
    inputRef.current?.focus()
    props.onFocus?.()
  }, [inputRef, props.onFocus])

  const field = useDisclosure({
    initialValue: props.alwaysExpanded || !!props.value,
    onOpen: focus,
    onClose: props.onBlur,
  })

  const closeField = () => {
    if (!props.alwaysExpanded) {
      field.onClose()
    }
  }

  useOutsideClickListener(containerRef, () => {
    if (field.isOpen && !inputRef.current?.value) {
      closeField()
    }
  })

  const blur = () => {
    if (inputRef.current) {
      inputRef.current.value = ""
      props.onChange?.("")
      closeField()
    }
    props.onBlur?.()
  }

  useImperativeHandle(ref, () => ({ focus, blur }))

  return (
    <div
      className={classNames(
        "box-border flex items-center h-8 overflow-hidden border rounded cursor-pointer text-gray-600",
        { "focus-within:border-gray-600": field.isOpen, "border-gray-300": !field.isOpen }
      )}
      ref={containerRef}>
      <div className="flex items-center justify-center p-1.5" onClick={field.toggle}>
        <Icons.Search height={20} width={20} />
      </div>
      <div
        className="flex h-full items-center overflow-hidden transition-[width]"
        style={{ width: field.isOpen ? props.maxWidth : 0, maxWidth: "100%" }}>
        <input
          className="flex-1 bg-transparent !text-sm"
          placeholder={props.placeholder}
          ref={inputRef}
          value={props.value}
          onChange={({ target: { value } }) => props.onChange?.(value)}
        />
        {(!props.alwaysExpanded || !!props.value) && (
          <Icons.Close
            className="-ml-2 mr-1"
            height={22}
            width={22}
            onClick={() => {
              if (inputRef.current) {
                const value = inputRef.current.value
                if (value) {
                  inputRef.current.value = ""
                  props.onChange?.("")
                  focus()
                } else {
                  closeField()
                }
              }
            }}
          />
        )}
      </div>
    </div>
  )
})

export default SearchIconField
