import { SelectPopover, SelectTrigger } from "@components/shared/single-select"
import { useAllowedLocations, useAuthenticationStateContext } from "@contexts/auth-context"
import { useUser } from "@contexts/user-context"
import i18n from "@i18n"
import { CaretDown } from "@phosphor-icons/react"

export function SiteSwitcher() {
  const user = useUser()
  const allowedLocations = useAllowedLocations()
  const { updateLocation } = useAuthenticationStateContext()
  return (
    <SelectPopover
      searchPlaceholder={i18n.t("navigation:sites.search_placeholder")}
      value={user.location.id}
      items={allowedLocations.map((loc) => ({
        value: loc.id,
        searchValue: loc.name,
        label: loc.name,
        disabled: !loc.approved,
      }))}
      onChange={(value) => {
        if (value) {
          updateLocation(value)
        }
      }}
      popoverContentProps={{
        sideOffset: 4,
        className: "mx-2 w-[calc(var(--radix-popover-trigger-width)-1rem)]",
      }}>
      <SelectTrigger className="group w-full" disabled={allowedLocations.length === 1}>
        <div className="mx-2 flex h-10 items-center justify-start rounded px-1 hover:bg-gray-50 hover:group-disabled:bg-white group-radix-state-open:bg-gray-100 ">
          {user.location.logo?.thumbnail_url && (
            <img
              src={user.location.logo?.thumbnail_url}
              width={32}
              height={32}
              className="mr-3"
            />
          )}
          <span className="line-clamp-2 text-left text-xs font-medium tracking-tight">
            {user.location.name}
          </span>
          {allowedLocations.length > 1 && (
            <CaretDown className="ml-auto h-4 w-4 text-gray-500" weight="bold" />
          )}
        </div>
      </SelectTrigger>
    </SelectPopover>
  )
}
