import { useAssetSelect } from "@components/asset"
import { Button } from "@components/shared"
import { ComboboxMultiSelect, ComboboxSelect } from "@components/shared/combobox-select"
import { DialogForm } from "@components/shared/dialog-form"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@components/shared/dropdown"
import { scanCode } from "@components/shared/qrcode/scanner/scanner-dialog-provider"
import {
  AssetDetailsByQrCodeDocument,
  AssetDetailsDocument,
  IAssetDetailsByQrCodeQuery,
  IAssetDetailsByQrCodeQueryVariables,
  IAssetDetailsQuery,
  IAssetDetailsQueryVariables,
  useAssetManufacturersQuery,
} from "@graphql/documents/asset.generated"
import { useAssetGroupsQuery } from "@graphql/documents/asset-group.generated"
import { useAssetPlacesQuery } from "@graphql/documents/asset-place.generated"
import { useDisclosure } from "@hooks"
import i18n from "@i18n"
import {
  DiamondsFour,
  Factory,
  FadersHorizontal,
  FunnelSimple,
  MapPin,
  Scan,
} from "@phosphor-icons/react"
import { useEffect, useState } from "react"
import { useClient } from "urql"

interface EventDetails {
  type: "single" | "multiple"
  initialValues?: { assetIds: string[] }
  onSelect: (assetIds: string[]) => void
  onCancel: () => void
}

type Filters = {
  groups: string[]
  locations: string[]
  manufacturers: string[]
}

type Options = {
  includeChildrenOnSelect: boolean
}

type OptionsProps = {
  onSelectAll: () => void
  onDeselectAll: () => void
}

export const AssetMultiSelectDialogForm = (props: {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  assetIds: string[]
  onAssetIdsChange: (ids: string[]) => void
  type?: "single" | "multiple"
}) => {
  const client = useClient()
  const [counter, setCounter] = useState(0)

  const [filters, setFilters] = useState<Filters>({
    groups: [],
    locations: [],
    manufacturers: [],
  })
  const [options, setOptions] = useState<Options>({
    includeChildrenOnSelect: false,
  })

  const [assetGroupsRes] = useAssetGroupsQuery()
  const assetGroups = assetGroupsRes.data?.asset_group ?? []

  const [assetLocationsRes] = useAssetPlacesQuery()
  const assetLocations = assetLocationsRes.data?.place ?? []

  const [assetManufacturersRes] = useAssetManufacturersQuery()
  const assetManufacturers = assetManufacturersRes.data?.asset_manufacturer ?? []

  const selectProps = useAssetSelect({
    assetIsSelectable: (asset) => {
      // Groups
      if (filters.groups.length > 0) {
        if (!asset.group_id || !filters.groups.includes(asset.group_id)) return false
      }

      // Locations
      if (filters.locations.length > 0) {
        if (!asset.place_id || !filters.locations.includes(asset.place_id)) return false
      }

      // Manufacturers
      if (filters.manufacturers.length > 0) {
        if (
          !asset.manufacturer_id ||
          !filters.manufacturers.includes(asset.manufacturer_id)
        ) {
          return false
        }
      }

      return true
    },
  })
  const { assetId, items, newAsset, onChangeDuringAssetCreation } = selectProps

  const handleScanQRCode = async () => {
    const url = await scanCode()

    if (!url) return

    const code = url.split("/").pop()
    if (!code) return

    // Check if the QR code is actually an uuid
    if (code.length === 36) {
      const assetRes = await client
        .query<IAssetDetailsQuery, IAssetDetailsQueryVariables>(AssetDetailsDocument, {
          id: code,
        })
        .toPromise()
      return assetRes.data?.asset_by_pk
    } else {
      const assetRes = await client
        .query<IAssetDetailsByQrCodeQuery, IAssetDetailsByQrCodeQueryVariables>(
          AssetDetailsByQrCodeDocument,
          { qr_code: code }
        )
        .toPromise()

      return assetRes.data?.asset?.[0]
    }
  }

  const Filters = () => (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button color="gray" type="tertiary">
          <FunnelSimple />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent>
        {/* Asset Groups */}
        {assetGroups.length > 0 && (
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              <DiamondsFour size={14} className="mr-1.5" />
              {i18n.t("assets:fields.group", { count: 2 })}
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                {assetGroups.map((group) => (
                  <DropdownMenuCheckboxItem
                    key={group.id}
                    checked={filters.groups.includes(group.id)}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        setFilters({
                          ...filters,
                          groups: [...filters.groups, group.id],
                        })
                      } else {
                        setFilters({
                          ...filters,
                          groups: filters.groups.filter((id) => id !== group.id),
                        })
                      }
                    }}>
                    {group.name}
                  </DropdownMenuCheckboxItem>
                ))}
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
        )}

        {/* Locations */}
        {assetLocations.length > 0 && (
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              <MapPin size={14} className="mr-1.5" />
              {i18n.t("assets:fields.location", { count: 2 })}
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                {assetLocations.map((location) => (
                  <DropdownMenuCheckboxItem
                    key={location.id}
                    checked={filters.locations.includes(location.id)}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        setFilters({
                          ...filters,
                          locations: [...filters.locations, location.id],
                        })
                      } else {
                        setFilters({
                          ...filters,
                          locations: filters.locations.filter((id) => id !== location.id),
                        })
                      }
                    }}>
                    {location.name}
                  </DropdownMenuCheckboxItem>
                ))}
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
        )}

        {/* Manufacturers */}
        {assetManufacturers.length > 0 && (
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              <Factory size={14} className="mr-1.5" />
              {i18n.t("assets:fields.manufacturer")}
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                {assetManufacturers.map((manufacturer) => (
                  <DropdownMenuCheckboxItem
                    key={manufacturer.id}
                    checked={filters.manufacturers.includes(manufacturer.id)}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        setFilters({
                          ...filters,
                          manufacturers: [...filters.manufacturers, manufacturer.id],
                        })
                      } else {
                        setFilters({
                          ...filters,
                          manufacturers: filters.manufacturers.filter(
                            (id) => id !== manufacturer.id
                          ),
                        })
                      }
                    }}>
                    {manufacturer.name}
                  </DropdownMenuCheckboxItem>
                ))}
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  )

  const Options = ({ onSelectAll, onDeselectAll }: OptionsProps) => (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button color="gray" type="tertiary">
          <FadersHorizontal />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent>
        <DropdownMenuItem onClick={onSelectAll}>
          {i18n.t("assets:select.options.select_all")}
        </DropdownMenuItem>
        <DropdownMenuItem onClick={onDeselectAll}>
          {i18n.t("assets:select.options.deselect_all")}
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuCheckboxItem
          checked={options.includeChildrenOnSelect}
          onCheckedChange={(checked) => {
            setOptions({ ...options, includeChildrenOnSelect: checked })
          }}>
          {i18n.t("assets:select.options.include_children")}
        </DropdownMenuCheckboxItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )

  return (
    <DialogForm
      className="sm:max-w-2xl"
      position="top"
      positionOffset={96}
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
      okText={i18n.t("common:select")}
      okProps={{ icon: undefined }}
      formikConfig={{
        initialValues: { assetIds: props.assetIds },
        onSubmit: (values) => {
          props.onAssetIdsChange(values.assetIds)
          props.onOpenChange(false)
        },
      }}>
      {(formik) => {
        const onScan = async () => {
          const asset = await handleScanQRCode()

          if (!asset) return

          formik.setFieldValue("assetIds", [...formik.values.assetIds, asset.id])
          setCounter((count) => count + 1)
        }

        if (props.type === "single") {
          return (
            <ComboboxSelect
              {...selectProps}
              key={counter}
              size="large"
              listMaxHeight={window.innerHeight}
              placeholder={i18n.t("assets:labels.search_placeholder")}
              inputProps={{
                className:
                  "flex items-center gap-2 py-0.5 pr-1 border rounded mb-4 sticky top-0 bg-white z-10",
                endAdornment: (
                  <div className="flex items-center">
                    <Button icon={Scan} color="gray" type="tertiary" onClick={onScan}>
                      {i18n.t("qrcode:actions.scan")}
                    </Button>

                    <Filters />
                  </div>
                ),
              }}
              value={formik.values.assetIds[0]}
              onChange={(value) => formik.setFieldValue("assetIds", [value])}
            />
          )
        }

        return (
          <div className="flex min-h-0 flex-1 flex-col">
            <ComboboxMultiSelect
              {...selectProps}
              key={counter}
              size="large"
              placeholder={i18n.t("assets:labels.search_placeholder")}
              listMaxHeight={window.innerHeight}
              className="mb-3"
              inputProps={{
                className:
                  "flex items-center gap-2 py-0.5 pr-1 border rounded mb-3 sticky top-0 bg-white z-10",
                endAdornment: (
                  <div className="flex items-center">
                    <Button icon={Scan} color="gray" type="tertiary" onClick={onScan}>
                      {i18n.t("qrcode:actions.scan")}
                    </Button>

                    <Options
                      onSelectAll={() => {
                        const itemIds = items.reduce((acc, item) => {
                          if (options.includeChildrenOnSelect && item.children) {
                            return [
                              ...acc,
                              item.value,
                              ...item.children.map((c) => c.value),
                            ]
                          }

                          return [...acc, item.value]
                        }, [] as string[])

                        formik.setFieldValue("assetIds", itemIds)
                      }}
                      onDeselectAll={() => {
                        formik.setFieldValue("assetIds", [])
                      }}
                    />
                    <Filters />
                  </div>
                ),
              }}
              value={formik.values.assetIds}
              items={assetId ? newAsset : items}
              onChange={(values) => {
                if (assetId) {
                  onChangeDuringAssetCreation()
                } else {
                  formik.setFieldValue("assetIds", values)

                  if (options.includeChildrenOnSelect) {
                    for (const value of values) {
                      const selectedAsset = items.find((item) => item.value === value)

                      if (selectedAsset?.children) {
                        const children = selectedAsset.children.map((child) => child.value)
                        formik.setFieldValue("assetIds", [...values, ...children])
                      }
                    }
                  }
                }
              }}
            />
          </div>
        )
      }}
    </DialogForm>
  )
}
const AssetMultiSelectDialog = () => {
  const dialog = useDisclosure()
  const [eventProps, setEventProps] = useState<EventDetails>({
    type: "multiple",
    initialValues: { assetIds: [] },
    onSelect: () => {},
    onCancel: () => {},
  })

  useEffect(() => {
    const listener = (e: Event) => {
      if (e instanceof CustomEvent && e.detail) {
        dialog.changeOpen(true)
        setEventProps(e.detail)
      }
    }

    window.addEventListener("select-asset", listener)

    return () => window.removeEventListener("select-asset", listener)
  }, [])

  return (
    <AssetMultiSelectDialogForm
      isOpen={dialog.isOpen}
      onOpenChange={dialog.changeOpen}
      assetIds={eventProps.initialValues?.assetIds ?? []}
      onAssetIdsChange={(assetIds) => {
        if (eventProps.type === "single") {
          eventProps.onSelect(assetIds.slice(0, 1))
        } else {
          eventProps.onSelect(assetIds)
        }
      }}
      type={eventProps.type}
    />
  )
}

export default AssetMultiSelectDialog

type SelectAssetProps = {
  type?: "single" | "multiple"
  initialValues?: { assetIds: string[] }
}

export const selectAsset = async (props: SelectAssetProps = {}) =>
  new Promise<string[] | null>((resolve) => {
    window.dispatchEvent(
      new CustomEvent<EventDetails>("select-asset", {
        detail: {
          type: props.type ?? "multiple",
          initialValues: props.initialValues,
          onSelect(result: string[]) {
            resolve(result)
          },
          onCancel() {
            resolve(null)
          },
        },
      })
    )
  })
