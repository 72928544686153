import { AssetTag, Button } from "@components/shared"
import { ContactTag } from "@components/shared/contact-tag"
import { DataView, DataViewProps } from "@components/shared/data-view/data-view"
import {
  createSchema,
  createSchemaStatement,
  generalSelectStateToWhere,
} from "@components/shared/data-view/data-view-filter.hooks"
import { Column, DataViewConfiguration } from "@components/shared/data-view/data-view-types"
import { Tooltip } from "@components/shared/tooltip"
import { genericAssets } from "@components/work-order/work-order-data-view-filter-configuration"
import { openModal } from "@contexts/modal-context"
import { IViewDataTypeEnum, Properties } from "@elara/db"
import { orderBy, Where } from "@elara/select"
import {
  AssetGroupsDocument,
  IAssetGroupsQuery,
} from "@graphql/documents/asset-group.generated"
import {
  AssetPlacesDocument,
  IAssetPlacesQuery,
} from "@graphql/documents/asset-place.generated"
import {
  ConsumableGroupsDocument,
  IConsumableGroupsQuery,
} from "@graphql/documents/consumable-group.generated"
import {
  IAssetGroupFragment,
  IConsumableFragment,
} from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { Gauge, MapPin, Nut, Plus } from "@phosphor-icons/react"
import { naturalCompare } from "@utils"
import { useNavigateWithBackgroundLocation } from "@utils/location"
import { useConsumableProperties } from "@utils/properties"
import classNames from "classnames"
import { useMemo } from "react"

import { ConsumableStorageLocation } from "./consumable-storage-location"
import { StockBadge } from "./stock-badge"

type ConsumableColumnOptions = {}

export type ConsumableColumnIds =
  | "consumable"
  | "public_id"
  | "group"
  | "name"
  | "status"
  | "location"
  | "description"
  | "quantity"
  | "min_quantity"
  | "metadata"
  | "contacts"
  | "assets"
  | "assetGroups"

const columnDefinitions: Column<
  IConsumableFragment,
  ConsumableColumnIds,
  ConsumableColumnOptions
>[] = [
  {
    id: "name",
    Header: i18n.t("common:name"),
    defaultWidth: 300,
    toText: (row) => row.name,
    Cell: ({ name }) => <span className="font-medium">{name}</span>,
    orderBy: (dir) => ({ name: dir }),
  },
  {
    id: "status",
    Header: i18n.t("consumables:fields.status"),
    defaultWidth: 100,
    toText: (row) => row.name,
    Cell: ({ quantity, min_quantity }) => (
      <StockBadge quantity={quantity} minQuantity={min_quantity} />
    ),
    orderBy: (dir) => ({ name: dir }),
  },
  {
    id: "public_id",
    Header: i18n.t("consumables:fields.public_id"),
    defaultWidth: 300,
    toText: (row) => row.public_id,
    Cell: ({ public_id }) => <span>{public_id}</span>,
    orderBy: (dir) => ({ public_id: dir }),
  },
  {
    id: "group",
    Header: i18n.t("consumables:fields.group", { count: 1 }),
    defaultWidth: 200,
    toText: (row) => row.group?.name ?? "",
    Cell: ({ group }) => <span>{group?.name}</span>,
    orderBy: (dir) => ({ group: { name: dir } }),
    groupBy: {
      id: ({ group }) => group?.id ?? "",
      label: (_id, row) => row?.group?.name ?? i18n.t("common:without_group"),
    },
  },
  {
    id: "consumable",
    Header: i18n.t("common:consumable", { count: 1 }),
    defaultWidth: 300,
    toText: (row) => `${row.name} ・ ${row.public_id}`,
    Cell: ({ name, avatar, public_id }) => (
      <div className="flex items-center">
        {avatar?.thumbnail_url && (
          <img src={avatar.thumbnail_url} className="mr-2 h-10 w-10 shrink-0 rounded" />
        )}
        <span className="line-clamp-2 font-medium">
          {name}
          <span className="ml-1 text-gray-500">{public_id}</span>
        </span>
      </div>
    ),
    orderBy: (dir) => ({ name: dir }),
  },
  {
    id: "location",
    Header: i18n.t("consumables:fields.location"),
    defaultWidth: 200,
    Cell: (row) => (
      <div>
        {row.storage_locations.map((location) => {
          return (
            <ConsumableStorageLocation
              storageLocation={location}
              key={location.place_id}
              hideIcon
            />
          )
        })}
      </div>
    ),
    toText: (row) => row.location ?? "",
    orderBy: (dir) => ({ location: dir }),
  },
  {
    id: "quantity",
    Header: i18n.t("consumables:fields.quantity"),
    defaultWidth: 200,
    toText: (row) =>
      (row.quantity ?? 0) > 0
        ? `${row.quantity} ${row.unit}`
        : i18n.t("consumables:labels.out_of_stock"),
    Cell: ({ quantity, unit, min_quantity }) => {
      const q = quantity ?? 0
      if (q > 0) {
        return (
          <>
            <span className={classNames({ "text-red-600": q < min_quantity })}>
              {quantity} {unit}
            </span>
          </>
        )
      }

      return (
        <span className={q < min_quantity ? "text-red-600" : "text-gray-500"}>
          {i18n.t("consumables:labels.out_of_stock")}
        </span>
      )
    },
    orderBy: (dir) => ({ quantity: dir }),
  },
  {
    id: "min_quantity",
    Header: i18n.t("consumables:fields.min_quantity"),
    defaultWidth: 200,
    toText: (row) => `${row.min_quantity} ${row.unit}`,
    Cell: ({ min_quantity, unit }) => {
      return (
        <span>
          {min_quantity} {unit}
        </span>
      )
    },
    orderBy: (dir) => ({ min_quantity: dir }),
  },
  {
    id: "contacts",
    Header: i18n.t("common:contact", { count: 2 }),
    defaultWidth: 300,
    toText: (row) => (row.contacts ? row.contacts.map((x) => x.name).join(", ") : ""),
    Cell: ({ contacts }) =>
      contacts && (
        <div className="flex items-center gap-1">
          {contacts[0] && <ContactTag compact contact={contacts[0]} />}
          <Tooltip
            content={contacts.slice(1).map((x) => (
              <p key={x.id}>{x.name}</p>
            ))}>
            <span className="ml-1">{contacts.length > 1 && `+${contacts.length - 1}`}</span>
          </Tooltip>
        </div>
      ),
  },
  {
    id: "assets",
    Header: i18n.t("common:asset", { count: 2 }),
    defaultWidth: 300,
    toText: (row) =>
      row.consumable_assets
        ? row.consumable_assets.map(({ asset }) => asset?.name).join(", ")
        : "",
    Cell: ({ consumable_assets: assets }) =>
      assets && (
        <div className="flex items-center gap-1">
          {assets[0]?.asset && <AssetTag asset={assets[0].asset} />}
          <Tooltip
            content={assets.slice(1).map(({ asset }) => (
              <p key={asset?.id}>{asset?.name}</p>
            ))}>
            <span className="ml-1">{assets.length > 1 && `+${assets.length - 1}`}</span>
          </Tooltip>
        </div>
      ),
    groupBy: {
      id: (row) => row.consumable_assets?.map(({ asset }) => asset?.id) ?? null,
      label: (id, row) =>
        row?.consumable_assets?.find(({ asset }) => asset?.id === id)?.asset?.name ??
        i18n.t("common:without_token", { token: i18n.t("common:asset", { count: 1 }) }),
    },
  },
  {
    id: "assetGroups",
    Header: i18n.t("assets:fields.group", { count: 2 }),
    disableSortBy: true,
    toText: (row) => {
      // We want to show all existing groups, in ascending order without duplicates
      const groups = row.consumable_assets
        ?.map(({ asset }) => asset?.group)
        ?.filter(Boolean) as IAssetGroupFragment[]
      // filter unique. Not the most efficient approach but the array is anyway very small
      const label = groups
        .filter(({ id }, i) => groups.findIndex((g) => g.id === id) === i)
        .sort((a, b) => naturalCompare(a.name, b.name))
        .map((g) => g.name)
        .join(", ")
      return label
    },
    Cell: (row) => {
      // We want to show all existing groups, in ascending order without duplicates
      const groups = row.consumable_assets
        ?.map(({ asset }) => asset?.group)
        ?.filter(Boolean) as IAssetGroupFragment[]
      // filter unique. Not the most efficient approach but the array is anyway very small
      const label = groups
        .filter(({ id }, i) => groups.findIndex((g) => g.id === id) === i)
        .sort((a, b) => naturalCompare(a.name, b.name))
        .map((g) => g.name)
        .join(", ")
      return label
    },
    groupBy: {
      id: (row) =>
        row.consumable_assets
          ?.map(({ asset }) => asset?.group?.id)
          .filter(Boolean) as string[],
      label: (id, row) => {
        if (id) {
          return (
            row?.consumable_assets?.find(({ asset }) => asset?.group?.id === id)?.asset
              ?.group?.name ?? ""
          )
        }
        return i18n.t("common:without_token", {
          token: i18n.t("assets:fields.group", { count: 1 }),
        })
      },
    },
  },
]

type Props = Omit<
  DataViewProps<IConsumableFragment, ConsumableColumnIds, ConsumableColumnOptions>,
  | "columnDefinitions"
  | "dataType"
  | "list_item"
  | "defaultConfig"
  | "filter"
  | "onSelect"
  | "dataId"
  | "dataSearchValue"
> &
  Partial<
    Pick<
      DataViewProps<IConsumableFragment, ConsumableColumnIds, ConsumableColumnOptions>,
      "defaultConfig"
    >
  >

export const consumableDataViewDefaultConfig = {
  columnOrder: [
    "consumable",
    "location",
    "status",
    "quantity",
    "min_quantity",
    "group",
    "assets",
    "contacts",
  ],
  orderBy: [{ id: "name", dir: "asc" }],
} as DataViewConfiguration<ConsumableColumnIds>

const useFilterSchema = () => {
  const schema = createSchema<IConsumableFragment>({
    low_stock: createSchemaStatement<IConsumableFragment, string>({
      type: "singleton",
      label: i18n.t("consumables:filters.low_stock"),
      badgeLabel: i18n.t("consumables:fields.quantity"),
      toWhere: () => ({ low_stock_since: { _is_null: false } }),
    }),
    assets: genericAssets<IConsumableFragment>(
      (state) =>
        generalSelectStateToWhere(["consumable_assets", "asset", "id"], state, {
          isOneToMany: true,
        }) as Where<IConsumableFragment>
    ),
    assetGroups: createSchemaStatement<IConsumableFragment, string>({
      type: "select",
      label: i18n.t("assets:fields.group", { count: 1 }),
      multiSelectedLabel: i18n.t("assets:fields.group", { count: 2 }),
      toWhere: (state) =>
        generalSelectStateToWhere(["consumable_assets", "asset", "group", "id"], state, {
          isOneToMany: true,
        }) as Where<IConsumableFragment>,
      getItems: async (client) => {
        const queryRes = await client.query<IAssetGroupsQuery>(
          AssetGroupsDocument,
          {},
          {
            requestPolicy: "cache-first",
          }
        )
        const groups = queryRes?.data?.asset_group
        if (!groups) return null
        return orderBy(groups, { name: "asc" }).map((a) => ({
          label: a.name,
          value: a.id,
          searchValue: a.name,
        }))
      },
    }),
    group: createSchemaStatement<IConsumableFragment, string>({
      type: "select",
      label: i18n.t("consumables:fields.group", { count: 1 }),
      multiSelectedLabel: i18n.t("consumables:fields.group", { count: 2 }),
      toWhere: (state) =>
        generalSelectStateToWhere(["group", "id"], state) as Where<IConsumableFragment>,
      getItems: async (client) => {
        const queryRes = await client.query<IConsumableGroupsQuery>(
          ConsumableGroupsDocument,
          {},
          {
            requestPolicy: "cache-first",
          }
        )
        const groups = queryRes?.data?.consumable_group
        if (!groups) return null
        return orderBy(groups, { name: "asc" }).map((a) => ({
          label: a.name,
          value: a.id,
          searchValue: a.name,
        }))
      },
    }),
    place: createSchemaStatement<IConsumableFragment, string>({
      type: "select",
      label: i18n.t("common:location_one"),
      multiSelectedLabel: i18n.t("location_other"),
      toWhere: (state) =>
        generalSelectStateToWhere(["storage_locations", "place_id"], state, {
          isOneToMany: true,
        }) as Where<IConsumableFragment>,
      getItems: async (client) => {
        const queryRes = await client.query<IAssetPlacesQuery>(
          AssetPlacesDocument,
          {},
          {
            requestPolicy: "cache-first",
          }
        )
        const groups = queryRes?.data?.place
        if (!groups) return null
        return orderBy(groups, { name: "asc" }).map((a) => ({
          label: a.name,
          value: a.id,
          searchValue: a.name,
        }))
      },
    }),
  })

  return { schema }
}

const ConsumableListItem = (consumable: IConsumableFragment): React.ReactNode => (
  <div className="flex flex-col gap-1 py-2">
    <p className="text-base font-medium">
      {consumable.name}
      <span className="ml-1 font-normal text-gray-500">{consumable.public_id}</span>
    </p>

    {(!!consumable.location || !!consumable.quantity || consumable.min_quantity > 0) && (
      <div className="my-1 flex flex-wrap gap-x-4 gap-y-2 text-sm">
        {consumable.location ? (
          <div className="flex items-center gap-1 border-l py-1 pl-2">
            <MapPin className="text-xs opacity-75" />{" "}
            <span>
              <span className="font-medium">{i18n.t("consumables:fields.location")}</span>{" "}
              {consumable.location}
            </span>
          </div>
        ) : null}

        {consumable.quantity !== null ? (
          <div className="flex items-center gap-1 border-l py-1 pl-2">
            <Nut className="text-xs opacity-75" />{" "}
            <span>
              <span className="font-medium">{i18n.t("consumables:fields.quantity")}</span>{" "}
              {consumable.quantity} {consumable.unit}
            </span>
          </div>
        ) : null}

        {consumable.min_quantity ? (
          <div className="flex items-center gap-1 border-l py-1 pl-2">
            <Gauge className="text-xs opacity-75" />{" "}
            <span>
              <span className="font-medium">
                {i18n.t("consumables:fields.min_quantity")}
              </span>{" "}
              {consumable.min_quantity} {consumable.unit}
            </span>
          </div>
        ) : null}
      </div>
    )}

    {consumable.description && <p>{consumable.description}</p>}
  </div>
)

export const ConsumableDataView: React.FC<Props> = ({ defaultConfig, ...props }) => {
  const filter = useFilterSchema()
  const properties = useConsumableProperties()

  const columns = useMemo(() => {
    return columnDefinitions.concat(
      properties?.map((p) => ({
        id: p.id as ConsumableColumnIds,
        Header: p.name,
        defaultWidth: 200,
        toText: (row) => (row.properties as Properties)?.[p.id]?.value?.toString() ?? "",
        orderBy: (dir) => ({ properties: { [p.id]: { value: dir } } }),
        Cell: ({ properties }) => {
          const value = (properties as Properties)?.[p.id]?.value ?? null
          if (typeof value === "string" && value.startsWith("http")) {
            return (
              <a
                href={value}
                target="_blank"
                rel="noreferrer"
                onClick={(e) => e.stopPropagation()}>
                {value}
              </a>
            )
          }
          return value
        },
      })) ?? []
    )
  }, [properties])

  const navigateWithBackgroundLocation = useNavigateWithBackgroundLocation()

  const onSelect = (item: IConsumableFragment) => {
    navigateWithBackgroundLocation(`/consumable/${item.id}`)
  }

  return (
    <DataView
      {...props}
      dataId={({ id }) => id}
      dataSearchValue={({ name, public_id, properties }) => {
        let searchString = `${name} ${public_id} `
        if (properties) {
          searchString += Object.entries(properties as Properties)
            .filter(([_, prop]) => prop.value !== null && prop.value !== "")
            .map(([_, prop]) => prop.value)
            .join(" ")
        }
        return searchString.trim()
      }}
      columnDefinitions={columns}
      dataType={IViewDataTypeEnum.Consumable}
      filterSchema={filter.schema}
      searchPlaceholder={i18n.t("common:search_token", {
        token: i18n.t("common:consumable", { count: 1 }),
      })}
      listItem={ConsumableListItem}
      onSelect={onSelect}
      defaultConfig={{
        ...consumableDataViewDefaultConfig,
        ...defaultConfig,
      }}
      baseOrderBy={[{ id: "name", dir: "asc" }]}
      noData={{
        icon: Nut,
        title: (
          <span className="mr-auto inline-block w-full">
            {i18n.t("common:no_token_created_yet", {
              token: i18n.t("common:consumable", { count: 2 }),
            })}
          </span>
        ),
        maxWidth: 600,
        message: <>{i18n.t("consumables:messages.empty_state.description1")}</>,
        actions: (
          <Button
            size="small"
            type="secondary"
            className="mt-4"
            icon={Plus}
            onClick={() => openModal("consumable")}>
            {i18n.t("common:new_token", {
              token: i18n.t("common:consumable", { count: 1 }),
            })}
          </Button>
        ),
      }}
    />
  )
}

export default ConsumableDataView
