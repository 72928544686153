import { DialogRootProps } from "@components/shared/dialog"
import { DialogForm } from "@components/shared/dialog-form"
import { FormField } from "@components/shared/form/form-field"
import { TextInput } from "@components/shared/text-input"
import toast from "@components/shared/toast"
import { useBreakpoint } from "@contexts/breakpoints"
import { IAssetInsertInput } from "@elara/db"
import { useInsertAssetMutation } from "@graphql/documents/asset.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { PropsWithChildren } from "react"
import { Trans } from "react-i18next"
import { Link } from "react-router-dom"

import { AssetGroupSingleSelect } from "./asset-group-select"
import { useAssetValidationSchema } from "./asset-information-form-dialog"
import { AssetPlaceSingleSelect } from "./asset-place-select"

export type AssetCreateFormDialogProps = PropsWithChildren<
  {
    title?: string
    initialValues?: IAssetInsertInput
    parentAssetId?: string
  } & Pick<DialogRootProps, "isOpen" | "onOpenChange">
>

export const AssetCreateFormDialog = (props: AssetCreateFormDialogProps) => {
  const bp = useBreakpoint()
  const size = bp.sm ? "small" : "large"

  const [, insertAsset] = useInsertAssetMutation()
  const { context } = usePermissionScope(IPermissionScopeEnum.AppAssetCreate)

  const validationSchema = useAssetValidationSchema(null)

  return (
    <DialogForm
      title={
        props.title ??
        i18n.t("common:create_token", {
          token: i18n.t("common:asset", { count: 1 }),
        })
      }
      trigger={props.children}
      formikConfig={{
        initialValues: props.initialValues ?? {
          name: "",
          group_id: null as string | null,
          place_id: null as string | null,
          public_id: "",
        },
        validationSchema,
        validateOnBlur: true,
        validateOnChange: false,
        onSubmit: async (values) => {
          const res = await insertAsset(
            {
              data: {
                name: values.name,
                group_id: values.group_id,
                place_id: values.place_id,
                public_id: values.public_id || undefined,
                parent_asset_id: props.parentAssetId,
              },
            },
            context()
          )

          if (!res.data?.insert_asset_one || res.error) {
            toast.error(
              i18n.t("common:messages.token_create_failure", {
                token: i18n.t("common:asset", { count: 1 }),
              })
            )
            throw new Error(
              i18n.t("common:messages.token_create_failure", {
                token: i18n.t("common:asset", { count: 1 }),
              })
            )
          }

          toast.success({
            title: i18n.t("common:messages.token_create_success", {
              token: i18n.t("common:asset", { count: 1 }),
            }),
            body: (
              <Trans i18n={i18n} i18nKey="common:open_item" values={{ item: values.name }}>
                <Link
                  to={`/object/${res.data?.insert_asset_one?.id}`}
                  className="text-blue-500 hover:text-blue-700">
                  Artikel
                </Link>
                öffnen
              </Trans>
            ),
            params: {
              duration: 5000,
              position: bp.mobile ? "top-center" : "bottom-right",
            },
          })
        },
      }}
      isOpen={props.isOpen}
      okText={i18n.t("common:create_token", {
        token: i18n.t("common:asset", { count: 1 }),
      })}
      cancelText={i18n.t("common:cancel")}
      onOpenChange={props.onOpenChange}
      className="md:w-full md:max-w-md md:self-center">
      {() => (
        <div className="grid grid-cols-1 gap-x-10 gap-y-1 py-4">
          <FormField label={i18n.t("assets:fields.name")} name="name">
            <TextInput size={size} autoComplete="nofill" autoFocus />
          </FormField>

          <FormField label={i18n.t("assets:fields.public_id")} name="public_id">
            <TextInput
              placeholder={i18n.t("common:automatically_generated")}
              autoComplete="nofill"
            />
          </FormField>

          <FormField
            label={i18n.t("assets:fields.group", { count: 1 })}
            name="group_id"
            optional>
            {({ field, helpers }) => (
              <AssetGroupSingleSelect
                {...field}
                isClearable
                onChange={(val) => helpers.setValue(val)}
                size={size}
              />
            )}
          </FormField>

          <FormField
            label={i18n.t("assets:fields.location", { count: 1 })}
            name="place_id"
            optional>
            {({ field, helpers }) => (
              <AssetPlaceSingleSelect
                {...field}
                onChange={(val) => helpers.setValue(val)}
                size={size}
              />
            )}
          </FormField>

          <p className="text-sm text-gray-500">
            {i18n.t("assets:messages.further_fields")}
          </p>
        </div>
      )}
    </DialogForm>
  )
}

export default AssetCreateFormDialog
