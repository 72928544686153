import HorizontalTabs from "@components/shared/horizontal-tabs"
import i18n from "@i18n"
import { Outlet, useMatch, useNavigate } from "react-router-dom"

const SettingsUserManagement = () => {
  const match = useMatch("/settings/user-management/:page")
  const page = match?.params.page ?? "users"
  const navigate = useNavigate()

  return (
    <HorizontalTabs.Root
      className="w-full"
      value={page}
      onValueChange={(page) => navigate(`/settings/user-management/${page}`)}>
      <HorizontalTabs.List>
        <HorizontalTabs.Trigger value="users">
          {i18n.t("settings:user_management.users.title")}
        </HorizontalTabs.Trigger>
        <HorizontalTabs.Trigger value="invites">
          {i18n.t("settings:user_management.invites.title")}
        </HorizontalTabs.Trigger>
        <HorizontalTabs.Trigger value="permissions">
          {i18n.t("settings:user_management.permissions.title")}
        </HorizontalTabs.Trigger>
      </HorizontalTabs.List>
      <Outlet />
    </HorizontalTabs.Root>
  )
}

export default SettingsUserManagement
