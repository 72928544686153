import { useConfirmModal } from "@components/shared"
import toast from "@components/shared/toast"
import { BlockGroupFormHandle } from "@components/work-order/block/block-group-form"
import { useAnalytics } from "@contexts/analytics-context"
import { useOpenModal } from "@contexts/modal-context"
import { usePage } from "@contexts/page-context"
import { useUser } from "@contexts/user-context"
import { IWorkOrderStatusEnum, IWorkOrderTypeEnum, uuid } from "@elara/db"
import {
  IWorkOrderFragment,
  IWorkOrderTemplateFragment,
} from "@graphql/documents/fragments.generated"
import { useMaintenanceTriggerTaskQuery } from "@graphql/documents/maintenance.generated"
import { useTeamsQuery } from "@graphql/documents/team.generated"
import {
  IUpdateWorkOrderPartiallyMutation,
  IUpdateWorkOrderPartiallyMutationVariables,
  UpdateWorkOrderPartiallyDocument,
  useCreateWorkOrderTemplateMutation,
  useInsertWorkOrderMutation,
  useTaskDataViewByPkQuery,
  useUpdateWorkOrderPartiallyMutation,
  useWorkOrderDetailsQuery,
} from "@graphql/documents/work-order.generated"
import { IPermissionScopeEnum, useDisclosure, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { add } from "date-fns"
import { useFormik } from "formik"
import { useMemo, useRef } from "react"
import { useClient } from "urql"
import { v4 } from "uuid"

import { cleanBlockElement } from "./block/block-element-form"
import { BlockElementFragment } from "./block/elements/block-element-types"
import {
  getInitialWorkOrderFormValues,
  valuesToWorkOrderInsertInput,
} from "./create-task-form.hooks"
import { WorkOrderSelectDialog } from "./work-order-select-dialog"

export const useConvertTaskToTemplate = () => {
  const user = useUser()

  const [teamsQueryRes] = useTeamsQuery({ requestPolicy: "cache-first" })
  const allowedTeamIds = teamsQueryRes?.data?.team?.map((t) => t.id) ?? []

  const [, createWorkOrder] = useInsertWorkOrderMutation()
  const [, createTemplate] = useCreateWorkOrderTemplateMutation()

  const createScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderCreate)

  return async (workOrder: IWorkOrderFragment) => {
    const initialValues = workOrderToTemplate(workOrder)
    const formValues = getInitialWorkOrderFormValues(
      initialValues,
      allowedTeamIds,
      user.id,
      { cleanBlockElementIds: true }
    )
    const workOrderData = valuesToWorkOrderInsertInput(formValues, {
      includeBlockElements: true,
    })

    const createWorkOrderRes = await createWorkOrder(
      {
        data: {
          ...workOrderData,
          id: v4(),
          type: IWorkOrderTypeEnum.Template,
        },
      },
      createScope.context()
    )

    const createTemplateRes = await createTemplate(
      {
        data: {
          name: `${workOrder.name} (Vorlage)`,
          description: workOrder.description,
          work_order_id: createWorkOrderRes.data?.insert_work_order_one?.id,
        },
      },
      createScope.context()
    )
    const template = createTemplateRes.data
      ?.insert_work_order_template_one as IWorkOrderTemplateFragment

    return template
  }
}

type UseDeleteWorkOrderModal = {
  workOrderId?: uuid
  isTemplate?: boolean
  afterDelete?: () => void
}

export const useDeleteWorkOrder = ({
  workOrderId,
  isTemplate,
  afterDelete,
}: UseDeleteWorkOrderModal) => {
  const { posthog } = useAnalytics()

  const [, updateWorkOrderPartiallyMutation] = useUpdateWorkOrderPartiallyMutation()
  const { context } = usePermissionScope(IPermissionScopeEnum.AppWorkOrderDelete)

  const TemplateConfirmModalProps = {
    title: i18n.t("common:delete_token", {
      token: i18n.t("common:template", { count: 1 }),
    }),
    content: i18n.t("common:messages.token_delete_confirmation", {
      token: i18n.t("common:template", { count: 1 }),
    }),
    okText: i18n.t("common:delete"),
    cancelText: i18n.t("common:cancel"),
  }

  const WorkOrderConfirmModalProps = {
    title: i18n.t("common:delete_token", {
      token: i18n.t("common:task", { count: 1 }),
    }),
    content: i18n.t("common:messages.token_delete_confirmation", {
      token: i18n.t("common:task", { count: 1 }),
    }),
    okText: i18n.t("common:delete"),
    cancelText: i18n.t("common:cancel"),
  }

  const ConfirmModalProps = isTemplate
    ? TemplateConfirmModalProps
    : WorkOrderConfirmModalProps

  const errorMessage = i18n.t("common:messages.token_delete_failure", {
    token: isTemplate
      ? i18n.t("common:template", { count: 1 })
      : i18n.t("common:task", { count: 1 }),
  })

  const successMsg = i18n.t("common:messages.token_delete_success", {
    token: isTemplate
      ? i18n.t("common:template", { count: 1 })
      : i18n.t("common:task", { count: 1 }),
  })

  const modal = useConfirmModal({
    ...ConfirmModalProps,
    onOk: async () => {
      if (!workOrderId) return
      const res = await updateWorkOrderPartiallyMutation(
        {
          id: workOrderId,
          changes: { deleted_at: new Date().toISOString() },
        },
        context()
      )
      if (res.error) toast.error(errorMessage)
      else {
        toast.success(successMsg)
        afterDelete?.()
        posthog?.capture("delete_work_order")
      }
    },
  })

  return {
    deleteConfirmModal: modal.component,
    delete: modal.show,
  }
}

function workOrderToTemplate(
  workOrder: IWorkOrderFragment
): Omit<IWorkOrderFragment, "id"> {
  return {
    ...workOrder,
    type: IWorkOrderTypeEnum.Template,
    status: IWorkOrderStatusEnum.Open,
    due_date: null,
    due_time: null,
    recurrence_info: null,
    block_groups: (workOrder.block_groups ?? []).map((g) => ({
      ...g,
      elements: (g.elements ?? []).map((e) => cleanBlockElement(e as BlockElementFragment)),
    })) as unknown as IWorkOrderFragment["block_groups"],
  }
}

function copyWorkOrderInitialValues(
  workOrder: IWorkOrderFragment
): Omit<IWorkOrderFragment, "id"> {
  return {
    ...workOrder,
    status: IWorkOrderStatusEnum.Open,
    block_groups: workOrder.block_groups.map((g) => ({
      ...g,
      elements: g.elements.map((e) => cleanBlockElement(e as BlockElementFragment)),
    })) as unknown as IWorkOrderFragment["block_groups"],
  }
}

export function useWorkOrderDetail(options: {
  workOrderId: uuid
  isTemplate?: boolean
  asPage?: boolean
}) {
  const { workOrderId } = options

  const [dataViewQueryRes] = useTaskDataViewByPkQuery({
    variables: { id: workOrderId },
    requestPolicy: "cache-only",
  })
  const [workOrderQueryRes] = useWorkOrderDetailsQuery({
    variables: { id: workOrderId },
    requestPolicy: "cache-and-network",
  })
  const [maintenanceTriggerTaskRes] = useMaintenanceTriggerTaskQuery({
    variables: { taskId: workOrderId },
    requestPolicy: "cache-and-network",
  })
  const editWorkOrderScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)
  const [, editWorkOrderPartially] = useUpdateWorkOrderPartiallyMutation()

  const workOrder = useMemo(() => {
    if (workOrderQueryRes.data?.work_order_by_pk) {
      return {
        ...workOrderQueryRes.data?.work_order_by_pk,
        detail: "detail" as "detail",
      }
    } else if (dataViewQueryRes.data?.work_order_by_pk) {
      return {
        ...dataViewQueryRes.data?.work_order_by_pk,
        detail: "data_view" as "data_view",
      }
    }
    return null
  }, [workOrderQueryRes.data?.work_order_by_pk, dataViewQueryRes.data?.work_order_by_pk])

  const isTemplate = options.isTemplate || workOrder?.type === IWorkOrderTypeEnum.Template
  const isState = workOrder?.type === IWorkOrderTypeEnum.State

  const isMaintenance =
    workOrder?.type === IWorkOrderTypeEnum.WorkOrder && workOrder?.maintenance_id !== null

  const isFromServiceRequest =
    workOrder?.type === IWorkOrderTypeEnum.WorkOrder &&
    workOrder?.service_request_id !== null

  const meterReadingTrigger = (maintenanceTriggerTaskRes.data?.maintenance_trigger_task ??
    [])[0]

  const textValuesBag = useFormik<{
    name: string
    description: string
    template_name: string
  }>({
    initialValues: {
      name: workOrder?.name ?? "",
      description: workOrder?.description ?? "",
      template_name: workOrder?.template_name ?? "",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (!workOrder) return

      if (!isTemplate && !values.name) {
        toast.error(i18n.t("tasks:messages.name_required"))
      }

      const result = await editWorkOrderPartially(
        { id: workOrder?.id, changes: values },
        editWorkOrderScope.context()
      )

      if (result.error || !result.data?.update_work_order_by_pk) {
        const errorMessage = i18n.t("common:messages.token_update_failure", {
          token: i18n.t("common:task", { count: 1 }),
        })

        toast.error(errorMessage)
        throw new Error(errorMessage)
      }
    },
  })

  usePage({
    isSubPage: true,
    paused: !options.asPage,
    id: isTemplate ? "template/details" : isState ? `state/details` : `work-order/details`,
    title: isTemplate
      ? i18n.t("common:template", { count: 1 })
      : isState
      ? i18n.t("tasks:types.state_change")
      : i18n.t("common:task", { count: 1 }),
  })

  const client = useClient()
  const edit = useDisclosure()
  const editBlockGroup = useDisclosure()
  const blockGroupFormHandle = useRef<BlockGroupFormHandle>(null)

  const confirmDiscardBlockGroupEdit = useConfirmModal({
    title: i18n.t("common:save_token", { token: i18n.t("tasks:fields.checklist") }),
    content: i18n.t("common:messages.unsaved_changes_confirmation"),
    okText: i18n.t("common:save"),
    cancelText: i18n.t("common:discard_changes"),
    onOk: async (afterClose?: () => void) => {
      const isValid = await blockGroupFormHandle.current?.submit()
      if (isValid) {
        editBlockGroup.onClose()
        afterClose?.()
      }
    },
    okButtonProps: { autoFocus: true },
    onCancel: (afterClose?: () => void) => {
      editBlockGroup.onClose()
      afterClose?.()
    },
  })

  const discardBlockGroupChanges = (afterClose?: () => void) => {
    if (blockGroupFormHandle.current?.isDirty) {
      confirmDiscardBlockGroupEdit.show(afterClose)
    } else {
      editBlockGroup.onClose()
      afterClose?.()
    }
  }

  const onEdit = () => {
    if (editBlockGroup.isOpen) {
      discardBlockGroupChanges(edit.onOpen)
    } else {
      edit.onOpen()
    }
  }

  const openModal = useOpenModal()

  const onCopyWorkOrder = () => {
    if (workOrder?.detail === "detail") {
      const initialValues = copyWorkOrderInitialValues(workOrder)
      const open = () =>
        openModal({
          task_create: { open: true, payload: { initialValues } },
        })
      if (editBlockGroup.isOpen) {
        discardBlockGroupChanges(open)
      } else {
        open()
      }
    }
  }

  const onSetSubtaskToTask = async () => {
    if (!workOrder?.id) return
    const res = await client
      .mutation<
        IUpdateWorkOrderPartiallyMutation,
        IUpdateWorkOrderPartiallyMutationVariables
      >(
        UpdateWorkOrderPartiallyDocument,
        {
          id: workOrder?.id,
          changes: {
            parent_work_order_id: null,
          },
        },
        editWorkOrderScope.context()
      )
      .toPromise()
    if (!res.data?.update_work_order_by_pk) {
      toast.error(
        i18n.t("common:messages.token_update_failure", {
          token: i18n.t("common:task", { count: 1 }),
        })
      )
    }
  }

  const parentTaskDisclosure = useDisclosure()

  const parentTask = {
    action: () => parentTaskDisclosure.onOpen(),
    component: (
      <WorkOrderSelectDialog
        isOpen={parentTaskDisclosure.isOpen}
        onOpenChange={parentTaskDisclosure.changeOpen}
        onSelect={async (id) => {
          await client
            .mutation<
              IUpdateWorkOrderPartiallyMutation,
              IUpdateWorkOrderPartiallyMutationVariables
            >(
              UpdateWorkOrderPartiallyDocument,
              {
                id: workOrder?.id!,
                changes: { parent_work_order_id: id },
              },
              editWorkOrderScope.context()
            )
            .toPromise()
        }}
      />
    ),
  }

  const onCreateShareLink = async () => {
    if (!workOrder?.id) return

    openModal({
      task_public_access: {
        open: true,
        payload: {
          taskId: workOrder.id,
          mode: workOrder.public_view_expiration ? "revoke" : "create",
          initialValues: {
            expiresIn: workOrder.public_view_expiration
              ? new Date(workOrder.public_view_expiration)
              : add(new Date(), { days: 7 }),
          },
        },
      },
    })
  }

  return {
    dataViewQueryRes,
    workOrderQueryRes,
    onEdit,
    textValuesEdit: edit,
    onCopyWorkOrder,
    onSetSubtaskToTask,
    onCreateShareLink,
    confirmDiscardBlockGroupEdit,
    discardBlockGroupChanges,
    editBlockGroup,
    blockGroupFormHandle,
    isState,
    isTemplate,
    isMaintenance,
    isFromServiceRequest,
    workOrder,
    parentTask,
    textValuesBag,
    meterReadingTrigger,
  }
}
