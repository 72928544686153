import { VStack } from "@components/layout"
import { Button, Icons } from "@components/shared"
import { RichTextFormController } from "@components/shared/form/rich-text-form-controller"
import { StaticRichText } from "@components/shared/static-rich-text"
import { TextArea } from "@components/shared/text-area"
import { ToggleGroup, ToggleGroupItem } from "@components/shared/toggle-group"
import Uploads from "@components/shared/uploads"
import { BlockElementYesNo, YesNoResponse } from "@elara/db"
import { useDebouncedPersistingText, useDisclosure } from "@hooks"
import i18n from "@i18n"
import { ToggleGroupSingleProps } from "@radix-ui/react-toggle-group"
import classNames from "classnames"
import { useRef } from "react"

import { useResponseUploads } from "./block-element-hooks"
import { BlockElementFormProps, BlockElementProps } from "./block-element-types"

const YesNoRadioChoices = (props: Omit<ToggleGroupSingleProps, "type">) => {
  return (
    <ToggleGroup type="single" {...props} className="w-full">
      <ToggleGroupItem value="yes" style={{ WebkitPrintColorAdjust: "exact" }}>
        {i18n.t("tasks:checklist.yes_no.choices.yes")}
      </ToggleGroupItem>
      <ToggleGroupItem value="no" style={{ WebkitPrintColorAdjust: "exact" }}>
        {i18n.t("tasks:checklist.yes_no.choices.no")}
      </ToggleGroupItem>
      <ToggleGroupItem
        value="not_answered"
        className="overflow-hidden text-ellipsis whitespace-nowrap"
        style={{ WebkitPrintColorAdjust: "exact" }}>
        {i18n.t("tasks:checklist.yes_no.choices.dont_know")}
      </ToggleGroupItem>
    </ToggleGroup>
  )
}

export const YesNoForm = (props: BlockElementFormProps) => {
  return (
    <VStack space={8} flex="1">
      <div>
        <RichTextFormController
          className="max-h-36"
          placeholder={i18n.t("tasks:checklist.yes_no.placeholder")}
          name={`${props.field}.${props.index}.config.description`}
          noStyle
          control={props.control}
        />
      </div>
      <YesNoRadioChoices disabled />
      <div className="flex">
        {" "}
        <Button disabled size="small" type="tertiary" icon={Icons.AddParagraph}>
          {i18n.t("tasks:fields.note")}
        </Button>
        <Button disabled size="small" type="tertiary" icon={Icons.Image}>
          {i18n.t("common:image", { count: 1 })}
        </Button>
      </div>
    </VStack>
  )
}

export const YesNoBlockElement = (props: BlockElementProps<BlockElementYesNo>) => {
  const noteRef = useRef<HTMLTextAreaElement>(null)
  const noteField = useDisclosure({
    initialValue: !!props.element.response?.note,
    onOpen: () => {
      setTimeout(() => {
        noteRef.current?.focus()
      }, 100)
    },
  })
  const [note, setNote] = useDebouncedPersistingText(
    props.element?.response?.note ?? "",
    (note) => {
      const yes_no_response = props.element.response?.yes_no_response
      if (!yes_no_response) return
      props.updateResponse?.(props.element.id, { yes_no_response, note })
    },
    500
  )

  const { addUpload, deleteUpload, scope } = useResponseUploads(props.element.id, {
    blockResponseUpdate: props.blockResponseUpdate,
  })

  return (
    <div className="relative flex w-full flex-1 flex-col items-start">
      <StaticRichText className="mb-2" content={props.element.config.description} />
      <YesNoRadioChoices
        value={props.element.response?.yes_no_response}
        onValueChange={(value) => {
          props.updateResponse?.(props.element.id, {
            note: "",
            ...props.element.response,
            yes_no_response: value as YesNoResponse,
          })
        }}
      />
      {!noteField.isOpen && (
        <Button
          type="tertiary"
          size="small"
          icon={Icons.AddParagraph}
          onClick={noteField.onOpen}
          disabled={!props.element.response?.yes_no_response}
          disabledReason="Es muss erst eine Auswahl getroffen werden">
          {i18n.t("common:enter_token", { token: i18n.t("tasks:fields.note") })}
        </Button>
      )}

      <TextArea
        value={note}
        ref={noteRef}
        onChange={(e) => setNote(e.target.value)}
        className={classNames("w-full mt-2 h-16", { hidden: !noteField.isOpen })}
        placeholder={i18n.t("tasks:fields.note")}
        onBlur={(e) => !e.target.value && noteField.onClose()}
      />

      <Uploads
        className="mt-2"
        placeholderLabel={i18n.t("common:upload_files")}
        addUploadButtonProps={{
          icon: Icons.TakePicture,
          disabled: !props.element.response?.yes_no_response,
          disabledReason: "Es muss erst eine Auswahl getroffen werden",
        }}
        disabled={!props.element.response?.yes_no_response}
        onUploadFinished={addUpload}
        onDelete={deleteUpload}
        boundDropzoneToContainer={false}
        uploads={props.element.uploads.map((u) => u.upload)}
        scope={scope}
      />
    </div>
  )
}
