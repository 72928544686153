import { DateRange } from "@utils/date"
import { stripHtml } from "@utils/string"
import { GridReadyEvent } from "ag-grid-community"
import { ColDef, GridApi, ValueFormatterParams } from "ag-grid-enterprise"
import { useMemo, useState } from "react"

import { DataGrid } from "../components/data-grid"
import { elaraLogo } from "../logos/elara"
import { ITasksDataGridQuery, useTasksDataGridQuery } from "../queries/tasks.generated"

type TData = ITasksDataGridQuery["work_order"]
type TRow = TData[number]

type Props = {
  dateRange: DateRange
  gridApi: GridApi | null
  setGridApi: (api: GridApi) => void
  className?: string
}

export const TasksDataGrid = ({ className, dateRange, setGridApi }: Props) => {
  const [queryRes] = useTasksDataGridQuery({
    variables: {
      startDate: dateRange.start.toISOString(),
      endDate: (dateRange.end ?? new Date()).toISOString(),
    },
  })
  const data = queryRes.data?.work_order ?? []

  const [colDefs, _setColDefs] = useState<ColDef<TRow>[]>([
    {
      field: "id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "work_order_number",
      headerName: "#",
      initialWidth: 60,
    },
    {
      field: "name",
      headerName: "Name",
      initialWidth: 300,
    },
    {
      field: "description",
      headerName: "Description",
      valueFormatter: (params) => stripHtml(params.value as string),
    },
    {
      field: "due_date",
      headerName: "Due Date",
      valueFormatter: (params) => {
        const data = params.value as TRow["completed_at"]
        if (!data) return ""
        return new Date(data).toLocaleDateString()
      },
    },
    {
      field: "status",
      headerName: "Status",
    },
    {
      field: "priority",
      headerName: "Priority",
    },
    {
      field: "assets",
      headerName: "Objects",
      enableRowGroup: true,
      keyCreator: (params) => {
        const data = params.value as TRow["assets"] | TRow["assets"][number]
        if (!data) return "No Objects"
        if (Array.isArray(data)) return data.map((a) => a.asset.id).join(", ")
        return data.asset.id
      },
      valueFormatter: (params) => {
        const data = params.value as TRow["assets"] | TRow["assets"][number]
        if (!data) return "No Objects"
        if (Array.isArray(data)) return data.map((a) => a.asset.name).join(", ")
        return data.asset.name
      },
    },
    {
      field: "comments",
      headerName: "Comments",
      filter: false,
      keyCreator: (params) => {
        const data = params.value as TRow["comments"] | TRow["comments"][number]
        if (!data) return "No Comments"
        if (Array.isArray(data)) return data.map((a) => a.id).join(", ")
        return data.id
      },
      valueFormatter: (params) => {
        const data = params.value as TRow["comments"] | TRow["comments"][number]
        if (!data) return "No Comments"
        if (Array.isArray(data)) return data.length > 0 ? `${data.length}` : ""
        return data.content
      },
    },
    {
      field: "block_groups",
      headerName: "Checklist",
      filter: false,
      keyCreator: (params) => {
        const data = params.value as TRow["block_groups"] | TRow["block_groups"][number]
        if (!data) return "No Checklists"
        if (Array.isArray(data)) return data.map((a) => a.id).join(", ")
        return data.id
      },
      valueFormatter: (params) => {
        const data = params.value as TRow["block_groups"] | TRow["block_groups"][number]
        if (!data) return "No Checklists"
        if (Array.isArray(data) && data.length > 0) {
          const blockGroup = data[0]
          const completed = blockGroup.elements.filter((a) => !!a.response).length
          return `${completed} / ${blockGroup.elements.length}`
        } else if (!Array.isArray(data)) {
          const completed = data.elements.filter((a) => !!a.response).length
          return `${completed} / ${data.elements.length}`
        }
        return ""
      },
    },
    {
      field: "categories",
      headerName: "Categories",
      enableRowGroup: true,
      keyCreator: (params) => {
        const data = params.value as TRow["categories"] | TRow["categories"][number]
        if (!data) return ""
        if (Array.isArray(data)) return data.map((a) => a.category.id).join(", ")
        return data.category.id
      },
      valueFormatter: (params) => {
        const data = params.value as TRow["categories"] | TRow["categories"][number]
        if (!data) return "(none)"
        if (Array.isArray(data)) return data.map((a) => a.category.label).join(", ")
        return data.category.label
      },
    },
    {
      field: "completed_by",
      headerName: "Completed By",
      enableRowGroup: true,
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => {
          const data = params.value as TRow["completed_by"]
          if (!data) return ""
          return data.first_name + " " + data.last_name
        },
      },
      keyCreator: (params) => {
        const data = params.value as TRow["completed_by"]
        if (!data) return ""
        return data.id
      },
      valueFormatter: (params) => {
        const data = params.value as TRow["completed_by"]
        if (!data) return ""
        return data.first_name + " " + data.last_name
      },
    },
    {
      field: "completed_at",
      headerName: "Completed Date",
      filter: false,
      valueFormatter: (params) => {
        const data = params.value as TRow["completed_at"]
        if (!data) return ""
        return new Date(data).toLocaleDateString()
      },
    },
  ])

  const defaultColDef = useMemo<ColDef<TRow>>(() => {
    return {
      filter: true,
      sortable: true,
      resizable: true,
    }
  }, [])

  const onGridReady = (params: GridReadyEvent) => setGridApi(params.api)

  return (
    <DataGrid<TRow>
      rowData={data}
      columnDefs={colDefs}
      wrapperClassName={className}
      rowSelection="multiple"
      defaultColDef={defaultColDef}
      onGridReady={onGridReady}
      rowGroupPanelShow="onlyWhenGrouping"
      statusBar={{
        statusPanels: [{ statusPanel: "agTotalRowCountComponent", align: "right" }],
      }}
      excelStyles={[
        {
          id: "header",
          alignment: { vertical: "Center" },
          interior: {
            color: "#2A55B0",
            pattern: "Solid",
          },
          font: {
            fontName: "Calibri",
            color: "#FFFFFF",
            bold: true,
          },
        },
        {
          id: "cell",
          font: { fontName: "Calibri" },
          alignment: { vertical: "Center" },
        },
      ]}
      defaultExcelExportParams={{
        rowHeight: (params) => (params.rowIndex === 1 ? 80 : 30),
        appendContent: [
          {
            cells: [
              {
                styleId: "header",
                mergeAcross: 1,
                data: {
                  type: "String",
                  value: `Report generated at ${new Date().toLocaleString()}`,
                },
              },
            ],
          },
        ],
        prependContent: [
          {
            cells: [
              {
                mergeAcross: 1,
                data: {
                  type: "String",
                  value: elaraLogo,
                },
              },
            ],
          },
        ],
        addImageToCell: (rowIndex, _col, value) => {
          if (rowIndex !== 1) return

          return {
            image: {
              id: "logo",
              width: 190,
              height: 45,
              base64: value,
              imageType: "png",
              position: { offsetX: 150, offsetY: 20 },
            },
          }
        },
      }}
    />
  )
}
