import { VStack } from "@components/layout"
import { RichTextFormController } from "@components/shared/form/rich-text-form-controller"
import { StaticRichText } from "@components/shared/static-rich-text"
import Uploads from "@components/shared/uploads"
import { BlockElementParagraph, IPermissionScopeEnum } from "@elara/db"
import { IUploadDataFragment } from "@graphql/documents/upload.generated"
import { usePermissionScope } from "@hooks"
import i18n from "@i18n"
import Icons from "@resources/icons"
import { useController } from "react-hook-form"

import { BlockElementFormProps, BlockElementProps } from "./block-element-types"

export const ParagraphForm = (props: BlockElementFormProps) => {
  const scope = usePermissionScope(IPermissionScopeEnum.AppDataEntry)

  const uploadsController = useController({
    name: `${props.field}.${props.index}.config_uploads`,
    control: props.control,
  })

  const addUpload = (upload: IUploadDataFragment, uploads: IUploadDataFragment[]) => {
    uploadsController.field.onChange(uploads.concat([upload]))
  }

  const deleteUpload = (uploadId: string, uploads: IUploadDataFragment[]) => {
    uploadsController.field.onChange(uploads.filter((u) => u.id !== uploadId))
  }

  return (
    <VStack space={8} flex="1">
      <div>
        <RichTextFormController
          className="max-h-36"
          placeholder={i18n.t("common:enter_token", {
            token: i18n.t("tasks:fields.description"),
          })}
          name={`${props.field}.${props.index}.config.description`}
          noStyle
          control={props.control}
        />
      </div>
      <Uploads
        onUploadFinished={addUpload}
        onDelete={deleteUpload}
        allowQuickDeleteOfPictures
        boundDropzoneToContainer={false}
        uploads={uploadsController.field.value ?? []}
        scope={scope}
      />
    </VStack>
  )
}

export const ParagraphBlockElement = (props: BlockElementProps<BlockElementParagraph>) => {
  return (
    <div>
      <StaticRichText className="mb-3" content={props.element.config.description} />
      <Uploads
        addUploadButtonProps={{ icon: Icons.TakePicture }}
        placeholderLabel={i18n.t("common:upload_files")}
        uploads={props.element.config_uploads ?? []}
      />
    </div>
  )
}
