import { Data } from "@elara/select"
import { FilteredTreeLike, Tree } from "@utils/tree"
import { useMemo } from "react"

import { DataViewFlatData, LayoutData } from "../data-view-types"

export const useDataViewFlatData = <D extends Data>(
  data: LayoutData<D>,
  isGroupCollapsed: (groupId: string | null) => boolean,
  isNodeOpen: (item: D) => boolean,
  addHeader: boolean = true
) => {
  return useMemo(() => {
    const flatData: DataViewFlatData<D> = []
    if (addHeader) {
      flatData.push({ type: "header" })
    }
    const addItemAndAllVisibleChildren = (item: FilteredTreeLike<Tree<D>>, level = 0) => {
      flatData.push({ type: "item", item: item, level })
      if (item.children && isNodeOpen(item)) {
        for (let child of item.children) {
          addItemAndAllVisibleChildren(child, level + 1)
        }
      }
    }

    if (data.type === "ungrouped") {
      for (let item of data.items) {
        addItemAndAllVisibleChildren(item)
      }
      return flatData
    }

    data.groups.forEach((group) => {
      flatData.push({
        type: "group",
        label: group.label,
        groupId: group.groupId,
        length: group.items.length,
      })
      if (!isGroupCollapsed(group.groupId)) {
        for (let item of group.items) {
          addItemAndAllVisibleChildren(item)
        }
      }
    })
    return flatData
  }, [data, isGroupCollapsed, isNodeOpen])
}
