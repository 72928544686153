import { useBreakpoint } from "@contexts/breakpoints"
import i18n from "@i18n"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import * as PopoverPrimitive from "@radix-ui/react-popover"
import { cn } from "@utils"
import classNames from "classnames"
import React from "react"

const ShowDialogContext = React.createContext(false)

export const PopoverRoot = ({
  alwaysPopover,
  dialog = {},
  ...props
}: PopoverPrimitive.PopoverProps & {
  dialog?: DialogPrimitive.DialogProps
  alwaysPopover?: boolean
}) => {
  const bp = useBreakpoint()
  const showDialog = alwaysPopover ? false : bp.mobile

  return (
    <ShowDialogContext.Provider value={showDialog}>
      {showDialog ? (
        <DialogPrimitive.Root
          {...dialog}
          open={props.open}
          onOpenChange={props.onOpenChange}
          defaultOpen={props.defaultOpen}>
          {props.children}
        </DialogPrimitive.Root>
      ) : (
        <PopoverPrimitive.Root {...props} />
      )}
    </ShowDialogContext.Provider>
  )
}

export type PopoverContentProps = PopoverPrimitive.PopoverContentProps & {
  dialog?: DialogPrimitive.DialogContentProps
}
export const PopoverContent = React.forwardRef<HTMLDivElement, PopoverContentProps>(
  ({ dialog = {}, ...props }, forwardedRef) => {
    const showDialog = React.useContext(ShowDialogContext)

    if (showDialog) {
      return (
        <DialogPrimitive.Portal className="isolate">
          <DialogPrimitive.Overlay className="fixed inset-0 bg-black/20" />
          <DialogPrimitive.Content
            onOpenAutoFocus={(e) => e.preventDefault()}
            {...dialog}
            onClick={(e) => e.stopPropagation()}
            className={cn(
              "top-24 left-1/2 -translate-x-1/2 rounded bg-white fixed w-[80vw] max-h-[80vh] max-w-md z-10 flex flex-col overflow-hidden",
              dialog.className
            )}
            style={{
              ...dialog.style,
              boxShadow: "rgb(0 0 0 / 9%) 0px 3px 12px",
            }}
            ref={forwardedRef}>
            <div className="flex min-h-0 flex-1 flex-col">{props.children}</div>
            <div className="flex justify-end border-t border-gray-200 p-2 px-3">
              <DialogPrimitive.Close className="select-none rounded border border-gray-200 px-2 py-1 text-sm font-medium text-gray-600 hover:border-gray-400 hover:text-gray-800">
                {i18n.t("common:close")}
              </DialogPrimitive.Close>
            </div>
          </DialogPrimitive.Content>
        </DialogPrimitive.Portal>
      )
    } else {
      return (
        <PopoverPrimitive.Portal>
          <PopoverPrimitive.Content
            {...props}
            avoidCollisions
            onClick={(e) => e.stopPropagation()}
            className={classNames(
              props.className,
              "rounded bg-white drop-shadow-md border border-gray-100"
            )}
            ref={forwardedRef}>
            {props.children}
          </PopoverPrimitive.Content>
        </PopoverPrimitive.Portal>
      )
    }
  }
)

// export const PopoverTrigger = PopoverPrimitive.Trigger
export const PopoverTrigger = React.forwardRef<
  HTMLButtonElement,
  PopoverPrimitive.PopoverTriggerProps
>((props, forwardedRef) => {
  const showDialog = React.useContext(ShowDialogContext)
  if (showDialog) {
    return <DialogPrimitive.Trigger {...props} ref={forwardedRef} />
  } else {
    return <PopoverPrimitive.Trigger {...props} ref={forwardedRef} />
  }
})
export const PopoverAnchor = React.forwardRef<
  HTMLDivElement,
  PopoverPrimitive.PopoverAnchorProps
>((props, forwardedRef) => {
  const showDialog = React.useContext(ShowDialogContext)
  if (showDialog) {
    return null
  } else {
    return <PopoverPrimitive.Anchor {...props} ref={forwardedRef} />
  }
})

export const PopoverClose = React.forwardRef<
  HTMLButtonElement,
  PopoverPrimitive.PopoverCloseProps
>((props, forwardedRef) => {
  const showDialog = React.useContext(ShowDialogContext)
  if (showDialog) {
    return <DialogPrimitive.Close {...props} ref={forwardedRef} />
  } else {
    return <PopoverPrimitive.Close {...props} ref={forwardedRef} />
  }
})
