import { Stack } from "@components/layout"
import { FC, PropsWithChildren } from "react"

type DetailsItemLabelProps = {
  className?: string
}

const DetailsItemLabel: FC<PropsWithChildren<DetailsItemLabelProps>> = (props) => (
  <span className={`text-xs text-gray-600 ${props.className ?? ""}`}>{props.children}</span>
)

type DetailsItemValueProps = {
  placeholder?: string
  className?: string
}

const DetailsItemValue: FC<PropsWithChildren<DetailsItemValueProps>> = (props) => {
  if (
    props.placeholder &&
    (!props.children || (Array.isArray(props.children) && !props.children.length))
  ) {
    return (
      <div className={props.className}>
        <span className="text-sm text-gray-500">{props.placeholder}</span>
      </div>
    )
  }
  return <div className={props.className ?? "text-sm"}>{props.children}</div>
}

type Props = {
  direction?: "vertical" | "horizontal"
  size?: 0 | 4 | 8 | 12 | 16
  className?: string
}

const DetailsItem: FC<PropsWithChildren<Props>> & {
  Label: FC<PropsWithChildren<{}>>
  Value: FC<PropsWithChildren<DetailsItemValueProps>>
} = (props) => (
  <Stack
    direction={props.direction === "vertical" ? "column" : "row"}
    space={props.size}
    className={props.className}>
    {props.children}
  </Stack>
)

DetailsItem.defaultProps = { size: 4 }

DetailsItem.Label = DetailsItemLabel
DetailsItem.Value = DetailsItemValue

export default DetailsItem
