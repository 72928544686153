import { AssetSingleSelect } from "@components/asset"
import { Button, TextArea, useConfirmModal } from "@components/shared"
import { CurrencyInput } from "@components/shared/currency-input"
import { CurrencyValue } from "@components/shared/currency-value"
import { DialogRootProps } from "@components/shared/dialog"
import { DialogForm } from "@components/shared/dialog-form"
import { FormField } from "@components/shared/form/form-field"
import { RadioInput } from "@components/shared/form/radio-input"
import { DateTimeNative } from "@components/shared/native-date-time-pickers"
import RadioGroupPanel from "@components/shared/radio-group-panel"
import { TextInput } from "@components/shared/text-input"
import { IPermissionScopeEnum, uuid } from "@elara/db"
import {
  useConsumableQuery,
  useDeleteConsumableLogMutation,
  useInsertConsumableLogMutation,
  useUpdateConsumableLogMutation,
} from "@graphql/documents/consumable.generated"
import { usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { Minus, Plus, X } from "@phosphor-icons/react"
import { Serialized } from "@utils"

export type MaterialRestockFormValues = {
  id?: string
  asset_id?: string | null
  adjustment?: number
  adjustment_at: Date
  place_id: string | null
  cost_per_unit: number
  description: string | null
}

type Props = {
  initialValues: Partial<Serialized<MaterialRestockFormValues>>
  consumableId: string | null
  baseQuantity?: number
} & Required<Pick<DialogRootProps, "isOpen" | "onOpenChange">>

const MaterialRestockDialog: React.FC<Props> = ({
  isOpen,
  onOpenChange,
  initialValues,
  consumableId,
  baseQuantity,
}) => {
  const [consumableRes] = useConsumableQuery({
    requestPolicy: "cache-first",
    pause: !consumableId,
    variables: { id: consumableId! },
  })
  const consumable = consumableRes.data?.consumable_by_pk

  const [, insertConsumableLog] = useInsertConsumableLogMutation()
  const [, updateConsumableLog] = useUpdateConsumableLogMutation()
  const [, deleteConsumableLog] = useDeleteConsumableLogMutation()

  const deleteModal = useConfirmModal({
    title: i18n.t("common:delete_token", {
      token: i18n.t("consumables:fields.consumption"),
    }),
    content: i18n.t("common:messages.token_delete_confirmation", {
      token: i18n.t("common:entry", { count: 1 }),
    }),
    okText: i18n.t("common:delete"),
    cancelText: i18n.t("common:cancel"),
    onOk: async () => {
      if (initialValues && initialValues.id) {
        await deleteConsumableLog({ id: initialValues.id })
        onOpenChange(false)
      }
    },
  })

  const scope = usePermissionScope(IPermissionScopeEnum.AppDataEntry)

  const onSubmit = async (values: MaterialRestockFormValues) => {
    const data = {
      asset_id: values.asset_id,
      adjustment: values.adjustment || 0,
      place_id: values.place_id === "no_value" ? null : values.place_id,
      consumable_id: consumableId,
      description: values.description || null,
      cost_per_unit: values.cost_per_unit || 0,
      adjustment_at: values.adjustment_at.toISOString(),
    }
    if (initialValues.id) {
      const res = await updateConsumableLog({ id: initialValues.id, data }, scope.context())
      if (res.error) throw res.error
    } else {
      const res = await insertConsumableLog({ data }, scope.context())
      if (res.error) throw res.error
    }
  }

  const onDelete = () => deleteModal.show()

  if (!consumable) return null

  const quantity = baseQuantity ?? consumable.quantity ?? 0

  return (
    <>
      <DialogForm<MaterialRestockFormValues>
        closeIcon
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        title={i18n.t("consumables:actions.adjust_quantity")}
        footerActions={
          initialValues.id ? (
            <Button color="red" type="tertiary" className="mr-auto" onClick={onDelete}>
              {i18n.t("common:delete")}
            </Button>
          ) : null
        }
        formikConfig={{
          initialValues: {
            ...initialValues,
            adjustment_at: initialValues.adjustment_at
              ? new Date(initialValues.adjustment_at)
              : new Date(),
            cost_per_unit: initialValues.cost_per_unit ?? 0,
            description: initialValues.description ?? "",
            place_id:
              Object.keys(initialValues).length > 0
                ? initialValues.place_id ?? "no_value"
                : consumable.storage_locations[0]?.place?.id ?? "no_value",
          },
          onSubmit,
        }}>
        {(formik) => {
          const newQuantity = (() => {
            const adjustment = +(formik.values.adjustment ?? 0)

            if (isNaN(adjustment)) return quantity

            return adjustment + quantity
          })()

          const QuantityHint = (
            <div className="mt-1 flex items-center justify-between text-xs">
              <span>
                <strong className="mr-1 font-medium">
                  {i18n.t("consumables:labels.old_value")}:
                </strong>
                <span className="tabular-nums">
                  {Number(quantity).toFixed(2)} {consumable.unit}
                </span>
              </span>

              <span>
                <strong className="mr-1 font-medium">
                  {i18n.t("consumables:labels.new_value")}:
                </strong>
                <span className="tabular-nums">
                  {Number(newQuantity).toFixed(2)} {consumable.unit}
                </span>
              </span>
            </div>
          )

          return (
            <div className="my-6 flex flex-col justify-center gap-4">
              <FormField
                name="adjustment"
                label={i18n.t("consumables:fields.quantity")}
                hint={QuantityHint}
                hasErrorPlaceholder={false}>
                {({ field, helpers }) => (
                  <div className="flex items-center justify-center gap-2">
                    <Button
                      color="gray"
                      icon={Minus}
                      type="tertiary"
                      size="extra-small"
                      onClick={() => {
                        if (field.value) {
                          helpers.setValue(+field.value - 1)
                        } else {
                          helpers.setValue(-1)
                        }
                      }}
                    />

                    <TextInput
                      size="small"
                      name={field.name}
                      defaultValue={field.value ?? 0}
                      value={field.value}
                      onChange={field.onChange}
                    />

                    <Button
                      icon={Plus}
                      color="gray"
                      type="tertiary"
                      size="extra-small"
                      onClick={() => {
                        if (field.value) {
                          helpers.setValue(+field.value + 1)
                        } else {
                          helpers.setValue(1)
                        }
                      }}
                    />
                  </div>
                )}
              </FormField>

              <FormField
                name="description"
                label={i18n.t("description")}
                optional
                hasErrorPlaceholder={false}>
                <TextArea
                  placeholder={i18n.t(
                    "consumables:messages.restock_description_placeholder"
                  )}
                />
              </FormField>

              <div className="flex gap-x-3">
                <FormField
                  name="cost_per_unit"
                  type="number"
                  className="w-1/2"
                  label={i18n.t("consumables:fields.cost_per_unit")}
                  hasErrorPlaceholder={false}>
                  <CurrencyInput />
                </FormField>
                <div className="flex flex-col space-y-3">
                  <span className="text-sm font-medium text-gray-700">
                    {i18n.t("common:cost")}
                  </span>
                  <span className="text-sm text-gray-700">
                    {Math.abs(formik.values.adjustment ?? 0).toFixed(2)}
                    <X className="mx-0.5 inline" size={10} weight="bold" />
                    <CurrencyValue value={formik.values.cost_per_unit ?? 0} /> ={" "}
                    <CurrencyValue
                      value={
                        (formik.values.cost_per_unit ?? 0) *
                        Math.abs(formik.values.adjustment ?? 0)
                      }
                    />
                  </span>
                </div>
              </div>

              <FormField
                name="adjustment_at"
                label={i18n.t("consumables:fields.adjustment_at")}
                hasErrorPlaceholder={false}>
                {({ field, helpers }) => (
                  <DateTimeNative value={field.value} onChange={helpers.setValue} />
                )}
              </FormField>

              <h4 className="-mb-2 text-sm font-medium text-gray-700">
                {i18n.t("consumables:labels.storage_location", { count: 1 })}
              </h4>

              {consumable.storage_locations.length > 0 && (
                <RadioGroupPanel
                  value={formik.values.place_id || "no_value"}
                  onValueChange={(v) => formik.setFieldValue("place_id", v)}
                  options={consumable.storage_locations.map((c) => ({
                    value: c.place.id,
                    label: c.place.name,
                    description: c.area ?? "",
                  }))}
                />
              )}
              <label className="flex items-center text-sm text-gray-500">
                <RadioInput
                  type="radio"
                  checked={formik.values.place_id === "no_value"}
                  onChange={() => formik.setFieldValue("place_id", "no_value")}
                />
                <span className="ml-2">
                  {i18n.t("consumables:labels.assign_no_location")}
                </span>
              </label>

              <FormField
                optional
                name="asset_id"
                label={i18n.t("common:asset", { count: 1 })}
                hint={i18n.t("consumables:messages.adjustment_object_hint")}
                className="col-span-4">
                {({ field, helpers }) => (
                  <AssetSingleSelect
                    size="large"
                    value={field.value}
                    onChange={helpers.setValue}
                    recommendedItems={consumable?.asset_ids as uuid[]}
                  />
                )}
              </FormField>
            </div>
          )
        }}
      </DialogForm>
      {deleteModal.component}
    </>
  )
}

export default MaterialRestockDialog
