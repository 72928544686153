import { Button } from "@components/shared"
import AutoResizeTextArea from "@components/shared/auto-resize-text-area"
import { CollapsibleTabsLink } from "@components/shared/collapsible-tabs-links"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@components/shared/dropdown"
import toast from "@components/shared/toast"
import { Tooltip } from "@components/shared/tooltip"
import {
  statusIcon,
  translateWorkOrderStatus,
  WorkOrderStatusTag,
} from "@components/work-order/work-order-status"
import {
  IBlockGroupTypeEnum,
  IPermissionScopeEnum,
  IWorkOrderStatusEnum,
  ProgressInfo,
} from "@elara/db"
import {
  IWorkOrderDataViewFragment,
  IWorkOrderFragment,
} from "@graphql/documents/fragments.generated"
import { usePermissionScope } from "@hooks"
import { useElementSize } from "@hooks/use-element-size"
import i18n from "@i18n"
import { TaskOptions } from "@pages/new-task.$id/components/options"
import {
  CaretRight,
  Chat,
  Check,
  DotsSixVertical,
  Info,
  ListChecks,
  Lock,
  Notepad,
  PencilSimple,
} from "@phosphor-icons/react"
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu"
import { cn } from "@utils"
import React from "react"
import { Link } from "react-router-dom"
import { useClient } from "urql"

import {
  IUpdateTaskNameMutation,
  IUpdateTaskNameMutationVariables,
  IUpdateTaskStatusMutation,
  IUpdateTaskStatusMutationVariables,
  UpdateTaskNameDocument,
  UpdateTaskStatusDocument,
} from "../actions.generated"

function TaskNumber(props: { number: number; name: string }) {
  return (
    <span
      className="cursor-pointer select-auto font-medium text-gray-600 "
      onClick={() => {
        const taskIdentifier = `#${props.number} - ${props.name}`
        window.navigator.clipboard.writeText(taskIdentifier)
        toast.success(i18n.t("tasks:messages.copy_task_identifier"))
      }}>{`#${props.number}`}</span>
  )
}

function CheckListProgressBadge(props: { task: IWorkOrderFragment }) {
  const checklist = props.task.block_groups.find(
    (blockGroup) => blockGroup.group_type === IBlockGroupTypeEnum.Procedure
  )
  if (!checklist) {
    return null
  }
  const progress = checklist.progress_info as ProgressInfo
  const isComplete = progress.total_with_response === progress.total
  const hasInteractiveElement = checklist.elements.some(
    (element) => !["paragraph", "heading"].includes(element.element_type)
  )

  return (
    <span
      className={cn("rounded-full  px-1 py-0.5 text-xs font-medium ", {
        "bg-gray-100 text-gray-700": progress.total_with_response,
        "bg-green-100 text-green-700": isComplete,
        "bg-blue-100 text-blue-700": progress.total_with_response > 0 && !isComplete,
      })}>
      {isComplete ? (
        <Check className="h-3 w-3" />
      ) : (
        checklist.elements.length > 0 &&
        hasInteractiveElement && (
          <span>
            {progress.total_with_response}/{progress.total}
          </span>
        )
      )}
    </span>
  )
}

function TaskStatusToggle(props: { task: IWorkOrderDataViewFragment }) {
  const client = useClient()
  const editScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)
  const dataEntryScope = usePermissionScope(IPermissionScopeEnum.AppDataEntry)

  const updateStatus = async (status: IWorkOrderStatusEnum) => {
    const res = await client
      .mutation<IUpdateTaskStatusMutation, IUpdateTaskStatusMutationVariables>(
        UpdateTaskStatusDocument,
        { id: props.task.id, status },
        editScope.hasScope ? editScope.context() : dataEntryScope.context()
      )
      .toPromise()

    if (res.error) {
      toast.error(i18n.t("common:generic_toast_error"))
    }
  }

  const middleStatus = [
    IWorkOrderStatusEnum.Open,
    IWorkOrderStatusEnum.InProgress,
    IWorkOrderStatusEnum.Done,
  ].includes(props.task.status)
    ? IWorkOrderStatusEnum.InProgress
    : props.task.status
  return (
    <div className="bg-gray-50 px-3 py-0.5 print:bg-white">
      <div className="inline-block w-full max-w-md @container">
        <div className="grid h-12 shrink-0 grid-cols-4 items-stretch space-x-px rounded-lg text-xs text-gray-700 @sm:text-sm ">
          <button
            onClick={() => updateStatus(IWorkOrderStatusEnum.Open)}
            className={cn(
              "flex rounded-l z-10 flex-col ring-1 ring-gray-200 items-center p-1 justify-center text-gray-600 font-medium print:rounded-lg print:hidden",
              {
                "ring-2 ring-gray-600 rounded-sm  bg-gray-100 z-10 text-gray-800 print:flex":
                  props.task.status === IWorkOrderStatusEnum.Open,
              }
            )}>
            <span className="text-base">{statusIcon(IWorkOrderStatusEnum.Open)}</span>{" "}
            {translateWorkOrderStatus(IWorkOrderStatusEnum.Open)}
          </button>
          <button
            onClick={() => updateStatus(IWorkOrderStatusEnum.InProgress)}
            className={cn(
              "flex rounded-sm flex-col ring-1 ring-gray-200 items-center p-1 justify-center font-medium print:hidden print:rounded-lg",

              {
                "print:flex ring-2 z-10 bg-blue-100 ring-blue-600":
                  props.task.status === IWorkOrderStatusEnum.InProgress,
                "print:flex ring-2 z-10 bg-orange-100 ring-orange-400 text-orange-600":
                  props.task.status === IWorkOrderStatusEnum.OnHold,
                "print:flex ring-2 z-10 bg-teal-100 ring-teal-400 text-teal-600":
                  props.task.status === IWorkOrderStatusEnum.ReadyForApproval,
                "print:flex ring-2 z-10 bg-gray-100 ring-gray-500 text-gray-600":
                  props.task.status === IWorkOrderStatusEnum.Planned ||
                  props.task.status === IWorkOrderStatusEnum.Canceled,
              }
            )}>
            <span className=" text-base">{statusIcon(middleStatus)}</span>{" "}
            {translateWorkOrderStatus(middleStatus)}
          </button>
          <button
            onClick={() => updateStatus(IWorkOrderStatusEnum.Done)}
            className={cn(
              "flex  flex-col ring-1 ring-gray-200 items-center p-1 justify-center text-green-600 font-medium print:rounded-lg print:hidden",
              {
                "print:flex ring-2 bg-green-100 rounded-sm  ring-green-600 z-10":
                  props.task.status === IWorkOrderStatusEnum.Done,
              }
            )}>
            <span className=" text-base">{statusIcon(IWorkOrderStatusEnum.Done)}</span>{" "}
            {translateWorkOrderStatus(IWorkOrderStatusEnum.Done)}
          </button>
          <DropdownMenu>
            <DropdownMenuTrigger
              className={cn(
                "flex rounded-r flex-col ring-1 ring-gray-200 items-center p-1 justify-center text-gray-600 font-medium",
                "radix-state-open:bg-gray-100 print:hidden"
              )}>
              <span className="text-base">
                <DotsSixVertical />
              </span>
              {i18n.t("tasks:header.more_status")}
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" side="bottom">
              {[
                IWorkOrderStatusEnum.OnHold,
                IWorkOrderStatusEnum.Planned,
                IWorkOrderStatusEnum.InProgress,
                IWorkOrderStatusEnum.ReadyForApproval,
                IWorkOrderStatusEnum.Canceled,
              ]
                .filter((s) => s !== middleStatus)
                .map((status) => (
                  <DropdownMenuItem key={status} onSelect={() => updateStatus(status)}>
                    <WorkOrderStatusTag status={status} />
                  </DropdownMenuItem>
                ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </div>
  )
}

export function Header(props: {
  taskDataView: IWorkOrderDataViewFragment
  task: IWorkOrderFragment | null
  template?: boolean
  maintenance?: boolean
}) {
  const task = props.taskDataView
  const ref = React.useRef<HTMLDivElement>(null)
  const elementSize = useElementSize(ref.current)
  const titleRef = React.useRef<HTMLTextAreaElement>(null)
  const [titleFocused, setTitleFocused] = React.useState(false)
  const client = useClient()
  const editScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)

  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const updateTitle = async (title: string) => {
    try {
      setIsSubmitting(true)

      const res = await client
        .mutation<IUpdateTaskNameMutation, IUpdateTaskNameMutationVariables>(
          UpdateTaskNameDocument,
          { id: task.id, name: title },
          editScope.context()
        )
        .toPromise()

      if (res.error) {
        toast.error(i18n.t("common:generic_toast_error"))
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <>
      {task.parent_task && (
        <div className="flex w-full justify-center bg-gray-50/80 px-3 pb-0 pt-3 backdrop-blur-md print:bg-white">
          <div className="inline-flex max-w-screen-md flex-1">
            <Link
              to={`/task/` + task.parent_task.id}
              className="group flex min-w-0 cursor-pointer items-center space-x-2 rounded border border-gray-300 bg-white px-2 py-1 text-xs text-gray-600 hover:border-gray-700 hover:text-gray-700">
              <WorkOrderStatusTag status={task.parent_task.status} short />
              <span className="pr-1 font-medium">
                #{task.parent_task.work_order_number}
              </span>{" "}
              {task.parent_task.name}
              <span className="truncate font-medium text-gray-700 hover:text-gray-900"></span>
              <CaretRight className="inline h-[1em] w-[1em] group-hover:text-gray-700" />
            </Link>
          </div>
        </div>
      )}
      <div
        className="sticky top-0 z-10 flex  w-full justify-center bg-gray-50/80 p-3 backdrop-blur-md print:bg-white"
        ref={ref}>
        <div className="flex max-w-screen-md flex-1 flex-col items-stretch @container">
          <div className="flex flex-1 items-center gap-x-1">
            {!props.template && (
              <div className="text-base">
                <TaskNumber number={task.work_order_number} name={task.name} />
              </div>
            )}
            {task.only_assigned && (
              <Tooltip content={i18n.t("tasks:messages.only_assigned")}>
                <Lock className="mx-1 box-content h-4 w-4 py-1.5 text-gray-400" />
              </Tooltip>
            )}
            <div className="flex flex-1 items-center gap-x-1">
              <AutoResizeTextArea
                rows={1}
                ref={titleRef}
                containerClassName="grow"
                defaultValue={task.name}
                onFocus={() => setTitleFocused(true)}
                onBlur={() => {
                  updateTitle(titleRef.current?.value ?? "")
                  setTimeout(() => setTitleFocused(false), 200)
                }}
                className="border-transparent bg-transparent font-semibold sm:text-lg"
              />
              <Button
                icon={titleFocused ? Check : PencilSimple}
                isLoading={isSubmitting}
                type="tertiary"
                color="gray"
                className="print:hidden"
                onPointerDown={(e) => {
                  if (!titleFocused) {
                    titleRef.current?.focus?.()
                    e.stopPropagation()
                    e.preventDefault()
                  }
                }}
              />
            </div>
            {props.task && <TaskOptions workOrder={props.task} />}
          </div>
        </div>
      </div>

      {!props.template && (
        <div className="flex justify-center bg-gray-50 print:bg-white">
          <div className="max-w-screen-md flex-1">
            <TaskStatusToggle task={task} />
          </div>
        </div>
      )}
      <div
        className="z-10 flex justify-center border-b bg-gray-50/80 backdrop-blur-md print:hidden"
        style={{
          position: "sticky",
          top: elementSize?.height ? elementSize.height + 16 : 0,
        }}>
        <div className="-mb-px max-w-[min(100%,768px)] flex-1">
          <CollapsibleTabsLink
            triggers={[
              { value: "details", label: i18n.t("tasks:header.details"), icon: <Info /> },
              {
                value: "checklist",
                label: (
                  <span className="inline-flex items-center space-x-1">
                    <span>{i18n.t("tasks:header.checklist")}</span>{" "}
                    {props.task && <CheckListProgressBadge task={props.task} />}
                  </span>
                ),
                icon: <ListChecks />,
              },
              { value: "comments", label: i18n.t("tasks:header.comments"), icon: <Chat /> },
              { value: "report", label: i18n.t("tasks:header.report"), icon: <Notepad /> },
            ].filter((x) => {
              if (props.template) {
                if (x.value === "report" || x.value === "comments") {
                  return false
                }
              }
              return true
            })}
          />
        </div>
      </div>
    </>
  )
}
