import { Archive, Info, ShieldWarning, Warning } from "@phosphor-icons/react"
import classNames from "classnames"

type AlertBoxProps = React.PropsWithChildren<{
  type: "info" | "danger" | "warning" | "archive"
  className?: string
}>

const AlertBox: React.FC<AlertBoxProps> = ({ type, className, children }) => (
  <div
    className={classNames(
      "flex items-center gap-3 rounded px-4 py-3",
      {
        "bg-blue-100 text-blue-900": type === "info",
        "bg-red-100 text-red-900": type === "danger",
        "bg-yellow-100 text-yellow-800": type === "warning",
        "bg-gray-200 text-gray-600": type === "archive",
      },
      className
    )}>
    <div>
      {type === "info" && <Info size={20} />}
      {type === "danger" && <ShieldWarning size={20} />}
      {type === "warning" && <Warning size={20} />}
      {type === "archive" && <Archive size={20} />}
    </div>
    <div className="text-xs leading-normal">{children}</div>
  </div>
)

export default AlertBox
