import { generatePath, Navigate, useLocation, useParams } from "react-router-dom"

export const Redirect = (props: { to: string }) => {
  const params = useParams()
  const { state } = useLocation()

  return <Navigate to={generatePath(props.to, params)} replace state={state} />
}

export default Redirect
