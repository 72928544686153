// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IPlaceFragment = { __typename: 'place', id: uuid, name: string, assets_aggregate: { __typename: 'asset_aggregate', aggregate: { __typename: 'asset_aggregate_fields', count: number } | null } };

export type IPlacesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IPlacesQuery = { place: Array<{ __typename: 'place', id: uuid, name: string, assets_aggregate: { __typename: 'asset_aggregate', aggregate: { __typename: 'asset_aggregate_fields', count: number } | null } }> };

export type IDeletePlaceMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type IDeletePlaceMutation = { delete_place_by_pk: { __typename: 'place', id: uuid } | null };

export type IUpdatePlaceMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  name: Types.Scalars['String']['input'];
}>;


export type IUpdatePlaceMutation = { update_place_by_pk: { __typename: 'place', id: uuid, name: string } | null };

export type IInsertPlaceMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
}>;


export type IInsertPlaceMutation = { insert_place_one: { __typename: 'place', id: uuid, name: string } | null };

export const PlaceFragmentDoc = gql`
    fragment Place on place {
  id
  name
  assets_aggregate {
    aggregate {
      count
    }
  }
}
    `;
export const PlacesDocument = gql`
    query Places {
  place {
    ...Place
  }
}
    ${PlaceFragmentDoc}`;

export function usePlacesQuery(options?: Omit<Urql.UseQueryArgs<IPlacesQueryVariables>, 'query'>) {
  return Urql.useQuery<IPlacesQuery, IPlacesQueryVariables>({ query: PlacesDocument, ...options });
};
export const DeletePlaceDocument = gql`
    mutation DeletePlace($id: uuid!) {
  delete_place_by_pk(id: $id) {
    id
  }
}
    `;

export function useDeletePlaceMutation() {
  return Urql.useMutation<IDeletePlaceMutation, IDeletePlaceMutationVariables>(DeletePlaceDocument);
};
export const UpdatePlaceDocument = gql`
    mutation UpdatePlace($id: uuid!, $name: String!) {
  update_place_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {
    id
    name
  }
}
    `;

export function useUpdatePlaceMutation() {
  return Urql.useMutation<IUpdatePlaceMutation, IUpdatePlaceMutationVariables>(UpdatePlaceDocument);
};
export const InsertPlaceDocument = gql`
    mutation InsertPlace($name: String!) {
  insert_place_one(object: {name: $name}) {
    id
    name
  }
}
    `;

export function useInsertPlaceMutation() {
  return Urql.useMutation<IInsertPlaceMutation, IInsertPlaceMutationVariables>(InsertPlaceDocument);
};