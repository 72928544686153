import { cn } from "@utils"
import { PropsWithChildren } from "react"

export const InfoTagContainer = ({
  children,
  className,
  $size,
}: PropsWithChildren<{ $size: "small" | "large"; className?: string }>) => (
  <div
    className={cn(
      "bg-gray-100 rounded px-3 h-9 flex items-center shrink-0 space-x-2 max-w-4xl",
      { "h-8": $size == "small" },
      className
    )}>
    {children}
  </div>
)

export default InfoTagContainer
