import { useControllableState } from "@hooks/use-controllable-state"
import i18n from "@i18n"
import { DateRange, DateRangeKey, dateRangeOptions } from "@utils/date"
import { TreeLike } from "@utils/tree"
import { endOfDay, startOfDay, startOfMinute } from "date-fns"
import { useMemo } from "react"

import { Item } from "./combobox-select"
import { DateNative } from "./native-date-time-pickers"
import { SelectPopover } from "./single-select"

type Props = {
  defaultValue?: DateRangeKey
  defaultRange?: DateRange
  range?: DateRange
  onRangeChange?: (range: DateRange) => void
  future?: boolean
  onlyFixedDateRange?: boolean
}

export function getRange(key: DateRangeKey, future: boolean = false): DateRange {
  return (
    dateRangeOptions(new Date(), { future }).find(({ key: k }) => k === key)?.range ?? {
      start: startOfDay(new Date()),
      end: startOfMinute(new Date()),
    }
  )
}
const DateRangePicker = ({
  defaultValue = "last-30-days",
  range,
  defaultRange,
  onRangeChange,
  future = false,
  onlyFixedDateRange,
}: Props) => {
  const defaultValueRange = useMemo(() => getRange(defaultValue, future), [])
  const [value, setValue] = useControllableState<DateRange>({
    prop: range,
    onChange: onRangeChange,
    defaultProp: defaultRange ?? defaultValueRange,
  })

  const items: TreeLike<Item<DateRange>>[] = useMemo(
    () =>
      dateRangeOptions(new Date(), { future }).map((date) => ({
        label: date.label,
        value: date.range,
        searchValue: date.label,
      })) as TreeLike<Item<DateRange>>[],
    [future]
  )
  const valueToString = (v: DateRange) =>
    `${v?.start?.getTime() ?? ""}-${v?.end?.getTime() ?? ""}`

  const itemsToSelect = useMemo(() => {
    if (onlyFixedDateRange) return items

    if (value) {
      const hasMatch = items.some(
        (item) => valueToString(item.value) === valueToString(value)
      )
      if (hasMatch) {
        return items
      } else {
        return [
          ...items,
          {
            value,
            label: i18n.t("calendar:relative_dates.custom_date_range"),
            searchValue: i18n.t("calendar:relative_dates.custom_date_range"),
          },
        ]
      }
    }
    return items
  }, [onlyFixedDateRange, items, value])

  return (
    <div className="grid gap-4 sm:grid-cols-[auto_auto_1fr]">
      {!onlyFixedDateRange && (
        <DateNative
          className="block"
          value={value?.start}
          onChange={(start) => start && setValue({ ...value, start })}
        />
      )}

      {!onlyFixedDateRange && (
        <DateNative
          className="block"
          value={value?.end}
          onChange={(end) =>
            end && value?.start && setValue({ ...value, end: endOfDay(end) })
          }
        />
      )}

      <SelectPopover<DateRange>
        items={itemsToSelect}
        value={value}
        valueToString={valueToString}
        className="w-auto"
        isClearable={false}
        onChange={(v) => v && setValue(v)}
        placeholder={i18n.t("common:select_token", {
          token: i18n.t("calendar:relative_dates.date_range"),
        })}
      />
    </div>
  )
}

export default DateRangePicker
