import { IWorkOrderFragment } from "@graphql/documents/fragments.generated"
import download from "downloadjs"
import { Options, parse } from "json2csv"
// @ts-ignore
import unwind from "json2csv/lib/transforms/unwind"

export const generateCSVFromWorkOrders = <D extends IWorkOrderFragment>(items: D[]) => {
  const transforms: Options<D>["transforms"] = [
    unwind({ paths: ["assigned_teams", "assignees", "assets", "categories"] }),
  ]

  const fields: Options<D>["fields"] = [
    {
      label: "Work Order Number",
      value: "work_order_number",
    },
    {
      label: "Name",
      value: "name",
    },
    {
      label: "Priority",
      value: "priority",
    },
    {
      label: "Status",
      value: "status",
    },
    {
      label: "Team",
      value: "assigned_teams.team.name",
    },
    {
      label: "Assignee",
      value: (row: any) => {
        const assignee = row.assignees as IWorkOrderFragment["assignees"][0]
        if (assignee) return `${assignee.user.first_name} ${assignee.user.last_name}`
        return ""
      },
    },
    {
      label: "Due Date",
      value: "due_date",
    },
    {
      label: "Asset",
      value: "assets.asset.name",
    },
    {
      label: "Categories",
      value: "categories.category.label",
    },
  ]

  return parse<D>(items, { fields, transforms })
}

export const exportWorkOrdersAsCSV = <D extends IWorkOrderFragment>(items: D[]) => {
  const filename = `elara-export-tasks-${new Date().toISOString().slice(0, 10)}.csv`
  const csv = generateCSVFromWorkOrders(items)

  download(csv, filename, "text/csv")
}
