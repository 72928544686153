import { Flex } from "@components/layout"
import { SelectPopover, SelectPopoverProps } from "@components/shared/single-select"
import toast from "@components/shared/toast"
import { uuid } from "@elara/db"
import { orderBy } from "@elara/select"
import { IUploadCategoryFragment } from "@graphql/documents/upload.generated"
import {
  useAllUploadTagsQuery,
  useInsertUploadTagMutation,
} from "@graphql/documents/upload.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import { useControllableState } from "@hooks/use-controllable-state"
import i18n from "@i18n"
import { transformColors } from "@pages/settings/work-order-categories/color-picker"
import { CategoryColors, categoryColors } from "@styles"
import { TreeLike } from "@utils/tree"
import classNames from "classnames"
import { useState } from "react"
import { useMemo } from "react"
import { useEffect } from "react"
import { v4 } from "uuid"

import { Item } from "./combobox-select"
import {
  MultiSelectPopover,
  MultiSelectPopoverProps,
  MultiSelectSummary,
} from "./multi-select"

export const CategoryColor = ({
  color,
  className,
}: {
  color: string | null | undefined
  className?: string
}) => (
  <div
    className={classNames("w-3 h-3 ml-px rounded-full shrink-0", className)}
    style={{ backgroundColor: color ?? "black" }}></div>
)

type CategoryColorTagProps = {
  label: string
  color: string | null
  className?: string
}
export function CategoryColorTag({ color, label, className }: CategoryColorTagProps) {
  return (
    <Flex row align="center" className={className}>
      <CategoryColor color={color} className="mr-2" />
      <span className="min-w-0 truncate">{label}</span>
    </Flex>
  )
}

export const CategoryColorNames: Record<keyof CategoryColors, string> = {
  blue: i18n.t("settings:categories.colors.blue"),
  brown: i18n.t("settings:categories.colors.brown"),
  deepBlue: i18n.t("settings:categories.colors.deepBlue"),
  deepGreen: i18n.t("settings:categories.colors.deepGreen"),
  gray: i18n.t("settings:categories.colors.gray"),
  green: i18n.t("settings:categories.colors.green"),
  lightGray: i18n.t("settings:categories.colors.lightGray"),
  orange: i18n.t("settings:categories.colors.orange"),
  pink: i18n.t("settings:categories.colors.pink"),
  purple: i18n.t("settings:categories.colors.purple"),
  red: i18n.t("settings:categories.colors.red"),
  yellow: i18n.t("settings:categories.colors.yellow"),
}

const useUploadCategorySelect = (
  args: {
    onChange?: (value: uuid[]) => void
    summaryMode?: "count" | "summary"
    isOpen?: boolean
    value?: uuid[]
  } = {}
) => {
  const { summaryMode, isOpen, onChange } = args
  const [, insertCategory] = useInsertUploadTagMutation()

  const editCategoryScope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)

  const [newlabel, setNewlabel] = useState<string | null>(null)
  const [addCategory, setAddCategory] = useState<uuid | null>(null)

  const colors = transformColors(categoryColors).map((color) => ({
    value: color.value,
    searchValue: color.value,
    color: color.value,
    label: (
      <CategoryColorTag
        color={color.value}
        label={CategoryColorNames[color.name as keyof CategoryColors]}
      />
    ),
  }))

  const [queryResult] = useAllUploadTagsQuery({})

  const options = useMemo(
    () =>
      orderBy(queryResult.data?.upload_category ?? [], { label: "asc" }).map(
        (category) => ({
          value: category.id,
          searchValue: category.label ?? "",
          color: category.color,
          label: <span className="truncate">{category.label}</span>,
          icon: <CategoryColor color={category.color} />,
        })
      ),
    [queryResult.data?.upload_category]
  )

  const onCreate = async (inputValue: string) => {
    const id = v4()

    setNewlabel(inputValue)
    setAddCategory(id)
    return id
  }

  const createCategory = async (color?: string | null) => {
    try {
      if (color && addCategory && newlabel) {
        const categoryInsertRes = await insertCategory(
          {
            id: addCategory,
            label: newlabel,
            color,
          },
          editCategoryScope.context()
        )

        if (categoryInsertRes.error) {
          toast.error(
            i18n.t("common:messages.token_create_failure", {
              token: i18n.t("tasks:fields.category", { count: 1 }),
            })
          )
        } else {
          onChange?.([addCategory].concat(args.value ?? []))
        }
      }
    } finally {
      setAddCategory(null)
    }
  }
  const onChangeDuringAddCategory = async (value: string[]) => {
    const color = value.find((v) => v.startsWith("#"))
    createCategory(color)
  }

  useEffect(() => {
    if (!isOpen) {
      createCategory(colors[Math.floor(Math.random() * colors.length)].value)
      setNewlabel(null)
      setAddCategory(null)
    }
  }, [isOpen])

  return {
    onCreate,
    options,
    placeholder: i18n.t("common:select_token", {
      token: i18n.t("tasks:fields.category", { count: 1 }),
    }),
    title: i18n.t("tasks:fields.category", { count: 1 }),
    valueToString: (s: string) => s,
    items: options,
    colors,
    newlabel,
    addCategory,
    setAddCategory,
    onChangeDuringAddCategory,
    renderCompactSelectedValues: (values: TreeLike<Item<string>>[]) => {
      const mode = summaryMode ?? "count"

      return (
        <MultiSelectSummary
          items={values}
          limit={3}
          itemsToIcons={(cat) => <CategoryColor color={cat.color} key={cat.value} />}
          mode={mode}
          firstItemLabel={values[0]?.searchValue}
          countLabel={i18n.t("tasks:fields.category", { count: 2 })}
          iconBorderClass="border-gray-100"
          className={classNames("text-gray-900", {
            "font-medium": mode === "count" && values.length,
          })}
        />
      )
    },
  }
}

export type UploadCategorySingleSelectProps = Partial<SelectPopoverProps<string>>

export function UploadCategorySingleSelect({ ...props }: UploadCategorySingleSelectProps) {
  const selectProps = useUploadCategorySelect()
  return <SelectPopover {...selectProps} {...props} />
}

export type UploadCategorySelectProps = React.PropsWithChildren<
  Partial<MultiSelectPopoverProps<string>>
> & {
  uploadCategoryIsSelectable?: (category: IUploadCategoryFragment) => boolean

  summaryMode?: "count" | "summary"
}

export function UploadCategoryMultiSelect(props: UploadCategorySelectProps) {
  const [isOpen, onOpenChange] = useControllableState({
    prop: props.isOpen,
    defaultProp: false,
    onChange: props.onOpenChange,
  })
  const selectProps = useUploadCategorySelect({
    summaryMode: props.summaryMode,
    isOpen,
    onChange: props.onChange,
    value: props.value,
  })
  const {
    addCategory,
    placeholder,
    colors,
    items,
    options,
    onCreate,
    onChangeDuringAddCategory,
  } = selectProps

  return (
    <>
      <MultiSelectPopover
        {...selectProps}
        {...props}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        hasCheckbox={addCategory ? false : true}
        placeholder={
          addCategory
            ? i18n.t("common:select_token", { token: i18n.t("common:color") })
            : placeholder
        }
        comboBoxKey={addCategory ? "comboBox" : "default"}
        value={props.value}
        items={addCategory ? colors : items}
        retainedItems={addCategory ? options : undefined}
        onChange={addCategory ? onChangeDuringAddCategory : props.onChange}
        onCreate={onCreate}
        title={i18n.t("tasks:fields.category", { count: 1 })}>
        {props.children && props.children}
      </MultiSelectPopover>
    </>
  )
}
