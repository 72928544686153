import Toggle from "@components/shared/toggle"
import { ServerFeatureFlag, useFeatureFlags } from "@contexts/feature-flag-context"
import { getUser } from "@contexts/user-context"
import {
  useServerFeatureFlagsQuery,
  useToggleLocationFeatureFlagMutation,
} from "@graphql/documents/feature-flag.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"

const FeatureFlagsPage = () => {
  const { featureFlags, updateClientFeatureFlag, clientFlags } = useFeatureFlags()

  const [queryRes] = useServerFeatureFlagsQuery()
  const serverFlags = (queryRes.data?.feature_flag ?? []) as {
    id: ServerFeatureFlag
    description: string
  }[]

  const [, toggleLocationFeatureFlag] = useToggleLocationFeatureFlagMutation()
  const accountScope = usePermissionScope(IPermissionScopeEnum.AppAccountManagement)

  const handleToggle = async (id: ServerFeatureFlag, enabled: boolean) => {
    const { location } = getUser()

    await toggleLocationFeatureFlag(
      { locationId: location.id, featureFlagId: id, enabled },
      accountScope.context()
    )
  }

  return (
    <div className="p-5">
      <div className="space-y-5">
        <h3 className="text-3xl font-bold">Feature Flags</h3>

        <div className="space-y-5">
          <h3 className="font-bold">Client Flags</h3>
          {!Object.keys(featureFlags).length && (
            <span className="text-gray-500">There are no feature flags set up yet.</span>
          )}
          {clientFlags.map((flag) => (
            <div key={flag.id} className="flex items-center gap-3">
              <Toggle
                checked={!!featureFlags[flag.id]}
                onChange={(e) => updateClientFeatureFlag(flag.id, e.target.checked)}
              />
              <span className="whitespace-pre rounded bg-blue-deep px-2 font-mono text-sm font-medium leading-relaxed text-gray-100">
                {flag.id}
              </span>
              <span className="text-gray-500">{flag.description}</span>
            </div>
          ))}
        </div>

        <div className="space-y-5">
          <h3 className="font-bold">Server Flags</h3>
          {serverFlags.map((flag) => (
            <div key={flag.id} className="flex items-center gap-3">
              <Toggle
                checked={!!featureFlags[flag.id]}
                onChange={() => {
                  handleToggle(flag.id, !featureFlags[flag.id])
                }}
              />
              <span className="whitespace-pre rounded bg-blue-deep px-2 font-mono text-sm font-medium leading-relaxed text-gray-100">
                {flag.id}
              </span>
              <span className="text-gray-500">{flag.description}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FeatureFlagsPage
