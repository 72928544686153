import LoadingIndicator from "@components/shared/loading-indicator"
import { BlockElementConfigBase } from "@elara/db"
import { IBlockElementTypeEnum } from "@elara/db/src/db.generated"
import { IUserTagFragment } from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { PencilSimpleLine } from "@phosphor-icons/react"
import { parseDate } from "@utils"
import { formatDate } from "@utils/date"
import classNames from "classnames"
import { useState } from "react"

import { AssetStateBlockElement } from "./elements/block-element-asset-state"
import { CheckboxBlockElement } from "./elements/block-element-checkbox"
import { ChoiceBlockElement } from "./elements/block-element-choice"
import { ConsumablesBlockElement as NewConsumablesBlockElement } from "./elements/block-element-consumable-list"
import { HeadingBlockElement } from "./elements/block-element-heading"
import { InspectionBlockElement } from "./elements/block-element-inspection"
import { MediaBlockElement } from "./elements/block-element-media"
import { MeterReadingBlockElement } from "./elements/block-element-meter-reading"
import { ParagraphBlockElement } from "./elements/block-element-paragraph"
import { TextFieldBlockElement } from "./elements/block-element-textfield"
import { ToleranceCheckBlockElement } from "./elements/block-element-tolerance-check"
import { BlockElementFragment, BlockElementProps } from "./elements/block-element-types"
import { YesNoBlockElement } from "./elements/block-element-yes-no"

export function BlockElementItem(props: BlockElementProps) {
  const elementType = props.element.element_type

  switch (props.element.element_type) {
    case IBlockElementTypeEnum.AssetState:
      return <AssetStateBlockElement {...props} element={props.element} />
    case IBlockElementTypeEnum.Consumables:
      return <NewConsumablesBlockElement {...props} element={props.element} />
    case IBlockElementTypeEnum.Checkbox:
      return <CheckboxBlockElement {...props} element={props.element} />
    case IBlockElementTypeEnum.Heading:
      return <HeadingBlockElement {...props} element={props.element} />
    case IBlockElementTypeEnum.Paragraph:
      return <ParagraphBlockElement {...props} element={props.element} />
    case IBlockElementTypeEnum.Choice:
      return <ChoiceBlockElement {...props} element={props.element} />
    case IBlockElementTypeEnum.Inspection:
      return <InspectionBlockElement {...props} element={props.element} />
    case IBlockElementTypeEnum.Media:
      return <MediaBlockElement {...props} element={props.element} />
    case IBlockElementTypeEnum.MeterReading:
      return <MeterReadingBlockElement {...props} element={props.element} />
    case IBlockElementTypeEnum.Text:
      return <TextFieldBlockElement {...props} element={props.element} />
    case IBlockElementTypeEnum.ToleranceCheck:
      return <ToleranceCheckBlockElement {...props} element={props.element} />
    case IBlockElementTypeEnum.YesNo:
      return <YesNoBlockElement {...props} element={props.element} />

    default:
      throw new Error("Unexpected type: " + elementType)
  }
}

type LastEditedInfoProps = {
  lastEditedAt?: Date | null
  lastEditedBy?: IUserTagFragment | null
  required?: boolean | null
  hasResponse?: boolean | null
}
const LastEditedInfo = (props: LastEditedInfoProps) => {
  if (!props.hasResponse && props.required) {
    return (
      <div className="mt-2 text-xs text-gray-600">{i18n.t("common:forms.required")}</div>
    )
  }

  if (!props.lastEditedAt || !props.lastEditedBy) return null

  return (
    <p className="mt-2 flex items-center text-xs text-gray-600">
      <PencilSimpleLine className="mr-1 inline" /> {props.lastEditedBy.first_name}{" "}
      {props.lastEditedBy.last_name} am {formatDate(props.lastEditedAt, "Pp")}
    </p>
  )
}

const hasElementResponse = (element: BlockElementFragment) => {
  if (element.element_type === IBlockElementTypeEnum.Media) {
    return element.uploads.length > 0
  }
  return !!element.response
}

export function BlockElement(props: BlockElementProps) {
  const [updateCounter, setUpdateCounter] = useState(0)

  return (
    <div
      className={classNames("relative p-3 border-dashed rounded bg-white", {
        "border ": props.element.element_type !== IBlockElementTypeEnum.Heading,
        "border-gray-200": !props.validationError,
        "border-red-300": props.validationError,
      })}>
      <BlockElementItem
        element={props.element}
        blockResponseUpdate={props.blockResponseUpdate}
        updateUploads={props.updateUploads}
        updateResponse={async (...args) => {
          try {
            setUpdateCounter((c) => c + 1)
            await props.updateResponse(...args)
          } finally {
            setUpdateCounter((c) => c - 1)
          }
        }}
      />

      {props.hideResponseInfo ? (
        props.element.config?.required && (
          <div className="mt-2 text-xs text-gray-600">{i18n.t("common:required")}</div>
        )
      ) : (
        <LastEditedInfo
          lastEditedAt={parseDate(props.element.response_last_edited_at)}
          lastEditedBy={props.element.response_last_edited_by}
          required={(props.element.config as BlockElementConfigBase).required}
          hasResponse={hasElementResponse(props.element)}
        />
      )}

      {updateCounter > 0 && (
        <LoadingIndicator
          size={20}
          className={classNames("!absolute top-3 right-3 text-gray-500")}
        />
      )}
    </div>
  )
}
