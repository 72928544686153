import { PopoverContentProps } from "@components/shared/popover"
import toast from "@components/shared/toast"
import { WorkOrderCategoryMultiSelect } from "@components/work-order/work-order-category-select"
import { IWorkOrderDataViewFragment } from "@graphql/documents/fragments.generated"
import {
  useDeleteWorkOrderXWorkOrderCategoryMutation,
  useInsertWorkOrderXWorkOrderCategoryMutation,
} from "@graphql/documents/work-order.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"

type WorkOrderCategoryQuickEditProps = React.PropsWithChildren<{
  workOrder: IWorkOrderDataViewFragment
  compact?: boolean
  isOpen?: boolean
  onOpenChange?: (isOpen: boolean) => void
  popoverContentProps?: PopoverContentProps
}>

export const WorkOrderCategoryQuickEdit = ({
  workOrder,
  ...props
}: WorkOrderCategoryQuickEditProps) => {
  const scope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)

  const [, insertWorkOrderCategory] = useInsertWorkOrderXWorkOrderCategoryMutation()
  const [, deleteWorkOrderCategory] = useDeleteWorkOrderXWorkOrderCategoryMutation()

  const existingWoCategories = workOrder.categories.map(
    (category) => category.work_order_category_id
  )

  return (
    <WorkOrderCategoryMultiSelect
      {...props}
      value={existingWoCategories}
      summaryMode="summary"
      classForTrigger="!bg-white"
      showArrow={false}
      onChange={async (e) => {
        const selectedCategory = e

        const addCt = selectedCategory.filter((ct) => !existingWoCategories.includes(ct))

        const deleteCt = existingWoCategories.filter((ct) => !selectedCategory.includes(ct))
        const res =
          addCt.length > 0 &&
          (await insertWorkOrderCategory(
            { work_order_category_id: addCt[0], work_order_id: workOrder.id },
            scope.context()
          ))

        const deletres =
          deleteCt.length > 0 &&
          (await deleteWorkOrderCategory(
            { work_order_id: workOrder.id, work_order_category_id: deleteCt[0] },
            scope.context()
          ))

        if (res && res.error) {
          toast.error("Kategorie konnte nicht aktualisiert werden.")
        }
        if (deletres && deletres.error) {
          toast.error("Kategorie konnte nicht gelöscht werden.")
        }
      }}>
      {props.children && props.children}
    </WorkOrderCategoryMultiSelect>
  )
}

export default WorkOrderCategoryQuickEdit
