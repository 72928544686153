import { PropsWithChildren, useMemo } from "react"
import React from "react"

import { useDataViewConfigContext } from "./data-view-config"
import { useColumn } from "./lib/hooks"

const useDataViewTable = () => {
  const ctx = useDataViewConfigContext()
  const c = useColumn({ id: ctx.id, toggleSortState: ctx.actions.updateOrderBy })

  return { ...c }
}

const DataViewTableContext = React.createContext<ReturnType<typeof useDataViewTable>>(
  undefined!
)

export function useDataViewTableContext() {
  const context = React.useContext(DataViewTableContext)
  if (!context) {
    throw new Error("useDataViewTableContext must be used within a DataViewTable")
  }
  return context
}

export function DataViewTable(
  props: PropsWithChildren<{ containerRef: React.Ref<HTMLDivElement> }>
) {
  const ctx = useDataViewConfigContext()
  const dataViewTable = useDataViewTable()

  const columnWidths = useMemo(() => {
    return ctx.renderedColumns
      .map((c) => dataViewTable.getColumnWidth(c, false))
      .reduce((style, width, idx) => {
        return {
          ...style,
          [`--col-${idx}-width`]: width,
        }
      }, {}) as React.CSSProperties
  }, [ctx.renderedColumns, dataViewTable.getColumnWidth])

  return (
    <DataViewTableContext.Provider value={dataViewTable}>
      <div style={{ ...columnWidths }} ref={props.containerRef} className="flex-1">
        {props.children}
      </div>
    </DataViewTableContext.Provider>
  )
}
