import { ActionMenu, Button } from "@components/shared"
import toast from "@components/shared/toast"
import { uuid } from "@elara/db"
import {
  useDeleteAssetGroupMutation,
  useInsertAssetGroupMutation,
  useUpdateAssetGroupNameMutation,
} from "@graphql/documents/asset-group.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { DotsThreeVertical, PencilSimpleLine, TrashSimple } from "@phosphor-icons/react"
import { useCallback, useEffect, useRef, useState } from "react"

type AssetGroupsActionMenuProps = {
  onEdit: () => void
  onDelete: () => void
  inCreateMode?: boolean
}
export const AssetGroupsActionMenu = ({
  onEdit,
  onDelete,
  inCreateMode,
}: AssetGroupsActionMenuProps) => {
  const editAssetGroupsScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)
  const deleteAssetGroupsScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderDelete)

  if (!editAssetGroupsScope.hasScope && !deleteAssetGroupsScope.hasScope) return null
  return (
    <ActionMenu
      items={[
        editAssetGroupsScope.hasScope
          ? {
              key: "edit",
              icon: <PencilSimpleLine />,
              label: i18n.t("edit"),
              action: onEdit,
              disabled: inCreateMode,
            }
          : null,
        deleteAssetGroupsScope.hasScope
          ? {
              key: "delete",
              icon: <TrashSimple />,
              label: i18n.t("common:delete"),
              action: onDelete,
            }
          : null,
      ]}>
      <Button
        size="small"
        type="tertiary"
        icon={DotsThreeVertical}
        className="!text-gray-600"
      />
    </ActionMenu>
  )
}

export const useAssetGroupsEdit = (label: string, AssetGroupsId?: uuid) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const [isEditing, setIsEditing] = useState(false)
  const [isCreating, setIsCreating] = useState(false)

  const [editedLabel, setEditedLabel] = useState(label)

  const [, updateAssetGroupsName] = useUpdateAssetGroupNameMutation()
  const [, insertAssetGroups] = useInsertAssetGroupMutation()
  const [, deleteAssetGroups] = useDeleteAssetGroupMutation()

  const createAssetGroupsScope = usePermissionScope(IPermissionScopeEnum.AppAssetCreate)
  const editAssetGroupsScope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)
  const deleteAssetGroupsScope = usePermissionScope(
    IPermissionScopeEnum.AppAssetArchiveDelete
  )

  useEffect(() => {
    if (isEditing && label !== editedLabel) {
      setEditedLabel(label)
    }
  }, [label, isEditing])

  const startEdit = useCallback(() => {
    setIsEditing(true)
    // to set focus ring
    setTimeout(() => inputRef.current?.focus(), 200)
  }, [inputRef])

  const startCreate = useCallback(() => {
    setIsCreating(true)
    // to set focus ring
    setTimeout(() => inputRef.current?.focus(), 200)
  }, [inputRef])

  const stopEdit = () => {
    setIsEditing(false)
  }

  const stopCreate = () => {
    setIsCreating(false)
  }

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedLabel(e.target.value)
  }, [])

  const onEditOrCreateAssetGroups = async () => {
    if (isEditing && editedLabel.length === 0) {
      toast.warning("Objektgruppe kann nicht leer sein")
      return
    }
    if (isEditing) {
      setIsEditing(false)
      const res = await updateAssetGroupsName(
        { id: AssetGroupsId!, name: editedLabel },
        editAssetGroupsScope.context()
      )
      if (res.error) {
        toast.error("Objektgruppe konnte nicht gespeichert werden.")
      }
    } else if (isCreating) {
      setIsCreating(false)
      const res = await insertAssetGroups(
        { name: editedLabel },
        createAssetGroupsScope.context()
      )
      setEditedLabel("")
      if (res.error) {
        toast.error("Objektgruppenänderung konnte nicht gespeichert werden.")
      }
    }
  }

  const onDeleteAssetGroups = async () => {
    const res = await deleteAssetGroups(
      { id: AssetGroupsId! },
      deleteAssetGroupsScope.context()
    )
    if (res && res.data?.delete_asset_group_by_pk) {
      toast.success("Objektgruppe gelöscht.")
    }
    if (res.error) {
      toast.error("Objektgruppe konnte nicht gelöscht werden.")
    }
  }

  return {
    inputRef,
    isEditing,
    isCreating,
    editedLabel,
    startEdit,
    startCreate,
    stopEdit,
    stopCreate,
    onChange,
    onEditOrCreateAssetGroups,
    onDeleteAssetGroups,
  }
}
