import { OpenCloseToggleIcon } from "@components/shared/open-close-toggle"
import { Data } from "@elara/select"
import { ErrorBoundary } from "@sentry/react"
import { cn } from "@utils"

import { useDataViewChunkedFetcherContext } from "./data-view-chunked-fetcher"
import { useDataViewConfigContext } from "./data-view-config"
import { useDataViewGroupByContext } from "./data-view-group-by"
import { useDataViewTreeContext } from "./data-view-tree-toggle"
import { GroupBySummaryItem, RowItem } from "./data-view-types"

function GroupHeader(props: { group: GroupBySummaryItem<Data> }) {
  const groupBy = useDataViewGroupByContext()
  const { renderedColumns } = useDataViewConfigContext()
  return (
    <div onClick={() => groupBy.toggleGroup(props.group.id)} className="group flex h-12">
      {renderedColumns.map((col, idx) => (
        <div
          key={col.id}
          className="box-border flex shrink-0 cursor-pointer select-none items-center border-b border-gray-100 bg-gray-50 p-4 px-3 text-sm font-medium text-gray-900 group-hover:bg-gray-100 "
          style={{
            width: `var(--col-${idx}-width)`,
            flexGrow: idx === renderedColumns.length - 1 ? 1 : 0,
          }}>
          {idx === 0 && (
            <>
              <div className="mr-1 flex items-center self-stretch">
                <OpenCloseToggleIcon
                  isOpen={!groupBy.isGroupCollapsed(props.group.id)}
                  className="shrink-0 text-lg"
                />
              </div>
              {props.group.label}
              <span className="ml-2 rounded border border-gray-300 px-1 text-center text-xs text-gray-500">
                {props.group.size}
              </span>
            </>
          )}
        </div>
      ))}
    </div>
  )
}

export type DataViewTableRowProps<D extends Data> = {
  item: RowItem<D>
  onRowClick: (d: D) => void
}

export const DataViewTableRow = <D extends Data>(props: DataViewTableRowProps<D>) => {
  const { renderedColumns } = useDataViewConfigContext()
  const groupBy = useDataViewGroupByContext()
  const tree = useDataViewTreeContext()
  const ctx = useDataViewChunkedFetcherContext()

  if (props.item.type === "group") {
    return <GroupHeader group={props.item.group} />
  }
  const row = props.item.row

  if (props.item.group && groupBy.isGroupCollapsed(props.item.group.id)) {
    return null
  }
  if (!row) {
    return (
      <div className="flex h-12 border-b border-gray-100">
        {renderedColumns.map((col, idx) => (
          <div
            key={col.id}
            className="box-border flex shrink-0 items-center justify-center p-4"
            style={{
              width: `var(--col-${idx}-width)`,
              flexGrow: idx === renderedColumns.length - 1 ? 1 : 0,
            }}>
            <div className="h-5 w-full animate-pulse rounded-md bg-gray-100" />
          </div>
        ))}
      </div>
    )
  }
  const level = props.item.level

  return (
    <div
      className={cn("flex flex-row text-sm group divide-x divide-gray-100", {
        // "bg-gray-50": isSelected,
      })}
      // tabIndex={isSelected ? 0 : -1}
      onClick={() => props.onRowClick(row)}
      // key={typeof props.row.id === "string" ? props.row.id : uuidv4()}
    >
      {renderedColumns.map((col, idx) => {
        const cell = col.Cell?.(row, {})
        return (
          <div
            key={col?.id}
            className={cn(
              "flex items-center pr-2 py-2 border-b border-gray-100 group-hover:bg-grey-7 cursor-pointer overflow-hidden flex-shrink-0"
            )}
            style={{
              width: `var(--col-${idx}-width)`,
              paddingLeft: idx === 0 ? level * 22 + 8 : 8,
              flexGrow: idx === renderedColumns.length - 1 ? 1 : 0,
            }}>
            {/* {hasBatchSelectFeature && idx === 0 && (
              <label
                onClick={(event) => event.stopPropagation()}
                className="mr-1 flex h-full w-8 items-center justify-center">
                <CheckboxInput
                  checked={batchSelect.isSelected}
                  onChange={batchSelect.toggle}
                />
              </label>
            )} */}
            {idx === 0 && tree?.numberOfChildren && tree.numberOfChildren?.(row) > 0 && (
              <div
                className="-my-1.5 -ml-1.5 mr-0.5 flex w-8 shrink-0 items-center justify-center self-stretch rounded hover:bg-gray-100"
                onPointerEnter={() => {
                  ctx.preloadChildren(row, level)
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  tree.toggle(tree.getId(row))
                }}>
                <OpenCloseToggleIcon isOpen={tree.isExpanded(tree.getId(row))} />
              </div>
            )}
            {cell &&
              (typeof cell === "string" ? (
                <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                  {cell}
                </span>
              ) : (
                <ErrorBoundary>
                  <div
                    className={cn(
                      "min-w-0 flex-1 first:pl-1"
                      //  props.cellClass
                    )}>
                    {cell}
                  </div>
                </ErrorBoundary>
              ))}
          </div>
        )
      })}
    </div>
  )
}
