import { UserAvatar } from "@components/shared/avatar"
import { Item } from "@components/shared/combobox-select"
import {
  MultiSelectPopover,
  MultiSelectPopoverProps,
  MultiSelectSummary,
} from "@components/shared/multi-select"
import { SelectPopover, SelectPopoverProps } from "@components/shared/single-select"
import {
  useAssetManufacturersQuery,
  useCreateAssetManufacturerMutation,
} from "@graphql/documents/asset-manufacturer.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { naturalCompare } from "@utils"
import { TreeLike } from "@utils/tree"
import { useCallback, useMemo } from "react"

const useManufacturerSelect = () => {
  const [assetGroups] = useAssetManufacturersQuery({ requestPolicy: "cache-first" })

  const items = useMemo(
    () =>
      (assetGroups.data?.asset_manufacturer ?? [])
        .slice()
        .sort((a, b) => naturalCompare(a.name, b.name))
        .map((manufacturer) => ({
          value: manufacturer.id,
          searchValue: manufacturer.name,
          label: <> {manufacturer.name} </>,
        })),
    [assetGroups.data?.asset_manufacturer]
  )

  const createScope = usePermissionScope(
    IPermissionScopeEnum.AppManufacturerCreateEditDelete
  )
  const [, createAssetManufacturer] = useCreateAssetManufacturerMutation()
  const onCreate = useCallback(
    async (name: string): Promise<string> => {
      const result = await createAssetManufacturer({ name }, createScope.context())
      const id = result.data?.insert_asset_manufacturer_one?.id
      if (result.error || !id) throw result.error
      return id
    },
    [createAssetManufacturer]
  )

  return {
    items,
    onCreate,
    placeholder: i18n.t("common:select_token", {
      token: i18n.t("assets:fields.manufacturer"),
    }),
    name: i18n.t("assets:fields.manufacturer"),
    valueToString: (s: string | null) =>
      s ?? (i18n.t("tasks:filters.users.nobody") as string),
    title: i18n.t("assets:fields.manufacturer"),
    renderCompactSelectedValues: (values: TreeLike<Item<string>>[]) => {
      const selectedManufacturer = (assetGroups.data?.asset_manufacturer ?? []).filter(
        (u) => values.some((v) => v.value === u.id)
      )
      const firstManufacturer = selectedManufacturer[0]

      return (
        <MultiSelectSummary
          items={selectedManufacturer}
          limit={3}
          itemsToIcons={(manufacturer) => (
            <UserAvatar
              user={{
                first_name: manufacturer.name,
                last_name: "",
                avatar: null,
              }}
              key={manufacturer.id}
              className="!border-white"
              size={20}
            />
          )}
          mode={"summary"}
          firstItemLabel={`${firstManufacturer.name ?? ""}`}
          countLabel="Hersteller"
          iconBorderClass="border-gray-100"
        />
      )
    },
  }
}

type AssetManufacturerSingleSelectProps = Partial<SelectPopoverProps<string>>
export const AssetManufacturerSingleSelect = (
  props: AssetManufacturerSingleSelectProps
) => {
  const manufacturerProps = useManufacturerSelect()
  return <SelectPopover {...manufacturerProps} {...props} />
}

type AssetManufacturerMultiSelectProps = Partial<MultiSelectPopoverProps<string>>
export const AssetManufacturerMultiSelect = (props: AssetManufacturerMultiSelectProps) => {
  const manufacturerProps = useManufacturerSelect()
  return <MultiSelectPopover {...manufacturerProps} {...props} />
}
