import { VStack } from "@components/layout"
import { Button } from "@components/shared"
import i18n from "@i18n"
import { useNavigate } from "react-router-dom"

type ErrorPageProps = {
  resetError?: () => void
}

const ErrorPage: React.FC<ErrorPageProps> = ({ resetError }) => {
  const navigate = useNavigate()

  const handleBackToHomepage = () => {
    navigate("/")
    resetError?.()
    window.location.reload()
  }

  return (
    <VStack space={16} className="m-auto max-w-[200px]">
      <span className="text-sm text-gray-700">{i18n.t("navigation:error.message")}</span>

      <Button onClick={handleBackToHomepage}>
        {i18n.t("navigation:error.back_to_homepage")}
      </Button>
    </VStack>
  )
}

export default ErrorPage
