import { Center, HStack, Spacer } from "@components/layout"
import { Button } from "@components/shared"
import { PopoverContent, PopoverRoot, PopoverTrigger } from "@components/shared/popover"
import { Tooltip } from "@components/shared/tooltip"
import { useAnalytics } from "@contexts/analytics-context"
import { IAssetStateVariantTypeEnum } from "@elara/db"
import {
  IAssetDataViewFragment,
  IAssetManufacturerFragment,
} from "@graphql/documents/fragments.generated"
import { uuid } from "@graphql/scalars"
import { useDisclosure } from "@hooks"
import i18n from "@i18n"
import { Calendar, Headset, ListPlus, Rows } from "@phosphor-icons/react"
import { colors } from "@styles"
import { cn } from "@utils"
import { flattenAndPrefixObject } from "@utils/analytics"
import classNames from "classnames"
import React from "react"

import { ManufacturerDialogContent } from "./manufacturer-field"

const Tag = (props: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    {...props}
    className={classNames(
      props.className,
      "py-1 px-2 border border-gray-300 text-gray-500 text-xs overflow-hidden whitespace-nowrap text-ellipsis rounded"
    )}
  />
)

const WorkOrderCountTag = (props: { count: number }) => {
  const { count } = props

  return (
    <Tooltip
      content={i18n.t("assets:messages.unfinished_task", { count })}
      contentProps={{
        side: "top",
        align: "start",
      }}>
      <Tag
        onClick={(e) => e.stopPropagation()}
        style={{ display: "flex", alignItems: "center" }}>
        <Rows />
        <span style={{ marginLeft: 4, fontSize: 12, marginTop: 1 }}>{count}</span>
      </Tag>
    </Tooltip>
  )
}

const ManufacturerInfo = (props: { manufacturer: IAssetManufacturerFragment }) => {
  const overlay = useDisclosure()

  return (
    <PopoverRoot open={overlay.isOpen} onOpenChange={overlay.changeOpen}>
      <PopoverTrigger asChild>
        <Button
          size="small"
          type="tertiary"
          icon={Headset}
          style={{
            color:
              props.manufacturer?.phone || props.manufacturer?.email
                ? colors.blueDeep
                : colors.grey3,
          }}
          onClick={(e) => {
            e.stopPropagation()
          }}
        />
      </PopoverTrigger>
      <PopoverContent side="left" align="start">
        <ManufacturerDialogContent
          manufacturer={props.manufacturer}
          onClose={overlay.onClose}
        />
      </PopoverContent>
    </PopoverRoot>
  )
}

type AssetDataViewListItemProps = {
  asset: IAssetDataViewFragment
  onCreateWorkOrder?: (assetId: uuid) => void
}

export const AssetDataViewListItem = React.memo((props: AssetDataViewListItemProps) => {
  const { asset } = props

  const { posthog } = useAnalytics()

  return (
    <HStack
      className="box-border h-[42px] select-none"
      row
      align="center"
      space={0}
      inset={{ y: 4, right: 12 }}
      flex="1">
      <div
        className={cn("mr-2 h-2 w-2 rounded-full", {
          "bg-green-600":
            asset.current_state?.asset_state_variant?.type ===
            IAssetStateVariantTypeEnum.Online,
          "bg-red-600":
            asset.current_state?.asset_state_variant?.type ===
            IAssetStateVariantTypeEnum.Offline,
          "bg-gray-600":
            asset.current_state?.asset_state_variant?.type ===
            IAssetStateVariantTypeEnum.DoNotTrack,
        })}
      />
      <span className="shrink-0 grow-0 basis-auto overflow-hidden text-ellipsis whitespace-nowrap font-medium">
        {asset.name}
      </span>

      <span className="ml-2 line-clamp-1 max-w-full shrink grow-0 basis-auto text-gray-500">
        {asset.place?.name}
      </span>

      {asset.manufacturer?.name && (
        <div className="ml-2 flex min-w-0 flex-1 shrink basis-0 justify-end sm:hidden">
          <Tag>{asset.manufacturer?.name}</Tag>
        </div>
      )}
      <div className="hidden sm:contents">
        <Spacer />
        <HStack space={12} style={{ marginRight: 6 }}>
          {asset.manufacturer?.name && (
            <Tooltip content="Hersteller" contentProps={{ side: "top" }}>
              <Tag>{asset.manufacturer?.name}</Tag>
            </Tooltip>
          )}

          {!!asset.active_work_orders?.filter((aw) => aw.work_order)?.length && (
            <WorkOrderCountTag
              count={asset.active_work_orders?.filter((aw) => aw.work_order)?.length}
            />
          )}
        </HStack>
        {!asset.has_recurring_work_order && (
          <Tooltip
            content="Es ist kein Wartungsauftrag für das Objekt hinterlegt."
            contentProps={{ side: "top" }}>
            <Center style={{ height: 32, width: 32 }}>
              <Calendar
                height={20}
                width={20}
                color={colors.orangeMedium}
                onClick={(e) => e.stopPropagation()}
              />
            </Center>
          </Tooltip>
        )}

        {asset.manufacturer && <ManufacturerInfo manufacturer={asset.manufacturer} />}

        {props.onCreateWorkOrder && (
          <Tooltip
            content="Neuen Aufgabe für die Maschine anlegen"
            contentProps={{ side: "top" }}>
            <Button
              size="small"
              type="tertiary"
              icon={ListPlus}
              onClick={(e) => {
                e.stopPropagation()
                props.onCreateWorkOrder?.(asset.id)
                posthog?.capture(
                  "asset_list_click_new_work_order",
                  flattenAndPrefixObject(asset, "asset")
                )
              }}
            />
          </Tooltip>
        )}
      </div>
    </HStack>
  )
})
