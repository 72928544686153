import { HStack } from "@components/layout"
import { IAssetTagFragment } from "@graphql/documents/fragments.generated"
import i18n from "@i18n"

import { AssetAvatarPlaceholder, Avatar, AvatarProps, AvatarSize } from "./avatar"
import { Tooltip } from "./tooltip"

type AvatarGroupItem = {
  id?: string
  label: React.ReactNode
} & AvatarProps

export type AvatarGroupProps = {
  items: AvatarGroupItem[]
  size?: AvatarSize
  hideFirstName?: boolean
  hideExtraCount?: boolean
  showDetailOverlay?: boolean
  detailOverlayTitle?: string
}

const AvatarGroup = (props: AvatarGroupProps) => {
  if (!props.items.length) return null

  if (props.items.length === 1) {
    const [{ label, ...avatarProps }] = props.items
    return (
      <HStack space={8}>
        <Avatar size={props.size} {...avatarProps} />
        {!props.hideFirstName && label && (
          <span className="overflow-hidden text-ellipsis whitespace-nowrap text-sm">
            {label}
          </span>
        )}
      </HStack>
    )
  }

  const { showDetailOverlay = true } = props

  const firstAvatars = props.items.slice(0, 3).map(({ id, ...avatarProps }, index) => {
    return (
      <Avatar
        key={id || avatarProps.avatar?.id || index}
        size={props.size}
        {...avatarProps}
        style={{
          ...avatarProps.style,
          border: "1px solid white",
          marginLeft: index > 0 ? -4 : 0,
        }}
      />
    )
  })

  const group = (
    <HStack space={8} style={{ cursor: "default" }}>
      <div style={{ position: "relative", display: "flex", flexWrap: "nowrap" }}>
        {firstAvatars}
      </div>
      {!props.hideFirstName && props.items[0].label && (
        <span className="overflow-hidden text-ellipsis whitespace-nowrap text-sm">
          {props.items[0].label}
        </span>
      )}
      {!props.hideExtraCount && (
        <div className="text-sm" style={{ marginLeft: "0.25em" }}>{`+${
          props.items.length - 1
        }`}</div>
      )}
    </HStack>
  )

  if (!showDetailOverlay) return group

  const overlay = (
    <div className="flex flex-col space-y-2 p-3">
      {props.detailOverlayTitle && (
        <span className="text-sm text-gray-500">{props.detailOverlayTitle}</span>
      )}
      {props.items.map(({ label, id, ...avatarProps }, idx) => (
        <HStack space={8} key={id ?? avatarProps?.avatar?.id ?? idx}>
          <Avatar size={props.size} {...avatarProps} />
          {label && <span className="text-sm">{label}</span>}
        </HStack>
      ))}
    </div>
  )

  return (
    <Tooltip
      content={overlay}
      contentProps={{ align: "start", side: "left", className: "!p-0" }}>
      {group}
    </Tooltip>
  )
}

type AssetAvatarGroupProps = {
  assets: IAssetTagFragment[]
} & Omit<AvatarGroupProps, "items">

export const AssetAvatarGroup = ({
  assets,
  size,
  ...otherProps
}: AssetAvatarGroupProps) => {
  const items = assets.map((asset) => {
    return {
      id: asset.id,
      placeholder: <AssetAvatarPlaceholder size={size} />,
      avatar: asset.avatar,
      label: asset.name,
    }
  })

  return (
    <AvatarGroup
      size={size}
      items={items}
      detailOverlayTitle={i18n.t("common:asset", { count: 2 })}
      {...otherProps}
    />
  )
}

export default AvatarGroup
