import { useCallbackRef } from "@hooks"
import i18n from "@i18n"
import { Plus } from "@phosphor-icons/react"
import { cn } from "@utils"
import { forEachTreeList } from "@utils/tree"
import classNames from "classnames"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { GridList, Item as GridListItem } from "react-aria-components"

import {
  ComboboxMultiSelectProps,
  Item,
  useComboBoxMultiSelect,
} from "./combobox-select.hook"
import { ComboboxSelectItem } from "./combobox-select.item"
import ScrollArea from "./scroll-area"
import toast from "./toast"

function ComboboxMultiSelect<V>(props: ComboboxMultiSelectProps<V>) {
  const { noDataPlaceholder = i18n.t("no_data_available"), hasCheckbox = true } = props
  const comboboxValues = useComboBoxMultiSelect(props)

  const cmdListRef = useRef<HTMLDivElement>(null)

  const flatListRef = useRef(comboboxValues.treeListBag.flatList)
  const [isCreating, setIsCreating] = useState(false)

  useEffect(() => {
    flatListRef.current = comboboxValues.treeListBag.flatList
  })

  const showOnCreateOption = useMemo(() => {
    if (!comboboxValues.treeListBag.treeList.length) return true

    const input = comboboxValues.searchInput.toLowerCase()
    let numberOfSubstringMatches = 0

    forEachTreeList(comboboxValues.treeListBag.treeList, (item) => {
      if (item.searchValue.toLowerCase().includes(input)) {
        numberOfSubstringMatches += 1
      }
    })

    return numberOfSubstringMatches < 4
  }, [comboboxValues.treeListBag.treeList, comboboxValues.searchInput])

  const disabledKeys = useMemo(
    () =>
      comboboxValues.treeListBag.flatList
        .filter((t) => t.disabled)
        .map((t) => props.valueToString(t.value)),
    [comboboxValues.treeListBag.flatList, props.valueToString]
  )

  return (
    <div className={cn("relative flex min-h-0 flex-1 flex-col", props.className)}>
      <div
        className={classNames(
          "flex items-center gap-2 border-b border-b-grey-5",
          props.inputProps?.className,
          { hidden: props.inputProps?.hide }
        )}>
        {props.inputProps?.startAdornment}

        <input
          value={comboboxValues.searchInput}
          onChange={(e) => comboboxValues.setSearchInput(e.target.value)}
          className="w-full p-3 placeholder:text-gray-400 sm:p-2 sm:text-sm"
          placeholder={props.placeholder ?? i18n.t("common:search")}
          onKeyUp={(e) => {
            if (e.key === "ArrowDown" || e.key === "ArrowUp") {
              cmdListRef.current?.focus()
            }
          }}
        />

        {props.inputProps?.endAdornment}
      </div>

      <ScrollArea vertical horizontal type="auto" viewportAsChild>
        <GridList
          selectionMode={hasCheckbox ? "multiple" : "single"}
          aria-label="List of items"
          ref={cmdListRef}
          disabledKeys={disabledKeys}
          className="relative cursor-default select-none"
          style={{ maxHeight: Math.min(props.listMaxHeight ?? 160) }}
          selectedKeys={comboboxValues.selectedValues.map((v) => props.valueToString(v))}
          onSelectionChange={(change) => {
            const selectedValueStrings = Array.from(change)
            const newSelectedItems = comboboxValues.treeListBag.flatList.filter((item) =>
              selectedValueStrings.includes(props.valueToString(item.value))
            )
            props.onChange?.(
              newSelectedItems.map((item) => item.value),
              newSelectedItems,
              true
            )
          }}>
          {comboboxValues.treeListBag.treeList &&
          comboboxValues.treeListBag.treeList.length > 0 ? (
            comboboxValues.treeListBag.treeList.map((child, idx) => {
              if (props.groupBy) {
                return (
                  <React.Fragment key={props.valueToString(child.value) + idx}>
                    {child.children?.map((item, idx) => {
                      return (
                        <ComboboxSelectItem
                          key={props.valueToString(item.value)}
                          item={item}
                          valueToString={props.valueToString}
                          getNodeDisclosure={comboboxValues.treeListBag.getNodeDisclosure}
                          onSelect={comboboxValues.onSelect}
                          size={props.size}
                          header={
                            props.groupBy && idx === 0 ? (
                              <div
                                className={classNames(
                                  "sticky top-0 z-10 bg-gray-50 px-3 py-0.5 text-sm text-gray-500",
                                  { "bg-white": !child.label }
                                )}>
                                {child.label}
                              </div>
                            ) : undefined
                          }
                        />
                      )
                    })}
                  </React.Fragment>
                )
              }
              return (
                <ComboboxSelectItem
                  key={props.valueToString(child.value)}
                  item={child}
                  valueToString={props.valueToString}
                  getNodeDisclosure={comboboxValues.treeListBag.getNodeDisclosure}
                  onSelect={comboboxValues.onSelect}
                  size={props.size}
                />
              )
            })
          ) : (
            <>
              <GridListItem className="rounded-md bg-white p-2 pl-3 text-sm text-gray-500">
                {!props.items.length ? noDataPlaceholder : i18n.t("common:no_results")}
              </GridListItem>
            </>
          )}
        </GridList>
      </ScrollArea>
      {props.onCreate &&
        showOnCreateOption &&
        comboboxValues.searchInput.trim().length > 0 && (
          <div className="sticky bottom-0 z-10 bg-white">
            {isCreating ? (
              <div className="flex w-full items-center bg-gray-50 p-2 pl-3 text-sm font-medium text-gray-500">
                <div className="mr-2 h-5 w-5 animate-spin rounded-full border-4 border-solid border-gray-200 border-r-gray-400 border-t-gray-400" />
                {i18n.t("create_placeholder")}
              </div>
            ) : (
              <button
                className="flex w-full items-center p-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                disabled={isCreating}
                onClick={async () => {
                  setIsCreating(true)
                  try {
                    const value = await props.onCreate?.(comboboxValues.searchInput)
                    if (value) {
                      const item = flatListRef.current.find((i) => i.value === value)
                      if (item) {
                        setIsCreating(false)
                        // TODO:
                        const selectedValueStrings = comboboxValues.selectedValues.map(
                          props.valueToString
                        )
                        selectedValueStrings.push(props.valueToString(item.value))
                        const newSelectedItems = comboboxValues.treeListBag.flatList.filter(
                          (item) =>
                            selectedValueStrings.includes(props.valueToString(item.value))
                        )
                        props.onChange?.(
                          [...comboboxValues.selectedValues, item.value],
                          newSelectedItems,
                          true
                        )
                      }
                    } else {
                      toast.error(i18n.t("common:messages.create_failure"))
                    }
                  } catch {
                    toast.error(i18n.t("common:messages.create_failure"))
                  } finally {
                    setIsCreating(false)
                  }
                }}>
                <Plus size={18} className="mr-1" />
                <span>{i18n.t("create")}</span>
                <span className="ml-2 text-gray-500">{comboboxValues.searchInput}</span>
              </button>
            )}
          </div>
        )}
    </div>
  )
}

export type ComboboxSelectProps<V> = {
  value?: V | null
  onChange: (value: V, item: Item<V>) => void
  valueToString?: (value: V) => string
} & Omit<ComboboxMultiSelectProps<V>, "value" | "onChange" | "valueToString">

export const ComboboxSelect = <V,>({
  value,
  onChange,
  ...props
}: ComboboxSelectProps<V>) => {
  const multiSelectValue = useMemo(() => (value ? [value] : []), [value])

  const valueToString = useCallbackRef(
    props.valueToString ?? ((value: V) => value as unknown as string)
  )

  const multiSelectOnChange = useCallbackRef((_values: V[], items: Item<V>[]) => {
    const item = items.find((i) =>
      value ? valueToString(i.value) !== valueToString(value) : true
    )
    if (item) {
      onChange(item.value, item)
    } else if (value) {
      // Handle case that the item.value is identical go the given `value`.
      // In this casse we have to find the item again since the multi-select logic
      // wants to deselect it and returns an empty array
      const valueItem = props.items.find((i) => {
        return valueToString(i.value) == valueToString(value)
      })
      if (valueItem) {
        onChange(value, valueItem)
      }
    }
  })

  return (
    <ComboboxMultiSelect
      {...props}
      initialValuesFirst={false}
      hasCheckbox={false}
      value={multiSelectValue}
      valueToString={valueToString}
      onChange={multiSelectOnChange}
    />
  )
}

export type { ComboboxMultiSelectProps, Item }
export { ComboboxMultiSelect }
