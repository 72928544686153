import { alertDialog } from "@components/shared/alert-dialog-provider"
import Button from "@components/shared/button"
import { CurrencyInput } from "@components/shared/currency-input"
import { DateField } from "@components/shared/date-field"
import { DialogForm } from "@components/shared/dialog-form"
import { FormField } from "@components/shared/form/form-field"
import TextArea from "@components/shared/text-area"
import toast from "@components/shared/toast"
import Uploads from "@components/shared/uploads"
import { IPermissionScopeEnum } from "@elara/db"
import { IWorkOrderExpenseFragment } from "@graphql/documents/fragments.generated"
import { IUploadDataFragment } from "@graphql/documents/upload.generated"
import { usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { parseDate } from "@internationalized/date"
import { localCalendarDate } from "@utils/tzdate"
import { PropsWithChildren, useState } from "react"
import { useClient } from "urql"
import * as yup from "yup"

import {
  CreateExpenseDocument,
  DeleteExpenseDocument,
  ICreateExpenseMutation,
  ICreateExpenseMutationVariables,
  IDeleteExpenseMutation,
  IDeleteExpenseMutationVariables,
  IUpdateExpenseMutation,
  IUpdateExpenseMutationVariables,
  UpdateExpenseDocument,
} from "./actions.generated"

const schema = yup.object().shape({
  amount: yup.number().positive().required(),
  description: yup.string().required(i18n.t("common:required")),
  expense_date: yup.object().required(),
  uploads: yup.array(yup.object().shape({ id: yup.string().required() })).required(),
})

export default function CreateEditExpenseDialogForm(
  props: PropsWithChildren<{ workOrderId: string; expense?: IWorkOrderExpenseFragment }>
) {
  const [open, setOpen] = useState(false)
  const scope = usePermissionScope(IPermissionScopeEnum.AppUser)
  const [today] = useState(localCalendarDate(new Date()))
  const client = useClient()
  const editScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)

  const initialValues = {
    amount: props.expense?.amount ?? 0,
    description: props.expense?.description ?? "",
    uploads: props.expense?.uploads ?? ([] as IUploadDataFragment[]),
    expense_date: props.expense?.expense_date
      ? parseDate(props.expense.expense_date)
      : today,
  }

  const onSubmit = async (values: typeof initialValues) => {
    if (props.expense) {
      const res = await client
        .mutation<IUpdateExpenseMutation, IUpdateExpenseMutationVariables>(
          UpdateExpenseDocument,
          {
            id: props.expense.id,
            data: {
              amount: values.amount,
              description: values.description,
              expense_date: values.expense_date.toString(),
              upload_ids: values.uploads.map((u) => u.id),
            },
          },
          editScope.context()
        )
        .toPromise()

      if (res.error) {
        toast.error(i18n.t("common:generic_toast_error"))
        throw res.error
      }
      return res
    } else {
      const res = await client
        .mutation<ICreateExpenseMutation, ICreateExpenseMutationVariables>(
          CreateExpenseDocument,
          {
            data: {
              amount: values.amount,
              description: values.description,
              expense_date: values.expense_date.toString(),
              upload_ids: values.uploads.map((u) => u.id),
              work_order_id: props.workOrderId,
            },
          },
          editScope.context()
        )
        .toPromise()

      if (res.error) {
        toast.error(i18n.t("tasks:report.expense.create.error"))
        throw res.error
      }
      return res
    }
  }

  const onDelete = async () => {
    alertDialog({
      danger: true,
      title: i18n.t("tasks:report.expense.delete.title"),
      description: i18n.t("tasks:report.expense.delete.message"),
      actionText: i18n.t("tasks:report.expense.delete.action"),
      async onAction() {
        const res = await client
          .mutation<IDeleteExpenseMutation, IDeleteExpenseMutationVariables>(
            DeleteExpenseDocument,
            { id: props.expense!.id! },
            editScope.context()
          )
          .toPromise()
        if (res.error) {
          toast.error(i18n.t("tasks:report.expense.delete.error"))
          throw res.error
        }
        setOpen(false)
      },
    })
  }

  return (
    <DialogForm
      open={open}
      onOpenChange={setOpen}
      title={
        !props.expense
          ? i18n.t("tasks:report.expense.create.title")
          : i18n.t("tasks:report.expense.edit.title")
      }
      trigger={props.children}
      formikConfig={{
        initialValues,
        onSubmit,
        validationSchema: schema,
      }}
      footerActions={
        props.expense ? (
          <Button type="primary" color="red" className="mr-auto" onClick={onDelete}>
            {i18n.t("common:delete")}
          </Button>
        ) : null
      }>
      {() => {
        return (
          <div className="grid grid-cols-2 gap-x-6 pb-3 @container">
            <FormField
              className="col-span-2 @sm:col-span-1"
              name="amount"
              label={i18n.t("tasks:report.cost.form.amount")}>
              <CurrencyInput className="w-36 @sm:w-full" inputClassName="text-right" />
            </FormField>
            <FormField
              className="col-span-2 @sm:col-span-1"
              name="expense_date"
              label={i18n.t("tasks:report.cost.form.date")}>
              {({ field, helpers }) => (
                <DateField value={field.value} onChange={(d) => d && helpers.setValue(d)} />
              )}
            </FormField>
            <FormField
              name="description"
              className="col-span-2"
              label={i18n.t("tasks:report.cost.form.description")}>
              <TextArea rows={3} name="description" />
            </FormField>

            <FormField
              name="uploads"
              className="col-span-2"
              label={i18n.t("tasks:report.cost.form.upload_label")}
              optional
              hint={i18n.t("tasks:report.cost.form.upload_hint")}>
              {({ field, helpers }) => {
                return (
                  <Uploads
                    scope={scope}
                    uploads={field.value}
                    onUploadFinished={(u, uploads) => helpers.setValue(uploads.concat([u]))}
                    allowQuickDeleteOfPictures
                    onDelete={(id, uploads) => {
                      helpers.setValue(uploads.filter((u) => u.id !== id))
                    }}
                    onEditFileName={(id, fileName, uploads) =>
                      helpers.setValue(
                        uploads.map((u) =>
                          u.id !== id ? u : { ...u, file_name: fileName }
                        )
                      )
                    }
                    placeholderType="zone"
                    boundDropzoneToContainer
                  />
                )
              }}
            </FormField>
          </div>
        )
      }}
    </DialogForm>
  )
}
