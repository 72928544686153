import { Data, OrderBy, Where } from "@elara/select"
import { PropsWithChildren } from "react"
import React from "react"
import { AnyVariables, TypedDocumentNode } from "urql"

import { useDataViewChunkedFetcher } from "./data-view-chunked-fetcher.hooks"
import { DataViewFetcher } from "./data-view-fetcher"
import { andWhere } from "./lib/utils"

export const DataViewChunkedFetcherContext = React.createContext<
  ReturnType<typeof useDataViewChunkedFetcher<Data, any, AnyVariables>>
>(undefined!)
export function useDataViewChunkedFetcherContext() {
  return React.useContext(DataViewChunkedFetcherContext)
}

export function DataViewChunkedFetcher<D extends Data, R, V extends AnyVariables>(
  props: PropsWithChildren<{
    query: TypedDocumentNode<R, V>
    getData: (data: R | undefined) => D[]
    where?: Where<D>
    orderBy: OrderBy<D>[]
    chunkSize: number
    numberOfRows: (where: Where<D>) => Promise<number>
  }>
) {
  const chunked = useDataViewChunkedFetcher<D, R, V>({
    chunkSize: props.chunkSize,
    where: props.where,
    numberOfRows: props.numberOfRows,
    query: props.query,
    getData: props.getData,
  })

  return (
    <DataViewChunkedFetcherContext.Provider value={chunked}>
      {Array.from(chunked.chunks.values()).map((chunk) => {
        const isMounted = (chunked.mountedChunks.get(chunk.meta.key)?.size ?? 0) > 0
        if (!isMounted) return null

        let where = chunk.meta.query.where as Where<D>
        if (!chunk.meta.query.subQuery) {
          where = andWhere<D>(where, chunked.where)
        }

        return (
          <DataViewFetcher<D, R, V>
            query={props.query}
            getData={props.getData}
            key={chunk.meta.key}
            limit={chunk.meta.query.limit}
            offset={chunk.meta.query.offset}
            delay={chunked.chunks.size === 1 ? 0 : 300}
            where={where}
            orderBy={props.orderBy}
            onRenderPropsChange={(p) => {
              if (chunk.data) {
                chunk.data.rows = p.rows
              } else {
                chunk.data = {
                  rows: p.rows,
                }
              }
              chunked.update()
            }}
          />
        )
      })}
      {props.children}
    </DataViewChunkedFetcherContext.Provider>
  )
}
