import { IMutationRootInsertPlaceOneArgs } from "@elara/db"
import {
  AssetPlacesDocument,
  IAssetPlacesQuery,
  IAssetPlacesQueryVariables,
} from "@graphql/documents/asset-place.generated"
import { IAssetPlaceFragment } from "@graphql/documents/fragments.generated"
import { UpdateResolver } from "@urql/exchange-graphcache"

type Mutations = {
  [fieldName: string]: UpdateResolver
}

const mutations: Mutations = {
  // Insert
  insert_place_one(
    result: { insert_place_one: IAssetPlaceFragment },
    args: IMutationRootInsertPlaceOneArgs,
    cache
  ) {
    if (args.object.id || !result?.insert_place_one) return

    cache.updateQuery<IAssetPlacesQuery, IAssetPlacesQueryVariables>(
      { query: AssetPlacesDocument, variables: {} },
      (data) => {
        if (data) {
          data.place.push({ ...result.insert_place_one })
          data.place.sort((a, b) => a.name.localeCompare(b.name))
        }
        return data
      }
    )
  },
}

export default {
  mutations,
}
