// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IAssetDowntimeDataGridQueryVariables = Types.Exact<{
  startDate: Types.Scalars['timestamptz']['input'];
  endDate: Types.Scalars['timestamptz']['input'];
}>;


export type IAssetDowntimeDataGridQuery = { asset_state_log: Array<{ __typename: 'asset_state_log', id: uuid, started_at: string, ended_at: string | null, note: string | null, asset_state_variant: { __typename: 'asset_state_variant', type: Types.IAssetStateVariantTypeEnum, planned: boolean }, asset: { __typename: 'asset', id: uuid, name: string, public_id: string }, work_order: { __typename: 'work_order', id: uuid, name: string } | null }> };


export const AssetDowntimeDataGridDocument = gql`
    query AssetDowntimeDataGrid($startDate: timestamptz!, $endDate: timestamptz!) {
  asset_state_log(
    where: {_and: [{started_at: {_gte: $startDate}}, {started_at: {_lte: $endDate}}]}
  ) {
    id
    started_at
    ended_at
    asset_state_variant {
      type
      planned
    }
    asset {
      id
      name
      public_id
    }
    note
    work_order {
      id
      name
    }
  }
}
    `;

export function useAssetDowntimeDataGridQuery(options: Omit<Urql.UseQueryArgs<IAssetDowntimeDataGridQueryVariables>, 'query'>) {
  return Urql.useQuery<IAssetDowntimeDataGridQuery, IAssetDowntimeDataGridQueryVariables>({ query: AssetDowntimeDataGridDocument, ...options });
};