import Button from "@components/shared/button"
import { DateTimeNative } from "@components/shared/native-date-time-pickers"
import toast from "@components/shared/toast"
import { IWorkOrderDataViewFragment } from "@graphql/documents/fragments.generated"
import {
  IUpdateWorkOrderPartiallyMutation,
  IUpdateWorkOrderPartiallyMutationVariables,
  UpdateWorkOrderPartiallyDocument,
} from "@graphql/documents/work-order.generated"
import { IPermissionScopeEnum, useCallbackRef, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { PencilSimpleLine } from "@phosphor-icons/react"
import { getFormattedDate, parseDate } from "@utils"
import { useState } from "react"
import { useClient } from "urql"
import { useDebouncedCallback } from "use-debounce"

export function WorkOrderQuickCompletedAt(props: {
  workOrder: IWorkOrderDataViewFragment
}) {
  const [dateTime, setDateTime] = useState(parseDate(props.workOrder.completed_at))
  const [isEditing, setIsEditing] = useState(false)

  const client = useClient()
  const editScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)

  const updateCompletedAt = useCallbackRef(async (completedAt: Date) => {
    const res = await client
      .mutation<
        IUpdateWorkOrderPartiallyMutation,
        IUpdateWorkOrderPartiallyMutationVariables
      >(
        UpdateWorkOrderPartiallyDocument,
        {
          id: props.workOrder.id,
          changes: {
            completed_at: completedAt.toISOString(),
          },
        },
        editScope.context()
      )
      .toPromise()
    if (res.error) {
      toast.error(i18n.t("common:generic_toast_error"))
      setDateTime(parseDate(props.workOrder.completed_at))
    }
  })

  const debouncedUpdateCompletedAt = useDebouncedCallback(updateCompletedAt, 5000)

  if (!editScope.hasScope) {
    return <span>{getFormattedDate(dateTime, { includeTime: true })}</span>
  }

  if (isEditing) {
    return (
      <DateTimeNative
        onClick={(e) => {
          e.stopPropagation()
        }}
        autoFocus
        value={dateTime}
        onChange={(newDateTime) => {
          setDateTime(newDateTime)
          if (newDateTime) {
            debouncedUpdateCompletedAt(newDateTime)
          }
        }}
        onBlur={() => {
          setIsEditing(false)
          debouncedUpdateCompletedAt.flush()
        }}
      />
    )
  }

  return (
    <label
      className="inline-flex items-center space-x-1"
      onClick={(e) => {
        e.stopPropagation()
      }}>
      <span>{getFormattedDate(dateTime, { includeTime: true })}</span>
      <Button
        color="gray"
        type="tertiary"
        size="extra-small"
        icon={PencilSimpleLine}
        disabled={!editScope.hasScope}
        onClick={() => setIsEditing(true)}
      />
    </label>
  )
}
