import { useDisclosure } from "@hooks"
import { useControllableState } from "@hooks/use-controllable-state"
import i18n from "@i18n"
import Icons from "@resources/icons"
import { cn } from "@utils"
import { Form, Formik, FormikConfig, FormikProps, FormikValues } from "formik"
import React, { ReactNode, useMemo } from "react"
import { PropsWithChildren } from "react"

import { AlertDialogContent, AlertDialogRoot } from "./alert-dialog"
import Button, { ButtonProps } from "./button"
import {
  DialogClose,
  DialogContent,
  DialogContentFooter,
  DialogContentProps,
  DialogRoot,
  DialogRootProps,
  DialogTrigger,
} from "./dialog"
import { DialogContentHeader, DialogContentHeaderProps } from "./dialog"

const DialogFormContext = React.createContext<{
  changeOpen: (isOpen: boolean) => void
  showAlertOnClose: React.MutableRefObject<boolean>
  alertDialog: {
    isOpen: boolean
    changeOpen: (isOpen: boolean) => void
  }
}>({
  changeOpen: () => {},
  showAlertOnClose: { current: false },
  alertDialog: {
    isOpen: false,
    changeOpen: () => {},
  },
})
const useDialogForm = () => {
  return React.useContext(DialogFormContext)
}

export function DialogFormRoot({
  open,
  onOpenChange,
  defaultOpen,
  isOpen: isOpen_,
  ...props
}: DialogRootProps) {
  const [isOpen, setIsOpen] = useControllableState({
    defaultProp: defaultOpen || false,
    prop: open ?? isOpen_,
    onChange: onOpenChange,
  })

  // A little hacky, but we need to be able to show the alert dialog
  // by intercepting the close event. This is because we want to show
  // the alert dialog also when the user clicks outside the dialog or closes the dialog with escape.
  const alertDialog = useDisclosure()
  const showAlertOnClose = React.useRef(false)
  const changeOpen = (isOpen: boolean) => {
    if (!isOpen && showAlertOnClose.current) {
      alertDialog.onOpen()
    } else {
      setIsOpen(isOpen)
    }
  }
  const value = useMemo(
    () => ({ changeOpen, alertDialog, showAlertOnClose }),
    [alertDialog.isOpen]
  )

  return (
    <DialogFormContext.Provider value={value}>
      <DialogRoot open={isOpen} onOpenChange={changeOpen} {...props}>
        {props.children}
      </DialogRoot>
    </DialogFormContext.Provider>
  )
}

const DialogFormCancelTrigger = (props: PropsWithChildren<{}>) => {
  return <DialogClose asChild>{props.children}</DialogClose>
}

const DialogFormSubmitTrigger = (props: ButtonProps) => {
  return (
    <Button type="primary" htmlType="submit" {...props}>
      {props.children ?? i18n.t("common:save")}
    </Button>
  )
}

type DialogFormFooterProps = {
  cancelProps?: ButtonProps
  cancelText?: string
  footerActions?: React.ReactNode
  okProps?: ButtonProps
  okText?: string
  className?: string
  isSubmitting?: boolean
}
export function DialogFormFooter(props: DialogFormFooterProps) {
  return (
    <DialogContentFooter className={props.className}>
      {props.footerActions}

      <DialogFormCancelTrigger>
        <Button type="secondary" {...props.cancelProps}>
          {props.cancelText ?? i18n.t("common:cancel")}
        </Button>
      </DialogFormCancelTrigger>

      <DialogFormSubmitTrigger
        className="ml-2"
        icon={Icons.Check}
        {...props.okProps}
        isLoading={props.isSubmitting}
        disabled={!!props.isSubmitting}>
        {props.okText ?? i18n.t("common:save")}
      </DialogFormSubmitTrigger>
    </DialogContentFooter>
  )
}

type DialogFormFormikFormProps<Values extends FormikValues> = {
  formikConfig: FormikConfig<Values>
  children: (formikProps: FormikProps<Values>) => ReactNode
} & DialogFormFooterProps &
  Partial<DialogContentHeaderProps> & { hideFooter?: boolean }

function DialogFormFormikForm<Values extends FormikValues>({
  cancelProps,
  cancelText,
  children,
  closeIcon,
  footerActions,
  hideFooter,
  okProps,
  okText,
  title,
  formikConfig,
}: DialogFormFormikFormProps<Values>) {
  const { changeOpen, showAlertOnClose, alertDialog } = useDialogForm()

  const formikConfig_: FormikConfig<Values> = {
    ...formikConfig,
    async onSubmit(values, formikHelpers) {
      try {
        await formikConfig.onSubmit(values, formikHelpers)
        changeOpen(false)
      } catch (err) {
        console.log(err)
      } finally {
        formikHelpers.setSubmitting(false)
      }
    },
  }

  return (
    <Formik {...formikConfig_}>
      {(formik) => {
        showAlertOnClose.current = formik.dirty && formik.submitCount === 0
        return (
          <>
            <Form>
              {title && <DialogContentHeader title={title} closeIcon={closeIcon} />}
              <div className={cn("flex flex-col flex-1 min-h-0 min-w-0")}>
                {children(formik)}
              </div>
              {!hideFooter && (
                <DialogFormFooter
                  cancelProps={cancelProps}
                  cancelText={cancelText}
                  okProps={okProps}
                  okText={okText}
                  footerActions={footerActions}
                  isSubmitting={formik.isSubmitting}
                />
              )}
            </Form>
            <AlertDialogRoot
              open={alertDialog.isOpen}
              onOpenChange={alertDialog.changeOpen}>
              <AlertDialogContent
                title={`${i18n.t("common:dialogs.discard_changes.title")}`}
                description={`${i18n.t("common:dialogs.discard_changes.description")}`}
                action={
                  <Button
                    type="primary"
                    onClick={() => {
                      showAlertOnClose.current = false
                      changeOpen(false)
                    }}>
                    {i18n.t("common:discard")}
                  </Button>
                }
                cancelText={i18n.t("common:cancel")}
              />
            </AlertDialogRoot>
          </>
        )
      }}
    </Formik>
  )
}

export type DialogFormProps<Values extends FormikValues> =
  DialogFormFormikFormProps<Values> &
    Omit<DialogRootProps, "children"> &
    Pick<DialogContentProps, "position" | "className" | "positionOffset"> & {
      contentProps?: DialogContentProps
      trigger?: React.ReactNode
    }

export function DialogForm<Values extends FormikValues>({
  open,
  onOpenChange,
  defaultOpen,
  modal,
  isOpen,
  contentProps,
  position,
  positionOffset,
  className,
  trigger,
  ...props
}: DialogFormProps<Values>) {
  return (
    <DialogFormRoot
      open={open}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      defaultOpen={defaultOpen}
      modal={modal}>
      {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent
        position={position}
        positionOffset={positionOffset}
        className={className}
        {...contentProps}>
        <DialogFormFormikForm {...props} />
      </DialogContent>
    </DialogFormRoot>
  )
}
