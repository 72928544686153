import { VStack } from "@components/layout"
import { UserTag } from "@components/shared"
import { UserAvatarPlaceholder } from "@components/shared/avatar"
import AvatarGroup, { AvatarGroupProps } from "@components/shared/avatar-group"
import { IconData, TeamIcon } from "@components/shared/team-icons"
import { TeamTag } from "@components/shared/team-tag"
import { uuid } from "@elara/db"
import { IAvatarFragment } from "@graphql/documents/upload.generated"
import i18n from "@i18n"
import { colors } from "@styles"

type WorkOrderAssignedEntitiesProps = {
  assignees: Array<{
    user: {
      id: uuid
      first_name: string
      last_name: string
      deleted_at: string | null
      avatar: IAvatarFragment | null
    }
  }>
  assigned_teams: Array<{ team: { id: uuid; name: string; icon: {} | null } }>
  inline?: boolean
  size?: number
}

export const WorkOrderAssignedEntities = (props: WorkOrderAssignedEntitiesProps) => {
  if (props.inline) {
    if (props.assignees.length === 0 && props.assigned_teams.length === 1) {
      const team = props.assigned_teams[0].team
      return <TeamTag team={team} />
    }

    const items = (
      props.assignees
        .map(
          ({ user }) =>
            user && {
              id: user.id,
              avatar: user.avatar,
              placeholder: <UserAvatarPlaceholder user={user} />,
              label: `${user.first_name} ${user.last_name}`,
            }
        )
        .filter(Boolean)
        .sort((a, b) => a.label.localeCompare(b.label)) as AvatarGroupProps["items"]
    ).concat(
      props.assigned_teams
        .map(({ team }) => {
          const icon = team.icon as IconData
          return {
            id: team.id,
            placeholder: <TeamIcon name={icon?.name} color={icon?.color} />,
            style: { backgroundColor: colors.grey7 },
            label: team.name,
          }
        })
        .sort((a, b) => a.label.localeCompare(b.label))
    )

    return (
      <AvatarGroup
        items={items}
        size={props.size}
        detailOverlayTitle={i18n.t("tasks:fields.assignee", { count: 2 })}
      />
    )
  }

  return (
    <VStack space={8}>
      {props.assigned_teams.map(({ team }) => (
        <TeamTag key={team?.id} team={team} />
      ))}
      {props.assignees.map(({ user }) => (
        <UserTag key={user.id} user={user} />
      ))}
    </VStack>
  )
}
