import { Flex } from "@components/layout"
import { PopoverContent, PopoverRoot, PopoverTrigger } from "@components/shared/popover"
import Icons from "@resources/icons"
import { CategoryColors, colors as defaultColors } from "@styles"
import classNames from "classnames"

export const transformColors = (categoryColors: CategoryColors) => {
  return Object.entries(categoryColors).map((mappedData) => ({
    name: mappedData[0],
    value: mappedData[1],
  }))
}

export type Color = { name: string; value: string }
type ColorPickerProps = {
  colors: Color[]
  editMode: boolean
  value: string
  onColorChange: (color: string) => void
}
export const ColorPicker = (props: ColorPickerProps) => {
  return (
    <PopoverRoot alwaysPopover>
      <PopoverTrigger
        className={classNames("rounded mr-3", {
          "p-[10px] border border-gray-200 bg-white mr-0": props.editMode,
        })}>
        <div
          className="h-3 w-3 rounded-full"
          style={{ backgroundColor: props.value }}></div>
      </PopoverTrigger>
      <PopoverContent
        align="center"
        side="bottom"
        alignOffset={4}
        sideOffset={12}
        className={classNames("bg-white border rounded p-2", {
          hidden: !props.editMode,
        })}>
        <Flex row align="center" justify="space-between" className="m-1 sm:m-0">
          {props.colors.map((color, index) => (
            <div
              key={`${index}_${color}`}
              className="m-1 h-[1em] w-[1em] rounded-full"
              onClick={() => props.onColorChange(color.value ?? defaultColors.dark)}
              style={{ backgroundColor: color.value ?? defaultColors.dark }}>
              <Icons.Check
                color={defaultColors.white}
                width={14}
                height={14}
                style={{ margin: "auto" }}
                className={classNames("", {
                  hidden: props.value !== color.value,
                })}
              />
            </div>
          ))}
        </Flex>
      </PopoverContent>
    </PopoverRoot>
  )
}
