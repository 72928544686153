import Uploads from "@components/shared/uploads"
import {
  useDeleteConsumableDocumentMutation,
  useInsertConsumableDocumentMutation,
} from "@graphql/documents/consumable.generated"
import { IUploadDataFragment } from "@graphql/documents/upload.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks/use-permission-scope"
import toast from "react-hot-toast"

import { TabProps } from "../detail"

const DocumentsTab = ({ consumable }: TabProps) => {
  const scope = usePermissionScope(IPermissionScopeEnum.AppAssetCreate)

  const [, insertConsumableDocument] = useInsertConsumableDocumentMutation()
  const [, deleteConsumableDocument] = useDeleteConsumableDocumentMutation()

  const onUploadFinished = async (upload: IUploadDataFragment) => {
    const res = await insertConsumableDocument(
      { data: { consumable_id: consumable.id, upload_id: upload.id } },
      scope.context()
    )

    if (res.error) toast.error("Datei konnte nicht hinzugefügt werden.")
  }

  const onDelete = async (uploadId: string) => {
    const res = await deleteConsumableDocument(
      { consumableId: consumable.id, uploadId },
      scope.context()
    )

    if (res.error) toast.error("Datei konnte nicht gelöscht werden.")
  }

  return (
    <div className="m-4 max-w-screen-lg">
      <Uploads
        scope={scope}
        placeholderType="zone"
        onDelete={onDelete}
        onUploadFinished={onUploadFinished}
        uploads={consumable.uploads.map((u) => u.upload) as IUploadDataFragment[]}
      />
    </div>
  )
}

export default DocumentsTab
