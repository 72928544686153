import { HStack } from "@components/layout"
import { IUserTagFragment } from "@graphql/documents/fragments.generated"

import { UserAvatar } from "./avatar"

export type UserTagProps = {
  user: Omit<IUserTagFragment, "__typename"> | null
}

export const getUserName = (user: Pick<IUserTagFragment, "first_name" | "last_name">) =>
  `${user.first_name} ${user.last_name}`

export const UserTag = ({ user }: UserTagProps) => {
  if (!user) return null

  return (
    <HStack align="center" space={8}>
      <UserAvatar user={user} />
      <span className="overflow-hidden text-ellipsis whitespace-nowrap text-sm">
        {getUserName(user)}
      </span>
    </HStack>
  )
}
