import { LoadingIndicator } from "@components/shared"
import ScrollArea from "@components/shared/scroll-area"
import { useSearchEverywhereQuery } from "@graphql/documents/search.generated"
import { useThrottledState } from "@hooks"
import i18n from "@i18n"
import Icons from "@resources/icons"
import classNames from "classnames"
import { useCallback, useEffect, useRef, useState } from "react"

import SearchResult, { SearchResultElement } from "./search-result"

export type SearchContainerProps = {
  filters?: Record<"asset" | "work_order", boolean>
  changeOpen: (isOpen: boolean) => void
  onSelect: (result: SearchResultElement) => void
  placeholder?: string
}

export const SearchCommand: React.FC<SearchContainerProps> = (props) => {
  const {
    filters = { asset: true, work_order: true },
    changeOpen: setOpen,
    onSelect,
  } = props
  const searchRef = useRef<HTMLInputElement>(null)

  const [searchText, setSearchText] = useState("")
  const [throttledSearchText, setThrottledSearchText] = useThrottledState(searchText, 300)

  const [currentIndex, setCurrentIndex] = useState(-1)

  const updateSearchInput = (text: string) => {
    setSearchText(text)
    setThrottledSearchText(text)
    setCurrentIndex(-1)
    if (text.length >= 3 && throttledSearchText.length < 3) {
      setThrottledSearchText.flush()
    }
  }

  const [searchRes] = useSearchEverywhereQuery({
    requestPolicy: "cache-first",
    pause: throttledSearchText.length < 3,
    variables: {
      text: throttledSearchText,
      workOrderNumber: 0,
      assetPublicId: { _eq: "_" },
    },
  })

  const results = searchRes?.data ?? null
  const mergedResults = (
    ((filters.work_order && results?.search_work_order) || []) as SearchResultElement[]
  ).concat((filters.asset && results?.search_asset) || [])

  const handleSelect = (result: SearchResultElement) => {
    onSelect(result)
    updateSearchInput("")
    setOpen(false)
  }

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const length = mergedResults.length

      if (e.key === "ArrowDown" && length > 0) {
        e.preventDefault()
        setCurrentIndex((index) => (index + 1) % length)
      } else if (e.key === "ArrowUp" && length > 0) {
        e.preventDefault()
        setCurrentIndex((index) => (index - 1 + length) % length)
      } else if (e.key === "Enter") {
        e.preventDefault()
        if (mergedResults[currentIndex]) {
          handleSelect(mergedResults[currentIndex])
          setOpen(false)
        }
      } else if (e.key === "Escape") {
        e.preventDefault()
        updateSearchInput("")
        setOpen(false)
      }
    },
    [results, currentIndex]
  )

  useEffect(() => {
    searchRef.current?.focus()
  }, [])

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown)

    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [handleKeyDown])

  const handleClear = () => {
    searchRef.current?.focus()
    updateSearchInput("")
    setOpen(false)
  }

  const noSearch = results === null || searchText.length === 0

  const hasMatch =
    !!results &&
    ((filters.asset && results.search_asset.length > 0) ||
      (filters.work_order && results.search_work_order.length > 0))

  return (
    <>
      <div className={classNames("flex items-center px-5 h-14", { "border-b": !noSearch })}>
        {searchRes.fetching || throttledSearchText !== searchText ? (
          <LoadingIndicator size={20} />
        ) : (
          <Icons.Search className="text-xl opacity-75" />
        )}
        <input
          type="text"
          ref={searchRef}
          value={searchText}
          className="ml-2 w-full flex-1 bg-transparent focus:outline-none"
          placeholder={props.placeholder ?? i18n.t("search:placeholder")}
          onChange={(e) => {
            updateSearchInput(e.target.value)
          }}
        />
        <Icons.Close
          onClick={handleClear}
          className={classNames("text-2xl opacity-0 transition-opacity", {
            hidden: searchText.length === 0,
            "cursor-pointer !opacity-75 block": searchText.length > 0,
          })}
        />
      </div>

      <div
        className={classNames("min-h-0 max-h-[600px] flex flex-col flex-1", {
          "!hidden": noSearch,
        })}>
        <ScrollArea vertical>
          {filters.work_order && !!results?.search_work_order.length && (
            <>
              <h6 className="pb-2 pl-5 pt-5 text-xs font-medium text-gray-600">
                {i18n.t("common:task", { count: 2 })}
              </h6>
              {results.search_work_order.map((work_order, index) => (
                <SearchResult
                  key={work_order.id}
                  result={work_order}
                  onClick={handleSelect}
                  isActive={index === currentIndex}
                />
              ))}
            </>
          )}
          {filters.asset && !!results?.search_asset?.length && (
            <>
              <h6 className="pb-2 pl-5 pt-5 text-xs font-medium text-gray-600">
                {i18n.t("common:asset", { count: 2 })}
              </h6>
              {results.search_asset.map((asset, index) => (
                <SearchResult
                  key={asset.id}
                  result={asset}
                  onClick={handleSelect}
                  isActive={results.search_work_order.length + index === currentIndex}
                />
              ))}
            </>
          )}
          {!hasMatch && (
            <div className="my-6 text-center text-gray-600">
              {i18n.t("common:no_token_found", {
                token: i18n.t("common:result", { count: 2 }),
              })}
            </div>
          )}
        </ScrollArea>
      </div>
    </>
  )
}

const SearchContainer: React.FC<SearchContainerProps> = (props) => {
  const handleBackdropClick = () => {
    props.changeOpen(false)
  }

  return (
    <div
      className="fixed inset-0 flex flex-col items-center bg-gray-900/20 text-gray-800"
      onClick={handleBackdropClick}>
      <div
        onClick={(e) => e.stopPropagation()}
        style={{ minWidth: "min(90vw, 600px)", maxWidth: "min(90vw, 420px)" }}
        className="mb-12 mt-[18vh] flex min-h-0 shrink flex-col rounded bg-white shadow-lg">
        <SearchCommand {...props} />
      </div>
    </div>
  )
}

export default SearchContainer
