// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import { ProjectFragmentDoc } from './fragments.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IProjectsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IProjectsQuery = { project: Array<{ __typename: 'project', id: uuid, name: string, icon: {} | null, status: Types.IProjectStatusEnum, lead: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } | null, teams: Array<{ __typename: 'project_x_team', team: { __typename: 'team', id: uuid, name: string, icon: {} | null } }> }> };

export type IProjectByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type IProjectByIdQuery = { project_by_pk: { __typename: 'project', id: uuid, name: string, icon: {} | null, status: Types.IProjectStatusEnum, lead: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } | null, teams: Array<{ __typename: 'project_x_team', team: { __typename: 'team', id: uuid, name: string, icon: {} | null } }> } | null };

export type ICreateProjectMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  icon?: Types.InputMaybe<Types.Scalars['jsonb']['input']>;
  leadId?: Types.InputMaybe<Types.Scalars['uuid']['input']>;
  teams?: Types.InputMaybe<Array<Types.IProjectXTeamInsertInput> | Types.IProjectXTeamInsertInput>;
}>;


export type ICreateProjectMutation = { insert_project_one: { __typename: 'project', id: uuid, name: string, icon: {} | null, status: Types.IProjectStatusEnum, lead: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } | null, teams: Array<{ __typename: 'project_x_team', team: { __typename: 'team', id: uuid, name: string, icon: {} | null } }> } | null };

export type IDeleteProjectTeamMutationVariables = Types.Exact<{
  projectId: Types.Scalars['uuid']['input'];
  teamId: Types.Scalars['uuid']['input'];
}>;


export type IDeleteProjectTeamMutation = { delete_project_x_team_by_pk: { __typename: 'project_x_team', team_id: uuid, project_id: uuid } | null };

export type IDeleteProjectByIdMutationVariables = Types.Exact<{
  projectId: Types.Scalars['uuid']['input'];
}>;


export type IDeleteProjectByIdMutation = { delete_project_by_pk: { __typename: 'project', id: uuid } | null };

export type IInsertProjectTeamMutationVariables = Types.Exact<{
  projectId: Types.Scalars['uuid']['input'];
  teamId: Types.Scalars['uuid']['input'];
}>;


export type IInsertProjectTeamMutation = { insert_project_x_team_one: { __typename: 'project_x_team', project: { __typename: 'project', id: uuid, name: string, icon: {} | null, status: Types.IProjectStatusEnum, lead: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } | null, teams: Array<{ __typename: 'project_x_team', team: { __typename: 'team', id: uuid, name: string, icon: {} | null } }> } } | null };

export type IUpdateProjectByIdMutationVariables = Types.Exact<{
  projectId: Types.Scalars['uuid']['input'];
  data: Types.IProjectSetInput;
}>;


export type IUpdateProjectByIdMutation = { update_project_by_pk: { __typename: 'project', id: uuid, name: string, icon: {} | null, status: Types.IProjectStatusEnum, lead: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } | null, teams: Array<{ __typename: 'project_x_team', team: { __typename: 'team', id: uuid, name: string, icon: {} | null } }> } | null };


export const ProjectsDocument = gql`
    query Projects {
  project {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;

export function useProjectsQuery(options?: Omit<Urql.UseQueryArgs<IProjectsQueryVariables>, 'query'>) {
  return Urql.useQuery<IProjectsQuery, IProjectsQueryVariables>({ query: ProjectsDocument, ...options });
};
export const ProjectByIdDocument = gql`
    query ProjectById($id: uuid!) {
  project_by_pk(id: $id) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;

export function useProjectByIdQuery(options: Omit<Urql.UseQueryArgs<IProjectByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<IProjectByIdQuery, IProjectByIdQueryVariables>({ query: ProjectByIdDocument, ...options });
};
export const CreateProjectDocument = gql`
    mutation CreateProject($name: String!, $icon: jsonb, $leadId: uuid, $teams: [project_x_team_insert_input!] = []) {
  insert_project_one(
    object: {name: $name, lead_id: $leadId, icon: $icon, teams: {data: $teams}}
    on_conflict: {constraint: project_pkey, update_columns: [name, icon]}
  ) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;

export function useCreateProjectMutation() {
  return Urql.useMutation<ICreateProjectMutation, ICreateProjectMutationVariables>(CreateProjectDocument);
};
export const DeleteProjectTeamDocument = gql`
    mutation DeleteProjectTeam($projectId: uuid!, $teamId: uuid!) {
  delete_project_x_team_by_pk(project_id: $projectId, team_id: $teamId) {
    team_id
    project_id
  }
}
    `;

export function useDeleteProjectTeamMutation() {
  return Urql.useMutation<IDeleteProjectTeamMutation, IDeleteProjectTeamMutationVariables>(DeleteProjectTeamDocument);
};
export const DeleteProjectByIdDocument = gql`
    mutation DeleteProjectById($projectId: uuid!) {
  delete_project_by_pk(id: $projectId) {
    id
  }
}
    `;

export function useDeleteProjectByIdMutation() {
  return Urql.useMutation<IDeleteProjectByIdMutation, IDeleteProjectByIdMutationVariables>(DeleteProjectByIdDocument);
};
export const InsertProjectTeamDocument = gql`
    mutation InsertProjectTeam($projectId: uuid!, $teamId: uuid!) {
  insert_project_x_team_one(object: {project_id: $projectId, team_id: $teamId}) {
    project {
      ...Project
    }
  }
}
    ${ProjectFragmentDoc}`;

export function useInsertProjectTeamMutation() {
  return Urql.useMutation<IInsertProjectTeamMutation, IInsertProjectTeamMutationVariables>(InsertProjectTeamDocument);
};
export const UpdateProjectByIdDocument = gql`
    mutation UpdateProjectById($projectId: uuid!, $data: project_set_input!) {
  update_project_by_pk(pk_columns: {id: $projectId}, _set: $data) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;

export function useUpdateProjectByIdMutation() {
  return Urql.useMutation<IUpdateProjectByIdMutation, IUpdateProjectByIdMutationVariables>(UpdateProjectByIdDocument);
};