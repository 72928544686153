import { HStack } from "@components/layout"
import { IWorkOrderDataViewFragment } from "@graphql/documents/fragments.generated"
import { useDisclosure } from "@hooks"
import i18n from "@i18n"
import { Plus } from "@phosphor-icons/react"

import { WorkOrderAssignedEntities } from "./work-order-assigned-entities"
import { WorkOrderQuickAssigneeSelect } from "./work-order-quick-assignee-select"

export function WorkOrderQuickAssigneeTags(props: {
  workOrder: IWorkOrderDataViewFragment
}) {
  const showDropdown = useDisclosure()

  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    showDropdown.toggle()
  }

  if (!props.workOrder.assignees.length && !showDropdown.isOpen) {
    return (
      <HStack
        space={4}
        align="center"
        onClick={handleOnClick}
        className="h-[32px] max-w-[149px] rounded border border-transparent px-2 pl-[9px] text-sm text-gray-500 hover:border-gray-200 hover:bg-white hover:text-gray-700">
        <Plus className="shrink-0" />
        <span className="truncate"> {i18n.t("tasks:fields.assignee", { count: 1 })}</span>
      </HStack>
    )
  }

  if (!showDropdown.isOpen) {
    return (
      <WorkOrderAssignedEntities
        inline
        assigned_teams={[]}
        assignees={props.workOrder.assignees}
      />
    )
  }
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <WorkOrderQuickAssigneeSelect
        title={i18n.t("tasks:fields.assignee", { count: 1 })}
        workOrder={props.workOrder}
        compact
        isListView
        isOpen={showDropdown.isOpen}
        onOpenChange={showDropdown.changeOpen}
      />
    </div>
  )
}
