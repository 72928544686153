import { useEffect } from "react"

import useMemoRef from "./use-memo-ref"

type PossibleEvent = MouseEvent | TouchEvent

export function useOutsideClickListener(
  ref: React.MutableRefObject<Element | null>,
  handler: ((event: PossibleEvent) => void) | null | undefined
) {
  const handlerRef = useMemoRef(handler)

  useEffect(() => {
    if (!handler) return

    const listener = (e: PossibleEvent) => {
      if (!ref.current || !handlerRef.current || ref.current.contains(e.target as Node))
        return

      handlerRef.current(e)
    }

    document.addEventListener("mousedown", listener)
    document.addEventListener("touchstart", listener)

    return () => {
      document.removeEventListener("mousedown", listener)
      document.removeEventListener("touchstart", listener)
    }
  }, [!handler])
}
