// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IUploadUrlsFragment = { __typename: 'upload', thumbnail_url: string | null, url: string | null };

export type IAvatarFragment = { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null };

export type IDocumentCardDataFragment = { __typename: 'upload', id: uuid, file_name: string, file_size: number, mime_type: string, created_at: string, thumbnail_url: string | null, url: string | null };

export type IQuickCategoryUploadChangeResultFragment = { __typename: 'upload', id: uuid, category: Array<{ __typename: 'upload_x_upload_category', upload_category_id: uuid, category: { __typename: 'upload_category', id: uuid } | null }> };

export type IUploadDataFragment = { __typename: 'upload', id: uuid, file_name: string, file_size: number, mime_type: string, created_at: string, thumbnail_url: string | null, url: string | null };

export type IUploadCategoryFragment = { __typename: 'upload_category', id: uuid, label: string | null, color: string };

export type IUploadTagsQueryVariables = Types.Exact<{
  uploadId: Types.Scalars['uuid']['input'];
}>;


export type IUploadTagsQuery = { upload_x_upload_category: Array<{ __typename: 'upload_x_upload_category', category: { __typename: 'upload_category', id: uuid, label: string | null, color: string } | null }> };

export type IAllUploadTagsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IAllUploadTagsQuery = { upload_category: Array<{ __typename: 'upload_category', id: uuid, label: string | null, color: string }> };

export type IInsertUploadTagMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['uuid']['input']>;
  label: Types.Scalars['String']['input'];
  color?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type IInsertUploadTagMutation = { insert_upload_category_one: { __typename: 'upload_category', id: uuid, label: string | null, color: string } | null };

export type IInsertUploadXUploadCategoryMutationVariables = Types.Exact<{
  upload_id: Types.Scalars['uuid']['input'];
  upload_category_id: Types.Scalars['uuid']['input'];
}>;


export type IInsertUploadXUploadCategoryMutation = { insert_upload_x_upload_category_one: { __typename: 'upload_x_upload_category', upload: { __typename: 'upload', id: uuid, category: Array<{ __typename: 'upload_x_upload_category', upload_category_id: uuid, category: { __typename: 'upload_category', id: uuid } | null }> } | null } | null };

export type IDeleteUploadXUploadCategoryMutationVariables = Types.Exact<{
  upload_id: Types.Scalars['uuid']['input'];
  upload_category_id: Types.Scalars['uuid']['input'];
}>;


export type IDeleteUploadXUploadCategoryMutation = { delete_upload_x_upload_category_by_pk: { __typename: 'upload_x_upload_category', upload: { __typename: 'upload', id: uuid, category: Array<{ __typename: 'upload_x_upload_category', upload_category_id: uuid, category: { __typename: 'upload_category', id: uuid } | null }> } | null } | null };

export type IEditFileNameMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  fileName: Types.Scalars['String']['input'];
}>;


export type IEditFileNameMutation = { update_upload_by_pk: { __typename: 'upload', id: uuid, file_name: string } | null };

export type IDeleteFileMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type IDeleteFileMutation = { delete_upload_by_pk: { __typename: 'upload', id: uuid } | null };

export type IInsertUploadMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['uuid']['input']>;
  url: Types.Scalars['String']['input'];
  fileName: Types.Scalars['String']['input'];
  fileSize: Types.Scalars['Int']['input'];
  mimeType: Types.Scalars['String']['input'];
  thumbnailUrl?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type IInsertUploadMutation = { insert_upload_one: { __typename: 'upload', id: uuid, file_name: string, file_size: number, mime_type: string, created_at: string, thumbnail_url: string | null, url: string | null } | null };

export type IInsertManyUploadsMutationVariables = Types.Exact<{
  data: Array<Types.IUploadInsertInput> | Types.IUploadInsertInput;
}>;


export type IInsertManyUploadsMutation = { insert_upload: { __typename: 'upload_mutation_response', returning: Array<{ __typename: 'upload', id: uuid, file_name: string, file_size: number, mime_type: string, created_at: string, thumbnail_url: string | null, url: string | null }> } | null };

export const UploadUrlsFragmentDoc = gql`
    fragment UploadUrls on upload {
  thumbnail_url: client_thumbnail_url
  url: client_url
}
    `;
export const AvatarFragmentDoc = gql`
    fragment Avatar on upload {
  id
  ...UploadUrls
}
    ${UploadUrlsFragmentDoc}`;
export const DocumentCardDataFragmentDoc = gql`
    fragment DocumentCardData on upload {
  id
  file_name
  file_size
  mime_type
  created_at
  ...UploadUrls
}
    ${UploadUrlsFragmentDoc}`;
export const QuickCategoryUploadChangeResultFragmentDoc = gql`
    fragment QuickCategoryUploadChangeResult on upload {
  __typename
  id
  category {
    upload_category_id
    category {
      id
    }
  }
}
    `;
export const UploadDataFragmentDoc = gql`
    fragment UploadData on upload {
  id
  file_name
  file_size
  mime_type
  created_at
  ...UploadUrls
}
    ${UploadUrlsFragmentDoc}`;
export const UploadCategoryFragmentDoc = gql`
    fragment UploadCategory on upload_category {
  id
  label
  color
}
    `;
export const UploadTagsDocument = gql`
    query UploadTags($uploadId: uuid!) {
  upload_x_upload_category(where: {upload_id: {_eq: $uploadId}}) {
    category {
      ...UploadCategory
    }
  }
}
    ${UploadCategoryFragmentDoc}`;

export function useUploadTagsQuery(options: Omit<Urql.UseQueryArgs<IUploadTagsQueryVariables>, 'query'>) {
  return Urql.useQuery<IUploadTagsQuery, IUploadTagsQueryVariables>({ query: UploadTagsDocument, ...options });
};
export const AllUploadTagsDocument = gql`
    query AllUploadTags {
  upload_category {
    ...UploadCategory
  }
}
    ${UploadCategoryFragmentDoc}`;

export function useAllUploadTagsQuery(options?: Omit<Urql.UseQueryArgs<IAllUploadTagsQueryVariables>, 'query'>) {
  return Urql.useQuery<IAllUploadTagsQuery, IAllUploadTagsQueryVariables>({ query: AllUploadTagsDocument, ...options });
};
export const InsertUploadTagDocument = gql`
    mutation InsertUploadTag($id: uuid, $label: String!, $color: String) {
  insert_upload_category_one(
    object: {id: $id, label: $label, color: $color}
    on_conflict: {constraint: upload_category_pkey, update_columns: []}
  ) {
    ...UploadCategory
  }
}
    ${UploadCategoryFragmentDoc}`;

export function useInsertUploadTagMutation() {
  return Urql.useMutation<IInsertUploadTagMutation, IInsertUploadTagMutationVariables>(InsertUploadTagDocument);
};
export const InsertUploadXUploadCategoryDocument = gql`
    mutation InsertUploadXUploadCategory($upload_id: uuid!, $upload_category_id: uuid!) {
  insert_upload_x_upload_category_one(
    object: {upload_id: $upload_id, upload_category_id: $upload_category_id}
  ) {
    upload {
      ...QuickCategoryUploadChangeResult
    }
  }
}
    ${QuickCategoryUploadChangeResultFragmentDoc}`;

export function useInsertUploadXUploadCategoryMutation() {
  return Urql.useMutation<IInsertUploadXUploadCategoryMutation, IInsertUploadXUploadCategoryMutationVariables>(InsertUploadXUploadCategoryDocument);
};
export const DeleteUploadXUploadCategoryDocument = gql`
    mutation DeleteUploadXUploadCategory($upload_id: uuid!, $upload_category_id: uuid!) {
  delete_upload_x_upload_category_by_pk(
    upload_id: $upload_id
    upload_category_id: $upload_category_id
  ) {
    upload {
      ...QuickCategoryUploadChangeResult
    }
  }
}
    ${QuickCategoryUploadChangeResultFragmentDoc}`;

export function useDeleteUploadXUploadCategoryMutation() {
  return Urql.useMutation<IDeleteUploadXUploadCategoryMutation, IDeleteUploadXUploadCategoryMutationVariables>(DeleteUploadXUploadCategoryDocument);
};
export const EditFileNameDocument = gql`
    mutation EditFileName($id: uuid!, $fileName: String!) {
  update_upload_by_pk(pk_columns: {id: $id}, _set: {file_name: $fileName}) {
    id
    file_name
  }
}
    `;

export function useEditFileNameMutation() {
  return Urql.useMutation<IEditFileNameMutation, IEditFileNameMutationVariables>(EditFileNameDocument);
};
export const DeleteFileDocument = gql`
    mutation DeleteFile($id: uuid!) {
  delete_upload_by_pk(id: $id) {
    id
  }
}
    `;

export function useDeleteFileMutation() {
  return Urql.useMutation<IDeleteFileMutation, IDeleteFileMutationVariables>(DeleteFileDocument);
};
export const InsertUploadDocument = gql`
    mutation InsertUpload($id: uuid, $url: String!, $fileName: String!, $fileSize: Int!, $mimeType: String!, $thumbnailUrl: String) {
  insert_upload_one(
    object: {id: $id, url: $url, thumbnail_url: $thumbnailUrl, file_name: $fileName, mime_type: $mimeType, file_size: $fileSize}
  ) {
    ...UploadData
  }
}
    ${UploadDataFragmentDoc}`;

export function useInsertUploadMutation() {
  return Urql.useMutation<IInsertUploadMutation, IInsertUploadMutationVariables>(InsertUploadDocument);
};
export const InsertManyUploadsDocument = gql`
    mutation InsertManyUploads($data: [upload_insert_input!]!) {
  insert_upload(objects: $data) {
    returning {
      ...UploadData
    }
  }
}
    ${UploadDataFragmentDoc}`;

export function useInsertManyUploadsMutation() {
  return Urql.useMutation<IInsertManyUploadsMutation, IInsertManyUploadsMutationVariables>(InsertManyUploadsDocument);
};