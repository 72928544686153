import PageViewLayout from "@components/page/page-view-layout"
import ScrollArea from "@components/shared/scroll-area"
import { useBreakpoint } from "@contexts/breakpoints"
import i18n from "@i18n"
import { Outlet } from "react-router-dom"

import SettingsMenu from "./settings-menu"

const SettingsPage = () => {
  const bp = useBreakpoint()

  return (
    <PageViewLayout title={i18n.t("navigation:settings")}>
      <div className="flex min-h-0 min-w-0 flex-1">
        {!bp.mobile && <SettingsMenu />}

        <div className="flex min-w-0 shrink grow-[3] basis-3/4 flex-col">
          <ScrollArea vertical className="min-w-0 flex-1" viewportAsChild>
            <div className="mx-auto flex min-h-0 w-full flex-1 flex-col px-4">
              <Outlet />
            </div>
          </ScrollArea>
        </div>
      </div>
    </PageViewLayout>
  )
}

export default SettingsPage
