import toast from "@components/shared/toast"
import Uploads from "@components/shared/uploads"
import { IPermissionScopeEnum, uuid } from "@elara/db"
import { IWorkOrderFragment } from "@graphql/documents/fragments.generated"
import { IUploadDataFragment } from "@graphql/documents/upload.generated"
import {
  useAddNewDocumentToWorkOrderMutation,
  useDeleteWorkOrderUploadMutation,
} from "@graphql/documents/work-order.generated"
import { usePermissionScope } from "@hooks"

export type WorkOrderDetailDocumentsProps = {
  workOrderId: uuid
  allowQuickDeleteOfPictures: boolean
  documents: IWorkOrderFragment["documents"]
}

export const WorkOrderDetailDocuments = (props: WorkOrderDetailDocumentsProps) => {
  const editScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)

  const [, addNewDocumentToWorkOrder] = useAddNewDocumentToWorkOrderMutation()
  const [, deleteWorkOrderUpload] = useDeleteWorkOrderUploadMutation()

  const onUploadFinished = async (upload: IUploadDataFragment) => {
    const res = await addNewDocumentToWorkOrder(
      {
        upload_id: upload.id,
        work_order_id: props.workOrderId,
      },
      editScope.context()
    )
    if (res.error) toast.error("Datei konnte nicht hinzugefügt werden.")
  }

  const onDelete = async (uploadId: string) => {
    const res = await deleteWorkOrderUpload(
      { uploadId, workOrderId: props.workOrderId },
      editScope.context()
    )

    if (!res.data?.delete_work_order_x_upload_by_pk) {
      toast.error("Datei konnte nicht gelöscht werden.")
    }
  }

  return (
    <Uploads
      scope={editScope}
      uploads={props.documents.map((d) => d.document)}
      onUploadFinished={onUploadFinished}
      boundDropzoneToContainer={false}
      onDelete={onDelete}
      allowQuickDeleteOfPictures={props.allowQuickDeleteOfPictures}
    />
  )
}

export default WorkOrderDetailDocuments
