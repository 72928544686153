import { useDataViewConfigContext } from "@components/new-data-view/data-view-config"
import { Data, Where } from "@elara/select"
import { useCallbackRef, useStickyState } from "@hooks"
import { useCallback, useEffect, useRef, useState } from "react"
import { AnyVariables, TypedDocumentNode, useClient } from "urql"

import { useDataViewFilterContext } from "../data-view-filter"
import { useDataViewSearchContext } from "../data-view-search"
import { useDataViewSortContext } from "../data-view-sort"
import { useDataViewTreeContext } from "../data-view-tree-toggle"
import { Column } from "../data-view-types"
import { andWhere } from "./utils"

export const useDataViewWhere = <D extends Data>(w?: Where<D> | null) => {
  const filters = useDataViewFilterContext()
  const { config } = useDataViewConfigContext()
  const search = useDataViewSearchContext()
  const tree = useDataViewTreeContext()
  // For a tree structure we only render root needs when there is no search value
  // If a search is happening we allow all
  let where = w ?? ({} as Where<D>)
  if (tree && !config.includeSubitems) {
    where = andWhere<D>(where, tree.rootWhere as Where<D>)
  }
  where = andWhere<D>(
    andWhere<D>((filters.whereStatement ?? {}) as Where<D>, where),
    search.searchQuery as Where<D>
  )

  return where
}

export const useFetchAllRows = <D extends Data, R, V extends AnyVariables>(args: {
  query: TypedDocumentNode<R, V>
  where?: Where<D>
  getData: (d: R | undefined) => D[]
}) => {
  const client = useClient()
  const where = useDataViewWhere(args.where)
  const orderBy = useDataViewSortContext()
  const variables = {
    where,
    limit: 10000,
    offset: 0,
    orderBy: orderBy.orderByStatements,
  } as unknown as V
  return () =>
    client
      .query(args.query, variables)
      .toPromise()
      .then((r) => args.getData(r.data))
}

const clamp = (x: number, a: number, b: number) => Math.min(Math.max(x, a), b)

export function useColumn<D extends Data, Id extends string, Options extends {}>(options: {
  id: string
  toggleSortState: (id: Id) => void
}) {
  // Column resizing
  const columnRefs = useRef({} as Record<Id, HTMLTableCellElement | null>)
  const [columnWidths, setColumnWidths] = useStickyState(
    {} as Record<Id, number | null>,
    options.id + "_columnWidths"
  )
  const [activeResizeColumn, setActiveResizeColumn] = useState<Column<
    D,
    Id,
    Options
  > | null>(null)

  const getResizeHandlerProps = useCallback(
    (column: Column<D, Id, Options>) => {
      return {
        isActive: column.id === activeResizeColumn?.id,
        onPointerDown: () => {
          setActiveResizeColumn(column)
        },
      }
    },
    [activeResizeColumn]
  )

  const getColumnWidth = useCallback(
    (column: Column<D, Id, Options>, isLastCol: boolean) => {
      let width: string
      if (columnWidths[column.id]) {
        width = `${columnWidths[column.id]}px`
      } else if (column.defaultWidth) {
        width = `${column.defaultWidth}px`
        // }
        //  else if (column.maxWidth) {
        //   // It is not possible to nest minmax, therefore
        //   // we ignore maxWidth if `isLastCol`
        //   width = "120px"
        //   if (isLastCol) {
        //     width = `0px`
        //   } else {
        //     width = `minmax(0px, ${column.maxWidth}px)`
        //   }
      } else {
        // width = `min-content`
        width = "120px"
      }
      if (isLastCol) {
        width = `minmax(${width}, 1fr)`
      }

      return width
    },
    [columnWidths]
  )

  useEffect(() => {
    if (!activeResizeColumn) return

    const moveHandler = (e: PointerEvent) => {
      const ref = columnRefs.current[activeResizeColumn.id]
      if (!ref) return

      const boundingRect = ref.getBoundingClientRect()
      let width = clamp(
        e.clientX - boundingRect.x,
        activeResizeColumn.minWidth ?? 25,
        activeResizeColumn.maxWidth ?? Number.POSITIVE_INFINITY
      )
      if (width !== columnWidths[activeResizeColumn.id]) {
        setColumnWidths((widths) => ({ ...widths, [activeResizeColumn.id]: width }))
      }
    }
    const upHandler = () => setActiveResizeColumn(null)

    window.addEventListener("pointermove", moveHandler)
    window.addEventListener("pointerup", upHandler)
    return () => {
      window.removeEventListener("pointermove", moveHandler)
      window.removeEventListener("pointerup", upHandler)
    }
  }, [activeResizeColumn])

  const getHeaderProps = useCallback(
    (column: Column<D, Id, Options>) => {
      return {
        ref: (r: HTMLTableCellElement | null) => {
          columnRefs.current[column.id] = r
        },
        onClick: () => options.toggleSortState(column.id),
      }
    },
    [columnRefs, options.toggleSortState]
  )

  return { getHeaderProps, getResizeHandlerProps, getColumnWidth }
}

export function dataViewRefreshData() {
  queueMicrotask(() => {
    window.dispatchEvent(new CustomEvent("data-view:refresh_data"))
  })
}

export function useDataViewRefreshData(onRefresh?: () => void) {
  const cbRef = useCallbackRef(onRefresh ?? (() => {}))
  useEffect(() => {
    window.addEventListener("data-view:refresh_data", cbRef)

    return () => {
      window.removeEventListener("data-view:refresh_data", cbRef)
    }
  }, [])

  return dataViewRefreshData
}
