import AssetAddEditMeterReadingsForm, {
  NEW_METER_READING_EVENT,
} from "@components/asset/detail/meter/create-and-edit-meter-readings-form"
import { Button, useConfirmModal, UserTag } from "@components/shared"
import ScrollArea from "@components/shared/scroll-area"
import Table from "@components/shared/table"
import toast from "@components/shared/toast"
import { Tooltip } from "@components/shared/tooltip"
import { IMeterTypeEnum, IPermissionScopeEnum, uuid } from "@elara/db"
import {
  DeleteMeterReadingDocument,
  IDeleteMeterReadingMutation,
  IDeleteMeterReadingMutationVariables,
  useMeterReadingsHistoryQuery,
} from "@graphql/documents/asset-meter.generated"
import {
  IMeterDetailFragment,
  IMeterReadingFragment,
} from "@graphql/documents/fragments.generated"
import { usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { PencilSimpleLine, TrashSimple, Warning } from "@phosphor-icons/react"
import { createColumnHelper } from "@tanstack/react-table"
import { getFormattedDate, parseDate } from "@utils"
import classNames from "classnames"
import { useEffect, useMemo } from "react"
import { Link } from "react-router-dom"
import { useClient } from "urql"

const helper = createColumnHelper<IMeterReadingFragment>()

export const MeterReadingsHistory = (props: { meter: IMeterDetailFragment }) => {
  const [readings, fetchReadings] = useMeterReadingsHistoryQuery({
    variables: { meterId: props.meter.id },
    requestPolicy: "cache-and-network",
  })

  const data = readings.data?.meter_reading ?? []

  useEffect(() => {
    const handler = (e: Event) => {
      if (e instanceof CustomEvent) {
        if (e.detail?.meterId === props.meter.id) {
          fetchReadings()
        }
      }
    }
    window.addEventListener(NEW_METER_READING_EVENT, handler)
    return () => {
      window.removeEventListener(NEW_METER_READING_EVENT, handler)
    }
  })

  const deleteReadingScope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)
  const client = useClient()
  const deleteReading = async (id: uuid) => {
    const res = await client
      .mutation<IDeleteMeterReadingMutation, IDeleteMeterReadingMutationVariables>(
        DeleteMeterReadingDocument,
        { id },
        deleteReadingScope.context()
      )
      .toPromise()

    if (res.error) {
      toast.error(i18n.t("common.generic_toast_error"))
    }
  }

  const columns = useMemo(
    () => [
      helper.accessor((row) => `${row.value} ${row.meter.unit}`, {
        id: "reading",
        header: () => i18n.t("meters:fields.value"),
        cell: (info) => {
          const triggeredTask = info.row.original.triggered_maintenance_task
          const isMeasurement = props.meter.meter_type === IMeterTypeEnum.Measurement
          let isOutsideRange = false
          if (props.meter.range_end && info.row.original.value > props.meter.range_end) {
            isOutsideRange = true
          } else if (
            props.meter.range_start &&
            info.row.original.value < props.meter.range_start
          ) {
            isOutsideRange = true
          }
          const showWarning = (isMeasurement && isOutsideRange) || triggeredTask.length > 0
          return (
            <span className="flex items-center font-medium">
              {info.getValue()}{" "}
              {showWarning && (
                <Tooltip
                  content={
                    <>
                      {triggeredTask.length > 0 && (
                        <Link to={`/task/${triggeredTask[0].task_id}`}>
                          {i18n.t("meters:messages.triggered_maintenance_task")}
                        </Link>
                      )}
                      {isOutsideRange && i18n.t("meters:messages.outside_range")}
                    </>
                  }
                  contentProps={{ side: "top", align: "start" }}>
                  <Warning size={20} className="ml-2 text-yellow-500" />
                </Tooltip>
              )}{" "}
            </span>
          )
        },
      }),
      helper.accessor("measured_at", {
        header: () => i18n.t("meters:fields.measured_at"),
        cell: (info) => (
          <span className="text-gray-500">
            {getFormattedDate(parseDate(info.getValue()), { includeTime: true })}
          </span>
        ),
      }),
      helper.accessor("measured_by", {
        header: () => i18n.t("meters:fields.measured_by"),
        cell: (info) => <UserTag user={info.getValue()} />,
      }),
      helper.accessor((row) => row, {
        id: "actions",
        header: () => null,
        cell: (info) => (
          <>
            <AssetAddEditMeterReadingsForm
              meterId={props.meter.id}
              meterReading={info.row.original}>
              <Button color="gray" size="small" type="tertiary" icon={PencilSimpleLine} />
            </AssetAddEditMeterReadingsForm>
            <Button
              type="tertiary"
              icon={TrashSimple}
              size="small"
              color="gray"
              onClick={() => deleteModal.show(info.getValue().id)}
            />
          </>
        ),
      }),
    ],
    []
  )

  const deleteModal = useConfirmModal({
    title: i18n.t("common:delete_token", {
      token: i18n.t("meters:fields.reading", { count: 1 }),
    }),
    content: i18n.t("common:messages.token_delete_confirmation", {
      token: i18n.t("meters:fields.reading", { count: 1 }),
    }),
    okText: i18n.t("common:delete"),
    cancelText: i18n.t("common:cancel"),
    onOk: (id: uuid) => deleteReading(id),
  })

  return (
    <ScrollArea vertical viewportAsChild>
      <div className={classNames("bg-white flex-1 sm:rounded flex flex-col min-h-0")}>
        <Table options={{ data, columns }} />
        {deleteModal.component}
      </div>
    </ScrollArea>
  )
}
