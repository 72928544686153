import { AssetSingleSelect } from "@components/asset"
import { Button, useConfirmModal } from "@components/shared"
import { DialogRootProps } from "@components/shared/dialog"
import { DialogForm } from "@components/shared/dialog-form"
import { FormField } from "@components/shared/form/form-field"
import { TextInput } from "@components/shared/text-input"
import { IPermissionScopeEnum, uuid } from "@elara/db"
import {
  useConsumableQuery,
  useDeleteConsumableLogMutation,
  useInsertConsumableLogMutation,
  useUpdateConsumableLogMutation,
} from "@graphql/documents/consumable.generated"
import { IConsumableLogFragment } from "@graphql/documents/fragments.generated"
import { usePermissionScope } from "@hooks"
import i18n from "@i18n"
import * as Yup from "yup"

type FormValues = Partial<IConsumableLogFragment>

type Props = {
  initialValues: FormValues & { consumable_id: string }
  type?: "add" | "take"
} & Required<Pick<DialogRootProps, "isOpen" | "onOpenChange">>

const ConsumableLogSchema = Yup.object().shape({
  consumable_id: Yup.string().required(
    i18n.t("common:forms.is_required", {
      field: i18n.t("common:consumable", { count: 1 }),
    })
  ),
  adjustment: Yup.number().required(
    i18n.t("common:forms.is_required", {
      field: i18n.t("consumables:fields.adjustment"),
    })
  ),
  asset_id: Yup.string().nullable(),
})

const CreateEditConsumableLogFormDialog: React.FC<Props> = (props) => {
  const [consumableRes] = useConsumableQuery({
    requestPolicy: "cache-first",
    variables: { id: props.initialValues.consumable_id },
  })
  const consumable = consumableRes.data?.consumable_by_pk

  const deleteModal = useConfirmModal({
    title: i18n.t("common:delete_token", {
      token: i18n.t("consumables:fields.consumption"),
    }),
    content: i18n.t("common:messages.token_delete_confirmation", {
      token: i18n.t("common:entry", { count: 1 }),
    }),
    okText: i18n.t("common:delete"),
    cancelText: i18n.t("common:cancel"),
    onOk: async () => {
      if (props.initialValues && props.initialValues.id) {
        await deleteConsumableLog({ id: props.initialValues.id }, dataEntryScope.context())
        props.onOpenChange(false)
      }
    },
  })

  const dataEntryScope = usePermissionScope(IPermissionScopeEnum.AppDataEntry)
  const [, insertConsumableLog] = useInsertConsumableLogMutation()
  const [, updateConsumableLog] = useUpdateConsumableLogMutation()
  const [, deleteConsumableLog] = useDeleteConsumableLogMutation()

  const onSubmit = async (values: FormValues) => {
    if (props.initialValues.id) {
      await updateConsumableLog(
        {
          id: props.initialValues.id,
          data: {
            asset_id: values.asset_id,
            adjustment: values.adjustment,
            consumable_id: props.initialValues.consumable_id,
          },
        },
        dataEntryScope.context()
      )
    } else {
      await insertConsumableLog(
        {
          data: {
            asset_id: values.asset_id,
            adjustment: values.adjustment,
            consumable_id: props.initialValues.consumable_id,
          },
        },
        dataEntryScope.context()
      )
    }
  }

  const onDelete = () => deleteModal.show()

  return (
    <>
      <DialogForm<FormValues>
        {...props}
        closeIcon
        footerActions={
          props.initialValues.id ? (
            <Button color="red" type="tertiary" className="mr-auto" onClick={onDelete}>
              {i18n.t("common:delete")}
            </Button>
          ) : null
        }
        title={
          props.initialValues.id
            ? i18n.t("common:edit_token", {
                token: i18n.t("consumables:fields.consumption"),
              })
            : i18n.t("common:add_token", {
                token: i18n.t("consumables:fields.consumption"),
              })
        }
        formikConfig={{
          onSubmit,
          validationSchema: ConsumableLogSchema,
          initialValues: props.initialValues ?? {},
        }}>
        {() => {
          if (props.type === "add") {
            return (
              <div className="my-4 grid grid-cols-4 gap-x-4">
                <FormField
                  name="adjustment"
                  label={i18n.t("consumables:labels.quantity_added")}
                  className="col-span-4">
                  {({ field }) => (
                    <div className="flex w-full flex-1 items-center">
                      <TextInput
                        type="number"
                        required
                        placeholder="0"
                        min={0}
                        className="text-left"
                        {...field}
                      />
                      <span className="ml-2 text-gray-600">{consumable?.unit}</span>
                    </div>
                  )}
                </FormField>
              </div>
            )
          }

          if (props.type === "take") {
            return (
              <div className="my-4 grid grid-cols-4 gap-x-4">
                <FormField
                  name="adjustment"
                  label={i18n.t("consumables:labels.quantity_taken")}
                  className="col-span-4">
                  {({ field, helpers }) => (
                    <div className="flex w-full flex-1 items-center">
                      <TextInput
                        type="number"
                        required
                        placeholder="0"
                        {...field}
                        onChange={(e) => {
                          const val = e.target.valueAsNumber
                          if (typeof val === "number") {
                            helpers.setValue(-val)
                          }
                        }}
                        value={-field.value}
                      />
                      <span className="ml-2 text-gray-600">{consumable?.unit}</span>
                    </div>
                  )}
                </FormField>
                <FormField
                  name="asset_id"
                  label={i18n.t("common:asset", { count: 1 })}
                  optional
                  hint={i18n.t("consumables:messages.adjustment_object_hint")}
                  className="col-span-4">
                  {({ field, helpers }) => (
                    <AssetSingleSelect
                      size="small"
                      value={field.value}
                      onChange={helpers.setValue}
                      recommendedItems={consumable?.asset_ids as uuid[]}
                    />
                  )}
                </FormField>
              </div>
            )
          }

          return (
            <div className="my-4 grid grid-cols-4 gap-x-4">
              <FormField
                name="adjustment"
                label={i18n.t("consumables:fields.adjustment")}
                className="col-span-4 mb-4"
                hint={i18n.t("consumables:messages.adjustment_hint")}>
                {({ field }) => (
                  <div className="flex w-full flex-1 items-center">
                    <TextInput
                      type="number"
                      required
                      placeholder="0"
                      className="text-right"
                      {...field}
                    />
                    <span className="ml-2 text-gray-600">{consumable?.unit}</span>
                  </div>
                )}
              </FormField>
              <FormField
                name="asset_id"
                label={i18n.t("common:asset", { count: 1 })}
                optional
                hint={i18n.t("consumables:messages.adjustment_object_hint")}
                className="col-span-4">
                {({ field, helpers }) => (
                  <AssetSingleSelect
                    size="small"
                    value={field.value}
                    onChange={helpers.setValue}
                    recommendedItems={consumable?.asset_ids as uuid[]}
                  />
                )}
              </FormField>
            </div>
          )
        }}
      </DialogForm>

      {deleteModal.component}
    </>
  )
}

export default CreateEditConsumableLogFormDialog
