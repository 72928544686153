import { Flex } from "@components/layout"
import { Button } from "@components/shared"
import { TextInput } from "@components/shared/text-input"
import { X } from "@phosphor-icons/react"
import Icons from "@resources/icons"
import { px } from "@styles"
import { Fragment, MutableRefObject } from "react"

import { Color, ColorPicker } from "./color-picker"

type CategoryEditCreateFormProps = {
  label: string
  currentColor: string
  colors: Color[]
  inputRef: MutableRefObject<HTMLInputElement | null>
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onEditOrCreateCategory: () => void
  stopEditOrCreate: () => void
  onChangeColor: (color: string) => void
}
const CategoryEditCreateForm = (props: CategoryEditCreateFormProps) => {
  return (
    <Flex row align="center" justify="space-between" flex="1">
      <Fragment>
        <ColorPicker
          colors={props.colors}
          editMode
          value={props.currentColor}
          onColorChange={props.onChangeColor}
        />
      </Fragment>
      <>
        <TextInput
          value={props.label}
          ref={props.inputRef}
          onChange={props.onChange}
          className="mx-2 inline-flex min-w-0 flex-1"
          required
        />
        <Button
          type="tertiary"
          icon={X}
          onClick={props.stopEditOrCreate}
          size="small"
          className="shrink-0"
          style={{ marginRight: px(4), marginLeft: px(4) }}
        />
        <Button
          type="tertiary"
          icon={Icons.Check}
          onMouseDown={(e) => e.stopPropagation()}
          onTouchStart={(e) => e.stopPropagation()}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            props.onEditOrCreateCategory()
          }}
          size="small"
          className="shrink-0"
          style={{ marginRight: px(4) }}
        />
      </>
    </Flex>
  )
}

export default CategoryEditCreateForm
