import AddEditMeterForm from "@components/asset/detail/meter/new-create-and-edit-meter-form"
import { MeterDataView } from "@components/asset/meter-data-view"
import Button from "@components/shared/button"
import { useMeterDataViewQuery } from "@graphql/documents/asset-meter.generated"
import { useDisclosure } from "@hooks"
import i18n from "@i18n"
import Icons from "@resources/icons"

import { useAsset } from "./$id"

const AssetOverview = () => {
  const { asset } = useAsset()
  const [dataRes] = useMeterDataViewQuery({
    requestPolicy: "cache-and-network",
    variables: {
      where: {
        asset: { _or: [{ id: { _eq: asset.id } }, { parent_ids: { _has_key: asset.id } }] },
      },
    },
  })
  const meterModal = useDisclosure()

  return (
    <div className="mt-3 flex min-h-0 flex-1 flex-col">
      <MeterDataView
        data={dataRes.data?.meter ?? null}
        configId="meter:_:_"
        headerClassName="px-3"
        assetId={asset.id}
        actions={
          <Button onClick={meterModal.onOpen} icon={Icons.AddMeter}>
            {i18n.t("meters:actions.new_meter")}
          </Button>
        }
      />
      <AddEditMeterForm
        isOpen={meterModal.isOpen}
        assetId={asset.id}
        onOpenChange={meterModal.changeOpen}
      />
    </div>
  )
}

export default AssetOverview
