import { useBreakpoint } from "@contexts/breakpoints"
import { IViewDataTypeEnum } from "@elara/db"
import {
  Data,
  groupBy,
  hasuraWhere,
  OrderBy,
  OrderByDirection,
  orderByOperator,
  useNullableWherePredicate,
  useOrderByCompareList,
  Where,
} from "@elara/select"
import { EventInput } from "@fullcalendar/core"
import { StickyType, useCallbackRef, useStickyState } from "@hooks"
import { NodeDisclosure, useTreeList, UseTreeListReturn } from "@hooks/use-tree-list"
import { naturalCompare } from "@utils"
import {
  buildTreeListFromId,
  FilteredTreeLike,
  filterTreeList,
  sortTreeList,
  Tree,
  TreeLike,
} from "@utils/tree"
import { dequal } from "dequal"
import React, { useCallback, useEffect, useMemo } from "react"
import { useDebounce } from "use-debounce"

import { useDataViewConfigContext } from "./data-view-config"
import {
  Schema,
  useDataViewFilter,
  UseDataViewFilterReturnType,
} from "./data-view-filter.hooks"
import {
  useBatchSelect,
  UseBatchSelectReturnType,
} from "./data-view-hooks/data-view-use-batch-select"
import {
  CalendarConfig,
  Column,
  CustomDataConfig,
  DataOrderConfig,
  DataViewConfiguration,
  DataViewLayoutType,
  FilterState,
  GroupedData,
  KanbanConfig,
  LayoutData,
} from "./data-view-types"

type UseDataViewOptions<D extends Data, Id extends string, Options extends {}> = {
  columns: Column<D, Id, Options>[]
  configId: string
  dataId: (data: D) => string
  dataSearchValue: (data: D) => string
  initialSearchValue?: string
  // Return a value to build up the parent child relationship
  getParentItemId?: (data: D) => string | null
  baseOrderBy?: DataOrderConfig<Id>[]
  hiddenFixedFilters: FilterState[]
  fixedFilters: FilterState[]
  onFiltersChange?: (filters: FilterState[]) => void
  filterSchema: Schema<D>
  configStickyness?: StickyType
  isCustomView: boolean
  customDataConfigToWhere?: (config: CustomDataConfig | null) => Where<D> | null
  useData?: (options: Where<D> | null) => {
    data: D[] | null
    isLoading: boolean
    events?: (range: { from: Date; to: Date }) => EventInput[]
  }
}

export type UseDataViewReturnType<D extends Data, Id extends string> = {
  data: LayoutData<D>
  treeList: FilteredTreeLike<Tree<D>>[]
  originalData: D[]
  dataSize: number
  config: DataViewConfiguration<Id>
  configId: string
  localStorageId: string
  getNodeDisclosure: (item: D) => NodeDisclosure
  isDataLoading: boolean
  isGroupCollapsed: (groupId: string | null) => boolean
  isNodeOpen: (item: D) => boolean
  navigate: UseTreeListReturn<D>["navigate"]
  toggleGroup: (groupId: string | null) => void
  updateColumnOrder: (columnOrder: Id[]) => void
  updateHistoryFilter: (historyFilter: FilterState) => void
  updateGroupBy: (groupBy: Id | null) => void
  updateKanban: (config: KanbanConfig) => void
  updateCalendar: (config: CalendarConfig) => void
  updateShowSummation: (showSummation: boolean) => void
  updateOrderBy: (id: Id, dir?: OrderByDirection | undefined) => void
  areFiltersPersisted: boolean
  isConfigPersisted: boolean
  hasNoFilterMatch: boolean
  hasNoSearchMatch: boolean
  hasNoData: boolean
  dataViewFilter: UseDataViewFilterReturnType<D>
  searchValue: string
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
  resetFilterAndSearch: () => void
  layoutType: DataViewLayoutType
  updateLayoutType: React.Dispatch<DataViewLayoutType>
  batchSelect: UseBatchSelectReturnType<D>
  events?: (range: { from: Date; to: Date }) => EventInput[]
}

export const tokenMap: Record<IViewDataTypeEnum, string> = {
  [IViewDataTypeEnum.Asset]: "asset",
  [IViewDataTypeEnum.AssetArchive]: "asset",
  [IViewDataTypeEnum.AssetState]: "activity",
  [IViewDataTypeEnum.Consumable]: "consumable",
  [IViewDataTypeEnum.Contact]: "contact",
  [IViewDataTypeEnum.Logbook]: "task",
  [IViewDataTypeEnum.Maintenance]: "maintenance",
  [IViewDataTypeEnum.MaintenanceArchive]: "maintenance",
  [IViewDataTypeEnum.Meter]: "meter",
  [IViewDataTypeEnum.Template]: "template",
  [IViewDataTypeEnum.Workorder]: "task",
  [IViewDataTypeEnum.ServiceRequest]: "service_request",
}

export function getLocalStorageId(configId: string) {
  return configId + "-v3"
}

export function useDataView<D extends Data, Id extends string, Options extends {}>(
  givenOriginalData: D[] | null,
  options: UseDataViewOptions<D, Id, Options>
): UseDataViewReturnType<D, Id> {
  const { configId, dataId, dataSearchValue } = options

  const localStorageId = getLocalStorageId(configId)
  const globalStateId = configId

  const {
    config,
    resettedConfig,
    actions: configActions,
    isConfigPersisted,
  } = useDataViewConfigContext<Id>()
  const areFiltersPersisted = useMemo(() => {
    return dequal(config.filters ?? [], resettedConfig.filters ?? [])
  }, [config])
  const dataViewFilter = useDataViewFilter(
    config.filters ?? [],
    configActions.updateFilters,
    {
      schema: options.filterSchema!,
      hiddenFixedFilters: options.hiddenFixedFilters.concat(config.historyFilter ?? []),
      fixedFilters: options.fixedFilters,
      customConfigWhere:
        options.customDataConfigToWhere?.(config.customDataConfig ?? null) ?? null,
    }
  )

  const bp = useBreakpoint()
  let layoutType = config.layoutType ?? "table"
  if (bp.mobile && layoutType === "table") {
    layoutType = "list"
  }

  const [searchValue, setSearchValue] = useStickyState(
    options.initialSearchValue ?? "",
    globalStateId + "__search",
    "global"
  )
  const [debouncedSearchValue] = useDebounce(searchValue, 300)

  const resetFilterAndSearch = useCallbackRef(() => {
    dataViewFilter.clearFilter()
    setSearchValue("")
  })

  // Now perform filtering + ordering

  const fixedWherePredicate = useNullableWherePredicate<D>(
    dataViewFilter.fixedWhereStatement
  )
  const stateWherePredicate = useNullableWherePredicate<D>(
    dataViewFilter.stateWhereStatement
  )

  const orderBys: OrderBy<D>[] = useMemo(
    () =>
      config.orderBy
        .concat(options.baseOrderBy ?? [])
        .map(({ id, dir, nulls = "nulls_last" }) => {
          const op = orderByOperator(dir, nulls)
          return (options.columns.find((c) => c.id === id)?.orderBy?.(op) ??
            {}) as OrderBy<D>
        }),
    [options.columns, config.orderBy]
  )

  const orderByCompare = useOrderByCompareList(orderBys)

  const dataInput = options.useData?.(
    hasuraWhere<D>((dataViewFilter.whereStatement ?? {}) as Where<D>)
  ) ?? { data: givenOriginalData, isLoading: givenOriginalData === null }
  const originalData = dataInput.data ?? []
  const events = dataInput.events

  let originalDataAsTree: TreeLike<D>[] = useMemo(() => {
    if (options.getParentItemId) {
      return buildTreeListFromId(originalData, {
        getItemId: dataId,
        getParentItemId: options.getParentItemId,
      })
    }
    return originalData
  }, [originalData])

  const isTreeStructure = !!options.getParentItemId

  const fixedFilteredData: TreeLike<D>[] = useMemo(() => {
    if (!fixedWherePredicate) return originalDataAsTree
    return isTreeStructure
      ? filterTreeList(originalDataAsTree, fixedWherePredicate)
      : originalDataAsTree.filter(fixedWherePredicate)
  }, [originalDataAsTree, fixedWherePredicate])

  const filteredData: TreeLike<D>[] = useMemo(() => {
    if (!stateWherePredicate) return fixedFilteredData
    return isTreeStructure
      ? filterTreeList(fixedFilteredData, stateWherePredicate)
      : fixedFilteredData.filter(stateWherePredicate)
  }, [fixedFilteredData, stateWherePredicate])

  const groupData = (
    inputData: TreeLike<D>[],
    sortData?: (d: TreeLike<D>[]) => TreeLike<D>[]
  ): GroupedData<D> => {
    if (!config.groupBy)
      return { type: "ungrouped", data: sortData ? sortData(inputData) : inputData }
    const col = options.columns.find((c) => c.id === config.groupBy)
    if (!col?.groupBy?.id)
      return { type: "ungrouped", data: sortData ? sortData(inputData) : inputData }
    // group by preserves the order of elements in a given group
    const groups = groupBy(inputData, { groupId: col.groupBy.id })
      .map((v) => {
        const row = v.items?.[0] ?? null
        return {
          groupId: v.groupId,
          data: sortData ? sortData(v.items) : v.items,
          label: col?.groupBy?.label?.(v.groupId, row) ?? "",
          labelText: col?.groupBy?.labelText?.(v.groupId, row) ?? "",
          orderValue: col?.groupBy?.orderValue?.(v.groupId, row) ?? null,
        }
      })
      .sort((a, b) => {
        if (a.groupId && b.groupId) {
          return naturalCompare(
            a.orderValue ?? (a.label as string | null),
            b.orderValue ?? (b.label as string | null)
          )
        } else {
          return a.groupId ? -1 : 1
        }
      })
    const data: TreeLike<D>[] = groups.reduce(
      (d, g) => d.concat(g.data),
      [] as TreeLike<D>[]
    )
    return { type: "grouped", groups, data }
  }

  const ordededAndGroupedData = useMemo(() => {
    const sortData = (data: TreeLike<D>[]) =>
      isTreeStructure
        ? sortTreeList(data, orderByCompare)
        : data.slice().sort(orderByCompare)

    return groupData(filteredData, sortData)
  }, [filteredData, orderByCompare, config.groupBy])

  const [stickyOpenCloseState, setStickyOpenCloseState] = useStickyState(
    {} as Record<string, boolean>,
    `DataViewOpenNodes:${globalStateId}`,
    "global"
  )

  const { treeList, getNodeDisclosure, openCloseState, navigate, isNodeOpen } = useTreeList(
    ordededAndGroupedData.data,
    {
      key: dataId,
      searchValue: dataSearchValue,
      searchInput: debouncedSearchValue,
      initialOpenCloseState: stickyOpenCloseState,
    }
  )

  const layoutData: LayoutData<D> = useMemo(() => {
    if (ordededAndGroupedData.type === "ungrouped" || debouncedSearchValue)
      return { type: "ungrouped" as "ungrouped", items: treeList }
    let lastGroupIndex = 0
    const groups = ordededAndGroupedData.groups.map((g) => {
      const nextGroupIndex = lastGroupIndex + g.data.length
      const group = {
        label: g.label,
        groupId: g.groupId,
        labelText: g.labelText,
        items: treeList.slice(lastGroupIndex, lastGroupIndex + g.data.length),
      }
      lastGroupIndex = nextGroupIndex
      return group
    })
    return { type: "grouped" as "grouped", groups }
  }, [ordededAndGroupedData, treeList, debouncedSearchValue])

  useEffect(() => {
    setStickyOpenCloseState(openCloseState)
  }, [openCloseState])

  const groupCollapsedInitialState = () => {
    if (!config.groupBy) return []
    const col = options.columns.find((c) => c.id === config.groupBy)
    const defaultCollapsed = col?.groupBy?.defaultCollapsed
    if (!defaultCollapsed) return []
    if (ordededAndGroupedData.type === "grouped") {
      return ordededAndGroupedData.groups.map(
        (g) => [g.groupId, defaultCollapsed(g.groupId)] as [string, boolean]
      )
    }
    return []
  }

  const [groupCollapsedState, setGroupCollapsedState] = useStickyState(
    Object.fromEntries(groupCollapsedInitialState()),
    `DataViewCollapsedGroups:${localStorageId}`,
    "localStorage"
  )

  const toggleGroup = useCallback(
    (groupId: string | null) =>
      setGroupCollapsedState((state) => ({
        ...state,
        [groupId ?? "__NULL__"]: !state[groupId ?? "__NULL__"],
      })),
    []
  )
  const isGroupCollapsed = useCallback(
    (groupId: string | null) => !!groupCollapsedState[groupId ?? "__NULL__"],
    [groupCollapsedState]
  )

  const hasNoData = !fixedFilteredData.length && areFiltersPersisted
  const hasNoSearchMatch = !fixedFilteredData.length && !!searchValue && !treeList.length
  const hasNoFilterMatch =
    !hasNoData && !fixedFilteredData.length && !hasNoSearchMatch && !areFiltersPersisted

  const batchSelect = useBatchSelect<D>({
    dataId,
    data: layoutData,
    storageId: localStorageId,
  })

  return {
    data: layoutData,
    events,
    localStorageId,
    originalData: fixedFilteredData,
    isDataLoading: dataInput.isLoading,
    treeList,
    configId: options.configId,
    dataSize: treeList.length,
    navigate,
    config,
    areFiltersPersisted,
    isConfigPersisted,
    getNodeDisclosure,
    isNodeOpen,
    isGroupCollapsed,
    toggleGroup,
    ...configActions,
    hasNoData,
    hasNoFilterMatch,
    hasNoSearchMatch,
    dataViewFilter,
    searchValue,
    setSearchValue,
    resetFilterAndSearch,
    layoutType,
    batchSelect,
  }
}
