import { Button } from "@components/shared"
import {
  AlertDialogContent,
  AlertDialogRoot,
  AlertDialogTrigger,
} from "@components/shared/alert-dialog"
import { Dialog, DialogClose } from "@components/shared/dialog"
import TouchTargetSize from "@components/shared/touch-target-size"
import { UserTagById } from "@components/shared/user-tag-by-id"
import { IPermissionScopeEnum, uuid } from "@elara/db"
import {
  DeleteAssetStateDocument,
  IDeleteAssetStateMutation,
  IDeleteAssetStateMutationVariables,
} from "@graphql/documents/asset-state.generated"
import { IAssetStateLogFragment } from "@graphql/documents/fragments.generated"
import { useWorkOrderDetailsForAssetStateQuery } from "@graphql/documents/work-order.generated"
import { usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { ArrowRight, PencilSimpleLine, TrashSimple } from "@phosphor-icons/react"
import { formatDate, formatDuration } from "@utils/date"
import { LinkWithBackgroundLocation } from "@utils/location"
import { differenceInHours, differenceInMinutes } from "date-fns"
import { PropsWithChildren } from "react"
import React from "react"
import { Link } from "react-router-dom"
import { useClient } from "urql"

import AssetStateFormDialog from "./asset-state-form-dialog"
import AssetStateVariantLabel from "./asset-state-variant-label"

export type CurrentState = Partial<Omit<IAssetStateLogFragment, "__typename">> &
  Pick<IAssetStateLogFragment, "asset_state_variant"> & {
    downtime_in_operating_hours?: number | null
  }

export const WorkOrderDetailsForAssetState = (workOrderId: uuid) => {
  const [workOrderQueryRes] = useWorkOrderDetailsForAssetStateQuery({
    variables: { id: workOrderId },
  })
  return workOrderQueryRes.data?.work_order_by_pk
}

export function AssetStateDetail(props: { state: CurrentState; abbreviated?: boolean }) {
  const { state, abbreviated } = props
  const workOrder = state.work_order_id
    ? WorkOrderDetailsForAssetState(state.work_order_id)
    : null
  const startedAt = state.started_at ? new Date(state.started_at) : null
  const endedAt = state.ended_at ? new Date(state.ended_at) : null
  const duration =
    typeof state.downtime_in_operating_hours === "number"
      ? {
          hours: Math.trunc(state.downtime_in_operating_hours),
          minutes: Math.trunc(state.downtime_in_operating_hours * 60) % 60,
        }
      : {
          hours: startedAt && endedAt ? differenceInHours(endedAt, startedAt) : 0,
          minutes: startedAt && endedAt ? differenceInMinutes(endedAt, startedAt) % 60 : 0,
        }

  return (
    <div className="space-y-3">
      <div className="flex">
        {state.asset_state_variant && (
          <AssetStateVariantLabel variant={state.asset_state_variant} />
        )}
      </div>
      {state.note && (
        <div>
          <div className="mt-1 text-sm text-gray-600">{state.note}</div>
        </div>
      )}
      <div className="grid grid-cols-2 gap-y-2">
        {endedAt && (
          <>
            <div className="text-sm font-medium text-gray-600">
              {i18n.t("assets:state.fields.duration")}
            </div>
            <div className="text-sm text-gray-600">{formatDuration(duration)}</div>
          </>
        )}
        {startedAt && (
          <>
            <div className="mb-1 text-sm font-medium text-gray-600">
              {i18n.t("assets:state.fields.started_at")}
            </div>
            <div className="text-sm text-gray-600">{formatDate(startedAt, "Pp")}</div>
          </>
        )}
        {!abbreviated && endedAt && (
          <>
            <div className="text-sm font-medium text-gray-600">
              {i18n.t("assets:state.fields.ended_at")}
            </div>
            <div className="text-sm text-gray-600">{formatDate(endedAt, "Pp")}</div>
          </>
        )}

        {!abbreviated && state.created_at && (
          <>
            <div className="text-sm font-medium text-gray-600">
              {i18n.t("assets:state.fields.created_at")}
            </div>
            <div className="text-sm text-gray-600">
              {formatDate(new Date(state.created_at), "Pp")}
            </div>
          </>
        )}
        {!abbreviated && state.created_by_id && (
          <>
            <div className="mb-1 text-sm font-medium text-gray-600">
              {i18n.t("assets:state.fields.created_by")}
            </div>
            <span className="text-gray-600">
              <UserTagById id={state.created_by_id} />
            </span>
          </>
        )}
        {workOrder && (
          <>
            <div className="mb-1 text-sm font-medium text-gray-600">
              {i18n.t("assets:state.fields.work_order_id")}
            </div>
            <div className="inline-block max-w-full truncate">
              <Link
                to={`/work-order/${state.work_order_id}`}
                className="rounded p-1 text-sm font-medium text-gray-600 hover:bg-gray-100">
                {`#${workOrder.work_order_number} ${workOrder.name}`}
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export const AssetStateDetailDialog = (
  props: PropsWithChildren<{
    assetId: uuid
    state: CurrentState
    isOpen?: boolean
    onOpenChange?: (open: boolean) => void
    onDelete?: () => void
    onEdit?: () => void
    allowDelete?: boolean
    allowEdit?: boolean
  }>
) => {
  const { allowDelete = !!props.onDelete, allowEdit = !!props.onEdit } = props
  const client = useClient()
  const dataEntryScope = usePermissionScope(IPermissionScopeEnum.AppDataEntry)
  return (
    <Dialog
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
      title={i18n.t("assets:state.detail.title")}
      content={
        <React.Fragment>
          <AssetStateDetail state={props.state} />
          <LinkWithBackgroundLocation
            to={`/object/${props.assetId}/insights/reliability`}
            className="relative mt-3 flex items-center text-sm font-medium text-blue-500 hover:underline">
            <ArrowRight className="mr-2" weight="bold" />
            {i18n.t("assets:state.detail.link_to_history")}
            <TouchTargetSize />
          </LinkWithBackgroundLocation>

          <div className="mt-2 flex justify-end space-x-6">
            {allowDelete && (
              <AlertDialogRoot>
                <AlertDialogContent
                  danger={true}
                  title={i18n.t("assets:state.delete.title")}
                  description={i18n.t("assets:state.delete.message")}
                  action={
                    <DialogClose asChild>
                      <Button
                        type="primary"
                        color="red"
                        icon={TrashSimple}
                        disabled={!dataEntryScope.hasScope}
                        onClickCapture={async () => {
                          if (props.state.id) {
                            const res = await client
                              .mutation<
                                IDeleteAssetStateMutation,
                                IDeleteAssetStateMutationVariables
                              >(
                                DeleteAssetStateDocument,
                                { id: props.state.id },
                                dataEntryScope.context()
                              )
                              .toPromise()

                            if (!res.error) {
                              props.onDelete?.()
                            }
                          }
                        }}>
                        {i18n.t("common:delete")}
                      </Button>
                    </DialogClose>
                  }
                />
                <AlertDialogTrigger asChild>
                  <Button
                    type="primary"
                    color="red"
                    icon={TrashSimple}
                    disabled={!dataEntryScope.hasScope || !props.state?.id}>
                    {i18n.t("common:delete")}
                  </Button>
                </AlertDialogTrigger>
              </AlertDialogRoot>
            )}
            {allowEdit && props.state?.id && (
              <AssetStateFormDialog
                state={props.state as IAssetStateLogFragment}
                assetId={props.assetId}
                onSubmit={props.onEdit}
                withEndedAt={!!props.state.ended_at}>
                <Button type="secondary" icon={PencilSimpleLine}>
                  {i18n.t("common:edit")}
                </Button>
              </AssetStateFormDialog>
            )}
          </div>
        </React.Fragment>
      }>
      {props.children}
    </Dialog>
  )
}

export default AssetStateDetail
