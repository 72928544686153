import { ActionMenu, Button } from "@components/shared"
import { openElaraCommandDialog } from "@components/shared/elara-command"
import toast from "@components/shared/toast"
import {
  useConvertTaskToTemplate,
  useDeleteWorkOrder,
} from "@components/work-order/work-order-detail.hooks"
import { useFeature } from "@contexts/feature-flag-context"
import { IWorkOrderTypeEnum } from "@elara/db"
import { IWorkOrderFragment } from "@graphql/documents/fragments.generated"
import {
  IUpdateWorkOrderPartiallyMutation,
  IUpdateWorkOrderPartiallyMutationVariables,
  UpdateWorkOrderPartiallyDocument,
} from "@graphql/documents/work-order.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { useTaskOptions } from "@pages/new-task.$id/components/options.hooks"
import {
  Backspace,
  CheckSquareOffset,
  Copy,
  DotsThreeVertical,
  Eye,
  EyeSlash,
  Globe,
  Link as LinkIcon,
  Printer,
  Swap,
  TrashSimple,
} from "@phosphor-icons/react"
import Icons from "@resources/icons"
import { CommandDef, useRegisterCommands } from "@stores/command"
import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useClient } from "urql"

export const TaskOptions = (props: { workOrder: IWorkOrderFragment }) => {
  const { workOrder } = props
  const { onCopyWorkOrder, onSetSubtaskToTask, onCreateShareLink } = useTaskOptions({
    workOrder,
  })

  const client = useClient()
  const navigate = useNavigate()
  const location = useLocation()
  const hasPublicTaskFeature = useFeature("public_task")

  const convertToTemplate = useConvertTaskToTemplate()
  const isTemplate = workOrder.type === IWorkOrderTypeEnum.Template

  const deleteWorkOrder = useDeleteWorkOrder({
    workOrderId: workOrder.id,
    isTemplate,
    afterDelete: () => {
      workOrder.type === IWorkOrderTypeEnum.Template
        ? navigate("/settings/template")
        : navigate(-1)
    },
  })

  const createWorkOrderScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderCreate)
  const deleteWorkOrderScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderDelete)
  const editWorkOrderScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)

  const handlePrint = () => {
    navigate("print", { replace: true, state: location.state })
  }
  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === "p" && (e.metaKey || e.ctrlKey)) {
        handlePrint()
        e.preventDefault()
      }
    }
    window.addEventListener("keydown", handler)
    return () => {
      window.removeEventListener("keydown", handler)
    }
  }, [])

  const handleCopyLinkToTask = () => {
    navigator.clipboard.writeText(window.location.origin + "/task/" + workOrder.id)
    toast.success(i18n.t("tasks:messages.link_copied_clipboard"))
  }

  const handleConvertTaskToTemplate = async () => {
    const template = await convertToTemplate(workOrder as IWorkOrderFragment)
    navigate(`/template/${template.id}`)
  }

  const handleSetParentTask = async (parentTaskId: string) => {
    await client
      .mutation<
        IUpdateWorkOrderPartiallyMutation,
        IUpdateWorkOrderPartiallyMutationVariables
      >(
        UpdateWorkOrderPartiallyDocument,
        { id: workOrder?.id!, changes: { parent_work_order_id: parentTaskId } },
        editWorkOrderScope.context()
      )
      .toPromise()
  }

  const handleToggleTaskVisibility = async () => {
    await client
      .mutation<
        IUpdateWorkOrderPartiallyMutation,
        IUpdateWorkOrderPartiallyMutationVariables
      >(
        UpdateWorkOrderPartiallyDocument,
        { id: workOrder.id, changes: { only_assigned: !workOrder.only_assigned } },
        editWorkOrderScope.context()
      )
      .toPromise()
  }

  useRegisterCommands(
    "1_work_order_options",
    [
      {
        type: "action",
        value: "print_task",
        searchValue: i18n.t("tasks:context_menu.print"),
        action: handlePrint,
        group: workOrder.name,
        icon: <Printer />,
        description: "Print task",
      },
      ...((workOrder.type === IWorkOrderTypeEnum.WorkOrder
        ? [
            {
              type: "action",
              value: "copy_link_to_task",
              searchValue: i18n.t("tasks:context_menu.copy_link"),
              action: handleCopyLinkToTask,
              group: workOrder.name,
              icon: <LinkIcon />,
              description: "Print task",
            },
          ]
        : []) as CommandDef[]),
      ...((createWorkOrderScope.hasScope && workOrder.type === IWorkOrderTypeEnum.WorkOrder
        ? [
            {
              type: "action",
              value: "duplicate_task",
              searchValue: i18n.t("tasks:context_menu.duplicate_task"),
              action: onCopyWorkOrder,
              group: workOrder.name,
              icon: <Copy />,
              description: "Duplicate task",
            },
            {
              type: "action",
              value: "convert_to_template",
              searchValue: i18n.t("tasks:context_menu.convert_to_template"),
              action: handleConvertTaskToTemplate,
              group: workOrder.name,
              icon: <Swap />,
              description: "Convert task to template",
            },
            {
              type: "page",
              page: "parent_task:select_task",
              value: "set_parent_task",
              searchValue: workOrder.parent_task
                ? i18n.t("tasks:context_menu.change_parent_task")
                : i18n.t("tasks:context_menu.set_parent_task"),
              group: workOrder.name,
              icon: <CheckSquareOffset />,
              description: "set/change parent task",
              subcommands: (searchResults) =>
                searchResults
                  .filter((c) => c.value.startsWith("open_task:"))
                  .map((c) => ({
                    ...c,
                    action: async () => {
                      if (c.meta?.task) {
                        await handleSetParentTask(c.meta.task.id)
                      }
                    },
                  })),
            },
          ]
        : []) as CommandDef[]),
      ...((editWorkOrderScope.hasScope && workOrder.parent_task
        ? [
            {
              type: "action",
              value: "remove_parent_task",
              searchValue: i18n.t("tasks:context_menu.remove_parent_task"),
              action: onSetSubtaskToTask,
              group: workOrder.name,
              icon: <Backspace />,
              description: "Remove parent task",
            },
          ]
        : []) as CommandDef[]),
      ...((editWorkOrderScope.hasScope && workOrder.type === IWorkOrderTypeEnum.WorkOrder
        ? [
            {
              type: "action",
              value: "mark_task_as_private",
              searchValue: !workOrder.only_assigned
                ? i18n.t("tasks:context_menu.set_private")
                : i18n.t("tasks:context_menu.set_public"),
              action: handleToggleTaskVisibility,
              group: workOrder.name,
              icon: !workOrder.only_assigned ? <EyeSlash /> : <Eye />,
              description: "Toggle task visibility",
            },
          ]
        : []) as CommandDef[]),
      ...((editWorkOrderScope.hasScope &&
      !workOrder.only_assigned &&
      hasPublicTaskFeature &&
      workOrder.type === IWorkOrderTypeEnum.WorkOrder
        ? [
            {
              type: "action",
              value: "public_access",
              searchValue: workOrder.public_view_expiration
                ? i18n.t("tasks:dialogs.public_access.revoke.title")
                : i18n.t("tasks:dialogs.public_access.create.title"),
              action: onCreateShareLink,
              group: workOrder.name,
              icon: <Globe />,
              description: "Toggle task visibility for people outside this organization",
            },
          ]
        : []) as CommandDef[]),
      ...((deleteWorkOrderScope.hasScope && workOrder.type === IWorkOrderTypeEnum.WorkOrder
        ? [
            {
              type: "action",
              value: "delete_task",
              searchValue: i18n.t("common:delete"),
              action: deleteWorkOrder.delete,
              group: workOrder.name,
              icon: <TrashSimple />,
              description: "Delete task",
            },
          ]
        : []) as CommandDef[]),
    ],
    [workOrder.last_update]
  )

  const actionMenuItems = [
    {
      key: "print",
      icon: <Printer />,
      label: i18n.t("tasks:context_menu.print"),
      action: handlePrint,
    },
    workOrder.type === IWorkOrderTypeEnum.WorkOrder
      ? {
          key: "copy_link",
          icon: <LinkIcon />,
          label: i18n.t("tasks:context_menu.copy_link"),
          action: handleCopyLinkToTask,
        }
      : null,
    createWorkOrderScope.hasScope && workOrder.type === IWorkOrderTypeEnum.WorkOrder
      ? {
          key: "duplicate_task",
          icon: <Copy />,
          label: i18n.t("tasks:context_menu.duplicate_task"),
          action: onCopyWorkOrder,
        }
      : null,
    createWorkOrderScope.hasScope && workOrder.type === IWorkOrderTypeEnum.WorkOrder
      ? {
          key: "convert_to_template",
          icon: <Swap />,
          label: i18n.t("tasks:context_menu.convert_to_template"),
          action: handleConvertTaskToTemplate,
        }
      : null,
    editWorkOrderScope.hasScope && workOrder.parent_task
      ? {
          key: "remove_parent_task",
          icon: <Backspace />,
          label: i18n.t("tasks:context_menu.remove_parent_task"),
          action: onSetSubtaskToTask,
        }
      : null,
    editWorkOrderScope.hasScope && workOrder.type == IWorkOrderTypeEnum.WorkOrder
      ? {
          key: "set_parent_task",
          icon: <CheckSquareOffset />,
          label: workOrder.parent_task
            ? i18n.t("tasks:context_menu.change_parent_task")
            : i18n.t("tasks:context_menu.set_parent_task"),
          action: () => openElaraCommandDialog("parent_task:select_task"),
        }
      : null,
    editWorkOrderScope.hasScope && workOrder.type === IWorkOrderTypeEnum.WorkOrder
      ? {
          key: "mark_private",
          icon: workOrder.only_assigned ? (
            <Eye className="h-[1em] w-[1em]" />
          ) : (
            <EyeSlash />
          ),
          label: !workOrder.only_assigned
            ? i18n.t("tasks:context_menu.set_private")
            : i18n.t("tasks:context_menu.set_public"),
          action: handleToggleTaskVisibility,
        }
      : null,
    editWorkOrderScope.hasScope &&
    !workOrder.only_assigned &&
    hasPublicTaskFeature &&
    workOrder.type === IWorkOrderTypeEnum.WorkOrder
      ? {
          key: "public_access",
          icon: <Globe />,
          label: workOrder.public_view_expiration
            ? i18n.t("tasks:dialogs.public_access.revoke.title")
            : i18n.t("tasks:dialogs.public_access.create.title"),
          action: onCreateShareLink,
        }
      : null,
    deleteWorkOrderScope.hasScope && workOrder.type === IWorkOrderTypeEnum.WorkOrder
      ? {
          key: "delete",
          icon: <Icons.Delete />,
          label: i18n.t("common:delete"),
          action: deleteWorkOrder.delete,
        }
      : null,
  ]

  if (!actionMenuItems.filter(Boolean).length) return null

  return (
    <>
      <div className="flex justify-end print:hidden">
        <div className="hidden @2xl:block">
          <ActionMenu items={actionMenuItems}>
            <Button size="small" type="secondary" icon={DotsThreeVertical}>
              {i18n.t("tasks:header.options")}
            </Button>
          </ActionMenu>
        </div>
        <div className="@2xl:hidden">
          <ActionMenu items={actionMenuItems}>
            <Button size="small" type="secondary" icon={DotsThreeVertical} />
          </ActionMenu>
        </div>
        {deleteWorkOrder.deleteConfirmModal}
      </div>
    </>
  )
}
