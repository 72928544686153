import {
  IMutationRootDeleteMeterByPkArgs,
  IMutationRootDeleteMeterReadingByPkArgs,
  IMutationRootInsertMeterOneArgs,
  IMutationRootInsertMeterReadingOneArgs,
} from "@elara/db"
import {
  AssetDetailsDocument,
  IAssetDetailsQuery,
  IAssetDetailsQueryVariables,
  IMetersByAssetIdQuery,
  IMetersByAssetIdQueryVariables,
  MetersByAssetIdDocument,
} from "@graphql/documents/asset.generated"
import {
  AssetMeterDetailsDocument,
  IAssetMeterDetailsQuery,
  IAssetMeterDetailsQueryVariables,
  IMeterDataViewQuery,
  IMeterDataViewQueryVariables,
  MeterDataViewDocument,
} from "@graphql/documents/asset-meter.generated"
import {
  IMeterDataViewFragment,
  IMeterReadingFragment,
  MeterDataViewFragmentDoc,
} from "@graphql/documents/fragments.generated"
import { Data, UpdateResolver } from "@urql/exchange-graphcache"

type Mutations = {
  [fieldName: string]: UpdateResolver
}

const mutations: Mutations = {
  // Delete
  delete_meter_by_pk(_result, args: IMutationRootDeleteMeterByPkArgs, cache) {
    cache.invalidate({
      __typename: "meter",
      id: args.id,
    })
  },
  delete_meter_reading_by_pk(
    _result,
    args: IMutationRootDeleteMeterReadingByPkArgs,
    cache
  ) {
    cache.invalidate({
      __typename: "meter_reading",
      id: args.id,
    })
  },

  // Insert
  insert_meter_one(
    result: { insert_meter_one?: IMeterDataViewFragment },
    args: IMutationRootInsertMeterOneArgs,
    cache
  ) {
    // return for upserts
    if (args.object.id) return

    const meter = result.insert_meter_one
    const assetId = args.object.asset_id

    if (!meter || !assetId) return

    cache.updateQuery<IAssetDetailsQuery, IAssetDetailsQueryVariables>(
      {
        query: AssetDetailsDocument,
        variables: { id: assetId },
      },
      (data) => {
        data?.asset_by_pk?.meters.push(meter)
        return data
      }
    )
    cache.updateQuery<IMeterDataViewQuery, IMeterDataViewQueryVariables>(
      { query: MeterDataViewDocument },
      (data) => {
        data?.meter?.push(meter)
        return data
      }
    )
    cache.updateQuery<IMeterDataViewQuery, IMeterDataViewQueryVariables>(
      {
        query: MeterDataViewDocument,
        variables: {
          where: {
            asset: {
              _or: [{ id: { _eq: assetId } }, { parent_ids: { _has_key: assetId } }],
            },
          },
        },
      },
      (data) => {
        data?.meter?.push(meter)
        return data
      }
    )
    cache.updateQuery<IMetersByAssetIdQuery, IMetersByAssetIdQueryVariables>(
      { query: MetersByAssetIdDocument, variables: { assetId } },
      (data) => {
        data?.meter?.push(meter)
        return data
      }
    )
  },
  insert_meter_reading_one(
    result: { insert_meter_reading_one?: IMeterReadingFragment },
    args: IMutationRootInsertMeterReadingOneArgs,
    cache
  ) {
    if (args.object.id) return

    const reading = result.insert_meter_reading_one
    if (!reading) return

    const meterId = args.object.meter_id
    if (!meterId) return

    cache.updateQuery<IAssetMeterDetailsQuery, IAssetMeterDetailsQueryVariables>(
      {
        query: AssetMeterDetailsDocument,
        variables: { meterId },
      },
      (data) => {
        if (!data || !data.meter_by_pk) return data

        data.meter_by_pk?.meter_readings.unshift(reading)
        return data
      }
    )
    // Update fragment
    const meterSummary = cache.readFragment<IMeterDataViewFragment>(
      MeterDataViewFragmentDoc,
      {
        id: meterId,
      } as Data
    )
    if (meterSummary) {
      const [last_reading] = meterSummary.last_readings
      if (!last_reading) {
        meterSummary.last_readings = [reading]
      } else {
        const readingIsNewer =
          new Date(reading.measured_at).getTime() >
          new Date(last_reading.measured_at).getTime()
        if (readingIsNewer) {
          meterSummary.last_readings = [reading]
        }
      }
      cache.writeFragment(MeterDataViewFragmentDoc, meterSummary)
    }
  },
}

export default {
  mutations,
}
