import SettingsPageLayout from "@components/settings/page-layout"
import { ActionMenu, Button, LoadingIndicator, UserSingleSelect } from "@components/shared"
import { alertDialog } from "@components/shared/alert-dialog-provider"
import { CheckboxInput } from "@components/shared/form/checkbox-input"
import { FormField } from "@components/shared/form/form-field"
import IconPicker from "@components/shared/icon-picker"
import { SelectTrigger } from "@components/shared/single-select"
import { IconData } from "@components/shared/team-icons"
import { TextInput } from "@components/shared/text-input"
import { UserAvatar } from "@components/shared/user-avatar"
import { usePage } from "@contexts/page-context"
import { useUser } from "@contexts/user-context"
import { INotificationTypeEnum, TeamNotificationSettings } from "@elara/db"
import { orderBy } from "@elara/select"
import {
  IUpdateTeamNotificationSettingsMutation,
  IUpdateTeamNotificationSettingsMutationVariables,
  UpdateTeamNotificationSettingsDocument,
  useAddTeamMemberMutation,
  useDeleteTeamMemberMutation,
  useDeleteTeamMutation,
  useTeamByIdQuery,
  useUpdateTeamMutation,
} from "@graphql/documents/team.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { DotsThreeVertical, TrashSimple, UserPlus } from "@phosphor-icons/react"
import Icons from "@resources/icons"
import classNames from "classnames"
import { Form, Formik } from "formik"
import React, { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useClient } from "urql"

export const SettingsTeam = () => {
  usePage({
    id: "settings/team",
    title: "Team",
    isSubPage: true,
  })

  const client = useClient()
  const user = useUser()
  const { teamId } = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState({
    name: false,
    icon: false,
    privacy: false,
    members: false,
    archive: false,
    unarchive: false,
    delete: false,
  })

  const [queryRes, refetchTeam] = useTeamByIdQuery({
    variables: { id: teamId! },
    pause: !teamId,
  })
  const team = queryRes?.data?.team_by_pk
  const adminScope = usePermissionScope(IPermissionScopeEnum.AppAccountManagement)
  const isAdmin = adminScope.hasScope
  const userScope = usePermissionScope(IPermissionScopeEnum.AppUser)
  const isTeamOwner = team?.owner_id == user?.id
  const accountEditScope = isAdmin ? adminScope : userScope

  const [, updateTeam] = useUpdateTeamMutation()
  const [, deleteTeam] = useDeleteTeamMutation()
  const [, addTeamMember] = useAddTeamMemberMutation()
  const [, deleteTeamMember] = useDeleteTeamMemberMutation()

  if (!team) return <LoadingIndicator size={24} />

  const onChangeTeamName = async ({ name }: { name: string }) => {
    setLoading({ ...loading, name: true })
    await updateTeam({ id: team?.id, data: { name } }, accountEditScope.context())
    setLoading({ ...loading, name: false })
  }

  const onChangeIcon = async (iconData: IconData) => {
    setLoading({ ...loading, icon: true })
    await updateTeam({ id: team?.id, data: { icon: iconData } }, accountEditScope.context())
    setLoading({ ...loading, icon: false })
  }

  const onChangePrivacy = async (privacy: boolean) => {
    setLoading({ ...loading, privacy: true })
    await updateTeam(
      { id: team?.id, data: { private: privacy } },
      accountEditScope.context()
    )
    setLoading({ ...loading, privacy: false })
  }

  const onDelete = async () => {
    setLoading({ ...loading, delete: true })
    const shouldDelete = await alertDialog({
      danger: true,
      title: i18n.t("settings:teams.delete_team_title"),
      description: i18n.t("settings:teams.delete_team_confirmation"),
      actionText: i18n.t("common:delete"),
    })
    if (shouldDelete) {
      await deleteTeam({ id: team?.id }, accountEditScope.context())
      navigate("/settings/teams")
      setLoading({ ...loading, delete: false })
    }
  }

  const upgradeTeamMemberRole = async (userId: string) => {
    setLoading({ ...loading, members: true })
    await updateTeam(
      { id: team?.id, data: { owner_id: userId } },
      accountEditScope.context()
    )
    setLoading({ ...loading, members: false })
  }

  const handleAddTeamMember = async (userId: string) => {
    setLoading({ ...loading, members: true })
    await addTeamMember({ userId, teamId: team?.id }, accountEditScope.context())
    refetchTeam()
    setLoading({ ...loading, members: false })
  }

  const handleDeleteTeamMember = async (userId: string) => {
    setLoading({ ...loading, members: true })
    await deleteTeamMember({ userId, teamId: team?.id }, accountEditScope.context())
    refetchTeam()
    setLoading({ ...loading, members: false })
  }

  return (
    <>
      <SettingsPageLayout
        title={team.name}
        backlink="/settings/team"
        description={i18n.t("settings:team_management.description")}>
        {(isTeamOwner || isAdmin) && (
          <>
            <Formik
              enableReinitialize
              initialValues={{ name: team.name }}
              onSubmit={onChangeTeamName}>
              {(formik) => (
                <Form className="flex items-end gap-2 sm:gap-4">
                  <FormField
                    label="Name"
                    name="name"
                    className="w-1/2 md:w-full"
                    hasErrorPlaceholder={false}>
                    <TextInput disabled={loading.name} />
                  </FormField>

                  <Button
                    size="small"
                    htmlType="submit"
                    disabled={!(isAdmin || isTeamOwner)}
                    isLoading={loading.name}
                    className={classNames("opacity-0 transition-opacity px-5", {
                      "opacity-100": formik.dirty,
                    })}>
                    {i18n.t("common:save")}
                  </Button>
                </Form>
              )}
            </Formik>

            <label className="mb-3 mt-2">
              <span className="mb-1 text-sm font-medium text-gray-700">Icon</span>
              <IconPicker
                section="team"
                onChange={onChangeIcon}
                name={(team.icon as IconData)?.name}
                color={(team.icon as IconData)?.color}
                showChangeButton
              />
            </label>

            <hr />
          </>
        )}

        <div className=" flex flex-wrap items-center justify-between">
          <div>
            <h4 className="font-medium">{i18n.t("settings:teams.change_team_members")}</h4>
            <p className="mt-2 text-sm text-gray-600">
              {i18n.t("settings:teams.change_team_members_description")}
            </p>
          </div>
          <UserSingleSelect
            popoverContentProps={{
              side: "left",
              sideOffset: 8,
              align: "start",
            }}
            onChange={(userId) => {
              if (!userId) return
              handleAddTeamMember(userId)
            }}
            userIsSelectable={(u) => !team.members.some((m) => m.user.id === u.id)}>
            <SelectTrigger asChild>
              <Button
                disabled={!(isAdmin || isTeamOwner)}
                disabledReason={i18n.t("settings:teams.add_disabled_reason")}
                size="small"
                type="tertiary"
                icon={UserPlus}
                className="mt-3 self-center lg:mt-0">
                {i18n.t("common:add_token", {
                  token: i18n.t("common:member", { count: 1 }),
                })}
              </Button>
            </SelectTrigger>
          </UserSingleSelect>
        </div>

        <div className="grid grid-cols-2 gap-y-4 divide-y divide-gray-100">
          {orderBy(team.members, { user: { first_name: "asc", last_name: "asc" } }).map(
            (m) => (
              <React.Fragment key={m.user.id}>
                <div className="flex items-center border-gray-100 pt-4 first:border-t">
                  <UserAvatar user={m.user} className="mr-3 text-xl" />
                  <div
                    className={classNames("mr-1 text-sm font-medium truncate", {
                      "line-through": !!m.user.deleted_at,
                    })}>
                    {m.user.first_name} {m.user.last_name}
                  </div>
                </div>
                <div className="flex items-center justify-between pt-4">
                  <div className="ml-4 truncate text-sm text-gray-600 md:ml-0">
                    {m.user.id === team.owner_id
                      ? i18n.t("teams:roles.owner")
                      : i18n.t("common:member", { count: 1 })}
                  </div>
                  {m.user.id !== team.owner_id && (isTeamOwner || isAdmin) && (
                    <ActionMenu
                      items={[
                        {
                          key: "remove",
                          label: i18n.t("common:remove_token", {
                            token: i18n.t("common:member", { count: 1 }),
                          }),
                          action: () => handleDeleteTeamMember(m.user.id),
                        },
                        m.user.deleted_at
                          ? null
                          : {
                              key: "owner",
                              label: i18n.t("teams:actions.promote_member"),
                              action: () => upgradeTeamMemberRole(m.user.id),
                            },
                      ]}>
                      <Button color="gray" type="tertiary" icon={DotsThreeVertical} />
                    </ActionMenu>
                  )}
                </div>
              </React.Fragment>
            )
          )}
        </div>

        <hr />

        <div className="mb-6 space-y-4">
          <h4 className="font-medium">{i18n.t("settings:teams.team_notifications")}</h4>

          <p className="break-words text-sm text-gray-600">
            {i18n.t("settings:teams.team_notifications_description")}
          </p>

          <div className="flex flex-col gap-y-2 text-sm">
            {[
              {
                value: INotificationTypeEnum.WorkOrderAssignedTeam,
                label: i18n.t("settings:teams.notifications.work_order_assigned"),
              },
              {
                value: INotificationTypeEnum.WorkOrderCommented,
                label: i18n.t("settings:teams.notifications.work_order_commented"),
              },
              {
                value: INotificationTypeEnum.WorkOrderDueDateReminder,
                label: i18n.t("settings:teams.notifications.work_order_due_date_reminder"),
              },
              {
                value: INotificationTypeEnum.WorkOrderReadyForApproval,
                label: i18n.t("settings:teams.notifications.work_order_ready_for_approval"),
              },
              {
                value: INotificationTypeEnum.WorkOrderDone,
                label: i18n.t("settings:teams.notifications.work_order_done"),
              },
            ].map(({ value, label }) => {
              return (
                <label key={value} className="flex items-center">
                  <CheckboxInput
                    checked={
                      !!(team.notification_settings as TeamNotificationSettings)?.[value]
                    }
                    onChange={async (e) => {
                      await client
                        .mutation<
                          IUpdateTeamNotificationSettingsMutation,
                          IUpdateTeamNotificationSettingsMutationVariables
                        >(
                          UpdateTeamNotificationSettingsDocument,
                          {
                            teamId: team.id,
                            settings: { [value]: e.target.checked },
                          },
                          accountEditScope.context()
                        )
                        .toPromise()
                    }}
                  />
                  <span className="ml-2 text-gray-700">{label}</span>
                </label>
              )
            })}
          </div>
        </div>
        <div className="mb-6 space-y-4">
          <h4 className="font-medium">
            {i18n.t("settings:teams.change_team_visibility_title")}
          </h4>

          <p className="break-words text-sm text-gray-600">
            {i18n.t("settings:teams.change_team_visibility_description")}
          </p>

          <div className="flex flex-col gap-y-2 md:flex-row md:gap-x-2">
            <Button
              type="secondary"
              isLoading={loading.privacy}
              disabled={!(isTeamOwner || isAdmin)}
              onClick={() => onChangePrivacy(!team.private)}
              icon={team.private ? Icons.View : Icons.Hide}>
              {team.private
                ? i18n.t("teams:actions.make_public")
                : i18n.t("teams:actions.make_private")}
            </Button>
          </div>
        </div>

        <hr />
        <h4 className="mb-2 font-medium">{i18n.t("settings:teams.delete_team_title")}</h4>
        <p className="mb-2 text-sm text-gray-500">
          {i18n.t("settings:teams.delete_team_description")}
        </p>
        <div className="pb-6">
          <Button
            color="red"
            icon={TrashSimple}
            onClick={onDelete}
            disabled={!(isTeamOwner || isAdmin)}>
            {i18n.t("settings:teams.delete_team_button")}
          </Button>
        </div>
      </SettingsPageLayout>
    </>
  )
}

export default SettingsTeam
