import { Button } from "@components/shared"
import { alertDialog } from "@components/shared/alert-dialog-provider"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@components/shared/dropdown"
import { CheckboxInput } from "@components/shared/form/checkbox-input"
import toast from "@components/shared/toast"
import { useAnalytics } from "@contexts/analytics-context"
import { useFeature } from "@contexts/feature-flag-context"
import {
  BlockElementAssetState,
  BlockElementConfigBase,
  BlockElementConsumables,
  BlockElementMeterReading,
  BlockElementPayload,
  IBlockElementTypeEnum,
  IBlockGroupTypeEnum,
  IPermissionScopeEnum,
  uuid,
} from "@elara/db"
import {
  elementSchema,
  initialBlockElementAssetState,
  initialBlockElementCheckbox,
  initialBlockElementChoice,
  initialBlockElementConsumables,
  initialBlockElementHeading,
  initialBlockElementInspection,
  initialBlockElementMedia,
  initialBlockElementMeterReading,
  initialBlockElementParagraph,
  initialBlockElementText,
  initialBlockElementToleranceCheck,
  initialBlockElementYesNo,
} from "@elara/utils"
import { useInsertBlockGroupMutation } from "@graphql/documents/block.generated"
import { useInsertWorkOrderAssetsMutation } from "@graphql/documents/work-order.generated"
import { DragDropContext, Draggable, Droppable, DropResult } from "@hello-pangea/dnd"
import { useCallbackRef, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import {
  Article,
  CheckCircle,
  CheckSquare,
  ClockCounterClockwise,
  Code,
  Copy,
  DotsSixVertical,
  DotsThreeVertical,
  Gauge,
  ImageSquare,
  MagnifyingGlass,
  Nut,
  Paragraph,
  Plus,
  PlusMinus,
  Scales,
  TextH,
  TrashSimple,
} from "@phosphor-icons/react"
import { DropdownMenuContentProps } from "@radix-ui/react-dropdown-menu"
import { cn, objectKeysToIndexString } from "@utils"
import { validateYupSchema, yupToFormErrors } from "formik"
import { nanoid } from "nanoid"
import React, { ReactNode, useImperativeHandle } from "react"
import {
  ArrayPath,
  Control,
  FieldValues,
  FormProvider,
  useFieldArray,
  useForm,
  useFormState,
} from "react-hook-form"
import { useWatch } from "react-hook-form"
import { v4 } from "uuid"

import { BlockElementForm } from "./block-element-form"
import { BlockElementFormItem } from "./elements/block-element-types"
import { mapElementTypeToInitialData, mapElementTypeToSchema } from "./initial-data"
import { formatShortcodeToObject } from "./shortcode/from-shortcode"
import { formatObjectToShortcode } from "./shortcode/to-shortcode"

const SelectStaticBlockElementDropdownMenuItems = <T extends FieldValues>(props: {
  name: ArrayPath<T>
  control: Control
  onSelect: (e: BlockElementFormItem) => void
}) => {
  const addShortcodeToBlock = (shortcode: string) => {
    try {
      const object = formatShortcodeToObject(shortcode) as Pick<
        BlockElementFormItem,
        "element_type" | "config"
      >

      if (typeof object !== "object" || !object.element_type || !object.config)
        throw Error()

      // Validate that the shortcode is valid
      if (!Object.values(IBlockElementTypeEnum).includes(object.element_type)) throw Error()

      const schema = mapElementTypeToSchema(object.element_type)

      const result = {
        id: v4(),
        ...mapElementTypeToInitialData(object.element_type, object.config),
      }

      schema?.validateSync(result)

      props.onSelect(result)
    } catch (error) {
      toast.error("Invalid shortcode")
    }
  }
  const { fields } = useFieldArray({ name: props.name, control: props.control })

  return (
    <>
      {[
        {
          label: i18n.t("tasks:fields.description"),
          icon: <Paragraph size={14} />,
          initial: initialBlockElementParagraph(),
        },
        {
          label: i18n.t("tasks:fields.headline"),
          icon: <TextH size={14} />,
          initial: initialBlockElementHeading(),
        },
      ].map(({ label, icon, initial }) => {
        return (
          <DropdownMenuItem
            key={label}
            textValue={label}
            onSelect={() => props.onSelect({ id: v4(), ...initial })}>
            {icon}
            <span className="ml-2">{label}</span>
          </DropdownMenuItem>
        )
      })}
      <DropdownMenuItem
        textValue={i18n.t("tasks:checklist.shortcodes.field")}
        onClick={async () => {
          const textareaId = nanoid(6)

          // Show Alert Dialog instead of Window Prompt
          await alertDialog({
            title: i18n.t("tasks:checklist.shortcodes.messages.description"),
            description: (
              <div className="space-y-1.5">
                <textarea
                  rows={4}
                  id={textareaId}
                  className="w-full overflow-x-scroll whitespace-pre rounded border p-3 font-mono !text-xs"
                />
                <p className="text-xs">
                  {i18n.t("tasks:checklist.shortcodes.messages.helper_text")}
                </p>
              </div>
            ),
            cancelText: i18n.t("common:cancel"),
            actionText: i18n.t("common:save"),
            onAction: () => {
              const formHasElements = fields.length > 0
              const promptValue = (
                document.getElementById(textareaId) as HTMLTextAreaElement
              )?.value
              const shortcodes = formHasElements
                ? promptValue.split("\n").reverse()
                : promptValue.split("\n")
              shortcodes.forEach(addShortcodeToBlock)
            },
          })
        }}>
        <Code />
        <span className="ml-2">{i18n.t("tasks:checklist.shortcodes.field")}</span>
      </DropdownMenuItem>
    </>
  )
}

const SelectInteractiveBlockElementDropdownMenuItems = (props: {
  disableMeterReading?: boolean
  isInfoSection?: boolean
  onSelect: (e: BlockElementFormItem) => void
}) => {
  const hasMaterialsFeature = useFeature("materials")
  const hasMetersFeature = useFeature("meters")

  return (
    <>
      {[
        {
          label: i18n.t("select"),
          icon: <CheckCircle size={14} />,
          initial: initialBlockElementChoice(),
        },
        {
          label: i18n.t("tasks:checklist.checkbox.title"),
          icon: <CheckSquare size={14} />,
          initial: initialBlockElementCheckbox(),
        },
        {
          label: i18n.t("tasks:checklist.inspection.title"),
          icon: <MagnifyingGlass size={14} />,
          initial: initialBlockElementInspection(),
        },
        {
          label: i18n.t("tasks:checklist.yes_no.title"),
          icon: <PlusMinus size={14} />,
          initial: initialBlockElementYesNo(),
        },
        {
          label: i18n.t("tasks:checklist.media.title"),
          icon: <ImageSquare size={14} />,
          initial: initialBlockElementMedia(),
        },
        {
          label: i18n.t("tasks:checklist.meter_reading.title"),
          icon: <Gauge size={14} />,
          disabled: props.disableMeterReading,
          initial: initialBlockElementMeterReading(),
        },
        {
          label: i18n.t("tasks:checklist.text_field.title"),
          icon: <Article size={14} />,
          initial: initialBlockElementText(),
        },
        {
          label: i18n.t("tasks:checklist.consumables.title"),
          icon: <Nut size={14} />,
          initial: initialBlockElementConsumables(),
        },
        {
          label: i18n.t("tasks:checklist.state_change.title"),
          icon: <ClockCounterClockwise size={14} />,
          initial: initialBlockElementAssetState(),
        },
        {
          label: i18n.t("tasks:checklist.tolerance_check.title"),
          icon: <Scales size={14} />,
          initial: initialBlockElementToleranceCheck(),
        },
      ]
        .sort((a, b) => a.label.localeCompare(b.label))
        .filter((x) => {
          if (props.isInfoSection) {
            switch (x.label) {
              case i18n.t("tasks:checklist.meter_reading.title"):
              case i18n.t("tasks:checklist.inspection.title"):
              case i18n.t("tasks:checklist.yes_no.title"):
              case i18n.t("tasks:checklist.state_change.title"):
              case i18n.t("tasks:checklist.consumables.title"):
              case i18n.t("tasks:checklist.tolerance_check.title"):
                return false
              default:
                return true
            }
          }
          if (
            !hasMaterialsFeature &&
            x.label === i18n.t("tasks:checklist.consumables.title")
          ) {
            return false
          }
          if (
            !hasMetersFeature &&
            x.label === i18n.t("tasks:checklist.meter_reading.title")
          ) {
            return false
          }

          return true
        })
        .map(({ label, icon, initial }) => {
          return (
            <DropdownMenuItem
              key={label}
              textValue={label}
              onSelect={() => props.onSelect({ id: v4(), ...initial })}>
              <span className="shrink-0">{icon}</span>
              <span className="ml-2">{label}</span>
            </DropdownMenuItem>
          )
        })}
    </>
  )
}

const GroupElement = React.forwardRef<
  HTMLDivElement,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    element: BlockElementFormItem
    index: number
    field: string
    isDragging: boolean
    onDelete?: () => void
    setRequired?: () => void
    dragHandleProps?: React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    > | null
    control: Control
    isTemplate: boolean
    selectedAssetIds: string[]
  }
>(
  (
    {
      index,
      field,
      control,
      element,
      onDelete,
      setRequired,
      dragHandleProps,
      isDragging,
      isTemplate,
      selectedAssetIds,
      ...props
    },
    forwardedRef
  ) => {
    const formState = useFormState({ control: control })

    const { error } = control.getFieldState(`${field}.${index}`, formState)

    const onCopyCode = () => {
      const object = { element_type: element.element_type, config: element.config }
      const code = formatObjectToShortcode(object)
      navigator.clipboard.writeText(code)

      toast.success(i18n.t("tasks:checklist.shortcodes.messages.copy_single_success"))
    }

    return (
      <div
        {...props}
        ref={forwardedRef}
        className={cn(
          "relative flex items-center bg-white rounded-md border pr-3",
          {
            "bg-gray-100": isDragging,
            "bg-red-50 shadow ring-2 ring-red-600": !!error,
          },
          props.className
        )}>
        <div className="absolute left-0 top-1 w-6 text-center text-xs font-medium text-gray-400">
          {index + 1}.
        </div>

        <div
          {...dragHandleProps}
          className={cn(
            "w-6 mr-2  box-border flex items-center justify-center rounded rounded-r-none text-gray-400 hover:bg-gray-100 hover:text-gray-700 self-stretch border-r",
            dragHandleProps?.className
          )}>
          <DotsSixVertical />
        </div>
        <div className="flex min-w-0 flex-1 flex-col py-3">
          {(element.config as BlockElementConfigBase).required && (
            <div className="mb-2 flex justify-end text-xs text-gray-600">
              {i18n.t("common:forms.required")}
            </div>
          )}
          <BlockElementForm
            index={index}
            key={element.id}
            element={element}
            field={field}
            control={control}
            isTemplate={isTemplate}
            selectedAssetIds={selectedAssetIds}
          />
        </div>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              type="tertiary"
              color="gray"
              icon={DotsThreeVertical}
              className="my-3 ml-2 self-start"
            />
          </DropdownMenuTrigger>

          <DropdownMenuPortal>
            <DropdownMenuContent
              align="start"
              side="top"
              className="rounded bg-white p-1 drop-shadow">
              {element.element_type !== IBlockElementTypeEnum.Paragraph &&
                element.element_type !== IBlockElementTypeEnum.Heading && (
                  <DropdownMenuItem onClick={setRequired}>
                    <CheckboxInput
                      checked={(element.config as BlockElementConfigBase).required}
                      onChange={setRequired}
                      className="mr-2"
                    />{" "}
                    {i18n.t("common:forms.required")}
                  </DropdownMenuItem>
                )}
              <DropdownMenuItem onClick={onCopyCode}>
                <Copy className="mr-2" />
                {i18n.t("tasks:checklist.shortcodes.copy_code_action")}
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              {onDelete && (
                <DropdownMenuItem onClick={onDelete}>
                  <TrashSimple className="mr-2.5" /> {i18n.t("common:delete")}
                </DropdownMenuItem>
              )}
            </DropdownMenuContent>
          </DropdownMenuPortal>
        </DropdownMenu>
      </div>
    )
  }
)

export const AddStepButton = <T extends FieldValues>(
  props: React.PropsWithChildren<{
    control: Control
    name: ArrayPath<T>
    onSelect: (e: BlockElementFormItem) => void
    isInfoSection: boolean
  }> &
    Pick<DropdownMenuContentProps, "side" | "align" | "alignOffset" | "sideOffset">
) => {
  const assetIds = useWatch({ name: "asset_ids", control: props.control })

  const onSelect = (element: BlockElementFormItem) => {
    if (element.element_type === IBlockElementTypeEnum.AssetState) {
      const e: BlockElementFormItem<BlockElementAssetState> = {
        ...element,
        config: { ...element.config, asset_id: assetIds?.[0] ?? null },
      }
      props.onSelect(e as any)
    } else if (element.element_type === IBlockElementTypeEnum.Consumables) {
      const e: BlockElementFormItem<BlockElementConsumables> = {
        ...element,
        config: { ...element.config, asset_id: assetIds?.[0] ?? null },
      }
      props.onSelect(e as any)
    } else if (element.element_type === IBlockElementTypeEnum.MeterReading) {
      const e: BlockElementFormItem<BlockElementMeterReading> = {
        ...element,
        config: { ...element.config, asset_id: assetIds?.[0] ?? null },
      }
      props.onSelect(e as any)
    } else {
      props.onSelect(element)
    }
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{props.children}</DropdownMenuTrigger>

      <DropdownMenuContent
        className="w-48 rounded bg-white py-1 text-sm text-gray-700 drop-shadow"
        side={props.side ?? "top"}
        sideOffset={props.sideOffset ?? 8}
        align={props.align ?? "start"}
        alignOffset={props.alignOffset}>
        <DropdownMenuLabel>{i18n.t("static")}</DropdownMenuLabel>
        <SelectStaticBlockElementDropdownMenuItems
          onSelect={onSelect}
          control={props.control}
          name={props.name}
        />
        <DropdownMenuSeparator />

        <DropdownMenuLabel>{i18n.t("interactive")}</DropdownMenuLabel>
        <SelectInteractiveBlockElementDropdownMenuItems
          onSelect={onSelect}
          isInfoSection={props.isInfoSection}
          disableMeterReading={!assetIds.length}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export type BlockGroupFormValues = {
  asset_ids: uuid[]
  elements: BlockElementFormItem[]
  template_name?: string | null
}

export const BlockGroupElementsFormItem = <T extends FieldValues>(
  props: React.PropsWithChildren<{
    control: Control<T>
    isInfoSection?: boolean
    name: ArrayPath<T>
    placeholderText?: string
    hidePlaceholderText?: boolean
    placeholderButton?: ReactNode
    isTemplate: boolean
    selectedAssetIds: string[]
  }>
) => {
  const { name } = props

  const {
    fields,
    append: push,
    move,
    remove,
    update,
    insert,
  } = useFieldArray({ name, control: props.control })

  const onDragEnd = (result: DropResult) => {
    const startIndex = result.source.index
    const targetIndex = result.destination?.index
    if (result.reason === "DROP" && targetIndex !== undefined) {
      move(startIndex, targetIndex)
    }
  }

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="block">
          {(droppableProvided) => (
            <div
              {...droppableProvided.droppableProps}
              className="flex flex-col gap-1"
              ref={droppableProvided.innerRef}>
              {fields.map((e, index) => {
                const element = e as BlockElementFormItem

                return (
                  <Draggable draggableId={element.id} index={index} key={element.id}>
                    {(provided, snapshot) => {
                      return (
                        <div key={element.id} {...provided.draggableProps}>
                          <GroupElement
                            index={index}
                            element={element}
                            isDragging={snapshot.isDragging}
                            onDelete={() => remove(index)}
                            control={props.control as any}
                            isTemplate={props.isTemplate}
                            selectedAssetIds={props.selectedAssetIds}
                            setRequired={() =>
                              update(index, {
                                ...element,
                                config: {
                                  ...element.config,
                                  required: (element.config as BlockElementConfigBase)
                                    .required
                                    ? false
                                    : true,
                                },
                              } as any)
                            }
                            field={name}
                            ref={provided.innerRef}
                            dragHandleProps={{
                              ...provided.dragHandleProps,
                            }}
                          />
                          {!snapshot.isDragging && (
                            <div className="relative flex h-12 w-full items-center justify-center">
                              <div
                                className={cn(
                                  "absolute inset-y-0 left-1/2 -ml-px border border-dashed",
                                  { "h-6": index === fields.length - 1 }
                                )}
                              />
                              <AddStepButton
                                side="right"
                                control={props.control as any}
                                name={props.name}
                                isInfoSection={!!props.isInfoSection}
                                onSelect={(element) => {
                                  insert(index + 1, element as any)
                                }}>
                                <Button
                                  type={"secondary"}
                                  icon={Plus}
                                  size="extra-small"
                                  className="rounded-lg radix-state-open:bg-gray-100"
                                />
                              </AddStepButton>
                            </div>
                          )}
                        </div>
                      )
                    }}
                  </Draggable>
                )
              })}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {fields.length === 0 ? (
        <div
          className={cn(
            !props.placeholderButton &&
              "flex items-center justify-center rounded-lg border border-dashed  bg-white p-6"
          )}>
          <AddStepButton
            control={props.control as any}
            name={props.name}
            onSelect={(element) => {
              push(element as any)
            }}
            side={"bottom"}
            align="center"
            isInfoSection={!!props.isInfoSection}>
            {props.placeholderButton || (
              <Button
                type={"primary"}
                icon={Plus}
                size="small"
                className="rounded-lg radix-state-open:bg-blue-800">
                {i18n.t("tasks:checklist.add_first_step")}
              </Button>
            )}
          </AddStepButton>
        </div>
      ) : (
        <div className="mb-1" />
      )}
    </div>
  )
}

// We ideally just want to use
// yup.object().shape({ elements: yup.array(yup.mixed().oneOf([...elementSchemas])) })
// but there is a bug in yup where oneOf compares using referential identity which does not work for objects...
// Ref: https://github.com/jquense/yup/issues/1393
export async function validateBlockElements(
  elements: BlockElementFormItem<BlockElementPayload>[],
  options: { isTemplate: boolean }
) {
  const elementErrors: Record<number, any> = {}

  for (let [index, element] of elements.entries()) {
    try {
      await validateYupSchema(
        element,
        elementSchema(element.element_type, { isTemplate: options.isTemplate }),
        true
      )
    } catch (err) {
      elementErrors[index] = yupToFormErrors(err)
    }
  }

  return elementErrors
}

export type BlockGroupFormHandle = {
  submit: () => Promise<boolean>
  isDirty: boolean
}

export type BlockGroupFormProps = {
  groupId?: uuid
  assignedAssetIds?: uuid[]
  workOrderId?: uuid
  initialElements: BlockElementFormItem[]
  groupType?: IBlockGroupTypeEnum
  placeholder?: string
  onSubmit?: () => void
  setRequired?: () => void
  isTemplate?: boolean
  hideSubmitButton?: boolean
}

export const BlockGroupForm = React.forwardRef<BlockGroupFormHandle, BlockGroupFormProps>(
  (props, forwardedRef) => {
    const { posthog } = useAnalytics()

    const [, insertBlockGroup] = useInsertBlockGroupMutation()
    const [, insertAssociatedAssets] = useInsertWorkOrderAssetsMutation()
    const scope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)

    const defaultValues = {
      asset_ids: props.assignedAssetIds ?? [],
      elements: props.initialElements,
    }

    const form = useForm({ defaultValues })
    const { control, formState, getValues, handleSubmit } = form
    const elements = getValues("elements")

    const onSubmit = useCallbackRef(async (values: typeof defaultValues) => {
      try {
        const procedueValidation = await validateBlockElements(values.elements, {
          isTemplate: !!props.isTemplate,
        })
        if (Object.keys(procedueValidation).length > 0) {
          toast.error(i18n.t("tasks:messages.form_validation_error"))
          objectKeysToIndexString(procedueValidation).forEach(({ key, value }) => {
            form.setError(`elements.${key}` as any, { message: value })
          })
          return false
        }

        const { elements } = values
        const groupId = props.groupId ?? v4()
        const res = await insertBlockGroup(
          {
            elements: elements.map((e, position) => ({
              id: e.id,
              config: e.config,
              element_type: e.element_type,
              config_upload_ids: e.config_uploads?.map((u) => u.id),
              position,
            })),
            elementIdsToKeep: elements.map((e) => e.id),
            workOrderId: props.workOrderId,
            groupId,
            groupType: props.groupType,
          },
          scope.context()
        )
        if (!res.data?.insert_block_group_one) {
          throw Error("Block update failed")
        } else {
          posthog?.capture("edit_work_order")
          props.onSubmit?.()
        }

        const assetsIdsFromBlocks = values.elements
          .filter(
            (block) =>
              block.element_type === IBlockElementTypeEnum.MeterReading &&
              !values.asset_ids.some((asset_id) => asset_id === block.config.asset_id)
          )
          .map((block) => {
            const meterReadingBlock = block as BlockElementMeterReading
            return meterReadingBlock.config.asset_id
          })
          .filter(Boolean)

        if (assetsIdsFromBlocks.length && props.workOrderId) {
          const res = await insertAssociatedAssets(
            {
              data: assetsIdsFromBlocks.map((id) => ({
                asset_id: id,
                work_order_id: props.workOrderId,
              })),
            },
            scope.context()
          )

          if (!res.data?.insert_asset_x_work_order)
            throw Error("WO assets_ids update failed")
        }

        toast.success(i18n.t("generic_toast_success"))
        return true
      } catch (err) {
        toast.error(i18n.t("generic_toast_error"))
        return false
      }
    })

    const submit = useCallbackRef(() => onSubmit(getValues()))

    useImperativeHandle(forwardedRef, () => ({ submit, isDirty: formState.isDirty }), [
      formState.isDirty,
    ])

    return (
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)} name="block-group" id="block-group">
          <BlockGroupElementsFormItem
            isTemplate={!!props.isTemplate}
            control={control}
            name="elements"
            selectedAssetIds={form.watch("asset_ids")}
            placeholderText={props.placeholder}
            isInfoSection={props.groupType === IBlockGroupTypeEnum.Info}
          />
          {!props.hideSubmitButton && (
            <div className={cn("flex justify-end", elements.length === 0 && "mt-4")}>
              <Button htmlType="submit" size="small">
                {i18n.t("common:save")}
              </Button>
            </div>
          )}
        </form>
      </FormProvider>
    )
  }
)

export default BlockGroupForm
