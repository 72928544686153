import Icons from "@resources/icons"
import classNames from "classnames"
import { format, parseISO } from "date-fns"
import { FieldMetaProps } from "formik"
import React from "react"

type DateTimeProps = {
  clearable?: boolean
  value?: Date | null
  hasError?: boolean
  onChange?: (datetime: Date | null) => void
  onClear?: () => void
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "value" | "onChange"> &
  Partial<FieldMetaProps<any>>

type GetClassesArgs = {
  className: string | undefined
  value: string | Date | null | undefined
  hasError: boolean
}

const getClasses = ({ className, value, hasError }: GetClassesArgs) =>
  classNames(
    className,
    "form-input rounded border-gray-200 h-8 pt-0 pb-0 text-sm flex-1 !px-2",
    {
      "text-gray-800": value,
      "text-gray-500": !value,
      "!border-red-700 text-red-700 focus:!ring-1 focus:!ring-red-700 focus:!border-red-700":
        hasError,
    }
  )

const PickerBase = React.forwardRef<
  HTMLInputElement,
  DateTimeProps & { dateTimeFormat: string; inputType: string; hasError?: boolean }
>(
  (
    {
      clearable,
      className,
      value,
      onChange,
      onClear,
      inputType,
      dateTimeFormat,
      hasError,
      error,
      ...props
    },
    forwardedRef
  ) => {
    const val = value ? format(value, dateTimeFormat) : ""

    return (
      <div className="relative flex">
        <input
          {...props}
          ref={forwardedRef}
          type={inputType}
          value={val}
          className={getClasses({
            className,
            value,
            hasError: hasError || !!error,
          })}
          onChange={(e) => {
            const datetime = parseISO(e.target.value)
            if (!isNaN(datetime.getTime())) {
              onChange?.(datetime)
            } else if (clearable) {
              onChange?.(null)
            }
          }}
        />
        {value && clearable && (
          <Icons.Close
            width={16}
            height={16}
            className={classNames(
              "date-picker-clear-button absolute top-[8px] right-[42px] text-white rounded",
              {
                "bg-blue-deep": !hasError || !error,
                "bg-red-700": hasError || !!error,
              }
            )}
            style={{
              borderRadius: "100%",
            }}
            onClick={() => {
              onChange?.(null)
              onClear?.()
            }}
          />
        )}
      </div>
    )
  }
)

export const DateNative = React.forwardRef<HTMLInputElement, DateTimeProps>(
  (props, forwardedRef) => (
    <PickerBase
      {...props}
      ref={forwardedRef}
      inputType="date"
      dateTimeFormat="yyyy-MM-dd"
    />
  )
)

type TimeProps = Omit<DateTimeProps, "onChange"> & {
  onChange?: (time: string | null) => void
}

export const TimeNative = React.forwardRef<HTMLInputElement, TimeProps>(
  (
    { clearable, value, className, onChange, onClear, hasError, error, ...props },
    forwardedRef
  ) => {
    const val = value === null ? "" : value
    return (
      <div className="relative flex">
        <input
          {...props}
          ref={forwardedRef}
          type="time"
          value={val}
          className={getClasses({
            className,
            value,
            hasError: hasError || !!error,
          })}
          onChange={(e) => {
            if (e.target.value) {
              onChange?.(e.target.value)
            }
          }}
        />
        {value && clearable && (
          <Icons.Close
            width={16}
            height={16}
            className={classNames(
              "date-picker-clear-button absolute top-[8px] right-[42px] text-white rounded",
              {
                "bg-blue-deep": !hasError || !error,
                "bg-red-700": hasError || !!error,
              }
            )}
            style={{
              borderRadius: "100%",
            }}
            onClick={() => {
              onChange?.(null)
              onClear?.()
            }}
          />
        )}
      </div>
    )
  }
)

export const DateTimeNative = React.forwardRef<HTMLInputElement, DateTimeProps>(
  (props, forwardedRef) => (
    <PickerBase
      {...props}
      ref={forwardedRef}
      inputType="datetime-local"
      dateTimeFormat="yyyy-MM-dd'T'HH:mm"
    />
  )
)
