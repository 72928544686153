import { useAssetSelect } from "@components/asset"
import { AssetStateDetailDialog } from "@components/asset/asset-state-detail"
import {
  AssetStateFormContent,
  changeAssetStatusSchema,
} from "@components/asset/asset-state-form-dialog"
import AssetStateVariantLabel from "@components/asset/asset-state-variant-label"
import { AssetStateVariantSelect } from "@components/asset/asset-state-variant-select"
import { VStack } from "@components/layout"
import { Button } from "@components/shared"
import { FormFieldController } from "@components/shared/form/form-field-controller"
import { SelectPopover } from "@components/shared/single-select"
import toast from "@components/shared/toast"
import Toggle from "@components/shared/toggle"
import { BlockElementAssetState, IPermissionScopeEnum } from "@elara/db"
import {
  IUpsertAssetStateLogMutation,
  IUpsertAssetStateLogMutationVariables,
  UpsertAssetStateLogDocument,
} from "@graphql/documents/asset-state.generated"
import { BlockElementByIdDocument } from "@graphql/documents/block.generated"
import { IAssetStateLogFragment } from "@graphql/documents/fragments.generated"
import { usePermissionScope } from "@hooks/use-permission-scope"
import i18n from "@i18n"
import { ArrowRight, PencilSimpleLine, X } from "@phosphor-icons/react"
import Icons from "@resources/icons"
import { formatDate, formatDuration } from "@utils/date"
import { differenceInHours, differenceInMinutes } from "date-fns"
import { Form, Formik } from "formik"
import { useEffect, useState } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { useClient } from "urql"

import { BlockElementFormProps, BlockElementProps } from "./block-element-types"

export const AssetStateForm = (
  props: BlockElementFormProps & { addCurrentAsset: boolean }
) => {
  const assetSelectProps = useAssetSelect()
  const { getValues, setValue } = useFormContext()

  const assetId = useWatch({
    name: `${props.field}.${props.index}.config.asset_id`,
    control: props.control,
  })

  useEffect(() => {
    const assetIds = getValues("asset_ids") as string[]

    if (assetIds.length > 0) {
      setValue(`${props.field}.${props.index}.config.asset_id`, assetIds[0])
    }
  }, [])

  return (
    <div>
      <div className="mb-2 text-sm font-medium">
        {i18n.t("tasks:checklist.state_change.title")}
      </div>

      <FormFieldController
        name={`${props.field}.${props.index}.config.asset_id`}
        label={i18n.t("common:asset", { count: 1 })}
        control={props.control}
        hint={
          assetId === null && props.isTemplate
            ? i18n.t("tasks:checklist.state_change.dynamic_hint")
            : undefined
        }
        render={({ field }) => (
          <SelectPopover
            {...assetSelectProps}
            placeholder={props.isTemplate ? i18n.t("common:dynamic") : undefined}
            isClearable={props.isTemplate}
            nullOption={
              props.isTemplate
                ? {
                    label: (
                      <span className="text-gray-500">{i18n.t("common:dynamic")}</span>
                    ),
                    searchValue: i18n.t("common:dynamic"),
                  }
                : undefined
            }
            items={assetSelectProps.items}
            size="small"
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
      <FormFieldController
        name={`${props.field}.${props.index}.config.default_asset_state_variant_id`}
        label={i18n.t("assets:state.fields.variant")}
        control={props.control}
        optional
        render={({ field }) => (
          <AssetStateVariantSelect
            isClearable
            size="small"
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
    </div>
  )
}

export const AssetStateBlockElement = (
  props: BlockElementProps<BlockElementAssetState>
) => {
  const client = useClient()
  const [edit, setEdit] = useState(false)
  const scope = usePermissionScope(IPermissionScopeEnum.AppDataEntry)
  const state = props.element.relationship_data?.asset_state_log
  const workOrderId = props.element.group.work_order?.id

  if (state && !edit) {
    const startedAt = state.started_at ? new Date(state.started_at) : null
    const endedAt = state.ended_at ? new Date(state.ended_at) : null
    const duration = {
      hours: startedAt && endedAt ? differenceInHours(endedAt, startedAt) : 0,
      minutes: startedAt && endedAt ? differenceInMinutes(endedAt, startedAt) % 60 : 0,
    }

    return (
      <div className="@container">
        <div className="flex">
          <AssetStateVariantLabel variant={state.asset_state_variant} />
        </div>
        {state.note && <div className="mt-1 text-sm text-gray-600">{state.note}</div>}
        <div className="mt-1 grid grid-cols-2 gap-2 @xl:grid-cols-[auto_1fr_auto_1fr]">
          {endedAt && (
            <>
              <div className="text-sm font-medium text-gray-600">
                {i18n.t("assets:state.fields.duration")}
              </div>
              <div className="text-sm text-gray-600">{formatDuration(duration)}</div>
            </>
          )}
          {startedAt && (
            <>
              <div className="mb-1 text-sm font-medium text-gray-600">
                {i18n.t("assets:state.fields.started_at")}
              </div>
              <div className="text-sm text-gray-600">{formatDate(startedAt, "Pp")}</div>
            </>
          )}
        </div>
        <div className="flex items-center space-x-3">
          <AssetStateDetailDialog
            state={state as unknown as IAssetStateLogFragment}
            assetId={props.element.config.asset_id}
            onDelete={() =>
              client.query(BlockElementByIdDocument, { id: props.element.id }).toPromise()
            }
            onEdit={() => {
              client.query(BlockElementByIdDocument, { id: props.element.id }).toPromise()
            }}>
            <Button type="secondary" icon={ArrowRight}>
              {i18n.t("common:details")}
            </Button>
          </AssetStateDetailDialog>
          <Button type="secondary" icon={PencilSimpleLine} onClick={() => setEdit(true)}>
            {i18n.t("common:edit")}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <>
      <Formik
        initialValues={{
          id: state?.id,
          note: state?.note ?? "",
          started_at: state?.started_at ? new Date(state?.started_at) : new Date(),
          asset_state_variant_id:
            state?.asset_state_variant_id ??
            props.element.config.default_asset_state_variant_id ??
            null,
          withEndedAt: !!state?.ended_at,
          ended_at: state?.ended_at ? new Date(state?.ended_at) : new Date(),
          work_order_id: workOrderId ?? null,
        }}
        validationSchema={changeAssetStatusSchema(true)}
        onSubmit={async (values) => {
          const res = await client
            .mutation<IUpsertAssetStateLogMutation, IUpsertAssetStateLogMutationVariables>(
              UpsertAssetStateLogDocument,
              {
                data: {
                  id: values.id ?? undefined,
                  note: values.note || null,
                  asset_id: props.element.config.asset_id,
                  asset_state_variant_id: values.asset_state_variant_id,
                  started_at: values.started_at.toISOString(),
                  ended_at:
                    values.ended_at && values.withEndedAt
                      ? values.ended_at.toISOString()
                      : null,
                  work_order_id: workOrderId,
                },
              },
              scope.context()
            )
            .toPromise()

          if (!res.data?.insert_asset_state_log_one) {
            toast.error(i18n.t("common:generic_toast_error"))
            return
          }

          await props.updateResponse(props.element.id, {
            asset_state_log_id: res.data?.insert_asset_state_log_one?.id,
          })

          setEdit(false)
        }}>
        {(formik) => (
          <Form>
            <VStack space={8} className="flex-1">
              <div className="flex items-center gap-2">
                <span className="text-sm font-medium">
                  {i18n.t("tasks:checklist.state_change.title")}
                </span>
              </div>
              <AssetStateFormContent
                formik={formik}
                withEndedAt={formik.values.withEndedAt}
                assetId={props.element.config.asset_id}
                workOrderId={workOrderId}
              />

              <div className="mt-1 flex flex-wrap items-center justify-between gap-y-2">
                <label className="cursor-pointer justify-self-start text-gray-600">
                  <Toggle
                    checked={formik.values.withEndedAt}
                    onChange={(e) => formik.setFieldValue("withEndedAt", e.target.checked)}
                  />
                  <span className="ml-2 text-sm text-gray-600">
                    {i18n.t("tasks:checklist.state_change.enter_downtime")}
                  </span>
                </label>

                <Button
                  icon={Icons.History}
                  htmlType="submit"
                  size="small"
                  type="secondary">
                  {i18n.t("common:change_token", { token: i18n.t("assets:fields.state") })}
                </Button>
              </div>
              {/* {state?.created_at && (
              <div className="flex-1 basis-full justify-self-start text-sm text-gray-600 sm:basis-auto">
                {i18n.t("assets:fields.state_change.created_at")}{" "}
                {formatDate(new Date(state.created_at), "Pp")}
              </div>
            )} */}
            </VStack>
          </Form>
        )}
      </Formik>
      {state && (
        <Button icon={X} type="secondary" className="mt-2" onClick={() => setEdit(false)}>
          {i18n.t("common:discard_changes")}
        </Button>
      )}
    </>
  )
}
