import { useBreakpoint } from "@contexts/breakpoints"
import { cn } from "@utils"
import React, { InputHTMLAttributes } from "react"

export type TextInputProps = { hasError?: boolean; size?: "small" | "large" } & Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "size"
>

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  ({ size: givenSize, hasError, ...props }, forwardedRef) => {
    const bp = useBreakpoint()

    const size = givenSize ?? (bp.sm ? "small" : "large")

    return (
      <input
        type="text"
        autoComplete="nofill"
        {...props}
        ref={forwardedRef}
        className={cn(
          "border focus:ring-1 rounded px-2 align-middle",
          {
            "text-base h-9": size === "large",
            "text-sm h-8": size === "small",
            "border-gray-200 focus:ring-blue-700 focus:border-blue-700 placeholder:text-gray-500 ":
              !hasError,
            "border-red-700 focus:ring-red-700 focus:border-red-700 placeholder:text-red-500":
              hasError,
          },
          props.className
        )}
      />
    )
  }
)
