import { Button, LoadingIndicator } from "@components/shared"
import { WorkOrderCompact } from "@components/work-order/work-order-compact"
import { IWorkOrderDataViewFragment } from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { CaretLeft, CaretRight } from "@phosphor-icons/react"
import { cn } from "@utils"
import { useNavigateWithBackgroundLocation } from "@utils/location"
import React, { ReactNode, useState } from "react"
import { Trans } from "react-i18next"

export const TasksBox = (props: {
  data: IWorkOrderDataViewFragment[]
  dateLabel: string
  dateElement: (t: IWorkOrderDataViewFragment) => React.ReactNode
  title?: (count: number) => string
  emptyPlaceholder: ReactNode
  isFetching: boolean
  className?: string
}) => {
  const pageSize = 5

  const [page, setPage] = useState(1)
  const navigate = useNavigateWithBackgroundLocation()

  const nPages = Math.ceil(props.data.length / pageSize)

  return (
    <div
      className={cn(
        "rounded-lg border border-gray-100 bg-white p-3 text-sm sm:p-5",
        props.className
      )}>
      {props.title && (
        <h3 className="mb-2 text-base font-medium text-gray-900">
          {props.title(props.data.length)}
        </h3>
      )}
      {props.data.length > 0 ? (
        <div>
          <div className="flex">
            <div className="mb-1 flex-1 font-medium text-gray-500">
              {i18n.t("task", { count: 1 })}
            </div>
            <div className="mb-1 ml-3 hidden w-40 font-medium text-gray-500 sm:block">
              {props.dateLabel}
            </div>
          </div>
          <div className="mb-0.5 flex flex-col">
            {props.data.slice((page - 1) * pageSize, page * pageSize).map((t) => {
              return (
                <React.Fragment key={t.id}>
                  <div
                    key={t.id}
                    className="flex min-h-[2.75rem] flex-wrap-reverse items-center rounded-md py-1 pl-2 hover:bg-gray-100 sm:flex-nowrap"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      navigate("/task/" + t.id)
                    }}>
                    <div className="mr-2 w-full flex-1 hover:bg-gray-100 sm:min-w-0">
                      <WorkOrderCompact
                        key={t.id}
                        workOrder={t}
                        className="cursor-pointer pb-1 sm:py-1"
                      />
                    </div>
                    <div className="flex w-40 items-center hover:bg-gray-100">
                      {props.dateElement(t)}
                    </div>
                  </div>
                  <hr className="my-0.5 border border-gray-100 last:hidden" />
                </React.Fragment>
              )
            })}
          </div>
          {nPages > 1 && (
            <nav
              className="flex flex-col items-start gap-y-1 border-t border-gray-200 pt-3 sm:flex-row sm:items-center sm:justify-between "
              aria-label="Pagination">
              <div className="block">
                <p className="text-sm text-gray-700">
                  <Trans
                    i18nKey={"pagination_result"}
                    i18n={i18n}
                    values={{
                      entity: i18n.t("common:task", { count: 2 }),
                      from: (page - 1) * pageSize + 1,
                      to: Math.min(page * pageSize, props.data.length),
                      total: props.data.length,
                    }}
                    components={{ strong: <span className="font-medium" /> }}
                  />
                </p>
              </div>
              <div className="flex flex-1 justify-between space-x-1 sm:justify-end">
                <Button
                  type="tertiary"
                  color="gray"
                  onClick={() => setPage(page - 1)}
                  disabled={page === 1}>
                  <CaretLeft size={16} weight="bold" className="mr-0.5" />
                  <span>{i18n.t("common:previous")}</span>
                </Button>
                <Button
                  type="tertiary"
                  color="gray"
                  onClick={() => setPage(page + 1)}
                  disabled={page * pageSize + 1 > props.data.length}>
                  {i18n.t("common:next")}
                  <CaretRight size={16} weight="bold" className="ml-0.5" />
                </Button>
              </div>
            </nav>
          )}
        </div>
      ) : props.isFetching ? (
        <div className="my-3 flex w-full justify-center">
          <LoadingIndicator size={24} />
        </div>
      ) : (
        <div className="flex items-center pt-2 text-sm text-gray-500">
          {props.emptyPlaceholder}
        </div>
      )}
    </div>
  )
}

export default TasksBox
