import { WorkOrderCategoryTags } from "@components/work-order/work-order-category-tags"
import { WorkOrderPriorityBadge } from "@components/work-order/work-order-priority"
import { WorkOrderStatusTag } from "@components/work-order/work-order-status"
import { IWorkOrderDataViewFragment } from "@graphql/documents/fragments.generated"
import {
  HoverCard,
  HoverCardContent,
  HoverCardPortal,
  HoverCardTrigger,
} from "@radix-ui/react-hover-card"

import { AssetTag } from "./asset-tag"
import { TeamTag } from "./team-tag"
import { UserTag } from "./user-tag"

type Props = {
  task: IWorkOrderDataViewFragment
  children: React.ReactNode
}

export const TaskHoverCard = ({ task, children }: Props) => {
  return (
    <HoverCard>
      <HoverCardTrigger>{children}</HoverCardTrigger>

      <HoverCardPortal>
        <HoverCardContent className="grid gap-y-2 rounded-lg bg-white p-4 text-sm shadow-xl">
          <div className="flex items-center gap-x-4">
            <WorkOrderPriorityBadge priority={task.priority} />
            <WorkOrderStatusTag status={task.status} />
            <WorkOrderCategoryTags workOrder={task} />
          </div>

          <h2 className="text-base font-bold">{task.name}</h2>

          {task.description && (
            <p
              className="prose prose-sm prose-zinc"
              dangerouslySetInnerHTML={{ __html: task.description }}
            />
          )}

          <div className="flex max-w-lg flex-wrap items-center gap-x-4 gap-y-2">
            {task.assigned_teams.map(({ team }) => (
              <TeamTag key={team.id} team={team} />
            ))}

            {task.assignees.map(({ user }) => (
              <UserTag key={user.id} user={user} />
            ))}

            {task.assets.map(({ asset }) => (
              <AssetTag key={asset.id} asset={asset} />
            ))}
          </div>
        </HoverCardContent>
      </HoverCardPortal>
    </HoverCard>
  )
}
