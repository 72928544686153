import { AssetWithParents } from "@components/asset/asset-with-parents"
import { ConsumableStorageLocation } from "@components/consumable/consumable-storage-location"
import { Button } from "@components/shared"
import { currencySymbol } from "@components/shared/currency"
import RadioGroupPanel from "@components/shared/radio-group-panel"
import { TextInputWithUnit } from "@components/shared/text-input-with-unit"
import { Tooltip } from "@components/shared/tooltip"
import { useUser } from "@contexts/user-context"
import {
  IAssetTagFragment,
  IConsumableCompactFragment,
  IConsumableStorageLocationFragment,
} from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { MapPin, Pencil, Shapes, TrashSimple, X } from "@phosphor-icons/react"
import * as Collapsible from "@radix-ui/react-collapsible"
import { Control, Controller, useWatch } from "react-hook-form"

export const ConsumableFormRowItem = (props: {
  name: string
  // No idea how to make this typesafe
  // But we need to have available at `{name}`
  // {asset_id: string, place_id: string, quantity: number}
  control: Control
  assets: IAssetTagFragment[]
  storageLocations: IConsumableStorageLocationFragment[]
  consumable: IConsumableCompactFragment
  onRemove: () => void
}) => {
  const user = useUser()
  const placeId = useWatch({ control: props.control, name: `${props.name}.place_id` })
  const assetId = useWatch({ control: props.control, name: `${props.name}.asset_id` })

  const nStorageLocations = props.storageLocations.length
  const allowToChangeStorageLocation =
    nStorageLocations > 1 ||
    (nStorageLocations == 1 && props.storageLocations[0]?.place_id !== placeId)

  const place = nStorageLocations > 0 && (
    <Collapsible.Root className="group">
      <div className="flex items-center text-sm group-radix-state-open:invisible">
        <ConsumableStorageLocation
          // Need to overwrite place_id to make it work since c.place_id is stale
          storageLocation={
            props.storageLocations.find((c) => c.place_id === placeId) ?? null
          }
          renderPlaceholder={() => (
            <span className="inline-flex items-center text-gray-500">
              <MapPin size={14} weight="fill" className="mr-1 text-gray-400 " />
              {i18n.t("consumables:labels.assigned_to_no_location")}
            </span>
          )}
        />
        {allowToChangeStorageLocation && (
          <Collapsible.Trigger asChild>
            <Button
              type="tertiary"
              icon={Pencil}
              color="gray"
              size="extra-small"
              className="-my-1 ml-1">
              {/* {i18n.t("common:change")} */}
            </Button>
          </Collapsible.Trigger>
        )}
        <div className="flex-1" />
      </div>

      <Collapsible.Content
        className="-mb-6 mt-2 box-border -translate-y-8 space-y-2 overflow-hidden rounded-lg bg-gray-100 
                p-3 radix-state-closed:animate-radix-slide-up radix-state-open:animate-radix-slide-down">
        <div className="flex items-center justify-between">
          <div className="text-sm text-gray-500">
            {i18n.t("consumables:labels.assigned_to_storage_location")}
          </div>
          <Collapsible.Trigger asChild>
            <Button type="tertiary" icon={X} color="gray" className="ml-1">
              {i18n.t("common:close")}
            </Button>
          </Collapsible.Trigger>
        </div>
        <Controller
          control={props.control}
          name={`${props.name}.place_id` as const}
          render={({ field }) => (
            <RadioGroupPanel
              value={field.value || "no_value"}
              onValueChange={field.onChange}
              options={
                props.storageLocations
                  .map((c) => ({
                    value: c.place.id,
                    label: c.place.name,
                    description: c.area ?? "",
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label)) ?? []
              }
            />
          )}
        />
      </Collapsible.Content>
    </Collapsible.Root>
  )
  const assignedAsset = props.assets.find((a) => a.id === assetId)
  const showAsset = props.assets.length >= 1

  const asset = showAsset && (
    <Collapsible.Root className="group">
      <div className="flex items-center text-sm group-radix-state-open:invisible">
        <Tooltip
          content={i18n.t("consumables:messages.assigned_object_tooltip")}
          contentProps={{ align: "start" }}>
          <Shapes className="mr-1 text-gray-400" />
        </Tooltip>
        <span className="text-gray-700">
          {assignedAsset?.name ?? (
            <span className="text-gray-500">
              {i18n.t("consumables:labels.assigned_to_no_asset")}
            </span>
          )}
        </span>
        <Collapsible.Trigger asChild>
          <Button
            type="tertiary"
            icon={Pencil}
            color="gray"
            size="extra-small"
            className="-my-1 ml-1">
            {/* {i18n.t("common:change")} */}
          </Button>
        </Collapsible.Trigger>
      </div>

      <Collapsible.Content
        className="-mb-6 mt-2 box-border -translate-y-8 space-y-2 overflow-hidden rounded-lg bg-gray-100 
                p-3 radix-state-closed:animate-radix-slide-up radix-state-open:animate-radix-slide-down">
        <div className="flex items-center justify-between">
          <div className="text-sm font-medium text-gray-500">
            {i18n.t("consumables:labels.assigned_to_object")}
          </div>
          <Collapsible.Trigger asChild>
            <Button size="small" color="gray" type="tertiary" className="ml-1" icon={X}>
              {i18n.t("common:close")}
            </Button>
          </Collapsible.Trigger>
        </div>
        <Controller
          control={props.control}
          name={`${props.name}.asset_id` as const}
          render={({ field }) => (
            <RadioGroupPanel
              value={field.value || "no_value"}
              onValueChange={field.onChange}
              options={
                props.assets
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((asset) => ({
                    value: asset.id,
                    label: <AssetWithParents asset={asset} showAvatar />,
                    description: "",
                  })) ?? []
              }
            />
          )}
        />
      </Collapsible.Content>
    </Collapsible.Root>
  )

  return (
    <div className="flex w-full flex-col gap-y-1 py-3 first:pt-0 last:pb-0">
      <div className="flex flex-wrap items-center gap-2 text-sm">
        <div className="grow">
          <span className="break-all font-medium">{props.consumable.name}</span>
          <span className="ml-1 text-gray-500"> {props.consumable.public_id}</span>
        </div>
        <TextInputWithUnit
          unit={props.consumable.unit ?? ""}
          {...props.control.register(`${props.name}.quantity`)}
          type="number"
          step="any"
          className="grow basis-1/3 sm:grow-0 sm:basis-28"
        />
        <TextInputWithUnit
          {...props.control.register(`${props.name}.cost_per_unit`, {
            valueAsNumber: true,
          })}
          unit={`${currencySymbol(user.location.settings.currency)} / ${
            props.consumable.unit
          }`}
          type="number"
          step="0.01"
          className="grow basis-1/3 sm:grow-0 sm:basis-36"
        />
        <Button
          color="gray"
          type="tertiary"
          size="extra-small"
          icon={TrashSimple}
          onClick={props.onRemove}
        />
      </div>
      {place}
      {asset}
    </div>
  )
}
