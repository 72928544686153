import { DataViewTabs } from "@components/shared/data-view/data-view-tabs"
import { IViewDataTypeEnum } from "@elara/db"
import i18n from "@i18n"

const MaintenanceArchivePage = () => (
  <DataViewTabs
    dataType={IViewDataTypeEnum.MaintenanceArchive}
    title={i18n.t("maintenance:tabs.archive")}
  />
)

export default MaintenanceArchivePage
