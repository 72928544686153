import { UserMultiSelect } from "@components/shared"
import { PopoverContentProps } from "@components/shared/popover"
import { IPermissionScopeEnum, uuid } from "@elara/db"
import { IWorkOrderDataViewFragment } from "@graphql/documents/fragments.generated"
import {
  AddAssigneeToWorkOrderDocument,
  DeleteAssigneeFromWorkOrderDocument,
  IAddAssigneeToWorkOrderMutation,
  IAddAssigneeToWorkOrderMutationVariables,
  IDeleteAssigneeFromWorkOrderMutation,
  IDeleteAssigneeFromWorkOrderMutationVariables,
} from "@graphql/documents/work-order.generated"
import { usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { useClient } from "urql"

type WorkOrderQuickAssigneeSelectProps = {
  workOrder: Pick<IWorkOrderDataViewFragment, "id" | "assignees">
  compact?: boolean
  isListView?: boolean
  title?: string
  isOpen?: boolean
  onOpenChange?: (isOpen: boolean) => void
  popoverContentProps?: PopoverContentProps
}

export const WorkOrderQuickAssigneeSelect = (props: WorkOrderQuickAssigneeSelectProps) => {
  const client = useClient()
  const scope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)
  const users = props.workOrder.assignees.map((t) => t.user?.id).filter(Boolean)

  const onChange = async (updatedAssignee: uuid[]) => {
    const addedUserId = updatedAssignee.find((t) => !users.includes(t))
    const deletedUserId = users.find((t) => !updatedAssignee.includes(t))
    if (addedUserId) {
      await client
        .mutation<
          IAddAssigneeToWorkOrderMutation,
          IAddAssigneeToWorkOrderMutationVariables
        >(
          AddAssigneeToWorkOrderDocument,
          { userId: addedUserId, workOrderId: props.workOrder.id },
          scope.context()
        )
        .toPromise()
    } else if (deletedUserId) {
      await client
        .mutation<
          IDeleteAssigneeFromWorkOrderMutation,
          IDeleteAssigneeFromWorkOrderMutationVariables
        >(
          DeleteAssigneeFromWorkOrderDocument,
          {
            userId: deletedUserId,
            workOrderId: props.workOrder.id,
          },
          scope.context()
        )
        .toPromise()
    }
  }

  return (
    <UserMultiSelect
      {...props}
      title={props.title ?? i18n.t("tasks:fields.assignee", { count: 1 })}
      value={users}
      onChange={onChange}
      compact={props.compact ?? false}
      isListView={props.isListView ?? false}
      classForTrigger="!bg-white"
      popoverContentProps={props.popoverContentProps}
    />
  )
}
