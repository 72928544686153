import { IConsumableStorageLocationFragment } from "@graphql/documents/fragments.generated"
import { MapPin } from "@phosphor-icons/react"
import { ReactElement } from "react"

export const ConsumableStorageLocation = (props: {
  storageLocation: IConsumableStorageLocationFragment | null
  renderPlaceholder?: () => ReactElement
  hideIcon?: boolean
}) => {
  const { storageLocation } = props

  if (!storageLocation) return props.renderPlaceholder?.() ?? null

  return (
    <div className="flex items-center">
      {!props.hideIcon && <MapPin size={14} weight="fill" className="mr-1 text-gray-400" />}
      <span className="text-gray-700">{storageLocation.place?.name}</span>
      <span className="ml-1 text-gray-500">{storageLocation.area}</span>
    </div>
  )
}
