import { VStack } from "@components/layout"
import { Button, Icons } from "@components/shared"
import { RichTextFormController } from "@components/shared/form/rich-text-form-controller"
import { RadioGroup, RadioGroupItem, RadioGroupProps } from "@components/shared/radio"
import { StaticRichText } from "@components/shared/static-rich-text"
import { TextArea } from "@components/shared/text-area"
import Uploads from "@components/shared/uploads"
import { BlockElementInspection, InspectionResult } from "@elara/db"
import { useDebouncedPersistingText, useDisclosure } from "@hooks"
import i18n from "@i18n"
import classNames from "classnames"
import { useRef } from "react"

import { useResponseUploads } from "./block-element-hooks"
import { BlockElementFormProps, BlockElementProps } from "./block-element-types"

export const InspectionRadioChoices = (props: RadioGroupProps) => (
  <RadioGroup
    style={{ width: "100%", display: "flex", flexDirection: "column" }}
    {...props}>
    <RadioGroupItem
      value="pass"
      className="focus-:ring-green-300 border-green-600 text-green-700 hover:border-green-700 focus:border-green-700"
      style={{ WebkitPrintColorAdjust: "exact" }}>
      <span className="text-sm">{i18n.t("tasks:checklist.inspection.states.passed")}</span>
    </RadioGroupItem>
    <RadioGroupItem
      value="flag"
      className="border-orange-400 text-orange-600 hover:border-orange-600 focus:border-orange-600 focus:ring-orange-300"
      style={{ WebkitPrintColorAdjust: "exact" }}>
      <span className="text-sm">
        {i18n.t("tasks:checklist.inspection.states.passed_with_remarks")}
      </span>
    </RadioGroupItem>
    <RadioGroupItem
      value="fail"
      className="border-red-600 text-red-700 hover:border-red-700 focus:ring-red-300"
      style={{ WebkitPrintColorAdjust: "exact" }}>
      <span className="text-sm">{i18n.t("tasks:checklist.inspection.states.failed")}</span>
    </RadioGroupItem>
  </RadioGroup>
)

export const InspectionForm = (props: BlockElementFormProps) => {
  return (
    <VStack space={8} flex="1">
      <RichTextFormController
        control={props.control}
        className="max-h-36"
        placeholder={i18n.t("tasks:checklist.inspection.placeholder")}
        name={`${props.field}.${props.index}.config.description`}
        noStyle
      />
      <InspectionRadioChoices disabled />
    </VStack>
  )
}

export const InspectionBlockElement = (
  props: BlockElementProps<BlockElementInspection>
) => {
  const noteRef = useRef<HTMLTextAreaElement>(null)
  const noteField = useDisclosure({
    initialValue: !!props.element.response?.note,
    onOpen: () => {
      setTimeout(() => {
        noteRef.current?.focus()
      }, 100)
    },
  })
  const [note, setNote] = useDebouncedPersistingText(
    props.element?.response?.note ?? "",
    (note) => {
      const inspection_result = props.element.response?.inspection_result
      if (!inspection_result) return
      props.updateResponse?.(props.element.id, { inspection_result, note })
    },
    500
  )

  const { addUpload, deleteUpload, scope } = useResponseUploads(props.element.id, {
    blockResponseUpdate: props.blockResponseUpdate,
  })

  return (
    <div className="relative flex w-full flex-1 flex-col items-start">
      <StaticRichText className="mb-2" content={props.element.config.description} />
      <InspectionRadioChoices
        value={props.element.response?.inspection_result}
        onValueChange={(value) => {
          props.updateResponse?.(props.element.id, {
            note: "",
            ...props.element.response,
            inspection_result: value as InspectionResult,
          })
        }}
      />
      {!noteField.isOpen && (
        <Button
          type="tertiary"
          size="small"
          icon={Icons.AddParagraph}
          onClick={noteField.onOpen}
          disabled={!props.element.response?.inspection_result}
          disabledReason="Es muss erst das Inspektionsergebnis festgehalten werden">
          {i18n.t("common:enter_token", { token: i18n.t("tasks:fields.note") })}
        </Button>
      )}

      <TextArea
        value={note}
        ref={noteRef}
        onChange={(e) => setNote(e.target.value)}
        className={classNames("w-full mt-2 h-16", { hidden: !noteField.isOpen })}
        placeholder={i18n.t("tasks:fields.note")}
        onBlur={(e) => !e.target.value && noteField.onClose()}
      />

      <Uploads
        className="mt-2"
        addUploadButtonProps={{
          icon: Icons.TakePicture,
          disabled: !props.element.response?.inspection_result,
          disabledReason: "Es muss erst das Inspektionsergebnis festgehalten werden",
        }}
        onUploadFinished={addUpload}
        onDelete={deleteUpload}
        disabled={!props.element.response?.inspection_result}
        placeholderLabel={i18n.t("common:upload_files")}
        boundDropzoneToContainer={false}
        uploads={props.element.uploads.map((u) => u.upload)}
        scope={scope}
      />
    </div>
  )
}
