import { IWorkOrderPriorityEnum } from "@elara/db"
import { IWorkOrderDataViewFragment } from "@graphql/documents/fragments.generated"
import { useDisclosure } from "@hooks"
import i18n from "@i18n"
import Icons from "@resources/icons"
import classNames from "classnames"
import colors from "tailwindcss/colors"

import { WorkOrderPriorityInfoSelect } from "./work-order-priority-info-select"

export function priorityOrderValue(priority: IWorkOrderPriorityEnum | null): number {
  if (priority === IWorkOrderPriorityEnum.High) return 0
  if (priority === IWorkOrderPriorityEnum.Medium) return 1
  if (priority === IWorkOrderPriorityEnum.Low) return 2
  return -1
}

// Sorted by `priorityOrderValue`
export const priorityColors = [
  colors["gray"]["500"],
  colors["red"]["500"],
  colors["orange"]["500"],
  colors["yellow"]["500"],
]

export function priorityColor(priority: IWorkOrderPriorityEnum | null): string {
  if (priority === IWorkOrderPriorityEnum.High) return colors["red"]["500"]
  if (priority === IWorkOrderPriorityEnum.Medium) return colors["orange"]["500"]
  if (priority === IWorkOrderPriorityEnum.Low) return colors["yellow"]["500"]
  return colors["gray"]["500"]
}

export function compareWorkOrderPriority(
  a: IWorkOrderPriorityEnum,
  b: IWorkOrderPriorityEnum
) {
  return priorityOrderValue(a) - priorityOrderValue(b)
}

export function translateWorkOrderPriority(priority: IWorkOrderPriorityEnum | null) {
  switch (priority) {
    case IWorkOrderPriorityEnum.High:
      return i18n.t("tasks:priority.high")
    case IWorkOrderPriorityEnum.Medium:
      return i18n.t("tasks:priority.medium")
    case IWorkOrderPriorityEnum.Low:
      return i18n.t("tasks:priority.low")
    default:
      return i18n.t("tasks:priority.none")
  }
}

type WorkOrderPriorityBadgeProps = {
  priority: IWorkOrderPriorityEnum | null
  className?: string
  hasLabel?: boolean
  size?: number
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

export function getPriorityIcon({
  priority,
  size = 18,
  className,
}: {
  priority: IWorkOrderPriorityEnum | null
  size: number
  className?: string
}) {
  const color = priorityColor(priority)

  switch (priority) {
    case IWorkOrderPriorityEnum.High:
      return (
        <Icons.PriorityHigh
          height={size}
          width={size}
          color={color}
          className={className}
        />
      )

    case IWorkOrderPriorityEnum.Medium:
      return (
        <Icons.PriorityMedium
          height={size}
          width={size}
          color={color}
          className={className}
        />
      )

    case IWorkOrderPriorityEnum.Low:
      return (
        <Icons.PriorityLow height={size} width={size} color={color} className={className} />
      )

    default:
      return (
        <Icons.NoPriority height={size} width={size} color={color} className={className} />
      )
  }
}

export const WorkOrderPriorityBadge = ({
  priority,
  className,
  size = 20,
  hasLabel = true,
  onClick,
}: WorkOrderPriorityBadgeProps) => {
  return (
    <div onClick={onClick} className={classNames("text-sm flex items-center", className)}>
      <div className={classNames({ "mr-1": hasLabel })}>
        {getPriorityIcon({ priority, size })}{" "}
      </div>
      {hasLabel && <span className="truncate">{translateWorkOrderPriority(priority)}</span>}
    </div>
  )
}

export const WorkOrderPriorityBadgeWithDropdown = (
  props: WorkOrderPriorityBadgeProps & { workOrder: IWorkOrderDataViewFragment }
) => {
  const showDropdown = useDisclosure()

  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    showDropdown.toggle()
  }

  if (!showDropdown.isOpen) {
    return (
      <WorkOrderPriorityBadge
        priority={props.priority}
        hasLabel={false}
        onClick={handleOnClick}
        className={
          !props.hasLabel ? "mr-0 rounded ring-gray-300 ring-offset-2 hover:ring-1" : "mr-1"
        }
      />
    )
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <WorkOrderPriorityInfoSelect
        workOrder={props.workOrder}
        hasLabel={props.hasLabel}
        isOpen={showDropdown.isOpen}
        onOpenChange={showDropdown.changeOpen}
      />
    </div>
  )
}
