// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import { FeatureFlagFragmentDoc, LocationFeatureFlagFragmentDoc, RoleFeatureFlagFragmentDoc } from './fragments.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IServerFeatureFlagsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IServerFeatureFlagsQuery = { feature_flag: Array<{ __typename: 'feature_flag', id: string, description: string, default_enabled: boolean }> };

export type ILocationFeatureFlagsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ILocationFeatureFlagsQuery = { location_x_feature_flag: Array<{ __typename: 'location_x_feature_flag', enabled: boolean, location_id: uuid, feature_flag_id: string }> };

export type IRoleFeatureFlagsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.IPermissionRoleXFeatureFlagBoolExp>;
}>;


export type IRoleFeatureFlagsQuery = { permission_role_x_feature_flag: Array<{ __typename: 'permission_role_x_feature_flag', enabled: boolean, feature_flag: string, permission_role_id: uuid }> };

export type IToggleLocationFeatureFlagMutationVariables = Types.Exact<{
  locationId: Types.Scalars['uuid']['input'];
  featureFlagId: Types.Scalars['String']['input'];
  enabled: Types.Scalars['Boolean']['input'];
}>;


export type IToggleLocationFeatureFlagMutation = { insert_location_x_feature_flag_one: { __typename: 'location_x_feature_flag', location: { __typename: 'location', id: uuid, settings: {}, features: {} | null } } | null };

export type IToggleRoleFeatureFlagMutationVariables = Types.Exact<{
  roleId: Types.Scalars['uuid']['input'];
  featureFlag: Types.Scalars['String']['input'];
  enabled: Types.Scalars['Boolean']['input'];
}>;


export type IToggleRoleFeatureFlagMutation = { insert_permission_role_x_feature_flag_one: { __typename: 'permission_role_x_feature_flag', enabled: boolean, feature_flag: string, permission_role_id: uuid } | null };

export type IUpsertRoleFeatureFlagsMutationVariables = Types.Exact<{
  objects: Array<Types.IPermissionRoleXFeatureFlagInsertInput> | Types.IPermissionRoleXFeatureFlagInsertInput;
}>;


export type IUpsertRoleFeatureFlagsMutation = { insert_permission_role_x_feature_flag: { __typename: 'permission_role_x_feature_flag_mutation_response', returning: Array<{ __typename: 'permission_role_x_feature_flag', enabled: boolean, feature_flag: string, permission_role_id: uuid }> } | null };


export const ServerFeatureFlagsDocument = gql`
    query ServerFeatureFlags {
  feature_flag {
    ...FeatureFlag
  }
}
    ${FeatureFlagFragmentDoc}`;

export function useServerFeatureFlagsQuery(options?: Omit<Urql.UseQueryArgs<IServerFeatureFlagsQueryVariables>, 'query'>) {
  return Urql.useQuery<IServerFeatureFlagsQuery, IServerFeatureFlagsQueryVariables>({ query: ServerFeatureFlagsDocument, ...options });
};
export const LocationFeatureFlagsDocument = gql`
    query LocationFeatureFlags {
  location_x_feature_flag {
    ...LocationFeatureFlag
  }
}
    ${LocationFeatureFlagFragmentDoc}`;

export function useLocationFeatureFlagsQuery(options?: Omit<Urql.UseQueryArgs<ILocationFeatureFlagsQueryVariables>, 'query'>) {
  return Urql.useQuery<ILocationFeatureFlagsQuery, ILocationFeatureFlagsQueryVariables>({ query: LocationFeatureFlagsDocument, ...options });
};
export const RoleFeatureFlagsDocument = gql`
    query RoleFeatureFlags($where: permission_role_x_feature_flag_bool_exp) {
  permission_role_x_feature_flag(where: $where) {
    ...RoleFeatureFlag
  }
}
    ${RoleFeatureFlagFragmentDoc}`;

export function useRoleFeatureFlagsQuery(options?: Omit<Urql.UseQueryArgs<IRoleFeatureFlagsQueryVariables>, 'query'>) {
  return Urql.useQuery<IRoleFeatureFlagsQuery, IRoleFeatureFlagsQueryVariables>({ query: RoleFeatureFlagsDocument, ...options });
};
export const ToggleLocationFeatureFlagDocument = gql`
    mutation ToggleLocationFeatureFlag($locationId: uuid!, $featureFlagId: String!, $enabled: Boolean!) {
  insert_location_x_feature_flag_one(
    object: {location_id: $locationId, feature_flag_id: $featureFlagId, enabled: $enabled}
    on_conflict: {constraint: location_x_feature_flag_pkey, update_columns: [enabled]}
  ) {
    location {
      id
      settings
      features
    }
  }
}
    `;

export function useToggleLocationFeatureFlagMutation() {
  return Urql.useMutation<IToggleLocationFeatureFlagMutation, IToggleLocationFeatureFlagMutationVariables>(ToggleLocationFeatureFlagDocument);
};
export const ToggleRoleFeatureFlagDocument = gql`
    mutation ToggleRoleFeatureFlag($roleId: uuid!, $featureFlag: String!, $enabled: Boolean!) {
  insert_permission_role_x_feature_flag_one(
    object: {permission_role_id: $roleId, feature_flag: $featureFlag, enabled: $enabled}
    on_conflict: {constraint: permission_role_x_feature_flag_pkey, update_columns: [enabled]}
  ) {
    ...RoleFeatureFlag
  }
}
    ${RoleFeatureFlagFragmentDoc}`;

export function useToggleRoleFeatureFlagMutation() {
  return Urql.useMutation<IToggleRoleFeatureFlagMutation, IToggleRoleFeatureFlagMutationVariables>(ToggleRoleFeatureFlagDocument);
};
export const UpsertRoleFeatureFlagsDocument = gql`
    mutation UpsertRoleFeatureFlags($objects: [permission_role_x_feature_flag_insert_input!]!) {
  insert_permission_role_x_feature_flag(
    objects: $objects
    on_conflict: {constraint: permission_role_x_feature_flag_pkey, update_columns: [enabled]}
  ) {
    returning {
      ...RoleFeatureFlag
    }
  }
}
    ${RoleFeatureFlagFragmentDoc}`;

export function useUpsertRoleFeatureFlagsMutation() {
  return Urql.useMutation<IUpsertRoleFeatureFlagsMutation, IUpsertRoleFeatureFlagsMutationVariables>(UpsertRoleFeatureFlagsDocument);
};