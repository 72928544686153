import { IMutationRootDeleteTeamByPkArgs, IMutationRootInsertTeamOneArgs } from "@elara/db"
import { ITeamFragment } from "@graphql/documents/fragments.generated"
import {
  ITeamsQuery,
  ITeamsQueryVariables,
  TeamsDocument,
} from "@graphql/documents/team.generated"
import { OptimisticMutationResolver, UpdateResolver } from "@urql/exchange-graphcache"

type Mutations = {
  [fieldName: string]: UpdateResolver
}
type Optimistic = {
  [fieldName: string]: OptimisticMutationResolver
}

const optimistic: Optimistic = {}

const mutations: Mutations = {
  insert_team_one(
    result: { insert_team_one: ITeamFragment },
    args: IMutationRootInsertTeamOneArgs,
    cache
  ) {
    const team = result.insert_team_one
    if (!team) return
    cache.updateQuery<ITeamsQuery, ITeamsQueryVariables>(
      {
        query: TeamsDocument,
      },
      (data) => {
        if (!data || !data.team) return data
        if (!data.team.some((t) => t.id === team?.id)) {
          data.team.push(team)
          data.team.sort((a, b) => a.name.localeCompare(b.name))
        }
        return data
      }
    )
  },
  delete_team_by_pk(_result, args: IMutationRootDeleteTeamByPkArgs, cache) {
    cache.invalidate({
      __typename: "team",
      id: args.id,
    })
  },
}

export default {
  optimistic,
  mutations,
}
