// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import { ContactFragmentDoc } from './fragments.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IContactsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IContactsQuery = { contact: Array<{ __typename: 'contact', id: uuid, name: string, address: string | null, email: string | null, phone: string | null, note: string | null, company: string, position: string | null, created_at: string, updated_at: string }> };

export type IUpsertContactMutationVariables = Types.Exact<{
  data: Types.IContactInsertInput;
}>;


export type IUpsertContactMutation = { insert_contact_one: { __typename: 'contact', id: uuid, name: string, address: string | null, email: string | null, phone: string | null, note: string | null, company: string, position: string | null, created_at: string, updated_at: string } | null };

export type IDeleteContactMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type IDeleteContactMutation = { delete_contact_by_pk: { __typename: 'contact', id: uuid, name: string, address: string | null, email: string | null, phone: string | null, note: string | null, company: string, position: string | null, created_at: string, updated_at: string } | null };


export const ContactsDocument = gql`
    query Contacts {
  contact {
    ...Contact
  }
}
    ${ContactFragmentDoc}`;

export function useContactsQuery(options?: Omit<Urql.UseQueryArgs<IContactsQueryVariables>, 'query'>) {
  return Urql.useQuery<IContactsQuery, IContactsQueryVariables>({ query: ContactsDocument, ...options });
};
export const UpsertContactDocument = gql`
    mutation UpsertContact($data: contact_insert_input!) {
  insert_contact_one(
    object: $data
    on_conflict: {constraint: contact_pkey, update_columns: [name, position, company, note, phone, email]}
  ) {
    ...Contact
  }
}
    ${ContactFragmentDoc}`;

export function useUpsertContactMutation() {
  return Urql.useMutation<IUpsertContactMutation, IUpsertContactMutationVariables>(UpsertContactDocument);
};
export const DeleteContactDocument = gql`
    mutation DeleteContact($id: uuid!) {
  delete_contact_by_pk(id: $id) {
    ...Contact
  }
}
    ${ContactFragmentDoc}`;

export function useDeleteContactMutation() {
  return Urql.useMutation<IDeleteContactMutation, IDeleteContactMutationVariables>(DeleteContactDocument);
};