import { Button } from "@components/shared"
import { Icon, Plus } from "@phosphor-icons/react"

export function EmptySection(props: {
  title: string
  message: string
  actionText: string
  icon?: Icon
  onAction?: () => void
  action?: React.ReactNode
}) {
  return (
    <div className="flex justify-center rounded-lg border border-dashed border-gray-200 p-3 print:h-32">
      <div className="text-center">
        <h3 className="mt-2 text-sm font-semibold text-gray-900 print:hidden">
          {props.title}
        </h3>
        <p className="my-1 max-w-sm text-sm text-gray-500  print:hidden">{props.message}</p>
        <div className="mt-3">
          {props.action ?? (
            <Button
              type="secondary"
              icon={props.icon ?? Plus}
              onClick={props.onAction}
              className=" print:hidden">
              {props.actionText}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
