import LoadingSpinner from "@components/shared/loading-spinner"
import toast from "@components/shared/toast"
import {
  AssetDetailsByQrCodeDocument,
  IAssetDetailsByQrCodeQuery,
  IAssetDetailsByQrCodeQueryVariables,
} from "@graphql/documents/asset.generated"
import i18n from "@i18n"
import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useClient } from "urql"

export const QrRedirect = () => {
  const { qrCode } = useParams()
  const client = useClient()
  const navigate = useNavigate()

  useEffect(() => {
    const redirect = async () => {
      if (!qrCode) {
        return navigate("/object")
      }
      const isUUID = qrCode?.length === 36
      console.log({ isUUID })

      if (isUUID) {
        navigate("/object/" + qrCode)
        return
      } else {
        const res = await client
          .query<IAssetDetailsByQrCodeQuery, IAssetDetailsByQrCodeQueryVariables>(
            AssetDetailsByQrCodeDocument,
            { qr_code: qrCode }
          )
          .toPromise()
        const asset = res.data?.asset?.[0]
        if (asset) {
          navigate("/object/" + asset.id)
          return
        } else {
          toast.error(i18n.t("assets:qr_code_not_found"))
          navigate("/object")
          return
        }
      }
    }
    redirect()
  }, [qrCode])
  return <LoadingSpinner />
}

export default QrRedirect
