import Button from "@components/shared/button"
import { CurrencyValue } from "@components/shared/currency-value"
import Uploads from "@components/shared/uploads"
import {
  IWorkOrderExpenseFragment,
  IWorkOrderFragment,
} from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { PencilSimple, Plus, Receipt } from "@phosphor-icons/react"
import { parseDate } from "@utils"
import { formatDate } from "@utils/date"

import { EmptySection } from "./empty"
import CreateEditExpenseDialogForm from "./expense/create-edit-expense"
import { Section } from "./section"

export function totalExpenseCost(expenses: IWorkOrderExpenseFragment[]) {
  return expenses.reduce((acc, expense) => acc + (expense.amount ?? 0), 0)
}

function ExpensesReportTable(props: {
  expenses: IWorkOrderExpenseFragment[]
  workOrderId: string
}) {
  return (
    <div className="rounded-lg bg-gray-50 p-3">
      <table className="min-w-full divide-y divide-gray-300 text-sm">
        <thead>
          <tr>
            <th
              scope="col"
              className="w-full pb-2 pr-3 text-left text-sm font-medium text-gray-500">
              {i18n.t("tasks:report.expense.description")}
            </th>
            <th
              scope="col"
              className="px-3 pb-2 text-right text-sm font-medium text-gray-500">
              {i18n.t("tasks:report.expense.amount")}
            </th>
            <th />
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-100">
          {props.expenses.map((expense) => {
            if (!expense) return null

            return (
              <tr key={expense.id}>
                <td className="py-2">
                  <div className="flex items-center">
                    {expense.description || (
                      <span className="text-gray-500">
                        {i18n.t("tasks:report.expense.no_description_placeholder")}
                      </span>
                    )}
                  </div>
                  <Uploads uploads={expense.uploads ?? []} />
                  <div className="mt-2 text-gray-500">
                    {formatDate(parseDate(expense.expense_date), "P")}
                  </div>
                </td>

                <td className="px-3 py-2 text-right align-top tabular-nums">
                  <CurrencyValue value={expense.amount ?? 0} />
                </td>
                <td className="px-1 py-0.5 align-top">
                  <CreateEditExpenseDialogForm
                    workOrderId={expense.work_order_id}
                    expense={expense}>
                    <Button type="tertiary" icon={PencilSimple} color="gray" />
                  </CreateEditExpenseDialogForm>
                </td>
              </tr>
            )
          })}
          <tr>
            <td className="py-2">
              <CreateEditExpenseDialogForm workOrderId={props.workOrderId}>
                <Button type="tertiary" color="blue" icon={Plus} size="extra-small">
                  {i18n.t("tasks:report.expense.empty.action")}
                </Button>
              </CreateEditExpenseDialogForm>
            </td>
            <td className="px-3 py-2 text-right font-medium tabular-nums">
              <span className="mr-2 font-medium text-gray-700 ">
                {i18n.t("common:total")}
              </span>
              <CurrencyValue value={totalExpenseCost(props.expenses)} />
            </td>
            <td />
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default function ExpenseReport(props: { task: IWorkOrderFragment }) {
  const {
    task: { expenses },
  } = props

  return (
    <Section title={i18n.t("tasks:report.expense.title")} icon={<Receipt />} action={null}>
      {expenses.length > 0 ? (
        <ExpensesReportTable expenses={expenses} workOrderId={props.task.id} />
      ) : (
        <EmptySection
          title={i18n.t("tasks:report.expense.empty.title")}
          message={i18n.t("tasks:report.expense.empty.message")}
          actionText={i18n.t("tasks:report.expense.empty.action")}
          action={
            <CreateEditExpenseDialogForm workOrderId={props.task.id}>
              <Button
                type="secondary"
                color="gray"
                icon={Receipt}
                className=" print:hidden">
                {i18n.t("tasks:report.expense.empty.action")}
              </Button>
            </CreateEditExpenseDialogForm>
          }
        />
      )}
    </Section>
  )
}
