import { IconSpacer } from "@components/shared/spacers"
import { IPermissionScopeEnum, IWorkOrderPriorityEnum } from "@elara/db"
import classNames from "classnames"
import React from "react"
import toast from "react-hot-toast"

import { IWorkOrderDataViewFragment } from "../../graphql/documents/fragments.generated"
import { useUpdateWorkOrderPartiallyMutation } from "../../graphql/documents/work-order.generated"
import { usePermissionScope } from "../../hooks"
import { SelectPopover, SelectTrigger } from "../shared/single-select"
import {
  getPriorityIcon,
  translateWorkOrderPriority,
  WorkOrderPriorityBadge,
} from "./work-order-priority"

type WorkOrderPriorityInfoSelectProps = {
  workOrder: IWorkOrderDataViewFragment
  hasLabel?: boolean
  isOpen?: boolean
  onOpenChange?: (isOpen: boolean) => void
}

export const WorkOrderPriorityInfoSelect = React.memo(
  ({
    workOrder,
    hasLabel = true,
    isOpen,
    onOpenChange,
  }: WorkOrderPriorityInfoSelectProps) => {
    const editScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)
    const [, updateWorkOrderPartiallyMutation] = useUpdateWorkOrderPartiallyMutation()

    const { id: workOrderId, priority } = workOrder
    const hasAnyScope = editScope.hasScope
    if (!hasAnyScope) return <WorkOrderPriorityBadge priority={priority} />

    const onChange = async (priority: IWorkOrderPriorityEnum | null) => {
      const result = await updateWorkOrderPartiallyMutation(
        {
          id: workOrderId,
          changes: {
            priority,
          },
        },
        editScope.context()
      )
      if (result.error) {
        toast.error("Auftragspriorität konnte nicht geändert werden.")
      }
    }

    return (
      <SelectPopover
        items={[
          {
            value: "no_priority",
            label: <WorkOrderPriorityBadge priority={null} />,
            searchValue: "null",
          },
          {
            value: IWorkOrderPriorityEnum.Low,
            searchValue: "low",
            label: <WorkOrderPriorityBadge priority={IWorkOrderPriorityEnum.Low} />,
          },
          {
            value: IWorkOrderPriorityEnum.Medium,
            searchValue: "medium",
            label: <WorkOrderPriorityBadge priority={IWorkOrderPriorityEnum.Medium} />,
          },
          {
            value: IWorkOrderPriorityEnum.High,
            searchValue: "high",
            label: <WorkOrderPriorityBadge priority={IWorkOrderPriorityEnum.High} />,
          },
        ]}
        value={workOrder.priority ?? "no_priority"}
        onChange={(key) => {
          if (key === "no_priority") {
            onChange(null)
          } else {
            onChange(key as IWorkOrderPriorityEnum)
          }
        }}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        isClearable={false}
        popoverContentProps={{
          align: "start",
          side: "left",
          sideOffset: 8,
          alignOffset: -8,
        }}
        listMaxHeight={300}>
        <SelectTrigger
          className={classNames(
            "flex items-center flex-1 font-medium rounded cursor-pointer border border-transparent hover:border-gray-300",
            { "!px-0 !py-0": !hasLabel, "text-gray-500": !workOrder.priority }
          )}>
          <IconSpacer
            spacing={{ y: 3, x: 1 }}
            containerRightPadding={12}
            icon={getPriorityIcon({ priority: workOrder.priority, size: 20 })}>
            <span className="truncate">{translateWorkOrderPriority(priority)}</span>
          </IconSpacer>
        </SelectTrigger>
      </SelectPopover>
    )
  }
)
