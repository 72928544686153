import { useControllableState } from "@hooks/use-controllable-state"
import i18n from "@i18n"
import { ZonedDateTime } from "@internationalized/date"
import { useTimeField } from "@react-aria/datepicker"
import { TimeFieldStateOptions, useTimeFieldState } from "@react-stately/datepicker"
import { useRef } from "react"

import { DateSegment } from "./date-segment"

export function TimeField({
  value: _value,
  defaultValue: _defaultValue,
  ...props
}: Omit<
  TimeFieldStateOptions<ZonedDateTime>,
  "locale" | "value" | "defaultValue" | "onChange"
> & {
  value?: ZonedDateTime | null
  defaultValue?: ZonedDateTime
  onValueChange?: (value: ZonedDateTime | null) => void
}) {
  let ref = useRef<HTMLDivElement>(null)

  const [value, setValue] = useControllableState({
    prop: _value,
    defaultProp: _defaultValue,
    onChange: props.onValueChange,
  })

  let state = useTimeFieldState({
    ...props,
    value,
    onChange: setValue,
    locale: i18n.language,
    defaultValue: undefined,
    hideTimeZone: props.hideTimeZone ?? true,
  })

  let { labelProps, fieldProps } = useTimeField(props, state, ref)

  return (
    <div className="flex flex-col items-start">
      <span {...labelProps} className="text-sm text-gray-800">
        {props.label}
      </span>
      <div
        {...fieldProps}
        ref={ref}
        className="flex rounded-md border border-gray-300 bg-white p-[5px] pr-2.5 transition-colors focus-within:border-blue-700 focus-within:ring-1 focus-within:ring-blue-700 hover:border-gray-400 focus-within:hover:border-blue-700">
        {state.segments.map((segment, i) => (
          <DateSegment key={i} segment={segment} state={state} />
        ))}
      </div>
    </div>
  )
}
