import i18n from "@i18n"
import { LongLogo } from "@resources/logos"

import LoadingIndicator from "./loading-indicator"

export const LoadingSpinner = (props: { showLogo?: boolean }) => (
  <div className="absolute inset-0 flex items-center justify-center">
    <div className="flex flex-col items-center space-y-3 text-gray-600">
      <LoadingIndicator size={24} />
      <div className="font-medium">{i18n.t("common:loading")}</div>
    </div>
    {props.showLogo && (
      <LongLogo width={122} height={44} className="absolute bottom-4 text-blue-700" />
    )}
  </div>
)

export default LoadingSpinner
