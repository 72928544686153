import { useDateSegment } from "@react-aria/datepicker"
import { DateFieldState, DateSegment as DateSegmentType } from "@react-stately/datepicker"
import { cn } from "@utils"
import { useRef } from "react"

export function DateSegment({
  segment,
  state,
}: {
  segment: DateSegmentType
  state: DateFieldState
}) {
  let ref = useRef<HTMLDivElement>(null)
  let { segmentProps } = useDateSegment(segment, state, ref)

  return (
    <div
      {...segmentProps}
      ref={ref}
      style={{
        ...segmentProps.style,
        minWidth:
          segment.maxValue != null ? String(segment.maxValue).length + "ch" : undefined,
      }}
      className={cn(
        "focus:bg-blue-600 group box-content rounded-sm px-0.5 text-sm text-right outline-none focus:text-white",
        !segment.isEditable ? "text-gray-500" : "text-gray-800"
      )}>
      {/* Always reserve space for the placeholder, to prevent layout shift when editing. */}
      <span
        aria-hidden="true"
        className={cn("block w-full text-center italic text-gray-500 pointer-events-none", {
          "invisible h-0": !segment.isPlaceholder,
        })}>
        {segment.placeholder}
      </span>
      {segment.isPlaceholder ? "" : segment.text}
    </div>
  )
}
