import { useUser } from "@contexts/user-context"
import { IWorkOrderDataViewFragment } from "@graphql/documents/fragments.generated"
import { useTeamsQuery } from "@graphql/documents/team.generated"
import React from "react"

const WorkOrderIsAssignedContext = React.createContext<boolean>(false)

export const useWorkOrderIsAssigned = () =>
  React.useContext(WorkOrderIsAssignedContext) ?? false

type WorkOrderIsAssignedProviderProps = {
  workOrder: Pick<
    IWorkOrderDataViewFragment,
    "assignees" | "assigned_teams" | "created_by_id"
  > | null
}
export const WorkOrderIsAssignedProvider = (
  props: React.PropsWithChildren<WorkOrderIsAssignedProviderProps>
) => {
  const user = useUser()
  const [teamsQueryRes] = useTeamsQuery({ requestPolicy: "cache-only" })
  const myTeams =
    teamsQueryRes?.data?.team?.filter((t) =>
      t.members?.some((u) => u.user?.id === user.id)
    ) ?? []

  // Optimistic default, the backend catches the error anyways. So let's
  // rather allow the user an invalid option than to block him accidentally
  let isAssigned = true

  if (props.workOrder) {
    const isCreator = props.workOrder.created_by_id === user.id
    const isDirectAssignee =
      props.workOrder.assignees.length > 0 &&
      props.workOrder.assignees.some((a) => a.user.id === user.id)
    const isAssignedViaTeam =
      props.workOrder.assigned_teams.length > 0 &&
      props.workOrder.assigned_teams.some((t) => myTeams.some((t2) => t.team_id === t2.id))
    isAssigned = isDirectAssignee || isAssignedViaTeam || isCreator
  }

  return (
    <WorkOrderIsAssignedContext.Provider value={isAssigned}>
      {props.children}
    </WorkOrderIsAssignedContext.Provider>
  )
}
