import { Flex, View } from "@components/layout"
import Icons from "@resources/icons"
import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"
import AnimateHeight from "react-animate-height"

type CollapseContextProps = {
  activePanels: string[]
  expandIcon: (props: { isActive: boolean; transitionDuration: number }) => React.ReactNode
  expandIconPosition: "right" | "left"
  togglePanel: (name: string) => void
}

const CollapseContext = React.createContext<CollapseContextProps>(undefined!)

export type CollapseProps = {
  // If true, only one panel can be open at a time
  accordion?: boolean
  expandIcon?: (props: { isActive: boolean; transitionDuration: number }) => React.ReactNode
  expandIconPosition?: "right" | "left"
  defaultOpenPanels?: string[]
}

export const Collapse: FunctionComponent<PropsWithChildren<CollapseProps>> = (props) => {
  const {
    defaultOpenPanels = [],
    expandIconPosition = "left",
    expandIcon = defaultExpandIcon,
  } = props

  const [activePanels, setActivePanels] = useState<string[]>(defaultOpenPanels)

  const togglePanel = useCallback(
    (name: string) => {
      const nameIsActive = activePanels.some((panel) => panel === name)
      if (props.accordion) {
        setActivePanels(nameIsActive ? [] : [name])
      } else {
        if (nameIsActive) {
          setActivePanels(activePanels.filter((panel) => panel !== name))
        } else {
          setActivePanels(activePanels.concat([name]))
        }
      }
    },
    [activePanels]
  )

  const value = useMemo(
    () => ({
      activePanels,
      togglePanel,
      expandIcon,
      expandIconPosition,
    }),
    [activePanels, togglePanel, expandIconPosition, expandIcon]
  )

  return <CollapseContext.Provider value={value}>{props.children}</CollapseContext.Provider>
}

export const useCollapse = () => {
  const value = useContext(CollapseContext)
  if (!value) throw new Error("CollapsePanel can only be used inside a Collapse")
  return value
}

const defaultExpandIcon = (props: { isActive: boolean; transitionDuration: number }) => {
  return (
    <Icons.RightNext
      height={20}
      width={20}
      style={{
        flexShrink: 0,
        transitionDuration: `${props.transitionDuration}ms`,
        transitionTimingFunction: "ease-in-out",
        transitionProperty: "transform",
        transform: props.isActive ? "rotate(90deg)" : "rotate(0deg)",
      }}
    />
  )
}

export type CollapsePanelProps = PropsWithChildren<{
  header: React.ReactNode
  name: string
  headerClassName?: string
  headerStyle?: React.CSSProperties
  onClick?: () => void
}>

export const CollapsePanel: FunctionComponent<CollapsePanelProps> = (props) => {
  const { activePanels, togglePanel, expandIcon, expandIconPosition } = useCollapse()
  const isActive = useMemo(
    () => activePanels.some((panel) => panel === props.name),
    [props.name, activePanels]
  )

  const icon = expandIcon({ isActive, transitionDuration: 300 })

  return (
    <View>
      <Flex
        row
        align="center"
        onClick={() => {
          props.onClick?.()
          togglePanel(props.name)
        }}
        className={props.headerClassName}
        style={{ cursor: "pointer", userSelect: "none", ...props.headerStyle }}>
        {expandIconPosition === "left" && icon}
        {props.header}
        {expandIconPosition === "right" && icon}
      </Flex>
      <AnimateHeight duration={300} height={isActive ? "auto" : 0} animateOpacity>
        {props.children}
      </AnimateHeight>
    </View>
  )
}
