import {
  IMutationRootUpdateNotificationArgs,
  IMutationRootUpdateNotificationByPkArgs,
  uuid,
} from "@elara/db"
import {
  AllNotificationsDocument,
  IAllNotificationsQuery,
  IAllNotificationsQueryVariables,
  NotificationFragmentDoc,
} from "@graphql/documents/notification.generated"
import { OptimisticMutationResolver, UpdateResolver } from "@urql/exchange-graphcache"

type Mutations = {
  [fieldName: string]: UpdateResolver
}
type Optimistic = {
  [fieldName: string]: OptimisticMutationResolver
}

const optimistic: Optimistic = {
  update_notification_by_pk(vars: IMutationRootUpdateNotificationByPkArgs, cache) {
    return {
      __typename: "notification",
      ...cache.readFragment(NotificationFragmentDoc, { id: vars.pk_columns.id }),
      ...vars._set,
    }
  },
}

const mutations: Mutations = {
  update_notification_by_pk(_result, args: IMutationRootUpdateNotificationByPkArgs, cache) {
    if (args._set?.deleted_at) {
      cache.updateQuery<IAllNotificationsQuery, IAllNotificationsQueryVariables>(
        { query: AllNotificationsDocument },
        (data) => {
          if (data?.notification) {
            data.notification = data.notification.filter((n) => n.id !== args.pk_columns.id)
            return data
          }
          return null
        }
      )
    }
  },
  update_notification(
    result: { update_notification?: { returning: { id: uuid }[] } | null },
    args: IMutationRootUpdateNotificationArgs,
    cache
  ) {
    if (args._set?.deleted_at) {
      for (let deletedItem of result.update_notification?.returning || []) {
        cache.invalidate({
          __typename: "notification",
          id: deletedItem.id,
        })
      }
    }
  },
}

export default {
  optimistic,
  mutations,
}
