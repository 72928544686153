import { useDisclosure } from "@hooks"
import { useEffect, useState } from "react"

import { ScannerDialog, ScannerDialogProps } from "./scanner-dialog"

type ScannerEventProps = Omit<ScannerDialogProps, "open" | "onOpenChange">

export const ScannerDialogProvider = () => {
  const [scannerProps, setScannerProps] = useState<ScannerEventProps | null>(null)

  const disclosure = useDisclosure({
    onClose: () => setScannerProps(null),
  })

  useEffect(() => {
    const listener = (e: Event) => {
      if (e instanceof CustomEvent && e.detail) {
        setScannerProps(e.detail)
        disclosure.changeOpen(true)
      }
    }

    window.addEventListener("scan-code", listener)

    return () => window.removeEventListener("scan-code", listener)
  }, [])

  if (!scannerProps) return null

  return (
    <ScannerDialog
      open={disclosure.isOpen}
      onOpenChange={disclosure.changeOpen}
      {...scannerProps}
    />
  )
}

export const scanCode = async (props: ScannerEventProps = {}) =>
  new Promise<string | null>((resolve) => {
    window.dispatchEvent(
      new CustomEvent("scan-code", {
        detail: {
          ...props,
          onScan(result: string) {
            props.onScan?.(result)
            resolve(result)
          },
          onCancel() {
            props.onCancel?.()
            resolve(null)
          },
        },
      })
    )
  })
