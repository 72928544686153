import PageTitle from "@components/page/page-title"
import { Button } from "@components/shared"
import ScrollArea from "@components/shared/scroll-area"
import { TeamMultiSelect } from "@components/shared/team-select"
import TasksBox from "@components/work-order/tasks-box"
import { useTaskData } from "@components/work-order/use-task-data"
import { WorkOrderDueDatePopover } from "@components/work-order/work-order-due-date-popover"
import { WorkOrderStatusTag } from "@components/work-order/work-order-status"
import { useBreakpoint } from "@contexts/breakpoints"
import { openModal } from "@contexts/modal-context"
import { IOrderBy, IWorkOrderStatusEnum, uuid } from "@elara/db"
import { orderBy } from "@elara/select"
import { useTaskOverviewDataQuery } from "@graphql/documents/analytics.generated"
import { useStickyState } from "@hooks/use-sticky-state"
import i18n from "@i18n"
import { CheckCircle, Plus } from "@phosphor-icons/react"
import { formatDate } from "@utils/date"
import { endOfWeek, getWeek, startOfWeek, subWeeks } from "date-fns"
import React from "react"

type OverviewFilters = {
  teams: uuid[]
}

function overviewFiltersToWhere(filters: OverviewFilters) {
  return {
    ...(filters.teams.length
      ? { assigned_teams: { team_id: { _in: filters.teams } } }
      : {}),
  }
}

const OverdueTasks = (props: { filters: OverviewFilters }) => {
  const data = useTaskData({
    where: {
      due_date: { _lt: formatDate(new Date(), "yyyy-MM-dd") },
      status: { _nin: [IWorkOrderStatusEnum.Done, IWorkOrderStatusEnum.Canceled] },
      ...overviewFiltersToWhere(props.filters),
    },
  })
  const overdueTasks = orderBy(data.data ?? [], { due_date: "desc" })

  return (
    <TasksBox
      title={(count) => i18n.t("tasks:overdue_tasks", { count })}
      dateLabel={i18n.t("tasks:fields.due_date")}
      data={overdueTasks}
      isFetching={data.queryRes.fetching}
      dateElement={(t) => (
        <div className="-ml-2 inline-flex sm:-ml-3">
          <WorkOrderDueDatePopover workOrder={t} markAsOverdue />
        </div>
      )}
      emptyPlaceholder={
        <>
          <CheckCircle size={20} weight="bold" className="mr-2 text-gray-400" />{" "}
          <span>{i18n.t("tasks:overview.no_overdue_tasks")}</span>
        </>
      }
    />
  )
}
const LatestTasks = (props: { filters: OverviewFilters }) => {
  const data = useTaskData({
    where: {
      id: { _is_null: false },
      status: { _nin: [IWorkOrderStatusEnum.Done, IWorkOrderStatusEnum.Canceled] },
      ...overviewFiltersToWhere(props.filters),
    },
    orderBy: { created_at: IOrderBy.Desc },
    limit: 10,
  })
  const tasks = orderBy(data.data ?? [], { created_at: "desc" }).slice(0, 10)
  return (
    <TasksBox
      title={() => i18n.t("tasks:overview.latest_tasks")}
      dateLabel={i18n.t("tasks:fields.created_at")}
      data={tasks}
      dateElement={(t) => {
        const date = new Date(t.created_at)
        return (
          <span>
            <span className="text-gray-700">{formatDate(date, "P")}</span>
            <span className="ml-0.5 text-gray-500">{formatDate(date, "p")}</span>
          </span>
        )
      }}
      isFetching={data.queryRes.fetching}
      emptyPlaceholder={i18n.t("tasks:overview.empty_placeholder")}
    />
  )
}

const RecentlyCompleted = (props: { filters: OverviewFilters }) => {
  const data = useTaskData({
    where: {
      id: { _is_null: false },
      status: { _eq: IWorkOrderStatusEnum.Done },
      ...overviewFiltersToWhere(props.filters),
    },
    orderBy: { completed_at: IOrderBy.Desc },
    limit: 10,
  })
  const tasks = orderBy(data.data ?? [], { completed_at: "desc" }).slice(0, 10)
  return (
    <TasksBox
      title={() => i18n.t("tasks:overview.recently_completed_tasks")}
      dateLabel={i18n.t("tasks:fields.completed_at")}
      dateElement={(t) => {
        const date = new Date(t.completed_at!)
        return (
          <span>
            <span className="text-gray-700">{formatDate(date, "P")}</span>
            <span className="ml-0.5 text-gray-500">{formatDate(date, "p")}</span>
          </span>
        )
      }}
      data={tasks}
      isFetching={data.queryRes.fetching}
      emptyPlaceholder={i18n.t("tasks:overview.empty_placeholder")}
    />
  )
}

const ActiveTasksAggregate = (props: {
  data: Partial<Record<IWorkOrderStatusEnum, number>>
}) => {
  const total = Object.values(props.data ?? {}).reduce((a, b) => a + b, 0)
  const bp = useBreakpoint()
  return (
    <div>
      <div className="text-base font-semibold text-gray-900">
        {i18n.t("tasks:overview.due_in_calendar_week", {
          count: total,
          week: getWeek(new Date()),
        })}
      </div>

      <div className="flex h-full min-w-[240px] flex-wrap items-center justify-around gap-x-2 gap-y-1 pt-4">
        {[
          IWorkOrderStatusEnum.Planned,
          IWorkOrderStatusEnum.Open,
          IWorkOrderStatusEnum.InProgress,
          IWorkOrderStatusEnum.ReadyForApproval,
          IWorkOrderStatusEnum.OnHold,
        ].map((s) => (
          <React.Fragment key={s}>
            <div className="flex flex-col items-center text-sm">
              <span className="text-right text-3xl font-semibold">
                {props.data?.[s] ?? 0}
              </span>
              <span className="text-gray-700">
                <WorkOrderStatusTag status={s} short={bp.renderMobile} />
              </span>
              {/* <span className="text-xs text-gray-500">{translateWorkOrderStatus(s)}</span> */}
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

export const TaskOverviewPage = () => {
  const [filters, setFilters] = useStickyState<OverviewFilters>(
    { teams: [] },
    "overview_filters"
  )

  const today = new Date()
  const startDate = startOfWeek(today)
  const prevStartDate = subWeeks(startDate, 1)
  const endDate = endOfWeek(today)
  const [queryRes] = useTaskOverviewDataQuery({
    variables: {
      startDate: formatDate(startDate, "yyyy-MM-dd"),
      endDate: formatDate(endDate, "yyyy-MM-dd"),
      startTimestamp: startDate.toISOString(),
      endTimestamp: endDate.toISOString(),
      prevStartTimestamp: prevStartDate.toISOString(),
      where: overviewFiltersToWhere(filters),
    },
  })

  return (
    <ScrollArea vertical className="flex min-w-0 flex-col bg-gray-50 px-3 " viewportAsChild>
      <div className="mx-auto w-full max-w-screen-lg space-y-3 pb-6 @container/page">
        <PageTitle
          title={i18n.t("tasks:overview.title")}
          className="pb-0 "
          action={
            <Button
              type="primary"
              className="ml-auto"
              icon={Plus}
              onClick={() => openModal("select_template")}>
              {i18n.t("tasks:labels.new_task")}
            </Button>
          }
        />
        {/* Show aggregate statistics */}

        <div>
          <TeamMultiSelect
            compact
            value={filters.teams}
            onChange={(teams) => setFilters((f) => ({ ...f, teams }))}
          />
        </div>
        <div className="grid gap-3 sm:grid-cols-3 sm:gap-6">
          <div className="col-span-1 flex flex-col rounded-lg border border-gray-100 bg-white p-3 sm:p-6">
            <div className="text-base font-semibold text-gray-900">
              {i18n.t("tasks:overview.completed_tasks")}
            </div>
            <div className="flex w-full grow items-center justify-center">
              <div className="grid w-full grid-cols-2 items-end justify-center">
                <div className="flex flex-col items-center">
                  <div className="text-4xl font-bold text-gray-900">
                    {queryRes.data?.completed?.aggregate?.count}
                  </div>
                  <span className="mx-auto text-center text-sm">
                    {i18n.t("common:calendar_week")} {getWeek(startDate)}
                  </span>
                </div>
                <div className="flex flex-col items-center">
                  <div className="text-4xl font-bold text-gray-500">
                    {queryRes.data?.prev_completed?.aggregate?.count}
                  </div>
                  <span className="mx-auto text-center text-sm text-gray-500">
                    {i18n.t("common:calendar_week")} {getWeek(prevStartDate)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-lg border border-gray-100 bg-white p-3 sm:col-span-2 sm:p-6">
            <ActiveTasksAggregate
              data={Object.fromEntries(
                (queryRes.data?.active_by_status ?? []).map((v) => [
                  v.value,
                  v.work_orders_aggregate.aggregate?.count ?? 0,
                ])
              )}
            />
          </div>
        </div>
        {/* {/* <TeamReporting teamId={props.team.id} /> */}
        <LatestTasks filters={filters} />
        <OverdueTasks filters={filters} />
        <RecentlyCompleted filters={filters} />
      </div>
    </ScrollArea>
  )
}

export default TaskOverviewPage
