import { Button, UserSingleSelect } from "@components/shared"
import { RichTextFormController } from "@components/shared/form/rich-text-form-controller"
import { DateTimeNative } from "@components/shared/native-date-time-pickers"
import { TextInputWithUnit } from "@components/shared/text-input-with-unit"
import toast from "@components/shared/toast"
import { useUser } from "@contexts/user-context"
import { uuid } from "@elara/db"
import { IWorkOrderReportFragment } from "@graphql/documents/fragments.generated"
import {
  DeleteTimeReportDocument,
  IDeleteTimeReportMutation,
  IDeleteTimeReportMutationVariables,
  useAddTimeReportMutation,
} from "@graphql/documents/work-order.generated"
import { zodResolver } from "@hookform/resolvers/zod"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import { useKeyboardListener } from "@hooks/use-keyboard-listener"
import i18n from "@i18n"
import { NotePencil } from "@phosphor-icons/react"
import { parseDate } from "@utils"
import classNames from "classnames"
import * as date from "date-fns"
import { intervalToDuration } from "date-fns"
import { useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useClient } from "urql"
import { z } from "zod"

const FormSchema = z.object({
  started_at: z.date(),
  duration: z
    .object({
      hours: z.number().int().min(0),
      minutes: z.number().int().min(0).max(59),
    })
    .refine((duration) => duration.hours > 0 || duration.minutes > 0, {
      message: i18n.t("tasks:messages.time_report_duration_error"),
    }),
  description: z.string().optional(),
  user_id: z.string().uuid(),
})

export const WorkOrderTimeTrackingForm = (props: {
  workOrderId: uuid
  report?: IWorkOrderReportFragment | null
  onCancel?: () => void
  onDelete?: () => void
  onSubmit?: () => void
}) => {
  const user = useUser()
  const client = useClient()
  const [isLoading, setIsLoading] = useState(false)

  const editScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)
  const dataEntryScope = usePermissionScope(IPermissionScopeEnum.AppDataEntry)
  const [, addTimeReport] = useAddTimeReportMutation()
  const [showDescription, changeShowDescription] = useState(true)

  const duration = props.report
    ? intervalToDuration({
        start: new Date(props.report.started_at),
        end: new Date(props.report.finished_at),
      })
    : { hours: 0, minutes: 0 }

  const { control, handleSubmit, reset, formState, watch, setValue } = useForm({
    resolver: zodResolver<any>(FormSchema),
    defaultValues: {
      started_at: parseDate(props.report?.started_at) ?? new Date(),
      duration,
      description: props.report?.description ?? "",
      user_id: props.report?.user?.id ?? user?.id ?? "",
    },
  })
  const onSubmit = handleSubmit(async (report) => {
    try {
      setIsLoading(true)

      const started_at = report.started_at.toISOString()
      const finished_at = date
        .add(report.started_at, {
          hours: report.duration.hours,
          minutes: report.duration.minutes,
        })
        .toISOString()

      const data = {
        id: props.report?.id,
        edited_at: props.report ? "now()" : undefined,
        work_order_id: props.workOrderId,
        started_at,
        finished_at,
        user_id: report.user_id,
        description: report.description,
      }

      const res = await addTimeReport(
        { data },
        editScope.hasScope ? editScope.context() : dataEntryScope.context()
      )
      if (res.data?.insert_work_order_report_one) {
        props.onSubmit?.()
        reset()
      } else {
        toast.error(i18n.t("common:messages.create_failure"))
      }
    } finally {
      setIsLoading(false)
    }
  })

  const onDelete = async () => {
    if (!props.report?.id) return

    setIsLoading(true)
    await client
      .mutation<IDeleteTimeReportMutation, IDeleteTimeReportMutationVariables>(
        DeleteTimeReportDocument,
        {
          id: props.report.id,
        },
        editScope.context()
      )
      .toPromise()
    props.onDelete?.()
  }

  const submitButtonRef = useRef<HTMLButtonElement>(null)
  useKeyboardListener((e: KeyboardEvent) => {
    if (e.key === "Enter" && e.metaKey) {
      e.stopPropagation()
      e.preventDefault()
      submitButtonRef.current?.click?.()
    }
  })

  return (
    <form className="flex flex-col text-sm" onSubmit={onSubmit}>
      <div className="">
        <div className="flex flex-wrap gap-x-6 gap-y-2 sm:grid-cols-2">
          <div className="w-5/12 min-w-[248px] grow">
            <span className="inline-block pb-1 font-medium text-gray-600">
              {i18n.t("employee", { count: 1 })}
            </span>
            <Controller
              control={control}
              name="user_id"
              render={({ field }) => (
                <UserSingleSelect
                  value={field.value}
                  onChange={field.onChange}
                  isClearable={false}
                />
              )}
            />
          </div>
          <div className="w-5/12 min-w-[248px] grow" />
          <div className="flex w-5/12 min-w-[248px] grow flex-col">
            <span className="inline-block pb-1 font-medium text-gray-600">
              {i18n.t("tasks:time_report.started_at")}
            </span>
            <Controller
              control={control}
              name={"started_at"}
              render={({ field }) => <DateTimeNative {...field} />}
            />
          </div>
          <div className="flex w-5/12 min-w-[248px] grow flex-col">
            <span className="inline-block pb-1 font-medium text-gray-600">
              {i18n.t("tasks:time_report.duration")}
            </span>
            <div className="flex items-center space-x-4">
              <TextInputWithUnit
                type="number"
                min="0"
                max="23"
                className={classNames("flex-1", {
                  "!border-red-500": formState.errors.duration?.message,
                })}
                {...control.register(`duration.hours`, {
                  valueAsNumber: true,
                  onChange: (e) => {
                    if (!control.getFieldState("started_at").isTouched && !props.report) {
                      setValue(
                        "started_at",
                        date.sub(new Date(), {
                          hours: e.target.valueAsNumber,
                          minutes: watch("duration.minutes"),
                        })
                      )
                    }
                  },
                })}
                unit={i18n.t("calendar:tokens.hour_other")}
              />
              <TextInputWithUnit
                type="number"
                min="0"
                max="59"
                className={classNames("flex-1", {
                  "!border-red-500": formState.errors.duration?.message,
                })}
                {...control.register(`duration.minutes`, {
                  valueAsNumber: true,
                  onChange: (e) => {
                    if (!control.getFieldState("started_at").isTouched && !props.report) {
                      setValue(
                        "started_at",
                        date.sub(new Date(), {
                          hours: watch("duration.hours"),
                          minutes: e.target.valueAsNumber,
                        })
                      )
                    }
                  },
                })}
                unit={i18n.t("calendar:tokens.minute_other")}
              />
            </div>
            {formState.errors.duration?.message && (
              <div className="mt-1 font-medium text-red-600">
                {formState.errors.duration?.message}
              </div>
            )}
          </div>
        </div>

        {showDescription && (
          <>
            <span className="inline-block pt-2 font-medium text-gray-600">
              {i18n.t("tasks:time_report.description")}
            </span>
            <RichTextFormController
              control={control}
              name={`description`}
              placeholder={i18n.t("tasks:time_report.add_note")}
              editorProps={{
                attributes: {
                  class: "min-h-[2.5rem] focus-visible:outline-none",
                },
              }}
            />
          </>
        )}

        <div className="-mx-2 flex justify-between">
          {showDescription ? (
            <div />
          ) : (
            <Button
              color="gray"
              type="tertiary"
              className="mt-2"
              icon={NotePencil}
              onClick={() => changeShowDescription(true)}>
              {i18n.t("tasks:time_report.add_note")}
            </Button>
          )}
        </div>
      </div>

      <div className="mt-3 flex justify-end gap-x-3">
        {props.report && props.onDelete && (
          <>
            <Button color="red" onClick={onDelete}>
              {i18n.t("common:delete")}
            </Button>
            <div className="flex-1" />
          </>
        )}
        <Button
          htmlType="button"
          className="self-end"
          onClick={() => {
            reset()
            props.onCancel?.()
          }}
          type="tertiary">
          {i18n.t("common:cancel")}
        </Button>
        <Button
          htmlType="submit"
          className="self-end"
          isLoading={isLoading}
          ref={submitButtonRef}>
          {props.report ? i18n.t("common:edit") : i18n.t("common:save")}
        </Button>
      </div>
    </form>
  )
}
