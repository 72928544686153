/* eslint-disable simple-import-sort/imports */
import deLocale from "@fullcalendar/core/locales/de"
import enLocale from "@fullcalendar/core/locales/en-au"
import FullCalendar from "@fullcalendar/react"
import interactionPlugin from "@fullcalendar/interaction"
import listPlugin from "@fullcalendar/list"
import dayGridPlugin from "@fullcalendar/daygrid"
import multiMonthPlugin from "@fullcalendar/multimonth"
import rrulePlugin from "@fullcalendar/rrule"

import {
  IMaintenanceFragment,
  IWorkOrderDataViewFragment,
} from "@graphql/documents/fragments.generated"
import type {
  CalendarType,
  CalendarRange,
} from "@components/shared/data-view/data-view-types"
import { useTaskDataViewQuery } from "@graphql/documents/work-order.generated"
import { IMaintenanceTriggerTypeEnum } from "@elara/db"
import i18n from "@i18n"
import { useLocation, useNavigate, useOutletContext } from "react-router-dom"
import { LocationState } from "@utils/location"
import { useEffect, useMemo, useState } from "react"
import { formatDate } from "@utils/date"
import { add, startOfDay, startOfMonth, startOfWeek, sub } from "date-fns"

import { Button, Icons } from "@components/shared"
import ScheduledEventItem from "@components/maintenance/components/scheduled-event-item"
import TaskEventItem from "@components/work-order/task-event-item"
import { ToggleGroup, ToggleGroupItem } from "@components/shared/toggle-group"
import { EventInput } from "@fullcalendar/core"
import { useBreakpoint } from "@contexts/breakpoints"
import ScrollArea from "@components/shared/scroll-area"
import { getCalendarScheduledMaintenanceEvents } from "@utils/maintenance"

type CalendarHelpers = {
  dateString: string
  prev: () => void
  today: () => void
  next: () => void
}

type CalendarConfig = {
  cursorDate: Date
  type?: CalendarType
  range?: CalendarRange
}

const CalendarTab = () => {
  const { maintenance } = useOutletContext<{ maintenance: IMaintenanceFragment }>()
  const bp = useBreakpoint()
  const navigate = useNavigate()
  const location = useLocation()

  const [calendarConfig, setCalendarConfig] = useState<CalendarConfig>({
    cursorDate: startOfMonth(new Date()),
    type: "dayGrid",
    range: "Month",
  })
  const [calendarRef, setCalendarRef] = useState<FullCalendar | null>(null)
  const calendarApi = useMemo(() => calendarRef?.getApi(), [calendarRef])

  const [tasksRes] = useTaskDataViewQuery({
    variables: { where: { maintenance_id: { _eq: maintenance.id } } },
  })
  const tasks = tasksRes.data?.work_order ?? []

  const timeTriggers = maintenance.triggers.filter(
    (t) => t.type === IMaintenanceTriggerTypeEnum.Time
  )

  const calendarEvents = useMemo(() => {
    let key = "months" as keyof Duration
    if (calendarConfig.range === "Day") {
      key = "days"
    } else if (calendarConfig.range === "Week") {
      key = "weeks"
    }

    const range = {
      from: sub(calendarConfig.cursorDate, { [key]: 1 }),
      to: add(calendarConfig.cursorDate, { [key]: 2 }),
    }
    return tasks
      .map(
        (task) =>
          ({
            title: task.name,
            start: new Date(task.due_date!),
            extendedProps: { task },
          } as EventInput)
      )
      .concat(getCalendarScheduledMaintenanceEvents(timeTriggers, range.from, range.to))
  }, [tasks, timeTriggers])

  useEffect(() => {
    if (calendarApi) {
      const { type, range } = calendarConfig

      setTimeout(() => {
        if (bp.mobile) {
          calendarApi.changeView("listMonth")
        } else if (type && range) {
          calendarApi.changeView(`${type}${range}`)
        }
      }, 0)
    }
  }, [bp, calendarConfig.type, calendarConfig.range])

  const helpers: CalendarHelpers = useMemo(() => {
    if (!calendarApi || !calendarConfig) return {} as CalendarHelpers

    return {
      dateString: (() => {
        const date = calendarConfig.cursorDate
        let dateStr = ""

        if (calendarConfig.range === "Day") {
          dateStr = formatDate(date, "PPP")
        } else if (calendarConfig.range === "Week") {
          const week = formatDate(date, "w")
          const year = formatDate(date, "yyyy")

          dateStr = `${i18n.t("calendar:relative_dates.week_number", { week })}, ${year}`
        } else if (calendarConfig.range === "Month") {
          dateStr = formatDate(date, "MMMM yyyy")
        } else if (calendarConfig.range === "Year") {
          dateStr = formatDate(date, "yyyy")
        }

        return dateStr
      })(),
      prev: () => {
        let date = calendarConfig.cursorDate

        if (calendarConfig.range === "Day") {
          date = sub(startOfDay(date), { days: 1 })
        } else if (calendarConfig.range === "Week") {
          date = sub(startOfWeek(date), { weeks: 1 })
        } else if (calendarConfig.range === "Month") {
          date = sub(startOfMonth(date), { months: 1 })
        } else if (calendarConfig.range === "Year") {
          date = sub(startOfMonth(date), { years: 1 })
        }

        calendarApi.gotoDate(date)
        setCalendarConfig((conf) => ({ ...conf, cursorDate: date }))
      },
      today: () => {
        let date = new Date()

        calendarApi.gotoDate(date)
        setCalendarConfig((conf) => ({ ...conf, cursorDate: date }))
      },
      next: () => {
        let date = calendarConfig.cursorDate

        if (calendarConfig.range === "Day") {
          date = add(startOfDay(date), { days: 1 })
        } else if (calendarConfig.range === "Week") {
          date = add(startOfWeek(date), { weeks: 1 })
        } else if (calendarConfig.range === "Month") {
          date = add(startOfMonth(date), { months: 1 })
        } else if (calendarConfig.range === "Year") {
          date = add(startOfMonth(date), { years: 1 })
        }

        calendarApi.gotoDate(date)
        setCalendarConfig((conf) => ({ ...conf, cursorDate: date }))
      },
    }
  }, [calendarApi, calendarConfig])

  const onEventClick = (data: IWorkOrderDataViewFragment) => {
    navigate(`/task/${data.id}`, {
      state: {
        backgroundLocation:
          (location.state as LocationState)?.backgroundLocation || location,
      },
    })
  }

  return (
    <ScrollArea vertical viewportAsChild className="p-4">
      <header className="mb-3 grid grid-cols-2 items-center gap-y-3 sm:grid-cols-3">
        <div className="ml-auto flex grow basis-0 items-center sm:ml-0">
          <Button
            type="secondary"
            icon={Icons.LeftPrevious}
            onClick={helpers.prev}
            className="rounded-none rounded-l"
          />
          <Button
            type="secondary"
            onClick={helpers.today}
            className="rounded-none border-x-0">
            {i18n.t("calendar:relative_dates.today")}
          </Button>
          <Button
            type="secondary"
            icon={Icons.RightNext}
            onClick={helpers.next}
            className="rounded-none rounded-r"
          />
        </div>

        <div className="order-first sm:order-none sm:text-center">
          <h2 className="text-lg font-medium">{helpers.dateString}</h2>
        </div>

        <div className="hidden justify-self-end md:block">
          <ToggleGroup
            type="single"
            value={calendarConfig.range}
            onValueChange={(range: CalendarRange) => {
              let type: CalendarType = "dayGrid"

              if (!range) return
              if (range === "Day") type = "list"
              if (range === "Year") type = "multiMonth"

              setCalendarConfig((conf) => {
                if (conf.range === "Month" && range === "Week") {
                  const today = new Date()
                  if (today.getMonth() === conf.cursorDate.getMonth()) {
                    return { ...conf, type, range, cursorDate: today }
                  }
                }
                return { ...conf, type, range }
              })
            }}>
            <ToggleGroupItem value="Day" className="capitalize">
              {i18n.t("calendar:tokens.day", { count: 1 })}
            </ToggleGroupItem>
            <ToggleGroupItem value="Week" className="capitalize">
              {i18n.t("calendar:tokens.week", { count: 1 })}
            </ToggleGroupItem>
            <ToggleGroupItem value="Month" className="capitalize">
              {i18n.t("calendar:tokens.month", { count: 1 })}
            </ToggleGroupItem>
            <ToggleGroupItem value="Year" className="capitalize">
              {i18n.t("calendar:tokens.year", { count: 1 })}
            </ToggleGroupItem>
          </ToggleGroup>
        </div>
      </header>

      <FullCalendar
        firstDay={1}
        headerToolbar={false}
        locale={i18n.language}
        events={calendarEvents}
        eventInteractive={false}
        eventStartEditable={true}
        locales={[deLocale, enLocale]}
        height={`calc(100vh - 230px)`}
        dayCellClassNames="cursor-cell"
        ref={(ref) => setCalendarRef(ref)}
        initialDate={calendarConfig.cursorDate}
        dayMaxEvents={calendarConfig.range === "Month" && 3}
        initialView={`${calendarConfig.type}${calendarConfig.range}`}
        defaultTimedEventDuration={{ second: 1 }}
        plugins={[
          interactionPlugin,
          listPlugin,
          dayGridPlugin,
          multiMonthPlugin,
          rrulePlugin,
        ]}
        dayCellContent={(info) => (
          <span className="text-sm font-medium text-gray-700">{info.dayNumberText}</span>
        )}
        eventClick={(info) => {
          const { task } = info.event.extendedProps

          if (task?.id !== undefined) onEventClick(task)
        }}
        eventContent={(info) => {
          const { task, trigger } = info.event.extendedProps

          if (task?.id !== undefined) {
            return <TaskEventItem task={task} />
          }

          if (!trigger) return null

          return (
            <ScheduledEventItem
              maintenance={maintenance}
              trigger={trigger}
              date={info.event.start}
            />
          )
        }}
      />
    </ScrollArea>
  )
}

export default CalendarTab
