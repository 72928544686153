import { Button } from "@components/shared"
import toast from "@components/shared/toast"
import { useUser } from "@contexts/user-context"
import {
  ISubmitSetupInfoMutation,
  ISubmitSetupInfoMutationVariables,
  SubmitSetupInfoDocument,
} from "@graphql/documents/business.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useClient } from "urql"

export const ResetDemoPage = () => {
  const client = useClient()
  const navigate = useNavigate()
  const user = useUser()
  const scope = usePermissionScope(IPermissionScopeEnum.AppAccountManagement)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <div className="mt-20 flex flex-1 flex-col items-center space-y-6">
      <h1 className="text-2xl font-bold">Reset to default demo state</h1>
      <p className="text-sm text-gray-500">
        This will reset your account to the demo state. All data will be deleted.
      </p>
      <Button
        isLoading={isLoading}
        onClick={async () => {
          try {
            setIsLoading(true)
            const res = await client
              .mutation<ISubmitSetupInfoMutation, ISubmitSetupInfoMutationVariables>(
                SubmitSetupInfoDocument,
                {
                  info: {},
                  data: "demo",
                  keepUser: true,
                  locationId: user.location.id,
                },
                scope.context()
              )
              .toPromise()
            if (res.data?.setupDemoData) {
              navigate("/")
              setTimeout(() => {
                window.location.reload()
              }, 400)
            } else {
              toast.error("Wir haben ein Problem.")
            }
          } finally {
            setIsLoading(false)
          }
        }}>
        Reset
      </Button>
    </div>
  )
}

export default ResetDemoPage
