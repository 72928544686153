import { useMaintenancesCountQuery } from "@components/new-data-view/implementations/queries.generated"
import PageViewLayout from "@components/page/page-view-layout"
import { Button } from "@components/shared"
import { CalendarCheck, FileArrowUp } from "@phosphor-icons/react"
import { useRef, useState } from "react"
import Confetti from "react-confetti"
import { Link } from "react-router-dom"

import loadingGridSVG from "../../resources/animations/loading-grid.svg"

type Stage = "upload" | "processing" | "success"

export const MaintenanceAIParserPage = () => {
  const [stage, setStage] = useState<Stage>("upload")
  const [confetti, setConfetti] = useState<boolean>(true)

  const fileInputRef = useRef<HTMLInputElement>(null)

  const [maintenancesCountRes] = useMaintenancesCountQuery({ variables: { where: {} } })
  const maintenancesCount =
    maintenancesCountRes.data?.maintenance_aggregate.aggregate?.count ?? 0

  const onClick = () => {
    fileInputRef.current?.click()
  }

  const onFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (!file) return

    // Parse the file here
    setStage("processing")

    // Add a 5 second delay to simulate processing
    await new Promise((resolve) => setTimeout(resolve, 4000))

    // Redirect to the maintenance detail page
    setStage("success")
  }

  return (
    <PageViewLayout title="AI PDF Parser" className="relative">
      <div className="flex h-full items-center justify-center">
        {stage === "upload" && (
          <div
            onClick={onClick}
            className="w-[600px] cursor-pointer rounded border border-dashed border-gray-400 bg-transparent p-8 text-sm transition-colors hover:bg-gray-50">
            <div className="flex flex-col items-center justify-center gap-y-2">
              <input
                type="file"
                ref={fileInputRef}
                className="hidden"
                onChange={onFileUpload}
              />

              <FileArrowUp weight="thin" size={96} opacity={0.25} />

              <h2 className="text-lg font-bold tracking-tight">
                Upload a maintenance booklet to begin
              </h2>

              <p className="prose prose-sm prose-zinc max-w-none text-center">
                Click here to select a file, or drag and drop a PDF of an object's
                maintenance booklet to magically parse through it and create appropriate
                preventive maintenance schedules for it within seconds!
              </p>
            </div>
          </div>
        )}

        {stage === "processing" && (
          <div className="flex flex-col items-center justify-center gap-y-8">
            <img src={loadingGridSVG} width={96} height={96} />

            <h2 className="text-lg font-medium tracking-tight">
              Please wait while we're crunching some numbers...
            </h2>
          </div>
        )}

        {stage === "success" && (
          <div className="w-[600px] space-y-4 rounded border border-dashed border-gray-400 p-8 text-sm">
            <Confetti
              recycle={false}
              className="absolute inset-0"
              numberOfPieces={confetti ? 500 : 0}
              onConfettiComplete={(conf) => {
                conf?.reset()
                setConfetti(false)
              }}
            />

            <h2 className="font-semibold tracking-tight">
              Your PDF has been successfully parsed!
            </h2>

            <p className="prose prose-sm prose-zinc">
              There were a total of {maintenancesCount} preventive maintenance schedules
              detected in your PDF, these have been added to your Elara Dashboard for your
              convenience.
            </p>

            <Link to="/maintenance/views" className="block">
              <Button icon={CalendarCheck}>Go to Preventive Maintenance</Button>
            </Link>
          </div>
        )}
      </div>
    </PageViewLayout>
  )
}
