import {
  IMutationRootDeleteCustomViewByPkArgs,
  IMutationRootInsertViewCollectionOneArgs,
  IMutationRootInsertViewConfigOneArgs,
  IMutationRootUpdateUserFavoriteByPkArgs,
} from "@elara/db"
import {
  IViewConfigByTypeQuery,
  IViewConfigByTypeQueryVariables,
  IViewConfigFragment,
  ViewConfigByTypeDocument,
} from "@graphql/documents/custom-view.generated"
import { OptimisticMutationResolver, UpdateResolver } from "@urql/exchange-graphcache"

type Mutations = {
  [fieldName: string]: UpdateResolver
}
type Optimistic = {
  [fieldName: string]: OptimisticMutationResolver
}

const optimistic: Optimistic = {
  // insert_user_favorite_one(variables: IMutationRootInsertUserFavoriteOneArgs, cache) {
  //   return {
  //     __typename: "user_favorite",
  //     custom_view: cache.readFragment(
  //       gql`
  //         fragment _ on custom_view {
  //           __typename
  //           id
  //           name
  //         }
  //       `,
  //       { id: variables.object.custom_view_id }
  //     ),
  //     ...variables.object,
  //   }
  // },
  update_user_favorite_by_pk(vars: IMutationRootUpdateUserFavoriteByPkArgs) {
    return { __typename: "user_favorite", id: vars.pk_columns?.id!, ...vars._set }
  },
  insert_view_collection_one(vars: IMutationRootInsertViewCollectionOneArgs) {
    // generate random id
    return { __typename: "view_collection", id: Math.random().toString(), ...vars.object }
  },
}
const mutations: Mutations = {
  // Delete
  delete_custom_view_by_pk(_result, args: IMutationRootDeleteCustomViewByPkArgs, cache) {
    cache.invalidate({ __typename: "custom_view", id: args.id })
  },

  insert_view_config_one(
    result: { insert_view_config_one?: IViewConfigFragment },
    args: IMutationRootInsertViewConfigOneArgs,
    cache
  ) {
    cache.updateQuery<IViewConfigByTypeQuery, IViewConfigByTypeQueryVariables>(
      {
        query: ViewConfigByTypeDocument,
        variables: { configId: args.object.config_id! },
      },
      (data) => {
        const res = result.insert_view_config_one
        if (!data?.view_config || !res) return data

        if (!data.view_config.some((v) => v.id === res.id)) {
          data.view_config.push(res)
        }

        return data
      }
    )
  },
}

export default {
  mutations,
  optimistic,
}
