import { Center, Flex } from "@components/layout"
import SettingsPageLayout from "@components/settings/page-layout"
import PlaceEditCreateForm from "@components/settings/place-edit-create-form"
import { ActionMenu, Button, LoadingIndicator } from "@components/shared"
import { alertDialog } from "@components/shared/alert-dialog-provider"
import { usePage } from "@contexts/page-context"
import { uuid } from "@elara/db"
import { useOrderBy } from "@elara/select"
import {
  DeletePlaceDocument,
  IDeletePlaceMutation,
  IDeletePlaceMutationVariables,
  IPlaceFragment,
  usePlacesQuery,
} from "@graphql/documents/place.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { DotsThreeVertical, PencilSimpleLine, TrashSimple } from "@phosphor-icons/react"
import classNames from "classnames"
import { useRef, useState } from "react"
import { useClient } from "urql"

type State = { type: "new" } | { type: "edit"; id: uuid } | null

type LocationActionMenuProps = {
  onEdit: () => void
  onDelete: () => void
  inCreateMode?: boolean
}

export const LocationActionMenu = ({
  onEdit,
  onDelete,
  inCreateMode,
}: LocationActionMenuProps) => {
  const editLocationScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)
  const deleteLocationScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderDelete)

  if (!editLocationScope.hasScope && !deleteLocationScope.hasScope) return null
  return (
    <ActionMenu
      dropdownContentProps={{ side: "left" }}
      items={[
        editLocationScope.hasScope
          ? {
              key: "edit",
              icon: <PencilSimpleLine />,
              label: i18n.t("edit"),
              action: onEdit,
              disabled: inCreateMode,
            }
          : null,
        deleteLocationScope.hasScope
          ? {
              key: "delete",
              icon: <TrashSimple />,
              label: i18n.t("common:delete"),
              action: onDelete,
            }
          : null,
      ]}>
      <Button color="gray" size="small" type="tertiary" icon={DotsThreeVertical} />
    </ActionMenu>
  )
}

type LocationCardProps = {
  place: IPlaceFragment
  onEdit: (id: uuid) => void
  onDelete: (id: uuid) => void
  onCancel: (id: uuid) => void
  isEditing: boolean
}

const LocationCard = (props: LocationCardProps) => {
  return (
    <Flex
      row
      align="center"
      justify="space-between"
      className={classNames(
        "text-sm bg-gray-50 hover:text-black border border-gray-100 rounded cursor-pointer px-4 py-2 mb-2",
        { "!py-4": props.isEditing }
      )}>
      {props.isEditing ? (
        <PlaceEditCreateForm
          id={props.place.id}
          label={props.place.name}
          onCancel={() => props.onCancel(props.place.id)}
          afterSubmitSuccess={() => props.onCancel(props.place.id)}
        />
      ) : (
        <>
          <Flex row align="center">
            <span className="mx-1 text-sm">{props.place.name}</span>
            <span className="ml-2 text-gray-500">
              {props.place.assets_aggregate?.aggregate?.count}{" "}
              {i18n.t("asset", {
                count: props.place.assets_aggregate?.aggregate?.count ?? 0,
              })}
            </span>
          </Flex>
          <LocationActionMenu
            onEdit={() => props.onEdit(props.place.id)}
            onDelete={() => props.onDelete(props.place.id)}
          />
        </>
      )}
    </Flex>
  )
}

const SettingsLocations = () => {
  usePage({
    isSubPage: true,
    id: "settings/place",
    title: i18n.t("location", { count: 2 }),
  })

  const client = useClient()
  const [queryRes, fetchData] = usePlacesQuery({
    requestPolicy: "cache-and-network",
  })
  const [data] = useOrderBy(queryRes.data?.place ?? [], { name: "asc" })
  const deleteplacesScope = usePermissionScope(IPermissionScopeEnum.AppAssetArchiveDelete)
  const createScope = usePermissionScope(IPermissionScopeEnum.AppAssetCreate)

  const [state, setState] = useState<State>(null)

  const scrollRef = useRef<HTMLDivElement>(null)

  if (queryRes.fetching) {
    return (
      <Center flex="1">
        <LoadingIndicator size={24} />
      </Center>
    )
  }

  return (
    <SettingsPageLayout
      title={i18n.t("assets:fields.location", { count: 2 })}
      description={i18n.t("settings:places.description")}
      buttonProps={{
        onClick: () => {
          setState({ type: "new" })
          scrollRef.current?.scroll({ top: 0, behavior: "smooth" })
        },
      }}
      buttonText={
        createScope.hasScope
          ? i18n.t("common:new_token", {
              context: "male",
              token: i18n.t("assets:fields.location", { count: 1 }),
            })
          : undefined
      }>
      {state?.type === "new" && (
        <div className="mb-4 flex cursor-pointer items-center truncate rounded border border-gray-100 bg-gray-50 p-4 text-sm">
          <PlaceEditCreateForm
            onCancel={() => setState(null)}
            afterSubmitSuccess={() => setState(null)}
          />
        </div>
      )}

      {data.length > 0 ? (
        <ul role="list" className="list-none">
          {data.map((place) => (
            <li key={place.id} className="list-none">
              <LocationCard
                place={place}
                isEditing={state?.type === "edit" && state.id === place.id}
                onEdit={(id) => setState({ type: "edit", id })}
                onDelete={() =>
                  alertDialog({
                    title: i18n.t("settings:places.delete.title"),
                    danger: true,
                    description: i18n.t("settings:places.delete.description"),
                    actionText: i18n.t("common:delete"),
                    async onAction() {
                      await client
                        .mutation<IDeletePlaceMutation, IDeletePlaceMutationVariables>(
                          DeletePlaceDocument,
                          { id: place.id },
                          deleteplacesScope.context()
                        )
                        .toPromise()
                      await fetchData({ requestPolicy: "network-only" })
                    },
                  })
                }
                onCancel={() => setState(null)}
              />
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-sm">
          {i18n.t("common:no_token_created_yet", {
            token: i18n.t("assets:fields.location", { count: 2 }),
          })}
        </p>
      )}

      {/* {deleteplaceModal.component} */}
    </SettingsPageLayout>
  )
}

export default SettingsLocations
