import React, { Dispatch, SetStateAction } from "react"
import { useEffect, useState } from "react"

const DetectPrintContext = React.createContext<{
  isPrinting: boolean
  setIsPrinting: Dispatch<SetStateAction<boolean>>
}>({ isPrinting: false, setIsPrinting: () => {} })

export const DetectPrintProvider = (props: React.PropsWithChildren<{}>) => {
  const [isPrinting, setIsPrinting] = useState(false)

  useEffect(() => {
    const handleBeforeprint = () => {
      setIsPrinting(true)
    }
    const handleAfterprint = () => {
      setIsPrinting(false)
    }
    window.addEventListener("beforeprint", handleBeforeprint)
    window.addEventListener("afterprint", handleAfterprint)
    return () => {
      window.removeEventListener("beforeprint", handleBeforeprint)
      window.removeEventListener("afterprint", handleAfterprint)
    }
  }, [])

  return (
    <DetectPrintContext.Provider value={{ isPrinting, setIsPrinting }}>
      {props.children}
    </DetectPrintContext.Provider>
  )
}

export const useDetectPrint = () => {
  return React.useContext(DetectPrintContext).isPrinting
}

export const useTriggerPrint = () => {
  const { setIsPrinting } = React.useContext(DetectPrintContext)
  return () => {
    setIsPrinting(true)
    setTimeout(() => {
      window.print()
      setIsPrinting(false)
    }, 500)
  }
}
