import { naturalCompare } from "@utils"
import {
  downInTreeList,
  FilteredTreeLike,
  filterTreeList,
  flattenTreeList,
  forEachTreeList,
  mapTreeList,
  sortTreeList,
  Tree,
  TreeLike,
  treeListExtended,
  upInTreeList,
} from "@utils/tree"
import { matchSorter } from "match-sorter"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"

import useCallbackRef from "./use-callback-ref"

export type NodeDisclosure = {
  open: () => void
  close: () => void
  toggle: () => void
  isOpen: boolean
}

export type UseTreeListReturn<Item> = {
  treeList: FilteredTreeLike<Tree<Item>>[]
  flatList: Tree<Item>[]
  navigate: {
    up(index: number): number
    down(index: number): number
    right(index: number): number
    left(index: number): number
  }
  resetOpenCloseState: () => void
  openCloseState: Record<string, boolean>
  setOpenCloseState: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
  getNodeDisclosure: (item: TreeLike<Item>) => NodeDisclosure
  isNodeOpen: (item: TreeLike<Item>) => boolean
  useOpenCloseKeyboardHandler: (
    currentIndex: number,
    setCurrentIndex: (index: number) => void
  ) => void
}

export function useTreeList<Item>(
  givenTreeList: TreeLike<Item>[],
  options: {
    key: (item: Item) => string
    searchValue: (item: Item) => string
    initialOpenCloseState?: Record<string, boolean>
    searchInput?: string
  }
): UseTreeListReturn<Item> {
  const { initialOpenCloseState = {}, searchInput = "", searchValue } = options
  const key = useCallbackRef(options.key)

  // Open and Closing state logic
  const [openCloseState, setOpenCloseState] = useState(initialOpenCloseState)
  const open = useCallback((item: Item) => {
    setOpenCloseState((items) => ({ ...items, [key(item)]: true }))
  }, [])
  const close = useCallback((item: TreeLike<Item>) => {
    setOpenCloseState((items) => {
      const update: Record<string, boolean> = { [key(item)]: false }
      if (item.children) {
        forEachTreeList(item.children, (child) => {
          update[key(child)] = false
        })
      }
      return { ...items, ...update }
    })
  }, [])
  const toggle = useCallback((item: TreeLike<Item>) => {
    setOpenCloseState((items) => {
      if (items[key(item)]) {
        const update: Record<string, boolean> = { [key(item)]: false }
        if (item.children) {
          forEachTreeList(item.children, (child) => {
            update[key(child)] = false
          })
        }
        return { ...items, ...update }
      }
      return { ...items, [key(item)]: true }
    })
  }, [])
  const isNodeOpen = useCallback(
    (item: Item) => !!openCloseState[key(item)],
    [openCloseState]
  )
  const getNodeDisclosure = useCallback(
    (item: TreeLike<Item>) => ({
      open: () => open(item),
      close: () => close(item),
      toggle: () => toggle(item),
      isOpen: isNodeOpen(item),
    }),
    [isNodeOpen]
  )

  const resetOpenCloseState = useCallback(() => {
    setOpenCloseState({})
  }, [])

  const treeList = useMemo(() => treeListExtended(givenTreeList), [givenTreeList])
  const flatList = useMemo(() => flattenTreeList(treeList), [treeList])

  const filteredTreeList = useMemo(() => {
    if (!searchInput) {
      // Just return the "filtered" list with all items included
      return filterTreeList(treeList, () => true)
    }
    // Given a search value we do not only want to filter the matches but also return
    // the items in an order where the "best" match is at the top.
    // We use the match-sorter package to do the filter and ordering work for us.
    // But we have to do some additional work since we have a tree structure and not
    // a flat list.
    // However, we start with applying `matchSorter` to our flattened list

    // We support multi word search by splitting words
    const terms = searchInput.split(" ")
    const matches = terms.reduceRight(
      (matches, term) =>
        matchSorter(matches, term, {
          keys: [searchValue],
          baseSort: (a, b) => naturalCompare(a.rankedValue, b.rankedValue),
        }),
      flatList
    )
    // const matches = matchSorter(flatList, searchInput,)
    // Given our matches in best-match order, as a first step we filter our tree
    // to only include our matches and their parents
    let matchPrioMap = new Map(matches.map((match, index) => [match.index, index + 1]))
    const filtered = filterTreeList(treeList, (t) => matchPrioMap.has(t.index))

    // Now, we need to decide what this means for our tree structure.
    // We want that the best match still appears at the first item.
    // However, this could a deep child node.
    // The idea is to use the `path` of a node. We go through our matches in order
    // and let's say the best match has a path of [4,2,5].
    // Then we store in `matchSortMap` that `4-2-5`, `4-2` and `4` have the highest prio.
    // Using this technique our best match node as well as all parents have the highest prio.

    let matchSortMap: Record<string, number> = {}
    matches.forEach((match, index) => {
      for (let k = 0; k < match.path.length; k++) {
        const id = match.path.slice(0, k + 1).join("-")
        if (matchSortMap[id] === undefined) {
          matchSortMap[id] = matches.length - index
        }
      }
    })
    const getMatchPrio = (a: Tree<Item>) => matchSortMap[a.path.join("-")] ?? 0
    // Finally apply the sorting
    const sorted = sortTreeList(filtered, (a, b) => getMatchPrio(b) - getMatchPrio(a))

    return sorted
  }, [treeList, searchInput])

  // Now that we changed the filtered tree list, we need to reset the openCloseState
  // and open all nodes to show our matches
  // Don't run the effect on mount though
  const lastSearchInput = useRef(searchInput)
  useEffect(() => {
    if (lastSearchInput.current === searchInput) {
      return
    }
    lastSearchInput.current = searchInput

    if (!searchInput) {
      if (Object.entries(openCloseState).length > 0) {
        resetOpenCloseState()
      }
      return
    }
    const openNodes = {} as Record<string, boolean>
    forEachTreeList(filteredTreeList, (tree) => {
      if (
        tree.children?.length &&
        (tree.nodeIsPredicateMatch || tree.childHasPredicateMatch)
      ) {
        openNodes[key(tree)] = true
      }
    })
    setOpenCloseState(openNodes)
  }, [filteredTreeList])

  const navigateList = useMemo(
    () =>
      treeListExtended(
        mapTreeList(filteredTreeList, (t) => ({
          ...t,
          originalIndex: t.index,
        })) as TreeLike<Item & { originalIndex: number }>[]
      ),
    [filteredTreeList]
  )
  const flatNavigateList = useMemo(() => flattenTreeList(navigateList), [navigateList])

  // Add logic for keyboard navigation in tree list
  const navigate = useMemo(() => {
    const treeIndexToNavigateNode = (index: number) => {
      return flatNavigateList.find((n) => n.originalIndex === index)
    }
    return {
      up(index: number) {
        const node = treeIndexToNavigateNode(index)
        if (!node) return index
        let upNode = upInTreeList(navigateList, node)
        if (!(upNode.index === node.index - 1)) {
          while (upNode.children?.length && openCloseState[key(upNode)]) {
            upNode = upNode.children[upNode.children.length - 1]
          }
        }
        return upNode.originalIndex
      },
      down(index: number) {
        const node = treeIndexToNavigateNode(index)
        if (!node) return index
        const firstChild = node.children?.[0]
        if (firstChild && isNodeOpen(node)) {
          return firstChild.originalIndex
        } else {
          const nextNodeDown = downInTreeList(navigateList, node)
          return nextNodeDown.originalIndex
        }
      },
      right(index: number) {
        const node = treeIndexToNavigateNode(index)
        if (!node) return index
        if (node.children?.length) {
          if (!isNodeOpen(node)) {
            open(node)
          } else {
            return node.children[0].originalIndex
          }
        }
        return index
      },
      left(index: number) {
        const node = treeIndexToNavigateNode(index)
        if (!node) return index
        if (node.children?.length && isNodeOpen(node)) {
          close(node)
        } else if (node.parent) {
          return node.parent.originalIndex
        }
        return index
      },
    }
  }, [navigateList, flatNavigateList, openCloseState])

  const useOpenCloseKeyboardHandler = (
    currentIndex: number,
    setCurrentIndex: (index: number) => void
  ) => {
    useEffect(() => {
      const handler = (e: KeyboardEvent) => {
        if (e.key === "ArrowRight") {
          setCurrentIndex(navigate.right(currentIndex))
        } else if (e.key === "ArrowLeft") {
          setCurrentIndex(navigate.left(currentIndex))
        }
      }
      document.addEventListener("keydown", handler)
      return () => {
        document.removeEventListener("keydown", handler)
      }
    }, [navigate, currentIndex])
  }

  return {
    treeList: filteredTreeList,
    flatList,
    navigate,
    getNodeDisclosure,
    isNodeOpen,
    resetOpenCloseState,
    setOpenCloseState,
    openCloseState,
    useOpenCloseKeyboardHandler,
  }
}
