import PageViewLayout from "@components/page/page-view-layout"
import { Button } from "@components/shared"
import HorizontalPills from "@components/shared/horizontal-pills"
import Tabs from "@components/shared/horizontal-tabs"
import { useFeature } from "@contexts/feature-flag-context"
import { usePage } from "@contexts/page-context"
import { useLocation } from "@contexts/user-context"
import i18n from "@i18n"
import { FilePdf } from "@phosphor-icons/react"
import { DateRange, formatDate } from "@utils/date"
import { getImageProps, resize } from "@utils/image"
import clsx from "clsx"
import { toPng } from "html-to-image"
import jsPDF from "jspdf"
import { useRef, useState } from "react"
import { Outlet, useMatch, useNavigate } from "react-router-dom"

export type RefPayload = {
  ref: HTMLDivElement | null
  dateRange: DateRange | null
}

enum MainTab {
  "Tasks" = "tasks",
  "Objects" = "objects",
  "Consumables" = "consumables",
}

enum SubTab {
  // Tasks
  "TaskActive" = "active",
  "TaskCompletion" = "completed",
  "TaskReporting" = "reporting",
  // Objects
  "AssetDowntime" = "downtime",
  "AssetReliability" = "reliability",
  // Consumables
  "ConsumableAdjustments" = "adjustments",
}

const pageTitleMap: Record<SubTab, string> = {
  [SubTab.TaskActive]: "Active Tasks",
  [SubTab.TaskCompletion]: "Completed Tasks",
  [SubTab.TaskReporting]: "Reported Hours",
  [SubTab.AssetDowntime]: "Object Downtime",
  [SubTab.AssetReliability]: "Object Reliability",
  [SubTab.ConsumableAdjustments]: "Material Adjustments",
}

const AnalyticsPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const pageRef = useRef<RefPayload>(null)
  const hasMaterialsFeature = useFeature("materials")

  const [isPrinting, setPrinting] = useState(false)

  const match = useMatch("/analytics/:category/:page")
  const category = (match?.params.category ?? MainTab.Tasks) as MainTab
  const page = (match?.params.page ?? SubTab.TaskActive) as SubTab

  usePage({
    id: "analytics",
    isSubPage: true,
    title: pageTitleMap[page],
  })

  const printPDF = async () => {
    if (!pageRef.current?.ref || !pageRef.current?.dateRange) return

    setPrinting(true)

    let { width, height } = resize(
      pageRef.current?.ref.offsetWidth,
      pageRef.current?.ref.offsetHeight,
      800,
      600
    )
    const imageUrl = await toPng(pageRef.current?.ref, {
      pixelRatio: 2.5,
      canvasWidth: width,
      canvasHeight: height,
    })
    const { naturalWidth, naturalHeight } = await getImageProps(imageUrl)
    const orientation = naturalWidth > naturalHeight ? "landscape" : "portrait"
    const margin = 100
    const dateRange = (() => {
      if (pageRef.current?.dateRange?.end) {
        return (
          formatDate(pageRef.current?.dateRange.start, "PPP") +
          " - " +
          formatDate(pageRef.current?.dateRange.end, "PPP")
        )
      }

      return formatDate(pageRef.current?.dateRange?.start, "PPP")
    })()
    const logo = location.logo?.url

    const title = i18n.t("analytics:report.heading_title", {
      title: pageTitleMap[page],
      dateRange,
    })
    const timestamp = i18n.t("analytics:report.heading_timestamp", {
      timestamp: formatDate(new Date(), "PPPpp"),
    })

    const pdf = new jsPDF({
      unit: "px",
      orientation,
      compress: true,
      format: [naturalWidth + margin * 2, naturalHeight + margin * 3],
    })

    // Config
    pdf.setFontSize(28)
    pdf.setFillColor("#f3f4f6")
    pdf.setTextColor("#1f2937")

    // Header
    pdf.rect(0, 0, pdf.internal.pageSize.width, 80, "F")
    pdf.addImage("/images/logo.png", "PNG", margin, 20, 32, 32)
    pdf.text(title, margin + 50, 46)
    if (orientation === "landscape")
      pdf.text(timestamp, naturalWidth + margin, 46, { align: "right" })

    // Report Canvas
    pdf.addImage(imageUrl, "PNG", margin, margin + 50, naturalWidth, naturalHeight)

    // Footer
    pdf.setFillColor("#f3f4f6")
    pdf.rect(0, pdf.internal.pageSize.height - 80, pdf.internal.pageSize.width, 80, "F")
    if (logo) pdf.addImage(logo, "PNG", margin, pdf.internal.pageSize.height - 58, 32, 32)
    pdf.text(location.name, logo ? margin + 50 : margin, pdf.internal.pageSize.height - 36)

    pdf.save(`elara-report_${category}-${page}_${formatDate(new Date(), "ddMMyy")}`)

    setPrinting(false)
  }

  const CategoryPicker = () => {
    return (
      <div className="sm:mr-auto">
        <HorizontalPills.Root
          value={category}
          onValueChange={(category) => navigate(category)}>
          <HorizontalPills.List>
            <HorizontalPills.Trigger value={MainTab.Tasks}>
              {i18n.t("common:task", { count: 2 })}
            </HorizontalPills.Trigger>
            <HorizontalPills.Trigger value={MainTab.Objects}>
              {i18n.t("common:asset", { count: 2 })}
            </HorizontalPills.Trigger>
            {hasMaterialsFeature && (
              <HorizontalPills.Trigger value={MainTab.Consumables}>
                {i18n.t("common:consumable", { count: 2 })}
              </HorizontalPills.Trigger>
            )}
          </HorizontalPills.List>
        </HorizontalPills.Root>

        <div className="absolute inset-y-0 right-4 my-3 hidden md:block">
          <Button icon={FilePdf} type="secondary" onClick={printPDF}>
            {i18n.t("analytics:actions.generate_report")}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <PageViewLayout title={i18n.t("analytics:title")} action={<CategoryPicker />}>
      <Tabs.Root value={page} onValueChange={(page) => navigate(`${category}/${page}`)}>
        {category === MainTab.Tasks && (
          <Tabs.List>
            <Tabs.Trigger value={SubTab.TaskActive}>
              {i18n.t("analytics:tabs.task_active")}
            </Tabs.Trigger>
            <Tabs.Trigger value={SubTab.TaskCompletion}>
              {i18n.t("analytics:tabs.task_completion")}
            </Tabs.Trigger>
            <Tabs.Trigger value={SubTab.TaskReporting}>
              {i18n.t("analytics:tabs.task_reporting")}
            </Tabs.Trigger>
          </Tabs.List>
        )}

        {category === MainTab.Objects && (
          <Tabs.List>
            <Tabs.Trigger value={SubTab.AssetDowntime}>
              {i18n.t("analytics:tabs.asset_downtime")}
            </Tabs.Trigger>
            {/* <Tabs.Trigger value={SubTab.AssetReliability}>
              {i18n.t("analytics:tabs.asset_reliability")}
            </Tabs.Trigger> */}
          </Tabs.List>
        )}
        {hasMaterialsFeature && category === MainTab.Consumables && (
          <Tabs.List>
            <Tabs.Trigger value={SubTab.ConsumableAdjustments}>
              {i18n.t("analytics:tabs.consumable_adjustments")}
            </Tabs.Trigger>
          </Tabs.List>
        )}
      </Tabs.Root>

      <div
        className={clsx("group flex min-h-0 min-w-0 flex-1 flex-col", {
          "is-printing": isPrinting,
        })}>
        <Outlet context={pageRef} />
      </div>
    </PageViewLayout>
  )
}

export default AnalyticsPage
