import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
/**
 * Create a throttled version of  function
 *
 * @param cb The callback that should be throttled
 * @param wait The throttling period in ms i.e., the callback is invoked at most every `wait` miliseconds
 * @returns A throttled version of `cb`
 */
function throttle(fn: Function, wait: number = 300) {
  let inThrottle: boolean, lastFn: ReturnType<typeof setTimeout>, lastTime: number
  return function (this: any) {
    const context = this,
      args = arguments
    if (!inThrottle) {
      fn.apply(context, args)
      lastTime = Date.now()
      inThrottle = true
    } else {
      clearTimeout(lastFn)
      lastFn = setTimeout(() => {
        if (Date.now() - lastTime >= wait) {
          fn.apply(context, args)
          lastTime = Date.now()
        }
      }, Math.max(wait - (Date.now() - lastTime), 0))
    }
  }
}

export type StickyType = "localStorage" | "global" | "component"

// We load the state only once from local storage to avoid too many writes
const STATE_KEY = "ELARA_STICKY_STATE"

let globalState: Record<string, unknown> = {}
let localStorage: Record<string, unknown> = {}
try {
  const serializedState = window.localStorage?.getItem(STATE_KEY)
  if (serializedState) {
    localStorage = JSON.parse(serializedState) as Record<string, unknown>
  }
} catch {}

// Make sure to not write too often to local storage
const persistToLocalStorage = throttle(() => {
  window.localStorage?.setItem(STATE_KEY, JSON.stringify(localStorage))
}, 500)

/**
 * Similar to `useState` but the value is either persisted in local storage (if `sticky === localStorage`) or globally in memory (`sticky === global`).
 *
 * @param defaultValue The default value used when the id not found in storage resp. memory
 * @param id The unique identifier.
 * @param sticky: `"localStorage" | "global"` determine how sticky the storage is.
 * @returns [state, setState]
 */
export function useStickyState<T>(
  defaultValue: T,
  id: string,
  sticky: StickyType = "localStorage",
  options: {
    mergeStickyAndDefault?: boolean
  } = {}
): [T, Dispatch<SetStateAction<T>>] {
  const init = () => {
    let stickyValue: T | undefined = defaultValue
    if (sticky !== "component") {
      stickyValue = globalState[id] as T | undefined
    }
    if (sticky === "localStorage" && typeof stickyValue === "undefined") {
      stickyValue = localStorage[id] as T | undefined
    }
    return stickyValue !== undefined
      ? options.mergeStickyAndDefault
        ? { ...defaultValue, ...stickyValue }
        : stickyValue
      : defaultValue
  }
  const [value, setValue] = useState<T>(init)

  const lastId = useRef(id)
  useEffect(() => {
    if (lastId.current === id) {
      if (sticky !== "component") {
        globalState[id] = value
      }
      if (sticky === "localStorage") {
        localStorage[id] = value
        persistToLocalStorage()
      }
    } else {
      setValue(init())
    }
    lastId.current = id
  }, [id, value, sticky])

  return [value, setValue]
}

export function setStickyStateValue(key: string, value: any) {
  globalState[key] = value
  localStorage[key] = value
  persistToLocalStorage()
}

export const clearStickyState = () => {
  window.localStorage?.removeItem(STATE_KEY)
}
