import {
  createSchema,
  createSchemaStatement,
  generalSelectStateToWhere,
  selectStateToWhere,
} from "@components/shared/data-view/data-view-filter.hooks"
import { useDataViewFilterDatePicker } from "@components/shared/data-view/data-view-filter-date-picker"
import { StaticRichText } from "@components/shared/static-rich-text"
import { TeamTag } from "@components/shared/team-tag"
import {
  genericAssets,
  pastRelativeDate,
} from "@components/work-order/work-order-data-view-filter-configuration"
import WorkOrderTag from "@components/work-order/work-order-tag"
import { Exact, IViewDataTypeEnum } from "@elara/db"
import { uuid } from "@elara/db"
import { orderBy, Where } from "@elara/select"
import { useActivityFeedDataViewQuery } from "@graphql/documents/asset.generated"
import {
  AssetGroupsDocument,
  IAssetGroupsQuery,
} from "@graphql/documents/asset-group.generated"
import {
  IAssetStateDataViewFragment,
  IConsumableLogFragment,
} from "@graphql/documents/fragments.generated"
import {
  IUserTagFragment,
  IWorkOrderDataViewFragment,
  IWorkOrderReportFragment,
} from "@graphql/documents/fragments.generated"
import { IAssetTagFragment } from "@graphql/documents/fragments.generated"
import { useCallbackRef, useDeepMemo } from "@hooks"
import i18n from "@i18n"
import { CheckCircle, ClockCounterClockwise, Rows, Shapes } from "@phosphor-icons/react"
import { format } from "date-fns"
import React, { useCallback, useMemo, useState } from "react"
import { Trans } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"

import {
  Column,
  DataView,
  DataViewConfiguration,
  DataViewProps,
} from "../shared/data-view/data-view"
import { AssetStateDetailDialog } from "./asset-state-detail"
import AssetStateVariantLabel from "./asset-state-variant-label"
import { AssetWithParents } from "./asset-with-parents"

export type AssetActivityOptions = {}

type AssetActivityFeedItem = {
  timestamp: string
  asset: IAssetTagFragment
  user: IUserTagFragment | null
  searchValue: string
} & (
  | { type: "task_created"; task: IWorkOrderDataViewFragment }
  | { type: "task_completed"; task: IWorkOrderDataViewFragment }
  | { type: "state_change"; state: IAssetStateDataViewFragment }
  | { type: "work_report"; report: IWorkOrderReportFragment }
  | { type: "consumable_log"; consumable_log: IConsumableLogFragment }
)

export type AssetActivityIds = "timestamp" | "object" | "activity"

const useAssetActivityFeedItems = (props: { assetId: uuid; sinceTimestamp: string }) => {
  const [queryRes] = useActivityFeedDataViewQuery({
    requestPolicy: "cache-and-network",
    variables: {
      sinceTimestamp: props.sinceTimestamp,
      assetWhere: {
        _or: [{ id: { _eq: props.assetId } }, { parent_ids: { _has_key: props.assetId } }],
      },
    },
  })

  const isValidAsset = (asset: IAssetTagFragment) =>
    props.assetId
      ? asset.id === props.assetId ||
        ((asset.parent_ids as string[])?.includes(props.assetId) ?? false)
      : true

  if (!queryRes.data?.work_order || !queryRes?.data?.asset_state_log) return null
  const feed: AssetActivityFeedItem[] = []

  queryRes.data?.work_order?.forEach((task) => {
    for (const { asset } of task.assets) {
      if (!isValidAsset(asset)) continue

      if (!task.completed_at || task.completed_at > task.created_at) {
        feed.push({
          type: "task_created",
          asset,
          task,
          timestamp: task.created_at,
          user: task.created_by,
          searchValue: task.name + " " + task.description + " " + asset.search_value,
        })
      }

      if (task.completed_at) {
        feed.push({
          type: "task_completed",
          asset,
          task,
          timestamp: task.completed_at,
          user: task.completed_by,
          searchValue: task.name + " " + task.description + " " + asset.search_value,
        })
      }
    }
  })

  queryRes.data?.asset_state_log?.forEach((state) => {
    feed.push({
      type: "state_change",
      asset: state.asset,
      user: state.created_by,
      timestamp: state.started_at,
      state: state,
      searchValue: (state.note ?? "") + " " + state.asset.search_value,
    })
  })

  queryRes?.data?.consumable_log?.forEach((log) => {
    if (log.asset) {
      feed.push({
        type: "consumable_log",
        timestamp: log.created_at,
        consumable_log: log,
        asset: log.asset,
        user: log.created_by,
        searchValue: log.consumable?.name + " " + log.asset?.search_value ?? "",
      })
    }
  })

  feed.sort((a, b) => b.timestamp.localeCompare(a.timestamp))
  return feed
}

export const AssetActivityFeedItemElement = (props: {
  feedItem: AssetActivityFeedItem
  headerHeight?: number
}) => {
  const { feedItem } = props

  switch (feedItem.type) {
    case "task_created":
    case "task_completed":
      return (
        <>
          <div className="min-w-0 flex-1 space-y-1">
            <div
              className="flex h-5 items-center text-sm font-semibold text-gray-900"
              style={{ height: props.headerHeight }}>
              {feedItem.type === "task_created"
                ? i18n.t("tasks:task_created")
                : i18n.t("tasks:task_completed")}{" "}
              <span className="mx-1 text-gray-500">{i18n.t("common:by")}</span>{" "}
              {feedItem.user?.first_name} {feedItem.user?.last_name}
            </div>
            <div className="-my-1 -ml-2">
              <WorkOrderTag workOrder={feedItem.task} singleLine hideStatus />
            </div>
            <StaticRichText
              className="line-clamp-2 text-gray-600"
              content={feedItem.task.description ?? ""}
            />
            <div className="space-y-2">
              {feedItem.task.categories.length > 0 && (
                <div className="flex flex-wrap gap-1 text-xs">
                  {feedItem.task.categories.map((c) => (
                    <div
                      className="inline-flex items-center rounded-full border border-gray-200 px-1 py-0.5 font-medium text-gray-600"
                      key={c.work_order_category_id}>
                      <div
                        className="mr-1 h-2.5 w-2.5 shrink-0 rounded-full"
                        style={{ backgroundColor: c.category.color ?? "black" }}
                      />
                      {c.category.label}
                    </div>
                  ))}
                </div>
              )}
              {feedItem.task.assigned_teams.length > 0 && (
                <div className="flex flex-wrap gap-1 text-xs text-gray-600">
                  {feedItem.task.assigned_teams.map((t) => (
                    <div
                      className="inline-block rounded border border-gray-200 px-1 py-0.5 font-medium"
                      key={t.team_id}>
                      <TeamTag team={t.team} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </>
      )

    case "state_change":
      return (
        <>
          <div className="min-w-0 flex-1 space-y-1">
            <div className="flex h-5 items-center" style={{ height: props.headerHeight }}>
              <Trans
                i18n={i18n}
                i18nKey="assets:labels.state_updated"
                className="flex text-sm font-medium">
                Status aktualisiert auf
                <AssetStateVariantLabel
                  variant={feedItem.state.asset_state_variant}
                  className="ml-1"
                />
              </Trans>
            </div>
            <div className="line-clamp-2 text-sm text-gray-600">{feedItem.state.note}</div>
          </div>
        </>
      )
    case "consumable_log":
      return (
        <>
          <div className="min-w-0 flex-1 space-y-1">
            <div
              className="flex h-5 flex-wrap items-center gap-x-1 gap-y-2"
              style={{ height: props.headerHeight }}>
              <span className="text-sm font-medium">
                {i18n.t("common:consumable", { count: 1 })}
              </span>
              <Link
                to={"/consumable/" + feedItem.consumable_log?.consumable?.id}
                className="-my-1 rounded p-1 text-sm font-medium hover:bg-gray-100">
                <span className="text-gray-600">
                  {feedItem.consumable_log.consumable?.name}
                </span>
                :
              </Link>
              <div>
                <span className="font-medium">
                  {feedItem.consumable_log.adjustment < 0 ? (
                    <span className="text-orange-600">
                      -{Math.abs(feedItem.consumable_log.adjustment)}{" "}
                      {feedItem.consumable_log.consumable?.unit}
                    </span>
                  ) : (
                    <span className="text-green-600">
                      +{Math.abs(feedItem.consumable_log.adjustment)}{" "}
                      {feedItem.consumable_log.consumable?.unit}
                    </span>
                  )}
                </span>
              </div>
            </div>
            {feedItem.consumable_log?.task && (
              <div className="-my-1 -ml-2">
                <WorkOrderTag
                  workOrder={feedItem.consumable_log?.task}
                  singleLine
                  hideStatus
                />
              </div>
            )}
          </div>
        </>
      )

    default:
      return null
  }
}
export const AssetActivityFeedItemIcon = (props: { feedItem: AssetActivityFeedItem }) => {
  const { feedItem } = props

  switch (feedItem.type) {
    case "task_created":
      return <Rows size={20} className="text-gray-500" />
    case "task_completed":
      return (
        <div className="relative isolate">
          <Rows size={20} />
          <div className="absolute -bottom-1 -right-1 z-10 rounded-full bg-gray-100 text-green-600">
            <CheckCircle size={14} />
          </div>
        </div>
      )
    case "state_change":
      return <ClockCounterClockwise size={20} className="shrink-0 text-gray-500" />
    case "consumable_log":
      return <Shapes size={20} className="shrink-0 text-gray-500" />
    default:
      return null
  }
}

export const columns: Column<
  AssetActivityFeedItem,
  AssetActivityIds,
  AssetActivityOptions
>[] = [
  {
    Header: i18n.t("common:timestamp"),
    id: "timestamp",
    defaultWidth: 180,
    orderBy: (dir) => ({ timestamp: dir }),
    toText: (feedItem) => format(new Date(feedItem.timestamp), "Pp"),
    Cell: (feedItem) => (
      <div className="flex min-w-0">
        <div className="flex h-8 min-w-0 items-center self-start truncate text-sm tabular-nums text-gray-600">
          <span className="font-medium">{format(new Date(feedItem.timestamp), "P")}</span>
          <span className="truncate">, {format(new Date(feedItem.timestamp), "p")}</span>
        </div>
      </div>
    ),
  },
  {
    Header: i18n.t("common:asset", { count: 1 }),
    id: "object",
    defaultWidth: 350,
    orderBy: (dir) => ({ asset: { name: dir } }),
    groupBy: {
      id: (row) => (row.asset?.id ? [row.asset?.id] : null),
      label: (_, row) =>
        row?.asset?.name ??
        i18n.t("common:without_token", { token: i18n.t("common:asset", { count: 1 }) }),
    },
    toText: (feedItem) => feedItem.asset?.name ?? "",
    Cell: (feedItem) => (
      <div
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}>
        <AssetWithParents
          asset={feedItem.asset}
          showAvatar
          withLink
          linkToSubpage="activity"
        />
      </div>
    ),
  },
  {
    Header: i18n.t("common:activity", { count: 1 }),
    id: "activity",
    defaultWidth: 500,
    toText: (feedItem) => i18n.t(`tasks:${feedItem.type}`),
    Cell: (feedItem) => (
      <div className="flex min-w-0 grow-[2] basis-80">
        <div className="mr-3 flex h-8 w-8 shrink-0 items-center justify-center rounded bg-gray-100 text-gray-700">
          <AssetActivityFeedItemIcon feedItem={feedItem} />
        </div>
        <div className="flex flex-col space-y-2">
          <div className="max-w-lg">
            <AssetActivityFeedItemElement feedItem={feedItem} headerHeight={32} />
          </div>
        </div>
      </div>
    ),
  },
]

export const ListItem = (feedItem: AssetActivityFeedItem) => (
  <div className="flex flex-wrap gap-4 py-2">
    <div className="min-w-0 grow basis-80">
      <div className="flex h-5 shrink-0 items-center self-start text-sm tabular-nums text-gray-600">
        <span className="font-medium">{format(new Date(feedItem.timestamp), "P")}</span>
        <span>, {format(new Date(feedItem.timestamp), "p")}</span>
      </div>
      <div
        className="mt-2"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}>
        <AssetWithParents
          asset={feedItem.asset}
          showAvatar
          withLink
          linkToSubpage="activity"
        />
      </div>
    </div>
    <div className="flex min-w-0 grow-[2] basis-80">
      <div className="mr-3 flex h-5 w-5 shrink-0 items-center justify-center rounded bg-gray-100 text-gray-700">
        <AssetActivityFeedItemIcon feedItem={feedItem} />
      </div>
      <div className="flex flex-col space-y-2">
        <div className="max-w-lg">
          <AssetActivityFeedItemElement feedItem={feedItem} />
        </div>
      </div>
    </div>
  </div>
)

type AssetActivityDataViewProps = Omit<
  DataViewProps<AssetActivityFeedItem, AssetActivityIds, AssetActivityOptions>,
  | "columnDefinitions"
  | "dataType"
  | "list_item"
  | "defaultConfig"
  | "filter"
  | "onSelect"
  | "dataId"
  | "data"
  | "dataSearchValue"
> &
  Partial<
    Pick<
      DataViewProps<AssetActivityFeedItem, AssetActivityIds, AssetActivityOptions>,
      "defaultConfig"
    >
  > & {
    assetId?: uuid
    defaultHistoryLength?:
      | { days: 1 }
      | { days: 7 }
      | { months: 1 }
      | { months: 12 }
      | Exact<{}>
    maxHistory?: "7d" | "all"
  }

const useFilterSchema = () => {
  const datePicker = useDataViewFilterDatePicker()
  const schema = useMemo(() => {
    return createSchema<AssetActivityFeedItem>({
      itemType: createSchemaStatement<
        AssetActivityFeedItem,
        "task_created" | "task_completed" | "work_report" | "state_change"
      >({
        type: "select",
        label: i18n.t("common:activity", { count: 1 }),
        multiSelectedLabel: i18n.t("common:activity", { count: 2 }),
        toWhere: (state) => ({ type: selectStateToWhere(state) } as any),
        valueToString: (v) => v,
        getItems: async () => {
          return [
            {
              label: i18n.t("tasks:task_created"),
              searchValue: i18n.t("tasks:task_created"),
              value: "task_created",
            },
            {
              label: i18n.t("tasks:task_completed"),
              searchValue: i18n.t("tasks:task_completed"),
              value: "task_completed",
            },
            {
              label: i18n.t("tasks:types.report"),
              searchValue: i18n.t("tasks:types.report"),
              value: "work_report",
            },
            {
              label: i18n.t("tasks:types.state_change"),
              searchValue: i18n.t("tasks:types.state_change"),
              value: "state_change",
            },
          ]
        },
      }),
      asset: genericAssets<AssetActivityFeedItem>((state) =>
        generalSelectStateToWhere(["asset", "id"], state)
      ),
      assetGroup: createSchemaStatement<AssetActivityFeedItem, string>({
        type: "select",
        label: i18n.t("assets:fields.group", { count: 1 }),
        multiSelectedLabel: i18n.t("assets:fields.group", { count: 2 }),
        toWhere: (state) => ({ asset: { group: { id: selectStateToWhere(state) } } }),
        getItems: async (client) => {
          const queryRes = await client
            .query<IAssetGroupsQuery>(
              AssetGroupsDocument,
              {},
              { requestPolicy: "cache-first" }
            )
            .toPromise()
          const groups = queryRes?.data?.asset_group
          if (!groups) return null
          return orderBy(groups, { name: "asc" }).map((a) => ({
            label: a.name,
            value: a.id,
            searchValue: a.name,
          }))
        },
      }),
      timestamp: pastRelativeDate(
        "timestamp",
        i18n.t("timestamp")
      )(datePicker.promptForDate),
    })
  }, [])

  return {
    schema,
    datePicker,
  }
}

export const assetActivityDataViewDefaultConfig = {
  columnOrder: ["timestamp", "activity", "object"],
  orderBy: [{ id: "timestamp", dir: "desc" }],
  historyFilter: {
    type: "select",
    id: "timestamp",
    selectedValues: [{ type: "relative", days: 7 }],
  },
} as DataViewConfiguration<AssetActivityIds>

export const AssetActivityDataView = React.memo(
  ({ defaultConfig, assetId, maxHistory = "7d", ...props }: AssetActivityDataViewProps) => {
    const navigate = useNavigate()

    let useData = undefined
    let data = null
    const [assetState, setAssetState] = useState<IAssetStateDataViewFragment | null>(null)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useData = useCallbackRef((where: Where<AssetActivityFeedItem> | null) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const data = useAssetActivityFeedItems({
        assetId: assetId!,
        sinceTimestamp: (where?._and?.[0]?.timestamp?._gte ??
          "1900-01-01T00:00:00.000Z") as string,
      })

      return { data, isLoading: data === null }
    })

    const filter = useFilterSchema()

    const onSelect = useCallback((data: AssetActivityFeedItem) => {
      if (data.type === "task_completed" || data.type === "task_created") {
        navigate(`/task/${data.task.id}`)
      } else if (data.type === "state_change") {
        setAssetState(data.state)
      } else if (data.type === "work_report") {
        // TODO
      }
    }, [])
    const dataId = useCallback(
      (d: AssetActivityFeedItem) => d.timestamp + d.type + d.asset.id,
      []
    )
    const dataSearchValue = useCallback(
      ({ searchValue }: AssetActivityFeedItem) => searchValue,
      []
    )

    const historyFilterItems = useMemo(
      () => [
        {
          searchValue: i18n.t("calendar:relative.today"),
          label: i18n.t("calendar:relative.today"),
          value: { type: "relative", days: 0 },
        },
        {
          searchValue: i18n.t("calendar:relative.since_yesterday"),
          label: i18n.t("calendar:relative.since_yesterday"),
          value: { type: "relative", days: 1 },
        },
        {
          searchValue: i18n.t("calendar:relative.last_7_days"),
          label: i18n.t("calendar:relative.last_7_days"),
          value: { type: "relative", days: 7 },
        },
        ...(maxHistory === "all"
          ? [
              {
                searchValue: i18n.t("calendar:relative.last_30_days"),
                label: i18n.t("calendar:relative.last_30_days"),
                value: { type: "relative", days: 30 },
              },
              {
                searchValue: i18n.t("calendar:relative.since_6_months"),
                label: i18n.t("calendar:relative.since_6_months"),
                value: { type: "relative", months: 6 },
              },
              {
                searchValue: i18n.t("calendar:relative.since_1_year"),
                label: i18n.t("calendar:relative.since_1_year"),
                value: { type: "relative", years: 1 },
              },
              {
                searchValue: i18n.t("common:all"),
                label: i18n.t("common:all"),
                value: { type: "relative", years: 100 },
              },
            ]
          : []),
      ],
      [maxHistory]
    )

    const memoConfig = useDeepMemo(
      () => ({
        ...assetActivityDataViewDefaultConfig,
        ...defaultConfig,
      }),
      [defaultConfig]
    )

    if (!filter) return null

    const { schema } = filter

    return (
      <div className="flex min-h-0 flex-1 flex-col">
        <DataView<AssetActivityFeedItem, AssetActivityIds, {}>
          {...props}
          data={data}
          useData={useData}
          headerClassName="mx-3"
          dataId={dataId}
          cellClass="h-full"
          dataSearchValue={dataSearchValue}
          columnDefinitions={columns}
          filterSchema={schema}
          onSelect={onSelect}
          searchPlaceholder={i18n.t("common:search_token", {
            token: i18n.t("common:activity", { count: 1 }),
          })}
          {...{
            historyFilterLabel: i18n.t("common:activity", { count: 2 }),
            historyFilterItems,
          }}
          dataType={IViewDataTypeEnum.AssetState}
          listItem={ListItem}
          defaultConfig={memoConfig}
          noData={{
            icon: () => <ClockCounterClockwise />,
            title: i18n.t("assets:labels.no_activity"),
          }}
        />
        {assetState && (
          <AssetStateDetailDialog
            isOpen={!!assetState}
            onOpenChange={() => setAssetState(null)}
            assetId={assetId!}
            state={assetState}
          />
        )}
      </div>
    )
  }
)
