import { MeterReadingTriggerOptions } from "@elara/db"
import i18n from "@i18n"

export const getMeterReadingTriggerDigest = (
  payload: MeterReadingTriggerOptions["payload"],
  unit?: string | null
): string => {
  if (payload.variant === "interval") {
    return i18n.t("maintenance:labels.triggers.meter.interval_digest", {
      every: payload.every,
      unit: unit ?? "",
    })
  } else if (payload.variant === "range") {
    return i18n.t("maintenance:labels.triggers.meter.range_digest", {
      from: payload.min,
      to: payload.max,
      unit: unit ?? "",
    })
  }

  return ""
}

export default function MeterReadingTriggerDigest({
  payload,
  unit,
  ...props
}: JSX.IntrinsicElements["span"] & {
  payload: MeterReadingTriggerOptions["payload"]
  unit?: string
}) {
  return <span {...props}> {getMeterReadingTriggerDigest(payload, unit)}</span>
}
