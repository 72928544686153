import SettingsPageLayout from "@components/settings/page-layout"
import { Button, UserTag } from "@components/shared"
import { alertDialog } from "@components/shared/alert-dialog-provider"
import { Tooltip } from "@components/shared/tooltip"
import { IPermissionScopeEnum, uuid } from "@elara/db"
import {
  CreateApiKeyDocument,
  DeleteApiKeyDocument,
  ICreateApiKeyMutation,
  IDeleteApiKeyMutation,
  IDeleteApiKeyMutationVariables,
  useApiKeysQuery,
} from "@graphql/documents/settings.generated"
import { usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { Plus, TrashSimple } from "@phosphor-icons/react"
import Icons from "@resources/icons"
import { formatDate } from "@utils/date"
import { useClient } from "urql"

export const SettingsAPIPage = () => {
  const [query, refetchQuery] = useApiKeysQuery({ requestPolicy: "cache-and-network" })

  const adminScope = usePermissionScope(IPermissionScopeEnum.AppAccountManagement)
  const client = useClient()
  const onDelete = (id: uuid) => {
    alertDialog({
      danger: true,
      actionText: i18n.t("common:delete"),
      title: i18n.t("settings:api.dialogs.delete_key.title"),
      description: i18n.t("settings:api.dialogs.delete_key.description"),
      onAction: async () => {
        await client
          .mutation<IDeleteApiKeyMutation, IDeleteApiKeyMutationVariables>(
            DeleteApiKeyDocument,
            { id },
            adminScope.context()
          )
          .toPromise()
        refetchQuery({ requestPolicy: "network-only" })
      },
    })
  }

  const onCreate = async () => {
    const res = await client
      .mutation<ICreateApiKeyMutation>(CreateApiKeyDocument, {}, adminScope.context())
      .toPromise()
    const key = res.data?.createAPIKey?.key

    if (key) {
      alertDialog({
        title: i18n.t("settings:api.dialogs.new_key.title"),
        description: (
          <div className="flex flex-col">
            <div className="mb-2">{i18n.t("settings:api.dialogs.new_key.description")}</div>
            <code className="rounded-lg border bg-gray-50 p-4">api-key {key}</code>
          </div>
        ),
        actionText: i18n.t("common:close"),
        cancelDisabled: true,
        onAction() {
          refetchQuery({ requestPolicy: "network-only" })
        },
      })
    }
  }

  return (
    <SettingsPageLayout
      title={i18n.t("settings:api.title")}
      description={i18n.t("settings:api.description")}
      buttonText={i18n.t("settings:api.actions.documentation")}
      buttonProps={{
        icon: Icons.FileDoc,
        onClick: () =>
          window.open(
            "https://documenter.getpostman.com/view/24907165/2s9Xy5NAuc",
            "_blank"
          ),
      }}>
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th className="pb-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
              {i18n.t("settings:api.fields.key", { count: 1 })}
            </th>
            <th className="px-3 pb-3.5 text-left text-sm font-semibold text-gray-900">
              {i18n.t("settings:api.fields.last_used_at")}
            </th>
            <th className="px-3 pb-3.5 text-left text-sm font-semibold text-gray-900">
              {i18n.t("common:user", { count: 1 })}
            </th>
            <th scope="col" className="relative pb-3.5 pl-3 pr-4 sm:pr-0">
              <span className="sr-only">delete</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {query.data?.location_api_key?.map((loc) => {
            return (
              <tr key={loc.id}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                  <Tooltip content={i18n.t("settings:api.messages.cannot_show_key")}>
                    {loc.key_preview}...
                  </Tooltip>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {formatDate(new Date(loc.last_used_at), "Pp")}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <UserTag user={loc.user} />
                </td>
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                  <Button
                    type="tertiary"
                    color="gray"
                    icon={TrashSimple}
                    disabled={!adminScope.hasScope}
                    onClick={() => onDelete(loc.id)}>
                    {i18n.t("common:delete")}
                  </Button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <div>
        <Button
          type="primary"
          onClick={onCreate}
          icon={Plus}
          disabled={!adminScope.hasScope}>
          {i18n.t("settings:api.actions.new_key")}
        </Button>
      </div>
    </SettingsPageLayout>
  )
}

export default SettingsAPIPage
