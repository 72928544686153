import toast from "@components/shared/toast"
import { IPermissionScopeEnum, uuid } from "@elara/db"
import { useEditAssetPartiallyMutation } from "@graphql/documents/asset.generated"
import { IAssetDetailFragment } from "@graphql/documents/fragments.generated"
import { useEditFileNameMutation, usePermissionScope } from "@hooks"
import { useFileUploads } from "@hooks/use-file-uploads"
import { ChangeEvent } from "react"
import { FileUpload } from "src/types"

export const useAssetManualSection = (asset: IAssetDetailFragment) => {
  const assetEditScope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)
  const [, editAssetPartially] = useEditAssetPartiallyMutation()
  const [, editFileName] = useEditFileNameMutation()

  const { uploads, uploadFile } = useFileUploads(
    asset.manual ? [{ ...asset?.manual }] : [],
    {
      onUploadFinished: async (upload) => {
        if (!asset?.id) return

        const res = await editAssetPartially(
          { asset_id: asset?.id, changes: { manual_id: upload.id } },
          assetEditScope.context()
        )

        if (res.error) toast.error("Die Betriebsanleitung konnte nicht hochgeladen werden.")
      },
    }
  )

  const onUploadManual = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    uploadFile(e.target.files[0])
  }

  const onDeleteManual = async (manualId: uuid) => {
    if (!manualId) return
    const res = await editAssetPartially(
      { asset_id: asset?.id, changes: { manual_id: null } },
      assetEditScope.context()
    )
    if (res.error) {
      toast.error(
        "Die Betriebsanleitung konnte nicht gelöscht werden. Bitte versuchen Sie es später noch einmal."
      )
    }
  }

  const onEditFileName = async (id: uuid, fileName: string) => {
    if (!id) return
    const res = await editFileName({ id, fileName }, assetEditScope.context())

    if (res.error) {
      toast.error("Bitte versuchen Sie es später noch einmal.")
    }
  }

  return {
    uploads,
    onDropManual: uploadFile,
    onUploadManual,
    onDeleteManual,
    onEditManualName: onEditFileName,
    scope: assetEditScope,
  }
}

export type AssetManualProps = {
  uploads: FileUpload[]
  scope: ReturnType<typeof usePermissionScope>
  onDropManual: (file: File) => Promise<string | void>
  onUploadManual: (e: ChangeEvent<HTMLInputElement>) => Promise<void>
  onDeleteManual: (manualId: uuid) => Promise<void>
  onEditManualName: (id: uuid, fileName: string) => Promise<void>
}
