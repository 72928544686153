import SettingsPageLayout from "@components/settings/page-layout"
import { alertDialog } from "@components/shared/alert-dialog-provider"
import Button from "@components/shared/button"
import { Dialog } from "@components/shared/dialog"
import { DialogForm } from "@components/shared/dialog-form"
import { CheckboxInput } from "@components/shared/form/checkbox-input"
import { FormField } from "@components/shared/form/form-field"
import { PermissionRoleSelect } from "@components/shared/permission-role-select"
import toast from "@components/shared/toast"
import { ILocationInviteFragment } from "@graphql/documents/fragments.generated"
import {
  DeleteInviteDocument,
  GenerateInviteDocument,
  IDeleteInviteMutation,
  IDeleteInviteMutationVariables,
  IGenerateInviteMutation,
  IGenerateInviteMutationVariables,
  LocationInvitesDocument,
  useLocationInvitesQuery,
} from "@graphql/documents/settings.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { Clipboard, Link, QrCode, Trash } from "@phosphor-icons/react"
import { Add } from "@resources/icons"
import { formatDate } from "@utils/date"
import React from "react"
import ReactQRCode from "react-qr-code"
import { useClient } from "urql"
import * as Yup from "yup"

function inviteUrl(inviteCode: string) {
  return `${window.location.origin}/invite/${inviteCode}`
}

function InviteDetail({ invite }: { invite: ILocationInviteFragment }) {
  return (
    <div className="">
      <div className="flex flex-col items-center space-y-3">
        <div className="font-medium ">
          {i18n.t("settings:user_management.create_invite.invite_code")}{" "}
          {invite.invite_code}
        </div>
        <div className=" text-gray-600">
          {i18n.t("settings:profile.fields.role")} {invite.permission_role.name}
        </div>
        <ReactQRCode value={inviteUrl(invite.invite_code)} />
        <div className="flex items-center">
          <Link className="text-gray-500" weight="bold" />
          <a
            href={inviteUrl(invite.invite_code)}
            target="_blank"
            className="mx-1 text-gray-800"
            rel="noreferrer">
            {inviteUrl(invite.invite_code)}
          </a>
        </div>
      </div>
    </div>
  )
}

function NewInviteDialog(props: { trigger: React.ReactNode; afterCreate?: () => void }) {
  const client = useClient()
  const accountManagementScope = usePermissionScope(
    IPermissionScopeEnum.AppAccountManagement
  )

  const [newCreatedInviteDialog, setNewCreatedInviteDialog] =
    React.useState<ILocationInviteFragment | null>(null)

  return (
    <>
      <DialogForm
        trigger={props.trigger}
        title={i18n.t("settings:user_management.create_invite.title")}
        okText={i18n.t("settings:user_management.create_invite.create_action")}
        formikConfig={{
          initialValues: {
            permissionRoleId: null as string | null,
            approvalNecessary: true,
          },
          validationSchema: Yup.object().shape({
            permissionRoleId: Yup.string().required(i18n.t("common:required")),
          }),
          onSubmit: async (values) => {
            const res = await client
              .mutation<IGenerateInviteMutation, IGenerateInviteMutationVariables>(
                GenerateInviteDocument,
                {
                  permissionRoleId: values.permissionRoleId!,
                  approvalNecessary: values.approvalNecessary,
                },
                accountManagementScope.context()
              )
              .toPromise()

            client.query(LocationInvitesDocument, {}).toPromise()

            if (res.data?.insert_location_invite_one) {
              setNewCreatedInviteDialog(res.data?.insert_location_invite_one)
            }
          },
        }}>
        {() => {
          return (
            <>
              <p className="mb-3 text-sm text-gray-600">
                {i18n.t("settings:user_management.create_invite.description")}
              </p>
              <FormField name="permissionRoleId">
                {({ field, helpers }) => (
                  <PermissionRoleSelect
                    value={field.value}
                    isClearable={false}
                    onChange={helpers.setValue}
                  />
                )}
              </FormField>
              <FormField name="approvalNecessary" noStyle>
                {({ field }) => (
                  <label className="mb-2 flex items-start gap-x-2 text-sm text-gray-600">
                    <CheckboxInput {...field} className="mt-0.5" />
                    {i18n.t("settings:user_management.create_invite.require_approval")}
                  </label>
                )}
              </FormField>
            </>
          )
        }}
      </DialogForm>
      <Dialog
        closeIcon
        isOpen={!!newCreatedInviteDialog}
        onOpenChange={(isOpen) => {
          !isOpen && setNewCreatedInviteDialog(null)
        }}
        title={i18n.t("settings:user_management.invites.details.title")}
        content={newCreatedInviteDialog && <InviteDetail invite={newCreatedInviteDialog} />}
      />
    </>
  )
}

function SettingsInvites() {
  const client = useClient()
  const accountManagementScope = usePermissionScope(
    IPermissionScopeEnum.AppAccountManagement
  )

  const [query, refetchQuery] = useLocationInvitesQuery()
  const invites = query.data?.location_invite ?? []

  return (
    <SettingsPageLayout
      title={i18n.t("settings:user_management.invites.title")}
      description={i18n.t("settings:user_management.invites.description")}
      buttonText={i18n.t("settings:user_management.invites.create_invite")}
      actionButton={
        <NewInviteDialog
          trigger={
            <Button size="small" type="tertiary" icon={Add}>
              {i18n.t("settings:user_management.create_invite.title")}
            </Button>
          }
        />
      }>
      <div>
        {!invites?.length && (
          <div className="flex w-full flex-col items-center justify-center space-y-3 rounded-lg border border-dashed p-6 text-sm font-medium text-gray-500">
            <span>{i18n.t("settings:user_management.create_invite.no_invites")}</span>
            {
              <NewInviteDialog
                trigger={
                  <Button size="small" type="secondary" color="gray" icon={Add}>
                    {i18n.t("settings:user_management.create_invite.title")}
                  </Button>
                }
              />
            }
          </div>
        )}
        <ul className="flex flex-col items-stretch divide-y divide-gray-200 text-sm">
          {invites?.map((invite) => (
            <ul key={invite.id} className="py-3">
              <div className="">
                {i18n.t("settings:user_management.create_invite.invite_code")}{" "}
                <code>{invite.invite_code}</code>{" "}
                <span className="mt-2 text-gray-600">
                  {i18n.t("settings:profile.fields.role")} {invite.permission_role.name}
                </span>
                {invite.approval_necessary && (
                  <span className="ml-2 rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-700">
                    {i18n.t("settings:user_management.create_invite.approval_necessary")}
                  </span>
                )}
              </div>

              <div className="flex items-center">
                <Link className="text-gray-500" weight="bold" />
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="mx-1 text-gray-600"
                  href={inviteUrl(invite.invite_code)}>
                  {inviteUrl(invite.invite_code)}
                </a>
                <Button
                  color="gray"
                  type="tertiary"
                  icon={Clipboard}
                  size="extra-small"
                  onClick={() => {
                    navigator.clipboard.writeText(inviteUrl(invite.invite_code))
                    toast.success(i18n.t("common:messages.copied_to_clipboard"))
                  }}
                />
              </div>
              <div className="text-xs text-gray-500">
                {i18n.t("settings:user_management.create_invite.expires_at", {
                  date: formatDate(new Date(invite.expires_at), "Pp"),
                })}
              </div>

              <div className="mt-2 flex space-x-3">
                <Dialog
                  closeIcon
                  title={i18n.t("settings:user_management.invites.details.title")}
                  content={<InviteDetail invite={invite} />}>
                  <Button icon={QrCode} color="gray" type="secondary">
                    {i18n.t("common:qr_code")}
                  </Button>
                </Dialog>

                <Button
                  color="red"
                  icon={Trash}
                  type="secondary"
                  onClick={() => {
                    alertDialog({
                      danger: true,
                      title: i18n.t("settings:user_management.invites.delete.title"),
                      description: i18n.t(
                        "settings:user_management.invites.delete.description"
                      ),
                      actionText: i18n.t("delete"),
                      onAction: async () => {
                        await client
                          .mutation<IDeleteInviteMutation, IDeleteInviteMutationVariables>(
                            DeleteInviteDocument,
                            { id: invite.id },
                            accountManagementScope.context()
                          )
                          .toPromise()
                        refetchQuery({ requestPolicy: "network-only" })
                      },
                    })
                  }}>
                  {i18n.t("delete")}
                </Button>
              </div>
            </ul>
          ))}
        </ul>
      </div>
    </SettingsPageLayout>
  )
}

export default SettingsInvites
