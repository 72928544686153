import { uuid } from './scalars.js'
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  bigint: { input: number; output: number; }
  citext: { input: string; output: string; }
  date: { input: string; output: string; }
  interval: { input: string; output: string; }
  json: { input: {}; output: {}; }
  jsonb: { input: {}; output: {}; }
  numeric: { input: number; output: number; }
  smallint: { input: number; output: number; }
  time: { input: string; output: string; }
  timestamptz: { input: string; output: string; }
  tstzrange: { input: string; output: string; }
  uuid: { input: uuid; output: uuid; }
};

export type IAddUserToLocationOutput = {
  __typename: 'AddUserToLocationOutput';
  password: Scalars['String']['output'];
  user?: Maybe<IUser>;
  user_id: Scalars['uuid']['output'];
};

export type IAskQuestionOutput = {
  __typename: 'AskQuestionOutput';
  answer: Scalars['String']['output'];
};

export type IAssetCustomFieldNamesData = {
  __typename: 'AssetCustomFieldNamesData';
  names: Array<Scalars['String']['output']>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type IBooleanComparisonExp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type IChangePasswordOutput = {
  __typename: 'ChangePasswordOutput';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ICheckDuplicateEmailOutput = {
  __typename: 'CheckDuplicateEmailOutput';
  isDuplicate: Scalars['Boolean']['output'];
};

export type ICheckMaintenanceTriggersOutput = {
  __typename: 'CheckMaintenanceTriggersOutput';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ICopyAssetOptions = {
  copyAvatar?: InputMaybe<Scalars['Boolean']['input']>;
  copyDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  newAssetName?: InputMaybe<Scalars['String']['input']>;
};

export type ICopyAssetOutput = {
  __typename: 'CopyAssetOutput';
  asset?: Maybe<IAsset>;
  id: Scalars['uuid']['output'];
};

export type ICopyWorkOrderOptions = {
  copyDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  copySteps?: InputMaybe<Scalars['Boolean']['input']>;
  copySubtasks?: InputMaybe<Scalars['Boolean']['input']>;
  newWorkOrderName?: InputMaybe<Scalars['String']['input']>;
};

export type ICopyWorkOrderOutput = {
  __typename: 'CopyWorkOrderOutput';
  id: Scalars['uuid']['output'];
  work_order?: Maybe<IWorkOrder>;
};

export type ICreateApiKeyOutput = {
  __typename: 'CreateAPIKeyOutput';
  key: Scalars['String']['output'];
};

export type ICreateOrgOutput = {
  __typename: 'CreateOrgOutput';
  location_id: Scalars['uuid']['output'];
  org?: Maybe<IOrg>;
  org_id: Scalars['uuid']['output'];
  password: Scalars['String']['output'];
  user?: Maybe<IUser>;
  user_id: Scalars['uuid']['output'];
};

export type IDeleteCompleteOrgOutput = {
  __typename: 'DeleteCompleteOrgOutput';
  org_id?: Maybe<Scalars['uuid']['output']>;
};

export type IDeleteUserCompletelyOutput = {
  __typename: 'DeleteUserCompletelyOutput';
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IIntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IResetUserPasswordResponse = {
  __typename: 'ResetUserPasswordResponse';
  password: Scalars['String']['output'];
};

export type ISetupDemoDataOutput = {
  __typename: 'SetupDemoDataOutput';
  success: Scalars['Boolean']['output'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type IStringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

export type ISyncUserToKeycloakOutput = {
  __typename: 'SyncUserToKeycloakOutput';
  user?: Maybe<IUser>;
  userId: Scalars['uuid']['output'];
};

export type IUpdateUserEmailOutput = {
  __typename: 'UpdateUserEmailOutput';
  email: Scalars['String']['output'];
  user?: Maybe<IUser>;
  userId: Scalars['uuid']['output'];
};

export type IUpsertEmbeddingOutput = {
  __typename: 'UpsertEmbeddingOutput';
  ids: Array<Scalars['String']['output']>;
};

export type IUserRequiredData = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "analytics.event" */
export type IAnalyticsEvent = {
  __typename: 'analytics_event';
  captured_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  location_id: Scalars['uuid']['output'];
  payload: Scalars['jsonb']['output'];
  type: Scalars['String']['output'];
  user_id: Scalars['uuid']['output'];
};


/** columns and relationships of "analytics.event" */
export type IAnalyticsEventPayloadArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "analytics.event" */
export type IAnalyticsEventAggregate = {
  __typename: 'analytics_event_aggregate';
  aggregate?: Maybe<IAnalyticsEventAggregateFields>;
  nodes: Array<IAnalyticsEvent>;
};

/** aggregate fields of "analytics.event" */
export type IAnalyticsEventAggregateFields = {
  __typename: 'analytics_event_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IAnalyticsEventMaxFields>;
  min?: Maybe<IAnalyticsEventMinFields>;
};


/** aggregate fields of "analytics.event" */
export type IAnalyticsEventAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IAnalyticsEventSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IAnalyticsEventAppendInput = {
  payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "analytics.event". All fields are combined with a logical 'AND'. */
export type IAnalyticsEventBoolExp = {
  _and?: InputMaybe<Array<IAnalyticsEventBoolExp>>;
  _not?: InputMaybe<IAnalyticsEventBoolExp>;
  _or?: InputMaybe<Array<IAnalyticsEventBoolExp>>;
  captured_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  payload?: InputMaybe<IJsonbComparisonExp>;
  type?: InputMaybe<IStringComparisonExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "analytics.event" */
export enum IAnalyticsEventConstraint {
  /** unique or primary key constraint on columns "id" */
  EventPkey = 'event_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IAnalyticsEventDeleteAtPathInput = {
  payload?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IAnalyticsEventDeleteElemInput = {
  payload?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IAnalyticsEventDeleteKeyInput = {
  payload?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "analytics.event" */
export type IAnalyticsEventInsertInput = {
  captured_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IAnalyticsEventMaxFields = {
  __typename: 'analytics_event_max_fields';
  captured_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type IAnalyticsEventMinFields = {
  __typename: 'analytics_event_min_fields';
  captured_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "analytics.event" */
export type IAnalyticsEventMutationResponse = {
  __typename: 'analytics_event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IAnalyticsEvent>;
};

/** on_conflict condition type for table "analytics.event" */
export type IAnalyticsEventOnConflict = {
  constraint: IAnalyticsEventConstraint;
  update_columns?: Array<IAnalyticsEventUpdateColumn>;
  where?: InputMaybe<IAnalyticsEventBoolExp>;
};

/** Ordering options when selecting data from "analytics.event". */
export type IAnalyticsEventOrderBy = {
  captured_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  payload?: InputMaybe<IOrderBy>;
  type?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: analytics.event */
export type IAnalyticsEventPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IAnalyticsEventPrependInput = {
  payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "analytics.event" */
export enum IAnalyticsEventSelectColumn {
  /** column name */
  CapturedAt = 'captured_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "analytics.event" */
export type IAnalyticsEventSetInput = {
  captured_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "analytics_event" */
export type IAnalyticsEventStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IAnalyticsEventStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IAnalyticsEventStreamCursorValueInput = {
  captured_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "analytics.event" */
export enum IAnalyticsEventUpdateColumn {
  /** column name */
  CapturedAt = 'captured_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

export type IAnalyticsEventUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IAnalyticsEventAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IAnalyticsEventDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IAnalyticsEventDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IAnalyticsEventDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IAnalyticsEventPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IAnalyticsEventSetInput>;
  /** filter the rows which have to be updated */
  where: IAnalyticsEventBoolExp;
};

/** An access log of all API requests made to Elara by our customers */
export type IApiAccessLog = {
  __typename: 'api_access_log';
  id: Scalars['uuid']['output'];
  key: Scalars['String']['output'];
  /** An object relationship */
  location?: Maybe<ILocation>;
  location_id: Scalars['uuid']['output'];
  /** An object relationship */
  org?: Maybe<IOrg>;
  org_id: Scalars['uuid']['output'];
  payload?: Maybe<Scalars['jsonb']['output']>;
  timestamp: Scalars['timestamptz']['output'];
  url: Scalars['String']['output'];
  /** An object relationship */
  user?: Maybe<IUser>;
  user_id: Scalars['uuid']['output'];
};


/** An access log of all API requests made to Elara by our customers */
export type IApiAccessLogPayloadArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "api_access_log" */
export type IApiAccessLogAggregate = {
  __typename: 'api_access_log_aggregate';
  aggregate?: Maybe<IApiAccessLogAggregateFields>;
  nodes: Array<IApiAccessLog>;
};

/** aggregate fields of "api_access_log" */
export type IApiAccessLogAggregateFields = {
  __typename: 'api_access_log_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IApiAccessLogMaxFields>;
  min?: Maybe<IApiAccessLogMinFields>;
};


/** aggregate fields of "api_access_log" */
export type IApiAccessLogAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IApiAccessLogSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IApiAccessLogAppendInput = {
  payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "api_access_log". All fields are combined with a logical 'AND'. */
export type IApiAccessLogBoolExp = {
  _and?: InputMaybe<Array<IApiAccessLogBoolExp>>;
  _not?: InputMaybe<IApiAccessLogBoolExp>;
  _or?: InputMaybe<Array<IApiAccessLogBoolExp>>;
  id?: InputMaybe<IUuidComparisonExp>;
  key?: InputMaybe<IStringComparisonExp>;
  location?: InputMaybe<ILocationBoolExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  org?: InputMaybe<IOrgBoolExp>;
  org_id?: InputMaybe<IUuidComparisonExp>;
  payload?: InputMaybe<IJsonbComparisonExp>;
  timestamp?: InputMaybe<ITimestamptzComparisonExp>;
  url?: InputMaybe<IStringComparisonExp>;
  user?: InputMaybe<IUserBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "api_access_log" */
export enum IApiAccessLogConstraint {
  /** unique or primary key constraint on columns "id" */
  ApiAccessLogPkey = 'api_access_log_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IApiAccessLogDeleteAtPathInput = {
  payload?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IApiAccessLogDeleteElemInput = {
  payload?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IApiAccessLogDeleteKeyInput = {
  payload?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "api_access_log" */
export type IApiAccessLogInsertInput = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<ILocationObjRelInsertInput>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  org?: InputMaybe<IOrgObjRelInsertInput>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<IUserObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IApiAccessLogMaxFields = {
  __typename: 'api_access_log_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  timestamp?: Maybe<Scalars['timestamptz']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type IApiAccessLogMinFields = {
  __typename: 'api_access_log_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  timestamp?: Maybe<Scalars['timestamptz']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "api_access_log" */
export type IApiAccessLogMutationResponse = {
  __typename: 'api_access_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IApiAccessLog>;
};

/** on_conflict condition type for table "api_access_log" */
export type IApiAccessLogOnConflict = {
  constraint: IApiAccessLogConstraint;
  update_columns?: Array<IApiAccessLogUpdateColumn>;
  where?: InputMaybe<IApiAccessLogBoolExp>;
};

/** Ordering options when selecting data from "api_access_log". */
export type IApiAccessLogOrderBy = {
  id?: InputMaybe<IOrderBy>;
  key?: InputMaybe<IOrderBy>;
  location?: InputMaybe<ILocationOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  org?: InputMaybe<IOrgOrderBy>;
  org_id?: InputMaybe<IOrderBy>;
  payload?: InputMaybe<IOrderBy>;
  timestamp?: InputMaybe<IOrderBy>;
  url?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUserOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: api_access_log */
export type IApiAccessLogPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IApiAccessLogPrependInput = {
  payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "api_access_log" */
export enum IApiAccessLogSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "api_access_log" */
export type IApiAccessLogSetInput = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "api_access_log" */
export type IApiAccessLogStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IApiAccessLogStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IApiAccessLogStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "api_access_log" */
export enum IApiAccessLogUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id'
}

export type IApiAccessLogUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IApiAccessLogAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IApiAccessLogDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IApiAccessLogDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IApiAccessLogDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IApiAccessLogPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IApiAccessLogSetInput>;
  /** filter the rows which have to be updated */
  where: IApiAccessLogBoolExp;
};

/** columns and relationships of "asset" */
export type IAsset = {
  __typename: 'asset';
  archived_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  asset_consumables: Array<IAssetXConsumable>;
  /** An aggregate relationship */
  asset_consumables_aggregate: IAssetXConsumableAggregate;
  /** A computed field, executes function "asset_availability" */
  availability?: Maybe<Scalars['json']['output']>;
  /** An object relationship */
  avatar?: Maybe<IUpload>;
  avatar_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  comments: Array<IComment>;
  /** An aggregate relationship */
  comments_aggregate: ICommentAggregate;
  /** A computed field, executes function "lookup_consumable_for_asset_row" */
  consumables?: Maybe<Array<IConsumable>>;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by?: Maybe<IUser>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  current_state?: Maybe<IAssetCurrentState>;
  custom_fields: Scalars['jsonb']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  department?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  documents: Array<IAssetXUpload>;
  /** An aggregate relationship */
  documents_aggregate: IAssetXUploadAggregate;
  /** An object relationship */
  file_folder?: Maybe<IFileFolder>;
  file_folder_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  group?: Maybe<IAssetGroup>;
  group_id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "asset_has_recurring_work_order_computed" */
  has_recurring_work_order?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  last_state?: Maybe<IAssetLastStateView>;
  /** An object relationship */
  location: ILocation;
  location_id: Scalars['uuid']['output'];
  /** An object relationship */
  manual?: Maybe<IUpload>;
  manual_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  manufacturer?: Maybe<IAssetManufacturer>;
  manufacturer_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  meters: Array<IMeter>;
  /** An aggregate relationship */
  meters_aggregate: IMeterAggregate;
  model_number?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  operating_schedules: Array<IAssetOperatingSchedule>;
  /** An aggregate relationship */
  operating_schedules_aggregate: IAssetOperatingScheduleAggregate;
  operating_since?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  parent_asset?: Maybe<IAsset>;
  parent_asset_id?: Maybe<Scalars['uuid']['output']>;
  parent_ids?: Maybe<Scalars['jsonb']['output']>;
  /** A computed field, executes function "asset_parents" */
  parents?: Maybe<Array<IAsset>>;
  /** An object relationship */
  place?: Maybe<IPlace>;
  place_id?: Maybe<Scalars['uuid']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  public_id: Scalars['String']['output'];
  qr_code: Scalars['String']['output'];
  rank: Scalars['Int']['output'];
  /** An object relationship */
  register_plate?: Maybe<IUpload>;
  register_plate_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  replaced_by?: Maybe<IAsset>;
  replaced_by_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  replacement_of?: Maybe<IAsset>;
  search_value?: Maybe<Scalars['String']['output']>;
  serial_number?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  states: Array<IAssetXAssetState>;
  /** An aggregate relationship */
  states_aggregate: IAssetXAssetStateAggregate;
  /** An array relationship */
  sub_assets: Array<IAsset>;
  /** An aggregate relationship */
  sub_assets_aggregate: IAssetAggregate;
  support_contact_ids?: Maybe<Scalars['jsonb']['output']>;
  /** A computed field, executes function "asset_support_contacts" */
  support_contacts?: Maybe<Array<IContact>>;
  tree_depth?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  work_orders: Array<IAssetXWorkOrder>;
  /** An aggregate relationship */
  work_orders_aggregate: IAssetXWorkOrderAggregate;
  year_of_purchase?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "asset" */
export type IAssetAssetConsumablesArgs = {
  distinct_on?: InputMaybe<Array<IAssetXConsumableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXConsumableOrderBy>>;
  where?: InputMaybe<IAssetXConsumableBoolExp>;
};


/** columns and relationships of "asset" */
export type IAssetAssetConsumablesAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetXConsumableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXConsumableOrderBy>>;
  where?: InputMaybe<IAssetXConsumableBoolExp>;
};


/** columns and relationships of "asset" */
export type IAssetAvailabilityArgs = {
  args: IAvailabilityAssetArgs;
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "asset" */
export type IAssetCommentsArgs = {
  distinct_on?: InputMaybe<Array<ICommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICommentOrderBy>>;
  where?: InputMaybe<ICommentBoolExp>;
};


/** columns and relationships of "asset" */
export type IAssetCommentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ICommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICommentOrderBy>>;
  where?: InputMaybe<ICommentBoolExp>;
};


/** columns and relationships of "asset" */
export type IAssetConsumablesArgs = {
  distinct_on?: InputMaybe<Array<IConsumableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableOrderBy>>;
  where?: InputMaybe<IConsumableBoolExp>;
};


/** columns and relationships of "asset" */
export type IAssetCustomFieldsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "asset" */
export type IAssetDocumentsArgs = {
  distinct_on?: InputMaybe<Array<IAssetXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXUploadOrderBy>>;
  where?: InputMaybe<IAssetXUploadBoolExp>;
};


/** columns and relationships of "asset" */
export type IAssetDocumentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXUploadOrderBy>>;
  where?: InputMaybe<IAssetXUploadBoolExp>;
};


/** columns and relationships of "asset" */
export type IAssetMetersArgs = {
  distinct_on?: InputMaybe<Array<IMeterSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMeterOrderBy>>;
  where?: InputMaybe<IMeterBoolExp>;
};


/** columns and relationships of "asset" */
export type IAssetMetersAggregateArgs = {
  distinct_on?: InputMaybe<Array<IMeterSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMeterOrderBy>>;
  where?: InputMaybe<IMeterBoolExp>;
};


/** columns and relationships of "asset" */
export type IAssetOperatingSchedulesArgs = {
  distinct_on?: InputMaybe<Array<IAssetOperatingScheduleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetOperatingScheduleOrderBy>>;
  where?: InputMaybe<IAssetOperatingScheduleBoolExp>;
};


/** columns and relationships of "asset" */
export type IAssetOperatingSchedulesAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetOperatingScheduleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetOperatingScheduleOrderBy>>;
  where?: InputMaybe<IAssetOperatingScheduleBoolExp>;
};


/** columns and relationships of "asset" */
export type IAssetParentIdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "asset" */
export type IAssetParentsArgs = {
  distinct_on?: InputMaybe<Array<IAssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetOrderBy>>;
  where?: InputMaybe<IAssetBoolExp>;
};


/** columns and relationships of "asset" */
export type IAssetStatesArgs = {
  distinct_on?: InputMaybe<Array<IAssetXAssetStateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXAssetStateOrderBy>>;
  where?: InputMaybe<IAssetXAssetStateBoolExp>;
};


/** columns and relationships of "asset" */
export type IAssetStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetXAssetStateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXAssetStateOrderBy>>;
  where?: InputMaybe<IAssetXAssetStateBoolExp>;
};


/** columns and relationships of "asset" */
export type IAssetSubAssetsArgs = {
  distinct_on?: InputMaybe<Array<IAssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetOrderBy>>;
  where?: InputMaybe<IAssetBoolExp>;
};


/** columns and relationships of "asset" */
export type IAssetSubAssetsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetOrderBy>>;
  where?: InputMaybe<IAssetBoolExp>;
};


/** columns and relationships of "asset" */
export type IAssetSupportContactIdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "asset" */
export type IAssetSupportContactsArgs = {
  distinct_on?: InputMaybe<Array<IContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IContactOrderBy>>;
  where?: InputMaybe<IContactBoolExp>;
};


/** columns and relationships of "asset" */
export type IAssetWorkOrdersArgs = {
  distinct_on?: InputMaybe<Array<IAssetXWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXWorkOrderOrderBy>>;
  where?: InputMaybe<IAssetXWorkOrderBoolExp>;
};


/** columns and relationships of "asset" */
export type IAssetWorkOrdersAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetXWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXWorkOrderOrderBy>>;
  where?: InputMaybe<IAssetXWorkOrderBoolExp>;
};

/** aggregated selection of "asset" */
export type IAssetAggregate = {
  __typename: 'asset_aggregate';
  aggregate?: Maybe<IAssetAggregateFields>;
  nodes: Array<IAsset>;
};

export type IAssetAggregateBoolExp = {
  count?: InputMaybe<IAssetAggregateBoolExpCount>;
};

export type IAssetAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IAssetSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IAssetBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "asset" */
export type IAssetAggregateFields = {
  __typename: 'asset_aggregate_fields';
  avg?: Maybe<IAssetAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IAssetMaxFields>;
  min?: Maybe<IAssetMinFields>;
  stddev?: Maybe<IAssetStddevFields>;
  stddev_pop?: Maybe<IAssetStddevPopFields>;
  stddev_samp?: Maybe<IAssetStddevSampFields>;
  sum?: Maybe<IAssetSumFields>;
  var_pop?: Maybe<IAssetVarPopFields>;
  var_samp?: Maybe<IAssetVarSampFields>;
  variance?: Maybe<IAssetVarianceFields>;
};


/** aggregate fields of "asset" */
export type IAssetAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IAssetSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "asset" */
export type IAssetAggregateOrderBy = {
  avg?: InputMaybe<IAssetAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IAssetMaxOrderBy>;
  min?: InputMaybe<IAssetMinOrderBy>;
  stddev?: InputMaybe<IAssetStddevOrderBy>;
  stddev_pop?: InputMaybe<IAssetStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IAssetStddevSampOrderBy>;
  sum?: InputMaybe<IAssetSumOrderBy>;
  var_pop?: InputMaybe<IAssetVarPopOrderBy>;
  var_samp?: InputMaybe<IAssetVarSampOrderBy>;
  variance?: InputMaybe<IAssetVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IAssetAppendInput = {
  custom_fields?: InputMaybe<Scalars['jsonb']['input']>;
  parent_ids?: InputMaybe<Scalars['jsonb']['input']>;
  support_contact_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "asset" */
export type IAssetArrRelInsertInput = {
  data: Array<IAssetInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IAssetOnConflict>;
};

export type IAssetAvailabilityAnalyticsLocationArgs = {
  asset_group?: InputMaybe<Scalars['uuid']['input']>;
  asset_ids?: InputMaybe<Scalars['jsonb']['input']>;
  end?: InputMaybe<Scalars['timestamptz']['input']>;
  start?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate avg on columns */
export type IAssetAvgFields = {
  __typename: 'asset_avg_fields';
  rank?: Maybe<Scalars['Float']['output']>;
  tree_depth?: Maybe<Scalars['Float']['output']>;
  year_of_purchase?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "asset" */
export type IAssetAvgOrderBy = {
  rank?: InputMaybe<IOrderBy>;
  tree_depth?: InputMaybe<IOrderBy>;
  year_of_purchase?: InputMaybe<IOrderBy>;
};

/** Boolean expression to filter rows from the table "asset". All fields are combined with a logical 'AND'. */
export type IAssetBoolExp = {
  _and?: InputMaybe<Array<IAssetBoolExp>>;
  _not?: InputMaybe<IAssetBoolExp>;
  _or?: InputMaybe<Array<IAssetBoolExp>>;
  archived_at?: InputMaybe<ITimestamptzComparisonExp>;
  asset_consumables?: InputMaybe<IAssetXConsumableBoolExp>;
  asset_consumables_aggregate?: InputMaybe<IAssetXConsumableAggregateBoolExp>;
  avatar?: InputMaybe<IUploadBoolExp>;
  avatar_id?: InputMaybe<IUuidComparisonExp>;
  comments?: InputMaybe<ICommentBoolExp>;
  comments_aggregate?: InputMaybe<ICommentAggregateBoolExp>;
  consumables?: InputMaybe<IConsumableBoolExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  created_by?: InputMaybe<IUserBoolExp>;
  created_by_id?: InputMaybe<IUuidComparisonExp>;
  current_state?: InputMaybe<IAssetCurrentStateBoolExp>;
  custom_fields?: InputMaybe<IJsonbComparisonExp>;
  deleted_at?: InputMaybe<ITimestamptzComparisonExp>;
  department?: InputMaybe<IStringComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  documents?: InputMaybe<IAssetXUploadBoolExp>;
  documents_aggregate?: InputMaybe<IAssetXUploadAggregateBoolExp>;
  file_folder?: InputMaybe<IFileFolderBoolExp>;
  file_folder_id?: InputMaybe<IUuidComparisonExp>;
  group?: InputMaybe<IAssetGroupBoolExp>;
  group_id?: InputMaybe<IUuidComparisonExp>;
  has_recurring_work_order?: InputMaybe<IBooleanComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  last_state?: InputMaybe<IAssetLastStateViewBoolExp>;
  location?: InputMaybe<ILocationBoolExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  manual?: InputMaybe<IUploadBoolExp>;
  manual_id?: InputMaybe<IUuidComparisonExp>;
  manufacturer?: InputMaybe<IAssetManufacturerBoolExp>;
  manufacturer_id?: InputMaybe<IUuidComparisonExp>;
  meters?: InputMaybe<IMeterBoolExp>;
  meters_aggregate?: InputMaybe<IMeterAggregateBoolExp>;
  model_number?: InputMaybe<IStringComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  note?: InputMaybe<IStringComparisonExp>;
  operating_schedules?: InputMaybe<IAssetOperatingScheduleBoolExp>;
  operating_schedules_aggregate?: InputMaybe<IAssetOperatingScheduleAggregateBoolExp>;
  operating_since?: InputMaybe<ITimestamptzComparisonExp>;
  parent_asset?: InputMaybe<IAssetBoolExp>;
  parent_asset_id?: InputMaybe<IUuidComparisonExp>;
  parent_ids?: InputMaybe<IJsonbComparisonExp>;
  parents?: InputMaybe<IAssetBoolExp>;
  place?: InputMaybe<IPlaceBoolExp>;
  place_id?: InputMaybe<IUuidComparisonExp>;
  position?: InputMaybe<IStringComparisonExp>;
  public_id?: InputMaybe<IStringComparisonExp>;
  qr_code?: InputMaybe<IStringComparisonExp>;
  rank?: InputMaybe<IIntComparisonExp>;
  register_plate?: InputMaybe<IUploadBoolExp>;
  register_plate_id?: InputMaybe<IUuidComparisonExp>;
  replaced_by?: InputMaybe<IAssetBoolExp>;
  replaced_by_id?: InputMaybe<IUuidComparisonExp>;
  replacement_of?: InputMaybe<IAssetBoolExp>;
  search_value?: InputMaybe<IStringComparisonExp>;
  serial_number?: InputMaybe<IStringComparisonExp>;
  states?: InputMaybe<IAssetXAssetStateBoolExp>;
  states_aggregate?: InputMaybe<IAssetXAssetStateAggregateBoolExp>;
  sub_assets?: InputMaybe<IAssetBoolExp>;
  sub_assets_aggregate?: InputMaybe<IAssetAggregateBoolExp>;
  support_contact_ids?: InputMaybe<IJsonbComparisonExp>;
  support_contacts?: InputMaybe<IContactBoolExp>;
  tree_depth?: InputMaybe<IIntComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  work_orders?: InputMaybe<IAssetXWorkOrderBoolExp>;
  work_orders_aggregate?: InputMaybe<IAssetXWorkOrderAggregateBoolExp>;
  year_of_purchase?: InputMaybe<IIntComparisonExp>;
};

/** unique or primary key constraints on table "asset" */
export enum IAssetConstraint {
  /** unique or primary key constraint on columns "location_id", "public_id" */
  AssetLocationIdPublicIdKey = 'asset_location_id_public_id_key',
  /** unique or primary key constraint on columns "id" */
  AssetPkey = 'asset_pkey',
  /** unique or primary key constraint on columns "replaced_by_id" */
  AssetReplacedByIdKey = 'asset_replaced_by_id_key',
  /** unique or primary key constraint on columns "qr_code" */
  QrCodeUnique = 'qr_code_unique'
}

/** columns and relationships of "asset_current_state" */
export type IAssetCurrentState = {
  __typename: 'asset_current_state';
  /** An object relationship */
  asset?: Maybe<IAsset>;
  asset_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  asset_state_variant?: Maybe<IAssetStateVariant>;
  asset_state_variant_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  ended_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  started_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  work_order?: Maybe<IWorkOrder>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "asset_current_state" */
export type IAssetCurrentStateAggregate = {
  __typename: 'asset_current_state_aggregate';
  aggregate?: Maybe<IAssetCurrentStateAggregateFields>;
  nodes: Array<IAssetCurrentState>;
};

/** aggregate fields of "asset_current_state" */
export type IAssetCurrentStateAggregateFields = {
  __typename: 'asset_current_state_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IAssetCurrentStateMaxFields>;
  min?: Maybe<IAssetCurrentStateMinFields>;
};


/** aggregate fields of "asset_current_state" */
export type IAssetCurrentStateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IAssetCurrentStateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "asset_current_state". All fields are combined with a logical 'AND'. */
export type IAssetCurrentStateBoolExp = {
  _and?: InputMaybe<Array<IAssetCurrentStateBoolExp>>;
  _not?: InputMaybe<IAssetCurrentStateBoolExp>;
  _or?: InputMaybe<Array<IAssetCurrentStateBoolExp>>;
  asset?: InputMaybe<IAssetBoolExp>;
  asset_id?: InputMaybe<IUuidComparisonExp>;
  asset_state_variant?: InputMaybe<IAssetStateVariantBoolExp>;
  asset_state_variant_id?: InputMaybe<IUuidComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  created_by_id?: InputMaybe<IUuidComparisonExp>;
  ended_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  note?: InputMaybe<IStringComparisonExp>;
  started_at?: InputMaybe<ITimestamptzComparisonExp>;
  work_order?: InputMaybe<IWorkOrderBoolExp>;
  work_order_id?: InputMaybe<IUuidComparisonExp>;
};

/** input type for inserting data into table "asset_current_state" */
export type IAssetCurrentStateInsertInput = {
  asset?: InputMaybe<IAssetObjRelInsertInput>;
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  asset_state_variant?: InputMaybe<IAssetStateVariantObjRelInsertInput>;
  asset_state_variant_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  ended_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_order?: InputMaybe<IWorkOrderObjRelInsertInput>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IAssetCurrentStateMaxFields = {
  __typename: 'asset_current_state_max_fields';
  asset_id?: Maybe<Scalars['uuid']['output']>;
  asset_state_variant_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  ended_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  started_at?: Maybe<Scalars['timestamptz']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type IAssetCurrentStateMinFields = {
  __typename: 'asset_current_state_min_fields';
  asset_id?: Maybe<Scalars['uuid']['output']>;
  asset_state_variant_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  ended_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  started_at?: Maybe<Scalars['timestamptz']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** input type for inserting object relation for remote table "asset_current_state" */
export type IAssetCurrentStateObjRelInsertInput = {
  data: IAssetCurrentStateInsertInput;
};

/** Ordering options when selecting data from "asset_current_state". */
export type IAssetCurrentStateOrderBy = {
  asset?: InputMaybe<IAssetOrderBy>;
  asset_id?: InputMaybe<IOrderBy>;
  asset_state_variant?: InputMaybe<IAssetStateVariantOrderBy>;
  asset_state_variant_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  ended_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  note?: InputMaybe<IOrderBy>;
  started_at?: InputMaybe<IOrderBy>;
  work_order?: InputMaybe<IWorkOrderOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** select columns of table "asset_current_state" */
export enum IAssetCurrentStateSelectColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  AssetStateVariantId = 'asset_state_variant_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Note = 'note',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  WorkOrderId = 'work_order_id'
}

/** Streaming cursor of the table "asset_current_state" */
export type IAssetCurrentStateStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IAssetCurrentStateStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IAssetCurrentStateStreamCursorValueInput = {
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  asset_state_variant_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  ended_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "asset_default_state" */
export type IAssetDefaultState = {
  __typename: 'asset_default_state';
  description: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "asset_default_state" */
export type IAssetDefaultStateAggregate = {
  __typename: 'asset_default_state_aggregate';
  aggregate?: Maybe<IAssetDefaultStateAggregateFields>;
  nodes: Array<IAssetDefaultState>;
};

/** aggregate fields of "asset_default_state" */
export type IAssetDefaultStateAggregateFields = {
  __typename: 'asset_default_state_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IAssetDefaultStateMaxFields>;
  min?: Maybe<IAssetDefaultStateMinFields>;
};


/** aggregate fields of "asset_default_state" */
export type IAssetDefaultStateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IAssetDefaultStateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "asset_default_state". All fields are combined with a logical 'AND'. */
export type IAssetDefaultStateBoolExp = {
  _and?: InputMaybe<Array<IAssetDefaultStateBoolExp>>;
  _not?: InputMaybe<IAssetDefaultStateBoolExp>;
  _or?: InputMaybe<Array<IAssetDefaultStateBoolExp>>;
  description?: InputMaybe<IStringComparisonExp>;
  value?: InputMaybe<IStringComparisonExp>;
};

/** unique or primary key constraints on table "asset_default_state" */
export enum IAssetDefaultStateConstraint {
  /** unique or primary key constraint on columns "value" */
  AssetDefaultStatePkey = 'asset_default_state_pkey'
}

export enum IAssetDefaultStateEnum {
  /** Not working, action required */
  Broken = 'broken',
  /** Everything okay */
  FullyFunctional = 'fully_functional',
  /** Not working due to maintenance */
  Maintenance = 'maintenance',
  /** Not working but currently being repaired */
  Repair = 'repair'
}

/** Boolean expression to compare columns of type "asset_default_state_enum". All fields are combined with logical 'AND'. */
export type IAssetDefaultStateEnumComparisonExp = {
  _eq?: InputMaybe<IAssetDefaultStateEnum>;
  _in?: InputMaybe<Array<IAssetDefaultStateEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<IAssetDefaultStateEnum>;
  _nin?: InputMaybe<Array<IAssetDefaultStateEnum>>;
};

/** input type for inserting data into table "asset_default_state" */
export type IAssetDefaultStateInsertInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type IAssetDefaultStateMaxFields = {
  __typename: 'asset_default_state_max_fields';
  description?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IAssetDefaultStateMinFields = {
  __typename: 'asset_default_state_min_fields';
  description?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "asset_default_state" */
export type IAssetDefaultStateMutationResponse = {
  __typename: 'asset_default_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IAssetDefaultState>;
};

/** on_conflict condition type for table "asset_default_state" */
export type IAssetDefaultStateOnConflict = {
  constraint: IAssetDefaultStateConstraint;
  update_columns?: Array<IAssetDefaultStateUpdateColumn>;
  where?: InputMaybe<IAssetDefaultStateBoolExp>;
};

/** Ordering options when selecting data from "asset_default_state". */
export type IAssetDefaultStateOrderBy = {
  description?: InputMaybe<IOrderBy>;
  value?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: asset_default_state */
export type IAssetDefaultStatePkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "asset_default_state" */
export enum IAssetDefaultStateSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "asset_default_state" */
export type IAssetDefaultStateSetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "asset_default_state" */
export type IAssetDefaultStateStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IAssetDefaultStateStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IAssetDefaultStateStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "asset_default_state" */
export enum IAssetDefaultStateUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type IAssetDefaultStateUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IAssetDefaultStateSetInput>;
  /** filter the rows which have to be updated */
  where: IAssetDefaultStateBoolExp;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IAssetDeleteAtPathInput = {
  custom_fields?: InputMaybe<Array<Scalars['String']['input']>>;
  parent_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  support_contact_ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IAssetDeleteElemInput = {
  custom_fields?: InputMaybe<Scalars['Int']['input']>;
  parent_ids?: InputMaybe<Scalars['Int']['input']>;
  support_contact_ids?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IAssetDeleteKeyInput = {
  custom_fields?: InputMaybe<Scalars['String']['input']>;
  parent_ids?: InputMaybe<Scalars['String']['input']>;
  support_contact_ids?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "asset_group" */
export type IAssetGroup = {
  __typename: 'asset_group';
  /** An array relationship */
  assets: Array<IAsset>;
  /** An aggregate relationship */
  assets_aggregate: IAssetAggregate;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  location: ILocation;
  location_id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "asset_group" */
export type IAssetGroupAssetsArgs = {
  distinct_on?: InputMaybe<Array<IAssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetOrderBy>>;
  where?: InputMaybe<IAssetBoolExp>;
};


/** columns and relationships of "asset_group" */
export type IAssetGroupAssetsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetOrderBy>>;
  where?: InputMaybe<IAssetBoolExp>;
};

/** aggregated selection of "asset_group" */
export type IAssetGroupAggregate = {
  __typename: 'asset_group_aggregate';
  aggregate?: Maybe<IAssetGroupAggregateFields>;
  nodes: Array<IAssetGroup>;
};

export type IAssetGroupAggregateBoolExp = {
  count?: InputMaybe<IAssetGroupAggregateBoolExpCount>;
};

export type IAssetGroupAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IAssetGroupSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IAssetGroupBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "asset_group" */
export type IAssetGroupAggregateFields = {
  __typename: 'asset_group_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IAssetGroupMaxFields>;
  min?: Maybe<IAssetGroupMinFields>;
};


/** aggregate fields of "asset_group" */
export type IAssetGroupAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IAssetGroupSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "asset_group" */
export type IAssetGroupAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IAssetGroupMaxOrderBy>;
  min?: InputMaybe<IAssetGroupMinOrderBy>;
};

/** input type for inserting array relation for remote table "asset_group" */
export type IAssetGroupArrRelInsertInput = {
  data: Array<IAssetGroupInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IAssetGroupOnConflict>;
};

/** Boolean expression to filter rows from the table "asset_group". All fields are combined with a logical 'AND'. */
export type IAssetGroupBoolExp = {
  _and?: InputMaybe<Array<IAssetGroupBoolExp>>;
  _not?: InputMaybe<IAssetGroupBoolExp>;
  _or?: InputMaybe<Array<IAssetGroupBoolExp>>;
  assets?: InputMaybe<IAssetBoolExp>;
  assets_aggregate?: InputMaybe<IAssetAggregateBoolExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  location?: InputMaybe<ILocationBoolExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "asset_group" */
export enum IAssetGroupConstraint {
  /** unique or primary key constraint on columns "location_id", "name" */
  AssetGroupNameLocationIdKey = 'asset_group_name_location_id_key',
  /** unique or primary key constraint on columns "id" */
  AssetGroupPkey = 'asset_group_pkey'
}

/** input type for inserting data into table "asset_group" */
export type IAssetGroupInsertInput = {
  assets?: InputMaybe<IAssetArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location?: InputMaybe<ILocationObjRelInsertInput>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type IAssetGroupMaxFields = {
  __typename: 'asset_group_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "asset_group" */
export type IAssetGroupMaxOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IAssetGroupMinFields = {
  __typename: 'asset_group_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "asset_group" */
export type IAssetGroupMinOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "asset_group" */
export type IAssetGroupMutationResponse = {
  __typename: 'asset_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IAssetGroup>;
};

/** input type for inserting object relation for remote table "asset_group" */
export type IAssetGroupObjRelInsertInput = {
  data: IAssetGroupInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IAssetGroupOnConflict>;
};

/** on_conflict condition type for table "asset_group" */
export type IAssetGroupOnConflict = {
  constraint: IAssetGroupConstraint;
  update_columns?: Array<IAssetGroupUpdateColumn>;
  where?: InputMaybe<IAssetGroupBoolExp>;
};

/** Ordering options when selecting data from "asset_group". */
export type IAssetGroupOrderBy = {
  assets_aggregate?: InputMaybe<IAssetAggregateOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location?: InputMaybe<ILocationOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: asset_group */
export type IAssetGroupPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "asset_group" */
export enum IAssetGroupSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "asset_group" */
export type IAssetGroupSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "asset_group" */
export type IAssetGroupStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IAssetGroupStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IAssetGroupStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "asset_group" */
export enum IAssetGroupUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type IAssetGroupUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IAssetGroupSetInput>;
  /** filter the rows which have to be updated */
  where: IAssetGroupBoolExp;
};

/** input type for incrementing numeric columns in table "asset" */
export type IAssetIncInput = {
  rank?: InputMaybe<Scalars['Int']['input']>;
  year_of_purchase?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "asset" */
export type IAssetInsertInput = {
  archived_at?: InputMaybe<Scalars['timestamptz']['input']>;
  asset_consumables?: InputMaybe<IAssetXConsumableArrRelInsertInput>;
  avatar?: InputMaybe<IUploadObjRelInsertInput>;
  avatar_id?: InputMaybe<Scalars['uuid']['input']>;
  comments?: InputMaybe<ICommentArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<IUserObjRelInsertInput>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  current_state?: InputMaybe<IAssetCurrentStateObjRelInsertInput>;
  custom_fields?: InputMaybe<Scalars['jsonb']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  department?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<IAssetXUploadArrRelInsertInput>;
  file_folder?: InputMaybe<IFileFolderObjRelInsertInput>;
  file_folder_id?: InputMaybe<Scalars['uuid']['input']>;
  group?: InputMaybe<IAssetGroupObjRelInsertInput>;
  group_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  last_state?: InputMaybe<IAssetLastStateViewObjRelInsertInput>;
  location?: InputMaybe<ILocationObjRelInsertInput>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  manual?: InputMaybe<IUploadObjRelInsertInput>;
  manual_id?: InputMaybe<Scalars['uuid']['input']>;
  manufacturer?: InputMaybe<IAssetManufacturerObjRelInsertInput>;
  manufacturer_id?: InputMaybe<Scalars['uuid']['input']>;
  meters?: InputMaybe<IMeterArrRelInsertInput>;
  model_number?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  operating_schedules?: InputMaybe<IAssetOperatingScheduleArrRelInsertInput>;
  operating_since?: InputMaybe<Scalars['timestamptz']['input']>;
  parent_asset?: InputMaybe<IAssetObjRelInsertInput>;
  parent_asset_id?: InputMaybe<Scalars['uuid']['input']>;
  parent_ids?: InputMaybe<Scalars['jsonb']['input']>;
  place?: InputMaybe<IPlaceObjRelInsertInput>;
  place_id?: InputMaybe<Scalars['uuid']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
  qr_code?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  register_plate?: InputMaybe<IUploadObjRelInsertInput>;
  register_plate_id?: InputMaybe<Scalars['uuid']['input']>;
  replaced_by?: InputMaybe<IAssetObjRelInsertInput>;
  replaced_by_id?: InputMaybe<Scalars['uuid']['input']>;
  replacement_of?: InputMaybe<IAssetObjRelInsertInput>;
  search_value?: InputMaybe<Scalars['String']['input']>;
  serial_number?: InputMaybe<Scalars['String']['input']>;
  states?: InputMaybe<IAssetXAssetStateArrRelInsertInput>;
  sub_assets?: InputMaybe<IAssetArrRelInsertInput>;
  support_contact_ids?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_orders?: InputMaybe<IAssetXWorkOrderArrRelInsertInput>;
  year_of_purchase?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "asset_last_state_view" */
export type IAssetLastStateView = {
  __typename: 'asset_last_state_view';
  asset_id?: Maybe<Scalars['uuid']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_first_name?: Maybe<Scalars['String']['output']>;
  created_by_last_name?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  state_id?: Maybe<Scalars['uuid']['output']>;
  state_name?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "asset_last_state_view" */
export type IAssetLastStateViewAggregate = {
  __typename: 'asset_last_state_view_aggregate';
  aggregate?: Maybe<IAssetLastStateViewAggregateFields>;
  nodes: Array<IAssetLastStateView>;
};

/** aggregate fields of "asset_last_state_view" */
export type IAssetLastStateViewAggregateFields = {
  __typename: 'asset_last_state_view_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IAssetLastStateViewMaxFields>;
  min?: Maybe<IAssetLastStateViewMinFields>;
};


/** aggregate fields of "asset_last_state_view" */
export type IAssetLastStateViewAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IAssetLastStateViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "asset_last_state_view". All fields are combined with a logical 'AND'. */
export type IAssetLastStateViewBoolExp = {
  _and?: InputMaybe<Array<IAssetLastStateViewBoolExp>>;
  _not?: InputMaybe<IAssetLastStateViewBoolExp>;
  _or?: InputMaybe<Array<IAssetLastStateViewBoolExp>>;
  asset_id?: InputMaybe<IUuidComparisonExp>;
  color?: InputMaybe<IStringComparisonExp>;
  completed_at?: InputMaybe<ITimestamptzComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  created_by_first_name?: InputMaybe<IStringComparisonExp>;
  created_by_last_name?: InputMaybe<IStringComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  state_id?: InputMaybe<IUuidComparisonExp>;
  state_name?: InputMaybe<IStringComparisonExp>;
  title?: InputMaybe<IStringComparisonExp>;
  work_order_id?: InputMaybe<IUuidComparisonExp>;
};

/** input type for inserting data into table "asset_last_state_view" */
export type IAssetLastStateViewInsertInput = {
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_first_name?: InputMaybe<Scalars['String']['input']>;
  created_by_last_name?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  state_id?: InputMaybe<Scalars['uuid']['input']>;
  state_name?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IAssetLastStateViewMaxFields = {
  __typename: 'asset_last_state_view_max_fields';
  asset_id?: Maybe<Scalars['uuid']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_first_name?: Maybe<Scalars['String']['output']>;
  created_by_last_name?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  state_id?: Maybe<Scalars['uuid']['output']>;
  state_name?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type IAssetLastStateViewMinFields = {
  __typename: 'asset_last_state_view_min_fields';
  asset_id?: Maybe<Scalars['uuid']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_first_name?: Maybe<Scalars['String']['output']>;
  created_by_last_name?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  state_id?: Maybe<Scalars['uuid']['output']>;
  state_name?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** input type for inserting object relation for remote table "asset_last_state_view" */
export type IAssetLastStateViewObjRelInsertInput = {
  data: IAssetLastStateViewInsertInput;
};

/** Ordering options when selecting data from "asset_last_state_view". */
export type IAssetLastStateViewOrderBy = {
  asset_id?: InputMaybe<IOrderBy>;
  color?: InputMaybe<IOrderBy>;
  completed_at?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by_first_name?: InputMaybe<IOrderBy>;
  created_by_last_name?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  state_id?: InputMaybe<IOrderBy>;
  state_name?: InputMaybe<IOrderBy>;
  title?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** select columns of table "asset_last_state_view" */
export enum IAssetLastStateViewSelectColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  Color = 'color',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByFirstName = 'created_by_first_name',
  /** column name */
  CreatedByLastName = 'created_by_last_name',
  /** column name */
  Description = 'description',
  /** column name */
  StateId = 'state_id',
  /** column name */
  StateName = 'state_name',
  /** column name */
  Title = 'title',
  /** column name */
  WorkOrderId = 'work_order_id'
}

/** Streaming cursor of the table "asset_last_state_view" */
export type IAssetLastStateViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IAssetLastStateViewStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IAssetLastStateViewStreamCursorValueInput = {
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_first_name?: InputMaybe<Scalars['String']['input']>;
  created_by_last_name?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  state_id?: InputMaybe<Scalars['uuid']['input']>;
  state_name?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "asset_manufacturer" */
export type IAssetManufacturer = {
  __typename: 'asset_manufacturer';
  contact?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  org: IOrg;
  org_id: Scalars['uuid']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "asset_manufacturer" */
export type IAssetManufacturerAggregate = {
  __typename: 'asset_manufacturer_aggregate';
  aggregate?: Maybe<IAssetManufacturerAggregateFields>;
  nodes: Array<IAssetManufacturer>;
};

export type IAssetManufacturerAggregateBoolExp = {
  count?: InputMaybe<IAssetManufacturerAggregateBoolExpCount>;
};

export type IAssetManufacturerAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IAssetManufacturerSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IAssetManufacturerBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "asset_manufacturer" */
export type IAssetManufacturerAggregateFields = {
  __typename: 'asset_manufacturer_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IAssetManufacturerMaxFields>;
  min?: Maybe<IAssetManufacturerMinFields>;
};


/** aggregate fields of "asset_manufacturer" */
export type IAssetManufacturerAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IAssetManufacturerSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "asset_manufacturer" */
export type IAssetManufacturerAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IAssetManufacturerMaxOrderBy>;
  min?: InputMaybe<IAssetManufacturerMinOrderBy>;
};

/** input type for inserting array relation for remote table "asset_manufacturer" */
export type IAssetManufacturerArrRelInsertInput = {
  data: Array<IAssetManufacturerInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IAssetManufacturerOnConflict>;
};

/** Boolean expression to filter rows from the table "asset_manufacturer". All fields are combined with a logical 'AND'. */
export type IAssetManufacturerBoolExp = {
  _and?: InputMaybe<Array<IAssetManufacturerBoolExp>>;
  _not?: InputMaybe<IAssetManufacturerBoolExp>;
  _or?: InputMaybe<Array<IAssetManufacturerBoolExp>>;
  contact?: InputMaybe<IStringComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  email?: InputMaybe<IStringComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  org?: InputMaybe<IOrgBoolExp>;
  org_id?: InputMaybe<IUuidComparisonExp>;
  phone?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "asset_manufacturer" */
export enum IAssetManufacturerConstraint {
  /** unique or primary key constraint on columns "id" */
  AssetManufacturerPkey = 'asset_manufacturer_pkey'
}

/** input type for inserting data into table "asset_manufacturer" */
export type IAssetManufacturerInsertInput = {
  contact?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org?: InputMaybe<IOrgObjRelInsertInput>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type IAssetManufacturerMaxFields = {
  __typename: 'asset_manufacturer_max_fields';
  contact?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "asset_manufacturer" */
export type IAssetManufacturerMaxOrderBy = {
  contact?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  email?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  org_id?: InputMaybe<IOrderBy>;
  phone?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IAssetManufacturerMinFields = {
  __typename: 'asset_manufacturer_min_fields';
  contact?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "asset_manufacturer" */
export type IAssetManufacturerMinOrderBy = {
  contact?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  email?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  org_id?: InputMaybe<IOrderBy>;
  phone?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "asset_manufacturer" */
export type IAssetManufacturerMutationResponse = {
  __typename: 'asset_manufacturer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IAssetManufacturer>;
};

/** input type for inserting object relation for remote table "asset_manufacturer" */
export type IAssetManufacturerObjRelInsertInput = {
  data: IAssetManufacturerInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IAssetManufacturerOnConflict>;
};

/** on_conflict condition type for table "asset_manufacturer" */
export type IAssetManufacturerOnConflict = {
  constraint: IAssetManufacturerConstraint;
  update_columns?: Array<IAssetManufacturerUpdateColumn>;
  where?: InputMaybe<IAssetManufacturerBoolExp>;
};

/** Ordering options when selecting data from "asset_manufacturer". */
export type IAssetManufacturerOrderBy = {
  contact?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  email?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  org?: InputMaybe<IOrgOrderBy>;
  org_id?: InputMaybe<IOrderBy>;
  phone?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: asset_manufacturer */
export type IAssetManufacturerPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "asset_manufacturer" */
export enum IAssetManufacturerSelectColumn {
  /** column name */
  Contact = 'contact',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Phone = 'phone',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "asset_manufacturer" */
export type IAssetManufacturerSetInput = {
  contact?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "asset_manufacturer" */
export type IAssetManufacturerStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IAssetManufacturerStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IAssetManufacturerStreamCursorValueInput = {
  contact?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "asset_manufacturer" */
export enum IAssetManufacturerUpdateColumn {
  /** column name */
  Contact = 'contact',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Phone = 'phone',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type IAssetManufacturerUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IAssetManufacturerSetInput>;
  /** filter the rows which have to be updated */
  where: IAssetManufacturerBoolExp;
};

/** aggregate max on columns */
export type IAssetMaxFields = {
  __typename: 'asset_max_fields';
  archived_at?: Maybe<Scalars['timestamptz']['output']>;
  avatar_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  department?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  file_folder_id?: Maybe<Scalars['uuid']['output']>;
  group_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  manual_id?: Maybe<Scalars['uuid']['output']>;
  manufacturer_id?: Maybe<Scalars['uuid']['output']>;
  model_number?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  operating_since?: Maybe<Scalars['timestamptz']['output']>;
  parent_asset_id?: Maybe<Scalars['uuid']['output']>;
  place_id?: Maybe<Scalars['uuid']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  public_id?: Maybe<Scalars['String']['output']>;
  qr_code?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
  register_plate_id?: Maybe<Scalars['uuid']['output']>;
  replaced_by_id?: Maybe<Scalars['uuid']['output']>;
  search_value?: Maybe<Scalars['String']['output']>;
  serial_number?: Maybe<Scalars['String']['output']>;
  tree_depth?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  year_of_purchase?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "asset" */
export type IAssetMaxOrderBy = {
  archived_at?: InputMaybe<IOrderBy>;
  avatar_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  deleted_at?: InputMaybe<IOrderBy>;
  department?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  file_folder_id?: InputMaybe<IOrderBy>;
  group_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  manual_id?: InputMaybe<IOrderBy>;
  manufacturer_id?: InputMaybe<IOrderBy>;
  model_number?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  note?: InputMaybe<IOrderBy>;
  operating_since?: InputMaybe<IOrderBy>;
  parent_asset_id?: InputMaybe<IOrderBy>;
  place_id?: InputMaybe<IOrderBy>;
  position?: InputMaybe<IOrderBy>;
  public_id?: InputMaybe<IOrderBy>;
  qr_code?: InputMaybe<IOrderBy>;
  rank?: InputMaybe<IOrderBy>;
  register_plate_id?: InputMaybe<IOrderBy>;
  replaced_by_id?: InputMaybe<IOrderBy>;
  search_value?: InputMaybe<IOrderBy>;
  serial_number?: InputMaybe<IOrderBy>;
  tree_depth?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  year_of_purchase?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IAssetMinFields = {
  __typename: 'asset_min_fields';
  archived_at?: Maybe<Scalars['timestamptz']['output']>;
  avatar_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  department?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  file_folder_id?: Maybe<Scalars['uuid']['output']>;
  group_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  manual_id?: Maybe<Scalars['uuid']['output']>;
  manufacturer_id?: Maybe<Scalars['uuid']['output']>;
  model_number?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  operating_since?: Maybe<Scalars['timestamptz']['output']>;
  parent_asset_id?: Maybe<Scalars['uuid']['output']>;
  place_id?: Maybe<Scalars['uuid']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  public_id?: Maybe<Scalars['String']['output']>;
  qr_code?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
  register_plate_id?: Maybe<Scalars['uuid']['output']>;
  replaced_by_id?: Maybe<Scalars['uuid']['output']>;
  search_value?: Maybe<Scalars['String']['output']>;
  serial_number?: Maybe<Scalars['String']['output']>;
  tree_depth?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  year_of_purchase?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "asset" */
export type IAssetMinOrderBy = {
  archived_at?: InputMaybe<IOrderBy>;
  avatar_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  deleted_at?: InputMaybe<IOrderBy>;
  department?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  file_folder_id?: InputMaybe<IOrderBy>;
  group_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  manual_id?: InputMaybe<IOrderBy>;
  manufacturer_id?: InputMaybe<IOrderBy>;
  model_number?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  note?: InputMaybe<IOrderBy>;
  operating_since?: InputMaybe<IOrderBy>;
  parent_asset_id?: InputMaybe<IOrderBy>;
  place_id?: InputMaybe<IOrderBy>;
  position?: InputMaybe<IOrderBy>;
  public_id?: InputMaybe<IOrderBy>;
  qr_code?: InputMaybe<IOrderBy>;
  rank?: InputMaybe<IOrderBy>;
  register_plate_id?: InputMaybe<IOrderBy>;
  replaced_by_id?: InputMaybe<IOrderBy>;
  search_value?: InputMaybe<IOrderBy>;
  serial_number?: InputMaybe<IOrderBy>;
  tree_depth?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  year_of_purchase?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "asset" */
export type IAssetMutationResponse = {
  __typename: 'asset_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IAsset>;
};

/** input type for inserting object relation for remote table "asset" */
export type IAssetObjRelInsertInput = {
  data: IAssetInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IAssetOnConflict>;
};

/** on_conflict condition type for table "asset" */
export type IAssetOnConflict = {
  constraint: IAssetConstraint;
  update_columns?: Array<IAssetUpdateColumn>;
  where?: InputMaybe<IAssetBoolExp>;
};

/** Operating schedules for an assets */
export type IAssetOperatingSchedule = {
  __typename: 'asset_operating_schedule';
  asset_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  location_id: Scalars['uuid']['output'];
  schedule: Scalars['jsonb']['output'];
  valid_from: Scalars['timestamptz']['output'];
  valid_range?: Maybe<Scalars['tstzrange']['output']>;
  valid_until?: Maybe<Scalars['timestamptz']['output']>;
};


/** Operating schedules for an assets */
export type IAssetOperatingScheduleScheduleArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "asset_operating_schedule" */
export type IAssetOperatingScheduleAggregate = {
  __typename: 'asset_operating_schedule_aggregate';
  aggregate?: Maybe<IAssetOperatingScheduleAggregateFields>;
  nodes: Array<IAssetOperatingSchedule>;
};

export type IAssetOperatingScheduleAggregateBoolExp = {
  count?: InputMaybe<IAssetOperatingScheduleAggregateBoolExpCount>;
};

export type IAssetOperatingScheduleAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IAssetOperatingScheduleSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IAssetOperatingScheduleBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "asset_operating_schedule" */
export type IAssetOperatingScheduleAggregateFields = {
  __typename: 'asset_operating_schedule_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IAssetOperatingScheduleMaxFields>;
  min?: Maybe<IAssetOperatingScheduleMinFields>;
};


/** aggregate fields of "asset_operating_schedule" */
export type IAssetOperatingScheduleAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IAssetOperatingScheduleSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "asset_operating_schedule" */
export type IAssetOperatingScheduleAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IAssetOperatingScheduleMaxOrderBy>;
  min?: InputMaybe<IAssetOperatingScheduleMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IAssetOperatingScheduleAppendInput = {
  schedule?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "asset_operating_schedule" */
export type IAssetOperatingScheduleArrRelInsertInput = {
  data: Array<IAssetOperatingScheduleInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IAssetOperatingScheduleOnConflict>;
};

/** Boolean expression to filter rows from the table "asset_operating_schedule". All fields are combined with a logical 'AND'. */
export type IAssetOperatingScheduleBoolExp = {
  _and?: InputMaybe<Array<IAssetOperatingScheduleBoolExp>>;
  _not?: InputMaybe<IAssetOperatingScheduleBoolExp>;
  _or?: InputMaybe<Array<IAssetOperatingScheduleBoolExp>>;
  asset_id?: InputMaybe<IUuidComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  schedule?: InputMaybe<IJsonbComparisonExp>;
  valid_from?: InputMaybe<ITimestamptzComparisonExp>;
  valid_range?: InputMaybe<ITstzrangeComparisonExp>;
  valid_until?: InputMaybe<ITimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "asset_operating_schedule" */
export enum IAssetOperatingScheduleConstraint {
  /** unique or primary key constraint on columns "id" */
  AssetOperatingSchedulePkey = 'asset_operating_schedule_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IAssetOperatingScheduleDeleteAtPathInput = {
  schedule?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IAssetOperatingScheduleDeleteElemInput = {
  schedule?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IAssetOperatingScheduleDeleteKeyInput = {
  schedule?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "asset_operating_schedule" */
export type IAssetOperatingScheduleInsertInput = {
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  schedule?: InputMaybe<Scalars['jsonb']['input']>;
  valid_from?: InputMaybe<Scalars['timestamptz']['input']>;
  valid_until?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type IAssetOperatingScheduleMaxFields = {
  __typename: 'asset_operating_schedule_max_fields';
  asset_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  valid_from?: Maybe<Scalars['timestamptz']['output']>;
  valid_until?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "asset_operating_schedule" */
export type IAssetOperatingScheduleMaxOrderBy = {
  asset_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  valid_from?: InputMaybe<IOrderBy>;
  valid_until?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IAssetOperatingScheduleMinFields = {
  __typename: 'asset_operating_schedule_min_fields';
  asset_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  valid_from?: Maybe<Scalars['timestamptz']['output']>;
  valid_until?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "asset_operating_schedule" */
export type IAssetOperatingScheduleMinOrderBy = {
  asset_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  valid_from?: InputMaybe<IOrderBy>;
  valid_until?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "asset_operating_schedule" */
export type IAssetOperatingScheduleMutationResponse = {
  __typename: 'asset_operating_schedule_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IAssetOperatingSchedule>;
};

/** on_conflict condition type for table "asset_operating_schedule" */
export type IAssetOperatingScheduleOnConflict = {
  constraint: IAssetOperatingScheduleConstraint;
  update_columns?: Array<IAssetOperatingScheduleUpdateColumn>;
  where?: InputMaybe<IAssetOperatingScheduleBoolExp>;
};

/** Ordering options when selecting data from "asset_operating_schedule". */
export type IAssetOperatingScheduleOrderBy = {
  asset_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  schedule?: InputMaybe<IOrderBy>;
  valid_from?: InputMaybe<IOrderBy>;
  valid_range?: InputMaybe<IOrderBy>;
  valid_until?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: asset_operating_schedule */
export type IAssetOperatingSchedulePkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IAssetOperatingSchedulePrependInput = {
  schedule?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "asset_operating_schedule" */
export enum IAssetOperatingScheduleSelectColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Schedule = 'schedule',
  /** column name */
  ValidFrom = 'valid_from',
  /** column name */
  ValidRange = 'valid_range',
  /** column name */
  ValidUntil = 'valid_until'
}

/** input type for updating data in table "asset_operating_schedule" */
export type IAssetOperatingScheduleSetInput = {
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  schedule?: InputMaybe<Scalars['jsonb']['input']>;
  valid_from?: InputMaybe<Scalars['timestamptz']['input']>;
  valid_until?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "asset_operating_schedule" */
export type IAssetOperatingScheduleStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IAssetOperatingScheduleStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IAssetOperatingScheduleStreamCursorValueInput = {
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  schedule?: InputMaybe<Scalars['jsonb']['input']>;
  valid_from?: InputMaybe<Scalars['timestamptz']['input']>;
  valid_range?: InputMaybe<Scalars['tstzrange']['input']>;
  valid_until?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "asset_operating_schedule" */
export enum IAssetOperatingScheduleUpdateColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Schedule = 'schedule',
  /** column name */
  ValidFrom = 'valid_from',
  /** column name */
  ValidUntil = 'valid_until'
}

export type IAssetOperatingScheduleUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IAssetOperatingScheduleAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IAssetOperatingScheduleDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IAssetOperatingScheduleDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IAssetOperatingScheduleDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IAssetOperatingSchedulePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IAssetOperatingScheduleSetInput>;
  /** filter the rows which have to be updated */
  where: IAssetOperatingScheduleBoolExp;
};

/** Ordering options when selecting data from "asset". */
export type IAssetOrderBy = {
  archived_at?: InputMaybe<IOrderBy>;
  asset_consumables_aggregate?: InputMaybe<IAssetXConsumableAggregateOrderBy>;
  avatar?: InputMaybe<IUploadOrderBy>;
  avatar_id?: InputMaybe<IOrderBy>;
  comments_aggregate?: InputMaybe<ICommentAggregateOrderBy>;
  consumables_aggregate?: InputMaybe<IConsumableAggregateOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by?: InputMaybe<IUserOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  current_state?: InputMaybe<IAssetCurrentStateOrderBy>;
  custom_fields?: InputMaybe<IOrderBy>;
  deleted_at?: InputMaybe<IOrderBy>;
  department?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  documents_aggregate?: InputMaybe<IAssetXUploadAggregateOrderBy>;
  file_folder?: InputMaybe<IFileFolderOrderBy>;
  file_folder_id?: InputMaybe<IOrderBy>;
  group?: InputMaybe<IAssetGroupOrderBy>;
  group_id?: InputMaybe<IOrderBy>;
  has_recurring_work_order?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  last_state?: InputMaybe<IAssetLastStateViewOrderBy>;
  location?: InputMaybe<ILocationOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  manual?: InputMaybe<IUploadOrderBy>;
  manual_id?: InputMaybe<IOrderBy>;
  manufacturer?: InputMaybe<IAssetManufacturerOrderBy>;
  manufacturer_id?: InputMaybe<IOrderBy>;
  meters_aggregate?: InputMaybe<IMeterAggregateOrderBy>;
  model_number?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  note?: InputMaybe<IOrderBy>;
  operating_schedules_aggregate?: InputMaybe<IAssetOperatingScheduleAggregateOrderBy>;
  operating_since?: InputMaybe<IOrderBy>;
  parent_asset?: InputMaybe<IAssetOrderBy>;
  parent_asset_id?: InputMaybe<IOrderBy>;
  parent_ids?: InputMaybe<IOrderBy>;
  parents_aggregate?: InputMaybe<IAssetAggregateOrderBy>;
  place?: InputMaybe<IPlaceOrderBy>;
  place_id?: InputMaybe<IOrderBy>;
  position?: InputMaybe<IOrderBy>;
  public_id?: InputMaybe<IOrderBy>;
  qr_code?: InputMaybe<IOrderBy>;
  rank?: InputMaybe<IOrderBy>;
  register_plate?: InputMaybe<IUploadOrderBy>;
  register_plate_id?: InputMaybe<IOrderBy>;
  replaced_by?: InputMaybe<IAssetOrderBy>;
  replaced_by_id?: InputMaybe<IOrderBy>;
  replacement_of?: InputMaybe<IAssetOrderBy>;
  search_value?: InputMaybe<IOrderBy>;
  serial_number?: InputMaybe<IOrderBy>;
  states_aggregate?: InputMaybe<IAssetXAssetStateAggregateOrderBy>;
  sub_assets_aggregate?: InputMaybe<IAssetAggregateOrderBy>;
  support_contact_ids?: InputMaybe<IOrderBy>;
  support_contacts_aggregate?: InputMaybe<IContactAggregateOrderBy>;
  tree_depth?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  work_orders_aggregate?: InputMaybe<IAssetXWorkOrderAggregateOrderBy>;
  year_of_purchase?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: asset */
export type IAssetPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IAssetPrependInput = {
  custom_fields?: InputMaybe<Scalars['jsonb']['input']>;
  parent_ids?: InputMaybe<Scalars['jsonb']['input']>;
  support_contact_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "asset" */
export enum IAssetSelectColumn {
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  AvatarId = 'avatar_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  CustomFields = 'custom_fields',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Department = 'department',
  /** column name */
  Description = 'description',
  /** column name */
  FileFolderId = 'file_folder_id',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  ManualId = 'manual_id',
  /** column name */
  ManufacturerId = 'manufacturer_id',
  /** column name */
  ModelNumber = 'model_number',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  OperatingSince = 'operating_since',
  /** column name */
  ParentAssetId = 'parent_asset_id',
  /** column name */
  ParentIds = 'parent_ids',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  Position = 'position',
  /** column name */
  PublicId = 'public_id',
  /** column name */
  QrCode = 'qr_code',
  /** column name */
  Rank = 'rank',
  /** column name */
  RegisterPlateId = 'register_plate_id',
  /** column name */
  ReplacedById = 'replaced_by_id',
  /** column name */
  SearchValue = 'search_value',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  SupportContactIds = 'support_contact_ids',
  /** column name */
  TreeDepth = 'tree_depth',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  YearOfPurchase = 'year_of_purchase'
}

/** input type for updating data in table "asset" */
export type IAssetSetInput = {
  archived_at?: InputMaybe<Scalars['timestamptz']['input']>;
  avatar_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  custom_fields?: InputMaybe<Scalars['jsonb']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  department?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  file_folder_id?: InputMaybe<Scalars['uuid']['input']>;
  group_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  manual_id?: InputMaybe<Scalars['uuid']['input']>;
  manufacturer_id?: InputMaybe<Scalars['uuid']['input']>;
  model_number?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  operating_since?: InputMaybe<Scalars['timestamptz']['input']>;
  parent_asset_id?: InputMaybe<Scalars['uuid']['input']>;
  parent_ids?: InputMaybe<Scalars['jsonb']['input']>;
  place_id?: InputMaybe<Scalars['uuid']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
  qr_code?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  register_plate_id?: InputMaybe<Scalars['uuid']['input']>;
  replaced_by_id?: InputMaybe<Scalars['uuid']['input']>;
  search_value?: InputMaybe<Scalars['String']['input']>;
  serial_number?: InputMaybe<Scalars['String']['input']>;
  support_contact_ids?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  year_of_purchase?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "asset_state" */
export type IAssetState = {
  __typename: 'asset_state';
  color?: Maybe<Scalars['String']['output']>;
  default_state_id?: Maybe<IAssetDefaultStateEnum>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  location?: Maybe<ILocation>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name: Scalars['String']['output'];
};

/** aggregated selection of "asset_state" */
export type IAssetStateAggregate = {
  __typename: 'asset_state_aggregate';
  aggregate?: Maybe<IAssetStateAggregateFields>;
  nodes: Array<IAssetState>;
};

/** aggregate fields of "asset_state" */
export type IAssetStateAggregateFields = {
  __typename: 'asset_state_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IAssetStateMaxFields>;
  min?: Maybe<IAssetStateMinFields>;
};


/** aggregate fields of "asset_state" */
export type IAssetStateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IAssetStateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "asset_state". All fields are combined with a logical 'AND'. */
export type IAssetStateBoolExp = {
  _and?: InputMaybe<Array<IAssetStateBoolExp>>;
  _not?: InputMaybe<IAssetStateBoolExp>;
  _or?: InputMaybe<Array<IAssetStateBoolExp>>;
  color?: InputMaybe<IStringComparisonExp>;
  default_state_id?: InputMaybe<IAssetDefaultStateEnumComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  location?: InputMaybe<ILocationBoolExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
};

/** unique or primary key constraints on table "asset_state" */
export enum IAssetStateConstraint {
  /** unique or primary key constraint on columns "default_state_id" */
  AssetStateDefaultStateIdKey = 'asset_state_default_state_id_key',
  /** unique or primary key constraint on columns "location_id", "name" */
  AssetStateNameLocationIdKey = 'asset_state_name_location_id_key',
  /** unique or primary key constraint on columns "id" */
  AssetStatePkey = 'asset_state_pkey'
}

/** input type for inserting data into table "asset_state" */
export type IAssetStateInsertInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  default_state_id?: InputMaybe<IAssetDefaultStateEnum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location?: InputMaybe<ILocationObjRelInsertInput>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** History asset state values */
export type IAssetStateLog = {
  __typename: 'asset_state_log';
  /** An object relationship */
  asset: IAsset;
  asset_id: Scalars['uuid']['output'];
  /** An object relationship */
  asset_state_variant: IAssetStateVariant;
  asset_state_variant_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by: IUser;
  created_by_id: Scalars['uuid']['output'];
  /** A computed field, executes function "downtime_in_operating_hours" */
  downtime_in_operating_hours?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "downtime_split_operating_hours" */
  downtime_split_operating_hours?: Maybe<Scalars['json']['output']>;
  ended_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  location_id: Scalars['uuid']['output'];
  note?: Maybe<Scalars['String']['output']>;
  started_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  work_order?: Maybe<IWorkOrder>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};


/** History asset state values */
export type IAssetStateLogDowntimeSplitOperatingHoursArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "asset_state_log" */
export type IAssetStateLogAggregate = {
  __typename: 'asset_state_log_aggregate';
  aggregate?: Maybe<IAssetStateLogAggregateFields>;
  nodes: Array<IAssetStateLog>;
};

/** aggregate fields of "asset_state_log" */
export type IAssetStateLogAggregateFields = {
  __typename: 'asset_state_log_aggregate_fields';
  avg?: Maybe<IAssetStateLogAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IAssetStateLogMaxFields>;
  min?: Maybe<IAssetStateLogMinFields>;
  stddev?: Maybe<IAssetStateLogStddevFields>;
  stddev_pop?: Maybe<IAssetStateLogStddevPopFields>;
  stddev_samp?: Maybe<IAssetStateLogStddevSampFields>;
  sum?: Maybe<IAssetStateLogSumFields>;
  var_pop?: Maybe<IAssetStateLogVarPopFields>;
  var_samp?: Maybe<IAssetStateLogVarSampFields>;
  variance?: Maybe<IAssetStateLogVarianceFields>;
};


/** aggregate fields of "asset_state_log" */
export type IAssetStateLogAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IAssetStateLogSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type IAssetStateLogAvgFields = {
  __typename: 'asset_state_log_avg_fields';
  /** A computed field, executes function "downtime_in_operating_hours" */
  downtime_in_operating_hours?: Maybe<Scalars['numeric']['output']>;
};

/** Boolean expression to filter rows from the table "asset_state_log". All fields are combined with a logical 'AND'. */
export type IAssetStateLogBoolExp = {
  _and?: InputMaybe<Array<IAssetStateLogBoolExp>>;
  _not?: InputMaybe<IAssetStateLogBoolExp>;
  _or?: InputMaybe<Array<IAssetStateLogBoolExp>>;
  asset?: InputMaybe<IAssetBoolExp>;
  asset_id?: InputMaybe<IUuidComparisonExp>;
  asset_state_variant?: InputMaybe<IAssetStateVariantBoolExp>;
  asset_state_variant_id?: InputMaybe<IUuidComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  created_by?: InputMaybe<IUserBoolExp>;
  created_by_id?: InputMaybe<IUuidComparisonExp>;
  downtime_in_operating_hours?: InputMaybe<INumericComparisonExp>;
  downtime_split_operating_hours?: InputMaybe<IJsonComparisonExp>;
  ended_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  note?: InputMaybe<IStringComparisonExp>;
  started_at?: InputMaybe<ITimestamptzComparisonExp>;
  work_order?: InputMaybe<IWorkOrderBoolExp>;
  work_order_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "asset_state_log" */
export enum IAssetStateLogConstraint {
  /** unique or primary key constraint on columns "id" */
  AssetStateLogPkey = 'asset_state_log_pkey'
}

/** input type for inserting data into table "asset_state_log" */
export type IAssetStateLogInsertInput = {
  asset?: InputMaybe<IAssetObjRelInsertInput>;
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  asset_state_variant?: InputMaybe<IAssetStateVariantObjRelInsertInput>;
  asset_state_variant_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<IUserObjRelInsertInput>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  ended_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_order?: InputMaybe<IWorkOrderObjRelInsertInput>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IAssetStateLogMaxFields = {
  __typename: 'asset_state_log_max_fields';
  asset_id?: Maybe<Scalars['uuid']['output']>;
  asset_state_variant_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "downtime_in_operating_hours" */
  downtime_in_operating_hours?: Maybe<Scalars['numeric']['output']>;
  ended_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  started_at?: Maybe<Scalars['timestamptz']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type IAssetStateLogMinFields = {
  __typename: 'asset_state_log_min_fields';
  asset_id?: Maybe<Scalars['uuid']['output']>;
  asset_state_variant_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "downtime_in_operating_hours" */
  downtime_in_operating_hours?: Maybe<Scalars['numeric']['output']>;
  ended_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  started_at?: Maybe<Scalars['timestamptz']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "asset_state_log" */
export type IAssetStateLogMutationResponse = {
  __typename: 'asset_state_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IAssetStateLog>;
};

/** on_conflict condition type for table "asset_state_log" */
export type IAssetStateLogOnConflict = {
  constraint: IAssetStateLogConstraint;
  update_columns?: Array<IAssetStateLogUpdateColumn>;
  where?: InputMaybe<IAssetStateLogBoolExp>;
};

/** Ordering options when selecting data from "asset_state_log". */
export type IAssetStateLogOrderBy = {
  asset?: InputMaybe<IAssetOrderBy>;
  asset_id?: InputMaybe<IOrderBy>;
  asset_state_variant?: InputMaybe<IAssetStateVariantOrderBy>;
  asset_state_variant_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by?: InputMaybe<IUserOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  downtime_in_operating_hours?: InputMaybe<IOrderBy>;
  downtime_split_operating_hours?: InputMaybe<IOrderBy>;
  ended_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  note?: InputMaybe<IOrderBy>;
  started_at?: InputMaybe<IOrderBy>;
  work_order?: InputMaybe<IWorkOrderOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: asset_state_log */
export type IAssetStateLogPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "asset_state_log" */
export enum IAssetStateLogSelectColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  AssetStateVariantId = 'asset_state_variant_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Note = 'note',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  WorkOrderId = 'work_order_id'
}

/** input type for updating data in table "asset_state_log" */
export type IAssetStateLogSetInput = {
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  asset_state_variant_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  ended_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type IAssetStateLogStddevFields = {
  __typename: 'asset_state_log_stddev_fields';
  /** A computed field, executes function "downtime_in_operating_hours" */
  downtime_in_operating_hours?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate stddev_pop on columns */
export type IAssetStateLogStddevPopFields = {
  __typename: 'asset_state_log_stddev_pop_fields';
  /** A computed field, executes function "downtime_in_operating_hours" */
  downtime_in_operating_hours?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate stddev_samp on columns */
export type IAssetStateLogStddevSampFields = {
  __typename: 'asset_state_log_stddev_samp_fields';
  /** A computed field, executes function "downtime_in_operating_hours" */
  downtime_in_operating_hours?: Maybe<Scalars['numeric']['output']>;
};

/** Streaming cursor of the table "asset_state_log" */
export type IAssetStateLogStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IAssetStateLogStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IAssetStateLogStreamCursorValueInput = {
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  asset_state_variant_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  ended_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type IAssetStateLogSumFields = {
  __typename: 'asset_state_log_sum_fields';
  /** A computed field, executes function "downtime_in_operating_hours" */
  downtime_in_operating_hours?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "asset_state_log" */
export enum IAssetStateLogUpdateColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  AssetStateVariantId = 'asset_state_variant_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Note = 'note',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  WorkOrderId = 'work_order_id'
}

export type IAssetStateLogUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IAssetStateLogSetInput>;
  /** filter the rows which have to be updated */
  where: IAssetStateLogBoolExp;
};

/** aggregate var_pop on columns */
export type IAssetStateLogVarPopFields = {
  __typename: 'asset_state_log_var_pop_fields';
  /** A computed field, executes function "downtime_in_operating_hours" */
  downtime_in_operating_hours?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate var_samp on columns */
export type IAssetStateLogVarSampFields = {
  __typename: 'asset_state_log_var_samp_fields';
  /** A computed field, executes function "downtime_in_operating_hours" */
  downtime_in_operating_hours?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate variance on columns */
export type IAssetStateLogVarianceFields = {
  __typename: 'asset_state_log_variance_fields';
  /** A computed field, executes function "downtime_in_operating_hours" */
  downtime_in_operating_hours?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate max on columns */
export type IAssetStateMaxFields = {
  __typename: 'asset_state_max_fields';
  color?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IAssetStateMinFields = {
  __typename: 'asset_state_min_fields';
  color?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "asset_state" */
export type IAssetStateMutationResponse = {
  __typename: 'asset_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IAssetState>;
};

/** input type for inserting object relation for remote table "asset_state" */
export type IAssetStateObjRelInsertInput = {
  data: IAssetStateInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IAssetStateOnConflict>;
};

/** on_conflict condition type for table "asset_state" */
export type IAssetStateOnConflict = {
  constraint: IAssetStateConstraint;
  update_columns?: Array<IAssetStateUpdateColumn>;
  where?: InputMaybe<IAssetStateBoolExp>;
};

/** Ordering options when selecting data from "asset_state". */
export type IAssetStateOrderBy = {
  color?: InputMaybe<IOrderBy>;
  default_state_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location?: InputMaybe<ILocationOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: asset_state */
export type IAssetStatePkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "asset_state" */
export enum IAssetStateSelectColumn {
  /** column name */
  Color = 'color',
  /** column name */
  DefaultStateId = 'default_state_id',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "asset_state" */
export type IAssetStateSetInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  default_state_id?: InputMaybe<IAssetDefaultStateEnum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "asset_state" */
export type IAssetStateStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IAssetStateStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IAssetStateStreamCursorValueInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  default_state_id?: InputMaybe<IAssetDefaultStateEnum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "asset_state" */
export enum IAssetStateUpdateColumn {
  /** column name */
  Color = 'color',
  /** column name */
  DefaultStateId = 'default_state_id',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name'
}

export type IAssetStateUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IAssetStateSetInput>;
  /** filter the rows which have to be updated */
  where: IAssetStateBoolExp;
};

/** Possible state values an asset can be in */
export type IAssetStateVariant = {
  __typename: 'asset_state_variant';
  id: Scalars['uuid']['output'];
  location_id?: Maybe<Scalars['uuid']['output']>;
  planned: Scalars['Boolean']['output'];
  subtype: Scalars['String']['output'];
  type: IAssetStateVariantTypeEnum;
};

/** aggregated selection of "asset_state_variant" */
export type IAssetStateVariantAggregate = {
  __typename: 'asset_state_variant_aggregate';
  aggregate?: Maybe<IAssetStateVariantAggregateFields>;
  nodes: Array<IAssetStateVariant>;
};

/** aggregate fields of "asset_state_variant" */
export type IAssetStateVariantAggregateFields = {
  __typename: 'asset_state_variant_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IAssetStateVariantMaxFields>;
  min?: Maybe<IAssetStateVariantMinFields>;
};


/** aggregate fields of "asset_state_variant" */
export type IAssetStateVariantAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IAssetStateVariantSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "asset_state_variant". All fields are combined with a logical 'AND'. */
export type IAssetStateVariantBoolExp = {
  _and?: InputMaybe<Array<IAssetStateVariantBoolExp>>;
  _not?: InputMaybe<IAssetStateVariantBoolExp>;
  _or?: InputMaybe<Array<IAssetStateVariantBoolExp>>;
  id?: InputMaybe<IUuidComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  planned?: InputMaybe<IBooleanComparisonExp>;
  subtype?: InputMaybe<IStringComparisonExp>;
  type?: InputMaybe<IAssetStateVariantTypeEnumComparisonExp>;
};

/** unique or primary key constraints on table "asset_state_variant" */
export enum IAssetStateVariantConstraint {
  /** unique or primary key constraint on columns "type", "location_id", "subtype" */
  AssetStateVariantLocationIdTypeSubtypeKey = 'asset_state_variant_location_id_type_subtype_key',
  /** unique or primary key constraint on columns "id" */
  AssetStateVariantPkey = 'asset_state_variant_pkey'
}

/** input type for inserting data into table "asset_state_variant" */
export type IAssetStateVariantInsertInput = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  planned?: InputMaybe<Scalars['Boolean']['input']>;
  subtype?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<IAssetStateVariantTypeEnum>;
};

/** aggregate max on columns */
export type IAssetStateVariantMaxFields = {
  __typename: 'asset_state_variant_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  subtype?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IAssetStateVariantMinFields = {
  __typename: 'asset_state_variant_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  subtype?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "asset_state_variant" */
export type IAssetStateVariantMutationResponse = {
  __typename: 'asset_state_variant_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IAssetStateVariant>;
};

/** input type for inserting object relation for remote table "asset_state_variant" */
export type IAssetStateVariantObjRelInsertInput = {
  data: IAssetStateVariantInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IAssetStateVariantOnConflict>;
};

/** on_conflict condition type for table "asset_state_variant" */
export type IAssetStateVariantOnConflict = {
  constraint: IAssetStateVariantConstraint;
  update_columns?: Array<IAssetStateVariantUpdateColumn>;
  where?: InputMaybe<IAssetStateVariantBoolExp>;
};

/** Ordering options when selecting data from "asset_state_variant". */
export type IAssetStateVariantOrderBy = {
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  planned?: InputMaybe<IOrderBy>;
  subtype?: InputMaybe<IOrderBy>;
  type?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: asset_state_variant */
export type IAssetStateVariantPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "asset_state_variant" */
export enum IAssetStateVariantSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Planned = 'planned',
  /** column name */
  Subtype = 'subtype',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "asset_state_variant" */
export type IAssetStateVariantSetInput = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  planned?: InputMaybe<Scalars['Boolean']['input']>;
  subtype?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<IAssetStateVariantTypeEnum>;
};

/** Streaming cursor of the table "asset_state_variant" */
export type IAssetStateVariantStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IAssetStateVariantStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IAssetStateVariantStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  planned?: InputMaybe<Scalars['Boolean']['input']>;
  subtype?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<IAssetStateVariantTypeEnum>;
};

/** columns and relationships of "asset_state_variant_type" */
export type IAssetStateVariantType = {
  __typename: 'asset_state_variant_type';
  value: Scalars['String']['output'];
};

/** aggregated selection of "asset_state_variant_type" */
export type IAssetStateVariantTypeAggregate = {
  __typename: 'asset_state_variant_type_aggregate';
  aggregate?: Maybe<IAssetStateVariantTypeAggregateFields>;
  nodes: Array<IAssetStateVariantType>;
};

/** aggregate fields of "asset_state_variant_type" */
export type IAssetStateVariantTypeAggregateFields = {
  __typename: 'asset_state_variant_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IAssetStateVariantTypeMaxFields>;
  min?: Maybe<IAssetStateVariantTypeMinFields>;
};


/** aggregate fields of "asset_state_variant_type" */
export type IAssetStateVariantTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IAssetStateVariantTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "asset_state_variant_type". All fields are combined with a logical 'AND'. */
export type IAssetStateVariantTypeBoolExp = {
  _and?: InputMaybe<Array<IAssetStateVariantTypeBoolExp>>;
  _not?: InputMaybe<IAssetStateVariantTypeBoolExp>;
  _or?: InputMaybe<Array<IAssetStateVariantTypeBoolExp>>;
  value?: InputMaybe<IStringComparisonExp>;
};

/** unique or primary key constraints on table "asset_state_variant_type" */
export enum IAssetStateVariantTypeConstraint {
  /** unique or primary key constraint on columns "value" */
  AssetStateVariantTypePkey = 'asset_state_variant_type_pkey'
}

export enum IAssetStateVariantTypeEnum {
  DoNotTrack = 'do_not_track',
  Offline = 'offline',
  Online = 'online'
}

/** Boolean expression to compare columns of type "asset_state_variant_type_enum". All fields are combined with logical 'AND'. */
export type IAssetStateVariantTypeEnumComparisonExp = {
  _eq?: InputMaybe<IAssetStateVariantTypeEnum>;
  _in?: InputMaybe<Array<IAssetStateVariantTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<IAssetStateVariantTypeEnum>;
  _nin?: InputMaybe<Array<IAssetStateVariantTypeEnum>>;
};

/** input type for inserting data into table "asset_state_variant_type" */
export type IAssetStateVariantTypeInsertInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type IAssetStateVariantTypeMaxFields = {
  __typename: 'asset_state_variant_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IAssetStateVariantTypeMinFields = {
  __typename: 'asset_state_variant_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "asset_state_variant_type" */
export type IAssetStateVariantTypeMutationResponse = {
  __typename: 'asset_state_variant_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IAssetStateVariantType>;
};

/** on_conflict condition type for table "asset_state_variant_type" */
export type IAssetStateVariantTypeOnConflict = {
  constraint: IAssetStateVariantTypeConstraint;
  update_columns?: Array<IAssetStateVariantTypeUpdateColumn>;
  where?: InputMaybe<IAssetStateVariantTypeBoolExp>;
};

/** Ordering options when selecting data from "asset_state_variant_type". */
export type IAssetStateVariantTypeOrderBy = {
  value?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: asset_state_variant_type */
export type IAssetStateVariantTypePkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "asset_state_variant_type" */
export enum IAssetStateVariantTypeSelectColumn {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "asset_state_variant_type" */
export type IAssetStateVariantTypeSetInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "asset_state_variant_type" */
export type IAssetStateVariantTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IAssetStateVariantTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IAssetStateVariantTypeStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "asset_state_variant_type" */
export enum IAssetStateVariantTypeUpdateColumn {
  /** column name */
  Value = 'value'
}

export type IAssetStateVariantTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IAssetStateVariantTypeSetInput>;
  /** filter the rows which have to be updated */
  where: IAssetStateVariantTypeBoolExp;
};

/** update columns of table "asset_state_variant" */
export enum IAssetStateVariantUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Planned = 'planned',
  /** column name */
  Subtype = 'subtype',
  /** column name */
  Type = 'type'
}

export type IAssetStateVariantUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IAssetStateVariantSetInput>;
  /** filter the rows which have to be updated */
  where: IAssetStateVariantBoolExp;
};

/** aggregate stddev on columns */
export type IAssetStddevFields = {
  __typename: 'asset_stddev_fields';
  rank?: Maybe<Scalars['Float']['output']>;
  tree_depth?: Maybe<Scalars['Float']['output']>;
  year_of_purchase?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "asset" */
export type IAssetStddevOrderBy = {
  rank?: InputMaybe<IOrderBy>;
  tree_depth?: InputMaybe<IOrderBy>;
  year_of_purchase?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_pop on columns */
export type IAssetStddevPopFields = {
  __typename: 'asset_stddev_pop_fields';
  rank?: Maybe<Scalars['Float']['output']>;
  tree_depth?: Maybe<Scalars['Float']['output']>;
  year_of_purchase?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "asset" */
export type IAssetStddevPopOrderBy = {
  rank?: InputMaybe<IOrderBy>;
  tree_depth?: InputMaybe<IOrderBy>;
  year_of_purchase?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_samp on columns */
export type IAssetStddevSampFields = {
  __typename: 'asset_stddev_samp_fields';
  rank?: Maybe<Scalars['Float']['output']>;
  tree_depth?: Maybe<Scalars['Float']['output']>;
  year_of_purchase?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "asset" */
export type IAssetStddevSampOrderBy = {
  rank?: InputMaybe<IOrderBy>;
  tree_depth?: InputMaybe<IOrderBy>;
  year_of_purchase?: InputMaybe<IOrderBy>;
};

/** Streaming cursor of the table "asset" */
export type IAssetStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IAssetStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IAssetStreamCursorValueInput = {
  archived_at?: InputMaybe<Scalars['timestamptz']['input']>;
  avatar_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  custom_fields?: InputMaybe<Scalars['jsonb']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  department?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  file_folder_id?: InputMaybe<Scalars['uuid']['input']>;
  group_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  manual_id?: InputMaybe<Scalars['uuid']['input']>;
  manufacturer_id?: InputMaybe<Scalars['uuid']['input']>;
  model_number?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  operating_since?: InputMaybe<Scalars['timestamptz']['input']>;
  parent_asset_id?: InputMaybe<Scalars['uuid']['input']>;
  parent_ids?: InputMaybe<Scalars['jsonb']['input']>;
  place_id?: InputMaybe<Scalars['uuid']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
  qr_code?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  register_plate_id?: InputMaybe<Scalars['uuid']['input']>;
  replaced_by_id?: InputMaybe<Scalars['uuid']['input']>;
  search_value?: InputMaybe<Scalars['String']['input']>;
  serial_number?: InputMaybe<Scalars['String']['input']>;
  support_contact_ids?: InputMaybe<Scalars['jsonb']['input']>;
  tree_depth?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  year_of_purchase?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type IAssetSumFields = {
  __typename: 'asset_sum_fields';
  rank?: Maybe<Scalars['Int']['output']>;
  tree_depth?: Maybe<Scalars['Int']['output']>;
  year_of_purchase?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "asset" */
export type IAssetSumOrderBy = {
  rank?: InputMaybe<IOrderBy>;
  tree_depth?: InputMaybe<IOrderBy>;
  year_of_purchase?: InputMaybe<IOrderBy>;
};

/** update columns of table "asset" */
export enum IAssetUpdateColumn {
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  AvatarId = 'avatar_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  CustomFields = 'custom_fields',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Department = 'department',
  /** column name */
  Description = 'description',
  /** column name */
  FileFolderId = 'file_folder_id',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  ManualId = 'manual_id',
  /** column name */
  ManufacturerId = 'manufacturer_id',
  /** column name */
  ModelNumber = 'model_number',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  OperatingSince = 'operating_since',
  /** column name */
  ParentAssetId = 'parent_asset_id',
  /** column name */
  ParentIds = 'parent_ids',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  Position = 'position',
  /** column name */
  PublicId = 'public_id',
  /** column name */
  QrCode = 'qr_code',
  /** column name */
  Rank = 'rank',
  /** column name */
  RegisterPlateId = 'register_plate_id',
  /** column name */
  ReplacedById = 'replaced_by_id',
  /** column name */
  SearchValue = 'search_value',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  SupportContactIds = 'support_contact_ids',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  YearOfPurchase = 'year_of_purchase'
}

export type IAssetUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IAssetAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IAssetDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IAssetDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IAssetDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IAssetIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IAssetPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IAssetSetInput>;
  /** filter the rows which have to be updated */
  where: IAssetBoolExp;
};

/** aggregate var_pop on columns */
export type IAssetVarPopFields = {
  __typename: 'asset_var_pop_fields';
  rank?: Maybe<Scalars['Float']['output']>;
  tree_depth?: Maybe<Scalars['Float']['output']>;
  year_of_purchase?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "asset" */
export type IAssetVarPopOrderBy = {
  rank?: InputMaybe<IOrderBy>;
  tree_depth?: InputMaybe<IOrderBy>;
  year_of_purchase?: InputMaybe<IOrderBy>;
};

/** aggregate var_samp on columns */
export type IAssetVarSampFields = {
  __typename: 'asset_var_samp_fields';
  rank?: Maybe<Scalars['Float']['output']>;
  tree_depth?: Maybe<Scalars['Float']['output']>;
  year_of_purchase?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "asset" */
export type IAssetVarSampOrderBy = {
  rank?: InputMaybe<IOrderBy>;
  tree_depth?: InputMaybe<IOrderBy>;
  year_of_purchase?: InputMaybe<IOrderBy>;
};

/** aggregate variance on columns */
export type IAssetVarianceFields = {
  __typename: 'asset_variance_fields';
  rank?: Maybe<Scalars['Float']['output']>;
  tree_depth?: Maybe<Scalars['Float']['output']>;
  year_of_purchase?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "asset" */
export type IAssetVarianceOrderBy = {
  rank?: InputMaybe<IOrderBy>;
  tree_depth?: InputMaybe<IOrderBy>;
  year_of_purchase?: InputMaybe<IOrderBy>;
};

/** columns and relationships of "asset_x_asset_state" */
export type IAssetXAssetState = {
  __typename: 'asset_x_asset_state';
  /** An object relationship */
  asset: IAsset;
  asset_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by: IUser;
  created_by_id: Scalars['uuid']['output'];
  /** Should be dropped in the future */
  description?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  duration?: Maybe<IAssetXAssetStateDuration>;
  id: Scalars['uuid']['output'];
  is_initial_state: Scalars['Boolean']['output'];
  /** A computed field, executes function "compute_next_asset_state_created_at" */
  next_state_created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  state: IAssetState;
  state_id: Scalars['uuid']['output'];
  /** An object relationship */
  work_order?: Maybe<IWorkOrder>;
  work_order_id: Scalars['uuid']['output'];
};

/** aggregated selection of "asset_x_asset_state" */
export type IAssetXAssetStateAggregate = {
  __typename: 'asset_x_asset_state_aggregate';
  aggregate?: Maybe<IAssetXAssetStateAggregateFields>;
  nodes: Array<IAssetXAssetState>;
};

export type IAssetXAssetStateAggregateBoolExp = {
  bool_and?: InputMaybe<IAssetXAssetStateAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<IAssetXAssetStateAggregateBoolExpBoolOr>;
  count?: InputMaybe<IAssetXAssetStateAggregateBoolExpCount>;
};

export type IAssetXAssetStateAggregateBoolExpBoolAnd = {
  arguments: IAssetXAssetStateSelectColumnAssetXAssetStateAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IAssetXAssetStateBoolExp>;
  predicate: IBooleanComparisonExp;
};

export type IAssetXAssetStateAggregateBoolExpBoolOr = {
  arguments: IAssetXAssetStateSelectColumnAssetXAssetStateAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IAssetXAssetStateBoolExp>;
  predicate: IBooleanComparisonExp;
};

export type IAssetXAssetStateAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IAssetXAssetStateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IAssetXAssetStateBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "asset_x_asset_state" */
export type IAssetXAssetStateAggregateFields = {
  __typename: 'asset_x_asset_state_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IAssetXAssetStateMaxFields>;
  min?: Maybe<IAssetXAssetStateMinFields>;
};


/** aggregate fields of "asset_x_asset_state" */
export type IAssetXAssetStateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IAssetXAssetStateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "asset_x_asset_state" */
export type IAssetXAssetStateAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IAssetXAssetStateMaxOrderBy>;
  min?: InputMaybe<IAssetXAssetStateMinOrderBy>;
};

/** input type for inserting array relation for remote table "asset_x_asset_state" */
export type IAssetXAssetStateArrRelInsertInput = {
  data: Array<IAssetXAssetStateInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IAssetXAssetStateOnConflict>;
};

/** Boolean expression to filter rows from the table "asset_x_asset_state". All fields are combined with a logical 'AND'. */
export type IAssetXAssetStateBoolExp = {
  _and?: InputMaybe<Array<IAssetXAssetStateBoolExp>>;
  _not?: InputMaybe<IAssetXAssetStateBoolExp>;
  _or?: InputMaybe<Array<IAssetXAssetStateBoolExp>>;
  asset?: InputMaybe<IAssetBoolExp>;
  asset_id?: InputMaybe<IUuidComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  created_by?: InputMaybe<IUserBoolExp>;
  created_by_id?: InputMaybe<IUuidComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  duration?: InputMaybe<IAssetXAssetStateDurationBoolExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  is_initial_state?: InputMaybe<IBooleanComparisonExp>;
  next_state_created_at?: InputMaybe<ITimestamptzComparisonExp>;
  state?: InputMaybe<IAssetStateBoolExp>;
  state_id?: InputMaybe<IUuidComparisonExp>;
  work_order?: InputMaybe<IWorkOrderBoolExp>;
  work_order_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "asset_x_asset_state" */
export enum IAssetXAssetStateConstraint {
  /** unique or primary key constraint on columns "id" */
  AssetXAssetStatePkey = 'asset_x_asset_state_pkey',
  /** unique or primary key constraint on columns "work_order_id" */
  AssetXAssetStateWorkOrderIdKey = 'asset_x_asset_state_work_order_id_key'
}

/** columns and relationships of "asset_x_asset_state_duration" */
export type IAssetXAssetStateDuration = {
  __typename: 'asset_x_asset_state_duration';
  duration?: Maybe<Scalars['interval']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "asset_x_asset_state_duration" */
export type IAssetXAssetStateDurationAggregate = {
  __typename: 'asset_x_asset_state_duration_aggregate';
  aggregate?: Maybe<IAssetXAssetStateDurationAggregateFields>;
  nodes: Array<IAssetXAssetStateDuration>;
};

/** aggregate fields of "asset_x_asset_state_duration" */
export type IAssetXAssetStateDurationAggregateFields = {
  __typename: 'asset_x_asset_state_duration_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IAssetXAssetStateDurationMaxFields>;
  min?: Maybe<IAssetXAssetStateDurationMinFields>;
};


/** aggregate fields of "asset_x_asset_state_duration" */
export type IAssetXAssetStateDurationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IAssetXAssetStateDurationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "asset_x_asset_state_duration". All fields are combined with a logical 'AND'. */
export type IAssetXAssetStateDurationBoolExp = {
  _and?: InputMaybe<Array<IAssetXAssetStateDurationBoolExp>>;
  _not?: InputMaybe<IAssetXAssetStateDurationBoolExp>;
  _or?: InputMaybe<Array<IAssetXAssetStateDurationBoolExp>>;
  duration?: InputMaybe<IIntervalComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
};

/** input type for inserting data into table "asset_x_asset_state_duration" */
export type IAssetXAssetStateDurationInsertInput = {
  duration?: InputMaybe<Scalars['interval']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IAssetXAssetStateDurationMaxFields = {
  __typename: 'asset_x_asset_state_duration_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type IAssetXAssetStateDurationMinFields = {
  __typename: 'asset_x_asset_state_duration_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "asset_x_asset_state_duration" */
export type IAssetXAssetStateDurationMutationResponse = {
  __typename: 'asset_x_asset_state_duration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IAssetXAssetStateDuration>;
};

/** input type for inserting object relation for remote table "asset_x_asset_state_duration" */
export type IAssetXAssetStateDurationObjRelInsertInput = {
  data: IAssetXAssetStateDurationInsertInput;
};

/** Ordering options when selecting data from "asset_x_asset_state_duration". */
export type IAssetXAssetStateDurationOrderBy = {
  duration?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
};

/** select columns of table "asset_x_asset_state_duration" */
export enum IAssetXAssetStateDurationSelectColumn {
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "asset_x_asset_state_duration" */
export type IAssetXAssetStateDurationSetInput = {
  duration?: InputMaybe<Scalars['interval']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "asset_x_asset_state_duration" */
export type IAssetXAssetStateDurationStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IAssetXAssetStateDurationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IAssetXAssetStateDurationStreamCursorValueInput = {
  duration?: InputMaybe<Scalars['interval']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

export type IAssetXAssetStateDurationUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IAssetXAssetStateDurationSetInput>;
  /** filter the rows which have to be updated */
  where: IAssetXAssetStateDurationBoolExp;
};

/** input type for inserting data into table "asset_x_asset_state" */
export type IAssetXAssetStateInsertInput = {
  asset?: InputMaybe<IAssetObjRelInsertInput>;
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<IUserObjRelInsertInput>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  /** Should be dropped in the future */
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<IAssetXAssetStateDurationObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_initial_state?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<IAssetStateObjRelInsertInput>;
  state_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order?: InputMaybe<IWorkOrderObjRelInsertInput>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IAssetXAssetStateMaxFields = {
  __typename: 'asset_x_asset_state_max_fields';
  asset_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  /** Should be dropped in the future */
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "compute_next_asset_state_created_at" */
  next_state_created_at?: Maybe<Scalars['timestamptz']['output']>;
  state_id?: Maybe<Scalars['uuid']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "asset_x_asset_state" */
export type IAssetXAssetStateMaxOrderBy = {
  asset_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  /** Should be dropped in the future */
  description?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  state_id?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IAssetXAssetStateMinFields = {
  __typename: 'asset_x_asset_state_min_fields';
  asset_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  /** Should be dropped in the future */
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "compute_next_asset_state_created_at" */
  next_state_created_at?: Maybe<Scalars['timestamptz']['output']>;
  state_id?: Maybe<Scalars['uuid']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "asset_x_asset_state" */
export type IAssetXAssetStateMinOrderBy = {
  asset_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  /** Should be dropped in the future */
  description?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  state_id?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "asset_x_asset_state" */
export type IAssetXAssetStateMutationResponse = {
  __typename: 'asset_x_asset_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IAssetXAssetState>;
};

/** input type for inserting object relation for remote table "asset_x_asset_state" */
export type IAssetXAssetStateObjRelInsertInput = {
  data: IAssetXAssetStateInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IAssetXAssetStateOnConflict>;
};

/** on_conflict condition type for table "asset_x_asset_state" */
export type IAssetXAssetStateOnConflict = {
  constraint: IAssetXAssetStateConstraint;
  update_columns?: Array<IAssetXAssetStateUpdateColumn>;
  where?: InputMaybe<IAssetXAssetStateBoolExp>;
};

/** Ordering options when selecting data from "asset_x_asset_state". */
export type IAssetXAssetStateOrderBy = {
  asset?: InputMaybe<IAssetOrderBy>;
  asset_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by?: InputMaybe<IUserOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  duration?: InputMaybe<IAssetXAssetStateDurationOrderBy>;
  id?: InputMaybe<IOrderBy>;
  is_initial_state?: InputMaybe<IOrderBy>;
  next_state_created_at?: InputMaybe<IOrderBy>;
  state?: InputMaybe<IAssetStateOrderBy>;
  state_id?: InputMaybe<IOrderBy>;
  work_order?: InputMaybe<IWorkOrderOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: asset_x_asset_state */
export type IAssetXAssetStatePkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "asset_x_asset_state" */
export enum IAssetXAssetStateSelectColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsInitialState = 'is_initial_state',
  /** column name */
  StateId = 'state_id',
  /** column name */
  WorkOrderId = 'work_order_id'
}

/** select "asset_x_asset_state_aggregate_bool_exp_bool_and_arguments_columns" columns of table "asset_x_asset_state" */
export enum IAssetXAssetStateSelectColumnAssetXAssetStateAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsInitialState = 'is_initial_state'
}

/** select "asset_x_asset_state_aggregate_bool_exp_bool_or_arguments_columns" columns of table "asset_x_asset_state" */
export enum IAssetXAssetStateSelectColumnAssetXAssetStateAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsInitialState = 'is_initial_state'
}

/** input type for updating data in table "asset_x_asset_state" */
export type IAssetXAssetStateSetInput = {
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  /** Should be dropped in the future */
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_initial_state?: InputMaybe<Scalars['Boolean']['input']>;
  state_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "asset_x_asset_state" */
export type IAssetXAssetStateStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IAssetXAssetStateStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IAssetXAssetStateStreamCursorValueInput = {
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  /** Should be dropped in the future */
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_initial_state?: InputMaybe<Scalars['Boolean']['input']>;
  state_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "asset_x_asset_state" */
export enum IAssetXAssetStateUpdateColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsInitialState = 'is_initial_state',
  /** column name */
  StateId = 'state_id',
  /** column name */
  WorkOrderId = 'work_order_id'
}

export type IAssetXAssetStateUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IAssetXAssetStateSetInput>;
  /** filter the rows which have to be updated */
  where: IAssetXAssetStateBoolExp;
};

/** columns and relationships of "asset_x_consumable" */
export type IAssetXConsumable = {
  __typename: 'asset_x_consumable';
  /** An object relationship */
  asset: IAsset;
  asset_id: Scalars['uuid']['output'];
  /** An object relationship */
  consumable: IConsumable;
  consumable_id: Scalars['uuid']['output'];
};

/** aggregated selection of "asset_x_consumable" */
export type IAssetXConsumableAggregate = {
  __typename: 'asset_x_consumable_aggregate';
  aggregate?: Maybe<IAssetXConsumableAggregateFields>;
  nodes: Array<IAssetXConsumable>;
};

export type IAssetXConsumableAggregateBoolExp = {
  count?: InputMaybe<IAssetXConsumableAggregateBoolExpCount>;
};

export type IAssetXConsumableAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IAssetXConsumableSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IAssetXConsumableBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "asset_x_consumable" */
export type IAssetXConsumableAggregateFields = {
  __typename: 'asset_x_consumable_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IAssetXConsumableMaxFields>;
  min?: Maybe<IAssetXConsumableMinFields>;
};


/** aggregate fields of "asset_x_consumable" */
export type IAssetXConsumableAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IAssetXConsumableSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "asset_x_consumable" */
export type IAssetXConsumableAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IAssetXConsumableMaxOrderBy>;
  min?: InputMaybe<IAssetXConsumableMinOrderBy>;
};

/** input type for inserting array relation for remote table "asset_x_consumable" */
export type IAssetXConsumableArrRelInsertInput = {
  data: Array<IAssetXConsumableInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IAssetXConsumableOnConflict>;
};

/** Boolean expression to filter rows from the table "asset_x_consumable". All fields are combined with a logical 'AND'. */
export type IAssetXConsumableBoolExp = {
  _and?: InputMaybe<Array<IAssetXConsumableBoolExp>>;
  _not?: InputMaybe<IAssetXConsumableBoolExp>;
  _or?: InputMaybe<Array<IAssetXConsumableBoolExp>>;
  asset?: InputMaybe<IAssetBoolExp>;
  asset_id?: InputMaybe<IUuidComparisonExp>;
  consumable?: InputMaybe<IConsumableBoolExp>;
  consumable_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "asset_x_consumable" */
export enum IAssetXConsumableConstraint {
  /** unique or primary key constraint on columns "asset_id", "consumable_id" */
  AssetXConsumablePkey = 'asset_x_consumable_pkey'
}

/** input type for inserting data into table "asset_x_consumable" */
export type IAssetXConsumableInsertInput = {
  asset?: InputMaybe<IAssetObjRelInsertInput>;
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  consumable?: InputMaybe<IConsumableObjRelInsertInput>;
  consumable_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IAssetXConsumableMaxFields = {
  __typename: 'asset_x_consumable_max_fields';
  asset_id?: Maybe<Scalars['uuid']['output']>;
  consumable_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "asset_x_consumable" */
export type IAssetXConsumableMaxOrderBy = {
  asset_id?: InputMaybe<IOrderBy>;
  consumable_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IAssetXConsumableMinFields = {
  __typename: 'asset_x_consumable_min_fields';
  asset_id?: Maybe<Scalars['uuid']['output']>;
  consumable_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "asset_x_consumable" */
export type IAssetXConsumableMinOrderBy = {
  asset_id?: InputMaybe<IOrderBy>;
  consumable_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "asset_x_consumable" */
export type IAssetXConsumableMutationResponse = {
  __typename: 'asset_x_consumable_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IAssetXConsumable>;
};

/** on_conflict condition type for table "asset_x_consumable" */
export type IAssetXConsumableOnConflict = {
  constraint: IAssetXConsumableConstraint;
  update_columns?: Array<IAssetXConsumableUpdateColumn>;
  where?: InputMaybe<IAssetXConsumableBoolExp>;
};

/** Ordering options when selecting data from "asset_x_consumable". */
export type IAssetXConsumableOrderBy = {
  asset?: InputMaybe<IAssetOrderBy>;
  asset_id?: InputMaybe<IOrderBy>;
  consumable?: InputMaybe<IConsumableOrderBy>;
  consumable_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: asset_x_consumable */
export type IAssetXConsumablePkColumnsInput = {
  asset_id: Scalars['uuid']['input'];
  consumable_id: Scalars['uuid']['input'];
};

/** select columns of table "asset_x_consumable" */
export enum IAssetXConsumableSelectColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  ConsumableId = 'consumable_id'
}

/** input type for updating data in table "asset_x_consumable" */
export type IAssetXConsumableSetInput = {
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  consumable_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "asset_x_consumable" */
export type IAssetXConsumableStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IAssetXConsumableStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IAssetXConsumableStreamCursorValueInput = {
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  consumable_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "asset_x_consumable" */
export enum IAssetXConsumableUpdateColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  ConsumableId = 'consumable_id'
}

export type IAssetXConsumableUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IAssetXConsumableSetInput>;
  /** filter the rows which have to be updated */
  where: IAssetXConsumableBoolExp;
};

/** columns and relationships of "asset_x_upload" */
export type IAssetXUpload = {
  __typename: 'asset_x_upload';
  /** An object relationship */
  asset: IAsset;
  asset_id: Scalars['uuid']['output'];
  /** An object relationship */
  document: IUpload;
  is_avatar?: Maybe<Scalars['Boolean']['output']>;
  upload_id: Scalars['uuid']['output'];
};

/** aggregated selection of "asset_x_upload" */
export type IAssetXUploadAggregate = {
  __typename: 'asset_x_upload_aggregate';
  aggregate?: Maybe<IAssetXUploadAggregateFields>;
  nodes: Array<IAssetXUpload>;
};

export type IAssetXUploadAggregateBoolExp = {
  bool_and?: InputMaybe<IAssetXUploadAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<IAssetXUploadAggregateBoolExpBoolOr>;
  count?: InputMaybe<IAssetXUploadAggregateBoolExpCount>;
};

export type IAssetXUploadAggregateBoolExpBoolAnd = {
  arguments: IAssetXUploadSelectColumnAssetXUploadAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IAssetXUploadBoolExp>;
  predicate: IBooleanComparisonExp;
};

export type IAssetXUploadAggregateBoolExpBoolOr = {
  arguments: IAssetXUploadSelectColumnAssetXUploadAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IAssetXUploadBoolExp>;
  predicate: IBooleanComparisonExp;
};

export type IAssetXUploadAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IAssetXUploadSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IAssetXUploadBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "asset_x_upload" */
export type IAssetXUploadAggregateFields = {
  __typename: 'asset_x_upload_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IAssetXUploadMaxFields>;
  min?: Maybe<IAssetXUploadMinFields>;
};


/** aggregate fields of "asset_x_upload" */
export type IAssetXUploadAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IAssetXUploadSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "asset_x_upload" */
export type IAssetXUploadAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IAssetXUploadMaxOrderBy>;
  min?: InputMaybe<IAssetXUploadMinOrderBy>;
};

/** input type for inserting array relation for remote table "asset_x_upload" */
export type IAssetXUploadArrRelInsertInput = {
  data: Array<IAssetXUploadInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IAssetXUploadOnConflict>;
};

/** Boolean expression to filter rows from the table "asset_x_upload". All fields are combined with a logical 'AND'. */
export type IAssetXUploadBoolExp = {
  _and?: InputMaybe<Array<IAssetXUploadBoolExp>>;
  _not?: InputMaybe<IAssetXUploadBoolExp>;
  _or?: InputMaybe<Array<IAssetXUploadBoolExp>>;
  asset?: InputMaybe<IAssetBoolExp>;
  asset_id?: InputMaybe<IUuidComparisonExp>;
  document?: InputMaybe<IUploadBoolExp>;
  is_avatar?: InputMaybe<IBooleanComparisonExp>;
  upload_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "asset_x_upload" */
export enum IAssetXUploadConstraint {
  /** unique or primary key constraint on columns "upload_id", "asset_id" */
  AssetDocumentsPkey = 'asset_documents_pkey'
}

/** input type for inserting data into table "asset_x_upload" */
export type IAssetXUploadInsertInput = {
  asset?: InputMaybe<IAssetObjRelInsertInput>;
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  document?: InputMaybe<IUploadObjRelInsertInput>;
  is_avatar?: InputMaybe<Scalars['Boolean']['input']>;
  upload_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IAssetXUploadMaxFields = {
  __typename: 'asset_x_upload_max_fields';
  asset_id?: Maybe<Scalars['uuid']['output']>;
  upload_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "asset_x_upload" */
export type IAssetXUploadMaxOrderBy = {
  asset_id?: InputMaybe<IOrderBy>;
  upload_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IAssetXUploadMinFields = {
  __typename: 'asset_x_upload_min_fields';
  asset_id?: Maybe<Scalars['uuid']['output']>;
  upload_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "asset_x_upload" */
export type IAssetXUploadMinOrderBy = {
  asset_id?: InputMaybe<IOrderBy>;
  upload_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "asset_x_upload" */
export type IAssetXUploadMutationResponse = {
  __typename: 'asset_x_upload_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IAssetXUpload>;
};

/** on_conflict condition type for table "asset_x_upload" */
export type IAssetXUploadOnConflict = {
  constraint: IAssetXUploadConstraint;
  update_columns?: Array<IAssetXUploadUpdateColumn>;
  where?: InputMaybe<IAssetXUploadBoolExp>;
};

/** Ordering options when selecting data from "asset_x_upload". */
export type IAssetXUploadOrderBy = {
  asset?: InputMaybe<IAssetOrderBy>;
  asset_id?: InputMaybe<IOrderBy>;
  document?: InputMaybe<IUploadOrderBy>;
  is_avatar?: InputMaybe<IOrderBy>;
  upload_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: asset_x_upload */
export type IAssetXUploadPkColumnsInput = {
  asset_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};

/** select columns of table "asset_x_upload" */
export enum IAssetXUploadSelectColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  IsAvatar = 'is_avatar',
  /** column name */
  UploadId = 'upload_id'
}

/** select "asset_x_upload_aggregate_bool_exp_bool_and_arguments_columns" columns of table "asset_x_upload" */
export enum IAssetXUploadSelectColumnAssetXUploadAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsAvatar = 'is_avatar'
}

/** select "asset_x_upload_aggregate_bool_exp_bool_or_arguments_columns" columns of table "asset_x_upload" */
export enum IAssetXUploadSelectColumnAssetXUploadAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsAvatar = 'is_avatar'
}

/** input type for updating data in table "asset_x_upload" */
export type IAssetXUploadSetInput = {
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  is_avatar?: InputMaybe<Scalars['Boolean']['input']>;
  upload_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "asset_x_upload" */
export type IAssetXUploadStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IAssetXUploadStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IAssetXUploadStreamCursorValueInput = {
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  is_avatar?: InputMaybe<Scalars['Boolean']['input']>;
  upload_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "asset_x_upload" */
export enum IAssetXUploadUpdateColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  IsAvatar = 'is_avatar',
  /** column name */
  UploadId = 'upload_id'
}

export type IAssetXUploadUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IAssetXUploadSetInput>;
  /** filter the rows which have to be updated */
  where: IAssetXUploadBoolExp;
};

/** columns and relationships of "asset_x_work_order" */
export type IAssetXWorkOrder = {
  __typename: 'asset_x_work_order';
  /** An object relationship */
  asset: IAsset;
  asset_id: Scalars['uuid']['output'];
  /** An object relationship */
  work_order: IWorkOrder;
  work_order_id: Scalars['uuid']['output'];
};

/** aggregated selection of "asset_x_work_order" */
export type IAssetXWorkOrderAggregate = {
  __typename: 'asset_x_work_order_aggregate';
  aggregate?: Maybe<IAssetXWorkOrderAggregateFields>;
  nodes: Array<IAssetXWorkOrder>;
};

export type IAssetXWorkOrderAggregateBoolExp = {
  count?: InputMaybe<IAssetXWorkOrderAggregateBoolExpCount>;
};

export type IAssetXWorkOrderAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IAssetXWorkOrderSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IAssetXWorkOrderBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "asset_x_work_order" */
export type IAssetXWorkOrderAggregateFields = {
  __typename: 'asset_x_work_order_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IAssetXWorkOrderMaxFields>;
  min?: Maybe<IAssetXWorkOrderMinFields>;
};


/** aggregate fields of "asset_x_work_order" */
export type IAssetXWorkOrderAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IAssetXWorkOrderSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "asset_x_work_order" */
export type IAssetXWorkOrderAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IAssetXWorkOrderMaxOrderBy>;
  min?: InputMaybe<IAssetXWorkOrderMinOrderBy>;
};

/** input type for inserting array relation for remote table "asset_x_work_order" */
export type IAssetXWorkOrderArrRelInsertInput = {
  data: Array<IAssetXWorkOrderInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IAssetXWorkOrderOnConflict>;
};

/** Boolean expression to filter rows from the table "asset_x_work_order". All fields are combined with a logical 'AND'. */
export type IAssetXWorkOrderBoolExp = {
  _and?: InputMaybe<Array<IAssetXWorkOrderBoolExp>>;
  _not?: InputMaybe<IAssetXWorkOrderBoolExp>;
  _or?: InputMaybe<Array<IAssetXWorkOrderBoolExp>>;
  asset?: InputMaybe<IAssetBoolExp>;
  asset_id?: InputMaybe<IUuidComparisonExp>;
  work_order?: InputMaybe<IWorkOrderBoolExp>;
  work_order_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "asset_x_work_order" */
export enum IAssetXWorkOrderConstraint {
  /** unique or primary key constraint on columns "asset_id", "work_order_id" */
  AssetXWorkOrderPkey = 'asset_x_work_order_pkey'
}

/** input type for inserting data into table "asset_x_work_order" */
export type IAssetXWorkOrderInsertInput = {
  asset?: InputMaybe<IAssetObjRelInsertInput>;
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order?: InputMaybe<IWorkOrderObjRelInsertInput>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IAssetXWorkOrderMaxFields = {
  __typename: 'asset_x_work_order_max_fields';
  asset_id?: Maybe<Scalars['uuid']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "asset_x_work_order" */
export type IAssetXWorkOrderMaxOrderBy = {
  asset_id?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IAssetXWorkOrderMinFields = {
  __typename: 'asset_x_work_order_min_fields';
  asset_id?: Maybe<Scalars['uuid']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "asset_x_work_order" */
export type IAssetXWorkOrderMinOrderBy = {
  asset_id?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "asset_x_work_order" */
export type IAssetXWorkOrderMutationResponse = {
  __typename: 'asset_x_work_order_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IAssetXWorkOrder>;
};

/** on_conflict condition type for table "asset_x_work_order" */
export type IAssetXWorkOrderOnConflict = {
  constraint: IAssetXWorkOrderConstraint;
  update_columns?: Array<IAssetXWorkOrderUpdateColumn>;
  where?: InputMaybe<IAssetXWorkOrderBoolExp>;
};

/** Ordering options when selecting data from "asset_x_work_order". */
export type IAssetXWorkOrderOrderBy = {
  asset?: InputMaybe<IAssetOrderBy>;
  asset_id?: InputMaybe<IOrderBy>;
  work_order?: InputMaybe<IWorkOrderOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: asset_x_work_order */
export type IAssetXWorkOrderPkColumnsInput = {
  asset_id: Scalars['uuid']['input'];
  work_order_id: Scalars['uuid']['input'];
};

/** select columns of table "asset_x_work_order" */
export enum IAssetXWorkOrderSelectColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  WorkOrderId = 'work_order_id'
}

/** input type for updating data in table "asset_x_work_order" */
export type IAssetXWorkOrderSetInput = {
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "asset_x_work_order" */
export type IAssetXWorkOrderStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IAssetXWorkOrderStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IAssetXWorkOrderStreamCursorValueInput = {
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "asset_x_work_order" */
export enum IAssetXWorkOrderUpdateColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  WorkOrderId = 'work_order_id'
}

export type IAssetXWorkOrderUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IAssetXWorkOrderSetInput>;
  /** filter the rows which have to be updated */
  where: IAssetXWorkOrderBoolExp;
};

export type IAvailabilityAssetArgs = {
  end?: InputMaybe<Scalars['timestamptz']['input']>;
  start?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type IBigintComparisonExp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** columns and relationships of "block.element" */
export type IBlockElement = {
  __typename: 'block_element';
  config: Scalars['jsonb']['output'];
  config_last_edited_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  config_last_edited_by?: Maybe<IUser>;
  config_last_edited_by_id?: Maybe<Scalars['uuid']['output']>;
  config_upload_ids?: Maybe<Scalars['jsonb']['output']>;
  /** A computed field, executes function "block.element_config_uploads" */
  config_uploads?: Maybe<Array<IUpload>>;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by?: Maybe<IUser>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  element_type: IBlockElementTypeEnum;
  /** An object relationship */
  group: IBlockGroup;
  group_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  position: Scalars['smallint']['output'];
  /** A computed field, executes function "block.element_relationship_data_fetcher" */
  relationship_data?: Maybe<Scalars['jsonb']['output']>;
  response?: Maybe<Scalars['jsonb']['output']>;
  response_last_edited_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  response_last_edited_by?: Maybe<IUser>;
  response_last_edited_by_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  uploads: Array<IBlockElementXUpload>;
  /** An aggregate relationship */
  uploads_aggregate: IBlockElementXUploadAggregate;
};


/** columns and relationships of "block.element" */
export type IBlockElementConfigArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "block.element" */
export type IBlockElementConfigUploadIdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "block.element" */
export type IBlockElementConfigUploadsArgs = {
  distinct_on?: InputMaybe<Array<IUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUploadOrderBy>>;
  where?: InputMaybe<IUploadBoolExp>;
};


/** columns and relationships of "block.element" */
export type IBlockElementRelationshipDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "block.element" */
export type IBlockElementResponseArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "block.element" */
export type IBlockElementUploadsArgs = {
  distinct_on?: InputMaybe<Array<IBlockElementXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBlockElementXUploadOrderBy>>;
  where?: InputMaybe<IBlockElementXUploadBoolExp>;
};


/** columns and relationships of "block.element" */
export type IBlockElementUploadsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IBlockElementXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBlockElementXUploadOrderBy>>;
  where?: InputMaybe<IBlockElementXUploadBoolExp>;
};

/** aggregated selection of "block.element" */
export type IBlockElementAggregate = {
  __typename: 'block_element_aggregate';
  aggregate?: Maybe<IBlockElementAggregateFields>;
  nodes: Array<IBlockElement>;
};

export type IBlockElementAggregateBoolExp = {
  count?: InputMaybe<IBlockElementAggregateBoolExpCount>;
};

export type IBlockElementAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IBlockElementSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IBlockElementBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "block.element" */
export type IBlockElementAggregateFields = {
  __typename: 'block_element_aggregate_fields';
  avg?: Maybe<IBlockElementAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IBlockElementMaxFields>;
  min?: Maybe<IBlockElementMinFields>;
  stddev?: Maybe<IBlockElementStddevFields>;
  stddev_pop?: Maybe<IBlockElementStddevPopFields>;
  stddev_samp?: Maybe<IBlockElementStddevSampFields>;
  sum?: Maybe<IBlockElementSumFields>;
  var_pop?: Maybe<IBlockElementVarPopFields>;
  var_samp?: Maybe<IBlockElementVarSampFields>;
  variance?: Maybe<IBlockElementVarianceFields>;
};


/** aggregate fields of "block.element" */
export type IBlockElementAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IBlockElementSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "block.element" */
export type IBlockElementAggregateOrderBy = {
  avg?: InputMaybe<IBlockElementAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IBlockElementMaxOrderBy>;
  min?: InputMaybe<IBlockElementMinOrderBy>;
  stddev?: InputMaybe<IBlockElementStddevOrderBy>;
  stddev_pop?: InputMaybe<IBlockElementStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IBlockElementStddevSampOrderBy>;
  sum?: InputMaybe<IBlockElementSumOrderBy>;
  var_pop?: InputMaybe<IBlockElementVarPopOrderBy>;
  var_samp?: InputMaybe<IBlockElementVarSampOrderBy>;
  variance?: InputMaybe<IBlockElementVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IBlockElementAppendInput = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  config_upload_ids?: InputMaybe<Scalars['jsonb']['input']>;
  response?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "block.element" */
export type IBlockElementArrRelInsertInput = {
  data: Array<IBlockElementInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IBlockElementOnConflict>;
};

/** aggregate avg on columns */
export type IBlockElementAvgFields = {
  __typename: 'block_element_avg_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "block.element" */
export type IBlockElementAvgOrderBy = {
  position?: InputMaybe<IOrderBy>;
};

/** Boolean expression to filter rows from the table "block.element". All fields are combined with a logical 'AND'. */
export type IBlockElementBoolExp = {
  _and?: InputMaybe<Array<IBlockElementBoolExp>>;
  _not?: InputMaybe<IBlockElementBoolExp>;
  _or?: InputMaybe<Array<IBlockElementBoolExp>>;
  config?: InputMaybe<IJsonbComparisonExp>;
  config_last_edited_at?: InputMaybe<ITimestamptzComparisonExp>;
  config_last_edited_by?: InputMaybe<IUserBoolExp>;
  config_last_edited_by_id?: InputMaybe<IUuidComparisonExp>;
  config_upload_ids?: InputMaybe<IJsonbComparisonExp>;
  config_uploads?: InputMaybe<IUploadBoolExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  created_by?: InputMaybe<IUserBoolExp>;
  created_by_id?: InputMaybe<IUuidComparisonExp>;
  element_type?: InputMaybe<IBlockElementTypeEnumComparisonExp>;
  group?: InputMaybe<IBlockGroupBoolExp>;
  group_id?: InputMaybe<IUuidComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  position?: InputMaybe<ISmallintComparisonExp>;
  relationship_data?: InputMaybe<IJsonbComparisonExp>;
  response?: InputMaybe<IJsonbComparisonExp>;
  response_last_edited_at?: InputMaybe<ITimestamptzComparisonExp>;
  response_last_edited_by?: InputMaybe<IUserBoolExp>;
  response_last_edited_by_id?: InputMaybe<IUuidComparisonExp>;
  uploads?: InputMaybe<IBlockElementXUploadBoolExp>;
  uploads_aggregate?: InputMaybe<IBlockElementXUploadAggregateBoolExp>;
};

/** unique or primary key constraints on table "block.element" */
export enum IBlockElementConstraint {
  /** unique or primary key constraint on columns "id" */
  ElementPkey = 'element_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IBlockElementDeleteAtPathInput = {
  config?: InputMaybe<Array<Scalars['String']['input']>>;
  config_upload_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  response?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IBlockElementDeleteElemInput = {
  config?: InputMaybe<Scalars['Int']['input']>;
  config_upload_ids?: InputMaybe<Scalars['Int']['input']>;
  response?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IBlockElementDeleteKeyInput = {
  config?: InputMaybe<Scalars['String']['input']>;
  config_upload_ids?: InputMaybe<Scalars['String']['input']>;
  response?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "block.element" */
export type IBlockElementIncInput = {
  position?: InputMaybe<Scalars['smallint']['input']>;
};

/** input type for inserting data into table "block.element" */
export type IBlockElementInsertInput = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  config_last_edited_at?: InputMaybe<Scalars['timestamptz']['input']>;
  config_last_edited_by?: InputMaybe<IUserObjRelInsertInput>;
  config_last_edited_by_id?: InputMaybe<Scalars['uuid']['input']>;
  config_upload_ids?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<IUserObjRelInsertInput>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  element_type?: InputMaybe<IBlockElementTypeEnum>;
  group?: InputMaybe<IBlockGroupObjRelInsertInput>;
  group_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  position?: InputMaybe<Scalars['smallint']['input']>;
  response?: InputMaybe<Scalars['jsonb']['input']>;
  response_last_edited_at?: InputMaybe<Scalars['timestamptz']['input']>;
  response_last_edited_by?: InputMaybe<IUserObjRelInsertInput>;
  response_last_edited_by_id?: InputMaybe<Scalars['uuid']['input']>;
  uploads?: InputMaybe<IBlockElementXUploadArrRelInsertInput>;
};

/** aggregate max on columns */
export type IBlockElementMaxFields = {
  __typename: 'block_element_max_fields';
  config_last_edited_at?: Maybe<Scalars['timestamptz']['output']>;
  config_last_edited_by_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  group_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  position?: Maybe<Scalars['smallint']['output']>;
  response_last_edited_at?: Maybe<Scalars['timestamptz']['output']>;
  response_last_edited_by_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "block.element" */
export type IBlockElementMaxOrderBy = {
  config_last_edited_at?: InputMaybe<IOrderBy>;
  config_last_edited_by_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  group_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  position?: InputMaybe<IOrderBy>;
  response_last_edited_at?: InputMaybe<IOrderBy>;
  response_last_edited_by_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IBlockElementMinFields = {
  __typename: 'block_element_min_fields';
  config_last_edited_at?: Maybe<Scalars['timestamptz']['output']>;
  config_last_edited_by_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  group_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  position?: Maybe<Scalars['smallint']['output']>;
  response_last_edited_at?: Maybe<Scalars['timestamptz']['output']>;
  response_last_edited_by_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "block.element" */
export type IBlockElementMinOrderBy = {
  config_last_edited_at?: InputMaybe<IOrderBy>;
  config_last_edited_by_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  group_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  position?: InputMaybe<IOrderBy>;
  response_last_edited_at?: InputMaybe<IOrderBy>;
  response_last_edited_by_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "block.element" */
export type IBlockElementMutationResponse = {
  __typename: 'block_element_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IBlockElement>;
};

/** input type for inserting object relation for remote table "block.element" */
export type IBlockElementObjRelInsertInput = {
  data: IBlockElementInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IBlockElementOnConflict>;
};

/** on_conflict condition type for table "block.element" */
export type IBlockElementOnConflict = {
  constraint: IBlockElementConstraint;
  update_columns?: Array<IBlockElementUpdateColumn>;
  where?: InputMaybe<IBlockElementBoolExp>;
};

/** Ordering options when selecting data from "block.element". */
export type IBlockElementOrderBy = {
  config?: InputMaybe<IOrderBy>;
  config_last_edited_at?: InputMaybe<IOrderBy>;
  config_last_edited_by?: InputMaybe<IUserOrderBy>;
  config_last_edited_by_id?: InputMaybe<IOrderBy>;
  config_upload_ids?: InputMaybe<IOrderBy>;
  config_uploads_aggregate?: InputMaybe<IUploadAggregateOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by?: InputMaybe<IUserOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  element_type?: InputMaybe<IOrderBy>;
  group?: InputMaybe<IBlockGroupOrderBy>;
  group_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  position?: InputMaybe<IOrderBy>;
  relationship_data?: InputMaybe<IOrderBy>;
  response?: InputMaybe<IOrderBy>;
  response_last_edited_at?: InputMaybe<IOrderBy>;
  response_last_edited_by?: InputMaybe<IUserOrderBy>;
  response_last_edited_by_id?: InputMaybe<IOrderBy>;
  uploads_aggregate?: InputMaybe<IBlockElementXUploadAggregateOrderBy>;
};

/** primary key columns input for table: block.element */
export type IBlockElementPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IBlockElementPrependInput = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  config_upload_ids?: InputMaybe<Scalars['jsonb']['input']>;
  response?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "block.element" */
export enum IBlockElementSelectColumn {
  /** column name */
  Config = 'config',
  /** column name */
  ConfigLastEditedAt = 'config_last_edited_at',
  /** column name */
  ConfigLastEditedById = 'config_last_edited_by_id',
  /** column name */
  ConfigUploadIds = 'config_upload_ids',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  ElementType = 'element_type',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  Position = 'position',
  /** column name */
  Response = 'response',
  /** column name */
  ResponseLastEditedAt = 'response_last_edited_at',
  /** column name */
  ResponseLastEditedById = 'response_last_edited_by_id'
}

/** input type for updating data in table "block.element" */
export type IBlockElementSetInput = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  config_last_edited_at?: InputMaybe<Scalars['timestamptz']['input']>;
  config_last_edited_by_id?: InputMaybe<Scalars['uuid']['input']>;
  config_upload_ids?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  element_type?: InputMaybe<IBlockElementTypeEnum>;
  group_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  position?: InputMaybe<Scalars['smallint']['input']>;
  response?: InputMaybe<Scalars['jsonb']['input']>;
  response_last_edited_at?: InputMaybe<Scalars['timestamptz']['input']>;
  response_last_edited_by_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type IBlockElementStddevFields = {
  __typename: 'block_element_stddev_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "block.element" */
export type IBlockElementStddevOrderBy = {
  position?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_pop on columns */
export type IBlockElementStddevPopFields = {
  __typename: 'block_element_stddev_pop_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "block.element" */
export type IBlockElementStddevPopOrderBy = {
  position?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_samp on columns */
export type IBlockElementStddevSampFields = {
  __typename: 'block_element_stddev_samp_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "block.element" */
export type IBlockElementStddevSampOrderBy = {
  position?: InputMaybe<IOrderBy>;
};

/** Streaming cursor of the table "block_element" */
export type IBlockElementStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IBlockElementStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IBlockElementStreamCursorValueInput = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  config_last_edited_at?: InputMaybe<Scalars['timestamptz']['input']>;
  config_last_edited_by_id?: InputMaybe<Scalars['uuid']['input']>;
  config_upload_ids?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  element_type?: InputMaybe<IBlockElementTypeEnum>;
  group_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  position?: InputMaybe<Scalars['smallint']['input']>;
  response?: InputMaybe<Scalars['jsonb']['input']>;
  response_last_edited_at?: InputMaybe<Scalars['timestamptz']['input']>;
  response_last_edited_by_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type IBlockElementSumFields = {
  __typename: 'block_element_sum_fields';
  position?: Maybe<Scalars['smallint']['output']>;
};

/** order by sum() on columns of table "block.element" */
export type IBlockElementSumOrderBy = {
  position?: InputMaybe<IOrderBy>;
};

/** columns and relationships of "block.element_type" */
export type IBlockElementType = {
  __typename: 'block_element_type';
  value: Scalars['String']['output'];
};

/** aggregated selection of "block.element_type" */
export type IBlockElementTypeAggregate = {
  __typename: 'block_element_type_aggregate';
  aggregate?: Maybe<IBlockElementTypeAggregateFields>;
  nodes: Array<IBlockElementType>;
};

/** aggregate fields of "block.element_type" */
export type IBlockElementTypeAggregateFields = {
  __typename: 'block_element_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IBlockElementTypeMaxFields>;
  min?: Maybe<IBlockElementTypeMinFields>;
};


/** aggregate fields of "block.element_type" */
export type IBlockElementTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IBlockElementTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "block.element_type". All fields are combined with a logical 'AND'. */
export type IBlockElementTypeBoolExp = {
  _and?: InputMaybe<Array<IBlockElementTypeBoolExp>>;
  _not?: InputMaybe<IBlockElementTypeBoolExp>;
  _or?: InputMaybe<Array<IBlockElementTypeBoolExp>>;
  value?: InputMaybe<IStringComparisonExp>;
};

/** unique or primary key constraints on table "block.element_type" */
export enum IBlockElementTypeConstraint {
  /** unique or primary key constraint on columns "value" */
  ElementTypePkey = 'element_type_pkey'
}

export enum IBlockElementTypeEnum {
  AssetState = 'asset_state',
  Checkbox = 'checkbox',
  Choice = 'choice',
  Consumables = 'consumables',
  Heading = 'heading',
  Inspection = 'inspection',
  Media = 'media',
  MeterReading = 'meter_reading',
  Paragraph = 'paragraph',
  Subtask = 'subtask',
  Text = 'text',
  ToleranceCheck = 'tolerance_check',
  YesNo = 'yes_no'
}

/** Boolean expression to compare columns of type "block_element_type_enum". All fields are combined with logical 'AND'. */
export type IBlockElementTypeEnumComparisonExp = {
  _eq?: InputMaybe<IBlockElementTypeEnum>;
  _in?: InputMaybe<Array<IBlockElementTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<IBlockElementTypeEnum>;
  _nin?: InputMaybe<Array<IBlockElementTypeEnum>>;
};

/** input type for inserting data into table "block.element_type" */
export type IBlockElementTypeInsertInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type IBlockElementTypeMaxFields = {
  __typename: 'block_element_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IBlockElementTypeMinFields = {
  __typename: 'block_element_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "block.element_type" */
export type IBlockElementTypeMutationResponse = {
  __typename: 'block_element_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IBlockElementType>;
};

/** on_conflict condition type for table "block.element_type" */
export type IBlockElementTypeOnConflict = {
  constraint: IBlockElementTypeConstraint;
  update_columns?: Array<IBlockElementTypeUpdateColumn>;
  where?: InputMaybe<IBlockElementTypeBoolExp>;
};

/** Ordering options when selecting data from "block.element_type". */
export type IBlockElementTypeOrderBy = {
  value?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: block.element_type */
export type IBlockElementTypePkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "block.element_type" */
export enum IBlockElementTypeSelectColumn {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "block.element_type" */
export type IBlockElementTypeSetInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "block_element_type" */
export type IBlockElementTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IBlockElementTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IBlockElementTypeStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "block.element_type" */
export enum IBlockElementTypeUpdateColumn {
  /** column name */
  Value = 'value'
}

export type IBlockElementTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IBlockElementTypeSetInput>;
  /** filter the rows which have to be updated */
  where: IBlockElementTypeBoolExp;
};

/** update columns of table "block.element" */
export enum IBlockElementUpdateColumn {
  /** column name */
  Config = 'config',
  /** column name */
  ConfigLastEditedAt = 'config_last_edited_at',
  /** column name */
  ConfigLastEditedById = 'config_last_edited_by_id',
  /** column name */
  ConfigUploadIds = 'config_upload_ids',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  ElementType = 'element_type',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  Position = 'position',
  /** column name */
  Response = 'response',
  /** column name */
  ResponseLastEditedAt = 'response_last_edited_at',
  /** column name */
  ResponseLastEditedById = 'response_last_edited_by_id'
}

export type IBlockElementUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IBlockElementAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IBlockElementDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IBlockElementDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IBlockElementDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IBlockElementIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IBlockElementPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IBlockElementSetInput>;
  /** filter the rows which have to be updated */
  where: IBlockElementBoolExp;
};

/** aggregate var_pop on columns */
export type IBlockElementVarPopFields = {
  __typename: 'block_element_var_pop_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "block.element" */
export type IBlockElementVarPopOrderBy = {
  position?: InputMaybe<IOrderBy>;
};

/** aggregate var_samp on columns */
export type IBlockElementVarSampFields = {
  __typename: 'block_element_var_samp_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "block.element" */
export type IBlockElementVarSampOrderBy = {
  position?: InputMaybe<IOrderBy>;
};

/** aggregate variance on columns */
export type IBlockElementVarianceFields = {
  __typename: 'block_element_variance_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "block.element" */
export type IBlockElementVarianceOrderBy = {
  position?: InputMaybe<IOrderBy>;
};

/** columns and relationships of "block.element_x_upload" */
export type IBlockElementXUpload = {
  __typename: 'block_element_x_upload';
  /** An object relationship */
  element?: Maybe<IBlockElement>;
  element_id: Scalars['uuid']['output'];
  /** An object relationship */
  upload: IUpload;
  upload_id: Scalars['uuid']['output'];
};

/** aggregated selection of "block.element_x_upload" */
export type IBlockElementXUploadAggregate = {
  __typename: 'block_element_x_upload_aggregate';
  aggregate?: Maybe<IBlockElementXUploadAggregateFields>;
  nodes: Array<IBlockElementXUpload>;
};

export type IBlockElementXUploadAggregateBoolExp = {
  count?: InputMaybe<IBlockElementXUploadAggregateBoolExpCount>;
};

export type IBlockElementXUploadAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IBlockElementXUploadSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IBlockElementXUploadBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "block.element_x_upload" */
export type IBlockElementXUploadAggregateFields = {
  __typename: 'block_element_x_upload_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IBlockElementXUploadMaxFields>;
  min?: Maybe<IBlockElementXUploadMinFields>;
};


/** aggregate fields of "block.element_x_upload" */
export type IBlockElementXUploadAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IBlockElementXUploadSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "block.element_x_upload" */
export type IBlockElementXUploadAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IBlockElementXUploadMaxOrderBy>;
  min?: InputMaybe<IBlockElementXUploadMinOrderBy>;
};

/** input type for inserting array relation for remote table "block.element_x_upload" */
export type IBlockElementXUploadArrRelInsertInput = {
  data: Array<IBlockElementXUploadInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IBlockElementXUploadOnConflict>;
};

/** Boolean expression to filter rows from the table "block.element_x_upload". All fields are combined with a logical 'AND'. */
export type IBlockElementXUploadBoolExp = {
  _and?: InputMaybe<Array<IBlockElementXUploadBoolExp>>;
  _not?: InputMaybe<IBlockElementXUploadBoolExp>;
  _or?: InputMaybe<Array<IBlockElementXUploadBoolExp>>;
  element?: InputMaybe<IBlockElementBoolExp>;
  element_id?: InputMaybe<IUuidComparisonExp>;
  upload?: InputMaybe<IUploadBoolExp>;
  upload_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "block.element_x_upload" */
export enum IBlockElementXUploadConstraint {
  /** unique or primary key constraint on columns "upload_id", "element_id" */
  ElementXUploadPkey = 'element_x_upload_pkey'
}

/** input type for inserting data into table "block.element_x_upload" */
export type IBlockElementXUploadInsertInput = {
  element?: InputMaybe<IBlockElementObjRelInsertInput>;
  element_id?: InputMaybe<Scalars['uuid']['input']>;
  upload?: InputMaybe<IUploadObjRelInsertInput>;
  upload_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IBlockElementXUploadMaxFields = {
  __typename: 'block_element_x_upload_max_fields';
  element_id?: Maybe<Scalars['uuid']['output']>;
  upload_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "block.element_x_upload" */
export type IBlockElementXUploadMaxOrderBy = {
  element_id?: InputMaybe<IOrderBy>;
  upload_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IBlockElementXUploadMinFields = {
  __typename: 'block_element_x_upload_min_fields';
  element_id?: Maybe<Scalars['uuid']['output']>;
  upload_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "block.element_x_upload" */
export type IBlockElementXUploadMinOrderBy = {
  element_id?: InputMaybe<IOrderBy>;
  upload_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "block.element_x_upload" */
export type IBlockElementXUploadMutationResponse = {
  __typename: 'block_element_x_upload_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IBlockElementXUpload>;
};

/** on_conflict condition type for table "block.element_x_upload" */
export type IBlockElementXUploadOnConflict = {
  constraint: IBlockElementXUploadConstraint;
  update_columns?: Array<IBlockElementXUploadUpdateColumn>;
  where?: InputMaybe<IBlockElementXUploadBoolExp>;
};

/** Ordering options when selecting data from "block.element_x_upload". */
export type IBlockElementXUploadOrderBy = {
  element?: InputMaybe<IBlockElementOrderBy>;
  element_id?: InputMaybe<IOrderBy>;
  upload?: InputMaybe<IUploadOrderBy>;
  upload_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: block.element_x_upload */
export type IBlockElementXUploadPkColumnsInput = {
  element_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};

/** select columns of table "block.element_x_upload" */
export enum IBlockElementXUploadSelectColumn {
  /** column name */
  ElementId = 'element_id',
  /** column name */
  UploadId = 'upload_id'
}

/** input type for updating data in table "block.element_x_upload" */
export type IBlockElementXUploadSetInput = {
  element_id?: InputMaybe<Scalars['uuid']['input']>;
  upload_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "block_element_x_upload" */
export type IBlockElementXUploadStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IBlockElementXUploadStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IBlockElementXUploadStreamCursorValueInput = {
  element_id?: InputMaybe<Scalars['uuid']['input']>;
  upload_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "block.element_x_upload" */
export enum IBlockElementXUploadUpdateColumn {
  /** column name */
  ElementId = 'element_id',
  /** column name */
  UploadId = 'upload_id'
}

export type IBlockElementXUploadUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IBlockElementXUploadSetInput>;
  /** filter the rows which have to be updated */
  where: IBlockElementXUploadBoolExp;
};

/** columns and relationships of "block.group" */
export type IBlockGroup = {
  __typename: 'block_group';
  created_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  elements: Array<IBlockElement>;
  /** An aggregate relationship */
  elements_aggregate: IBlockElementAggregate;
  group_type: IBlockGroupTypeEnum;
  id: Scalars['uuid']['output'];
  is_template: Scalars['Boolean']['output'];
  location_id: Scalars['uuid']['output'];
  /** A computed field, executes function "block.group_progress_info_by_group" */
  progress_info?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  work_order?: Maybe<IWorkOrder>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};


/** columns and relationships of "block.group" */
export type IBlockGroupElementsArgs = {
  distinct_on?: InputMaybe<Array<IBlockElementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBlockElementOrderBy>>;
  where?: InputMaybe<IBlockElementBoolExp>;
};


/** columns and relationships of "block.group" */
export type IBlockGroupElementsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IBlockElementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBlockElementOrderBy>>;
  where?: InputMaybe<IBlockElementBoolExp>;
};


/** columns and relationships of "block.group" */
export type IBlockGroupProgressInfoArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "block.group" */
export type IBlockGroupAggregate = {
  __typename: 'block_group_aggregate';
  aggregate?: Maybe<IBlockGroupAggregateFields>;
  nodes: Array<IBlockGroup>;
};

export type IBlockGroupAggregateBoolExp = {
  bool_and?: InputMaybe<IBlockGroupAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<IBlockGroupAggregateBoolExpBoolOr>;
  count?: InputMaybe<IBlockGroupAggregateBoolExpCount>;
};

export type IBlockGroupAggregateBoolExpBoolAnd = {
  arguments: IBlockGroupSelectColumnBlockGroupAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IBlockGroupBoolExp>;
  predicate: IBooleanComparisonExp;
};

export type IBlockGroupAggregateBoolExpBoolOr = {
  arguments: IBlockGroupSelectColumnBlockGroupAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IBlockGroupBoolExp>;
  predicate: IBooleanComparisonExp;
};

export type IBlockGroupAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IBlockGroupSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IBlockGroupBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "block.group" */
export type IBlockGroupAggregateFields = {
  __typename: 'block_group_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IBlockGroupMaxFields>;
  min?: Maybe<IBlockGroupMinFields>;
};


/** aggregate fields of "block.group" */
export type IBlockGroupAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IBlockGroupSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "block.group" */
export type IBlockGroupAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IBlockGroupMaxOrderBy>;
  min?: InputMaybe<IBlockGroupMinOrderBy>;
};

/** input type for inserting array relation for remote table "block.group" */
export type IBlockGroupArrRelInsertInput = {
  data: Array<IBlockGroupInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IBlockGroupOnConflict>;
};

/** Boolean expression to filter rows from the table "block.group". All fields are combined with a logical 'AND'. */
export type IBlockGroupBoolExp = {
  _and?: InputMaybe<Array<IBlockGroupBoolExp>>;
  _not?: InputMaybe<IBlockGroupBoolExp>;
  _or?: InputMaybe<Array<IBlockGroupBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  elements?: InputMaybe<IBlockElementBoolExp>;
  elements_aggregate?: InputMaybe<IBlockElementAggregateBoolExp>;
  group_type?: InputMaybe<IBlockGroupTypeEnumComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  is_template?: InputMaybe<IBooleanComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  progress_info?: InputMaybe<IJsonbComparisonExp>;
  work_order?: InputMaybe<IWorkOrderBoolExp>;
  work_order_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "block.group" */
export enum IBlockGroupConstraint {
  /** unique or primary key constraint on columns "id" */
  GroupPkey = 'group_pkey',
  /** unique or primary key constraint on columns "group_type", "work_order_id" */
  GroupWorkOrderIdGroupTypeKey = 'group_work_order_id_group_type_key'
}

/** input type for inserting data into table "block.group" */
export type IBlockGroupInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  elements?: InputMaybe<IBlockElementArrRelInsertInput>;
  group_type?: InputMaybe<IBlockGroupTypeEnum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_template?: InputMaybe<Scalars['Boolean']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order?: InputMaybe<IWorkOrderObjRelInsertInput>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IBlockGroupMaxFields = {
  __typename: 'block_group_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "block.group" */
export type IBlockGroupMaxOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IBlockGroupMinFields = {
  __typename: 'block_group_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "block.group" */
export type IBlockGroupMinOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "block.group" */
export type IBlockGroupMutationResponse = {
  __typename: 'block_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IBlockGroup>;
};

/** input type for inserting object relation for remote table "block.group" */
export type IBlockGroupObjRelInsertInput = {
  data: IBlockGroupInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IBlockGroupOnConflict>;
};

/** on_conflict condition type for table "block.group" */
export type IBlockGroupOnConflict = {
  constraint: IBlockGroupConstraint;
  update_columns?: Array<IBlockGroupUpdateColumn>;
  where?: InputMaybe<IBlockGroupBoolExp>;
};

/** Ordering options when selecting data from "block.group". */
export type IBlockGroupOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  elements_aggregate?: InputMaybe<IBlockElementAggregateOrderBy>;
  group_type?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  is_template?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  progress_info?: InputMaybe<IOrderBy>;
  work_order?: InputMaybe<IWorkOrderOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: block.group */
export type IBlockGroupPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "block.group" */
export enum IBlockGroupSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupType = 'group_type',
  /** column name */
  Id = 'id',
  /** column name */
  IsTemplate = 'is_template',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  WorkOrderId = 'work_order_id'
}

/** select "block_group_aggregate_bool_exp_bool_and_arguments_columns" columns of table "block.group" */
export enum IBlockGroupSelectColumnBlockGroupAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsTemplate = 'is_template'
}

/** select "block_group_aggregate_bool_exp_bool_or_arguments_columns" columns of table "block.group" */
export enum IBlockGroupSelectColumnBlockGroupAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsTemplate = 'is_template'
}

/** input type for updating data in table "block.group" */
export type IBlockGroupSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  group_type?: InputMaybe<IBlockGroupTypeEnum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_template?: InputMaybe<Scalars['Boolean']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "block_group" */
export type IBlockGroupStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IBlockGroupStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IBlockGroupStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  group_type?: InputMaybe<IBlockGroupTypeEnum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_template?: InputMaybe<Scalars['Boolean']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "block.group_type" */
export type IBlockGroupType = {
  __typename: 'block_group_type';
  value: Scalars['String']['output'];
};

/** aggregated selection of "block.group_type" */
export type IBlockGroupTypeAggregate = {
  __typename: 'block_group_type_aggregate';
  aggregate?: Maybe<IBlockGroupTypeAggregateFields>;
  nodes: Array<IBlockGroupType>;
};

/** aggregate fields of "block.group_type" */
export type IBlockGroupTypeAggregateFields = {
  __typename: 'block_group_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IBlockGroupTypeMaxFields>;
  min?: Maybe<IBlockGroupTypeMinFields>;
};


/** aggregate fields of "block.group_type" */
export type IBlockGroupTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IBlockGroupTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "block.group_type". All fields are combined with a logical 'AND'. */
export type IBlockGroupTypeBoolExp = {
  _and?: InputMaybe<Array<IBlockGroupTypeBoolExp>>;
  _not?: InputMaybe<IBlockGroupTypeBoolExp>;
  _or?: InputMaybe<Array<IBlockGroupTypeBoolExp>>;
  value?: InputMaybe<IStringComparisonExp>;
};

/** unique or primary key constraints on table "block.group_type" */
export enum IBlockGroupTypeConstraint {
  /** unique or primary key constraint on columns "value" */
  GroupTypePkey = 'group_type_pkey'
}

export enum IBlockGroupTypeEnum {
  Feedback = 'feedback',
  Info = 'info',
  Procedure = 'procedure'
}

/** Boolean expression to compare columns of type "block_group_type_enum". All fields are combined with logical 'AND'. */
export type IBlockGroupTypeEnumComparisonExp = {
  _eq?: InputMaybe<IBlockGroupTypeEnum>;
  _in?: InputMaybe<Array<IBlockGroupTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<IBlockGroupTypeEnum>;
  _nin?: InputMaybe<Array<IBlockGroupTypeEnum>>;
};

/** input type for inserting data into table "block.group_type" */
export type IBlockGroupTypeInsertInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type IBlockGroupTypeMaxFields = {
  __typename: 'block_group_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IBlockGroupTypeMinFields = {
  __typename: 'block_group_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "block.group_type" */
export type IBlockGroupTypeMutationResponse = {
  __typename: 'block_group_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IBlockGroupType>;
};

/** on_conflict condition type for table "block.group_type" */
export type IBlockGroupTypeOnConflict = {
  constraint: IBlockGroupTypeConstraint;
  update_columns?: Array<IBlockGroupTypeUpdateColumn>;
  where?: InputMaybe<IBlockGroupTypeBoolExp>;
};

/** Ordering options when selecting data from "block.group_type". */
export type IBlockGroupTypeOrderBy = {
  value?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: block.group_type */
export type IBlockGroupTypePkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "block.group_type" */
export enum IBlockGroupTypeSelectColumn {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "block.group_type" */
export type IBlockGroupTypeSetInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "block_group_type" */
export type IBlockGroupTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IBlockGroupTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IBlockGroupTypeStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "block.group_type" */
export enum IBlockGroupTypeUpdateColumn {
  /** column name */
  Value = 'value'
}

export type IBlockGroupTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IBlockGroupTypeSetInput>;
  /** filter the rows which have to be updated */
  where: IBlockGroupTypeBoolExp;
};

/** update columns of table "block.group" */
export enum IBlockGroupUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupType = 'group_type',
  /** column name */
  Id = 'id',
  /** column name */
  IsTemplate = 'is_template',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  WorkOrderId = 'work_order_id'
}

export type IBlockGroupUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IBlockGroupSetInput>;
  /** filter the rows which have to be updated */
  where: IBlockGroupBoolExp;
};

/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
export type ICitextComparisonExp = {
  _eq?: InputMaybe<Scalars['citext']['input']>;
  _gt?: InputMaybe<Scalars['citext']['input']>;
  _gte?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['citext']['input']>;
  _in?: InputMaybe<Array<Scalars['citext']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['citext']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['citext']['input']>;
  _lt?: InputMaybe<Scalars['citext']['input']>;
  _lte?: InputMaybe<Scalars['citext']['input']>;
  _neq?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['citext']['input']>;
  _nin?: InputMaybe<Array<Scalars['citext']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['citext']['input']>;
};

/** columns and relationships of "comment" */
export type IComment = {
  __typename: 'comment';
  /** An object relationship */
  asset?: Maybe<IAsset>;
  asset_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  attachments: Array<ICommentXUpload>;
  /** An aggregate relationship */
  attachments_aggregate: ICommentXUploadAggregate;
  content: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by: IUser;
  created_by_id: Scalars['uuid']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  edited_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_mention_ids: Scalars['json']['output'];
  /** An object relationship */
  work_order?: Maybe<IWorkOrder>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};


/** columns and relationships of "comment" */
export type ICommentAttachmentsArgs = {
  distinct_on?: InputMaybe<Array<ICommentXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICommentXUploadOrderBy>>;
  where?: InputMaybe<ICommentXUploadBoolExp>;
};


/** columns and relationships of "comment" */
export type ICommentAttachmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ICommentXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICommentXUploadOrderBy>>;
  where?: InputMaybe<ICommentXUploadBoolExp>;
};


/** columns and relationships of "comment" */
export type ICommentUserMentionIdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "comment" */
export type ICommentAggregate = {
  __typename: 'comment_aggregate';
  aggregate?: Maybe<ICommentAggregateFields>;
  nodes: Array<IComment>;
};

export type ICommentAggregateBoolExp = {
  count?: InputMaybe<ICommentAggregateBoolExpCount>;
};

export type ICommentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ICommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ICommentBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "comment" */
export type ICommentAggregateFields = {
  __typename: 'comment_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ICommentMaxFields>;
  min?: Maybe<ICommentMinFields>;
};


/** aggregate fields of "comment" */
export type ICommentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ICommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "comment" */
export type ICommentAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<ICommentMaxOrderBy>;
  min?: InputMaybe<ICommentMinOrderBy>;
};

/** input type for inserting array relation for remote table "comment" */
export type ICommentArrRelInsertInput = {
  data: Array<ICommentInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ICommentOnConflict>;
};

/** Boolean expression to filter rows from the table "comment". All fields are combined with a logical 'AND'. */
export type ICommentBoolExp = {
  _and?: InputMaybe<Array<ICommentBoolExp>>;
  _not?: InputMaybe<ICommentBoolExp>;
  _or?: InputMaybe<Array<ICommentBoolExp>>;
  asset?: InputMaybe<IAssetBoolExp>;
  asset_id?: InputMaybe<IUuidComparisonExp>;
  attachments?: InputMaybe<ICommentXUploadBoolExp>;
  attachments_aggregate?: InputMaybe<ICommentXUploadAggregateBoolExp>;
  content?: InputMaybe<IStringComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  created_by?: InputMaybe<IUserBoolExp>;
  created_by_id?: InputMaybe<IUuidComparisonExp>;
  deleted_at?: InputMaybe<ITimestamptzComparisonExp>;
  edited_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  user_mention_ids?: InputMaybe<IJsonComparisonExp>;
  work_order?: InputMaybe<IWorkOrderBoolExp>;
  work_order_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "comment" */
export enum ICommentConstraint {
  /** unique or primary key constraint on columns "id" */
  CommentPkey = 'comment_pkey'
}

/** input type for inserting data into table "comment" */
export type ICommentInsertInput = {
  asset?: InputMaybe<IAssetObjRelInsertInput>;
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  attachments?: InputMaybe<ICommentXUploadArrRelInsertInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<IUserObjRelInsertInput>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  edited_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_mention_ids?: InputMaybe<Scalars['json']['input']>;
  work_order?: InputMaybe<IWorkOrderObjRelInsertInput>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type ICommentMaxFields = {
  __typename: 'comment_max_fields';
  asset_id?: Maybe<Scalars['uuid']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  edited_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "comment" */
export type ICommentMaxOrderBy = {
  asset_id?: InputMaybe<IOrderBy>;
  content?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  deleted_at?: InputMaybe<IOrderBy>;
  edited_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type ICommentMinFields = {
  __typename: 'comment_min_fields';
  asset_id?: Maybe<Scalars['uuid']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  edited_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "comment" */
export type ICommentMinOrderBy = {
  asset_id?: InputMaybe<IOrderBy>;
  content?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  deleted_at?: InputMaybe<IOrderBy>;
  edited_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "comment" */
export type ICommentMutationResponse = {
  __typename: 'comment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IComment>;
};

/** input type for inserting object relation for remote table "comment" */
export type ICommentObjRelInsertInput = {
  data: ICommentInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ICommentOnConflict>;
};

/** on_conflict condition type for table "comment" */
export type ICommentOnConflict = {
  constraint: ICommentConstraint;
  update_columns?: Array<ICommentUpdateColumn>;
  where?: InputMaybe<ICommentBoolExp>;
};

/** Ordering options when selecting data from "comment". */
export type ICommentOrderBy = {
  asset?: InputMaybe<IAssetOrderBy>;
  asset_id?: InputMaybe<IOrderBy>;
  attachments_aggregate?: InputMaybe<ICommentXUploadAggregateOrderBy>;
  content?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by?: InputMaybe<IUserOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  deleted_at?: InputMaybe<IOrderBy>;
  edited_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  user_mention_ids?: InputMaybe<IOrderBy>;
  work_order?: InputMaybe<IWorkOrderOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: comment */
export type ICommentPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "comment" */
export enum ICommentSelectColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EditedAt = 'edited_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserMentionIds = 'user_mention_ids',
  /** column name */
  WorkOrderId = 'work_order_id'
}

/** input type for updating data in table "comment" */
export type ICommentSetInput = {
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  edited_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_mention_ids?: InputMaybe<Scalars['json']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "comment" */
export type ICommentStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ICommentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ICommentStreamCursorValueInput = {
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  edited_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_mention_ids?: InputMaybe<Scalars['json']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "comment" */
export enum ICommentUpdateColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EditedAt = 'edited_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserMentionIds = 'user_mention_ids',
  /** column name */
  WorkOrderId = 'work_order_id'
}

export type ICommentUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ICommentSetInput>;
  /** filter the rows which have to be updated */
  where: ICommentBoolExp;
};

/** columns and relationships of "comment_x_upload" */
export type ICommentXUpload = {
  __typename: 'comment_x_upload';
  /** An object relationship */
  comment: IComment;
  comment_id: Scalars['uuid']['output'];
  /** An object relationship */
  upload: IUpload;
  upload_id: Scalars['uuid']['output'];
};

/** aggregated selection of "comment_x_upload" */
export type ICommentXUploadAggregate = {
  __typename: 'comment_x_upload_aggregate';
  aggregate?: Maybe<ICommentXUploadAggregateFields>;
  nodes: Array<ICommentXUpload>;
};

export type ICommentXUploadAggregateBoolExp = {
  count?: InputMaybe<ICommentXUploadAggregateBoolExpCount>;
};

export type ICommentXUploadAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ICommentXUploadSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ICommentXUploadBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "comment_x_upload" */
export type ICommentXUploadAggregateFields = {
  __typename: 'comment_x_upload_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ICommentXUploadMaxFields>;
  min?: Maybe<ICommentXUploadMinFields>;
};


/** aggregate fields of "comment_x_upload" */
export type ICommentXUploadAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ICommentXUploadSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "comment_x_upload" */
export type ICommentXUploadAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<ICommentXUploadMaxOrderBy>;
  min?: InputMaybe<ICommentXUploadMinOrderBy>;
};

/** input type for inserting array relation for remote table "comment_x_upload" */
export type ICommentXUploadArrRelInsertInput = {
  data: Array<ICommentXUploadInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ICommentXUploadOnConflict>;
};

/** Boolean expression to filter rows from the table "comment_x_upload". All fields are combined with a logical 'AND'. */
export type ICommentXUploadBoolExp = {
  _and?: InputMaybe<Array<ICommentXUploadBoolExp>>;
  _not?: InputMaybe<ICommentXUploadBoolExp>;
  _or?: InputMaybe<Array<ICommentXUploadBoolExp>>;
  comment?: InputMaybe<ICommentBoolExp>;
  comment_id?: InputMaybe<IUuidComparisonExp>;
  upload?: InputMaybe<IUploadBoolExp>;
  upload_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "comment_x_upload" */
export enum ICommentXUploadConstraint {
  /** unique or primary key constraint on columns "comment_id", "upload_id" */
  CommentXUploadCommentIdUploadIdKey = 'comment_x_upload_comment_id_upload_id_key',
  /** unique or primary key constraint on columns "comment_id", "upload_id" */
  CommentXUploadPkey = 'comment_x_upload_pkey'
}

/** input type for inserting data into table "comment_x_upload" */
export type ICommentXUploadInsertInput = {
  comment?: InputMaybe<ICommentObjRelInsertInput>;
  comment_id?: InputMaybe<Scalars['uuid']['input']>;
  upload?: InputMaybe<IUploadObjRelInsertInput>;
  upload_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type ICommentXUploadMaxFields = {
  __typename: 'comment_x_upload_max_fields';
  comment_id?: Maybe<Scalars['uuid']['output']>;
  upload_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "comment_x_upload" */
export type ICommentXUploadMaxOrderBy = {
  comment_id?: InputMaybe<IOrderBy>;
  upload_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type ICommentXUploadMinFields = {
  __typename: 'comment_x_upload_min_fields';
  comment_id?: Maybe<Scalars['uuid']['output']>;
  upload_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "comment_x_upload" */
export type ICommentXUploadMinOrderBy = {
  comment_id?: InputMaybe<IOrderBy>;
  upload_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "comment_x_upload" */
export type ICommentXUploadMutationResponse = {
  __typename: 'comment_x_upload_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ICommentXUpload>;
};

/** on_conflict condition type for table "comment_x_upload" */
export type ICommentXUploadOnConflict = {
  constraint: ICommentXUploadConstraint;
  update_columns?: Array<ICommentXUploadUpdateColumn>;
  where?: InputMaybe<ICommentXUploadBoolExp>;
};

/** Ordering options when selecting data from "comment_x_upload". */
export type ICommentXUploadOrderBy = {
  comment?: InputMaybe<ICommentOrderBy>;
  comment_id?: InputMaybe<IOrderBy>;
  upload?: InputMaybe<IUploadOrderBy>;
  upload_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: comment_x_upload */
export type ICommentXUploadPkColumnsInput = {
  comment_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};

/** select columns of table "comment_x_upload" */
export enum ICommentXUploadSelectColumn {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  UploadId = 'upload_id'
}

/** input type for updating data in table "comment_x_upload" */
export type ICommentXUploadSetInput = {
  comment_id?: InputMaybe<Scalars['uuid']['input']>;
  upload_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "comment_x_upload" */
export type ICommentXUploadStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ICommentXUploadStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ICommentXUploadStreamCursorValueInput = {
  comment_id?: InputMaybe<Scalars['uuid']['input']>;
  upload_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "comment_x_upload" */
export enum ICommentXUploadUpdateColumn {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  UploadId = 'upload_id'
}

export type ICommentXUploadUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ICommentXUploadSetInput>;
  /** filter the rows which have to be updated */
  where: ICommentXUploadBoolExp;
};

/** columns and relationships of "consumable" */
export type IConsumable = {
  __typename: 'consumable';
  asset_ids: Scalars['jsonb']['output'];
  /** A computed field, executes function "consumable_assets" */
  assets?: Maybe<Array<IAsset>>;
  /** An object relationship */
  avatar?: Maybe<IUpload>;
  avatar_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  consumable_assets: Array<IAssetXConsumable>;
  /** An aggregate relationship */
  consumable_assets_aggregate: IAssetXConsumableAggregate;
  contact_ids: Scalars['jsonb']['output'];
  /** A computed field, executes function "consumable_contacts" */
  contacts?: Maybe<Array<IContact>>;
  cost: Scalars['numeric']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by?: Maybe<IUser>;
  created_by_id: Scalars['uuid']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  group?: Maybe<IConsumableGroup>;
  group_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  location?: Maybe<Scalars['String']['output']>;
  location_id: Scalars['uuid']['output'];
  low_stock_since?: Maybe<Scalars['timestamptz']['output']>;
  metadata: Scalars['jsonb']['output'];
  min_quantity: Scalars['numeric']['output'];
  name: Scalars['String']['output'];
  properties?: Maybe<Scalars['jsonb']['output']>;
  public_id: Scalars['String']['output'];
  /** A computed field, executes function "compute_consumable_quantity" */
  quantity?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "consumable_computed_quantity_in_period" */
  quantity_in_period?: Maybe<Scalars['json']['output']>;
  /** A computed field, executes function "compute_consumable_quantity_until" */
  quantity_until?: Maybe<Scalars['numeric']['output']>;
  rank: Scalars['Int']['output'];
  /** An array relationship */
  storage_locations: Array<IConsumableStorageLocation>;
  /** An aggregate relationship */
  storage_locations_aggregate: IConsumableStorageLocationAggregate;
  unit: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  uploads: Array<IConsumableXUpload>;
  /** An aggregate relationship */
  uploads_aggregate: IConsumableXUploadAggregate;
};


/** columns and relationships of "consumable" */
export type IConsumableAssetIdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "consumable" */
export type IConsumableAssetsArgs = {
  distinct_on?: InputMaybe<Array<IAssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetOrderBy>>;
  where?: InputMaybe<IAssetBoolExp>;
};


/** columns and relationships of "consumable" */
export type IConsumableConsumableAssetsArgs = {
  distinct_on?: InputMaybe<Array<IAssetXConsumableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXConsumableOrderBy>>;
  where?: InputMaybe<IAssetXConsumableBoolExp>;
};


/** columns and relationships of "consumable" */
export type IConsumableConsumableAssetsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetXConsumableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXConsumableOrderBy>>;
  where?: InputMaybe<IAssetXConsumableBoolExp>;
};


/** columns and relationships of "consumable" */
export type IConsumableContactIdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "consumable" */
export type IConsumableContactsArgs = {
  distinct_on?: InputMaybe<Array<IContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IContactOrderBy>>;
  where?: InputMaybe<IContactBoolExp>;
};


/** columns and relationships of "consumable" */
export type IConsumableMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "consumable" */
export type IConsumablePropertiesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "consumable" */
export type IConsumableQuantityInPeriodArgs = {
  args: IQuantityInPeriodConsumableArgs;
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "consumable" */
export type IConsumableQuantityUntilArgs = {
  args: IQuantityUntilConsumableArgs;
};


/** columns and relationships of "consumable" */
export type IConsumableStorageLocationsArgs = {
  distinct_on?: InputMaybe<Array<IConsumableStorageLocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableStorageLocationOrderBy>>;
  where?: InputMaybe<IConsumableStorageLocationBoolExp>;
};


/** columns and relationships of "consumable" */
export type IConsumableStorageLocationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IConsumableStorageLocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableStorageLocationOrderBy>>;
  where?: InputMaybe<IConsumableStorageLocationBoolExp>;
};


/** columns and relationships of "consumable" */
export type IConsumableUploadsArgs = {
  distinct_on?: InputMaybe<Array<IConsumableXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableXUploadOrderBy>>;
  where?: InputMaybe<IConsumableXUploadBoolExp>;
};


/** columns and relationships of "consumable" */
export type IConsumableUploadsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IConsumableXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableXUploadOrderBy>>;
  where?: InputMaybe<IConsumableXUploadBoolExp>;
};

/** aggregated selection of "consumable" */
export type IConsumableAggregate = {
  __typename: 'consumable_aggregate';
  aggregate?: Maybe<IConsumableAggregateFields>;
  nodes: Array<IConsumable>;
};

export type IConsumableAggregateBoolExp = {
  count?: InputMaybe<IConsumableAggregateBoolExpCount>;
};

export type IConsumableAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IConsumableSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IConsumableBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "consumable" */
export type IConsumableAggregateFields = {
  __typename: 'consumable_aggregate_fields';
  avg?: Maybe<IConsumableAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IConsumableMaxFields>;
  min?: Maybe<IConsumableMinFields>;
  stddev?: Maybe<IConsumableStddevFields>;
  stddev_pop?: Maybe<IConsumableStddevPopFields>;
  stddev_samp?: Maybe<IConsumableStddevSampFields>;
  sum?: Maybe<IConsumableSumFields>;
  var_pop?: Maybe<IConsumableVarPopFields>;
  var_samp?: Maybe<IConsumableVarSampFields>;
  variance?: Maybe<IConsumableVarianceFields>;
};


/** aggregate fields of "consumable" */
export type IConsumableAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IConsumableSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "consumable" */
export type IConsumableAggregateOrderBy = {
  avg?: InputMaybe<IConsumableAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IConsumableMaxOrderBy>;
  min?: InputMaybe<IConsumableMinOrderBy>;
  stddev?: InputMaybe<IConsumableStddevOrderBy>;
  stddev_pop?: InputMaybe<IConsumableStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IConsumableStddevSampOrderBy>;
  sum?: InputMaybe<IConsumableSumOrderBy>;
  var_pop?: InputMaybe<IConsumableVarPopOrderBy>;
  var_samp?: InputMaybe<IConsumableVarSampOrderBy>;
  variance?: InputMaybe<IConsumableVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IConsumableAppendInput = {
  asset_ids?: InputMaybe<Scalars['jsonb']['input']>;
  contact_ids?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  properties?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "consumable" */
export type IConsumableArrRelInsertInput = {
  data: Array<IConsumableInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IConsumableOnConflict>;
};

/** aggregate avg on columns */
export type IConsumableAvgFields = {
  __typename: 'consumable_avg_fields';
  cost?: Maybe<Scalars['Float']['output']>;
  min_quantity?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_consumable_quantity" */
  quantity?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_consumable_quantity_until" */
  quantity_until?: Maybe<Scalars['numeric']['output']>;
  rank?: Maybe<Scalars['Float']['output']>;
};


/** aggregate avg on columns */
export type IConsumableAvgFieldsQuantityUntilArgs = {
  args: IQuantityUntilConsumableArgs;
};

/** order by avg() on columns of table "consumable" */
export type IConsumableAvgOrderBy = {
  cost?: InputMaybe<IOrderBy>;
  min_quantity?: InputMaybe<IOrderBy>;
  rank?: InputMaybe<IOrderBy>;
};

/** Boolean expression to filter rows from the table "consumable". All fields are combined with a logical 'AND'. */
export type IConsumableBoolExp = {
  _and?: InputMaybe<Array<IConsumableBoolExp>>;
  _not?: InputMaybe<IConsumableBoolExp>;
  _or?: InputMaybe<Array<IConsumableBoolExp>>;
  asset_ids?: InputMaybe<IJsonbComparisonExp>;
  assets?: InputMaybe<IAssetBoolExp>;
  avatar?: InputMaybe<IUploadBoolExp>;
  avatar_id?: InputMaybe<IUuidComparisonExp>;
  consumable_assets?: InputMaybe<IAssetXConsumableBoolExp>;
  consumable_assets_aggregate?: InputMaybe<IAssetXConsumableAggregateBoolExp>;
  contact_ids?: InputMaybe<IJsonbComparisonExp>;
  contacts?: InputMaybe<IContactBoolExp>;
  cost?: InputMaybe<INumericComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  created_by?: InputMaybe<IUserBoolExp>;
  created_by_id?: InputMaybe<IUuidComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  group?: InputMaybe<IConsumableGroupBoolExp>;
  group_id?: InputMaybe<IUuidComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  location?: InputMaybe<IStringComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  low_stock_since?: InputMaybe<ITimestamptzComparisonExp>;
  metadata?: InputMaybe<IJsonbComparisonExp>;
  min_quantity?: InputMaybe<INumericComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  properties?: InputMaybe<IJsonbComparisonExp>;
  public_id?: InputMaybe<IStringComparisonExp>;
  quantity?: InputMaybe<INumericComparisonExp>;
  rank?: InputMaybe<IIntComparisonExp>;
  storage_locations?: InputMaybe<IConsumableStorageLocationBoolExp>;
  storage_locations_aggregate?: InputMaybe<IConsumableStorageLocationAggregateBoolExp>;
  unit?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  uploads?: InputMaybe<IConsumableXUploadBoolExp>;
  uploads_aggregate?: InputMaybe<IConsumableXUploadAggregateBoolExp>;
};

/** columns and relationships of "consumable_config" */
export type IConsumableConfig = {
  __typename: 'consumable_config';
  location_id: Scalars['uuid']['output'];
  low_stock_notification_user_ids: Scalars['jsonb']['output'];
  /** A computed field, executes function "consumable_config_low_stock_notification_users" */
  low_stock_notification_users?: Maybe<Array<IUser>>;
  properties: Scalars['jsonb']['output'];
};


/** columns and relationships of "consumable_config" */
export type IConsumableConfigLowStockNotificationUserIdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "consumable_config" */
export type IConsumableConfigLowStockNotificationUsersArgs = {
  distinct_on?: InputMaybe<Array<IUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUserOrderBy>>;
  where?: InputMaybe<IUserBoolExp>;
};


/** columns and relationships of "consumable_config" */
export type IConsumableConfigPropertiesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "consumable_config" */
export type IConsumableConfigAggregate = {
  __typename: 'consumable_config_aggregate';
  aggregate?: Maybe<IConsumableConfigAggregateFields>;
  nodes: Array<IConsumableConfig>;
};

/** aggregate fields of "consumable_config" */
export type IConsumableConfigAggregateFields = {
  __typename: 'consumable_config_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IConsumableConfigMaxFields>;
  min?: Maybe<IConsumableConfigMinFields>;
};


/** aggregate fields of "consumable_config" */
export type IConsumableConfigAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IConsumableConfigSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IConsumableConfigAppendInput = {
  low_stock_notification_user_ids?: InputMaybe<Scalars['jsonb']['input']>;
  properties?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "consumable_config". All fields are combined with a logical 'AND'. */
export type IConsumableConfigBoolExp = {
  _and?: InputMaybe<Array<IConsumableConfigBoolExp>>;
  _not?: InputMaybe<IConsumableConfigBoolExp>;
  _or?: InputMaybe<Array<IConsumableConfigBoolExp>>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  low_stock_notification_user_ids?: InputMaybe<IJsonbComparisonExp>;
  low_stock_notification_users?: InputMaybe<IUserBoolExp>;
  properties?: InputMaybe<IJsonbComparisonExp>;
};

/** unique or primary key constraints on table "consumable_config" */
export enum IConsumableConfigConstraint {
  /** unique or primary key constraint on columns "location_id" */
  ConsumableConfigPkey = 'consumable_config_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IConsumableConfigDeleteAtPathInput = {
  low_stock_notification_user_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  properties?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IConsumableConfigDeleteElemInput = {
  low_stock_notification_user_ids?: InputMaybe<Scalars['Int']['input']>;
  properties?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IConsumableConfigDeleteKeyInput = {
  low_stock_notification_user_ids?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "consumable_config" */
export type IConsumableConfigInsertInput = {
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  low_stock_notification_user_ids?: InputMaybe<Scalars['jsonb']['input']>;
  properties?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type IConsumableConfigMaxFields = {
  __typename: 'consumable_config_max_fields';
  location_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type IConsumableConfigMinFields = {
  __typename: 'consumable_config_min_fields';
  location_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "consumable_config" */
export type IConsumableConfigMutationResponse = {
  __typename: 'consumable_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IConsumableConfig>;
};

/** on_conflict condition type for table "consumable_config" */
export type IConsumableConfigOnConflict = {
  constraint: IConsumableConfigConstraint;
  update_columns?: Array<IConsumableConfigUpdateColumn>;
  where?: InputMaybe<IConsumableConfigBoolExp>;
};

/** Ordering options when selecting data from "consumable_config". */
export type IConsumableConfigOrderBy = {
  location_id?: InputMaybe<IOrderBy>;
  low_stock_notification_user_ids?: InputMaybe<IOrderBy>;
  low_stock_notification_users_aggregate?: InputMaybe<IUserAggregateOrderBy>;
  properties?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: consumable_config */
export type IConsumableConfigPkColumnsInput = {
  location_id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IConsumableConfigPrependInput = {
  low_stock_notification_user_ids?: InputMaybe<Scalars['jsonb']['input']>;
  properties?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "consumable_config" */
export enum IConsumableConfigSelectColumn {
  /** column name */
  LocationId = 'location_id',
  /** column name */
  LowStockNotificationUserIds = 'low_stock_notification_user_ids',
  /** column name */
  Properties = 'properties'
}

/** input type for updating data in table "consumable_config" */
export type IConsumableConfigSetInput = {
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  low_stock_notification_user_ids?: InputMaybe<Scalars['jsonb']['input']>;
  properties?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "consumable_config" */
export type IConsumableConfigStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IConsumableConfigStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IConsumableConfigStreamCursorValueInput = {
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  low_stock_notification_user_ids?: InputMaybe<Scalars['jsonb']['input']>;
  properties?: InputMaybe<Scalars['jsonb']['input']>;
};

/** update columns of table "consumable_config" */
export enum IConsumableConfigUpdateColumn {
  /** column name */
  LocationId = 'location_id',
  /** column name */
  LowStockNotificationUserIds = 'low_stock_notification_user_ids',
  /** column name */
  Properties = 'properties'
}

export type IConsumableConfigUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IConsumableConfigAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IConsumableConfigDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IConsumableConfigDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IConsumableConfigDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IConsumableConfigPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IConsumableConfigSetInput>;
  /** filter the rows which have to be updated */
  where: IConsumableConfigBoolExp;
};

/** unique or primary key constraints on table "consumable" */
export enum IConsumableConstraint {
  /** unique or primary key constraint on columns "location_id", "public_id" */
  ConsumableLocationIdPublicIdKey = 'consumable_location_id_public_id_key',
  /** unique or primary key constraint on columns "id" */
  ConsumablePkey = 'consumable_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IConsumableDeleteAtPathInput = {
  asset_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  contact_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
  properties?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IConsumableDeleteElemInput = {
  asset_ids?: InputMaybe<Scalars['Int']['input']>;
  contact_ids?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['Int']['input']>;
  properties?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IConsumableDeleteKeyInput = {
  asset_ids?: InputMaybe<Scalars['String']['input']>;
  contact_ids?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "consumable_group" */
export type IConsumableGroup = {
  __typename: 'consumable_group';
  /** An array relationship */
  consumables: Array<IConsumable>;
  /** An aggregate relationship */
  consumables_aggregate: IConsumableAggregate;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  location_id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "consumable_group" */
export type IConsumableGroupConsumablesArgs = {
  distinct_on?: InputMaybe<Array<IConsumableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableOrderBy>>;
  where?: InputMaybe<IConsumableBoolExp>;
};


/** columns and relationships of "consumable_group" */
export type IConsumableGroupConsumablesAggregateArgs = {
  distinct_on?: InputMaybe<Array<IConsumableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableOrderBy>>;
  where?: InputMaybe<IConsumableBoolExp>;
};

/** aggregated selection of "consumable_group" */
export type IConsumableGroupAggregate = {
  __typename: 'consumable_group_aggregate';
  aggregate?: Maybe<IConsumableGroupAggregateFields>;
  nodes: Array<IConsumableGroup>;
};

/** aggregate fields of "consumable_group" */
export type IConsumableGroupAggregateFields = {
  __typename: 'consumable_group_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IConsumableGroupMaxFields>;
  min?: Maybe<IConsumableGroupMinFields>;
};


/** aggregate fields of "consumable_group" */
export type IConsumableGroupAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IConsumableGroupSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "consumable_group". All fields are combined with a logical 'AND'. */
export type IConsumableGroupBoolExp = {
  _and?: InputMaybe<Array<IConsumableGroupBoolExp>>;
  _not?: InputMaybe<IConsumableGroupBoolExp>;
  _or?: InputMaybe<Array<IConsumableGroupBoolExp>>;
  consumables?: InputMaybe<IConsumableBoolExp>;
  consumables_aggregate?: InputMaybe<IConsumableAggregateBoolExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "consumable_group" */
export enum IConsumableGroupConstraint {
  /** unique or primary key constraint on columns "location_id", "name" */
  ConsumableGroupLocationIdNameKey = 'consumable_group_location_id_name_key',
  /** unique or primary key constraint on columns "id" */
  ConsumableGroupPkey = 'consumable_group_pkey'
}

/** input type for inserting data into table "consumable_group" */
export type IConsumableGroupInsertInput = {
  consumables?: InputMaybe<IConsumableArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type IConsumableGroupMaxFields = {
  __typename: 'consumable_group_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type IConsumableGroupMinFields = {
  __typename: 'consumable_group_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "consumable_group" */
export type IConsumableGroupMutationResponse = {
  __typename: 'consumable_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IConsumableGroup>;
};

/** input type for inserting object relation for remote table "consumable_group" */
export type IConsumableGroupObjRelInsertInput = {
  data: IConsumableGroupInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IConsumableGroupOnConflict>;
};

/** on_conflict condition type for table "consumable_group" */
export type IConsumableGroupOnConflict = {
  constraint: IConsumableGroupConstraint;
  update_columns?: Array<IConsumableGroupUpdateColumn>;
  where?: InputMaybe<IConsumableGroupBoolExp>;
};

/** Ordering options when selecting data from "consumable_group". */
export type IConsumableGroupOrderBy = {
  consumables_aggregate?: InputMaybe<IConsumableAggregateOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: consumable_group */
export type IConsumableGroupPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "consumable_group" */
export enum IConsumableGroupSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "consumable_group" */
export type IConsumableGroupSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "consumable_group" */
export type IConsumableGroupStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IConsumableGroupStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IConsumableGroupStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "consumable_group" */
export enum IConsumableGroupUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type IConsumableGroupUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IConsumableGroupSetInput>;
  /** filter the rows which have to be updated */
  where: IConsumableGroupBoolExp;
};

/** input type for incrementing numeric columns in table "consumable" */
export type IConsumableIncInput = {
  cost?: InputMaybe<Scalars['numeric']['input']>;
  min_quantity?: InputMaybe<Scalars['numeric']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "consumable" */
export type IConsumableInsertInput = {
  asset_ids?: InputMaybe<Scalars['jsonb']['input']>;
  avatar?: InputMaybe<IUploadObjRelInsertInput>;
  avatar_id?: InputMaybe<Scalars['uuid']['input']>;
  consumable_assets?: InputMaybe<IAssetXConsumableArrRelInsertInput>;
  contact_ids?: InputMaybe<Scalars['jsonb']['input']>;
  cost?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<IUserObjRelInsertInput>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<IConsumableGroupObjRelInsertInput>;
  group_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  low_stock_since?: InputMaybe<Scalars['timestamptz']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  min_quantity?: InputMaybe<Scalars['numeric']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<Scalars['jsonb']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  storage_locations?: InputMaybe<IConsumableStorageLocationArrRelInsertInput>;
  unit?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  uploads?: InputMaybe<IConsumableXUploadArrRelInsertInput>;
};

/** columns and relationships of "consumable_log" */
export type IConsumableLog = {
  __typename: 'consumable_log';
  adjustment: Scalars['numeric']['output'];
  adjustment_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  asset?: Maybe<IAsset>;
  asset_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  consumable?: Maybe<IConsumable>;
  consumable_id: Scalars['uuid']['output'];
  cost_per_unit: Scalars['numeric']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by?: Maybe<IUser>;
  created_by_id: Scalars['uuid']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  place?: Maybe<IPlace>;
  place_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  task?: Maybe<IWorkOrder>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "consumable_log" */
export type IConsumableLogAggregate = {
  __typename: 'consumable_log_aggregate';
  aggregate?: Maybe<IConsumableLogAggregateFields>;
  nodes: Array<IConsumableLog>;
};

export type IConsumableLogAggregateBoolExp = {
  count?: InputMaybe<IConsumableLogAggregateBoolExpCount>;
};

export type IConsumableLogAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IConsumableLogSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IConsumableLogBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "consumable_log" */
export type IConsumableLogAggregateFields = {
  __typename: 'consumable_log_aggregate_fields';
  avg?: Maybe<IConsumableLogAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IConsumableLogMaxFields>;
  min?: Maybe<IConsumableLogMinFields>;
  stddev?: Maybe<IConsumableLogStddevFields>;
  stddev_pop?: Maybe<IConsumableLogStddevPopFields>;
  stddev_samp?: Maybe<IConsumableLogStddevSampFields>;
  sum?: Maybe<IConsumableLogSumFields>;
  var_pop?: Maybe<IConsumableLogVarPopFields>;
  var_samp?: Maybe<IConsumableLogVarSampFields>;
  variance?: Maybe<IConsumableLogVarianceFields>;
};


/** aggregate fields of "consumable_log" */
export type IConsumableLogAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IConsumableLogSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "consumable_log" */
export type IConsumableLogAggregateOrderBy = {
  avg?: InputMaybe<IConsumableLogAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IConsumableLogMaxOrderBy>;
  min?: InputMaybe<IConsumableLogMinOrderBy>;
  stddev?: InputMaybe<IConsumableLogStddevOrderBy>;
  stddev_pop?: InputMaybe<IConsumableLogStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IConsumableLogStddevSampOrderBy>;
  sum?: InputMaybe<IConsumableLogSumOrderBy>;
  var_pop?: InputMaybe<IConsumableLogVarPopOrderBy>;
  var_samp?: InputMaybe<IConsumableLogVarSampOrderBy>;
  variance?: InputMaybe<IConsumableLogVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "consumable_log" */
export type IConsumableLogArrRelInsertInput = {
  data: Array<IConsumableLogInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IConsumableLogOnConflict>;
};

/** aggregate avg on columns */
export type IConsumableLogAvgFields = {
  __typename: 'consumable_log_avg_fields';
  adjustment?: Maybe<Scalars['Float']['output']>;
  cost_per_unit?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "consumable_log" */
export type IConsumableLogAvgOrderBy = {
  adjustment?: InputMaybe<IOrderBy>;
  cost_per_unit?: InputMaybe<IOrderBy>;
};

/** Boolean expression to filter rows from the table "consumable_log". All fields are combined with a logical 'AND'. */
export type IConsumableLogBoolExp = {
  _and?: InputMaybe<Array<IConsumableLogBoolExp>>;
  _not?: InputMaybe<IConsumableLogBoolExp>;
  _or?: InputMaybe<Array<IConsumableLogBoolExp>>;
  adjustment?: InputMaybe<INumericComparisonExp>;
  adjustment_at?: InputMaybe<ITimestamptzComparisonExp>;
  asset?: InputMaybe<IAssetBoolExp>;
  asset_id?: InputMaybe<IUuidComparisonExp>;
  consumable?: InputMaybe<IConsumableBoolExp>;
  consumable_id?: InputMaybe<IUuidComparisonExp>;
  cost_per_unit?: InputMaybe<INumericComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  created_by?: InputMaybe<IUserBoolExp>;
  created_by_id?: InputMaybe<IUuidComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  place?: InputMaybe<IPlaceBoolExp>;
  place_id?: InputMaybe<IUuidComparisonExp>;
  task?: InputMaybe<IWorkOrderBoolExp>;
  task_id?: InputMaybe<IUuidComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "consumable_log" */
export enum IConsumableLogConstraint {
  /** unique or primary key constraint on columns "id" */
  ConsumableLogPkey = 'consumable_log_pkey'
}

/** input type for incrementing numeric columns in table "consumable_log" */
export type IConsumableLogIncInput = {
  adjustment?: InputMaybe<Scalars['numeric']['input']>;
  cost_per_unit?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "consumable_log" */
export type IConsumableLogInsertInput = {
  adjustment?: InputMaybe<Scalars['numeric']['input']>;
  adjustment_at?: InputMaybe<Scalars['timestamptz']['input']>;
  asset?: InputMaybe<IAssetObjRelInsertInput>;
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  consumable?: InputMaybe<IConsumableObjRelInsertInput>;
  consumable_id?: InputMaybe<Scalars['uuid']['input']>;
  cost_per_unit?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<IUserObjRelInsertInput>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  place?: InputMaybe<IPlaceObjRelInsertInput>;
  place_id?: InputMaybe<Scalars['uuid']['input']>;
  task?: InputMaybe<IWorkOrderObjRelInsertInput>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type IConsumableLogMaxFields = {
  __typename: 'consumable_log_max_fields';
  adjustment?: Maybe<Scalars['numeric']['output']>;
  adjustment_at?: Maybe<Scalars['timestamptz']['output']>;
  asset_id?: Maybe<Scalars['uuid']['output']>;
  consumable_id?: Maybe<Scalars['uuid']['output']>;
  cost_per_unit?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  place_id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "consumable_log" */
export type IConsumableLogMaxOrderBy = {
  adjustment?: InputMaybe<IOrderBy>;
  adjustment_at?: InputMaybe<IOrderBy>;
  asset_id?: InputMaybe<IOrderBy>;
  consumable_id?: InputMaybe<IOrderBy>;
  cost_per_unit?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  place_id?: InputMaybe<IOrderBy>;
  task_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IConsumableLogMinFields = {
  __typename: 'consumable_log_min_fields';
  adjustment?: Maybe<Scalars['numeric']['output']>;
  adjustment_at?: Maybe<Scalars['timestamptz']['output']>;
  asset_id?: Maybe<Scalars['uuid']['output']>;
  consumable_id?: Maybe<Scalars['uuid']['output']>;
  cost_per_unit?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  place_id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "consumable_log" */
export type IConsumableLogMinOrderBy = {
  adjustment?: InputMaybe<IOrderBy>;
  adjustment_at?: InputMaybe<IOrderBy>;
  asset_id?: InputMaybe<IOrderBy>;
  consumable_id?: InputMaybe<IOrderBy>;
  cost_per_unit?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  place_id?: InputMaybe<IOrderBy>;
  task_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "consumable_log" */
export type IConsumableLogMutationResponse = {
  __typename: 'consumable_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IConsumableLog>;
};

/** on_conflict condition type for table "consumable_log" */
export type IConsumableLogOnConflict = {
  constraint: IConsumableLogConstraint;
  update_columns?: Array<IConsumableLogUpdateColumn>;
  where?: InputMaybe<IConsumableLogBoolExp>;
};

/** Ordering options when selecting data from "consumable_log". */
export type IConsumableLogOrderBy = {
  adjustment?: InputMaybe<IOrderBy>;
  adjustment_at?: InputMaybe<IOrderBy>;
  asset?: InputMaybe<IAssetOrderBy>;
  asset_id?: InputMaybe<IOrderBy>;
  consumable?: InputMaybe<IConsumableOrderBy>;
  consumable_id?: InputMaybe<IOrderBy>;
  cost_per_unit?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by?: InputMaybe<IUserOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  place?: InputMaybe<IPlaceOrderBy>;
  place_id?: InputMaybe<IOrderBy>;
  task?: InputMaybe<IWorkOrderOrderBy>;
  task_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: consumable_log */
export type IConsumableLogPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "consumable_log" */
export enum IConsumableLogSelectColumn {
  /** column name */
  Adjustment = 'adjustment',
  /** column name */
  AdjustmentAt = 'adjustment_at',
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  ConsumableId = 'consumable_id',
  /** column name */
  CostPerUnit = 'cost_per_unit',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "consumable_log" */
export type IConsumableLogSetInput = {
  adjustment?: InputMaybe<Scalars['numeric']['input']>;
  adjustment_at?: InputMaybe<Scalars['timestamptz']['input']>;
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  consumable_id?: InputMaybe<Scalars['uuid']['input']>;
  cost_per_unit?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  place_id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type IConsumableLogStddevFields = {
  __typename: 'consumable_log_stddev_fields';
  adjustment?: Maybe<Scalars['Float']['output']>;
  cost_per_unit?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "consumable_log" */
export type IConsumableLogStddevOrderBy = {
  adjustment?: InputMaybe<IOrderBy>;
  cost_per_unit?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_pop on columns */
export type IConsumableLogStddevPopFields = {
  __typename: 'consumable_log_stddev_pop_fields';
  adjustment?: Maybe<Scalars['Float']['output']>;
  cost_per_unit?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "consumable_log" */
export type IConsumableLogStddevPopOrderBy = {
  adjustment?: InputMaybe<IOrderBy>;
  cost_per_unit?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_samp on columns */
export type IConsumableLogStddevSampFields = {
  __typename: 'consumable_log_stddev_samp_fields';
  adjustment?: Maybe<Scalars['Float']['output']>;
  cost_per_unit?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "consumable_log" */
export type IConsumableLogStddevSampOrderBy = {
  adjustment?: InputMaybe<IOrderBy>;
  cost_per_unit?: InputMaybe<IOrderBy>;
};

/** Streaming cursor of the table "consumable_log" */
export type IConsumableLogStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IConsumableLogStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IConsumableLogStreamCursorValueInput = {
  adjustment?: InputMaybe<Scalars['numeric']['input']>;
  adjustment_at?: InputMaybe<Scalars['timestamptz']['input']>;
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  consumable_id?: InputMaybe<Scalars['uuid']['input']>;
  cost_per_unit?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  place_id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type IConsumableLogSumFields = {
  __typename: 'consumable_log_sum_fields';
  adjustment?: Maybe<Scalars['numeric']['output']>;
  cost_per_unit?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "consumable_log" */
export type IConsumableLogSumOrderBy = {
  adjustment?: InputMaybe<IOrderBy>;
  cost_per_unit?: InputMaybe<IOrderBy>;
};

/** update columns of table "consumable_log" */
export enum IConsumableLogUpdateColumn {
  /** column name */
  Adjustment = 'adjustment',
  /** column name */
  AdjustmentAt = 'adjustment_at',
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  ConsumableId = 'consumable_id',
  /** column name */
  CostPerUnit = 'cost_per_unit',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type IConsumableLogUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IConsumableLogIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IConsumableLogSetInput>;
  /** filter the rows which have to be updated */
  where: IConsumableLogBoolExp;
};

/** aggregate var_pop on columns */
export type IConsumableLogVarPopFields = {
  __typename: 'consumable_log_var_pop_fields';
  adjustment?: Maybe<Scalars['Float']['output']>;
  cost_per_unit?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "consumable_log" */
export type IConsumableLogVarPopOrderBy = {
  adjustment?: InputMaybe<IOrderBy>;
  cost_per_unit?: InputMaybe<IOrderBy>;
};

/** aggregate var_samp on columns */
export type IConsumableLogVarSampFields = {
  __typename: 'consumable_log_var_samp_fields';
  adjustment?: Maybe<Scalars['Float']['output']>;
  cost_per_unit?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "consumable_log" */
export type IConsumableLogVarSampOrderBy = {
  adjustment?: InputMaybe<IOrderBy>;
  cost_per_unit?: InputMaybe<IOrderBy>;
};

/** aggregate variance on columns */
export type IConsumableLogVarianceFields = {
  __typename: 'consumable_log_variance_fields';
  adjustment?: Maybe<Scalars['Float']['output']>;
  cost_per_unit?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "consumable_log" */
export type IConsumableLogVarianceOrderBy = {
  adjustment?: InputMaybe<IOrderBy>;
  cost_per_unit?: InputMaybe<IOrderBy>;
};

/** aggregate max on columns */
export type IConsumableMaxFields = {
  __typename: 'consumable_max_fields';
  avatar_id?: Maybe<Scalars['uuid']['output']>;
  cost?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  group_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  low_stock_since?: Maybe<Scalars['timestamptz']['output']>;
  min_quantity?: Maybe<Scalars['numeric']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  public_id?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "compute_consumable_quantity" */
  quantity?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_consumable_quantity_until" */
  quantity_until?: Maybe<Scalars['numeric']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};


/** aggregate max on columns */
export type IConsumableMaxFieldsQuantityUntilArgs = {
  args: IQuantityUntilConsumableArgs;
};

/** order by max() on columns of table "consumable" */
export type IConsumableMaxOrderBy = {
  avatar_id?: InputMaybe<IOrderBy>;
  cost?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  group_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  low_stock_since?: InputMaybe<IOrderBy>;
  min_quantity?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  public_id?: InputMaybe<IOrderBy>;
  rank?: InputMaybe<IOrderBy>;
  unit?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IConsumableMinFields = {
  __typename: 'consumable_min_fields';
  avatar_id?: Maybe<Scalars['uuid']['output']>;
  cost?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  group_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  low_stock_since?: Maybe<Scalars['timestamptz']['output']>;
  min_quantity?: Maybe<Scalars['numeric']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  public_id?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "compute_consumable_quantity" */
  quantity?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_consumable_quantity_until" */
  quantity_until?: Maybe<Scalars['numeric']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};


/** aggregate min on columns */
export type IConsumableMinFieldsQuantityUntilArgs = {
  args: IQuantityUntilConsumableArgs;
};

/** order by min() on columns of table "consumable" */
export type IConsumableMinOrderBy = {
  avatar_id?: InputMaybe<IOrderBy>;
  cost?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  group_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  low_stock_since?: InputMaybe<IOrderBy>;
  min_quantity?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  public_id?: InputMaybe<IOrderBy>;
  rank?: InputMaybe<IOrderBy>;
  unit?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "consumable" */
export type IConsumableMutationResponse = {
  __typename: 'consumable_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IConsumable>;
};

/** input type for inserting object relation for remote table "consumable" */
export type IConsumableObjRelInsertInput = {
  data: IConsumableInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IConsumableOnConflict>;
};

/** on_conflict condition type for table "consumable" */
export type IConsumableOnConflict = {
  constraint: IConsumableConstraint;
  update_columns?: Array<IConsumableUpdateColumn>;
  where?: InputMaybe<IConsumableBoolExp>;
};

/** Ordering options when selecting data from "consumable". */
export type IConsumableOrderBy = {
  asset_ids?: InputMaybe<IOrderBy>;
  assets_aggregate?: InputMaybe<IAssetAggregateOrderBy>;
  avatar?: InputMaybe<IUploadOrderBy>;
  avatar_id?: InputMaybe<IOrderBy>;
  consumable_assets_aggregate?: InputMaybe<IAssetXConsumableAggregateOrderBy>;
  contact_ids?: InputMaybe<IOrderBy>;
  contacts_aggregate?: InputMaybe<IContactAggregateOrderBy>;
  cost?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by?: InputMaybe<IUserOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  group?: InputMaybe<IConsumableGroupOrderBy>;
  group_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  low_stock_since?: InputMaybe<IOrderBy>;
  metadata?: InputMaybe<IOrderBy>;
  min_quantity?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  properties?: InputMaybe<IOrderBy>;
  public_id?: InputMaybe<IOrderBy>;
  quantity?: InputMaybe<IOrderBy>;
  rank?: InputMaybe<IOrderBy>;
  storage_locations_aggregate?: InputMaybe<IConsumableStorageLocationAggregateOrderBy>;
  unit?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  uploads_aggregate?: InputMaybe<IConsumableXUploadAggregateOrderBy>;
};

/** primary key columns input for table: consumable */
export type IConsumablePkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IConsumablePrependInput = {
  asset_ids?: InputMaybe<Scalars['jsonb']['input']>;
  contact_ids?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  properties?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "consumable" */
export enum IConsumableSelectColumn {
  /** column name */
  AssetIds = 'asset_ids',
  /** column name */
  AvatarId = 'avatar_id',
  /** column name */
  ContactIds = 'contact_ids',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Description = 'description',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  LowStockSince = 'low_stock_since',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MinQuantity = 'min_quantity',
  /** column name */
  Name = 'name',
  /** column name */
  Properties = 'properties',
  /** column name */
  PublicId = 'public_id',
  /** column name */
  Rank = 'rank',
  /** column name */
  Unit = 'unit',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "consumable" */
export type IConsumableSetInput = {
  asset_ids?: InputMaybe<Scalars['jsonb']['input']>;
  avatar_id?: InputMaybe<Scalars['uuid']['input']>;
  contact_ids?: InputMaybe<Scalars['jsonb']['input']>;
  cost?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  group_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  low_stock_since?: InputMaybe<Scalars['timestamptz']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  min_quantity?: InputMaybe<Scalars['numeric']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<Scalars['jsonb']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type IConsumableStddevFields = {
  __typename: 'consumable_stddev_fields';
  cost?: Maybe<Scalars['Float']['output']>;
  min_quantity?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_consumable_quantity" */
  quantity?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_consumable_quantity_until" */
  quantity_until?: Maybe<Scalars['numeric']['output']>;
  rank?: Maybe<Scalars['Float']['output']>;
};


/** aggregate stddev on columns */
export type IConsumableStddevFieldsQuantityUntilArgs = {
  args: IQuantityUntilConsumableArgs;
};

/** order by stddev() on columns of table "consumable" */
export type IConsumableStddevOrderBy = {
  cost?: InputMaybe<IOrderBy>;
  min_quantity?: InputMaybe<IOrderBy>;
  rank?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_pop on columns */
export type IConsumableStddevPopFields = {
  __typename: 'consumable_stddev_pop_fields';
  cost?: Maybe<Scalars['Float']['output']>;
  min_quantity?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_consumable_quantity" */
  quantity?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_consumable_quantity_until" */
  quantity_until?: Maybe<Scalars['numeric']['output']>;
  rank?: Maybe<Scalars['Float']['output']>;
};


/** aggregate stddev_pop on columns */
export type IConsumableStddevPopFieldsQuantityUntilArgs = {
  args: IQuantityUntilConsumableArgs;
};

/** order by stddev_pop() on columns of table "consumable" */
export type IConsumableStddevPopOrderBy = {
  cost?: InputMaybe<IOrderBy>;
  min_quantity?: InputMaybe<IOrderBy>;
  rank?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_samp on columns */
export type IConsumableStddevSampFields = {
  __typename: 'consumable_stddev_samp_fields';
  cost?: Maybe<Scalars['Float']['output']>;
  min_quantity?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_consumable_quantity" */
  quantity?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_consumable_quantity_until" */
  quantity_until?: Maybe<Scalars['numeric']['output']>;
  rank?: Maybe<Scalars['Float']['output']>;
};


/** aggregate stddev_samp on columns */
export type IConsumableStddevSampFieldsQuantityUntilArgs = {
  args: IQuantityUntilConsumableArgs;
};

/** order by stddev_samp() on columns of table "consumable" */
export type IConsumableStddevSampOrderBy = {
  cost?: InputMaybe<IOrderBy>;
  min_quantity?: InputMaybe<IOrderBy>;
  rank?: InputMaybe<IOrderBy>;
};

/** The place and metadata for consumables */
export type IConsumableStorageLocation = {
  __typename: 'consumable_storage_location';
  area?: Maybe<Scalars['String']['output']>;
  consumable_id: Scalars['uuid']['output'];
  location_id: Scalars['uuid']['output'];
  /** An object relationship */
  place: IPlace;
  place_id: Scalars['uuid']['output'];
  /** A computed field, executes function "compute_consumable_storage_location_quantity" */
  quantity?: Maybe<Scalars['numeric']['output']>;
};

/** aggregated selection of "consumable_storage_location" */
export type IConsumableStorageLocationAggregate = {
  __typename: 'consumable_storage_location_aggregate';
  aggregate?: Maybe<IConsumableStorageLocationAggregateFields>;
  nodes: Array<IConsumableStorageLocation>;
};

export type IConsumableStorageLocationAggregateBoolExp = {
  count?: InputMaybe<IConsumableStorageLocationAggregateBoolExpCount>;
};

export type IConsumableStorageLocationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IConsumableStorageLocationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IConsumableStorageLocationBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "consumable_storage_location" */
export type IConsumableStorageLocationAggregateFields = {
  __typename: 'consumable_storage_location_aggregate_fields';
  avg?: Maybe<IConsumableStorageLocationAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IConsumableStorageLocationMaxFields>;
  min?: Maybe<IConsumableStorageLocationMinFields>;
  stddev?: Maybe<IConsumableStorageLocationStddevFields>;
  stddev_pop?: Maybe<IConsumableStorageLocationStddevPopFields>;
  stddev_samp?: Maybe<IConsumableStorageLocationStddevSampFields>;
  sum?: Maybe<IConsumableStorageLocationSumFields>;
  var_pop?: Maybe<IConsumableStorageLocationVarPopFields>;
  var_samp?: Maybe<IConsumableStorageLocationVarSampFields>;
  variance?: Maybe<IConsumableStorageLocationVarianceFields>;
};


/** aggregate fields of "consumable_storage_location" */
export type IConsumableStorageLocationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IConsumableStorageLocationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "consumable_storage_location" */
export type IConsumableStorageLocationAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IConsumableStorageLocationMaxOrderBy>;
  min?: InputMaybe<IConsumableStorageLocationMinOrderBy>;
};

/** input type for inserting array relation for remote table "consumable_storage_location" */
export type IConsumableStorageLocationArrRelInsertInput = {
  data: Array<IConsumableStorageLocationInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IConsumableStorageLocationOnConflict>;
};

/** aggregate avg on columns */
export type IConsumableStorageLocationAvgFields = {
  __typename: 'consumable_storage_location_avg_fields';
  /** A computed field, executes function "compute_consumable_storage_location_quantity" */
  quantity?: Maybe<Scalars['numeric']['output']>;
};

/** Boolean expression to filter rows from the table "consumable_storage_location". All fields are combined with a logical 'AND'. */
export type IConsumableStorageLocationBoolExp = {
  _and?: InputMaybe<Array<IConsumableStorageLocationBoolExp>>;
  _not?: InputMaybe<IConsumableStorageLocationBoolExp>;
  _or?: InputMaybe<Array<IConsumableStorageLocationBoolExp>>;
  area?: InputMaybe<IStringComparisonExp>;
  consumable_id?: InputMaybe<IUuidComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  place?: InputMaybe<IPlaceBoolExp>;
  place_id?: InputMaybe<IUuidComparisonExp>;
  quantity?: InputMaybe<INumericComparisonExp>;
};

/** unique or primary key constraints on table "consumable_storage_location" */
export enum IConsumableStorageLocationConstraint {
  /** unique or primary key constraint on columns "place_id", "consumable_id" */
  ConsumableStorageLocationPkey = 'consumable_storage_location_pkey'
}

/** input type for inserting data into table "consumable_storage_location" */
export type IConsumableStorageLocationInsertInput = {
  area?: InputMaybe<Scalars['String']['input']>;
  consumable_id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  place?: InputMaybe<IPlaceObjRelInsertInput>;
  place_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IConsumableStorageLocationMaxFields = {
  __typename: 'consumable_storage_location_max_fields';
  area?: Maybe<Scalars['String']['output']>;
  consumable_id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  place_id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "compute_consumable_storage_location_quantity" */
  quantity?: Maybe<Scalars['numeric']['output']>;
};

/** order by max() on columns of table "consumable_storage_location" */
export type IConsumableStorageLocationMaxOrderBy = {
  area?: InputMaybe<IOrderBy>;
  consumable_id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  place_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IConsumableStorageLocationMinFields = {
  __typename: 'consumable_storage_location_min_fields';
  area?: Maybe<Scalars['String']['output']>;
  consumable_id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  place_id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "compute_consumable_storage_location_quantity" */
  quantity?: Maybe<Scalars['numeric']['output']>;
};

/** order by min() on columns of table "consumable_storage_location" */
export type IConsumableStorageLocationMinOrderBy = {
  area?: InputMaybe<IOrderBy>;
  consumable_id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  place_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "consumable_storage_location" */
export type IConsumableStorageLocationMutationResponse = {
  __typename: 'consumable_storage_location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IConsumableStorageLocation>;
};

/** on_conflict condition type for table "consumable_storage_location" */
export type IConsumableStorageLocationOnConflict = {
  constraint: IConsumableStorageLocationConstraint;
  update_columns?: Array<IConsumableStorageLocationUpdateColumn>;
  where?: InputMaybe<IConsumableStorageLocationBoolExp>;
};

/** Ordering options when selecting data from "consumable_storage_location". */
export type IConsumableStorageLocationOrderBy = {
  area?: InputMaybe<IOrderBy>;
  consumable_id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  place?: InputMaybe<IPlaceOrderBy>;
  place_id?: InputMaybe<IOrderBy>;
  quantity?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: consumable_storage_location */
export type IConsumableStorageLocationPkColumnsInput = {
  consumable_id: Scalars['uuid']['input'];
  place_id: Scalars['uuid']['input'];
};

/** select columns of table "consumable_storage_location" */
export enum IConsumableStorageLocationSelectColumn {
  /** column name */
  Area = 'area',
  /** column name */
  ConsumableId = 'consumable_id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  PlaceId = 'place_id'
}

/** input type for updating data in table "consumable_storage_location" */
export type IConsumableStorageLocationSetInput = {
  area?: InputMaybe<Scalars['String']['input']>;
  consumable_id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  place_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type IConsumableStorageLocationStddevFields = {
  __typename: 'consumable_storage_location_stddev_fields';
  /** A computed field, executes function "compute_consumable_storage_location_quantity" */
  quantity?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate stddev_pop on columns */
export type IConsumableStorageLocationStddevPopFields = {
  __typename: 'consumable_storage_location_stddev_pop_fields';
  /** A computed field, executes function "compute_consumable_storage_location_quantity" */
  quantity?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate stddev_samp on columns */
export type IConsumableStorageLocationStddevSampFields = {
  __typename: 'consumable_storage_location_stddev_samp_fields';
  /** A computed field, executes function "compute_consumable_storage_location_quantity" */
  quantity?: Maybe<Scalars['numeric']['output']>;
};

/** Streaming cursor of the table "consumable_storage_location" */
export type IConsumableStorageLocationStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IConsumableStorageLocationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IConsumableStorageLocationStreamCursorValueInput = {
  area?: InputMaybe<Scalars['String']['input']>;
  consumable_id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  place_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type IConsumableStorageLocationSumFields = {
  __typename: 'consumable_storage_location_sum_fields';
  /** A computed field, executes function "compute_consumable_storage_location_quantity" */
  quantity?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "consumable_storage_location" */
export enum IConsumableStorageLocationUpdateColumn {
  /** column name */
  Area = 'area',
  /** column name */
  ConsumableId = 'consumable_id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  PlaceId = 'place_id'
}

export type IConsumableStorageLocationUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IConsumableStorageLocationSetInput>;
  /** filter the rows which have to be updated */
  where: IConsumableStorageLocationBoolExp;
};

/** aggregate var_pop on columns */
export type IConsumableStorageLocationVarPopFields = {
  __typename: 'consumable_storage_location_var_pop_fields';
  /** A computed field, executes function "compute_consumable_storage_location_quantity" */
  quantity?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate var_samp on columns */
export type IConsumableStorageLocationVarSampFields = {
  __typename: 'consumable_storage_location_var_samp_fields';
  /** A computed field, executes function "compute_consumable_storage_location_quantity" */
  quantity?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate variance on columns */
export type IConsumableStorageLocationVarianceFields = {
  __typename: 'consumable_storage_location_variance_fields';
  /** A computed field, executes function "compute_consumable_storage_location_quantity" */
  quantity?: Maybe<Scalars['numeric']['output']>;
};

/** Streaming cursor of the table "consumable" */
export type IConsumableStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IConsumableStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IConsumableStreamCursorValueInput = {
  asset_ids?: InputMaybe<Scalars['jsonb']['input']>;
  avatar_id?: InputMaybe<Scalars['uuid']['input']>;
  contact_ids?: InputMaybe<Scalars['jsonb']['input']>;
  cost?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  group_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  low_stock_since?: InputMaybe<Scalars['timestamptz']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  min_quantity?: InputMaybe<Scalars['numeric']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<Scalars['jsonb']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type IConsumableSumFields = {
  __typename: 'consumable_sum_fields';
  cost?: Maybe<Scalars['numeric']['output']>;
  min_quantity?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_consumable_quantity" */
  quantity?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_consumable_quantity_until" */
  quantity_until?: Maybe<Scalars['numeric']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
};


/** aggregate sum on columns */
export type IConsumableSumFieldsQuantityUntilArgs = {
  args: IQuantityUntilConsumableArgs;
};

/** order by sum() on columns of table "consumable" */
export type IConsumableSumOrderBy = {
  cost?: InputMaybe<IOrderBy>;
  min_quantity?: InputMaybe<IOrderBy>;
  rank?: InputMaybe<IOrderBy>;
};

/** update columns of table "consumable" */
export enum IConsumableUpdateColumn {
  /** column name */
  AssetIds = 'asset_ids',
  /** column name */
  AvatarId = 'avatar_id',
  /** column name */
  ContactIds = 'contact_ids',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Description = 'description',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  LowStockSince = 'low_stock_since',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MinQuantity = 'min_quantity',
  /** column name */
  Name = 'name',
  /** column name */
  Properties = 'properties',
  /** column name */
  PublicId = 'public_id',
  /** column name */
  Rank = 'rank',
  /** column name */
  Unit = 'unit',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type IConsumableUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IConsumableAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IConsumableDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IConsumableDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IConsumableDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IConsumableIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IConsumablePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IConsumableSetInput>;
  /** filter the rows which have to be updated */
  where: IConsumableBoolExp;
};

/** aggregate var_pop on columns */
export type IConsumableVarPopFields = {
  __typename: 'consumable_var_pop_fields';
  cost?: Maybe<Scalars['Float']['output']>;
  min_quantity?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_consumable_quantity" */
  quantity?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_consumable_quantity_until" */
  quantity_until?: Maybe<Scalars['numeric']['output']>;
  rank?: Maybe<Scalars['Float']['output']>;
};


/** aggregate var_pop on columns */
export type IConsumableVarPopFieldsQuantityUntilArgs = {
  args: IQuantityUntilConsumableArgs;
};

/** order by var_pop() on columns of table "consumable" */
export type IConsumableVarPopOrderBy = {
  cost?: InputMaybe<IOrderBy>;
  min_quantity?: InputMaybe<IOrderBy>;
  rank?: InputMaybe<IOrderBy>;
};

/** aggregate var_samp on columns */
export type IConsumableVarSampFields = {
  __typename: 'consumable_var_samp_fields';
  cost?: Maybe<Scalars['Float']['output']>;
  min_quantity?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_consumable_quantity" */
  quantity?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_consumable_quantity_until" */
  quantity_until?: Maybe<Scalars['numeric']['output']>;
  rank?: Maybe<Scalars['Float']['output']>;
};


/** aggregate var_samp on columns */
export type IConsumableVarSampFieldsQuantityUntilArgs = {
  args: IQuantityUntilConsumableArgs;
};

/** order by var_samp() on columns of table "consumable" */
export type IConsumableVarSampOrderBy = {
  cost?: InputMaybe<IOrderBy>;
  min_quantity?: InputMaybe<IOrderBy>;
  rank?: InputMaybe<IOrderBy>;
};

/** aggregate variance on columns */
export type IConsumableVarianceFields = {
  __typename: 'consumable_variance_fields';
  cost?: Maybe<Scalars['Float']['output']>;
  min_quantity?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_consumable_quantity" */
  quantity?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_consumable_quantity_until" */
  quantity_until?: Maybe<Scalars['numeric']['output']>;
  rank?: Maybe<Scalars['Float']['output']>;
};


/** aggregate variance on columns */
export type IConsumableVarianceFieldsQuantityUntilArgs = {
  args: IQuantityUntilConsumableArgs;
};

/** order by variance() on columns of table "consumable" */
export type IConsumableVarianceOrderBy = {
  cost?: InputMaybe<IOrderBy>;
  min_quantity?: InputMaybe<IOrderBy>;
  rank?: InputMaybe<IOrderBy>;
};

/** columns and relationships of "consumable_x_upload" */
export type IConsumableXUpload = {
  __typename: 'consumable_x_upload';
  /** An object relationship */
  consumable?: Maybe<IConsumable>;
  consumable_id: Scalars['uuid']['output'];
  /** An object relationship */
  upload?: Maybe<IUpload>;
  upload_id: Scalars['uuid']['output'];
};

/** aggregated selection of "consumable_x_upload" */
export type IConsumableXUploadAggregate = {
  __typename: 'consumable_x_upload_aggregate';
  aggregate?: Maybe<IConsumableXUploadAggregateFields>;
  nodes: Array<IConsumableXUpload>;
};

export type IConsumableXUploadAggregateBoolExp = {
  count?: InputMaybe<IConsumableXUploadAggregateBoolExpCount>;
};

export type IConsumableXUploadAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IConsumableXUploadSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IConsumableXUploadBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "consumable_x_upload" */
export type IConsumableXUploadAggregateFields = {
  __typename: 'consumable_x_upload_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IConsumableXUploadMaxFields>;
  min?: Maybe<IConsumableXUploadMinFields>;
};


/** aggregate fields of "consumable_x_upload" */
export type IConsumableXUploadAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IConsumableXUploadSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "consumable_x_upload" */
export type IConsumableXUploadAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IConsumableXUploadMaxOrderBy>;
  min?: InputMaybe<IConsumableXUploadMinOrderBy>;
};

/** input type for inserting array relation for remote table "consumable_x_upload" */
export type IConsumableXUploadArrRelInsertInput = {
  data: Array<IConsumableXUploadInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IConsumableXUploadOnConflict>;
};

/** Boolean expression to filter rows from the table "consumable_x_upload". All fields are combined with a logical 'AND'. */
export type IConsumableXUploadBoolExp = {
  _and?: InputMaybe<Array<IConsumableXUploadBoolExp>>;
  _not?: InputMaybe<IConsumableXUploadBoolExp>;
  _or?: InputMaybe<Array<IConsumableXUploadBoolExp>>;
  consumable?: InputMaybe<IConsumableBoolExp>;
  consumable_id?: InputMaybe<IUuidComparisonExp>;
  upload?: InputMaybe<IUploadBoolExp>;
  upload_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "consumable_x_upload" */
export enum IConsumableXUploadConstraint {
  /** unique or primary key constraint on columns "upload_id", "consumable_id" */
  ConsumableXUploadPkey = 'consumable_x_upload_pkey'
}

/** input type for inserting data into table "consumable_x_upload" */
export type IConsumableXUploadInsertInput = {
  consumable?: InputMaybe<IConsumableObjRelInsertInput>;
  consumable_id?: InputMaybe<Scalars['uuid']['input']>;
  upload?: InputMaybe<IUploadObjRelInsertInput>;
  upload_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IConsumableXUploadMaxFields = {
  __typename: 'consumable_x_upload_max_fields';
  consumable_id?: Maybe<Scalars['uuid']['output']>;
  upload_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "consumable_x_upload" */
export type IConsumableXUploadMaxOrderBy = {
  consumable_id?: InputMaybe<IOrderBy>;
  upload_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IConsumableXUploadMinFields = {
  __typename: 'consumable_x_upload_min_fields';
  consumable_id?: Maybe<Scalars['uuid']['output']>;
  upload_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "consumable_x_upload" */
export type IConsumableXUploadMinOrderBy = {
  consumable_id?: InputMaybe<IOrderBy>;
  upload_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "consumable_x_upload" */
export type IConsumableXUploadMutationResponse = {
  __typename: 'consumable_x_upload_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IConsumableXUpload>;
};

/** on_conflict condition type for table "consumable_x_upload" */
export type IConsumableXUploadOnConflict = {
  constraint: IConsumableXUploadConstraint;
  update_columns?: Array<IConsumableXUploadUpdateColumn>;
  where?: InputMaybe<IConsumableXUploadBoolExp>;
};

/** Ordering options when selecting data from "consumable_x_upload". */
export type IConsumableXUploadOrderBy = {
  consumable?: InputMaybe<IConsumableOrderBy>;
  consumable_id?: InputMaybe<IOrderBy>;
  upload?: InputMaybe<IUploadOrderBy>;
  upload_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: consumable_x_upload */
export type IConsumableXUploadPkColumnsInput = {
  consumable_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};

/** select columns of table "consumable_x_upload" */
export enum IConsumableXUploadSelectColumn {
  /** column name */
  ConsumableId = 'consumable_id',
  /** column name */
  UploadId = 'upload_id'
}

/** input type for updating data in table "consumable_x_upload" */
export type IConsumableXUploadSetInput = {
  consumable_id?: InputMaybe<Scalars['uuid']['input']>;
  upload_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "consumable_x_upload" */
export type IConsumableXUploadStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IConsumableXUploadStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IConsumableXUploadStreamCursorValueInput = {
  consumable_id?: InputMaybe<Scalars['uuid']['input']>;
  upload_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "consumable_x_upload" */
export enum IConsumableXUploadUpdateColumn {
  /** column name */
  ConsumableId = 'consumable_id',
  /** column name */
  UploadId = 'upload_id'
}

export type IConsumableXUploadUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IConsumableXUploadSetInput>;
  /** filter the rows which have to be updated */
  where: IConsumableXUploadBoolExp;
};

/** Rolodex of important point of external contacts, like manufacturers or retailers. */
export type IContact = {
  __typename: 'contact';
  address?: Maybe<Scalars['String']['output']>;
  company: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  location_id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "contact" */
export type IContactAggregate = {
  __typename: 'contact_aggregate';
  aggregate?: Maybe<IContactAggregateFields>;
  nodes: Array<IContact>;
};

/** aggregate fields of "contact" */
export type IContactAggregateFields = {
  __typename: 'contact_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IContactMaxFields>;
  min?: Maybe<IContactMinFields>;
};


/** aggregate fields of "contact" */
export type IContactAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IContactSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "contact" */
export type IContactAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IContactMaxOrderBy>;
  min?: InputMaybe<IContactMinOrderBy>;
};

/** Boolean expression to filter rows from the table "contact". All fields are combined with a logical 'AND'. */
export type IContactBoolExp = {
  _and?: InputMaybe<Array<IContactBoolExp>>;
  _not?: InputMaybe<IContactBoolExp>;
  _or?: InputMaybe<Array<IContactBoolExp>>;
  address?: InputMaybe<IStringComparisonExp>;
  company?: InputMaybe<IStringComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  email?: InputMaybe<IStringComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  note?: InputMaybe<IStringComparisonExp>;
  phone?: InputMaybe<IStringComparisonExp>;
  position?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "contact" */
export enum IContactConstraint {
  /** unique or primary key constraint on columns "id" */
  ContactPkey = 'contact_pkey'
}

/** input type for inserting data into table "contact" */
export type IContactInsertInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type IContactMaxFields = {
  __typename: 'contact_max_fields';
  address?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "contact" */
export type IContactMaxOrderBy = {
  address?: InputMaybe<IOrderBy>;
  company?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  email?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  note?: InputMaybe<IOrderBy>;
  phone?: InputMaybe<IOrderBy>;
  position?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IContactMinFields = {
  __typename: 'contact_min_fields';
  address?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "contact" */
export type IContactMinOrderBy = {
  address?: InputMaybe<IOrderBy>;
  company?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  email?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  note?: InputMaybe<IOrderBy>;
  phone?: InputMaybe<IOrderBy>;
  position?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "contact" */
export type IContactMutationResponse = {
  __typename: 'contact_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IContact>;
};

/** on_conflict condition type for table "contact" */
export type IContactOnConflict = {
  constraint: IContactConstraint;
  update_columns?: Array<IContactUpdateColumn>;
  where?: InputMaybe<IContactBoolExp>;
};

/** Ordering options when selecting data from "contact". */
export type IContactOrderBy = {
  address?: InputMaybe<IOrderBy>;
  company?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  email?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  note?: InputMaybe<IOrderBy>;
  phone?: InputMaybe<IOrderBy>;
  position?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: contact */
export type IContactPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "contact" */
export enum IContactSelectColumn {
  /** column name */
  Address = 'address',
  /** column name */
  Company = 'company',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  Phone = 'phone',
  /** column name */
  Position = 'position',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "contact" */
export type IContactSetInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "contact" */
export type IContactStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IContactStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IContactStreamCursorValueInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "contact" */
export enum IContactUpdateColumn {
  /** column name */
  Address = 'address',
  /** column name */
  Company = 'company',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  Phone = 'phone',
  /** column name */
  Position = 'position',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type IContactUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IContactSetInput>;
  /** filter the rows which have to be updated */
  where: IContactBoolExp;
};

/** ordering argument of a cursor */
export enum ICursorOrdering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Table to log custom migrations */
export type ICustomMigration = {
  __typename: 'custom_migration';
  location_id: Scalars['uuid']['output'];
  migrated: Scalars['Boolean']['output'];
  migration_id: Scalars['String']['output'];
  payload?: Maybe<Scalars['jsonb']['output']>;
  success: Scalars['Boolean']['output'];
};


/** Table to log custom migrations */
export type ICustomMigrationPayloadArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "custom_migration" */
export type ICustomMigrationAggregate = {
  __typename: 'custom_migration_aggregate';
  aggregate?: Maybe<ICustomMigrationAggregateFields>;
  nodes: Array<ICustomMigration>;
};

/** aggregate fields of "custom_migration" */
export type ICustomMigrationAggregateFields = {
  __typename: 'custom_migration_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ICustomMigrationMaxFields>;
  min?: Maybe<ICustomMigrationMinFields>;
};


/** aggregate fields of "custom_migration" */
export type ICustomMigrationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ICustomMigrationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ICustomMigrationAppendInput = {
  payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "custom_migration". All fields are combined with a logical 'AND'. */
export type ICustomMigrationBoolExp = {
  _and?: InputMaybe<Array<ICustomMigrationBoolExp>>;
  _not?: InputMaybe<ICustomMigrationBoolExp>;
  _or?: InputMaybe<Array<ICustomMigrationBoolExp>>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  migrated?: InputMaybe<IBooleanComparisonExp>;
  migration_id?: InputMaybe<IStringComparisonExp>;
  payload?: InputMaybe<IJsonbComparisonExp>;
  success?: InputMaybe<IBooleanComparisonExp>;
};

/** unique or primary key constraints on table "custom_migration" */
export enum ICustomMigrationConstraint {
  /** unique or primary key constraint on columns "migration_id", "location_id" */
  CustomMigrationPkey = 'custom_migration_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ICustomMigrationDeleteAtPathInput = {
  payload?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ICustomMigrationDeleteElemInput = {
  payload?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ICustomMigrationDeleteKeyInput = {
  payload?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "custom_migration" */
export type ICustomMigrationInsertInput = {
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  migrated?: InputMaybe<Scalars['Boolean']['input']>;
  migration_id?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  success?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type ICustomMigrationMaxFields = {
  __typename: 'custom_migration_max_fields';
  location_id?: Maybe<Scalars['uuid']['output']>;
  migration_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ICustomMigrationMinFields = {
  __typename: 'custom_migration_min_fields';
  location_id?: Maybe<Scalars['uuid']['output']>;
  migration_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "custom_migration" */
export type ICustomMigrationMutationResponse = {
  __typename: 'custom_migration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ICustomMigration>;
};

/** on_conflict condition type for table "custom_migration" */
export type ICustomMigrationOnConflict = {
  constraint: ICustomMigrationConstraint;
  update_columns?: Array<ICustomMigrationUpdateColumn>;
  where?: InputMaybe<ICustomMigrationBoolExp>;
};

/** Ordering options when selecting data from "custom_migration". */
export type ICustomMigrationOrderBy = {
  location_id?: InputMaybe<IOrderBy>;
  migrated?: InputMaybe<IOrderBy>;
  migration_id?: InputMaybe<IOrderBy>;
  payload?: InputMaybe<IOrderBy>;
  success?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: custom_migration */
export type ICustomMigrationPkColumnsInput = {
  location_id: Scalars['uuid']['input'];
  migration_id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ICustomMigrationPrependInput = {
  payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "custom_migration" */
export enum ICustomMigrationSelectColumn {
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Migrated = 'migrated',
  /** column name */
  MigrationId = 'migration_id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Success = 'success'
}

/** input type for updating data in table "custom_migration" */
export type ICustomMigrationSetInput = {
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  migrated?: InputMaybe<Scalars['Boolean']['input']>;
  migration_id?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  success?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Streaming cursor of the table "custom_migration" */
export type ICustomMigrationStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ICustomMigrationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ICustomMigrationStreamCursorValueInput = {
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  migrated?: InputMaybe<Scalars['Boolean']['input']>;
  migration_id?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  success?: InputMaybe<Scalars['Boolean']['input']>;
};

/** update columns of table "custom_migration" */
export enum ICustomMigrationUpdateColumn {
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Migrated = 'migrated',
  /** column name */
  MigrationId = 'migration_id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Success = 'success'
}

export type ICustomMigrationUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ICustomMigrationAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ICustomMigrationDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ICustomMigrationDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ICustomMigrationDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ICustomMigrationPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ICustomMigrationSetInput>;
  /** filter the rows which have to be updated */
  where: ICustomMigrationBoolExp;
};

/** columns and relationships of "custom_view" */
export type ICustomView = {
  __typename: 'custom_view';
  config: Scalars['json']['output'];
  created_at: Scalars['timestamptz']['output'];
  created_by_id: Scalars['uuid']['output'];
  data_type: IViewDataTypeEnum;
  description?: Maybe<Scalars['String']['output']>;
  filters: Scalars['json']['output'];
  icon?: Maybe<Scalars['jsonb']['output']>;
  id: Scalars['uuid']['output'];
  location_id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  shared: Scalars['Boolean']['output'];
  shared_team_id?: Maybe<Scalars['uuid']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  version: Scalars['Int']['output'];
};


/** columns and relationships of "custom_view" */
export type ICustomViewConfigArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "custom_view" */
export type ICustomViewFiltersArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "custom_view" */
export type ICustomViewIconArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "custom_view" */
export type ICustomViewAggregate = {
  __typename: 'custom_view_aggregate';
  aggregate?: Maybe<ICustomViewAggregateFields>;
  nodes: Array<ICustomView>;
};

/** aggregate fields of "custom_view" */
export type ICustomViewAggregateFields = {
  __typename: 'custom_view_aggregate_fields';
  avg?: Maybe<ICustomViewAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ICustomViewMaxFields>;
  min?: Maybe<ICustomViewMinFields>;
  stddev?: Maybe<ICustomViewStddevFields>;
  stddev_pop?: Maybe<ICustomViewStddevPopFields>;
  stddev_samp?: Maybe<ICustomViewStddevSampFields>;
  sum?: Maybe<ICustomViewSumFields>;
  var_pop?: Maybe<ICustomViewVarPopFields>;
  var_samp?: Maybe<ICustomViewVarSampFields>;
  variance?: Maybe<ICustomViewVarianceFields>;
};


/** aggregate fields of "custom_view" */
export type ICustomViewAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ICustomViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ICustomViewAppendInput = {
  icon?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type ICustomViewAvgFields = {
  __typename: 'custom_view_avg_fields';
  version?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "custom_view". All fields are combined with a logical 'AND'. */
export type ICustomViewBoolExp = {
  _and?: InputMaybe<Array<ICustomViewBoolExp>>;
  _not?: InputMaybe<ICustomViewBoolExp>;
  _or?: InputMaybe<Array<ICustomViewBoolExp>>;
  config?: InputMaybe<IJsonComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  created_by_id?: InputMaybe<IUuidComparisonExp>;
  data_type?: InputMaybe<IViewDataTypeEnumComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  filters?: InputMaybe<IJsonComparisonExp>;
  icon?: InputMaybe<IJsonbComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  shared?: InputMaybe<IBooleanComparisonExp>;
  shared_team_id?: InputMaybe<IUuidComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  version?: InputMaybe<IIntComparisonExp>;
};

/** unique or primary key constraints on table "custom_view" */
export enum ICustomViewConstraint {
  /** unique or primary key constraint on columns "id" */
  CustomViewPkey = 'custom_view_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ICustomViewDeleteAtPathInput = {
  icon?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ICustomViewDeleteElemInput = {
  icon?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ICustomViewDeleteKeyInput = {
  icon?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "custom_view" */
export type ICustomViewIncInput = {
  version?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "custom_view" */
export type ICustomViewInsertInput = {
  config?: InputMaybe<Scalars['json']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  data_type?: InputMaybe<IViewDataTypeEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['json']['input']>;
  icon?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shared?: InputMaybe<Scalars['Boolean']['input']>;
  shared_team_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type ICustomViewMaxFields = {
  __typename: 'custom_view_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  shared_team_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type ICustomViewMinFields = {
  __typename: 'custom_view_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  shared_team_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "custom_view" */
export type ICustomViewMutationResponse = {
  __typename: 'custom_view_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ICustomView>;
};

/** input type for inserting object relation for remote table "custom_view" */
export type ICustomViewObjRelInsertInput = {
  data: ICustomViewInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ICustomViewOnConflict>;
};

/** on_conflict condition type for table "custom_view" */
export type ICustomViewOnConflict = {
  constraint: ICustomViewConstraint;
  update_columns?: Array<ICustomViewUpdateColumn>;
  where?: InputMaybe<ICustomViewBoolExp>;
};

/** Ordering options when selecting data from "custom_view". */
export type ICustomViewOrderBy = {
  config?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  data_type?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  filters?: InputMaybe<IOrderBy>;
  icon?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  shared?: InputMaybe<IOrderBy>;
  shared_team_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  version?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: custom_view */
export type ICustomViewPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ICustomViewPrependInput = {
  icon?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "custom_view" */
export enum ICustomViewSelectColumn {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DataType = 'data_type',
  /** column name */
  Description = 'description',
  /** column name */
  Filters = 'filters',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  Shared = 'shared',
  /** column name */
  SharedTeamId = 'shared_team_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "custom_view" */
export type ICustomViewSetInput = {
  config?: InputMaybe<Scalars['json']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  data_type?: InputMaybe<IViewDataTypeEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['json']['input']>;
  icon?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shared?: InputMaybe<Scalars['Boolean']['input']>;
  shared_team_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type ICustomViewStddevFields = {
  __typename: 'custom_view_stddev_fields';
  version?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type ICustomViewStddevPopFields = {
  __typename: 'custom_view_stddev_pop_fields';
  version?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type ICustomViewStddevSampFields = {
  __typename: 'custom_view_stddev_samp_fields';
  version?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "custom_view" */
export type ICustomViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ICustomViewStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ICustomViewStreamCursorValueInput = {
  config?: InputMaybe<Scalars['json']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  data_type?: InputMaybe<IViewDataTypeEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['json']['input']>;
  icon?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shared?: InputMaybe<Scalars['Boolean']['input']>;
  shared_team_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type ICustomViewSumFields = {
  __typename: 'custom_view_sum_fields';
  version?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "custom_view" */
export enum ICustomViewUpdateColumn {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DataType = 'data_type',
  /** column name */
  Description = 'description',
  /** column name */
  Filters = 'filters',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  Shared = 'shared',
  /** column name */
  SharedTeamId = 'shared_team_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version'
}

export type ICustomViewUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ICustomViewAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ICustomViewDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ICustomViewDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ICustomViewDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ICustomViewIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ICustomViewPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ICustomViewSetInput>;
  /** filter the rows which have to be updated */
  where: ICustomViewBoolExp;
};

/** columns and relationships of "custom_view_user_favorite" */
export type ICustomViewUserFavorite = {
  __typename: 'custom_view_user_favorite';
  /** An object relationship */
  custom_view: ICustomView;
  custom_view_id: Scalars['uuid']['output'];
  is_favored?: Maybe<Scalars['Boolean']['output']>;
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "custom_view_user_favorite" */
export type ICustomViewUserFavoriteAggregate = {
  __typename: 'custom_view_user_favorite_aggregate';
  aggregate?: Maybe<ICustomViewUserFavoriteAggregateFields>;
  nodes: Array<ICustomViewUserFavorite>;
};

/** aggregate fields of "custom_view_user_favorite" */
export type ICustomViewUserFavoriteAggregateFields = {
  __typename: 'custom_view_user_favorite_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ICustomViewUserFavoriteMaxFields>;
  min?: Maybe<ICustomViewUserFavoriteMinFields>;
};


/** aggregate fields of "custom_view_user_favorite" */
export type ICustomViewUserFavoriteAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ICustomViewUserFavoriteSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "custom_view_user_favorite". All fields are combined with a logical 'AND'. */
export type ICustomViewUserFavoriteBoolExp = {
  _and?: InputMaybe<Array<ICustomViewUserFavoriteBoolExp>>;
  _not?: InputMaybe<ICustomViewUserFavoriteBoolExp>;
  _or?: InputMaybe<Array<ICustomViewUserFavoriteBoolExp>>;
  custom_view?: InputMaybe<ICustomViewBoolExp>;
  custom_view_id?: InputMaybe<IUuidComparisonExp>;
  is_favored?: InputMaybe<IBooleanComparisonExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "custom_view_user_favorite" */
export enum ICustomViewUserFavoriteConstraint {
  /** unique or primary key constraint on columns "custom_view_id", "user_id" */
  CustomViewUserFavoritePkey = 'custom_view_user_favorite_pkey'
}

/** input type for inserting data into table "custom_view_user_favorite" */
export type ICustomViewUserFavoriteInsertInput = {
  custom_view?: InputMaybe<ICustomViewObjRelInsertInput>;
  custom_view_id?: InputMaybe<Scalars['uuid']['input']>;
  is_favored?: InputMaybe<Scalars['Boolean']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type ICustomViewUserFavoriteMaxFields = {
  __typename: 'custom_view_user_favorite_max_fields';
  custom_view_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type ICustomViewUserFavoriteMinFields = {
  __typename: 'custom_view_user_favorite_min_fields';
  custom_view_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "custom_view_user_favorite" */
export type ICustomViewUserFavoriteMutationResponse = {
  __typename: 'custom_view_user_favorite_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ICustomViewUserFavorite>;
};

/** on_conflict condition type for table "custom_view_user_favorite" */
export type ICustomViewUserFavoriteOnConflict = {
  constraint: ICustomViewUserFavoriteConstraint;
  update_columns?: Array<ICustomViewUserFavoriteUpdateColumn>;
  where?: InputMaybe<ICustomViewUserFavoriteBoolExp>;
};

/** Ordering options when selecting data from "custom_view_user_favorite". */
export type ICustomViewUserFavoriteOrderBy = {
  custom_view?: InputMaybe<ICustomViewOrderBy>;
  custom_view_id?: InputMaybe<IOrderBy>;
  is_favored?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: custom_view_user_favorite */
export type ICustomViewUserFavoritePkColumnsInput = {
  custom_view_id: Scalars['uuid']['input'];
  user_id: Scalars['uuid']['input'];
};

/** select columns of table "custom_view_user_favorite" */
export enum ICustomViewUserFavoriteSelectColumn {
  /** column name */
  CustomViewId = 'custom_view_id',
  /** column name */
  IsFavored = 'is_favored',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "custom_view_user_favorite" */
export type ICustomViewUserFavoriteSetInput = {
  custom_view_id?: InputMaybe<Scalars['uuid']['input']>;
  is_favored?: InputMaybe<Scalars['Boolean']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "custom_view_user_favorite" */
export type ICustomViewUserFavoriteStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ICustomViewUserFavoriteStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ICustomViewUserFavoriteStreamCursorValueInput = {
  custom_view_id?: InputMaybe<Scalars['uuid']['input']>;
  is_favored?: InputMaybe<Scalars['Boolean']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "custom_view_user_favorite" */
export enum ICustomViewUserFavoriteUpdateColumn {
  /** column name */
  CustomViewId = 'custom_view_id',
  /** column name */
  IsFavored = 'is_favored',
  /** column name */
  UserId = 'user_id'
}

export type ICustomViewUserFavoriteUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ICustomViewUserFavoriteSetInput>;
  /** filter the rows which have to be updated */
  where: ICustomViewUserFavoriteBoolExp;
};

/** aggregate var_pop on columns */
export type ICustomViewVarPopFields = {
  __typename: 'custom_view_var_pop_fields';
  version?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type ICustomViewVarSampFields = {
  __typename: 'custom_view_var_samp_fields';
  version?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ICustomViewVarianceFields = {
  __typename: 'custom_view_variance_fields';
  version?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "customer_feedback" */
export type ICustomerFeedback = {
  __typename: 'customer_feedback';
  id: Scalars['Int']['output'];
  submitted_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  submitted_by: IUser;
  submitted_by_id: Scalars['uuid']['output'];
  text: Scalars['String']['output'];
  upload_ids?: Maybe<Scalars['jsonb']['output']>;
};


/** columns and relationships of "customer_feedback" */
export type ICustomerFeedbackUploadIdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "customer_feedback" */
export type ICustomerFeedbackAggregate = {
  __typename: 'customer_feedback_aggregate';
  aggregate?: Maybe<ICustomerFeedbackAggregateFields>;
  nodes: Array<ICustomerFeedback>;
};

/** aggregate fields of "customer_feedback" */
export type ICustomerFeedbackAggregateFields = {
  __typename: 'customer_feedback_aggregate_fields';
  avg?: Maybe<ICustomerFeedbackAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ICustomerFeedbackMaxFields>;
  min?: Maybe<ICustomerFeedbackMinFields>;
  stddev?: Maybe<ICustomerFeedbackStddevFields>;
  stddev_pop?: Maybe<ICustomerFeedbackStddevPopFields>;
  stddev_samp?: Maybe<ICustomerFeedbackStddevSampFields>;
  sum?: Maybe<ICustomerFeedbackSumFields>;
  var_pop?: Maybe<ICustomerFeedbackVarPopFields>;
  var_samp?: Maybe<ICustomerFeedbackVarSampFields>;
  variance?: Maybe<ICustomerFeedbackVarianceFields>;
};


/** aggregate fields of "customer_feedback" */
export type ICustomerFeedbackAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ICustomerFeedbackSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ICustomerFeedbackAppendInput = {
  upload_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type ICustomerFeedbackAvgFields = {
  __typename: 'customer_feedback_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "customer_feedback". All fields are combined with a logical 'AND'. */
export type ICustomerFeedbackBoolExp = {
  _and?: InputMaybe<Array<ICustomerFeedbackBoolExp>>;
  _not?: InputMaybe<ICustomerFeedbackBoolExp>;
  _or?: InputMaybe<Array<ICustomerFeedbackBoolExp>>;
  id?: InputMaybe<IIntComparisonExp>;
  submitted_at?: InputMaybe<ITimestamptzComparisonExp>;
  submitted_by?: InputMaybe<IUserBoolExp>;
  submitted_by_id?: InputMaybe<IUuidComparisonExp>;
  text?: InputMaybe<IStringComparisonExp>;
  upload_ids?: InputMaybe<IJsonbComparisonExp>;
};

/** unique or primary key constraints on table "customer_feedback" */
export enum ICustomerFeedbackConstraint {
  /** unique or primary key constraint on columns "id" */
  CustomerFeedbackPkey = 'customer_feedback_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ICustomerFeedbackDeleteAtPathInput = {
  upload_ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ICustomerFeedbackDeleteElemInput = {
  upload_ids?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ICustomerFeedbackDeleteKeyInput = {
  upload_ids?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "customer_feedback" */
export type ICustomerFeedbackIncInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "customer_feedback" */
export type ICustomerFeedbackInsertInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  submitted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  submitted_by?: InputMaybe<IUserObjRelInsertInput>;
  submitted_by_id?: InputMaybe<Scalars['uuid']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  upload_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type ICustomerFeedbackMaxFields = {
  __typename: 'customer_feedback_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  submitted_at?: Maybe<Scalars['timestamptz']['output']>;
  submitted_by_id?: Maybe<Scalars['uuid']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ICustomerFeedbackMinFields = {
  __typename: 'customer_feedback_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  submitted_at?: Maybe<Scalars['timestamptz']['output']>;
  submitted_by_id?: Maybe<Scalars['uuid']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "customer_feedback" */
export type ICustomerFeedbackMutationResponse = {
  __typename: 'customer_feedback_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ICustomerFeedback>;
};

/** on_conflict condition type for table "customer_feedback" */
export type ICustomerFeedbackOnConflict = {
  constraint: ICustomerFeedbackConstraint;
  update_columns?: Array<ICustomerFeedbackUpdateColumn>;
  where?: InputMaybe<ICustomerFeedbackBoolExp>;
};

/** Ordering options when selecting data from "customer_feedback". */
export type ICustomerFeedbackOrderBy = {
  id?: InputMaybe<IOrderBy>;
  submitted_at?: InputMaybe<IOrderBy>;
  submitted_by?: InputMaybe<IUserOrderBy>;
  submitted_by_id?: InputMaybe<IOrderBy>;
  text?: InputMaybe<IOrderBy>;
  upload_ids?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: customer_feedback */
export type ICustomerFeedbackPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ICustomerFeedbackPrependInput = {
  upload_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "customer_feedback" */
export enum ICustomerFeedbackSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  SubmittedAt = 'submitted_at',
  /** column name */
  SubmittedById = 'submitted_by_id',
  /** column name */
  Text = 'text',
  /** column name */
  UploadIds = 'upload_ids'
}

/** input type for updating data in table "customer_feedback" */
export type ICustomerFeedbackSetInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  submitted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  submitted_by_id?: InputMaybe<Scalars['uuid']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  upload_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate stddev on columns */
export type ICustomerFeedbackStddevFields = {
  __typename: 'customer_feedback_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type ICustomerFeedbackStddevPopFields = {
  __typename: 'customer_feedback_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type ICustomerFeedbackStddevSampFields = {
  __typename: 'customer_feedback_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "customer_feedback" */
export type ICustomerFeedbackStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ICustomerFeedbackStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ICustomerFeedbackStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  submitted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  submitted_by_id?: InputMaybe<Scalars['uuid']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  upload_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate sum on columns */
export type ICustomerFeedbackSumFields = {
  __typename: 'customer_feedback_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "customer_feedback" */
export enum ICustomerFeedbackUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  SubmittedAt = 'submitted_at',
  /** column name */
  SubmittedById = 'submitted_by_id',
  /** column name */
  Text = 'text',
  /** column name */
  UploadIds = 'upload_ids'
}

export type ICustomerFeedbackUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ICustomerFeedbackAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ICustomerFeedbackDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ICustomerFeedbackDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ICustomerFeedbackDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ICustomerFeedbackIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ICustomerFeedbackPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ICustomerFeedbackSetInput>;
  /** filter the rows which have to be updated */
  where: ICustomerFeedbackBoolExp;
};

/** aggregate var_pop on columns */
export type ICustomerFeedbackVarPopFields = {
  __typename: 'customer_feedback_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type ICustomerFeedbackVarSampFields = {
  __typename: 'customer_feedback_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ICustomerFeedbackVarianceFields = {
  __typename: 'customer_feedback_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type IDateComparisonExp = {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
};

/** columns and relationships of "feature_flag" */
export type IFeatureFlag = {
  __typename: 'feature_flag';
  created_at: Scalars['timestamptz']['output'];
  default_enabled: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "feature_flag" */
export type IFeatureFlagAggregate = {
  __typename: 'feature_flag_aggregate';
  aggregate?: Maybe<IFeatureFlagAggregateFields>;
  nodes: Array<IFeatureFlag>;
};

/** aggregate fields of "feature_flag" */
export type IFeatureFlagAggregateFields = {
  __typename: 'feature_flag_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IFeatureFlagMaxFields>;
  min?: Maybe<IFeatureFlagMinFields>;
};


/** aggregate fields of "feature_flag" */
export type IFeatureFlagAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IFeatureFlagSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "feature_flag". All fields are combined with a logical 'AND'. */
export type IFeatureFlagBoolExp = {
  _and?: InputMaybe<Array<IFeatureFlagBoolExp>>;
  _not?: InputMaybe<IFeatureFlagBoolExp>;
  _or?: InputMaybe<Array<IFeatureFlagBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  default_enabled?: InputMaybe<IBooleanComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  id?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "feature_flag" */
export enum IFeatureFlagConstraint {
  /** unique or primary key constraint on columns "id" */
  FeatureFlagPkey = 'feature_flag_pkey'
}

/** input type for inserting data into table "feature_flag" */
export type IFeatureFlagInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  default_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type IFeatureFlagMaxFields = {
  __typename: 'feature_flag_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type IFeatureFlagMinFields = {
  __typename: 'feature_flag_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "feature_flag" */
export type IFeatureFlagMutationResponse = {
  __typename: 'feature_flag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IFeatureFlag>;
};

/** on_conflict condition type for table "feature_flag" */
export type IFeatureFlagOnConflict = {
  constraint: IFeatureFlagConstraint;
  update_columns?: Array<IFeatureFlagUpdateColumn>;
  where?: InputMaybe<IFeatureFlagBoolExp>;
};

/** Ordering options when selecting data from "feature_flag". */
export type IFeatureFlagOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  default_enabled?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: feature_flag */
export type IFeatureFlagPkColumnsInput = {
  id: Scalars['String']['input'];
};

/** select columns of table "feature_flag" */
export enum IFeatureFlagSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultEnabled = 'default_enabled',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "feature_flag" */
export type IFeatureFlagSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  default_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "feature_flag" */
export type IFeatureFlagStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IFeatureFlagStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IFeatureFlagStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  default_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "feature_flag" */
export enum IFeatureFlagUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultEnabled = 'default_enabled',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type IFeatureFlagUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IFeatureFlagSetInput>;
  /** filter the rows which have to be updated */
  where: IFeatureFlagBoolExp;
};

/** columns and relationships of "file_folder" */
export type IFileFolder = {
  __typename: 'file_folder';
  created_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  files: Array<IFileFolderXUpload>;
  /** An aggregate relationship */
  files_aggregate: IFileFolderXUploadAggregate;
  id: Scalars['uuid']['output'];
  location_id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  parent_folder?: Maybe<IFileFolder>;
  parent_folder_id?: Maybe<Scalars['uuid']['output']>;
  root_folder_id: Scalars['uuid']['output'];
  /** An array relationship */
  sub_folders: Array<IFileFolder>;
  /** An aggregate relationship */
  sub_folders_aggregate: IFileFolderAggregate;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "file_folder" */
export type IFileFolderFilesArgs = {
  distinct_on?: InputMaybe<Array<IFileFolderXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IFileFolderXUploadOrderBy>>;
  where?: InputMaybe<IFileFolderXUploadBoolExp>;
};


/** columns and relationships of "file_folder" */
export type IFileFolderFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<IFileFolderXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IFileFolderXUploadOrderBy>>;
  where?: InputMaybe<IFileFolderXUploadBoolExp>;
};


/** columns and relationships of "file_folder" */
export type IFileFolderSubFoldersArgs = {
  distinct_on?: InputMaybe<Array<IFileFolderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IFileFolderOrderBy>>;
  where?: InputMaybe<IFileFolderBoolExp>;
};


/** columns and relationships of "file_folder" */
export type IFileFolderSubFoldersAggregateArgs = {
  distinct_on?: InputMaybe<Array<IFileFolderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IFileFolderOrderBy>>;
  where?: InputMaybe<IFileFolderBoolExp>;
};

/** aggregated selection of "file_folder" */
export type IFileFolderAggregate = {
  __typename: 'file_folder_aggregate';
  aggregate?: Maybe<IFileFolderAggregateFields>;
  nodes: Array<IFileFolder>;
};

export type IFileFolderAggregateBoolExp = {
  count?: InputMaybe<IFileFolderAggregateBoolExpCount>;
};

export type IFileFolderAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IFileFolderSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IFileFolderBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "file_folder" */
export type IFileFolderAggregateFields = {
  __typename: 'file_folder_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IFileFolderMaxFields>;
  min?: Maybe<IFileFolderMinFields>;
};


/** aggregate fields of "file_folder" */
export type IFileFolderAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IFileFolderSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "file_folder" */
export type IFileFolderAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IFileFolderMaxOrderBy>;
  min?: InputMaybe<IFileFolderMinOrderBy>;
};

/** input type for inserting array relation for remote table "file_folder" */
export type IFileFolderArrRelInsertInput = {
  data: Array<IFileFolderInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IFileFolderOnConflict>;
};

/** Boolean expression to filter rows from the table "file_folder". All fields are combined with a logical 'AND'. */
export type IFileFolderBoolExp = {
  _and?: InputMaybe<Array<IFileFolderBoolExp>>;
  _not?: InputMaybe<IFileFolderBoolExp>;
  _or?: InputMaybe<Array<IFileFolderBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  files?: InputMaybe<IFileFolderXUploadBoolExp>;
  files_aggregate?: InputMaybe<IFileFolderXUploadAggregateBoolExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  parent_folder?: InputMaybe<IFileFolderBoolExp>;
  parent_folder_id?: InputMaybe<IUuidComparisonExp>;
  root_folder_id?: InputMaybe<IUuidComparisonExp>;
  sub_folders?: InputMaybe<IFileFolderBoolExp>;
  sub_folders_aggregate?: InputMaybe<IFileFolderAggregateBoolExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "file_folder" */
export enum IFileFolderConstraint {
  /** unique or primary key constraint on columns "id" */
  FileFolderPkey = 'file_folder_pkey'
}

/** input type for inserting data into table "file_folder" */
export type IFileFolderInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  files?: InputMaybe<IFileFolderXUploadArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent_folder?: InputMaybe<IFileFolderObjRelInsertInput>;
  parent_folder_id?: InputMaybe<Scalars['uuid']['input']>;
  root_folder_id?: InputMaybe<Scalars['uuid']['input']>;
  sub_folders?: InputMaybe<IFileFolderArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type IFileFolderMaxFields = {
  __typename: 'file_folder_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent_folder_id?: Maybe<Scalars['uuid']['output']>;
  root_folder_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "file_folder" */
export type IFileFolderMaxOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  parent_folder_id?: InputMaybe<IOrderBy>;
  root_folder_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IFileFolderMinFields = {
  __typename: 'file_folder_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent_folder_id?: Maybe<Scalars['uuid']['output']>;
  root_folder_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "file_folder" */
export type IFileFolderMinOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  parent_folder_id?: InputMaybe<IOrderBy>;
  root_folder_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "file_folder" */
export type IFileFolderMutationResponse = {
  __typename: 'file_folder_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IFileFolder>;
};

/** input type for inserting object relation for remote table "file_folder" */
export type IFileFolderObjRelInsertInput = {
  data: IFileFolderInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IFileFolderOnConflict>;
};

/** on_conflict condition type for table "file_folder" */
export type IFileFolderOnConflict = {
  constraint: IFileFolderConstraint;
  update_columns?: Array<IFileFolderUpdateColumn>;
  where?: InputMaybe<IFileFolderBoolExp>;
};

/** Ordering options when selecting data from "file_folder". */
export type IFileFolderOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  files_aggregate?: InputMaybe<IFileFolderXUploadAggregateOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  parent_folder?: InputMaybe<IFileFolderOrderBy>;
  parent_folder_id?: InputMaybe<IOrderBy>;
  root_folder_id?: InputMaybe<IOrderBy>;
  sub_folders_aggregate?: InputMaybe<IFileFolderAggregateOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: file_folder */
export type IFileFolderPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "file_folder" */
export enum IFileFolderSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentFolderId = 'parent_folder_id',
  /** column name */
  RootFolderId = 'root_folder_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "file_folder" */
export type IFileFolderSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent_folder_id?: InputMaybe<Scalars['uuid']['input']>;
  root_folder_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "file_folder" */
export type IFileFolderStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IFileFolderStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IFileFolderStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent_folder_id?: InputMaybe<Scalars['uuid']['input']>;
  root_folder_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "file_folder" */
export enum IFileFolderUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentFolderId = 'parent_folder_id',
  /** column name */
  RootFolderId = 'root_folder_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type IFileFolderUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IFileFolderSetInput>;
  /** filter the rows which have to be updated */
  where: IFileFolderBoolExp;
};

/** columns and relationships of "file_folder_x_upload" */
export type IFileFolderXUpload = {
  __typename: 'file_folder_x_upload';
  /** An object relationship */
  file: IUpload;
  /** An object relationship */
  file_folder: IFileFolder;
  file_folder_id: Scalars['uuid']['output'];
  root_folder_id: Scalars['uuid']['output'];
  upload_id: Scalars['uuid']['output'];
};

/** aggregated selection of "file_folder_x_upload" */
export type IFileFolderXUploadAggregate = {
  __typename: 'file_folder_x_upload_aggregate';
  aggregate?: Maybe<IFileFolderXUploadAggregateFields>;
  nodes: Array<IFileFolderXUpload>;
};

export type IFileFolderXUploadAggregateBoolExp = {
  count?: InputMaybe<IFileFolderXUploadAggregateBoolExpCount>;
};

export type IFileFolderXUploadAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IFileFolderXUploadSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IFileFolderXUploadBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "file_folder_x_upload" */
export type IFileFolderXUploadAggregateFields = {
  __typename: 'file_folder_x_upload_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IFileFolderXUploadMaxFields>;
  min?: Maybe<IFileFolderXUploadMinFields>;
};


/** aggregate fields of "file_folder_x_upload" */
export type IFileFolderXUploadAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IFileFolderXUploadSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "file_folder_x_upload" */
export type IFileFolderXUploadAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IFileFolderXUploadMaxOrderBy>;
  min?: InputMaybe<IFileFolderXUploadMinOrderBy>;
};

/** input type for inserting array relation for remote table "file_folder_x_upload" */
export type IFileFolderXUploadArrRelInsertInput = {
  data: Array<IFileFolderXUploadInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IFileFolderXUploadOnConflict>;
};

/** Boolean expression to filter rows from the table "file_folder_x_upload". All fields are combined with a logical 'AND'. */
export type IFileFolderXUploadBoolExp = {
  _and?: InputMaybe<Array<IFileFolderXUploadBoolExp>>;
  _not?: InputMaybe<IFileFolderXUploadBoolExp>;
  _or?: InputMaybe<Array<IFileFolderXUploadBoolExp>>;
  file?: InputMaybe<IUploadBoolExp>;
  file_folder?: InputMaybe<IFileFolderBoolExp>;
  file_folder_id?: InputMaybe<IUuidComparisonExp>;
  root_folder_id?: InputMaybe<IUuidComparisonExp>;
  upload_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "file_folder_x_upload" */
export enum IFileFolderXUploadConstraint {
  /** unique or primary key constraint on columns "file_folder_id", "upload_id" */
  FileFolderXUploadPkey = 'file_folder_x_upload_pkey'
}

/** input type for inserting data into table "file_folder_x_upload" */
export type IFileFolderXUploadInsertInput = {
  file?: InputMaybe<IUploadObjRelInsertInput>;
  file_folder?: InputMaybe<IFileFolderObjRelInsertInput>;
  file_folder_id?: InputMaybe<Scalars['uuid']['input']>;
  root_folder_id?: InputMaybe<Scalars['uuid']['input']>;
  upload_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IFileFolderXUploadMaxFields = {
  __typename: 'file_folder_x_upload_max_fields';
  file_folder_id?: Maybe<Scalars['uuid']['output']>;
  root_folder_id?: Maybe<Scalars['uuid']['output']>;
  upload_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "file_folder_x_upload" */
export type IFileFolderXUploadMaxOrderBy = {
  file_folder_id?: InputMaybe<IOrderBy>;
  root_folder_id?: InputMaybe<IOrderBy>;
  upload_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IFileFolderXUploadMinFields = {
  __typename: 'file_folder_x_upload_min_fields';
  file_folder_id?: Maybe<Scalars['uuid']['output']>;
  root_folder_id?: Maybe<Scalars['uuid']['output']>;
  upload_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "file_folder_x_upload" */
export type IFileFolderXUploadMinOrderBy = {
  file_folder_id?: InputMaybe<IOrderBy>;
  root_folder_id?: InputMaybe<IOrderBy>;
  upload_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "file_folder_x_upload" */
export type IFileFolderXUploadMutationResponse = {
  __typename: 'file_folder_x_upload_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IFileFolderXUpload>;
};

/** on_conflict condition type for table "file_folder_x_upload" */
export type IFileFolderXUploadOnConflict = {
  constraint: IFileFolderXUploadConstraint;
  update_columns?: Array<IFileFolderXUploadUpdateColumn>;
  where?: InputMaybe<IFileFolderXUploadBoolExp>;
};

/** Ordering options when selecting data from "file_folder_x_upload". */
export type IFileFolderXUploadOrderBy = {
  file?: InputMaybe<IUploadOrderBy>;
  file_folder?: InputMaybe<IFileFolderOrderBy>;
  file_folder_id?: InputMaybe<IOrderBy>;
  root_folder_id?: InputMaybe<IOrderBy>;
  upload_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: file_folder_x_upload */
export type IFileFolderXUploadPkColumnsInput = {
  file_folder_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};

/** select columns of table "file_folder_x_upload" */
export enum IFileFolderXUploadSelectColumn {
  /** column name */
  FileFolderId = 'file_folder_id',
  /** column name */
  RootFolderId = 'root_folder_id',
  /** column name */
  UploadId = 'upload_id'
}

/** input type for updating data in table "file_folder_x_upload" */
export type IFileFolderXUploadSetInput = {
  file_folder_id?: InputMaybe<Scalars['uuid']['input']>;
  root_folder_id?: InputMaybe<Scalars['uuid']['input']>;
  upload_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "file_folder_x_upload" */
export type IFileFolderXUploadStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IFileFolderXUploadStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IFileFolderXUploadStreamCursorValueInput = {
  file_folder_id?: InputMaybe<Scalars['uuid']['input']>;
  root_folder_id?: InputMaybe<Scalars['uuid']['input']>;
  upload_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "file_folder_x_upload" */
export enum IFileFolderXUploadUpdateColumn {
  /** column name */
  FileFolderId = 'file_folder_id',
  /** column name */
  RootFolderId = 'root_folder_id',
  /** column name */
  UploadId = 'upload_id'
}

export type IFileFolderXUploadUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IFileFolderXUploadSetInput>;
  /** filter the rows which have to be updated */
  where: IFileFolderXUploadBoolExp;
};

/** columns and relationships of "inspection_result" */
export type IInspectionResult = {
  __typename: 'inspection_result';
  comment: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "inspection_result" */
export type IInspectionResultAggregate = {
  __typename: 'inspection_result_aggregate';
  aggregate?: Maybe<IInspectionResultAggregateFields>;
  nodes: Array<IInspectionResult>;
};

/** aggregate fields of "inspection_result" */
export type IInspectionResultAggregateFields = {
  __typename: 'inspection_result_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IInspectionResultMaxFields>;
  min?: Maybe<IInspectionResultMinFields>;
};


/** aggregate fields of "inspection_result" */
export type IInspectionResultAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IInspectionResultSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "inspection_result". All fields are combined with a logical 'AND'. */
export type IInspectionResultBoolExp = {
  _and?: InputMaybe<Array<IInspectionResultBoolExp>>;
  _not?: InputMaybe<IInspectionResultBoolExp>;
  _or?: InputMaybe<Array<IInspectionResultBoolExp>>;
  comment?: InputMaybe<IStringComparisonExp>;
  value?: InputMaybe<IStringComparisonExp>;
};

/** unique or primary key constraints on table "inspection_result" */
export enum IInspectionResultConstraint {
  /** unique or primary key constraint on columns "value" */
  InspectionStatePkey = 'inspection_state_pkey'
}

/** input type for inserting data into table "inspection_result" */
export type IInspectionResultInsertInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type IInspectionResultMaxFields = {
  __typename: 'inspection_result_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IInspectionResultMinFields = {
  __typename: 'inspection_result_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "inspection_result" */
export type IInspectionResultMutationResponse = {
  __typename: 'inspection_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IInspectionResult>;
};

/** on_conflict condition type for table "inspection_result" */
export type IInspectionResultOnConflict = {
  constraint: IInspectionResultConstraint;
  update_columns?: Array<IInspectionResultUpdateColumn>;
  where?: InputMaybe<IInspectionResultBoolExp>;
};

/** Ordering options when selecting data from "inspection_result". */
export type IInspectionResultOrderBy = {
  comment?: InputMaybe<IOrderBy>;
  value?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: inspection_result */
export type IInspectionResultPkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "inspection_result" */
export enum IInspectionResultSelectColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "inspection_result" */
export type IInspectionResultSetInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "inspection_result" */
export type IInspectionResultStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IInspectionResultStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IInspectionResultStreamCursorValueInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "inspection_result" */
export enum IInspectionResultUpdateColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type IInspectionResultUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IInspectionResultSetInput>;
  /** filter the rows which have to be updated */
  where: IInspectionResultBoolExp;
};

/** Boolean expression to compare columns of type "interval". All fields are combined with logical 'AND'. */
export type IIntervalComparisonExp = {
  _eq?: InputMaybe<Scalars['interval']['input']>;
  _gt?: InputMaybe<Scalars['interval']['input']>;
  _gte?: InputMaybe<Scalars['interval']['input']>;
  _in?: InputMaybe<Array<Scalars['interval']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['interval']['input']>;
  _lte?: InputMaybe<Scalars['interval']['input']>;
  _neq?: InputMaybe<Scalars['interval']['input']>;
  _nin?: InputMaybe<Array<Scalars['interval']['input']>>;
};

/** Table to ensure that cron jobs are idempotent */
export type IJobsCronJobCompleted = {
  __typename: 'jobs_cron_job_completed';
  can_be_deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['String']['output'];
};

/** aggregated selection of "jobs.cron_job_completed" */
export type IJobsCronJobCompletedAggregate = {
  __typename: 'jobs_cron_job_completed_aggregate';
  aggregate?: Maybe<IJobsCronJobCompletedAggregateFields>;
  nodes: Array<IJobsCronJobCompleted>;
};

/** aggregate fields of "jobs.cron_job_completed" */
export type IJobsCronJobCompletedAggregateFields = {
  __typename: 'jobs_cron_job_completed_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IJobsCronJobCompletedMaxFields>;
  min?: Maybe<IJobsCronJobCompletedMinFields>;
};


/** aggregate fields of "jobs.cron_job_completed" */
export type IJobsCronJobCompletedAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IJobsCronJobCompletedSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "jobs.cron_job_completed". All fields are combined with a logical 'AND'. */
export type IJobsCronJobCompletedBoolExp = {
  _and?: InputMaybe<Array<IJobsCronJobCompletedBoolExp>>;
  _not?: InputMaybe<IJobsCronJobCompletedBoolExp>;
  _or?: InputMaybe<Array<IJobsCronJobCompletedBoolExp>>;
  can_be_deleted_at?: InputMaybe<ITimestamptzComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IStringComparisonExp>;
};

/** unique or primary key constraints on table "jobs.cron_job_completed" */
export enum IJobsCronJobCompletedConstraint {
  /** unique or primary key constraint on columns "id" */
  CronJobCompletedPkey = 'cron_job_completed_pkey'
}

/** input type for inserting data into table "jobs.cron_job_completed" */
export type IJobsCronJobCompletedInsertInput = {
  can_be_deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type IJobsCronJobCompletedMaxFields = {
  __typename: 'jobs_cron_job_completed_max_fields';
  can_be_deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IJobsCronJobCompletedMinFields = {
  __typename: 'jobs_cron_job_completed_min_fields';
  can_be_deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "jobs.cron_job_completed" */
export type IJobsCronJobCompletedMutationResponse = {
  __typename: 'jobs_cron_job_completed_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IJobsCronJobCompleted>;
};

/** on_conflict condition type for table "jobs.cron_job_completed" */
export type IJobsCronJobCompletedOnConflict = {
  constraint: IJobsCronJobCompletedConstraint;
  update_columns?: Array<IJobsCronJobCompletedUpdateColumn>;
  where?: InputMaybe<IJobsCronJobCompletedBoolExp>;
};

/** Ordering options when selecting data from "jobs.cron_job_completed". */
export type IJobsCronJobCompletedOrderBy = {
  can_be_deleted_at?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: jobs.cron_job_completed */
export type IJobsCronJobCompletedPkColumnsInput = {
  id: Scalars['String']['input'];
};

/** select columns of table "jobs.cron_job_completed" */
export enum IJobsCronJobCompletedSelectColumn {
  /** column name */
  CanBeDeletedAt = 'can_be_deleted_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "jobs.cron_job_completed" */
export type IJobsCronJobCompletedSetInput = {
  can_be_deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "jobs_cron_job_completed" */
export type IJobsCronJobCompletedStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IJobsCronJobCompletedStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IJobsCronJobCompletedStreamCursorValueInput = {
  can_be_deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "jobs.cron_job_completed" */
export enum IJobsCronJobCompletedUpdateColumn {
  /** column name */
  CanBeDeletedAt = 'can_be_deleted_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id'
}

export type IJobsCronJobCompletedUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IJobsCronJobCompletedSetInput>;
  /** filter the rows which have to be updated */
  where: IJobsCronJobCompletedBoolExp;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type IJsonComparisonExp = {
  _eq?: InputMaybe<Scalars['json']['input']>;
  _gt?: InputMaybe<Scalars['json']['input']>;
  _gte?: InputMaybe<Scalars['json']['input']>;
  _in?: InputMaybe<Array<Scalars['json']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['json']['input']>;
  _lte?: InputMaybe<Scalars['json']['input']>;
  _neq?: InputMaybe<Scalars['json']['input']>;
  _nin?: InputMaybe<Array<Scalars['json']['input']>>;
};

export type IJsonbCastExp = {
  String?: InputMaybe<IStringComparisonExp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type IJsonbComparisonExp = {
  _cast?: InputMaybe<IJsonbCastExp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** columns and relationships of "location" */
export type ILocation = {
  __typename: 'location';
  /** An array relationship */
  admins: Array<ILocationAdminView>;
  /** An aggregate relationship */
  admins_aggregate: ILocationAdminViewAggregate;
  /** A computed field, executes function "asset_availability_analytics" */
  asset_availability_analytics?: Maybe<Scalars['json']['output']>;
  /** An array relationship */
  asset_groups: Array<IAssetGroup>;
  /** An aggregate relationship */
  asset_groups_aggregate: IAssetGroupAggregate;
  /** An array relationship */
  assets: Array<IAsset>;
  /** An aggregate relationship */
  assets_aggregate: IAssetAggregate;
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  /** A computed field, executes function "location_features" */
  features?: Maybe<Scalars['jsonb']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  logo?: Maybe<IUpload>;
  logo_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  members: Array<ILocationMember>;
  /** An aggregate relationship */
  members_aggregate: ILocationMemberAggregate;
  name: Scalars['String']['output'];
  /** An object relationship */
  org: IOrg;
  org_id: Scalars['uuid']['output'];
  settings: Scalars['jsonb']['output'];
  setup_info?: Maybe<Scalars['json']['output']>;
  /** An array relationship */
  teams: Array<ITeam>;
  /** An aggregate relationship */
  teams_aggregate: ITeamAggregate;
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  work_orders: Array<IWorkOrder>;
  /** An aggregate relationship */
  work_orders_aggregate: IWorkOrderAggregate;
};


/** columns and relationships of "location" */
export type ILocationAdminsArgs = {
  distinct_on?: InputMaybe<Array<ILocationAdminViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationAdminViewOrderBy>>;
  where?: InputMaybe<ILocationAdminViewBoolExp>;
};


/** columns and relationships of "location" */
export type ILocationAdminsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ILocationAdminViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationAdminViewOrderBy>>;
  where?: InputMaybe<ILocationAdminViewBoolExp>;
};


/** columns and relationships of "location" */
export type ILocationAssetAvailabilityAnalyticsArgs = {
  args: IAssetAvailabilityAnalyticsLocationArgs;
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "location" */
export type ILocationAssetGroupsArgs = {
  distinct_on?: InputMaybe<Array<IAssetGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetGroupOrderBy>>;
  where?: InputMaybe<IAssetGroupBoolExp>;
};


/** columns and relationships of "location" */
export type ILocationAssetGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetGroupOrderBy>>;
  where?: InputMaybe<IAssetGroupBoolExp>;
};


/** columns and relationships of "location" */
export type ILocationAssetsArgs = {
  distinct_on?: InputMaybe<Array<IAssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetOrderBy>>;
  where?: InputMaybe<IAssetBoolExp>;
};


/** columns and relationships of "location" */
export type ILocationAssetsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetOrderBy>>;
  where?: InputMaybe<IAssetBoolExp>;
};


/** columns and relationships of "location" */
export type ILocationFeaturesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "location" */
export type ILocationMembersArgs = {
  distinct_on?: InputMaybe<Array<ILocationMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationMemberOrderBy>>;
  where?: InputMaybe<ILocationMemberBoolExp>;
};


/** columns and relationships of "location" */
export type ILocationMembersAggregateArgs = {
  distinct_on?: InputMaybe<Array<ILocationMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationMemberOrderBy>>;
  where?: InputMaybe<ILocationMemberBoolExp>;
};


/** columns and relationships of "location" */
export type ILocationSettingsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "location" */
export type ILocationSetupInfoArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "location" */
export type ILocationTeamsArgs = {
  distinct_on?: InputMaybe<Array<ITeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ITeamOrderBy>>;
  where?: InputMaybe<ITeamBoolExp>;
};


/** columns and relationships of "location" */
export type ILocationTeamsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ITeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ITeamOrderBy>>;
  where?: InputMaybe<ITeamBoolExp>;
};


/** columns and relationships of "location" */
export type ILocationWorkOrdersArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOrderBy>>;
  where?: InputMaybe<IWorkOrderBoolExp>;
};


/** columns and relationships of "location" */
export type ILocationWorkOrdersAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOrderBy>>;
  where?: InputMaybe<IWorkOrderBoolExp>;
};

/** columns and relationships of "location_admin_view" */
export type ILocationAdminView = {
  __typename: 'location_admin_view';
  /** An object relationship */
  location?: Maybe<ILocation>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  user?: Maybe<IUser>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "location_admin_view" */
export type ILocationAdminViewAggregate = {
  __typename: 'location_admin_view_aggregate';
  aggregate?: Maybe<ILocationAdminViewAggregateFields>;
  nodes: Array<ILocationAdminView>;
};

export type ILocationAdminViewAggregateBoolExp = {
  count?: InputMaybe<ILocationAdminViewAggregateBoolExpCount>;
};

export type ILocationAdminViewAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ILocationAdminViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ILocationAdminViewBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "location_admin_view" */
export type ILocationAdminViewAggregateFields = {
  __typename: 'location_admin_view_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ILocationAdminViewMaxFields>;
  min?: Maybe<ILocationAdminViewMinFields>;
};


/** aggregate fields of "location_admin_view" */
export type ILocationAdminViewAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ILocationAdminViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "location_admin_view" */
export type ILocationAdminViewAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<ILocationAdminViewMaxOrderBy>;
  min?: InputMaybe<ILocationAdminViewMinOrderBy>;
};

/** input type for inserting array relation for remote table "location_admin_view" */
export type ILocationAdminViewArrRelInsertInput = {
  data: Array<ILocationAdminViewInsertInput>;
};

/** Boolean expression to filter rows from the table "location_admin_view". All fields are combined with a logical 'AND'. */
export type ILocationAdminViewBoolExp = {
  _and?: InputMaybe<Array<ILocationAdminViewBoolExp>>;
  _not?: InputMaybe<ILocationAdminViewBoolExp>;
  _or?: InputMaybe<Array<ILocationAdminViewBoolExp>>;
  location?: InputMaybe<ILocationBoolExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  user?: InputMaybe<IUserBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
};

/** input type for inserting data into table "location_admin_view" */
export type ILocationAdminViewInsertInput = {
  location?: InputMaybe<ILocationObjRelInsertInput>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<IUserObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type ILocationAdminViewMaxFields = {
  __typename: 'location_admin_view_max_fields';
  location_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "location_admin_view" */
export type ILocationAdminViewMaxOrderBy = {
  location_id?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type ILocationAdminViewMinFields = {
  __typename: 'location_admin_view_min_fields';
  location_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "location_admin_view" */
export type ILocationAdminViewMinOrderBy = {
  location_id?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
};

/** Ordering options when selecting data from "location_admin_view". */
export type ILocationAdminViewOrderBy = {
  location?: InputMaybe<ILocationOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUserOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
};

/** select columns of table "location_admin_view" */
export enum ILocationAdminViewSelectColumn {
  /** column name */
  LocationId = 'location_id',
  /** column name */
  UserId = 'user_id'
}

/** Streaming cursor of the table "location_admin_view" */
export type ILocationAdminViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ILocationAdminViewStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ILocationAdminViewStreamCursorValueInput = {
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregated selection of "location" */
export type ILocationAggregate = {
  __typename: 'location_aggregate';
  aggregate?: Maybe<ILocationAggregateFields>;
  nodes: Array<ILocation>;
};

export type ILocationAggregateBoolExp = {
  count?: InputMaybe<ILocationAggregateBoolExpCount>;
};

export type ILocationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ILocationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ILocationBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "location" */
export type ILocationAggregateFields = {
  __typename: 'location_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ILocationMaxFields>;
  min?: Maybe<ILocationMinFields>;
};


/** aggregate fields of "location" */
export type ILocationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ILocationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "location" */
export type ILocationAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<ILocationMaxOrderBy>;
  min?: InputMaybe<ILocationMinOrderBy>;
};

/** columns and relationships of "location_api_key" */
export type ILocationApiKey = {
  __typename: 'location_api_key';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  key: Scalars['String']['output'];
  /** A computed field, executes function "api_key_preview" */
  key_preview?: Maybe<Scalars['String']['output']>;
  last_used_at: Scalars['timestamptz']['output'];
  location_id: Scalars['uuid']['output'];
  /** An object relationship */
  user?: Maybe<IUser>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "location_api_key" */
export type ILocationApiKeyAggregate = {
  __typename: 'location_api_key_aggregate';
  aggregate?: Maybe<ILocationApiKeyAggregateFields>;
  nodes: Array<ILocationApiKey>;
};

/** aggregate fields of "location_api_key" */
export type ILocationApiKeyAggregateFields = {
  __typename: 'location_api_key_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ILocationApiKeyMaxFields>;
  min?: Maybe<ILocationApiKeyMinFields>;
};


/** aggregate fields of "location_api_key" */
export type ILocationApiKeyAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ILocationApiKeySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "location_api_key". All fields are combined with a logical 'AND'. */
export type ILocationApiKeyBoolExp = {
  _and?: InputMaybe<Array<ILocationApiKeyBoolExp>>;
  _not?: InputMaybe<ILocationApiKeyBoolExp>;
  _or?: InputMaybe<Array<ILocationApiKeyBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  key?: InputMaybe<IStringComparisonExp>;
  key_preview?: InputMaybe<IStringComparisonExp>;
  last_used_at?: InputMaybe<ITimestamptzComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  user?: InputMaybe<IUserBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "location_api_key" */
export enum ILocationApiKeyConstraint {
  /** unique or primary key constraint on columns "key" */
  LocationApiKeyKeyKey = 'location_api_key_key_key',
  /** unique or primary key constraint on columns "id" */
  LocationApiKeyPkey = 'location_api_key_pkey'
}

/** input type for inserting data into table "location_api_key" */
export type ILocationApiKeyInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  last_used_at?: InputMaybe<Scalars['timestamptz']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<IUserObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type ILocationApiKeyMaxFields = {
  __typename: 'location_api_key_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "api_key_preview" */
  key_preview?: Maybe<Scalars['String']['output']>;
  last_used_at?: Maybe<Scalars['timestamptz']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type ILocationApiKeyMinFields = {
  __typename: 'location_api_key_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "api_key_preview" */
  key_preview?: Maybe<Scalars['String']['output']>;
  last_used_at?: Maybe<Scalars['timestamptz']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "location_api_key" */
export type ILocationApiKeyMutationResponse = {
  __typename: 'location_api_key_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ILocationApiKey>;
};

/** on_conflict condition type for table "location_api_key" */
export type ILocationApiKeyOnConflict = {
  constraint: ILocationApiKeyConstraint;
  update_columns?: Array<ILocationApiKeyUpdateColumn>;
  where?: InputMaybe<ILocationApiKeyBoolExp>;
};

/** Ordering options when selecting data from "location_api_key". */
export type ILocationApiKeyOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  key?: InputMaybe<IOrderBy>;
  key_preview?: InputMaybe<IOrderBy>;
  last_used_at?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUserOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: location_api_key */
export type ILocationApiKeyPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "location_api_key" */
export enum ILocationApiKeySelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  LastUsedAt = 'last_used_at',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "location_api_key" */
export type ILocationApiKeySetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  last_used_at?: InputMaybe<Scalars['timestamptz']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "location_api_key" */
export type ILocationApiKeyStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ILocationApiKeyStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ILocationApiKeyStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  last_used_at?: InputMaybe<Scalars['timestamptz']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "location_api_key" */
export enum ILocationApiKeyUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  LastUsedAt = 'last_used_at',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  UserId = 'user_id'
}

export type ILocationApiKeyUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ILocationApiKeySetInput>;
  /** filter the rows which have to be updated */
  where: ILocationApiKeyBoolExp;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ILocationAppendInput = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "location" */
export type ILocationArrRelInsertInput = {
  data: Array<ILocationInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ILocationOnConflict>;
};

/** Boolean expression to filter rows from the table "location". All fields are combined with a logical 'AND'. */
export type ILocationBoolExp = {
  _and?: InputMaybe<Array<ILocationBoolExp>>;
  _not?: InputMaybe<ILocationBoolExp>;
  _or?: InputMaybe<Array<ILocationBoolExp>>;
  admins?: InputMaybe<ILocationAdminViewBoolExp>;
  admins_aggregate?: InputMaybe<ILocationAdminViewAggregateBoolExp>;
  asset_groups?: InputMaybe<IAssetGroupBoolExp>;
  asset_groups_aggregate?: InputMaybe<IAssetGroupAggregateBoolExp>;
  assets?: InputMaybe<IAssetBoolExp>;
  assets_aggregate?: InputMaybe<IAssetAggregateBoolExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  deleted_at?: InputMaybe<ITimestamptzComparisonExp>;
  features?: InputMaybe<IJsonbComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  logo?: InputMaybe<IUploadBoolExp>;
  logo_id?: InputMaybe<IUuidComparisonExp>;
  members?: InputMaybe<ILocationMemberBoolExp>;
  members_aggregate?: InputMaybe<ILocationMemberAggregateBoolExp>;
  name?: InputMaybe<IStringComparisonExp>;
  org?: InputMaybe<IOrgBoolExp>;
  org_id?: InputMaybe<IUuidComparisonExp>;
  settings?: InputMaybe<IJsonbComparisonExp>;
  setup_info?: InputMaybe<IJsonComparisonExp>;
  teams?: InputMaybe<ITeamBoolExp>;
  teams_aggregate?: InputMaybe<ITeamAggregateBoolExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  work_orders?: InputMaybe<IWorkOrderBoolExp>;
  work_orders_aggregate?: InputMaybe<IWorkOrderAggregateBoolExp>;
};

/** unique or primary key constraints on table "location" */
export enum ILocationConstraint {
  /** unique or primary key constraint on columns "id" */
  LocationPkey = 'location_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ILocationDeleteAtPathInput = {
  settings?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ILocationDeleteElemInput = {
  settings?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ILocationDeleteKeyInput = {
  settings?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "location" */
export type ILocationInsertInput = {
  admins?: InputMaybe<ILocationAdminViewArrRelInsertInput>;
  asset_groups?: InputMaybe<IAssetGroupArrRelInsertInput>;
  assets?: InputMaybe<IAssetArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  logo?: InputMaybe<IUploadObjRelInsertInput>;
  logo_id?: InputMaybe<Scalars['uuid']['input']>;
  members?: InputMaybe<ILocationMemberArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  org?: InputMaybe<IOrgObjRelInsertInput>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  setup_info?: InputMaybe<Scalars['json']['input']>;
  teams?: InputMaybe<ITeamArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_orders?: InputMaybe<IWorkOrderArrRelInsertInput>;
};

/** Temporary invites to a location */
export type ILocationInvite = {
  __typename: 'location_invite';
  approval_necessary: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  expires_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  invite_code: Scalars['String']['output'];
  location_id: Scalars['uuid']['output'];
  /** An object relationship */
  permission_role: IPermissionRole;
  permission_role_id: Scalars['uuid']['output'];
};

/** aggregated selection of "location_invite" */
export type ILocationInviteAggregate = {
  __typename: 'location_invite_aggregate';
  aggregate?: Maybe<ILocationInviteAggregateFields>;
  nodes: Array<ILocationInvite>;
};

/** aggregate fields of "location_invite" */
export type ILocationInviteAggregateFields = {
  __typename: 'location_invite_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ILocationInviteMaxFields>;
  min?: Maybe<ILocationInviteMinFields>;
};


/** aggregate fields of "location_invite" */
export type ILocationInviteAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ILocationInviteSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "location_invite". All fields are combined with a logical 'AND'. */
export type ILocationInviteBoolExp = {
  _and?: InputMaybe<Array<ILocationInviteBoolExp>>;
  _not?: InputMaybe<ILocationInviteBoolExp>;
  _or?: InputMaybe<Array<ILocationInviteBoolExp>>;
  approval_necessary?: InputMaybe<IBooleanComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  expires_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  invite_code?: InputMaybe<IStringComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  permission_role?: InputMaybe<IPermissionRoleBoolExp>;
  permission_role_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "location_invite" */
export enum ILocationInviteConstraint {
  /** unique or primary key constraint on columns "invite_code" */
  LocationInviteInviteCodeKey = 'location_invite_invite_code_key',
  /** unique or primary key constraint on columns "id" */
  LocationInvitePkey = 'location_invite_pkey'
}

/** input type for inserting data into table "location_invite" */
export type ILocationInviteInsertInput = {
  approval_necessary?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  invite_code?: InputMaybe<Scalars['String']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  permission_role?: InputMaybe<IPermissionRoleObjRelInsertInput>;
  permission_role_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type ILocationInviteMaxFields = {
  __typename: 'location_invite_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  expires_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  invite_code?: Maybe<Scalars['String']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  permission_role_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type ILocationInviteMinFields = {
  __typename: 'location_invite_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  expires_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  invite_code?: Maybe<Scalars['String']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  permission_role_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "location_invite" */
export type ILocationInviteMutationResponse = {
  __typename: 'location_invite_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ILocationInvite>;
};

/** on_conflict condition type for table "location_invite" */
export type ILocationInviteOnConflict = {
  constraint: ILocationInviteConstraint;
  update_columns?: Array<ILocationInviteUpdateColumn>;
  where?: InputMaybe<ILocationInviteBoolExp>;
};

/** Ordering options when selecting data from "location_invite". */
export type ILocationInviteOrderBy = {
  approval_necessary?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  expires_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  invite_code?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  permission_role?: InputMaybe<IPermissionRoleOrderBy>;
  permission_role_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: location_invite */
export type ILocationInvitePkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "location_invite" */
export enum ILocationInviteSelectColumn {
  /** column name */
  ApprovalNecessary = 'approval_necessary',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  InviteCode = 'invite_code',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  PermissionRoleId = 'permission_role_id'
}

/** input type for updating data in table "location_invite" */
export type ILocationInviteSetInput = {
  approval_necessary?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  invite_code?: InputMaybe<Scalars['String']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  permission_role_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "location_invite" */
export type ILocationInviteStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ILocationInviteStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ILocationInviteStreamCursorValueInput = {
  approval_necessary?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  invite_code?: InputMaybe<Scalars['String']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  permission_role_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "location_invite" */
export enum ILocationInviteUpdateColumn {
  /** column name */
  ApprovalNecessary = 'approval_necessary',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  InviteCode = 'invite_code',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  PermissionRoleId = 'permission_role_id'
}

export type ILocationInviteUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ILocationInviteSetInput>;
  /** filter the rows which have to be updated */
  where: ILocationInviteBoolExp;
};

/** aggregate max on columns */
export type ILocationMaxFields = {
  __typename: 'location_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  logo_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "location" */
export type ILocationMaxOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  deleted_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  logo_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  org_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** columns and relationships of "location_member" */
export type ILocationMember = {
  __typename: 'location_member';
  approved_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at: Scalars['timestamptz']['output'];
  has_full_access: Scalars['Boolean']['output'];
  has_only_assigned_access?: Maybe<Scalars['Boolean']['output']>;
  is_owner: Scalars['Boolean']['output'];
  /** An object relationship */
  location: ILocation;
  location_id: Scalars['uuid']['output'];
  /** An object relationship */
  permission_role?: Maybe<IPermissionRole>;
  permission_role_id?: Maybe<Scalars['uuid']['output']>;
  role?: Maybe<ILocationMemberRoleEnum>;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: IUser;
  user_id: Scalars['uuid']['output'];
  user_role?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "location_member" */
export type ILocationMemberAggregate = {
  __typename: 'location_member_aggregate';
  aggregate?: Maybe<ILocationMemberAggregateFields>;
  nodes: Array<ILocationMember>;
};

export type ILocationMemberAggregateBoolExp = {
  bool_and?: InputMaybe<ILocationMemberAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<ILocationMemberAggregateBoolExpBoolOr>;
  count?: InputMaybe<ILocationMemberAggregateBoolExpCount>;
};

export type ILocationMemberAggregateBoolExpBoolAnd = {
  arguments: ILocationMemberSelectColumnLocationMemberAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ILocationMemberBoolExp>;
  predicate: IBooleanComparisonExp;
};

export type ILocationMemberAggregateBoolExpBoolOr = {
  arguments: ILocationMemberSelectColumnLocationMemberAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ILocationMemberBoolExp>;
  predicate: IBooleanComparisonExp;
};

export type ILocationMemberAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ILocationMemberSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ILocationMemberBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "location_member" */
export type ILocationMemberAggregateFields = {
  __typename: 'location_member_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ILocationMemberMaxFields>;
  min?: Maybe<ILocationMemberMinFields>;
};


/** aggregate fields of "location_member" */
export type ILocationMemberAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ILocationMemberSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "location_member" */
export type ILocationMemberAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<ILocationMemberMaxOrderBy>;
  min?: InputMaybe<ILocationMemberMinOrderBy>;
};

/** input type for inserting array relation for remote table "location_member" */
export type ILocationMemberArrRelInsertInput = {
  data: Array<ILocationMemberInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ILocationMemberOnConflict>;
};

/** Boolean expression to filter rows from the table "location_member". All fields are combined with a logical 'AND'. */
export type ILocationMemberBoolExp = {
  _and?: InputMaybe<Array<ILocationMemberBoolExp>>;
  _not?: InputMaybe<ILocationMemberBoolExp>;
  _or?: InputMaybe<Array<ILocationMemberBoolExp>>;
  approved_at?: InputMaybe<ITimestamptzComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  has_full_access?: InputMaybe<IBooleanComparisonExp>;
  has_only_assigned_access?: InputMaybe<IBooleanComparisonExp>;
  is_owner?: InputMaybe<IBooleanComparisonExp>;
  location?: InputMaybe<ILocationBoolExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  permission_role?: InputMaybe<IPermissionRoleBoolExp>;
  permission_role_id?: InputMaybe<IUuidComparisonExp>;
  role?: InputMaybe<ILocationMemberRoleEnumComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  user?: InputMaybe<IUserBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
  user_role?: InputMaybe<IStringComparisonExp>;
};

/** unique or primary key constraints on table "location_member" */
export enum ILocationMemberConstraint {
  /** unique or primary key constraint on columns "user_id", "location_id" */
  LocationMemberPkey = 'location_member_pkey'
}

/** input type for inserting data into table "location_member" */
export type ILocationMemberInsertInput = {
  approved_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  has_full_access?: InputMaybe<Scalars['Boolean']['input']>;
  has_only_assigned_access?: InputMaybe<Scalars['Boolean']['input']>;
  is_owner?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<ILocationObjRelInsertInput>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  permission_role?: InputMaybe<IPermissionRoleObjRelInsertInput>;
  permission_role_id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<ILocationMemberRoleEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<IUserObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  user_role?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ILocationMemberMaxFields = {
  __typename: 'location_member_max_fields';
  approved_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  permission_role_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  user_role?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "location_member" */
export type ILocationMemberMaxOrderBy = {
  approved_at?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  permission_role_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  user_role?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type ILocationMemberMinFields = {
  __typename: 'location_member_min_fields';
  approved_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  permission_role_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  user_role?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "location_member" */
export type ILocationMemberMinOrderBy = {
  approved_at?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  permission_role_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  user_role?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "location_member" */
export type ILocationMemberMutationResponse = {
  __typename: 'location_member_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ILocationMember>;
};

/** on_conflict condition type for table "location_member" */
export type ILocationMemberOnConflict = {
  constraint: ILocationMemberConstraint;
  update_columns?: Array<ILocationMemberUpdateColumn>;
  where?: InputMaybe<ILocationMemberBoolExp>;
};

/** Ordering options when selecting data from "location_member". */
export type ILocationMemberOrderBy = {
  approved_at?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  has_full_access?: InputMaybe<IOrderBy>;
  has_only_assigned_access?: InputMaybe<IOrderBy>;
  is_owner?: InputMaybe<IOrderBy>;
  location?: InputMaybe<ILocationOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  permission_role?: InputMaybe<IPermissionRoleOrderBy>;
  permission_role_id?: InputMaybe<IOrderBy>;
  role?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUserOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  user_role?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: location_member */
export type ILocationMemberPkColumnsInput = {
  location_id: Scalars['uuid']['input'];
  user_id: Scalars['uuid']['input'];
};

/** columns and relationships of "location_member_role" */
export type ILocationMemberRole = {
  __typename: 'location_member_role';
  role: Scalars['String']['output'];
};

/** aggregated selection of "location_member_role" */
export type ILocationMemberRoleAggregate = {
  __typename: 'location_member_role_aggregate';
  aggregate?: Maybe<ILocationMemberRoleAggregateFields>;
  nodes: Array<ILocationMemberRole>;
};

/** aggregate fields of "location_member_role" */
export type ILocationMemberRoleAggregateFields = {
  __typename: 'location_member_role_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ILocationMemberRoleMaxFields>;
  min?: Maybe<ILocationMemberRoleMinFields>;
};


/** aggregate fields of "location_member_role" */
export type ILocationMemberRoleAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ILocationMemberRoleSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "location_member_role". All fields are combined with a logical 'AND'. */
export type ILocationMemberRoleBoolExp = {
  _and?: InputMaybe<Array<ILocationMemberRoleBoolExp>>;
  _not?: InputMaybe<ILocationMemberRoleBoolExp>;
  _or?: InputMaybe<Array<ILocationMemberRoleBoolExp>>;
  role?: InputMaybe<IStringComparisonExp>;
};

/** unique or primary key constraints on table "location_member_role" */
export enum ILocationMemberRoleConstraint {
  /** unique or primary key constraint on columns "role" */
  LocationMemberRolePkey = 'location_member_role_pkey'
}

export enum ILocationMemberRoleEnum {
  Admin = 'admin',
  Guest = 'guest',
  LimitedAdmin = 'limited_admin',
  LimitedUser = 'limited_user',
  SuperAdmin = 'super_admin',
  User = 'user'
}

/** Boolean expression to compare columns of type "location_member_role_enum". All fields are combined with logical 'AND'. */
export type ILocationMemberRoleEnumComparisonExp = {
  _eq?: InputMaybe<ILocationMemberRoleEnum>;
  _in?: InputMaybe<Array<ILocationMemberRoleEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<ILocationMemberRoleEnum>;
  _nin?: InputMaybe<Array<ILocationMemberRoleEnum>>;
};

/** input type for inserting data into table "location_member_role" */
export type ILocationMemberRoleInsertInput = {
  role?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ILocationMemberRoleMaxFields = {
  __typename: 'location_member_role_max_fields';
  role?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ILocationMemberRoleMinFields = {
  __typename: 'location_member_role_min_fields';
  role?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "location_member_role" */
export type ILocationMemberRoleMutationResponse = {
  __typename: 'location_member_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ILocationMemberRole>;
};

/** on_conflict condition type for table "location_member_role" */
export type ILocationMemberRoleOnConflict = {
  constraint: ILocationMemberRoleConstraint;
  update_columns?: Array<ILocationMemberRoleUpdateColumn>;
  where?: InputMaybe<ILocationMemberRoleBoolExp>;
};

/** Ordering options when selecting data from "location_member_role". */
export type ILocationMemberRoleOrderBy = {
  role?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: location_member_role */
export type ILocationMemberRolePkColumnsInput = {
  role: Scalars['String']['input'];
};

/** select columns of table "location_member_role" */
export enum ILocationMemberRoleSelectColumn {
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "location_member_role" */
export type ILocationMemberRoleSetInput = {
  role?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "location_member_role" */
export type ILocationMemberRoleStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ILocationMemberRoleStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ILocationMemberRoleStreamCursorValueInput = {
  role?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "location_member_role" */
export enum ILocationMemberRoleUpdateColumn {
  /** column name */
  Role = 'role'
}

export type ILocationMemberRoleUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ILocationMemberRoleSetInput>;
  /** filter the rows which have to be updated */
  where: ILocationMemberRoleBoolExp;
};

/** select columns of table "location_member" */
export enum ILocationMemberSelectColumn {
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasFullAccess = 'has_full_access',
  /** column name */
  HasOnlyAssignedAccess = 'has_only_assigned_access',
  /** column name */
  IsOwner = 'is_owner',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  PermissionRoleId = 'permission_role_id',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserRole = 'user_role'
}

/** select "location_member_aggregate_bool_exp_bool_and_arguments_columns" columns of table "location_member" */
export enum ILocationMemberSelectColumnLocationMemberAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  HasFullAccess = 'has_full_access',
  /** column name */
  HasOnlyAssignedAccess = 'has_only_assigned_access',
  /** column name */
  IsOwner = 'is_owner'
}

/** select "location_member_aggregate_bool_exp_bool_or_arguments_columns" columns of table "location_member" */
export enum ILocationMemberSelectColumnLocationMemberAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  HasFullAccess = 'has_full_access',
  /** column name */
  HasOnlyAssignedAccess = 'has_only_assigned_access',
  /** column name */
  IsOwner = 'is_owner'
}

/** input type for updating data in table "location_member" */
export type ILocationMemberSetInput = {
  approved_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  has_full_access?: InputMaybe<Scalars['Boolean']['input']>;
  has_only_assigned_access?: InputMaybe<Scalars['Boolean']['input']>;
  is_owner?: InputMaybe<Scalars['Boolean']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  permission_role_id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<ILocationMemberRoleEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  user_role?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "location_member" */
export type ILocationMemberStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ILocationMemberStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ILocationMemberStreamCursorValueInput = {
  approved_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  has_full_access?: InputMaybe<Scalars['Boolean']['input']>;
  has_only_assigned_access?: InputMaybe<Scalars['Boolean']['input']>;
  is_owner?: InputMaybe<Scalars['Boolean']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  permission_role_id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<ILocationMemberRoleEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  user_role?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "location_member" */
export enum ILocationMemberUpdateColumn {
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasFullAccess = 'has_full_access',
  /** column name */
  HasOnlyAssignedAccess = 'has_only_assigned_access',
  /** column name */
  IsOwner = 'is_owner',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  PermissionRoleId = 'permission_role_id',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserRole = 'user_role'
}

export type ILocationMemberUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ILocationMemberSetInput>;
  /** filter the rows which have to be updated */
  where: ILocationMemberBoolExp;
};

/** aggregate min on columns */
export type ILocationMinFields = {
  __typename: 'location_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  logo_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "location" */
export type ILocationMinOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  deleted_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  logo_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  org_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "location" */
export type ILocationMutationResponse = {
  __typename: 'location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ILocation>;
};

/** input type for inserting object relation for remote table "location" */
export type ILocationObjRelInsertInput = {
  data: ILocationInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ILocationOnConflict>;
};

/** on_conflict condition type for table "location" */
export type ILocationOnConflict = {
  constraint: ILocationConstraint;
  update_columns?: Array<ILocationUpdateColumn>;
  where?: InputMaybe<ILocationBoolExp>;
};

/** Ordering options when selecting data from "location". */
export type ILocationOrderBy = {
  admins_aggregate?: InputMaybe<ILocationAdminViewAggregateOrderBy>;
  asset_groups_aggregate?: InputMaybe<IAssetGroupAggregateOrderBy>;
  assets_aggregate?: InputMaybe<IAssetAggregateOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  deleted_at?: InputMaybe<IOrderBy>;
  features?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  logo?: InputMaybe<IUploadOrderBy>;
  logo_id?: InputMaybe<IOrderBy>;
  members_aggregate?: InputMaybe<ILocationMemberAggregateOrderBy>;
  name?: InputMaybe<IOrderBy>;
  org?: InputMaybe<IOrgOrderBy>;
  org_id?: InputMaybe<IOrderBy>;
  settings?: InputMaybe<IOrderBy>;
  setup_info?: InputMaybe<IOrderBy>;
  teams_aggregate?: InputMaybe<ITeamAggregateOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  work_orders_aggregate?: InputMaybe<IWorkOrderAggregateOrderBy>;
};

/** primary key columns input for table: location */
export type ILocationPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ILocationPrependInput = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "location" */
export enum ILocationSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LogoId = 'logo_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Settings = 'settings',
  /** column name */
  SetupInfo = 'setup_info',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "location" */
export type ILocationSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  logo_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  setup_info?: InputMaybe<Scalars['json']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "location" */
export type ILocationStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ILocationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ILocationStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  logo_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  setup_info?: InputMaybe<Scalars['json']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "location" */
export enum ILocationUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LogoId = 'logo_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Settings = 'settings',
  /** column name */
  SetupInfo = 'setup_info',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ILocationUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ILocationAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ILocationDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ILocationDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ILocationDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ILocationPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ILocationSetInput>;
  /** filter the rows which have to be updated */
  where: ILocationBoolExp;
};

/** The specific feature flags for a location */
export type ILocationXFeatureFlag = {
  __typename: 'location_x_feature_flag';
  enabled: Scalars['Boolean']['output'];
  feature_flag_id: Scalars['String']['output'];
  /** An object relationship */
  location: ILocation;
  location_id: Scalars['uuid']['output'];
};

/** aggregated selection of "location_x_feature_flag" */
export type ILocationXFeatureFlagAggregate = {
  __typename: 'location_x_feature_flag_aggregate';
  aggregate?: Maybe<ILocationXFeatureFlagAggregateFields>;
  nodes: Array<ILocationXFeatureFlag>;
};

/** aggregate fields of "location_x_feature_flag" */
export type ILocationXFeatureFlagAggregateFields = {
  __typename: 'location_x_feature_flag_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ILocationXFeatureFlagMaxFields>;
  min?: Maybe<ILocationXFeatureFlagMinFields>;
};


/** aggregate fields of "location_x_feature_flag" */
export type ILocationXFeatureFlagAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ILocationXFeatureFlagSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "location_x_feature_flag". All fields are combined with a logical 'AND'. */
export type ILocationXFeatureFlagBoolExp = {
  _and?: InputMaybe<Array<ILocationXFeatureFlagBoolExp>>;
  _not?: InputMaybe<ILocationXFeatureFlagBoolExp>;
  _or?: InputMaybe<Array<ILocationXFeatureFlagBoolExp>>;
  enabled?: InputMaybe<IBooleanComparisonExp>;
  feature_flag_id?: InputMaybe<IStringComparisonExp>;
  location?: InputMaybe<ILocationBoolExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "location_x_feature_flag" */
export enum ILocationXFeatureFlagConstraint {
  /** unique or primary key constraint on columns "feature_flag_id", "location_id" */
  LocationXFeatureFlagPkey = 'location_x_feature_flag_pkey'
}

/** input type for inserting data into table "location_x_feature_flag" */
export type ILocationXFeatureFlagInsertInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  feature_flag_id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<ILocationObjRelInsertInput>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type ILocationXFeatureFlagMaxFields = {
  __typename: 'location_x_feature_flag_max_fields';
  feature_flag_id?: Maybe<Scalars['String']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type ILocationXFeatureFlagMinFields = {
  __typename: 'location_x_feature_flag_min_fields';
  feature_flag_id?: Maybe<Scalars['String']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "location_x_feature_flag" */
export type ILocationXFeatureFlagMutationResponse = {
  __typename: 'location_x_feature_flag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ILocationXFeatureFlag>;
};

/** on_conflict condition type for table "location_x_feature_flag" */
export type ILocationXFeatureFlagOnConflict = {
  constraint: ILocationXFeatureFlagConstraint;
  update_columns?: Array<ILocationXFeatureFlagUpdateColumn>;
  where?: InputMaybe<ILocationXFeatureFlagBoolExp>;
};

/** Ordering options when selecting data from "location_x_feature_flag". */
export type ILocationXFeatureFlagOrderBy = {
  enabled?: InputMaybe<IOrderBy>;
  feature_flag_id?: InputMaybe<IOrderBy>;
  location?: InputMaybe<ILocationOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: location_x_feature_flag */
export type ILocationXFeatureFlagPkColumnsInput = {
  feature_flag_id: Scalars['String']['input'];
  location_id: Scalars['uuid']['input'];
};

/** select columns of table "location_x_feature_flag" */
export enum ILocationXFeatureFlagSelectColumn {
  /** column name */
  Enabled = 'enabled',
  /** column name */
  FeatureFlagId = 'feature_flag_id',
  /** column name */
  LocationId = 'location_id'
}

/** input type for updating data in table "location_x_feature_flag" */
export type ILocationXFeatureFlagSetInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  feature_flag_id?: InputMaybe<Scalars['String']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "location_x_feature_flag" */
export type ILocationXFeatureFlagStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ILocationXFeatureFlagStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ILocationXFeatureFlagStreamCursorValueInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  feature_flag_id?: InputMaybe<Scalars['String']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "location_x_feature_flag" */
export enum ILocationXFeatureFlagUpdateColumn {
  /** column name */
  Enabled = 'enabled',
  /** column name */
  FeatureFlagId = 'feature_flag_id',
  /** column name */
  LocationId = 'location_id'
}

export type ILocationXFeatureFlagUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ILocationXFeatureFlagSetInput>;
  /** filter the rows which have to be updated */
  where: ILocationXFeatureFlagBoolExp;
};

/** Custom Schedules for Preventive Maintenance */
export type IMaintenance = {
  __typename: 'maintenance';
  archived_at?: Maybe<Scalars['timestamptz']['output']>;
  create_individual_tasks_for_objects: Scalars['Boolean']['output'];
  create_next_only_if_last_complete: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  location_id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  paused: Scalars['Boolean']['output'];
  /** An array relationship */
  tasks: Array<IWorkOrder>;
  /** An aggregate relationship */
  tasks_aggregate: IWorkOrderAggregate;
  /** An object relationship */
  template?: Maybe<IWorkOrder>;
  template_id: Scalars['uuid']['output'];
  /** An array relationship */
  triggers: Array<IMaintenanceTrigger>;
  /** An aggregate relationship */
  triggers_aggregate: IMaintenanceTriggerAggregate;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};


/** Custom Schedules for Preventive Maintenance */
export type IMaintenanceTasksArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOrderBy>>;
  where?: InputMaybe<IWorkOrderBoolExp>;
};


/** Custom Schedules for Preventive Maintenance */
export type IMaintenanceTasksAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOrderBy>>;
  where?: InputMaybe<IWorkOrderBoolExp>;
};


/** Custom Schedules for Preventive Maintenance */
export type IMaintenanceTriggersArgs = {
  distinct_on?: InputMaybe<Array<IMaintenanceTriggerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMaintenanceTriggerOrderBy>>;
  where?: InputMaybe<IMaintenanceTriggerBoolExp>;
};


/** Custom Schedules for Preventive Maintenance */
export type IMaintenanceTriggersAggregateArgs = {
  distinct_on?: InputMaybe<Array<IMaintenanceTriggerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMaintenanceTriggerOrderBy>>;
  where?: InputMaybe<IMaintenanceTriggerBoolExp>;
};

/** aggregated selection of "maintenance" */
export type IMaintenanceAggregate = {
  __typename: 'maintenance_aggregate';
  aggregate?: Maybe<IMaintenanceAggregateFields>;
  nodes: Array<IMaintenance>;
};

/** aggregate fields of "maintenance" */
export type IMaintenanceAggregateFields = {
  __typename: 'maintenance_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IMaintenanceMaxFields>;
  min?: Maybe<IMaintenanceMinFields>;
};


/** aggregate fields of "maintenance" */
export type IMaintenanceAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IMaintenanceSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "maintenance". All fields are combined with a logical 'AND'. */
export type IMaintenanceBoolExp = {
  _and?: InputMaybe<Array<IMaintenanceBoolExp>>;
  _not?: InputMaybe<IMaintenanceBoolExp>;
  _or?: InputMaybe<Array<IMaintenanceBoolExp>>;
  archived_at?: InputMaybe<ITimestamptzComparisonExp>;
  create_individual_tasks_for_objects?: InputMaybe<IBooleanComparisonExp>;
  create_next_only_if_last_complete?: InputMaybe<IBooleanComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  paused?: InputMaybe<IBooleanComparisonExp>;
  tasks?: InputMaybe<IWorkOrderBoolExp>;
  tasks_aggregate?: InputMaybe<IWorkOrderAggregateBoolExp>;
  template?: InputMaybe<IWorkOrderBoolExp>;
  template_id?: InputMaybe<IUuidComparisonExp>;
  triggers?: InputMaybe<IMaintenanceTriggerBoolExp>;
  triggers_aggregate?: InputMaybe<IMaintenanceTriggerAggregateBoolExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "maintenance" */
export enum IMaintenanceConstraint {
  /** unique or primary key constraint on columns "id" */
  MaintenancePkey = 'maintenance_pkey'
}

/** input type for inserting data into table "maintenance" */
export type IMaintenanceInsertInput = {
  archived_at?: InputMaybe<Scalars['timestamptz']['input']>;
  create_individual_tasks_for_objects?: InputMaybe<Scalars['Boolean']['input']>;
  create_next_only_if_last_complete?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paused?: InputMaybe<Scalars['Boolean']['input']>;
  tasks?: InputMaybe<IWorkOrderArrRelInsertInput>;
  template?: InputMaybe<IWorkOrderObjRelInsertInput>;
  template_id?: InputMaybe<Scalars['uuid']['input']>;
  triggers?: InputMaybe<IMaintenanceTriggerArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type IMaintenanceMaxFields = {
  __typename: 'maintenance_max_fields';
  archived_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  template_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type IMaintenanceMinFields = {
  __typename: 'maintenance_min_fields';
  archived_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  template_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "maintenance" */
export type IMaintenanceMutationResponse = {
  __typename: 'maintenance_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IMaintenance>;
};

/** input type for inserting object relation for remote table "maintenance" */
export type IMaintenanceObjRelInsertInput = {
  data: IMaintenanceInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IMaintenanceOnConflict>;
};

/** on_conflict condition type for table "maintenance" */
export type IMaintenanceOnConflict = {
  constraint: IMaintenanceConstraint;
  update_columns?: Array<IMaintenanceUpdateColumn>;
  where?: InputMaybe<IMaintenanceBoolExp>;
};

/** Ordering options when selecting data from "maintenance". */
export type IMaintenanceOrderBy = {
  archived_at?: InputMaybe<IOrderBy>;
  create_individual_tasks_for_objects?: InputMaybe<IOrderBy>;
  create_next_only_if_last_complete?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  paused?: InputMaybe<IOrderBy>;
  tasks_aggregate?: InputMaybe<IWorkOrderAggregateOrderBy>;
  template?: InputMaybe<IWorkOrderOrderBy>;
  template_id?: InputMaybe<IOrderBy>;
  triggers_aggregate?: InputMaybe<IMaintenanceTriggerAggregateOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: maintenance */
export type IMaintenancePkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "maintenance" */
export enum IMaintenanceSelectColumn {
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  CreateIndividualTasksForObjects = 'create_individual_tasks_for_objects',
  /** column name */
  CreateNextOnlyIfLastComplete = 'create_next_only_if_last_complete',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  Paused = 'paused',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "maintenance" */
export type IMaintenanceSetInput = {
  archived_at?: InputMaybe<Scalars['timestamptz']['input']>;
  create_individual_tasks_for_objects?: InputMaybe<Scalars['Boolean']['input']>;
  create_next_only_if_last_complete?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paused?: InputMaybe<Scalars['Boolean']['input']>;
  template_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "maintenance" */
export type IMaintenanceStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IMaintenanceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IMaintenanceStreamCursorValueInput = {
  archived_at?: InputMaybe<Scalars['timestamptz']['input']>;
  create_individual_tasks_for_objects?: InputMaybe<Scalars['Boolean']['input']>;
  create_next_only_if_last_complete?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paused?: InputMaybe<Scalars['Boolean']['input']>;
  template_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Triggers for Scheduled Maintenance Tasks */
export type IMaintenanceTrigger = {
  __typename: 'maintenance_trigger';
  id: Scalars['uuid']['output'];
  /** An array relationship */
  instances: Array<IWorkOrder>;
  /** An aggregate relationship */
  instances_aggregate: IWorkOrderAggregate;
  /** An object relationship */
  maintenance?: Maybe<IMaintenance>;
  maintenance_id: Scalars['uuid']['output'];
  /** An object relationship */
  meter?: Maybe<IMeter>;
  meter_id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "trigger_meter_last_reading" */
  meter_last_reading?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "trigger_meter_unit" */
  meter_unit?: Maybe<Scalars['String']['output']>;
  payload?: Maybe<Scalars['jsonb']['output']>;
  type: IMaintenanceTriggerTypeEnum;
};


/** Triggers for Scheduled Maintenance Tasks */
export type IMaintenanceTriggerInstancesArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOrderBy>>;
  where?: InputMaybe<IWorkOrderBoolExp>;
};


/** Triggers for Scheduled Maintenance Tasks */
export type IMaintenanceTriggerInstancesAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOrderBy>>;
  where?: InputMaybe<IWorkOrderBoolExp>;
};


/** Triggers for Scheduled Maintenance Tasks */
export type IMaintenanceTriggerPayloadArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "maintenance_trigger" */
export type IMaintenanceTriggerAggregate = {
  __typename: 'maintenance_trigger_aggregate';
  aggregate?: Maybe<IMaintenanceTriggerAggregateFields>;
  nodes: Array<IMaintenanceTrigger>;
};

export type IMaintenanceTriggerAggregateBoolExp = {
  count?: InputMaybe<IMaintenanceTriggerAggregateBoolExpCount>;
};

export type IMaintenanceTriggerAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IMaintenanceTriggerSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IMaintenanceTriggerBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "maintenance_trigger" */
export type IMaintenanceTriggerAggregateFields = {
  __typename: 'maintenance_trigger_aggregate_fields';
  avg?: Maybe<IMaintenanceTriggerAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IMaintenanceTriggerMaxFields>;
  min?: Maybe<IMaintenanceTriggerMinFields>;
  stddev?: Maybe<IMaintenanceTriggerStddevFields>;
  stddev_pop?: Maybe<IMaintenanceTriggerStddevPopFields>;
  stddev_samp?: Maybe<IMaintenanceTriggerStddevSampFields>;
  sum?: Maybe<IMaintenanceTriggerSumFields>;
  var_pop?: Maybe<IMaintenanceTriggerVarPopFields>;
  var_samp?: Maybe<IMaintenanceTriggerVarSampFields>;
  variance?: Maybe<IMaintenanceTriggerVarianceFields>;
};


/** aggregate fields of "maintenance_trigger" */
export type IMaintenanceTriggerAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IMaintenanceTriggerSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "maintenance_trigger" */
export type IMaintenanceTriggerAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IMaintenanceTriggerMaxOrderBy>;
  min?: InputMaybe<IMaintenanceTriggerMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IMaintenanceTriggerAppendInput = {
  payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "maintenance_trigger" */
export type IMaintenanceTriggerArrRelInsertInput = {
  data: Array<IMaintenanceTriggerInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IMaintenanceTriggerOnConflict>;
};

/** aggregate avg on columns */
export type IMaintenanceTriggerAvgFields = {
  __typename: 'maintenance_trigger_avg_fields';
  /** A computed field, executes function "trigger_meter_last_reading" */
  meter_last_reading?: Maybe<Scalars['numeric']['output']>;
};

/** Boolean expression to filter rows from the table "maintenance_trigger". All fields are combined with a logical 'AND'. */
export type IMaintenanceTriggerBoolExp = {
  _and?: InputMaybe<Array<IMaintenanceTriggerBoolExp>>;
  _not?: InputMaybe<IMaintenanceTriggerBoolExp>;
  _or?: InputMaybe<Array<IMaintenanceTriggerBoolExp>>;
  id?: InputMaybe<IUuidComparisonExp>;
  instances?: InputMaybe<IWorkOrderBoolExp>;
  instances_aggregate?: InputMaybe<IWorkOrderAggregateBoolExp>;
  maintenance?: InputMaybe<IMaintenanceBoolExp>;
  maintenance_id?: InputMaybe<IUuidComparisonExp>;
  meter?: InputMaybe<IMeterBoolExp>;
  meter_id?: InputMaybe<IUuidComparisonExp>;
  meter_last_reading?: InputMaybe<INumericComparisonExp>;
  meter_unit?: InputMaybe<IStringComparisonExp>;
  payload?: InputMaybe<IJsonbComparisonExp>;
  type?: InputMaybe<IMaintenanceTriggerTypeEnumComparisonExp>;
};

/** unique or primary key constraints on table "maintenance_trigger" */
export enum IMaintenanceTriggerConstraint {
  /** unique or primary key constraint on columns "id" */
  MaintenanceTriggerPkey = 'maintenance_trigger_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IMaintenanceTriggerDeleteAtPathInput = {
  payload?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IMaintenanceTriggerDeleteElemInput = {
  payload?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IMaintenanceTriggerDeleteKeyInput = {
  payload?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "maintenance_trigger" */
export type IMaintenanceTriggerInsertInput = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  instances?: InputMaybe<IWorkOrderArrRelInsertInput>;
  maintenance?: InputMaybe<IMaintenanceObjRelInsertInput>;
  maintenance_id?: InputMaybe<Scalars['uuid']['input']>;
  meter?: InputMaybe<IMeterObjRelInsertInput>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<IMaintenanceTriggerTypeEnum>;
};

/** aggregate max on columns */
export type IMaintenanceTriggerMaxFields = {
  __typename: 'maintenance_trigger_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  maintenance_id?: Maybe<Scalars['uuid']['output']>;
  meter_id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "trigger_meter_last_reading" */
  meter_last_reading?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "trigger_meter_unit" */
  meter_unit?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "maintenance_trigger" */
export type IMaintenanceTriggerMaxOrderBy = {
  id?: InputMaybe<IOrderBy>;
  maintenance_id?: InputMaybe<IOrderBy>;
  meter_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IMaintenanceTriggerMinFields = {
  __typename: 'maintenance_trigger_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  maintenance_id?: Maybe<Scalars['uuid']['output']>;
  meter_id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "trigger_meter_last_reading" */
  meter_last_reading?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "trigger_meter_unit" */
  meter_unit?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "maintenance_trigger" */
export type IMaintenanceTriggerMinOrderBy = {
  id?: InputMaybe<IOrderBy>;
  maintenance_id?: InputMaybe<IOrderBy>;
  meter_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "maintenance_trigger" */
export type IMaintenanceTriggerMutationResponse = {
  __typename: 'maintenance_trigger_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IMaintenanceTrigger>;
};

/** input type for inserting object relation for remote table "maintenance_trigger" */
export type IMaintenanceTriggerObjRelInsertInput = {
  data: IMaintenanceTriggerInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IMaintenanceTriggerOnConflict>;
};

/** on_conflict condition type for table "maintenance_trigger" */
export type IMaintenanceTriggerOnConflict = {
  constraint: IMaintenanceTriggerConstraint;
  update_columns?: Array<IMaintenanceTriggerUpdateColumn>;
  where?: InputMaybe<IMaintenanceTriggerBoolExp>;
};

/** Ordering options when selecting data from "maintenance_trigger". */
export type IMaintenanceTriggerOrderBy = {
  id?: InputMaybe<IOrderBy>;
  instances_aggregate?: InputMaybe<IWorkOrderAggregateOrderBy>;
  maintenance?: InputMaybe<IMaintenanceOrderBy>;
  maintenance_id?: InputMaybe<IOrderBy>;
  meter?: InputMaybe<IMeterOrderBy>;
  meter_id?: InputMaybe<IOrderBy>;
  meter_last_reading?: InputMaybe<IOrderBy>;
  meter_unit?: InputMaybe<IOrderBy>;
  payload?: InputMaybe<IOrderBy>;
  type?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: maintenance_trigger */
export type IMaintenanceTriggerPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IMaintenanceTriggerPrependInput = {
  payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "maintenance_trigger" */
export enum IMaintenanceTriggerSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MaintenanceId = 'maintenance_id',
  /** column name */
  MeterId = 'meter_id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "maintenance_trigger" */
export type IMaintenanceTriggerSetInput = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  maintenance_id?: InputMaybe<Scalars['uuid']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<IMaintenanceTriggerTypeEnum>;
};

/** aggregate stddev on columns */
export type IMaintenanceTriggerStddevFields = {
  __typename: 'maintenance_trigger_stddev_fields';
  /** A computed field, executes function "trigger_meter_last_reading" */
  meter_last_reading?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate stddev_pop on columns */
export type IMaintenanceTriggerStddevPopFields = {
  __typename: 'maintenance_trigger_stddev_pop_fields';
  /** A computed field, executes function "trigger_meter_last_reading" */
  meter_last_reading?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate stddev_samp on columns */
export type IMaintenanceTriggerStddevSampFields = {
  __typename: 'maintenance_trigger_stddev_samp_fields';
  /** A computed field, executes function "trigger_meter_last_reading" */
  meter_last_reading?: Maybe<Scalars['numeric']['output']>;
};

/** Streaming cursor of the table "maintenance_trigger" */
export type IMaintenanceTriggerStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IMaintenanceTriggerStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IMaintenanceTriggerStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  maintenance_id?: InputMaybe<Scalars['uuid']['input']>;
  meter_id?: InputMaybe<Scalars['uuid']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<IMaintenanceTriggerTypeEnum>;
};

/** aggregate sum on columns */
export type IMaintenanceTriggerSumFields = {
  __typename: 'maintenance_trigger_sum_fields';
  /** A computed field, executes function "trigger_meter_last_reading" */
  meter_last_reading?: Maybe<Scalars['numeric']['output']>;
};

/** columns and relationships of "maintenance_trigger_task" */
export type IMaintenanceTriggerTask = {
  __typename: 'maintenance_trigger_task';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  maintenance_trigger?: Maybe<IMaintenanceTrigger>;
  maintenance_trigger_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  meter?: Maybe<IMeter>;
  meter_id: Scalars['uuid']['output'];
  meter_reading_id: Scalars['uuid']['output'];
  meter_reading_value?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  task: IWorkOrder;
  task_id: Scalars['uuid']['output'];
};

/** aggregated selection of "maintenance_trigger_task" */
export type IMaintenanceTriggerTaskAggregate = {
  __typename: 'maintenance_trigger_task_aggregate';
  aggregate?: Maybe<IMaintenanceTriggerTaskAggregateFields>;
  nodes: Array<IMaintenanceTriggerTask>;
};

export type IMaintenanceTriggerTaskAggregateBoolExp = {
  count?: InputMaybe<IMaintenanceTriggerTaskAggregateBoolExpCount>;
};

export type IMaintenanceTriggerTaskAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IMaintenanceTriggerTaskSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IMaintenanceTriggerTaskBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "maintenance_trigger_task" */
export type IMaintenanceTriggerTaskAggregateFields = {
  __typename: 'maintenance_trigger_task_aggregate_fields';
  avg?: Maybe<IMaintenanceTriggerTaskAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IMaintenanceTriggerTaskMaxFields>;
  min?: Maybe<IMaintenanceTriggerTaskMinFields>;
  stddev?: Maybe<IMaintenanceTriggerTaskStddevFields>;
  stddev_pop?: Maybe<IMaintenanceTriggerTaskStddevPopFields>;
  stddev_samp?: Maybe<IMaintenanceTriggerTaskStddevSampFields>;
  sum?: Maybe<IMaintenanceTriggerTaskSumFields>;
  var_pop?: Maybe<IMaintenanceTriggerTaskVarPopFields>;
  var_samp?: Maybe<IMaintenanceTriggerTaskVarSampFields>;
  variance?: Maybe<IMaintenanceTriggerTaskVarianceFields>;
};


/** aggregate fields of "maintenance_trigger_task" */
export type IMaintenanceTriggerTaskAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IMaintenanceTriggerTaskSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "maintenance_trigger_task" */
export type IMaintenanceTriggerTaskAggregateOrderBy = {
  avg?: InputMaybe<IMaintenanceTriggerTaskAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IMaintenanceTriggerTaskMaxOrderBy>;
  min?: InputMaybe<IMaintenanceTriggerTaskMinOrderBy>;
  stddev?: InputMaybe<IMaintenanceTriggerTaskStddevOrderBy>;
  stddev_pop?: InputMaybe<IMaintenanceTriggerTaskStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IMaintenanceTriggerTaskStddevSampOrderBy>;
  sum?: InputMaybe<IMaintenanceTriggerTaskSumOrderBy>;
  var_pop?: InputMaybe<IMaintenanceTriggerTaskVarPopOrderBy>;
  var_samp?: InputMaybe<IMaintenanceTriggerTaskVarSampOrderBy>;
  variance?: InputMaybe<IMaintenanceTriggerTaskVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "maintenance_trigger_task" */
export type IMaintenanceTriggerTaskArrRelInsertInput = {
  data: Array<IMaintenanceTriggerTaskInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IMaintenanceTriggerTaskOnConflict>;
};

/** aggregate avg on columns */
export type IMaintenanceTriggerTaskAvgFields = {
  __typename: 'maintenance_trigger_task_avg_fields';
  meter_reading_value?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "maintenance_trigger_task" */
export type IMaintenanceTriggerTaskAvgOrderBy = {
  meter_reading_value?: InputMaybe<IOrderBy>;
};

/** Boolean expression to filter rows from the table "maintenance_trigger_task". All fields are combined with a logical 'AND'. */
export type IMaintenanceTriggerTaskBoolExp = {
  _and?: InputMaybe<Array<IMaintenanceTriggerTaskBoolExp>>;
  _not?: InputMaybe<IMaintenanceTriggerTaskBoolExp>;
  _or?: InputMaybe<Array<IMaintenanceTriggerTaskBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  maintenance_trigger?: InputMaybe<IMaintenanceTriggerBoolExp>;
  maintenance_trigger_id?: InputMaybe<IUuidComparisonExp>;
  meter?: InputMaybe<IMeterBoolExp>;
  meter_id?: InputMaybe<IUuidComparisonExp>;
  meter_reading_id?: InputMaybe<IUuidComparisonExp>;
  meter_reading_value?: InputMaybe<INumericComparisonExp>;
  task?: InputMaybe<IWorkOrderBoolExp>;
  task_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "maintenance_trigger_task" */
export enum IMaintenanceTriggerTaskConstraint {
  /** unique or primary key constraint on columns "id" */
  MaintenanceTriggerTaskPkey = 'maintenance_trigger_task_pkey'
}

/** input type for incrementing numeric columns in table "maintenance_trigger_task" */
export type IMaintenanceTriggerTaskIncInput = {
  meter_reading_value?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "maintenance_trigger_task" */
export type IMaintenanceTriggerTaskInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  maintenance_trigger?: InputMaybe<IMaintenanceTriggerObjRelInsertInput>;
  maintenance_trigger_id?: InputMaybe<Scalars['uuid']['input']>;
  meter?: InputMaybe<IMeterObjRelInsertInput>;
  meter_id?: InputMaybe<Scalars['uuid']['input']>;
  meter_reading_id?: InputMaybe<Scalars['uuid']['input']>;
  meter_reading_value?: InputMaybe<Scalars['numeric']['input']>;
  task?: InputMaybe<IWorkOrderObjRelInsertInput>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IMaintenanceTriggerTaskMaxFields = {
  __typename: 'maintenance_trigger_task_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  maintenance_trigger_id?: Maybe<Scalars['uuid']['output']>;
  meter_id?: Maybe<Scalars['uuid']['output']>;
  meter_reading_id?: Maybe<Scalars['uuid']['output']>;
  meter_reading_value?: Maybe<Scalars['numeric']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "maintenance_trigger_task" */
export type IMaintenanceTriggerTaskMaxOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  maintenance_trigger_id?: InputMaybe<IOrderBy>;
  meter_id?: InputMaybe<IOrderBy>;
  meter_reading_id?: InputMaybe<IOrderBy>;
  meter_reading_value?: InputMaybe<IOrderBy>;
  task_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IMaintenanceTriggerTaskMinFields = {
  __typename: 'maintenance_trigger_task_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  maintenance_trigger_id?: Maybe<Scalars['uuid']['output']>;
  meter_id?: Maybe<Scalars['uuid']['output']>;
  meter_reading_id?: Maybe<Scalars['uuid']['output']>;
  meter_reading_value?: Maybe<Scalars['numeric']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "maintenance_trigger_task" */
export type IMaintenanceTriggerTaskMinOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  maintenance_trigger_id?: InputMaybe<IOrderBy>;
  meter_id?: InputMaybe<IOrderBy>;
  meter_reading_id?: InputMaybe<IOrderBy>;
  meter_reading_value?: InputMaybe<IOrderBy>;
  task_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "maintenance_trigger_task" */
export type IMaintenanceTriggerTaskMutationResponse = {
  __typename: 'maintenance_trigger_task_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IMaintenanceTriggerTask>;
};

/** on_conflict condition type for table "maintenance_trigger_task" */
export type IMaintenanceTriggerTaskOnConflict = {
  constraint: IMaintenanceTriggerTaskConstraint;
  update_columns?: Array<IMaintenanceTriggerTaskUpdateColumn>;
  where?: InputMaybe<IMaintenanceTriggerTaskBoolExp>;
};

/** Ordering options when selecting data from "maintenance_trigger_task". */
export type IMaintenanceTriggerTaskOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  maintenance_trigger?: InputMaybe<IMaintenanceTriggerOrderBy>;
  maintenance_trigger_id?: InputMaybe<IOrderBy>;
  meter?: InputMaybe<IMeterOrderBy>;
  meter_id?: InputMaybe<IOrderBy>;
  meter_reading_id?: InputMaybe<IOrderBy>;
  meter_reading_value?: InputMaybe<IOrderBy>;
  task?: InputMaybe<IWorkOrderOrderBy>;
  task_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: maintenance_trigger_task */
export type IMaintenanceTriggerTaskPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "maintenance_trigger_task" */
export enum IMaintenanceTriggerTaskSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MaintenanceTriggerId = 'maintenance_trigger_id',
  /** column name */
  MeterId = 'meter_id',
  /** column name */
  MeterReadingId = 'meter_reading_id',
  /** column name */
  MeterReadingValue = 'meter_reading_value',
  /** column name */
  TaskId = 'task_id'
}

/** input type for updating data in table "maintenance_trigger_task" */
export type IMaintenanceTriggerTaskSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  maintenance_trigger_id?: InputMaybe<Scalars['uuid']['input']>;
  meter_id?: InputMaybe<Scalars['uuid']['input']>;
  meter_reading_id?: InputMaybe<Scalars['uuid']['input']>;
  meter_reading_value?: InputMaybe<Scalars['numeric']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type IMaintenanceTriggerTaskStddevFields = {
  __typename: 'maintenance_trigger_task_stddev_fields';
  meter_reading_value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "maintenance_trigger_task" */
export type IMaintenanceTriggerTaskStddevOrderBy = {
  meter_reading_value?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_pop on columns */
export type IMaintenanceTriggerTaskStddevPopFields = {
  __typename: 'maintenance_trigger_task_stddev_pop_fields';
  meter_reading_value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "maintenance_trigger_task" */
export type IMaintenanceTriggerTaskStddevPopOrderBy = {
  meter_reading_value?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_samp on columns */
export type IMaintenanceTriggerTaskStddevSampFields = {
  __typename: 'maintenance_trigger_task_stddev_samp_fields';
  meter_reading_value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "maintenance_trigger_task" */
export type IMaintenanceTriggerTaskStddevSampOrderBy = {
  meter_reading_value?: InputMaybe<IOrderBy>;
};

/** Streaming cursor of the table "maintenance_trigger_task" */
export type IMaintenanceTriggerTaskStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IMaintenanceTriggerTaskStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IMaintenanceTriggerTaskStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  maintenance_trigger_id?: InputMaybe<Scalars['uuid']['input']>;
  meter_id?: InputMaybe<Scalars['uuid']['input']>;
  meter_reading_id?: InputMaybe<Scalars['uuid']['input']>;
  meter_reading_value?: InputMaybe<Scalars['numeric']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type IMaintenanceTriggerTaskSumFields = {
  __typename: 'maintenance_trigger_task_sum_fields';
  meter_reading_value?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "maintenance_trigger_task" */
export type IMaintenanceTriggerTaskSumOrderBy = {
  meter_reading_value?: InputMaybe<IOrderBy>;
};

/** update columns of table "maintenance_trigger_task" */
export enum IMaintenanceTriggerTaskUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MaintenanceTriggerId = 'maintenance_trigger_id',
  /** column name */
  MeterId = 'meter_id',
  /** column name */
  MeterReadingId = 'meter_reading_id',
  /** column name */
  MeterReadingValue = 'meter_reading_value',
  /** column name */
  TaskId = 'task_id'
}

export type IMaintenanceTriggerTaskUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IMaintenanceTriggerTaskIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IMaintenanceTriggerTaskSetInput>;
  /** filter the rows which have to be updated */
  where: IMaintenanceTriggerTaskBoolExp;
};

/** aggregate var_pop on columns */
export type IMaintenanceTriggerTaskVarPopFields = {
  __typename: 'maintenance_trigger_task_var_pop_fields';
  meter_reading_value?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "maintenance_trigger_task" */
export type IMaintenanceTriggerTaskVarPopOrderBy = {
  meter_reading_value?: InputMaybe<IOrderBy>;
};

/** aggregate var_samp on columns */
export type IMaintenanceTriggerTaskVarSampFields = {
  __typename: 'maintenance_trigger_task_var_samp_fields';
  meter_reading_value?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "maintenance_trigger_task" */
export type IMaintenanceTriggerTaskVarSampOrderBy = {
  meter_reading_value?: InputMaybe<IOrderBy>;
};

/** aggregate variance on columns */
export type IMaintenanceTriggerTaskVarianceFields = {
  __typename: 'maintenance_trigger_task_variance_fields';
  meter_reading_value?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "maintenance_trigger_task" */
export type IMaintenanceTriggerTaskVarianceOrderBy = {
  meter_reading_value?: InputMaybe<IOrderBy>;
};

/** Types of Triggers for Scheduled Maintenance Tasks */
export type IMaintenanceTriggerType = {
  __typename: 'maintenance_trigger_type';
  name: Scalars['String']['output'];
};

/** aggregated selection of "maintenance_trigger_type" */
export type IMaintenanceTriggerTypeAggregate = {
  __typename: 'maintenance_trigger_type_aggregate';
  aggregate?: Maybe<IMaintenanceTriggerTypeAggregateFields>;
  nodes: Array<IMaintenanceTriggerType>;
};

/** aggregate fields of "maintenance_trigger_type" */
export type IMaintenanceTriggerTypeAggregateFields = {
  __typename: 'maintenance_trigger_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IMaintenanceTriggerTypeMaxFields>;
  min?: Maybe<IMaintenanceTriggerTypeMinFields>;
};


/** aggregate fields of "maintenance_trigger_type" */
export type IMaintenanceTriggerTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IMaintenanceTriggerTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "maintenance_trigger_type". All fields are combined with a logical 'AND'. */
export type IMaintenanceTriggerTypeBoolExp = {
  _and?: InputMaybe<Array<IMaintenanceTriggerTypeBoolExp>>;
  _not?: InputMaybe<IMaintenanceTriggerTypeBoolExp>;
  _or?: InputMaybe<Array<IMaintenanceTriggerTypeBoolExp>>;
  name?: InputMaybe<IStringComparisonExp>;
};

/** unique or primary key constraints on table "maintenance_trigger_type" */
export enum IMaintenanceTriggerTypeConstraint {
  /** unique or primary key constraint on columns "name" */
  MaintenanceTriggerTypePkey = 'maintenance_trigger_type_pkey'
}

export enum IMaintenanceTriggerTypeEnum {
  Meter = 'meter',
  Time = 'time'
}

/** Boolean expression to compare columns of type "maintenance_trigger_type_enum". All fields are combined with logical 'AND'. */
export type IMaintenanceTriggerTypeEnumComparisonExp = {
  _eq?: InputMaybe<IMaintenanceTriggerTypeEnum>;
  _in?: InputMaybe<Array<IMaintenanceTriggerTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<IMaintenanceTriggerTypeEnum>;
  _nin?: InputMaybe<Array<IMaintenanceTriggerTypeEnum>>;
};

/** input type for inserting data into table "maintenance_trigger_type" */
export type IMaintenanceTriggerTypeInsertInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type IMaintenanceTriggerTypeMaxFields = {
  __typename: 'maintenance_trigger_type_max_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IMaintenanceTriggerTypeMinFields = {
  __typename: 'maintenance_trigger_type_min_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "maintenance_trigger_type" */
export type IMaintenanceTriggerTypeMutationResponse = {
  __typename: 'maintenance_trigger_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IMaintenanceTriggerType>;
};

/** on_conflict condition type for table "maintenance_trigger_type" */
export type IMaintenanceTriggerTypeOnConflict = {
  constraint: IMaintenanceTriggerTypeConstraint;
  update_columns?: Array<IMaintenanceTriggerTypeUpdateColumn>;
  where?: InputMaybe<IMaintenanceTriggerTypeBoolExp>;
};

/** Ordering options when selecting data from "maintenance_trigger_type". */
export type IMaintenanceTriggerTypeOrderBy = {
  name?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: maintenance_trigger_type */
export type IMaintenanceTriggerTypePkColumnsInput = {
  name: Scalars['String']['input'];
};

/** select columns of table "maintenance_trigger_type" */
export enum IMaintenanceTriggerTypeSelectColumn {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "maintenance_trigger_type" */
export type IMaintenanceTriggerTypeSetInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "maintenance_trigger_type" */
export type IMaintenanceTriggerTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IMaintenanceTriggerTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IMaintenanceTriggerTypeStreamCursorValueInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "maintenance_trigger_type" */
export enum IMaintenanceTriggerTypeUpdateColumn {
  /** column name */
  Name = 'name'
}

export type IMaintenanceTriggerTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IMaintenanceTriggerTypeSetInput>;
  /** filter the rows which have to be updated */
  where: IMaintenanceTriggerTypeBoolExp;
};

/** update columns of table "maintenance_trigger" */
export enum IMaintenanceTriggerUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MaintenanceId = 'maintenance_id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Type = 'type'
}

export type IMaintenanceTriggerUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IMaintenanceTriggerAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IMaintenanceTriggerDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IMaintenanceTriggerDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IMaintenanceTriggerDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IMaintenanceTriggerPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IMaintenanceTriggerSetInput>;
  /** filter the rows which have to be updated */
  where: IMaintenanceTriggerBoolExp;
};

/** aggregate var_pop on columns */
export type IMaintenanceTriggerVarPopFields = {
  __typename: 'maintenance_trigger_var_pop_fields';
  /** A computed field, executes function "trigger_meter_last_reading" */
  meter_last_reading?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate var_samp on columns */
export type IMaintenanceTriggerVarSampFields = {
  __typename: 'maintenance_trigger_var_samp_fields';
  /** A computed field, executes function "trigger_meter_last_reading" */
  meter_last_reading?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate variance on columns */
export type IMaintenanceTriggerVarianceFields = {
  __typename: 'maintenance_trigger_variance_fields';
  /** A computed field, executes function "trigger_meter_last_reading" */
  meter_last_reading?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "maintenance" */
export enum IMaintenanceUpdateColumn {
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  CreateIndividualTasksForObjects = 'create_individual_tasks_for_objects',
  /** column name */
  CreateNextOnlyIfLastComplete = 'create_next_only_if_last_complete',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  Paused = 'paused',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type IMaintenanceUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IMaintenanceSetInput>;
  /** filter the rows which have to be updated */
  where: IMaintenanceBoolExp;
};

/** columns and relationships of "meter" */
export type IMeter = {
  __typename: 'meter';
  /** An object relationship */
  asset: IAsset;
  asset_id: Scalars['uuid']['output'];
  /** A computed field, executes function "create_scheduled_interval_task_at_computed" */
  create_scheduled_interval_task_at?: Maybe<Scalars['numeric']['output']>;
  created_at: Scalars['timestamptz']['output'];
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  first_interval_task_at?: Maybe<Scalars['numeric']['output']>;
  id: Scalars['uuid']['output'];
  interval?: Maybe<Scalars['numeric']['output']>;
  is_deviation?: Maybe<Scalars['Boolean']['output']>;
  location_id: Scalars['uuid']['output'];
  /** An array relationship */
  maintenance_triggers: Array<IMaintenanceTrigger>;
  /** An aggregate relationship */
  maintenance_triggers_aggregate: IMaintenanceTriggerAggregate;
  /** An array relationship */
  meter_readings: Array<IMeterReading>;
  /** An aggregate relationship */
  meter_readings_aggregate: IMeterReadingAggregate;
  meter_type?: Maybe<IMeterTypeEnum>;
  name: Scalars['String']['output'];
  norm_value?: Maybe<Scalars['numeric']['output']>;
  range_end?: Maybe<Scalars['numeric']['output']>;
  range_start?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  template?: Maybe<IWorkOrderTemplate>;
  template_id?: Maybe<Scalars['uuid']['output']>;
  unit: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "meter" */
export type IMeterMaintenanceTriggersArgs = {
  distinct_on?: InputMaybe<Array<IMaintenanceTriggerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMaintenanceTriggerOrderBy>>;
  where?: InputMaybe<IMaintenanceTriggerBoolExp>;
};


/** columns and relationships of "meter" */
export type IMeterMaintenanceTriggersAggregateArgs = {
  distinct_on?: InputMaybe<Array<IMaintenanceTriggerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMaintenanceTriggerOrderBy>>;
  where?: InputMaybe<IMaintenanceTriggerBoolExp>;
};


/** columns and relationships of "meter" */
export type IMeterMeterReadingsArgs = {
  distinct_on?: InputMaybe<Array<IMeterReadingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMeterReadingOrderBy>>;
  where?: InputMaybe<IMeterReadingBoolExp>;
};


/** columns and relationships of "meter" */
export type IMeterMeterReadingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IMeterReadingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMeterReadingOrderBy>>;
  where?: InputMaybe<IMeterReadingBoolExp>;
};

/** aggregated selection of "meter" */
export type IMeterAggregate = {
  __typename: 'meter_aggregate';
  aggregate?: Maybe<IMeterAggregateFields>;
  nodes: Array<IMeter>;
};

export type IMeterAggregateBoolExp = {
  bool_and?: InputMaybe<IMeterAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<IMeterAggregateBoolExpBoolOr>;
  count?: InputMaybe<IMeterAggregateBoolExpCount>;
};

export type IMeterAggregateBoolExpBoolAnd = {
  arguments: IMeterSelectColumnMeterAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IMeterBoolExp>;
  predicate: IBooleanComparisonExp;
};

export type IMeterAggregateBoolExpBoolOr = {
  arguments: IMeterSelectColumnMeterAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IMeterBoolExp>;
  predicate: IBooleanComparisonExp;
};

export type IMeterAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IMeterSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IMeterBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "meter" */
export type IMeterAggregateFields = {
  __typename: 'meter_aggregate_fields';
  avg?: Maybe<IMeterAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IMeterMaxFields>;
  min?: Maybe<IMeterMinFields>;
  stddev?: Maybe<IMeterStddevFields>;
  stddev_pop?: Maybe<IMeterStddevPopFields>;
  stddev_samp?: Maybe<IMeterStddevSampFields>;
  sum?: Maybe<IMeterSumFields>;
  var_pop?: Maybe<IMeterVarPopFields>;
  var_samp?: Maybe<IMeterVarSampFields>;
  variance?: Maybe<IMeterVarianceFields>;
};


/** aggregate fields of "meter" */
export type IMeterAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IMeterSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "meter" */
export type IMeterAggregateOrderBy = {
  avg?: InputMaybe<IMeterAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IMeterMaxOrderBy>;
  min?: InputMaybe<IMeterMinOrderBy>;
  stddev?: InputMaybe<IMeterStddevOrderBy>;
  stddev_pop?: InputMaybe<IMeterStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IMeterStddevSampOrderBy>;
  sum?: InputMaybe<IMeterSumOrderBy>;
  var_pop?: InputMaybe<IMeterVarPopOrderBy>;
  var_samp?: InputMaybe<IMeterVarSampOrderBy>;
  variance?: InputMaybe<IMeterVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "meter" */
export type IMeterArrRelInsertInput = {
  data: Array<IMeterInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IMeterOnConflict>;
};

/** aggregate avg on columns */
export type IMeterAvgFields = {
  __typename: 'meter_avg_fields';
  /** A computed field, executes function "create_scheduled_interval_task_at_computed" */
  create_scheduled_interval_task_at?: Maybe<Scalars['numeric']['output']>;
  first_interval_task_at?: Maybe<Scalars['Float']['output']>;
  interval?: Maybe<Scalars['Float']['output']>;
  norm_value?: Maybe<Scalars['Float']['output']>;
  range_end?: Maybe<Scalars['Float']['output']>;
  range_start?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "meter" */
export type IMeterAvgOrderBy = {
  first_interval_task_at?: InputMaybe<IOrderBy>;
  interval?: InputMaybe<IOrderBy>;
  norm_value?: InputMaybe<IOrderBy>;
  range_end?: InputMaybe<IOrderBy>;
  range_start?: InputMaybe<IOrderBy>;
};

/** Boolean expression to filter rows from the table "meter". All fields are combined with a logical 'AND'. */
export type IMeterBoolExp = {
  _and?: InputMaybe<Array<IMeterBoolExp>>;
  _not?: InputMaybe<IMeterBoolExp>;
  _or?: InputMaybe<Array<IMeterBoolExp>>;
  asset?: InputMaybe<IAssetBoolExp>;
  asset_id?: InputMaybe<IUuidComparisonExp>;
  create_scheduled_interval_task_at?: InputMaybe<INumericComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  created_by_id?: InputMaybe<IUuidComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  first_interval_task_at?: InputMaybe<INumericComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  interval?: InputMaybe<INumericComparisonExp>;
  is_deviation?: InputMaybe<IBooleanComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  maintenance_triggers?: InputMaybe<IMaintenanceTriggerBoolExp>;
  maintenance_triggers_aggregate?: InputMaybe<IMaintenanceTriggerAggregateBoolExp>;
  meter_readings?: InputMaybe<IMeterReadingBoolExp>;
  meter_readings_aggregate?: InputMaybe<IMeterReadingAggregateBoolExp>;
  meter_type?: InputMaybe<IMeterTypeEnumComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  norm_value?: InputMaybe<INumericComparisonExp>;
  range_end?: InputMaybe<INumericComparisonExp>;
  range_start?: InputMaybe<INumericComparisonExp>;
  template?: InputMaybe<IWorkOrderTemplateBoolExp>;
  template_id?: InputMaybe<IUuidComparisonExp>;
  unit?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "meter" */
export enum IMeterConstraint {
  /** unique or primary key constraint on columns "asset_id", "unit", "name" */
  MeterNameUnitAssetIdKey = 'meter_name_unit_asset_id_key',
  /** unique or primary key constraint on columns "id" */
  MeterPkey = 'meter_pkey'
}

/** input type for incrementing numeric columns in table "meter" */
export type IMeterIncInput = {
  first_interval_task_at?: InputMaybe<Scalars['numeric']['input']>;
  interval?: InputMaybe<Scalars['numeric']['input']>;
  norm_value?: InputMaybe<Scalars['numeric']['input']>;
  range_end?: InputMaybe<Scalars['numeric']['input']>;
  range_start?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "meter" */
export type IMeterInsertInput = {
  asset?: InputMaybe<IAssetObjRelInsertInput>;
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  first_interval_task_at?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  interval?: InputMaybe<Scalars['numeric']['input']>;
  is_deviation?: InputMaybe<Scalars['Boolean']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  maintenance_triggers?: InputMaybe<IMaintenanceTriggerArrRelInsertInput>;
  meter_readings?: InputMaybe<IMeterReadingArrRelInsertInput>;
  meter_type?: InputMaybe<IMeterTypeEnum>;
  name?: InputMaybe<Scalars['String']['input']>;
  norm_value?: InputMaybe<Scalars['numeric']['input']>;
  range_end?: InputMaybe<Scalars['numeric']['input']>;
  range_start?: InputMaybe<Scalars['numeric']['input']>;
  template?: InputMaybe<IWorkOrderTemplateObjRelInsertInput>;
  template_id?: InputMaybe<Scalars['uuid']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type IMeterMaxFields = {
  __typename: 'meter_max_fields';
  asset_id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "create_scheduled_interval_task_at_computed" */
  create_scheduled_interval_task_at?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  first_interval_task_at?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  interval?: Maybe<Scalars['numeric']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  norm_value?: Maybe<Scalars['numeric']['output']>;
  range_end?: Maybe<Scalars['numeric']['output']>;
  range_start?: Maybe<Scalars['numeric']['output']>;
  template_id?: Maybe<Scalars['uuid']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "meter" */
export type IMeterMaxOrderBy = {
  asset_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  first_interval_task_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  interval?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  norm_value?: InputMaybe<IOrderBy>;
  range_end?: InputMaybe<IOrderBy>;
  range_start?: InputMaybe<IOrderBy>;
  template_id?: InputMaybe<IOrderBy>;
  unit?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IMeterMinFields = {
  __typename: 'meter_min_fields';
  asset_id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "create_scheduled_interval_task_at_computed" */
  create_scheduled_interval_task_at?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  first_interval_task_at?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  interval?: Maybe<Scalars['numeric']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  norm_value?: Maybe<Scalars['numeric']['output']>;
  range_end?: Maybe<Scalars['numeric']['output']>;
  range_start?: Maybe<Scalars['numeric']['output']>;
  template_id?: Maybe<Scalars['uuid']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "meter" */
export type IMeterMinOrderBy = {
  asset_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  first_interval_task_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  interval?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  norm_value?: InputMaybe<IOrderBy>;
  range_end?: InputMaybe<IOrderBy>;
  range_start?: InputMaybe<IOrderBy>;
  template_id?: InputMaybe<IOrderBy>;
  unit?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "meter" */
export type IMeterMutationResponse = {
  __typename: 'meter_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IMeter>;
};

/** input type for inserting object relation for remote table "meter" */
export type IMeterObjRelInsertInput = {
  data: IMeterInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IMeterOnConflict>;
};

/** on_conflict condition type for table "meter" */
export type IMeterOnConflict = {
  constraint: IMeterConstraint;
  update_columns?: Array<IMeterUpdateColumn>;
  where?: InputMaybe<IMeterBoolExp>;
};

/** Ordering options when selecting data from "meter". */
export type IMeterOrderBy = {
  asset?: InputMaybe<IAssetOrderBy>;
  asset_id?: InputMaybe<IOrderBy>;
  create_scheduled_interval_task_at?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  first_interval_task_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  interval?: InputMaybe<IOrderBy>;
  is_deviation?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  maintenance_triggers_aggregate?: InputMaybe<IMaintenanceTriggerAggregateOrderBy>;
  meter_readings_aggregate?: InputMaybe<IMeterReadingAggregateOrderBy>;
  meter_type?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  norm_value?: InputMaybe<IOrderBy>;
  range_end?: InputMaybe<IOrderBy>;
  range_start?: InputMaybe<IOrderBy>;
  template?: InputMaybe<IWorkOrderTemplateOrderBy>;
  template_id?: InputMaybe<IOrderBy>;
  unit?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: meter */
export type IMeterPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** columns and relationships of "meter_reading" */
export type IMeterReading = {
  __typename: 'meter_reading';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  measured_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  measured_by: IUser;
  measured_by_id: Scalars['uuid']['output'];
  /** An object relationship */
  meter: IMeter;
  meter_id: Scalars['uuid']['output'];
  /** An array relationship */
  triggered_maintenance_task: Array<IMaintenanceTriggerTask>;
  /** An aggregate relationship */
  triggered_maintenance_task_aggregate: IMaintenanceTriggerTaskAggregate;
  updated_at: Scalars['timestamptz']['output'];
  value: Scalars['numeric']['output'];
};


/** columns and relationships of "meter_reading" */
export type IMeterReadingTriggeredMaintenanceTaskArgs = {
  distinct_on?: InputMaybe<Array<IMaintenanceTriggerTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMaintenanceTriggerTaskOrderBy>>;
  where?: InputMaybe<IMaintenanceTriggerTaskBoolExp>;
};


/** columns and relationships of "meter_reading" */
export type IMeterReadingTriggeredMaintenanceTaskAggregateArgs = {
  distinct_on?: InputMaybe<Array<IMaintenanceTriggerTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMaintenanceTriggerTaskOrderBy>>;
  where?: InputMaybe<IMaintenanceTriggerTaskBoolExp>;
};

/** aggregated selection of "meter_reading" */
export type IMeterReadingAggregate = {
  __typename: 'meter_reading_aggregate';
  aggregate?: Maybe<IMeterReadingAggregateFields>;
  nodes: Array<IMeterReading>;
};

export type IMeterReadingAggregateBoolExp = {
  count?: InputMaybe<IMeterReadingAggregateBoolExpCount>;
};

export type IMeterReadingAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IMeterReadingSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IMeterReadingBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "meter_reading" */
export type IMeterReadingAggregateFields = {
  __typename: 'meter_reading_aggregate_fields';
  avg?: Maybe<IMeterReadingAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IMeterReadingMaxFields>;
  min?: Maybe<IMeterReadingMinFields>;
  stddev?: Maybe<IMeterReadingStddevFields>;
  stddev_pop?: Maybe<IMeterReadingStddevPopFields>;
  stddev_samp?: Maybe<IMeterReadingStddevSampFields>;
  sum?: Maybe<IMeterReadingSumFields>;
  var_pop?: Maybe<IMeterReadingVarPopFields>;
  var_samp?: Maybe<IMeterReadingVarSampFields>;
  variance?: Maybe<IMeterReadingVarianceFields>;
};


/** aggregate fields of "meter_reading" */
export type IMeterReadingAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IMeterReadingSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "meter_reading" */
export type IMeterReadingAggregateOrderBy = {
  avg?: InputMaybe<IMeterReadingAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IMeterReadingMaxOrderBy>;
  min?: InputMaybe<IMeterReadingMinOrderBy>;
  stddev?: InputMaybe<IMeterReadingStddevOrderBy>;
  stddev_pop?: InputMaybe<IMeterReadingStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IMeterReadingStddevSampOrderBy>;
  sum?: InputMaybe<IMeterReadingSumOrderBy>;
  var_pop?: InputMaybe<IMeterReadingVarPopOrderBy>;
  var_samp?: InputMaybe<IMeterReadingVarSampOrderBy>;
  variance?: InputMaybe<IMeterReadingVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "meter_reading" */
export type IMeterReadingArrRelInsertInput = {
  data: Array<IMeterReadingInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IMeterReadingOnConflict>;
};

/** aggregate avg on columns */
export type IMeterReadingAvgFields = {
  __typename: 'meter_reading_avg_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "meter_reading" */
export type IMeterReadingAvgOrderBy = {
  value?: InputMaybe<IOrderBy>;
};

/** Boolean expression to filter rows from the table "meter_reading". All fields are combined with a logical 'AND'. */
export type IMeterReadingBoolExp = {
  _and?: InputMaybe<Array<IMeterReadingBoolExp>>;
  _not?: InputMaybe<IMeterReadingBoolExp>;
  _or?: InputMaybe<Array<IMeterReadingBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  measured_at?: InputMaybe<ITimestamptzComparisonExp>;
  measured_by?: InputMaybe<IUserBoolExp>;
  measured_by_id?: InputMaybe<IUuidComparisonExp>;
  meter?: InputMaybe<IMeterBoolExp>;
  meter_id?: InputMaybe<IUuidComparisonExp>;
  triggered_maintenance_task?: InputMaybe<IMaintenanceTriggerTaskBoolExp>;
  triggered_maintenance_task_aggregate?: InputMaybe<IMaintenanceTriggerTaskAggregateBoolExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  value?: InputMaybe<INumericComparisonExp>;
};

/** unique or primary key constraints on table "meter_reading" */
export enum IMeterReadingConstraint {
  /** unique or primary key constraint on columns "id" */
  MeterReadingPkey = 'meter_reading_pkey'
}

/** input type for incrementing numeric columns in table "meter_reading" */
export type IMeterReadingIncInput = {
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "meter_reading" */
export type IMeterReadingInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measured_at?: InputMaybe<Scalars['timestamptz']['input']>;
  measured_by?: InputMaybe<IUserObjRelInsertInput>;
  measured_by_id?: InputMaybe<Scalars['uuid']['input']>;
  meter?: InputMaybe<IMeterObjRelInsertInput>;
  meter_id?: InputMaybe<Scalars['uuid']['input']>;
  triggered_maintenance_task?: InputMaybe<IMaintenanceTriggerTaskArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type IMeterReadingMaxFields = {
  __typename: 'meter_reading_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  measured_at?: Maybe<Scalars['timestamptz']['output']>;
  measured_by_id?: Maybe<Scalars['uuid']['output']>;
  meter_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  value?: Maybe<Scalars['numeric']['output']>;
};

/** order by max() on columns of table "meter_reading" */
export type IMeterReadingMaxOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  measured_at?: InputMaybe<IOrderBy>;
  measured_by_id?: InputMaybe<IOrderBy>;
  meter_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  value?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IMeterReadingMinFields = {
  __typename: 'meter_reading_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  measured_at?: Maybe<Scalars['timestamptz']['output']>;
  measured_by_id?: Maybe<Scalars['uuid']['output']>;
  meter_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  value?: Maybe<Scalars['numeric']['output']>;
};

/** order by min() on columns of table "meter_reading" */
export type IMeterReadingMinOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  measured_at?: InputMaybe<IOrderBy>;
  measured_by_id?: InputMaybe<IOrderBy>;
  meter_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  value?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "meter_reading" */
export type IMeterReadingMutationResponse = {
  __typename: 'meter_reading_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IMeterReading>;
};

/** on_conflict condition type for table "meter_reading" */
export type IMeterReadingOnConflict = {
  constraint: IMeterReadingConstraint;
  update_columns?: Array<IMeterReadingUpdateColumn>;
  where?: InputMaybe<IMeterReadingBoolExp>;
};

/** Ordering options when selecting data from "meter_reading". */
export type IMeterReadingOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  measured_at?: InputMaybe<IOrderBy>;
  measured_by?: InputMaybe<IUserOrderBy>;
  measured_by_id?: InputMaybe<IOrderBy>;
  meter?: InputMaybe<IMeterOrderBy>;
  meter_id?: InputMaybe<IOrderBy>;
  triggered_maintenance_task_aggregate?: InputMaybe<IMaintenanceTriggerTaskAggregateOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  value?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: meter_reading */
export type IMeterReadingPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "meter_reading" */
export enum IMeterReadingSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MeasuredAt = 'measured_at',
  /** column name */
  MeasuredById = 'measured_by_id',
  /** column name */
  MeterId = 'meter_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "meter_reading" */
export type IMeterReadingSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measured_at?: InputMaybe<Scalars['timestamptz']['input']>;
  measured_by_id?: InputMaybe<Scalars['uuid']['input']>;
  meter_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type IMeterReadingStddevFields = {
  __typename: 'meter_reading_stddev_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "meter_reading" */
export type IMeterReadingStddevOrderBy = {
  value?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_pop on columns */
export type IMeterReadingStddevPopFields = {
  __typename: 'meter_reading_stddev_pop_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "meter_reading" */
export type IMeterReadingStddevPopOrderBy = {
  value?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_samp on columns */
export type IMeterReadingStddevSampFields = {
  __typename: 'meter_reading_stddev_samp_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "meter_reading" */
export type IMeterReadingStddevSampOrderBy = {
  value?: InputMaybe<IOrderBy>;
};

/** Streaming cursor of the table "meter_reading" */
export type IMeterReadingStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IMeterReadingStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IMeterReadingStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measured_at?: InputMaybe<Scalars['timestamptz']['input']>;
  measured_by_id?: InputMaybe<Scalars['uuid']['input']>;
  meter_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type IMeterReadingSumFields = {
  __typename: 'meter_reading_sum_fields';
  value?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "meter_reading" */
export type IMeterReadingSumOrderBy = {
  value?: InputMaybe<IOrderBy>;
};

/** update columns of table "meter_reading" */
export enum IMeterReadingUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MeasuredAt = 'measured_at',
  /** column name */
  MeasuredById = 'measured_by_id',
  /** column name */
  MeterId = 'meter_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type IMeterReadingUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IMeterReadingIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IMeterReadingSetInput>;
  /** filter the rows which have to be updated */
  where: IMeterReadingBoolExp;
};

/** aggregate var_pop on columns */
export type IMeterReadingVarPopFields = {
  __typename: 'meter_reading_var_pop_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "meter_reading" */
export type IMeterReadingVarPopOrderBy = {
  value?: InputMaybe<IOrderBy>;
};

/** aggregate var_samp on columns */
export type IMeterReadingVarSampFields = {
  __typename: 'meter_reading_var_samp_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "meter_reading" */
export type IMeterReadingVarSampOrderBy = {
  value?: InputMaybe<IOrderBy>;
};

/** aggregate variance on columns */
export type IMeterReadingVarianceFields = {
  __typename: 'meter_reading_variance_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "meter_reading" */
export type IMeterReadingVarianceOrderBy = {
  value?: InputMaybe<IOrderBy>;
};

/** select columns of table "meter" */
export enum IMeterSelectColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Description = 'description',
  /** column name */
  FirstIntervalTaskAt = 'first_interval_task_at',
  /** column name */
  Id = 'id',
  /** column name */
  Interval = 'interval',
  /** column name */
  IsDeviation = 'is_deviation',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  MeterType = 'meter_type',
  /** column name */
  Name = 'name',
  /** column name */
  NormValue = 'norm_value',
  /** column name */
  RangeEnd = 'range_end',
  /** column name */
  RangeStart = 'range_start',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  Unit = 'unit',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "meter_aggregate_bool_exp_bool_and_arguments_columns" columns of table "meter" */
export enum IMeterSelectColumnMeterAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDeviation = 'is_deviation'
}

/** select "meter_aggregate_bool_exp_bool_or_arguments_columns" columns of table "meter" */
export enum IMeterSelectColumnMeterAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDeviation = 'is_deviation'
}

/** input type for updating data in table "meter" */
export type IMeterSetInput = {
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  first_interval_task_at?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  interval?: InputMaybe<Scalars['numeric']['input']>;
  is_deviation?: InputMaybe<Scalars['Boolean']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  meter_type?: InputMaybe<IMeterTypeEnum>;
  name?: InputMaybe<Scalars['String']['input']>;
  norm_value?: InputMaybe<Scalars['numeric']['input']>;
  range_end?: InputMaybe<Scalars['numeric']['input']>;
  range_start?: InputMaybe<Scalars['numeric']['input']>;
  template_id?: InputMaybe<Scalars['uuid']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type IMeterStddevFields = {
  __typename: 'meter_stddev_fields';
  /** A computed field, executes function "create_scheduled_interval_task_at_computed" */
  create_scheduled_interval_task_at?: Maybe<Scalars['numeric']['output']>;
  first_interval_task_at?: Maybe<Scalars['Float']['output']>;
  interval?: Maybe<Scalars['Float']['output']>;
  norm_value?: Maybe<Scalars['Float']['output']>;
  range_end?: Maybe<Scalars['Float']['output']>;
  range_start?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "meter" */
export type IMeterStddevOrderBy = {
  first_interval_task_at?: InputMaybe<IOrderBy>;
  interval?: InputMaybe<IOrderBy>;
  norm_value?: InputMaybe<IOrderBy>;
  range_end?: InputMaybe<IOrderBy>;
  range_start?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_pop on columns */
export type IMeterStddevPopFields = {
  __typename: 'meter_stddev_pop_fields';
  /** A computed field, executes function "create_scheduled_interval_task_at_computed" */
  create_scheduled_interval_task_at?: Maybe<Scalars['numeric']['output']>;
  first_interval_task_at?: Maybe<Scalars['Float']['output']>;
  interval?: Maybe<Scalars['Float']['output']>;
  norm_value?: Maybe<Scalars['Float']['output']>;
  range_end?: Maybe<Scalars['Float']['output']>;
  range_start?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "meter" */
export type IMeterStddevPopOrderBy = {
  first_interval_task_at?: InputMaybe<IOrderBy>;
  interval?: InputMaybe<IOrderBy>;
  norm_value?: InputMaybe<IOrderBy>;
  range_end?: InputMaybe<IOrderBy>;
  range_start?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_samp on columns */
export type IMeterStddevSampFields = {
  __typename: 'meter_stddev_samp_fields';
  /** A computed field, executes function "create_scheduled_interval_task_at_computed" */
  create_scheduled_interval_task_at?: Maybe<Scalars['numeric']['output']>;
  first_interval_task_at?: Maybe<Scalars['Float']['output']>;
  interval?: Maybe<Scalars['Float']['output']>;
  norm_value?: Maybe<Scalars['Float']['output']>;
  range_end?: Maybe<Scalars['Float']['output']>;
  range_start?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "meter" */
export type IMeterStddevSampOrderBy = {
  first_interval_task_at?: InputMaybe<IOrderBy>;
  interval?: InputMaybe<IOrderBy>;
  norm_value?: InputMaybe<IOrderBy>;
  range_end?: InputMaybe<IOrderBy>;
  range_start?: InputMaybe<IOrderBy>;
};

/** Streaming cursor of the table "meter" */
export type IMeterStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IMeterStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IMeterStreamCursorValueInput = {
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  first_interval_task_at?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  interval?: InputMaybe<Scalars['numeric']['input']>;
  is_deviation?: InputMaybe<Scalars['Boolean']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  meter_type?: InputMaybe<IMeterTypeEnum>;
  name?: InputMaybe<Scalars['String']['input']>;
  norm_value?: InputMaybe<Scalars['numeric']['input']>;
  range_end?: InputMaybe<Scalars['numeric']['input']>;
  range_start?: InputMaybe<Scalars['numeric']['input']>;
  template_id?: InputMaybe<Scalars['uuid']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type IMeterSumFields = {
  __typename: 'meter_sum_fields';
  /** A computed field, executes function "create_scheduled_interval_task_at_computed" */
  create_scheduled_interval_task_at?: Maybe<Scalars['numeric']['output']>;
  first_interval_task_at?: Maybe<Scalars['numeric']['output']>;
  interval?: Maybe<Scalars['numeric']['output']>;
  norm_value?: Maybe<Scalars['numeric']['output']>;
  range_end?: Maybe<Scalars['numeric']['output']>;
  range_start?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "meter" */
export type IMeterSumOrderBy = {
  first_interval_task_at?: InputMaybe<IOrderBy>;
  interval?: InputMaybe<IOrderBy>;
  norm_value?: InputMaybe<IOrderBy>;
  range_end?: InputMaybe<IOrderBy>;
  range_start?: InputMaybe<IOrderBy>;
};

/** columns and relationships of "meter_type" */
export type IMeterType = {
  __typename: 'meter_type';
  value: Scalars['String']['output'];
};

/** aggregated selection of "meter_type" */
export type IMeterTypeAggregate = {
  __typename: 'meter_type_aggregate';
  aggregate?: Maybe<IMeterTypeAggregateFields>;
  nodes: Array<IMeterType>;
};

/** aggregate fields of "meter_type" */
export type IMeterTypeAggregateFields = {
  __typename: 'meter_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IMeterTypeMaxFields>;
  min?: Maybe<IMeterTypeMinFields>;
};


/** aggregate fields of "meter_type" */
export type IMeterTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IMeterTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "meter_type". All fields are combined with a logical 'AND'. */
export type IMeterTypeBoolExp = {
  _and?: InputMaybe<Array<IMeterTypeBoolExp>>;
  _not?: InputMaybe<IMeterTypeBoolExp>;
  _or?: InputMaybe<Array<IMeterTypeBoolExp>>;
  value?: InputMaybe<IStringComparisonExp>;
};

/** unique or primary key constraints on table "meter_type" */
export enum IMeterTypeConstraint {
  /** unique or primary key constraint on columns "value" */
  MeterTypePkey = 'meter_type_pkey'
}

export enum IMeterTypeEnum {
  Measurement = 'measurement',
  Meter = 'meter'
}

/** Boolean expression to compare columns of type "meter_type_enum". All fields are combined with logical 'AND'. */
export type IMeterTypeEnumComparisonExp = {
  _eq?: InputMaybe<IMeterTypeEnum>;
  _in?: InputMaybe<Array<IMeterTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<IMeterTypeEnum>;
  _nin?: InputMaybe<Array<IMeterTypeEnum>>;
};

/** input type for inserting data into table "meter_type" */
export type IMeterTypeInsertInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type IMeterTypeMaxFields = {
  __typename: 'meter_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IMeterTypeMinFields = {
  __typename: 'meter_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "meter_type" */
export type IMeterTypeMutationResponse = {
  __typename: 'meter_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IMeterType>;
};

/** on_conflict condition type for table "meter_type" */
export type IMeterTypeOnConflict = {
  constraint: IMeterTypeConstraint;
  update_columns?: Array<IMeterTypeUpdateColumn>;
  where?: InputMaybe<IMeterTypeBoolExp>;
};

/** Ordering options when selecting data from "meter_type". */
export type IMeterTypeOrderBy = {
  value?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: meter_type */
export type IMeterTypePkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "meter_type" */
export enum IMeterTypeSelectColumn {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "meter_type" */
export type IMeterTypeSetInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "meter_type" */
export type IMeterTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IMeterTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IMeterTypeStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "meter_type" */
export enum IMeterTypeUpdateColumn {
  /** column name */
  Value = 'value'
}

export type IMeterTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IMeterTypeSetInput>;
  /** filter the rows which have to be updated */
  where: IMeterTypeBoolExp;
};

/** update columns of table "meter" */
export enum IMeterUpdateColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Description = 'description',
  /** column name */
  FirstIntervalTaskAt = 'first_interval_task_at',
  /** column name */
  Id = 'id',
  /** column name */
  Interval = 'interval',
  /** column name */
  IsDeviation = 'is_deviation',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  MeterType = 'meter_type',
  /** column name */
  Name = 'name',
  /** column name */
  NormValue = 'norm_value',
  /** column name */
  RangeEnd = 'range_end',
  /** column name */
  RangeStart = 'range_start',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  Unit = 'unit',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type IMeterUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IMeterIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IMeterSetInput>;
  /** filter the rows which have to be updated */
  where: IMeterBoolExp;
};

/** aggregate var_pop on columns */
export type IMeterVarPopFields = {
  __typename: 'meter_var_pop_fields';
  /** A computed field, executes function "create_scheduled_interval_task_at_computed" */
  create_scheduled_interval_task_at?: Maybe<Scalars['numeric']['output']>;
  first_interval_task_at?: Maybe<Scalars['Float']['output']>;
  interval?: Maybe<Scalars['Float']['output']>;
  norm_value?: Maybe<Scalars['Float']['output']>;
  range_end?: Maybe<Scalars['Float']['output']>;
  range_start?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "meter" */
export type IMeterVarPopOrderBy = {
  first_interval_task_at?: InputMaybe<IOrderBy>;
  interval?: InputMaybe<IOrderBy>;
  norm_value?: InputMaybe<IOrderBy>;
  range_end?: InputMaybe<IOrderBy>;
  range_start?: InputMaybe<IOrderBy>;
};

/** aggregate var_samp on columns */
export type IMeterVarSampFields = {
  __typename: 'meter_var_samp_fields';
  /** A computed field, executes function "create_scheduled_interval_task_at_computed" */
  create_scheduled_interval_task_at?: Maybe<Scalars['numeric']['output']>;
  first_interval_task_at?: Maybe<Scalars['Float']['output']>;
  interval?: Maybe<Scalars['Float']['output']>;
  norm_value?: Maybe<Scalars['Float']['output']>;
  range_end?: Maybe<Scalars['Float']['output']>;
  range_start?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "meter" */
export type IMeterVarSampOrderBy = {
  first_interval_task_at?: InputMaybe<IOrderBy>;
  interval?: InputMaybe<IOrderBy>;
  norm_value?: InputMaybe<IOrderBy>;
  range_end?: InputMaybe<IOrderBy>;
  range_start?: InputMaybe<IOrderBy>;
};

/** aggregate variance on columns */
export type IMeterVarianceFields = {
  __typename: 'meter_variance_fields';
  /** A computed field, executes function "create_scheduled_interval_task_at_computed" */
  create_scheduled_interval_task_at?: Maybe<Scalars['numeric']['output']>;
  first_interval_task_at?: Maybe<Scalars['Float']['output']>;
  interval?: Maybe<Scalars['Float']['output']>;
  norm_value?: Maybe<Scalars['Float']['output']>;
  range_end?: Maybe<Scalars['Float']['output']>;
  range_start?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "meter" */
export type IMeterVarianceOrderBy = {
  first_interval_task_at?: InputMaybe<IOrderBy>;
  interval?: InputMaybe<IOrderBy>;
  norm_value?: InputMaybe<IOrderBy>;
  range_end?: InputMaybe<IOrderBy>;
  range_start?: InputMaybe<IOrderBy>;
};

/** mutation root */
export type IMutationRoot = {
  __typename: 'mutation_root';
  addUserToLocation?: Maybe<IAddUserToLocationOutput>;
  changePassword?: Maybe<IChangePasswordOutput>;
  checkDuplicateEmail?: Maybe<ICheckDuplicateEmailOutput>;
  checkMaintenanceTriggers?: Maybe<ICheckMaintenanceTriggersOutput>;
  copyAsset?: Maybe<ICopyAssetOutput>;
  copyWorkOrder?: Maybe<ICopyWorkOrderOutput>;
  createAPIKey?: Maybe<ICreateApiKeyOutput>;
  createOrgWithOwner?: Maybe<ICreateOrgOutput>;
  deleteCompleteOrg?: Maybe<IDeleteCompleteOrgOutput>;
  deleteUserCompletely?: Maybe<IDeleteUserCompletelyOutput>;
  /** delete data from the table: "analytics.event" */
  delete_analytics_event?: Maybe<IAnalyticsEventMutationResponse>;
  /** delete single row from the table: "analytics.event" */
  delete_analytics_event_by_pk?: Maybe<IAnalyticsEvent>;
  /** delete data from the table: "api_access_log" */
  delete_api_access_log?: Maybe<IApiAccessLogMutationResponse>;
  /** delete single row from the table: "api_access_log" */
  delete_api_access_log_by_pk?: Maybe<IApiAccessLog>;
  /** delete data from the table: "asset" */
  delete_asset?: Maybe<IAssetMutationResponse>;
  /** delete single row from the table: "asset" */
  delete_asset_by_pk?: Maybe<IAsset>;
  /** delete data from the table: "asset_default_state" */
  delete_asset_default_state?: Maybe<IAssetDefaultStateMutationResponse>;
  /** delete single row from the table: "asset_default_state" */
  delete_asset_default_state_by_pk?: Maybe<IAssetDefaultState>;
  /** delete data from the table: "asset_group" */
  delete_asset_group?: Maybe<IAssetGroupMutationResponse>;
  /** delete single row from the table: "asset_group" */
  delete_asset_group_by_pk?: Maybe<IAssetGroup>;
  /** delete data from the table: "asset_manufacturer" */
  delete_asset_manufacturer?: Maybe<IAssetManufacturerMutationResponse>;
  /** delete single row from the table: "asset_manufacturer" */
  delete_asset_manufacturer_by_pk?: Maybe<IAssetManufacturer>;
  /** delete data from the table: "asset_operating_schedule" */
  delete_asset_operating_schedule?: Maybe<IAssetOperatingScheduleMutationResponse>;
  /** delete single row from the table: "asset_operating_schedule" */
  delete_asset_operating_schedule_by_pk?: Maybe<IAssetOperatingSchedule>;
  /** delete data from the table: "asset_state" */
  delete_asset_state?: Maybe<IAssetStateMutationResponse>;
  /** delete single row from the table: "asset_state" */
  delete_asset_state_by_pk?: Maybe<IAssetState>;
  /** delete data from the table: "asset_state_log" */
  delete_asset_state_log?: Maybe<IAssetStateLogMutationResponse>;
  /** delete single row from the table: "asset_state_log" */
  delete_asset_state_log_by_pk?: Maybe<IAssetStateLog>;
  /** delete data from the table: "asset_state_variant" */
  delete_asset_state_variant?: Maybe<IAssetStateVariantMutationResponse>;
  /** delete single row from the table: "asset_state_variant" */
  delete_asset_state_variant_by_pk?: Maybe<IAssetStateVariant>;
  /** delete data from the table: "asset_state_variant_type" */
  delete_asset_state_variant_type?: Maybe<IAssetStateVariantTypeMutationResponse>;
  /** delete single row from the table: "asset_state_variant_type" */
  delete_asset_state_variant_type_by_pk?: Maybe<IAssetStateVariantType>;
  /** delete data from the table: "asset_x_asset_state" */
  delete_asset_x_asset_state?: Maybe<IAssetXAssetStateMutationResponse>;
  /** delete single row from the table: "asset_x_asset_state" */
  delete_asset_x_asset_state_by_pk?: Maybe<IAssetXAssetState>;
  /** delete data from the table: "asset_x_asset_state_duration" */
  delete_asset_x_asset_state_duration?: Maybe<IAssetXAssetStateDurationMutationResponse>;
  /** delete data from the table: "asset_x_consumable" */
  delete_asset_x_consumable?: Maybe<IAssetXConsumableMutationResponse>;
  /** delete single row from the table: "asset_x_consumable" */
  delete_asset_x_consumable_by_pk?: Maybe<IAssetXConsumable>;
  /** delete data from the table: "asset_x_upload" */
  delete_asset_x_upload?: Maybe<IAssetXUploadMutationResponse>;
  /** delete single row from the table: "asset_x_upload" */
  delete_asset_x_upload_by_pk?: Maybe<IAssetXUpload>;
  /** delete data from the table: "asset_x_work_order" */
  delete_asset_x_work_order?: Maybe<IAssetXWorkOrderMutationResponse>;
  /** delete single row from the table: "asset_x_work_order" */
  delete_asset_x_work_order_by_pk?: Maybe<IAssetXWorkOrder>;
  /** delete data from the table: "block.element" */
  delete_block_element?: Maybe<IBlockElementMutationResponse>;
  /** delete single row from the table: "block.element" */
  delete_block_element_by_pk?: Maybe<IBlockElement>;
  /** delete data from the table: "block.element_type" */
  delete_block_element_type?: Maybe<IBlockElementTypeMutationResponse>;
  /** delete single row from the table: "block.element_type" */
  delete_block_element_type_by_pk?: Maybe<IBlockElementType>;
  /** delete data from the table: "block.element_x_upload" */
  delete_block_element_x_upload?: Maybe<IBlockElementXUploadMutationResponse>;
  /** delete single row from the table: "block.element_x_upload" */
  delete_block_element_x_upload_by_pk?: Maybe<IBlockElementXUpload>;
  /** delete data from the table: "block.group" */
  delete_block_group?: Maybe<IBlockGroupMutationResponse>;
  /** delete single row from the table: "block.group" */
  delete_block_group_by_pk?: Maybe<IBlockGroup>;
  /** delete data from the table: "block.group_type" */
  delete_block_group_type?: Maybe<IBlockGroupTypeMutationResponse>;
  /** delete single row from the table: "block.group_type" */
  delete_block_group_type_by_pk?: Maybe<IBlockGroupType>;
  /** delete data from the table: "comment" */
  delete_comment?: Maybe<ICommentMutationResponse>;
  /** delete single row from the table: "comment" */
  delete_comment_by_pk?: Maybe<IComment>;
  /** delete data from the table: "comment_x_upload" */
  delete_comment_x_upload?: Maybe<ICommentXUploadMutationResponse>;
  /** delete single row from the table: "comment_x_upload" */
  delete_comment_x_upload_by_pk?: Maybe<ICommentXUpload>;
  /** delete data from the table: "consumable" */
  delete_consumable?: Maybe<IConsumableMutationResponse>;
  /** delete single row from the table: "consumable" */
  delete_consumable_by_pk?: Maybe<IConsumable>;
  /** delete data from the table: "consumable_config" */
  delete_consumable_config?: Maybe<IConsumableConfigMutationResponse>;
  /** delete single row from the table: "consumable_config" */
  delete_consumable_config_by_pk?: Maybe<IConsumableConfig>;
  /** delete data from the table: "consumable_group" */
  delete_consumable_group?: Maybe<IConsumableGroupMutationResponse>;
  /** delete single row from the table: "consumable_group" */
  delete_consumable_group_by_pk?: Maybe<IConsumableGroup>;
  /** delete data from the table: "consumable_log" */
  delete_consumable_log?: Maybe<IConsumableLogMutationResponse>;
  /** delete single row from the table: "consumable_log" */
  delete_consumable_log_by_pk?: Maybe<IConsumableLog>;
  /** delete data from the table: "consumable_storage_location" */
  delete_consumable_storage_location?: Maybe<IConsumableStorageLocationMutationResponse>;
  /** delete single row from the table: "consumable_storage_location" */
  delete_consumable_storage_location_by_pk?: Maybe<IConsumableStorageLocation>;
  /** delete data from the table: "consumable_x_upload" */
  delete_consumable_x_upload?: Maybe<IConsumableXUploadMutationResponse>;
  /** delete single row from the table: "consumable_x_upload" */
  delete_consumable_x_upload_by_pk?: Maybe<IConsumableXUpload>;
  /** delete data from the table: "contact" */
  delete_contact?: Maybe<IContactMutationResponse>;
  /** delete single row from the table: "contact" */
  delete_contact_by_pk?: Maybe<IContact>;
  /** delete data from the table: "custom_migration" */
  delete_custom_migration?: Maybe<ICustomMigrationMutationResponse>;
  /** delete single row from the table: "custom_migration" */
  delete_custom_migration_by_pk?: Maybe<ICustomMigration>;
  /** delete data from the table: "custom_view" */
  delete_custom_view?: Maybe<ICustomViewMutationResponse>;
  /** delete single row from the table: "custom_view" */
  delete_custom_view_by_pk?: Maybe<ICustomView>;
  /** delete data from the table: "custom_view_user_favorite" */
  delete_custom_view_user_favorite?: Maybe<ICustomViewUserFavoriteMutationResponse>;
  /** delete single row from the table: "custom_view_user_favorite" */
  delete_custom_view_user_favorite_by_pk?: Maybe<ICustomViewUserFavorite>;
  /** delete data from the table: "customer_feedback" */
  delete_customer_feedback?: Maybe<ICustomerFeedbackMutationResponse>;
  /** delete single row from the table: "customer_feedback" */
  delete_customer_feedback_by_pk?: Maybe<ICustomerFeedback>;
  /** delete data from the table: "feature_flag" */
  delete_feature_flag?: Maybe<IFeatureFlagMutationResponse>;
  /** delete single row from the table: "feature_flag" */
  delete_feature_flag_by_pk?: Maybe<IFeatureFlag>;
  /** delete data from the table: "file_folder" */
  delete_file_folder?: Maybe<IFileFolderMutationResponse>;
  /** delete single row from the table: "file_folder" */
  delete_file_folder_by_pk?: Maybe<IFileFolder>;
  /** delete data from the table: "file_folder_x_upload" */
  delete_file_folder_x_upload?: Maybe<IFileFolderXUploadMutationResponse>;
  /** delete single row from the table: "file_folder_x_upload" */
  delete_file_folder_x_upload_by_pk?: Maybe<IFileFolderXUpload>;
  /** delete data from the table: "inspection_result" */
  delete_inspection_result?: Maybe<IInspectionResultMutationResponse>;
  /** delete single row from the table: "inspection_result" */
  delete_inspection_result_by_pk?: Maybe<IInspectionResult>;
  /** delete data from the table: "jobs.cron_job_completed" */
  delete_jobs_cron_job_completed?: Maybe<IJobsCronJobCompletedMutationResponse>;
  /** delete single row from the table: "jobs.cron_job_completed" */
  delete_jobs_cron_job_completed_by_pk?: Maybe<IJobsCronJobCompleted>;
  /** delete data from the table: "location" */
  delete_location?: Maybe<ILocationMutationResponse>;
  /** delete data from the table: "location_api_key" */
  delete_location_api_key?: Maybe<ILocationApiKeyMutationResponse>;
  /** delete single row from the table: "location_api_key" */
  delete_location_api_key_by_pk?: Maybe<ILocationApiKey>;
  /** delete single row from the table: "location" */
  delete_location_by_pk?: Maybe<ILocation>;
  /** delete data from the table: "location_invite" */
  delete_location_invite?: Maybe<ILocationInviteMutationResponse>;
  /** delete single row from the table: "location_invite" */
  delete_location_invite_by_pk?: Maybe<ILocationInvite>;
  /** delete data from the table: "location_member" */
  delete_location_member?: Maybe<ILocationMemberMutationResponse>;
  /** delete single row from the table: "location_member" */
  delete_location_member_by_pk?: Maybe<ILocationMember>;
  /** delete data from the table: "location_member_role" */
  delete_location_member_role?: Maybe<ILocationMemberRoleMutationResponse>;
  /** delete single row from the table: "location_member_role" */
  delete_location_member_role_by_pk?: Maybe<ILocationMemberRole>;
  /** delete data from the table: "location_x_feature_flag" */
  delete_location_x_feature_flag?: Maybe<ILocationXFeatureFlagMutationResponse>;
  /** delete single row from the table: "location_x_feature_flag" */
  delete_location_x_feature_flag_by_pk?: Maybe<ILocationXFeatureFlag>;
  /** delete data from the table: "maintenance" */
  delete_maintenance?: Maybe<IMaintenanceMutationResponse>;
  /** delete single row from the table: "maintenance" */
  delete_maintenance_by_pk?: Maybe<IMaintenance>;
  /** delete data from the table: "maintenance_trigger" */
  delete_maintenance_trigger?: Maybe<IMaintenanceTriggerMutationResponse>;
  /** delete single row from the table: "maintenance_trigger" */
  delete_maintenance_trigger_by_pk?: Maybe<IMaintenanceTrigger>;
  /** delete data from the table: "maintenance_trigger_task" */
  delete_maintenance_trigger_task?: Maybe<IMaintenanceTriggerTaskMutationResponse>;
  /** delete single row from the table: "maintenance_trigger_task" */
  delete_maintenance_trigger_task_by_pk?: Maybe<IMaintenanceTriggerTask>;
  /** delete data from the table: "maintenance_trigger_type" */
  delete_maintenance_trigger_type?: Maybe<IMaintenanceTriggerTypeMutationResponse>;
  /** delete single row from the table: "maintenance_trigger_type" */
  delete_maintenance_trigger_type_by_pk?: Maybe<IMaintenanceTriggerType>;
  /** delete data from the table: "meter" */
  delete_meter?: Maybe<IMeterMutationResponse>;
  /** delete single row from the table: "meter" */
  delete_meter_by_pk?: Maybe<IMeter>;
  /** delete data from the table: "meter_reading" */
  delete_meter_reading?: Maybe<IMeterReadingMutationResponse>;
  /** delete single row from the table: "meter_reading" */
  delete_meter_reading_by_pk?: Maybe<IMeterReading>;
  /** delete data from the table: "meter_type" */
  delete_meter_type?: Maybe<IMeterTypeMutationResponse>;
  /** delete single row from the table: "meter_type" */
  delete_meter_type_by_pk?: Maybe<IMeterType>;
  /** delete data from the table: "notification" */
  delete_notification?: Maybe<INotificationMutationResponse>;
  /** delete single row from the table: "notification" */
  delete_notification_by_pk?: Maybe<INotification>;
  /** delete data from the table: "notification_type" */
  delete_notification_type?: Maybe<INotificationTypeMutationResponse>;
  /** delete single row from the table: "notification_type" */
  delete_notification_type_by_pk?: Maybe<INotificationType>;
  /** delete data from the table: "org" */
  delete_org?: Maybe<IOrgMutationResponse>;
  /** delete single row from the table: "org" */
  delete_org_by_pk?: Maybe<IOrg>;
  /** delete data from the table: "org_feature" */
  delete_org_feature?: Maybe<IOrgFeatureMutationResponse>;
  /** delete single row from the table: "org_feature" */
  delete_org_feature_by_pk?: Maybe<IOrgFeature>;
  /** delete data from the table: "org_prefs" */
  delete_org_prefs?: Maybe<IOrgPrefsMutationResponse>;
  /** delete single row from the table: "org_prefs" */
  delete_org_prefs_by_pk?: Maybe<IOrgPrefs>;
  /** delete data from the table: "org_sso_settings" */
  delete_org_sso_settings?: Maybe<IOrgSsoSettingsMutationResponse>;
  /** delete single row from the table: "org_sso_settings" */
  delete_org_sso_settings_by_pk?: Maybe<IOrgSsoSettings>;
  /** delete data from the table: "permission_role" */
  delete_permission_role?: Maybe<IPermissionRoleMutationResponse>;
  /** delete single row from the table: "permission_role" */
  delete_permission_role_by_pk?: Maybe<IPermissionRole>;
  /** delete data from the table: "permission_role_x_feature_flag" */
  delete_permission_role_x_feature_flag?: Maybe<IPermissionRoleXFeatureFlagMutationResponse>;
  /** delete single row from the table: "permission_role_x_feature_flag" */
  delete_permission_role_x_feature_flag_by_pk?: Maybe<IPermissionRoleXFeatureFlag>;
  /** delete data from the table: "permission_role_x_permission_scope" */
  delete_permission_role_x_permission_scope?: Maybe<IPermissionRoleXPermissionScopeMutationResponse>;
  /** delete single row from the table: "permission_role_x_permission_scope" */
  delete_permission_role_x_permission_scope_by_pk?: Maybe<IPermissionRoleXPermissionScope>;
  /** delete data from the table: "permission_scope" */
  delete_permission_scope?: Maybe<IPermissionScopeMutationResponse>;
  /** delete single row from the table: "permission_scope" */
  delete_permission_scope_by_pk?: Maybe<IPermissionScope>;
  /** delete data from the table: "place" */
  delete_place?: Maybe<IPlaceMutationResponse>;
  /** delete single row from the table: "place" */
  delete_place_by_pk?: Maybe<IPlace>;
  /** delete data from the table: "place_x_upload" */
  delete_place_x_upload?: Maybe<IPlaceXUploadMutationResponse>;
  /** delete single row from the table: "place_x_upload" */
  delete_place_x_upload_by_pk?: Maybe<IPlaceXUpload>;
  /** delete data from the table: "project" */
  delete_project?: Maybe<IProjectMutationResponse>;
  /** delete single row from the table: "project" */
  delete_project_by_pk?: Maybe<IProject>;
  /** delete data from the table: "project_status" */
  delete_project_status?: Maybe<IProjectStatusMutationResponse>;
  /** delete single row from the table: "project_status" */
  delete_project_status_by_pk?: Maybe<IProjectStatus>;
  /** delete data from the table: "project_x_team" */
  delete_project_x_team?: Maybe<IProjectXTeamMutationResponse>;
  /** delete single row from the table: "project_x_team" */
  delete_project_x_team_by_pk?: Maybe<IProjectXTeam>;
  /** delete data from the table: "recurrence" */
  delete_recurrence?: Maybe<IRecurrenceMutationResponse>;
  /** delete single row from the table: "recurrence" */
  delete_recurrence_by_pk?: Maybe<IRecurrence>;
  /** delete data from the table: "recurrence_pattern" */
  delete_recurrence_pattern?: Maybe<IRecurrencePatternMutationResponse>;
  /** delete single row from the table: "recurrence_pattern" */
  delete_recurrence_pattern_by_pk?: Maybe<IRecurrencePattern>;
  /** delete data from the table: "scheduled_task" */
  delete_scheduled_task?: Maybe<IScheduledTaskMutationResponse>;
  /** delete single row from the table: "scheduled_task" */
  delete_scheduled_task_by_pk?: Maybe<IScheduledTask>;
  /** delete data from the table: "service_request" */
  delete_service_request?: Maybe<IServiceRequestMutationResponse>;
  /** delete single row from the table: "service_request" */
  delete_service_request_by_pk?: Maybe<IServiceRequest>;
  /** delete data from the table: "service_request_status" */
  delete_service_request_status?: Maybe<IServiceRequestStatusMutationResponse>;
  /** delete single row from the table: "service_request_status" */
  delete_service_request_status_by_pk?: Maybe<IServiceRequestStatus>;
  /** delete data from the table: "service_request_x_asset" */
  delete_service_request_x_asset?: Maybe<IServiceRequestXAssetMutationResponse>;
  /** delete single row from the table: "service_request_x_asset" */
  delete_service_request_x_asset_by_pk?: Maybe<IServiceRequestXAsset>;
  /** delete data from the table: "service_request_x_team" */
  delete_service_request_x_team?: Maybe<IServiceRequestXTeamMutationResponse>;
  /** delete single row from the table: "service_request_x_team" */
  delete_service_request_x_team_by_pk?: Maybe<IServiceRequestXTeam>;
  /** delete data from the table: "service_request_x_upload" */
  delete_service_request_x_upload?: Maybe<IServiceRequestXUploadMutationResponse>;
  /** delete single row from the table: "service_request_x_upload" */
  delete_service_request_x_upload_by_pk?: Maybe<IServiceRequestXUpload>;
  /** delete data from the table: "service_request_x_user" */
  delete_service_request_x_user?: Maybe<IServiceRequestXUserMutationResponse>;
  /** delete single row from the table: "service_request_x_user" */
  delete_service_request_x_user_by_pk?: Maybe<IServiceRequestXUser>;
  /** delete data from the table: "setting.config" */
  delete_setting_config?: Maybe<ISettingConfigMutationResponse>;
  /** delete single row from the table: "setting.config" */
  delete_setting_config_by_pk?: Maybe<ISettingConfig>;
  /** delete data from the table: "setting.level" */
  delete_setting_level?: Maybe<ISettingLevelMutationResponse>;
  /** delete single row from the table: "setting.level" */
  delete_setting_level_by_pk?: Maybe<ISettingLevel>;
  /** delete data from the table: "setting.type" */
  delete_setting_type?: Maybe<ISettingTypeMutationResponse>;
  /** delete single row from the table: "setting.type" */
  delete_setting_type_by_pk?: Maybe<ISettingType>;
  /** delete data from the table: "team" */
  delete_team?: Maybe<ITeamMutationResponse>;
  /** delete single row from the table: "team" */
  delete_team_by_pk?: Maybe<ITeam>;
  /** delete data from the table: "team_member" */
  delete_team_member?: Maybe<ITeamMemberMutationResponse>;
  /** delete single row from the table: "team_member" */
  delete_team_member_by_pk?: Maybe<ITeamMember>;
  /** delete data from the table: "team_user_role" */
  delete_team_user_role?: Maybe<ITeamUserRoleMutationResponse>;
  /** delete single row from the table: "team_user_role" */
  delete_team_user_role_by_pk?: Maybe<ITeamUserRole>;
  /** delete data from the table: "upload" */
  delete_upload?: Maybe<IUploadMutationResponse>;
  /** delete single row from the table: "upload" */
  delete_upload_by_pk?: Maybe<IUpload>;
  /** delete data from the table: "upload_category" */
  delete_upload_category?: Maybe<IUploadCategoryMutationResponse>;
  /** delete single row from the table: "upload_category" */
  delete_upload_category_by_pk?: Maybe<IUploadCategory>;
  /** delete data from the table: "upload_x_upload_category" */
  delete_upload_x_upload_category?: Maybe<IUploadXUploadCategoryMutationResponse>;
  /** delete single row from the table: "upload_x_upload_category" */
  delete_upload_x_upload_category_by_pk?: Maybe<IUploadXUploadCategory>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<IUserMutationResponse>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<IUser>;
  /** delete data from the table: "user_favorite" */
  delete_user_favorite?: Maybe<IUserFavoriteMutationResponse>;
  /** delete single row from the table: "user_favorite" */
  delete_user_favorite_by_pk?: Maybe<IUserFavorite>;
  /** delete data from the table: "user_recently_used" */
  delete_user_recently_used?: Maybe<IUserRecentlyUsedMutationResponse>;
  /** delete single row from the table: "user_recently_used" */
  delete_user_recently_used_by_pk?: Maybe<IUserRecentlyUsed>;
  /** delete data from the table: "view_collection" */
  delete_view_collection?: Maybe<IViewCollectionMutationResponse>;
  /** delete single row from the table: "view_collection" */
  delete_view_collection_by_pk?: Maybe<IViewCollection>;
  /** delete data from the table: "view_config" */
  delete_view_config?: Maybe<IViewConfigMutationResponse>;
  /** delete single row from the table: "view_config" */
  delete_view_config_by_pk?: Maybe<IViewConfig>;
  /** delete data from the table: "view_data_type" */
  delete_view_data_type?: Maybe<IViewDataTypeMutationResponse>;
  /** delete single row from the table: "view_data_type" */
  delete_view_data_type_by_pk?: Maybe<IViewDataType>;
  /** delete data from the table: "work_order" */
  delete_work_order?: Maybe<IWorkOrderMutationResponse>;
  /** delete data from the table: "work_order_activity" */
  delete_work_order_activity?: Maybe<IWorkOrderActivityMutationResponse>;
  /** delete single row from the table: "work_order_activity" */
  delete_work_order_activity_by_pk?: Maybe<IWorkOrderActivity>;
  /** delete data from the table: "work_order_activity_type" */
  delete_work_order_activity_type?: Maybe<IWorkOrderActivityTypeMutationResponse>;
  /** delete single row from the table: "work_order_activity_type" */
  delete_work_order_activity_type_by_pk?: Maybe<IWorkOrderActivityType>;
  /** delete single row from the table: "work_order" */
  delete_work_order_by_pk?: Maybe<IWorkOrder>;
  /** delete data from the table: "work_order_category" */
  delete_work_order_category?: Maybe<IWorkOrderCategoryMutationResponse>;
  /** delete single row from the table: "work_order_category" */
  delete_work_order_category_by_pk?: Maybe<IWorkOrderCategory>;
  /** delete data from the table: "work_order_expense" */
  delete_work_order_expense?: Maybe<IWorkOrderExpenseMutationResponse>;
  /** delete single row from the table: "work_order_expense" */
  delete_work_order_expense_by_pk?: Maybe<IWorkOrderExpense>;
  /** delete data from the table: "work_order_feedback" */
  delete_work_order_feedback?: Maybe<IWorkOrderFeedbackMutationResponse>;
  /** delete single row from the table: "work_order_feedback" */
  delete_work_order_feedback_by_pk?: Maybe<IWorkOrderFeedback>;
  /** delete data from the table: "work_order_last_update" */
  delete_work_order_last_update?: Maybe<IWorkOrderLastUpdateMutationResponse>;
  /** delete data from the table: "work_order_priority" */
  delete_work_order_priority?: Maybe<IWorkOrderPriorityMutationResponse>;
  /** delete single row from the table: "work_order_priority" */
  delete_work_order_priority_by_pk?: Maybe<IWorkOrderPriority>;
  /** delete data from the table: "work_order_report" */
  delete_work_order_report?: Maybe<IWorkOrderReportMutationResponse>;
  /** delete single row from the table: "work_order_report" */
  delete_work_order_report_by_pk?: Maybe<IWorkOrderReport>;
  /** delete data from the table: "work_order_status" */
  delete_work_order_status?: Maybe<IWorkOrderStatusMutationResponse>;
  /** delete single row from the table: "work_order_status" */
  delete_work_order_status_by_pk?: Maybe<IWorkOrderStatus>;
  /** delete data from the table: "work_order_template" */
  delete_work_order_template?: Maybe<IWorkOrderTemplateMutationResponse>;
  /** delete single row from the table: "work_order_template" */
  delete_work_order_template_by_pk?: Maybe<IWorkOrderTemplate>;
  /** delete data from the table: "work_order_type" */
  delete_work_order_type?: Maybe<IWorkOrderTypeMutationResponse>;
  /** delete single row from the table: "work_order_type" */
  delete_work_order_type_by_pk?: Maybe<IWorkOrderType>;
  /** delete data from the table: "work_order_x_collaborator" */
  delete_work_order_x_collaborator?: Maybe<IWorkOrderXCollaboratorMutationResponse>;
  /** delete single row from the table: "work_order_x_collaborator" */
  delete_work_order_x_collaborator_by_pk?: Maybe<IWorkOrderXCollaborator>;
  /** delete data from the table: "work_order_x_team" */
  delete_work_order_x_team?: Maybe<IWorkOrderXTeamMutationResponse>;
  /** delete single row from the table: "work_order_x_team" */
  delete_work_order_x_team_by_pk?: Maybe<IWorkOrderXTeam>;
  /** delete data from the table: "work_order_x_upload" */
  delete_work_order_x_upload?: Maybe<IWorkOrderXUploadMutationResponse>;
  /** delete single row from the table: "work_order_x_upload" */
  delete_work_order_x_upload_by_pk?: Maybe<IWorkOrderXUpload>;
  /** delete data from the table: "work_order_x_user" */
  delete_work_order_x_user?: Maybe<IWorkOrderXUserMutationResponse>;
  /** delete single row from the table: "work_order_x_user" */
  delete_work_order_x_user_by_pk?: Maybe<IWorkOrderXUser>;
  /** delete data from the table: "work_order_x_work_order_category" */
  delete_work_order_x_work_order_category?: Maybe<IWorkOrderXWorkOrderCategoryMutationResponse>;
  /** delete single row from the table: "work_order_x_work_order_category" */
  delete_work_order_x_work_order_category_by_pk?: Maybe<IWorkOrderXWorkOrderCategory>;
  /** insert data into the table: "analytics.event" */
  insert_analytics_event?: Maybe<IAnalyticsEventMutationResponse>;
  /** insert a single row into the table: "analytics.event" */
  insert_analytics_event_one?: Maybe<IAnalyticsEvent>;
  /** insert data into the table: "api_access_log" */
  insert_api_access_log?: Maybe<IApiAccessLogMutationResponse>;
  /** insert a single row into the table: "api_access_log" */
  insert_api_access_log_one?: Maybe<IApiAccessLog>;
  /** insert data into the table: "asset" */
  insert_asset?: Maybe<IAssetMutationResponse>;
  /** insert data into the table: "asset_default_state" */
  insert_asset_default_state?: Maybe<IAssetDefaultStateMutationResponse>;
  /** insert a single row into the table: "asset_default_state" */
  insert_asset_default_state_one?: Maybe<IAssetDefaultState>;
  /** insert data into the table: "asset_group" */
  insert_asset_group?: Maybe<IAssetGroupMutationResponse>;
  /** insert a single row into the table: "asset_group" */
  insert_asset_group_one?: Maybe<IAssetGroup>;
  /** insert data into the table: "asset_manufacturer" */
  insert_asset_manufacturer?: Maybe<IAssetManufacturerMutationResponse>;
  /** insert a single row into the table: "asset_manufacturer" */
  insert_asset_manufacturer_one?: Maybe<IAssetManufacturer>;
  /** insert a single row into the table: "asset" */
  insert_asset_one?: Maybe<IAsset>;
  /** insert data into the table: "asset_operating_schedule" */
  insert_asset_operating_schedule?: Maybe<IAssetOperatingScheduleMutationResponse>;
  /** insert a single row into the table: "asset_operating_schedule" */
  insert_asset_operating_schedule_one?: Maybe<IAssetOperatingSchedule>;
  /** insert data into the table: "asset_state" */
  insert_asset_state?: Maybe<IAssetStateMutationResponse>;
  /** insert data into the table: "asset_state_log" */
  insert_asset_state_log?: Maybe<IAssetStateLogMutationResponse>;
  /** insert a single row into the table: "asset_state_log" */
  insert_asset_state_log_one?: Maybe<IAssetStateLog>;
  /** insert a single row into the table: "asset_state" */
  insert_asset_state_one?: Maybe<IAssetState>;
  /** insert data into the table: "asset_state_variant" */
  insert_asset_state_variant?: Maybe<IAssetStateVariantMutationResponse>;
  /** insert a single row into the table: "asset_state_variant" */
  insert_asset_state_variant_one?: Maybe<IAssetStateVariant>;
  /** insert data into the table: "asset_state_variant_type" */
  insert_asset_state_variant_type?: Maybe<IAssetStateVariantTypeMutationResponse>;
  /** insert a single row into the table: "asset_state_variant_type" */
  insert_asset_state_variant_type_one?: Maybe<IAssetStateVariantType>;
  /** insert data into the table: "asset_x_asset_state" */
  insert_asset_x_asset_state?: Maybe<IAssetXAssetStateMutationResponse>;
  /** insert data into the table: "asset_x_asset_state_duration" */
  insert_asset_x_asset_state_duration?: Maybe<IAssetXAssetStateDurationMutationResponse>;
  /** insert a single row into the table: "asset_x_asset_state_duration" */
  insert_asset_x_asset_state_duration_one?: Maybe<IAssetXAssetStateDuration>;
  /** insert a single row into the table: "asset_x_asset_state" */
  insert_asset_x_asset_state_one?: Maybe<IAssetXAssetState>;
  /** insert data into the table: "asset_x_consumable" */
  insert_asset_x_consumable?: Maybe<IAssetXConsumableMutationResponse>;
  /** insert a single row into the table: "asset_x_consumable" */
  insert_asset_x_consumable_one?: Maybe<IAssetXConsumable>;
  /** insert data into the table: "asset_x_upload" */
  insert_asset_x_upload?: Maybe<IAssetXUploadMutationResponse>;
  /** insert a single row into the table: "asset_x_upload" */
  insert_asset_x_upload_one?: Maybe<IAssetXUpload>;
  /** insert data into the table: "asset_x_work_order" */
  insert_asset_x_work_order?: Maybe<IAssetXWorkOrderMutationResponse>;
  /** insert a single row into the table: "asset_x_work_order" */
  insert_asset_x_work_order_one?: Maybe<IAssetXWorkOrder>;
  /** insert data into the table: "block.element" */
  insert_block_element?: Maybe<IBlockElementMutationResponse>;
  /** insert a single row into the table: "block.element" */
  insert_block_element_one?: Maybe<IBlockElement>;
  /** insert data into the table: "block.element_type" */
  insert_block_element_type?: Maybe<IBlockElementTypeMutationResponse>;
  /** insert a single row into the table: "block.element_type" */
  insert_block_element_type_one?: Maybe<IBlockElementType>;
  /** insert data into the table: "block.element_x_upload" */
  insert_block_element_x_upload?: Maybe<IBlockElementXUploadMutationResponse>;
  /** insert a single row into the table: "block.element_x_upload" */
  insert_block_element_x_upload_one?: Maybe<IBlockElementXUpload>;
  /** insert data into the table: "block.group" */
  insert_block_group?: Maybe<IBlockGroupMutationResponse>;
  /** insert a single row into the table: "block.group" */
  insert_block_group_one?: Maybe<IBlockGroup>;
  /** insert data into the table: "block.group_type" */
  insert_block_group_type?: Maybe<IBlockGroupTypeMutationResponse>;
  /** insert a single row into the table: "block.group_type" */
  insert_block_group_type_one?: Maybe<IBlockGroupType>;
  /** insert data into the table: "comment" */
  insert_comment?: Maybe<ICommentMutationResponse>;
  /** insert a single row into the table: "comment" */
  insert_comment_one?: Maybe<IComment>;
  /** insert data into the table: "comment_x_upload" */
  insert_comment_x_upload?: Maybe<ICommentXUploadMutationResponse>;
  /** insert a single row into the table: "comment_x_upload" */
  insert_comment_x_upload_one?: Maybe<ICommentXUpload>;
  /** insert data into the table: "consumable" */
  insert_consumable?: Maybe<IConsumableMutationResponse>;
  /** insert data into the table: "consumable_config" */
  insert_consumable_config?: Maybe<IConsumableConfigMutationResponse>;
  /** insert a single row into the table: "consumable_config" */
  insert_consumable_config_one?: Maybe<IConsumableConfig>;
  /** insert data into the table: "consumable_group" */
  insert_consumable_group?: Maybe<IConsumableGroupMutationResponse>;
  /** insert a single row into the table: "consumable_group" */
  insert_consumable_group_one?: Maybe<IConsumableGroup>;
  /** insert data into the table: "consumable_log" */
  insert_consumable_log?: Maybe<IConsumableLogMutationResponse>;
  /** insert a single row into the table: "consumable_log" */
  insert_consumable_log_one?: Maybe<IConsumableLog>;
  /** insert a single row into the table: "consumable" */
  insert_consumable_one?: Maybe<IConsumable>;
  /** insert data into the table: "consumable_storage_location" */
  insert_consumable_storage_location?: Maybe<IConsumableStorageLocationMutationResponse>;
  /** insert a single row into the table: "consumable_storage_location" */
  insert_consumable_storage_location_one?: Maybe<IConsumableStorageLocation>;
  /** insert data into the table: "consumable_x_upload" */
  insert_consumable_x_upload?: Maybe<IConsumableXUploadMutationResponse>;
  /** insert a single row into the table: "consumable_x_upload" */
  insert_consumable_x_upload_one?: Maybe<IConsumableXUpload>;
  /** insert data into the table: "contact" */
  insert_contact?: Maybe<IContactMutationResponse>;
  /** insert a single row into the table: "contact" */
  insert_contact_one?: Maybe<IContact>;
  /** insert data into the table: "custom_migration" */
  insert_custom_migration?: Maybe<ICustomMigrationMutationResponse>;
  /** insert a single row into the table: "custom_migration" */
  insert_custom_migration_one?: Maybe<ICustomMigration>;
  /** insert data into the table: "custom_view" */
  insert_custom_view?: Maybe<ICustomViewMutationResponse>;
  /** insert a single row into the table: "custom_view" */
  insert_custom_view_one?: Maybe<ICustomView>;
  /** insert data into the table: "custom_view_user_favorite" */
  insert_custom_view_user_favorite?: Maybe<ICustomViewUserFavoriteMutationResponse>;
  /** insert a single row into the table: "custom_view_user_favorite" */
  insert_custom_view_user_favorite_one?: Maybe<ICustomViewUserFavorite>;
  /** insert data into the table: "customer_feedback" */
  insert_customer_feedback?: Maybe<ICustomerFeedbackMutationResponse>;
  /** insert a single row into the table: "customer_feedback" */
  insert_customer_feedback_one?: Maybe<ICustomerFeedback>;
  /** insert data into the table: "feature_flag" */
  insert_feature_flag?: Maybe<IFeatureFlagMutationResponse>;
  /** insert a single row into the table: "feature_flag" */
  insert_feature_flag_one?: Maybe<IFeatureFlag>;
  /** insert data into the table: "file_folder" */
  insert_file_folder?: Maybe<IFileFolderMutationResponse>;
  /** insert a single row into the table: "file_folder" */
  insert_file_folder_one?: Maybe<IFileFolder>;
  /** insert data into the table: "file_folder_x_upload" */
  insert_file_folder_x_upload?: Maybe<IFileFolderXUploadMutationResponse>;
  /** insert a single row into the table: "file_folder_x_upload" */
  insert_file_folder_x_upload_one?: Maybe<IFileFolderXUpload>;
  /** insert data into the table: "inspection_result" */
  insert_inspection_result?: Maybe<IInspectionResultMutationResponse>;
  /** insert a single row into the table: "inspection_result" */
  insert_inspection_result_one?: Maybe<IInspectionResult>;
  /** insert data into the table: "jobs.cron_job_completed" */
  insert_jobs_cron_job_completed?: Maybe<IJobsCronJobCompletedMutationResponse>;
  /** insert a single row into the table: "jobs.cron_job_completed" */
  insert_jobs_cron_job_completed_one?: Maybe<IJobsCronJobCompleted>;
  /** insert data into the table: "location" */
  insert_location?: Maybe<ILocationMutationResponse>;
  /** insert data into the table: "location_api_key" */
  insert_location_api_key?: Maybe<ILocationApiKeyMutationResponse>;
  /** insert a single row into the table: "location_api_key" */
  insert_location_api_key_one?: Maybe<ILocationApiKey>;
  /** insert data into the table: "location_invite" */
  insert_location_invite?: Maybe<ILocationInviteMutationResponse>;
  /** insert a single row into the table: "location_invite" */
  insert_location_invite_one?: Maybe<ILocationInvite>;
  /** insert data into the table: "location_member" */
  insert_location_member?: Maybe<ILocationMemberMutationResponse>;
  /** insert a single row into the table: "location_member" */
  insert_location_member_one?: Maybe<ILocationMember>;
  /** insert data into the table: "location_member_role" */
  insert_location_member_role?: Maybe<ILocationMemberRoleMutationResponse>;
  /** insert a single row into the table: "location_member_role" */
  insert_location_member_role_one?: Maybe<ILocationMemberRole>;
  /** insert a single row into the table: "location" */
  insert_location_one?: Maybe<ILocation>;
  /** insert data into the table: "location_x_feature_flag" */
  insert_location_x_feature_flag?: Maybe<ILocationXFeatureFlagMutationResponse>;
  /** insert a single row into the table: "location_x_feature_flag" */
  insert_location_x_feature_flag_one?: Maybe<ILocationXFeatureFlag>;
  /** insert data into the table: "maintenance" */
  insert_maintenance?: Maybe<IMaintenanceMutationResponse>;
  /** insert a single row into the table: "maintenance" */
  insert_maintenance_one?: Maybe<IMaintenance>;
  /** insert data into the table: "maintenance_trigger" */
  insert_maintenance_trigger?: Maybe<IMaintenanceTriggerMutationResponse>;
  /** insert a single row into the table: "maintenance_trigger" */
  insert_maintenance_trigger_one?: Maybe<IMaintenanceTrigger>;
  /** insert data into the table: "maintenance_trigger_task" */
  insert_maintenance_trigger_task?: Maybe<IMaintenanceTriggerTaskMutationResponse>;
  /** insert a single row into the table: "maintenance_trigger_task" */
  insert_maintenance_trigger_task_one?: Maybe<IMaintenanceTriggerTask>;
  /** insert data into the table: "maintenance_trigger_type" */
  insert_maintenance_trigger_type?: Maybe<IMaintenanceTriggerTypeMutationResponse>;
  /** insert a single row into the table: "maintenance_trigger_type" */
  insert_maintenance_trigger_type_one?: Maybe<IMaintenanceTriggerType>;
  /** insert data into the table: "meter" */
  insert_meter?: Maybe<IMeterMutationResponse>;
  /** insert a single row into the table: "meter" */
  insert_meter_one?: Maybe<IMeter>;
  /** insert data into the table: "meter_reading" */
  insert_meter_reading?: Maybe<IMeterReadingMutationResponse>;
  /** insert a single row into the table: "meter_reading" */
  insert_meter_reading_one?: Maybe<IMeterReading>;
  /** insert data into the table: "meter_type" */
  insert_meter_type?: Maybe<IMeterTypeMutationResponse>;
  /** insert a single row into the table: "meter_type" */
  insert_meter_type_one?: Maybe<IMeterType>;
  /** insert data into the table: "notification" */
  insert_notification?: Maybe<INotificationMutationResponse>;
  /** insert a single row into the table: "notification" */
  insert_notification_one?: Maybe<INotification>;
  /** insert data into the table: "notification_type" */
  insert_notification_type?: Maybe<INotificationTypeMutationResponse>;
  /** insert a single row into the table: "notification_type" */
  insert_notification_type_one?: Maybe<INotificationType>;
  /** insert data into the table: "org" */
  insert_org?: Maybe<IOrgMutationResponse>;
  /** insert data into the table: "org_feature" */
  insert_org_feature?: Maybe<IOrgFeatureMutationResponse>;
  /** insert a single row into the table: "org_feature" */
  insert_org_feature_one?: Maybe<IOrgFeature>;
  /** insert a single row into the table: "org" */
  insert_org_one?: Maybe<IOrg>;
  /** insert data into the table: "org_prefs" */
  insert_org_prefs?: Maybe<IOrgPrefsMutationResponse>;
  /** insert a single row into the table: "org_prefs" */
  insert_org_prefs_one?: Maybe<IOrgPrefs>;
  /** insert data into the table: "org_sso_settings" */
  insert_org_sso_settings?: Maybe<IOrgSsoSettingsMutationResponse>;
  /** insert a single row into the table: "org_sso_settings" */
  insert_org_sso_settings_one?: Maybe<IOrgSsoSettings>;
  /** insert data into the table: "permission_role" */
  insert_permission_role?: Maybe<IPermissionRoleMutationResponse>;
  /** insert a single row into the table: "permission_role" */
  insert_permission_role_one?: Maybe<IPermissionRole>;
  /** insert data into the table: "permission_role_x_feature_flag" */
  insert_permission_role_x_feature_flag?: Maybe<IPermissionRoleXFeatureFlagMutationResponse>;
  /** insert a single row into the table: "permission_role_x_feature_flag" */
  insert_permission_role_x_feature_flag_one?: Maybe<IPermissionRoleXFeatureFlag>;
  /** insert data into the table: "permission_role_x_permission_scope" */
  insert_permission_role_x_permission_scope?: Maybe<IPermissionRoleXPermissionScopeMutationResponse>;
  /** insert a single row into the table: "permission_role_x_permission_scope" */
  insert_permission_role_x_permission_scope_one?: Maybe<IPermissionRoleXPermissionScope>;
  /** insert data into the table: "permission_scope" */
  insert_permission_scope?: Maybe<IPermissionScopeMutationResponse>;
  /** insert a single row into the table: "permission_scope" */
  insert_permission_scope_one?: Maybe<IPermissionScope>;
  /** insert data into the table: "place" */
  insert_place?: Maybe<IPlaceMutationResponse>;
  /** insert a single row into the table: "place" */
  insert_place_one?: Maybe<IPlace>;
  /** insert data into the table: "place_x_upload" */
  insert_place_x_upload?: Maybe<IPlaceXUploadMutationResponse>;
  /** insert a single row into the table: "place_x_upload" */
  insert_place_x_upload_one?: Maybe<IPlaceXUpload>;
  /** insert data into the table: "project" */
  insert_project?: Maybe<IProjectMutationResponse>;
  /** insert a single row into the table: "project" */
  insert_project_one?: Maybe<IProject>;
  /** insert data into the table: "project_status" */
  insert_project_status?: Maybe<IProjectStatusMutationResponse>;
  /** insert a single row into the table: "project_status" */
  insert_project_status_one?: Maybe<IProjectStatus>;
  /** insert data into the table: "project_x_team" */
  insert_project_x_team?: Maybe<IProjectXTeamMutationResponse>;
  /** insert a single row into the table: "project_x_team" */
  insert_project_x_team_one?: Maybe<IProjectXTeam>;
  /** insert data into the table: "recurrence" */
  insert_recurrence?: Maybe<IRecurrenceMutationResponse>;
  /** insert a single row into the table: "recurrence" */
  insert_recurrence_one?: Maybe<IRecurrence>;
  /** insert data into the table: "recurrence_pattern" */
  insert_recurrence_pattern?: Maybe<IRecurrencePatternMutationResponse>;
  /** insert a single row into the table: "recurrence_pattern" */
  insert_recurrence_pattern_one?: Maybe<IRecurrencePattern>;
  /** insert data into the table: "scheduled_task" */
  insert_scheduled_task?: Maybe<IScheduledTaskMutationResponse>;
  /** insert a single row into the table: "scheduled_task" */
  insert_scheduled_task_one?: Maybe<IScheduledTask>;
  /** insert data into the table: "service_request" */
  insert_service_request?: Maybe<IServiceRequestMutationResponse>;
  /** insert a single row into the table: "service_request" */
  insert_service_request_one?: Maybe<IServiceRequest>;
  /** insert data into the table: "service_request_status" */
  insert_service_request_status?: Maybe<IServiceRequestStatusMutationResponse>;
  /** insert a single row into the table: "service_request_status" */
  insert_service_request_status_one?: Maybe<IServiceRequestStatus>;
  /** insert data into the table: "service_request_x_asset" */
  insert_service_request_x_asset?: Maybe<IServiceRequestXAssetMutationResponse>;
  /** insert a single row into the table: "service_request_x_asset" */
  insert_service_request_x_asset_one?: Maybe<IServiceRequestXAsset>;
  /** insert data into the table: "service_request_x_team" */
  insert_service_request_x_team?: Maybe<IServiceRequestXTeamMutationResponse>;
  /** insert a single row into the table: "service_request_x_team" */
  insert_service_request_x_team_one?: Maybe<IServiceRequestXTeam>;
  /** insert data into the table: "service_request_x_upload" */
  insert_service_request_x_upload?: Maybe<IServiceRequestXUploadMutationResponse>;
  /** insert a single row into the table: "service_request_x_upload" */
  insert_service_request_x_upload_one?: Maybe<IServiceRequestXUpload>;
  /** insert data into the table: "service_request_x_user" */
  insert_service_request_x_user?: Maybe<IServiceRequestXUserMutationResponse>;
  /** insert a single row into the table: "service_request_x_user" */
  insert_service_request_x_user_one?: Maybe<IServiceRequestXUser>;
  /** insert data into the table: "setting.config" */
  insert_setting_config?: Maybe<ISettingConfigMutationResponse>;
  /** insert a single row into the table: "setting.config" */
  insert_setting_config_one?: Maybe<ISettingConfig>;
  /** insert data into the table: "setting.level" */
  insert_setting_level?: Maybe<ISettingLevelMutationResponse>;
  /** insert a single row into the table: "setting.level" */
  insert_setting_level_one?: Maybe<ISettingLevel>;
  /** insert data into the table: "setting.type" */
  insert_setting_type?: Maybe<ISettingTypeMutationResponse>;
  /** insert a single row into the table: "setting.type" */
  insert_setting_type_one?: Maybe<ISettingType>;
  /** insert data into the table: "team" */
  insert_team?: Maybe<ITeamMutationResponse>;
  /** insert data into the table: "team_member" */
  insert_team_member?: Maybe<ITeamMemberMutationResponse>;
  /** insert a single row into the table: "team_member" */
  insert_team_member_one?: Maybe<ITeamMember>;
  /** insert a single row into the table: "team" */
  insert_team_one?: Maybe<ITeam>;
  /** insert data into the table: "team_user_role" */
  insert_team_user_role?: Maybe<ITeamUserRoleMutationResponse>;
  /** insert a single row into the table: "team_user_role" */
  insert_team_user_role_one?: Maybe<ITeamUserRole>;
  /** insert data into the table: "upload" */
  insert_upload?: Maybe<IUploadMutationResponse>;
  /** insert data into the table: "upload_category" */
  insert_upload_category?: Maybe<IUploadCategoryMutationResponse>;
  /** insert a single row into the table: "upload_category" */
  insert_upload_category_one?: Maybe<IUploadCategory>;
  /** insert a single row into the table: "upload" */
  insert_upload_one?: Maybe<IUpload>;
  /** insert data into the table: "upload_x_upload_category" */
  insert_upload_x_upload_category?: Maybe<IUploadXUploadCategoryMutationResponse>;
  /** insert a single row into the table: "upload_x_upload_category" */
  insert_upload_x_upload_category_one?: Maybe<IUploadXUploadCategory>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<IUserMutationResponse>;
  /** insert data into the table: "user_favorite" */
  insert_user_favorite?: Maybe<IUserFavoriteMutationResponse>;
  /** insert a single row into the table: "user_favorite" */
  insert_user_favorite_one?: Maybe<IUserFavorite>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<IUser>;
  /** insert data into the table: "user_recently_used" */
  insert_user_recently_used?: Maybe<IUserRecentlyUsedMutationResponse>;
  /** insert a single row into the table: "user_recently_used" */
  insert_user_recently_used_one?: Maybe<IUserRecentlyUsed>;
  /** insert data into the table: "view_collection" */
  insert_view_collection?: Maybe<IViewCollectionMutationResponse>;
  /** insert a single row into the table: "view_collection" */
  insert_view_collection_one?: Maybe<IViewCollection>;
  /** insert data into the table: "view_config" */
  insert_view_config?: Maybe<IViewConfigMutationResponse>;
  /** insert a single row into the table: "view_config" */
  insert_view_config_one?: Maybe<IViewConfig>;
  /** insert data into the table: "view_data_type" */
  insert_view_data_type?: Maybe<IViewDataTypeMutationResponse>;
  /** insert a single row into the table: "view_data_type" */
  insert_view_data_type_one?: Maybe<IViewDataType>;
  /** insert data into the table: "work_order" */
  insert_work_order?: Maybe<IWorkOrderMutationResponse>;
  /** insert data into the table: "work_order_activity" */
  insert_work_order_activity?: Maybe<IWorkOrderActivityMutationResponse>;
  /** insert a single row into the table: "work_order_activity" */
  insert_work_order_activity_one?: Maybe<IWorkOrderActivity>;
  /** insert data into the table: "work_order_activity_type" */
  insert_work_order_activity_type?: Maybe<IWorkOrderActivityTypeMutationResponse>;
  /** insert a single row into the table: "work_order_activity_type" */
  insert_work_order_activity_type_one?: Maybe<IWorkOrderActivityType>;
  /** insert data into the table: "work_order_category" */
  insert_work_order_category?: Maybe<IWorkOrderCategoryMutationResponse>;
  /** insert a single row into the table: "work_order_category" */
  insert_work_order_category_one?: Maybe<IWorkOrderCategory>;
  /** insert data into the table: "work_order_expense" */
  insert_work_order_expense?: Maybe<IWorkOrderExpenseMutationResponse>;
  /** insert a single row into the table: "work_order_expense" */
  insert_work_order_expense_one?: Maybe<IWorkOrderExpense>;
  /** insert data into the table: "work_order_feedback" */
  insert_work_order_feedback?: Maybe<IWorkOrderFeedbackMutationResponse>;
  /** insert a single row into the table: "work_order_feedback" */
  insert_work_order_feedback_one?: Maybe<IWorkOrderFeedback>;
  /** insert data into the table: "work_order_last_update" */
  insert_work_order_last_update?: Maybe<IWorkOrderLastUpdateMutationResponse>;
  /** insert a single row into the table: "work_order_last_update" */
  insert_work_order_last_update_one?: Maybe<IWorkOrderLastUpdate>;
  /** insert a single row into the table: "work_order" */
  insert_work_order_one?: Maybe<IWorkOrder>;
  /** insert data into the table: "work_order_priority" */
  insert_work_order_priority?: Maybe<IWorkOrderPriorityMutationResponse>;
  /** insert a single row into the table: "work_order_priority" */
  insert_work_order_priority_one?: Maybe<IWorkOrderPriority>;
  /** insert data into the table: "work_order_report" */
  insert_work_order_report?: Maybe<IWorkOrderReportMutationResponse>;
  /** insert a single row into the table: "work_order_report" */
  insert_work_order_report_one?: Maybe<IWorkOrderReport>;
  /** insert data into the table: "work_order_status" */
  insert_work_order_status?: Maybe<IWorkOrderStatusMutationResponse>;
  /** insert a single row into the table: "work_order_status" */
  insert_work_order_status_one?: Maybe<IWorkOrderStatus>;
  /** insert data into the table: "work_order_template" */
  insert_work_order_template?: Maybe<IWorkOrderTemplateMutationResponse>;
  /** insert a single row into the table: "work_order_template" */
  insert_work_order_template_one?: Maybe<IWorkOrderTemplate>;
  /** insert data into the table: "work_order_type" */
  insert_work_order_type?: Maybe<IWorkOrderTypeMutationResponse>;
  /** insert a single row into the table: "work_order_type" */
  insert_work_order_type_one?: Maybe<IWorkOrderType>;
  /** insert data into the table: "work_order_x_collaborator" */
  insert_work_order_x_collaborator?: Maybe<IWorkOrderXCollaboratorMutationResponse>;
  /** insert a single row into the table: "work_order_x_collaborator" */
  insert_work_order_x_collaborator_one?: Maybe<IWorkOrderXCollaborator>;
  /** insert data into the table: "work_order_x_team" */
  insert_work_order_x_team?: Maybe<IWorkOrderXTeamMutationResponse>;
  /** insert a single row into the table: "work_order_x_team" */
  insert_work_order_x_team_one?: Maybe<IWorkOrderXTeam>;
  /** insert data into the table: "work_order_x_upload" */
  insert_work_order_x_upload?: Maybe<IWorkOrderXUploadMutationResponse>;
  /** insert a single row into the table: "work_order_x_upload" */
  insert_work_order_x_upload_one?: Maybe<IWorkOrderXUpload>;
  /** insert data into the table: "work_order_x_user" */
  insert_work_order_x_user?: Maybe<IWorkOrderXUserMutationResponse>;
  /** insert a single row into the table: "work_order_x_user" */
  insert_work_order_x_user_one?: Maybe<IWorkOrderXUser>;
  /** insert data into the table: "work_order_x_work_order_category" */
  insert_work_order_x_work_order_category?: Maybe<IWorkOrderXWorkOrderCategoryMutationResponse>;
  /** insert a single row into the table: "work_order_x_work_order_category" */
  insert_work_order_x_work_order_category_one?: Maybe<IWorkOrderXWorkOrderCategory>;
  /** Allows to reset the password for  user */
  resetUserPassword?: Maybe<IResetUserPasswordResponse>;
  /** setupDemoData */
  setupDemoData?: Maybe<ISetupDemoDataOutput>;
  syncUserToKeycloak?: Maybe<ISyncUserToKeycloakOutput>;
  /** Update user's email in keycloak */
  updateUserEmail?: Maybe<IUpdateUserEmailOutput>;
  /** update data of the table: "analytics.event" */
  update_analytics_event?: Maybe<IAnalyticsEventMutationResponse>;
  /** update single row of the table: "analytics.event" */
  update_analytics_event_by_pk?: Maybe<IAnalyticsEvent>;
  /** update multiples rows of table: "analytics.event" */
  update_analytics_event_many?: Maybe<Array<Maybe<IAnalyticsEventMutationResponse>>>;
  /** update data of the table: "api_access_log" */
  update_api_access_log?: Maybe<IApiAccessLogMutationResponse>;
  /** update single row of the table: "api_access_log" */
  update_api_access_log_by_pk?: Maybe<IApiAccessLog>;
  /** update multiples rows of table: "api_access_log" */
  update_api_access_log_many?: Maybe<Array<Maybe<IApiAccessLogMutationResponse>>>;
  /** update data of the table: "asset" */
  update_asset?: Maybe<IAssetMutationResponse>;
  /** update single row of the table: "asset" */
  update_asset_by_pk?: Maybe<IAsset>;
  /** update data of the table: "asset_default_state" */
  update_asset_default_state?: Maybe<IAssetDefaultStateMutationResponse>;
  /** update single row of the table: "asset_default_state" */
  update_asset_default_state_by_pk?: Maybe<IAssetDefaultState>;
  /** update multiples rows of table: "asset_default_state" */
  update_asset_default_state_many?: Maybe<Array<Maybe<IAssetDefaultStateMutationResponse>>>;
  /** update data of the table: "asset_group" */
  update_asset_group?: Maybe<IAssetGroupMutationResponse>;
  /** update single row of the table: "asset_group" */
  update_asset_group_by_pk?: Maybe<IAssetGroup>;
  /** update multiples rows of table: "asset_group" */
  update_asset_group_many?: Maybe<Array<Maybe<IAssetGroupMutationResponse>>>;
  /** update data of the table: "asset_manufacturer" */
  update_asset_manufacturer?: Maybe<IAssetManufacturerMutationResponse>;
  /** update single row of the table: "asset_manufacturer" */
  update_asset_manufacturer_by_pk?: Maybe<IAssetManufacturer>;
  /** update multiples rows of table: "asset_manufacturer" */
  update_asset_manufacturer_many?: Maybe<Array<Maybe<IAssetManufacturerMutationResponse>>>;
  /** update multiples rows of table: "asset" */
  update_asset_many?: Maybe<Array<Maybe<IAssetMutationResponse>>>;
  /** update data of the table: "asset_operating_schedule" */
  update_asset_operating_schedule?: Maybe<IAssetOperatingScheduleMutationResponse>;
  /** update single row of the table: "asset_operating_schedule" */
  update_asset_operating_schedule_by_pk?: Maybe<IAssetOperatingSchedule>;
  /** update multiples rows of table: "asset_operating_schedule" */
  update_asset_operating_schedule_many?: Maybe<Array<Maybe<IAssetOperatingScheduleMutationResponse>>>;
  /** update data of the table: "asset_state" */
  update_asset_state?: Maybe<IAssetStateMutationResponse>;
  /** update single row of the table: "asset_state" */
  update_asset_state_by_pk?: Maybe<IAssetState>;
  /** update data of the table: "asset_state_log" */
  update_asset_state_log?: Maybe<IAssetStateLogMutationResponse>;
  /** update single row of the table: "asset_state_log" */
  update_asset_state_log_by_pk?: Maybe<IAssetStateLog>;
  /** update multiples rows of table: "asset_state_log" */
  update_asset_state_log_many?: Maybe<Array<Maybe<IAssetStateLogMutationResponse>>>;
  /** update multiples rows of table: "asset_state" */
  update_asset_state_many?: Maybe<Array<Maybe<IAssetStateMutationResponse>>>;
  /** update data of the table: "asset_state_variant" */
  update_asset_state_variant?: Maybe<IAssetStateVariantMutationResponse>;
  /** update single row of the table: "asset_state_variant" */
  update_asset_state_variant_by_pk?: Maybe<IAssetStateVariant>;
  /** update multiples rows of table: "asset_state_variant" */
  update_asset_state_variant_many?: Maybe<Array<Maybe<IAssetStateVariantMutationResponse>>>;
  /** update data of the table: "asset_state_variant_type" */
  update_asset_state_variant_type?: Maybe<IAssetStateVariantTypeMutationResponse>;
  /** update single row of the table: "asset_state_variant_type" */
  update_asset_state_variant_type_by_pk?: Maybe<IAssetStateVariantType>;
  /** update multiples rows of table: "asset_state_variant_type" */
  update_asset_state_variant_type_many?: Maybe<Array<Maybe<IAssetStateVariantTypeMutationResponse>>>;
  /** update data of the table: "asset_x_asset_state" */
  update_asset_x_asset_state?: Maybe<IAssetXAssetStateMutationResponse>;
  /** update single row of the table: "asset_x_asset_state" */
  update_asset_x_asset_state_by_pk?: Maybe<IAssetXAssetState>;
  /** update data of the table: "asset_x_asset_state_duration" */
  update_asset_x_asset_state_duration?: Maybe<IAssetXAssetStateDurationMutationResponse>;
  /** update multiples rows of table: "asset_x_asset_state_duration" */
  update_asset_x_asset_state_duration_many?: Maybe<Array<Maybe<IAssetXAssetStateDurationMutationResponse>>>;
  /** update multiples rows of table: "asset_x_asset_state" */
  update_asset_x_asset_state_many?: Maybe<Array<Maybe<IAssetXAssetStateMutationResponse>>>;
  /** update data of the table: "asset_x_consumable" */
  update_asset_x_consumable?: Maybe<IAssetXConsumableMutationResponse>;
  /** update single row of the table: "asset_x_consumable" */
  update_asset_x_consumable_by_pk?: Maybe<IAssetXConsumable>;
  /** update multiples rows of table: "asset_x_consumable" */
  update_asset_x_consumable_many?: Maybe<Array<Maybe<IAssetXConsumableMutationResponse>>>;
  /** update data of the table: "asset_x_upload" */
  update_asset_x_upload?: Maybe<IAssetXUploadMutationResponse>;
  /** update single row of the table: "asset_x_upload" */
  update_asset_x_upload_by_pk?: Maybe<IAssetXUpload>;
  /** update multiples rows of table: "asset_x_upload" */
  update_asset_x_upload_many?: Maybe<Array<Maybe<IAssetXUploadMutationResponse>>>;
  /** update data of the table: "asset_x_work_order" */
  update_asset_x_work_order?: Maybe<IAssetXWorkOrderMutationResponse>;
  /** update single row of the table: "asset_x_work_order" */
  update_asset_x_work_order_by_pk?: Maybe<IAssetXWorkOrder>;
  /** update multiples rows of table: "asset_x_work_order" */
  update_asset_x_work_order_many?: Maybe<Array<Maybe<IAssetXWorkOrderMutationResponse>>>;
  /** update data of the table: "block.element" */
  update_block_element?: Maybe<IBlockElementMutationResponse>;
  /** update single row of the table: "block.element" */
  update_block_element_by_pk?: Maybe<IBlockElement>;
  /** update multiples rows of table: "block.element" */
  update_block_element_many?: Maybe<Array<Maybe<IBlockElementMutationResponse>>>;
  /** update data of the table: "block.element_type" */
  update_block_element_type?: Maybe<IBlockElementTypeMutationResponse>;
  /** update single row of the table: "block.element_type" */
  update_block_element_type_by_pk?: Maybe<IBlockElementType>;
  /** update multiples rows of table: "block.element_type" */
  update_block_element_type_many?: Maybe<Array<Maybe<IBlockElementTypeMutationResponse>>>;
  /** update data of the table: "block.element_x_upload" */
  update_block_element_x_upload?: Maybe<IBlockElementXUploadMutationResponse>;
  /** update single row of the table: "block.element_x_upload" */
  update_block_element_x_upload_by_pk?: Maybe<IBlockElementXUpload>;
  /** update multiples rows of table: "block.element_x_upload" */
  update_block_element_x_upload_many?: Maybe<Array<Maybe<IBlockElementXUploadMutationResponse>>>;
  /** update data of the table: "block.group" */
  update_block_group?: Maybe<IBlockGroupMutationResponse>;
  /** update single row of the table: "block.group" */
  update_block_group_by_pk?: Maybe<IBlockGroup>;
  /** update multiples rows of table: "block.group" */
  update_block_group_many?: Maybe<Array<Maybe<IBlockGroupMutationResponse>>>;
  /** update data of the table: "block.group_type" */
  update_block_group_type?: Maybe<IBlockGroupTypeMutationResponse>;
  /** update single row of the table: "block.group_type" */
  update_block_group_type_by_pk?: Maybe<IBlockGroupType>;
  /** update multiples rows of table: "block.group_type" */
  update_block_group_type_many?: Maybe<Array<Maybe<IBlockGroupTypeMutationResponse>>>;
  /** update data of the table: "comment" */
  update_comment?: Maybe<ICommentMutationResponse>;
  /** update single row of the table: "comment" */
  update_comment_by_pk?: Maybe<IComment>;
  /** update multiples rows of table: "comment" */
  update_comment_many?: Maybe<Array<Maybe<ICommentMutationResponse>>>;
  /** update data of the table: "comment_x_upload" */
  update_comment_x_upload?: Maybe<ICommentXUploadMutationResponse>;
  /** update single row of the table: "comment_x_upload" */
  update_comment_x_upload_by_pk?: Maybe<ICommentXUpload>;
  /** update multiples rows of table: "comment_x_upload" */
  update_comment_x_upload_many?: Maybe<Array<Maybe<ICommentXUploadMutationResponse>>>;
  /** update data of the table: "consumable" */
  update_consumable?: Maybe<IConsumableMutationResponse>;
  /** update single row of the table: "consumable" */
  update_consumable_by_pk?: Maybe<IConsumable>;
  /** update data of the table: "consumable_config" */
  update_consumable_config?: Maybe<IConsumableConfigMutationResponse>;
  /** update single row of the table: "consumable_config" */
  update_consumable_config_by_pk?: Maybe<IConsumableConfig>;
  /** update multiples rows of table: "consumable_config" */
  update_consumable_config_many?: Maybe<Array<Maybe<IConsumableConfigMutationResponse>>>;
  /** update data of the table: "consumable_group" */
  update_consumable_group?: Maybe<IConsumableGroupMutationResponse>;
  /** update single row of the table: "consumable_group" */
  update_consumable_group_by_pk?: Maybe<IConsumableGroup>;
  /** update multiples rows of table: "consumable_group" */
  update_consumable_group_many?: Maybe<Array<Maybe<IConsumableGroupMutationResponse>>>;
  /** update data of the table: "consumable_log" */
  update_consumable_log?: Maybe<IConsumableLogMutationResponse>;
  /** update single row of the table: "consumable_log" */
  update_consumable_log_by_pk?: Maybe<IConsumableLog>;
  /** update multiples rows of table: "consumable_log" */
  update_consumable_log_many?: Maybe<Array<Maybe<IConsumableLogMutationResponse>>>;
  /** update multiples rows of table: "consumable" */
  update_consumable_many?: Maybe<Array<Maybe<IConsumableMutationResponse>>>;
  /** update data of the table: "consumable_storage_location" */
  update_consumable_storage_location?: Maybe<IConsumableStorageLocationMutationResponse>;
  /** update single row of the table: "consumable_storage_location" */
  update_consumable_storage_location_by_pk?: Maybe<IConsumableStorageLocation>;
  /** update multiples rows of table: "consumable_storage_location" */
  update_consumable_storage_location_many?: Maybe<Array<Maybe<IConsumableStorageLocationMutationResponse>>>;
  /** update data of the table: "consumable_x_upload" */
  update_consumable_x_upload?: Maybe<IConsumableXUploadMutationResponse>;
  /** update single row of the table: "consumable_x_upload" */
  update_consumable_x_upload_by_pk?: Maybe<IConsumableXUpload>;
  /** update multiples rows of table: "consumable_x_upload" */
  update_consumable_x_upload_many?: Maybe<Array<Maybe<IConsumableXUploadMutationResponse>>>;
  /** update data of the table: "contact" */
  update_contact?: Maybe<IContactMutationResponse>;
  /** update single row of the table: "contact" */
  update_contact_by_pk?: Maybe<IContact>;
  /** update multiples rows of table: "contact" */
  update_contact_many?: Maybe<Array<Maybe<IContactMutationResponse>>>;
  /** update data of the table: "custom_migration" */
  update_custom_migration?: Maybe<ICustomMigrationMutationResponse>;
  /** update single row of the table: "custom_migration" */
  update_custom_migration_by_pk?: Maybe<ICustomMigration>;
  /** update multiples rows of table: "custom_migration" */
  update_custom_migration_many?: Maybe<Array<Maybe<ICustomMigrationMutationResponse>>>;
  /** update data of the table: "custom_view" */
  update_custom_view?: Maybe<ICustomViewMutationResponse>;
  /** update single row of the table: "custom_view" */
  update_custom_view_by_pk?: Maybe<ICustomView>;
  /** update multiples rows of table: "custom_view" */
  update_custom_view_many?: Maybe<Array<Maybe<ICustomViewMutationResponse>>>;
  /** update data of the table: "custom_view_user_favorite" */
  update_custom_view_user_favorite?: Maybe<ICustomViewUserFavoriteMutationResponse>;
  /** update single row of the table: "custom_view_user_favorite" */
  update_custom_view_user_favorite_by_pk?: Maybe<ICustomViewUserFavorite>;
  /** update multiples rows of table: "custom_view_user_favorite" */
  update_custom_view_user_favorite_many?: Maybe<Array<Maybe<ICustomViewUserFavoriteMutationResponse>>>;
  /** update data of the table: "customer_feedback" */
  update_customer_feedback?: Maybe<ICustomerFeedbackMutationResponse>;
  /** update single row of the table: "customer_feedback" */
  update_customer_feedback_by_pk?: Maybe<ICustomerFeedback>;
  /** update multiples rows of table: "customer_feedback" */
  update_customer_feedback_many?: Maybe<Array<Maybe<ICustomerFeedbackMutationResponse>>>;
  /** update data of the table: "feature_flag" */
  update_feature_flag?: Maybe<IFeatureFlagMutationResponse>;
  /** update single row of the table: "feature_flag" */
  update_feature_flag_by_pk?: Maybe<IFeatureFlag>;
  /** update multiples rows of table: "feature_flag" */
  update_feature_flag_many?: Maybe<Array<Maybe<IFeatureFlagMutationResponse>>>;
  /** update data of the table: "file_folder" */
  update_file_folder?: Maybe<IFileFolderMutationResponse>;
  /** update single row of the table: "file_folder" */
  update_file_folder_by_pk?: Maybe<IFileFolder>;
  /** update multiples rows of table: "file_folder" */
  update_file_folder_many?: Maybe<Array<Maybe<IFileFolderMutationResponse>>>;
  /** update data of the table: "file_folder_x_upload" */
  update_file_folder_x_upload?: Maybe<IFileFolderXUploadMutationResponse>;
  /** update single row of the table: "file_folder_x_upload" */
  update_file_folder_x_upload_by_pk?: Maybe<IFileFolderXUpload>;
  /** update multiples rows of table: "file_folder_x_upload" */
  update_file_folder_x_upload_many?: Maybe<Array<Maybe<IFileFolderXUploadMutationResponse>>>;
  /** update data of the table: "inspection_result" */
  update_inspection_result?: Maybe<IInspectionResultMutationResponse>;
  /** update single row of the table: "inspection_result" */
  update_inspection_result_by_pk?: Maybe<IInspectionResult>;
  /** update multiples rows of table: "inspection_result" */
  update_inspection_result_many?: Maybe<Array<Maybe<IInspectionResultMutationResponse>>>;
  /** update data of the table: "jobs.cron_job_completed" */
  update_jobs_cron_job_completed?: Maybe<IJobsCronJobCompletedMutationResponse>;
  /** update single row of the table: "jobs.cron_job_completed" */
  update_jobs_cron_job_completed_by_pk?: Maybe<IJobsCronJobCompleted>;
  /** update multiples rows of table: "jobs.cron_job_completed" */
  update_jobs_cron_job_completed_many?: Maybe<Array<Maybe<IJobsCronJobCompletedMutationResponse>>>;
  /** update data of the table: "location" */
  update_location?: Maybe<ILocationMutationResponse>;
  /** update data of the table: "location_api_key" */
  update_location_api_key?: Maybe<ILocationApiKeyMutationResponse>;
  /** update single row of the table: "location_api_key" */
  update_location_api_key_by_pk?: Maybe<ILocationApiKey>;
  /** update multiples rows of table: "location_api_key" */
  update_location_api_key_many?: Maybe<Array<Maybe<ILocationApiKeyMutationResponse>>>;
  /** update single row of the table: "location" */
  update_location_by_pk?: Maybe<ILocation>;
  /** update data of the table: "location_invite" */
  update_location_invite?: Maybe<ILocationInviteMutationResponse>;
  /** update single row of the table: "location_invite" */
  update_location_invite_by_pk?: Maybe<ILocationInvite>;
  /** update multiples rows of table: "location_invite" */
  update_location_invite_many?: Maybe<Array<Maybe<ILocationInviteMutationResponse>>>;
  /** update multiples rows of table: "location" */
  update_location_many?: Maybe<Array<Maybe<ILocationMutationResponse>>>;
  /** update data of the table: "location_member" */
  update_location_member?: Maybe<ILocationMemberMutationResponse>;
  /** update single row of the table: "location_member" */
  update_location_member_by_pk?: Maybe<ILocationMember>;
  /** update multiples rows of table: "location_member" */
  update_location_member_many?: Maybe<Array<Maybe<ILocationMemberMutationResponse>>>;
  /** update data of the table: "location_member_role" */
  update_location_member_role?: Maybe<ILocationMemberRoleMutationResponse>;
  /** update single row of the table: "location_member_role" */
  update_location_member_role_by_pk?: Maybe<ILocationMemberRole>;
  /** update multiples rows of table: "location_member_role" */
  update_location_member_role_many?: Maybe<Array<Maybe<ILocationMemberRoleMutationResponse>>>;
  /** update data of the table: "location_x_feature_flag" */
  update_location_x_feature_flag?: Maybe<ILocationXFeatureFlagMutationResponse>;
  /** update single row of the table: "location_x_feature_flag" */
  update_location_x_feature_flag_by_pk?: Maybe<ILocationXFeatureFlag>;
  /** update multiples rows of table: "location_x_feature_flag" */
  update_location_x_feature_flag_many?: Maybe<Array<Maybe<ILocationXFeatureFlagMutationResponse>>>;
  /** update data of the table: "maintenance" */
  update_maintenance?: Maybe<IMaintenanceMutationResponse>;
  /** update single row of the table: "maintenance" */
  update_maintenance_by_pk?: Maybe<IMaintenance>;
  /** update multiples rows of table: "maintenance" */
  update_maintenance_many?: Maybe<Array<Maybe<IMaintenanceMutationResponse>>>;
  /** update data of the table: "maintenance_trigger" */
  update_maintenance_trigger?: Maybe<IMaintenanceTriggerMutationResponse>;
  /** update single row of the table: "maintenance_trigger" */
  update_maintenance_trigger_by_pk?: Maybe<IMaintenanceTrigger>;
  /** update multiples rows of table: "maintenance_trigger" */
  update_maintenance_trigger_many?: Maybe<Array<Maybe<IMaintenanceTriggerMutationResponse>>>;
  /** update data of the table: "maintenance_trigger_task" */
  update_maintenance_trigger_task?: Maybe<IMaintenanceTriggerTaskMutationResponse>;
  /** update single row of the table: "maintenance_trigger_task" */
  update_maintenance_trigger_task_by_pk?: Maybe<IMaintenanceTriggerTask>;
  /** update multiples rows of table: "maintenance_trigger_task" */
  update_maintenance_trigger_task_many?: Maybe<Array<Maybe<IMaintenanceTriggerTaskMutationResponse>>>;
  /** update data of the table: "maintenance_trigger_type" */
  update_maintenance_trigger_type?: Maybe<IMaintenanceTriggerTypeMutationResponse>;
  /** update single row of the table: "maintenance_trigger_type" */
  update_maintenance_trigger_type_by_pk?: Maybe<IMaintenanceTriggerType>;
  /** update multiples rows of table: "maintenance_trigger_type" */
  update_maintenance_trigger_type_many?: Maybe<Array<Maybe<IMaintenanceTriggerTypeMutationResponse>>>;
  /** update data of the table: "meter" */
  update_meter?: Maybe<IMeterMutationResponse>;
  /** update single row of the table: "meter" */
  update_meter_by_pk?: Maybe<IMeter>;
  /** update multiples rows of table: "meter" */
  update_meter_many?: Maybe<Array<Maybe<IMeterMutationResponse>>>;
  /** update data of the table: "meter_reading" */
  update_meter_reading?: Maybe<IMeterReadingMutationResponse>;
  /** update single row of the table: "meter_reading" */
  update_meter_reading_by_pk?: Maybe<IMeterReading>;
  /** update multiples rows of table: "meter_reading" */
  update_meter_reading_many?: Maybe<Array<Maybe<IMeterReadingMutationResponse>>>;
  /** update data of the table: "meter_type" */
  update_meter_type?: Maybe<IMeterTypeMutationResponse>;
  /** update single row of the table: "meter_type" */
  update_meter_type_by_pk?: Maybe<IMeterType>;
  /** update multiples rows of table: "meter_type" */
  update_meter_type_many?: Maybe<Array<Maybe<IMeterTypeMutationResponse>>>;
  /** update data of the table: "notification" */
  update_notification?: Maybe<INotificationMutationResponse>;
  /** update single row of the table: "notification" */
  update_notification_by_pk?: Maybe<INotification>;
  /** update multiples rows of table: "notification" */
  update_notification_many?: Maybe<Array<Maybe<INotificationMutationResponse>>>;
  /** update data of the table: "notification_type" */
  update_notification_type?: Maybe<INotificationTypeMutationResponse>;
  /** update single row of the table: "notification_type" */
  update_notification_type_by_pk?: Maybe<INotificationType>;
  /** update multiples rows of table: "notification_type" */
  update_notification_type_many?: Maybe<Array<Maybe<INotificationTypeMutationResponse>>>;
  /** update data of the table: "org" */
  update_org?: Maybe<IOrgMutationResponse>;
  /** update single row of the table: "org" */
  update_org_by_pk?: Maybe<IOrg>;
  /** update data of the table: "org_feature" */
  update_org_feature?: Maybe<IOrgFeatureMutationResponse>;
  /** update single row of the table: "org_feature" */
  update_org_feature_by_pk?: Maybe<IOrgFeature>;
  /** update multiples rows of table: "org_feature" */
  update_org_feature_many?: Maybe<Array<Maybe<IOrgFeatureMutationResponse>>>;
  /** update multiples rows of table: "org" */
  update_org_many?: Maybe<Array<Maybe<IOrgMutationResponse>>>;
  /** update data of the table: "org_prefs" */
  update_org_prefs?: Maybe<IOrgPrefsMutationResponse>;
  /** update single row of the table: "org_prefs" */
  update_org_prefs_by_pk?: Maybe<IOrgPrefs>;
  /** update multiples rows of table: "org_prefs" */
  update_org_prefs_many?: Maybe<Array<Maybe<IOrgPrefsMutationResponse>>>;
  /** update data of the table: "org_sso_settings" */
  update_org_sso_settings?: Maybe<IOrgSsoSettingsMutationResponse>;
  /** update single row of the table: "org_sso_settings" */
  update_org_sso_settings_by_pk?: Maybe<IOrgSsoSettings>;
  /** update multiples rows of table: "org_sso_settings" */
  update_org_sso_settings_many?: Maybe<Array<Maybe<IOrgSsoSettingsMutationResponse>>>;
  /** update data of the table: "permission_role" */
  update_permission_role?: Maybe<IPermissionRoleMutationResponse>;
  /** update single row of the table: "permission_role" */
  update_permission_role_by_pk?: Maybe<IPermissionRole>;
  /** update multiples rows of table: "permission_role" */
  update_permission_role_many?: Maybe<Array<Maybe<IPermissionRoleMutationResponse>>>;
  /** update data of the table: "permission_role_x_feature_flag" */
  update_permission_role_x_feature_flag?: Maybe<IPermissionRoleXFeatureFlagMutationResponse>;
  /** update single row of the table: "permission_role_x_feature_flag" */
  update_permission_role_x_feature_flag_by_pk?: Maybe<IPermissionRoleXFeatureFlag>;
  /** update multiples rows of table: "permission_role_x_feature_flag" */
  update_permission_role_x_feature_flag_many?: Maybe<Array<Maybe<IPermissionRoleXFeatureFlagMutationResponse>>>;
  /** update data of the table: "permission_role_x_permission_scope" */
  update_permission_role_x_permission_scope?: Maybe<IPermissionRoleXPermissionScopeMutationResponse>;
  /** update single row of the table: "permission_role_x_permission_scope" */
  update_permission_role_x_permission_scope_by_pk?: Maybe<IPermissionRoleXPermissionScope>;
  /** update multiples rows of table: "permission_role_x_permission_scope" */
  update_permission_role_x_permission_scope_many?: Maybe<Array<Maybe<IPermissionRoleXPermissionScopeMutationResponse>>>;
  /** update data of the table: "permission_scope" */
  update_permission_scope?: Maybe<IPermissionScopeMutationResponse>;
  /** update single row of the table: "permission_scope" */
  update_permission_scope_by_pk?: Maybe<IPermissionScope>;
  /** update multiples rows of table: "permission_scope" */
  update_permission_scope_many?: Maybe<Array<Maybe<IPermissionScopeMutationResponse>>>;
  /** update data of the table: "place" */
  update_place?: Maybe<IPlaceMutationResponse>;
  /** update single row of the table: "place" */
  update_place_by_pk?: Maybe<IPlace>;
  /** update multiples rows of table: "place" */
  update_place_many?: Maybe<Array<Maybe<IPlaceMutationResponse>>>;
  /** update data of the table: "place_x_upload" */
  update_place_x_upload?: Maybe<IPlaceXUploadMutationResponse>;
  /** update single row of the table: "place_x_upload" */
  update_place_x_upload_by_pk?: Maybe<IPlaceXUpload>;
  /** update multiples rows of table: "place_x_upload" */
  update_place_x_upload_many?: Maybe<Array<Maybe<IPlaceXUploadMutationResponse>>>;
  /** update data of the table: "project" */
  update_project?: Maybe<IProjectMutationResponse>;
  /** update single row of the table: "project" */
  update_project_by_pk?: Maybe<IProject>;
  /** update multiples rows of table: "project" */
  update_project_many?: Maybe<Array<Maybe<IProjectMutationResponse>>>;
  /** update data of the table: "project_status" */
  update_project_status?: Maybe<IProjectStatusMutationResponse>;
  /** update single row of the table: "project_status" */
  update_project_status_by_pk?: Maybe<IProjectStatus>;
  /** update multiples rows of table: "project_status" */
  update_project_status_many?: Maybe<Array<Maybe<IProjectStatusMutationResponse>>>;
  /** update data of the table: "project_x_team" */
  update_project_x_team?: Maybe<IProjectXTeamMutationResponse>;
  /** update single row of the table: "project_x_team" */
  update_project_x_team_by_pk?: Maybe<IProjectXTeam>;
  /** update multiples rows of table: "project_x_team" */
  update_project_x_team_many?: Maybe<Array<Maybe<IProjectXTeamMutationResponse>>>;
  /** update data of the table: "recurrence" */
  update_recurrence?: Maybe<IRecurrenceMutationResponse>;
  /** update single row of the table: "recurrence" */
  update_recurrence_by_pk?: Maybe<IRecurrence>;
  /** update multiples rows of table: "recurrence" */
  update_recurrence_many?: Maybe<Array<Maybe<IRecurrenceMutationResponse>>>;
  /** update data of the table: "recurrence_pattern" */
  update_recurrence_pattern?: Maybe<IRecurrencePatternMutationResponse>;
  /** update single row of the table: "recurrence_pattern" */
  update_recurrence_pattern_by_pk?: Maybe<IRecurrencePattern>;
  /** update multiples rows of table: "recurrence_pattern" */
  update_recurrence_pattern_many?: Maybe<Array<Maybe<IRecurrencePatternMutationResponse>>>;
  /** update data of the table: "scheduled_task" */
  update_scheduled_task?: Maybe<IScheduledTaskMutationResponse>;
  /** update single row of the table: "scheduled_task" */
  update_scheduled_task_by_pk?: Maybe<IScheduledTask>;
  /** update multiples rows of table: "scheduled_task" */
  update_scheduled_task_many?: Maybe<Array<Maybe<IScheduledTaskMutationResponse>>>;
  /** update data of the table: "service_request" */
  update_service_request?: Maybe<IServiceRequestMutationResponse>;
  /** update single row of the table: "service_request" */
  update_service_request_by_pk?: Maybe<IServiceRequest>;
  /** update multiples rows of table: "service_request" */
  update_service_request_many?: Maybe<Array<Maybe<IServiceRequestMutationResponse>>>;
  /** update data of the table: "service_request_status" */
  update_service_request_status?: Maybe<IServiceRequestStatusMutationResponse>;
  /** update single row of the table: "service_request_status" */
  update_service_request_status_by_pk?: Maybe<IServiceRequestStatus>;
  /** update multiples rows of table: "service_request_status" */
  update_service_request_status_many?: Maybe<Array<Maybe<IServiceRequestStatusMutationResponse>>>;
  /** update data of the table: "service_request_x_asset" */
  update_service_request_x_asset?: Maybe<IServiceRequestXAssetMutationResponse>;
  /** update single row of the table: "service_request_x_asset" */
  update_service_request_x_asset_by_pk?: Maybe<IServiceRequestXAsset>;
  /** update multiples rows of table: "service_request_x_asset" */
  update_service_request_x_asset_many?: Maybe<Array<Maybe<IServiceRequestXAssetMutationResponse>>>;
  /** update data of the table: "service_request_x_team" */
  update_service_request_x_team?: Maybe<IServiceRequestXTeamMutationResponse>;
  /** update single row of the table: "service_request_x_team" */
  update_service_request_x_team_by_pk?: Maybe<IServiceRequestXTeam>;
  /** update multiples rows of table: "service_request_x_team" */
  update_service_request_x_team_many?: Maybe<Array<Maybe<IServiceRequestXTeamMutationResponse>>>;
  /** update data of the table: "service_request_x_upload" */
  update_service_request_x_upload?: Maybe<IServiceRequestXUploadMutationResponse>;
  /** update single row of the table: "service_request_x_upload" */
  update_service_request_x_upload_by_pk?: Maybe<IServiceRequestXUpload>;
  /** update multiples rows of table: "service_request_x_upload" */
  update_service_request_x_upload_many?: Maybe<Array<Maybe<IServiceRequestXUploadMutationResponse>>>;
  /** update data of the table: "service_request_x_user" */
  update_service_request_x_user?: Maybe<IServiceRequestXUserMutationResponse>;
  /** update single row of the table: "service_request_x_user" */
  update_service_request_x_user_by_pk?: Maybe<IServiceRequestXUser>;
  /** update multiples rows of table: "service_request_x_user" */
  update_service_request_x_user_many?: Maybe<Array<Maybe<IServiceRequestXUserMutationResponse>>>;
  /** update data of the table: "setting.config" */
  update_setting_config?: Maybe<ISettingConfigMutationResponse>;
  /** update single row of the table: "setting.config" */
  update_setting_config_by_pk?: Maybe<ISettingConfig>;
  /** update multiples rows of table: "setting.config" */
  update_setting_config_many?: Maybe<Array<Maybe<ISettingConfigMutationResponse>>>;
  /** update data of the table: "setting.level" */
  update_setting_level?: Maybe<ISettingLevelMutationResponse>;
  /** update single row of the table: "setting.level" */
  update_setting_level_by_pk?: Maybe<ISettingLevel>;
  /** update multiples rows of table: "setting.level" */
  update_setting_level_many?: Maybe<Array<Maybe<ISettingLevelMutationResponse>>>;
  /** update data of the table: "setting.type" */
  update_setting_type?: Maybe<ISettingTypeMutationResponse>;
  /** update single row of the table: "setting.type" */
  update_setting_type_by_pk?: Maybe<ISettingType>;
  /** update multiples rows of table: "setting.type" */
  update_setting_type_many?: Maybe<Array<Maybe<ISettingTypeMutationResponse>>>;
  /** update data of the table: "team" */
  update_team?: Maybe<ITeamMutationResponse>;
  /** update single row of the table: "team" */
  update_team_by_pk?: Maybe<ITeam>;
  /** update multiples rows of table: "team" */
  update_team_many?: Maybe<Array<Maybe<ITeamMutationResponse>>>;
  /** update data of the table: "team_member" */
  update_team_member?: Maybe<ITeamMemberMutationResponse>;
  /** update single row of the table: "team_member" */
  update_team_member_by_pk?: Maybe<ITeamMember>;
  /** update multiples rows of table: "team_member" */
  update_team_member_many?: Maybe<Array<Maybe<ITeamMemberMutationResponse>>>;
  /** update data of the table: "team_user_role" */
  update_team_user_role?: Maybe<ITeamUserRoleMutationResponse>;
  /** update single row of the table: "team_user_role" */
  update_team_user_role_by_pk?: Maybe<ITeamUserRole>;
  /** update multiples rows of table: "team_user_role" */
  update_team_user_role_many?: Maybe<Array<Maybe<ITeamUserRoleMutationResponse>>>;
  /** update data of the table: "upload" */
  update_upload?: Maybe<IUploadMutationResponse>;
  /** update single row of the table: "upload" */
  update_upload_by_pk?: Maybe<IUpload>;
  /** update data of the table: "upload_category" */
  update_upload_category?: Maybe<IUploadCategoryMutationResponse>;
  /** update single row of the table: "upload_category" */
  update_upload_category_by_pk?: Maybe<IUploadCategory>;
  /** update multiples rows of table: "upload_category" */
  update_upload_category_many?: Maybe<Array<Maybe<IUploadCategoryMutationResponse>>>;
  /** update multiples rows of table: "upload" */
  update_upload_many?: Maybe<Array<Maybe<IUploadMutationResponse>>>;
  /** update data of the table: "upload_x_upload_category" */
  update_upload_x_upload_category?: Maybe<IUploadXUploadCategoryMutationResponse>;
  /** update single row of the table: "upload_x_upload_category" */
  update_upload_x_upload_category_by_pk?: Maybe<IUploadXUploadCategory>;
  /** update multiples rows of table: "upload_x_upload_category" */
  update_upload_x_upload_category_many?: Maybe<Array<Maybe<IUploadXUploadCategoryMutationResponse>>>;
  /** update data of the table: "user" */
  update_user?: Maybe<IUserMutationResponse>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<IUser>;
  /** update data of the table: "user_favorite" */
  update_user_favorite?: Maybe<IUserFavoriteMutationResponse>;
  /** update single row of the table: "user_favorite" */
  update_user_favorite_by_pk?: Maybe<IUserFavorite>;
  /** update multiples rows of table: "user_favorite" */
  update_user_favorite_many?: Maybe<Array<Maybe<IUserFavoriteMutationResponse>>>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<IUserMutationResponse>>>;
  /** update data of the table: "user_recently_used" */
  update_user_recently_used?: Maybe<IUserRecentlyUsedMutationResponse>;
  /** update single row of the table: "user_recently_used" */
  update_user_recently_used_by_pk?: Maybe<IUserRecentlyUsed>;
  /** update multiples rows of table: "user_recently_used" */
  update_user_recently_used_many?: Maybe<Array<Maybe<IUserRecentlyUsedMutationResponse>>>;
  /** update data of the table: "view_collection" */
  update_view_collection?: Maybe<IViewCollectionMutationResponse>;
  /** update single row of the table: "view_collection" */
  update_view_collection_by_pk?: Maybe<IViewCollection>;
  /** update multiples rows of table: "view_collection" */
  update_view_collection_many?: Maybe<Array<Maybe<IViewCollectionMutationResponse>>>;
  /** update data of the table: "view_config" */
  update_view_config?: Maybe<IViewConfigMutationResponse>;
  /** update single row of the table: "view_config" */
  update_view_config_by_pk?: Maybe<IViewConfig>;
  /** update multiples rows of table: "view_config" */
  update_view_config_many?: Maybe<Array<Maybe<IViewConfigMutationResponse>>>;
  /** update data of the table: "view_data_type" */
  update_view_data_type?: Maybe<IViewDataTypeMutationResponse>;
  /** update single row of the table: "view_data_type" */
  update_view_data_type_by_pk?: Maybe<IViewDataType>;
  /** update multiples rows of table: "view_data_type" */
  update_view_data_type_many?: Maybe<Array<Maybe<IViewDataTypeMutationResponse>>>;
  /** update data of the table: "work_order" */
  update_work_order?: Maybe<IWorkOrderMutationResponse>;
  /** update data of the table: "work_order_activity" */
  update_work_order_activity?: Maybe<IWorkOrderActivityMutationResponse>;
  /** update single row of the table: "work_order_activity" */
  update_work_order_activity_by_pk?: Maybe<IWorkOrderActivity>;
  /** update multiples rows of table: "work_order_activity" */
  update_work_order_activity_many?: Maybe<Array<Maybe<IWorkOrderActivityMutationResponse>>>;
  /** update data of the table: "work_order_activity_type" */
  update_work_order_activity_type?: Maybe<IWorkOrderActivityTypeMutationResponse>;
  /** update single row of the table: "work_order_activity_type" */
  update_work_order_activity_type_by_pk?: Maybe<IWorkOrderActivityType>;
  /** update multiples rows of table: "work_order_activity_type" */
  update_work_order_activity_type_many?: Maybe<Array<Maybe<IWorkOrderActivityTypeMutationResponse>>>;
  /** update single row of the table: "work_order" */
  update_work_order_by_pk?: Maybe<IWorkOrder>;
  /** update data of the table: "work_order_category" */
  update_work_order_category?: Maybe<IWorkOrderCategoryMutationResponse>;
  /** update single row of the table: "work_order_category" */
  update_work_order_category_by_pk?: Maybe<IWorkOrderCategory>;
  /** update multiples rows of table: "work_order_category" */
  update_work_order_category_many?: Maybe<Array<Maybe<IWorkOrderCategoryMutationResponse>>>;
  /** update data of the table: "work_order_expense" */
  update_work_order_expense?: Maybe<IWorkOrderExpenseMutationResponse>;
  /** update single row of the table: "work_order_expense" */
  update_work_order_expense_by_pk?: Maybe<IWorkOrderExpense>;
  /** update multiples rows of table: "work_order_expense" */
  update_work_order_expense_many?: Maybe<Array<Maybe<IWorkOrderExpenseMutationResponse>>>;
  /** update data of the table: "work_order_feedback" */
  update_work_order_feedback?: Maybe<IWorkOrderFeedbackMutationResponse>;
  /** update single row of the table: "work_order_feedback" */
  update_work_order_feedback_by_pk?: Maybe<IWorkOrderFeedback>;
  /** update multiples rows of table: "work_order_feedback" */
  update_work_order_feedback_many?: Maybe<Array<Maybe<IWorkOrderFeedbackMutationResponse>>>;
  /** update data of the table: "work_order_last_update" */
  update_work_order_last_update?: Maybe<IWorkOrderLastUpdateMutationResponse>;
  /** update multiples rows of table: "work_order_last_update" */
  update_work_order_last_update_many?: Maybe<Array<Maybe<IWorkOrderLastUpdateMutationResponse>>>;
  /** update multiples rows of table: "work_order" */
  update_work_order_many?: Maybe<Array<Maybe<IWorkOrderMutationResponse>>>;
  /** update data of the table: "work_order_priority" */
  update_work_order_priority?: Maybe<IWorkOrderPriorityMutationResponse>;
  /** update single row of the table: "work_order_priority" */
  update_work_order_priority_by_pk?: Maybe<IWorkOrderPriority>;
  /** update multiples rows of table: "work_order_priority" */
  update_work_order_priority_many?: Maybe<Array<Maybe<IWorkOrderPriorityMutationResponse>>>;
  /** update data of the table: "work_order_report" */
  update_work_order_report?: Maybe<IWorkOrderReportMutationResponse>;
  /** update single row of the table: "work_order_report" */
  update_work_order_report_by_pk?: Maybe<IWorkOrderReport>;
  /** update multiples rows of table: "work_order_report" */
  update_work_order_report_many?: Maybe<Array<Maybe<IWorkOrderReportMutationResponse>>>;
  /** update data of the table: "work_order_status" */
  update_work_order_status?: Maybe<IWorkOrderStatusMutationResponse>;
  /** update single row of the table: "work_order_status" */
  update_work_order_status_by_pk?: Maybe<IWorkOrderStatus>;
  /** update multiples rows of table: "work_order_status" */
  update_work_order_status_many?: Maybe<Array<Maybe<IWorkOrderStatusMutationResponse>>>;
  /** update data of the table: "work_order_template" */
  update_work_order_template?: Maybe<IWorkOrderTemplateMutationResponse>;
  /** update single row of the table: "work_order_template" */
  update_work_order_template_by_pk?: Maybe<IWorkOrderTemplate>;
  /** update multiples rows of table: "work_order_template" */
  update_work_order_template_many?: Maybe<Array<Maybe<IWorkOrderTemplateMutationResponse>>>;
  /** update data of the table: "work_order_type" */
  update_work_order_type?: Maybe<IWorkOrderTypeMutationResponse>;
  /** update single row of the table: "work_order_type" */
  update_work_order_type_by_pk?: Maybe<IWorkOrderType>;
  /** update multiples rows of table: "work_order_type" */
  update_work_order_type_many?: Maybe<Array<Maybe<IWorkOrderTypeMutationResponse>>>;
  /** update data of the table: "work_order_x_collaborator" */
  update_work_order_x_collaborator?: Maybe<IWorkOrderXCollaboratorMutationResponse>;
  /** update single row of the table: "work_order_x_collaborator" */
  update_work_order_x_collaborator_by_pk?: Maybe<IWorkOrderXCollaborator>;
  /** update multiples rows of table: "work_order_x_collaborator" */
  update_work_order_x_collaborator_many?: Maybe<Array<Maybe<IWorkOrderXCollaboratorMutationResponse>>>;
  /** update data of the table: "work_order_x_team" */
  update_work_order_x_team?: Maybe<IWorkOrderXTeamMutationResponse>;
  /** update single row of the table: "work_order_x_team" */
  update_work_order_x_team_by_pk?: Maybe<IWorkOrderXTeam>;
  /** update multiples rows of table: "work_order_x_team" */
  update_work_order_x_team_many?: Maybe<Array<Maybe<IWorkOrderXTeamMutationResponse>>>;
  /** update data of the table: "work_order_x_upload" */
  update_work_order_x_upload?: Maybe<IWorkOrderXUploadMutationResponse>;
  /** update single row of the table: "work_order_x_upload" */
  update_work_order_x_upload_by_pk?: Maybe<IWorkOrderXUpload>;
  /** update multiples rows of table: "work_order_x_upload" */
  update_work_order_x_upload_many?: Maybe<Array<Maybe<IWorkOrderXUploadMutationResponse>>>;
  /** update data of the table: "work_order_x_user" */
  update_work_order_x_user?: Maybe<IWorkOrderXUserMutationResponse>;
  /** update single row of the table: "work_order_x_user" */
  update_work_order_x_user_by_pk?: Maybe<IWorkOrderXUser>;
  /** update multiples rows of table: "work_order_x_user" */
  update_work_order_x_user_many?: Maybe<Array<Maybe<IWorkOrderXUserMutationResponse>>>;
  /** update data of the table: "work_order_x_work_order_category" */
  update_work_order_x_work_order_category?: Maybe<IWorkOrderXWorkOrderCategoryMutationResponse>;
  /** update single row of the table: "work_order_x_work_order_category" */
  update_work_order_x_work_order_category_by_pk?: Maybe<IWorkOrderXWorkOrderCategory>;
  /** update multiples rows of table: "work_order_x_work_order_category" */
  update_work_order_x_work_order_category_many?: Maybe<Array<Maybe<IWorkOrderXWorkOrderCategoryMutationResponse>>>;
  upsertEmbedding?: Maybe<IUpsertEmbeddingOutput>;
  /** execute VOLATILE function "work_order_from_stream" which returns "work_order" */
  work_order_from_stream: Array<IWorkOrder>;
};


/** mutation root */
export type IMutationRootAddUserToLocationArgs = {
  permissionRoleId: Scalars['uuid']['input'];
  teamId?: InputMaybe<Scalars['uuid']['input']>;
  userData?: InputMaybe<IUserRequiredData>;
};


/** mutation root */
export type IMutationRootChangePasswordArgs = {
  newPassword: Scalars['String']['input'];
};


/** mutation root */
export type IMutationRootCheckDuplicateEmailArgs = {
  email: Scalars['String']['input'];
};


/** mutation root */
export type IMutationRootCheckMaintenanceTriggersArgs = {
  maintenanceId: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootCopyAssetArgs = {
  assetId: Scalars['uuid']['input'];
  options?: InputMaybe<ICopyAssetOptions>;
};


/** mutation root */
export type IMutationRootCopyWorkOrderArgs = {
  options?: InputMaybe<ICopyWorkOrderOptions>;
  workOrderId: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootCreateOrgWithOwnerArgs = {
  orgName: Scalars['String']['input'];
  ownerData?: InputMaybe<IUserRequiredData>;
};


/** mutation root */
export type IMutationRootDeleteCompleteOrgArgs = {
  org_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteUserCompletelyArgs = {
  userId: Scalars['String']['input'];
};


/** mutation root */
export type IMutationRootDeleteAnalyticsEventArgs = {
  where: IAnalyticsEventBoolExp;
};


/** mutation root */
export type IMutationRootDeleteAnalyticsEventByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteApiAccessLogArgs = {
  where: IApiAccessLogBoolExp;
};


/** mutation root */
export type IMutationRootDeleteApiAccessLogByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteAssetArgs = {
  where: IAssetBoolExp;
};


/** mutation root */
export type IMutationRootDeleteAssetByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteAssetDefaultStateArgs = {
  where: IAssetDefaultStateBoolExp;
};


/** mutation root */
export type IMutationRootDeleteAssetDefaultStateByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type IMutationRootDeleteAssetGroupArgs = {
  where: IAssetGroupBoolExp;
};


/** mutation root */
export type IMutationRootDeleteAssetGroupByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteAssetManufacturerArgs = {
  where: IAssetManufacturerBoolExp;
};


/** mutation root */
export type IMutationRootDeleteAssetManufacturerByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteAssetOperatingScheduleArgs = {
  where: IAssetOperatingScheduleBoolExp;
};


/** mutation root */
export type IMutationRootDeleteAssetOperatingScheduleByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteAssetStateArgs = {
  where: IAssetStateBoolExp;
};


/** mutation root */
export type IMutationRootDeleteAssetStateByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteAssetStateLogArgs = {
  where: IAssetStateLogBoolExp;
};


/** mutation root */
export type IMutationRootDeleteAssetStateLogByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteAssetStateVariantArgs = {
  where: IAssetStateVariantBoolExp;
};


/** mutation root */
export type IMutationRootDeleteAssetStateVariantByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteAssetStateVariantTypeArgs = {
  where: IAssetStateVariantTypeBoolExp;
};


/** mutation root */
export type IMutationRootDeleteAssetStateVariantTypeByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type IMutationRootDeleteAssetXAssetStateArgs = {
  where: IAssetXAssetStateBoolExp;
};


/** mutation root */
export type IMutationRootDeleteAssetXAssetStateByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteAssetXAssetStateDurationArgs = {
  where: IAssetXAssetStateDurationBoolExp;
};


/** mutation root */
export type IMutationRootDeleteAssetXConsumableArgs = {
  where: IAssetXConsumableBoolExp;
};


/** mutation root */
export type IMutationRootDeleteAssetXConsumableByPkArgs = {
  asset_id: Scalars['uuid']['input'];
  consumable_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteAssetXUploadArgs = {
  where: IAssetXUploadBoolExp;
};


/** mutation root */
export type IMutationRootDeleteAssetXUploadByPkArgs = {
  asset_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteAssetXWorkOrderArgs = {
  where: IAssetXWorkOrderBoolExp;
};


/** mutation root */
export type IMutationRootDeleteAssetXWorkOrderByPkArgs = {
  asset_id: Scalars['uuid']['input'];
  work_order_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteBlockElementArgs = {
  where: IBlockElementBoolExp;
};


/** mutation root */
export type IMutationRootDeleteBlockElementByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteBlockElementTypeArgs = {
  where: IBlockElementTypeBoolExp;
};


/** mutation root */
export type IMutationRootDeleteBlockElementTypeByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type IMutationRootDeleteBlockElementXUploadArgs = {
  where: IBlockElementXUploadBoolExp;
};


/** mutation root */
export type IMutationRootDeleteBlockElementXUploadByPkArgs = {
  element_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteBlockGroupArgs = {
  where: IBlockGroupBoolExp;
};


/** mutation root */
export type IMutationRootDeleteBlockGroupByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteBlockGroupTypeArgs = {
  where: IBlockGroupTypeBoolExp;
};


/** mutation root */
export type IMutationRootDeleteBlockGroupTypeByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type IMutationRootDeleteCommentArgs = {
  where: ICommentBoolExp;
};


/** mutation root */
export type IMutationRootDeleteCommentByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteCommentXUploadArgs = {
  where: ICommentXUploadBoolExp;
};


/** mutation root */
export type IMutationRootDeleteCommentXUploadByPkArgs = {
  comment_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteConsumableArgs = {
  where: IConsumableBoolExp;
};


/** mutation root */
export type IMutationRootDeleteConsumableByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteConsumableConfigArgs = {
  where: IConsumableConfigBoolExp;
};


/** mutation root */
export type IMutationRootDeleteConsumableConfigByPkArgs = {
  location_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteConsumableGroupArgs = {
  where: IConsumableGroupBoolExp;
};


/** mutation root */
export type IMutationRootDeleteConsumableGroupByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteConsumableLogArgs = {
  where: IConsumableLogBoolExp;
};


/** mutation root */
export type IMutationRootDeleteConsumableLogByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteConsumableStorageLocationArgs = {
  where: IConsumableStorageLocationBoolExp;
};


/** mutation root */
export type IMutationRootDeleteConsumableStorageLocationByPkArgs = {
  consumable_id: Scalars['uuid']['input'];
  place_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteConsumableXUploadArgs = {
  where: IConsumableXUploadBoolExp;
};


/** mutation root */
export type IMutationRootDeleteConsumableXUploadByPkArgs = {
  consumable_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteContactArgs = {
  where: IContactBoolExp;
};


/** mutation root */
export type IMutationRootDeleteContactByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteCustomMigrationArgs = {
  where: ICustomMigrationBoolExp;
};


/** mutation root */
export type IMutationRootDeleteCustomMigrationByPkArgs = {
  location_id: Scalars['uuid']['input'];
  migration_id: Scalars['String']['input'];
};


/** mutation root */
export type IMutationRootDeleteCustomViewArgs = {
  where: ICustomViewBoolExp;
};


/** mutation root */
export type IMutationRootDeleteCustomViewByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteCustomViewUserFavoriteArgs = {
  where: ICustomViewUserFavoriteBoolExp;
};


/** mutation root */
export type IMutationRootDeleteCustomViewUserFavoriteByPkArgs = {
  custom_view_id: Scalars['uuid']['input'];
  user_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteCustomerFeedbackArgs = {
  where: ICustomerFeedbackBoolExp;
};


/** mutation root */
export type IMutationRootDeleteCustomerFeedbackByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type IMutationRootDeleteFeatureFlagArgs = {
  where: IFeatureFlagBoolExp;
};


/** mutation root */
export type IMutationRootDeleteFeatureFlagByPkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type IMutationRootDeleteFileFolderArgs = {
  where: IFileFolderBoolExp;
};


/** mutation root */
export type IMutationRootDeleteFileFolderByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteFileFolderXUploadArgs = {
  where: IFileFolderXUploadBoolExp;
};


/** mutation root */
export type IMutationRootDeleteFileFolderXUploadByPkArgs = {
  file_folder_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteInspectionResultArgs = {
  where: IInspectionResultBoolExp;
};


/** mutation root */
export type IMutationRootDeleteInspectionResultByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type IMutationRootDeleteJobsCronJobCompletedArgs = {
  where: IJobsCronJobCompletedBoolExp;
};


/** mutation root */
export type IMutationRootDeleteJobsCronJobCompletedByPkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type IMutationRootDeleteLocationArgs = {
  where: ILocationBoolExp;
};


/** mutation root */
export type IMutationRootDeleteLocationApiKeyArgs = {
  where: ILocationApiKeyBoolExp;
};


/** mutation root */
export type IMutationRootDeleteLocationApiKeyByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteLocationByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteLocationInviteArgs = {
  where: ILocationInviteBoolExp;
};


/** mutation root */
export type IMutationRootDeleteLocationInviteByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteLocationMemberArgs = {
  where: ILocationMemberBoolExp;
};


/** mutation root */
export type IMutationRootDeleteLocationMemberByPkArgs = {
  location_id: Scalars['uuid']['input'];
  user_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteLocationMemberRoleArgs = {
  where: ILocationMemberRoleBoolExp;
};


/** mutation root */
export type IMutationRootDeleteLocationMemberRoleByPkArgs = {
  role: Scalars['String']['input'];
};


/** mutation root */
export type IMutationRootDeleteLocationXFeatureFlagArgs = {
  where: ILocationXFeatureFlagBoolExp;
};


/** mutation root */
export type IMutationRootDeleteLocationXFeatureFlagByPkArgs = {
  feature_flag_id: Scalars['String']['input'];
  location_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteMaintenanceArgs = {
  where: IMaintenanceBoolExp;
};


/** mutation root */
export type IMutationRootDeleteMaintenanceByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteMaintenanceTriggerArgs = {
  where: IMaintenanceTriggerBoolExp;
};


/** mutation root */
export type IMutationRootDeleteMaintenanceTriggerByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteMaintenanceTriggerTaskArgs = {
  where: IMaintenanceTriggerTaskBoolExp;
};


/** mutation root */
export type IMutationRootDeleteMaintenanceTriggerTaskByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteMaintenanceTriggerTypeArgs = {
  where: IMaintenanceTriggerTypeBoolExp;
};


/** mutation root */
export type IMutationRootDeleteMaintenanceTriggerTypeByPkArgs = {
  name: Scalars['String']['input'];
};


/** mutation root */
export type IMutationRootDeleteMeterArgs = {
  where: IMeterBoolExp;
};


/** mutation root */
export type IMutationRootDeleteMeterByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteMeterReadingArgs = {
  where: IMeterReadingBoolExp;
};


/** mutation root */
export type IMutationRootDeleteMeterReadingByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteMeterTypeArgs = {
  where: IMeterTypeBoolExp;
};


/** mutation root */
export type IMutationRootDeleteMeterTypeByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type IMutationRootDeleteNotificationArgs = {
  where: INotificationBoolExp;
};


/** mutation root */
export type IMutationRootDeleteNotificationByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteNotificationTypeArgs = {
  where: INotificationTypeBoolExp;
};


/** mutation root */
export type IMutationRootDeleteNotificationTypeByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type IMutationRootDeleteOrgArgs = {
  where: IOrgBoolExp;
};


/** mutation root */
export type IMutationRootDeleteOrgByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteOrgFeatureArgs = {
  where: IOrgFeatureBoolExp;
};


/** mutation root */
export type IMutationRootDeleteOrgFeatureByPkArgs = {
  org_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteOrgPrefsArgs = {
  where: IOrgPrefsBoolExp;
};


/** mutation root */
export type IMutationRootDeleteOrgPrefsByPkArgs = {
  org_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteOrgSsoSettingsArgs = {
  where: IOrgSsoSettingsBoolExp;
};


/** mutation root */
export type IMutationRootDeleteOrgSsoSettingsByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeletePermissionRoleArgs = {
  where: IPermissionRoleBoolExp;
};


/** mutation root */
export type IMutationRootDeletePermissionRoleByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeletePermissionRoleXFeatureFlagArgs = {
  where: IPermissionRoleXFeatureFlagBoolExp;
};


/** mutation root */
export type IMutationRootDeletePermissionRoleXFeatureFlagByPkArgs = {
  feature_flag: Scalars['String']['input'];
  location_id: Scalars['uuid']['input'];
  permission_role_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeletePermissionRoleXPermissionScopeArgs = {
  where: IPermissionRoleXPermissionScopeBoolExp;
};


/** mutation root */
export type IMutationRootDeletePermissionRoleXPermissionScopeByPkArgs = {
  role_id: Scalars['uuid']['input'];
  scope: IPermissionScopeEnum;
};


/** mutation root */
export type IMutationRootDeletePermissionScopeArgs = {
  where: IPermissionScopeBoolExp;
};


/** mutation root */
export type IMutationRootDeletePermissionScopeByPkArgs = {
  scope: Scalars['String']['input'];
};


/** mutation root */
export type IMutationRootDeletePlaceArgs = {
  where: IPlaceBoolExp;
};


/** mutation root */
export type IMutationRootDeletePlaceByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeletePlaceXUploadArgs = {
  where: IPlaceXUploadBoolExp;
};


/** mutation root */
export type IMutationRootDeletePlaceXUploadByPkArgs = {
  place_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteProjectArgs = {
  where: IProjectBoolExp;
};


/** mutation root */
export type IMutationRootDeleteProjectByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteProjectStatusArgs = {
  where: IProjectStatusBoolExp;
};


/** mutation root */
export type IMutationRootDeleteProjectStatusByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type IMutationRootDeleteProjectXTeamArgs = {
  where: IProjectXTeamBoolExp;
};


/** mutation root */
export type IMutationRootDeleteProjectXTeamByPkArgs = {
  project_id: Scalars['uuid']['input'];
  team_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteRecurrenceArgs = {
  where: IRecurrenceBoolExp;
};


/** mutation root */
export type IMutationRootDeleteRecurrenceByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteRecurrencePatternArgs = {
  where: IRecurrencePatternBoolExp;
};


/** mutation root */
export type IMutationRootDeleteRecurrencePatternByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type IMutationRootDeleteScheduledTaskArgs = {
  where: IScheduledTaskBoolExp;
};


/** mutation root */
export type IMutationRootDeleteScheduledTaskByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteServiceRequestArgs = {
  where: IServiceRequestBoolExp;
};


/** mutation root */
export type IMutationRootDeleteServiceRequestByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type IMutationRootDeleteServiceRequestStatusArgs = {
  where: IServiceRequestStatusBoolExp;
};


/** mutation root */
export type IMutationRootDeleteServiceRequestStatusByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type IMutationRootDeleteServiceRequestXAssetArgs = {
  where: IServiceRequestXAssetBoolExp;
};


/** mutation root */
export type IMutationRootDeleteServiceRequestXAssetByPkArgs = {
  asset_id: Scalars['uuid']['input'];
  service_request_id: Scalars['Int']['input'];
};


/** mutation root */
export type IMutationRootDeleteServiceRequestXTeamArgs = {
  where: IServiceRequestXTeamBoolExp;
};


/** mutation root */
export type IMutationRootDeleteServiceRequestXTeamByPkArgs = {
  service_request_id: Scalars['Int']['input'];
  team_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteServiceRequestXUploadArgs = {
  where: IServiceRequestXUploadBoolExp;
};


/** mutation root */
export type IMutationRootDeleteServiceRequestXUploadByPkArgs = {
  service_request_id: Scalars['Int']['input'];
  upload_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteServiceRequestXUserArgs = {
  where: IServiceRequestXUserBoolExp;
};


/** mutation root */
export type IMutationRootDeleteServiceRequestXUserByPkArgs = {
  service_request_id: Scalars['Int']['input'];
  user_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteSettingConfigArgs = {
  where: ISettingConfigBoolExp;
};


/** mutation root */
export type IMutationRootDeleteSettingConfigByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteSettingLevelArgs = {
  where: ISettingLevelBoolExp;
};


/** mutation root */
export type IMutationRootDeleteSettingLevelByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type IMutationRootDeleteSettingTypeArgs = {
  where: ISettingTypeBoolExp;
};


/** mutation root */
export type IMutationRootDeleteSettingTypeByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type IMutationRootDeleteTeamArgs = {
  where: ITeamBoolExp;
};


/** mutation root */
export type IMutationRootDeleteTeamByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteTeamMemberArgs = {
  where: ITeamMemberBoolExp;
};


/** mutation root */
export type IMutationRootDeleteTeamMemberByPkArgs = {
  team_id: Scalars['uuid']['input'];
  user_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteTeamUserRoleArgs = {
  where: ITeamUserRoleBoolExp;
};


/** mutation root */
export type IMutationRootDeleteTeamUserRoleByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type IMutationRootDeleteUploadArgs = {
  where: IUploadBoolExp;
};


/** mutation root */
export type IMutationRootDeleteUploadByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteUploadCategoryArgs = {
  where: IUploadCategoryBoolExp;
};


/** mutation root */
export type IMutationRootDeleteUploadCategoryByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteUploadXUploadCategoryArgs = {
  where: IUploadXUploadCategoryBoolExp;
};


/** mutation root */
export type IMutationRootDeleteUploadXUploadCategoryByPkArgs = {
  upload_category_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteUserArgs = {
  where: IUserBoolExp;
};


/** mutation root */
export type IMutationRootDeleteUserByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteUserFavoriteArgs = {
  where: IUserFavoriteBoolExp;
};


/** mutation root */
export type IMutationRootDeleteUserFavoriteByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteUserRecentlyUsedArgs = {
  where: IUserRecentlyUsedBoolExp;
};


/** mutation root */
export type IMutationRootDeleteUserRecentlyUsedByPkArgs = {
  entity_id: Scalars['uuid']['input'];
  entity_type: Scalars['String']['input'];
  user_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteViewCollectionArgs = {
  where: IViewCollectionBoolExp;
};


/** mutation root */
export type IMutationRootDeleteViewCollectionByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteViewConfigArgs = {
  where: IViewConfigBoolExp;
};


/** mutation root */
export type IMutationRootDeleteViewConfigByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteViewDataTypeArgs = {
  where: IViewDataTypeBoolExp;
};


/** mutation root */
export type IMutationRootDeleteViewDataTypeByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type IMutationRootDeleteWorkOrderArgs = {
  where: IWorkOrderBoolExp;
};


/** mutation root */
export type IMutationRootDeleteWorkOrderActivityArgs = {
  where: IWorkOrderActivityBoolExp;
};


/** mutation root */
export type IMutationRootDeleteWorkOrderActivityByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteWorkOrderActivityTypeArgs = {
  where: IWorkOrderActivityTypeBoolExp;
};


/** mutation root */
export type IMutationRootDeleteWorkOrderActivityTypeByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type IMutationRootDeleteWorkOrderByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteWorkOrderCategoryArgs = {
  where: IWorkOrderCategoryBoolExp;
};


/** mutation root */
export type IMutationRootDeleteWorkOrderCategoryByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteWorkOrderExpenseArgs = {
  where: IWorkOrderExpenseBoolExp;
};


/** mutation root */
export type IMutationRootDeleteWorkOrderExpenseByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteWorkOrderFeedbackArgs = {
  where: IWorkOrderFeedbackBoolExp;
};


/** mutation root */
export type IMutationRootDeleteWorkOrderFeedbackByPkArgs = {
  work_order_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteWorkOrderLastUpdateArgs = {
  where: IWorkOrderLastUpdateBoolExp;
};


/** mutation root */
export type IMutationRootDeleteWorkOrderPriorityArgs = {
  where: IWorkOrderPriorityBoolExp;
};


/** mutation root */
export type IMutationRootDeleteWorkOrderPriorityByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type IMutationRootDeleteWorkOrderReportArgs = {
  where: IWorkOrderReportBoolExp;
};


/** mutation root */
export type IMutationRootDeleteWorkOrderReportByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteWorkOrderStatusArgs = {
  where: IWorkOrderStatusBoolExp;
};


/** mutation root */
export type IMutationRootDeleteWorkOrderStatusByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type IMutationRootDeleteWorkOrderTemplateArgs = {
  where: IWorkOrderTemplateBoolExp;
};


/** mutation root */
export type IMutationRootDeleteWorkOrderTemplateByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteWorkOrderTypeArgs = {
  where: IWorkOrderTypeBoolExp;
};


/** mutation root */
export type IMutationRootDeleteWorkOrderTypeByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type IMutationRootDeleteWorkOrderXCollaboratorArgs = {
  where: IWorkOrderXCollaboratorBoolExp;
};


/** mutation root */
export type IMutationRootDeleteWorkOrderXCollaboratorByPkArgs = {
  user_id: Scalars['uuid']['input'];
  work_order_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteWorkOrderXTeamArgs = {
  where: IWorkOrderXTeamBoolExp;
};


/** mutation root */
export type IMutationRootDeleteWorkOrderXTeamByPkArgs = {
  team_id: Scalars['uuid']['input'];
  work_order_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteWorkOrderXUploadArgs = {
  where: IWorkOrderXUploadBoolExp;
};


/** mutation root */
export type IMutationRootDeleteWorkOrderXUploadByPkArgs = {
  upload_id: Scalars['uuid']['input'];
  work_order_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteWorkOrderXUserArgs = {
  where: IWorkOrderXUserBoolExp;
};


/** mutation root */
export type IMutationRootDeleteWorkOrderXUserByPkArgs = {
  user_id: Scalars['uuid']['input'];
  work_order_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootDeleteWorkOrderXWorkOrderCategoryArgs = {
  where: IWorkOrderXWorkOrderCategoryBoolExp;
};


/** mutation root */
export type IMutationRootDeleteWorkOrderXWorkOrderCategoryByPkArgs = {
  work_order_category_id: Scalars['uuid']['input'];
  work_order_id: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootInsertAnalyticsEventArgs = {
  objects: Array<IAnalyticsEventInsertInput>;
  on_conflict?: InputMaybe<IAnalyticsEventOnConflict>;
};


/** mutation root */
export type IMutationRootInsertAnalyticsEventOneArgs = {
  object: IAnalyticsEventInsertInput;
  on_conflict?: InputMaybe<IAnalyticsEventOnConflict>;
};


/** mutation root */
export type IMutationRootInsertApiAccessLogArgs = {
  objects: Array<IApiAccessLogInsertInput>;
  on_conflict?: InputMaybe<IApiAccessLogOnConflict>;
};


/** mutation root */
export type IMutationRootInsertApiAccessLogOneArgs = {
  object: IApiAccessLogInsertInput;
  on_conflict?: InputMaybe<IApiAccessLogOnConflict>;
};


/** mutation root */
export type IMutationRootInsertAssetArgs = {
  objects: Array<IAssetInsertInput>;
  on_conflict?: InputMaybe<IAssetOnConflict>;
};


/** mutation root */
export type IMutationRootInsertAssetDefaultStateArgs = {
  objects: Array<IAssetDefaultStateInsertInput>;
  on_conflict?: InputMaybe<IAssetDefaultStateOnConflict>;
};


/** mutation root */
export type IMutationRootInsertAssetDefaultStateOneArgs = {
  object: IAssetDefaultStateInsertInput;
  on_conflict?: InputMaybe<IAssetDefaultStateOnConflict>;
};


/** mutation root */
export type IMutationRootInsertAssetGroupArgs = {
  objects: Array<IAssetGroupInsertInput>;
  on_conflict?: InputMaybe<IAssetGroupOnConflict>;
};


/** mutation root */
export type IMutationRootInsertAssetGroupOneArgs = {
  object: IAssetGroupInsertInput;
  on_conflict?: InputMaybe<IAssetGroupOnConflict>;
};


/** mutation root */
export type IMutationRootInsertAssetManufacturerArgs = {
  objects: Array<IAssetManufacturerInsertInput>;
  on_conflict?: InputMaybe<IAssetManufacturerOnConflict>;
};


/** mutation root */
export type IMutationRootInsertAssetManufacturerOneArgs = {
  object: IAssetManufacturerInsertInput;
  on_conflict?: InputMaybe<IAssetManufacturerOnConflict>;
};


/** mutation root */
export type IMutationRootInsertAssetOneArgs = {
  object: IAssetInsertInput;
  on_conflict?: InputMaybe<IAssetOnConflict>;
};


/** mutation root */
export type IMutationRootInsertAssetOperatingScheduleArgs = {
  objects: Array<IAssetOperatingScheduleInsertInput>;
  on_conflict?: InputMaybe<IAssetOperatingScheduleOnConflict>;
};


/** mutation root */
export type IMutationRootInsertAssetOperatingScheduleOneArgs = {
  object: IAssetOperatingScheduleInsertInput;
  on_conflict?: InputMaybe<IAssetOperatingScheduleOnConflict>;
};


/** mutation root */
export type IMutationRootInsertAssetStateArgs = {
  objects: Array<IAssetStateInsertInput>;
  on_conflict?: InputMaybe<IAssetStateOnConflict>;
};


/** mutation root */
export type IMutationRootInsertAssetStateLogArgs = {
  objects: Array<IAssetStateLogInsertInput>;
  on_conflict?: InputMaybe<IAssetStateLogOnConflict>;
};


/** mutation root */
export type IMutationRootInsertAssetStateLogOneArgs = {
  object: IAssetStateLogInsertInput;
  on_conflict?: InputMaybe<IAssetStateLogOnConflict>;
};


/** mutation root */
export type IMutationRootInsertAssetStateOneArgs = {
  object: IAssetStateInsertInput;
  on_conflict?: InputMaybe<IAssetStateOnConflict>;
};


/** mutation root */
export type IMutationRootInsertAssetStateVariantArgs = {
  objects: Array<IAssetStateVariantInsertInput>;
  on_conflict?: InputMaybe<IAssetStateVariantOnConflict>;
};


/** mutation root */
export type IMutationRootInsertAssetStateVariantOneArgs = {
  object: IAssetStateVariantInsertInput;
  on_conflict?: InputMaybe<IAssetStateVariantOnConflict>;
};


/** mutation root */
export type IMutationRootInsertAssetStateVariantTypeArgs = {
  objects: Array<IAssetStateVariantTypeInsertInput>;
  on_conflict?: InputMaybe<IAssetStateVariantTypeOnConflict>;
};


/** mutation root */
export type IMutationRootInsertAssetStateVariantTypeOneArgs = {
  object: IAssetStateVariantTypeInsertInput;
  on_conflict?: InputMaybe<IAssetStateVariantTypeOnConflict>;
};


/** mutation root */
export type IMutationRootInsertAssetXAssetStateArgs = {
  objects: Array<IAssetXAssetStateInsertInput>;
  on_conflict?: InputMaybe<IAssetXAssetStateOnConflict>;
};


/** mutation root */
export type IMutationRootInsertAssetXAssetStateDurationArgs = {
  objects: Array<IAssetXAssetStateDurationInsertInput>;
};


/** mutation root */
export type IMutationRootInsertAssetXAssetStateDurationOneArgs = {
  object: IAssetXAssetStateDurationInsertInput;
};


/** mutation root */
export type IMutationRootInsertAssetXAssetStateOneArgs = {
  object: IAssetXAssetStateInsertInput;
  on_conflict?: InputMaybe<IAssetXAssetStateOnConflict>;
};


/** mutation root */
export type IMutationRootInsertAssetXConsumableArgs = {
  objects: Array<IAssetXConsumableInsertInput>;
  on_conflict?: InputMaybe<IAssetXConsumableOnConflict>;
};


/** mutation root */
export type IMutationRootInsertAssetXConsumableOneArgs = {
  object: IAssetXConsumableInsertInput;
  on_conflict?: InputMaybe<IAssetXConsumableOnConflict>;
};


/** mutation root */
export type IMutationRootInsertAssetXUploadArgs = {
  objects: Array<IAssetXUploadInsertInput>;
  on_conflict?: InputMaybe<IAssetXUploadOnConflict>;
};


/** mutation root */
export type IMutationRootInsertAssetXUploadOneArgs = {
  object: IAssetXUploadInsertInput;
  on_conflict?: InputMaybe<IAssetXUploadOnConflict>;
};


/** mutation root */
export type IMutationRootInsertAssetXWorkOrderArgs = {
  objects: Array<IAssetXWorkOrderInsertInput>;
  on_conflict?: InputMaybe<IAssetXWorkOrderOnConflict>;
};


/** mutation root */
export type IMutationRootInsertAssetXWorkOrderOneArgs = {
  object: IAssetXWorkOrderInsertInput;
  on_conflict?: InputMaybe<IAssetXWorkOrderOnConflict>;
};


/** mutation root */
export type IMutationRootInsertBlockElementArgs = {
  objects: Array<IBlockElementInsertInput>;
  on_conflict?: InputMaybe<IBlockElementOnConflict>;
};


/** mutation root */
export type IMutationRootInsertBlockElementOneArgs = {
  object: IBlockElementInsertInput;
  on_conflict?: InputMaybe<IBlockElementOnConflict>;
};


/** mutation root */
export type IMutationRootInsertBlockElementTypeArgs = {
  objects: Array<IBlockElementTypeInsertInput>;
  on_conflict?: InputMaybe<IBlockElementTypeOnConflict>;
};


/** mutation root */
export type IMutationRootInsertBlockElementTypeOneArgs = {
  object: IBlockElementTypeInsertInput;
  on_conflict?: InputMaybe<IBlockElementTypeOnConflict>;
};


/** mutation root */
export type IMutationRootInsertBlockElementXUploadArgs = {
  objects: Array<IBlockElementXUploadInsertInput>;
  on_conflict?: InputMaybe<IBlockElementXUploadOnConflict>;
};


/** mutation root */
export type IMutationRootInsertBlockElementXUploadOneArgs = {
  object: IBlockElementXUploadInsertInput;
  on_conflict?: InputMaybe<IBlockElementXUploadOnConflict>;
};


/** mutation root */
export type IMutationRootInsertBlockGroupArgs = {
  objects: Array<IBlockGroupInsertInput>;
  on_conflict?: InputMaybe<IBlockGroupOnConflict>;
};


/** mutation root */
export type IMutationRootInsertBlockGroupOneArgs = {
  object: IBlockGroupInsertInput;
  on_conflict?: InputMaybe<IBlockGroupOnConflict>;
};


/** mutation root */
export type IMutationRootInsertBlockGroupTypeArgs = {
  objects: Array<IBlockGroupTypeInsertInput>;
  on_conflict?: InputMaybe<IBlockGroupTypeOnConflict>;
};


/** mutation root */
export type IMutationRootInsertBlockGroupTypeOneArgs = {
  object: IBlockGroupTypeInsertInput;
  on_conflict?: InputMaybe<IBlockGroupTypeOnConflict>;
};


/** mutation root */
export type IMutationRootInsertCommentArgs = {
  objects: Array<ICommentInsertInput>;
  on_conflict?: InputMaybe<ICommentOnConflict>;
};


/** mutation root */
export type IMutationRootInsertCommentOneArgs = {
  object: ICommentInsertInput;
  on_conflict?: InputMaybe<ICommentOnConflict>;
};


/** mutation root */
export type IMutationRootInsertCommentXUploadArgs = {
  objects: Array<ICommentXUploadInsertInput>;
  on_conflict?: InputMaybe<ICommentXUploadOnConflict>;
};


/** mutation root */
export type IMutationRootInsertCommentXUploadOneArgs = {
  object: ICommentXUploadInsertInput;
  on_conflict?: InputMaybe<ICommentXUploadOnConflict>;
};


/** mutation root */
export type IMutationRootInsertConsumableArgs = {
  objects: Array<IConsumableInsertInput>;
  on_conflict?: InputMaybe<IConsumableOnConflict>;
};


/** mutation root */
export type IMutationRootInsertConsumableConfigArgs = {
  objects: Array<IConsumableConfigInsertInput>;
  on_conflict?: InputMaybe<IConsumableConfigOnConflict>;
};


/** mutation root */
export type IMutationRootInsertConsumableConfigOneArgs = {
  object: IConsumableConfigInsertInput;
  on_conflict?: InputMaybe<IConsumableConfigOnConflict>;
};


/** mutation root */
export type IMutationRootInsertConsumableGroupArgs = {
  objects: Array<IConsumableGroupInsertInput>;
  on_conflict?: InputMaybe<IConsumableGroupOnConflict>;
};


/** mutation root */
export type IMutationRootInsertConsumableGroupOneArgs = {
  object: IConsumableGroupInsertInput;
  on_conflict?: InputMaybe<IConsumableGroupOnConflict>;
};


/** mutation root */
export type IMutationRootInsertConsumableLogArgs = {
  objects: Array<IConsumableLogInsertInput>;
  on_conflict?: InputMaybe<IConsumableLogOnConflict>;
};


/** mutation root */
export type IMutationRootInsertConsumableLogOneArgs = {
  object: IConsumableLogInsertInput;
  on_conflict?: InputMaybe<IConsumableLogOnConflict>;
};


/** mutation root */
export type IMutationRootInsertConsumableOneArgs = {
  object: IConsumableInsertInput;
  on_conflict?: InputMaybe<IConsumableOnConflict>;
};


/** mutation root */
export type IMutationRootInsertConsumableStorageLocationArgs = {
  objects: Array<IConsumableStorageLocationInsertInput>;
  on_conflict?: InputMaybe<IConsumableStorageLocationOnConflict>;
};


/** mutation root */
export type IMutationRootInsertConsumableStorageLocationOneArgs = {
  object: IConsumableStorageLocationInsertInput;
  on_conflict?: InputMaybe<IConsumableStorageLocationOnConflict>;
};


/** mutation root */
export type IMutationRootInsertConsumableXUploadArgs = {
  objects: Array<IConsumableXUploadInsertInput>;
  on_conflict?: InputMaybe<IConsumableXUploadOnConflict>;
};


/** mutation root */
export type IMutationRootInsertConsumableXUploadOneArgs = {
  object: IConsumableXUploadInsertInput;
  on_conflict?: InputMaybe<IConsumableXUploadOnConflict>;
};


/** mutation root */
export type IMutationRootInsertContactArgs = {
  objects: Array<IContactInsertInput>;
  on_conflict?: InputMaybe<IContactOnConflict>;
};


/** mutation root */
export type IMutationRootInsertContactOneArgs = {
  object: IContactInsertInput;
  on_conflict?: InputMaybe<IContactOnConflict>;
};


/** mutation root */
export type IMutationRootInsertCustomMigrationArgs = {
  objects: Array<ICustomMigrationInsertInput>;
  on_conflict?: InputMaybe<ICustomMigrationOnConflict>;
};


/** mutation root */
export type IMutationRootInsertCustomMigrationOneArgs = {
  object: ICustomMigrationInsertInput;
  on_conflict?: InputMaybe<ICustomMigrationOnConflict>;
};


/** mutation root */
export type IMutationRootInsertCustomViewArgs = {
  objects: Array<ICustomViewInsertInput>;
  on_conflict?: InputMaybe<ICustomViewOnConflict>;
};


/** mutation root */
export type IMutationRootInsertCustomViewOneArgs = {
  object: ICustomViewInsertInput;
  on_conflict?: InputMaybe<ICustomViewOnConflict>;
};


/** mutation root */
export type IMutationRootInsertCustomViewUserFavoriteArgs = {
  objects: Array<ICustomViewUserFavoriteInsertInput>;
  on_conflict?: InputMaybe<ICustomViewUserFavoriteOnConflict>;
};


/** mutation root */
export type IMutationRootInsertCustomViewUserFavoriteOneArgs = {
  object: ICustomViewUserFavoriteInsertInput;
  on_conflict?: InputMaybe<ICustomViewUserFavoriteOnConflict>;
};


/** mutation root */
export type IMutationRootInsertCustomerFeedbackArgs = {
  objects: Array<ICustomerFeedbackInsertInput>;
  on_conflict?: InputMaybe<ICustomerFeedbackOnConflict>;
};


/** mutation root */
export type IMutationRootInsertCustomerFeedbackOneArgs = {
  object: ICustomerFeedbackInsertInput;
  on_conflict?: InputMaybe<ICustomerFeedbackOnConflict>;
};


/** mutation root */
export type IMutationRootInsertFeatureFlagArgs = {
  objects: Array<IFeatureFlagInsertInput>;
  on_conflict?: InputMaybe<IFeatureFlagOnConflict>;
};


/** mutation root */
export type IMutationRootInsertFeatureFlagOneArgs = {
  object: IFeatureFlagInsertInput;
  on_conflict?: InputMaybe<IFeatureFlagOnConflict>;
};


/** mutation root */
export type IMutationRootInsertFileFolderArgs = {
  objects: Array<IFileFolderInsertInput>;
  on_conflict?: InputMaybe<IFileFolderOnConflict>;
};


/** mutation root */
export type IMutationRootInsertFileFolderOneArgs = {
  object: IFileFolderInsertInput;
  on_conflict?: InputMaybe<IFileFolderOnConflict>;
};


/** mutation root */
export type IMutationRootInsertFileFolderXUploadArgs = {
  objects: Array<IFileFolderXUploadInsertInput>;
  on_conflict?: InputMaybe<IFileFolderXUploadOnConflict>;
};


/** mutation root */
export type IMutationRootInsertFileFolderXUploadOneArgs = {
  object: IFileFolderXUploadInsertInput;
  on_conflict?: InputMaybe<IFileFolderXUploadOnConflict>;
};


/** mutation root */
export type IMutationRootInsertInspectionResultArgs = {
  objects: Array<IInspectionResultInsertInput>;
  on_conflict?: InputMaybe<IInspectionResultOnConflict>;
};


/** mutation root */
export type IMutationRootInsertInspectionResultOneArgs = {
  object: IInspectionResultInsertInput;
  on_conflict?: InputMaybe<IInspectionResultOnConflict>;
};


/** mutation root */
export type IMutationRootInsertJobsCronJobCompletedArgs = {
  objects: Array<IJobsCronJobCompletedInsertInput>;
  on_conflict?: InputMaybe<IJobsCronJobCompletedOnConflict>;
};


/** mutation root */
export type IMutationRootInsertJobsCronJobCompletedOneArgs = {
  object: IJobsCronJobCompletedInsertInput;
  on_conflict?: InputMaybe<IJobsCronJobCompletedOnConflict>;
};


/** mutation root */
export type IMutationRootInsertLocationArgs = {
  objects: Array<ILocationInsertInput>;
  on_conflict?: InputMaybe<ILocationOnConflict>;
};


/** mutation root */
export type IMutationRootInsertLocationApiKeyArgs = {
  objects: Array<ILocationApiKeyInsertInput>;
  on_conflict?: InputMaybe<ILocationApiKeyOnConflict>;
};


/** mutation root */
export type IMutationRootInsertLocationApiKeyOneArgs = {
  object: ILocationApiKeyInsertInput;
  on_conflict?: InputMaybe<ILocationApiKeyOnConflict>;
};


/** mutation root */
export type IMutationRootInsertLocationInviteArgs = {
  objects: Array<ILocationInviteInsertInput>;
  on_conflict?: InputMaybe<ILocationInviteOnConflict>;
};


/** mutation root */
export type IMutationRootInsertLocationInviteOneArgs = {
  object: ILocationInviteInsertInput;
  on_conflict?: InputMaybe<ILocationInviteOnConflict>;
};


/** mutation root */
export type IMutationRootInsertLocationMemberArgs = {
  objects: Array<ILocationMemberInsertInput>;
  on_conflict?: InputMaybe<ILocationMemberOnConflict>;
};


/** mutation root */
export type IMutationRootInsertLocationMemberOneArgs = {
  object: ILocationMemberInsertInput;
  on_conflict?: InputMaybe<ILocationMemberOnConflict>;
};


/** mutation root */
export type IMutationRootInsertLocationMemberRoleArgs = {
  objects: Array<ILocationMemberRoleInsertInput>;
  on_conflict?: InputMaybe<ILocationMemberRoleOnConflict>;
};


/** mutation root */
export type IMutationRootInsertLocationMemberRoleOneArgs = {
  object: ILocationMemberRoleInsertInput;
  on_conflict?: InputMaybe<ILocationMemberRoleOnConflict>;
};


/** mutation root */
export type IMutationRootInsertLocationOneArgs = {
  object: ILocationInsertInput;
  on_conflict?: InputMaybe<ILocationOnConflict>;
};


/** mutation root */
export type IMutationRootInsertLocationXFeatureFlagArgs = {
  objects: Array<ILocationXFeatureFlagInsertInput>;
  on_conflict?: InputMaybe<ILocationXFeatureFlagOnConflict>;
};


/** mutation root */
export type IMutationRootInsertLocationXFeatureFlagOneArgs = {
  object: ILocationXFeatureFlagInsertInput;
  on_conflict?: InputMaybe<ILocationXFeatureFlagOnConflict>;
};


/** mutation root */
export type IMutationRootInsertMaintenanceArgs = {
  objects: Array<IMaintenanceInsertInput>;
  on_conflict?: InputMaybe<IMaintenanceOnConflict>;
};


/** mutation root */
export type IMutationRootInsertMaintenanceOneArgs = {
  object: IMaintenanceInsertInput;
  on_conflict?: InputMaybe<IMaintenanceOnConflict>;
};


/** mutation root */
export type IMutationRootInsertMaintenanceTriggerArgs = {
  objects: Array<IMaintenanceTriggerInsertInput>;
  on_conflict?: InputMaybe<IMaintenanceTriggerOnConflict>;
};


/** mutation root */
export type IMutationRootInsertMaintenanceTriggerOneArgs = {
  object: IMaintenanceTriggerInsertInput;
  on_conflict?: InputMaybe<IMaintenanceTriggerOnConflict>;
};


/** mutation root */
export type IMutationRootInsertMaintenanceTriggerTaskArgs = {
  objects: Array<IMaintenanceTriggerTaskInsertInput>;
  on_conflict?: InputMaybe<IMaintenanceTriggerTaskOnConflict>;
};


/** mutation root */
export type IMutationRootInsertMaintenanceTriggerTaskOneArgs = {
  object: IMaintenanceTriggerTaskInsertInput;
  on_conflict?: InputMaybe<IMaintenanceTriggerTaskOnConflict>;
};


/** mutation root */
export type IMutationRootInsertMaintenanceTriggerTypeArgs = {
  objects: Array<IMaintenanceTriggerTypeInsertInput>;
  on_conflict?: InputMaybe<IMaintenanceTriggerTypeOnConflict>;
};


/** mutation root */
export type IMutationRootInsertMaintenanceTriggerTypeOneArgs = {
  object: IMaintenanceTriggerTypeInsertInput;
  on_conflict?: InputMaybe<IMaintenanceTriggerTypeOnConflict>;
};


/** mutation root */
export type IMutationRootInsertMeterArgs = {
  objects: Array<IMeterInsertInput>;
  on_conflict?: InputMaybe<IMeterOnConflict>;
};


/** mutation root */
export type IMutationRootInsertMeterOneArgs = {
  object: IMeterInsertInput;
  on_conflict?: InputMaybe<IMeterOnConflict>;
};


/** mutation root */
export type IMutationRootInsertMeterReadingArgs = {
  objects: Array<IMeterReadingInsertInput>;
  on_conflict?: InputMaybe<IMeterReadingOnConflict>;
};


/** mutation root */
export type IMutationRootInsertMeterReadingOneArgs = {
  object: IMeterReadingInsertInput;
  on_conflict?: InputMaybe<IMeterReadingOnConflict>;
};


/** mutation root */
export type IMutationRootInsertMeterTypeArgs = {
  objects: Array<IMeterTypeInsertInput>;
  on_conflict?: InputMaybe<IMeterTypeOnConflict>;
};


/** mutation root */
export type IMutationRootInsertMeterTypeOneArgs = {
  object: IMeterTypeInsertInput;
  on_conflict?: InputMaybe<IMeterTypeOnConflict>;
};


/** mutation root */
export type IMutationRootInsertNotificationArgs = {
  objects: Array<INotificationInsertInput>;
  on_conflict?: InputMaybe<INotificationOnConflict>;
};


/** mutation root */
export type IMutationRootInsertNotificationOneArgs = {
  object: INotificationInsertInput;
  on_conflict?: InputMaybe<INotificationOnConflict>;
};


/** mutation root */
export type IMutationRootInsertNotificationTypeArgs = {
  objects: Array<INotificationTypeInsertInput>;
  on_conflict?: InputMaybe<INotificationTypeOnConflict>;
};


/** mutation root */
export type IMutationRootInsertNotificationTypeOneArgs = {
  object: INotificationTypeInsertInput;
  on_conflict?: InputMaybe<INotificationTypeOnConflict>;
};


/** mutation root */
export type IMutationRootInsertOrgArgs = {
  objects: Array<IOrgInsertInput>;
  on_conflict?: InputMaybe<IOrgOnConflict>;
};


/** mutation root */
export type IMutationRootInsertOrgFeatureArgs = {
  objects: Array<IOrgFeatureInsertInput>;
  on_conflict?: InputMaybe<IOrgFeatureOnConflict>;
};


/** mutation root */
export type IMutationRootInsertOrgFeatureOneArgs = {
  object: IOrgFeatureInsertInput;
  on_conflict?: InputMaybe<IOrgFeatureOnConflict>;
};


/** mutation root */
export type IMutationRootInsertOrgOneArgs = {
  object: IOrgInsertInput;
  on_conflict?: InputMaybe<IOrgOnConflict>;
};


/** mutation root */
export type IMutationRootInsertOrgPrefsArgs = {
  objects: Array<IOrgPrefsInsertInput>;
  on_conflict?: InputMaybe<IOrgPrefsOnConflict>;
};


/** mutation root */
export type IMutationRootInsertOrgPrefsOneArgs = {
  object: IOrgPrefsInsertInput;
  on_conflict?: InputMaybe<IOrgPrefsOnConflict>;
};


/** mutation root */
export type IMutationRootInsertOrgSsoSettingsArgs = {
  objects: Array<IOrgSsoSettingsInsertInput>;
  on_conflict?: InputMaybe<IOrgSsoSettingsOnConflict>;
};


/** mutation root */
export type IMutationRootInsertOrgSsoSettingsOneArgs = {
  object: IOrgSsoSettingsInsertInput;
  on_conflict?: InputMaybe<IOrgSsoSettingsOnConflict>;
};


/** mutation root */
export type IMutationRootInsertPermissionRoleArgs = {
  objects: Array<IPermissionRoleInsertInput>;
  on_conflict?: InputMaybe<IPermissionRoleOnConflict>;
};


/** mutation root */
export type IMutationRootInsertPermissionRoleOneArgs = {
  object: IPermissionRoleInsertInput;
  on_conflict?: InputMaybe<IPermissionRoleOnConflict>;
};


/** mutation root */
export type IMutationRootInsertPermissionRoleXFeatureFlagArgs = {
  objects: Array<IPermissionRoleXFeatureFlagInsertInput>;
  on_conflict?: InputMaybe<IPermissionRoleXFeatureFlagOnConflict>;
};


/** mutation root */
export type IMutationRootInsertPermissionRoleXFeatureFlagOneArgs = {
  object: IPermissionRoleXFeatureFlagInsertInput;
  on_conflict?: InputMaybe<IPermissionRoleXFeatureFlagOnConflict>;
};


/** mutation root */
export type IMutationRootInsertPermissionRoleXPermissionScopeArgs = {
  objects: Array<IPermissionRoleXPermissionScopeInsertInput>;
  on_conflict?: InputMaybe<IPermissionRoleXPermissionScopeOnConflict>;
};


/** mutation root */
export type IMutationRootInsertPermissionRoleXPermissionScopeOneArgs = {
  object: IPermissionRoleXPermissionScopeInsertInput;
  on_conflict?: InputMaybe<IPermissionRoleXPermissionScopeOnConflict>;
};


/** mutation root */
export type IMutationRootInsertPermissionScopeArgs = {
  objects: Array<IPermissionScopeInsertInput>;
  on_conflict?: InputMaybe<IPermissionScopeOnConflict>;
};


/** mutation root */
export type IMutationRootInsertPermissionScopeOneArgs = {
  object: IPermissionScopeInsertInput;
  on_conflict?: InputMaybe<IPermissionScopeOnConflict>;
};


/** mutation root */
export type IMutationRootInsertPlaceArgs = {
  objects: Array<IPlaceInsertInput>;
  on_conflict?: InputMaybe<IPlaceOnConflict>;
};


/** mutation root */
export type IMutationRootInsertPlaceOneArgs = {
  object: IPlaceInsertInput;
  on_conflict?: InputMaybe<IPlaceOnConflict>;
};


/** mutation root */
export type IMutationRootInsertPlaceXUploadArgs = {
  objects: Array<IPlaceXUploadInsertInput>;
  on_conflict?: InputMaybe<IPlaceXUploadOnConflict>;
};


/** mutation root */
export type IMutationRootInsertPlaceXUploadOneArgs = {
  object: IPlaceXUploadInsertInput;
  on_conflict?: InputMaybe<IPlaceXUploadOnConflict>;
};


/** mutation root */
export type IMutationRootInsertProjectArgs = {
  objects: Array<IProjectInsertInput>;
  on_conflict?: InputMaybe<IProjectOnConflict>;
};


/** mutation root */
export type IMutationRootInsertProjectOneArgs = {
  object: IProjectInsertInput;
  on_conflict?: InputMaybe<IProjectOnConflict>;
};


/** mutation root */
export type IMutationRootInsertProjectStatusArgs = {
  objects: Array<IProjectStatusInsertInput>;
  on_conflict?: InputMaybe<IProjectStatusOnConflict>;
};


/** mutation root */
export type IMutationRootInsertProjectStatusOneArgs = {
  object: IProjectStatusInsertInput;
  on_conflict?: InputMaybe<IProjectStatusOnConflict>;
};


/** mutation root */
export type IMutationRootInsertProjectXTeamArgs = {
  objects: Array<IProjectXTeamInsertInput>;
  on_conflict?: InputMaybe<IProjectXTeamOnConflict>;
};


/** mutation root */
export type IMutationRootInsertProjectXTeamOneArgs = {
  object: IProjectXTeamInsertInput;
  on_conflict?: InputMaybe<IProjectXTeamOnConflict>;
};


/** mutation root */
export type IMutationRootInsertRecurrenceArgs = {
  objects: Array<IRecurrenceInsertInput>;
  on_conflict?: InputMaybe<IRecurrenceOnConflict>;
};


/** mutation root */
export type IMutationRootInsertRecurrenceOneArgs = {
  object: IRecurrenceInsertInput;
  on_conflict?: InputMaybe<IRecurrenceOnConflict>;
};


/** mutation root */
export type IMutationRootInsertRecurrencePatternArgs = {
  objects: Array<IRecurrencePatternInsertInput>;
  on_conflict?: InputMaybe<IRecurrencePatternOnConflict>;
};


/** mutation root */
export type IMutationRootInsertRecurrencePatternOneArgs = {
  object: IRecurrencePatternInsertInput;
  on_conflict?: InputMaybe<IRecurrencePatternOnConflict>;
};


/** mutation root */
export type IMutationRootInsertScheduledTaskArgs = {
  objects: Array<IScheduledTaskInsertInput>;
  on_conflict?: InputMaybe<IScheduledTaskOnConflict>;
};


/** mutation root */
export type IMutationRootInsertScheduledTaskOneArgs = {
  object: IScheduledTaskInsertInput;
  on_conflict?: InputMaybe<IScheduledTaskOnConflict>;
};


/** mutation root */
export type IMutationRootInsertServiceRequestArgs = {
  objects: Array<IServiceRequestInsertInput>;
  on_conflict?: InputMaybe<IServiceRequestOnConflict>;
};


/** mutation root */
export type IMutationRootInsertServiceRequestOneArgs = {
  object: IServiceRequestInsertInput;
  on_conflict?: InputMaybe<IServiceRequestOnConflict>;
};


/** mutation root */
export type IMutationRootInsertServiceRequestStatusArgs = {
  objects: Array<IServiceRequestStatusInsertInput>;
  on_conflict?: InputMaybe<IServiceRequestStatusOnConflict>;
};


/** mutation root */
export type IMutationRootInsertServiceRequestStatusOneArgs = {
  object: IServiceRequestStatusInsertInput;
  on_conflict?: InputMaybe<IServiceRequestStatusOnConflict>;
};


/** mutation root */
export type IMutationRootInsertServiceRequestXAssetArgs = {
  objects: Array<IServiceRequestXAssetInsertInput>;
  on_conflict?: InputMaybe<IServiceRequestXAssetOnConflict>;
};


/** mutation root */
export type IMutationRootInsertServiceRequestXAssetOneArgs = {
  object: IServiceRequestXAssetInsertInput;
  on_conflict?: InputMaybe<IServiceRequestXAssetOnConflict>;
};


/** mutation root */
export type IMutationRootInsertServiceRequestXTeamArgs = {
  objects: Array<IServiceRequestXTeamInsertInput>;
  on_conflict?: InputMaybe<IServiceRequestXTeamOnConflict>;
};


/** mutation root */
export type IMutationRootInsertServiceRequestXTeamOneArgs = {
  object: IServiceRequestXTeamInsertInput;
  on_conflict?: InputMaybe<IServiceRequestXTeamOnConflict>;
};


/** mutation root */
export type IMutationRootInsertServiceRequestXUploadArgs = {
  objects: Array<IServiceRequestXUploadInsertInput>;
  on_conflict?: InputMaybe<IServiceRequestXUploadOnConflict>;
};


/** mutation root */
export type IMutationRootInsertServiceRequestXUploadOneArgs = {
  object: IServiceRequestXUploadInsertInput;
  on_conflict?: InputMaybe<IServiceRequestXUploadOnConflict>;
};


/** mutation root */
export type IMutationRootInsertServiceRequestXUserArgs = {
  objects: Array<IServiceRequestXUserInsertInput>;
  on_conflict?: InputMaybe<IServiceRequestXUserOnConflict>;
};


/** mutation root */
export type IMutationRootInsertServiceRequestXUserOneArgs = {
  object: IServiceRequestXUserInsertInput;
  on_conflict?: InputMaybe<IServiceRequestXUserOnConflict>;
};


/** mutation root */
export type IMutationRootInsertSettingConfigArgs = {
  objects: Array<ISettingConfigInsertInput>;
  on_conflict?: InputMaybe<ISettingConfigOnConflict>;
};


/** mutation root */
export type IMutationRootInsertSettingConfigOneArgs = {
  object: ISettingConfigInsertInput;
  on_conflict?: InputMaybe<ISettingConfigOnConflict>;
};


/** mutation root */
export type IMutationRootInsertSettingLevelArgs = {
  objects: Array<ISettingLevelInsertInput>;
  on_conflict?: InputMaybe<ISettingLevelOnConflict>;
};


/** mutation root */
export type IMutationRootInsertSettingLevelOneArgs = {
  object: ISettingLevelInsertInput;
  on_conflict?: InputMaybe<ISettingLevelOnConflict>;
};


/** mutation root */
export type IMutationRootInsertSettingTypeArgs = {
  objects: Array<ISettingTypeInsertInput>;
  on_conflict?: InputMaybe<ISettingTypeOnConflict>;
};


/** mutation root */
export type IMutationRootInsertSettingTypeOneArgs = {
  object: ISettingTypeInsertInput;
  on_conflict?: InputMaybe<ISettingTypeOnConflict>;
};


/** mutation root */
export type IMutationRootInsertTeamArgs = {
  objects: Array<ITeamInsertInput>;
  on_conflict?: InputMaybe<ITeamOnConflict>;
};


/** mutation root */
export type IMutationRootInsertTeamMemberArgs = {
  objects: Array<ITeamMemberInsertInput>;
  on_conflict?: InputMaybe<ITeamMemberOnConflict>;
};


/** mutation root */
export type IMutationRootInsertTeamMemberOneArgs = {
  object: ITeamMemberInsertInput;
  on_conflict?: InputMaybe<ITeamMemberOnConflict>;
};


/** mutation root */
export type IMutationRootInsertTeamOneArgs = {
  object: ITeamInsertInput;
  on_conflict?: InputMaybe<ITeamOnConflict>;
};


/** mutation root */
export type IMutationRootInsertTeamUserRoleArgs = {
  objects: Array<ITeamUserRoleInsertInput>;
  on_conflict?: InputMaybe<ITeamUserRoleOnConflict>;
};


/** mutation root */
export type IMutationRootInsertTeamUserRoleOneArgs = {
  object: ITeamUserRoleInsertInput;
  on_conflict?: InputMaybe<ITeamUserRoleOnConflict>;
};


/** mutation root */
export type IMutationRootInsertUploadArgs = {
  objects: Array<IUploadInsertInput>;
  on_conflict?: InputMaybe<IUploadOnConflict>;
};


/** mutation root */
export type IMutationRootInsertUploadCategoryArgs = {
  objects: Array<IUploadCategoryInsertInput>;
  on_conflict?: InputMaybe<IUploadCategoryOnConflict>;
};


/** mutation root */
export type IMutationRootInsertUploadCategoryOneArgs = {
  object: IUploadCategoryInsertInput;
  on_conflict?: InputMaybe<IUploadCategoryOnConflict>;
};


/** mutation root */
export type IMutationRootInsertUploadOneArgs = {
  object: IUploadInsertInput;
  on_conflict?: InputMaybe<IUploadOnConflict>;
};


/** mutation root */
export type IMutationRootInsertUploadXUploadCategoryArgs = {
  objects: Array<IUploadXUploadCategoryInsertInput>;
  on_conflict?: InputMaybe<IUploadXUploadCategoryOnConflict>;
};


/** mutation root */
export type IMutationRootInsertUploadXUploadCategoryOneArgs = {
  object: IUploadXUploadCategoryInsertInput;
  on_conflict?: InputMaybe<IUploadXUploadCategoryOnConflict>;
};


/** mutation root */
export type IMutationRootInsertUserArgs = {
  objects: Array<IUserInsertInput>;
  on_conflict?: InputMaybe<IUserOnConflict>;
};


/** mutation root */
export type IMutationRootInsertUserFavoriteArgs = {
  objects: Array<IUserFavoriteInsertInput>;
  on_conflict?: InputMaybe<IUserFavoriteOnConflict>;
};


/** mutation root */
export type IMutationRootInsertUserFavoriteOneArgs = {
  object: IUserFavoriteInsertInput;
  on_conflict?: InputMaybe<IUserFavoriteOnConflict>;
};


/** mutation root */
export type IMutationRootInsertUserOneArgs = {
  object: IUserInsertInput;
  on_conflict?: InputMaybe<IUserOnConflict>;
};


/** mutation root */
export type IMutationRootInsertUserRecentlyUsedArgs = {
  objects: Array<IUserRecentlyUsedInsertInput>;
  on_conflict?: InputMaybe<IUserRecentlyUsedOnConflict>;
};


/** mutation root */
export type IMutationRootInsertUserRecentlyUsedOneArgs = {
  object: IUserRecentlyUsedInsertInput;
  on_conflict?: InputMaybe<IUserRecentlyUsedOnConflict>;
};


/** mutation root */
export type IMutationRootInsertViewCollectionArgs = {
  objects: Array<IViewCollectionInsertInput>;
  on_conflict?: InputMaybe<IViewCollectionOnConflict>;
};


/** mutation root */
export type IMutationRootInsertViewCollectionOneArgs = {
  object: IViewCollectionInsertInput;
  on_conflict?: InputMaybe<IViewCollectionOnConflict>;
};


/** mutation root */
export type IMutationRootInsertViewConfigArgs = {
  objects: Array<IViewConfigInsertInput>;
  on_conflict?: InputMaybe<IViewConfigOnConflict>;
};


/** mutation root */
export type IMutationRootInsertViewConfigOneArgs = {
  object: IViewConfigInsertInput;
  on_conflict?: InputMaybe<IViewConfigOnConflict>;
};


/** mutation root */
export type IMutationRootInsertViewDataTypeArgs = {
  objects: Array<IViewDataTypeInsertInput>;
  on_conflict?: InputMaybe<IViewDataTypeOnConflict>;
};


/** mutation root */
export type IMutationRootInsertViewDataTypeOneArgs = {
  object: IViewDataTypeInsertInput;
  on_conflict?: InputMaybe<IViewDataTypeOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderArgs = {
  objects: Array<IWorkOrderInsertInput>;
  on_conflict?: InputMaybe<IWorkOrderOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderActivityArgs = {
  objects: Array<IWorkOrderActivityInsertInput>;
  on_conflict?: InputMaybe<IWorkOrderActivityOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderActivityOneArgs = {
  object: IWorkOrderActivityInsertInput;
  on_conflict?: InputMaybe<IWorkOrderActivityOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderActivityTypeArgs = {
  objects: Array<IWorkOrderActivityTypeInsertInput>;
  on_conflict?: InputMaybe<IWorkOrderActivityTypeOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderActivityTypeOneArgs = {
  object: IWorkOrderActivityTypeInsertInput;
  on_conflict?: InputMaybe<IWorkOrderActivityTypeOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderCategoryArgs = {
  objects: Array<IWorkOrderCategoryInsertInput>;
  on_conflict?: InputMaybe<IWorkOrderCategoryOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderCategoryOneArgs = {
  object: IWorkOrderCategoryInsertInput;
  on_conflict?: InputMaybe<IWorkOrderCategoryOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderExpenseArgs = {
  objects: Array<IWorkOrderExpenseInsertInput>;
  on_conflict?: InputMaybe<IWorkOrderExpenseOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderExpenseOneArgs = {
  object: IWorkOrderExpenseInsertInput;
  on_conflict?: InputMaybe<IWorkOrderExpenseOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderFeedbackArgs = {
  objects: Array<IWorkOrderFeedbackInsertInput>;
  on_conflict?: InputMaybe<IWorkOrderFeedbackOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderFeedbackOneArgs = {
  object: IWorkOrderFeedbackInsertInput;
  on_conflict?: InputMaybe<IWorkOrderFeedbackOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderLastUpdateArgs = {
  objects: Array<IWorkOrderLastUpdateInsertInput>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderLastUpdateOneArgs = {
  object: IWorkOrderLastUpdateInsertInput;
};


/** mutation root */
export type IMutationRootInsertWorkOrderOneArgs = {
  object: IWorkOrderInsertInput;
  on_conflict?: InputMaybe<IWorkOrderOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderPriorityArgs = {
  objects: Array<IWorkOrderPriorityInsertInput>;
  on_conflict?: InputMaybe<IWorkOrderPriorityOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderPriorityOneArgs = {
  object: IWorkOrderPriorityInsertInput;
  on_conflict?: InputMaybe<IWorkOrderPriorityOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderReportArgs = {
  objects: Array<IWorkOrderReportInsertInput>;
  on_conflict?: InputMaybe<IWorkOrderReportOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderReportOneArgs = {
  object: IWorkOrderReportInsertInput;
  on_conflict?: InputMaybe<IWorkOrderReportOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderStatusArgs = {
  objects: Array<IWorkOrderStatusInsertInput>;
  on_conflict?: InputMaybe<IWorkOrderStatusOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderStatusOneArgs = {
  object: IWorkOrderStatusInsertInput;
  on_conflict?: InputMaybe<IWorkOrderStatusOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderTemplateArgs = {
  objects: Array<IWorkOrderTemplateInsertInput>;
  on_conflict?: InputMaybe<IWorkOrderTemplateOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderTemplateOneArgs = {
  object: IWorkOrderTemplateInsertInput;
  on_conflict?: InputMaybe<IWorkOrderTemplateOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderTypeArgs = {
  objects: Array<IWorkOrderTypeInsertInput>;
  on_conflict?: InputMaybe<IWorkOrderTypeOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderTypeOneArgs = {
  object: IWorkOrderTypeInsertInput;
  on_conflict?: InputMaybe<IWorkOrderTypeOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderXCollaboratorArgs = {
  objects: Array<IWorkOrderXCollaboratorInsertInput>;
  on_conflict?: InputMaybe<IWorkOrderXCollaboratorOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderXCollaboratorOneArgs = {
  object: IWorkOrderXCollaboratorInsertInput;
  on_conflict?: InputMaybe<IWorkOrderXCollaboratorOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderXTeamArgs = {
  objects: Array<IWorkOrderXTeamInsertInput>;
  on_conflict?: InputMaybe<IWorkOrderXTeamOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderXTeamOneArgs = {
  object: IWorkOrderXTeamInsertInput;
  on_conflict?: InputMaybe<IWorkOrderXTeamOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderXUploadArgs = {
  objects: Array<IWorkOrderXUploadInsertInput>;
  on_conflict?: InputMaybe<IWorkOrderXUploadOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderXUploadOneArgs = {
  object: IWorkOrderXUploadInsertInput;
  on_conflict?: InputMaybe<IWorkOrderXUploadOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderXUserArgs = {
  objects: Array<IWorkOrderXUserInsertInput>;
  on_conflict?: InputMaybe<IWorkOrderXUserOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderXUserOneArgs = {
  object: IWorkOrderXUserInsertInput;
  on_conflict?: InputMaybe<IWorkOrderXUserOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderXWorkOrderCategoryArgs = {
  objects: Array<IWorkOrderXWorkOrderCategoryInsertInput>;
  on_conflict?: InputMaybe<IWorkOrderXWorkOrderCategoryOnConflict>;
};


/** mutation root */
export type IMutationRootInsertWorkOrderXWorkOrderCategoryOneArgs = {
  object: IWorkOrderXWorkOrderCategoryInsertInput;
  on_conflict?: InputMaybe<IWorkOrderXWorkOrderCategoryOnConflict>;
};


/** mutation root */
export type IMutationRootResetUserPasswordArgs = {
  userId: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootSetupDemoDataArgs = {
  data?: InputMaybe<Scalars['String']['input']>;
  keepUser?: InputMaybe<Scalars['Boolean']['input']>;
};


/** mutation root */
export type IMutationRootSyncUserToKeycloakArgs = {
  userId: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootUpdateUserEmailArgs = {
  email: Scalars['String']['input'];
  userId: Scalars['uuid']['input'];
};


/** mutation root */
export type IMutationRootUpdateAnalyticsEventArgs = {
  _append?: InputMaybe<IAnalyticsEventAppendInput>;
  _delete_at_path?: InputMaybe<IAnalyticsEventDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IAnalyticsEventDeleteElemInput>;
  _delete_key?: InputMaybe<IAnalyticsEventDeleteKeyInput>;
  _prepend?: InputMaybe<IAnalyticsEventPrependInput>;
  _set?: InputMaybe<IAnalyticsEventSetInput>;
  where: IAnalyticsEventBoolExp;
};


/** mutation root */
export type IMutationRootUpdateAnalyticsEventByPkArgs = {
  _append?: InputMaybe<IAnalyticsEventAppendInput>;
  _delete_at_path?: InputMaybe<IAnalyticsEventDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IAnalyticsEventDeleteElemInput>;
  _delete_key?: InputMaybe<IAnalyticsEventDeleteKeyInput>;
  _prepend?: InputMaybe<IAnalyticsEventPrependInput>;
  _set?: InputMaybe<IAnalyticsEventSetInput>;
  pk_columns: IAnalyticsEventPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateAnalyticsEventManyArgs = {
  updates: Array<IAnalyticsEventUpdates>;
};


/** mutation root */
export type IMutationRootUpdateApiAccessLogArgs = {
  _append?: InputMaybe<IApiAccessLogAppendInput>;
  _delete_at_path?: InputMaybe<IApiAccessLogDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IApiAccessLogDeleteElemInput>;
  _delete_key?: InputMaybe<IApiAccessLogDeleteKeyInput>;
  _prepend?: InputMaybe<IApiAccessLogPrependInput>;
  _set?: InputMaybe<IApiAccessLogSetInput>;
  where: IApiAccessLogBoolExp;
};


/** mutation root */
export type IMutationRootUpdateApiAccessLogByPkArgs = {
  _append?: InputMaybe<IApiAccessLogAppendInput>;
  _delete_at_path?: InputMaybe<IApiAccessLogDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IApiAccessLogDeleteElemInput>;
  _delete_key?: InputMaybe<IApiAccessLogDeleteKeyInput>;
  _prepend?: InputMaybe<IApiAccessLogPrependInput>;
  _set?: InputMaybe<IApiAccessLogSetInput>;
  pk_columns: IApiAccessLogPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateApiAccessLogManyArgs = {
  updates: Array<IApiAccessLogUpdates>;
};


/** mutation root */
export type IMutationRootUpdateAssetArgs = {
  _append?: InputMaybe<IAssetAppendInput>;
  _delete_at_path?: InputMaybe<IAssetDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IAssetDeleteElemInput>;
  _delete_key?: InputMaybe<IAssetDeleteKeyInput>;
  _inc?: InputMaybe<IAssetIncInput>;
  _prepend?: InputMaybe<IAssetPrependInput>;
  _set?: InputMaybe<IAssetSetInput>;
  where: IAssetBoolExp;
};


/** mutation root */
export type IMutationRootUpdateAssetByPkArgs = {
  _append?: InputMaybe<IAssetAppendInput>;
  _delete_at_path?: InputMaybe<IAssetDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IAssetDeleteElemInput>;
  _delete_key?: InputMaybe<IAssetDeleteKeyInput>;
  _inc?: InputMaybe<IAssetIncInput>;
  _prepend?: InputMaybe<IAssetPrependInput>;
  _set?: InputMaybe<IAssetSetInput>;
  pk_columns: IAssetPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateAssetDefaultStateArgs = {
  _set?: InputMaybe<IAssetDefaultStateSetInput>;
  where: IAssetDefaultStateBoolExp;
};


/** mutation root */
export type IMutationRootUpdateAssetDefaultStateByPkArgs = {
  _set?: InputMaybe<IAssetDefaultStateSetInput>;
  pk_columns: IAssetDefaultStatePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateAssetDefaultStateManyArgs = {
  updates: Array<IAssetDefaultStateUpdates>;
};


/** mutation root */
export type IMutationRootUpdateAssetGroupArgs = {
  _set?: InputMaybe<IAssetGroupSetInput>;
  where: IAssetGroupBoolExp;
};


/** mutation root */
export type IMutationRootUpdateAssetGroupByPkArgs = {
  _set?: InputMaybe<IAssetGroupSetInput>;
  pk_columns: IAssetGroupPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateAssetGroupManyArgs = {
  updates: Array<IAssetGroupUpdates>;
};


/** mutation root */
export type IMutationRootUpdateAssetManufacturerArgs = {
  _set?: InputMaybe<IAssetManufacturerSetInput>;
  where: IAssetManufacturerBoolExp;
};


/** mutation root */
export type IMutationRootUpdateAssetManufacturerByPkArgs = {
  _set?: InputMaybe<IAssetManufacturerSetInput>;
  pk_columns: IAssetManufacturerPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateAssetManufacturerManyArgs = {
  updates: Array<IAssetManufacturerUpdates>;
};


/** mutation root */
export type IMutationRootUpdateAssetManyArgs = {
  updates: Array<IAssetUpdates>;
};


/** mutation root */
export type IMutationRootUpdateAssetOperatingScheduleArgs = {
  _append?: InputMaybe<IAssetOperatingScheduleAppendInput>;
  _delete_at_path?: InputMaybe<IAssetOperatingScheduleDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IAssetOperatingScheduleDeleteElemInput>;
  _delete_key?: InputMaybe<IAssetOperatingScheduleDeleteKeyInput>;
  _prepend?: InputMaybe<IAssetOperatingSchedulePrependInput>;
  _set?: InputMaybe<IAssetOperatingScheduleSetInput>;
  where: IAssetOperatingScheduleBoolExp;
};


/** mutation root */
export type IMutationRootUpdateAssetOperatingScheduleByPkArgs = {
  _append?: InputMaybe<IAssetOperatingScheduleAppendInput>;
  _delete_at_path?: InputMaybe<IAssetOperatingScheduleDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IAssetOperatingScheduleDeleteElemInput>;
  _delete_key?: InputMaybe<IAssetOperatingScheduleDeleteKeyInput>;
  _prepend?: InputMaybe<IAssetOperatingSchedulePrependInput>;
  _set?: InputMaybe<IAssetOperatingScheduleSetInput>;
  pk_columns: IAssetOperatingSchedulePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateAssetOperatingScheduleManyArgs = {
  updates: Array<IAssetOperatingScheduleUpdates>;
};


/** mutation root */
export type IMutationRootUpdateAssetStateArgs = {
  _set?: InputMaybe<IAssetStateSetInput>;
  where: IAssetStateBoolExp;
};


/** mutation root */
export type IMutationRootUpdateAssetStateByPkArgs = {
  _set?: InputMaybe<IAssetStateSetInput>;
  pk_columns: IAssetStatePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateAssetStateLogArgs = {
  _set?: InputMaybe<IAssetStateLogSetInput>;
  where: IAssetStateLogBoolExp;
};


/** mutation root */
export type IMutationRootUpdateAssetStateLogByPkArgs = {
  _set?: InputMaybe<IAssetStateLogSetInput>;
  pk_columns: IAssetStateLogPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateAssetStateLogManyArgs = {
  updates: Array<IAssetStateLogUpdates>;
};


/** mutation root */
export type IMutationRootUpdateAssetStateManyArgs = {
  updates: Array<IAssetStateUpdates>;
};


/** mutation root */
export type IMutationRootUpdateAssetStateVariantArgs = {
  _set?: InputMaybe<IAssetStateVariantSetInput>;
  where: IAssetStateVariantBoolExp;
};


/** mutation root */
export type IMutationRootUpdateAssetStateVariantByPkArgs = {
  _set?: InputMaybe<IAssetStateVariantSetInput>;
  pk_columns: IAssetStateVariantPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateAssetStateVariantManyArgs = {
  updates: Array<IAssetStateVariantUpdates>;
};


/** mutation root */
export type IMutationRootUpdateAssetStateVariantTypeArgs = {
  _set?: InputMaybe<IAssetStateVariantTypeSetInput>;
  where: IAssetStateVariantTypeBoolExp;
};


/** mutation root */
export type IMutationRootUpdateAssetStateVariantTypeByPkArgs = {
  _set?: InputMaybe<IAssetStateVariantTypeSetInput>;
  pk_columns: IAssetStateVariantTypePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateAssetStateVariantTypeManyArgs = {
  updates: Array<IAssetStateVariantTypeUpdates>;
};


/** mutation root */
export type IMutationRootUpdateAssetXAssetStateArgs = {
  _set?: InputMaybe<IAssetXAssetStateSetInput>;
  where: IAssetXAssetStateBoolExp;
};


/** mutation root */
export type IMutationRootUpdateAssetXAssetStateByPkArgs = {
  _set?: InputMaybe<IAssetXAssetStateSetInput>;
  pk_columns: IAssetXAssetStatePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateAssetXAssetStateDurationArgs = {
  _set?: InputMaybe<IAssetXAssetStateDurationSetInput>;
  where: IAssetXAssetStateDurationBoolExp;
};


/** mutation root */
export type IMutationRootUpdateAssetXAssetStateDurationManyArgs = {
  updates: Array<IAssetXAssetStateDurationUpdates>;
};


/** mutation root */
export type IMutationRootUpdateAssetXAssetStateManyArgs = {
  updates: Array<IAssetXAssetStateUpdates>;
};


/** mutation root */
export type IMutationRootUpdateAssetXConsumableArgs = {
  _set?: InputMaybe<IAssetXConsumableSetInput>;
  where: IAssetXConsumableBoolExp;
};


/** mutation root */
export type IMutationRootUpdateAssetXConsumableByPkArgs = {
  _set?: InputMaybe<IAssetXConsumableSetInput>;
  pk_columns: IAssetXConsumablePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateAssetXConsumableManyArgs = {
  updates: Array<IAssetXConsumableUpdates>;
};


/** mutation root */
export type IMutationRootUpdateAssetXUploadArgs = {
  _set?: InputMaybe<IAssetXUploadSetInput>;
  where: IAssetXUploadBoolExp;
};


/** mutation root */
export type IMutationRootUpdateAssetXUploadByPkArgs = {
  _set?: InputMaybe<IAssetXUploadSetInput>;
  pk_columns: IAssetXUploadPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateAssetXUploadManyArgs = {
  updates: Array<IAssetXUploadUpdates>;
};


/** mutation root */
export type IMutationRootUpdateAssetXWorkOrderArgs = {
  _set?: InputMaybe<IAssetXWorkOrderSetInput>;
  where: IAssetXWorkOrderBoolExp;
};


/** mutation root */
export type IMutationRootUpdateAssetXWorkOrderByPkArgs = {
  _set?: InputMaybe<IAssetXWorkOrderSetInput>;
  pk_columns: IAssetXWorkOrderPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateAssetXWorkOrderManyArgs = {
  updates: Array<IAssetXWorkOrderUpdates>;
};


/** mutation root */
export type IMutationRootUpdateBlockElementArgs = {
  _append?: InputMaybe<IBlockElementAppendInput>;
  _delete_at_path?: InputMaybe<IBlockElementDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IBlockElementDeleteElemInput>;
  _delete_key?: InputMaybe<IBlockElementDeleteKeyInput>;
  _inc?: InputMaybe<IBlockElementIncInput>;
  _prepend?: InputMaybe<IBlockElementPrependInput>;
  _set?: InputMaybe<IBlockElementSetInput>;
  where: IBlockElementBoolExp;
};


/** mutation root */
export type IMutationRootUpdateBlockElementByPkArgs = {
  _append?: InputMaybe<IBlockElementAppendInput>;
  _delete_at_path?: InputMaybe<IBlockElementDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IBlockElementDeleteElemInput>;
  _delete_key?: InputMaybe<IBlockElementDeleteKeyInput>;
  _inc?: InputMaybe<IBlockElementIncInput>;
  _prepend?: InputMaybe<IBlockElementPrependInput>;
  _set?: InputMaybe<IBlockElementSetInput>;
  pk_columns: IBlockElementPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateBlockElementManyArgs = {
  updates: Array<IBlockElementUpdates>;
};


/** mutation root */
export type IMutationRootUpdateBlockElementTypeArgs = {
  _set?: InputMaybe<IBlockElementTypeSetInput>;
  where: IBlockElementTypeBoolExp;
};


/** mutation root */
export type IMutationRootUpdateBlockElementTypeByPkArgs = {
  _set?: InputMaybe<IBlockElementTypeSetInput>;
  pk_columns: IBlockElementTypePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateBlockElementTypeManyArgs = {
  updates: Array<IBlockElementTypeUpdates>;
};


/** mutation root */
export type IMutationRootUpdateBlockElementXUploadArgs = {
  _set?: InputMaybe<IBlockElementXUploadSetInput>;
  where: IBlockElementXUploadBoolExp;
};


/** mutation root */
export type IMutationRootUpdateBlockElementXUploadByPkArgs = {
  _set?: InputMaybe<IBlockElementXUploadSetInput>;
  pk_columns: IBlockElementXUploadPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateBlockElementXUploadManyArgs = {
  updates: Array<IBlockElementXUploadUpdates>;
};


/** mutation root */
export type IMutationRootUpdateBlockGroupArgs = {
  _set?: InputMaybe<IBlockGroupSetInput>;
  where: IBlockGroupBoolExp;
};


/** mutation root */
export type IMutationRootUpdateBlockGroupByPkArgs = {
  _set?: InputMaybe<IBlockGroupSetInput>;
  pk_columns: IBlockGroupPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateBlockGroupManyArgs = {
  updates: Array<IBlockGroupUpdates>;
};


/** mutation root */
export type IMutationRootUpdateBlockGroupTypeArgs = {
  _set?: InputMaybe<IBlockGroupTypeSetInput>;
  where: IBlockGroupTypeBoolExp;
};


/** mutation root */
export type IMutationRootUpdateBlockGroupTypeByPkArgs = {
  _set?: InputMaybe<IBlockGroupTypeSetInput>;
  pk_columns: IBlockGroupTypePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateBlockGroupTypeManyArgs = {
  updates: Array<IBlockGroupTypeUpdates>;
};


/** mutation root */
export type IMutationRootUpdateCommentArgs = {
  _set?: InputMaybe<ICommentSetInput>;
  where: ICommentBoolExp;
};


/** mutation root */
export type IMutationRootUpdateCommentByPkArgs = {
  _set?: InputMaybe<ICommentSetInput>;
  pk_columns: ICommentPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateCommentManyArgs = {
  updates: Array<ICommentUpdates>;
};


/** mutation root */
export type IMutationRootUpdateCommentXUploadArgs = {
  _set?: InputMaybe<ICommentXUploadSetInput>;
  where: ICommentXUploadBoolExp;
};


/** mutation root */
export type IMutationRootUpdateCommentXUploadByPkArgs = {
  _set?: InputMaybe<ICommentXUploadSetInput>;
  pk_columns: ICommentXUploadPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateCommentXUploadManyArgs = {
  updates: Array<ICommentXUploadUpdates>;
};


/** mutation root */
export type IMutationRootUpdateConsumableArgs = {
  _append?: InputMaybe<IConsumableAppendInput>;
  _delete_at_path?: InputMaybe<IConsumableDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IConsumableDeleteElemInput>;
  _delete_key?: InputMaybe<IConsumableDeleteKeyInput>;
  _inc?: InputMaybe<IConsumableIncInput>;
  _prepend?: InputMaybe<IConsumablePrependInput>;
  _set?: InputMaybe<IConsumableSetInput>;
  where: IConsumableBoolExp;
};


/** mutation root */
export type IMutationRootUpdateConsumableByPkArgs = {
  _append?: InputMaybe<IConsumableAppendInput>;
  _delete_at_path?: InputMaybe<IConsumableDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IConsumableDeleteElemInput>;
  _delete_key?: InputMaybe<IConsumableDeleteKeyInput>;
  _inc?: InputMaybe<IConsumableIncInput>;
  _prepend?: InputMaybe<IConsumablePrependInput>;
  _set?: InputMaybe<IConsumableSetInput>;
  pk_columns: IConsumablePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateConsumableConfigArgs = {
  _append?: InputMaybe<IConsumableConfigAppendInput>;
  _delete_at_path?: InputMaybe<IConsumableConfigDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IConsumableConfigDeleteElemInput>;
  _delete_key?: InputMaybe<IConsumableConfigDeleteKeyInput>;
  _prepend?: InputMaybe<IConsumableConfigPrependInput>;
  _set?: InputMaybe<IConsumableConfigSetInput>;
  where: IConsumableConfigBoolExp;
};


/** mutation root */
export type IMutationRootUpdateConsumableConfigByPkArgs = {
  _append?: InputMaybe<IConsumableConfigAppendInput>;
  _delete_at_path?: InputMaybe<IConsumableConfigDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IConsumableConfigDeleteElemInput>;
  _delete_key?: InputMaybe<IConsumableConfigDeleteKeyInput>;
  _prepend?: InputMaybe<IConsumableConfigPrependInput>;
  _set?: InputMaybe<IConsumableConfigSetInput>;
  pk_columns: IConsumableConfigPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateConsumableConfigManyArgs = {
  updates: Array<IConsumableConfigUpdates>;
};


/** mutation root */
export type IMutationRootUpdateConsumableGroupArgs = {
  _set?: InputMaybe<IConsumableGroupSetInput>;
  where: IConsumableGroupBoolExp;
};


/** mutation root */
export type IMutationRootUpdateConsumableGroupByPkArgs = {
  _set?: InputMaybe<IConsumableGroupSetInput>;
  pk_columns: IConsumableGroupPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateConsumableGroupManyArgs = {
  updates: Array<IConsumableGroupUpdates>;
};


/** mutation root */
export type IMutationRootUpdateConsumableLogArgs = {
  _inc?: InputMaybe<IConsumableLogIncInput>;
  _set?: InputMaybe<IConsumableLogSetInput>;
  where: IConsumableLogBoolExp;
};


/** mutation root */
export type IMutationRootUpdateConsumableLogByPkArgs = {
  _inc?: InputMaybe<IConsumableLogIncInput>;
  _set?: InputMaybe<IConsumableLogSetInput>;
  pk_columns: IConsumableLogPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateConsumableLogManyArgs = {
  updates: Array<IConsumableLogUpdates>;
};


/** mutation root */
export type IMutationRootUpdateConsumableManyArgs = {
  updates: Array<IConsumableUpdates>;
};


/** mutation root */
export type IMutationRootUpdateConsumableStorageLocationArgs = {
  _set?: InputMaybe<IConsumableStorageLocationSetInput>;
  where: IConsumableStorageLocationBoolExp;
};


/** mutation root */
export type IMutationRootUpdateConsumableStorageLocationByPkArgs = {
  _set?: InputMaybe<IConsumableStorageLocationSetInput>;
  pk_columns: IConsumableStorageLocationPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateConsumableStorageLocationManyArgs = {
  updates: Array<IConsumableStorageLocationUpdates>;
};


/** mutation root */
export type IMutationRootUpdateConsumableXUploadArgs = {
  _set?: InputMaybe<IConsumableXUploadSetInput>;
  where: IConsumableXUploadBoolExp;
};


/** mutation root */
export type IMutationRootUpdateConsumableXUploadByPkArgs = {
  _set?: InputMaybe<IConsumableXUploadSetInput>;
  pk_columns: IConsumableXUploadPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateConsumableXUploadManyArgs = {
  updates: Array<IConsumableXUploadUpdates>;
};


/** mutation root */
export type IMutationRootUpdateContactArgs = {
  _set?: InputMaybe<IContactSetInput>;
  where: IContactBoolExp;
};


/** mutation root */
export type IMutationRootUpdateContactByPkArgs = {
  _set?: InputMaybe<IContactSetInput>;
  pk_columns: IContactPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateContactManyArgs = {
  updates: Array<IContactUpdates>;
};


/** mutation root */
export type IMutationRootUpdateCustomMigrationArgs = {
  _append?: InputMaybe<ICustomMigrationAppendInput>;
  _delete_at_path?: InputMaybe<ICustomMigrationDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ICustomMigrationDeleteElemInput>;
  _delete_key?: InputMaybe<ICustomMigrationDeleteKeyInput>;
  _prepend?: InputMaybe<ICustomMigrationPrependInput>;
  _set?: InputMaybe<ICustomMigrationSetInput>;
  where: ICustomMigrationBoolExp;
};


/** mutation root */
export type IMutationRootUpdateCustomMigrationByPkArgs = {
  _append?: InputMaybe<ICustomMigrationAppendInput>;
  _delete_at_path?: InputMaybe<ICustomMigrationDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ICustomMigrationDeleteElemInput>;
  _delete_key?: InputMaybe<ICustomMigrationDeleteKeyInput>;
  _prepend?: InputMaybe<ICustomMigrationPrependInput>;
  _set?: InputMaybe<ICustomMigrationSetInput>;
  pk_columns: ICustomMigrationPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateCustomMigrationManyArgs = {
  updates: Array<ICustomMigrationUpdates>;
};


/** mutation root */
export type IMutationRootUpdateCustomViewArgs = {
  _append?: InputMaybe<ICustomViewAppendInput>;
  _delete_at_path?: InputMaybe<ICustomViewDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ICustomViewDeleteElemInput>;
  _delete_key?: InputMaybe<ICustomViewDeleteKeyInput>;
  _inc?: InputMaybe<ICustomViewIncInput>;
  _prepend?: InputMaybe<ICustomViewPrependInput>;
  _set?: InputMaybe<ICustomViewSetInput>;
  where: ICustomViewBoolExp;
};


/** mutation root */
export type IMutationRootUpdateCustomViewByPkArgs = {
  _append?: InputMaybe<ICustomViewAppendInput>;
  _delete_at_path?: InputMaybe<ICustomViewDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ICustomViewDeleteElemInput>;
  _delete_key?: InputMaybe<ICustomViewDeleteKeyInput>;
  _inc?: InputMaybe<ICustomViewIncInput>;
  _prepend?: InputMaybe<ICustomViewPrependInput>;
  _set?: InputMaybe<ICustomViewSetInput>;
  pk_columns: ICustomViewPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateCustomViewManyArgs = {
  updates: Array<ICustomViewUpdates>;
};


/** mutation root */
export type IMutationRootUpdateCustomViewUserFavoriteArgs = {
  _set?: InputMaybe<ICustomViewUserFavoriteSetInput>;
  where: ICustomViewUserFavoriteBoolExp;
};


/** mutation root */
export type IMutationRootUpdateCustomViewUserFavoriteByPkArgs = {
  _set?: InputMaybe<ICustomViewUserFavoriteSetInput>;
  pk_columns: ICustomViewUserFavoritePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateCustomViewUserFavoriteManyArgs = {
  updates: Array<ICustomViewUserFavoriteUpdates>;
};


/** mutation root */
export type IMutationRootUpdateCustomerFeedbackArgs = {
  _append?: InputMaybe<ICustomerFeedbackAppendInput>;
  _delete_at_path?: InputMaybe<ICustomerFeedbackDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ICustomerFeedbackDeleteElemInput>;
  _delete_key?: InputMaybe<ICustomerFeedbackDeleteKeyInput>;
  _inc?: InputMaybe<ICustomerFeedbackIncInput>;
  _prepend?: InputMaybe<ICustomerFeedbackPrependInput>;
  _set?: InputMaybe<ICustomerFeedbackSetInput>;
  where: ICustomerFeedbackBoolExp;
};


/** mutation root */
export type IMutationRootUpdateCustomerFeedbackByPkArgs = {
  _append?: InputMaybe<ICustomerFeedbackAppendInput>;
  _delete_at_path?: InputMaybe<ICustomerFeedbackDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ICustomerFeedbackDeleteElemInput>;
  _delete_key?: InputMaybe<ICustomerFeedbackDeleteKeyInput>;
  _inc?: InputMaybe<ICustomerFeedbackIncInput>;
  _prepend?: InputMaybe<ICustomerFeedbackPrependInput>;
  _set?: InputMaybe<ICustomerFeedbackSetInput>;
  pk_columns: ICustomerFeedbackPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateCustomerFeedbackManyArgs = {
  updates: Array<ICustomerFeedbackUpdates>;
};


/** mutation root */
export type IMutationRootUpdateFeatureFlagArgs = {
  _set?: InputMaybe<IFeatureFlagSetInput>;
  where: IFeatureFlagBoolExp;
};


/** mutation root */
export type IMutationRootUpdateFeatureFlagByPkArgs = {
  _set?: InputMaybe<IFeatureFlagSetInput>;
  pk_columns: IFeatureFlagPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateFeatureFlagManyArgs = {
  updates: Array<IFeatureFlagUpdates>;
};


/** mutation root */
export type IMutationRootUpdateFileFolderArgs = {
  _set?: InputMaybe<IFileFolderSetInput>;
  where: IFileFolderBoolExp;
};


/** mutation root */
export type IMutationRootUpdateFileFolderByPkArgs = {
  _set?: InputMaybe<IFileFolderSetInput>;
  pk_columns: IFileFolderPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateFileFolderManyArgs = {
  updates: Array<IFileFolderUpdates>;
};


/** mutation root */
export type IMutationRootUpdateFileFolderXUploadArgs = {
  _set?: InputMaybe<IFileFolderXUploadSetInput>;
  where: IFileFolderXUploadBoolExp;
};


/** mutation root */
export type IMutationRootUpdateFileFolderXUploadByPkArgs = {
  _set?: InputMaybe<IFileFolderXUploadSetInput>;
  pk_columns: IFileFolderXUploadPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateFileFolderXUploadManyArgs = {
  updates: Array<IFileFolderXUploadUpdates>;
};


/** mutation root */
export type IMutationRootUpdateInspectionResultArgs = {
  _set?: InputMaybe<IInspectionResultSetInput>;
  where: IInspectionResultBoolExp;
};


/** mutation root */
export type IMutationRootUpdateInspectionResultByPkArgs = {
  _set?: InputMaybe<IInspectionResultSetInput>;
  pk_columns: IInspectionResultPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateInspectionResultManyArgs = {
  updates: Array<IInspectionResultUpdates>;
};


/** mutation root */
export type IMutationRootUpdateJobsCronJobCompletedArgs = {
  _set?: InputMaybe<IJobsCronJobCompletedSetInput>;
  where: IJobsCronJobCompletedBoolExp;
};


/** mutation root */
export type IMutationRootUpdateJobsCronJobCompletedByPkArgs = {
  _set?: InputMaybe<IJobsCronJobCompletedSetInput>;
  pk_columns: IJobsCronJobCompletedPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateJobsCronJobCompletedManyArgs = {
  updates: Array<IJobsCronJobCompletedUpdates>;
};


/** mutation root */
export type IMutationRootUpdateLocationArgs = {
  _append?: InputMaybe<ILocationAppendInput>;
  _delete_at_path?: InputMaybe<ILocationDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ILocationDeleteElemInput>;
  _delete_key?: InputMaybe<ILocationDeleteKeyInput>;
  _prepend?: InputMaybe<ILocationPrependInput>;
  _set?: InputMaybe<ILocationSetInput>;
  where: ILocationBoolExp;
};


/** mutation root */
export type IMutationRootUpdateLocationApiKeyArgs = {
  _set?: InputMaybe<ILocationApiKeySetInput>;
  where: ILocationApiKeyBoolExp;
};


/** mutation root */
export type IMutationRootUpdateLocationApiKeyByPkArgs = {
  _set?: InputMaybe<ILocationApiKeySetInput>;
  pk_columns: ILocationApiKeyPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateLocationApiKeyManyArgs = {
  updates: Array<ILocationApiKeyUpdates>;
};


/** mutation root */
export type IMutationRootUpdateLocationByPkArgs = {
  _append?: InputMaybe<ILocationAppendInput>;
  _delete_at_path?: InputMaybe<ILocationDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ILocationDeleteElemInput>;
  _delete_key?: InputMaybe<ILocationDeleteKeyInput>;
  _prepend?: InputMaybe<ILocationPrependInput>;
  _set?: InputMaybe<ILocationSetInput>;
  pk_columns: ILocationPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateLocationInviteArgs = {
  _set?: InputMaybe<ILocationInviteSetInput>;
  where: ILocationInviteBoolExp;
};


/** mutation root */
export type IMutationRootUpdateLocationInviteByPkArgs = {
  _set?: InputMaybe<ILocationInviteSetInput>;
  pk_columns: ILocationInvitePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateLocationInviteManyArgs = {
  updates: Array<ILocationInviteUpdates>;
};


/** mutation root */
export type IMutationRootUpdateLocationManyArgs = {
  updates: Array<ILocationUpdates>;
};


/** mutation root */
export type IMutationRootUpdateLocationMemberArgs = {
  _set?: InputMaybe<ILocationMemberSetInput>;
  where: ILocationMemberBoolExp;
};


/** mutation root */
export type IMutationRootUpdateLocationMemberByPkArgs = {
  _set?: InputMaybe<ILocationMemberSetInput>;
  pk_columns: ILocationMemberPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateLocationMemberManyArgs = {
  updates: Array<ILocationMemberUpdates>;
};


/** mutation root */
export type IMutationRootUpdateLocationMemberRoleArgs = {
  _set?: InputMaybe<ILocationMemberRoleSetInput>;
  where: ILocationMemberRoleBoolExp;
};


/** mutation root */
export type IMutationRootUpdateLocationMemberRoleByPkArgs = {
  _set?: InputMaybe<ILocationMemberRoleSetInput>;
  pk_columns: ILocationMemberRolePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateLocationMemberRoleManyArgs = {
  updates: Array<ILocationMemberRoleUpdates>;
};


/** mutation root */
export type IMutationRootUpdateLocationXFeatureFlagArgs = {
  _set?: InputMaybe<ILocationXFeatureFlagSetInput>;
  where: ILocationXFeatureFlagBoolExp;
};


/** mutation root */
export type IMutationRootUpdateLocationXFeatureFlagByPkArgs = {
  _set?: InputMaybe<ILocationXFeatureFlagSetInput>;
  pk_columns: ILocationXFeatureFlagPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateLocationXFeatureFlagManyArgs = {
  updates: Array<ILocationXFeatureFlagUpdates>;
};


/** mutation root */
export type IMutationRootUpdateMaintenanceArgs = {
  _set?: InputMaybe<IMaintenanceSetInput>;
  where: IMaintenanceBoolExp;
};


/** mutation root */
export type IMutationRootUpdateMaintenanceByPkArgs = {
  _set?: InputMaybe<IMaintenanceSetInput>;
  pk_columns: IMaintenancePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateMaintenanceManyArgs = {
  updates: Array<IMaintenanceUpdates>;
};


/** mutation root */
export type IMutationRootUpdateMaintenanceTriggerArgs = {
  _append?: InputMaybe<IMaintenanceTriggerAppendInput>;
  _delete_at_path?: InputMaybe<IMaintenanceTriggerDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IMaintenanceTriggerDeleteElemInput>;
  _delete_key?: InputMaybe<IMaintenanceTriggerDeleteKeyInput>;
  _prepend?: InputMaybe<IMaintenanceTriggerPrependInput>;
  _set?: InputMaybe<IMaintenanceTriggerSetInput>;
  where: IMaintenanceTriggerBoolExp;
};


/** mutation root */
export type IMutationRootUpdateMaintenanceTriggerByPkArgs = {
  _append?: InputMaybe<IMaintenanceTriggerAppendInput>;
  _delete_at_path?: InputMaybe<IMaintenanceTriggerDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IMaintenanceTriggerDeleteElemInput>;
  _delete_key?: InputMaybe<IMaintenanceTriggerDeleteKeyInput>;
  _prepend?: InputMaybe<IMaintenanceTriggerPrependInput>;
  _set?: InputMaybe<IMaintenanceTriggerSetInput>;
  pk_columns: IMaintenanceTriggerPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateMaintenanceTriggerManyArgs = {
  updates: Array<IMaintenanceTriggerUpdates>;
};


/** mutation root */
export type IMutationRootUpdateMaintenanceTriggerTaskArgs = {
  _inc?: InputMaybe<IMaintenanceTriggerTaskIncInput>;
  _set?: InputMaybe<IMaintenanceTriggerTaskSetInput>;
  where: IMaintenanceTriggerTaskBoolExp;
};


/** mutation root */
export type IMutationRootUpdateMaintenanceTriggerTaskByPkArgs = {
  _inc?: InputMaybe<IMaintenanceTriggerTaskIncInput>;
  _set?: InputMaybe<IMaintenanceTriggerTaskSetInput>;
  pk_columns: IMaintenanceTriggerTaskPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateMaintenanceTriggerTaskManyArgs = {
  updates: Array<IMaintenanceTriggerTaskUpdates>;
};


/** mutation root */
export type IMutationRootUpdateMaintenanceTriggerTypeArgs = {
  _set?: InputMaybe<IMaintenanceTriggerTypeSetInput>;
  where: IMaintenanceTriggerTypeBoolExp;
};


/** mutation root */
export type IMutationRootUpdateMaintenanceTriggerTypeByPkArgs = {
  _set?: InputMaybe<IMaintenanceTriggerTypeSetInput>;
  pk_columns: IMaintenanceTriggerTypePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateMaintenanceTriggerTypeManyArgs = {
  updates: Array<IMaintenanceTriggerTypeUpdates>;
};


/** mutation root */
export type IMutationRootUpdateMeterArgs = {
  _inc?: InputMaybe<IMeterIncInput>;
  _set?: InputMaybe<IMeterSetInput>;
  where: IMeterBoolExp;
};


/** mutation root */
export type IMutationRootUpdateMeterByPkArgs = {
  _inc?: InputMaybe<IMeterIncInput>;
  _set?: InputMaybe<IMeterSetInput>;
  pk_columns: IMeterPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateMeterManyArgs = {
  updates: Array<IMeterUpdates>;
};


/** mutation root */
export type IMutationRootUpdateMeterReadingArgs = {
  _inc?: InputMaybe<IMeterReadingIncInput>;
  _set?: InputMaybe<IMeterReadingSetInput>;
  where: IMeterReadingBoolExp;
};


/** mutation root */
export type IMutationRootUpdateMeterReadingByPkArgs = {
  _inc?: InputMaybe<IMeterReadingIncInput>;
  _set?: InputMaybe<IMeterReadingSetInput>;
  pk_columns: IMeterReadingPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateMeterReadingManyArgs = {
  updates: Array<IMeterReadingUpdates>;
};


/** mutation root */
export type IMutationRootUpdateMeterTypeArgs = {
  _set?: InputMaybe<IMeterTypeSetInput>;
  where: IMeterTypeBoolExp;
};


/** mutation root */
export type IMutationRootUpdateMeterTypeByPkArgs = {
  _set?: InputMaybe<IMeterTypeSetInput>;
  pk_columns: IMeterTypePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateMeterTypeManyArgs = {
  updates: Array<IMeterTypeUpdates>;
};


/** mutation root */
export type IMutationRootUpdateNotificationArgs = {
  _append?: InputMaybe<INotificationAppendInput>;
  _delete_at_path?: InputMaybe<INotificationDeleteAtPathInput>;
  _delete_elem?: InputMaybe<INotificationDeleteElemInput>;
  _delete_key?: InputMaybe<INotificationDeleteKeyInput>;
  _prepend?: InputMaybe<INotificationPrependInput>;
  _set?: InputMaybe<INotificationSetInput>;
  where: INotificationBoolExp;
};


/** mutation root */
export type IMutationRootUpdateNotificationByPkArgs = {
  _append?: InputMaybe<INotificationAppendInput>;
  _delete_at_path?: InputMaybe<INotificationDeleteAtPathInput>;
  _delete_elem?: InputMaybe<INotificationDeleteElemInput>;
  _delete_key?: InputMaybe<INotificationDeleteKeyInput>;
  _prepend?: InputMaybe<INotificationPrependInput>;
  _set?: InputMaybe<INotificationSetInput>;
  pk_columns: INotificationPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateNotificationManyArgs = {
  updates: Array<INotificationUpdates>;
};


/** mutation root */
export type IMutationRootUpdateNotificationTypeArgs = {
  _set?: InputMaybe<INotificationTypeSetInput>;
  where: INotificationTypeBoolExp;
};


/** mutation root */
export type IMutationRootUpdateNotificationTypeByPkArgs = {
  _set?: InputMaybe<INotificationTypeSetInput>;
  pk_columns: INotificationTypePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateNotificationTypeManyArgs = {
  updates: Array<INotificationTypeUpdates>;
};


/** mutation root */
export type IMutationRootUpdateOrgArgs = {
  _set?: InputMaybe<IOrgSetInput>;
  where: IOrgBoolExp;
};


/** mutation root */
export type IMutationRootUpdateOrgByPkArgs = {
  _set?: InputMaybe<IOrgSetInput>;
  pk_columns: IOrgPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateOrgFeatureArgs = {
  _set?: InputMaybe<IOrgFeatureSetInput>;
  where: IOrgFeatureBoolExp;
};


/** mutation root */
export type IMutationRootUpdateOrgFeatureByPkArgs = {
  _set?: InputMaybe<IOrgFeatureSetInput>;
  pk_columns: IOrgFeaturePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateOrgFeatureManyArgs = {
  updates: Array<IOrgFeatureUpdates>;
};


/** mutation root */
export type IMutationRootUpdateOrgManyArgs = {
  updates: Array<IOrgUpdates>;
};


/** mutation root */
export type IMutationRootUpdateOrgPrefsArgs = {
  _set?: InputMaybe<IOrgPrefsSetInput>;
  where: IOrgPrefsBoolExp;
};


/** mutation root */
export type IMutationRootUpdateOrgPrefsByPkArgs = {
  _set?: InputMaybe<IOrgPrefsSetInput>;
  pk_columns: IOrgPrefsPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateOrgPrefsManyArgs = {
  updates: Array<IOrgPrefsUpdates>;
};


/** mutation root */
export type IMutationRootUpdateOrgSsoSettingsArgs = {
  _set?: InputMaybe<IOrgSsoSettingsSetInput>;
  where: IOrgSsoSettingsBoolExp;
};


/** mutation root */
export type IMutationRootUpdateOrgSsoSettingsByPkArgs = {
  _set?: InputMaybe<IOrgSsoSettingsSetInput>;
  pk_columns: IOrgSsoSettingsPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateOrgSsoSettingsManyArgs = {
  updates: Array<IOrgSsoSettingsUpdates>;
};


/** mutation root */
export type IMutationRootUpdatePermissionRoleArgs = {
  _set?: InputMaybe<IPermissionRoleSetInput>;
  where: IPermissionRoleBoolExp;
};


/** mutation root */
export type IMutationRootUpdatePermissionRoleByPkArgs = {
  _set?: InputMaybe<IPermissionRoleSetInput>;
  pk_columns: IPermissionRolePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdatePermissionRoleManyArgs = {
  updates: Array<IPermissionRoleUpdates>;
};


/** mutation root */
export type IMutationRootUpdatePermissionRoleXFeatureFlagArgs = {
  _set?: InputMaybe<IPermissionRoleXFeatureFlagSetInput>;
  where: IPermissionRoleXFeatureFlagBoolExp;
};


/** mutation root */
export type IMutationRootUpdatePermissionRoleXFeatureFlagByPkArgs = {
  _set?: InputMaybe<IPermissionRoleXFeatureFlagSetInput>;
  pk_columns: IPermissionRoleXFeatureFlagPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdatePermissionRoleXFeatureFlagManyArgs = {
  updates: Array<IPermissionRoleXFeatureFlagUpdates>;
};


/** mutation root */
export type IMutationRootUpdatePermissionRoleXPermissionScopeArgs = {
  _append?: InputMaybe<IPermissionRoleXPermissionScopeAppendInput>;
  _delete_at_path?: InputMaybe<IPermissionRoleXPermissionScopeDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IPermissionRoleXPermissionScopeDeleteElemInput>;
  _delete_key?: InputMaybe<IPermissionRoleXPermissionScopeDeleteKeyInput>;
  _prepend?: InputMaybe<IPermissionRoleXPermissionScopePrependInput>;
  _set?: InputMaybe<IPermissionRoleXPermissionScopeSetInput>;
  where: IPermissionRoleXPermissionScopeBoolExp;
};


/** mutation root */
export type IMutationRootUpdatePermissionRoleXPermissionScopeByPkArgs = {
  _append?: InputMaybe<IPermissionRoleXPermissionScopeAppendInput>;
  _delete_at_path?: InputMaybe<IPermissionRoleXPermissionScopeDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IPermissionRoleXPermissionScopeDeleteElemInput>;
  _delete_key?: InputMaybe<IPermissionRoleXPermissionScopeDeleteKeyInput>;
  _prepend?: InputMaybe<IPermissionRoleXPermissionScopePrependInput>;
  _set?: InputMaybe<IPermissionRoleXPermissionScopeSetInput>;
  pk_columns: IPermissionRoleXPermissionScopePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdatePermissionRoleXPermissionScopeManyArgs = {
  updates: Array<IPermissionRoleXPermissionScopeUpdates>;
};


/** mutation root */
export type IMutationRootUpdatePermissionScopeArgs = {
  _set?: InputMaybe<IPermissionScopeSetInput>;
  where: IPermissionScopeBoolExp;
};


/** mutation root */
export type IMutationRootUpdatePermissionScopeByPkArgs = {
  _set?: InputMaybe<IPermissionScopeSetInput>;
  pk_columns: IPermissionScopePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdatePermissionScopeManyArgs = {
  updates: Array<IPermissionScopeUpdates>;
};


/** mutation root */
export type IMutationRootUpdatePlaceArgs = {
  _set?: InputMaybe<IPlaceSetInput>;
  where: IPlaceBoolExp;
};


/** mutation root */
export type IMutationRootUpdatePlaceByPkArgs = {
  _set?: InputMaybe<IPlaceSetInput>;
  pk_columns: IPlacePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdatePlaceManyArgs = {
  updates: Array<IPlaceUpdates>;
};


/** mutation root */
export type IMutationRootUpdatePlaceXUploadArgs = {
  _set?: InputMaybe<IPlaceXUploadSetInput>;
  where: IPlaceXUploadBoolExp;
};


/** mutation root */
export type IMutationRootUpdatePlaceXUploadByPkArgs = {
  _set?: InputMaybe<IPlaceXUploadSetInput>;
  pk_columns: IPlaceXUploadPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdatePlaceXUploadManyArgs = {
  updates: Array<IPlaceXUploadUpdates>;
};


/** mutation root */
export type IMutationRootUpdateProjectArgs = {
  _append?: InputMaybe<IProjectAppendInput>;
  _delete_at_path?: InputMaybe<IProjectDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IProjectDeleteElemInput>;
  _delete_key?: InputMaybe<IProjectDeleteKeyInput>;
  _prepend?: InputMaybe<IProjectPrependInput>;
  _set?: InputMaybe<IProjectSetInput>;
  where: IProjectBoolExp;
};


/** mutation root */
export type IMutationRootUpdateProjectByPkArgs = {
  _append?: InputMaybe<IProjectAppendInput>;
  _delete_at_path?: InputMaybe<IProjectDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IProjectDeleteElemInput>;
  _delete_key?: InputMaybe<IProjectDeleteKeyInput>;
  _prepend?: InputMaybe<IProjectPrependInput>;
  _set?: InputMaybe<IProjectSetInput>;
  pk_columns: IProjectPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateProjectManyArgs = {
  updates: Array<IProjectUpdates>;
};


/** mutation root */
export type IMutationRootUpdateProjectStatusArgs = {
  _set?: InputMaybe<IProjectStatusSetInput>;
  where: IProjectStatusBoolExp;
};


/** mutation root */
export type IMutationRootUpdateProjectStatusByPkArgs = {
  _set?: InputMaybe<IProjectStatusSetInput>;
  pk_columns: IProjectStatusPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateProjectStatusManyArgs = {
  updates: Array<IProjectStatusUpdates>;
};


/** mutation root */
export type IMutationRootUpdateProjectXTeamArgs = {
  _set?: InputMaybe<IProjectXTeamSetInput>;
  where: IProjectXTeamBoolExp;
};


/** mutation root */
export type IMutationRootUpdateProjectXTeamByPkArgs = {
  _set?: InputMaybe<IProjectXTeamSetInput>;
  pk_columns: IProjectXTeamPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateProjectXTeamManyArgs = {
  updates: Array<IProjectXTeamUpdates>;
};


/** mutation root */
export type IMutationRootUpdateRecurrenceArgs = {
  _append?: InputMaybe<IRecurrenceAppendInput>;
  _delete_at_path?: InputMaybe<IRecurrenceDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IRecurrenceDeleteElemInput>;
  _delete_key?: InputMaybe<IRecurrenceDeleteKeyInput>;
  _inc?: InputMaybe<IRecurrenceIncInput>;
  _prepend?: InputMaybe<IRecurrencePrependInput>;
  _set?: InputMaybe<IRecurrenceSetInput>;
  where: IRecurrenceBoolExp;
};


/** mutation root */
export type IMutationRootUpdateRecurrenceByPkArgs = {
  _append?: InputMaybe<IRecurrenceAppendInput>;
  _delete_at_path?: InputMaybe<IRecurrenceDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IRecurrenceDeleteElemInput>;
  _delete_key?: InputMaybe<IRecurrenceDeleteKeyInput>;
  _inc?: InputMaybe<IRecurrenceIncInput>;
  _prepend?: InputMaybe<IRecurrencePrependInput>;
  _set?: InputMaybe<IRecurrenceSetInput>;
  pk_columns: IRecurrencePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateRecurrenceManyArgs = {
  updates: Array<IRecurrenceUpdates>;
};


/** mutation root */
export type IMutationRootUpdateRecurrencePatternArgs = {
  _set?: InputMaybe<IRecurrencePatternSetInput>;
  where: IRecurrencePatternBoolExp;
};


/** mutation root */
export type IMutationRootUpdateRecurrencePatternByPkArgs = {
  _set?: InputMaybe<IRecurrencePatternSetInput>;
  pk_columns: IRecurrencePatternPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateRecurrencePatternManyArgs = {
  updates: Array<IRecurrencePatternUpdates>;
};


/** mutation root */
export type IMutationRootUpdateScheduledTaskArgs = {
  _append?: InputMaybe<IScheduledTaskAppendInput>;
  _delete_at_path?: InputMaybe<IScheduledTaskDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IScheduledTaskDeleteElemInput>;
  _delete_key?: InputMaybe<IScheduledTaskDeleteKeyInput>;
  _inc?: InputMaybe<IScheduledTaskIncInput>;
  _prepend?: InputMaybe<IScheduledTaskPrependInput>;
  _set?: InputMaybe<IScheduledTaskSetInput>;
  where: IScheduledTaskBoolExp;
};


/** mutation root */
export type IMutationRootUpdateScheduledTaskByPkArgs = {
  _append?: InputMaybe<IScheduledTaskAppendInput>;
  _delete_at_path?: InputMaybe<IScheduledTaskDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IScheduledTaskDeleteElemInput>;
  _delete_key?: InputMaybe<IScheduledTaskDeleteKeyInput>;
  _inc?: InputMaybe<IScheduledTaskIncInput>;
  _prepend?: InputMaybe<IScheduledTaskPrependInput>;
  _set?: InputMaybe<IScheduledTaskSetInput>;
  pk_columns: IScheduledTaskPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateScheduledTaskManyArgs = {
  updates: Array<IScheduledTaskUpdates>;
};


/** mutation root */
export type IMutationRootUpdateServiceRequestArgs = {
  _inc?: InputMaybe<IServiceRequestIncInput>;
  _set?: InputMaybe<IServiceRequestSetInput>;
  where: IServiceRequestBoolExp;
};


/** mutation root */
export type IMutationRootUpdateServiceRequestByPkArgs = {
  _inc?: InputMaybe<IServiceRequestIncInput>;
  _set?: InputMaybe<IServiceRequestSetInput>;
  pk_columns: IServiceRequestPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateServiceRequestManyArgs = {
  updates: Array<IServiceRequestUpdates>;
};


/** mutation root */
export type IMutationRootUpdateServiceRequestStatusArgs = {
  _set?: InputMaybe<IServiceRequestStatusSetInput>;
  where: IServiceRequestStatusBoolExp;
};


/** mutation root */
export type IMutationRootUpdateServiceRequestStatusByPkArgs = {
  _set?: InputMaybe<IServiceRequestStatusSetInput>;
  pk_columns: IServiceRequestStatusPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateServiceRequestStatusManyArgs = {
  updates: Array<IServiceRequestStatusUpdates>;
};


/** mutation root */
export type IMutationRootUpdateServiceRequestXAssetArgs = {
  _inc?: InputMaybe<IServiceRequestXAssetIncInput>;
  _set?: InputMaybe<IServiceRequestXAssetSetInput>;
  where: IServiceRequestXAssetBoolExp;
};


/** mutation root */
export type IMutationRootUpdateServiceRequestXAssetByPkArgs = {
  _inc?: InputMaybe<IServiceRequestXAssetIncInput>;
  _set?: InputMaybe<IServiceRequestXAssetSetInput>;
  pk_columns: IServiceRequestXAssetPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateServiceRequestXAssetManyArgs = {
  updates: Array<IServiceRequestXAssetUpdates>;
};


/** mutation root */
export type IMutationRootUpdateServiceRequestXTeamArgs = {
  _inc?: InputMaybe<IServiceRequestXTeamIncInput>;
  _set?: InputMaybe<IServiceRequestXTeamSetInput>;
  where: IServiceRequestXTeamBoolExp;
};


/** mutation root */
export type IMutationRootUpdateServiceRequestXTeamByPkArgs = {
  _inc?: InputMaybe<IServiceRequestXTeamIncInput>;
  _set?: InputMaybe<IServiceRequestXTeamSetInput>;
  pk_columns: IServiceRequestXTeamPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateServiceRequestXTeamManyArgs = {
  updates: Array<IServiceRequestXTeamUpdates>;
};


/** mutation root */
export type IMutationRootUpdateServiceRequestXUploadArgs = {
  _inc?: InputMaybe<IServiceRequestXUploadIncInput>;
  _set?: InputMaybe<IServiceRequestXUploadSetInput>;
  where: IServiceRequestXUploadBoolExp;
};


/** mutation root */
export type IMutationRootUpdateServiceRequestXUploadByPkArgs = {
  _inc?: InputMaybe<IServiceRequestXUploadIncInput>;
  _set?: InputMaybe<IServiceRequestXUploadSetInput>;
  pk_columns: IServiceRequestXUploadPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateServiceRequestXUploadManyArgs = {
  updates: Array<IServiceRequestXUploadUpdates>;
};


/** mutation root */
export type IMutationRootUpdateServiceRequestXUserArgs = {
  _inc?: InputMaybe<IServiceRequestXUserIncInput>;
  _set?: InputMaybe<IServiceRequestXUserSetInput>;
  where: IServiceRequestXUserBoolExp;
};


/** mutation root */
export type IMutationRootUpdateServiceRequestXUserByPkArgs = {
  _inc?: InputMaybe<IServiceRequestXUserIncInput>;
  _set?: InputMaybe<IServiceRequestXUserSetInput>;
  pk_columns: IServiceRequestXUserPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateServiceRequestXUserManyArgs = {
  updates: Array<IServiceRequestXUserUpdates>;
};


/** mutation root */
export type IMutationRootUpdateSettingConfigArgs = {
  _append?: InputMaybe<ISettingConfigAppendInput>;
  _delete_at_path?: InputMaybe<ISettingConfigDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ISettingConfigDeleteElemInput>;
  _delete_key?: InputMaybe<ISettingConfigDeleteKeyInput>;
  _prepend?: InputMaybe<ISettingConfigPrependInput>;
  _set?: InputMaybe<ISettingConfigSetInput>;
  where: ISettingConfigBoolExp;
};


/** mutation root */
export type IMutationRootUpdateSettingConfigByPkArgs = {
  _append?: InputMaybe<ISettingConfigAppendInput>;
  _delete_at_path?: InputMaybe<ISettingConfigDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ISettingConfigDeleteElemInput>;
  _delete_key?: InputMaybe<ISettingConfigDeleteKeyInput>;
  _prepend?: InputMaybe<ISettingConfigPrependInput>;
  _set?: InputMaybe<ISettingConfigSetInput>;
  pk_columns: ISettingConfigPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateSettingConfigManyArgs = {
  updates: Array<ISettingConfigUpdates>;
};


/** mutation root */
export type IMutationRootUpdateSettingLevelArgs = {
  _set?: InputMaybe<ISettingLevelSetInput>;
  where: ISettingLevelBoolExp;
};


/** mutation root */
export type IMutationRootUpdateSettingLevelByPkArgs = {
  _set?: InputMaybe<ISettingLevelSetInput>;
  pk_columns: ISettingLevelPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateSettingLevelManyArgs = {
  updates: Array<ISettingLevelUpdates>;
};


/** mutation root */
export type IMutationRootUpdateSettingTypeArgs = {
  _set?: InputMaybe<ISettingTypeSetInput>;
  where: ISettingTypeBoolExp;
};


/** mutation root */
export type IMutationRootUpdateSettingTypeByPkArgs = {
  _set?: InputMaybe<ISettingTypeSetInput>;
  pk_columns: ISettingTypePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateSettingTypeManyArgs = {
  updates: Array<ISettingTypeUpdates>;
};


/** mutation root */
export type IMutationRootUpdateTeamArgs = {
  _append?: InputMaybe<ITeamAppendInput>;
  _delete_at_path?: InputMaybe<ITeamDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ITeamDeleteElemInput>;
  _delete_key?: InputMaybe<ITeamDeleteKeyInput>;
  _prepend?: InputMaybe<ITeamPrependInput>;
  _set?: InputMaybe<ITeamSetInput>;
  where: ITeamBoolExp;
};


/** mutation root */
export type IMutationRootUpdateTeamByPkArgs = {
  _append?: InputMaybe<ITeamAppendInput>;
  _delete_at_path?: InputMaybe<ITeamDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ITeamDeleteElemInput>;
  _delete_key?: InputMaybe<ITeamDeleteKeyInput>;
  _prepend?: InputMaybe<ITeamPrependInput>;
  _set?: InputMaybe<ITeamSetInput>;
  pk_columns: ITeamPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateTeamManyArgs = {
  updates: Array<ITeamUpdates>;
};


/** mutation root */
export type IMutationRootUpdateTeamMemberArgs = {
  _inc?: InputMaybe<ITeamMemberIncInput>;
  _set?: InputMaybe<ITeamMemberSetInput>;
  where: ITeamMemberBoolExp;
};


/** mutation root */
export type IMutationRootUpdateTeamMemberByPkArgs = {
  _inc?: InputMaybe<ITeamMemberIncInput>;
  _set?: InputMaybe<ITeamMemberSetInput>;
  pk_columns: ITeamMemberPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateTeamMemberManyArgs = {
  updates: Array<ITeamMemberUpdates>;
};


/** mutation root */
export type IMutationRootUpdateTeamUserRoleArgs = {
  _set?: InputMaybe<ITeamUserRoleSetInput>;
  where: ITeamUserRoleBoolExp;
};


/** mutation root */
export type IMutationRootUpdateTeamUserRoleByPkArgs = {
  _set?: InputMaybe<ITeamUserRoleSetInput>;
  pk_columns: ITeamUserRolePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateTeamUserRoleManyArgs = {
  updates: Array<ITeamUserRoleUpdates>;
};


/** mutation root */
export type IMutationRootUpdateUploadArgs = {
  _inc?: InputMaybe<IUploadIncInput>;
  _set?: InputMaybe<IUploadSetInput>;
  where: IUploadBoolExp;
};


/** mutation root */
export type IMutationRootUpdateUploadByPkArgs = {
  _inc?: InputMaybe<IUploadIncInput>;
  _set?: InputMaybe<IUploadSetInput>;
  pk_columns: IUploadPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateUploadCategoryArgs = {
  _set?: InputMaybe<IUploadCategorySetInput>;
  where: IUploadCategoryBoolExp;
};


/** mutation root */
export type IMutationRootUpdateUploadCategoryByPkArgs = {
  _set?: InputMaybe<IUploadCategorySetInput>;
  pk_columns: IUploadCategoryPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateUploadCategoryManyArgs = {
  updates: Array<IUploadCategoryUpdates>;
};


/** mutation root */
export type IMutationRootUpdateUploadManyArgs = {
  updates: Array<IUploadUpdates>;
};


/** mutation root */
export type IMutationRootUpdateUploadXUploadCategoryArgs = {
  _set?: InputMaybe<IUploadXUploadCategorySetInput>;
  where: IUploadXUploadCategoryBoolExp;
};


/** mutation root */
export type IMutationRootUpdateUploadXUploadCategoryByPkArgs = {
  _set?: InputMaybe<IUploadXUploadCategorySetInput>;
  pk_columns: IUploadXUploadCategoryPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateUploadXUploadCategoryManyArgs = {
  updates: Array<IUploadXUploadCategoryUpdates>;
};


/** mutation root */
export type IMutationRootUpdateUserArgs = {
  _append?: InputMaybe<IUserAppendInput>;
  _delete_at_path?: InputMaybe<IUserDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IUserDeleteElemInput>;
  _delete_key?: InputMaybe<IUserDeleteKeyInput>;
  _prepend?: InputMaybe<IUserPrependInput>;
  _set?: InputMaybe<IUserSetInput>;
  where: IUserBoolExp;
};


/** mutation root */
export type IMutationRootUpdateUserByPkArgs = {
  _append?: InputMaybe<IUserAppendInput>;
  _delete_at_path?: InputMaybe<IUserDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IUserDeleteElemInput>;
  _delete_key?: InputMaybe<IUserDeleteKeyInput>;
  _prepend?: InputMaybe<IUserPrependInput>;
  _set?: InputMaybe<IUserSetInput>;
  pk_columns: IUserPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateUserFavoriteArgs = {
  _inc?: InputMaybe<IUserFavoriteIncInput>;
  _set?: InputMaybe<IUserFavoriteSetInput>;
  where: IUserFavoriteBoolExp;
};


/** mutation root */
export type IMutationRootUpdateUserFavoriteByPkArgs = {
  _inc?: InputMaybe<IUserFavoriteIncInput>;
  _set?: InputMaybe<IUserFavoriteSetInput>;
  pk_columns: IUserFavoritePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateUserFavoriteManyArgs = {
  updates: Array<IUserFavoriteUpdates>;
};


/** mutation root */
export type IMutationRootUpdateUserManyArgs = {
  updates: Array<IUserUpdates>;
};


/** mutation root */
export type IMutationRootUpdateUserRecentlyUsedArgs = {
  _set?: InputMaybe<IUserRecentlyUsedSetInput>;
  where: IUserRecentlyUsedBoolExp;
};


/** mutation root */
export type IMutationRootUpdateUserRecentlyUsedByPkArgs = {
  _set?: InputMaybe<IUserRecentlyUsedSetInput>;
  pk_columns: IUserRecentlyUsedPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateUserRecentlyUsedManyArgs = {
  updates: Array<IUserRecentlyUsedUpdates>;
};


/** mutation root */
export type IMutationRootUpdateViewCollectionArgs = {
  _append?: InputMaybe<IViewCollectionAppendInput>;
  _delete_at_path?: InputMaybe<IViewCollectionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IViewCollectionDeleteElemInput>;
  _delete_key?: InputMaybe<IViewCollectionDeleteKeyInput>;
  _prepend?: InputMaybe<IViewCollectionPrependInput>;
  _set?: InputMaybe<IViewCollectionSetInput>;
  where: IViewCollectionBoolExp;
};


/** mutation root */
export type IMutationRootUpdateViewCollectionByPkArgs = {
  _append?: InputMaybe<IViewCollectionAppendInput>;
  _delete_at_path?: InputMaybe<IViewCollectionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IViewCollectionDeleteElemInput>;
  _delete_key?: InputMaybe<IViewCollectionDeleteKeyInput>;
  _prepend?: InputMaybe<IViewCollectionPrependInput>;
  _set?: InputMaybe<IViewCollectionSetInput>;
  pk_columns: IViewCollectionPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateViewCollectionManyArgs = {
  updates: Array<IViewCollectionUpdates>;
};


/** mutation root */
export type IMutationRootUpdateViewConfigArgs = {
  _set?: InputMaybe<IViewConfigSetInput>;
  where: IViewConfigBoolExp;
};


/** mutation root */
export type IMutationRootUpdateViewConfigByPkArgs = {
  _set?: InputMaybe<IViewConfigSetInput>;
  pk_columns: IViewConfigPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateViewConfigManyArgs = {
  updates: Array<IViewConfigUpdates>;
};


/** mutation root */
export type IMutationRootUpdateViewDataTypeArgs = {
  _set?: InputMaybe<IViewDataTypeSetInput>;
  where: IViewDataTypeBoolExp;
};


/** mutation root */
export type IMutationRootUpdateViewDataTypeByPkArgs = {
  _set?: InputMaybe<IViewDataTypeSetInput>;
  pk_columns: IViewDataTypePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateViewDataTypeManyArgs = {
  updates: Array<IViewDataTypeUpdates>;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderArgs = {
  _append?: InputMaybe<IWorkOrderAppendInput>;
  _delete_at_path?: InputMaybe<IWorkOrderDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IWorkOrderDeleteElemInput>;
  _delete_key?: InputMaybe<IWorkOrderDeleteKeyInput>;
  _inc?: InputMaybe<IWorkOrderIncInput>;
  _prepend?: InputMaybe<IWorkOrderPrependInput>;
  _set?: InputMaybe<IWorkOrderSetInput>;
  where: IWorkOrderBoolExp;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderActivityArgs = {
  _set?: InputMaybe<IWorkOrderActivitySetInput>;
  where: IWorkOrderActivityBoolExp;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderActivityByPkArgs = {
  _set?: InputMaybe<IWorkOrderActivitySetInput>;
  pk_columns: IWorkOrderActivityPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderActivityManyArgs = {
  updates: Array<IWorkOrderActivityUpdates>;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderActivityTypeArgs = {
  _set?: InputMaybe<IWorkOrderActivityTypeSetInput>;
  where: IWorkOrderActivityTypeBoolExp;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderActivityTypeByPkArgs = {
  _set?: InputMaybe<IWorkOrderActivityTypeSetInput>;
  pk_columns: IWorkOrderActivityTypePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderActivityTypeManyArgs = {
  updates: Array<IWorkOrderActivityTypeUpdates>;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderByPkArgs = {
  _append?: InputMaybe<IWorkOrderAppendInput>;
  _delete_at_path?: InputMaybe<IWorkOrderDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IWorkOrderDeleteElemInput>;
  _delete_key?: InputMaybe<IWorkOrderDeleteKeyInput>;
  _inc?: InputMaybe<IWorkOrderIncInput>;
  _prepend?: InputMaybe<IWorkOrderPrependInput>;
  _set?: InputMaybe<IWorkOrderSetInput>;
  pk_columns: IWorkOrderPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderCategoryArgs = {
  _set?: InputMaybe<IWorkOrderCategorySetInput>;
  where: IWorkOrderCategoryBoolExp;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderCategoryByPkArgs = {
  _set?: InputMaybe<IWorkOrderCategorySetInput>;
  pk_columns: IWorkOrderCategoryPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderCategoryManyArgs = {
  updates: Array<IWorkOrderCategoryUpdates>;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderExpenseArgs = {
  _append?: InputMaybe<IWorkOrderExpenseAppendInput>;
  _delete_at_path?: InputMaybe<IWorkOrderExpenseDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IWorkOrderExpenseDeleteElemInput>;
  _delete_key?: InputMaybe<IWorkOrderExpenseDeleteKeyInput>;
  _inc?: InputMaybe<IWorkOrderExpenseIncInput>;
  _prepend?: InputMaybe<IWorkOrderExpensePrependInput>;
  _set?: InputMaybe<IWorkOrderExpenseSetInput>;
  where: IWorkOrderExpenseBoolExp;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderExpenseByPkArgs = {
  _append?: InputMaybe<IWorkOrderExpenseAppendInput>;
  _delete_at_path?: InputMaybe<IWorkOrderExpenseDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IWorkOrderExpenseDeleteElemInput>;
  _delete_key?: InputMaybe<IWorkOrderExpenseDeleteKeyInput>;
  _inc?: InputMaybe<IWorkOrderExpenseIncInput>;
  _prepend?: InputMaybe<IWorkOrderExpensePrependInput>;
  _set?: InputMaybe<IWorkOrderExpenseSetInput>;
  pk_columns: IWorkOrderExpensePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderExpenseManyArgs = {
  updates: Array<IWorkOrderExpenseUpdates>;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderFeedbackArgs = {
  _append?: InputMaybe<IWorkOrderFeedbackAppendInput>;
  _delete_at_path?: InputMaybe<IWorkOrderFeedbackDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IWorkOrderFeedbackDeleteElemInput>;
  _delete_key?: InputMaybe<IWorkOrderFeedbackDeleteKeyInput>;
  _prepend?: InputMaybe<IWorkOrderFeedbackPrependInput>;
  _set?: InputMaybe<IWorkOrderFeedbackSetInput>;
  where: IWorkOrderFeedbackBoolExp;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderFeedbackByPkArgs = {
  _append?: InputMaybe<IWorkOrderFeedbackAppendInput>;
  _delete_at_path?: InputMaybe<IWorkOrderFeedbackDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IWorkOrderFeedbackDeleteElemInput>;
  _delete_key?: InputMaybe<IWorkOrderFeedbackDeleteKeyInput>;
  _prepend?: InputMaybe<IWorkOrderFeedbackPrependInput>;
  _set?: InputMaybe<IWorkOrderFeedbackSetInput>;
  pk_columns: IWorkOrderFeedbackPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderFeedbackManyArgs = {
  updates: Array<IWorkOrderFeedbackUpdates>;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderLastUpdateArgs = {
  _set?: InputMaybe<IWorkOrderLastUpdateSetInput>;
  where: IWorkOrderLastUpdateBoolExp;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderLastUpdateManyArgs = {
  updates: Array<IWorkOrderLastUpdateUpdates>;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderManyArgs = {
  updates: Array<IWorkOrderUpdates>;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderPriorityArgs = {
  _set?: InputMaybe<IWorkOrderPrioritySetInput>;
  where: IWorkOrderPriorityBoolExp;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderPriorityByPkArgs = {
  _set?: InputMaybe<IWorkOrderPrioritySetInput>;
  pk_columns: IWorkOrderPriorityPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderPriorityManyArgs = {
  updates: Array<IWorkOrderPriorityUpdates>;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderReportArgs = {
  _set?: InputMaybe<IWorkOrderReportSetInput>;
  where: IWorkOrderReportBoolExp;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderReportByPkArgs = {
  _set?: InputMaybe<IWorkOrderReportSetInput>;
  pk_columns: IWorkOrderReportPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderReportManyArgs = {
  updates: Array<IWorkOrderReportUpdates>;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderStatusArgs = {
  _set?: InputMaybe<IWorkOrderStatusSetInput>;
  where: IWorkOrderStatusBoolExp;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderStatusByPkArgs = {
  _set?: InputMaybe<IWorkOrderStatusSetInput>;
  pk_columns: IWorkOrderStatusPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderStatusManyArgs = {
  updates: Array<IWorkOrderStatusUpdates>;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderTemplateArgs = {
  _set?: InputMaybe<IWorkOrderTemplateSetInput>;
  where: IWorkOrderTemplateBoolExp;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderTemplateByPkArgs = {
  _set?: InputMaybe<IWorkOrderTemplateSetInput>;
  pk_columns: IWorkOrderTemplatePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderTemplateManyArgs = {
  updates: Array<IWorkOrderTemplateUpdates>;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderTypeArgs = {
  _set?: InputMaybe<IWorkOrderTypeSetInput>;
  where: IWorkOrderTypeBoolExp;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderTypeByPkArgs = {
  _set?: InputMaybe<IWorkOrderTypeSetInput>;
  pk_columns: IWorkOrderTypePkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderTypeManyArgs = {
  updates: Array<IWorkOrderTypeUpdates>;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderXCollaboratorArgs = {
  _set?: InputMaybe<IWorkOrderXCollaboratorSetInput>;
  where: IWorkOrderXCollaboratorBoolExp;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderXCollaboratorByPkArgs = {
  _set?: InputMaybe<IWorkOrderXCollaboratorSetInput>;
  pk_columns: IWorkOrderXCollaboratorPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderXCollaboratorManyArgs = {
  updates: Array<IWorkOrderXCollaboratorUpdates>;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderXTeamArgs = {
  _set?: InputMaybe<IWorkOrderXTeamSetInput>;
  where: IWorkOrderXTeamBoolExp;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderXTeamByPkArgs = {
  _set?: InputMaybe<IWorkOrderXTeamSetInput>;
  pk_columns: IWorkOrderXTeamPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderXTeamManyArgs = {
  updates: Array<IWorkOrderXTeamUpdates>;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderXUploadArgs = {
  _set?: InputMaybe<IWorkOrderXUploadSetInput>;
  where: IWorkOrderXUploadBoolExp;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderXUploadByPkArgs = {
  _set?: InputMaybe<IWorkOrderXUploadSetInput>;
  pk_columns: IWorkOrderXUploadPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderXUploadManyArgs = {
  updates: Array<IWorkOrderXUploadUpdates>;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderXUserArgs = {
  _set?: InputMaybe<IWorkOrderXUserSetInput>;
  where: IWorkOrderXUserBoolExp;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderXUserByPkArgs = {
  _set?: InputMaybe<IWorkOrderXUserSetInput>;
  pk_columns: IWorkOrderXUserPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderXUserManyArgs = {
  updates: Array<IWorkOrderXUserUpdates>;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderXWorkOrderCategoryArgs = {
  _set?: InputMaybe<IWorkOrderXWorkOrderCategorySetInput>;
  where: IWorkOrderXWorkOrderCategoryBoolExp;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderXWorkOrderCategoryByPkArgs = {
  _set?: InputMaybe<IWorkOrderXWorkOrderCategorySetInput>;
  pk_columns: IWorkOrderXWorkOrderCategoryPkColumnsInput;
};


/** mutation root */
export type IMutationRootUpdateWorkOrderXWorkOrderCategoryManyArgs = {
  updates: Array<IWorkOrderXWorkOrderCategoryUpdates>;
};


/** mutation root */
export type IMutationRootUpsertEmbeddingArgs = {
  kind: Scalars['String']['input'];
  kindId?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type IMutationRootWorkOrderFromStreamArgs = {
  args: IWorkOrderFromStreamArgs;
  distinct_on?: InputMaybe<Array<IWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOrderBy>>;
  where?: InputMaybe<IWorkOrderBoolExp>;
};

/** columns and relationships of "notification" */
export type INotification = {
  __typename: 'notification';
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  payload: Scalars['jsonb']['output'];
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  seen_at?: Maybe<Scalars['timestamptz']['output']>;
  session_info?: Maybe<Scalars['json']['output']>;
  type: INotificationTypeEnum;
  /** An object relationship */
  user: IUser;
  user_id: Scalars['uuid']['output'];
  /** An object relationship */
  work_order?: Maybe<IWorkOrder>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};


/** columns and relationships of "notification" */
export type INotificationPayloadArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "notification" */
export type INotificationSessionInfoArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "notification" */
export type INotificationAggregate = {
  __typename: 'notification_aggregate';
  aggregate?: Maybe<INotificationAggregateFields>;
  nodes: Array<INotification>;
};

export type INotificationAggregateBoolExp = {
  count?: InputMaybe<INotificationAggregateBoolExpCount>;
};

export type INotificationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<INotificationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<INotificationBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "notification" */
export type INotificationAggregateFields = {
  __typename: 'notification_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<INotificationMaxFields>;
  min?: Maybe<INotificationMinFields>;
};


/** aggregate fields of "notification" */
export type INotificationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<INotificationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "notification" */
export type INotificationAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<INotificationMaxOrderBy>;
  min?: InputMaybe<INotificationMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type INotificationAppendInput = {
  payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "notification" */
export type INotificationArrRelInsertInput = {
  data: Array<INotificationInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<INotificationOnConflict>;
};

/** Boolean expression to filter rows from the table "notification". All fields are combined with a logical 'AND'. */
export type INotificationBoolExp = {
  _and?: InputMaybe<Array<INotificationBoolExp>>;
  _not?: InputMaybe<INotificationBoolExp>;
  _or?: InputMaybe<Array<INotificationBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  deleted_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  payload?: InputMaybe<IJsonbComparisonExp>;
  read_at?: InputMaybe<ITimestamptzComparisonExp>;
  seen_at?: InputMaybe<ITimestamptzComparisonExp>;
  session_info?: InputMaybe<IJsonComparisonExp>;
  type?: InputMaybe<INotificationTypeEnumComparisonExp>;
  user?: InputMaybe<IUserBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
  work_order?: InputMaybe<IWorkOrderBoolExp>;
  work_order_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "notification" */
export enum INotificationConstraint {
  /** unique or primary key constraint on columns "id" */
  NotificationPkey = 'notification_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type INotificationDeleteAtPathInput = {
  payload?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type INotificationDeleteElemInput = {
  payload?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type INotificationDeleteKeyInput = {
  payload?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "notification" */
export type INotificationInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  seen_at?: InputMaybe<Scalars['timestamptz']['input']>;
  session_info?: InputMaybe<Scalars['json']['input']>;
  type?: InputMaybe<INotificationTypeEnum>;
  user?: InputMaybe<IUserObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order?: InputMaybe<IWorkOrderObjRelInsertInput>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type INotificationMaxFields = {
  __typename: 'notification_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  seen_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "notification" */
export type INotificationMaxOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  deleted_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  read_at?: InputMaybe<IOrderBy>;
  seen_at?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type INotificationMinFields = {
  __typename: 'notification_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  seen_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "notification" */
export type INotificationMinOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  deleted_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  read_at?: InputMaybe<IOrderBy>;
  seen_at?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "notification" */
export type INotificationMutationResponse = {
  __typename: 'notification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<INotification>;
};

/** on_conflict condition type for table "notification" */
export type INotificationOnConflict = {
  constraint: INotificationConstraint;
  update_columns?: Array<INotificationUpdateColumn>;
  where?: InputMaybe<INotificationBoolExp>;
};

/** Ordering options when selecting data from "notification". */
export type INotificationOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  deleted_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  payload?: InputMaybe<IOrderBy>;
  read_at?: InputMaybe<IOrderBy>;
  seen_at?: InputMaybe<IOrderBy>;
  session_info?: InputMaybe<IOrderBy>;
  type?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUserOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  work_order?: InputMaybe<IWorkOrderOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: notification */
export type INotificationPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type INotificationPrependInput = {
  payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "notification" */
export enum INotificationSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  SeenAt = 'seen_at',
  /** column name */
  SessionInfo = 'session_info',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WorkOrderId = 'work_order_id'
}

/** input type for updating data in table "notification" */
export type INotificationSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  seen_at?: InputMaybe<Scalars['timestamptz']['input']>;
  session_info?: InputMaybe<Scalars['json']['input']>;
  type?: InputMaybe<INotificationTypeEnum>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "notification" */
export type INotificationStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: INotificationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type INotificationStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  seen_at?: InputMaybe<Scalars['timestamptz']['input']>;
  session_info?: InputMaybe<Scalars['json']['input']>;
  type?: InputMaybe<INotificationTypeEnum>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "notification_type" */
export type INotificationType = {
  __typename: 'notification_type';
  /** work_order_overdue_tomorrow is legacy and is replaced by work_order_due_date_reminder. It has not been deleted to avoid data loss */
  value: Scalars['String']['output'];
};

/** aggregated selection of "notification_type" */
export type INotificationTypeAggregate = {
  __typename: 'notification_type_aggregate';
  aggregate?: Maybe<INotificationTypeAggregateFields>;
  nodes: Array<INotificationType>;
};

/** aggregate fields of "notification_type" */
export type INotificationTypeAggregateFields = {
  __typename: 'notification_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<INotificationTypeMaxFields>;
  min?: Maybe<INotificationTypeMinFields>;
};


/** aggregate fields of "notification_type" */
export type INotificationTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<INotificationTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "notification_type". All fields are combined with a logical 'AND'. */
export type INotificationTypeBoolExp = {
  _and?: InputMaybe<Array<INotificationTypeBoolExp>>;
  _not?: InputMaybe<INotificationTypeBoolExp>;
  _or?: InputMaybe<Array<INotificationTypeBoolExp>>;
  value?: InputMaybe<IStringComparisonExp>;
};

/** unique or primary key constraints on table "notification_type" */
export enum INotificationTypeConstraint {
  /** unique or primary key constraint on columns "value" */
  NotificationTypePkey = 'notification_type_pkey'
}

export enum INotificationTypeEnum {
  ConsumableLowStock = 'consumable_low_stock',
  UserAddedToAPrivateTeam = 'user_added_to_a_private_team',
  WorkOrderAssignedTeam = 'work_order_assigned_team',
  WorkOrderAssignedUser = 'work_order_assigned_user',
  WorkOrderCancelled = 'work_order_cancelled',
  WorkOrderCommented = 'work_order_commented',
  WorkOrderDone = 'work_order_done',
  WorkOrderDueDateReminder = 'work_order_due_date_reminder',
  WorkOrderOverdueTomorrow = 'work_order_overdue_tomorrow',
  WorkOrderReadyForApproval = 'work_order_ready_for_approval',
  WorkOrderXCollaboratorInsert = 'work_order_x_collaborator_insert'
}

/** Boolean expression to compare columns of type "notification_type_enum". All fields are combined with logical 'AND'. */
export type INotificationTypeEnumComparisonExp = {
  _eq?: InputMaybe<INotificationTypeEnum>;
  _in?: InputMaybe<Array<INotificationTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<INotificationTypeEnum>;
  _nin?: InputMaybe<Array<INotificationTypeEnum>>;
};

/** input type for inserting data into table "notification_type" */
export type INotificationTypeInsertInput = {
  /** work_order_overdue_tomorrow is legacy and is replaced by work_order_due_date_reminder. It has not been deleted to avoid data loss */
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type INotificationTypeMaxFields = {
  __typename: 'notification_type_max_fields';
  /** work_order_overdue_tomorrow is legacy and is replaced by work_order_due_date_reminder. It has not been deleted to avoid data loss */
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type INotificationTypeMinFields = {
  __typename: 'notification_type_min_fields';
  /** work_order_overdue_tomorrow is legacy and is replaced by work_order_due_date_reminder. It has not been deleted to avoid data loss */
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "notification_type" */
export type INotificationTypeMutationResponse = {
  __typename: 'notification_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<INotificationType>;
};

/** on_conflict condition type for table "notification_type" */
export type INotificationTypeOnConflict = {
  constraint: INotificationTypeConstraint;
  update_columns?: Array<INotificationTypeUpdateColumn>;
  where?: InputMaybe<INotificationTypeBoolExp>;
};

/** Ordering options when selecting data from "notification_type". */
export type INotificationTypeOrderBy = {
  value?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: notification_type */
export type INotificationTypePkColumnsInput = {
  /** work_order_overdue_tomorrow is legacy and is replaced by work_order_due_date_reminder. It has not been deleted to avoid data loss */
  value: Scalars['String']['input'];
};

/** select columns of table "notification_type" */
export enum INotificationTypeSelectColumn {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "notification_type" */
export type INotificationTypeSetInput = {
  /** work_order_overdue_tomorrow is legacy and is replaced by work_order_due_date_reminder. It has not been deleted to avoid data loss */
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "notification_type" */
export type INotificationTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: INotificationTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type INotificationTypeStreamCursorValueInput = {
  /** work_order_overdue_tomorrow is legacy and is replaced by work_order_due_date_reminder. It has not been deleted to avoid data loss */
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "notification_type" */
export enum INotificationTypeUpdateColumn {
  /** column name */
  Value = 'value'
}

export type INotificationTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<INotificationTypeSetInput>;
  /** filter the rows which have to be updated */
  where: INotificationTypeBoolExp;
};

/** update columns of table "notification" */
export enum INotificationUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  SeenAt = 'seen_at',
  /** column name */
  SessionInfo = 'session_info',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WorkOrderId = 'work_order_id'
}

export type INotificationUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<INotificationAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<INotificationDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<INotificationDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<INotificationDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<INotificationPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<INotificationSetInput>;
  /** filter the rows which have to be updated */
  where: INotificationBoolExp;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type INumericComparisonExp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
};

/** column ordering options */
export enum IOrderBy {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "org" */
export type IOrg = {
  __typename: 'org';
  /** An array relationship */
  asset_manufacturers: Array<IAssetManufacturer>;
  /** An aggregate relationship */
  asset_manufacturers_aggregate: IAssetManufacturerAggregate;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  features?: Maybe<IOrgFeature>;
  free_trial_until?: Maybe<Scalars['date']['output']>;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  locations: Array<ILocation>;
  /** An aggregate relationship */
  locations_aggregate: ILocationAggregate;
  /** An array relationship */
  members: Array<IUser>;
  /** An aggregate relationship */
  members_aggregate: IUserAggregate;
  name: Scalars['String']['output'];
  /** An array relationship */
  org_sso_settings: Array<IOrgSsoSettings>;
  /** An aggregate relationship */
  org_sso_settings_aggregate: IOrgSsoSettingsAggregate;
  paying_customer_since?: Maybe<Scalars['date']['output']>;
  plan: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  uploads: Array<IUpload>;
  /** An aggregate relationship */
  uploads_aggregate: IUploadAggregate;
};


/** columns and relationships of "org" */
export type IOrgAssetManufacturersArgs = {
  distinct_on?: InputMaybe<Array<IAssetManufacturerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetManufacturerOrderBy>>;
  where?: InputMaybe<IAssetManufacturerBoolExp>;
};


/** columns and relationships of "org" */
export type IOrgAssetManufacturersAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetManufacturerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetManufacturerOrderBy>>;
  where?: InputMaybe<IAssetManufacturerBoolExp>;
};


/** columns and relationships of "org" */
export type IOrgLocationsArgs = {
  distinct_on?: InputMaybe<Array<ILocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationOrderBy>>;
  where?: InputMaybe<ILocationBoolExp>;
};


/** columns and relationships of "org" */
export type IOrgLocationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ILocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationOrderBy>>;
  where?: InputMaybe<ILocationBoolExp>;
};


/** columns and relationships of "org" */
export type IOrgMembersArgs = {
  distinct_on?: InputMaybe<Array<IUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUserOrderBy>>;
  where?: InputMaybe<IUserBoolExp>;
};


/** columns and relationships of "org" */
export type IOrgMembersAggregateArgs = {
  distinct_on?: InputMaybe<Array<IUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUserOrderBy>>;
  where?: InputMaybe<IUserBoolExp>;
};


/** columns and relationships of "org" */
export type IOrgOrgSsoSettingsArgs = {
  distinct_on?: InputMaybe<Array<IOrgSsoSettingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IOrgSsoSettingsOrderBy>>;
  where?: InputMaybe<IOrgSsoSettingsBoolExp>;
};


/** columns and relationships of "org" */
export type IOrgOrgSsoSettingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IOrgSsoSettingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IOrgSsoSettingsOrderBy>>;
  where?: InputMaybe<IOrgSsoSettingsBoolExp>;
};


/** columns and relationships of "org" */
export type IOrgUploadsArgs = {
  distinct_on?: InputMaybe<Array<IUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUploadOrderBy>>;
  where?: InputMaybe<IUploadBoolExp>;
};


/** columns and relationships of "org" */
export type IOrgUploadsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUploadOrderBy>>;
  where?: InputMaybe<IUploadBoolExp>;
};

/** aggregated selection of "org" */
export type IOrgAggregate = {
  __typename: 'org_aggregate';
  aggregate?: Maybe<IOrgAggregateFields>;
  nodes: Array<IOrg>;
};

/** aggregate fields of "org" */
export type IOrgAggregateFields = {
  __typename: 'org_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IOrgMaxFields>;
  min?: Maybe<IOrgMinFields>;
};


/** aggregate fields of "org" */
export type IOrgAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IOrgSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "org". All fields are combined with a logical 'AND'. */
export type IOrgBoolExp = {
  _and?: InputMaybe<Array<IOrgBoolExp>>;
  _not?: InputMaybe<IOrgBoolExp>;
  _or?: InputMaybe<Array<IOrgBoolExp>>;
  asset_manufacturers?: InputMaybe<IAssetManufacturerBoolExp>;
  asset_manufacturers_aggregate?: InputMaybe<IAssetManufacturerAggregateBoolExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  features?: InputMaybe<IOrgFeatureBoolExp>;
  free_trial_until?: InputMaybe<IDateComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  locations?: InputMaybe<ILocationBoolExp>;
  locations_aggregate?: InputMaybe<ILocationAggregateBoolExp>;
  members?: InputMaybe<IUserBoolExp>;
  members_aggregate?: InputMaybe<IUserAggregateBoolExp>;
  name?: InputMaybe<IStringComparisonExp>;
  org_sso_settings?: InputMaybe<IOrgSsoSettingsBoolExp>;
  org_sso_settings_aggregate?: InputMaybe<IOrgSsoSettingsAggregateBoolExp>;
  paying_customer_since?: InputMaybe<IDateComparisonExp>;
  plan?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  uploads?: InputMaybe<IUploadBoolExp>;
  uploads_aggregate?: InputMaybe<IUploadAggregateBoolExp>;
};

/** unique or primary key constraints on table "org" */
export enum IOrgConstraint {
  /** unique or primary key constraint on columns "id" */
  OrgPkey = 'org_pkey'
}

/** Features enabled for specific organizations */
export type IOrgFeature = {
  __typename: 'org_feature';
  consumables: Scalars['Boolean']['output'];
  org_id: Scalars['uuid']['output'];
  v3: Scalars['Boolean']['output'];
};

/** aggregated selection of "org_feature" */
export type IOrgFeatureAggregate = {
  __typename: 'org_feature_aggregate';
  aggregate?: Maybe<IOrgFeatureAggregateFields>;
  nodes: Array<IOrgFeature>;
};

/** aggregate fields of "org_feature" */
export type IOrgFeatureAggregateFields = {
  __typename: 'org_feature_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IOrgFeatureMaxFields>;
  min?: Maybe<IOrgFeatureMinFields>;
};


/** aggregate fields of "org_feature" */
export type IOrgFeatureAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IOrgFeatureSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "org_feature". All fields are combined with a logical 'AND'. */
export type IOrgFeatureBoolExp = {
  _and?: InputMaybe<Array<IOrgFeatureBoolExp>>;
  _not?: InputMaybe<IOrgFeatureBoolExp>;
  _or?: InputMaybe<Array<IOrgFeatureBoolExp>>;
  consumables?: InputMaybe<IBooleanComparisonExp>;
  org_id?: InputMaybe<IUuidComparisonExp>;
  v3?: InputMaybe<IBooleanComparisonExp>;
};

/** unique or primary key constraints on table "org_feature" */
export enum IOrgFeatureConstraint {
  /** unique or primary key constraint on columns "org_id" */
  OrgFeaturePkey = 'org_feature_pkey'
}

/** input type for inserting data into table "org_feature" */
export type IOrgFeatureInsertInput = {
  consumables?: InputMaybe<Scalars['Boolean']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  v3?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type IOrgFeatureMaxFields = {
  __typename: 'org_feature_max_fields';
  org_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type IOrgFeatureMinFields = {
  __typename: 'org_feature_min_fields';
  org_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "org_feature" */
export type IOrgFeatureMutationResponse = {
  __typename: 'org_feature_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IOrgFeature>;
};

/** input type for inserting object relation for remote table "org_feature" */
export type IOrgFeatureObjRelInsertInput = {
  data: IOrgFeatureInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IOrgFeatureOnConflict>;
};

/** on_conflict condition type for table "org_feature" */
export type IOrgFeatureOnConflict = {
  constraint: IOrgFeatureConstraint;
  update_columns?: Array<IOrgFeatureUpdateColumn>;
  where?: InputMaybe<IOrgFeatureBoolExp>;
};

/** Ordering options when selecting data from "org_feature". */
export type IOrgFeatureOrderBy = {
  consumables?: InputMaybe<IOrderBy>;
  org_id?: InputMaybe<IOrderBy>;
  v3?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: org_feature */
export type IOrgFeaturePkColumnsInput = {
  org_id: Scalars['uuid']['input'];
};

/** select columns of table "org_feature" */
export enum IOrgFeatureSelectColumn {
  /** column name */
  Consumables = 'consumables',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  V3 = 'v3'
}

/** input type for updating data in table "org_feature" */
export type IOrgFeatureSetInput = {
  consumables?: InputMaybe<Scalars['Boolean']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  v3?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Streaming cursor of the table "org_feature" */
export type IOrgFeatureStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IOrgFeatureStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IOrgFeatureStreamCursorValueInput = {
  consumables?: InputMaybe<Scalars['Boolean']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  v3?: InputMaybe<Scalars['Boolean']['input']>;
};

/** update columns of table "org_feature" */
export enum IOrgFeatureUpdateColumn {
  /** column name */
  Consumables = 'consumables',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  V3 = 'v3'
}

export type IOrgFeatureUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IOrgFeatureSetInput>;
  /** filter the rows which have to be updated */
  where: IOrgFeatureBoolExp;
};

/** input type for inserting data into table "org" */
export type IOrgInsertInput = {
  asset_manufacturers?: InputMaybe<IAssetManufacturerArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  features?: InputMaybe<IOrgFeatureObjRelInsertInput>;
  free_trial_until?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  locations?: InputMaybe<ILocationArrRelInsertInput>;
  members?: InputMaybe<IUserArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_sso_settings?: InputMaybe<IOrgSsoSettingsArrRelInsertInput>;
  paying_customer_since?: InputMaybe<Scalars['date']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  uploads?: InputMaybe<IUploadArrRelInsertInput>;
};

/** aggregate max on columns */
export type IOrgMaxFields = {
  __typename: 'org_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  free_trial_until?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paying_customer_since?: Maybe<Scalars['date']['output']>;
  plan?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** columns and relationships of "org_member" */
export type IOrgMember = {
  __typename: 'org_member';
  org_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "org_member" */
export type IOrgMemberAggregate = {
  __typename: 'org_member_aggregate';
  aggregate?: Maybe<IOrgMemberAggregateFields>;
  nodes: Array<IOrgMember>;
};

/** aggregate fields of "org_member" */
export type IOrgMemberAggregateFields = {
  __typename: 'org_member_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IOrgMemberMaxFields>;
  min?: Maybe<IOrgMemberMinFields>;
};


/** aggregate fields of "org_member" */
export type IOrgMemberAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IOrgMemberSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "org_member". All fields are combined with a logical 'AND'. */
export type IOrgMemberBoolExp = {
  _and?: InputMaybe<Array<IOrgMemberBoolExp>>;
  _not?: InputMaybe<IOrgMemberBoolExp>;
  _or?: InputMaybe<Array<IOrgMemberBoolExp>>;
  org_id?: InputMaybe<IUuidComparisonExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
};

/** aggregate max on columns */
export type IOrgMemberMaxFields = {
  __typename: 'org_member_max_fields';
  org_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type IOrgMemberMinFields = {
  __typename: 'org_member_min_fields';
  org_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** Ordering options when selecting data from "org_member". */
export type IOrgMemberOrderBy = {
  org_id?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
};

/** select columns of table "org_member" */
export enum IOrgMemberSelectColumn {
  /** column name */
  OrgId = 'org_id',
  /** column name */
  UserId = 'user_id'
}

/** Streaming cursor of the table "org_member" */
export type IOrgMemberStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IOrgMemberStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IOrgMemberStreamCursorValueInput = {
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate min on columns */
export type IOrgMinFields = {
  __typename: 'org_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  free_trial_until?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paying_customer_since?: Maybe<Scalars['date']['output']>;
  plan?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "org" */
export type IOrgMutationResponse = {
  __typename: 'org_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IOrg>;
};

/** input type for inserting object relation for remote table "org" */
export type IOrgObjRelInsertInput = {
  data: IOrgInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IOrgOnConflict>;
};

/** on_conflict condition type for table "org" */
export type IOrgOnConflict = {
  constraint: IOrgConstraint;
  update_columns?: Array<IOrgUpdateColumn>;
  where?: InputMaybe<IOrgBoolExp>;
};

/** Ordering options when selecting data from "org". */
export type IOrgOrderBy = {
  asset_manufacturers_aggregate?: InputMaybe<IAssetManufacturerAggregateOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  features?: InputMaybe<IOrgFeatureOrderBy>;
  free_trial_until?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  locations_aggregate?: InputMaybe<ILocationAggregateOrderBy>;
  members_aggregate?: InputMaybe<IUserAggregateOrderBy>;
  name?: InputMaybe<IOrderBy>;
  org_sso_settings_aggregate?: InputMaybe<IOrgSsoSettingsAggregateOrderBy>;
  paying_customer_since?: InputMaybe<IOrderBy>;
  plan?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  uploads_aggregate?: InputMaybe<IUploadAggregateOrderBy>;
};

/** primary key columns input for table: org */
export type IOrgPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** Default preferences for all users in an organization */
export type IOrgPrefs = {
  __typename: 'org_prefs';
  language: Scalars['String']['output'];
  org_id: Scalars['uuid']['output'];
};

/** aggregated selection of "org_prefs" */
export type IOrgPrefsAggregate = {
  __typename: 'org_prefs_aggregate';
  aggregate?: Maybe<IOrgPrefsAggregateFields>;
  nodes: Array<IOrgPrefs>;
};

/** aggregate fields of "org_prefs" */
export type IOrgPrefsAggregateFields = {
  __typename: 'org_prefs_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IOrgPrefsMaxFields>;
  min?: Maybe<IOrgPrefsMinFields>;
};


/** aggregate fields of "org_prefs" */
export type IOrgPrefsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IOrgPrefsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "org_prefs". All fields are combined with a logical 'AND'. */
export type IOrgPrefsBoolExp = {
  _and?: InputMaybe<Array<IOrgPrefsBoolExp>>;
  _not?: InputMaybe<IOrgPrefsBoolExp>;
  _or?: InputMaybe<Array<IOrgPrefsBoolExp>>;
  language?: InputMaybe<IStringComparisonExp>;
  org_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "org_prefs" */
export enum IOrgPrefsConstraint {
  /** unique or primary key constraint on columns "org_id" */
  OrgPrefsPkey = 'org_prefs_pkey'
}

/** input type for inserting data into table "org_prefs" */
export type IOrgPrefsInsertInput = {
  language?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IOrgPrefsMaxFields = {
  __typename: 'org_prefs_max_fields';
  language?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type IOrgPrefsMinFields = {
  __typename: 'org_prefs_min_fields';
  language?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "org_prefs" */
export type IOrgPrefsMutationResponse = {
  __typename: 'org_prefs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IOrgPrefs>;
};

/** on_conflict condition type for table "org_prefs" */
export type IOrgPrefsOnConflict = {
  constraint: IOrgPrefsConstraint;
  update_columns?: Array<IOrgPrefsUpdateColumn>;
  where?: InputMaybe<IOrgPrefsBoolExp>;
};

/** Ordering options when selecting data from "org_prefs". */
export type IOrgPrefsOrderBy = {
  language?: InputMaybe<IOrderBy>;
  org_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: org_prefs */
export type IOrgPrefsPkColumnsInput = {
  org_id: Scalars['uuid']['input'];
};

/** select columns of table "org_prefs" */
export enum IOrgPrefsSelectColumn {
  /** column name */
  Language = 'language',
  /** column name */
  OrgId = 'org_id'
}

/** input type for updating data in table "org_prefs" */
export type IOrgPrefsSetInput = {
  language?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "org_prefs" */
export type IOrgPrefsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IOrgPrefsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IOrgPrefsStreamCursorValueInput = {
  language?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "org_prefs" */
export enum IOrgPrefsUpdateColumn {
  /** column name */
  Language = 'language',
  /** column name */
  OrgId = 'org_id'
}

export type IOrgPrefsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IOrgPrefsSetInput>;
  /** filter the rows which have to be updated */
  where: IOrgPrefsBoolExp;
};

/** select columns of table "org" */
export enum IOrgSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FreeTrialUntil = 'free_trial_until',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PayingCustomerSince = 'paying_customer_since',
  /** column name */
  Plan = 'plan',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "org" */
export type IOrgSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  free_trial_until?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paying_customer_since?: InputMaybe<Scalars['date']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "org_sso_settings" */
export type IOrgSsoSettings = {
  __typename: 'org_sso_settings';
  domain: Scalars['citext']['output'];
  id: Scalars['uuid']['output'];
  /** provider name on Keycloak */
  idp: Scalars['citext']['output'];
  onesignal_api_key?: Maybe<Scalars['String']['output']>;
  onesignal_app_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  org: IOrg;
  org_id: Scalars['uuid']['output'];
};

/** aggregated selection of "org_sso_settings" */
export type IOrgSsoSettingsAggregate = {
  __typename: 'org_sso_settings_aggregate';
  aggregate?: Maybe<IOrgSsoSettingsAggregateFields>;
  nodes: Array<IOrgSsoSettings>;
};

export type IOrgSsoSettingsAggregateBoolExp = {
  count?: InputMaybe<IOrgSsoSettingsAggregateBoolExpCount>;
};

export type IOrgSsoSettingsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IOrgSsoSettingsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IOrgSsoSettingsBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "org_sso_settings" */
export type IOrgSsoSettingsAggregateFields = {
  __typename: 'org_sso_settings_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IOrgSsoSettingsMaxFields>;
  min?: Maybe<IOrgSsoSettingsMinFields>;
};


/** aggregate fields of "org_sso_settings" */
export type IOrgSsoSettingsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IOrgSsoSettingsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "org_sso_settings" */
export type IOrgSsoSettingsAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IOrgSsoSettingsMaxOrderBy>;
  min?: InputMaybe<IOrgSsoSettingsMinOrderBy>;
};

/** input type for inserting array relation for remote table "org_sso_settings" */
export type IOrgSsoSettingsArrRelInsertInput = {
  data: Array<IOrgSsoSettingsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IOrgSsoSettingsOnConflict>;
};

/** Boolean expression to filter rows from the table "org_sso_settings". All fields are combined with a logical 'AND'. */
export type IOrgSsoSettingsBoolExp = {
  _and?: InputMaybe<Array<IOrgSsoSettingsBoolExp>>;
  _not?: InputMaybe<IOrgSsoSettingsBoolExp>;
  _or?: InputMaybe<Array<IOrgSsoSettingsBoolExp>>;
  domain?: InputMaybe<ICitextComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  idp?: InputMaybe<ICitextComparisonExp>;
  onesignal_api_key?: InputMaybe<IStringComparisonExp>;
  onesignal_app_id?: InputMaybe<IStringComparisonExp>;
  org?: InputMaybe<IOrgBoolExp>;
  org_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "org_sso_settings" */
export enum IOrgSsoSettingsConstraint {
  /** unique or primary key constraint on columns "id" */
  OrgSsoSettingsPkey = 'org_sso_settings_pkey'
}

/** input type for inserting data into table "org_sso_settings" */
export type IOrgSsoSettingsInsertInput = {
  domain?: InputMaybe<Scalars['citext']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  /** provider name on Keycloak */
  idp?: InputMaybe<Scalars['citext']['input']>;
  onesignal_api_key?: InputMaybe<Scalars['String']['input']>;
  onesignal_app_id?: InputMaybe<Scalars['String']['input']>;
  org?: InputMaybe<IOrgObjRelInsertInput>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IOrgSsoSettingsMaxFields = {
  __typename: 'org_sso_settings_max_fields';
  domain?: Maybe<Scalars['citext']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  /** provider name on Keycloak */
  idp?: Maybe<Scalars['citext']['output']>;
  onesignal_api_key?: Maybe<Scalars['String']['output']>;
  onesignal_app_id?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "org_sso_settings" */
export type IOrgSsoSettingsMaxOrderBy = {
  domain?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  /** provider name on Keycloak */
  idp?: InputMaybe<IOrderBy>;
  onesignal_api_key?: InputMaybe<IOrderBy>;
  onesignal_app_id?: InputMaybe<IOrderBy>;
  org_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IOrgSsoSettingsMinFields = {
  __typename: 'org_sso_settings_min_fields';
  domain?: Maybe<Scalars['citext']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  /** provider name on Keycloak */
  idp?: Maybe<Scalars['citext']['output']>;
  onesignal_api_key?: Maybe<Scalars['String']['output']>;
  onesignal_app_id?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "org_sso_settings" */
export type IOrgSsoSettingsMinOrderBy = {
  domain?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  /** provider name on Keycloak */
  idp?: InputMaybe<IOrderBy>;
  onesignal_api_key?: InputMaybe<IOrderBy>;
  onesignal_app_id?: InputMaybe<IOrderBy>;
  org_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "org_sso_settings" */
export type IOrgSsoSettingsMutationResponse = {
  __typename: 'org_sso_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IOrgSsoSettings>;
};

/** on_conflict condition type for table "org_sso_settings" */
export type IOrgSsoSettingsOnConflict = {
  constraint: IOrgSsoSettingsConstraint;
  update_columns?: Array<IOrgSsoSettingsUpdateColumn>;
  where?: InputMaybe<IOrgSsoSettingsBoolExp>;
};

/** Ordering options when selecting data from "org_sso_settings". */
export type IOrgSsoSettingsOrderBy = {
  domain?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  idp?: InputMaybe<IOrderBy>;
  onesignal_api_key?: InputMaybe<IOrderBy>;
  onesignal_app_id?: InputMaybe<IOrderBy>;
  org?: InputMaybe<IOrgOrderBy>;
  org_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: org_sso_settings */
export type IOrgSsoSettingsPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "org_sso_settings" */
export enum IOrgSsoSettingsSelectColumn {
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  Idp = 'idp',
  /** column name */
  OnesignalApiKey = 'onesignal_api_key',
  /** column name */
  OnesignalAppId = 'onesignal_app_id',
  /** column name */
  OrgId = 'org_id'
}

/** input type for updating data in table "org_sso_settings" */
export type IOrgSsoSettingsSetInput = {
  domain?: InputMaybe<Scalars['citext']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  /** provider name on Keycloak */
  idp?: InputMaybe<Scalars['citext']['input']>;
  onesignal_api_key?: InputMaybe<Scalars['String']['input']>;
  onesignal_app_id?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "org_sso_settings" */
export type IOrgSsoSettingsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IOrgSsoSettingsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IOrgSsoSettingsStreamCursorValueInput = {
  domain?: InputMaybe<Scalars['citext']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  /** provider name on Keycloak */
  idp?: InputMaybe<Scalars['citext']['input']>;
  onesignal_api_key?: InputMaybe<Scalars['String']['input']>;
  onesignal_app_id?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "org_sso_settings" */
export enum IOrgSsoSettingsUpdateColumn {
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  Idp = 'idp',
  /** column name */
  OnesignalApiKey = 'onesignal_api_key',
  /** column name */
  OnesignalAppId = 'onesignal_app_id',
  /** column name */
  OrgId = 'org_id'
}

export type IOrgSsoSettingsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IOrgSsoSettingsSetInput>;
  /** filter the rows which have to be updated */
  where: IOrgSsoSettingsBoolExp;
};

/** Streaming cursor of the table "org" */
export type IOrgStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IOrgStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IOrgStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  free_trial_until?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paying_customer_since?: InputMaybe<Scalars['date']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "org" */
export enum IOrgUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FreeTrialUntil = 'free_trial_until',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PayingCustomerSince = 'paying_customer_since',
  /** column name */
  Plan = 'plan',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type IOrgUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IOrgSetInput>;
  /** filter the rows which have to be updated */
  where: IOrgBoolExp;
};

export type IOverdueWorkOrdersWithoutNotificationArgs = {
  days?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "permission_role" */
export type IPermissionRole = {
  __typename: 'permission_role';
  /** A computed field, executes function "can_delete_permission_role" */
  can_delete?: Maybe<Scalars['Boolean']['output']>;
  created_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  features: Array<IPermissionRoleXFeatureFlag>;
  /** An aggregate relationship */
  features_aggregate: IPermissionRoleXFeatureFlagAggregate;
  id: Scalars['uuid']['output'];
  is_admin: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  org: IOrg;
  org_id: Scalars['uuid']['output'];
  /** An array relationship */
  scopes: Array<IPermissionRoleXPermissionScope>;
  /** An aggregate relationship */
  scopes_aggregate: IPermissionRoleXPermissionScopeAggregate;
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  users_with_role: Array<ILocationMember>;
  /** An aggregate relationship */
  users_with_role_aggregate: ILocationMemberAggregate;
};


/** columns and relationships of "permission_role" */
export type IPermissionRoleFeaturesArgs = {
  distinct_on?: InputMaybe<Array<IPermissionRoleXFeatureFlagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPermissionRoleXFeatureFlagOrderBy>>;
  where?: InputMaybe<IPermissionRoleXFeatureFlagBoolExp>;
};


/** columns and relationships of "permission_role" */
export type IPermissionRoleFeaturesAggregateArgs = {
  distinct_on?: InputMaybe<Array<IPermissionRoleXFeatureFlagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPermissionRoleXFeatureFlagOrderBy>>;
  where?: InputMaybe<IPermissionRoleXFeatureFlagBoolExp>;
};


/** columns and relationships of "permission_role" */
export type IPermissionRoleScopesArgs = {
  distinct_on?: InputMaybe<Array<IPermissionRoleXPermissionScopeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPermissionRoleXPermissionScopeOrderBy>>;
  where?: InputMaybe<IPermissionRoleXPermissionScopeBoolExp>;
};


/** columns and relationships of "permission_role" */
export type IPermissionRoleScopesAggregateArgs = {
  distinct_on?: InputMaybe<Array<IPermissionRoleXPermissionScopeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPermissionRoleXPermissionScopeOrderBy>>;
  where?: InputMaybe<IPermissionRoleXPermissionScopeBoolExp>;
};


/** columns and relationships of "permission_role" */
export type IPermissionRoleUsersWithRoleArgs = {
  distinct_on?: InputMaybe<Array<ILocationMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationMemberOrderBy>>;
  where?: InputMaybe<ILocationMemberBoolExp>;
};


/** columns and relationships of "permission_role" */
export type IPermissionRoleUsersWithRoleAggregateArgs = {
  distinct_on?: InputMaybe<Array<ILocationMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationMemberOrderBy>>;
  where?: InputMaybe<ILocationMemberBoolExp>;
};

/** aggregated selection of "permission_role" */
export type IPermissionRoleAggregate = {
  __typename: 'permission_role_aggregate';
  aggregate?: Maybe<IPermissionRoleAggregateFields>;
  nodes: Array<IPermissionRole>;
};

/** aggregate fields of "permission_role" */
export type IPermissionRoleAggregateFields = {
  __typename: 'permission_role_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IPermissionRoleMaxFields>;
  min?: Maybe<IPermissionRoleMinFields>;
};


/** aggregate fields of "permission_role" */
export type IPermissionRoleAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IPermissionRoleSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "permission_role". All fields are combined with a logical 'AND'. */
export type IPermissionRoleBoolExp = {
  _and?: InputMaybe<Array<IPermissionRoleBoolExp>>;
  _not?: InputMaybe<IPermissionRoleBoolExp>;
  _or?: InputMaybe<Array<IPermissionRoleBoolExp>>;
  can_delete?: InputMaybe<IBooleanComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  features?: InputMaybe<IPermissionRoleXFeatureFlagBoolExp>;
  features_aggregate?: InputMaybe<IPermissionRoleXFeatureFlagAggregateBoolExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  is_admin?: InputMaybe<IBooleanComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  org?: InputMaybe<IOrgBoolExp>;
  org_id?: InputMaybe<IUuidComparisonExp>;
  scopes?: InputMaybe<IPermissionRoleXPermissionScopeBoolExp>;
  scopes_aggregate?: InputMaybe<IPermissionRoleXPermissionScopeAggregateBoolExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  users_with_role?: InputMaybe<ILocationMemberBoolExp>;
  users_with_role_aggregate?: InputMaybe<ILocationMemberAggregateBoolExp>;
};

/** unique or primary key constraints on table "permission_role" */
export enum IPermissionRoleConstraint {
  /** unique or primary key constraint on columns "id" */
  PermissionRolePkey = 'permission_role_pkey'
}

/** input type for inserting data into table "permission_role" */
export type IPermissionRoleInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  features?: InputMaybe<IPermissionRoleXFeatureFlagArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_admin?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org?: InputMaybe<IOrgObjRelInsertInput>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  scopes?: InputMaybe<IPermissionRoleXPermissionScopeArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  users_with_role?: InputMaybe<ILocationMemberArrRelInsertInput>;
};

/** aggregate max on columns */
export type IPermissionRoleMaxFields = {
  __typename: 'permission_role_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type IPermissionRoleMinFields = {
  __typename: 'permission_role_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "permission_role" */
export type IPermissionRoleMutationResponse = {
  __typename: 'permission_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IPermissionRole>;
};

/** input type for inserting object relation for remote table "permission_role" */
export type IPermissionRoleObjRelInsertInput = {
  data: IPermissionRoleInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IPermissionRoleOnConflict>;
};

/** on_conflict condition type for table "permission_role" */
export type IPermissionRoleOnConflict = {
  constraint: IPermissionRoleConstraint;
  update_columns?: Array<IPermissionRoleUpdateColumn>;
  where?: InputMaybe<IPermissionRoleBoolExp>;
};

/** Ordering options when selecting data from "permission_role". */
export type IPermissionRoleOrderBy = {
  can_delete?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  features_aggregate?: InputMaybe<IPermissionRoleXFeatureFlagAggregateOrderBy>;
  id?: InputMaybe<IOrderBy>;
  is_admin?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  org?: InputMaybe<IOrgOrderBy>;
  org_id?: InputMaybe<IOrderBy>;
  scopes_aggregate?: InputMaybe<IPermissionRoleXPermissionScopeAggregateOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  users_with_role_aggregate?: InputMaybe<ILocationMemberAggregateOrderBy>;
};

/** primary key columns input for table: permission_role */
export type IPermissionRolePkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "permission_role" */
export enum IPermissionRoleSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsAdmin = 'is_admin',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "permission_role" */
export type IPermissionRoleSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_admin?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "permission_role" */
export type IPermissionRoleStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IPermissionRoleStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IPermissionRoleStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_admin?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "permission_role" */
export enum IPermissionRoleUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsAdmin = 'is_admin',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type IPermissionRoleUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IPermissionRoleSetInput>;
  /** filter the rows which have to be updated */
  where: IPermissionRoleBoolExp;
};

/** columns and relationships of "permission_role_x_feature_flag" */
export type IPermissionRoleXFeatureFlag = {
  __typename: 'permission_role_x_feature_flag';
  created_at: Scalars['timestamptz']['output'];
  enabled: Scalars['Boolean']['output'];
  feature_flag: Scalars['String']['output'];
  /** An object relationship */
  location: ILocation;
  location_id: Scalars['uuid']['output'];
  /** An array relationship */
  location_members: Array<ILocationMember>;
  /** An aggregate relationship */
  location_members_aggregate: ILocationMemberAggregate;
  permission_role_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "permission_role_x_feature_flag" */
export type IPermissionRoleXFeatureFlagLocationMembersArgs = {
  distinct_on?: InputMaybe<Array<ILocationMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationMemberOrderBy>>;
  where?: InputMaybe<ILocationMemberBoolExp>;
};


/** columns and relationships of "permission_role_x_feature_flag" */
export type IPermissionRoleXFeatureFlagLocationMembersAggregateArgs = {
  distinct_on?: InputMaybe<Array<ILocationMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationMemberOrderBy>>;
  where?: InputMaybe<ILocationMemberBoolExp>;
};

/** aggregated selection of "permission_role_x_feature_flag" */
export type IPermissionRoleXFeatureFlagAggregate = {
  __typename: 'permission_role_x_feature_flag_aggregate';
  aggregate?: Maybe<IPermissionRoleXFeatureFlagAggregateFields>;
  nodes: Array<IPermissionRoleXFeatureFlag>;
};

export type IPermissionRoleXFeatureFlagAggregateBoolExp = {
  bool_and?: InputMaybe<IPermissionRoleXFeatureFlagAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<IPermissionRoleXFeatureFlagAggregateBoolExpBoolOr>;
  count?: InputMaybe<IPermissionRoleXFeatureFlagAggregateBoolExpCount>;
};

export type IPermissionRoleXFeatureFlagAggregateBoolExpBoolAnd = {
  arguments: IPermissionRoleXFeatureFlagSelectColumnPermissionRoleXFeatureFlagAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IPermissionRoleXFeatureFlagBoolExp>;
  predicate: IBooleanComparisonExp;
};

export type IPermissionRoleXFeatureFlagAggregateBoolExpBoolOr = {
  arguments: IPermissionRoleXFeatureFlagSelectColumnPermissionRoleXFeatureFlagAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IPermissionRoleXFeatureFlagBoolExp>;
  predicate: IBooleanComparisonExp;
};

export type IPermissionRoleXFeatureFlagAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IPermissionRoleXFeatureFlagSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IPermissionRoleXFeatureFlagBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "permission_role_x_feature_flag" */
export type IPermissionRoleXFeatureFlagAggregateFields = {
  __typename: 'permission_role_x_feature_flag_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IPermissionRoleXFeatureFlagMaxFields>;
  min?: Maybe<IPermissionRoleXFeatureFlagMinFields>;
};


/** aggregate fields of "permission_role_x_feature_flag" */
export type IPermissionRoleXFeatureFlagAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IPermissionRoleXFeatureFlagSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "permission_role_x_feature_flag" */
export type IPermissionRoleXFeatureFlagAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IPermissionRoleXFeatureFlagMaxOrderBy>;
  min?: InputMaybe<IPermissionRoleXFeatureFlagMinOrderBy>;
};

/** input type for inserting array relation for remote table "permission_role_x_feature_flag" */
export type IPermissionRoleXFeatureFlagArrRelInsertInput = {
  data: Array<IPermissionRoleXFeatureFlagInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IPermissionRoleXFeatureFlagOnConflict>;
};

/** Boolean expression to filter rows from the table "permission_role_x_feature_flag". All fields are combined with a logical 'AND'. */
export type IPermissionRoleXFeatureFlagBoolExp = {
  _and?: InputMaybe<Array<IPermissionRoleXFeatureFlagBoolExp>>;
  _not?: InputMaybe<IPermissionRoleXFeatureFlagBoolExp>;
  _or?: InputMaybe<Array<IPermissionRoleXFeatureFlagBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  enabled?: InputMaybe<IBooleanComparisonExp>;
  feature_flag?: InputMaybe<IStringComparisonExp>;
  location?: InputMaybe<ILocationBoolExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  location_members?: InputMaybe<ILocationMemberBoolExp>;
  location_members_aggregate?: InputMaybe<ILocationMemberAggregateBoolExp>;
  permission_role_id?: InputMaybe<IUuidComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "permission_role_x_feature_flag" */
export enum IPermissionRoleXFeatureFlagConstraint {
  /** unique or primary key constraint on columns "permission_role_id", "location_id", "feature_flag" */
  PermissionRoleXFeatureFlagPkey = 'permission_role_x_feature_flag_pkey'
}

/** input type for inserting data into table "permission_role_x_feature_flag" */
export type IPermissionRoleXFeatureFlagInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  feature_flag?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<ILocationObjRelInsertInput>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  location_members?: InputMaybe<ILocationMemberArrRelInsertInput>;
  permission_role_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type IPermissionRoleXFeatureFlagMaxFields = {
  __typename: 'permission_role_x_feature_flag_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  feature_flag?: Maybe<Scalars['String']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  permission_role_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "permission_role_x_feature_flag" */
export type IPermissionRoleXFeatureFlagMaxOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  feature_flag?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  permission_role_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IPermissionRoleXFeatureFlagMinFields = {
  __typename: 'permission_role_x_feature_flag_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  feature_flag?: Maybe<Scalars['String']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  permission_role_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "permission_role_x_feature_flag" */
export type IPermissionRoleXFeatureFlagMinOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  feature_flag?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  permission_role_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "permission_role_x_feature_flag" */
export type IPermissionRoleXFeatureFlagMutationResponse = {
  __typename: 'permission_role_x_feature_flag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IPermissionRoleXFeatureFlag>;
};

/** on_conflict condition type for table "permission_role_x_feature_flag" */
export type IPermissionRoleXFeatureFlagOnConflict = {
  constraint: IPermissionRoleXFeatureFlagConstraint;
  update_columns?: Array<IPermissionRoleXFeatureFlagUpdateColumn>;
  where?: InputMaybe<IPermissionRoleXFeatureFlagBoolExp>;
};

/** Ordering options when selecting data from "permission_role_x_feature_flag". */
export type IPermissionRoleXFeatureFlagOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  enabled?: InputMaybe<IOrderBy>;
  feature_flag?: InputMaybe<IOrderBy>;
  location?: InputMaybe<ILocationOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  location_members_aggregate?: InputMaybe<ILocationMemberAggregateOrderBy>;
  permission_role_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: permission_role_x_feature_flag */
export type IPermissionRoleXFeatureFlagPkColumnsInput = {
  feature_flag: Scalars['String']['input'];
  location_id: Scalars['uuid']['input'];
  permission_role_id: Scalars['uuid']['input'];
};

/** select columns of table "permission_role_x_feature_flag" */
export enum IPermissionRoleXFeatureFlagSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  FeatureFlag = 'feature_flag',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  PermissionRoleId = 'permission_role_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "permission_role_x_feature_flag_aggregate_bool_exp_bool_and_arguments_columns" columns of table "permission_role_x_feature_flag" */
export enum IPermissionRoleXFeatureFlagSelectColumnPermissionRoleXFeatureFlagAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  Enabled = 'enabled'
}

/** select "permission_role_x_feature_flag_aggregate_bool_exp_bool_or_arguments_columns" columns of table "permission_role_x_feature_flag" */
export enum IPermissionRoleXFeatureFlagSelectColumnPermissionRoleXFeatureFlagAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  Enabled = 'enabled'
}

/** input type for updating data in table "permission_role_x_feature_flag" */
export type IPermissionRoleXFeatureFlagSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  feature_flag?: InputMaybe<Scalars['String']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  permission_role_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "permission_role_x_feature_flag" */
export type IPermissionRoleXFeatureFlagStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IPermissionRoleXFeatureFlagStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IPermissionRoleXFeatureFlagStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  feature_flag?: InputMaybe<Scalars['String']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  permission_role_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "permission_role_x_feature_flag" */
export enum IPermissionRoleXFeatureFlagUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  FeatureFlag = 'feature_flag',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  PermissionRoleId = 'permission_role_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type IPermissionRoleXFeatureFlagUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IPermissionRoleXFeatureFlagSetInput>;
  /** filter the rows which have to be updated */
  where: IPermissionRoleXFeatureFlagBoolExp;
};

/** columns and relationships of "permission_role_x_permission_scope" */
export type IPermissionRoleXPermissionScope = {
  __typename: 'permission_role_x_permission_scope';
  options?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  permission_role: IPermissionRole;
  /** An object relationship */
  permission_scope: IPermissionScope;
  role_id: Scalars['uuid']['output'];
  scope: IPermissionScopeEnum;
};


/** columns and relationships of "permission_role_x_permission_scope" */
export type IPermissionRoleXPermissionScopeOptionsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "permission_role_x_permission_scope" */
export type IPermissionRoleXPermissionScopeAggregate = {
  __typename: 'permission_role_x_permission_scope_aggregate';
  aggregate?: Maybe<IPermissionRoleXPermissionScopeAggregateFields>;
  nodes: Array<IPermissionRoleXPermissionScope>;
};

export type IPermissionRoleXPermissionScopeAggregateBoolExp = {
  count?: InputMaybe<IPermissionRoleXPermissionScopeAggregateBoolExpCount>;
};

export type IPermissionRoleXPermissionScopeAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IPermissionRoleXPermissionScopeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IPermissionRoleXPermissionScopeBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "permission_role_x_permission_scope" */
export type IPermissionRoleXPermissionScopeAggregateFields = {
  __typename: 'permission_role_x_permission_scope_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IPermissionRoleXPermissionScopeMaxFields>;
  min?: Maybe<IPermissionRoleXPermissionScopeMinFields>;
};


/** aggregate fields of "permission_role_x_permission_scope" */
export type IPermissionRoleXPermissionScopeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IPermissionRoleXPermissionScopeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "permission_role_x_permission_scope" */
export type IPermissionRoleXPermissionScopeAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IPermissionRoleXPermissionScopeMaxOrderBy>;
  min?: InputMaybe<IPermissionRoleXPermissionScopeMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IPermissionRoleXPermissionScopeAppendInput = {
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "permission_role_x_permission_scope" */
export type IPermissionRoleXPermissionScopeArrRelInsertInput = {
  data: Array<IPermissionRoleXPermissionScopeInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IPermissionRoleXPermissionScopeOnConflict>;
};

/** Boolean expression to filter rows from the table "permission_role_x_permission_scope". All fields are combined with a logical 'AND'. */
export type IPermissionRoleXPermissionScopeBoolExp = {
  _and?: InputMaybe<Array<IPermissionRoleXPermissionScopeBoolExp>>;
  _not?: InputMaybe<IPermissionRoleXPermissionScopeBoolExp>;
  _or?: InputMaybe<Array<IPermissionRoleXPermissionScopeBoolExp>>;
  options?: InputMaybe<IJsonbComparisonExp>;
  permission_role?: InputMaybe<IPermissionRoleBoolExp>;
  permission_scope?: InputMaybe<IPermissionScopeBoolExp>;
  role_id?: InputMaybe<IUuidComparisonExp>;
  scope?: InputMaybe<IPermissionScopeEnumComparisonExp>;
};

/** unique or primary key constraints on table "permission_role_x_permission_scope" */
export enum IPermissionRoleXPermissionScopeConstraint {
  /** unique or primary key constraint on columns "role_id", "scope" */
  PermissionRoleXPermissionScopePkey = 'permission_role_x_permission_scope_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IPermissionRoleXPermissionScopeDeleteAtPathInput = {
  options?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IPermissionRoleXPermissionScopeDeleteElemInput = {
  options?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IPermissionRoleXPermissionScopeDeleteKeyInput = {
  options?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "permission_role_x_permission_scope" */
export type IPermissionRoleXPermissionScopeInsertInput = {
  options?: InputMaybe<Scalars['jsonb']['input']>;
  permission_role?: InputMaybe<IPermissionRoleObjRelInsertInput>;
  permission_scope?: InputMaybe<IPermissionScopeObjRelInsertInput>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  scope?: InputMaybe<IPermissionScopeEnum>;
};

/** aggregate max on columns */
export type IPermissionRoleXPermissionScopeMaxFields = {
  __typename: 'permission_role_x_permission_scope_max_fields';
  role_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "permission_role_x_permission_scope" */
export type IPermissionRoleXPermissionScopeMaxOrderBy = {
  role_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IPermissionRoleXPermissionScopeMinFields = {
  __typename: 'permission_role_x_permission_scope_min_fields';
  role_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "permission_role_x_permission_scope" */
export type IPermissionRoleXPermissionScopeMinOrderBy = {
  role_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "permission_role_x_permission_scope" */
export type IPermissionRoleXPermissionScopeMutationResponse = {
  __typename: 'permission_role_x_permission_scope_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IPermissionRoleXPermissionScope>;
};

/** on_conflict condition type for table "permission_role_x_permission_scope" */
export type IPermissionRoleXPermissionScopeOnConflict = {
  constraint: IPermissionRoleXPermissionScopeConstraint;
  update_columns?: Array<IPermissionRoleXPermissionScopeUpdateColumn>;
  where?: InputMaybe<IPermissionRoleXPermissionScopeBoolExp>;
};

/** Ordering options when selecting data from "permission_role_x_permission_scope". */
export type IPermissionRoleXPermissionScopeOrderBy = {
  options?: InputMaybe<IOrderBy>;
  permission_role?: InputMaybe<IPermissionRoleOrderBy>;
  permission_scope?: InputMaybe<IPermissionScopeOrderBy>;
  role_id?: InputMaybe<IOrderBy>;
  scope?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: permission_role_x_permission_scope */
export type IPermissionRoleXPermissionScopePkColumnsInput = {
  role_id: Scalars['uuid']['input'];
  scope: IPermissionScopeEnum;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IPermissionRoleXPermissionScopePrependInput = {
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "permission_role_x_permission_scope" */
export enum IPermissionRoleXPermissionScopeSelectColumn {
  /** column name */
  Options = 'options',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  Scope = 'scope'
}

/** input type for updating data in table "permission_role_x_permission_scope" */
export type IPermissionRoleXPermissionScopeSetInput = {
  options?: InputMaybe<Scalars['jsonb']['input']>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  scope?: InputMaybe<IPermissionScopeEnum>;
};

/** Streaming cursor of the table "permission_role_x_permission_scope" */
export type IPermissionRoleXPermissionScopeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IPermissionRoleXPermissionScopeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IPermissionRoleXPermissionScopeStreamCursorValueInput = {
  options?: InputMaybe<Scalars['jsonb']['input']>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  scope?: InputMaybe<IPermissionScopeEnum>;
};

/** update columns of table "permission_role_x_permission_scope" */
export enum IPermissionRoleXPermissionScopeUpdateColumn {
  /** column name */
  Options = 'options',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  Scope = 'scope'
}

export type IPermissionRoleXPermissionScopeUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IPermissionRoleXPermissionScopeAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IPermissionRoleXPermissionScopeDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IPermissionRoleXPermissionScopeDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IPermissionRoleXPermissionScopeDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IPermissionRoleXPermissionScopePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IPermissionRoleXPermissionScopeSetInput>;
  /** filter the rows which have to be updated */
  where: IPermissionRoleXPermissionScopeBoolExp;
};

/** columns and relationships of "permission_scope" */
export type IPermissionScope = {
  __typename: 'permission_scope';
  inherited_role: Scalars['String']['output'];
  scope: Scalars['String']['output'];
};

/** aggregated selection of "permission_scope" */
export type IPermissionScopeAggregate = {
  __typename: 'permission_scope_aggregate';
  aggregate?: Maybe<IPermissionScopeAggregateFields>;
  nodes: Array<IPermissionScope>;
};

/** aggregate fields of "permission_scope" */
export type IPermissionScopeAggregateFields = {
  __typename: 'permission_scope_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IPermissionScopeMaxFields>;
  min?: Maybe<IPermissionScopeMinFields>;
};


/** aggregate fields of "permission_scope" */
export type IPermissionScopeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IPermissionScopeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "permission_scope". All fields are combined with a logical 'AND'. */
export type IPermissionScopeBoolExp = {
  _and?: InputMaybe<Array<IPermissionScopeBoolExp>>;
  _not?: InputMaybe<IPermissionScopeBoolExp>;
  _or?: InputMaybe<Array<IPermissionScopeBoolExp>>;
  inherited_role?: InputMaybe<IStringComparisonExp>;
  scope?: InputMaybe<IStringComparisonExp>;
};

/** unique or primary key constraints on table "permission_scope" */
export enum IPermissionScopeConstraint {
  /** unique or primary key constraint on columns "scope" */
  PermissionScopePkey = 'permission_scope_pkey'
}

export enum IPermissionScopeEnum {
  /** account */
  AppAccountManagement = 'app_account_management',
  /** a_d */
  AppAssetArchiveDelete = 'app_asset_archive_delete',
  /** a_c */
  AppAssetCreate = 'app_asset_create',
  /** a_e */
  AppAssetEdit = 'app_asset_edit',
  /** data */
  AppDataEntry = 'app_data_entry',
  /** f_a */
  AppFullAccess = 'app_full_access',
  /** man */
  AppManufacturerCreateEditDelete = 'app_manufacturer_create_edit_delete',
  /** sr_c */
  AppServiceRequestCreate = 'app_service_request_create',
  /** sr_m */
  AppServiceRequestManage = 'app_service_request_manage',
  /** usr */
  AppUser = 'app_user',
  /** wo_ap */
  AppWorkOrderApproval = 'app_work_order_approval',
  /** wo_c */
  AppWorkOrderCreate = 'app_work_order_create',
  /** wo_d */
  AppWorkOrderDelete = 'app_work_order_delete',
  /** wo_e */
  AppWorkOrderEdit = 'app_work_order_edit'
}

/** Boolean expression to compare columns of type "permission_scope_enum". All fields are combined with logical 'AND'. */
export type IPermissionScopeEnumComparisonExp = {
  _eq?: InputMaybe<IPermissionScopeEnum>;
  _in?: InputMaybe<Array<IPermissionScopeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<IPermissionScopeEnum>;
  _nin?: InputMaybe<Array<IPermissionScopeEnum>>;
};

/** input type for inserting data into table "permission_scope" */
export type IPermissionScopeInsertInput = {
  inherited_role?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type IPermissionScopeMaxFields = {
  __typename: 'permission_scope_max_fields';
  inherited_role?: Maybe<Scalars['String']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IPermissionScopeMinFields = {
  __typename: 'permission_scope_min_fields';
  inherited_role?: Maybe<Scalars['String']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "permission_scope" */
export type IPermissionScopeMutationResponse = {
  __typename: 'permission_scope_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IPermissionScope>;
};

/** input type for inserting object relation for remote table "permission_scope" */
export type IPermissionScopeObjRelInsertInput = {
  data: IPermissionScopeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IPermissionScopeOnConflict>;
};

/** on_conflict condition type for table "permission_scope" */
export type IPermissionScopeOnConflict = {
  constraint: IPermissionScopeConstraint;
  update_columns?: Array<IPermissionScopeUpdateColumn>;
  where?: InputMaybe<IPermissionScopeBoolExp>;
};

/** Ordering options when selecting data from "permission_scope". */
export type IPermissionScopeOrderBy = {
  inherited_role?: InputMaybe<IOrderBy>;
  scope?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: permission_scope */
export type IPermissionScopePkColumnsInput = {
  scope: Scalars['String']['input'];
};

/** select columns of table "permission_scope" */
export enum IPermissionScopeSelectColumn {
  /** column name */
  InheritedRole = 'inherited_role',
  /** column name */
  Scope = 'scope'
}

/** input type for updating data in table "permission_scope" */
export type IPermissionScopeSetInput = {
  inherited_role?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "permission_scope" */
export type IPermissionScopeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IPermissionScopeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IPermissionScopeStreamCursorValueInput = {
  inherited_role?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "permission_scope" */
export enum IPermissionScopeUpdateColumn {
  /** column name */
  InheritedRole = 'inherited_role',
  /** column name */
  Scope = 'scope'
}

export type IPermissionScopeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IPermissionScopeSetInput>;
  /** filter the rows which have to be updated */
  where: IPermissionScopeBoolExp;
};

/** columns and relationships of "place" */
export type IPlace = {
  __typename: 'place';
  /** An array relationship */
  assets: Array<IAsset>;
  /** An aggregate relationship */
  assets_aggregate: IAssetAggregate;
  /** A computed field, executes function "assets_belongs_to_a_place_and_all_levels_sub_places" */
  assets_in_all_levels?: Maybe<Array<IAsset>>;
  /** An array relationship */
  children: Array<IPlace>;
  /** An aggregate relationship */
  children_aggregate: IPlaceAggregate;
  /** An array relationship */
  documents: Array<IPlaceXUpload>;
  /** An aggregate relationship */
  documents_aggregate: IPlaceXUploadAggregate;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  location: ILocation;
  location_id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  parent?: Maybe<IPlace>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
};


/** columns and relationships of "place" */
export type IPlaceAssetsArgs = {
  distinct_on?: InputMaybe<Array<IAssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetOrderBy>>;
  where?: InputMaybe<IAssetBoolExp>;
};


/** columns and relationships of "place" */
export type IPlaceAssetsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetOrderBy>>;
  where?: InputMaybe<IAssetBoolExp>;
};


/** columns and relationships of "place" */
export type IPlaceAssetsInAllLevelsArgs = {
  distinct_on?: InputMaybe<Array<IAssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetOrderBy>>;
  where?: InputMaybe<IAssetBoolExp>;
};


/** columns and relationships of "place" */
export type IPlaceChildrenArgs = {
  distinct_on?: InputMaybe<Array<IPlaceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPlaceOrderBy>>;
  where?: InputMaybe<IPlaceBoolExp>;
};


/** columns and relationships of "place" */
export type IPlaceChildrenAggregateArgs = {
  distinct_on?: InputMaybe<Array<IPlaceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPlaceOrderBy>>;
  where?: InputMaybe<IPlaceBoolExp>;
};


/** columns and relationships of "place" */
export type IPlaceDocumentsArgs = {
  distinct_on?: InputMaybe<Array<IPlaceXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPlaceXUploadOrderBy>>;
  where?: InputMaybe<IPlaceXUploadBoolExp>;
};


/** columns and relationships of "place" */
export type IPlaceDocumentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IPlaceXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPlaceXUploadOrderBy>>;
  where?: InputMaybe<IPlaceXUploadBoolExp>;
};

/** aggregated selection of "place" */
export type IPlaceAggregate = {
  __typename: 'place_aggregate';
  aggregate?: Maybe<IPlaceAggregateFields>;
  nodes: Array<IPlace>;
};

export type IPlaceAggregateBoolExp = {
  count?: InputMaybe<IPlaceAggregateBoolExpCount>;
};

export type IPlaceAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IPlaceSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IPlaceBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "place" */
export type IPlaceAggregateFields = {
  __typename: 'place_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IPlaceMaxFields>;
  min?: Maybe<IPlaceMinFields>;
};


/** aggregate fields of "place" */
export type IPlaceAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IPlaceSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "place" */
export type IPlaceAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IPlaceMaxOrderBy>;
  min?: InputMaybe<IPlaceMinOrderBy>;
};

/** input type for inserting array relation for remote table "place" */
export type IPlaceArrRelInsertInput = {
  data: Array<IPlaceInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IPlaceOnConflict>;
};

/** Boolean expression to filter rows from the table "place". All fields are combined with a logical 'AND'. */
export type IPlaceBoolExp = {
  _and?: InputMaybe<Array<IPlaceBoolExp>>;
  _not?: InputMaybe<IPlaceBoolExp>;
  _or?: InputMaybe<Array<IPlaceBoolExp>>;
  assets?: InputMaybe<IAssetBoolExp>;
  assets_aggregate?: InputMaybe<IAssetAggregateBoolExp>;
  assets_in_all_levels?: InputMaybe<IAssetBoolExp>;
  children?: InputMaybe<IPlaceBoolExp>;
  children_aggregate?: InputMaybe<IPlaceAggregateBoolExp>;
  documents?: InputMaybe<IPlaceXUploadBoolExp>;
  documents_aggregate?: InputMaybe<IPlaceXUploadAggregateBoolExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  location?: InputMaybe<ILocationBoolExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  note?: InputMaybe<IStringComparisonExp>;
  parent?: InputMaybe<IPlaceBoolExp>;
  parent_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "place" */
export enum IPlaceConstraint {
  /** unique or primary key constraint on columns "location_id", "name" */
  PlaceLocationIdNameKey = 'place_location_id_name_key',
  /** unique or primary key constraint on columns "location_id", "name" */
  PlaceNameLocationIdParentIdKey = 'place_name_location_id_parent_id_key',
  /** unique or primary key constraint on columns "id" */
  PlacePkey = 'place_pkey'
}

/** input type for inserting data into table "place" */
export type IPlaceInsertInput = {
  assets?: InputMaybe<IAssetArrRelInsertInput>;
  children?: InputMaybe<IPlaceArrRelInsertInput>;
  documents?: InputMaybe<IPlaceXUploadArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location?: InputMaybe<ILocationObjRelInsertInput>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<IPlaceObjRelInsertInput>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IPlaceMaxFields = {
  __typename: 'place_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "place" */
export type IPlaceMaxOrderBy = {
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  note?: InputMaybe<IOrderBy>;
  parent_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IPlaceMinFields = {
  __typename: 'place_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "place" */
export type IPlaceMinOrderBy = {
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  note?: InputMaybe<IOrderBy>;
  parent_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "place" */
export type IPlaceMutationResponse = {
  __typename: 'place_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IPlace>;
};

/** input type for inserting object relation for remote table "place" */
export type IPlaceObjRelInsertInput = {
  data: IPlaceInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IPlaceOnConflict>;
};

/** on_conflict condition type for table "place" */
export type IPlaceOnConflict = {
  constraint: IPlaceConstraint;
  update_columns?: Array<IPlaceUpdateColumn>;
  where?: InputMaybe<IPlaceBoolExp>;
};

/** Ordering options when selecting data from "place". */
export type IPlaceOrderBy = {
  assets_aggregate?: InputMaybe<IAssetAggregateOrderBy>;
  assets_in_all_levels_aggregate?: InputMaybe<IAssetAggregateOrderBy>;
  children_aggregate?: InputMaybe<IPlaceAggregateOrderBy>;
  documents_aggregate?: InputMaybe<IPlaceXUploadAggregateOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location?: InputMaybe<ILocationOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  note?: InputMaybe<IOrderBy>;
  parent?: InputMaybe<IPlaceOrderBy>;
  parent_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: place */
export type IPlacePkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "place" */
export enum IPlaceSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  ParentId = 'parent_id'
}

/** input type for updating data in table "place" */
export type IPlaceSetInput = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "place" */
export type IPlaceStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IPlaceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IPlaceStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "place" */
export enum IPlaceUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  ParentId = 'parent_id'
}

export type IPlaceUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IPlaceSetInput>;
  /** filter the rows which have to be updated */
  where: IPlaceBoolExp;
};

/** columns and relationships of "place_x_upload" */
export type IPlaceXUpload = {
  __typename: 'place_x_upload';
  /** An object relationship */
  place: IPlace;
  place_id: Scalars['uuid']['output'];
  /** An object relationship */
  upload: IUpload;
  upload_id: Scalars['uuid']['output'];
};

/** aggregated selection of "place_x_upload" */
export type IPlaceXUploadAggregate = {
  __typename: 'place_x_upload_aggregate';
  aggregate?: Maybe<IPlaceXUploadAggregateFields>;
  nodes: Array<IPlaceXUpload>;
};

export type IPlaceXUploadAggregateBoolExp = {
  count?: InputMaybe<IPlaceXUploadAggregateBoolExpCount>;
};

export type IPlaceXUploadAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IPlaceXUploadSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IPlaceXUploadBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "place_x_upload" */
export type IPlaceXUploadAggregateFields = {
  __typename: 'place_x_upload_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IPlaceXUploadMaxFields>;
  min?: Maybe<IPlaceXUploadMinFields>;
};


/** aggregate fields of "place_x_upload" */
export type IPlaceXUploadAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IPlaceXUploadSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "place_x_upload" */
export type IPlaceXUploadAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IPlaceXUploadMaxOrderBy>;
  min?: InputMaybe<IPlaceXUploadMinOrderBy>;
};

/** input type for inserting array relation for remote table "place_x_upload" */
export type IPlaceXUploadArrRelInsertInput = {
  data: Array<IPlaceXUploadInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IPlaceXUploadOnConflict>;
};

/** Boolean expression to filter rows from the table "place_x_upload". All fields are combined with a logical 'AND'. */
export type IPlaceXUploadBoolExp = {
  _and?: InputMaybe<Array<IPlaceXUploadBoolExp>>;
  _not?: InputMaybe<IPlaceXUploadBoolExp>;
  _or?: InputMaybe<Array<IPlaceXUploadBoolExp>>;
  place?: InputMaybe<IPlaceBoolExp>;
  place_id?: InputMaybe<IUuidComparisonExp>;
  upload?: InputMaybe<IUploadBoolExp>;
  upload_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "place_x_upload" */
export enum IPlaceXUploadConstraint {
  /** unique or primary key constraint on columns "place_id", "upload_id" */
  PlaceXUploadPkey = 'place_x_upload_pkey'
}

/** input type for inserting data into table "place_x_upload" */
export type IPlaceXUploadInsertInput = {
  place?: InputMaybe<IPlaceObjRelInsertInput>;
  place_id?: InputMaybe<Scalars['uuid']['input']>;
  upload?: InputMaybe<IUploadObjRelInsertInput>;
  upload_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IPlaceXUploadMaxFields = {
  __typename: 'place_x_upload_max_fields';
  place_id?: Maybe<Scalars['uuid']['output']>;
  upload_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "place_x_upload" */
export type IPlaceXUploadMaxOrderBy = {
  place_id?: InputMaybe<IOrderBy>;
  upload_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IPlaceXUploadMinFields = {
  __typename: 'place_x_upload_min_fields';
  place_id?: Maybe<Scalars['uuid']['output']>;
  upload_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "place_x_upload" */
export type IPlaceXUploadMinOrderBy = {
  place_id?: InputMaybe<IOrderBy>;
  upload_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "place_x_upload" */
export type IPlaceXUploadMutationResponse = {
  __typename: 'place_x_upload_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IPlaceXUpload>;
};

/** on_conflict condition type for table "place_x_upload" */
export type IPlaceXUploadOnConflict = {
  constraint: IPlaceXUploadConstraint;
  update_columns?: Array<IPlaceXUploadUpdateColumn>;
  where?: InputMaybe<IPlaceXUploadBoolExp>;
};

/** Ordering options when selecting data from "place_x_upload". */
export type IPlaceXUploadOrderBy = {
  place?: InputMaybe<IPlaceOrderBy>;
  place_id?: InputMaybe<IOrderBy>;
  upload?: InputMaybe<IUploadOrderBy>;
  upload_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: place_x_upload */
export type IPlaceXUploadPkColumnsInput = {
  place_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};

/** select columns of table "place_x_upload" */
export enum IPlaceXUploadSelectColumn {
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  UploadId = 'upload_id'
}

/** input type for updating data in table "place_x_upload" */
export type IPlaceXUploadSetInput = {
  place_id?: InputMaybe<Scalars['uuid']['input']>;
  upload_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "place_x_upload" */
export type IPlaceXUploadStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IPlaceXUploadStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IPlaceXUploadStreamCursorValueInput = {
  place_id?: InputMaybe<Scalars['uuid']['input']>;
  upload_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "place_x_upload" */
export enum IPlaceXUploadUpdateColumn {
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  UploadId = 'upload_id'
}

export type IPlaceXUploadUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IPlaceXUploadSetInput>;
  /** filter the rows which have to be updated */
  where: IPlaceXUploadBoolExp;
};

/** columns and relationships of "project" */
export type IProject = {
  __typename: 'project';
  icon?: Maybe<Scalars['jsonb']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  lead?: Maybe<IUser>;
  lead_id?: Maybe<Scalars['uuid']['output']>;
  location_id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  status: IProjectStatusEnum;
  /** An array relationship */
  teams: Array<IProjectXTeam>;
  /** An aggregate relationship */
  teams_aggregate: IProjectXTeamAggregate;
};


/** columns and relationships of "project" */
export type IProjectIconArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "project" */
export type IProjectTeamsArgs = {
  distinct_on?: InputMaybe<Array<IProjectXTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IProjectXTeamOrderBy>>;
  where?: InputMaybe<IProjectXTeamBoolExp>;
};


/** columns and relationships of "project" */
export type IProjectTeamsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IProjectXTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IProjectXTeamOrderBy>>;
  where?: InputMaybe<IProjectXTeamBoolExp>;
};

/** aggregated selection of "project" */
export type IProjectAggregate = {
  __typename: 'project_aggregate';
  aggregate?: Maybe<IProjectAggregateFields>;
  nodes: Array<IProject>;
};

/** aggregate fields of "project" */
export type IProjectAggregateFields = {
  __typename: 'project_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IProjectMaxFields>;
  min?: Maybe<IProjectMinFields>;
};


/** aggregate fields of "project" */
export type IProjectAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IProjectSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IProjectAppendInput = {
  icon?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "project". All fields are combined with a logical 'AND'. */
export type IProjectBoolExp = {
  _and?: InputMaybe<Array<IProjectBoolExp>>;
  _not?: InputMaybe<IProjectBoolExp>;
  _or?: InputMaybe<Array<IProjectBoolExp>>;
  icon?: InputMaybe<IJsonbComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  lead?: InputMaybe<IUserBoolExp>;
  lead_id?: InputMaybe<IUuidComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  status?: InputMaybe<IProjectStatusEnumComparisonExp>;
  teams?: InputMaybe<IProjectXTeamBoolExp>;
  teams_aggregate?: InputMaybe<IProjectXTeamAggregateBoolExp>;
};

/** unique or primary key constraints on table "project" */
export enum IProjectConstraint {
  /** unique or primary key constraint on columns "id" */
  ProjectPkey = 'project_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IProjectDeleteAtPathInput = {
  icon?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IProjectDeleteElemInput = {
  icon?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IProjectDeleteKeyInput = {
  icon?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "project" */
export type IProjectInsertInput = {
  icon?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  lead?: InputMaybe<IUserObjRelInsertInput>;
  lead_id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<IProjectStatusEnum>;
  teams?: InputMaybe<IProjectXTeamArrRelInsertInput>;
};

/** aggregate max on columns */
export type IProjectMaxFields = {
  __typename: 'project_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  lead_id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IProjectMinFields = {
  __typename: 'project_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  lead_id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "project" */
export type IProjectMutationResponse = {
  __typename: 'project_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IProject>;
};

/** input type for inserting object relation for remote table "project" */
export type IProjectObjRelInsertInput = {
  data: IProjectInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IProjectOnConflict>;
};

/** on_conflict condition type for table "project" */
export type IProjectOnConflict = {
  constraint: IProjectConstraint;
  update_columns?: Array<IProjectUpdateColumn>;
  where?: InputMaybe<IProjectBoolExp>;
};

/** Ordering options when selecting data from "project". */
export type IProjectOrderBy = {
  icon?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lead?: InputMaybe<IUserOrderBy>;
  lead_id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  status?: InputMaybe<IOrderBy>;
  teams_aggregate?: InputMaybe<IProjectXTeamAggregateOrderBy>;
};

/** primary key columns input for table: project */
export type IProjectPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IProjectPrependInput = {
  icon?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "project" */
export enum IProjectSelectColumn {
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  LeadId = 'lead_id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "project" */
export type IProjectSetInput = {
  icon?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  lead_id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<IProjectStatusEnum>;
};

/** columns and relationships of "project_status" */
export type IProjectStatus = {
  __typename: 'project_status';
  value: Scalars['String']['output'];
};

/** aggregated selection of "project_status" */
export type IProjectStatusAggregate = {
  __typename: 'project_status_aggregate';
  aggregate?: Maybe<IProjectStatusAggregateFields>;
  nodes: Array<IProjectStatus>;
};

/** aggregate fields of "project_status" */
export type IProjectStatusAggregateFields = {
  __typename: 'project_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IProjectStatusMaxFields>;
  min?: Maybe<IProjectStatusMinFields>;
};


/** aggregate fields of "project_status" */
export type IProjectStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IProjectStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "project_status". All fields are combined with a logical 'AND'. */
export type IProjectStatusBoolExp = {
  _and?: InputMaybe<Array<IProjectStatusBoolExp>>;
  _not?: InputMaybe<IProjectStatusBoolExp>;
  _or?: InputMaybe<Array<IProjectStatusBoolExp>>;
  value?: InputMaybe<IStringComparisonExp>;
};

/** unique or primary key constraints on table "project_status" */
export enum IProjectStatusConstraint {
  /** unique or primary key constraint on columns "value" */
  ProjectStatusPkey = 'project_status_pkey'
}

export enum IProjectStatusEnum {
  Active = 'active',
  Canceled = 'canceled',
  Complete = 'complete',
  Planned = 'planned'
}

/** Boolean expression to compare columns of type "project_status_enum". All fields are combined with logical 'AND'. */
export type IProjectStatusEnumComparisonExp = {
  _eq?: InputMaybe<IProjectStatusEnum>;
  _in?: InputMaybe<Array<IProjectStatusEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<IProjectStatusEnum>;
  _nin?: InputMaybe<Array<IProjectStatusEnum>>;
};

/** input type for inserting data into table "project_status" */
export type IProjectStatusInsertInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type IProjectStatusMaxFields = {
  __typename: 'project_status_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IProjectStatusMinFields = {
  __typename: 'project_status_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "project_status" */
export type IProjectStatusMutationResponse = {
  __typename: 'project_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IProjectStatus>;
};

/** on_conflict condition type for table "project_status" */
export type IProjectStatusOnConflict = {
  constraint: IProjectStatusConstraint;
  update_columns?: Array<IProjectStatusUpdateColumn>;
  where?: InputMaybe<IProjectStatusBoolExp>;
};

/** Ordering options when selecting data from "project_status". */
export type IProjectStatusOrderBy = {
  value?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: project_status */
export type IProjectStatusPkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "project_status" */
export enum IProjectStatusSelectColumn {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "project_status" */
export type IProjectStatusSetInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "project_status" */
export type IProjectStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IProjectStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IProjectStatusStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "project_status" */
export enum IProjectStatusUpdateColumn {
  /** column name */
  Value = 'value'
}

export type IProjectStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IProjectStatusSetInput>;
  /** filter the rows which have to be updated */
  where: IProjectStatusBoolExp;
};

/** Streaming cursor of the table "project" */
export type IProjectStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IProjectStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IProjectStreamCursorValueInput = {
  icon?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  lead_id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<IProjectStatusEnum>;
};

/** update columns of table "project" */
export enum IProjectUpdateColumn {
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  LeadId = 'lead_id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status'
}

export type IProjectUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IProjectAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IProjectDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IProjectDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IProjectDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IProjectPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IProjectSetInput>;
  /** filter the rows which have to be updated */
  where: IProjectBoolExp;
};

/** columns and relationships of "project_x_team" */
export type IProjectXTeam = {
  __typename: 'project_x_team';
  /** An object relationship */
  project: IProject;
  project_id: Scalars['uuid']['output'];
  /** An object relationship */
  team: ITeam;
  team_id: Scalars['uuid']['output'];
};

/** aggregated selection of "project_x_team" */
export type IProjectXTeamAggregate = {
  __typename: 'project_x_team_aggregate';
  aggregate?: Maybe<IProjectXTeamAggregateFields>;
  nodes: Array<IProjectXTeam>;
};

export type IProjectXTeamAggregateBoolExp = {
  count?: InputMaybe<IProjectXTeamAggregateBoolExpCount>;
};

export type IProjectXTeamAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IProjectXTeamSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IProjectXTeamBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "project_x_team" */
export type IProjectXTeamAggregateFields = {
  __typename: 'project_x_team_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IProjectXTeamMaxFields>;
  min?: Maybe<IProjectXTeamMinFields>;
};


/** aggregate fields of "project_x_team" */
export type IProjectXTeamAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IProjectXTeamSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "project_x_team" */
export type IProjectXTeamAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IProjectXTeamMaxOrderBy>;
  min?: InputMaybe<IProjectXTeamMinOrderBy>;
};

/** input type for inserting array relation for remote table "project_x_team" */
export type IProjectXTeamArrRelInsertInput = {
  data: Array<IProjectXTeamInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IProjectXTeamOnConflict>;
};

/** Boolean expression to filter rows from the table "project_x_team". All fields are combined with a logical 'AND'. */
export type IProjectXTeamBoolExp = {
  _and?: InputMaybe<Array<IProjectXTeamBoolExp>>;
  _not?: InputMaybe<IProjectXTeamBoolExp>;
  _or?: InputMaybe<Array<IProjectXTeamBoolExp>>;
  project?: InputMaybe<IProjectBoolExp>;
  project_id?: InputMaybe<IUuidComparisonExp>;
  team?: InputMaybe<ITeamBoolExp>;
  team_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "project_x_team" */
export enum IProjectXTeamConstraint {
  /** unique or primary key constraint on columns "project_id", "team_id" */
  ProjectXTeamPkey = 'project_x_team_pkey'
}

/** input type for inserting data into table "project_x_team" */
export type IProjectXTeamInsertInput = {
  project?: InputMaybe<IProjectObjRelInsertInput>;
  project_id?: InputMaybe<Scalars['uuid']['input']>;
  team?: InputMaybe<ITeamObjRelInsertInput>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IProjectXTeamMaxFields = {
  __typename: 'project_x_team_max_fields';
  project_id?: Maybe<Scalars['uuid']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "project_x_team" */
export type IProjectXTeamMaxOrderBy = {
  project_id?: InputMaybe<IOrderBy>;
  team_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IProjectXTeamMinFields = {
  __typename: 'project_x_team_min_fields';
  project_id?: Maybe<Scalars['uuid']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "project_x_team" */
export type IProjectXTeamMinOrderBy = {
  project_id?: InputMaybe<IOrderBy>;
  team_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "project_x_team" */
export type IProjectXTeamMutationResponse = {
  __typename: 'project_x_team_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IProjectXTeam>;
};

/** on_conflict condition type for table "project_x_team" */
export type IProjectXTeamOnConflict = {
  constraint: IProjectXTeamConstraint;
  update_columns?: Array<IProjectXTeamUpdateColumn>;
  where?: InputMaybe<IProjectXTeamBoolExp>;
};

/** Ordering options when selecting data from "project_x_team". */
export type IProjectXTeamOrderBy = {
  project?: InputMaybe<IProjectOrderBy>;
  project_id?: InputMaybe<IOrderBy>;
  team?: InputMaybe<ITeamOrderBy>;
  team_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: project_x_team */
export type IProjectXTeamPkColumnsInput = {
  project_id: Scalars['uuid']['input'];
  team_id: Scalars['uuid']['input'];
};

/** select columns of table "project_x_team" */
export enum IProjectXTeamSelectColumn {
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  TeamId = 'team_id'
}

/** input type for updating data in table "project_x_team" */
export type IProjectXTeamSetInput = {
  project_id?: InputMaybe<Scalars['uuid']['input']>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "project_x_team" */
export type IProjectXTeamStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IProjectXTeamStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IProjectXTeamStreamCursorValueInput = {
  project_id?: InputMaybe<Scalars['uuid']['input']>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "project_x_team" */
export enum IProjectXTeamUpdateColumn {
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  TeamId = 'team_id'
}

export type IProjectXTeamUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IProjectXTeamSetInput>;
  /** filter the rows which have to be updated */
  where: IProjectXTeamBoolExp;
};

export type IQuantityInPeriodConsumableArgs = {
  start_date?: InputMaybe<Scalars['date']['input']>;
  target_date?: InputMaybe<Scalars['date']['input']>;
};

export type IQuantityUntilConsumableArgs = {
  time_stamp?: InputMaybe<Scalars['timestamptz']['input']>;
};

export type IQueryRoot = {
  __typename: 'query_root';
  /** fetch data from the table: "analytics.event" */
  analytics_event: Array<IAnalyticsEvent>;
  /** fetch aggregated fields from the table: "analytics.event" */
  analytics_event_aggregate: IAnalyticsEventAggregate;
  /** fetch data from the table: "analytics.event" using primary key columns */
  analytics_event_by_pk?: Maybe<IAnalyticsEvent>;
  /** fetch data from the table: "api_access_log" */
  api_access_log: Array<IApiAccessLog>;
  /** fetch aggregated fields from the table: "api_access_log" */
  api_access_log_aggregate: IApiAccessLogAggregate;
  /** fetch data from the table: "api_access_log" using primary key columns */
  api_access_log_by_pk?: Maybe<IApiAccessLog>;
  askQuestion?: Maybe<IAskQuestionOutput>;
  /** fetch data from the table: "asset" */
  asset: Array<IAsset>;
  assetCustomFieldNames?: Maybe<IAssetCustomFieldNamesData>;
  /** fetch aggregated fields from the table: "asset" */
  asset_aggregate: IAssetAggregate;
  /** fetch data from the table: "asset" using primary key columns */
  asset_by_pk?: Maybe<IAsset>;
  /** fetch data from the table: "asset_current_state" */
  asset_current_state: Array<IAssetCurrentState>;
  /** fetch aggregated fields from the table: "asset_current_state" */
  asset_current_state_aggregate: IAssetCurrentStateAggregate;
  /** fetch data from the table: "asset_default_state" */
  asset_default_state: Array<IAssetDefaultState>;
  /** fetch aggregated fields from the table: "asset_default_state" */
  asset_default_state_aggregate: IAssetDefaultStateAggregate;
  /** fetch data from the table: "asset_default_state" using primary key columns */
  asset_default_state_by_pk?: Maybe<IAssetDefaultState>;
  /** fetch data from the table: "asset_group" */
  asset_group: Array<IAssetGroup>;
  /** fetch aggregated fields from the table: "asset_group" */
  asset_group_aggregate: IAssetGroupAggregate;
  /** fetch data from the table: "asset_group" using primary key columns */
  asset_group_by_pk?: Maybe<IAssetGroup>;
  /** fetch data from the table: "asset_last_state_view" */
  asset_last_state_view: Array<IAssetLastStateView>;
  /** fetch aggregated fields from the table: "asset_last_state_view" */
  asset_last_state_view_aggregate: IAssetLastStateViewAggregate;
  /** fetch data from the table: "asset_manufacturer" */
  asset_manufacturer: Array<IAssetManufacturer>;
  /** fetch aggregated fields from the table: "asset_manufacturer" */
  asset_manufacturer_aggregate: IAssetManufacturerAggregate;
  /** fetch data from the table: "asset_manufacturer" using primary key columns */
  asset_manufacturer_by_pk?: Maybe<IAssetManufacturer>;
  /** fetch data from the table: "asset_operating_schedule" */
  asset_operating_schedule: Array<IAssetOperatingSchedule>;
  /** fetch aggregated fields from the table: "asset_operating_schedule" */
  asset_operating_schedule_aggregate: IAssetOperatingScheduleAggregate;
  /** fetch data from the table: "asset_operating_schedule" using primary key columns */
  asset_operating_schedule_by_pk?: Maybe<IAssetOperatingSchedule>;
  /** fetch data from the table: "asset_state" */
  asset_state: Array<IAssetState>;
  /** fetch aggregated fields from the table: "asset_state" */
  asset_state_aggregate: IAssetStateAggregate;
  /** fetch data from the table: "asset_state" using primary key columns */
  asset_state_by_pk?: Maybe<IAssetState>;
  /** fetch data from the table: "asset_state_log" */
  asset_state_log: Array<IAssetStateLog>;
  /** fetch aggregated fields from the table: "asset_state_log" */
  asset_state_log_aggregate: IAssetStateLogAggregate;
  /** fetch data from the table: "asset_state_log" using primary key columns */
  asset_state_log_by_pk?: Maybe<IAssetStateLog>;
  /** fetch data from the table: "asset_state_variant" */
  asset_state_variant: Array<IAssetStateVariant>;
  /** fetch aggregated fields from the table: "asset_state_variant" */
  asset_state_variant_aggregate: IAssetStateVariantAggregate;
  /** fetch data from the table: "asset_state_variant" using primary key columns */
  asset_state_variant_by_pk?: Maybe<IAssetStateVariant>;
  /** fetch data from the table: "asset_state_variant_type" */
  asset_state_variant_type: Array<IAssetStateVariantType>;
  /** fetch aggregated fields from the table: "asset_state_variant_type" */
  asset_state_variant_type_aggregate: IAssetStateVariantTypeAggregate;
  /** fetch data from the table: "asset_state_variant_type" using primary key columns */
  asset_state_variant_type_by_pk?: Maybe<IAssetStateVariantType>;
  /** fetch data from the table: "asset_x_asset_state" */
  asset_x_asset_state: Array<IAssetXAssetState>;
  /** fetch aggregated fields from the table: "asset_x_asset_state" */
  asset_x_asset_state_aggregate: IAssetXAssetStateAggregate;
  /** fetch data from the table: "asset_x_asset_state" using primary key columns */
  asset_x_asset_state_by_pk?: Maybe<IAssetXAssetState>;
  /** fetch data from the table: "asset_x_asset_state_duration" */
  asset_x_asset_state_duration: Array<IAssetXAssetStateDuration>;
  /** fetch aggregated fields from the table: "asset_x_asset_state_duration" */
  asset_x_asset_state_duration_aggregate: IAssetXAssetStateDurationAggregate;
  /** fetch data from the table: "asset_x_consumable" */
  asset_x_consumable: Array<IAssetXConsumable>;
  /** fetch aggregated fields from the table: "asset_x_consumable" */
  asset_x_consumable_aggregate: IAssetXConsumableAggregate;
  /** fetch data from the table: "asset_x_consumable" using primary key columns */
  asset_x_consumable_by_pk?: Maybe<IAssetXConsumable>;
  /** fetch data from the table: "asset_x_upload" */
  asset_x_upload: Array<IAssetXUpload>;
  /** fetch aggregated fields from the table: "asset_x_upload" */
  asset_x_upload_aggregate: IAssetXUploadAggregate;
  /** fetch data from the table: "asset_x_upload" using primary key columns */
  asset_x_upload_by_pk?: Maybe<IAssetXUpload>;
  /** fetch data from the table: "asset_x_work_order" */
  asset_x_work_order: Array<IAssetXWorkOrder>;
  /** fetch aggregated fields from the table: "asset_x_work_order" */
  asset_x_work_order_aggregate: IAssetXWorkOrderAggregate;
  /** fetch data from the table: "asset_x_work_order" using primary key columns */
  asset_x_work_order_by_pk?: Maybe<IAssetXWorkOrder>;
  /** fetch data from the table: "block.element" */
  block_element: Array<IBlockElement>;
  /** fetch aggregated fields from the table: "block.element" */
  block_element_aggregate: IBlockElementAggregate;
  /** fetch data from the table: "block.element" using primary key columns */
  block_element_by_pk?: Maybe<IBlockElement>;
  /** fetch data from the table: "block.element_type" */
  block_element_type: Array<IBlockElementType>;
  /** fetch aggregated fields from the table: "block.element_type" */
  block_element_type_aggregate: IBlockElementTypeAggregate;
  /** fetch data from the table: "block.element_type" using primary key columns */
  block_element_type_by_pk?: Maybe<IBlockElementType>;
  /** fetch data from the table: "block.element_x_upload" */
  block_element_x_upload: Array<IBlockElementXUpload>;
  /** fetch aggregated fields from the table: "block.element_x_upload" */
  block_element_x_upload_aggregate: IBlockElementXUploadAggregate;
  /** fetch data from the table: "block.element_x_upload" using primary key columns */
  block_element_x_upload_by_pk?: Maybe<IBlockElementXUpload>;
  /** fetch data from the table: "block.group" */
  block_group: Array<IBlockGroup>;
  /** fetch aggregated fields from the table: "block.group" */
  block_group_aggregate: IBlockGroupAggregate;
  /** fetch data from the table: "block.group" using primary key columns */
  block_group_by_pk?: Maybe<IBlockGroup>;
  /** fetch data from the table: "block.group_type" */
  block_group_type: Array<IBlockGroupType>;
  /** fetch aggregated fields from the table: "block.group_type" */
  block_group_type_aggregate: IBlockGroupTypeAggregate;
  /** fetch data from the table: "block.group_type" using primary key columns */
  block_group_type_by_pk?: Maybe<IBlockGroupType>;
  /** fetch data from the table: "comment" */
  comment: Array<IComment>;
  /** fetch aggregated fields from the table: "comment" */
  comment_aggregate: ICommentAggregate;
  /** fetch data from the table: "comment" using primary key columns */
  comment_by_pk?: Maybe<IComment>;
  /** An array relationship */
  comment_x_upload: Array<ICommentXUpload>;
  /** An aggregate relationship */
  comment_x_upload_aggregate: ICommentXUploadAggregate;
  /** fetch data from the table: "comment_x_upload" using primary key columns */
  comment_x_upload_by_pk?: Maybe<ICommentXUpload>;
  /** fetch data from the table: "consumable" */
  consumable: Array<IConsumable>;
  /** fetch aggregated fields from the table: "consumable" */
  consumable_aggregate: IConsumableAggregate;
  /** fetch data from the table: "consumable" using primary key columns */
  consumable_by_pk?: Maybe<IConsumable>;
  /** fetch data from the table: "consumable_config" */
  consumable_config: Array<IConsumableConfig>;
  /** fetch aggregated fields from the table: "consumable_config" */
  consumable_config_aggregate: IConsumableConfigAggregate;
  /** fetch data from the table: "consumable_config" using primary key columns */
  consumable_config_by_pk?: Maybe<IConsumableConfig>;
  /** fetch data from the table: "consumable_group" */
  consumable_group: Array<IConsumableGroup>;
  /** fetch aggregated fields from the table: "consumable_group" */
  consumable_group_aggregate: IConsumableGroupAggregate;
  /** fetch data from the table: "consumable_group" using primary key columns */
  consumable_group_by_pk?: Maybe<IConsumableGroup>;
  /** fetch data from the table: "consumable_log" */
  consumable_log: Array<IConsumableLog>;
  /** fetch aggregated fields from the table: "consumable_log" */
  consumable_log_aggregate: IConsumableLogAggregate;
  /** fetch data from the table: "consumable_log" using primary key columns */
  consumable_log_by_pk?: Maybe<IConsumableLog>;
  /** fetch data from the table: "consumable_storage_location" */
  consumable_storage_location: Array<IConsumableStorageLocation>;
  /** fetch aggregated fields from the table: "consumable_storage_location" */
  consumable_storage_location_aggregate: IConsumableStorageLocationAggregate;
  /** fetch data from the table: "consumable_storage_location" using primary key columns */
  consumable_storage_location_by_pk?: Maybe<IConsumableStorageLocation>;
  /** fetch data from the table: "consumable_x_upload" */
  consumable_x_upload: Array<IConsumableXUpload>;
  /** fetch aggregated fields from the table: "consumable_x_upload" */
  consumable_x_upload_aggregate: IConsumableXUploadAggregate;
  /** fetch data from the table: "consumable_x_upload" using primary key columns */
  consumable_x_upload_by_pk?: Maybe<IConsumableXUpload>;
  /** fetch data from the table: "contact" */
  contact: Array<IContact>;
  /** fetch aggregated fields from the table: "contact" */
  contact_aggregate: IContactAggregate;
  /** fetch data from the table: "contact" using primary key columns */
  contact_by_pk?: Maybe<IContact>;
  /** fetch data from the table: "custom_migration" */
  custom_migration: Array<ICustomMigration>;
  /** fetch aggregated fields from the table: "custom_migration" */
  custom_migration_aggregate: ICustomMigrationAggregate;
  /** fetch data from the table: "custom_migration" using primary key columns */
  custom_migration_by_pk?: Maybe<ICustomMigration>;
  /** fetch data from the table: "custom_view" */
  custom_view: Array<ICustomView>;
  /** fetch aggregated fields from the table: "custom_view" */
  custom_view_aggregate: ICustomViewAggregate;
  /** fetch data from the table: "custom_view" using primary key columns */
  custom_view_by_pk?: Maybe<ICustomView>;
  /** fetch data from the table: "custom_view_user_favorite" */
  custom_view_user_favorite: Array<ICustomViewUserFavorite>;
  /** fetch aggregated fields from the table: "custom_view_user_favorite" */
  custom_view_user_favorite_aggregate: ICustomViewUserFavoriteAggregate;
  /** fetch data from the table: "custom_view_user_favorite" using primary key columns */
  custom_view_user_favorite_by_pk?: Maybe<ICustomViewUserFavorite>;
  /** fetch data from the table: "customer_feedback" */
  customer_feedback: Array<ICustomerFeedback>;
  /** fetch aggregated fields from the table: "customer_feedback" */
  customer_feedback_aggregate: ICustomerFeedbackAggregate;
  /** fetch data from the table: "customer_feedback" using primary key columns */
  customer_feedback_by_pk?: Maybe<ICustomerFeedback>;
  /** fetch data from the table: "feature_flag" */
  feature_flag: Array<IFeatureFlag>;
  /** fetch aggregated fields from the table: "feature_flag" */
  feature_flag_aggregate: IFeatureFlagAggregate;
  /** fetch data from the table: "feature_flag" using primary key columns */
  feature_flag_by_pk?: Maybe<IFeatureFlag>;
  /** fetch data from the table: "file_folder" */
  file_folder: Array<IFileFolder>;
  /** fetch aggregated fields from the table: "file_folder" */
  file_folder_aggregate: IFileFolderAggregate;
  /** fetch data from the table: "file_folder" using primary key columns */
  file_folder_by_pk?: Maybe<IFileFolder>;
  /** fetch data from the table: "file_folder_x_upload" */
  file_folder_x_upload: Array<IFileFolderXUpload>;
  /** fetch aggregated fields from the table: "file_folder_x_upload" */
  file_folder_x_upload_aggregate: IFileFolderXUploadAggregate;
  /** fetch data from the table: "file_folder_x_upload" using primary key columns */
  file_folder_x_upload_by_pk?: Maybe<IFileFolderXUpload>;
  /** fetch data from the table: "inspection_result" */
  inspection_result: Array<IInspectionResult>;
  /** fetch aggregated fields from the table: "inspection_result" */
  inspection_result_aggregate: IInspectionResultAggregate;
  /** fetch data from the table: "inspection_result" using primary key columns */
  inspection_result_by_pk?: Maybe<IInspectionResult>;
  /** fetch data from the table: "jobs.cron_job_completed" */
  jobs_cron_job_completed: Array<IJobsCronJobCompleted>;
  /** fetch aggregated fields from the table: "jobs.cron_job_completed" */
  jobs_cron_job_completed_aggregate: IJobsCronJobCompletedAggregate;
  /** fetch data from the table: "jobs.cron_job_completed" using primary key columns */
  jobs_cron_job_completed_by_pk?: Maybe<IJobsCronJobCompleted>;
  /** fetch data from the table: "location" */
  location: Array<ILocation>;
  /** fetch data from the table: "location_admin_view" */
  location_admin_view: Array<ILocationAdminView>;
  /** fetch aggregated fields from the table: "location_admin_view" */
  location_admin_view_aggregate: ILocationAdminViewAggregate;
  /** fetch aggregated fields from the table: "location" */
  location_aggregate: ILocationAggregate;
  /** fetch data from the table: "location_api_key" */
  location_api_key: Array<ILocationApiKey>;
  /** fetch aggregated fields from the table: "location_api_key" */
  location_api_key_aggregate: ILocationApiKeyAggregate;
  /** fetch data from the table: "location_api_key" using primary key columns */
  location_api_key_by_pk?: Maybe<ILocationApiKey>;
  /** fetch data from the table: "location" using primary key columns */
  location_by_pk?: Maybe<ILocation>;
  /** fetch data from the table: "location_invite" */
  location_invite: Array<ILocationInvite>;
  /** fetch aggregated fields from the table: "location_invite" */
  location_invite_aggregate: ILocationInviteAggregate;
  /** fetch data from the table: "location_invite" using primary key columns */
  location_invite_by_pk?: Maybe<ILocationInvite>;
  /** fetch data from the table: "location_member" */
  location_member: Array<ILocationMember>;
  /** fetch aggregated fields from the table: "location_member" */
  location_member_aggregate: ILocationMemberAggregate;
  /** fetch data from the table: "location_member" using primary key columns */
  location_member_by_pk?: Maybe<ILocationMember>;
  /** fetch data from the table: "location_member_role" */
  location_member_role: Array<ILocationMemberRole>;
  /** fetch aggregated fields from the table: "location_member_role" */
  location_member_role_aggregate: ILocationMemberRoleAggregate;
  /** fetch data from the table: "location_member_role" using primary key columns */
  location_member_role_by_pk?: Maybe<ILocationMemberRole>;
  /** fetch data from the table: "location_x_feature_flag" */
  location_x_feature_flag: Array<ILocationXFeatureFlag>;
  /** fetch aggregated fields from the table: "location_x_feature_flag" */
  location_x_feature_flag_aggregate: ILocationXFeatureFlagAggregate;
  /** fetch data from the table: "location_x_feature_flag" using primary key columns */
  location_x_feature_flag_by_pk?: Maybe<ILocationXFeatureFlag>;
  /** fetch data from the table: "maintenance" */
  maintenance: Array<IMaintenance>;
  /** fetch aggregated fields from the table: "maintenance" */
  maintenance_aggregate: IMaintenanceAggregate;
  /** fetch data from the table: "maintenance" using primary key columns */
  maintenance_by_pk?: Maybe<IMaintenance>;
  /** fetch data from the table: "maintenance_trigger" */
  maintenance_trigger: Array<IMaintenanceTrigger>;
  /** fetch aggregated fields from the table: "maintenance_trigger" */
  maintenance_trigger_aggregate: IMaintenanceTriggerAggregate;
  /** fetch data from the table: "maintenance_trigger" using primary key columns */
  maintenance_trigger_by_pk?: Maybe<IMaintenanceTrigger>;
  /** fetch data from the table: "maintenance_trigger_task" */
  maintenance_trigger_task: Array<IMaintenanceTriggerTask>;
  /** fetch aggregated fields from the table: "maintenance_trigger_task" */
  maintenance_trigger_task_aggregate: IMaintenanceTriggerTaskAggregate;
  /** fetch data from the table: "maintenance_trigger_task" using primary key columns */
  maintenance_trigger_task_by_pk?: Maybe<IMaintenanceTriggerTask>;
  /** fetch data from the table: "maintenance_trigger_type" */
  maintenance_trigger_type: Array<IMaintenanceTriggerType>;
  /** fetch aggregated fields from the table: "maintenance_trigger_type" */
  maintenance_trigger_type_aggregate: IMaintenanceTriggerTypeAggregate;
  /** fetch data from the table: "maintenance_trigger_type" using primary key columns */
  maintenance_trigger_type_by_pk?: Maybe<IMaintenanceTriggerType>;
  /** fetch data from the table: "meter" */
  meter: Array<IMeter>;
  /** fetch aggregated fields from the table: "meter" */
  meter_aggregate: IMeterAggregate;
  /** fetch data from the table: "meter" using primary key columns */
  meter_by_pk?: Maybe<IMeter>;
  /** fetch data from the table: "meter_reading" */
  meter_reading: Array<IMeterReading>;
  /** fetch aggregated fields from the table: "meter_reading" */
  meter_reading_aggregate: IMeterReadingAggregate;
  /** fetch data from the table: "meter_reading" using primary key columns */
  meter_reading_by_pk?: Maybe<IMeterReading>;
  /** fetch data from the table: "meter_type" */
  meter_type: Array<IMeterType>;
  /** fetch aggregated fields from the table: "meter_type" */
  meter_type_aggregate: IMeterTypeAggregate;
  /** fetch data from the table: "meter_type" using primary key columns */
  meter_type_by_pk?: Maybe<IMeterType>;
  /** fetch data from the table: "notification" */
  notification: Array<INotification>;
  /** fetch aggregated fields from the table: "notification" */
  notification_aggregate: INotificationAggregate;
  /** fetch data from the table: "notification" using primary key columns */
  notification_by_pk?: Maybe<INotification>;
  /** fetch data from the table: "notification_type" */
  notification_type: Array<INotificationType>;
  /** fetch aggregated fields from the table: "notification_type" */
  notification_type_aggregate: INotificationTypeAggregate;
  /** fetch data from the table: "notification_type" using primary key columns */
  notification_type_by_pk?: Maybe<INotificationType>;
  /** fetch data from the table: "org" */
  org: Array<IOrg>;
  /** fetch aggregated fields from the table: "org" */
  org_aggregate: IOrgAggregate;
  /** fetch data from the table: "org" using primary key columns */
  org_by_pk?: Maybe<IOrg>;
  /** fetch data from the table: "org_feature" */
  org_feature: Array<IOrgFeature>;
  /** fetch aggregated fields from the table: "org_feature" */
  org_feature_aggregate: IOrgFeatureAggregate;
  /** fetch data from the table: "org_feature" using primary key columns */
  org_feature_by_pk?: Maybe<IOrgFeature>;
  /** fetch data from the table: "org_member" */
  org_member: Array<IOrgMember>;
  /** fetch aggregated fields from the table: "org_member" */
  org_member_aggregate: IOrgMemberAggregate;
  /** fetch data from the table: "org_prefs" */
  org_prefs: Array<IOrgPrefs>;
  /** fetch aggregated fields from the table: "org_prefs" */
  org_prefs_aggregate: IOrgPrefsAggregate;
  /** fetch data from the table: "org_prefs" using primary key columns */
  org_prefs_by_pk?: Maybe<IOrgPrefs>;
  /** An array relationship */
  org_sso_settings: Array<IOrgSsoSettings>;
  /** An aggregate relationship */
  org_sso_settings_aggregate: IOrgSsoSettingsAggregate;
  /** fetch data from the table: "org_sso_settings" using primary key columns */
  org_sso_settings_by_pk?: Maybe<IOrgSsoSettings>;
  /** execute function "overdue_work_orders_without_notification" which returns "work_order_x_user" */
  overdue_work_orders_without_notification: Array<IWorkOrderXUser>;
  /** execute function "overdue_work_orders_without_notification" and query aggregates on result of table type "work_order_x_user" */
  overdue_work_orders_without_notification_aggregate: IWorkOrderXUserAggregate;
  /** fetch data from the table: "permission_role" */
  permission_role: Array<IPermissionRole>;
  /** fetch aggregated fields from the table: "permission_role" */
  permission_role_aggregate: IPermissionRoleAggregate;
  /** fetch data from the table: "permission_role" using primary key columns */
  permission_role_by_pk?: Maybe<IPermissionRole>;
  /** fetch data from the table: "permission_role_x_feature_flag" */
  permission_role_x_feature_flag: Array<IPermissionRoleXFeatureFlag>;
  /** fetch aggregated fields from the table: "permission_role_x_feature_flag" */
  permission_role_x_feature_flag_aggregate: IPermissionRoleXFeatureFlagAggregate;
  /** fetch data from the table: "permission_role_x_feature_flag" using primary key columns */
  permission_role_x_feature_flag_by_pk?: Maybe<IPermissionRoleXFeatureFlag>;
  /** fetch data from the table: "permission_role_x_permission_scope" */
  permission_role_x_permission_scope: Array<IPermissionRoleXPermissionScope>;
  /** fetch aggregated fields from the table: "permission_role_x_permission_scope" */
  permission_role_x_permission_scope_aggregate: IPermissionRoleXPermissionScopeAggregate;
  /** fetch data from the table: "permission_role_x_permission_scope" using primary key columns */
  permission_role_x_permission_scope_by_pk?: Maybe<IPermissionRoleXPermissionScope>;
  /** fetch data from the table: "permission_scope" */
  permission_scope: Array<IPermissionScope>;
  /** fetch aggregated fields from the table: "permission_scope" */
  permission_scope_aggregate: IPermissionScopeAggregate;
  /** fetch data from the table: "permission_scope" using primary key columns */
  permission_scope_by_pk?: Maybe<IPermissionScope>;
  /** fetch data from the table: "place" */
  place: Array<IPlace>;
  /** fetch aggregated fields from the table: "place" */
  place_aggregate: IPlaceAggregate;
  /** fetch data from the table: "place" using primary key columns */
  place_by_pk?: Maybe<IPlace>;
  /** fetch data from the table: "place_x_upload" */
  place_x_upload: Array<IPlaceXUpload>;
  /** fetch aggregated fields from the table: "place_x_upload" */
  place_x_upload_aggregate: IPlaceXUploadAggregate;
  /** fetch data from the table: "place_x_upload" using primary key columns */
  place_x_upload_by_pk?: Maybe<IPlaceXUpload>;
  /** fetch data from the table: "project" */
  project: Array<IProject>;
  /** fetch aggregated fields from the table: "project" */
  project_aggregate: IProjectAggregate;
  /** fetch data from the table: "project" using primary key columns */
  project_by_pk?: Maybe<IProject>;
  /** fetch data from the table: "project_status" */
  project_status: Array<IProjectStatus>;
  /** fetch aggregated fields from the table: "project_status" */
  project_status_aggregate: IProjectStatusAggregate;
  /** fetch data from the table: "project_status" using primary key columns */
  project_status_by_pk?: Maybe<IProjectStatus>;
  /** fetch data from the table: "project_x_team" */
  project_x_team: Array<IProjectXTeam>;
  /** fetch aggregated fields from the table: "project_x_team" */
  project_x_team_aggregate: IProjectXTeamAggregate;
  /** fetch data from the table: "project_x_team" using primary key columns */
  project_x_team_by_pk?: Maybe<IProjectXTeam>;
  /** fetch data from the table: "recurrence" */
  recurrence: Array<IRecurrence>;
  /** fetch aggregated fields from the table: "recurrence" */
  recurrence_aggregate: IRecurrenceAggregate;
  /** fetch data from the table: "recurrence" using primary key columns */
  recurrence_by_pk?: Maybe<IRecurrence>;
  /** fetch data from the table: "recurrence_pattern" */
  recurrence_pattern: Array<IRecurrencePattern>;
  /** fetch aggregated fields from the table: "recurrence_pattern" */
  recurrence_pattern_aggregate: IRecurrencePatternAggregate;
  /** fetch data from the table: "recurrence_pattern" using primary key columns */
  recurrence_pattern_by_pk?: Maybe<IRecurrencePattern>;
  /** fetch data from the table: "scheduled_task" */
  scheduled_task: Array<IScheduledTask>;
  /** fetch aggregated fields from the table: "scheduled_task" */
  scheduled_task_aggregate: IScheduledTaskAggregate;
  /** fetch data from the table: "scheduled_task" using primary key columns */
  scheduled_task_by_pk?: Maybe<IScheduledTask>;
  /** execute function "search_asset" which returns "asset" */
  search_asset: Array<IAsset>;
  /** execute function "search_asset" and query aggregates on result of table type "asset" */
  search_asset_aggregate: IAssetAggregate;
  /** execute function "search_maintenance" which returns "maintenance" */
  search_maintenance: Array<IMaintenance>;
  /** execute function "search_maintenance" and query aggregates on result of table type "maintenance" */
  search_maintenance_aggregate: IMaintenanceAggregate;
  /** execute function "search_work_order" which returns "work_order" */
  search_work_order: Array<IWorkOrder>;
  /** execute function "search_work_order" and query aggregates on result of table type "work_order" */
  search_work_order_aggregate: IWorkOrderAggregate;
  /** fetch data from the table: "service_request" */
  service_request: Array<IServiceRequest>;
  /** fetch aggregated fields from the table: "service_request" */
  service_request_aggregate: IServiceRequestAggregate;
  /** fetch data from the table: "service_request" using primary key columns */
  service_request_by_pk?: Maybe<IServiceRequest>;
  /** fetch data from the table: "service_request_status" */
  service_request_status: Array<IServiceRequestStatus>;
  /** fetch aggregated fields from the table: "service_request_status" */
  service_request_status_aggregate: IServiceRequestStatusAggregate;
  /** fetch data from the table: "service_request_status" using primary key columns */
  service_request_status_by_pk?: Maybe<IServiceRequestStatus>;
  /** fetch data from the table: "service_request_x_asset" */
  service_request_x_asset: Array<IServiceRequestXAsset>;
  /** fetch aggregated fields from the table: "service_request_x_asset" */
  service_request_x_asset_aggregate: IServiceRequestXAssetAggregate;
  /** fetch data from the table: "service_request_x_asset" using primary key columns */
  service_request_x_asset_by_pk?: Maybe<IServiceRequestXAsset>;
  /** fetch data from the table: "service_request_x_team" */
  service_request_x_team: Array<IServiceRequestXTeam>;
  /** fetch aggregated fields from the table: "service_request_x_team" */
  service_request_x_team_aggregate: IServiceRequestXTeamAggregate;
  /** fetch data from the table: "service_request_x_team" using primary key columns */
  service_request_x_team_by_pk?: Maybe<IServiceRequestXTeam>;
  /** fetch data from the table: "service_request_x_upload" */
  service_request_x_upload: Array<IServiceRequestXUpload>;
  /** fetch aggregated fields from the table: "service_request_x_upload" */
  service_request_x_upload_aggregate: IServiceRequestXUploadAggregate;
  /** fetch data from the table: "service_request_x_upload" using primary key columns */
  service_request_x_upload_by_pk?: Maybe<IServiceRequestXUpload>;
  /** fetch data from the table: "service_request_x_user" */
  service_request_x_user: Array<IServiceRequestXUser>;
  /** fetch aggregated fields from the table: "service_request_x_user" */
  service_request_x_user_aggregate: IServiceRequestXUserAggregate;
  /** fetch data from the table: "service_request_x_user" using primary key columns */
  service_request_x_user_by_pk?: Maybe<IServiceRequestXUser>;
  /** fetch data from the table: "setting.config" */
  setting_config: Array<ISettingConfig>;
  /** fetch aggregated fields from the table: "setting.config" */
  setting_config_aggregate: ISettingConfigAggregate;
  /** fetch data from the table: "setting.config" using primary key columns */
  setting_config_by_pk?: Maybe<ISettingConfig>;
  /** fetch data from the table: "setting.level" */
  setting_level: Array<ISettingLevel>;
  /** fetch aggregated fields from the table: "setting.level" */
  setting_level_aggregate: ISettingLevelAggregate;
  /** fetch data from the table: "setting.level" using primary key columns */
  setting_level_by_pk?: Maybe<ISettingLevel>;
  /** fetch data from the table: "setting.type" */
  setting_type: Array<ISettingType>;
  /** fetch aggregated fields from the table: "setting.type" */
  setting_type_aggregate: ISettingTypeAggregate;
  /** fetch data from the table: "setting.type" using primary key columns */
  setting_type_by_pk?: Maybe<ISettingType>;
  /** fetch data from the table: "team" */
  team: Array<ITeam>;
  /** fetch aggregated fields from the table: "team" */
  team_aggregate: ITeamAggregate;
  /** fetch data from the table: "team" using primary key columns */
  team_by_pk?: Maybe<ITeam>;
  /** fetch data from the table: "team_member" */
  team_member: Array<ITeamMember>;
  /** fetch aggregated fields from the table: "team_member" */
  team_member_aggregate: ITeamMemberAggregate;
  /** fetch data from the table: "team_member" using primary key columns */
  team_member_by_pk?: Maybe<ITeamMember>;
  /** fetch data from the table: "team_user_role" */
  team_user_role: Array<ITeamUserRole>;
  /** fetch aggregated fields from the table: "team_user_role" */
  team_user_role_aggregate: ITeamUserRoleAggregate;
  /** fetch data from the table: "team_user_role" using primary key columns */
  team_user_role_by_pk?: Maybe<ITeamUserRole>;
  /** fetch data from the table: "upload" */
  upload: Array<IUpload>;
  /** fetch aggregated fields from the table: "upload" */
  upload_aggregate: IUploadAggregate;
  /** fetch data from the table: "upload" using primary key columns */
  upload_by_pk?: Maybe<IUpload>;
  /** fetch data from the table: "upload_category" */
  upload_category: Array<IUploadCategory>;
  /** fetch aggregated fields from the table: "upload_category" */
  upload_category_aggregate: IUploadCategoryAggregate;
  /** fetch data from the table: "upload_category" using primary key columns */
  upload_category_by_pk?: Maybe<IUploadCategory>;
  /** fetch data from the table: "upload_x_upload_category" */
  upload_x_upload_category: Array<IUploadXUploadCategory>;
  /** fetch aggregated fields from the table: "upload_x_upload_category" */
  upload_x_upload_category_aggregate: IUploadXUploadCategoryAggregate;
  /** fetch data from the table: "upload_x_upload_category" using primary key columns */
  upload_x_upload_category_by_pk?: Maybe<IUploadXUploadCategory>;
  /** An array relationship */
  user: Array<IUser>;
  /** An aggregate relationship */
  user_aggregate: IUserAggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<IUser>;
  /** fetch data from the table: "user_favorite" */
  user_favorite: Array<IUserFavorite>;
  /** fetch aggregated fields from the table: "user_favorite" */
  user_favorite_aggregate: IUserFavoriteAggregate;
  /** fetch data from the table: "user_favorite" using primary key columns */
  user_favorite_by_pk?: Maybe<IUserFavorite>;
  /** fetch data from the table: "user_recently_used" */
  user_recently_used: Array<IUserRecentlyUsed>;
  /** fetch aggregated fields from the table: "user_recently_used" */
  user_recently_used_aggregate: IUserRecentlyUsedAggregate;
  /** fetch data from the table: "user_recently_used" using primary key columns */
  user_recently_used_by_pk?: Maybe<IUserRecentlyUsed>;
  /** fetch data from the table: "view_collection" */
  view_collection: Array<IViewCollection>;
  /** fetch aggregated fields from the table: "view_collection" */
  view_collection_aggregate: IViewCollectionAggregate;
  /** fetch data from the table: "view_collection" using primary key columns */
  view_collection_by_pk?: Maybe<IViewCollection>;
  /** fetch data from the table: "view_config" */
  view_config: Array<IViewConfig>;
  /** fetch aggregated fields from the table: "view_config" */
  view_config_aggregate: IViewConfigAggregate;
  /** fetch data from the table: "view_config" using primary key columns */
  view_config_by_pk?: Maybe<IViewConfig>;
  /** fetch data from the table: "view_data_type" */
  view_data_type: Array<IViewDataType>;
  /** fetch aggregated fields from the table: "view_data_type" */
  view_data_type_aggregate: IViewDataTypeAggregate;
  /** fetch data from the table: "view_data_type" using primary key columns */
  view_data_type_by_pk?: Maybe<IViewDataType>;
  /** fetch data from the table: "work_order" */
  work_order: Array<IWorkOrder>;
  /** fetch data from the table: "work_order_activity" */
  work_order_activity: Array<IWorkOrderActivity>;
  /** fetch aggregated fields from the table: "work_order_activity" */
  work_order_activity_aggregate: IWorkOrderActivityAggregate;
  /** fetch data from the table: "work_order_activity" using primary key columns */
  work_order_activity_by_pk?: Maybe<IWorkOrderActivity>;
  /** fetch data from the table: "work_order_activity_type" */
  work_order_activity_type: Array<IWorkOrderActivityType>;
  /** fetch aggregated fields from the table: "work_order_activity_type" */
  work_order_activity_type_aggregate: IWorkOrderActivityTypeAggregate;
  /** fetch data from the table: "work_order_activity_type" using primary key columns */
  work_order_activity_type_by_pk?: Maybe<IWorkOrderActivityType>;
  /** fetch aggregated fields from the table: "work_order" */
  work_order_aggregate: IWorkOrderAggregate;
  /** fetch data from the table: "work_order" using primary key columns */
  work_order_by_pk?: Maybe<IWorkOrder>;
  /** fetch data from the table: "work_order_category" */
  work_order_category: Array<IWorkOrderCategory>;
  /** fetch aggregated fields from the table: "work_order_category" */
  work_order_category_aggregate: IWorkOrderCategoryAggregate;
  /** fetch data from the table: "work_order_category" using primary key columns */
  work_order_category_by_pk?: Maybe<IWorkOrderCategory>;
  /** fetch data from the table: "work_order_completed_by_category_view" */
  work_order_completed_by_category_view: Array<IWorkOrderCompletedByCategoryView>;
  /** fetch aggregated fields from the table: "work_order_completed_by_category_view" */
  work_order_completed_by_category_view_aggregate: IWorkOrderCompletedByCategoryViewAggregate;
  /** fetch data from the table: "work_order_completed_ontime_vs_overdue" */
  work_order_completed_ontime_vs_overdue: Array<IWorkOrderCompletedOntimeVsOverdue>;
  /** fetch aggregated fields from the table: "work_order_completed_ontime_vs_overdue" */
  work_order_completed_ontime_vs_overdue_aggregate: IWorkOrderCompletedOntimeVsOverdueAggregate;
  /** fetch data from the table: "work_order_expense" */
  work_order_expense: Array<IWorkOrderExpense>;
  /** fetch aggregated fields from the table: "work_order_expense" */
  work_order_expense_aggregate: IWorkOrderExpenseAggregate;
  /** fetch data from the table: "work_order_expense" using primary key columns */
  work_order_expense_by_pk?: Maybe<IWorkOrderExpense>;
  /** fetch data from the table: "work_order_feedback" */
  work_order_feedback: Array<IWorkOrderFeedback>;
  /** fetch aggregated fields from the table: "work_order_feedback" */
  work_order_feedback_aggregate: IWorkOrderFeedbackAggregate;
  /** fetch data from the table: "work_order_feedback" using primary key columns */
  work_order_feedback_by_pk?: Maybe<IWorkOrderFeedback>;
  /** fetch data from the table: "work_order_last_update" */
  work_order_last_update: Array<IWorkOrderLastUpdate>;
  /** fetch aggregated fields from the table: "work_order_last_update" */
  work_order_last_update_aggregate: IWorkOrderLastUpdateAggregate;
  /** fetch data from the table: "work_order_open_grouped_by_due_date_view" */
  work_order_open_grouped_by_due_date_view: Array<IWorkOrderOpenGroupedByDueDateView>;
  /** fetch aggregated fields from the table: "work_order_open_grouped_by_due_date_view" */
  work_order_open_grouped_by_due_date_view_aggregate: IWorkOrderOpenGroupedByDueDateViewAggregate;
  /** fetch data from the table: "work_order_priority" */
  work_order_priority: Array<IWorkOrderPriority>;
  /** fetch aggregated fields from the table: "work_order_priority" */
  work_order_priority_aggregate: IWorkOrderPriorityAggregate;
  /** fetch data from the table: "work_order_priority" using primary key columns */
  work_order_priority_by_pk?: Maybe<IWorkOrderPriority>;
  /** fetch data from the table: "work_order_recurring_view" */
  work_order_recurring_view: Array<IWorkOrderRecurringView>;
  /** fetch aggregated fields from the table: "work_order_recurring_view" */
  work_order_recurring_view_aggregate: IWorkOrderRecurringViewAggregate;
  /** fetch data from the table: "work_order_report" */
  work_order_report: Array<IWorkOrderReport>;
  /** fetch aggregated fields from the table: "work_order_report" */
  work_order_report_aggregate: IWorkOrderReportAggregate;
  /** fetch data from the table: "work_order_report" using primary key columns */
  work_order_report_by_pk?: Maybe<IWorkOrderReport>;
  /** fetch data from the table: "work_order_status" */
  work_order_status: Array<IWorkOrderStatus>;
  /** fetch aggregated fields from the table: "work_order_status" */
  work_order_status_aggregate: IWorkOrderStatusAggregate;
  /** fetch data from the table: "work_order_status" using primary key columns */
  work_order_status_by_pk?: Maybe<IWorkOrderStatus>;
  /** fetch data from the table: "work_order_template" */
  work_order_template: Array<IWorkOrderTemplate>;
  /** fetch aggregated fields from the table: "work_order_template" */
  work_order_template_aggregate: IWorkOrderTemplateAggregate;
  /** fetch data from the table: "work_order_template" using primary key columns */
  work_order_template_by_pk?: Maybe<IWorkOrderTemplate>;
  /** fetch data from the table: "work_order_type" */
  work_order_type: Array<IWorkOrderType>;
  /** fetch aggregated fields from the table: "work_order_type" */
  work_order_type_aggregate: IWorkOrderTypeAggregate;
  /** fetch data from the table: "work_order_type" using primary key columns */
  work_order_type_by_pk?: Maybe<IWorkOrderType>;
  /** fetch data from the table: "work_order_with_last_recurring_instance_view" */
  work_order_with_last_recurring_instance_view: Array<IWorkOrderWithLastRecurringInstanceView>;
  /** fetch aggregated fields from the table: "work_order_with_last_recurring_instance_view" */
  work_order_with_last_recurring_instance_view_aggregate: IWorkOrderWithLastRecurringInstanceViewAggregate;
  /** fetch data from the table: "work_order_with_open_due_date_reminder" */
  work_order_with_open_due_date_reminder: Array<IWorkOrderWithOpenDueDateReminder>;
  /** fetch aggregated fields from the table: "work_order_with_open_due_date_reminder" */
  work_order_with_open_due_date_reminder_aggregate: IWorkOrderWithOpenDueDateReminderAggregate;
  /** fetch data from the table: "work_order_x_collaborator" */
  work_order_x_collaborator: Array<IWorkOrderXCollaborator>;
  /** fetch aggregated fields from the table: "work_order_x_collaborator" */
  work_order_x_collaborator_aggregate: IWorkOrderXCollaboratorAggregate;
  /** fetch data from the table: "work_order_x_collaborator" using primary key columns */
  work_order_x_collaborator_by_pk?: Maybe<IWorkOrderXCollaborator>;
  /** fetch data from the table: "work_order_x_team" */
  work_order_x_team: Array<IWorkOrderXTeam>;
  /** fetch aggregated fields from the table: "work_order_x_team" */
  work_order_x_team_aggregate: IWorkOrderXTeamAggregate;
  /** fetch data from the table: "work_order_x_team" using primary key columns */
  work_order_x_team_by_pk?: Maybe<IWorkOrderXTeam>;
  /** fetch data from the table: "work_order_x_upload" */
  work_order_x_upload: Array<IWorkOrderXUpload>;
  /** fetch aggregated fields from the table: "work_order_x_upload" */
  work_order_x_upload_aggregate: IWorkOrderXUploadAggregate;
  /** fetch data from the table: "work_order_x_upload" using primary key columns */
  work_order_x_upload_by_pk?: Maybe<IWorkOrderXUpload>;
  /** fetch data from the table: "work_order_x_user" */
  work_order_x_user: Array<IWorkOrderXUser>;
  /** fetch aggregated fields from the table: "work_order_x_user" */
  work_order_x_user_aggregate: IWorkOrderXUserAggregate;
  /** fetch data from the table: "work_order_x_user" using primary key columns */
  work_order_x_user_by_pk?: Maybe<IWorkOrderXUser>;
  /** fetch data from the table: "work_order_x_work_order_category" */
  work_order_x_work_order_category: Array<IWorkOrderXWorkOrderCategory>;
  /** fetch aggregated fields from the table: "work_order_x_work_order_category" */
  work_order_x_work_order_category_aggregate: IWorkOrderXWorkOrderCategoryAggregate;
  /** fetch data from the table: "work_order_x_work_order_category" using primary key columns */
  work_order_x_work_order_category_by_pk?: Maybe<IWorkOrderXWorkOrderCategory>;
  /** execute function "work_orders_needing_due_date_reminder" which returns "work_order" */
  work_orders_needing_due_date_reminder: Array<IWorkOrder>;
  /** execute function "work_orders_needing_due_date_reminder" and query aggregates on result of table type "work_order" */
  work_orders_needing_due_date_reminder_aggregate: IWorkOrderAggregate;
};


export type IQueryRootAnalyticsEventArgs = {
  distinct_on?: InputMaybe<Array<IAnalyticsEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAnalyticsEventOrderBy>>;
  where?: InputMaybe<IAnalyticsEventBoolExp>;
};


export type IQueryRootAnalyticsEventAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAnalyticsEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAnalyticsEventOrderBy>>;
  where?: InputMaybe<IAnalyticsEventBoolExp>;
};


export type IQueryRootAnalyticsEventByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootApiAccessLogArgs = {
  distinct_on?: InputMaybe<Array<IApiAccessLogSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IApiAccessLogOrderBy>>;
  where?: InputMaybe<IApiAccessLogBoolExp>;
};


export type IQueryRootApiAccessLogAggregateArgs = {
  distinct_on?: InputMaybe<Array<IApiAccessLogSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IApiAccessLogOrderBy>>;
  where?: InputMaybe<IApiAccessLogBoolExp>;
};


export type IQueryRootApiAccessLogByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootAskQuestionArgs = {
  question?: InputMaybe<Scalars['String']['input']>;
};


export type IQueryRootAssetArgs = {
  distinct_on?: InputMaybe<Array<IAssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetOrderBy>>;
  where?: InputMaybe<IAssetBoolExp>;
};


export type IQueryRootAssetAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetOrderBy>>;
  where?: InputMaybe<IAssetBoolExp>;
};


export type IQueryRootAssetByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootAssetCurrentStateArgs = {
  distinct_on?: InputMaybe<Array<IAssetCurrentStateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetCurrentStateOrderBy>>;
  where?: InputMaybe<IAssetCurrentStateBoolExp>;
};


export type IQueryRootAssetCurrentStateAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetCurrentStateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetCurrentStateOrderBy>>;
  where?: InputMaybe<IAssetCurrentStateBoolExp>;
};


export type IQueryRootAssetDefaultStateArgs = {
  distinct_on?: InputMaybe<Array<IAssetDefaultStateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetDefaultStateOrderBy>>;
  where?: InputMaybe<IAssetDefaultStateBoolExp>;
};


export type IQueryRootAssetDefaultStateAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetDefaultStateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetDefaultStateOrderBy>>;
  where?: InputMaybe<IAssetDefaultStateBoolExp>;
};


export type IQueryRootAssetDefaultStateByPkArgs = {
  value: Scalars['String']['input'];
};


export type IQueryRootAssetGroupArgs = {
  distinct_on?: InputMaybe<Array<IAssetGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetGroupOrderBy>>;
  where?: InputMaybe<IAssetGroupBoolExp>;
};


export type IQueryRootAssetGroupAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetGroupOrderBy>>;
  where?: InputMaybe<IAssetGroupBoolExp>;
};


export type IQueryRootAssetGroupByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootAssetLastStateViewArgs = {
  distinct_on?: InputMaybe<Array<IAssetLastStateViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetLastStateViewOrderBy>>;
  where?: InputMaybe<IAssetLastStateViewBoolExp>;
};


export type IQueryRootAssetLastStateViewAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetLastStateViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetLastStateViewOrderBy>>;
  where?: InputMaybe<IAssetLastStateViewBoolExp>;
};


export type IQueryRootAssetManufacturerArgs = {
  distinct_on?: InputMaybe<Array<IAssetManufacturerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetManufacturerOrderBy>>;
  where?: InputMaybe<IAssetManufacturerBoolExp>;
};


export type IQueryRootAssetManufacturerAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetManufacturerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetManufacturerOrderBy>>;
  where?: InputMaybe<IAssetManufacturerBoolExp>;
};


export type IQueryRootAssetManufacturerByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootAssetOperatingScheduleArgs = {
  distinct_on?: InputMaybe<Array<IAssetOperatingScheduleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetOperatingScheduleOrderBy>>;
  where?: InputMaybe<IAssetOperatingScheduleBoolExp>;
};


export type IQueryRootAssetOperatingScheduleAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetOperatingScheduleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetOperatingScheduleOrderBy>>;
  where?: InputMaybe<IAssetOperatingScheduleBoolExp>;
};


export type IQueryRootAssetOperatingScheduleByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootAssetStateArgs = {
  distinct_on?: InputMaybe<Array<IAssetStateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetStateOrderBy>>;
  where?: InputMaybe<IAssetStateBoolExp>;
};


export type IQueryRootAssetStateAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetStateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetStateOrderBy>>;
  where?: InputMaybe<IAssetStateBoolExp>;
};


export type IQueryRootAssetStateByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootAssetStateLogArgs = {
  distinct_on?: InputMaybe<Array<IAssetStateLogSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetStateLogOrderBy>>;
  where?: InputMaybe<IAssetStateLogBoolExp>;
};


export type IQueryRootAssetStateLogAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetStateLogSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetStateLogOrderBy>>;
  where?: InputMaybe<IAssetStateLogBoolExp>;
};


export type IQueryRootAssetStateLogByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootAssetStateVariantArgs = {
  distinct_on?: InputMaybe<Array<IAssetStateVariantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetStateVariantOrderBy>>;
  where?: InputMaybe<IAssetStateVariantBoolExp>;
};


export type IQueryRootAssetStateVariantAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetStateVariantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetStateVariantOrderBy>>;
  where?: InputMaybe<IAssetStateVariantBoolExp>;
};


export type IQueryRootAssetStateVariantByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootAssetStateVariantTypeArgs = {
  distinct_on?: InputMaybe<Array<IAssetStateVariantTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetStateVariantTypeOrderBy>>;
  where?: InputMaybe<IAssetStateVariantTypeBoolExp>;
};


export type IQueryRootAssetStateVariantTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetStateVariantTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetStateVariantTypeOrderBy>>;
  where?: InputMaybe<IAssetStateVariantTypeBoolExp>;
};


export type IQueryRootAssetStateVariantTypeByPkArgs = {
  value: Scalars['String']['input'];
};


export type IQueryRootAssetXAssetStateArgs = {
  distinct_on?: InputMaybe<Array<IAssetXAssetStateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXAssetStateOrderBy>>;
  where?: InputMaybe<IAssetXAssetStateBoolExp>;
};


export type IQueryRootAssetXAssetStateAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetXAssetStateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXAssetStateOrderBy>>;
  where?: InputMaybe<IAssetXAssetStateBoolExp>;
};


export type IQueryRootAssetXAssetStateByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootAssetXAssetStateDurationArgs = {
  distinct_on?: InputMaybe<Array<IAssetXAssetStateDurationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXAssetStateDurationOrderBy>>;
  where?: InputMaybe<IAssetXAssetStateDurationBoolExp>;
};


export type IQueryRootAssetXAssetStateDurationAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetXAssetStateDurationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXAssetStateDurationOrderBy>>;
  where?: InputMaybe<IAssetXAssetStateDurationBoolExp>;
};


export type IQueryRootAssetXConsumableArgs = {
  distinct_on?: InputMaybe<Array<IAssetXConsumableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXConsumableOrderBy>>;
  where?: InputMaybe<IAssetXConsumableBoolExp>;
};


export type IQueryRootAssetXConsumableAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetXConsumableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXConsumableOrderBy>>;
  where?: InputMaybe<IAssetXConsumableBoolExp>;
};


export type IQueryRootAssetXConsumableByPkArgs = {
  asset_id: Scalars['uuid']['input'];
  consumable_id: Scalars['uuid']['input'];
};


export type IQueryRootAssetXUploadArgs = {
  distinct_on?: InputMaybe<Array<IAssetXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXUploadOrderBy>>;
  where?: InputMaybe<IAssetXUploadBoolExp>;
};


export type IQueryRootAssetXUploadAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXUploadOrderBy>>;
  where?: InputMaybe<IAssetXUploadBoolExp>;
};


export type IQueryRootAssetXUploadByPkArgs = {
  asset_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};


export type IQueryRootAssetXWorkOrderArgs = {
  distinct_on?: InputMaybe<Array<IAssetXWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXWorkOrderOrderBy>>;
  where?: InputMaybe<IAssetXWorkOrderBoolExp>;
};


export type IQueryRootAssetXWorkOrderAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetXWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXWorkOrderOrderBy>>;
  where?: InputMaybe<IAssetXWorkOrderBoolExp>;
};


export type IQueryRootAssetXWorkOrderByPkArgs = {
  asset_id: Scalars['uuid']['input'];
  work_order_id: Scalars['uuid']['input'];
};


export type IQueryRootBlockElementArgs = {
  distinct_on?: InputMaybe<Array<IBlockElementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBlockElementOrderBy>>;
  where?: InputMaybe<IBlockElementBoolExp>;
};


export type IQueryRootBlockElementAggregateArgs = {
  distinct_on?: InputMaybe<Array<IBlockElementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBlockElementOrderBy>>;
  where?: InputMaybe<IBlockElementBoolExp>;
};


export type IQueryRootBlockElementByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootBlockElementTypeArgs = {
  distinct_on?: InputMaybe<Array<IBlockElementTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBlockElementTypeOrderBy>>;
  where?: InputMaybe<IBlockElementTypeBoolExp>;
};


export type IQueryRootBlockElementTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<IBlockElementTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBlockElementTypeOrderBy>>;
  where?: InputMaybe<IBlockElementTypeBoolExp>;
};


export type IQueryRootBlockElementTypeByPkArgs = {
  value: Scalars['String']['input'];
};


export type IQueryRootBlockElementXUploadArgs = {
  distinct_on?: InputMaybe<Array<IBlockElementXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBlockElementXUploadOrderBy>>;
  where?: InputMaybe<IBlockElementXUploadBoolExp>;
};


export type IQueryRootBlockElementXUploadAggregateArgs = {
  distinct_on?: InputMaybe<Array<IBlockElementXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBlockElementXUploadOrderBy>>;
  where?: InputMaybe<IBlockElementXUploadBoolExp>;
};


export type IQueryRootBlockElementXUploadByPkArgs = {
  element_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};


export type IQueryRootBlockGroupArgs = {
  distinct_on?: InputMaybe<Array<IBlockGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBlockGroupOrderBy>>;
  where?: InputMaybe<IBlockGroupBoolExp>;
};


export type IQueryRootBlockGroupAggregateArgs = {
  distinct_on?: InputMaybe<Array<IBlockGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBlockGroupOrderBy>>;
  where?: InputMaybe<IBlockGroupBoolExp>;
};


export type IQueryRootBlockGroupByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootBlockGroupTypeArgs = {
  distinct_on?: InputMaybe<Array<IBlockGroupTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBlockGroupTypeOrderBy>>;
  where?: InputMaybe<IBlockGroupTypeBoolExp>;
};


export type IQueryRootBlockGroupTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<IBlockGroupTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBlockGroupTypeOrderBy>>;
  where?: InputMaybe<IBlockGroupTypeBoolExp>;
};


export type IQueryRootBlockGroupTypeByPkArgs = {
  value: Scalars['String']['input'];
};


export type IQueryRootCommentArgs = {
  distinct_on?: InputMaybe<Array<ICommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICommentOrderBy>>;
  where?: InputMaybe<ICommentBoolExp>;
};


export type IQueryRootCommentAggregateArgs = {
  distinct_on?: InputMaybe<Array<ICommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICommentOrderBy>>;
  where?: InputMaybe<ICommentBoolExp>;
};


export type IQueryRootCommentByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootCommentXUploadArgs = {
  distinct_on?: InputMaybe<Array<ICommentXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICommentXUploadOrderBy>>;
  where?: InputMaybe<ICommentXUploadBoolExp>;
};


export type IQueryRootCommentXUploadAggregateArgs = {
  distinct_on?: InputMaybe<Array<ICommentXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICommentXUploadOrderBy>>;
  where?: InputMaybe<ICommentXUploadBoolExp>;
};


export type IQueryRootCommentXUploadByPkArgs = {
  comment_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};


export type IQueryRootConsumableArgs = {
  distinct_on?: InputMaybe<Array<IConsumableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableOrderBy>>;
  where?: InputMaybe<IConsumableBoolExp>;
};


export type IQueryRootConsumableAggregateArgs = {
  distinct_on?: InputMaybe<Array<IConsumableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableOrderBy>>;
  where?: InputMaybe<IConsumableBoolExp>;
};


export type IQueryRootConsumableByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootConsumableConfigArgs = {
  distinct_on?: InputMaybe<Array<IConsumableConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableConfigOrderBy>>;
  where?: InputMaybe<IConsumableConfigBoolExp>;
};


export type IQueryRootConsumableConfigAggregateArgs = {
  distinct_on?: InputMaybe<Array<IConsumableConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableConfigOrderBy>>;
  where?: InputMaybe<IConsumableConfigBoolExp>;
};


export type IQueryRootConsumableConfigByPkArgs = {
  location_id: Scalars['uuid']['input'];
};


export type IQueryRootConsumableGroupArgs = {
  distinct_on?: InputMaybe<Array<IConsumableGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableGroupOrderBy>>;
  where?: InputMaybe<IConsumableGroupBoolExp>;
};


export type IQueryRootConsumableGroupAggregateArgs = {
  distinct_on?: InputMaybe<Array<IConsumableGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableGroupOrderBy>>;
  where?: InputMaybe<IConsumableGroupBoolExp>;
};


export type IQueryRootConsumableGroupByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootConsumableLogArgs = {
  distinct_on?: InputMaybe<Array<IConsumableLogSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableLogOrderBy>>;
  where?: InputMaybe<IConsumableLogBoolExp>;
};


export type IQueryRootConsumableLogAggregateArgs = {
  distinct_on?: InputMaybe<Array<IConsumableLogSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableLogOrderBy>>;
  where?: InputMaybe<IConsumableLogBoolExp>;
};


export type IQueryRootConsumableLogByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootConsumableStorageLocationArgs = {
  distinct_on?: InputMaybe<Array<IConsumableStorageLocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableStorageLocationOrderBy>>;
  where?: InputMaybe<IConsumableStorageLocationBoolExp>;
};


export type IQueryRootConsumableStorageLocationAggregateArgs = {
  distinct_on?: InputMaybe<Array<IConsumableStorageLocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableStorageLocationOrderBy>>;
  where?: InputMaybe<IConsumableStorageLocationBoolExp>;
};


export type IQueryRootConsumableStorageLocationByPkArgs = {
  consumable_id: Scalars['uuid']['input'];
  place_id: Scalars['uuid']['input'];
};


export type IQueryRootConsumableXUploadArgs = {
  distinct_on?: InputMaybe<Array<IConsumableXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableXUploadOrderBy>>;
  where?: InputMaybe<IConsumableXUploadBoolExp>;
};


export type IQueryRootConsumableXUploadAggregateArgs = {
  distinct_on?: InputMaybe<Array<IConsumableXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableXUploadOrderBy>>;
  where?: InputMaybe<IConsumableXUploadBoolExp>;
};


export type IQueryRootConsumableXUploadByPkArgs = {
  consumable_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};


export type IQueryRootContactArgs = {
  distinct_on?: InputMaybe<Array<IContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IContactOrderBy>>;
  where?: InputMaybe<IContactBoolExp>;
};


export type IQueryRootContactAggregateArgs = {
  distinct_on?: InputMaybe<Array<IContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IContactOrderBy>>;
  where?: InputMaybe<IContactBoolExp>;
};


export type IQueryRootContactByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootCustomMigrationArgs = {
  distinct_on?: InputMaybe<Array<ICustomMigrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICustomMigrationOrderBy>>;
  where?: InputMaybe<ICustomMigrationBoolExp>;
};


export type IQueryRootCustomMigrationAggregateArgs = {
  distinct_on?: InputMaybe<Array<ICustomMigrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICustomMigrationOrderBy>>;
  where?: InputMaybe<ICustomMigrationBoolExp>;
};


export type IQueryRootCustomMigrationByPkArgs = {
  location_id: Scalars['uuid']['input'];
  migration_id: Scalars['String']['input'];
};


export type IQueryRootCustomViewArgs = {
  distinct_on?: InputMaybe<Array<ICustomViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICustomViewOrderBy>>;
  where?: InputMaybe<ICustomViewBoolExp>;
};


export type IQueryRootCustomViewAggregateArgs = {
  distinct_on?: InputMaybe<Array<ICustomViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICustomViewOrderBy>>;
  where?: InputMaybe<ICustomViewBoolExp>;
};


export type IQueryRootCustomViewByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootCustomViewUserFavoriteArgs = {
  distinct_on?: InputMaybe<Array<ICustomViewUserFavoriteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICustomViewUserFavoriteOrderBy>>;
  where?: InputMaybe<ICustomViewUserFavoriteBoolExp>;
};


export type IQueryRootCustomViewUserFavoriteAggregateArgs = {
  distinct_on?: InputMaybe<Array<ICustomViewUserFavoriteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICustomViewUserFavoriteOrderBy>>;
  where?: InputMaybe<ICustomViewUserFavoriteBoolExp>;
};


export type IQueryRootCustomViewUserFavoriteByPkArgs = {
  custom_view_id: Scalars['uuid']['input'];
  user_id: Scalars['uuid']['input'];
};


export type IQueryRootCustomerFeedbackArgs = {
  distinct_on?: InputMaybe<Array<ICustomerFeedbackSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICustomerFeedbackOrderBy>>;
  where?: InputMaybe<ICustomerFeedbackBoolExp>;
};


export type IQueryRootCustomerFeedbackAggregateArgs = {
  distinct_on?: InputMaybe<Array<ICustomerFeedbackSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICustomerFeedbackOrderBy>>;
  where?: InputMaybe<ICustomerFeedbackBoolExp>;
};


export type IQueryRootCustomerFeedbackByPkArgs = {
  id: Scalars['Int']['input'];
};


export type IQueryRootFeatureFlagArgs = {
  distinct_on?: InputMaybe<Array<IFeatureFlagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IFeatureFlagOrderBy>>;
  where?: InputMaybe<IFeatureFlagBoolExp>;
};


export type IQueryRootFeatureFlagAggregateArgs = {
  distinct_on?: InputMaybe<Array<IFeatureFlagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IFeatureFlagOrderBy>>;
  where?: InputMaybe<IFeatureFlagBoolExp>;
};


export type IQueryRootFeatureFlagByPkArgs = {
  id: Scalars['String']['input'];
};


export type IQueryRootFileFolderArgs = {
  distinct_on?: InputMaybe<Array<IFileFolderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IFileFolderOrderBy>>;
  where?: InputMaybe<IFileFolderBoolExp>;
};


export type IQueryRootFileFolderAggregateArgs = {
  distinct_on?: InputMaybe<Array<IFileFolderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IFileFolderOrderBy>>;
  where?: InputMaybe<IFileFolderBoolExp>;
};


export type IQueryRootFileFolderByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootFileFolderXUploadArgs = {
  distinct_on?: InputMaybe<Array<IFileFolderXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IFileFolderXUploadOrderBy>>;
  where?: InputMaybe<IFileFolderXUploadBoolExp>;
};


export type IQueryRootFileFolderXUploadAggregateArgs = {
  distinct_on?: InputMaybe<Array<IFileFolderXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IFileFolderXUploadOrderBy>>;
  where?: InputMaybe<IFileFolderXUploadBoolExp>;
};


export type IQueryRootFileFolderXUploadByPkArgs = {
  file_folder_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};


export type IQueryRootInspectionResultArgs = {
  distinct_on?: InputMaybe<Array<IInspectionResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IInspectionResultOrderBy>>;
  where?: InputMaybe<IInspectionResultBoolExp>;
};


export type IQueryRootInspectionResultAggregateArgs = {
  distinct_on?: InputMaybe<Array<IInspectionResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IInspectionResultOrderBy>>;
  where?: InputMaybe<IInspectionResultBoolExp>;
};


export type IQueryRootInspectionResultByPkArgs = {
  value: Scalars['String']['input'];
};


export type IQueryRootJobsCronJobCompletedArgs = {
  distinct_on?: InputMaybe<Array<IJobsCronJobCompletedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IJobsCronJobCompletedOrderBy>>;
  where?: InputMaybe<IJobsCronJobCompletedBoolExp>;
};


export type IQueryRootJobsCronJobCompletedAggregateArgs = {
  distinct_on?: InputMaybe<Array<IJobsCronJobCompletedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IJobsCronJobCompletedOrderBy>>;
  where?: InputMaybe<IJobsCronJobCompletedBoolExp>;
};


export type IQueryRootJobsCronJobCompletedByPkArgs = {
  id: Scalars['String']['input'];
};


export type IQueryRootLocationArgs = {
  distinct_on?: InputMaybe<Array<ILocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationOrderBy>>;
  where?: InputMaybe<ILocationBoolExp>;
};


export type IQueryRootLocationAdminViewArgs = {
  distinct_on?: InputMaybe<Array<ILocationAdminViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationAdminViewOrderBy>>;
  where?: InputMaybe<ILocationAdminViewBoolExp>;
};


export type IQueryRootLocationAdminViewAggregateArgs = {
  distinct_on?: InputMaybe<Array<ILocationAdminViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationAdminViewOrderBy>>;
  where?: InputMaybe<ILocationAdminViewBoolExp>;
};


export type IQueryRootLocationAggregateArgs = {
  distinct_on?: InputMaybe<Array<ILocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationOrderBy>>;
  where?: InputMaybe<ILocationBoolExp>;
};


export type IQueryRootLocationApiKeyArgs = {
  distinct_on?: InputMaybe<Array<ILocationApiKeySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationApiKeyOrderBy>>;
  where?: InputMaybe<ILocationApiKeyBoolExp>;
};


export type IQueryRootLocationApiKeyAggregateArgs = {
  distinct_on?: InputMaybe<Array<ILocationApiKeySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationApiKeyOrderBy>>;
  where?: InputMaybe<ILocationApiKeyBoolExp>;
};


export type IQueryRootLocationApiKeyByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootLocationByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootLocationInviteArgs = {
  distinct_on?: InputMaybe<Array<ILocationInviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationInviteOrderBy>>;
  where?: InputMaybe<ILocationInviteBoolExp>;
};


export type IQueryRootLocationInviteAggregateArgs = {
  distinct_on?: InputMaybe<Array<ILocationInviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationInviteOrderBy>>;
  where?: InputMaybe<ILocationInviteBoolExp>;
};


export type IQueryRootLocationInviteByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootLocationMemberArgs = {
  distinct_on?: InputMaybe<Array<ILocationMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationMemberOrderBy>>;
  where?: InputMaybe<ILocationMemberBoolExp>;
};


export type IQueryRootLocationMemberAggregateArgs = {
  distinct_on?: InputMaybe<Array<ILocationMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationMemberOrderBy>>;
  where?: InputMaybe<ILocationMemberBoolExp>;
};


export type IQueryRootLocationMemberByPkArgs = {
  location_id: Scalars['uuid']['input'];
  user_id: Scalars['uuid']['input'];
};


export type IQueryRootLocationMemberRoleArgs = {
  distinct_on?: InputMaybe<Array<ILocationMemberRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationMemberRoleOrderBy>>;
  where?: InputMaybe<ILocationMemberRoleBoolExp>;
};


export type IQueryRootLocationMemberRoleAggregateArgs = {
  distinct_on?: InputMaybe<Array<ILocationMemberRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationMemberRoleOrderBy>>;
  where?: InputMaybe<ILocationMemberRoleBoolExp>;
};


export type IQueryRootLocationMemberRoleByPkArgs = {
  role: Scalars['String']['input'];
};


export type IQueryRootLocationXFeatureFlagArgs = {
  distinct_on?: InputMaybe<Array<ILocationXFeatureFlagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationXFeatureFlagOrderBy>>;
  where?: InputMaybe<ILocationXFeatureFlagBoolExp>;
};


export type IQueryRootLocationXFeatureFlagAggregateArgs = {
  distinct_on?: InputMaybe<Array<ILocationXFeatureFlagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationXFeatureFlagOrderBy>>;
  where?: InputMaybe<ILocationXFeatureFlagBoolExp>;
};


export type IQueryRootLocationXFeatureFlagByPkArgs = {
  feature_flag_id: Scalars['String']['input'];
  location_id: Scalars['uuid']['input'];
};


export type IQueryRootMaintenanceArgs = {
  distinct_on?: InputMaybe<Array<IMaintenanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMaintenanceOrderBy>>;
  where?: InputMaybe<IMaintenanceBoolExp>;
};


export type IQueryRootMaintenanceAggregateArgs = {
  distinct_on?: InputMaybe<Array<IMaintenanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMaintenanceOrderBy>>;
  where?: InputMaybe<IMaintenanceBoolExp>;
};


export type IQueryRootMaintenanceByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootMaintenanceTriggerArgs = {
  distinct_on?: InputMaybe<Array<IMaintenanceTriggerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMaintenanceTriggerOrderBy>>;
  where?: InputMaybe<IMaintenanceTriggerBoolExp>;
};


export type IQueryRootMaintenanceTriggerAggregateArgs = {
  distinct_on?: InputMaybe<Array<IMaintenanceTriggerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMaintenanceTriggerOrderBy>>;
  where?: InputMaybe<IMaintenanceTriggerBoolExp>;
};


export type IQueryRootMaintenanceTriggerByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootMaintenanceTriggerTaskArgs = {
  distinct_on?: InputMaybe<Array<IMaintenanceTriggerTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMaintenanceTriggerTaskOrderBy>>;
  where?: InputMaybe<IMaintenanceTriggerTaskBoolExp>;
};


export type IQueryRootMaintenanceTriggerTaskAggregateArgs = {
  distinct_on?: InputMaybe<Array<IMaintenanceTriggerTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMaintenanceTriggerTaskOrderBy>>;
  where?: InputMaybe<IMaintenanceTriggerTaskBoolExp>;
};


export type IQueryRootMaintenanceTriggerTaskByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootMaintenanceTriggerTypeArgs = {
  distinct_on?: InputMaybe<Array<IMaintenanceTriggerTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMaintenanceTriggerTypeOrderBy>>;
  where?: InputMaybe<IMaintenanceTriggerTypeBoolExp>;
};


export type IQueryRootMaintenanceTriggerTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<IMaintenanceTriggerTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMaintenanceTriggerTypeOrderBy>>;
  where?: InputMaybe<IMaintenanceTriggerTypeBoolExp>;
};


export type IQueryRootMaintenanceTriggerTypeByPkArgs = {
  name: Scalars['String']['input'];
};


export type IQueryRootMeterArgs = {
  distinct_on?: InputMaybe<Array<IMeterSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMeterOrderBy>>;
  where?: InputMaybe<IMeterBoolExp>;
};


export type IQueryRootMeterAggregateArgs = {
  distinct_on?: InputMaybe<Array<IMeterSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMeterOrderBy>>;
  where?: InputMaybe<IMeterBoolExp>;
};


export type IQueryRootMeterByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootMeterReadingArgs = {
  distinct_on?: InputMaybe<Array<IMeterReadingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMeterReadingOrderBy>>;
  where?: InputMaybe<IMeterReadingBoolExp>;
};


export type IQueryRootMeterReadingAggregateArgs = {
  distinct_on?: InputMaybe<Array<IMeterReadingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMeterReadingOrderBy>>;
  where?: InputMaybe<IMeterReadingBoolExp>;
};


export type IQueryRootMeterReadingByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootMeterTypeArgs = {
  distinct_on?: InputMaybe<Array<IMeterTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMeterTypeOrderBy>>;
  where?: InputMaybe<IMeterTypeBoolExp>;
};


export type IQueryRootMeterTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<IMeterTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMeterTypeOrderBy>>;
  where?: InputMaybe<IMeterTypeBoolExp>;
};


export type IQueryRootMeterTypeByPkArgs = {
  value: Scalars['String']['input'];
};


export type IQueryRootNotificationArgs = {
  distinct_on?: InputMaybe<Array<INotificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<INotificationOrderBy>>;
  where?: InputMaybe<INotificationBoolExp>;
};


export type IQueryRootNotificationAggregateArgs = {
  distinct_on?: InputMaybe<Array<INotificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<INotificationOrderBy>>;
  where?: InputMaybe<INotificationBoolExp>;
};


export type IQueryRootNotificationByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootNotificationTypeArgs = {
  distinct_on?: InputMaybe<Array<INotificationTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<INotificationTypeOrderBy>>;
  where?: InputMaybe<INotificationTypeBoolExp>;
};


export type IQueryRootNotificationTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<INotificationTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<INotificationTypeOrderBy>>;
  where?: InputMaybe<INotificationTypeBoolExp>;
};


export type IQueryRootNotificationTypeByPkArgs = {
  value: Scalars['String']['input'];
};


export type IQueryRootOrgArgs = {
  distinct_on?: InputMaybe<Array<IOrgSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IOrgOrderBy>>;
  where?: InputMaybe<IOrgBoolExp>;
};


export type IQueryRootOrgAggregateArgs = {
  distinct_on?: InputMaybe<Array<IOrgSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IOrgOrderBy>>;
  where?: InputMaybe<IOrgBoolExp>;
};


export type IQueryRootOrgByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootOrgFeatureArgs = {
  distinct_on?: InputMaybe<Array<IOrgFeatureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IOrgFeatureOrderBy>>;
  where?: InputMaybe<IOrgFeatureBoolExp>;
};


export type IQueryRootOrgFeatureAggregateArgs = {
  distinct_on?: InputMaybe<Array<IOrgFeatureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IOrgFeatureOrderBy>>;
  where?: InputMaybe<IOrgFeatureBoolExp>;
};


export type IQueryRootOrgFeatureByPkArgs = {
  org_id: Scalars['uuid']['input'];
};


export type IQueryRootOrgMemberArgs = {
  distinct_on?: InputMaybe<Array<IOrgMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IOrgMemberOrderBy>>;
  where?: InputMaybe<IOrgMemberBoolExp>;
};


export type IQueryRootOrgMemberAggregateArgs = {
  distinct_on?: InputMaybe<Array<IOrgMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IOrgMemberOrderBy>>;
  where?: InputMaybe<IOrgMemberBoolExp>;
};


export type IQueryRootOrgPrefsArgs = {
  distinct_on?: InputMaybe<Array<IOrgPrefsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IOrgPrefsOrderBy>>;
  where?: InputMaybe<IOrgPrefsBoolExp>;
};


export type IQueryRootOrgPrefsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IOrgPrefsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IOrgPrefsOrderBy>>;
  where?: InputMaybe<IOrgPrefsBoolExp>;
};


export type IQueryRootOrgPrefsByPkArgs = {
  org_id: Scalars['uuid']['input'];
};


export type IQueryRootOrgSsoSettingsArgs = {
  distinct_on?: InputMaybe<Array<IOrgSsoSettingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IOrgSsoSettingsOrderBy>>;
  where?: InputMaybe<IOrgSsoSettingsBoolExp>;
};


export type IQueryRootOrgSsoSettingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IOrgSsoSettingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IOrgSsoSettingsOrderBy>>;
  where?: InputMaybe<IOrgSsoSettingsBoolExp>;
};


export type IQueryRootOrgSsoSettingsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootOverdueWorkOrdersWithoutNotificationArgs = {
  args: IOverdueWorkOrdersWithoutNotificationArgs;
  distinct_on?: InputMaybe<Array<IWorkOrderXUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXUserOrderBy>>;
  where?: InputMaybe<IWorkOrderXUserBoolExp>;
};


export type IQueryRootOverdueWorkOrdersWithoutNotificationAggregateArgs = {
  args: IOverdueWorkOrdersWithoutNotificationArgs;
  distinct_on?: InputMaybe<Array<IWorkOrderXUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXUserOrderBy>>;
  where?: InputMaybe<IWorkOrderXUserBoolExp>;
};


export type IQueryRootPermissionRoleArgs = {
  distinct_on?: InputMaybe<Array<IPermissionRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPermissionRoleOrderBy>>;
  where?: InputMaybe<IPermissionRoleBoolExp>;
};


export type IQueryRootPermissionRoleAggregateArgs = {
  distinct_on?: InputMaybe<Array<IPermissionRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPermissionRoleOrderBy>>;
  where?: InputMaybe<IPermissionRoleBoolExp>;
};


export type IQueryRootPermissionRoleByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootPermissionRoleXFeatureFlagArgs = {
  distinct_on?: InputMaybe<Array<IPermissionRoleXFeatureFlagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPermissionRoleXFeatureFlagOrderBy>>;
  where?: InputMaybe<IPermissionRoleXFeatureFlagBoolExp>;
};


export type IQueryRootPermissionRoleXFeatureFlagAggregateArgs = {
  distinct_on?: InputMaybe<Array<IPermissionRoleXFeatureFlagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPermissionRoleXFeatureFlagOrderBy>>;
  where?: InputMaybe<IPermissionRoleXFeatureFlagBoolExp>;
};


export type IQueryRootPermissionRoleXFeatureFlagByPkArgs = {
  feature_flag: Scalars['String']['input'];
  location_id: Scalars['uuid']['input'];
  permission_role_id: Scalars['uuid']['input'];
};


export type IQueryRootPermissionRoleXPermissionScopeArgs = {
  distinct_on?: InputMaybe<Array<IPermissionRoleXPermissionScopeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPermissionRoleXPermissionScopeOrderBy>>;
  where?: InputMaybe<IPermissionRoleXPermissionScopeBoolExp>;
};


export type IQueryRootPermissionRoleXPermissionScopeAggregateArgs = {
  distinct_on?: InputMaybe<Array<IPermissionRoleXPermissionScopeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPermissionRoleXPermissionScopeOrderBy>>;
  where?: InputMaybe<IPermissionRoleXPermissionScopeBoolExp>;
};


export type IQueryRootPermissionRoleXPermissionScopeByPkArgs = {
  role_id: Scalars['uuid']['input'];
  scope: IPermissionScopeEnum;
};


export type IQueryRootPermissionScopeArgs = {
  distinct_on?: InputMaybe<Array<IPermissionScopeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPermissionScopeOrderBy>>;
  where?: InputMaybe<IPermissionScopeBoolExp>;
};


export type IQueryRootPermissionScopeAggregateArgs = {
  distinct_on?: InputMaybe<Array<IPermissionScopeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPermissionScopeOrderBy>>;
  where?: InputMaybe<IPermissionScopeBoolExp>;
};


export type IQueryRootPermissionScopeByPkArgs = {
  scope: Scalars['String']['input'];
};


export type IQueryRootPlaceArgs = {
  distinct_on?: InputMaybe<Array<IPlaceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPlaceOrderBy>>;
  where?: InputMaybe<IPlaceBoolExp>;
};


export type IQueryRootPlaceAggregateArgs = {
  distinct_on?: InputMaybe<Array<IPlaceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPlaceOrderBy>>;
  where?: InputMaybe<IPlaceBoolExp>;
};


export type IQueryRootPlaceByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootPlaceXUploadArgs = {
  distinct_on?: InputMaybe<Array<IPlaceXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPlaceXUploadOrderBy>>;
  where?: InputMaybe<IPlaceXUploadBoolExp>;
};


export type IQueryRootPlaceXUploadAggregateArgs = {
  distinct_on?: InputMaybe<Array<IPlaceXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPlaceXUploadOrderBy>>;
  where?: InputMaybe<IPlaceXUploadBoolExp>;
};


export type IQueryRootPlaceXUploadByPkArgs = {
  place_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};


export type IQueryRootProjectArgs = {
  distinct_on?: InputMaybe<Array<IProjectSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IProjectOrderBy>>;
  where?: InputMaybe<IProjectBoolExp>;
};


export type IQueryRootProjectAggregateArgs = {
  distinct_on?: InputMaybe<Array<IProjectSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IProjectOrderBy>>;
  where?: InputMaybe<IProjectBoolExp>;
};


export type IQueryRootProjectByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootProjectStatusArgs = {
  distinct_on?: InputMaybe<Array<IProjectStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IProjectStatusOrderBy>>;
  where?: InputMaybe<IProjectStatusBoolExp>;
};


export type IQueryRootProjectStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<IProjectStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IProjectStatusOrderBy>>;
  where?: InputMaybe<IProjectStatusBoolExp>;
};


export type IQueryRootProjectStatusByPkArgs = {
  value: Scalars['String']['input'];
};


export type IQueryRootProjectXTeamArgs = {
  distinct_on?: InputMaybe<Array<IProjectXTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IProjectXTeamOrderBy>>;
  where?: InputMaybe<IProjectXTeamBoolExp>;
};


export type IQueryRootProjectXTeamAggregateArgs = {
  distinct_on?: InputMaybe<Array<IProjectXTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IProjectXTeamOrderBy>>;
  where?: InputMaybe<IProjectXTeamBoolExp>;
};


export type IQueryRootProjectXTeamByPkArgs = {
  project_id: Scalars['uuid']['input'];
  team_id: Scalars['uuid']['input'];
};


export type IQueryRootRecurrenceArgs = {
  distinct_on?: InputMaybe<Array<IRecurrenceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IRecurrenceOrderBy>>;
  where?: InputMaybe<IRecurrenceBoolExp>;
};


export type IQueryRootRecurrenceAggregateArgs = {
  distinct_on?: InputMaybe<Array<IRecurrenceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IRecurrenceOrderBy>>;
  where?: InputMaybe<IRecurrenceBoolExp>;
};


export type IQueryRootRecurrenceByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootRecurrencePatternArgs = {
  distinct_on?: InputMaybe<Array<IRecurrencePatternSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IRecurrencePatternOrderBy>>;
  where?: InputMaybe<IRecurrencePatternBoolExp>;
};


export type IQueryRootRecurrencePatternAggregateArgs = {
  distinct_on?: InputMaybe<Array<IRecurrencePatternSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IRecurrencePatternOrderBy>>;
  where?: InputMaybe<IRecurrencePatternBoolExp>;
};


export type IQueryRootRecurrencePatternByPkArgs = {
  value: Scalars['String']['input'];
};


export type IQueryRootScheduledTaskArgs = {
  distinct_on?: InputMaybe<Array<IScheduledTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IScheduledTaskOrderBy>>;
  where?: InputMaybe<IScheduledTaskBoolExp>;
};


export type IQueryRootScheduledTaskAggregateArgs = {
  distinct_on?: InputMaybe<Array<IScheduledTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IScheduledTaskOrderBy>>;
  where?: InputMaybe<IScheduledTaskBoolExp>;
};


export type IQueryRootScheduledTaskByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootSearchAssetArgs = {
  args: ISearchAssetArgs;
  distinct_on?: InputMaybe<Array<IAssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetOrderBy>>;
  where?: InputMaybe<IAssetBoolExp>;
};


export type IQueryRootSearchAssetAggregateArgs = {
  args: ISearchAssetArgs;
  distinct_on?: InputMaybe<Array<IAssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetOrderBy>>;
  where?: InputMaybe<IAssetBoolExp>;
};


export type IQueryRootSearchMaintenanceArgs = {
  args: ISearchMaintenanceArgs;
  distinct_on?: InputMaybe<Array<IMaintenanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMaintenanceOrderBy>>;
  where?: InputMaybe<IMaintenanceBoolExp>;
};


export type IQueryRootSearchMaintenanceAggregateArgs = {
  args: ISearchMaintenanceArgs;
  distinct_on?: InputMaybe<Array<IMaintenanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMaintenanceOrderBy>>;
  where?: InputMaybe<IMaintenanceBoolExp>;
};


export type IQueryRootSearchWorkOrderArgs = {
  args: ISearchWorkOrderArgs;
  distinct_on?: InputMaybe<Array<IWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOrderBy>>;
  where?: InputMaybe<IWorkOrderBoolExp>;
};


export type IQueryRootSearchWorkOrderAggregateArgs = {
  args: ISearchWorkOrderArgs;
  distinct_on?: InputMaybe<Array<IWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOrderBy>>;
  where?: InputMaybe<IWorkOrderBoolExp>;
};


export type IQueryRootServiceRequestArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestOrderBy>>;
  where?: InputMaybe<IServiceRequestBoolExp>;
};


export type IQueryRootServiceRequestAggregateArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestOrderBy>>;
  where?: InputMaybe<IServiceRequestBoolExp>;
};


export type IQueryRootServiceRequestByPkArgs = {
  id: Scalars['Int']['input'];
};


export type IQueryRootServiceRequestStatusArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestStatusOrderBy>>;
  where?: InputMaybe<IServiceRequestStatusBoolExp>;
};


export type IQueryRootServiceRequestStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestStatusOrderBy>>;
  where?: InputMaybe<IServiceRequestStatusBoolExp>;
};


export type IQueryRootServiceRequestStatusByPkArgs = {
  value: Scalars['String']['input'];
};


export type IQueryRootServiceRequestXAssetArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestXAssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestXAssetOrderBy>>;
  where?: InputMaybe<IServiceRequestXAssetBoolExp>;
};


export type IQueryRootServiceRequestXAssetAggregateArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestXAssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestXAssetOrderBy>>;
  where?: InputMaybe<IServiceRequestXAssetBoolExp>;
};


export type IQueryRootServiceRequestXAssetByPkArgs = {
  asset_id: Scalars['uuid']['input'];
  service_request_id: Scalars['Int']['input'];
};


export type IQueryRootServiceRequestXTeamArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestXTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestXTeamOrderBy>>;
  where?: InputMaybe<IServiceRequestXTeamBoolExp>;
};


export type IQueryRootServiceRequestXTeamAggregateArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestXTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestXTeamOrderBy>>;
  where?: InputMaybe<IServiceRequestXTeamBoolExp>;
};


export type IQueryRootServiceRequestXTeamByPkArgs = {
  service_request_id: Scalars['Int']['input'];
  team_id: Scalars['uuid']['input'];
};


export type IQueryRootServiceRequestXUploadArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestXUploadOrderBy>>;
  where?: InputMaybe<IServiceRequestXUploadBoolExp>;
};


export type IQueryRootServiceRequestXUploadAggregateArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestXUploadOrderBy>>;
  where?: InputMaybe<IServiceRequestXUploadBoolExp>;
};


export type IQueryRootServiceRequestXUploadByPkArgs = {
  service_request_id: Scalars['Int']['input'];
  upload_id: Scalars['uuid']['input'];
};


export type IQueryRootServiceRequestXUserArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestXUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestXUserOrderBy>>;
  where?: InputMaybe<IServiceRequestXUserBoolExp>;
};


export type IQueryRootServiceRequestXUserAggregateArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestXUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestXUserOrderBy>>;
  where?: InputMaybe<IServiceRequestXUserBoolExp>;
};


export type IQueryRootServiceRequestXUserByPkArgs = {
  service_request_id: Scalars['Int']['input'];
  user_id: Scalars['uuid']['input'];
};


export type IQueryRootSettingConfigArgs = {
  distinct_on?: InputMaybe<Array<ISettingConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ISettingConfigOrderBy>>;
  where?: InputMaybe<ISettingConfigBoolExp>;
};


export type IQueryRootSettingConfigAggregateArgs = {
  distinct_on?: InputMaybe<Array<ISettingConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ISettingConfigOrderBy>>;
  where?: InputMaybe<ISettingConfigBoolExp>;
};


export type IQueryRootSettingConfigByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootSettingLevelArgs = {
  distinct_on?: InputMaybe<Array<ISettingLevelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ISettingLevelOrderBy>>;
  where?: InputMaybe<ISettingLevelBoolExp>;
};


export type IQueryRootSettingLevelAggregateArgs = {
  distinct_on?: InputMaybe<Array<ISettingLevelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ISettingLevelOrderBy>>;
  where?: InputMaybe<ISettingLevelBoolExp>;
};


export type IQueryRootSettingLevelByPkArgs = {
  value: Scalars['String']['input'];
};


export type IQueryRootSettingTypeArgs = {
  distinct_on?: InputMaybe<Array<ISettingTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ISettingTypeOrderBy>>;
  where?: InputMaybe<ISettingTypeBoolExp>;
};


export type IQueryRootSettingTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<ISettingTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ISettingTypeOrderBy>>;
  where?: InputMaybe<ISettingTypeBoolExp>;
};


export type IQueryRootSettingTypeByPkArgs = {
  value: Scalars['String']['input'];
};


export type IQueryRootTeamArgs = {
  distinct_on?: InputMaybe<Array<ITeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ITeamOrderBy>>;
  where?: InputMaybe<ITeamBoolExp>;
};


export type IQueryRootTeamAggregateArgs = {
  distinct_on?: InputMaybe<Array<ITeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ITeamOrderBy>>;
  where?: InputMaybe<ITeamBoolExp>;
};


export type IQueryRootTeamByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootTeamMemberArgs = {
  distinct_on?: InputMaybe<Array<ITeamMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ITeamMemberOrderBy>>;
  where?: InputMaybe<ITeamMemberBoolExp>;
};


export type IQueryRootTeamMemberAggregateArgs = {
  distinct_on?: InputMaybe<Array<ITeamMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ITeamMemberOrderBy>>;
  where?: InputMaybe<ITeamMemberBoolExp>;
};


export type IQueryRootTeamMemberByPkArgs = {
  team_id: Scalars['uuid']['input'];
  user_id: Scalars['uuid']['input'];
};


export type IQueryRootTeamUserRoleArgs = {
  distinct_on?: InputMaybe<Array<ITeamUserRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ITeamUserRoleOrderBy>>;
  where?: InputMaybe<ITeamUserRoleBoolExp>;
};


export type IQueryRootTeamUserRoleAggregateArgs = {
  distinct_on?: InputMaybe<Array<ITeamUserRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ITeamUserRoleOrderBy>>;
  where?: InputMaybe<ITeamUserRoleBoolExp>;
};


export type IQueryRootTeamUserRoleByPkArgs = {
  value: Scalars['String']['input'];
};


export type IQueryRootUploadArgs = {
  distinct_on?: InputMaybe<Array<IUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUploadOrderBy>>;
  where?: InputMaybe<IUploadBoolExp>;
};


export type IQueryRootUploadAggregateArgs = {
  distinct_on?: InputMaybe<Array<IUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUploadOrderBy>>;
  where?: InputMaybe<IUploadBoolExp>;
};


export type IQueryRootUploadByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootUploadCategoryArgs = {
  distinct_on?: InputMaybe<Array<IUploadCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUploadCategoryOrderBy>>;
  where?: InputMaybe<IUploadCategoryBoolExp>;
};


export type IQueryRootUploadCategoryAggregateArgs = {
  distinct_on?: InputMaybe<Array<IUploadCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUploadCategoryOrderBy>>;
  where?: InputMaybe<IUploadCategoryBoolExp>;
};


export type IQueryRootUploadCategoryByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootUploadXUploadCategoryArgs = {
  distinct_on?: InputMaybe<Array<IUploadXUploadCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUploadXUploadCategoryOrderBy>>;
  where?: InputMaybe<IUploadXUploadCategoryBoolExp>;
};


export type IQueryRootUploadXUploadCategoryAggregateArgs = {
  distinct_on?: InputMaybe<Array<IUploadXUploadCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUploadXUploadCategoryOrderBy>>;
  where?: InputMaybe<IUploadXUploadCategoryBoolExp>;
};


export type IQueryRootUploadXUploadCategoryByPkArgs = {
  upload_category_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};


export type IQueryRootUserArgs = {
  distinct_on?: InputMaybe<Array<IUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUserOrderBy>>;
  where?: InputMaybe<IUserBoolExp>;
};


export type IQueryRootUserAggregateArgs = {
  distinct_on?: InputMaybe<Array<IUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUserOrderBy>>;
  where?: InputMaybe<IUserBoolExp>;
};


export type IQueryRootUserByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootUserFavoriteArgs = {
  distinct_on?: InputMaybe<Array<IUserFavoriteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUserFavoriteOrderBy>>;
  where?: InputMaybe<IUserFavoriteBoolExp>;
};


export type IQueryRootUserFavoriteAggregateArgs = {
  distinct_on?: InputMaybe<Array<IUserFavoriteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUserFavoriteOrderBy>>;
  where?: InputMaybe<IUserFavoriteBoolExp>;
};


export type IQueryRootUserFavoriteByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootUserRecentlyUsedArgs = {
  distinct_on?: InputMaybe<Array<IUserRecentlyUsedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUserRecentlyUsedOrderBy>>;
  where?: InputMaybe<IUserRecentlyUsedBoolExp>;
};


export type IQueryRootUserRecentlyUsedAggregateArgs = {
  distinct_on?: InputMaybe<Array<IUserRecentlyUsedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUserRecentlyUsedOrderBy>>;
  where?: InputMaybe<IUserRecentlyUsedBoolExp>;
};


export type IQueryRootUserRecentlyUsedByPkArgs = {
  entity_id: Scalars['uuid']['input'];
  entity_type: Scalars['String']['input'];
  user_id: Scalars['uuid']['input'];
};


export type IQueryRootViewCollectionArgs = {
  distinct_on?: InputMaybe<Array<IViewCollectionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IViewCollectionOrderBy>>;
  where?: InputMaybe<IViewCollectionBoolExp>;
};


export type IQueryRootViewCollectionAggregateArgs = {
  distinct_on?: InputMaybe<Array<IViewCollectionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IViewCollectionOrderBy>>;
  where?: InputMaybe<IViewCollectionBoolExp>;
};


export type IQueryRootViewCollectionByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootViewConfigArgs = {
  distinct_on?: InputMaybe<Array<IViewConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IViewConfigOrderBy>>;
  where?: InputMaybe<IViewConfigBoolExp>;
};


export type IQueryRootViewConfigAggregateArgs = {
  distinct_on?: InputMaybe<Array<IViewConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IViewConfigOrderBy>>;
  where?: InputMaybe<IViewConfigBoolExp>;
};


export type IQueryRootViewConfigByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootViewDataTypeArgs = {
  distinct_on?: InputMaybe<Array<IViewDataTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IViewDataTypeOrderBy>>;
  where?: InputMaybe<IViewDataTypeBoolExp>;
};


export type IQueryRootViewDataTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<IViewDataTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IViewDataTypeOrderBy>>;
  where?: InputMaybe<IViewDataTypeBoolExp>;
};


export type IQueryRootViewDataTypeByPkArgs = {
  value: Scalars['String']['input'];
};


export type IQueryRootWorkOrderArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOrderBy>>;
  where?: InputMaybe<IWorkOrderBoolExp>;
};


export type IQueryRootWorkOrderActivityArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderActivityOrderBy>>;
  where?: InputMaybe<IWorkOrderActivityBoolExp>;
};


export type IQueryRootWorkOrderActivityAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderActivityOrderBy>>;
  where?: InputMaybe<IWorkOrderActivityBoolExp>;
};


export type IQueryRootWorkOrderActivityByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootWorkOrderActivityTypeArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderActivityTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderActivityTypeOrderBy>>;
  where?: InputMaybe<IWorkOrderActivityTypeBoolExp>;
};


export type IQueryRootWorkOrderActivityTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderActivityTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderActivityTypeOrderBy>>;
  where?: InputMaybe<IWorkOrderActivityTypeBoolExp>;
};


export type IQueryRootWorkOrderActivityTypeByPkArgs = {
  value: Scalars['String']['input'];
};


export type IQueryRootWorkOrderAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOrderBy>>;
  where?: InputMaybe<IWorkOrderBoolExp>;
};


export type IQueryRootWorkOrderByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootWorkOrderCategoryArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderCategoryOrderBy>>;
  where?: InputMaybe<IWorkOrderCategoryBoolExp>;
};


export type IQueryRootWorkOrderCategoryAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderCategoryOrderBy>>;
  where?: InputMaybe<IWorkOrderCategoryBoolExp>;
};


export type IQueryRootWorkOrderCategoryByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootWorkOrderCompletedByCategoryViewArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderCompletedByCategoryViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderCompletedByCategoryViewOrderBy>>;
  where?: InputMaybe<IWorkOrderCompletedByCategoryViewBoolExp>;
};


export type IQueryRootWorkOrderCompletedByCategoryViewAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderCompletedByCategoryViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderCompletedByCategoryViewOrderBy>>;
  where?: InputMaybe<IWorkOrderCompletedByCategoryViewBoolExp>;
};


export type IQueryRootWorkOrderCompletedOntimeVsOverdueArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderCompletedOntimeVsOverdueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderCompletedOntimeVsOverdueOrderBy>>;
  where?: InputMaybe<IWorkOrderCompletedOntimeVsOverdueBoolExp>;
};


export type IQueryRootWorkOrderCompletedOntimeVsOverdueAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderCompletedOntimeVsOverdueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderCompletedOntimeVsOverdueOrderBy>>;
  where?: InputMaybe<IWorkOrderCompletedOntimeVsOverdueBoolExp>;
};


export type IQueryRootWorkOrderExpenseArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderExpenseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderExpenseOrderBy>>;
  where?: InputMaybe<IWorkOrderExpenseBoolExp>;
};


export type IQueryRootWorkOrderExpenseAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderExpenseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderExpenseOrderBy>>;
  where?: InputMaybe<IWorkOrderExpenseBoolExp>;
};


export type IQueryRootWorkOrderExpenseByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootWorkOrderFeedbackArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderFeedbackSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderFeedbackOrderBy>>;
  where?: InputMaybe<IWorkOrderFeedbackBoolExp>;
};


export type IQueryRootWorkOrderFeedbackAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderFeedbackSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderFeedbackOrderBy>>;
  where?: InputMaybe<IWorkOrderFeedbackBoolExp>;
};


export type IQueryRootWorkOrderFeedbackByPkArgs = {
  work_order_id: Scalars['uuid']['input'];
};


export type IQueryRootWorkOrderLastUpdateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderLastUpdateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderLastUpdateOrderBy>>;
  where?: InputMaybe<IWorkOrderLastUpdateBoolExp>;
};


export type IQueryRootWorkOrderLastUpdateAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderLastUpdateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderLastUpdateOrderBy>>;
  where?: InputMaybe<IWorkOrderLastUpdateBoolExp>;
};


export type IQueryRootWorkOrderOpenGroupedByDueDateViewArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderOpenGroupedByDueDateViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOpenGroupedByDueDateViewOrderBy>>;
  where?: InputMaybe<IWorkOrderOpenGroupedByDueDateViewBoolExp>;
};


export type IQueryRootWorkOrderOpenGroupedByDueDateViewAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderOpenGroupedByDueDateViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOpenGroupedByDueDateViewOrderBy>>;
  where?: InputMaybe<IWorkOrderOpenGroupedByDueDateViewBoolExp>;
};


export type IQueryRootWorkOrderPriorityArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderPrioritySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderPriorityOrderBy>>;
  where?: InputMaybe<IWorkOrderPriorityBoolExp>;
};


export type IQueryRootWorkOrderPriorityAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderPrioritySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderPriorityOrderBy>>;
  where?: InputMaybe<IWorkOrderPriorityBoolExp>;
};


export type IQueryRootWorkOrderPriorityByPkArgs = {
  value: Scalars['String']['input'];
};


export type IQueryRootWorkOrderRecurringViewArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderRecurringViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderRecurringViewOrderBy>>;
  where?: InputMaybe<IWorkOrderRecurringViewBoolExp>;
};


export type IQueryRootWorkOrderRecurringViewAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderRecurringViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderRecurringViewOrderBy>>;
  where?: InputMaybe<IWorkOrderRecurringViewBoolExp>;
};


export type IQueryRootWorkOrderReportArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderReportSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderReportOrderBy>>;
  where?: InputMaybe<IWorkOrderReportBoolExp>;
};


export type IQueryRootWorkOrderReportAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderReportSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderReportOrderBy>>;
  where?: InputMaybe<IWorkOrderReportBoolExp>;
};


export type IQueryRootWorkOrderReportByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootWorkOrderStatusArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderStatusOrderBy>>;
  where?: InputMaybe<IWorkOrderStatusBoolExp>;
};


export type IQueryRootWorkOrderStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderStatusOrderBy>>;
  where?: InputMaybe<IWorkOrderStatusBoolExp>;
};


export type IQueryRootWorkOrderStatusByPkArgs = {
  value: Scalars['String']['input'];
};


export type IQueryRootWorkOrderTemplateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderTemplateOrderBy>>;
  where?: InputMaybe<IWorkOrderTemplateBoolExp>;
};


export type IQueryRootWorkOrderTemplateAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderTemplateOrderBy>>;
  where?: InputMaybe<IWorkOrderTemplateBoolExp>;
};


export type IQueryRootWorkOrderTemplateByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type IQueryRootWorkOrderTypeArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderTypeOrderBy>>;
  where?: InputMaybe<IWorkOrderTypeBoolExp>;
};


export type IQueryRootWorkOrderTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderTypeOrderBy>>;
  where?: InputMaybe<IWorkOrderTypeBoolExp>;
};


export type IQueryRootWorkOrderTypeByPkArgs = {
  value: Scalars['String']['input'];
};


export type IQueryRootWorkOrderWithLastRecurringInstanceViewArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderWithLastRecurringInstanceViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderWithLastRecurringInstanceViewOrderBy>>;
  where?: InputMaybe<IWorkOrderWithLastRecurringInstanceViewBoolExp>;
};


export type IQueryRootWorkOrderWithLastRecurringInstanceViewAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderWithLastRecurringInstanceViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderWithLastRecurringInstanceViewOrderBy>>;
  where?: InputMaybe<IWorkOrderWithLastRecurringInstanceViewBoolExp>;
};


export type IQueryRootWorkOrderWithOpenDueDateReminderArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderWithOpenDueDateReminderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderWithOpenDueDateReminderOrderBy>>;
  where?: InputMaybe<IWorkOrderWithOpenDueDateReminderBoolExp>;
};


export type IQueryRootWorkOrderWithOpenDueDateReminderAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderWithOpenDueDateReminderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderWithOpenDueDateReminderOrderBy>>;
  where?: InputMaybe<IWorkOrderWithOpenDueDateReminderBoolExp>;
};


export type IQueryRootWorkOrderXCollaboratorArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXCollaboratorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXCollaboratorOrderBy>>;
  where?: InputMaybe<IWorkOrderXCollaboratorBoolExp>;
};


export type IQueryRootWorkOrderXCollaboratorAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXCollaboratorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXCollaboratorOrderBy>>;
  where?: InputMaybe<IWorkOrderXCollaboratorBoolExp>;
};


export type IQueryRootWorkOrderXCollaboratorByPkArgs = {
  user_id: Scalars['uuid']['input'];
  work_order_id: Scalars['uuid']['input'];
};


export type IQueryRootWorkOrderXTeamArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXTeamOrderBy>>;
  where?: InputMaybe<IWorkOrderXTeamBoolExp>;
};


export type IQueryRootWorkOrderXTeamAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXTeamOrderBy>>;
  where?: InputMaybe<IWorkOrderXTeamBoolExp>;
};


export type IQueryRootWorkOrderXTeamByPkArgs = {
  team_id: Scalars['uuid']['input'];
  work_order_id: Scalars['uuid']['input'];
};


export type IQueryRootWorkOrderXUploadArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXUploadOrderBy>>;
  where?: InputMaybe<IWorkOrderXUploadBoolExp>;
};


export type IQueryRootWorkOrderXUploadAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXUploadOrderBy>>;
  where?: InputMaybe<IWorkOrderXUploadBoolExp>;
};


export type IQueryRootWorkOrderXUploadByPkArgs = {
  upload_id: Scalars['uuid']['input'];
  work_order_id: Scalars['uuid']['input'];
};


export type IQueryRootWorkOrderXUserArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXUserOrderBy>>;
  where?: InputMaybe<IWorkOrderXUserBoolExp>;
};


export type IQueryRootWorkOrderXUserAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXUserOrderBy>>;
  where?: InputMaybe<IWorkOrderXUserBoolExp>;
};


export type IQueryRootWorkOrderXUserByPkArgs = {
  user_id: Scalars['uuid']['input'];
  work_order_id: Scalars['uuid']['input'];
};


export type IQueryRootWorkOrderXWorkOrderCategoryArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXWorkOrderCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXWorkOrderCategoryOrderBy>>;
  where?: InputMaybe<IWorkOrderXWorkOrderCategoryBoolExp>;
};


export type IQueryRootWorkOrderXWorkOrderCategoryAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXWorkOrderCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXWorkOrderCategoryOrderBy>>;
  where?: InputMaybe<IWorkOrderXWorkOrderCategoryBoolExp>;
};


export type IQueryRootWorkOrderXWorkOrderCategoryByPkArgs = {
  work_order_category_id: Scalars['uuid']['input'];
  work_order_id: Scalars['uuid']['input'];
};


export type IQueryRootWorkOrdersNeedingDueDateReminderArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOrderBy>>;
  where?: InputMaybe<IWorkOrderBoolExp>;
};


export type IQueryRootWorkOrdersNeedingDueDateReminderAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOrderBy>>;
  where?: InputMaybe<IWorkOrderBoolExp>;
};

/** columns and relationships of "recurrence" */
export type IRecurrence = {
  __typename: 'recurrence';
  created_at: Scalars['timestamptz']['output'];
  day?: Maybe<Scalars['Int']['output']>;
  id: Scalars['uuid']['output'];
  interval: Scalars['Int']['output'];
  recurrence_pattern: IRecurrencePatternEnum;
  updated_at: Scalars['timestamptz']['output'];
  weekdays: Scalars['jsonb']['output'];
  /** An object relationship */
  work_order?: Maybe<IWorkOrder>;
};


/** columns and relationships of "recurrence" */
export type IRecurrenceWeekdaysArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "recurrence" */
export type IRecurrenceAggregate = {
  __typename: 'recurrence_aggregate';
  aggregate?: Maybe<IRecurrenceAggregateFields>;
  nodes: Array<IRecurrence>;
};

/** aggregate fields of "recurrence" */
export type IRecurrenceAggregateFields = {
  __typename: 'recurrence_aggregate_fields';
  avg?: Maybe<IRecurrenceAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IRecurrenceMaxFields>;
  min?: Maybe<IRecurrenceMinFields>;
  stddev?: Maybe<IRecurrenceStddevFields>;
  stddev_pop?: Maybe<IRecurrenceStddevPopFields>;
  stddev_samp?: Maybe<IRecurrenceStddevSampFields>;
  sum?: Maybe<IRecurrenceSumFields>;
  var_pop?: Maybe<IRecurrenceVarPopFields>;
  var_samp?: Maybe<IRecurrenceVarSampFields>;
  variance?: Maybe<IRecurrenceVarianceFields>;
};


/** aggregate fields of "recurrence" */
export type IRecurrenceAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IRecurrenceSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IRecurrenceAppendInput = {
  weekdays?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type IRecurrenceAvgFields = {
  __typename: 'recurrence_avg_fields';
  day?: Maybe<Scalars['Float']['output']>;
  interval?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "recurrence". All fields are combined with a logical 'AND'. */
export type IRecurrenceBoolExp = {
  _and?: InputMaybe<Array<IRecurrenceBoolExp>>;
  _not?: InputMaybe<IRecurrenceBoolExp>;
  _or?: InputMaybe<Array<IRecurrenceBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  day?: InputMaybe<IIntComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  interval?: InputMaybe<IIntComparisonExp>;
  recurrence_pattern?: InputMaybe<IRecurrencePatternEnumComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  weekdays?: InputMaybe<IJsonbComparisonExp>;
  work_order?: InputMaybe<IWorkOrderBoolExp>;
};

/** unique or primary key constraints on table "recurrence" */
export enum IRecurrenceConstraint {
  /** unique or primary key constraint on columns "id" */
  RecurrencePkey = 'recurrence_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IRecurrenceDeleteAtPathInput = {
  weekdays?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IRecurrenceDeleteElemInput = {
  weekdays?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IRecurrenceDeleteKeyInput = {
  weekdays?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "recurrence" */
export type IRecurrenceIncInput = {
  day?: InputMaybe<Scalars['Int']['input']>;
  interval?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "recurrence" */
export type IRecurrenceInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  day?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  interval?: InputMaybe<Scalars['Int']['input']>;
  recurrence_pattern?: InputMaybe<IRecurrencePatternEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  weekdays?: InputMaybe<Scalars['jsonb']['input']>;
  work_order?: InputMaybe<IWorkOrderObjRelInsertInput>;
};

/** aggregate max on columns */
export type IRecurrenceMaxFields = {
  __typename: 'recurrence_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  day?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  interval?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type IRecurrenceMinFields = {
  __typename: 'recurrence_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  day?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  interval?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "recurrence" */
export type IRecurrenceMutationResponse = {
  __typename: 'recurrence_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IRecurrence>;
};

/** input type for inserting object relation for remote table "recurrence" */
export type IRecurrenceObjRelInsertInput = {
  data: IRecurrenceInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IRecurrenceOnConflict>;
};

/** on_conflict condition type for table "recurrence" */
export type IRecurrenceOnConflict = {
  constraint: IRecurrenceConstraint;
  update_columns?: Array<IRecurrenceUpdateColumn>;
  where?: InputMaybe<IRecurrenceBoolExp>;
};

/** Ordering options when selecting data from "recurrence". */
export type IRecurrenceOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  day?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  interval?: InputMaybe<IOrderBy>;
  recurrence_pattern?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  weekdays?: InputMaybe<IOrderBy>;
  work_order?: InputMaybe<IWorkOrderOrderBy>;
};

/** columns and relationships of "recurrence_pattern" */
export type IRecurrencePattern = {
  __typename: 'recurrence_pattern';
  value: Scalars['String']['output'];
};

/** aggregated selection of "recurrence_pattern" */
export type IRecurrencePatternAggregate = {
  __typename: 'recurrence_pattern_aggregate';
  aggregate?: Maybe<IRecurrencePatternAggregateFields>;
  nodes: Array<IRecurrencePattern>;
};

/** aggregate fields of "recurrence_pattern" */
export type IRecurrencePatternAggregateFields = {
  __typename: 'recurrence_pattern_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IRecurrencePatternMaxFields>;
  min?: Maybe<IRecurrencePatternMinFields>;
};


/** aggregate fields of "recurrence_pattern" */
export type IRecurrencePatternAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IRecurrencePatternSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "recurrence_pattern". All fields are combined with a logical 'AND'. */
export type IRecurrencePatternBoolExp = {
  _and?: InputMaybe<Array<IRecurrencePatternBoolExp>>;
  _not?: InputMaybe<IRecurrencePatternBoolExp>;
  _or?: InputMaybe<Array<IRecurrencePatternBoolExp>>;
  value?: InputMaybe<IStringComparisonExp>;
};

/** unique or primary key constraints on table "recurrence_pattern" */
export enum IRecurrencePatternConstraint {
  /** unique or primary key constraint on columns "value" */
  RecurrencePatternPkey = 'recurrence_pattern_pkey'
}

export enum IRecurrencePatternEnum {
  Daily = 'daily',
  Hourly = 'hourly',
  Monthly = 'monthly',
  Weekly = 'weekly',
  Yearly = 'yearly'
}

/** Boolean expression to compare columns of type "recurrence_pattern_enum". All fields are combined with logical 'AND'. */
export type IRecurrencePatternEnumComparisonExp = {
  _eq?: InputMaybe<IRecurrencePatternEnum>;
  _in?: InputMaybe<Array<IRecurrencePatternEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<IRecurrencePatternEnum>;
  _nin?: InputMaybe<Array<IRecurrencePatternEnum>>;
};

/** input type for inserting data into table "recurrence_pattern" */
export type IRecurrencePatternInsertInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type IRecurrencePatternMaxFields = {
  __typename: 'recurrence_pattern_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IRecurrencePatternMinFields = {
  __typename: 'recurrence_pattern_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "recurrence_pattern" */
export type IRecurrencePatternMutationResponse = {
  __typename: 'recurrence_pattern_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IRecurrencePattern>;
};

/** on_conflict condition type for table "recurrence_pattern" */
export type IRecurrencePatternOnConflict = {
  constraint: IRecurrencePatternConstraint;
  update_columns?: Array<IRecurrencePatternUpdateColumn>;
  where?: InputMaybe<IRecurrencePatternBoolExp>;
};

/** Ordering options when selecting data from "recurrence_pattern". */
export type IRecurrencePatternOrderBy = {
  value?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: recurrence_pattern */
export type IRecurrencePatternPkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "recurrence_pattern" */
export enum IRecurrencePatternSelectColumn {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "recurrence_pattern" */
export type IRecurrencePatternSetInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "recurrence_pattern" */
export type IRecurrencePatternStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IRecurrencePatternStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IRecurrencePatternStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "recurrence_pattern" */
export enum IRecurrencePatternUpdateColumn {
  /** column name */
  Value = 'value'
}

export type IRecurrencePatternUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IRecurrencePatternSetInput>;
  /** filter the rows which have to be updated */
  where: IRecurrencePatternBoolExp;
};

/** primary key columns input for table: recurrence */
export type IRecurrencePkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IRecurrencePrependInput = {
  weekdays?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "recurrence" */
export enum IRecurrenceSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Day = 'day',
  /** column name */
  Id = 'id',
  /** column name */
  Interval = 'interval',
  /** column name */
  RecurrencePattern = 'recurrence_pattern',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Weekdays = 'weekdays'
}

/** input type for updating data in table "recurrence" */
export type IRecurrenceSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  day?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  interval?: InputMaybe<Scalars['Int']['input']>;
  recurrence_pattern?: InputMaybe<IRecurrencePatternEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  weekdays?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate stddev on columns */
export type IRecurrenceStddevFields = {
  __typename: 'recurrence_stddev_fields';
  day?: Maybe<Scalars['Float']['output']>;
  interval?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type IRecurrenceStddevPopFields = {
  __typename: 'recurrence_stddev_pop_fields';
  day?: Maybe<Scalars['Float']['output']>;
  interval?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type IRecurrenceStddevSampFields = {
  __typename: 'recurrence_stddev_samp_fields';
  day?: Maybe<Scalars['Float']['output']>;
  interval?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "recurrence" */
export type IRecurrenceStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IRecurrenceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IRecurrenceStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  day?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  interval?: InputMaybe<Scalars['Int']['input']>;
  recurrence_pattern?: InputMaybe<IRecurrencePatternEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  weekdays?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate sum on columns */
export type IRecurrenceSumFields = {
  __typename: 'recurrence_sum_fields';
  day?: Maybe<Scalars['Int']['output']>;
  interval?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "recurrence" */
export enum IRecurrenceUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Day = 'day',
  /** column name */
  Id = 'id',
  /** column name */
  Interval = 'interval',
  /** column name */
  RecurrencePattern = 'recurrence_pattern',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Weekdays = 'weekdays'
}

export type IRecurrenceUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IRecurrenceAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IRecurrenceDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IRecurrenceDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IRecurrenceDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IRecurrenceIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IRecurrencePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IRecurrenceSetInput>;
  /** filter the rows which have to be updated */
  where: IRecurrenceBoolExp;
};

/** aggregate var_pop on columns */
export type IRecurrenceVarPopFields = {
  __typename: 'recurrence_var_pop_fields';
  day?: Maybe<Scalars['Float']['output']>;
  interval?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type IRecurrenceVarSampFields = {
  __typename: 'recurrence_var_samp_fields';
  day?: Maybe<Scalars['Float']['output']>;
  interval?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type IRecurrenceVarianceFields = {
  __typename: 'recurrence_variance_fields';
  day?: Maybe<Scalars['Float']['output']>;
  interval?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "scheduled_task" */
export type IScheduledTask = {
  __typename: 'scheduled_task';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  meter: IMeter;
  meter_id: Scalars['uuid']['output'];
  scheduled_by_meter_reading_id: Scalars['uuid']['output'];
  scheduled_on?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  task: IWorkOrder;
  task_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "scheduled_task" */
export type IScheduledTaskMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "scheduled_task" */
export type IScheduledTaskAggregate = {
  __typename: 'scheduled_task_aggregate';
  aggregate?: Maybe<IScheduledTaskAggregateFields>;
  nodes: Array<IScheduledTask>;
};

/** aggregate fields of "scheduled_task" */
export type IScheduledTaskAggregateFields = {
  __typename: 'scheduled_task_aggregate_fields';
  avg?: Maybe<IScheduledTaskAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IScheduledTaskMaxFields>;
  min?: Maybe<IScheduledTaskMinFields>;
  stddev?: Maybe<IScheduledTaskStddevFields>;
  stddev_pop?: Maybe<IScheduledTaskStddevPopFields>;
  stddev_samp?: Maybe<IScheduledTaskStddevSampFields>;
  sum?: Maybe<IScheduledTaskSumFields>;
  var_pop?: Maybe<IScheduledTaskVarPopFields>;
  var_samp?: Maybe<IScheduledTaskVarSampFields>;
  variance?: Maybe<IScheduledTaskVarianceFields>;
};


/** aggregate fields of "scheduled_task" */
export type IScheduledTaskAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IScheduledTaskSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IScheduledTaskAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type IScheduledTaskAvgFields = {
  __typename: 'scheduled_task_avg_fields';
  scheduled_on?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "scheduled_task". All fields are combined with a logical 'AND'. */
export type IScheduledTaskBoolExp = {
  _and?: InputMaybe<Array<IScheduledTaskBoolExp>>;
  _not?: InputMaybe<IScheduledTaskBoolExp>;
  _or?: InputMaybe<Array<IScheduledTaskBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  metadata?: InputMaybe<IJsonbComparisonExp>;
  meter?: InputMaybe<IMeterBoolExp>;
  meter_id?: InputMaybe<IUuidComparisonExp>;
  scheduled_by_meter_reading_id?: InputMaybe<IUuidComparisonExp>;
  scheduled_on?: InputMaybe<INumericComparisonExp>;
  task?: InputMaybe<IWorkOrderBoolExp>;
  task_id?: InputMaybe<IUuidComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "scheduled_task" */
export enum IScheduledTaskConstraint {
  /** unique or primary key constraint on columns "id" */
  ScheduledTaskPkey = 'scheduled_task_pkey',
  /** unique or primary key constraint on columns "scheduled_by_meter_reading_id" */
  ScheduledTaskScheduledByMeterReadingIdKey = 'scheduled_task_scheduled_by_meter_reading_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IScheduledTaskDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IScheduledTaskDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IScheduledTaskDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "scheduled_task" */
export type IScheduledTaskIncInput = {
  scheduled_on?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "scheduled_task" */
export type IScheduledTaskInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  meter?: InputMaybe<IMeterObjRelInsertInput>;
  meter_id?: InputMaybe<Scalars['uuid']['input']>;
  scheduled_by_meter_reading_id?: InputMaybe<Scalars['uuid']['input']>;
  scheduled_on?: InputMaybe<Scalars['numeric']['input']>;
  task?: InputMaybe<IWorkOrderObjRelInsertInput>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type IScheduledTaskMaxFields = {
  __typename: 'scheduled_task_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  meter_id?: Maybe<Scalars['uuid']['output']>;
  scheduled_by_meter_reading_id?: Maybe<Scalars['uuid']['output']>;
  scheduled_on?: Maybe<Scalars['numeric']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type IScheduledTaskMinFields = {
  __typename: 'scheduled_task_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  meter_id?: Maybe<Scalars['uuid']['output']>;
  scheduled_by_meter_reading_id?: Maybe<Scalars['uuid']['output']>;
  scheduled_on?: Maybe<Scalars['numeric']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "scheduled_task" */
export type IScheduledTaskMutationResponse = {
  __typename: 'scheduled_task_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IScheduledTask>;
};

/** on_conflict condition type for table "scheduled_task" */
export type IScheduledTaskOnConflict = {
  constraint: IScheduledTaskConstraint;
  update_columns?: Array<IScheduledTaskUpdateColumn>;
  where?: InputMaybe<IScheduledTaskBoolExp>;
};

/** Ordering options when selecting data from "scheduled_task". */
export type IScheduledTaskOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  metadata?: InputMaybe<IOrderBy>;
  meter?: InputMaybe<IMeterOrderBy>;
  meter_id?: InputMaybe<IOrderBy>;
  scheduled_by_meter_reading_id?: InputMaybe<IOrderBy>;
  scheduled_on?: InputMaybe<IOrderBy>;
  task?: InputMaybe<IWorkOrderOrderBy>;
  task_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: scheduled_task */
export type IScheduledTaskPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IScheduledTaskPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "scheduled_task" */
export enum IScheduledTaskSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MeterId = 'meter_id',
  /** column name */
  ScheduledByMeterReadingId = 'scheduled_by_meter_reading_id',
  /** column name */
  ScheduledOn = 'scheduled_on',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "scheduled_task" */
export type IScheduledTaskSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  meter_id?: InputMaybe<Scalars['uuid']['input']>;
  scheduled_by_meter_reading_id?: InputMaybe<Scalars['uuid']['input']>;
  scheduled_on?: InputMaybe<Scalars['numeric']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type IScheduledTaskStddevFields = {
  __typename: 'scheduled_task_stddev_fields';
  scheduled_on?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type IScheduledTaskStddevPopFields = {
  __typename: 'scheduled_task_stddev_pop_fields';
  scheduled_on?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type IScheduledTaskStddevSampFields = {
  __typename: 'scheduled_task_stddev_samp_fields';
  scheduled_on?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "scheduled_task" */
export type IScheduledTaskStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IScheduledTaskStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IScheduledTaskStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  meter_id?: InputMaybe<Scalars['uuid']['input']>;
  scheduled_by_meter_reading_id?: InputMaybe<Scalars['uuid']['input']>;
  scheduled_on?: InputMaybe<Scalars['numeric']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type IScheduledTaskSumFields = {
  __typename: 'scheduled_task_sum_fields';
  scheduled_on?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "scheduled_task" */
export enum IScheduledTaskUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MeterId = 'meter_id',
  /** column name */
  ScheduledByMeterReadingId = 'scheduled_by_meter_reading_id',
  /** column name */
  ScheduledOn = 'scheduled_on',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type IScheduledTaskUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IScheduledTaskAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IScheduledTaskDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IScheduledTaskDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IScheduledTaskDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IScheduledTaskIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IScheduledTaskPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IScheduledTaskSetInput>;
  /** filter the rows which have to be updated */
  where: IScheduledTaskBoolExp;
};

/** aggregate var_pop on columns */
export type IScheduledTaskVarPopFields = {
  __typename: 'scheduled_task_var_pop_fields';
  scheduled_on?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type IScheduledTaskVarSampFields = {
  __typename: 'scheduled_task_var_samp_fields';
  scheduled_on?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type IScheduledTaskVarianceFields = {
  __typename: 'scheduled_task_variance_fields';
  scheduled_on?: Maybe<Scalars['Float']['output']>;
};

export type ISearchAssetArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ISearchMaintenanceArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ISearchWorkOrderArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};

/** A table of service requests, to be managed by service technicians and finally approved to actionable tasks. */
export type IServiceRequest = {
  __typename: 'service_request';
  /** An array relationship */
  assets: Array<IServiceRequestXAsset>;
  /** An aggregate relationship */
  assets_aggregate: IServiceRequestXAssetAggregate;
  /** An array relationship */
  assigned_teams: Array<IServiceRequestXTeam>;
  /** An aggregate relationship */
  assigned_teams_aggregate: IServiceRequestXTeamAggregate;
  /** An array relationship */
  assignees: Array<IServiceRequestXUser>;
  /** An aggregate relationship */
  assignees_aggregate: IServiceRequestXUserAggregate;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by: IUser;
  created_by_id: Scalars['uuid']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  location_id: Scalars['uuid']['output'];
  priority?: Maybe<IWorkOrderPriorityEnum>;
  status: IServiceRequestStatusEnum;
  /** An array relationship */
  tasks: Array<IWorkOrder>;
  /** An aggregate relationship */
  tasks_aggregate: IWorkOrderAggregate;
  title: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  uploads: Array<IServiceRequestXUpload>;
  /** An aggregate relationship */
  uploads_aggregate: IServiceRequestXUploadAggregate;
};


/** A table of service requests, to be managed by service technicians and finally approved to actionable tasks. */
export type IServiceRequestAssetsArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestXAssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestXAssetOrderBy>>;
  where?: InputMaybe<IServiceRequestXAssetBoolExp>;
};


/** A table of service requests, to be managed by service technicians and finally approved to actionable tasks. */
export type IServiceRequestAssetsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestXAssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestXAssetOrderBy>>;
  where?: InputMaybe<IServiceRequestXAssetBoolExp>;
};


/** A table of service requests, to be managed by service technicians and finally approved to actionable tasks. */
export type IServiceRequestAssignedTeamsArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestXTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestXTeamOrderBy>>;
  where?: InputMaybe<IServiceRequestXTeamBoolExp>;
};


/** A table of service requests, to be managed by service technicians and finally approved to actionable tasks. */
export type IServiceRequestAssignedTeamsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestXTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestXTeamOrderBy>>;
  where?: InputMaybe<IServiceRequestXTeamBoolExp>;
};


/** A table of service requests, to be managed by service technicians and finally approved to actionable tasks. */
export type IServiceRequestAssigneesArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestXUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestXUserOrderBy>>;
  where?: InputMaybe<IServiceRequestXUserBoolExp>;
};


/** A table of service requests, to be managed by service technicians and finally approved to actionable tasks. */
export type IServiceRequestAssigneesAggregateArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestXUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestXUserOrderBy>>;
  where?: InputMaybe<IServiceRequestXUserBoolExp>;
};


/** A table of service requests, to be managed by service technicians and finally approved to actionable tasks. */
export type IServiceRequestTasksArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOrderBy>>;
  where?: InputMaybe<IWorkOrderBoolExp>;
};


/** A table of service requests, to be managed by service technicians and finally approved to actionable tasks. */
export type IServiceRequestTasksAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOrderBy>>;
  where?: InputMaybe<IWorkOrderBoolExp>;
};


/** A table of service requests, to be managed by service technicians and finally approved to actionable tasks. */
export type IServiceRequestUploadsArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestXUploadOrderBy>>;
  where?: InputMaybe<IServiceRequestXUploadBoolExp>;
};


/** A table of service requests, to be managed by service technicians and finally approved to actionable tasks. */
export type IServiceRequestUploadsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestXUploadOrderBy>>;
  where?: InputMaybe<IServiceRequestXUploadBoolExp>;
};

/** aggregated selection of "service_request" */
export type IServiceRequestAggregate = {
  __typename: 'service_request_aggregate';
  aggregate?: Maybe<IServiceRequestAggregateFields>;
  nodes: Array<IServiceRequest>;
};

export type IServiceRequestAggregateBoolExp = {
  count?: InputMaybe<IServiceRequestAggregateBoolExpCount>;
};

export type IServiceRequestAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IServiceRequestSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IServiceRequestBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "service_request" */
export type IServiceRequestAggregateFields = {
  __typename: 'service_request_aggregate_fields';
  avg?: Maybe<IServiceRequestAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IServiceRequestMaxFields>;
  min?: Maybe<IServiceRequestMinFields>;
  stddev?: Maybe<IServiceRequestStddevFields>;
  stddev_pop?: Maybe<IServiceRequestStddevPopFields>;
  stddev_samp?: Maybe<IServiceRequestStddevSampFields>;
  sum?: Maybe<IServiceRequestSumFields>;
  var_pop?: Maybe<IServiceRequestVarPopFields>;
  var_samp?: Maybe<IServiceRequestVarSampFields>;
  variance?: Maybe<IServiceRequestVarianceFields>;
};


/** aggregate fields of "service_request" */
export type IServiceRequestAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IServiceRequestSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "service_request" */
export type IServiceRequestAggregateOrderBy = {
  avg?: InputMaybe<IServiceRequestAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IServiceRequestMaxOrderBy>;
  min?: InputMaybe<IServiceRequestMinOrderBy>;
  stddev?: InputMaybe<IServiceRequestStddevOrderBy>;
  stddev_pop?: InputMaybe<IServiceRequestStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IServiceRequestStddevSampOrderBy>;
  sum?: InputMaybe<IServiceRequestSumOrderBy>;
  var_pop?: InputMaybe<IServiceRequestVarPopOrderBy>;
  var_samp?: InputMaybe<IServiceRequestVarSampOrderBy>;
  variance?: InputMaybe<IServiceRequestVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "service_request" */
export type IServiceRequestArrRelInsertInput = {
  data: Array<IServiceRequestInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IServiceRequestOnConflict>;
};

/** aggregate avg on columns */
export type IServiceRequestAvgFields = {
  __typename: 'service_request_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "service_request" */
export type IServiceRequestAvgOrderBy = {
  id?: InputMaybe<IOrderBy>;
};

/** Boolean expression to filter rows from the table "service_request". All fields are combined with a logical 'AND'. */
export type IServiceRequestBoolExp = {
  _and?: InputMaybe<Array<IServiceRequestBoolExp>>;
  _not?: InputMaybe<IServiceRequestBoolExp>;
  _or?: InputMaybe<Array<IServiceRequestBoolExp>>;
  assets?: InputMaybe<IServiceRequestXAssetBoolExp>;
  assets_aggregate?: InputMaybe<IServiceRequestXAssetAggregateBoolExp>;
  assigned_teams?: InputMaybe<IServiceRequestXTeamBoolExp>;
  assigned_teams_aggregate?: InputMaybe<IServiceRequestXTeamAggregateBoolExp>;
  assignees?: InputMaybe<IServiceRequestXUserBoolExp>;
  assignees_aggregate?: InputMaybe<IServiceRequestXUserAggregateBoolExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  created_by?: InputMaybe<IUserBoolExp>;
  created_by_id?: InputMaybe<IUuidComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  id?: InputMaybe<IIntComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  priority?: InputMaybe<IWorkOrderPriorityEnumComparisonExp>;
  status?: InputMaybe<IServiceRequestStatusEnumComparisonExp>;
  tasks?: InputMaybe<IWorkOrderBoolExp>;
  tasks_aggregate?: InputMaybe<IWorkOrderAggregateBoolExp>;
  title?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  uploads?: InputMaybe<IServiceRequestXUploadBoolExp>;
  uploads_aggregate?: InputMaybe<IServiceRequestXUploadAggregateBoolExp>;
};

/** unique or primary key constraints on table "service_request" */
export enum IServiceRequestConstraint {
  /** unique or primary key constraint on columns "id" */
  ServiceRequestPkey = 'service_request_pkey'
}

/** input type for incrementing numeric columns in table "service_request" */
export type IServiceRequestIncInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "service_request" */
export type IServiceRequestInsertInput = {
  assets?: InputMaybe<IServiceRequestXAssetArrRelInsertInput>;
  assigned_teams?: InputMaybe<IServiceRequestXTeamArrRelInsertInput>;
  assignees?: InputMaybe<IServiceRequestXUserArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<IUserObjRelInsertInput>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  priority?: InputMaybe<IWorkOrderPriorityEnum>;
  status?: InputMaybe<IServiceRequestStatusEnum>;
  tasks?: InputMaybe<IWorkOrderArrRelInsertInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  uploads?: InputMaybe<IServiceRequestXUploadArrRelInsertInput>;
};

/** aggregate max on columns */
export type IServiceRequestMaxFields = {
  __typename: 'service_request_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "service_request" */
export type IServiceRequestMaxOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  title?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IServiceRequestMinFields = {
  __typename: 'service_request_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "service_request" */
export type IServiceRequestMinOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  title?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "service_request" */
export type IServiceRequestMutationResponse = {
  __typename: 'service_request_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IServiceRequest>;
};

/** input type for inserting object relation for remote table "service_request" */
export type IServiceRequestObjRelInsertInput = {
  data: IServiceRequestInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IServiceRequestOnConflict>;
};

/** on_conflict condition type for table "service_request" */
export type IServiceRequestOnConflict = {
  constraint: IServiceRequestConstraint;
  update_columns?: Array<IServiceRequestUpdateColumn>;
  where?: InputMaybe<IServiceRequestBoolExp>;
};

/** Ordering options when selecting data from "service_request". */
export type IServiceRequestOrderBy = {
  assets_aggregate?: InputMaybe<IServiceRequestXAssetAggregateOrderBy>;
  assigned_teams_aggregate?: InputMaybe<IServiceRequestXTeamAggregateOrderBy>;
  assignees_aggregate?: InputMaybe<IServiceRequestXUserAggregateOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by?: InputMaybe<IUserOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  priority?: InputMaybe<IOrderBy>;
  status?: InputMaybe<IOrderBy>;
  tasks_aggregate?: InputMaybe<IWorkOrderAggregateOrderBy>;
  title?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  uploads_aggregate?: InputMaybe<IServiceRequestXUploadAggregateOrderBy>;
};

/** primary key columns input for table: service_request */
export type IServiceRequestPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** select columns of table "service_request" */
export enum IServiceRequestSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "service_request" */
export type IServiceRequestSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  priority?: InputMaybe<IWorkOrderPriorityEnum>;
  status?: InputMaybe<IServiceRequestStatusEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "service_request_status" */
export type IServiceRequestStatus = {
  __typename: 'service_request_status';
  /** An array relationship */
  service_requests: Array<IServiceRequest>;
  /** An aggregate relationship */
  service_requests_aggregate: IServiceRequestAggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "service_request_status" */
export type IServiceRequestStatusServiceRequestsArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestOrderBy>>;
  where?: InputMaybe<IServiceRequestBoolExp>;
};


/** columns and relationships of "service_request_status" */
export type IServiceRequestStatusServiceRequestsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestOrderBy>>;
  where?: InputMaybe<IServiceRequestBoolExp>;
};

/** aggregated selection of "service_request_status" */
export type IServiceRequestStatusAggregate = {
  __typename: 'service_request_status_aggregate';
  aggregate?: Maybe<IServiceRequestStatusAggregateFields>;
  nodes: Array<IServiceRequestStatus>;
};

/** aggregate fields of "service_request_status" */
export type IServiceRequestStatusAggregateFields = {
  __typename: 'service_request_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IServiceRequestStatusMaxFields>;
  min?: Maybe<IServiceRequestStatusMinFields>;
};


/** aggregate fields of "service_request_status" */
export type IServiceRequestStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IServiceRequestStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "service_request_status". All fields are combined with a logical 'AND'. */
export type IServiceRequestStatusBoolExp = {
  _and?: InputMaybe<Array<IServiceRequestStatusBoolExp>>;
  _not?: InputMaybe<IServiceRequestStatusBoolExp>;
  _or?: InputMaybe<Array<IServiceRequestStatusBoolExp>>;
  service_requests?: InputMaybe<IServiceRequestBoolExp>;
  service_requests_aggregate?: InputMaybe<IServiceRequestAggregateBoolExp>;
  value?: InputMaybe<IStringComparisonExp>;
};

/** unique or primary key constraints on table "service_request_status" */
export enum IServiceRequestStatusConstraint {
  /** unique or primary key constraint on columns "value" */
  ServiceRequestStatusPkey = 'service_request_status_pkey'
}

export enum IServiceRequestStatusEnum {
  Done = 'done',
  InProgress = 'in_progress',
  New = 'new',
  Rejected = 'rejected'
}

/** Boolean expression to compare columns of type "service_request_status_enum". All fields are combined with logical 'AND'. */
export type IServiceRequestStatusEnumComparisonExp = {
  _eq?: InputMaybe<IServiceRequestStatusEnum>;
  _in?: InputMaybe<Array<IServiceRequestStatusEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<IServiceRequestStatusEnum>;
  _nin?: InputMaybe<Array<IServiceRequestStatusEnum>>;
};

/** input type for inserting data into table "service_request_status" */
export type IServiceRequestStatusInsertInput = {
  service_requests?: InputMaybe<IServiceRequestArrRelInsertInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type IServiceRequestStatusMaxFields = {
  __typename: 'service_request_status_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IServiceRequestStatusMinFields = {
  __typename: 'service_request_status_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "service_request_status" */
export type IServiceRequestStatusMutationResponse = {
  __typename: 'service_request_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IServiceRequestStatus>;
};

/** on_conflict condition type for table "service_request_status" */
export type IServiceRequestStatusOnConflict = {
  constraint: IServiceRequestStatusConstraint;
  update_columns?: Array<IServiceRequestStatusUpdateColumn>;
  where?: InputMaybe<IServiceRequestStatusBoolExp>;
};

/** Ordering options when selecting data from "service_request_status". */
export type IServiceRequestStatusOrderBy = {
  service_requests_aggregate?: InputMaybe<IServiceRequestAggregateOrderBy>;
  value?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: service_request_status */
export type IServiceRequestStatusPkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "service_request_status" */
export enum IServiceRequestStatusSelectColumn {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "service_request_status" */
export type IServiceRequestStatusSetInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "service_request_status" */
export type IServiceRequestStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IServiceRequestStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IServiceRequestStatusStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "service_request_status" */
export enum IServiceRequestStatusUpdateColumn {
  /** column name */
  Value = 'value'
}

export type IServiceRequestStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IServiceRequestStatusSetInput>;
  /** filter the rows which have to be updated */
  where: IServiceRequestStatusBoolExp;
};

/** aggregate stddev on columns */
export type IServiceRequestStddevFields = {
  __typename: 'service_request_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "service_request" */
export type IServiceRequestStddevOrderBy = {
  id?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_pop on columns */
export type IServiceRequestStddevPopFields = {
  __typename: 'service_request_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "service_request" */
export type IServiceRequestStddevPopOrderBy = {
  id?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_samp on columns */
export type IServiceRequestStddevSampFields = {
  __typename: 'service_request_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "service_request" */
export type IServiceRequestStddevSampOrderBy = {
  id?: InputMaybe<IOrderBy>;
};

/** Streaming cursor of the table "service_request" */
export type IServiceRequestStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IServiceRequestStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IServiceRequestStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  priority?: InputMaybe<IWorkOrderPriorityEnum>;
  status?: InputMaybe<IServiceRequestStatusEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type IServiceRequestSumFields = {
  __typename: 'service_request_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "service_request" */
export type IServiceRequestSumOrderBy = {
  id?: InputMaybe<IOrderBy>;
};

/** update columns of table "service_request" */
export enum IServiceRequestUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type IServiceRequestUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IServiceRequestIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IServiceRequestSetInput>;
  /** filter the rows which have to be updated */
  where: IServiceRequestBoolExp;
};

/** aggregate var_pop on columns */
export type IServiceRequestVarPopFields = {
  __typename: 'service_request_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "service_request" */
export type IServiceRequestVarPopOrderBy = {
  id?: InputMaybe<IOrderBy>;
};

/** aggregate var_samp on columns */
export type IServiceRequestVarSampFields = {
  __typename: 'service_request_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "service_request" */
export type IServiceRequestVarSampOrderBy = {
  id?: InputMaybe<IOrderBy>;
};

/** aggregate variance on columns */
export type IServiceRequestVarianceFields = {
  __typename: 'service_request_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "service_request" */
export type IServiceRequestVarianceOrderBy = {
  id?: InputMaybe<IOrderBy>;
};

/** Bridge table of Service Requests and the Objects that are associated to it. */
export type IServiceRequestXAsset = {
  __typename: 'service_request_x_asset';
  /** An object relationship */
  asset: IAsset;
  asset_id: Scalars['uuid']['output'];
  /** An object relationship */
  service_request: IServiceRequest;
  service_request_id: Scalars['Int']['output'];
};

/** aggregated selection of "service_request_x_asset" */
export type IServiceRequestXAssetAggregate = {
  __typename: 'service_request_x_asset_aggregate';
  aggregate?: Maybe<IServiceRequestXAssetAggregateFields>;
  nodes: Array<IServiceRequestXAsset>;
};

export type IServiceRequestXAssetAggregateBoolExp = {
  count?: InputMaybe<IServiceRequestXAssetAggregateBoolExpCount>;
};

export type IServiceRequestXAssetAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IServiceRequestXAssetSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IServiceRequestXAssetBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "service_request_x_asset" */
export type IServiceRequestXAssetAggregateFields = {
  __typename: 'service_request_x_asset_aggregate_fields';
  avg?: Maybe<IServiceRequestXAssetAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IServiceRequestXAssetMaxFields>;
  min?: Maybe<IServiceRequestXAssetMinFields>;
  stddev?: Maybe<IServiceRequestXAssetStddevFields>;
  stddev_pop?: Maybe<IServiceRequestXAssetStddevPopFields>;
  stddev_samp?: Maybe<IServiceRequestXAssetStddevSampFields>;
  sum?: Maybe<IServiceRequestXAssetSumFields>;
  var_pop?: Maybe<IServiceRequestXAssetVarPopFields>;
  var_samp?: Maybe<IServiceRequestXAssetVarSampFields>;
  variance?: Maybe<IServiceRequestXAssetVarianceFields>;
};


/** aggregate fields of "service_request_x_asset" */
export type IServiceRequestXAssetAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IServiceRequestXAssetSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "service_request_x_asset" */
export type IServiceRequestXAssetAggregateOrderBy = {
  avg?: InputMaybe<IServiceRequestXAssetAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IServiceRequestXAssetMaxOrderBy>;
  min?: InputMaybe<IServiceRequestXAssetMinOrderBy>;
  stddev?: InputMaybe<IServiceRequestXAssetStddevOrderBy>;
  stddev_pop?: InputMaybe<IServiceRequestXAssetStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IServiceRequestXAssetStddevSampOrderBy>;
  sum?: InputMaybe<IServiceRequestXAssetSumOrderBy>;
  var_pop?: InputMaybe<IServiceRequestXAssetVarPopOrderBy>;
  var_samp?: InputMaybe<IServiceRequestXAssetVarSampOrderBy>;
  variance?: InputMaybe<IServiceRequestXAssetVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "service_request_x_asset" */
export type IServiceRequestXAssetArrRelInsertInput = {
  data: Array<IServiceRequestXAssetInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IServiceRequestXAssetOnConflict>;
};

/** aggregate avg on columns */
export type IServiceRequestXAssetAvgFields = {
  __typename: 'service_request_x_asset_avg_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "service_request_x_asset" */
export type IServiceRequestXAssetAvgOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** Boolean expression to filter rows from the table "service_request_x_asset". All fields are combined with a logical 'AND'. */
export type IServiceRequestXAssetBoolExp = {
  _and?: InputMaybe<Array<IServiceRequestXAssetBoolExp>>;
  _not?: InputMaybe<IServiceRequestXAssetBoolExp>;
  _or?: InputMaybe<Array<IServiceRequestXAssetBoolExp>>;
  asset?: InputMaybe<IAssetBoolExp>;
  asset_id?: InputMaybe<IUuidComparisonExp>;
  service_request?: InputMaybe<IServiceRequestBoolExp>;
  service_request_id?: InputMaybe<IIntComparisonExp>;
};

/** unique or primary key constraints on table "service_request_x_asset" */
export enum IServiceRequestXAssetConstraint {
  /** unique or primary key constraint on columns "service_request_id", "asset_id" */
  ServiceRequestXAssetPkey = 'service_request_x_asset_pkey'
}

/** input type for incrementing numeric columns in table "service_request_x_asset" */
export type IServiceRequestXAssetIncInput = {
  service_request_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "service_request_x_asset" */
export type IServiceRequestXAssetInsertInput = {
  asset?: InputMaybe<IAssetObjRelInsertInput>;
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  service_request?: InputMaybe<IServiceRequestObjRelInsertInput>;
  service_request_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type IServiceRequestXAssetMaxFields = {
  __typename: 'service_request_x_asset_max_fields';
  asset_id?: Maybe<Scalars['uuid']['output']>;
  service_request_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "service_request_x_asset" */
export type IServiceRequestXAssetMaxOrderBy = {
  asset_id?: InputMaybe<IOrderBy>;
  service_request_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IServiceRequestXAssetMinFields = {
  __typename: 'service_request_x_asset_min_fields';
  asset_id?: Maybe<Scalars['uuid']['output']>;
  service_request_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "service_request_x_asset" */
export type IServiceRequestXAssetMinOrderBy = {
  asset_id?: InputMaybe<IOrderBy>;
  service_request_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "service_request_x_asset" */
export type IServiceRequestXAssetMutationResponse = {
  __typename: 'service_request_x_asset_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IServiceRequestXAsset>;
};

/** on_conflict condition type for table "service_request_x_asset" */
export type IServiceRequestXAssetOnConflict = {
  constraint: IServiceRequestXAssetConstraint;
  update_columns?: Array<IServiceRequestXAssetUpdateColumn>;
  where?: InputMaybe<IServiceRequestXAssetBoolExp>;
};

/** Ordering options when selecting data from "service_request_x_asset". */
export type IServiceRequestXAssetOrderBy = {
  asset?: InputMaybe<IAssetOrderBy>;
  asset_id?: InputMaybe<IOrderBy>;
  service_request?: InputMaybe<IServiceRequestOrderBy>;
  service_request_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: service_request_x_asset */
export type IServiceRequestXAssetPkColumnsInput = {
  asset_id: Scalars['uuid']['input'];
  service_request_id: Scalars['Int']['input'];
};

/** select columns of table "service_request_x_asset" */
export enum IServiceRequestXAssetSelectColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  ServiceRequestId = 'service_request_id'
}

/** input type for updating data in table "service_request_x_asset" */
export type IServiceRequestXAssetSetInput = {
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  service_request_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type IServiceRequestXAssetStddevFields = {
  __typename: 'service_request_x_asset_stddev_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "service_request_x_asset" */
export type IServiceRequestXAssetStddevOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_pop on columns */
export type IServiceRequestXAssetStddevPopFields = {
  __typename: 'service_request_x_asset_stddev_pop_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "service_request_x_asset" */
export type IServiceRequestXAssetStddevPopOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_samp on columns */
export type IServiceRequestXAssetStddevSampFields = {
  __typename: 'service_request_x_asset_stddev_samp_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "service_request_x_asset" */
export type IServiceRequestXAssetStddevSampOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** Streaming cursor of the table "service_request_x_asset" */
export type IServiceRequestXAssetStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IServiceRequestXAssetStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IServiceRequestXAssetStreamCursorValueInput = {
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  service_request_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type IServiceRequestXAssetSumFields = {
  __typename: 'service_request_x_asset_sum_fields';
  service_request_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "service_request_x_asset" */
export type IServiceRequestXAssetSumOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** update columns of table "service_request_x_asset" */
export enum IServiceRequestXAssetUpdateColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  ServiceRequestId = 'service_request_id'
}

export type IServiceRequestXAssetUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IServiceRequestXAssetIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IServiceRequestXAssetSetInput>;
  /** filter the rows which have to be updated */
  where: IServiceRequestXAssetBoolExp;
};

/** aggregate var_pop on columns */
export type IServiceRequestXAssetVarPopFields = {
  __typename: 'service_request_x_asset_var_pop_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "service_request_x_asset" */
export type IServiceRequestXAssetVarPopOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** aggregate var_samp on columns */
export type IServiceRequestXAssetVarSampFields = {
  __typename: 'service_request_x_asset_var_samp_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "service_request_x_asset" */
export type IServiceRequestXAssetVarSampOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** aggregate variance on columns */
export type IServiceRequestXAssetVarianceFields = {
  __typename: 'service_request_x_asset_variance_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "service_request_x_asset" */
export type IServiceRequestXAssetVarianceOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** Bridge table of Service Requests and the Teams that are assigned to it. */
export type IServiceRequestXTeam = {
  __typename: 'service_request_x_team';
  /** An object relationship */
  service_request: IServiceRequest;
  service_request_id: Scalars['Int']['output'];
  /** An object relationship */
  team: ITeam;
  team_id: Scalars['uuid']['output'];
};

/** aggregated selection of "service_request_x_team" */
export type IServiceRequestXTeamAggregate = {
  __typename: 'service_request_x_team_aggregate';
  aggregate?: Maybe<IServiceRequestXTeamAggregateFields>;
  nodes: Array<IServiceRequestXTeam>;
};

export type IServiceRequestXTeamAggregateBoolExp = {
  count?: InputMaybe<IServiceRequestXTeamAggregateBoolExpCount>;
};

export type IServiceRequestXTeamAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IServiceRequestXTeamSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IServiceRequestXTeamBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "service_request_x_team" */
export type IServiceRequestXTeamAggregateFields = {
  __typename: 'service_request_x_team_aggregate_fields';
  avg?: Maybe<IServiceRequestXTeamAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IServiceRequestXTeamMaxFields>;
  min?: Maybe<IServiceRequestXTeamMinFields>;
  stddev?: Maybe<IServiceRequestXTeamStddevFields>;
  stddev_pop?: Maybe<IServiceRequestXTeamStddevPopFields>;
  stddev_samp?: Maybe<IServiceRequestXTeamStddevSampFields>;
  sum?: Maybe<IServiceRequestXTeamSumFields>;
  var_pop?: Maybe<IServiceRequestXTeamVarPopFields>;
  var_samp?: Maybe<IServiceRequestXTeamVarSampFields>;
  variance?: Maybe<IServiceRequestXTeamVarianceFields>;
};


/** aggregate fields of "service_request_x_team" */
export type IServiceRequestXTeamAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IServiceRequestXTeamSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "service_request_x_team" */
export type IServiceRequestXTeamAggregateOrderBy = {
  avg?: InputMaybe<IServiceRequestXTeamAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IServiceRequestXTeamMaxOrderBy>;
  min?: InputMaybe<IServiceRequestXTeamMinOrderBy>;
  stddev?: InputMaybe<IServiceRequestXTeamStddevOrderBy>;
  stddev_pop?: InputMaybe<IServiceRequestXTeamStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IServiceRequestXTeamStddevSampOrderBy>;
  sum?: InputMaybe<IServiceRequestXTeamSumOrderBy>;
  var_pop?: InputMaybe<IServiceRequestXTeamVarPopOrderBy>;
  var_samp?: InputMaybe<IServiceRequestXTeamVarSampOrderBy>;
  variance?: InputMaybe<IServiceRequestXTeamVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "service_request_x_team" */
export type IServiceRequestXTeamArrRelInsertInput = {
  data: Array<IServiceRequestXTeamInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IServiceRequestXTeamOnConflict>;
};

/** aggregate avg on columns */
export type IServiceRequestXTeamAvgFields = {
  __typename: 'service_request_x_team_avg_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "service_request_x_team" */
export type IServiceRequestXTeamAvgOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** Boolean expression to filter rows from the table "service_request_x_team". All fields are combined with a logical 'AND'. */
export type IServiceRequestXTeamBoolExp = {
  _and?: InputMaybe<Array<IServiceRequestXTeamBoolExp>>;
  _not?: InputMaybe<IServiceRequestXTeamBoolExp>;
  _or?: InputMaybe<Array<IServiceRequestXTeamBoolExp>>;
  service_request?: InputMaybe<IServiceRequestBoolExp>;
  service_request_id?: InputMaybe<IIntComparisonExp>;
  team?: InputMaybe<ITeamBoolExp>;
  team_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "service_request_x_team" */
export enum IServiceRequestXTeamConstraint {
  /** unique or primary key constraint on columns "service_request_id", "team_id" */
  ServiceRequestXTeamPkey = 'service_request_x_team_pkey'
}

/** input type for incrementing numeric columns in table "service_request_x_team" */
export type IServiceRequestXTeamIncInput = {
  service_request_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "service_request_x_team" */
export type IServiceRequestXTeamInsertInput = {
  service_request?: InputMaybe<IServiceRequestObjRelInsertInput>;
  service_request_id?: InputMaybe<Scalars['Int']['input']>;
  team?: InputMaybe<ITeamObjRelInsertInput>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IServiceRequestXTeamMaxFields = {
  __typename: 'service_request_x_team_max_fields';
  service_request_id?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "service_request_x_team" */
export type IServiceRequestXTeamMaxOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
  team_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IServiceRequestXTeamMinFields = {
  __typename: 'service_request_x_team_min_fields';
  service_request_id?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "service_request_x_team" */
export type IServiceRequestXTeamMinOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
  team_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "service_request_x_team" */
export type IServiceRequestXTeamMutationResponse = {
  __typename: 'service_request_x_team_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IServiceRequestXTeam>;
};

/** on_conflict condition type for table "service_request_x_team" */
export type IServiceRequestXTeamOnConflict = {
  constraint: IServiceRequestXTeamConstraint;
  update_columns?: Array<IServiceRequestXTeamUpdateColumn>;
  where?: InputMaybe<IServiceRequestXTeamBoolExp>;
};

/** Ordering options when selecting data from "service_request_x_team". */
export type IServiceRequestXTeamOrderBy = {
  service_request?: InputMaybe<IServiceRequestOrderBy>;
  service_request_id?: InputMaybe<IOrderBy>;
  team?: InputMaybe<ITeamOrderBy>;
  team_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: service_request_x_team */
export type IServiceRequestXTeamPkColumnsInput = {
  service_request_id: Scalars['Int']['input'];
  team_id: Scalars['uuid']['input'];
};

/** select columns of table "service_request_x_team" */
export enum IServiceRequestXTeamSelectColumn {
  /** column name */
  ServiceRequestId = 'service_request_id',
  /** column name */
  TeamId = 'team_id'
}

/** input type for updating data in table "service_request_x_team" */
export type IServiceRequestXTeamSetInput = {
  service_request_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type IServiceRequestXTeamStddevFields = {
  __typename: 'service_request_x_team_stddev_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "service_request_x_team" */
export type IServiceRequestXTeamStddevOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_pop on columns */
export type IServiceRequestXTeamStddevPopFields = {
  __typename: 'service_request_x_team_stddev_pop_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "service_request_x_team" */
export type IServiceRequestXTeamStddevPopOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_samp on columns */
export type IServiceRequestXTeamStddevSampFields = {
  __typename: 'service_request_x_team_stddev_samp_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "service_request_x_team" */
export type IServiceRequestXTeamStddevSampOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** Streaming cursor of the table "service_request_x_team" */
export type IServiceRequestXTeamStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IServiceRequestXTeamStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IServiceRequestXTeamStreamCursorValueInput = {
  service_request_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type IServiceRequestXTeamSumFields = {
  __typename: 'service_request_x_team_sum_fields';
  service_request_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "service_request_x_team" */
export type IServiceRequestXTeamSumOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** update columns of table "service_request_x_team" */
export enum IServiceRequestXTeamUpdateColumn {
  /** column name */
  ServiceRequestId = 'service_request_id',
  /** column name */
  TeamId = 'team_id'
}

export type IServiceRequestXTeamUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IServiceRequestXTeamIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IServiceRequestXTeamSetInput>;
  /** filter the rows which have to be updated */
  where: IServiceRequestXTeamBoolExp;
};

/** aggregate var_pop on columns */
export type IServiceRequestXTeamVarPopFields = {
  __typename: 'service_request_x_team_var_pop_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "service_request_x_team" */
export type IServiceRequestXTeamVarPopOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** aggregate var_samp on columns */
export type IServiceRequestXTeamVarSampFields = {
  __typename: 'service_request_x_team_var_samp_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "service_request_x_team" */
export type IServiceRequestXTeamVarSampOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** aggregate variance on columns */
export type IServiceRequestXTeamVarianceFields = {
  __typename: 'service_request_x_team_variance_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "service_request_x_team" */
export type IServiceRequestXTeamVarianceOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** columns and relationships of "service_request_x_upload" */
export type IServiceRequestXUpload = {
  __typename: 'service_request_x_upload';
  /** An object relationship */
  service_request: IServiceRequest;
  service_request_id: Scalars['Int']['output'];
  /** An object relationship */
  upload: IUpload;
  upload_id: Scalars['uuid']['output'];
};

/** aggregated selection of "service_request_x_upload" */
export type IServiceRequestXUploadAggregate = {
  __typename: 'service_request_x_upload_aggregate';
  aggregate?: Maybe<IServiceRequestXUploadAggregateFields>;
  nodes: Array<IServiceRequestXUpload>;
};

export type IServiceRequestXUploadAggregateBoolExp = {
  count?: InputMaybe<IServiceRequestXUploadAggregateBoolExpCount>;
};

export type IServiceRequestXUploadAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IServiceRequestXUploadSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IServiceRequestXUploadBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "service_request_x_upload" */
export type IServiceRequestXUploadAggregateFields = {
  __typename: 'service_request_x_upload_aggregate_fields';
  avg?: Maybe<IServiceRequestXUploadAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IServiceRequestXUploadMaxFields>;
  min?: Maybe<IServiceRequestXUploadMinFields>;
  stddev?: Maybe<IServiceRequestXUploadStddevFields>;
  stddev_pop?: Maybe<IServiceRequestXUploadStddevPopFields>;
  stddev_samp?: Maybe<IServiceRequestXUploadStddevSampFields>;
  sum?: Maybe<IServiceRequestXUploadSumFields>;
  var_pop?: Maybe<IServiceRequestXUploadVarPopFields>;
  var_samp?: Maybe<IServiceRequestXUploadVarSampFields>;
  variance?: Maybe<IServiceRequestXUploadVarianceFields>;
};


/** aggregate fields of "service_request_x_upload" */
export type IServiceRequestXUploadAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IServiceRequestXUploadSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "service_request_x_upload" */
export type IServiceRequestXUploadAggregateOrderBy = {
  avg?: InputMaybe<IServiceRequestXUploadAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IServiceRequestXUploadMaxOrderBy>;
  min?: InputMaybe<IServiceRequestXUploadMinOrderBy>;
  stddev?: InputMaybe<IServiceRequestXUploadStddevOrderBy>;
  stddev_pop?: InputMaybe<IServiceRequestXUploadStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IServiceRequestXUploadStddevSampOrderBy>;
  sum?: InputMaybe<IServiceRequestXUploadSumOrderBy>;
  var_pop?: InputMaybe<IServiceRequestXUploadVarPopOrderBy>;
  var_samp?: InputMaybe<IServiceRequestXUploadVarSampOrderBy>;
  variance?: InputMaybe<IServiceRequestXUploadVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "service_request_x_upload" */
export type IServiceRequestXUploadArrRelInsertInput = {
  data: Array<IServiceRequestXUploadInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IServiceRequestXUploadOnConflict>;
};

/** aggregate avg on columns */
export type IServiceRequestXUploadAvgFields = {
  __typename: 'service_request_x_upload_avg_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "service_request_x_upload" */
export type IServiceRequestXUploadAvgOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** Boolean expression to filter rows from the table "service_request_x_upload". All fields are combined with a logical 'AND'. */
export type IServiceRequestXUploadBoolExp = {
  _and?: InputMaybe<Array<IServiceRequestXUploadBoolExp>>;
  _not?: InputMaybe<IServiceRequestXUploadBoolExp>;
  _or?: InputMaybe<Array<IServiceRequestXUploadBoolExp>>;
  service_request?: InputMaybe<IServiceRequestBoolExp>;
  service_request_id?: InputMaybe<IIntComparisonExp>;
  upload?: InputMaybe<IUploadBoolExp>;
  upload_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "service_request_x_upload" */
export enum IServiceRequestXUploadConstraint {
  /** unique or primary key constraint on columns "upload_id", "service_request_id" */
  ServiceRequestXUploadPkey = 'service_request_x_upload_pkey'
}

/** input type for incrementing numeric columns in table "service_request_x_upload" */
export type IServiceRequestXUploadIncInput = {
  service_request_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "service_request_x_upload" */
export type IServiceRequestXUploadInsertInput = {
  service_request?: InputMaybe<IServiceRequestObjRelInsertInput>;
  service_request_id?: InputMaybe<Scalars['Int']['input']>;
  upload?: InputMaybe<IUploadObjRelInsertInput>;
  upload_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IServiceRequestXUploadMaxFields = {
  __typename: 'service_request_x_upload_max_fields';
  service_request_id?: Maybe<Scalars['Int']['output']>;
  upload_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "service_request_x_upload" */
export type IServiceRequestXUploadMaxOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
  upload_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IServiceRequestXUploadMinFields = {
  __typename: 'service_request_x_upload_min_fields';
  service_request_id?: Maybe<Scalars['Int']['output']>;
  upload_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "service_request_x_upload" */
export type IServiceRequestXUploadMinOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
  upload_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "service_request_x_upload" */
export type IServiceRequestXUploadMutationResponse = {
  __typename: 'service_request_x_upload_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IServiceRequestXUpload>;
};

/** on_conflict condition type for table "service_request_x_upload" */
export type IServiceRequestXUploadOnConflict = {
  constraint: IServiceRequestXUploadConstraint;
  update_columns?: Array<IServiceRequestXUploadUpdateColumn>;
  where?: InputMaybe<IServiceRequestXUploadBoolExp>;
};

/** Ordering options when selecting data from "service_request_x_upload". */
export type IServiceRequestXUploadOrderBy = {
  service_request?: InputMaybe<IServiceRequestOrderBy>;
  service_request_id?: InputMaybe<IOrderBy>;
  upload?: InputMaybe<IUploadOrderBy>;
  upload_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: service_request_x_upload */
export type IServiceRequestXUploadPkColumnsInput = {
  service_request_id: Scalars['Int']['input'];
  upload_id: Scalars['uuid']['input'];
};

/** select columns of table "service_request_x_upload" */
export enum IServiceRequestXUploadSelectColumn {
  /** column name */
  ServiceRequestId = 'service_request_id',
  /** column name */
  UploadId = 'upload_id'
}

/** input type for updating data in table "service_request_x_upload" */
export type IServiceRequestXUploadSetInput = {
  service_request_id?: InputMaybe<Scalars['Int']['input']>;
  upload_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type IServiceRequestXUploadStddevFields = {
  __typename: 'service_request_x_upload_stddev_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "service_request_x_upload" */
export type IServiceRequestXUploadStddevOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_pop on columns */
export type IServiceRequestXUploadStddevPopFields = {
  __typename: 'service_request_x_upload_stddev_pop_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "service_request_x_upload" */
export type IServiceRequestXUploadStddevPopOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_samp on columns */
export type IServiceRequestXUploadStddevSampFields = {
  __typename: 'service_request_x_upload_stddev_samp_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "service_request_x_upload" */
export type IServiceRequestXUploadStddevSampOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** Streaming cursor of the table "service_request_x_upload" */
export type IServiceRequestXUploadStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IServiceRequestXUploadStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IServiceRequestXUploadStreamCursorValueInput = {
  service_request_id?: InputMaybe<Scalars['Int']['input']>;
  upload_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type IServiceRequestXUploadSumFields = {
  __typename: 'service_request_x_upload_sum_fields';
  service_request_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "service_request_x_upload" */
export type IServiceRequestXUploadSumOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** update columns of table "service_request_x_upload" */
export enum IServiceRequestXUploadUpdateColumn {
  /** column name */
  ServiceRequestId = 'service_request_id',
  /** column name */
  UploadId = 'upload_id'
}

export type IServiceRequestXUploadUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IServiceRequestXUploadIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IServiceRequestXUploadSetInput>;
  /** filter the rows which have to be updated */
  where: IServiceRequestXUploadBoolExp;
};

/** aggregate var_pop on columns */
export type IServiceRequestXUploadVarPopFields = {
  __typename: 'service_request_x_upload_var_pop_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "service_request_x_upload" */
export type IServiceRequestXUploadVarPopOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** aggregate var_samp on columns */
export type IServiceRequestXUploadVarSampFields = {
  __typename: 'service_request_x_upload_var_samp_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "service_request_x_upload" */
export type IServiceRequestXUploadVarSampOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** aggregate variance on columns */
export type IServiceRequestXUploadVarianceFields = {
  __typename: 'service_request_x_upload_variance_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "service_request_x_upload" */
export type IServiceRequestXUploadVarianceOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** Bridge table of Service Requests and the Users assigned to the specific request. */
export type IServiceRequestXUser = {
  __typename: 'service_request_x_user';
  /** An object relationship */
  service_request: IServiceRequest;
  service_request_id: Scalars['Int']['output'];
  /** An object relationship */
  user: IUser;
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "service_request_x_user" */
export type IServiceRequestXUserAggregate = {
  __typename: 'service_request_x_user_aggregate';
  aggregate?: Maybe<IServiceRequestXUserAggregateFields>;
  nodes: Array<IServiceRequestXUser>;
};

export type IServiceRequestXUserAggregateBoolExp = {
  count?: InputMaybe<IServiceRequestXUserAggregateBoolExpCount>;
};

export type IServiceRequestXUserAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IServiceRequestXUserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IServiceRequestXUserBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "service_request_x_user" */
export type IServiceRequestXUserAggregateFields = {
  __typename: 'service_request_x_user_aggregate_fields';
  avg?: Maybe<IServiceRequestXUserAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IServiceRequestXUserMaxFields>;
  min?: Maybe<IServiceRequestXUserMinFields>;
  stddev?: Maybe<IServiceRequestXUserStddevFields>;
  stddev_pop?: Maybe<IServiceRequestXUserStddevPopFields>;
  stddev_samp?: Maybe<IServiceRequestXUserStddevSampFields>;
  sum?: Maybe<IServiceRequestXUserSumFields>;
  var_pop?: Maybe<IServiceRequestXUserVarPopFields>;
  var_samp?: Maybe<IServiceRequestXUserVarSampFields>;
  variance?: Maybe<IServiceRequestXUserVarianceFields>;
};


/** aggregate fields of "service_request_x_user" */
export type IServiceRequestXUserAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IServiceRequestXUserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "service_request_x_user" */
export type IServiceRequestXUserAggregateOrderBy = {
  avg?: InputMaybe<IServiceRequestXUserAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IServiceRequestXUserMaxOrderBy>;
  min?: InputMaybe<IServiceRequestXUserMinOrderBy>;
  stddev?: InputMaybe<IServiceRequestXUserStddevOrderBy>;
  stddev_pop?: InputMaybe<IServiceRequestXUserStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IServiceRequestXUserStddevSampOrderBy>;
  sum?: InputMaybe<IServiceRequestXUserSumOrderBy>;
  var_pop?: InputMaybe<IServiceRequestXUserVarPopOrderBy>;
  var_samp?: InputMaybe<IServiceRequestXUserVarSampOrderBy>;
  variance?: InputMaybe<IServiceRequestXUserVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "service_request_x_user" */
export type IServiceRequestXUserArrRelInsertInput = {
  data: Array<IServiceRequestXUserInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IServiceRequestXUserOnConflict>;
};

/** aggregate avg on columns */
export type IServiceRequestXUserAvgFields = {
  __typename: 'service_request_x_user_avg_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "service_request_x_user" */
export type IServiceRequestXUserAvgOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** Boolean expression to filter rows from the table "service_request_x_user". All fields are combined with a logical 'AND'. */
export type IServiceRequestXUserBoolExp = {
  _and?: InputMaybe<Array<IServiceRequestXUserBoolExp>>;
  _not?: InputMaybe<IServiceRequestXUserBoolExp>;
  _or?: InputMaybe<Array<IServiceRequestXUserBoolExp>>;
  service_request?: InputMaybe<IServiceRequestBoolExp>;
  service_request_id?: InputMaybe<IIntComparisonExp>;
  user?: InputMaybe<IUserBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "service_request_x_user" */
export enum IServiceRequestXUserConstraint {
  /** unique or primary key constraint on columns "service_request_id", "user_id" */
  ServiceRequestXUserPkey = 'service_request_x_user_pkey'
}

/** input type for incrementing numeric columns in table "service_request_x_user" */
export type IServiceRequestXUserIncInput = {
  service_request_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "service_request_x_user" */
export type IServiceRequestXUserInsertInput = {
  service_request?: InputMaybe<IServiceRequestObjRelInsertInput>;
  service_request_id?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<IUserObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IServiceRequestXUserMaxFields = {
  __typename: 'service_request_x_user_max_fields';
  service_request_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "service_request_x_user" */
export type IServiceRequestXUserMaxOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IServiceRequestXUserMinFields = {
  __typename: 'service_request_x_user_min_fields';
  service_request_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "service_request_x_user" */
export type IServiceRequestXUserMinOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "service_request_x_user" */
export type IServiceRequestXUserMutationResponse = {
  __typename: 'service_request_x_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IServiceRequestXUser>;
};

/** on_conflict condition type for table "service_request_x_user" */
export type IServiceRequestXUserOnConflict = {
  constraint: IServiceRequestXUserConstraint;
  update_columns?: Array<IServiceRequestXUserUpdateColumn>;
  where?: InputMaybe<IServiceRequestXUserBoolExp>;
};

/** Ordering options when selecting data from "service_request_x_user". */
export type IServiceRequestXUserOrderBy = {
  service_request?: InputMaybe<IServiceRequestOrderBy>;
  service_request_id?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUserOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: service_request_x_user */
export type IServiceRequestXUserPkColumnsInput = {
  service_request_id: Scalars['Int']['input'];
  user_id: Scalars['uuid']['input'];
};

/** select columns of table "service_request_x_user" */
export enum IServiceRequestXUserSelectColumn {
  /** column name */
  ServiceRequestId = 'service_request_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "service_request_x_user" */
export type IServiceRequestXUserSetInput = {
  service_request_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type IServiceRequestXUserStddevFields = {
  __typename: 'service_request_x_user_stddev_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "service_request_x_user" */
export type IServiceRequestXUserStddevOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_pop on columns */
export type IServiceRequestXUserStddevPopFields = {
  __typename: 'service_request_x_user_stddev_pop_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "service_request_x_user" */
export type IServiceRequestXUserStddevPopOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_samp on columns */
export type IServiceRequestXUserStddevSampFields = {
  __typename: 'service_request_x_user_stddev_samp_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "service_request_x_user" */
export type IServiceRequestXUserStddevSampOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** Streaming cursor of the table "service_request_x_user" */
export type IServiceRequestXUserStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IServiceRequestXUserStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IServiceRequestXUserStreamCursorValueInput = {
  service_request_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type IServiceRequestXUserSumFields = {
  __typename: 'service_request_x_user_sum_fields';
  service_request_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "service_request_x_user" */
export type IServiceRequestXUserSumOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** update columns of table "service_request_x_user" */
export enum IServiceRequestXUserUpdateColumn {
  /** column name */
  ServiceRequestId = 'service_request_id',
  /** column name */
  UserId = 'user_id'
}

export type IServiceRequestXUserUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IServiceRequestXUserIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IServiceRequestXUserSetInput>;
  /** filter the rows which have to be updated */
  where: IServiceRequestXUserBoolExp;
};

/** aggregate var_pop on columns */
export type IServiceRequestXUserVarPopFields = {
  __typename: 'service_request_x_user_var_pop_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "service_request_x_user" */
export type IServiceRequestXUserVarPopOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** aggregate var_samp on columns */
export type IServiceRequestXUserVarSampFields = {
  __typename: 'service_request_x_user_var_samp_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "service_request_x_user" */
export type IServiceRequestXUserVarSampOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** aggregate variance on columns */
export type IServiceRequestXUserVarianceFields = {
  __typename: 'service_request_x_user_variance_fields';
  service_request_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "service_request_x_user" */
export type IServiceRequestXUserVarianceOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
};

/** columns and relationships of "setting.config" */
export type ISettingConfig = {
  __typename: 'setting_config';
  id: Scalars['uuid']['output'];
  level: ISettingLevelEnum;
  location_id?: Maybe<Scalars['uuid']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  setting?: Maybe<Scalars['jsonb']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
  type: ISettingTypeEnum;
  user_id?: Maybe<Scalars['uuid']['output']>;
};


/** columns and relationships of "setting.config" */
export type ISettingConfigSettingArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "setting.config" */
export type ISettingConfigAggregate = {
  __typename: 'setting_config_aggregate';
  aggregate?: Maybe<ISettingConfigAggregateFields>;
  nodes: Array<ISettingConfig>;
};

/** aggregate fields of "setting.config" */
export type ISettingConfigAggregateFields = {
  __typename: 'setting_config_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ISettingConfigMaxFields>;
  min?: Maybe<ISettingConfigMinFields>;
};


/** aggregate fields of "setting.config" */
export type ISettingConfigAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ISettingConfigSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ISettingConfigAppendInput = {
  setting?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "setting.config". All fields are combined with a logical 'AND'. */
export type ISettingConfigBoolExp = {
  _and?: InputMaybe<Array<ISettingConfigBoolExp>>;
  _not?: InputMaybe<ISettingConfigBoolExp>;
  _or?: InputMaybe<Array<ISettingConfigBoolExp>>;
  id?: InputMaybe<IUuidComparisonExp>;
  level?: InputMaybe<ISettingLevelEnumComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  org_id?: InputMaybe<IUuidComparisonExp>;
  setting?: InputMaybe<IJsonbComparisonExp>;
  team_id?: InputMaybe<IUuidComparisonExp>;
  type?: InputMaybe<ISettingTypeEnumComparisonExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "setting.config" */
export enum ISettingConfigConstraint {
  /** unique or primary key constraint on columns "id" */
  ConfigPkey = 'config_pkey',
  /** unique or primary key constraint on columns "level", "org_id", "user_id", "setting", "type", "location_id", "team_id" */
  ConfigTypeLevelSettingOrgIdLocationIdTeamIdUserIdKe = 'config_type_level_setting_org_id_location_id_team_id_user_id_ke'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ISettingConfigDeleteAtPathInput = {
  setting?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ISettingConfigDeleteElemInput = {
  setting?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ISettingConfigDeleteKeyInput = {
  setting?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "setting.config" */
export type ISettingConfigInsertInput = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  level?: InputMaybe<ISettingLevelEnum>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  setting?: InputMaybe<Scalars['jsonb']['input']>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<ISettingTypeEnum>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type ISettingConfigMaxFields = {
  __typename: 'setting_config_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type ISettingConfigMinFields = {
  __typename: 'setting_config_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "setting.config" */
export type ISettingConfigMutationResponse = {
  __typename: 'setting_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ISettingConfig>;
};

/** on_conflict condition type for table "setting.config" */
export type ISettingConfigOnConflict = {
  constraint: ISettingConfigConstraint;
  update_columns?: Array<ISettingConfigUpdateColumn>;
  where?: InputMaybe<ISettingConfigBoolExp>;
};

/** Ordering options when selecting data from "setting.config". */
export type ISettingConfigOrderBy = {
  id?: InputMaybe<IOrderBy>;
  level?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  org_id?: InputMaybe<IOrderBy>;
  setting?: InputMaybe<IOrderBy>;
  team_id?: InputMaybe<IOrderBy>;
  type?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: setting.config */
export type ISettingConfigPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ISettingConfigPrependInput = {
  setting?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "setting.config" */
export enum ISettingConfigSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Setting = 'setting',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "setting.config" */
export type ISettingConfigSetInput = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  level?: InputMaybe<ISettingLevelEnum>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  setting?: InputMaybe<Scalars['jsonb']['input']>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<ISettingTypeEnum>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "setting_config" */
export type ISettingConfigStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ISettingConfigStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ISettingConfigStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  level?: InputMaybe<ISettingLevelEnum>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  setting?: InputMaybe<Scalars['jsonb']['input']>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<ISettingTypeEnum>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "setting.config" */
export enum ISettingConfigUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Setting = 'setting',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

export type ISettingConfigUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ISettingConfigAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ISettingConfigDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ISettingConfigDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ISettingConfigDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ISettingConfigPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ISettingConfigSetInput>;
  /** filter the rows which have to be updated */
  where: ISettingConfigBoolExp;
};

/** columns and relationships of "setting.level" */
export type ISettingLevel = {
  __typename: 'setting_level';
  value: Scalars['String']['output'];
};

/** aggregated selection of "setting.level" */
export type ISettingLevelAggregate = {
  __typename: 'setting_level_aggregate';
  aggregate?: Maybe<ISettingLevelAggregateFields>;
  nodes: Array<ISettingLevel>;
};

/** aggregate fields of "setting.level" */
export type ISettingLevelAggregateFields = {
  __typename: 'setting_level_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ISettingLevelMaxFields>;
  min?: Maybe<ISettingLevelMinFields>;
};


/** aggregate fields of "setting.level" */
export type ISettingLevelAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ISettingLevelSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "setting.level". All fields are combined with a logical 'AND'. */
export type ISettingLevelBoolExp = {
  _and?: InputMaybe<Array<ISettingLevelBoolExp>>;
  _not?: InputMaybe<ISettingLevelBoolExp>;
  _or?: InputMaybe<Array<ISettingLevelBoolExp>>;
  value?: InputMaybe<IStringComparisonExp>;
};

/** unique or primary key constraints on table "setting.level" */
export enum ISettingLevelConstraint {
  /** unique or primary key constraint on columns "value" */
  LevelPkey = 'level_pkey'
}

export enum ISettingLevelEnum {
  Location = 'location',
  Organization = 'organization',
  Team = 'team',
  User = 'user'
}

/** Boolean expression to compare columns of type "setting_level_enum". All fields are combined with logical 'AND'. */
export type ISettingLevelEnumComparisonExp = {
  _eq?: InputMaybe<ISettingLevelEnum>;
  _in?: InputMaybe<Array<ISettingLevelEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<ISettingLevelEnum>;
  _nin?: InputMaybe<Array<ISettingLevelEnum>>;
};

/** input type for inserting data into table "setting.level" */
export type ISettingLevelInsertInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ISettingLevelMaxFields = {
  __typename: 'setting_level_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ISettingLevelMinFields = {
  __typename: 'setting_level_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "setting.level" */
export type ISettingLevelMutationResponse = {
  __typename: 'setting_level_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ISettingLevel>;
};

/** on_conflict condition type for table "setting.level" */
export type ISettingLevelOnConflict = {
  constraint: ISettingLevelConstraint;
  update_columns?: Array<ISettingLevelUpdateColumn>;
  where?: InputMaybe<ISettingLevelBoolExp>;
};

/** Ordering options when selecting data from "setting.level". */
export type ISettingLevelOrderBy = {
  value?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: setting.level */
export type ISettingLevelPkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "setting.level" */
export enum ISettingLevelSelectColumn {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "setting.level" */
export type ISettingLevelSetInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "setting_level" */
export type ISettingLevelStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ISettingLevelStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ISettingLevelStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "setting.level" */
export enum ISettingLevelUpdateColumn {
  /** column name */
  Value = 'value'
}

export type ISettingLevelUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ISettingLevelSetInput>;
  /** filter the rows which have to be updated */
  where: ISettingLevelBoolExp;
};

/** columns and relationships of "setting.type" */
export type ISettingType = {
  __typename: 'setting_type';
  value: Scalars['String']['output'];
};

/** aggregated selection of "setting.type" */
export type ISettingTypeAggregate = {
  __typename: 'setting_type_aggregate';
  aggregate?: Maybe<ISettingTypeAggregateFields>;
  nodes: Array<ISettingType>;
};

/** aggregate fields of "setting.type" */
export type ISettingTypeAggregateFields = {
  __typename: 'setting_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ISettingTypeMaxFields>;
  min?: Maybe<ISettingTypeMinFields>;
};


/** aggregate fields of "setting.type" */
export type ISettingTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ISettingTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "setting.type". All fields are combined with a logical 'AND'. */
export type ISettingTypeBoolExp = {
  _and?: InputMaybe<Array<ISettingTypeBoolExp>>;
  _not?: InputMaybe<ISettingTypeBoolExp>;
  _or?: InputMaybe<Array<ISettingTypeBoolExp>>;
  value?: InputMaybe<IStringComparisonExp>;
};

/** unique or primary key constraints on table "setting.type" */
export enum ISettingTypeConstraint {
  /** unique or primary key constraint on columns "value" */
  TypePkey = 'type_pkey'
}

export enum ISettingTypeEnum {
  DisableEmailNotification = 'disable_email_notification',
  EnablePushNotification = 'enable_push_notification',
  EnableTeamNotification = 'enable_team_notification'
}

/** Boolean expression to compare columns of type "setting_type_enum". All fields are combined with logical 'AND'. */
export type ISettingTypeEnumComparisonExp = {
  _eq?: InputMaybe<ISettingTypeEnum>;
  _in?: InputMaybe<Array<ISettingTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<ISettingTypeEnum>;
  _nin?: InputMaybe<Array<ISettingTypeEnum>>;
};

/** input type for inserting data into table "setting.type" */
export type ISettingTypeInsertInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ISettingTypeMaxFields = {
  __typename: 'setting_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ISettingTypeMinFields = {
  __typename: 'setting_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "setting.type" */
export type ISettingTypeMutationResponse = {
  __typename: 'setting_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ISettingType>;
};

/** on_conflict condition type for table "setting.type" */
export type ISettingTypeOnConflict = {
  constraint: ISettingTypeConstraint;
  update_columns?: Array<ISettingTypeUpdateColumn>;
  where?: InputMaybe<ISettingTypeBoolExp>;
};

/** Ordering options when selecting data from "setting.type". */
export type ISettingTypeOrderBy = {
  value?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: setting.type */
export type ISettingTypePkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "setting.type" */
export enum ISettingTypeSelectColumn {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "setting.type" */
export type ISettingTypeSetInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "setting_type" */
export type ISettingTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ISettingTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ISettingTypeStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "setting.type" */
export enum ISettingTypeUpdateColumn {
  /** column name */
  Value = 'value'
}

export type ISettingTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ISettingTypeSetInput>;
  /** filter the rows which have to be updated */
  where: ISettingTypeBoolExp;
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type ISmallintComparisonExp = {
  _eq?: InputMaybe<Scalars['smallint']['input']>;
  _gt?: InputMaybe<Scalars['smallint']['input']>;
  _gte?: InputMaybe<Scalars['smallint']['input']>;
  _in?: InputMaybe<Array<Scalars['smallint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['smallint']['input']>;
  _lte?: InputMaybe<Scalars['smallint']['input']>;
  _neq?: InputMaybe<Scalars['smallint']['input']>;
  _nin?: InputMaybe<Array<Scalars['smallint']['input']>>;
};

export type ISubscriptionRoot = {
  __typename: 'subscription_root';
  /** fetch data from the table: "analytics.event" */
  analytics_event: Array<IAnalyticsEvent>;
  /** fetch aggregated fields from the table: "analytics.event" */
  analytics_event_aggregate: IAnalyticsEventAggregate;
  /** fetch data from the table: "analytics.event" using primary key columns */
  analytics_event_by_pk?: Maybe<IAnalyticsEvent>;
  /** fetch data from the table in a streaming manner: "analytics.event" */
  analytics_event_stream: Array<IAnalyticsEvent>;
  /** fetch data from the table: "api_access_log" */
  api_access_log: Array<IApiAccessLog>;
  /** fetch aggregated fields from the table: "api_access_log" */
  api_access_log_aggregate: IApiAccessLogAggregate;
  /** fetch data from the table: "api_access_log" using primary key columns */
  api_access_log_by_pk?: Maybe<IApiAccessLog>;
  /** fetch data from the table in a streaming manner: "api_access_log" */
  api_access_log_stream: Array<IApiAccessLog>;
  /** fetch data from the table: "asset" */
  asset: Array<IAsset>;
  /** fetch aggregated fields from the table: "asset" */
  asset_aggregate: IAssetAggregate;
  /** fetch data from the table: "asset" using primary key columns */
  asset_by_pk?: Maybe<IAsset>;
  /** fetch data from the table: "asset_current_state" */
  asset_current_state: Array<IAssetCurrentState>;
  /** fetch aggregated fields from the table: "asset_current_state" */
  asset_current_state_aggregate: IAssetCurrentStateAggregate;
  /** fetch data from the table in a streaming manner: "asset_current_state" */
  asset_current_state_stream: Array<IAssetCurrentState>;
  /** fetch data from the table: "asset_default_state" */
  asset_default_state: Array<IAssetDefaultState>;
  /** fetch aggregated fields from the table: "asset_default_state" */
  asset_default_state_aggregate: IAssetDefaultStateAggregate;
  /** fetch data from the table: "asset_default_state" using primary key columns */
  asset_default_state_by_pk?: Maybe<IAssetDefaultState>;
  /** fetch data from the table in a streaming manner: "asset_default_state" */
  asset_default_state_stream: Array<IAssetDefaultState>;
  /** fetch data from the table: "asset_group" */
  asset_group: Array<IAssetGroup>;
  /** fetch aggregated fields from the table: "asset_group" */
  asset_group_aggregate: IAssetGroupAggregate;
  /** fetch data from the table: "asset_group" using primary key columns */
  asset_group_by_pk?: Maybe<IAssetGroup>;
  /** fetch data from the table in a streaming manner: "asset_group" */
  asset_group_stream: Array<IAssetGroup>;
  /** fetch data from the table: "asset_last_state_view" */
  asset_last_state_view: Array<IAssetLastStateView>;
  /** fetch aggregated fields from the table: "asset_last_state_view" */
  asset_last_state_view_aggregate: IAssetLastStateViewAggregate;
  /** fetch data from the table in a streaming manner: "asset_last_state_view" */
  asset_last_state_view_stream: Array<IAssetLastStateView>;
  /** fetch data from the table: "asset_manufacturer" */
  asset_manufacturer: Array<IAssetManufacturer>;
  /** fetch aggregated fields from the table: "asset_manufacturer" */
  asset_manufacturer_aggregate: IAssetManufacturerAggregate;
  /** fetch data from the table: "asset_manufacturer" using primary key columns */
  asset_manufacturer_by_pk?: Maybe<IAssetManufacturer>;
  /** fetch data from the table in a streaming manner: "asset_manufacturer" */
  asset_manufacturer_stream: Array<IAssetManufacturer>;
  /** fetch data from the table: "asset_operating_schedule" */
  asset_operating_schedule: Array<IAssetOperatingSchedule>;
  /** fetch aggregated fields from the table: "asset_operating_schedule" */
  asset_operating_schedule_aggregate: IAssetOperatingScheduleAggregate;
  /** fetch data from the table: "asset_operating_schedule" using primary key columns */
  asset_operating_schedule_by_pk?: Maybe<IAssetOperatingSchedule>;
  /** fetch data from the table in a streaming manner: "asset_operating_schedule" */
  asset_operating_schedule_stream: Array<IAssetOperatingSchedule>;
  /** fetch data from the table: "asset_state" */
  asset_state: Array<IAssetState>;
  /** fetch aggregated fields from the table: "asset_state" */
  asset_state_aggregate: IAssetStateAggregate;
  /** fetch data from the table: "asset_state" using primary key columns */
  asset_state_by_pk?: Maybe<IAssetState>;
  /** fetch data from the table: "asset_state_log" */
  asset_state_log: Array<IAssetStateLog>;
  /** fetch aggregated fields from the table: "asset_state_log" */
  asset_state_log_aggregate: IAssetStateLogAggregate;
  /** fetch data from the table: "asset_state_log" using primary key columns */
  asset_state_log_by_pk?: Maybe<IAssetStateLog>;
  /** fetch data from the table in a streaming manner: "asset_state_log" */
  asset_state_log_stream: Array<IAssetStateLog>;
  /** fetch data from the table in a streaming manner: "asset_state" */
  asset_state_stream: Array<IAssetState>;
  /** fetch data from the table: "asset_state_variant" */
  asset_state_variant: Array<IAssetStateVariant>;
  /** fetch aggregated fields from the table: "asset_state_variant" */
  asset_state_variant_aggregate: IAssetStateVariantAggregate;
  /** fetch data from the table: "asset_state_variant" using primary key columns */
  asset_state_variant_by_pk?: Maybe<IAssetStateVariant>;
  /** fetch data from the table in a streaming manner: "asset_state_variant" */
  asset_state_variant_stream: Array<IAssetStateVariant>;
  /** fetch data from the table: "asset_state_variant_type" */
  asset_state_variant_type: Array<IAssetStateVariantType>;
  /** fetch aggregated fields from the table: "asset_state_variant_type" */
  asset_state_variant_type_aggregate: IAssetStateVariantTypeAggregate;
  /** fetch data from the table: "asset_state_variant_type" using primary key columns */
  asset_state_variant_type_by_pk?: Maybe<IAssetStateVariantType>;
  /** fetch data from the table in a streaming manner: "asset_state_variant_type" */
  asset_state_variant_type_stream: Array<IAssetStateVariantType>;
  /** fetch data from the table in a streaming manner: "asset" */
  asset_stream: Array<IAsset>;
  /** fetch data from the table: "asset_x_asset_state" */
  asset_x_asset_state: Array<IAssetXAssetState>;
  /** fetch aggregated fields from the table: "asset_x_asset_state" */
  asset_x_asset_state_aggregate: IAssetXAssetStateAggregate;
  /** fetch data from the table: "asset_x_asset_state" using primary key columns */
  asset_x_asset_state_by_pk?: Maybe<IAssetXAssetState>;
  /** fetch data from the table: "asset_x_asset_state_duration" */
  asset_x_asset_state_duration: Array<IAssetXAssetStateDuration>;
  /** fetch aggregated fields from the table: "asset_x_asset_state_duration" */
  asset_x_asset_state_duration_aggregate: IAssetXAssetStateDurationAggregate;
  /** fetch data from the table in a streaming manner: "asset_x_asset_state_duration" */
  asset_x_asset_state_duration_stream: Array<IAssetXAssetStateDuration>;
  /** fetch data from the table in a streaming manner: "asset_x_asset_state" */
  asset_x_asset_state_stream: Array<IAssetXAssetState>;
  /** fetch data from the table: "asset_x_consumable" */
  asset_x_consumable: Array<IAssetXConsumable>;
  /** fetch aggregated fields from the table: "asset_x_consumable" */
  asset_x_consumable_aggregate: IAssetXConsumableAggregate;
  /** fetch data from the table: "asset_x_consumable" using primary key columns */
  asset_x_consumable_by_pk?: Maybe<IAssetXConsumable>;
  /** fetch data from the table in a streaming manner: "asset_x_consumable" */
  asset_x_consumable_stream: Array<IAssetXConsumable>;
  /** fetch data from the table: "asset_x_upload" */
  asset_x_upload: Array<IAssetXUpload>;
  /** fetch aggregated fields from the table: "asset_x_upload" */
  asset_x_upload_aggregate: IAssetXUploadAggregate;
  /** fetch data from the table: "asset_x_upload" using primary key columns */
  asset_x_upload_by_pk?: Maybe<IAssetXUpload>;
  /** fetch data from the table in a streaming manner: "asset_x_upload" */
  asset_x_upload_stream: Array<IAssetXUpload>;
  /** fetch data from the table: "asset_x_work_order" */
  asset_x_work_order: Array<IAssetXWorkOrder>;
  /** fetch aggregated fields from the table: "asset_x_work_order" */
  asset_x_work_order_aggregate: IAssetXWorkOrderAggregate;
  /** fetch data from the table: "asset_x_work_order" using primary key columns */
  asset_x_work_order_by_pk?: Maybe<IAssetXWorkOrder>;
  /** fetch data from the table in a streaming manner: "asset_x_work_order" */
  asset_x_work_order_stream: Array<IAssetXWorkOrder>;
  /** fetch data from the table: "block.element" */
  block_element: Array<IBlockElement>;
  /** fetch aggregated fields from the table: "block.element" */
  block_element_aggregate: IBlockElementAggregate;
  /** fetch data from the table: "block.element" using primary key columns */
  block_element_by_pk?: Maybe<IBlockElement>;
  /** fetch data from the table in a streaming manner: "block.element" */
  block_element_stream: Array<IBlockElement>;
  /** fetch data from the table: "block.element_type" */
  block_element_type: Array<IBlockElementType>;
  /** fetch aggregated fields from the table: "block.element_type" */
  block_element_type_aggregate: IBlockElementTypeAggregate;
  /** fetch data from the table: "block.element_type" using primary key columns */
  block_element_type_by_pk?: Maybe<IBlockElementType>;
  /** fetch data from the table in a streaming manner: "block.element_type" */
  block_element_type_stream: Array<IBlockElementType>;
  /** fetch data from the table: "block.element_x_upload" */
  block_element_x_upload: Array<IBlockElementXUpload>;
  /** fetch aggregated fields from the table: "block.element_x_upload" */
  block_element_x_upload_aggregate: IBlockElementXUploadAggregate;
  /** fetch data from the table: "block.element_x_upload" using primary key columns */
  block_element_x_upload_by_pk?: Maybe<IBlockElementXUpload>;
  /** fetch data from the table in a streaming manner: "block.element_x_upload" */
  block_element_x_upload_stream: Array<IBlockElementXUpload>;
  /** fetch data from the table: "block.group" */
  block_group: Array<IBlockGroup>;
  /** fetch aggregated fields from the table: "block.group" */
  block_group_aggregate: IBlockGroupAggregate;
  /** fetch data from the table: "block.group" using primary key columns */
  block_group_by_pk?: Maybe<IBlockGroup>;
  /** fetch data from the table in a streaming manner: "block.group" */
  block_group_stream: Array<IBlockGroup>;
  /** fetch data from the table: "block.group_type" */
  block_group_type: Array<IBlockGroupType>;
  /** fetch aggregated fields from the table: "block.group_type" */
  block_group_type_aggregate: IBlockGroupTypeAggregate;
  /** fetch data from the table: "block.group_type" using primary key columns */
  block_group_type_by_pk?: Maybe<IBlockGroupType>;
  /** fetch data from the table in a streaming manner: "block.group_type" */
  block_group_type_stream: Array<IBlockGroupType>;
  /** fetch data from the table: "comment" */
  comment: Array<IComment>;
  /** fetch aggregated fields from the table: "comment" */
  comment_aggregate: ICommentAggregate;
  /** fetch data from the table: "comment" using primary key columns */
  comment_by_pk?: Maybe<IComment>;
  /** fetch data from the table in a streaming manner: "comment" */
  comment_stream: Array<IComment>;
  /** An array relationship */
  comment_x_upload: Array<ICommentXUpload>;
  /** An aggregate relationship */
  comment_x_upload_aggregate: ICommentXUploadAggregate;
  /** fetch data from the table: "comment_x_upload" using primary key columns */
  comment_x_upload_by_pk?: Maybe<ICommentXUpload>;
  /** fetch data from the table in a streaming manner: "comment_x_upload" */
  comment_x_upload_stream: Array<ICommentXUpload>;
  /** fetch data from the table: "consumable" */
  consumable: Array<IConsumable>;
  /** fetch aggregated fields from the table: "consumable" */
  consumable_aggregate: IConsumableAggregate;
  /** fetch data from the table: "consumable" using primary key columns */
  consumable_by_pk?: Maybe<IConsumable>;
  /** fetch data from the table: "consumable_config" */
  consumable_config: Array<IConsumableConfig>;
  /** fetch aggregated fields from the table: "consumable_config" */
  consumable_config_aggregate: IConsumableConfigAggregate;
  /** fetch data from the table: "consumable_config" using primary key columns */
  consumable_config_by_pk?: Maybe<IConsumableConfig>;
  /** fetch data from the table in a streaming manner: "consumable_config" */
  consumable_config_stream: Array<IConsumableConfig>;
  /** fetch data from the table: "consumable_group" */
  consumable_group: Array<IConsumableGroup>;
  /** fetch aggregated fields from the table: "consumable_group" */
  consumable_group_aggregate: IConsumableGroupAggregate;
  /** fetch data from the table: "consumable_group" using primary key columns */
  consumable_group_by_pk?: Maybe<IConsumableGroup>;
  /** fetch data from the table in a streaming manner: "consumable_group" */
  consumable_group_stream: Array<IConsumableGroup>;
  /** fetch data from the table: "consumable_log" */
  consumable_log: Array<IConsumableLog>;
  /** fetch aggregated fields from the table: "consumable_log" */
  consumable_log_aggregate: IConsumableLogAggregate;
  /** fetch data from the table: "consumable_log" using primary key columns */
  consumable_log_by_pk?: Maybe<IConsumableLog>;
  /** fetch data from the table in a streaming manner: "consumable_log" */
  consumable_log_stream: Array<IConsumableLog>;
  /** fetch data from the table: "consumable_storage_location" */
  consumable_storage_location: Array<IConsumableStorageLocation>;
  /** fetch aggregated fields from the table: "consumable_storage_location" */
  consumable_storage_location_aggregate: IConsumableStorageLocationAggregate;
  /** fetch data from the table: "consumable_storage_location" using primary key columns */
  consumable_storage_location_by_pk?: Maybe<IConsumableStorageLocation>;
  /** fetch data from the table in a streaming manner: "consumable_storage_location" */
  consumable_storage_location_stream: Array<IConsumableStorageLocation>;
  /** fetch data from the table in a streaming manner: "consumable" */
  consumable_stream: Array<IConsumable>;
  /** fetch data from the table: "consumable_x_upload" */
  consumable_x_upload: Array<IConsumableXUpload>;
  /** fetch aggregated fields from the table: "consumable_x_upload" */
  consumable_x_upload_aggregate: IConsumableXUploadAggregate;
  /** fetch data from the table: "consumable_x_upload" using primary key columns */
  consumable_x_upload_by_pk?: Maybe<IConsumableXUpload>;
  /** fetch data from the table in a streaming manner: "consumable_x_upload" */
  consumable_x_upload_stream: Array<IConsumableXUpload>;
  /** fetch data from the table: "contact" */
  contact: Array<IContact>;
  /** fetch aggregated fields from the table: "contact" */
  contact_aggregate: IContactAggregate;
  /** fetch data from the table: "contact" using primary key columns */
  contact_by_pk?: Maybe<IContact>;
  /** fetch data from the table in a streaming manner: "contact" */
  contact_stream: Array<IContact>;
  /** fetch data from the table: "custom_migration" */
  custom_migration: Array<ICustomMigration>;
  /** fetch aggregated fields from the table: "custom_migration" */
  custom_migration_aggregate: ICustomMigrationAggregate;
  /** fetch data from the table: "custom_migration" using primary key columns */
  custom_migration_by_pk?: Maybe<ICustomMigration>;
  /** fetch data from the table in a streaming manner: "custom_migration" */
  custom_migration_stream: Array<ICustomMigration>;
  /** fetch data from the table: "custom_view" */
  custom_view: Array<ICustomView>;
  /** fetch aggregated fields from the table: "custom_view" */
  custom_view_aggregate: ICustomViewAggregate;
  /** fetch data from the table: "custom_view" using primary key columns */
  custom_view_by_pk?: Maybe<ICustomView>;
  /** fetch data from the table in a streaming manner: "custom_view" */
  custom_view_stream: Array<ICustomView>;
  /** fetch data from the table: "custom_view_user_favorite" */
  custom_view_user_favorite: Array<ICustomViewUserFavorite>;
  /** fetch aggregated fields from the table: "custom_view_user_favorite" */
  custom_view_user_favorite_aggregate: ICustomViewUserFavoriteAggregate;
  /** fetch data from the table: "custom_view_user_favorite" using primary key columns */
  custom_view_user_favorite_by_pk?: Maybe<ICustomViewUserFavorite>;
  /** fetch data from the table in a streaming manner: "custom_view_user_favorite" */
  custom_view_user_favorite_stream: Array<ICustomViewUserFavorite>;
  /** fetch data from the table: "customer_feedback" */
  customer_feedback: Array<ICustomerFeedback>;
  /** fetch aggregated fields from the table: "customer_feedback" */
  customer_feedback_aggregate: ICustomerFeedbackAggregate;
  /** fetch data from the table: "customer_feedback" using primary key columns */
  customer_feedback_by_pk?: Maybe<ICustomerFeedback>;
  /** fetch data from the table in a streaming manner: "customer_feedback" */
  customer_feedback_stream: Array<ICustomerFeedback>;
  /** fetch data from the table: "feature_flag" */
  feature_flag: Array<IFeatureFlag>;
  /** fetch aggregated fields from the table: "feature_flag" */
  feature_flag_aggregate: IFeatureFlagAggregate;
  /** fetch data from the table: "feature_flag" using primary key columns */
  feature_flag_by_pk?: Maybe<IFeatureFlag>;
  /** fetch data from the table in a streaming manner: "feature_flag" */
  feature_flag_stream: Array<IFeatureFlag>;
  /** fetch data from the table: "file_folder" */
  file_folder: Array<IFileFolder>;
  /** fetch aggregated fields from the table: "file_folder" */
  file_folder_aggregate: IFileFolderAggregate;
  /** fetch data from the table: "file_folder" using primary key columns */
  file_folder_by_pk?: Maybe<IFileFolder>;
  /** fetch data from the table in a streaming manner: "file_folder" */
  file_folder_stream: Array<IFileFolder>;
  /** fetch data from the table: "file_folder_x_upload" */
  file_folder_x_upload: Array<IFileFolderXUpload>;
  /** fetch aggregated fields from the table: "file_folder_x_upload" */
  file_folder_x_upload_aggregate: IFileFolderXUploadAggregate;
  /** fetch data from the table: "file_folder_x_upload" using primary key columns */
  file_folder_x_upload_by_pk?: Maybe<IFileFolderXUpload>;
  /** fetch data from the table in a streaming manner: "file_folder_x_upload" */
  file_folder_x_upload_stream: Array<IFileFolderXUpload>;
  /** fetch data from the table: "inspection_result" */
  inspection_result: Array<IInspectionResult>;
  /** fetch aggregated fields from the table: "inspection_result" */
  inspection_result_aggregate: IInspectionResultAggregate;
  /** fetch data from the table: "inspection_result" using primary key columns */
  inspection_result_by_pk?: Maybe<IInspectionResult>;
  /** fetch data from the table in a streaming manner: "inspection_result" */
  inspection_result_stream: Array<IInspectionResult>;
  /** fetch data from the table: "jobs.cron_job_completed" */
  jobs_cron_job_completed: Array<IJobsCronJobCompleted>;
  /** fetch aggregated fields from the table: "jobs.cron_job_completed" */
  jobs_cron_job_completed_aggregate: IJobsCronJobCompletedAggregate;
  /** fetch data from the table: "jobs.cron_job_completed" using primary key columns */
  jobs_cron_job_completed_by_pk?: Maybe<IJobsCronJobCompleted>;
  /** fetch data from the table in a streaming manner: "jobs.cron_job_completed" */
  jobs_cron_job_completed_stream: Array<IJobsCronJobCompleted>;
  /** fetch data from the table: "location" */
  location: Array<ILocation>;
  /** fetch data from the table: "location_admin_view" */
  location_admin_view: Array<ILocationAdminView>;
  /** fetch aggregated fields from the table: "location_admin_view" */
  location_admin_view_aggregate: ILocationAdminViewAggregate;
  /** fetch data from the table in a streaming manner: "location_admin_view" */
  location_admin_view_stream: Array<ILocationAdminView>;
  /** fetch aggregated fields from the table: "location" */
  location_aggregate: ILocationAggregate;
  /** fetch data from the table: "location_api_key" */
  location_api_key: Array<ILocationApiKey>;
  /** fetch aggregated fields from the table: "location_api_key" */
  location_api_key_aggregate: ILocationApiKeyAggregate;
  /** fetch data from the table: "location_api_key" using primary key columns */
  location_api_key_by_pk?: Maybe<ILocationApiKey>;
  /** fetch data from the table in a streaming manner: "location_api_key" */
  location_api_key_stream: Array<ILocationApiKey>;
  /** fetch data from the table: "location" using primary key columns */
  location_by_pk?: Maybe<ILocation>;
  /** fetch data from the table: "location_invite" */
  location_invite: Array<ILocationInvite>;
  /** fetch aggregated fields from the table: "location_invite" */
  location_invite_aggregate: ILocationInviteAggregate;
  /** fetch data from the table: "location_invite" using primary key columns */
  location_invite_by_pk?: Maybe<ILocationInvite>;
  /** fetch data from the table in a streaming manner: "location_invite" */
  location_invite_stream: Array<ILocationInvite>;
  /** fetch data from the table: "location_member" */
  location_member: Array<ILocationMember>;
  /** fetch aggregated fields from the table: "location_member" */
  location_member_aggregate: ILocationMemberAggregate;
  /** fetch data from the table: "location_member" using primary key columns */
  location_member_by_pk?: Maybe<ILocationMember>;
  /** fetch data from the table: "location_member_role" */
  location_member_role: Array<ILocationMemberRole>;
  /** fetch aggregated fields from the table: "location_member_role" */
  location_member_role_aggregate: ILocationMemberRoleAggregate;
  /** fetch data from the table: "location_member_role" using primary key columns */
  location_member_role_by_pk?: Maybe<ILocationMemberRole>;
  /** fetch data from the table in a streaming manner: "location_member_role" */
  location_member_role_stream: Array<ILocationMemberRole>;
  /** fetch data from the table in a streaming manner: "location_member" */
  location_member_stream: Array<ILocationMember>;
  /** fetch data from the table in a streaming manner: "location" */
  location_stream: Array<ILocation>;
  /** fetch data from the table: "location_x_feature_flag" */
  location_x_feature_flag: Array<ILocationXFeatureFlag>;
  /** fetch aggregated fields from the table: "location_x_feature_flag" */
  location_x_feature_flag_aggregate: ILocationXFeatureFlagAggregate;
  /** fetch data from the table: "location_x_feature_flag" using primary key columns */
  location_x_feature_flag_by_pk?: Maybe<ILocationXFeatureFlag>;
  /** fetch data from the table in a streaming manner: "location_x_feature_flag" */
  location_x_feature_flag_stream: Array<ILocationXFeatureFlag>;
  /** fetch data from the table: "maintenance" */
  maintenance: Array<IMaintenance>;
  /** fetch aggregated fields from the table: "maintenance" */
  maintenance_aggregate: IMaintenanceAggregate;
  /** fetch data from the table: "maintenance" using primary key columns */
  maintenance_by_pk?: Maybe<IMaintenance>;
  /** fetch data from the table in a streaming manner: "maintenance" */
  maintenance_stream: Array<IMaintenance>;
  /** fetch data from the table: "maintenance_trigger" */
  maintenance_trigger: Array<IMaintenanceTrigger>;
  /** fetch aggregated fields from the table: "maintenance_trigger" */
  maintenance_trigger_aggregate: IMaintenanceTriggerAggregate;
  /** fetch data from the table: "maintenance_trigger" using primary key columns */
  maintenance_trigger_by_pk?: Maybe<IMaintenanceTrigger>;
  /** fetch data from the table in a streaming manner: "maintenance_trigger" */
  maintenance_trigger_stream: Array<IMaintenanceTrigger>;
  /** fetch data from the table: "maintenance_trigger_task" */
  maintenance_trigger_task: Array<IMaintenanceTriggerTask>;
  /** fetch aggregated fields from the table: "maintenance_trigger_task" */
  maintenance_trigger_task_aggregate: IMaintenanceTriggerTaskAggregate;
  /** fetch data from the table: "maintenance_trigger_task" using primary key columns */
  maintenance_trigger_task_by_pk?: Maybe<IMaintenanceTriggerTask>;
  /** fetch data from the table in a streaming manner: "maintenance_trigger_task" */
  maintenance_trigger_task_stream: Array<IMaintenanceTriggerTask>;
  /** fetch data from the table: "maintenance_trigger_type" */
  maintenance_trigger_type: Array<IMaintenanceTriggerType>;
  /** fetch aggregated fields from the table: "maintenance_trigger_type" */
  maintenance_trigger_type_aggregate: IMaintenanceTriggerTypeAggregate;
  /** fetch data from the table: "maintenance_trigger_type" using primary key columns */
  maintenance_trigger_type_by_pk?: Maybe<IMaintenanceTriggerType>;
  /** fetch data from the table in a streaming manner: "maintenance_trigger_type" */
  maintenance_trigger_type_stream: Array<IMaintenanceTriggerType>;
  /** fetch data from the table: "meter" */
  meter: Array<IMeter>;
  /** fetch aggregated fields from the table: "meter" */
  meter_aggregate: IMeterAggregate;
  /** fetch data from the table: "meter" using primary key columns */
  meter_by_pk?: Maybe<IMeter>;
  /** fetch data from the table: "meter_reading" */
  meter_reading: Array<IMeterReading>;
  /** fetch aggregated fields from the table: "meter_reading" */
  meter_reading_aggregate: IMeterReadingAggregate;
  /** fetch data from the table: "meter_reading" using primary key columns */
  meter_reading_by_pk?: Maybe<IMeterReading>;
  /** fetch data from the table in a streaming manner: "meter_reading" */
  meter_reading_stream: Array<IMeterReading>;
  /** fetch data from the table in a streaming manner: "meter" */
  meter_stream: Array<IMeter>;
  /** fetch data from the table: "meter_type" */
  meter_type: Array<IMeterType>;
  /** fetch aggregated fields from the table: "meter_type" */
  meter_type_aggregate: IMeterTypeAggregate;
  /** fetch data from the table: "meter_type" using primary key columns */
  meter_type_by_pk?: Maybe<IMeterType>;
  /** fetch data from the table in a streaming manner: "meter_type" */
  meter_type_stream: Array<IMeterType>;
  /** fetch data from the table: "notification" */
  notification: Array<INotification>;
  /** fetch aggregated fields from the table: "notification" */
  notification_aggregate: INotificationAggregate;
  /** fetch data from the table: "notification" using primary key columns */
  notification_by_pk?: Maybe<INotification>;
  /** fetch data from the table in a streaming manner: "notification" */
  notification_stream: Array<INotification>;
  /** fetch data from the table: "notification_type" */
  notification_type: Array<INotificationType>;
  /** fetch aggregated fields from the table: "notification_type" */
  notification_type_aggregate: INotificationTypeAggregate;
  /** fetch data from the table: "notification_type" using primary key columns */
  notification_type_by_pk?: Maybe<INotificationType>;
  /** fetch data from the table in a streaming manner: "notification_type" */
  notification_type_stream: Array<INotificationType>;
  /** fetch data from the table: "org" */
  org: Array<IOrg>;
  /** fetch aggregated fields from the table: "org" */
  org_aggregate: IOrgAggregate;
  /** fetch data from the table: "org" using primary key columns */
  org_by_pk?: Maybe<IOrg>;
  /** fetch data from the table: "org_feature" */
  org_feature: Array<IOrgFeature>;
  /** fetch aggregated fields from the table: "org_feature" */
  org_feature_aggregate: IOrgFeatureAggregate;
  /** fetch data from the table: "org_feature" using primary key columns */
  org_feature_by_pk?: Maybe<IOrgFeature>;
  /** fetch data from the table in a streaming manner: "org_feature" */
  org_feature_stream: Array<IOrgFeature>;
  /** fetch data from the table: "org_member" */
  org_member: Array<IOrgMember>;
  /** fetch aggregated fields from the table: "org_member" */
  org_member_aggregate: IOrgMemberAggregate;
  /** fetch data from the table in a streaming manner: "org_member" */
  org_member_stream: Array<IOrgMember>;
  /** fetch data from the table: "org_prefs" */
  org_prefs: Array<IOrgPrefs>;
  /** fetch aggregated fields from the table: "org_prefs" */
  org_prefs_aggregate: IOrgPrefsAggregate;
  /** fetch data from the table: "org_prefs" using primary key columns */
  org_prefs_by_pk?: Maybe<IOrgPrefs>;
  /** fetch data from the table in a streaming manner: "org_prefs" */
  org_prefs_stream: Array<IOrgPrefs>;
  /** An array relationship */
  org_sso_settings: Array<IOrgSsoSettings>;
  /** An aggregate relationship */
  org_sso_settings_aggregate: IOrgSsoSettingsAggregate;
  /** fetch data from the table: "org_sso_settings" using primary key columns */
  org_sso_settings_by_pk?: Maybe<IOrgSsoSettings>;
  /** fetch data from the table in a streaming manner: "org_sso_settings" */
  org_sso_settings_stream: Array<IOrgSsoSettings>;
  /** fetch data from the table in a streaming manner: "org" */
  org_stream: Array<IOrg>;
  /** execute function "overdue_work_orders_without_notification" which returns "work_order_x_user" */
  overdue_work_orders_without_notification: Array<IWorkOrderXUser>;
  /** execute function "overdue_work_orders_without_notification" and query aggregates on result of table type "work_order_x_user" */
  overdue_work_orders_without_notification_aggregate: IWorkOrderXUserAggregate;
  /** fetch data from the table: "permission_role" */
  permission_role: Array<IPermissionRole>;
  /** fetch aggregated fields from the table: "permission_role" */
  permission_role_aggregate: IPermissionRoleAggregate;
  /** fetch data from the table: "permission_role" using primary key columns */
  permission_role_by_pk?: Maybe<IPermissionRole>;
  /** fetch data from the table in a streaming manner: "permission_role" */
  permission_role_stream: Array<IPermissionRole>;
  /** fetch data from the table: "permission_role_x_feature_flag" */
  permission_role_x_feature_flag: Array<IPermissionRoleXFeatureFlag>;
  /** fetch aggregated fields from the table: "permission_role_x_feature_flag" */
  permission_role_x_feature_flag_aggregate: IPermissionRoleXFeatureFlagAggregate;
  /** fetch data from the table: "permission_role_x_feature_flag" using primary key columns */
  permission_role_x_feature_flag_by_pk?: Maybe<IPermissionRoleXFeatureFlag>;
  /** fetch data from the table in a streaming manner: "permission_role_x_feature_flag" */
  permission_role_x_feature_flag_stream: Array<IPermissionRoleXFeatureFlag>;
  /** fetch data from the table: "permission_role_x_permission_scope" */
  permission_role_x_permission_scope: Array<IPermissionRoleXPermissionScope>;
  /** fetch aggregated fields from the table: "permission_role_x_permission_scope" */
  permission_role_x_permission_scope_aggregate: IPermissionRoleXPermissionScopeAggregate;
  /** fetch data from the table: "permission_role_x_permission_scope" using primary key columns */
  permission_role_x_permission_scope_by_pk?: Maybe<IPermissionRoleXPermissionScope>;
  /** fetch data from the table in a streaming manner: "permission_role_x_permission_scope" */
  permission_role_x_permission_scope_stream: Array<IPermissionRoleXPermissionScope>;
  /** fetch data from the table: "permission_scope" */
  permission_scope: Array<IPermissionScope>;
  /** fetch aggregated fields from the table: "permission_scope" */
  permission_scope_aggregate: IPermissionScopeAggregate;
  /** fetch data from the table: "permission_scope" using primary key columns */
  permission_scope_by_pk?: Maybe<IPermissionScope>;
  /** fetch data from the table in a streaming manner: "permission_scope" */
  permission_scope_stream: Array<IPermissionScope>;
  /** fetch data from the table: "place" */
  place: Array<IPlace>;
  /** fetch aggregated fields from the table: "place" */
  place_aggregate: IPlaceAggregate;
  /** fetch data from the table: "place" using primary key columns */
  place_by_pk?: Maybe<IPlace>;
  /** fetch data from the table in a streaming manner: "place" */
  place_stream: Array<IPlace>;
  /** fetch data from the table: "place_x_upload" */
  place_x_upload: Array<IPlaceXUpload>;
  /** fetch aggregated fields from the table: "place_x_upload" */
  place_x_upload_aggregate: IPlaceXUploadAggregate;
  /** fetch data from the table: "place_x_upload" using primary key columns */
  place_x_upload_by_pk?: Maybe<IPlaceXUpload>;
  /** fetch data from the table in a streaming manner: "place_x_upload" */
  place_x_upload_stream: Array<IPlaceXUpload>;
  /** fetch data from the table: "project" */
  project: Array<IProject>;
  /** fetch aggregated fields from the table: "project" */
  project_aggregate: IProjectAggregate;
  /** fetch data from the table: "project" using primary key columns */
  project_by_pk?: Maybe<IProject>;
  /** fetch data from the table: "project_status" */
  project_status: Array<IProjectStatus>;
  /** fetch aggregated fields from the table: "project_status" */
  project_status_aggregate: IProjectStatusAggregate;
  /** fetch data from the table: "project_status" using primary key columns */
  project_status_by_pk?: Maybe<IProjectStatus>;
  /** fetch data from the table in a streaming manner: "project_status" */
  project_status_stream: Array<IProjectStatus>;
  /** fetch data from the table in a streaming manner: "project" */
  project_stream: Array<IProject>;
  /** fetch data from the table: "project_x_team" */
  project_x_team: Array<IProjectXTeam>;
  /** fetch aggregated fields from the table: "project_x_team" */
  project_x_team_aggregate: IProjectXTeamAggregate;
  /** fetch data from the table: "project_x_team" using primary key columns */
  project_x_team_by_pk?: Maybe<IProjectXTeam>;
  /** fetch data from the table in a streaming manner: "project_x_team" */
  project_x_team_stream: Array<IProjectXTeam>;
  /** fetch data from the table: "recurrence" */
  recurrence: Array<IRecurrence>;
  /** fetch aggregated fields from the table: "recurrence" */
  recurrence_aggregate: IRecurrenceAggregate;
  /** fetch data from the table: "recurrence" using primary key columns */
  recurrence_by_pk?: Maybe<IRecurrence>;
  /** fetch data from the table: "recurrence_pattern" */
  recurrence_pattern: Array<IRecurrencePattern>;
  /** fetch aggregated fields from the table: "recurrence_pattern" */
  recurrence_pattern_aggregate: IRecurrencePatternAggregate;
  /** fetch data from the table: "recurrence_pattern" using primary key columns */
  recurrence_pattern_by_pk?: Maybe<IRecurrencePattern>;
  /** fetch data from the table in a streaming manner: "recurrence_pattern" */
  recurrence_pattern_stream: Array<IRecurrencePattern>;
  /** fetch data from the table in a streaming manner: "recurrence" */
  recurrence_stream: Array<IRecurrence>;
  /** fetch data from the table: "scheduled_task" */
  scheduled_task: Array<IScheduledTask>;
  /** fetch aggregated fields from the table: "scheduled_task" */
  scheduled_task_aggregate: IScheduledTaskAggregate;
  /** fetch data from the table: "scheduled_task" using primary key columns */
  scheduled_task_by_pk?: Maybe<IScheduledTask>;
  /** fetch data from the table in a streaming manner: "scheduled_task" */
  scheduled_task_stream: Array<IScheduledTask>;
  /** execute function "search_asset" which returns "asset" */
  search_asset: Array<IAsset>;
  /** execute function "search_asset" and query aggregates on result of table type "asset" */
  search_asset_aggregate: IAssetAggregate;
  /** execute function "search_maintenance" which returns "maintenance" */
  search_maintenance: Array<IMaintenance>;
  /** execute function "search_maintenance" and query aggregates on result of table type "maintenance" */
  search_maintenance_aggregate: IMaintenanceAggregate;
  /** execute function "search_work_order" which returns "work_order" */
  search_work_order: Array<IWorkOrder>;
  /** execute function "search_work_order" and query aggregates on result of table type "work_order" */
  search_work_order_aggregate: IWorkOrderAggregate;
  /** fetch data from the table: "service_request" */
  service_request: Array<IServiceRequest>;
  /** fetch aggregated fields from the table: "service_request" */
  service_request_aggregate: IServiceRequestAggregate;
  /** fetch data from the table: "service_request" using primary key columns */
  service_request_by_pk?: Maybe<IServiceRequest>;
  /** fetch data from the table: "service_request_status" */
  service_request_status: Array<IServiceRequestStatus>;
  /** fetch aggregated fields from the table: "service_request_status" */
  service_request_status_aggregate: IServiceRequestStatusAggregate;
  /** fetch data from the table: "service_request_status" using primary key columns */
  service_request_status_by_pk?: Maybe<IServiceRequestStatus>;
  /** fetch data from the table in a streaming manner: "service_request_status" */
  service_request_status_stream: Array<IServiceRequestStatus>;
  /** fetch data from the table in a streaming manner: "service_request" */
  service_request_stream: Array<IServiceRequest>;
  /** fetch data from the table: "service_request_x_asset" */
  service_request_x_asset: Array<IServiceRequestXAsset>;
  /** fetch aggregated fields from the table: "service_request_x_asset" */
  service_request_x_asset_aggregate: IServiceRequestXAssetAggregate;
  /** fetch data from the table: "service_request_x_asset" using primary key columns */
  service_request_x_asset_by_pk?: Maybe<IServiceRequestXAsset>;
  /** fetch data from the table in a streaming manner: "service_request_x_asset" */
  service_request_x_asset_stream: Array<IServiceRequestXAsset>;
  /** fetch data from the table: "service_request_x_team" */
  service_request_x_team: Array<IServiceRequestXTeam>;
  /** fetch aggregated fields from the table: "service_request_x_team" */
  service_request_x_team_aggregate: IServiceRequestXTeamAggregate;
  /** fetch data from the table: "service_request_x_team" using primary key columns */
  service_request_x_team_by_pk?: Maybe<IServiceRequestXTeam>;
  /** fetch data from the table in a streaming manner: "service_request_x_team" */
  service_request_x_team_stream: Array<IServiceRequestXTeam>;
  /** fetch data from the table: "service_request_x_upload" */
  service_request_x_upload: Array<IServiceRequestXUpload>;
  /** fetch aggregated fields from the table: "service_request_x_upload" */
  service_request_x_upload_aggregate: IServiceRequestXUploadAggregate;
  /** fetch data from the table: "service_request_x_upload" using primary key columns */
  service_request_x_upload_by_pk?: Maybe<IServiceRequestXUpload>;
  /** fetch data from the table in a streaming manner: "service_request_x_upload" */
  service_request_x_upload_stream: Array<IServiceRequestXUpload>;
  /** fetch data from the table: "service_request_x_user" */
  service_request_x_user: Array<IServiceRequestXUser>;
  /** fetch aggregated fields from the table: "service_request_x_user" */
  service_request_x_user_aggregate: IServiceRequestXUserAggregate;
  /** fetch data from the table: "service_request_x_user" using primary key columns */
  service_request_x_user_by_pk?: Maybe<IServiceRequestXUser>;
  /** fetch data from the table in a streaming manner: "service_request_x_user" */
  service_request_x_user_stream: Array<IServiceRequestXUser>;
  /** fetch data from the table: "setting.config" */
  setting_config: Array<ISettingConfig>;
  /** fetch aggregated fields from the table: "setting.config" */
  setting_config_aggregate: ISettingConfigAggregate;
  /** fetch data from the table: "setting.config" using primary key columns */
  setting_config_by_pk?: Maybe<ISettingConfig>;
  /** fetch data from the table in a streaming manner: "setting.config" */
  setting_config_stream: Array<ISettingConfig>;
  /** fetch data from the table: "setting.level" */
  setting_level: Array<ISettingLevel>;
  /** fetch aggregated fields from the table: "setting.level" */
  setting_level_aggregate: ISettingLevelAggregate;
  /** fetch data from the table: "setting.level" using primary key columns */
  setting_level_by_pk?: Maybe<ISettingLevel>;
  /** fetch data from the table in a streaming manner: "setting.level" */
  setting_level_stream: Array<ISettingLevel>;
  /** fetch data from the table: "setting.type" */
  setting_type: Array<ISettingType>;
  /** fetch aggregated fields from the table: "setting.type" */
  setting_type_aggregate: ISettingTypeAggregate;
  /** fetch data from the table: "setting.type" using primary key columns */
  setting_type_by_pk?: Maybe<ISettingType>;
  /** fetch data from the table in a streaming manner: "setting.type" */
  setting_type_stream: Array<ISettingType>;
  /** fetch data from the table: "team" */
  team: Array<ITeam>;
  /** fetch aggregated fields from the table: "team" */
  team_aggregate: ITeamAggregate;
  /** fetch data from the table: "team" using primary key columns */
  team_by_pk?: Maybe<ITeam>;
  /** fetch data from the table: "team_member" */
  team_member: Array<ITeamMember>;
  /** fetch aggregated fields from the table: "team_member" */
  team_member_aggregate: ITeamMemberAggregate;
  /** fetch data from the table: "team_member" using primary key columns */
  team_member_by_pk?: Maybe<ITeamMember>;
  /** fetch data from the table in a streaming manner: "team_member" */
  team_member_stream: Array<ITeamMember>;
  /** fetch data from the table in a streaming manner: "team" */
  team_stream: Array<ITeam>;
  /** fetch data from the table: "team_user_role" */
  team_user_role: Array<ITeamUserRole>;
  /** fetch aggregated fields from the table: "team_user_role" */
  team_user_role_aggregate: ITeamUserRoleAggregate;
  /** fetch data from the table: "team_user_role" using primary key columns */
  team_user_role_by_pk?: Maybe<ITeamUserRole>;
  /** fetch data from the table in a streaming manner: "team_user_role" */
  team_user_role_stream: Array<ITeamUserRole>;
  /** fetch data from the table: "upload" */
  upload: Array<IUpload>;
  /** fetch aggregated fields from the table: "upload" */
  upload_aggregate: IUploadAggregate;
  /** fetch data from the table: "upload" using primary key columns */
  upload_by_pk?: Maybe<IUpload>;
  /** fetch data from the table: "upload_category" */
  upload_category: Array<IUploadCategory>;
  /** fetch aggregated fields from the table: "upload_category" */
  upload_category_aggregate: IUploadCategoryAggregate;
  /** fetch data from the table: "upload_category" using primary key columns */
  upload_category_by_pk?: Maybe<IUploadCategory>;
  /** fetch data from the table in a streaming manner: "upload_category" */
  upload_category_stream: Array<IUploadCategory>;
  /** fetch data from the table in a streaming manner: "upload" */
  upload_stream: Array<IUpload>;
  /** fetch data from the table: "upload_x_upload_category" */
  upload_x_upload_category: Array<IUploadXUploadCategory>;
  /** fetch aggregated fields from the table: "upload_x_upload_category" */
  upload_x_upload_category_aggregate: IUploadXUploadCategoryAggregate;
  /** fetch data from the table: "upload_x_upload_category" using primary key columns */
  upload_x_upload_category_by_pk?: Maybe<IUploadXUploadCategory>;
  /** fetch data from the table in a streaming manner: "upload_x_upload_category" */
  upload_x_upload_category_stream: Array<IUploadXUploadCategory>;
  /** An array relationship */
  user: Array<IUser>;
  /** An aggregate relationship */
  user_aggregate: IUserAggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<IUser>;
  /** fetch data from the table: "user_favorite" */
  user_favorite: Array<IUserFavorite>;
  /** fetch aggregated fields from the table: "user_favorite" */
  user_favorite_aggregate: IUserFavoriteAggregate;
  /** fetch data from the table: "user_favorite" using primary key columns */
  user_favorite_by_pk?: Maybe<IUserFavorite>;
  /** fetch data from the table in a streaming manner: "user_favorite" */
  user_favorite_stream: Array<IUserFavorite>;
  /** fetch data from the table: "user_recently_used" */
  user_recently_used: Array<IUserRecentlyUsed>;
  /** fetch aggregated fields from the table: "user_recently_used" */
  user_recently_used_aggregate: IUserRecentlyUsedAggregate;
  /** fetch data from the table: "user_recently_used" using primary key columns */
  user_recently_used_by_pk?: Maybe<IUserRecentlyUsed>;
  /** fetch data from the table in a streaming manner: "user_recently_used" */
  user_recently_used_stream: Array<IUserRecentlyUsed>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<IUser>;
  /** fetch data from the table: "view_collection" */
  view_collection: Array<IViewCollection>;
  /** fetch aggregated fields from the table: "view_collection" */
  view_collection_aggregate: IViewCollectionAggregate;
  /** fetch data from the table: "view_collection" using primary key columns */
  view_collection_by_pk?: Maybe<IViewCollection>;
  /** fetch data from the table in a streaming manner: "view_collection" */
  view_collection_stream: Array<IViewCollection>;
  /** fetch data from the table: "view_config" */
  view_config: Array<IViewConfig>;
  /** fetch aggregated fields from the table: "view_config" */
  view_config_aggregate: IViewConfigAggregate;
  /** fetch data from the table: "view_config" using primary key columns */
  view_config_by_pk?: Maybe<IViewConfig>;
  /** fetch data from the table in a streaming manner: "view_config" */
  view_config_stream: Array<IViewConfig>;
  /** fetch data from the table: "view_data_type" */
  view_data_type: Array<IViewDataType>;
  /** fetch aggregated fields from the table: "view_data_type" */
  view_data_type_aggregate: IViewDataTypeAggregate;
  /** fetch data from the table: "view_data_type" using primary key columns */
  view_data_type_by_pk?: Maybe<IViewDataType>;
  /** fetch data from the table in a streaming manner: "view_data_type" */
  view_data_type_stream: Array<IViewDataType>;
  /** fetch data from the table: "work_order" */
  work_order: Array<IWorkOrder>;
  /** fetch data from the table: "work_order_activity" */
  work_order_activity: Array<IWorkOrderActivity>;
  /** fetch aggregated fields from the table: "work_order_activity" */
  work_order_activity_aggregate: IWorkOrderActivityAggregate;
  /** fetch data from the table: "work_order_activity" using primary key columns */
  work_order_activity_by_pk?: Maybe<IWorkOrderActivity>;
  /** fetch data from the table in a streaming manner: "work_order_activity" */
  work_order_activity_stream: Array<IWorkOrderActivity>;
  /** fetch data from the table: "work_order_activity_type" */
  work_order_activity_type: Array<IWorkOrderActivityType>;
  /** fetch aggregated fields from the table: "work_order_activity_type" */
  work_order_activity_type_aggregate: IWorkOrderActivityTypeAggregate;
  /** fetch data from the table: "work_order_activity_type" using primary key columns */
  work_order_activity_type_by_pk?: Maybe<IWorkOrderActivityType>;
  /** fetch data from the table in a streaming manner: "work_order_activity_type" */
  work_order_activity_type_stream: Array<IWorkOrderActivityType>;
  /** fetch aggregated fields from the table: "work_order" */
  work_order_aggregate: IWorkOrderAggregate;
  /** fetch data from the table: "work_order" using primary key columns */
  work_order_by_pk?: Maybe<IWorkOrder>;
  /** fetch data from the table: "work_order_category" */
  work_order_category: Array<IWorkOrderCategory>;
  /** fetch aggregated fields from the table: "work_order_category" */
  work_order_category_aggregate: IWorkOrderCategoryAggregate;
  /** fetch data from the table: "work_order_category" using primary key columns */
  work_order_category_by_pk?: Maybe<IWorkOrderCategory>;
  /** fetch data from the table in a streaming manner: "work_order_category" */
  work_order_category_stream: Array<IWorkOrderCategory>;
  /** fetch data from the table: "work_order_completed_by_category_view" */
  work_order_completed_by_category_view: Array<IWorkOrderCompletedByCategoryView>;
  /** fetch aggregated fields from the table: "work_order_completed_by_category_view" */
  work_order_completed_by_category_view_aggregate: IWorkOrderCompletedByCategoryViewAggregate;
  /** fetch data from the table in a streaming manner: "work_order_completed_by_category_view" */
  work_order_completed_by_category_view_stream: Array<IWorkOrderCompletedByCategoryView>;
  /** fetch data from the table: "work_order_completed_ontime_vs_overdue" */
  work_order_completed_ontime_vs_overdue: Array<IWorkOrderCompletedOntimeVsOverdue>;
  /** fetch aggregated fields from the table: "work_order_completed_ontime_vs_overdue" */
  work_order_completed_ontime_vs_overdue_aggregate: IWorkOrderCompletedOntimeVsOverdueAggregate;
  /** fetch data from the table in a streaming manner: "work_order_completed_ontime_vs_overdue" */
  work_order_completed_ontime_vs_overdue_stream: Array<IWorkOrderCompletedOntimeVsOverdue>;
  /** fetch data from the table: "work_order_expense" */
  work_order_expense: Array<IWorkOrderExpense>;
  /** fetch aggregated fields from the table: "work_order_expense" */
  work_order_expense_aggregate: IWorkOrderExpenseAggregate;
  /** fetch data from the table: "work_order_expense" using primary key columns */
  work_order_expense_by_pk?: Maybe<IWorkOrderExpense>;
  /** fetch data from the table in a streaming manner: "work_order_expense" */
  work_order_expense_stream: Array<IWorkOrderExpense>;
  /** fetch data from the table: "work_order_feedback" */
  work_order_feedback: Array<IWorkOrderFeedback>;
  /** fetch aggregated fields from the table: "work_order_feedback" */
  work_order_feedback_aggregate: IWorkOrderFeedbackAggregate;
  /** fetch data from the table: "work_order_feedback" using primary key columns */
  work_order_feedback_by_pk?: Maybe<IWorkOrderFeedback>;
  /** fetch data from the table in a streaming manner: "work_order_feedback" */
  work_order_feedback_stream: Array<IWorkOrderFeedback>;
  /** fetch data from the table: "work_order_last_update" */
  work_order_last_update: Array<IWorkOrderLastUpdate>;
  /** fetch aggregated fields from the table: "work_order_last_update" */
  work_order_last_update_aggregate: IWorkOrderLastUpdateAggregate;
  /** fetch data from the table in a streaming manner: "work_order_last_update" */
  work_order_last_update_stream: Array<IWorkOrderLastUpdate>;
  /** fetch data from the table: "work_order_open_grouped_by_due_date_view" */
  work_order_open_grouped_by_due_date_view: Array<IWorkOrderOpenGroupedByDueDateView>;
  /** fetch aggregated fields from the table: "work_order_open_grouped_by_due_date_view" */
  work_order_open_grouped_by_due_date_view_aggregate: IWorkOrderOpenGroupedByDueDateViewAggregate;
  /** fetch data from the table in a streaming manner: "work_order_open_grouped_by_due_date_view" */
  work_order_open_grouped_by_due_date_view_stream: Array<IWorkOrderOpenGroupedByDueDateView>;
  /** fetch data from the table: "work_order_priority" */
  work_order_priority: Array<IWorkOrderPriority>;
  /** fetch aggregated fields from the table: "work_order_priority" */
  work_order_priority_aggregate: IWorkOrderPriorityAggregate;
  /** fetch data from the table: "work_order_priority" using primary key columns */
  work_order_priority_by_pk?: Maybe<IWorkOrderPriority>;
  /** fetch data from the table in a streaming manner: "work_order_priority" */
  work_order_priority_stream: Array<IWorkOrderPriority>;
  /** fetch data from the table: "work_order_recurring_view" */
  work_order_recurring_view: Array<IWorkOrderRecurringView>;
  /** fetch aggregated fields from the table: "work_order_recurring_view" */
  work_order_recurring_view_aggregate: IWorkOrderRecurringViewAggregate;
  /** fetch data from the table in a streaming manner: "work_order_recurring_view" */
  work_order_recurring_view_stream: Array<IWorkOrderRecurringView>;
  /** fetch data from the table: "work_order_report" */
  work_order_report: Array<IWorkOrderReport>;
  /** fetch aggregated fields from the table: "work_order_report" */
  work_order_report_aggregate: IWorkOrderReportAggregate;
  /** fetch data from the table: "work_order_report" using primary key columns */
  work_order_report_by_pk?: Maybe<IWorkOrderReport>;
  /** fetch data from the table in a streaming manner: "work_order_report" */
  work_order_report_stream: Array<IWorkOrderReport>;
  /** fetch data from the table: "work_order_status" */
  work_order_status: Array<IWorkOrderStatus>;
  /** fetch aggregated fields from the table: "work_order_status" */
  work_order_status_aggregate: IWorkOrderStatusAggregate;
  /** fetch data from the table: "work_order_status" using primary key columns */
  work_order_status_by_pk?: Maybe<IWorkOrderStatus>;
  /** fetch data from the table in a streaming manner: "work_order_status" */
  work_order_status_stream: Array<IWorkOrderStatus>;
  /** fetch data from the table in a streaming manner: "work_order" */
  work_order_stream: Array<IWorkOrder>;
  /** fetch data from the table: "work_order_template" */
  work_order_template: Array<IWorkOrderTemplate>;
  /** fetch aggregated fields from the table: "work_order_template" */
  work_order_template_aggregate: IWorkOrderTemplateAggregate;
  /** fetch data from the table: "work_order_template" using primary key columns */
  work_order_template_by_pk?: Maybe<IWorkOrderTemplate>;
  /** fetch data from the table in a streaming manner: "work_order_template" */
  work_order_template_stream: Array<IWorkOrderTemplate>;
  /** fetch data from the table: "work_order_type" */
  work_order_type: Array<IWorkOrderType>;
  /** fetch aggregated fields from the table: "work_order_type" */
  work_order_type_aggregate: IWorkOrderTypeAggregate;
  /** fetch data from the table: "work_order_type" using primary key columns */
  work_order_type_by_pk?: Maybe<IWorkOrderType>;
  /** fetch data from the table in a streaming manner: "work_order_type" */
  work_order_type_stream: Array<IWorkOrderType>;
  /** fetch data from the table: "work_order_with_last_recurring_instance_view" */
  work_order_with_last_recurring_instance_view: Array<IWorkOrderWithLastRecurringInstanceView>;
  /** fetch aggregated fields from the table: "work_order_with_last_recurring_instance_view" */
  work_order_with_last_recurring_instance_view_aggregate: IWorkOrderWithLastRecurringInstanceViewAggregate;
  /** fetch data from the table in a streaming manner: "work_order_with_last_recurring_instance_view" */
  work_order_with_last_recurring_instance_view_stream: Array<IWorkOrderWithLastRecurringInstanceView>;
  /** fetch data from the table: "work_order_with_open_due_date_reminder" */
  work_order_with_open_due_date_reminder: Array<IWorkOrderWithOpenDueDateReminder>;
  /** fetch aggregated fields from the table: "work_order_with_open_due_date_reminder" */
  work_order_with_open_due_date_reminder_aggregate: IWorkOrderWithOpenDueDateReminderAggregate;
  /** fetch data from the table in a streaming manner: "work_order_with_open_due_date_reminder" */
  work_order_with_open_due_date_reminder_stream: Array<IWorkOrderWithOpenDueDateReminder>;
  /** fetch data from the table: "work_order_x_collaborator" */
  work_order_x_collaborator: Array<IWorkOrderXCollaborator>;
  /** fetch aggregated fields from the table: "work_order_x_collaborator" */
  work_order_x_collaborator_aggregate: IWorkOrderXCollaboratorAggregate;
  /** fetch data from the table: "work_order_x_collaborator" using primary key columns */
  work_order_x_collaborator_by_pk?: Maybe<IWorkOrderXCollaborator>;
  /** fetch data from the table in a streaming manner: "work_order_x_collaborator" */
  work_order_x_collaborator_stream: Array<IWorkOrderXCollaborator>;
  /** fetch data from the table: "work_order_x_team" */
  work_order_x_team: Array<IWorkOrderXTeam>;
  /** fetch aggregated fields from the table: "work_order_x_team" */
  work_order_x_team_aggregate: IWorkOrderXTeamAggregate;
  /** fetch data from the table: "work_order_x_team" using primary key columns */
  work_order_x_team_by_pk?: Maybe<IWorkOrderXTeam>;
  /** fetch data from the table in a streaming manner: "work_order_x_team" */
  work_order_x_team_stream: Array<IWorkOrderXTeam>;
  /** fetch data from the table: "work_order_x_upload" */
  work_order_x_upload: Array<IWorkOrderXUpload>;
  /** fetch aggregated fields from the table: "work_order_x_upload" */
  work_order_x_upload_aggregate: IWorkOrderXUploadAggregate;
  /** fetch data from the table: "work_order_x_upload" using primary key columns */
  work_order_x_upload_by_pk?: Maybe<IWorkOrderXUpload>;
  /** fetch data from the table in a streaming manner: "work_order_x_upload" */
  work_order_x_upload_stream: Array<IWorkOrderXUpload>;
  /** fetch data from the table: "work_order_x_user" */
  work_order_x_user: Array<IWorkOrderXUser>;
  /** fetch aggregated fields from the table: "work_order_x_user" */
  work_order_x_user_aggregate: IWorkOrderXUserAggregate;
  /** fetch data from the table: "work_order_x_user" using primary key columns */
  work_order_x_user_by_pk?: Maybe<IWorkOrderXUser>;
  /** fetch data from the table in a streaming manner: "work_order_x_user" */
  work_order_x_user_stream: Array<IWorkOrderXUser>;
  /** fetch data from the table: "work_order_x_work_order_category" */
  work_order_x_work_order_category: Array<IWorkOrderXWorkOrderCategory>;
  /** fetch aggregated fields from the table: "work_order_x_work_order_category" */
  work_order_x_work_order_category_aggregate: IWorkOrderXWorkOrderCategoryAggregate;
  /** fetch data from the table: "work_order_x_work_order_category" using primary key columns */
  work_order_x_work_order_category_by_pk?: Maybe<IWorkOrderXWorkOrderCategory>;
  /** fetch data from the table in a streaming manner: "work_order_x_work_order_category" */
  work_order_x_work_order_category_stream: Array<IWorkOrderXWorkOrderCategory>;
  /** execute function "work_orders_needing_due_date_reminder" which returns "work_order" */
  work_orders_needing_due_date_reminder: Array<IWorkOrder>;
  /** execute function "work_orders_needing_due_date_reminder" and query aggregates on result of table type "work_order" */
  work_orders_needing_due_date_reminder_aggregate: IWorkOrderAggregate;
};


export type ISubscriptionRootAnalyticsEventArgs = {
  distinct_on?: InputMaybe<Array<IAnalyticsEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAnalyticsEventOrderBy>>;
  where?: InputMaybe<IAnalyticsEventBoolExp>;
};


export type ISubscriptionRootAnalyticsEventAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAnalyticsEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAnalyticsEventOrderBy>>;
  where?: InputMaybe<IAnalyticsEventBoolExp>;
};


export type ISubscriptionRootAnalyticsEventByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootAnalyticsEventStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IAnalyticsEventStreamCursorInput>>;
  where?: InputMaybe<IAnalyticsEventBoolExp>;
};


export type ISubscriptionRootApiAccessLogArgs = {
  distinct_on?: InputMaybe<Array<IApiAccessLogSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IApiAccessLogOrderBy>>;
  where?: InputMaybe<IApiAccessLogBoolExp>;
};


export type ISubscriptionRootApiAccessLogAggregateArgs = {
  distinct_on?: InputMaybe<Array<IApiAccessLogSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IApiAccessLogOrderBy>>;
  where?: InputMaybe<IApiAccessLogBoolExp>;
};


export type ISubscriptionRootApiAccessLogByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootApiAccessLogStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IApiAccessLogStreamCursorInput>>;
  where?: InputMaybe<IApiAccessLogBoolExp>;
};


export type ISubscriptionRootAssetArgs = {
  distinct_on?: InputMaybe<Array<IAssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetOrderBy>>;
  where?: InputMaybe<IAssetBoolExp>;
};


export type ISubscriptionRootAssetAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetOrderBy>>;
  where?: InputMaybe<IAssetBoolExp>;
};


export type ISubscriptionRootAssetByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootAssetCurrentStateArgs = {
  distinct_on?: InputMaybe<Array<IAssetCurrentStateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetCurrentStateOrderBy>>;
  where?: InputMaybe<IAssetCurrentStateBoolExp>;
};


export type ISubscriptionRootAssetCurrentStateAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetCurrentStateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetCurrentStateOrderBy>>;
  where?: InputMaybe<IAssetCurrentStateBoolExp>;
};


export type ISubscriptionRootAssetCurrentStateStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IAssetCurrentStateStreamCursorInput>>;
  where?: InputMaybe<IAssetCurrentStateBoolExp>;
};


export type ISubscriptionRootAssetDefaultStateArgs = {
  distinct_on?: InputMaybe<Array<IAssetDefaultStateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetDefaultStateOrderBy>>;
  where?: InputMaybe<IAssetDefaultStateBoolExp>;
};


export type ISubscriptionRootAssetDefaultStateAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetDefaultStateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetDefaultStateOrderBy>>;
  where?: InputMaybe<IAssetDefaultStateBoolExp>;
};


export type ISubscriptionRootAssetDefaultStateByPkArgs = {
  value: Scalars['String']['input'];
};


export type ISubscriptionRootAssetDefaultStateStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IAssetDefaultStateStreamCursorInput>>;
  where?: InputMaybe<IAssetDefaultStateBoolExp>;
};


export type ISubscriptionRootAssetGroupArgs = {
  distinct_on?: InputMaybe<Array<IAssetGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetGroupOrderBy>>;
  where?: InputMaybe<IAssetGroupBoolExp>;
};


export type ISubscriptionRootAssetGroupAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetGroupOrderBy>>;
  where?: InputMaybe<IAssetGroupBoolExp>;
};


export type ISubscriptionRootAssetGroupByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootAssetGroupStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IAssetGroupStreamCursorInput>>;
  where?: InputMaybe<IAssetGroupBoolExp>;
};


export type ISubscriptionRootAssetLastStateViewArgs = {
  distinct_on?: InputMaybe<Array<IAssetLastStateViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetLastStateViewOrderBy>>;
  where?: InputMaybe<IAssetLastStateViewBoolExp>;
};


export type ISubscriptionRootAssetLastStateViewAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetLastStateViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetLastStateViewOrderBy>>;
  where?: InputMaybe<IAssetLastStateViewBoolExp>;
};


export type ISubscriptionRootAssetLastStateViewStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IAssetLastStateViewStreamCursorInput>>;
  where?: InputMaybe<IAssetLastStateViewBoolExp>;
};


export type ISubscriptionRootAssetManufacturerArgs = {
  distinct_on?: InputMaybe<Array<IAssetManufacturerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetManufacturerOrderBy>>;
  where?: InputMaybe<IAssetManufacturerBoolExp>;
};


export type ISubscriptionRootAssetManufacturerAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetManufacturerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetManufacturerOrderBy>>;
  where?: InputMaybe<IAssetManufacturerBoolExp>;
};


export type ISubscriptionRootAssetManufacturerByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootAssetManufacturerStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IAssetManufacturerStreamCursorInput>>;
  where?: InputMaybe<IAssetManufacturerBoolExp>;
};


export type ISubscriptionRootAssetOperatingScheduleArgs = {
  distinct_on?: InputMaybe<Array<IAssetOperatingScheduleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetOperatingScheduleOrderBy>>;
  where?: InputMaybe<IAssetOperatingScheduleBoolExp>;
};


export type ISubscriptionRootAssetOperatingScheduleAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetOperatingScheduleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetOperatingScheduleOrderBy>>;
  where?: InputMaybe<IAssetOperatingScheduleBoolExp>;
};


export type ISubscriptionRootAssetOperatingScheduleByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootAssetOperatingScheduleStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IAssetOperatingScheduleStreamCursorInput>>;
  where?: InputMaybe<IAssetOperatingScheduleBoolExp>;
};


export type ISubscriptionRootAssetStateArgs = {
  distinct_on?: InputMaybe<Array<IAssetStateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetStateOrderBy>>;
  where?: InputMaybe<IAssetStateBoolExp>;
};


export type ISubscriptionRootAssetStateAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetStateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetStateOrderBy>>;
  where?: InputMaybe<IAssetStateBoolExp>;
};


export type ISubscriptionRootAssetStateByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootAssetStateLogArgs = {
  distinct_on?: InputMaybe<Array<IAssetStateLogSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetStateLogOrderBy>>;
  where?: InputMaybe<IAssetStateLogBoolExp>;
};


export type ISubscriptionRootAssetStateLogAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetStateLogSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetStateLogOrderBy>>;
  where?: InputMaybe<IAssetStateLogBoolExp>;
};


export type ISubscriptionRootAssetStateLogByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootAssetStateLogStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IAssetStateLogStreamCursorInput>>;
  where?: InputMaybe<IAssetStateLogBoolExp>;
};


export type ISubscriptionRootAssetStateStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IAssetStateStreamCursorInput>>;
  where?: InputMaybe<IAssetStateBoolExp>;
};


export type ISubscriptionRootAssetStateVariantArgs = {
  distinct_on?: InputMaybe<Array<IAssetStateVariantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetStateVariantOrderBy>>;
  where?: InputMaybe<IAssetStateVariantBoolExp>;
};


export type ISubscriptionRootAssetStateVariantAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetStateVariantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetStateVariantOrderBy>>;
  where?: InputMaybe<IAssetStateVariantBoolExp>;
};


export type ISubscriptionRootAssetStateVariantByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootAssetStateVariantStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IAssetStateVariantStreamCursorInput>>;
  where?: InputMaybe<IAssetStateVariantBoolExp>;
};


export type ISubscriptionRootAssetStateVariantTypeArgs = {
  distinct_on?: InputMaybe<Array<IAssetStateVariantTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetStateVariantTypeOrderBy>>;
  where?: InputMaybe<IAssetStateVariantTypeBoolExp>;
};


export type ISubscriptionRootAssetStateVariantTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetStateVariantTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetStateVariantTypeOrderBy>>;
  where?: InputMaybe<IAssetStateVariantTypeBoolExp>;
};


export type ISubscriptionRootAssetStateVariantTypeByPkArgs = {
  value: Scalars['String']['input'];
};


export type ISubscriptionRootAssetStateVariantTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IAssetStateVariantTypeStreamCursorInput>>;
  where?: InputMaybe<IAssetStateVariantTypeBoolExp>;
};


export type ISubscriptionRootAssetStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IAssetStreamCursorInput>>;
  where?: InputMaybe<IAssetBoolExp>;
};


export type ISubscriptionRootAssetXAssetStateArgs = {
  distinct_on?: InputMaybe<Array<IAssetXAssetStateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXAssetStateOrderBy>>;
  where?: InputMaybe<IAssetXAssetStateBoolExp>;
};


export type ISubscriptionRootAssetXAssetStateAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetXAssetStateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXAssetStateOrderBy>>;
  where?: InputMaybe<IAssetXAssetStateBoolExp>;
};


export type ISubscriptionRootAssetXAssetStateByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootAssetXAssetStateDurationArgs = {
  distinct_on?: InputMaybe<Array<IAssetXAssetStateDurationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXAssetStateDurationOrderBy>>;
  where?: InputMaybe<IAssetXAssetStateDurationBoolExp>;
};


export type ISubscriptionRootAssetXAssetStateDurationAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetXAssetStateDurationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXAssetStateDurationOrderBy>>;
  where?: InputMaybe<IAssetXAssetStateDurationBoolExp>;
};


export type ISubscriptionRootAssetXAssetStateDurationStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IAssetXAssetStateDurationStreamCursorInput>>;
  where?: InputMaybe<IAssetXAssetStateDurationBoolExp>;
};


export type ISubscriptionRootAssetXAssetStateStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IAssetXAssetStateStreamCursorInput>>;
  where?: InputMaybe<IAssetXAssetStateBoolExp>;
};


export type ISubscriptionRootAssetXConsumableArgs = {
  distinct_on?: InputMaybe<Array<IAssetXConsumableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXConsumableOrderBy>>;
  where?: InputMaybe<IAssetXConsumableBoolExp>;
};


export type ISubscriptionRootAssetXConsumableAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetXConsumableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXConsumableOrderBy>>;
  where?: InputMaybe<IAssetXConsumableBoolExp>;
};


export type ISubscriptionRootAssetXConsumableByPkArgs = {
  asset_id: Scalars['uuid']['input'];
  consumable_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootAssetXConsumableStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IAssetXConsumableStreamCursorInput>>;
  where?: InputMaybe<IAssetXConsumableBoolExp>;
};


export type ISubscriptionRootAssetXUploadArgs = {
  distinct_on?: InputMaybe<Array<IAssetXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXUploadOrderBy>>;
  where?: InputMaybe<IAssetXUploadBoolExp>;
};


export type ISubscriptionRootAssetXUploadAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXUploadOrderBy>>;
  where?: InputMaybe<IAssetXUploadBoolExp>;
};


export type ISubscriptionRootAssetXUploadByPkArgs = {
  asset_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootAssetXUploadStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IAssetXUploadStreamCursorInput>>;
  where?: InputMaybe<IAssetXUploadBoolExp>;
};


export type ISubscriptionRootAssetXWorkOrderArgs = {
  distinct_on?: InputMaybe<Array<IAssetXWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXWorkOrderOrderBy>>;
  where?: InputMaybe<IAssetXWorkOrderBoolExp>;
};


export type ISubscriptionRootAssetXWorkOrderAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetXWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXWorkOrderOrderBy>>;
  where?: InputMaybe<IAssetXWorkOrderBoolExp>;
};


export type ISubscriptionRootAssetXWorkOrderByPkArgs = {
  asset_id: Scalars['uuid']['input'];
  work_order_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootAssetXWorkOrderStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IAssetXWorkOrderStreamCursorInput>>;
  where?: InputMaybe<IAssetXWorkOrderBoolExp>;
};


export type ISubscriptionRootBlockElementArgs = {
  distinct_on?: InputMaybe<Array<IBlockElementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBlockElementOrderBy>>;
  where?: InputMaybe<IBlockElementBoolExp>;
};


export type ISubscriptionRootBlockElementAggregateArgs = {
  distinct_on?: InputMaybe<Array<IBlockElementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBlockElementOrderBy>>;
  where?: InputMaybe<IBlockElementBoolExp>;
};


export type ISubscriptionRootBlockElementByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootBlockElementStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IBlockElementStreamCursorInput>>;
  where?: InputMaybe<IBlockElementBoolExp>;
};


export type ISubscriptionRootBlockElementTypeArgs = {
  distinct_on?: InputMaybe<Array<IBlockElementTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBlockElementTypeOrderBy>>;
  where?: InputMaybe<IBlockElementTypeBoolExp>;
};


export type ISubscriptionRootBlockElementTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<IBlockElementTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBlockElementTypeOrderBy>>;
  where?: InputMaybe<IBlockElementTypeBoolExp>;
};


export type ISubscriptionRootBlockElementTypeByPkArgs = {
  value: Scalars['String']['input'];
};


export type ISubscriptionRootBlockElementTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IBlockElementTypeStreamCursorInput>>;
  where?: InputMaybe<IBlockElementTypeBoolExp>;
};


export type ISubscriptionRootBlockElementXUploadArgs = {
  distinct_on?: InputMaybe<Array<IBlockElementXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBlockElementXUploadOrderBy>>;
  where?: InputMaybe<IBlockElementXUploadBoolExp>;
};


export type ISubscriptionRootBlockElementXUploadAggregateArgs = {
  distinct_on?: InputMaybe<Array<IBlockElementXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBlockElementXUploadOrderBy>>;
  where?: InputMaybe<IBlockElementXUploadBoolExp>;
};


export type ISubscriptionRootBlockElementXUploadByPkArgs = {
  element_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootBlockElementXUploadStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IBlockElementXUploadStreamCursorInput>>;
  where?: InputMaybe<IBlockElementXUploadBoolExp>;
};


export type ISubscriptionRootBlockGroupArgs = {
  distinct_on?: InputMaybe<Array<IBlockGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBlockGroupOrderBy>>;
  where?: InputMaybe<IBlockGroupBoolExp>;
};


export type ISubscriptionRootBlockGroupAggregateArgs = {
  distinct_on?: InputMaybe<Array<IBlockGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBlockGroupOrderBy>>;
  where?: InputMaybe<IBlockGroupBoolExp>;
};


export type ISubscriptionRootBlockGroupByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootBlockGroupStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IBlockGroupStreamCursorInput>>;
  where?: InputMaybe<IBlockGroupBoolExp>;
};


export type ISubscriptionRootBlockGroupTypeArgs = {
  distinct_on?: InputMaybe<Array<IBlockGroupTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBlockGroupTypeOrderBy>>;
  where?: InputMaybe<IBlockGroupTypeBoolExp>;
};


export type ISubscriptionRootBlockGroupTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<IBlockGroupTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBlockGroupTypeOrderBy>>;
  where?: InputMaybe<IBlockGroupTypeBoolExp>;
};


export type ISubscriptionRootBlockGroupTypeByPkArgs = {
  value: Scalars['String']['input'];
};


export type ISubscriptionRootBlockGroupTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IBlockGroupTypeStreamCursorInput>>;
  where?: InputMaybe<IBlockGroupTypeBoolExp>;
};


export type ISubscriptionRootCommentArgs = {
  distinct_on?: InputMaybe<Array<ICommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICommentOrderBy>>;
  where?: InputMaybe<ICommentBoolExp>;
};


export type ISubscriptionRootCommentAggregateArgs = {
  distinct_on?: InputMaybe<Array<ICommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICommentOrderBy>>;
  where?: InputMaybe<ICommentBoolExp>;
};


export type ISubscriptionRootCommentByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootCommentStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ICommentStreamCursorInput>>;
  where?: InputMaybe<ICommentBoolExp>;
};


export type ISubscriptionRootCommentXUploadArgs = {
  distinct_on?: InputMaybe<Array<ICommentXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICommentXUploadOrderBy>>;
  where?: InputMaybe<ICommentXUploadBoolExp>;
};


export type ISubscriptionRootCommentXUploadAggregateArgs = {
  distinct_on?: InputMaybe<Array<ICommentXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICommentXUploadOrderBy>>;
  where?: InputMaybe<ICommentXUploadBoolExp>;
};


export type ISubscriptionRootCommentXUploadByPkArgs = {
  comment_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootCommentXUploadStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ICommentXUploadStreamCursorInput>>;
  where?: InputMaybe<ICommentXUploadBoolExp>;
};


export type ISubscriptionRootConsumableArgs = {
  distinct_on?: InputMaybe<Array<IConsumableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableOrderBy>>;
  where?: InputMaybe<IConsumableBoolExp>;
};


export type ISubscriptionRootConsumableAggregateArgs = {
  distinct_on?: InputMaybe<Array<IConsumableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableOrderBy>>;
  where?: InputMaybe<IConsumableBoolExp>;
};


export type ISubscriptionRootConsumableByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootConsumableConfigArgs = {
  distinct_on?: InputMaybe<Array<IConsumableConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableConfigOrderBy>>;
  where?: InputMaybe<IConsumableConfigBoolExp>;
};


export type ISubscriptionRootConsumableConfigAggregateArgs = {
  distinct_on?: InputMaybe<Array<IConsumableConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableConfigOrderBy>>;
  where?: InputMaybe<IConsumableConfigBoolExp>;
};


export type ISubscriptionRootConsumableConfigByPkArgs = {
  location_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootConsumableConfigStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IConsumableConfigStreamCursorInput>>;
  where?: InputMaybe<IConsumableConfigBoolExp>;
};


export type ISubscriptionRootConsumableGroupArgs = {
  distinct_on?: InputMaybe<Array<IConsumableGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableGroupOrderBy>>;
  where?: InputMaybe<IConsumableGroupBoolExp>;
};


export type ISubscriptionRootConsumableGroupAggregateArgs = {
  distinct_on?: InputMaybe<Array<IConsumableGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableGroupOrderBy>>;
  where?: InputMaybe<IConsumableGroupBoolExp>;
};


export type ISubscriptionRootConsumableGroupByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootConsumableGroupStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IConsumableGroupStreamCursorInput>>;
  where?: InputMaybe<IConsumableGroupBoolExp>;
};


export type ISubscriptionRootConsumableLogArgs = {
  distinct_on?: InputMaybe<Array<IConsumableLogSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableLogOrderBy>>;
  where?: InputMaybe<IConsumableLogBoolExp>;
};


export type ISubscriptionRootConsumableLogAggregateArgs = {
  distinct_on?: InputMaybe<Array<IConsumableLogSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableLogOrderBy>>;
  where?: InputMaybe<IConsumableLogBoolExp>;
};


export type ISubscriptionRootConsumableLogByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootConsumableLogStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IConsumableLogStreamCursorInput>>;
  where?: InputMaybe<IConsumableLogBoolExp>;
};


export type ISubscriptionRootConsumableStorageLocationArgs = {
  distinct_on?: InputMaybe<Array<IConsumableStorageLocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableStorageLocationOrderBy>>;
  where?: InputMaybe<IConsumableStorageLocationBoolExp>;
};


export type ISubscriptionRootConsumableStorageLocationAggregateArgs = {
  distinct_on?: InputMaybe<Array<IConsumableStorageLocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableStorageLocationOrderBy>>;
  where?: InputMaybe<IConsumableStorageLocationBoolExp>;
};


export type ISubscriptionRootConsumableStorageLocationByPkArgs = {
  consumable_id: Scalars['uuid']['input'];
  place_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootConsumableStorageLocationStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IConsumableStorageLocationStreamCursorInput>>;
  where?: InputMaybe<IConsumableStorageLocationBoolExp>;
};


export type ISubscriptionRootConsumableStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IConsumableStreamCursorInput>>;
  where?: InputMaybe<IConsumableBoolExp>;
};


export type ISubscriptionRootConsumableXUploadArgs = {
  distinct_on?: InputMaybe<Array<IConsumableXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableXUploadOrderBy>>;
  where?: InputMaybe<IConsumableXUploadBoolExp>;
};


export type ISubscriptionRootConsumableXUploadAggregateArgs = {
  distinct_on?: InputMaybe<Array<IConsumableXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableXUploadOrderBy>>;
  where?: InputMaybe<IConsumableXUploadBoolExp>;
};


export type ISubscriptionRootConsumableXUploadByPkArgs = {
  consumable_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootConsumableXUploadStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IConsumableXUploadStreamCursorInput>>;
  where?: InputMaybe<IConsumableXUploadBoolExp>;
};


export type ISubscriptionRootContactArgs = {
  distinct_on?: InputMaybe<Array<IContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IContactOrderBy>>;
  where?: InputMaybe<IContactBoolExp>;
};


export type ISubscriptionRootContactAggregateArgs = {
  distinct_on?: InputMaybe<Array<IContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IContactOrderBy>>;
  where?: InputMaybe<IContactBoolExp>;
};


export type ISubscriptionRootContactByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootContactStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IContactStreamCursorInput>>;
  where?: InputMaybe<IContactBoolExp>;
};


export type ISubscriptionRootCustomMigrationArgs = {
  distinct_on?: InputMaybe<Array<ICustomMigrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICustomMigrationOrderBy>>;
  where?: InputMaybe<ICustomMigrationBoolExp>;
};


export type ISubscriptionRootCustomMigrationAggregateArgs = {
  distinct_on?: InputMaybe<Array<ICustomMigrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICustomMigrationOrderBy>>;
  where?: InputMaybe<ICustomMigrationBoolExp>;
};


export type ISubscriptionRootCustomMigrationByPkArgs = {
  location_id: Scalars['uuid']['input'];
  migration_id: Scalars['String']['input'];
};


export type ISubscriptionRootCustomMigrationStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ICustomMigrationStreamCursorInput>>;
  where?: InputMaybe<ICustomMigrationBoolExp>;
};


export type ISubscriptionRootCustomViewArgs = {
  distinct_on?: InputMaybe<Array<ICustomViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICustomViewOrderBy>>;
  where?: InputMaybe<ICustomViewBoolExp>;
};


export type ISubscriptionRootCustomViewAggregateArgs = {
  distinct_on?: InputMaybe<Array<ICustomViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICustomViewOrderBy>>;
  where?: InputMaybe<ICustomViewBoolExp>;
};


export type ISubscriptionRootCustomViewByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootCustomViewStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ICustomViewStreamCursorInput>>;
  where?: InputMaybe<ICustomViewBoolExp>;
};


export type ISubscriptionRootCustomViewUserFavoriteArgs = {
  distinct_on?: InputMaybe<Array<ICustomViewUserFavoriteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICustomViewUserFavoriteOrderBy>>;
  where?: InputMaybe<ICustomViewUserFavoriteBoolExp>;
};


export type ISubscriptionRootCustomViewUserFavoriteAggregateArgs = {
  distinct_on?: InputMaybe<Array<ICustomViewUserFavoriteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICustomViewUserFavoriteOrderBy>>;
  where?: InputMaybe<ICustomViewUserFavoriteBoolExp>;
};


export type ISubscriptionRootCustomViewUserFavoriteByPkArgs = {
  custom_view_id: Scalars['uuid']['input'];
  user_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootCustomViewUserFavoriteStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ICustomViewUserFavoriteStreamCursorInput>>;
  where?: InputMaybe<ICustomViewUserFavoriteBoolExp>;
};


export type ISubscriptionRootCustomerFeedbackArgs = {
  distinct_on?: InputMaybe<Array<ICustomerFeedbackSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICustomerFeedbackOrderBy>>;
  where?: InputMaybe<ICustomerFeedbackBoolExp>;
};


export type ISubscriptionRootCustomerFeedbackAggregateArgs = {
  distinct_on?: InputMaybe<Array<ICustomerFeedbackSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICustomerFeedbackOrderBy>>;
  where?: InputMaybe<ICustomerFeedbackBoolExp>;
};


export type ISubscriptionRootCustomerFeedbackByPkArgs = {
  id: Scalars['Int']['input'];
};


export type ISubscriptionRootCustomerFeedbackStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ICustomerFeedbackStreamCursorInput>>;
  where?: InputMaybe<ICustomerFeedbackBoolExp>;
};


export type ISubscriptionRootFeatureFlagArgs = {
  distinct_on?: InputMaybe<Array<IFeatureFlagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IFeatureFlagOrderBy>>;
  where?: InputMaybe<IFeatureFlagBoolExp>;
};


export type ISubscriptionRootFeatureFlagAggregateArgs = {
  distinct_on?: InputMaybe<Array<IFeatureFlagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IFeatureFlagOrderBy>>;
  where?: InputMaybe<IFeatureFlagBoolExp>;
};


export type ISubscriptionRootFeatureFlagByPkArgs = {
  id: Scalars['String']['input'];
};


export type ISubscriptionRootFeatureFlagStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IFeatureFlagStreamCursorInput>>;
  where?: InputMaybe<IFeatureFlagBoolExp>;
};


export type ISubscriptionRootFileFolderArgs = {
  distinct_on?: InputMaybe<Array<IFileFolderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IFileFolderOrderBy>>;
  where?: InputMaybe<IFileFolderBoolExp>;
};


export type ISubscriptionRootFileFolderAggregateArgs = {
  distinct_on?: InputMaybe<Array<IFileFolderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IFileFolderOrderBy>>;
  where?: InputMaybe<IFileFolderBoolExp>;
};


export type ISubscriptionRootFileFolderByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootFileFolderStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IFileFolderStreamCursorInput>>;
  where?: InputMaybe<IFileFolderBoolExp>;
};


export type ISubscriptionRootFileFolderXUploadArgs = {
  distinct_on?: InputMaybe<Array<IFileFolderXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IFileFolderXUploadOrderBy>>;
  where?: InputMaybe<IFileFolderXUploadBoolExp>;
};


export type ISubscriptionRootFileFolderXUploadAggregateArgs = {
  distinct_on?: InputMaybe<Array<IFileFolderXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IFileFolderXUploadOrderBy>>;
  where?: InputMaybe<IFileFolderXUploadBoolExp>;
};


export type ISubscriptionRootFileFolderXUploadByPkArgs = {
  file_folder_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootFileFolderXUploadStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IFileFolderXUploadStreamCursorInput>>;
  where?: InputMaybe<IFileFolderXUploadBoolExp>;
};


export type ISubscriptionRootInspectionResultArgs = {
  distinct_on?: InputMaybe<Array<IInspectionResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IInspectionResultOrderBy>>;
  where?: InputMaybe<IInspectionResultBoolExp>;
};


export type ISubscriptionRootInspectionResultAggregateArgs = {
  distinct_on?: InputMaybe<Array<IInspectionResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IInspectionResultOrderBy>>;
  where?: InputMaybe<IInspectionResultBoolExp>;
};


export type ISubscriptionRootInspectionResultByPkArgs = {
  value: Scalars['String']['input'];
};


export type ISubscriptionRootInspectionResultStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IInspectionResultStreamCursorInput>>;
  where?: InputMaybe<IInspectionResultBoolExp>;
};


export type ISubscriptionRootJobsCronJobCompletedArgs = {
  distinct_on?: InputMaybe<Array<IJobsCronJobCompletedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IJobsCronJobCompletedOrderBy>>;
  where?: InputMaybe<IJobsCronJobCompletedBoolExp>;
};


export type ISubscriptionRootJobsCronJobCompletedAggregateArgs = {
  distinct_on?: InputMaybe<Array<IJobsCronJobCompletedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IJobsCronJobCompletedOrderBy>>;
  where?: InputMaybe<IJobsCronJobCompletedBoolExp>;
};


export type ISubscriptionRootJobsCronJobCompletedByPkArgs = {
  id: Scalars['String']['input'];
};


export type ISubscriptionRootJobsCronJobCompletedStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IJobsCronJobCompletedStreamCursorInput>>;
  where?: InputMaybe<IJobsCronJobCompletedBoolExp>;
};


export type ISubscriptionRootLocationArgs = {
  distinct_on?: InputMaybe<Array<ILocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationOrderBy>>;
  where?: InputMaybe<ILocationBoolExp>;
};


export type ISubscriptionRootLocationAdminViewArgs = {
  distinct_on?: InputMaybe<Array<ILocationAdminViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationAdminViewOrderBy>>;
  where?: InputMaybe<ILocationAdminViewBoolExp>;
};


export type ISubscriptionRootLocationAdminViewAggregateArgs = {
  distinct_on?: InputMaybe<Array<ILocationAdminViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationAdminViewOrderBy>>;
  where?: InputMaybe<ILocationAdminViewBoolExp>;
};


export type ISubscriptionRootLocationAdminViewStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ILocationAdminViewStreamCursorInput>>;
  where?: InputMaybe<ILocationAdminViewBoolExp>;
};


export type ISubscriptionRootLocationAggregateArgs = {
  distinct_on?: InputMaybe<Array<ILocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationOrderBy>>;
  where?: InputMaybe<ILocationBoolExp>;
};


export type ISubscriptionRootLocationApiKeyArgs = {
  distinct_on?: InputMaybe<Array<ILocationApiKeySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationApiKeyOrderBy>>;
  where?: InputMaybe<ILocationApiKeyBoolExp>;
};


export type ISubscriptionRootLocationApiKeyAggregateArgs = {
  distinct_on?: InputMaybe<Array<ILocationApiKeySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationApiKeyOrderBy>>;
  where?: InputMaybe<ILocationApiKeyBoolExp>;
};


export type ISubscriptionRootLocationApiKeyByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootLocationApiKeyStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ILocationApiKeyStreamCursorInput>>;
  where?: InputMaybe<ILocationApiKeyBoolExp>;
};


export type ISubscriptionRootLocationByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootLocationInviteArgs = {
  distinct_on?: InputMaybe<Array<ILocationInviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationInviteOrderBy>>;
  where?: InputMaybe<ILocationInviteBoolExp>;
};


export type ISubscriptionRootLocationInviteAggregateArgs = {
  distinct_on?: InputMaybe<Array<ILocationInviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationInviteOrderBy>>;
  where?: InputMaybe<ILocationInviteBoolExp>;
};


export type ISubscriptionRootLocationInviteByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootLocationInviteStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ILocationInviteStreamCursorInput>>;
  where?: InputMaybe<ILocationInviteBoolExp>;
};


export type ISubscriptionRootLocationMemberArgs = {
  distinct_on?: InputMaybe<Array<ILocationMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationMemberOrderBy>>;
  where?: InputMaybe<ILocationMemberBoolExp>;
};


export type ISubscriptionRootLocationMemberAggregateArgs = {
  distinct_on?: InputMaybe<Array<ILocationMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationMemberOrderBy>>;
  where?: InputMaybe<ILocationMemberBoolExp>;
};


export type ISubscriptionRootLocationMemberByPkArgs = {
  location_id: Scalars['uuid']['input'];
  user_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootLocationMemberRoleArgs = {
  distinct_on?: InputMaybe<Array<ILocationMemberRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationMemberRoleOrderBy>>;
  where?: InputMaybe<ILocationMemberRoleBoolExp>;
};


export type ISubscriptionRootLocationMemberRoleAggregateArgs = {
  distinct_on?: InputMaybe<Array<ILocationMemberRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationMemberRoleOrderBy>>;
  where?: InputMaybe<ILocationMemberRoleBoolExp>;
};


export type ISubscriptionRootLocationMemberRoleByPkArgs = {
  role: Scalars['String']['input'];
};


export type ISubscriptionRootLocationMemberRoleStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ILocationMemberRoleStreamCursorInput>>;
  where?: InputMaybe<ILocationMemberRoleBoolExp>;
};


export type ISubscriptionRootLocationMemberStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ILocationMemberStreamCursorInput>>;
  where?: InputMaybe<ILocationMemberBoolExp>;
};


export type ISubscriptionRootLocationStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ILocationStreamCursorInput>>;
  where?: InputMaybe<ILocationBoolExp>;
};


export type ISubscriptionRootLocationXFeatureFlagArgs = {
  distinct_on?: InputMaybe<Array<ILocationXFeatureFlagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationXFeatureFlagOrderBy>>;
  where?: InputMaybe<ILocationXFeatureFlagBoolExp>;
};


export type ISubscriptionRootLocationXFeatureFlagAggregateArgs = {
  distinct_on?: InputMaybe<Array<ILocationXFeatureFlagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationXFeatureFlagOrderBy>>;
  where?: InputMaybe<ILocationXFeatureFlagBoolExp>;
};


export type ISubscriptionRootLocationXFeatureFlagByPkArgs = {
  feature_flag_id: Scalars['String']['input'];
  location_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootLocationXFeatureFlagStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ILocationXFeatureFlagStreamCursorInput>>;
  where?: InputMaybe<ILocationXFeatureFlagBoolExp>;
};


export type ISubscriptionRootMaintenanceArgs = {
  distinct_on?: InputMaybe<Array<IMaintenanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMaintenanceOrderBy>>;
  where?: InputMaybe<IMaintenanceBoolExp>;
};


export type ISubscriptionRootMaintenanceAggregateArgs = {
  distinct_on?: InputMaybe<Array<IMaintenanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMaintenanceOrderBy>>;
  where?: InputMaybe<IMaintenanceBoolExp>;
};


export type ISubscriptionRootMaintenanceByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootMaintenanceStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IMaintenanceStreamCursorInput>>;
  where?: InputMaybe<IMaintenanceBoolExp>;
};


export type ISubscriptionRootMaintenanceTriggerArgs = {
  distinct_on?: InputMaybe<Array<IMaintenanceTriggerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMaintenanceTriggerOrderBy>>;
  where?: InputMaybe<IMaintenanceTriggerBoolExp>;
};


export type ISubscriptionRootMaintenanceTriggerAggregateArgs = {
  distinct_on?: InputMaybe<Array<IMaintenanceTriggerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMaintenanceTriggerOrderBy>>;
  where?: InputMaybe<IMaintenanceTriggerBoolExp>;
};


export type ISubscriptionRootMaintenanceTriggerByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootMaintenanceTriggerStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IMaintenanceTriggerStreamCursorInput>>;
  where?: InputMaybe<IMaintenanceTriggerBoolExp>;
};


export type ISubscriptionRootMaintenanceTriggerTaskArgs = {
  distinct_on?: InputMaybe<Array<IMaintenanceTriggerTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMaintenanceTriggerTaskOrderBy>>;
  where?: InputMaybe<IMaintenanceTriggerTaskBoolExp>;
};


export type ISubscriptionRootMaintenanceTriggerTaskAggregateArgs = {
  distinct_on?: InputMaybe<Array<IMaintenanceTriggerTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMaintenanceTriggerTaskOrderBy>>;
  where?: InputMaybe<IMaintenanceTriggerTaskBoolExp>;
};


export type ISubscriptionRootMaintenanceTriggerTaskByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootMaintenanceTriggerTaskStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IMaintenanceTriggerTaskStreamCursorInput>>;
  where?: InputMaybe<IMaintenanceTriggerTaskBoolExp>;
};


export type ISubscriptionRootMaintenanceTriggerTypeArgs = {
  distinct_on?: InputMaybe<Array<IMaintenanceTriggerTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMaintenanceTriggerTypeOrderBy>>;
  where?: InputMaybe<IMaintenanceTriggerTypeBoolExp>;
};


export type ISubscriptionRootMaintenanceTriggerTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<IMaintenanceTriggerTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMaintenanceTriggerTypeOrderBy>>;
  where?: InputMaybe<IMaintenanceTriggerTypeBoolExp>;
};


export type ISubscriptionRootMaintenanceTriggerTypeByPkArgs = {
  name: Scalars['String']['input'];
};


export type ISubscriptionRootMaintenanceTriggerTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IMaintenanceTriggerTypeStreamCursorInput>>;
  where?: InputMaybe<IMaintenanceTriggerTypeBoolExp>;
};


export type ISubscriptionRootMeterArgs = {
  distinct_on?: InputMaybe<Array<IMeterSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMeterOrderBy>>;
  where?: InputMaybe<IMeterBoolExp>;
};


export type ISubscriptionRootMeterAggregateArgs = {
  distinct_on?: InputMaybe<Array<IMeterSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMeterOrderBy>>;
  where?: InputMaybe<IMeterBoolExp>;
};


export type ISubscriptionRootMeterByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootMeterReadingArgs = {
  distinct_on?: InputMaybe<Array<IMeterReadingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMeterReadingOrderBy>>;
  where?: InputMaybe<IMeterReadingBoolExp>;
};


export type ISubscriptionRootMeterReadingAggregateArgs = {
  distinct_on?: InputMaybe<Array<IMeterReadingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMeterReadingOrderBy>>;
  where?: InputMaybe<IMeterReadingBoolExp>;
};


export type ISubscriptionRootMeterReadingByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootMeterReadingStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IMeterReadingStreamCursorInput>>;
  where?: InputMaybe<IMeterReadingBoolExp>;
};


export type ISubscriptionRootMeterStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IMeterStreamCursorInput>>;
  where?: InputMaybe<IMeterBoolExp>;
};


export type ISubscriptionRootMeterTypeArgs = {
  distinct_on?: InputMaybe<Array<IMeterTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMeterTypeOrderBy>>;
  where?: InputMaybe<IMeterTypeBoolExp>;
};


export type ISubscriptionRootMeterTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<IMeterTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMeterTypeOrderBy>>;
  where?: InputMaybe<IMeterTypeBoolExp>;
};


export type ISubscriptionRootMeterTypeByPkArgs = {
  value: Scalars['String']['input'];
};


export type ISubscriptionRootMeterTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IMeterTypeStreamCursorInput>>;
  where?: InputMaybe<IMeterTypeBoolExp>;
};


export type ISubscriptionRootNotificationArgs = {
  distinct_on?: InputMaybe<Array<INotificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<INotificationOrderBy>>;
  where?: InputMaybe<INotificationBoolExp>;
};


export type ISubscriptionRootNotificationAggregateArgs = {
  distinct_on?: InputMaybe<Array<INotificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<INotificationOrderBy>>;
  where?: InputMaybe<INotificationBoolExp>;
};


export type ISubscriptionRootNotificationByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootNotificationStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<INotificationStreamCursorInput>>;
  where?: InputMaybe<INotificationBoolExp>;
};


export type ISubscriptionRootNotificationTypeArgs = {
  distinct_on?: InputMaybe<Array<INotificationTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<INotificationTypeOrderBy>>;
  where?: InputMaybe<INotificationTypeBoolExp>;
};


export type ISubscriptionRootNotificationTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<INotificationTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<INotificationTypeOrderBy>>;
  where?: InputMaybe<INotificationTypeBoolExp>;
};


export type ISubscriptionRootNotificationTypeByPkArgs = {
  value: Scalars['String']['input'];
};


export type ISubscriptionRootNotificationTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<INotificationTypeStreamCursorInput>>;
  where?: InputMaybe<INotificationTypeBoolExp>;
};


export type ISubscriptionRootOrgArgs = {
  distinct_on?: InputMaybe<Array<IOrgSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IOrgOrderBy>>;
  where?: InputMaybe<IOrgBoolExp>;
};


export type ISubscriptionRootOrgAggregateArgs = {
  distinct_on?: InputMaybe<Array<IOrgSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IOrgOrderBy>>;
  where?: InputMaybe<IOrgBoolExp>;
};


export type ISubscriptionRootOrgByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootOrgFeatureArgs = {
  distinct_on?: InputMaybe<Array<IOrgFeatureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IOrgFeatureOrderBy>>;
  where?: InputMaybe<IOrgFeatureBoolExp>;
};


export type ISubscriptionRootOrgFeatureAggregateArgs = {
  distinct_on?: InputMaybe<Array<IOrgFeatureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IOrgFeatureOrderBy>>;
  where?: InputMaybe<IOrgFeatureBoolExp>;
};


export type ISubscriptionRootOrgFeatureByPkArgs = {
  org_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootOrgFeatureStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IOrgFeatureStreamCursorInput>>;
  where?: InputMaybe<IOrgFeatureBoolExp>;
};


export type ISubscriptionRootOrgMemberArgs = {
  distinct_on?: InputMaybe<Array<IOrgMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IOrgMemberOrderBy>>;
  where?: InputMaybe<IOrgMemberBoolExp>;
};


export type ISubscriptionRootOrgMemberAggregateArgs = {
  distinct_on?: InputMaybe<Array<IOrgMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IOrgMemberOrderBy>>;
  where?: InputMaybe<IOrgMemberBoolExp>;
};


export type ISubscriptionRootOrgMemberStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IOrgMemberStreamCursorInput>>;
  where?: InputMaybe<IOrgMemberBoolExp>;
};


export type ISubscriptionRootOrgPrefsArgs = {
  distinct_on?: InputMaybe<Array<IOrgPrefsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IOrgPrefsOrderBy>>;
  where?: InputMaybe<IOrgPrefsBoolExp>;
};


export type ISubscriptionRootOrgPrefsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IOrgPrefsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IOrgPrefsOrderBy>>;
  where?: InputMaybe<IOrgPrefsBoolExp>;
};


export type ISubscriptionRootOrgPrefsByPkArgs = {
  org_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootOrgPrefsStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IOrgPrefsStreamCursorInput>>;
  where?: InputMaybe<IOrgPrefsBoolExp>;
};


export type ISubscriptionRootOrgSsoSettingsArgs = {
  distinct_on?: InputMaybe<Array<IOrgSsoSettingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IOrgSsoSettingsOrderBy>>;
  where?: InputMaybe<IOrgSsoSettingsBoolExp>;
};


export type ISubscriptionRootOrgSsoSettingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IOrgSsoSettingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IOrgSsoSettingsOrderBy>>;
  where?: InputMaybe<IOrgSsoSettingsBoolExp>;
};


export type ISubscriptionRootOrgSsoSettingsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootOrgSsoSettingsStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IOrgSsoSettingsStreamCursorInput>>;
  where?: InputMaybe<IOrgSsoSettingsBoolExp>;
};


export type ISubscriptionRootOrgStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IOrgStreamCursorInput>>;
  where?: InputMaybe<IOrgBoolExp>;
};


export type ISubscriptionRootOverdueWorkOrdersWithoutNotificationArgs = {
  args: IOverdueWorkOrdersWithoutNotificationArgs;
  distinct_on?: InputMaybe<Array<IWorkOrderXUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXUserOrderBy>>;
  where?: InputMaybe<IWorkOrderXUserBoolExp>;
};


export type ISubscriptionRootOverdueWorkOrdersWithoutNotificationAggregateArgs = {
  args: IOverdueWorkOrdersWithoutNotificationArgs;
  distinct_on?: InputMaybe<Array<IWorkOrderXUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXUserOrderBy>>;
  where?: InputMaybe<IWorkOrderXUserBoolExp>;
};


export type ISubscriptionRootPermissionRoleArgs = {
  distinct_on?: InputMaybe<Array<IPermissionRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPermissionRoleOrderBy>>;
  where?: InputMaybe<IPermissionRoleBoolExp>;
};


export type ISubscriptionRootPermissionRoleAggregateArgs = {
  distinct_on?: InputMaybe<Array<IPermissionRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPermissionRoleOrderBy>>;
  where?: InputMaybe<IPermissionRoleBoolExp>;
};


export type ISubscriptionRootPermissionRoleByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootPermissionRoleStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IPermissionRoleStreamCursorInput>>;
  where?: InputMaybe<IPermissionRoleBoolExp>;
};


export type ISubscriptionRootPermissionRoleXFeatureFlagArgs = {
  distinct_on?: InputMaybe<Array<IPermissionRoleXFeatureFlagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPermissionRoleXFeatureFlagOrderBy>>;
  where?: InputMaybe<IPermissionRoleXFeatureFlagBoolExp>;
};


export type ISubscriptionRootPermissionRoleXFeatureFlagAggregateArgs = {
  distinct_on?: InputMaybe<Array<IPermissionRoleXFeatureFlagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPermissionRoleXFeatureFlagOrderBy>>;
  where?: InputMaybe<IPermissionRoleXFeatureFlagBoolExp>;
};


export type ISubscriptionRootPermissionRoleXFeatureFlagByPkArgs = {
  feature_flag: Scalars['String']['input'];
  location_id: Scalars['uuid']['input'];
  permission_role_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootPermissionRoleXFeatureFlagStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IPermissionRoleXFeatureFlagStreamCursorInput>>;
  where?: InputMaybe<IPermissionRoleXFeatureFlagBoolExp>;
};


export type ISubscriptionRootPermissionRoleXPermissionScopeArgs = {
  distinct_on?: InputMaybe<Array<IPermissionRoleXPermissionScopeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPermissionRoleXPermissionScopeOrderBy>>;
  where?: InputMaybe<IPermissionRoleXPermissionScopeBoolExp>;
};


export type ISubscriptionRootPermissionRoleXPermissionScopeAggregateArgs = {
  distinct_on?: InputMaybe<Array<IPermissionRoleXPermissionScopeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPermissionRoleXPermissionScopeOrderBy>>;
  where?: InputMaybe<IPermissionRoleXPermissionScopeBoolExp>;
};


export type ISubscriptionRootPermissionRoleXPermissionScopeByPkArgs = {
  role_id: Scalars['uuid']['input'];
  scope: IPermissionScopeEnum;
};


export type ISubscriptionRootPermissionRoleXPermissionScopeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IPermissionRoleXPermissionScopeStreamCursorInput>>;
  where?: InputMaybe<IPermissionRoleXPermissionScopeBoolExp>;
};


export type ISubscriptionRootPermissionScopeArgs = {
  distinct_on?: InputMaybe<Array<IPermissionScopeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPermissionScopeOrderBy>>;
  where?: InputMaybe<IPermissionScopeBoolExp>;
};


export type ISubscriptionRootPermissionScopeAggregateArgs = {
  distinct_on?: InputMaybe<Array<IPermissionScopeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPermissionScopeOrderBy>>;
  where?: InputMaybe<IPermissionScopeBoolExp>;
};


export type ISubscriptionRootPermissionScopeByPkArgs = {
  scope: Scalars['String']['input'];
};


export type ISubscriptionRootPermissionScopeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IPermissionScopeStreamCursorInput>>;
  where?: InputMaybe<IPermissionScopeBoolExp>;
};


export type ISubscriptionRootPlaceArgs = {
  distinct_on?: InputMaybe<Array<IPlaceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPlaceOrderBy>>;
  where?: InputMaybe<IPlaceBoolExp>;
};


export type ISubscriptionRootPlaceAggregateArgs = {
  distinct_on?: InputMaybe<Array<IPlaceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPlaceOrderBy>>;
  where?: InputMaybe<IPlaceBoolExp>;
};


export type ISubscriptionRootPlaceByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootPlaceStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IPlaceStreamCursorInput>>;
  where?: InputMaybe<IPlaceBoolExp>;
};


export type ISubscriptionRootPlaceXUploadArgs = {
  distinct_on?: InputMaybe<Array<IPlaceXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPlaceXUploadOrderBy>>;
  where?: InputMaybe<IPlaceXUploadBoolExp>;
};


export type ISubscriptionRootPlaceXUploadAggregateArgs = {
  distinct_on?: InputMaybe<Array<IPlaceXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IPlaceXUploadOrderBy>>;
  where?: InputMaybe<IPlaceXUploadBoolExp>;
};


export type ISubscriptionRootPlaceXUploadByPkArgs = {
  place_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootPlaceXUploadStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IPlaceXUploadStreamCursorInput>>;
  where?: InputMaybe<IPlaceXUploadBoolExp>;
};


export type ISubscriptionRootProjectArgs = {
  distinct_on?: InputMaybe<Array<IProjectSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IProjectOrderBy>>;
  where?: InputMaybe<IProjectBoolExp>;
};


export type ISubscriptionRootProjectAggregateArgs = {
  distinct_on?: InputMaybe<Array<IProjectSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IProjectOrderBy>>;
  where?: InputMaybe<IProjectBoolExp>;
};


export type ISubscriptionRootProjectByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootProjectStatusArgs = {
  distinct_on?: InputMaybe<Array<IProjectStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IProjectStatusOrderBy>>;
  where?: InputMaybe<IProjectStatusBoolExp>;
};


export type ISubscriptionRootProjectStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<IProjectStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IProjectStatusOrderBy>>;
  where?: InputMaybe<IProjectStatusBoolExp>;
};


export type ISubscriptionRootProjectStatusByPkArgs = {
  value: Scalars['String']['input'];
};


export type ISubscriptionRootProjectStatusStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IProjectStatusStreamCursorInput>>;
  where?: InputMaybe<IProjectStatusBoolExp>;
};


export type ISubscriptionRootProjectStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IProjectStreamCursorInput>>;
  where?: InputMaybe<IProjectBoolExp>;
};


export type ISubscriptionRootProjectXTeamArgs = {
  distinct_on?: InputMaybe<Array<IProjectXTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IProjectXTeamOrderBy>>;
  where?: InputMaybe<IProjectXTeamBoolExp>;
};


export type ISubscriptionRootProjectXTeamAggregateArgs = {
  distinct_on?: InputMaybe<Array<IProjectXTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IProjectXTeamOrderBy>>;
  where?: InputMaybe<IProjectXTeamBoolExp>;
};


export type ISubscriptionRootProjectXTeamByPkArgs = {
  project_id: Scalars['uuid']['input'];
  team_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootProjectXTeamStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IProjectXTeamStreamCursorInput>>;
  where?: InputMaybe<IProjectXTeamBoolExp>;
};


export type ISubscriptionRootRecurrenceArgs = {
  distinct_on?: InputMaybe<Array<IRecurrenceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IRecurrenceOrderBy>>;
  where?: InputMaybe<IRecurrenceBoolExp>;
};


export type ISubscriptionRootRecurrenceAggregateArgs = {
  distinct_on?: InputMaybe<Array<IRecurrenceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IRecurrenceOrderBy>>;
  where?: InputMaybe<IRecurrenceBoolExp>;
};


export type ISubscriptionRootRecurrenceByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootRecurrencePatternArgs = {
  distinct_on?: InputMaybe<Array<IRecurrencePatternSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IRecurrencePatternOrderBy>>;
  where?: InputMaybe<IRecurrencePatternBoolExp>;
};


export type ISubscriptionRootRecurrencePatternAggregateArgs = {
  distinct_on?: InputMaybe<Array<IRecurrencePatternSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IRecurrencePatternOrderBy>>;
  where?: InputMaybe<IRecurrencePatternBoolExp>;
};


export type ISubscriptionRootRecurrencePatternByPkArgs = {
  value: Scalars['String']['input'];
};


export type ISubscriptionRootRecurrencePatternStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IRecurrencePatternStreamCursorInput>>;
  where?: InputMaybe<IRecurrencePatternBoolExp>;
};


export type ISubscriptionRootRecurrenceStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IRecurrenceStreamCursorInput>>;
  where?: InputMaybe<IRecurrenceBoolExp>;
};


export type ISubscriptionRootScheduledTaskArgs = {
  distinct_on?: InputMaybe<Array<IScheduledTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IScheduledTaskOrderBy>>;
  where?: InputMaybe<IScheduledTaskBoolExp>;
};


export type ISubscriptionRootScheduledTaskAggregateArgs = {
  distinct_on?: InputMaybe<Array<IScheduledTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IScheduledTaskOrderBy>>;
  where?: InputMaybe<IScheduledTaskBoolExp>;
};


export type ISubscriptionRootScheduledTaskByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootScheduledTaskStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IScheduledTaskStreamCursorInput>>;
  where?: InputMaybe<IScheduledTaskBoolExp>;
};


export type ISubscriptionRootSearchAssetArgs = {
  args: ISearchAssetArgs;
  distinct_on?: InputMaybe<Array<IAssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetOrderBy>>;
  where?: InputMaybe<IAssetBoolExp>;
};


export type ISubscriptionRootSearchAssetAggregateArgs = {
  args: ISearchAssetArgs;
  distinct_on?: InputMaybe<Array<IAssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetOrderBy>>;
  where?: InputMaybe<IAssetBoolExp>;
};


export type ISubscriptionRootSearchMaintenanceArgs = {
  args: ISearchMaintenanceArgs;
  distinct_on?: InputMaybe<Array<IMaintenanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMaintenanceOrderBy>>;
  where?: InputMaybe<IMaintenanceBoolExp>;
};


export type ISubscriptionRootSearchMaintenanceAggregateArgs = {
  args: ISearchMaintenanceArgs;
  distinct_on?: InputMaybe<Array<IMaintenanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMaintenanceOrderBy>>;
  where?: InputMaybe<IMaintenanceBoolExp>;
};


export type ISubscriptionRootSearchWorkOrderArgs = {
  args: ISearchWorkOrderArgs;
  distinct_on?: InputMaybe<Array<IWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOrderBy>>;
  where?: InputMaybe<IWorkOrderBoolExp>;
};


export type ISubscriptionRootSearchWorkOrderAggregateArgs = {
  args: ISearchWorkOrderArgs;
  distinct_on?: InputMaybe<Array<IWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOrderBy>>;
  where?: InputMaybe<IWorkOrderBoolExp>;
};


export type ISubscriptionRootServiceRequestArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestOrderBy>>;
  where?: InputMaybe<IServiceRequestBoolExp>;
};


export type ISubscriptionRootServiceRequestAggregateArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestOrderBy>>;
  where?: InputMaybe<IServiceRequestBoolExp>;
};


export type ISubscriptionRootServiceRequestByPkArgs = {
  id: Scalars['Int']['input'];
};


export type ISubscriptionRootServiceRequestStatusArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestStatusOrderBy>>;
  where?: InputMaybe<IServiceRequestStatusBoolExp>;
};


export type ISubscriptionRootServiceRequestStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestStatusOrderBy>>;
  where?: InputMaybe<IServiceRequestStatusBoolExp>;
};


export type ISubscriptionRootServiceRequestStatusByPkArgs = {
  value: Scalars['String']['input'];
};


export type ISubscriptionRootServiceRequestStatusStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IServiceRequestStatusStreamCursorInput>>;
  where?: InputMaybe<IServiceRequestStatusBoolExp>;
};


export type ISubscriptionRootServiceRequestStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IServiceRequestStreamCursorInput>>;
  where?: InputMaybe<IServiceRequestBoolExp>;
};


export type ISubscriptionRootServiceRequestXAssetArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestXAssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestXAssetOrderBy>>;
  where?: InputMaybe<IServiceRequestXAssetBoolExp>;
};


export type ISubscriptionRootServiceRequestXAssetAggregateArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestXAssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestXAssetOrderBy>>;
  where?: InputMaybe<IServiceRequestXAssetBoolExp>;
};


export type ISubscriptionRootServiceRequestXAssetByPkArgs = {
  asset_id: Scalars['uuid']['input'];
  service_request_id: Scalars['Int']['input'];
};


export type ISubscriptionRootServiceRequestXAssetStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IServiceRequestXAssetStreamCursorInput>>;
  where?: InputMaybe<IServiceRequestXAssetBoolExp>;
};


export type ISubscriptionRootServiceRequestXTeamArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestXTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestXTeamOrderBy>>;
  where?: InputMaybe<IServiceRequestXTeamBoolExp>;
};


export type ISubscriptionRootServiceRequestXTeamAggregateArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestXTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestXTeamOrderBy>>;
  where?: InputMaybe<IServiceRequestXTeamBoolExp>;
};


export type ISubscriptionRootServiceRequestXTeamByPkArgs = {
  service_request_id: Scalars['Int']['input'];
  team_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootServiceRequestXTeamStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IServiceRequestXTeamStreamCursorInput>>;
  where?: InputMaybe<IServiceRequestXTeamBoolExp>;
};


export type ISubscriptionRootServiceRequestXUploadArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestXUploadOrderBy>>;
  where?: InputMaybe<IServiceRequestXUploadBoolExp>;
};


export type ISubscriptionRootServiceRequestXUploadAggregateArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestXUploadOrderBy>>;
  where?: InputMaybe<IServiceRequestXUploadBoolExp>;
};


export type ISubscriptionRootServiceRequestXUploadByPkArgs = {
  service_request_id: Scalars['Int']['input'];
  upload_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootServiceRequestXUploadStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IServiceRequestXUploadStreamCursorInput>>;
  where?: InputMaybe<IServiceRequestXUploadBoolExp>;
};


export type ISubscriptionRootServiceRequestXUserArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestXUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestXUserOrderBy>>;
  where?: InputMaybe<IServiceRequestXUserBoolExp>;
};


export type ISubscriptionRootServiceRequestXUserAggregateArgs = {
  distinct_on?: InputMaybe<Array<IServiceRequestXUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IServiceRequestXUserOrderBy>>;
  where?: InputMaybe<IServiceRequestXUserBoolExp>;
};


export type ISubscriptionRootServiceRequestXUserByPkArgs = {
  service_request_id: Scalars['Int']['input'];
  user_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootServiceRequestXUserStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IServiceRequestXUserStreamCursorInput>>;
  where?: InputMaybe<IServiceRequestXUserBoolExp>;
};


export type ISubscriptionRootSettingConfigArgs = {
  distinct_on?: InputMaybe<Array<ISettingConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ISettingConfigOrderBy>>;
  where?: InputMaybe<ISettingConfigBoolExp>;
};


export type ISubscriptionRootSettingConfigAggregateArgs = {
  distinct_on?: InputMaybe<Array<ISettingConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ISettingConfigOrderBy>>;
  where?: InputMaybe<ISettingConfigBoolExp>;
};


export type ISubscriptionRootSettingConfigByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootSettingConfigStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ISettingConfigStreamCursorInput>>;
  where?: InputMaybe<ISettingConfigBoolExp>;
};


export type ISubscriptionRootSettingLevelArgs = {
  distinct_on?: InputMaybe<Array<ISettingLevelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ISettingLevelOrderBy>>;
  where?: InputMaybe<ISettingLevelBoolExp>;
};


export type ISubscriptionRootSettingLevelAggregateArgs = {
  distinct_on?: InputMaybe<Array<ISettingLevelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ISettingLevelOrderBy>>;
  where?: InputMaybe<ISettingLevelBoolExp>;
};


export type ISubscriptionRootSettingLevelByPkArgs = {
  value: Scalars['String']['input'];
};


export type ISubscriptionRootSettingLevelStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ISettingLevelStreamCursorInput>>;
  where?: InputMaybe<ISettingLevelBoolExp>;
};


export type ISubscriptionRootSettingTypeArgs = {
  distinct_on?: InputMaybe<Array<ISettingTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ISettingTypeOrderBy>>;
  where?: InputMaybe<ISettingTypeBoolExp>;
};


export type ISubscriptionRootSettingTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<ISettingTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ISettingTypeOrderBy>>;
  where?: InputMaybe<ISettingTypeBoolExp>;
};


export type ISubscriptionRootSettingTypeByPkArgs = {
  value: Scalars['String']['input'];
};


export type ISubscriptionRootSettingTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ISettingTypeStreamCursorInput>>;
  where?: InputMaybe<ISettingTypeBoolExp>;
};


export type ISubscriptionRootTeamArgs = {
  distinct_on?: InputMaybe<Array<ITeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ITeamOrderBy>>;
  where?: InputMaybe<ITeamBoolExp>;
};


export type ISubscriptionRootTeamAggregateArgs = {
  distinct_on?: InputMaybe<Array<ITeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ITeamOrderBy>>;
  where?: InputMaybe<ITeamBoolExp>;
};


export type ISubscriptionRootTeamByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootTeamMemberArgs = {
  distinct_on?: InputMaybe<Array<ITeamMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ITeamMemberOrderBy>>;
  where?: InputMaybe<ITeamMemberBoolExp>;
};


export type ISubscriptionRootTeamMemberAggregateArgs = {
  distinct_on?: InputMaybe<Array<ITeamMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ITeamMemberOrderBy>>;
  where?: InputMaybe<ITeamMemberBoolExp>;
};


export type ISubscriptionRootTeamMemberByPkArgs = {
  team_id: Scalars['uuid']['input'];
  user_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootTeamMemberStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ITeamMemberStreamCursorInput>>;
  where?: InputMaybe<ITeamMemberBoolExp>;
};


export type ISubscriptionRootTeamStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ITeamStreamCursorInput>>;
  where?: InputMaybe<ITeamBoolExp>;
};


export type ISubscriptionRootTeamUserRoleArgs = {
  distinct_on?: InputMaybe<Array<ITeamUserRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ITeamUserRoleOrderBy>>;
  where?: InputMaybe<ITeamUserRoleBoolExp>;
};


export type ISubscriptionRootTeamUserRoleAggregateArgs = {
  distinct_on?: InputMaybe<Array<ITeamUserRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ITeamUserRoleOrderBy>>;
  where?: InputMaybe<ITeamUserRoleBoolExp>;
};


export type ISubscriptionRootTeamUserRoleByPkArgs = {
  value: Scalars['String']['input'];
};


export type ISubscriptionRootTeamUserRoleStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ITeamUserRoleStreamCursorInput>>;
  where?: InputMaybe<ITeamUserRoleBoolExp>;
};


export type ISubscriptionRootUploadArgs = {
  distinct_on?: InputMaybe<Array<IUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUploadOrderBy>>;
  where?: InputMaybe<IUploadBoolExp>;
};


export type ISubscriptionRootUploadAggregateArgs = {
  distinct_on?: InputMaybe<Array<IUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUploadOrderBy>>;
  where?: InputMaybe<IUploadBoolExp>;
};


export type ISubscriptionRootUploadByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootUploadCategoryArgs = {
  distinct_on?: InputMaybe<Array<IUploadCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUploadCategoryOrderBy>>;
  where?: InputMaybe<IUploadCategoryBoolExp>;
};


export type ISubscriptionRootUploadCategoryAggregateArgs = {
  distinct_on?: InputMaybe<Array<IUploadCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUploadCategoryOrderBy>>;
  where?: InputMaybe<IUploadCategoryBoolExp>;
};


export type ISubscriptionRootUploadCategoryByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootUploadCategoryStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IUploadCategoryStreamCursorInput>>;
  where?: InputMaybe<IUploadCategoryBoolExp>;
};


export type ISubscriptionRootUploadStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IUploadStreamCursorInput>>;
  where?: InputMaybe<IUploadBoolExp>;
};


export type ISubscriptionRootUploadXUploadCategoryArgs = {
  distinct_on?: InputMaybe<Array<IUploadXUploadCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUploadXUploadCategoryOrderBy>>;
  where?: InputMaybe<IUploadXUploadCategoryBoolExp>;
};


export type ISubscriptionRootUploadXUploadCategoryAggregateArgs = {
  distinct_on?: InputMaybe<Array<IUploadXUploadCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUploadXUploadCategoryOrderBy>>;
  where?: InputMaybe<IUploadXUploadCategoryBoolExp>;
};


export type ISubscriptionRootUploadXUploadCategoryByPkArgs = {
  upload_category_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootUploadXUploadCategoryStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IUploadXUploadCategoryStreamCursorInput>>;
  where?: InputMaybe<IUploadXUploadCategoryBoolExp>;
};


export type ISubscriptionRootUserArgs = {
  distinct_on?: InputMaybe<Array<IUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUserOrderBy>>;
  where?: InputMaybe<IUserBoolExp>;
};


export type ISubscriptionRootUserAggregateArgs = {
  distinct_on?: InputMaybe<Array<IUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUserOrderBy>>;
  where?: InputMaybe<IUserBoolExp>;
};


export type ISubscriptionRootUserByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootUserFavoriteArgs = {
  distinct_on?: InputMaybe<Array<IUserFavoriteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUserFavoriteOrderBy>>;
  where?: InputMaybe<IUserFavoriteBoolExp>;
};


export type ISubscriptionRootUserFavoriteAggregateArgs = {
  distinct_on?: InputMaybe<Array<IUserFavoriteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUserFavoriteOrderBy>>;
  where?: InputMaybe<IUserFavoriteBoolExp>;
};


export type ISubscriptionRootUserFavoriteByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootUserFavoriteStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IUserFavoriteStreamCursorInput>>;
  where?: InputMaybe<IUserFavoriteBoolExp>;
};


export type ISubscriptionRootUserRecentlyUsedArgs = {
  distinct_on?: InputMaybe<Array<IUserRecentlyUsedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUserRecentlyUsedOrderBy>>;
  where?: InputMaybe<IUserRecentlyUsedBoolExp>;
};


export type ISubscriptionRootUserRecentlyUsedAggregateArgs = {
  distinct_on?: InputMaybe<Array<IUserRecentlyUsedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUserRecentlyUsedOrderBy>>;
  where?: InputMaybe<IUserRecentlyUsedBoolExp>;
};


export type ISubscriptionRootUserRecentlyUsedByPkArgs = {
  entity_id: Scalars['uuid']['input'];
  entity_type: Scalars['String']['input'];
  user_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootUserRecentlyUsedStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IUserRecentlyUsedStreamCursorInput>>;
  where?: InputMaybe<IUserRecentlyUsedBoolExp>;
};


export type ISubscriptionRootUserStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IUserStreamCursorInput>>;
  where?: InputMaybe<IUserBoolExp>;
};


export type ISubscriptionRootViewCollectionArgs = {
  distinct_on?: InputMaybe<Array<IViewCollectionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IViewCollectionOrderBy>>;
  where?: InputMaybe<IViewCollectionBoolExp>;
};


export type ISubscriptionRootViewCollectionAggregateArgs = {
  distinct_on?: InputMaybe<Array<IViewCollectionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IViewCollectionOrderBy>>;
  where?: InputMaybe<IViewCollectionBoolExp>;
};


export type ISubscriptionRootViewCollectionByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootViewCollectionStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IViewCollectionStreamCursorInput>>;
  where?: InputMaybe<IViewCollectionBoolExp>;
};


export type ISubscriptionRootViewConfigArgs = {
  distinct_on?: InputMaybe<Array<IViewConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IViewConfigOrderBy>>;
  where?: InputMaybe<IViewConfigBoolExp>;
};


export type ISubscriptionRootViewConfigAggregateArgs = {
  distinct_on?: InputMaybe<Array<IViewConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IViewConfigOrderBy>>;
  where?: InputMaybe<IViewConfigBoolExp>;
};


export type ISubscriptionRootViewConfigByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootViewConfigStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IViewConfigStreamCursorInput>>;
  where?: InputMaybe<IViewConfigBoolExp>;
};


export type ISubscriptionRootViewDataTypeArgs = {
  distinct_on?: InputMaybe<Array<IViewDataTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IViewDataTypeOrderBy>>;
  where?: InputMaybe<IViewDataTypeBoolExp>;
};


export type ISubscriptionRootViewDataTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<IViewDataTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IViewDataTypeOrderBy>>;
  where?: InputMaybe<IViewDataTypeBoolExp>;
};


export type ISubscriptionRootViewDataTypeByPkArgs = {
  value: Scalars['String']['input'];
};


export type ISubscriptionRootViewDataTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IViewDataTypeStreamCursorInput>>;
  where?: InputMaybe<IViewDataTypeBoolExp>;
};


export type ISubscriptionRootWorkOrderArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOrderBy>>;
  where?: InputMaybe<IWorkOrderBoolExp>;
};


export type ISubscriptionRootWorkOrderActivityArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderActivityOrderBy>>;
  where?: InputMaybe<IWorkOrderActivityBoolExp>;
};


export type ISubscriptionRootWorkOrderActivityAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderActivityOrderBy>>;
  where?: InputMaybe<IWorkOrderActivityBoolExp>;
};


export type ISubscriptionRootWorkOrderActivityByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootWorkOrderActivityStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IWorkOrderActivityStreamCursorInput>>;
  where?: InputMaybe<IWorkOrderActivityBoolExp>;
};


export type ISubscriptionRootWorkOrderActivityTypeArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderActivityTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderActivityTypeOrderBy>>;
  where?: InputMaybe<IWorkOrderActivityTypeBoolExp>;
};


export type ISubscriptionRootWorkOrderActivityTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderActivityTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderActivityTypeOrderBy>>;
  where?: InputMaybe<IWorkOrderActivityTypeBoolExp>;
};


export type ISubscriptionRootWorkOrderActivityTypeByPkArgs = {
  value: Scalars['String']['input'];
};


export type ISubscriptionRootWorkOrderActivityTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IWorkOrderActivityTypeStreamCursorInput>>;
  where?: InputMaybe<IWorkOrderActivityTypeBoolExp>;
};


export type ISubscriptionRootWorkOrderAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOrderBy>>;
  where?: InputMaybe<IWorkOrderBoolExp>;
};


export type ISubscriptionRootWorkOrderByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootWorkOrderCategoryArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderCategoryOrderBy>>;
  where?: InputMaybe<IWorkOrderCategoryBoolExp>;
};


export type ISubscriptionRootWorkOrderCategoryAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderCategoryOrderBy>>;
  where?: InputMaybe<IWorkOrderCategoryBoolExp>;
};


export type ISubscriptionRootWorkOrderCategoryByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootWorkOrderCategoryStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IWorkOrderCategoryStreamCursorInput>>;
  where?: InputMaybe<IWorkOrderCategoryBoolExp>;
};


export type ISubscriptionRootWorkOrderCompletedByCategoryViewArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderCompletedByCategoryViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderCompletedByCategoryViewOrderBy>>;
  where?: InputMaybe<IWorkOrderCompletedByCategoryViewBoolExp>;
};


export type ISubscriptionRootWorkOrderCompletedByCategoryViewAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderCompletedByCategoryViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderCompletedByCategoryViewOrderBy>>;
  where?: InputMaybe<IWorkOrderCompletedByCategoryViewBoolExp>;
};


export type ISubscriptionRootWorkOrderCompletedByCategoryViewStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IWorkOrderCompletedByCategoryViewStreamCursorInput>>;
  where?: InputMaybe<IWorkOrderCompletedByCategoryViewBoolExp>;
};


export type ISubscriptionRootWorkOrderCompletedOntimeVsOverdueArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderCompletedOntimeVsOverdueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderCompletedOntimeVsOverdueOrderBy>>;
  where?: InputMaybe<IWorkOrderCompletedOntimeVsOverdueBoolExp>;
};


export type ISubscriptionRootWorkOrderCompletedOntimeVsOverdueAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderCompletedOntimeVsOverdueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderCompletedOntimeVsOverdueOrderBy>>;
  where?: InputMaybe<IWorkOrderCompletedOntimeVsOverdueBoolExp>;
};


export type ISubscriptionRootWorkOrderCompletedOntimeVsOverdueStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IWorkOrderCompletedOntimeVsOverdueStreamCursorInput>>;
  where?: InputMaybe<IWorkOrderCompletedOntimeVsOverdueBoolExp>;
};


export type ISubscriptionRootWorkOrderExpenseArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderExpenseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderExpenseOrderBy>>;
  where?: InputMaybe<IWorkOrderExpenseBoolExp>;
};


export type ISubscriptionRootWorkOrderExpenseAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderExpenseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderExpenseOrderBy>>;
  where?: InputMaybe<IWorkOrderExpenseBoolExp>;
};


export type ISubscriptionRootWorkOrderExpenseByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootWorkOrderExpenseStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IWorkOrderExpenseStreamCursorInput>>;
  where?: InputMaybe<IWorkOrderExpenseBoolExp>;
};


export type ISubscriptionRootWorkOrderFeedbackArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderFeedbackSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderFeedbackOrderBy>>;
  where?: InputMaybe<IWorkOrderFeedbackBoolExp>;
};


export type ISubscriptionRootWorkOrderFeedbackAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderFeedbackSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderFeedbackOrderBy>>;
  where?: InputMaybe<IWorkOrderFeedbackBoolExp>;
};


export type ISubscriptionRootWorkOrderFeedbackByPkArgs = {
  work_order_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootWorkOrderFeedbackStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IWorkOrderFeedbackStreamCursorInput>>;
  where?: InputMaybe<IWorkOrderFeedbackBoolExp>;
};


export type ISubscriptionRootWorkOrderLastUpdateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderLastUpdateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderLastUpdateOrderBy>>;
  where?: InputMaybe<IWorkOrderLastUpdateBoolExp>;
};


export type ISubscriptionRootWorkOrderLastUpdateAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderLastUpdateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderLastUpdateOrderBy>>;
  where?: InputMaybe<IWorkOrderLastUpdateBoolExp>;
};


export type ISubscriptionRootWorkOrderLastUpdateStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IWorkOrderLastUpdateStreamCursorInput>>;
  where?: InputMaybe<IWorkOrderLastUpdateBoolExp>;
};


export type ISubscriptionRootWorkOrderOpenGroupedByDueDateViewArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderOpenGroupedByDueDateViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOpenGroupedByDueDateViewOrderBy>>;
  where?: InputMaybe<IWorkOrderOpenGroupedByDueDateViewBoolExp>;
};


export type ISubscriptionRootWorkOrderOpenGroupedByDueDateViewAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderOpenGroupedByDueDateViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOpenGroupedByDueDateViewOrderBy>>;
  where?: InputMaybe<IWorkOrderOpenGroupedByDueDateViewBoolExp>;
};


export type ISubscriptionRootWorkOrderOpenGroupedByDueDateViewStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IWorkOrderOpenGroupedByDueDateViewStreamCursorInput>>;
  where?: InputMaybe<IWorkOrderOpenGroupedByDueDateViewBoolExp>;
};


export type ISubscriptionRootWorkOrderPriorityArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderPrioritySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderPriorityOrderBy>>;
  where?: InputMaybe<IWorkOrderPriorityBoolExp>;
};


export type ISubscriptionRootWorkOrderPriorityAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderPrioritySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderPriorityOrderBy>>;
  where?: InputMaybe<IWorkOrderPriorityBoolExp>;
};


export type ISubscriptionRootWorkOrderPriorityByPkArgs = {
  value: Scalars['String']['input'];
};


export type ISubscriptionRootWorkOrderPriorityStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IWorkOrderPriorityStreamCursorInput>>;
  where?: InputMaybe<IWorkOrderPriorityBoolExp>;
};


export type ISubscriptionRootWorkOrderRecurringViewArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderRecurringViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderRecurringViewOrderBy>>;
  where?: InputMaybe<IWorkOrderRecurringViewBoolExp>;
};


export type ISubscriptionRootWorkOrderRecurringViewAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderRecurringViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderRecurringViewOrderBy>>;
  where?: InputMaybe<IWorkOrderRecurringViewBoolExp>;
};


export type ISubscriptionRootWorkOrderRecurringViewStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IWorkOrderRecurringViewStreamCursorInput>>;
  where?: InputMaybe<IWorkOrderRecurringViewBoolExp>;
};


export type ISubscriptionRootWorkOrderReportArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderReportSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderReportOrderBy>>;
  where?: InputMaybe<IWorkOrderReportBoolExp>;
};


export type ISubscriptionRootWorkOrderReportAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderReportSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderReportOrderBy>>;
  where?: InputMaybe<IWorkOrderReportBoolExp>;
};


export type ISubscriptionRootWorkOrderReportByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootWorkOrderReportStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IWorkOrderReportStreamCursorInput>>;
  where?: InputMaybe<IWorkOrderReportBoolExp>;
};


export type ISubscriptionRootWorkOrderStatusArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderStatusOrderBy>>;
  where?: InputMaybe<IWorkOrderStatusBoolExp>;
};


export type ISubscriptionRootWorkOrderStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderStatusOrderBy>>;
  where?: InputMaybe<IWorkOrderStatusBoolExp>;
};


export type ISubscriptionRootWorkOrderStatusByPkArgs = {
  value: Scalars['String']['input'];
};


export type ISubscriptionRootWorkOrderStatusStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IWorkOrderStatusStreamCursorInput>>;
  where?: InputMaybe<IWorkOrderStatusBoolExp>;
};


export type ISubscriptionRootWorkOrderStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IWorkOrderStreamCursorInput>>;
  where?: InputMaybe<IWorkOrderBoolExp>;
};


export type ISubscriptionRootWorkOrderTemplateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderTemplateOrderBy>>;
  where?: InputMaybe<IWorkOrderTemplateBoolExp>;
};


export type ISubscriptionRootWorkOrderTemplateAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderTemplateOrderBy>>;
  where?: InputMaybe<IWorkOrderTemplateBoolExp>;
};


export type ISubscriptionRootWorkOrderTemplateByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type ISubscriptionRootWorkOrderTemplateStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IWorkOrderTemplateStreamCursorInput>>;
  where?: InputMaybe<IWorkOrderTemplateBoolExp>;
};


export type ISubscriptionRootWorkOrderTypeArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderTypeOrderBy>>;
  where?: InputMaybe<IWorkOrderTypeBoolExp>;
};


export type ISubscriptionRootWorkOrderTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderTypeOrderBy>>;
  where?: InputMaybe<IWorkOrderTypeBoolExp>;
};


export type ISubscriptionRootWorkOrderTypeByPkArgs = {
  value: Scalars['String']['input'];
};


export type ISubscriptionRootWorkOrderTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IWorkOrderTypeStreamCursorInput>>;
  where?: InputMaybe<IWorkOrderTypeBoolExp>;
};


export type ISubscriptionRootWorkOrderWithLastRecurringInstanceViewArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderWithLastRecurringInstanceViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderWithLastRecurringInstanceViewOrderBy>>;
  where?: InputMaybe<IWorkOrderWithLastRecurringInstanceViewBoolExp>;
};


export type ISubscriptionRootWorkOrderWithLastRecurringInstanceViewAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderWithLastRecurringInstanceViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderWithLastRecurringInstanceViewOrderBy>>;
  where?: InputMaybe<IWorkOrderWithLastRecurringInstanceViewBoolExp>;
};


export type ISubscriptionRootWorkOrderWithLastRecurringInstanceViewStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IWorkOrderWithLastRecurringInstanceViewStreamCursorInput>>;
  where?: InputMaybe<IWorkOrderWithLastRecurringInstanceViewBoolExp>;
};


export type ISubscriptionRootWorkOrderWithOpenDueDateReminderArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderWithOpenDueDateReminderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderWithOpenDueDateReminderOrderBy>>;
  where?: InputMaybe<IWorkOrderWithOpenDueDateReminderBoolExp>;
};


export type ISubscriptionRootWorkOrderWithOpenDueDateReminderAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderWithOpenDueDateReminderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderWithOpenDueDateReminderOrderBy>>;
  where?: InputMaybe<IWorkOrderWithOpenDueDateReminderBoolExp>;
};


export type ISubscriptionRootWorkOrderWithOpenDueDateReminderStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IWorkOrderWithOpenDueDateReminderStreamCursorInput>>;
  where?: InputMaybe<IWorkOrderWithOpenDueDateReminderBoolExp>;
};


export type ISubscriptionRootWorkOrderXCollaboratorArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXCollaboratorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXCollaboratorOrderBy>>;
  where?: InputMaybe<IWorkOrderXCollaboratorBoolExp>;
};


export type ISubscriptionRootWorkOrderXCollaboratorAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXCollaboratorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXCollaboratorOrderBy>>;
  where?: InputMaybe<IWorkOrderXCollaboratorBoolExp>;
};


export type ISubscriptionRootWorkOrderXCollaboratorByPkArgs = {
  user_id: Scalars['uuid']['input'];
  work_order_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootWorkOrderXCollaboratorStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IWorkOrderXCollaboratorStreamCursorInput>>;
  where?: InputMaybe<IWorkOrderXCollaboratorBoolExp>;
};


export type ISubscriptionRootWorkOrderXTeamArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXTeamOrderBy>>;
  where?: InputMaybe<IWorkOrderXTeamBoolExp>;
};


export type ISubscriptionRootWorkOrderXTeamAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXTeamOrderBy>>;
  where?: InputMaybe<IWorkOrderXTeamBoolExp>;
};


export type ISubscriptionRootWorkOrderXTeamByPkArgs = {
  team_id: Scalars['uuid']['input'];
  work_order_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootWorkOrderXTeamStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IWorkOrderXTeamStreamCursorInput>>;
  where?: InputMaybe<IWorkOrderXTeamBoolExp>;
};


export type ISubscriptionRootWorkOrderXUploadArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXUploadOrderBy>>;
  where?: InputMaybe<IWorkOrderXUploadBoolExp>;
};


export type ISubscriptionRootWorkOrderXUploadAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXUploadOrderBy>>;
  where?: InputMaybe<IWorkOrderXUploadBoolExp>;
};


export type ISubscriptionRootWorkOrderXUploadByPkArgs = {
  upload_id: Scalars['uuid']['input'];
  work_order_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootWorkOrderXUploadStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IWorkOrderXUploadStreamCursorInput>>;
  where?: InputMaybe<IWorkOrderXUploadBoolExp>;
};


export type ISubscriptionRootWorkOrderXUserArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXUserOrderBy>>;
  where?: InputMaybe<IWorkOrderXUserBoolExp>;
};


export type ISubscriptionRootWorkOrderXUserAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXUserOrderBy>>;
  where?: InputMaybe<IWorkOrderXUserBoolExp>;
};


export type ISubscriptionRootWorkOrderXUserByPkArgs = {
  user_id: Scalars['uuid']['input'];
  work_order_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootWorkOrderXUserStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IWorkOrderXUserStreamCursorInput>>;
  where?: InputMaybe<IWorkOrderXUserBoolExp>;
};


export type ISubscriptionRootWorkOrderXWorkOrderCategoryArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXWorkOrderCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXWorkOrderCategoryOrderBy>>;
  where?: InputMaybe<IWorkOrderXWorkOrderCategoryBoolExp>;
};


export type ISubscriptionRootWorkOrderXWorkOrderCategoryAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXWorkOrderCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXWorkOrderCategoryOrderBy>>;
  where?: InputMaybe<IWorkOrderXWorkOrderCategoryBoolExp>;
};


export type ISubscriptionRootWorkOrderXWorkOrderCategoryByPkArgs = {
  work_order_category_id: Scalars['uuid']['input'];
  work_order_id: Scalars['uuid']['input'];
};


export type ISubscriptionRootWorkOrderXWorkOrderCategoryStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IWorkOrderXWorkOrderCategoryStreamCursorInput>>;
  where?: InputMaybe<IWorkOrderXWorkOrderCategoryBoolExp>;
};


export type ISubscriptionRootWorkOrdersNeedingDueDateReminderArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOrderBy>>;
  where?: InputMaybe<IWorkOrderBoolExp>;
};


export type ISubscriptionRootWorkOrdersNeedingDueDateReminderAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOrderBy>>;
  where?: InputMaybe<IWorkOrderBoolExp>;
};

/** columns and relationships of "team" */
export type ITeam = {
  __typename: 'team';
  archived_at?: Maybe<Scalars['timestamptz']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  config: Scalars['jsonb']['output'];
  created_at: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['json']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  location: ILocation;
  location_id: Scalars['uuid']['output'];
  /** An array relationship */
  members: Array<ITeamMember>;
  /** An aggregate relationship */
  members_aggregate: ITeamMemberAggregate;
  name: Scalars['String']['output'];
  /** A computed field, executes function "notification_is_enabled" */
  notification_is_enabled?: Maybe<Scalars['Boolean']['output']>;
  notification_settings?: Maybe<Scalars['jsonb']['output']>;
  owner_id?: Maybe<Scalars['uuid']['output']>;
  private?: Maybe<Scalars['Boolean']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "team" */
export type ITeamConfigArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "team" */
export type ITeamIconArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "team" */
export type ITeamMembersArgs = {
  distinct_on?: InputMaybe<Array<ITeamMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ITeamMemberOrderBy>>;
  where?: InputMaybe<ITeamMemberBoolExp>;
};


/** columns and relationships of "team" */
export type ITeamMembersAggregateArgs = {
  distinct_on?: InputMaybe<Array<ITeamMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ITeamMemberOrderBy>>;
  where?: InputMaybe<ITeamMemberBoolExp>;
};


/** columns and relationships of "team" */
export type ITeamNotificationSettingsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "team" */
export type ITeamAggregate = {
  __typename: 'team_aggregate';
  aggregate?: Maybe<ITeamAggregateFields>;
  nodes: Array<ITeam>;
};

export type ITeamAggregateBoolExp = {
  bool_and?: InputMaybe<ITeamAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<ITeamAggregateBoolExpBoolOr>;
  count?: InputMaybe<ITeamAggregateBoolExpCount>;
};

export type ITeamAggregateBoolExpBoolAnd = {
  arguments: ITeamSelectColumnTeamAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ITeamBoolExp>;
  predicate: IBooleanComparisonExp;
};

export type ITeamAggregateBoolExpBoolOr = {
  arguments: ITeamSelectColumnTeamAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ITeamBoolExp>;
  predicate: IBooleanComparisonExp;
};

export type ITeamAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ITeamSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ITeamBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "team" */
export type ITeamAggregateFields = {
  __typename: 'team_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ITeamMaxFields>;
  min?: Maybe<ITeamMinFields>;
};


/** aggregate fields of "team" */
export type ITeamAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ITeamSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "team" */
export type ITeamAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<ITeamMaxOrderBy>;
  min?: InputMaybe<ITeamMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ITeamAppendInput = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  notification_settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "team" */
export type ITeamArrRelInsertInput = {
  data: Array<ITeamInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ITeamOnConflict>;
};

/** Boolean expression to filter rows from the table "team". All fields are combined with a logical 'AND'. */
export type ITeamBoolExp = {
  _and?: InputMaybe<Array<ITeamBoolExp>>;
  _not?: InputMaybe<ITeamBoolExp>;
  _or?: InputMaybe<Array<ITeamBoolExp>>;
  archived_at?: InputMaybe<ITimestamptzComparisonExp>;
  category?: InputMaybe<IStringComparisonExp>;
  config?: InputMaybe<IJsonbComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  icon?: InputMaybe<IJsonComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  location?: InputMaybe<ILocationBoolExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  members?: InputMaybe<ITeamMemberBoolExp>;
  members_aggregate?: InputMaybe<ITeamMemberAggregateBoolExp>;
  name?: InputMaybe<IStringComparisonExp>;
  notification_is_enabled?: InputMaybe<IBooleanComparisonExp>;
  notification_settings?: InputMaybe<IJsonbComparisonExp>;
  owner_id?: InputMaybe<IUuidComparisonExp>;
  private?: InputMaybe<IBooleanComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "team" */
export enum ITeamConstraint {
  /** unique or primary key constraint on columns "location_id", "name" */
  TeamNameLocationIdKey = 'team_name_location_id_key',
  /** unique or primary key constraint on columns "id" */
  TeamPkey = 'team_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ITeamDeleteAtPathInput = {
  config?: InputMaybe<Array<Scalars['String']['input']>>;
  notification_settings?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ITeamDeleteElemInput = {
  config?: InputMaybe<Scalars['Int']['input']>;
  notification_settings?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ITeamDeleteKeyInput = {
  config?: InputMaybe<Scalars['String']['input']>;
  notification_settings?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "team" */
export type ITeamInsertInput = {
  archived_at?: InputMaybe<Scalars['timestamptz']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  config?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['json']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location?: InputMaybe<ILocationObjRelInsertInput>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  members?: InputMaybe<ITeamMemberArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  notification_settings?: InputMaybe<Scalars['jsonb']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  private?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type ITeamMaxFields = {
  __typename: 'team_max_fields';
  archived_at?: Maybe<Scalars['timestamptz']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owner_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "team" */
export type ITeamMaxOrderBy = {
  archived_at?: InputMaybe<IOrderBy>;
  category?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  owner_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** columns and relationships of "team_member" */
export type ITeamMember = {
  __typename: 'team_member';
  created_at: Scalars['timestamptz']['output'];
  role: ITeamUserRoleEnum;
  sort_order: Scalars['numeric']['output'];
  /** An object relationship */
  team: ITeam;
  team_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: IUser;
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "team_member" */
export type ITeamMemberAggregate = {
  __typename: 'team_member_aggregate';
  aggregate?: Maybe<ITeamMemberAggregateFields>;
  nodes: Array<ITeamMember>;
};

export type ITeamMemberAggregateBoolExp = {
  count?: InputMaybe<ITeamMemberAggregateBoolExpCount>;
};

export type ITeamMemberAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ITeamMemberSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ITeamMemberBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "team_member" */
export type ITeamMemberAggregateFields = {
  __typename: 'team_member_aggregate_fields';
  avg?: Maybe<ITeamMemberAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ITeamMemberMaxFields>;
  min?: Maybe<ITeamMemberMinFields>;
  stddev?: Maybe<ITeamMemberStddevFields>;
  stddev_pop?: Maybe<ITeamMemberStddevPopFields>;
  stddev_samp?: Maybe<ITeamMemberStddevSampFields>;
  sum?: Maybe<ITeamMemberSumFields>;
  var_pop?: Maybe<ITeamMemberVarPopFields>;
  var_samp?: Maybe<ITeamMemberVarSampFields>;
  variance?: Maybe<ITeamMemberVarianceFields>;
};


/** aggregate fields of "team_member" */
export type ITeamMemberAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ITeamMemberSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "team_member" */
export type ITeamMemberAggregateOrderBy = {
  avg?: InputMaybe<ITeamMemberAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<ITeamMemberMaxOrderBy>;
  min?: InputMaybe<ITeamMemberMinOrderBy>;
  stddev?: InputMaybe<ITeamMemberStddevOrderBy>;
  stddev_pop?: InputMaybe<ITeamMemberStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ITeamMemberStddevSampOrderBy>;
  sum?: InputMaybe<ITeamMemberSumOrderBy>;
  var_pop?: InputMaybe<ITeamMemberVarPopOrderBy>;
  var_samp?: InputMaybe<ITeamMemberVarSampOrderBy>;
  variance?: InputMaybe<ITeamMemberVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "team_member" */
export type ITeamMemberArrRelInsertInput = {
  data: Array<ITeamMemberInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ITeamMemberOnConflict>;
};

/** aggregate avg on columns */
export type ITeamMemberAvgFields = {
  __typename: 'team_member_avg_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "team_member" */
export type ITeamMemberAvgOrderBy = {
  sort_order?: InputMaybe<IOrderBy>;
};

/** Boolean expression to filter rows from the table "team_member". All fields are combined with a logical 'AND'. */
export type ITeamMemberBoolExp = {
  _and?: InputMaybe<Array<ITeamMemberBoolExp>>;
  _not?: InputMaybe<ITeamMemberBoolExp>;
  _or?: InputMaybe<Array<ITeamMemberBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  role?: InputMaybe<ITeamUserRoleEnumComparisonExp>;
  sort_order?: InputMaybe<INumericComparisonExp>;
  team?: InputMaybe<ITeamBoolExp>;
  team_id?: InputMaybe<IUuidComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  user?: InputMaybe<IUserBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "team_member" */
export enum ITeamMemberConstraint {
  /** unique or primary key constraint on columns "user_id", "team_id" */
  TeamMemberPkey = 'team_member_pkey'
}

/** input type for incrementing numeric columns in table "team_member" */
export type ITeamMemberIncInput = {
  sort_order?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "team_member" */
export type ITeamMemberInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  role?: InputMaybe<ITeamUserRoleEnum>;
  sort_order?: InputMaybe<Scalars['numeric']['input']>;
  team?: InputMaybe<ITeamObjRelInsertInput>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<IUserObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type ITeamMemberMaxFields = {
  __typename: 'team_member_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  sort_order?: Maybe<Scalars['numeric']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "team_member" */
export type ITeamMemberMaxOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  sort_order?: InputMaybe<IOrderBy>;
  team_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type ITeamMemberMinFields = {
  __typename: 'team_member_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  sort_order?: Maybe<Scalars['numeric']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "team_member" */
export type ITeamMemberMinOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  sort_order?: InputMaybe<IOrderBy>;
  team_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "team_member" */
export type ITeamMemberMutationResponse = {
  __typename: 'team_member_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ITeamMember>;
};

/** on_conflict condition type for table "team_member" */
export type ITeamMemberOnConflict = {
  constraint: ITeamMemberConstraint;
  update_columns?: Array<ITeamMemberUpdateColumn>;
  where?: InputMaybe<ITeamMemberBoolExp>;
};

/** Ordering options when selecting data from "team_member". */
export type ITeamMemberOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  role?: InputMaybe<IOrderBy>;
  sort_order?: InputMaybe<IOrderBy>;
  team?: InputMaybe<ITeamOrderBy>;
  team_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUserOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: team_member */
export type ITeamMemberPkColumnsInput = {
  team_id: Scalars['uuid']['input'];
  user_id: Scalars['uuid']['input'];
};

/** select columns of table "team_member" */
export enum ITeamMemberSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Role = 'role',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "team_member" */
export type ITeamMemberSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  role?: InputMaybe<ITeamUserRoleEnum>;
  sort_order?: InputMaybe<Scalars['numeric']['input']>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type ITeamMemberStddevFields = {
  __typename: 'team_member_stddev_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "team_member" */
export type ITeamMemberStddevOrderBy = {
  sort_order?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_pop on columns */
export type ITeamMemberStddevPopFields = {
  __typename: 'team_member_stddev_pop_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "team_member" */
export type ITeamMemberStddevPopOrderBy = {
  sort_order?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_samp on columns */
export type ITeamMemberStddevSampFields = {
  __typename: 'team_member_stddev_samp_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "team_member" */
export type ITeamMemberStddevSampOrderBy = {
  sort_order?: InputMaybe<IOrderBy>;
};

/** Streaming cursor of the table "team_member" */
export type ITeamMemberStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ITeamMemberStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ITeamMemberStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  role?: InputMaybe<ITeamUserRoleEnum>;
  sort_order?: InputMaybe<Scalars['numeric']['input']>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type ITeamMemberSumFields = {
  __typename: 'team_member_sum_fields';
  sort_order?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "team_member" */
export type ITeamMemberSumOrderBy = {
  sort_order?: InputMaybe<IOrderBy>;
};

/** update columns of table "team_member" */
export enum ITeamMemberUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Role = 'role',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type ITeamMemberUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ITeamMemberIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ITeamMemberSetInput>;
  /** filter the rows which have to be updated */
  where: ITeamMemberBoolExp;
};

/** aggregate var_pop on columns */
export type ITeamMemberVarPopFields = {
  __typename: 'team_member_var_pop_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "team_member" */
export type ITeamMemberVarPopOrderBy = {
  sort_order?: InputMaybe<IOrderBy>;
};

/** aggregate var_samp on columns */
export type ITeamMemberVarSampFields = {
  __typename: 'team_member_var_samp_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "team_member" */
export type ITeamMemberVarSampOrderBy = {
  sort_order?: InputMaybe<IOrderBy>;
};

/** aggregate variance on columns */
export type ITeamMemberVarianceFields = {
  __typename: 'team_member_variance_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "team_member" */
export type ITeamMemberVarianceOrderBy = {
  sort_order?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type ITeamMinFields = {
  __typename: 'team_min_fields';
  archived_at?: Maybe<Scalars['timestamptz']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owner_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "team" */
export type ITeamMinOrderBy = {
  archived_at?: InputMaybe<IOrderBy>;
  category?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  owner_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "team" */
export type ITeamMutationResponse = {
  __typename: 'team_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ITeam>;
};

/** input type for inserting object relation for remote table "team" */
export type ITeamObjRelInsertInput = {
  data: ITeamInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ITeamOnConflict>;
};

/** on_conflict condition type for table "team" */
export type ITeamOnConflict = {
  constraint: ITeamConstraint;
  update_columns?: Array<ITeamUpdateColumn>;
  where?: InputMaybe<ITeamBoolExp>;
};

/** Ordering options when selecting data from "team". */
export type ITeamOrderBy = {
  archived_at?: InputMaybe<IOrderBy>;
  category?: InputMaybe<IOrderBy>;
  config?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  icon?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location?: InputMaybe<ILocationOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  members_aggregate?: InputMaybe<ITeamMemberAggregateOrderBy>;
  name?: InputMaybe<IOrderBy>;
  notification_is_enabled?: InputMaybe<IOrderBy>;
  notification_settings?: InputMaybe<IOrderBy>;
  owner_id?: InputMaybe<IOrderBy>;
  private?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: team */
export type ITeamPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ITeamPrependInput = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  notification_settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "team" */
export enum ITeamSelectColumn {
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  Category = 'category',
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  NotificationSettings = 'notification_settings',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Private = 'private',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "team_aggregate_bool_exp_bool_and_arguments_columns" columns of table "team" */
export enum ITeamSelectColumnTeamAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  Private = 'private'
}

/** select "team_aggregate_bool_exp_bool_or_arguments_columns" columns of table "team" */
export enum ITeamSelectColumnTeamAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  Private = 'private'
}

/** input type for updating data in table "team" */
export type ITeamSetInput = {
  archived_at?: InputMaybe<Scalars['timestamptz']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  config?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['json']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notification_settings?: InputMaybe<Scalars['jsonb']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  private?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "team" */
export type ITeamStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ITeamStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ITeamStreamCursorValueInput = {
  archived_at?: InputMaybe<Scalars['timestamptz']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  config?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['json']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notification_settings?: InputMaybe<Scalars['jsonb']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  private?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "team" */
export enum ITeamUpdateColumn {
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  Category = 'category',
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  NotificationSettings = 'notification_settings',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Private = 'private',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ITeamUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ITeamAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ITeamDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ITeamDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ITeamDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ITeamPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ITeamSetInput>;
  /** filter the rows which have to be updated */
  where: ITeamBoolExp;
};

/** columns and relationships of "team_user_role" */
export type ITeamUserRole = {
  __typename: 'team_user_role';
  value: Scalars['String']['output'];
};

/** aggregated selection of "team_user_role" */
export type ITeamUserRoleAggregate = {
  __typename: 'team_user_role_aggregate';
  aggregate?: Maybe<ITeamUserRoleAggregateFields>;
  nodes: Array<ITeamUserRole>;
};

/** aggregate fields of "team_user_role" */
export type ITeamUserRoleAggregateFields = {
  __typename: 'team_user_role_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ITeamUserRoleMaxFields>;
  min?: Maybe<ITeamUserRoleMinFields>;
};


/** aggregate fields of "team_user_role" */
export type ITeamUserRoleAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ITeamUserRoleSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "team_user_role". All fields are combined with a logical 'AND'. */
export type ITeamUserRoleBoolExp = {
  _and?: InputMaybe<Array<ITeamUserRoleBoolExp>>;
  _not?: InputMaybe<ITeamUserRoleBoolExp>;
  _or?: InputMaybe<Array<ITeamUserRoleBoolExp>>;
  value?: InputMaybe<IStringComparisonExp>;
};

/** unique or primary key constraints on table "team_user_role" */
export enum ITeamUserRoleConstraint {
  /** unique or primary key constraint on columns "value" */
  TeamUserRolePkey = 'team_user_role_pkey'
}

export enum ITeamUserRoleEnum {
  Lead = 'lead',
  Member = 'member'
}

/** Boolean expression to compare columns of type "team_user_role_enum". All fields are combined with logical 'AND'. */
export type ITeamUserRoleEnumComparisonExp = {
  _eq?: InputMaybe<ITeamUserRoleEnum>;
  _in?: InputMaybe<Array<ITeamUserRoleEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<ITeamUserRoleEnum>;
  _nin?: InputMaybe<Array<ITeamUserRoleEnum>>;
};

/** input type for inserting data into table "team_user_role" */
export type ITeamUserRoleInsertInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ITeamUserRoleMaxFields = {
  __typename: 'team_user_role_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ITeamUserRoleMinFields = {
  __typename: 'team_user_role_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "team_user_role" */
export type ITeamUserRoleMutationResponse = {
  __typename: 'team_user_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ITeamUserRole>;
};

/** on_conflict condition type for table "team_user_role" */
export type ITeamUserRoleOnConflict = {
  constraint: ITeamUserRoleConstraint;
  update_columns?: Array<ITeamUserRoleUpdateColumn>;
  where?: InputMaybe<ITeamUserRoleBoolExp>;
};

/** Ordering options when selecting data from "team_user_role". */
export type ITeamUserRoleOrderBy = {
  value?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: team_user_role */
export type ITeamUserRolePkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "team_user_role" */
export enum ITeamUserRoleSelectColumn {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "team_user_role" */
export type ITeamUserRoleSetInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "team_user_role" */
export type ITeamUserRoleStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ITeamUserRoleStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ITeamUserRoleStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "team_user_role" */
export enum ITeamUserRoleUpdateColumn {
  /** column name */
  Value = 'value'
}

export type ITeamUserRoleUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ITeamUserRoleSetInput>;
  /** filter the rows which have to be updated */
  where: ITeamUserRoleBoolExp;
};

/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export type ITimeComparisonExp = {
  _eq?: InputMaybe<Scalars['time']['input']>;
  _gt?: InputMaybe<Scalars['time']['input']>;
  _gte?: InputMaybe<Scalars['time']['input']>;
  _in?: InputMaybe<Array<Scalars['time']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['time']['input']>;
  _lte?: InputMaybe<Scalars['time']['input']>;
  _neq?: InputMaybe<Scalars['time']['input']>;
  _nin?: InputMaybe<Array<Scalars['time']['input']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type ITimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** Boolean expression to compare columns of type "tstzrange". All fields are combined with logical 'AND'. */
export type ITstzrangeComparisonExp = {
  _eq?: InputMaybe<Scalars['tstzrange']['input']>;
  _gt?: InputMaybe<Scalars['tstzrange']['input']>;
  _gte?: InputMaybe<Scalars['tstzrange']['input']>;
  _in?: InputMaybe<Array<Scalars['tstzrange']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['tstzrange']['input']>;
  _lte?: InputMaybe<Scalars['tstzrange']['input']>;
  _neq?: InputMaybe<Scalars['tstzrange']['input']>;
  _nin?: InputMaybe<Array<Scalars['tstzrange']['input']>>;
};

/** columns and relationships of "upload" */
export type IUpload = {
  __typename: 'upload';
  /** An array relationship */
  category: Array<IUploadXUploadCategory>;
  /** An aggregate relationship */
  category_aggregate: IUploadXUploadCategoryAggregate;
  /** A computed field, executes function "create_client_file_thumbnail_url" */
  client_thumbnail_url?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "create_client_file_url" */
  client_url?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  comment_x_upload: Array<ICommentXUpload>;
  /** An aggregate relationship */
  comment_x_upload_aggregate: ICommentXUploadAggregate;
  created_at: Scalars['timestamptz']['output'];
  file_name: Scalars['String']['output'];
  file_size: Scalars['Int']['output'];
  id: Scalars['uuid']['output'];
  mime_type: Scalars['String']['output'];
  /** An object relationship */
  org: IOrg;
  org_id: Scalars['uuid']['output'];
  thumbnail_url?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  uploaded_by: Scalars['uuid']['output'];
  url: Scalars['String']['output'];
  /** An array relationship */
  user: Array<IUser>;
  /** An aggregate relationship */
  user_aggregate: IUserAggregate;
};


/** columns and relationships of "upload" */
export type IUploadCategoryArgs = {
  distinct_on?: InputMaybe<Array<IUploadXUploadCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUploadXUploadCategoryOrderBy>>;
  where?: InputMaybe<IUploadXUploadCategoryBoolExp>;
};


/** columns and relationships of "upload" */
export type IUploadCategoryAggregateArgs = {
  distinct_on?: InputMaybe<Array<IUploadXUploadCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUploadXUploadCategoryOrderBy>>;
  where?: InputMaybe<IUploadXUploadCategoryBoolExp>;
};


/** columns and relationships of "upload" */
export type IUploadCommentXUploadArgs = {
  distinct_on?: InputMaybe<Array<ICommentXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICommentXUploadOrderBy>>;
  where?: InputMaybe<ICommentXUploadBoolExp>;
};


/** columns and relationships of "upload" */
export type IUploadCommentXUploadAggregateArgs = {
  distinct_on?: InputMaybe<Array<ICommentXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICommentXUploadOrderBy>>;
  where?: InputMaybe<ICommentXUploadBoolExp>;
};


/** columns and relationships of "upload" */
export type IUploadUserArgs = {
  distinct_on?: InputMaybe<Array<IUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUserOrderBy>>;
  where?: InputMaybe<IUserBoolExp>;
};


/** columns and relationships of "upload" */
export type IUploadUserAggregateArgs = {
  distinct_on?: InputMaybe<Array<IUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUserOrderBy>>;
  where?: InputMaybe<IUserBoolExp>;
};

/** aggregated selection of "upload" */
export type IUploadAggregate = {
  __typename: 'upload_aggregate';
  aggregate?: Maybe<IUploadAggregateFields>;
  nodes: Array<IUpload>;
};

export type IUploadAggregateBoolExp = {
  count?: InputMaybe<IUploadAggregateBoolExpCount>;
};

export type IUploadAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IUploadSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IUploadBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "upload" */
export type IUploadAggregateFields = {
  __typename: 'upload_aggregate_fields';
  avg?: Maybe<IUploadAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IUploadMaxFields>;
  min?: Maybe<IUploadMinFields>;
  stddev?: Maybe<IUploadStddevFields>;
  stddev_pop?: Maybe<IUploadStddevPopFields>;
  stddev_samp?: Maybe<IUploadStddevSampFields>;
  sum?: Maybe<IUploadSumFields>;
  var_pop?: Maybe<IUploadVarPopFields>;
  var_samp?: Maybe<IUploadVarSampFields>;
  variance?: Maybe<IUploadVarianceFields>;
};


/** aggregate fields of "upload" */
export type IUploadAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IUploadSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "upload" */
export type IUploadAggregateOrderBy = {
  avg?: InputMaybe<IUploadAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IUploadMaxOrderBy>;
  min?: InputMaybe<IUploadMinOrderBy>;
  stddev?: InputMaybe<IUploadStddevOrderBy>;
  stddev_pop?: InputMaybe<IUploadStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IUploadStddevSampOrderBy>;
  sum?: InputMaybe<IUploadSumOrderBy>;
  var_pop?: InputMaybe<IUploadVarPopOrderBy>;
  var_samp?: InputMaybe<IUploadVarSampOrderBy>;
  variance?: InputMaybe<IUploadVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "upload" */
export type IUploadArrRelInsertInput = {
  data: Array<IUploadInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IUploadOnConflict>;
};

/** aggregate avg on columns */
export type IUploadAvgFields = {
  __typename: 'upload_avg_fields';
  file_size?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "upload" */
export type IUploadAvgOrderBy = {
  file_size?: InputMaybe<IOrderBy>;
};

/** Boolean expression to filter rows from the table "upload". All fields are combined with a logical 'AND'. */
export type IUploadBoolExp = {
  _and?: InputMaybe<Array<IUploadBoolExp>>;
  _not?: InputMaybe<IUploadBoolExp>;
  _or?: InputMaybe<Array<IUploadBoolExp>>;
  category?: InputMaybe<IUploadXUploadCategoryBoolExp>;
  category_aggregate?: InputMaybe<IUploadXUploadCategoryAggregateBoolExp>;
  client_thumbnail_url?: InputMaybe<IStringComparisonExp>;
  client_url?: InputMaybe<IStringComparisonExp>;
  comment_x_upload?: InputMaybe<ICommentXUploadBoolExp>;
  comment_x_upload_aggregate?: InputMaybe<ICommentXUploadAggregateBoolExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  file_name?: InputMaybe<IStringComparisonExp>;
  file_size?: InputMaybe<IIntComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  mime_type?: InputMaybe<IStringComparisonExp>;
  org?: InputMaybe<IOrgBoolExp>;
  org_id?: InputMaybe<IUuidComparisonExp>;
  thumbnail_url?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  uploaded_by?: InputMaybe<IUuidComparisonExp>;
  url?: InputMaybe<IStringComparisonExp>;
  user?: InputMaybe<IUserBoolExp>;
  user_aggregate?: InputMaybe<IUserAggregateBoolExp>;
};

/** columns and relationships of "upload_category" */
export type IUploadCategory = {
  __typename: 'upload_category';
  /** An array relationship */
  categories: Array<IUploadXUploadCategory>;
  /** An aggregate relationship */
  categories_aggregate: IUploadXUploadCategoryAggregate;
  color: Scalars['String']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  label?: Maybe<Scalars['String']['output']>;
  location_id: Scalars['uuid']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "upload_category" */
export type IUploadCategoryCategoriesArgs = {
  distinct_on?: InputMaybe<Array<IUploadXUploadCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUploadXUploadCategoryOrderBy>>;
  where?: InputMaybe<IUploadXUploadCategoryBoolExp>;
};


/** columns and relationships of "upload_category" */
export type IUploadCategoryCategoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<IUploadXUploadCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUploadXUploadCategoryOrderBy>>;
  where?: InputMaybe<IUploadXUploadCategoryBoolExp>;
};

/** aggregated selection of "upload_category" */
export type IUploadCategoryAggregate = {
  __typename: 'upload_category_aggregate';
  aggregate?: Maybe<IUploadCategoryAggregateFields>;
  nodes: Array<IUploadCategory>;
};

/** aggregate fields of "upload_category" */
export type IUploadCategoryAggregateFields = {
  __typename: 'upload_category_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IUploadCategoryMaxFields>;
  min?: Maybe<IUploadCategoryMinFields>;
};


/** aggregate fields of "upload_category" */
export type IUploadCategoryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IUploadCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "upload_category". All fields are combined with a logical 'AND'. */
export type IUploadCategoryBoolExp = {
  _and?: InputMaybe<Array<IUploadCategoryBoolExp>>;
  _not?: InputMaybe<IUploadCategoryBoolExp>;
  _or?: InputMaybe<Array<IUploadCategoryBoolExp>>;
  categories?: InputMaybe<IUploadXUploadCategoryBoolExp>;
  categories_aggregate?: InputMaybe<IUploadXUploadCategoryAggregateBoolExp>;
  color?: InputMaybe<IStringComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  label?: InputMaybe<IStringComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "upload_category" */
export enum IUploadCategoryConstraint {
  /** unique or primary key constraint on columns "id" */
  UploadCategoryIdKey = 'upload_category_id_key',
  /** unique or primary key constraint on columns "id" */
  UploadCategoryPkey = 'upload_category_pkey'
}

/** input type for inserting data into table "upload_category" */
export type IUploadCategoryInsertInput = {
  categories?: InputMaybe<IUploadXUploadCategoryArrRelInsertInput>;
  color?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type IUploadCategoryMaxFields = {
  __typename: 'upload_category_max_fields';
  color?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type IUploadCategoryMinFields = {
  __typename: 'upload_category_min_fields';
  color?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "upload_category" */
export type IUploadCategoryMutationResponse = {
  __typename: 'upload_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IUploadCategory>;
};

/** input type for inserting object relation for remote table "upload_category" */
export type IUploadCategoryObjRelInsertInput = {
  data: IUploadCategoryInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IUploadCategoryOnConflict>;
};

/** on_conflict condition type for table "upload_category" */
export type IUploadCategoryOnConflict = {
  constraint: IUploadCategoryConstraint;
  update_columns?: Array<IUploadCategoryUpdateColumn>;
  where?: InputMaybe<IUploadCategoryBoolExp>;
};

/** Ordering options when selecting data from "upload_category". */
export type IUploadCategoryOrderBy = {
  categories_aggregate?: InputMaybe<IUploadXUploadCategoryAggregateOrderBy>;
  color?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  label?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: upload_category */
export type IUploadCategoryPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "upload_category" */
export enum IUploadCategorySelectColumn {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "upload_category" */
export type IUploadCategorySetInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "upload_category" */
export type IUploadCategoryStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IUploadCategoryStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IUploadCategoryStreamCursorValueInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "upload_category" */
export enum IUploadCategoryUpdateColumn {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type IUploadCategoryUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IUploadCategorySetInput>;
  /** filter the rows which have to be updated */
  where: IUploadCategoryBoolExp;
};

/** unique or primary key constraints on table "upload" */
export enum IUploadConstraint {
  /** unique or primary key constraint on columns "id" */
  UploadPkey = 'upload_pkey'
}

/** input type for incrementing numeric columns in table "upload" */
export type IUploadIncInput = {
  file_size?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "upload" */
export type IUploadInsertInput = {
  category?: InputMaybe<IUploadXUploadCategoryArrRelInsertInput>;
  comment_x_upload?: InputMaybe<ICommentXUploadArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_name?: InputMaybe<Scalars['String']['input']>;
  file_size?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  mime_type?: InputMaybe<Scalars['String']['input']>;
  org?: InputMaybe<IOrgObjRelInsertInput>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  thumbnail_url?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  uploaded_by?: InputMaybe<Scalars['uuid']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<IUserArrRelInsertInput>;
};

/** aggregate max on columns */
export type IUploadMaxFields = {
  __typename: 'upload_max_fields';
  /** A computed field, executes function "create_client_file_thumbnail_url" */
  client_thumbnail_url?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "create_client_file_url" */
  client_url?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_name?: Maybe<Scalars['String']['output']>;
  file_size?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  mime_type?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  thumbnail_url?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  uploaded_by?: Maybe<Scalars['uuid']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "upload" */
export type IUploadMaxOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  file_name?: InputMaybe<IOrderBy>;
  file_size?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  mime_type?: InputMaybe<IOrderBy>;
  org_id?: InputMaybe<IOrderBy>;
  thumbnail_url?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  uploaded_by?: InputMaybe<IOrderBy>;
  url?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IUploadMinFields = {
  __typename: 'upload_min_fields';
  /** A computed field, executes function "create_client_file_thumbnail_url" */
  client_thumbnail_url?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "create_client_file_url" */
  client_url?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_name?: Maybe<Scalars['String']['output']>;
  file_size?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  mime_type?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  thumbnail_url?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  uploaded_by?: Maybe<Scalars['uuid']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "upload" */
export type IUploadMinOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  file_name?: InputMaybe<IOrderBy>;
  file_size?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  mime_type?: InputMaybe<IOrderBy>;
  org_id?: InputMaybe<IOrderBy>;
  thumbnail_url?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  uploaded_by?: InputMaybe<IOrderBy>;
  url?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "upload" */
export type IUploadMutationResponse = {
  __typename: 'upload_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IUpload>;
};

/** input type for inserting object relation for remote table "upload" */
export type IUploadObjRelInsertInput = {
  data: IUploadInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IUploadOnConflict>;
};

/** on_conflict condition type for table "upload" */
export type IUploadOnConflict = {
  constraint: IUploadConstraint;
  update_columns?: Array<IUploadUpdateColumn>;
  where?: InputMaybe<IUploadBoolExp>;
};

/** Ordering options when selecting data from "upload". */
export type IUploadOrderBy = {
  category_aggregate?: InputMaybe<IUploadXUploadCategoryAggregateOrderBy>;
  client_thumbnail_url?: InputMaybe<IOrderBy>;
  client_url?: InputMaybe<IOrderBy>;
  comment_x_upload_aggregate?: InputMaybe<ICommentXUploadAggregateOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  file_name?: InputMaybe<IOrderBy>;
  file_size?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  mime_type?: InputMaybe<IOrderBy>;
  org?: InputMaybe<IOrgOrderBy>;
  org_id?: InputMaybe<IOrderBy>;
  thumbnail_url?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  uploaded_by?: InputMaybe<IOrderBy>;
  url?: InputMaybe<IOrderBy>;
  user_aggregate?: InputMaybe<IUserAggregateOrderBy>;
};

/** primary key columns input for table: upload */
export type IUploadPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "upload" */
export enum IUploadSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileName = 'file_name',
  /** column name */
  FileSize = 'file_size',
  /** column name */
  Id = 'id',
  /** column name */
  MimeType = 'mime_type',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  ThumbnailUrl = 'thumbnail_url',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadedBy = 'uploaded_by',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "upload" */
export type IUploadSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_name?: InputMaybe<Scalars['String']['input']>;
  file_size?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  mime_type?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  thumbnail_url?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  uploaded_by?: InputMaybe<Scalars['uuid']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type IUploadStddevFields = {
  __typename: 'upload_stddev_fields';
  file_size?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "upload" */
export type IUploadStddevOrderBy = {
  file_size?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_pop on columns */
export type IUploadStddevPopFields = {
  __typename: 'upload_stddev_pop_fields';
  file_size?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "upload" */
export type IUploadStddevPopOrderBy = {
  file_size?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_samp on columns */
export type IUploadStddevSampFields = {
  __typename: 'upload_stddev_samp_fields';
  file_size?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "upload" */
export type IUploadStddevSampOrderBy = {
  file_size?: InputMaybe<IOrderBy>;
};

/** Streaming cursor of the table "upload" */
export type IUploadStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IUploadStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IUploadStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_name?: InputMaybe<Scalars['String']['input']>;
  file_size?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  mime_type?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  thumbnail_url?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  uploaded_by?: InputMaybe<Scalars['uuid']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type IUploadSumFields = {
  __typename: 'upload_sum_fields';
  file_size?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "upload" */
export type IUploadSumOrderBy = {
  file_size?: InputMaybe<IOrderBy>;
};

/** update columns of table "upload" */
export enum IUploadUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileName = 'file_name',
  /** column name */
  FileSize = 'file_size',
  /** column name */
  Id = 'id',
  /** column name */
  MimeType = 'mime_type',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  ThumbnailUrl = 'thumbnail_url',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadedBy = 'uploaded_by',
  /** column name */
  Url = 'url'
}

export type IUploadUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IUploadIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IUploadSetInput>;
  /** filter the rows which have to be updated */
  where: IUploadBoolExp;
};

/** aggregate var_pop on columns */
export type IUploadVarPopFields = {
  __typename: 'upload_var_pop_fields';
  file_size?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "upload" */
export type IUploadVarPopOrderBy = {
  file_size?: InputMaybe<IOrderBy>;
};

/** aggregate var_samp on columns */
export type IUploadVarSampFields = {
  __typename: 'upload_var_samp_fields';
  file_size?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "upload" */
export type IUploadVarSampOrderBy = {
  file_size?: InputMaybe<IOrderBy>;
};

/** aggregate variance on columns */
export type IUploadVarianceFields = {
  __typename: 'upload_variance_fields';
  file_size?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "upload" */
export type IUploadVarianceOrderBy = {
  file_size?: InputMaybe<IOrderBy>;
};

/** columns and relationships of "upload_x_upload_category" */
export type IUploadXUploadCategory = {
  __typename: 'upload_x_upload_category';
  /** An object relationship */
  category?: Maybe<IUploadCategory>;
  created_at: Scalars['timestamptz']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  upload?: Maybe<IUpload>;
  upload_category_id: Scalars['uuid']['output'];
  upload_id: Scalars['uuid']['output'];
};

/** aggregated selection of "upload_x_upload_category" */
export type IUploadXUploadCategoryAggregate = {
  __typename: 'upload_x_upload_category_aggregate';
  aggregate?: Maybe<IUploadXUploadCategoryAggregateFields>;
  nodes: Array<IUploadXUploadCategory>;
};

export type IUploadXUploadCategoryAggregateBoolExp = {
  count?: InputMaybe<IUploadXUploadCategoryAggregateBoolExpCount>;
};

export type IUploadXUploadCategoryAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IUploadXUploadCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IUploadXUploadCategoryBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "upload_x_upload_category" */
export type IUploadXUploadCategoryAggregateFields = {
  __typename: 'upload_x_upload_category_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IUploadXUploadCategoryMaxFields>;
  min?: Maybe<IUploadXUploadCategoryMinFields>;
};


/** aggregate fields of "upload_x_upload_category" */
export type IUploadXUploadCategoryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IUploadXUploadCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "upload_x_upload_category" */
export type IUploadXUploadCategoryAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IUploadXUploadCategoryMaxOrderBy>;
  min?: InputMaybe<IUploadXUploadCategoryMinOrderBy>;
};

/** input type for inserting array relation for remote table "upload_x_upload_category" */
export type IUploadXUploadCategoryArrRelInsertInput = {
  data: Array<IUploadXUploadCategoryInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IUploadXUploadCategoryOnConflict>;
};

/** Boolean expression to filter rows from the table "upload_x_upload_category". All fields are combined with a logical 'AND'. */
export type IUploadXUploadCategoryBoolExp = {
  _and?: InputMaybe<Array<IUploadXUploadCategoryBoolExp>>;
  _not?: InputMaybe<IUploadXUploadCategoryBoolExp>;
  _or?: InputMaybe<Array<IUploadXUploadCategoryBoolExp>>;
  category?: InputMaybe<IUploadCategoryBoolExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  upload?: InputMaybe<IUploadBoolExp>;
  upload_category_id?: InputMaybe<IUuidComparisonExp>;
  upload_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "upload_x_upload_category" */
export enum IUploadXUploadCategoryConstraint {
  /** unique or primary key constraint on columns "upload_id", "upload_category_id" */
  UploadXUploadCategoryPkey = 'upload_x_upload_category_pkey'
}

/** input type for inserting data into table "upload_x_upload_category" */
export type IUploadXUploadCategoryInsertInput = {
  category?: InputMaybe<IUploadCategoryObjRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  upload?: InputMaybe<IUploadObjRelInsertInput>;
  upload_category_id?: InputMaybe<Scalars['uuid']['input']>;
  upload_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IUploadXUploadCategoryMaxFields = {
  __typename: 'upload_x_upload_category_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  upload_category_id?: Maybe<Scalars['uuid']['output']>;
  upload_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "upload_x_upload_category" */
export type IUploadXUploadCategoryMaxOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  upload_category_id?: InputMaybe<IOrderBy>;
  upload_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IUploadXUploadCategoryMinFields = {
  __typename: 'upload_x_upload_category_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  upload_category_id?: Maybe<Scalars['uuid']['output']>;
  upload_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "upload_x_upload_category" */
export type IUploadXUploadCategoryMinOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  upload_category_id?: InputMaybe<IOrderBy>;
  upload_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "upload_x_upload_category" */
export type IUploadXUploadCategoryMutationResponse = {
  __typename: 'upload_x_upload_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IUploadXUploadCategory>;
};

/** on_conflict condition type for table "upload_x_upload_category" */
export type IUploadXUploadCategoryOnConflict = {
  constraint: IUploadXUploadCategoryConstraint;
  update_columns?: Array<IUploadXUploadCategoryUpdateColumn>;
  where?: InputMaybe<IUploadXUploadCategoryBoolExp>;
};

/** Ordering options when selecting data from "upload_x_upload_category". */
export type IUploadXUploadCategoryOrderBy = {
  category?: InputMaybe<IUploadCategoryOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  upload?: InputMaybe<IUploadOrderBy>;
  upload_category_id?: InputMaybe<IOrderBy>;
  upload_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: upload_x_upload_category */
export type IUploadXUploadCategoryPkColumnsInput = {
  upload_category_id: Scalars['uuid']['input'];
  upload_id: Scalars['uuid']['input'];
};

/** select columns of table "upload_x_upload_category" */
export enum IUploadXUploadCategorySelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadCategoryId = 'upload_category_id',
  /** column name */
  UploadId = 'upload_id'
}

/** input type for updating data in table "upload_x_upload_category" */
export type IUploadXUploadCategorySetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  upload_category_id?: InputMaybe<Scalars['uuid']['input']>;
  upload_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "upload_x_upload_category" */
export type IUploadXUploadCategoryStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IUploadXUploadCategoryStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IUploadXUploadCategoryStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  upload_category_id?: InputMaybe<Scalars['uuid']['input']>;
  upload_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "upload_x_upload_category" */
export enum IUploadXUploadCategoryUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadCategoryId = 'upload_category_id',
  /** column name */
  UploadId = 'upload_id'
}

export type IUploadXUploadCategoryUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IUploadXUploadCategorySetInput>;
  /** filter the rows which have to be updated */
  where: IUploadXUploadCategoryBoolExp;
};

/** columns and relationships of "user" */
export type IUser = {
  __typename: 'user';
  /** An object relationship */
  avatar?: Maybe<IUpload>;
  avatar_id?: Maybe<Scalars['uuid']['output']>;
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  keycloak_id?: Maybe<Scalars['uuid']['output']>;
  keycloak_last_synced_at?: Maybe<Scalars['timestamptz']['output']>;
  language: Scalars['String']['output'];
  last_name: Scalars['String']['output'];
  /** An array relationship */
  locations: Array<ILocationMember>;
  /** An aggregate relationship */
  locations_aggregate: ILocationMemberAggregate;
  note?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  notifications: Array<INotification>;
  /** An aggregate relationship */
  notifications_aggregate: INotificationAggregate;
  /** An object relationship */
  org?: Maybe<IOrg>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  settings: Scalars['jsonb']['output'];
  /** An array relationship */
  teams: Array<ITeamMember>;
  /** An aggregate relationship */
  teams_aggregate: ITeamMemberAggregate;
  updated_at: Scalars['timestamptz']['output'];
  username: Scalars['String']['output'];
};


/** columns and relationships of "user" */
export type IUserLocationsArgs = {
  distinct_on?: InputMaybe<Array<ILocationMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationMemberOrderBy>>;
  where?: InputMaybe<ILocationMemberBoolExp>;
};


/** columns and relationships of "user" */
export type IUserLocationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ILocationMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ILocationMemberOrderBy>>;
  where?: InputMaybe<ILocationMemberBoolExp>;
};


/** columns and relationships of "user" */
export type IUserNotificationsArgs = {
  distinct_on?: InputMaybe<Array<INotificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<INotificationOrderBy>>;
  where?: InputMaybe<INotificationBoolExp>;
};


/** columns and relationships of "user" */
export type IUserNotificationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<INotificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<INotificationOrderBy>>;
  where?: InputMaybe<INotificationBoolExp>;
};


/** columns and relationships of "user" */
export type IUserSettingsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "user" */
export type IUserTeamsArgs = {
  distinct_on?: InputMaybe<Array<ITeamMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ITeamMemberOrderBy>>;
  where?: InputMaybe<ITeamMemberBoolExp>;
};


/** columns and relationships of "user" */
export type IUserTeamsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ITeamMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ITeamMemberOrderBy>>;
  where?: InputMaybe<ITeamMemberBoolExp>;
};

/** aggregated selection of "user" */
export type IUserAggregate = {
  __typename: 'user_aggregate';
  aggregate?: Maybe<IUserAggregateFields>;
  nodes: Array<IUser>;
};

export type IUserAggregateBoolExp = {
  count?: InputMaybe<IUserAggregateBoolExpCount>;
};

export type IUserAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IUserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IUserBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "user" */
export type IUserAggregateFields = {
  __typename: 'user_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IUserMaxFields>;
  min?: Maybe<IUserMinFields>;
};


/** aggregate fields of "user" */
export type IUserAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IUserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user" */
export type IUserAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IUserMaxOrderBy>;
  min?: InputMaybe<IUserMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IUserAppendInput = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "user" */
export type IUserArrRelInsertInput = {
  data: Array<IUserInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IUserOnConflict>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type IUserBoolExp = {
  _and?: InputMaybe<Array<IUserBoolExp>>;
  _not?: InputMaybe<IUserBoolExp>;
  _or?: InputMaybe<Array<IUserBoolExp>>;
  avatar?: InputMaybe<IUploadBoolExp>;
  avatar_id?: InputMaybe<IUuidComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  deleted_at?: InputMaybe<ITimestamptzComparisonExp>;
  email?: InputMaybe<IStringComparisonExp>;
  first_name?: InputMaybe<IStringComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  keycloak_id?: InputMaybe<IUuidComparisonExp>;
  keycloak_last_synced_at?: InputMaybe<ITimestamptzComparisonExp>;
  language?: InputMaybe<IStringComparisonExp>;
  last_name?: InputMaybe<IStringComparisonExp>;
  locations?: InputMaybe<ILocationMemberBoolExp>;
  locations_aggregate?: InputMaybe<ILocationMemberAggregateBoolExp>;
  note?: InputMaybe<IStringComparisonExp>;
  notifications?: InputMaybe<INotificationBoolExp>;
  notifications_aggregate?: InputMaybe<INotificationAggregateBoolExp>;
  org?: InputMaybe<IOrgBoolExp>;
  org_id?: InputMaybe<IUuidComparisonExp>;
  phone?: InputMaybe<IStringComparisonExp>;
  settings?: InputMaybe<IJsonbComparisonExp>;
  teams?: InputMaybe<ITeamMemberBoolExp>;
  teams_aggregate?: InputMaybe<ITeamMemberAggregateBoolExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  username?: InputMaybe<IStringComparisonExp>;
};

/** unique or primary key constraints on table "user" */
export enum IUserConstraint {
  /** unique or primary key constraint on columns "keycloak_id" */
  UserKeycloakIdKey = 'user_keycloak_id_key',
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IUserDeleteAtPathInput = {
  settings?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IUserDeleteElemInput = {
  settings?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IUserDeleteKeyInput = {
  settings?: InputMaybe<Scalars['String']['input']>;
};

/** User favorites, including teams and projects */
export type IUserFavorite = {
  __typename: 'user_favorite';
  /** An object relationship */
  asset?: Maybe<IAsset>;
  asset_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  custom_view?: Maybe<ICustomView>;
  custom_view_id?: Maybe<Scalars['uuid']['output']>;
  default_view_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  location_id: Scalars['uuid']['output'];
  /** An object relationship */
  project?: Maybe<IProject>;
  project_id?: Maybe<Scalars['uuid']['output']>;
  sort_order: Scalars['numeric']['output'];
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "user_favorite" */
export type IUserFavoriteAggregate = {
  __typename: 'user_favorite_aggregate';
  aggregate?: Maybe<IUserFavoriteAggregateFields>;
  nodes: Array<IUserFavorite>;
};

/** aggregate fields of "user_favorite" */
export type IUserFavoriteAggregateFields = {
  __typename: 'user_favorite_aggregate_fields';
  avg?: Maybe<IUserFavoriteAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IUserFavoriteMaxFields>;
  min?: Maybe<IUserFavoriteMinFields>;
  stddev?: Maybe<IUserFavoriteStddevFields>;
  stddev_pop?: Maybe<IUserFavoriteStddevPopFields>;
  stddev_samp?: Maybe<IUserFavoriteStddevSampFields>;
  sum?: Maybe<IUserFavoriteSumFields>;
  var_pop?: Maybe<IUserFavoriteVarPopFields>;
  var_samp?: Maybe<IUserFavoriteVarSampFields>;
  variance?: Maybe<IUserFavoriteVarianceFields>;
};


/** aggregate fields of "user_favorite" */
export type IUserFavoriteAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IUserFavoriteSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type IUserFavoriteAvgFields = {
  __typename: 'user_favorite_avg_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "user_favorite". All fields are combined with a logical 'AND'. */
export type IUserFavoriteBoolExp = {
  _and?: InputMaybe<Array<IUserFavoriteBoolExp>>;
  _not?: InputMaybe<IUserFavoriteBoolExp>;
  _or?: InputMaybe<Array<IUserFavoriteBoolExp>>;
  asset?: InputMaybe<IAssetBoolExp>;
  asset_id?: InputMaybe<IUuidComparisonExp>;
  custom_view?: InputMaybe<ICustomViewBoolExp>;
  custom_view_id?: InputMaybe<IUuidComparisonExp>;
  default_view_id?: InputMaybe<IStringComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  project?: InputMaybe<IProjectBoolExp>;
  project_id?: InputMaybe<IUuidComparisonExp>;
  sort_order?: InputMaybe<INumericComparisonExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "user_favorite" */
export enum IUserFavoriteConstraint {
  /** unique or primary key constraint on columns "id" */
  UserFavoritePkey = 'user_favorite_pkey'
}

/** input type for incrementing numeric columns in table "user_favorite" */
export type IUserFavoriteIncInput = {
  sort_order?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "user_favorite" */
export type IUserFavoriteInsertInput = {
  asset?: InputMaybe<IAssetObjRelInsertInput>;
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  custom_view?: InputMaybe<ICustomViewObjRelInsertInput>;
  custom_view_id?: InputMaybe<Scalars['uuid']['input']>;
  default_view_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  project?: InputMaybe<IProjectObjRelInsertInput>;
  project_id?: InputMaybe<Scalars['uuid']['input']>;
  sort_order?: InputMaybe<Scalars['numeric']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IUserFavoriteMaxFields = {
  __typename: 'user_favorite_max_fields';
  asset_id?: Maybe<Scalars['uuid']['output']>;
  custom_view_id?: Maybe<Scalars['uuid']['output']>;
  default_view_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  project_id?: Maybe<Scalars['uuid']['output']>;
  sort_order?: Maybe<Scalars['numeric']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type IUserFavoriteMinFields = {
  __typename: 'user_favorite_min_fields';
  asset_id?: Maybe<Scalars['uuid']['output']>;
  custom_view_id?: Maybe<Scalars['uuid']['output']>;
  default_view_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  project_id?: Maybe<Scalars['uuid']['output']>;
  sort_order?: Maybe<Scalars['numeric']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "user_favorite" */
export type IUserFavoriteMutationResponse = {
  __typename: 'user_favorite_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IUserFavorite>;
};

/** on_conflict condition type for table "user_favorite" */
export type IUserFavoriteOnConflict = {
  constraint: IUserFavoriteConstraint;
  update_columns?: Array<IUserFavoriteUpdateColumn>;
  where?: InputMaybe<IUserFavoriteBoolExp>;
};

/** Ordering options when selecting data from "user_favorite". */
export type IUserFavoriteOrderBy = {
  asset?: InputMaybe<IAssetOrderBy>;
  asset_id?: InputMaybe<IOrderBy>;
  custom_view?: InputMaybe<ICustomViewOrderBy>;
  custom_view_id?: InputMaybe<IOrderBy>;
  default_view_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  project?: InputMaybe<IProjectOrderBy>;
  project_id?: InputMaybe<IOrderBy>;
  sort_order?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: user_favorite */
export type IUserFavoritePkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "user_favorite" */
export enum IUserFavoriteSelectColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  CustomViewId = 'custom_view_id',
  /** column name */
  DefaultViewId = 'default_view_id',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_favorite" */
export type IUserFavoriteSetInput = {
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  custom_view_id?: InputMaybe<Scalars['uuid']['input']>;
  default_view_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  project_id?: InputMaybe<Scalars['uuid']['input']>;
  sort_order?: InputMaybe<Scalars['numeric']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type IUserFavoriteStddevFields = {
  __typename: 'user_favorite_stddev_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type IUserFavoriteStddevPopFields = {
  __typename: 'user_favorite_stddev_pop_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type IUserFavoriteStddevSampFields = {
  __typename: 'user_favorite_stddev_samp_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "user_favorite" */
export type IUserFavoriteStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IUserFavoriteStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IUserFavoriteStreamCursorValueInput = {
  asset_id?: InputMaybe<Scalars['uuid']['input']>;
  custom_view_id?: InputMaybe<Scalars['uuid']['input']>;
  default_view_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  project_id?: InputMaybe<Scalars['uuid']['input']>;
  sort_order?: InputMaybe<Scalars['numeric']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type IUserFavoriteSumFields = {
  __typename: 'user_favorite_sum_fields';
  sort_order?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "user_favorite" */
export enum IUserFavoriteUpdateColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  CustomViewId = 'custom_view_id',
  /** column name */
  DefaultViewId = 'default_view_id',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  UserId = 'user_id'
}

export type IUserFavoriteUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IUserFavoriteIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IUserFavoriteSetInput>;
  /** filter the rows which have to be updated */
  where: IUserFavoriteBoolExp;
};

/** aggregate var_pop on columns */
export type IUserFavoriteVarPopFields = {
  __typename: 'user_favorite_var_pop_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type IUserFavoriteVarSampFields = {
  __typename: 'user_favorite_var_samp_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type IUserFavoriteVarianceFields = {
  __typename: 'user_favorite_variance_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** input type for inserting data into table "user" */
export type IUserInsertInput = {
  avatar?: InputMaybe<IUploadObjRelInsertInput>;
  avatar_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  keycloak_id?: InputMaybe<Scalars['uuid']['input']>;
  keycloak_last_synced_at?: InputMaybe<Scalars['timestamptz']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  locations?: InputMaybe<ILocationMemberArrRelInsertInput>;
  note?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<INotificationArrRelInsertInput>;
  org?: InputMaybe<IOrgObjRelInsertInput>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  teams?: InputMaybe<ITeamMemberArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type IUserMaxFields = {
  __typename: 'user_max_fields';
  avatar_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  keycloak_id?: Maybe<Scalars['uuid']['output']>;
  keycloak_last_synced_at?: Maybe<Scalars['timestamptz']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "user" */
export type IUserMaxOrderBy = {
  avatar_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  deleted_at?: InputMaybe<IOrderBy>;
  email?: InputMaybe<IOrderBy>;
  first_name?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  keycloak_id?: InputMaybe<IOrderBy>;
  keycloak_last_synced_at?: InputMaybe<IOrderBy>;
  language?: InputMaybe<IOrderBy>;
  last_name?: InputMaybe<IOrderBy>;
  note?: InputMaybe<IOrderBy>;
  org_id?: InputMaybe<IOrderBy>;
  phone?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  username?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IUserMinFields = {
  __typename: 'user_min_fields';
  avatar_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  keycloak_id?: Maybe<Scalars['uuid']['output']>;
  keycloak_last_synced_at?: Maybe<Scalars['timestamptz']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "user" */
export type IUserMinOrderBy = {
  avatar_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  deleted_at?: InputMaybe<IOrderBy>;
  email?: InputMaybe<IOrderBy>;
  first_name?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  keycloak_id?: InputMaybe<IOrderBy>;
  keycloak_last_synced_at?: InputMaybe<IOrderBy>;
  language?: InputMaybe<IOrderBy>;
  last_name?: InputMaybe<IOrderBy>;
  note?: InputMaybe<IOrderBy>;
  org_id?: InputMaybe<IOrderBy>;
  phone?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  username?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "user" */
export type IUserMutationResponse = {
  __typename: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IUser>;
};

/** input type for inserting object relation for remote table "user" */
export type IUserObjRelInsertInput = {
  data: IUserInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IUserOnConflict>;
};

/** on_conflict condition type for table "user" */
export type IUserOnConflict = {
  constraint: IUserConstraint;
  update_columns?: Array<IUserUpdateColumn>;
  where?: InputMaybe<IUserBoolExp>;
};

/** Ordering options when selecting data from "user". */
export type IUserOrderBy = {
  avatar?: InputMaybe<IUploadOrderBy>;
  avatar_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  deleted_at?: InputMaybe<IOrderBy>;
  email?: InputMaybe<IOrderBy>;
  first_name?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  keycloak_id?: InputMaybe<IOrderBy>;
  keycloak_last_synced_at?: InputMaybe<IOrderBy>;
  language?: InputMaybe<IOrderBy>;
  last_name?: InputMaybe<IOrderBy>;
  locations_aggregate?: InputMaybe<ILocationMemberAggregateOrderBy>;
  note?: InputMaybe<IOrderBy>;
  notifications_aggregate?: InputMaybe<INotificationAggregateOrderBy>;
  org?: InputMaybe<IOrgOrderBy>;
  org_id?: InputMaybe<IOrderBy>;
  phone?: InputMaybe<IOrderBy>;
  settings?: InputMaybe<IOrderBy>;
  teams_aggregate?: InputMaybe<ITeamMemberAggregateOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  username?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: user */
export type IUserPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IUserPrependInput = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "user_recently_used" */
export type IUserRecentlyUsed = {
  __typename: 'user_recently_used';
  entity_id: Scalars['uuid']['output'];
  entity_type: Scalars['String']['output'];
  timestamp: Scalars['timestamptz']['output'];
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "user_recently_used" */
export type IUserRecentlyUsedAggregate = {
  __typename: 'user_recently_used_aggregate';
  aggregate?: Maybe<IUserRecentlyUsedAggregateFields>;
  nodes: Array<IUserRecentlyUsed>;
};

/** aggregate fields of "user_recently_used" */
export type IUserRecentlyUsedAggregateFields = {
  __typename: 'user_recently_used_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IUserRecentlyUsedMaxFields>;
  min?: Maybe<IUserRecentlyUsedMinFields>;
};


/** aggregate fields of "user_recently_used" */
export type IUserRecentlyUsedAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IUserRecentlyUsedSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "user_recently_used". All fields are combined with a logical 'AND'. */
export type IUserRecentlyUsedBoolExp = {
  _and?: InputMaybe<Array<IUserRecentlyUsedBoolExp>>;
  _not?: InputMaybe<IUserRecentlyUsedBoolExp>;
  _or?: InputMaybe<Array<IUserRecentlyUsedBoolExp>>;
  entity_id?: InputMaybe<IUuidComparisonExp>;
  entity_type?: InputMaybe<IStringComparisonExp>;
  timestamp?: InputMaybe<ITimestamptzComparisonExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "user_recently_used" */
export enum IUserRecentlyUsedConstraint {
  /** unique or primary key constraint on columns "entity_type", "user_id", "entity_id" */
  UserRecentlyUsedPkey = 'user_recently_used_pkey'
}

/** input type for inserting data into table "user_recently_used" */
export type IUserRecentlyUsedInsertInput = {
  entity_id?: InputMaybe<Scalars['uuid']['input']>;
  entity_type?: InputMaybe<Scalars['String']['input']>;
  timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IUserRecentlyUsedMaxFields = {
  __typename: 'user_recently_used_max_fields';
  entity_id?: Maybe<Scalars['uuid']['output']>;
  entity_type?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type IUserRecentlyUsedMinFields = {
  __typename: 'user_recently_used_min_fields';
  entity_id?: Maybe<Scalars['uuid']['output']>;
  entity_type?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "user_recently_used" */
export type IUserRecentlyUsedMutationResponse = {
  __typename: 'user_recently_used_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IUserRecentlyUsed>;
};

/** on_conflict condition type for table "user_recently_used" */
export type IUserRecentlyUsedOnConflict = {
  constraint: IUserRecentlyUsedConstraint;
  update_columns?: Array<IUserRecentlyUsedUpdateColumn>;
  where?: InputMaybe<IUserRecentlyUsedBoolExp>;
};

/** Ordering options when selecting data from "user_recently_used". */
export type IUserRecentlyUsedOrderBy = {
  entity_id?: InputMaybe<IOrderBy>;
  entity_type?: InputMaybe<IOrderBy>;
  timestamp?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: user_recently_used */
export type IUserRecentlyUsedPkColumnsInput = {
  entity_id: Scalars['uuid']['input'];
  entity_type: Scalars['String']['input'];
  user_id: Scalars['uuid']['input'];
};

/** select columns of table "user_recently_used" */
export enum IUserRecentlyUsedSelectColumn {
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  EntityType = 'entity_type',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_recently_used" */
export type IUserRecentlyUsedSetInput = {
  entity_id?: InputMaybe<Scalars['uuid']['input']>;
  entity_type?: InputMaybe<Scalars['String']['input']>;
  timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "user_recently_used" */
export type IUserRecentlyUsedStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IUserRecentlyUsedStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IUserRecentlyUsedStreamCursorValueInput = {
  entity_id?: InputMaybe<Scalars['uuid']['input']>;
  entity_type?: InputMaybe<Scalars['String']['input']>;
  timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "user_recently_used" */
export enum IUserRecentlyUsedUpdateColumn {
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  EntityType = 'entity_type',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  UserId = 'user_id'
}

export type IUserRecentlyUsedUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IUserRecentlyUsedSetInput>;
  /** filter the rows which have to be updated */
  where: IUserRecentlyUsedBoolExp;
};

/** select columns of table "user" */
export enum IUserSelectColumn {
  /** column name */
  AvatarId = 'avatar_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  KeycloakId = 'keycloak_id',
  /** column name */
  KeycloakLastSyncedAt = 'keycloak_last_synced_at',
  /** column name */
  Language = 'language',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Note = 'note',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Phone = 'phone',
  /** column name */
  Settings = 'settings',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "user" */
export type IUserSetInput = {
  avatar_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  keycloak_id?: InputMaybe<Scalars['uuid']['input']>;
  keycloak_last_synced_at?: InputMaybe<Scalars['timestamptz']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user" */
export type IUserStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IUserStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IUserStreamCursorValueInput = {
  avatar_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  keycloak_id?: InputMaybe<Scalars['uuid']['input']>;
  keycloak_last_synced_at?: InputMaybe<Scalars['timestamptz']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user" */
export enum IUserUpdateColumn {
  /** column name */
  AvatarId = 'avatar_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  KeycloakId = 'keycloak_id',
  /** column name */
  KeycloakLastSyncedAt = 'keycloak_last_synced_at',
  /** column name */
  Language = 'language',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Note = 'note',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Phone = 'phone',
  /** column name */
  Settings = 'settings',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

export type IUserUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IUserAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IUserDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IUserDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IUserDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IUserPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IUserSetInput>;
  /** filter the rows which have to be updated */
  where: IUserBoolExp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type IUuidComparisonExp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** A collection of default or custom views for a given data type */
export type IViewCollection = {
  __typename: 'view_collection';
  created_at: Scalars['timestamptz']['output'];
  data_type: IViewDataTypeEnum;
  id: Scalars['uuid']['output'];
  location_id: Scalars['uuid']['output'];
  team_id?: Maybe<Scalars['uuid']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['uuid']['output']>;
  views: Scalars['jsonb']['output'];
};


/** A collection of default or custom views for a given data type */
export type IViewCollectionViewsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "view_collection" */
export type IViewCollectionAggregate = {
  __typename: 'view_collection_aggregate';
  aggregate?: Maybe<IViewCollectionAggregateFields>;
  nodes: Array<IViewCollection>;
};

/** aggregate fields of "view_collection" */
export type IViewCollectionAggregateFields = {
  __typename: 'view_collection_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IViewCollectionMaxFields>;
  min?: Maybe<IViewCollectionMinFields>;
};


/** aggregate fields of "view_collection" */
export type IViewCollectionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IViewCollectionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IViewCollectionAppendInput = {
  views?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "view_collection". All fields are combined with a logical 'AND'. */
export type IViewCollectionBoolExp = {
  _and?: InputMaybe<Array<IViewCollectionBoolExp>>;
  _not?: InputMaybe<IViewCollectionBoolExp>;
  _or?: InputMaybe<Array<IViewCollectionBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  data_type?: InputMaybe<IViewDataTypeEnumComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  team_id?: InputMaybe<IUuidComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
  views?: InputMaybe<IJsonbComparisonExp>;
};

/** unique or primary key constraints on table "view_collection" */
export enum IViewCollectionConstraint {
  /** unique or primary key constraint on columns "id" */
  ViewCollectionPkey = 'view_collection_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IViewCollectionDeleteAtPathInput = {
  views?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IViewCollectionDeleteElemInput = {
  views?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IViewCollectionDeleteKeyInput = {
  views?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "view_collection" */
export type IViewCollectionInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data_type?: InputMaybe<IViewDataTypeEnum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  views?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type IViewCollectionMaxFields = {
  __typename: 'view_collection_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type IViewCollectionMinFields = {
  __typename: 'view_collection_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "view_collection" */
export type IViewCollectionMutationResponse = {
  __typename: 'view_collection_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IViewCollection>;
};

/** on_conflict condition type for table "view_collection" */
export type IViewCollectionOnConflict = {
  constraint: IViewCollectionConstraint;
  update_columns?: Array<IViewCollectionUpdateColumn>;
  where?: InputMaybe<IViewCollectionBoolExp>;
};

/** Ordering options when selecting data from "view_collection". */
export type IViewCollectionOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  data_type?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  team_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  views?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: view_collection */
export type IViewCollectionPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IViewCollectionPrependInput = {
  views?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "view_collection" */
export enum IViewCollectionSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DataType = 'data_type',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Views = 'views'
}

/** input type for updating data in table "view_collection" */
export type IViewCollectionSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data_type?: InputMaybe<IViewDataTypeEnum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  views?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "view_collection" */
export type IViewCollectionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IViewCollectionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IViewCollectionStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data_type?: InputMaybe<IViewDataTypeEnum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  views?: InputMaybe<Scalars['jsonb']['input']>;
};

/** update columns of table "view_collection" */
export enum IViewCollectionUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DataType = 'data_type',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Views = 'views'
}

export type IViewCollectionUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IViewCollectionAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IViewCollectionDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IViewCollectionDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IViewCollectionDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IViewCollectionPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IViewCollectionSetInput>;
  /** filter the rows which have to be updated */
  where: IViewCollectionBoolExp;
};

/** columns and relationships of "view_config" */
export type IViewConfig = {
  __typename: 'view_config';
  config: Scalars['json']['output'];
  config_id: Scalars['String']['output'];
  data_type: IViewDataTypeEnum;
  detail_of_type?: Maybe<IViewDataTypeEnum>;
  id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['uuid']['output'];
};


/** columns and relationships of "view_config" */
export type IViewConfigConfigArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "view_config" */
export type IViewConfigAggregate = {
  __typename: 'view_config_aggregate';
  aggregate?: Maybe<IViewConfigAggregateFields>;
  nodes: Array<IViewConfig>;
};

/** aggregate fields of "view_config" */
export type IViewConfigAggregateFields = {
  __typename: 'view_config_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IViewConfigMaxFields>;
  min?: Maybe<IViewConfigMinFields>;
};


/** aggregate fields of "view_config" */
export type IViewConfigAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IViewConfigSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "view_config". All fields are combined with a logical 'AND'. */
export type IViewConfigBoolExp = {
  _and?: InputMaybe<Array<IViewConfigBoolExp>>;
  _not?: InputMaybe<IViewConfigBoolExp>;
  _or?: InputMaybe<Array<IViewConfigBoolExp>>;
  config?: InputMaybe<IJsonComparisonExp>;
  config_id?: InputMaybe<IStringComparisonExp>;
  data_type?: InputMaybe<IViewDataTypeEnumComparisonExp>;
  detail_of_type?: InputMaybe<IViewDataTypeEnumComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "view_config" */
export enum IViewConfigConstraint {
  /** unique or primary key constraint on columns "user_id", "detail_of_type", "data_type" */
  ViewConfigDataTypeDetailOfTypeUserIdKey = 'view_config_data_type_detail_of_type_user_id_key',
  /** unique or primary key constraint on columns "id" */
  ViewConfigPkey = 'view_config_pkey',
  /** unique or primary key constraint on columns "config_id", "user_id" */
  ViewConfigUserIdConfigIdKey = 'view_config_user_id_config_id_key'
}

/** input type for inserting data into table "view_config" */
export type IViewConfigInsertInput = {
  config?: InputMaybe<Scalars['json']['input']>;
  config_id?: InputMaybe<Scalars['String']['input']>;
  data_type?: InputMaybe<IViewDataTypeEnum>;
  detail_of_type?: InputMaybe<IViewDataTypeEnum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IViewConfigMaxFields = {
  __typename: 'view_config_max_fields';
  config_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type IViewConfigMinFields = {
  __typename: 'view_config_min_fields';
  config_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "view_config" */
export type IViewConfigMutationResponse = {
  __typename: 'view_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IViewConfig>;
};

/** on_conflict condition type for table "view_config" */
export type IViewConfigOnConflict = {
  constraint: IViewConfigConstraint;
  update_columns?: Array<IViewConfigUpdateColumn>;
  where?: InputMaybe<IViewConfigBoolExp>;
};

/** Ordering options when selecting data from "view_config". */
export type IViewConfigOrderBy = {
  config?: InputMaybe<IOrderBy>;
  config_id?: InputMaybe<IOrderBy>;
  data_type?: InputMaybe<IOrderBy>;
  detail_of_type?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: view_config */
export type IViewConfigPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "view_config" */
export enum IViewConfigSelectColumn {
  /** column name */
  Config = 'config',
  /** column name */
  ConfigId = 'config_id',
  /** column name */
  DataType = 'data_type',
  /** column name */
  DetailOfType = 'detail_of_type',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "view_config" */
export type IViewConfigSetInput = {
  config?: InputMaybe<Scalars['json']['input']>;
  config_id?: InputMaybe<Scalars['String']['input']>;
  data_type?: InputMaybe<IViewDataTypeEnum>;
  detail_of_type?: InputMaybe<IViewDataTypeEnum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "view_config" */
export type IViewConfigStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IViewConfigStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IViewConfigStreamCursorValueInput = {
  config?: InputMaybe<Scalars['json']['input']>;
  config_id?: InputMaybe<Scalars['String']['input']>;
  data_type?: InputMaybe<IViewDataTypeEnum>;
  detail_of_type?: InputMaybe<IViewDataTypeEnum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "view_config" */
export enum IViewConfigUpdateColumn {
  /** column name */
  Config = 'config',
  /** column name */
  ConfigId = 'config_id',
  /** column name */
  DataType = 'data_type',
  /** column name */
  DetailOfType = 'detail_of_type',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type IViewConfigUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IViewConfigSetInput>;
  /** filter the rows which have to be updated */
  where: IViewConfigBoolExp;
};

/** columns and relationships of "view_data_type" */
export type IViewDataType = {
  __typename: 'view_data_type';
  value: Scalars['String']['output'];
};

/** aggregated selection of "view_data_type" */
export type IViewDataTypeAggregate = {
  __typename: 'view_data_type_aggregate';
  aggregate?: Maybe<IViewDataTypeAggregateFields>;
  nodes: Array<IViewDataType>;
};

/** aggregate fields of "view_data_type" */
export type IViewDataTypeAggregateFields = {
  __typename: 'view_data_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IViewDataTypeMaxFields>;
  min?: Maybe<IViewDataTypeMinFields>;
};


/** aggregate fields of "view_data_type" */
export type IViewDataTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IViewDataTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "view_data_type". All fields are combined with a logical 'AND'. */
export type IViewDataTypeBoolExp = {
  _and?: InputMaybe<Array<IViewDataTypeBoolExp>>;
  _not?: InputMaybe<IViewDataTypeBoolExp>;
  _or?: InputMaybe<Array<IViewDataTypeBoolExp>>;
  value?: InputMaybe<IStringComparisonExp>;
};

/** unique or primary key constraints on table "view_data_type" */
export enum IViewDataTypeConstraint {
  /** unique or primary key constraint on columns "value" */
  ViewDataTypePkey = 'view_data_type_pkey'
}

export enum IViewDataTypeEnum {
  Asset = 'asset',
  AssetArchive = 'asset_archive',
  AssetState = 'asset_state',
  Consumable = 'consumable',
  Contact = 'contact',
  Logbook = 'logbook',
  Maintenance = 'maintenance',
  MaintenanceArchive = 'maintenance_archive',
  Meter = 'meter',
  ServiceRequest = 'service_request',
  Template = 'template',
  Workorder = 'workorder'
}

/** Boolean expression to compare columns of type "view_data_type_enum". All fields are combined with logical 'AND'. */
export type IViewDataTypeEnumComparisonExp = {
  _eq?: InputMaybe<IViewDataTypeEnum>;
  _in?: InputMaybe<Array<IViewDataTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<IViewDataTypeEnum>;
  _nin?: InputMaybe<Array<IViewDataTypeEnum>>;
};

/** input type for inserting data into table "view_data_type" */
export type IViewDataTypeInsertInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type IViewDataTypeMaxFields = {
  __typename: 'view_data_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IViewDataTypeMinFields = {
  __typename: 'view_data_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "view_data_type" */
export type IViewDataTypeMutationResponse = {
  __typename: 'view_data_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IViewDataType>;
};

/** on_conflict condition type for table "view_data_type" */
export type IViewDataTypeOnConflict = {
  constraint: IViewDataTypeConstraint;
  update_columns?: Array<IViewDataTypeUpdateColumn>;
  where?: InputMaybe<IViewDataTypeBoolExp>;
};

/** Ordering options when selecting data from "view_data_type". */
export type IViewDataTypeOrderBy = {
  value?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: view_data_type */
export type IViewDataTypePkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "view_data_type" */
export enum IViewDataTypeSelectColumn {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "view_data_type" */
export type IViewDataTypeSetInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "view_data_type" */
export type IViewDataTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IViewDataTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IViewDataTypeStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "view_data_type" */
export enum IViewDataTypeUpdateColumn {
  /** column name */
  Value = 'value'
}

export type IViewDataTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IViewDataTypeSetInput>;
  /** filter the rows which have to be updated */
  where: IViewDataTypeBoolExp;
};

/** columns and relationships of "work_order" */
export type IWorkOrder = {
  __typename: 'work_order';
  /** An array relationship */
  activities: Array<IWorkOrderActivity>;
  /** An aggregate relationship */
  activities_aggregate: IWorkOrderActivityAggregate;
  /** An array relationship */
  assets: Array<IAssetXWorkOrder>;
  /** An aggregate relationship */
  assets_aggregate: IAssetXWorkOrderAggregate;
  /** An array relationship */
  assigned_teams: Array<IWorkOrderXTeam>;
  /** An aggregate relationship */
  assigned_teams_aggregate: IWorkOrderXTeamAggregate;
  /** An array relationship */
  assignees: Array<IWorkOrderXUser>;
  /** An aggregate relationship */
  assignees_aggregate: IWorkOrderXUserAggregate;
  /** An object relationship */
  block?: Maybe<IBlockGroup>;
  /** An array relationship */
  block_groups: Array<IBlockGroup>;
  /** An aggregate relationship */
  block_groups_aggregate: IBlockGroupAggregate;
  /** An array relationship */
  categories: Array<IWorkOrderXWorkOrderCategory>;
  /** An aggregate relationship */
  categories_aggregate: IWorkOrderXWorkOrderCategoryAggregate;
  /** An object relationship */
  category?: Maybe<IWorkOrderCategory>;
  category_id?: Maybe<Scalars['uuid']['output']>;
  child_ref?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  child_work_order?: Maybe<IWorkOrder>;
  /** An array relationship */
  collaborators: Array<IWorkOrderXCollaborator>;
  /** An aggregate relationship */
  collaborators_aggregate: IWorkOrderXCollaboratorAggregate;
  /** An array relationship */
  comments: Array<IComment>;
  /** An aggregate relationship */
  comments_aggregate: ICommentAggregate;
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  completed_by?: Maybe<IUser>;
  completed_by_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  consumables_used: Array<IConsumableLog>;
  /** An aggregate relationship */
  consumables_used_aggregate: IConsumableLogAggregate;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by: IUser;
  created_by_api?: Maybe<Scalars['Boolean']['output']>;
  created_by_id: Scalars['uuid']['output'];
  custom_properties?: Maybe<Scalars['jsonb']['output']>;
  /** A computed field, executes function "work_order_days_left_until_overdue" */
  days_left_until_overdue?: Maybe<Scalars['Int']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  documents: Array<IWorkOrderXUpload>;
  /** An aggregate relationship */
  documents_aggregate: IWorkOrderXUploadAggregate;
  due_date?: Maybe<Scalars['date']['output']>;
  due_time?: Maybe<Scalars['time']['output']>;
  /** An array relationship */
  expenses: Array<IWorkOrderExpense>;
  /** An aggregate relationship */
  expenses_aggregate: IWorkOrderExpenseAggregate;
  /** An object relationship */
  feedback?: Maybe<IWorkOrderFeedback>;
  /** A computed field, executes function "check_access_work_order" */
  has_access?: Maybe<Scalars['Boolean']['output']>;
  /** A computed field, executes function "permission.user_work_order_approval_check" */
  has_user_work_order_approval_permission?: Maybe<Scalars['Boolean']['output']>;
  /** A computed field, executes function "permission.user_work_order_data_entry_check" */
  has_user_work_order_data_entry_permission?: Maybe<Scalars['Boolean']['output']>;
  /** A computed field, executes function "permission.user_work_order_select_check" */
  has_user_work_order_select_permission?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['uuid']['output'];
  /** Should be removed in the near future */
  is_only_reported: Scalars['Boolean']['output'];
  last_update: Scalars['timestamptz']['output'];
  /** An object relationship */
  location: ILocation;
  location_id: Scalars['uuid']['output'];
  /** An object relationship */
  maintenance?: Maybe<IMaintenance>;
  maintenance_id?: Maybe<Scalars['uuid']['output']>;
  name: Scalars['String']['output'];
  /** An array relationship */
  notifications: Array<INotification>;
  /** An aggregate relationship */
  notifications_aggregate: INotificationAggregate;
  only_assigned: Scalars['Boolean']['output'];
  /** An object relationship */
  parent?: Maybe<IWorkOrder>;
  parent_ref?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  parent_task?: Maybe<IWorkOrder>;
  /** An object relationship */
  parent_work_order?: Maybe<IWorkOrder>;
  parent_work_order_id?: Maybe<Scalars['uuid']['output']>;
  priority?: Maybe<IWorkOrderPriorityEnum>;
  /** A computed field, executes function "computed_work_order_priority_order_value" */
  priority_order_value?: Maybe<Scalars['Int']['output']>;
  procedure_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  project?: Maybe<IProject>;
  project_id?: Maybe<Scalars['uuid']['output']>;
  public_view_expiration?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  recurrence?: Maybe<IRecurrence>;
  recurrence_id?: Maybe<Scalars['uuid']['output']>;
  recurrence_info?: Maybe<Scalars['jsonb']['output']>;
  reminder_config?: Maybe<Scalars['jsonb']['output']>;
  reminder_date_time?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  reports: Array<IWorkOrderReport>;
  /** An aggregate relationship */
  reports_aggregate: IWorkOrderReportAggregate;
  /** A computed field, executes function "work_order_recurring_root_calculator" */
  root_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  service_request?: Maybe<IServiceRequest>;
  service_request_id?: Maybe<Scalars['Int']['output']>;
  sort_order: Scalars['numeric']['output'];
  /** An object relationship */
  state?: Maybe<IAssetXAssetState>;
  status: IWorkOrderStatusEnum;
  /** A computed field, executes function "computed_work_order_status_order_value" */
  status_order_value?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  subtasks: Array<IWorkOrder>;
  /** An aggregate relationship */
  subtasks_aggregate: IWorkOrderAggregate;
  template_name?: Maybe<Scalars['String']['output']>;
  templated_used_id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "total_hours_work_reports" */
  total_hours_work_reports?: Maybe<Scalars['numeric']['output']>;
  type: IWorkOrderTypeEnum;
  updated_at: Scalars['timestamptz']['output'];
  work_order_number: Scalars['Int']['output'];
};


/** columns and relationships of "work_order" */
export type IWorkOrderActivitiesArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderActivityOrderBy>>;
  where?: InputMaybe<IWorkOrderActivityBoolExp>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderActivitiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderActivityOrderBy>>;
  where?: InputMaybe<IWorkOrderActivityBoolExp>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderAssetsArgs = {
  distinct_on?: InputMaybe<Array<IAssetXWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXWorkOrderOrderBy>>;
  where?: InputMaybe<IAssetXWorkOrderBoolExp>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderAssetsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetXWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXWorkOrderOrderBy>>;
  where?: InputMaybe<IAssetXWorkOrderBoolExp>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderAssignedTeamsArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXTeamOrderBy>>;
  where?: InputMaybe<IWorkOrderXTeamBoolExp>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderAssignedTeamsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXTeamOrderBy>>;
  where?: InputMaybe<IWorkOrderXTeamBoolExp>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderAssigneesArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXUserOrderBy>>;
  where?: InputMaybe<IWorkOrderXUserBoolExp>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderAssigneesAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXUserOrderBy>>;
  where?: InputMaybe<IWorkOrderXUserBoolExp>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderBlockGroupsArgs = {
  distinct_on?: InputMaybe<Array<IBlockGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBlockGroupOrderBy>>;
  where?: InputMaybe<IBlockGroupBoolExp>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderBlockGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IBlockGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBlockGroupOrderBy>>;
  where?: InputMaybe<IBlockGroupBoolExp>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderCategoriesArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXWorkOrderCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXWorkOrderCategoryOrderBy>>;
  where?: InputMaybe<IWorkOrderXWorkOrderCategoryBoolExp>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderCategoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXWorkOrderCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXWorkOrderCategoryOrderBy>>;
  where?: InputMaybe<IWorkOrderXWorkOrderCategoryBoolExp>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderCollaboratorsArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXCollaboratorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXCollaboratorOrderBy>>;
  where?: InputMaybe<IWorkOrderXCollaboratorBoolExp>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderCollaboratorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXCollaboratorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXCollaboratorOrderBy>>;
  where?: InputMaybe<IWorkOrderXCollaboratorBoolExp>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderCommentsArgs = {
  distinct_on?: InputMaybe<Array<ICommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICommentOrderBy>>;
  where?: InputMaybe<ICommentBoolExp>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderCommentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ICommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICommentOrderBy>>;
  where?: InputMaybe<ICommentBoolExp>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderConsumablesUsedArgs = {
  distinct_on?: InputMaybe<Array<IConsumableLogSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableLogOrderBy>>;
  where?: InputMaybe<IConsumableLogBoolExp>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderConsumablesUsedAggregateArgs = {
  distinct_on?: InputMaybe<Array<IConsumableLogSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IConsumableLogOrderBy>>;
  where?: InputMaybe<IConsumableLogBoolExp>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderCustomPropertiesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderDocumentsArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXUploadOrderBy>>;
  where?: InputMaybe<IWorkOrderXUploadBoolExp>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderDocumentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXUploadOrderBy>>;
  where?: InputMaybe<IWorkOrderXUploadBoolExp>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderExpensesArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderExpenseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderExpenseOrderBy>>;
  where?: InputMaybe<IWorkOrderExpenseBoolExp>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderExpensesAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderExpenseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderExpenseOrderBy>>;
  where?: InputMaybe<IWorkOrderExpenseBoolExp>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderNotificationsArgs = {
  distinct_on?: InputMaybe<Array<INotificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<INotificationOrderBy>>;
  where?: InputMaybe<INotificationBoolExp>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderNotificationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<INotificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<INotificationOrderBy>>;
  where?: InputMaybe<INotificationBoolExp>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderRecurrenceInfoArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderReminderConfigArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderReportsArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderReportSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderReportOrderBy>>;
  where?: InputMaybe<IWorkOrderReportBoolExp>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderReportsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderReportSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderReportOrderBy>>;
  where?: InputMaybe<IWorkOrderReportBoolExp>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderSubtasksArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOrderBy>>;
  where?: InputMaybe<IWorkOrderBoolExp>;
};


/** columns and relationships of "work_order" */
export type IWorkOrderSubtasksAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOrderBy>>;
  where?: InputMaybe<IWorkOrderBoolExp>;
};

/** Event log of activity of a work order, e.g., status updates */
export type IWorkOrderActivity = {
  __typename: 'work_order_activity';
  activity_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  activity_by?: Maybe<IUser>;
  activity_by_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  payload?: Maybe<Scalars['json']['output']>;
  type: IWorkOrderActivityTypeEnum;
  /** An object relationship */
  work_order?: Maybe<IWorkOrder>;
  work_order_id: Scalars['uuid']['output'];
};


/** Event log of activity of a work order, e.g., status updates */
export type IWorkOrderActivityPayloadArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "work_order_activity" */
export type IWorkOrderActivityAggregate = {
  __typename: 'work_order_activity_aggregate';
  aggregate?: Maybe<IWorkOrderActivityAggregateFields>;
  nodes: Array<IWorkOrderActivity>;
};

export type IWorkOrderActivityAggregateBoolExp = {
  count?: InputMaybe<IWorkOrderActivityAggregateBoolExpCount>;
};

export type IWorkOrderActivityAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IWorkOrderActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IWorkOrderActivityBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "work_order_activity" */
export type IWorkOrderActivityAggregateFields = {
  __typename: 'work_order_activity_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IWorkOrderActivityMaxFields>;
  min?: Maybe<IWorkOrderActivityMinFields>;
};


/** aggregate fields of "work_order_activity" */
export type IWorkOrderActivityAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IWorkOrderActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "work_order_activity" */
export type IWorkOrderActivityAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IWorkOrderActivityMaxOrderBy>;
  min?: InputMaybe<IWorkOrderActivityMinOrderBy>;
};

/** input type for inserting array relation for remote table "work_order_activity" */
export type IWorkOrderActivityArrRelInsertInput = {
  data: Array<IWorkOrderActivityInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IWorkOrderActivityOnConflict>;
};

/** Boolean expression to filter rows from the table "work_order_activity". All fields are combined with a logical 'AND'. */
export type IWorkOrderActivityBoolExp = {
  _and?: InputMaybe<Array<IWorkOrderActivityBoolExp>>;
  _not?: InputMaybe<IWorkOrderActivityBoolExp>;
  _or?: InputMaybe<Array<IWorkOrderActivityBoolExp>>;
  activity_at?: InputMaybe<ITimestamptzComparisonExp>;
  activity_by?: InputMaybe<IUserBoolExp>;
  activity_by_id?: InputMaybe<IUuidComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  payload?: InputMaybe<IJsonComparisonExp>;
  type?: InputMaybe<IWorkOrderActivityTypeEnumComparisonExp>;
  work_order?: InputMaybe<IWorkOrderBoolExp>;
  work_order_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "work_order_activity" */
export enum IWorkOrderActivityConstraint {
  /** unique or primary key constraint on columns "id" */
  WorkOrderActivityPkey = 'work_order_activity_pkey'
}

/** input type for inserting data into table "work_order_activity" */
export type IWorkOrderActivityInsertInput = {
  activity_at?: InputMaybe<Scalars['timestamptz']['input']>;
  activity_by?: InputMaybe<IUserObjRelInsertInput>;
  activity_by_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  payload?: InputMaybe<Scalars['json']['input']>;
  type?: InputMaybe<IWorkOrderActivityTypeEnum>;
  work_order?: InputMaybe<IWorkOrderObjRelInsertInput>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IWorkOrderActivityMaxFields = {
  __typename: 'work_order_activity_max_fields';
  activity_at?: Maybe<Scalars['timestamptz']['output']>;
  activity_by_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "work_order_activity" */
export type IWorkOrderActivityMaxOrderBy = {
  activity_at?: InputMaybe<IOrderBy>;
  activity_by_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IWorkOrderActivityMinFields = {
  __typename: 'work_order_activity_min_fields';
  activity_at?: Maybe<Scalars['timestamptz']['output']>;
  activity_by_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "work_order_activity" */
export type IWorkOrderActivityMinOrderBy = {
  activity_at?: InputMaybe<IOrderBy>;
  activity_by_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "work_order_activity" */
export type IWorkOrderActivityMutationResponse = {
  __typename: 'work_order_activity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IWorkOrderActivity>;
};

/** on_conflict condition type for table "work_order_activity" */
export type IWorkOrderActivityOnConflict = {
  constraint: IWorkOrderActivityConstraint;
  update_columns?: Array<IWorkOrderActivityUpdateColumn>;
  where?: InputMaybe<IWorkOrderActivityBoolExp>;
};

/** Ordering options when selecting data from "work_order_activity". */
export type IWorkOrderActivityOrderBy = {
  activity_at?: InputMaybe<IOrderBy>;
  activity_by?: InputMaybe<IUserOrderBy>;
  activity_by_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  payload?: InputMaybe<IOrderBy>;
  type?: InputMaybe<IOrderBy>;
  work_order?: InputMaybe<IWorkOrderOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: work_order_activity */
export type IWorkOrderActivityPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "work_order_activity" */
export enum IWorkOrderActivitySelectColumn {
  /** column name */
  ActivityAt = 'activity_at',
  /** column name */
  ActivityById = 'activity_by_id',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Type = 'type',
  /** column name */
  WorkOrderId = 'work_order_id'
}

/** input type for updating data in table "work_order_activity" */
export type IWorkOrderActivitySetInput = {
  activity_at?: InputMaybe<Scalars['timestamptz']['input']>;
  activity_by_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  payload?: InputMaybe<Scalars['json']['input']>;
  type?: InputMaybe<IWorkOrderActivityTypeEnum>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "work_order_activity" */
export type IWorkOrderActivityStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IWorkOrderActivityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IWorkOrderActivityStreamCursorValueInput = {
  activity_at?: InputMaybe<Scalars['timestamptz']['input']>;
  activity_by_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  payload?: InputMaybe<Scalars['json']['input']>;
  type?: InputMaybe<IWorkOrderActivityTypeEnum>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "work_order_activity_type" */
export type IWorkOrderActivityType = {
  __typename: 'work_order_activity_type';
  value: Scalars['String']['output'];
};

/** aggregated selection of "work_order_activity_type" */
export type IWorkOrderActivityTypeAggregate = {
  __typename: 'work_order_activity_type_aggregate';
  aggregate?: Maybe<IWorkOrderActivityTypeAggregateFields>;
  nodes: Array<IWorkOrderActivityType>;
};

/** aggregate fields of "work_order_activity_type" */
export type IWorkOrderActivityTypeAggregateFields = {
  __typename: 'work_order_activity_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IWorkOrderActivityTypeMaxFields>;
  min?: Maybe<IWorkOrderActivityTypeMinFields>;
};


/** aggregate fields of "work_order_activity_type" */
export type IWorkOrderActivityTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IWorkOrderActivityTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "work_order_activity_type". All fields are combined with a logical 'AND'. */
export type IWorkOrderActivityTypeBoolExp = {
  _and?: InputMaybe<Array<IWorkOrderActivityTypeBoolExp>>;
  _not?: InputMaybe<IWorkOrderActivityTypeBoolExp>;
  _or?: InputMaybe<Array<IWorkOrderActivityTypeBoolExp>>;
  value?: InputMaybe<IStringComparisonExp>;
};

/** unique or primary key constraints on table "work_order_activity_type" */
export enum IWorkOrderActivityTypeConstraint {
  /** unique or primary key constraint on columns "value" */
  WorkOrderActivityTypePkey = 'work_order_activity_type_pkey'
}

export enum IWorkOrderActivityTypeEnum {
  Status = 'status'
}

/** Boolean expression to compare columns of type "work_order_activity_type_enum". All fields are combined with logical 'AND'. */
export type IWorkOrderActivityTypeEnumComparisonExp = {
  _eq?: InputMaybe<IWorkOrderActivityTypeEnum>;
  _in?: InputMaybe<Array<IWorkOrderActivityTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<IWorkOrderActivityTypeEnum>;
  _nin?: InputMaybe<Array<IWorkOrderActivityTypeEnum>>;
};

/** input type for inserting data into table "work_order_activity_type" */
export type IWorkOrderActivityTypeInsertInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type IWorkOrderActivityTypeMaxFields = {
  __typename: 'work_order_activity_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IWorkOrderActivityTypeMinFields = {
  __typename: 'work_order_activity_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "work_order_activity_type" */
export type IWorkOrderActivityTypeMutationResponse = {
  __typename: 'work_order_activity_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IWorkOrderActivityType>;
};

/** on_conflict condition type for table "work_order_activity_type" */
export type IWorkOrderActivityTypeOnConflict = {
  constraint: IWorkOrderActivityTypeConstraint;
  update_columns?: Array<IWorkOrderActivityTypeUpdateColumn>;
  where?: InputMaybe<IWorkOrderActivityTypeBoolExp>;
};

/** Ordering options when selecting data from "work_order_activity_type". */
export type IWorkOrderActivityTypeOrderBy = {
  value?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: work_order_activity_type */
export type IWorkOrderActivityTypePkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "work_order_activity_type" */
export enum IWorkOrderActivityTypeSelectColumn {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "work_order_activity_type" */
export type IWorkOrderActivityTypeSetInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "work_order_activity_type" */
export type IWorkOrderActivityTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IWorkOrderActivityTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IWorkOrderActivityTypeStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "work_order_activity_type" */
export enum IWorkOrderActivityTypeUpdateColumn {
  /** column name */
  Value = 'value'
}

export type IWorkOrderActivityTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IWorkOrderActivityTypeSetInput>;
  /** filter the rows which have to be updated */
  where: IWorkOrderActivityTypeBoolExp;
};

/** update columns of table "work_order_activity" */
export enum IWorkOrderActivityUpdateColumn {
  /** column name */
  ActivityAt = 'activity_at',
  /** column name */
  ActivityById = 'activity_by_id',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Type = 'type',
  /** column name */
  WorkOrderId = 'work_order_id'
}

export type IWorkOrderActivityUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IWorkOrderActivitySetInput>;
  /** filter the rows which have to be updated */
  where: IWorkOrderActivityBoolExp;
};

/** aggregated selection of "work_order" */
export type IWorkOrderAggregate = {
  __typename: 'work_order_aggregate';
  aggregate?: Maybe<IWorkOrderAggregateFields>;
  nodes: Array<IWorkOrder>;
};

export type IWorkOrderAggregateBoolExp = {
  bool_and?: InputMaybe<IWorkOrderAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<IWorkOrderAggregateBoolExpBoolOr>;
  count?: InputMaybe<IWorkOrderAggregateBoolExpCount>;
};

export type IWorkOrderAggregateBoolExpBoolAnd = {
  arguments: IWorkOrderSelectColumnWorkOrderAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IWorkOrderBoolExp>;
  predicate: IBooleanComparisonExp;
};

export type IWorkOrderAggregateBoolExpBoolOr = {
  arguments: IWorkOrderSelectColumnWorkOrderAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IWorkOrderBoolExp>;
  predicate: IBooleanComparisonExp;
};

export type IWorkOrderAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IWorkOrderSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IWorkOrderBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "work_order" */
export type IWorkOrderAggregateFields = {
  __typename: 'work_order_aggregate_fields';
  avg?: Maybe<IWorkOrderAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IWorkOrderMaxFields>;
  min?: Maybe<IWorkOrderMinFields>;
  stddev?: Maybe<IWorkOrderStddevFields>;
  stddev_pop?: Maybe<IWorkOrderStddevPopFields>;
  stddev_samp?: Maybe<IWorkOrderStddevSampFields>;
  sum?: Maybe<IWorkOrderSumFields>;
  var_pop?: Maybe<IWorkOrderVarPopFields>;
  var_samp?: Maybe<IWorkOrderVarSampFields>;
  variance?: Maybe<IWorkOrderVarianceFields>;
};


/** aggregate fields of "work_order" */
export type IWorkOrderAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IWorkOrderSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "work_order" */
export type IWorkOrderAggregateOrderBy = {
  avg?: InputMaybe<IWorkOrderAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IWorkOrderMaxOrderBy>;
  min?: InputMaybe<IWorkOrderMinOrderBy>;
  stddev?: InputMaybe<IWorkOrderStddevOrderBy>;
  stddev_pop?: InputMaybe<IWorkOrderStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IWorkOrderStddevSampOrderBy>;
  sum?: InputMaybe<IWorkOrderSumOrderBy>;
  var_pop?: InputMaybe<IWorkOrderVarPopOrderBy>;
  var_samp?: InputMaybe<IWorkOrderVarSampOrderBy>;
  variance?: InputMaybe<IWorkOrderVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IWorkOrderAppendInput = {
  custom_properties?: InputMaybe<Scalars['jsonb']['input']>;
  recurrence_info?: InputMaybe<Scalars['jsonb']['input']>;
  reminder_config?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "work_order" */
export type IWorkOrderArrRelInsertInput = {
  data: Array<IWorkOrderInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IWorkOrderOnConflict>;
};

/** aggregate avg on columns */
export type IWorkOrderAvgFields = {
  __typename: 'work_order_avg_fields';
  /** A computed field, executes function "work_order_days_left_until_overdue" */
  days_left_until_overdue?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "computed_work_order_priority_order_value" */
  priority_order_value?: Maybe<Scalars['Int']['output']>;
  service_request_id?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "computed_work_order_status_order_value" */
  status_order_value?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "total_hours_work_reports" */
  total_hours_work_reports?: Maybe<Scalars['numeric']['output']>;
  work_order_number?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "work_order" */
export type IWorkOrderAvgOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
  sort_order?: InputMaybe<IOrderBy>;
  work_order_number?: InputMaybe<IOrderBy>;
};

/** Boolean expression to filter rows from the table "work_order". All fields are combined with a logical 'AND'. */
export type IWorkOrderBoolExp = {
  _and?: InputMaybe<Array<IWorkOrderBoolExp>>;
  _not?: InputMaybe<IWorkOrderBoolExp>;
  _or?: InputMaybe<Array<IWorkOrderBoolExp>>;
  activities?: InputMaybe<IWorkOrderActivityBoolExp>;
  activities_aggregate?: InputMaybe<IWorkOrderActivityAggregateBoolExp>;
  assets?: InputMaybe<IAssetXWorkOrderBoolExp>;
  assets_aggregate?: InputMaybe<IAssetXWorkOrderAggregateBoolExp>;
  assigned_teams?: InputMaybe<IWorkOrderXTeamBoolExp>;
  assigned_teams_aggregate?: InputMaybe<IWorkOrderXTeamAggregateBoolExp>;
  assignees?: InputMaybe<IWorkOrderXUserBoolExp>;
  assignees_aggregate?: InputMaybe<IWorkOrderXUserAggregateBoolExp>;
  block?: InputMaybe<IBlockGroupBoolExp>;
  block_groups?: InputMaybe<IBlockGroupBoolExp>;
  block_groups_aggregate?: InputMaybe<IBlockGroupAggregateBoolExp>;
  categories?: InputMaybe<IWorkOrderXWorkOrderCategoryBoolExp>;
  categories_aggregate?: InputMaybe<IWorkOrderXWorkOrderCategoryAggregateBoolExp>;
  category?: InputMaybe<IWorkOrderCategoryBoolExp>;
  category_id?: InputMaybe<IUuidComparisonExp>;
  child_ref?: InputMaybe<IUuidComparisonExp>;
  child_work_order?: InputMaybe<IWorkOrderBoolExp>;
  collaborators?: InputMaybe<IWorkOrderXCollaboratorBoolExp>;
  collaborators_aggregate?: InputMaybe<IWorkOrderXCollaboratorAggregateBoolExp>;
  comments?: InputMaybe<ICommentBoolExp>;
  comments_aggregate?: InputMaybe<ICommentAggregateBoolExp>;
  completed_at?: InputMaybe<ITimestamptzComparisonExp>;
  completed_by?: InputMaybe<IUserBoolExp>;
  completed_by_id?: InputMaybe<IUuidComparisonExp>;
  consumables_used?: InputMaybe<IConsumableLogBoolExp>;
  consumables_used_aggregate?: InputMaybe<IConsumableLogAggregateBoolExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  created_by?: InputMaybe<IUserBoolExp>;
  created_by_api?: InputMaybe<IBooleanComparisonExp>;
  created_by_id?: InputMaybe<IUuidComparisonExp>;
  custom_properties?: InputMaybe<IJsonbComparisonExp>;
  days_left_until_overdue?: InputMaybe<IIntComparisonExp>;
  deleted_at?: InputMaybe<ITimestamptzComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  documents?: InputMaybe<IWorkOrderXUploadBoolExp>;
  documents_aggregate?: InputMaybe<IWorkOrderXUploadAggregateBoolExp>;
  due_date?: InputMaybe<IDateComparisonExp>;
  due_time?: InputMaybe<ITimeComparisonExp>;
  expenses?: InputMaybe<IWorkOrderExpenseBoolExp>;
  expenses_aggregate?: InputMaybe<IWorkOrderExpenseAggregateBoolExp>;
  feedback?: InputMaybe<IWorkOrderFeedbackBoolExp>;
  has_access?: InputMaybe<IBooleanComparisonExp>;
  has_user_work_order_approval_permission?: InputMaybe<IBooleanComparisonExp>;
  has_user_work_order_data_entry_permission?: InputMaybe<IBooleanComparisonExp>;
  has_user_work_order_select_permission?: InputMaybe<IBooleanComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  is_only_reported?: InputMaybe<IBooleanComparisonExp>;
  last_update?: InputMaybe<ITimestamptzComparisonExp>;
  location?: InputMaybe<ILocationBoolExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  maintenance?: InputMaybe<IMaintenanceBoolExp>;
  maintenance_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  notifications?: InputMaybe<INotificationBoolExp>;
  notifications_aggregate?: InputMaybe<INotificationAggregateBoolExp>;
  only_assigned?: InputMaybe<IBooleanComparisonExp>;
  parent?: InputMaybe<IWorkOrderBoolExp>;
  parent_ref?: InputMaybe<IUuidComparisonExp>;
  parent_task?: InputMaybe<IWorkOrderBoolExp>;
  parent_work_order?: InputMaybe<IWorkOrderBoolExp>;
  parent_work_order_id?: InputMaybe<IUuidComparisonExp>;
  priority?: InputMaybe<IWorkOrderPriorityEnumComparisonExp>;
  priority_order_value?: InputMaybe<IIntComparisonExp>;
  procedure_id?: InputMaybe<IUuidComparisonExp>;
  project?: InputMaybe<IProjectBoolExp>;
  project_id?: InputMaybe<IUuidComparisonExp>;
  public_view_expiration?: InputMaybe<ITimestamptzComparisonExp>;
  recurrence?: InputMaybe<IRecurrenceBoolExp>;
  recurrence_id?: InputMaybe<IUuidComparisonExp>;
  recurrence_info?: InputMaybe<IJsonbComparisonExp>;
  reminder_config?: InputMaybe<IJsonbComparisonExp>;
  reminder_date_time?: InputMaybe<ITimestamptzComparisonExp>;
  reports?: InputMaybe<IWorkOrderReportBoolExp>;
  reports_aggregate?: InputMaybe<IWorkOrderReportAggregateBoolExp>;
  root_id?: InputMaybe<IStringComparisonExp>;
  service_request?: InputMaybe<IServiceRequestBoolExp>;
  service_request_id?: InputMaybe<IIntComparisonExp>;
  sort_order?: InputMaybe<INumericComparisonExp>;
  state?: InputMaybe<IAssetXAssetStateBoolExp>;
  status?: InputMaybe<IWorkOrderStatusEnumComparisonExp>;
  status_order_value?: InputMaybe<IIntComparisonExp>;
  subtasks?: InputMaybe<IWorkOrderBoolExp>;
  subtasks_aggregate?: InputMaybe<IWorkOrderAggregateBoolExp>;
  template_name?: InputMaybe<IStringComparisonExp>;
  templated_used_id?: InputMaybe<IUuidComparisonExp>;
  total_hours_work_reports?: InputMaybe<INumericComparisonExp>;
  type?: InputMaybe<IWorkOrderTypeEnumComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  work_order_number?: InputMaybe<IIntComparisonExp>;
};

/** columns and relationships of "work_order_category" */
export type IWorkOrderCategory = {
  __typename: 'work_order_category';
  color?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  label: Scalars['String']['output'];
  /** An object relationship */
  location: ILocation;
  location_id: Scalars['uuid']['output'];
};

/** aggregated selection of "work_order_category" */
export type IWorkOrderCategoryAggregate = {
  __typename: 'work_order_category_aggregate';
  aggregate?: Maybe<IWorkOrderCategoryAggregateFields>;
  nodes: Array<IWorkOrderCategory>;
};

/** aggregate fields of "work_order_category" */
export type IWorkOrderCategoryAggregateFields = {
  __typename: 'work_order_category_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IWorkOrderCategoryMaxFields>;
  min?: Maybe<IWorkOrderCategoryMinFields>;
};


/** aggregate fields of "work_order_category" */
export type IWorkOrderCategoryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IWorkOrderCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "work_order_category". All fields are combined with a logical 'AND'. */
export type IWorkOrderCategoryBoolExp = {
  _and?: InputMaybe<Array<IWorkOrderCategoryBoolExp>>;
  _not?: InputMaybe<IWorkOrderCategoryBoolExp>;
  _or?: InputMaybe<Array<IWorkOrderCategoryBoolExp>>;
  color?: InputMaybe<IStringComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  label?: InputMaybe<IStringComparisonExp>;
  location?: InputMaybe<ILocationBoolExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "work_order_category" */
export enum IWorkOrderCategoryConstraint {
  /** unique or primary key constraint on columns "label", "location_id" */
  WorkOrderCategoryLabelLocationIdKey = 'work_order_category_label_location_id_key',
  /** unique or primary key constraint on columns "id" */
  WorkOrderCategoryPkey = 'work_order_category_pkey'
}

/** input type for inserting data into table "work_order_category" */
export type IWorkOrderCategoryInsertInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<ILocationObjRelInsertInput>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IWorkOrderCategoryMaxFields = {
  __typename: 'work_order_category_max_fields';
  color?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type IWorkOrderCategoryMinFields = {
  __typename: 'work_order_category_min_fields';
  color?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "work_order_category" */
export type IWorkOrderCategoryMutationResponse = {
  __typename: 'work_order_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IWorkOrderCategory>;
};

/** input type for inserting object relation for remote table "work_order_category" */
export type IWorkOrderCategoryObjRelInsertInput = {
  data: IWorkOrderCategoryInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IWorkOrderCategoryOnConflict>;
};

/** on_conflict condition type for table "work_order_category" */
export type IWorkOrderCategoryOnConflict = {
  constraint: IWorkOrderCategoryConstraint;
  update_columns?: Array<IWorkOrderCategoryUpdateColumn>;
  where?: InputMaybe<IWorkOrderCategoryBoolExp>;
};

/** Ordering options when selecting data from "work_order_category". */
export type IWorkOrderCategoryOrderBy = {
  color?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  label?: InputMaybe<IOrderBy>;
  location?: InputMaybe<ILocationOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: work_order_category */
export type IWorkOrderCategoryPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "work_order_category" */
export enum IWorkOrderCategorySelectColumn {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  LocationId = 'location_id'
}

/** input type for updating data in table "work_order_category" */
export type IWorkOrderCategorySetInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "work_order_category" */
export type IWorkOrderCategoryStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IWorkOrderCategoryStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IWorkOrderCategoryStreamCursorValueInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "work_order_category" */
export enum IWorkOrderCategoryUpdateColumn {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  LocationId = 'location_id'
}

export type IWorkOrderCategoryUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IWorkOrderCategorySetInput>;
  /** filter the rows which have to be updated */
  where: IWorkOrderCategoryBoolExp;
};

/** columns and relationships of "work_order_completed_by_category_view" */
export type IWorkOrderCompletedByCategoryView = {
  __typename: 'work_order_completed_by_category_view';
  completed_at?: Maybe<Scalars['date']['output']>;
  count_by_category?: Maybe<Scalars['json']['output']>;
  due_date?: Maybe<Scalars['json']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
  total?: Maybe<Scalars['numeric']['output']>;
  work_order_ids_by_category?: Maybe<Scalars['json']['output']>;
};


/** columns and relationships of "work_order_completed_by_category_view" */
export type IWorkOrderCompletedByCategoryViewCountByCategoryArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "work_order_completed_by_category_view" */
export type IWorkOrderCompletedByCategoryViewDueDateArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "work_order_completed_by_category_view" */
export type IWorkOrderCompletedByCategoryViewWorkOrderIdsByCategoryArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "work_order_completed_by_category_view" */
export type IWorkOrderCompletedByCategoryViewAggregate = {
  __typename: 'work_order_completed_by_category_view_aggregate';
  aggregate?: Maybe<IWorkOrderCompletedByCategoryViewAggregateFields>;
  nodes: Array<IWorkOrderCompletedByCategoryView>;
};

/** aggregate fields of "work_order_completed_by_category_view" */
export type IWorkOrderCompletedByCategoryViewAggregateFields = {
  __typename: 'work_order_completed_by_category_view_aggregate_fields';
  avg?: Maybe<IWorkOrderCompletedByCategoryViewAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IWorkOrderCompletedByCategoryViewMaxFields>;
  min?: Maybe<IWorkOrderCompletedByCategoryViewMinFields>;
  stddev?: Maybe<IWorkOrderCompletedByCategoryViewStddevFields>;
  stddev_pop?: Maybe<IWorkOrderCompletedByCategoryViewStddevPopFields>;
  stddev_samp?: Maybe<IWorkOrderCompletedByCategoryViewStddevSampFields>;
  sum?: Maybe<IWorkOrderCompletedByCategoryViewSumFields>;
  var_pop?: Maybe<IWorkOrderCompletedByCategoryViewVarPopFields>;
  var_samp?: Maybe<IWorkOrderCompletedByCategoryViewVarSampFields>;
  variance?: Maybe<IWorkOrderCompletedByCategoryViewVarianceFields>;
};


/** aggregate fields of "work_order_completed_by_category_view" */
export type IWorkOrderCompletedByCategoryViewAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IWorkOrderCompletedByCategoryViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type IWorkOrderCompletedByCategoryViewAvgFields = {
  __typename: 'work_order_completed_by_category_view_avg_fields';
  total?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "work_order_completed_by_category_view". All fields are combined with a logical 'AND'. */
export type IWorkOrderCompletedByCategoryViewBoolExp = {
  _and?: InputMaybe<Array<IWorkOrderCompletedByCategoryViewBoolExp>>;
  _not?: InputMaybe<IWorkOrderCompletedByCategoryViewBoolExp>;
  _or?: InputMaybe<Array<IWorkOrderCompletedByCategoryViewBoolExp>>;
  completed_at?: InputMaybe<IDateComparisonExp>;
  count_by_category?: InputMaybe<IJsonComparisonExp>;
  due_date?: InputMaybe<IJsonComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  team_id?: InputMaybe<IUuidComparisonExp>;
  total?: InputMaybe<INumericComparisonExp>;
  work_order_ids_by_category?: InputMaybe<IJsonComparisonExp>;
};

/** aggregate max on columns */
export type IWorkOrderCompletedByCategoryViewMaxFields = {
  __typename: 'work_order_completed_by_category_view_max_fields';
  completed_at?: Maybe<Scalars['date']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
  total?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type IWorkOrderCompletedByCategoryViewMinFields = {
  __typename: 'work_order_completed_by_category_view_min_fields';
  completed_at?: Maybe<Scalars['date']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
  total?: Maybe<Scalars['numeric']['output']>;
};

/** Ordering options when selecting data from "work_order_completed_by_category_view". */
export type IWorkOrderCompletedByCategoryViewOrderBy = {
  completed_at?: InputMaybe<IOrderBy>;
  count_by_category?: InputMaybe<IOrderBy>;
  due_date?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  team_id?: InputMaybe<IOrderBy>;
  total?: InputMaybe<IOrderBy>;
  work_order_ids_by_category?: InputMaybe<IOrderBy>;
};

/** select columns of table "work_order_completed_by_category_view" */
export enum IWorkOrderCompletedByCategoryViewSelectColumn {
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CountByCategory = 'count_by_category',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  Total = 'total',
  /** column name */
  WorkOrderIdsByCategory = 'work_order_ids_by_category'
}

/** aggregate stddev on columns */
export type IWorkOrderCompletedByCategoryViewStddevFields = {
  __typename: 'work_order_completed_by_category_view_stddev_fields';
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type IWorkOrderCompletedByCategoryViewStddevPopFields = {
  __typename: 'work_order_completed_by_category_view_stddev_pop_fields';
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type IWorkOrderCompletedByCategoryViewStddevSampFields = {
  __typename: 'work_order_completed_by_category_view_stddev_samp_fields';
  total?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "work_order_completed_by_category_view" */
export type IWorkOrderCompletedByCategoryViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IWorkOrderCompletedByCategoryViewStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IWorkOrderCompletedByCategoryViewStreamCursorValueInput = {
  completed_at?: InputMaybe<Scalars['date']['input']>;
  count_by_category?: InputMaybe<Scalars['json']['input']>;
  due_date?: InputMaybe<Scalars['json']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
  total?: InputMaybe<Scalars['numeric']['input']>;
  work_order_ids_by_category?: InputMaybe<Scalars['json']['input']>;
};

/** aggregate sum on columns */
export type IWorkOrderCompletedByCategoryViewSumFields = {
  __typename: 'work_order_completed_by_category_view_sum_fields';
  total?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate var_pop on columns */
export type IWorkOrderCompletedByCategoryViewVarPopFields = {
  __typename: 'work_order_completed_by_category_view_var_pop_fields';
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type IWorkOrderCompletedByCategoryViewVarSampFields = {
  __typename: 'work_order_completed_by_category_view_var_samp_fields';
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type IWorkOrderCompletedByCategoryViewVarianceFields = {
  __typename: 'work_order_completed_by_category_view_variance_fields';
  total?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "work_order_completed_ontime_vs_overdue" */
export type IWorkOrderCompletedOntimeVsOverdue = {
  __typename: 'work_order_completed_ontime_vs_overdue';
  completed_at?: Maybe<Scalars['date']['output']>;
  completed_on_time?: Maybe<Scalars['bigint']['output']>;
  completed_overdue?: Maybe<Scalars['bigint']['output']>;
  due_date?: Maybe<Scalars['json']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  no_due_date?: Maybe<Scalars['bigint']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
  total?: Maybe<Scalars['bigint']['output']>;
};


/** columns and relationships of "work_order_completed_ontime_vs_overdue" */
export type IWorkOrderCompletedOntimeVsOverdueDueDateArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "work_order_completed_ontime_vs_overdue" */
export type IWorkOrderCompletedOntimeVsOverdueAggregate = {
  __typename: 'work_order_completed_ontime_vs_overdue_aggregate';
  aggregate?: Maybe<IWorkOrderCompletedOntimeVsOverdueAggregateFields>;
  nodes: Array<IWorkOrderCompletedOntimeVsOverdue>;
};

/** aggregate fields of "work_order_completed_ontime_vs_overdue" */
export type IWorkOrderCompletedOntimeVsOverdueAggregateFields = {
  __typename: 'work_order_completed_ontime_vs_overdue_aggregate_fields';
  avg?: Maybe<IWorkOrderCompletedOntimeVsOverdueAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IWorkOrderCompletedOntimeVsOverdueMaxFields>;
  min?: Maybe<IWorkOrderCompletedOntimeVsOverdueMinFields>;
  stddev?: Maybe<IWorkOrderCompletedOntimeVsOverdueStddevFields>;
  stddev_pop?: Maybe<IWorkOrderCompletedOntimeVsOverdueStddevPopFields>;
  stddev_samp?: Maybe<IWorkOrderCompletedOntimeVsOverdueStddevSampFields>;
  sum?: Maybe<IWorkOrderCompletedOntimeVsOverdueSumFields>;
  var_pop?: Maybe<IWorkOrderCompletedOntimeVsOverdueVarPopFields>;
  var_samp?: Maybe<IWorkOrderCompletedOntimeVsOverdueVarSampFields>;
  variance?: Maybe<IWorkOrderCompletedOntimeVsOverdueVarianceFields>;
};


/** aggregate fields of "work_order_completed_ontime_vs_overdue" */
export type IWorkOrderCompletedOntimeVsOverdueAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IWorkOrderCompletedOntimeVsOverdueSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type IWorkOrderCompletedOntimeVsOverdueAvgFields = {
  __typename: 'work_order_completed_ontime_vs_overdue_avg_fields';
  completed_on_time?: Maybe<Scalars['Float']['output']>;
  completed_overdue?: Maybe<Scalars['Float']['output']>;
  no_due_date?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "work_order_completed_ontime_vs_overdue". All fields are combined with a logical 'AND'. */
export type IWorkOrderCompletedOntimeVsOverdueBoolExp = {
  _and?: InputMaybe<Array<IWorkOrderCompletedOntimeVsOverdueBoolExp>>;
  _not?: InputMaybe<IWorkOrderCompletedOntimeVsOverdueBoolExp>;
  _or?: InputMaybe<Array<IWorkOrderCompletedOntimeVsOverdueBoolExp>>;
  completed_at?: InputMaybe<IDateComparisonExp>;
  completed_on_time?: InputMaybe<IBigintComparisonExp>;
  completed_overdue?: InputMaybe<IBigintComparisonExp>;
  due_date?: InputMaybe<IJsonComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  no_due_date?: InputMaybe<IBigintComparisonExp>;
  team_id?: InputMaybe<IUuidComparisonExp>;
  total?: InputMaybe<IBigintComparisonExp>;
};

/** aggregate max on columns */
export type IWorkOrderCompletedOntimeVsOverdueMaxFields = {
  __typename: 'work_order_completed_ontime_vs_overdue_max_fields';
  completed_at?: Maybe<Scalars['date']['output']>;
  completed_on_time?: Maybe<Scalars['bigint']['output']>;
  completed_overdue?: Maybe<Scalars['bigint']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  no_due_date?: Maybe<Scalars['bigint']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
  total?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type IWorkOrderCompletedOntimeVsOverdueMinFields = {
  __typename: 'work_order_completed_ontime_vs_overdue_min_fields';
  completed_at?: Maybe<Scalars['date']['output']>;
  completed_on_time?: Maybe<Scalars['bigint']['output']>;
  completed_overdue?: Maybe<Scalars['bigint']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  no_due_date?: Maybe<Scalars['bigint']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
  total?: Maybe<Scalars['bigint']['output']>;
};

/** Ordering options when selecting data from "work_order_completed_ontime_vs_overdue". */
export type IWorkOrderCompletedOntimeVsOverdueOrderBy = {
  completed_at?: InputMaybe<IOrderBy>;
  completed_on_time?: InputMaybe<IOrderBy>;
  completed_overdue?: InputMaybe<IOrderBy>;
  due_date?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  no_due_date?: InputMaybe<IOrderBy>;
  team_id?: InputMaybe<IOrderBy>;
  total?: InputMaybe<IOrderBy>;
};

/** select columns of table "work_order_completed_ontime_vs_overdue" */
export enum IWorkOrderCompletedOntimeVsOverdueSelectColumn {
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CompletedOnTime = 'completed_on_time',
  /** column name */
  CompletedOverdue = 'completed_overdue',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  NoDueDate = 'no_due_date',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  Total = 'total'
}

/** aggregate stddev on columns */
export type IWorkOrderCompletedOntimeVsOverdueStddevFields = {
  __typename: 'work_order_completed_ontime_vs_overdue_stddev_fields';
  completed_on_time?: Maybe<Scalars['Float']['output']>;
  completed_overdue?: Maybe<Scalars['Float']['output']>;
  no_due_date?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type IWorkOrderCompletedOntimeVsOverdueStddevPopFields = {
  __typename: 'work_order_completed_ontime_vs_overdue_stddev_pop_fields';
  completed_on_time?: Maybe<Scalars['Float']['output']>;
  completed_overdue?: Maybe<Scalars['Float']['output']>;
  no_due_date?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type IWorkOrderCompletedOntimeVsOverdueStddevSampFields = {
  __typename: 'work_order_completed_ontime_vs_overdue_stddev_samp_fields';
  completed_on_time?: Maybe<Scalars['Float']['output']>;
  completed_overdue?: Maybe<Scalars['Float']['output']>;
  no_due_date?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "work_order_completed_ontime_vs_overdue" */
export type IWorkOrderCompletedOntimeVsOverdueStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IWorkOrderCompletedOntimeVsOverdueStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IWorkOrderCompletedOntimeVsOverdueStreamCursorValueInput = {
  completed_at?: InputMaybe<Scalars['date']['input']>;
  completed_on_time?: InputMaybe<Scalars['bigint']['input']>;
  completed_overdue?: InputMaybe<Scalars['bigint']['input']>;
  due_date?: InputMaybe<Scalars['json']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  no_due_date?: InputMaybe<Scalars['bigint']['input']>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
  total?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type IWorkOrderCompletedOntimeVsOverdueSumFields = {
  __typename: 'work_order_completed_ontime_vs_overdue_sum_fields';
  completed_on_time?: Maybe<Scalars['bigint']['output']>;
  completed_overdue?: Maybe<Scalars['bigint']['output']>;
  no_due_date?: Maybe<Scalars['bigint']['output']>;
  total?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type IWorkOrderCompletedOntimeVsOverdueVarPopFields = {
  __typename: 'work_order_completed_ontime_vs_overdue_var_pop_fields';
  completed_on_time?: Maybe<Scalars['Float']['output']>;
  completed_overdue?: Maybe<Scalars['Float']['output']>;
  no_due_date?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type IWorkOrderCompletedOntimeVsOverdueVarSampFields = {
  __typename: 'work_order_completed_ontime_vs_overdue_var_samp_fields';
  completed_on_time?: Maybe<Scalars['Float']['output']>;
  completed_overdue?: Maybe<Scalars['Float']['output']>;
  no_due_date?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type IWorkOrderCompletedOntimeVsOverdueVarianceFields = {
  __typename: 'work_order_completed_ontime_vs_overdue_variance_fields';
  completed_on_time?: Maybe<Scalars['Float']['output']>;
  completed_overdue?: Maybe<Scalars['Float']['output']>;
  no_due_date?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** unique or primary key constraints on table "work_order" */
export enum IWorkOrderConstraint {
  /** unique or primary key constraint on columns "work_order_number", "location_id" */
  WorkOrderLocationIdWorkOrderNumberKey = 'work_order_location_id_work_order_number_key',
  /** unique or primary key constraint on columns "parent_ref" */
  WorkOrderParentRefKey = 'work_order_parent_ref_key',
  /** unique or primary key constraint on columns "id" */
  WorkOrderPkey = 'work_order_pkey',
  /** unique or primary key constraint on columns "procedure_id" */
  WorkOrderProcedureIdKey = 'work_order_procedure_id_key',
  /** unique or primary key constraint on columns "recurrence_id" */
  WorkOrderRecurrenceIdKey = 'work_order_recurrence_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IWorkOrderDeleteAtPathInput = {
  custom_properties?: InputMaybe<Array<Scalars['String']['input']>>;
  recurrence_info?: InputMaybe<Array<Scalars['String']['input']>>;
  reminder_config?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IWorkOrderDeleteElemInput = {
  custom_properties?: InputMaybe<Scalars['Int']['input']>;
  recurrence_info?: InputMaybe<Scalars['Int']['input']>;
  reminder_config?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IWorkOrderDeleteKeyInput = {
  custom_properties?: InputMaybe<Scalars['String']['input']>;
  recurrence_info?: InputMaybe<Scalars['String']['input']>;
  reminder_config?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "work_order_expense" */
export type IWorkOrderExpense = {
  __typename: 'work_order_expense';
  amount: Scalars['numeric']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by: IUser;
  created_by_id: Scalars['uuid']['output'];
  description?: Maybe<Scalars['String']['output']>;
  expense_date: Scalars['date']['output'];
  id: Scalars['uuid']['output'];
  location_id: Scalars['uuid']['output'];
  upload_ids: Scalars['jsonb']['output'];
  /** A computed field, executes function "work_order_expense_uploads" */
  uploads?: Maybe<Array<IUpload>>;
  /** An object relationship */
  work_order: IWorkOrder;
  work_order_id: Scalars['uuid']['output'];
};


/** columns and relationships of "work_order_expense" */
export type IWorkOrderExpenseUploadIdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "work_order_expense" */
export type IWorkOrderExpenseUploadsArgs = {
  distinct_on?: InputMaybe<Array<IUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUploadOrderBy>>;
  where?: InputMaybe<IUploadBoolExp>;
};

/** aggregated selection of "work_order_expense" */
export type IWorkOrderExpenseAggregate = {
  __typename: 'work_order_expense_aggregate';
  aggregate?: Maybe<IWorkOrderExpenseAggregateFields>;
  nodes: Array<IWorkOrderExpense>;
};

export type IWorkOrderExpenseAggregateBoolExp = {
  count?: InputMaybe<IWorkOrderExpenseAggregateBoolExpCount>;
};

export type IWorkOrderExpenseAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IWorkOrderExpenseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IWorkOrderExpenseBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "work_order_expense" */
export type IWorkOrderExpenseAggregateFields = {
  __typename: 'work_order_expense_aggregate_fields';
  avg?: Maybe<IWorkOrderExpenseAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IWorkOrderExpenseMaxFields>;
  min?: Maybe<IWorkOrderExpenseMinFields>;
  stddev?: Maybe<IWorkOrderExpenseStddevFields>;
  stddev_pop?: Maybe<IWorkOrderExpenseStddevPopFields>;
  stddev_samp?: Maybe<IWorkOrderExpenseStddevSampFields>;
  sum?: Maybe<IWorkOrderExpenseSumFields>;
  var_pop?: Maybe<IWorkOrderExpenseVarPopFields>;
  var_samp?: Maybe<IWorkOrderExpenseVarSampFields>;
  variance?: Maybe<IWorkOrderExpenseVarianceFields>;
};


/** aggregate fields of "work_order_expense" */
export type IWorkOrderExpenseAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IWorkOrderExpenseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "work_order_expense" */
export type IWorkOrderExpenseAggregateOrderBy = {
  avg?: InputMaybe<IWorkOrderExpenseAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IWorkOrderExpenseMaxOrderBy>;
  min?: InputMaybe<IWorkOrderExpenseMinOrderBy>;
  stddev?: InputMaybe<IWorkOrderExpenseStddevOrderBy>;
  stddev_pop?: InputMaybe<IWorkOrderExpenseStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IWorkOrderExpenseStddevSampOrderBy>;
  sum?: InputMaybe<IWorkOrderExpenseSumOrderBy>;
  var_pop?: InputMaybe<IWorkOrderExpenseVarPopOrderBy>;
  var_samp?: InputMaybe<IWorkOrderExpenseVarSampOrderBy>;
  variance?: InputMaybe<IWorkOrderExpenseVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IWorkOrderExpenseAppendInput = {
  upload_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "work_order_expense" */
export type IWorkOrderExpenseArrRelInsertInput = {
  data: Array<IWorkOrderExpenseInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IWorkOrderExpenseOnConflict>;
};

/** aggregate avg on columns */
export type IWorkOrderExpenseAvgFields = {
  __typename: 'work_order_expense_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "work_order_expense" */
export type IWorkOrderExpenseAvgOrderBy = {
  amount?: InputMaybe<IOrderBy>;
};

/** Boolean expression to filter rows from the table "work_order_expense". All fields are combined with a logical 'AND'. */
export type IWorkOrderExpenseBoolExp = {
  _and?: InputMaybe<Array<IWorkOrderExpenseBoolExp>>;
  _not?: InputMaybe<IWorkOrderExpenseBoolExp>;
  _or?: InputMaybe<Array<IWorkOrderExpenseBoolExp>>;
  amount?: InputMaybe<INumericComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  created_by?: InputMaybe<IUserBoolExp>;
  created_by_id?: InputMaybe<IUuidComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  expense_date?: InputMaybe<IDateComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  upload_ids?: InputMaybe<IJsonbComparisonExp>;
  uploads?: InputMaybe<IUploadBoolExp>;
  work_order?: InputMaybe<IWorkOrderBoolExp>;
  work_order_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "work_order_expense" */
export enum IWorkOrderExpenseConstraint {
  /** unique or primary key constraint on columns "id" */
  WorkOrderExpensePkey = 'work_order_expense_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IWorkOrderExpenseDeleteAtPathInput = {
  upload_ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IWorkOrderExpenseDeleteElemInput = {
  upload_ids?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IWorkOrderExpenseDeleteKeyInput = {
  upload_ids?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "work_order_expense" */
export type IWorkOrderExpenseIncInput = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "work_order_expense" */
export type IWorkOrderExpenseInsertInput = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<IUserObjRelInsertInput>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expense_date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  upload_ids?: InputMaybe<Scalars['jsonb']['input']>;
  work_order?: InputMaybe<IWorkOrderObjRelInsertInput>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IWorkOrderExpenseMaxFields = {
  __typename: 'work_order_expense_max_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expense_date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "work_order_expense" */
export type IWorkOrderExpenseMaxOrderBy = {
  amount?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  expense_date?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IWorkOrderExpenseMinFields = {
  __typename: 'work_order_expense_min_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expense_date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "work_order_expense" */
export type IWorkOrderExpenseMinOrderBy = {
  amount?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  expense_date?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "work_order_expense" */
export type IWorkOrderExpenseMutationResponse = {
  __typename: 'work_order_expense_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IWorkOrderExpense>;
};

/** on_conflict condition type for table "work_order_expense" */
export type IWorkOrderExpenseOnConflict = {
  constraint: IWorkOrderExpenseConstraint;
  update_columns?: Array<IWorkOrderExpenseUpdateColumn>;
  where?: InputMaybe<IWorkOrderExpenseBoolExp>;
};

/** Ordering options when selecting data from "work_order_expense". */
export type IWorkOrderExpenseOrderBy = {
  amount?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by?: InputMaybe<IUserOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  expense_date?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  upload_ids?: InputMaybe<IOrderBy>;
  uploads_aggregate?: InputMaybe<IUploadAggregateOrderBy>;
  work_order?: InputMaybe<IWorkOrderOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: work_order_expense */
export type IWorkOrderExpensePkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IWorkOrderExpensePrependInput = {
  upload_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "work_order_expense" */
export enum IWorkOrderExpenseSelectColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Description = 'description',
  /** column name */
  ExpenseDate = 'expense_date',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  UploadIds = 'upload_ids',
  /** column name */
  WorkOrderId = 'work_order_id'
}

/** input type for updating data in table "work_order_expense" */
export type IWorkOrderExpenseSetInput = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expense_date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  upload_ids?: InputMaybe<Scalars['jsonb']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type IWorkOrderExpenseStddevFields = {
  __typename: 'work_order_expense_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "work_order_expense" */
export type IWorkOrderExpenseStddevOrderBy = {
  amount?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_pop on columns */
export type IWorkOrderExpenseStddevPopFields = {
  __typename: 'work_order_expense_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "work_order_expense" */
export type IWorkOrderExpenseStddevPopOrderBy = {
  amount?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_samp on columns */
export type IWorkOrderExpenseStddevSampFields = {
  __typename: 'work_order_expense_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "work_order_expense" */
export type IWorkOrderExpenseStddevSampOrderBy = {
  amount?: InputMaybe<IOrderBy>;
};

/** Streaming cursor of the table "work_order_expense" */
export type IWorkOrderExpenseStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IWorkOrderExpenseStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IWorkOrderExpenseStreamCursorValueInput = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expense_date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  upload_ids?: InputMaybe<Scalars['jsonb']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type IWorkOrderExpenseSumFields = {
  __typename: 'work_order_expense_sum_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "work_order_expense" */
export type IWorkOrderExpenseSumOrderBy = {
  amount?: InputMaybe<IOrderBy>;
};

/** update columns of table "work_order_expense" */
export enum IWorkOrderExpenseUpdateColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Description = 'description',
  /** column name */
  ExpenseDate = 'expense_date',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  UploadIds = 'upload_ids',
  /** column name */
  WorkOrderId = 'work_order_id'
}

export type IWorkOrderExpenseUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IWorkOrderExpenseAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IWorkOrderExpenseDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IWorkOrderExpenseDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IWorkOrderExpenseDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IWorkOrderExpenseIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IWorkOrderExpensePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IWorkOrderExpenseSetInput>;
  /** filter the rows which have to be updated */
  where: IWorkOrderExpenseBoolExp;
};

/** aggregate var_pop on columns */
export type IWorkOrderExpenseVarPopFields = {
  __typename: 'work_order_expense_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "work_order_expense" */
export type IWorkOrderExpenseVarPopOrderBy = {
  amount?: InputMaybe<IOrderBy>;
};

/** aggregate var_samp on columns */
export type IWorkOrderExpenseVarSampFields = {
  __typename: 'work_order_expense_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "work_order_expense" */
export type IWorkOrderExpenseVarSampOrderBy = {
  amount?: InputMaybe<IOrderBy>;
};

/** aggregate variance on columns */
export type IWorkOrderExpenseVarianceFields = {
  __typename: 'work_order_expense_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "work_order_expense" */
export type IWorkOrderExpenseVarianceOrderBy = {
  amount?: InputMaybe<IOrderBy>;
};

/** columns and relationships of "work_order_feedback" */
export type IWorkOrderFeedback = {
  __typename: 'work_order_feedback';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by: IUser;
  created_by_id: Scalars['uuid']['output'];
  feedback?: Maybe<Scalars['String']['output']>;
  location_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  upload_ids: Scalars['jsonb']['output'];
  /** A computed field, executes function "work_order_feedback_uploads" */
  uploads?: Maybe<Array<IUpload>>;
  /** An object relationship */
  work_order: IWorkOrder;
  work_order_id: Scalars['uuid']['output'];
};


/** columns and relationships of "work_order_feedback" */
export type IWorkOrderFeedbackUploadIdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "work_order_feedback" */
export type IWorkOrderFeedbackUploadsArgs = {
  distinct_on?: InputMaybe<Array<IUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUploadOrderBy>>;
  where?: InputMaybe<IUploadBoolExp>;
};

/** aggregated selection of "work_order_feedback" */
export type IWorkOrderFeedbackAggregate = {
  __typename: 'work_order_feedback_aggregate';
  aggregate?: Maybe<IWorkOrderFeedbackAggregateFields>;
  nodes: Array<IWorkOrderFeedback>;
};

/** aggregate fields of "work_order_feedback" */
export type IWorkOrderFeedbackAggregateFields = {
  __typename: 'work_order_feedback_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IWorkOrderFeedbackMaxFields>;
  min?: Maybe<IWorkOrderFeedbackMinFields>;
};


/** aggregate fields of "work_order_feedback" */
export type IWorkOrderFeedbackAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IWorkOrderFeedbackSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IWorkOrderFeedbackAppendInput = {
  upload_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "work_order_feedback". All fields are combined with a logical 'AND'. */
export type IWorkOrderFeedbackBoolExp = {
  _and?: InputMaybe<Array<IWorkOrderFeedbackBoolExp>>;
  _not?: InputMaybe<IWorkOrderFeedbackBoolExp>;
  _or?: InputMaybe<Array<IWorkOrderFeedbackBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  created_by?: InputMaybe<IUserBoolExp>;
  created_by_id?: InputMaybe<IUuidComparisonExp>;
  feedback?: InputMaybe<IStringComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  upload_ids?: InputMaybe<IJsonbComparisonExp>;
  uploads?: InputMaybe<IUploadBoolExp>;
  work_order?: InputMaybe<IWorkOrderBoolExp>;
  work_order_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "work_order_feedback" */
export enum IWorkOrderFeedbackConstraint {
  /** unique or primary key constraint on columns "work_order_id" */
  WorkOrderFeedbackPkey = 'work_order_feedback_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IWorkOrderFeedbackDeleteAtPathInput = {
  upload_ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IWorkOrderFeedbackDeleteElemInput = {
  upload_ids?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IWorkOrderFeedbackDeleteKeyInput = {
  upload_ids?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "work_order_feedback" */
export type IWorkOrderFeedbackInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<IUserObjRelInsertInput>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  upload_ids?: InputMaybe<Scalars['jsonb']['input']>;
  work_order?: InputMaybe<IWorkOrderObjRelInsertInput>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IWorkOrderFeedbackMaxFields = {
  __typename: 'work_order_feedback_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  feedback?: Maybe<Scalars['String']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type IWorkOrderFeedbackMinFields = {
  __typename: 'work_order_feedback_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  feedback?: Maybe<Scalars['String']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "work_order_feedback" */
export type IWorkOrderFeedbackMutationResponse = {
  __typename: 'work_order_feedback_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IWorkOrderFeedback>;
};

/** input type for inserting object relation for remote table "work_order_feedback" */
export type IWorkOrderFeedbackObjRelInsertInput = {
  data: IWorkOrderFeedbackInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IWorkOrderFeedbackOnConflict>;
};

/** on_conflict condition type for table "work_order_feedback" */
export type IWorkOrderFeedbackOnConflict = {
  constraint: IWorkOrderFeedbackConstraint;
  update_columns?: Array<IWorkOrderFeedbackUpdateColumn>;
  where?: InputMaybe<IWorkOrderFeedbackBoolExp>;
};

/** Ordering options when selecting data from "work_order_feedback". */
export type IWorkOrderFeedbackOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  created_by?: InputMaybe<IUserOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  feedback?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  upload_ids?: InputMaybe<IOrderBy>;
  uploads_aggregate?: InputMaybe<IUploadAggregateOrderBy>;
  work_order?: InputMaybe<IWorkOrderOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: work_order_feedback */
export type IWorkOrderFeedbackPkColumnsInput = {
  work_order_id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IWorkOrderFeedbackPrependInput = {
  upload_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "work_order_feedback" */
export enum IWorkOrderFeedbackSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Feedback = 'feedback',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadIds = 'upload_ids',
  /** column name */
  WorkOrderId = 'work_order_id'
}

/** input type for updating data in table "work_order_feedback" */
export type IWorkOrderFeedbackSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  upload_ids?: InputMaybe<Scalars['jsonb']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "work_order_feedback" */
export type IWorkOrderFeedbackStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IWorkOrderFeedbackStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IWorkOrderFeedbackStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  upload_ids?: InputMaybe<Scalars['jsonb']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "work_order_feedback" */
export enum IWorkOrderFeedbackUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Feedback = 'feedback',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadIds = 'upload_ids',
  /** column name */
  WorkOrderId = 'work_order_id'
}

export type IWorkOrderFeedbackUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IWorkOrderFeedbackAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IWorkOrderFeedbackDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IWorkOrderFeedbackDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IWorkOrderFeedbackDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IWorkOrderFeedbackPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IWorkOrderFeedbackSetInput>;
  /** filter the rows which have to be updated */
  where: IWorkOrderFeedbackBoolExp;
};

export type IWorkOrderFromStreamArgs = {
  work_order_ids?: InputMaybe<Scalars['json']['input']>;
};

/** input type for incrementing numeric columns in table "work_order" */
export type IWorkOrderIncInput = {
  service_request_id?: InputMaybe<Scalars['Int']['input']>;
  sort_order?: InputMaybe<Scalars['numeric']['input']>;
  work_order_number?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "work_order" */
export type IWorkOrderInsertInput = {
  activities?: InputMaybe<IWorkOrderActivityArrRelInsertInput>;
  assets?: InputMaybe<IAssetXWorkOrderArrRelInsertInput>;
  assigned_teams?: InputMaybe<IWorkOrderXTeamArrRelInsertInput>;
  assignees?: InputMaybe<IWorkOrderXUserArrRelInsertInput>;
  block?: InputMaybe<IBlockGroupObjRelInsertInput>;
  block_groups?: InputMaybe<IBlockGroupArrRelInsertInput>;
  categories?: InputMaybe<IWorkOrderXWorkOrderCategoryArrRelInsertInput>;
  category?: InputMaybe<IWorkOrderCategoryObjRelInsertInput>;
  category_id?: InputMaybe<Scalars['uuid']['input']>;
  child_ref?: InputMaybe<Scalars['uuid']['input']>;
  child_work_order?: InputMaybe<IWorkOrderObjRelInsertInput>;
  collaborators?: InputMaybe<IWorkOrderXCollaboratorArrRelInsertInput>;
  comments?: InputMaybe<ICommentArrRelInsertInput>;
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  completed_by?: InputMaybe<IUserObjRelInsertInput>;
  completed_by_id?: InputMaybe<Scalars['uuid']['input']>;
  consumables_used?: InputMaybe<IConsumableLogArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<IUserObjRelInsertInput>;
  created_by_api?: InputMaybe<Scalars['Boolean']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  custom_properties?: InputMaybe<Scalars['jsonb']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<IWorkOrderXUploadArrRelInsertInput>;
  due_date?: InputMaybe<Scalars['date']['input']>;
  due_time?: InputMaybe<Scalars['time']['input']>;
  expenses?: InputMaybe<IWorkOrderExpenseArrRelInsertInput>;
  feedback?: InputMaybe<IWorkOrderFeedbackObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  /** Should be removed in the near future */
  is_only_reported?: InputMaybe<Scalars['Boolean']['input']>;
  last_update?: InputMaybe<Scalars['timestamptz']['input']>;
  location?: InputMaybe<ILocationObjRelInsertInput>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  maintenance?: InputMaybe<IMaintenanceObjRelInsertInput>;
  maintenance_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<INotificationArrRelInsertInput>;
  only_assigned?: InputMaybe<Scalars['Boolean']['input']>;
  parent?: InputMaybe<IWorkOrderObjRelInsertInput>;
  parent_ref?: InputMaybe<Scalars['uuid']['input']>;
  parent_task?: InputMaybe<IWorkOrderObjRelInsertInput>;
  parent_work_order?: InputMaybe<IWorkOrderObjRelInsertInput>;
  parent_work_order_id?: InputMaybe<Scalars['uuid']['input']>;
  priority?: InputMaybe<IWorkOrderPriorityEnum>;
  procedure_id?: InputMaybe<Scalars['uuid']['input']>;
  project?: InputMaybe<IProjectObjRelInsertInput>;
  project_id?: InputMaybe<Scalars['uuid']['input']>;
  public_view_expiration?: InputMaybe<Scalars['timestamptz']['input']>;
  recurrence?: InputMaybe<IRecurrenceObjRelInsertInput>;
  recurrence_id?: InputMaybe<Scalars['uuid']['input']>;
  recurrence_info?: InputMaybe<Scalars['jsonb']['input']>;
  reminder_config?: InputMaybe<Scalars['jsonb']['input']>;
  reminder_date_time?: InputMaybe<Scalars['timestamptz']['input']>;
  reports?: InputMaybe<IWorkOrderReportArrRelInsertInput>;
  service_request?: InputMaybe<IServiceRequestObjRelInsertInput>;
  service_request_id?: InputMaybe<Scalars['Int']['input']>;
  sort_order?: InputMaybe<Scalars['numeric']['input']>;
  state?: InputMaybe<IAssetXAssetStateObjRelInsertInput>;
  status?: InputMaybe<IWorkOrderStatusEnum>;
  subtasks?: InputMaybe<IWorkOrderArrRelInsertInput>;
  template_name?: InputMaybe<Scalars['String']['input']>;
  templated_used_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<IWorkOrderTypeEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_order_number?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "work_order_last_update" */
export type IWorkOrderLastUpdate = {
  __typename: 'work_order_last_update';
  id?: Maybe<Scalars['uuid']['output']>;
  last_update?: Maybe<Scalars['timestamptz']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "work_order_last_update" */
export type IWorkOrderLastUpdateAggregate = {
  __typename: 'work_order_last_update_aggregate';
  aggregate?: Maybe<IWorkOrderLastUpdateAggregateFields>;
  nodes: Array<IWorkOrderLastUpdate>;
};

/** aggregate fields of "work_order_last_update" */
export type IWorkOrderLastUpdateAggregateFields = {
  __typename: 'work_order_last_update_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IWorkOrderLastUpdateMaxFields>;
  min?: Maybe<IWorkOrderLastUpdateMinFields>;
};


/** aggregate fields of "work_order_last_update" */
export type IWorkOrderLastUpdateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IWorkOrderLastUpdateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "work_order_last_update". All fields are combined with a logical 'AND'. */
export type IWorkOrderLastUpdateBoolExp = {
  _and?: InputMaybe<Array<IWorkOrderLastUpdateBoolExp>>;
  _not?: InputMaybe<IWorkOrderLastUpdateBoolExp>;
  _or?: InputMaybe<Array<IWorkOrderLastUpdateBoolExp>>;
  id?: InputMaybe<IUuidComparisonExp>;
  last_update?: InputMaybe<ITimestamptzComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
};

/** input type for inserting data into table "work_order_last_update" */
export type IWorkOrderLastUpdateInsertInput = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  last_update?: InputMaybe<Scalars['timestamptz']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IWorkOrderLastUpdateMaxFields = {
  __typename: 'work_order_last_update_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  last_update?: Maybe<Scalars['timestamptz']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type IWorkOrderLastUpdateMinFields = {
  __typename: 'work_order_last_update_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  last_update?: Maybe<Scalars['timestamptz']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "work_order_last_update" */
export type IWorkOrderLastUpdateMutationResponse = {
  __typename: 'work_order_last_update_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IWorkOrderLastUpdate>;
};

/** Ordering options when selecting data from "work_order_last_update". */
export type IWorkOrderLastUpdateOrderBy = {
  id?: InputMaybe<IOrderBy>;
  last_update?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
};

/** select columns of table "work_order_last_update" */
export enum IWorkOrderLastUpdateSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  LastUpdate = 'last_update',
  /** column name */
  LocationId = 'location_id'
}

/** input type for updating data in table "work_order_last_update" */
export type IWorkOrderLastUpdateSetInput = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  last_update?: InputMaybe<Scalars['timestamptz']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "work_order_last_update" */
export type IWorkOrderLastUpdateStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IWorkOrderLastUpdateStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IWorkOrderLastUpdateStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  last_update?: InputMaybe<Scalars['timestamptz']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
};

export type IWorkOrderLastUpdateUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IWorkOrderLastUpdateSetInput>;
  /** filter the rows which have to be updated */
  where: IWorkOrderLastUpdateBoolExp;
};

/** aggregate max on columns */
export type IWorkOrderMaxFields = {
  __typename: 'work_order_max_fields';
  category_id?: Maybe<Scalars['uuid']['output']>;
  child_ref?: Maybe<Scalars['uuid']['output']>;
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  completed_by_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "work_order_days_left_until_overdue" */
  days_left_until_overdue?: Maybe<Scalars['Int']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_update?: Maybe<Scalars['timestamptz']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  maintenance_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent_ref?: Maybe<Scalars['uuid']['output']>;
  parent_work_order_id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "computed_work_order_priority_order_value" */
  priority_order_value?: Maybe<Scalars['Int']['output']>;
  procedure_id?: Maybe<Scalars['uuid']['output']>;
  project_id?: Maybe<Scalars['uuid']['output']>;
  public_view_expiration?: Maybe<Scalars['timestamptz']['output']>;
  recurrence_id?: Maybe<Scalars['uuid']['output']>;
  reminder_date_time?: Maybe<Scalars['timestamptz']['output']>;
  /** A computed field, executes function "work_order_recurring_root_calculator" */
  root_id?: Maybe<Scalars['String']['output']>;
  service_request_id?: Maybe<Scalars['Int']['output']>;
  sort_order?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "computed_work_order_status_order_value" */
  status_order_value?: Maybe<Scalars['Int']['output']>;
  template_name?: Maybe<Scalars['String']['output']>;
  templated_used_id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "total_hours_work_reports" */
  total_hours_work_reports?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  work_order_number?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "work_order" */
export type IWorkOrderMaxOrderBy = {
  category_id?: InputMaybe<IOrderBy>;
  child_ref?: InputMaybe<IOrderBy>;
  completed_at?: InputMaybe<IOrderBy>;
  completed_by_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  deleted_at?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  due_date?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  last_update?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  maintenance_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  parent_ref?: InputMaybe<IOrderBy>;
  parent_work_order_id?: InputMaybe<IOrderBy>;
  procedure_id?: InputMaybe<IOrderBy>;
  project_id?: InputMaybe<IOrderBy>;
  public_view_expiration?: InputMaybe<IOrderBy>;
  recurrence_id?: InputMaybe<IOrderBy>;
  reminder_date_time?: InputMaybe<IOrderBy>;
  service_request_id?: InputMaybe<IOrderBy>;
  sort_order?: InputMaybe<IOrderBy>;
  template_name?: InputMaybe<IOrderBy>;
  templated_used_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  work_order_number?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IWorkOrderMinFields = {
  __typename: 'work_order_min_fields';
  category_id?: Maybe<Scalars['uuid']['output']>;
  child_ref?: Maybe<Scalars['uuid']['output']>;
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  completed_by_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "work_order_days_left_until_overdue" */
  days_left_until_overdue?: Maybe<Scalars['Int']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_update?: Maybe<Scalars['timestamptz']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  maintenance_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent_ref?: Maybe<Scalars['uuid']['output']>;
  parent_work_order_id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "computed_work_order_priority_order_value" */
  priority_order_value?: Maybe<Scalars['Int']['output']>;
  procedure_id?: Maybe<Scalars['uuid']['output']>;
  project_id?: Maybe<Scalars['uuid']['output']>;
  public_view_expiration?: Maybe<Scalars['timestamptz']['output']>;
  recurrence_id?: Maybe<Scalars['uuid']['output']>;
  reminder_date_time?: Maybe<Scalars['timestamptz']['output']>;
  /** A computed field, executes function "work_order_recurring_root_calculator" */
  root_id?: Maybe<Scalars['String']['output']>;
  service_request_id?: Maybe<Scalars['Int']['output']>;
  sort_order?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "computed_work_order_status_order_value" */
  status_order_value?: Maybe<Scalars['Int']['output']>;
  template_name?: Maybe<Scalars['String']['output']>;
  templated_used_id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "total_hours_work_reports" */
  total_hours_work_reports?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  work_order_number?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "work_order" */
export type IWorkOrderMinOrderBy = {
  category_id?: InputMaybe<IOrderBy>;
  child_ref?: InputMaybe<IOrderBy>;
  completed_at?: InputMaybe<IOrderBy>;
  completed_by_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  deleted_at?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  due_date?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  last_update?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  maintenance_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  parent_ref?: InputMaybe<IOrderBy>;
  parent_work_order_id?: InputMaybe<IOrderBy>;
  procedure_id?: InputMaybe<IOrderBy>;
  project_id?: InputMaybe<IOrderBy>;
  public_view_expiration?: InputMaybe<IOrderBy>;
  recurrence_id?: InputMaybe<IOrderBy>;
  reminder_date_time?: InputMaybe<IOrderBy>;
  service_request_id?: InputMaybe<IOrderBy>;
  sort_order?: InputMaybe<IOrderBy>;
  template_name?: InputMaybe<IOrderBy>;
  templated_used_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  work_order_number?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "work_order" */
export type IWorkOrderMutationResponse = {
  __typename: 'work_order_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IWorkOrder>;
};

/** input type for inserting object relation for remote table "work_order" */
export type IWorkOrderObjRelInsertInput = {
  data: IWorkOrderInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IWorkOrderOnConflict>;
};

/** on_conflict condition type for table "work_order" */
export type IWorkOrderOnConflict = {
  constraint: IWorkOrderConstraint;
  update_columns?: Array<IWorkOrderUpdateColumn>;
  where?: InputMaybe<IWorkOrderBoolExp>;
};

/** columns and relationships of "work_order_open_grouped_by_due_date_view" */
export type IWorkOrderOpenGroupedByDueDateView = {
  __typename: 'work_order_open_grouped_by_due_date_view';
  count?: Maybe<Scalars['bigint']['output']>;
  due_date?: Maybe<Scalars['date']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  not_recurring_tasks?: Maybe<Scalars['bigint']['output']>;
  recurring_tasks?: Maybe<Scalars['bigint']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "work_order_open_grouped_by_due_date_view" */
export type IWorkOrderOpenGroupedByDueDateViewAggregate = {
  __typename: 'work_order_open_grouped_by_due_date_view_aggregate';
  aggregate?: Maybe<IWorkOrderOpenGroupedByDueDateViewAggregateFields>;
  nodes: Array<IWorkOrderOpenGroupedByDueDateView>;
};

/** aggregate fields of "work_order_open_grouped_by_due_date_view" */
export type IWorkOrderOpenGroupedByDueDateViewAggregateFields = {
  __typename: 'work_order_open_grouped_by_due_date_view_aggregate_fields';
  avg?: Maybe<IWorkOrderOpenGroupedByDueDateViewAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IWorkOrderOpenGroupedByDueDateViewMaxFields>;
  min?: Maybe<IWorkOrderOpenGroupedByDueDateViewMinFields>;
  stddev?: Maybe<IWorkOrderOpenGroupedByDueDateViewStddevFields>;
  stddev_pop?: Maybe<IWorkOrderOpenGroupedByDueDateViewStddevPopFields>;
  stddev_samp?: Maybe<IWorkOrderOpenGroupedByDueDateViewStddevSampFields>;
  sum?: Maybe<IWorkOrderOpenGroupedByDueDateViewSumFields>;
  var_pop?: Maybe<IWorkOrderOpenGroupedByDueDateViewVarPopFields>;
  var_samp?: Maybe<IWorkOrderOpenGroupedByDueDateViewVarSampFields>;
  variance?: Maybe<IWorkOrderOpenGroupedByDueDateViewVarianceFields>;
};


/** aggregate fields of "work_order_open_grouped_by_due_date_view" */
export type IWorkOrderOpenGroupedByDueDateViewAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IWorkOrderOpenGroupedByDueDateViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type IWorkOrderOpenGroupedByDueDateViewAvgFields = {
  __typename: 'work_order_open_grouped_by_due_date_view_avg_fields';
  count?: Maybe<Scalars['Float']['output']>;
  not_recurring_tasks?: Maybe<Scalars['Float']['output']>;
  recurring_tasks?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "work_order_open_grouped_by_due_date_view". All fields are combined with a logical 'AND'. */
export type IWorkOrderOpenGroupedByDueDateViewBoolExp = {
  _and?: InputMaybe<Array<IWorkOrderOpenGroupedByDueDateViewBoolExp>>;
  _not?: InputMaybe<IWorkOrderOpenGroupedByDueDateViewBoolExp>;
  _or?: InputMaybe<Array<IWorkOrderOpenGroupedByDueDateViewBoolExp>>;
  count?: InputMaybe<IBigintComparisonExp>;
  due_date?: InputMaybe<IDateComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  not_recurring_tasks?: InputMaybe<IBigintComparisonExp>;
  recurring_tasks?: InputMaybe<IBigintComparisonExp>;
  team_id?: InputMaybe<IUuidComparisonExp>;
};

/** aggregate max on columns */
export type IWorkOrderOpenGroupedByDueDateViewMaxFields = {
  __typename: 'work_order_open_grouped_by_due_date_view_max_fields';
  count?: Maybe<Scalars['bigint']['output']>;
  due_date?: Maybe<Scalars['date']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  not_recurring_tasks?: Maybe<Scalars['bigint']['output']>;
  recurring_tasks?: Maybe<Scalars['bigint']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type IWorkOrderOpenGroupedByDueDateViewMinFields = {
  __typename: 'work_order_open_grouped_by_due_date_view_min_fields';
  count?: Maybe<Scalars['bigint']['output']>;
  due_date?: Maybe<Scalars['date']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  not_recurring_tasks?: Maybe<Scalars['bigint']['output']>;
  recurring_tasks?: Maybe<Scalars['bigint']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
};

/** Ordering options when selecting data from "work_order_open_grouped_by_due_date_view". */
export type IWorkOrderOpenGroupedByDueDateViewOrderBy = {
  count?: InputMaybe<IOrderBy>;
  due_date?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  not_recurring_tasks?: InputMaybe<IOrderBy>;
  recurring_tasks?: InputMaybe<IOrderBy>;
  team_id?: InputMaybe<IOrderBy>;
};

/** select columns of table "work_order_open_grouped_by_due_date_view" */
export enum IWorkOrderOpenGroupedByDueDateViewSelectColumn {
  /** column name */
  Count = 'count',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  NotRecurringTasks = 'not_recurring_tasks',
  /** column name */
  RecurringTasks = 'recurring_tasks',
  /** column name */
  TeamId = 'team_id'
}

/** aggregate stddev on columns */
export type IWorkOrderOpenGroupedByDueDateViewStddevFields = {
  __typename: 'work_order_open_grouped_by_due_date_view_stddev_fields';
  count?: Maybe<Scalars['Float']['output']>;
  not_recurring_tasks?: Maybe<Scalars['Float']['output']>;
  recurring_tasks?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type IWorkOrderOpenGroupedByDueDateViewStddevPopFields = {
  __typename: 'work_order_open_grouped_by_due_date_view_stddev_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
  not_recurring_tasks?: Maybe<Scalars['Float']['output']>;
  recurring_tasks?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type IWorkOrderOpenGroupedByDueDateViewStddevSampFields = {
  __typename: 'work_order_open_grouped_by_due_date_view_stddev_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
  not_recurring_tasks?: Maybe<Scalars['Float']['output']>;
  recurring_tasks?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "work_order_open_grouped_by_due_date_view" */
export type IWorkOrderOpenGroupedByDueDateViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IWorkOrderOpenGroupedByDueDateViewStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IWorkOrderOpenGroupedByDueDateViewStreamCursorValueInput = {
  count?: InputMaybe<Scalars['bigint']['input']>;
  due_date?: InputMaybe<Scalars['date']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  not_recurring_tasks?: InputMaybe<Scalars['bigint']['input']>;
  recurring_tasks?: InputMaybe<Scalars['bigint']['input']>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type IWorkOrderOpenGroupedByDueDateViewSumFields = {
  __typename: 'work_order_open_grouped_by_due_date_view_sum_fields';
  count?: Maybe<Scalars['bigint']['output']>;
  not_recurring_tasks?: Maybe<Scalars['bigint']['output']>;
  recurring_tasks?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type IWorkOrderOpenGroupedByDueDateViewVarPopFields = {
  __typename: 'work_order_open_grouped_by_due_date_view_var_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
  not_recurring_tasks?: Maybe<Scalars['Float']['output']>;
  recurring_tasks?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type IWorkOrderOpenGroupedByDueDateViewVarSampFields = {
  __typename: 'work_order_open_grouped_by_due_date_view_var_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
  not_recurring_tasks?: Maybe<Scalars['Float']['output']>;
  recurring_tasks?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type IWorkOrderOpenGroupedByDueDateViewVarianceFields = {
  __typename: 'work_order_open_grouped_by_due_date_view_variance_fields';
  count?: Maybe<Scalars['Float']['output']>;
  not_recurring_tasks?: Maybe<Scalars['Float']['output']>;
  recurring_tasks?: Maybe<Scalars['Float']['output']>;
};

/** Ordering options when selecting data from "work_order". */
export type IWorkOrderOrderBy = {
  activities_aggregate?: InputMaybe<IWorkOrderActivityAggregateOrderBy>;
  assets_aggregate?: InputMaybe<IAssetXWorkOrderAggregateOrderBy>;
  assigned_teams_aggregate?: InputMaybe<IWorkOrderXTeamAggregateOrderBy>;
  assignees_aggregate?: InputMaybe<IWorkOrderXUserAggregateOrderBy>;
  block?: InputMaybe<IBlockGroupOrderBy>;
  block_groups_aggregate?: InputMaybe<IBlockGroupAggregateOrderBy>;
  categories_aggregate?: InputMaybe<IWorkOrderXWorkOrderCategoryAggregateOrderBy>;
  category?: InputMaybe<IWorkOrderCategoryOrderBy>;
  category_id?: InputMaybe<IOrderBy>;
  child_ref?: InputMaybe<IOrderBy>;
  child_work_order?: InputMaybe<IWorkOrderOrderBy>;
  collaborators_aggregate?: InputMaybe<IWorkOrderXCollaboratorAggregateOrderBy>;
  comments_aggregate?: InputMaybe<ICommentAggregateOrderBy>;
  completed_at?: InputMaybe<IOrderBy>;
  completed_by?: InputMaybe<IUserOrderBy>;
  completed_by_id?: InputMaybe<IOrderBy>;
  consumables_used_aggregate?: InputMaybe<IConsumableLogAggregateOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by?: InputMaybe<IUserOrderBy>;
  created_by_api?: InputMaybe<IOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  custom_properties?: InputMaybe<IOrderBy>;
  days_left_until_overdue?: InputMaybe<IOrderBy>;
  deleted_at?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  documents_aggregate?: InputMaybe<IWorkOrderXUploadAggregateOrderBy>;
  due_date?: InputMaybe<IOrderBy>;
  due_time?: InputMaybe<IOrderBy>;
  expenses_aggregate?: InputMaybe<IWorkOrderExpenseAggregateOrderBy>;
  feedback?: InputMaybe<IWorkOrderFeedbackOrderBy>;
  has_access?: InputMaybe<IOrderBy>;
  has_user_work_order_approval_permission?: InputMaybe<IOrderBy>;
  has_user_work_order_data_entry_permission?: InputMaybe<IOrderBy>;
  has_user_work_order_select_permission?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  is_only_reported?: InputMaybe<IOrderBy>;
  last_update?: InputMaybe<IOrderBy>;
  location?: InputMaybe<ILocationOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  maintenance?: InputMaybe<IMaintenanceOrderBy>;
  maintenance_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  notifications_aggregate?: InputMaybe<INotificationAggregateOrderBy>;
  only_assigned?: InputMaybe<IOrderBy>;
  parent?: InputMaybe<IWorkOrderOrderBy>;
  parent_ref?: InputMaybe<IOrderBy>;
  parent_task?: InputMaybe<IWorkOrderOrderBy>;
  parent_work_order?: InputMaybe<IWorkOrderOrderBy>;
  parent_work_order_id?: InputMaybe<IOrderBy>;
  priority?: InputMaybe<IOrderBy>;
  priority_order_value?: InputMaybe<IOrderBy>;
  procedure_id?: InputMaybe<IOrderBy>;
  project?: InputMaybe<IProjectOrderBy>;
  project_id?: InputMaybe<IOrderBy>;
  public_view_expiration?: InputMaybe<IOrderBy>;
  recurrence?: InputMaybe<IRecurrenceOrderBy>;
  recurrence_id?: InputMaybe<IOrderBy>;
  recurrence_info?: InputMaybe<IOrderBy>;
  reminder_config?: InputMaybe<IOrderBy>;
  reminder_date_time?: InputMaybe<IOrderBy>;
  reports_aggregate?: InputMaybe<IWorkOrderReportAggregateOrderBy>;
  root_id?: InputMaybe<IOrderBy>;
  service_request?: InputMaybe<IServiceRequestOrderBy>;
  service_request_id?: InputMaybe<IOrderBy>;
  sort_order?: InputMaybe<IOrderBy>;
  state?: InputMaybe<IAssetXAssetStateOrderBy>;
  status?: InputMaybe<IOrderBy>;
  status_order_value?: InputMaybe<IOrderBy>;
  subtasks_aggregate?: InputMaybe<IWorkOrderAggregateOrderBy>;
  template_name?: InputMaybe<IOrderBy>;
  templated_used_id?: InputMaybe<IOrderBy>;
  total_hours_work_reports?: InputMaybe<IOrderBy>;
  type?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  work_order_number?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: work_order */
export type IWorkOrderPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IWorkOrderPrependInput = {
  custom_properties?: InputMaybe<Scalars['jsonb']['input']>;
  recurrence_info?: InputMaybe<Scalars['jsonb']['input']>;
  reminder_config?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "work_order_priority" */
export type IWorkOrderPriority = {
  __typename: 'work_order_priority';
  value: Scalars['String']['output'];
};

/** aggregated selection of "work_order_priority" */
export type IWorkOrderPriorityAggregate = {
  __typename: 'work_order_priority_aggregate';
  aggregate?: Maybe<IWorkOrderPriorityAggregateFields>;
  nodes: Array<IWorkOrderPriority>;
};

/** aggregate fields of "work_order_priority" */
export type IWorkOrderPriorityAggregateFields = {
  __typename: 'work_order_priority_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IWorkOrderPriorityMaxFields>;
  min?: Maybe<IWorkOrderPriorityMinFields>;
};


/** aggregate fields of "work_order_priority" */
export type IWorkOrderPriorityAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IWorkOrderPrioritySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "work_order_priority". All fields are combined with a logical 'AND'. */
export type IWorkOrderPriorityBoolExp = {
  _and?: InputMaybe<Array<IWorkOrderPriorityBoolExp>>;
  _not?: InputMaybe<IWorkOrderPriorityBoolExp>;
  _or?: InputMaybe<Array<IWorkOrderPriorityBoolExp>>;
  value?: InputMaybe<IStringComparisonExp>;
};

/** unique or primary key constraints on table "work_order_priority" */
export enum IWorkOrderPriorityConstraint {
  /** unique or primary key constraint on columns "value" */
  WorkOrderPriorityPkey = 'work_order_priority_pkey'
}

export enum IWorkOrderPriorityEnum {
  High = 'high',
  Low = 'low',
  Medium = 'medium'
}

/** Boolean expression to compare columns of type "work_order_priority_enum". All fields are combined with logical 'AND'. */
export type IWorkOrderPriorityEnumComparisonExp = {
  _eq?: InputMaybe<IWorkOrderPriorityEnum>;
  _in?: InputMaybe<Array<IWorkOrderPriorityEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<IWorkOrderPriorityEnum>;
  _nin?: InputMaybe<Array<IWorkOrderPriorityEnum>>;
};

/** input type for inserting data into table "work_order_priority" */
export type IWorkOrderPriorityInsertInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type IWorkOrderPriorityMaxFields = {
  __typename: 'work_order_priority_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IWorkOrderPriorityMinFields = {
  __typename: 'work_order_priority_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "work_order_priority" */
export type IWorkOrderPriorityMutationResponse = {
  __typename: 'work_order_priority_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IWorkOrderPriority>;
};

/** on_conflict condition type for table "work_order_priority" */
export type IWorkOrderPriorityOnConflict = {
  constraint: IWorkOrderPriorityConstraint;
  update_columns?: Array<IWorkOrderPriorityUpdateColumn>;
  where?: InputMaybe<IWorkOrderPriorityBoolExp>;
};

/** Ordering options when selecting data from "work_order_priority". */
export type IWorkOrderPriorityOrderBy = {
  value?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: work_order_priority */
export type IWorkOrderPriorityPkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "work_order_priority" */
export enum IWorkOrderPrioritySelectColumn {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "work_order_priority" */
export type IWorkOrderPrioritySetInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "work_order_priority" */
export type IWorkOrderPriorityStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IWorkOrderPriorityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IWorkOrderPriorityStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "work_order_priority" */
export enum IWorkOrderPriorityUpdateColumn {
  /** column name */
  Value = 'value'
}

export type IWorkOrderPriorityUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IWorkOrderPrioritySetInput>;
  /** filter the rows which have to be updated */
  where: IWorkOrderPriorityBoolExp;
};

/** columns and relationships of "work_order_recurring_view" */
export type IWorkOrderRecurringView = {
  __typename: 'work_order_recurring_view';
  /** An array relationship */
  assets: Array<IAssetXWorkOrder>;
  /** An aggregate relationship */
  assets_aggregate: IAssetXWorkOrderAggregate;
  /** An array relationship */
  assignees: Array<IWorkOrderXUser>;
  /** An aggregate relationship */
  assignees_aggregate: IWorkOrderXUserAggregate;
  /** An object relationship */
  category?: Maybe<IWorkOrderCategory>;
  category_id?: Maybe<Scalars['uuid']['output']>;
  child_ref?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  comments: Array<IComment>;
  /** An aggregate relationship */
  comments_aggregate: ICommentAggregate;
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  completed_by?: Maybe<IUser>;
  completed_by_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  created_by?: Maybe<IUser>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  depth?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  documents: Array<IWorkOrderXUpload>;
  /** An aggregate relationship */
  documents_aggregate: IWorkOrderXUploadAggregate;
  due_date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  location?: Maybe<ILocation>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent_ref?: Maybe<Scalars['uuid']['output']>;
  priority?: Maybe<Scalars['String']['output']>;
  procedure_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  recurrence?: Maybe<IRecurrence>;
  recurrence_id?: Maybe<Scalars['uuid']['output']>;
  root_id?: Maybe<Scalars['uuid']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  work_order_number?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "work_order_recurring_view" */
export type IWorkOrderRecurringViewAssetsArgs = {
  distinct_on?: InputMaybe<Array<IAssetXWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXWorkOrderOrderBy>>;
  where?: InputMaybe<IAssetXWorkOrderBoolExp>;
};


/** columns and relationships of "work_order_recurring_view" */
export type IWorkOrderRecurringViewAssetsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetXWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXWorkOrderOrderBy>>;
  where?: InputMaybe<IAssetXWorkOrderBoolExp>;
};


/** columns and relationships of "work_order_recurring_view" */
export type IWorkOrderRecurringViewAssigneesArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXUserOrderBy>>;
  where?: InputMaybe<IWorkOrderXUserBoolExp>;
};


/** columns and relationships of "work_order_recurring_view" */
export type IWorkOrderRecurringViewAssigneesAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXUserOrderBy>>;
  where?: InputMaybe<IWorkOrderXUserBoolExp>;
};


/** columns and relationships of "work_order_recurring_view" */
export type IWorkOrderRecurringViewCommentsArgs = {
  distinct_on?: InputMaybe<Array<ICommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICommentOrderBy>>;
  where?: InputMaybe<ICommentBoolExp>;
};


/** columns and relationships of "work_order_recurring_view" */
export type IWorkOrderRecurringViewCommentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ICommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICommentOrderBy>>;
  where?: InputMaybe<ICommentBoolExp>;
};


/** columns and relationships of "work_order_recurring_view" */
export type IWorkOrderRecurringViewDocumentsArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXUploadOrderBy>>;
  where?: InputMaybe<IWorkOrderXUploadBoolExp>;
};


/** columns and relationships of "work_order_recurring_view" */
export type IWorkOrderRecurringViewDocumentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXUploadOrderBy>>;
  where?: InputMaybe<IWorkOrderXUploadBoolExp>;
};

/** aggregated selection of "work_order_recurring_view" */
export type IWorkOrderRecurringViewAggregate = {
  __typename: 'work_order_recurring_view_aggregate';
  aggregate?: Maybe<IWorkOrderRecurringViewAggregateFields>;
  nodes: Array<IWorkOrderRecurringView>;
};

/** aggregate fields of "work_order_recurring_view" */
export type IWorkOrderRecurringViewAggregateFields = {
  __typename: 'work_order_recurring_view_aggregate_fields';
  avg?: Maybe<IWorkOrderRecurringViewAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IWorkOrderRecurringViewMaxFields>;
  min?: Maybe<IWorkOrderRecurringViewMinFields>;
  stddev?: Maybe<IWorkOrderRecurringViewStddevFields>;
  stddev_pop?: Maybe<IWorkOrderRecurringViewStddevPopFields>;
  stddev_samp?: Maybe<IWorkOrderRecurringViewStddevSampFields>;
  sum?: Maybe<IWorkOrderRecurringViewSumFields>;
  var_pop?: Maybe<IWorkOrderRecurringViewVarPopFields>;
  var_samp?: Maybe<IWorkOrderRecurringViewVarSampFields>;
  variance?: Maybe<IWorkOrderRecurringViewVarianceFields>;
};


/** aggregate fields of "work_order_recurring_view" */
export type IWorkOrderRecurringViewAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IWorkOrderRecurringViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type IWorkOrderRecurringViewAvgFields = {
  __typename: 'work_order_recurring_view_avg_fields';
  depth?: Maybe<Scalars['Float']['output']>;
  work_order_number?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "work_order_recurring_view". All fields are combined with a logical 'AND'. */
export type IWorkOrderRecurringViewBoolExp = {
  _and?: InputMaybe<Array<IWorkOrderRecurringViewBoolExp>>;
  _not?: InputMaybe<IWorkOrderRecurringViewBoolExp>;
  _or?: InputMaybe<Array<IWorkOrderRecurringViewBoolExp>>;
  assets?: InputMaybe<IAssetXWorkOrderBoolExp>;
  assets_aggregate?: InputMaybe<IAssetXWorkOrderAggregateBoolExp>;
  assignees?: InputMaybe<IWorkOrderXUserBoolExp>;
  assignees_aggregate?: InputMaybe<IWorkOrderXUserAggregateBoolExp>;
  category?: InputMaybe<IWorkOrderCategoryBoolExp>;
  category_id?: InputMaybe<IUuidComparisonExp>;
  child_ref?: InputMaybe<IUuidComparisonExp>;
  comments?: InputMaybe<ICommentBoolExp>;
  comments_aggregate?: InputMaybe<ICommentAggregateBoolExp>;
  completed_at?: InputMaybe<ITimestamptzComparisonExp>;
  completed_by?: InputMaybe<IUserBoolExp>;
  completed_by_id?: InputMaybe<IUuidComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  created_by?: InputMaybe<IUserBoolExp>;
  created_by_id?: InputMaybe<IUuidComparisonExp>;
  deleted_at?: InputMaybe<ITimestamptzComparisonExp>;
  depth?: InputMaybe<IIntComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  documents?: InputMaybe<IWorkOrderXUploadBoolExp>;
  documents_aggregate?: InputMaybe<IWorkOrderXUploadAggregateBoolExp>;
  due_date?: InputMaybe<IDateComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  location?: InputMaybe<ILocationBoolExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  parent_ref?: InputMaybe<IUuidComparisonExp>;
  priority?: InputMaybe<IStringComparisonExp>;
  procedure_id?: InputMaybe<IUuidComparisonExp>;
  recurrence?: InputMaybe<IRecurrenceBoolExp>;
  recurrence_id?: InputMaybe<IUuidComparisonExp>;
  root_id?: InputMaybe<IUuidComparisonExp>;
  status?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  work_order_number?: InputMaybe<IIntComparisonExp>;
};

/** aggregate max on columns */
export type IWorkOrderRecurringViewMaxFields = {
  __typename: 'work_order_recurring_view_max_fields';
  category_id?: Maybe<Scalars['uuid']['output']>;
  child_ref?: Maybe<Scalars['uuid']['output']>;
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  completed_by_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  depth?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent_ref?: Maybe<Scalars['uuid']['output']>;
  priority?: Maybe<Scalars['String']['output']>;
  procedure_id?: Maybe<Scalars['uuid']['output']>;
  recurrence_id?: Maybe<Scalars['uuid']['output']>;
  root_id?: Maybe<Scalars['uuid']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  work_order_number?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type IWorkOrderRecurringViewMinFields = {
  __typename: 'work_order_recurring_view_min_fields';
  category_id?: Maybe<Scalars['uuid']['output']>;
  child_ref?: Maybe<Scalars['uuid']['output']>;
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  completed_by_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  depth?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent_ref?: Maybe<Scalars['uuid']['output']>;
  priority?: Maybe<Scalars['String']['output']>;
  procedure_id?: Maybe<Scalars['uuid']['output']>;
  recurrence_id?: Maybe<Scalars['uuid']['output']>;
  root_id?: Maybe<Scalars['uuid']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  work_order_number?: Maybe<Scalars['Int']['output']>;
};

/** Ordering options when selecting data from "work_order_recurring_view". */
export type IWorkOrderRecurringViewOrderBy = {
  assets_aggregate?: InputMaybe<IAssetXWorkOrderAggregateOrderBy>;
  assignees_aggregate?: InputMaybe<IWorkOrderXUserAggregateOrderBy>;
  category?: InputMaybe<IWorkOrderCategoryOrderBy>;
  category_id?: InputMaybe<IOrderBy>;
  child_ref?: InputMaybe<IOrderBy>;
  comments_aggregate?: InputMaybe<ICommentAggregateOrderBy>;
  completed_at?: InputMaybe<IOrderBy>;
  completed_by?: InputMaybe<IUserOrderBy>;
  completed_by_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by?: InputMaybe<IUserOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  deleted_at?: InputMaybe<IOrderBy>;
  depth?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  documents_aggregate?: InputMaybe<IWorkOrderXUploadAggregateOrderBy>;
  due_date?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location?: InputMaybe<ILocationOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  parent_ref?: InputMaybe<IOrderBy>;
  priority?: InputMaybe<IOrderBy>;
  procedure_id?: InputMaybe<IOrderBy>;
  recurrence?: InputMaybe<IRecurrenceOrderBy>;
  recurrence_id?: InputMaybe<IOrderBy>;
  root_id?: InputMaybe<IOrderBy>;
  status?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  work_order_number?: InputMaybe<IOrderBy>;
};

/** select columns of table "work_order_recurring_view" */
export enum IWorkOrderRecurringViewSelectColumn {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  ChildRef = 'child_ref',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CompletedById = 'completed_by_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Depth = 'depth',
  /** column name */
  Description = 'description',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentRef = 'parent_ref',
  /** column name */
  Priority = 'priority',
  /** column name */
  ProcedureId = 'procedure_id',
  /** column name */
  RecurrenceId = 'recurrence_id',
  /** column name */
  RootId = 'root_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkOrderNumber = 'work_order_number'
}

/** aggregate stddev on columns */
export type IWorkOrderRecurringViewStddevFields = {
  __typename: 'work_order_recurring_view_stddev_fields';
  depth?: Maybe<Scalars['Float']['output']>;
  work_order_number?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type IWorkOrderRecurringViewStddevPopFields = {
  __typename: 'work_order_recurring_view_stddev_pop_fields';
  depth?: Maybe<Scalars['Float']['output']>;
  work_order_number?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type IWorkOrderRecurringViewStddevSampFields = {
  __typename: 'work_order_recurring_view_stddev_samp_fields';
  depth?: Maybe<Scalars['Float']['output']>;
  work_order_number?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "work_order_recurring_view" */
export type IWorkOrderRecurringViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IWorkOrderRecurringViewStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IWorkOrderRecurringViewStreamCursorValueInput = {
  category_id?: InputMaybe<Scalars['uuid']['input']>;
  child_ref?: InputMaybe<Scalars['uuid']['input']>;
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  completed_by_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  depth?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  due_date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent_ref?: InputMaybe<Scalars['uuid']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  procedure_id?: InputMaybe<Scalars['uuid']['input']>;
  recurrence_id?: InputMaybe<Scalars['uuid']['input']>;
  root_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_order_number?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type IWorkOrderRecurringViewSumFields = {
  __typename: 'work_order_recurring_view_sum_fields';
  depth?: Maybe<Scalars['Int']['output']>;
  work_order_number?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type IWorkOrderRecurringViewVarPopFields = {
  __typename: 'work_order_recurring_view_var_pop_fields';
  depth?: Maybe<Scalars['Float']['output']>;
  work_order_number?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type IWorkOrderRecurringViewVarSampFields = {
  __typename: 'work_order_recurring_view_var_samp_fields';
  depth?: Maybe<Scalars['Float']['output']>;
  work_order_number?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type IWorkOrderRecurringViewVarianceFields = {
  __typename: 'work_order_recurring_view_variance_fields';
  depth?: Maybe<Scalars['Float']['output']>;
  work_order_number?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "work_order_report" */
export type IWorkOrderReport = {
  __typename: 'work_order_report';
  created_at: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  edited_at?: Maybe<Scalars['timestamptz']['output']>;
  finished_at: Scalars['timestamptz']['output'];
  /** A computed field, executes function "hours_work_report" */
  hours?: Maybe<Scalars['numeric']['output']>;
  id: Scalars['uuid']['output'];
  started_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  submitted_by: IUser;
  submitted_by_id: Scalars['uuid']['output'];
  title?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: IUser;
  /** The user that did the work */
  user_id: Scalars['uuid']['output'];
  /** An object relationship */
  work_order: IWorkOrder;
  work_order_id: Scalars['uuid']['output'];
};

/** aggregated selection of "work_order_report" */
export type IWorkOrderReportAggregate = {
  __typename: 'work_order_report_aggregate';
  aggregate?: Maybe<IWorkOrderReportAggregateFields>;
  nodes: Array<IWorkOrderReport>;
};

export type IWorkOrderReportAggregateBoolExp = {
  count?: InputMaybe<IWorkOrderReportAggregateBoolExpCount>;
};

export type IWorkOrderReportAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IWorkOrderReportSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IWorkOrderReportBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "work_order_report" */
export type IWorkOrderReportAggregateFields = {
  __typename: 'work_order_report_aggregate_fields';
  avg?: Maybe<IWorkOrderReportAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IWorkOrderReportMaxFields>;
  min?: Maybe<IWorkOrderReportMinFields>;
  stddev?: Maybe<IWorkOrderReportStddevFields>;
  stddev_pop?: Maybe<IWorkOrderReportStddevPopFields>;
  stddev_samp?: Maybe<IWorkOrderReportStddevSampFields>;
  sum?: Maybe<IWorkOrderReportSumFields>;
  var_pop?: Maybe<IWorkOrderReportVarPopFields>;
  var_samp?: Maybe<IWorkOrderReportVarSampFields>;
  variance?: Maybe<IWorkOrderReportVarianceFields>;
};


/** aggregate fields of "work_order_report" */
export type IWorkOrderReportAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IWorkOrderReportSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "work_order_report" */
export type IWorkOrderReportAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IWorkOrderReportMaxOrderBy>;
  min?: InputMaybe<IWorkOrderReportMinOrderBy>;
};

/** input type for inserting array relation for remote table "work_order_report" */
export type IWorkOrderReportArrRelInsertInput = {
  data: Array<IWorkOrderReportInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IWorkOrderReportOnConflict>;
};

/** aggregate avg on columns */
export type IWorkOrderReportAvgFields = {
  __typename: 'work_order_report_avg_fields';
  /** A computed field, executes function "hours_work_report" */
  hours?: Maybe<Scalars['numeric']['output']>;
};

/** Boolean expression to filter rows from the table "work_order_report". All fields are combined with a logical 'AND'. */
export type IWorkOrderReportBoolExp = {
  _and?: InputMaybe<Array<IWorkOrderReportBoolExp>>;
  _not?: InputMaybe<IWorkOrderReportBoolExp>;
  _or?: InputMaybe<Array<IWorkOrderReportBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  edited_at?: InputMaybe<ITimestamptzComparisonExp>;
  finished_at?: InputMaybe<ITimestamptzComparisonExp>;
  hours?: InputMaybe<INumericComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  started_at?: InputMaybe<ITimestamptzComparisonExp>;
  submitted_by?: InputMaybe<IUserBoolExp>;
  submitted_by_id?: InputMaybe<IUuidComparisonExp>;
  title?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  user?: InputMaybe<IUserBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
  work_order?: InputMaybe<IWorkOrderBoolExp>;
  work_order_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "work_order_report" */
export enum IWorkOrderReportConstraint {
  /** unique or primary key constraint on columns "id" */
  WorkOrderReportPkey = 'work_order_report_pkey'
}

/** input type for inserting data into table "work_order_report" */
export type IWorkOrderReportInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  edited_at?: InputMaybe<Scalars['timestamptz']['input']>;
  finished_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  submitted_by?: InputMaybe<IUserObjRelInsertInput>;
  submitted_by_id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<IUserObjRelInsertInput>;
  /** The user that did the work */
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order?: InputMaybe<IWorkOrderObjRelInsertInput>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IWorkOrderReportMaxFields = {
  __typename: 'work_order_report_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  edited_at?: Maybe<Scalars['timestamptz']['output']>;
  finished_at?: Maybe<Scalars['timestamptz']['output']>;
  /** A computed field, executes function "hours_work_report" */
  hours?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  started_at?: Maybe<Scalars['timestamptz']['output']>;
  submitted_by_id?: Maybe<Scalars['uuid']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** The user that did the work */
  user_id?: Maybe<Scalars['uuid']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "work_order_report" */
export type IWorkOrderReportMaxOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  edited_at?: InputMaybe<IOrderBy>;
  finished_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  started_at?: InputMaybe<IOrderBy>;
  submitted_by_id?: InputMaybe<IOrderBy>;
  title?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  /** The user that did the work */
  user_id?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IWorkOrderReportMinFields = {
  __typename: 'work_order_report_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  edited_at?: Maybe<Scalars['timestamptz']['output']>;
  finished_at?: Maybe<Scalars['timestamptz']['output']>;
  /** A computed field, executes function "hours_work_report" */
  hours?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  started_at?: Maybe<Scalars['timestamptz']['output']>;
  submitted_by_id?: Maybe<Scalars['uuid']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** The user that did the work */
  user_id?: Maybe<Scalars['uuid']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "work_order_report" */
export type IWorkOrderReportMinOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  edited_at?: InputMaybe<IOrderBy>;
  finished_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  started_at?: InputMaybe<IOrderBy>;
  submitted_by_id?: InputMaybe<IOrderBy>;
  title?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  /** The user that did the work */
  user_id?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "work_order_report" */
export type IWorkOrderReportMutationResponse = {
  __typename: 'work_order_report_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IWorkOrderReport>;
};

/** on_conflict condition type for table "work_order_report" */
export type IWorkOrderReportOnConflict = {
  constraint: IWorkOrderReportConstraint;
  update_columns?: Array<IWorkOrderReportUpdateColumn>;
  where?: InputMaybe<IWorkOrderReportBoolExp>;
};

/** Ordering options when selecting data from "work_order_report". */
export type IWorkOrderReportOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  edited_at?: InputMaybe<IOrderBy>;
  finished_at?: InputMaybe<IOrderBy>;
  hours?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  started_at?: InputMaybe<IOrderBy>;
  submitted_by?: InputMaybe<IUserOrderBy>;
  submitted_by_id?: InputMaybe<IOrderBy>;
  title?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUserOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  work_order?: InputMaybe<IWorkOrderOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: work_order_report */
export type IWorkOrderReportPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "work_order_report" */
export enum IWorkOrderReportSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EditedAt = 'edited_at',
  /** column name */
  FinishedAt = 'finished_at',
  /** column name */
  Id = 'id',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  SubmittedById = 'submitted_by_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WorkOrderId = 'work_order_id'
}

/** input type for updating data in table "work_order_report" */
export type IWorkOrderReportSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  edited_at?: InputMaybe<Scalars['timestamptz']['input']>;
  finished_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  submitted_by_id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  /** The user that did the work */
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type IWorkOrderReportStddevFields = {
  __typename: 'work_order_report_stddev_fields';
  /** A computed field, executes function "hours_work_report" */
  hours?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate stddev_pop on columns */
export type IWorkOrderReportStddevPopFields = {
  __typename: 'work_order_report_stddev_pop_fields';
  /** A computed field, executes function "hours_work_report" */
  hours?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate stddev_samp on columns */
export type IWorkOrderReportStddevSampFields = {
  __typename: 'work_order_report_stddev_samp_fields';
  /** A computed field, executes function "hours_work_report" */
  hours?: Maybe<Scalars['numeric']['output']>;
};

/** Streaming cursor of the table "work_order_report" */
export type IWorkOrderReportStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IWorkOrderReportStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IWorkOrderReportStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  edited_at?: InputMaybe<Scalars['timestamptz']['input']>;
  finished_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  submitted_by_id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  /** The user that did the work */
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type IWorkOrderReportSumFields = {
  __typename: 'work_order_report_sum_fields';
  /** A computed field, executes function "hours_work_report" */
  hours?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "work_order_report" */
export enum IWorkOrderReportUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EditedAt = 'edited_at',
  /** column name */
  FinishedAt = 'finished_at',
  /** column name */
  Id = 'id',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  SubmittedById = 'submitted_by_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WorkOrderId = 'work_order_id'
}

export type IWorkOrderReportUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IWorkOrderReportSetInput>;
  /** filter the rows which have to be updated */
  where: IWorkOrderReportBoolExp;
};

/** aggregate var_pop on columns */
export type IWorkOrderReportVarPopFields = {
  __typename: 'work_order_report_var_pop_fields';
  /** A computed field, executes function "hours_work_report" */
  hours?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate var_samp on columns */
export type IWorkOrderReportVarSampFields = {
  __typename: 'work_order_report_var_samp_fields';
  /** A computed field, executes function "hours_work_report" */
  hours?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate variance on columns */
export type IWorkOrderReportVarianceFields = {
  __typename: 'work_order_report_variance_fields';
  /** A computed field, executes function "hours_work_report" */
  hours?: Maybe<Scalars['numeric']['output']>;
};

/** select columns of table "work_order" */
export enum IWorkOrderSelectColumn {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  ChildRef = 'child_ref',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CompletedById = 'completed_by_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByApi = 'created_by_api',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  CustomProperties = 'custom_properties',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  DueTime = 'due_time',
  /** column name */
  Id = 'id',
  /** column name */
  IsOnlyReported = 'is_only_reported',
  /** column name */
  LastUpdate = 'last_update',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  MaintenanceId = 'maintenance_id',
  /** column name */
  Name = 'name',
  /** column name */
  OnlyAssigned = 'only_assigned',
  /** column name */
  ParentRef = 'parent_ref',
  /** column name */
  ParentWorkOrderId = 'parent_work_order_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  ProcedureId = 'procedure_id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  PublicViewExpiration = 'public_view_expiration',
  /** column name */
  RecurrenceId = 'recurrence_id',
  /** column name */
  RecurrenceInfo = 'recurrence_info',
  /** column name */
  ReminderConfig = 'reminder_config',
  /** column name */
  ReminderDateTime = 'reminder_date_time',
  /** column name */
  ServiceRequestId = 'service_request_id',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  Status = 'status',
  /** column name */
  TemplateName = 'template_name',
  /** column name */
  TemplatedUsedId = 'templated_used_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkOrderNumber = 'work_order_number'
}

/** select "work_order_aggregate_bool_exp_bool_and_arguments_columns" columns of table "work_order" */
export enum IWorkOrderSelectColumnWorkOrderAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  CreatedByApi = 'created_by_api',
  /** column name */
  IsOnlyReported = 'is_only_reported',
  /** column name */
  OnlyAssigned = 'only_assigned'
}

/** select "work_order_aggregate_bool_exp_bool_or_arguments_columns" columns of table "work_order" */
export enum IWorkOrderSelectColumnWorkOrderAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  CreatedByApi = 'created_by_api',
  /** column name */
  IsOnlyReported = 'is_only_reported',
  /** column name */
  OnlyAssigned = 'only_assigned'
}

/** input type for updating data in table "work_order" */
export type IWorkOrderSetInput = {
  category_id?: InputMaybe<Scalars['uuid']['input']>;
  child_ref?: InputMaybe<Scalars['uuid']['input']>;
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  completed_by_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_api?: InputMaybe<Scalars['Boolean']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  custom_properties?: InputMaybe<Scalars['jsonb']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  due_date?: InputMaybe<Scalars['date']['input']>;
  due_time?: InputMaybe<Scalars['time']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  /** Should be removed in the near future */
  is_only_reported?: InputMaybe<Scalars['Boolean']['input']>;
  last_update?: InputMaybe<Scalars['timestamptz']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  maintenance_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  only_assigned?: InputMaybe<Scalars['Boolean']['input']>;
  parent_ref?: InputMaybe<Scalars['uuid']['input']>;
  parent_work_order_id?: InputMaybe<Scalars['uuid']['input']>;
  priority?: InputMaybe<IWorkOrderPriorityEnum>;
  procedure_id?: InputMaybe<Scalars['uuid']['input']>;
  project_id?: InputMaybe<Scalars['uuid']['input']>;
  public_view_expiration?: InputMaybe<Scalars['timestamptz']['input']>;
  recurrence_id?: InputMaybe<Scalars['uuid']['input']>;
  recurrence_info?: InputMaybe<Scalars['jsonb']['input']>;
  reminder_config?: InputMaybe<Scalars['jsonb']['input']>;
  reminder_date_time?: InputMaybe<Scalars['timestamptz']['input']>;
  service_request_id?: InputMaybe<Scalars['Int']['input']>;
  sort_order?: InputMaybe<Scalars['numeric']['input']>;
  status?: InputMaybe<IWorkOrderStatusEnum>;
  template_name?: InputMaybe<Scalars['String']['input']>;
  templated_used_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<IWorkOrderTypeEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_order_number?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "work_order_status" */
export type IWorkOrderStatus = {
  __typename: 'work_order_status';
  value: Scalars['String']['output'];
  /** An array relationship */
  work_orders: Array<IWorkOrder>;
  /** An aggregate relationship */
  work_orders_aggregate: IWorkOrderAggregate;
};


/** columns and relationships of "work_order_status" */
export type IWorkOrderStatusWorkOrdersArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOrderBy>>;
  where?: InputMaybe<IWorkOrderBoolExp>;
};


/** columns and relationships of "work_order_status" */
export type IWorkOrderStatusWorkOrdersAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderOrderBy>>;
  where?: InputMaybe<IWorkOrderBoolExp>;
};

/** aggregated selection of "work_order_status" */
export type IWorkOrderStatusAggregate = {
  __typename: 'work_order_status_aggregate';
  aggregate?: Maybe<IWorkOrderStatusAggregateFields>;
  nodes: Array<IWorkOrderStatus>;
};

/** aggregate fields of "work_order_status" */
export type IWorkOrderStatusAggregateFields = {
  __typename: 'work_order_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IWorkOrderStatusMaxFields>;
  min?: Maybe<IWorkOrderStatusMinFields>;
};


/** aggregate fields of "work_order_status" */
export type IWorkOrderStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IWorkOrderStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "work_order_status". All fields are combined with a logical 'AND'. */
export type IWorkOrderStatusBoolExp = {
  _and?: InputMaybe<Array<IWorkOrderStatusBoolExp>>;
  _not?: InputMaybe<IWorkOrderStatusBoolExp>;
  _or?: InputMaybe<Array<IWorkOrderStatusBoolExp>>;
  value?: InputMaybe<IStringComparisonExp>;
  work_orders?: InputMaybe<IWorkOrderBoolExp>;
  work_orders_aggregate?: InputMaybe<IWorkOrderAggregateBoolExp>;
};

/** unique or primary key constraints on table "work_order_status" */
export enum IWorkOrderStatusConstraint {
  /** unique or primary key constraint on columns "value" */
  WorkOrderStatusPkey = 'work_order_status_pkey'
}

export enum IWorkOrderStatusEnum {
  Canceled = 'canceled',
  Done = 'done',
  InProgress = 'in_progress',
  Inbox = 'inbox',
  OnHold = 'on_hold',
  Open = 'open',
  Planned = 'planned',
  ReadyForApproval = 'ready_for_approval'
}

/** Boolean expression to compare columns of type "work_order_status_enum". All fields are combined with logical 'AND'. */
export type IWorkOrderStatusEnumComparisonExp = {
  _eq?: InputMaybe<IWorkOrderStatusEnum>;
  _in?: InputMaybe<Array<IWorkOrderStatusEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<IWorkOrderStatusEnum>;
  _nin?: InputMaybe<Array<IWorkOrderStatusEnum>>;
};

/** input type for inserting data into table "work_order_status" */
export type IWorkOrderStatusInsertInput = {
  value?: InputMaybe<Scalars['String']['input']>;
  work_orders?: InputMaybe<IWorkOrderArrRelInsertInput>;
};

/** aggregate max on columns */
export type IWorkOrderStatusMaxFields = {
  __typename: 'work_order_status_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IWorkOrderStatusMinFields = {
  __typename: 'work_order_status_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "work_order_status" */
export type IWorkOrderStatusMutationResponse = {
  __typename: 'work_order_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IWorkOrderStatus>;
};

/** on_conflict condition type for table "work_order_status" */
export type IWorkOrderStatusOnConflict = {
  constraint: IWorkOrderStatusConstraint;
  update_columns?: Array<IWorkOrderStatusUpdateColumn>;
  where?: InputMaybe<IWorkOrderStatusBoolExp>;
};

/** Ordering options when selecting data from "work_order_status". */
export type IWorkOrderStatusOrderBy = {
  value?: InputMaybe<IOrderBy>;
  work_orders_aggregate?: InputMaybe<IWorkOrderAggregateOrderBy>;
};

/** primary key columns input for table: work_order_status */
export type IWorkOrderStatusPkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "work_order_status" */
export enum IWorkOrderStatusSelectColumn {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "work_order_status" */
export type IWorkOrderStatusSetInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "work_order_status" */
export type IWorkOrderStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IWorkOrderStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IWorkOrderStatusStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "work_order_status" */
export enum IWorkOrderStatusUpdateColumn {
  /** column name */
  Value = 'value'
}

export type IWorkOrderStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IWorkOrderStatusSetInput>;
  /** filter the rows which have to be updated */
  where: IWorkOrderStatusBoolExp;
};

/** aggregate stddev on columns */
export type IWorkOrderStddevFields = {
  __typename: 'work_order_stddev_fields';
  /** A computed field, executes function "work_order_days_left_until_overdue" */
  days_left_until_overdue?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "computed_work_order_priority_order_value" */
  priority_order_value?: Maybe<Scalars['Int']['output']>;
  service_request_id?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "computed_work_order_status_order_value" */
  status_order_value?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "total_hours_work_reports" */
  total_hours_work_reports?: Maybe<Scalars['numeric']['output']>;
  work_order_number?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "work_order" */
export type IWorkOrderStddevOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
  sort_order?: InputMaybe<IOrderBy>;
  work_order_number?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_pop on columns */
export type IWorkOrderStddevPopFields = {
  __typename: 'work_order_stddev_pop_fields';
  /** A computed field, executes function "work_order_days_left_until_overdue" */
  days_left_until_overdue?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "computed_work_order_priority_order_value" */
  priority_order_value?: Maybe<Scalars['Int']['output']>;
  service_request_id?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "computed_work_order_status_order_value" */
  status_order_value?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "total_hours_work_reports" */
  total_hours_work_reports?: Maybe<Scalars['numeric']['output']>;
  work_order_number?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "work_order" */
export type IWorkOrderStddevPopOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
  sort_order?: InputMaybe<IOrderBy>;
  work_order_number?: InputMaybe<IOrderBy>;
};

/** aggregate stddev_samp on columns */
export type IWorkOrderStddevSampFields = {
  __typename: 'work_order_stddev_samp_fields';
  /** A computed field, executes function "work_order_days_left_until_overdue" */
  days_left_until_overdue?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "computed_work_order_priority_order_value" */
  priority_order_value?: Maybe<Scalars['Int']['output']>;
  service_request_id?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "computed_work_order_status_order_value" */
  status_order_value?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "total_hours_work_reports" */
  total_hours_work_reports?: Maybe<Scalars['numeric']['output']>;
  work_order_number?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "work_order" */
export type IWorkOrderStddevSampOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
  sort_order?: InputMaybe<IOrderBy>;
  work_order_number?: InputMaybe<IOrderBy>;
};

/** Streaming cursor of the table "work_order" */
export type IWorkOrderStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IWorkOrderStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IWorkOrderStreamCursorValueInput = {
  category_id?: InputMaybe<Scalars['uuid']['input']>;
  child_ref?: InputMaybe<Scalars['uuid']['input']>;
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  completed_by_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_api?: InputMaybe<Scalars['Boolean']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  custom_properties?: InputMaybe<Scalars['jsonb']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  due_date?: InputMaybe<Scalars['date']['input']>;
  due_time?: InputMaybe<Scalars['time']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  /** Should be removed in the near future */
  is_only_reported?: InputMaybe<Scalars['Boolean']['input']>;
  last_update?: InputMaybe<Scalars['timestamptz']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  maintenance_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  only_assigned?: InputMaybe<Scalars['Boolean']['input']>;
  parent_ref?: InputMaybe<Scalars['uuid']['input']>;
  parent_work_order_id?: InputMaybe<Scalars['uuid']['input']>;
  priority?: InputMaybe<IWorkOrderPriorityEnum>;
  procedure_id?: InputMaybe<Scalars['uuid']['input']>;
  project_id?: InputMaybe<Scalars['uuid']['input']>;
  public_view_expiration?: InputMaybe<Scalars['timestamptz']['input']>;
  recurrence_id?: InputMaybe<Scalars['uuid']['input']>;
  recurrence_info?: InputMaybe<Scalars['jsonb']['input']>;
  reminder_config?: InputMaybe<Scalars['jsonb']['input']>;
  reminder_date_time?: InputMaybe<Scalars['timestamptz']['input']>;
  service_request_id?: InputMaybe<Scalars['Int']['input']>;
  sort_order?: InputMaybe<Scalars['numeric']['input']>;
  status?: InputMaybe<IWorkOrderStatusEnum>;
  template_name?: InputMaybe<Scalars['String']['input']>;
  templated_used_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<IWorkOrderTypeEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_order_number?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type IWorkOrderSumFields = {
  __typename: 'work_order_sum_fields';
  /** A computed field, executes function "work_order_days_left_until_overdue" */
  days_left_until_overdue?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "computed_work_order_priority_order_value" */
  priority_order_value?: Maybe<Scalars['Int']['output']>;
  service_request_id?: Maybe<Scalars['Int']['output']>;
  sort_order?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "computed_work_order_status_order_value" */
  status_order_value?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "total_hours_work_reports" */
  total_hours_work_reports?: Maybe<Scalars['numeric']['output']>;
  work_order_number?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "work_order" */
export type IWorkOrderSumOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
  sort_order?: InputMaybe<IOrderBy>;
  work_order_number?: InputMaybe<IOrderBy>;
};

/** Metadata for work order templates */
export type IWorkOrderTemplate = {
  __typename: 'work_order_template';
  allow_modification_in_task_form: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  location_id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  work_order: IWorkOrder;
  work_order_id: Scalars['uuid']['output'];
};

/** aggregated selection of "work_order_template" */
export type IWorkOrderTemplateAggregate = {
  __typename: 'work_order_template_aggregate';
  aggregate?: Maybe<IWorkOrderTemplateAggregateFields>;
  nodes: Array<IWorkOrderTemplate>;
};

/** aggregate fields of "work_order_template" */
export type IWorkOrderTemplateAggregateFields = {
  __typename: 'work_order_template_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IWorkOrderTemplateMaxFields>;
  min?: Maybe<IWorkOrderTemplateMinFields>;
};


/** aggregate fields of "work_order_template" */
export type IWorkOrderTemplateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IWorkOrderTemplateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "work_order_template". All fields are combined with a logical 'AND'. */
export type IWorkOrderTemplateBoolExp = {
  _and?: InputMaybe<Array<IWorkOrderTemplateBoolExp>>;
  _not?: InputMaybe<IWorkOrderTemplateBoolExp>;
  _or?: InputMaybe<Array<IWorkOrderTemplateBoolExp>>;
  allow_modification_in_task_form?: InputMaybe<IBooleanComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  work_order?: InputMaybe<IWorkOrderBoolExp>;
  work_order_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "work_order_template" */
export enum IWorkOrderTemplateConstraint {
  /** unique or primary key constraint on columns "id" */
  WorkOrderTemplatePkey = 'work_order_template_pkey'
}

/** input type for inserting data into table "work_order_template" */
export type IWorkOrderTemplateInsertInput = {
  allow_modification_in_task_form?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  work_order?: InputMaybe<IWorkOrderObjRelInsertInput>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IWorkOrderTemplateMaxFields = {
  __typename: 'work_order_template_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type IWorkOrderTemplateMinFields = {
  __typename: 'work_order_template_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "work_order_template" */
export type IWorkOrderTemplateMutationResponse = {
  __typename: 'work_order_template_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IWorkOrderTemplate>;
};

/** input type for inserting object relation for remote table "work_order_template" */
export type IWorkOrderTemplateObjRelInsertInput = {
  data: IWorkOrderTemplateInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IWorkOrderTemplateOnConflict>;
};

/** on_conflict condition type for table "work_order_template" */
export type IWorkOrderTemplateOnConflict = {
  constraint: IWorkOrderTemplateConstraint;
  update_columns?: Array<IWorkOrderTemplateUpdateColumn>;
  where?: InputMaybe<IWorkOrderTemplateBoolExp>;
};

/** Ordering options when selecting data from "work_order_template". */
export type IWorkOrderTemplateOrderBy = {
  allow_modification_in_task_form?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  work_order?: InputMaybe<IWorkOrderOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: work_order_template */
export type IWorkOrderTemplatePkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "work_order_template" */
export enum IWorkOrderTemplateSelectColumn {
  /** column name */
  AllowModificationInTaskForm = 'allow_modification_in_task_form',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  WorkOrderId = 'work_order_id'
}

/** input type for updating data in table "work_order_template" */
export type IWorkOrderTemplateSetInput = {
  allow_modification_in_task_form?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "work_order_template" */
export type IWorkOrderTemplateStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IWorkOrderTemplateStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IWorkOrderTemplateStreamCursorValueInput = {
  allow_modification_in_task_form?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "work_order_template" */
export enum IWorkOrderTemplateUpdateColumn {
  /** column name */
  AllowModificationInTaskForm = 'allow_modification_in_task_form',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  WorkOrderId = 'work_order_id'
}

export type IWorkOrderTemplateUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IWorkOrderTemplateSetInput>;
  /** filter the rows which have to be updated */
  where: IWorkOrderTemplateBoolExp;
};

/** columns and relationships of "work_order_type" */
export type IWorkOrderType = {
  __typename: 'work_order_type';
  description: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "work_order_type" */
export type IWorkOrderTypeAggregate = {
  __typename: 'work_order_type_aggregate';
  aggregate?: Maybe<IWorkOrderTypeAggregateFields>;
  nodes: Array<IWorkOrderType>;
};

/** aggregate fields of "work_order_type" */
export type IWorkOrderTypeAggregateFields = {
  __typename: 'work_order_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IWorkOrderTypeMaxFields>;
  min?: Maybe<IWorkOrderTypeMinFields>;
};


/** aggregate fields of "work_order_type" */
export type IWorkOrderTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IWorkOrderTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "work_order_type". All fields are combined with a logical 'AND'. */
export type IWorkOrderTypeBoolExp = {
  _and?: InputMaybe<Array<IWorkOrderTypeBoolExp>>;
  _not?: InputMaybe<IWorkOrderTypeBoolExp>;
  _or?: InputMaybe<Array<IWorkOrderTypeBoolExp>>;
  description?: InputMaybe<IStringComparisonExp>;
  value?: InputMaybe<IStringComparisonExp>;
};

/** unique or primary key constraints on table "work_order_type" */
export enum IWorkOrderTypeConstraint {
  /** unique or primary key constraint on columns "value" */
  WorkOrderTypePkey = 'work_order_type_pkey'
}

export enum IWorkOrderTypeEnum {
  /** Unplanned work which has been reported */
  ReportWork = 'report_work',
  /** Change of state of an asset */
  State = 'state',
  /** Template for create similar work orders */
  Template = 'template',
  /** Planned work which needs to be executed */
  WorkOrder = 'work_order'
}

/** Boolean expression to compare columns of type "work_order_type_enum". All fields are combined with logical 'AND'. */
export type IWorkOrderTypeEnumComparisonExp = {
  _eq?: InputMaybe<IWorkOrderTypeEnum>;
  _in?: InputMaybe<Array<IWorkOrderTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<IWorkOrderTypeEnum>;
  _nin?: InputMaybe<Array<IWorkOrderTypeEnum>>;
};

/** input type for inserting data into table "work_order_type" */
export type IWorkOrderTypeInsertInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type IWorkOrderTypeMaxFields = {
  __typename: 'work_order_type_max_fields';
  description?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IWorkOrderTypeMinFields = {
  __typename: 'work_order_type_min_fields';
  description?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "work_order_type" */
export type IWorkOrderTypeMutationResponse = {
  __typename: 'work_order_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IWorkOrderType>;
};

/** on_conflict condition type for table "work_order_type" */
export type IWorkOrderTypeOnConflict = {
  constraint: IWorkOrderTypeConstraint;
  update_columns?: Array<IWorkOrderTypeUpdateColumn>;
  where?: InputMaybe<IWorkOrderTypeBoolExp>;
};

/** Ordering options when selecting data from "work_order_type". */
export type IWorkOrderTypeOrderBy = {
  description?: InputMaybe<IOrderBy>;
  value?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: work_order_type */
export type IWorkOrderTypePkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "work_order_type" */
export enum IWorkOrderTypeSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "work_order_type" */
export type IWorkOrderTypeSetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "work_order_type" */
export type IWorkOrderTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IWorkOrderTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IWorkOrderTypeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "work_order_type" */
export enum IWorkOrderTypeUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type IWorkOrderTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IWorkOrderTypeSetInput>;
  /** filter the rows which have to be updated */
  where: IWorkOrderTypeBoolExp;
};

/** update columns of table "work_order" */
export enum IWorkOrderUpdateColumn {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  ChildRef = 'child_ref',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CompletedById = 'completed_by_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByApi = 'created_by_api',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  CustomProperties = 'custom_properties',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  DueTime = 'due_time',
  /** column name */
  Id = 'id',
  /** column name */
  IsOnlyReported = 'is_only_reported',
  /** column name */
  LastUpdate = 'last_update',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  MaintenanceId = 'maintenance_id',
  /** column name */
  Name = 'name',
  /** column name */
  OnlyAssigned = 'only_assigned',
  /** column name */
  ParentRef = 'parent_ref',
  /** column name */
  ParentWorkOrderId = 'parent_work_order_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  ProcedureId = 'procedure_id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  PublicViewExpiration = 'public_view_expiration',
  /** column name */
  RecurrenceId = 'recurrence_id',
  /** column name */
  RecurrenceInfo = 'recurrence_info',
  /** column name */
  ReminderConfig = 'reminder_config',
  /** column name */
  ReminderDateTime = 'reminder_date_time',
  /** column name */
  ServiceRequestId = 'service_request_id',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  Status = 'status',
  /** column name */
  TemplateName = 'template_name',
  /** column name */
  TemplatedUsedId = 'templated_used_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkOrderNumber = 'work_order_number'
}

export type IWorkOrderUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IWorkOrderAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IWorkOrderDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IWorkOrderDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IWorkOrderDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IWorkOrderIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IWorkOrderPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IWorkOrderSetInput>;
  /** filter the rows which have to be updated */
  where: IWorkOrderBoolExp;
};

/** aggregate var_pop on columns */
export type IWorkOrderVarPopFields = {
  __typename: 'work_order_var_pop_fields';
  /** A computed field, executes function "work_order_days_left_until_overdue" */
  days_left_until_overdue?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "computed_work_order_priority_order_value" */
  priority_order_value?: Maybe<Scalars['Int']['output']>;
  service_request_id?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "computed_work_order_status_order_value" */
  status_order_value?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "total_hours_work_reports" */
  total_hours_work_reports?: Maybe<Scalars['numeric']['output']>;
  work_order_number?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "work_order" */
export type IWorkOrderVarPopOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
  sort_order?: InputMaybe<IOrderBy>;
  work_order_number?: InputMaybe<IOrderBy>;
};

/** aggregate var_samp on columns */
export type IWorkOrderVarSampFields = {
  __typename: 'work_order_var_samp_fields';
  /** A computed field, executes function "work_order_days_left_until_overdue" */
  days_left_until_overdue?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "computed_work_order_priority_order_value" */
  priority_order_value?: Maybe<Scalars['Int']['output']>;
  service_request_id?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "computed_work_order_status_order_value" */
  status_order_value?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "total_hours_work_reports" */
  total_hours_work_reports?: Maybe<Scalars['numeric']['output']>;
  work_order_number?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "work_order" */
export type IWorkOrderVarSampOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
  sort_order?: InputMaybe<IOrderBy>;
  work_order_number?: InputMaybe<IOrderBy>;
};

/** aggregate variance on columns */
export type IWorkOrderVarianceFields = {
  __typename: 'work_order_variance_fields';
  /** A computed field, executes function "work_order_days_left_until_overdue" */
  days_left_until_overdue?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "computed_work_order_priority_order_value" */
  priority_order_value?: Maybe<Scalars['Int']['output']>;
  service_request_id?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "computed_work_order_status_order_value" */
  status_order_value?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "total_hours_work_reports" */
  total_hours_work_reports?: Maybe<Scalars['numeric']['output']>;
  work_order_number?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "work_order" */
export type IWorkOrderVarianceOrderBy = {
  service_request_id?: InputMaybe<IOrderBy>;
  sort_order?: InputMaybe<IOrderBy>;
  work_order_number?: InputMaybe<IOrderBy>;
};

/** columns and relationships of "work_order_with_last_recurring_instance_view" */
export type IWorkOrderWithLastRecurringInstanceView = {
  __typename: 'work_order_with_last_recurring_instance_view';
  /** An array relationship */
  assets: Array<IAssetXWorkOrder>;
  /** An aggregate relationship */
  assets_aggregate: IAssetXWorkOrderAggregate;
  /** An array relationship */
  assignees: Array<IWorkOrderXUser>;
  /** An aggregate relationship */
  assignees_aggregate: IWorkOrderXUserAggregate;
  /** An object relationship */
  category?: Maybe<IWorkOrderCategory>;
  category_id?: Maybe<Scalars['uuid']['output']>;
  child_ref?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  comments: Array<IComment>;
  /** An aggregate relationship */
  comments_aggregate: ICommentAggregate;
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  completed_by?: Maybe<IUser>;
  completed_by_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  created_by?: Maybe<IUser>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  depth?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  documents: Array<IWorkOrderXUpload>;
  /** An aggregate relationship */
  documents_aggregate: IWorkOrderXUploadAggregate;
  due_date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  location?: Maybe<ILocation>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent_ref?: Maybe<Scalars['uuid']['output']>;
  priority?: Maybe<Scalars['String']['output']>;
  procedure_id?: Maybe<Scalars['uuid']['output']>;
  recurrence_id?: Maybe<Scalars['uuid']['output']>;
  root_id?: Maybe<Scalars['uuid']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  work_order_number?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "work_order_with_last_recurring_instance_view" */
export type IWorkOrderWithLastRecurringInstanceViewAssetsArgs = {
  distinct_on?: InputMaybe<Array<IAssetXWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXWorkOrderOrderBy>>;
  where?: InputMaybe<IAssetXWorkOrderBoolExp>;
};


/** columns and relationships of "work_order_with_last_recurring_instance_view" */
export type IWorkOrderWithLastRecurringInstanceViewAssetsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IAssetXWorkOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAssetXWorkOrderOrderBy>>;
  where?: InputMaybe<IAssetXWorkOrderBoolExp>;
};


/** columns and relationships of "work_order_with_last_recurring_instance_view" */
export type IWorkOrderWithLastRecurringInstanceViewAssigneesArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXUserOrderBy>>;
  where?: InputMaybe<IWorkOrderXUserBoolExp>;
};


/** columns and relationships of "work_order_with_last_recurring_instance_view" */
export type IWorkOrderWithLastRecurringInstanceViewAssigneesAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXUserOrderBy>>;
  where?: InputMaybe<IWorkOrderXUserBoolExp>;
};


/** columns and relationships of "work_order_with_last_recurring_instance_view" */
export type IWorkOrderWithLastRecurringInstanceViewCommentsArgs = {
  distinct_on?: InputMaybe<Array<ICommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICommentOrderBy>>;
  where?: InputMaybe<ICommentBoolExp>;
};


/** columns and relationships of "work_order_with_last_recurring_instance_view" */
export type IWorkOrderWithLastRecurringInstanceViewCommentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ICommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICommentOrderBy>>;
  where?: InputMaybe<ICommentBoolExp>;
};


/** columns and relationships of "work_order_with_last_recurring_instance_view" */
export type IWorkOrderWithLastRecurringInstanceViewDocumentsArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXUploadOrderBy>>;
  where?: InputMaybe<IWorkOrderXUploadBoolExp>;
};


/** columns and relationships of "work_order_with_last_recurring_instance_view" */
export type IWorkOrderWithLastRecurringInstanceViewDocumentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IWorkOrderXUploadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IWorkOrderXUploadOrderBy>>;
  where?: InputMaybe<IWorkOrderXUploadBoolExp>;
};

/** aggregated selection of "work_order_with_last_recurring_instance_view" */
export type IWorkOrderWithLastRecurringInstanceViewAggregate = {
  __typename: 'work_order_with_last_recurring_instance_view_aggregate';
  aggregate?: Maybe<IWorkOrderWithLastRecurringInstanceViewAggregateFields>;
  nodes: Array<IWorkOrderWithLastRecurringInstanceView>;
};

/** aggregate fields of "work_order_with_last_recurring_instance_view" */
export type IWorkOrderWithLastRecurringInstanceViewAggregateFields = {
  __typename: 'work_order_with_last_recurring_instance_view_aggregate_fields';
  avg?: Maybe<IWorkOrderWithLastRecurringInstanceViewAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IWorkOrderWithLastRecurringInstanceViewMaxFields>;
  min?: Maybe<IWorkOrderWithLastRecurringInstanceViewMinFields>;
  stddev?: Maybe<IWorkOrderWithLastRecurringInstanceViewStddevFields>;
  stddev_pop?: Maybe<IWorkOrderWithLastRecurringInstanceViewStddevPopFields>;
  stddev_samp?: Maybe<IWorkOrderWithLastRecurringInstanceViewStddevSampFields>;
  sum?: Maybe<IWorkOrderWithLastRecurringInstanceViewSumFields>;
  var_pop?: Maybe<IWorkOrderWithLastRecurringInstanceViewVarPopFields>;
  var_samp?: Maybe<IWorkOrderWithLastRecurringInstanceViewVarSampFields>;
  variance?: Maybe<IWorkOrderWithLastRecurringInstanceViewVarianceFields>;
};


/** aggregate fields of "work_order_with_last_recurring_instance_view" */
export type IWorkOrderWithLastRecurringInstanceViewAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IWorkOrderWithLastRecurringInstanceViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type IWorkOrderWithLastRecurringInstanceViewAvgFields = {
  __typename: 'work_order_with_last_recurring_instance_view_avg_fields';
  depth?: Maybe<Scalars['Float']['output']>;
  work_order_number?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "work_order_with_last_recurring_instance_view". All fields are combined with a logical 'AND'. */
export type IWorkOrderWithLastRecurringInstanceViewBoolExp = {
  _and?: InputMaybe<Array<IWorkOrderWithLastRecurringInstanceViewBoolExp>>;
  _not?: InputMaybe<IWorkOrderWithLastRecurringInstanceViewBoolExp>;
  _or?: InputMaybe<Array<IWorkOrderWithLastRecurringInstanceViewBoolExp>>;
  assets?: InputMaybe<IAssetXWorkOrderBoolExp>;
  assets_aggregate?: InputMaybe<IAssetXWorkOrderAggregateBoolExp>;
  assignees?: InputMaybe<IWorkOrderXUserBoolExp>;
  assignees_aggregate?: InputMaybe<IWorkOrderXUserAggregateBoolExp>;
  category?: InputMaybe<IWorkOrderCategoryBoolExp>;
  category_id?: InputMaybe<IUuidComparisonExp>;
  child_ref?: InputMaybe<IUuidComparisonExp>;
  comments?: InputMaybe<ICommentBoolExp>;
  comments_aggregate?: InputMaybe<ICommentAggregateBoolExp>;
  completed_at?: InputMaybe<ITimestamptzComparisonExp>;
  completed_by?: InputMaybe<IUserBoolExp>;
  completed_by_id?: InputMaybe<IUuidComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  created_by?: InputMaybe<IUserBoolExp>;
  created_by_id?: InputMaybe<IUuidComparisonExp>;
  deleted_at?: InputMaybe<ITimestamptzComparisonExp>;
  depth?: InputMaybe<IIntComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  documents?: InputMaybe<IWorkOrderXUploadBoolExp>;
  documents_aggregate?: InputMaybe<IWorkOrderXUploadAggregateBoolExp>;
  due_date?: InputMaybe<IDateComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  location?: InputMaybe<ILocationBoolExp>;
  location_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  parent_ref?: InputMaybe<IUuidComparisonExp>;
  priority?: InputMaybe<IStringComparisonExp>;
  procedure_id?: InputMaybe<IUuidComparisonExp>;
  recurrence_id?: InputMaybe<IUuidComparisonExp>;
  root_id?: InputMaybe<IUuidComparisonExp>;
  status?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  work_order_number?: InputMaybe<IIntComparisonExp>;
};

/** aggregate max on columns */
export type IWorkOrderWithLastRecurringInstanceViewMaxFields = {
  __typename: 'work_order_with_last_recurring_instance_view_max_fields';
  category_id?: Maybe<Scalars['uuid']['output']>;
  child_ref?: Maybe<Scalars['uuid']['output']>;
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  completed_by_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  depth?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent_ref?: Maybe<Scalars['uuid']['output']>;
  priority?: Maybe<Scalars['String']['output']>;
  procedure_id?: Maybe<Scalars['uuid']['output']>;
  recurrence_id?: Maybe<Scalars['uuid']['output']>;
  root_id?: Maybe<Scalars['uuid']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  work_order_number?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type IWorkOrderWithLastRecurringInstanceViewMinFields = {
  __typename: 'work_order_with_last_recurring_instance_view_min_fields';
  category_id?: Maybe<Scalars['uuid']['output']>;
  child_ref?: Maybe<Scalars['uuid']['output']>;
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  completed_by_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['uuid']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  depth?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent_ref?: Maybe<Scalars['uuid']['output']>;
  priority?: Maybe<Scalars['String']['output']>;
  procedure_id?: Maybe<Scalars['uuid']['output']>;
  recurrence_id?: Maybe<Scalars['uuid']['output']>;
  root_id?: Maybe<Scalars['uuid']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  work_order_number?: Maybe<Scalars['Int']['output']>;
};

/** Ordering options when selecting data from "work_order_with_last_recurring_instance_view". */
export type IWorkOrderWithLastRecurringInstanceViewOrderBy = {
  assets_aggregate?: InputMaybe<IAssetXWorkOrderAggregateOrderBy>;
  assignees_aggregate?: InputMaybe<IWorkOrderXUserAggregateOrderBy>;
  category?: InputMaybe<IWorkOrderCategoryOrderBy>;
  category_id?: InputMaybe<IOrderBy>;
  child_ref?: InputMaybe<IOrderBy>;
  comments_aggregate?: InputMaybe<ICommentAggregateOrderBy>;
  completed_at?: InputMaybe<IOrderBy>;
  completed_by?: InputMaybe<IUserOrderBy>;
  completed_by_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  created_by?: InputMaybe<IUserOrderBy>;
  created_by_id?: InputMaybe<IOrderBy>;
  deleted_at?: InputMaybe<IOrderBy>;
  depth?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  documents_aggregate?: InputMaybe<IWorkOrderXUploadAggregateOrderBy>;
  due_date?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location?: InputMaybe<ILocationOrderBy>;
  location_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  parent_ref?: InputMaybe<IOrderBy>;
  priority?: InputMaybe<IOrderBy>;
  procedure_id?: InputMaybe<IOrderBy>;
  recurrence_id?: InputMaybe<IOrderBy>;
  root_id?: InputMaybe<IOrderBy>;
  status?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  work_order_number?: InputMaybe<IOrderBy>;
};

/** select columns of table "work_order_with_last_recurring_instance_view" */
export enum IWorkOrderWithLastRecurringInstanceViewSelectColumn {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  ChildRef = 'child_ref',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CompletedById = 'completed_by_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Depth = 'depth',
  /** column name */
  Description = 'description',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentRef = 'parent_ref',
  /** column name */
  Priority = 'priority',
  /** column name */
  ProcedureId = 'procedure_id',
  /** column name */
  RecurrenceId = 'recurrence_id',
  /** column name */
  RootId = 'root_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkOrderNumber = 'work_order_number'
}

/** aggregate stddev on columns */
export type IWorkOrderWithLastRecurringInstanceViewStddevFields = {
  __typename: 'work_order_with_last_recurring_instance_view_stddev_fields';
  depth?: Maybe<Scalars['Float']['output']>;
  work_order_number?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type IWorkOrderWithLastRecurringInstanceViewStddevPopFields = {
  __typename: 'work_order_with_last_recurring_instance_view_stddev_pop_fields';
  depth?: Maybe<Scalars['Float']['output']>;
  work_order_number?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type IWorkOrderWithLastRecurringInstanceViewStddevSampFields = {
  __typename: 'work_order_with_last_recurring_instance_view_stddev_samp_fields';
  depth?: Maybe<Scalars['Float']['output']>;
  work_order_number?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "work_order_with_last_recurring_instance_view" */
export type IWorkOrderWithLastRecurringInstanceViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IWorkOrderWithLastRecurringInstanceViewStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IWorkOrderWithLastRecurringInstanceViewStreamCursorValueInput = {
  category_id?: InputMaybe<Scalars['uuid']['input']>;
  child_ref?: InputMaybe<Scalars['uuid']['input']>;
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  completed_by_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['uuid']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  depth?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  due_date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent_ref?: InputMaybe<Scalars['uuid']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  procedure_id?: InputMaybe<Scalars['uuid']['input']>;
  recurrence_id?: InputMaybe<Scalars['uuid']['input']>;
  root_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_order_number?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type IWorkOrderWithLastRecurringInstanceViewSumFields = {
  __typename: 'work_order_with_last_recurring_instance_view_sum_fields';
  depth?: Maybe<Scalars['Int']['output']>;
  work_order_number?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type IWorkOrderWithLastRecurringInstanceViewVarPopFields = {
  __typename: 'work_order_with_last_recurring_instance_view_var_pop_fields';
  depth?: Maybe<Scalars['Float']['output']>;
  work_order_number?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type IWorkOrderWithLastRecurringInstanceViewVarSampFields = {
  __typename: 'work_order_with_last_recurring_instance_view_var_samp_fields';
  depth?: Maybe<Scalars['Float']['output']>;
  work_order_number?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type IWorkOrderWithLastRecurringInstanceViewVarianceFields = {
  __typename: 'work_order_with_last_recurring_instance_view_variance_fields';
  depth?: Maybe<Scalars['Float']['output']>;
  work_order_number?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "work_order_with_open_due_date_reminder" */
export type IWorkOrderWithOpenDueDateReminder = {
  __typename: 'work_order_with_open_due_date_reminder';
  user_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  work_order?: Maybe<IWorkOrder>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "work_order_with_open_due_date_reminder" */
export type IWorkOrderWithOpenDueDateReminderAggregate = {
  __typename: 'work_order_with_open_due_date_reminder_aggregate';
  aggregate?: Maybe<IWorkOrderWithOpenDueDateReminderAggregateFields>;
  nodes: Array<IWorkOrderWithOpenDueDateReminder>;
};

/** aggregate fields of "work_order_with_open_due_date_reminder" */
export type IWorkOrderWithOpenDueDateReminderAggregateFields = {
  __typename: 'work_order_with_open_due_date_reminder_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IWorkOrderWithOpenDueDateReminderMaxFields>;
  min?: Maybe<IWorkOrderWithOpenDueDateReminderMinFields>;
};


/** aggregate fields of "work_order_with_open_due_date_reminder" */
export type IWorkOrderWithOpenDueDateReminderAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IWorkOrderWithOpenDueDateReminderSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "work_order_with_open_due_date_reminder". All fields are combined with a logical 'AND'. */
export type IWorkOrderWithOpenDueDateReminderBoolExp = {
  _and?: InputMaybe<Array<IWorkOrderWithOpenDueDateReminderBoolExp>>;
  _not?: InputMaybe<IWorkOrderWithOpenDueDateReminderBoolExp>;
  _or?: InputMaybe<Array<IWorkOrderWithOpenDueDateReminderBoolExp>>;
  user_id?: InputMaybe<IUuidComparisonExp>;
  work_order?: InputMaybe<IWorkOrderBoolExp>;
  work_order_id?: InputMaybe<IUuidComparisonExp>;
};

/** aggregate max on columns */
export type IWorkOrderWithOpenDueDateReminderMaxFields = {
  __typename: 'work_order_with_open_due_date_reminder_max_fields';
  user_id?: Maybe<Scalars['uuid']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type IWorkOrderWithOpenDueDateReminderMinFields = {
  __typename: 'work_order_with_open_due_date_reminder_min_fields';
  user_id?: Maybe<Scalars['uuid']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** Ordering options when selecting data from "work_order_with_open_due_date_reminder". */
export type IWorkOrderWithOpenDueDateReminderOrderBy = {
  user_id?: InputMaybe<IOrderBy>;
  work_order?: InputMaybe<IWorkOrderOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** select columns of table "work_order_with_open_due_date_reminder" */
export enum IWorkOrderWithOpenDueDateReminderSelectColumn {
  /** column name */
  UserId = 'user_id',
  /** column name */
  WorkOrderId = 'work_order_id'
}

/** Streaming cursor of the table "work_order_with_open_due_date_reminder" */
export type IWorkOrderWithOpenDueDateReminderStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IWorkOrderWithOpenDueDateReminderStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IWorkOrderWithOpenDueDateReminderStreamCursorValueInput = {
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Pivot Table for Collaborators (Watchers) of a Work Order */
export type IWorkOrderXCollaborator = {
  __typename: 'work_order_x_collaborator';
  assigned_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  assigned_by?: Maybe<IUser>;
  assigned_by_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  user: IUser;
  user_id: Scalars['uuid']['output'];
  /** An object relationship */
  work_order: IWorkOrder;
  work_order_id: Scalars['uuid']['output'];
};

/** aggregated selection of "work_order_x_collaborator" */
export type IWorkOrderXCollaboratorAggregate = {
  __typename: 'work_order_x_collaborator_aggregate';
  aggregate?: Maybe<IWorkOrderXCollaboratorAggregateFields>;
  nodes: Array<IWorkOrderXCollaborator>;
};

export type IWorkOrderXCollaboratorAggregateBoolExp = {
  count?: InputMaybe<IWorkOrderXCollaboratorAggregateBoolExpCount>;
};

export type IWorkOrderXCollaboratorAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IWorkOrderXCollaboratorSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IWorkOrderXCollaboratorBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "work_order_x_collaborator" */
export type IWorkOrderXCollaboratorAggregateFields = {
  __typename: 'work_order_x_collaborator_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IWorkOrderXCollaboratorMaxFields>;
  min?: Maybe<IWorkOrderXCollaboratorMinFields>;
};


/** aggregate fields of "work_order_x_collaborator" */
export type IWorkOrderXCollaboratorAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IWorkOrderXCollaboratorSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "work_order_x_collaborator" */
export type IWorkOrderXCollaboratorAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IWorkOrderXCollaboratorMaxOrderBy>;
  min?: InputMaybe<IWorkOrderXCollaboratorMinOrderBy>;
};

/** input type for inserting array relation for remote table "work_order_x_collaborator" */
export type IWorkOrderXCollaboratorArrRelInsertInput = {
  data: Array<IWorkOrderXCollaboratorInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IWorkOrderXCollaboratorOnConflict>;
};

/** Boolean expression to filter rows from the table "work_order_x_collaborator". All fields are combined with a logical 'AND'. */
export type IWorkOrderXCollaboratorBoolExp = {
  _and?: InputMaybe<Array<IWorkOrderXCollaboratorBoolExp>>;
  _not?: InputMaybe<IWorkOrderXCollaboratorBoolExp>;
  _or?: InputMaybe<Array<IWorkOrderXCollaboratorBoolExp>>;
  assigned_at?: InputMaybe<ITimestamptzComparisonExp>;
  assigned_by?: InputMaybe<IUserBoolExp>;
  assigned_by_id?: InputMaybe<IUuidComparisonExp>;
  user?: InputMaybe<IUserBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
  work_order?: InputMaybe<IWorkOrderBoolExp>;
  work_order_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "work_order_x_collaborator" */
export enum IWorkOrderXCollaboratorConstraint {
  /** unique or primary key constraint on columns "user_id", "work_order_id" */
  WorkOrderXCollaboratorPkey = 'work_order_x_collaborator_pkey',
  /** unique or primary key constraint on columns "user_id", "work_order_id" */
  WorkOrderXCollaboratorUserIdWorkOrderIdKey = 'work_order_x_collaborator_user_id_work_order_id_key'
}

/** input type for inserting data into table "work_order_x_collaborator" */
export type IWorkOrderXCollaboratorInsertInput = {
  assigned_at?: InputMaybe<Scalars['timestamptz']['input']>;
  assigned_by?: InputMaybe<IUserObjRelInsertInput>;
  assigned_by_id?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<IUserObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order?: InputMaybe<IWorkOrderObjRelInsertInput>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IWorkOrderXCollaboratorMaxFields = {
  __typename: 'work_order_x_collaborator_max_fields';
  assigned_at?: Maybe<Scalars['timestamptz']['output']>;
  assigned_by_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "work_order_x_collaborator" */
export type IWorkOrderXCollaboratorMaxOrderBy = {
  assigned_at?: InputMaybe<IOrderBy>;
  assigned_by_id?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IWorkOrderXCollaboratorMinFields = {
  __typename: 'work_order_x_collaborator_min_fields';
  assigned_at?: Maybe<Scalars['timestamptz']['output']>;
  assigned_by_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "work_order_x_collaborator" */
export type IWorkOrderXCollaboratorMinOrderBy = {
  assigned_at?: InputMaybe<IOrderBy>;
  assigned_by_id?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "work_order_x_collaborator" */
export type IWorkOrderXCollaboratorMutationResponse = {
  __typename: 'work_order_x_collaborator_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IWorkOrderXCollaborator>;
};

/** on_conflict condition type for table "work_order_x_collaborator" */
export type IWorkOrderXCollaboratorOnConflict = {
  constraint: IWorkOrderXCollaboratorConstraint;
  update_columns?: Array<IWorkOrderXCollaboratorUpdateColumn>;
  where?: InputMaybe<IWorkOrderXCollaboratorBoolExp>;
};

/** Ordering options when selecting data from "work_order_x_collaborator". */
export type IWorkOrderXCollaboratorOrderBy = {
  assigned_at?: InputMaybe<IOrderBy>;
  assigned_by?: InputMaybe<IUserOrderBy>;
  assigned_by_id?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUserOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  work_order?: InputMaybe<IWorkOrderOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: work_order_x_collaborator */
export type IWorkOrderXCollaboratorPkColumnsInput = {
  user_id: Scalars['uuid']['input'];
  work_order_id: Scalars['uuid']['input'];
};

/** select columns of table "work_order_x_collaborator" */
export enum IWorkOrderXCollaboratorSelectColumn {
  /** column name */
  AssignedAt = 'assigned_at',
  /** column name */
  AssignedById = 'assigned_by_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WorkOrderId = 'work_order_id'
}

/** input type for updating data in table "work_order_x_collaborator" */
export type IWorkOrderXCollaboratorSetInput = {
  assigned_at?: InputMaybe<Scalars['timestamptz']['input']>;
  assigned_by_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "work_order_x_collaborator" */
export type IWorkOrderXCollaboratorStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IWorkOrderXCollaboratorStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IWorkOrderXCollaboratorStreamCursorValueInput = {
  assigned_at?: InputMaybe<Scalars['timestamptz']['input']>;
  assigned_by_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "work_order_x_collaborator" */
export enum IWorkOrderXCollaboratorUpdateColumn {
  /** column name */
  AssignedAt = 'assigned_at',
  /** column name */
  AssignedById = 'assigned_by_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WorkOrderId = 'work_order_id'
}

export type IWorkOrderXCollaboratorUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IWorkOrderXCollaboratorSetInput>;
  /** filter the rows which have to be updated */
  where: IWorkOrderXCollaboratorBoolExp;
};

/** columns and relationships of "work_order_x_team" */
export type IWorkOrderXTeam = {
  __typename: 'work_order_x_team';
  assigned_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  assigned_by?: Maybe<IUser>;
  assigned_by_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  team: ITeam;
  team_id: Scalars['uuid']['output'];
  /** An object relationship */
  work_order: IWorkOrder;
  work_order_id: Scalars['uuid']['output'];
};

/** aggregated selection of "work_order_x_team" */
export type IWorkOrderXTeamAggregate = {
  __typename: 'work_order_x_team_aggregate';
  aggregate?: Maybe<IWorkOrderXTeamAggregateFields>;
  nodes: Array<IWorkOrderXTeam>;
};

export type IWorkOrderXTeamAggregateBoolExp = {
  count?: InputMaybe<IWorkOrderXTeamAggregateBoolExpCount>;
};

export type IWorkOrderXTeamAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IWorkOrderXTeamSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IWorkOrderXTeamBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "work_order_x_team" */
export type IWorkOrderXTeamAggregateFields = {
  __typename: 'work_order_x_team_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IWorkOrderXTeamMaxFields>;
  min?: Maybe<IWorkOrderXTeamMinFields>;
};


/** aggregate fields of "work_order_x_team" */
export type IWorkOrderXTeamAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IWorkOrderXTeamSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "work_order_x_team" */
export type IWorkOrderXTeamAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IWorkOrderXTeamMaxOrderBy>;
  min?: InputMaybe<IWorkOrderXTeamMinOrderBy>;
};

/** input type for inserting array relation for remote table "work_order_x_team" */
export type IWorkOrderXTeamArrRelInsertInput = {
  data: Array<IWorkOrderXTeamInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IWorkOrderXTeamOnConflict>;
};

/** Boolean expression to filter rows from the table "work_order_x_team". All fields are combined with a logical 'AND'. */
export type IWorkOrderXTeamBoolExp = {
  _and?: InputMaybe<Array<IWorkOrderXTeamBoolExp>>;
  _not?: InputMaybe<IWorkOrderXTeamBoolExp>;
  _or?: InputMaybe<Array<IWorkOrderXTeamBoolExp>>;
  assigned_at?: InputMaybe<ITimestamptzComparisonExp>;
  assigned_by?: InputMaybe<IUserBoolExp>;
  assigned_by_id?: InputMaybe<IUuidComparisonExp>;
  team?: InputMaybe<ITeamBoolExp>;
  team_id?: InputMaybe<IUuidComparisonExp>;
  work_order?: InputMaybe<IWorkOrderBoolExp>;
  work_order_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "work_order_x_team" */
export enum IWorkOrderXTeamConstraint {
  /** unique or primary key constraint on columns "work_order_id", "team_id" */
  WorkOrderXTeamPkey = 'work_order_x_team_pkey'
}

/** input type for inserting data into table "work_order_x_team" */
export type IWorkOrderXTeamInsertInput = {
  assigned_at?: InputMaybe<Scalars['timestamptz']['input']>;
  assigned_by?: InputMaybe<IUserObjRelInsertInput>;
  assigned_by_id?: InputMaybe<Scalars['uuid']['input']>;
  team?: InputMaybe<ITeamObjRelInsertInput>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order?: InputMaybe<IWorkOrderObjRelInsertInput>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IWorkOrderXTeamMaxFields = {
  __typename: 'work_order_x_team_max_fields';
  assigned_at?: Maybe<Scalars['timestamptz']['output']>;
  assigned_by_id?: Maybe<Scalars['uuid']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "work_order_x_team" */
export type IWorkOrderXTeamMaxOrderBy = {
  assigned_at?: InputMaybe<IOrderBy>;
  assigned_by_id?: InputMaybe<IOrderBy>;
  team_id?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IWorkOrderXTeamMinFields = {
  __typename: 'work_order_x_team_min_fields';
  assigned_at?: Maybe<Scalars['timestamptz']['output']>;
  assigned_by_id?: Maybe<Scalars['uuid']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "work_order_x_team" */
export type IWorkOrderXTeamMinOrderBy = {
  assigned_at?: InputMaybe<IOrderBy>;
  assigned_by_id?: InputMaybe<IOrderBy>;
  team_id?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "work_order_x_team" */
export type IWorkOrderXTeamMutationResponse = {
  __typename: 'work_order_x_team_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IWorkOrderXTeam>;
};

/** on_conflict condition type for table "work_order_x_team" */
export type IWorkOrderXTeamOnConflict = {
  constraint: IWorkOrderXTeamConstraint;
  update_columns?: Array<IWorkOrderXTeamUpdateColumn>;
  where?: InputMaybe<IWorkOrderXTeamBoolExp>;
};

/** Ordering options when selecting data from "work_order_x_team". */
export type IWorkOrderXTeamOrderBy = {
  assigned_at?: InputMaybe<IOrderBy>;
  assigned_by?: InputMaybe<IUserOrderBy>;
  assigned_by_id?: InputMaybe<IOrderBy>;
  team?: InputMaybe<ITeamOrderBy>;
  team_id?: InputMaybe<IOrderBy>;
  work_order?: InputMaybe<IWorkOrderOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: work_order_x_team */
export type IWorkOrderXTeamPkColumnsInput = {
  team_id: Scalars['uuid']['input'];
  work_order_id: Scalars['uuid']['input'];
};

/** select columns of table "work_order_x_team" */
export enum IWorkOrderXTeamSelectColumn {
  /** column name */
  AssignedAt = 'assigned_at',
  /** column name */
  AssignedById = 'assigned_by_id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  WorkOrderId = 'work_order_id'
}

/** input type for updating data in table "work_order_x_team" */
export type IWorkOrderXTeamSetInput = {
  assigned_at?: InputMaybe<Scalars['timestamptz']['input']>;
  assigned_by_id?: InputMaybe<Scalars['uuid']['input']>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "work_order_x_team" */
export type IWorkOrderXTeamStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IWorkOrderXTeamStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IWorkOrderXTeamStreamCursorValueInput = {
  assigned_at?: InputMaybe<Scalars['timestamptz']['input']>;
  assigned_by_id?: InputMaybe<Scalars['uuid']['input']>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "work_order_x_team" */
export enum IWorkOrderXTeamUpdateColumn {
  /** column name */
  AssignedAt = 'assigned_at',
  /** column name */
  AssignedById = 'assigned_by_id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  WorkOrderId = 'work_order_id'
}

export type IWorkOrderXTeamUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IWorkOrderXTeamSetInput>;
  /** filter the rows which have to be updated */
  where: IWorkOrderXTeamBoolExp;
};

/** columns and relationships of "work_order_x_upload" */
export type IWorkOrderXUpload = {
  __typename: 'work_order_x_upload';
  /** An object relationship */
  document: IUpload;
  upload_id: Scalars['uuid']['output'];
  /** An object relationship */
  work_order: IWorkOrder;
  work_order_id: Scalars['uuid']['output'];
};

/** aggregated selection of "work_order_x_upload" */
export type IWorkOrderXUploadAggregate = {
  __typename: 'work_order_x_upload_aggregate';
  aggregate?: Maybe<IWorkOrderXUploadAggregateFields>;
  nodes: Array<IWorkOrderXUpload>;
};

export type IWorkOrderXUploadAggregateBoolExp = {
  count?: InputMaybe<IWorkOrderXUploadAggregateBoolExpCount>;
};

export type IWorkOrderXUploadAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IWorkOrderXUploadSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IWorkOrderXUploadBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "work_order_x_upload" */
export type IWorkOrderXUploadAggregateFields = {
  __typename: 'work_order_x_upload_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IWorkOrderXUploadMaxFields>;
  min?: Maybe<IWorkOrderXUploadMinFields>;
};


/** aggregate fields of "work_order_x_upload" */
export type IWorkOrderXUploadAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IWorkOrderXUploadSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "work_order_x_upload" */
export type IWorkOrderXUploadAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IWorkOrderXUploadMaxOrderBy>;
  min?: InputMaybe<IWorkOrderXUploadMinOrderBy>;
};

/** input type for inserting array relation for remote table "work_order_x_upload" */
export type IWorkOrderXUploadArrRelInsertInput = {
  data: Array<IWorkOrderXUploadInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IWorkOrderXUploadOnConflict>;
};

/** Boolean expression to filter rows from the table "work_order_x_upload". All fields are combined with a logical 'AND'. */
export type IWorkOrderXUploadBoolExp = {
  _and?: InputMaybe<Array<IWorkOrderXUploadBoolExp>>;
  _not?: InputMaybe<IWorkOrderXUploadBoolExp>;
  _or?: InputMaybe<Array<IWorkOrderXUploadBoolExp>>;
  document?: InputMaybe<IUploadBoolExp>;
  upload_id?: InputMaybe<IUuidComparisonExp>;
  work_order?: InputMaybe<IWorkOrderBoolExp>;
  work_order_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "work_order_x_upload" */
export enum IWorkOrderXUploadConstraint {
  /** unique or primary key constraint on columns "upload_id", "work_order_id" */
  WorkOrderXUploadPkey = 'work_order_x_upload_pkey'
}

/** input type for inserting data into table "work_order_x_upload" */
export type IWorkOrderXUploadInsertInput = {
  document?: InputMaybe<IUploadObjRelInsertInput>;
  upload_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order?: InputMaybe<IWorkOrderObjRelInsertInput>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IWorkOrderXUploadMaxFields = {
  __typename: 'work_order_x_upload_max_fields';
  upload_id?: Maybe<Scalars['uuid']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "work_order_x_upload" */
export type IWorkOrderXUploadMaxOrderBy = {
  upload_id?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IWorkOrderXUploadMinFields = {
  __typename: 'work_order_x_upload_min_fields';
  upload_id?: Maybe<Scalars['uuid']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "work_order_x_upload" */
export type IWorkOrderXUploadMinOrderBy = {
  upload_id?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "work_order_x_upload" */
export type IWorkOrderXUploadMutationResponse = {
  __typename: 'work_order_x_upload_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IWorkOrderXUpload>;
};

/** on_conflict condition type for table "work_order_x_upload" */
export type IWorkOrderXUploadOnConflict = {
  constraint: IWorkOrderXUploadConstraint;
  update_columns?: Array<IWorkOrderXUploadUpdateColumn>;
  where?: InputMaybe<IWorkOrderXUploadBoolExp>;
};

/** Ordering options when selecting data from "work_order_x_upload". */
export type IWorkOrderXUploadOrderBy = {
  document?: InputMaybe<IUploadOrderBy>;
  upload_id?: InputMaybe<IOrderBy>;
  work_order?: InputMaybe<IWorkOrderOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: work_order_x_upload */
export type IWorkOrderXUploadPkColumnsInput = {
  upload_id: Scalars['uuid']['input'];
  work_order_id: Scalars['uuid']['input'];
};

/** select columns of table "work_order_x_upload" */
export enum IWorkOrderXUploadSelectColumn {
  /** column name */
  UploadId = 'upload_id',
  /** column name */
  WorkOrderId = 'work_order_id'
}

/** input type for updating data in table "work_order_x_upload" */
export type IWorkOrderXUploadSetInput = {
  upload_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "work_order_x_upload" */
export type IWorkOrderXUploadStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IWorkOrderXUploadStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IWorkOrderXUploadStreamCursorValueInput = {
  upload_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "work_order_x_upload" */
export enum IWorkOrderXUploadUpdateColumn {
  /** column name */
  UploadId = 'upload_id',
  /** column name */
  WorkOrderId = 'work_order_id'
}

export type IWorkOrderXUploadUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IWorkOrderXUploadSetInput>;
  /** filter the rows which have to be updated */
  where: IWorkOrderXUploadBoolExp;
};

/** columns and relationships of "work_order_x_user" */
export type IWorkOrderXUser = {
  __typename: 'work_order_x_user';
  assigned_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  assigned_by?: Maybe<IUser>;
  assigned_by_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  user: IUser;
  user_id: Scalars['uuid']['output'];
  /** An object relationship */
  work_order: IWorkOrder;
  work_order_id: Scalars['uuid']['output'];
};

/** aggregated selection of "work_order_x_user" */
export type IWorkOrderXUserAggregate = {
  __typename: 'work_order_x_user_aggregate';
  aggregate?: Maybe<IWorkOrderXUserAggregateFields>;
  nodes: Array<IWorkOrderXUser>;
};

export type IWorkOrderXUserAggregateBoolExp = {
  count?: InputMaybe<IWorkOrderXUserAggregateBoolExpCount>;
};

export type IWorkOrderXUserAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IWorkOrderXUserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IWorkOrderXUserBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "work_order_x_user" */
export type IWorkOrderXUserAggregateFields = {
  __typename: 'work_order_x_user_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IWorkOrderXUserMaxFields>;
  min?: Maybe<IWorkOrderXUserMinFields>;
};


/** aggregate fields of "work_order_x_user" */
export type IWorkOrderXUserAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IWorkOrderXUserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "work_order_x_user" */
export type IWorkOrderXUserAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IWorkOrderXUserMaxOrderBy>;
  min?: InputMaybe<IWorkOrderXUserMinOrderBy>;
};

/** input type for inserting array relation for remote table "work_order_x_user" */
export type IWorkOrderXUserArrRelInsertInput = {
  data: Array<IWorkOrderXUserInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IWorkOrderXUserOnConflict>;
};

/** Boolean expression to filter rows from the table "work_order_x_user". All fields are combined with a logical 'AND'. */
export type IWorkOrderXUserBoolExp = {
  _and?: InputMaybe<Array<IWorkOrderXUserBoolExp>>;
  _not?: InputMaybe<IWorkOrderXUserBoolExp>;
  _or?: InputMaybe<Array<IWorkOrderXUserBoolExp>>;
  assigned_at?: InputMaybe<ITimestamptzComparisonExp>;
  assigned_by?: InputMaybe<IUserBoolExp>;
  assigned_by_id?: InputMaybe<IUuidComparisonExp>;
  user?: InputMaybe<IUserBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
  work_order?: InputMaybe<IWorkOrderBoolExp>;
  work_order_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "work_order_x_user" */
export enum IWorkOrderXUserConstraint {
  /** unique or primary key constraint on columns "user_id", "work_order_id" */
  WorkOrderXUserPkey = 'work_order_x_user_pkey'
}

/** input type for inserting data into table "work_order_x_user" */
export type IWorkOrderXUserInsertInput = {
  assigned_at?: InputMaybe<Scalars['timestamptz']['input']>;
  assigned_by?: InputMaybe<IUserObjRelInsertInput>;
  assigned_by_id?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<IUserObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order?: InputMaybe<IWorkOrderObjRelInsertInput>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IWorkOrderXUserMaxFields = {
  __typename: 'work_order_x_user_max_fields';
  assigned_at?: Maybe<Scalars['timestamptz']['output']>;
  assigned_by_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "work_order_x_user" */
export type IWorkOrderXUserMaxOrderBy = {
  assigned_at?: InputMaybe<IOrderBy>;
  assigned_by_id?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IWorkOrderXUserMinFields = {
  __typename: 'work_order_x_user_min_fields';
  assigned_at?: Maybe<Scalars['timestamptz']['output']>;
  assigned_by_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "work_order_x_user" */
export type IWorkOrderXUserMinOrderBy = {
  assigned_at?: InputMaybe<IOrderBy>;
  assigned_by_id?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "work_order_x_user" */
export type IWorkOrderXUserMutationResponse = {
  __typename: 'work_order_x_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IWorkOrderXUser>;
};

/** on_conflict condition type for table "work_order_x_user" */
export type IWorkOrderXUserOnConflict = {
  constraint: IWorkOrderXUserConstraint;
  update_columns?: Array<IWorkOrderXUserUpdateColumn>;
  where?: InputMaybe<IWorkOrderXUserBoolExp>;
};

/** Ordering options when selecting data from "work_order_x_user". */
export type IWorkOrderXUserOrderBy = {
  assigned_at?: InputMaybe<IOrderBy>;
  assigned_by?: InputMaybe<IUserOrderBy>;
  assigned_by_id?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUserOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  work_order?: InputMaybe<IWorkOrderOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: work_order_x_user */
export type IWorkOrderXUserPkColumnsInput = {
  user_id: Scalars['uuid']['input'];
  work_order_id: Scalars['uuid']['input'];
};

/** select columns of table "work_order_x_user" */
export enum IWorkOrderXUserSelectColumn {
  /** column name */
  AssignedAt = 'assigned_at',
  /** column name */
  AssignedById = 'assigned_by_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WorkOrderId = 'work_order_id'
}

/** input type for updating data in table "work_order_x_user" */
export type IWorkOrderXUserSetInput = {
  assigned_at?: InputMaybe<Scalars['timestamptz']['input']>;
  assigned_by_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "work_order_x_user" */
export type IWorkOrderXUserStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IWorkOrderXUserStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IWorkOrderXUserStreamCursorValueInput = {
  assigned_at?: InputMaybe<Scalars['timestamptz']['input']>;
  assigned_by_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "work_order_x_user" */
export enum IWorkOrderXUserUpdateColumn {
  /** column name */
  AssignedAt = 'assigned_at',
  /** column name */
  AssignedById = 'assigned_by_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WorkOrderId = 'work_order_id'
}

export type IWorkOrderXUserUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IWorkOrderXUserSetInput>;
  /** filter the rows which have to be updated */
  where: IWorkOrderXUserBoolExp;
};

/** columns and relationships of "work_order_x_work_order_category" */
export type IWorkOrderXWorkOrderCategory = {
  __typename: 'work_order_x_work_order_category';
  /** An object relationship */
  category: IWorkOrderCategory;
  created_at: Scalars['timestamptz']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  work_order: IWorkOrder;
  work_order_category_id: Scalars['uuid']['output'];
  work_order_id: Scalars['uuid']['output'];
};

/** aggregated selection of "work_order_x_work_order_category" */
export type IWorkOrderXWorkOrderCategoryAggregate = {
  __typename: 'work_order_x_work_order_category_aggregate';
  aggregate?: Maybe<IWorkOrderXWorkOrderCategoryAggregateFields>;
  nodes: Array<IWorkOrderXWorkOrderCategory>;
};

export type IWorkOrderXWorkOrderCategoryAggregateBoolExp = {
  count?: InputMaybe<IWorkOrderXWorkOrderCategoryAggregateBoolExpCount>;
};

export type IWorkOrderXWorkOrderCategoryAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IWorkOrderXWorkOrderCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IWorkOrderXWorkOrderCategoryBoolExp>;
  predicate: IIntComparisonExp;
};

/** aggregate fields of "work_order_x_work_order_category" */
export type IWorkOrderXWorkOrderCategoryAggregateFields = {
  __typename: 'work_order_x_work_order_category_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IWorkOrderXWorkOrderCategoryMaxFields>;
  min?: Maybe<IWorkOrderXWorkOrderCategoryMinFields>;
};


/** aggregate fields of "work_order_x_work_order_category" */
export type IWorkOrderXWorkOrderCategoryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IWorkOrderXWorkOrderCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "work_order_x_work_order_category" */
export type IWorkOrderXWorkOrderCategoryAggregateOrderBy = {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IWorkOrderXWorkOrderCategoryMaxOrderBy>;
  min?: InputMaybe<IWorkOrderXWorkOrderCategoryMinOrderBy>;
};

/** input type for inserting array relation for remote table "work_order_x_work_order_category" */
export type IWorkOrderXWorkOrderCategoryArrRelInsertInput = {
  data: Array<IWorkOrderXWorkOrderCategoryInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IWorkOrderXWorkOrderCategoryOnConflict>;
};

/** Boolean expression to filter rows from the table "work_order_x_work_order_category". All fields are combined with a logical 'AND'. */
export type IWorkOrderXWorkOrderCategoryBoolExp = {
  _and?: InputMaybe<Array<IWorkOrderXWorkOrderCategoryBoolExp>>;
  _not?: InputMaybe<IWorkOrderXWorkOrderCategoryBoolExp>;
  _or?: InputMaybe<Array<IWorkOrderXWorkOrderCategoryBoolExp>>;
  category?: InputMaybe<IWorkOrderCategoryBoolExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  work_order?: InputMaybe<IWorkOrderBoolExp>;
  work_order_category_id?: InputMaybe<IUuidComparisonExp>;
  work_order_id?: InputMaybe<IUuidComparisonExp>;
};

/** unique or primary key constraints on table "work_order_x_work_order_category" */
export enum IWorkOrderXWorkOrderCategoryConstraint {
  /** unique or primary key constraint on columns "work_order_category_id", "work_order_id" */
  WorkOrderXWorkOrderCategoryPkey = 'work_order_x_work_order_category_pkey'
}

/** input type for inserting data into table "work_order_x_work_order_category" */
export type IWorkOrderXWorkOrderCategoryInsertInput = {
  category?: InputMaybe<IWorkOrderCategoryObjRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_order?: InputMaybe<IWorkOrderObjRelInsertInput>;
  work_order_category_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type IWorkOrderXWorkOrderCategoryMaxFields = {
  __typename: 'work_order_x_work_order_category_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  work_order_category_id?: Maybe<Scalars['uuid']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "work_order_x_work_order_category" */
export type IWorkOrderXWorkOrderCategoryMaxOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  work_order_category_id?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** aggregate min on columns */
export type IWorkOrderXWorkOrderCategoryMinFields = {
  __typename: 'work_order_x_work_order_category_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  work_order_category_id?: Maybe<Scalars['uuid']['output']>;
  work_order_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "work_order_x_work_order_category" */
export type IWorkOrderXWorkOrderCategoryMinOrderBy = {
  created_at?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  work_order_category_id?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** response of any mutation on the table "work_order_x_work_order_category" */
export type IWorkOrderXWorkOrderCategoryMutationResponse = {
  __typename: 'work_order_x_work_order_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IWorkOrderXWorkOrderCategory>;
};

/** on_conflict condition type for table "work_order_x_work_order_category" */
export type IWorkOrderXWorkOrderCategoryOnConflict = {
  constraint: IWorkOrderXWorkOrderCategoryConstraint;
  update_columns?: Array<IWorkOrderXWorkOrderCategoryUpdateColumn>;
  where?: InputMaybe<IWorkOrderXWorkOrderCategoryBoolExp>;
};

/** Ordering options when selecting data from "work_order_x_work_order_category". */
export type IWorkOrderXWorkOrderCategoryOrderBy = {
  category?: InputMaybe<IWorkOrderCategoryOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  work_order?: InputMaybe<IWorkOrderOrderBy>;
  work_order_category_id?: InputMaybe<IOrderBy>;
  work_order_id?: InputMaybe<IOrderBy>;
};

/** primary key columns input for table: work_order_x_work_order_category */
export type IWorkOrderXWorkOrderCategoryPkColumnsInput = {
  work_order_category_id: Scalars['uuid']['input'];
  work_order_id: Scalars['uuid']['input'];
};

/** select columns of table "work_order_x_work_order_category" */
export enum IWorkOrderXWorkOrderCategorySelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkOrderCategoryId = 'work_order_category_id',
  /** column name */
  WorkOrderId = 'work_order_id'
}

/** input type for updating data in table "work_order_x_work_order_category" */
export type IWorkOrderXWorkOrderCategorySetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_order_category_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "work_order_x_work_order_category" */
export type IWorkOrderXWorkOrderCategoryStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IWorkOrderXWorkOrderCategoryStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IWorkOrderXWorkOrderCategoryStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_order_category_id?: InputMaybe<Scalars['uuid']['input']>;
  work_order_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "work_order_x_work_order_category" */
export enum IWorkOrderXWorkOrderCategoryUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkOrderCategoryId = 'work_order_category_id',
  /** column name */
  WorkOrderId = 'work_order_id'
}

export type IWorkOrderXWorkOrderCategoryUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IWorkOrderXWorkOrderCategorySetInput>;
  /** filter the rows which have to be updated */
  where: IWorkOrderXWorkOrderCategoryBoolExp;
};
