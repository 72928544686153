import { Data } from "@elara/select"
import { useCallbackRef } from "@hooks"
import { UseTreeListReturn } from "@hooks/use-tree-list"
import { FilteredTreeLike, Tree } from "@utils/tree"
import React, { useEffect, useState } from "react"
import { VirtuosoHandle } from "react-virtuoso"

import { DataViewFlatData } from "../data-view-types"

export const useKeyboardNavigation = <D extends Data>(args: {
  flatData: DataViewFlatData<D>
  navigate: UseTreeListReturn<D>["navigate"]
  virtuosoRef?: React.RefObject<VirtuosoHandle>
  containerRef?: React.RefObject<HTMLDivElement>
  onSelect?: (row: FilteredTreeLike<Tree<D>>) => void
}) => {
  const { flatData, navigate } = args
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1)
  const [isFocused, setIsFocused] = useState(false)

  const updateSelectedRow = (index: number) => {
    setSelectedRowIndex(index)
    const selectedData = flatData[index]
    const selectedRow = selectedData?.type === "item" ? selectedData.item : null
    if (selectedRow) {
      args.onSelect?.(selectedRow)
    }
  }

  const selectedData = flatData[selectedRowIndex]
  const selectedRow = selectedData?.type === "item" ? selectedData.item : null
  const onClick = (row: FilteredTreeLike<Tree<D>>) => {
    setIsFocused(true)
    const index = flatData.findIndex((r) => r.type === "item" && r.item.index === row.index)
    updateSelectedRow(index)
  }
  const handleKey = useCallbackRef((key: string) => {
    if (!(key === "ArrowDown" || key === "ArrowUp")) return
    if (selectedRow) {
      let currentIndex = selectedRow.index
      while (true) {
        let nextIndex = -1
        let nextSelectedRowFlatDataIndex = -1
        if (key === "ArrowDown") {
          nextIndex = navigate.down(currentIndex)
          // try to find the next item efficiently
          const nextFlatData = flatData[selectedRowIndex + 1]
          if (nextFlatData?.type === "item" && nextFlatData.item.index === nextIndex) {
            nextSelectedRowFlatDataIndex = selectedRowIndex + 1
          }
        } else if (key === "ArrowUp") {
          nextIndex = navigate.up(currentIndex)
          // try to find the next item efficiently
          const nextFlatData = flatData[selectedRowIndex - 1]
          if (nextFlatData?.type === "item" && nextFlatData.item.index === nextIndex) {
            nextSelectedRowFlatDataIndex = selectedRowIndex - 1
          }
        }
        if (nextIndex === -1) return

        // if we didn't find so far the next index make a big search
        if (nextSelectedRowFlatDataIndex === -1) {
          nextSelectedRowFlatDataIndex = flatData.findIndex((e) => {
            return e.type === "item" && e.item.index === nextIndex
          })
        }

        const nextSelectedRow = flatData[nextSelectedRowFlatDataIndex]
        if (!nextSelectedRow) {
          currentIndex = nextIndex
          // avoid endless loop if everything is collapsed
          if (currentIndex === selectedRow.index) break
          continue
        }
        if (nextSelectedRow.type === "item") {
          args.virtuosoRef?.current?.scrollIntoView({ index: nextSelectedRowFlatDataIndex })
          updateSelectedRow(nextSelectedRowFlatDataIndex)
        }
        break
      }
    }
  })

  useEffect(() => {
    const handleKeyPress = (e: globalThis.KeyboardEvent) => {
      if (!isFocused) return
      handleKey(e.key)
    }

    document.addEventListener("mousedown", (e) => {
      if (!args.containerRef?.current?.contains(e.target as Node | null)) {
        setIsFocused(false)
      } else {
        setIsFocused(true)
      }
    })
    document.addEventListener("touchstart", (e) => {
      if (!args.containerRef?.current?.contains(e.target as Node | null)) {
        setIsFocused(false)
      } else {
        setIsFocused(true)
      }
    })
    document.addEventListener("keydown", handleKeyPress)
    return () => {
      document.removeEventListener("keydown", handleKeyPress)
    }
  }, [isFocused])

  return { onClick, selectedRow, selectedRowIndex }
}
