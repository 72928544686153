import { HStack, VStack } from "@components/layout"
import Icons from "@resources/icons"
import { colors } from "@styles"
import { ReactNode } from "react"
import toast, { Toast, ToastOptions } from "react-hot-toast"

const defaultDesktopParams: ToastOptions = {
  duration: 1500,
  position: "top-right",
  style: {
    maxWidth: "450px",
    padding: "8px 10px",
  },
}

const defaultMobileParams: ToastOptions = {
  duration: 1500,
  position: "top-center",
  style: {
    maxWidth: "100%",
    padding: "8px 10px",
  },
}

type RegularToastTypes = "success" | "error" | "warning"

type ToastFunctionArgs = {
  title: string
  body?: ReactNode
  icon?: React.ReactNode
  params?: ToastOptions
  onDismiss?: () => void
  type?: RegularToastTypes
}

const getRegularToastIcon = (type: RegularToastTypes) => {
  switch (type) {
    case "success":
      return <Icons.Executed color={colors.greenDeep} height={20} width={20} />
    case "error":
      return <Icons.Rejected color={colors.redDeep} height={20} width={20} />
    case "warning":
      return <Icons.Warning color={colors.yellowDeep} height={20} width={20} />
    default:
      return null
  }
}

const RegularToast = ({
  title,
  type,
  body,
  t,
  icon,
  onDismiss,
}: ToastFunctionArgs & { t: Toast }) => {
  return (
    <HStack align="stretch" space={12} className="max-w-full">
      <div className="flex w-8 flex-col items-start">
        {icon ? icon : type ? getRegularToastIcon(type) : null}
      </div>
      <VStack space={4} className="!shrink">
        <HStack space={12} align="center" className="!shrink">
          <span className="flex-1 text-sm font-semibold">{title}</span>
          <Icons.Close
            className="shrink-0 grow-0 cursor-pointer text-gray-500 hover:text-gray-700"
            onClick={() => {
              toast.dismiss(t.id)
              onDismiss?.()
            }}
          />
        </HStack>
        <HStack>
          <div className="text-sm">{body}</div>
          <div className="pl-[36px]"></div>
        </HStack>
      </VStack>
    </HStack>
  )
}

const CondensedToast = ({
  body,
  t,
  onDismiss,
}: { body: ReactNode; onDismiss?: () => void } & { t: Toast }) => {
  return (
    <HStack space={12}>
      {body}
      <Icons.Close
        className="shrink-0 grow-0 cursor-pointer text-gray-500 hover:text-gray-700"
        onClick={() => {
          toast.dismiss(t.id)
          onDismiss?.()
        }}
      />
    </HStack>
  )
}

export const regularToast = (args: ToastFunctionArgs) => {
  const defaultParams = window.innerWidth < 640 ? defaultMobileParams : defaultDesktopParams

  return toast((t) => <RegularToast {...args} t={t} />, {
    ...defaultParams,
    ...args.params,
  })
}

export const toastSuccess = (args: ToastFunctionArgs | string) =>
  typeof args === "object"
    ? regularToast({ ...args, type: "success" })
    : regularToast({ title: args, type: "success" })

export const toastError = (args: ToastFunctionArgs | string) =>
  typeof args === "object"
    ? regularToast({ ...args, type: "error" })
    : regularToast({ title: args, type: "error" })

export const toastWarning = (args: ToastFunctionArgs | string) =>
  typeof args === "object"
    ? regularToast({ ...args, type: "warning" })
    : regularToast({ title: args, type: "warning" })

export const toastCondensed = (args: { body: ReactNode; params?: ToastOptions }) =>
  toast((t) => <CondensedToast body={args.body} t={t} />, args.params)

export default {
  success: toastSuccess,
  error: toastError,
  warning: toastWarning,
}
