// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import { AssetManufacturerFragmentDoc } from './fragments.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IAssetManufacturersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IAssetManufacturersQuery = { asset_manufacturer: Array<{ __typename: 'asset_manufacturer', id: uuid, name: string, email: string | null, phone: string | null, contact: string | null }> };

export type ICreateAssetManufacturerMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
}>;


export type ICreateAssetManufacturerMutation = { insert_asset_manufacturer_one: { __typename: 'asset_manufacturer', id: uuid, name: string, email: string | null, phone: string | null, contact: string | null } | null };

export type ICreateAssetManufacturerManyMutationVariables = Types.Exact<{
  objects: Array<Types.IAssetManufacturerInsertInput> | Types.IAssetManufacturerInsertInput;
}>;


export type ICreateAssetManufacturerManyMutation = { insert_asset_manufacturer: { __typename: 'asset_manufacturer_mutation_response', returning: Array<{ __typename: 'asset_manufacturer', id: uuid, name: string }> } | null };

export type IUpdateManufacturerMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['uuid']['input']>;
  _set: Types.IAssetManufacturerSetInput;
}>;


export type IUpdateManufacturerMutation = { update_asset_manufacturer_by_pk: { __typename: 'asset_manufacturer', id: uuid, name: string, email: string | null, phone: string | null, contact: string | null } | null };


export const AssetManufacturersDocument = gql`
    query AssetManufacturers {
  asset_manufacturer {
    ...AssetManufacturer
  }
}
    ${AssetManufacturerFragmentDoc}`;

export function useAssetManufacturersQuery(options?: Omit<Urql.UseQueryArgs<IAssetManufacturersQueryVariables>, 'query'>) {
  return Urql.useQuery<IAssetManufacturersQuery, IAssetManufacturersQueryVariables>({ query: AssetManufacturersDocument, ...options });
};
export const CreateAssetManufacturerDocument = gql`
    mutation CreateAssetManufacturer($name: String!) {
  insert_asset_manufacturer_one(object: {name: $name}) {
    ...AssetManufacturer
  }
}
    ${AssetManufacturerFragmentDoc}`;

export function useCreateAssetManufacturerMutation() {
  return Urql.useMutation<ICreateAssetManufacturerMutation, ICreateAssetManufacturerMutationVariables>(CreateAssetManufacturerDocument);
};
export const CreateAssetManufacturerManyDocument = gql`
    mutation CreateAssetManufacturerMany($objects: [asset_manufacturer_insert_input!]!) {
  insert_asset_manufacturer(objects: $objects) {
    returning {
      id
      name
    }
  }
}
    `;

export function useCreateAssetManufacturerManyMutation() {
  return Urql.useMutation<ICreateAssetManufacturerManyMutation, ICreateAssetManufacturerManyMutationVariables>(CreateAssetManufacturerManyDocument);
};
export const UpdateManufacturerDocument = gql`
    mutation UpdateManufacturer($id: uuid = "", $_set: asset_manufacturer_set_input!) {
  update_asset_manufacturer_by_pk(_set: $_set, pk_columns: {id: $id}) {
    ...AssetManufacturer
  }
}
    ${AssetManufacturerFragmentDoc}`;

export function useUpdateManufacturerMutation() {
  return Urql.useMutation<IUpdateManufacturerMutation, IUpdateManufacturerMutationVariables>(UpdateManufacturerDocument);
};