import { AssetCreateFormDialog } from "@components/asset/asset-create-form-dialog"
import { DataViewTabs as NewDataViewTabs } from "@components/new-data-view/data-view-tabs"
import { Button } from "@components/shared"
import { DataViewTabs } from "@components/shared/data-view/data-view-tabs"
import { useFeature } from "@contexts/feature-flag-context"
import { IViewDataTypeEnum } from "@elara/db"
import { useDisclosure } from "@hooks"
import i18n from "@i18n"
import { Plus } from "@phosphor-icons/react"

const HeaderAction = () => {
  const assetCreateDialog = useDisclosure()

  return (
    <div className="space-x-2">
      <Button onClick={assetCreateDialog.onOpen} type="primary" icon={Plus}>
        {i18n.t("assets:new_asset")}
      </Button>

      <AssetCreateFormDialog
        isOpen={assetCreateDialog.isOpen}
        onOpenChange={assetCreateDialog.changeOpen}
        title={i18n.t("common:create_token", {
          token: i18n.t("common:asset", { count: 1 }),
        })}
      />
    </div>
  )
}

const ObjectsPage = () => {
  const newDataView = useFeature("data_view")

  if (newDataView) {
    return (
      <NewDataViewTabs
        dataType={IViewDataTypeEnum.Asset}
        title={i18n.t("common:asset", { count: 2 })}
        action={<HeaderAction />}
      />
    )
  }
  return (
    <DataViewTabs
      dataType={IViewDataTypeEnum.Asset}
      title={i18n.t("common:asset", { count: 2 })}
      action={<HeaderAction />}
    />
  )
}

export default ObjectsPage
