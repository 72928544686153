// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import { ConsumableGroupFragmentDoc } from './fragments.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IConsumableGroupsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IConsumableGroupsQuery = { consumable_group: Array<{ __typename: 'consumable_group', id: uuid, name: string }> };

export type IConsumableGroupsWithCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IConsumableGroupsWithCountQuery = { consumable_group: Array<{ __typename: 'consumable_group', id: uuid, name: string, consumables_aggregate: { __typename: 'consumable_aggregate', aggregate: { __typename: 'consumable_aggregate_fields', count: number } | null } }> };

export type IInsertConsumableGroupMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
}>;


export type IInsertConsumableGroupMutation = { insert_consumable_group_one: { __typename: 'consumable_group', id: uuid, name: string } | null };

export type IInsertConsumableGroupManyMutationVariables = Types.Exact<{
  objects: Array<Types.IConsumableGroupInsertInput> | Types.IConsumableGroupInsertInput;
}>;


export type IInsertConsumableGroupManyMutation = { insert_consumable_group: { __typename: 'consumable_group_mutation_response', returning: Array<{ __typename: 'consumable_group', id: uuid, name: string }> } | null };

export type IUpdateConsumableGroupMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  group?: Types.InputMaybe<Types.IConsumableGroupSetInput>;
}>;


export type IUpdateConsumableGroupMutation = { update_consumable_group_by_pk: { __typename: 'consumable_group', id: uuid, name: string } | null };

export type IDeleteConsumableGroupMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type IDeleteConsumableGroupMutation = { delete_consumable_group_by_pk: { __typename: 'consumable_group', id: uuid, name: string } | null };


export const ConsumableGroupsDocument = gql`
    query ConsumableGroups {
  consumable_group {
    ...ConsumableGroup
  }
}
    ${ConsumableGroupFragmentDoc}`;

export function useConsumableGroupsQuery(options?: Omit<Urql.UseQueryArgs<IConsumableGroupsQueryVariables>, 'query'>) {
  return Urql.useQuery<IConsumableGroupsQuery, IConsumableGroupsQueryVariables>({ query: ConsumableGroupsDocument, ...options });
};
export const ConsumableGroupsWithCountDocument = gql`
    query ConsumableGroupsWithCount {
  consumable_group {
    ...ConsumableGroup
    consumables_aggregate {
      aggregate {
        count
      }
    }
  }
}
    ${ConsumableGroupFragmentDoc}`;

export function useConsumableGroupsWithCountQuery(options?: Omit<Urql.UseQueryArgs<IConsumableGroupsWithCountQueryVariables>, 'query'>) {
  return Urql.useQuery<IConsumableGroupsWithCountQuery, IConsumableGroupsWithCountQueryVariables>({ query: ConsumableGroupsWithCountDocument, ...options });
};
export const InsertConsumableGroupDocument = gql`
    mutation InsertConsumableGroup($name: String!) {
  insert_consumable_group_one(object: {name: $name}) {
    ...ConsumableGroup
  }
}
    ${ConsumableGroupFragmentDoc}`;

export function useInsertConsumableGroupMutation() {
  return Urql.useMutation<IInsertConsumableGroupMutation, IInsertConsumableGroupMutationVariables>(InsertConsumableGroupDocument);
};
export const InsertConsumableGroupManyDocument = gql`
    mutation InsertConsumableGroupMany($objects: [consumable_group_insert_input!]!) {
  insert_consumable_group(
    objects: $objects
    on_conflict: {constraint: consumable_group_location_id_name_key, update_columns: []}
  ) {
    returning {
      id
      name
    }
  }
}
    `;

export function useInsertConsumableGroupManyMutation() {
  return Urql.useMutation<IInsertConsumableGroupManyMutation, IInsertConsumableGroupManyMutationVariables>(InsertConsumableGroupManyDocument);
};
export const UpdateConsumableGroupDocument = gql`
    mutation UpdateConsumableGroup($id: uuid!, $group: consumable_group_set_input) {
  update_consumable_group_by_pk(pk_columns: {id: $id}, _set: $group) {
    ...ConsumableGroup
  }
}
    ${ConsumableGroupFragmentDoc}`;

export function useUpdateConsumableGroupMutation() {
  return Urql.useMutation<IUpdateConsumableGroupMutation, IUpdateConsumableGroupMutationVariables>(UpdateConsumableGroupDocument);
};
export const DeleteConsumableGroupDocument = gql`
    mutation DeleteConsumableGroup($id: uuid!) {
  delete_consumable_group_by_pk(id: $id) {
    ...ConsumableGroup
  }
}
    ${ConsumableGroupFragmentDoc}`;

export function useDeleteConsumableGroupMutation() {
  return Urql.useMutation<IDeleteConsumableGroupMutation, IDeleteConsumableGroupMutationVariables>(DeleteConsumableGroupDocument);
};