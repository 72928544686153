import { IWorkOrderTypeEnum } from "@elara/db"
import i18n from "@i18n"

export const translateEntryTypes = (type: IWorkOrderTypeEnum) => {
  const typeMap: Record<IWorkOrderTypeEnum, string> = {
    [IWorkOrderTypeEnum.ReportWork]: i18n.t("tasks:types.report"),
    [IWorkOrderTypeEnum.WorkOrder]: i18n.t("common:task", { count: 1 }),
    [IWorkOrderTypeEnum.State]: i18n.t("tasks:types.state_change"),
    [IWorkOrderTypeEnum.Template]: i18n.t("common:template", { count: 1 }),
  }

  return typeMap[type]
}
