import { IViewDataTypeEnum } from "@elara/db"
import { Data } from "@elara/select"
import { AnyVariables } from "urql"

import { DataViewProps } from "./data-view"
import { DataViewConfiguration } from "./data-view-types"
import ConsumableDataView from "./implementations/consumable-data-view"
import MaintenanceDataView from "./implementations/maintenance-data-view"
import { MeterDataView } from "./implementations/meter-data-view"
import ObjectDataView from "./implementations/object-data-view"
import TaskDataView from "./implementations/task-data-view"

export const DefaultView = (
  props: {
    id: string
    config: DataViewConfiguration<any>
    dataType: IViewDataTypeEnum
  } & Pick<DataViewProps<Data, string, unknown, AnyVariables>, "onCreateView">
) => {
  const { config, id, ...shared } = props
  const sharedProps = {
    configId: id,
    defaultConfig: config,
    customView: null,
    initialValuesForSaveAsCustomView: { shared: true },
    storeAllConfigChangesInCustomView: true,
    ...shared,
  }
  switch (props.dataType) {
    case IViewDataTypeEnum.Workorder:
      return <TaskDataView {...sharedProps} />
    case IViewDataTypeEnum.Asset:
      return <ObjectDataView {...sharedProps} />
    case IViewDataTypeEnum.Consumable:
      return <ConsumableDataView {...sharedProps} />
    case IViewDataTypeEnum.Maintenance:
      return <MaintenanceDataView {...sharedProps} />
    case IViewDataTypeEnum.Meter:
      return <MeterDataView {...sharedProps} />

    default:
      return <>{null}</>
  }
}

export default DefaultView
