import { SubpageLayout } from "@components/page/subpage-layout"
import i18n from "@i18n"

import { useAsset } from "./$id"

const AssetOverview = () => {
  const context = useAsset()
  const pages = [
    { path: "general", label: i18n.t("common:general") },
    { path: "documents", label: i18n.t("common:document_other") },
    { path: "operating-schedule", label: i18n.t("assets:operating_schedule.title") },
    { path: "qr", label: i18n.t("common:qr_code") },
  ]
  return <SubpageLayout pages={pages} context={context} />
}

export default AssetOverview
