"use client"

import { CaretDown, Check } from "@phosphor-icons/react"
import * as SelectPrimitive from "@radix-ui/react-select"
import { cn } from "@utils"
import * as React from "react"

const SelectRoot = SelectPrimitive.Root

const SelectGroup = SelectPrimitive.Group

const SelectValue = SelectPrimitive.Value

type SelectTriggerProps = React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  SelectTriggerProps
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={cn(
      "w-full flex items-center justify-between px-2 text-sm border rounded cursor-default transition-colors text-gray-700",
      "bg-white border-gray-200 hover:border-gray-400 active:border-gray-600",
      "min-h-[32px] min-w-[32px] px-[8px] py-[1px] text-sm",
      "group disabled:bg-gray-100",
      className
    )}
    {...props}>
    {children}

    <CaretDown size={14} className="ml-1.5 opacity-75 group-disabled:hidden" />
  </SelectPrimitive.Trigger>
))

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={cn(
        "animate-in fade-in-80 relative z-50 min-w-[8rem] overflow-hidden rounded-md border border-gray-100 bg-white/70 backdrop-blur-md text-gray-700 shadow-md",
        className
      )}
      {...props}>
      <SelectPrimitive.Viewport className="p-1">{children}</SelectPrimitive.Viewport>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
))

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    ref={ref}
    className={cn("py-1.5 pr-2 pl-8 text-sm font-semibold text-gray-900 ", className)}
    {...props}
  />
))

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(
      "relative flex cursor-default select-none items-center text-gray-700 rounded-sm py-1.5 pr-2 pl-8 text-sm outline-none focus:bg-gray-100/70 data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      className
    )}
    {...props}>
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <SelectPrimitive.ItemIndicator>
        <Check size={14} />
      </SelectPrimitive.ItemIndicator>
    </span>

    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
))

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={cn("-mx-1 my-1 h-px bg-gray-100 ", className)}
    {...props}
  />
))

type Item = { value: string; label: React.ReactNode; group?: string }
type Group = { name: string | null; items: Item[] }

export type SelectProps = React.PropsWithChildren<
  {
    placeholder?: string
    items: Item[]
    value?: string
    onValueChange?: (value: string) => void
    disabled?: boolean
    open?: boolean
    onOpenChange?: (open: boolean) => void
    onBlur?: React.FocusEventHandler<HTMLButtonElement> | undefined
    contentProps?: SelectPrimitive.SelectContentProps
  } & Omit<SelectTriggerProps, "onChange">
>

const Select = ({
  value,
  onValueChange,
  open,
  onOpenChange,
  items,
  placeholder,
  children,
  contentProps,
  ...props
}: SelectProps) => {
  function groupItems(items: Item[]): Group[] {
    const groupedItems: { [group: string]: Item[] } = {}
    const ungroupedItems: Item[] = []

    items.forEach((option) => {
      if (option.group) {
        if (!groupedItems[option.group]) {
          groupedItems[option.group] = []
        }
        groupedItems[option.group].push(option)
      } else {
        ungroupedItems.push(option)
      }
    })

    const groups: Group[] = Object.keys(groupedItems).map((group) => ({
      name: group,
      items: groupedItems[group],
    }))

    return [...groups, { name: null, items: ungroupedItems }]
  }

  return (
    <SelectRoot
      value={value}
      onValueChange={onValueChange}
      open={open}
      onOpenChange={onOpenChange}>
      <SelectTrigger
        {...props}
        className={cn("data-[placeholder]:text-gray-500", props.className)}>
        <SelectValue placeholder={placeholder}>{children}</SelectValue>
      </SelectTrigger>

      <SelectContent {...contentProps}>
        {groupItems(items).map((group) => (
          <SelectGroup key={group.name}>
            {group.name && (
              <SelectLabel className="-ml-3 text-xs">{group.name}</SelectLabel>
            )}

            {group.items.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectGroup>
        ))}
      </SelectContent>
    </SelectRoot>
  )
}

export {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectRoot,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
}
