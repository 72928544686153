import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react"

const queries = {
  mobile: "(max-width: 639px)",
  portrait: "(orientation: portrait)",
  sm: "(min-width: 640px)",
  md: "(min-width: 768px)",
  lg: "(min-width: 1024px)",
  xl: "(min-width: 1280px)",
  "2xl": "(min-width: 1536px)",
}
export type Device = "desktop" | "tablet" | "mobile"
type Queries = typeof queries
type MediaQueryResult = {
  [P in keyof Queries]: MediaQueryList
}
type MediaQueryValues = {
  [P in keyof Queries]: boolean
}
type BreakpointValues = MediaQueryValues & {
  tablet: boolean
  renderMobile: boolean
  renderDesktop: boolean
  isDesktopForced: boolean
  forceDesktop: (desktopForced: boolean) => void
  isTouchDevice: boolean
  device: Device
}

const defaultQueryValues = Object.fromEntries(
  Object.keys(queries).map((key) => [key, false])
) as MediaQueryValues

const checkIfTouchDevice = () => {
  return (
    navigator.maxTouchPoints > 0 ||
    navigator.platform === "iPad" ||
    navigator.platform === "iPhone" ||
    navigator.platform === "Android"
  )
}

function isDesktopForcedStorage() {
  return localStorage?.getItem("FORCE_DESKTOP") === "true"
}

function setDesktopForcedStorage(forced: boolean) {
  localStorage?.setItem("FORCE_DESKTOP", `${forced}`)
}

const BreakpointContext = createContext<BreakpointValues>(undefined!)

export const BreakpointProvider = (props: PropsWithChildren<{}>) => {
  const [queryMatch, setQueryMatch] = useState<MediaQueryValues>(defaultQueryValues)
  const [isDesktopForced, setDesktopForced] = useState(isDesktopForcedStorage())
  const forceDesktop = React.useCallback((force: boolean) => {
    setDesktopForcedStorage(force)
    setDesktopForced(force)
  }, [])

  const checkIfTablet = React.useCallback(() => {
    return false
  }, [])

  const [tablet, setTablet] = useState(checkIfTablet())
  useEffect(() => {
    setTablet(checkIfTablet())
  }, [isDesktopForced])

  // Setup media queries
  useLayoutEffect(() => {
    const mediaQueryLists = {} as MediaQueryResult
    const keys = Object.keys(queries) as (keyof Queries)[]
    let isAttached = false

    const handleQueryListener = () => {
      const updatedMatches = keys.reduce((acc: MediaQueryValues, media: keyof Queries) => {
        acc[media] = !!(mediaQueryLists[media] && mediaQueryLists[media]?.matches)
        return acc
      }, {} as MediaQueryValues)
      setQueryMatch((matches) => ({ ...matches, ...updatedMatches }))
    }

    const matches = {} as MediaQueryValues
    if (window && window.matchMedia) {
      keys.forEach((media) => {
        mediaQueryLists[media] = window.matchMedia(queries[media])
        matches[media] = !!mediaQueryLists[media]?.matches
      })

      isAttached = true
      keys.forEach((media) => {
        mediaQueryLists[media]?.addListener(handleQueryListener)
      })
    }
    setQueryMatch({ ...defaultQueryValues, ...matches })

    return () => {
      if (isAttached) {
        keys.forEach((media) => {
          mediaQueryLists[media]?.removeListener(handleQueryListener)
        })
      }
    }
  }, [queries])

  const isTouchDevice = checkIfTouchDevice()

  const value = React.useMemo(() => {
    const renderMobile = queryMatch.mobile || tablet
    const renderDesktop = !renderMobile

    let device: Device = "desktop"
    if (queryMatch.mobile) {
      device = "mobile"
    } else if (tablet) {
      device = "tablet"
    }

    return {
      ...queryMatch,
      device,
      tablet,
      renderMobile,
      renderDesktop,
      isDesktopForced,
      forceDesktop,
      isTouchDevice,
    }
  }, [queryMatch, tablet, isDesktopForced, isTouchDevice])

  return (
    <BreakpointContext.Provider value={value}>{props.children}</BreakpointContext.Provider>
  )
}

export function useBreakpoint() {
  const value = useContext(BreakpointContext)
  return value
}
