import { alertDialog } from "@components/shared/alert-dialog-provider"
import { getUser, useUser } from "@contexts/user-context"
import {
  IMarkNotificationAsReadMutation,
  IMarkNotificationAsReadMutationVariables,
  MarkNotificationAsReadDocument,
} from "@graphql/documents/notification.generated"
import {
  IUpdateUserSettingsMutation,
  IUpdateUserSettingsMutationVariables,
  UpdateUserSettingsDocument,
} from "@graphql/documents/user.generated"
import { useSSOInfo } from "@hooks/use-sso-info"
import { useSubdomain } from "@hooks/use-subdomain"
import i18n from "@i18n"
import merge from "deepmerge"
import React from "react"
import { useEffect } from "react"
import OneSignal from "react-onesignal"
import { useClient } from "urql"

export const disablePushNotifications = async () => {
  await OneSignal.setSubscription(false)
}

// Returns true if granted
const askForPushPermissions = async () => {
  const permission = await OneSignal.getNotificationPermission()
  if (permission === "default") {
    // Show a dialog to subscribe to push notifications, if provided
    return await alertDialog({
      title: i18n.t("settings:push.permission_dialog.title"),
      description: i18n.t("settings:push.permission_dialog.description"),
      actionText: i18n.t("allow"),
      cancelText: i18n.t("cancel"),
      onAction: async () => {
        await OneSignal.registerForPushNotifications()
        await OneSignal.setSubscription(true)
      },
    })
  } else if (permission === "denied") {
    return false
  } else {
    return true
  }
}

export function useAskForPushPermissions() {
  useEffect(() => {
    const user = getUser()
    if (!user.profile.settings.notifications.channel.push) return

    askForPushPermissions()
  }, [])
}

export function usePushToggle() {
  const user = useUser()
  const { settings } = user.profile
  const client = useClient()
  const isPushEnabled = settings.notifications.channel.push
  const [isPushBlocked, setIsPushBlocked] = React.useState(false)

  useEffect(() => {
    OneSignal.getNotificationPermission().then((permission) => {
      if (permission === "denied") {
        setIsPushBlocked(true)
      } else {
        setIsPushBlocked(false)
      }
    })
  }, [])

  const toggle = async (enablePush: boolean) => {
    const newSettings = merge(settings, {
      notifications: { channel: { push: enablePush } },
    })

    if (enablePush) {
      const permissionGranted = await askForPushPermissions()

      if (permissionGranted) {
        await client
          .mutation<IUpdateUserSettingsMutation, IUpdateUserSettingsMutationVariables>(
            UpdateUserSettingsDocument,
            {
              id: user.id,
              settings: newSettings,
            }
          )
          .toPromise()
      }
    } else {
      await client
        .mutation<IUpdateUserSettingsMutation, IUpdateUserSettingsMutationVariables>(
          UpdateUserSettingsDocument,
          {
            id: user.id,
            settings: newSettings,
          }
        )
        .toPromise()
      await OneSignal.setSubscription(false)
    }
  }

  return { isPushEnabled, togglePush: toggle, isPushBlocked }
}

export function useOneSignal() {
  const user = useUser()
  const client = useClient()

  const subdomain = useSubdomain()
  const ssoInfoQuery = useSSOInfo()

  const initializeOneSignal = async () => {
    if (subdomain && !ssoInfoQuery.data) return

    const isProduction = import.meta.env.PROD
    const appId =
      (subdomain && ssoInfoQuery.data?.oneSignalAppId) ??
      import.meta.env.VITE_ONESIGNAL_APP_ID

    if (!appId) return

    await OneSignal.init({
      appId,
      allowLocalhostAsSecureOrigin: !isProduction,
      serviceWorkerParam: { scope: "/push/onesignal/" },
      serviceWorkerPath: "push/onesignal/OneSignalSDKWorker.js",
      serviceWorkerUpdaterPath: "push/onesignal/OneSignalSDKUpdaterWorker.js",
    })

    const handleNotification = async (notification: Notification) => {
      if (notification.data.id) {
        await client
          .mutation<
            IMarkNotificationAsReadMutation,
            IMarkNotificationAsReadMutationVariables
          >(MarkNotificationAsReadDocument, {
            ids: [notification.data.id],
            now: new Date().toISOString(),
          })
          .toPromise()
      }

      await OneSignal.addListenerForNotificationOpened(handleNotification)
    }

    await OneSignal.setExternalUserId(user.id)

    const permission = await OneSignal.getNotificationPermission()

    if (permission === "granted" && user.profile.settings.notifications.channel.push) {
      await OneSignal.setSubscription(true)
    } else {
      await OneSignal.setSubscription(false)
    }

    await OneSignal.addListenerForNotificationOpened(handleNotification)
  }

  useEffect(() => {
    initializeOneSignal()
  }, [subdomain, ssoInfoQuery.data])
}
