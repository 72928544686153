import { VStack } from "@components/layout"
import { Button, Icons } from "@components/shared"
import { RichTextFormController } from "@components/shared/form/rich-text-form-controller"
import { StaticRichText } from "@components/shared/static-rich-text"
import Uploads from "@components/shared/uploads"
import { BlockElementMedia } from "@elara/db"
import i18n from "@i18n"

import { useResponseUploads } from "./block-element-hooks"
import { BlockElementFormProps, BlockElementProps } from "./block-element-types"

export const MediaForm = (props: BlockElementFormProps) => (
  <VStack space={8} flex="1">
    <div>
      <RichTextFormController
        className="max-h-36"
        control={props.control}
        name={`${props.field}.${props.index}.config.description`}
        placeholder={i18n.t("tasks:checklist.media.placeholder")}
        noStyle
      />
    </div>
    <div>
      <Button disabled size="small" type="tertiary" icon={Icons.Image}>
        {i18n.t("common:upload_files")}
      </Button>
    </div>
  </VStack>
)

export const MediaBlockElement = (props: BlockElementProps<BlockElementMedia>) => {
  const { addUpload, deleteUpload, scope } = useResponseUploads(props.element.id, {
    blockResponseUpdate: props.blockResponseUpdate,
  })

  return (
    <div className="relative flex w-full flex-1 flex-col items-start">
      <StaticRichText content={props.element.config.description} className="mb-2" />
      <Uploads
        addUploadButtonProps={{ icon: Icons.TakePicture }}
        onUploadFinished={async (u) => {
          await addUpload(u)
          props.updateUploads?.(
            props.element.id,
            props.element.uploads.map((u) => u.upload).concat([u])
          )
        }}
        onDelete={async (id) => {
          await deleteUpload(id)
          props.updateUploads?.(
            props.element.id,
            props.element.uploads.map((u) => u.upload).filter((u) => u.id !== id)
          )
        }}
        placeholderLabel={i18n.t("common:upload_files")}
        boundDropzoneToContainer={false}
        uploads={props.element.uploads.map((u) => u.upload)}
        scope={scope}
        placeholderType="zone"
      />
    </div>
  )
}
