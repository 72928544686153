import { AssetWithParents } from "@components/asset/asset-with-parents"
import { ConsumableStorageLocation } from "@components/consumable/consumable-storage-location"
import { Button } from "@components/shared"
import { alertDialog } from "@components/shared/alert-dialog-provider"
import { currencySymbol } from "@components/shared/currency"
import RadioGroupPanel from "@components/shared/radio-group-panel"
import { TextInputWithUnit } from "@components/shared/text-input-with-unit"
import toast from "@components/shared/toast"
import { Tooltip } from "@components/shared/tooltip"
import { useUser } from "@contexts/user-context"
import {
  IUpdateConsumableLogsInTaskMutation,
  IUpdateConsumableLogsInTaskMutationVariables,
  UpdateConsumableLogsInTaskDocument,
} from "@graphql/documents/consumable.generated"
import {
  ITaskConsumableUsedFragment,
  IWorkOrderFragment,
} from "@graphql/documents/fragments.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { Check, MapPin, PencilSimple, Shapes, TrashSimple, X } from "@phosphor-icons/react"
import * as Collapsible from "@radix-ui/react-collapsible"
import { Controller, useWatch } from "react-hook-form"
import { useForm } from "react-hook-form"
import { useClient } from "urql"

import {
  DeleteMaterialLogDocument,
  IDeleteMaterialLogMutation,
  IDeleteMaterialLogMutationVariables,
} from "./actions.generated"

export const MaterialInlineForm = (props: {
  log: ITaskConsumableUsedFragment
  task: IWorkOrderFragment
  onDiscard: () => void
  onSave: () => void
}) => {
  const { log, task } = props
  const client = useClient()
  const user = useUser()

  const form = useForm({
    defaultValues: {
      log_id: log.id,
      consumable: log.consumable!,
      place_id: log.place_id,
      quantity: -log.adjustment,
      asset_id: log.asset_id,
      cost_per_unit: log.cost_per_unit ?? log.consumable?.cost ?? 0,
    },
  })
  const scope = usePermissionScope(IPermissionScopeEnum.AppDataEntry)

  const assets = props.task.assets.map((a) => a.asset)
  const validAssetIds = props.task.assets.map((a) => a.asset_id)
  const ensureValidAssetId = (asset_id: string | null) => {
    if (asset_id && validAssetIds.includes(asset_id)) return asset_id
    return validAssetIds?.[0] ?? null
  }

  const onSubmit = form.handleSubmit(async (values) => {
    const res = await client
      .mutation<
        IUpdateConsumableLogsInTaskMutation,
        IUpdateConsumableLogsInTaskMutationVariables
      >(
        UpdateConsumableLogsInTaskDocument,
        {
          logs: [
            {
              id: values.log_id,
              adjustment: -values.quantity,
              place_id: values.place_id === "no_value" ? null : values.place_id,
              consumable_id: values.consumable?.id,
              asset_id: ensureValidAssetId(values.asset_id),
              task_id: task.id,
              cost_per_unit: values.cost_per_unit,
            },
          ],
          logsToDelete: [],
          taskId: task.id,
        },
        scope.context()
      )
      .toPromise()
    if (res.error) {
      toast.error(i18n.t("common:generic_toast_error"))
    } else {
      props.onSave()
    }
  })

  const deleteLog = () => {
    alertDialog({
      danger: true,
      title: i18n.t("tasks:report.material.delete_log.title"),
      description: i18n.t("tasks:report.material.delete_log.description"),
      actionText: i18n.t("tasks:report.material.delete_log.action"),
      onAction: async () => {
        const res = await client
          .mutation<IDeleteMaterialLogMutation, IDeleteMaterialLogMutationVariables>(
            DeleteMaterialLogDocument,
            { id: log.id },
            scope.context()
          )
          .toPromise()

        if (res.error) {
          toast.error(i18n.t("common.generic_toast_error"))
          throw res.error
        }
      },
    })
  }

  const placeId = useWatch({ control: form.control, name: `place_id` })
  const assetId = useWatch({ control: form.control, name: `asset_id` })

  const storageLocations = log.consumable?.storage_locations ?? []
  const nStorageLocations = storageLocations.length
  const allowToChangeStorageLocation =
    nStorageLocations > 1 ||
    (nStorageLocations == 1 && storageLocations[0]?.place_id !== placeId)

  const place = nStorageLocations > 0 && (
    <Collapsible.Root className="group">
      <div className="flex items-center text-sm group-radix-state-open:invisible">
        <ConsumableStorageLocation
          // Need to overwrite place_id to make it work since c.place_id is stale
          storageLocation={storageLocations.find((c) => c.place_id === placeId) ?? null}
          renderPlaceholder={() => (
            <span className="inline-flex items-center text-gray-500">
              <MapPin size={14} weight="fill" className="mr-1 text-gray-400 " />
              {i18n.t("consumables:labels.assigned_to_no_location")}
            </span>
          )}
        />
        {allowToChangeStorageLocation && (
          <Collapsible.Trigger asChild>
            <Button
              type="tertiary"
              icon={PencilSimple}
              color="gray"
              size="extra-small"
              className="-my-1 ml-1">
              {/* {i18n.t("common:change")} */}
            </Button>
          </Collapsible.Trigger>
        )}
        <div className="flex-1" />
      </div>

      <Collapsible.Content
        className="-mb-6 mt-2 box-border -translate-y-8 space-y-2 overflow-hidden rounded-lg bg-gray-100 
                p-3 radix-state-closed:animate-radix-slide-up radix-state-open:animate-radix-slide-down">
        <div className="flex items-center justify-between">
          <div className="text-sm text-gray-500">
            {i18n.t("consumables:labels.assigned_to_storage_location")}
          </div>
          <Collapsible.Trigger asChild>
            <Button type="tertiary" icon={X} color="gray" className="ml-1">
              {i18n.t("common:close")}
            </Button>
          </Collapsible.Trigger>
        </div>
        <Controller
          control={form.control}
          name={`place_id` as const}
          render={({ field }) => (
            <RadioGroupPanel
              value={field.value || "no_value"}
              onValueChange={field.onChange}
              options={
                storageLocations
                  .map((c) => ({
                    value: c.place.id,
                    label: c.place.name,
                    description: c.area ?? "",
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label)) ?? []
              }
            />
          )}
        />
      </Collapsible.Content>
    </Collapsible.Root>
  )
  const assignedAsset = assets.find((a) => a.id === assetId)
  const showAsset = assets.length >= 1

  const asset = showAsset && (
    <Collapsible.Root className="group">
      <div className="flex items-center text-sm group-radix-state-open:invisible">
        <Tooltip
          content={i18n.t("consumables:messages.assigned_object_tooltip")}
          contentProps={{ align: "start" }}>
          <Shapes className="mr-1 text-gray-400" />
        </Tooltip>
        <span className="text-gray-700">
          {assignedAsset?.name ?? (
            <span className="text-gray-500">
              {i18n.t("consumables:labels.assigned_to_no_asset")}
            </span>
          )}
        </span>
        <Collapsible.Trigger asChild>
          <Button
            type="tertiary"
            icon={PencilSimple}
            color="gray"
            size="extra-small"
            className="-my-1 ml-1">
            {/* {i18n.t("common:change")} */}
          </Button>
        </Collapsible.Trigger>
      </div>

      <Collapsible.Content
        className="-mb-6 mt-2 box-border -translate-y-8 space-y-2 overflow-hidden rounded-lg bg-gray-100 
                p-3 radix-state-closed:animate-radix-slide-up radix-state-open:animate-radix-slide-down">
        <div className="flex items-center justify-between">
          <div className="text-sm font-medium text-gray-500">
            {i18n.t("consumables:labels.assigned_to_object")}
          </div>
          <Collapsible.Trigger asChild>
            <Button size="small" color="gray" type="tertiary" className="ml-1" icon={X}>
              {i18n.t("common:close")}
            </Button>
          </Collapsible.Trigger>
        </div>
        <Controller
          control={form.control}
          name={`asset_id` as const}
          render={({ field }) => (
            <RadioGroupPanel
              value={field.value || "no_value"}
              onValueChange={field.onChange}
              options={
                assets
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((asset) => ({
                    value: asset.id,
                    label: <AssetWithParents asset={asset} showAvatar />,
                    description: "",
                  })) ?? []
              }
            />
          )}
        />
      </Collapsible.Content>
    </Collapsible.Root>
  )

  const { consumable } = log

  if (!consumable) return null

  return (
    <>
      <div className="flex flex-wrap items-center gap-2 text-sm">
        <div className="grow">
          <span className="break-all font-medium">{consumable.name}</span>
          <span className="ml-1 text-gray-500"> {consumable.public_id}</span>
        </div>
        <TextInputWithUnit
          unit={consumable.unit ?? ""}
          {...form.control.register(`quantity`)}
          type="number"
          step="any"
          className="grow basis-1/3 sm:grow-0 sm:basis-28"
        />
        <TextInputWithUnit
          {...form.control.register(`cost_per_unit`, {
            valueAsNumber: true,
          })}
          unit={`${currencySymbol(user.location.settings.currency)} / ${consumable.unit}`}
          type="number"
          step="0.01"
          className="grow basis-1/3 sm:grow-0 sm:basis-36"
        />
        <Button
          color="gray"
          type="tertiary"
          size="extra-small"
          icon={X}
          onClick={props.onDiscard}
        />
        <Button
          color="gray"
          type="tertiary"
          size="extra-small"
          icon={Check}
          onClick={onSubmit}
        />
      </div>
      {place}
      {asset}
      <Button
        color="gray"
        type="tertiary"
        size="extra-small"
        icon={TrashSimple}
        onClick={deleteLog}>
        {i18n.t("common:delete")}
      </Button>
    </>
  )
}
