import { Column, GroupBySummaryItem } from "@components/new-data-view/data-view-types"
import { Button } from "@components/shared"
import { ContactTag } from "@components/shared/contact-tag"
import { ContextMenuItem, ContextMenuSeparator } from "@components/shared/context-menu"
import { CoreDataViewHandle } from "@components/shared/data-view/core-data-view"
import {
  createSchema,
  createSchemaStatement,
  selectStateToWhere,
} from "@components/shared/data-view/data-view-filter.hooks"
import {
  DataViewFilterDatePicker,
  useDataViewFilterDatePicker,
} from "@components/shared/data-view/data-view-filter-date-picker"
import { useFeature } from "@contexts/feature-flag-context"
import { IPermissionScopeEnum, IViewDataTypeEnum, uuid } from "@elara/db"
import { orderBy, Where } from "@elara/select"
import { IAssetManufacturersQuery } from "@graphql/documents/asset.generated"
import {
  AssetGroupsDocument,
  IAssetGroupsQuery,
} from "@graphql/documents/asset-group.generated"
import { AssetManufacturersDocument } from "@graphql/documents/asset-manufacturer.generated"
import {
  AssetPlacesDocument,
  IAssetPlacesQuery,
} from "@graphql/documents/asset-place.generated"
import {
  AssetStateVariantsDocument,
  IAssetStateVariantsQuery,
} from "@graphql/documents/asset-state.generated"
import { IAssetDataViewFragment } from "@graphql/documents/fragments.generated"
import { useCallbackRef, useDisclosure, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import {
  ArrowsIn,
  ArrowsInLineVertical,
  ArrowsOut,
  ArrowsOutLineVertical,
  Pencil,
  Plus,
  Shapes,
} from "@phosphor-icons/react"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import { getFormattedDate, naturalCompare, parseDate } from "@utils"
import { useNavigateWithBackgroundLocation } from "@utils/location"
import { findTreeList, forEachTree, forEachTreeList } from "@utils/tree"
import React, { useCallback, useMemo, useRef, useState } from "react"

import {
  DataView,
  DataViewConfiguration,
  DataViewProps,
} from "../shared/data-view/data-view"
import { AssetActiveTasksBadge } from "./asset-active-tasks-badge"
import { AssetCreateFormDialog } from "./asset-create-form-dialog"
import {
  AssetGroupSummaryDocument,
  IAssetGroupSummaryQuery,
  IAssetGroupSummaryQueryVariables,
} from "./asset-data-view/queries.generated"
import { AssetDataViewListItem } from "./asset-data-view-list-item"
import { AssetRenameAction } from "./asset-rename-action"
import { CurrentState } from "./asset-state-detail"
import AssetStateVariantLabel, {
  textForAssetStateVariantLabel,
} from "./asset-state-variant-label"

type CustomAssetFields = null | { name: string; value: string }[]

export type AssetViewOptions = {}

export type AssetViewIds =
  | "activeWorkOrders"
  | "archivedAt"
  | "assetGroup"
  | "lastState"
  | "manufacturer"
  | "modelNumber"
  | "name"
  | "place"
  | "publicId"
  | "serialNumber"
  | "state_description"
  | "support"

type ContextMenuAction = { asset: IAssetDataViewFragment } & { type: "rename" }

export const defaultAssetColumns = (
  showPublicId: boolean
): Column<IAssetDataViewFragment, AssetViewIds, AssetViewOptions>[] => [
  {
    Header: i18n.t("assets:fields.name"),
    id: "name",
    defaultWidth: 300,
    toText: (row) => row.name,
    orderBy: (dir) => ({ name: dir }),
    Cell: (row) => (
      <span className="line-clamp-2 break-all font-medium">
        <span>{row.name}</span>
        {showPublicId && <span className="ml-1.5 opacity-50">{row.public_id}</span>}
      </span>
    ),
    searchQuery: (value) => ({
      _or: [{ name: { _ilike: `%${value}%` } }, { public_id: { _ilike: `%${value}%` } }],
    }),
  },
  {
    Header: i18n.t("assets:fields.state"),
    id: "lastState",
    toText: (row) =>
      textForAssetStateVariantLabel(
        (row.current_state as CurrentState).asset_state_variant
      ),
    Cell: (row) => (
      <AssetStateVariantLabel
        size="small"
        variant={(row.current_state as CurrentState).asset_state_variant}
      />
    ),
    orderBy: (dir) => ({
      dir,
      transform(row) {
        return textForAssetStateVariantLabel(
          (row.current_state as CurrentState).asset_state_variant
        )
      },
    }),
    groupBy: {
      id: (row) => (row.current_state as CurrentState).asset_state_variant.id,
      label: (_, row) =>
        row && (
          <AssetStateVariantLabel
            size="small"
            variant={(row.current_state as CurrentState).asset_state_variant}
          />
        ),
      orderValue(_, row) {
        if (!row) return ""
        return textForAssetStateVariantLabel(
          (row.current_state as CurrentState).asset_state_variant
        )
      },
    },
  },
  {
    Header: i18n.t("assets:fields.state_description"),
    id: "state_description",
    toText: (row) => (row.current_state as CurrentState).note ?? "",
    Cell: (row) => (row.current_state as CurrentState).note,
  },
  {
    Header: i18n.t("assets:fields.group", { count: 1 }),
    id: "assetGroup",
    Cell: (row) => row.group?.name ?? "",
    toText: (row) => row.group?.name ?? "",
    orderBy: (dir) => ({ group: { name: dir } }),
    groupBy: {
      id: (row) => (row.group?.id ? [row.group.id] : null),
      label: (_, row) =>
        row?.group?.name ??
        i18n.t("common:without_token", {
          token: i18n.t("assets:fields.group", { count: 1 }),
        }),
    },
    groupBySummary: (client, where) => {
      return client
        .query<IAssetGroupSummaryQuery, IAssetGroupSummaryQueryVariables>(
          AssetGroupSummaryDocument,
          { where: where as any }
        )
        .toPromise()
        .then((res) => {
          const groups =
            res.data?.asset_group
              .map(
                (g) =>
                  ({
                    id: g.id,
                    label: g.name,
                    size: g.assets_aggregate.aggregate?.count ?? 0,
                    where: { group_id: { _eq: g.id } } as Where<IAssetDataViewFragment>,
                  } satisfies GroupBySummaryItem<IAssetDataViewFragment>)
              )
              ?.sort((a, b) => naturalCompare(a.label, b.label)) ?? []
          const withoutGroup = {
            id: "without_group",
            label: i18n.t("common:without_group"),
            size: res.data?.asset_aggregate.aggregate?.count ?? 0,
            where: { group_id: { _is_null: true } },
          } satisfies GroupBySummaryItem<IAssetDataViewFragment>
          return groups.concat([withoutGroup])
        })
    },
  },
  {
    Header: i18n.t("assets:fields.location", { count: 1 }),
    id: "place",
    Cell: (row) => row.place?.name,
    toText: (row) => row.place?.name ?? "",
    orderBy: (dir) => ({ place: { name: dir } }),
    groupBy: {
      id: (row) => (row.place?.id ? [row.place.id] : null),
      label: (_, row) =>
        row?.place?.name ??
        i18n.t("common:without_token", {
          token: i18n.t("assets:fields.location", { count: 1 }),
        }),
    },
  },
  {
    Header: i18n.t("assets:fields.manufacturer"),
    id: "manufacturer",
    Cell: (row) => row.manufacturer?.name,
    toText: (row) => row.manufacturer?.name ?? "",
    orderBy: (dir) => ({ manufacturer: { name: dir } }),
    groupBy: {
      id: (row) => (row.manufacturer?.id ? [row.manufacturer.id] : null),
      label: (_, row) =>
        row?.manufacturer?.name ??
        i18n.t("common:without_token", { token: i18n.t("assets:fields.manufacturer") }),
    },
  },
  {
    Header: i18n.t("assets:fields.serial_number"),
    id: "serialNumber",
    Cell: (row) => row.serial_number ?? "",
    toText: (row) => row.serial_number ?? "",
    orderBy: (dir) => ({ serial_number: dir }),
  },
  {
    Header: i18n.t("assets:fields.public_id"),
    id: "publicId",
    Cell: (row) => row.public_id,
    toText: (row) => row.public_id,
    orderBy: (dir) => ({ public_id: dir }),
  },
  {
    Header: i18n.t("assets:fields.model_number"),
    id: "modelNumber",
    Cell: (row) => row.model_number ?? "",
    toText: (row) => row.model_number ?? "",
    orderBy: (dir) => ({ model_number: dir }),
  },
  {
    Header: i18n.t("assets:fields.support"),
    id: "support",
    toText: (row) =>
      row.support_contacts
        ?.map((c) => `${c.name} (${c.company}) - ${c.phone || c.email}`)
        .join("\n") ?? "",
    Cell: (row) => {
      return (
        <div className="flex flex-wrap gap-2">
          {row.support_contacts?.map((contact) => (
            <ContactTag compact key={contact.id} contact={contact} />
          ))}
        </div>
      )
    },
  },
  {
    Header: i18n.t("assets:fields.active_tasks"),
    id: "activeWorkOrders",
    defaultSortDirection: "desc",
    toText: (row) => (row.active_work_orders?.length ?? 0).toFixed(0),
    summationValue: (row) => row.active_work_orders?.length ?? 0,
    orderBy: (dir) => ({
      dir,
      transform(asset) {
        return asset.active_work_orders?.length ?? 0
      },
    }),
    Cell: (row) => {
      return <AssetActiveTasksBadge asset={row} />
    },
  },
  {
    Header: i18n.t("assets:fields.archived_at"),
    id: "archivedAt",
    defaultSortDirection: "desc",
    orderBy: (dir) => ({ archived_at: dir }),
    Cell: (row) => getFormattedDate(parseDate(row.archived_at)),
    toText: (row) => getFormattedDate(parseDate(row.archived_at)),
  },
]

type DVProps = DataViewProps<IAssetDataViewFragment, AssetViewIds, AssetViewOptions>

type AssetDataViewProps = Omit<
  DVProps,
  | "columnDefinitions"
  | "dataType"
  | "list_item"
  | "defaultConfig"
  | "filter"
  | "onSelect"
  | "dataId"
  | "dataSearchValue"
  | "alwaysAppliedFixerFilters"
> & {
  onCreateWorkOrder?: (assetId: uuid) => void
  archive?: boolean
} & Partial<
    Pick<DVProps, "dataType"> & {
      defaultConfig: Partial<DVProps["defaultConfig"]>
    }
  >

export const useAssetFilterSchema = () => {
  const datePicker = useDataViewFilterDatePicker()

  const schema = useMemo(() => {
    return createSchema<IAssetDataViewFragment>({
      active_work_order: createSchemaStatement<IAssetDataViewFragment, string>({
        type: "singleton",
        label: i18n.t("assets:fields.active_tasks"),
        badgeLabel: i18n.t("common:asset", { count: 1 }),
        operatorLabel: (negated) =>
          negated ? i18n.t("data-view:filters.has_no") : i18n.t("data-view:filters.has"),
        toWhere: (state) => {
          const cond = {
            active_work_orders: {
              work_order: { id: {} },
            },
          }
          return state.negated ? { _not: cond } : cond
        },
      }),
      lastState: createSchemaStatement<IAssetDataViewFragment, string>({
        type: "select",
        label: i18n.t("assets:fields.current_state"),
        getItems: async (client) => {
          const queryRes = await client
            .query<IAssetStateVariantsQuery>(
              AssetStateVariantsDocument,
              {},
              {
                requestPolicy: "cache-first",
              }
            )
            .toPromise()
          const states = queryRes?.data?.asset_state_variant
          if (!states) return null
          return orderBy(
            states.map((a) => ({
              value: a.id,
              label: textForAssetStateVariantLabel(a),
              searchValue: textForAssetStateVariantLabel(a),
            })),
            { searchValue: "asc" }
          )
        },
        toWhere: (state) => ({
          current_state: { asset_state_variant_id: selectStateToWhere(state) },
        }),
      }),
      assetGroup: createSchemaStatement<IAssetDataViewFragment, string>({
        type: "select",
        label: i18n.t("assets:fields.group", { count: 1 }),
        multiSelectedLabel: i18n.t("assets:fields.group", { count: 2 }),
        toWhere: (state) => ({ group_id: selectStateToWhere(state) }),
        getItems: async (client) => {
          const queryRes = await client
            .query<IAssetGroupsQuery>(
              AssetGroupsDocument,
              {},
              {
                requestPolicy: "cache-first",
              }
            )
            .toPromise()
          const groups = queryRes?.data?.asset_group
          if (!groups) return null
          return orderBy(groups, { name: "asc" }).map((a) => ({
            label: a.name,
            value: a.id,
            searchValue: a.name,
          }))
        },
      }),
      manufacturer: createSchemaStatement<IAssetDataViewFragment, string>({
        type: "select",
        label: i18n.t("assets:fields.manufacturer"),
        toWhere: (state) => ({ manufacturer: { id: selectStateToWhere(state) } }),
        getItems: async (client) => {
          const queryRes = await client
            .query<IAssetManufacturersQuery>(
              AssetManufacturersDocument,
              {},
              {
                requestPolicy: "cache-first",
              }
            )
            .toPromise()
          const manufacturers = queryRes?.data?.asset_manufacturer
          if (!manufacturers) return null
          return orderBy(manufacturers, { name: "asc" }).map((a) => ({
            label: a.name,
            value: a.id,
            searchValue: a.name,
          }))
        },
      }),
      place: createSchemaStatement<IAssetDataViewFragment, string>({
        type: "select",
        label: i18n.t("assets:fields.location", { count: 1 }),
        multiSelectedLabel: i18n.t("assets:fields.location", { count: 2 }),
        toWhere: (state) => ({ place: { id: selectStateToWhere(state) } }),
        getItems: async (client) => {
          const queryRes = await client
            .query<IAssetPlacesQuery>(
              AssetPlacesDocument,
              {},
              {
                requestPolicy: "cache-first",
              }
            )
            .toPromise()
          const groups = queryRes?.data?.place
          if (!groups) return null
          return orderBy(groups, { name: "asc" }).map((a) => ({
            label: a.name,
            value: a.id,
            searchValue: a.name,
          }))
        },
      }),
      is_archived: {
        type: "singleton",
        label: i18n.t("data-view:filters.archived"),
        badgeLabel: i18n.t("common:asset", { count: 1 }),
        toWhere: (state) => {
          return { archived_at: { _is_null: state.negated } }
        },
      },
    })
  }, [])

  return {
    schema,
    components: <DataViewFilterDatePicker dataViewFilterDaterPicker={datePicker} />,
  }
}

export const assetDataViewDefaultConfig = {
  columnOrder: [
    "name",
    "place",
    "lastState",
    "assetGroup",
    "activeWorkOrders",
    "manufacturer",
    "support",
  ],
  orderBy: [
    { id: "name", dir: "asc" },
    { id: "place", dir: "asc" },
  ],
  groupBy: "assetGroup",
} as DataViewConfiguration<AssetViewIds>

export const assetArchiveDataViewDefaultConfig = {
  columnOrder: ["name", "archivedAt", "place", "assetGroup", "manufacturer"],
  orderBy: [{ id: "archivedAt", dir: "desc" }],
  groupBy: null,
} as DataViewConfiguration<AssetViewIds>

export const AssetDataView = React.memo(
  ({ defaultConfig, ...props }: AssetDataViewProps) => {
    const filter = useAssetFilterSchema()
    const navigate = useNavigateWithBackgroundLocation()
    const onSelect = useCallbackRef((data: IAssetDataViewFragment) => {
      navigate(`/object/${data.id}`)
    })
    const dataId = useCallback((d: IAssetDataViewFragment) => d.id, [])
    const dataSearchValue = useCallback(
      (d: IAssetDataViewFragment) =>
        `${d.search_value} ${d.public_id} ${d.place?.name ?? ""} ${d.group?.name ?? ""} ${
          d.manufacturer?.name ?? ""
        }`,
      []
    )
    const dataViewRef =
      useRef<CoreDataViewHandle<IAssetDataViewFragment, AssetViewIds>>(null)

    const mobCreateDialog = useDisclosure()
    const scope = usePermissionScope(IPermissionScopeEnum.AppAssetCreate)
    const [dialogState, setDialogState] = useState<ContextMenuAction | null>(null)

    const contextMenu = useCallback(
      (data: IAssetDataViewFragment) => (
        <>
          <ContextMenuItem onSelect={() => setDialogState({ type: "rename", asset: data })}>
            <Pencil className="mr-1 h-5 w-5" />
            {i18n.t("common:rename", { token: i18n.t("common:asset", { count: 1 }) })}
          </ContextMenuItem>
          <ContextMenuSeparator />
          <ContextMenuItem
            onSelect={() => {
              const dataView = dataViewRef?.current?.dataView
              if (!dataView) return

              const treeItem = findTreeList(
                dataView.treeList,
                (item) => item.id === data.id
              )
              if (!treeItem) return

              forEachTree(treeItem, (item) => {
                dataView.getNodeDisclosure(item).open()
              })
            }}>
            <ArrowsOutLineVertical className="mr-1 h-5 w-5" />
            {i18n.t("data-view:actions.expand_subobjects")}
          </ContextMenuItem>

          <ContextMenuItem
            onSelect={() => {
              const dataView = dataViewRef?.current?.dataView
              if (!dataView) return

              const treeItem = findTreeList(
                dataView.treeList,
                (item) => item.id === data.id
              )
              if (!treeItem) return

              forEachTree(treeItem, (item) => {
                dataView.getNodeDisclosure(item).close()
              })
            }}>
            <ArrowsInLineVertical className="mr-1 h-5 w-5" />
            {i18n.t("data-view:actions.collapse_subobjects")}
          </ContextMenuItem>
          <ContextMenuSeparator />
          <ContextMenuItem
            onSelect={() => {
              const dataView = dataViewRef?.current?.dataView
              if (!dataView) return

              forEachTreeList(dataView.treeList, (item) => {
                dataView.getNodeDisclosure(item).open()
              })
            }}>
            <ArrowsOut className="mr-1 h-5 w-5" />
            {i18n.t("data-view:actions.expand_all_objects")}
          </ContextMenuItem>
          <ContextMenuItem
            onSelect={() => {
              const dataView = dataViewRef?.current?.dataView
              if (!dataView) return

              forEachTreeList(dataView.treeList, (item) => {
                dataView.getNodeDisclosure(item).close()
              })
            }}>
            <ArrowsIn className="mr-1 h-5 w-5" />
            {i18n.t("data-view:actions.collapse_all_objects")}
          </ContextMenuItem>
        </>
      ),
      []
    )

    const showPublicId = useFeature("asset_public_id")

    const columns = useMemo(() => {
      const cols = defaultAssetColumns(showPublicId)

      const customFieldNames = new Set<string>()

      props.data?.forEach((a) =>
        (a.custom_fields as CustomAssetFields)?.forEach((f) => {
          if (f.name) {
            customFieldNames.add(f.name?.trim())
          }
        })
      )

      const customField = (asset: IAssetDataViewFragment, name: string) =>
        (asset.custom_fields as CustomAssetFields)?.find((f) => f.name.trim() === name)

      new Array(...customFieldNames.values()).sort().forEach((name) => {
        cols.push({
          Header: name,
          id: ("custom_field:" + name) as AssetViewIds,
          Cell: (asset) => customField(asset, name)?.value,
          toText: (asset) => customField(asset, name)?.value ?? "",
          orderBy: (dir) => ({
            custom_fields: {
              transform: (arr: CustomAssetFields) => {
                if (!arr || arr.length < 1) return null
                const field = arr.find((f) => (f.name ? f.name.trim() === name : undefined))
                return field?.value ?? null
              },
              dir,
            },
          }),
          groupBy: {
            label: (groupId, asset) => {
              if (groupId !== null && asset) return customField(asset, name)?.value ?? ""
              return i18n.t("common:without_group")
            },
            id: (asset) => customField(asset, name)?.value ?? null,
          },
        })
      })

      return cols
    }, [!!props.data])

    if (!filter) return null

    const { schema, components } = filter

    return (
      <>
        <AssetCreateFormDialog
          isOpen={mobCreateDialog.isOpen}
          onOpenChange={mobCreateDialog.changeOpen}
          title={i18n.t("common:create_token", {
            token: i18n.t("common:asset", { count: 1 }),
          })}
        />
        <DataView
          {...props}
          innerRef={dataViewRef}
          dataId={dataId}
          dataSearchValue={dataSearchValue}
          getParentItemId={(a) => a.parent_asset_id}
          columnDefinitions={columns}
          filterSchema={schema}
          onSelect={onSelect}
          searchPlaceholder={i18n.t("common:search_token", {
            token: i18n.t("common:asset", { count: 1 }),
          })}
          hiddenFixedFilters={[
            { type: "singleton", id: "is_archived", negated: !props.archive },
          ]}
          dataType={IViewDataTypeEnum.Asset}
          listItem={(asset) => (
            <AssetDataViewListItem
              asset={asset}
              onCreateWorkOrder={props.onCreateWorkOrder}
            />
          )}
          contextMenu={contextMenu}
          defaultConfig={{
            ...assetDataViewDefaultConfig,
            ...defaultConfig,
          }}
          baseOrderBy={[
            { id: "name", dir: "asc" },
            { id: "place", dir: "asc" },
          ]}
          noData={
            props.noData ?? {
              icon: Shapes,
              title: i18n.t("common:no_token_created_yet", {
                token: i18n.t("common:asset", { count: 2 }),
              }),
              message: (
                <p style={{ marginTop: 4 }}>
                  {i18n.t("assets:messages.empty_state.content")}
                </p>
              ),
              actions: scope.hasScope ? (
                <Button
                  icon={Plus}
                  size="small"
                  type="primary"
                  className="mx-auto mt-3"
                  onClick={mobCreateDialog.onOpen}>
                  {i18n.t("add_token", { token: i18n.t("common:asset", { count: 1 }) })}
                </Button>
              ) : null,
            }
          }
        />
        {components}
        <DialogPrimitive.Root
          open={!!dialogState}
          onOpenChange={(open) => {
            if (!open) setDialogState(null)
          }}>
          <DialogPrimitive.Portal>
            <DialogPrimitive.Overlay className="fixed inset-0 bg-black/20" />
            {dialogState && (
              <DialogPrimitive.Content className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded border bg-white p-4 text-gray-700 drop-shadow-2xl">
                {dialogState.type === "rename" && (
                  <AssetRenameAction
                    id={dialogState.asset.id}
                    name={dialogState.asset.name}
                    triggerClose={() => setDialogState(null)}
                  />
                )}
              </DialogPrimitive.Content>
            )}
          </DialogPrimitive.Portal>
        </DialogPrimitive.Root>
      </>
    )
  }
)
