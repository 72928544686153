import { Flex, HStack } from "@components/layout"
import { useBreakpoint } from "@contexts/breakpoints"
import { useDisclosure } from "@hooks"
import i18n from "@i18n"
import { CaretDown, CaretUp } from "@phosphor-icons/react"
import Icons from "@resources/icons"
import { findTreeList } from "@utils/tree"
import classNames from "classnames"
import { dequal } from "dequal"
import React, { ReactNode, useRef } from "react"

import { ComboboxSelect, ComboboxSelectProps, Item } from "./combobox-select"
import { PopoverContent, PopoverContentProps, PopoverRoot, PopoverTrigger } from "./popover"

type SelectPopoverSize = "large" | "small"

export type SelectPopoverProps<T> = Pick<
  ComboboxSelectProps<T>,
  "value" | "items" | "onCreate"
> & {
  placeholder?: string
  hasError?: boolean
  onChange?: (value: T | null) => void
  onBlur?: React.FocusEventHandler<HTMLButtonElement> | undefined
  className?: string
  size?: SelectPopoverSize
  disabled?: boolean
  isClearable?: boolean
  valueToString?: ComboboxSelectProps<T>["valueToString"]
  id?: string
  name?: string
  icon?: React.ReactNode
  listWidth?: number
  listMaxHeight?: number
  isOpen?: boolean
  nullOption?: {
    searchValue: string
    label: ReactNode
  }
  onOpenChange?: (isOpen: boolean) => void
  children?:
    | ReactNode
    | ((args: { selectedItem: Item<T> | null; isOpen: boolean }) => ReactNode)
  popoverContentProps?: PopoverContentProps
  searchPlaceholder?: string
}

export const SelectPopover = <T,>({
  size = "small",
  value,
  placeholder,
  className,
  onChange,
  onBlur,
  hasError,
  isClearable = true,
  disabled = false,
  id,
  name,
  children,
  listWidth,
  listMaxHeight = 250,
  isOpen,
  onOpenChange,
  ...props
}: SelectPopoverProps<T>) => {
  const bp = useBreakpoint()
  const selectPopup = useDisclosure({ isOpen, onOpenChange })

  const items = props.nullOption
    ? [
        {
          value: null as unknown as T,
          label: props.nullOption.label,
          searchValue: props.nullOption.searchValue,
          icon: undefined,
        },
        ...props.items,
      ]
    : props.items

  const selectedItem = items && findTreeList(items, (item) => dequal(item.value, value))
  const buttonRef = useRef<HTMLButtonElement>(null)

  const defaultTrigger = (
    <SelectTrigger
      className={classNames(
        "w-full tracking-wide flex items-center px-2 text-sm border rounded cursor-default transition-colors text-gray-700",
        "bg-white !border-gray-200 hover:!border-gray-400 active:!border-gray-600 ",
        {
          "min-h-[32px] min-w-[32px] px-[8px] py-[1px] text-sm": size === "small",
          "min-h-[36px] min-w-[36px] px-[14px] py-[2px] text-base": size === "large",
          "text-red-700 border-red-700 focus:ring-red-700 focus:border-red-700": hasError,
          "hover:border-gray-600 hover:text-gray-900 border-gray-400": !hasError,
          "!bg-gray-100 hover:!border-gray-200": disabled,
        },
        className
      )}
      role="combobox"
      ref={buttonRef}
      onBlur={onBlur}
      id={id}
      name={name}
      disabled={disabled}>
      <Flex row flex="1" className="mr-1">
        {/* Also render placeholder for undefined */}
        {value != null ? (
          <div className="flex min-w-0 items-center">
            {selectedItem?.icon && (
              <div className="mr-1 h-[1em] w-[1em]">{selectedItem?.icon}</div>
            )}
            <span className="truncate">{selectedItem?.label}</span>
          </div>
        ) : (
          <span
            className={classNames("truncate", {
              "text-red-300": hasError,
              "text-gray-500": !hasError,
            })}>
            {placeholder ?? i18n.t("select")}
          </span>
        )}
      </Flex>
      <HStack align="center">
        {value !== null && !disabled && isClearable && !props.nullOption && (
          <span
            className="p-1.5"
            onClick={(e) => {
              e.stopPropagation()
              if (buttonRef.current) buttonRef.current.blur()
              onChange?.(null)
            }}>
            <Icons.Close
              className={classNames("text-md rounded-full text-white", {
                "bg-gray-500 hover:bg-gray-800": !hasError,
                "bg-red-700 hover:bg-red-800": hasError,
              })}
            />
          </span>
        )}
        {selectPopup.isOpen ? (
          <CaretUp className="h-4 w-4 text-gray-700 opacity-50" />
        ) : (
          <CaretDown className="h-4 w-4 text-gray-700 opacity-50" />
        )}
      </HStack>
    </SelectTrigger>
  )

  return (
    <PopoverRoot open={selectPopup.isOpen} onOpenChange={selectPopup.changeOpen}>
      {typeof children === "function"
        ? children?.({ isOpen: selectPopup.isOpen, selectedItem })
        : children ?? defaultTrigger}
      <PopoverContent
        sideOffset={4}
        alignOffset={0}
        side={"bottom"}
        align={"start"}
        style={{ minWidth: listWidth ?? buttonRef.current?.offsetWidth }}
        {...props.popoverContentProps}>
        <ComboboxSelect
          {...props}
          items={items}
          onCreate={props.onCreate}
          listMaxHeight={bp.mobile ? window.innerHeight : listMaxHeight}
          valueToString={props.valueToString ?? ((x) => x as unknown as string)}
          value={value}
          onChange={(value) => {
            onChange?.(value)
            selectPopup.onClose()
          }}
          placeholder={
            props.searchPlaceholder ??
            (props.onCreate ? i18n.t("common:search_or_create") : i18n.t("common:search"))
          }
        />
      </PopoverContent>
    </PopoverRoot>
  )
}
export const SelectTrigger = PopoverTrigger
