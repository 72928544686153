import { VStack } from "@components/layout"
import { useDragFilesContext } from "@contexts/drag-files-context"
import { IUploadDataFragment } from "@graphql/documents/upload.generated"
import { uuid } from "@graphql/scalars"
import i18n from "@i18n"
import Icons from "@resources/icons"
import { cn } from "@utils"
import { useEffect, useRef } from "react"
import { Accept, FileRejection, useDropzone } from "react-dropzone"
import { FileUpload } from "src/types"

import { Button } from "."
import { PictureCard } from "./picture-card"

type PictureUploadUploadsProps = {
  uploads: FileUpload[]
  onChange?: (uploads: IUploadDataFragment[]) => void
  onDelete: (id: uuid) => void
  allowDeleteOnHome?: boolean
}

const Uploads = ({
  uploads,
  onChange,
  onDelete,
  allowDeleteOnHome,
}: PictureUploadUploadsProps) => {
  // required for injection in form items
  useEffect(() => {
    onChange?.(uploads.map((u) => u.data))
  }, [uploads])

  return (
    <>
      {uploads.map((upload) => (
        <PictureCard
          key={upload.data.id}
          upload={upload}
          onDelete={() => onDelete(upload.data.id)}
          onEdit={() => onDelete(upload.data.id)}
          containerClasses="h-full"
          allowDeleteOnHome={allowDeleteOnHome}
        />
      ))}
    </>
  )
}

type PictureUploadDropzoneProps = {
  extraRequiredTriggers?: boolean[]
  className?: string
  accept?: Accept
  onBeforeDrop?: () => Promise<void>
  onDropFile: (file: File) => Promise<string | void>
  onFilesRejected?: (files: FileRejection[]) => void
}

export const Dropzone = ({
  accept,
  className,
  extraRequiredTriggers,
  onBeforeDrop,
  onDropFile,
  onFilesRejected,
}: PictureUploadDropzoneProps) => {
  let { isDragging } = useDragFilesContext()
  const dz = useDropzone({
    noClick: true,
    accept: accept,
    onDrop(acceptedFiles, fileRejections) {
      const onDropFiles = async () => {
        await onBeforeDrop?.()
        for (const file of acceptedFiles) {
          onDropFile(file)
        }
        if (fileRejections.length > 0) {
          onFilesRejected?.(fileRejections)
        }
      }
      onDropFiles()
    },
  })
  const { getRootProps, getInputProps } = dz

  const isVisible = extraRequiredTriggers
    ? isDragging && extraRequiredTriggers.every((trigger) => trigger === true)
    : isDragging

  return (
    <div
      {...getRootProps({
        className: cn(
          "absolute inset-0 z-50 bg-white/80 border border-dashed border-gray-300 cursor-auto rounded",
          "dropzone items-center justify-center",
          isVisible ? "flex" : "hidden",
          className
        ),
      })}>
      <input {...getInputProps()} crossOrigin="anonymous" />
      <div className="flex flex-row items-center text-sm font-medium text-gray-600">
        <Icons.DownArrow className="text-lg" />
        <span className="ml-2">{i18n.t("common:drop_file_here")}</span>
      </div>
    </div>
  )
}

type UploadButtonProps = {
  onUpload: (file: File) => Promise<uuid>
}

const UploadButton = ({ onUpload }: UploadButtonProps) => {
  const inputFileRef: React.RefObject<HTMLInputElement> = useRef(null)

  return (
    <>
      <input
        multiple
        name="files"
        type="file"
        ref={inputFileRef}
        onChange={(e) => {
          if (!e.target.files) return
          for (const file of Object.values(e.target.files)) {
            onUpload(file)
          }
        }}
        style={{ display: "none" }}
      />
      <Button
        onClick={() => inputFileRef?.current?.click()}
        type="tertiary"
        icon={Icons.Upload}>
        <VStack align="flex-start" inset={{ left: 8 }}>
          Dateien anhängen
          <span className="text-sm font-normal text-gray-500">
            Klicken oder hineinziehen
          </span>
        </VStack>
      </Button>
    </>
  )
}

export default {
  Dropzone,
  Uploads,
  Button: UploadButton,
}
