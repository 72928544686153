import { CollapsibleTabsLink } from "@components/shared/collapsible-tabs-links"
import LoadingSpinner from "@components/shared/loading-spinner"
import { useWorkOrderTemplatesQuery } from "@graphql/documents/work-order.generated"
import i18n from "@i18n"
import { Link, Navigate, Outlet, useParams } from "react-router-dom"

export const TemplateDetailPage = () => {
  const { templateId } = useParams()

  const [templatesQueryRes] = useWorkOrderTemplatesQuery({ requestPolicy: "cache-first" })

  if (!templateId) {
    return <Navigate to="/" />
  }

  const template = templatesQueryRes.data?.work_order_template?.find(
    (t) => t.id === templateId
  )

  if (!template) {
    return <LoadingSpinner showLogo={false} />
  }

  return (
    <div className="flex min-h-0 flex-1 flex-col bg-gray-50">
      <div className="m-3 mb-1.5">
        <h2 className="text-2xl font-bold tracking-tight text-gray-700">{template.name}</h2>
        <h3 className="mt-1 text-sm text-gray-500">
          {i18n.t("tasks:template.description")}
          <Link to={`/task/template`} className="ml-2 text-sm font-medium text-blue-600">
            {i18n.t("tasks:template.link")}
          </Link>
        </h3>
      </div>

      <CollapsibleTabsLink
        className=" flex min-h-0 flex-col"
        triggers={[
          { value: "metadata", label: i18n.t("information") },
          {
            value: `details/${template.work_order.id}`,
            label: i18n.t("task", { count: 1 }),
          },
        ]}
      />

      <Outlet context={{ template }} />
    </div>
  )
}

export default TemplateDetailPage
