import { AssetCreateFormDialog } from "@components/asset/asset-create-form-dialog"
import { AssetDataView } from "@components/asset/asset-data-view"
import { Button } from "@components/shared"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@components/shared/dropdown"
import { openElaraCommandDialog } from "@components/shared/elara-command"
import toast from "@components/shared/toast"
import {
  IUpdateAssetParentMutation,
  IUpdateAssetParentMutationVariables,
  UpdateAssetParentDocument,
  useAssetDataViewQuery,
} from "@graphql/documents/asset.generated"
import { IPermissionScopeEnum, useDisclosure, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { CaretDown, PictureInPicture, Plus } from "@phosphor-icons/react"
import { useRegisterCommands } from "@stores/command"
import { useClient } from "urql"

import { useAsset } from "./$id"

const ObjectChildren = () => {
  const client = useClient()
  const { asset } = useAsset()

  const [queryRes, fetch] = useAssetDataViewQuery({
    variables: { where: { parent_ids: { _has_key: asset.id } } },
    requestPolicy: "cache-and-network",
  })

  const createModal = useDisclosure({
    onClose: () => fetch({ requestPolicy: "network-only" }),
  })

  const createAssetScope = usePermissionScope(IPermissionScopeEnum.AppAssetCreate)
  const editAssetScope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)

  const handleInsertObject = async (assetId: string) => {
    const res = await client
      .mutation<IUpdateAssetParentMutation, IUpdateAssetParentMutationVariables>(
        UpdateAssetParentDocument,
        { assetId, parentId: asset.id },
        editAssetScope.context()
      )
      .toPromise()

    if (res.error) {
      toast.error(i18n.t("common:generic_toast_error"))
    }

    fetch({ requestPolicy: "network-only" })
  }

  useRegisterCommands("2_object_children", [
    {
      type: "action",
      value: "object_children:create",
      searchValue: i18n.t("command:object.children.create"),
      action: createModal.onOpen,
      group: asset.name,
      icon: <Plus />,
      description: i18n.t("command:object.children.create"),
    },
    {
      type: "page",
      page: "page:object_children:insert",
      value: "object_children:insert",
      searchValue: i18n.t("command:object.children.insert"),
      group: asset.name,
      icon: <PictureInPicture />,
      description: i18n.t("command:object.children.insert"),
      subcommands: (searchResults) =>
        searchResults
          .filter((c) => c.value.startsWith("open_object:"))
          .filter((c) => c.meta?.object?.id !== asset.id)
          .map((c) => ({
            ...c,
            action: async () => {
              if (c.meta?.object) handleInsertObject(c.meta.object.id)
            },
          })),
    },
  ])

  return (
    <div className="mt-3 flex min-h-0 flex-1 flex-col">
      <AssetDataView
        headerClassName="mx-3"
        configId="subasset-detail"
        defaultConfig={{ groupBy: null }}
        data={queryRes.data?.asset ?? null}
        actions={
          createAssetScope.hasScope && (
            <>
              <Button
                type="primary"
                icon={Plus}
                onClick={createModal.onOpen}
                className="rounded-r-none">
                {i18n.t("common:new_token", {
                  token: i18n.t("assets:fields.subasset", { count: 1 }),
                })}
              </Button>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button type="primary" icon={CaretDown} className="rounded-l-none" />
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem
                    onClick={() => openElaraCommandDialog("page:object_children:insert")}>
                    {i18n.t("assets:context_menu.insert")}
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </>
          )
        }
        noData={{
          title: i18n.t("common:no_token_created_yet", {
            token: i18n.t("assets:fields.subasset", { count: 2 }),
          }),
          message: i18n.t("assets:messages.subassets.empty_state.content"),
          actions: createAssetScope.hasScope && (
            <Button type="secondary" icon={Plus} onClick={createModal.onOpen}>
              {i18n.t("common:new_token", {
                token: i18n.t("assets:fields.subasset", { count: 1 }),
              })}
            </Button>
          ),
        }}
      />
      <AssetCreateFormDialog
        isOpen={createModal.isOpen}
        onOpenChange={createModal.changeOpen}
        title={i18n.t("common:create_token", {
          token: i18n.t("assets:fields.subasset", { count: 1 }),
        })}
        parentAssetId={asset.id}
      />
    </div>
  )
}

export default ObjectChildren
