import { HStack } from "@components/layout"
import { SelectPopover, SelectPopoverProps } from "@components/shared/single-select"
import { IWorkOrderPriorityEnum } from "@elara/db"
import i18n from "@i18n"
import Icons from "@resources/icons"

import { translateWorkOrderPriority, WorkOrderPriorityBadge } from "../work-order-priority"

export const PrioritySelect = (props: Omit<SelectPopoverProps<string>, "items">) => (
  <SelectPopover
    {...props}
    placeholder={i18n.t("tasks:priority.none")}
    value={props.value === null ? "no_priority" : props.value}
    onChange={(value) => props.onChange?.(value === "no_priority" ? null : value)}
    items={[
      {
        value: "no_priority",
        searchValue: i18n.t("tasks:priority.none"),
        label: (
          <HStack space={4} className="text-gray-700">
            <Icons.NoPriority height={18} width={18} className="mr-0.5 px-0.5" />
            <span className="truncate">{i18n.t("tasks:priority.none")}</span>
          </HStack>
        ),
      },
      ...[
        IWorkOrderPriorityEnum.Low,
        IWorkOrderPriorityEnum.Medium,
        IWorkOrderPriorityEnum.High,
      ].map((value) => ({
        value,
        searchValue: translateWorkOrderPriority(value),
        label: <WorkOrderPriorityBadge priority={value} />,
      })),
    ]}
  />
)
