import { getUser, hasPermissionScope } from "@contexts/user-context"
import { IPermissionScopeEnum } from "@elara/db"
import { ITeamFragment } from "@graphql/documents/fragments.generated"
import { useTeamsQuery } from "@graphql/documents/team.generated"
import i18n from "@i18n"
import { TreeLike } from "@utils/tree"
import classNames from "classnames"
import { useMemo } from "react"

import { Item } from "./combobox-select"
import {
  MultiSelectPopover,
  MultiSelectPopoverProps,
  MultiSelectSummary,
} from "./multi-select"
import { SelectPopover, SelectPopoverProps } from "./single-select"
import { IconData, TeamIcon } from "./team-icons"

export function teamItems(
  teams: ITeamFragment[],
  teamIsSelectable: (team: ITeamFragment) => boolean = always
) {
  const userId = getUser().id
  const isAdmin = hasPermissionScope(IPermissionScopeEnum.AppAccountManagement)

  const teamItem = (team: ITeamFragment) => {
    const icon = team.icon as IconData
    return {
      value: team?.id,
      label: (
        <span
          className={classNames("truncate", {
            "line-through opacity-70": !!team.archived_at,
          })}>
          {team.name}
        </span>
      ),
      icon: <TeamIcon name={icon?.name} color={icon?.color} />,
      searchValue: team.name,
    }
  }

  type T = ReturnType<typeof teamItem>
  const myTeams: T[] = []
  const otherTeams: T[] = []
  const retainedItems: T[] = []
  for (const team of teams) {
    if (!team) continue

    if (team.archived_at || !teamIsSelectable(team)) {
      retainedItems.push(teamItem(team))
    } else if (team.members && team.members.some((m) => m.user && m.user.id === userId)) {
      myTeams.push(teamItem(team))
    } else if (!team.private) {
      otherTeams.push(teamItem(team))
    } else if (team.private && isAdmin) {
      otherTeams.push(teamItem(team))
    } else {
      retainedItems.push(teamItem(team))
    }
  }

  const items = [
    {
      value: "my_teams",
      label: i18n.t("teams:my_teams"),
      searchValue: i18n.t("teams:my_teams"),
      children: myTeams,
    },
    {
      value: "other_teams",
      label: i18n.t("teams:other_teams"),
      searchValue: i18n.t("teams:other_teams"),
      children: otherTeams,
    },
  ]

  return { items, retainedItems }
}

const always = () => true
const useTeamSelect = (
  teamIsSelectable?: (team: ITeamFragment) => boolean,
  summaryMode?: "count" | "summary"
) => {
  const [queryResult] = useTeamsQuery({ requestPolicy: "cache-first" })

  const teams = useMemo(
    () => (queryResult.data?.team ?? []).filter(teamIsSelectable ?? always),
    [queryResult.data?.team, teamIsSelectable]
  )

  const { items, retainedItems } = useMemo(
    () => teamItems(queryResult.data?.team ?? [], teamIsSelectable),
    [teams, teamIsSelectable]
  )

  return {
    items,
    retainedItems,
    valueToString: (s: string) => s,
    placeholder: i18n.t("common:search_token", {
      token: i18n.t("common:team", { count: 1 }),
    }),
    noDataPlaceholder: i18n.t("common:no_token_found", {
      token: i18n.t("common:team", { count: 2 }),
    }),
    size: "small" as "small" | "large",
    groupBy: true,
    title: i18n.t("common:team", { count: 1 }),
    renderCompactSelectedValues: (values: TreeLike<Item<string>>[]) => {
      const selectedTeams = teams.filter((t) => values.some((v) => v.value === t.id))

      const firstTeam = selectedTeams[0] as ITeamFragment | null
      return (
        <MultiSelectSummary
          items={selectedTeams}
          limit={3}
          itemsToIcons={(team) => {
            const icon = team.icon as IconData
            return <TeamIcon name={icon?.name} color={icon?.color} key={team?.id} />
          }}
          firstItemLabel={
            <span
              className={classNames({
                "line-through opacity-50": !!firstTeam?.archived_at,
              })}>
              {firstTeam?.name ?? ""}
            </span>
          }
          mode={summaryMode ?? "count"}
          countLabel={i18n.t("common:team", { count: 2 })}
          iconBgClass="bg-gray-100"
          spaceBetweenIconsClass="-ml-1.5"
          iconBorderClass="border-gray-100"
        />
      )
    },
  }
}

export type TeamSingleSelectProps = Partial<SelectPopoverProps<string>> & {
  teamIsSelectable?: (team: ITeamFragment) => boolean
}

export function TeamSingleSelect({ teamIsSelectable, ...props }: TeamSingleSelectProps) {
  const selectProps = useTeamSelect(teamIsSelectable)
  return <SelectPopover {...selectProps} {...props} />
}

export type TeamMultiSelectProps = Partial<MultiSelectPopoverProps<string>> & {
  teamIsSelectable?: (team: ITeamFragment) => boolean
  summaryMode?: "count" | "summary"
}

export function TeamMultiSelect({
  teamIsSelectable,
  summaryMode,
  ...props
}: TeamMultiSelectProps) {
  const selectProps = useTeamSelect(teamIsSelectable, summaryMode)
  return <MultiSelectPopover {...selectProps} {...props} />
}
