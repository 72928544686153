import { LoadingIndicator } from "@components/shared"
import toast from "@components/shared/toast"
import { useAuthenticationStateContext } from "@contexts/auth-context"
import i18n from "@i18n"
import { useEffect } from "react"
import { useParams } from "react-router-dom"

export function useAcceptUserInvite() {
  const { afterInviteAccept } = useAuthenticationStateContext()
  return async (args: { userId: string; inviteCode: string }) => {
    const { success, locationId } = await fetch("/api/auth/accept-invite", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ inviteCode: args.inviteCode, userId: args.userId }),
    }).then((r) => r.json())

    if (success && locationId) {
      toast.success("Invite accepted")
      afterInviteAccept(locationId)
    } else {
      toast.error("Invalid invite code")
    }
  }
}

export function InviteCodePage() {
  const params = useParams()
  const { state: authState } = useAuthenticationStateContext()
  const acceptUserInvite = useAcceptUserInvite()
  const userId =
    authState.stage === "with_location_infos" || authState.stage === "fully_authenticated"
      ? authState.userInfo.id
      : null

  useEffect(() => {
    if (!userId) return

    const { inviteCode } = params
    if (!inviteCode) {
      alert("No invite code provided")
    } else {
      acceptUserInvite({
        userId,
        inviteCode,
      })
    }
  }, [userId])

  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <div className="flex items-center text-gray-600">
        <LoadingIndicator size={20} />
        <span className="ml-2">{i18n.t("common:messages.processing")}...</span>
      </div>
    </div>
  )
}
