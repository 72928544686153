import { AssetTag } from "@components/shared/asset-tag"
import { DataView, DataViewProps } from "@components/shared/data-view/data-view"
import {
  createSchema,
  createSchemaStatement,
  generalSelectStateToWhere,
} from "@components/shared/data-view/data-view-filter.hooks"
import { Column, DataViewConfiguration } from "@components/shared/data-view/data-view-types"
import { Tooltip } from "@components/shared/tooltip"
import {
  genericAssetGroups,
  genericAssets,
} from "@components/work-order/work-order-data-view-filter-configuration"
import {
  IMaintenanceBoolExp,
  IMaintenanceTriggerTypeEnum,
  IViewDataTypeEnum,
  MeterReadingTriggerOptions,
  TimeIntervalTriggerOptions,
} from "@elara/db"
import { Where } from "@elara/select"
import { IMaintenanceFragment } from "@graphql/documents/fragments.generated"
import { useMaintenanceQuery } from "@graphql/documents/maintenance.generated"
import i18n from "@i18n"
import { CalendarX, Pause } from "@phosphor-icons/react"
import { cn, parseDate } from "@utils"
import { formatDate } from "@utils/date"
import { useNavigateWithBackgroundLocation } from "@utils/location"
import { getCalendarScheduledMaintenanceEvents } from "@utils/maintenance"
import { useMemo } from "react"

import { getMeterReadingTriggerDigest } from "./components/meter-reading-trigger-digest"
import { ScheduledEventItemById } from "./components/scheduled-event-item-by-id"
import { getTimeIntervalTriggerDigest } from "./components/time-interval-trigger-digest"

type MaintenanceColumnOptions = {}

export type MaintenanceColumnIds =
  | "name"
  | "description"
  | "triggers"
  | "objects"
  | "object_groups"
  | "nextAt"
  | "archivedAt"

const columnDefinitions: Column<
  IMaintenanceFragment,
  MaintenanceColumnIds,
  MaintenanceColumnOptions
>[] = [
  {
    id: "name",
    Header: i18n.t("maintenance:fields.name"),
    defaultWidth: 200,
    calendarView: {
      default: true,
      // This is hacky, I don't need it but to make the calendar view work I need to define it
      event: ({ id, name }) => {
        return {
          id,
          title: name,
        }
      },
    },
    groupBy: {
      id: (row) => row.id,
      label: (groupId, row) => {
        return row?.name ?? ""
      },
    },
    Cell: ({ name, paused }) => (
      <span className="inline-flex items-center space-x-1 font-medium text-gray-800">
        <span className={cn({ "text-gray-500": paused })}>{name}</span>
        {paused && (
          <Tooltip
            content={
              <p className="text-xs">{i18n.t("maintenance:messages.status.paused")}</p>
            }
            className="inline-flex">
            <Pause className="text-gray-400" weight="fill" />
          </Tooltip>
        )}
      </span>
    ),
    toText: ({ name }) => name,
    orderBy: (dir) => ({ name: dir }),
  },
  {
    id: "description",
    Header: i18n.t("maintenance:fields.description"),
    defaultWidth: 300,
    Cell: ({ description }) => description,
    orderBy: (dir) => ({ description: dir }),
    toText: ({ description }) => description ?? "",
  },
  {
    id: "triggers",
    Header: i18n.t("maintenance:fields.triggers"),
    defaultWidth: 200,
    Cell: ({ triggers }) =>
      triggers
        .map((t) => {
          if (t.type === IMaintenanceTriggerTypeEnum.Time) {
            return `${i18n.t(
              "maintenance:labels.triggers.time.digest_prefix"
            )}: ${getTimeIntervalTriggerDigest(
              t.payload as TimeIntervalTriggerOptions["payload"]
            )}`
          } else {
            return `${i18n.t(
              "maintenance:labels.triggers.meter.digest_prefix"
            )}: ${getMeterReadingTriggerDigest(
              t.payload as MeterReadingTriggerOptions["payload"],
              t.meter_unit
            )}`
          }
        })
        .join(", "),
    toText: ({ triggers }) =>
      triggers
        .map((t) => {
          if (t.type === IMaintenanceTriggerTypeEnum.Time) {
            return `${i18n.t(
              "maintenance:labels.triggers.time.digest_prefix"
            )}: ${getTimeIntervalTriggerDigest(
              t.payload as TimeIntervalTriggerOptions["payload"]
            )}`
          } else {
            return `${i18n.t(
              "maintenance:labels.triggers.meter.digest_prefix"
            )}: ${getMeterReadingTriggerDigest(
              t.payload as MeterReadingTriggerOptions["payload"],
              t.meter_unit
            )}`
          }
        })
        .join(", "),
  },
  {
    id: "objects",
    Header: i18n.t("maintenance:data-view.columns.objects"),
    defaultWidth: 300,
    groupBy: {
      id: (row) => row.template?.assets?.map((a) => a.asset.id) ?? [],
      label: (groupId, row) => {
        if (!row) return null
        const a = row.template?.assets.find((a) => a.asset.id === groupId)?.asset

        if (!a) {
          return i18n.t("common:without_token", {
            token: i18n.t("common:asset", { count: 1 }),
          })
        }

        return <AssetTag asset={a} />
      },
    },
    Cell: (row) => (
      <>
        <div className="flex flex-wrap gap-1">
          {row.template?.assets.slice(0, 3).map((a) => (
            <AssetTag key={a.asset.id} asset={a.asset} />
          ))}
        </div>
        {(row.template?.assets.length ?? 0) > 3 && (
          <span className="mt-1 text-xs text-gray-500">
            +{(row.template?.assets.length ?? 0) - 3} {i18n.t("common:more")}
          </span>
        )}
      </>
    ),
    toText: (row) => row.template?.assets.map((a) => a.asset.name).join(", ") ?? "",
  },
  {
    id: "object_groups",
    Header: i18n.t("maintenance:data-view.columns.object_groups"),
    defaultWidth: 300,
    groupBy: {
      id: (row) =>
        Array.from(
          new Set(
            row.template?.assets
              .filter((a) => !!a.asset.group)
              .map((a) => a.asset.group!.id)
          )
        ) ?? [],
      label: (groupId, row) => {
        if (!row) return null

        const a = row.template?.assets
          .filter((a) => !!a.asset.group)
          .find((a) => a.asset.group!.id === groupId)?.asset.group

        return (
          a?.name ??
          i18n.t("common:without_token", {
            token: i18n.t("assets:fields.group", { count: 1 }),
          })
        )
      },
    },
    Cell: (row) => {
      const assetGroups = Array.from(
        new Set(
          row.template?.assets
            .filter((a) => !!a.asset.group)
            .map((a) => a.asset.group!.name)
        )
      )

      return <div className="flex flex-wrap gap-1">{assetGroups.join(", ")}</div>
    },
    toText: (row) => {
      const assetGroups = Array.from(
        new Set(
          row.template?.assets
            .filter((a) => !!a.asset.group)
            .map((a) => a.asset.group!.name)
        )
      )

      return assetGroups.join(", ") ?? ""
    },
  },
  {
    id: "archivedAt",
    Header: i18n.t("maintenance:fields.archived_at"),
    defaultWidth: 300,
    Cell: ({ archived_at }) =>
      archived_at ? formatDate(parseDate(archived_at), "P") : null,
    toText: ({ archived_at }) =>
      archived_at ? formatDate(parseDate(archived_at), "P") : "",
  },
  {
    id: "nextAt",
    Header: i18n.t("maintenance:data-view.columns.next_at"),
    defaultWidth: 300,
    Cell: (row) => (
      <div className="flex flex-wrap gap-1">
        {row.triggers
          .map((t) => {
            if (t.type === IMaintenanceTriggerTypeEnum.Time) {
              const p = t.payload as TimeIntervalTriggerOptions["payload"]
              return (
                <>
                  {p.nextAt
                    ? formatDate(parseDate(p.nextAt), p.nextTime ? "Pp" : "P")
                    : null}
                </>
              )
            } else {
              const p = t.payload as MeterReadingTriggerOptions["payload"]
              if (p.variant === "interval") {
                return (
                  <>
                    {p.nextAt ? (
                      <span>
                        {p.nextAt} {t.meter_unit}{" "}
                        <span className="text-gray-600">
                          (aktuell {t.meter_last_reading} {t.meter_unit})
                        </span>
                      </span>
                    ) : null}
                  </>
                )
              }
              return null
            }
          })
          .filter((a) => a)
          .map((s) => (
            <>{s}</>
          ))}
      </div>
    ),
    toText: (row) => row.template?.assets.map((a) => a.asset.name).join(", ") ?? "",
  },
]

type Props = Omit<
  DataViewProps<IMaintenanceFragment, MaintenanceColumnIds, MaintenanceColumnOptions>,
  | "columnDefinitions"
  | "list_item"
  | "defaultConfig"
  | "filter"
  | "onSelect"
  | "data"
  | "dataType"
  | "useData"
  | "dataId"
  | "dataSearchValue"
> &
  Partial<
    Pick<
      DataViewProps<IMaintenanceFragment, MaintenanceColumnIds, MaintenanceColumnOptions>,
      "defaultConfig"
    >
  > & { archive?: boolean }

const useFilterSchema = () => {
  const schema = useMemo(
    () =>
      createSchema<IMaintenanceFragment>({
        assets: genericAssets<IMaintenanceFragment>(
          (state) =>
            generalSelectStateToWhere(["template", "assets", "asset", "id"], state, {
              isOneToMany: true,
            }) as Where<IMaintenanceFragment>
        ),
        assetGroups: genericAssetGroups<IMaintenanceFragment>(
          (state) =>
            generalSelectStateToWhere(
              ["template", "assets", "asset", "group", "id"],
              state,
              { isOneToMany: true }
            ) as Where<IMaintenanceFragment>
        ),
        maintenanceType: createSchemaStatement<IMaintenanceFragment, string>({
          type: "select",
          label: i18n.t("maintenance:fields.triggers"),
          multiSelectedLabel: i18n.t("maintenance:fields.triggers"),
          getItems: async () => {
            return [
              {
                value: IMaintenanceTriggerTypeEnum.Time,
                label: i18n.t("maintenance:labels.triggers.time.title"),
                searchValue: i18n.t("maintenance:labels.triggers.time.title"),
              },
              {
                value: IMaintenanceTriggerTypeEnum.Meter,
                label: i18n.t("maintenance:labels.triggers.meter.title"),
                searchValue: i18n.t("maintenance:labels.triggers.meter.title"),
              },
            ]
          },
          toWhere: (state) => {
            const cond = generalSelectStateToWhere(["triggers", "type"], state, {
              isOneToMany: true,
            }) satisfies Where<IMaintenanceFragment>
            return state.negated ? { _not: cond } : cond
          },
        }),
        paused: createSchemaStatement<IMaintenanceFragment, string>({
          type: "singleton",
          label: i18n.t("maintenance:fields.is_paused"),
          badgeLabel: i18n.t("maintenance:fields.is_paused"),

          operatorLabel: (negated) =>
            negated ? i18n.t("data-view:filters.has_no") : i18n.t("data-view:filters.has"),
          toWhere: (state) => {
            const cond = { paused: { _eq: true } } satisfies Where<IMaintenanceFragment>
            return state.negated ? { _not: cond } : cond
          },
        }),
      }),
    []
  )

  return { schema }
}

export const maintenanceDataViewDefaultConfig = {
  columnOrder: ["name", "triggers", "objects", "object_groups", "nextAt"],
  orderBy: [{ id: "name", dir: "asc" }],
} as DataViewConfiguration<MaintenanceColumnIds>

export const maintenanceArchiveDataViewDefaultConfig = {
  columnOrder: ["name", "archivedAt", "triggers", "objects", "object_groups"],
  orderBy: [{ id: "archivedAt", dir: "desc" }],
} as DataViewConfiguration<MaintenanceColumnIds>

const maintenanceListItem = (maintenance: IMaintenanceFragment) => (
  <div className="group inline-flex flex-col rounded py-3">
    <div className="flex items-center">
      <div className="leading-loose">
        <p className="text-sm font-medium">{maintenance.name}</p>
        <p className="text-xs opacity-50">{maintenance.description}</p>
      </div>
    </div>
  </div>
)

const MaintenanceDataView: React.FC<Props> = ({ defaultConfig, archive, ...props }) => {
  const filter = useFilterSchema()
  const navigateWithBackgroundLocation = useNavigateWithBackgroundLocation()

  const onSelect = (item: IMaintenanceFragment) => {
    navigateWithBackgroundLocation(`/maintenance/${item.id}`)
  }

  return (
    <DataView
      {...props}
      dataType={
        archive ? IViewDataTypeEnum.MaintenanceArchive : IViewDataTypeEnum.Maintenance
      }
      data={null}
      useData={(where) => {
        // eslint-disable-next-line
        const [queryRes] = useMaintenanceQuery({
          variables: {
            where: archive
              ? {
                  _and: [
                    { archived_at: { _is_null: false } },
                    where as IMaintenanceBoolExp,
                  ],
                }
              : (where as IMaintenanceBoolExp),
          },
          requestPolicy: "cache-and-network",
        })
        const data = queryRes.data?.maintenance ?? []
        return {
          data,
          isLoading: queryRes.fetching,
          events: ({ from, to }) => {
            return data.flatMap((d) =>
              getCalendarScheduledMaintenanceEvents(d.triggers, from, to)
            )
          },
        }
      }}
      dataId={({ id }) => id}
      dataSearchValue={({ name }) => name}
      columnDefinitions={columnDefinitions}
      listItem={maintenanceListItem}
      eventItem={(row, event) => (
        <ScheduledEventItemById
          id={event.event.groupId}
          triggerId={event.event.extendedProps?.trigger?.id}
          date={event.event.start}
        />
      )}
      filterSchema={filter.schema}
      searchPlaceholder={i18n.t("common:search")}
      onSelect={onSelect}
      defaultConfig={{
        ...maintenanceDataViewDefaultConfig,
        ...defaultConfig,
      }}
      noData={{
        icon: CalendarX,
        title: i18n.t("maintenance:messages.no_data.title"),
      }}
      baseOrderBy={[{ id: "name", dir: "asc" }]}
    />
  )
}

export default MaintenanceDataView
