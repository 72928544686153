import { IUserTagFragment } from "@graphql/documents/fragments.generated"
import { IAvatarFragment } from "@graphql/documents/upload.generated"
import * as AvatarPrimitive from "@radix-ui/react-avatar"
import Icons from "@resources/icons"
import { cn } from "@utils"
import React from "react"

export type AvatarSize = "gigantic" | "huge" | "large" | "small" | number

function getContainerSize(size: AvatarSize) {
  if (typeof size === "number") return size
  if (size === "gigantic") return 56
  if (size === "huge") return 42
  if (size === "large") return 24
  // "small"
  return 20
}

type AvatarRootProps = React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root> & {
  size?: AvatarSize
}

const AvatarRoot = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  AvatarRootProps
>(({ className, size = "large", ...props }, ref) => (
  <AvatarPrimitive.Root
    ref={ref}
    {...props}
    className={cn("relative flex shrink-0 overflow-hidden rounded-full", className)}
    style={{
      width: getContainerSize(size),
      height: getContainerSize(size),
      ...props.style,
    }}
  />
))

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image
    ref={ref}
    className={cn("aspect-square object-cover h-full w-full", className)}
    {...props}
  />
))

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    className={cn(
      "flex h-full w-full items-center justify-center rounded-full bg-gray-100",
      className
    )}
    {...props}
  />
))

export type AvatarProps = {
  avatar?: IAvatarFragment | null
  placeholder?: React.ReactNode
  src?: string
} & Omit<AvatarRootProps, "children" | "placeholder">

const Avatar = React.forwardRef<React.ElementRef<typeof AvatarPrimitive.Root>, AvatarProps>(
  ({ className, avatar, size = "large", src, placeholder, ...props }, ref) => {
    return (
      <AvatarRoot ref={ref} className={className} size={size} {...props}>
        <AvatarImage src={src ?? avatar?.thumbnail_url ?? avatar?.url ?? undefined} />
        <AvatarFallback>{placeholder}</AvatarFallback>
      </AvatarRoot>
    )
  }
)

function getAssetPlaceholderIconSize(size: AvatarSize) {
  if (typeof size === "number") return Math.floor(size / 2)
  if (size === "gigantic") return 40
  if (size === "huge") return 32
  if (size === "large") return 18
  // "small"
  return 14
}

export const AssetAvatarPlaceholder = (props: { size?: AvatarSize }) => {
  const placeholderSize = getAssetPlaceholderIconSize(props.size ?? "large")
  return <Icons.Asset height={placeholderSize} width={placeholderSize} />
}

const AssetAvatar = (props: AvatarProps) => {
  return (
    <Avatar
      {...props}
      className={cn("rounded-md", props.className)}
      placeholder={
        props.placeholder ?? <AssetAvatarPlaceholder size={props.size ?? "large"} />
      }
    />
  )
}

const getInitials = (firstName?: string, lastName?: string) => {
  const firstInitial = firstName?.charAt(0).toUpperCase() ?? ""
  const lastInitial = lastName?.charAt(0).toUpperCase() ?? ""

  return firstInitial + lastInitial
}

function getInitialsFontSize(size: AvatarSize) {
  if (typeof size === "number") return Math.floor(size / 2)
  if (size === "gigantic") return 24
  if (size === "huge") return 20
  if (size === "large") return 10
  // "small"
  return 8
}

export interface IUserInfo {
  first_name: string
  last_name: string
  avatar?: IAvatarFragment | null
}

export type UserAvatarProps = AvatarProps & {
  user?: IUserInfo | null
  firstName?: string
  lastName?: string
}

const UserAvatar = ({
  lastName,
  firstName,
  user,
  placeholder,
  avatar,
  ...props
}: UserAvatarProps) => (
  <Avatar
    {...props}
    avatar={user?.avatar || avatar}
    placeholder={
      placeholder || (
        <span style={{ fontSize: getInitialsFontSize(props.size!) }}>
          {getInitials(firstName || user?.first_name, lastName || user?.last_name)}
        </span>
      )
    }
  />
)

export const UserAvatarPlaceholder = (props: {
  size?: AvatarSize
  user: Pick<IUserTagFragment, "first_name" | "last_name">
}) => {
  return (
    <span style={{ fontSize: getInitialsFontSize(props.size ?? "large") }}>
      {getInitials(props.user.first_name, props.user.last_name)}
    </span>
  )
}

export { AssetAvatar, Avatar, AvatarFallback, AvatarImage, AvatarRoot, UserAvatar }
