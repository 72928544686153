import { uuid } from "@elara/db"
import { useUserTagByIdQuery } from "@graphql/documents/user.generated"

import { UserTag } from "./user-tag"

export const UserTagById = (props: { id: uuid | null }) => {
  const [queryRes] = useUserTagByIdQuery({
    variables: { id: props.id! },
    requestPolicy: "cache-only",
    pause: !props.id,
  })

  const user = queryRes?.data?.user_by_pk
  if (!user) return null

  return <UserTag user={user} />
}
