import { Data } from "@elara/select"
import { useCallbackRef } from "@hooks"
import React from "react"
import { unstable_serialize } from "swr"

import { TreeExtension } from "./data-view-types"

type DataViewTreeContextValue<D extends Data> = {
  expanded: Set<string>
  isExpanded: (id: string) => boolean
  numberOfChildren?: (data: any) => number
  toggle: (id: string) => void
  getId: (data: any) => string
} & TreeExtension<D>

const DataViewTreeContext = React.createContext<DataViewTreeContextValue<Data> | null>(null)

export const useDataViewTreeContext = () => {
  const value = React.useContext(DataViewTreeContext)
  return value
}

export type DataViewTreeProps<D extends Data> = React.PropsWithChildren<
  {
    getId: (data: D) => string
  } & TreeExtension<D>
>
export const DataViewTree = <D extends Data>(props: DataViewTreeProps<D>) => {
  const [expanded, setExpanded] = React.useState<Set<string>>(new Set())

  const isExpanded = React.useCallback((id: string) => expanded.has(id), [expanded])
  const toggle = React.useCallback((id: string) => {
    setExpanded((expanded) => {
      const newExpanded = new Set(expanded)
      if (newExpanded.has(id)) {
        newExpanded.delete(id)
      } else {
        newExpanded.add(id)
      }
      return newExpanded
    })
  }, [])

  const getId = useCallbackRef(props.getId)
  const numberOfChildren = useCallbackRef(props.numberOfChildren)
  const childrenQuery = useCallbackRef(props.childrenQuery)

  const value = React.useMemo(
    () => ({
      expanded,
      isExpanded,
      toggle,
      getId,
      numberOfChildren,
      childrenQuery,
      rootWhere: props.rootWhere,
    }),
    [expanded, isExpanded, toggle, unstable_serialize(props.rootWhere)]
  )

  return (
    <DataViewTreeContext.Provider value={value as DataViewTreeContextValue<Data>}>
      {props.children}
    </DataViewTreeContext.Provider>
  )
}
