import { Flex } from "@components/layout"
import { useDisclosure } from "@hooks"
import i18n from "@i18n"
import {
  ArrowClockwise,
  ArrowCounterClockwise,
  DownloadSimple,
  MagnifyingGlassMinus,
  MagnifyingGlassPlus,
  TrashSimple,
  X,
} from "@phosphor-icons/react"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import { useControllableState } from "@radix-ui/react-use-controllable-state"
import Icons, { LeftPrevious, RightNext } from "@resources/icons"
import classNames from "classnames"
import { useEffect, useState } from "react"
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch"
import { FileUpload } from "src/types"

import { alertDialog } from "./alert-dialog-provider"
import { ImagePropertiesDialog } from "./image-properties-dialog"

const useImageGalleryPreview = () => {
  const [rotation, setRotation] = useState(0)

  const rotateCounterClockwise = () =>
    setRotation((rotation) => (rotation - 90 + 360) % 360)
  const rotateClockwise = () => setRotation((rotation) => (rotation + 90) % 360)

  return {
    rotation,
    rotateClockwise,
    rotateCounterClockwise,
  }
}

const ButtonContainer = ({
  children,
  onClick,
}: {
  children: React.ReactNode
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}) => {
  return (
    <div
      className="flex cursor-pointer items-center justify-center text-white hover:text-grey-4"
      style={{ height: "44px", width: "44px" }}
      onClick={onClick}>
      {children}
    </div>
  )
}

type ImageGalleryPreviewProps = {
  images: FileUpload[]
  isVisible: boolean
  currentIndex: number
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>
  hidePreview?: () => void
  onDelete?: (id: string) => void
}

const ImageGalleryPreview = (props: ImageGalleryPreviewProps & { alt?: string }) => {
  const { images, alt, currentIndex, setCurrentIndex } = props

  const { rotation, rotateClockwise, rotateCounterClockwise } = useImageGalleryPreview()

  const handleNextImage = () =>
    setCurrentIndex((currentIndex) => (currentIndex + 1) % images.length)

  const handlePrevImage = () =>
    setCurrentIndex((currentIndex) => (currentIndex - 1 + images.length) % images.length)

  useEffect(() => {
    const onKeyPress = ({ key }: KeyboardEvent) => {
      if (key === "ArrowLeft") {
        handlePrevImage()
      } else if (key === "ArrowRight") {
        handleNextImage()
      } else if (key === "[") {
        rotateCounterClockwise()
      } else if (key === "]") {
        rotateClockwise()
      } else if (key === "Escape") {
        props.hidePreview?.()
      }
    }

    window.addEventListener("keyup", onKeyPress)

    return () => {
      window.removeEventListener("keyup", onKeyPress)
    }
  }, [])

  const onDelete = async () => {
    const shouldDelete = await alertDialog({
      danger: true,
      title: i18n.t("common:delete_image"),
      description: i18n.t("common:delete_image_description"),
      actionText: i18n.t("delete"),
    })
    if (shouldDelete) {
      props.onDelete?.(images[currentIndex].data.id)
    }
  }

  if (!props.isVisible) return null

  return (
    <TransformWrapper initialScale={1} centerZoomedOut doubleClick={{ disabled: true }}>
      {({ zoomIn, zoomOut, ..._ }) => (
        <div className="fixed inset-0 flex min-h-0 min-w-0 cursor-default flex-col bg-black/50">
          <Flex
            row
            align="center"
            justify="flex-end"
            className="!absolute top-0 z-10 h-12 w-screen bg-black/[.2] px-2"
            onClick={(e) => e.stopPropagation()}>
            <ButtonContainer onClick={rotateCounterClockwise}>
              <ArrowCounterClockwise size={18} />
            </ButtonContainer>
            <ButtonContainer onClick={rotateClockwise}>
              <ArrowClockwise size={18} />
            </ButtonContainer>
            <ButtonContainer onClick={() => zoomIn()}>
              <MagnifyingGlassPlus size={18} />
            </ButtonContainer>
            <ButtonContainer onClick={() => zoomOut()}>
              <MagnifyingGlassMinus size={18} />
            </ButtonContainer>
            <ButtonContainer>
              <a
                className="flex items-center"
                href={`${images[currentIndex].data.url}?download=1`}>
                <DownloadSimple size={18} className="mr-2" />
              </a>
            </ButtonContainer>

            {props.onDelete && (
              <ButtonContainer onClick={onDelete}>
                <TrashSimple size={18} />
              </ButtonContainer>
            )}

            <ButtonContainer
              onClick={(e) => {
                e.stopPropagation()
                props.hidePreview?.()
              }}>
              <X size={18} className="cursor-pointer" />
            </ButtonContainer>
          </Flex>

          {images.length > 1 && (
            <>
              <div className="absolute inset-y-0 left-5 flex items-center justify-center">
                <LeftPrevious
                  fontSize={72}
                  color="white"
                  onClick={handlePrevImage}
                  className="relative z-10 cursor-pointer p-3 hover:text-grey-4"
                />
              </div>
              <div className="absolute inset-y-0 right-5 flex items-center justify-center">
                <RightNext
                  fontSize={72}
                  color="white"
                  onClick={handleNextImage}
                  className="relative z-10 cursor-pointer p-3 hover:text-grey-4"
                />
              </div>
            </>
          )}

          <TransformComponent
            contentClass="!pt-12 !flex-1 !flex !flex-col !items-center !justify-center !w-full !grow !overflow-hidden"
            wrapperClass="!flex-1 !flex !flex-col !grow !w-full">
            <div className="relative flex w-full flex-1 grow flex-col items-center justify-center">
              <div
                className="absolute inset-0"
                onClick={(e) => {
                  e.stopPropagation()
                  props.hidePreview?.()
                }}
              />
              <img
                src={images[currentIndex].data.url ?? undefined}
                alt={alt}
                style={{
                  // Subtract 3rem for the h-12 height of the action bar
                  maxHeight: rotation % 180 == 0 ? "calc(100vh - 3rem)" : "100vw",
                  maxWidth: rotation % 180 == 0 ? "100vw" : "calc(100vh - 3rem)",
                  transform: `rotate(${rotation}deg)`,
                  objectFit: "contain",
                  pointerEvents: "auto",
                }}
                className="z-10 "
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          </TransformComponent>
        </div>
      )}
    </TransformWrapper>
  )
}

type ImageProps = {
  images: FileUpload[]
  alt?: string
  height?: number
  width?: number
  style?: React.CSSProperties
  containerStyle?: React.CSSProperties
  imageClasses?: string
  containerClasses?: string
  onDelete?: (id: string) => void
  onClick?: React.MouseEventHandler<HTMLImageElement>
  allowQuickDeleteOfPictures?: boolean
  confirmQuickDelete?: boolean
}

const ImageGallery = (props: ImageProps) => {
  const {
    alt,
    containerClasses,
    containerStyle,
    height,
    imageClasses,
    images,
    onClick,
    onDelete,
    style,
    width,
    allowQuickDeleteOfPictures,
  } = props

  const [currentIndex, setCurrentIndex] = useState(0)

  const [isPreviewVisible, onPreviewVisibleChange] = useControllableState({
    defaultProp: false,
  })

  const handleDelete = (id: string) => {
    setCurrentIndex(0)
    onDelete?.(id)
  }

  const deleteWithConfirmation = async (_id: string) => {
    const shouldDelete = await alertDialog({
      danger: true,
      title: i18n.t("common:delete_image"),
      description: i18n.t("common:delete_image_description"),
      actionText: i18n.t("delete"),
    })

    if (shouldDelete) {
      props.onDelete?.(images[currentIndex].data.id)
    }
  }

  if (!images || images.length === 0) return null

  return (
    <>
      <DialogPrimitive.Root open={isPreviewVisible} onOpenChange={onPreviewVisibleChange}>
        <div className={containerClasses} style={containerStyle}>
          <div className="flex flex-wrap gap-3">
            {images.map((image, index) => {
              return (
                <ImageEditProperties
                  image={image}
                  height={height}
                  imageClasses={imageClasses}
                  onClick={onClick}
                  onDelete={onDelete}
                  style={style}
                  width={width}
                  deleteWithConfirmation={deleteWithConfirmation}
                  setCurrentIndex={setCurrentIndex}
                  onPreviewVisibleChange={onPreviewVisibleChange}
                  currentIndex={index}
                  confirmQuickDelete={props.confirmQuickDelete}
                  allowQuickDeleteOfPictures={allowQuickDeleteOfPictures}
                />
              )
            })}
          </div>

          {isPreviewVisible && (
            <DialogPrimitive.Portal>
              <DialogPrimitive.Content>
                <ImageGalleryPreview
                  alt={alt}
                  images={images}
                  onDelete={onDelete ? handleDelete : undefined}
                  isVisible={isPreviewVisible!}
                  hidePreview={() => onPreviewVisibleChange(false)}
                  currentIndex={currentIndex}
                  setCurrentIndex={setCurrentIndex}
                />
              </DialogPrimitive.Content>
            </DialogPrimitive.Portal>
          )}
        </div>
      </DialogPrimitive.Root>
    </>
  )
}

type ImageButtonsProps = {
  image: FileUpload
  alt?: string
  height?: number
  width?: number
  style?: React.CSSProperties
  containerStyle?: React.CSSProperties
  imageClasses?: string
  containerClasses?: string
  onDelete?: (id: string) => void
  onClick?: React.MouseEventHandler<HTMLImageElement>
  allowQuickDeleteOfPictures?: boolean
  confirmQuickDelete?: boolean
  deleteWithConfirmation: (id: string) => void
  setCurrentIndex: (id: number) => void
  onPreviewVisibleChange: (id: boolean) => void
  currentIndex: number
}

const ImageEditProperties = (props: ImageButtonsProps) => {
  const {
    height,
    imageClasses,
    image,
    onClick,
    onDelete,
    style,
    width,
    deleteWithConfirmation,
    setCurrentIndex,
    onPreviewVisibleChange,
    currentIndex,
    confirmQuickDelete,
    allowQuickDeleteOfPictures,
  } = props

  const editUploadDialog = useDisclosure()

  return (
    <div
      style={{ width, height }}
      className="relative box-border rounded border border-gray-200 shadow-sm"
      key={image.data.id}>
      <img
        key={image.data.id}
        src={image.data.thumbnail_url ?? undefined}
        className={classNames(
          "h-24 object-cover rounded aspect-square cursor-zoom-in",
          imageClasses
        )}
        style={{ width, height, ...style }}
        onClick={(e) => {
          onClick?.(e)
          setCurrentIndex(currentIndex)
          onPreviewVisibleChange(true)
        }}
      />
      {allowQuickDeleteOfPictures && (
        <>
          <div
            className={classNames(
              "absolute flex items-center justify-center -top-1 -right-1 h-8 w-8 group cursor-pointer"
            )}
            onClick={() => editUploadDialog.onOpen()}>
            <div
              className={classNames(
                "h-4 w-4 box-content z-40 bg-white text-black group-hover:bg-grey-2.5 group-hover:text-grey-6"
              )}>
              <Icons.Menu height={16} width={16} />
            </div>
          </div>
          <div
            className={classNames(
              "absolute flex items-center justify-center -top-1 -left-1 h-8 w-8 group cursor-pointer"
            )}
            onClick={() => {
              if (confirmQuickDelete) {
                deleteWithConfirmation(image.data.id)
              } else {
                onDelete?.(image.data.id)
              }
            }}>
            <div
              className={classNames(
                "h-4 w-4  box-content z-40 bg-white text-black group-hover:bg-grey-2.5 group-hover:text-grey-6"
              )}>
              <Icons.Close height={16} width={16} />
            </div>
          </div>
        </>
      )}
      {image.progress < 1 && (
        <>
          <div
            className="pointer-events-none absolute inset-y-0 left-0 w-[0%] bg-white opacity-25 transition-[width]"
            style={{ width: `${image.progress * 100}%` }}
          />
          <div className="pointer-events-none absolute inset-0 flex items-center justify-center">
            <p className="text-xs font-semibold text-white opacity-90">
              {Math.round(image.progress * 100)}%
            </p>
          </div>
        </>
      )}
      {editUploadDialog.isOpen && (
        <ImagePropertiesDialog
          upload={image}
          onClick={editUploadDialog.onClose}
          onDelete={onDelete}
          confirmQuickDelete={confirmQuickDelete}
        />
      )}
    </div>
  )
}

export default ImageGallery
