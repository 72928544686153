import { getLocationTimeZone } from "@contexts/user-context"
import {
  Ends,
  Frequency,
  MaintenanceSchema as _MaintenanceSchema,
  MeterReadingTriggerOptions,
  Month,
  MonthlyModifier,
  TimeIntervalTriggerOptions,
  Weekday,
} from "@elara/db"
import i18n from "@i18n"
import { toCalendarDate } from "@internationalized/date"
import { formatDate, month, week } from "@utils/date"
import { now } from "@utils/tzdate"
import { Duration } from "date-fns"
import { DeepPartial } from "react-hook-form"
import { Frequency as RRuleFrequency, RRule, Weekday as RRuleWeekday } from "rrule"
import { z } from "zod"

export const defaultDuration: Duration = {
  seconds: 0,
  minutes: 0,
  hours: 0,
  days: 0,
  weeks: 0,
  months: 0,
  years: 0,
}

export const freqToRRuleFreq: Record<Frequency, RRuleFrequency> = {
  [Frequency.secondly]: RRule.SECONDLY,
  [Frequency.minutely]: RRule.MINUTELY,
  [Frequency.hourly]: RRule.HOURLY,
  [Frequency.daily]: RRule.DAILY,
  [Frequency.weekly]: RRule.WEEKLY,
  [Frequency.monthly]: RRule.MONTHLY,
  [Frequency.yearly]: RRule.YEARLY,
}

export const weekdayToRRuleWeekday: Record<Weekday, RRuleWeekday> = {
  [Weekday.MO]: RRule.MO,
  [Weekday.TU]: RRule.TU,
  [Weekday.WE]: RRule.WE,
  [Weekday.TH]: RRule.TH,
  [Weekday.FR]: RRule.FR,
  [Weekday.SA]: RRule.SA,
  [Weekday.SU]: RRule.SU,
}

// export const TimeIntervalTriggerSchemaABC = z.object({
//   type: z.literal("time"),
//   payload: z
//     .object({
//       startDate: z
//         .string()
//         .datetime()
//         // Check if startDate is a valid date and in the future
//         .refine((startDate: string) => !isBefore(new Date(startDate), new Date()), {
//           message: i18n.t("maintenance:messages.invalid_start_date"),
//         }),
//       dueTime: z.string().datetime().optional().nullable(),
//       rrule: z.string().optional(),
//       nextAt: z.string().datetime().optional(),
//       timeZone: z.string().optional(),
//       repetition: z.object({
//         frequency: z.nativeEnum(Frequency),
//         interval: z.number(),
//         hourly: z
//           .object({
//             from: z.string().optional(),
//             to: z.string().optional(),
//             weekdays: z.array(z.nativeEnum(Weekday)).optional(),
//           })
//           .optional()
//           // Check if from is before to and if at least one is set
//           .refine(
//             (value) => {
//               if (!value) return true
//               if (!(value.from || value.to) && value.weekdays) return true
//               if (value.from && value.to && value.from > value.to) return false
//               if (value.from || value.to) return true
//               return false
//             },
//             { message: i18n.t("maintenance:messages.invalid_hourly_time") }
//           ),
//         weekly: z
//           .object({ weekdays: z.array(z.nativeEnum(Weekday)).optional() })
//           .optional(),
//         monthly: z.object({ modifier: z.nativeEnum(MonthlyModifier) }).optional(),
//       }),
//       createNextOnlyIfLastComplete: z.boolean(),
//       createBefore: z.object({
//         years: z.number().optional(),
//         months: z.number().optional(),
//         weeks: z.number().optional(),
//         days: z.number().optional(),
//         hours: z.number().optional(),
//         minutes: z.number().optional(),
//         seconds: z.number().optional(),
//       }),
//       ends: z
//         .object({
//           value: z.nativeEnum(Ends),
//           date: z.string().datetime().optional(),
//           count: z.number().optional(),
//         })
//         // Check if date or count is set depending on Ends value
//         .refine(({ value, count, date }) => {
//           if (value === Ends.on) return !!date
//           if (value === Ends.after) return !!count
//           return true
//         }),
//     })
//     // Check if Ends.on date is after startDate
//     .refine(
//       (value) => {
//         if (value.startDate && value.ends.value === Ends.on && value.ends.date) {
//           return isBefore(new Date(value.startDate), new Date(value.ends.date))
//         }
//         return true
//       },
//       { message: i18n.t("maintenance:messages.invalid_ends_on") }
//     ),
// })

export const getDurationOptions = (count: number) => [
  {
    label: i18n.t("calendar:tokens.day", { count }),
    value: "days",
  },
  {
    label: i18n.t("calendar:tokens.week", { count }),
    value: "weeks",
  },
  {
    label: i18n.t("calendar:tokens.month", { count }),
    value: "months",
  },
  {
    label: i18n.t("calendar:tokens.year", { count }),
    value: "years",
  },
]

export const getMonthlyModifierOptions = (options: { date: string; weekday: string }) => [
  {
    value: MonthlyModifier.first_of_month,
    label: i18n.t("maintenance:repetition.monthly_modifier.first_of_month"),
  },
  {
    value: MonthlyModifier.by_date,
    label: i18n.t("maintenance:repetition.monthly_modifier.by_date", {
      date: options.date,
    }),
  },
  {
    value: MonthlyModifier.by_weekday,
    label: i18n.t("maintenance:repetition.monthly_modifier.by_weekday", {
      weekday: options.weekday,
    }),
  },
  {
    value: MonthlyModifier.last_of_month,
    label: i18n.t("maintenance:repetition.monthly_modifier.last_of_month"),
  },
]

export const getWeekdayOptions = () => [
  {
    value: Weekday.MO,
    name: formatDate(week[0], "iiii"),
    label: formatDate(week[0], "iiiiii"),
  },
  {
    value: Weekday.TU,
    name: formatDate(week[1], "iiii"),
    label: formatDate(week[1], "iiiiii"),
  },
  {
    value: Weekday.WE,
    name: formatDate(week[2], "iiii"),
    label: formatDate(week[2], "iiiiii"),
  },
  {
    value: Weekday.TH,
    name: formatDate(week[3], "iiii"),
    label: formatDate(week[3], "iiiiii"),
  },
  {
    value: Weekday.FR,
    name: formatDate(week[4], "iiii"),
    label: formatDate(week[4], "iiiiii"),
  },
  {
    value: Weekday.SA,
    name: formatDate(week[5], "iiii"),
    label: formatDate(week[5], "iiiiii"),
  },
  {
    value: Weekday.SU,
    name: formatDate(week[6], "iiii"),
    label: formatDate(week[6], "iiiiii"),
  },
]

export const getMonthOptions = () => [
  {
    value: Month.JAN,
    name: formatDate(month[0], "MMMM"),
    label: formatDate(month[0], "MM"),
  },
  {
    value: Month.FEB,
    name: formatDate(month[1], "MMMM"),
    label: formatDate(month[1], "MM"),
  },
  {
    value: Month.MAR,
    name: formatDate(month[2], "MMMM"),
    label: formatDate(month[2], "MM"),
  },
  {
    value: Month.APR,
    name: formatDate(month[3], "MMMM"),
    label: formatDate(month[3], "MM"),
  },
  {
    value: Month.MAY,
    name: formatDate(month[4], "MMMM"),
    label: formatDate(month[4], "MM"),
  },
  {
    value: Month.JUN,
    name: formatDate(month[5], "MMMM"),
    label: formatDate(month[5], "MM"),
  },
  {
    value: Month.JUL,
    name: formatDate(month[6], "MMMM"),
    label: formatDate(month[6], "MM"),
  },
  {
    value: Month.AUG,
    name: formatDate(month[7], "MMMM"),
    label: formatDate(month[7], "MM"),
  },
  {
    value: Month.SEP,
    name: formatDate(month[8], "MMMM"),
    label: formatDate(month[8], "MM"),
  },
  {
    value: Month.OCT,
    name: formatDate(month[9], "MMMM"),
    label: formatDate(month[9], "MM"),
  },
  {
    value: Month.NOV,
    name: formatDate(month[10], "MMMM"),
    label: formatDate(month[10], "MM"),
  },
  {
    value: Month.DEC,
    name: formatDate(month[11], "MMMM"),
    label: formatDate(month[11], "MM"),
  },
]

export const getDefaultTimeIntervalTriggerOptions = () => {
  return {
    type: "time",
    payload: {
      nextAt: null,
      nextDate: toCalendarDate(now()).toString(),
      timeZone: getLocationTimeZone(),
      createBefore: { ...defaultDuration, weeks: 2 },
      createNextOnlyIfLastComplete: true,
      repetition: {
        interval: 1,
        frequency: Frequency.monthly,
        monthly: { modifier: MonthlyModifier.by_date },
      },
      ends: { value: Ends.never },
    },
  } as DeepPartial<TimeIntervalTriggerOptions>
}

export const getDefaultMeterReadingTriggerOptions = () => {
  return {
    type: "meter",
    payload: {
      variant: "range",
      onlyOneOpenTask: true,
      dueAfter: { ...defaultDuration, days: 1 },
    },
  } as DeepPartial<MeterReadingTriggerOptions>
}

export const MaintenanceSchema = _MaintenanceSchema.superRefine((value, ctx) => {
  // Check if value.name is not empty
  if (!value.name || value.name === "") {
    ctx.addIssue({
      path: ["name"],
      message: i18n.t("common:required"),
      code: z.ZodIssueCode.custom,
    })
  }

  // Check if value.triggers is not empty
  if (!value.triggers || value.triggers.length === 0) {
    ctx.addIssue({
      path: ["triggers"],
      message: i18n.t("common:required"),
      code: z.ZodIssueCode.custom,
    })
  }

  value.triggers.forEach((trigger) => {
    // Time Interval Trigger Validation
    if (trigger.type === "time") {
    }

    // Meter Reading Trigger Validation
    if (trigger.type === "meter") {
      // Range
      if (trigger.payload.variant === "range") {
        if (trigger.payload.min > trigger.payload.max) {
          ctx.addIssue({
            path: ["triggers"],
            code: z.ZodIssueCode.custom,
            message: i18n.t("maintenance:messages.invalid_min_max_range"),
          })
        }
      }

      // Interval
      if (trigger.payload.variant === "interval") {
      }
    }
  })
})
