import { VStack } from "@components/layout"
import i18n from "@i18n"
import * as Sentry from "@sentry/react"
import React from "react"
import { useNavigate } from "react-router-dom"

import { Button } from "./button"

type ErrorBoundaryProps = React.PropsWithChildren<{
  buttonText?: string
  buttonOnClick?: () => void
}>

export const ErrorBoundary = (props: ErrorBoundaryProps) => {
  const navigate = useNavigate()
  return (
    <Sentry.ErrorBoundary
      fallback={(errProps) => {
        return (
          <VStack space={16} className="mx-auto max-w-sm p-8">
            <span className="text-sm text-gray-700">
              {i18n.t("navigation:error.message")}
            </span>
            <Button
              onClick={() => {
                // I think this is only for dev
                errProps.resetError()
                if (props.buttonOnClick) {
                  props.buttonOnClick()
                } else {
                  navigate("/")
                  window.location.reload()
                }
              }}>
              {props.buttonText ?? i18n.t("navigation:error.back_to_homepage")}
            </Button>
          </VStack>
        )
      }}>
      {props.children}
    </Sentry.ErrorBoundary>
  )
}
