import { Tooltip } from "@components/shared/tooltip"
import i18n from "@i18n"
import { dateLocaleMap, formatDate } from "@utils/date"
import { formatDistanceToNow } from "date-fns"
import { useEffect, useState } from "react"

type Props = { date: Date; fullTimestampIfOlderThanMs?: number }

const TimeAgo = (props: Props) => {
  const [time, setTime] = useState(
    formatDistanceToNow(props.date, {
      locale: dateLocaleMap[i18n.language],
      addSuffix: true,
    })
  )
  const [title, setTitle] = useState<string | undefined>(undefined)

  useEffect(() => {
    const updateTime = () => {
      const olderThan8Hours =
        Date.now() - props.date.getTime() >
        (props.fullTimestampIfOlderThanMs ?? 8 * 60 * 60 * 1000)

      if (olderThan8Hours) {
        setTime(formatDate(props.date, "Pp"))
        setTitle(undefined)
      } else {
        setTime(
          formatDistanceToNow(props.date, {
            locale: dateLocaleMap[i18n.language],
            addSuffix: true,
          })
        )
        setTitle(formatDate(props.date, "Pp"))
      }
    }
    updateTime()
    const handle = setInterval(updateTime, 60 * 1000)
    return () => clearInterval(handle)
  }, [props.date.getTime()])

  return title ? (
    <Tooltip content={title}>
      <span>{time}</span>
    </Tooltip>
  ) : (
    <span>{time}</span>
  )
}

export default TimeAgo
