export type TreeLike<P> = { children?: TreeLike<P>[] } & P
export type Tree<P> = TreeLike<
  P & { index: number; parent: Tree<P> | null; path: number[] }
>

export function buildTreeListFromId<P>(
  items: P[],
  options: {
    getItemId: (item: P) => string
    getParentItemId: (item: P) => string | null
  }
): TreeLike<P>[] {
  // build up a list of items
  const existingIds = new Set<string>()
  items.forEach((item) => existingIds.add(options.getItemId(item)))

  const roots = [] as P[]
  const children = new Map<string, P[]>()
  for (let item of items) {
    const parentId = options.getParentItemId(item)
    if (parentId && existingIds.has(parentId)) {
      const child = children.get(parentId)
      if (child) {
        child.push(item)
      } else {
        children.set(parentId, [item])
      }
    } else {
      roots.push(item)
    }
  }

  const buildTree = (item: P): TreeLike<P> => ({
    ...item,
    children: children.get(options.getItemId(item))?.map((child) => buildTree(child)),
  })

  return roots.map((item) => buildTree(item))
}

export function treeListExtended<P>(treeList: TreeLike<P>[]): Tree<P>[] {
  const extendedTreeList = [] as Tree<P>[]
  let index = 0
  treeList.forEach((tree, k) => {
    const [mappedTree, updatedIndex] = _treeExtended(tree, index, [k])
    index = updatedIndex
    extendedTreeList.push(mappedTree)
  })

  return extendedTreeList
}

function _treeExtendedWithOutParent<P>(
  tree: TreeLike<P>,
  index: number,
  path: number[]
): [Tree<P>, number] {
  const { children, ...item } = tree
  const extendedItem = { ...(item as P), index, parent: null, path }
  let newIndex = index + 1
  if (!children?.length) return [extendedItem, newIndex]

  const mappedChildren = [] as Tree<P>[]
  children.forEach((child, idx) => {
    const [mappedChild, newIndex_] = _treeExtendedWithOutParent(
      child,
      newIndex,
      path.concat([idx])
    )
    newIndex = newIndex_
    mappedChildren.push(mappedChild)
  })

  return [{ ...extendedItem, children: mappedChildren }, newIndex]
}

function _treeExtended<P>(
  tree: TreeLike<P>,
  index: number,
  path: number[]
): [Tree<P>, number] {
  const [extendedTree, newIndex] = _treeExtendedWithOutParent(tree, index, path)
  const linkParents = (tree: Tree<P>, parent: Tree<P> | null) => {
    tree.parent = parent
    tree.children?.forEach((item) => linkParents(item, tree))
  }
  linkParents(extendedTree, null)

  return [extendedTree, newIndex]
}

export function nextInTreeList<P>(
  treeList: Tree<P>[],
  node: Tree<P>
  // skipNode: (node: Tree<P>) => boolean
): Tree<P> {
  let currentNode: Tree<P> | null = node
  while (true) {
    if (!currentNode.parent?.children?.length) {
      let index = currentNode.path[0]
      // Handle case if path is not populated
      if (index === undefined) {
        index = Math.max(
          treeList.findIndex((n) => n.index === node.index),
          0
        )
      }
      const nextIndex = index + 1 === treeList.length ? 0 : index + 1
      return treeList[nextIndex]
    }

    const index = currentNode.path[currentNode.path.length - 1]
    if (index + 1 < currentNode.parent.children.length) {
      return currentNode.parent.children[index + 1]
    } else {
      currentNode = currentNode.parent
    }
  }
}

export function downInTreeList<P>(treeList: Tree<P>[], node: Tree<P>): Tree<P> {
  let currentNode: Tree<P> | null = node
  while (true) {
    if (!currentNode.parent?.children?.length) {
      let index = currentNode.path[0]
      // Handle case if path is not populated
      if (index === undefined) {
        index = Math.max(
          treeList.findIndex((n) => n.index === node.index),
          0
        )
      }
      const nextIndex = index + 1 === treeList.length ? 0 : index + 1
      return treeList[nextIndex]
    }

    const index = currentNode.path[currentNode.path.length - 1]
    if (index + 1 < currentNode.parent.children.length) {
      return currentNode.parent.children[index + 1]
    } else {
      currentNode = currentNode.parent
    }
  }
}
export function upInTreeList<P>(treeList: Tree<P>[], node: Tree<P>): Tree<P> {
  let currentNode = node
  while (true) {
    if (!currentNode.parent?.children?.length) {
      let index = currentNode.path[0]
      // Handle case if path is not populated
      if (index === undefined) {
        index = Math.max(
          treeList.findIndex((n) => n.index === node.index),
          0
        )
      }
      const nextIndex = index === 0 ? treeList.length - 1 : index - 1
      return treeList[nextIndex]
    }

    const index = currentNode.path[currentNode.path.length - 1]
    if (index - 1 >= 0) {
      return currentNode.parent.children[index - 1]
    } else {
      return currentNode.parent
    }
  }
}

export function mapTreeList<P, Q>(
  treeList: TreeLike<P>[],
  f: (item: TreeLike<P>, index: number, parent: TreeLike<P> | null) => Q
): TreeLike<Q>[] {
  const mappedTreeList = [] as TreeLike<Q>[]
  let index = 0
  for (const tree of treeList) {
    const [mappedTree, updatedIndex] = _mapTree(tree, f, index, null)
    index = updatedIndex
    mappedTreeList.push(mappedTree)
  }
  return mappedTreeList
}

export function mapTree<P, Q>(
  tree: TreeLike<P>,
  f: (item: P, index: number, parent: P | null) => Q
): TreeLike<Q> {
  return _mapTree(tree, f, 0, null)[0]
}

function _mapTree<P, Q>(
  tree: TreeLike<P>,
  f: (item: P, index: number, parent: P | null) => Q,
  index: number,
  parent: TreeLike<P> | null
): [TreeLike<Q>, number] {
  const mappedItem = f(tree, index, parent) as TreeLike<Q>
  let newIndex = index + 1
  if (!tree.children?.length) return [mappedItem, newIndex]

  const mappedChildren = [] as TreeLike<Q>[]
  for (const child of tree.children) {
    const [mappedChild, newIndex_] = _mapTree(child, f, newIndex, tree)
    newIndex = newIndex_
    mappedChildren.push(mappedChild)
  }

  return [{ ...mappedItem, children: mappedChildren }, newIndex]
}

export function forEachTree<P>(
  tree: TreeLike<P>,
  f: (item: TreeLike<P>, index: number) => void,
  index: number = 0
): number {
  f(tree, index)
  let newIndex = index + 1
  if (!tree.children?.length) return newIndex

  for (const child of tree.children) {
    newIndex = forEachTree(child, f, newIndex)
  }

  return newIndex
}

export function forEachTreeList<P>(
  treeList: TreeLike<P>[],
  f: (item: TreeLike<P>, index: number) => void
): number {
  let index = 0
  for (const tree of treeList) {
    index = forEachTree(tree, f, index)
  }
  return index
}

export function findTree<P>(
  tree: TreeLike<P>,
  predicate: (item: TreeLike<P>) => boolean
): TreeLike<P> | null {
  if (predicate(tree)) return tree
  if (!tree.children?.length) return null

  for (const child of tree.children) {
    const item = findTree(child, predicate)
    if (item !== null) return item
  }

  return null
}

export function findTreeList<P>(
  treeList: TreeLike<P>[],
  predicate: (item: TreeLike<P>) => boolean
): TreeLike<P> | null {
  for (const tree of treeList) {
    const item = findTree(tree, predicate)
    if (item !== null) return item
  }
  return null
}

export function _findAllTree<P>(
  results: TreeLike<P>[],
  tree: TreeLike<P>,
  predicate: (item: TreeLike<P>) => boolean
): void {
  if (predicate(tree)) {
    results.push(tree)
  }
  if (!tree.children?.length) return

  for (const child of tree.children) {
    _findAllTree(results, child, predicate)
  }
}

export function findAllTreeList<P>(
  treeList: TreeLike<P>[],
  predicate: (item: TreeLike<P>) => boolean
): TreeLike<P>[] {
  const results: TreeLike<P>[] = []
  for (const tree of treeList) {
    _findAllTree(results, tree, predicate)
  }
  return results
}

export type FilteredTreeLike<P> = TreeLike<
  P & {
    parent: P | null
    nodeIsPredicateMatch: boolean
    childHasPredicateMatch: boolean
  }
>

/**
 * Filter a tree for a given predicate. If a child node satisfies the predicate,
 * then all parent nodes are also included
 * @param tree
 * @param predicate
 * @param requirePredicateMatchToBeIncludedInReturnValue
 * @returns a filtered tree or null if no match in the tree
 */
export function filterTree<P>(
  tree: TreeLike<P>,
  predicate: (item: TreeLike<P>, parent: TreeLike<P> | null) => boolean,
  parent: TreeLike<P> | null = null
): FilteredTreeLike<P> | null {
  const nodeIsPredicateMatch = predicate(tree, parent)
  // If nodeIsPredicateMatch we include all subassets
  // if not we recursively filter out all subassets
  const filteredChildren = [] as FilteredTreeLike<P>[]
  let childHasPredicateMatch = false
  tree.children?.forEach((subtree) => {
    const filteredSubtree = filterTree(subtree, predicate, tree)
    if (filteredSubtree) {
      filteredChildren.push(filteredSubtree)
      childHasPredicateMatch =
        childHasPredicateMatch ||
        filteredSubtree.childHasPredicateMatch ||
        filteredSubtree.nodeIsPredicateMatch
    }
  })

  if (filteredChildren.length || nodeIsPredicateMatch || childHasPredicateMatch) {
    return {
      ...tree,
      parent,
      nodeIsPredicateMatch,
      children: filteredChildren,
      childHasPredicateMatch,
    }
  }

  return null
}

export function filterTreeList<P>(
  treeList: TreeLike<P>[],
  predicate: (item: TreeLike<P>, parent: TreeLike<P> | null) => boolean
): FilteredTreeLike<P>[] {
  const filteredTreeList = [] as FilteredTreeLike<P>[]
  for (const tree of treeList) {
    const filteredTree = filterTree(tree, predicate)
    if (filteredTree) {
      filteredTreeList.push(filteredTree)
    }
  }
  return filteredTreeList
}

export function flattenTreeList<P>(treeList: TreeLike<P>[]): TreeLike<P>[] {
  const list: TreeLike<P>[] = []
  const flatten = (tree: TreeLike<P>) => {
    list.push(tree)
    tree.children?.forEach((item) => flatten(item))
  }
  treeList.forEach(flatten)
  return list
}

export function sortTree<P>(
  tree: TreeLike<P>,
  compare: (u: TreeLike<P>, v: TreeLike<P>) => number
): TreeLike<P> {
  return {
    ...tree,
    children: tree.children?.map((t) => sortTree(t, compare))?.sort(compare),
  } as TreeLike<P>
}
export function sortTreeList<P>(
  treeList: TreeLike<P>[],
  compare: (u: P, v: P) => number
): TreeLike<P>[] {
  return treeList.map((tree) => sortTree(tree, compare)).sort(compare)
}

export function sortTreeWithFirstItems<P>(
  treeList: Tree<P>[],
  firstItems: Tree<P>[],
  compare: (u: P, v: P) => number
) {
  let matchSortMap: Record<string, number> = {}
  firstItems.forEach((match) => {
    for (let k = 0; k < match.path.length; k++) {
      const id = match.path.slice(0, k + 1).join("-")
      if (matchSortMap[id] === undefined) {
        matchSortMap[id] = 1
      }
    }
  })
  const getMatchPrio = (a: Tree<P>) => matchSortMap[a.path.join("-")] ?? 0
  // Finally apply the sorting
  return sortTreeList(treeList, (a, b) => {
    const prioA = getMatchPrio(a)
    const prioB = getMatchPrio(b)
    if (prioA === prioB) {
      return compare(a, b)
    } else if (prioA > prioB) {
      return -1
    } else {
      return 1
    }
  })
}
