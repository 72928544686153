import WorkOrderDetail from "@components/work-order/work-order-detail"
import { Navigate, useParams } from "react-router-dom"

const TaskDetailPage = (props: { isTemplate?: boolean }) => {
  const { workOrderId } = useParams()

  if (!workOrderId) {
    return <Navigate to="/" />
  }

  return <WorkOrderDetail asPage workOrderId={workOrderId} isTemplate={props.isTemplate} />
}

export default TaskDetailPage
