import useOperatingSystem, { OperatingSystem } from "@hooks/use-operating-system"
import { MagnifyingGlass } from "@phosphor-icons/react"
import classNames from "classnames"
import { useTranslation } from "react-i18next"

import Button from "./button"
import { openElaraCommandDialog } from "./elara-command"

export const SearchButton = (props: { className?: string; collapsed?: boolean }) => {
  const { t } = useTranslation()
  const os = useOperatingSystem()

  const onClick = () => {
    openElaraCommandDialog()
  }

  if (props.collapsed) {
    return (
      <button
        type="button"
        onClick={onClick}
        className={classNames(props.className, "p-2 rounded hover:bg-gray-100")}>
        <MagnifyingGlass className="text-gray-500" />
      </button>
    )
  }

  return (
    <Button
      type="secondary"
      size="extra-small"
      onClick={onClick}
      className={props.className}>
      <MagnifyingGlass />
      <p className="ml-2 truncate !text-sm text-gray-500">{t("search")}</p>
      <div className="ml-auto whitespace-nowrap text-xs text-gray-500">
        <span className="hidden sm:inline">
          {os === OperatingSystem.Mac ? "⌘" : t("navigation:control_hotkey")} + K
        </span>
      </div>
    </Button>
  )
}
