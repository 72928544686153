import classNames from "classnames"
import React from "react"

export type TouchTargetSizeProps = React.HTMLAttributes<HTMLSpanElement> & {
  vertical?: boolean
  horizontal?: boolean
}

// Allows to increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
export const TouchTargetSize = React.forwardRef<HTMLSpanElement, TouchTargetSizeProps>(
  ({ vertical = true, horizontal = true, ...props }, ref) => {
    return (
      <span
        {...props}
        ref={ref}
        className={classNames(
          "absolute top-1/2 left-1/2 w-full h-full transform -translate-x-1/2 -translate-y-1/2",
          props.className,
          { "min-h-[36px]": vertical, "min-w-[36px]": horizontal }
        )}
      />
    )
  }
)

export default TouchTargetSize
