import { ReactNode } from "react"

export function Section(props: {
  icon: ReactNode
  title: ReactNode
  action?: ReactNode
  children: ReactNode
}) {
  return (
    <div className="">
      <div className="mb-1.5 ml-3 flex h-9 items-center justify-between">
        <h2 className="flex items-center gap-x-1 font-semibold text-gray-800">
          {props.icon}
          {props.title}
        </h2>
        {props.action}
      </div>
      {props.children}
    </div>
  )
}
