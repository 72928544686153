import { DocumentCard, TimeAgo, useConfirmModal, UserTag } from "@components/shared"
import { CommentField } from "@components/shared/comment"
import ImageGallery from "@components/shared/gallery"
import HorizontalTabs from "@components/shared/horizontal-tabs"
import { StaticRichText } from "@components/shared/static-rich-text"
import { Tooltip } from "@components/shared/tooltip"
import { useUser } from "@contexts/user-context"
import { IWorkOrderStatusEnum, IWorkOrderTypeEnum } from "@elara/db"
import { useOrderBy } from "@elara/select"
import {
  ICommentFragment,
  IUserTagFragment,
  IWorkOrderFragment,
  IWorkOrderReportFragment,
} from "@graphql/documents/fragments.generated"
import { uuid } from "@graphql/scalars"
import i18n from "@i18n"
import {
  DotsThreeVertical,
  ListPlus,
  PencilSimpleLine,
  TrashSimple,
} from "@phosphor-icons/react"
import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu"
import Icons from "@resources/icons"
import { dateLocaleMap, formatDate } from "@utils/date"
import classNames from "classnames"
import { Duration, formatDuration, intervalToDuration } from "date-fns"
import React, { ReactNode, useMemo, useState } from "react"
import { Trans } from "react-i18next"

import { useDeleteCommmentMutation } from "../../graphql/documents/comment.generated"
import { useDeleteTimeReportMutation } from "../../graphql/documents/work-order.generated"
import { IPermissionScopeEnum, useDisclosure, usePermissionScope } from "../../hooks"
import toast from "../shared/toast"
import { WorkOrderCompletedEditForm } from "./work-order-form-v2/work-order-completed-edit-form"
import { statusIcon, translateWorkOrderStatus } from "./work-order-status"
import { WorkOrderTimeTrackingForm } from "./work-order-time-tracking-form"

type WorkOrderActvity = { id: uuid; activity_by: IUserTagFragment; activity_at: string } & {
  type: "status"
  payload: { new: IWorkOrderStatusEnum; old: IWorkOrderStatusEnum }
}

type ActivityType = "comment" | "report" | "created_at" | "completed_at" | "activity"
// create a new array of merged work order reports and work order comments then sort it by created_at
type Activity =
  | {
      type: "report"
      key: string
      report: IWorkOrderReportFragment
      timestamp: string
      duration: Duration | null
    }
  | { type: "comment"; key: string; comment: ICommentFragment; timestamp: string }
  | { type: "created_at"; key: string; timestamp: string; createdBy: IUserTagFragment }
  | {
      type: "activity"
      key: string
      timestamp: string
      activity: WorkOrderActvity
    }
  | {
      type: "completed_at"
      key: string
      completedAt: string
      completedBy: IUserTagFragment
      timestamp: string
    }

/*const calculateTotalReportDuration = (reports: IWorkOrderReportFragment[]) => {
  const durations = reports.map((r) => {
    return intervalToDuration({
      start: new Date(r.started_at!),
      end: new Date(r.finished_at!),
    })
  })
  const duration = durations.reduce(
    (a, b) => ({
      hours: (a.hours ?? 0) + (b.hours ?? 0),
      minutes: (a.minutes ?? 0) + (b.minutes ?? 0),
    }),
    { hours: 0, minutes: 0 } as { hours: number; minutes: number }
  )
  return {
    hours: duration.hours + Math.floor(duration.minutes / 60),
    minutes: duration.minutes % 60,
  }
}*/

export const ActivityForm = (props: { workOrder: IWorkOrderFragment }) => {
  const editScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)
  const dataEntryScope = usePermissionScope(IPermissionScopeEnum.AppDataEntry)
  const canEnterReport = editScope.hasScope || dataEntryScope.hasScope

  return (
    <>
      <HorizontalTabs.Root
        orientation="horizontal"
        defaultValue="comment"
        className="mt-3 flex flex-1 flex-col print:hidden">
        <HorizontalTabs.List>
          <HorizontalTabs.Trigger value="comment">
            {i18n.t("tasks:fields.comment", { count: 2 })}
          </HorizontalTabs.Trigger>
          {canEnterReport && (
            <HorizontalTabs.Trigger value="report">
              {i18n.t("tasks:types.report")}
            </HorizontalTabs.Trigger>
          )}
        </HorizontalTabs.List>

        <HorizontalTabs.Content value="comment" className="relative min-h-[256px] pt-3">
          <CommentField workOrderId={props.workOrder.id} />
        </HorizontalTabs.Content>

        {canEnterReport && (
          <HorizontalTabs.Content value="report" className="min-h-[256px] pt-3">
            <WorkOrderTimeTrackingForm workOrderId={props.workOrder.id} />
          </HorizontalTabs.Content>
        )}
      </HorizontalTabs.Root>
    </>
  )
}

export const ActivityItem = (
  props: React.PropsWithChildren<{
    activityType: ActivityType
    icon: ReactNode
    hasAction?: boolean
    border?: boolean
    onEdit?: () => void
    onDelete?: () => void
  }>
) => {
  return (
    <li className="mb-3 ml-6 list-none last:mb-0">
      <span className="absolute -left-3 flex h-5 w-5 items-center justify-center rounded-full bg-white text-xl text-gray-700 ring-8 ring-white">
        {props.icon}
      </span>
      <div
        className={classNames("relative flex items-center justify-between", {
          "px-2 py-3 border border-gray-200 rounded": props.border,
        })}>
        <div className={classNames("flex-1 px-2", { "!pl-0": !props.border })}>
          {props.children}
        </div>
        {props.hasAction && (
          <DropdownPrimitive.Root>
            <DropdownPrimitive.Trigger className="right-4 top-4 flex h-8 w-8 cursor-pointer items-center justify-center self-start rounded text-gray-700 hover:bg-gray-100 hover:text-gray-900 radix-state-open:bg-gray-100">
              <DotsThreeVertical size={18} />
            </DropdownPrimitive.Trigger>

            <DropdownPrimitive.Content
              align="start"
              side="right"
              sideOffset={4}
              className="rounded bg-white p-1 drop-shadow">
              <DropdownPrimitive.Item
                className="flex cursor-pointer items-center px-2 py-1 text-sm hover:bg-gray-100"
                onClick={props.onEdit}>
                <PencilSimpleLine className="mr-2.5" /> {i18n.t("common:edit")}
              </DropdownPrimitive.Item>
              <DropdownPrimitive.Item
                className="flex cursor-pointer items-center px-2 py-1 text-sm hover:bg-gray-100"
                onClick={props.onDelete}>
                <TrashSimple className="mr-2.5" /> {i18n.t("common:delete")}
              </DropdownPrimitive.Item>
            </DropdownPrimitive.Content>
          </DropdownPrimitive.Root>
        )}
      </div>
    </li>
  )
}

export const WorkOrderActivitySection = (props: {
  workOrder: IWorkOrderFragment
  order: "oldest-first" | "newest-first"
  hideActivityForm?: boolean
}) => {
  const { workOrder } = props

  const user = useUser()
  const editCompletedAt = useDisclosure()

  const [editedActivity, setEditedActivity] = useState<Activity | null>(null)

  const [, deleteTimeReport] = useDeleteTimeReportMutation()
  const [, deleteComment] = useDeleteCommmentMutation()
  const dataEntryScope = usePermissionScope(IPermissionScopeEnum.AppDataEntry)
  const editScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)

  const confirmDeleteTimeReport = useConfirmModal({
    title: i18n.t("common:delete_token", { token: i18n.t("tasks:fields.time_report") }),
    content: i18n.t("common:messages.token_delete_confirmation", {
      token: i18n.t("tasks:fields.time_report"),
    }),
    okText: i18n.t("common:delete"),
    cancelText: i18n.t("common:cancel"),
    okButtonProps: { autoFocus: true },
    onOk: async (id: string) => {
      const res = await deleteTimeReport(
        { id },
        editScope.hasScope ? editScope.context() : dataEntryScope.context()
      )
      if (!res.error) {
        toast.success(
          i18n.t("common:messages.token_delete_success", {
            token: i18n.t("tasks:fields.time_report"),
          })
        )
      } else {
        toast.error(
          i18n.t("common:messages.token_delete_failure", {
            token: i18n.t("tasks:fields.time_report"),
          })
        )
      }
    },
  })

  const confirmDeleteComment = useConfirmModal({
    title: i18n.t("common:delete_token", {
      token: i18n.t("tasks:fields.comment", { count: 1 }),
    }),
    okText: i18n.t("common:delete"),
    cancelText: i18n.t("common:cancel"),
    content: i18n.t("common:messages.token_delete_confirmation", {
      token: i18n.t("tasks:fields.comment", { count: 1 }),
    }),
    onOk: async (id: string) => {
      const res = await deleteComment({ id, now: new Date().toISOString() })
      if (!res.error) {
        toast.success(
          i18n.t("common:messages.token_delete_success", {
            token: i18n.t("tasks:fields.comment", { count: 1 }),
          })
        )
      } else {
        toast.error(
          i18n.t("common:messages.token_delete_failure", {
            token: i18n.t("tasks:fields.comment", { count: 1 }),
          })
        )
      }
    },
    okButtonProps: { autoFocus: true },
  })

  const isState = props.workOrder.type === IWorkOrderTypeEnum.State

  const [activities] = useOrderBy(
    useMemo(
      () =>
        [
          {
            type: "created_at",
            key: "created_at",
            timestamp: isState ? props.workOrder.completed_at : workOrder.created_at,
            createdBy: isState ? props.workOrder.completed_by : workOrder.created_by,
          } as Activity,
        ]
          .concat(
            workOrder.reports.map(
              (report) =>
                ({
                  type: "report",
                  key: report.id,
                  report,
                  timestamp: report.started_at,
                  duration:
                    report.started_at && report.finished_at
                      ? intervalToDuration({
                          start: new Date(report.started_at),
                          end: new Date(report.finished_at),
                        })
                      : null,
                } as Activity)
            )
          )
          .concat(
            workOrder.comments.map((comment: ICommentFragment) => ({
              type: "comment",
              key: comment.id,
              comment,
              timestamp: comment.created_at,
            }))
          )
          .concat(
            workOrder.completed_at && !isState
              ? [
                  {
                    type: "completed_at",
                    key: "completed_at",
                    completedAt: workOrder.completed_at,
                    completedBy: workOrder.completed_by,
                    timestamp: workOrder.completed_at,
                  } as Activity,
                ]
              : []
          )
          .concat(
            workOrder.activities.map(
              (a) =>
                ({
                  type: "activity",
                  key: a.id,
                  timestamp: a.activity_at,
                  activity: a as unknown as WorkOrderActvity,
                } as Activity)
            )
          ),
      [workOrder.reports, workOrder.comments]
    ),
    { timestamp: props.order === "oldest-first" ? "asc" : "desc" }
  )

  return (
    <div>
      <div className="pl-3">
        <ul className="relative border-l border-gray-300">
          {activities.map((activity) => {
            const onEdit = () => setEditedActivity(activity)
            switch (activity.type) {
              case "comment":
                const { comment } = activity

                const images = comment.attachments.filter(({ upload }) =>
                  upload.mime_type.startsWith("image")
                )

                if (editedActivity?.key === activity.key) {
                  return (
                    <ActivityItem
                      icon={<Icons.Comment />}
                      activityType={activity.type}
                      key={activity.key}
                      hasAction={false}
                      border>
                      <CommentField
                        workOrderId={props.workOrder.id}
                        comment={comment}
                        onClearCommentField={() => setEditedActivity(null)}
                      />
                    </ActivityItem>
                  )
                }
                return (
                  <ActivityItem
                    activityType={activity.type}
                    key={activity.key}
                    icon={<Icons.Comment />}
                    onDelete={() => confirmDeleteComment.show(activity.comment.id)}
                    onEdit={onEdit}
                    hasAction={user.id === comment.created_by.id}
                    border>
                    <div className="text-xs font-normal sm:text-sm">
                      <div>
                        <div className="-ml-2 -mt-1 flex items-center font-medium">
                          <UserTag user={comment.created_by} />
                          {/* {comment.created_by.first_name} {comment.created_by.last_name} */}
                          <span className="ml-2 font-normal text-gray-600">
                            <TimeAgo date={new Date(comment.created_at)} />
                            {comment.edited_at && (
                              <span>
                                {" "}
                                <Tooltip
                                  content={formatDate(new Date(comment.edited_at), "Pp")}>
                                  <span> ({i18n.t("common:edited")})</span>
                                </Tooltip>
                              </span>
                            )}
                          </span>
                        </div>

                        <StaticRichText
                          className="mt-2 select-text"
                          style={{ wordBreak: "break-word" }}
                          content={comment.content}
                        />
                      </div>

                      {comment.attachments && (
                        <div className="mt-3 grid gap-3">
                          <ImageGallery
                            images={images.map(({ upload }) => ({
                              data: upload,
                              progress: 100,
                            }))}
                          />

                          {comment.attachments.map(({ upload }) => {
                            if (upload.mime_type.startsWith("image")) {
                              return null
                            }

                            return <DocumentCard key={upload.id} document={upload} />
                          })}
                        </div>
                      )}
                    </div>
                  </ActivityItem>
                )
              case "report":
                const { report, duration } = activity
                if (editedActivity?.key === activity.key) {
                  return (
                    <ActivityItem
                      icon={<Icons.Time />}
                      activityType={activity.type}
                      key={activity.key}
                      hasAction={false}
                      border>
                      <WorkOrderTimeTrackingForm
                        onCancel={() => setEditedActivity(null)}
                        onSubmit={() => setEditedActivity(null)}
                        workOrderId={props.workOrder.id}
                        report={report}
                      />
                    </ActivityItem>
                  )
                }
                return (
                  <ActivityItem
                    key={activity.key}
                    icon={<Icons.Time />}
                    activityType={activity.type}
                    onDelete={() => confirmDeleteTimeReport.show(activity.report.id)}
                    onEdit={onEdit}
                    hasAction
                    border>
                    <div className="text-xs sm:text-sm">
                      <div className="mb-2">
                        <span className="mr-1 font-medium">
                          {i18n.t("tasks:types.report")}
                          <span className="font-normal"> {i18n.t("common:for")} </span>
                          {report.user.first_name} {report.user.last_name}
                        </span>
                        <time className="mr-1 text-right text-gray-600 sm:mb-0">
                          {report.started_at &&
                            formatDate(
                              new Date(report.started_at),
                              `P '${i18n.t("calendar:tokens.from")}' p`
                            )}
                          {report.finished_at &&
                            formatDate(
                              new Date(report.finished_at),
                              ` '${i18n.t("calendar:tokens.to")}' p`
                            )}
                        </time>
                        <time className="ml-2 text-gray-600">
                          {report.finished_at !== report.started_at && duration && (
                            <>
                              (
                              {formatDuration(duration, {
                                format: ["hours", "minutes"],
                                locale: dateLocaleMap[i18n.language],
                              })}
                              )
                            </>
                          )}
                        </time>
                      </div>
                      <div className="mt-2 font-normal">
                        {report.description && (
                          <StaticRichText
                            className="mt-2 min-w-0"
                            style={{ wordBreak: "break-word" }}
                            content={report.description}
                          />
                        )}
                      </div>
                      <div className="mt-2 text-gray-500">
                        {i18n.t("tasks:fields.created_by")} {report.submitted_by.first_name}{" "}
                        {report.submitted_by.last_name}{" "}
                        <TimeAgo date={new Date(report.created_at)} />{" "}
                        {report.edited_at && (
                          <span>
                            {" "}
                            <Tooltip content={formatDate(new Date(report.edited_at), "Pp")}>
                              <span> ({i18n.t("common:edited")})</span>
                            </Tooltip>
                          </span>
                        )}
                      </div>
                    </div>
                  </ActivityItem>
                )
              case "created_at":
                return (
                  <ActivityItem
                    key={activity.key}
                    activityType={activity.type}
                    icon={<ListPlus />}>
                    <div className="mr-1 flex flex-wrap items-center text-xs sm:text-sm">
                      <span className="font-normal">
                        {i18n.t("tasks:fields.created_by")}{" "}
                        <span className="mr-1 font-medium">
                          {activity.createdBy?.first_name} {activity.createdBy?.last_name}
                        </span>
                      </span>
                      <time className="text-gray-600 sm:order-last sm:mb-0">
                        {i18n.t("calendar:tokens.on")}{" "}
                        {formatDate(
                          new Date(activity.timestamp),
                          `P '${i18n.t("calendar:tokens.at")}' p`
                        )}
                      </time>
                    </div>
                  </ActivityItem>
                )
              case "activity":
                if (activity.activity.type === "status") {
                  return (
                    <ActivityItem
                      key={activity.key}
                      activityType={activity.type}
                      icon={statusIcon(activity.activity.payload.new)}>
                      <div className="mr-1 text-xs sm:text-sm">
                        <Trans
                          i18n={i18n}
                          i18nKey="tasks:messages.status_change"
                          values={{
                            name: `${activity.activity.activity_by?.first_name} ${activity.activity.activity_by?.last_name}`,
                            oldStatus: translateWorkOrderStatus(
                              activity.activity.payload.old
                            ),
                            newStatus: translateWorkOrderStatus(
                              activity.activity.payload.new
                            ),
                          }}>
                          <span className="font-medium">name</span>
                          hat den Status von
                          <span className="font-medium">oldStatus</span>
                          auf
                          <span className="font-medium">newStatus</span>
                          geändert.
                        </Trans>

                        <span className="ml-2 text-gray-500">
                          <TimeAgo
                            date={new Date(activity.timestamp)}
                            fullTimestampIfOlderThanMs={30 * 24 * 60 * 60 * 1000}
                          />
                        </span>
                      </div>
                    </ActivityItem>
                  )
                }
                return null
              case "completed_at":
                return (
                  <ActivityItem
                    key={activity.key}
                    activityType={activity.type}
                    icon={<Icons.Check />}>
                    <div className="flex flex-wrap items-center text-xs sm:text-sm">
                      <div className="mr-1 font-normal">
                        {i18n.t("tasks:fields.completed_by")}{" "}
                        <span className="font-medium">
                          {activity.completedBy?.first_name}{" "}
                          {activity.completedBy?.last_name}
                        </span>
                      </div>
                      <time className="ml-1 font-normal text-gray-600 sm:order-2 sm:mb-0">
                        {i18n.t("calendar:tokens.on")}{" "}
                        {formatDate(
                          new Date(activity.completedAt),
                          `P '${i18n.t("calendar:tokens.at")}' p`
                        )}
                      </time>
                      {editScope.hasScope && (
                        <button
                          type="button"
                          className="ml-1 flex cursor-pointer select-none items-center rounded px-2 py-1 font-semibold hover:bg-gray-100 print:hidden sm:order-last"
                          onClick={editCompletedAt.onOpen}>
                          <PencilSimpleLine className="mr-1" /> {i18n.t("common:edit")}
                        </button>
                      )}
                    </div>
                  </ActivityItem>
                )
              default:
                return null
            }
          })}
        </ul>
        {confirmDeleteTimeReport.component}
        {confirmDeleteComment.component}
        {workOrder.completed_at && workOrder.completed_by?.id && (
          <WorkOrderCompletedEditForm
            isOpen={editCompletedAt.isOpen}
            onOpenChange={editCompletedAt.changeOpen}
            workOrderId={workOrder.id}
            completedAt={workOrder.completed_at}
            completedById={workOrder.completed_by?.id}
          />
        )}
      </div>
      {!editedActivity && !props.hideActivityForm && <ActivityForm workOrder={workOrder} />}
    </div>
  )
}
