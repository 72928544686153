import { UserAvatarPlaceholder } from "@components/shared/avatar"
import AvatarGroup, { AssetAvatarGroup } from "@components/shared/avatar-group"
import { useFeature } from "@contexts/feature-flag-context"
import { IWorkOrderDataViewFragment } from "@graphql/documents/fragments.generated"
import Icons from "@resources/icons"
import { cn } from "@utils"

import { statusIcon } from "./work-order-status"

type TaskEventItemProps = {
  task: IWorkOrderDataViewFragment
}

const TaskEventItem = ({ task }: TaskEventItemProps) => {
  const hasMaintenance = useFeature("maintenance")
  const hasAssetsOrAssignees = task.assets.length > 0 || task.assignees.length > 0
  return (
    <div
      className={cn(
        "w-full items-stretch justify-stretch gap-0.5 overflow-hidden rounded-lg bg-gray-100 px-1 py-0.5 leading-none",
        {
          "flex flex-wrap items-center": !hasAssetsOrAssignees,
          "space-y-1": hasAssetsOrAssignees,
        }
      )}>
      <div className="flex min-w-0 items-center justify-stretch gap-1 leading-none">
        <span className="shrink-0">{statusIcon(task.status)}</span>
        <span className="text-xs text-gray-500">#{task.work_order_number}</span>

        {hasAssetsOrAssignees && (
          <div className="inline-flex flex-1 justify-end gap-1">
            <AssetAvatarGroup
              size={15}
              assets={task.assets.map((a) => a.asset)}
              hideFirstName
              hideExtraCount
            />
            <AvatarGroup
              size={15}
              hideFirstName
              hideExtraCount
              items={task.assignees.map(({ user }) => ({
                id: user.id,
                avatar: user.avatar,
                label: `${user.first_name} ${user.last_name}`,
                placeholder: <UserAvatarPlaceholder user={user} />,
              }))}
            />
          </div>
        )}
      </div>
      <div className="flex min-w-0 items-center gap-2 text-xs">
        <span className="truncate font-medium leading-none">{task.name}</span>
        {!hasMaintenance && task.recurrence_info && <span>{<Icons.Repeat />}</span>}
      </div>
    </div>
  )
}

export default TaskEventItem
