import {
  IMutationRootDeleteSettingConfigArgs,
  IMutationRootInsertSettingConfigOneArgs,
  ISettingTypeEnum,
} from "@elara/db"
import {
  ITeamsQuery,
  ITeamsQueryVariables,
  TeamsDocument,
} from "@graphql/documents/team.generated"
import {
  CheckEmailNotificationSettingDocument,
  CheckPushNotificationSettingDocument,
  ICheckEmailNotificationSettingQuery,
  ICheckPushNotificationSettingQuery,
  IDeleteSettingMutation,
  IInsertSettingMutation,
} from "@graphql/documents/user.generated"
import { UpdateResolver } from "@urql/exchange-graphcache"

type Mutations = {
  [fieldName: string]: UpdateResolver
}

const mutations: Mutations = {
  // Insert
  insert_setting_config_one(
    result: Pick<IInsertSettingMutation, "insert_setting_config_one">,
    args: IMutationRootInsertSettingConfigOneArgs,
    cache
  ) {
    // Check that we got something back and the we really passed an assetId
    const settingRes = result.insert_setting_config_one
    const settingType = args.object.type

    if (!settingRes) return

    switch (settingType) {
      case ISettingTypeEnum.DisableEmailNotification:
        cache.updateQuery<ICheckEmailNotificationSettingQuery>(
          { query: CheckEmailNotificationSettingDocument },
          (data) => {
            if (data?.setting_config_aggregate.aggregate?.count != null) {
              data.setting_config_aggregate.aggregate.count += 1
            }
            return data
          }
        )
        return
      case ISettingTypeEnum.EnableTeamNotification:
        cache.updateQuery<ITeamsQuery, ITeamsQueryVariables>(
          {
            query: TeamsDocument,
          },
          (data) => {
            if (!data || !data.team) return data
            const _data = {
              ...data,
              team: data.team.map((t) =>
                t.id !== args.object.team_id ? t : { ...t, notification_is_enabled: true }
              ),
            }
            return _data
          }
        )
        return
      case ISettingTypeEnum.EnablePushNotification:
        cache.updateQuery<ICheckPushNotificationSettingQuery>(
          { query: CheckPushNotificationSettingDocument },
          (data) => {
            if (data?.setting_config_aggregate.aggregate?.count != null) {
              data.setting_config_aggregate.aggregate.count += 1
            }
            return data
          }
        )
        return
      default:
        throw new Error("Unexpected setting type")
    }
  },

  // delete
  delete_setting_config(
    result: Pick<IDeleteSettingMutation, "delete_setting_config">,
    args: IMutationRootDeleteSettingConfigArgs,
    cache
  ) {
    // Check that we got something back and the we really passed an assetId
    const settingRes = result.delete_setting_config
    const settingType = args.where.type?._eq

    if (!settingRes) return

    switch (settingType) {
      case ISettingTypeEnum.DisableEmailNotification:
        cache.updateQuery<ICheckEmailNotificationSettingQuery>(
          { query: CheckEmailNotificationSettingDocument },
          (data) => {
            if (data?.setting_config_aggregate.aggregate?.count != null) {
              data.setting_config_aggregate.aggregate.count -= 1
            }
            return data
          }
        )
        return
      case ISettingTypeEnum.EnableTeamNotification:
        cache.updateQuery<ITeamsQuery, ITeamsQueryVariables>(
          {
            query: TeamsDocument,
          },
          (data) => {
            if (!data || !data.team) return data
            const _data = {
              ...data,
              team: data.team.map((t) =>
                t.id !== args.where.team_id?._eq
                  ? t
                  : { ...t, notification_is_enabled: false }
              ),
            }
            return _data
          }
        )
        return
      case ISettingTypeEnum.EnablePushNotification:
        cache.updateQuery<ICheckPushNotificationSettingQuery>(
          { query: CheckPushNotificationSettingDocument },
          (data) => {
            if (data?.setting_config_aggregate.aggregate?.count != null) {
              data.setting_config_aggregate.aggregate.count -= 1
            }
            return data
          }
        )
        return
      default:
        throw new Error("Unexpected setting type")
    }
  },
}

export default {
  mutations,
}
