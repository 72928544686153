import type { SelectItem } from "@components/asset/asset-select"
import { IAssetSelectDataFragment } from "@graphql/documents/fragments.generated"
import { naturalCompare } from "@utils"

import { buildTreeListFromId, mapTreeList, sortTreeList } from "./tree"

export function buildAssetsTree(
  assets: IAssetSelectDataFragment[] | undefined | null
): SelectItem[] {
  if (!assets) return []

  return sortTreeList(
    mapTreeList(
      buildTreeListFromId(assets, {
        getItemId: (a) => a.id,
        getParentItemId: (a) => a.parent_asset_id,
      }),
      (a) => ({
        label: (
          <span>
            {a.name} <span className="text-gray-500">{a.public_id}</span>{" "}
            {a.place && <span className="text-gray-500">{a.place?.name}</span>}
          </span>
        ),
        value: a.id,
        searchValue: `${a.name} ${a.public_id} ${a.place?.name ?? ""}`.trim(),
      })
    ),
    (a, b) => naturalCompare(a.searchValue, b.searchValue)
  )
}
