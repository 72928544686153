import { Transition } from "@headlessui/react"
import i18n from "@i18n"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import classNames from "classnames"
import { Fragment } from "react"
import { OnResultFunction, QrReader } from "react-qr-reader"

export type ScannerDialogProps = {
  open: boolean
  onOpenChange: (open: boolean) => void
  onScan?: (result: string) => void
  onCancel?: () => void
}

export const ScannerDialog = (props: ScannerDialogProps) => {
  const constraints: MediaTrackConstraints = {
    aspectRatio: 1,
    facingMode: "environment",
  }

  const onResult: OnResultFunction = (result) => {
    if (!result) return

    const text = result.getText()
    props.onScan?.(text)
    props.onOpenChange(false)
  }

  return (
    <DialogPrimitive.Root open={props.open} onOpenChange={props.onOpenChange}>
      <DialogPrimitive.Portal forceMount>
        <Transition.Root show={props.open}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <DialogPrimitive.Overlay
              forceMount
              className="fixed inset-0 z-20 bg-black/50"
            />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <DialogPrimitive.Content
              forceMount
              className={classNames(
                "fixed z-50 overflow-hidden",
                "w-[95vw] max-w-md rounded-lg md:w-full",
                "top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]",
                "bg-white",
                "focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
              )}>
              <DialogPrimitive.Description className="text-center text-sm font-medium text-gray-700">
                <QrReader
                  className="relative overflow-hidden rounded after:absolute after:inset-x-0 after:top-0 after:h-[3px] after:animate-scanner after:bg-white after:opacity-50 after:shadow-2xl after:content-['']"
                  onResult={onResult}
                  constraints={constraints}
                />

                <p className="p-4">{i18n.t("qrcode:dialogs.scanner.description")}</p>
              </DialogPrimitive.Description>
            </DialogPrimitive.Content>
          </Transition.Child>
        </Transition.Root>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  )
}
