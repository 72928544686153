// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IViewCollectionFragment = { __typename: 'view_collection', id: uuid, data_type: Types.IViewDataTypeEnum, views: {} };

export type IViewCollectionForTeamQueryVariables = Types.Exact<{
  teamId: Types.Scalars['uuid']['input'];
}>;


export type IViewCollectionForTeamQuery = { view_collection: Array<{ __typename: 'view_collection', id: uuid, data_type: Types.IViewDataTypeEnum, views: {} }> };

export type IUpdateViewsInCollectionMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['uuid']['input']>;
  teamId: Types.Scalars['uuid']['input'];
  dataType?: Types.InputMaybe<Types.IViewDataTypeEnum>;
  views?: Types.InputMaybe<Types.Scalars['jsonb']['input']>;
}>;


export type IUpdateViewsInCollectionMutation = { insert_view_collection_one: { __typename: 'view_collection', id: uuid, data_type: Types.IViewDataTypeEnum, views: {} } | null };

export type IViewCollectionForUserQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid']['input'];
}>;


export type IViewCollectionForUserQuery = { view_collection: Array<{ __typename: 'view_collection', id: uuid, data_type: Types.IViewDataTypeEnum, views: {} }> };

export type IUpdateUserViewsInCollectionMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['uuid']['input']>;
  userId: Types.Scalars['uuid']['input'];
  dataType?: Types.InputMaybe<Types.IViewDataTypeEnum>;
  views?: Types.InputMaybe<Types.Scalars['jsonb']['input']>;
}>;


export type IUpdateUserViewsInCollectionMutation = { insert_view_collection_one: { __typename: 'view_collection', id: uuid, data_type: Types.IViewDataTypeEnum, views: {} } | null };

export const ViewCollectionFragmentDoc = gql`
    fragment ViewCollection on view_collection {
  id
  data_type
  views
}
    `;
export const ViewCollectionForTeamDocument = gql`
    query ViewCollectionForTeam($teamId: uuid!) {
  view_collection(where: {team_id: {_eq: $teamId}}) {
    ...ViewCollection
  }
}
    ${ViewCollectionFragmentDoc}`;

export function useViewCollectionForTeamQuery(options: Omit<Urql.UseQueryArgs<IViewCollectionForTeamQueryVariables>, 'query'>) {
  return Urql.useQuery<IViewCollectionForTeamQuery, IViewCollectionForTeamQueryVariables>({ query: ViewCollectionForTeamDocument, ...options });
};
export const UpdateViewsInCollectionDocument = gql`
    mutation UpdateViewsInCollection($id: uuid, $teamId: uuid!, $dataType: view_data_type_enum, $views: jsonb) {
  insert_view_collection_one(
    object: {id: $id, team_id: $teamId, data_type: $dataType, views: $views}
    on_conflict: {constraint: view_collection_pkey, update_columns: [views]}
  ) {
    ...ViewCollection
  }
}
    ${ViewCollectionFragmentDoc}`;

export function useUpdateViewsInCollectionMutation() {
  return Urql.useMutation<IUpdateViewsInCollectionMutation, IUpdateViewsInCollectionMutationVariables>(UpdateViewsInCollectionDocument);
};
export const ViewCollectionForUserDocument = gql`
    query ViewCollectionForUser($userId: uuid!) {
  view_collection(where: {user_id: {_eq: $userId}}) {
    ...ViewCollection
  }
}
    ${ViewCollectionFragmentDoc}`;

export function useViewCollectionForUserQuery(options: Omit<Urql.UseQueryArgs<IViewCollectionForUserQueryVariables>, 'query'>) {
  return Urql.useQuery<IViewCollectionForUserQuery, IViewCollectionForUserQueryVariables>({ query: ViewCollectionForUserDocument, ...options });
};
export const UpdateUserViewsInCollectionDocument = gql`
    mutation UpdateUserViewsInCollection($id: uuid, $userId: uuid!, $dataType: view_data_type_enum, $views: jsonb) {
  insert_view_collection_one(
    object: {id: $id, user_id: $userId, data_type: $dataType, views: $views}
    on_conflict: {constraint: view_collection_pkey, update_columns: [views]}
  ) {
    ...ViewCollection
  }
}
    ${ViewCollectionFragmentDoc}`;

export function useUpdateUserViewsInCollectionMutation() {
  return Urql.useMutation<IUpdateUserViewsInCollectionMutation, IUpdateUserViewsInCollectionMutationVariables>(UpdateUserViewsInCollectionDocument);
};