import { AssetWithParents } from "@components/asset/asset-with-parents"
import { selectAsset } from "@components/select/asset-multi-select-dialog"
import { Button, Image } from "@components/shared"
import { IPermissionScopeEnum, uuid } from "@elara/db"
import { IWorkOrderDataViewFragment } from "@graphql/documents/fragments.generated"
import {
  DeleteAssetFromWorkOrderDocument,
  IDeleteAssetFromWorkOrderMutation,
  IDeleteAssetFromWorkOrderMutationVariables,
  useDeleteWorkOrderAssetsMutation,
  useInsertWorkOrderAssetsMutation,
} from "@graphql/documents/work-order.generated"
import { usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { Check, PencilSimple, Plus, Shapes, Swatches, X } from "@phosphor-icons/react"
import Icons from "@resources/icons"
import { cn } from "@utils"
import { LinkWithBackgroundLocation } from "@utils/location"
import { useState } from "react"
import { useClient } from "urql"

export const TaskObjects = (props: { workOrder: IWorkOrderDataViewFragment }) => {
  const client = useClient()
  const [, insertAssets] = useInsertWorkOrderAssetsMutation()
  const [, deleteAssets] = useDeleteWorkOrderAssetsMutation()
  const editScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)

  const [edit, setEdit] = useState(false)
  const { workOrder } = props

  const pickObject = async () => {
    let assetIds = await selectAsset({
      initialValues: {
        assetIds: workOrder.assets.map(({ asset }) => asset.id),
      },
    })

    if (!assetIds) return

    try {
      await deleteAssets({ work_order_id: workOrder.id }, editScope.context())

      await insertAssets(
        {
          data: assetIds.map((assetId) => ({
            work_order_id: workOrder.id,
            asset_id: assetId,
          })),
        },
        editScope.context()
      )
    } catch {}
  }

  const onDelete = async (assetId: uuid) => {
    try {
      await client
        .mutation<
          IDeleteAssetFromWorkOrderMutation,
          IDeleteAssetFromWorkOrderMutationVariables
        >(
          DeleteAssetFromWorkOrderDocument,
          { assetId, workOrderId: props.workOrder.id },
          editScope.context()
        )
        .toPromise()
    } catch {}
  }

  return (
    <div className="@container">
      <div className="flex items-center justify-between">
        <h3 className="flex h-9 items-center text-sm font-medium text-gray-900">
          {i18n.t("asset_other")}
        </h3>
        {!!props.workOrder.assets.length && (
          <Button
            type="tertiary"
            onClick={() => setEdit((t) => !t)}
            className=""
            icon={edit ? Check : PencilSimple}
            color={edit ? "blue" : "gray"}>
            {edit ? i18n.t("common:save") : i18n.t("tasks:objects.edit_objects")}
          </Button>
        )}
      </div>
      <div className="mt-1 grid min-h-0 min-w-0 max-w-full flex-1 gap-2 @xl:grid-cols-2 ">
        {workOrder.assets.map(({ asset }) => {
          if (!asset) return null

          return (
            <LinkWithBackgroundLocation
              to={`/object/${asset.id}`}
              key={asset.id}
              className={cn(
                "flex rounded bg-gray-50 p-2 hover:bg-gray-100 hover:text-gray-900"
                // {
                //   "col-span-full": workOrder.assets.length === 1,
                // }
              )}>
              <div>
                <div className="pb-1 pl-0">
                  <AssetWithParents asset={asset} />
                </div>
                <div className="flex">
                  <div className="mr-2 shrink-0">
                    {asset.avatar ? (
                      <Image
                        src={asset.avatar?.thumbnail_url}
                        preview={{ src: asset.avatar?.url }}
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                        }}
                        imageClasses="w-12 h-12 rounded shrink-0"
                      />
                    ) : (
                      <div className="flex h-12 w-12 shrink-0 items-center justify-center rounded border border-dashed text-xl text-gray-300">
                        <Shapes />
                      </div>
                    )}
                  </div>
                  <div className="flex h-12 min-w-0 flex-col justify-evenly">
                    <div className="flex min-w-0 items-center text-sm text-gray-500">
                      <div className="w-6 shrink-0">
                        <Swatches className="mx-auto" />{" "}
                      </div>
                      <span className="truncate">
                        {asset.group?.name ?? i18n.t("common:without_group")}
                      </span>
                    </div>
                    {
                      <div className="flex min-w-0 items-center text-sm text-gray-500">
                        <div className="w-6 shrink-0">
                          <Icons.Location className="mx-auto text-base" />{" "}
                        </div>
                        <span className="truncate">
                          {asset.place?.name ?? i18n.t("common:without_location")}
                        </span>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div className="flex-1" />
              {edit && (
                <Button
                  type="tertiary"
                  icon={X}
                  color="gray"
                  htmlType="button"
                  className="ml-1 shrink-0 hover:!bg-gray-200"
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    if (!asset.id) return
                    onDelete(asset.id)
                  }}
                />
              )}
            </LinkWithBackgroundLocation>
          )
        })}

        {(edit || !props.workOrder.assets.length) && (
          <button
            className="flex min-h-[5.5rem] flex-col items-center justify-center rounded-md border border-dashed px-6 text-gray-400 hover:bg-gray-100 hover:text-gray-600"
            onClick={pickObject}>
            <Shapes className="h-5 w-5 " />
            <div className="flex items-center text-sm font-medium">
              <Plus className="mr-1 h-4 w-4" />
              {i18n.t("common:add_token", { token: i18n.t("common:asset", { count: 1 }) })}
            </div>
          </button>
        )}
      </div>
    </div>
  )
}
