import { useCallbackRef } from "@hooks"
import { useTreeList, UseTreeListReturn } from "@hooks/use-tree-list"
import { splitArray } from "@utils"
import {
  filterTreeList,
  findTree,
  forEachTreeList,
  Tree,
  TreeLike,
  treeListExtended,
} from "@utils/tree"
import { ReactNode, useMemo, useRef, useState } from "react"

export type Item<V> = {
  value: V
  searchValue: string
  color?: string | null
  icon?: ReactNode
  label: ReactNode
  disabled?: boolean
  customOnClick?: boolean
}
export type ItemTreeElement<V> = UseTreeListReturn<Item<V>>["flatList"][number]

export type ComboboxMultiSelectProps<V> = {
  size?: "small" | "large"
  items: TreeLike<Item<V>>[]
  hasCheckbox?: boolean
  className?: string
  groupBy?: boolean
  inputProps?: {
    hide?: boolean
    className?: string
    startAdornment?: ReactNode
    endAdornment?: ReactNode
  }
  listMaxHeight?: number
  onCustomOnClick?: (selectedItem: Item<V>) => void
  onChange?: (selectedValues: V[], selectedItems: Item<V>[], keepOpen: boolean) => void
  placeholder?: string
  initialValuesFirst?: boolean
  value?: Item<V>["value"][]
  valueToString: (value: V) => string
  onCreate?: (searchInputValue: string) => Promise<V>
  noDataPlaceholder?: string
  includeParentsOnSelect?: boolean
  includeChildrenOnSelect?: boolean
}

export const useComboBoxMultiSelect = <V,>(props: ComboboxMultiSelectProps<V>) => {
  const { initialValuesFirst = true } = props
  const selectedValues = props.value ?? []

  // Sort items and put initial selected values to the front
  const initialValue = useRef(selectedValues)
  const { items, openNodes } = useMemo(() => {
    const openNodes = {} as Record<string, boolean>

    if (initialValuesFirst && initialValue.current.length) {
      const treeList = treeListExtended(props.items)
      const [intitialValues, otherValues] = splitArray(treeList, (tree) => {
        return !!findTree(tree, (item) =>
          initialValue.current.some(
            (v) => props.valueToString(v) === props.valueToString(item.value)
          )
        )
      })

      // Expand all nodes that have a selected child
      const nodesToExpand = filterTreeList(intitialValues, (item) =>
        initialValue.current.some(
          (v) => props.valueToString(v) === props.valueToString(item.value)
        )
      )
      forEachTreeList(nodesToExpand, (tree) => {
        if (
          tree.children?.length &&
          (tree.nodeIsPredicateMatch || tree.childHasPredicateMatch)
        ) {
          openNodes[props.valueToString(tree.value)] = true
        }
      })

      return { items: intitialValues.concat(otherValues), openNodes }
    } else {
      return { items: props.items, openNodes }
    }
  }, [props.items, initialValuesFirst])

  const valueToString = useCallbackRef(
    props.valueToString ?? ((value: V) => value as unknown as string)
  )

  const [searchInput, setSearchInput] = useState("")
  const treeListBag = useTreeList(items, {
    key: (item) => valueToString(item.value),
    searchValue: (item) => item.searchValue,
    initialOpenCloseState: openNodes,
    searchInput,
  })

  const onSelect = (selectedItem: ItemTreeElement<V>) => {
    if (!selectedItem) return

    if (selectedItem.customOnClick) {
      props.onCustomOnClick?.(selectedItem)
      return
    }

    const index = selectedValues.findIndex(
      (v) => valueToString(v) === valueToString(selectedItem.value)
    )
    let newSelectedValues = selectedValues
    if (index > 0) {
      newSelectedValues = selectedValues
        .slice(0, index)
        .concat(selectedValues.slice(index + 1))
    } else if (index === 0) {
      newSelectedValues = selectedValues.slice(1)
    } else {
      newSelectedValues = selectedValues.concat([selectedItem.value])
    }

    // If an item is selected, all its parents should be selected as well
    if (props.includeParentsOnSelect) {
      // Recursively return all parents of selectedItem
      const getParents = (item: Tree<Item<V>>): any[] => {
        const parents = []
        if (item.parent) {
          parents.push(item.parent)
          parents.push(...getParents(item.parent))
        }
        return parents
      }

      const parents = getParents(selectedItem)

      parents.forEach((parent) => {
        if (
          !newSelectedValues.some((v) => valueToString(v) === valueToString(parent.value))
        ) {
          newSelectedValues.push(parent.value)
        }
      })
    }

    // If an item is selected, all it's children should be selected as well
    if (props.includeChildrenOnSelect) {
      const getChildren = (item: Tree<Item<V>>): any[] => {
        const children = []

        if (item.children) {
          children.push(...item.children)
          item.children.forEach((child) => {
            children.push(...getChildren(child))
          })
        }

        return children
      }

      const children = getChildren(selectedItem)

      children.forEach((child) => {
        if (
          !newSelectedValues.some((v) => valueToString(v) === valueToString(child.value))
        ) {
          newSelectedValues.push(child.value)
        }
      })
    }

    const keepOpen = false

    const selectedValueStrings = newSelectedValues.map(valueToString)

    const newSelectedItems = treeListBag.flatList.filter((item) =>
      selectedValueStrings.includes(valueToString(item.value))
    )

    props.onChange?.(newSelectedValues, newSelectedItems, keepOpen)
  }

  return {
    treeListBag,
    valueToString,
    selectedValues,
    setSearchInput,
    searchInput,
    onSelect,
  }
}
