import { Button, Icons } from "@components/shared"
import { alertDialog } from "@components/shared/alert-dialog-provider"
import { FormFieldController } from "@components/shared/form/form-field-controller"
import { TextArea } from "@components/shared/text-area"
import { TextInput } from "@components/shared/text-input"
import Toggle from "@components/shared/toggle"
import { IPermissionScopeEnum, IWorkOrderTemplateInsertInput } from "@elara/db"
import { IWorkOrderTemplateFragment } from "@graphql/documents/fragments.generated"
import {
  useCopyWorkOrderMutation,
  useCreateWorkOrderTemplateMutation,
  useDeleteWorkOrderTemplateMutation,
  useUpdateWorkOrderTemplateMutation,
} from "@graphql/documents/work-order.generated"
import { usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { Warning } from "@phosphor-icons/react"
import { useForm } from "react-hook-form"
import { useNavigate, useOutletContext } from "react-router-dom"

export const MetadataPage = () => {
  const { template } = useOutletContext<{ template: IWorkOrderTemplateFragment }>()
  const navigate = useNavigate()

  const { control, handleSubmit, formState, reset } = useForm({
    defaultValues: {
      name: template.name,
      description: template.description || "",
      allow_modification_in_task_form: template.allow_modification_in_task_form,
    },
  })

  const [, copyWorkOrder] = useCopyWorkOrderMutation()
  const [, createWorkOrderTemplate] = useCreateWorkOrderTemplateMutation()
  const [, updateWorkOrderTemplate] = useUpdateWorkOrderTemplateMutation()
  const [, deleteWorkOrderTemplate] = useDeleteWorkOrderTemplateMutation()

  const createScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderCreate)
  const editScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)

  const onDelete = async () => {
    await alertDialog({
      title: i18n.t("tasks:template.alert.title"),
      description: i18n.t("tasks:template.alert.description"),
      actionText: i18n.t("delete"),
      danger: true,
      onAction: async () => {
        await deleteWorkOrderTemplate({ templateId: template.id }, editScope.context())
        navigate(`/settings/template`)
      },
    })
  }

  const onDuplicate = async () => {
    const copyWorkOrderData = {
      id: template.work_order.id,
      options: {
        newWorkOrderName: `Vorlage: ${template.name} (Kopie)`,
        copySteps: true,
        copySubtasks: true,
        copyDocuments: true,
      },
    }

    const copyWorkOrderRes = await copyWorkOrder(copyWorkOrderData, createScope.context())
    const workOrderId = copyWorkOrderRes.data?.copyWorkOrder?.id

    if (!workOrderId) {
      throw new Error("Task could not be duplicated, ID was not returned.")
    }

    const data: IWorkOrderTemplateInsertInput = {
      name: `${template.name} (Kopie)`,
      description: template.description,
      work_order_id: workOrderId,
    }

    const createTemplateRes = await createWorkOrderTemplate({ data }, createScope.context())
    const templateId = createTemplateRes.data?.insert_work_order_template_one?.id

    if (!templateId) {
      throw new Error("Template could not be duplicated, ID was not returned.")
    }

    navigate(`/template/${templateId}`)
    // toast.success(`Vorlage "${templateName}" wurde erfolgreich kopiert.`)
  }

  const onSubmit = handleSubmit(async (data) => {
    await updateWorkOrderTemplate({ templateId: template.id, data }, editScope.context())
    reset(data)
  })

  return (
    <div className="flex-1 bg-white">
      <form onSubmit={onSubmit} className="flex max-w-md flex-col p-3">
        <FormFieldController
          control={control}
          name="name"
          label={i18n.t("common:name")}
          render={({ field }) => <TextInput {...field} />}
        />
        <FormFieldController
          control={control}
          name="description"
          label={i18n.t("common:description")}
          render={({ field }) => <TextArea rows={2} {...field} />}
        />
        <FormFieldController
          control={control}
          name="allow_modification_in_task_form"
          label={""}
          render={({ field }) => (
            <label className="space-x-2 text-sm text-gray-700">
              <Toggle checked={field.value} onChange={field.onChange} />
              <span>{i18n.t("tasks:template.allow_modification_in_task_form_label")}</span>
            </label>
          )}
        />
        {formState.isDirty && (
          <div className="mb-3 flex items-center space-x-2 rounded bg-yellow-100 px-3 py-2 text-sm text-yellow-600">
            <Warning weight="fill" />
            <span>{i18n.t("common:unsaved_changes")}</span>
          </div>
        )}
        <div className="flex items-center self-end">
          <Button
            color="red"
            type="tertiary"
            disabled={!editScope.hasScope}
            onClick={onDelete}>
            {i18n.t("common:delete")}
          </Button>
          <Button
            color="gray"
            type="tertiary"
            disabled={!createScope.hasScope}
            onClick={onDuplicate}>
            {i18n.t("common:actions.duplicate")}
          </Button>
          <Button
            type="primary"
            className="ml-2"
            htmlType="submit"
            icon={Icons.Check}
            disabled={!editScope.hasScope}
            isLoading={formState.isSubmitting}>
            {i18n.t("common:save")}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default MetadataPage
