import { FormField } from "@components/shared/form/form-field"
import { cn } from "@utils"
import { FieldHelperProps } from "formik"
import { useRef } from "react"

import RichText, { RichTextProps } from "../rich-text"

export type RichTextFormFieldProps = RichTextProps & {
  name: string
  label?: string
  noStyle?: boolean
  optional?: boolean
  toolbarClassName?: string
  hasErrorPlaceholder?: boolean
}

export function RichTextFormField({
  name,
  className,
  hasErrorPlaceholder,
  optional,
  ...props
}: RichTextFormFieldProps) {
  const helpersRef = useRef<FieldHelperProps<any> | null>(null)

  return (
    <FormField
      name={name}
      label={props.label}
      noStyle={props.noStyle}
      optional={optional}
      hasErrorPlaceholder={hasErrorPlaceholder}>
      {({ field, helpers, hasError }) => {
        helpersRef.current = helpers
        return (
          <RichText
            hasError={hasError}
            content={field.value}
            onBlur={() => helpers.setTouched(true)}
            toolbarClassName={props.toolbarClassName}
            className={cn(
              "flex-1 w-full text-base md:text-sm border rounded bg-white cursor-text focus-within:ring-1 focus-within:ring-blue-700",
              hasError &&
                "border-red-700 focus-within:!border-red-700 focus-within:!ring-red-700",
              className
            )}
            onUpdate={({ editor }) => {
              if (editor.isEmpty) {
                helpersRef.current?.setValue("")
              } else {
                helpersRef.current?.setValue(editor.getHTML())
              }
            }}
            {...props}
          />
        )
      }}
    </FormField>
  )
}
