import { Flex, VStack } from "@components/layout"
import { Button } from "@components/shared/button"
import { Drawer } from "@components/shared/drawer"
import Toggle from "@components/shared/toggle"
import { Data } from "@elara/select"
import { DragDropContext, Draggable, Droppable, DropResult } from "@hello-pangea/dnd"
import i18n from "@i18n"
import Icons from "@resources/icons"
import { cn } from "@utils"
import React, { useImperativeHandle, useRef, useState } from "react"

import { useDataViewConfigContext } from "./data-view-config"
import { Column } from "./data-view-types"

type ColumnSwitchLineProps = {
  index: number
  isActive: boolean
  columnName: string
  columnId: string
  onToggle: (val: string) => void
}

const ColumnSwitchLine = ({
  columnId,
  columnName,
  index,
  isActive,
  onToggle,
}: ColumnSwitchLineProps) => {
  return (
    <Draggable draggableId={columnId} index={index}>
      {(provided, snapshot) => (
        <Flex
          align="center"
          className="bg-white pl-2"
          row
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={provided.draggableProps.style}>
          <div className="absolute inset-y-0 -left-4 flex items-center justify-center text-gray-300">
            <Icons.Drag height={20} width={20} />
          </div>

          <div
            className={cn(
              "flex flex-row justify-between p-3 border border-gray-200 rounded grow-[2]",
              {
                "border-gray-700": snapshot.isDragging,
              }
            )}>
            <span className="text-sm">{columnName}</span>
            <Toggle
              checked={isActive}
              onChange={() => onToggle(columnId)}
              name={columnId}
              withLabel
            />
          </div>
        </Flex>
      )}
    </Draggable>
  )
}

function getOrderedColumns<D extends Data, Id extends string, Options extends {}>(
  orderedColumns: string[],
  columnDefinitions: Column<D, Id, Options>[]
) {
  return orderedColumns
    .map((id) => columnDefinitions.find((c) => c.id === id))
    .filter(Boolean)
    .concat(
      columnDefinitions.filter((c) => !orderedColumns.some((id) => id === c.id))
    ) as Column<D, Id, Options>[]
}

type CustomizeColumnsProps<D extends Data, Id extends string, Options extends {}> = {
  columnOrder: Id[]
  columnDefinitions: Column<D, Id, Options>[]
}

const CustomizeColumns = React.forwardRef(
  <D extends Data, Id extends string, Options extends {}>(
    { columnOrder, columnDefinitions }: CustomizeColumnsProps<D, Id, Options>,
    ref: React.Ref<{ currentColumnOrder: () => string[] }>
  ) => {
    const [orderedColumns, setOrderedColumns] = useState(() =>
      getOrderedColumns(columnOrder, columnDefinitions)
    )
    const [toggledColumns, setToggledColumns] = useState(() =>
      columnOrder.reduce((toggled, id) => {
        toggled[id] = true
        return toggled
      }, {} as Record<string, boolean>)
    )

    const onToggle = (val: string) => {
      setToggledColumns((cols) => ({ ...cols, [val]: !cols[val] }))
    }

    const onDragEnd = ({ destination, source }: DropResult) => {
      if (!destination) return

      const result = orderedColumns.slice()
      const [removed] = result.splice(source.index, 1)
      result.splice(destination.index, 0, removed)

      setOrderedColumns(result)
    }

    useImperativeHandle(
      ref,
      () => ({
        currentColumnOrder: () =>
          orderedColumns.filter((c) => toggledColumns[c.id]).map((c) => c.id),
      }),
      [orderedColumns, toggledColumns]
    )

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="work-order-columns">
          {(provided, _) => (
            <VStack
              space={12}
              inset={{ bottom: 24 }}
              ref={provided.innerRef}
              {...provided.droppableProps}>
              {orderedColumns.map((col, idx) => {
                return (
                  <ColumnSwitchLine
                    key={col.id}
                    columnId={col.id}
                    columnName={col.Header}
                    isActive={!!toggledColumns[col.id]}
                    onToggle={onToggle}
                    index={idx}
                  />
                )
              })}
              {provided.placeholder}
            </VStack>
          )}
        </Droppable>
      </DragDropContext>
    )
  }
)

type CustomizeColumnsDrawerProps = {
  isOpen: boolean
  onClose: () => void
}

export const CustomizeColumnsDrawer = <Id extends string>({
  isOpen,
  onClose,
}: CustomizeColumnsDrawerProps) => {
  const ctx = useDataViewConfigContext()

  const ref = useRef<{ currentColumnOrder: () => Id[] }>(null)

  return (
    <Drawer
      title={i18n.t("data-view:options.table.columns")}
      isOpen={isOpen}
      footer={
        <Flex justify="flex-end" style={{ width: "100%" }} row>
          <Button
            type="primary"
            onClick={() => {
              const currentActiveColums = ref.current?.currentColumnOrder()
              if (currentActiveColums) {
                ctx.actions.updateColumnOrder(currentActiveColums)
              }
              onClose()
            }}
            style={{ width: "42%" }}>
            {i18n.t("common:save")}
          </Button>
        </Flex>
      }
      onClose={onClose}>
      <CustomizeColumns
        columnOrder={ctx.config.columnOrder}
        columnDefinitions={ctx.columns}
        ref={ref}
      />
    </Drawer>
  )
}
