import {
  Frequency,
  MonthlyModifier,
  TimeIntervalTriggerOptions,
  weekdayToIsoWeekday,
} from "@elara/db"
import i18n from "@i18n"
import { parseTime } from "@internationalized/date"
import { formatDate, getAbbreviatedWeekNames, getNumberWeekday } from "@utils/date"
import { now, parseAbsolute } from "@utils/tzdate"

const getWeekdayString = (weekday: number) => getAbbreviatedWeekNames()[weekday - 1]

const getWeekdaysString = (weekdays: number[]) => {
  if (weekdays.length === 0) return ""
  if (weekdays.length === 1) return getWeekdayString(weekdays[0])

  // We want to get:
  // Mo. und Di.
  // Mo., Di. und Do.
  const sortedWeekdayStrs = weekdays
    .slice()
    .sort((a, b) => a - b)
    .map(getWeekdayString)
  const firstDaysStr = sortedWeekdayStrs.slice(0, -1).join(", ")
  const lastDayStr = sortedWeekdayStrs.pop()

  return `${firstDaysStr} ${i18n.t("common:and")} ${lastDayStr}`
}

export const getTimeIntervalTriggerDigest = (
  payload: TimeIntervalTriggerOptions["payload"]
): string => {
  const { repetition, nextAt } = payload

  if (!nextAt) return ""

  switch (repetition.frequency) {
    case Frequency.hourly: {
      const from = parseTime(repetition.hourly?.from ?? "00:00")
      const to = parseTime(repetition.hourly?.to ?? "23:59")

      return i18n.t("calendar:recurrence.display.every_x_hours_on", {
        count: repetition.interval,
        start: formatDate(
          now().set({ hour: from.hour, minute: from.minute }).toDate(),
          "p"
        ),
        end: formatDate(now().set({ hour: to.hour, minute: to.minute }).toDate(), "p"),
        weekdays: getWeekdaysString(
          repetition.hourly?.weekdays?.map(weekdayToIsoWeekday) ?? []
        ),
      })
    }
    case Frequency.daily:
      return i18n.t("calendar:recurrence.display.every_x_days", {
        count: repetition.interval,
      })
    case Frequency.weekly: {
      const weekdays = repetition.weekly?.weekdays?.map(weekdayToIsoWeekday) ?? []

      return i18n.t("calendar:recurrence.display.every_x_weeks_on", {
        count: repetition.interval,
        weeks: getWeekdaysString(weekdays),
      })
    }
    case Frequency.monthly: {
      if (repetition.monthly?.modifier === MonthlyModifier.by_date) {
        return i18n.t("calendar:recurrence.display.every_x_months_on_day", {
          count: repetition.interval,
          day: `${parseAbsolute(nextAt).day}.`,
        })
      } else if (repetition.monthly?.modifier === MonthlyModifier.by_weekday) {
        const next = new Date(nextAt)

        return i18n.t("calendar:recurrence.display.every_x_months_on_day", {
          count: repetition.interval,
          day: getNumberWeekday(next),
        })
      } else if (repetition.monthly?.modifier === MonthlyModifier.first_of_month) {
        return i18n.t("calendar:recurrence.display.every_x_months_on_first", {
          count: repetition.interval,
        })
      } else if (repetition.monthly?.modifier === MonthlyModifier.last_of_month) {
        return i18n.t("calendar:recurrence.display.every_x_months_on_last", {
          count: repetition.interval,
        })
      }

      return ""
    }
    case Frequency.yearly: {
      return i18n.t("calendar:recurrence.display.every_x_years_on", {
        count: repetition.interval,
        date: payload.nextAt ? formatDate(new Date(payload.nextAt), "P").slice(0, -5) : "",
      })
    }

    default:
      throw new Error("Unhandled Recurrence Display")
  }
}

export default function TimeIntervalTriggerDigest({
  payload,
  ...props
}: JSX.IntrinsicElements["span"] & { payload: TimeIntervalTriggerOptions["payload"] }) {
  return <span {...props}> {getTimeIntervalTriggerDigest(payload)}</span>
}
