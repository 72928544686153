import { Data } from "@elara/select"
import { useStickyState } from "@hooks"
import React, { PropsWithChildren, useCallback } from "react"

import { useDataViewConfigContext } from "./data-view-config"
import { DataOrderConfig } from "./data-view-types"

function useDataViewGroupBy<_D extends Data>() {
  const ctx = useDataViewConfigContext()

  const [groupExpandedState, setGroupExpandedState] = useStickyState(
    {} as Record<string, boolean>,
    `DataView:${ctx.id}:GroupExpanded`,
    "localStorage"
  )

  const toggleGroup = useCallback(
    (groupId: string | null) =>
      setGroupExpandedState((state) => ({
        ...state,
        [groupId ?? "__NULL__"]: !state[groupId ?? "__NULL__"],
      })),
    []
  )
  const isGroupCollapsed = useCallback(
    (groupId: string | null) => !groupExpandedState[groupId ?? "__NULL__"],
    [groupExpandedState]
  )

  return { groupBy: ctx.config.groupBy, toggleGroup, isGroupCollapsed }
}

const DataViewGroupByContext = React.createContext<
  ReturnType<typeof useDataViewGroupBy<Data>>
>(undefined!)

export function useDataViewGroupByContext<D extends Data, _Id extends string>() {
  const context = React.useContext(DataViewGroupByContext)
  if (!context) {
    throw new Error("useDataViewGroupByContext must be used within a DataViewGroupBy")
  }
  return context as unknown as ReturnType<typeof useDataViewGroupBy<D>>
}

export function DataViewGroupBy<Id extends string = string>(
  props: PropsWithChildren<{
    baseOrderBy?: DataOrderConfig<Id>[]
  }>
) {
  const value = useDataViewGroupBy<Data>()

  return (
    <DataViewGroupByContext.Provider value={value}>
      {props.children}
    </DataViewGroupByContext.Provider>
  )
}
