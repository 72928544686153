import i18n from "@i18n"

type Page = {
  group: string
  name: string
  path: string
}

export const defaultLandingPages: Page[] = [
  // Tasks
  {
    group: i18n.t("common:task", { count: 2 }),
    name: i18n.t("common:overview"),
    path: "/task/overview",
  },
  {
    group: i18n.t("common:task", { count: 2 }),
    name: i18n.t("common:view", { count: 2 }),
    path: "/task/views",
  },
  {
    group: i18n.t("common:task", { count: 2 }),
    name: i18n.t("tasks:assigned_page.assigned_to_me"),
    path: "/task/assigned/me",
  },

  // Analytics
  {
    group: i18n.t("navigation:categories.overview.analytics"),
    name: i18n.t("common:task", { count: 2 }),
    path: "/analytics/tasks",
  },
  {
    group: i18n.t("navigation:categories.overview.analytics"),
    name: i18n.t("common:asset", { count: 2 }),
    path: "/analytics/objects",
  },
  {
    group: i18n.t("navigation:categories.overview.analytics"),
    name: i18n.t("common:consumable", { count: 2 }),
    path: "/analytics/consumables",
  },

  // Objects
  {
    group: i18n.t("common:asset", { count: 2 }),
    name: i18n.t("common:view", { count: 2 }),
    path: "/object/views",
  },
  {
    group: i18n.t("common:asset", { count: 2 }),
    name: i18n.t("assets:tabs.activity"),
    path: "/object/activity",
  },
]
