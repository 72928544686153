import { VStack } from "@components/layout"
import { uuid } from "@elara/db/src/scalars"
import { useDisclosure } from "@hooks"
import i18n from "@i18n"
import Icons from "@resources/icons"
import { colors } from "@styles"
import { formatBytes } from "@utils"
import classNames from "classnames"
import { ReactNode } from "react"
import { FileUpload } from "src/types"

import { useConfirmModal } from "./confirm-modal"
import Image from "./image"
import { ImagePropertiesDialog } from "./image-properties-dialog"
import LoadingIndicator from "./loading-indicator"

type PictureCardProps = {
  className?: string
  isAvatar?: boolean
  upload: FileUpload | null | undefined
  placeholder?: ReactNode
  width?: number
  height?: number
  containerClasses?: string
  containerStyle?: React.CSSProperties
  useThumbnail?: boolean
  allowDeleteOnPreview?: boolean
  inEditMode?: boolean
  allowDeleteOnHome?: boolean
  onClick?: () => void
  onDelete?: (id: uuid) => void
  onEdit?: (id: uuid) => void
}

export const PictureCard = (props: PictureCardProps) => {
  const {
    isAvatar,
    upload,
    width,
    height,
    containerClasses,
    containerStyle,
    placeholder,
    useThumbnail = true,
    onDelete,
    onEdit,
    allowDeleteOnPreview,
    inEditMode,
    onClick,
    allowDeleteOnHome,
  } = props
  const { mime_type, thumbnail_url, url, file_name, file_size, id } = upload?.data ?? {}

  const editUploadDialog = useDisclosure()

  const confirm = useConfirmModal({
    title: i18n.t("common:delete_token", { token: i18n.t("common:photo", { count: 1 }) }),
    content: i18n.t("common:messages.token_delete_confirmation", {
      token: i18n.t("common:photo", { count: 1 }),
    }),
    okText: i18n.t("common:delete"),
    cancelText: i18n.t("common:cancel"),
    onOk: () => onDelete?.(id!),
  })
  const isLoading = (upload?.progress ?? 1) < 1

  const thumbnailUrl =
    useThumbnail && !mime_type?.includes("svg") ? thumbnail_url || url : url

  const { isOpen, changeOpen, onOpen } = useDisclosure()
  const isImage = mime_type?.startsWith("image")

  const containerComputedClasses = classNames(
    "border-2 border-solid rounded overflow-hidden",
    containerClasses,
    {
      "cursor-pointer": url,
      "cursor-auto": !url,
      "border-transparent ring-2 ring-blue-700": isAvatar,
      "border-gray-300": !isAvatar,
    }
  )

  return (
    <>
      <div
        className={classNames("relative select-none", props.className)}
        onClick={!inEditMode && url ? onOpen : undefined}>
        {(!isLoading || onEdit) && (
          <>
            <div
              className={classNames(
                "absolute flex items-center justify-center -top-1 -right-1 h-8 w-8 group cursor-pointer",
                { "hidden border-none": !isLoading && !inEditMode && !allowDeleteOnHome }
              )}
              onClick={
                !isLoading && upload?.data
                  ? () => {
                      inEditMode ? editUploadDialog.onOpen() : onEdit?.(upload.data.id)
                    }
                  : undefined
              }>
              <div
                className={classNames("h-4 w-4 box-content z-40", {
                  "bg-white text-black group-hover:bg-grey-2.5 group-hover:text-grey-6":
                    !isLoading && (allowDeleteOnHome || inEditMode),
                })}>
                {isLoading ? (
                  <LoadingIndicator size={16} color={colors.blueMedium} display="block" />
                ) : (
                  (inEditMode || allowDeleteOnHome) && <Icons.Menu height={16} width={16} />
                )}
              </div>
            </div>
            <div
              className={classNames(
                "absolute flex items-center justify-center -top-1 -left-1 h-8 w-8 group cursor-pointer",
                { "hidden border-none": !isLoading && !inEditMode && !allowDeleteOnHome }
              )}
              onClick={
                !isLoading && upload?.data
                  ? () => {
                      inEditMode ? confirm.show() : onDelete?.(upload.data.id)
                    }
                  : undefined
              }>
              <div
                className={classNames("h-4 w-4  box-content z-40", {
                  "bg-white text-black group-hover:bg-grey-2.5 group-hover:text-grey-6":
                    !isLoading && (allowDeleteOnHome || inEditMode),
                })}>
                {isLoading ? (
                  <LoadingIndicator size={16} color={colors.blueMedium} display="block" />
                ) : (
                  (inEditMode || allowDeleteOnHome) && (
                    <Icons.Close height={16} width={16} />
                  )
                )}
              </div>
            </div>
          </>
        )}
        {isImage || upload === null || upload === undefined ? (
          <>
            <Image
              src={thumbnailUrl}
              height={height}
              width={width}
              key={thumbnailUrl}
              placeholder={placeholder}
              preview={
                inEditMode
                  ? null
                  : {
                      src: url!,
                      isVisible: isOpen,
                      onVisibleChange: changeOpen,
                      onDelete: allowDeleteOnPreview && onDelete ? confirm.show : undefined,
                    }
              }
              containerClasses={containerComputedClasses}
              containerStyle={{ width: width, height: height, ...containerStyle }}
              imageClasses="h-full w-full aspect-square"
              onClick={onClick}
            />
            {confirm.component}
          </>
        ) : (
          <VStack
            className="!static h-full rounded border border-solid border-grey-5 bg-white px-2"
            align="center"
            justify="center"
            space={8}>
            <Icons.File className="text-grey-2" height={24} width={24} />
            <VStack
              space={2}
              className="max-w-full overflow-hidden text-ellipsis whitespace-nowrap">
              <span className="text-sm">{file_name}</span>
              <span className="text-xs text-grey-2.5">{formatBytes(file_size ?? 0)}</span>
            </VStack>
          </VStack>
        )}
      </div>
      {editUploadDialog.isOpen && (
        <ImagePropertiesDialog
          upload={upload}
          onClick={editUploadDialog.onClose}
          onDelete={onDelete}
        />
      )}
    </>
  )
}
