import { AssetStateDetailDialog, CurrentState } from "@components/asset/asset-state-detail"
import AssetStateFormDialog from "@components/asset/asset-state-form-dialog"
import AssetStateVariantLabel from "@components/asset/asset-state-variant-label"
import AssetMaintenance from "@components/asset/detail/overview/asset-maintenance"
import { Button } from "@components/shared"
import ScrollArea from "@components/shared/scroll-area"
import TasksBox from "@components/work-order/tasks-box"
import { useTaskData } from "@components/work-order/use-task-data"
import { useFeature } from "@contexts/feature-flag-context"
import { IOrderBy, IWorkOrderStatusEnum, uuid } from "@elara/db"
import { orderBy } from "@elara/select"
import { IAssetDetailFragment } from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { ArrowsDownUp } from "@phosphor-icons/react"
import { formatDate } from "@utils/date"
import { startOfDay, sub } from "date-fns"

import { useAsset } from "./$id"

const ChangeState = (props: { assetId: uuid }) => {
  return (
    <AssetStateFormDialog assetId={props.assetId} followUpOption>
      <Button type="secondary" className="hidden @mobile/page:flex">
        <ArrowsDownUp className="mr-2" />
        {i18n.t("assets:state.change_state_dialog.title")}
      </Button>
    </AssetStateFormDialog>
  )
}

const AssetStateSection = ({ asset }: { asset: IAssetDetailFragment }) => {
  const currentState = asset.current_state as CurrentState
  if (!currentState) return null
  const currentStateVariant = currentState.asset_state_variant

  return (
    <div className="rounded-lg bg-white p-6">
      <div className="flex flex-wrap items-center justify-between gap-y-2">
        <span className="mr-2 block font-medium leading-none text-gray-900">
          {i18n.t("assets:fields.current_state")}
        </span>
        <AssetStateVariantLabel variant={currentStateVariant} className="mr-2.5" />

        <AssetStateDetailDialog state={currentState} assetId={asset.id} allowEdit>
          <Button type="secondary" color="gray" className="hidden @mobile/page:flex">
            {i18n.t("common:details")}
          </Button>
        </AssetStateDetailDialog>

        <div className="flex-1"></div>

        <ChangeState assetId={asset.id} />
      </div>
      {currentState.note && (
        <div className="my-2 text-sm text-gray-500">{currentState.note}</div>
      )}
      <div className="mt-2 flex space-x-4 @mobile/page:hidden">
        <AssetStateDetailDialog state={currentState} assetId={asset.id}>
          <Button type="secondary" color="gray" className="grow @lg/page:basis-0">
            {i18n.t("common:details")}
          </Button>
        </AssetStateDetailDialog>

        <AssetStateFormDialog assetId={asset.id} followUpOption>
          <Button type="secondary" className="grow @lg/page:basis-0">
            <ArrowsDownUp className="mr-2" />
            {i18n.t("assets:state.change_state_dialog.title")}
          </Button>
        </AssetStateFormDialog>
      </div>
    </div>
  )
}

const RecentlyCompleted = (props: { asset: IAssetDetailFragment }) => {
  const data = useTaskData({
    where: {
      id: { _is_null: false },
      status: { _eq: IWorkOrderStatusEnum.Done },
      assets: {
        asset: {
          _or: [
            { id: { _eq: props.asset.id } },
            { parent_ids: { _has_key: props.asset.id } },
          ],
        },
      },
      completed_at: { _gt: startOfDay(sub(new Date(), { days: 30 })).toISOString() },
    },
    orderBy: { completed_at: IOrderBy.Desc },
    limit: 5,
  })
  const tasks = orderBy(data.data ?? [], { completed_at: "desc" }).slice(0, 10)
  if (!tasks.length) return null
  return (
    <TasksBox
      className="border-white bg-white"
      title={() => i18n.t("tasks:overview.recently_completed_tasks")}
      dateLabel={i18n.t("tasks:fields.completed_at")}
      dateElement={(t) => {
        const date = new Date(t.completed_at!)
        return (
          <span>
            <span className="tabular-nums text-gray-700">{formatDate(date, "P")}</span>
            <span className="ml-0.5 tabular-nums text-gray-500">
              {formatDate(date, "p")}
            </span>
          </span>
        )
      }}
      data={tasks}
      isFetching={data.queryRes.fetching}
      emptyPlaceholder={i18n.t("tasks:overview.empty_placeholder")}
    />
  )
}

const OpenTasks = (props: { asset: IAssetDetailFragment }) => {
  const { asset } = props
  const data = useTaskData({
    where: {
      id: { _is_null: false },
      status: { _nin: [IWorkOrderStatusEnum.Done, IWorkOrderStatusEnum.Canceled] },
      assets: {
        asset: {
          _or: [{ id: { _eq: asset.id } }, { parent_ids: { _has_key: asset.id } }],
        },
      },
    },
    orderBy: { due_date: IOrderBy.DescNullsFirst, created_at: IOrderBy.Desc },
    limit: 50,
  })
  const tasks = orderBy(data.data ?? [], { completed_at: "desc" }).slice(0, 10)
  if (!tasks.length) return null
  return (
    <TasksBox
      className="border-white bg-white"
      title={() => i18n.t("assets:overview.open_tasks")}
      dateLabel={i18n.t("tasks:fields.due_date")}
      dateElement={(t) => {
        if (!t.due_date) return null
        const date = new Date(t.due_date)
        return (
          <span>
            <span className="tabular-nums text-gray-700">{formatDate(date, "P")}</span>
          </span>
        )
      }}
      data={tasks}
      isFetching={data.queryRes.fetching}
      emptyPlaceholder={i18n.t("tasks:overview.empty_placeholder")}
    />
  )
}

const AssetOverview = () => {
  const { asset } = useAsset()

  const hasMaintenanceFeature = useFeature("maintenance")

  return (
    <div className="flex min-h-0 min-w-0 flex-1 flex-col bg-gray-50">
      <ScrollArea vertical viewportAsChild>
        <div className="mx-auto flex min-h-0 w-full min-w-0 max-w-screen-lg shrink-0 flex-col space-y-3 px-3 py-4">
          <AssetStateSection asset={asset} />
          <RecentlyCompleted asset={asset} />
          <OpenTasks asset={asset} />
          {hasMaintenanceFeature && <AssetMaintenance asset={asset} />}
        </div>
      </ScrollArea>
    </div>
  )
}

export default AssetOverview
