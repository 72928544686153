import * as Dates from "date-fns"
import { useMemo } from "react"
import { Data, DataValue, Exact, FieldPrimitiveValue, Maybe, useDeepMemo } from "./data"

export type True = { [k: string]: never }
export type WhereLogicOperators = "_and" | "_or" | "_not"
export type WherePrimitiveComparisonOperators =
  | "_eq"
  | "_gt"
  | "_gte"
  | "_in"
  | "_is_null"
  | "_lt"
  | "_lte"
  | "_neq"
  | "_nin"
  | "_has_key"

// These are used for relative dates, e.g., show all in the last 28 days
// They are always relative do Date.now() and round to `startOfDay`
// resp. `endOfDay` (for `_since_last` resp. `_unil_next`)
export type WhereRelativeComparisonOperators = "_since_last" | "_until_next"
export type RelativeComparisonValue = {
  hours?: number
  days?: number
  weeks?: number
  months?: number
  years?: number
}

export type WhereExtraStringComparisonOperators =
  | WhereRelativeComparisonOperators
  | "_regex"
  | "_nregex"
  | "_iregex"
  | "_niregex"
  | "_ilike" // TODO: There is also the like family of operators

export type WhereBaseComparisonOperators =
  | WhereLogicOperators
  | WherePrimitiveComparisonOperators

export type WhereBaseComparisonExpr<T> = {
  [K in WhereBaseComparisonOperators]?: K extends
    | "_eq"
    | "_gt"
    | "_gte"
    | "_lt"
    | "_lte"
    | "_neq"
    ? T | True
    : K extends "_has_key"
    ? string
    : K extends "_is_null"
    ? boolean | True
    : K extends "_in" | "_nin"
    ? Array<String> | True
    : never
}

type WhereStringComparisonExpr = {
  [K in WhereExtraStringComparisonOperators]?: K extends WhereRelativeComparisonOperators
    ? RelativeComparisonValue
    : string
}
type WhereJSONComparisonExpr =
  | {
      [K in "_has_key"]?: string
    }
  | {
      [K in "_has_keys_any"]?: string[]
    }
  | { _is_null?: boolean }

type WhereComparisonExpr<T> = Exact<
  WhereBaseComparisonExpr<T> & (T extends string ? WhereStringComparisonExpr : {})
>

type WhereTransformationValue<V extends DataValue> = V extends FieldPrimitiveValue
  ? WhereComparisonExpr<V>
  : V extends Array<infer D>
  ? D extends Data
    ? Where<D>
    : D extends string
    ? WhereJSONComparisonExpr
    : never
  : V extends Data
  ? Where<V> | WhereJSONComparisonExpr
  : V extends unknown
  ? unknown
  : never

type WhereTransformation<D extends Data> = Exact<{
  [P in keyof D]?: D[P] extends Maybe<infer V>
    ? V extends DataValue
      ? WhereTransformationValue<V>
      : never
    : D[P] extends DataValue
    ? WhereTransformationValue<D[P]>
    : never
}>

export type Where<D extends Data> = Exact<
  {
    _and?: Array<Where<D>>
    _or?: Array<Where<D>>
    _not?: Where<D>
  } & WhereTransformation<D>
>

type WhereLookupStructure =
  | { type: "field"; key: string; statements: WhereLookupStructure[] }
  | { type: "_or"; statements: Array<WhereLookupStructure[]> }
  | { type: "_and"; statements: Array<WhereLookupStructure[]> }
  | { type: "_not"; statements: WhereLookupStructure[] }
  | {
      type: "comparison"
      op: "_eq" | "_gt" | "_gte" | "_lt" | "_lte" | "_neq"
      value: string | number | boolean
    }
  | {
      type: "comparison"
      op: "_is_null"
      value: unknown | null
    }
  | {
      type: "comparison"
      op: "_in" | "_nin"
      value: Array<string | number | boolean> | True
    }
  | {
      type: "comparison"
      op: "_regex" | "_nregex"
      value: RegExp
    }
  | {
      type: "json"
      op: "_has_key"
      key: string
      value: string
    }
  | {
      type: "json"
      op: "_has_keys_any"
      key: string
      value: string[]
    }

function isEmptyObject(obj?: any) {
  // Check for empty object
  // because Object.keys(new Date()).length === 0;
  // we have to do some additional check
  return (
    obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype
  )
}

function buildLookupStructureForPredicate<D extends Data>(
  where: Where<D> | WhereTransformation<D>,
  lastWhere?: Where<D> | WhereTransformation<D>
): WhereLookupStructure[] {
  const statements = [] as WhereLookupStructure[]
  for (let [key, value] of Object.entries(where)) {
    if (key === "_and" && Array.isArray(value)) {
      statements.push({
        type: "_and",
        statements: value.map((v: unknown) =>
          buildLookupStructureForPredicate(v as Where<D>)
        ),
      })
    } else if (key === "_or" && Array.isArray(value)) {
      statements.push({
        type: "_or",
        statements: value.map((v: unknown) =>
          buildLookupStructureForPredicate(v as Where<D>)
        ),
      })
    } else if (key === "_not") {
      statements.push({
        type: "_not",
        statements: buildLookupStructureForPredicate(value as Where<D>),
      })
    } else if (key === "_since_last") {
      const duration = value as Duration
      const date = Dates.sub(new Date(), duration)
      // Transform to a simple `_gte` op
      statements.push({
        type: "comparison",
        op: "_gte",
        value: duration.hours
          ? Dates.startOfHour(date).toISOString()
          : Dates.startOfDay(date).toISOString(),
      })
    } else if (key === "_until_next") {
      const duration = value as Duration
      const date = Dates.add(new Date(), duration)
      // Transform to a simple `_lte` op
      statements.push({
        type: "comparison",
        op: "_lte",
        value: duration.hours
          ? Dates.endOfHour(date).toISOString()
          : Dates.endOfDay(date).toISOString(),
      })
    } else if (key === "_ilike") {
      const regex = (value as string).replace(/^%/, "^.*?").replace(/%$/, ".*?$")
      statements.push({
        type: "comparison",
        op: "_regex",
        value: new RegExp(regex, "i"),
      })
    } else if (key === "_regex") {
      statements.push({
        type: "comparison",
        op: "_regex",
        value: new RegExp(value as string),
      })
    } else if (key === "_nregex") {
      statements.push({
        type: "comparison",
        op: "_nregex",
        value: new RegExp(value as string),
      })
    } else if (key === "_iregex") {
      statements.push({
        type: "comparison",
        op: "_regex",
        value: new RegExp(value as string, "i"),
      })
    } else if (key === "_niregex") {
      statements.push({
        type: "comparison",
        op: "_nregex",
        value: new RegExp(value as string, "i"),
      })
    } else if (
      key === "_eq" ||
      key === "_gt" ||
      key === "_gte" ||
      key === "_in" ||
      key === "_is_null" ||
      key === "_lt" ||
      key === "_lte" ||
      key === "_neq" ||
      key === "_nin"
    ) {
      // Need the any here otherwise TS is complaining since we use a discriminated union on the op key for the return value
      statements.push({ type: "comparison", op: key as any, value })
    } else if (key === "_ilike" || key === "_like" || key === "Cast") {
      console.warn("unsupported key", key)
      // do nothing for unsupported
    } else if (typeof value === "object" && typeof value._has_key === "string") {
      statements.push({
        type: "json",
        key,
        op: "_has_key",
        value: value._has_key,
      })
    } else if (typeof value === "object" && Array.isArray(value._has_keys_any)) {
      statements.push({
        type: "json",
        key,
        op: "_has_keys_any",
        value: value._has_keys_any,
      })
    } else {
      statements.push({
        type: "field",
        key,
        // there is not really a way to type this properly...
        statements: buildLookupStructureForPredicate(value as Where<{}>, where),
      })
    }
  }
  // Before returning remove all statements that are always true
  return statements.filter((stmt) => !isAlwaysTrueStatement(stmt))
}

function isAlwaysTrueStatement(stmt: WhereLookupStructure) {
  switch (stmt.type) {
    case "_and":
      return stmt.statements.every((s) => s.length === 0)
    case "_or":
      if (stmt.statements.length === 0) return true
      return stmt.statements.some((s) => s.length === 0)
    case "_not":
      return false
    case "comparison":
      return isEmptyObject(stmt.value)
    // case "field":
    //   return stmt.statements.length === 0
    default:
      return false
  }
}

function checkAll(
  data: Data | DataValue | null,
  statements: WhereLookupStructure[]
): boolean {
  return statements.every((statement) => check(data, statement))
}

function check(data: Data | DataValue | null, statement: WhereLookupStructure): boolean {
  if (statement.type === "_and") {
    return statement.statements.every((stmts) => checkAll(data, stmts))
  } else if (statement.type === "_or") {
    return statement.statements.some((stmts) => checkAll(data, stmts))
  } else if (statement.type === "_not") {
    // if (statement.statements.length === 0) return
    return !checkAll(data, statement.statements)
  } else if (statement.type === "json" && statement.op === "_has_key") {
    if (data === null) return false
    const value = (data as Data)[statement.key]
    if (Array.isArray(value)) {
      return value.some((v) => v === statement.value)
    }
    return true
  } else if (statement.type === "json" && statement.op === "_has_keys_any") {
    if (data === null) return false
    const value = (data as Data)[statement.key]
    if (Array.isArray(value)) {
      return value.some((v) => statement.value.some((v1) => v1 === v))
    }
    return true
  } else if (statement.type === "field" && typeof data === "object") {
    if (data === null) return false
    const value = (data as Data)[statement.key]
    if (Array.isArray(value)) {
      // Following the hasura docs we include a value if any of the list items is valid
      return value.some((d) => checkAll(d, statement.statements))
    } else {
      return checkAll(value, statement.statements)
    }
  } else if (statement.type === "comparison") {
    const value = data as FieldPrimitiveValue | null
    const { op } = statement
    if (value === null) {
      if (op === "_is_null") {
        return statement.value === true ? true : false
      }
      return false
    }
    if (op === "_eq") {
      return value === statement.value
    } else if (op === "_gt") {
      return value > statement.value
    } else if (op === "_gte") {
      return value >= statement.value
    } else if (op === "_in") {
      if (Array.isArray(statement.value)) {
        return statement.value.some((v) => v === value)
      }
      return true
    } else if (op === "_lt") {
      return value < statement.value
    } else if (op === "_lte") {
      return value <= statement.value
    } else if (op === "_neq") {
      return value !== statement.value
    } else if (op === "_nin") {
      if (Array.isArray(statement.value)) {
        return !statement.value.some((v) => v === value)
      }
      return true
    } else if (op === "_is_null") {
      // value !== null since we checked above
      return statement.value === true ? false : true
    } else if (op === "_regex" && typeof value === "string") {
      return statement.value.test(value)
    } else if (op === "_nregex" && typeof value === "string") {
      return !statement.value.test(value)
    }
  }
  return false
}

export function buildWherePredicate<D extends Data = {}>(
  where: Where<D>
): (data: D) => boolean {
  return predicateFromStatements(buildLookupStructureForPredicate(where))
}

export function predicateFromStatements<D extends Data>(
  statements: WhereLookupStructure[]
) {
  return (data: D) => {
    // gracefully handle exceptions
    try {
      return statements.every((statement) => check(data, statement))
    } catch (err) {
      console.warn("Exception in where predicate:", err)
      return false
    }
  }
}

export function where<D extends Data>(data: D[], where: Where<D>) {
  const predicate = buildWherePredicate(where)
  return data.filter(predicate)
}

export function useWherePredicate<D extends Data>(where: Where<D>): (data: D) => boolean {
  // First build the statements. Since we filter out statements that are always
  // true it could be that `where` changes but the statements not.
  const statements = useDeepMemo(() => buildLookupStructureForPredicate(where), [where])
  const predicate = useDeepMemo(() => predicateFromStatements(statements), [statements])
  return predicate
}

export function useNullableWherePredicate<D extends Data>(
  where: Where<D> | null
): ((data: D) => boolean) | null {
  // First build the statements. Since we filter out statements that are always
  // true it could be that `where` changes but the statements not.
  const statements = useDeepMemo(() => {
    if (!where) return null
    return buildLookupStructureForPredicate(where)
  }, [where])
  const predicate = useDeepMemo(() => {
    if (!statements) return null
    return predicateFromStatements(statements)
  }, [statements])
  return predicate
}

/**
 * Apply the specified `where` conditions to the given data. Performs memorization on the filtered data
 * and the `where` parameter. On the `where` parameter we perform deep equality check.
 *
 * @param data Data to be filtered
 * @param where Declarative filter description
 * @returns [filteredData, predicate] where `filteredData = data.filter(predicate)`.
 */
export function useWhere<D extends Data>(
  data: D[],
  where: Where<D>
): [D[], (data: D) => boolean] {
  const predicate = useWherePredicate(where)
  const value: [D[], (data: D) => boolean] = useMemo(
    () => [data.filter(predicate), predicate],
    [data, predicate]
  )
  return value
}

export function emptyArrayIsTrue<T>(a: Array<T>): Array<T> | True {
  return a.length ? a : {}
}

// Map our where to a hasura where
export function hasuraWhere<D extends Data>(where: Where<D>): Where<D> {
  return transformNonStandardWhere(where as Data | DataValue) as Where<D>
}

function transformNonStandardWhere(data: Data | DataValue | null): Data | DataValue | null {
  if (data === null) return null
  if (typeof data === "object") {
    if (Array.isArray(data)) {
      return data.map(transformNonStandardWhere) as DataValue
    }
    const d: Record<string, Data | DataValue | null> = {}
    Object.entries(data).forEach(([key, value]) => {
      if (key === "_since_last") {
        d["_gte"] = Dates.startOfDay(
          Dates.sub(new Date(), value as unknown as Duration)
        ).toISOString()
      } else if (key === "_until_next") {
        d["_lte"] = Dates.endOfDay(Dates.add(new Date(), value as Duration)).toISOString()
      } else {
        d[key] = transformNonStandardWhere(value)
      }
      return d
    })
    return d
  }
  return data
}
