import { openModal } from "@contexts/modal-context"
import { uuid } from "@elara/db"
import i18n from "@i18n"
import { Plus } from "@phosphor-icons/react"
import posthog from "posthog-js"
import React, { ReactElement, useCallback } from "react"
import { matchPath, useLocation } from "react-router-dom"
import { validate } from "uuid"

function validateUUID(uuid: string | undefined | null) {
  if (!uuid) return null
  if (!validate(uuid)) return null
  return uuid
}

export const useTriggerRecordTask = () => {
  const { pathname } = useLocation()

  return useCallback(() => {
    const assetId = validateUUID(matchPath("/object/:id/*", pathname)?.params?.id)
    const asset_ids: uuid[] = []
    if (assetId) asset_ids.push(assetId)

    openModal("select_template", { initialTaskValues: { asset_ids } })

    posthog?.capture("record-task-button-clicked")
  }, [pathname])
}

const RecordTaskButton = (props: { children?: ReactElement }) => {
  const onAddRecord = useTriggerRecordTask()

  if (props.children) {
    return React.cloneElement(props.children, { onClick: onAddRecord })
  }

  return (
    <button
      type="button"
      onClick={onAddRecord}
      className="flex items-center rounded bg-gradient-to-bl from-blue-600 to-blue-500 px-3 py-1.5 text-center text-sm font-medium text-white hover:from-blue-700 hover:to-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-700 focus-visible:ring-offset-2">
      <Plus size={18} className="mr-1 inline-block" />{" "}
      {i18n.t("common:new_token", {
        context: "female",
        token: i18n.t("common:task", { count: 1 }),
      })}
    </button>
  )
}

export default RecordTaskButton
