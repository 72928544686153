import AssetStateVariantLabel, {
  compareAssetStateVariants,
} from "@components/asset/asset-state-variant-label"
import SettingsPageLayout from "@components/settings/page-layout"
import { Button } from "@components/shared"
import { AlertDialog } from "@components/shared/alert-dialog"
import { alertDialog } from "@components/shared/alert-dialog-provider"
import { DialogForm } from "@components/shared/dialog-form"
import { FormField } from "@components/shared/form/form-field"
import RadioGroupPanel from "@components/shared/radio-group-panel"
import { TextInput } from "@components/shared/text-input"
import { IAssetStateVariantTypeEnum } from "@elara/db"
import { uuid } from "@elara/db"
import { useOrderBy } from "@elara/select"
import {
  AddAssetStateVariantDocument,
  DeleteAssetStateVariantDocument,
  IAddAssetStateVariantMutation,
  IAddAssetStateVariantMutationVariables,
  UpdateAssetStateVariantDocument,
  useAssetStateVariantsQuery,
} from "@graphql/documents/asset-state.generated"
import { IAssetStateVariantFragment } from "@graphql/documents/fragments.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { Plus, TrashSimple } from "@phosphor-icons/react"
import { capitalize } from "@utils"
import { useState } from "react"
import toast from "react-hot-toast"
import { useClient } from "urql"
import * as yup from "yup"

import {
  DeleteAssetGroupDocument,
  IDeleteAssetGroupMutation,
  IDeleteAssetGroupMutationVariables,
  useAssetGroupsWithAssetCountQuery,
} from "../../graphql/documents/asset-group.generated"
import AssetGroupsCard from "./work-order-asset-groups/asset-groups-card"
import AssetGroupsEditCreateForm from "./work-order-asset-groups/asset-groups-edit-create-form"

const AssetStateVariantSchema = yup.object().shape({
  type: yup.string().required(i18n.t("common:forms.required")),
  subtype: yup.string().min(1).required(i18n.t("common:forms.required")),
  planned: yup.string().required(i18n.t("common:forms.required")),
})

const EditAssetStateVariant = (
  props: React.PropsWithChildren<{ variant: IAssetStateVariantFragment }>
) => {
  const client = useClient()
  const scope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)
  return (
    <DialogForm
      trigger={props.children}
      title={i18n.t("settings:asset.edit_variant.title")}
      formikConfig={{
        initialValues: {
          subtype: props.variant.subtype,
          planned: props.variant.planned ? "planned" : "unplanned",
          type: props.variant.type,
        },
        validationSchema: AssetStateVariantSchema,
        onSubmit: async (values) => {
          const res = await client
            .mutation(
              UpdateAssetStateVariantDocument,
              {
                id: props.variant.id,
                data: {
                  subtype: values.subtype,
                  planned: values.planned === "planned",
                },
              },
              scope.context()
            )
            .toPromise()
          if (res.error) {
            toast.error(i18n.t("common:generic_toast_error"))
            new Error(res.error.message)
          }
        },
      }}>
      {(formik) => (
        <div className="pb-4">
          {props.variant.type === IAssetStateVariantTypeEnum.Offline && (
            <FormField name="planned" label="Planned">
              {({ field, helpers }) => (
                <>
                  <p className="mb-2 text-sm text-gray-500">Some useful explanation</p>
                  <RadioGroupPanel
                    value={field.value}
                    onValueChange={helpers.setValue}
                    options={[
                      {
                        value: "planned",
                        label: "Planned",
                        description: "Planned description",
                      },
                      {
                        value: "unplanned",
                        label: "Unplanned",
                        description: "Unplanned description",
                      },
                    ]}
                  />
                </>
              )}
            </FormField>
          )}

          <FormField name="subtype" label="Subtype">
            <TextInput />
          </FormField>

          <div>
            <div className="mb-2 text-sm text-gray-500">Preview new label</div>
            <AssetStateVariantLabel
              variant={{
                ...props.variant,
                planned: formik.values.planned === "planned",
                subtype: formik.values.subtype,
              }}
            />
          </div>
        </div>
      )}
    </DialogForm>
  )
}

const DeleteAssetStateVariant = (
  props: React.PropsWithChildren<{
    variant: IAssetStateVariantFragment
    afterDelete?: () => void
  }>
) => {
  const client = useClient()
  const scope = usePermissionScope(IPermissionScopeEnum.AppAssetArchiveDelete)
  return (
    <AlertDialog
      danger
      title="Delete variant"
      actionText={i18n.t("common:delete")}
      description="Are you sure you want to delete this variant?"
      onAction={async () => {
        const res = await client
          .mutation(
            DeleteAssetStateVariantDocument,
            { id: props.variant.id },
            scope.context()
          )
          .toPromise()
        if (res.error) {
          toast.error(i18n.t("common.generic_toast_error"))
          throw new Error(res.error.message)
        }
        props.afterDelete?.()
      }}>
      {props.children}
    </AlertDialog>
  )
}

const AddAssetStateVariant = (
  props: React.PropsWithChildren<{
    afterAdd?: () => void
  }>
) => {
  const client = useClient()
  const scope = usePermissionScope(IPermissionScopeEnum.AppAssetCreate)
  return (
    <DialogForm
      position="top"
      positionOffset={96}
      title={i18n.t("settings:asset.add_variant")}
      formikConfig={{
        initialValues: {
          subtype: "",
          type: IAssetStateVariantTypeEnum.Offline,
          planned: "unplanned",
        },
        onSubmit: async (values) => {
          const res = await client
            .mutation<
              IAddAssetStateVariantMutation,
              IAddAssetStateVariantMutationVariables
            >(
              AddAssetStateVariantDocument,
              {
                data: {
                  planned: values.planned === "planned",
                  subtype: values.subtype,
                  type: values.type,
                },
              },
              scope.context()
            )
            .toPromise()

          if (res.error) {
            toast.error(i18n.t("common:generic_toast_error"))
            throw new Error(res.error.message)
          }
          props.afterAdd?.()
        },
        validationSchema: AssetStateVariantSchema,
      }}
      trigger={props.children}>
      {(formik) => (
        <div className="pb-4">
          <FormField name="type" label={i18n.t("assets:fields.state_change.variant")}>
            {({ field, helpers }) => (
              <RadioGroupPanel
                value={field.value}
                onValueChange={helpers.setValue}
                indicatorClassName="radix-state-checked:border-current"
                options={[
                  {
                    value: IAssetStateVariantTypeEnum.Offline,
                    label: i18n.t("assets:state.variant.offline"),
                    className:
                      "bg-red-50 text-red-700 hover:bg-red-50 hover:border-red-700",
                  },
                  {
                    value: IAssetStateVariantTypeEnum.Online,
                    label: i18n.t("assets:state.variant.online"),
                    className:
                      "bg-green-50 text-green-700 hover:bg-green-50 hover:border-green-700",
                  },
                  {
                    value: IAssetStateVariantTypeEnum.DoNotTrack,
                    label: i18n.t("assets:state.variant.do_not_track"),
                    className:
                      "bg-gray-50 text-gray-700 hover:bg-gray-50 hover:border-gray-700",
                  },
                ]}
              />
            )}
          </FormField>

          {formik.values.type === IAssetStateVariantTypeEnum.Offline && (
            <FormField name="planned" label={i18n.t("assets:fields.state_change.planning")}>
              {({ field, helpers }) => (
                <RadioGroupPanel
                  value={field.value}
                  onValueChange={helpers.setValue}
                  options={[
                    {
                      value: "planned",
                      label: capitalize(i18n.t("assets:state.variant.planned")),
                    },
                    {
                      value: "unplanned",
                      label: capitalize(i18n.t("assets:state.variant.unplanned")),
                    },
                  ]}
                />
              )}
            </FormField>
          )}

          <FormField name="subtype" label={i18n.t("assets:fields.state_change.subtype")}>
            <TextInput />
          </FormField>

          <div>
            <div className="mb-2 text-sm text-gray-500">
              {i18n.t("settings:asset.preview")}
            </div>
            <AssetStateVariantLabel
              variant={{
                planned: formik.values.planned === "planned",
                subtype: formik.values.subtype,
                type: formik.values.type,
              }}
            />
          </div>
        </div>
      )}
    </DialogForm>
  )
}

function AssetStateVariants() {
  const [assetStateVariants, refetchAssetStateVariants] = useAssetStateVariantsQuery({
    requestPolicy: "cache-and-network",
  })
  const variants = (assetStateVariants.data?.asset_state_variant ?? []).sort(
    compareAssetStateVariants
  )

  return (
    <div>
      <div className="space-y-3">
        <h3 className="font-semibold">{i18n.t("settings:asset.title")}</h3>
        <p className="text-sm text-gray-500">{i18n.t("settings:asset.description")}</p>
        {variants.map((variant) => {
          return (
            <div key={variant.id} className="flex flex-wrap items-center gap-x-3 gap-y-1.5">
              <AssetStateVariantLabel variant={variant} />

              {variant.subtype && (
                <div className="inline-flex items-center space-x-3">
                  <EditAssetStateVariant variant={variant}>
                    <Button type="tertiary" color="gray">
                      {i18n.t("common:edit")}
                    </Button>
                  </EditAssetStateVariant>
                  <DeleteAssetStateVariant
                    variant={variant}
                    afterDelete={() => refetchAssetStateVariants()}>
                    <Button type="tertiary" icon={TrashSimple} color="gray" />
                  </DeleteAssetStateVariant>
                </div>
              )}
            </div>
          )
        })}
        <AddAssetStateVariant afterAdd={() => refetchAssetStateVariants()}>
          <Button type="secondary" icon={Plus}>
            {i18n.t("settings:asset.add_variant")}
          </Button>
        </AddAssetStateVariant>
      </div>
    </div>
  )
}

type AssetGroupState = { type: "new" } | { type: "edit"; id: uuid } | null

const AssetGroups = () => {
  const client = useClient()
  const [queryRes, refetchGroups] = useAssetGroupsWithAssetCountQuery({
    requestPolicy: "cache-and-network",
  })
  const [data] = useOrderBy(queryRes.data?.asset_group ?? [], { name: "asc" })
  const deleteAssetGroupsScope = usePermissionScope(
    IPermissionScopeEnum.AppAssetArchiveDelete
  )
  const createScope = usePermissionScope(IPermissionScopeEnum.AppAssetCreate)

  const [state, setState] = useState<AssetGroupState>(null)

  const deleteAssetGroupModal = (id: uuid) =>
    alertDialog({
      danger: true,
      title: i18n.t("common:delete_token", {
        token: i18n.t("assets:fields.group", { count: 1 }),
      }),
      description: i18n.t("assets:messages.group_delete_confirmation"),
      actionText: i18n.t("common:delete"),
      onAction: async () => {
        const res = await client
          .mutation<IDeleteAssetGroupMutation, IDeleteAssetGroupMutationVariables>(
            DeleteAssetGroupDocument,
            { id },
            deleteAssetGroupsScope.context()
          )
          .toPromise()
        if (res.error) {
          toast.error(i18n.t("assets:messages.group_delete_failure"))
          throw new Error(res.error.message)
        }
        refetchGroups()
      },
    })

  return (
    <div>
      <h3 className="mb-3 font-semibold">{i18n.t("assets:fields.group", { count: 2 })}</h3>
      <p className="mb-3 text-sm text-gray-500">
        {i18n.t("settings:asset_groups.description")}
      </p>

      {state?.type === "new" && (
        <div className="mb-4 flex cursor-pointer items-center truncate rounded border border-gray-100 bg-gray-50 p-4 text-sm">
          <AssetGroupsEditCreateForm
            onCancel={() => setState(null)}
            afterSubmitSuccess={() => setState(null)}
          />
        </div>
      )}

      {data.length > 0 ? (
        <ul role="list" className="list-none">
          {data.map((assetGroup) => (
            <li key={assetGroup.id} className="list-none">
              <AssetGroupsCard
                assetGroup={assetGroup}
                isEditing={state?.type === "edit" && state.id === assetGroup.id}
                onEdit={(id) => setState({ type: "edit", id })}
                onDelete={() => deleteAssetGroupModal(assetGroup.id)}
                onCancel={() => setState(null)}
              />
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-sm">
          {i18n.t("common:no_token_created_yet", {
            token: i18n.t("assets:fields.group", { count: 2 }),
          })}
        </p>
      )}

      {createScope.hasScope && (
        <Button
          type="secondary"
          icon={Plus}
          onClick={() => {
            setState({ type: "new" })
          }}>
          {i18n.t("settings:asset.add_object_group")}
        </Button>
      )}
    </div>
  )
}

const SettingsAsset = () => (
  <SettingsPageLayout title={i18n.t("asset_other")}>
    <AssetGroups />
    <hr />
    <AssetStateVariants />
  </SettingsPageLayout>
)

export default SettingsAsset
