/* eslint-disable simple-import-sort/imports */

// Keeping i18n import at the top of the file to always load languages before the app is rendered
import i18n from "./i18n"

import "@resources/styles/global.css"
import "container-query-polyfill"
import "regenerator-runtime/runtime"

import { useReloadPrompt } from "@components/notifications/reload-prompt"
import { useOneSignal } from "@components/notifications/use-onesignal"
import ErrorPage from "@components/page/page-error"
import PageLayout from "@components/page/page-layout"
import { defaultLandingPages } from "@components/settings/constants/default-landing-pages"
import PeekLayout from "@components/shared/peek-layout"
import Redirect from "@components/shared/redirect"
import { useWorkOrderStream } from "@components/work-order/use-work-order-stream"
import AppProviders, { AuthenticatedAppProviders } from "@contexts/app-providers"
import { useAuthenticationStateContext } from "@contexts/auth-context"
import { useBreakpoint } from "@contexts/breakpoints"
import { useDetectPrint } from "@contexts/detect-print"
import { useFeature } from "@contexts/feature-flag-context"
import { useUser } from "@contexts/user-context"
import { Language } from "@elara/db"
import {
  BasicApplicationDataDocument,
  IBasicApplicationDataQuery,
} from "@graphql/documents/basic.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import { useSubdomain } from "@hooks/use-subdomain"
import AnalyticsPage from "@pages/analytics"
import AssetDowntime from "@pages/analytics/assets/asset-downtime"
import AssetReliability from "@pages/analytics/assets/asset-reliability"
import ConsumableAdjustments from "@pages/analytics/consumables/adjustments"
import TaskActive from "@pages/analytics/tasks/task-active"
import TaskCompletion from "@pages/analytics/tasks/task-completion"
import ContactsOverview from "@pages/contact"
import FeatureFlagsPage from "@pages/feature-flags"
import { InviteCodePage } from "@pages/invite/code"
import LoadingPage from "@pages/loading"
import MaintenancePage from "@pages/maintenance"
import MaintenanceDetailPage from "@pages/maintenance/detail"
import MaintenanceArchivePage from "@pages/maintenance/archive"
import MaterialsPage from "@pages/material"
import MaterialDetailPage from "@pages/material/detail"
import MeterDetailPage from "@pages/meter/$id"
import OldMeterDetailPage from "@pages/meter/detail"
import MetersPage from "@pages/meters"
import MorePageMobile from "@pages/more"
import TaskIdChecklist from "@pages/new-task.$id.checklist/route"
import TaskComments from "@pages/new-task.$id.comments/route"
import TaskIdDetails from "@pages/new-task.$id.details/route"
import TaskPrintPage from "@pages/new-task.$id.print/route"
import TaskReport from "@pages/new-task.$id.report/route"
import NewTaskDetailPage from "@pages/new-task.$id/route"
import NewTemplateMetadata from "@pages/new-task.template.$templateId.metadata/route"
import NewTemplateDetail from "@pages/new-task.template.$templateId/route"
import NotificationsPage from "@pages/notifications"
import ObjectsPage from "@pages/object"
import AssetDetailPage from "@pages/object/$id"
import AssetDetailChildrenPage from "@pages/object/$id.children"
import AssetDetailInformationPage from "@pages/object/$id.information"
import AssetDetailInformationDocumentsPage from "@pages/object/$id.information.documents"
import AssetDetailInformationGeneralPage from "@pages/object/$id.information.general"
import AssetDetailInformationOperatingSchedule from "@pages/object/$id.information.operating-schedule"
import AssetDetailInformationQRPage from "@pages/object/$id.information.qr"
import AssetDetailInsightPage from "@pages/object/$id.insight"
import AssetDetailInsightActivityPage from "@pages/object/$id.insight.activity"
import AssetDetailInsightReliabilityPage from "@pages/object/$id.insight.reliability"
import AssetDetailMaterialsPage from "@pages/object/$id.materials"
import AssetDetailMetersPage from "@pages/object/$id.meters"
import AssetDetailOverviewPage from "@pages/object/$id.overview"
import AssetDetailTasksPage from "@pages/object/$id.tasks"
import QrRedirect from "@pages/object/detail/qr-redirect"
import ObjectActivityPage from "@pages/object/object-activity"
import ObjectArchivePage from "@pages/object/object-archive"
import RegisterPage from "@pages/register"
import ResetDemo from "@pages/reset-demo"
import QRScannerPage from "@pages/scanner/index"
import SelectLocationPage from "@pages/select-location"
import SettingsPage from "@pages/settings"
import SettingsAPI from "@pages/settings/settings-api"
import SettingsAsset from "@pages/settings/settings-asset"
import SettingsConsumables from "@pages/settings/settings-consumables"
import SettingsGeneralPage from "@pages/settings/settings-general"
import SettingsImport from "@pages/settings/settings-import"
import SettingsInvites from "@pages/settings/settings-invites"
import SettingsMenu from "@pages/settings/settings-menu"
import SettingsNotificationsPage from "@pages/settings/settings-notifications"
import SettingsPermissions from "@pages/settings/settings-permissions"
import SettingsLocations from "@pages/settings/settings-place"
import SettingsProfilePage from "@pages/settings/settings-profile"
import SettingsTeam from "@pages/settings/settings-team-detail"
import SettingsTeams from "@pages/settings/settings-teams"
import SettingsUserManagement from "@pages/settings/settings-user-management"
import SettingsUsers from "@pages/settings/settings-users"
import SettingsWorkOrderCategories from "@pages/settings/settings-work-order-categories"
import SettingsWorkOrderTemplates from "@pages/settings/settings-work-order-templates"
import SetupPage from "@pages/setup"
import { SSOLogin } from "@pages/sso-login"
import AssignedPage from "@pages/task/assigned"
import TaskOverviewPage from "@pages/task/overview"
import PublicTaskDetailPage from "@pages/task/public/task-detail-page"
import TaskDetailPage from "@pages/task/task-detail-page"
import TemplatesPage from "@pages/task/template"
import TemplateDetailPage from "@pages/task/template/detail"
import TasksPage from "@pages/task/views"
import CustomViewPage from "@pages/view/custom-view"
import { Portal } from "@radix-ui/react-portal"
import { useKeycloak } from "@react-keycloak/web"
import * as Sentry from "@sentry/react"
import React, { useEffect, useState } from "react"
import { Toaster } from "react-hot-toast"
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import { useClient } from "urql"
import { changeAppLanguage } from "./i18n/languages"
import MaintenanceDetails from "@pages/maintenance/tabs/details"
import MaintenanceCalendar from "@pages/maintenance/tabs/calendar"
import MaintenanceHistory from "@pages/maintenance/tabs/history"
import MaintenanceTask from "@pages/maintenance/tabs/task"
import MaintenanceNewTask from "@pages/maintenance/tabs/new-task"
import TaskReporting from "@pages/analytics/tasks/task-reporting"
import ServiceRequestCreatePage from "@pages/service-request/create/route"
import ServiceRequestDetailPage from "@pages/service-request/detail/route"
import ServiceRequestListPage from "@pages/service-request/list/route"
import { MaintenanceAIParserPage } from "@pages/maintenance/ai-parser"
import { ReportsPage } from "@pages/reporting/route"

const useBasicData = () => {
  const client = useClient()

  const [hasBasicDataLoaded, setHasBasicDataLoaded] = useState(false)

  useEffect(() => {
    const fetchBasicData = async () => {
      const cacheRes = await client
        .query<IBasicApplicationDataQuery>(
          BasicApplicationDataDocument,
          {},
          { requestPolicy: "cache-only" }
        )
        .toPromise()

      const hasSomeData = !!cacheRes.data
      if (hasSomeData) {
        setHasBasicDataLoaded(true)
      }
      const networkRes = await client
        .query<IBasicApplicationDataQuery>(
          BasicApplicationDataDocument,
          {},
          { requestPolicy: "network-only" }
        )
        .toPromise()
      if (hasSomeData || !!networkRes.data) {
        setHasBasicDataLoaded(true)
      }
    }

    fetchBasicData()

    const intervalId = setInterval(fetchBasicData, 1000 * 60 * 10)

    return () => {
      clearInterval(intervalId)
    }
  }, [client])

  return hasBasicDataLoaded
}

const AuthenticatedApp = React.memo(() => {
  useOneSignal()
  useReloadPrompt()
  useWorkOrderStream()

  const user = useUser()
  const location = useLocation()
  const isPrinting = useDetectPrint()
  const { renderMobile } = useBreakpoint()
  const hasBasicDataLoaded = useBasicData()

  const hasTaskDetail = useFeature("task_detail")
  const hasMaintenanceFeature = useFeature("maintenance")

  const adminScope = usePermissionScope(IPermissionScopeEnum.AppAccountManagement)

  const defaultHomePage = user.profile.settings.homePage ?? defaultLandingPages[0].path
  const state = location.state as { backgroundLocation?: Location } | null
  const isPeekVisible = !!state?.backgroundLocation

  useEffect(() => {
    if (user.profile && user.profile.language !== i18n.language) {
      changeAppLanguage(user.profile.language as Language, true)
    }
  }, [user.profile, i18n])

  // Uncomment below lines to debug routing issues:
  // useEffect(() => {
  //   if (process.env.NODE_ENV === "development") {
  //     console.log(
  //       `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(
  //         location.state
  //       )}`
  //     )
  //     console.log(`History: ${JSON.stringify(window.history, null, 2)}`)
  //   }
  // }, [location])

  if (!hasBasicDataLoaded) return <LoadingPage />

  if (!user.location.setupInfo && location.pathname !== "/setup" && adminScope.hasScope)
    return <Navigate to="/setup" />

  const objectDetail = (
    <Route path=":id" element={<AssetDetailPage />}>
      <Route path="insights" element={<AssetDetailInsightPage />}>
        <Route path="reliability" element={<AssetDetailInsightReliabilityPage />} />
        <Route path="activity" element={<AssetDetailInsightActivityPage />} />
        <Route index element={<Redirect to="reliability" />} />
      </Route>

      <Route path="children" element={<AssetDetailChildrenPage />} />
      <Route path="materials" element={<AssetDetailMaterialsPage />} />
      <Route path="meters" element={<AssetDetailMetersPage />} />
      <Route path="overview" element={<AssetDetailOverviewPage />} />
      <Route path="info" element={<AssetDetailInformationPage />}>
        <Route path="documents" element={<AssetDetailInformationDocumentsPage />} />
        <Route path="general" element={<AssetDetailInformationGeneralPage />} />
        <Route path="qr" element={<AssetDetailInformationQRPage />} />
        <Route
          path="operating-schedule"
          element={<AssetDetailInformationOperatingSchedule />}
        />
        <Route index element={<Redirect to="general" />} />
      </Route>

      <Route path="tasks" element={<AssetDetailTasksPage />} />
      <Route index element={<Redirect to="overview" />} />
    </Route>
  )

  return (
    <Sentry.ErrorBoundary
      fallback={({ resetError }) => <ErrorPage resetError={resetError} />}>
      {!(isPeekVisible && isPrinting) && (
        <Routes location={state?.backgroundLocation || location}>
          <Route
            path="/"
            element={<PageLayout isInBackground={!!state?.backgroundLocation} />}>
            {/* Same route order as in navigation */}
            <Route path="notifications" element={<NotificationsPage />} />

            <Route path="task">
              <Route path="overview" element={<TaskOverviewPage />} />
              <Route path="views" element={<TasksPage />} />
              <Route path="assigned">
                <Route path=":assignee" element={<AssignedPage />} />
                <Route index element={<Redirect to="/task/assigned/me" />} />
              </Route>
              {!hasTaskDetail ? (
                <Route path="template">
                  <Route index element={<TemplatesPage />} />
                  <Route path=":templateId/*" element={<TemplateDetailPage />} />
                </Route>
              ) : (
                <Route path="template">
                  <Route index element={<TemplatesPage />} />
                  <Route path=":templateId" element={<NewTemplateDetail />}>
                    <Route path="metadata" element={<NewTemplateMetadata />} />
                    <Route path="details">
                      <Route path=":workOrderId" element={<NewTaskDetailPage template />}>
                        <Route path="details" element={<TaskIdDetails template />} />
                        {/* <Route path="comments" element={<TaskComments />} /> */}
                        <Route path="checklist" element={<TaskIdChecklist />} />
                        {/* <Route path="report" element={<TaskReport />} /> */}
                        <Route path="print" element={<TaskPrintPage template />} />
                        <Route path="*" element={<Redirect to="details" />} />
                        <Route index element={<Redirect to="details" />} />
                      </Route>
                    </Route>
                    <Route path="*" element={<Redirect to="metadata" />} />
                    <Route index element={<Redirect to="metadata" />} />
                  </Route>
                </Route>
              )}

              <Route index element={<Navigate to="views" replace />} />

              {hasTaskDetail ? (
                <Route path=":workOrderId" element={<NewTaskDetailPage />}>
                  <Route path="details" element={<TaskIdDetails />} />
                  <Route path="comments" element={<TaskComments />} />
                  <Route path="checklist" element={<TaskIdChecklist />} />
                  <Route path="report" element={<TaskReport />} />
                  <Route path="print" element={<TaskPrintPage />} />
                  <Route path="*" element={<Redirect to="details" />} />
                  <Route index element={<Redirect to="details" />} />
                </Route>
              ) : (
                <Route path=":workOrderId/*" element={<TaskDetailPage />} />
              )}
            </Route>

            <Route path="work-order/*" element={<Redirect to="/task/*" />} />
            <Route path="logbook" element={<Redirect to="/task/views" />} />

            <Route path="analytics" element={<AnalyticsPage />}>
              <Route path="tasks">
                <Route path="active" element={<TaskActive />} />
                <Route path="completed" element={<TaskCompletion />} />
                <Route path="reporting" element={<TaskReporting />} />

                <Route index element={<Redirect to="/analytics/tasks/active" />} />
              </Route>

              <Route path="objects">
                <Route path="downtime" element={<AssetDowntime />} />
                <Route path="reliability" element={<AssetReliability />} />

                <Route index element={<Redirect to="/analytics/objects/downtime" />} />
              </Route>
              <Route path="consumables">
                <Route path="adjustments" element={<ConsumableAdjustments />} />

                <Route
                  index
                  element={<Redirect to="/analytics/consumables/adjustments" />}
                />
              </Route>
              <Route index element={<Navigate to="tasks" replace />} />
            </Route>

            <Route path="object">
              <Route path="views" element={<ObjectsPage />} />
              <Route path="activity" element={<ObjectActivityPage />} />
              <Route path="archive" element={<ObjectArchivePage />} />

              {/* old redirects */}
              <Route path="state_history" element={<Navigate to="activity" replace />} />
              <Route path="meters" element={<Redirect to="/meter" />} />

              <Route
                path=":mobId/meter/:meterId/*"
                element={<Redirect to="/meter/:meterId" />}
              />
              {objectDetail}

              <Route index element={<Navigate to="views" replace />} />
            </Route>
            <Route path="asset/*" element={<Redirect to="/object/*" />} />

            <Route path="qr">
              <Route path="scanner" element={<QRScannerPage />} />
              <Route path=":qrCode" element={<QrRedirect />} />
            </Route>
            <Route path="qr-code">
              <Route path="scanner" element={<QRScannerPage />} />
              <Route path=":qrCode" element={<QrRedirect />} />
            </Route>

            <Route path="view">
              <Route path=":viewId" element={<CustomViewPage key="view" />} />
            </Route>

            <Route path="meter">
              <Route
                path=":meterId/*"
                element={
                  hasMaintenanceFeature ? <MeterDetailPage /> : <OldMeterDetailPage />
                }
              />
              <Route index element={<MetersPage />} />
            </Route>

            <Route path="contact">
              <Route index element={<ContactsOverview />} />
            </Route>

            <Route path="consumable">
              <Route index element={<MaterialsPage />} />
              <Route path=":id">
                <Route path="*" element={<MaterialDetailPage />} />
                <Route index element={<Navigate to="details" replace />} />
              </Route>
            </Route>

            <Route path="maintenance">
              <Route path="views" element={<MaintenancePage />} />
              <Route path="archive" element={<MaintenanceArchivePage />} />
              <Route path="ai-parser" element={<MaintenanceAIParserPage />} />

              <Route path=":id" element={<MaintenanceDetailPage />}>
                <Route path="details" element={<MaintenanceDetails />} />
                <Route path="calendar" element={<MaintenanceCalendar />} />
                <Route path="history" element={<MaintenanceHistory />} />
                {hasTaskDetail ? (
                  <Route path="task" element={<MaintenanceNewTask />}>
                    <Route path="details" element={<TaskIdDetails template />} />
                    <Route path="checklist" element={<TaskIdChecklist />} />
                    <Route path="*" element={<Redirect to="details" />} />
                    <Route index element={<Redirect to="details" />} />
                  </Route>
                ) : (
                  <Route path="task" element={<MaintenanceTask />} />
                )}
                <Route path="*" element={<Redirect to="details" />} />
                <Route index element={<Redirect to="details" />} />
              </Route>

              <Route index element={<Navigate to="views" replace />} />
            </Route>

            <Route path="template">
              <Route
                path=":templateId"
                element={<Redirect to="/task/template/:templateId" />}
              />
              <Route index element={<Navigate to="settings/template" replace />} />
            </Route>

            {/* Service Requests */}
            <Route path="service-request">
              <Route index element={<ServiceRequestListPage />} />
              <Route path="create" element={<ServiceRequestCreatePage />} />
              <Route path=":id" element={<ServiceRequestDetailPage />} />
            </Route>

            {/* Reports */}
            <Route path="reports" element={<ReportsPage />} />

            <Route path="feature-flags" element={<FeatureFlagsPage />} />
            <Route path="reset-demo" element={<ResetDemo />} />

            <Route path="settings/template" element={<Redirect to="/task/template" />} />

            <Route path="settings" element={<SettingsPage />}>
              <Route path="general" element={<SettingsGeneralPage />} />
              <Route path="profile" element={<SettingsProfilePage />} />
              <Route path="notifications" element={<SettingsNotificationsPage />} />
              <Route path="template" element={<SettingsWorkOrderTemplates />} />
              <Route path="user-management" element={<SettingsUserManagement />}>
                <Route path="users" element={<SettingsUsers />} />
                <Route path="invites" element={<SettingsInvites />} />
                <Route path="permissions" element={<SettingsPermissions />} />
                <Route path="*" element={<Redirect to="users" />} />
                <Route index element={<Redirect to="users" />} />
              </Route>
              <Route path="categories" element={<SettingsWorkOrderCategories />} />
              <Route path="asset" element={<SettingsAsset />} />
              <Route path="consumables" element={<SettingsConsumables />} />
              <Route path="teams" element={<SettingsTeams />} />
              <Route path="location" element={<SettingsLocations />} />
              <Route path="api" element={<SettingsAPI />} />
              <Route path="team/:teamId" element={<SettingsTeam />} />
              <Route path="import" element={<SettingsImport />} />
              <Route
                index
                element={
                  !renderMobile ? <Navigate to="profile" replace /> : <SettingsMenu />
                }
              />
              <Route path="*" element={<Navigate to="profile" replace />} />
            </Route>

            {/* Public Routes, to be replicated on `UnauthenticatedApp` as well */}
            <Route path="public">
              <Route path="task">
                <Route path=":id" element={<PublicTaskDetailPage />} />
              </Route>
            </Route>

            {renderMobile &&
              ["more", "menu"].map((path) => (
                <Route key={path} path={path} element={<MorePageMobile />} />
              ))}

            <Route index element={<Navigate to={defaultHomePage} replace />} />

            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>

          <Route path="select-location" element={<SelectLocationPage />} />

          <Route path="/register" element={<RegisterPage />} />
          <Route path="/setup" element={<SetupPage />} />
        </Routes>
      )}

      {state?.backgroundLocation && (
        <Routes location={location}>
          <Route path="/" element={<PeekLayout />}>
            <Route
              path="meter/:meterId/*"
              element={hasMaintenanceFeature ? <MeterDetailPage /> : <OldMeterDetailPage />}
            />

            <Route path="object">
              <Route
                path=":mobId/meter/:meterId/*"
                element={<Redirect to="/meter/:meterId" />}
              />
              {objectDetail}
            </Route>

            <Route path="asset/*" element={<Redirect to="/object/*" />} />

            <Route path="task">
              {hasTaskDetail ? (
                <Route path=":workOrderId" element={<NewTaskDetailPage />}>
                  <Route path="details" element={<TaskIdDetails />} />
                  <Route path="comments" element={<TaskComments />} />
                  <Route path="checklist" element={<TaskIdChecklist />} />
                  <Route path="report" element={<TaskReport />} />
                  <Route path="print" element={<TaskPrintPage />} />
                  <Route path="*" element={<Redirect to="details" />} />
                  <Route index element={<Redirect to="details" />} />
                </Route>
              ) : (
                <Route path=":workOrderId/*" element={<TaskDetailPage />} />
              )}
            </Route>

            <Route path="work-order/*" element={<Redirect to="/task/*" />} />

            <Route path="consumable">
              <Route path=":id">
                <Route path="*" element={<MaterialDetailPage />} />
                <Route index element={<Redirect to="details" />} />
              </Route>
            </Route>

            <Route path="maintenance">
              <Route path=":id" element={<MaintenanceDetailPage />}>
                {/* <Route path="*" element={<MaintenanceDetailPage />} /> */}

                <Route path="details" element={<MaintenanceDetails />} />
                <Route path="calendar" element={<MaintenanceCalendar />} />
                <Route path="history" element={<MaintenanceHistory />} />
                {hasTaskDetail ? (
                  <Route path="task" element={<MaintenanceNewTask />}>
                    <Route path="details" element={<TaskIdDetails template />} />
                    <Route path="checklist" element={<TaskIdChecklist />} />
                    <Route path="*" element={<Redirect to="details" />} />
                    <Route index element={<Redirect to="details" />} />
                  </Route>
                ) : (
                  <Route path="task" element={<MaintenanceTask />} />
                )}
                <Route path="*" element={<Redirect to="details" />} />
                <Route index element={<Redirect to="details" />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      )}
    </Sentry.ErrorBoundary>
  )
})

const RedirectToLogin = () => {
  const { keycloak, initialized } = useKeycloak()

  useEffect(() => {
    if (initialized && !keycloak.authenticated) {
      keycloak?.login()
    }
  }, [keycloak, initialized])

  return <LoadingPage />
}

const UnauthenticatedApp = () => {
  const subdomain = useSubdomain()
  const { initialized } = useKeycloak()

  if (!initialized) return <LoadingPage />

  return (
    <Routes>
      <Route path="public">
        <Route path="task">
          <Route path=":id" element={<PublicTaskDetailPage />} />
        </Route>
      </Route>

      {!subdomain ? (
        <Route path="*" element={<RedirectToLogin />} />
      ) : (
        <Route path="*" element={<SSOLogin subdomain={subdomain} />} />
      )}
    </Routes>
  )
}

const NoLocationApp = () => (
  <Routes>
    <Route path="/select-location" element={<SelectLocationPage />} />
    <Route path="/invite/:inviteCode" element={<InviteCodePage />} />
    <Route path="*" element={<LoadingPage />} />
  </Routes>
)

const MainApp = () => {
  const authState = useAuthenticationStateContext()
  if (!authState) return <LoadingPage />

  switch (authState.state.stage) {
    case "fully_authenticated":
      return (
        <AuthenticatedAppProviders>
          <AuthenticatedApp />
        </AuthenticatedAppProviders>
      )
    case "with_location_infos":
      return <NoLocationApp />
    default:
      return <UnauthenticatedApp />
  }
}

const App = () => (
  <AppProviders>
    <MainApp />
    <Portal className="z-max">
      <Toaster />
    </Portal>
  </AppProviders>
)

export default App
