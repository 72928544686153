import { useBreakpoint } from "@contexts/breakpoints"
import { IViewDataTypeEnum } from "@elara/db"
import { Data } from "@elara/select"
import { ICustomViewFragment } from "@graphql/documents/custom-view.generated"
import { setStickyStateValue, StickyType, useDisclosure } from "@hooks"
import React, { PropsWithChildren, useState } from "react"

import LoadingSpinner from "../shared/loading-spinner"
import { DataViewUpdateCustomViewConfig } from "./data-view-config/data-view-update-custom-view-config"
import { useDataViewConfigActions } from "./data-view-config/use-data-view-config-actions"
import { useDataViewConfigStorage } from "./data-view-config/use-data-view-config-storage"
import { Column, DataViewConfiguration, DataViewLayoutType } from "./data-view-types"

type DataViewConfigContextValue<D extends Data, Id extends string> = {
  id: string
  columns: Column<D, Id, {}>[]
  renderedColumns: Column<D, Id, {}>[]
  config: DataViewConfiguration<Id>
  layoutType: DataViewLayoutType
  resettedConfig: DataViewConfiguration<Id>
  isConfigPersisted: boolean
  storeConfig: (config?: DataViewConfiguration<Id>) => Promise<void>
  saveAsCustomView: () => void
  save: (config?: DataViewConfiguration<Id>) => void
  resetConfig: () => Promise<void>
  actions: ReturnType<typeof useDataViewConfigActions<Id>>
}

const DataViewConfigContext = React.createContext<DataViewConfigContextValue<Data, string>>(
  undefined!
)

export function useDataViewConfigContext<D extends Data, Id extends string>() {
  const context = React.useContext(DataViewConfigContext)
  if (!context) {
    throw new Error("useDataViewConfigContext must be used within a DataViewConfig")
  }
  return context as unknown as DataViewConfigContextValue<D, Id>
}

export type DataViewConfigProps<D extends Data, Id extends string> = {
  columns: Column<D, Id, {}>[]
  customView: ICustomViewFragment | null
  defaultConfig: DataViewConfiguration<Id>
  dataType: IViewDataTypeEnum
  configId: string
  configStickyness: StickyType
  storeAllConfigChangesInCustomView?: boolean
  initialValuesForSaveAsCustomView?: Partial<ICustomViewFragment>
  onCreateView?: (customView: ICustomViewFragment) => void
  isEditView?: boolean
  onEditViewFinish?: () => void
}

function getLocalStorageId(configId: string) {
  return `DataView:Configuration:${configId}-v4`
}

export function resetDataViewConfiguration(configId: string) {
  setStickyStateValue(getLocalStorageId(configId), undefined)
}

export function DataViewConfig<D extends Data, Id extends string>(
  props: PropsWithChildren<DataViewConfigProps<D, Id>>
) {
  const bp = useBreakpoint()
  const configStorage = useDataViewConfigStorage(props)
  const actions = useDataViewConfigActions<Id>(configStorage.changeConfig)

  const dialog = useDisclosure({
    initialValue: !!props.isEditView,
    onClose: () => {
      props.isEditView && props.onEditViewFinish?.()
    },
  })

  const [updateCustomViewConfig, setUpdateCustomViewConfig] =
    useState<DataViewConfiguration<Id> | null>(
      props.isEditView ? (props.customView?.config as DataViewConfiguration<Id>) : null
    )

  if (configStorage.config === null) return <LoadingSpinner />

  const saveAsCustomView = () => {
    setUpdateCustomViewConfig(configStorage.config)
    dialog.onOpen()
  }

  const save = () => {
    if (props.customView || props.storeAllConfigChangesInCustomView) {
      saveAsCustomView()
    } else if (configStorage.config) {
      configStorage.storeConfig(configStorage.config)
    }
  }

  const renderedColumns = (configStorage.config?.columnOrder ?? [])
    .map((id) => props.columns.find((c) => c.id === id))
    .filter(Boolean) as Column<D, Id, {}>[]

  let layoutType = configStorage.config.layoutType ?? "table"
  if (bp.mobile && layoutType === "table") {
    layoutType = "list"
  }

  const value = {
    id: props.configId,
    layoutType,
    config: configStorage.config,
    resettedConfig: configStorage.resettedConfig,
    isConfigPersisted: configStorage.isConfigPersisted,
    changeConfig: configStorage.changeConfig,
    resetConfig: configStorage.resetConfig,
    saveAsCustomView,
    save,
    actions,
    columns: props.columns,
    renderedColumns: renderedColumns,
  } as unknown as DataViewConfigContextValue<Data, string>

  return (
    <DataViewConfigContext.Provider value={value}>
      {props.children}
      {updateCustomViewConfig && (
        <DataViewUpdateCustomViewConfig
          isOpen={dialog.isOpen}
          dataType={props.dataType}
          config={updateCustomViewConfig}
          isDefaultView={props.storeAllConfigChangesInCustomView || !props.customView?.id}
          isEditView={props.isEditView}
          onOpenChange={dialog.changeOpen}
          onCreateView={props.onCreateView}
          customView={{ ...props.initialValuesForSaveAsCustomView, ...props.customView }}
        />
      )}
    </DataViewConfigContext.Provider>
  )
}
