import { OnResultFunction, QrReader } from "react-qr-reader"

const QRScannerPage = () => {
  const constraints: MediaTrackConstraints = {
    aspectRatio: 1,
    facingMode: "environment",
  }

  const onResult: OnResultFunction = (result) => {
    if (!result) return

    const url = result.getText()
    window.location.href = url
  }

  return (
    <div className="space-y-6 p-5 text-center">
      <QrReader
        className="relative overflow-hidden rounded after:absolute after:inset-x-0 after:top-0 after:h-[3px] after:animate-scanner after:bg-white after:opacity-50 after:shadow-2xl after:content-['']"
        onResult={onResult}
        constraints={constraints}
      />

      <div className="mx-auto max-w-xs space-y-6 opacity-90">
        <p>
          Point your camera at a QR Code generated from Elara to view more information about
          the object.
        </p>
      </div>
    </div>
  )
}

export default QRScannerPage
