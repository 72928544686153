// Function to convert a JavaScript object to a structured shortcode string.
export function formatObjectToShortcode(obj: any): string {
  // Helper function to format an array into a shortcode string.
  const formatArray = (arr: any[]): string => {
    return arr
      .map((item, index) => {
        // If the item is an object, format it using formatObjectToShortcode.
        if (typeof item === "object" && item !== null) {
          return `${index}=${formatObjectToShortcode(item)}`
        } else {
          // Otherwise, use formatValue to format the item.
          return `${index}=${formatValue(item)}`
        }
      })
      .join(",") // Join all items using a comma.
  }

  // Helper function to format a value into a shortcode string.
  const formatValue = (value: any): string => {
    if (typeof value === "string") {
      // If the value is a string, escape double quotes and wrap it with double quotes.
      return `"${value.replace(/"/g, '\\"')}"`
    } else if (Array.isArray(value)) {
      // If the value is an array, format it using formatArray.
      return `[${formatArray(value)}]`
    } else if (typeof value === "object" && value !== null) {
      // If the value is an object, recursively format it using formatObjectToShortcode.
      return formatObjectToShortcode(value)
    } else {
      // For other types, convert the value to a string.
      return String(value)
    }
  }

  // Helper function to format an object into a shortcode string.
  const formatObject = (obj: { [key: string]: any }): string => {
    return (
      "[" +
      // Map each key-value pair to a string and join them with commas.
      Object.keys(obj)
        .map((key) => {
          return `${key}=${formatValue(obj[key])}`
        })
        .join(",") +
      "]"
    )
  }

  // If the input is not an object or is null, convert it to a string and return.
  if (typeof obj !== "object" || obj === null) {
    return String(obj)
  }

  // For objects, use formatObject to format the entire object.
  return formatObject(obj)
}
