import { Button } from "@components/shared"
import { useSSOInfo } from "@hooks/use-sso-info"
import i18n from "@i18n"
import { useLogout } from "@models/authentication"
import { useKeycloak } from "@react-keycloak/web"
import { LongLogo } from "@resources/logos"

import LoadingPage from "./loading"

export function SSOLogin(props: { subdomain: string }) {
  const { keycloak } = useKeycloak()
  const logout = useLogout()
  const query = useSSOInfo()

  const login = () => {
    keycloak.login({
      idpHint: query.data?.idp,
    })
  }

  if (query.error) {
    return (
      <div className="mt-8 flex flex-col items-center justify-center">
        <p>Could not find any login information associated with {props.subdomain}.</p>
        <Button onClick={() => window.location.reload()} className="mt-4">
          Try again
        </Button>
      </div>
    )
  }

  if (query.isLoading || !query.data) {
    return <LoadingPage />
  }

  return (
    <div className="p-8">
      <div className="flex justify-between">
        <LongLogo className={"h-10 w-40 text-blue-700"} />
        <Button type="tertiary" onClick={logout}>
          {i18n.t("settings:profile.logout")}
        </Button>
      </div>
      <div className="mt-4 flex  flex-1 flex-col items-center justify-center">
        <div className="min-w-[25vw] max-w-sm rounded-xl bg-gray-100 p-8">
          <div className="text-2xl font-bold">{query.data?.orgName}</div>
          <div className="mt-2 text-lg text-gray-700">{i18n.t("common:SSO")}</div>
          <Button onClick={login} className="mt-4 w-full">
            {i18n.t("common:login")}
          </Button>

          <hr className="my-4" />

          <Button
            type="tertiary"
            size="extra-small"
            className="-mx-2.5 -mb-2 inline-flex"
            onClick={() => {
              keycloak.login()
            }}>
            {i18n.t("common:go_to_standard_login")}
          </Button>
        </div>
      </div>
    </div>
  )
}
