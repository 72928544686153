import { Flex } from "@components/layout"
import Button from "@components/shared/button"
import { SectionHeader } from "@components/shared/section-header"
import toast from "@components/shared/toast"
import { UploadCategoryMultiSelect } from "@components/shared/upload-category-select"
import { uuid } from "@elara/db/src/scalars"
import {
  useDeleteUploadXUploadCategoryMutation,
  useInsertUploadXUploadCategoryMutation,
} from "@graphql/documents/upload.generated"
import { useUploadTagsQuery } from "@graphql/documents/upload.generated"
import { Transition } from "@headlessui/react"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import { toDateString } from "@utils"
import classNames from "classnames"
import { Fragment } from "react"
import { FileUpload } from "src/types"

import { useConfirmModal } from "./confirm-modal"

type ImagePropertiesDialogProps = {
  upload: FileUpload | null | undefined
  onClick: () => void
  onDelete?: (id: uuid) => void
  confirmQuickDelete?: boolean | undefined
}

export const ImagePropertiesDialog = (props: ImagePropertiesDialogProps) => {
  const { upload, onClick, onDelete, confirmQuickDelete } = props
  const { id } = upload?.data ?? {}
  const scope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)

  const [querryTags, refetch] = useUploadTagsQuery({
    variables: {
      uploadId: upload?.data.id || "",
    },
  })
  const getTagString = (item: any) =>
    [...(item.data?.upload_x_upload_category || [])].map((item) => item.category?.id ?? "")

  const [, insertUploadCategory] = useInsertUploadXUploadCategoryMutation()
  const [, deleteUploadCategory] = useDeleteUploadXUploadCategoryMutation()

  const confirm = useConfirmModal({
    title: i18n.t("common:delete_token", { token: i18n.t("common:photo", { count: 1 }) }),
    content: i18n.t("common:messages.token_delete_confirmation", {
      token: i18n.t("common:photo", { count: 1 }),
    }),
    okText: i18n.t("common:delete"),
    cancelText: i18n.t("common:cancel"),
    onOk: () => onDelete?.(id!),
  })

  return (
    <DialogPrimitive.Root open onOpenChange={() => onClick()}>
      <DialogPrimitive.Portal forceMount className="isolate">
        <Transition.Root show className="isolate">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <DialogPrimitive.Overlay
              forceMount
              className="fixed inset-0 z-20 bg-black/50"
            />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <DialogPrimitive.Content
              forceMount
              className={classNames(
                "fixed z-50 bg-white",
                "w-[95vw] max-w-2xl rounded-lg md:w-full",
                "top-[10vh] left-[50%] -translate-x-[50%]",
                "flex flex-col min-h-0 max-h-[80vh]",
                "focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75",
                "overflow-y-auto"
              )}>
              <div className="flex flex-col gap-y-4 p-6">
                <div className="grid grid-cols-[auto_1fr] gap-2 @mobile/page:grid-cols-[auto_1fr_auto_1fr]">
                  <Flex
                    justify="space-between"
                    align="center"
                    row
                    className="col-span-2 pb-1 @mobile/page:col-span-4">
                    <SectionHeader>{i18n.t("common:property_other")}</SectionHeader>
                  </Flex>

                  <label
                    className="mr-4 text-sm font-medium text-gray-900"
                    style={{ overflowWrap: "anywhere", maxWidth: `${768 / 4}px` }}>
                    {i18n.t("assets:dialogs:file_name")}
                  </label>
                  <div className="text-sm text-gray-700">{upload?.data.file_name}</div>
                  <label
                    className="mr-4 text-sm font-medium text-gray-900"
                    style={{ overflowWrap: "anywhere", maxWidth: `${768 / 4}px` }}>
                    {i18n.t("assets:dialogs:file_size")}
                  </label>
                  <div className="text-sm text-gray-700">
                    {((upload?.data.file_size ?? 0) / (1000 * 1000))
                      .toFixed(2)
                      .replace(/\.?0+$/, "")}{" "}
                    mb
                  </div>
                  <label
                    className="mr-4 text-sm font-medium text-gray-900"
                    style={{ overflowWrap: "anywhere", maxWidth: `${768 / 4}px` }}>
                    {i18n.t("assets:dialogs:upload_date")}
                  </label>
                  <div className="text-sm text-gray-700">
                    {toDateString(new Date(upload?.data.created_at ?? ""))}
                  </div>
                </div>
                <Flex
                  justify="space-between"
                  align="center"
                  row
                  className="col-span-2 pb-1 @mobile/page:col-span-4">
                  <SectionHeader>
                    {i18n.t("assets:dialogs:category", { count: 2 })}
                  </SectionHeader>
                </Flex>
                <UploadCategoryMultiSelect
                  value={querryTags.fetching ? [""] : getTagString(querryTags)}
                  summaryMode="summary"
                  classForTrigger="!bg-white"
                  showArrow={false}
                  onChange={async (e) => {
                    const selectedCategory = e
                    const tags = getTagString(querryTags)
                    const addCt = selectedCategory.filter((ct) => !tags.includes(ct))
                    const deleteCt = tags.filter((ct) => !selectedCategory.includes(ct))
                    const res =
                      addCt.length > 0 &&
                      (await insertUploadCategory(
                        {
                          upload_category_id: addCt[0],
                          upload_id: upload?.data.id ?? "",
                        },
                        scope.context()
                      ))

                    const deletres =
                      deleteCt.length > 0 &&
                      (await deleteUploadCategory(
                        {
                          upload_id: upload?.data.id ?? "",
                          upload_category_id: deleteCt[0],
                        },
                        scope.context()
                      ))
                    if (res && res.error) {
                      toast.error("Kategorie konnte nicht aktualisiert werden.")
                    }
                    if (deletres && deletres.error) {
                      toast.error("Kategorie konnte nicht gelöscht werden.")
                    }
                    refetch()
                  }}
                />

                <div className="flex justify-between">
                  <Button
                    type="primary"
                    size="small"
                    color="red"
                    onClick={() => {
                      if (confirmQuickDelete) {
                        confirm.show()
                      } else {
                        onDelete?.(id!)
                      }
                    }}>
                    {i18n.t("common:delete")}
                  </Button>
                  <Button type="primary" size="small" onClick={onClick}>
                    {i18n.t("common:close")}
                  </Button>
                  {confirm.component}
                </div>
              </div>
            </DialogPrimitive.Content>
          </Transition.Child>
        </Transition.Root>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  )
}
