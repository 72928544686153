import AssetStateVariantLabel from "@components/asset/asset-state-variant-label"
import { Tooltip } from "@components/shared/tooltip"
import { IAssetStateVariantTypeEnum } from "@elara/db"
import i18n from "@i18n"
import { AvailabilityData } from "@pages/object/$id.insight.reliability"
import { cn, formatLocaleNumber } from "@utils"
import { formatDate, formatFractionalHours } from "@utils/date"
import { isSameDay } from "date-fns"

import { useAsset } from "../../pages/object/$id"

export const AvailabilityBar = (props: { data: AvailabilityData[] }) => {
  const { data } = props
  const { asset } = useAsset()
  return (
    <div className="flex space-x-0.5">
      {data.map((item) => {
        const assetOperatingSince = asset.operating_since
          ? new Date(asset.operating_since)
          : new Date(asset.created_at)
        const isSameDate = isSameDay(new Date(item.day), assetOperatingSince)
        const isBeforeInstallation = !isSameDate && new Date(item.day) < assetOperatingSince
        let state = "online"
        if (item.scheduled_operating_hours === 0 || isBeforeInstallation) {
          state = "ignore"
        } else if (item.downtimes) {
          if (
            item.downtimes.some(
              (d) => d.asset_state_variant.type === IAssetStateVariantTypeEnum.Offline
            )
          ) {
            state = "offline"
          } else if (
            item.downtimes.some(
              (d) => d.asset_state_variant.type === IAssetStateVariantTypeEnum.DoNotTrack
            )
          ) {
            state = "do_not_track"
          }
        }
        return (
          <Tooltip
            key={item.day}
            delayDuration={100}
            asChild
            content={
              <div>
                <div className="font-medium">
                  {formatDate(new Date(item.day), "EE, P")}{" "}
                </div>
                <div className="grid grid-cols-[auto_auto] gap-x-3 gap-y-1">
                  <div className="font-medium text-gray-500">
                    {i18n.t("assets:state.fields.operating_hours")}
                  </div>
                  <div className="text-gray-500">
                    {isBeforeInstallation
                      ? "0 / 0"
                      : `${formatFractionalHours(
                          item.operating_hours
                        )} / ${formatFractionalHours(item.scheduled_operating_hours)}`}
                  </div>
                  <div className="font-medium text-gray-500">
                    {i18n.t("assets:state.fields.availability")}
                  </div>
                  <div className="text-gray-500">
                    {isBeforeInstallation
                      ? "0%"
                      : formatLocaleNumber(item.availability, { style: "percent" })}
                  </div>
                  {!!item.planned_downtime_hours && (
                    <>
                      <div className="font-medium text-gray-500">
                        {i18n.t("assets:state.fields.planned_downtime_hours")}
                      </div>
                      <div className="text-gray-500">
                        {formatFractionalHours(item.planned_downtime_hours)}
                      </div>
                    </>
                  )}
                  {!!item.unplanned_downtime_hours && (
                    <>
                      <div className="font-medium text-gray-500">
                        {i18n.t("assets:state.fields.unplanned_downtime_hours")}
                      </div>
                      <div className="text-gray-500">
                        {formatFractionalHours(item.unplanned_downtime_hours)}
                      </div>
                    </>
                  )}
                </div>
                {item.downtimes && (
                  <div>
                    <h4 className="mt-1 font-medium text-gray-600">
                      {i18n.t("assets:state.reliablity.downtime_other")}
                    </h4>
                    <div className="flex flex-col divide-y divide-gray-200">
                      {item.downtimes?.map((d) => (
                        <div key={d.id} className="py-0.5 text-gray-500">
                          {i18n.t("assets:state.fields.started_at")}{" "}
                          {formatDate(new Date(d.started_at), "P, p")}
                          <AssetStateVariantLabel
                            variant={d.asset_state_variant}
                            size="small"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            }>
            <button
              type="button"
              className={cn("h-8 w-full rounded-none text-xs", {
                "bg-green-500": state === "online",
                "bg-red-500": state === "offline",
                "bg-gray-500": state === "do_not_track",
                "bg-gray-50": state === "ignore",
              })}></button>
          </Tooltip>
        )
      })}
    </div>
  )
}
