import { Button } from "@components/shared"
import BlockGroup from "@components/work-order/block/block-group"
import BlockGroupForm from "@components/work-order/block/block-group-form"
import { BlockElementFragment } from "@components/work-order/block/elements/block-element-types"
import {
  IBlockGroupTypeEnum,
  IPermissionScopeEnum,
  IWorkOrderStatusEnum,
  IWorkOrderTypeEnum,
} from "@elara/db"
import { IWorkOrderFragment } from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { Notebook } from "@phosphor-icons/react"
import React from "react"

import { EmptySection } from "./empty"
import { Section } from "./section"

export function TaskFeedback(props: { task: IWorkOrderFragment }) {
  const [isEditing, setIsEditing] = React.useState(false)

  const isTemplate = props.task.type === IWorkOrderTypeEnum.Template
  const isDone =
    props.task.status === IWorkOrderStatusEnum.Done ||
    props.task.status === IWorkOrderStatusEnum.Canceled

  const isNotEmpty =
    !!props.task.feedback?.feedback?.trim() || !!props.task.feedback?.uploads?.length

  const checklist = props.task.block_groups.find(
    (group) => group.group_type === IBlockGroupTypeEnum.Feedback
  )
  const elements = (checklist?.elements ?? []) as BlockElementFragment[]
  const isChecklistEmpty = elements.length === 0

  return (
    <Section
      icon={<Notebook />}
      title={i18n.t("tasks:report.feedback.title")}
      action={
        isEditing ? (
          <div className="space-x-3">
            <Button
              type="secondary"
              onClick={() => setIsEditing(false)}
              className=" print:hidden">
              {i18n.t("common:discard")}
            </Button>
          </div>
        ) : (
          isNotEmpty && (
            <Button
              type="tertiary"
              onClick={() => setIsEditing((e) => !e)}
              className=" print:hidden">
              {i18n.t("common:edit")}
            </Button>
          )
        )
      }>
      {isEditing ? (
        <BlockGroupForm
          groupId={checklist?.id}
          initialElements={elements}
          workOrderId={props.task.id}
          onSubmit={() => setIsEditing(false)}
          groupType={IBlockGroupTypeEnum.Feedback}
        />
      ) : isChecklistEmpty ? (
        <EmptySection
          title={i18n.t("tasks:report.feedback.empty.title")}
          message={i18n.t("tasks:report.feedback.empty.message")}
          actionText={i18n.t("tasks:report.feedback.empty.actionText")}
          action={
            <Button
              type="secondary"
              color="gray"
              icon={Notebook}
              className=" print:hidden"
              onClick={() => setIsEditing(true)}>
              {i18n.t("tasks:report.feedback.empty.actionText")}
            </Button>
          }
        />
      ) : (
        <BlockGroup
          elements={elements}
          blockResponseUpdate={isTemplate || !isDone}
          scope={IPermissionScopeEnum.AppWorkOrderEdit}
          placeholder={i18n.t("common:metadata_empty")}
        />
      )}
    </Section>
  )
}
