import { HStack, Spacer, VStack } from "@components/layout"
import { FeaturePreview } from "@components/settings/feature-preview"
import { Button } from "@components/shared"
import { UserAvatar } from "@components/shared/avatar"
import { DragHandle } from "@components/shared/drag-handle"
import { scanCode } from "@components/shared/qrcode/scanner/scanner-dialog-provider"
import ScrollArea from "@components/shared/scroll-area"
import { SearchButton } from "@components/shared/search-button"
import { DEFAULT_FAVORITE_ICON_NAME, TeamIcon } from "@components/shared/team-icons"
import { Tooltip } from "@components/shared/tooltip"
import { allDefaultViews } from "@components/view/default-views"
import { useBreakpoint } from "@contexts/breakpoints"
import { FeatureGuard, useFeature } from "@contexts/feature-flag-context"
import { openModal } from "@contexts/modal-context"
import { useUser } from "@contexts/user-context"
import { orderBy } from "@elara/select"
import {
  DeleteUserFavoriteDocument,
  IDeleteUserFavoriteMutation,
  IDeleteUserFavoriteMutationVariables,
  IUserFavoriteFragment,
  UpdateUserFavoriteSortOrderDocument,
  UserFavoritesDocument,
  useUserFavoritesQuery,
} from "@graphql/documents/custom-view.generated"
import {
  useAllNotificationsQuery,
  useNotificationsSubscription,
} from "@graphql/documents/notification.generated"
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd"
import { useStickyState } from "@hooks"
import i18n from "@i18n"
import {
  AddressBook,
  Bell,
  CalendarCheck,
  CaretDoubleDown,
  CaretDoubleLeft,
  CaretDoubleUp,
  CaretDown,
  ChartLine,
  ChatText,
  Faders,
  Flask,
  Gauge,
  Lifebuoy,
  Nut,
  Rows,
  Shapes,
  Table,
  X,
} from "@phosphor-icons/react"
import * as Collapsible from "@radix-ui/react-collapsible"
import {
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
  Root as PopoverRoot,
} from "@radix-ui/react-popover"
import Icons from "@resources/icons"
import { cn } from "@utils"
import { parseQRCodeUrl } from "@utils/scanner"
import { calculateSortOrder } from "@utils/sorting"
import classNames from "classnames"
import React, { ReactElement, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useClient } from "urql"

import { SiteSwitcher } from "./navigation-menu/site-switcher"

type MenuItemProps = {
  as?: React.ElementType
  linkTo?: string
  isActive?: boolean
  collapseOnSelect?: boolean
  collapsed?: boolean
  onChangeCollapsed?: (collapsed: boolean) => void
  icon: React.ReactNode
  submenu?: { linkTo: string; label: string }[]
} & React.HTMLAttributes<HTMLElement>

const MenuItem = ({
  as: Component = "div",
  icon,
  children,
  linkTo,
  isActive,
  collapseOnSelect,
  collapsed,
  onChangeCollapsed,
  submenu,
  ...props
}: MenuItemProps) => {
  const bp = useBreakpoint()
  const { pathname } = useLocation()

  const isItemActive = isActive ?? (linkTo ? pathname.startsWith(linkTo) : false)

  const [open, setOpen] = useState<boolean>(isItemActive)

  const item = (
    <Component
      {...props}
      className={classNames(
        "group flex items-center px-2 py-1.5 my-0.5 text-sm text-left font-medium rounded-md",
        isItemActive
          ? "bg-gray-100 text-gray-900"
          : "text-gray-700 hover:bg-gray-50 hover:text-gray-900 group-radix-state-active:bg-gray-50 group-radix-state-open:text-gray-900",
        props.className
      )}>
      <span
        className={classNames(
          "flex-shrink-0",
          { "text-xl": collapsed, "mr-2 text-lg": !collapsed },
          {
            "text-gray-700": isItemActive,
            "text-gray-600 group-hover:text-gray-700": !isItemActive,
          }
        )}>
        {icon}
      </span>
      {!collapsed && children}
      {!!submenu && (
        <CaretDown
          className={classNames(
            "ml-auto text-gray-500 group/coll:rotate-0 group-[.nav-collapse-open]/coll:rotate-180",
            { "rotate-180": !!linkTo && pathname.startsWith(linkTo) }
          )}
        />
      )}
    </Component>
  )

  const tooltip = (e: ReactElement) => {
    if (collapsed) {
      return (
        <Tooltip
          content={children}
          contentProps={{ side: "top", align: "start", sideOffset: 4, alignOffset: 0 }}
          asChild={Component === "button"}>
          {e}
        </Tooltip>
      )
    } else {
      return e
    }
  }

  const link = (e: ReactElement) => {
    if (linkTo) {
      if (submenu && bp.mobile) return e

      return (
        <Link
          to={linkTo}
          className="w-full min-w-0"
          onClick={() => {
            if (collapseOnSelect && !submenu && !collapsed) {
              onChangeCollapsed?.(true)
            }
          }}>
          {e}
        </Link>
      )
    }

    return e
  }

  const SubMenu = ({ indent }: { indent?: boolean }) => (
    <div
      className={classNames(" space-y-0.5 pl-1", {
        "ml-3.5 border-l-2 border-gray-200": indent,
      })}>
      {submenu?.map((item) => (
        <Link
          to={item.linkTo}
          key={item.linkTo}
          onClick={() => {
            if (collapseOnSelect && !collapsed) {
              onChangeCollapsed?.(true)
            }
          }}
          className={classNames(
            "block rounded-md py-1.5 px-3 font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-800",
            { "bg-gray-100 text-gray-800": pathname.startsWith(item.linkTo) }
          )}>
          {item.label}
        </Link>
      ))}
    </div>
  )

  const popover = (e: ReactElement) => {
    if (collapsed && submenu) {
      return (
        <PopoverRoot modal key={pathname}>
          <PopoverTrigger className="group">{e}</PopoverTrigger>
          <PopoverPortal>
            <PopoverContent
              side="right"
              align="start"
              sideOffset={5}
              className="rounded-r-md border-y border-r bg-white py-1 pr-1 text-sm">
              <SubMenu />
            </PopoverContent>
          </PopoverPortal>
        </PopoverRoot>
      )
    } else {
      return tooltip(link(item))
    }
  }

  const collapse = (e: ReactElement) => {
    if (submenu) {
      return (
        <Collapsible.Root open={open} onOpenChange={setOpen}>
          <Collapsible.Trigger
            className={classNames("group/coll w-full", { "nav-collapse-open": open })}>
            {e}
          </Collapsible.Trigger>
          <Collapsible.Content>
            <SubMenu indent />
          </Collapsible.Content>
        </Collapsible.Root>
      )
    } else {
      return tooltip(link(item))
    }
  }

  if (submenu) {
    return (
      <>
        {!bp.sm && collapse(item)}
        {bp.sm && popover(item)}
        {!collapsed && !!linkTo && pathname.startsWith(linkTo) && <SubMenu indent />}
      </>
    )
  }

  return tooltip(link(item))
}

type FavoriteMenuItemData = {
  type: "default_view" | "custom_view" | "project" | "asset"
  id: string
  key: string
  name: string
  linkTo: string
  icon: React.ReactNode
}

const FavoritesSection = (props: { favorites: IUserFavoriteFragment[] }) => {
  const [isCollapsed, setIsCollapsed] = useStickyState(false, "favoriteToggle")
  const [showMoreViews, setShowMoreViews] = useStickyState<boolean>(false, "showMoreViews")

  const client = useClient()
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const favorites = orderBy(props.favorites, { sort_order: "asc" })

  return (
    <>
      {favorites.length > 0 && (
        <div>
          <div
            onClick={() => setIsCollapsed(!isCollapsed)}
            className="flex items-center rounded px-2 py-1 text-gray-500 hover:bg-gray-100">
            <span className="text-xs font-medium">
              {t("navigation:categories.favorites.title")}
            </span>
            <CaretDown
              size={12}
              className={classNames("ml-1", { "rotate-180": !isCollapsed })}
            />
          </div>
          {!isCollapsed && (
            <DragDropContext
              onDragEnd={async (r) => {
                const start = r.source.index
                const dest = r.destination?.index

                if (dest === undefined) return

                await client
                  .mutation(UpdateUserFavoriteSortOrderDocument, {
                    id: favorites[start].id,
                    sortOrder: calculateSortOrder(
                      start,
                      dest,
                      favorites.map((f) => f.sort_order)
                    ),
                  })
                  .toPromise()
              }}>
              <Droppable droppableId="favorites">
                {(droppableProvided) => (
                  <div
                    className="flex min-w-0 flex-col gap-0.5"
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}>
                    {favorites
                      .filter((_, index) => (showMoreViews ? true : index < 5))
                      .map((fav, index) => {
                        return (
                          <Draggable draggableId={fav.id} index={index} key={fav.id}>
                            {(provided) => {
                              const data: FavoriteMenuItemData = ((fav) => {
                                // Project
                                if (fav.project_id && fav.project) {
                                  return {
                                    type: "project",
                                    key: fav.project_id,
                                    id: fav.id,
                                    name: fav.project.name,
                                    linkTo: `/project/${fav.project_id}`,
                                    icon: fav.project.icon ? (
                                      <TeamIcon {...fav.project.icon} />
                                    ) : (
                                      <Icons.FolderOpen />
                                    ),
                                  }
                                }

                                // Asset
                                if (fav.asset_id && fav.asset) {
                                  return {
                                    type: "asset",
                                    key: fav.asset_id,
                                    id: fav.id,
                                    name: fav.asset.name,
                                    linkTo: `/object/${fav.asset_id}`,
                                    icon: <Shapes />,
                                  }
                                }

                                // Default View
                                if (fav.default_view_id) {
                                  const defaultView = allDefaultViews().find(
                                    (v) => v.id === fav.default_view_id
                                  )

                                  if (!defaultView) {
                                    throw new Error("Default view not found.")
                                  }

                                  return {
                                    type: "default_view",
                                    key: fav.default_view_id,
                                    id: fav.id,
                                    name: defaultView.name,
                                    linkTo: `/view/${fav.default_view_id}`,
                                    icon:
                                      defaultView.icon &&
                                      typeof defaultView.icon === "function" ? (
                                        defaultView.icon({})
                                      ) : (
                                        <TeamIcon name={DEFAULT_FAVORITE_ICON_NAME} />
                                      ),
                                  }
                                }

                                // Custom View
                                return {
                                  type: "custom_view",
                                  key: fav.custom_view_id!,
                                  id: fav.id,
                                  name: fav.custom_view!.name,
                                  linkTo: `/view/${fav.custom_view_id}`,
                                  icon: fav.custom_view!.icon ? (
                                    <TeamIcon {...fav.custom_view!.icon} />
                                  ) : (
                                    <TeamIcon name={DEFAULT_FAVORITE_ICON_NAME} />
                                  ),
                                }
                              })(fav)

                              const isItemActive = pathname.startsWith(data.linkTo)

                              return (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={provided.draggableProps.style}
                                  className="group relative flex min-w-0">
                                  <DragHandle
                                    className={classNames("absolute opacity-0 -left-5", {
                                      "group-hover:opacity-100": !isItemActive,
                                    })}
                                  />
                                  <div className="flex w-full shrink flex-row items-center justify-between">
                                    <MenuItem
                                      key={data.key}
                                      icon={data.icon}
                                      linkTo={data.linkTo}
                                      isActive={isItemActive}
                                      className={cn(
                                        "flex-1",
                                        !isItemActive && "hover:!bg-transparent"
                                      )}>
                                      <span className="truncate">{data.name}</span>
                                    </MenuItem>
                                    <button
                                      type="button"
                                      className="flex h-6 w-6 items-center justify-center text-gray-400 opacity-0 transition-opacity hover:text-gray-700 group-hover:opacity-100"
                                      onClick={async () => {
                                        await client
                                          .mutation<
                                            IDeleteUserFavoriteMutation,
                                            IDeleteUserFavoriteMutationVariables
                                          >(DeleteUserFavoriteDocument, { id: data.id })
                                          .toPromise()

                                        await client
                                          .query(UserFavoritesDocument, {})
                                          .toPromise()
                                      }}>
                                      <X size={12} />
                                    </button>
                                  </div>
                                </div>
                              )
                            }}
                          </Draggable>
                        )
                      })}

                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
          {!isCollapsed && favorites.length > 5 && (
            <div
              onClick={() => setShowMoreViews(!showMoreViews)}
              className="flex items-center rounded py-1.5 pl-3 text-gray-500 transition-colors hover:bg-gray-100">
              {showMoreViews ? <CaretDoubleUp size={12} /> : <CaretDoubleDown size={12} />}
              <span className="ml-3 text-xs font-medium">
                {showMoreViews
                  ? t("navigation:categories.favorites.show_less")
                  : t("navigation:categories.favorites.show_more")}
              </span>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export const NavigationMenu = (props: {
  collapseOnSelect?: boolean
  collapsed?: boolean
  onChangeCollapsed?: (collapsed: boolean) => void
}) => {
  useNotificationsSubscription()

  const user = useUser()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { key, pathname } = useLocation()
  const hasAIParser = useFeature("ai_parser")

  const goBack = () => navigate(-1)
  const goForward = () => navigate(1)

  const [notificationRes] = useAllNotificationsQuery({ requestPolicy: "cache-only" })
  const [favoritesQueryRes] = useUserFavoritesQuery({ requestPolicy: "cache-only" })
  const [toggledFeaturePreview, setToggledFeaturePreview] = useStickyState(
    false,
    "featurePreview:v3"
  )

  const notifications = notificationRes.data?.notification ?? []

  // Filter in case a view was shared and is no not more, it can still be in the favorites but custom_view will then be null
  const favoriteViews = (favoritesQueryRes.data?.user_favorite ?? []).filter(
    (v) => !!v.default_view_id || !!v.custom_view?.id || !!v.project?.id || !!v.asset?.id
  )

  const unreadNotificationCount = useMemo(
    () =>
      notifications?.filter((notification) => notification.read_at === null).length ?? 0,
    [notifications]
  )

  const handleScan = async () => {
    // Returns a string if a QR code was scanned, otherwise null
    const result = await scanCode()

    if (!result) return

    const pathname = parseQRCodeUrl(result)
    pathname && navigate(pathname)
  }

  return (
    <div className="z-0 flex min-h-0 flex-1 flex-col">
      <ScrollArea className="flex flex-1 flex-col" type="hover" vertical viewportAsChild>
        <div>
          <div className="hidden items-center sm:flex">
            <button
              onClick={() => props.onChangeCollapsed?.(!props.collapsed)}
              className="m-1.5 flex h-8 w-8 items-center justify-center rounded text-gray-600 hover:bg-gray-100 hover:text-gray-700">
              <CaretDoubleLeft
                className={classNames("transition-transform", {
                  "-scale-x-100": props.collapsed,
                })}
              />
            </button>

            {!props.collapsed && (
              <>
                <div className="flex-1" />
                <div className="flex items-center justify-end pr-1.5">
                  <button
                    type="button"
                    onClick={goBack}
                    disabled={key === "default"}
                    className="group relative flex h-8 w-8 items-center justify-center rounded hover:bg-gray-100">
                    <Icons.LeftArrow
                      width={20}
                      height={20}
                      className={classNames(
                        "cursor-pointer text-gray-500 group-hover:bg-gray-100",
                        {
                          "cursor-default group-hover:!bg-transparent text-gray-300":
                            key === "default",
                        }
                      )}
                    />
                  </button>
                  <button
                    type="button"
                    onClick={goForward}
                    className="group relative flex h-8 w-8 items-center justify-center rounded hover:bg-gray-100">
                    <Icons.RightArrow
                      width={20}
                      height={20}
                      onClick={goForward}
                      className={classNames(
                        "cursor-pointer group-hover:bg-gray-100 rounded text-gray-500"
                      )}
                    />
                  </button>
                </div>
              </>
            )}
          </div>

          {props.collapsed ? null : <SiteSwitcher />}

          <div
            className={classNames(
              "h-0.5 my-1 bg-blue-700",
              props.collapsed ? "w-1/3" : "w-1/6"
            )}
          />
        </div>

        <div
          className={classNames(
            "flex min-w-0 flex-1 flex-col space-y-3 py-2 text-sm",
            props.collapsed ? "px-1" : "px-3"
          )}>
          <div className="flex min-h-0 min-w-0 max-w-full flex-col">
            <div className="mb-4 flex w-full items-center space-x-2">
              <SearchButton collapsed={props.collapsed} className="flex-1" />
              <Button type="secondary" icon={Icons.Scan} onClick={handleScan} />
            </div>

            <MenuItem
              collapsed={props.collapsed}
              collapseOnSelect={props.collapseOnSelect}
              onChangeCollapsed={props.onChangeCollapsed}
              linkTo="/notifications"
              icon={
                <div className="relative inset-0">
                  {props.collapsed && unreadNotificationCount > 0 && (
                    <div
                      className="absolute shrink-0 rounded-full bg-blue-600 px-1 py-0.5 text-[0.5rem] leading-[0.75rem] text-white"
                      style={{ top: "-6px", right: "-8px" }}>
                      {unreadNotificationCount}
                    </div>
                  )}

                  <Bell />
                </div>
              }
              isActive={pathname.startsWith("/notifications")}>
              <span className="mr-1">{t("navigation:notifications")}</span>
              {unreadNotificationCount > 0 && (
                <span className="ml-auto rounded-full bg-blue-600 px-2.5 py-0.5 text-xs text-white">
                  {unreadNotificationCount}
                </span>
              )}
            </MenuItem>

            <FeatureGuard id="tasks">
              <MenuItem
                icon={<Rows />}
                linkTo={"/task"}
                isActive={false}
                collapsed={props.collapsed}
                collapseOnSelect={props.collapseOnSelect}
                onChangeCollapsed={props.onChangeCollapsed}
                submenu={[
                  { label: i18n.t("common:overview"), linkTo: "/task/overview" },
                  { label: i18n.t("common:view", { count: 2 }), linkTo: "/task/views" },
                  {
                    label: i18n.t("tasks:assigned_page.assigned_to_me"),
                    linkTo: "/task/assigned",
                  },
                  {
                    label: i18n.t("common:template", { count: 2 }),
                    linkTo: "/task/template",
                  },
                ]}>
                {i18n.t("common:task", { count: 2 })}
              </MenuItem>
            </FeatureGuard>

            <FeatureGuard id="service_requests">
              <MenuItem
                isActive={false}
                icon={<Lifebuoy />}
                linkTo="/service-request"
                collapsed={props.collapsed}
                collapseOnSelect={props.collapseOnSelect}
                onChangeCollapsed={props.onChangeCollapsed}>
                {i18n.t("common:service_request", { count: 2 })}
              </MenuItem>
            </FeatureGuard>

            <FeatureGuard id="maintenance">
              <MenuItem
                isActive={false}
                linkTo="/maintenance"
                collapsed={props.collapsed}
                collapseOnSelect={props.collapseOnSelect}
                onChangeCollapsed={props.onChangeCollapsed}
                icon={<CalendarCheck />}
                submenu={[
                  {
                    label: i18n.t("common:view", { count: 2 }),
                    linkTo: "/maintenance/views",
                  },
                  {
                    label: i18n.t("maintenance:tabs.archive"),
                    linkTo: "/maintenance/archive",
                  },
                  ...(hasAIParser
                    ? [{ label: "AI Parser", linkTo: "/maintenance/ai-parser" }]
                    : []),
                ]}>
                {i18n.t("maintenance:title")}
              </MenuItem>
            </FeatureGuard>

            <FeatureGuard id="advanced_analytics">
              <MenuItem
                collapsed={props.collapsed}
                collapseOnSelect={props.collapseOnSelect}
                onChangeCollapsed={props.onChangeCollapsed}
                linkTo="/analytics"
                icon={<ChartLine />}>
                {i18n.t("navigation:categories.overview.analytics")}
              </MenuItem>
            </FeatureGuard>

            <FeatureGuard id="objects">
              <MenuItem
                collapseOnSelect={props.collapseOnSelect}
                collapsed={props.collapsed}
                onChangeCollapsed={props.onChangeCollapsed}
                icon={<Shapes />}
                isActive={false}
                linkTo={"/object"}
                submenu={[
                  { label: i18n.t("common:view", { count: 2 }), linkTo: "/object/views" },
                  { label: i18n.t("assets:tabs.activity"), linkTo: "/object/activity" },
                  { label: i18n.t("assets:tabs.archive"), linkTo: "/object/archive" },
                ]}>
                {i18n.t("common:asset", { count: 2 })}
              </MenuItem>
            </FeatureGuard>

            <FeatureGuard id="materials">
              <MenuItem
                collapseOnSelect={props.collapseOnSelect}
                collapsed={props.collapsed}
                onChangeCollapsed={props.onChangeCollapsed}
                icon={<Nut />}
                linkTo={"/consumable"}>
                {i18n.t("common:consumable", { count: 2 })}
              </MenuItem>
            </FeatureGuard>

            <FeatureGuard id="meters">
              <MenuItem
                collapseOnSelect={props.collapseOnSelect}
                collapsed={props.collapsed}
                onChangeCollapsed={props.onChangeCollapsed}
                icon={<Gauge />}
                linkTo={"/meter"}>
                {i18n.t("common:meter", { count: 2 })}
              </MenuItem>
            </FeatureGuard>

            <FeatureGuard id="contacts">
              <MenuItem
                collapseOnSelect={props.collapseOnSelect}
                collapsed={props.collapsed}
                onChangeCollapsed={props.onChangeCollapsed}
                linkTo="/contact"
                icon={<AddressBook />}>
                {t("common:contact", { count: 2 })}
              </MenuItem>
            </FeatureGuard>

            <FeatureGuard id="reporting">
              <MenuItem
                collapseOnSelect={props.collapseOnSelect}
                collapsed={props.collapsed}
                onChangeCollapsed={props.onChangeCollapsed}
                linkTo="/reports"
                icon={<Table />}>
                Reports
              </MenuItem>
            </FeatureGuard>

            <div className="my-1" />
            {!props.collapsed && <FavoritesSection favorites={favoriteViews} />}
          </div>

          <Spacer className="bg-white" />
          <hr className="m-2 -mt-px text-gray-100" />

          <VStack space={2}>
            <MenuItem
              as="button"
              collapseOnSelect={props.collapseOnSelect}
              collapsed={props.collapsed}
              onChangeCollapsed={props.onChangeCollapsed}
              icon={<ChatText />}
              onClick={() => openModal("feedback")}>
              {t("feedback:dialog.title")}
            </MenuItem>

            {(window.location.origin.includes("localhost") ||
              window.location.origin.includes("elaratest.de")) && (
              <MenuItem
                collapseOnSelect={props.collapseOnSelect}
                collapsed={props.collapsed}
                onChangeCollapsed={props.onChangeCollapsed}
                linkTo="/feature-flags"
                icon={<Flask />}>
                Feature Flags
              </MenuItem>
            )}

            <FeaturePreview>
              <button onClick={() => setToggledFeaturePreview(true)}>
                <MenuItem
                  icon={<Flask />}
                  collapseOnSelect={props.collapseOnSelect}
                  collapsed={props.collapsed}
                  onChangeCollapsed={props.onChangeCollapsed}>
                  {t("common:feature_preview.title")}
                  {!toggledFeaturePreview && (
                    <span className="ml-2 rounded-full border-2 border-blue-700 px-2 py-1 text-xs font-medium text-blue-700 ">
                      {i18n.t("common:new")}
                    </span>
                  )}
                </MenuItem>
              </button>
            </FeaturePreview>

            <MenuItem
              collapseOnSelect={props.collapseOnSelect}
              collapsed={props.collapsed}
              onChangeCollapsed={props.onChangeCollapsed}
              linkTo="/settings"
              icon={<Faders />}>
              {t("navigation:settings")}
            </MenuItem>
          </VStack>
        </div>

        <Link to="/settings/profile">
          <HStack align="center" space={8} className="p-3">
            <UserAvatar user={user.profile} size={24} />
            {!props.collapsed && (
              <VStack space={0}>
                <span className="text-sm font-semibold text-gray-600">
                  {user.profile?.first_name} {user.profile?.last_name}
                </span>
                <span className="truncate text-xs text-gray-500">
                  {user.permissionRole?.name}
                </span>
              </VStack>
            )}
          </HStack>
        </Link>
      </ScrollArea>
    </div>
  )
}

export default NavigationMenu
