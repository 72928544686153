import { Flex, VStack } from "@components/layout"
import { Button } from "@components/shared"
import { regularToast } from "@components/shared/toast"
import i18n from "@i18n"
import Icons from "@resources/icons"
import { useRegisterSW } from "@utils/register-sw"
import { useEffect, useState } from "react"

// Check for update every 10 minutes
const checkforUpdateIntervalMS = 10 * 60 * 1000

// Show update prompt every 5 minutes until user updates
const showUpdateAfterDismissTimeoutMS = 5 * 60 * 1000

const UpdateMessage = (props: { onUpdate: () => void }) => (
  <VStack space={8}>
    <div>{i18n.t("common:dialogs.update_version.message")}</div>
    <Flex row justify="flex-start">
      <Button type="secondary" onClick={props.onUpdate} size="small">
        {i18n.t("common:dialogs.update_version.actions.update")}
      </Button>
    </Flex>
  </VStack>
)

export function useReloadPrompt() {
  const [isDismissed, setIsDismissed] = useState<boolean>(false)
  const [serviceWorkerRegistration, setServiceWorkerRegistration] =
    useState<ServiceWorkerRegistration | null>(null)

  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(r) {
      if (r) {
        setServiceWorkerRegistration(r)
      }
    },
    onNeedRefresh() {},
    onRegisterError(e) {
      console.warn(e)
    },
  })

  useEffect(() => {
    const r = serviceWorkerRegistration

    const update = () => {
      // Catch updates errors to avoid sentry reports
      r?.update().catch((e) => {
        console.warn(e)
      })
    }

    const intervalId = setInterval(update, checkforUpdateIntervalMS)

    const onVisibillityChange = () => {
      const state = document.visibilityState
      if (state === "visible") {
        update()
      }
    }

    document.addEventListener("visibilitychange", onVisibillityChange)

    return () => {
      clearInterval(intervalId)
      document.removeEventListener("visibilitychange", onVisibillityChange)
    }
  }, [serviceWorkerRegistration])

  useEffect(() => {
    if (isDismissed) {
      const timeoutId = setTimeout(() => {
        setIsDismissed(false)
      }, showUpdateAfterDismissTimeoutMS)

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [isDismissed])

  useEffect(() => {
    if (needRefresh && !isDismissed) {
      regularToast({
        params: {
          duration: checkforUpdateIntervalMS,
          position: "bottom-right",
          style: {
            marginBottom: "env(safe-area-inset-bottom,0px)",
          },
        },
        icon: <Icons.Refresh className="h-5 w-5 text-grey-2.5" height={24} width={24} />,
        title: i18n.t("common:dialogs.update_version.title"),
        onDismiss: () => setIsDismissed(true),
        body: <UpdateMessage onUpdate={() => updateServiceWorker(true)} />,
      })
    }
  }, [needRefresh, isDismissed])
}
