import { useAnalytics } from "@contexts/analytics-context"
import { IPermissionScopeEnum, uuid } from "@elara/db"
import {
  EditCommentDocument,
  IEditCommentMutation,
  IEditCommentMutationVariables,
  ISendCommmentMutation,
  ISendCommmentMutationVariables,
  SendCommmentDocument,
} from "@graphql/documents/comment.generated"
import { ICommentFragment } from "@graphql/documents/fragments.generated"
import { IUploadDataFragment } from "@graphql/documents/upload.generated"
import { useUpsertCollaboratorsToWorkOrderMutation } from "@graphql/documents/work-order.generated"
import { useCallbackRef, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { CSSProperties, useEffect, useMemo, useRef, useState } from "react"
import { useClient } from "urql"

import Button from "./button"
import RichText, { RichTextHandle } from "./rich-text"
import toast from "./toast"
import Uploads from "./uploads"

type CommentFieldProps = {
  className?: string
  comment?: ICommentFragment | null
  workOrderId: uuid
  onClearCommentField?: () => void
  style?: CSSProperties
}

const useCommentField = (props: CommentFieldProps) => {
  const { posthog } = useAnalytics()
  const client = useClient()
  const editorRef = useRef<RichTextHandle>(null)
  const [isEmpty, setIsEmpty] = useState(true)
  const [attachments, setAttachments] = useState<IUploadDataFragment[]>([])

  const scope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)

  const [, upsertCollaboratorsToWorkOrder] = useUpsertCollaboratorsToWorkOrderMutation()

  const clearCommentField = () => {
    props.onClearCommentField?.()
    setAttachments([])
    editorRef.current?.clearContent()
    setIsEmpty(true)
  }

  const setCommentFieldValues = (content: string, uploads: IUploadDataFragment[]) => {
    setAttachments(uploads)
    editorRef.current?.setContent(content)
  }

  const [isSending, setIsSending] = useState(false)

  const sendComment = async () => {
    if (!editorRef.current) return
    const { html, mentions } = editorRef?.current?.getContent()

    let success = false

    setIsSending(true)

    if (props.comment?.id) {
      const res = await client
        .mutation<IEditCommentMutation, IEditCommentMutationVariables>(
          EditCommentDocument,
          {
            id: props.comment.id,
            content: html,
            user_mention_ids: mentions.map((m) => m.id),
            work_order_id: props.workOrderId,
            attachment_ids: attachments.map((a) => a.id),
            attachments: attachments.map((a) => ({ upload_id: a.id })),
          }
        )
        .toPromise()
      posthog?.capture("edit_work_order", { is_comment: "yes" })
      if (res.error) {
        toast.error(
          i18n.t("common:messages.token_update_failure", {
            token: i18n.t("tasks:fields.comment", { count: 1 }),
          })
        )
      } else {
        success = true
      }
    } else {
      const res = await client
        .mutation<ISendCommmentMutation, ISendCommmentMutationVariables>(
          SendCommmentDocument,
          {
            content: html,
            user_mention_ids: mentions.map((m) => m.id),
            work_order_id: props.workOrderId,
            attachments: attachments.map((a) => ({ upload_id: a.id })),
          }
        )
        .toPromise()
      posthog?.capture("edit_work_order", { is_comment: "yes" })
      if (res.error) {
        toast.error(
          i18n.t("common:messages.token_create_failure", {
            token: i18n.t("tasks:fields.comment", { count: 1 }),
          })
        )
      } else {
        success = true
      }
    }

    if (success) {
      await upsertCollaboratorsToWorkOrder(
        {
          objects: mentions.map((m) => ({
            user_id: m.id,
            work_order_id: props.workOrderId,
          })),
        },
        scope.context()
      )
    }

    setIsSending(false)

    if (success) {
      clearCommentField()
    }
  }

  const onUploadFinished = (upload: IUploadDataFragment) =>
    setAttachments((uploads) => uploads.concat([upload]))

  const onDelete = (uploadId: uuid) =>
    setAttachments((uploads) => uploads.filter((u) => u.id !== uploadId))

  return {
    sendComment,
    onUploadFinished,
    onDelete,
    isEmpty,
    setIsEmpty,
    uploads: attachments,
    editorRef,
    isSendingComment: isSending,
    setCommentFieldValues,
    clearCommentField,
  }
}

export const CommentField = (props: CommentFieldProps) => {
  const scope = usePermissionScope(IPermissionScopeEnum.AppUser)

  const {
    clearCommentField,
    editorRef,
    uploads,
    onUploadFinished,
    onDelete,
    isEmpty,
    setIsEmpty,
    sendComment,
    setCommentFieldValues,
    isSendingComment,
  } = useCommentField(props)

  useEffect(() => {
    if (props.comment) {
      setCommentFieldValues(
        props.comment.content,
        props.comment.attachments.map((a) => a.upload)
      )
    } else {
      clearCommentField()
    }
  }, [props.comment?.id])

  const [isUploading, setIsUploading] = useState(false)

  const sendCommentMemo = useCallbackRef(sendComment)

  let isDisabled = useMemo(() => {
    if (isSendingComment) return true
    if (isUploading || isEmpty) return !uploads.length

    return false
  }, [isUploading, isSendingComment, isEmpty, uploads])

  return (
    <div>
      <div className="mb-3 grow-0 select-text rounded border">
        <RichText
          showToolbar
          ref={editorRef}
          onUpdate={({ editor }) => {
            setIsEmpty(editor.isEmpty)
          }}
          editorProps={{
            attributes: {
              class: "focus-visible:outline-none",
            },
          }}
          contentClassName="!p-3"
          content={props.comment?.content}
          onSubmit={sendCommentMemo}
          placeholder={i18n.t("common:add_token", {
            token: i18n.t("tasks:fields.comment", { count: 1 }),
          })}
          className="max-h-56 hover:cursor-text md:text-sm"
        />
      </div>
      <Uploads
        allowQuickDeleteOfPictures
        scope={scope}
        onDelete={onDelete}
        onUploadsActivityChange={setIsUploading}
        onUploadFinished={onUploadFinished}
        uploads={uploads}>
        <div className="mt-2 flex w-full justify-end">
          {props.comment && (
            <Button
              type="tertiary"
              onClick={clearCommentField}
              size="small"
              className="mb-2 ml-3">
              {i18n.t("common:cancel")}
            </Button>
          )}
          <Button
            type="primary"
            className="mb-2 ml-3"
            onClick={sendCommentMemo}
            isLoading={isSendingComment}
            disabled={isDisabled}
            size="small">
            {props.comment ? i18n.t("common:edit") : i18n.t("common:send")}
          </Button>
        </div>
      </Uploads>
    </div>
  )
}
