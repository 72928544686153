import analytics from "./analytics.json"
import assets from "./assets.json"
import calendar from "./calendar.json"
import command from "./command.json"
import common from "./common.json"
import consumables from "./consumables.json"
import contacts from "./contacts.json"
import dataView from "./data-view.json"
import feedback from "./feedback.json"
import maintenance from "./maintenance.json"
import meters from "./meters.json"
import navigation from "./navigation.json"
import notifications from "./notifications.json"
import projects from "./projects.json"
import qrcode from "./qrcode.json"
import register from "./register.json"
import reporting from "./reporting.json"
import search from "./search.json"
import serviceRequest from "./service-request.json"
import settings from "./settings.json"
import setup from "./setup.json"
import tasks from "./tasks.json"
import teams from "./teams.json"
import templates from "./templates.json"
import views from "./views.json"

export default {
  "data-view": dataView,
  analytics,
  assets,
  calendar,
  command,
  common,
  consumables,
  contacts,
  feedback,
  maintenance,
  meters,
  navigation,
  notifications,
  projects,
  qrcode,
  register,
  reporting,
  search,
  "service-request": serviceRequest,
  settings,
  setup,
  tasks,
  teams,
  templates,
  views,
}
