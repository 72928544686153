import {
  IAsset,
  IAssetXUpload,
  IAssetXWorkOrder,
  ILocationMember,
  ILocationXFeatureFlag,
  IMutationRootDeleteAssetGroupByPkArgs,
  IMutationRootDeleteAssetXUploadByPkArgs,
  IMutationRootDeleteFileFolderByPkArgs,
  IMutationRootDeleteUploadByPkArgs,
  IMutationRootDeleteUploadXUploadCategoryByPkArgs,
  IMutationRootInsertAssetGroupOneArgs,
  IMutationRootInsertAssetManufacturerOneArgs,
  IMutationRootInsertAssetOneArgs,
  IMutationRootInsertAssetXUploadOneArgs,
  IMutationRootInsertCommentOneArgs,
  IMutationRootInsertFileFolderOneArgs,
  IMutationRootInsertFileFolderXUploadOneArgs,
  IMutationRootInsertUploadXUploadCategoryOneArgs,
  IMutationRootInsertWorkOrderXUploadOneArgs,
  IMutationRootUpdateAssetByPkArgs,
  IMutationRootUpdateBlockElementByPkArgs,
  IMutationRootUpdateCommentByPkArgs,
  IMutationRootUpdateFileFolderByPkArgs,
  IMutationRootUpdateTeamByPkArgs,
  IMutationRootUpdateTeamMemberByPkArgs,
  IMutationRootUpdateUploadByPkArgs,
  IPermissionRoleXFeatureFlag,
  ITeamMember,
  IUploadXUploadCategory,
  IWorkOrderCompletedByCategoryView,
  IWorkOrderCompletedOntimeVsOverdue,
  IWorkOrderFeedback,
  IWorkOrderOpenGroupedByDueDateView,
  IWorkOrderXCollaborator,
  IWorkOrderXWorkOrderCategory,
  uuid,
} from "@elara/db"
import {
  AssetGroupsDocument,
  IAssetGroupsQuery,
  IAssetGroupsQueryVariables,
} from "@graphql/documents/asset-group.generated"
import {
  AssetManufacturersDocument,
  IAssetManufacturersQuery,
} from "@graphql/documents/asset-manufacturer.generated"
import {
  GetCommentsDocument,
  IGetCommentsQuery,
  IGetCommentsQueryVariables,
} from "@graphql/documents/comment.generated"
import {
  FileFolderFragmentDoc,
  IFileFolderFragment,
  IFileFolderUploadFragment,
} from "@graphql/documents/file-folder.generated"
import {
  IAssetGroupFragment,
  IAssetManufacturerFragment,
  ICommentFragment,
  IConsumableConfigFragment,
  IConsumableGroupFragment,
  IUserRecentlyUsedFragment,
  TeamFragmentDoc,
  TeamMemberFragmentDoc,
} from "@graphql/documents/fragments.generated"
import {
  AllNotificationsDocument,
  IAllNotificationsQuery,
  IAllNotificationsQueryVariables,
  INotificationsSubscriptionResultFragment,
} from "@graphql/documents/notification.generated"
import {
  IQuickCategoryUploadChangeResultFragment,
  QuickCategoryUploadChangeResultFragmentDoc,
} from "@graphql/documents/upload.generated"
import { cacheExchange, ResolverConfig } from "@urql/exchange-graphcache"
import { IntrospectionQuery } from "graphql"

import {
  AssetAggregateInfoDocument,
  AssetDataViewDocument,
  AssetDetailsDocument,
  AssetPicturesDocument,
  AssetSelectDataDocument,
  AssetTableDataDocument,
  AssetTagsDocument,
  IAddNewDocumentToAssetMutation,
  IAssetAggregateInfoQuery,
  IAssetAggregateInfoQueryVariables,
  IAssetDataViewQuery,
  IAssetDataViewQueryVariables,
  IAssetDetailsQuery,
  IAssetDetailsQueryVariables,
  IAssetPicturesQuery,
  IAssetPicturesQueryVariables,
  IAssetSelectDataQuery,
  IAssetSelectDataQueryVariables,
  IAssetTableDataQuery,
  IAssetTableDataQueryVariables,
  IAssetTagsQuery,
  IAssetTagsQueryVariables,
} from "../documents/asset.generated"
import {
  AssetDetailFragmentDoc,
  IAssetDetailFragment,
} from "../documents/fragments.generated"
import {
  IAddNewDocumentToWorkOrderMutation,
  IWorkOrderDetailsQuery,
  IWorkOrderDetailsQueryVariables,
  WorkOrderDetailsDocument,
} from "../documents/work-order.generated"
import schema from "../schema.generated.json"
import Asset from "./graphcache-asset"
import AssetMeter from "./graphcache-asset-meter"
import AssetPlace from "./graphcache-asset-place"
import Consumable from "./graphcache-consumable"
import Contact from "./graphcache-contact"
import CustomView from "./graphcache-custom-view"
import Maintenance from "./graphcache-maintenance"
import Notifications from "./graphcache-notifications"
import Permissions from "./graphcache-permissions"
import Project from "./graphcache-project"
import ServiceRequest from "./graphcache-service-request"
import Setting from "./graphcache-setting"
import Team from "./graphcache-team"
import WorkOrder from "./graphcache-work-order"
import WorkOrderTemplates from "./graphcache-work-order-templates"

export const graphcache = () =>
  cacheExchange({
    // Need typecasting here. See also https://github.com/FormidableLabs/urql/discussions/1389
    // prettier-ignore
    schema: (schema as unknown) as IntrospectionQuery,
    keys: {
      asset_current_state: (d: any) => d?.asset_id ?? d?.id ?? null,
      asset_aggregate: () => null,
      asset_aggregate_fields: () => null,
      asset_x_consumable: () => null,
      asset_x_upload: (data_) => {
        const data = data_ as unknown as IAssetXUpload
        if (data.asset_id && data.upload_id) {
          return `${data.asset_id}_X_${data.upload_id}`
        }
        return null
      },
      asset_x_upload_aggregate: () => null,
      asset_x_upload_aggregate_fields: () => null,
      asset_x_work_order: (data_) => {
        const data = data_ as unknown as IAssetXWorkOrder
        if (data.asset_id && data.work_order_id) {
          return `${data.asset_id}_X_${data.work_order_id}`
        }
        return null
      },
      asset_last_state_view: () => null,
      asset_x_asset_state_duration: () => null,
      asset_x_work_order_aggregate: () => null,
      asset_x_work_order_aggregate_fields: () => null,
      asset_state: () => null,
      asset_x_asset_state: () => null,
      block_element_x_upload: () => null,
      consumable_storage_location: () => null,
      consumable_config: (data: IConsumableConfigFragment) => data?.location_id ?? "-",
      consumable_x_upload: () => null,
      consumable_aggregate: () => null,
      consumable_aggregate_fields: () => null,
      consumable_group: (data: IConsumableGroupFragment) => data?.id,
      comment_x_upload: () => null,
      comment_aggregate: () => null,
      comment_aggregate_fields: () => null,
      file_folder_aggregate: () => null,
      file_folder_aggregate_fields: () => null,
      file_folder_x_upload: () => null,
      user_recently_used: (data: IUserRecentlyUsedFragment) =>
        `${data.entity_id} ${data.entity_type} ${data.user_id}`,
      file_folder_x_upload_aggregate: () => null,
      file_folder_x_upload_aggregate_fields: () => null,
      location_member: (data) => (data as unknown as ILocationMember).user_id ?? null,
      permission_scope: (data) => (data as unknown as { scope?: string }).scope ?? null,
      permission_role_x_permission_scope: (data_) => {
        const data = data_ as unknown as { scope?: string; role_id?: string }
        if (data.role_id && data.scope) {
          return `${data.role_id}_X_${data.scope}`
        }
        return null
      },
      project_x_team: () => {
        return null
      },
      service_request_x_user: () => null,
      service_request_x_team: () => null,
      service_request_x_asset: () => null,
      service_request_x_upload: () => null,
      team_member: (data_) => {
        const data = data_ as unknown as ITeamMember
        if (data.user_id && data.team_id) {
          return `${data.user_id}_X_${data.team_id}`
        }
        return null
      },
      work_order_aggregate: () => null,
      work_order_aggregate_fields: () => null,
      work_order_feedback: (d) => (d as unknown as IWorkOrderFeedback).work_order_id,
      work_order_x_upload_aggregate: () => null,
      work_order_x_upload_aggregate_fields: () => null,
      work_order_x_upload: () => null,
      work_order_x_user: () => null,
      work_order_x_team: () => null,
      work_order_x_work_order_category: (data_) => {
        const data = data_ as unknown as IWorkOrderXWorkOrderCategory
        if (data.work_order_id && data.work_order_category_id) {
          return `${data.work_order_id}_X_${data.work_order_category_id}`
        }
        return null
      },
      work_order_x_collaborator: (data_) => {
        const data = data_ as unknown as IWorkOrderXCollaborator
        if (data.work_order_id && data.user_id) {
          return `${data.work_order_id}_X_${data.user_id}`
        }
        return null
      },
      work_order_status: (data) => (data as { value?: string }).value ?? null,
      work_order_completed_by_category_view: (data_) => {
        const data = data_ as unknown as IWorkOrderCompletedByCategoryView
        return data.completed_at || null
      },
      work_order_completed_ontime_vs_overdue: (data_) => {
        const data = data_ as unknown as IWorkOrderCompletedOntimeVsOverdue
        return data.completed_at || null
      },
      work_order_open_grouped_by_due_date_view: (data_) => {
        const data = data_ as unknown as IWorkOrderOpenGroupedByDueDateView
        return data.due_date || null
      },
      setting_config_aggregate: () => null,
      setting_config_aggregate_fields: () => null,
      location_x_feature_flag: (data_) => {
        const data = data_ as unknown as ILocationXFeatureFlag
        if (data.location_id && data.feature_flag_id)
          return `${data.location_id}_X_${data.feature_flag_id}`
        return null
      },
      permission_role_x_feature_flag: (data_) => {
        const data = data_ as unknown as IPermissionRoleXFeatureFlag
        if (data.permission_role_id && data.feature_flag)
          return `${data.permission_role_id}_X_${data.feature_flag}`
        return null
      },
      maintenance_aggregate: () => null,
      upload_x_upload_category: (data_) => {
        const data = data_ as unknown as IUploadXUploadCategory
        if (data.upload_id && data.upload_category_id) {
          return `${data.upload_id}_X_${data.upload_category_id}`
        }
        return null
      },
    },
    optimistic: {
      update_block_element_by_pk(variables: IMutationRootUpdateBlockElementByPkArgs) {
        return {
          __typename: "block_element",
          id: variables.pk_columns?.id,
          ...variables._set,
        }
      },
      update_team_by_pk(variables: IMutationRootUpdateTeamByPkArgs, cache) {
        return {
          __typename: "team",
          ...cache.readFragment(TeamFragmentDoc, {
            id: variables.pk_columns?.id!,
          }),
          ...variables._set,
        }
      },
      update_team_member_by_pk(variables: IMutationRootUpdateTeamMemberByPkArgs, cache) {
        return {
          __typename: "team_member",
          ...cache.readFragment(TeamMemberFragmentDoc, {
            ...variables.pk_columns,
          }),
          ...variables._set,
        }
      },
      update_upload_by_pk(variables: IMutationRootUpdateUploadByPkArgs) {
        return {
          __typename: "upload",
          id: variables.pk_columns?.id,
          ...variables._set,
        }
      },
      update_file_folder_by_pk(variables: IMutationRootUpdateFileFolderByPkArgs) {
        return {
          __typename: "file_folder",
          id: variables.pk_columns?.id,
          ...variables._set,
        }
      },
      // Categories
      insert_upload_x_upload_category_one(
        variables: IMutationRootInsertUploadXUploadCategoryOneArgs,
        cache
      ) {
        const upload = cache.readFragment(QuickCategoryUploadChangeResultFragmentDoc, {
          id: variables.object.upload_id,
        }) as IQuickCategoryUploadChangeResultFragment | null

        upload?.category?.push({
          __typename: "upload_x_upload_category",
          upload_category_id: variables.object.upload_category_id!,
          category: {
            __typename: "upload_category",
            id: variables.object.upload_category_id!,
          },
        })

        return {
          __typename: "upload_x_upload_category",
          upload,
        }
      },
      delete_upload_x_upload_category_by_pk(
        variables: IMutationRootDeleteUploadXUploadCategoryByPkArgs,
        cache: any
      ) {
        const upload = cache.readFragment(QuickCategoryUploadChangeResultFragmentDoc, {
          id: variables.upload_id,
        }) as IQuickCategoryUploadChangeResultFragment | null

        return {
          __typename: "upload_x_upload_category",
          upload: {
            ...upload,
            categories:
              upload?.category?.filter(
                (c) => c?.category?.id ?? "" !== variables.upload_category_id
              ) ?? [],
          },
        }
      },

      ...CustomView.optimistic,
      ...Team.optimistic,
      ...WorkOrder.optimistic,
      ...WorkOrderTemplates.optimistic,
      ...Notifications.optimistic,
      ...Project.optimistic,
      ...Contact.optimistic,
      ...Consumable.optimistic,
      ...Maintenance.optimistic,
      ...ServiceRequest.optimistic,
    },
    resolvers: {
      query_root: {
        work_order_by_pk: (_data, args) => ({ __typename: "work_order", id: args.id }),
        meter_by_pk: (_data, args) => ({ __typename: "meter", id: args.id }),
        team_by_pk: (_, args) => ({ __typename: "team", id: args.id }),
        asset_by_pk: (_, args) => ({ __typename: "asset", id: args.id }),
        consumable_by_pk: (_, args) => ({ __typename: "consumable", id: args.id }),
        user_by_pk: (_, args) => ({ __typename: "user", id: args.id }),
        maintenance_by_pk: (_, args) => ({ __typename: "maintenance", id: args.id }),
        maintenance_trigger_by_pk: (_, args) => ({
          __typename: "maintenance_trigger",
          id: args.id,
        }),
      },
    } as ResolverConfig,
    updates: {
      mutation_root: {
        delete_upload_x_upload_category_by_pk(
          _result,
          args: IMutationRootDeleteUploadXUploadCategoryByPkArgs,
          cache
        ) {
          cache.invalidate({
            __typename: "upload_x_upload_category",
            upload_id: args.upload_id,
            upload_category_id: args.upload_category_id,
          })
        },
        delete_upload_by_pk(_result, args: IMutationRootDeleteUploadByPkArgs, cache) {
          cache.invalidate({
            __typename: "upload",
            id: args.id,
          })
        },
        delete_asset_group_by_pk(
          _result,
          args: IMutationRootDeleteAssetGroupByPkArgs,
          cache
        ) {
          cache.invalidate({
            __typename: "asset_group",
            id: args.id,
          })
        },
        insert_asset_one(
          result: { insert_asset_one: IAssetDetailFragment },
          args: IMutationRootInsertAssetOneArgs,
          cache
        ) {
          const asset = result.insert_asset_one
          if (!asset) return

          cache.updateQuery<IAssetSelectDataQuery, IAssetSelectDataQueryVariables>(
            {
              query: AssetSelectDataDocument,
            },
            (data) => {
              if (!data || !data.asset) return data

              if (!data.asset.some((a) => a.id === asset.id)) {
                data.asset.push({
                  ...asset,
                  meters_aggregate: {
                    __typename: "meter_aggregate",
                    aggregate: {
                      __typename: "meter_aggregate_fields",
                      count: 0,
                    },
                  },
                })
              }

              return data
            }
          )

          // Only do something if no update is happening
          if (args.object.id) return

          if (asset.parent_asset_id) {
            cache.updateQuery<IAssetDetailsQuery, IAssetDetailsQueryVariables>(
              { query: AssetDetailsDocument, variables: { id: asset.parent_asset_id } },
              (data) => {
                if (data) {
                  data.asset_by_pk?.sub_assets.push({
                    ...asset,
                    parent_ids: ((asset?.parent_ids ?? []) as uuid[]).concat([
                      asset.parent_asset_id!,
                    ]),
                    active_wo_count: {
                      __typename: "asset_x_work_order_aggregate",
                      aggregate: {
                        __typename: "asset_x_work_order_aggregate_fields",
                        count: 0,
                      },
                    },
                  })
                }
                return data
              }
            )
          }

          cache.updateQuery<IAssetTableDataQuery, IAssetTableDataQueryVariables>(
            { query: AssetTableDataDocument, variables: {} },
            (data) => {
              if (data) {
                data.asset.push({
                  ...result.insert_asset_one,
                  documents_aggregate: {
                    __typename: "asset_x_upload_aggregate",
                    aggregate: {
                      __typename: "asset_x_upload_aggregate_fields",
                      count: result.insert_asset_one.documents?.length ?? 0,
                    },
                  },
                })
              }
              return data
            }
          )

          cache.updateQuery<IAssetDataViewQuery, IAssetDataViewQueryVariables>(
            { query: AssetDataViewDocument, variables: {} },
            (data) => {
              if (data) {
                data.asset.push(result.insert_asset_one)
              }
              return data
            }
          )

          cache.updateQuery<IAssetTagsQuery, IAssetTagsQueryVariables>(
            { query: AssetTagsDocument, variables: {} },
            (data) => {
              if (data) {
                data.asset.push(result.insert_asset_one)
              }
              return data
            }
          )

          cache.updateQuery<IAssetAggregateInfoQuery, IAssetAggregateInfoQueryVariables>(
            { query: AssetAggregateInfoDocument },
            (data) => {
              if (data?.asset_aggregate.aggregate?.count != null) {
                data.asset_aggregate.aggregate.count += 1
              }
              return data
            }
          )
        },
        update_asset_by_pk(
          result: { update_asset_by_pk: Partial<IAsset> },
          args: IMutationRootUpdateAssetByPkArgs,
          cache
        ) {
          if (args._set?.deleted_at) {
            cache.updateQuery<IAssetDataViewQuery, IAssetDataViewQueryVariables>(
              { query: AssetDataViewDocument, variables: {} },
              (data) => {
                if (data) {
                  return {
                    ...data,
                    asset: data.asset.filter(
                      (asset) => asset.id !== result.update_asset_by_pk.id
                    ),
                  }
                }
                return data
              }
            )
          }

          if (args._set?.archived_at) {
            cache.updateQuery<IAssetAggregateInfoQuery, IAssetAggregateInfoQueryVariables>(
              { query: AssetAggregateInfoDocument },
              (data) => {
                if (data?.asset_aggregate.aggregate?.count != null) {
                  data.asset_aggregate.aggregate.count -= 1
                }
                return data
              }
            )
          }
        },
        insert_asset_group_one(
          result: { insert_asset_group_one: IAssetGroupFragment },
          args: IMutationRootInsertAssetGroupOneArgs,
          cache
        ) {
          if (args.object.id || !result?.insert_asset_group_one) return

          cache.updateQuery<IAssetGroupsQuery, IAssetGroupsQueryVariables>(
            { query: AssetGroupsDocument, variables: {} },
            (data) => {
              if (data) {
                data.asset_group.push({ ...result.insert_asset_group_one })
                data.asset_group.sort((a, b) => a.name.localeCompare(b.name))
              }
              return data
            }
          )
        },
        insert_asset_manufacturer_one(
          result: { insert_asset_manufacturer_one: IAssetManufacturerFragment },
          args: IMutationRootInsertAssetManufacturerOneArgs,
          cache
        ) {
          if (args.object.id || !result?.insert_asset_manufacturer_one) return

          cache.updateQuery<IAssetManufacturersQuery>(
            { query: AssetManufacturersDocument, variables: {} },
            (data) => {
              if (data) {
                data.asset_manufacturer.push({ ...result.insert_asset_manufacturer_one })
                data.asset_manufacturer.sort((a, b) => a.name.localeCompare(b.name))
              }
              return data
            }
          )
        },

        insert_comment_one(
          result: { insert_comment_one: ICommentFragment },
          args: IMutationRootInsertCommentOneArgs,
          cache
        ) {
          // Check whether it is actually an upsert
          if (args.object.id) return

          cache.updateQuery<IGetCommentsQuery, IGetCommentsQueryVariables>(
            {
              query: GetCommentsDocument,
              variables: {
                where: {
                  asset_id: args.object.asset_id
                    ? { _eq: args.object.asset_id }
                    : undefined,
                  work_order_id: args.object.work_order_id
                    ? { _eq: args.object.work_order_id }
                    : undefined,
                },
              },
            },
            (data) => {
              // Check if query ran
              if (data) {
                data.comment.unshift(result.insert_comment_one)
              }
              return data
            }
          )
        },
        insert_work_order_x_upload_one(
          result: IAddNewDocumentToWorkOrderMutation,
          args: IMutationRootInsertWorkOrderXUploadOneArgs,
          cache
        ) {
          cache.updateQuery<IWorkOrderDetailsQuery, IWorkOrderDetailsQueryVariables>(
            {
              query: WorkOrderDetailsDocument,
              variables: { id: args.object.work_order_id! },
            },
            (data) => {
              if (!data?.work_order_by_pk || !result?.insert_work_order_x_upload_one)
                return data

              const documents = data.work_order_by_pk.documents
              documents.unshift(result?.insert_work_order_x_upload_one)

              return {
                work_order_by_pk: {
                  ...data.work_order_by_pk,
                  documents,
                },
              }
            }
          )
        },
        insert_asset_x_upload_one(
          result: IAddNewDocumentToAssetMutation,
          args: IMutationRootInsertAssetXUploadOneArgs,
          cache
        ) {
          const assetId = args.object.asset_id
          const uploadId = args.object.upload_id
          const assetUploadedDocument = result?.insert_asset_x_upload_one

          const asset = cache.readFragment(AssetDetailFragmentDoc, {
            id: args.object.asset_id,
          }) as IAssetDetailFragment | null

          if (!assetId || !uploadId || !assetUploadedDocument) return

          cache.updateQuery<IAssetPicturesQuery, IAssetPicturesQueryVariables>(
            { query: AssetPicturesDocument, variables: { assetId } },
            (data) => {
              if (!data || !data.asset_by_pk) return data

              data.asset_by_pk.documents.push({
                __typename: "asset_x_upload",
                document: {
                  ...assetUploadedDocument.document,
                  created_at: new Date().toISOString(),
                },
              })
              return data
            }
          )

          if (asset) {
            const documents = asset.documents || []
            documents.unshift(result.insert_asset_x_upload_one!)
            cache.writeFragment(AssetDetailFragmentDoc, { ...asset, documents })
          }
        },
        delete_asset_x_upload_by_pk(
          _result,
          args: IMutationRootDeleteAssetXUploadByPkArgs,
          cache
        ) {
          cache.invalidate({
            __typename: "asset_x_upload",
            asset_id: args.asset_id,
            upload_id: args.upload_id,
          })
        },
        insert_file_folder_x_upload_one(
          result: {
            insert_file_folder_x_upload_one: IFileFolderUploadFragment
          },
          args: IMutationRootInsertFileFolderXUploadOneArgs,
          cache
        ) {
          const fileFolder = cache.readFragment(FileFolderFragmentDoc, {
            id: args.object.file_folder_id,
          }) as IFileFolderFragment | null

          if (fileFolder) {
            const files = fileFolder.files || []
            files.unshift({
              ...result.insert_file_folder_x_upload_one,
              file: {
                ...result.insert_file_folder_x_upload_one.file,
                created_at: new Date().toISOString(),
              },
            })
            cache.writeFragment(FileFolderFragmentDoc, { ...fileFolder, files })
          }
        },
        insert_file_folder_one(
          result: {
            insert_file_folder_one: IFileFolderFragment
          },
          args: IMutationRootInsertFileFolderOneArgs,
          cache
        ) {
          const parentFolder = cache.readFragment(FileFolderFragmentDoc, {
            id: args.object.parent_folder_id,
          }) as IFileFolderFragment | null
          if (parentFolder) {
            const subFolders = parentFolder.sub_folders
            subFolders.unshift({
              ...result.insert_file_folder_one,
              __typename: "file_folder",
              sub_folders_aggregate: {
                __typename: "file_folder_aggregate",
                aggregate: {
                  __typename: "file_folder_aggregate_fields",
                  count: 0,
                },
              },
              files_aggregate: {
                __typename: "file_folder_x_upload_aggregate",
                aggregate: {
                  __typename: "file_folder_x_upload_aggregate_fields",
                  count: 0,
                },
              },
            })
            cache.writeFragment(FileFolderFragmentDoc, { ...parentFolder, subFolders })
          }
        },
        delete_file_folder_by_pk(
          _result,
          args: IMutationRootDeleteFileFolderByPkArgs,
          cache
        ) {
          cache.invalidate({
            __typename: "file_folder",
            id: args.id,
          })
        },
        update_comment_by_pk(_result, args: IMutationRootUpdateCommentByPkArgs, cache) {
          if (args._set?.deleted_at) {
            cache.invalidate({
              __typename: "comment",
              id: args.pk_columns.id,
            })
          }
        },
        ...CustomView.mutations,
        ...AssetPlace.mutations,
        ...AssetMeter.mutations,
        ...Team.mutations,
        ...WorkOrder.mutations,
        ...WorkOrderTemplates.mutations,
        ...Permissions.mutations,
        ...Setting.mutations,
        ...Notifications.mutations,
        ...Project.mutations,
        ...Contact.mutations,
        ...Asset.mutations,
        ...Consumable.mutations,
        ...Maintenance.mutations,
        ...ServiceRequest.mutations,
        // ...ConsumableGroup.mutations,
      },
      subscription_root: {
        ...WorkOrder.Subscription,
        notification(result, _args, cache) {
          cache.updateQuery<IAllNotificationsQuery, IAllNotificationsQueryVariables>(
            {
              query: AllNotificationsDocument,
            },
            (data) => {
              if (!data) return null

              const result_ = result as {
                notification?: INotificationsSubscriptionResultFragment[]
              }

              result_?.notification?.forEach((notification) => {
                if (!data.notification.some((n) => n.id === notification.id)) {
                  data.notification.push(notification)
                }
              })
              data.notification.sort((a, b) => b.created_at.localeCompare(a.created_at))

              return data
            }
          )
        },
      },
    },
  })
