import { Button } from "@components/shared"
import LanguageSelect from "@components/shared/language-select"
import RadioGroupPanel from "@components/shared/radio-group-panel"
import ScrollArea from "@components/shared/scroll-area"
import { TextInput } from "@components/shared/text-input"
import toast from "@components/shared/toast"
import { useAuthenticationStateContext } from "@contexts/auth-context"
import i18n from "@i18n"
import { useLogout } from "@models/authentication"
import LoadingPage from "@pages/loading"
import { ArrowCounterClockwise } from "@phosphor-icons/react"
import { ReactComponent as ElaraLogo } from "@resources/logos/long-logo.svg"
import { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { useAcceptUserInvite } from "./invite/code"

function RefreshButton() {
  const [refreshing, setRefreshing] = useState(false)
  const { updatePossibleLocations } = useAuthenticationStateContext()

  return (
    <Button
      onClick={async () => {
        try {
          setRefreshing(true)
          await updatePossibleLocations()
        } finally {
          setRefreshing(false)
        }
      }}
      isLoading={refreshing}
      icon={ArrowCounterClockwise}
      type="tertiary"
      color="gray"
      size="small">
      {i18n.t("common:actions.refresh")}
    </Button>
  )
}

function SelectLocation() {
  const navigate = useNavigate()
  const acceptUserInvite = useAcceptUserInvite()

  const { state: authState, updateLocation } = useAuthenticationStateContext()

  const canSelectLocation =
    authState.stage === "fully_authenticated" || authState.stage === "with_location_infos"

  if (!canSelectLocation) return <LoadingPage />

  const locationsPendingApproval = authState.locations.filter((l) => !l.approved)

  return (
    <>
      <div className="flex items-center justify-between">
        <h3 className="text-2xl font-bold tracking-tight">
          {i18n.t("common:messages.select_site_header")}
        </h3>

        <div className="flex items-center gap-x-4">
          <RefreshButton />
        </div>
      </div>

      <p className="mb-6 mt-3 text-gray-700">
        <Trans
          i18n={i18n}
          i18nKey={"common:messages.select_site"}
          values={{
            name: `${authState.userInfo.first_name} ${authState.userInfo.last_name}`,
          }}
        />
      </p>

      {authState.locations.length > 0 ? (
        <ScrollArea vertical>
          <RadioGroupPanel
            onValueChange={async (locationId) => {
              await updateLocation(locationId)
              const searchParams = new URLSearchParams(window.location.search)
              const redirectTo = searchParams.get("redirectTo") || "/"
              navigate(redirectTo)
            }}
            options={authState.locations
              .filter((l) => l.approved)
              .map((l) => ({
                value: l.id,
                label: l.name,
                description: l.org.name,
                disabled: !l.approved,
              }))}
          />

          {locationsPendingApproval.length > 0 && (
            <>
              <h2 className="mb-2 mt-4 flex items-center justify-between text-sm font-medium text-gray-500">
                <span>{i18n.t("common:messages.pending_approval")}</span>
              </h2>
              <RadioGroupPanel
                onValueChange={() => {
                  toast.error(i18n.t("common:messages.site_pending_approval_error"))
                }}
                value={"-"}
                options={locationsPendingApproval.map((l) => ({
                  value: l.id,
                  label: l.name,
                  description: l.org.name,
                  className: "opacity-60",
                }))}
              />
            </>
          )}

          <p className="mt-6 text-sm text-gray-500">
            {i18n.t("common:messages.missing_site")}
          </p>
        </ScrollArea>
      ) : (
        <>
          <div className="rounded-lg border border-dashed border-gray-300 bg-gray-50 p-6 text-gray-600">
            <p>{i18n.t("common:messages.no_site")}</p>
            <p className="mt-2">{i18n.t("common:select_site.invite_code_hint")}</p>
            <form
              className="mt-2 flex gap-x-3"
              onSubmit={async (e) => {
                e.preventDefault()
                const formData = new FormData(e.currentTarget)
                let inviteCode = formData.get("invite_code") as string
                if (!inviteCode) return
                // Detect if the full invite url is given
                if (inviteCode.startsWith("https://") || inviteCode.startsWith("http://")) {
                  const match = /invite\/(.*)/g.exec(inviteCode)
                  if (match && match[1]) {
                    inviteCode = match[1]
                  }
                }

                await acceptUserInvite({
                  userId: authState.userInfo.id,
                  inviteCode,
                })
              }}>
              <TextInput
                name="invite_code"
                placeholder={i18n.t("common:select_site.invite_code_placeholder")}
                className="flex-1"
              />
              <Button htmlType="submit">{i18n.t("common:select_site.check_invite")}</Button>
            </form>
          </div>
        </>
      )}
    </>
  )
}

export default function AuthLocationPage() {
  const logout = useLogout()
  const { i18n } = useTranslation()

  useEffect(() => {
    const body = document.querySelector("body")
    body?.style.setProperty("height", "auto")
    body?.style.setProperty("overflow", "auto")
  }, [])

  return (
    <div className="m-4 flex min-h-0 flex-1 flex-col items-center">
      <div className="w-full max-w-2xl">
        <div className="flex items-center justify-between">
          <ElaraLogo width={200} className="h-8 text-blue-700" />

          <div className="flex items-center gap-x-4">
            <LanguageSelect value={i18n.language} onValueChange={i18n.changeLanguage} />

            <Button type="tertiary" onClick={logout}>
              {i18n.t("settings:profile.logout")}
            </Button>
          </div>
        </div>

        <hr className="my-3" />

        <SelectLocation />
      </div>
    </div>
  )
}
