import { alertDialog } from "@components/shared/alert-dialog-provider"
import toast from "@components/shared/toast"
import { IPermissionScopeEnum, uuid } from "@elara/db"
import { IMaintenanceFragment } from "@graphql/documents/fragments.generated"
import {
  IUpsertMaintenanceMutation,
  IUpsertMaintenanceMutationVariables,
  UpsertMaintenanceDocument,
  useDeleteMaintenanceMutation,
  useUpdateMaintenanceScheduleMutation,
} from "@graphql/documents/maintenance.generated"
import {
  CopyWorkOrderDocument,
  ICopyWorkOrderMutation,
  ICopyWorkOrderMutationVariables,
} from "@graphql/documents/work-order.generated"
import { usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { useNavigate } from "react-router-dom"
import { useClient } from "urql"

type UseMaintenanceOptions = { id: uuid }

export const useMaintenance = ({ id }: UseMaintenanceOptions) => {
  const client = useClient()
  const navigate = useNavigate()

  const [, _deleteMaintenance] = useDeleteMaintenanceMutation()
  const [, _updateMaintenance] = useUpdateMaintenanceScheduleMutation()

  const workOrderCreateScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderCreate)
  const workOrderEditScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)
  const workOrderDeleteScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderDelete)

  const archiveMaintenance = async () => {
    alertDialog({
      title: i18n.t("maintenance:dialogs.archive_schedule.title"),
      description: i18n.t("maintenance:dialogs.archive_schedule.message"),
      actionText: i18n.t("common:actions.archive"),
      onAction: async () => {
        await _updateMaintenance(
          { id, maintenance: { archived_at: new Date().toISOString(), paused: true } },
          workOrderDeleteScope.context()
        )

        navigate("/maintenance")
      },
    })
  }

  const reactivateMaintenance = async () => {
    alertDialog({
      title: i18n.t("maintenance:dialogs.reactivate_schedule.title"),
      description: i18n.t("maintenance:dialogs.reactivate_schedule.message"),
      actionText: i18n.t("common:actions.reactivate"),
      onAction: async () => {
        await _updateMaintenance(
          { id, maintenance: { archived_at: null } },
          workOrderDeleteScope.context()
        )
      },
    })
  }

  const togglePauseState = async (maintenance: IMaintenanceFragment) => {
    if (maintenance.paused) {
      return await _updateMaintenance(
        { id, maintenance: { paused: false } },
        workOrderEditScope.context()
      )
    }

    alertDialog({
      title: i18n.t("maintenance:dialogs.pause_schedule.title"),
      description: i18n.t("maintenance:dialogs.pause_schedule.description"),
      actionText: i18n.t("maintenance:dialogs.pause_schedule.action"),
      onAction: async () => {
        await _updateMaintenance(
          { id, maintenance: { paused: true } },
          workOrderEditScope.context()
        )
      },
    })
  }

  const deleteMaintenance = async () => {
    alertDialog({
      danger: true,
      title: i18n.t("maintenance:dialogs.delete_schedule.title"),
      description: i18n.t("maintenance:dialogs.delete_schedule.message"),
      actionText: i18n.t("common:delete"),
      onAction: async () => {
        await _deleteMaintenance({ id }, workOrderDeleteScope.context())

        navigate("/maintenance")
      },
    })
  }

  const duplicateMaintenance = async (maintenance: IMaintenanceFragment) => {
    alertDialog({
      title: i18n.t("maintenance:dialogs.duplicate_schedule.title"),
      description: i18n.t("maintenance:dialogs.duplicate_schedule.message"),
      actionText: i18n.t("common:actions.duplicate"),
      onAction: async () => {
        const duplicateTemplateRes = await client
          .mutation<ICopyWorkOrderMutation, ICopyWorkOrderMutationVariables>(
            CopyWorkOrderDocument,
            {
              id: maintenance.template_id,
              options: {
                copySteps: true,
                copySubtasks: true,
                copyDocuments: true,
                newWorkOrderName: `${maintenance.name} (${i18n.t("common:copy")})`,
              },
            },
            workOrderCreateScope.context()
          )
          .toPromise()
        const duplicateTemplate = duplicateTemplateRes.data?.copyWorkOrder

        if (!duplicateTemplate) {
          toast.error(i18n.t("common:messages.duplicate_failure"))
          return
        }

        const maintenanceRes = await client
          .mutation<IUpsertMaintenanceMutation, IUpsertMaintenanceMutationVariables>(
            UpsertMaintenanceDocument,
            {
              data: {
                ...maintenance,
                name: `${maintenance.name} (${i18n.t("common:copy")})`,
                paused: true,
                triggers: {
                  data: maintenance.triggers.map((trigger) => ({
                    type: trigger.type,
                    payload: trigger.payload,
                  })),
                },
                template_id: duplicateTemplate.id,
                template: undefined,
                tasks: undefined,
                id: undefined,
              },
            },
            workOrderCreateScope.context()
          )
          .toPromise()
        const data = maintenanceRes.data?.insert_maintenance_one

        if (data) navigate(`/maintenance/${data.id}`)
      },
    })
  }

  return {
    archiveMaintenance,
    reactivateMaintenance,
    deleteMaintenance,
    duplicateMaintenance,
    togglePauseState,
  }
}
