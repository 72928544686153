import AssetStateDetail, {
  AssetStateDetailDialog,
} from "@components/asset/asset-state-detail"
import AssetStateFormDialog, {
  useAssetStateChangeListener,
} from "@components/asset/asset-state-form-dialog"
import AssetStateVariantLabel from "@components/asset/asset-state-variant-label"
import { AvailabilityBar } from "@components/asset/availability-bar"
import { Button } from "@components/shared"
import LoadingSpinner from "@components/shared/loading-spinner"
import { SelectPopover } from "@components/shared/single-select"
import { useAssetReliabilityHistoryQuery } from "@graphql/documents/asset-state.generated"
import { IAssetStateLogFragment } from "@graphql/documents/fragments.generated"
import { useDisclosure } from "@hooks"
import i18n from "@i18n"
import { exportAssetDowntimeToCSV } from "@models/asset-state"
import { CaretRight, DownloadSimple, Plus } from "@phosphor-icons/react"
import { formatDate, formatDuration } from "@utils/date"
import { isSameDay, startOfDay, startOfMinute, sub } from "date-fns"
import download from "downloadjs"
import { useState } from "react"

import { useAsset } from "./$id"

const historyFilterItems = [
  {
    searchValue: i18n.t("calendar:relative.last_7_days"),
    label: i18n.t("calendar:relative.last_7_days"),
    value: { days: 7 },
  },
  {
    searchValue: i18n.t("calendar:relative.last_30_days"),
    label: i18n.t("calendar:relative.last_30_days"),
    value: { days: 30 },
  },
  {
    searchValue: i18n.t("calendar:relative.since_6_months"),
    label: i18n.t("calendar:relative.since_6_months"),
    value: { months: 6 },
  },
  {
    searchValue: i18n.t("calendar:relative.since_1_year"),
    label: i18n.t("calendar:relative.since_1_year"),
    value: { years: 1 },
  },
  {
    searchValue: i18n.t("calendar:relative.since_3_year"),
    label: i18n.t("calendar:relative.since_3_year"),
    value: { years: 3 },
  },
]

type CurrentAssetState = Pick<IAssetStateLogFragment, "asset_state_variant"> &
  Partial<IAssetStateLogFragment> &
  Pick<IAssetStateLogFragment, "id" | "started_at">
type AssetDownTime = CurrentAssetState & { downtime_in_operating_hours: number }

// Add typescript type for this data
// {"day":"2023-04-01T13:00:00+00:00","asset_id":"df9580b5-2b42-4fd6-ab47-b1e45bb3d3eb","scheduled_operating_hours":24.0,"unplanned_downtime_hours":0.0,"planned_downtime_hours":0.0,"do_not_track_hours":0.0,"operating_hours":24.0,"availability":1.00000000000000000000,"downtimes":[{"id": "7fb6e878-7f00-490a-8aa6-b4935bd5c6ed", "note": null, "asset_id": "593573de-3200-45b1-a512-35fe61c794d8", "ended_at": "2023-04-07T12:00:00+00:00", "created_at": "2023-04-19T07:32:21.333649+00:00", "started_at": "2023-04-04T07:31:00+00:00", "location_id": "054ca575-402a-49cd-9a3d-a668c8db3b16", "created_by_id": "a6b7e792-99e2-4155-bc52-6e1472d8d7da", "asset_state_variant": {"id": "1648d54c-5915-47eb-abf4-75a619a50c01", "type": "offline", "planned": false, "subtype": "", "location_id": null}, "asset_state_variant_id": "1648d54c-5915-47eb-abf4-75a619a50c01"}]}}
export type AvailabilityData = {
  day: string
  asset_id: string
  scheduled_operating_hours: number
  unplanned_downtime_hours: number
  planned_downtime_hours: number
  do_not_track_hours: number
  operating_hours: number
  availability: number
  downtimes: AssetDownTime[] | null
}

export function AssetReliabilityHistory() {
  const { asset } = useAsset()
  const [period, setPeriod] = useState<Duration>({ days: 30 })
  const periodStartDate = startOfDay(sub(new Date(), period))

  const [query, refetch] = useAssetReliabilityHistoryQuery({
    variables: {
      assetId: asset.id,
      from: periodStartDate.toISOString(),
      to: startOfMinute(new Date()).toISOString(),
    },
    requestPolicy: "cache-and-network",
  })

  useAssetStateChangeListener(() => {
    refetch({ requestPolicy: "network-only" })
  })

  const logs = query.data?.asset_state_log ?? []
  const availability = (query.data?.asset_by_pk?.availability ?? []) as AvailabilityData[]
  const computeDowntime = () => {
    let downtime = 0
    let uptime = 0
    const assetOperatingSince = asset.operating_since
      ? new Date(asset.operating_since)
      : new Date(asset.created_at)
    const currentDate = new Date()

    if (assetOperatingSince > currentDate) {
      return {
        downtime: {
          hours: 0,
          minutes: 0,
          total: 0,
        },
        uptime: {
          hours: 0,
          minutes: 0,
          total: 0,
        },
      }
    }

    availability.forEach((log) => {
      const isSameDate = isSameDay(new Date(log.day), assetOperatingSince)
      const isAfterInstallation = isSameDate || new Date(log.day) > assetOperatingSince
      if (isAfterInstallation) {
        uptime += log.operating_hours
        downtime += log.planned_downtime_hours + log.unplanned_downtime_hours
      }
    })

    return {
      downtime: {
        hours: Math.trunc(downtime),
        minutes: Math.trunc(downtime * 60) % 60,
        total: downtime,
      },
      uptime: {
        hours: Math.trunc(uptime),
        minutes: Math.trunc(uptime * 60) % 60,
        total: uptime,
      },
    }
  }

  const { uptime, downtime } = computeDowntime()
  const currentState = query?.data?.asset_by_pk?.current_state as CurrentAssetState | null
  const addDowntimeDialog = useDisclosure()
  const endedLogs = logs.filter((log) => log.ended_at)

  if (!availability.length) return <LoadingSpinner />

  return (
    <div className="max-w-screen-lg">
      <div>
        <h3 className="mb-3 font-medium">{i18n.t("assets:fields.current_state")}</h3>
      </div>
      {currentState && (
        <>
          <AssetStateDetail state={currentState} />
          <hr className="my-3" />
        </>
      )}

      <div className="mb-3 flex items-center justify-between">
        <h3 className="font-medium leading-none">
          {i18n.t("assets:state.reliablity.history_section_title")}
        </h3>
        <div className="flex items-center space-x-2 rounded border border-gray-200 bg-gray-50 pl-1 text-sm text-gray-700">
          <span className="whitespace-nowrap font-medium">
            {i18n.t("assets:state.reliablity.period")}
          </span>
          <SelectPopover<any>
            isClearable={false}
            className="-m-px translate-x-px"
            items={historyFilterItems}
            value={period}
            onChange={setPeriod}
          />
        </div>
      </div>

      <div className="grid grid-cols-2 divide-x rounded-lg border border-gray-100 bg-gray-50 p-3">
        <div className="flex flex-col items-center">
          <span className="text-base font-medium text-green-600">
            {formatDuration(uptime, { delimiter: ", ", zero: true })}{" "}
          </span>
          <span className="text-sm text-gray-500">
            {i18n.t("assets:state.reliablity.uptime")}
          </span>
        </div>
        <div className="flex flex-col items-center">
          <span className="text-base font-medium text-red-600">
            {formatDuration(downtime, { delimiter: ", ", zero: true })}{" "}
          </span>
          <span className="text-sm text-gray-500">
            {i18n.t("assets:state.reliablity.downtime")}
          </span>
        </div>
      </div>

      <div className="mb-1 mt-3 flex items-center justify-between text-sm text-gray-600">
        <span>{i18n.t("assets:state.reliablity.uptime")}</span>
        <span className="">
          {downtime.total + uptime.total !== 0
            ? `${
                Math.round((uptime.total / (downtime.total + uptime.total)) * 10000) / 100
              }%`
            : "0%"}
        </span>
      </div>
      <AvailabilityBar data={availability} />
      <div className="mt-1 flex items-center justify-between text-sm text-gray-600">
        <span>{formatDate(periodStartDate, "P")}</span>
        <span>{formatDate(new Date(), "P")}</span>
      </div>

      <div className="my-2 flex flex-wrap items-center gap-x-3 gap-y-2">
        <Button
          type="secondary"
          icon={DownloadSimple}
          className="grow basis-64"
          onClick={() => {
            const filename = `elara_export-state-history_${
              asset.public_id
            }_${new Date().toISOString()}.csv`
            const csv = exportAssetDowntimeToCSV(logs)
            download(csv, filename, "text/csv")
          }}>
          {i18n.t("assets:state.actions.export_history")}
        </Button>
        <AssetStateFormDialog
          assetId={asset.id}
          followUpOption
          isOpen={addDowntimeDialog.isOpen}
          onOpenChange={addDowntimeDialog.changeOpen}
          onSubmit={() => refetch()}
          withEndedAt>
          <Button type="secondary" icon={Plus} className="grow basis-64">
            {i18n.t("assets:state.actions.add_downtime")}
          </Button>
        </AssetStateFormDialog>
      </div>
      {endedLogs.length ? (
        <>
          <div className="text-sm font-medium text-gray-600">
            {endedLogs.length} {i18n.t("common:entries", { count: endedLogs.length })}
          </div>
          <div className="divide-y divide-gray-200">
            {endedLogs.map((log) => {
              const startedAt = log.started_at ? new Date(log.started_at) : null
              const endedAt = log.ended_at ? new Date(log.ended_at) : null

              if (!endedAt) return null

              return (
                <AssetStateDetailDialog
                  key={log.id}
                  assetId={asset.id}
                  state={log}
                  onDelete={() => refetch()}
                  allowEdit>
                  <button className="group relative w-full py-2 text-start">
                    <div className="absolute -inset-x-2 inset-y-1 hidden rounded-md bg-gray-50 group-hover:block" />
                    <div className="isolate z-10">
                      <div className="flex items-center">
                        {/* {duration.hours}h{duration.minutes}m */}
                        <div className="flex flex-col items-start">
                          <span className="block text-sm font-medium">
                            {startedAt &&
                              endedAt &&
                              formatDuration(
                                {
                                  hours: Math.trunc(log.downtime_in_operating_hours ?? 0),
                                  minutes:
                                    Math.trunc(
                                      (log.downtime_in_operating_hours ?? 0) * 60
                                    ) % 60,
                                },
                                {
                                  delimiter: ", ",
                                  format: ["days", "hours", "minutes"],
                                  zero: log.downtime_in_operating_hours === 0,
                                }
                              )}
                          </span>
                          <span className="mt-0.5 block text-sm leading-none text-gray-500">
                            {startedAt && formatDate(startedAt, "Pp")}
                          </span>
                        </div>
                        <div className="flex-1"></div>
                        <div className="flex items-center">
                          <AssetStateVariantLabel variant={log.asset_state_variant} />
                        </div>
                        <CaretRight width={16} height={16} weight="bold" className="mx-2" />
                      </div>
                      {log.note && (
                        <div className="-z-10 mt-1 line-clamp-3 text-sm text-gray-600">
                          {log.note}
                        </div>
                      )}
                    </div>
                  </button>
                </AssetStateDetailDialog>
              )
            })}
          </div>
        </>
      ) : (
        <div className="my-3 text-center text-sm text-gray-500">
          {i18n.t("assets:state.no_entries")}
        </div>
      )}
    </div>
  )
}

export default AssetReliabilityHistory
