import { Data, OrderBy, orderByOperator, useOrderByCompareList } from "@elara/select"
import React, { PropsWithChildren, useMemo } from "react"

import { useDataViewConfigContext } from "./data-view-config"
import { DataOrderConfig } from "./data-view-types"

function useDataViewSort<D extends Data, Id extends string>(props: {
  tieBraker: DataOrderConfig<Id>[]
}) {
  const config = useDataViewConfigContext()

  const orderByStatements: OrderBy<D>[] = useMemo(
    () =>
      config.config.orderBy
        .concat(props.tieBraker ?? [])
        .map(({ id, dir, nulls = "nulls_last" }) => {
          const op = orderByOperator(dir, nulls)
          return (config.columns.find((c) => c.id === id)?.orderBy?.(op) ??
            {}) as OrderBy<D>
        }),
    [config.columns, config.config.orderBy]
  )

  const orderByCompare = useOrderByCompareList(orderByStatements)

  return {
    orderByCompare,
    orderByStatements,
    orderBy: config.config.orderBy,
    updateOrderBy: config.actions.updateOrderBy,
  }
}

const DataViewSortContext = React.createContext<
  ReturnType<typeof useDataViewSort<Data, string>>
>(undefined!)

export function useDataViewSortContext<D extends Data, Id extends string>() {
  const context = React.useContext(DataViewSortContext)
  if (!context) {
    throw new Error("useDataViewSortContext must be used within a DataViewSort")
  }
  return context as unknown as ReturnType<typeof useDataViewSort<D, Id>>
}

export type DataViewSortProps<Id extends string> = PropsWithChildren<{
  tieBraker: DataOrderConfig<Id>[]
}>

export function DataViewSort<D extends Data, Id extends string = string>(
  props: DataViewSortProps<Id>
) {
  const value = useDataViewSort<D, string>(props)

  return (
    <DataViewSortContext.Provider
      value={value as ReturnType<typeof useDataViewSort<Data, string>>}>
      {props.children}
    </DataViewSortContext.Provider>
  )
}
