import { Data } from "@elara/select"

import LoadingSpinner from "../loading-spinner"
import { BasicDataViewProps, CoreDataView } from "./core-data-view"
import { DataViewConfig, DataViewConfigProps } from "./data-view-config"

export * from "./data-view.hooks"
export type { Column, DataViewConfiguration } from "./data-view-types"

export type DataViewProps<D extends Data, Id extends string, Options extends {}> = Omit<
  BasicDataViewProps<D, Id, Options>,
  "filterSchema"
> &
  DataViewConfigProps<Id> & {
    filterSchema?: BasicDataViewProps<D, Id, Options>["filterSchema"] | null
  }

export function DataView<D extends Data, Id extends string, Options extends {}>(
  props: DataViewProps<D, Id, Options>
) {
  const { filterSchema } = props

  if (filterSchema === null) {
    return <LoadingSpinner />
  }

  return (
    <DataViewConfig {...props}>
      <CoreDataView
        {...props}
        isEditView={false}
        key={props.configId}
        filterSchema={filterSchema}
      />
    </DataViewConfig>
  )
}
