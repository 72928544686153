import { HStack, VStack } from "@components/layout"
import SettingsPageLayout from "@components/settings/page-layout"
import { Button } from "@components/shared"
import { useUserInputConfirmationDialog } from "@components/shared/dialog-with-user-input-confirmation"
import PictureUpload from "@components/shared/picture-upload"
import { Select } from "@components/shared/select"
import { TextInput } from "@components/shared/text-input"
import toast from "@components/shared/toast"
import { usePage } from "@contexts/page-context"
import { hasPermissionScope, useUser } from "@contexts/user-context"
import { uuid } from "@elara/db"
import {
  IAssetParentIdsQuery,
  IAssetTableDataQuery,
  useAssetManufacturersQuery,
  useAssetParentIdsQuery,
  useAssetTableDataQuery,
  useDeleteAssetManyMutation,
  useInsertAssetManyMutation,
} from "@graphql/documents/asset.generated"
import {
  useAssetGroupsQuery,
  useInsertAssetGroupManyMutation,
} from "@graphql/documents/asset-group.generated"
import { useCreateAssetManufacturerManyMutation } from "@graphql/documents/asset-manufacturer.generated"
import { useCreateAssetPlaceManyMutation } from "@graphql/documents/asset-place.generated"
import {
  IAllConsumablesWithLogsQuery,
  useAllConsumablesWithLogsQuery,
  useConsumableConfigQuery,
  useDeleteConsumableLogManyMutation,
  useDeleteConsumableManyMutation,
  useInsertConsumableLogManyMutation,
  useInsertConsumableManyMutation,
  useSetAssetConsumablesManyMutation,
  useUpdateConsumableStorageLocationManyMutation,
} from "@graphql/documents/consumable.generated"
import {
  useConsumableGroupsQuery,
  useInsertConsumableGroupManyMutation,
} from "@graphql/documents/consumable-group.generated"
import { usePlacesQuery } from "@graphql/documents/place.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks/use-permission-scope"
import i18n from "@i18n"
import { FileArrowUp, TrashSimple } from "@phosphor-icons/react"
import Papa, { ParseResult, unparse } from "papaparse"
import { ChangeEvent, useRef, useState } from "react"
import { v4 } from "uuid"

interface Metadata {
  key: string
  value: string
}

interface PropertyData {
  [uuid: string]: {
    type: string
    value: string
  }
}

interface StorageLocation {
  data: {
    place_id: string
    area: string
  }[]
}

interface TransformedSparePart {
  id: string | null
  name: string
  min_quantity: number
  unit: string
  storage_locations: StorageLocation | null
  location: string
  metadata: Metadata[]
  properties: PropertyData
  group_id: string | null
  cost: number | null
  public_id: string | null
}
interface SparePartLog {
  consumable_id: string
  place_id: string
  adjustment: number
}

interface PlacesUpdate {
  where: {
    consumable_id: {
      _eq: string
    }
  }
  _set: {
    place_id: string
  }
}
interface AssignedAssetsMap {
  [key: string]: string[]
}

interface AssetXConsumable {
  asset_id: string
  consumable_id: string
}

interface CustomField {
  name: string
  value: string
}

interface TransformedHierarchy {
  id: string | null
  name: string
  parent_asset_id: string | null
  group_id: string | null
  manufacturer_id: string | null
  year_of_purchase: number | null
  serial_number: string | null
  model_number: string | null
  place_id: string | null
  custom_fields: CustomField[]
  public_id: string | undefined
}

const objectsToCSV = (
  assetQuery: IAssetTableDataQuery,
  assetParentQuery: IAssetParentIdsQuery | undefined
) => {
  type AssetExport = {
    id: string
    model_number: string | null
    name: string
    year_of_purchase: number | null
    public_id: string | null
    group: string | null
    manufacturer: string | null
    place: string | null
    parent: string | null
  }

  const assetExport: Array<AssetExport> = assetQuery.asset.map(
    ({
      id,
      model_number,
      name,
      year_of_purchase,
      public_id,
      group,
      manufacturer,
      place,
    }) => {
      const parent_asset_id = assetParentQuery?.asset.find((p) => p.id === id)
      return {
        id,
        model_number,
        name,
        year_of_purchase,
        public_id,
        group: group ? group.name : null,
        manufacturer: manufacturer ? manufacturer.name : null,
        place: place ? place.name : null,
        parent: parent_asset_id?.parents ? parent_asset_id.parents.pop()?.id || null : null,
      }
    }
  )
  const csv = unparse(assetExport)

  const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" })
  const csvURL = window.URL.createObjectURL(csvData)

  var tempLink = document.createElement("a")
  tempLink.href = csvURL
  tempLink.setAttribute("download", "download.csv")
  tempLink.click()
}

const sparePartsToCSV = (sparePartsQuery: IAllConsumablesWithLogsQuery) => {
  type SparePartExport = {
    id: string
    name: string
    group: string
    unit: string
    min_quantity: number
    quantity: number
    location: string
    area: string
    cost: number
    public_id: string
    [key: string]: string | number
  }

  const csvData: Array<SparePartExport> = []

  for (const element of sparePartsQuery.consumable) {
    for (const storageLocation of element.storage_locations) {
      const metadataObj: Record<string, string> = {}
      for (const metadataItem of element.metadata as Array<{
        key: string
        value: string
      }>) {
        if (metadataItem.key === "area") {
          metadataObj[metadataItem.key] = storageLocation.area || ""
        } else {
          metadataObj[metadataItem.key] = metadataItem.value
        }
      }

      const log = sparePartsQuery.consumable_log.find(
        (l) => l.consumable_id === element.id && l.place_id === storageLocation.place.id
      )
      const locationQuantity = log ? log.adjustment : 0

      const sparePartExport: SparePartExport = {
        id: element.id,
        name: element.name,
        group: element.group ? element.group.name : "",
        unit: element.unit,
        min_quantity: element.min_quantity,
        quantity: locationQuantity,
        location: storageLocation.place.name,
        area: storageLocation.area || "",
        cost: element.cost,
        public_id: element.public_id,
        ...metadataObj,
      }

      csvData.push(sparePartExport)
    }
  }
  const csv = unparse(csvData)

  const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
  const csvURL = window.URL.createObjectURL(csvBlob)

  var tempLink = document.createElement("a")
  tempLink.href = csvURL
  tempLink.setAttribute("download", "download.csv")
  tempLink.click()
}

const SettingsImport = () => {
  const [, insertAssetMany] = useInsertAssetManyMutation()
  const [, insertAssetGroupMany] = useInsertAssetGroupManyMutation()
  const [, insertAssetPlaceMany] = useCreateAssetPlaceManyMutation()
  const [, insertAssetManufacturerMany] = useCreateAssetManufacturerManyMutation()
  const [, insertConsumableMany] = useInsertConsumableManyMutation()
  const [, insertConsumableLogMany] = useInsertConsumableLogManyMutation()
  const [, insertConsumableGroupMany] = useInsertConsumableGroupManyMutation()
  const [, updateConsumableStorageLocationMany] =
    useUpdateConsumableStorageLocationManyMutation()
  const [, setConsuambleAssetsMany] = useSetAssetConsumablesManyMutation()
  const [, deleteConsumableLog] = useDeleteConsumableLogManyMutation()
  const [, deleteAssetMany] = useDeleteAssetManyMutation()
  const [, deleteConsumableMany] = useDeleteConsumableManyMutation()
  const { location } = useUser()
  const [consumableConfigQueryRes] = useConsumableConfigQuery({
    variables: { locationId: location.id },
    requestPolicy: "cache-and-network",
  })
  const [getGroups] = useAssetGroupsQuery()
  const [getConsumableGroups] = useConsumableGroupsQuery()
  const [getPlaces] = usePlacesQuery()
  const [getManufacutrers] = useAssetManufacturersQuery()
  const [getAssets] = useAssetTableDataQuery()
  const [getAssetParents] = useAssetParentIdsQuery()
  const [getSpareParts] = useAllConsumablesWithLogsQuery()

  const isAdmin = hasPermissionScope(IPermissionScopeEnum.AppAccountManagement)

  const processAssets = async (
    assets: TransformedHierarchy[],
    error_msg: string[] = [],
    index: number = 0
  ) => {
    const response = await insertAssetMany(
      {
        data: assets,
      },
      assetEditScope.context()
    )

    if (response.error) {
      error_msg.push(
        "Error inserting assets from batch line " + index + " to " + linesPerStep
      )
    }
  }

  const processSpareParts = async (
    spareParts: TransformedSparePart[],
    sparePartsLog: SparePartLog[],
    sparePartsPlaces: PlacesUpdate[],
    assignedAssetsBySparePartId: AssignedAssetsMap,
    deleteLogIds: string[],
    error_msg: string[] = [],
    index: number = 0
  ) => {
    await deleteConsumableLog({
      where: {
        consumable: {
          id: {
            _in: deleteLogIds,
          },
        },
      },
    })

    const response_consumable = await insertConsumableMany(
      {
        data: spareParts,
      },
      assetEditScope.context()
    )
    if (response_consumable.error) {
      error_msg.push(
        "Error inserting consumables from batch line " + index + " to " + linesPerStep
      )
    }

    const sparePartIds = spareParts
      .map((sparePart) => sparePart.id)
      .filter((id) => id !== null) as string[]

    let assetXConsumable: AssetXConsumable[] = Object.entries(
      assignedAssetsBySparePartId
    ).reduce(
      (acc: AssetXConsumable[], [consumableId, assetIds]) => [
        ...acc,
        ...assetIds.map((assetId) => ({
          asset_id: assetId,
          consumable_id: consumableId,
        })),
      ],
      []
    )

    const response_assign_assets = await setConsuambleAssetsMany(
      {
        consumableIds: sparePartIds,
        assetXConsumable: assetXConsumable,
      },
      assetEditScope.context()
    )
    if (response_assign_assets.error) {
      error_msg.push(
        "Error inserting consumables to objects from batch line " +
          index +
          " to " +
          linesPerStep
      )
    }

    const response_log = await insertConsumableLogMany(
      {
        data: sparePartsLog,
      },
      dataEntryScope.context()
    )
    if (response_log.error) {
      error_msg.push(
        "Error inserting consumable log from batch line " + index + " to " + linesPerStep
      )
    }
    const response_storage = await updateConsumableStorageLocationMany(
      {
        data: sparePartsPlaces,
      },
      assetEditScope.context()
    )
    if (response_storage.error) {
      error_msg.push(
        "Error inserting consumable place from batch line " + index + " to " + linesPerStep
      )
    }
  }

  const deleteAllObjects = async (locationId: uuid) => {
    const response = await deleteAssetMany(
      { locationId: locationId },
      deleteAssetScope.context()
    )
    if (response.error) {
      toast.error(response.error.message)
    } else {
      toast.success(i18n.t("settings:import.objects_deleted"))
    }
  }

  const deleteAllMaterials = async (locationId: uuid) => {
    const response = await deleteConsumableMany(
      { locationId: locationId },
      deleteAssetScope.context()
    )
    if (response.error) {
      toast.error(response.error.message)
    } else {
      toast.success(i18n.t("settings:import.materials_deleted"))
    }
  }

  const objectsDeleteModal = useUserInputConfirmationDialog({
    title: i18n.t("settings:import.confirm_delete_objects.title"),
    message: i18n.t("settings:import.confirm_delete_objects.message"),
    okText: i18n.t("common:delete"),
    cancelText: i18n.t("common:cancel"),
    onConfirm: async () => {
      deleteAllObjects(location.id)
    },
  })

  const materialsDeleteModal = useUserInputConfirmationDialog({
    title: i18n.t("settings:import.confirm_delete_materials.title"),
    message: i18n.t("settings:import.confirm_delete_materials.message"),
    okText: i18n.t("common:delete"),
    cancelText: i18n.t("common:cancel"),
    onConfirm: async () => {
      deleteAllMaterials(location.id)
    },
  })

  const onObjectsDelete = () => objectsDeleteModal.show()
  const onMaterialsDelete = () => materialsDeleteModal.show()

  const assetScope = usePermissionScope(IPermissionScopeEnum.AppAssetCreate)
  const assetEditScope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)
  const deleteAssetScope = usePermissionScope(IPermissionScopeEnum.AppAssetArchiveDelete)
  const manufacturerScope = usePermissionScope(
    IPermissionScopeEnum.AppManufacturerCreateEditDelete
  )
  const dataEntryScope = usePermissionScope(IPermissionScopeEnum.AppDataEntry)

  const [selectedImportType, setSelectedImportType] = useState("objects")
  const [linesPerStep, setLinesPerStep] = useState(50)

  const uploadSparePartFile = async (file: File) => {
    console.log("uploading spare part")
    const id = v4()

    interface SparePartRow {
      id: string
      name: string
      quantity: number
      min_quantity: number
      unit: string
      location: string
      group: string
      cost: number
      public_id: string
      [key: string]: string | number
    }

    const createStorageLocation = (location: string, area: string): StorageLocation => ({
      data: [
        {
          place_id: location,
          area: area,
        },
      ],
    })

    const standardHeaders: (keyof SparePartRow)[] = [
      "id",
      "name",
      "quantity",
      "min_quantity",
      "unit",
      "location",
      "group",
      "cost",
      "public_id",
    ]

    if (!file) return
    Papa.parse(file, {
      header: true,
      dynamicTyping: false,
      delimiter: ",",
      skipEmptyLines: true,
      transformHeader: function (h) {
        return h.trim()
      },
      complete: async function (results: ParseResult<SparePartRow>) {
        const spareParts: SparePartRow[] = results.data
        for (const sparePart of spareParts) if (!sparePart.id) sparePart.id = v4()

        const uniquePlaces: Set<string> = new Set()
        const uniqueGroups: Set<string> = new Set()

        const error_msg: string[] = []

        let transformedSpareParts: TransformedSparePart[] = []
        let transformedSparePartLogs: SparePartLog[] = []
        let placesUpdate: PlacesUpdate[] = []
        let deleteLogIds: string[] = []
        let assignedAssetsBySparePartId: AssignedAssetsMap = {}
        let areas: { [key: string]: string[] } = {}

        for (const sparePart of spareParts) {
          uniquePlaces.add(sparePart.location.trim())
          uniqueGroups.add(sparePart.group.trim())
          if (areas[sparePart.id]) {
            areas[sparePart.id].push(String(sparePart.area).trim())
          } else {
            areas[sparePart.id] = [String(sparePart.area).trim()]
          }
        }

        const filteredUniquePlaces = Array.from(uniquePlaces).filter(
          (location) => location !== ""
        )
        const filteredUniqueGroups = Array.from(uniqueGroups).filter(
          (group) => group !== ""
        )

        const insertPlaces = await insertAssetPlaceMany(
          {
            objects: filteredUniquePlaces.map((place_name) => ({ name: place_name })),
          },
          assetScope.context()
        )
        const insertGroups = await insertConsumableGroupMany(
          {
            objects: filteredUniqueGroups.map((group_name) => ({ name: group_name })),
          },
          assetScope.context()
        )

        let places = [
          ...(getPlaces.data?.place || []),
          ...(insertPlaces.data?.insert_place?.returning || []),
        ]
        let groups = [
          ...(getConsumableGroups.data?.consumable_group || []),
          ...(insertGroups.data?.insert_consumable_group?.returning || []),
        ]

        const propertiesQuery =
          consumableConfigQueryRes?.data?.consumable_config_by_pk?.properties
        const uuidNamePairs = propertiesQuery
          ? Object.entries(propertiesQuery).map(([uuid, prop]) => ({
              uuid,
              name: (prop as any)?.name,
            }))
          : []

        let last_index = 0
        for (const [index, sparePart] of spareParts.entries()) {
          let metadata = []
          let properties: PropertyData = {}
          let assignedAssets = []
          for (const key in sparePart) {
            if (!standardHeaders.includes(key as keyof SparePartRow)) {
              if (key === "area") {
                metadata.push({ key, value: areas[sparePart.id].join(", ") })
              } else if (key.startsWith("object_")) {
                if (String(sparePart[key]).trim())
                  assignedAssets.push(String(sparePart[key]).trim())
              } else {
                const matchingPair = uuidNamePairs.find((pair) => pair.name === key)
                if (matchingPair) {
                  properties[matchingPair.uuid] = {
                    type: "text",
                    value: String(sparePart[key]).trim(),
                  }
                } else {
                  metadata.push({ key, value: String(sparePart[key]).trim() })
                }
              }
            }
          }
          assignedAssetsBySparePartId[sparePart.id] = assignedAssets

          const place = places.find((place) => place.name === sparePart.location) || {
            id: "",
            area: "",
          }
          const groupId = groups.find((group) => group.name === sparePart.group) || {
            id: null,
          }

          const area = String(sparePart.area).trim()

          const existingElement = getSpareParts.data?.consumable.find(
            (element) => element.id === sparePart.id
          )

          const storageLocation = createStorageLocation(place.id, area)

          if (existingElement) {
            transformedSpareParts.push({
              id: sparePart.id,
              name: sparePart.name.trim(),
              min_quantity: sparePart.min_quantity,
              unit: sparePart.unit.trim(),
              storage_locations: storageLocation,
              location: sparePart.location,
              metadata,
              properties,
              group_id: groupId.id,
              cost: sparePart.cost,
              public_id: sparePart.public_id.trim(),
            })
            placesUpdate.push({
              where: {
                consumable_id: {
                  _eq: sparePart.id,
                },
              },
              _set: {
                place_id: place.id,
              },
            })
            deleteLogIds.push(sparePart.id)
          } else {
            transformedSpareParts.push({
              id: sparePart.id,
              name: sparePart.name.trim(),
              min_quantity: sparePart.min_quantity || 0,
              unit: sparePart.unit.trim(),
              storage_locations: storageLocation,
              location: sparePart.location,
              metadata,
              properties,
              group_id: groupId.id,
              cost: sparePart.cost,
              public_id: sparePart.public_id,
            })
          }
          transformedSparePartLogs.push({
            consumable_id: sparePart.id,
            place_id: place.id,
            adjustment: sparePart.quantity || 0,
          })

          if (index % linesPerStep === linesPerStep - 1) {
            console.log(
              `Processing spare part ${index - linesPerStep + 1} to ${index + 1}...`
            )
            setProcessingIndex(index + 1)
            await processSpareParts(
              transformedSpareParts,
              transformedSparePartLogs,
              placesUpdate,
              assignedAssetsBySparePartId,
              deleteLogIds,
              error_msg,
              index
            )
            transformedSpareParts = []
            transformedSparePartLogs = []
            placesUpdate = []
            deleteLogIds = []
          }
          last_index = index
        }
        if (transformedSpareParts.length > 0) {
          await processSpareParts(
            transformedSpareParts,
            transformedSparePartLogs,
            placesUpdate,
            assignedAssetsBySparePartId,
            deleteLogIds,
            error_msg,
            last_index
          )
          transformedSpareParts = []
          transformedSparePartLogs = []
          placesUpdate = []
          deleteLogIds = []
        }

        console.log(error_msg)

        if (error_msg.length === 0) {
          toast.success(i18n.t("settings:import.success", { count: results.data.length }))
          setDone(true)
          setCount(results.data.length)
          setProcessingIndex(0)
        } else toast.error(i18n.t("settings:import.failure"))
      },
    })

    return id
  }

  const uploadObjectFile = async (file: File) => {
    const id = v4()

    interface ObjectRow {
      id: string
      group: string
      place: string
      location: string
      manufacturer: string
      name: string
      number: string
      year_of_purchase: number
      serial_number: string
      model_number: string
      public_id: string | undefined
      parent: string
      children?: ObjectRow[]
      [key: string]: string | number | ObjectRow[] | undefined
    }
    const standardHeaders: (keyof ObjectRow)[] = [
      "id",
      "place",
      "location", //synonym to place
      "name",
      "group",
      "manufacturer",
      "year",
      "model_number",
      "serial_number",
      "public_id",
      "parent",
    ]

    if (file) {
      Papa.parse(file, {
        header: true,
        dynamicTyping: false,
        delimiter: ",",
        skipEmptyLines: true,
        transformHeader: function (h) {
          return h.trim()
        },
        complete: async function (results: ParseResult<ObjectRow>) {
          const assets: ObjectRow[] = results.data
          for (const asset of assets) if (!asset.id) asset.id = v4()

          const assetMap = new Map<string, ObjectRow>()

          for (const asset of assets) {
            assetMap.set(asset.id !== "" ? asset.id : v4(), asset)
          }

          const error_msg: string[] = []

          const uniqueGroups: Set<string> = new Set()
          const uniquePlaces: Set<string> = new Set()
          const uniqueManufacturers: Set<string> = new Set()
          let transformedHierarchy: TransformedHierarchy[] = []

          for (const asset of assets) {
            uniqueGroups.add(asset.group)
            uniquePlaces.add(asset.place ?? asset.location)
            uniqueManufacturers.add(asset.manufacturer)
          }

          const filteredUniqueGroups = Array.from(uniqueGroups).filter(
            (group) => group !== ""
          )
          const filteredUniquePlaces = Array.from(uniquePlaces).filter(
            (location) => location !== ""
          )
          const filteredUniqueManufacturers = Array.from(uniqueManufacturers).filter(
            (manufacturer) =>
              manufacturer !== "" &&
              !getManufacutrers.data?.asset_manufacturer.some(
                (item) => item.name === manufacturer
              )
          )

          let insertGroups
          if (filteredUniqueGroups.length > 0 && filteredUniqueGroups[0]) {
            insertGroups = await insertAssetGroupMany(
              {
                objects: filteredUniqueGroups.map((group_name) => ({ name: group_name })),
              },
              assetScope.context()
            )
          }

          let insertPlaces
          if (filteredUniquePlaces.length > 0 && filteredUniquePlaces[0])
            insertPlaces = await insertAssetPlaceMany(
              {
                objects: filteredUniquePlaces.map((place_name) => ({ name: place_name })),
              },
              assetScope.context()
            )

          let insertManufacturers
          if (filteredUniqueManufacturers.length > 0 && filteredUniqueManufacturers[0]) {
            insertManufacturers = await insertAssetManufacturerMany(
              {
                objects: filteredUniqueManufacturers.map((manufacturer_name) => ({
                  name: manufacturer_name,
                })),
              },
              manufacturerScope.context()
            )
          }
          let groups = [
            ...(getGroups.data?.asset_group || []),
            ...(insertGroups?.data?.insert_asset_group?.returning || []),
          ]
          let places = [
            ...(getPlaces.data?.place || []),
            ...(insertPlaces?.data?.insert_place?.returning || []),
          ]
          let manufacturers = [
            ...(getManufacutrers.data?.asset_manufacturer || []),
            ...(insertManufacturers?.data?.insert_asset_manufacturer?.returning || []),
          ]

          let last_index = 0
          for (const [index, asset] of assets.entries()) {
            let custom_fields = []
            for (const key in asset) {
              if (!standardHeaders.includes(key as keyof ObjectRow)) {
                custom_fields.push({ name: key, value: String(asset[key]).trim() })
              }
            }

            const parentId = asset.parent
            const parent = assetMap.get(parentId)
            const groupId = groups.find((group) => group.name === asset.group) || {
              id: null,
            }
            const manufacturerId = manufacturers.find(
              (manufacturer) => manufacturer.name === asset.manufacturer
            ) || { id: "" }
            const placeId = places.find(
              (place) => place.name === asset.place ?? asset.location
            ) || {
              id: "",
            }

            transformedHierarchy.push({
              id: asset.id,
              name: asset.name,
              parent_asset_id: parent ? parent.id : null,
              group_id: groupId.id || null,
              place_id: placeId.id || null,
              serial_number: asset.serial_number,
              model_number: asset.number,
              manufacturer_id: manufacturerId.id || null,
              year_of_purchase: asset.year_of_purchase || null,
              public_id: asset.public_id?.trim(),
              custom_fields,
            })

            if (index % linesPerStep === linesPerStep - 1) {
              console.log(
                `Processing object ${index - linesPerStep + 1} to ${index + 1}...`
              )
              setProcessingIndex(index + 1)
              await processAssets(transformedHierarchy, error_msg, index)

              transformedHierarchy = []
            }
            last_index = index
          }
          if (transformedHierarchy.length > 0) {
            await processAssets(transformedHierarchy, error_msg, last_index)
            transformedHierarchy = []
          }

          console.log(error_msg)

          if (error_msg.length === 0) {
            toast.success(i18n.t("settings:import.success", { count: results.data.length }))
            setDone(true)
            setCount(results.data.length)
            setProcessingIndex(0)
          } else toast.error(i18n.t("settings:import.failure"))
        },
      })
    }

    return id
  }

  const onUploadFiles = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return

    for (const file of Object.values(e.target.files)) {
      if (selectedImportType === "objects") uploadObjectFile(file)
      else if (selectedImportType === "spareparts") uploadSparePartFile(file)
    }
  }
  usePage({
    isSubPage: true,
    id: "settings/import",
    title: "Import",
  })

  const [count, setCount] = useState(0)
  const [done, setDone] = useState(false)
  const [processingIndex, setProcessingIndex] = useState(0)

  const inputFileRef: React.RefObject<HTMLInputElement> = useRef(null)

  return (
    <SettingsPageLayout title={i18n.t("settings:import.title")} description="CSV Import">
      <VStack space={12} className="relative">
        {done && (
          <VStack space={20}>
            <p className="text-sm font-semibold">
              {i18n.t("settings:import.success", { count })}
            </p>
          </VStack>
        )}
        <Select
          items={[
            {
              value: "objects",
              label: i18n.t("common:asset_other"),
            },
            {
              value: "spareparts",
              label: i18n.t("common:consumable_other"),
            },
          ]}
          placeholder="Select import type"
          onValueChange={(value) => setSelectedImportType(value)}
          value={selectedImportType}
        />
        <div className="space-x-2">
          <span className="inline-flex items-center text-sm font-medium text-gray-600 sm:mt-px">
            {i18n.t("settings:import.lines_per_step")}
          </span>
          <TextInput
            type="number"
            value={linesPerStep}
            onChange={(e) => setLinesPerStep(Number(e.currentTarget.value))}
            placeholder="Lines per step"
          />
        </div>
        <VStack space={20}>
          <div className="relative my-3">
            <PictureUpload.Dropzone
              accept={{
                "text/csv": [".csv"],
              }}
              onDropFile={
                selectedImportType === "objects" ? uploadObjectFile : uploadSparePartFile
              }
              onFilesRejected={(err) => {
                if (err.length) {
                  toast.error({
                    title: "Error",
                    body: <span className="text-sm text-gray-700">Error</span>,
                    params: {
                      duration: 5000,
                    },
                  })
                }
              }}
            />

            <label className=" flex cursor-pointer flex-col items-center justify-center overflow-hidden rounded border-2 border-dashed border-grey-5 p-6 px-px text-gray-700 hover:bg-gray-100">
              <input
                type="file"
                accept=".csv"
                ref={inputFileRef}
                onChange={onUploadFiles}
                style={{ display: "none" }}
              />
              <div className="flex items-center gap-x-2">
                <FileArrowUp size={24} className="text-gray-500"></FileArrowUp>{" "}
                {i18n.t("settings:import.select_csv")}
              </div>
            </label>
          </div>
        </VStack>
        <HStack space={20} className="mb-4">
          {getAssets.data && (
            <Button
              type="secondary"
              onClick={() => objectsToCSV(getAssets.data!, getAssetParents.data)}>
              {i18n.t("settings:import.download_objects")}
            </Button>
          )}
          {getSpareParts.data && (
            <Button type="secondary" onClick={() => sparePartsToCSV(getSpareParts.data!)}>
              {i18n.t("settings:import.download_spare_parts")}
            </Button>
          )}
          {processingIndex > 0 && (
            <div className="block">
              {i18n.t("settings:import.processing", { number: processingIndex })}
            </div>
          )}
        </HStack>
        {isAdmin && (
          <>
            <hr />
            <div className="mb-4 font-semibold text-gray-600">
              {i18n.t("settings:import.danger_zone")}
            </div>
            <HStack space={20}>
              <Button
                type="primary"
                onClick={onObjectsDelete}
                color="red"
                icon={TrashSimple}
                className="max-w-fit">
                {i18n.t("settings:import.delete_all_objects")}
              </Button>
              <Button
                type="primary"
                onClick={onMaterialsDelete}
                color="red"
                icon={TrashSimple}
                className="max-w-fit">
                {i18n.t("settings:import.delete_all_materials")}
              </Button>
              {objectsDeleteModal.component}
              {materialsDeleteModal.component}
            </HStack>
          </>
        )}
      </VStack>
    </SettingsPageLayout>
  )
}

export default SettingsImport
