import { Center } from "@components/layout"
import { Button, LoadingIndicator } from "@components/shared"
import { SectionHeader } from "@components/shared/section-header"
import WorkOrderTag from "@components/work-order/work-order-tag"
import { openModal } from "@contexts/modal-context"
import { IMaintenanceTriggerTypeEnum, IWorkOrderStatusEnum } from "@elara/db"
import { orderBy } from "@elara/select"
import {
  IAssetDetailFragment,
  IMaintenanceFragment,
} from "@graphql/documents/fragments.generated"
import { useMaintenanceByAssetIdQuery } from "@graphql/documents/maintenance.generated"
import { useTaskDataViewQuery } from "@graphql/documents/work-order.generated"
import i18n from "@i18n"
import { Gauge, Pause, Plus, Repeat } from "@phosphor-icons/react"
import { useNavigateWithBackgroundLocation } from "@utils/location"

type AssetMaintenanceProps = { asset: IAssetDetailFragment }

const AssetMaintenance = ({ asset }: AssetMaintenanceProps) => {
  const navigateWithBackgroundLocation = useNavigateWithBackgroundLocation()

  const [maintenanceRes] = useMaintenanceByAssetIdQuery({
    variables: { assetId: asset.id },
  })
  const schedules = orderBy(maintenanceRes.data?.maintenance ?? [], {
    name: "asc",
  })

  const [tasksRes] = useTaskDataViewQuery({
    pause: !schedules,
    variables: { where: { maintenance_id: { _in: schedules.map((s) => s.id) } } },
  })
  const tasks = tasksRes.data?.work_order ?? []

  const handleOpenMaintenanceSchedule = (maintenance: IMaintenanceFragment) => {
    navigateWithBackgroundLocation(`/maintenance/${maintenance.id}`)
  }

  if (!asset || !tasks || maintenanceRes.fetching || tasksRes.fetching) {
    return (
      <Center>
        <LoadingIndicator size={24} />
      </Center>
    )
  }

  return (
    <div className="min-w-0 rounded-lg bg-white p-6">
      <div className="flex justify-between">
        <SectionHeader>{i18n.t("maintenance:title")}</SectionHeader>

        {schedules.length > 0 && (
          <Button
            type="tertiary"
            icon={Plus}
            onClick={() => {
              openModal("maintenance", { initialTaskValues: { asset_ids: [asset.id] } })
            }}>
            {i18n.t("common:create_token", {
              token: i18n.t("maintenance:fields.schedule"),
            })}
          </Button>
        )}
      </div>

      <div className="mt-3 flex flex-col space-y-1">
        {schedules.length === 0 ? (
          <div>
            <p className="text-sm text-gray-500">
              {i18n.t("assets:maintenance.empty_state.message")}
            </p>

            <Button
              type="secondary"
              className="mt-4"
              icon={Plus}
              onClick={() => {
                openModal("maintenance", { initialTaskValues: { asset_ids: [asset.id] } })
              }}>
              {i18n.t("assets:maintenance.empty_state.title")}
            </Button>
          </div>
        ) : (
          schedules.map((maintenance) => {
            const maintenanceTasks = tasks.filter(
              (t) => t.maintenance_id === maintenance.id
            )

            const timeTrigger = maintenance.triggers.find(
              (t) => t.type === IMaintenanceTriggerTypeEnum.Time
            )

            const meterReadingTrigger = maintenance.triggers.find(
              (t) => t.type === IMaintenanceTriggerTypeEnum.Meter
            )

            // Next Open Task
            const currentDate = new Date()
            const openTasks = maintenanceTasks.filter(
              (task) => task.status === IWorkOrderStatusEnum.Open
            )
            const nextOpenTask = openTasks.reduce((prevTask, currentTask) => {
              const prevTaskDate = new Date(prevTask.due_date!)
              const currentTaskDate = new Date(currentTask.due_date!)
              const prevTaskDiff = prevTaskDate.getTime() - currentDate.getTime()
              const currentTaskDiff = currentTaskDate.getTime() - currentDate.getTime()
              return prevTaskDiff > currentTaskDiff ? currentTask : prevTask
            }, openTasks[0])

            // Last Completed Task
            const completedTasks = maintenanceTasks.filter(
              (task) => task.status === IWorkOrderStatusEnum.Done
            )
            const lastCompletedTask = completedTasks.reduce((prevTask, currentTask) => {
              return prevTask.completed_at! > currentTask.completed_at!
                ? prevTask
                : currentTask
            }, completedTasks[0])

            return (
              <div key={maintenance.id} className="rounded border pb-5">
                <div
                  className="cursor-pointer rounded px-5 py-4 hover:bg-gray-50"
                  onClick={() => handleOpenMaintenanceSchedule(maintenance)}>
                  <div className="flex items-center gap-2">
                    <div className="font-medium">{maintenance.name}</div>
                    <span className="flex items-center gap-1 text-gray-500">
                      {maintenance.paused && <Pause />}
                      {meterReadingTrigger && <Gauge />}
                      {timeTrigger && <Repeat />}
                    </span>
                  </div>

                  <div className="mt-1 line-clamp-2 text-xs leading-relaxed text-gray-500">
                    {maintenance.description}
                  </div>
                </div>

                <div className="grid grid-cols-2 px-5 text-sm">
                  {/* Last Completed Task */}
                  {lastCompletedTask && (
                    <div className="flex flex-col gap-1">
                      <p className="text-xs font-medium text-gray-600">
                        {i18n.t("assets:overview.maintenance.last_completed_task")}
                      </p>
                      <WorkOrderTag workOrder={lastCompletedTask} showDueDate />
                    </div>
                  )}

                  {/* Next Open Task */}
                  {nextOpenTask && (
                    <div className="flex flex-col gap-1">
                      <p className="text-xs font-medium text-gray-600">
                        {i18n.t("assets:overview.maintenance.next_scheduled_task")}
                      </p>
                      <WorkOrderTag workOrder={nextOpenTask} showDueDate />
                    </div>
                  )}
                </div>
              </div>
            )
          })
        )}
      </div>
    </div>
  )
}

export default AssetMaintenance
