import { uuid } from "@elara/db"
import { useAssetNameByIdQuery } from "@graphql/documents/asset.generated"

export const AssetName = (props: { assetId: uuid }) => {
  const [queryRes] = useAssetNameByIdQuery({
    variables: { id: props.assetId },
    requestPolicy: "cache-only",
  })

  return <span>{queryRes?.data?.asset_by_pk?.name ?? ""}</span>
}
