import { IViewDataTypeEnum } from "@elara/db"
import {
  ICustomViewFragment,
  ISaveCustomViewConfigMutation,
  ISaveCustomViewConfigMutationVariables,
  ISaveViewConfigMutation,
  ISaveViewConfigMutationVariables,
  SaveCustomViewConfigDocument,
  SaveViewConfigDocument,
  useViewConfigByTypeQuery,
} from "@graphql/documents/custom-view.generated"
import { useCallbackRef, useDeepMemo } from "@hooks"
import { setStickyStateValue, StickyType, useStickyState } from "@hooks/use-sticky-state"
import { dequal } from "dequal"
import { useMemo } from "react"
import { useClient } from "urql"

import { DataViewConfiguration } from "../data-view-types"

function getLocalStorageId(configId: string) {
  return `DataView:Configuration:${configId}-v4`
}

export function resetDataViewConfiguration(configId: string) {
  setStickyStateValue(getLocalStorageId(configId), undefined)
}

export function useDataViewConfigStorage<Id extends string = string>(props: {
  customView: ICustomViewFragment | null
  defaultConfig: DataViewConfiguration<Id>
  configId: string
  dataType: IViewDataTypeEnum
  configStickyness: StickyType
}) {
  const client = useClient()
  const [queryRes, refetchConfig] = useViewConfigByTypeQuery({
    variables: { configId: props.configId },
    requestPolicy: "cache-first",
    pause: !!props.customView,
  })

  const [stickyConfig, setStickyConfig] = useStickyState<DataViewConfiguration<Id> | null>(
    null,
    getLocalStorageId(props.configId),
    props.configStickyness ?? "localStorage"
  )

  const memoizedConfig = useDeepMemo(() => {
    let config = { ...props.defaultConfig }
    let resettedConfig = { ...props.defaultConfig }
    if (props.customView) {
      Object.assign(config, props.customView.config)
      Object.assign(resettedConfig, props.customView.config)
    } else {
      const isFetchingStoredConfig = queryRes.fetching
      if (isFetchingStoredConfig) return null

      const storedConfig = queryRes.data?.view_config?.[0]
      // If we have a stored config, we use that as the base config
      if (storedConfig) {
        Object.assign(config, storedConfig.config)
        Object.assign(resettedConfig, storedConfig.config)
      }
    }
    // If we have stored something in local storage we use that as the base config
    if (stickyConfig) {
      Object.assign(config, stickyConfig)
    }
    return { config, resettedConfig }
  }, [props.customView, queryRes, props.configId, stickyConfig])

  // We only store the actual diff to the default config to make it easier to change the default config
  const diffedConfig = useMemo(() => {
    if (!memoizedConfig) return null
    const { config } = memoizedConfig
    const diffedConfig = Object.keys(config).reduce((c, key_) => {
      const key = key_ as keyof DataViewConfiguration<Id>
      if (!dequal(config[key], props.defaultConfig[key])) {
        return { ...c, [key]: config[key] }
      }
      return c
    }, {} as Partial<DataViewConfiguration<Id>>)
    return diffedConfig
  }, [memoizedConfig])

  const changeConfig = useCallbackRef(
    (update: (prevState: DataViewConfiguration<Id>) => DataViewConfiguration<Id>) => {
      setStickyConfig((state) => {
        if (!state) {
          if (!memoizedConfig) return null
          return update(memoizedConfig.config)
        }

        return update(state)
      })
    }
  )

  const storeConfig = useCallbackRef(async (config: DataViewConfiguration<Id>) => {
    if (!diffedConfig) return

    if (!props.customView) {
      await client.mutation<ISaveViewConfigMutation, ISaveViewConfigMutationVariables>(
        SaveViewConfigDocument,
        {
          id: queryRes.data?.view_config?.[0]?.id,
          dataType: props.dataType,
          config: diffedConfig,
          configId: props.configId,
        }
      )
      refetchConfig({ requestPolicy: "cache-and-network" })
    } else {
      await client.mutation<
        ISaveCustomViewConfigMutation,
        ISaveCustomViewConfigMutationVariables
      >(SaveCustomViewConfigDocument, { customViewId: props.customView.id, config })
    }
  })

  const resetConfig = useCallbackRef(async () => {
    if (!memoizedConfig) return
    // Clean sticky config
    setStickyConfig(null)
  })

  const isConfigPersisted = useMemo(
    () =>
      !!memoizedConfig && dequal(memoizedConfig?.config, memoizedConfig?.resettedConfig),
    [memoizedConfig]
  )

  return {
    config: memoizedConfig?.config ?? null,
    resettedConfig: memoizedConfig?.resettedConfig ?? null,
    changeConfig,
    storeConfig,
    resetConfig,
    isConfigPersisted,
  }
}
