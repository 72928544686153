import AssetInformation from "@components/asset/detail/overview/asset-information"
import AssetPhotos from "@components/asset/detail/overview/asset-photos"
import AssetSupport from "@components/asset/detail/overview/asset-support"

import { useAsset } from "./$id"

export function AssetInformationGeneral() {
  const { asset } = useAsset()
  return (
    <div className="space-y-6">
      <AssetPhotos asset={asset} />
      <AssetInformation asset={asset} />
      <AssetSupport asset={asset} />
    </div>
  )
}

export default AssetInformationGeneral
