import Card from "@components/analytics/card"
import Statistic from "@components/analytics/statistic"
import { AssetMultiSelect } from "@components/asset"
import { useAssetStateVariants } from "@components/asset/asset-state-variant-select"
import { Button } from "@components/shared"
import { Item } from "@components/shared/combobox-select"
import DateRangePicker, { getRange } from "@components/shared/date-range-picker"
import { MultiSelectPopover, MultiSelectSummary } from "@components/shared/multi-select"
import ScrollArea from "@components/shared/scroll-area"
import { useFeature } from "@contexts/feature-flag-context"
import { AssetStateLogSplitOperatingHours, uuid } from "@elara/db"
import { groupBy } from "@elara/select"
import {
  IAssetStateDowntimeFragment,
  useAssetStateDowntimeQuery,
} from "@graphql/documents/analytics.generated"
import { useAssetGroupsQuery } from "@graphql/documents/asset-group.generated"
import i18n from "@i18n"
import { DownloadSimple, Shapes, Strategy } from "@phosphor-icons/react"
import { DateRange, formatDate, formatDuration } from "@utils/date"
import { TreeLike } from "@utils/tree"
import { differenceInMinutes, getUnixTime, getWeek, setWeek, startOfWeek } from "date-fns"
import download from "downloadjs"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { RefObject, useImperativeHandle, useMemo, useState } from "react"
import { useOutletContext } from "react-router-dom"
import * as ss from "simple-statistics"

import { exportAssetDowntimeToCSV } from "../../../models/asset-state"
import { RefPayload } from ".."

const calculateDowntimeMinutes = (downtime: IAssetStateDowntimeFragment) => {
  const op = downtime.downtime_split_operating_hours as AssetStateLogSplitOperatingHours

  return op
    ? Math.trunc(op.hours * 60)
    : differenceInMinutes(
        downtime.ended_at ? new Date(downtime.ended_at) : new Date(),
        new Date(downtime.started_at),
        { roundingMethod: "ceil" }
      )
}

const getTimestampStartOfWeek = (weekNumber: number): number => {
  // Create a Date object for the current year, January 1st
  const startingDate = new Date(new Date().getFullYear(), 0, 1)

  // Set the week number
  const dateInWeek = setWeek(startingDate, weekNumber)

  // Get the start of the week
  const startOfWeekDate = startOfWeek(dateInWeek)

  // Convert to Unix timestamp
  const unixTimestamp = getUnixTime(startOfWeekDate) * 1000

  return unixTimestamp
}

const AssetDowntime = () => {
  const ref = useOutletContext<RefObject<RefPayload>>()

  const hasAdvancedAnalytics = useFeature("advanced_analytics")

  const [asset, setAsset] = useState<uuid[]>([])
  const [variants, setVariants] = useState<uuid[]>([])
  const [assetGroups, setAssetGroups] = useState<uuid[]>([])
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null)
  const [dateRange, setDateRange] = useState<DateRange>(getRange("last-30-days"))

  useImperativeHandle(ref, () => ({ ref: containerRef, dateRange: dateRange }), [
    containerRef,
    dateRange,
  ])

  const variantOptions = useAssetStateVariants()
  const [assetGroupQueryRes] = useAssetGroupsQuery({ requestPolicy: "cache-first" })
  const [assetDowntimeQueryRes] = useAssetStateDowntimeQuery({
    variables: {
      where: {
        ...(asset.length > 0 ? { asset_id: { _in: asset } } : {}),
        ...(assetGroups.length ? { asset: { group_id: { _in: assetGroups } } } : {}),
        ...(variants.length > 0 ? { asset_state_variant_id: { _in: variants } } : {}),
        ...(dateRange
          ? {
              started_at: {
                _gte: dateRange?.start.toISOString(),
                _lte: dateRange?.end?.toISOString(),
              },
            }
          : {}),
      },
    },
  })
  const assetGroupOptions: TreeLike<Item<string>>[] = (
    assetGroupQueryRes.data?.asset_group ?? []
  ).map((group) => ({
    value: group.id,
    label: group.name,
    searchValue: group.name,
  }))

  const data = (assetDowntimeQueryRes.data?.asset_state_log ?? []).map((log) => ({
    ...log,
    downtime_minutes: calculateDowntimeMinutes(log),
  }))

  const downtimesByObject = useMemo(() => {
    return (groupBy(data, { groupId: (row) => row.asset.id }) ?? []).map((group) => {
      const result = group.items

      result.sort((a, b) => a.started_at.localeCompare(b.started_at))

      return {
        ...group,
        items: result,
        total_downtime_minutes: result.reduce(
          (acc, curr) => acc + curr.downtime_minutes,
          0
        ),
      }
    })
  }, [data])

  const meanTimeBetweenFailures = (data: (typeof downtimesByObject)[number]) => {
    // Step 1: Identify the Total Time Period
    const earliestStart = Math.min(...data.items.map((item) => Date.parse(item.started_at)))
    const latestEnd = Math.max(
      ...data.items.map((item) => (item.ended_at ? Date.parse(item.ended_at) : Date.now()))
    )

    // Calculate the total time period in minutes
    const total_time_period = (latestEnd - earliestStart) / (1000 * 60)

    // Step 2: Calculate Total Downtime
    const total_downtime = data.items.reduce((sum, item) => sum + item.downtime_minutes, 0)

    // Step 3: Calculate Total Operational Time
    const total_operational_time = total_time_period - total_downtime

    // Step 4: Identify the Number of Failures
    const number_of_failures = data.items.length

    // Step 5: Calculate MTBF
    const mtbf = total_operational_time / number_of_failures

    return mtbf
  }

  const totalDowntimeMinutes = useMemo(
    () => downtimesByObject.reduce((acc, curr) => acc + curr.total_downtime_minutes, 0),
    [downtimesByObject]
  )

  const downtimesByObjectChartOptions = useMemo<Highcharts.Options>(() => {
    return {
      chart: { type: "column" },
      legend: { enabled: false },
      title: { text: i18n.t("analytics:assets.downtime.downtimes_per_object") },
      xAxis: { type: "category", crosshair: true },
      yAxis: {
        min: 0,
        allowDecimals: false,
        title: { text: "Downtime (in minutes)" },
      },
      tooltip: {
        formatter: function () {
          const duration = formatDuration(
            { hours: Math.floor(this.y! / 60), minutes: this.y! % 60 },
            { zero: true, delimiter: ", " }
          )

          return `<p><span style="font-size: 10px">${this.point.name}</span><br/>${duration}`
        },
      },
      series: [
        {
          type: "column",
          name: i18n.t("common:asset", { count: 2 }),
          colorByPoint: true,
          data: downtimesByObject.map((d) => ({
            drilldown: d.groupId!,
            name: d.items[0].asset.name,
            y: d.total_downtime_minutes,
          })),
        },
      ],
      drilldown: {
        breadcrumbs: { position: { align: "right" } },
        activeAxisLabelStyle: {
          color: "#333",
          fontWeight: "normal",
          textDecoration: "none",
        },
        series: downtimesByObject.map((d) => ({
          type: "column",
          id: d.groupId!,
          name: d.items[0].asset.name,
          data: d.items.map((item) => [
            formatDate(new Date(item.started_at), "PP"),
            item.downtime_minutes,
          ]),
        })),
      },
    } satisfies Highcharts.Options
  }, [downtimesByObject])

  const mtbfByObject = useMemo(() => {
    return downtimesByObject.map((d) => ({
      id: d.groupId!,
      name: d.items[0].asset.name,
      mtbf: meanTimeBetweenFailures(d),
    }))
  }, [downtimesByObject])

  const mtbfByObjectChartOptions = useMemo<Highcharts.Options>(() => {
    return {
      chart: { type: "column" },
      legend: { enabled: false },
      title: { text: i18n.t("analytics:assets.downtime.mean_time_between_failures") },
      xAxis: { type: "category" },
      yAxis: {
        min: 0,
        allowDecimals: false,
        title: { text: i18n.t("analytics:assets.downtime.mtbf_in_minutes") },
      },
      tooltip: {
        formatter: function () {
          const duration = formatDuration(
            { hours: Math.floor(this.y! / 60), minutes: this.y! % 60 },
            { zero: true, delimiter: ", " }
          )

          return `<p><span style="font-size: 10px">${this.point.name}</span><br/>${duration}`
        },
      },
      series: [
        {
          type: "column",
          name: i18n.t("common:asset", { count: 2 }),
          colorByPoint: true,
          data: mtbfByObject.map((d) => ({ name: d.name, y: d.mtbf })),
        },
      ],
    } satisfies Highcharts.Options
  }, [mtbfByObject])

  const downtimeByWeek = useMemo(() => {
    const groupedByWeek = groupBy(data, {
      groupId: (row) => getWeek(Date.parse(row.started_at)).toString(),
    }).map((group) => ({
      ...group,
      total_downtime_minutes: group.items.reduce(
        (acc, curr) => acc + curr.downtime_minutes,
        0
      ),
    }))

    groupedByWeek.sort((a, b) => a.groupId!.localeCompare(b.groupId!))

    return groupedByWeek
  }, [data])

  const linearRegression = useMemo(() => {
    if (downtimeByWeek.length === 0) return []

    // Convert the unix timestamps to number of days since the first timestamp
    const timestamps = downtimeByWeek.map((d) => getTimestampStartOfWeek(+d.groupId!))
    const minTimestamp = Math.min(...timestamps)
    const daysSinceStart = timestamps.map((t) => (t - minTimestamp) / (1000 * 60 * 60 * 24))

    // Prepare data for regression
    const points = daysSinceStart.map((day, i) => [
      day,
      downtimeByWeek[i].total_downtime_minutes,
    ])

    // Perform linear regression
    const regressionLine = ss.linearRegression(points)

    // Calculate the Unix timestamp for each of the next 30 days
    const lastDate = new Date(Math.max(...timestamps))
    let nextMonday = new Date(lastDate)
    nextMonday.setDate(lastDate.getDate() + ((1 + 7 - lastDate.getDay()) % 7))

    // Generate timestamps for the next 5 Mondays
    const futureTimestamps = Array.from({ length: 5 }, (_, i) => {
      const futureMonday = new Date(nextMonday)
      futureMonday.setDate(nextMonday.getDate() + i * 7)
      return +futureMonday
    })

    // Predict values for each of these timestamps
    const futurePredictions = {} as { [key: number]: number }

    futureTimestamps.forEach((timestamp) => {
      const day = (timestamp - minTimestamp) / (1000 * 60 * 60 * 24)
      futurePredictions[timestamp] = Math.max(
        Math.round(regressionLine.m * day + regressionLine.b),
        0
      )
    })

    return futurePredictions
  }, [downtimeByWeek])

  const downtimeByWeekChartOptions = useMemo<Highcharts.Options>(() => {
    return {
      legend: { enabled: false },
      title: { text: i18n.t("analytics:assets.downtime.downtimes_per_week") },
      xAxis: {
        type: "datetime",
        tickInterval: 7 * 24 * 3600 * 1000,
        labels: { format: "{value:%e. %b}" },
      },
      yAxis: { title: { text: i18n.t("analytics:assets.downtime.downtime_in_minutes") } },
      tooltip: {
        formatter: function () {
          const week = formatDate(new Date(this.x!), "w")
          const duration = formatDuration(
            { hours: Math.floor(this.y! / 60), minutes: this.y! % 60 },
            { zero: true, delimiter: ", " }
          )

          return `<p><span style="font-size: 10px">${i18n.t(
            "calendar:relative_dates.week_number",
            { week }
          )}</span><br/><b>${this.series.name}</b>: ${duration}`
        },
      },
      series: [
        {
          type: "area",
          name: i18n.t("analytics:metrics.asset_downtime.statistics.total_downtime"),
          pointInterval: 24 * 3600 * 1000,
          data: downtimeByWeek.map((d) => [
            getTimestampStartOfWeek(+d.groupId!),
            d.total_downtime_minutes,
          ]),
        },
        {
          type: "line",
          name: i18n.t("analytics:metrics.projected"),
          dashStyle: "Dash",
          allowPointSelect: false,
          marker: { enabled: false },
          data: Object.entries(linearRegression).map(([x, y]) => [Number(x), y]),
        },
      ],
    } satisfies Highcharts.Options
  }, [downtimeByWeek, linearRegression])

  return (
    <ScrollArea vertical>
      <div className="grid w-full gap-4 p-4">
        <div className="max-w-full md:max-w-lg">
          <span className="mb-1 block text-xs font-medium">
            {i18n.t("calendar:relative_dates.date_range")}
          </span>
          <DateRangePicker
            onRangeChange={setDateRange}
            onlyFixedDateRange={!hasAdvancedAnalytics}
          />
        </div>
        <div className="grid grid-cols-1 items-start gap-4 sm:grid-cols-2 md:grid-cols-6 lg:grid-cols-8">
          <div>
            <span className="mb-1 block text-xs font-medium">
              {i18n.t("assets:fields.group", { count: 1 })}
            </span>
            <MultiSelectPopover
              compact
              items={assetGroupOptions}
              valueToString={(v) => v}
              value={assetGroups}
              onChange={setAssetGroups}
              renderCompactSelectedValues={(values: TreeLike<Item<string>>[]) => (
                <MultiSelectSummary
                  firstItemLabel={values[0]?.searchValue}
                  countLabel={i18n.t("assets:fields.group", { count: values.length })}
                  limit={3}
                  mode="count"
                  items={values}
                  itemsToIcons={() => <Shapes className="!text-gray-700" />}
                  iconBgClass="bg-gray-100"
                  iconBorderClass="border-gray-100"
                />
              )}
            />
          </div>
          <div>
            <span className="mb-1 block text-xs font-medium">
              {i18n.t("common:asset", { count: 2 })}
            </span>
            <AssetMultiSelect
              compact
              value={asset}
              onChange={setAsset}
              includeChildrenOnSelect
            />
          </div>
          <div>
            <span className="mb-1 block text-xs font-medium">
              {i18n.t("assets:fields.state", { count: 2 })}
            </span>
            <MultiSelectPopover
              compact
              items={variantOptions.items}
              valueToString={(v) => v}
              value={variants}
              onChange={setVariants}
              renderCompactSelectedValues={(values: TreeLike<Item<string>>[]) => (
                <MultiSelectSummary
                  firstItemLabel={values[0]?.searchValue}
                  countLabel={i18n.t("assets:fields.state", { count: values.length })}
                  limit={3}
                  mode="count"
                  items={values}
                  itemsToIcons={() => <Strategy className="!text-gray-700" />}
                  iconBgClass="bg-gray-100"
                  iconBorderClass="border-gray-100"
                />
              )}
            />
          </div>
        </div>

        <hr />

        <div ref={setContainerRef} className="grid gap-4">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-semibold text-gray-700">
              {i18n.t("analytics:tabs.asset_downtime")}
            </h3>
            <Button
              type="secondary"
              icon={DownloadSimple}
              className="group-[.is-printing]:invisible"
              onClick={() => {
                // slice iso string to only contain date
                const filename = `elara-export-asset-downtime-${formatDate(
                  new Date(),
                  "yyyy-MM-dd"
                ).slice(0, 10)}.csv`
                const csv = exportAssetDowntimeToCSV(data)
                download(csv, filename, "text/csv")
              }}>
              {i18n.t("common:actions.export_to_csv")}
            </Button>
          </div>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <Card
              cardClassName="lg:col-span-2"
              title={i18n.t("analytics:metrics.asset_downtime.statistics.total_downtime")}>
              <Statistic className="text-center">
                {formatDuration(
                  {
                    hours: Math.floor(totalDowntimeMinutes / 60),
                    minutes: totalDowntimeMinutes % 60,
                  },
                  { zero: true, delimiter: ", " }
                )}
              </Statistic>
            </Card>

            <Card
              title={i18n.t(
                "analytics:metrics.asset_downtime.statistics.num_downtime_events"
              )}>
              <Statistic className="text-center">{data.length}</Statistic>
            </Card>

            <Card
              title={i18n.t(
                "analytics:metrics.asset_downtime.statistics.objects_with_downtime"
              )}>
              <Statistic className="text-center">{downtimesByObject.length}</Statistic>
            </Card>
          </div>

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6">
            <Card cardClassName="col-span-full px-4 lg:col-span-3">
              <HighchartsReact
                highcharts={Highcharts}
                options={downtimesByObjectChartOptions}
              />
            </Card>

            <Card cardClassName="col-span-full px-4 lg:col-span-3">
              <HighchartsReact highcharts={Highcharts} options={mtbfByObjectChartOptions} />
            </Card>

            <Card cardClassName="col-span-full px-4 lg:col-span-6">
              <HighchartsReact
                highcharts={Highcharts}
                options={downtimeByWeekChartOptions}
              />
            </Card>
          </div>
        </div>
      </div>
    </ScrollArea>
  )
}

export default AssetDowntime
