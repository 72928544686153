import useResizeObserver from "@react-hook/resize-observer"
import React from "react"

export const useElementSize = (target: HTMLElement | null) => {
  const [size, setSize] = React.useState<DOMRect>()

  React.useLayoutEffect(() => {
    const rect = target?.getBoundingClientRect()
    if (rect) setSize(rect)
  }, [target])

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize(entry.contentRect))
  return size
}
