import { Tooltip } from "@components/shared/tooltip"
import { useDisclosure } from "@hooks"
import i18n from "@i18n"
import { Clipboard } from "@phosphor-icons/react"

import Button from "./button"

export const CopyClipboard = (props: { text: string }) => {
  const tooltip = useDisclosure()

  const handleCopy = () => {
    navigator.clipboard.writeText(props.text)
    tooltip.onOpen()
  }

  return (
    <Tooltip
      asChild
      defaultOpen={false}
      delayDuration={10000}
      open={tooltip.isOpen}
      onOpenChange={tooltip.changeOpen}
      content={i18n.t("common:messages.copied_to_clipboard")}>
      <Button type="secondary" className="!px-5" onClick={handleCopy} icon={Clipboard}>
        <span>{i18n.t("common:copy")}</span>
      </Button>
    </Tooltip>
  )
}
