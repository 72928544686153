import LoadingSpinner from "@components/shared/loading-spinner"

const LoadingPage = () => (
  <div
    style={{
      backgroundColor: "white",
      position: "relative",
      minHeight: "100%",
      flex: "1",
    }}>
    <LoadingSpinner showLogo />
  </div>
)

export default LoadingPage
