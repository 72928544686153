import { WorkOrderStatusTag } from "@components/work-order/work-order-status"
import { useFeatureFlag } from "@contexts/feature-flag-context"
import { openModal } from "@contexts/modal-context"
import { IViewDataTypeEnum, IWorkOrderTypeEnum } from "@elara/db"
import { groupBy, orderBy } from "@elara/select"
import {
  ISearchEverywhereQuery,
  ISearchEverywhereQueryVariables,
  SearchEverywhereDocument,
} from "@graphql/documents/search.generated"
import { useTemplateListQuery } from "@graphql/documents/templates.generated"
import { useDisclosure } from "@hooks/use-disclosure"
import i18n from "@i18n"
import { CaretRight, Pause, Plus } from "@phosphor-icons/react"
import { Search } from "@resources/icons"
import {
  ActionCommandDef,
  CommandDef,
  PageCommandDef,
  useCommandStore,
  useRegisterCommands,
} from "@stores/command"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Icon } from "src/types"
import { useClient } from "urql"
import { useThrottledCallback } from "use-debounce"

import { AssetAvatar } from "./avatar"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./command"
import { useAvailableViews } from "./data-view/data-view-tabs.hooks"
import { Dialog } from "./dialog"
import { TeamIcon } from "./team-icons"

function useElaraCommands() {
  const navigate = useNavigate()
  const hasMaintenanceFeature = useFeatureFlag("maintenance")

  const _commands = useCommandStore((state) => state.commands)
  const commands = Object.keys(_commands)
    .sort()
    .flatMap((key) => _commands[key])

  const flatCommands = (searchResults: ActionCommandDef[]) =>
    commands.flatMap((c) => [
      c,
      ...((c as PageCommandDef).subcommands?.(searchResults)?.map((s) => ({
        ...s,
        label: (
          <span className="inline-flex items-center gap-x-1">
            <span>{s.label ?? s.searchValue}</span>{" "}
            <span className="text-gray-500">{c.group}</span>
          </span>
        ),
      })) ?? []),
    ])

  const taskViews = useAvailableViews([
    IViewDataTypeEnum.Workorder,
    IViewDataTypeEnum.Consumable,
    IViewDataTypeEnum.Asset,
    ...(hasMaintenanceFeature ? [IViewDataTypeEnum.Maintenance] : []),
  ])

  const [queryRes] = useTemplateListQuery({ requestPolicy: "cache-and-network" })
  const templates =
    queryRes.data?.templates?.sort((a, b) => a.name?.localeCompare(b.name ?? "") ?? 0) ?? []

  useRegisterCommands("global", [
    {
      type: "page",
      page: "view",
      value: "open_view",
      searchValue: i18n.t("command:open_view"),
      group: i18n.t("command:group.navigation"),
      icon: <Plus weight="bold" />,
      description: "Open a view",
      subcommands: () =>
        taskViews.map((v) => {
          let group: string

          if (v.dataType === IViewDataTypeEnum.Asset) {
            group = i18n.t("command:group.asset_views")
          } else if (v.dataType === IViewDataTypeEnum.Consumable) {
            group = i18n.t("command:group.consumable_views")
          } else if (v.dataType === IViewDataTypeEnum.Maintenance) {
            group = i18n.t("command:group.maintenance_views")
          } else {
            group = i18n.t("command:group.task_views")
          }

          return {
            type: "action",
            value: `open_view:"${v.name}"`,
            searchValue: `${i18n.t("command:open_view")} ${v.name}`,
            searchLabel: (
              <div className="flex items-center gap-x-1">
                {/* {v.name}{" "}
                <span className="ml-1 inline-flex items-center text-sm text-gray-500">
                  {i18n.t("common:view_one")}{" "}
                  <CaretRight weight="bold" className="!h-3 !w-3" /> {group}{" "}
                </span> */}
                {i18n.t("command:open_view")}{" "}
                <CaretRight className="!h-3 !w-3 opacity-50" /> {v.name}
              </div>
            ),
            icon:
              v.type === "default" ? (
                <>{v.icon ? React.createElement(v.icon as Icon) : null}</>
              ) : (
                <TeamIcon {...v.icon} />
              ),

            group,
            description: "Open the view " + v.name,

            action() {
              navigate(`/view/${v.id}`)
            },
          } satisfies ActionCommandDef
        }),
    },
    {
      type: "page",
      searchValue: i18n.t("command:find_task"),
      page: "find_task",
      value: "find_task_page",
      group: i18n.t("command:group.task"),
      icon: <Search />,
      description: "Create a new task",
      subcommands: (searchResults) =>
        searchResults.filter((a) => a.value.startsWith("open_task:")),
    },
    {
      type: "action",
      value: "new_task",
      searchValue: i18n.t("command:new_task"),
      action: () => {
        openModal("select_template")
      },
      group: i18n.t("command:group.task"),
      icon: <Plus weight="bold" />,
      description: "Create a new task",
    },
    {
      type: "page",
      page: "select_template",
      value: "new_task_from_template",
      searchValue: i18n.t("command:new_task_from_template"),
      group: i18n.t("command:group.task"),
      icon: <Plus weight="bold" />,
      description: "Create a new task from a template",
      subcommands: () =>
        templates.map((template) => {
          return {
            type: "action",
            action: () => {
              openModal("task_create", {
                initialValues: {
                  ...template.work_order,

                  type: IWorkOrderTypeEnum.WorkOrder,
                  templated_used_id: template.id,
                },
                template: {
                  id: template.id,
                  name: template.name,
                  allow_modification_in_task_form:
                    !!template.allow_modification_in_task_form,
                },
              })
            },
            value: `new_task_from_template:"${template.name}"`,
            searchValue: `${i18n.t("command:group.template")} ${template.name}`,
            label: template.name,
            searchLabel: (
              <span>
                {template.name}{" "}
                <span className="text-gray-500">{i18n.t("command:group.template")}</span>
              </span>
            ),
            description: "Create a new task using the template " + template.name,
            group: i18n.t("command:group.template"),
          }
        }),
    },
    {
      type: "page",
      searchValue: i18n.t("command:find_object"),
      page: "find_object",
      value: "find_object_page",
      group: i18n.t("command:group.object"),
      icon: <Search />,
      description: "Find an object",
      subcommands: (searchResults) =>
        searchResults.filter((a) => a.value.startsWith("open_object:")),
    },
    {
      type: "action",
      value: "new_object",
      searchValue: i18n.t("command:new_object"),
      action: () => {
        openModal("object_create")
      },
      group: i18n.t("command:group.object"),
      icon: <Plus weight="bold" />,
      description: "Create a new object",
    },

    ...((hasMaintenanceFeature
      ? [
          {
            type: "page",
            searchValue: i18n.t("command:find_maintenance"),
            page: "find_maintenance",
            value: "find_maintenance_page",
            group: i18n.t("command:group.maintenance"),
            icon: <Search />,
            description: "Find a maintenance schedule",
            subcommands: (searchResults) =>
              searchResults.filter((a) => a.value.startsWith("open_maintenance:")),
          },
          {
            type: "action",
            value: "new_maintenance",
            searchValue: i18n.t("command:new_maintenance"),
            action: () => {
              openModal("maintenance")
            },
            group: i18n.t("command:group.maintenance"),
            icon: <Plus weight="bold" />,
            description: "Create a new maintenance schedule",
          },
        ]
      : []) as CommandDef[]),
  ])

  return { commands, flatCommands }
}

function useSearchResults(search: string) {
  const client = useClient()
  const navigate = useNavigate()
  const hasMaintenanceFeature = useFeatureFlag("maintenance")

  const [searchResults, setSearchResults] = React.useState<ActionCommandDef[]>([])

  const buildTaskCmd = (
    task: ISearchEverywhereQuery["work_order"][number]
  ): ActionCommandDef => ({
    type: "action",
    meta: { task },
    action: () => navigate("/task/" + task.id),
    value: `open_task:"${task.id}"`,
    searchValue: `#${task.work_order_number} ${task.work_order_number} ${task.name}`,
    label: (
      <span>
        <span className="mr-1 text-gray-500">#{task.work_order_number}</span>
        {task.name}
      </span>
    ),
    icon: (
      <div className="flex items-center justify-center">
        <WorkOrderStatusTag status={task.status} short />
      </div>
    ),
    description: "",
    group: i18n.t("command:group.task"),
  })

  const buildAssetCmd = (
    object: ISearchEverywhereQuery["search_asset"][number]
  ): ActionCommandDef => ({
    type: "action",
    meta: { object },
    action: () => {
      navigate("/object/" + object.id)
    },
    value: `open_object:"${object.id}"`,
    searchValue: `${object.name} ${object.public_id}`,
    label: (
      <span>
        {object.name} <span className="ml-1 text-gray-500">{object.public_id}</span>
      </span>
    ),
    icon: <AssetAvatar avatar={object.avatar} />,
    description: "",
    group: i18n.t("command:group.object"),
  })

  const buildMaintenanceCmd = (
    maintenance: ISearchEverywhereQuery["search_maintenance"][number]
  ): ActionCommandDef => ({
    type: "action",
    meta: { maintenance },
    action: () => {
      navigate("/maintenance/" + maintenance.id)
    },
    value: `open_maintenance:"${maintenance.id}"`,
    searchValue: maintenance.name,
    label: (
      <div className="flex items-center gap-1">
        {maintenance.paused && <Pause />}
        <span>{maintenance.name}</span>
      </div>
    ),
    description: maintenance.description ?? "",
    group: i18n.t("command:group.maintenance"),
  })

  const queryForTasksAndObjects = useThrottledCallback(async (search: string) => {
    let workOrderNumber = parseInt(search)

    if (isNaN(workOrderNumber) && search.startsWith("#")) {
      workOrderNumber = parseInt(search.split("#")?.[1] ?? "")
    }

    if (isNaN(workOrderNumber)) {
      workOrderNumber = 0
    }

    const res = await client
      .query<ISearchEverywhereQuery, ISearchEverywhereQueryVariables>(
        SearchEverywhereDocument,
        { text: search, workOrderNumber, assetPublicId: { _ilike: `%${search}%` } }
      )
      .toPromise()

    const results: ActionCommandDef[] = []

    res?.data?.work_order?.forEach((task) => {
      results.push(buildTaskCmd(task))
    })

    res.data?.search_work_order?.forEach((task) => {
      const cmd = buildTaskCmd(task)

      if (!results.some((r) => r.value === cmd.value)) {
        return results.push(cmd)
      }
    })

    res.data?.asset?.forEach((object) => {
      return results.push(buildAssetCmd(object))
    })
    res.data?.search_asset?.forEach((object) => {
      const cmd = buildAssetCmd(object)
      if (!results.some((r) => r.value === cmd.value)) {
        return results.push(cmd)
      }
    })

    if (hasMaintenanceFeature) {
      res.data?.search_maintenance?.forEach((maintenance) => {
        return results.push(buildMaintenanceCmd(maintenance))
      })
    }

    setSearchResults(results)
  }, 400)

  useEffect(() => {
    if (!search) {
      queryForTasksAndObjects.cancel()
      setSearchResults([])
    } else {
      queryForTasksAndObjects(search)
    }
    return () => {}
  }, [search])

  return { searchResults }
}

export function ElaraCommand(props: { close: () => void; initialPage?: string | null }) {
  const [search, setSearch] = React.useState("")
  const [pages, setPages] = React.useState<string[]>(
    props.initialPage ? [props.initialPage] : []
  )
  const page = pages[pages.length - 1]

  const { commands, flatCommands } = useElaraCommands()
  const { searchResults } = useSearchResults(search)

  const commandsGroupedByGroup = groupBy(commands, {
    groupId: (d) => d.group ?? null,
  })

  function onCmdSelect(cmd: CommandDef) {
    return () => {
      if (cmd.type === "page") {
        setSearch("")
        setPages((pages) => [...pages, cmd.page])
      } else {
        cmd.action()
        props.close()
      }
    }
  }

  const pageCmd = commands.find(
    (c) => c.type === "page" && c.page === page
  ) as PageCommandDef | null

  return (
    <Command
      className="rounded-lg border shadow-md"
      onKeyDown={(e) => {
        // Escape goes to previous page
        // Backspace goes to previous page when search is empty
        if (page && (e.key === "Escape" || (e.key === "Backspace" && !search))) {
          e.preventDefault()
          setPages((pages) => pages.slice(0, -1))
        }

        if (e.key === "ArrowDown" || e.key === "ArrowUp") {
          e.stopPropagation()
        }
      }}>
      <CommandInput
        placeholder={pageCmd ? pageCmd.searchValue : i18n.t("command:search_placeholder")}
        value={search}
        onValueChange={setSearch}
      />
      <CommandList className="pb-3">
        <CommandEmpty>{i18n.t("command:no_results_found")}</CommandEmpty>
        {!page &&
          !search &&
          commandsGroupedByGroup.map((group) => (
            <CommandGroup key={group.groupId} heading={group.groupId}>
              {group.items.map((item) => (
                <CommandItem
                  key={item.value}
                  value={item.searchValue}
                  onSelect={onCmdSelect(item)}>
                  {item.icon && <span className="mr-2">{item.icon}</span>}
                  <span className="truncate">{item.label ?? item.searchValue}</span>
                </CommandItem>
              ))}
            </CommandGroup>
          ))}
        {!!search && !page && (
          <>
            {groupBy(flatCommands([]), {
              groupId: (d) => d.group ?? null,
            }).map((group) => (
              <CommandGroup key={group.groupId} heading={group.groupId}>
                {group.items.map((item) => (
                  <CommandItem
                    key={item.value}
                    value={item.searchValue}
                    onSelect={onCmdSelect(item)}>
                    {item.icon && <span className="mr-2">{item.icon}</span>}
                    <span className="truncate">
                      {item.searchLabel ?? item.label ?? item.searchValue}
                    </span>
                  </CommandItem>
                ))}
              </CommandGroup>
            ))}

            <CommandGroup heading={`Search results for "${search}"`}>
              {searchResults.map((item) => (
                <CommandItem
                  key={item.value}
                  value={item.searchValue}
                  onSelect={onCmdSelect(item)}>
                  {item.icon && <span className="mr-2">{item.icon}</span>}
                  <span className="truncate">
                    {item.searchLabel ?? item.label ?? item.searchValue}
                  </span>
                </CommandItem>
              ))}
            </CommandGroup>
          </>
        )}
        {pageCmd &&
          groupBy(pageCmd.subcommands(searchResults), { groupId: (d) => d.group })
            .sort((a, b) => a.groupId?.localeCompare(b.groupId!) ?? 0)
            .map(({ groupId, items }) => (
              <CommandGroup heading={groupId} key={groupId}>
                {orderBy(items, { searchValue: "asc" }).map((item) => (
                  <CommandItem
                    key={item.value}
                    value={item.searchValue}
                    onSelect={onCmdSelect(item)}>
                    {item.icon && <span className="mr-2">{item.icon}</span>}
                    <span className="truncate">{item.label ?? item.searchValue}</span>
                  </CommandItem>
                ))}
              </CommandGroup>
            ))}
      </CommandList>
    </Command>
  )
}

export function openElaraCommandDialog(page?: string) {
  document.dispatchEvent(new CustomEvent("open-elara-command-dialog", { detail: { page } }))
}

export function ElaraCommandDialog() {
  const [initialPage, setInitialPage] = React.useState<string | null>(null)
  const disclosure = useDisclosure({
    onClose: () => setInitialPage(null),
  })

  // Toggle the menu when Ctrl/Command + K is pressed
  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && (e.key === "K" || e.key === "k")) {
        disclosure.onOpen()
      }
    }

    const ev = (e: Event) => {
      if (e instanceof CustomEvent && e.detail?.page) setInitialPage(e.detail.page)

      disclosure.onOpen()
    }

    document.addEventListener("open-elara-command-dialog", ev)
    document.addEventListener("keydown", down)

    return () => {
      document.removeEventListener("keydown", down)
      document.removeEventListener("open-elara-command-dialog", ev)
    }
  }, [])

  return (
    <Dialog
      contentAsChild
      position="top"
      positionOffset={128}
      isOpen={disclosure.isOpen}
      onOpenChange={disclosure.changeOpen}
      className="p-0 sm:max-w-lg sm:p-0 lg:max-w-xl xl:max-w-2xl">
      <ElaraCommand close={disclosure.onClose} initialPage={initialPage} />
    </Dialog>
  )
}
