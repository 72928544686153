import classNames from "classnames"

export type LoadingIndicatorProps = {
  size: number
  className?: string
  color?: string
  display?: string
}

export const LoadingIndicator = (props: LoadingIndicatorProps) => (
  <div
    key="animationDelay"
    className={classNames(props.className, "relative items-center justify-center")}
    style={{
      display: props.display ?? "inline-flex",
      width: props.size,
      height: props.size,
    }}>
    <div
      className="absolute m-0.5 block animate-spin rounded-full border-2"
      style={{
        animationDelay: "0",
        width: props.size - 2,
        height: props.size - 2,
        opacity: 0.75,
        borderColor: `${props.color ?? "currentcolor"} transparent transparent transparent`,
      }}></div>
    <div
      className="absolute m-0.5 block animate-spin rounded-full border-2"
      style={{
        animationDelay: "-0.5s",
        width: props.size - 2,
        height: props.size - 2,
        opacity: 0.25,
        borderColor: `${props.color ?? "currentcolor"}`,
      }}></div>
  </div>
)

export default LoadingIndicator
