import toast from "@components/shared/toast"
import { IPermissionScopeEnum } from "@elara/db"
import { orderBy } from "@elara/select"
import { IWorkOrderDataViewFragment } from "@graphql/documents/fragments.generated"
import {
  IUpdateSubtaskOrderMutation,
  IUpdateSubtaskOrderMutationVariables,
  UpdateSubtaskOrderDocument,
} from "@graphql/documents/work-order.generated"
import { DragDropContext, Droppable, DropResult } from "@hello-pangea/dnd"
import { usePermissionScope } from "@hooks"
import { calculateSortOrder } from "@utils/sorting"
import React from "react"
import { useClient } from "urql"

import { SubtaskItem } from "./subtask-item"

export type SubtaskListProps = {
  workOrder: IWorkOrderDataViewFragment
  placeholder?: string
}

export const SubtaskList = ({
  workOrder,
  ...props
}: React.PropsWithChildren<SubtaskListProps>) => {
  const client = useClient()
  const editScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)

  const subtasks = orderBy(workOrder.subtasks || [], { sort_order: "asc" })

  return (
    <div>
      <DragDropContext
        onDragEnd={async (result: DropResult) => {
          const startIndex = result.source.index
          const targetIndex = result.destination?.index
          if (result.reason === "DROP" && targetIndex !== undefined && subtasks) {
            const newSortOrder = calculateSortOrder(
              startIndex,
              targetIndex,
              subtasks.map((s) => s.sort_order ?? 0)
            )
            const res = await client
              .mutation<IUpdateSubtaskOrderMutation, IUpdateSubtaskOrderMutationVariables>(
                UpdateSubtaskOrderDocument,
                {
                  subtaskId: subtasks[startIndex]?.id,
                  sortOrder: newSortOrder,
                },
                editScope.context()
              )
              .toPromise()

            if (res.error)
              return toast.error("Die Reihenfolge konnte nicht geändert werden.")
          }
        }}>
        <Droppable droppableId="subtask">
          {(droppableProvided) => (
            <div
              className="flex flex-col gap-1"
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}>
              {subtasks?.map((subtask, index) => (
                <SubtaskItem subtask={subtask} index={index} key={index} />
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="flex justify-between">{props.children}</div>
    </div>
  )
}

export default SubtaskList
