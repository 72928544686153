"use client"

import * as TabsPrimitive from "@radix-ui/react-tabs"
import classNames from "classnames"
import * as React from "react"

const ToggleTabs = TabsPrimitive.Root

const ToggleTabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={classNames(
      "inline-flex items-center justify-center rounded-md bg-gray-100 p-1 ",
      className
    )}
    {...props}
  />
))

const ToggleTabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    className={classNames(
      "inline-flex min-w-[100px] items-center justify-center rounded-[0.185rem] px-3 py-1.5 text-sm font-medium text-gray-700 transition-all disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-white data-[state=active]:text-gray-900 data-[state=active]:shadow-sm ",
      className
    )}
    {...props}
    ref={ref}
  />
))

const ToggleTabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content className={classNames(className)} {...props} ref={ref} />
))

export { ToggleTabs, ToggleTabsContent, ToggleTabsList, ToggleTabsTrigger }
