import {
  IMutationRootDeleteConsumableByPkArgs,
  IMutationRootDeleteConsumableLogByPkArgs,
  IMutationRootDeleteConsumableXUploadByPkArgs,
  IMutationRootInsertConsumableLogOneArgs,
  IMutationRootInsertConsumableOneArgs,
  IMutationRootInsertConsumableXUploadOneArgs,
} from "@elara/db"
import {
  ConsumableDocument,
  ConsumablesDocument,
  IConsumableQuery,
  IConsumableQueryVariables,
  IConsumablesQuery,
  IConsumablesQueryVariables,
  IInsertConsumableDocumentMutation,
} from "@graphql/documents/consumable.generated"
import {
  IConsumableFragment,
  IConsumableLogFragment,
} from "@graphql/documents/fragments.generated"
import { OptimisticMutationResolver, UpdateResolver } from "@urql/exchange-graphcache"
import { sortDate } from "@utils"

type Mutations = {
  [fieldName: string]: UpdateResolver
}

type Optimistic = {
  [fieldName: string]: OptimisticMutationResolver
}

const optimistic: Optimistic = {}

const mutations: Mutations = {
  insert_consumable_one(
    result: { insert_consumable_one: IConsumableFragment },
    args: IMutationRootInsertConsumableOneArgs,
    cache
  ) {
    const consumable = result.insert_consumable_one

    if (!consumable) return

    cache.updateQuery<IConsumablesQuery, IConsumablesQueryVariables>(
      {
        query: ConsumablesDocument,
      },
      (data) => {
        if (!data || !data.consumable) return data
        if (!data.consumable.some((c) => c.id === consumable?.id)) {
          data.consumable.push(consumable)
          data.consumable.sort((a, b) => a.name.localeCompare(b.name))
        }
        return data
      }
    )
  },

  delete_consumable_by_pk(_parent, args: IMutationRootDeleteConsumableByPkArgs, cache) {
    cache.updateQuery<IConsumablesQuery>({ query: ConsumablesDocument }, (data) => {
      if (data) {
        data.consumable = data.consumable.filter((c) => c.id !== args.id)
      }
      return data
    })
  },

  insert_consumable_log_one(
    result: { insert_consumable_log_one: IConsumableLogFragment },
    args: IMutationRootInsertConsumableLogOneArgs,
    cache
  ) {
    const consumableLog = result.insert_consumable_log_one

    if (!consumableLog) return

    cache.updateQuery<IConsumableQuery, IConsumableQueryVariables>(
      {
        query: ConsumableDocument,
        variables: { id: consumableLog.consumable?.id || "" },
      },
      (data) => {
        if (!data || !data.consumable_log) return data
        if (!data.consumable_log.some((c) => c.id === consumableLog?.id)) {
          data.consumable_log.push(consumableLog)
          data.consumable_log.sort((a, b) =>
            sortDate(new Date(b.created_at), new Date(a.created_at))
          )
        }
        return data
      }
    )
  },

  delete_consumable_log_by_pk(
    result: { delete_consumable_log_by_pk: IConsumableLogFragment },
    args: IMutationRootDeleteConsumableLogByPkArgs,
    cache
  ) {
    const consumableLog = result.delete_consumable_log_by_pk

    if (!consumableLog) return

    cache.updateQuery<IConsumableQuery>(
      {
        query: ConsumableDocument,
        variables: { id: consumableLog.consumable?.id || "" },
      },
      (data) => {
        if (data) {
          data.consumable_log = data.consumable_log.filter((c) => c.id !== args.id)
        }
        return data
      }
    )
  },

  insert_consumable_x_upload_one(
    result: IInsertConsumableDocumentMutation,
    args: IMutationRootInsertConsumableXUploadOneArgs,
    cache
  ) {
    cache.updateQuery<IConsumableQuery, IConsumableQueryVariables>(
      { query: ConsumableDocument, variables: { id: args.object.consumable_id! } },
      (data) => {
        if (!data?.consumable_by_pk || !result?.insert_consumable_x_upload_one) return data

        const uploads = data.consumable_by_pk.uploads

        if (
          uploads.find(
            ({ upload }) => upload!.id === result.insert_consumable_x_upload_one!.upload!.id
          ) === undefined
        ) {
          uploads.unshift(result?.insert_consumable_x_upload_one)
        }

        return {
          ...data,
          consumable_by_pk: {
            ...data.consumable_by_pk,
            uploads,
          },
        }
      }
    )
  },

  delete_consumable_x_upload_by_pk(
    _result,
    args: IMutationRootDeleteConsumableXUploadByPkArgs,
    cache
  ) {
    if (args.consumable_id && args.upload_id) {
      cache.updateQuery<IConsumableQuery>(
        { query: ConsumableDocument, variables: { id: args.consumable_id } },
        (data) => {
          if (data && data.consumable_by_pk) {
            data.consumable_by_pk.uploads = data.consumable_by_pk.uploads.filter(
              (u) => u.upload?.id !== args.upload_id
            )
          }

          return data
        }
      )
    }
  },
}

export default {
  optimistic,
  mutations,
}
