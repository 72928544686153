import { AssetGroupSingleSelect, AssetMultiSelect } from "@components/asset"
import { AssetWithParents } from "@components/asset/asset-with-parents"
import { AvailabilityBar } from "@components/asset/availability-bar"
import { ActionMenu, Button } from "@components/shared"
import DateRangePicker, { getRange } from "@components/shared/date-range-picker"
import LoadingSpinner from "@components/shared/loading-spinner"
import ScrollArea from "@components/shared/scroll-area"
import { useFeature } from "@contexts/feature-flag-context"
import { getUser } from "@contexts/user-context"
import { uuid } from "@elara/db"
import { orderBy } from "@elara/select"
import { useAssetReliabilityAnalyticsQuery } from "@graphql/documents/analytics.generated"
import { IAssetTagFragment } from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { AvailabilityData } from "@pages/object/$id.insight.reliability"
import { DownloadSimple } from "@phosphor-icons/react"
import { formatLocaleNumber } from "@utils"
import { DateRange, formatDate } from "@utils/date"
import { endOfMinute, min } from "date-fns"
import download from "downloadjs"
import { parse } from "json2csv"
import { Fragment, RefObject, useImperativeHandle, useMemo, useState } from "react"
import { useOutletContext } from "react-router-dom"

import { RefPayload } from ".."

const AssetDowntime = () => {
  const ref = useOutletContext<RefObject<RefPayload>>()

  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null)
  const [filter, setFilter] = useState<{
    assetGroup: uuid | null
    assets: uuid[]
    dateRange: DateRange
  }>({
    assetGroup: null,
    assets: [],
    dateRange: getRange("last-30-days"),
  })

  useImperativeHandle(ref, () => ({ ref: containerRef, dateRange: filter.dateRange }), [
    containerRef,
    filter.dateRange,
  ])

  const [query] = useAssetReliabilityAnalyticsQuery({
    requestPolicy: "cache-and-network",
    variables: {
      asset_group: filter.assetGroup,
      asset_ids: filter.assets.length ? filter.assets : null,
      start: filter.dateRange.start.toISOString(),
      end: min([
        filter.dateRange.end ?? endOfMinute(new Date()),
        endOfMinute(new Date()),
      ]).toISOString(),
      location_id: getUser().location.id,
      where: {
        ...(filter.assetGroup ? { group_id: { _eq: filter.assetGroup } } : {}),
        ...(filter.assets.length ? { id: { _in: filter.assets } } : {}),
      },
    },
  })

  const assetInfos = query.data?.asset ?? []
  const availabilityAnalytics = (query.data?.location_by_pk?.asset_availability_analytics ??
    []) as {
    data: AvailabilityData[]
    asset_id: string
  }[]

  const hasAdvancedAnalytics = useFeature("advanced_analytics")
  const data = useMemo(() => {
    const collect: Record<
      string,
      { asset?: IAssetTagFragment; availability?: AvailabilityData[] }
    > = {}
    for (const asset of assetInfos) {
      collect[asset.id] = { asset }
    }
    for (const availability of availabilityAnalytics) {
      if (collect[availability.asset_id]) {
        collect[availability.asset_id].availability = availability.data.sort((a, b) =>
          a.day.localeCompare(b.day)
        )
      }
    }

    return orderBy(
      Object.values(collect).filter((v) => v.asset && v.availability) as {
        asset: IAssetTagFragment
        availability: AvailabilityData[]
      }[],
      { asset: { search_value: "asc" } }
    )
  }, [assetInfos, availabilityAnalytics])

  const getCSV = (
    value:
      | "operating_hours"
      | "downtime"
      | "unplanned_downtime"
      | "planned_downtime"
      | "availability"
  ) => {
    const rows = data.map((d) => {
      const row: Record<string, string> = {}
      row[i18n.t("asset_one")] = d.asset.name
      row[i18n.t("assets:fields.public_id")] = d.asset.public_id

      for (const item of d.availability) {
        let v = ""
        if (value === "operating_hours") {
          v = formatLocaleNumber(item.operating_hours * 60)
        } else if (value === "downtime") {
          v = formatLocaleNumber(
            (item.unplanned_downtime_hours + item.planned_downtime_hours) * 60
          )
        } else if (value === "unplanned_downtime") {
          v = formatLocaleNumber(item.unplanned_downtime_hours * 60)
        } else if (value === "planned_downtime") {
          v = formatLocaleNumber(item.planned_downtime_hours * 60)
        } else if (value === "availability") {
          v = formatLocaleNumber(item.availability, { style: "percent" })
        }

        row[formatDate(new Date(item.day), "P")] = v
      }
      return row
    })
    download(
      parse(rows, { header: true }),
      `asset-reliability-${new Date().toISOString()}-${value}.csv`,
      "text/csv;charset=utf-8"
    )
  }
  if (!assetInfos.length) return <LoadingSpinner />

  return (
    <div className="flex min-h-0 w-full flex-1 flex-col gap-4 p-4">
      <div className="max-w-full md:max-w-lg">
        <span className="mb-1 block text-xs font-medium">
          {i18n.t("calendar:relative_dates.date_range")}
        </span>
        <DateRangePicker
          range={filter.dateRange}
          onlyFixedDateRange={!hasAdvancedAnalytics}
          onRangeChange={(dateRange) => setFilter((f) => ({ ...f, dateRange }))}
        />
      </div>

      <div className="grid grid-cols-1 items-start gap-4 sm:grid-cols-2 md:grid-cols-6 lg:grid-cols-8">
        <div className="col-span-2">
          <span className="mb-1 block text-xs font-medium">
            {i18n.t("assets:fields.group", { count: 1 })}
          </span>
          <AssetGroupSingleSelect
            isClearable
            value={filter.assetGroup}
            onChange={(assetGroup) => setFilter((f) => ({ ...f, assetGroup }))}
          />
        </div>
        <div>
          <span className="mb-1 block text-xs font-medium">
            {i18n.t("common:asset", { count: 2 })}
          </span>
          <AssetMultiSelect
            compact
            value={filter.assets}
            onChange={(assets) => setFilter((f) => ({ ...f, assets }))}
            includeChildrenOnSelect
          />
        </div>
      </div>

      <hr />

      <div ref={setContainerRef} className="grid gap-4">
        <div className="flex items-center justify-between">
          <div className="sticky -top-1 bg-white/90 py-2 pl-2 font-semibold text-gray-600 backdrop-blur-sm">
            {i18n.t("assets:tabs.reliability")}
          </div>

          <ActionMenu
            items={[
              {
                label: i18n.t("assets:state.fields.operating_hours"),
                key: "operating_hours",
                action: () => getCSV("operating_hours"),
              },
              {
                label: i18n.t("assets:state.reliablity.downtime_other"),
                key: "downtime",
                action: () => getCSV("downtime"),
              },
              {
                label: i18n.t("assets:state.fields.unplanned_downtime_hours"),
                key: "unplanned_downtime",
                action: () => getCSV("unplanned_downtime"),
              },
              {
                label: i18n.t("assets:state.fields.planned_downtime_hours"),
                key: "planned_downtime",
                action: () => getCSV("planned_downtime"),
              },
              {
                label: i18n.t("assets:state.fields.availability"),
                key: "availability",
                action: () => getCSV("availability"),
              },
            ]}>
            <Button icon={DownloadSimple} className="group-[.is-printing]:invisible">
              {i18n.t("common:actions.export_to_csv")}
            </Button>
          </ActionMenu>
        </div>

        <ScrollArea vertical viewportAsChild>
          <div className="mt-2 grid grid-cols-[200px_minmax(0,_1fr)_auto] items-center gap-y-2">
            <div className="sticky -top-1 col-start-2 flex items-center justify-between bg-white/90 py-1 text-sm font-medium text-gray-600 backdrop-blur-sm">
              <span>{formatDate(filter.dateRange.start, "P")}</span>
              <span>{formatDate(filter.dateRange.end ?? new Date(), "P")}</span>
            </div>
            <div className="sticky -top-1 bg-white/90 py-1 pl-1 pr-3 text-sm font-medium text-gray-600 backdrop-blur-sm">
              {i18n.t("analytics:assets.reliability.availability")}
            </div>
            {data.map((d) => {
              const totalTrackedOperating = d.availability.reduce(
                (s, a) => s + a.scheduled_operating_hours - a.do_not_track_hours,
                0
              )
              const totalOperating = d.availability.reduce(
                (s, a) => s + a.operating_hours,
                0
              )

              return (
                <Fragment key={d.asset.id}>
                  <AssetWithParents asset={d.asset} showAvatar withLink />
                  <AvailabilityBar data={d.availability} />

                  <span className="text-center text-sm font-medium text-gray-600">
                    {Math.round((totalOperating / totalTrackedOperating) * 10000) / 100}%
                  </span>
                </Fragment>
              )
            })}
          </div>
        </ScrollArea>
      </div>
    </div>
  )
}

export default AssetDowntime
