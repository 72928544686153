// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import { UserTagFragmentDoc, LocationInviteFragmentDoc, UserProfileFragmentDoc } from './fragments.generated';
import { AvatarFragmentDoc, UploadDataFragmentDoc } from './upload.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IApiKeysQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IApiKeysQuery = { location_api_key: Array<{ __typename: 'location_api_key', id: uuid, key_preview: string | null, last_used_at: string, user: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } | null }> };

export type ILocationInvitesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ILocationInvitesQuery = { location_invite: Array<{ __typename: 'location_invite', id: uuid, location_id: uuid, expires_at: string, invite_code: string, approval_necessary: boolean, permission_role: { __typename: 'permission_role', id: uuid, name: string } }> };

export type ICreateApiKeyMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type ICreateApiKeyMutation = { createAPIKey: { __typename: 'CreateAPIKeyOutput', key: string } | null };

export type IDeleteApiKeyMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type IDeleteApiKeyMutation = { delete_location_api_key_by_pk: { __typename: 'location_api_key', id: uuid } | null };

export type IUpdateGeneralSettingsMutationVariables = Types.Exact<{
  locationId: Types.Scalars['uuid']['input'];
  name: Types.Scalars['String']['input'];
  logoId?: Types.InputMaybe<Types.Scalars['uuid']['input']>;
  settings: Types.Scalars['jsonb']['input'];
}>;


export type IUpdateGeneralSettingsMutation = { update_location_by_pk: { __typename: 'location', id: uuid, name: string, settings: {}, logo: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } | null };

export type IGenerateInviteMutationVariables = Types.Exact<{
  permissionRoleId: Types.Scalars['uuid']['input'];
  approvalNecessary: Types.Scalars['Boolean']['input'];
}>;


export type IGenerateInviteMutation = { insert_location_invite_one: { __typename: 'location_invite', id: uuid, location_id: uuid, expires_at: string, invite_code: string, approval_necessary: boolean, permission_role: { __typename: 'permission_role', id: uuid, name: string } } | null };

export type IDeleteInviteMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type IDeleteInviteMutation = { delete_location_invite_by_pk: { __typename: 'location_invite', id: uuid } | null };

export type IApproveLocationMemberMutationVariables = Types.Exact<{
  userId: Types.Scalars['uuid']['input'];
  locationId: Types.Scalars['uuid']['input'];
}>;


export type IApproveLocationMemberMutation = { update_location_member_by_pk: { __typename: 'location_member', user_id: uuid, location_id: uuid, approved_at: string | null } | null };


export const ApiKeysDocument = gql`
    query APIKeys {
  location_api_key {
    id
    key_preview
    last_used_at
    user {
      ...UserTag
    }
  }
}
    ${UserTagFragmentDoc}`;

export function useApiKeysQuery(options?: Omit<Urql.UseQueryArgs<IApiKeysQueryVariables>, 'query'>) {
  return Urql.useQuery<IApiKeysQuery, IApiKeysQueryVariables>({ query: ApiKeysDocument, ...options });
};
export const LocationInvitesDocument = gql`
    query LocationInvites {
  location_invite(where: {expires_at: {_gt: "now()"}}) {
    ...LocationInvite
  }
}
    ${LocationInviteFragmentDoc}`;

export function useLocationInvitesQuery(options?: Omit<Urql.UseQueryArgs<ILocationInvitesQueryVariables>, 'query'>) {
  return Urql.useQuery<ILocationInvitesQuery, ILocationInvitesQueryVariables>({ query: LocationInvitesDocument, ...options });
};
export const CreateApiKeyDocument = gql`
    mutation CreateApiKey {
  createAPIKey {
    key
  }
}
    `;

export function useCreateApiKeyMutation() {
  return Urql.useMutation<ICreateApiKeyMutation, ICreateApiKeyMutationVariables>(CreateApiKeyDocument);
};
export const DeleteApiKeyDocument = gql`
    mutation DeleteApiKey($id: uuid!) {
  delete_location_api_key_by_pk(id: $id) {
    id
  }
}
    `;

export function useDeleteApiKeyMutation() {
  return Urql.useMutation<IDeleteApiKeyMutation, IDeleteApiKeyMutationVariables>(DeleteApiKeyDocument);
};
export const UpdateGeneralSettingsDocument = gql`
    mutation UpdateGeneralSettings($locationId: uuid!, $name: String!, $logoId: uuid, $settings: jsonb!) {
  update_location_by_pk(
    pk_columns: {id: $locationId}
    _set: {name: $name, logo_id: $logoId}
    _append: {settings: $settings}
  ) {
    id
    name
    settings
    logo {
      ...Avatar
    }
  }
}
    ${AvatarFragmentDoc}`;

export function useUpdateGeneralSettingsMutation() {
  return Urql.useMutation<IUpdateGeneralSettingsMutation, IUpdateGeneralSettingsMutationVariables>(UpdateGeneralSettingsDocument);
};
export const GenerateInviteDocument = gql`
    mutation GenerateInvite($permissionRoleId: uuid!, $approvalNecessary: Boolean!) {
  insert_location_invite_one(
    object: {permission_role_id: $permissionRoleId, approval_necessary: $approvalNecessary}
  ) {
    ...LocationInvite
  }
}
    ${LocationInviteFragmentDoc}`;

export function useGenerateInviteMutation() {
  return Urql.useMutation<IGenerateInviteMutation, IGenerateInviteMutationVariables>(GenerateInviteDocument);
};
export const DeleteInviteDocument = gql`
    mutation DeleteInvite($id: uuid!) {
  delete_location_invite_by_pk(id: $id) {
    id
  }
}
    `;

export function useDeleteInviteMutation() {
  return Urql.useMutation<IDeleteInviteMutation, IDeleteInviteMutationVariables>(DeleteInviteDocument);
};
export const ApproveLocationMemberDocument = gql`
    mutation ApproveLocationMember($userId: uuid!, $locationId: uuid!) {
  update_location_member_by_pk(
    pk_columns: {user_id: $userId, location_id: $locationId}
    _set: {approved_at: "now()"}
  ) {
    user_id
    location_id
    approved_at
  }
}
    `;

export function useApproveLocationMemberMutation() {
  return Urql.useMutation<IApproveLocationMemberMutation, IApproveLocationMemberMutationVariables>(ApproveLocationMemberDocument);
};