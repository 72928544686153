import { Flex } from "@components/layout"
import i18n from "@i18n"
import { CaretDoubleLeft, CaretDoubleRight } from "@phosphor-icons/react"
import classNames from "classnames"
import {
  addDays,
  addMonths,
  endOfMonth,
  endOfWeek,
  isSameDay,
  isSameMonth,
  startOfMonth,
  startOfWeek,
  subMonths,
} from "date-fns"
import React, { Fragment, useState } from "react"

import { parseDate } from "../../utils"
import { dateLocaleMap, formatDate } from "../../utils/date"

export const WorkOrderDueDateCalendar = React.memo(
  (props: {
    dueDate: string | null
    activeDate?: Date | null
    changeDueDate: (date: Date) => void
  }) => {
    const [activeDate, setActiveDate] = useState(props.activeDate || new Date())

    const handleDateChange = (date: Date) => {
      setActiveDate(date)
      props.changeDueDate(date)
    }

    const getHeader = () => {
      return (
        <div className="mt-2 flex items-center justify-between font-medium">
          <div
            className="cursor-pointer rounded border border-gray-300 px-3 py-1 text-sm text-gray-600 hover:border-gray-500 hover:bg-gray-100"
            onClick={() => handleDateChange(new Date())}>
            {i18n.t("calendar:relative_dates.today")}
          </div>
          <Flex row align="center" justify="end">
            <button
              type="button"
              className="cursor-pointer rounded p-1.5 hover:bg-gray-100"
              onClick={() => {
                setActiveDate(subMonths(activeDate, 1))
              }}>
              <CaretDoubleLeft size={12} />
            </button>
            <p
              className="m-auto text-center text-sm"
              style={{ width: Math.min(window.innerWidth, 120) }}>
              {formatDate(activeDate, "MMMM yyyy")}
            </p>
            <button
              type="button"
              className="cursor-pointer rounded p-1.5 hover:bg-gray-100"
              onClick={() => {
                setActiveDate(addMonths(activeDate, 1))
              }}>
              <CaretDoubleRight size={12} />
            </button>
          </Flex>
        </div>
      )
    }

    const getWeekDaysNames = () => {
      const weekStartDate = startOfWeek(activeDate, {
        locale: dateLocaleMap[i18n.language],
      })
      const weekDays = [
        <div
          key="cw"
          className="flex h-4 w-4 select-none justify-center p-4 text-sm text-gray-500">
          {i18n.t("calendar:relative_dates.week_number_header")}
        </div>,
      ]

      for (let day = 0; day < 7; day++) {
        weekDays.push(
          <div
            key={day}
            className="flex h-4 w-4 select-none justify-center p-4 text-sm text-gray-500">
            {formatDate(addDays(weekStartDate, day), "EEEEEE")}
          </div>
        )
      }
      return <div className="grid grid-cols-8">{weekDays}</div>
    }

    const generateDatesForCurrentWeek = (date: Date, dueDate: Date, activeDate: Date) => {
      let currentDate = date
      const week = [
        <div
          key={`cw-${currentDate.getTime()}`}
          className="flex h-4 w-4 items-center justify-center p-4 text-sm text-gray-400">
          {formatDate(currentDate, "w")}
        </div>,
      ]

      for (let day = 0; day < 7; day++) {
        const cloneDate = currentDate

        week.push(
          <div
            key={cloneDate.getTime()}
            onClick={() => handleDateChange(cloneDate)}
            className={classNames(
              "p-4 h-4 w-4 cursor-pointer flex justify-center items-center text-sm hover:bg-gray-100 rounded border border-white",
              {
                "text-gray-400": !isSameMonth(currentDate, activeDate),
                "text-white bg-blue-500 hover:!bg-blue-500": isSameDay(
                  currentDate,
                  dueDate
                ),
                "!text-blue-500 !border-blue-500":
                  !isSameDay(currentDate, dueDate) && isSameDay(currentDate, new Date()),
              }
            )}>
            {formatDate(currentDate, "d")}
          </div>
        )

        currentDate = addDays(currentDate, 1)
      }

      return <Fragment key={date.getTime()}>{week}</Fragment>
    }

    const getDates = () => {
      const startOfTheSelectedMonth = startOfMonth(activeDate)
      const endOfTheSelectedMonth = endOfMonth(activeDate)
      const startDate = startOfWeek(startOfTheSelectedMonth, {
        locale: dateLocaleMap[i18n.language],
      })
      const endDate = endOfWeek(endOfTheSelectedMonth, {
        locale: dateLocaleMap[i18n.language],
      })

      let currentDate = startDate

      const allWeeks = []

      while (currentDate <= endDate) {
        allWeeks.push(
          generateDatesForCurrentWeek(currentDate, parseDate(props.dueDate)!, activeDate)
        )
        currentDate = addDays(currentDate, 7)
      }
      if (allWeeks.length === 5) {
        allWeeks.push(
          generateDatesForCurrentWeek(currentDate, parseDate(props.dueDate)!, activeDate)
        )
      }

      return <div className="my-2 grid grid-cols-8">{allWeeks}</div>
    }

    return (
      <div>
        {getHeader()}
        {getWeekDaysNames()}
        {getDates()}
      </div>
    )
  }
)
