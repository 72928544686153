import Card from "@components/analytics/card"
import { UserMultiSelect } from "@components/shared"
import DateRangePicker, { getRange } from "@components/shared/date-range-picker"
import ScrollArea from "@components/shared/scroll-area"
import { TeamMultiSelect } from "@components/shared/team-select"
import { useFeature } from "@contexts/feature-flag-context"
import { uuid } from "@elara/db"
import { groupBy } from "@elara/select"
import { useTaskReportingQuery } from "@graphql/documents/analytics.generated"
import i18n from "@i18n"
import { DateRange } from "@utils/date"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { RefObject, useImperativeHandle, useMemo, useState } from "react"
import { useOutletContext } from "react-router-dom"

import { RefPayload } from ".."

const TaskReporting = () => {
  const ref = useOutletContext<RefObject<RefPayload>>()

  const hasAdvancedAnalytics = useFeature("advanced_analytics")

  const [users, setUsers] = useState<uuid[]>([])
  const [teams, setTeams] = useState<uuid[]>([])
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null)
  const [dateRange, setDateRange] = useState<DateRange | null>(getRange("last-30-days"))

  const [taskReportingQueryRes] = useTaskReportingQuery({
    variables: {
      where: {
        ...(users.length > 0 || teams.length > 0
          ? {
              user: {
                ...(users.length > 0 ? { id: { _in: users } } : {}),
                ...(teams.length > 0 ? { teams: { team_id: { _in: teams } } } : {}),
              },
            }
          : {}),

        ...(dateRange
          ? {
              finished_at: {
                _gte: dateRange?.start.toISOString(),
                _lte: dateRange?.end?.toISOString(),
              },
            }
          : { finished_at: { _is_null: false } }),
      },
    },
  })
  const taskReporting = taskReportingQueryRes.data?.work_order_report ?? []

  useImperativeHandle(ref, () => ({ ref: containerRef, dateRange: dateRange }), [
    containerRef,
    dateRange,
  ])

  const reportsByUser = useMemo(() => {
    return groupBy(taskReporting, { groupId: (row) => row.user.id }).map((row) => {
      const total = row.items.reduce((acc, curr) => acc + (curr.hours ?? 0), 0)
      return { ...row, total }
    })
  }, [taskReporting])

  const reportsByUserChartOptions = useMemo<Highcharts.Options>(() => {
    return {
      chart: { type: "column" },
      title: {
        text: i18n.t("analytics:metrics.task_reporting.statistics.grouped_by_user.title"),
      },
      xAxis: {
        crosshair: true,
        categories: reportsByUser.map(
          (data) => data.items[0].user.first_name + " " + data.items[0].user.last_name
        ),
      },
      yAxis: { title: { text: "Hours" } },
      tooltip: { pointFormat: "{point.y:.2f} hours" },
      legend: { enabled: false },
      plotOptions: {
        column: {
          borderWidth: 0,
          pointPadding: 0.2,
        },
      },
      series: [
        {
          type: "column",
          name: "Hours",
          data: reportsByUser.map((data) => data.total),
        },
      ],
    } satisfies Highcharts.Options
  }, [reportsByUser])

  return (
    <ScrollArea vertical>
      <div className="grid gap-4 p-4">
        <div className="max-w-full md:max-w-lg">
          <span className="mb-1 block text-xs font-medium">
            {i18n.t("analytics:metrics.task_reporting.date_range")}
          </span>
          <DateRangePicker
            onRangeChange={setDateRange}
            onlyFixedDateRange={!hasAdvancedAnalytics}
          />
        </div>
        <div className="grid grid-cols-1 items-start gap-4 sm:grid-cols-2 md:grid-cols-6">
          <div>
            <span className="mb-1 block text-xs font-medium">
              {i18n.t("common:employee", { count: 2 })}
            </span>
            <UserMultiSelect compact value={users} onChange={setUsers} />
          </div>
          <div>
            <span className="mb-1 block text-xs font-medium">
              {i18n.t("common:team", { count: 2 })}
            </span>
            <TeamMultiSelect compact value={teams} onChange={setTeams} />
          </div>
        </div>

        <hr />

        <div ref={setContainerRef}>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
            <Card cardClassName="px-4 col-span-full">
              <HighchartsReact
                highcharts={Highcharts}
                options={reportsByUserChartOptions}
              />
            </Card>
          </div>
        </div>
      </div>
    </ScrollArea>
  )
}

export default TaskReporting
