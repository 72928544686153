import { IViewDataTypeEnum } from "@elara/db"
import i18n from "@i18n"
import download from "downloadjs"

import { DialogRootProps } from "../dialog"
import { DialogForm } from "../dialog-form"
import { CheckboxInput } from "../form/checkbox-input"
import { FormField } from "../form/form-field"
import { Select } from "../select"
import { TextInput } from "../text-input"

type FormValues = {
  page: {
    size: "A4"
  }
  code: {
    format: "large" | "compact"
  }
  grid: {
    rows: number
    cols: number
    showBorders: boolean
  }
  ids: string[]
  dataType: IViewDataTypeEnum
}

export type BulkGenerateQRCodeDialogProps = {
  dataType: IViewDataTypeEnum
  initialValues: {
    ids: string[]
  }
} & Required<Pick<DialogRootProps, "isOpen" | "onOpenChange">>

const BulkGenerateQRCodeDialog = (props: BulkGenerateQRCodeDialogProps) => {
  const handleSubmit = async (values: FormValues) => {
    const filename = `qr-codes-${new Date().toISOString()}.pdf`

    const pdfBlob = await fetch("/api/qr", {
      method: "POST",
      headers: {
        Accept: "application/pdf",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    }).then((r) => r.blob())

    download(pdfBlob, filename, "application/pdf")
  }

  return (
    <DialogForm<FormValues>
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
      title={i18n.t("qrcode:dialogs.bulk_generate.title")}
      okText={i18n.t("qrcode:dialogs.bulk_generate.actions.submit")}
      okProps={{ icon: undefined }}
      formikConfig={{
        onSubmit: handleSubmit,
        initialValues: {
          page: { size: "A4" },
          code: { format: "large" },
          grid: { rows: 8, cols: 3, showBorders: true },
          ids: props.initialValues.ids,
          dataType: props.dataType,
        },
      }}>
      {(formik) => (
        <div className="my-4 grid grid-cols-4 gap-x-4">
          <div className="col-span-4 mb-4 text-sm opacity-75">
            {i18n.t("qrcode:dialogs.bulk_generate.description", {
              count: props.initialValues.ids.length,
            })}
          </div>

          <div className="col-span-1">
            <div
              style={{
                display: "grid",
                aspectRatio: "1 / 1.414",
                gridTemplateRows: `repeat(${formik.values.grid.rows}, 1fr)`,
                gridTemplateColumns: `repeat(${formik.values.grid.cols}, 1fr)`,
              }}>
              {Array.from({
                length: formik.values.grid.rows * formik.values.grid.cols,
              }).map((_, index) => (
                <div key={index} className="border" />
              ))}
            </div>
          </div>

          <div className="col-span-3">
            <div className="grid grid-cols-2 gap-4">
              <FormField
                name="code.format"
                label={i18n.t("qrcode:dialogs.bulk_generate.labels.format")}
                hasErrorPlaceholder={false}>
                {({ field, helpers }) => (
                  <Select
                    value={field.value}
                    onValueChange={helpers.setValue}
                    items={[
                      { value: "large", label: i18n.t("assets:qr_code.formats.large") },
                      { value: "compact", label: i18n.t("assets:qr_code.formats.compact") },
                    ]}
                  />
                )}
              </FormField>

              <FormField
                name="page.size"
                label={i18n.t("qrcode:dialogs.bulk_generate.labels.size")}
                hasErrorPlaceholder={false}>
                {({ field, helpers }) => (
                  <Select
                    value={field.value}
                    onValueChange={helpers.setValue}
                    items={[{ value: "A4", label: "A4" }]}
                  />
                )}
              </FormField>

              <FormField
                name="grid.rows"
                label={i18n.t("qrcode:dialogs.bulk_generate.labels.rows")}
                hasErrorPlaceholder={false}>
                {({ field, helpers }) => (
                  <TextInput
                    type="number"
                    min={1}
                    value={field.value}
                    onChange={(e) => helpers.setValue(e.target.value)}
                  />
                )}
              </FormField>

              <FormField
                name="grid.cols"
                label={i18n.t("qrcode:dialogs.bulk_generate.labels.cols")}
                hasErrorPlaceholder={false}>
                {({ field, helpers }) => (
                  <TextInput
                    type="number"
                    min={1}
                    value={field.value}
                    onChange={(e) => helpers.setValue(e.target.value)}
                  />
                )}
              </FormField>

              <div className="col-span-2">
                <h4 className="mb-2 text-xs font-semibold opacity-75">
                  {i18n.t("common:option", { count: 2 })}
                </h4>

                <FormField name="grid.showBorders" hasErrorPlaceholder={false}>
                  {({ field, helpers }) => (
                    <label>
                      <CheckboxInput
                        checked={field.value}
                        onChange={(e) => helpers.setValue(e.target.checked)}
                      />
                      <span className="ml-2 select-none text-sm text-gray-700">
                        {i18n.t("qrcode:dialogs.bulk_generate.labels.borders")}
                      </span>
                    </label>
                  )}
                </FormField>
              </div>
            </div>
          </div>
        </div>
      )}
    </DialogForm>
  )
}

export default BulkGenerateQRCodeDialog
