import AssetCreateDialog from "@components/asset/asset-create-form-dialog"
import CreateEditConsumableFormDialog from "@components/consumable/dialogs/create-edit-consumable-form-dialog"
import CreateEditConsumableLogFormDialog from "@components/consumable/dialogs/create-edit-consumable-log-form-dialog"
import CreateEditContactFormDialog from "@components/contact/dialogs/create-edit-contact-form-dialog"
import CreateEditMaintenanceFormDialog from "@components/maintenance/dialogs/create-edit-maintenance-form-dialog"
import EditServiceRequestFormDialog from "@components/service-request/dialogs/edit-service-request-form-dialog"
import FeedbackModal from "@components/shared/feedback-modal"
import BulkGenerateQRCodeDialog from "@components/shared/qrcode/bulk-generate-dialog"
import CreateTaskFormDialog from "@components/work-order/create-task-form"
import type { InitialFormValuesInput } from "@components/work-order/create-task-form.hooks"
import TaskPublicAccessDialog from "@components/work-order/dialogs/task-public-access"
import SelectTaskTemplateDialog from "@components/work-order/select-task-template-dialog"
import type { IAssetInsertInput, IViewDataTypeEnum } from "@elara/db"
import {
  IConsumableFragment,
  IConsumableLogFragment,
  IContactFragment,
  IMaintenanceFragment,
  IServiceRequestFragment,
} from "@graphql/documents/fragments.generated"
import type { MaterialRestockFormValues } from "@pages/material/dialogs/restock"
import MaterialRestockDialog from "@pages/material/dialogs/restock"
import { Serialized } from "@utils"
import React, { Suspense } from "react"
import { createContext, useCallback, useEffect, useReducer } from "react"

// Add new modals here
export enum Modals {
  "bulk_generate_qrcode" = "bulk_generate_qrcode",
  "consumable_log" = "consumable_log",
  "consumable" = "consumable",
  "contact" = "contact",
  "feedback" = "feedback",
  "maintenance" = "maintenance",
  "service_request" = "service_request",
  "material_restock" = "material_restock",
  "object_create" = "object_create",
  "select_template" = "select_template",
  "task_create" = "task_create",
  "task_public_access" = "task_public_access",
}

export enum ModalActionType {
  "SET_MODAL_STATE" = "SET_MODAL_STATE",
}

// Add the corresponding payload types for the modals here, avoid using "any/unknown" if possible
type ModalState = {
  object_create: {
    open: boolean
    payload?: { initialValues?: IAssetInsertInput; parentAssetId?: string }
  }
  task_create: {
    open: boolean
    payload?: {
      initialValues: InitialFormValuesInput
      template?: {
        id: string
        name: string
        allow_modification_in_task_form: boolean
      }
    }
  }
  select_template: {
    open: boolean
    payload?: { initialTaskValues: InitialFormValuesInput }
  }
  contact: {
    open: boolean
    payload?: { initialValues: Partial<IContactFragment> }
  }
  consumable: {
    open: boolean
    payload?: { initialValues: Partial<IConsumableFragment> }
  }
  consumable_log: {
    open: boolean
    payload?: {
      initialValues: Partial<IConsumableLogFragment> & { consumable_id: string }
      type?: "add" | "take"
    }
  }
  maintenance: {
    open: boolean
    payload?: {
      initialValues?: Partial<IMaintenanceFragment>
      initialTaskValues?: InitialFormValuesInput
    }
  }
  service_request: {
    open: boolean
    payload: {
      initialValues: Partial<IServiceRequestFragment>
    }
  }
  material_restock: {
    open: boolean
    payload: {
      initialValues: Partial<Serialized<MaterialRestockFormValues>>
      consumable_id: string
      baseQuantity?: number
    }
  }
  bulk_generate_qrcode: {
    open: boolean
    payload?: { ids: string[]; type: IViewDataTypeEnum }
  }
  task_public_access: {
    open: boolean
    payload: {
      taskId: string
      mode: "create" | "revoke"
      initialValues?: { expiresIn: Date }
    }
  }
  feedback: {
    open: boolean
    payload?: {}
  }
}

type ModalAction = {
  type: ModalActionType
  payload: Partial<ModalState>
}

type ModalContextValue = {
  state: Partial<ModalState>
  dispatch: React.Dispatch<ModalAction>
}

const modalReducer = (
  state: Partial<ModalState>,
  action: ModalAction
): Partial<ModalState> => {
  const { type, payload } = action

  switch (type) {
    case ModalActionType.SET_MODAL_STATE:
      return payload
  }
}

export const ModalContext = createContext<ModalContextValue>({
  state: {} as Partial<ModalState>,
  dispatch: () => {},
})

export const ModalProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [state, dispatch] = useReducer(modalReducer, {})

  const closeModal = (modal: Modals) =>
    dispatch({
      type: ModalActionType.SET_MODAL_STATE,
      payload: { [modal]: { open: false } },
    })

  useEffect(() => {
    const dispatchListener = (e: Event) => {
      if (e instanceof CustomEvent && e.detail) {
        dispatch(e.detail)
      }
    }

    window.addEventListener("dispatchModal", dispatchListener)

    return () => {
      window.removeEventListener("dispatchModal", dispatchListener)
    }
  }, [])

  return (
    <ModalContext.Provider value={{ state, dispatch }}>
      {children}

      <Suspense fallback={<div></div>}>
        {/* Attach Modals to DOM Here */}
        {state.object_create?.open && (
          <AssetCreateDialog
            isOpen
            onOpenChange={() => closeModal(Modals.object_create)}
            {...state.object_create.payload}
          />
        )}
        {/* Attach Modals to DOM Here */}
        {state.select_template?.open && (
          <SelectTaskTemplateDialog
            onOpenChange={() => closeModal(Modals.select_template)}
            {...state.select_template.payload}
          />
        )}

        {state.task_create?.open && (
          <CreateTaskFormDialog
            open
            onOpenChange={() => closeModal(Modals.task_create)}
            initialValues={state.task_create.payload?.initialValues}
            template={state.task_create.payload?.template ?? null}
          />
        )}

        {state.contact?.open && (
          <CreateEditContactFormDialog
            isOpen
            onOpenChange={() => closeModal(Modals.contact)}
            initialValues={state.contact.payload?.initialValues}
          />
        )}

        {state.consumable?.open && (
          <CreateEditConsumableFormDialog
            isOpen
            onOpenChange={() => closeModal(Modals.consumable)}
            initialValues={state.consumable.payload?.initialValues}
          />
        )}

        {state.consumable_log?.open && (
          <CreateEditConsumableLogFormDialog
            isOpen
            onOpenChange={() => closeModal(Modals.consumable_log)}
            initialValues={state.consumable_log.payload!.initialValues}
            type={state.consumable_log.payload!.type}
          />
        )}

        {state.maintenance?.open && (
          <CreateEditMaintenanceFormDialog
            isOpen
            onOpenChange={() => closeModal(Modals.maintenance)}
            initialValues={state.maintenance.payload?.initialValues}
            initialTaskValues={state.maintenance.payload?.initialTaskValues}
          />
        )}

        {state.material_restock?.open && (
          <MaterialRestockDialog
            isOpen
            onOpenChange={() => closeModal(Modals.material_restock)}
            initialValues={state.material_restock.payload.initialValues}
            consumableId={state.material_restock.payload.consumable_id}
            baseQuantity={state.material_restock.payload.baseQuantity}
          />
        )}

        {state.service_request?.open && (
          <EditServiceRequestFormDialog
            isOpen
            onOpenChange={() => closeModal(Modals.service_request)}
            initialValues={state.service_request.payload?.initialValues}
          />
        )}

        {state.bulk_generate_qrcode?.open && state.bulk_generate_qrcode.payload && (
          <BulkGenerateQRCodeDialog
            isOpen
            onOpenChange={() => closeModal(Modals.bulk_generate_qrcode)}
            dataType={state.bulk_generate_qrcode.payload?.type}
            initialValues={{
              ids: state.bulk_generate_qrcode.payload?.ids ?? [],
            }}
          />
        )}

        {state.task_public_access?.open && (
          <TaskPublicAccessDialog
            isOpen
            onOpenChange={() => closeModal(Modals.material_restock)}
            mode={state.task_public_access.payload.mode}
            taskId={state.task_public_access.payload.taskId}
            initialValues={state.task_public_access.payload.initialValues}
          />
        )}

        {state.feedback?.open && (
          <FeedbackModal isOpen onOpenChange={() => closeModal(Modals.feedback)} />
        )}
      </Suspense>
    </ModalContext.Provider>
  )
}

export const useOpenModal = () => {
  const open = useCallback((action: Partial<ModalState>) => {
    const e = new CustomEvent("dispatchModal", {
      detail: {
        type: ModalActionType.SET_MODAL_STATE,
        payload: action,
      },
    })

    window.dispatchEvent(e)
  }, [])

  return open
}

export const openModal = <T extends keyof ModalState>(
  modal: T,
  payload?: ModalState[T]["payload"]
) => {
  const e = new CustomEvent("dispatchModal", {
    detail: {
      type: ModalActionType.SET_MODAL_STATE,
      payload: {
        [modal]: { open: true, payload },
      },
    },
  })

  window.dispatchEvent(e)
}

export const closeModal = <T extends keyof ModalState>(modal: T) => {
  const e = new CustomEvent("dispatchModal", {
    detail: {
      type: ModalActionType.SET_MODAL_STATE,
      payload: { [modal]: { open: false } },
    },
  })

  window.dispatchEvent(e)
}
