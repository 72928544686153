// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IConsumablesDataGridQueryVariables = Types.Exact<{
  startDate: Types.Scalars['timestamptz']['input'];
  endDate: Types.Scalars['timestamptz']['input'];
}>;


export type IConsumablesDataGridQuery = { consumable_log: Array<{ __typename: 'consumable_log', id: uuid, adjustment: number, cost_per_unit: number, created_at: string, adjustment_at: string, consumable: { __typename: 'consumable', id: uuid, name: string, description: string | null } | null, task: { __typename: 'work_order', id: uuid, name: string, description: string | null } | null, place: { __typename: 'place', id: uuid, name: string } | null, asset: { __typename: 'asset', id: uuid, name: string } | null }> };


export const ConsumablesDataGridDocument = gql`
    query ConsumablesDataGrid($startDate: timestamptz!, $endDate: timestamptz!) {
  consumable_log(
    where: {_and: [{adjustment_at: {_gte: $startDate}}, {adjustment_at: {_lte: $endDate}}]}
  ) {
    id
    consumable {
      id
      name
      description
    }
    adjustment
    cost_per_unit
    created_at
    adjustment_at
    task {
      id
      name
      description
    }
    place {
      id
      name
    }
    asset {
      id
      name
    }
  }
}
    `;

export function useConsumablesDataGridQuery(options: Omit<Urql.UseQueryArgs<IConsumablesDataGridQueryVariables>, 'query'>) {
  return Urql.useQuery<IConsumablesDataGridQuery, IConsumablesDataGridQueryVariables>({ query: ConsumablesDataGridDocument, ...options });
};