import { QRCode } from "@components/shared/qr-code"

import { useAsset } from "./$id"

export function AssetInformationGeneral() {
  const { asset } = useAsset()
  const assetParents = asset.parents?.map((parent) => parent.name).join(" > ")

  return (
    <QRCode
      title={asset.name}
      pretitle={assetParents}
      identifier=""
      relativeUrl={`/object/${asset.id}`}
      location={asset.place?.name ?? ""}
    />
  )
}

export default AssetInformationGeneral
