import { UserMultiSelect } from "@components/shared"
import { PopoverContentProps } from "@components/shared/popover"
import { IPermissionScopeEnum, uuid } from "@elara/db"
import { IWorkOrderDataViewFragment } from "@graphql/documents/fragments.generated"
import {
  useAddCollaboratorToWorkOrderMutation,
  useDeleteCollaboratorFromWorkOrderMutation,
} from "@graphql/documents/work-order.generated"
import { usePermissionScope } from "@hooks"
import i18n from "@i18n"

export const WorkOrderQuickCollaboratorSelect = (props: {
  workOrder: IWorkOrderDataViewFragment
  popoverContentProps?: PopoverContentProps
}) => {
  const scope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)
  const [, addCollaboratorToWorkOrder] = useAddCollaboratorToWorkOrderMutation()
  const [, deleteCollaboratorToWorkOrder] = useDeleteCollaboratorFromWorkOrderMutation()

  const assignees = props.workOrder.assignees.map(({ user }) => user.id)
  const users = props.workOrder.collaborators.map(({ user }) => user.id)

  const onChange = async (updatedCollaborators: uuid[]) => {
    const addedUserId = updatedCollaborators.find((t) => !users.includes(t))
    const deletedUserId = users.find((t) => !updatedCollaborators.includes(t))

    if (addedUserId) {
      await addCollaboratorToWorkOrder(
        { userId: addedUserId, workOrderId: props.workOrder.id },
        scope.context()
      )
    } else if (deletedUserId) {
      await deleteCollaboratorToWorkOrder(
        { userId: deletedUserId, workOrderId: props.workOrder.id },
        scope.context()
      )
    }
  }

  return (
    <UserMultiSelect
      value={users}
      onChange={onChange}
      userIsSelectable={(user) => !assignees.includes(user.id)}
      popoverContentProps={props.popoverContentProps}
      title={i18n.t("tasks:fields.collaborator", { count: 1 })}
      placeholder={i18n.t("common:search_token", {
        token: i18n.t("tasks:fields.collaborator", { count: 1 }),
      })}
    />
  )
}
