// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import { UsersTableRowFragmentDoc } from './fragments.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IUsersTableQueryVariables = Types.Exact<{
  location_id: Types.Scalars['uuid']['input'];
}>;


export type IUsersTableQuery = { location_member: Array<{ __typename: 'location_member', location_id: uuid, user_id: uuid, approved_at: string | null, user: { __typename: 'user', phone: string | null, email: string | null, username: string, language: string, settings: {}, id: uuid, first_name: string, last_name: string, deleted_at: string | null, teams: Array<{ __typename: 'team_member', team_id: uuid, user_id: uuid, team: { __typename: 'team', id: uuid, name: string } }>, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null }, permission_role: { __typename: 'permission_role', id: uuid, name: string } | null }> };

export type IUpdateUserPermissionRoleMutationVariables = Types.Exact<{
  user_id: Types.Scalars['uuid']['input'];
  location_id: Types.Scalars['uuid']['input'];
  permissionRoleId: Types.Scalars['uuid']['input'];
}>;


export type IUpdateUserPermissionRoleMutation = { update_location_member_by_pk: { __typename: 'location_member', location_id: uuid, user_id: uuid, approved_at: string | null, user: { __typename: 'user', phone: string | null, email: string | null, username: string, language: string, settings: {}, id: uuid, first_name: string, last_name: string, deleted_at: string | null, teams: Array<{ __typename: 'team_member', team_id: uuid, user_id: uuid, team: { __typename: 'team', id: uuid, name: string } }>, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null }, permission_role: { __typename: 'permission_role', id: uuid, name: string } | null } | null };


export const UsersTableDocument = gql`
    query UsersTable($location_id: uuid!) {
  location_member(where: {location_id: {_eq: $location_id}}) {
    ...UsersTableRow
  }
}
    ${UsersTableRowFragmentDoc}`;

export function useUsersTableQuery(options: Omit<Urql.UseQueryArgs<IUsersTableQueryVariables>, 'query'>) {
  return Urql.useQuery<IUsersTableQuery, IUsersTableQueryVariables>({ query: UsersTableDocument, ...options });
};
export const UpdateUserPermissionRoleDocument = gql`
    mutation UpdateUserPermissionRole($user_id: uuid!, $location_id: uuid!, $permissionRoleId: uuid!) {
  update_location_member_by_pk(
    pk_columns: {user_id: $user_id, location_id: $location_id}
    _set: {permission_role_id: $permissionRoleId}
  ) {
    ...UsersTableRow
  }
}
    ${UsersTableRowFragmentDoc}`;

export function useUpdateUserPermissionRoleMutation() {
  return Urql.useMutation<IUpdateUserPermissionRoleMutation, IUpdateUserPermissionRoleMutationVariables>(UpdateUserPermissionRoleDocument);
};