import { CurrencyValue } from "@components/shared/currency-value"
import LoadingSpinner from "@components/shared/loading-spinner"
import { Tooltip } from "@components/shared/tooltip"
import { UserTag } from "@components/shared/user-tag"
import {
  IUserTagFragment,
  IWorkOrderFragment,
} from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { Calculator, Question, Timer, User } from "@phosphor-icons/react"
import { cn, naturalCompare } from "@utils"
import { useMemo } from "react"
import { useOutletContext } from "react-router-dom"

import ExpenseReport, { totalExpenseCost } from "./expense"
import { TaskFeedback } from "./feedback"
import { MaterialReport, totalMaterialCost } from "./material"
import { compactDuration, TimeReport, totalDuration } from "./time"

function TaskReport(props: { task: IWorkOrderFragment }) {
  const { task } = props
  const involvedUsers = useMemo(() => {
    const inv: Record<string, IUserTagFragment> = {}
    task.reports.forEach((r) => {
      if (!r.user) return
      inv[r.user.id] = r.user
    })
    if (task.feedback?.created_by) {
      inv[task.feedback.created_by.id] = task.feedback.created_by
    }

    return Object.values(inv).sort((a, b) =>
      naturalCompare(`${a?.first_name} ${a?.last_name}`, `${b?.first_name} ${b?.last_name}`)
    )
  }, [task?.reports])

  const totalCost = totalMaterialCost(task) + totalExpenseCost(task.expenses)
  const totalDur = totalDuration(task)

  return (
    <div className="px-3">
      <div className="mx-auto max-w-screen-md space-y-6 py-3 @container">
        <div
          className={cn({
            "print:hidden":
              totalCost === 0 &&
              Object.keys(totalDur).length === 0 &&
              involvedUsers.length === 0,
          })}>
          <div className="mt-3 grid grid-cols-4 gap-3">
            <div className="col-span-2 flex flex-col rounded-lg border border-gray-100 bg-gray-50 p-4 pb-0 @2xl:col-span-1">
              <div className="flex items-center gap-x-1 text-sm font-medium text-gray-600">
                <Calculator /> {i18n.t("tasks:report.total_cost")}
              </div>
              <div className="flex flex-1 items-center justify-center p-2 text-lg font-semibold @2xl:text-xl">
                <CurrencyValue value={totalCost} />
              </div>
            </div>
            <div className="col-span-2 flex flex-col rounded-lg border border-gray-100 bg-gray-50 p-4 pb-0 @2xl:col-span-1">
              <div className="flex items-center gap-x-1 text-sm font-medium text-gray-600">
                <Timer />
                {i18n.t("tasks:report.total_time")}
              </div>
              <div className="flex flex-1 items-center justify-center p-4 text-lg font-semibold @2xl:text-xl">
                {compactDuration(totalDur)}
              </div>
            </div>
            <div className="col-span-full rounded-lg border border-gray-100 bg-gray-50 p-4 pb-0 @2xl:col-span-2">
              <div className="flex items-center gap-x-1 text-sm font-medium text-gray-600">
                <User />
                {i18n.t("tasks:report.involved_employees")}
                <Tooltip content={i18n.t("tasks:report.involved_tooltip")}>
                  <Question className="text-gray-400" />
                </Tooltip>
              </div>
              <div className="mt-1 flex flex-wrap gap-1 py-3 font-medium text-gray-700">
                {!involvedUsers.length ? (
                  <p className="py-1 text-sm font-normal text-gray-500">
                    {i18n.t("tasks:report.time.no_involved_users")}
                  </p>
                ) : (
                  involvedUsers.map((u) => (
                    <div
                      key={u.id}
                      className="rounded-full border border-gray-200 bg-gray-50 px-2 py-1">
                      <UserTag user={u} />
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
        <TaskFeedback task={task} />
        <TimeReport task={task} />
        <MaterialReport task={task} />
        <ExpenseReport task={task} />
      </div>
    </div>
  )
}

export default function TaskReportPage() {
  const { task } = useOutletContext<{ task: IWorkOrderFragment | null }>()

  if (!task) return <LoadingSpinner />

  return <TaskReport task={task} />
}
