import { Flex, HStack, VStack } from "@components/layout"
import { DetailsItem } from "@components/shared"
import { IAssetManufacturerFragment } from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { PaperPlaneTilt, Phone, X } from "@phosphor-icons/react"

export type ManufacturerDialogContentProps = {
  manufacturer: IAssetManufacturerFragment
  onClose: () => void
}

export const ManufacturerDialogContent = ({
  manufacturer,

  onClose,
}: ManufacturerDialogContentProps) => {
  const { name, contact, phone, email } = manufacturer

  const presentationBody = (
    <>
      <DetailsItem direction="vertical">
        <DetailsItem.Label>{i18n.t("assets:fields.manufacturer")}</DetailsItem.Label>
        <DetailsItem.Value>
          <span className="text-sm">{name}</span>
        </DetailsItem.Value>
      </DetailsItem>

      <DetailsItem direction="vertical">
        <DetailsItem.Label>{i18n.t("assets:fields.contact_person")}</DetailsItem.Label>
        <DetailsItem.Value
          placeholder={i18n.t("common:no_token_given", {
            token: i18n.t("assets:fields.contact_person"),
          })}>
          {contact && <span className="text-sm">{contact}</span>}
        </DetailsItem.Value>
      </DetailsItem>

      <DetailsItem direction="vertical">
        <DetailsItem.Label>{i18n.t("assets:fields.phone")}</DetailsItem.Label>
        <DetailsItem.Value
          placeholder={i18n.t("common:no_token_given", {
            token: i18n.t("assets:fields.phone"),
          })}>
          {phone && (
            <a href={`tel:${phone}`}>
              <HStack align="center" space={8}>
                <span className="text-sm">{phone}</span>
                {phone ? <Phone size={20} className="text-blue-600" /> : null}
              </HStack>
            </a>
          )}
        </DetailsItem.Value>
      </DetailsItem>

      <DetailsItem direction="vertical">
        <DetailsItem.Label>{i18n.t("assets:fields.email")}</DetailsItem.Label>
        <DetailsItem.Value
          placeholder={i18n.t("common:no_token_given", {
            token: i18n.t("assets:fields.email"),
          })}>
          {email && (
            <a href={`mailto: ${email}`} target="_blank" rel="noreferrer">
              <HStack align="center" space={8}>
                <span className="text-sm">{email}</span>
                {phone ? <PaperPlaneTilt size={20} className="text-blue-600" /> : null}
              </HStack>
            </a>
          )}
        </DetailsItem.Value>
      </DetailsItem>
    </>
  )

  return (
    <div>
      <Flex row justify="space-between" align="center" className="bg-gray-50 p-3">
        <span className="text-sm">{i18n.t("assets:labels.manufacturer_support")}</span>
        <div className="flex items-center justify-center p-1.5" onClick={onClose}>
          <X size={24} />
        </div>
      </Flex>

      <VStack space={16} inset={{ x: 8, bottom: 20, top: 8 }}>
        {presentationBody}
      </VStack>
    </div>
  )
}
