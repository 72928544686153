import * as Tabs from "@radix-ui/react-tabs"
import classNames from "classnames"
import React from "react"

const Root = React.forwardRef<
  React.ElementRef<typeof Tabs.Root>,
  React.ComponentProps<typeof Tabs.Root>
>((props, forwardedRef) => {
  return (
    <Tabs.Root
      {...props}
      ref={forwardedRef}
      className={classNames("flex min-h-0 flex-1 flex-col", props.className)}
    />
  )
})

const List = React.forwardRef<
  React.ElementRef<typeof Tabs.List>,
  React.ComponentProps<typeof Tabs.List>
>(({ children, ...props }, forwardedRef) => {
  return (
    <Tabs.List
      {...props}
      ref={forwardedRef}
      className={classNames("flex gap-2", props.className)}>
      {children}
    </Tabs.List>
  )
})

const Content = React.forwardRef<
  React.ElementRef<typeof Tabs.Content>,
  React.ComponentProps<typeof Tabs.Content>
>((props, forwardedRef) => {
  return <Tabs.Content {...props} ref={forwardedRef} className={props.className} />
})

const Trigger = (props: React.ComponentProps<typeof Tabs.Trigger>) => {
  return (
    <Tabs.Trigger
      {...props}
      className={classNames(
        "text-gray-500 hover:text-gray-700",
        "radix-state-active:!bg-blue-100 radix-state-active:!text-blue-600",
        "px-3 py-1.5 font-medium text-sm rounded-md",
        props.className
      )}>
      {props.children}
    </Tabs.Trigger>
  )
}

export default {
  Root,
  List,
  Content,
  Trigger,
}
