// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import { TeamFragmentDoc, TeamMemberFragmentDoc, TeamNameIconFragmentDoc } from './fragments.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ITeamsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ITeamsQuery = { team: Array<{ __typename: 'team', id: uuid, name: string, icon: {} | null, private: boolean | null, owner_id: uuid | null, archived_at: string | null, notification_is_enabled: boolean | null, config: {}, notification_settings: {} | null, members: Array<{ __typename: 'team_member', team_id: uuid, user_id: uuid, sort_order: number, user: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } }> }> };

export type IArchivedTeamQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IArchivedTeamQuery = { team: Array<{ __typename: 'team', id: uuid, name: string, icon: {} | null, private: boolean | null, owner_id: uuid | null, archived_at: string | null, notification_is_enabled: boolean | null, config: {}, notification_settings: {} | null, members: Array<{ __typename: 'team_member', team_id: uuid, user_id: uuid, sort_order: number, user: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } }> }> };

export type IGetTeamsQueryVariables = Types.Exact<{
  where?: Types.ITeamBoolExp;
}>;


export type IGetTeamsQuery = { team: Array<{ __typename: 'team', id: uuid, name: string, icon: {} | null, private: boolean | null, owner_id: uuid | null, archived_at: string | null, notification_is_enabled: boolean | null, config: {}, notification_settings: {} | null, members: Array<{ __typename: 'team_member', team_id: uuid, user_id: uuid, sort_order: number, user: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } }> }> };

export type ITeamByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type ITeamByIdQuery = { team_by_pk: { __typename: 'team', id: uuid, name: string, icon: {} | null, private: boolean | null, owner_id: uuid | null, archived_at: string | null, notification_is_enabled: boolean | null, config: {}, notification_settings: {} | null, members: Array<{ __typename: 'team_member', team_id: uuid, user_id: uuid, sort_order: number, user: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } }> } | null };

export type ITeamNameIconByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type ITeamNameIconByIdQuery = { team_by_pk: { __typename: 'team', id: uuid, name: string, icon: {} | null } | null };

export type IInsertTeamMutationVariables = Types.Exact<{
  data: Types.ITeamInsertInput;
}>;


export type IInsertTeamMutation = { insert_team_one: { __typename: 'team', id: uuid, name: string, icon: {} | null, private: boolean | null, owner_id: uuid | null, archived_at: string | null, notification_is_enabled: boolean | null, config: {}, notification_settings: {} | null, members: Array<{ __typename: 'team_member', team_id: uuid, user_id: uuid, sort_order: number, user: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } }> } | null };

export type IUpdateTeamMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  data: Types.ITeamSetInput;
}>;


export type IUpdateTeamMutation = { update_team_by_pk: { __typename: 'team', id: uuid, name: string, icon: {} | null, private: boolean | null, owner_id: uuid | null, archived_at: string | null, notification_is_enabled: boolean | null, config: {}, notification_settings: {} | null, members: Array<{ __typename: 'team_member', team_id: uuid, user_id: uuid, sort_order: number, user: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } }> } | null };

export type IDeleteTeamMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type IDeleteTeamMutation = { delete_team_by_pk: { __typename: 'team', id: uuid, name: string, icon: {} | null, private: boolean | null, owner_id: uuid | null, archived_at: string | null, notification_is_enabled: boolean | null, config: {}, notification_settings: {} | null, members: Array<{ __typename: 'team_member', team_id: uuid, user_id: uuid, sort_order: number, user: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } }> } | null };

export type IAddTeamMemberMutationVariables = Types.Exact<{
  userId: Types.Scalars['uuid']['input'];
  teamId: Types.Scalars['uuid']['input'];
}>;


export type IAddTeamMemberMutation = { insert_team_member: { __typename: 'team_member_mutation_response', returning: Array<{ __typename: 'team_member', user_id: uuid, team_id: uuid, team: { __typename: 'team', id: uuid, name: string, icon: {} | null, private: boolean | null, owner_id: uuid | null, archived_at: string | null, notification_is_enabled: boolean | null, config: {}, notification_settings: {} | null, members: Array<{ __typename: 'team_member', team_id: uuid, user_id: uuid, sort_order: number, user: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } }> } }> } | null };

export type IDeleteTeamMemberMutationVariables = Types.Exact<{
  userId: Types.Scalars['uuid']['input'];
  teamId: Types.Scalars['uuid']['input'];
}>;


export type IDeleteTeamMemberMutation = { delete_team_member_by_pk: { __typename: 'team_member', team_id: uuid, user_id: uuid, sort_order: number } | null };

export type IUpdateTeamMemberMutationVariables = Types.Exact<{
  userId: Types.Scalars['uuid']['input'];
  teamId: Types.Scalars['uuid']['input'];
  data: Types.ITeamMemberSetInput;
}>;


export type IUpdateTeamMemberMutation = { update_team_member_by_pk: { __typename: 'team_member', team_id: uuid, user_id: uuid, sort_order: number } | null };

export type IUpdateTeamNotificationSettingsMutationVariables = Types.Exact<{
  teamId: Types.Scalars['uuid']['input'];
  settings: Types.Scalars['jsonb']['input'];
}>;


export type IUpdateTeamNotificationSettingsMutation = { update_team_by_pk: { __typename: 'team', id: uuid, notification_settings: {} | null } | null };


export const TeamsDocument = gql`
    query Teams {
  team {
    ...Team
  }
}
    ${TeamFragmentDoc}`;

export function useTeamsQuery(options?: Omit<Urql.UseQueryArgs<ITeamsQueryVariables>, 'query'>) {
  return Urql.useQuery<ITeamsQuery, ITeamsQueryVariables>({ query: TeamsDocument, ...options });
};
export const ArchivedTeamDocument = gql`
    query ArchivedTeam {
  team(where: {archived_at: {_is_null: false}}) {
    ...Team
  }
}
    ${TeamFragmentDoc}`;

export function useArchivedTeamQuery(options?: Omit<Urql.UseQueryArgs<IArchivedTeamQueryVariables>, 'query'>) {
  return Urql.useQuery<IArchivedTeamQuery, IArchivedTeamQueryVariables>({ query: ArchivedTeamDocument, ...options });
};
export const GetTeamsDocument = gql`
    query GetTeams($where: team_bool_exp! = {}) {
  team(where: $where) {
    ...Team
  }
}
    ${TeamFragmentDoc}`;

export function useGetTeamsQuery(options?: Omit<Urql.UseQueryArgs<IGetTeamsQueryVariables>, 'query'>) {
  return Urql.useQuery<IGetTeamsQuery, IGetTeamsQueryVariables>({ query: GetTeamsDocument, ...options });
};
export const TeamByIdDocument = gql`
    query TeamById($id: uuid!) {
  team_by_pk(id: $id) {
    ...Team
  }
}
    ${TeamFragmentDoc}`;

export function useTeamByIdQuery(options: Omit<Urql.UseQueryArgs<ITeamByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<ITeamByIdQuery, ITeamByIdQueryVariables>({ query: TeamByIdDocument, ...options });
};
export const TeamNameIconByIdDocument = gql`
    query TeamNameIconById($id: uuid!) {
  team_by_pk(id: $id) {
    ...TeamNameIcon
  }
}
    ${TeamNameIconFragmentDoc}`;

export function useTeamNameIconByIdQuery(options: Omit<Urql.UseQueryArgs<ITeamNameIconByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<ITeamNameIconByIdQuery, ITeamNameIconByIdQueryVariables>({ query: TeamNameIconByIdDocument, ...options });
};
export const InsertTeamDocument = gql`
    mutation InsertTeam($data: team_insert_input!) {
  insert_team_one(object: $data) {
    ...Team
  }
}
    ${TeamFragmentDoc}`;

export function useInsertTeamMutation() {
  return Urql.useMutation<IInsertTeamMutation, IInsertTeamMutationVariables>(InsertTeamDocument);
};
export const UpdateTeamDocument = gql`
    mutation UpdateTeam($id: uuid!, $data: team_set_input!) {
  update_team_by_pk(pk_columns: {id: $id}, _set: $data) {
    ...Team
  }
}
    ${TeamFragmentDoc}`;

export function useUpdateTeamMutation() {
  return Urql.useMutation<IUpdateTeamMutation, IUpdateTeamMutationVariables>(UpdateTeamDocument);
};
export const DeleteTeamDocument = gql`
    mutation DeleteTeam($id: uuid!) {
  delete_team_by_pk(id: $id) {
    ...Team
  }
}
    ${TeamFragmentDoc}`;

export function useDeleteTeamMutation() {
  return Urql.useMutation<IDeleteTeamMutation, IDeleteTeamMutationVariables>(DeleteTeamDocument);
};
export const AddTeamMemberDocument = gql`
    mutation AddTeamMember($userId: uuid!, $teamId: uuid!) {
  insert_team_member(objects: [{user_id: $userId, team_id: $teamId}]) {
    returning {
      user_id
      team_id
      team {
        ...Team
      }
    }
  }
}
    ${TeamFragmentDoc}`;

export function useAddTeamMemberMutation() {
  return Urql.useMutation<IAddTeamMemberMutation, IAddTeamMemberMutationVariables>(AddTeamMemberDocument);
};
export const DeleteTeamMemberDocument = gql`
    mutation DeleteTeamMember($userId: uuid!, $teamId: uuid!) {
  delete_team_member_by_pk(user_id: $userId, team_id: $teamId) {
    ...TeamMember
  }
}
    ${TeamMemberFragmentDoc}`;

export function useDeleteTeamMemberMutation() {
  return Urql.useMutation<IDeleteTeamMemberMutation, IDeleteTeamMemberMutationVariables>(DeleteTeamMemberDocument);
};
export const UpdateTeamMemberDocument = gql`
    mutation UpdateTeamMember($userId: uuid!, $teamId: uuid!, $data: team_member_set_input!) {
  update_team_member_by_pk(
    pk_columns: {user_id: $userId, team_id: $teamId}
    _set: $data
  ) {
    ...TeamMember
  }
}
    ${TeamMemberFragmentDoc}`;

export function useUpdateTeamMemberMutation() {
  return Urql.useMutation<IUpdateTeamMemberMutation, IUpdateTeamMemberMutationVariables>(UpdateTeamMemberDocument);
};
export const UpdateTeamNotificationSettingsDocument = gql`
    mutation UpdateTeamNotificationSettings($teamId: uuid!, $settings: jsonb!) {
  update_team_by_pk(
    pk_columns: {id: $teamId}
    _append: {notification_settings: $settings}
  ) {
    id
    notification_settings
  }
}
    `;

export function useUpdateTeamNotificationSettingsMutation() {
  return Urql.useMutation<IUpdateTeamNotificationSettingsMutation, IUpdateTeamNotificationSettingsMutationVariables>(UpdateTeamNotificationSettingsDocument);
};