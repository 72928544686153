import {
  BlockElementAssetState,
  BlockElementCheckbox,
  BlockElementChoice,
  BlockElementConsumables,
  BlockElementHeading,
  BlockElementInspection,
  BlockElementMedia,
  BlockElementMeterReading,
  BlockElementParagraph,
  BlockElementText,
  BlockElementToleranceCheck,
  BlockElementYesNo,
  IBlockElementTypeEnum,
} from "@elara/db"
import * as yup from "yup"
import { nanoid } from "nanoid"
import { v4 as uuidv4 } from "uuid"

// Asset State
export const assetStateSchema = (options: { isTemplate: boolean }) =>
  yup.object().shape({
    element_type: yup.mixed().oneOf([IBlockElementTypeEnum.AssetState]),
    config: yup.object().shape({
      asset_id: options.isTemplate
        ? yup.string().uuid().nullable()
        : yup.string().uuid().required(),
      default_asset_state_variant_id: yup.string().uuid().nullable(),
    }),
  })

export function initialBlockElementAssetState(): BlockElementAssetState {
  return {
    config: {
      asset_id: null as unknown as string,
      default_asset_state_variant_id: null as string | null,
    },
    response: null,
    relationship_data: null,
    element_type: IBlockElementTypeEnum.AssetState,
  }
}

// Checkbox
export const checkboxSchema = yup.object().shape({
  element_type: yup.mixed().oneOf([IBlockElementTypeEnum.Checkbox]),
  config: yup.object().shape({ description: yup.string().required() }),
})

export function initialBlockElementCheckbox(): BlockElementCheckbox {
  return {
    response: null,
    config: { description: "" },
    element_type: IBlockElementTypeEnum.Checkbox,
  }
}

// Choice
export const choiceSchema = yup.object().shape({
  element_type: yup.mixed().oneOf([IBlockElementTypeEnum.Choice]),
  config: yup.object().shape({
    description: yup.string().required(),
    multiple: yup.boolean().required(),
    options: yup
      .array(
        yup.object().shape({
          id: yup.string().required(),
          label: yup.string().required(),
        })
      )
      .required(),
  }),
})

export function initialBlockElementChoice(): BlockElementChoice {
  return {
    config: {
      description: "",
      multiple: false,
      options: [
        { label: "", id: nanoid(5) },
        { label: "", id: nanoid(5) },
      ],
    },
    response: null,
    element_type: IBlockElementTypeEnum.Choice,
  }
}

// Consumables
export const consumablesSchema = yup.object().shape({
  element_type: yup.mixed().oneOf([IBlockElementTypeEnum.Consumables]),
  config: yup.object().shape({
    asset_id: yup.string().nullable(),
  }),
})

export function initialBlockElementConsumables(): BlockElementConsumables {
  return {
    config: {
      asset_id: null as unknown as string,
      consumables: [],
    },
    response: null,
    relationship_data: null,
    element_type: IBlockElementTypeEnum.Consumables,
  }
}

// Heading
export const headingSchema = yup.object().shape({
  element_type: yup.mixed().oneOf([IBlockElementTypeEnum.Heading]),
  config: yup.object().shape({ description: yup.string().required() }),
})

export function initialBlockElementHeading(): BlockElementHeading {
  return {
    response: null,
    config: { description: "" },
    element_type: IBlockElementTypeEnum.Heading,
  }
}

// Inspection
export const inspectionSchema = yup.object().shape({
  element_type: yup.mixed().oneOf([IBlockElementTypeEnum.Inspection]),
  config: yup.object().shape({ description: yup.string().required() }),
})

export function initialBlockElementInspection(): BlockElementInspection {
  return {
    response: null,
    config: { description: "" },
    element_type: IBlockElementTypeEnum.Inspection,
  }
}

// Media
export const mediaSchema = yup.object().shape({
  element_type: yup.mixed().oneOf([IBlockElementTypeEnum.Media]),
  config: yup.object().shape({ description: yup.string().required() }),
})

export function initialBlockElementMedia(): BlockElementMedia {
  return {
    response: null,
    config: { description: "" },
    element_type: IBlockElementTypeEnum.Media,
  }
}

// Meter Reading
export const meterReadingSchema = (options: { isTemplate: boolean }) =>
  yup.object().shape({
    element_type: yup.mixed().oneOf([IBlockElementTypeEnum.MeterReading]),
    config: yup.object().shape({
      asset_id: options.isTemplate ? yup.string().nullable() : yup.string().required(),
      meter_id: options.isTemplate ? yup.string().nullable() : yup.string().required(),
    }),
  })

export function initialBlockElementMeterReading(): BlockElementMeterReading {
  return {
    config: {
      meter_reading_id: uuidv4(),
      asset_id: null as unknown as string,
      meter_id: null as unknown as string,
    },
    response: null,
    relationship_data: null,
    element_type: IBlockElementTypeEnum.MeterReading,
  }
}

// Paragraph
export const paragraphSchema = yup.object().shape({
  element_type: yup.mixed().oneOf([IBlockElementTypeEnum.Paragraph]),
  config: yup.object().shape({ description: yup.string().required() }),
})

export function initialBlockElementParagraph(): BlockElementParagraph {
  return {
    response: null,
    config: { description: "" },
    element_type: IBlockElementTypeEnum.Paragraph,
  }
}

// Text Field
export const textFieldSchema = yup.object().shape({
  element_type: yup.mixed().oneOf([IBlockElementTypeEnum.Text]),
  config: yup.object().shape({ description: yup.string().required() }),
})

export function initialBlockElementText(): BlockElementText {
  return {
    config: { description: "" },
    response: null,
    element_type: IBlockElementTypeEnum.Text,
  }
}

// Tolerance Check
export const toleranceCheckSchema = yup.object().shape({
  element_type: yup.mixed().oneOf([IBlockElementTypeEnum.ToleranceCheck]),
  config: yup.object().shape({
    measurements: yup.array(yup.object().shape({ max_tolerance: yup.number() })).required(),
    unit: yup.string().required(),
  }),
})

export function initialBlockElementToleranceCheck(): BlockElementToleranceCheck {
  return {
    response: null,
    config: { unit: "kg", measurements: [] },
    element_type: IBlockElementTypeEnum.ToleranceCheck,
  }
}

// Yes/No
export const yesNoSchema = yup.object().shape({
  element_type: yup.mixed().oneOf([IBlockElementTypeEnum.YesNo]),
  config: yup.object().shape({ description: yup.string().required() }),
})

export function initialBlockElementYesNo(): BlockElementYesNo {
  return {
    response: null,
    config: { description: "" },
    element_type: IBlockElementTypeEnum.YesNo,
  }
}
