import { AssetWithParents } from "@components/asset/asset-with-parents"
import { ConsumableStorageLocation } from "@components/consumable/consumable-storage-location"
import { selectConsumable } from "@components/select/consumable-multi-select-dialog"
import { Button } from "@components/shared"
import { CurrencyValue } from "@components/shared/currency-value"
import { TextInputWithUnit } from "@components/shared/text-input-with-unit"
import toast from "@components/shared/toast"
import { Tooltip } from "@components/shared/tooltip"
import TouchTargetSize from "@components/shared/touch-target-size"
import { uuid } from "@elara/db"
import { orderBy } from "@elara/select"
import {
  ConsumableCompactByIdDocument,
  IConsumableCompactByIdQuery,
  IConsumableCompactByIdQueryVariables,
  IInsertConsumableLogMutation,
  IInsertConsumableLogMutationVariables,
  InsertConsumableLogDocument,
} from "@graphql/documents/consumable.generated"
import {
  ITaskConsumableUsedFragment,
  IWorkOrderFragment,
} from "@graphql/documents/fragments.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { Minus, Nut, PencilSimple, Plus, Shapes } from "@phosphor-icons/react"
import { LinkWithBackgroundLocation } from "@utils/location"
import { useState } from "react"
import React from "react"
import { useClient } from "urql"

import { EmptySection } from "./empty"
import {
  ChangeMaterialAdjustmentDocument,
  IChangeMaterialAdjustmentMutation,
  IChangeMaterialAdjustmentMutationVariables,
} from "./material/actions.generated"
import { MaterialInlineForm } from "./material/inline-form"
import { Section } from "./section"

export function totalMaterialCost(task: IWorkOrderFragment) {
  return task.consumables_used.reduce(
    (acc, consumableUsed) =>
      acc + Math.abs(consumableUsed.adjustment ?? 0) * (consumableUsed.cost_per_unit ?? 0),
    0
  )
}

function TableRow(props: { log: ITaskConsumableUsedFragment; task: IWorkOrderFragment }) {
  const { log, task } = props

  const [isEditing, setIsEditing] = useState(false)
  const client = useClient()
  const scope = usePermissionScope(IPermissionScopeEnum.AppDataEntry)

  const updateAdjustment = async (id: uuid, newAdjustment: number) => {
    const res = await client
      .mutation<
        IChangeMaterialAdjustmentMutation,
        IChangeMaterialAdjustmentMutationVariables
      >(
        ChangeMaterialAdjustmentDocument,
        {
          id: id,
          newAdjustment,
        },
        scope.context()
      )
      .toPromise()

    if (res.error) {
      toast.error(i18n.t("common.generic_toast_error"))
      throw res.error
    }
  }

  if (!log) return null
  const asset = task.assets.find((a) => a.asset_id === log.asset_id)?.asset

  if (isEditing) {
    return (
      <tr>
        <td colSpan={4} className="py-2">
          <MaterialInlineForm
            log={log}
            task={task}
            onDiscard={() => setIsEditing(false)}
            onSave={() => setIsEditing(false)}
          />
        </td>
      </tr>
    )
  }

  const material = (
    <>
      <div className="mt-2 flex  items-center">
        <LinkWithBackgroundLocation
          to={`/consumable/${log.consumable_id}`}
          className="group line-clamp-2 block hover:text-gray-900">
          <span className="font-medium group-hover:underline">{log.consumable?.name}</span>
          <span className="ml-1 text-gray-500">{log.consumable?.public_id}</span>
        </LinkWithBackgroundLocation>
        {asset && (
          <span className="relative ml-2 flex items-center text-gray-500 hover:text-gray-700">
            <Tooltip
              content={<AssetWithParents asset={asset} showAvatar />}
              delayDuration={50}>
              <Shapes />
              <TouchTargetSize />
            </Tooltip>
          </span>
        )}
      </div>
      <div className="mt-2 flex items-center">
        <ConsumableStorageLocation
          storageLocation={
            log.consumable?.storage_locations?.find((s) => s.place_id === log.place_id) ??
            null
          }
        />
      </div>
    </>
  )

  const quantity = (
    <div className="flex items-center gap-x-1">
      <Button
        type="tertiary"
        size="extra-small"
        icon={Minus}
        color="gray"
        className="shrink-0"
        onClick={() => {
          updateAdjustment(log.id, log.adjustment + 1)
        }}
      />
      <TextInputWithUnit
        defaultValue={-log.adjustment}
        key={log.adjustment}
        inputClassName="text-right pr-2 shrink-0 w-12"
        unit={log.consumable?.unit ?? ""}
        onBlur={async (e) => {
          const adjustment = parseInt(e.target.value)
          if (isNaN(adjustment)) {
            e.target.value = (-log.adjustment).toString()
            return
          }
          try {
            updateAdjustment(log.id, -adjustment)
          } catch {
            e.target.value = (-log.adjustment).toString()
          }
        }}
      />
      <Button
        type="tertiary"
        size="extra-small"
        icon={Plus}
        color="gray"
        className="shrink-0"
        onClick={() => {
          updateAdjustment(log.id, log.adjustment - 1)
        }}
      />
    </div>
  )
  return (
    <React.Fragment key={log.id}>
      <tr key={log.id} className="hidden @2xl:table-row">
        <td className="py-2">{material}</td>
        <td className="whitespace-nowrap px-3 py-2 ">{quantity}</td>
        <td className="px-3 py-2 text-right tabular-nums">
          <CurrencyValue value={Math.abs(log.adjustment ?? 0) * (log.cost_per_unit ?? 0)} />
        </td>
        <td className="px-1 py-0.5 ">
          <Button
            type="tertiary"
            icon={PencilSimple}
            color="gray"
            onClick={() => setIsEditing(true)}
          />
        </td>
      </tr>
      <tr key={log.id} className="@2xl:hidden">
        <td className="pt-2" colSpan={2}>
          <div className="flex space-x-3">
            <div className="flex-1">{material}</div>
            <Button
              type="tertiary"
              icon={PencilSimple}
              color="gray"
              onClick={() => setIsEditing(true)}
            />
          </div>
        </td>
      </tr>
      <tr className="border-b border-gray-100 @2xl:hidden">
        <td className="whitespace-nowrap px-3 pb-2 ">{quantity}</td>
        <td className="px-3 pb-2 text-right tabular-nums">
          <CurrencyValue value={Math.abs(log.adjustment ?? 0) * (log.cost_per_unit ?? 0)} />
        </td>
      </tr>
    </React.Fragment>
  )
}

function MaterialTable(props: { task: IWorkOrderFragment; onAddMaterial: () => void }) {
  const { task } = props
  const workOrder = task
  const logs = orderBy(workOrder.consumables_used, {
    consumable: { name: "asc" },
    adjustment_at: "asc",
  })

  return (
    <div className="rounded-lg bg-gray-50 p-3 @container">
      <table className="min-w-full divide-y divide-gray-300 text-sm">
        <thead>
          <tr>
            <th
              scope="col"
              className="w-full pb-2 pr-3 text-left text-sm font-medium text-gray-500">
              {i18n.t("common:consumable", { count: 1 })}
            </th>
            <th
              scope="col"
              className="hidden px-3 pb-2 text-center text-sm font-medium text-gray-500 @2xl:table-cell">
              {i18n.t("consumables:fields.quantity")}
            </th>
            <th
              scope="col"
              className="px-3 pb-2 text-right text-sm font-medium text-gray-500 @2xl:text-left">
              {i18n.t("common:cost")}
            </th>
            <th className="hidden @2xl:table-cell" />
          </tr>
        </thead>
        <tbody className="@2xl:divide-y @2xl:divide-gray-100">
          {logs.map((log) => (
            <TableRow log={log} task={workOrder} key={log.id} />
          ))}
          <tr>
            <td className="py-0">
              <Button
                type="tertiary"
                icon={Plus}
                onClick={props.onAddMaterial}
                size="extra-small">
                {i18n.t("tasks:report.material.add")}
              </Button>
            </td>

            <td colSpan={2} className="px-3 py-2 text-right font-medium tabular-nums">
              <span className="mr-2 font-medium text-gray-700">
                {i18n.t("common:total")}
              </span>
              <CurrencyValue value={totalMaterialCost(workOrder)} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export const MaterialReport = (props: { task: IWorkOrderFragment }) => {
  const client = useClient()
  const { task } = props
  const workOrder = task

  const scope = usePermissionScope(IPermissionScopeEnum.AppDataEntry)

  const pickMaterial = async () => {
    const ids = await selectConsumable({ type: "multiple" })

    if (!ids) return

    for (let id of ids) {
      const consumableQueryRes = await client
        .query<IConsumableCompactByIdQuery, IConsumableCompactByIdQueryVariables>(
          ConsumableCompactByIdDocument,
          { id },
          { requestPolicy: "cache-only" }
        )
        .toPromise()
      const consumable = consumableQueryRes.data?.consumable_by_pk
      await client
        .mutation<IInsertConsumableLogMutation, IInsertConsumableLogMutationVariables>(
          InsertConsumableLogDocument,
          {
            data: {
              consumable_id: id,
              task_id: workOrder.id,
              asset_id: workOrder.assets[0]?.asset_id ?? null,
              adjustment: -1,
              place_id: consumable?.storage_locations?.[0]?.place_id ?? null,
              cost_per_unit: consumable?.cost ?? 0,
            },
          },
          scope.context()
        )
        .toPromise()
    }
  }

  return (
    <Section icon={<Nut />} title={i18n.t("tasks:report.material.title")} action={null}>
      {task.consumables_used.length === 0 ? (
        <EmptySection
          title={i18n.t("tasks:report.material.no_reports.title")}
          message={i18n.t("tasks:report.material.no_reports.message")}
          actionText={i18n.t("tasks:report.material.add_first")}
          onAction={pickMaterial}
          icon={Nut}
        />
      ) : (
        <MaterialTable task={task} onAddMaterial={pickMaterial} />
      )}
    </Section>
  )
}
