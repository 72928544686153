import { VStack } from "@components/layout"
import { RichTextFormController } from "@components/shared/form/rich-text-form-controller"
import RichText from "@components/shared/rich-text"
import { StaticRichText } from "@components/shared/static-rich-text"
import { TextArea } from "@components/shared/text-area"
import { BlockElementText } from "@elara/db"
import { useDebouncedPersistingText } from "@hooks/use-debounced-persisting-state"
import i18n from "@i18n"

import { BlockElementFormProps, BlockElementProps } from "./block-element-types"

export const TextFieldForm = (props: BlockElementFormProps) => {
  return (
    <VStack space={8} flex="1">
      <RichTextFormController
        className="max-h-36"
        placeholder={i18n.t("tasks:checklist.text_field.placeholder")}
        name={`${props.field}.${props.index}.config.description`}
        noStyle
        control={props.control}
      />
      <TextArea
        placeholder={i18n.t("tasks:checklist.text_field.message_placeholder")}
        disabled
        autoComplete="nofill"
        className="h-16"
      />
    </VStack>
  )
}

export const TextFieldBlockElement = (props: BlockElementProps<BlockElementText>) => {
  const [, setResponseValue] = useDebouncedPersistingText(
    props.element.response?.value ?? "",
    async (value: string) => {
      await props.updateResponse?.(props.element.id, value === "<p></p>" ? null : { value })
    },
    1000
  )

  return (
    <VStack space={8} className="flex-1">
      <StaticRichText className="mb-2 " content={props.element.config.description} />
      <RichText
        content={props.element.response?.value}
        onUpdate={(e) => {
          setResponseValue(e.editor.getHTML())
        }}
        className="max-h-36 w-full flex-1 cursor-text rounded border"
        placeholder={i18n.t("tasks:checklist.text_field.message_placeholder")}
      />
    </VStack>
  )
}
