import {
  DeleteFileDocument,
  EditFileNameDocument,
  IDeleteFileMutation,
  IDeleteFileMutationVariables,
  IEditFileNameMutation,
  IEditFileNameMutationVariables,
  IInsertUploadMutation,
  IInsertUploadMutationVariables,
  InsertUploadDocument,
  IUploadDataFragment,
} from "@graphql/documents/upload.generated"
import axios, { AxiosProgressEvent, AxiosResponse } from "axios"
import { useCallback } from "react"
import { useClient } from "urql"
import { v4 } from "uuid"

import useCallbackRef from "./use-callback-ref"
import { IPermissionScopeEnum, usePermissionScope } from "./use-permission-scope"
export { useEditFileNameMutation } from "@graphql/documents/upload.generated"

export interface StorageData {
  url: string
  thumbnailUrl?: string
}

type OnUploadProgressHandler = (event: AxiosProgressEvent) => void

export const uploadFilesToStorage = async (
  files: File[],
  onUploadProgress?: OnUploadProgressHandler
): Promise<StorageData[]> => {
  // Prepare form data
  const form = new FormData()
  files.forEach((file) => form.append("files", file))
  // Perform upload request
  const res: AxiosResponse<StorageData[]> = await axios.request({
    url: `/api/storage/upload`,
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress,
    timeout: 120000,
    data: form,
  })

  return res.data
}

export const useFileUpload = () => {
  const client = useClient()

  const uploadFile = useCallback(
    async (
      file: File,
      onUploadProgress?: OnUploadProgressHandler,
      id = v4()
    ): Promise<IUploadDataFragment> => {
      const [{ url, thumbnailUrl }] = await uploadFilesToStorage([file], onUploadProgress)
      const variables = {
        url,
        thumbnailUrl,
        mimeType: file.type,
        fileName: file.name,
        fileSize: file.size,
        id,
      }
      const res = await client
        .mutation<IInsertUploadMutation, IInsertUploadMutationVariables>(
          InsertUploadDocument,
          variables
        )
        .toPromise()
      if (res.data?.insert_upload_one) {
        return res.data.insert_upload_one
      }
      throw res.error
    },
    [client]
  )
  return uploadFile
}

export const useEditFileName = () => {
  const client = useClient()
  const editFileName = useCallback(
    async (id: string, fileName: string) => {
      const res = await client
        .mutation<IEditFileNameMutation, IEditFileNameMutationVariables>(
          EditFileNameDocument,
          {
            id,
            fileName,
          }
        )
        .toPromise()
      if (res.data?.update_upload_by_pk) {
        return res.data.update_upload_by_pk
      }
      throw res.error
    },
    [client]
  )
  return editFileName
}

export const useDeleteFile = () => {
  const client = useClient()
  const adminScope = usePermissionScope(IPermissionScopeEnum.AppAccountManagement)
  const userScope = usePermissionScope(IPermissionScopeEnum.AppUser)
  const deleteFile = useCallbackRef(async (id: string) => {
    const res = await client
      .mutation<IDeleteFileMutation, IDeleteFileMutationVariables>(
        DeleteFileDocument,
        {
          id,
        },
        adminScope.hasScope ? adminScope.context() : userScope.context()
      )
      .toPromise()
    if (res.data?.delete_upload_by_pk) {
      return res.data.delete_upload_by_pk
    }
    if (res.error) throw res.error
  })
  return deleteFile
}
