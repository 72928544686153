import { Data } from "@elara/select"
import React from "react"

import { useDataViewFilter } from "./hooks"

export const DataViewFilterContext = React.createContext<
  ReturnType<typeof useDataViewFilter>
>(undefined!)

export function useDataViewFilterContext<D extends Data>() {
  const context = React.useContext(DataViewFilterContext)
  if (!context) {
    throw new Error("useDataViewFilterContext must be used within a DataViewFilter")
  }
  return context as unknown as ReturnType<typeof useDataViewFilter<D>>
}
