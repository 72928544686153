import { IconData, TeamIcon } from "@components/shared/team-icons"
import { ITeamNameIconFragment } from "@graphql/documents/fragments.generated"

export type TeamTagProps = {
  team: Omit<ITeamNameIconFragment, "__typename">
}

export const TeamTag = ({ team }: TeamTagProps) => {
  const icon = team?.icon as IconData

  return (
    <span className="inline-flex min-w-0 items-center">
      <TeamIcon name={icon?.name} color={icon?.color} className="mr-1.5 h-4 w-4 shrink-0" />

      <span className="truncate">{team.name}</span>
    </span>
  )
}
