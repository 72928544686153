export const getImageProps = async (imageUrl: string) => {
  const imageEl = new Image()

  imageEl.src = imageUrl
  await imageEl.decode()

  return imageEl
}

export const resize = (
  width: number,
  height: number,
  maxWidth = 800, // Maximum width for resizing
  maxHeight = 600 // Maximum height for resizing
): { width: number; height: number } => {
  // Calculate the aspect ratio of the original image
  const aspectRatio = width / height

  // Initialize the new width and height variables
  let newWidth: number
  let newHeight: number

  // Check if the original width or height exceeds the maximum values
  if (width > maxWidth || height > maxHeight) {
    // Calculate the new width and height while maintaining the aspect ratio
    if (aspectRatio > 1) {
      // Landscape image
      newWidth = maxWidth
      newHeight = newWidth / aspectRatio
    } else {
      // Portrait or square image
      newHeight = maxHeight
      newWidth = newHeight * aspectRatio
    }
  } else {
    // The original image is within the maximum dimensions, no need to resize
    newWidth = width
    newHeight = height
  }

  // Return the new width and height as an object
  return { width: newWidth, height: newHeight }
}
