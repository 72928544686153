import { Dispatch, SetStateAction, useState } from "react"
import { useThrottledCallback } from "use-debounce"
import { DebouncedState } from "use-debounce"

export function useThrottledState<T>(
  initialValue: T,
  wait: number
): [T, DebouncedState<Dispatch<SetStateAction<T>>>] {
  const [throttledValue, setThrottledValue] = useState<T>(initialValue)

  const updateThrottledValue = useThrottledCallback(setThrottledValue, wait)

  return [throttledValue, updateThrottledValue]
}
