import { AssetPlaceSingleSelect } from "@components/asset/asset-place-select"
import { Button } from "@components/shared"
import { ContactMultiSelect } from "@components/shared/contact-select"
import { CurrencyInput } from "@components/shared/currency-input"
import { DialogRootProps } from "@components/shared/dialog"
import { DialogForm } from "@components/shared/dialog-form"
import { FormField } from "@components/shared/form/form-field"
import { ImageUploadFormElement } from "@components/shared/image-upload-form-element"
import { TextInput } from "@components/shared/text-input"
import toast from "@components/shared/toast"
import {
  ISetPlaceInConsumableLogsWithNoPlaceMutation,
  ISetPlaceInConsumableLogsWithNoPlaceMutationVariables,
  SetPlaceInConsumableLogsWithNoPlaceDocument,
  useInsertConsumableLogMutation,
  useInsertConsumableMutation,
  useUpdateConsumableMutation,
} from "@graphql/documents/consumable.generated"
import { IConsumableFragment } from "@graphql/documents/fragments.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { MapPin, Nut, Plus, TrashSimple } from "@phosphor-icons/react"
import {
  propertiesFormValues,
  useConsumableProperties,
  yupPropertiesSchema,
} from "@utils/properties"
import { FieldArray } from "formik"
import { useMemo } from "react"
import { useClient } from "urql"
import * as Yup from "yup"

import { ConsumableGroupSingleSelect } from "../select/consumable-group-select"

type FormValues = Partial<Omit<IConsumableFragment, "storage_locations">> & {
  storage_locations: { place_id: string | null; area: string | null }[]
}

type Props = {
  initialValues?: Partial<FormValues>
} & Required<Pick<DialogRootProps, "isOpen" | "onOpenChange">>

const CreateEditConsumableFormDialog: React.FC<Props> = (props) => {
  const client = useClient()

  const properties = useConsumableProperties()

  const editScope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)
  const createScope = usePermissionScope(IPermissionScopeEnum.AppAssetCreate)
  const dataEntryScope = usePermissionScope(IPermissionScopeEnum.AppDataEntry)

  const ConsumableSchema = useMemo(() => {
    return Yup.object().shape({
      public_id: Yup.string()
        .nullable()
        .max(255)
        .test("unique", i18n.t("common:forms.not_unique"), async (value) => {
          if (!value) return true
          const { data } = await client
            .query(
              `query($public_id: String!) {
              consumable(where: {public_id: {_eq: $public_id}}) {
                id
              }
            }`,
              { public_id: value }
            )
            .toPromise()
          const n = data.consumable?.length ?? 0
          if (n === 0) return true
          if (n === 1 && data.consumable[0].id === props.initialValues?.id) return true
          return false
        }),
      name: Yup.string().required(
        i18n.t("common:forms.is_required", {
          field: i18n.t("consumables:fields.name"),
        })
      ),
      group_id: Yup.string().nullable(),
      description: Yup.string().nullable(),
      unit: Yup.string().required(
        i18n.t("common:forms.is_required", {
          field: i18n.t("consumables:fields.unit"),
        })
      ),
      cost: Yup.number().default(0),
      quantity: Yup.number().nullable(),
      min_quantity: Yup.number().default(0),
      metadata: Yup.array().of(
        Yup.object().shape({ key: Yup.string(), value: Yup.string() })
      ),
      properties: yupPropertiesSchema(properties),
      asset_ids: Yup.array().of(Yup.string()),
      contact_ids: Yup.array().of(Yup.string()),
      storage_locations: Yup.array()
        .of(
          Yup.object({
            place_id: Yup.string().required(
              i18n.t("consumables:messages.storage_location_place_required")
            ),
            area: Yup.string().nullable(),
          })
        )
        .test(
          "unique",
          i18n.t("consumables:messages.storage_location_place_unique"),
          (value) => {
            if (!value) return true
            const places = value.map((v) => v.place_id)
            return places.length === new Set(places).size
          }
        ),
    })
  }, [properties])

  const [, insertConsumable] = useInsertConsumableMutation()
  const [, updateConsumable] = useUpdateConsumableMutation()
  const [, insertConsumableLog] = useInsertConsumableLogMutation()

  const onSubmit = async (values: FormValues) => {
    const data = {
      name: values.name,
      unit: values.unit ?? i18n.t("consumables:fields.unit_default"),
      metadata: values.metadata ?? [],
      description: values.description,
      public_id: values.public_id || "",
      group_id: values.group_id || null,
      min_quantity: values.min_quantity,
      contact_ids: values.contact_ids,
      properties: values.properties,
      location: values.location,
      avatar_id: values.avatar?.id ?? null,
      cost: values.cost || 0,
    }

    const id = values.id
    if (id) {
      // Rewrite the place_id for all consumable logs that don't have a place_id
      if (values.storage_locations[0]?.place_id && dataEntryScope.hasScope) {
        await client
          .mutation<
            ISetPlaceInConsumableLogsWithNoPlaceMutation,
            ISetPlaceInConsumableLogsWithNoPlaceMutationVariables
          >(
            SetPlaceInConsumableLogsWithNoPlaceDocument,
            {
              consumableId: id,
              placeId: values.storage_locations[0].place_id,
            },
            dataEntryScope.context()
          )
          .toPromise()
      }

      const consumable = await updateConsumable(
        {
          id,
          data,
          storage_locations: values.storage_locations.map((s) => ({
            place_id: s.place_id,
            area: s.area,
            consumable_id: values.id,
          })),
          storage_location_place_ids: values.storage_locations
            .map((s) => s.place_id)
            .filter(Boolean) as string[],
        },
        editScope.context()
      )

      if (!consumable.data?.update_consumable_by_pk) {
        toast.error(i18n.t("common:generic_toast_error"))
        throw new Error("update failed")
      }
    } else {
      const consumable = await insertConsumable(
        {
          data: {
            ...data,
            storage_locations: {
              data: values.storage_locations.map((s) => ({
                place_id: s.place_id,
                area: s.area,
              })),
            },
          },
        },
        createScope.context()
      )

      if (consumable.data?.insert_consumable_one?.id) {
        if (values.quantity && consumable?.data?.insert_consumable_one?.id) {
          await insertConsumableLog(
            {
              data: {
                consumable_id: consumable.data.insert_consumable_one.id,
                place_id: values.storage_locations[0]?.place_id,
                adjustment: values.quantity,
              },
            },
            dataEntryScope.context()
          )
        }
      } else {
        toast.error(i18n.t("common:generic_toast_error"))
        throw new Error("insert failed")
      }
    }
  }

  const initialValues = {
    ...(props.initialValues ?? {
      unit: i18n.t("consumables:fields.unit_default"),
      description: "",
      contact_ids: [],
      metadata: [],
      location: "",
      quantity: 0,
      min_quantity: 0,
      avatar: null,
      public_id: "",
      group_id: null,
      cost: 0,
    }),
    asset_ids:
      props.initialValues?.consumable_assets?.map((a) => a?.asset?.id)?.filter(Boolean) ??
      [],
    storage_locations: props.initialValues?.storage_locations ?? [],
    properties: propertiesFormValues(properties, props.initialValues?.properties ?? {}),
  }

  return (
    <>
      <DialogForm<FormValues>
        isOpen={props.isOpen}
        onOpenChange={props.onOpenChange}
        closeIcon
        className="md:max-w-3xl"
        title={
          props.initialValues?.id
            ? i18n.t("common:edit_token", {
                token: i18n.t("common:consumable", { count: 1 }),
              })
            : i18n.t("consumables:actions.new_consumable")
        }
        formikConfig={{
          onSubmit,
          validationSchema: ConsumableSchema,
          initialValues,
          validateOnBlur: true,
          validateOnChange: false,
        }}>
        {(formik) => (
          <div className="my-3 grid grid-cols-12 gap-x-4">
            <FormField name="avatar" label="Avatar" noStyle>
              {({ field, helpers }) => (
                <div className="col-span-12">
                  <ImageUploadFormElement
                    fallback={<Nut />}
                    uploadLabel={i18n.t("consumables:labels.upload_avatar")}
                    editLabel={i18n.t("consumables:labels.edit_avatar")}
                    upload={field.value}
                    onUploadChange={helpers.setValue}
                  />
                </div>
              )}
            </FormField>

            <FormField
              name="name"
              label={i18n.t("consumables:fields.name")}
              className="col-span-12 sm:col-span-6">
              <TextInput required />
            </FormField>

            <FormField
              name="public_id"
              label={i18n.t("consumables:fields.public_id")}
              className="col-span-12 sm:col-span-6">
              <TextInput placeholder={i18n.t("common:automatically_generated")} />
            </FormField>

            <FormField
              optional
              name="description"
              label={i18n.t("consumables:fields.description")}
              className="col-span-12">
              <TextInput size="small" />
            </FormField>

            {!props.initialValues?.id && (
              <FormField
                name="quantity"
                label={i18n.t("consumables:fields.quantity")}
                className="col-span-6 sm:col-span-4">
                <TextInput type="number" />
              </FormField>
            )}

            <FormField
              name="min_quantity"
              label={i18n.t("consumables:fields.min_quantity")}
              className="col-span-6 sm:col-span-4">
              <TextInput required type="number" />
            </FormField>

            <FormField
              name="unit"
              label={i18n.t("consumables:fields.unit")}
              className="col-span-6 sm:col-span-4">
              <TextInput placeholder={i18n.t("consumables:fields.unit_default")} />
            </FormField>
            <FormField
              name="cost"
              type="number"
              label={i18n.t("consumables:fields.cost_per_unit")}
              className="col-span-6 sm:col-span-4">
              <CurrencyInput />
            </FormField>

            <hr className="col-span-12 mb-3 border-gray-100" />

            <>
              <div className="col-span-12 mb-3">
                <div className="mb-2 font-medium text-gray-600">
                  {i18n.t("consumables:labels.storage_location", { count: 2 })}
                </div>
                <FieldArray name="storage_locations">
                  {(arrayHelpers) =>
                    formik.values.storage_locations?.length ? (
                      <div className="flex flex-col space-y-2">
                        {formik.values.storage_locations?.map((storage_location, index) => (
                          <div
                            className="flex flex-col rounded-lg bg-gray-50 px-3 pt-3"
                            key={`${storage_location.place_id}_${index}`}>
                            <div className="grid gap-x-4 md:grid-cols-[minmax(0,1fr)_minmax(0,1fr)_auto]">
                              <FormField
                                name={`storage_locations.${index}.place_id`}
                                label={i18n.t("common:location", { count: 1 })}
                                className="col-span-1">
                                {({ field }) => (
                                  <AssetPlaceSingleSelect
                                    value={field.value}
                                    isClearable={false}
                                    onChange={(value) => {
                                      formik.setFieldValue(
                                        `storage_locations.${index}.place_id`,
                                        value
                                      )
                                    }}
                                  />
                                )}
                              </FormField>
                              <FormField
                                name={`storage_locations.${index}.area`}
                                label={i18n.t("consumables:fields.area")}
                                optional
                                className="col-span-1">
                                <TextInput />
                              </FormField>
                              <Button
                                color="gray"
                                type="tertiary"
                                size="extra-small"
                                icon={TrashSimple}
                                className="-mt-3 mb-2 sm:mb-5 sm:mt-0 sm:self-end"
                                onClick={() => arrayHelpers.remove(index)}>
                                {i18n.t("common:remove")}
                              </Button>
                            </div>
                          </div>
                        ))}
                        {typeof formik.errors?.storage_locations === "string" && (
                          <div className="rounded-lg bg-red-50 p-2 text-sm text-red-500">
                            {formik.errors?.storage_locations}
                          </div>
                        )}
                        <Button
                          icon={Plus}
                          color="gray"
                          type="tertiary"
                          className="self-start"
                          onClick={() => arrayHelpers.push({})}>
                          {i18n.t("consumables:actions.add_storage_location")}
                        </Button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        onClick={() => arrayHelpers.push({})}
                        className="flex w-full cursor-pointer items-center justify-center gap-2 rounded border border-dashed p-5 text-sm text-gray-500 hover:border-gray-400 hover:bg-gray-50 hover:text-gray-700 radix-state-open:border-gray-400 radix-state-open:bg-gray-50 radix-state-open:text-gray-700">
                        <MapPin size={20} className="text-gray-400" weight="fill" />
                        {i18n.t("consumables:actions.add_storage_location")}
                      </button>
                    )
                  }
                </FieldArray>
              </div>

              <hr className="col-span-12 mb-3 border-gray-100" />
            </>

            <h3 className="col-span-12 mb-3 font-medium text-gray-600">
              {i18n.t("common:information")}
            </h3>

            <FormField
              optional
              name="group_id"
              className="col-span-6"
              label={i18n.t("consumables:fields.group", { count: 1 })}>
              {({ field, helpers }) => (
                <ConsumableGroupSingleSelect
                  value={field.value}
                  onChange={helpers.setValue}
                />
              )}
            </FormField>

            {properties
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((prop) => {
                return (
                  <FormField
                    key={prop.id}
                    name={"properties." + prop.id + ".value"}
                    label={prop.name}
                    className="col-span-12 sm:col-span-6"
                    optional={!prop.required}>
                    <TextInput
                      type={prop.type === "number" ? "number" : "text"}
                      autoComplete="nofill"
                    />
                  </FormField>
                )
              })}

            <FormField
              name="contact_ids"
              label={i18n.t("common:contact", { count: 2 })}
              className="col-span-12 sm:col-span-6"
              hint={i18n.t("consumables:messages.contact_hint")}
              optional>
              {({ field, helpers }) => (
                <ContactMultiSelect {...field} onChange={helpers.setValue} compact />
              )}
            </FormField>

            <FormField name="metadata" className="col-span-12 mt-2">
              {({ field }) => (
                <FieldArray name="metadata">
                  {({ push, remove }) => (
                    <>
                      <div className="flex w-full items-center justify-between">
                        <h6 className="text-sm font-medium">
                          {i18n.t("common:forms.metadata.title")}
                        </h6>

                        <Button
                          icon={Plus}
                          color="gray"
                          size="small"
                          type="tertiary"
                          onClick={() => push({ key: "", value: "" })}>
                          {i18n.t("common:forms.metadata.add")}
                        </Button>
                      </div>

                      <div className="mt-2 grid gap-4 sm:grid-cols-2">
                        {(field.value || []).length > 0 ? (
                          field.value.map((_customField: any, index: number) => (
                            <div
                              key={`${(field.value || []).length}_${index}`}
                              className="grid gap-2">
                              <FormField
                                label={i18n.t("common:forms.metadata.fields.key")}
                                hasErrorPlaceholder={false}
                                name={`metadata.${index}.key`}>
                                <TextInput />
                              </FormField>

                              <FormField
                                optional
                                label={i18n.t("common:forms.metadata.fields.value")}
                                hasErrorPlaceholder={false}
                                name={`metadata.${index}.value`}>
                                <TextInput />
                              </FormField>

                              <div>
                                <Button
                                  color="gray"
                                  type="tertiary"
                                  icon={TrashSimple}
                                  onClick={() => remove(index)}>
                                  {i18n.t("common:forms.metadata.delete")}
                                </Button>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p className="col-span-3 text-sm text-gray-500">
                            {i18n.t("common:forms.metadata.empty", {
                              type: i18n.t("common:consumable", { count: 1 }),
                            })}{" "}
                            {!properties.length &&
                              i18n.t("consumables:messages.config_hint")}
                          </p>
                        )}
                      </div>
                    </>
                  )}
                </FieldArray>
              )}
            </FormField>
          </div>
        )}
      </DialogForm>
    </>
  )
}

export default CreateEditConsumableFormDialog
