import { useDisclosure } from "@hooks/use-disclosure"
import i18n from "@i18n"
import { startOfDay } from "date-fns"
import { useRef, useState } from "react"
import * as Yup from "yup"

import { DialogForm } from "../dialog-form"
import { FormField } from "../form/form-field"
import { DateTimeNative } from "../native-date-time-pickers"

export const useDataViewFilterDatePicker = () => {
  const datePicker = useDisclosure()
  const [onDatePickerSelect, setOnDatePickerSelect] = useState<
    ((date: Date) => void) | null
  >(null)
  const rejectRef = useRef<(() => void) | null>(null)

  const promptForDate = () => {
    datePicker.onOpen()
    return new Promise<Date>((resolve, reject) => {
      const cb = (date: Date) => {
        rejectRef.current = null
        resolve(date)
      }
      rejectRef.current = reject
      setOnDatePickerSelect(() => cb)
    })
  }

  const cancel = () => {
    try {
      rejectRef.current?.()
    } finally {
      rejectRef.current = null
    }
  }

  return { datePicker, onDatePickerSelect, cancel, promptForDate }
}

export type DataViewFilterDatePickerProps = {
  dataViewFilterDaterPicker: ReturnType<typeof useDataViewFilterDatePicker>
}
export const DataViewFilterDatePicker = (props: DataViewFilterDatePickerProps) => {
  const { datePicker, onDatePickerSelect, cancel } = props.dataViewFilterDaterPicker

  return (
    <DialogForm
      isOpen={datePicker.isOpen}
      onOpenChange={(isOpen) => {
        if (!isOpen) cancel()
        datePicker.changeOpen(isOpen)
      }}
      title={i18n.t("common:select_token", { token: i18n.t("common:timestamp") })}
      cancelText={i18n.t("common:cancel")}
      okText={i18n.t("common:select")}
      formikConfig={{
        initialValues: {
          date: startOfDay(new Date()) as Date,
        },
        validationSchema: Yup.object({
          date: Yup.date().required(
            i18n.t("common:forms.is_required", { field: i18n.t("common:timestamp") })
          ),
        }),
        onSubmit: (data) => {
          const { date } = data
          if (date) {
            onDatePickerSelect?.(date)
          }
        },
      }}>
      {() => (
        <div className="mt-4">
          <FormField name="date" label={i18n.t("common:timestamp")}>
            {({ field, helpers }) => (
              <DateTimeNative {...field} onChange={(val) => helpers.setValue(val)} />
            )}
          </FormField>
        </div>
      )}
    </DialogForm>
  )
}
