import {
  useAskForPushPermissions,
  usePushToggle,
} from "@components/notifications/use-onesignal"
import { defaultLandingPages } from "@components/settings/constants/default-landing-pages"
import SettingsPageLayout from "@components/settings/page-layout"
import { Button, DetailsItem } from "@components/shared"
import { IUserInfo, UserAvatar } from "@components/shared/avatar"
import { Select } from "@components/shared/select"
import Toggle from "@components/shared/toggle"
import { Tooltip } from "@components/shared/tooltip"
import { usePage } from "@contexts/page-context"
import { useUser } from "@contexts/user-context"
import { ISettingLevelEnum, ISettingTypeEnum } from "@elara/db"
import {
  useCheckEmailNotificationSettingQuery,
  useDeleteSettingMutation,
  useInsertSettingMutation,
  useUpdateUserLanguageMutation,
  useUpdateUserSettingsMutation,
} from "@graphql/documents/user.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { useLogout } from "@models/authentication"
import { WarningCircle } from "@phosphor-icons/react"
import Icons from "@resources/icons"
import { isAppleiOS } from "@utils"
import React, { useState } from "react"
import { changeAppLanguage, useLocaleOptions } from "src/i18n/languages"

import ChangePassword from "./change-password"
import EditUserProfile from "./edit-user-profile"

const SettingsProfileContent = () => {
  const user = useUser()
  const locales = useLocaleOptions()

  const [isVisibleEditProfile, setVisibleEditProfile] = useState(false)
  const closeEditProfile = React.useCallback(() => setVisibleEditProfile(false), [])

  const [isVisibleChangePassword, setVisibleChangePassword] = useState(false)
  const closeChangePassword = React.useCallback(() => setVisibleChangePassword(false), [])

  const [checkEmailNotificationSettingResult] = useCheckEmailNotificationSettingQuery()
  const isEmailNotificationEnable =
    checkEmailNotificationSettingResult.data?.setting_config_aggregate.aggregate?.count ===
    0

  const homePage =
    defaultLandingPages.find((page) => page.path === user.profile.settings.homePage) ??
    defaultLandingPages[0]

  const [, updateUserSettings] = useUpdateUserSettingsMutation()
  const [, updateUserMutation] = useUpdateUserLanguageMutation()

  const handleChangeLanguage = async (value: string | undefined | null) => {
    if (value) {
      await updateUserMutation({ userId: user.id, language: value })
      await changeAppLanguage(value)
    }
  }

  const handleChangeHomePage = async (value: string | undefined | null) => {
    if (value) {
      await updateUserSettings({ id: user.id, settings: { homePage: value } })
    }
  }

  useAskForPushPermissions()

  const { isPushBlocked, isPushEnabled, togglePush } = usePushToggle()

  const appUserScope = usePermissionScope(IPermissionScopeEnum.AppUser)

  const [, insertSetting] = useInsertSettingMutation()
  const [, deleteSetting] = useDeleteSettingMutation()

  const handleEmailNotificationToggle = (value: boolean) => {
    if (!value) {
      insertSetting(
        {
          config: {
            level: ISettingLevelEnum.User,
            type: ISettingTypeEnum.DisableEmailNotification,
          },
        },
        appUserScope.context()
      )
    } else {
      deleteSetting(
        {
          config: {
            level: { _eq: ISettingLevelEnum.User },
            type: { _eq: ISettingTypeEnum.DisableEmailNotification },
          },
        },
        appUserScope.context()
      )
    }
  }

  return (
    <>
      <div className="max-w-md space-y-4 py-4">
        <UserAvatar
          size={160}
          className="ring-4 ring-blue-50 ring-offset-0"
          user={
            {
              ...user.profile,
              avatar: user.profile?.avatar
                ? {
                    ...user.profile.avatar,
                    thumbnail_url: user.profile?.avatar?.url ?? null,
                  }
                : null,
            } as IUserInfo
          }
        />

        <DetailsItem direction="vertical">
          <DetailsItem.Label>{i18n.t("settings:profile.fields.name")}</DetailsItem.Label>
          <DetailsItem.Value
            placeholder={i18n.t("common:no_token_given", {
              token: i18n.t("settings:profile.fields.name"),
            })}>
            {user.profile?.first_name} {user.profile?.last_name}
          </DetailsItem.Value>
        </DetailsItem>

        <DetailsItem direction="vertical">
          <DetailsItem.Label>{i18n.t("settings:profile.fields.email")}</DetailsItem.Label>
          <DetailsItem.Value
            placeholder={i18n.t("common:no_token_given", {
              token: i18n.t("settings:profile.fields.email"),
            })}>
            {user.profile?.email}
          </DetailsItem.Value>
        </DetailsItem>

        <DetailsItem direction="vertical">
          <DetailsItem.Label>{i18n.t("settings:profile.fields.phone")}</DetailsItem.Label>
          <DetailsItem.Value
            placeholder={i18n.t("common:no_token_given", {
              token: i18n.t("settings:profile.fields.phone"),
            })}>
            {user.profile?.phone}
          </DetailsItem.Value>
        </DetailsItem>
        <DetailsItem direction="vertical">
          <DetailsItem.Label>{i18n.t("settings:profile.fields.role")}</DetailsItem.Label>
          <DetailsItem.Value>{user.permissionRole?.name}</DetailsItem.Value>
        </DetailsItem>

        <hr className="w-full" />

        <DetailsItem direction="vertical" className="!space-y-3">
          <DetailsItem.Label>{i18n.t("common:notification_other")}</DetailsItem.Label>
          <DetailsItem.Value className="flex items-center space-x-2">
            <Toggle
              id="email-notification-toggle"
              checked={isEmailNotificationEnable ? true : false}
              onChange={(e) => handleEmailNotificationToggle(e.target.checked)}
            />
            <label className="select-none text-sm" htmlFor="email-notification-toggle">
              {i18n.t("settings:profile.notifications.email.title")}
            </label>
          </DetailsItem.Value>
          <DetailsItem.Value>
            <div className="flex items-center space-x-2">
              <Toggle
                id="push-notification-toggle"
                onChange={(e) => togglePush(e.target.checked)}
                checked={isPushEnabled}
                disabled={isPushBlocked}
              />
              <label className="select-none text-sm" htmlFor="push-notification-toggle">
                {i18n.t("settings:profile.notifications.push.title")}
              </label>
              {isPushBlocked && (
                <Tooltip
                  contentProps={{ side: "right" }}
                  content={i18n.t("settings:profile.notifications.push.messages.blocked")}>
                  <WarningCircle weight="bold" className="text-gray-500" />
                </Tooltip>
              )}
            </div>
          </DetailsItem.Value>
        </DetailsItem>

        {isAppleiOS() && (
          <span className="mt-1 text-sm text-gray-500">
            {i18n.t("settings:profile.notifications.push.messages.ios_16_4")}
          </span>
        )}

        <DetailsItem direction="vertical">
          <DetailsItem.Label>
            {i18n.t("settings:profile.fields.language")}
          </DetailsItem.Label>
          <div className="inline-flex">
            <Select
              items={locales}
              data-testid="language-select"
              value={user.profile?.language}
              onValueChange={handleChangeLanguage}
            />
          </div>
        </DetailsItem>

        <DetailsItem direction="vertical">
          <DetailsItem.Label>Home Page</DetailsItem.Label>
          <div className="inline-flex">
            <Select
              items={defaultLandingPages.map((page) => ({
                label: page.name,
                value: page.path,
                group: page.group,
              }))}
              value={homePage.path}
              onValueChange={handleChangeHomePage}>
              <span>{`${homePage.group} / ${homePage.name}`}</span>
            </Select>
          </div>
          <div className="max-w-[240px] text-xs leading-normal opacity-75">
            {i18n.t("settings:profile.fields.homepage_description")}
          </div>
        </DetailsItem>

        <hr className="w-full" />

        <DetailsItem direction="vertical">
          <DetailsItem.Label>
            {i18n.t("settings:profile.fields.password")}
          </DetailsItem.Label>
          <DetailsItem.Value>
            <Button
              type="secondary"
              size="small"
              onClick={() => setVisibleChangePassword(true)}>
              {i18n.t("common:change_token", {
                token: i18n.t("settings:profile.fields.password"),
              })}
            </Button>
          </DetailsItem.Value>
        </DetailsItem>

        <DetailsItem direction="vertical">
          <DetailsItem.Label>{i18n.t("settings:profile.title")}</DetailsItem.Label>
          <DetailsItem.Value>
            <Button
              type="secondary"
              size="small"
              onClick={() => setVisibleEditProfile(true)}>
              {i18n.t("common:edit_token", { token: i18n.t("settings:profile.title") })}
            </Button>
          </DetailsItem.Value>
        </DetailsItem>
      </div>

      <EditUserProfile visible={isVisibleEditProfile} onClose={closeEditProfile} />
      <ChangePassword visible={isVisibleChangePassword} onClose={closeChangePassword} />
    </>
  )
}

const SettingsProfilePage = () => {
  const logout = useLogout()
  usePage({
    isSubPage: true,
    id: "settings/profile",
    title: i18n.t("settings:profile.title"),
  })

  return (
    <SettingsPageLayout
      title={i18n.t("settings:profile.title")}
      description={i18n.t("settings:profile.description")}
      buttonText={i18n.t("settings:profile.logout")}
      buttonProps={{ onClick: logout, icon: Icons.Logout }}>
      <SettingsProfileContent />
    </SettingsPageLayout>
  )
}

export default SettingsProfilePage
