import { assetActivityDataViewDefaultConfig } from "@components/asset/asset-activity-data-view"
import {
  assetArchiveDataViewDefaultConfig,
  assetDataViewDefaultConfig,
} from "@components/asset/asset-data-view"
import { meterDataViewDefaultConfig } from "@components/asset/meter-data-view"
import { consumableDataViewDefaultConfig } from "@components/consumable/consumable-data-view"
import { contactDataViewDefaultConfig } from "@components/contact/contact-data-view"
import {
  maintenanceArchiveDataViewDefaultConfig,
  maintenanceDataViewDefaultConfig,
} from "@components/maintenance/maintenance-data-view"
import { servicerequestDataViewDefaultConfig } from "@components/service-request/service-request-data-view"
import { DataViewConfiguration } from "@components/shared/data-view/data-view-types"
import { workOrderDataViewDefaultConfig } from "@components/work-order/work-order-data-view"
import { IViewDataTypeEnum } from "@elara/db"
import i18n from "@i18n"
import {
  Activity,
  AddressBook,
  Archive,
  BatteryVerticalLow,
  Calendar,
  CheckSquare,
  Gauge,
  Lifebuoy,
  Nut,
  Placeholder,
  Repeat,
  Rows,
  Shapes,
  Siren,
  Table,
  User,
} from "@phosphor-icons/react"
import { Icon } from "src/types"

export const defaultConfig = (data_type: IViewDataTypeEnum) => {
  switch (data_type) {
    case IViewDataTypeEnum.Workorder:
      return workOrderDataViewDefaultConfig
    case IViewDataTypeEnum.Asset:
      return assetDataViewDefaultConfig
    case IViewDataTypeEnum.AssetArchive:
      return assetArchiveDataViewDefaultConfig
    case IViewDataTypeEnum.AssetState:
      return assetActivityDataViewDefaultConfig
    case IViewDataTypeEnum.Consumable:
      return consumableDataViewDefaultConfig
    case IViewDataTypeEnum.Contact:
      return contactDataViewDefaultConfig
    case IViewDataTypeEnum.ServiceRequest:
      return servicerequestDataViewDefaultConfig
    case IViewDataTypeEnum.Meter:
      return meterDataViewDefaultConfig
    case IViewDataTypeEnum.Maintenance:
      return maintenanceDataViewDefaultConfig
    case IViewDataTypeEnum.MaintenanceArchive:
      return maintenanceArchiveDataViewDefaultConfig
    default:
      throw new Error(`Could not find defaultConfig for ${data_type} dataType`)
  }
}

export type DefaultView = {
  id: string
  icon?: Icon
  name: string
  standard: boolean
  data_type: IViewDataTypeEnum
  config: DataViewConfiguration<any>
}

export const allDefaultViews = (): DefaultView[] => [
  {
    data_type: IViewDataTypeEnum.Workorder,
    id: "all_tasks",
    icon: () => <Rows className="text-blue-600" />,
    standard: true,
    name: i18n.t("views:default_views.all_tasks"),
    config: {
      ...defaultConfig(IViewDataTypeEnum.Workorder),
    },
  },
  {
    data_type: IViewDataTypeEnum.Workorder,
    id: "recurring_tasks",
    icon: () => <Repeat className="text-teal-700" />,
    name: i18n.t("views:default_views.recurring_tasks"),
    standard: true,
    config: {
      ...defaultConfig(IViewDataTypeEnum.Workorder),
      filters: [
        {
          id: "recurrence",
          type: "singleton",
          negated: false,
        },
      ],
    },
  },
  {
    data_type: IViewDataTypeEnum.Workorder,
    id: "overdue_tasks",
    icon: () => <Calendar className={"h-[1em] w-[1em] scale-[1.1] text-red-600"} />,
    name: i18n.t("views:default_views.overdue_tasks"),
    standard: false,
    config: {
      ...defaultConfig(IViewDataTypeEnum.Workorder),
      filters: [
        {
          id: "status",
          type: "select",
          negated: true,
          selectedValues: ["done", "canceled"],
        },
        {
          id: "dueDate",
          type: "select",
          selectedValues: [{ type: "relative", days: -1 }],
        },
      ],
      historyFilter: {
        type: "select",
        id: "completedAt",
        selectedValues: [
          {
            type: "relative",
            days: -2,
          },
        ],
      },
    },
  },
  {
    data_type: IViewDataTypeEnum.Workorder,
    id: "tasks_without_assignee",
    icon: () => <Placeholder weight="bold" className="text-yellow-500" />,
    name: i18n.t("views:default_views.tasks_without_assignee"),
    standard: false,
    config: {
      ...defaultConfig(IViewDataTypeEnum.Workorder),
      filters: [
        {
          id: "assignees",
          type: "select",
          negated: false,
          selectedValues: [{ type: "user", id: null }],
        },
      ],
      historyFilter: {
        type: "select",
        id: "completedAt",
        selectedValues: [
          {
            type: "relative",
            days: -2,
          },
        ],
      },
    },
  },
  {
    standard: false,
    id: "tasks_completed_today",
    name: i18n.t("views:default_views.tasks_completed_today"),
    data_type: IViewDataTypeEnum.Workorder,
    icon: () => <CheckSquare weight="fill" className="text-green-700" />,
    config: {
      ...defaultConfig(IViewDataTypeEnum.Workorder),
      filters: [
        {
          id: "status",
          type: "select",
          negated: false,
          selectedValues: ["done"],
        },
      ],
      historyFilter: {
        type: "select",
        id: "completedAt",
        selectedValues: [
          {
            type: "relative",
            days: 0,
          },
        ],
      },
    },
  },
  {
    data_type: IViewDataTypeEnum.Workorder,
    id: "created_by_me",
    icon: User,
    standard: true,
    name: i18n.t("views:default_views.created_by_me"),
    config: {
      ...defaultConfig(IViewDataTypeEnum.Workorder),
      filters: [
        {
          type: "select",
          id: "createdBy",
          selectedValues: ["current_user"],
        },
      ],
    },
  },
  {
    data_type: IViewDataTypeEnum.Asset,
    id: "all_objects",
    icon: () => <Shapes />,
    name: i18n.t("views:default_views.all_objects"),
    standard: true,
    config: {
      ...defaultConfig(IViewDataTypeEnum.Asset),
    },
  },
  {
    data_type: IViewDataTypeEnum.Asset,
    id: "objects_unplanned_offline",
    icon: () => <Siren className="text-red-700" weight="bold" />,
    name: i18n.t("views:default_views.objects_unplanned_offline"),
    standard: true,
    config: {
      ...defaultConfig(IViewDataTypeEnum.Asset),
      filters: [
        {
          type: "select",
          id: "lastState",
          selectedValues: ["1648d54c-5915-47eb-abf4-75a619a50c01"],
        },
      ],
    },
  },
  {
    data_type: IViewDataTypeEnum.AssetState,
    id: "all_activities",
    icon: () => <Activity size={16} weight="bold" />,
    name: i18n.t("views:default_views.all_activities"),
    standard: true,
    config: {
      ...defaultConfig(IViewDataTypeEnum.AssetState),
    },
  },
  {
    data_type: IViewDataTypeEnum.AssetArchive,
    id: "archived_objects",
    icon: Archive,
    name: i18n.t("views:default_views.archived_objects"),
    standard: true,
    config: {
      ...defaultConfig(IViewDataTypeEnum.AssetArchive),
    },
  },
  {
    data_type: IViewDataTypeEnum.Meter,
    id: "all_meters",
    icon: Gauge,
    name: i18n.t("views:default_views.all_meters"),
    standard: true,
    config: {
      ...defaultConfig(IViewDataTypeEnum.Meter),
    },
  },

  {
    data_type: IViewDataTypeEnum.Consumable,
    id: "all_consumables",
    icon: Nut,
    name: i18n.t("views:default_views.all_consumables"),
    standard: true,
    config: {
      ...defaultConfig(IViewDataTypeEnum.Consumable),
    },
  },
  {
    data_type: IViewDataTypeEnum.Consumable,
    id: "consumable_low_stock",
    icon: () => <BatteryVerticalLow className="text-orange-700" weight="fill" />,
    name: i18n.t("views:default_views.consumable_low_stock"),
    standard: true,
    config: {
      ...defaultConfig(IViewDataTypeEnum.Consumable),
      filters: [
        {
          id: "low_stock",
          type: "singleton",
          negated: false,
        },
      ],
    },
  },
  {
    data_type: IViewDataTypeEnum.Contact,
    id: "all_contacts",
    icon: ({ width }) => <AddressBook size={width} weight="fill" className="scale-[1.1]" />,
    name: i18n.t("views:default_views.all_contacts"),
    standard: true,
    config: {
      ...defaultConfig(IViewDataTypeEnum.Contact),
    },
  },
  {
    data_type: IViewDataTypeEnum.ServiceRequest,
    id: "all_service_requests",
    icon: ({ width }) => <Lifebuoy size={width} weight="fill" className="scale-[1.1]" />,
    name: i18n.t("views:default_views.all_service_requests"),
    standard: true,
    config: {
      ...defaultConfig(IViewDataTypeEnum.ServiceRequest),
    },
  },
  {
    data_type: IViewDataTypeEnum.Maintenance,
    id: "all_maintenance",
    icon: () => <Table weight="fill" className="h-[1.1em] w-[1.1em] text-blue-600" />,
    name: i18n.t("views:default_views.all_maintenance"),
    standard: true,
    config: {
      ...defaultConfig(IViewDataTypeEnum.Maintenance),
    },
  },
  {
    data_type: IViewDataTypeEnum.Maintenance,
    id: "all_maintenance_calendar",
    icon: ({ width }) => (
      <Calendar size={width} weight="fill" className="scale-[1.1] text-teal-600" />
    ),
    name: i18n.t("views:default_views.all_maintenance_calendar"),
    standard: true,
    config: {
      ...defaultConfig(IViewDataTypeEnum.Maintenance),
      groupBy: "name",
      calendarConfig: {
        type: "dayGrid",
        range: "Month",
      },
      layoutType: "calendar",
    },
  },
  {
    data_type: IViewDataTypeEnum.MaintenanceArchive,
    id: "archived_maintenance",
    icon: () => <Archive weight="fill" className="h-[1.1em] w-[1.1em] text-gray-600" />,
    name: i18n.t("views:default_views.archived_maintenance"),
    standard: true,
    config: {
      ...defaultConfig(IViewDataTypeEnum.MaintenanceArchive),
    },
  },
]

export const defaultViews = (data_type: IViewDataTypeEnum): DefaultView[] => {
  return allDefaultViews().filter((v) => v.data_type === data_type)
}
