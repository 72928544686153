import { getLocationTimeZone } from "@contexts/user-context"
import {
  Ends,
  Frequency,
  monthToIsoMonth,
  TimeIntervalTriggerOptions,
  Weekday,
} from "@elara/db"
import {
  getLocalTimeZone,
  parseDate,
  Time,
  toCalendarDateTime,
} from "@internationalized/date"
import { getDateFromTimeString, getWeekOfMonth } from "@utils/date"
import {
  endOfDay,
  getLocalDateTimeAsUTC,
  getUTCAsLocalDateTime,
  parseAbsolute,
  toZoned,
} from "@utils/tzdate"
import { eachHourOfInterval, format } from "date-fns"
import { Options as RRuleOptions, RRule } from "rrule"

import { freqToRRuleFreq, weekdayToRRuleWeekday } from "../types"

export const generateRRuleObject = (payload: TimeIntervalTriggerOptions["payload"]) => {
  const options: Partial<RRuleOptions> = {}

  const nextDate = parseDate(payload.nextDate)
  const nextTime = payload.nextTime ? parseAbsolute(payload.nextTime) : null
  const nextAt = nextTime
    ? nextTime.set({ year: nextDate.year, month: nextDate.month, day: nextDate.day })
    : toZoned(toCalendarDateTime(nextDate, new Time(23, 59)))

  const nextAtCalendarDateTime = toCalendarDateTime(nextAt)
  // Convert to local time zone to make all formatting function work properly
  const startDate = nextAtCalendarDateTime.toDate(getLocalTimeZone())
  options.dtstart = nextAtCalendarDateTime.toDate("UTC") // Time Zone
  options.tzid = getLocationTimeZone()

  // Frequency
  const frequency = freqToRRuleFreq[payload.repetition.frequency]
  options.freq = frequency

  // Interval
  options.interval = payload.repetition.interval

  // Week Start
  options.wkst = RRule.MO

  // By Hour
  if (payload.repetition.frequency === Frequency.hourly && payload.repetition.hourly) {
    try {
      const { from, to } = payload.repetition.hourly

      if (from && to) {
        const fromDate = getDateFromTimeString(from)
        const toDate = getDateFromTimeString(to)
        const intervalDates = eachHourOfInterval({ start: fromDate, end: toDate })

        options.byhour = intervalDates.map((date) => date.getHours())
      }
    } catch (e) {}
  }

  // By Day (Hourly)
  if (payload.repetition.frequency === Frequency.hourly && payload.repetition.hourly) {
    const { weekdays } = payload.repetition.hourly

    if (weekdays && weekdays.length > 0) {
      options.byweekday = weekdays.map((weekday) => weekdayToRRuleWeekday[weekday])
    }
  }

  // By Day (Weekly)
  if (payload.repetition.frequency === "weekly" && payload.repetition.weekly) {
    const { weekdays } = payload.repetition.weekly

    if (weekdays && weekdays.length > 0) {
      options.byweekday = weekdays.map((weekday) => weekdayToRRuleWeekday[weekday])
    }
  }

  // By Month (Monthly)
  if (payload.repetition.frequency === "monthly" && payload.repetition.monthly) {
    const { modifier, bymonth } = payload.repetition.monthly

    if (modifier === "by_date" && bymonth) {
      options.bymonth = bymonth.map((month) => monthToIsoMonth(month))
    }
  }

  // Monthly Modifiers
  if (payload.repetition.frequency === "monthly" && payload.repetition.monthly) {
    const { modifier } = payload.repetition.monthly

    if (modifier === "first_of_month") {
      options.bymonthday = [1]
    }

    if (modifier === "last_of_month") {
      options.bymonthday = [-1]
    }

    if (modifier === "by_date") {
      options.bymonthday = [startDate.getUTCDate()]
    }

    if (modifier === "by_weekday") {
      const weekday = format(startDate, "EEEEEE").toLocaleUpperCase() as Weekday
      const rruleWeekday = weekdayToRRuleWeekday[weekday]
      const weekOfMonth = getWeekOfMonth(startDate)

      options.byweekday = [rruleWeekday.nth(weekOfMonth)]
    }
  }

  // Until
  if (payload.ends.value === Ends.on && payload.ends.date) {
    const endDate = endOfDay(parseAbsolute(payload.ends.date))

    options.until = endDate.toDate()
  }

  return new RRule(options)
}

export function getRRuleNextAt(rrule: RRule, include = true) {
  // From RRule docs:
  /**
   * Returned "UTC" dates are always meant to be interpreted as dates in your local timezone.
   * This may mean you have to do additional conversion to get the "correct" local time with offset applied.
   *
   * https://github.com/jakubroztocil/rrule#important-use-utc-dates
   **/

  // Make sure next at conincides with the rrule
  const rruleNextAt = rrule.after(getLocalDateTimeAsUTC(new Date()), include)
  if (rruleNextAt) {
    return getUTCAsLocalDateTime(rruleNextAt).toAbsoluteString()
  } else {
    return null
  }
}

export const prepareTimeIntervalTriggerPayload = (
  payload: TimeIntervalTriggerOptions["payload"]
): TimeIntervalTriggerOptions["payload"] => {
  // Generate RRule
  const rrule = generateRRuleObject(payload)
  payload.rrule = rrule.toString()

  // Make sure next at conincides with the rrule
  const nextAtString = getRRuleNextAt(rrule)
  payload.nextAt = nextAtString
  // Add when to create next task
  if (nextAtString) {
    const nextAt = parseAbsolute(nextAtString)

    // If we have no next time, set it to 3am
    let createNextAt = nextAt.subtract(payload.createBefore)
    if (!payload.nextTime) {
      createNextAt = createNextAt.set({ hour: 3, minute: 0, second: 0 })
    }

    payload.createNextAt = createNextAt.toAbsoluteString()
  } else {
    payload.createNextAt = null
  }

  return payload
}
