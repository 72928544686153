import {
  IMutationRoot,
  IMutationRootDeleteWorkOrderTemplateByPkArgs,
  IMutationRootInsertWorkOrderTemplateOneArgs,
  IMutationRootUpdateWorkOrderTemplateByPkArgs,
} from "@elara/db"
import {
  IWorkOrderTemplateFragment,
  WorkOrderTemplateFragmentDoc,
} from "@graphql/documents/fragments.generated"
import {
  IWorkOrderTemplatesQuery,
  WorkOrderTemplatesDocument,
} from "@graphql/documents/work-order.generated"
import { OptimisticMutationResolver, UpdateResolver } from "@urql/exchange-graphcache"

type Mutations = Partial<Record<keyof IMutationRoot, UpdateResolver>>
type Optimistic = Partial<Record<keyof IMutationRoot, OptimisticMutationResolver>>

const optimistic: Optimistic = {
  update_work_order_template_by_pk(
    args: IMutationRootUpdateWorkOrderTemplateByPkArgs,
    cache
  ) {
    const id = args.pk_columns.id
    const template = cache.readFragment(WorkOrderTemplateFragmentDoc, { id })
    return { __typename: "work_order_template", ...template, ...args._set }
  },
}

const mutations: Mutations = {
  insert_work_order_template_one(
    result: { insert_work_order_template_one: IWorkOrderTemplateFragment },
    _args: IMutationRootInsertWorkOrderTemplateOneArgs,
    cache
  ) {
    const template = result.insert_work_order_template_one

    if (!template) return

    cache.updateQuery<IWorkOrderTemplatesQuery>(
      { query: WorkOrderTemplatesDocument },
      (data) => {
        if (!data || !data.work_order_template) return data
        if (!data.work_order_template.some((t) => t.id === template?.id)) {
          data.work_order_template.push(template)
          data.work_order_template.sort((a, b) => a.name.localeCompare(b.name))
        }
        return data
      }
    )
  },
  delete_work_order_template_by_pk(
    _parent,
    args: IMutationRootDeleteWorkOrderTemplateByPkArgs,
    cache
  ) {
    cache.updateQuery<IWorkOrderTemplatesQuery>(
      { query: WorkOrderTemplatesDocument },
      (data) => {
        if (data) {
          data.work_order_template = data.work_order_template.filter(
            (t) => t.id !== args.id
          )
        }
        return data
      }
    )
  },
}

export default {
  optimistic,
  mutations,
}
