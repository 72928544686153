import { IContactFragment } from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import * as HoverCard from "@radix-ui/react-hover-card"
import classNames from "classnames"

type Props = {
  contact: IContactFragment
  compact?: boolean
  expanded?: boolean
}

export function ContactTag({ contact, compact, expanded }: Props) {
  if (compact) {
    return (
      <HoverCard.Root openDelay={200}>
        <HoverCard.Trigger className="group">
          <div className="line-clamp-2 inline-flex items-center rounded border px-2 py-1">
            <div className="text-sm">{contact.company}</div>
          </div>
        </HoverCard.Trigger>

        <HoverCard.Portal>
          <HoverCard.Content
            align="start"
            className="mt-2 border bg-white px-4 py-2 shadow">
            <div className="grid gap-0.5 text-sm">
              <div className="text-xs leading-relaxed">
                <div className="text-sm font-medium">{contact.name}</div>
                <div className="opacity-75">{contact.position}</div>
                <div className="font-medium">{contact.company}</div>
              </div>

              {(contact.phone || contact.email) && (
                <div className="mt-2 grid gap-2 border-t pt-2 text-xs">
                  {contact.phone && (
                    <div>
                      <div className="mb-0.5 font-medium opacity-75">Phone</div>
                      <a href={`tel:${contact.phone}`}>{contact.phone}</a>
                    </div>
                  )}
                  {contact.email && (
                    <div>
                      <div className="mb-0.5 font-medium opacity-75">Email</div>
                      <a href={`mailto:${contact.email}`}>{contact.email}</a>
                    </div>
                  )}
                  {contact.note && (
                    <div>
                      <div className="mb-0.5 font-medium opacity-75">Notiz</div>
                      <div>{contact.note}</div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </HoverCard.Content>
        </HoverCard.Portal>
      </HoverCard.Root>
    )
  }

  return (
    <div className="group inline-flex min-w-[200px] max-w-[320px] flex-col rounded border px-4 py-2">
      <div className="flex items-center">
        <div className="leading-loose">
          <p className="text-sm font-medium">{contact.company}</p>
          <p className="text-xs font-medium opacity-75">{contact.name}</p>
          <p className="text-xs opacity-50">{contact.position}</p>
        </div>
      </div>

      {(contact.phone || contact.email || contact.note) && (
        <div
          className={classNames("grid gap-2 text-xs", {
            "mt-2": expanded,
            "pointer-events-none max-h-0 opacity-0 transition-[height_opacity_margin] delay-200 group-hover:pointer-events-auto group-hover:mt-2 group-hover:max-h-screen group-hover:opacity-100":
              !expanded,
          })}>
          {contact.phone && (
            <div>
              <div className="mb-0.5 font-medium opacity-75">
                {i18n.t("contacts:fields.phone")}
              </div>
              <a href={`tel:${contact.phone}`}>{contact.phone}</a>
            </div>
          )}
          {contact.email && (
            <div>
              <div className="mb-0.5 font-medium opacity-75">
                {i18n.t("contacts:fields.email")}
              </div>
              <a href={`mailto:${contact.email}`}>{contact.email}</a>
            </div>
          )}
          {contact.note && (
            <div>
              <div className="mb-0.5 font-medium opacity-75">
                {i18n.t("contacts:fields.note")}
              </div>
              <div>{contact.note}</div>
            </div>
          )}
        </div>
      )}

      {!(contact.phone || contact.email || contact.note) && (
        <div
          className={classNames("grid gap-2 text-xs", {
            "mt-2": expanded,
            "pointer-events-none max-h-0 opacity-0 transition-[height_opacity_margin] delay-200 group-hover:pointer-events-auto group-hover:mt-2 group-hover:max-h-screen group-hover:opacity-100":
              !expanded,
          })}>
          <p className="text-gray-500">
            {i18n.t("contacts:messages.no_contact_information")}
          </p>
        </div>
      )}
    </div>
  )
}
