import { Language } from "@elara/db"
import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import deDE from "./locales/de-DE"
import enUS from "./locales/en-US"
import plPL from "./locales/pl-PL"
import ptBR from "./locales/pt-BR"
import zhCN from "./locales/zh-CN"

const resources: Record<Language, any> = {
  "de-DE": deDE,
  "en-US": enUS,
  "en-AU": enUS,
  "pl-PL": plPL,
  "pt-BR": ptBR,
  "zh-CN": zhCN,
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    defaultNS: "common",
    fallbackLng: "de-DE",
    ns: Object.keys(deDE),
    interpolation: { escapeValue: false },
  })

export default i18n
