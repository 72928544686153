import { DialogRootProps } from "@components/shared/dialog"
import { DialogForm } from "@components/shared/dialog-form"
import { FormField } from "@components/shared/form/form-field"
import IconPicker from "@components/shared/icon-picker"
import { TextInput } from "@components/shared/text-input"
import toast from "@components/shared/toast"
import { useBreakpoint } from "@contexts/breakpoints"
import { useUser } from "@contexts/user-context"
import {
  useAddTeamMemberMutation,
  useInsertTeamMutation,
} from "@graphql/documents/team.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import React from "react"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"

import { randomIcon } from "./team-icons"

const TeamCreateSchema = yup.object().shape({
  name: yup
    .string()
    .required(i18n.t("common:forms.is_required", { field: i18n.t("teams:fields.name") })),
  private: yup.boolean(),
  icon: yup.object().shape({
    name: yup.string(),
    color: yup.string(),
  }),
})

export type TeamCreateFormDialogProps = {
  title: string
} & Required<Pick<DialogRootProps, "isOpen" | "onOpenChange">>

export const TeamCreateFormDialog = (props: TeamCreateFormDialogProps) => {
  const bp = useBreakpoint()
  const size = bp.mobile ? "small" : "large"

  const user = useUser()
  const navigate = useNavigate()

  const [, insertTeam] = useInsertTeamMutation()
  const [, addTeamMember] = useAddTeamMemberMutation()
  const accountScope = usePermissionScope(IPermissionScopeEnum.AppAccountManagement)

  const [initialIcon, setInitialIcon] = React.useState(() => randomIcon())
  useEffect(() => {
    if (props.isOpen) {
      setInitialIcon(randomIcon())
    }
  }, [props.isOpen])

  const initialValues = {
    name: "",
    private: false,
    icon: initialIcon,
  }

  const addTeam = async (values: typeof initialValues) => {
    const res = await insertTeam(
      { data: { ...values, owner_id: user.id } },
      accountScope.context()
    )

    if (!res.data?.insert_team_one || res.error) {
      toast.error(
        i18n.t("common:messages.token_create_failure", {
          token: i18n.t("common:team", { count: 1 }),
        })
      )
      throw new Error(
        i18n.t("common:messages.token_create_failure", {
          token: i18n.t("common:team", { count: 1 }),
        })
      )
    }

    if (res.data && res.data.insert_team_one) {
      const { owner_id, id } = res.data.insert_team_one
      if (owner_id) {
        await addTeamMember({ userId: owner_id, teamId: id }, accountScope.context())
      }
    }

    navigate(`/settings/team/${res.data.insert_team_one.id}`)
  }

  return (
    <DialogForm
      title={props.title}
      formikConfig={{
        initialValues,
        onSubmit: addTeam,
        validationSchema: TeamCreateSchema,
      }}
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}>
      {() => (
        <div className="grid grid-cols-1 gap-x-10 gap-y-1 py-4">
          <div className="flex w-full flex-col">
            <FormField label={i18n.t("teams:fields.name")} name="name">
              <TextInput
                type="text"
                placeholder={i18n.t("teams:fields.name")}
                size={size}
                autoComplete="nofill"
                autoFocus
              />
            </FormField>

            <FormField
              label={i18n.t("common:icon")}
              name="icon"
              hasErrorPlaceholder={false}>
              {({ field, helpers }) => (
                <IconPicker
                  section="team"
                  name={field.value.name}
                  color={field.value.color}
                  onChange={helpers.setValue}
                  showChangeButton
                />
              )}
            </FormField>
          </div>
          {/* <label className="mb-2 flex cursor-pointer items-center rounded px-2 py-1 text-sm hover:bg-gray-100">
          <FormField name="private" noStyle>
            <CheckboxInput />
          </FormField>
          <span className="ml-2 text-gray-700">
            {i18n.t("teams:actions.create_as_private")}
          </span>
        </label> */}
        </div>
      )}
    </DialogForm>
  )
}
