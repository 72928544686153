import { useUser } from "@contexts/user-context"
import { uuid } from "@elara/db"
import {
  ITaskStreamSubscription,
  ITaskStreamSubscriptionVariables,
  ITaskStreamUpdatesMutation,
  ITaskStreamUpdatesMutationVariables,
  TaskStreamDocument,
  TaskStreamUpdatesDocument,
} from "@graphql/documents/work-order.generated"
import { useEffect } from "react"
import { useClient } from "urql"
import { pipe, subscribe } from "wonka"

export const useWorkOrderStream = () => {
  const user = useUser()
  const client = useClient()
  useEffect(() => {
    if (!user.location.id) return
    // @ts-ignore
    const { unsubscribe } = pipe(
      // @ts-ignore
      client.subscription<ITaskStreamSubscription, ITaskStreamSubscriptionVariables>(
        TaskStreamDocument,
        { cursor_value: new Date().toISOString(), locationId: user.location.id }
      ),

      // @ts-ignore
      subscribe((res: any) => {
        if (res.data?.work_order_last_update_stream?.length) {
          client
            .mutation<ITaskStreamUpdatesMutation, ITaskStreamUpdatesMutationVariables>(
              TaskStreamUpdatesDocument,
              { ids: res.data.work_order_last_update_stream.map((w: { id: uuid }) => w.id) }
            )
            .toPromise()
        }
      })
    )

    return () => {
      unsubscribe()
    }
  }, [user.location.id])
}
