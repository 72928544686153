import ScrollArea from "@components/shared/scroll-area"
import { Select } from "@components/shared/select"
import { cn } from "@utils"
import { useNavigateWithBackgroundLocation } from "@utils/location"
import { NavLink, Outlet, resolvePath, useLocation } from "react-router-dom"

export const SubpageLayout = (props: {
  pages: { label: string; path: string }[]
  context?: any
}) => {
  const { state } = useLocation()
  const { pathname } = useLocation()
  const navigate = useNavigateWithBackgroundLocation()

  return (
    <ScrollArea
      vertical
      viewportAsChild
      viewPortClassName="mx-auto flex min-h-0 w-full min-w-0 max-w-screen-xl flex-1 flex-col @mobile/page:flex-row">
      <div className="sticky top-0 hidden w-full max-w-[200px] flex-col space-y-1 border-r border-gray-200 p-4 @mobile/page:flex">
        {props.pages.map((tab) => (
          <NavLink
            key={tab.path}
            state={state}
            to={tab.path}
            className={({ isActive }) =>
              cn(
                "text-sm text-gray-700 font-medium px-3 py-2 border-l-2 border-transparent rounded-md hover:text-current hover:bg-gray-100",
                {
                  "bg-gray-100 rounded-l-none border-l-blue-500 font-medium ": isActive,
                }
              )
            }>
            {tab.label}
          </NavLink>
        ))}
      </div>
      <div className="p-3 @mobile/page:hidden">
        <Select
          value={pathname}
          onValueChange={(path) => {
            navigate(path)
          }}
          items={props.pages.map((item) => {
            const value = resolvePath("../" + item.path, pathname).pathname
            return { label: item.label, value }
          })}
        />
      </div>

      <div className="flex min-h-0 min-w-0 flex-1 flex-col p-3">
        <Outlet context={props.context} />
      </div>
    </ScrollArea>
  )
}
