import { useConfirmModal } from "@components/shared"
import { alertDialog } from "@components/shared/alert-dialog-provider"
import { DialogForm } from "@components/shared/dialog-form"
import { CheckboxInput } from "@components/shared/form/checkbox-input"
import { FormField } from "@components/shared/form/form-field"
import { TextInput } from "@components/shared/text-input"
import toast from "@components/shared/toast"
import { IPermissionScopeEnum, uuid } from "@elara/db"
import {
  AssetDataViewDocument,
  IAssetDataViewQueryVariables,
  IAssetStateDataViewQuery,
  useAddAssetQuicklyMutation,
  useArchiveAssetMutation,
  useCopyAssetMutation,
  useDeleteAssetMutation,
} from "@graphql/documents/asset.generated"
import { useDisclosure } from "@hooks"
import { usePermissionScope } from "@hooks/use-permission-scope"
import i18n from "@i18n"
import { Field } from "formik"
import { useState } from "react"
import { Trans } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import { useClient } from "urql"

type UseArchiveAssetOptions = {
  assetId: uuid
  assetName: string
}

export const useReplaceAsset = (options: UseArchiveAssetOptions) => {
  const navigate = useNavigate()

  const createScope = usePermissionScope(IPermissionScopeEnum.AppAssetCreate)
  const deleteScope = usePermissionScope(IPermissionScopeEnum.AppAssetArchiveDelete)

  const [, archiveAssetMutation] = useArchiveAssetMutation()
  const [, addAssetQuickly] = useAddAssetQuicklyMutation()

  const replaceAsset = async () => {
    const res = await addAssetQuickly({ name: options.assetName }, createScope.context())
    const replaced_by_id = res.data?.insert_asset_one?.id
    if (!replaced_by_id) {
      toast.error("Nachfolgeanlage konnte nicht angelegt werden.")
    }
    const archiveRes = await archiveAssetMutation(
      {
        id: options.assetId,
        set: { replaced_by_id },
      },
      deleteScope.context()
    )
    const isSuccess = !archiveRes.error && !!archiveRes.data?.update_asset_by_pk
    if (!isSuccess) {
      toast.error("Nachfolgeanlage konnte nicht angelegt werden.")
      return
    }
    navigate(`/object/${replaced_by_id}`)
  }
  return replaceAsset
}

export const useArchiveAsset = (options: UseArchiveAssetOptions) => {
  const [shouldCreateFollowUpAsset, setShouldCreateFollowUpAsset] = useState(false)
  const [, archiveAssetMutation] = useArchiveAssetMutation()
  const replaceAsset = useReplaceAsset(options)

  const editScope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)
  const deleteScope = usePermissionScope(IPermissionScopeEnum.AppAssetArchiveDelete)

  const reactivateAsset = async () => {
    const reactivateRes = await archiveAssetMutation(
      {
        id: options.assetId,
        set: {
          archived_at: null,
          replaced_by_id: null,
        },
      },
      editScope.context()
    )

    const isReactivated = !reactivateRes.error && !!reactivateRes.data?.update_asset_by_pk

    if (!isReactivated) {
      toast.error("Objekt konnte nicht reaktiviert werden.")
      return
    } else {
      toast.success("Das Objekt wurde erfolgreich reaktiviert.")
      return
    }
  }

  const archiveAsset = async () => {
    const archiveRes = await archiveAssetMutation(
      {
        id: options.assetId,
        set: { archived_at: new Date().toISOString() },
      },
      deleteScope.context()
    )
    const isArchived = !archiveRes.error && !!archiveRes.data?.update_asset_by_pk
    if (!isArchived) {
      toast.error("Objekt konnte nicht archiviert werden.")
      return
    } else {
      toast.success("Das Objekt wurde erfolgreich archiviert.")
    }
    if (shouldCreateFollowUpAsset) {
      replaceAsset()
    }
  }

  const reactivateModal = useConfirmModal({
    title: i18n.t("assets:dialogs.unarchive_asset.title", { name: options.assetName }),
    content: i18n.t("assets:dialogs.unarchive_asset.content", { name: options.assetName }),
    cancelText: i18n.t("common:cancel"),
    okText: i18n.t("common:actions.reactivate"),
    onOk: reactivateAsset,
  })

  const archiveModal = useConfirmModal({
    title: i18n.t("assets:dialogs.archive_asset.title", { name: options.assetName }),
    content: (
      <div className="flex flex-col space-y-4">
        <span className="text-gray-500">
          {i18n.t("assets:dialogs.archive_asset.content")}
        </span>
        <label>
          <CheckboxInput
            onChange={(e) => setShouldCreateFollowUpAsset(e.target.checked)}
            checked={shouldCreateFollowUpAsset}></CheckboxInput>
          <span className="ml-2 text-sm text-gray-700">
            {i18n.t("assets:dialogs.archive_asset.actions.create_replacement")}
          </span>
        </label>
      </div>
    ),
    cancelText: i18n.t("common:cancel"),
    okText: i18n.t("common:actions.archive"),
    onOk: archiveAsset,
  })

  return {
    archiveConfirmModal: archiveModal.component,
    archive: archiveModal.show,
    reactivateConfirmModal: reactivateModal.component,
    reactivate: reactivateModal.show,
    replaceAsset,
    reactivateAsset,
  }
}

type UseCopyAssetArgs = {
  assetId: uuid
  assetName: string
}

export const useCopyAsset = ({ assetId, assetName }: UseCopyAssetArgs) => {
  const createScope = usePermissionScope(IPermissionScopeEnum.AppAssetCreate)
  const client = useClient()

  const [, copyAsset] = useCopyAssetMutation()
  const defaultAssetName = `${assetName} (${i18n.t("common:copy")})`
  const modal = useDisclosure()
  const dialog = (
    <DialogForm
      isOpen={modal.isOpen}
      onOpenChange={modal.changeOpen}
      title={i18n.t("common:actions.duplicate_token", {
        token: i18n.t("common:asset", { count: 1 }),
      })}
      formikConfig={{
        initialValues: { checkboxes: [] as string[], assetName: "" },
        onSubmit: async (values) => {
          const { checkboxes, assetName } = values
          const res = await copyAsset(
            {
              id: assetId,
              options: {
                copyAvatar: checkboxes.includes("avatar"),
                copyDocuments: checkboxes.includes("documents"),
                newAssetName: assetName ? assetName : undefined,
              },
            },
            createScope.context()
          )
          if (res.error) {
            toast.error(
              i18n.t("assets:dialogs.duplicate_asset.messages.error", { name: assetName })
            )
            return
          }

          toast.success({
            title: i18n.t("common:messages.token_create_success", {
              token: i18n.t("common:asset", { count: 1 }),
            }),
            params: { duration: 5000, position: "bottom-right" },
            body: (
              <Trans
                i18n={i18n}
                i18nKey="common:open_item"
                values={{ item: assetName || i18n.t("common:asset", { count: 1 }) }}>
                <Link
                  to={`/object/${res.data?.copyAsset?.id}`}
                  className="mr-1 text-blue-700">
                  Objekt
                </Link>
                öffnen
              </Trans>
            ),
          })

          // reload asset list
          client
            .query<IAssetStateDataViewQuery, IAssetDataViewQueryVariables>(
              AssetDataViewDocument,
              {}
            )
            .toPromise()
        },
      }}>
      {() => (
        <div className="mb-1 mt-4 flex flex-col space-y-3">
          {/* Disabled since broken */}
          {/* <label className="space-x-2 text-gray-700">
            <Field as={CheckboxInput} name="checkboxes" value="avatar" />
            <span>{i18n.t("assets:dialogs.duplicate_asset.copy_avatar")}</span>
          </label> */}

          <label className="space-x-2 text-gray-700">
            <Field as={CheckboxInput} name="checkboxes" value="documents" />
            <span>{i18n.t("assets:dialogs.duplicate_asset.copy_documents")}</span>
          </label>

          <FormField
            label={i18n.t("assets:dialogs.duplicate_asset.labels.name")}
            name="assetName">
            <TextInput placeholder={defaultAssetName} />
          </FormField>
        </div>
      )}
    </DialogForm>
  )

  return {
    copyConfirmModal: dialog,
    copy: modal.onOpen,
  }
}

type UseDeleteAssetModal = {
  assetId: uuid
  assetName: string
}

/* Currently not in use!
Has been replaced after implementation of APP-2030 
by deleteAssetOnClick in /home/kc/Documents/elaraapp/apps/web/src/pages/object/$id.tsx */
export const useDeleteAsset = ({ assetName, assetId }: UseDeleteAssetModal) => {
  const deleteScope = usePermissionScope(IPermissionScopeEnum.AppAssetArchiveDelete)
  const [, deleteAssetMutation] = useDeleteAssetMutation()
  const navigate = useNavigate()

  const deleteDialog = async () => {
    alertDialog({
      danger: true,
      actionText: i18n.t("common:delete"),
      title: i18n.t("assets:dialogs.delete_asset.title"),
      description: i18n.t("assets:dialogs.delete_asset.content"),
      async onAction() {
        const res = await deleteAssetMutation(
          {
            assetId: assetId,
          },
          deleteScope.context()
        )
        if (res.error)
          toast.error(
            i18n.t("common:messages.token_delete_failure", {
              token: assetName,
            })
          )
        else {
          toast.success(
            i18n.t("common:messages.token_delete_success", {
              token: assetName,
            })
          )
          navigate("/asset")
        }
      },
    })
  }

  return {
    delete: deleteDialog,
  }
}
