import classNames from "classnames"
import { useRef } from "react"
import React from "react"
import { Controller, ControllerProps, FieldPath, FieldValues, Path } from "react-hook-form"

import RichText, { RichTextProps } from "../rich-text"

export type RichTextFormFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = Path<TFieldValues>
> = Pick<ControllerProps<TFieldValues, TName>, "control" | "name"> &
  RichTextProps & {
    label?: string
    noStyle?: boolean
    optional?: boolean
    toolbarClassName?: string
    hasErrorPlaceholder?: boolean
  }

export function RichTextFormController<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = Path<TFieldValues>
>({ name, className, ...props }: RichTextFormFieldProps<TFieldValues, TName>) {
  const onChangeRef = useRef<any>(null)
  const [focus, setFocus] = React.useState(false)

  return (
    <Controller<TFieldValues>
      name={name}
      control={props.control}
      render={({ field }) => {
        onChangeRef.current = field.onChange

        return (
          <RichText
            className={classNames(
              {
                "border-red-700 focus-within:!border-red-700 focus-within:!ring-red-700":
                  false,
              },
              "flex-1 w-full text-base md:text-sm border rounded bg-white cursor-text",
              focus && "ring-2 ring-blue-700",
              className
            )}
            toolbarClassName={props.toolbarClassName}
            content={field.value}
            onBlur={() => setFocus(false)}
            onFocus={() => setFocus(true)}
            onUpdate={({ editor }) => {
              if (editor.isEmpty) {
                onChangeRef.current?.("")
              } else {
                onChangeRef.current?.(editor.getHTML())
              }
            }}
            {...props}
          />
        )
      }}
    />
  )
}
