// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import { AssetTagFragmentDoc, AssetStateVariantFragmentDoc, UserTagFragmentDoc, WorkOrderCategoryFragmentDoc, TaskAssetsFragmentDoc, TaskAssigneesFragmentDoc, WorkOrderTagFragmentDoc } from './fragments.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IAssetStateDowntimeFragment = { __typename: 'asset_state_log', id: uuid, started_at: string, ended_at: string | null, downtime_split_operating_hours: {} | null, asset: { __typename: 'asset', created_at: string, id: uuid, name: string, public_id: string, parent_asset_id: uuid | null, search_value: string | null, parent_ids: {} | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null, place: { __typename: 'place', id: uuid, name: string } | null, group: { __typename: 'asset_group', id: uuid, name: string } | null, parents: Array<{ __typename: 'asset', id: uuid, name: string, tree_depth: number | null }> | null }, asset_state_variant: { __typename: 'asset_state_variant', id: uuid, type: Types.IAssetStateVariantTypeEnum, subtype: string, planned: boolean, location_id: uuid | null } };

export type IAssetStateDowntimeQueryVariables = Types.Exact<{
  where: Types.IAssetStateLogBoolExp;
}>;


export type IAssetStateDowntimeQuery = { asset_state_log: Array<{ __typename: 'asset_state_log', id: uuid, started_at: string, ended_at: string | null, downtime_split_operating_hours: {} | null, asset: { __typename: 'asset', created_at: string, id: uuid, name: string, public_id: string, parent_asset_id: uuid | null, search_value: string | null, parent_ids: {} | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null, place: { __typename: 'place', id: uuid, name: string } | null, group: { __typename: 'asset_group', id: uuid, name: string } | null, parents: Array<{ __typename: 'asset', id: uuid, name: string, tree_depth: number | null }> | null }, asset_state_variant: { __typename: 'asset_state_variant', id: uuid, type: Types.IAssetStateVariantTypeEnum, subtype: string, planned: boolean, location_id: uuid | null } }> };

export type ITaskCompletionFragment = { __typename: 'work_order', id: uuid, due_date: string | null, priority: Types.IWorkOrderPriorityEnum | null, created_at: string, completed_at: string | null, completed_by_id: uuid | null, completed_by: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } | null, categories: Array<{ __typename: 'work_order_x_work_order_category', work_order_category_id: uuid, category: { __typename: 'work_order_category', id: uuid, label: string, color: string | null } }> };

export type ITaskReportingFragment = { __typename: 'work_order_report', id: uuid, hours: number | null, user: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } };

export type ITaskReportingQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.IWorkOrderReportBoolExp>;
}>;


export type ITaskReportingQuery = { work_order_report: Array<{ __typename: 'work_order_report', id: uuid, hours: number | null, user: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } }> };

export type IAssetReliabilityAnalyticsQueryVariables = Types.Exact<{
  location_id: Types.Scalars['uuid']['input'];
  asset_group?: Types.InputMaybe<Types.Scalars['uuid']['input']>;
  asset_ids?: Types.InputMaybe<Types.Scalars['jsonb']['input']>;
  start: Types.Scalars['timestamptz']['input'];
  end: Types.Scalars['timestamptz']['input'];
  where: Types.IAssetBoolExp;
}>;


export type IAssetReliabilityAnalyticsQuery = { asset: Array<{ __typename: 'asset', id: uuid, name: string, public_id: string, parent_asset_id: uuid | null, search_value: string | null, parent_ids: {} | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null, place: { __typename: 'place', id: uuid, name: string } | null, group: { __typename: 'asset_group', id: uuid, name: string } | null, parents: Array<{ __typename: 'asset', id: uuid, name: string, tree_depth: number | null }> | null }>, location_by_pk: { __typename: 'location', id: uuid, asset_availability_analytics: {} | null } | null };

export type ITaskCompletionQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.IWorkOrderBoolExp>;
}>;


export type ITaskCompletionQuery = { work_order: Array<{ __typename: 'work_order', id: uuid, due_date: string | null, priority: Types.IWorkOrderPriorityEnum | null, created_at: string, completed_at: string | null, completed_by_id: uuid | null, completed_by: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } | null, categories: Array<{ __typename: 'work_order_x_work_order_category', work_order_category_id: uuid, category: { __typename: 'work_order_category', id: uuid, label: string, color: string | null } }> }> };

export type ITaskActiveFragment = { __typename: 'work_order', id: uuid, status: Types.IWorkOrderStatusEnum, due_date: string | null, priority: Types.IWorkOrderPriorityEnum | null, recurrence_info: {} | null, created_at: string, completed_at: string | null, completed_by_id: uuid | null, completed_by: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } | null, categories: Array<{ __typename: 'work_order_x_work_order_category', work_order_category_id: uuid, category: { __typename: 'work_order_category', id: uuid, label: string, color: string | null } }>, assets: Array<{ __typename: 'asset_x_work_order', asset_id: uuid, asset: { __typename: 'asset', id: uuid, name: string, public_id: string, parent_asset_id: uuid | null, search_value: string | null, parent_ids: {} | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null, place: { __typename: 'place', id: uuid, name: string } | null, group: { __typename: 'asset_group', id: uuid, name: string } | null, parents: Array<{ __typename: 'asset', id: uuid, name: string, tree_depth: number | null }> | null } }>, assignees: Array<{ __typename: 'work_order_x_user', user_id: uuid, user: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } }> };

export type ITaskActiveQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.IWorkOrderBoolExp>;
}>;


export type ITaskActiveQuery = { work_order: Array<{ __typename: 'work_order', id: uuid, status: Types.IWorkOrderStatusEnum, due_date: string | null, priority: Types.IWorkOrderPriorityEnum | null, recurrence_info: {} | null, created_at: string, completed_at: string | null, completed_by_id: uuid | null, completed_by: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } | null, categories: Array<{ __typename: 'work_order_x_work_order_category', work_order_category_id: uuid, category: { __typename: 'work_order_category', id: uuid, label: string, color: string | null } }>, assets: Array<{ __typename: 'asset_x_work_order', asset_id: uuid, asset: { __typename: 'asset', id: uuid, name: string, public_id: string, parent_asset_id: uuid | null, search_value: string | null, parent_ids: {} | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null, place: { __typename: 'place', id: uuid, name: string } | null, group: { __typename: 'asset_group', id: uuid, name: string } | null, parents: Array<{ __typename: 'asset', id: uuid, name: string, tree_depth: number | null }> | null } }>, assignees: Array<{ __typename: 'work_order_x_user', user_id: uuid, user: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } }> }> };

export type ITaskOverviewDataQueryVariables = Types.Exact<{
  startDate: Types.Scalars['date']['input'];
  endDate: Types.Scalars['date']['input'];
  startTimestamp: Types.Scalars['timestamptz']['input'];
  endTimestamp: Types.Scalars['timestamptz']['input'];
  prevStartTimestamp: Types.Scalars['timestamptz']['input'];
  where?: Types.InputMaybe<Types.IWorkOrderBoolExp>;
}>;


export type ITaskOverviewDataQuery = { active_by_status: Array<{ __typename: 'work_order_status', value: string, work_orders_aggregate: { __typename: 'work_order_aggregate', aggregate: { __typename: 'work_order_aggregate_fields', count: number } | null } }>, completed: { __typename: 'work_order_aggregate', aggregate: { __typename: 'work_order_aggregate_fields', count: number } | null }, prev_completed: { __typename: 'work_order_aggregate', aggregate: { __typename: 'work_order_aggregate_fields', count: number } | null } };

export type IConsumableAdjustmentsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.IConsumableLogBoolExp>;
}>;


export type IConsumableAdjustmentsQuery = { consumable_log: Array<{ __typename: 'consumable_log', adjustment_at: string, id: uuid, cost_per_unit: number, consumable_id: uuid, place_id: uuid | null, task_id: uuid | null, asset_id: uuid | null, adjustment: number, created_at: string, updated_at: string, consumable: { __typename: 'consumable', id: uuid, name: string, public_id: string, unit: string } | null, place: { __typename: 'place', id: uuid, name: string } | null, task: { __typename: 'work_order', id: uuid, name: string, status: Types.IWorkOrderStatusEnum, due_date: string | null, due_time: string | null, work_order_number: number } | null, asset: { __typename: 'asset', id: uuid, name: string, public_id: string, parent_asset_id: uuid | null, search_value: string | null, parent_ids: {} | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null, place: { __typename: 'place', id: uuid, name: string } | null, group: { __typename: 'asset_group', id: uuid, name: string } | null, parents: Array<{ __typename: 'asset', id: uuid, name: string, tree_depth: number | null }> | null } | null, created_by: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } | null }> };

export type ISessionInfoMutationVariables = Types.Exact<{
  type: Types.Scalars['String']['input'];
  payload: Types.Scalars['jsonb']['input'];
  id?: Types.InputMaybe<Types.Scalars['uuid']['input']>;
}>;


export type ISessionInfoMutation = { insert_analytics_event: { __typename: 'analytics_event_mutation_response', affected_rows: number } | null };

export const AssetStateDowntimeFragmentDoc = gql`
    fragment AssetStateDowntime on asset_state_log {
  __typename
  id
  asset {
    ...AssetTag
    created_at
  }
  asset_state_variant {
    ...AssetStateVariant
  }
  started_at
  ended_at
  downtime_split_operating_hours
}
    ${AssetTagFragmentDoc}
${AssetStateVariantFragmentDoc}`;
export const TaskCompletionFragmentDoc = gql`
    fragment TaskCompletion on work_order {
  __typename
  id
  due_date
  priority
  created_at
  completed_at
  completed_by_id
  completed_by {
    ...UserTag
  }
  categories {
    work_order_category_id
    category {
      ...WorkOrderCategory
    }
  }
}
    ${UserTagFragmentDoc}
${WorkOrderCategoryFragmentDoc}`;
export const TaskReportingFragmentDoc = gql`
    fragment TaskReporting on work_order_report {
  __typename
  id
  hours
  user {
    ...UserTag
  }
}
    ${UserTagFragmentDoc}`;
export const TaskActiveFragmentDoc = gql`
    fragment TaskActive on work_order {
  __typename
  id
  status
  due_date
  priority
  recurrence_info
  created_at
  completed_at
  completed_by_id
  completed_by {
    ...UserTag
  }
  categories {
    work_order_category_id
    category {
      ...WorkOrderCategory
    }
  }
  ...TaskAssets
  ...TaskAssignees
}
    ${UserTagFragmentDoc}
${WorkOrderCategoryFragmentDoc}
${TaskAssetsFragmentDoc}
${TaskAssigneesFragmentDoc}`;
export const AssetStateDowntimeDocument = gql`
    query AssetStateDowntime($where: asset_state_log_bool_exp!) {
  asset_state_log(
    where: {_and: [{asset_state_variant: {type: {_eq: offline}}, asset: {deleted_at: {_is_null: true}}}, $where]}
  ) {
    ...AssetStateDowntime
  }
}
    ${AssetStateDowntimeFragmentDoc}`;

export function useAssetStateDowntimeQuery(options: Omit<Urql.UseQueryArgs<IAssetStateDowntimeQueryVariables>, 'query'>) {
  return Urql.useQuery<IAssetStateDowntimeQuery, IAssetStateDowntimeQueryVariables>({ query: AssetStateDowntimeDocument, ...options });
};
export const TaskReportingDocument = gql`
    query TaskReporting($where: work_order_report_bool_exp = {}) {
  work_order_report(where: $where) {
    ...TaskReporting
  }
}
    ${TaskReportingFragmentDoc}`;

export function useTaskReportingQuery(options?: Omit<Urql.UseQueryArgs<ITaskReportingQueryVariables>, 'query'>) {
  return Urql.useQuery<ITaskReportingQuery, ITaskReportingQueryVariables>({ query: TaskReportingDocument, ...options });
};
export const AssetReliabilityAnalyticsDocument = gql`
    query AssetReliabilityAnalytics($location_id: uuid!, $asset_group: uuid, $asset_ids: jsonb, $start: timestamptz!, $end: timestamptz!, $where: asset_bool_exp!) {
  asset(
    where: {_and: [{location_id: {_eq: $location_id}}, {deleted_at: {_is_null: true}}, $where]}
  ) {
    id
    ...AssetTag
  }
  location_by_pk(id: $location_id) {
    id
    asset_availability_analytics(
      args: {asset_group: $asset_group, asset_ids: $asset_ids, end: $end, start: $start}
    )
  }
}
    ${AssetTagFragmentDoc}`;

export function useAssetReliabilityAnalyticsQuery(options: Omit<Urql.UseQueryArgs<IAssetReliabilityAnalyticsQueryVariables>, 'query'>) {
  return Urql.useQuery<IAssetReliabilityAnalyticsQuery, IAssetReliabilityAnalyticsQueryVariables>({ query: AssetReliabilityAnalyticsDocument, ...options });
};
export const TaskCompletionDocument = gql`
    query TaskCompletion($where: work_order_bool_exp = {}) {
  work_order(where: {_and: [{type: {_eq: work_order}}, $where]}) {
    ...TaskCompletion
  }
}
    ${TaskCompletionFragmentDoc}`;

export function useTaskCompletionQuery(options?: Omit<Urql.UseQueryArgs<ITaskCompletionQueryVariables>, 'query'>) {
  return Urql.useQuery<ITaskCompletionQuery, ITaskCompletionQueryVariables>({ query: TaskCompletionDocument, ...options });
};
export const TaskActiveDocument = gql`
    query TaskActive($where: work_order_bool_exp = {}) {
  work_order(where: {_and: [{type: {_eq: work_order}}, $where]}) {
    ...TaskActive
  }
}
    ${TaskActiveFragmentDoc}`;

export function useTaskActiveQuery(options?: Omit<Urql.UseQueryArgs<ITaskActiveQueryVariables>, 'query'>) {
  return Urql.useQuery<ITaskActiveQuery, ITaskActiveQueryVariables>({ query: TaskActiveDocument, ...options });
};
export const TaskOverviewDataDocument = gql`
    query TaskOverviewData($startDate: date!, $endDate: date!, $startTimestamp: timestamptz!, $endTimestamp: timestamptz!, $prevStartTimestamp: timestamptz!, $where: work_order_bool_exp = {}) {
  active_by_status: work_order_status(where: {value: {_nin: [done, canceled]}}) {
    value
    work_orders_aggregate(
      where: {_and: [{due_date: {_gte: $startDate}}, {due_date: {_lte: $endDate}}, $where], type: {_eq: work_order}}
    ) {
      aggregate {
        count
      }
    }
  }
  completed: work_order_aggregate(
    where: {_and: [{completed_at: {_gte: $startTimestamp}}, {completed_at: {_lt: $endTimestamp}}, $where], type: {_eq: work_order}}
  ) {
    aggregate {
      count
    }
  }
  prev_completed: work_order_aggregate(
    where: {_and: [{completed_at: {_gte: $prevStartTimestamp}}, {completed_at: {_lt: $startTimestamp}}, $where], type: {_eq: work_order}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

export function useTaskOverviewDataQuery(options: Omit<Urql.UseQueryArgs<ITaskOverviewDataQueryVariables>, 'query'>) {
  return Urql.useQuery<ITaskOverviewDataQuery, ITaskOverviewDataQueryVariables>({ query: TaskOverviewDataDocument, ...options });
};
export const ConsumableAdjustmentsDocument = gql`
    query ConsumableAdjustments($where: consumable_log_bool_exp = {}) {
  consumable_log(where: $where) {
    __typename
    adjustment_at
    id
    cost_per_unit
    consumable_id
    consumable {
      id
      name
      public_id
      unit
    }
    place_id
    place {
      id
      name
    }
    task_id
    task {
      ...WorkOrderTag
    }
    asset_id
    asset {
      ...AssetTag
    }
    adjustment
    created_by {
      ...UserTag
    }
    created_at
    updated_at
  }
}
    ${WorkOrderTagFragmentDoc}
${AssetTagFragmentDoc}
${UserTagFragmentDoc}`;

export function useConsumableAdjustmentsQuery(options?: Omit<Urql.UseQueryArgs<IConsumableAdjustmentsQueryVariables>, 'query'>) {
  return Urql.useQuery<IConsumableAdjustmentsQuery, IConsumableAdjustmentsQueryVariables>({ query: ConsumableAdjustmentsDocument, ...options });
};
export const SessionInfoDocument = gql`
    mutation SessionInfo($type: String!, $payload: jsonb!, $id: uuid) {
  insert_analytics_event(
    objects: [{id: $id, type: $type, payload: $payload}]
    on_conflict: {constraint: event_pkey, update_columns: [payload]}
  ) {
    affected_rows
  }
}
    `;

export function useSessionInfoMutation() {
  return Urql.useMutation<ISessionInfoMutation, ISessionInfoMutationVariables>(SessionInfoDocument);
};