import ErrorPage from "@components/page/page-error"
import { keycloak, useAuthenticationState } from "@models/authentication"
import { ReactKeycloakProvider } from "@react-keycloak/web"
import React from "react"
import { PropsWithChildren, useContext } from "react"
export type { FullyAuthenticatedState } from "@models/authentication"

export type AuthInfo = AuthenticationState["state"]

export type AuthData = {
  info: AuthInfo | null
}

type AuthenticationState = ReturnType<typeof useAuthenticationState>

const AuthenticationStateContext = React.createContext<AuthenticationState>(undefined!)

export function useAuthenticationStateContext() {
  return useContext(AuthenticationStateContext)
}

export function useAllowedLocations() {
  const authState = useAuthenticationStateContext()
  const state = authState?.state
  if (!state || state.stage !== "fully_authenticated") return []
  return state.locations
}

const AuthProvider = (props: PropsWithChildren<{}>) => {
  const authState = useAuthenticationState()

  return (
    <ReactKeycloakProvider
      initOptions={{
        enableLogging: false,
        onLoad: "check-sso",
        checkLoginIframe: false,
        // Otherwise a page refresh triggers a second reload
        silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
      }}
      authClient={keycloak}
      onTokens={async (tokens) => {
        if (tokens.token) {
          await authState.onKeycloakAccessTokenUpdate()
        }
      }}>
      <AuthenticationStateContext.Provider value={authState}>
        {authState.state.stage === "authentication_failed" ? <ErrorPage /> : props.children}
      </AuthenticationStateContext.Provider>
    </ReactKeycloakProvider>
  )
}

export default AuthProvider
