import classNames from "classnames"
import { CSSProperties, ReactNode } from "react"

type SpacerObjectValues = {
  x?: number
  y?: number
  top?: number
  left?: number
  bottom?: number
  right?: number
}

type SpacerValues = number | SpacerObjectValues
type PresetValues =
  | "select"
  | "compactSelect"
  | "emptyMultiSelectTrigger"
  | "row"
  | "dueDateRow"

type IconSpacerProps = {
  children: ReactNode
  icon: ReactNode
  spacing?: SpacerValues
  containerRightPadding?: number
  preset?: PresetValues
  className?: string
}

const mapPresetToSpacing: Record<PresetValues, SpacerValues> = {
  select: { x: 10, y: 0 },
  compactSelect: { left: 8, right: 4 },
  emptyMultiSelectTrigger: { x: 4, y: 6 },
  row: { left: 0, right: 6 },
  dueDateRow: { left: 0, right: 8 },
}

const mapPresetToContainerRightPadding: Record<PresetValues, number> = {
  select: 8,
  compactSelect: 10,
  emptyMultiSelectTrigger: 6,
  row: 0,
  dueDateRow: 0,
}

function getSpacingStyle(spacer: SpacerValues): CSSProperties {
  if (typeof spacer === "number") return { padding: spacer }

  let style: CSSProperties = {}
  const left = spacer.left ?? spacer.x
  const right = spacer.right ?? spacer.x
  const top = spacer.top ?? spacer.y
  const bottom = spacer.bottom ?? spacer.y

  if (left) style.paddingLeft = left
  if (right) style.paddingRight = right
  if (top) style.paddingTop = top
  if (bottom) style.paddingBottom = bottom

  return style
}

export const IconSpacer = (props: IconSpacerProps) => {
  const spacing = props.preset ? mapPresetToSpacing[props.preset] : props.spacing ?? 0
  const iconRightPadding =
    props.preset && !props.containerRightPadding
      ? mapPresetToContainerRightPadding[props.preset]
      : props.containerRightPadding ?? 0

  return (
    <div
      className={classNames(
        "flex max-w-full min-w-0 flex-row items-center",
        props.className
      )}
      style={{ paddingRight: iconRightPadding }}>
      <div style={getSpacingStyle(spacing)}>{props.icon}</div>
      {props.children}
    </div>
  )
}
