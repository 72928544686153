import { SelectPopover, SelectPopoverProps } from "@components/shared/single-select"
import {
  useAssetGroupsQuery,
  useCreateAssetGroupMutation,
} from "@graphql/documents/asset-group.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { naturalCompare } from "@utils"
import { useCallback, useMemo } from "react"

const useAssetGroupSelect = (): Omit<SelectPopoverProps<string>, "value"> => {
  const [assetGroups] = useAssetGroupsQuery({ requestPolicy: "cache-first" })

  const items = useMemo(
    () =>
      (assetGroups.data?.asset_group ?? [])
        .slice()
        .sort((a, b) => naturalCompare(a.name, b.name))
        .map((group) => ({
          value: group.id,
          searchValue: group.name,
          label: <> {group.name} </>,
        })),
    [assetGroups.data?.asset_group]
  )

  const createScope = usePermissionScope(IPermissionScopeEnum.AppAssetCreate)
  const [, createAssetGroup] = useCreateAssetGroupMutation()
  const onCreate = useCallback(
    async (name: string): Promise<string> => {
      const result = await createAssetGroup({ name }, createScope.context())
      const id = result.data?.insert_asset_group_one?.id
      if (result.error || !id) throw result.error
      return id
    },
    [createScope, createAssetGroup]
  )

  return {
    items,
    name: i18n.t("assets:fields.group", { count: 1 }),
    onCreate,
    placeholder: i18n.t("common:select_token", {
      token: i18n.t("assets:fields.group", { count: 1 }),
    }),
  }
}

export const AssetGroupSingleSelect = (
  props: Omit<SelectPopoverProps<string>, "items">
) => {
  const assetGroupProps = useAssetGroupSelect()
  return <SelectPopover {...assetGroupProps} {...props} />
}
