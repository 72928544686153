import { uuid } from "@elara/db"
import { useSessionInfoMutation } from "@graphql/documents/analytics.generated"
import { useEffect } from "react"
import { v4 } from "uuid"

const MAX_SESSION_IDLE_LENGTH_IN_MS = 15 * 60 * 1000 // 15 mins

const SESSION_KEY = "ELARA_CURRENT_SESSION"

function parseSession() {
  try {
    const savedSession = localStorage?.getItem(SESSION_KEY)
    return savedSession ? JSON.parse(savedSession) : null
  } catch {
    return null
  }
}

let currentSession: null | {
  id: string
  startedAt: number
  lastClickedAt: number
  completed?: boolean
} = parseSession()

function saveCurrentSession() {
  try {
    localStorage?.setItem(SESSION_KEY, JSON.stringify(currentSession))
  } catch {}
}

async function registerSession(
  captureSessionInfo: (data: { id: uuid; type: string; payload: {} }) => Promise<unknown>
) {
  try {
    currentSession = { id: v4(), startedAt: Date.now(), lastClickedAt: Date.now() }
    saveCurrentSession()
    await captureSessionInfo({
      id: currentSession.id,
      type: "session",
      payload: {
        startedAt: currentSession.startedAt,
      },
    })
  } catch {}
  return currentSession
}

function isSessionIdle() {
  if (!currentSession) return false
  return Date.now() - currentSession.lastClickedAt > MAX_SESSION_IDLE_LENGTH_IN_MS
}

export async function completeSession(
  captureSessionInfo?: (data: { id: uuid; type: string; payload: {} }) => Promise<unknown>
) {
  if (!currentSession) return
  if (currentSession.completed) return
  try {
    currentSession.completed = true
    await captureSessionInfo?.({
      id: currentSession.id,
      type: "session",
      payload: {
        startedAt: currentSession.startedAt,
        endedAt: currentSession.lastClickedAt,
        sessionLength: currentSession.lastClickedAt - currentSession.startedAt,
      },
    })
  } catch {}
}

export function useSessionTracking(isIntialized: boolean) {
  const [, updateSessionInfo] = useSessionInfoMutation()
  useEffect(() => {
    if (!isIntialized) return

    // try catch to avoid any crashed here
    try {
      if (!currentSession) {
        registerSession(updateSessionInfo)
        // Check if we need to finish a session from the previous load
      } else if (isSessionIdle()) {
        completeSession(updateSessionInfo)
        registerSession(updateSessionInfo)
      }
    } catch {}

    // Check every minute seconds whether we can complete a session
    const interval = setInterval(() => {
      if (!currentSession?.completed && isSessionIdle()) {
        completeSession(updateSessionInfo)
      }
    }, 60 * 1000)

    const onClick = () => {
      if (!currentSession) return
      const now = Date.now()
      if (isSessionIdle()) {
        // 1. Complete session
        completeSession(updateSessionInfo)
        // 2. start a new one
        registerSession(updateSessionInfo)
      } else {
        // Update lastClicked at
        currentSession.lastClickedAt = now
        // Save update in local storage
        saveCurrentSession()
      }
    }

    document.addEventListener("click", onClick)
    return () => {
      document.removeEventListener("click", onClick)
      clearInterval(interval)
    }
  }, [isIntialized])
}
