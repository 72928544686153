import { ActionMenu, Button } from "@components/shared"
import { alertDialog } from "@components/shared/alert-dialog-provider"
import CollapsibleTabs from "@components/shared/collapsible-tabs"
import Tabs from "@components/shared/horizontal-tabs"
import Image from "@components/shared/image"
import { Tooltip } from "@components/shared/tooltip"
import { openModal } from "@contexts/modal-context"
import { usePage } from "@contexts/page-context"
import {
  DeleteConsumableDocument,
  useConsumableQuery,
} from "@graphql/documents/consumable.generated"
import { IConsumableFragment } from "@graphql/documents/fragments.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import {
  ChartLine,
  DotsThreeVertical,
  File,
  Info,
  Nut,
  PencilSimpleLine,
  PlusMinus,
  Rows,
  Shapes,
  TrashSimple,
} from "@phosphor-icons/react"
import { useMemo } from "react"
import toast from "react-hot-toast"
import {
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom"
import { useClient } from "urql"

import AdjustmentsTab from "./tabs/adjustments"
import DetailsTab from "./tabs/details"
import DocumentsTab from "./tabs/documents"
import InsightsPage from "./tabs/insights"
import ObjectsTab from "./tabs/objects"

type PageProps = {
  id?: string
}

enum Tab {
  Details = "details",
  Adjustments = "adjustments",
  Objects = "objects",
  Documents = "documents",
  Insights = "insights",
}

export type TabProps = {
  consumable: IConsumableFragment
}

const MaterialDetailPage = (props: PageProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const match = useMatch("/consumable/:id/:page")
  const page = match?.params.page ?? Tab.Details
  const { id = props.id } = useParams() as { id: string }

  const [consumableRes] = useConsumableQuery({ pause: !id, variables: { id: id! } })
  const consumable = consumableRes.data?.consumable_by_pk ?? null
  const adjustments = consumableRes.data?.consumable_log ?? []

  const pageTitle = useMemo(() => {
    return consumable?.name ?? i18n.t("common:consumable", { count: 1 })
  }, [consumable?.name])

  usePage({
    id: "consumable",
    title: pageTitle,
    isSubPage: true,
    paused: !!props.id,
  })

  const dataEntryScope = usePermissionScope(IPermissionScopeEnum.AppDataEntry)
  const editScope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)
  const deleteScope = usePermissionScope(IPermissionScopeEnum.AppAssetArchiveDelete)

  const client = useClient()

  if (!consumable) return null

  const onRestock = () => {
    openModal("material_restock", {
      initialValues: { cost_per_unit: consumable.cost },
      consumable_id: consumable.id,
    })
  }

  const onEdit = () => {
    openModal("consumable", { initialValues: consumable })
  }

  const onDelete = () => {
    alertDialog({
      danger: true,
      title: i18n.t("consumables:actions.delete.title"),
      description: i18n.t("consumables:actions.delete.description"),
      actionText: i18n.t("common:delete"),
      onAction: async () => {
        const res = await client
          .mutation(DeleteConsumableDocument, { id: consumable.id }, deleteScope.context())
          .toPromise()
        if (!res.error) {
          navigate("/consumable")
        } else {
          toast.error(i18n.t("common:messages.delete_failure"))
        }
      },
    })
  }

  return (
    <div className="mx-auto flex h-full w-full flex-col bg-gray-50">
      <section className="flex flex-wrap items-center gap-4 p-3">
        <div>
          <Image
            src={consumable.avatar?.thumbnail_url}
            height={48}
            width={48}
            containerClasses="rounded overflow-hidden border border-gray-200"
            preview={{ src: consumable?.avatar?.url }}
            placeholder={
              <div className="flex h-12 w-12 items-center justify-center bg-gray-50 text-gray-300">
                <Nut size={24} />
              </div>
            }
          />
        </div>
        <div className="grow basis-1/4">
          <h3 className="text-base font-medium sm:text-lg">{consumable.name} </h3>
          <h4>
            <Tooltip content={i18n.t("consumables:fields.public_id")}>
              <span className="font-normal text-gray-500">{consumable.public_id}</span>
            </Tooltip>
          </h4>
        </div>

        <div className="flex items-center justify-end space-x-3 max-sm:w-full">
          <Button
            type="primary"
            color="blue"
            icon={PlusMinus}
            onClick={onRestock}
            disabled={!dataEntryScope.hasScope}
            disabledReason={i18n.t("common:missing_permission")}>
            {i18n.t("consumables:actions.adjust_quantity")}
          </Button>

          <ActionMenu
            dropdownContentProps={{ side: "bottom", align: "end" }}
            items={[
              {
                key: "edit",
                action: onEdit,
                label: i18n.t("common:edit"),
                icon: <PencilSimpleLine />,
                disabled: !editScope.hasScope,
              },
              {
                key: "delete",
                action: onDelete,
                label: i18n.t("common:delete"),
                icon: <TrashSimple />,
                disabled: !editScope.hasScope,
              },
            ]}>
            <Button type="secondary" icon={DotsThreeVertical} />
          </ActionMenu>
        </div>
      </section>

      <section className="flex min-h-0 flex-1 flex-col">
        <Tabs.Root
          value={page}
          onValueChange={(page) => navigate(page, { state: location.state })}>
          <CollapsibleTabs.List
            triggers={[
              {
                value: Tab.Details,
                label: i18n.t("consumables:tabs.details"),
                icon: <Info />,
              },
              {
                value: Tab.Adjustments,
                label: i18n.t("consumables:tabs.adjustments"),
                icon: <Rows />,
              },
              {
                value: Tab.Objects,
                label: i18n.t("common:asset", { count: 2 }),
                icon: <Shapes />,
              },
              {
                value: Tab.Documents,
                label: i18n.t("consumables:tabs.documents"),
                icon: <File />,
              },
              {
                value: Tab.Insights,
                label: i18n.t("consumables:tabs.insights"),
                icon: <ChartLine />,
              },
            ]}
          />
        </Tabs.Root>

        <div className="flex min-h-0 flex-1 flex-col bg-white">
          <Routes>
            <Route path={Tab.Details} element={<DetailsTab consumable={consumable} />} />
            <Route
              path={Tab.Adjustments}
              element={<AdjustmentsTab adjustments={adjustments} consumable={consumable} />}
            />
            <Route path={Tab.Objects} element={<ObjectsTab consumable={consumable} />} />
            <Route
              path={Tab.Documents}
              element={<DocumentsTab consumable={consumable} />}
            />
            <Route path={Tab.Insights} element={<InsightsPage consumable={consumable} />} />
          </Routes>
        </div>
      </section>
    </div>
  )
}

export default MaterialDetailPage
