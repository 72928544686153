import {
  useAskForPushPermissions,
  usePushToggle,
} from "@components/notifications/use-onesignal"
import SettingsPageLayout from "@components/settings/page-layout"
import Toggle from "@components/shared/toggle"
import { Tooltip } from "@components/shared/tooltip"
import { usePage } from "@contexts/page-context"
import { useUser } from "@contexts/user-context"
import { UserSettings } from "@elara/db"
import {
  IUpdateUserSettingsMutation,
  IUpdateUserSettingsMutationVariables,
  UpdateUserSettingsDocument,
} from "@graphql/documents/user.generated"
import i18n from "@i18n"
import { WarningCircle } from "@phosphor-icons/react"
import { isAppleiOS } from "@utils"
import merge from "deepmerge"
import { DeepPartial } from "react-hook-form"
import { useClient } from "urql"

const SettingsNotificationsContent = () => {
  const user = useUser()

  useAskForPushPermissions()
  const { isPushBlocked, isPushEnabled, togglePush } = usePushToggle()

  const client = useClient()
  const updateSettings = (partialSettings: DeepPartial<UserSettings>) => {
    return client
      .mutation<IUpdateUserSettingsMutation, IUpdateUserSettingsMutationVariables>(
        UpdateUserSettingsDocument,
        {
          id: user.id,
          settings: merge(user.profile.settings, partialSettings),
        }
      )
      .toPromise()
  }

  return (
    <div className="flex flex-col items-start space-y-6">
      <div className="space-y-2">
        <h3 className="font-medium">{i18n.t("settings:notifications.channel.title")}</h3>
        <p className="pb-2 text-sm text-gray-500">
          {i18n.t("settings:notifications.channel.description")}
        </p>
        <div className="flex items-center space-x-2">
          <Toggle
            id="email-notification-toggle"
            checked={user.profile.settings.notifications.channel.email}
            onChange={(e) =>
              updateSettings({ notifications: { channel: { email: e.target.checked } } })
            }
          />
          <label className="select-none text-sm" htmlFor="email-notification-toggle">
            {i18n.t("settings:notifications.email")}
          </label>
        </div>

        <div>
          <div className="flex items-center space-x-2">
            <Toggle
              id="push-notification-toggle"
              onChange={(e) => togglePush(e.target.checked)}
              checked={isPushEnabled}
              disabled={isPushBlocked}
            />
            <label className="select-none text-sm" htmlFor="push-notification-toggle">
              {i18n.t("settings:notifications.push")}
            </label>
            {isPushBlocked && (
              <Tooltip
                contentProps={{ side: "right" }}
                content={i18n.t("settings:profile.notifications.push.messages.blocked")}>
                <WarningCircle weight="bold" className="text-gray-500" />
              </Tooltip>
            )}
          </div>

          {isAppleiOS() && (
            <span className="mt-1 text-sm text-gray-500">
              {i18n.t("settings:profile.notifications.push.messages.ios_16_4")}
            </span>
          )}
        </div>
      </div>

      <div className="space-y-2">
        <h3 className="font-medium">{i18n.t("settings:notifications.options.title")}</h3>
        <div className="flex items-center space-x-2">
          <Toggle
            id="options-creator-collaborator"
            checked={user.profile.settings.notifications.options.creatorAsCollaborator}
            onChange={(e) =>
              updateSettings({
                notifications: { options: { creatorAsCollaborator: e.target.checked } },
              })
            }
          />
          <label className="select-none text-sm" htmlFor="options-creator-collaborator">
            {i18n.t("settings:notifications.options.creatorAsCollaborator.label")}
          </label>
        </div>
      </div>
    </div>
  )
}

const SettingsNotificationsPage = () => {
  usePage({
    isSubPage: true,
    id: "settings/notifications",
    title: i18n.t("common:notification_other"),
  })

  return (
    <SettingsPageLayout
      title={i18n.t("notification_other")}
      description={i18n.t("settings:notifications.description")}>
      <SettingsNotificationsContent />
    </SettingsPageLayout>
  )
}

export default SettingsNotificationsPage
