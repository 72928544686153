import { DialogForm } from "@components/shared/dialog-form"
import { FormField } from "@components/shared/form/form-field"
import { SelectPopover } from "@components/shared/single-select"
import { TextInput } from "@components/shared/text-input"
import {
  IMeterTypeEnum,
  IPermissionScopeEnum,
  IScheduledTask,
  IScheduledTaskSetInput,
  uuid,
} from "@elara/db"
import { useAssetDetailsQuery } from "@graphql/documents/asset.generated"
import { useUpdateScheduledTaskMutation } from "@graphql/documents/asset-meter.generated"
import {
  IScheduledTaskFragment,
  IWorkOrderFragment,
} from "@graphql/documents/fragments.generated"
import { useTaskDataViewQuery } from "@graphql/documents/work-order.generated"
import { usePermissionScope } from "@hooks"
import i18n from "@i18n"

type FormState = Partial<IScheduledTask>

type Props = {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  scheduledTask?: IScheduledTaskFragment
}

const CreateEditScheduledTaskForm: React.FC<Props> = ({
  isOpen,
  onOpenChange,
  scheduledTask,
}) => {
  const assetEdit = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)

  const [assetRes] = useAssetDetailsQuery({
    requestPolicy: "cache-only",
    pause: !scheduledTask?.meter.asset_id,
    variables: { id: scheduledTask?.meter.asset_id! },
  })
  const asset = assetRes.data?.asset_by_pk

  const [workOrderRes] = useTaskDataViewQuery({
    pause: !scheduledTask?.meter.asset_id,
    variables: {
      where: { assets: { asset_id: { _in: [scheduledTask?.meter.asset_id!] } } },
    },
  })
  const workOrders = (workOrderRes.data?.work_order || []) as IWorkOrderFragment[]

  const [, updateScheduledTask] = useUpdateScheduledTaskMutation()

  const onSubmit = async (values: FormState) => {
    let data: IScheduledTaskSetInput = {
      task_id: values.task_id,
    }

    if (scheduledTask?.meter.meter_type === IMeterTypeEnum.Meter) {
      data = {
        ...data,
        scheduled_on: values.scheduled_on,
      }
    }

    if (scheduledTask) {
      await updateScheduledTask({ id: scheduledTask.id, data }, assetEdit.context())
    } else {
      // Create the scheduled task
    }
  }

  return (
    <DialogForm<FormState>
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      title={
        scheduledTask
          ? i18n.t("common:edit_token", { token: i18n.t("common:entry", { count: 1 }) })
          : i18n.t("common:create_token", { token: i18n.t("common:entry", { count: 1 }) })
      }
      formikConfig={{
        onSubmit,
        validateOnChange: false,
        initialValues: {
          task_id: scheduledTask?.task_id ?? undefined,
          scheduled_on: scheduledTask?.scheduled_on ?? undefined,
        },
      }}>
      {({ values, setFieldValue, getFieldProps }) => (
        <div className="my-5">
          {scheduledTask?.meter.meter_type === IMeterTypeEnum.Meter && (
            <FormField name="scheduled_on" label={i18n.t("meters:fields.scheduled_on")}>
              <TextInput type="number" placeholder="500" />
            </FormField>
          )}

          <FormField
            name="task_id"
            label={i18n.t("common:task", { count: 1 })}
            hint={i18n.t("meters:messages.task_object_restriction", { name: asset?.name })}>
            <div>
              <SelectPopover<uuid>
                {...getFieldProps("task_id")}
                placeholder={i18n.t("common:select_token", {
                  token: i18n.t("common:template", { count: 1 }),
                })}
                popoverContentProps={{ align: "end" }}
                isClearable={false}
                items={workOrders.map((wo) => ({
                  value: wo.id,
                  label: (
                    <>
                      <span className="inline-block w-12 text-gray-400">
                        #{wo.work_order_number}
                      </span>{" "}
                      {wo.name}
                    </>
                  ),
                  searchValue: `${wo.work_order_number} ${wo.name}`,
                }))}
                value={values.task_id}
                onChange={(value) => setFieldValue("task_id", value)}
              />
            </div>
          </FormField>
        </div>
      )}
    </DialogForm>
  )
}

export default CreateEditScheduledTaskForm
