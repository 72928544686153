import * as TooltipPrimitive from "@radix-ui/react-tooltip"
import classNames from "classnames"
import React from "react"

export const TooltipProvider = TooltipPrimitive.Provider
export const TooltipRoot = TooltipPrimitive.Root
export const TooltipTrigger = TooltipPrimitive.Trigger
export const TooltipContent = React.forwardRef<
  HTMLDivElement,
  TooltipPrimitive.TooltipContentProps
>((props, forwardedRef) => (
  <TooltipPrimitive.Content
    ref={forwardedRef}
    {...props}
    className={classNames(
      "text-sm overflow-hidden rounded-md border max-w-sm border-gray-100 bg-white px-3 py-1.5 text-gray-700 shadow-md animate-in fade-in-30 ",
      props.className
    )}
  />
))

export const Tooltip = ({
  content,
  asChild,
  children,
  contentProps,
  ...props
}: React.PropsWithChildren<
  {
    content: React.ReactNode
    asChild?: boolean
    className?: string
    contentProps?: TooltipPrimitive.TooltipContentProps
  } & TooltipPrimitive.TooltipProps
>) => {
  return (
    <TooltipProvider>
      <TooltipRoot
        delayDuration={500}
        disableHoverableContent={props.disableHoverableContent || true}
        {...props}>
        <TooltipTrigger className="cursor-default" asChild={asChild} type="button">
          {children}
        </TooltipTrigger>
        <TooltipPrimitive.TooltipPortal>
          <TooltipContent
            side="top"
            align="center"
            sideOffset={8}
            {...contentProps}
            className={classNames(contentProps?.className, "group z-max cursor-default")}>
            {content}
          </TooltipContent>
        </TooltipPrimitive.TooltipPortal>
      </TooltipRoot>
    </TooltipProvider>
  )
}
