import { VStack } from "@components/layout"
import { Button, getUserName, UserTag } from "@components/shared"
import {
  createSchema,
  createSchemaStatement,
  generalSelectStateToWhere,
  selectStateToWhere,
} from "@components/shared/data-view/data-view-filter.hooks"
import {
  DataViewFilterDatePicker,
  useDataViewFilterDatePicker,
} from "@components/shared/data-view/data-view-filter-date-picker"
import { Tooltip } from "@components/shared/tooltip"
import { genericAssets } from "@components/work-order/work-order-data-view-filter-configuration"
import { IMeterTypeEnum, IViewDataTypeEnum, uuid } from "@elara/db"
import { orderBy } from "@elara/select"
import {
  AssetGroupsDocument,
  IAssetGroupsQuery,
} from "@graphql/documents/asset-group.generated"
import {
  AssetPlacesDocument,
  IAssetPlacesQuery,
} from "@graphql/documents/asset-place.generated"
import { IMeterDataViewFragment } from "@graphql/documents/fragments.generated"
import { useCallbackRef } from "@hooks"
import i18n from "@i18n"
import { Gauge, Plus, Warning } from "@phosphor-icons/react"
import { getFormattedDate, parseDate } from "@utils"
import {
  LinkWithBackgroundLocation,
  useNavigateWithBackgroundLocation,
} from "@utils/location"
import React, { useCallback, useMemo } from "react"
import { Trans } from "react-i18next"

import {
  Column,
  DataView,
  DataViewConfiguration,
  DataViewProps,
} from "../shared/data-view/data-view"
import { AssetWithParents } from "./asset-with-parents"
import { useMeterActions } from "./detail/meter/meter-action-menu"

export type MeterViewOptions = {
  onNewMeterReading: (meterId: uuid) => void
}

export type MeterViewIds =
  | "name"
  | "meter_type"
  | "description"
  | "last_reading"
  | "last_reading_by"
  | "asset"
  | "asset_group"
  | "place"
  | "actions"

export const columns: Column<IMeterDataViewFragment, MeterViewIds, MeterViewOptions>[] = [
  {
    Header: i18n.t("common:name"),
    id: "name",
    defaultWidth: 300,
    toText: (row) => row.name,
    orderBy: (dir) => ({ name: dir }),
    Cell: (row) => (
      <div className="flex shrink">
        <span className="truncate font-medium">{row.name}</span>
      </div>
    ),
  },
  {
    Header: i18n.t("common:description"),
    id: "description",
    Cell: (row) => row.description,
    toText: (row) => row.description ?? "",
  },
  {
    Header: i18n.t("meters:fields.location", { count: 1 }),
    id: "place",
    toText: (row) =>
      row?.asset.place?.name ??
      i18n.t("common:without_token", {
        token: i18n.t("meters:fields.location", { count: 1 }),
      }),
    orderBy: (dir) => ({ asset: { place: { name: dir } } }),
    Cell: (row) => {
      return row.asset?.place?.name
    },
    groupBy: {
      id: (row) => (row.asset?.place?.id ? [row.asset?.place.id] : null),
      label: (id, row) =>
        row?.asset.place?.name ??
        i18n.t("common:without_token", {
          token: i18n.t("meters:fields.location", { count: 1 }),
        }),
    },
  },
  {
    Header: i18n.t("common:asset", { count: 1 }),
    id: "asset",
    defaultWidth: 350,
    toText: (row) => row.asset.name,
    orderBy: (dir) => ({ asset: { name: dir } }),
    Cell: (row) => {
      if (row.asset?.id) {
        return (
          <div className="-m-1 flex shrink">
            <LinkWithBackgroundLocation
              to={`/object/${row.asset?.id}`}
              onClick={(e) => e.stopPropagation()}
              className="flex w-full min-w-0 cursor-pointer truncate break-all rounded font-medium ring-gray-300 hover:bg-gray-100 hover:text-gray-700 hover:ring-1">
              <AssetWithParents asset={row.asset} showAvatar />
            </LinkWithBackgroundLocation>
          </div>
        )
      }

      return row.asset?.name
    },
    groupBy: {
      id: (row) => (row.asset?.id ? [row.asset?.id] : null),
      label: (id, row) =>
        row?.asset.name ??
        i18n.t("common:without_token", { token: i18n.t("common:asset", { count: 1 }) }),
    },
  },
  {
    Header: i18n.t("meters:fields.type"),
    id: "meter_type",
    toText: (row) =>
      row.meter_type === IMeterTypeEnum.Meter
        ? i18n.t("common:meter", { count: 1 })
        : i18n.t("meters:types.measurement"),
    orderBy: (dir) => ({
      meter_type: {
        dir,
        transform(v) {
          return v === IMeterTypeEnum.Meter ? 1 : 0
        },
      },
    }),
    Cell: (row) =>
      row.meter_type === IMeterTypeEnum.Meter
        ? i18n.t("common:meter", { count: 1 })
        : i18n.t("meters:types.measurement"),
    groupBy: {
      id: (row) => row.meter_type,
      label: (id) =>
        id === IMeterTypeEnum.Meter
          ? i18n.t("common:meter", { count: 1 })
          : i18n.t("meters:types.measurement"),
    },
  },
  {
    Header: i18n.t("assets:fields.group", { count: 1 }),
    id: "asset_group",
    Cell: (row) => row.asset?.group?.name,
    toText: (row) => row.asset.group?.name ?? "",
    orderBy: (dir) => ({ asset: { group: { name: dir } } }),
    groupBy: {
      id: (row) => (row.asset?.group?.id ? [row.asset?.group.id] : null),
      label: (id, row) =>
        row?.asset?.group?.name ??
        i18n.t("common:without_token", {
          token: i18n.t("assets:fields.group", { count: 1 }),
        }),
    },
  },
  {
    Header: "",
    id: "actions",
    toText: () => "",
    Cell: (row, options) => {
      if (!options.onNewMeterReading) return null
      return (
        <Button
          type="tertiary"
          icon={Plus}
          onClick={(e) => {
            e.stopPropagation()
            options.onNewMeterReading?.(row.id)
          }}>
          {i18n.t("meters:fields.reading", { count: 1 })}
        </Button>
      )
    },
  },
  {
    Header: i18n.t("meters:fields.last_reading"),
    id: "last_reading",
    defaultWidth: 300,
    toText: (row) =>
      row.last_readings?.[0]
        ? getFormattedDate(parseDate(row.last_readings?.[0]?.measured_at), {
            includeTime: true,
          })
        : "",
    Cell: (row) => {
      const value = row.last_readings?.[0]?.value
      const measured_at = row.last_readings?.[0]?.measured_at
      if (!row.last_readings?.[0])
        return (
          <span className="inline-block w-full text-right text-xs text-gray-500">
            {i18n.t("common:no_token", {
              context: "female",
              token: i18n.t("meters:fields.reading", { count: 1 }),
            })}
          </span>
        )
      if (!measured_at) return null
      if (!value) return null

      const isMeasurement = row.meter_type === IMeterTypeEnum.Measurement
      let isOutsideRange = false
      if (row.range_end && value > row.range_end) {
        isOutsideRange = true
      } else if (row.range_start && value < row.range_start) {
        isOutsideRange = true
      }

      return (
        <div className="flex flex-col items-end">
          <span className="flex w-full min-w-0 justify-end truncate">
            <span className="min-w-0 pr-1 font-medium tabular-nums">{value}</span>
            <span className="min-w-0 shrink-[10] pr-1 text-gray-600">{row.unit}</span>
            {isMeasurement && isOutsideRange && (
              <Tooltip content={i18n.t("meters:messages.reading_outside_range")}>
                <Warning size={20} className="text-yellow-500" />
              </Tooltip>
            )}
          </span>
          <span className="min-w-0 shrink-[100] truncate text-xs tabular-nums text-gray-500">
            {getFormattedDate(parseDate(measured_at), {
              includeTime: true,
            })}
          </span>
        </div>
      )
    },
  },
  {
    Header: i18n.t("meters:fields.last_reading_by"),
    id: "last_reading_by",
    toText: (row) =>
      row.last_readings?.[0] ? getUserName(row.last_readings?.[0]?.measured_by) : "",
    Cell: (row) => {
      const user = row.last_readings?.[0]?.measured_by
      if (!user) return null
      return <UserTag user={user} />
    },
  },
]

export const meterDataViewDefaultConfig = {
  columnOrder: ["name", "asset", "last_reading", "actions"],
  orderBy: [
    { id: "name", dir: "asc" },
    { id: "place", dir: "asc" },
  ],
} as DataViewConfiguration<MeterViewIds>

type MeterDataViewProps = Omit<
  DataViewProps<IMeterDataViewFragment, MeterViewIds, MeterViewOptions>,
  | "columnDefinitions"
  | "dataType"
  | "list_item"
  | "defaultConfig"
  | "filter"
  | "onSelect"
  | "dataId"
  | "dataSearchValue"
> &
  Partial<
    Pick<
      DataViewProps<IMeterDataViewFragment, MeterViewIds, MeterViewOptions>,
      "defaultConfig"
    >
  > & { assetId?: uuid }

const useFilterSchema = () => {
  const datePicker = useDataViewFilterDatePicker()

  const schema = useMemo(() => {
    return createSchema<IMeterDataViewFragment>({
      asset: genericAssets<IMeterDataViewFragment>((state) =>
        generalSelectStateToWhere(["asset", "id"], state)
      ),
      assetGroup: createSchemaStatement<IMeterDataViewFragment, string>({
        type: "select",
        label: i18n.t("assets:fields.group", { count: 1 }),
        multiSelectedLabel: i18n.t("assets:fields.group", { count: 2 }),
        toWhere: (state) => ({ asset: { group: { id: selectStateToWhere(state) } } }),
        getItems: async (client) => {
          const queryRes = await client
            .query<IAssetGroupsQuery>(
              AssetGroupsDocument,
              {},
              {
                query: AssetGroupsDocument,
                requestPolicy: "cache-first",
              }
            )
            .toPromise()
          const groups = queryRes?.data?.asset_group
          if (!groups) return null
          return orderBy(groups, { name: "asc" }).map((a) => ({
            label: a.name,
            value: a.id,
            searchValue: a.name,
          }))
        },
      }),
      place: createSchemaStatement<IMeterDataViewFragment, string>({
        type: "select",
        label: i18n.t("assets:fields.location", { count: 1 }),
        multiSelectedLabel: i18n.t("assets:fields.location", { count: 2 }),
        toWhere: (state) => ({ asset: { place: { id: selectStateToWhere(state) } } }),
        getItems: async (client) => {
          const queryRes = await client
            .query<IAssetPlacesQuery>(
              AssetPlacesDocument,
              {},
              {
                requestPolicy: "cache-first",
              }
            )
            .toPromise()
          const groups = queryRes?.data?.place
          if (!groups) return null
          return orderBy(groups, { name: "asc" }).map((a) => ({
            label: a.name,
            value: a.id,
            searchValue: a.name,
          }))
        },
      }),
    })
  }, [])

  return {
    schema,
    components: <DataViewFilterDatePicker dataViewFilterDaterPicker={datePicker} />,
  }
}

const MeterListItem = (meter: IMeterDataViewFragment, options?: MeterViewOptions) => {
  const isMeasurement = meter.meter_type === IMeterTypeEnum.Measurement
  const value = meter.last_readings?.[0]?.value
  let isOutsideRange = false
  if (meter.range_end && value > meter.range_end) {
    isOutsideRange = true
  } else if (meter.range_start && value < meter.range_start) {
    isOutsideRange = true
  }

  return (
    <div className="space-y-2 py-3">
      <div className="flex flex-wrap items-center gap-x-4 gap-y-1">
        <AssetWithParents showAvatar asset={meter.asset} />
      </div>
      <div className="font-semibold">{meter.name}</div>
      <div className="flex">
        <span className="font-semibold tracking-tight">
          {meter.last_readings[0]?.value ?? "--"}
        </span>{" "}
        <span className="ml-1 text-sm text-gray-700">{meter.unit}</span>
        {isMeasurement && isOutsideRange && (
          <Tooltip content={`Letzte Messung außerhalb des Toleranzbereiches.`}>
            <Warning size={20} className="ml-1 text-yellow-500" />
          </Tooltip>
        )}
        <div className="ml-1 text-sm text-gray-500">
          <span>{i18n.t("meters:fields.last_reading_from")}</span>{" "}
          <span className="font-medium">
            {getFormattedDate(parseDate(meter.last_readings?.[0]?.measured_at), {
              includeTime: true,
            })}
          </span>{" "}
        </div>
      </div>
      <div>
        <Button
          type="tertiary"
          icon={Plus}
          onClick={(e) => {
            e.stopPropagation()
            options?.onNewMeterReading?.(meter.id)
          }}>
          {i18n.t("meters:fields.reading", { count: 1 })}
        </Button>
      </div>
    </div>
  )
}

export const MeterDataView = React.memo(
  ({ defaultConfig, assetId, ...props }: MeterDataViewProps) => {
    const filter = useFilterSchema()
    const navigateWithBackground = useNavigateWithBackgroundLocation()

    const onSelect = useCallbackRef((data: IMeterDataViewFragment) => {
      navigateWithBackground(`/meter/${data.id}`)
    })
    const dataId = useCallback((d: IMeterDataViewFragment) => d.id, [])
    const dataSearchValue = useCallback(
      (d: IMeterDataViewFragment) =>
        `${d.name} ${d.asset?.name ?? ""} ${d.asset?.place ?? ""} ${d.description ?? ""} ${
          d.unit ?? ""
        } `,
      []
    )

    const actions = useMeterActions(assetId)

    if (!filter) return null
    const { schema, components } = filter
    return (
      <>
        <DataView
          {...props}
          options={{ onNewMeterReading: actions.onAddReading }}
          dataId={dataId}
          dataSearchValue={dataSearchValue}
          columnDefinitions={columns}
          filterSchema={schema}
          onSelect={onSelect}
          searchPlaceholder={i18n.t("common:search_token", {
            token: i18n.t("common:meter", { count: 1 }),
          })}
          dataType={IViewDataTypeEnum.Meter}
          listItem={MeterListItem}
          defaultConfig={{
            ...meterDataViewDefaultConfig,
            ...defaultConfig,
          }}
          baseOrderBy={[
            { id: "name", dir: "asc" },
            { id: "place", dir: "asc" },
          ]}
          noData={{
            icon: Gauge,
            title: (
              <span className="mr-auto inline-block w-full">
                {i18n.t("common:no_token_created_yet", {
                  token: i18n.t("common:meter", { count: 2 }),
                })}
              </span>
            ),
            message: (
              <VStack space={16} className="text-justify">
                <Trans
                  i18n={i18n}
                  className="text-sm font-normal text-gray-600"
                  i18nKey="meters:messages.meter_type_description">
                  <p>
                    Es werden zwei Konfigurationen für Zähler unterstützt:
                    <span className="font-medium">Interval</span> und
                    <span className="font-medium">Toleranzbereich</span>.
                  </p>
                  <p>
                    Ein Beispiel für <span className="font-medium">Interval</span> sind
                    Betriebsstunden. Für einen Betriebsstundenzähler kann eine regelmäßige
                    Aufgabe hinterlegt werden wie eine Wartung alle 500 Stunden.
                  </p>
                  <p>
                    Ein Beispiel für einen
                    <span className="font-medium">Toleranzbereich</span> ist die
                    Kontrollmessung von Waagen. Es kann eine Aufgabe hinterlegt werden, die
                    erstellt wird bei Messungen außerhalb des Toleranzbereiches.
                  </p>
                </Trans>
              </VStack>
            ),
            actions: (
              <Button
                icon={Gauge}
                size="small"
                type="secondary"
                className="mt-4"
                onClick={actions.meterModal.onOpen}>
                {i18n.t("meters:actions.new_meter")}
              </Button>
            ),
          }}
        />
        {components}
        {actions.addMeterDialog}
        {actions.addReadingDialog}
      </>
    )
  }
)
