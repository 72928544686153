import { Button } from "@components/shared"
import { PopoverClose, PopoverContent } from "@components/shared/popover"
import { MaintenanceTrigger } from "@elara/db"
import { IMaintenanceFragment } from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { ArrowRight, Pause, Repeat, X } from "@phosphor-icons/react"
import * as Popover from "@radix-ui/react-popover"
import { cn } from "@utils"
import { formatDate } from "@utils/date"
import { useNavigateWithBackgroundLocation } from "@utils/location"
import { sub } from "date-fns"
import { PropsWithChildren } from "react"

import TimeIntervalTriggerDigest from "./time-interval-trigger-digest"

type ScheduledEventItemProps = {
  maintenance: Pick<IMaintenanceFragment, "id" | "name" | "description" | "paused">
  trigger: MaintenanceTrigger
  date: Date | null
}

export const ScheduledEventItemPopover = ({
  maintenance,
  trigger,
  date,
  children,
}: PropsWithChildren<ScheduledEventItemProps>) => {
  const navigate = useNavigateWithBackgroundLocation()

  return (
    <div className="flex w-full flex-col">
      <Popover.Root>
        {children}
        <Popover.Portal>
          <PopoverContent align="start" className="p-3" sideOffset={4}>
            <div className="grid gap-1 text-sm">
              <div className="flex justify-between text-xs font-medium text-gray-500">
                {i18n.t("maintenance:labels.details.title")}
                <PopoverClose>
                  <X weight="bold" className="focus:text-gray-600" />
                </PopoverClose>
              </div>
              <div className="text-xs leading-relaxed">
                <div className="text-sm font-medium">{maintenance.name}</div>
                <div className="text-gray-600">{maintenance.description}</div>
              </div>

              {maintenance.paused && (
                <div className="inline-flex items-center text-xs leading-relaxed">
                  <Pause size={20} className="mr-2" />
                  <span>{i18n.t("maintenance:messages.status.paused")}</span>
                </div>
              )}

              <div className="flex items-center text-xs text-gray-600">
                {trigger.type === "time" && (
                  <>
                    <Repeat weight="bold" className="mr-1 text-gray-500" />
                    <TimeIntervalTriggerDigest payload={trigger.payload} />
                  </>
                )}
              </div>
              {date && trigger.type === "time" && (
                <div className="mt-2 text-xs text-gray-500">
                  {i18n.t("maintenance:labels.create_next_at", {
                    date: formatDate(
                      sub(new Date(date), trigger.payload.createBefore),
                      "P"
                    ),
                  })}
                </div>
              )}

              <Button
                color="gray"
                type="tertiary"
                size="extra-small"
                icon={ArrowRight}
                className="-ml-2 inline-flex self-start justify-self-start"
                onClick={() => navigate(`/maintenance/${maintenance.id}`)}>
                {i18n.t("maintenance:labels.go_to_maintenance")}
              </Button>
            </div>
          </PopoverContent>
        </Popover.Portal>
      </Popover.Root>
    </div>
  )
}
const ScheduledEventItem = (props: ScheduledEventItemProps) => {
  return (
    <ScheduledEventItemPopover {...props}>
      <Popover.Trigger className={cn("group", { "opacity-75": props.maintenance.paused })}>
        <div className="flex gap-1 rounded bg-teal-50 p-1 text-teal-700 ">
          <div className="flex items-center gap-0.5">
            {props.maintenance.paused && <Pause weight="fill" />}
            <Repeat weight="bold" className="" />
          </div>

          <span className="min-w-0 truncate text-xs font-medium leading-none">
            {props.maintenance.name}
          </span>
        </div>
      </Popover.Trigger>
      <Popover.Anchor />
    </ScheduledEventItemPopover>
  )
}

export default ScheduledEventItem
