import { CustomViewShareSelect } from "@components/view/custom-view-share-select"
import { IViewDataTypeEnum, uuid } from "@elara/db"
import {
  CreateCustomViewDocument,
  ICreateCustomViewMutation,
  ICreateCustomViewMutationVariables,
  ICustomViewFragment,
  IUpdateCustomViewByIdMutation,
  IUpdateCustomViewByIdMutationVariables,
  UpdateCustomViewByIdDocument,
} from "@graphql/documents/custom-view.generated"
import i18n from "@i18n"
import classNames from "classnames"
import { Field, FieldProps } from "formik"
import { Trans } from "react-i18next"
import { Link } from "react-router-dom"
import { useClient } from "urql"
import { validate } from "uuid"
import * as yup from "yup"

import { DialogForm } from "../dialog-form"
import { FormField } from "../form/form-field"
import { RadioInput } from "../form/radio-input"
import IconPicker from "../icon-picker"
import { IconData, randomIcon } from "../team-icons"
import { TextInput } from "../text-input"
import toast from "../toast"
import { DataViewConfiguration } from "./data-view-types"

const Schema = yup.object().shape({
  selected: yup.string().required(),
  name: yup.string().when("selected", {
    is: "overwrite",
    otherwise: (s) =>
      s.required(i18n.t("common:forms.is_required", { field: i18n.t("common:name") })),
  }),
})

export const DataViewUpdateCustomViewConfig = (props: {
  customView: Partial<ICustomViewFragment> | null
  config: DataViewConfiguration<any>
  isDefaultView?: boolean
  isEditView?: boolean
  dataType: IViewDataTypeEnum
  teamId?: uuid
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  onCreateView?: (customView: ICustomViewFragment) => void
}) => {
  const client = useClient()
  return (
    <DialogForm
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
      title={
        props.customView?.id
          ? i18n.t("common:edit_token", { token: i18n.t("common:view", { count: 1 }) })
          : i18n.t("common:save_token", { token: i18n.t("common:view", { count: 1 }) })
      }
      className="!max-w-lg"
      formikConfig={{
        initialValues: {
          selected: (props.customView?.id ? "overwrite" : "new") as "overwrite" | "new",
          name: props.customView?.name,
          icon: (props.customView?.icon ?? randomIcon()) as IconData,
          shared: (props.customView?.shared
            ? props.customView?.shared_team_id ?? props.teamId ?? "allUsers"
            : "private") as "private" | "allUsers" | uuid,
        },
        validationSchema: Schema,
        onSubmit: async (values) => {
          const data = {
            name: values.name,
            icon: values.icon,
            config: props.config,
            data_type: props.dataType,
            shared: values.shared !== "private",
            shared_team_id: validate(values.shared) ? values.shared : null,
          }
          if (props.customView?.id && values.selected === "overwrite") {
            const res = await client
              .mutation<
                IUpdateCustomViewByIdMutation,
                IUpdateCustomViewByIdMutationVariables
              >(UpdateCustomViewByIdDocument, {
                id: props.customView?.id,
                data,
              })
              .toPromise()
            if (res.error) {
              toast.error(i18n.t("views:messages.view_update_error"))
            }
          } else {
            const res = await client
              .mutation<ICreateCustomViewMutation, ICreateCustomViewMutationVariables>(
                CreateCustomViewDocument,
                { data }
              )
              .toPromise()
            if (res.error) {
              toast.error(i18n.t("views:messages.view_update_error"))
            } else if (res.data?.insert_custom_view_one) {
              props.onCreateView?.(res.data?.insert_custom_view_one)
              toast.success({
                title: i18n.t("views:messages.new_view_created"),
                params: { duration: 5000, position: "bottom-right" },
                body: (
                  <span>
                    {i18n.t("view", { count: 1 })}{" "}
                    <Link
                      to={`/view/${res.data?.insert_custom_view_one?.id}`}
                      className="mr-1 text-blue-700">
                      {values.name}
                    </Link>
                    {i18n.t("created")}
                  </span>
                ),
              })
            }
          }
        },
      }}>
      {(formik) => {
        return (
          <div className="my-4 text-sm text-gray-700">
            {(props.customView?.id || props.isDefaultView) && !props.isEditView && (
              <>
                <p className="font-medium">{i18n.t("views:messages.update_or_replace")}</p>
                <label className="group mt-3 flex items-center">
                  <Field name="selected">
                    {({ field }: FieldProps<any>) => (
                      <RadioInput
                        {...field}
                        value="overwrite"
                        disabled={props.isDefaultView}
                        checked={field.value === "overwrite"}
                        onChange={(e) => {
                          formik.setFieldValue("icon", formik.initialValues.icon)
                          field.onChange(e)
                        }}
                      />
                    )}
                  </Field>

                  <span
                    className={classNames("ml-2", {
                      "text-gray-200": props.isDefaultView,
                    })}>
                    <Trans
                      i18n={i18n}
                      i18nKey="views:actions.replace_original_view"
                      values={{ name: props.customView?.name }}
                      components={{ bold: <span className="font-medium" /> }}
                    />
                  </span>
                </label>
                <label className="mt-2 flex items-center">
                  <Field name="selected">
                    {({ field }: FieldProps<any>) => (
                      <RadioInput
                        {...field}
                        value="new"
                        checked={field.value === "new"}
                        onChange={(e) => {
                          formik.setFieldValue("icon", randomIcon())
                          field.onChange(e)
                        }}
                      />
                    )}
                  </Field>
                  <span className="ml-2">{i18n.t("views:actions.save_as_new_view")}</span>
                </label>
              </>
            )}
            {(formik.values.selected === "new" || props.isEditView) && (
              <div className="mt-2">
                <FormField name="icon" label={i18n.t("common:icon")}>
                  {({ field, helpers }) => {
                    return (
                      <IconPicker
                        section="favorite"
                        name={field?.value?.name}
                        color={field?.value?.color}
                        onChange={helpers.setValue}
                        className="mr-1 hover:bg-gray-200"
                        showChangeButton
                      />
                    )
                  }}
                </FormField>
                <div className="flex items-end space-x-3">
                  <FormField name="name" label={i18n.t("common:name")} className="flex-1">
                    <TextInput />
                  </FormField>
                </div>
                <div>
                  <span className="mr-2 whitespace-nowrap text-sm text-gray-700">
                    {i18n.t("views:sharing.label")}
                  </span>
                  <FormField name="shared" noStyle>
                    {({ field, helpers }) => (
                      <CustomViewShareSelect
                        value={field.value}
                        onChange={helpers.setValue}
                      />
                    )}
                  </FormField>
                </div>
              </div>
            )}
          </div>
        )
      }}
    </DialogForm>
  )
}
