import { Button } from "@components/shared"
import { DataViewTabs } from "@components/shared/data-view/data-view-tabs"
import { IViewDataTypeEnum } from "@elara/db"
import i18n from "@i18n"
import { Plus } from "@phosphor-icons/react"
import { Link } from "react-router-dom"

const ServiceRequestsPage = () => (
  <DataViewTabs
    dataType={IViewDataTypeEnum.ServiceRequest}
    title={i18n.t("common:service_request", { count: 2 })}
    action={
      <Link to="/service-request/create">
        <Button icon={Plus}>
          {i18n.t("common:new_token", {
            context: "female",
            token: i18n.t("common:service_request", { count: 1 }),
          })}
        </Button>
      </Link>
    }
  />
)

export default ServiceRequestsPage
