import { dequal } from "dequal"
import { useRef } from "react"

export type FieldPrimitiveValue = string | number | boolean
export type Maybe<T> = T | null
export type DataValue =
  | FieldPrimitiveValue
  | Data
  | Array<Data>
  | Array<FieldPrimitiveValue>
export type Data = { [key: string]: unknown } & { [key: string]: DataValue | null }

export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }

/**
 * Get a field with the given key sequence.
 *
 * @param data The data object the field should be extracted from
 * @param keys A key sequence that describes the path to the data
 * @returns The field value described by the data
 */
export function _unsafeGetField<D extends Data>(
  data: D,
  keys: string[]
): FieldPrimitiveValue | null {
  let value = data as any
  for (let key of keys) {
    if (typeof value !== "object" || value === null) return null
    value = value[key]
  }

  return value ?? null
}

export function useDeepMemo<K, V>(memoFn: () => V, key: K) {
  const ref = useRef<{ key: K; value: V }>()
  if (!ref.current || !dequal(key, ref.current.key)) {
    ref.current = { key, value: memoFn() }
  }
  return ref.current.value
}
