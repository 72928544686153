import { useLocaleOptions } from "src/i18n/languages"

import { Select, SelectProps } from "./select"

const LanguageSelect = (props: Omit<SelectProps, "items">) => {
  const locales = useLocaleOptions()
  return <Select {...props} items={locales} />
}

export default LanguageSelect
