import { IViewDataTypeEnum } from "@elara/db"
import { ICustomViewFragment } from "@graphql/documents/custom-view.generated"
import { uuid } from "@graphql/scalars"
import { ReactNode } from "react"

import { DataViewCustomViewFetcher } from "./data-view-custom-view-fetcher"
import ConsumableDataView from "./implementations/consumable-data-view"
import MaintenanceDataView from "./implementations/maintenance-data-view"
import { MeterDataView } from "./implementations/meter-data-view"
import ObjectDataView from "./implementations/object-data-view"
import TaskDataView from "./implementations/task-data-view"

export const CustomView = (props: {
  edit?: boolean
  viewId: uuid
  onEditViewFinish?: () => void
  onCreateView?: (view: ICustomViewFragment) => void
  action?: ReactNode
}) => {
  return (
    <DataViewCustomViewFetcher
      key={props.viewId}
      customViewId={props.viewId}
      render={(customView) => {
        switch (customView.data_type) {
          case IViewDataTypeEnum.Workorder:
            return <TaskDataView customView={customView} key={customView.id} />

          case IViewDataTypeEnum.Asset:
            return <ObjectDataView customView={customView} key={customView.id} />
          case IViewDataTypeEnum.Consumable:
            return <ConsumableDataView customView={customView} key={customView.id} />
          case IViewDataTypeEnum.Maintenance:
            return <MaintenanceDataView customView={customView} key={customView.id} />
          case IViewDataTypeEnum.Meter:
            return <MeterDataView customView={customView} key={customView.id} />

          default:
            return <>{null}</>
        }
      }}
    />
  )
}

export default CustomView
