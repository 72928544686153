import { DataView } from "@components/new-data-view/data-view"
import {
  columns,
  useWorkOrderDataViewSchema,
  workOrderDataViewDefaultConfig,
  WorkOrderViewIds,
} from "@components/work-order/work-order-data-view"
import { IViewDataTypeEnum, IWorkOrderTypeEnum } from "@elara/db"
import { IWorkOrderDataViewFragment } from "@graphql/documents/fragments.generated"
import {
  ITaskDataViewQuery,
  ITaskDataViewQueryVariables,
  TaskDataViewDocument,
} from "@graphql/documents/work-order.generated"
import i18n from "@i18n"
import { useNavigateWithBackgroundLocation } from "@utils/location"
import gql from "graphql-tag"
import { useClient } from "urql"

import { TaskDataViewCalendar } from "./task-data-view-calendar"
import { TaskDataViewListItem } from "./task-data-view-list-item"
import { DataViewImplementation } from "./types"

export default function TaskDataView(
  props: DataViewImplementation<
    IWorkOrderDataViewFragment,
    WorkOrderViewIds,
    ITaskDataViewQuery,
    ITaskDataViewQueryVariables
  >
) {
  const schema = useWorkOrderDataViewSchema()
  const client = useClient()
  const navigate = useNavigateWithBackgroundLocation()
  const onSelect = (t: IWorkOrderDataViewFragment) => navigate(`/task/${t.id}`)

  return (
    <DataView<
      IWorkOrderDataViewFragment,
      WorkOrderViewIds,
      ITaskDataViewQuery,
      ITaskDataViewQueryVariables
    >
      columns={columns}
      defaultSearchColumn="task"
      dataType={IViewDataTypeEnum.Workorder}
      defaultConfig={props.defaultConfig ?? workOrderDataViewDefaultConfig}
      configId={props.configId ?? props.customView?.id ?? "work-order:default"}
      renderListItem={TaskDataViewListItem}
      configStickyness="localStorage"
      schema={schema}
      tieBraker={[{ id: "createdAt", dir: "desc" }]}
      getId={(r) => r.id}
      getData={(data) => data?.work_order ?? []}
      query={TaskDataViewDocument}
      chunkSize={30}
      onSelect={onSelect}
      where={{
        type: { _eq: IWorkOrderTypeEnum.WorkOrder },
      }}
      tree={{
        rootWhere: { parent_work_order_id: { _is_null: true } },
        childrenQuery: (parentRow: IWorkOrderDataViewFragment) => ({
          parent_work_order_id: { _eq: parentRow.id },
        }),
        numberOfChildren: (row) => row.subtasks.length,
        includeSubitemsLabel: i18n.t("data-view:task.include_subtasks"),
      }}
      numberOfRows={(where) =>
        client
          .query(
            gql`
              query ($where: work_order_bool_exp) {
                work_order_aggregate(where: $where) {
                  aggregate {
                    count
                  }
                }
              }
            `,
            { where }
          )
          .toPromise()
          .then(({ data }) => {
            return data?.work_order_aggregate.aggregate?.count ?? 0
          })
      }
      calendar={<TaskDataViewCalendar onSelect={onSelect} />}
      {...props}
    />
  )
}
