import { FileUploadProvider } from "@contexts/file-upload-context"
import { IPermissionScopeEnum, uuid } from "@elara/db"
import {
  IFileFolderFragment,
  IFileFolderUploadFragment,
  ISubFolderUploadFragment,
  useAddEmptyFileFolderMutation,
  useAddFileToFileFolderMutation,
  useDeleteFileFolderFileMutation,
  useDeleteFileFolderFolderMutation,
  useEditFileFolderNameMutation,
  useGetFileFolderQuery,
} from "@graphql/documents/file-folder.generated"
import { IAssetDetailFragment } from "@graphql/documents/fragments.generated"
import { useEditFileNameMutation } from "@graphql/documents/upload.generated"
import { usePermissionScope } from "@hooks/use-permission-scope"
import { useUploadAction } from "@hooks/use-upload-action"
import i18n from "@i18n"
import { FolderSimplePlus, Plus } from "@phosphor-icons/react"
import Icons from "@resources/icons"
import { px } from "@styles"
import { formatBytes } from "@utils"
import classNames from "classnames"
import React, { FC, PropsWithChildren, useCallback, useLayoutEffect, useState } from "react"

import Box from "./box"
import { Button } from "./button"
import CircularProgress from "./circular-progress"
import { Dialog } from "./dialog"
import Divider from "./divider"
import { useFolderUpload } from "./file-explorer.hooks"
import FilesList from "./files-list"
import ScrollArea from "./scroll-area"
import toast from "./toast"

type UseFileExplorerOptions = {
  rootFolderId: uuid
  initialFolderId?: uuid | null
  rootFolderName: string
}

export const useFileExplorer = ({
  initialFolderId,
  rootFolderId,
  rootFolderName,
}: UseFileExplorerOptions) => {
  const editScope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)

  const [currentFolderId, setCurrentFolderId] = useState(initialFolderId || rootFolderId)
  useLayoutEffect(() => {
    if (initialFolderId) {
      setCurrentFolderId(initialFolderId)
    } else {
      setCurrentFolderId(rootFolderId)
    }
  }, [initialFolderId, rootFolderId])

  const [folderResult] = useGetFileFolderQuery({
    variables: { id: currentFolderId },
    // We need this here since we lay in the calls of the hook that rootFolderId is given...
    pause: !currentFolderId,
    requestPolicy: "cache-first",
  })

  const [, editFileName] = useEditFileNameMutation()
  const [, editFolderName] = useEditFileFolderNameMutation()
  const [, addFileToFolderFile] = useAddFileToFileFolderMutation()
  const [, deleteFile] = useDeleteFileFolderFileMutation()
  const [, deleteFolder] = useDeleteFileFolderFolderMutation()
  const [, addEmptyFolder] = useAddEmptyFileFolderMutation()
  const [isInAddFolderMode, setIsInAddFolderMode] = useState(() => false)

  const currentFolder = folderResult.data?.file_folder_by_pk

  const filesAndFolders = React.useMemo(() => {
    const filesAndFolders: (IFileFolderUploadFragment | ISubFolderUploadFragment)[] = []
    return filesAndFolders
      .concat(currentFolder?.sub_folders ?? [])
      .concat(currentFolder?.files ?? [])
  }, [currentFolder?.sub_folders, currentFolder?.files])

  return {
    editFileName,
    editFolderName,
    addFileToFolderFile,
    deleteFile,
    deleteFolder,
    currentFolder,
    currentFolderId,
    filesAndFolders,
    rootFolderId,
    rootFolderName,
    setCurrentFolderId,
    isInAddFolderMode,
    setIsInAddFolderMode,
    addEmptyFolder,
    editScope,
  }
}

export type UseFileExplorerReturnType = ReturnType<typeof useFileExplorer>

type FolderUploadProgressModalDesktopProps = {
  blockFileUploadProgress: {
    progress: number
    totalFiles: number
    uploadedFiles: number
    totalBytes: number
    uploadedBytes: number
  }
  destinationFolder?: string | undefined
}

export const FolderUploadProgressModal = ({
  blockFileUploadProgress,
  destinationFolder,
}: FolderUploadProgressModalDesktopProps) => {
  const { progress, totalFiles, uploadedFiles, totalBytes, uploadedBytes } =
    blockFileUploadProgress

  const visible = progress < 1 && uploadedFiles < totalFiles

  return (
    <Dialog isOpen={visible} contentAsChild>
      <Box style={{ padding: `${px(32)}` }}>
        <span>
          {i18n.t("common:uploading_files_folder", {
            count: totalFiles,
            destinationFolder: destinationFolder === "root" ? "/" : destinationFolder,
          })}
        </span>
        <CircularProgress size={20} progress={progress} />
        <span className="text-sm text-gray-500">
          {formatBytes(uploadedBytes)} {i18n.t("common:of")} {formatBytes(totalBytes)}
        </span>
      </Box>
    </Dialog>
  )
}

type ModalTitleDesktopProps = {
  currentFolder: IFileFolderFragment | undefined | null
  rootFolderId: uuid
  setFolderId: (id: uuid) => void
}

const ModalTitleDesktop = ({
  rootFolderId,
  currentFolder,
  setFolderId,
}: ModalTitleDesktopProps) => {
  const onBreadCrumbClick = useCallback((id: uuid) => setFolderId(id), [rootFolderId])
  const parentFolderId = currentFolder?.parent_folder?.id

  const isRootFolder = currentFolder && !currentFolder.parent_folder

  return (
    <>
      <div className="flex items-center space-x-1 text-xs font-medium text-gray-600 sm:h-6 sm:text-sm">
        <button
          type="button"
          className={classNames("hover:underline truncate", {
            "text-gray-500": !isRootFolder,
          })}
          onClick={() => onBreadCrumbClick(rootFolderId)}>
          /
        </button>
        {!isRootFolder && (
          <>
            {parentFolderId && parentFolderId !== rootFolderId && (
              <>
                <Icons.RightNext className="h-4 w-4 text-gray-500 sm:h-6 sm:w-6" />
                <span className="text-gray-500">...</span>
                <Icons.RightNext className="h-4 w-4 text-gray-500 sm:h-6 sm:w-6" />
                <button
                  type="button"
                  className="truncate text-gray-500 hover:underline"
                  onClick={() => onBreadCrumbClick(parentFolderId)}>
                  {currentFolder.parent_folder?.name}
                </button>
              </>
            )}
            <Icons.RightNext className="h-4 w-4 text-gray-500 sm:h-6 sm:w-6" />
            <span className="truncate">{currentFolder?.name}</span>
          </>
        )}
      </div>
    </>
  )
}

type DocumentsFilesListProps = {
  fileExplorer: UseFileExplorerReturnType
}

const DocumentsFilesList = ({ fileExplorer }: DocumentsFilesListProps) => {
  return (
    <FilesList>
      {[
        ...fileExplorer.filesAndFolders.map((item, i) => {
          if (item.__typename === "file_folder_x_upload")
            return (
              <React.Fragment key={item.file.id}>
                {i !== 0 && <Divider />}
                <FilesList.FileRow
                  hasEditRights={fileExplorer.editScope.hasScope}
                  item={item}
                  onDelete={(body) =>
                    fileExplorer.deleteFile(body, fileExplorer.editScope.context())
                  }
                  onRename={async (id, fileName) => {
                    await fileExplorer.editFileName(
                      { id, fileName },
                      fileExplorer.editScope.context()
                    )
                  }}
                />
              </React.Fragment>
            )
          if (item.__typename === "file_folder")
            return (
              <React.Fragment key={item.id}>
                {i !== 0 && <Divider />}
                <FilesList.FolderRow
                  hasEditRights={fileExplorer.editScope.hasScope}
                  item={item}
                  onClick={fileExplorer.setCurrentFolderId}
                  onDelete={(id) =>
                    fileExplorer.deleteFolder({ id: id }, fileExplorer.editScope.context())
                  }
                  onRename={async (id, name) => {
                    await fileExplorer.editFolderName(
                      { id, name },
                      fileExplorer.editScope.context()
                    )
                  }}
                />
              </React.Fragment>
            )
          return null
        }),
        fileExplorer.isInAddFolderMode ? (
          <React.Fragment key="createEmptyFolderInput">
            {fileExplorer.filesAndFolders.length !== 0 && <Divider />}
            <FilesList.CreateFolderRow
              key="createFolderRow"
              onCreate={(name) => {
                if (fileExplorer.currentFolder?.id && name) {
                  fileExplorer.addEmptyFolder(
                    {
                      name: name,
                      parentFolderId: fileExplorer.currentFolder?.id,
                      rootFolderId: fileExplorer.rootFolderId,
                    },
                    fileExplorer.editScope.context()
                  )
                }
                fileExplorer.setIsInAddFolderMode(false)
              }}
            />
          </React.Fragment>
        ) : null,
      ]}
    </FilesList>
  )
}

type FolderUploadProps = {
  rootFolderId: uuid
  currentFolder?: IFileFolderFragment | null
  disabled?: boolean
}

const FolderUpload = (props: FolderUploadProps) => {
  const editScope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)

  const folderUpload = useFolderUpload({
    rootFolderId: props.rootFolderId,
    currentFolder: props.currentFolder,
    permissionScope: editScope,
  })

  return (
    <>
      <FolderUploadProgressModal
        blockFileUploadProgress={folderUpload.blockFileUploadProgress}
        destinationFolder={props.currentFolder?.name}
      />
      <input {...folderUpload.inputProps} />
      <Button
        size="small"
        type="secondary"
        disabled={props.disabled}
        icon={FolderSimplePlus}
        {...folderUpload.buttonProps}>
        {i18n.t("common:upload_token", {
          token: i18n.t("assets:documents.fields.folder", { count: 1 }),
        })}
      </Button>
    </>
  )
}

type FileExplorerDesktopActionsProps = {
  fileExplorer: UseFileExplorerReturnType
  isPreview?: boolean
}

export const FileExplorerDesktopActions: FC<FileExplorerDesktopActionsProps> = ({
  fileExplorer,
  isPreview = false,
}) => {
  const { inputProps, triggerUpload } = useUploadAction()
  return (
    <div className="flex space-x-3">
      {!isPreview && (
        <div style={{ flex: 1 }}>
          <Button
            type="secondary"
            icon={Plus}
            size="small"
            onClick={(e) => {
              e.stopPropagation()
              fileExplorer.setIsInAddFolderMode(true)
            }}>
            {i18n.t("common:add_token", {
              token: i18n.t("assets:documents.fields.folder", { count: 1 }),
            })}
          </Button>
        </div>
      )}

      <div className="hidden @mobile/page:block">
        <FolderUpload
          rootFolderId={fileExplorer.rootFolderId}
          currentFolder={fileExplorer.currentFolder}
        />
      </div>

      <div>
        <input {...inputProps} multiple />
        <Button
          type="secondary"
          icon={Icons.AddDocument}
          size="small"
          onClick={triggerUpload}>
          {i18n.t("common:upload_token", {
            token: i18n.t("assets:documents.fields.document", { count: 1 }),
          })}
        </Button>
      </div>
    </div>
  )
}

type FileExplorerFileUploadProviderProps = PropsWithChildren<{
  fileExplorer: UseFileExplorerReturnType
}>

export const FileExplorerFileUploadProvider = ({
  fileExplorer,
  children,
}: FileExplorerFileUploadProviderProps) => {
  return (
    <FileUploadProvider
      onUpload={async (doc) => {
        if (!fileExplorer.currentFolderId) return
        const res = await fileExplorer.addFileToFolderFile(
          {
            data: {
              file_folder_id: fileExplorer.currentFolderId,
              root_folder_id: fileExplorer.rootFolderId,
              upload_id: doc.id,
            },
          },
          fileExplorer.editScope.context()
        )
        if (res.error) toast.error(i18n.t("assets:documents.messages.error_during_upload"))
      }}>
      {children}
    </FileUploadProvider>
  )
}

type FileExplorerProps = {
  asset: IAssetDetailFragment
}

export const FileExplorerContent = (props: FileExplorerProps) => {
  const fileExplorer = useFileExplorer({
    rootFolderId: props.asset.file_folder_id!,
    rootFolderName: props.asset.name,
  })

  return (
    <FileExplorerFileUploadProvider fileExplorer={fileExplorer}>
      <ModalTitleDesktop
        rootFolderId={fileExplorer.rootFolderId}
        currentFolder={fileExplorer.currentFolder}
        setFolderId={fileExplorer.setCurrentFolderId}
      />
      <ScrollArea vertical viewportAsChild>
        <div className="flex min-h-0 flex-1 flex-col">
          <DocumentsFilesList fileExplorer={fileExplorer} />
        </div>
      </ScrollArea>

      {fileExplorer.editScope.hasScope && (
        <FileExplorerDesktopActions fileExplorer={fileExplorer} />
      )}
    </FileExplorerFileUploadProvider>
  )
}
