import { ActionMenu, Button } from "@components/shared"
import { uuid } from "@elara/db"
import {
  useDeleteMeterMutation,
  useDeleteMeterReadingMutation,
} from "@graphql/documents/asset-meter.generated"
import {
  IMeterDataViewFragment,
  IMeterFragment,
  IMeterReadingFragment,
} from "@graphql/documents/fragments.generated"
import { IPermissionScopeEnum, useDisclosure, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { DotsThreeVertical, PencilSimpleLine, TrashSimple } from "@phosphor-icons/react"
import { useState } from "react"

import AssetAddEditMeterForm from "./create-and-edit-meter-form"
import AssetAddEditMeterReadingsForm from "./create-and-edit-meter-readings-form"

type MeterActionMenuProps = {
  onEdit: () => void
  onDelete: () => void
}

export const MeterActionMenu = ({ onEdit, onDelete }: MeterActionMenuProps) => {
  const editMetersScope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)
  const deleteMetersScope = usePermissionScope(IPermissionScopeEnum.AppAssetArchiveDelete)

  if (!editMetersScope.hasScope && !deleteMetersScope.hasScope) return null

  return (
    <ActionMenu
      items={[
        editMetersScope.hasScope
          ? {
              key: "edit",
              icon: <PencilSimpleLine />,
              label: i18n.t("common:edit"),
              action: onEdit,
            }
          : null,
        deleteMetersScope.hasScope
          ? {
              key: "delete",
              icon: <TrashSimple />,
              label: i18n.t("common:delete"),
              action: onDelete,
            }
          : null,
      ]}>
      <Button color="gray" type="secondary" icon={DotsThreeVertical} />
    </ActionMenu>
  )
}

export const useMeterActions = (assetId?: uuid) => {
  const [editedMeter, setEditedMeter] = useState<
    IMeterDataViewFragment | IMeterFragment | undefined
  >(undefined)
  const [readingMeterId, setReadingMeterId] = useState<uuid | undefined>(undefined)
  const [editedReading, setEditedReading] = useState<IMeterReadingFragment | undefined>(
    undefined
  )

  const editScope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)
  const [, deleteMeter] = useDeleteMeterMutation()
  const [, deleteReading] = useDeleteMeterReadingMutation()

  const meterModal = useDisclosure({ onClose: () => setEditedMeter(undefined) })
  const readingModal = useDisclosure({ onClose: () => setEditedReading(undefined) })

  const onDeleteMeter = (id: uuid) => deleteMeter({ id: id }, editScope.context())

  const onDeleteMeterReading = (id: uuid) => deleteReading({ id: id }, editScope.context())

  const onEditMeter = (meter: IMeterDataViewFragment | IMeterFragment) => {
    setEditedMeter(meter)
    meterModal.onOpen()
  }

  const onAddReading = (id: uuid) => {
    setReadingMeterId(id)
    readingModal.onOpen()
  }

  const onEditReading = (reading: IMeterReadingFragment) => {
    setEditedReading(reading)
    readingModal.onOpen()
  }

  const addMeterDialog = (
    <AssetAddEditMeterForm
      isOpen={meterModal.isOpen}
      onOpenChange={meterModal.changeOpen}
      assetId={assetId}
      meter={editedMeter}
    />
  )

  const addReadingDialog = readingMeterId ? (
    <AssetAddEditMeterReadingsForm
      key={readingMeterId}
      isOpen={readingModal.isOpen}
      onOpenChange={readingModal.changeOpen}
      meterId={readingMeterId}
      meterReading={editedReading}
    />
  ) : null

  return {
    meterModal,
    setReadingMeterId,
    onDeleteMeter,
    onEditMeter,
    onAddReading,
    onEditReading,
    onDeleteMeterReading,
    addMeterDialog,
    addReadingDialog,
  }
}
