import { useSessionTracking } from "@hooks/use-session-tracking"
import posthog from "posthog-js"
import React, { createContext, useEffect, useMemo, useState } from "react"

import { UserContext } from "./user-context"

const ELARA_ORG_ID = "fcda66c9-db49-4eca-9a95-3ca159ecfdd9"

const AnalyticsContext = createContext<{ posthog: null | typeof posthog }>({
  posthog: null,
})
const AnalyticsProvider = (props: React.PropsWithChildren<{}>) => {
  const user = React.useContext(UserContext)

  const [isInitialized, setIsInitialized] = useState(false)
  useEffect(() => {
    if (!user?.id || !user?.profile?.email) return

    if (
      import.meta.env.VITE_ELARA_IN_PRODUCTION === "true" &&
      !isInitialized &&
      // Exclude ourselves from posthog recordings
      user.orgId !== ELARA_ORG_ID
    ) {
      posthog.init("phc_EVcmYNvbc9Pi3P2QJh24SFNh0HadYG7I0w6Cr7o8z1W", {
        api_host: "https://eu.posthog.com",
        autocapture: false,
        capture_pageview: false,
        loaded(posthog) {
          posthog.identify(user.id, {
            email: user.profile?.email,
            name: user.profile?.first_name + " " + user.profile?.last_name,
            org_id: user.orgId,
            location_id: user.location.id,
            location: user.location.name,
          })
        },
      })
      setIsInitialized(true)
    }
  }, [user?.id, user?.profile?.email])

  useSessionTracking(!!user?.id)

  const value = useMemo(
    () => ({ posthog: isInitialized ? posthog : null }),
    [isInitialized]
  )

  return (
    <AnalyticsContext.Provider value={value}>{props.children}</AnalyticsContext.Provider>
  )
}

export const useAnalytics = () => React.useContext(AnalyticsContext)

export default AnalyticsProvider
