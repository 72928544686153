import { Tooltip } from "@components/shared/tooltip"
import { Info } from "@phosphor-icons/react"
import { cn } from "@utils"

type CardProps = {
  title?: string
  tooltip?: string
  className?: string
  cardClassName?: string
  children: React.ReactNode
}

const Card = ({ title, tooltip, className, cardClassName, children }: CardProps) => (
  <div
    className={cn(
      "flex flex-col rounded border border-gray-200 bg-white px-6 py-4 shadow-sm",
      cardClassName
    )}>
    {title && (
      <div className="mb-1 flex items-center gap-1.5">
        <p className="text-sm text-gray-500">{title}</p>
        {tooltip && (
          <Tooltip content={tooltip} contentProps={{ side: "bottom" }}>
            <Info size={14} opacity={0.75} />
          </Tooltip>
        )}
      </div>
    )}

    <div className={cn(className)}>{children}</div>
  </div>
)

export default Card
