import React, { FC } from "react"

import {
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuRoot,
  ContextMenuSeparator,
  ContextMenuTrigger,
} from "../shared/context-menu"

export type RightClickMenuItem = {
  key: string
  action?: (e: Event | React.SyntheticEvent<HTMLElement>) => void
  icon?: React.ReactNode
  label: React.ReactNode
  disabled?: boolean
  separator?: React.ReactNode
  type: string
}

type RightClickMenuProps = React.PropsWithChildren<{
  items: (RightClickMenuItem | null)[]
  asChild?: boolean
  className?: string
}>

const useRightClickMenu = () => {
  const [isOpen, setOpen] = React.useState(false)
  const open = React.useCallback(() => setOpen(true), [])
  const close = React.useCallback(() => setOpen(false), [])

  return { isOpen, open, close, setOpen }
}

export const RightClickMenu: FC<RightClickMenuProps> = (props) => {
  const menu = useRightClickMenu()

  if (!props.items.some(Boolean)) return null

  return (
    <ContextMenuRoot>
      <ContextMenuTrigger asChild={props.asChild ?? true} className={props.className}>
        {props.children}
      </ContextMenuTrigger>

      <ContextMenuContent className="bg-white">
        {props.items.map((item) => {
          if (!item?.label) return null

          const { icon, label, key, action, separator, type, disabled } = item

          return (
            <React.Fragment key={key}>
              {type == "label" ? <ContextMenuLabel>{label}</ContextMenuLabel> : null}
              {type == "item" ? (
                <ContextMenuItem
                  key={key}
                  disabled={disabled}
                  onSelect={(e) => {
                    menu.close()
                    action?.(e)
                  }}>
                  {icon && <span className="mr-2 inline-flex text-lg">{icon}</span>}
                  {label}
                </ContextMenuItem>
              ) : null}
              {separator && (
                <ContextMenuSeparator
                  style={{
                    backgroundColor: "lightgrey",
                    height: 1,
                    margin: 5,
                  }}></ContextMenuSeparator>
              )}
            </React.Fragment>
          )
        })}
      </ContextMenuContent>
    </ContextMenuRoot>
  )
}

export default RightClickMenu
