import { Center, Flex } from "@components/layout"
import { NotificationsDialogContent } from "@components/notifications/notifications-dialog"
import { useAskForPushPermissions } from "@components/notifications/use-onesignal"
import PageViewLayout from "@components/page/page-view-layout"
import Button from "@components/shared/button"
import ScrollArea from "@components/shared/scroll-area"
import WorkOrderDetail from "@components/work-order/work-order-detail"
import { useBreakpoint } from "@contexts/breakpoints"
import { useDetectPrint } from "@contexts/detect-print"
import { usePage } from "@contexts/page-context"
import { IPermissionScopeEnum, uuid, WorkOrderNotificationPayload } from "@elara/db"
import { useOrderBy } from "@elara/select"
import {
  useAllNotificationsQuery,
  useDeleteNotificationMutation,
  useNotificationsSubscription,
} from "@graphql/documents/notification.generated"
import { usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { ArchiveTray, Tray } from "@phosphor-icons/react"
import Icons from "@resources/icons"
import { useNavigateWithBackgroundLocation } from "@utils/location"
import { useMemo, useState } from "react"
import { Trans } from "react-i18next"
import { useNavigate } from "react-router-dom"

type NotificationType = "work-order" | "consumable"

const NotificationsPage = () => {
  usePage({ id: "notifications", title: i18n.t("common:notification", { count: 2 }) })

  const bp = useBreakpoint()
  const navigate = useNavigate()
  const navigateWithBackgroun = useNavigateWithBackgroundLocation()

  useAskForPushPermissions()
  useNotificationsSubscription()

  const [selected, setSelected] = useState<{
    id: uuid
    type: NotificationType
    itemId: uuid
  } | null>(null)

  const [notificationRes] = useAllNotificationsQuery({ requestPolicy: "cache-first" })
  const [notifications] = useOrderBy(notificationRes.data?.notification ?? [], {
    created_at: "desc",
  })

  const scope = usePermissionScope(IPermissionScopeEnum.AppUser)
  const [, deleteNotificationMutation] = useDeleteNotificationMutation()

  const selectNotification = (id: uuid, type: NotificationType, itemId: uuid) => {
    if (bp.mobile) {
      navigate(`/${type}/${itemId}`)
    } else {
      setSelected({ id, type, itemId })
    }
  }

  const deleteNotification = async (notificationId: uuid) => {
    const index = notifications.findIndex((n) => n.id === notificationId)
    let nextIndex = -1
    if (index >= 0) {
      if (index + 1 < notifications.length) {
        nextIndex = index + 1
      } else if (index > 0) {
        nextIndex = index - 1
      }
    }

    await deleteNotificationMutation({ id: notificationId }, scope.context())

    const nextSelectedNotification = notifications[nextIndex]

    if (nextSelectedNotification) {
      const { workOrderId } =
        nextSelectedNotification.payload as WorkOrderNotificationPayload["payload"]

      if (workOrderId) {
        setSelected({
          id: nextSelectedNotification.id,
          type: "work-order",
          itemId: workOrderId,
        })
      }
    } else {
      setSelected(null)
    }
  }

  const unreadNotificationCount = useMemo(
    () => notifications.reduce((c, n) => c + Number(!n.read_at), 0),
    [notifications]
  )
  const isPrinting = useDetectPrint()

  if (isPrinting && selected?.type === "work-order" && selected?.itemId) {
    return <WorkOrderDetail key={selected.itemId} workOrderId={selected.itemId} />
  }

  return (
    <PageViewLayout title={i18n.t("common:notification", { count: 2 })}>
      <div className="flex min-h-0 min-w-0 flex-1">
        <div className="flex grow basis-72 flex-col border-r">
          <NotificationsDialogContent
            notifications={notifications}
            activeNotification={selected?.id}
            selectNotification={selectNotification}
            onDeleteNotification={deleteNotification}
            onDeleteAllNotification={() => {
              setSelected(null)
            }}
          />
        </div>
        {bp.sm && (
          <div className="flex min-w-0 flex-1 grow-[4] basis-96 flex-col">
            <Flex row className="gap-2 border-b border-gray-200 px-3 py-2">
              {selected ? (
                <>
                  <Button
                    size="small"
                    type="secondary"
                    icon={() => <ArchiveTray className="mr-0.5" />}
                    onClick={() => {
                      if (selected?.id) {
                        deleteNotification(selected.id)
                      }
                    }}>
                    {i18n.t("notifications:actions.delete_notification")}
                  </Button>
                  {selected.type === "work-order" && (
                    <Button
                      size="small"
                      type="secondary"
                      icon={Icons.RightNext}
                      onClick={() => navigate("/work-order/" + selected.itemId)}>
                      {i18n.t("notifications:actions.goto_task")}
                    </Button>
                  )}
                </>
              ) : (
                <div className="h-8" />
              )}
            </Flex>

            {selected?.itemId ? (
              <ScrollArea vertical className="flex flex-1 flex-col" viewportAsChild>
                {selected.type === "work-order" && (
                  <WorkOrderDetail key={selected.itemId} workOrderId={selected.itemId} />
                )}
                {selected.type === "consumable" && (
                  <div className="my-6 flex justify-center">
                    <Button
                      type="primary"
                      onClick={() =>
                        navigateWithBackgroun(`/consumable/${selected.itemId}`)
                      }>
                      {i18n.t("notifications:actions.goto_consumable")}
                    </Button>
                  </div>
                )}
              </ScrollArea>
            ) : (
              <Center flex="1">
                <div className="flex items-center text-gray-600">
                  {unreadNotificationCount > 0 ? (
                    <>
                      <ArchiveTray size={24} className="mr-3 inline-block" />
                      <Trans
                        i18n={i18n}
                        values={{ count: unreadNotificationCount }}
                        i18nKey="notifications:messages.x_unread_notifications">
                        Sie haben noch
                        <span className="mx-1 font-medium text-gray-700">40</span>
                        ungelesene Benachrichtigungen.
                      </Trans>
                    </>
                  ) : (
                    <>
                      <Tray size={24} className="mr-3 inline-block" />
                      <span>{i18n.t("notifications:messages.all_notifications_read")}</span>
                    </>
                  )}
                </div>
              </Center>
            )}
          </div>
        )}
      </div>
    </PageViewLayout>
  )
}

export default NotificationsPage
