import { IViewDataTypeEnum } from "@elara/db"
import { Data } from "@elara/select"
import { useCallbackRef, useDeepMemo, useScrollRestoration } from "@hooks"
import { UseTreeListReturn } from "@hooks/use-tree-list"
import useVirtuosoScrollRestoration from "@hooks/use-virtuoso-scroll-restoration"
import { genericReactMemo } from "@utils"
import { FilteredTreeLike, Tree } from "@utils/tree"
import classNames from "classnames"
import { ReactNode, useRef } from "react"
import { Virtuoso, VirtuosoHandle } from "react-virtuoso"

import { OpenCloseToggleIcon } from "../open-close-toggle"
import { UseDataViewReturnType } from "./data-view.hooks"
import { useDataViewFlatData } from "./data-view-hooks/data-view-use-flat-data"
import { useKeyboardNavigation } from "./data-view-hooks/data-view-use-keyboard-navigation"

type DataViewListRowProps<D extends Data, Options extends {}> = {
  row: FilteredTreeLike<Tree<D>>
  options: Options
  onRowClick: (row: FilteredTreeLike<Tree<D>>) => void
  listItem: (row: D, options: Options) => ReactNode
  getNodeDisclosure: UseTreeListReturn<D>["getNodeDisclosure"]
  dataId: (d: D) => string
  selectedDataId?: string | null
  level?: number
}

const DataViewListRow = genericReactMemo(
  <D extends Data, Options extends {}>(props: DataViewListRowProps<D, Options>) => {
    const { level = 0 } = props
    const disclosure = props.getNodeDisclosure(props.row)
    return (
      <>
        <div
          className={classNames(
            "flex text-sm border-b cursor-pointer border-b-gray-200 hover:bg-gray-50",
            {
              "bg-gray-50":
                props.selectedDataId && props.selectedDataId === props.dataId(props.row),
            }
          )}
          onClick={() => props.onRowClick(props.row)}
          style={{ paddingLeft: level * 22 + 12, paddingRight: 12 }}>
          {!!props.row.children?.length ? (
            <div
              className="my-0.5 mr-0.5 flex w-8 shrink-0 items-center justify-center self-stretch rounded hover:bg-gray-100"
              onClick={(e) => {
                e.stopPropagation()
                disclosure.toggle()
              }}>
              <OpenCloseToggleIcon isOpen={disclosure.isOpen} />
            </div>
          ) : (
            <div className="sm:w-3" />
          )}
          <div className="min-w-0 flex-1">{props.listItem?.(props.row, props.options)}</div>
        </div>
        {/* {disclosure.isOpen &&
          props.row.children?.map((childRow, idx) => (
            <DataViewListRow
              {...props}
              key={(childRow as { id?: string }).id ?? idx}
              row={childRow}
              level={level + 1}
            />
          ))} */}
      </>
    )
  }
)

type DataViewListProps<D extends Data, Id extends string, Options extends {}> = {
  id: string
  dataType: IViewDataTypeEnum
  dataView: UseDataViewReturnType<D, Id>
  listItem: (data: D, options?: Options) => ReactNode
  onRowClick: (row: FilteredTreeLike<Tree<D>>) => void
  onKeyboardSelect?: (row: FilteredTreeLike<Tree<D>>) => void
  selectedDataId?: string | null
  dataId: (d: D) => string
  options?: Options
  containerRef?: React.RefObject<HTMLDivElement>
}

const DataListView = genericReactMemo(
  <D extends Data, Id extends string, Options extends {}>(
    props: DataViewListProps<D, Id, Options>
  ) => {
    const options = useDeepMemo(() => props.options ?? ({} as Options), [props.options])
    // )
    const listRef = useRef<HTMLDivElement>(null)
    useScrollRestoration(listRef, props.id + "dataListView")
    const { startIndex, onRangeUpdate } = useVirtuosoScrollRestoration(props.id + "list")
    const flatData = useDataViewFlatData(
      props.dataView.data,
      props.dataView.isGroupCollapsed,
      props.dataView.isNodeOpen,
      false
    )

    const virtuosoRef = useRef<VirtuosoHandle>(null)

    const keyboardNavigation = useKeyboardNavigation({
      containerRef: props.containerRef,
      flatData,
      navigate: props.dataView.navigate,
      virtuosoRef,
      onSelect: props.onKeyboardSelect,
    })

    const onRowClick = useCallbackRef((row: FilteredTreeLike<Tree<D>>) => {
      keyboardNavigation.onClick(row)
      props.onRowClick(row)
    })

    const selectedRowDataId = keyboardNavigation.selectedRow
      ? props.dataId(keyboardNavigation.selectedRow)
      : null

    return (
      <Virtuoso
        style={{ height: "100%" }}
        className="border-t border-gray-200 will-change-transform"
        initialTopMostItemIndex={startIndex ?? 0}
        data={flatData}
        ref={virtuosoRef}
        rangeChanged={onRangeUpdate}
        itemContent={(index) => {
          const dataItem = flatData[index]
          if (!dataItem) return null
          if (dataItem.type === "header") {
            return null
          } else if (dataItem.type === "item") {
            return (
              <DataViewListRow
                row={dataItem.item}
                listItem={props.listItem}
                options={options}
                level={dataItem.level}
                onRowClick={onRowClick}
                getNodeDisclosure={props.dataView.getNodeDisclosure}
                selectedDataId={selectedRowDataId}
                dataId={props.dataId}
              />
            )
          } else {
            return (
              <div
                onClick={() => props.dataView.toggleGroup(dataItem.groupId)}
                className="top-[45px] z-10 flex h-11 cursor-pointer select-none items-center bg-gray-100 px-2 text-sm font-medium text-gray-900">
                <div className="mr-1 flex items-center self-stretch">
                  <OpenCloseToggleIcon
                    isOpen={!props.dataView.isGroupCollapsed(dataItem.groupId)}
                    className="shrink-0 text-lg"
                  />
                </div>
                {dataItem.label}
                <span className="ml-2 rounded border border-gray-300 px-1 text-center text-xs text-gray-500">
                  {dataItem.length}
                </span>
              </div>
            )
          }
        }}
      />
    )
  }
)

export default DataListView
