import i18n from "@i18n"
import { cn } from "@utils"

type Props = {
  label: string
  required?: boolean
  className?: string
  children: React.ReactNode
  truncate?: boolean
}

const FormLabel = ({
  label,
  required = false,
  className,
  children,
  truncate = true,
}: Props) => (
  <div className={cn("mb-3 text-sm font-medium text-gray-600", className)}>
    <div className="mb-0.5 flex items-center justify-between">
      <span className={cn({ truncate })}>{label}</span>

      {!required && (
        <span className="text-xs font-normal text-gray-500">
          {i18n.t("common:optional")}
        </span>
      )}
    </div>

    {children}
  </div>
)

export default FormLabel
