import { useBreakpoint } from "@contexts/breakpoints"
import { DotsThreeVertical } from "@phosphor-icons/react"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import classNames from "classnames"
import cx from "classnames"
import React, { FC } from "react"

import Button from "./button"
import { Tooltip } from "./tooltip"

export type ActionMenuItem = {
  key: string
  tooltip?: string
  disabled?: boolean
  icon?: React.ReactNode
  label: React.ReactNode
  action?: (e: Event | React.SyntheticEvent<HTMLElement>) => void
}

type ActionMenuProps = React.PropsWithChildren<{
  items: (ActionMenuItem | null)[]
  asChild?: boolean
  className?: string
  dropdownContentProps?: DropdownMenu.DropdownMenuContentProps
}>

const useActionMenu = () => {
  const [isOpen, setOpen] = React.useState(false)
  const open = React.useCallback(() => setOpen(true), [])
  const close = React.useCallback(() => setOpen(false), [])

  return { isOpen, open, close, setOpen }
}

const ActionMenuDesktop: FC<ActionMenuProps> = (props) => {
  const menu = useActionMenu()

  return (
    <div className="relative inline-block text-left">
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild={props.asChild ?? true} className={props.className}>
          {props.children}
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            align="end"
            sideOffset={4}
            {...props.dropdownContentProps}
            className="z-50 rounded border border-gray-100 bg-white py-1 text-sm shadow-lg ring-1 ring-black/5 print:!hidden">
            {props.items.map((item) => {
              if (!item?.label) return null

              const { icon, label, key, disabled, tooltip, action } = item

              const menuItem = (
                <DropdownMenu.Item
                  className={cx(
                    "flex cursor-default select-none items-center px-3 py-2 outline-none",
                    "text-gray-700 focus:bg-gray-100 focus:text-gray-900",
                    { "opacity-60": disabled }
                  )}
                  key={key}
                  disabled={disabled}
                  onSelect={(e) => {
                    menu.close()
                    action?.(e)
                  }}>
                  {icon && <span className="mr-2 inline-flex text-lg">{icon}</span>}
                  {label}
                </DropdownMenu.Item>
              )

              if (!tooltip) return menuItem

              return (
                <Tooltip key={key} contentProps={{ side: "right" }} content={tooltip}>
                  <DropdownMenu.Item
                    className={cx(
                      "flex cursor-default select-none items-center px-3 py-2 outline-none",
                      "text-gray-700 focus:bg-gray-100 focus:text-gray-900",
                      { "opacity-60": disabled }
                    )}
                    disabled={disabled}
                    onSelect={(e) => {
                      menu.close()
                      action?.(e)
                    }}>
                    {icon && <span className="mr-2 inline-flex text-lg">{icon}</span>}
                    {label}
                  </DropdownMenu.Item>
                </Tooltip>
              )
            })}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  )
}

const ActionMenuMobile: FC<ActionMenuProps> = (props) => {
  const menu = useActionMenu()

  return (
    <DialogPrimitive.Root open={menu.isOpen} onOpenChange={menu.setOpen}>
      <DialogPrimitive.Trigger asChild={props.asChild ?? true} className={props.className}>
        {props.children}
      </DialogPrimitive.Trigger>
      <DialogPrimitive.Portal className="isolate">
        <DialogPrimitive.Overlay
          forceMount
          onClick={menu.close}
          className="fixed inset-0 z-20 bg-black/20"
        />
        <DialogPrimitive.Content
          forceMount
          className="fixed bottom-0 z-50 grid w-screen max-w-md grid-cols-1 gap-2 rounded bg-white p-4 text-sm print:!hidden md:w-full">
          {props.items.map((item) => {
            if (!item?.label) return null

            const { icon, label, key, disabled, tooltip, action } = item

            return (
              <DialogPrimitive.Title
                className={classNames(
                  "flex cursor-default select-none items-center rounded p-2 text-black outline-none",
                  {
                    "opacity-50": disabled,
                    "hover:bg-gray-200 focus:bg-gray-200": !disabled,
                  }
                )}
                key={key}
                onClick={(e) => {
                  if (disabled) return

                  menu.close()
                  action?.(e)
                }}>
                {icon && <span className="mr-2 inline-flex">{icon}</span>}

                <div>
                  <p className="leading-none">{label}</p>
                  {tooltip && <span className="text-xs leading-none">{tooltip}</span>}
                </div>
              </DialogPrimitive.Title>
            )
          })}
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  )
}

export const ActionMenu: FC<ActionMenuProps> = (props) => {
  const bp = useBreakpoint()
  if (!props.items.some(Boolean)) return null

  const children = props.children ?? (
    <Button icon={DotsThreeVertical} size="small" type="secondary" className="shrink-0" />
  )
  if (bp.renderDesktop) {
    return <ActionMenuDesktop {...props}>{children}</ActionMenuDesktop>
  }
  return <ActionMenuMobile {...props}>{children}</ActionMenuMobile>
}

export default ActionMenu
