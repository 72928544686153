import { Button, Icons } from "@components/shared"
import { FormField } from "@components/shared/form/form-field"
import { TextInput } from "@components/shared/text-input"
import { uuid } from "@elara/db"
import {
  IInsertPlaceMutation,
  IInsertPlaceMutationVariables,
  InsertPlaceDocument,
  IUpdatePlaceMutation,
  IUpdatePlaceMutationVariables,
  UpdatePlaceDocument,
} from "@graphql/documents/place.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { X } from "@phosphor-icons/react"
import { px } from "@styles"
import { Formik } from "formik"
import { useState } from "react"
import toast from "react-hot-toast"
import { useClient } from "urql"

type PlaceEditCreateFormProps = {
  label?: string
  id?: uuid
  onCancel: () => void
  afterSubmitSuccess: () => void
}
const PlaceEditCreateForm = (props: PlaceEditCreateFormProps) => {
  const createPlaceScope = usePermissionScope(IPermissionScopeEnum.AppAssetCreate)
  const editPlaceScope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)

  const client = useClient()
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Formik
      initialValues={{ label: props.label ?? "" }}
      onSubmit={async (values) => {
        let isSuccessful = false

        try {
          setIsLoading(true)
          // Edit
          if (props.id) {
            const res = await client
              .mutation<IUpdatePlaceMutation, IUpdatePlaceMutationVariables>(
                UpdatePlaceDocument,
                { name: values.label, id: props.id },
                editPlaceScope.context()
              )
              .toPromise()

            if (!res.data?.update_place_by_pk) {
              toast.error(
                i18n.t("common:messages.token_update_failure", {
                  token: i18n.t("assets:fields.place", { count: 1 }),
                })
              )
            } else {
              isSuccessful = true
            }
            // Create
          } else {
            const res = await client
              .mutation<IInsertPlaceMutation, IInsertPlaceMutationVariables>(
                InsertPlaceDocument,
                { name: values.label },
                createPlaceScope.context()
              )

              .toPromise()

            if (!res.data?.insert_place_one) {
              toast.error(
                i18n.t("common:messages.token_create_failure", {
                  token: i18n.t("assets:fields.place", { count: 1 }),
                })
              )
            } else {
              isSuccessful = true
            }
          }
        } finally {
          setIsLoading(false)
          if (isSuccessful) {
            props.afterSubmitSuccess()
          }
        }
      }}>
      {(formik) => (
        <div className="flex flex-1 items-center justify-between">
          <FormField
            name="label"
            label={i18n.t("assets:fields.place", { count: 1 })}
            noStyle>
            <TextInput
              value={props.label}
              className="mx-2 inline-flex flex-1"
              placeholder={i18n.t("common:name")}
              autoComplete="nofill"
              autoFocus={true}
            />
          </FormField>

          <Button
            type="tertiary"
            icon={X}
            onClick={props.onCancel}
            size="small"
            style={{ marginRight: px(4), marginLeft: px(4) }}
          />

          <Button
            type="tertiary"
            icon={Icons.Check}
            isLoading={isLoading}
            disabled={!formik.values.label.length}
            size="small"
            htmlType="submit"
            onClick={formik.submitForm}
            style={{ marginRight: px(4) }}
          />
        </div>
      )}
    </Formik>
  )
}

export default PlaceEditCreateForm
