import { CalendarDate, parseDate } from "@internationalized/date"
import Icons from "@resources/icons"
import { cn } from "@utils"
import React from "react"

type DateFieldProps = {
  clearable?: boolean
  value?: CalendarDate | null
  onChange?: (datetime: CalendarDate | null) => void
  onClear?: () => void
  hasError?: boolean
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "value" | "onChange">

export const DateField = React.forwardRef<HTMLInputElement, DateFieldProps>(
  (
    { clearable, className, value, onChange, onClear, hasError, ...props },
    forwardedRef
  ) => {
    return (
      <div className="relative flex">
        <input
          {...props}
          ref={forwardedRef}
          type="date"
          value={value ? value.toString() : ""}
          className={cn(
            "form-input rounded border-gray-200 h-8 pt-0 pb-0 text-sm flex-1 !px-2",
            {
              "text-gray-800": value,
              "text-gray-500": !value,
              "border-red-700 text-red-700 focus:ring-1 focus:ring-red-700 focus:border-red-700":
                hasError,
            },
            className
          )}
          onChange={(e) => {
            if (e.target.value) {
              onChange?.(parseDate(e.target.value))
            } else if (clearable) {
              onChange?.(null)
            }
          }}
        />
        {value && clearable && (
          <Icons.Close
            width={16}
            height={16}
            className={cn(
              "date-picker-clear-button absolute top-[8px] right-[42px] text-white rounded-full",
              {
                "bg-blue-deep": !hasError,
                "bg-red-700": hasError,
              }
            )}
            onClick={() => {
              onChange?.(null)
              onClear?.()
            }}
          />
        )}
      </div>
    )
  }
)
