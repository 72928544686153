import type { ObjectKey } from "src/types"

export function prefixObjectKeys(object: Record<ObjectKey, unknown>, prefix: string) {
  const prefixedKeysAndValues = Object.entries(object).map(([key, val]) => [
    `${prefix}_${key}`,
    val,
  ])
  return Object.fromEntries(prefixedKeysAndValues)
}

export function flattenObject(object: Record<ObjectKey, unknown>) {
  let result: Record<string | number | symbol, unknown> = {}

  for (const key of Object.keys(object)) {
    if (
      typeof object[key] === "object" &&
      object[key] !== null &&
      !Array.isArray(object[key])
    ) {
      const temp = flattenObject(object[key] as Record<ObjectKey, unknown>)
      for (const j of Object.keys(temp)) {
        result[key + "_" + j] = temp[j]
      }
    } else {
      result[key] = object[key]
    }
  }
  return result
}

export function flattenAndPrefixObject(
  object: Record<ObjectKey, unknown>,
  prefix?: string
) {
  const flattened = flattenObject(object)
  if (!prefix) {
    return flattened
  }
  return prefixObjectKeys(flattened, prefix)
}
