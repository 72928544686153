"use client"

import { Circle } from "@phosphor-icons/react"
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group"
import { cn } from "@utils"
import * as React from "react"

export type RadioGroupProps = React.ComponentPropsWithoutRef<
  typeof RadioGroupPrimitive.Root
>

export const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  RadioGroupProps
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn("grid gap-2", className)}
      {...props}
      ref={ref}
    />
  )
})

export type RadioGroupItemProps = React.ComponentPropsWithoutRef<
  typeof RadioGroupPrimitive.Item
>

export const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  RadioGroupItemProps
>(({ className, children, ...props }, ref) => {
  const item = (
    <RadioGroupPrimitive.Item
      ref={ref}
      id={children ? props.value : undefined}
      className={cn(
        "text:fill-gray-50 h-4 w-4 rounded-full border border-gray-300 text-gray-900 hover:border-gray-400 focus:ring-2 focus-visible:ring-gray-400 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 ",
        className
      )}
      {...props}>
      <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
        <Circle className="h-2.5 w-2.5 fill-current" weight="fill" />
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
  )

  if (!children) {
    return item
  }
  return (
    <div className="flex items-center space-x-2">
      {item}
      <label htmlFor={props.value}>{children}</label>
    </div>
  )
})
