import { AssetWithParents } from "@components/asset/asset-with-parents"
import { Button } from "@components/shared"
import { CurrencyValue } from "@components/shared/currency-value"
import Table from "@components/shared/table"
import WorkOrderTag from "@components/work-order/work-order-tag"
import { useBreakpoint } from "@contexts/breakpoints"
import { openModal } from "@contexts/modal-context"
import {
  IAssetTagFragment,
  IConsumableFragment,
  IConsumableLogFragment,
} from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { PencilSimpleLine } from "@phosphor-icons/react"
import { ColumnDef, createColumnHelper } from "@tanstack/react-table"
import { formatDate } from "@utils/date"
import { useMemo } from "react"

const consumableLogHelper = createColumnHelper<
  IConsumableLogFragment & {
    quantity: { total: number; place: number | null }
  }
>()

type AdjustmentsTabProps = {
  consumable: IConsumableFragment
  adjustments: IConsumableLogFragment[]
}

type Row = IConsumableLogFragment & { quantity: { total: number; place: number | null } }

const AdjustmentsTab = ({ adjustments, consumable }: AdjustmentsTabProps) => {
  const bp = useBreakpoint()

  const { data, showTotal } = useMemo(() => {
    const sorted = adjustments.sort((a, b) =>
      b.adjustment_at.localeCompare(a.adjustment_at)
    ) as (IConsumableLogFragment & { quantity: { total: number; place: number | null } })[]

    const acc: Record<string, number> = { total: 0, no_value: 0 }
    for (let i = sorted.length - 1; i >= 0; i--) {
      const log = sorted[i]
      acc.total += log.adjustment
      let place_quantity: number | null = null
      const placeId = log.place?.id ?? "no_value"
      place_quantity = (acc[placeId] || 0) + log.adjustment
      acc[placeId] = place_quantity
      sorted[i] = {
        ...log,
        quantity: {
          total: acc.total,
          place: place_quantity,
        },
      }
    }

    const showTotal =
      consumable.storage_locations.length > 1 ||
      Object.keys(acc).length > 3 ||
      (Object.keys(acc).length === 3 && acc.no_value > 0)

    return { data: sorted, showTotal }
  }, [adjustments])

  const columns = useMemo(() => {
    const columns: ColumnDef<Row, any>[] = [
      consumableLogHelper.accessor("adjustment_at", {
        header: () => i18n.t("common:date"),
        cell: (info) => {
          const date = new Date(info.getValue())
          return (
            <span>
              {formatDate(date, "P")}
              <span className="ml-1 text-gray-500">{formatDate(date, "p")}</span>
            </span>
          )
        },
      }),
      consumableLogHelper.accessor("place", {
        header: () => i18n.t("common:location", { count: 1 }),
        cell: (info) => <div className="text-gray-500">{info.getValue()?.name ?? ""}</div>,
      }),
      consumableLogHelper.accessor("adjustment", {
        header: () => i18n.t("consumables:fields.adjustment"),
        cell: (info) => (
          <div className="pr-4 text-right">
            <span className="inline-block w-2.5">{info.getValue() < 0 ? "-" : "+"}</span>
            <span>{Math.abs(info.getValue())}</span>
          </div>
        ),
      }),
      consumableLogHelper.accessor("quantity", {
        header: () =>
          showTotal ? (
            <span>
              {i18n.t("common:new")}{" "}
              <span className="text-gray-500">({i18n.t("common:total")})</span>
            </span>
          ) : (
            i18n.t("common:new")
          ),
        cell: (info) => (
          <div className="pr-4 text-right">
            <span>{Number(info.getValue().place).toFixed(2)}</span>
            {showTotal && (
              <span className="ml-1 text-gray-500">
                ({Number(info.getValue().total).toFixed(2)})
              </span>
            )}
          </div>
        ),
      }),
      consumableLogHelper.display({
        id: "cost",
        header: () => <span>{i18n.t("common:cost")}</span>,
        cell: (info) => {
          const data = info.row.original
          return (
            <div className="pr-4 text-right">
              <CurrencyValue
                value={data.cost_per_unit * Math.max(-(data.adjustment ?? 0), 0)}
              />
            </div>
          )
        },
      }),
    ]
    if (bp.xl) {
      columns.push(
        consumableLogHelper.accessor(
          (row) => ({ task: row.task, description: row.description }),
          {
            header: () => i18n.t("common:description"),
            id: "description",
            cell: (info) => {
              const { task, description } = info.getValue()

              return (
                <>
                  {task && <WorkOrderTag workOrder={task} hideStatus singleLine />}
                  {description && (
                    <span className="line-clamp-2 text-gray-700"> {description}</span>
                  )}
                </>
              )
            },
          }
        ),
        consumableLogHelper.accessor("asset", {
          header: () => i18n.t("common:asset", { count: 1 }),
          cell: (info) =>
            info.getValue() ? (
              <div className="inline-block">
                <AssetWithParents
                  withLink
                  showAvatar
                  asset={info.getValue() as IAssetTagFragment}
                />
              </div>
            ) : (
              <span className="px-2 text-gray-500">
                {i18n.t("consumables:messages.no_object_associated")}
              </span>
            ),
        })
      )
    }

    columns.push(
      consumableLogHelper.accessor((row) => row, {
        id: "actions",
        header: () => null,
        cell: (info) => (
          <div className="flex items-center justify-end gap-2">
            <Button
              size="small"
              color="gray"
              type="tertiary"
              icon={PencilSimpleLine}
              onClick={() => {
                const log = info.getValue() as Row
                openModal("material_restock", {
                  initialValues: log,
                  baseQuantity: log.quantity.total - log.adjustment,
                  consumable_id: consumable.id,
                })
              }}>
              <span className="hidden lg:inline-block">{i18n.t("common:edit")}</span>
            </Button>
          </div>
        ),
      })
    )

    return columns
  }, [showTotal, bp.xl])

  return (
    <Table
      options={{ data: data, columns }}
      key={bp.xl ? "1" : "0"}
      className="flex min-h-0 min-w-0 flex-1 flex-col"
    />
  )
}

export default AdjustmentsTab
