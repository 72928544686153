import { OpenCloseToggleIcon } from "@components/shared/open-close-toggle"
import { Data } from "@elara/select"
import { cn } from "@utils"

import { useDataViewGroupByContext } from "./data-view-group-by"
import { useDataViewTreeContext } from "./data-view-tree-toggle"
import { GroupBySummaryItem, RowItem } from "./data-view-types"

function GroupHeader(props: { group: GroupBySummaryItem<Data> }) {
  const groupBy = useDataViewGroupByContext()

  return (
    <div
      onClick={() => groupBy.toggleGroup(props.group.id)}
      className="top-[45px] z-10 flex h-11 cursor-pointer select-none items-center bg-gray-100 px-2 text-sm font-medium text-gray-900">
      <div className="mr-1 flex items-center self-stretch">
        <OpenCloseToggleIcon
          isOpen={!groupBy.isGroupCollapsed(props.group.id)}
          className="shrink-0 text-lg"
        />
      </div>
      {props.group.label}
      <span className="ml-2 rounded border border-gray-300 px-1 text-center text-xs text-gray-500">
        {props.group.size}
      </span>
    </div>
  )
}

export type DataViewListItemProps<D extends Data> = {
  item: RowItem<D>
  onRowClick: (d: D) => void
  render: (d: D) => React.ReactNode
}

export const DataViewListItem = <D extends Data>(props: DataViewListItemProps<D>) => {
  const groupBy = useDataViewGroupByContext()
  const tree = useDataViewTreeContext()

  if (props.item.type === "group") {
    return <GroupHeader group={props.item.group} />
  }
  const row = props.item.row

  if (props.item.group && groupBy.isGroupCollapsed(props.item.group.id)) {
    return null
  }
  if (!row) {
    return (
      <div className="flex h-12 border-b border-gray-100">
        <div className="w-full p-4">
          <div className="h-5 w-full animate-pulse rounded-md bg-gray-100" />
        </div>
      </div>
    )
  }
  const level = props.item.level

  return (
    <div
      className={cn(
        "flex text-sm border-b cursor-pointer border-b-gray-200 hover:bg-gray-50"
      )}
      onClick={() => props.onRowClick(row)}
      style={{ paddingLeft: level * 22 + 12, paddingRight: 12 }}>
      {tree?.numberOfChildren && tree.numberOfChildren(row) > 0 ? (
        <div
          className="my-0.5 mr-0.5 flex w-8 shrink-0 items-center justify-center self-stretch rounded hover:bg-gray-100"
          onClick={(e) => {
            e.stopPropagation()
            tree.toggle(tree.getId(row))
          }}>
          <OpenCloseToggleIcon isOpen={tree.isExpanded(tree.getId(row))} />
        </div>
      ) : (
        <div className="sm:w-3" />
      )}
      <div className="min-w-0 flex-1">{props.render(row)}</div>
    </div>
  )
}
