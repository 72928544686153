import { DataViewTabs as NewDataViewTabs } from "@components/new-data-view/data-view-tabs"
import { Button } from "@components/shared"
import { DataViewTabs } from "@components/shared/data-view/data-view-tabs"
import { useFeature } from "@contexts/feature-flag-context"
import { openModal } from "@contexts/modal-context"
import { IViewDataTypeEnum } from "@elara/db"
import i18n from "@i18n"
import { Plus } from "@phosphor-icons/react"

const NewTaskButton = () => {
  return (
    <Button onClick={() => openModal("select_template")} type="primary" icon={Plus}>
      {i18n.t("tasks:labels.new_task")}
    </Button>
  )
}

const TasksPage = () => {
  const newDataView = useFeature("data_view")
  if (newDataView) {
    return (
      <NewDataViewTabs
        dataType={IViewDataTypeEnum.Workorder}
        title={i18n.t("common:task", { count: 2 })}
        action={<NewTaskButton />}
      />
    )
  }
  return (
    <DataViewTabs
      dataType={IViewDataTypeEnum.Workorder}
      title={i18n.t("common:task", { count: 2 })}
      action={<NewTaskButton />}
    />
  )
}
export default TasksPage
