// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import { UserTagFragmentDoc, LocationInviteFragmentDoc, UserProfileFragmentDoc } from './fragments.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IUserSelectDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IUserSelectDataQuery = { location_member: Array<{ __typename: 'location_member', user: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } }> };

export type IUserTagByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type IUserTagByIdQuery = { user_by_pk: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } | null };

export type ICheckEmailNotificationSettingQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ICheckEmailNotificationSettingQuery = { setting_config_aggregate: { __typename: 'setting_config_aggregate', aggregate: { __typename: 'setting_config_aggregate_fields', count: number } | null } };

export type ICheckPushNotificationSettingQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ICheckPushNotificationSettingQuery = { setting_config_aggregate: { __typename: 'setting_config_aggregate', aggregate: { __typename: 'setting_config_aggregate_fields', count: number } | null } };

export type IInsertSettingMutationVariables = Types.Exact<{
  config: Types.ISettingConfigInsertInput;
}>;


export type IInsertSettingMutation = { insert_setting_config_one: { __typename: 'setting_config', id: uuid } | null };

export type IDeleteSettingMutationVariables = Types.Exact<{
  config: Types.ISettingConfigBoolExp;
}>;


export type IDeleteSettingMutation = { delete_setting_config: { __typename: 'setting_config_mutation_response', affected_rows: number } | null };

export type ICreateUserMutationVariables = Types.Exact<{
  userData: Types.IUserRequiredData;
  permissionRoleId: Types.Scalars['uuid']['input'];
  teamId?: Types.InputMaybe<Types.Scalars['uuid']['input']>;
}>;


export type ICreateUserMutation = { addUserToLocation: { __typename: 'AddUserToLocationOutput', password: string, user: { __typename: 'user', phone: string | null, email: string | null, username: string, language: string, settings: {}, id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } | null } | null };

export type IEditPasswordMutationVariables = Types.Exact<{
  newPassword: Types.Scalars['String']['input'];
}>;


export type IEditPasswordMutation = { changePassword: { __typename: 'ChangePasswordOutput', success: boolean | null } | null };

export type IEditUserProfileMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  set?: Types.InputMaybe<Types.IUserSetInput>;
}>;


export type IEditUserProfileMutation = { update_user_by_pk: { __typename: 'user', phone: string | null, email: string | null, username: string, language: string, settings: {}, id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } | null };

export type ISyncUserToKeycloakMutationVariables = Types.Exact<{
  userId: Types.Scalars['uuid']['input'];
}>;


export type ISyncUserToKeycloakMutation = { syncUserToKeycloak: { __typename: 'SyncUserToKeycloakOutput', userId: uuid } | null };

export type ISoftDeleteUserMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type ISoftDeleteUserMutation = { update_user_by_pk: { __typename: 'user', id: uuid } | null };

export type IRemoveLocationMemberMutationVariables = Types.Exact<{
  userId: Types.Scalars['uuid']['input'];
  locationId: Types.Scalars['uuid']['input'];
}>;


export type IRemoveLocationMemberMutation = { delete_location_member_by_pk: { __typename: 'location_member', user_id: uuid, location_id: uuid } | null };

export type IResetPasswordMutationVariables = Types.Exact<{
  userId: Types.Scalars['uuid']['input'];
}>;


export type IResetPasswordMutation = { resetUserPassword: { __typename: 'ResetUserPasswordResponse', password: string } | null };

export type IUpdateUserEmailMutationVariables = Types.Exact<{
  userId: Types.Scalars['uuid']['input'];
  email: Types.Scalars['String']['input'];
}>;


export type IUpdateUserEmailMutation = { updateUserEmail: { __typename: 'UpdateUserEmailOutput', userId: uuid, user: { __typename: 'user', id: uuid, email: string | null } | null } | null };

export type IUpdateUserLanguageMutationVariables = Types.Exact<{
  userId: Types.Scalars['uuid']['input'];
  language: Types.Scalars['String']['input'];
}>;


export type IUpdateUserLanguageMutation = { update_user_by_pk: { __typename: 'user', phone: string | null, email: string | null, username: string, language: string, settings: {}, id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } | null };

export type IUpdateUserSettingsMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  settings: Types.Scalars['jsonb']['input'];
}>;


export type IUpdateUserSettingsMutation = { update_user_by_pk: { __typename: 'user', id: uuid, settings: {} } | null };


export const UserSelectDataDocument = gql`
    query UserSelectData {
  location_member {
    user {
      ...UserTag
    }
  }
}
    ${UserTagFragmentDoc}`;

export function useUserSelectDataQuery(options?: Omit<Urql.UseQueryArgs<IUserSelectDataQueryVariables>, 'query'>) {
  return Urql.useQuery<IUserSelectDataQuery, IUserSelectDataQueryVariables>({ query: UserSelectDataDocument, ...options });
};
export const UserTagByIdDocument = gql`
    query UserTagById($id: uuid!) {
  user_by_pk(id: $id) {
    ...UserTag
  }
}
    ${UserTagFragmentDoc}`;

export function useUserTagByIdQuery(options: Omit<Urql.UseQueryArgs<IUserTagByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<IUserTagByIdQuery, IUserTagByIdQueryVariables>({ query: UserTagByIdDocument, ...options });
};
export const CheckEmailNotificationSettingDocument = gql`
    query CheckEmailNotificationSetting {
  setting_config_aggregate(
    where: {type: {_eq: disable_email_notification}, level: {_eq: user}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

export function useCheckEmailNotificationSettingQuery(options?: Omit<Urql.UseQueryArgs<ICheckEmailNotificationSettingQueryVariables>, 'query'>) {
  return Urql.useQuery<ICheckEmailNotificationSettingQuery, ICheckEmailNotificationSettingQueryVariables>({ query: CheckEmailNotificationSettingDocument, ...options });
};
export const CheckPushNotificationSettingDocument = gql`
    query CheckPushNotificationSetting {
  setting_config_aggregate(
    where: {type: {_eq: enable_push_notification}, level: {_eq: user}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

export function useCheckPushNotificationSettingQuery(options?: Omit<Urql.UseQueryArgs<ICheckPushNotificationSettingQueryVariables>, 'query'>) {
  return Urql.useQuery<ICheckPushNotificationSettingQuery, ICheckPushNotificationSettingQueryVariables>({ query: CheckPushNotificationSettingDocument, ...options });
};
export const InsertSettingDocument = gql`
    mutation InsertSetting($config: setting_config_insert_input!) {
  insert_setting_config_one(object: $config) {
    id
  }
}
    `;

export function useInsertSettingMutation() {
  return Urql.useMutation<IInsertSettingMutation, IInsertSettingMutationVariables>(InsertSettingDocument);
};
export const DeleteSettingDocument = gql`
    mutation DeleteSetting($config: setting_config_bool_exp!) {
  delete_setting_config(where: $config) {
    affected_rows
  }
}
    `;

export function useDeleteSettingMutation() {
  return Urql.useMutation<IDeleteSettingMutation, IDeleteSettingMutationVariables>(DeleteSettingDocument);
};
export const CreateUserDocument = gql`
    mutation CreateUser($userData: UserRequiredData!, $permissionRoleId: uuid!, $teamId: uuid) {
  addUserToLocation(
    userData: $userData
    permissionRoleId: $permissionRoleId
    teamId: $teamId
  ) {
    user {
      ...UserProfile
    }
    password
  }
}
    ${UserProfileFragmentDoc}`;

export function useCreateUserMutation() {
  return Urql.useMutation<ICreateUserMutation, ICreateUserMutationVariables>(CreateUserDocument);
};
export const EditPasswordDocument = gql`
    mutation EditPassword($newPassword: String!) {
  changePassword(newPassword: $newPassword) {
    success
  }
}
    `;

export function useEditPasswordMutation() {
  return Urql.useMutation<IEditPasswordMutation, IEditPasswordMutationVariables>(EditPasswordDocument);
};
export const EditUserProfileDocument = gql`
    mutation EditUserProfile($id: uuid!, $set: user_set_input) {
  update_user_by_pk(pk_columns: {id: $id}, _set: $set) {
    ...UserProfile
  }
}
    ${UserProfileFragmentDoc}`;

export function useEditUserProfileMutation() {
  return Urql.useMutation<IEditUserProfileMutation, IEditUserProfileMutationVariables>(EditUserProfileDocument);
};
export const SyncUserToKeycloakDocument = gql`
    mutation SyncUserToKeycloak($userId: uuid!) {
  syncUserToKeycloak(userId: $userId) {
    userId
  }
}
    `;

export function useSyncUserToKeycloakMutation() {
  return Urql.useMutation<ISyncUserToKeycloakMutation, ISyncUserToKeycloakMutationVariables>(SyncUserToKeycloakDocument);
};
export const SoftDeleteUserDocument = gql`
    mutation SoftDeleteUser($id: uuid!) {
  update_user_by_pk(pk_columns: {id: $id}, _set: {deleted_at: "now()"}) {
    id
  }
}
    `;

export function useSoftDeleteUserMutation() {
  return Urql.useMutation<ISoftDeleteUserMutation, ISoftDeleteUserMutationVariables>(SoftDeleteUserDocument);
};
export const RemoveLocationMemberDocument = gql`
    mutation RemoveLocationMember($userId: uuid!, $locationId: uuid!) {
  delete_location_member_by_pk(user_id: $userId, location_id: $locationId) {
    user_id
    location_id
  }
}
    `;

export function useRemoveLocationMemberMutation() {
  return Urql.useMutation<IRemoveLocationMemberMutation, IRemoveLocationMemberMutationVariables>(RemoveLocationMemberDocument);
};
export const ResetPasswordDocument = gql`
    mutation ResetPassword($userId: uuid!) {
  resetUserPassword(userId: $userId) {
    password
  }
}
    `;

export function useResetPasswordMutation() {
  return Urql.useMutation<IResetPasswordMutation, IResetPasswordMutationVariables>(ResetPasswordDocument);
};
export const UpdateUserEmailDocument = gql`
    mutation UpdateUserEmail($userId: uuid!, $email: String!) {
  updateUserEmail(userId: $userId, email: $email) {
    userId
    user {
      id
      email
    }
  }
}
    `;

export function useUpdateUserEmailMutation() {
  return Urql.useMutation<IUpdateUserEmailMutation, IUpdateUserEmailMutationVariables>(UpdateUserEmailDocument);
};
export const UpdateUserLanguageDocument = gql`
    mutation UpdateUserLanguage($userId: uuid!, $language: String!) {
  update_user_by_pk(pk_columns: {id: $userId}, _set: {language: $language}) {
    ...UserProfile
  }
}
    ${UserProfileFragmentDoc}`;

export function useUpdateUserLanguageMutation() {
  return Urql.useMutation<IUpdateUserLanguageMutation, IUpdateUserLanguageMutationVariables>(UpdateUserLanguageDocument);
};
export const UpdateUserSettingsDocument = gql`
    mutation UpdateUserSettings($id: uuid!, $settings: jsonb!) {
  update_user_by_pk(pk_columns: {id: $id}, _append: {settings: $settings}) {
    id
    settings
  }
}
    `;

export function useUpdateUserSettingsMutation() {
  return Urql.useMutation<IUpdateUserSettingsMutation, IUpdateUserSettingsMutationVariables>(UpdateUserSettingsDocument);
};