import { getLocationTimeZone } from "@contexts/user-context"
import * as IDate from "@internationalized/date"
import { CalendarDateTime, ZonedDateTime } from "@internationalized/date"

export function now() {
  const tz = getLocationTimeZone()
  const dt = IDate.now(tz)
  return dt
}

export function parseAbsolute(dt: string) {
  const tz = getLocationTimeZone()
  return IDate.parseAbsolute(dt, tz)
}

export function startOfDay(dt: ZonedDateTime) {
  return dt.set({ millisecond: 0, second: 0, minute: 0, hour: 0 })
}

export function endOfDay(dt: ZonedDateTime) {
  return startOfDay(dt.add({ days: 1 })).subtract({ milliseconds: 1 })
}

export function dateToCalendarDateTime(d: Date) {
  return new IDate.CalendarDateTime(
    d.getUTCFullYear(),
    d.getUTCMonth() + 1,
    d.getUTCDate(),
    d.getUTCHours(),
    d.getUTCMinutes(),
    d.getUTCSeconds(),
    d.getUTCMilliseconds()
  )
}

export function toZoned(dt: CalendarDateTime) {
  const tz = getLocationTimeZone()
  return IDate.toZoned(dt, tz)
}

export function dateToZoned(d: Date) {
  return toZoned(dateToCalendarDateTime(d))
}

export function zonedAsUTCDate(z: ZonedDateTime) {
  return IDate.toCalendarDateTime(z).toDate("UTC")
}

/**
 * Interprets a UTC date as a zoned date
 * @param dt Date
 * @returns ZonedDateTime
 */
export function utcDateAsZoned(dt: Date) {
  return toZoned(dateToCalendarDateTime(dt))
}

export function localCalendarDate(d: Date) {
  return new IDate.CalendarDate(d.getFullYear(), d.getMonth() + 1, d.getDate())
}

export function getLocalDateTimeAsUTC(d: Date) {
  const dt = new IDate.CalendarDateTime(
    d.getFullYear(),
    d.getMonth() + 1,
    d.getDate(),
    d.getHours(),
    d.getMinutes(),
    d.getSeconds(),
    d.getMilliseconds()
  )
  return dt.toDate("UTC")
}

export function getUTCAsLocalDateTime(d: Date) {
  return IDate.toZoned(dateToCalendarDateTime(d), IDate.getLocalTimeZone())
}
