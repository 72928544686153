import classNames from "classnames"
import React, { InputHTMLAttributes } from "react"

export type CheckboxInputProps = { hasError?: boolean; size?: "small" | "large" } & Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "size"
>

export const CheckboxInput = React.forwardRef<HTMLInputElement, CheckboxInputProps>(
  ({ size: _size, hasError, ...props }, forwardedRef) => {
    return (
      <input
        {...props}
        ref={forwardedRef}
        className={classNames(
          props.className,
          "w-4 h-4 cursor-pointer rounded form-checkbox box-border text-blue-deep border-grey-5 focus:outline-none focus:ring-transparent disabled:bg-gray-50 disabled:cursor-not-allowed",
          {
            "border-gray-200 focus:border-blue-700 placeholder:text-gray-400 ": !hasError,
            "border-red-700 focus:border-red-700 placeholder:text-red-300": hasError,
          }
        )}
        style={{ WebkitPrintColorAdjust: "exact" }}
        checked={
          // Formik does not provide checked when given initialValues,
          // but instead passes value=true.
          // So we here try to recognize if value with a boolean prop
          // is given and take this if no checked prop is given
          typeof props.checked === "boolean"
            ? props.checked
            : typeof props.value === "boolean"
            ? props.value
            : undefined
        }
        type="checkbox"
      />
    )
  }
)
