import { hasuraWhereStatementFromStates } from "@components/shared/data-view/data-view-filter.hooks"
import {
  DataViewConfiguration,
  FilterState,
} from "@components/shared/data-view/data-view-types"
import { useUser } from "@contexts/user-context"
import { IWorkOrderBoolExp, IWorkOrderOrderBy, IWorkOrderTypeEnum, uuid } from "@elara/db"
import { Where } from "@elara/select"
import { EventInput } from "@fullcalendar/core"
import { ICustomViewFragment } from "@graphql/documents/custom-view.generated"
import {
  IMaintenanceTriggerFragment,
  IWorkOrderDataViewFragment,
} from "@graphql/documents/fragments.generated"
import { useTaskDataViewQuery } from "@graphql/documents/work-order.generated"
import { useDeepMemo } from "@hooks"
import { getCalendarScheduledMaintenanceEvents } from "@utils/maintenance"
import { startOfDay, sub } from "date-fns"
import { useCallback, useMemo } from "react"

import { useWorkOrderDataViewSchema } from "./work-order-data-view"

export type UseTaskDataOptions = {
  teams?: uuid[]
  myTasks?: boolean
  logbook?: boolean
  asset?: uuid
  project?: uuid
  completedAtSince?: string
  customView?: Pick<ICustomViewFragment, "config">
  where?: Where<IWorkOrderDataViewFragment>
  orderBy?: IWorkOrderOrderBy
  limit?: number
}

export const useTaskData = (options: UseTaskDataOptions = {}) => {
  const user = useUser()
  const schema = useWorkOrderDataViewSchema()

  const completedAtSince =
    options.completedAtSince ?? sub(startOfDay(new Date()), { weeks: 1 }).toISOString()
  let fixedFilters: FilterState[] = []
  let where: Where<IWorkOrderDataViewFragment> = {
    type: { _in: [IWorkOrderTypeEnum.WorkOrder, IWorkOrderTypeEnum.ReportWork] },
    _and: [],
  }
  if (options.where) {
    where._and?.push(options.where)
  }

  if (options.myTasks) {
    const cond = {
      _or: [
        { assignees: { user_id: { _eq: user.id } } },
        { collaborators: { user_id: { _eq: user.id } } },
        { created_by_id: { _eq: user.id } },
      ],
    }

    where._and?.push(cond)
  }

  if (options.teams?.length) {
    const cond = {
      assigned_teams: {
        team_id:
          options.teams.length > 1 ? { _in: options.teams } : { _eq: options.teams[0] },
      },
    }
    if (options.myTasks) {
      where?._and?.[1]?._or?.push(cond)
    } else {
      where._and?.push(cond)
    }
    if (!options.myTasks) {
      fixedFilters.push({ type: "select", id: "teams", selectedValues: options.teams })
    }
  }

  if (options.asset) {
    where?._and?.push({ assets: { asset_id: { _eq: options.asset } } })
    fixedFilters.push({ type: "select", id: "assets", selectedValues: [options.asset] })
  }

  if (options.project) {
    where?._and?.push({ project_id: { _eq: options.project } })
    // fixedFilters.push({ type: "select", id: "assets", selectedValues: [options.asset] })
  }

  if (options.customView) {
    const filters = (options.customView.config as DataViewConfiguration<any>).filters ?? []
    fixedFilters.push(...filters)
    const w = hasuraWhereStatementFromStates<IWorkOrderDataViewFragment>(
      schema,
      fixedFilters
    )
    where?._and?.push(w)
  }

  const [queryRes, fetch] = useTaskDataViewQuery({
    requestPolicy: "cache-first",
    variables: {
      where: where as IWorkOrderBoolExp,
      orderBy: options.orderBy,
      limit: options.limit,
    },
  })

  const data = useMemo(
    () => queryRes.data?.work_order ?? null,
    [queryRes.data?.work_order, completedAtSince, options.logbook]
  )

  return {
    data,
    isLoading: !queryRes.data?.work_order && queryRes.fetching,
    queryRes,
    fetch,
    fixedFilters: useDeepMemo(() => fixedFilters, [fixedFilters]),
  }
}

export const useDataWorkOrderDataView = (
  where: Where<IWorkOrderDataViewFragment> | null,
  options: {
    orderBy?: IWorkOrderOrderBy
    limit?: number
    requestPolicy?: "cache-first" | "cache-and-network"
  } = {}
) => {
  const [queryRes, fetch] = useTaskDataViewQuery({
    requestPolicy: options.requestPolicy ?? "cache-and-network",
    variables: {
      where: {
        _and: [{ type: { _eq: IWorkOrderTypeEnum.WorkOrder } }, where ?? {}],
      } as IWorkOrderBoolExp,
      maintenanceWhere: (where ?? {}) as IWorkOrderBoolExp,
      orderBy: options.orderBy,
      limit: options.limit,
    },
  })

  const data = queryRes.data?.work_order ?? null
  const events = useCallback(
    (range: { from: Date; to: Date }): EventInput[] => {
      const triggers = (queryRes.data?.maintenance_trigger ??
        []) as unknown as IMaintenanceTriggerFragment[]

      return getCalendarScheduledMaintenanceEvents(triggers, range.from, range.to)
    },
    [queryRes.data?.maintenance_trigger]
  )

  return {
    data,
    isLoading: queryRes.fetching,
    queryRes,
    fetch,
    events,
  }
}
