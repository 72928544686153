import {
  IInsertManyUploadsMutation,
  IInsertManyUploadsMutationVariables,
  InsertManyUploadsDocument,
  IUploadDataFragment,
} from "@graphql/documents/upload.generated"
import {
  uploadFilesToStorage,
  useEditFileName,
  useFileUpload as useSimpleFileUpload,
} from "@hooks"
import { splitIntoChunks } from "@utils"
import { AxiosProgressEvent } from "axios"
import React, { FunctionComponent, PropsWithChildren, useState } from "react"
import { useClient } from "urql"
import { v4 as uuid } from "uuid"

type ActiveFileUpload = {
  data: IUploadDataFragment
  progress: number
}

const getPreviewUrl = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result as string)
    reader.onerror = () => reject()
    reader.readAsDataURL(file)
  })
}

export const useSingleFileUpload = (onUpload?: UploadHandler | undefined) => {
  const [activeUploads, setActiveFileUploads] = useState<ActiveFileUpload[]>([])

  const uploadFile = useSimpleFileUpload()

  const addFilesToActiveUploads = async (files: File[]) => {
    const newActiveFileUploads = await Promise.all(
      files.map(async (file) => {
        const id = uuid()
        const previewUrl = await getPreviewUrl(file)
        const data: IUploadDataFragment = {
          __typename: "upload",
          id,
          file_size: file.size,
          mime_type: file.type,
          file_name: file.name,
          url: previewUrl,
          thumbnail_url: null,
          created_at: new Date().toISOString(),
        }
        return { data, progress: 0, isUploading: false }
      })
    )
    // Add file to active uploads
    setActiveFileUploads((activeUploads) => activeUploads.concat(newActiveFileUploads))
    return newActiveFileUploads
  }

  const uploadFileWithProgress = React.useCallback(
    async (file: File) => {
      const [
        {
          data: { id },
        },
      ] = await addFilesToActiveUploads([file])

      const onUploadProgress = (e: AxiosProgressEvent) => {
        if (!e.total) return
        const progress = e.loaded / e.total
        // Update progress
        setActiveFileUploads((activeUploads) =>
          activeUploads.map((u) => (u.data.id === id ? { data: u.data, progress } : u))
        )
      }

      try {
        const uploadData = await uploadFile(file, onUploadProgress, id)
        if (onUpload) await onUpload(uploadData, file)
        return uploadData
      } finally {
        // Remove from active uploads
        setActiveFileUploads((activeUploads) =>
          activeUploads.filter((u) => u.data.id !== id)
        )
      }
    },
    [uploadFile, onUpload]
  )

  return { activeUploads, uploadFile: uploadFileWithProgress }
}

export const useBlockFileUpload = (onBlockUpload?: BlockUploadHandler | undefined) => {
  const client = useClient()

  const uploadFiles = React.useCallback(
    async (
      files: File[],
      onUploadProgress?: (event: AxiosProgressEvent) => void
    ): Promise<IUploadDataFragment[]> => {
      const urls = await uploadFilesToStorage(files, onUploadProgress)
      const data = files.map((file, i) => {
        const { url, thumbnailUrl } = urls[i]
        return {
          url,
          thumbnail_url: thumbnailUrl,
          mime_type: file.type,
          file_name: file.name,
          file_size: file.size,
        }
      })

      const res = await client
        .mutation<IInsertManyUploadsMutation, IInsertManyUploadsMutationVariables>(
          InsertManyUploadsDocument,
          { data }
        )
        .toPromise()
      if (res.data?.insert_upload?.returning) {
        return res.data.insert_upload.returning
      }
      throw res.error
    },
    [client]
  )

  const [blockFileUploadProgress, setBlockUploadProgress] = useState({
    progress: 0,
    totalBytes: 0,
    uploadedBytes: 0,
    totalFiles: 0,
    uploadedFiles: 0,
  })

  const blockFileUploadWithProgress = async (files: File[]) => {
    const totalFileSize = files.reduce((total, file) => total + file.size, 0)
    const chunks = splitIntoChunks(files, 5)

    setBlockUploadProgress({
      progress: 0,
      totalFiles: files.length,
      uploadedFiles: 0,
      totalBytes: totalFileSize,
      uploadedBytes: 0,
    })

    let totalUploadProgress = { value: 0 }
    const uploads: { upload: IUploadDataFragment; file: File }[] = []

    for (let chunk of chunks) {
      let chunkSize = chunk.reduce((chunkSize, file) => chunkSize + file.size, 0)

      const chunkUpload = await uploadFiles(chunk, (e) => {
        setBlockUploadProgress((state) => ({
          ...state,
          uploadedBytes: state.uploadedBytes + e.loaded,
          progress: (totalUploadProgress.value + e.loaded) / totalFileSize,
        }))
      })
      chunkUpload.forEach((upload, i) => uploads.push({ upload, file: chunk[i] }))

      totalUploadProgress.value += chunkSize
      setBlockUploadProgress((state) => ({
        ...state,
        uploadedFiles: state.uploadedFiles + chunk.length,
        progress: totalUploadProgress.value / totalFileSize,
      }))
    }

    await onBlockUpload?.(uploads)

    return uploads
  }

  return { blockFileUploadProgress, blockFileUpload: blockFileUploadWithProgress }
}

export type UploadHandler = (
  uploadData: IUploadDataFragment,
  file: File
) => Promise<void> | void
export type BlockUploadHandler = (
  data: { upload: IUploadDataFragment; file: File }[]
) => Promise<void> | void

export type FileUploadProviderProps = {
  onUpload?: UploadHandler
  onBlockUpload?: BlockUploadHandler
}

type FileFileUploadContextValue = {
  activeUploads: ActiveFileUpload[]

  editFileName: ReturnType<typeof useEditFileName>
  uploadFile: (file: File) => Promise<IUploadDataFragment>

  blockFileUploadProgress: { progress: number; totalFiles: number; uploadedFiles: number }
  blockFileUpload: (files: File[]) => Promise<{ upload: IUploadDataFragment; file: File }[]>
}

const FileUploadContext = React.createContext<FileFileUploadContextValue>(undefined!)

export const FileUploadProvider: FunctionComponent<
  PropsWithChildren<FileUploadProviderProps>
> = ({ children, onUpload, onBlockUpload }) => {
  const { activeUploads, uploadFile } = useSingleFileUpload(onUpload)
  const { blockFileUpload, blockFileUploadProgress } = useBlockFileUpload(onBlockUpload)

  const editFileName = useEditFileName()

  const value = React.useMemo(
    () => ({
      uploadFile,
      blockFileUpload,
      blockFileUploadProgress,
      activeUploads,
      editFileName,
    }),
    [uploadFile, activeUploads, editFileName, blockFileUploadProgress, blockFileUpload]
  )
  return <FileUploadContext.Provider value={value}>{children}</FileUploadContext.Provider>
}

export const useFileUpload = () => {
  const upload = React.useContext(FileUploadContext)
  if (!upload) throw Error("useUpload has to be used inside an FileUploadProvider!")

  return upload
}
