import { PopoverContentProps } from "@components/shared/popover"
import { TeamMultiSelect } from "@components/shared/team-select"
import { IPermissionScopeEnum, uuid } from "@elara/db"
import { IWorkOrderDataViewFragment } from "@graphql/documents/fragments.generated"
import {
  AddTeamToWorkOrderDocument,
  DeleteTeamFromWorkOrderDocument,
  IAddTeamToWorkOrderMutation,
  IAddTeamToWorkOrderMutationVariables,
  IDeleteTeamFromWorkOrderMutation,
  IDeleteTeamFromWorkOrderMutationVariables,
} from "@graphql/documents/work-order.generated"
import { usePermissionScope } from "@hooks"
import i18n from "@i18n"
import classNames from "classnames"
import { useClient } from "urql"

export const WorkOrderQuickTeamSelect = (props: {
  workOrder: IWorkOrderDataViewFragment
  compact?: boolean
  isListView?: boolean
  popoverContentProps?: PopoverContentProps
}) => {
  const client = useClient()
  const scope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)
  const teams = props.workOrder.assigned_teams.map((t) => t?.team?.id).filter(Boolean)

  const onChange = async (updatedTeams: uuid[]) => {
    const addedTeamId = updatedTeams.find((t) => !teams.includes(t))
    const deletedTeamId = teams.find((t) => !updatedTeams.includes(t))
    if (addedTeamId) {
      await client
        .mutation<IAddTeamToWorkOrderMutation, IAddTeamToWorkOrderMutationVariables>(
          AddTeamToWorkOrderDocument,
          { teamId: addedTeamId, workOrderId: props.workOrder.id },
          scope.context()
        )
        .toPromise()
    } else if (deletedTeamId) {
      await client
        .mutation<
          IDeleteTeamFromWorkOrderMutation,
          IDeleteTeamFromWorkOrderMutationVariables
        >(
          DeleteTeamFromWorkOrderDocument,
          {
            teamId: deletedTeamId,
            workOrderId: props.workOrder.id,
          },
          scope.context()
        )
        .toPromise()
    }
  }

  return (
    <TeamMultiSelect
      value={teams}
      onChange={onChange}
      title={i18n.t("common:team", { count: 1 })}
      compact={props.compact ?? false}
      summaryMode={props.compact ? "summary" : "count"}
      classForTrigger={
        props.isListView ? classNames("!bg-white", { invisible: !teams.length }) : ""
      }
      popoverContentProps={props.popoverContentProps}
    />
  )
}
