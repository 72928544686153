import { AssetViewOptions, defaultAssetColumns } from "@components/asset/asset-data-view"
import {
  assetDataViewDefaultConfig,
  AssetViewIds,
  useAssetFilterSchema,
} from "@components/asset/asset-data-view"
import { AssetDataViewListItem } from "@components/asset/asset-data-view-list-item"
import { DataView } from "@components/new-data-view/data-view"
import { useFeature } from "@contexts/feature-flag-context"
import { openModal } from "@contexts/modal-context"
import { IViewDataTypeEnum } from "@elara/db"
import { Where } from "@elara/select"
import {
  AssetDataViewDocument,
  IAssetDataViewQuery,
  IAssetDataViewQueryVariables,
} from "@graphql/documents/asset.generated"
import { IAssetDataViewFragment } from "@graphql/documents/fragments.generated"
import { useCallbackRef } from "@hooks"
import i18n from "@i18n"
import { useNavigateWithBackgroundLocation } from "@utils/location"
import { useMemo } from "react"
import { unstable_serialize } from "swr"
import { gql, useClient, useQuery } from "urql"

import { Column } from "../data-view-types"
import {
  AssetCustomFieldNamesDocument,
  IAssetCustomFieldNamesQuery,
  IAssetCustomFieldNamesQueryVariables,
} from "./queries.generated"
import { DataViewImplementation } from "./types"

function CustomFieldNamesFetcher(props: { render: (fieldNames: string[]) => JSX.Element }) {
  const [fieldNames] = useQuery<
    IAssetCustomFieldNamesQuery,
    IAssetCustomFieldNamesQueryVariables
  >({ query: AssetCustomFieldNamesDocument })

  if (!fieldNames.data?.assetCustomFieldNames?.names) return null

  return props.render(fieldNames.data.assetCustomFieldNames.names)
}

type CustomAssetFields = null | { name: string; value: string }[]
const customField = (asset: IAssetDataViewFragment, name: string) =>
  (asset.custom_fields as CustomAssetFields)?.find((f) => f.name.trim() === name)

function ObjectDataViewComponent({
  fieldNames,
  ...props
}: DataViewImplementation<
  IAssetDataViewFragment,
  AssetViewIds,
  IAssetDataViewQuery,
  IAssetDataViewQueryVariables
> & {
  fieldNames: string[]
}) {
  const { schema, components } = useAssetFilterSchema()
  const client = useClient()
  const showPublicId = useFeature("asset_public_id")

  const assetColumns = useMemo(
    () => [
      ...defaultAssetColumns(showPublicId),
      ...fieldNames.map(
        (name) =>
          ({
            Header: name,
            id: ("custom_field:" + name) as AssetViewIds,
            Cell: (asset) => customField(asset, name)?.value,
            toText: (asset) => customField(asset, name)?.value ?? "",
            searchQuery: (value) =>
              ({
                // This is not a super accurate way to search custom fields, but it's the best we can do
                // since Hasura does not expose any json path conditions for jsonb columns
                custom_fields: { _cast: { String: { _ilike: `%${value}%` } } },
              } as Where<IAssetDataViewFragment>),
          } satisfies Column<IAssetDataViewFragment, AssetViewIds, AssetViewOptions>)
      ),
    ],
    [unstable_serialize(fieldNames)]
  )

  const navigate = useNavigateWithBackgroundLocation()
  const renderListItem = useCallbackRef((asset: IAssetDataViewFragment) => (
    <AssetDataViewListItem
      asset={asset}
      onCreateWorkOrder={() =>
        openModal("select_template", { initialTaskValues: { asset_ids: [asset.id] } })
      }
    />
  ))

  return (
    <>
      <DataView<
        IAssetDataViewFragment,
        AssetViewIds,
        IAssetDataViewQuery,
        IAssetDataViewQueryVariables
      >
        columns={assetColumns}
        defaultSearchColumn="name"
        dataType={IViewDataTypeEnum.Asset}
        defaultConfig={props.defaultConfig ?? assetDataViewDefaultConfig}
        configId={props.configId ?? props.customView?.id ?? "asset:default"}
        configStickyness="localStorage"
        schema={schema}
        tieBraker={[{ id: "publicId", dir: "desc" }]}
        getId={(r: IAssetDataViewFragment) => r.id}
        getData={(data) => data?.asset ?? []}
        query={AssetDataViewDocument}
        onSelect={(d) => navigate(`/object/${d.id}`)}
        chunkSize={30}
        where={{}}
        renderListItem={renderListItem}
        tree={{
          rootWhere: { parent_asset_id: { _is_null: true } },
          childrenQuery: (parentRow: IAssetDataViewFragment) => ({
            parent_asset_id: { _eq: parentRow.id },
          }),
          numberOfChildren: (row) => row.sub_assets.length,
          includeSubitemsLabel: i18n.t("data-view:object.include_subobjects"),
        }}
        numberOfRows={(where) =>
          client
            .query(
              gql`
                query ($where: asset_bool_exp) {
                  asset_aggregate(where: $where) {
                    aggregate {
                      count
                    }
                  }
                }
              `,
              { where }
            )
            .toPromise()
            .then(({ data }) => {
              return data?.asset_aggregate.aggregate?.count ?? 0
            })
        }
        {...props}
      />
      {components}
    </>
  )
}

const ObjectDataView = (
  props: DataViewImplementation<
    IAssetDataViewFragment,
    AssetViewIds,
    IAssetDataViewQuery,
    IAssetDataViewQueryVariables
  >
) => (
  <CustomFieldNamesFetcher
    render={(fieldNames) => <ObjectDataViewComponent {...props} fieldNames={fieldNames} />}
  />
)

export default ObjectDataView
