import { Button } from "@components/shared"
import RichText, { RichTextHandle } from "@components/shared/rich-text"
import toast from "@components/shared/toast"
import { IWorkOrderDataViewFragment } from "@graphql/documents/fragments.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { Check } from "@phosphor-icons/react"
import { cn } from "@utils"
import { useRef, useState } from "react"
import { useClient } from "urql"

import {
  ISaveTaskDescriptionMutation,
  ISaveTaskDescriptionMutationVariables,
  SaveTaskDescriptionDocument,
} from "../new-task.$id/actions.generated"

export function TaskDescription(props: { task: IWorkOrderDataViewFragment }) {
  const client = useClient()
  const ref = useRef<RichTextHandle>(null)
  const editScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)

  const [focused, setFocused] = useState(false)
  const save = async () => {
    const content = ref.current?.getContent?.()
    if (!content) {
      toast.error(i18n.t("common:generic_toast_error"))
      return
    }

    const res = await client
      .mutation<ISaveTaskDescriptionMutation, ISaveTaskDescriptionMutationVariables>(
        SaveTaskDescriptionDocument,
        {
          id: props.task.id,
          description: content.html,
        },
        editScope.context()
      )
      .toPromise()
    if (res.error) {
      toast.error(i18n.t("common:generic_toast_error"))
    }
  }

  return (
    <div className="block">
      <RichText
        ref={ref}
        placeholder={i18n.t("tasks:fields.description_placeholder")}
        content={props.task.description}
        className={cn("rounded p-1 text-gray-900 ring-2 mb-2 ring-transparent", {
          "ring-blue-700 transition-colors": focused,
        })}
        onBlur={async () => {
          setFocused(false)
          await save()
        }}
        onFocus={() => setFocused(true)}
        editorProps={{ attributes: { class: "prose" } }}
        footerAction={
          <Button icon={Check} className="ml-auto" id="">
            {i18n.t("save")}
          </Button>
        }
      />
    </div>
  )
}
