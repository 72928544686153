import { IPermissionScopeEnum, usePermissionScope } from "@hooks/use-permission-scope"
import { useMemo } from "react"

export const useServiceRequestScope = () => {
  const createScope = usePermissionScope(IPermissionScopeEnum.AppServiceRequestCreate)
  const manageScope = usePermissionScope(IPermissionScopeEnum.AppServiceRequestManage)

  const context = useMemo(() => {
    if (manageScope.hasScope) return manageScope.context()
    if (createScope.hasScope) return createScope.context()
  }, [createScope.hasScope, manageScope.hasScope])

  return context
}
