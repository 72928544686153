import { Flex, HStack, VStack } from "@components/layout"
import SettingsPageLayout from "@components/settings/page-layout"
import PermissionRoleForm, {
  PermissionFormValues,
} from "@components/settings/permission-role-form"
import { Button, useConfirmModal } from "@components/shared"
import {
  getPermissionScopeDescription,
  getPermissionScopeName,
} from "@components/shared/permission-scope"
import toast from "@components/shared/toast"
import { Tooltip } from "@components/shared/tooltip"
import {
  mergeComputedAndRoleFeatureFlags,
  RoleFeatureFlag,
  useComputedFeatureFlags,
  useRoleFeatureFlags,
} from "@contexts/feature-flag-context"
import { usePage } from "@contexts/page-context"
import { IPermissionScopeEnum, uuid } from "@elara/db"
import {
  useRoleFeatureFlagsQuery,
  useUpsertRoleFeatureFlagsMutation,
} from "@graphql/documents/feature-flag.generated"
import { IPermissionRoleFragment } from "@graphql/documents/fragments.generated"
import {
  useCreatePermissionRoleMutation,
  useDeletePermissionRoleMutation,
  usePermissionRolesQuery,
  useUpdatePermissionRoleMutation,
} from "@graphql/documents/permissions.generated"
import { useDisclosure, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { Info, PencilSimpleLine, TrashSimple } from "@phosphor-icons/react"
import { useMemo, useState } from "react"

const CreatePermissionRole = (props: {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  computedFeatureFlags: ReturnType<typeof useComputedFeatureFlags>
}) => {
  const [, createPermissionRole] = useCreatePermissionRoleMutation()
  const [, upsertRoleFeatureFlags] = useUpsertRoleFeatureFlagsMutation()
  const scope = usePermissionScope(IPermissionScopeEnum.AppAccountManagement)

  const roleFeatureFlags = useRoleFeatureFlags({
    context: useMemo(() => scope.context(), [scope.hasScope]),
  })

  const onSubmit = async (v: PermissionFormValues) => {
    const roleRes = await createPermissionRole(
      {
        name: v.roleName,
        scopes: v.scopes.map((scope) => ({ scope, options: v.options[scope] ?? null })),
        features: Object.entries(v.features)
          .filter(([_feature, enabled]) => enabled)
          .map(([feature, enabled]) => ({ feature_flag: feature, enabled })),
      },
      scope.context()
    )
    const role = roleRes.data?.insert_permission_role_one

    if (!role) {
      toast.error(
        i18n.t("common:messages.token_create_failure", {
          token: i18n.t("settings:profile.fields.role"),
        })
      )
      throw new Error(
        i18n.t("common:messages.token_create_failure", {
          token: i18n.t("settings:profile.fields.role"),
        })
      )
    }

    const featuresRes = await upsertRoleFeatureFlags(
      {
        objects: Object.entries(v.features).map(([feature, enabled]) => ({
          permission_role_id: role.id,
          feature_flag: feature,
          enabled,
        })),
      },
      scope.context()
    )
    const features = featuresRes.data?.insert_permission_role_x_feature_flag

    if (!features) {
      toast.error(
        i18n.t("common:messages.token_create_failure", {
          token: i18n.t("settings:profile.fields.role"),
        })
      )
      throw new Error(
        i18n.t("common:messages.token_create_failure", {
          token: i18n.t("settings:profile.fields.role"),
        })
      )
    }
  }

  return (
    <PermissionRoleForm
      {...props}
      title={i18n.t("common:create_token", {
        token: i18n.t("settings:profile.fields.role"),
      })}
      formikConfig={{
        onSubmit,
        enableReinitialize: true,
        initialValues: {
          options: {},
          roleName: "",
          scopes: [IPermissionScopeEnum.AppUser],
          features: roleFeatureFlags.reduce(
            (flags, f) => ({ ...flags, [f.id]: f.enabled }),
            {}
          ),
        },
      }}
    />
  )
}
const EditPermissionRole = ({
  role,
  ...props
}: {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  role: IPermissionRoleFragment | null
  computedFeatureFlags: ReturnType<typeof useComputedFeatureFlags>
}) => {
  const [, updatePermissionRole] = useUpdatePermissionRoleMutation()
  const [, upsertRoleFeatureFlags] = useUpsertRoleFeatureFlagsMutation()
  const scope = usePermissionScope(IPermissionScopeEnum.AppAccountManagement)

  const [featuresQueryRes] = useRoleFeatureFlagsQuery({
    pause: !role?.id,
    context: useMemo(() => scope.context(), [scope.hasScope]),
    variables: { where: { permission_role_id: { _eq: role?.id } } },
  })
  const roleFeatures = (featuresQueryRes.data?.permission_role_x_feature_flag ??
    []) as RoleFeatureFlag[]

  const features = mergeComputedAndRoleFeatureFlags(
    props.computedFeatureFlags,
    roleFeatures
  ).reduce((flags, f) => ({ ...flags, [f.id]: f.enabled }), {})

  const onSubmit = async (v: PermissionFormValues) => {
    if (!role?.id) return

    const roleRes = await updatePermissionRole(
      {
        id: role.id,
        name: v.roleName,
        scopesToKeep: v.scopes,
        scopes: v.scopes.map((scope) => ({ scope, options: v.options[scope] ?? null })),
      },
      scope.context()
    )

    const featuresRes = await upsertRoleFeatureFlags(
      {
        objects: Object.entries(v.features).map(([feature, enabled]) => ({
          permission_role_id: role.id,
          feature_flag: feature,
          enabled,
        })),
      },
      scope.context()
    )

    if (roleRes.error || featuresRes.error) {
      toast.error(
        i18n.t("common:messages.token_update_failure", {
          token: i18n.t("settings:profile.fields.role"),
        })
      )

      throw new Error(
        i18n.t("common:messages.token_update_failure", {
          token: i18n.t("settings:profile.fields.role"),
        })
      )
    }
  }

  return (
    <PermissionRoleForm
      {...props}
      title={i18n.t("common:edit_token", { token: i18n.t("settings:profile.fields.role") })}
      formikConfig={{
        onSubmit,
        enableReinitialize: true,
        initialValues: {
          features,
          roleName: role?.name ?? "",
          scopes: role?.scopes?.map((s) => s.scope) ?? [],
          options:
            role?.scopes?.reduce(
              (opts, s) => ({ ...opts, [s.scope]: s.options }),
              {} as PermissionFormValues["options"]
            ) ?? {},
        },
      }}
    />
  )
}

const RoleScopesDescription = (props: { role: IPermissionRoleFragment }) => (
  <div className="rounded-lg bg-gray-50 p-3 text-sm text-gray-700">
    <h4 className="text-xs text-gray-500">{i18n.t("settings:permissions.title")}</h4>
    <ul className="mt-1 list-inside space-y-1 pl-2 marker:text-gray-400">
      {props.role.scopes
        .map(({ scope, options }) => ({
          name: getPermissionScopeName(scope),
          description: getPermissionScopeDescription(scope),
          assigned_only:
            (options as { assigned_only?: boolean } | null)?.assigned_only ?? false,
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({ name, description, assigned_only }) => (
          <li key={props.role.name + name}>
            <div className="inline-flex items-center justify-end space-x-1.5">
              <span className="text-gray-600">
                {name}{" "}
                {assigned_only && i18n.t("settings:permissions.labels.assigned_only")}
              </span>
              <Tooltip content={description} contentProps={{ side: "right" }}>
                <Info size={14} opacity={0.5} />
              </Tooltip>
            </div>
          </li>
        ))}
    </ul>

    <h4 className="mt-2 text-xs text-gray-500">{i18n.t("settings:feature_flags.title")}</h4>
    <ul className="mt-1 list-inside space-y-1 pl-2 marker:text-gray-400">
      {props.role.features.length === 0 && (
        <li className="text-gray-600">{i18n.t("settings:features.enabled_by_default")}</li>
      )}
      {props.role.features
        .filter((f) => f.enabled)
        .map(({ feature_flag }) => ({
          title: i18n.t(`settings:feature_flags.${feature_flag}.title`),
          description: i18n.t(`settings:feature_flags.${feature_flag}.description`),
        }))
        .sort((a, b) => a.title.localeCompare(b.title))
        .map(({ title, description }) => (
          <li key={title}>
            <div className="inline-flex items-center justify-end space-x-1.5">
              <span className="text-gray-600">{title}</span>
              <Tooltip content={description} contentProps={{ side: "right" }}>
                <Info size={14} opacity={0.5} />
              </Tooltip>
            </div>
          </li>
        ))}
    </ul>
  </div>
)

const SettingsPermissions = () => {
  usePage({
    id: "settings/permissions",
    title: i18n.t("permissions"),
    isSubPage: true,
  })

  const createRole = useDisclosure()
  const editRole = useDisclosure({ onClose: () => setRoleToEdit(null) })

  const [selectedForDelete, setSelectedForDelete] = useState<uuid | null>()
  const [roleToEdit, setRoleToEdit] = useState<IPermissionRoleFragment | null>(null)

  const [, deleteRole] = useDeletePermissionRoleMutation()
  const scope = usePermissionScope(IPermissionScopeEnum.AppAccountManagement)

  const [queryRes] = usePermissionRolesQuery({
    requestPolicy: "cache-and-network",
    context: useMemo(() => scope.context(), [scope.hasScope]),
  })
  const roles = queryRes.data?.permission_role ?? []

  const computedFeatureFlags = useComputedFeatureFlags()

  const confirmDelete = useConfirmModal({
    title: i18n.t("common:delete_token", { token: i18n.t("settings:profile.fields.role") }),
    content: i18n.t("common:messages.token_delete_confirmation", {
      token: i18n.t("settings:profile.fields.role"),
    }),
    cancelText: i18n.t("common:cancel"),
    okText: i18n.t("common:delete"),
    onOk: async () => {
      if (selectedForDelete) await deleteRole({ id: selectedForDelete }, scope.context())
    },
  })

  return (
    <SettingsPageLayout
      title={i18n.t("settings:permissions.title")}
      description={i18n.t("settings:permissions.description")}
      buttonProps={{ onClick: createRole.toggle }}
      buttonText={i18n.t("common:new_token", {
        context: "female",
        token: i18n.t("settings:profile.fields.role"),
      })}>
      <VStack space={8}>
        {roles
          ?.slice()
          ?.sort((a, b) => a.name.localeCompare(b.name))
          .map((role) => {
            return (
              <Flex col key={role.id} className="mt-3 first:mt-0">
                <HStack space={12} key={role.id} justify="space-between" className="mb-1">
                  <span className="text-sm font-semibold">{role.name}</span>

                  {!role.is_admin && scope.hasScope && (
                    <HStack space={8}>
                      <Button
                        key="delete"
                        icon={TrashSimple}
                        size="small"
                        type="tertiary"
                        onClick={() => {
                          setSelectedForDelete(role.id)
                          confirmDelete.show()
                        }}
                        disabled={!role.can_delete}
                        disabledReason={i18n.t(
                          "settings:permissions.messages.delete_disabled_reason"
                        )}
                      />
                      <Button
                        key="edit"
                        icon={PencilSimpleLine}
                        size="small"
                        type="tertiary"
                        onClick={() => {
                          setRoleToEdit(role)
                          editRole.onOpen()
                        }}
                      />
                    </HStack>
                  )}
                </HStack>
                <RoleScopesDescription role={role} />
              </Flex>
            )
          })}
      </VStack>

      {confirmDelete.component}

      <CreatePermissionRole
        isOpen={createRole.isOpen}
        onOpenChange={createRole.changeOpen}
        computedFeatureFlags={computedFeatureFlags}
      />

      <EditPermissionRole
        isOpen={editRole.isOpen}
        onOpenChange={editRole.changeOpen}
        computedFeatureFlags={computedFeatureFlags}
        role={roleToEdit}
      />
    </SettingsPageLayout>
  )
}

export default SettingsPermissions
