import { ElaraCommandDialog } from "@components/shared/elara-command"
import { makeClient } from "@graphql"
import { useCallbackRef } from "@hooks"
import { IconContext } from "@phosphor-icons/react"
import * as Sentry from "@sentry/react"
import { GraphQLError } from "graphql"
import { PropsWithChildren, useMemo } from "react"
import { BrowserRouter } from "react-router-dom"
import { CombinedError, Provider as UrqlProvider } from "urql"

import AnalyticsProvider from "./analytics-context"
import AuthProvider, { useAuthenticationStateContext } from "./auth-context"
import { BreakpointProvider } from "./breakpoints"
import { DetectPrintProvider } from "./detect-print"
import DragFilesProvider from "./drag-files-context"
import { FeatureFlagsProvider } from "./feature-flag-context"
import HighchartsWrapper from "./highcharts-wrapper"
import { ModalProvider } from "./modal-context"
import { PageProvider } from "./page-context"
import { UserProvider } from "./user-context"

export const GraphQLProvider = (props: PropsWithChildren<{}>) => {
  const authState = useAuthenticationStateContext()
  const getAuthState = useCallbackRef(() => authState)
  const locationId =
    authState.state.stage === "fully_authenticated" ? authState.state.dataLocationId : null

  const client = useMemo(() => makeClient({ getAuthState }), [locationId])

  return <UrqlProvider value={client}>{props.children}</UrqlProvider>
}

if (
  import.meta.env.VITE_ELARA_IN_PRODUCTION === "true" ||
  import.meta.env.VITE_ELARA_IN_TEST === "true"
) {
  Sentry.init({
    dsn: "https://bd7515db44ca49c5bd16e025c6a25baa@o1057340.ingest.sentry.io/6044057",
    beforeSend: (event, hint) => {
      if (hint?.originalException instanceof GraphQLError) {
        event.fingerprint = [
          "{{ GraphQLError }}",
          String(hint?.originalException.message),
          String(hint?.originalException.extensions.path),
        ]
      } else if (hint?.originalException instanceof CombinedError) {
        event.fingerprint = [
          "{{ CombinedError }}",
          String(
            hint?.originalException.graphQLErrors.map((err) => err.message).join(", ")
          ),
          String(hint?.originalException.networkError?.message),
        ]
      }

      return event
    },
    integrations: [],
    ignoreErrors: ["ResizeObserver loop", "PermissionError"],
    tracesSampleRate: 0.01,
  })
}

export const AppProviders = (props: PropsWithChildren<{}>) => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <BreakpointProvider>{props.children}</BreakpointProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}

export const AnonymousAppProviders = (props: PropsWithChildren<{}>) => {
  return <GraphQLProvider>{props.children}</GraphQLProvider>
}

export const AuthenticatedAppProviders = (props: PropsWithChildren<{}>) => {
  return (
    <GraphQLProvider>
      <UserProvider>
        <FeatureFlagsProvider>
          <AnalyticsProvider>
            <DragFilesProvider>
              <PageProvider>
                <HighchartsWrapper>
                  <IconContext.Provider value={{ size: 16, weight: "bold" }}>
                    <ModalProvider>
                      <DetectPrintProvider>
                        {props.children}

                        <ElaraCommandDialog />
                      </DetectPrintProvider>
                    </ModalProvider>
                  </IconContext.Provider>
                </HighchartsWrapper>
              </PageProvider>
            </DragFilesProvider>
          </AnalyticsProvider>
        </FeatureFlagsProvider>
      </UserProvider>
    </GraphQLProvider>
  )
}

export default AppProviders
