import {
  ComboboxMultiSelect,
  ComboboxSelect,
  Item,
} from "@components/shared/combobox-select"
import {
  PopoverAnchor,
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from "@components/shared/popover"
import { Data } from "@elara/select"
import { useCallbackRef, useDisclosure } from "@hooks"
import i18n from "@i18n"
import { Plus } from "@phosphor-icons/react"
import { PopoverContentProps } from "@radix-ui/react-popover"
import Icons from "@resources/icons"
import { cn } from "@utils"
import { findTreeList } from "@utils/tree"
import classNames from "classnames"
import { dequal } from "dequal"
import React, { RefCallback, useEffect, useLayoutEffect, useState } from "react"
import { useClient } from "urql"

import LoadingIndicator from "../loading-indicator"
import TouchTargetSize from "../touch-target-size"
import {
  defaultValueToString,
  Schema,
  SelectSchemaStatement,
  UseDataViewFilterReturnType,
} from "./data-view-filter.hooks"
import { FilterState, SelectStateWithId } from "./data-view-types"

export const useDataViewFilterMaxListHeight = (): [number, RefCallback<HTMLElement>] => {
  const [maxListHeight, setListMaxHeight] = useState(150)
  const ref = (node: HTMLElement) => {
    if (node) {
      window.visualViewport &&
        setListMaxHeight(
          window.visualViewport.height -
            node.getBoundingClientRect().y +
            // Subtract 36 to account for the height of the trigger
            -36 +
            // Subtract 64 to account for the size of the input element and some spacing
            -64
        )
    }
  }
  return [maxListHeight, ref]
}

type SelectedFilterMenuProps<D extends Data> = {
  schemaStatement: SelectSchemaStatement<D>
  state: SelectStateWithId
  closeMenu: () => void
  listMaxHeight?: number
  onStateChange: (state: SelectStateWithId, keepOpen: boolean) => void
}

const SelectedFilterMenu = <D extends Data>(props: SelectedFilterMenuProps<D>) => {
  const { schemaStatement, state } = props
  const [items, setItems] = useState<Item<any>[] | null>(null)
  const client = useClient()
  useEffect(() => {
    const fetchItems = async () => {
      const items = await schemaStatement.getItems?.(client)
      setItems(items)
    }
    fetchItems()
  }, [state.id])

  const onStateChange = useCallbackRef(props.onStateChange)

  if (!items)
    return (
      <div className="flex w-32 items-center justify-center p-4">
        <LoadingIndicator size={24} />
      </div>
    )
  if (schemaStatement.singleSelectOnly) {
    return (
      <ComboboxSelect
        valueToString={schemaStatement.valueToString ?? defaultValueToString}
        items={items}
        groupBy={schemaStatement.isGrouped}
        placeholder={schemaStatement.label}
        value={state?.selectedValues?.[0] ?? null}
        listMaxHeight={props.listMaxHeight}
        onCustomOnClick={async (selectedItem) => {
          props.closeMenu()
          if (schemaStatement.onCustomClick) {
            try {
              const item = await schemaStatement.onCustomClick(selectedItem.value)
              onStateChange({ ...state, selectedValues: [item.value] }, false)
            } catch {}
          }
        }}
        onChange={(value) => {
          onStateChange({ ...state, selectedValues: [value] }, false)
        }}
      />
    )
  }

  return (
    <ComboboxMultiSelect
      valueToString={schemaStatement.valueToString ?? defaultValueToString}
      items={items}
      groupBy={schemaStatement.isGrouped}
      placeholder={schemaStatement.label}
      className="max-w-xs"
      listMaxHeight={props.listMaxHeight}
      value={state?.selectedValues ?? []}
      onCustomOnClick={props.closeMenu}
      onChange={(selectedValues, _selectedItems, keepOpen) => {
        props.onStateChange({ ...state, selectedValues }, keepOpen)
      }}
    />
  )
}

type SelectNegatedMenuProps = {
  operatorLabel?: (negated: boolean, multiple: boolean) => string
  multiple: boolean
  isNegated: boolean
  onNegatedChange: (isNegated: boolean) => void
}

function defaultOperatorLabel(isNegated: boolean, multiple: boolean) {
  if (multiple) {
    return isNegated
      ? i18n.t("data-view:filters.is_not")
      : i18n.t("data-view:filters.is_either")
  } else {
    return isNegated ? i18n.t("data-view:filters.is_not") : i18n.t("data-view:filters.is")
  }
}

const SelectNegatedMenu = (props: React.PropsWithChildren<SelectNegatedMenuProps>) => {
  const { operatorLabel = defaultOperatorLabel } = props
  const menu = useDisclosure()
  return (
    <PopoverRoot open={menu.isOpen} onOpenChange={menu.changeOpen} alwaysPopover>
      <PopoverTrigger className="relative h-full cursor-default px-1 text-gray-600 hover:bg-gray-100 hover:text-gray-900">
        <TouchTargetSize horizontal={false} />
        {operatorLabel(props.isNegated, props.multiple)}
      </PopoverTrigger>
      <PopoverContent sideOffset={12}>
        <ComboboxSelect
          items={[
            {
              label: operatorLabel(false, props.multiple),
              value: "eq",
              searchValue: operatorLabel(false, props.multiple),
            },
            {
              label: operatorLabel(true, props.multiple),
              value: "neq",
              searchValue: operatorLabel(true, props.multiple),
            },
          ]}
          valueToString={(v) => v}
          placeholder={i18n.t("common:select")}
          inputProps={{ hide: true }}
          value={props.isNegated ? "neq" : "eq"}
          onChange={(v) => {
            props.onNegatedChange(v === "neq")
            menu.onClose()
          }}
        />
      </PopoverContent>
    </PopoverRoot>
  )
}

type FilterBadgeProps<D extends Data> = {
  schema: Schema<D>
  state: FilterState
  onStateChange: (state: FilterState) => void
  onRemove: () => void
}

const FilterBadge = <D extends Data>(props: FilterBadgeProps<D>) => {
  const { state, schema } = props

  const menu = useDisclosure()

  const schemaStatement = schema[state.id]

  const [items, setItems] = useState<Item<any>[] | null>(null)
  const client = useClient()
  useEffect(() => {
    const fetchItems = async () => {
      if (schemaStatement.type === "singleton") return
      const items = await schemaStatement.getItems?.(client)
      setItems(items)
    }
    fetchItems()
  }, [])

  const [listMaxHeight, ref] = useDataViewFilterMaxListHeight()

  return (
    <PopoverRoot open={menu.isOpen} onOpenChange={menu.changeOpen} modal>
      <div
        ref={ref}
        className="flex cursor-default flex-row items-stretch rounded border border-solid border-gray-300 bg-white pl-1 text-sm text-gray-700">
        <span className="inline-flex items-center px-1">
          {schemaStatement.icon ? (
            <span className="mr-1 text-gray-500">{schemaStatement.icon}</span>
          ) : null}
          {schemaStatement.type === "singleton"
            ? schemaStatement.badgeLabel
            : schemaStatement.label}
        </span>
        <SelectNegatedMenu
          multiple={
            state?.type === "select" &&
            !!state.selectedValues &&
            state.selectedValues.length > 1
          }
          operatorLabel={
            schemaStatement.operatorLabel &&
            ((negated: boolean, multiple: boolean) =>
              schemaStatement.operatorLabel!(negated, multiple, state as any))
          }
          isNegated={state.negated ?? false}
          onNegatedChange={(isNegated) => {
            props.onStateChange({ ...state, negated: isNegated })
          }}
        />
        <PopoverTrigger
          className={classNames("h-full px-1 cursor-default relative whitespace-nowrap", {
            "hover:bg-gray-100 hover:text-gray-900": state.type === "select",
          })}>
          <TouchTargetSize horizontal={false} />
          {state.type === "select" && schemaStatement.type === "select" ? (
            state.selectedValues?.length == 1 ? (
              findTreeList(items ?? [], (item) =>
                dequal(state.selectedValues[0], item.value)
              )?.label
            ) : (
              <span>
                {state.selectedValues?.length}{" "}
                {(schemaStatement as SelectSchemaStatement<any>).multiSelectedLabel}
              </span>
            )
          ) : (
            schemaStatement.label
          )}
        </PopoverTrigger>
        <div
          className="inline-flex shrink-0 items-center rounded-r p-1 text-lg text-gray-600 hover:bg-gray-100 hover:text-gray-800"
          onClick={props.onRemove}>
          <Icons.Close />
        </div>
      </div>

      <PopoverContent
        alignOffset={0}
        sideOffset={8}
        className="max-w-sm"
        dialog={{ onOpenAutoFocus: (e) => e.preventDefault() }}>
        {state.type === "select" && schemaStatement?.type === "select" && (
          <SelectedFilterMenu
            onStateChange={(state, keepOpen) => {
              props.onStateChange(state)
              menu.changeOpen(keepOpen)
            }}
            closeMenu={menu.onClose}
            schemaStatement={schemaStatement}
            listMaxHeight={listMaxHeight}
            state={state}
          />
        )}
      </PopoverContent>
    </PopoverRoot>
  )
}

type FilterMenuProps<D extends Data> = {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  listMaxHeight?: number
  schema: Schema<D>

  onAddState: (state: FilterState, keepOpen: boolean) => void
  lastState: FilterState | null
  onUpdateLastState: (state: FilterState, keepOpen: boolean) => void
}

const FilterMenu = <D extends Data>(props: React.PropsWithChildren<FilterMenuProps<D>>) => {
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null)
  useLayoutEffect(() => {
    if (!props.isOpen) {
      setSelectedFilter(null)
    }
  }, [props.isOpen])

  if (selectedFilter) {
    const id = selectedFilter
    const state = props.lastState ?? { type: props.schema[id].type, id, selectedItems: [] }
    const schemaStatement = props.schema[id]
    if (state.type !== "select" || schemaStatement?.type !== "select") return null
    return (
      <SelectedFilterMenu
        schemaStatement={schemaStatement}
        state={state as SelectStateWithId}
        listMaxHeight={props.listMaxHeight}
        closeMenu={() => props.onOpenChange(false)}
        onStateChange={(state, keepOpen) => {
          if (props.lastState) {
            props.onUpdateLastState(state, keepOpen)
          } else {
            props.onAddState(state, keepOpen)
          }
        }}
      />
    )
  } else {
    const items = Object.keys(props.schema).map((id) => {
      const s = props.schema[id]
      return {
        value: id,
        label: s.label,
        searchValue: s.label,
        icon: s.icon ? <span className="text-gray-500">{s.icon}</span> : null,
      }
    })

    return (
      <ComboboxSelect
        items={items}
        listMaxHeight={props.listMaxHeight}
        placeholder={i18n.t("common:search")}
        valueToString={(v) => v}
        value={selectedFilter}
        onChange={(selectedValue) => {
          const schemaStatement = props.schema[selectedValue]
          if (schemaStatement.type === "singleton") {
            props.onAddState(
              { id: selectedValue, type: "singleton", negated: false },
              false
            )
            props.onOpenChange(false)
          } else {
            setSelectedFilter(selectedValue)
          }
        }}
      />
    )
  }
}

type DataViewFilterRootProps<D extends Data> = UseDataViewFilterReturnType<D>

const DataViewFilterContext = React.createContext<UseDataViewFilterReturnType<Data> | null>(
  null
)

const useDataViewFilterContext = <D extends Data>() => {
  const value = React.useContext(DataViewFilterContext)
  return value as UseDataViewFilterReturnType<D> | null
}

export const DataViewFilterRoot = <D extends Data>(
  props: React.PropsWithChildren<DataViewFilterRootProps<D>>
) => (
  <DataViewFilterContext.Provider value={props}>
    {props.children}
  </DataViewFilterContext.Provider>
)

export const DataViewFilterMenuPopover = ({
  listMaxHeight,
  onClose,
  onOpen,
  ...props
}: React.PropsWithChildren<
  PopoverContentProps & {
    listMaxHeight?: number
    onOpen?: () => void
    onClose?: () => void
  }
>) => {
  const dataViewFilter = useDataViewFilterContext()

  const filterMenu = useDisclosure({
    onOpen,
    onClose: () => {
      dispatch({ type: "closeMenu" })
      onClose?.()
    },
  })

  if (!dataViewFilter) return null

  const { schema, state, dispatch, filterStates } = dataViewFilter
  const { addedFilterFromCurrentOpenMenu } = state

  return (
    <PopoverRoot open={filterMenu.isOpen} onOpenChange={filterMenu.changeOpen}>
      {props.children}

      <PopoverContent {...props} dialog={{ onOpenAutoFocus: (e) => e.preventDefault() }}>
        <FilterMenu
          isOpen={filterMenu.isOpen}
          onOpenChange={filterMenu.changeOpen}
          listMaxHeight={listMaxHeight}
          schema={schema}
          lastState={
            addedFilterFromCurrentOpenMenu ? filterStates[filterStates.length - 1] : null
          }
          onAddState={(state, keepOpen) => {
            dispatch({ type: "addFilterState", state })
            filterMenu.changeOpen(keepOpen)
          }}
          onUpdateLastState={(state, keepOpen) => {
            dispatch({
              type: "updateFilterState",
              state,
              index: filterStates.length - 1,
            })
            filterMenu.changeOpen(keepOpen)
          }}
        />
      </PopoverContent>
    </PopoverRoot>
  )
}

export const DataViewFilterTrigger = (props: React.PropsWithChildren<{}>) => {
  const dataViewFilter = useDataViewFilterContext()
  if (!dataViewFilter) return null
  return <PopoverTrigger asChild>{props.children}</PopoverTrigger>
}

const AddMore = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<{
    dataViewFilter: UseDataViewFilterReturnType<Data>
    className?: string
  }>
>((props, ref) => (
  <>
    <PopoverTrigger
      className={classNames(
        "relative flex items-center justify-center text-gray-600 rounded min-w-[1.75rem] hover:text-gray-800 hover:bg-gray-100 ",
        // { "ml-2": props.dataViewFilter.state.addedFilterFromCurrentOpenMenu },
        { "text-sm px-1 -mx-1": !!props.children },
        props.className
      )}
      ref={ref}>
      <TouchTargetSize />
      <Plus className={classNames("shrink-0", props.children ? "mr-1" : "")} />{" "}
      {props.children}
    </PopoverTrigger>
  </>
))

export const DataViewFilterBadges = (
  props: React.PropsWithChildren<{ className?: string; allowToAddToEmptyList?: boolean }>
) => {
  const dataViewFilter = useDataViewFilterContext()
  const [listMaxHeight, ref] = useDataViewFilterMaxListHeight()

  if (!dataViewFilter) return null

  return (
    <DataViewFilterMenuPopover
      side="bottom"
      align="start"
      sideOffset={4}
      alignOffset={0}
      listMaxHeight={listMaxHeight}>
      <div
        className={classNames(
          "flex flex-row flex-wrap gap-x-2 gap-y-1 items-center",
          props.className
        )}>
        {dataViewFilter.filterStates.map((state, index) => {
          const isLastBadge = index === dataViewFilter.filterStates.length - 1
          const isSecondLastBadge = index === dataViewFilter.filterStates.length - 2
          const showAnchor =
            (isSecondLastBadge && dataViewFilter.state.addedFilterFromCurrentOpenMenu) ||
            (isLastBadge && !dataViewFilter.state.addedFilterFromCurrentOpenMenu)
          return (
            <div
              key={`${state.id}_${index}`}
              className="flex min-h-[2rem] flex-wrap gap-y-2">
              <FilterBadge
                schema={dataViewFilter.schema}
                state={state}
                onStateChange={(state) => {
                  dataViewFilter.dispatch({ type: "updateFilterState", state, index })
                }}
                onRemove={() =>
                  dataViewFilter.dispatch({ type: "removeFilterState", index })
                }
              />
              {showAnchor && (
                <PopoverAnchor
                  key="anchor"
                  data-id="anchor"
                  className={cn("bg-red-500 self-stretch")}
                />
              )}
              {isLastBadge && (
                <AddMore ref={ref} dataViewFilter={dataViewFilter} className="ml-2">
                  {i18n.t("data-view:filter", { count: 1 })}
                </AddMore>
              )}
            </div>
          )
        })}
        {dataViewFilter.filterStates.length === 0 && props.allowToAddToEmptyList && (
          <div className="flex">
            <AddMore ref={ref} dataViewFilter={dataViewFilter}>
              {i18n.t("data-view:filter", { count: 1 })}
            </AddMore>
          </div>
        )}
      </div>
    </DataViewFilterMenuPopover>
  )
}

export default DataViewFilterRoot
