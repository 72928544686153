import { IAssetTagFragment } from "@graphql/documents/fragments.generated"
import { CaretRight, Shapes } from "@phosphor-icons/react"
import { LinkWithBackgroundLocation } from "@utils/location"
import classNames from "classnames"
import React from "react"

export const AssetWithParents = (props: {
  asset: Partial<IAssetTagFragment>
  showAvatar?: boolean
  wrapParents?: boolean
  withLink?: boolean
  linkToSubpage?: string
}) => {
  const tag = (
    <div className="flex min-w-0 items-center">
      {props.showAvatar &&
        (props.asset.avatar?.thumbnail_url ? (
          <img
            src={props.asset.avatar?.thumbnail_url!}
            className="mr-2 h-6 w-6 shrink-0 rounded"
          />
        ) : (
          <div className="mr-2 flex h-6 w-6 shrink-0 items-center justify-center rounded text-gray-300">
            <Shapes size={20} />
          </div>
        ))}
      <div className="flex min-w-0 flex-col">
        <div
          className={classNames(
            "flex min-w-0 items-center gap-x-1 text-xs font-normal text-gray-500",
            { "flex-wrap": props.wrapParents }
          )}>
          {props.asset.parents?.map((a, idx) => (
            <React.Fragment key={a.id}>
              <span className="min-w-0 truncate" style={{ flexShrink: idx * 3 }}>
                {a.name}
              </span>
              <CaretRight
                size={10}
                className="-mx-1 text-gray-400"
                style={{ flexShrink: idx * 3 }}
              />
            </React.Fragment>
          ))}
        </div>
        <div className="inline-flex min-w-0 shrink">
          <span className="truncate text-sm">
            <span className="font-medium">{props.asset?.name}</span>{" "}
            <span className="text-gray-500">{props.asset?.public_id}</span>
          </span>
        </div>
      </div>
    </div>
  )

  if (props.withLink) {
    return (
      <LinkWithBackgroundLocation
        to={`/object/${props.asset.id}/` + (props.linkToSubpage ?? "")}
        className="flex min-w-0 cursor-pointer truncate break-all rounded px-2 py-1 font-medium ring-gray-300 transition-all hover:text-gray-800 hover:ring-1">
        {tag}
      </LinkWithBackgroundLocation>
    )
  } else {
    return tag
  }
}
