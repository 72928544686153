import PageViewLayout from "@components/page/page-view-layout"
import { useServiceRequestScope } from "@components/service-request/hooks/use-service-request-scope"
import {
  ServiceRequestPrioritySelect,
  ServiceRequestStatusSelect,
} from "@components/service-request/status"
import { Button, UserMultiSelect } from "@components/shared"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@components/shared/dropdown"
import RichText from "@components/shared/rich-text"
import { TeamMultiSelect } from "@components/shared/team-select"
import toast from "@components/shared/toast"
import Uploads from "@components/shared/uploads"
import { SelectObjectElement } from "@components/work-order/create-task-form"
import TasksBox from "@components/work-order/tasks-box"
import { FeatureGuard } from "@contexts/feature-flag-context"
import { openModal } from "@contexts/modal-context"
import {
  IPermissionScopeEnum,
  IServiceRequestSetInput,
  IServiceRequestStatusEnum,
  IWorkOrderPriorityEnum,
} from "@elara/db"
import { IWorkOrderDataViewFragment } from "@graphql/documents/fragments.generated"
import {
  AddTeamToServiceRequestDocument,
  AddUserToServiceRequestDocument,
  RemoveTeamToServiceRequestDocument,
  RemoveUserToServiceRequestDocument,
  useServiceRequestQuery,
  useUpdateServiceRequestMutation,
} from "@graphql/documents/service-request.generated"
import { usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { PencilSimple, Swap, XCircle } from "@phosphor-icons/react"
import { MoreActions } from "@resources/icons"
import { useParams } from "react-router-dom"
import { useClient } from "urql"

type RouteParams = { id: string }

export default function ServiceRequestDetailPage() {
  const client = useClient()
  const { id } = useParams<RouteParams>()

  const context = useServiceRequestScope()
  const [queryRes, refetch] = useServiceRequestQuery({
    variables: { id: +id! },
    pause: !id,
    context,
  })
  const serviceRequest = queryRes.data?.service_request_by_pk ?? null

  const [, updateRequest] = useUpdateServiceRequestMutation()
  const manageScope = usePermissionScope(IPermissionScopeEnum.AppServiceRequestManage)

  if (!id || !serviceRequest) return null

  const isCompleted = [
    (IServiceRequestStatusEnum.Done, IServiceRequestStatusEnum.Rejected),
  ].includes(serviceRequest.status)

  const hasTasks = serviceRequest.tasks.length > 0

  const onChange = async (object: IServiceRequestSetInput) => {
    const response = await updateRequest({ id: serviceRequest.id, object }, context)

    if (response.error) return

    toast.success({
      title: "The service request was updated successfully.",
      params: { position: "bottom-right" },
    })
  }

  const handleAssigneeChange = async (userIds: string[]) => {
    let response

    const existingUserIds = serviceRequest.assignees.map((a) => a.user_id)
    const addedUserId = userIds.find((t) => !existingUserIds.includes(t))
    const deletedUserId = existingUserIds.find((t) => !userIds.includes(t))

    if (addedUserId) {
      response = await client.mutation(
        AddUserToServiceRequestDocument,
        {
          user_id: addedUserId,
          service_request_id: serviceRequest.id,
        },
        context
      )
    } else {
      response = await client.mutation(
        RemoveUserToServiceRequestDocument,
        {
          user_id: deletedUserId,
          service_request_id: serviceRequest.id,
        },
        context
      )
    }

    if (response.error) return

    refetch()
  }

  const handleAssignedTeamChange = async (teamIds: string[]) => {
    let response

    const existingTeamIds = serviceRequest.assigned_teams.map((a) => a.team_id)
    const addedTeamId = teamIds.find((t) => !existingTeamIds.includes(t))
    const deletedTeamId = existingTeamIds.find((t) => !teamIds.includes(t))

    if (addedTeamId) {
      response = await client.mutation(
        AddTeamToServiceRequestDocument,
        {
          team_id: addedTeamId,
          service_request_id: serviceRequest.id,
        },
        context
      )
    } else {
      response = await client.mutation(
        RemoveTeamToServiceRequestDocument,
        {
          team_id: deletedTeamId,
          service_request_id: serviceRequest.id,
        },
        context
      )
    }

    if (response.error) return

    refetch()
  }

  const handleCreateTaskFromRequest = async () => {
    // Create a new task
    openModal("task_create", {
      initialValues: {
        name: serviceRequest.title,
        description: serviceRequest.description,
        priority: serviceRequest.priority,
        service_request_id: serviceRequest.id,
        asset_ids: serviceRequest.assets.map((a) => a.asset_id),
        assignee_ids: serviceRequest.assignees.map((a) => a.user_id),
        team_ids: serviceRequest.assigned_teams.map((a) => a.team_id),
        documents: serviceRequest.uploads.map((u) => ({
          __typename: "work_order_x_upload",
          document: u.upload,
        })),
      },
    })

    // Set Service Request Status to "In Progress"
    await onChange({ status: IServiceRequestStatusEnum.InProgress })
  }

  return (
    <PageViewLayout
      title={serviceRequest.title}
      action={
        <div className="flex items-center space-x-2">
          {!isCompleted && !hasTasks && manageScope.hasScope && (
            <Button
              type="secondary"
              icon={() => <Swap />}
              className="hidden md:flex"
              onClick={handleCreateTaskFromRequest}>
              {i18n.t("service-request:actions.create_task")}
            </Button>
          )}

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button type="secondary" icon={() => <MoreActions />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {!isCompleted && !hasTasks && manageScope.hasScope && (
                <DropdownMenuItem
                  onClick={handleCreateTaskFromRequest}
                  className="flex md:hidden">
                  <Swap size={14} weight="fill" className="ml-0.5" />
                  <span className="ml-2">
                    {i18n.t("service-request:actions.create_task")}
                  </span>
                </DropdownMenuItem>
              )}

              <DropdownMenuItem
                onClick={() => {
                  openModal("service_request", { initialValues: serviceRequest })
                }}>
                <PencilSimple size={14} weight="fill" className="ml-0.5" />
                <span className="ml-2">
                  {i18n.t("common:edit_token", {
                    token: i18n.t("common:service_request", { count: 1 }),
                  })}
                </span>
              </DropdownMenuItem>

              {manageScope.hasScope && (
                <>
                  <DropdownMenuSeparator />

                  <DropdownMenuItem
                    disabled={isCompleted}
                    className="text-red-500"
                    onClick={() => {
                      onChange({ status: IServiceRequestStatusEnum.Rejected })
                    }}>
                    <XCircle size={14} weight="fill" className="ml-0.5" />
                    <span className="ml-2">{i18n.t("service-request:actions.reject")}</span>
                  </DropdownMenuItem>
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      }>
      <div className="mx-6 my-4 max-w-6xl space-y-3">
        <div className="inline-flex rounded-full border border-gray-200 bg-gray-50 px-3 py-0.5 text-xs font-semibold tracking-widest text-gray-600">
          #{serviceRequest.id}
        </div>

        <div className="grid gap-6 md:grid-cols-4">
          <div className="order-2 flex flex-col gap-y-4 md:order-1 md:col-span-3">
            {/* Title */}
            <div className="space-y-2">
              <span className="text-xs font-medium">
                {i18n.t("service-request:fields.title")}
              </span>
              <h2 className="text-base font-medium">{serviceRequest.title}</h2>
            </div>

            {/* Description */}
            <div className="space-y-2">
              <span className="text-xs font-medium">
                {i18n.t("service-request:fields.description")}
              </span>
              <RichText
                contentClassName="p-0"
                content={serviceRequest.description}
                placeholder={i18n.t("service-request:messages.no_description")}
              />
            </div>

            {/* Attachments */}
            {serviceRequest.uploads.length > 0 && (
              <div className="space-y-2">
                <span className="text-xs font-medium">
                  {i18n.t("service-request:fields.uploads")}
                </span>
                <Uploads uploads={serviceRequest.uploads.map((u) => u.upload)} />
              </div>
            )}

            {/* Objects */}
            <FeatureGuard id="objects">
              {serviceRequest.assets.length > 0 ? (
                <div className="space-y-2">
                  <span className="text-xs font-medium">
                    {i18n.t("common:asset", { count: 2 })}
                  </span>
                  <div className="space-y-2">
                    {serviceRequest.assets.map(({ asset_id }) => (
                      <SelectObjectElement key={asset_id} assetId={asset_id} />
                    ))}
                  </div>
                </div>
              ) : null}
            </FeatureGuard>

            {/* Tasks */}
            <FeatureGuard id="tasks">
              {serviceRequest.tasks.length > 0 ? (
                <TasksBox
                  className="sm:p-3"
                  data={serviceRequest.tasks as unknown as IWorkOrderDataViewFragment[]}
                  dateLabel=""
                  isFetching={false}
                  dateElement={() => null}
                  emptyPlaceholder={null}
                />
              ) : null}
            </FeatureGuard>
          </div>

          <div className="order-1 grid grid-cols-2 gap-y-4 border-b pb-6 md:order-2 md:col-span-1 md:flex md:grid-cols-1 md:flex-col md:border-b-0 md:pb-0">
            {/* Status */}
            <div className="space-y-0.5">
              <span className="text-xs font-medium">
                {i18n.t("service-request:fields.status")}
              </span>
              <div className="max-w-fit">
                <ServiceRequestStatusSelect
                  className="border-none disabled:bg-transparent"
                  disabled={isCompleted || !manageScope.hasScope}
                  value={serviceRequest.status}
                  onValueChange={(status) => {
                    onChange({ status: status as IServiceRequestStatusEnum })
                  }}
                />
              </div>
            </div>

            {/* Priority */}
            <div className="space-y-0.5">
              <span className="text-xs font-medium">
                {i18n.t("service-request:fields.priority")}
              </span>
              <div className="max-w-fit">
                <ServiceRequestPrioritySelect
                  value={serviceRequest.priority ?? ""}
                  onValueChange={(priority) => {
                    onChange({ priority: priority as IWorkOrderPriorityEnum })
                  }}
                />
              </div>
            </div>

            {/* Assignees */}
            <div className="space-y-2">
              <span className="text-xs font-medium">
                {i18n.t("service-request:fields.assignees")}
              </span>
              <div className="max-w-fit">
                <UserMultiSelect
                  compact
                  disabled={!manageScope.hasScope}
                  onChange={handleAssigneeChange}
                  classForTrigger="border-none bg-transparent"
                  value={serviceRequest.assignees.map((a) => a.user_id)}
                />
              </div>
            </div>

            {/* Assigned Teams */}
            <div className="space-y-2">
              <span className="text-xs font-medium">
                {i18n.t("service-request:fields.assigned_teams")}
              </span>
              <div className="max-w-fit">
                <TeamMultiSelect
                  compact
                  disabled={!manageScope.hasScope}
                  onChange={handleAssignedTeamChange}
                  classForTrigger="border-none bg-transparent"
                  value={serviceRequest.assigned_teams.map((a) => a.team_id)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageViewLayout>
  )
}
