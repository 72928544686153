import CollapsibleSection from "@components/shared/collapsible-section"
import DisabledMessage from "@components/shared/disabled-message"
import toast from "@components/shared/toast"
import { BlockElementPayload, IBlockElementTypeEnum, uuid } from "@elara/db"
import { useUpdateBlockElementResponseMutation } from "@graphql/documents/block.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import React from "react"

import { BlockElement } from "./block-element"
import { BlockElementFragment } from "./elements/block-element-types"

export type BlockGroupProps = {
  elements: BlockElementFragment[]
  blockResponseUpdate?: boolean
  scope?: IPermissionScopeEnum
  hideResponseInfo?: boolean
  placeholder?: string
}

export const BlockGroup = (props: BlockGroupProps) => {
  let stepNumber = 0
  const sections = props.elements.reduce((sections, element) => {
    const isHeading = element.element_type === IBlockElementTypeEnum.Heading

    if (isHeading) {
      sections.push({ title: element.config.description, elements: [] })
    } else {
      if (sections.length > 0) {
        sections[sections.length - 1].elements.push(element)
      }
    }

    return sections
  }, [] as { title: string; elements: BlockElementFragment[] }[])

  const standaloneElements =
    sections.length === 0
      ? props.elements
      : props.elements.slice(
          0,
          props.elements.findIndex(
            (element) => element.element_type === IBlockElementTypeEnum.Heading
          )
        )

  const scope = usePermissionScope(props.scope ?? IPermissionScopeEnum.AppDataEntry)
  const [, updateBlockElementResponse] = useUpdateBlockElementResponseMutation()

  const updateResponse = async (id: uuid, response: BlockElementPayload["response"]) => {
    if (scope.hasScope && !props.blockResponseUpdate) {
      const res = await updateBlockElementResponse(
        { id, response: response as {} },
        scope.context()
      )
      if (!res.data?.update_block_element_by_pk) {
        toast.error(i18n.t("common:generic_toast_error"))
      }
    } else if (!scope.hasScope) {
      toast.error(i18n.t("common:missing_permission"))
    } else if (props.blockResponseUpdate) {
      toast.error(i18n.t("tasks:messages.filling_not_allowed"))
    }
  }

  return (
    <div className="relative">
      <div className="grid grid-cols-1 gap-x-2 gap-y-3">
        {props.elements.length === 0 ? (
          <span className="text-sm text-gray-600">
            {props.placeholder ?? i18n.t("tasks:checklist.empty.title")}
          </span>
        ) : (
          <>
            {!scope.hasScope && (
              <DisabledMessage
                hasIcon
                mode="info"
                message={i18n.t("tasks:checklist.disabled.title")}
              />
            )}
            {standaloneElements &&
              standaloneElements.map((element) => {
                const hideStepNumber =
                  element.element_type === IBlockElementTypeEnum.Paragraph

                if (!hideStepNumber) stepNumber++

                return (
                  <React.Fragment key={element.id}>
                    {hideStepNumber ? (
                      <span />
                    ) : (
                      <span className="mt-3.5 text-sm font-medium text-gray-600">
                        {stepNumber}.{" "}
                      </span>
                    )}
                    <BlockElement
                      key={element.id}
                      element={element}
                      hideStepNumber={hideStepNumber}
                      updateResponse={updateResponse}
                      hideResponseInfo={props.hideResponseInfo}
                      blockResponseUpdate={props.blockResponseUpdate}
                    />
                  </React.Fragment>
                )
              })}
            {sections &&
              sections.map((section, sectionIndex) => (
                <div key={sectionIndex}>
                  <CollapsibleSection
                    title={section.title}
                    defaultOpen={sections.length <= 5}>
                    {section.elements.map((element) => {
                      const hideStepNumber = [
                        IBlockElementTypeEnum.Heading,
                        IBlockElementTypeEnum.Paragraph,
                      ].includes(element.element_type)

                      if (element.element_type === IBlockElementTypeEnum.Heading)
                        stepNumber = 0

                      if (!hideStepNumber) stepNumber++

                      return (
                        <React.Fragment key={element.id}>
                          {hideStepNumber ? (
                            <span />
                          ) : (
                            <span className="mt-3.5 text-sm font-medium text-gray-600">
                              {stepNumber}.{" "}
                            </span>
                          )}
                          <BlockElement
                            key={element.id}
                            element={element}
                            hideStepNumber={hideStepNumber}
                            updateResponse={updateResponse}
                            hideResponseInfo={props.hideResponseInfo}
                            blockResponseUpdate={props.blockResponseUpdate}
                          />
                        </React.Fragment>
                      )
                    })}
                  </CollapsibleSection>
                </div>
              ))}
          </>
        )}
      </div>
    </div>
  )
}

export default BlockGroup
