import { IViewDataTypeEnum } from "@elara/db"
import { Data } from "@elara/select"
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from "@hello-pangea/dnd"
import { FilteredTreeLike, Tree } from "@utils/tree"
import { useCallback, useEffect, useMemo } from "react"

import ScrollArea from "../scroll-area"
import { UseDataViewReturnType } from "./data-view.hooks"
import { Column, GroupOrderType } from "./data-view-types"

type DataViewKanbanProps<D extends Data, Id extends string, Options extends {}> = {
  id: string
  dataType: IViewDataTypeEnum
  columnDef: Column<D, Id, Options>
  dataView: UseDataViewReturnType<D, Id>

  // Callbacks
  onCardClick: (row: FilteredTreeLike<Tree<D>>) => void
  cardItem?: (data: D, options?: Options) => React.ReactNode
  onDragDrop?: (key: keyof D, row: D, sourceId: string, targetId: string) => void
}

const DataViewKanban = <D extends Data, Id extends string, Options extends {}>(
  props: DataViewKanbanProps<D, Id, Options>
) => {
  const groupOrder = useMemo<GroupOrderType[]>(() => {
    const groupById = props.dataView.config.groupBy
    const kanbanConfig = props.dataView.config.kanbanConfig
    const groupOrder = groupById && kanbanConfig && kanbanConfig[groupById]

    return groupOrder ?? []
  }, [props.dataView.config.groupBy, props.dataView.config.kanbanConfig])

  const onDragEnd: OnDragEndResponder = useCallback(
    (result) => {
      const sourceId = result.source.droppableId
      const targetId = result.destination?.droppableId
      const sourceIndex = result.source.index

      if (props.dataView.data.type === "ungrouped") return null

      const key = props.columnDef.key
      const group = props.dataView.data.groups.find((x) => x.groupId === sourceId)
      const item = group?.items[sourceIndex]

      if (props.onDragDrop && item && key && targetId && sourceId !== targetId) {
        props.onDragDrop(key, item, sourceId, targetId)
      }
    },
    [props.dataView.data]
  )

  // Set Kanban Group Order on first render, if not set already
  useEffect(() => {
    if (
      props.dataView.config.groupBy &&
      props.dataView.data.type === "grouped" &&
      groupOrder.length === 0
    ) {
      props.dataView.updateKanban({
        ...props.dataView.config.kanbanConfig,
        [props.dataView.config.groupBy]: props.dataView.data.groups.map((col) => {
          return {
            hidden: false,
            groupId: col.groupId,
            label: col.labelText && col.labelText?.length > 0 ? col.labelText : col.label,
          }
        }),
      })
    }
  }, [groupOrder, props.dataView.data, props.dataView.config.groupBy])

  return (
    <div className="mx-3 flex h-full flex-col gap-3">
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="flex gap-4 overflow-x-scroll pb-6">
          {groupOrder
            .filter((g) => !g.hidden)
            .map((group) => {
              const columnGroup =
                props.dataView.data.type === "grouped"
                  ? props.dataView.data.groups.find((c) => c.groupId === group.groupId)
                  : undefined

              if (!columnGroup) return null

              return (
                <Droppable
                  type="column"
                  key={columnGroup.groupId}
                  droppableId={columnGroup.groupId || "None"}>
                  {(provided, _snapshot) => (
                    <div
                      ref={provided.innerRef}
                      className="flex min-w-[250px] max-w-[250px] flex-col rounded border border-gray-100 bg-gray-50 px-2">
                      <div className="flex items-center justify-between py-3 text-sm font-medium">
                        <div className="min-w-0 truncate">{columnGroup.label}</div>
                        <div className="ml-2 rounded-full bg-gray-600 px-2 py-0.5 text-xs text-white">
                          {columnGroup.items.length}
                        </div>
                      </div>

                      <ScrollArea vertical viewportAsChild>
                        {columnGroup.items.map((item, index) => {
                          const id = item["id"] as string

                          return (
                            <Draggable
                              index={index}
                              key={`${columnGroup.groupId}/${id}`}
                              draggableId={`${columnGroup.groupId}/${id}`}
                              isDragDisabled={
                                props.columnDef.kanbanView?.disableDragging ?? false
                              }>
                              {(provided, _snapshot) => (
                                <div
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  className="my-1 first:mt-0"
                                  onClick={() => props.onCardClick(item)}>
                                  {props.cardItem && props.cardItem(item)}
                                </div>
                              )}
                            </Draggable>
                          )
                        })}

                        {provided.placeholder}
                      </ScrollArea>
                    </div>
                  )}
                </Droppable>
              )
            })}
        </div>
      </DragDropContext>
    </div>
  )
}

export default DataViewKanban
