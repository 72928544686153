// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IInsertCustomerFeedbackMutationVariables = Types.Exact<{
  text: Types.Scalars['String']['input'];
  uploadIds: Types.Scalars['jsonb']['input'];
}>;


export type IInsertCustomerFeedbackMutation = { insert_customer_feedback: { __typename: 'customer_feedback_mutation_response', affected_rows: number } | null };


export const InsertCustomerFeedbackDocument = gql`
    mutation InsertCustomerFeedback($text: String!, $uploadIds: jsonb!) {
  insert_customer_feedback(objects: [{text: $text, upload_ids: $uploadIds}]) {
    affected_rows
  }
}
    `;

export function useInsertCustomerFeedbackMutation() {
  return Urql.useMutation<IInsertCustomerFeedbackMutation, IInsertCustomerFeedbackMutationVariables>(InsertCustomerFeedbackDocument);
};