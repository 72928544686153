import { textForAssetStateVariantLabel } from "@components/asset/asset-state-variant-label"
import { IAssetStateLogFragment } from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { formatDate } from "@utils/date"
import { differenceInMinutes } from "date-fns"
import { Options, parse } from "json2csv"

type AssetDowntimeExportData = Pick<
  IAssetStateLogFragment,
  "asset" | "started_at" | "ended_at" | "asset_state_variant"
> & { downtime_in_operating_hours?: number | null }

export function exportAssetDowntimeToCSV(data: AssetDowntimeExportData[]) {
  const fields: Options<AssetDowntimeExportData>["fields"] = [
    {
      label: i18n.t("assets:state.fields.started_at"),
      value: (row: AssetDowntimeExportData) => formatDate(new Date(row.started_at), "Pp"),
    },
    {
      label: i18n.t("assets:state.fields.ended_at"),
      value: (row: AssetDowntimeExportData) =>
        row.ended_at ? formatDate(new Date(row.ended_at), "Pp") : "",
    },
    {
      label: i18n.t("common:asset_one"),
      value: (row: AssetDowntimeExportData) => row.asset?.name,
    },
    {
      label: i18n.t("consumables:fields.public_id"),
      value: (row: AssetDowntimeExportData) => row?.asset?.public_id,
    },

    {
      label: i18n.t("assets:fields.state"),
      value: (row: AssetDowntimeExportData) =>
        textForAssetStateVariantLabel(row.asset_state_variant),
    },

    {
      label:
        i18n.t("assets:state.fields.duration") +
        " (" +
        i18n.t("calendar:tokens.minute_other") +
        ")",
      value: (row: AssetDowntimeExportData) => {
        if (typeof row.downtime_in_operating_hours === "number") {
          return Math.trunc(row.downtime_in_operating_hours * 60)
        }
        return row.ended_at
          ? differenceInMinutes(new Date(row.ended_at), new Date(row.started_at))
          : ""
      },
    },
  ]

  return parse(data, { fields })
}
