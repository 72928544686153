import { AssetArchivedReplacedTag } from "@components/asset"
import { AssetInformationFormDialog } from "@components/asset/asset-information-form-dialog"
import AssetStateFormDialog from "@components/asset/asset-state-form-dialog"
import { ActionMenu, ActionMenuItem, Button } from "@components/shared"
import CollapsibleTabs from "@components/shared/collapsible-tabs"
import { Dialog, DialogClose, DialogContentFooter } from "@components/shared/dialog"
import Tabs from "@components/shared/horizontal-tabs"
import Image from "@components/shared/image"
import LoadingSpinner from "@components/shared/loading-spinner"
import { TextInput } from "@components/shared/text-input"
import toast from "@components/shared/toast"
import TouchTargetSize from "@components/shared/touch-target-size"
import { UserFavorite } from "@components/shared/user-favorite"
import { useFeature } from "@contexts/feature-flag-context"
import { openModal } from "@contexts/modal-context"
import {
  useAssetDetailsQuery,
  useDeleteAssetMutation,
} from "@graphql/documents/asset.generated"
import { IAssetDetailFragment } from "@graphql/documents/fragments.generated"
import { IPermissionScopeEnum, useDisclosure, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { useArchiveAsset, useCopyAsset } from "@pages/object/detail/asset-detail.hooks"
import {
  Alarm,
  Archive,
  CaretRight,
  ChartLine,
  ClockClockwise,
  Copy,
  DotsThreeVertical,
  Gauge,
  House,
  Info,
  Nut,
  PencilSimpleLine,
  Plus,
  Rows,
  Shapes,
  Swap,
  TrashSimple,
} from "@phosphor-icons/react"
import { LinkWithBackgroundLocation } from "@utils/location"
import { useState } from "react"
import React from "react"
import { Trans } from "react-i18next"
import {
  Navigate,
  Outlet,
  useLocation,
  useMatch,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom"

type PageHeaderProps = {
  asset: IAssetDetailFragment
}

const PageHeader = (props: PageHeaderProps) => {
  const { asset } = props

  const location = useLocation()

  const copyAsset = useCopyAsset({
    assetId: props.asset.id,
    assetName: props.asset.name,
  })

  const isArchived = !!asset.archived_at
  const archiveAsset = useArchiveAsset({ assetId: asset.id, assetName: asset?.name ?? "" })
  const deleteAsset = useDisclosure()
  const createAssetScope = usePermissionScope(IPermissionScopeEnum.AppAssetCreate)
  const editAssetScope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)
  const archiveAssetScope = usePermissionScope(IPermissionScopeEnum.AppAssetArchiveDelete)
  const deleteAssetScope = usePermissionScope(IPermissionScopeEnum.AppAssetArchiveDelete)
  const editAsset = useDisclosure()

  const actionMenuItems: (ActionMenuItem | null)[] = []

  if (isArchived && editAssetScope.hasScope) {
    actionMenuItems.push({
      key: "reactivate",
      icon: <ClockClockwise />,
      label: i18n.t("common:actions.reactivate"),
      action: archiveAsset.reactivate,
    })
    actionMenuItems.push({
      key: "replace",
      icon: <Swap />,
      label: i18n.t("assets:context_menu.replace"),
      action: archiveAsset.replaceAsset,
    })
  } else {
    actionMenuItems.push(
      editAssetScope.hasScope
        ? {
            key: "edit",
            icon: <PencilSimpleLine />,
            label: i18n.t("common:edit"),
            action: editAsset.onOpen,
          }
        : null,
      createAssetScope.hasScope
        ? {
            key: "copy",
            icon: <Copy />,
            label: i18n.t("common:actions.duplicate"),
            action: copyAsset.copy,
          }
        : null,
      archiveAssetScope.hasScope
        ? {
            key: "archive",
            icon: <Archive />,
            label: i18n.t("common:actions.archive"),
            action: archiveAsset.archive,
          }
        : null
    )
  }
  actionMenuItems.push(
    deleteAssetScope.hasScope
      ? {
          key: "delete",
          icon: <TrashSimple />,
          label: i18n.t("common:delete"),
          action: deleteAsset.onOpen,
        }
      : null
  )

  const [inputValue, setInputValue] = useState("")
  const [, deleteAssetMutation] = useDeleteAssetMutation()
  const deleteAssetOnClick = async () => {
    if (inputValue.trim() !== props.asset.name.trim()) return
    const res = await deleteAssetMutation(
      {
        assetId: props.asset.id,
      },
      deleteAssetScope.context()
    )
    if (res.error)
      toast.error(
        i18n.t("common:messages.token_delete_failure", {
          token: props.asset.name,
        })
      )
    else {
      toast.success(
        i18n.t("common:messages.token_delete_success", {
          token: props.asset.name,
        })
      )
    }
    if (res.data?.delete_asset_by_pk) {
      deleteAsset.onClose()
    }
  }

  return (
    <div className="bg-gray-50 p-3 @container">
      <div className="flex flex-wrap items-center gap-y-3">
        <div className="mr-3">
          <Image
            src={asset.avatar?.thumbnail_url}
            height={48}
            width={48}
            containerClasses="rounded overflow-hidden border border-gray-200"
            preview={{ src: asset?.avatar?.url }}
            placeholder={
              <div className="flex h-12 w-12 items-center justify-center bg-gray-50 text-gray-300">
                <Shapes size={24} />
              </div>
            }
          />
        </div>
        <div>
          <div className="flex flex-row flex-wrap items-center gap-x-1">
            {asset.parents &&
              asset.parents.map((parent) => (
                <React.Fragment key={parent.id}>
                  <LinkWithBackgroundLocation
                    to={`/object/${parent.id}/` + (location.pathname.split("/")?.[3] ?? "")}
                    className="relative flex min-w-0 shrink items-center rounded p-1 text-xs text-gray-600 first:pl-0 hover:bg-gray-100 hover:text-gray-800">
                    {parent.name}
                    <TouchTargetSize />
                  </LinkWithBackgroundLocation>
                  <CaretRight className="-mx-1 text-gray-500" />
                </React.Fragment>
              ))}
          </div>
          <div className="flex items-center">
            <span className="font-medium leading-none @mobile/page:w-auto @mobile/page:text-xl @mobile/page:font-semibold @mobile/page:leading-none">
              {asset.name}
              <span className="ml-1 font-normal text-gray-500">{asset.public_id}</span>
            </span>
            <div className="ml-1 inline-flex items-center">
              <UserFavorite assetId={asset.id} />
            </div>
          </div>
        </div>

        <div className="flex w-full space-x-3 @mobile/page:ml-auto @mobile/page:w-auto">
          <AssetStateFormDialog assetId={props.asset.id} withEndedAt followUpOption>
            <Button
              icon={Alarm}
              type="primary"
              className="flex-1 text-xs @md/page:text-sm @mobile/page:flex-auto">
              {i18n.t("assets:state.actions.add_downtime")}
            </Button>
          </AssetStateFormDialog>
          <Button
            icon={Plus}
            type="primary"
            className="flex-1 text-xs @md/page:text-sm @mobile/page:flex-auto"
            onClick={() => {
              openModal("select_template", {
                initialTaskValues: { asset_ids: [asset.id] },
              })
            }}>
            {i18n.t("tasks:labels.new_task")}
          </Button>
          <ActionMenu items={actionMenuItems}>
            <Button size="small" type="secondary" icon={DotsThreeVertical} />
          </ActionMenu>
        </div>

        {archiveAsset.archiveConfirmModal}
        {archiveAsset.reactivateConfirmModal}
        {copyAsset.copyConfirmModal}
        <AssetInformationFormDialog
          asset={asset}
          isOpen={editAsset.isOpen}
          onOpenChange={editAsset.changeOpen}
        />
      </div>

      {isArchived && (
        <AssetArchivedReplacedTag
          archivedAt={asset.archived_at}
          replacedBy={asset.replaced_by}
          replacementOf={asset.replacement_of}
        />
      )}
      {
        <>
          <Dialog
            contentAsChild
            className="max-w-md"
            title={i18n.t("assets:dialogs.delete_asset.title", {
              name: props.asset.name,
            })}
            isOpen={deleteAsset.isOpen}
            onOpenChange={deleteAsset.toggle}>
            <p className="pt-3 text-sm text-gray-600">
              <Trans
                i18nKey={"assets:dialogs.delete_asset.confirm_1"}
                i18n={i18n}
                values={{ name: props.asset.name }}
                components={{ strong: <span className="font-medium" /> }}
              />
            </p>
            <p className="mt-3 pb-3 text-sm text-gray-600">
              <Trans
                i18nKey={"assets:dialogs.delete_asset.confirm_2"}
                i18n={i18n}
                values={{ name: props.asset.name }}
                components={{ strong: <span className="font-medium" /> }}
              />
            </p>
            <TextInput
              placeholder={props.asset.name.trim()}
              className="mb-3"
              defaultValue=""
              onChange={(e) => setInputValue(e.target.value)}
            />
            {
              <DialogContentFooter className="flex justify-end space-x-3">
                <DialogClose asChild>
                  <Button onClick={deleteAsset.onClose} type="secondary">
                    {i18n.t("common:cancel")}
                  </Button>
                </DialogClose>
                <Button
                  color="red"
                  disabled={inputValue.trim() !== props.asset.name.trim()}
                  onClick={deleteAssetOnClick}>
                  {i18n.t("common:delete")}
                </Button>
              </DialogContentFooter>
            }
          </Dialog>
        </>
      }
    </div>
  )
}

type OutletContextType = { asset: IAssetDetailFragment }

export const useAsset = () => {
  return useOutletContext<OutletContextType>()
}

export const AssetDetailPage = () => {
  const { id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const match = useMatch("/object/:id/:page/*")
  const page = match?.params.page ?? "overview"

  const [assetDetailResult] = useAssetDetailsQuery({
    variables: { id: id! },
    pause: !id,
  })
  const asset = assetDetailResult.data?.asset_by_pk
  const hasMaterialsFeature = useFeature("materials")
  const hasMetersFeature = useFeature("meters")

  if (assetDetailResult.fetching) return <LoadingSpinner />
  if (assetDetailResult.error || !asset) return <Navigate to="/asset" replace />

  return (
    <div className="flex min-h-0 min-w-0 flex-1 flex-col @container/page">
      <PageHeader asset={asset} />
      <Tabs.Root
        orientation="horizontal"
        onValueChange={(val) => navigate(val, { state: location.state })}
        value={page}
        className="flex min-h-0 min-w-0 flex-1 flex-col bg-gray-50">
        <CollapsibleTabs.List
          triggers={[
            {
              value: "overview",
              label: i18n.t("assets:tabs.overview"),
              icon: <House />,
            },
            {
              value: "info",
              label: i18n.t("common:information", { count: 2 }),
              icon: <Info />,
            },
            {
              value: "tasks",
              label: i18n.t("common:task", { count: 2 }),
              icon: <Rows />,
            },
            {
              value: "children",
              label: i18n.t("assets:fields.subasset", { count: 2 }),
              icon: <Shapes />,
            },
            {
              value: "materials",
              label: i18n.t("common:consumable", { count: 2 }),
              icon: <Nut />,
            },
            {
              value: "meters",
              label: i18n.t("common:meter", { count: 2 }),
              icon: <Gauge />,
            },
            {
              value: "insights",
              label: i18n.t("common:insight_other"),
              icon: <ChartLine />,
            },
          ].filter((t) => {
            if (!hasMaterialsFeature && t.value === "materials") {
              return false
            }
            if (!hasMetersFeature && t.value === "meters") {
              return false
            }
            return true
          })}
        />

        <div className="flex min-h-0 min-w-0 flex-1 flex-col bg-white">
          <Outlet context={{ asset }} />
        </div>
      </Tabs.Root>
    </div>
  )
}

export default AssetDetailPage
