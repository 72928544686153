import { Data, hasuraWhere, True, Where } from "@elara/select"
import { StickyType, useCallbackRef, useDeepMemo } from "@hooks"
import { unique } from "@utils"
import { findTreeList } from "@utils/tree"
import { dequal } from "dequal"
import { Dispatch, ReactNode, useCallback, useMemo, useState } from "react"
import { Client } from "urql"

import { FilterState, SelectItem, SelectState, SingletonState } from "./data-view-types"

export function selectedItemsToWhere<V>(
  selectedItems: V[],
  negated?: boolean,
  options: { emptyIsTrue: boolean } = { emptyIsTrue: true }
) {
  if (selectedItems.length == 0 && options.emptyIsTrue) return {} as True
  if (negated) {
    if (selectedItems.length == 1) {
      return { _neq: selectedItems[0] }
    }
    return { _nin: selectedItems }
  } else {
    if (selectedItems.length == 1) {
      return { _eq: selectedItems[0] }
    }
    return { _in: selectedItems }
  }
}

export function selectStateToWhere<V>(state: SelectState<V>) {
  const { selectedValues } = state

  if (!selectedValues || selectedValues.length == 0) return {} as True
  if (state.negated) {
    if (selectedValues.length == 1) {
      return { _neq: selectedValues[0] }
    }
    return { _nin: selectedValues }
  } else {
    if (selectedValues.length == 1) {
      return { _eq: selectedValues[0] }
    }
  }

  return { _in: selectedValues }
}

function makeNested(path: string[], value: any) {
  const obj: Record<string, any> = {}
  let current = obj
  path.forEach((p, idx) => {
    let nextCurrent = idx === path.length - 1 ? value : {}
    current[p] = nextCurrent
    current = nextCurrent
  })
  return obj
}

export function generalSelectStateToWhere<V extends string, K = null>(
  path: string[],
  state: SelectState<V | K>,
  options: {
    isOneToMany?: boolean
    nothingSelectedValue?: K
    operator?: "_in" | "_has_keys_any"
  } = {}
) {
  const nothingSelectedValue = (options.nothingSelectedValue ?? null) as K
  const hasNothingSelectedValue =
    state.selectedValues.some((v) => v === nothingSelectedValue) ||
    state.selectedValues.length === 0
  const nonNothingSelectedValue = state.selectedValues.filter(Boolean) as V[]
  const op = options.operator ?? "_in"

  let result: Record<string, any> = {}
  if (options.isOneToMany) {
    if (hasNothingSelectedValue) {
      result = {
        _or: [
          makeNested(path, { [op]: nonNothingSelectedValue }),
          { _not: makeNested(path, {}) },
        ],
      }
    } else {
      result = makeNested(path, { [op]: nonNothingSelectedValue })
    }
  } else {
    if (hasNothingSelectedValue) {
      result = {
        _or: [{ _is_null: true }, { [op]: nonNothingSelectedValue }].map((c) =>
          makeNested(path, c)
        ),
      }
    } else {
      result = makeNested(path, { [op]: nonNothingSelectedValue })
    }
  }

  if (state.negated) {
    return { _not: result }
  }

  return result
}

// Schema
export type BaseSchemaStatement<S, HS, D extends Data> = {
  icon?: ReactNode
  label: string
  operatorLabel?: (negated: boolean, multiple: boolean, state: S) => string
  toWhere: (state: HS) => Where<D>
}
export type SelectSchemaStatement<D extends Data, V = unknown> = BaseSchemaStatement<
  SelectState<V>,
  SelectState<V>,
  D
> & {
  type: "select"
  isGrouped?: boolean
  getItems: (client: Client) => Promise<SelectItem<V>[] | null>
  multiSelectedLabel?: string
  onCustomClick?: (selectedValue: V) => Promise<SelectItem<V>>
  singleSelectOnly?: boolean
  valueToItem?: (value: V, items: SelectItem<V>[]) => SelectItem<V> | null
  valueToString?: (value: V) => string
}
export type SingletonSchemaStatement<D extends Data> = {
  type: "singleton"
  badgeLabel: string
} & BaseSchemaStatement<SingletonState, SingletonState, D>

export type SchemaStatement<D extends Data, V = unknown> =
  | SelectSchemaStatement<D, V>
  | SingletonSchemaStatement<D>

export type Schema<D extends Data> = Record<string, SchemaStatement<D>>

export function createSchema<D extends Data>(schema: Schema<D>): Schema<D> {
  return schema
}
export function createSchemaStatement<D extends Data, V = unknown>(
  schemaStatement: SchemaStatement<D, V>
): SchemaStatement<D> {
  return schemaStatement as SchemaStatement<D>
}

export function defaultValueToString<V = unknown>(value: V): string {
  if (value === null || value === undefined) {
    return ""
  }

  if (typeof value === "string") {
    return value
  }

  throw new Error(
    "Default for `valueToString` expected a string as type of value. Please provide `valueToString` in the schema statement."
  )
}

export function defaultValueToItem<V = unknown>(
  value: V,
  items: SelectItem<V>[]
): SelectItem<V> | null {
  return findTreeList(items, (item) => dequal(item.value, value)) ?? null
}

export function whereStatementFromStates<D extends Data>(
  schema: Schema<D>,
  states: FilterState[]
): Where<D> {
  return {
    _and: states
      .map((state) => {
        const s = schema[state.id]
        if (s && state.type === "select" && s.type === "select") {
          return s.toWhere(state)
        } else if (s && state.type === "singleton" && s.type === "singleton") {
          return s.toWhere(state)
        }
        return null
      })
      .filter(Boolean),
  } as Where<D>
}

export function hasuraWhereStatementFromStates<D extends Data>(
  schema: Schema<D>,
  states: FilterState[]
): Where<D> {
  return hasuraWhere<D>(whereStatementFromStates(schema, states))
}

export type ViewFilterState = {
  addedFilterFromCurrentOpenMenu: boolean
}

type ViewFilterAction =
  | { type: "removeFilterState"; index: number }
  | { type: "updateFilterState"; index: number; state: FilterState }
  | { type: "addFilterState"; state: FilterState }
  | { type: "setFilterStates"; states: FilterState[] }
  | { type: "clearFilter" }
  | { type: "closeMenu" }

export type UseDataViewFilterReturnType<D extends Data> = {
  schema: Schema<D>
  state: ViewFilterState
  hasFiltersAdded: boolean
  dispatch: Dispatch<ViewFilterAction>
  whereStatement: Where<D> | null
  fixedWhereStatement: Where<D> | null
  stateWhereStatement: Where<D> | null
  configFilters: FilterState[]
  filterStates: FilterState[]
  clearFilter: () => void
}

export function useDataViewFilter<D extends Data>(
  filterStates: FilterState[],
  onFilterStateChange: (filters: FilterState[]) => void,
  options: {
    schema: Schema<D>
    hiddenFixedFilters: FilterState[]
    fixedFilters: FilterState[]
    filterStickyness?: StickyType
    customConfigWhere: Where<D> | null
  }
): UseDataViewFilterReturnType<D> {
  const customWhere = useDeepMemo(
    () => (options.customConfigWhere ?? {}) as Where<D>,
    [options.customConfigWhere]
  )
  const fixedFilters = useDeepMemo(
    () => options.hiddenFixedFilters.concat(options.fixedFilters),
    [options.hiddenFixedFilters, options.fixedFilters]
  )

  const whereStatement = useMemo(() => {
    const filters = fixedFilters.concat(filterStates)
    if (!filters.length) return null
    return {
      _and: [whereStatementFromStates(options.schema, filters), customWhere],
    } as Where<D>
  }, [options.schema, filterStates, fixedFilters, customWhere])

  const fixedWhereStatement = useMemo(() => {
    if (!fixedFilters.length) return null
    return {
      _and: [whereStatementFromStates(options.schema, fixedFilters), customWhere],
    } as Where<D>
  }, [options.schema, fixedFilters, customWhere])

  const stateWhereStatement = useMemo(() => {
    if (!filterStates.length) return null
    return whereStatementFromStates(options.schema, filterStates)
  }, [options.schema, filterStates])

  const configFilters = useDeepMemo(
    () => unique(options.fixedFilters.concat(filterStates)),
    [filterStates, options.fixedFilters]
  )

  const [state, setState] = useState({ addedFilterFromCurrentOpenMenu: false })

  const dispatch = useCallbackRef((action: ViewFilterAction) => {
    switch (action.type) {
      case "updateFilterState":
        const updatedFilterStates = filterStates.slice()
        updatedFilterStates[action.index] = action.state
        onFilterStateChange(updatedFilterStates)
        break
      case "removeFilterState":
        onFilterStateChange(
          filterStates.slice(0, action.index).concat(filterStates.slice(action.index + 1))
        )
        break
      case "addFilterState":
        setState({ addedFilterFromCurrentOpenMenu: true })
        onFilterStateChange(filterStates.concat(action.state))
        break
      case "clearFilter":
        onFilterStateChange([])
        break
      case "closeMenu":
        setState({ addedFilterFromCurrentOpenMenu: false })
        break
      case "setFilterStates":
        onFilterStateChange(action.states)
        break
      default:
        throw new Error("Unexpected action")
    }
  })

  const clearFilter = useCallback(() => dispatch({ type: "clearFilter" }), [])

  const hasFiltersAdded = filterStates.length > 0

  return {
    schema: options.schema,
    state,
    dispatch,
    filterStates,
    whereStatement,
    fixedWhereStatement,
    stateWhereStatement,
    configFilters,
    clearFilter,
    hasFiltersAdded,
  }
}
