import { generateRRuleObject } from "@components/maintenance/utils"
import {
  Frequency,
  IMaintenanceTriggerTypeEnum,
  TimeIntervalTriggerOptions,
} from "@elara/db"
import { EventInput } from "@fullcalendar/core"
import { IMaintenanceTriggerFragment } from "@graphql/documents/fragments.generated"
import { getUTCAsLocalDateTime } from "@utils/tzdate"
import { max } from "date-fns"

export function getCalendarScheduledMaintenanceEvents(
  triggers: Omit<
    IMaintenanceTriggerFragment,
    "meter" | "meter_unit" | "meter_last_reading"
  >[],
  from: Date,
  to: Date
) {
  return (
    triggers
      .filter((t) => t.type === IMaintenanceTriggerTypeEnum.Time)
      .flatMap((t) => {
        const payload = t.payload as TimeIntervalTriggerOptions["payload"]
        // Don't show hourly events
        if (payload.repetition.frequency == Frequency.hourly) return []
        const rrule = generateRRuleObject(payload)

        const nowDate = payload.nextAt ? new Date(payload.nextAt) : new Date()
        const dates = rrule
          .between(max([nowDate, from]), to, false, (_, k) => k < 100)
          .map((d) => getUTCAsLocalDateTime(d).toDate())

        return dates.map(
          (d) =>
            ({
              groupId: t.maintenance_id,
              borderColor: "transparent",
              backgroundColor: "transparent",
              id: "maintenance_trigger:" + t.id + ":" + d.toISOString(),
              editable: false,
              start: d,
              extendedProps: { trigger: t },
            } satisfies EventInput)
        )
      }) ?? []
  )
}
