import { Button } from "@components/shared"
import ScrollArea from "@components/shared/scroll-area"
import { WorkOrderNumber } from "@components/work-order/work-order-number"
import { WorkOrderStatusTagWithDropdown } from "@components/work-order/work-order-status"
import { useOrderBy } from "@elara/select"
import { IScheduledTaskFragment } from "@graphql/documents/fragments.generated"
import { useDisclosure } from "@hooks"
import { PencilSimpleLine } from "@phosphor-icons/react"
import { getFormattedDate, parseDate } from "@utils"
import classNames from "classnames"
import { useState } from "react"
import { Link } from "react-router-dom"

import CreateEditScheduledTaskForm from "./detail/scheduled-task/create-edit-scheduled-task-form"

type AssetMeterMaintenanceListProps = {
  data: IScheduledTaskFragment[] | null
}

export const AssetMeterMaintenanceList = (props: AssetMeterMaintenanceListProps) => {
  const [editTask, setEditTask] = useState<IScheduledTaskFragment>()

  const { isOpen, changeOpen } = useDisclosure({
    onClose: () => setEditTask(undefined),
  })

  const [orderedData] = useOrderBy(props.data ?? [], { created_at: "desc" })

  return (
    <div
      className={classNames(
        "bg-white overflow-hidden sm:rounded flex flex-1 flex-col min-h-0"
      )}>
      <ScrollArea vertical horizontal viewportAsChild>
        <table className="min-w-full border-separate" style={{ borderSpacing: 0 }}>
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50/75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur sm:pl-6 lg:pl-8">
                Messwert
              </th>
              <th
                scope="col"
                className="sticky top-0 z-10 table-cell border-b border-gray-300 bg-gray-50/75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur">
                Aufgabe
              </th>
              <th
                scope="col"
                className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50/75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur lg:table-cell">
                Erstellt am
              </th>
              <th
                scope="col"
                className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50/75 py-3.5 pl-3 pr-4 backdrop-blur sm:pr-6 lg:pr-8">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {orderedData.map((data, dataIdx) => (
              <tr key={data.id}>
                <td
                  className={classNames(
                    dataIdx !== orderedData.length - 1 ? "border-b border-gray-200" : "",
                    "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8 text-right w-1"
                  )}>
                  <span className="truncate font-semibold">
                    {data.scheduled_on}{" "}
                    <span className="font-medium">{data.meter.unit}</span>
                  </span>
                </td>
                <td
                  className={classNames(
                    dataIdx !== orderedData.length - 1 ? "border-b border-gray-200" : "",
                    "truncate px-2 py-3 text-sm text-gray-500"
                  )}>
                  <Link
                    to={"/work-order/" + data.task_id}
                    className="group hover:text-gray-800">
                    <div className="flex w-[50vw] min-w-0 items-center gap-x-2 rounded p-1 group-hover:!bg-gray-100 sm:w-auto">
                      <div
                        onTouchStart={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                        }}
                        onMouseDown={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                        }}>
                        <WorkOrderStatusTagWithDropdown
                          short
                          status={data.task?.status}
                          workOrder={data.task}
                        />
                      </div>
                      <WorkOrderNumber workOrderNumber={data.task?.work_order_number} />
                      <span className="min-w-0 truncate font-medium">
                        {data.task?.name}
                      </span>
                    </div>
                  </Link>
                </td>
                <td
                  className={classNames(
                    dataIdx !== orderedData.length - 1 ? "border-b border-gray-200" : "",
                    "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell"
                  )}>
                  {getFormattedDate(parseDate(data.created_at), { includeTime: true })}
                </td>

                <td
                  className={classNames(
                    dataIdx !== orderedData.length - 1 ? "border-b border-gray-200" : "",
                    "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                  )}>
                  <Button
                    size="small"
                    type="tertiary"
                    color="gray"
                    icon={PencilSimpleLine}
                    onClick={(e) => {
                      e.stopPropagation()

                      setEditTask(data)
                      changeOpen(true)
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ScrollArea>

      <CreateEditScheduledTaskForm
        isOpen={isOpen}
        onOpenChange={changeOpen}
        scheduledTask={editTask}
      />
    </div>
  )
}
