import MeterReadingTriggerForm from "@components/maintenance/components/triggers/meter-reading-trigger-form"
import TimeIntervalTriggerForm from "@components/maintenance/components/triggers/time-interval-trigger-form"
import {
  getDefaultMeterReadingTriggerOptions,
  getDefaultTimeIntervalTriggerOptions,
} from "@components/maintenance/types"
import { Button, TextArea } from "@components/shared"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from "@components/shared/dropdown"
import { FormField } from "@components/shared/form/form-field"
import RadioGroupPanel from "@components/shared/radio-group-panel"
import { TextInput } from "@components/shared/text-input"
import Toggle from "@components/shared/toggle"
import { IMaintenanceTriggerTypeEnum } from "@elara/db"
import { IMaintenanceFragment } from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { Plus } from "@phosphor-icons/react"
import { cn } from "@utils"
import { FieldArray, useFormikContext } from "formik"
import { Fragment, PropsWithChildren } from "react"

import { TriggerSeparator } from "./triggers/trigger-separator"

export const MaintenanceInnerForm = (
  props: PropsWithChildren<{
    editForm?: boolean
    className?: string
    onDiscard?: () => void
    handleDelete: (id: string | undefined, cb: () => void) => void
  }>
) => {
  const formik = useFormikContext<IMaintenanceFragment>()

  return (
    <div className={cn("max-w-2xl", props.className)}>
      <FormField name="name" label={i18n.t("maintenance:fields.name")}>
        <TextInput />
      </FormField>

      <FormField
        optional
        name="description"
        label={i18n.t("maintenance:fields.description")}>
        <TextArea />
      </FormField>

      <div className="space-y-2">
        <h3 className="text-sm font-medium text-gray-800">
          {i18n.t("maintenance:labels.options.title")}
        </h3>

        <FormField name="create_individual_tasks_for_objects">
          {({ field, helpers }) => (
            <div className="flex items-center gap-x-3">
              <Toggle
                checked={field.value}
                id="create_individual_tasks_for_objects"
                onChange={(event) => helpers.setValue(event.target.checked)}
              />
              <label
                htmlFor="create_individual_tasks_for_objects"
                className="max-w-[240px] text-xs">
                {i18n.t("maintenance:labels.options.create_individual_tasks_for_objects")}
              </label>
            </div>
          )}
        </FormField>
      </div>

      <div className="space-y-2">
        <h3 className="text-sm font-medium text-gray-800">
          {i18n.t("maintenance:fields.triggers")}
        </h3>

        <FieldArray name="triggers">
          {({ push, remove }) => {
            const triggers = formik.values.triggers ?? []

            const addTriggerBtn = (
              <DropdownMenu>
                {triggers.length > 0 && (
                  <DropdownMenuTrigger
                    className={cn({ "w-full": triggers.length === 0 })}
                    asChild>
                    <Button type="secondary" icon={Plus}>
                      {i18n.t("maintenance:messages.add_more_trigger")}
                    </Button>
                  </DropdownMenuTrigger>
                )}

                <DropdownMenuPortal>
                  <DropdownMenuContent side="top" align="start">
                    <DropdownMenuItem
                      className="font-normal"
                      onClick={() => push(getDefaultTimeIntervalTriggerOptions())}>
                      {i18n.t("maintenance:labels.triggers.time.title")}
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      className="font-normal"
                      onClick={() => push(getDefaultMeterReadingTriggerOptions())}>
                      {i18n.t("maintenance:labels.triggers.meter.title")}
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenuPortal>
              </DropdownMenu>
            )

            return (
              <>
                <div className={cn("space-y-6", { "mt-4": triggers.length > 0 })}>
                  {triggers.map((trigger, index) => (
                    <Fragment key={`${trigger.id}_${index}`}>
                      {index > 0 && <TriggerSeparator />}
                      {trigger.type === IMaintenanceTriggerTypeEnum.Time && (
                        <TimeIntervalTriggerForm
                          prefix={`triggers.${index}.payload`}
                          onDelete={() =>
                            props.handleDelete(trigger.id, () => remove(index))
                          }
                        />
                      )}

                      {trigger.type === IMaintenanceTriggerTypeEnum.Meter && (
                        <MeterReadingTriggerForm
                          prefix={`triggers.${index}.payload`}
                          onDelete={() =>
                            props.handleDelete(trigger.id, () => remove(index))
                          }
                        />
                      )}
                    </Fragment>
                  ))}
                </div>
                {!triggers.length && (
                  <div>
                    <p className="mb-2 text-sm text-gray-600">
                      {i18n.t("maintenance:labels.triggers.select_trigger")}
                    </p>
                    <RadioGroupPanel
                      onValueChange={(value) => {
                        if (value === "time") {
                          push(getDefaultTimeIntervalTriggerOptions())
                        } else {
                          push(getDefaultMeterReadingTriggerOptions())
                        }
                      }}
                      options={[
                        {
                          value: "time",
                          label: i18n.t("maintenance:labels.triggers.time.title"),
                          description: (
                            <p className="text-xs">
                              {i18n.t("maintenance:labels.triggers.time.description")}
                            </p>
                          ),
                        },
                        {
                          value: "meter",
                          label: i18n.t("maintenance:labels.triggers.meter.title"),
                          description: (
                            <ol className="text-xs">
                              {i18n.t("maintenance:labels.triggers.meter.description")}
                              <li className="ml-4 !list-outside">
                                {i18n.t(
                                  "maintenance:labels.triggers.meter.description_option_1"
                                )}
                              </li>
                              <li className="ml-4 !list-outside">
                                {i18n.t(
                                  "maintenance:labels.triggers.meter.description_option_2"
                                )}
                              </li>
                            </ol>
                          ),
                        },
                      ]}
                    />
                  </div>
                )}
                {props.editForm ? (
                  <div className="sticky bottom-0 mt-3 flex items-center justify-between rounded-lg border bg-gray-50 p-3">
                    {triggers.length ? addTriggerBtn : <div />}
                    <div className="space-x-3">
                      {props.onDiscard && (
                        <Button type="tertiary" color="gray" onClick={props.onDiscard}>
                          {i18n.t("common:discard")}
                        </Button>
                      )}
                      <Button
                        type="primary"
                        onClick={formik.submitForm}
                        disabled={formik.isSubmitting}
                        isLoading={formik.isSubmitting}>
                        {i18n.t("common:save")}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="mt-3">{addTriggerBtn}</div>
                )}
              </>
            )
          }}
        </FieldArray>
      </div>
    </div>
  )
}
