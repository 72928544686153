import { LogbookHistoryOption } from "@components/work-order/use-logbook-data"
import { getLocationTimeZone } from "@contexts/user-context"
import i18n from "@i18n"
import {
  CalendarDateTime,
  now,
  parseAbsolute,
  toCalendarDateTime,
} from "@internationalized/date"
import { ZonedDateTime } from "@internationalized/date"
import {
  add,
  addDays,
  addMonths,
  addYears,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  formatDistance,
  formatDuration as formatDurationFns,
  formatISO,
  getWeekOfMonth as getWeekOfMonthFns,
  Locale,
  set,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  sub,
} from "date-fns"
import deDE from "date-fns/locale/de"
import enAU from "date-fns/locale/en-AU"
import enUS from "date-fns/locale/en-US"
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz"
import { z } from "zod"

import { dateToCalendarDateTime } from "./tzdate"

export type DateRange = { start: Date; end?: Date }

export type DateRangeKey = string

export type DateRangeOption = { key: DateRangeKey; label: string; range: DateRange | null }

type NullableDate = Date | null | undefined

export const dateLocaleMap: Record<string, Locale> = {
  "en-AU": enAU,
  "en-US": enUS,
  "de-DE": deDE,
}

export const prettyDateFormat = "EEEE, MMMM do, yyyy"

export function formatDate(date: Date | null, formatString: string) {
  if (!date) return ""

  const timeZone = getLocationTimeZone()

  return formatInTimeZone(date, timeZone, formatString, {
    locale: dateLocaleMap[i18n.language],
  })
}

export function formatDateDistance(date: Date | null, baseDate: Date = new Date()) {
  if (!date) return ""
  return formatDistance(date, baseDate, {
    includeSeconds: true,
    locale: dateLocaleMap[i18n.language],
  })
}

export function formatDuration(
  duration: Duration,
  options?: {
    format?: string[]
    zero?: boolean
    delimiter?: string
    locale?: Locale
  }
) {
  return formatDurationFns(duration, { ...options, locale: dateLocaleMap[i18n.language] })
}

export function formatFractionalHours(hours: number) {
  const minutes = Math.round(hours * 60)
  const h = Math.trunc(minutes / 60)
  const m = minutes % 60
  if (m === 0) return `${h}h`
  return `${h}h ${m}m`
}

export function formatISODate(date: Date) {
  return formatISO(date, { representation: "date" })
}

type DateTimeRanges = "lastHour" | "today" | "yesterday" | "beforeYesterday"

type DateTimeFormatter = (interval: number) => string | null

export function formatDateTimeRanges(
  laterDate: NullableDate,
  earlierDate: NullableDate,
  formatters: Partial<Record<DateTimeRanges, DateTimeFormatter>> & {
    default: (date: Date) => string | null
  }
) {
  if (!laterDate || !earlierDate) return null

  const hoursInterval = differenceInHours(laterDate, earlierDate)
  if (formatters.lastHour && hoursInterval === 0)
    return formatters.lastHour(differenceInMinutes(laterDate, earlierDate))

  if (formatters.today && hoursInterval < 24) return formatters.today(hoursInterval)

  if (formatters.yesterday && hoursInterval < 48 && hoursInterval >= 24)
    return formatters.yesterday(hoursInterval)

  if (formatters.beforeYesterday && hoursInterval >= 48)
    return formatters.beforeYesterday(differenceInDays(laterDate, earlierDate))

  return formatters.default(earlierDate)
}

// A function to return an array of weeks names with their abbreviations
// Returns an array of ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"], for example if language is "en"
export function getAbbreviatedWeekNames(): string[] {
  const weeks = []
  const date = new Date()

  for (let i = 0; i < 7; i++) {
    weeks.push(
      formatDate(add(startOfWeek(date, { weekStartsOn: 1 }), { days: i }), "EEEEEE")
    )
  }

  return weeks
}

// If the date is invalid, the getTime() method will return a NaN (not a number) value
export const dateIsValid = (date: string) => {
  return !Number.isNaN(new Date(date).getTime())
}

export function setTimeFromDueTime(
  dueDate: Date | null,
  dueTime: string | null | undefined
) {
  if (!dueDate || !dueTime) return null
  const [hour, minute] = dueTime.split(":") as unknown as number[]
  return dateToCalendarDateTime(dueDate).set({ hour, minute }).toDate(getLocationTimeZone())
}

export function formatHistoryLengthOption(option: LogbookHistoryOption | undefined) {
  if (!option) return

  return i18n.t(`calendar:logbook_history.length.${option.value}`, { defaultValue: "" })
}

export function cloneTimeFromDate(left: Date, right: Date) {
  return set(left, { hours: right.getHours(), minutes: right.getMinutes() })
}

export const dateRangeOptions = (
  baseDate: Date = new Date(),
  options: { future?: boolean } = {}
): DateRangeOption[] => {
  if (options.future) {
    return [
      {
        key: "unlimited",
        label: i18n.t("unlimited"),
        range: {
          start: startOfDay(baseDate),
          end: endOfYear(addYears(baseDate, 100)),
        },
      },
      {
        key: "today",
        label: i18n.t("calendar:relative_dates.today"),
        range: {
          start: startOfDay(baseDate),
          end: endOfDay(baseDate),
        },
      },
      {
        key: "tomorrow",
        label: i18n.t("calendar:relative_dates.tomorrow"),
        range: {
          start: startOfDay(add(baseDate, { days: 1 })),
          end: endOfDay(add(baseDate, { days: 1 })),
        },
      },
      {
        key: "next-7-days",
        label: i18n.t("calendar:relative_dates.next_x_days", { count: 7 }),
        range: {
          start: startOfDay(baseDate),
          end: endOfDay(add(baseDate, { days: 6 })),
        },
      },
      {
        key: "next-30-days",
        label: i18n.t("calendar:relative_dates.next_x_days", { count: 30 }),
        range: {
          start: startOfDay(baseDate),
          end: endOfDay(add(baseDate, { days: 29 })),
        },
      },
      {
        key: "this-week",
        label: i18n.t("calendar:relative_dates.this_week"),
        range: {
          start: startOfWeek(baseDate),
          end: endOfWeek(baseDate),
        },
      },
      {
        key: "next-week",
        label: i18n.t("calendar:relative_dates.next_week"),
        range: {
          start: startOfWeek(add(baseDate, { weeks: 1 })),
          end: endOfWeek(add(baseDate, { weeks: 1 })),
        },
      },
      {
        key: "this-month",
        label: i18n.t("calendar:relative_dates.this_month"),
        range: {
          start: startOfMonth(baseDate),
          end: endOfMonth(baseDate),
        },
      },
      {
        key: "next-month",
        label: i18n.t("calendar:relative_dates.next_month"),
        range: {
          start: startOfMonth(add(baseDate, { months: 1 })),
          end: endOfMonth(add(baseDate, { months: 1 })),
        },
      },
    ]
  } else {
    return [
      {
        key: "beginning",
        label: i18n.t("calendar:relative_dates.since_beginning"),
        range: {
          start: startOfYear(sub(baseDate, { years: 3 })),
          end: endOfDay(baseDate),
        },
      },
      {
        key: "today",
        label: i18n.t("calendar:relative_dates.today"),
        range: {
          start: startOfDay(baseDate),
          end: endOfDay(baseDate),
        },
      },
      {
        key: "yesterday",
        label: i18n.t("calendar:relative_dates.yesterday"),
        range: {
          start: startOfDay(sub(baseDate, { days: 1 })),
          end: endOfDay(sub(baseDate, { days: 1 })),
        },
      },
      {
        key: "last-7-days",
        label: i18n.t("calendar:relative_dates.last_x_days", { count: 7 }),
        range: {
          start: startOfDay(sub(baseDate, { days: 6 })),
          end: endOfDay(baseDate),
        },
      },
      {
        key: "last-30-days",
        label: i18n.t("calendar:relative_dates.last_x_days", { count: 30 }),
        range: {
          start: startOfDay(sub(baseDate, { days: 29 })),
          end: endOfDay(baseDate),
        },
      },
      {
        key: "this-week",
        label: i18n.t("calendar:relative_dates.this_week"),
        range: {
          start: startOfWeek(baseDate),
          end: endOfWeek(baseDate),
        },
      },
      {
        key: "last-week",
        label: i18n.t("calendar:relative_dates.last_week"),
        range: {
          start: startOfWeek(sub(baseDate, { weeks: 1 })),
          end: endOfWeek(sub(baseDate, { weeks: 1 })),
        },
      },
      {
        key: "this-month",
        label: i18n.t("calendar:relative_dates.this_month"),
        range: {
          start: startOfMonth(baseDate),
          end: endOfMonth(baseDate),
        },
      },
      {
        key: "last-month",
        label: i18n.t("calendar:relative_dates.last_month"),
        range: {
          start: startOfMonth(sub(baseDate, { months: 1 })),
          end: endOfMonth(sub(baseDate, { months: 1 })),
        },
      },
    ]
  }
}

export function getNumberWeekday(date: Date): string {
  const dayOfMonth = date.getDate()
  const dayOfWeek = date.getDay()
  let weekOfMonth = Math.ceil(dayOfMonth / 7)
  let numberWeekday = ""

  switch (dayOfWeek) {
    case 1: // Monday
      numberWeekday = `${weekOfMonth}${getOrdinalSuffix(weekOfMonth)} ${i18n.t(
        "calendar:tokens.weekdays.monday"
      )}`
      break
    case 2: // Tuesday
      numberWeekday = `${weekOfMonth}${getOrdinalSuffix(weekOfMonth)} ${i18n.t(
        "calendar:tokens.weekdays.tuesday"
      )}`
      break
    case 3: // Wednesday
      numberWeekday = `${weekOfMonth}${getOrdinalSuffix(weekOfMonth)} ${i18n.t(
        "calendar:tokens.weekdays.wednesday"
      )}`
      break
    case 4: // Thursday
      numberWeekday = `${weekOfMonth}${getOrdinalSuffix(weekOfMonth)} ${i18n.t(
        "calendar:tokens.weekdays.thursday"
      )}`
      break
    case 5: // Friday
      numberWeekday = `${weekOfMonth}${getOrdinalSuffix(weekOfMonth)} ${i18n.t(
        "calendar:tokens.weekdays.friday"
      )}`
      break
    case 6: // Saturday
      numberWeekday = `${weekOfMonth}${getOrdinalSuffix(weekOfMonth)} ${i18n.t(
        "calendar:tokens.weekdays.saturday"
      )}`
      break
    case 0: // Sunday
      weekOfMonth -= 1
      numberWeekday = `${weekOfMonth}${getOrdinalSuffix(weekOfMonth)} ${i18n.t(
        "calendar:tokens.weekdays.sunday"
      )}`
      break
  }

  return numberWeekday
}

function getOrdinalSuffix(n: number): string {
  const { language } = i18n

  if (language === "de-DE") {
    return "."
  } else {
    if (n >= 11 && n <= 13) {
      return "th"
    }
    switch (n % 10) {
      case 1:
        return "st"
      case 2:
        return "nd"
      case 3:
        return "rd"
      default:
        return "th"
    }
  }
}

export function getWeekdayNameFromIsoDay(ISODay: 1 | 2 | 3 | 4 | 5 | 6 | 7): string {
  switch (ISODay) {
    case 1:
      return i18n.t("calendar:tokens.weekdays.monday")
    case 2:
      return i18n.t("calendar:tokens.weekdays.tuesday")
    case 3:
      return i18n.t("calendar:tokens.weekdays.wednesday")
    case 4:
      return i18n.t("calendar:tokens.weekdays.thursday")
    case 5:
      return i18n.t("calendar:tokens.weekdays.friday")
    case 6:
      return i18n.t("calendar:tokens.weekdays.saturday")
    case 7:
      return i18n.t("calendar:tokens.weekdays.sunday")
  }
}

export function getDateFromTimeString(timeString: string) {
  const [hours, minutes] = timeString.split(":").map(Number)
  const date = new Date()

  date.setHours(hours)
  date.setMinutes(minutes)
  date.setSeconds(0)
  date.setMilliseconds(0)

  return date
}

export function generateDateFromDueDateTime(
  dueDate: string | null,
  dueTime: string | null
) {
  const date = dueDate ? new Date(dueDate) : new Date()
  const hours = dueTime ? parseInt(dueTime.split(":")[0]) : 0
  const minutes = dueTime ? parseInt(dueTime.split(":")[1]) : 0

  return set(endOfDay(date), { hours, minutes })
}

export function zonedDateTimeFromDate(date: Date) {
  return zonedDateTime(date.toISOString())
}

export function zonedDateTime(isodate: string) {
  const tz = getLocationTimeZone()
  const dt = parseAbsolute(isodate, tz)
  return dt
}

export function zonedNow() {
  const tz = getLocationTimeZone()
  const dt = now(tz)
  return dt
}

export function zonedStartOfDay(dt: ZonedDateTime) {
  return dt.set({ millisecond: 0, second: 0, minute: 0, hour: 0 })
}
export function zonedEndOfDay(dt: ZonedDateTime) {
  return zonedStartOfDay(dt.add({ days: 1 })).subtract({ milliseconds: 1 })
}

export function zoned(date: Date) {
  const tz = getLocationTimeZone()
  return utcToZonedTime(date, tz)
}

// export function z(fn: (d: Date) => Date): (d: Date) => Date {
//   const tz = getLocationTimeZone()
//   return (d) => zonedTimeToUtc(fn(utcToZonedTime(d, tz)), tz)
// }

export function z2<T>(fn: (d: Date, arg: T) => Date): (d: Date, arg: T) => Date {
  const tz = getLocationTimeZone()
  return (d, arg) => zonedTimeToUtc(fn(utcToZonedTime(d, tz), arg), tz)
}

export function zonedDateFn(): (fn: (d: Date) => Date, d: Date) => Date {
  const tz = getLocationTimeZone()
  return (fn, d) => zonedTimeToUtc(fn(utcToZonedTime(d, tz)), tz)
}

export function interpretUTCAsZoned(utcd: Date): Date {
  const d = new CalendarDateTime(
    utcd.getUTCFullYear(),
    utcd.getUTCMonth() + 1,
    utcd.getUTCDate(),
    utcd.getUTCHours(),
    utcd.getUTCMinutes()
  )
  return d.toDate(getLocationTimeZone())
}

export function interpretZonedAsUTC(zdate: Date): Date {
  return toCalendarDateTime(
    parseAbsolute(zdate.toISOString(), getLocationTimeZone())
  ).toDate("UTC")
}

export function interpretZonedDateTimeAsUTC(zdate: ZonedDateTime): Date {
  return toCalendarDateTime(zdate).toDate("UTC")
}

export function getWeekOfMonth(date: Date): number {
  return getWeekOfMonthFns(date, { locale: dateLocaleMap[i18n.language], weekStartsOn: 1 })
}

export const futureDateValidator = z.custom((value) => {
  const startDate = new Date(value as string)
  const now = zonedNow().toDate()

  if (startDate <= now) {
    throw new Error("Start date must be in the future.")
  }

  return value
})

export const week = [
  ...Array.from({ length: 7 }).map((_, index) =>
    addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), index)
  ),
]

export const month = [
  ...Array.from({ length: 12 }).map((_, index) =>
    addMonths(startOfMonth(new Date(new Date().getFullYear(), 0, 1)), index)
  ),
]
