import { Button, useConfirmModal, UserTag } from "@components/shared"
import ScrollArea from "@components/shared/scroll-area"
import Table from "@components/shared/table"
import { Tooltip } from "@components/shared/tooltip"
import { IMeterTypeEnum, uuid } from "@elara/db"
import { useOrderBy } from "@elara/select"
import {
  IMeterFragment,
  IMeterReadingFragment,
} from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { PencilSimpleLine, TrashSimple, Warning } from "@phosphor-icons/react"
import { createColumnHelper } from "@tanstack/react-table"
import { getFormattedDate, parseDate } from "@utils"
import classNames from "classnames"
import React, { useMemo } from "react"

type AssetMeterReadingListProps = {
  data: IMeterReadingFragment[] | null
  meter: IMeterFragment
  onEdit: (meterReading: IMeterReadingFragment) => void
  onDelete: (meterId: uuid) => void
}

const helper = createColumnHelper<IMeterReadingFragment>()

export const AssetMeterReadingList = React.memo((props: AssetMeterReadingListProps) => {
  const [orderedData] = useOrderBy(props.data ?? [], { measured_at: "desc" })

  const columns = useMemo(
    () => [
      helper.accessor((row) => `${row.value} ${row.meter.unit}`, {
        id: "reading",
        header: () => i18n.t("meters:fields.value"),
        cell: (info) => {
          const isMeasurement = props.meter.meter_type === IMeterTypeEnum.Measurement
          let isOutsideRange = false
          if (props.meter.range_end && info.row.original.value > props.meter.range_end) {
            isOutsideRange = true
          } else if (
            props.meter.range_start &&
            info.row.original.value < props.meter.range_start
          ) {
            isOutsideRange = true
          }

          return (
            <span className="flex items-center font-medium">
              {info.getValue()}{" "}
              {isMeasurement && isOutsideRange && (
                <Tooltip
                  content={`Letzte Messung außerhalb des Toleranzbereiches.`}
                  contentProps={{ side: "top", align: "start" }}>
                  <Warning size={20} className="ml-2 text-yellow-500" />
                </Tooltip>
              )}{" "}
            </span>
          )
        },
      }),
      helper.accessor("measured_at", {
        header: () => i18n.t("meters:fields.measured_at"),
        cell: (info) => (
          <span className="text-gray-500">
            {getFormattedDate(parseDate(info.getValue()), { includeTime: true })}
          </span>
        ),
      }),
      helper.accessor("measured_by", {
        header: () => i18n.t("meters:fields.measured_by"),
        cell: (info) => <UserTag user={info.getValue()} />,
      }),
      helper.accessor((row) => row, {
        id: "actions",
        header: () => null,
        cell: (info) => (
          <>
            <Button
              color="gray"
              size="small"
              type="tertiary"
              icon={PencilSimpleLine}
              onClick={() => props.onEdit(info.getValue())}
            />
            <Button
              color="gray"
              icon={TrashSimple}
              size="small"
              type="tertiary"
              onClick={() => deleteModal.show(info.getValue().id)}
            />
          </>
        ),
      }),
    ],
    []
  )

  const deleteModal = useConfirmModal({
    title: i18n.t("common:delete_token", {
      token: i18n.t("meters:fields.reading", { count: 1 }),
    }),
    content: i18n.t("common:messages.token_delete_confirmation", {
      token: i18n.t("meters:fields.reading", { count: 1 }),
    }),
    okText: i18n.t("common:delete"),
    cancelText: i18n.t("common:cancel"),
    onOk: (id: uuid) => props.onDelete(id),
  })

  return (
    <ScrollArea vertical viewportAsChild>
      <div className={classNames("bg-white flex-1 sm:rounded flex flex-col min-h-0")}>
        <Table options={{ data: orderedData, columns }} />
        {deleteModal.component}
      </div>
    </ScrollArea>
  )
})
