import { Button } from "@components/shared"
import { DataViewTabs } from "@components/shared/data-view/data-view-tabs"
import { openModal } from "@contexts/modal-context"
import { IViewDataTypeEnum } from "@elara/db"
import i18n from "@i18n"
import { Plus } from "@phosphor-icons/react"

const ContactsPage = () => (
  <DataViewTabs
    dataType={IViewDataTypeEnum.Contact}
    title={i18n.t("common:contact", { count: 2 })}
    subtitle={i18n.t("contacts:messages.contact_overview_description")}
    action={
      <Button icon={Plus} onClick={() => openModal("contact")}>
        {i18n.t("contacts:actions.new_contact")}
      </Button>
    }
  />
)

export default ContactsPage
