import { DataViewTabs as NewDataViewTabs } from "@components/new-data-view/data-view-tabs"
import { Button } from "@components/shared"
import { DataViewTabs } from "@components/shared/data-view/data-view-tabs"
import { useFeature } from "@contexts/feature-flag-context"
import { openModal } from "@contexts/modal-context"
import { IViewDataTypeEnum } from "@elara/db"
import i18n from "@i18n"
import { Plus } from "@phosphor-icons/react"

const MaterialsPage = () => {
  const newDataView = useFeature("data_view")
  if (newDataView) {
    return (
      <NewDataViewTabs
        dataType={IViewDataTypeEnum.Consumable}
        title={i18n.t("common:consumable", { count: 2 })}
        action={
          <Button onClick={() => openModal("consumable")} icon={Plus}>
            {i18n.t("consumables:actions.new_consumable")}
          </Button>
        }
      />
    )
  }

  return (
    <DataViewTabs
      dataType={IViewDataTypeEnum.Consumable}
      title={i18n.t("common:consumable", { count: 2 })}
      action={
        <Button onClick={() => openModal("consumable")} icon={Plus}>
          {i18n.t("consumables:actions.new_consumable")}
        </Button>
      }
    />
  )
}

export default MaterialsPage
