import ChangePasswordForm from "@components/settings/change-password-form"

export type ChangePasswordProps = {
  visible: boolean
  onClose: () => void
}
export const ChangePassword = (props: ChangePasswordProps) => {
  return <ChangePasswordForm isOpen={props.visible} onOpenChange={() => props.onClose()} />
}

export default ChangePassword
