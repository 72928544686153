import { AssetGroupSingleSelect, AssetSingleSelect } from "@components/asset"
import { AssetPlaceSingleSelect } from "@components/asset/asset-place-select"
import { Flex } from "@components/layout"
import { Button, DocumentCard, Dropzone, TextArea } from "@components/shared"
import { DialogRootProps } from "@components/shared/dialog"
import { DialogForm } from "@components/shared/dialog-form"
import { FormField } from "@components/shared/form/form-field"
import { DateNative } from "@components/shared/native-date-time-pickers"
import { TextInput } from "@components/shared/text-input"
import toast from "@components/shared/toast"
import { useBreakpoint } from "@contexts/breakpoints"
import {
  AssetDetailsDocument,
  IAssetDetailsQuery,
  IAssetDetailsQueryVariables,
  useEditAssetPartiallyMutation,
} from "@graphql/documents/asset.generated"
import {
  IAssetDetailFragment,
  IAssetSelectDataFragment,
} from "@graphql/documents/fragments.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { FilePlus, Plus, Trash } from "@phosphor-icons/react"
import { naturalCompare } from "@utils"
import { formatDate } from "@utils/date"
import { parseISO } from "date-fns"
import { FieldArray } from "formik"
import { PropsWithChildren, useMemo, useRef } from "react"
import React from "react"
import { FileUpload } from "src/types"
import { useClient } from "urql"
import * as yup from "yup"

import { AssetManualProps, useAssetManualSection } from "./asset-manual-section"
import { AssetManufacturerSingleSelect } from "./manufacturer-select"

type CustomField = {
  name: string
  value: string
}

function getInitialValues(asset: IAssetDetailFragment | null) {
  return {
    custom_fields: ((asset?.custom_fields ?? []) as CustomField[]).sort((a, b) =>
      naturalCompare(a.name, b.name)
    ),
    group_id: asset?.group?.id ?? null,
    manufacturer_id: asset?.manufacturer?.id ?? null,
    model_number: asset?.model_number ?? "",
    name: asset?.name,
    note: asset?.note ?? "",
    operating_since: asset?.operating_since ?? asset?.created_at,
    public_id: asset?.public_id,
    parent_asset_id: asset?.parent_asset?.id ?? null,
    place_id: asset?.place?.id ?? null,
    serial_number: asset?.serial_number ?? "",
    year_of_purchase: asset?.year_of_purchase ?? null,
  }
}

export const useAssetValidationSchema = (assetId: string | null) => {
  const client = useClient()
  return useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .required(
            i18n.t("common:forms.is_required", { field: i18n.t("assets:fields.name") })
          ),
        public_id: yup
          .string()
          .test("unique", i18n.t("common:forms.not_unique"), async (value) => {
            if (!value) return true
            const { data } = await client
              .query(
                `query($public_id: String!) {
              asset(where: {public_id: {_eq: $public_id}}) {
                id
              }
            }`,
                { public_id: value }
              )
              .toPromise()
            const n = data.asset?.length ?? 0
            if (n === 0) return true
            if (n === 1 && data.asset[0].id === assetId) return true
            return false
          }),
        year_of_purchase: yup
          .number()
          .nullable()
          .integer(i18n.t("assets:messages.integer_only"))
          .transform((v) => (isNaN(v) ? null : v))
          .typeError(i18n.t("assets:messages.year_only")),
        custom_fields: yup.array().of(
          yup.object().shape({
            name: yup.string().required(i18n.t("common:forms.required")),
            value: yup.string(),
          })
        ),
        operating_schedule: yup.array().of(
          yup.object({
            isoday: yup.number().min(1).max(7).required(i18n.t("common:forms.required")),
            start: yup.string().required(i18n.t("common:forms.required")),
            end: yup.string().required(i18n.t("common:forms.required")),
          })
        ),
      }),
    [assetId]
  )
}

export type AssetInformationFormValues = ReturnType<typeof getInitialValues>

export type AssetInformationFormDialogProps = PropsWithChildren<
  {
    asset: IAssetDetailFragment
    autoFocus?: string | null
    manual?: Partial<AssetManualProps>
  } & Pick<DialogRootProps, "isOpen" | "onOpenChange">
>

export const AssetInformationFormDialog = (props: AssetInformationFormDialogProps) => {
  const client = useClient()
  const bp = useBreakpoint()
  const size = bp.sm ? "small" : "large"
  const manual = useAssetManualSection(props.asset)

  const initialValues = getInitialValues(props.asset)
  const [, editAsset] = useEditAssetPartiallyMutation()

  const validationSchema = useAssetValidationSchema(props.asset.id)
  const editScope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)

  const onSubmit = async (values: AssetInformationFormValues) => {
    if (!props.asset) return
    const res = await editAsset(
      {
        asset_id: props.asset.id,
        changes: {
          ...values,
          year_of_purchase: values.year_of_purchase || null,
        },
      },
      editScope.context()
    )
    if (!res.data?.update_asset_by_pk || res.error) {
      toast.error(i18n.t("common:generic_toast_error"))
      throw new Error(res.error?.message ?? "Unknown error")
    }
    // Refetch the parents info for all asset since they get only recomputed in an
    // after update trigger
    await client
      .query<IAssetDetailsQuery, IAssetDetailsQueryVariables>(AssetDetailsDocument, {
        id: props.asset.id,
      })
      .toPromise()
  }

  const assetIsSelectable = (asset: IAssetSelectDataFragment) =>
    asset.id !== props.asset?.id

  const inputFileRef: React.RefObject<HTMLInputElement> = useRef(null)

  return (
    <DialogForm
      title={i18n.t("common:edit_token", { token: i18n.t("common:asset", { count: 1 }) })}
      formikConfig={{
        initialValues,
        onSubmit,
        validationSchema: validationSchema,
        validateOnBlur: true,
        validateOnChange: false,
      }}
      isOpen={props.isOpen}
      cancelProps={{ autoFocus: !props.autoFocus }}
      onOpenChange={props.onOpenChange}
      className="md:!max-w-screen-md"
      trigger={props.children}>
      {(formik) => (
        <div className="grid grid-cols-1 gap-x-10 gap-y-0 py-4 sm:grid-cols-2 sm:gap-y-2">
          <div className="mb-2 sm:col-span-2">
            <h3 className="text-base font-semibold text-gray-800">
              {i18n.t("common:information")}
            </h3>
          </div>

          <FormField label={i18n.t("assets:fields.name")} name="name">
            <TextInput size={size} autoComplete="nofill" />
          </FormField>

          <FormField label={i18n.t("assets:fields.public_id")} name="public_id">
            <TextInput />
          </FormField>

          <FormField
            label={i18n.t("assets:fields.group", { count: 1 })}
            name="group_id"
            optional>
            {({ field, helpers }) => (
              <AssetGroupSingleSelect
                {...field}
                isClearable
                onChange={(val) => helpers.setValue(val)}
                size={size}
              />
            )}
          </FormField>

          <FormField
            label={i18n.t("assets:fields.location", { count: 1 })}
            name="place_id"
            optional>
            {({ field, helpers }) => (
              <AssetPlaceSingleSelect
                {...field}
                onChange={(val) => helpers.setValue(val)}
                size={size}
              />
            )}
          </FormField>

          <FormField label={i18n.t("assets:fields.parent")} name="parent_asset_id" optional>
            {({ field, helpers }) => (
              <AssetSingleSelect
                {...field}
                assetIsSelectable={assetIsSelectable}
                isClearable
                size={size}
                onChange={(val) => helpers.setValue(val)}
              />
            )}
          </FormField>

          <div className="my-2 sm:col-span-2">
            <h3 className="text-base font-semibold text-gray-800">
              {i18n.t("common:property_other")}
            </h3>
          </div>

          <FormField
            label={i18n.t("assets:fields.build_year")}
            name="year_of_purchase"
            optional>
            <TextInput type="number" size={size} />
          </FormField>
          <FormField
            label={i18n.t("assets:fields.operating_since")}
            name="operating_since"
            optional>
            {({ field, helpers }) => (
              <DateNative
                value={parseISO(field.value)}
                onChange={(date) => {
                  if (date) {
                    const dateString = formatDate(date, "yyyy-MM-dd")
                    helpers.setValue(dateString)
                  }
                }}
              />
            )}
          </FormField>
          <FormField
            label={i18n.t("assets:fields.manufacturer")}
            name="manufacturer_id"
            optional>
            {({ field, helpers }) => (
              <AssetManufacturerSingleSelect
                {...field}
                onChange={(val) => helpers.setValue(val)}
              />
            )}
          </FormField>
          <FormField
            label={i18n.t("assets:fields.model_number")}
            name="model_number"
            optional>
            <TextInput size={size} />
          </FormField>
          <FormField
            label={i18n.t("assets:fields.serial_number")}
            name="serial_number"
            optional>
            <TextInput size={size} />
          </FormField>

          <FormField
            label={i18n.t("assets:fields.notes")}
            name="note"
            optional
            className="sm:col-span-2">
            <TextArea className="focus:!border-blue-medium" size={size} />
          </FormField>

          <FormField
            name="manual"
            optional
            hasErrorPlaceholder={false}
            label={i18n.t("assets:fields.operating_manual")}
            className="sm:col-span-2">
            {({ field }) =>
              manual?.uploads &&
              manual?.uploads.length <= 0 && (
                <div className="relative mt-1">
                  <Dropzone
                    onDropFile={manual?.onDropManual!}
                    onFilesRejected={(err) => {
                      if (err.length) {
                        toast.error(i18n.t("common:messages.upload_failure"))
                      }
                    }}
                  />

                  <label className="flex cursor-pointer flex-col items-center justify-center overflow-hidden rounded border-2 border-dashed border-grey-5 p-4 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700">
                    <TextInput
                      {...field}
                      type="file"
                      ref={inputFileRef}
                      onChange={manual?.onUploadManual}
                      style={{ display: "none" }}
                    />
                    <Flex col align="center" justify="center" className="!shrink-0">
                      <FilePlus size={24} className="mb-1" />
                      <span>
                        {i18n.t("common:upload_token", {
                          token: i18n.t("assets:fields.operating_manual"),
                        })}
                      </span>
                    </Flex>
                  </label>
                </div>
              )
            }
          </FormField>
          {manual?.uploads &&
            manual?.uploads.length > 0 &&
            manual?.uploads.map((upload: FileUpload) => (
              <DocumentCard
                className="sm:col-span-2"
                document={upload.data}
                key={upload.data.id}
                allowEdit
                onEditFileName={(id, fileName) => {
                  manual?.onEditManualName?.(id, fileName)
                }}
                allowDelete
                onDelete={(id) => manual?.onDeleteManual?.(id)}
              />
            ))}

          <FieldArray name="custom_fields">
            {({ remove, push }) => (
              <>
                <div className="mt-4 flex justify-between sm:col-span-2">
                  <h3 className="text-base font-semibold text-gray-800">
                    {i18n.t("common:forms.metadata.title")}
                  </h3>

                  <Button
                    icon={Plus}
                    size="small"
                    type="tertiary"
                    onClick={() => {
                      const n = formik.values.custom_fields.length
                      push({ name: "", value: "" })
                      setTimeout(() => {
                        document.getElementById(`custom_fields.${n + 1}.name`)?.focus()
                      }, 400)
                    }}>
                    {i18n.t("common:forms.metadata.add")}
                  </Button>
                </div>
                {formik.values.custom_fields.length > 0 ? (
                  formik.values.custom_fields.map((_, index) => (
                    <div key={`${formik.values.custom_fields.length}_${index}`}>
                      <FormField
                        label={i18n.t("common:forms.metadata.fields.key")}
                        name={`custom_fields.${index}.name`}
                        className="mb-1">
                        <TextInput
                          size={size}
                          // By adding autofocus with index larger than initial values
                          // we get a focus when adding new fields
                          autoFocus={index >= initialValues.custom_fields.length}
                        />
                      </FormField>
                      <FormField
                        label={i18n.t("common:forms.metadata.fields.value")}
                        name={`custom_fields.${index}.value`}
                        hasErrorPlaceholder={false}
                        optional>
                        <TextInput size={size} />
                      </FormField>
                      <Flex row justify="flex-end" className="w-full pt-2">
                        <Button
                          color="gray"
                          size="small"
                          icon={Trash}
                          type="tertiary"
                          onClick={() => remove(index)}>
                          {i18n.t("common:forms.metadata.delete")}
                        </Button>
                      </Flex>
                    </div>
                  ))
                ) : (
                  <p className="text-sm text-gray-500 sm:col-span-2">
                    {i18n.t("common:forms.metadata.empty", {
                      type: i18n.t("common:asset", { count: 1 }),
                    })}
                  </p>
                )}
              </>
            )}
          </FieldArray>
        </div>
      )}
    </DialogForm>
  )
}
