import { Data, Where } from "@elara/select"

export function andWhere<D extends Data>(where: Where<D>, ...args: Where<D>[]): Where<D> {
  const ws = [where, ...args]
    .filter((w) => w != null && Object.keys(w).length > 0)
    .flatMap((w) => {
      if (w._and && Object.keys(w).length === 1) {
        return w._and
      } else {
        return [w]
      }
    })
  if (ws.length === 0) return {} as Where<D>
  if (ws.length === 1) return ws[0] as Where<D>
  return { _and: ws } as Where<D>
}
