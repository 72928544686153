import { X } from "@phosphor-icons/react"
import { colors } from "@styles"
import { cn } from "@utils"
import React, { FunctionComponent, PropsWithChildren, useRef } from "react"

import Box from "./box"
import Button from "./button"
import Portal from "./portal"

export type DrawerProps = PropsWithChildren<{
  title: string
  isOpen: boolean
  onClose: () => void
  footer?: React.ReactNode
}>

export const Drawer: FunctionComponent<DrawerProps> = (props) => {
  const ref = useRef(null)

  if (!props.isOpen) return null
  return (
    <Portal>
      <div className="fixed inset-0 z-[60] mt-0" ref={ref}>
        <div
          className="absolute inset-0 z-[1] bg-black opacity-25 animate-in fade-in-0"
          onClick={props.onClose}
        />
        <div
          className={cn(
            "max-w-screen-sm:w-[100vw] w-[420px] absolute bg-white right-0 top-0 bottom-0 z-10 flex flex-col items-stretch border border-gray-200 animate-in slide-in-from-right-full"
          )}>
          <div className="flex items-center justify-between border-b border-gray-200 bg-gray-50 py-3 text-gray-900 sm:p-4">
            <h3 className="text-lg font-semibold sm:text-xl">{props.title}</h3>
            <Button
              type="tertiary"
              icon={X}
              onClick={props.onClose}
              style={{ color: colors.grey1 }}
            />
          </div>
          <Box className="flex-1 p-4" scrollY>
            {props.children}
          </Box>
          {props.footer && (
            <div className="flex border-t border-gray-200 bg-gray-50 px-3 py-4 text-gray-900">
              {props.footer}
            </div>
          )}
        </div>
      </div>
    </Portal>
  )
}

export default Drawer
