import { HStack } from "@components/layout"
import { X } from "@phosphor-icons/react"

import { Icons } from "."
import Button from "./button"

type InlineEditButtonsProps = {
  isLoading?: boolean
  okIsDisabled?: boolean
  onCancel: () => void
  onOk: () => void
}

export const InlineEditButtons = ({
  isLoading = false,
  okIsDisabled = false,
  onCancel,
  onOk,
}: InlineEditButtonsProps) => {
  return (
    <HStack space={8}>
      {!isLoading && <Button type="tertiary" icon={X} onClick={onCancel} />}
      <Button
        id="file-folder-save-icon"
        type="tertiary"
        icon={Icons.Check}
        isLoading={isLoading}
        disabled={okIsDisabled}
        onClick={onOk}
      />
    </HStack>
  )
}
