import { cn } from "@utils"
import React, { useMemo } from "react"
import { v4 as randomUUID } from "uuid"

export type ToggleProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, "size"> & {
  withLabel?: boolean
}

export const Toggle = React.forwardRef<HTMLInputElement, ToggleProps>((props, ref) => {
  const { withLabel = true, name: givenName, ...otherProps } = props
  const name = useMemo(() => givenName || randomUUID(), [givenName])

  return (
    <div
      className={cn(
        "relative inline-block w-[44px] select-none rounded-full align-middle transition duration-200 ease-in",
        { "opacity-50": props.disabled }
      )}>
      <input
        type="checkbox"
        name={name}
        id={name}
        {...otherProps}
        className={cn(
          "peer absolute h-[24px] w-[24px] cursor-pointer appearance-none rounded-full border-[2px] border-gray-200 bg-white transition-transform checked:translate-x-5 checked:border-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-300 focus-visible:ring-offset-1 disabled:cursor-not-allowed ",
          props.className
        )}
        checked={
          // Formik does not provide checked when given initialValues,
          // but instead passes value=true.
          // So we here try to recognize if value with a boolean prop
          // is given and take this if no checked prop is given
          typeof props.checked === "boolean"
            ? props.checked
            : typeof props.value === "boolean"
            ? props.value
            : undefined
        }
        ref={ref}
      />
      {React.createElement(withLabel ? "label" : "div", {
        ...(withLabel ? { htmlFor: name } : {}),
        className: cn(
          "block h-[24px] w-[44px] overflow-hidden rounded-full bg-gray-200 peer-checked:bg-blue-600 peer-disabled:cursor-not-allowed",
          { "cursor-pointer": withLabel }
        ),
      })}
    </div>
  )
})

export default Toggle
