import {
  IRoleFeatureFlagsQueryVariables,
  useLocationFeatureFlagsQuery,
  useRoleFeatureFlagsQuery,
  useServerFeatureFlagsQuery,
} from "@graphql/documents/feature-flag.generated"
import { useStickyState } from "@hooks"
import { createContext, PropsWithChildren, useContext, useMemo } from "react"
import { UseQueryArgs } from "urql"

import { useLocation } from "./user-context"

/**
 * Our own simple feature flag mechanism.
 *
 * To add a new feature flag:
 *  1) Add the id of the flag to `FeatureFlag`
 *  2) Provide a default value and id to `defaultFlags`
 *
 * To use a feature flag there are two options
 *  1) You can use the `useFeature(id: FeatureFlag)` hook.
 *  2) You can use the `FeatureFlag` component.
 */

export type ServerFeatureFlag =
  | "advanced_analytics"
  | "ai_writing_assistant"
  | "service_requests"
  | "contacts"
  | "maintenance"
  | "materials"
  | "meters"
  | "objects"
  | "tasks"
  | "reporting"
  | "voice_transcripts"

export type ClientFeatureFlag =
  | "batch_select"
  | "kanban_view"
  | "public_task"
  | "task_detail"
  | "import_tool"
  | "data_view"
  | "ai_parser"
  | "asset_public_id"

export type FeatureFlag = ServerFeatureFlag | ClientFeatureFlag

interface ClientFeatureFlagConfig {
  id: ClientFeatureFlag
  description: string
  active: boolean
}

const clientFlags: ClientFeatureFlagConfig[] = [
  {
    active: false,
    id: "batch_select",
    description: "Activates the batch selection feature.",
  },
  {
    active: false,
    id: "kanban_view",
    description: "Activates the Kanban Data View Layout.",
  },
  {
    active: false,
    id: "public_task",
    description: "Activates the Public Tasks feature.",
  },
  {
    active: false,
    id: "import_tool",
    description: "Activates the import feature.",
  },
  {
    active: true,
    id: "task_detail",
    description: "Activates a new experimental Task Detail Page.",
  },
  {
    active: false,
    id: "data_view",
    description: "Activates the new data view component",
  },
  {
    active: false,
    id: "ai_parser",
    description: "Activates the Preventive Maintenance AI Parser Page.",
  },
  {
    active: true,
    id: "asset_public_id",
    description: "Displays unique identifiers for Objects.",
  },
]

type FeatureFlagsContextValue = {
  featureFlags: Partial<Record<FeatureFlag, boolean>>
  updateClientFeatureFlag: (id: ClientFeatureFlag, active: boolean) => void
  clientFlags: ClientFeatureFlagConfig[]
}

let GLOBAL_FEATURE_FLAGS: Partial<Record<FeatureFlag, boolean>> = {}

const FeatureFlagsContext = createContext<FeatureFlagsContextValue>(undefined!)

export const FeatureFlagsProvider = (props: PropsWithChildren<{}>) => {
  const location = useLocation()
  const [localFeatureFlags, setLocalFeatureFlags] = useStickyState({}, "localFeatureFlags")

  const serverFeatureFlags = location.features as Partial<
    Record<ServerFeatureFlag, boolean>
  >

  const featureFlags = useMemo(() => {
    let flags: Partial<Record<FeatureFlag, boolean>> = {}

    clientFlags.forEach((f) => {
      flags[f.id] = f.active
    })

    Object.assign(flags, localFeatureFlags)
    Object.assign(flags, serverFeatureFlags)

    GLOBAL_FEATURE_FLAGS = flags

    flags.data_view = false

    return flags
  }, [localFeatureFlags, serverFeatureFlags])

  const updateClientFeatureFlag = (id: FeatureFlag, active: boolean) => {
    setLocalFeatureFlags((flags) => ({ ...flags, [id]: active }))
  }

  const isServerFeature = (id: FeatureFlag) => id in serverFeatureFlags

  const value = useMemo(
    () => ({ featureFlags, updateClientFeatureFlag, isServerFeature, clientFlags }),
    [featureFlags, updateClientFeatureFlag]
  )

  return (
    <FeatureFlagsContext.Provider value={value}>
      {props.children}
    </FeatureFlagsContext.Provider>
  )
}

export function useFeatureFlags() {
  const value = useContext(FeatureFlagsContext)

  if (!value) {
    throw new Error("`useFeatureFlags` can only be used inside a `FeatureFlagsProvider`")
  }

  return value
}

export function useServerFeatureFlags() {
  const [queryRes] = useServerFeatureFlagsQuery()

  return (queryRes.data?.feature_flag ?? []) as {
    id: ServerFeatureFlag
    description: string
    default_enabled: boolean
  }[]
}

export function useLocationFeatureFlags() {
  const [queryRes] = useLocationFeatureFlagsQuery()

  return (queryRes.data?.location_x_feature_flag ?? []) as {
    feature_flag_id: ServerFeatureFlag
    location_id: string
    enabled: boolean
  }[]
}

export function mergeServerAndLocationFeatureFlags(
  serverFeatureFlags: ReturnType<typeof useServerFeatureFlags>,
  locationFeatureFlags: ReturnType<typeof useLocationFeatureFlags>
) {
  const sff = serverFeatureFlags
  const lff = locationFeatureFlags

  const result = [] as {
    id: ServerFeatureFlag
    location_enabled: boolean
  }[]

  for (const sffFeature of sff) {
    const locationEnabled = lff.find(
      (lffFeature) => lffFeature.feature_flag_id === sffFeature.id
    )
    const enabled = locationEnabled ? locationEnabled.enabled : sffFeature.default_enabled
    result.push({
      id: sffFeature.id,
      location_enabled: enabled,
    })
  }

  return result
}

export function useComputedFeatureFlags() {
  const sff = useServerFeatureFlags()
  const lff = useLocationFeatureFlags()
  return mergeServerAndLocationFeatureFlags(sff, lff)
}

export type RoleFeatureFlag = {
  permission_role_id: string
  feature_flag: ServerFeatureFlag
  enabled: boolean
}

export function mergeComputedAndRoleFeatureFlags(
  computedFeatures: ReturnType<typeof useComputedFeatureFlags>,
  roleFeatureFlags: RoleFeatureFlag[]
) {
  const result = [] as {
    id: ServerFeatureFlag
    enabled: boolean
  }[]

  for (const cff of computedFeatures) {
    const rff = roleFeatureFlags.find((rff) => rff.feature_flag === cff.id)

    let enabled = cff.location_enabled

    if (rff) {
      enabled = rff.enabled !== undefined ? rff.enabled && cff.location_enabled : enabled
    }

    result.push({
      id: cff.id,
      enabled: enabled && cff.location_enabled,
    })
  }
  return result
}

export function useRoleFeatureFlags(
  options?: Omit<UseQueryArgs<IRoleFeatureFlagsQueryVariables>, "query">
) {
  const computedFeatures = useComputedFeatureFlags()
  const [queryRes] = useRoleFeatureFlagsQuery(options)
  const roleFeatureFlags = (queryRes.data?.permission_role_x_feature_flag ??
    []) as RoleFeatureFlag[]

  return mergeComputedAndRoleFeatureFlags(computedFeatures, roleFeatureFlags)
}

export function useFeatureFlag(id: FeatureFlag) {
  const roleFeatureFlags = useRoleFeatureFlags()
  const feature = roleFeatureFlags.find((rff) => rff.id === id)

  return feature?.enabled ?? true
}

export function FeatureGuard(props: {
  id: FeatureFlag
  children: JSX.Element | null
  fallback?: JSX.Element
}) {
  const isEnabled = useFeatureFlag(props.id)
  if (!isEnabled) return props.fallback ?? null
  return props.children
}

export function useFeature(id: FeatureFlag) {
  const { featureFlags } = useFeatureFlags()

  return featureFlags[id] ?? false
}

export function hasFeature(id: FeatureFlag) {
  return GLOBAL_FEATURE_FLAGS[id] ?? false
}
