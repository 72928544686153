// This maps between a row index and our data structure

import { Data, Where } from "@elara/select"

import { GroupBySummaryItem } from "../data-view-types"

export type LookUpItem =
  | { type: "group"; group: GroupBySummaryItem<Data> }
  | { type: "row"; group: GroupBySummaryItem<Data> | null; k: number }
  | { type: "child"; parentRow: Data; k: number; level: number }
export type LookUpItemWithIndex = LookUpItem & { rowIndex: number }

export type Input = {
  groupBySummary: GroupBySummaryItem<Data>[] | null
  numberOfRows: number | null
  isGroupCollapsed: (id: string) => boolean
  numberOfChildren?: (d: Data) => number
  showChildren?: (d: Data) => boolean
  getRow: (l: LookUpItem) => Data | null
}

interface RowMapper {
  getItem: (rowIdx: number) => LookUpItemWithIndex
  size: number
}

function addSubtree(lookUp: LookUpItem[], input: Input, item: LookUpItem, level = 1) {
  const { numberOfChildren, getRow } = input
  const r = getRow(item)
  if (r && numberOfChildren && input.showChildren) {
    const nChildren = numberOfChildren(r)
    if (nChildren > 0 && input.showChildren(r)) {
      for (let l = 0; l < nChildren; l++) {
        const subItem = { type: "child" as const, parentRow: r, k: l, level }
        lookUp.push(subItem)
        addSubtree(lookUp, input, subItem, level + 1)
      }
    }
  }
}

export const createRowMapper = (input: Input): RowMapper => {
  const { groupBySummary, isGroupCollapsed } = input
  const lookUp: LookUpItem[] = []
  if (groupBySummary) {
    for (let group of groupBySummary) {
      if (group.size === 0) continue
      lookUp.push({ type: "group", group })
      if (!isGroupCollapsed(group.id)) {
        for (let k = 0; k < group.size; k++) {
          const item = { type: "row" as const, group, k }
          lookUp.push(item)

          addSubtree(lookUp, input, item)
        }
      }
    }
  } else if (input.numberOfRows) {
    for (let k = 0; k < input.numberOfRows; k++) {
      const item = { type: "row" as const, group: null, k }
      lookUp.push(item)

      addSubtree(lookUp, input, item)
    }
  }

  const lookUpWithIndex = lookUp.map((item, index) => ({ ...item, rowIndex: index }))
  return {
    getItem: (index: number) => lookUpWithIndex[index],
    size: lookUpWithIndex.length,
  }
}

export type ChunkMeta = {
  key: string
  query: { where: Where<Data>; offset: number; limit: number; subQuery?: boolean }
}

export function getChunkMeta<D extends Data>(options: {
  lookUpItem: LookUpItem
  chunkSize: number
  childrenQuery?: (d: D) => Where<D>
}): ChunkMeta | null {
  const { lookUpItem: l, chunkSize, childrenQuery } = options
  if (l.type === "group") return null
  if (l.type === "row") {
    const chunkStart = Math.floor(l.k / chunkSize) * chunkSize
    const key = `chunk:group=${l.group?.id ?? "__NO_GROUP__"}:${chunkStart}`
    const query = { where: l.group?.where ?? {}, offset: chunkStart, limit: chunkSize }

    return { key, query }
  } else if (l.type === "child" && childrenQuery) {
    const chunkStart = Math.floor(l.k / chunkSize) * chunkSize
    const key = `chunk:parent=${l.parentRow.id}:${chunkStart}`
    const query = {
      where: childrenQuery(l.parentRow as D),
      offset: chunkStart,
      limit: chunkSize,
      subQuery: true,
    }

    return { key, query }
  }
  return null
}
