import { cn } from "@utils"
import classNames from "classnames"

import PageTitle from "./page-title"

type PageViewLayoutProps = {
  subtitle?: string
  overflowPage?: boolean
  title: React.ReactNode
  action?: React.ReactNode
  children: React.ReactNode
  className?: string
}

const PageViewLayout: React.FC<PageViewLayoutProps> = (props) => (
  <div
    className={cn(
      "flex flex-col flex-1 min-w-0 bg-gray-50",
      {
        "min-h-0": !props.overflowPage,
      },
      props.className
    )}>
    <PageTitle {...props} className="px-3 sm:px-6" />

    <div
      className={classNames(
        "flex flex-col min-w-0 flex-1 bg-white sm:mx-3 sm:mb-3 sm:rounded-lg border-t sm:border border-gray-200",
        { "min-h-0": !props.overflowPage }
      )}>
      {props.children}
    </div>
  </div>
)

export default PageViewLayout
