import { Button, UserTag } from "@components/shared"
import { Dialog } from "@components/shared/dialog"
import { WorkOrderTimeTrackingForm } from "@components/work-order/work-order-time-tracking-form"
import { orderBy } from "@elara/select"
import { IWorkOrderFragment } from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { Clock, PencilSimple, Plus, Timer } from "@phosphor-icons/react"
import { cn } from "@utils"
import { formatDate } from "@utils/date"
import { intervalToDuration } from "date-fns"
import React, { PropsWithChildren, useEffect } from "react"
import { useState } from "react"

import { EmptySection } from "./empty"
import { Section } from "./section"

interface LineClampProps {
  lines?: number
}

type LineClampContextValue = {
  isClamped: boolean
  isExpanded: boolean
  setIsExpanded: (value: boolean) => void
  setIsClamped: (value: boolean) => void
}
const LineClampContext = React.createContext<LineClampContextValue>(undefined!)

function LineClamp(props: PropsWithChildren) {
  const [isClamped, setIsClamped] = useState(true)
  const [isExpanded, setIsExpanded] = useState(false)
  const value = React.useMemo(
    () => ({
      isClamped,
      isExpanded,
      setIsExpanded,
      setIsClamped,
    }),
    [isClamped, isExpanded]
  )
  return (
    <LineClampContext.Provider value={value}>{props.children} </LineClampContext.Provider>
  )
}

function LineClampText({
  lines = 2,
  ...props
}: LineClampProps & React.HTMLAttributes<HTMLDivElement>) {
  const ctx = React.useContext(LineClampContext)
  if (!ctx) {
    throw new Error("LineClampText must be used within a LineClamp")
  }

  const [container, setContainer] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    if (container) {
      if (container.scrollHeight > container.clientHeight) {
        ctx.setIsClamped(true)
      } else {
        ctx.setIsClamped(false)
      }
    }
  }, [lines, container])

  return (
    <div
      ref={setContainer}
      {...props}
      className={cn(
        {
          "line-clamp-1": !ctx.isExpanded && lines === 1,
          "line-clamp-2": !ctx.isExpanded && lines === 2,
          "line-clamp-3": !ctx.isExpanded && lines === 3,
          "line-clamp-4": !ctx.isExpanded && lines === 4,
          "line-clamp-5": !ctx.isExpanded && lines === 5,
          "line-clamp-6": !ctx.isExpanded && lines === 6,

          // "overflow-hidden": isClamped,
        },
        props.className
      )}
    />
  )
}

function LineClampToggle() {
  const ctx = React.useContext(LineClampContext)
  if (!ctx) {
    throw new Error("LineClampToggle must be used within a LineClamp")
  }
  if (!ctx.isClamped) {
    return null
  }
  return (
    <Button
      className="-ml-2.5"
      type="tertiary"
      size="extra-small"
      color="gray"
      onClick={() => ctx.setIsExpanded(!ctx.isExpanded)}>
      {ctx.isExpanded ? "Show less" : "Show more"}
    </Button>
  )
}

export function compactDuration(duration: { hours?: number; minutes?: number }) {
  const hours = duration.hours ?? 0
  const minutes = duration.minutes ?? 0

  return `${hours}:${minutes < 10 ? "0" : ""}${minutes} ${i18n.t(
    "tasks:report.time.hours_short"
  )}`
}

export function totalDuration(task: IWorkOrderFragment) {
  return task.reports.reduce((h, r) => {
    const d = intervalToDuration({
      end: new Date(r.finished_at),
      start: new Date(r.started_at),
    })
    Object.keys(d).forEach((k) => {
      // @ts-ignore
      h[k] = (h[k] ?? 0) + d[k]
    })
    return h
  }, {} as Duration)
}

function TimeReportTable(props: {
  task: IWorkOrderFragment
  onNew: () => void
  onEdit: (report: IWorkOrderFragment["reports"][0]) => void
}) {
  return (
    <>
      <div className="rounded-lg  bg-gray-50 p-3 @container">
        <div className="@2xl:hidden">
          <div className="divide-y text-sm" key="sm">
            {props.task.reports.map((report) => {
              const startedAt = new Date(report.started_at)
              const finishedAt = new Date(report.finished_at)
              const duration = intervalToDuration({ start: startedAt, end: finishedAt })
              return (
                <div key={report.id} className="py-3 first:pt-0">
                  <div className="flex items-center justify-between">
                    <div className="font-medium text-gray-700">
                      <UserTag user={report.user} />
                    </div>
                    <Button
                      type="tertiary"
                      size="extra-small"
                      color="gray"
                      icon={PencilSimple}
                      onClick={() => props.onEdit?.(report)}
                    />
                  </div>
                  <div className="flex items-center justify-between text-sm">
                    <div className="inline-flex items-center space-x-1 text-gray-500">
                      <Clock />
                      <span>{formatDate(startedAt, "Pp")}</span>
                    </div>
                    <div className="inline-flex items-center space-x-1 font-medium text-gray-700">
                      <Timer />
                      <span>{compactDuration(duration)}</span>
                    </div>
                  </div>
                  {report.description && (
                    <LineClamp>
                      <LineClampText
                        lines={2}
                        dangerouslySetInnerHTML={{ __html: report.description }}
                        className="mt-1  text-gray-700"
                      />
                    </LineClamp>
                  )}
                </div>
              )
            })}
          </div>
          <div className="flex items-center justify-between border-t border-gray-200 pt-3 text-sm">
            <Button type="tertiary" icon={Plus} onClick={props.onNew} size="extra-small">
              {i18n.t("tasks:report.time.add_new")}
            </Button>
            <div>
              <span className="mr-2 font-medium text-gray-600">
                {i18n.t("tasks:report.time.total")}
              </span>
              <span className="font-medium">
                {compactDuration(totalDuration(props.task))}
              </span>
            </div>
          </div>
        </div>
        <table className="hidden min-w-full divide-y divide-gray-300 text-sm @2xl:block">
          <thead>
            <tr>
              <th
                scope="col"
                className="w-full pb-2 pr-3 text-left text-sm font-medium text-gray-500">
                {i18n.t("common:user", { count: 1 })}
              </th>
              <th
                scope="col"
                className="px-3 pb-2 text-left text-sm font-medium text-gray-500">
                {i18n.t("tasks:report.time.started_at")}
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-3 pb-2 text-center text-sm font-medium text-gray-500 ">
                {i18n.t("tasks:report.time.duration")}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100">
            {props.task.reports.map((report) => {
              if (!report) return null

              const startedAt = new Date(report.started_at)
              const finishedAt = new Date(report.finished_at)
              const duration = intervalToDuration({ start: startedAt, end: finishedAt })

              return (
                <tr key={report.id}>
                  <td className="block py-2">
                    <div className="font-medium text-gray-700">
                      <UserTag user={report.user} />
                    </div>
                    {report.description && (
                      <LineClamp>
                        <LineClampText
                          lines={2}
                          dangerouslySetInnerHTML={{ __html: report.description }}
                          className="mt-1  text-gray-700"
                        />
                        <LineClampToggle />
                      </LineClamp>
                    )}
                  </td>
                  <td className="whitespace-nowrap px-3 py-2 align-top text-gray-700">
                    {formatDate(startedAt, "Pp")}
                  </td>
                  <td className="whitespace-nowrap px-3 py-2 text-right align-top font-medium tabular-nums  text-gray-700">
                    {compactDuration(duration)}
                  </td>
                  <td className="align-top">
                    <Button
                      type="tertiary"
                      size="small"
                      color="gray"
                      icon={PencilSimple}
                      onClick={() => props.onEdit?.(report)}
                    />
                  </td>
                </tr>
              )
            })}
            <tr>
              <td className="py-2">
                <Button
                  type="tertiary"
                  icon={Plus}
                  onClick={props.onNew}
                  size="extra-small">
                  {i18n.t("tasks:report.time.add_new")}
                </Button>
              </td>

              <td
                colSpan={2}
                className="whitespace-nowrap px-3 py-2 text-right font-medium tabular-nums">
                <span className="mr-2 font-medium text-gray-700">
                  {i18n.t("common:total")}
                </span>
                {compactDuration(totalDuration(props.task))}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export function TimeReport(props: { task: IWorkOrderFragment }) {
  const { task } = props
  const reports = orderBy(task.reports, { started_at: "desc" })
  const [trackingForm, setTrackingForm] = useState<{
    open: boolean
    report: IWorkOrderFragment["reports"][0] | null
  }>({ open: false, report: null })

  return (
    <Section icon={<Timer />} title={i18n.t("tasks:report.time.title")}>
      {reports.length > 0 ? (
        <TimeReportTable
          task={task}
          onNew={() => setTrackingForm({ open: true, report: null })}
          onEdit={(report) => setTrackingForm({ open: true, report })}
        />
      ) : (
        <EmptySection
          title={i18n.t("tasks:report.time.no_reports.title")}
          message={i18n.t("tasks:report.time.no_reports.message")}
          actionText={i18n.t("tasks:report.time.add_first")}
          icon={Timer}
          onAction={() => setTrackingForm({ open: true, report: null })}
        />
      )}
      <Dialog
        title={
          trackingForm.report
            ? i18n.t("tasks:report.time.edit")
            : i18n.t("tasks:report.time.new")
        }
        content={
          <WorkOrderTimeTrackingForm
            workOrderId={task.id}
            report={trackingForm.report}
            key={trackingForm.report?.id ?? "-"}
            onCancel={() => {
              setTrackingForm({ open: false, report: null })
            }}
            onSubmit={() => {
              setTrackingForm({ open: false, report: null })
            }}
            onDelete={() => {
              setTrackingForm({ open: false, report: null })
            }}
          />
        }
        isOpen={trackingForm.open}
        onOpenChange={(open) => {
          if (!open) {
            setTrackingForm({ open: false, report: null })
          }
        }}
      />
    </Section>
  )
}
