import { dequal } from "dequal"
import { useRef } from "react"

export function useDeepMemo<K, V>(memoFn: () => V, key: K) {
  const ref = useRef<{ key: K; value: V }>()
  if (!ref.current || !dequal(key, ref.current.key)) {
    ref.current = { key, value: memoFn() }
  }
  return ref.current.value
}
