import { Button } from "@components/shared"
import { alertDialog } from "@components/shared/alert-dialog-provider"
import { DialogForm } from "@components/shared/dialog-form"
import { FormField } from "@components/shared/form/form-field"
import { DateNative } from "@components/shared/native-date-time-pickers"
import { SectionHeader } from "@components/shared/section-header"
import { Select } from "@components/shared/select"
import { Time } from "@components/shared/time"
import { TimeField } from "@components/shared/time-field"
import toast from "@components/shared/toast"
import { AssetOperatingSchedule, IPermissionScopeEnum, uuid } from "@elara/db"
import { orderBy } from "@elara/select"
import {
  DeleteOperatingScheduleDocument,
  IAssetOperatingEntryFragment,
  IInsertOperatingScheduleMutation,
  IInsertOperatingScheduleMutationVariables,
  InsertOperatingScheduleDocument,
  IUpdateOperatingScheduleMutation,
  IUpdateOperatingScheduleMutationVariables,
  UpdateOperatingScheduleDocument,
  useAssetOperatingScheduleQuery,
} from "@graphql/documents/asset.generated"
import { usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { Copy, Info, PencilSimpleLine, Plus, TrashSimple } from "@phosphor-icons/react"
import { Delete } from "@resources/icons"
import { cn, parseDate } from "@utils"
import {
  formatDate,
  getWeekdayNameFromIsoDay,
  zonedDateTimeFromDate,
  zonedEndOfDay,
  zonedNow,
  zonedStartOfDay,
} from "@utils/date"
import { parseAbsolute } from "@utils/tzdate"
import { set } from "date-fns"
import { FieldArray } from "formik"
import React, { PropsWithChildren } from "react"
import { useClient } from "urql"
import * as yup from "yup"

import { useAsset } from "./$id"

type AssetOperatingEntry = {
  schedule: AssetOperatingSchedule
} & Omit<IAssetOperatingEntryFragment, "schedule">

const VALIDATION_SCHEMA = yup.object().shape({
  schedule: yup
    .array()
    .of(
      yup.object({
        isoday: yup.number().min(1).max(7).required(i18n.t("common:forms.required")),
        start: yup.string().required(i18n.t("common:forms.required")),
        end: yup.string().required(i18n.t("common:forms.required")),
      })
    )
    .min(1, i18n.t("assets:operating_schedule.errors.min_entries")),
})

const ISO_DAY_OPTIONS = [
  {
    value: "1",
    label: i18n.t("calendar:tokens.weekdays.monday"),
  },
  {
    value: "2",
    label: i18n.t("calendar:tokens.weekdays.tuesday"),
  },
  {
    value: "3",
    label: i18n.t("calendar:tokens.weekdays.wednesday"),
  },
  {
    value: "4",
    label: i18n.t("calendar:tokens.weekdays.thursday"),
  },
  {
    value: "5",
    label: i18n.t("calendar:tokens.weekdays.friday"),
  },
  {
    value: "6",
    label: i18n.t("calendar:tokens.weekdays.saturday"),
  },
  {
    value: "7",
    label: i18n.t("calendar:tokens.weekdays.sunday"),
  },
]

export const AssetOperatingEntryForm = (
  props: PropsWithChildren<{
    assetId: uuid
    operatingEntry?: AssetOperatingEntry
    onSubmit?: () => void
  }>
) => {
  const client = useClient()
  const scope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)

  return (
    <DialogForm
      title={i18n.t("common:edit_token", { token: i18n.t("common:asset", { count: 1 }) })}
      formikConfig={{
        initialValues: {
          schedule: props.operatingEntry?.schedule ?? [],
          valid_from: parseDate(props.operatingEntry?.valid_from) ?? new Date(),
          valid_until: parseDate(props.operatingEntry?.valid_until),
        },
        onSubmit: async (values, { setSubmitting }) => {
          try {
            setSubmitting(true)
            const { schedule } = values
            const validFrom = zonedStartOfDay(
              zonedDateTimeFromDate(values.valid_from)
            ).toAbsoluteString()
            const validUntil = values.valid_until
              ? zonedEndOfDay(zonedDateTimeFromDate(values.valid_until)).toAbsoluteString()
              : null

            if (props.operatingEntry) {
              const res = await client
                .mutation<
                  IUpdateOperatingScheduleMutation,
                  IUpdateOperatingScheduleMutationVariables
                >(
                  UpdateOperatingScheduleDocument,
                  {
                    id: props.operatingEntry.id,
                    valid_from: validFrom,
                    valid_until: validUntil,
                    schedule: schedule?.length ? schedule : null,
                  },
                  scope.context()
                )
                .toPromise()

              if (res.error) {
                console.error(res.error)

                throw res.error
              }
            } else {
              const res = await client
                .mutation<
                  IInsertOperatingScheduleMutation,
                  IInsertOperatingScheduleMutationVariables
                >(
                  InsertOperatingScheduleDocument,
                  {
                    valid_from: validFrom,
                    valid_until: validUntil,
                    assetId: props.assetId,
                    schedule: schedule?.length ? schedule : null,
                  },
                  scope.context()
                )
                .toPromise()

              if (res.error) {
                console.error(res.error)

                throw res.error
              }
            }
            props.onSubmit?.()
          } finally {
            setSubmitting(false)
          }
        },
        validationSchema: VALIDATION_SCHEMA,
        validateOnBlur: true,
        validateOnChange: false,
      }}
      className="md:!max-w-screen-md"
      trigger={props.children}>
      {(formik) => (
        <div className="grid grid-cols-1 gap-x-10 gap-y-0 py-4 sm:grid-cols-2 sm:gap-y-2">
          <FormField
            name="valid_from"
            label={i18n.t("assets:operating_schedule.valid_from")}>
            {({ field, helpers }) => (
              <DateNative value={field.value} onChange={helpers.setValue} />
            )}
          </FormField>

          <FormField
            name="valid_until"
            label={i18n.t("assets:operating_schedule.valid_until")}
            optional>
            {({ field, helpers }) => (
              <DateNative value={field.value} onChange={helpers.setValue} clearable />
            )}
          </FormField>
          <FieldArray name="schedule">
            {({ remove, push }) => (
              <div className="col-span-full mt-3 @container">
                <h3 className="text-base font-semibold text-gray-800">
                  {i18n.t("assets:operating_schedule.title")}
                </h3>

                {formik.values.schedule?.length ? (
                  <>
                    <div className="mt-2 grid grid-cols-[auto_auto_auto] gap-3 @lg:grid-cols-[auto_auto_auto_minmax(42px,1fr)]">
                      {(formik.values.schedule ?? []).map((op, index) => (
                        <React.Fragment key={op.isoday + "_" + index}>
                          <FormField
                            hasErrorPlaceholder={false}
                            name={`schedule.${index}.isoday`}
                            className="col-span-full @lg:col-span-1">
                            {({ field, helpers }) => (
                              <Select
                                className="w-52"
                                items={ISO_DAY_OPTIONS}
                                value={String(field.value)}
                                onValueChange={(val) => helpers.setValue(parseInt(val))}
                              />
                            )}
                          </FormField>
                          <FormField
                            name={`schedule.${index}.start`}
                            className="inline-flex items-center space-x-2"
                            label={i18n.t("assets:operating_schedule.from")}
                            hasErrorPlaceholder={false}>
                            {({ field, helpers }) => (
                              <TimeField
                                aria-label={i18n.t("assets:operating_schedule.from")}
                                value={parseAbsolute(field.value)}
                                onValueChange={(value) => {
                                  value && helpers.setValue(value.toAbsoluteString())
                                }}
                              />
                            )}
                          </FormField>
                          <FormField
                            name={`schedule.${index}.end`}
                            label={i18n.t("assets:operating_schedule.to")}
                            className="col-span-2 inline-flex items-center space-x-2 @lg:col-span-1"
                            hasErrorPlaceholder={false}>
                            {({ field, helpers }) => (
                              <TimeField
                                aria-label={i18n.t("assets:operating_schedule.to")}
                                value={parseAbsolute(field.value)}
                                onValueChange={(value) => {
                                  value && helpers.setValue(value.toAbsoluteString())
                                }}
                              />
                            )}
                          </FormField>
                          <Button
                            icon={Delete}
                            className=" justify-self-start @lg:self-end"
                            type="tertiary"
                            color="gray"
                            onClick={() => remove(index)}>
                            {i18n.t("common:remove")}
                          </Button>
                        </React.Fragment>
                      ))}
                    </div>
                    <Button
                      type="tertiary"
                      color="gray"
                      className="mt-2"
                      icon={Plus}
                      onClick={() => {
                        const existingsHours = formik.values.schedule ?? []
                        const lastHours = existingsHours[existingsHours.length - 1] ?? null

                        const lastIsoDay = lastHours?.isoday ?? 1
                        const day = {
                          isoday: (lastIsoDay % 7) + 1,
                          start:
                            lastHours?.start ??
                            set(new Date(), {
                              hours: 6,
                              minutes: 0,
                              seconds: 0,
                            }).toISOString(),
                          end:
                            lastHours?.end ??
                            set(new Date(), {
                              hours: 18,
                              minutes: 0,
                              seconds: 0,
                            }).toISOString(),
                        }
                        push(day)
                      }}>
                      {i18n.t("assets:operating_schedule.add")}
                    </Button>
                  </>
                ) : (
                  <>
                    <label className="col-span-full mt-3 flex flex-col items-center rounded border-2 border-dashed p-3 text-sm text-gray-500">
                      <p className="text-center">
                        {i18n.t("assets:operating_schedule.empty")}
                      </p>
                      <Button
                        color="gray"
                        type="secondary"
                        className="mx-auto mt-3"
                        onClick={() => {
                          const day = {
                            isoday: 1,
                            start: set(new Date(), {
                              hours: 6,
                              minutes: 0,
                              seconds: 0,
                            }).toISOString(),
                            end: set(new Date(), {
                              hours: 18,
                              minutes: 0,
                              seconds: 0,
                            }).toISOString(),
                          }

                          push(day)
                        }}>
                        {i18n.t("assets:operating_schedule.add")}
                      </Button>
                    </label>
                    {typeof formik.errors.schedule === "string" && formik.touched && (
                      <span className="mt-2 block w-full rounded border border-red-600 bg-red-100 px-3 py-1.5 text-sm text-red-600">
                        {formik.errors.schedule}
                      </span>
                    )}
                  </>
                )}
              </div>
            )}
          </FieldArray>
        </div>
      )}
    </DialogForm>
  )
}

function OperatingSchedule(props: {
  schedule: AssetOperatingSchedule
  className?: string
}) {
  return (
    <div
      className={cn(
        "grid grid-cols-[auto_minmax(0,1fr)] gap-x-3 gap-y-1 text-sm text-gray-600 @mobile/page:col-span-3",
        props.className
      )}>
      {props.schedule
        ?.sort((a, b) => {
          let cmp = a.isoday - b.isoday
          if (cmp == 0) {
            cmp = a.start.localeCompare(b.start)
          }
          return cmp
        })
        .map((op, idx) => (
          <React.Fragment key={idx}>
            {props.schedule?.[idx - 1]?.isoday == op.isoday ? (
              <span />
            ) : (
              <span>{getWeekdayNameFromIsoDay(op.isoday)}</span>
            )}
            <span className="tabular-nums">
              <Time date={new Date(op.start)} /> - <Time date={new Date(op.end)} />
            </span>
          </React.Fragment>
        ))}
    </div>
  )
}

export function AssetInformationOperatingSchedule() {
  const client = useClient()
  const { asset } = useAsset()

  const [query, refetch] = useAssetOperatingScheduleQuery({
    variables: { assetId: asset.id },
    requestPolicy: "cache-and-network",
  })
  const scope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)
  const entries = (query.data?.asset_operating_schedule ?? []) as AssetOperatingEntry[]

  const now = new Date()
  const isoNow = now.toISOString()

  const currentValidEntry = orderBy(entries, { valid_from: "desc" }).find(
    (entry) =>
      entry.valid_from <= isoNow && (!entry.valid_until || entry.valid_until >= isoNow)
  )

  const znow = zonedNow()

  const defaultSchedule = [1, 2, 3, 4, 5, 6, 7].map((isoday) => ({
    isoday,
    start: zonedStartOfDay(znow).toAbsoluteString(),
    end: zonedEndOfDay(znow).toAbsoluteString(),
  })) satisfies AssetOperatingSchedule

  const pastEntries = orderBy(
    entries.filter((entry) => entry.id !== currentValidEntry?.id),
    { valid_from: "desc" }
  )

  const copyOperatingScheduleToChildren = async () => {
    if (!currentValidEntry) return
    if (!asset.sub_assets.length) return

    const { valid_from, valid_until, schedule } = currentValidEntry

    const copyMutations = asset.sub_assets.map((subasset) =>
      client
        .mutation<
          IInsertOperatingScheduleMutation,
          IInsertOperatingScheduleMutationVariables
        >(
          InsertOperatingScheduleDocument,
          {
            valid_from,
            valid_until,
            assetId: subasset.id,
            schedule: schedule?.length ? schedule : null,
          },
          scope.context()
        )
        .toPromise()
    )

    const responses = await Promise.all(copyMutations)
    responses.forEach((res) => res.error && console.error(res.error))

    toast.success(i18n.t("assets:operating_schedule.copy_success"))
  }

  return (
    <div className="flex min-h-0 w-full max-w-xl flex-1 flex-col gap-4 lg:p-0">
      <div className="flex items-center justify-between">
        <SectionHeader>{i18n.t("assets:operating_schedule.title")}</SectionHeader>
        <AssetOperatingEntryForm assetId={asset.id} onSubmit={() => refetch()}>
          <Button type="primary">{i18n.t("assets:operating_schedule.new_title")}</Button>
        </AssetOperatingEntryForm>
      </div>

      <div>
        <div className="flex items-center">
          <div className="mr-2 font-medium text-gray-700">
            {i18n.t("assets:operating_schedule.current_schedule")}
          </div>
          {currentValidEntry && (
            <div className="flex items-center">
              <AssetOperatingEntryForm
                assetId={asset.id}
                operatingEntry={currentValidEntry}
                onSubmit={() => refetch()}>
                <Button type="tertiary" icon={PencilSimpleLine} size="extra-small">
                  {i18n.t("common:edit")}
                </Button>
              </AssetOperatingEntryForm>

              {asset.sub_assets.length > 0 && (
                <Button
                  icon={Copy}
                  type="tertiary"
                  size="extra-small"
                  onClick={copyOperatingScheduleToChildren}>
                  Copy to Subobjects
                </Button>
              )}
            </div>
          )}
        </div>
      </div>

      <div>
        <OperatingSchedule schedule={currentValidEntry?.schedule ?? defaultSchedule} />
        {currentValidEntry && (
          <div className="mt-2 space-x-1 text-sm text-gray-500">
            <span>{i18n.t("assets:operating_schedule.valid_from")}</span>
            <span>{formatDate(new Date(currentValidEntry.valid_from), "P")}</span>
            {currentValidEntry.valid_until && (
              <span> - {formatDate(new Date(currentValidEntry.valid_until), "P")}</span>
            )}
          </div>
        )}
      </div>

      {!!pastEntries.length && (
        <div className="mt-4">
          <div className="divide-y-2 font-medium text-gray-700">
            {i18n.t("assets:operating_schedule.previous_schedule")}
          </div>
          {pastEntries.map((entry) => {
            return (
              <div key={entry.id} className="flex py-2">
                <div>
                  <div className="flex items-center">
                    <div className="mr-3 space-x-1 text-sm font-medium text-gray-600">
                      <span>{formatDate(new Date(entry.valid_from), "P")}</span>
                      {entry.valid_until && (
                        <span> - {formatDate(new Date(entry.valid_until), "P")}</span>
                      )}
                    </div>
                    <AssetOperatingEntryForm
                      assetId={asset.id}
                      operatingEntry={entry}
                      onSubmit={() => refetch()}>
                      <Button
                        type="tertiary"
                        icon={PencilSimpleLine}
                        color="gray"
                        size="extra-small">
                        {i18n.t("common:edit")}
                      </Button>
                    </AssetOperatingEntryForm>

                    <Button
                      type="tertiary"
                      icon={TrashSimple}
                      color="gray"
                      size="extra-small"
                      onClick={() => {
                        alertDialog({
                          danger: true,
                          title: i18n.t("common:delete"),
                          description: i18n.t(
                            "assets:operating_schedule.delete_description"
                          ),
                          cancelText: i18n.t("common:cancel"),
                          actionText: i18n.t("common:delete"),
                          onAction: async () => {
                            const res = await client
                              .mutation(DeleteOperatingScheduleDocument, { id: entry.id })
                              .toPromise()
                            if (res.error) {
                              toast.error(i18n.t("common.generic_toast_error"))
                            }
                            refetch()
                          },
                        })
                      }}>
                      {i18n.t("common:delete")}
                    </Button>
                  </div>

                  <OperatingSchedule schedule={entry.schedule} className="text-gray-500" />
                </div>
              </div>
            )
          })}
        </div>
      )}
      <div className="flex items-center rounded-lg bg-blue-50 p-3 text-sm">
        <Info size={24} className="mr-3 text-blue-500" />
        <p className="text-blue-700">
          {i18n.t("assets:operating_schedule.no_schedule_handling")}
        </p>
      </div>
    </div>
  )
}

export default AssetInformationOperatingSchedule
