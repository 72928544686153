import { AssetGroupSingleSelect } from "@components/asset"
import { TextInputWithUnit } from "@components/shared/text-input-with-unit"
import { useLocation } from "@contexts/user-context"
import { AssetOperatingSchedule } from "@elara/db"
import { groupBy } from "@elara/select"
import { useStickyState } from "@hooks"
import { DateRange } from "@utils/date"
import { GridReadyEvent } from "ag-grid-community"
import { ColDef, GridApi } from "ag-grid-enterprise"
import { differenceInDays, differenceInHours } from "date-fns"
import { useEffect, useMemo } from "react"

import { ComponentAvailabilityChart } from "../charts/component-availability"
import { ComponentDowntimeChart } from "../charts/component-downtime"
import { SystemAvailabilityChart } from "../charts/system-availability"
import { DataGrid } from "../components/data-grid"
import { elaraLogo } from "../logos/elara"
import {
  useAssetsWithOperatingScheduleQuery,
  useAvailabilityDataGridQuery,
} from "../queries/availability.generated"

type AvailabilityLoading = { [id: string]: number }

type Props = {
  dateRange: DateRange
  gridApi: GridApi | null
  setGridApi: (api: GridApi) => void
}

export const AvailabilityDataGrid = ({ dateRange, setGridApi }: Props) => {
  const location = useLocation()

  const [assetGroupId, setAssetGroupId] = useStickyState<string>("", "selectedAssetGroupId")
  const [productionHours, setProductionHours] = useStickyState<number>(
    600,
    "productionHours"
  )
  const [availabilityLoading, setAvailabilityLoading] = useStickyState<AvailabilityLoading>(
    {},
    "availabilityLoading"
  )

  const totalDays = useMemo(() => {
    return differenceInDays(dateRange.end ?? new Date(), dateRange.start) + 1
  }, [dateRange])

  const [assetsQueryRes] = useAssetsWithOperatingScheduleQuery({
    variables: { assetGroupId },
  })

  const operatingHoursPerWeek = useMemo(() => {
    if (!assetsQueryRes.data?.asset_group_by_pk) return 24 * 7

    const hoursPerWeek = assetsQueryRes.data.asset_group_by_pk.assets.map((asset) => {
      return asset.operating_schedules.reduce((acc, curr) => {
        const schedule = curr.schedule as AssetOperatingSchedule

        const hours = schedule?.reduce((acc, curr) => {
          return acc + differenceInHours(new Date(curr.end), new Date(curr.start))
        }, 0)

        return acc + (hours ?? 0)
      }, 0)
    })

    return Math.max(...hoursPerWeek) || 24 * 7
  }, [assetsQueryRes.data, totalDays])

  useEffect(() => {
    const totalProductionHours = Math.round((totalDays / 7) * operatingHoursPerWeek)
    setProductionHours(totalProductionHours)
  }, [totalDays, operatingHoursPerWeek])

  const [queryRes] = useAvailabilityDataGridQuery({
    variables: {
      startDate: dateRange.start.toISOString(),
      endDate: (dateRange.end ?? new Date()).toISOString(),
    },
  })
  const data = useMemo(() => queryRes.data?.asset_state_log ?? [], [queryRes.data])

  useEffect(() => {
    data.forEach((row) => {
      const groupId = row.asset.group?.id
      if (groupId && availabilityLoading[groupId] === undefined) {
        setAvailabilityLoading((prev) => ({ ...prev, [groupId]: 0 }))
      }
    })
  }, [data, availabilityLoading])

  const rowData = useMemo(() => {
    return groupBy(data, { groupId: (row) => row.asset.group?.id ?? "" }).map((result) => {
      const id = result.groupId!
      const componentName = result.items[0].asset.group?.name ?? ""
      const downtimeInHours = result.items.reduce((acc, curr) => {
        if (curr.started_at && curr.ended_at) {
          const startedAt = new Date(curr.started_at).getTime()
          const endedAt = new Date(curr.ended_at).getTime()
          return acc + (endedAt - startedAt) / 1000 / 60 / 60
        }

        return acc
      }, 0)
      const availabilityLoadingPercentage = availabilityLoading[result.groupId!] ?? 0
      const componentAvailabilityPercentage =
        ((productionHours - downtimeInHours) / productionHours) * 100
      const calculatedSystemDeterioration =
        (100 - componentAvailabilityPercentage) * availabilityLoadingPercentage
      const totalAvailability = 100 - calculatedSystemDeterioration

      return {
        id,
        componentName,
        downtimeInHours,
        availabilityLoadingPercentage,
        componentAvailabilityPercentage,
        calculatedSystemDeterioration,
        totalAvailability,
      }
    })
  }, [data, productionHours, availabilityLoading])

  type TRowData = (typeof rowData)[0]

  const colDefs = useMemo<ColDef<TRowData>[]>(() => {
    return [
      {
        field: "id",
        headerName: "ID",
        hide: true,
      },
      {
        field: "componentName",
        headerName: "Component",
      },
      {
        field: "downtimeInHours",
        headerName: "Downtime Hours",
        valueFormatter: (params) => {
          const data = params.value as TRowData["downtimeInHours"]
          if (!data) return ""
          return `${data.toFixed(2)} hours`
        },
      },
      {
        field: "availabilityLoadingPercentage",
        headerName: "Availability Loading (%)",
        valueSetter: (params) => {
          if (!params.data || !params.data.id || params.newValue === undefined) return false
          setAvailabilityLoading((prev) => ({
            ...prev,
            [params.data.id as string]: Number(params.newValue),
          }))
          return true
        },
        valueFormatter: (params) => {
          const data = params.value as number
          if (!data) return "0.00%"
          return `${data.toFixed(2)}%`
        },
        editable: true,
        cellEditor: "agNumberCellEditor",
        cellEditorParams: {
          min: 0,
          max: 100,
        },
      },
      {
        field: "componentAvailabilityPercentage",
        headerName: "Component Availability (%)",
        valueFormatter: (params) => {
          const data = params.value as number
          if (!data) return "0.00%"
          return `${data.toFixed(2)}%`
        },
      },
      {
        field: "calculatedSystemDeterioration",
        headerName: "Calculated System Deterioration (%)",
        valueFormatter: (params) => {
          const data = params.value as number
          if (!data) return "0.00%"
          return `${data.toFixed(2)}%`
        },
      },
      {
        field: "totalAvailability",
        headerName: "Total Availability (%)",
        valueFormatter: (params) => {
          const data = params.value as number
          if (!data) return "0.00%"
          return `${data.toFixed(2)}%`
        },
      },
    ]
  }, [productionHours, availabilityLoading])

  const defaultColDef = useMemo<ColDef<TRowData>>(() => {
    return {
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
    }
  }, [])

  const downtimePercentage = useMemo(() => {
    return rowData
      .reduce((acc, curr) => acc + curr.calculatedSystemDeterioration, 0)
      .toFixed(2)
  }, [rowData])

  const uptimePercentage = useMemo(() => {
    return `${100 - Number(downtimePercentage)}`
  }, [rowData, downtimePercentage])

  const componentAvailabilityChartData = useMemo(() => {
    return rowData.map((row) => ({
      name: row.componentName,
      value: row.componentAvailabilityPercentage,
    }))
  }, [rowData])

  const componentDowntimeChartData = useMemo(() => {
    return rowData.map((row) => ({
      name: row.componentName,
      value: row.downtimeInHours,
    }))
  }, [rowData])

  const systemAvailabilityChartData = useMemo(() => {
    const totalUptime = Number(uptimePercentage)

    return [
      {
        name: "Total Availability",
        value: totalUptime,
      },
      {
        name: "Downtime",
        value: 100 - totalUptime,
      },
    ]
  }, [rowData])

  const onGridReady = (params: GridReadyEvent) => setGridApi(params.api)

  return (
    <div className="flex h-full flex-col">
      <div className="my-4 flex items-start gap-x-6 px-4">
        <div className="min-w-[200px] max-w-[200px] space-y-1.5">
          <label className="text-xs font-medium text-gray-900">Object Group</label>
          <AssetGroupSingleSelect
            isClearable={false}
            value={assetGroupId}
            onChange={(id) => id && setAssetGroupId(id)}
          />
        </div>

        <div className="max-w-[200px] space-y-1.5">
          <label className="text-xs font-medium text-gray-900">Total Production Time</label>
          <TextInputWithUnit
            unit="hours"
            value={productionHours}
            onChange={(e) => {
              const value = Number(e.target.value)
              if (isNaN(value)) return
              setProductionHours(value)
            }}
          />
          <span className="text-[11px] opacity-75">
            ({totalDays} days / 7) x ({operatingHoursPerWeek} hours / week)
          </span>
        </div>

        <div className="max-w-[200px] space-y-1.5">
          <label className="text-xs font-medium text-gray-900">Downtime (%)</label>
          <TextInputWithUnit unit="%" value={downtimePercentage} />
        </div>

        <div className="max-w-[200px] space-y-1.5">
          <label className="text-xs font-medium text-gray-900">Uptime (%)</label>
          <TextInputWithUnit unit="%" value={uptimePercentage} />
        </div>
      </div>

      <div className="hidden">
        <ComponentAvailabilityChart data={componentAvailabilityChartData} />
        <ComponentDowntimeChart data={componentDowntimeChartData} />
        <SystemAvailabilityChart data={systemAvailabilityChartData} />
      </div>

      <DataGrid<TRowData>
        rowData={rowData}
        columnDefs={colDefs}
        rowSelection="multiple"
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        rowGroupPanelShow="onlyWhenGrouping"
        statusBar={{
          statusPanels: [{ statusPanel: "agTotalRowCountComponent", align: "right" }],
        }}
        excelStyles={[
          {
            id: "header",
            alignment: { vertical: "Center" },
            interior: {
              color: "#2A55B0",
              pattern: "Solid",
            },
            font: {
              fontName: "Calibri",
              color: "#FFFFFF",
              bold: true,
            },
          },
          {
            id: "cell",
            font: { fontName: "Calibri" },
            alignment: { vertical: "Center" },
          },
        ]}
        defaultExcelExportParams={{
          rowHeight: (params) => (params.rowIndex === 1 ? 80 : 30),
          appendContent: [
            {
              cells: [
                {
                  styleId: "header",
                  mergeAcross: 1,
                  data: {
                    type: "String",
                    value: `Report generated at ${new Date().toLocaleString()}`,
                  },
                },
              ],
            },
          ],
          prependContent: [
            {
              cells: [
                {
                  mergeAcross: 1,
                  data: {
                    type: "String",
                    value: elaraLogo,
                  },
                },
              ],
            },
            {
              cells: [
                {
                  data: {
                    type: "String",
                    value: "Site",
                  },
                },
                {
                  data: {
                    type: "String",
                    value: location.name,
                  },
                },
              ],
            },
            {
              cells: [
                {
                  data: {
                    type: "String",
                    value: "Date Range",
                  },
                },
                {
                  data: {
                    type: "String",
                    value: `${dateRange.start.toLocaleDateString()} - ${(
                      dateRange.end ?? new Date()
                    ).toLocaleDateString()}`,
                  },
                },
              ],
            },
            {
              cells: [
                {
                  data: {
                    type: "String",
                    value: "Total Production Hours",
                  },
                },
                {
                  data: {
                    type: "String",
                    value: `${productionHours} hours`,
                  },
                },
              ],
            },
            {
              cells: [
                {
                  data: {
                    type: "String",
                    value: "Downtime %",
                  },
                },
                {
                  data: {
                    type: "String",
                    value: `${downtimePercentage}%`,
                  },
                },
              ],
            },
            {
              cells: [
                {
                  data: {
                    type: "String",
                    value: "Uptime %",
                  },
                },
                {
                  data: {
                    type: "String",
                    value: `${uptimePercentage}%`,
                  },
                },
              ],
            },
          ],
          addImageToCell: (rowIndex, _col, value) => {
            if (rowIndex !== 1) return

            return {
              image: {
                id: "logo",
                width: 190,
                height: 45,
                base64: value,
                imageType: "png",
                position: { offsetX: 150, offsetY: 20 },
              },
            }
          },
        }}
      />
    </div>
  )
}
