// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import { BlockElementWithGroupProgressInfoFragmentDoc, BlockGroupFragmentDoc } from './fragments.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IBlockElementByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type IBlockElementByIdQuery = { block_element_by_pk: { __typename: 'block_element', id: uuid, config: {}, created_at: string, element_type: Types.IBlockElementTypeEnum, position: number, relationship_data: {} | null, response: {} | null, response_last_edited_at: string | null, group: { __typename: 'block_group', id: uuid, progress_info: {} | null, work_order: { __typename: 'work_order', id: uuid } | null }, response_last_edited_by: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } | null, config_uploads: Array<{ __typename: 'upload', id: uuid, file_name: string, file_size: number, mime_type: string, created_at: string, thumbnail_url: string | null, url: string | null }> | null, uploads: Array<{ __typename: 'block_element_x_upload', element_id: uuid, upload_id: uuid, upload: { __typename: 'upload', id: uuid, file_name: string, file_size: number, mime_type: string, created_at: string, thumbnail_url: string | null, url: string | null } }> } | null };

export type IUpdateBlockElementResponseMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  response: Types.Scalars['jsonb']['input'];
}>;


export type IUpdateBlockElementResponseMutation = { update_block_element_by_pk: { __typename: 'block_element', id: uuid, response: {} | null, group: { __typename: 'block_group', id: uuid, progress_info: {} | null, work_order: { __typename: 'work_order', id: uuid, status: Types.IWorkOrderStatusEnum } | null } } | null };

export type IAddBlockElementUploadMutationVariables = Types.Exact<{
  elementId: Types.Scalars['uuid']['input'];
  uploadId: Types.Scalars['uuid']['input'];
}>;


export type IAddBlockElementUploadMutation = { insert_block_element_x_upload_one: { __typename: 'block_element_x_upload', element_id: uuid, upload_id: uuid, element: { __typename: 'block_element', id: uuid, config: {}, created_at: string, element_type: Types.IBlockElementTypeEnum, position: number, relationship_data: {} | null, response: {} | null, response_last_edited_at: string | null, group: { __typename: 'block_group', id: uuid, progress_info: {} | null, work_order: { __typename: 'work_order', id: uuid } | null }, response_last_edited_by: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } | null, config_uploads: Array<{ __typename: 'upload', id: uuid, file_name: string, file_size: number, mime_type: string, created_at: string, thumbnail_url: string | null, url: string | null }> | null, uploads: Array<{ __typename: 'block_element_x_upload', element_id: uuid, upload_id: uuid, upload: { __typename: 'upload', id: uuid, file_name: string, file_size: number, mime_type: string, created_at: string, thumbnail_url: string | null, url: string | null } }> } | null } | null };

export type IDeleteBlockElementUploadMutationVariables = Types.Exact<{
  elementId: Types.Scalars['uuid']['input'];
  uploadId: Types.Scalars['uuid']['input'];
}>;


export type IDeleteBlockElementUploadMutation = { delete_block_element_x_upload_by_pk: { __typename: 'block_element_x_upload', element: { __typename: 'block_element', id: uuid, config: {}, created_at: string, element_type: Types.IBlockElementTypeEnum, position: number, relationship_data: {} | null, response: {} | null, response_last_edited_at: string | null, group: { __typename: 'block_group', id: uuid, progress_info: {} | null, work_order: { __typename: 'work_order', id: uuid } | null }, response_last_edited_by: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } | null, config_uploads: Array<{ __typename: 'upload', id: uuid, file_name: string, file_size: number, mime_type: string, created_at: string, thumbnail_url: string | null, url: string | null }> | null, uploads: Array<{ __typename: 'block_element_x_upload', element_id: uuid, upload_id: uuid, upload: { __typename: 'upload', id: uuid, file_name: string, file_size: number, mime_type: string, created_at: string, thumbnail_url: string | null, url: string | null } }> } | null } | null };

export type IInsertBlockGroupMutationVariables = Types.Exact<{
  elements: Array<Types.IBlockElementInsertInput> | Types.IBlockElementInsertInput;
  elementIdsToKeep: Array<Types.Scalars['uuid']['input']> | Types.Scalars['uuid']['input'];
  groupId: Types.Scalars['uuid']['input'];
  workOrderId?: Types.InputMaybe<Types.Scalars['uuid']['input']>;
  groupType?: Types.InputMaybe<Types.IBlockGroupTypeEnum>;
}>;


export type IInsertBlockGroupMutation = { delete_block_element: { __typename: 'block_element_mutation_response', affected_rows: number } | null, insert_block_group_one: { __typename: 'block_group', id: uuid, group_type: Types.IBlockGroupTypeEnum, progress_info: {} | null, work_order: { __typename: 'work_order', id: uuid, block_groups: Array<{ __typename: 'block_group', id: uuid, group_type: Types.IBlockGroupTypeEnum, progress_info: {} | null }> } | null, elements: Array<{ __typename: 'block_element', id: uuid, config: {}, created_at: string, element_type: Types.IBlockElementTypeEnum, position: number, relationship_data: {} | null, response: {} | null, response_last_edited_at: string | null, group: { __typename: 'block_group', id: uuid, work_order: { __typename: 'work_order', id: uuid } | null }, response_last_edited_by: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } | null, config_uploads: Array<{ __typename: 'upload', id: uuid, file_name: string, file_size: number, mime_type: string, created_at: string, thumbnail_url: string | null, url: string | null }> | null, uploads: Array<{ __typename: 'block_element_x_upload', element_id: uuid, upload_id: uuid, upload: { __typename: 'upload', id: uuid, file_name: string, file_size: number, mime_type: string, created_at: string, thumbnail_url: string | null, url: string | null } }> }> } | null };


export const BlockElementByIdDocument = gql`
    query BlockElementById($id: uuid!) {
  block_element_by_pk(id: $id) {
    ...BlockElementWithGroupProgressInfo
  }
}
    ${BlockElementWithGroupProgressInfoFragmentDoc}`;

export function useBlockElementByIdQuery(options: Omit<Urql.UseQueryArgs<IBlockElementByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<IBlockElementByIdQuery, IBlockElementByIdQueryVariables>({ query: BlockElementByIdDocument, ...options });
};
export const UpdateBlockElementResponseDocument = gql`
    mutation UpdateBlockElementResponse($id: uuid!, $response: jsonb!) {
  update_block_element_by_pk(pk_columns: {id: $id}, _set: {response: $response}) {
    id
    response
    group {
      id
      progress_info
      work_order {
        id
        status
      }
    }
  }
}
    `;

export function useUpdateBlockElementResponseMutation() {
  return Urql.useMutation<IUpdateBlockElementResponseMutation, IUpdateBlockElementResponseMutationVariables>(UpdateBlockElementResponseDocument);
};
export const AddBlockElementUploadDocument = gql`
    mutation AddBlockElementUpload($elementId: uuid!, $uploadId: uuid!) {
  insert_block_element_x_upload_one(
    object: {element_id: $elementId, upload_id: $uploadId}
  ) {
    element_id
    upload_id
    element {
      ...BlockElementWithGroupProgressInfo
    }
  }
}
    ${BlockElementWithGroupProgressInfoFragmentDoc}`;

export function useAddBlockElementUploadMutation() {
  return Urql.useMutation<IAddBlockElementUploadMutation, IAddBlockElementUploadMutationVariables>(AddBlockElementUploadDocument);
};
export const DeleteBlockElementUploadDocument = gql`
    mutation DeleteBlockElementUpload($elementId: uuid!, $uploadId: uuid!) {
  delete_block_element_x_upload_by_pk(
    element_id: $elementId
    upload_id: $uploadId
  ) {
    element {
      ...BlockElementWithGroupProgressInfo
    }
  }
}
    ${BlockElementWithGroupProgressInfoFragmentDoc}`;

export function useDeleteBlockElementUploadMutation() {
  return Urql.useMutation<IDeleteBlockElementUploadMutation, IDeleteBlockElementUploadMutationVariables>(DeleteBlockElementUploadDocument);
};
export const InsertBlockGroupDocument = gql`
    mutation InsertBlockGroup($elements: [block_element_insert_input!]!, $elementIdsToKeep: [uuid!]!, $groupId: uuid!, $workOrderId: uuid, $groupType: block_group_type_enum = procedure) {
  delete_block_element(
    where: {group_id: {_eq: $groupId}, id: {_nin: $elementIdsToKeep}}
  ) {
    affected_rows
  }
  insert_block_group_one(
    object: {elements: {data: $elements, on_conflict: {constraint: element_pkey, update_columns: [config, position, config_upload_ids]}}, id: $groupId, work_order_id: $workOrderId, group_type: $groupType}
    on_conflict: {constraint: group_pkey, update_columns: [work_order_id]}
  ) {
    ...BlockGroup
    work_order {
      id
      block_groups {
        id
        group_type
        progress_info
      }
    }
  }
}
    ${BlockGroupFragmentDoc}`;

export function useInsertBlockGroupMutation() {
  return Urql.useMutation<IInsertBlockGroupMutation, IInsertBlockGroupMutationVariables>(InsertBlockGroupDocument);
};