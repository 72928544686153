import { ConsumableFormRowItem } from "@components/consumable/consumable-form-row-item"
import { selectConsumable } from "@components/select/consumable-multi-select-dialog"
import { Button } from "@components/shared"
import {
  ConsumableCompactByIdDocument,
  IConsumableCompactByIdQuery,
  IConsumableCompactByIdQueryVariables,
  IUpdateConsumableLogsInTaskMutation,
  IUpdateConsumableLogsInTaskMutationVariables,
  UpdateConsumableLogsInTaskDocument,
} from "@graphql/documents/consumable.generated"
import { IWorkOrderFragment } from "@graphql/documents/fragments.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { Plus } from "@phosphor-icons/react"
import { Control, useFieldArray, useForm } from "react-hook-form"
import { useClient } from "urql"
import { v4 } from "uuid"

export const WorkOrderDetailConsumableForm = (props: {
  workOrder: IWorkOrderFragment
  onDiscard: () => void
  onSave: () => void
}) => {
  const client = useClient()
  const initialValues = {
    consumable: props.workOrder.consumables_used.map((c) => ({
      log_id: c.id,
      consumable: c.consumable!,
      place_id: c.place_id,
      quantity: -c.adjustment,
      asset_id: c.asset_id,
      cost_per_unit: c.cost_per_unit ?? c.consumable?.cost ?? 0,
    })),
  }

  const form = useForm({
    defaultValues: initialValues,
  })
  const scope = usePermissionScope(IPermissionScopeEnum.AppDataEntry)

  const validAssetIds = props.workOrder.assets.map((a) => a.asset_id)
  const ensureValidAssetId = (asset_id: string | null) => {
    if (asset_id && validAssetIds.includes(asset_id)) return asset_id
    return validAssetIds?.[0] ?? null
  }

  const onSubmit = form.handleSubmit(async (values) => {
    const logsToDelete = props.workOrder.consumables_used
      .map((c) => c.id)
      .filter((id) => !values.consumable.find((c) => c.log_id === id))

    const res = await client
      .mutation<
        IUpdateConsumableLogsInTaskMutation,
        IUpdateConsumableLogsInTaskMutationVariables
      >(
        UpdateConsumableLogsInTaskDocument,
        {
          logs: values.consumable.map((c) => ({
            id: c.log_id,
            adjustment: -c.quantity,
            place_id: c.place_id === "no_value" ? null : c.place_id,
            consumable_id: c.consumable?.id,
            asset_id: ensureValidAssetId(c.asset_id),
            task_id: props.workOrder.id,
            cost_per_unit: c.cost_per_unit,
          })),
          logsToDelete,
          taskId: props.workOrder.id,
        },
        scope.context()
      )
      .toPromise()
    if (res.error) {
    } else {
      props.onSave()
    }
  })

  const helpers = useFieldArray({ control: form.control, name: "consumable" })

  return (
    <form className="" onSubmit={onSubmit}>
      <div className="flex flex-col divide-y divide-gray-100">
        {helpers.fields.map((c, i) => {
          return (
            <ConsumableFormRowItem
              key={c.id}
              control={form.control as unknown as Control}
              name={`consumable.${i}`}
              onRemove={() => helpers.remove(i)}
              assets={props.workOrder.assets.map((a) => a.asset).filter(Boolean)}
              consumable={c.consumable}
              storageLocations={c.consumable.storage_locations}
            />
          )
        })}
      </div>
      <div className="mt-2 flex flex-wrap items-center gap-y-2">
        <Button
          type="tertiary"
          icon={Plus}
          className="w-full sm:w-auto"
          color="gray"
          onClick={async () => {
            const ids = await selectConsumable()
            if (!ids) return
            for (let id of ids) {
              const consumableQueryRes = await client
                .query<IConsumableCompactByIdQuery, IConsumableCompactByIdQueryVariables>(
                  ConsumableCompactByIdDocument,
                  { id },
                  { requestPolicy: "cache-only" }
                )
                .toPromise()

              const consumable = consumableQueryRes.data?.consumable_by_pk
              if (!consumable) continue
              helpers.append({
                log_id: v4(),
                consumable,
                asset_id: ensureValidAssetId(null),
                place_id: consumable?.storage_locations?.[0]?.place_id ?? null,
                quantity: 1,
                cost_per_unit: consumable.cost ?? 0,
              })
            }
          }}>
          {i18n.t("consumables:actions.add_consumable")}
        </Button>

        <div className="flex-1" />
        <Button type="secondary" className="mr-3 grow sm:grow-0" onClick={props.onDiscard}>
          {i18n.t("common:discard")}
        </Button>
        <Button htmlType="submit" className="grow sm:grow-0">
          {i18n.t("save")}
        </Button>
      </div>
    </form>
  )
}
