import { HStack, VStack } from "@components/layout"
import { UserAvatar } from "@components/shared/avatar"
import CircularProgress from "@components/shared/circular-progress"
import { MultiSelectSummary } from "@components/shared/multi-select"
import { IconData, TeamIcon } from "@components/shared/team-icons"
import { Tooltip } from "@components/shared/tooltip"
import { CategoryColor } from "@components/work-order/work-order-category-select"
import { WorkOrderDueDate } from "@components/work-order/work-order-due-date-popover"
import { WorkOrderPriorityBadge } from "@components/work-order/work-order-priority"
import { WorkOrderStatusTag } from "@components/work-order/work-order-status"
import { WorkOrderSubtaskTag } from "@components/work-order/work-order-subtask"
import { useBreakpoint } from "@contexts/breakpoints"
import { IBlockGroupTypeEnum, IWorkOrderStatusEnum, ProgressInfo } from "@elara/db"
import { IWorkOrderDataViewFragment } from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { Shapes } from "@phosphor-icons/react"
import Icons from "@resources/icons"
import { naturalCompare } from "@utils"
import React from "react"

export const TaskDataViewListItem = (row: IWorkOrderDataViewFragment) => {
  const bp = useBreakpoint()

  const assets = row.assets.sort((a, b) => naturalCompare(a.asset?.name, b.asset?.name))
  const progressInfo = (row.block_groups?.find(
    (g) => g.group_type === IBlockGroupTypeEnum.Procedure
  )?.progress_info ?? null) as ProgressInfo | null

  if (bp.mobile) {
    return (
      <VStack space={12} className="px-1 py-3">
        <VStack className="ml-[-1px]">
          {row.parent_task && (
            <div>
              <span className="inline-flex min-w-0 shrink-[9999] items-center truncate text-xs text-gray-600">
                <Icons.RightNext className="mr-1 inline-block shrink-0" /> #
                <span className="min-w-0 truncate">
                  {row.parent_task?.work_order_number} {row.parent_task.name}
                </span>
              </span>
            </div>
          )}
          <HStack space={4} className="truncate">
            <HStack className="truncate" space={2}>
              <WorkOrderPriorityBadge priority={row.priority} hasLabel={false} />
              <WorkOrderStatusTag status={row.status} short />
            </HStack>
            <span className="font-normal text-gray-500">#{row.work_order_number}</span>
            <span className="text-ellipsis font-medium text-gray-900">{row.name}</span>
          </HStack>
        </VStack>

        <VStack space={4}>
          <div className="h-5" style={{ marginLeft: 1 }}>
            <WorkOrderDueDate
              workOrder={row}
              iconSize={20}
              showRecurrenceInfo
              markAsOverdue={
                !(
                  row.status === IWorkOrderStatusEnum.Done ||
                  row.status === IWorkOrderStatusEnum.Canceled
                )
              }
              mode="static"
            />
          </div>

          {!!assets.length && (
            <div className="h-5">
              <MultiSelectSummary
                limit={3}
                mode="summary"
                firstItemLabel={
                  <React.Fragment>
                    <span className="text-gray-900">{assets[0].asset?.name} </span>
                    <span className="whitespace-nowrap text-gray-700">
                      {assets[0].asset?.place?.name}
                    </span>
                  </React.Fragment>
                }
                items={assets}
                itemsToIcons={(_) => <Shapes size={20} />}
                isStaticRow
              />
            </div>
          )}
          {!!row.assignees.length && (
            <div className="h-5">
              <MultiSelectSummary
                items={row.assignees}
                limit={3}
                itemsToIcons={(user) => (
                  <UserAvatar
                    user={user.user}
                    key={user?.user?.id}
                    className="border-2 !border-white"
                    size={20}
                  />
                )}
                mode="summary"
                firstItemLabel={`${row.assignees[0]?.user?.first_name ?? ""} ${
                  row.assignees[0]?.user?.last_name ?? ""
                }`}
                isStaticRow
              />
            </div>
          )}
          {!!row.assigned_teams.length && (
            <div className="h-5">
              <MultiSelectSummary
                items={row.assigned_teams}
                limit={3}
                itemsToIcons={({ team }) => {
                  const icon = team?.icon as IconData
                  return (
                    <TeamIcon
                      name={icon?.name}
                      color={icon?.color}
                      key={team?.id}
                      className="!h-5 !w-5"
                    />
                  )
                }}
                firstItemLabel={`${row.assigned_teams[0]?.team?.name ?? ""}`}
                mode="summary"
                isStaticRow
              />
            </div>
          )}
          {!!row.categories.length && (
            <div className="h-5 pl-1">
              <MultiSelectSummary
                items={row.categories}
                limit={3}
                itemsToIcons={(cat) => (
                  <CategoryColor
                    color={cat.category?.color}
                    key={cat.category?.label}
                    className="!h-4 !w-4 !border-2 border-transparent"
                  />
                )}
                mode="summary"
                className="ml-0.5"
                firstItemLabel={row.categories[0]?.category?.label}
                isStaticRow
              />
            </div>
          )}
        </VStack>

        {((progressInfo && progressInfo.total > 0) || row.subtasks.length > 0) && (
          <div className="grid grid-cols-2 pl-1">
            <HStack space={4} align="center">
              <span className="mr-2">{i18n.t("tasks:fields.checklist")}</span>
              {progressInfo && progressInfo.total > 0 ? (
                <Tooltip
                  content={i18n.t("tasks:checklist.status", {
                    count: progressInfo.total_with_response,
                    total: progressInfo.total,
                  })}>
                  <div className="inline-flex items-center rounded border py-1 pl-1 pr-1.5 text-xs">
                    <CircularProgress
                      size={18}
                      progress={progressInfo.percent_with_response / 100}
                    />
                    <span className="ml-1 text-gray-500">
                      {progressInfo.total_with_response}/{progressInfo.total}
                    </span>
                  </div>
                </Tooltip>
              ) : (
                <span className="text-gray-500">-</span>
              )}
            </HStack>

            <HStack space={4} align="center">
              <span className="mr-2">{i18n.t("common:subtask", { count: 2 })}</span>
              {row.subtasks.length > 0 ? (
                <WorkOrderSubtaskTag subtasks={row.subtasks} />
              ) : (
                <span className="text-gray-500">-</span>
              )}
            </HStack>
          </div>
        )}
      </VStack>
    )
  }

  return (
    <VStack space={12} className="py-3">
      <VStack className="ml-[-1px]">
        {row.parent_task && (
          <div>
            <span className="inline-flex min-w-0 shrink-[9999] items-center truncate text-xs text-gray-600">
              <Icons.RightNext className="mr-1 inline-block shrink-0" />
              <span className="min-w-0 truncate">
                #{row.parent_task?.work_order_number} {row.parent_task.name}
              </span>
            </span>
          </div>
        )}
        <HStack space={8} className="truncate">
          <HStack className="truncate" space={2}>
            <WorkOrderPriorityBadge priority={row.priority} hasLabel={false} />
            <WorkOrderStatusTag status={row.status} short />
          </HStack>
          <span className="font-normal text-gray-500">#{row.work_order_number}</span>
          <span className="text-ellipsis font-medium text-gray-900">{row.name}</span>
        </HStack>
      </VStack>

      <VStack space={4}>
        <div style={{ marginLeft: 1 }}>
          <WorkOrderDueDate
            workOrder={row}
            iconSize={20}
            markAsOverdue={
              !(
                row.status === IWorkOrderStatusEnum.Done ||
                row.status === IWorkOrderStatusEnum.Canceled
              )
            }
            mode="static"
            showRecurrenceInfo
          />
        </div>

        <HStack space={16}>
          {!!assets.length && (
            <MultiSelectSummary
              limit={3}
              mode="summary"
              firstItemLabel={
                <React.Fragment>
                  <span className="text-gray-900">{assets[0].asset?.name} </span>
                  <span className="whitespace-nowrap text-gray-700">
                    {assets[0].asset?.place?.name}
                  </span>
                </React.Fragment>
              }
              items={assets}
              itemsToIcons={(_) => <Shapes size={20} />}
              isStaticRow
            />
          )}

          {!!row.assignees.length && (
            <MultiSelectSummary
              items={row.assignees}
              limit={3}
              itemsToIcons={(user) => (
                <UserAvatar
                  user={user.user}
                  key={user?.user?.id}
                  className="!border-white"
                  size={20}
                />
              )}
              mode="summary"
              firstItemLabel={`${row.assignees[0]?.user?.first_name ?? ""} ${
                row.assignees[0]?.user?.last_name ?? ""
              }`}
              isStaticRow
            />
          )}

          {!!row.assigned_teams.length && (
            <MultiSelectSummary
              items={row.assigned_teams}
              limit={3}
              itemsToIcons={({ team }) => {
                const icon = team?.icon as IconData
                return (
                  <TeamIcon
                    name={icon?.name}
                    color={icon?.color}
                    key={team?.id}
                    className="!h-5 !w-5"
                  />
                )
              }}
              firstItemLabel={`${row.assigned_teams[0].team?.name ?? ""}`}
              mode="summary"
              isStaticRow
            />
          )}

          {!!row.categories.length && (
            <MultiSelectSummary
              items={row.categories}
              limit={3}
              itemsToIcons={(cat) => (
                <CategoryColor
                  color={cat.category.color}
                  key={cat.category.label}
                  className="!h-4 !w-4 !border-2 border-transparent"
                />
              )}
              mode="summary"
              firstItemLabel={row.categories[0]?.category.label}
              isStaticRow
            />
          )}
        </HStack>
      </VStack>

      {((progressInfo && progressInfo.total > 0) || row.subtasks.length > 0) && (
        <div className="flex flex-row gap-x-8">
          <HStack space={4} align="center">
            <span className="mr-2">{i18n.t("tasks:fields.checklist")}</span>
            {progressInfo && progressInfo.total > 0 ? (
              <Tooltip
                content={i18n.t("tasks:checklist.status", {
                  count: progressInfo.total_with_response,
                  total: progressInfo.total,
                })}>
                <div className="inline-flex items-center rounded border py-1 pl-1 pr-1.5 text-xs">
                  <CircularProgress
                    size={16}
                    progress={progressInfo.percent_with_response / 100}
                  />
                  <span className="ml-1 text-gray-500">
                    {progressInfo.total_with_response}/{progressInfo.total}
                  </span>
                </div>
              </Tooltip>
            ) : (
              <span className="text-gray-500">-</span>
            )}
          </HStack>

          <HStack space={4} align="center">
            <span className="mr-2">{i18n.t("common:subtask", { count: 2 })}</span>
            {row.subtasks.length > 0 ? (
              <WorkOrderSubtaskTag subtasks={row.subtasks} />
            ) : (
              <span className="text-gray-500">-</span>
            )}
          </HStack>
        </div>
      )}
    </VStack>
  )
}
