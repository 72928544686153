import EditUserProfileForm from "@components/settings/edit-user-profile-form"
import { uuid } from "@elara/db"

export type EditUserProfileProps = {
  userId?: uuid | null
  visible: boolean
  onClose: () => void
}
export const EditUserProfile = (props: EditUserProfileProps) => {
  return (
    <EditUserProfileForm
      isOpen={props.visible}
      onOpenChange={() => props.onClose()}
      userId={props.userId}
    />
  )
}

export default EditUserProfile
