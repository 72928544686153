import Button from "@components/shared/button"
import { IPermissionScopeEnum, uuid } from "@elara/db"
import { useEditAssetNameMutation } from "@graphql/documents/asset.generated"
import { useCallbackRef, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { Pencil } from "@phosphor-icons/react"
import { useEffect, useState } from "react"

export const AssetRenameAction = (props: {
  id: uuid
  name: string
  triggerClose: () => void
}) => {
  const [name, setName] = useState(props.name)
  const [isLoading, setIsLoading] = useState(false)

  const [, editName] = useEditAssetNameMutation()
  const scope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)

  const submit = useCallbackRef(async () => {
    setIsLoading(true)
    await editName({ id: props.id, name }, scope.context())
    setIsLoading(false)
    props.triggerClose()
  })

  useEffect(() => {
    const cmdEnter = (e: KeyboardEvent) => {
      if (e.key === "Enter" && e.metaKey) {
        submit()
      }
    }

    document.addEventListener("keydown", cmdEnter)

    return () => {
      document.removeEventListener("keyup", cmdEnter)
    }
  })

  return (
    <div className="w-[768px] max-w-md">
      <div className="rounded bg-gray-100 px-2 py-1 text-sm text-gray-500">
        {i18n.t("common:asset", { count: 1 })} - {props.name}
      </div>
      <div className="my-4 flex gap-x-2">
        <input value={name} onChange={(e) => setName(e.target.value)} className="flex-1 " />
        <div>
          <Button type="secondary" isLoading={isLoading} onClick={submit}>
            {i18n.t("common:save")}
          </Button>
        </div>
      </div>
      <div className="-mx-4 -mb-4 flex items-center bg-gray-200 px-4 py-2 text-sm">
        <Pencil size={20} className="mr-2" />{" "}
        <span className="shrink-0 whitespace-nowrap">Objekt umbennen zu</span>{" "}
        <span className="ml-1 truncate text-gray-500">"{name}"</span>
      </div>
    </div>
  )
}
