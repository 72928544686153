import {
  getMonthlyModifierOptions,
  getMonthOptions,
  getWeekdayOptions,
} from "@components/maintenance/types"
import { generateRRuleObject, getRRuleNextAt } from "@components/maintenance/utils"
import { Button } from "@components/shared"
import AlertBox from "@components/shared/alert-box"
import { ComboboxMultiSelect } from "@components/shared/combobox-select"
import DurationInput from "@components/shared/duration-input"
import { FormField } from "@components/shared/form/form-field"
import FormLabel from "@components/shared/form/form-label"
import Tabs from "@components/shared/horizontal-tabs"
import { DateNative, TimeNative } from "@components/shared/native-date-time-pickers"
import { PopoverContent, PopoverTrigger } from "@components/shared/popover"
import { RadioGroup, RadioGroupItem } from "@components/shared/radio"
import { ScrollArea } from "@components/shared/scroll-area"
import { Select } from "@components/shared/select"
import { TextInput } from "@components/shared/text-input"
import { TimeField } from "@components/shared/time-field"
import Toggle from "@components/shared/toggle"
import { ToggleGroup, ToggleGroupItem } from "@components/shared/toggle-group"
import { Tooltip } from "@components/shared/tooltip"
import { Ends, Frequency, MonthlyModifier, TimeIntervalTriggerOptions } from "@elara/db"
import { useAutoAnimate } from "@formkit/auto-animate/react"
import i18n from "@i18n"
import { CaretDown, Timer, TrashSimple, X } from "@phosphor-icons/react"
import { Popover } from "@radix-ui/react-popover"
import { cn } from "@utils"
import { formatDate, getNumberWeekday } from "@utils/date"
import { now, parseAbsolute } from "@utils/tzdate"
import { format, parseISO } from "date-fns"
import { useField } from "formik"
import { useMemo, useState } from "react"
import { Trans } from "react-i18next"

interface TimeIntervalTriggerFormProps {
  prefix: string
  onDelete?: () => void
}

const frequencyToLabelMap: Record<string, string> = {
  secondly: "second",
  minutely: "minute",
  hourly: "hour",
  daily: "day",
  weekly: "week",
  monthly: "month",
  yearly: "year",
}

type TimeIntervalTriggerPayload = TimeIntervalTriggerOptions["payload"]

const TimeIntervalTriggerForm = ({ prefix, onDelete }: TimeIntervalTriggerFormProps) => {
  const [animationParent] = useAutoAnimate()

  const [{ value: payload }, payloadMeta, payloadHelpers] =
    useField<TimeIntervalTriggerPayload>(`${prefix}`)

  const [showHourTime, setShowHourTime] = useState<boolean>(() => {
    const { hourly } = payload.repetition
    if (hourly && (hourly.from || hourly.to)) return true
    return false
  })

  const nextOccurrence = useMemo(() => {
    const rrule = generateRRuleObject(payload)
    const utcd = getRRuleNextAt(rrule)

    if (!utcd) return null

    return utcd
  }, [payload])

  return (
    <div
      ref={animationParent}
      className={cn("relative rounded border p-4", {
        "border-red-500": payloadMeta.touched && payloadMeta.error,
      })}>
      <div className="absolute inset-x-2 -top-2 flex items-center justify-between">
        <p className="bg-white px-2 text-xs">
          {i18n.t("maintenance:labels.triggers.time.title")}
        </p>

        {onDelete && (
          <div className="bg-white px-2">
            <TrashSimple
              onClick={onDelete}
              className="cursor-pointer text-red-300 hover:text-red-500"
            />
          </div>
        )}
      </div>

      {/* {isLocalhostOrTest && (
        <pre className="mb-3 overflow-x-scroll rounded bg-gray-900 p-5 text-xs text-gray-50">
          <code>{JSON.stringify(payload, null, 2)}</code>
        </pre>
      )} */}

      <FormLabel label={i18n.t("maintenance:fields.start_at")} required>
        <div className="flex w-2/3 items-center gap-2">
          <FormField noStyle name={`${prefix}.nextDate`}>
            {({ field, helpers }) => (
              <DateNative
                className="ml-2"
                value={parseISO(field.value)}
                onChange={(date) => {
                  if (date) {
                    const dateString = formatDate(date, "yyyy-MM-dd")
                    helpers.setValue(dateString)
                  }
                }}
              />
            )}
          </FormField>

          <FormField noStyle name={`${prefix}.nextTime`}>
            {({ field, helpers }) => (
              <div className="flex items-center gap-1">
                {field.value ? (
                  <>
                    <TimeField
                      aria-label={i18n.t("tasks:fields.due_time")}
                      value={parseAbsolute(field.value)}
                      onValueChange={(value) => {
                        value && helpers.setValue(value.toAbsoluteString())
                      }}
                    />

                    <Button
                      color="gray"
                      type="tertiary"
                      icon={X}
                      onClick={() => helpers.setValue(undefined)}
                    />
                  </>
                ) : (
                  <Button
                    icon={Timer}
                    type="tertiary"
                    onClick={() =>
                      helpers.setValue(
                        now().set({ second: 0, millisecond: 0 }).toAbsoluteString()
                      )
                    }>
                    {i18n.t("maintenance:messages.add_time")}
                  </Button>
                )}
              </div>
            )}
          </FormField>
        </div>

        {payloadMeta.error && (payloadMeta.error as any).startDate && (
          <AlertBox type="danger" className="my-3">
            {(payloadMeta.error as any).startDate}
          </AlertBox>
        )}
      </FormLabel>

      <FormLabel required label="Repetition" className="!mb-0">
        <Tabs.Root value={payload.repetition.frequency}>
          <ScrollArea horizontal hideScrollbar>
            <FormField noStyle name={`${prefix}.repetition.frequency`}>
              {({ field, helpers }) => (
                <ToggleGroup
                  type="single"
                  value={field.value}
                  onValueChange={(frequency) => {
                    if (!frequency) return

                    if (frequency === Frequency.monthly) {
                      payloadHelpers.setValue({
                        ...payload,
                        repetition: {
                          ...payload.repetition,
                          monthly: {
                            modifier:
                              payload.repetition.monthly?.modifier ??
                              MonthlyModifier.by_date,
                          },
                        },
                      })
                    }

                    helpers.setValue(frequency as Frequency)
                  }}>
                  <ToggleGroupItem value={Frequency.hourly}>
                    {i18n.t("maintenance:fields.frequency.hourly")}
                  </ToggleGroupItem>
                  <ToggleGroupItem value={Frequency.daily}>
                    {i18n.t("maintenance:fields.frequency.daily")}
                  </ToggleGroupItem>
                  <ToggleGroupItem value={Frequency.weekly}>
                    {i18n.t("maintenance:fields.frequency.weekly")}
                  </ToggleGroupItem>
                  <ToggleGroupItem value={Frequency.monthly}>
                    {i18n.t("maintenance:fields.frequency.monthly")}
                  </ToggleGroupItem>
                  <ToggleGroupItem value={Frequency.yearly}>
                    {i18n.t("maintenance:fields.frequency.yearly")}
                  </ToggleGroupItem>
                </ToggleGroup>
              )}
            </FormField>
          </ScrollArea>

          <div className="mt-4">
            <div className="mb-3 flex flex-wrap items-center gap-2 whitespace-nowrap text-sm">
              {i18n.t("maintenance:repetition.prefix")}
              <FormField noStyle name={`${prefix}.repetition.interval`}>
                <TextInput min={1} type="number" className="max-w-[60px]" />
              </FormField>
              {i18n.t(
                `calendar:tokens.${frequencyToLabelMap[payload.repetition.frequency]}`,
                { count: payload.repetition.interval }
              )}
              <span>{i18n.t("maintenance:repetition.suffix")}</span>
            </div>

            <Tabs.Content value={Frequency.hourly} className="mb-3 space-y-3">
              {showHourTime ? (
                <div className="flex flex-wrap items-center gap-2 whitespace-nowrap text-sm">
                  {i18n.t("calendar:tokens.from")}
                  <FormField noStyle name={`${prefix}.repetition.hourly.from`}>
                    {({ field, helpers }) => (
                      <TimeNative value={field.value} onChange={helpers.setValue} />
                    )}
                  </FormField>
                  {i18n.t("calendar:tokens.to")}
                  <FormField noStyle name={`${prefix}.repetition.hourly.to`}>
                    {({ field, helpers }) => (
                      <TimeNative value={field.value} onChange={helpers.setValue} />
                    )}
                  </FormField>
                  <FormField noStyle name={`${prefix}.repetition.hourly`}>
                    {({ helpers }) => (
                      <Button
                        color="gray"
                        type="tertiary"
                        icon={X}
                        onClick={() => {
                          setShowHourTime(false)
                          helpers.setValue(undefined)
                        }}
                      />
                    )}
                  </FormField>
                </div>
              ) : (
                <div className="flex">
                  <Button
                    icon={Timer}
                    type="tertiary"
                    onClick={() => setShowHourTime(true)}>
                    {i18n.t("maintenance:messages.add_time_range")}
                  </Button>
                </div>
              )}

              <FormField noStyle name={`${prefix}.repetition.hourly.weekdays`}>
                {({ field, helpers }) => (
                  <ToggleGroup
                    type="multiple"
                    className="max-w-xs"
                    value={field.value}
                    onValueChange={(weekdays) => helpers.setValue(weekdays)}>
                    {getWeekdayOptions().map(({ label, name, value }, index) => (
                      <ToggleGroupItem key={index} value={value}>
                        <Tooltip
                          content={name}
                          delayDuration={0}
                          contentProps={{ side: "bottom" }}>
                          {label}
                        </Tooltip>
                      </ToggleGroupItem>
                    ))}
                  </ToggleGroup>
                )}
              </FormField>
            </Tabs.Content>
            <Tabs.Content value={Frequency.daily} />
            <Tabs.Content value={Frequency.weekly} className="mb-3 space-y-3">
              <FormField noStyle name={`${prefix}.repetition.weekly.weekdays`}>
                {({ field, helpers }) => (
                  <ToggleGroup
                    type="multiple"
                    className="max-w-xs"
                    value={field.value}
                    onValueChange={(weekdays) => helpers.setValue(weekdays)}>
                    {getWeekdayOptions().map(({ label, name, value }, index) => (
                      <ToggleGroupItem key={index} value={value}>
                        <Tooltip
                          content={name}
                          delayDuration={0}
                          contentProps={{ side: "bottom" }}>
                          {label}
                        </Tooltip>
                      </ToggleGroupItem>
                    ))}
                  </ToggleGroup>
                )}
              </FormField>
            </Tabs.Content>
            <Tabs.Content value={Frequency.monthly} className="mb-3 space-y-2">
              <FormField
                className="mb-2 max-w-xs"
                hasErrorPlaceholder={false}
                name={`${prefix}.repetition.monthly.modifier`}>
                {({ field, helpers }) => (
                  <Select
                    items={getMonthlyModifierOptions({
                      date:
                        i18n.language === "de-DE"
                          ? `${format(new Date(payload.nextDate), "d")}.`
                          : format(new Date(payload.nextDate), "do"),
                      weekday: getNumberWeekday(new Date(payload.nextDate)),
                    })}
                    value={field.value}
                    onValueChange={helpers.setValue}
                  />
                )}
              </FormField>

              {/* Which months should this trigger be applied to? (BYMONTH) */}
              <FormField
                className="mb-6 max-w-xs"
                hasErrorPlaceholder={false}
                name={`${prefix}.repetition.monthly.bymonth`}>
                {({ field, helpers }) => (
                  <Popover>
                    <PopoverTrigger>
                      <div className="flex items-center rounded border px-2 py-1.5">
                        {(field.value ?? []).length === 0 && (
                          <span className="opacity-50">
                            {i18n.t("maintenance:fields.bymonth")}
                          </span>
                        )}

                        <p className="mr-2 line-clamp-1 flex-1 text-left">
                          {getMonthOptions()
                            .filter((option) => (field.value ?? []).includes(option.value))
                            .map((opt) => opt.name)
                            .join(", ")}
                        </p>

                        <CaretDown />
                      </div>
                    </PopoverTrigger>
                    <PopoverContent side="bottom" align="end">
                      <ComboboxMultiSelect
                        value={field.value ?? []}
                        valueToString={(value) => value}
                        onChange={(value) => helpers.setValue(value)}
                        items={getMonthOptions().map((option) => ({
                          label: option.name,
                          value: option.value,
                          searchValue: option.name,
                        }))}
                      />
                    </PopoverContent>
                  </Popover>
                )}
              </FormField>
            </Tabs.Content>
            <Tabs.Content value={Frequency.yearly} className="space-y-3" />
          </div>
        </Tabs.Root>
      </FormLabel>

      {nextOccurrence && (
        <AlertBox type="info" className="mb-3">
          <Trans
            i18n={i18n}
            i18nKey="maintenance:messages.next_maintenance_at"
            components={{ bold: <span className="font-medium" /> }}
            values={{
              date: formatDate(new Date(nextOccurrence), payload.nextTime ? "PPPp" : "PPP"),
            }}
          />
        </AlertBox>
      )}

      <FormLabel
        label={i18n.t("maintenance:fields.create_before")}
        truncate={false}
        required>
        <FormField noStyle name={`${prefix}.createBefore`}>
          {({ field, helpers }) => (
            <div className="inline-flex items-center gap-2">
              <DurationInput
                className="w-[180px]"
                value={field.value}
                onChange={helpers.setValue}
              />

              <span className="font-normal">
                {i18n.t("maintenance:fields.create_before_suffix")}
              </span>
            </div>
          )}
        </FormField>
      </FormLabel>

      <label className="my-3 flex items-center">
        <FormField noStyle name={`${prefix}.createNextOnlyIfLastComplete`}>
          {({ field, helpers }) => (
            <Toggle
              checked={field.value}
              onChange={(e) => helpers.setValue(e.target.checked)}
            />
          )}
        </FormField>
        <p className="ml-3 whitespace-pre-wrap text-[13px]">
          {i18n.t("maintenance:fields.create_next_if_last_complete")}
        </p>
      </label>

      <FormLabel required label={i18n.t("maintenance:fields.ends")}>
        <FormField noStyle name={`${prefix}.ends.value`}>
          {({ field, helpers }) => (
            <RadioGroup
              value={field.value}
              onValueChange={(value) => {
                if (value === "on") {
                  payloadHelpers.setValue({
                    ...payload,
                    ends: {
                      ...payload.ends,
                      date: new Date().toISOString(),
                    },
                  })
                }

                helpers.setValue(value)
              }}
              className="flex flex-col gap-y-3 pl-1 text-sm">
              <RadioGroupItem value={Ends.never}>
                {i18n.t("maintenance:labels.end_options.never")}
              </RadioGroupItem>
              <RadioGroupItem value={Ends.on}>
                <div className="flex items-center">
                  {i18n.t("maintenance:labels.end_options.on")}
                  {field.value === Ends.on && (
                    <FormField noStyle name={`${prefix}.ends.date`}>
                      {({ field, helpers }) => (
                        <DateNative
                          className="ml-2"
                          value={new Date(field.value)}
                          onChange={(date) => date && helpers.setValue(date.toISOString())}
                        />
                      )}
                    </FormField>
                  )}
                </div>
              </RadioGroupItem>
            </RadioGroup>
          )}
        </FormField>
      </FormLabel>
    </div>
  )
}

export default TimeIntervalTriggerForm
