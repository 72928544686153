import NavigationMenuV3 from "@components/page/navigation-menu"
import { usePage } from "@contexts/page-context"

const MorePageMobile: React.FC = () => {
  usePage({ id: "menu", title: "Menü" })

  return <NavigationMenuV3 />
}

export default MorePageMobile
