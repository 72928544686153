import { DataViewChunkedFetcher } from "@components/new-data-view/data-view-chunked-fetcher"
import { useDataViewFilterContext } from "@components/new-data-view/data-view-filter"
import { useDataViewGroupByContext } from "@components/new-data-view/data-view-group-by"
import { useDataViewSortContext } from "@components/new-data-view/data-view-sort"
import { Data, OrderBy, Where } from "@elara/select"
import { PropsWithChildren } from "react"
import { unstable_serialize } from "swr"
import { AnyVariables, TypedDocumentNode } from "urql"

import { useDataViewSearchContext } from "./data-view-search"
import { TreeExtension } from "./data-view-types"

export type DataViewChunkedProps<
  D extends Data,
  R,
  V extends AnyVariables = AnyVariables
> = PropsWithChildren<{
  chunkSize: number
  query: TypedDocumentNode<R, V>
  getData: (data: R | undefined) => D[]
  where?: Where<D>
  numberOfRows: (where: Where<D>) => Promise<number>
  tree?: TreeExtension<D>
}>

const useDataViewQueryParametersKey = () => {
  const filters = useDataViewFilterContext()
  const search = useDataViewSearchContext()
  const groupBy = useDataViewGroupByContext()
  const sort = useDataViewSortContext()

  return unstable_serialize([
    filters.whereStatement,
    groupBy.groupBy,
    sort.orderByStatements,
    search.searchQuery,
  ])
}

export const DataViewChunked = <D extends Data, R, V extends AnyVariables>(
  props: DataViewChunkedProps<D, R, V>
) => {
  const key = useDataViewQueryParametersKey()
  const sort = useDataViewSortContext()
  const orderBy = sort.orderByStatements as OrderBy<D>[]
  return (
    <DataViewChunkedFetcher<D, R, V>
      getData={props.getData}
      query={props.query}
      key={key}
      where={props.where}
      orderBy={orderBy}
      chunkSize={props.chunkSize}
      numberOfRows={props.numberOfRows}>
      {props.children}
    </DataViewChunkedFetcher>
  )
}
