import { disablePushNotifications } from "@components/notifications/use-onesignal"
import { IPermissionScopeEnum, uuid } from "@elara/db"
import { clearStickyState, useCallbackRef, useStickyState } from "@hooks"
import { completeSession } from "@hooks/use-session-tracking"
import { useKeycloak } from "@react-keycloak/web"
import { fetchWithRetry } from "@utils/fetch"
import jwtDecode from "jwt-decode"
import Keycloak from "keycloak-js"
import { useReducer, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { z } from "zod"

let keycloakOverride = null
try {
  if (window.localStorage.getItem("keycloak_url")) {
    keycloakOverride = window.localStorage.getItem("keycloak_url")
  }
} catch {}

export const keycloak = new Keycloak({
  realm: "elara",
  url:
    keycloakOverride ??
    (import.meta.env.VITE_KEYCLOAK_BASE_URL as string) ??
    window.location.origin + "/auth",
  clientId: "web-app",
})

type HasuraClaims = {
  "x-hasura-user-id": uuid
  "x-hasura-org-id": uuid
  "x-hasura-location-id": uuid
  "x-hasura-allowed-roles": IPermissionScopeEnum[]
}

const AuthLocationsSchema = z.object({
  locations: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      org: z.object({
        id: z.string(),
        name: z.string(),
      }),
      approved: z.boolean(),
    })
  ),
  user: z.object({
    id: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    email: z.string().nullable().optional(),
  }),
})

type AuthLocations = z.infer<typeof AuthLocationsSchema>["locations"]

async function fetchPossibleLocations() {
  const res = await fetchWithRetry("/api/auth/locations", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ access_token: keycloak.token }),
  })
  const data = AuthLocationsSchema.parse(await res.json())
  data.locations.sort((a, b) => a.name.localeCompare(b.name))
  return data
}

const AuthDataSchema = z.object({
  data_access_token: z.string(),
})

async function fetchDataAccessToken(locationId: uuid) {
  await keycloak.updateToken(15)
  const res = await fetchWithRetry("/api/auth/data", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ access_token: keycloak.token, location_id: locationId }),
  })

  const data = AuthDataSchema.parse(await res.json())

  return data
}

export function useLogout() {
  const { keycloak } = useKeycloak()
  return async () => {
    try {
      await Promise.race([
        Promise.all([completeSession(), disablePushNotifications()]),
        new Promise<void>((resolve) => {
          setTimeout(() => resolve(), 500)
        }),
      ])
      clearStickyState()
    } finally {
      // Redirect to location origin, otherwise if you login in again directly
      // you are still most likely on the settings page since the log out was triggered there
      await keycloak.logout({ redirectUri: window.location.origin })
    }
  }
}

async function exchangeKeycloakAccessTokenForDataAccessToken(locationId: uuid) {
  const { data_access_token: dataAccessToken } = await fetchDataAccessToken(locationId)
  return dataAccessToken
}

export type FullyAuthenticatedState = {
  stage: "fully_authenticated"
  dataLocationId: string
  hasuraClaims: HasuraClaims
  userInfo: { id: string; first_name: string; last_name: string }
  locations: AuthLocations
}
type AuthState =
  | { stage: "authentication_failed" }
  | { stage: "not_authenticated" }
  | {
      stage: "basic_authenticated"
    }
  | {
      stage: "with_location_infos"
      userInfo: { id: string; first_name: string; last_name: string }
      locations: AuthLocations
    }
  | FullyAuthenticatedState

type AuthAction =
  | { type: "KEYCLOAK_AUTHENTICATED" }
  | { type: "LOGOUT" }
  | {
      type: "UPDATE_LOCATION_INFOS"
      userInfo: { id: string; first_name: string; last_name: string; email?: string | null }
      locations: AuthLocations
    }
  | { type: "UPDATE_DATA_ACCESS_TOKEN"; dataAccessToken: string; dataLocationId: string }
  | { type: "DATA_ACCESS_TOKEN_EXCHANGE_FAILED" }

function authStateReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case "LOGOUT":
      return { stage: "not_authenticated" }

    case "KEYCLOAK_AUTHENTICATED":
      if (state.stage === "not_authenticated" || state.stage === "authentication_failed") {
        return {
          stage: "basic_authenticated",
        }
      }
      return state

    case "UPDATE_LOCATION_INFOS":
      if (state.stage === "basic_authenticated") {
        return {
          ...state,
          stage: "with_location_infos",
          userInfo: action.userInfo,
          locations: action.locations,
        }
      } else if (
        state.stage === "with_location_infos" ||
        state.stage === "fully_authenticated"
      ) {
        return {
          ...state,
          userInfo: action.userInfo,
          locations: action.locations,
        }
      }
      return state
    case "UPDATE_DATA_ACCESS_TOKEN":
      if (state.stage === "with_location_infos" || state.stage === "fully_authenticated") {
        const payload = jwtDecode<{ hasura: HasuraClaims }>(action.dataAccessToken)
        return {
          ...state,
          hasuraClaims: payload.hasura,
          stage: "fully_authenticated",
          dataLocationId: action.dataLocationId,
        }
      }
      return state
    case "DATA_ACCESS_TOKEN_EXCHANGE_FAILED":
      return { stage: "authentication_failed" }
  }
}

export function useAuthenticationState() {
  const [state, dispatch] = useReducer(authStateReducer, { stage: "not_authenticated" })
  const [selectedLocation, setSelectedLocation] = useStickyState<uuid | null>(
    null,
    "selected-location"
  )

  // We store the data access token in a ref so that we can make sure that async
  // callbacks always have the latest token after it has been updated
  const dataAccessToken = useRef<string>("")
  const navigate = useNavigate()
  const location = useLocation()

  const dataTokenIsFetching = useRef(false)

  const getDataAccessToken = useCallbackRef(() => dataAccessToken.current)

  const updateLocation = useCallbackRef(async (locationId: string) => {
    await fetchDataAccessToken(locationId)
    setSelectedLocation(locationId)
  })

  const fetchDataAccessToken = useCallbackRef(async (_locationId?: string) => {
    let locationId = _locationId
    if (!_locationId && state.stage === "fully_authenticated") {
      locationId = state.dataLocationId
    }
    if (locationId) {
      if (!dataTokenIsFetching.current) {
        dataTokenIsFetching.current = true
        try {
          const token = await exchangeKeycloakAccessTokenForDataAccessToken(locationId)
          dataAccessToken.current = token
          dispatch({
            type: "UPDATE_DATA_ACCESS_TOKEN",
            dataAccessToken: token,
            dataLocationId: locationId,
          })
        } catch (e) {
          console.warn(e)
        } finally {
          dataTokenIsFetching.current = false
        }
      }
    }
  })

  const updatePossibleLocations = useCallbackRef(async () => {
    const { user, locations } = await fetchPossibleLocations()
    dispatch({ type: "UPDATE_LOCATION_INFOS", userInfo: user, locations })
    return { user, locations }
  })

  const onKeycloakAccessTokenUpdate = useCallbackRef(async () => {
    if (state.stage === "not_authenticated") {
      dispatch({ type: "KEYCLOAK_AUTHENTICATED" })
      const { locations } = await updatePossibleLocations()

      if (locations.length === 1 && locations[0].approved) {
        await updateLocation(locations[0].id)
        // Check if we have a selected location
      } else if (
        selectedLocation &&
        locations.find((l) => l.approved && l.id === selectedLocation)
      ) {
        await fetchDataAccessToken(selectedLocation)
      } else {
        if (location.pathname.startsWith("/invite")) {
          return
        } else {
          navigate(`/select-location?redirect_to=${encodeURIComponent(location.pathname)}`)
        }
      }
    } else if (state.stage === "fully_authenticated") {
      await fetchDataAccessToken(state.dataLocationId)
    }
  })

  const afterInviteAccept = useCallbackRef(async (locationId: string) => {
    await updatePossibleLocations()
    await fetchDataAccessToken(locationId)
    setSelectedLocation(locationId)
  })

  const authState = {
    state,
    onKeycloakAccessTokenUpdate,
    fetchDataAccessToken,
    getDataAccessToken,
    afterInviteAccept,
    updateLocation,
    updatePossibleLocations,
  }
  return authState
}

export type AuthenticationState = ReturnType<typeof useAuthenticationState>
