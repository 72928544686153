import { ActionMenu } from "@components/shared"
import Button from "@components/shared/button"
import { ComboboxSelect } from "@components/shared/combobox-select"
import { DataViewUpdateCustomViewConfig } from "@components/shared/data-view/data-view-update-custom-view-config"
import { DragHandle } from "@components/shared/drag-handle"
import { PopoverContent, PopoverRoot, PopoverTrigger } from "@components/shared/popover"
import ScrollArea from "@components/shared/scroll-area"
import { IconData, TeamIcon } from "@components/shared/team-icons"
import { useUserFavorite } from "@components/shared/user-favorite"
import { defaultConfig } from "@components/view/default-views"
import { TeamViewsDialog } from "@components/view/team-views-dialog"
import { useBreakpoint } from "@contexts/breakpoints"
import { IViewDataTypeEnum, View } from "@elara/db"
import { orderBy } from "@elara/select"
import { ICustomViewFragment } from "@graphql/documents/custom-view.generated"
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd"
import { useDisclosure } from "@hooks"
import { useDraggableInPortal } from "@hooks/use-draggable-in-portal"
import { useElementSize } from "@hooks/use-element-size"
import i18n from "@i18n"
import {
  CaretDown,
  DotsThree,
  DotsThreeVertical,
  EyeSlash,
  PencilSimpleLine,
  Plus,
  Star,
} from "@phosphor-icons/react"
import * as Tabs from "@radix-ui/react-tabs"
import Icons from "@resources/icons"
import classNames from "classnames"
import { move } from "formik"
import React, { useMemo, useRef } from "react"
import { useState } from "react"
import { Icon } from "src/types"

import {
  CollectionView,
  CollectionViewSelection,
  UseDataViewTabs,
} from "./data-view-tabs.hooks"

const AddViewButton = (props: {
  collectionViews: View[]
  customViews: ICustomViewFragment[]
  defaultCollectionViews: CollectionView[]
  onCreateView: () => void
  addViewToCollection: (selection: CollectionViewSelection) => void
}) => {
  const disclosure = useDisclosure()
  const bp = useBreakpoint()

  // Shared Views
  const sharedViews = orderBy(props.customViews, { name: "asc" }).filter(
    (v) => !props.collectionViews.find((view) => view.id === v.id) && v.shared
  )

  // Private Views
  const privateViews = orderBy(props.customViews, { name: "asc" }).filter(
    (v) => !props.collectionViews.find((view) => view.id === v.id) && !v.shared
  )

  // Default Views
  const defaultViews = props.defaultCollectionViews
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter((v) => !props.collectionViews.find((view) => view.id === v.id))

  return (
    <PopoverRoot open={disclosure.isOpen} onOpenChange={disclosure.changeOpen}>
      <PopoverTrigger asChild>
        <Button
          color="gray"
          type="tertiary"
          icon={Plus}
          className="radix-state-open:bg-gray-100">
          {i18n.t("common:view", { count: 1 })}
        </Button>
      </PopoverTrigger>

      <PopoverContent className="text-sm" side="left" align="start" sideOffset={4}>
        <ComboboxSelect<string>
          groupBy
          placeholder={i18n.t("views:actions.search_view")}
          listMaxHeight={bp.renderDesktop ? 300 : 500}
          onChange={async (value) => {
            const view = props.customViews.find((v) => v.id === value)

            if (view) {
              props.addViewToCollection({
                id: view.id,
                name: view.name,
                icon: view.icon,
                customView: view,
                type: "custom_view",
              })
            } else {
              const d = props.defaultCollectionViews.find((v) => v.id === value)

              if (!d) return

              props.addViewToCollection({
                id: d.id,
                icon: null,
                name: d.name,
                defaultView: d,
                type: "default_view",
              })
            }
            disclosure.onClose()
          }}
          items={[
            {
              value: "shared",
              label: i18n.t("views:labels.shared_views"),
              searchValue: i18n.t("views:labels.shared_views"),
              children: sharedViews.map((v) => ({
                value: v.id,
                label: <span>{v.name}</span>,
                icon: <TeamIcon {...v.icon} />,
                searchValue: v.name,
              })),
            },
            {
              value: "private",
              label: i18n.t("views:labels.private_views"),
              searchValue: i18n.t("views:labels.private_views"),
              children: privateViews.map((v) => ({
                value: v.id,
                label: <span>{v.name}</span>,
                icon: <Icons.Lock />,
                searchValue: v.name,
              })),
            },
            {
              value: "default",
              label: i18n.t("views:labels.default_views"),
              searchValue: i18n.t("views:labels.default_views"),
              children: defaultViews.map((v) => ({
                value: v.id,
                label: <span>{v.name}</span>,
                icon: v.icon && React.createElement(v.icon as Icon),
                searchValue: v.name,
              })),
            },
          ]}
        />
        <hr />
        <Button
          type="tertiary"
          onClick={props.onCreateView}
          className="my-1 mr-auto"
          icon={Plus}>
          {i18n.t("views:actions.create_new_view")}
        </Button>
      </PopoverContent>
    </PopoverRoot>
  )
}

const SelectedTabActionMenu = (
  props: React.PropsWithChildren<{
    className?: string
    customViewId: string
    isDefaultView: boolean
    onEdit: () => void
    onRemove: () => void
  }>
) => {
  const { toggleFavorite, isFavorite } = useUserFavorite({
    ...props,
    ...(props.isDefaultView
      ? { defaultViewId: props.customViewId, customViewId: null }
      : {}),
  })

  const favoriteIcon = useMemo(
    () => (
      <Star
        className={classNames("text-gray-500 group-hover:text-gray-700", {
          "text-yellow-400 fill-yellow-400 group-hover:!text-yellow-400 ": isFavorite,
        })}
      />
    ),
    [isFavorite]
  )

  return (
    <ActionMenu
      asChild
      items={[
        {
          key: "fav",
          action: toggleFavorite,
          icon: favoriteIcon,
          label: isFavorite
            ? i18n.t("views:actions.unfavorite_view")
            : i18n.t("views:actions.favorite_view"),
        },
        {
          key: "edit",
          action: props.onEdit,
          label: i18n.t("views:actions.rename_view"),
          icon: <PencilSimpleLine />,
          disabled: props.isDefaultView,
          tooltip: props.isDefaultView
            ? i18n.t("views:messages.rename_view_disabled")
            : undefined,
        },
        {
          key: "remove",
          action: props.onRemove,
          label: i18n.t("views:actions.remove_view"),
          icon: <EyeSlash />,
        },
      ]}
      className={props.className}>
      {props.children}
    </ActionMenu>
  )
}

function useTabsToRender(props: {
  tabs: CollectionViewSelection[]
  selectedTab: CollectionViewSelection | null
}) {
  const { selectedTab, tabs } = props
  const bp = useBreakpoint()
  const tabsContainerRef = useRef<HTMLDivElement>(null)
  const size = useElementSize(tabsContainerRef?.current ?? null)

  // all units in rem

  // 1. Calculate the maximum width of the tabs bar
  const MAX_TOTAL_WIDTH = bp.sm ? (size?.width ?? 0) / 16 - 240 / 16 : 0

  // 2. Calculate the width of the selected tab
  let totalWidth = selectedTab ? selectedTab.name.length * 0.5 + 1 + 0.5 : 0

  // 3. Calculate the number of tabs to be displayed
  let k = selectedTab ? 1 : 0

  // 4. Loop through all tabs and calculate the total width
  for (let tab of tabs) {
    if (tab.id === selectedTab?.id) continue
    const tabWidth = tab.name.length * 0.5 + 2
    if (totalWidth + tabWidth > MAX_TOTAL_WIDTH && k > 0) break
    totalWidth += tabWidth
    k += 1
  }

  // 5. Find the index of the selected tab
  let indexOfSelectedTab = tabs.findIndex((t) => t.id === selectedTab?.id)

  // 6. Check if the selected tab is not displayed
  const pushSelectedTab = indexOfSelectedTab + 1 > k

  // 7. Calculate the number of hidden tabs
  const numberOfHiddenTabs = tabs.length - k - (pushSelectedTab ? 1 : 0)

  const renderSelectedTabAsPopover =
    !bp.sm && (k === 1 || (k === 0 && pushSelectedTab)) && !!selectedTab
  const tabsToRender = tabs.slice(0, k)
  if (pushSelectedTab && selectedTab) {
    tabsToRender.push(selectedTab)
  }

  return {
    renderSelectedTabAsPopover,
    tabsToRender,
    tabsContainerRef,
    numberOfHiddenTabs,
  }
}

const getTabIcon = (tab: CollectionViewSelection) => {
  if (tab.type === "default_view" && tab.icon) {
    return <div className="mr-2 shrink-0 opacity-75">{React.createElement(tab.icon)}</div>
  }

  if (tab.type === "custom_view") {
    return <TeamIcon {...(tab.icon as IconData)} className="mr-2 shrink-0 opacity-75" />
  }

  return null
}

export function DataViewTabsTabBar(props: {
  dataType: IViewDataTypeEnum
  dataViewTabs: UseDataViewTabs
}) {
  const { dataViewTabs } = props
  const { tabs, selectedTab } = dataViewTabs

  const bp = useBreakpoint()
  const selectView = useDisclosure()
  const tabsPopoverDisclosure = useDisclosure()
  const renderDraggable = useDraggableInPortal()

  const [selectedCustomView, setSelectedCustomView] = useState<
    Partial<ICustomViewFragment>
  >({ shared: true })
  const createView = useDisclosure({
    onClose: () => setSelectedCustomView({ shared: true }),
  })

  const { tabsContainerRef, renderSelectedTabAsPopover, tabsToRender, numberOfHiddenTabs } =
    useTabsToRender({
      tabs,
      selectedTab,
    })

  const tabsPopoverContent = (
    <DragDropContext
      onDragEnd={async (result) => {
        const startIndex = result.source.index
        const targetIndex = result.destination?.index
        if (result.reason === "DROP" && targetIndex !== undefined) {
          await dataViewTabs.updateViewsInCollection(
            move(dataViewTabs.collectionViews, startIndex, targetIndex) as View[]
          )
        }
      }}>
      <ScrollArea vertical className="sm:max-h-96" viewportAsChild>
        <Droppable droppableId="block">
          {(droppableProvided) => (
            <div
              className="relative flex flex-col p-1"
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}>
              {tabs.map((tab, index) => (
                <Draggable draggableId={tab.id} index={index} key={tab.id}>
                  {renderDraggable((provided, snapshot) => (
                    <div
                      className="flex items-center justify-start rounded px-2 py-0.5 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-800"
                      ref={provided.innerRef}
                      data-dragging={snapshot.isDragging}
                      {...provided.draggableProps}>
                      <DragHandle className="-ml-2 mr-0.5" {...provided.dragHandleProps} />
                      <Tabs.Trigger
                        value={tab.id}
                        key={tab.id}
                        className="flex min-w-0 flex-1 items-center justify-start"
                        onClick={() => tabsPopoverDisclosure.onClose()}>
                        {getTabIcon(tab)}
                        <span className="truncate">{tab.name}</span>
                        <div className="flex-1" />
                      </Tabs.Trigger>

                      <SelectedTabActionMenu
                        className="group"
                        customViewId={tab.id}
                        isDefaultView={tab.type === "default_view"}
                        onEdit={() => {
                          if (tab.type === "custom_view") {
                            setSelectedCustomView(tab.customView)
                            createView.onOpen()
                          }
                        }}
                        onRemove={async () => {
                          await dataViewTabs.updateViewsInCollection(
                            dataViewTabs.collectionViews.filter((v) => v.id !== tab.id)
                          )
                          dataViewTabs.selectTab(tabs?.[0] ?? null)
                        }}>
                        <div className="ml-2 rounded-md p-0.5 hover:bg-gray-200 radix-state-open:bg-gray-200">
                          <DotsThree size={20} weight="bold" />
                        </div>
                      </SelectedTabActionMenu>
                    </div>
                  ))}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </ScrollArea>
    </DragDropContext>
  )

  return (
    <>
      <div className="relative flex items-center justify-between border-b border-gray-200">
        <div className="flex min-w-0 flex-1 items-center" ref={tabsContainerRef}>
          {renderSelectedTabAsPopover && selectedTab ? (
            <PopoverRoot
              open={tabsPopoverDisclosure.isOpen}
              alwaysPopover
              onOpenChange={tabsPopoverDisclosure.changeOpen}>
              <PopoverTrigger
                className={classNames(
                  "border-b-gray-500 text-gray-900 mx-3",
                  "border-transparent text-gray-500 group",
                  "whitespace-nowrap px-0.5 border-b-2 border-transparent font-medium text-sm",
                  "flex items-center max-sm:flex-1"
                )}>
                <div className="my-1 flex items-center truncate rounded-md p-1.5 group-hover:bg-gray-100 max-sm:flex-1 sm:max-w-[240px]">
                  {getTabIcon(selectedTab)}
                  <span className="max-sm:inline-flex max-sm:flex-1">
                    {selectedTab.name}
                  </span>
                  <CaretDown weight="bold" className="ml-1 h-4 w-4" />
                </div>
              </PopoverTrigger>
              <PopoverContent
                onClick={(e) => e.stopPropagation()}
                side={"bottom"}
                align="start"
                className="max-sm:w-[--radix-popper-anchor-width]">
                <ScrollArea vertical viewportAsChild>
                  <Tabs.List asChild>{tabsPopoverContent}</Tabs.List>
                </ScrollArea>
              </PopoverContent>
            </PopoverRoot>
          ) : (
            <>
              <Tabs.List className="flex items-center space-x-1 px-3">
                {tabsToRender.map((tab) => (
                  <Tabs.Trigger
                    value={tab.id}
                    key={tab.id}
                    onPointerDown={(e) => {
                      e.currentTarget.scrollIntoView({
                        behavior: "smooth",
                        block: "nearest",
                        inline: "center",
                      })
                    }}
                    onClick={(e) => {
                      if (tab.id === selectedTab?.id) {
                        e.preventDefault()
                      }
                    }}
                    className={classNames(
                      "radix-state-active:border-b-gray-500 radix-state-active:text-gray-900",
                      "border-transparent text-gray-500 group",
                      "whitespace-nowrap px-0.5 border-b-2 border-transparent font-medium text-sm",
                      "flex items-center snap-center snap-always"
                    )}>
                    {tab.id === selectedTab?.id ? (
                      <SelectedTabActionMenu
                        className="group"
                        customViewId={tab.id}
                        isDefaultView={tab.type === "default_view"}
                        onEdit={() => {
                          if (tab.type === "custom_view") {
                            setSelectedCustomView(tab.customView)
                            createView.onOpen()
                          }
                        }}
                        onRemove={async () => {
                          await dataViewTabs.updateViewsInCollection(
                            dataViewTabs.collectionViews.filter((v) => v.id !== tab.id)
                          )
                          dataViewTabs.selectTab(tabs?.[0] ?? null)
                        }}>
                        <div className="my-1 flex max-w-[240px] items-center truncate rounded-md p-1.5 group-hover:bg-gray-100 radix-state-open:bg-gray-100">
                          {getTabIcon(tab)}
                          <span className="truncate">{tab.name}</span>
                        </div>
                      </SelectedTabActionMenu>
                    ) : (
                      <div className="my-1 flex max-w-[240px] items-center truncate rounded-md p-1.5 group-hover:bg-gray-100 radix-state-open:bg-gray-100">
                        <span className="opacity-75">{getTabIcon(tab)}</span>
                        <span className="truncate">{tab.name}</span>
                      </div>
                    )}
                  </Tabs.Trigger>
                ))}
                {numberOfHiddenTabs > 0 && (
                  <PopoverRoot
                    open={tabsPopoverDisclosure.isOpen}
                    onOpenChange={tabsPopoverDisclosure.changeOpen}>
                    <PopoverTrigger className="inline-flex items-center rounded-md p-1.5 text-sm text-gray-600 hover:bg-gray-100 radix-state-open:bg-gray-100">
                      {numberOfHiddenTabs} {i18n.t("common:more")}...
                      <CaretDown weight="bold" className="h-4 w-4" />
                    </PopoverTrigger>
                    <PopoverContent sideOffset={4}>{tabsPopoverContent}</PopoverContent>
                  </PopoverRoot>
                )}
              </Tabs.List>
            </>
          )}
        </div>
        {bp.renderMobile ? (
          <PopoverRoot alwaysPopover>
            <PopoverTrigger asChild>
              <Button type="secondary" icon={DotsThreeVertical} className="mr-3 shrink-0" />
            </PopoverTrigger>
            <PopoverContent
              className=" flex flex-col items-start space-y-1 p-1"
              side="left"
              sideOffset={4}
              align="start">
              <AddViewButton
                customViews={dataViewTabs.customViews}
                onCreateView={createView.onOpen}
                defaultCollectionViews={dataViewTabs.defaultCollectionViews}
                collectionViews={dataViewTabs.collectionViews}
                addViewToCollection={dataViewTabs.addViewToCollection}
              />
              <Button
                type="tertiary"
                color="gray"
                onClick={selectView.onOpen}
                style={{ boxShadow: "-16px 0px 10px 0px white" }}>
                {i18n.t("views:labels.all_views")}
              </Button>
            </PopoverContent>
          </PopoverRoot>
        ) : (
          <div
            className="flex h-full items-center border-l px-1"
            style={{ boxShadow: "white -10px 0px 5px -6px" }}>
            <AddViewButton
              customViews={dataViewTabs.customViews}
              onCreateView={createView.onOpen}
              defaultCollectionViews={dataViewTabs.defaultCollectionViews}
              collectionViews={dataViewTabs.collectionViews}
              addViewToCollection={dataViewTabs.addViewToCollection}
            />
            <Button type="tertiary" color="gray" onClick={selectView.onOpen}>
              {i18n.t("views:labels.all_views")}
            </Button>
          </div>
        )}
      </div>
      <DataViewUpdateCustomViewConfig
        isOpen={createView.isOpen}
        dataType={props.dataType}
        customView={selectedCustomView ?? null}
        isEditView={!!selectedCustomView.id}
        config={defaultConfig(props.dataType)}
        isDefaultView={dataViewTabs.selectedTab?.type === "default_view"}
        onOpenChange={createView.changeOpen}
        onCreateView={dataViewTabs.createView}
      />
      <TeamViewsDialog
        isOpen={selectView.isOpen}
        onOpenChange={selectView.changeOpen}
        customViews={dataViewTabs.customViews}
        defaultCollectionViews={dataViewTabs.defaultCollectionViews}
        updateViewsInCollection={dataViewTabs.updateViewsInCollection}
        viewsInCollection={dataViewTabs.collectionViews}
      />
    </>
  )
}
