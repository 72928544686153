import {
  AssetActivityDataView,
  AssetActivityIds,
} from "@components/asset/asset-activity-data-view"
import { AssetDataView, AssetViewIds } from "@components/asset/asset-data-view"
import { MeterDataView, MeterViewIds } from "@components/asset/meter-data-view"
import { ConsumableDataView } from "@components/consumable/consumable-data-view"
import ContactDataView from "@components/contact/contact-data-view"
import MaintenanceDataView from "@components/maintenance/maintenance-data-view"
import { useServiceRequestScope } from "@components/service-request/hooks/use-service-request-scope"
import ServiceRequestDataView from "@components/service-request/service-request-data-view"
import { DataViewConfiguration } from "@components/shared/data-view/data-view"
import { useDataWorkOrderDataView } from "@components/work-order/use-task-data"
import {
  WorkOrderDataView,
  WorkOrderViewIds,
} from "@components/work-order/work-order-data-view"
import { IViewDataTypeEnum } from "@elara/db"
import {
  useAssetArchiveDataViewQuery,
  useAssetDataViewQuery,
} from "@graphql/documents/asset.generated"
import { useMeterDataViewQuery } from "@graphql/documents/asset-meter.generated"
import { useConsumablesQuery } from "@graphql/documents/consumable.generated"
import { useContactsQuery } from "@graphql/documents/contact.generated"
import { ICustomViewFragment } from "@graphql/documents/custom-view.generated"
import { useServiceRequestsQuery } from "@graphql/documents/service-request.generated"
import { usePollQuery } from "@hooks"
import i18n from "@i18n"
import { Rows } from "@phosphor-icons/react"
import { useMemo } from "react"

type DefaultViewProps = {
  id: string
  config: DataViewConfiguration<any>

  onCreateView?: (customView: ICustomViewFragment) => void
  action?: React.ReactNode
}

export const AssetDefaultView = (props: DefaultViewProps) => {
  const [queryRes] = useAssetDataViewQuery({ requestPolicy: "cache-first" })

  return (
    <AssetDataView
      configId={props.id}
      headerClassName="mx-3"
      data={queryRes.data?.asset ?? null}
      defaultConfig={props.config as DataViewConfiguration<AssetViewIds>}
      initialValuesForSaveAsCustomView={{ shared: true }}
      storeAllConfigChangesInCustomView
      allowToSaveCustomView
      onCreateView={props.onCreateView}
      actions={props.action}
    />
  )
}

export const AssetArchiveDefaultView = (props: DefaultViewProps) => {
  const [queryRes] = useAssetArchiveDataViewQuery({ requestPolicy: "cache-first" })

  return (
    <AssetDataView
      archive
      configId={props.id}
      headerClassName="mx-3"
      data={queryRes.data?.asset ?? null}
      defaultConfig={props.config as DataViewConfiguration<AssetViewIds>}
      noData={{ message: "Noch keine Objekte archiviert" }}
      initialValuesForSaveAsCustomView={{ shared: true }}
      storeAllConfigChangesInCustomView
      allowToSaveCustomView
      onCreateView={props.onCreateView}
      actions={props.action}
    />
  )
}

export const AssetActivityDefaultView = (props: DefaultViewProps) => (
  <AssetActivityDataView
    configId={props.id}
    headerClassName="mx-3"
    defaultConfig={props.config as DataViewConfiguration<AssetActivityIds>}
    initialValuesForSaveAsCustomView={{ shared: true }}
    storeAllConfigChangesInCustomView
    allowToSaveCustomView
    onCreateView={props.onCreateView}
    actions={props.action}
  />
)

export const WorkOrderDefaultView = (props: DefaultViewProps) => {
  const noData = useMemo(
    () => ({
      icon: Rows,
      title: i18n.t("views:work_order.no_data.title"),
      message: <p style={{ marginTop: 4 }}>{i18n.t("views:work_order.no_data.message")}</p>,
      actions: null,
    }),
    []
  )

  return (
    <WorkOrderDataView
      data={null}
      useData={useDataWorkOrderDataView}
      configId={props.id}
      headerClassName="mx-3"
      defaultConfig={props.config as DataViewConfiguration<WorkOrderViewIds>}
      initialValuesForSaveAsCustomView={{ shared: true }}
      storeAllConfigChangesInCustomView
      allowToSaveCustomView
      onCreateView={props.onCreateView}
      actions={props.action}
      noData={noData}
    />
  )
}

export const MeterDefaultView = (props: DefaultViewProps) => {
  const [queryRes, fetchData] = useMeterDataViewQuery({
    requestPolicy: "cache-first",
  })

  const reexecute = () => fetchData({ requestPolicy: "network-only" })

  usePollQuery({
    reexecute,
    isFetching: queryRes.fetching,
    pollInterval: 5 * 60 * 1000,
  })

  return (
    <MeterDataView
      data={queryRes.data?.meter ?? null}
      configId={props.id}
      headerClassName="mx-3"
      defaultConfig={props.config as DataViewConfiguration<MeterViewIds>}
      initialValuesForSaveAsCustomView={{ shared: true }}
      storeAllConfigChangesInCustomView
      allowToSaveCustomView
      onCreateView={props.onCreateView}
      actions={props.action}
    />
  )
}
export const ConsumableDefaultView = (props: DefaultViewProps) => {
  const [queryRes, fetchData] = useConsumablesQuery()

  const reexecute = () => fetchData({ requestPolicy: "network-only" })

  usePollQuery({
    reexecute,
    isFetching: queryRes.fetching,
    pollInterval: 5 * 60 * 1000,
  })

  return (
    <ConsumableDataView
      data={queryRes.data?.consumable ?? null}
      configId={props.id}
      headerClassName="mx-3"
      defaultConfig={props.config as DataViewConfiguration<any>}
      initialValuesForSaveAsCustomView={{ shared: true }}
      storeAllConfigChangesInCustomView
      allowToSaveCustomView
      onCreateView={props.onCreateView}
      actions={props.action}
    />
  )
}

export const ContactDefaultView = (props: DefaultViewProps) => {
  const [queryRes, fetchData] = useContactsQuery()

  const reexecute = () => fetchData({ requestPolicy: "network-only" })

  usePollQuery({
    reexecute,
    isFetching: queryRes.fetching,
    pollInterval: 5 * 60 * 1000,
  })

  return (
    <ContactDataView
      data={queryRes.data?.contact ?? null}
      configId={props.id}
      headerClassName="mx-3"
      defaultConfig={props.config as DataViewConfiguration<any>}
      initialValuesForSaveAsCustomView={{ shared: true }}
      storeAllConfigChangesInCustomView
      allowToSaveCustomView
      onCreateView={props.onCreateView}
      actions={props.action}
    />
  )
}

export const ServiceRequestDefaultView = (props: DefaultViewProps) => {
  const context = useServiceRequestScope()
  const [queryRes, fetchData] = useServiceRequestsQuery({ context })

  const reexecute = () => fetchData({ requestPolicy: "network-only" })

  usePollQuery({
    reexecute,
    isFetching: queryRes.fetching,
    pollInterval: 5 * 60 * 1000,
  })

  return (
    <ServiceRequestDataView
      data={queryRes.data?.service_request ?? null}
      configId={props.id}
      headerClassName="mx-3"
      defaultConfig={props.config as DataViewConfiguration<any>}
      initialValuesForSaveAsCustomView={{ shared: true }}
      storeAllConfigChangesInCustomView
      allowToSaveCustomView
      onCreateView={props.onCreateView}
      actions={props.action}
    />
  )
}

export const MaintenanceDefaultView = (props: DefaultViewProps) => {
  return (
    <MaintenanceDataView
      configId={props.id}
      headerClassName="mx-3"
      defaultConfig={props.config as DataViewConfiguration<any>}
      initialValuesForSaveAsCustomView={{ shared: true }}
      storeAllConfigChangesInCustomView
      allowToSaveCustomView
      onCreateView={props.onCreateView}
      actions={props.action}
    />
  )
}

export const MaintenanceArchiveDefaultView = (props: DefaultViewProps) => {
  return (
    <MaintenanceDataView
      archive
      configId={props.id}
      headerClassName="mx-3"
      defaultConfig={props.config as DataViewConfiguration<any>}
      initialValuesForSaveAsCustomView={{ shared: true }}
      storeAllConfigChangesInCustomView
      allowToSaveCustomView
      onCreateView={props.onCreateView}
      actions={props.action}
    />
  )
}

export const DefaultView = (props: DefaultViewProps & { data_type: IViewDataTypeEnum }) => {
  return (
    <>
      {props.data_type === IViewDataTypeEnum.Asset && <AssetDefaultView {...props} />}
      {props.data_type === IViewDataTypeEnum.AssetState && (
        <AssetActivityDefaultView {...props} />
      )}
      {props.data_type === IViewDataTypeEnum.AssetArchive && (
        <AssetArchiveDefaultView {...props} />
      )}
      {props.data_type === IViewDataTypeEnum.Workorder && (
        <WorkOrderDefaultView {...props} />
      )}
      {props.data_type === IViewDataTypeEnum.Meter && <MeterDefaultView {...props} />}
      {props.data_type === IViewDataTypeEnum.Consumable && (
        <ConsumableDefaultView {...props} />
      )}
      {props.data_type === IViewDataTypeEnum.Contact && <ContactDefaultView {...props} />}
      {props.data_type === IViewDataTypeEnum.ServiceRequest && (
        <ServiceRequestDefaultView {...props} />
      )}
      {props.data_type === IViewDataTypeEnum.Maintenance && (
        <MaintenanceDefaultView {...props} />
      )}
      {props.data_type === IViewDataTypeEnum.MaintenanceArchive && (
        <MaintenanceArchiveDefaultView {...props} />
      )}
    </>
  )
}

export default DefaultView
