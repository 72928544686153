import { AssetWithParents } from "@components/asset/asset-with-parents"
import { selectAsset } from "@components/select/asset-multi-select-dialog"
import { Button, Image } from "@components/shared"
import CollapsibleSection from "@components/shared/collapsible-section"
import { IPermissionScopeEnum, uuid } from "@elara/db"
import { IWorkOrderDataViewFragment } from "@graphql/documents/fragments.generated"
import {
  DeleteAssetFromWorkOrderDocument,
  IDeleteAssetFromWorkOrderMutation,
  IDeleteAssetFromWorkOrderMutationVariables,
  useDeleteWorkOrderAssetsMutation,
  useInsertWorkOrderAssetsMutation,
} from "@graphql/documents/work-order.generated"
import { usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { Plus, Shapes, Swatches, X } from "@phosphor-icons/react"
import Icons from "@resources/icons"
import { Link } from "react-router-dom"
import { useClient } from "urql"

export const WorkOrderDetailObjectSection = (props: {
  workOrder: IWorkOrderDataViewFragment
}) => {
  const client = useClient()
  const [, insertAssets] = useInsertWorkOrderAssetsMutation()
  const [, deleteAssets] = useDeleteWorkOrderAssetsMutation()
  const editScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)

  const { workOrder } = props

  const onAdd = async () => {
    let assetIds = await selectAsset({
      initialValues: {
        assetIds: workOrder.assets.map(({ asset }) => asset.id),
      },
    })

    if (!assetIds) return

    try {
      await deleteAssets({ work_order_id: workOrder.id }, editScope.context())

      await insertAssets(
        {
          data: assetIds.map((assetId) => ({
            work_order_id: workOrder.id,
            asset_id: assetId,
          })),
        },
        editScope.context()
      )
    } catch {}
  }

  const onDelete = async (assetId: uuid) => {
    try {
      await client
        .mutation<
          IDeleteAssetFromWorkOrderMutation,
          IDeleteAssetFromWorkOrderMutationVariables
        >(
          DeleteAssetFromWorkOrderDocument,
          { assetId, workOrderId: props.workOrder.id },
          editScope.context()
        )
        .toPromise()
    } catch {}
  }

  return workOrder.assets.length > 0 ? (
    <CollapsibleSection
      defaultOpen
      action={null}
      title={i18n.t("common:asset", { count: 2 })}>
      <div className="mt-3 flex min-h-0 min-w-0 max-w-full flex-1 flex-col gap-2">
        {workOrder.assets.map((a) => {
          const asset = a.asset

          if (!asset) return null

          return (
            <Link
              to={`/object/${asset.id}`}
              key={asset.id}
              className="flex min-w-0 rounded bg-gray-50 p-2 hover:bg-gray-100 hover:text-gray-900">
              <div className="mr-2 shrink-0">
                {asset.avatar ? (
                  <Image
                    src={asset.avatar?.thumbnail_url}
                    preview={{ src: asset.avatar?.url }}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    imageClasses="w-20 h-20 rounded shrink-0"
                  />
                ) : (
                  <div className="flex h-20 w-20 shrink-0 items-center justify-center rounded border border-dashed text-xl text-gray-300">
                    <Shapes />
                  </div>
                )}
              </div>

              <div className="flex h-20 min-w-0 flex-col justify-evenly">
                <AssetWithParents asset={asset} />

                <div className="flex min-w-0 items-center text-sm text-gray-500">
                  <div className="w-6 shrink-0">
                    <Swatches className="mx-auto" />{" "}
                  </div>
                  <span className="truncate">
                    {asset.group?.name ?? i18n.t("common:without_group")}
                  </span>
                </div>
                {
                  <div className="flex min-w-0 items-center text-sm text-gray-500">
                    <div className="w-6 shrink-0">
                      <Icons.Location className="mx-auto text-base" />{" "}
                    </div>
                    <span className="truncate">
                      {asset.place?.name ?? i18n.t("common:without_location")}
                    </span>
                  </div>
                }
              </div>
              <div className="flex-1" />
              <Button
                type="tertiary"
                icon={X}
                color="gray"
                htmlType="button"
                className="ml-1 shrink-0 hover:!bg-gray-200"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  if (!asset.id) return
                  onDelete(asset.id)
                }}
              />
            </Link>
          )
        })}

        <div className="self-start">
          <div
            onClick={onAdd}
            className="relative flex h-8 min-w-0 cursor-pointer items-center rounded border border-white px-1.5 py-0.5 text-sm text-gray-500 hover:border-gray-200 hover:text-gray-700 focus-visible:border-gray-300">
            <Plus className="mr-1" />{" "}
            {i18n.t("common:add_token", { token: i18n.t("common:asset", { count: 1 }) })}
          </div>
        </div>
      </div>
    </CollapsibleSection>
  ) : (
    <div>
      <Button type="tertiary" icon={Plus} onClick={onAdd}>
        {i18n.t("common:add_token", { token: i18n.t("common:asset", { count: 1 }) })}
      </Button>
    </div>
  )
}
