// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import { UploadDataFragmentDoc } from './upload.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ISubFoldersAggregateFragment = { __typename: 'file_folder_aggregate', aggregate: { __typename: 'file_folder_aggregate_fields', count: number } | null };

export type IFilesAggregateFragment = { __typename: 'file_folder_x_upload_aggregate', aggregate: { __typename: 'file_folder_x_upload_aggregate_fields', count: number } | null };

export type IFolderSubFolderFragment = { __typename: 'file_folder', id: uuid, name: string, created_at: string, root_folder_id: uuid, parent_folder: { __typename: 'file_folder', id: uuid, name: string } | null, sub_folders: Array<{ __typename: 'file_folder', id: uuid, created_at: string, name: string, sub_folders_aggregate: { __typename: 'file_folder_aggregate', aggregate: { __typename: 'file_folder_aggregate_fields', count: number } | null }, files_aggregate: { __typename: 'file_folder_x_upload_aggregate', aggregate: { __typename: 'file_folder_x_upload_aggregate_fields', count: number } | null } }> };

export type IFileFolderFragment = { __typename: 'file_folder', id: uuid, name: string, created_at: string, root_folder_id: uuid, files: Array<{ __typename: 'file_folder_x_upload', file_folder_id: uuid, file: { __typename: 'upload', created_at: string, id: uuid, file_name: string, file_size: number, mime_type: string, thumbnail_url: string | null, url: string | null } }>, parent_folder: { __typename: 'file_folder', id: uuid, name: string } | null, sub_folders: Array<{ __typename: 'file_folder', id: uuid, created_at: string, name: string, sub_folders_aggregate: { __typename: 'file_folder_aggregate', aggregate: { __typename: 'file_folder_aggregate_fields', count: number } | null }, files_aggregate: { __typename: 'file_folder_x_upload_aggregate', aggregate: { __typename: 'file_folder_x_upload_aggregate_fields', count: number } | null } }> };

export type IFileFolderUploadFragment = { __typename: 'file_folder_x_upload', file_folder_id: uuid, file: { __typename: 'upload', created_at: string, id: uuid, file_name: string, file_size: number, mime_type: string, thumbnail_url: string | null, url: string | null } };

export type ISubFolderUploadFragment = { __typename: 'file_folder', id: uuid, name: string, sub_folders_aggregate: { __typename: 'file_folder_aggregate', aggregate: { __typename: 'file_folder_aggregate_fields', count: number } | null }, files_aggregate: { __typename: 'file_folder_x_upload_aggregate', aggregate: { __typename: 'file_folder_x_upload_aggregate_fields', count: number } | null } };

export type IGetFileFolderQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type IGetFileFolderQuery = { file_folder_by_pk: { __typename: 'file_folder', id: uuid, name: string, created_at: string, root_folder_id: uuid, files: Array<{ __typename: 'file_folder_x_upload', file_folder_id: uuid, file: { __typename: 'upload', created_at: string, id: uuid, file_name: string, file_size: number, mime_type: string, thumbnail_url: string | null, url: string | null } }>, parent_folder: { __typename: 'file_folder', id: uuid, name: string } | null, sub_folders: Array<{ __typename: 'file_folder', id: uuid, created_at: string, name: string, sub_folders_aggregate: { __typename: 'file_folder_aggregate', aggregate: { __typename: 'file_folder_aggregate_fields', count: number } | null }, files_aggregate: { __typename: 'file_folder_x_upload_aggregate', aggregate: { __typename: 'file_folder_x_upload_aggregate_fields', count: number } | null } }> } | null };

export type IGetFileFolderWithFilesFilterQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  files_filter?: Types.InputMaybe<Types.IFileFolderXUploadBoolExp>;
}>;


export type IGetFileFolderWithFilesFilterQuery = { file_folder_by_pk: { __typename: 'file_folder', id: uuid, name: string, created_at: string, root_folder_id: uuid, files: Array<{ __typename: 'file_folder_x_upload', file_folder_id: uuid, file: { __typename: 'upload', created_at: string, id: uuid, file_name: string, file_size: number, mime_type: string, thumbnail_url: string | null, url: string | null } }>, parent_folder: { __typename: 'file_folder', id: uuid, name: string } | null, sub_folders: Array<{ __typename: 'file_folder', id: uuid, created_at: string, name: string, sub_folders_aggregate: { __typename: 'file_folder_aggregate', aggregate: { __typename: 'file_folder_aggregate_fields', count: number } | null }, files_aggregate: { __typename: 'file_folder_x_upload_aggregate', aggregate: { __typename: 'file_folder_x_upload_aggregate_fields', count: number } | null } }> } | null };

export type IAddFileToFileFolderMutationVariables = Types.Exact<{
  data: Types.IFileFolderXUploadInsertInput;
}>;


export type IAddFileToFileFolderMutation = { insert_file_folder_x_upload_one: { __typename: 'file_folder_x_upload', root_folder_id: uuid, upload_id: uuid, file_folder_id: uuid, file: { __typename: 'upload', created_at: string, id: uuid, file_name: string, file_size: number, mime_type: string, thumbnail_url: string | null, url: string | null } } | null };

export type IDeleteFileFolderFileMutationVariables = Types.Exact<{
  upload_id: Types.Scalars['uuid']['input'];
  folder_id: Types.Scalars['uuid']['input'];
}>;


export type IDeleteFileFolderFileMutation = { delete_file_folder_x_upload_by_pk: { __typename: 'file_folder_x_upload', upload_id: uuid, file_folder_id: uuid } | null };

export type IDeleteFileFolderFolderMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type IDeleteFileFolderFolderMutation = { delete_file_folder_by_pk: { __typename: 'file_folder', id: uuid } | null };

export type IEditFileFolderNameMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  name: Types.Scalars['String']['input'];
}>;


export type IEditFileFolderNameMutation = { update_file_folder_by_pk: { __typename: 'file_folder', id: uuid, name: string } | null };

export type IAddEmptyFileFolderMutationVariables = Types.Exact<{
  parentFolderId: Types.Scalars['uuid']['input'];
  name: Types.Scalars['String']['input'];
  rootFolderId: Types.Scalars['uuid']['input'];
}>;


export type IAddEmptyFileFolderMutation = { insert_file_folder_one: { __typename: 'file_folder', id: uuid, name: string, created_at: string, root_folder_id: uuid, files: Array<{ __typename: 'file_folder_x_upload', file_folder_id: uuid, file: { __typename: 'upload', created_at: string, id: uuid, file_name: string, file_size: number, mime_type: string, thumbnail_url: string | null, url: string | null } }>, parent_folder: { __typename: 'file_folder', id: uuid, name: string } | null, sub_folders: Array<{ __typename: 'file_folder', id: uuid, created_at: string, name: string, sub_folders_aggregate: { __typename: 'file_folder_aggregate', aggregate: { __typename: 'file_folder_aggregate_fields', count: number } | null }, files_aggregate: { __typename: 'file_folder_x_upload_aggregate', aggregate: { __typename: 'file_folder_x_upload_aggregate_fields', count: number } | null } }> } | null };

export type IAddRootFileFolderToAssetMutationVariables = Types.Exact<{
  asset_id: Types.Scalars['uuid']['input'];
  asset_name: Types.Scalars['String']['input'];
  root_folder_id: Types.Scalars['uuid']['input'];
}>;


export type IAddRootFileFolderToAssetMutation = { insert_asset_one: { __typename: 'asset', id: uuid, file_folder_id: uuid | null, file_folder: { __typename: 'file_folder', id: uuid, name: string, created_at: string, root_folder_id: uuid, files: Array<{ __typename: 'file_folder_x_upload', file_folder_id: uuid, file: { __typename: 'upload', created_at: string, id: uuid, file_name: string, file_size: number, mime_type: string, thumbnail_url: string | null, url: string | null } }>, parent_folder: { __typename: 'file_folder', id: uuid, name: string } | null, sub_folders: Array<{ __typename: 'file_folder', id: uuid, created_at: string, name: string, sub_folders_aggregate: { __typename: 'file_folder_aggregate', aggregate: { __typename: 'file_folder_aggregate_fields', count: number } | null }, files_aggregate: { __typename: 'file_folder_x_upload_aggregate', aggregate: { __typename: 'file_folder_x_upload_aggregate_fields', count: number } | null } }> } | null } | null };

export type IInsertRootFileFolderMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type IInsertRootFileFolderMutation = { insert_file_folder_one: { __typename: 'file_folder', root_folder_id: uuid, id: uuid, name: string, created_at: string, files: Array<{ __typename: 'file_folder_x_upload', file_folder_id: uuid, file: { __typename: 'upload', created_at: string, id: uuid, file_name: string, file_size: number, mime_type: string, thumbnail_url: string | null, url: string | null } }>, parent_folder: { __typename: 'file_folder', id: uuid, name: string } | null, sub_folders: Array<{ __typename: 'file_folder', id: uuid, created_at: string, name: string, sub_folders_aggregate: { __typename: 'file_folder_aggregate', aggregate: { __typename: 'file_folder_aggregate_fields', count: number } | null }, files_aggregate: { __typename: 'file_folder_x_upload_aggregate', aggregate: { __typename: 'file_folder_x_upload_aggregate_fields', count: number } | null } }> } | null };

export type IAddFolderToFileFolderMutationVariables = Types.Exact<{
  data: Types.IFileFolderInsertInput;
}>;


export type IAddFolderToFileFolderMutation = { insert_file_folder_one: { __typename: 'file_folder', id: uuid, name: string, created_at: string, root_folder_id: uuid, files: Array<{ __typename: 'file_folder_x_upload', file_folder_id: uuid, file: { __typename: 'upload', created_at: string, id: uuid, file_name: string, file_size: number, mime_type: string, thumbnail_url: string | null, url: string | null } }>, parent_folder: { __typename: 'file_folder', id: uuid, name: string } | null, sub_folders: Array<{ __typename: 'file_folder', id: uuid, created_at: string, name: string, sub_folders_aggregate: { __typename: 'file_folder_aggregate', aggregate: { __typename: 'file_folder_aggregate_fields', count: number } | null }, files_aggregate: { __typename: 'file_folder_x_upload_aggregate', aggregate: { __typename: 'file_folder_x_upload_aggregate_fields', count: number } | null } }> } | null };

export const SubFoldersAggregateFragmentDoc = gql`
    fragment SubFoldersAggregate on file_folder_aggregate {
  aggregate {
    count
  }
}
    `;
export const FilesAggregateFragmentDoc = gql`
    fragment FilesAggregate on file_folder_x_upload_aggregate {
  aggregate {
    count
  }
}
    `;
export const FolderSubFolderFragmentDoc = gql`
    fragment FolderSubFolder on file_folder {
  id
  name
  created_at
  root_folder_id
  parent_folder {
    id
    name
  }
  sub_folders {
    __typename
    id
    created_at
    name
    sub_folders_aggregate {
      ...SubFoldersAggregate
    }
    files_aggregate {
      ...FilesAggregate
    }
  }
}
    ${SubFoldersAggregateFragmentDoc}
${FilesAggregateFragmentDoc}`;
export const FileFolderFragmentDoc = gql`
    fragment FileFolder on file_folder {
  ...FolderSubFolder
  files {
    __typename
    file_folder_id
    file {
      created_at
      ...UploadData
    }
  }
}
    ${FolderSubFolderFragmentDoc}
${UploadDataFragmentDoc}`;
export const FileFolderUploadFragmentDoc = gql`
    fragment FileFolderUpload on file_folder_x_upload {
  __typename
  file_folder_id
  file {
    created_at
    ...UploadData
  }
}
    ${UploadDataFragmentDoc}`;
export const SubFolderUploadFragmentDoc = gql`
    fragment SubFolderUpload on file_folder {
  id
  name
  sub_folders_aggregate {
    ...SubFoldersAggregate
  }
  files_aggregate {
    ...FilesAggregate
  }
}
    ${SubFoldersAggregateFragmentDoc}
${FilesAggregateFragmentDoc}`;
export const GetFileFolderDocument = gql`
    query GetFileFolder($id: uuid!) {
  file_folder_by_pk(id: $id) {
    ...FileFolder
  }
}
    ${FileFolderFragmentDoc}`;

export function useGetFileFolderQuery(options: Omit<Urql.UseQueryArgs<IGetFileFolderQueryVariables>, 'query'>) {
  return Urql.useQuery<IGetFileFolderQuery, IGetFileFolderQueryVariables>({ query: GetFileFolderDocument, ...options });
};
export const GetFileFolderWithFilesFilterDocument = gql`
    query GetFileFolderWithFilesFilter($id: uuid!, $files_filter: file_folder_x_upload_bool_exp = {}) {
  file_folder_by_pk(id: $id) {
    ...FolderSubFolder
    files(where: $files_filter) {
      __typename
      file_folder_id
      file {
        created_at
        ...UploadData
      }
    }
  }
}
    ${FolderSubFolderFragmentDoc}
${UploadDataFragmentDoc}`;

export function useGetFileFolderWithFilesFilterQuery(options: Omit<Urql.UseQueryArgs<IGetFileFolderWithFilesFilterQueryVariables>, 'query'>) {
  return Urql.useQuery<IGetFileFolderWithFilesFilterQuery, IGetFileFolderWithFilesFilterQueryVariables>({ query: GetFileFolderWithFilesFilterDocument, ...options });
};
export const AddFileToFileFolderDocument = gql`
    mutation AddFileToFileFolder($data: file_folder_x_upload_insert_input!) {
  insert_file_folder_x_upload_one(object: $data) {
    root_folder_id
    upload_id
    file_folder_id
    ...FileFolderUpload
  }
}
    ${FileFolderUploadFragmentDoc}`;

export function useAddFileToFileFolderMutation() {
  return Urql.useMutation<IAddFileToFileFolderMutation, IAddFileToFileFolderMutationVariables>(AddFileToFileFolderDocument);
};
export const DeleteFileFolderFileDocument = gql`
    mutation DeleteFileFolderFile($upload_id: uuid!, $folder_id: uuid!) {
  delete_file_folder_x_upload_by_pk(
    file_folder_id: $folder_id
    upload_id: $upload_id
  ) {
    upload_id
    file_folder_id
  }
}
    `;

export function useDeleteFileFolderFileMutation() {
  return Urql.useMutation<IDeleteFileFolderFileMutation, IDeleteFileFolderFileMutationVariables>(DeleteFileFolderFileDocument);
};
export const DeleteFileFolderFolderDocument = gql`
    mutation DeleteFileFolderFolder($id: uuid!) {
  delete_file_folder_by_pk(id: $id) {
    id
  }
}
    `;

export function useDeleteFileFolderFolderMutation() {
  return Urql.useMutation<IDeleteFileFolderFolderMutation, IDeleteFileFolderFolderMutationVariables>(DeleteFileFolderFolderDocument);
};
export const EditFileFolderNameDocument = gql`
    mutation EditFileFolderName($id: uuid!, $name: String!) {
  update_file_folder_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {
    id
    name
  }
}
    `;

export function useEditFileFolderNameMutation() {
  return Urql.useMutation<IEditFileFolderNameMutation, IEditFileFolderNameMutationVariables>(EditFileFolderNameDocument);
};
export const AddEmptyFileFolderDocument = gql`
    mutation AddEmptyFileFolder($parentFolderId: uuid!, $name: String!, $rootFolderId: uuid!) {
  insert_file_folder_one(
    object: {parent_folder_id: $parentFolderId, name: $name, root_folder_id: $rootFolderId}
  ) {
    ...FileFolder
  }
}
    ${FileFolderFragmentDoc}`;

export function useAddEmptyFileFolderMutation() {
  return Urql.useMutation<IAddEmptyFileFolderMutation, IAddEmptyFileFolderMutationVariables>(AddEmptyFileFolderDocument);
};
export const AddRootFileFolderToAssetDocument = gql`
    mutation AddRootFileFolderToAsset($asset_id: uuid!, $asset_name: String!, $root_folder_id: uuid!) {
  insert_asset_one(
    object: {id: $asset_id, name: $asset_name, file_folder: {data: {id: $root_folder_id, name: "root", root_folder_id: $root_folder_id}}}
    on_conflict: {constraint: asset_pkey, update_columns: [file_folder_id]}
  ) {
    id
    file_folder_id
    file_folder {
      ...FileFolder
    }
  }
}
    ${FileFolderFragmentDoc}`;

export function useAddRootFileFolderToAssetMutation() {
  return Urql.useMutation<IAddRootFileFolderToAssetMutation, IAddRootFileFolderToAssetMutationVariables>(AddRootFileFolderToAssetDocument);
};
export const InsertRootFileFolderDocument = gql`
    mutation InsertRootFileFolder($id: uuid!) {
  insert_file_folder_one(object: {id: $id, name: "root", root_folder_id: $id}) {
    root_folder_id
    ...FileFolder
  }
}
    ${FileFolderFragmentDoc}`;

export function useInsertRootFileFolderMutation() {
  return Urql.useMutation<IInsertRootFileFolderMutation, IInsertRootFileFolderMutationVariables>(InsertRootFileFolderDocument);
};
export const AddFolderToFileFolderDocument = gql`
    mutation AddFolderToFileFolder($data: file_folder_insert_input!) {
  insert_file_folder_one(
    object: $data
    on_conflict: {constraint: file_folder_pkey, update_columns: [name]}
  ) {
    ...FileFolder
  }
}
    ${FileFolderFragmentDoc}`;

export function useAddFolderToFileFolderMutation() {
  return Urql.useMutation<IAddFolderToFileFolderMutation, IAddFolderToFileFolderMutationVariables>(AddFolderToFileFolderDocument);
};