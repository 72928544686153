import { Select } from "@components/shared/select"
import i18n from "@i18n"
import { MagnifyingGlass } from "@phosphor-icons/react"
import { naturalCompare } from "@utils"
import React, { PropsWithChildren, useMemo } from "react"
import { useDebounce } from "use-debounce"

import { useDataViewConfigContext } from "./data-view-config"

function useDataViewSearch<Id extends string>(props: { defaultSearchColumn: Id }) {
  const config = useDataViewConfigContext()

  const [searchColumn, setSearchColumn] = React.useState(props.defaultSearchColumn)
  const [searchValue, setSearchValue] = React.useState("")
  // Debounce search value
  const [debouncedSearchValue] = useDebounce(searchValue, 300)

  const searchQuery = useMemo(() => {
    if (!debouncedSearchValue) return {}
    const col = config.columns.find((c) => c.id === searchColumn)
    return col?.searchQuery?.(debouncedSearchValue) ?? {}
  }, [searchColumn, debouncedSearchValue, config.columns])

  return {
    searchColumn,
    setSearchColumn,
    searchValue,
    setSearchValue,
    searchQuery,
  }
}

const DataViewSearchContext = React.createContext<
  ReturnType<typeof useDataViewSearch<string>>
>(undefined!)

export function useDataViewSearchContext<Id extends string>() {
  const context = React.useContext(DataViewSearchContext)
  if (!context) {
    throw new Error("useDataViewSearchContext must be used within a DataViewSearch")
  }
  return context as unknown as ReturnType<typeof useDataViewSearch<Id>>
}

export type DataViewSearchProps<Id extends string> = PropsWithChildren<{
  defaultSearchColumn: Id
}>

export function DataViewSearch<Id extends string = string>(props: DataViewSearchProps<Id>) {
  const value = useDataViewSearch<string>(props)

  return (
    <DataViewSearchContext.Provider
      value={value as ReturnType<typeof useDataViewSearch<string>>}>
      {props.children}
    </DataViewSearchContext.Provider>
  )
}

export function DataViewSearchField() {
  const ctx = useDataViewConfigContext()
  const search = useDataViewSearchContext<string>()

  return (
    <div className="group relative box-border flex items-stretch rounded-md border bg-gray-50 focus-within:border-gray-300">
      <div className=" flex items-center pl-2 text-gray-500">
        <MagnifyingGlass />
      </div>

      <Select
        value={search.searchColumn}
        onValueChange={search.setSearchColumn}
        items={ctx.columns
          .filter((c) => c.searchQuery)
          .map((c) => ({ label: c.Header, value: c.id }))
          .sort((a, b) => naturalCompare(a.label, b.label))}
        className="h-[30px] min-h-[30px] w-32 max-w-[120px] truncate rounded-r-none border-none bg-gray-50 text-xs font-medium group-focus-within:border-gray-300 [&>span]:truncate"
      />
      <input
        type="text"
        value={search.searchValue}
        placeholder={i18n.t("common:search")}
        onChange={(e) => search.setSearchValue(e.target.value)}
        className="w-[120px] rounded-r-md bg-white pl-2 sm:text-sm"
      />
    </div>
  )
}
