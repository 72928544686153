import { AssetWithParents } from "@components/asset/asset-with-parents"
import { ConsumableStorageLocation } from "@components/consumable/consumable-storage-location"
import { selectConsumable } from "@components/select/consumable-multi-select-dialog"
import { Button } from "@components/shared"
import CollapsibleSection from "@components/shared/collapsible-section"
import { CurrencyValue } from "@components/shared/currency-value"
import { Tooltip } from "@components/shared/tooltip"
import TouchTargetSize from "@components/shared/touch-target-size"
import {
  ConsumableCompactByIdDocument,
  IConsumableCompactByIdQuery,
  IConsumableCompactByIdQueryVariables,
  IInsertConsumableLogMutation,
  IInsertConsumableLogMutationVariables,
  InsertConsumableLogDocument,
} from "@graphql/documents/consumable.generated"
import {
  IWorkOrderDataViewFragment,
  IWorkOrderFragment,
} from "@graphql/documents/fragments.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { Plus, Shapes } from "@phosphor-icons/react"
import { LinkWithBackgroundLocation } from "@utils/location"
import { useState } from "react"
import { useClient } from "urql"

import { WorkOrderDetailConsumableForm } from "./work-order-detail-consumable-form"

type SectionProps = {
  workOrder:
    | ({ detail: "detail" } & IWorkOrderFragment)
    | ({ detail: "data_view" } & IWorkOrderDataViewFragment)
}

export const ConsumableSection = (props: SectionProps) => {
  const { workOrder } = props
  const client = useClient()

  const [isEditing, setIsEditing] = useState(false)
  const scope = usePermissionScope(IPermissionScopeEnum.AppDataEntry)

  if (workOrder.detail !== "detail") return null

  const AddConsumableButton = () => (
    <Button
      type="tertiary"
      icon={Plus}
      onClick={async () => {
        const ids = await selectConsumable()

        if (!ids) return

        for (let id of ids) {
          const consumableQueryRes = await client
            .query<IConsumableCompactByIdQuery, IConsumableCompactByIdQueryVariables>(
              ConsumableCompactByIdDocument,
              { id },
              { requestPolicy: "cache-only" }
            )
            .toPromise()
          const consumable = consumableQueryRes.data?.consumable_by_pk
          await client
            .mutation<IInsertConsumableLogMutation, IInsertConsumableLogMutationVariables>(
              InsertConsumableLogDocument,
              {
                data: {
                  consumable_id: id,
                  task_id: workOrder.id,
                  asset_id: workOrder.assets[0]?.asset_id ?? null,
                  adjustment: -1,
                  place_id: consumable?.storage_locations?.[0]?.place_id ?? null,
                  cost_per_unit: consumable?.cost ?? 0,
                },
              },
              scope.context()
            )
            .toPromise()
        }
      }}>
      {i18n.t("common:add_token", { token: i18n.t("common:consumable", { count: 1 }) })}
    </Button>
  )

  return workOrder.consumables_used.length > 0 ? (
    <CollapsibleSection
      title={i18n.t("consumables:labels.used")}
      defaultOpen
      action={
        !isEditing && (
          <Button type="tertiary" onClick={() => setIsEditing(true)}>
            {i18n.t("edit")}
          </Button>
        )
      }>
      <div className="rounded-lg bg-gray-50 p-3">
        {isEditing ? (
          <WorkOrderDetailConsumableForm
            workOrder={workOrder}
            onDiscard={() => setIsEditing(false)}
            onSave={() => setIsEditing(false)}
          />
        ) : (
          <table className="min-w-full divide-y divide-gray-300 text-sm">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="w-full pb-2 pr-3 text-left text-sm font-medium text-gray-500">
                  {i18n.t("common:consumable", { count: 1 })}
                </th>
                <th
                  scope="col"
                  className="px-3 pb-2 text-left text-sm font-medium text-gray-500">
                  {i18n.t("consumables:fields.quantity")}
                </th>
                <th
                  scope="col"
                  className="px-3 pb-2 text-left text-sm font-medium text-gray-500">
                  {i18n.t("common:cost")}
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100">
              {workOrder.consumables_used?.map((consumableUsed) => {
                if (!consumableUsed) return null
                const asset = props.workOrder.assets.find(
                  (a) => a.asset_id === consumableUsed.asset_id
                )?.asset

                return (
                  <tr key={consumableUsed.id}>
                    <td className="py-2">
                      <div className="flex items-center">
                        <LinkWithBackgroundLocation
                          to={`/consumable/${consumableUsed.consumable_id}`}
                          className="group line-clamp-2 block hover:text-gray-900">
                          <span className="font-medium group-hover:underline">
                            {consumableUsed.consumable?.name}
                          </span>
                          <span className="ml-1 text-gray-500">
                            {consumableUsed.consumable?.public_id}
                          </span>
                        </LinkWithBackgroundLocation>

                        {asset && (
                          <span className="relative ml-2 flex items-center text-gray-500 hover:text-gray-700">
                            <Tooltip
                              content={<AssetWithParents asset={asset} showAvatar />}
                              delayDuration={50}>
                              <Shapes />
                              <TouchTargetSize />
                            </Tooltip>
                          </span>
                        )}
                      </div>
                      <div className="flex items-center">
                        <ConsumableStorageLocation
                          storageLocation={
                            consumableUsed.consumable?.storage_locations?.find(
                              (s) => s.place_id === consumableUsed.place_id
                            ) ?? null
                          }
                        />
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-2">
                      <span className="">
                        {-consumableUsed.adjustment}
                        <span className="ml-1 text-gray-500">
                          {consumableUsed.consumable?.unit}
                        </span>
                      </span>
                    </td>
                    <td className="px-3 py-2">
                      <CurrencyValue
                        value={
                          Math.abs(consumableUsed.adjustment ?? 0) *
                          (consumableUsed.cost_per_unit ?? 0)
                        }
                      />
                    </td>
                  </tr>
                )
              })}
              <tr>
                <td className="py-2">
                  <span className="mr-2 font-medium">
                    {i18n.t("consumables:labels.total")}
                  </span>
                  {workOrder.consumables_used.length}{" "}
                  {i18n.t("consumable", { count: workOrder.consumables_used?.length ?? 0 })}
                </td>
                <td className="whitespace-nowrap px-3 py-2"></td>
                <td className="px-3 py-2">
                  <CurrencyValue
                    value={workOrder.consumables_used.reduce(
                      (acc, consumableUsed) =>
                        acc +
                        Math.abs(consumableUsed.adjustment ?? 0) *
                          (consumableUsed.cost_per_unit ?? 0),
                      0
                    )}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </CollapsibleSection>
  ) : (
    <div>{<AddConsumableButton />}</div>
  )
}
