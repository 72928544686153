import { Center } from "@components/layout"
import PageTitle from "@components/page/page-title"
import { Button, LoadingIndicator } from "@components/shared"
import { DialogForm } from "@components/shared/dialog-form"
import { FormField } from "@components/shared/form/form-field"
import { TextArea } from "@components/shared/text-area"
import { TextInput } from "@components/shared/text-input"
import EmptyState from "@components/shared/v3/empty-state"
import { useBreakpoint } from "@contexts/breakpoints"
import { usePage } from "@contexts/page-context"
import { AppWorkOrderCreateScope, useUser } from "@contexts/user-context"
import { IWorkOrderTemplateInsertInput, IWorkOrderTypeEnum } from "@elara/db"
import { orderBy } from "@elara/select"
import {
  CreateWorkOrderTemplateDocument,
  ICreateWorkOrderTemplateMutation,
  ICreateWorkOrderTemplateMutationVariables,
  useWorkOrderTemplatesQuery,
} from "@graphql/documents/work-order.generated"
import { IPermissionScopeEnum, useDisclosure, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { FileDotted, Plus } from "@phosphor-icons/react"
import { Link } from "react-router-dom"
import { useClient } from "urql"
import * as yup from "yup"

type FormValues = {
  name: string
  description: string
}

const TemplateFormSchema = yup.object().shape({
  name: yup
    .string()
    .required(i18n.t("common:forms.is_required", { field: i18n.t("common:name") })),
  description: yup.string(),
})

const CreateTemplateForm = (props: {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
}) => {
  const client = useClient()

  const scope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderCreate)

  const handleSubmit = async (values: FormValues) => {
    const data: IWorkOrderTemplateInsertInput = {
      ...values,
      work_order: {
        data: {
          type: IWorkOrderTypeEnum.Template,
          name: `Vorlage: ${values.name}`,
        },
      },
    }

    const res = await client
      .mutation<
        ICreateWorkOrderTemplateMutation,
        ICreateWorkOrderTemplateMutationVariables
      >(CreateWorkOrderTemplateDocument, { data }, scope.context())
      .toPromise()

    if (res.error) {
      throw res.error
    }
  }

  return (
    <DialogForm<FormValues>
      title={i18n.t("templates:tasks.select_dialog.labels.create_template")}
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
      formikConfig={{
        initialValues: { name: "", description: "" },
        validationSchema: TemplateFormSchema,
        onSubmit: handleSubmit,
      }}>
      {() => (
        <div className="mt-4">
          <FormField name="name" label={i18n.t("common:name")}>
            {({ field }) => <TextInput {...field} />}
          </FormField>

          <FormField name="description" label={i18n.t("tasks:fields.description")}>
            {({ field }) => <TextArea rows={2} {...field} />}
          </FormField>
        </div>
      )}
    </DialogForm>
  )
}

const TaskTemplates = () => {
  const bp = useBreakpoint()
  const user = useUser()

  usePage({
    id: "templates",
    isSubPage: bp.renderMobile,
    title: i18n.t("common:template", { count: 2 }),
  })

  const createScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderCreate)
  const canOnlySelectTemplate = !!(createScope.scope as AppWorkOrderCreateScope)?.options
    ?.template_only
  const canOnlyEditOwnTemplate = !!(createScope.scope as AppWorkOrderCreateScope)?.options
    ?.owned_only
  const [dataRes, fetchTemplates] = useWorkOrderTemplatesQuery({
    requestPolicy: "cache-first",
  })
  const data = orderBy(dataRes.data?.work_order_template ?? [], { name: "asc" })

  const templateCreate = useDisclosure({
    onClose: () => fetchTemplates({ requestPolicy: "network-only" }),
  })

  if (dataRes.fetching) {
    return (
      <Center flex="1">
        <LoadingIndicator size={24} />
      </Center>
    )
  }

  return (
    <div className="flex min-h-0 flex-1 flex-col bg-gray-50">
      <div className="mx-auto flex min-h-0 w-full max-w-3xl flex-1 flex-col px-3">
        <PageTitle
          title={i18n.t("common:template", { count: 2 })}
          subtitle={i18n.t("settings:templates.description")}
          action={
            <Button
              disabled={canOnlySelectTemplate || !createScope.hasScope}
              disabledReason={i18n.t("common:messages.lack_of_permissions")}
              onClick={templateCreate.onOpen}>
              {i18n.t("common:new_token", {
                context: "female",
                token: i18n.t("common:template", { count: 1 }),
              })}
            </Button>
          }
        />
        <div className="mt-3 flex flex-col items-center">
          {data.length > 0 ? (
            <div className="mb-3 w-full max-w-3xl rounded border border-gray-300 bg-white">
              <ul role="list" className="list-none divide-y divide-gray-300">
                {data?.map((template) => (
                  <li key={template.id} className="list-none">
                    <Link
                      to={`${template.id}`}
                      className="flex cursor-pointer flex-col gap-4 truncate p-4 text-sm hover:bg-gray-100 hover:text-black sm:flex-row sm:items-center sm:justify-between">
                      <div className="space-y-1">
                        <div className="font-medium">{template.name}</div>
                        <div className="max-w-md truncate text-xs text-gray-500 lg:max-w-xl">
                          {template.description ?? i18n.t("tasks:messages.no_description")}
                        </div>
                      </div>
                      <div>
                        <Button
                          type="secondary"
                          disabled={
                            !createScope.hasScope ||
                            canOnlySelectTemplate ||
                            (template.work_order.created_by_id != user.id &&
                              canOnlyEditOwnTemplate)
                          }
                          disabledReason={i18n.t("common:messages.lack_of_permissions")}>
                          {i18n.t("common:edit")}
                        </Button>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div className="mx-auto py-8">
              <EmptyState
                icon={<FileDotted size={64} weight="thin" />}
                title={i18n.t("templates:tasks.empty_state.title")}
                message={i18n.t("templates:tasks.empty_state.message")}
                action={
                  <Button
                    type="tertiary"
                    icon={Plus}
                    onClick={templateCreate.onOpen}
                    disabled={!createScope.hasScope}
                    disabledReason={i18n.t("common:messages.lack_of_permissions")}>
                    {i18n.t("common:new_token", {
                      context: "female",
                      token: i18n.t("common:template", { count: 1 }),
                    })}
                  </Button>
                }
              />
            </div>
          )}
        </div>
        <CreateTemplateForm
          isOpen={templateCreate.isOpen}
          onOpenChange={templateCreate.changeOpen}
        />
      </div>
    </div>
  )
}

export default TaskTemplates
