// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import { SubtaskTagFragmentDoc } from '../../../graphql/documents/fragments.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IRemoveSubtaskMutationVariables = Types.Exact<{
  subtaskId: Types.Scalars['uuid']['input'];
  parentTaskId: Types.Scalars['uuid']['input'];
}>;


export type IRemoveSubtaskMutation = { subtask: { __typename: 'work_order', id: uuid } | null, parent: { __typename: 'work_order', id: uuid, subtasks: Array<{ __typename: 'work_order', id: uuid, name: string, status: Types.IWorkOrderStatusEnum, priority: Types.IWorkOrderPriorityEnum | null, work_order_number: number, parent_work_order_id: uuid | null, sort_order: number, reminder_config: {} | null, due_date: string | null, due_time: string | null, recurrence_info: {} | null, assignees: Array<{ __typename: 'work_order_x_user', user_id: uuid, user: { __typename: 'user', id: uuid, first_name: string, last_name: string, deleted_at: string | null, avatar: { __typename: 'upload', id: uuid, thumbnail_url: string | null, url: string | null } | null } }> }> } | null };

export type IDeleteSubtaskMutationVariables = Types.Exact<{
  subtaskId: Types.Scalars['uuid']['input'];
}>;


export type IDeleteSubtaskMutation = { update_work_order_by_pk: { __typename: 'work_order', id: uuid, deleted_at: string | null } | null };


export const RemoveSubtaskDocument = gql`
    mutation RemoveSubtask($subtaskId: uuid!, $parentTaskId: uuid!) {
  subtask: update_work_order_by_pk(
    pk_columns: {id: $subtaskId}
    _set: {parent_work_order_id: null}
  ) {
    id
  }
  parent: update_work_order_by_pk(
    pk_columns: {id: $parentTaskId}
    _set: {updated_at: "now()"}
  ) {
    id
    subtasks {
      ...SubtaskTag
    }
  }
}
    ${SubtaskTagFragmentDoc}`;

export function useRemoveSubtaskMutation() {
  return Urql.useMutation<IRemoveSubtaskMutation, IRemoveSubtaskMutationVariables>(RemoveSubtaskDocument);
};
export const DeleteSubtaskDocument = gql`
    mutation DeleteSubtask($subtaskId: uuid!) {
  update_work_order_by_pk(
    pk_columns: {id: $subtaskId}
    _set: {deleted_at: "now()"}
  ) {
    id
    deleted_at
  }
}
    `;

export function useDeleteSubtaskMutation() {
  return Urql.useMutation<IDeleteSubtaskMutation, IDeleteSubtaskMutationVariables>(DeleteSubtaskDocument);
};