import { ReactRenderer } from "@tiptap/react"
import { SuggestionOptions } from "@tiptap/suggestion"
import tippy, { Instance } from "tippy.js"

import MentionList, { MentionItem } from "./rich-text-mention-list"

const suggest: Omit<SuggestionOptions<MentionItem>, "editor"> = {
  render: () => {
    // No idea how to type this correctly
    let component: ReactRenderer<any, any>
    let popup: Instance<any>[]

    return {
      onStart: (props) => {
        component = new ReactRenderer(MentionList, {
          props,
          editor: props.editor,
        })

        if (!props.clientRect) {
          return
        }

        // @ts-ignore
        popup = tippy("body", {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start",
          arrow: false,
        })
      },

      onUpdate(props) {
        component.updateProps(props)

        if (!props.clientRect) {
          return
        }

        if (popup && popup[0]) {
          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          })
        }
      },

      onKeyDown(props) {
        if (props.event.key === "Escape") {
          if (popup && popup[0]) {
            popup[0].hide()
          }

          return true
        }

        return component?.ref?.onKeyDown(props)
      },

      onExit() {
        if (popup && popup[0]) {
          popup[0].destroy()
        }
        component?.destroy()
      },
    }
  },
}

export default suggest
