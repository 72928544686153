import { useEffect } from "react"

/**
 * A hook
 */
type UsePollQueryArgs = {
  reexecute: () => Promise<void> | void
  isFetching: boolean
  pollInterval?: number
}

export function usePollQuery({
  reexecute,
  isFetching,
  pollInterval = 60 * 1000,
}: UsePollQueryArgs) {
  useEffect(() => {
    if (!isFetching) {
      const id = setTimeout(() => reexecute(), pollInterval)
      return () => {
        clearTimeout(id)
      }
    }
  }, [isFetching, reexecute, pollInterval])
}
