import { useCallback } from "react"

import useMemoRef from "./use-memo-ref"

export function useCallbackRef<T extends Function | undefined | null>(cb: T) {
  const ref = useMemoRef(cb)
  // wrap the ref in a stable function reference
  const wrapper = useCallback(
    (...args: any) => ref.current?.(...args),
    [ref]
  ) as unknown as T
  if (!cb) return cb
  return wrapper
}

export default useCallbackRef
