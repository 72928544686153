import React, { useLayoutEffect } from "react"

// Store all positions with the map location.key : scrollPosition
const positions = new Map<string, number>()
// // Keep track of all location keys to avoid memory leak
let keys: string[] = []

function addKeyandCleanUpPositions(key: string | undefined) {
  if (key && (!keys.length || keys[keys.length - 1] !== key)) {
    keys.push(key)
    // Clean up if we have at least 50 elements
    if (keys.length >= 50) {
      // delete the 40 oldest entries
      for (let i = 0; i < 40; i++) {
        positions.delete(keys[i])
      }
      keys = keys.slice(40)
    }
  }
}

export const useScrollRestoration = (
  ref: React.RefObject<HTMLElement> | React.MutableRefObject<HTMLElement>,
  key: string
) => {
  // Listen to scroll position
  useLayoutEffect(() => {
    addKeyandCleanUpPositions(key)
    if (ref.current && key) {
      const node = ref.current
      const listener = () => {
        positions.set(key, node.scrollTop)
      }
      node.addEventListener("scroll", listener)
      return () => {
        node.removeEventListener("scroll", listener)
      }
    }
  }, [key, ref.current])

  // Restore
  useLayoutEffect(() => {
    // Only restore when going back
    if (ref.current) {
      const oldPosition = positions.get(key)
      if (oldPosition) {
        ref.current.scrollTop = oldPosition
      }
    }
  }, [key, ref.current])
  return positions.get(key)
}

export default useScrollRestoration
