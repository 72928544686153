import { UserAvatarPlaceholder } from "@components/shared/avatar"
import AvatarGroup, { AssetAvatarGroup } from "@components/shared/avatar-group"
import { statusIcon } from "@components/work-order/work-order-status"
import { useFeature } from "@contexts/feature-flag-context"
import { IWorkOrderStatusEnum, IWorkOrderTypeEnum } from "@elara/db"
import { EventContentArg, EventInput, EventSourceFuncArg } from "@fullcalendar/core"
import {
  IMaintenanceFragment,
  IWorkOrderDataViewFragment,
} from "@graphql/documents/fragments.generated"
import {
  ITaskDataViewQuery,
  ITaskDataViewQueryVariables,
  TaskDataViewDocument,
} from "@graphql/documents/work-order.generated"
import * as IDate from "@internationalized/date"
import { Pause, Repeat } from "@phosphor-icons/react"
import Icons from "@resources/icons"
import { cn } from "@utils"
import { getCalendarScheduledMaintenanceEvents } from "@utils/maintenance"
import { parseAbsolute, toZoned } from "@utils/tzdate"
import React, { ComponentPropsWithoutRef } from "react"
import { Client } from "urql"

import { DataViewCalendar } from "../data-view-calendar"
import { useDataViewConfigContext } from "../data-view-config"
import {
  IMaintenanceTriggersQuery,
  IMaintenanceTriggersQueryVariables,
  MaintenanceTriggersDocument,
} from "./queries.generated"

async function fetchTasks(from: string, to: string, client: Client, where: any) {
  const res = await client
    .query<ITaskDataViewQuery, ITaskDataViewQueryVariables>(TaskDataViewDocument, {
      where: {
        _and: [
          where,
          { type: { _eq: IWorkOrderTypeEnum.WorkOrder } },
          { maintenance_id: { _is_null: false } },
          {
            _or: [
              {
                _and: [
                  { due_date: { _gte: from } },
                  { due_date: { _lt: to } },
                  {
                    status: {
                      _nin: [IWorkOrderStatusEnum.Done, IWorkOrderStatusEnum.Canceled],
                    },
                  },
                ],
              },
              {
                _and: [
                  { completed_at: { _gte: from } },
                  { completed_at: { _lt: to } },
                  {
                    status: {
                      _in: [IWorkOrderStatusEnum.Done, IWorkOrderStatusEnum.Canceled],
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    })
    .toPromise()
  const data = res.data?.work_order
  if (!data) return []

  const events = data
    .map((row) => {
      let startDate: Date | undefined
      if (row.completed_at) {
        startDate = toZoned(
          IDate.toCalendarDateTime(parseAbsolute(row.completed_at))
        ).toDate()
      } else if (row.due_date && row.due_time) {
        startDate = toZoned(
          IDate.toCalendarDateTime(
            IDate.parseDate(row.due_date),
            IDate.parseTime(row.due_time)
          )
        ).toDate()
      } else if (row.due_date) {
        startDate = toZoned(
          IDate.toCalendarDateTime(IDate.parseDate(row.due_date))
        ).toDate()
      }

      if (!startDate) return null

      const event = {
        id: row.id,
        title: row.name,
        start: startDate,
        extendedProps: {
          type: "task",
          data: row,
        },
      }
      return event satisfies EventInput
    })
    .filter(Boolean) as EventInput[]

  return events
}

async function fetchMaintenanceEvents(
  from: string,
  to: string,
  client: Client,
  where: any
) {
  const res = await client
    .query<IMaintenanceTriggersQuery, IMaintenanceTriggersQueryVariables>(
      MaintenanceTriggersDocument,
      { where }
    )
    .toPromise()
  const triggers = res.data?.maintenance_trigger ?? []

  return getCalendarScheduledMaintenanceEvents(triggers, new Date(from), new Date(to))
}

async function fetchEvents(
  info: EventSourceFuncArg,
  client: Client,
  where: any,
  hideUpcomingMaintenanceTasks: boolean
) {
  const taskEvents = await fetchTasks(info.startStr, info.endStr, client, where)
  if (hideUpcomingMaintenanceTasks) return taskEvents
  const maintenanceEvents = await fetchMaintenanceEvents(
    info.startStr,
    info.endStr,
    client,
    where
  )
  return taskEvents.concat(maintenanceEvents)
}

type TaskEventItemProps = {
  task: IWorkOrderDataViewFragment
  onSelect: (task: IWorkOrderDataViewFragment) => void
}

const TaskEventItem = ({ task, onSelect }: TaskEventItemProps) => {
  const hasMaintenance = useFeature("maintenance")
  const hasAssetsOrAssignees = task.assets.length > 0 || task.assignees.length > 0
  return (
    <div
      className={cn(
        "w-full items-stretch justify-stretch gap-x-0.5 gap-y-0 overflow-hidden px-1 py-0.5 leading-none",
        {
          "flex flex-wrap items-center": !hasAssetsOrAssignees,
          // "space-y-1": hasAssetsOrAssignees,
        }
      )}
      onClick={() => onSelect(task)}>
      <div className="flex min-w-0 items-center justify-stretch gap-1 leading-none">
        <span className="shrink-0">{statusIcon(task.status)}</span>
        <span className="text-xs text-gray-500">#{task.work_order_number}</span>

        {hasAssetsOrAssignees && (
          <div className="inline-flex flex-1 justify-end gap-1">
            <AssetAvatarGroup
              size={15}
              assets={task.assets.map((a) => a.asset)}
              hideFirstName
              hideExtraCount
            />
            <AvatarGroup
              size={15}
              hideFirstName
              hideExtraCount
              items={task.assignees.map(({ user }) => ({
                id: user.id,
                avatar: user.avatar,
                label: `${user.first_name} ${user.last_name}`,
                placeholder: <UserAvatarPlaceholder user={user} />,
              }))}
            />
          </div>
        )}
      </div>
      <div className="flex min-w-0 items-center gap-2 text-xs leading-none">
        <span className="truncate font-medium leading-none">{task.name}</span>
        {!hasMaintenance && task.recurrence_info && <span>{<Icons.Repeat />}</span>}
      </div>
    </div>
  )
}

export default TaskEventItem

export function MaintenanceDataViewCalendar(props: {
  onSelectTask: (task: IWorkOrderDataViewFragment) => void
  onSelectMaintenance: (maintenance: IMaintenanceFragment) => void
}) {
  const ctx = useDataViewConfigContext()
  const hideUpcomingMaintenanceTasks =
    !!ctx.config.calendarConfig?.hideUpcomingMaintenanceTasks
  const _fetchEvents = React.useCallback(
    ((info, client, where) =>
      fetchEvents(
        info,
        client,
        where,
        hideUpcomingMaintenanceTasks
      )) satisfies ComponentPropsWithoutRef<
      typeof DataViewCalendar<IWorkOrderDataViewFragment>
    >["fetchEvents"],
    [hideUpcomingMaintenanceTasks]
  )

  const eventItem = (info: EventContentArg) => {
    if (info.event.extendedProps.type === "task") {
      return (
        <TaskEventItem
          task={info.event.extendedProps.data as IWorkOrderDataViewFragment}
          onSelect={props.onSelectTask}
        />
      )
    } else if (info.event.extendedProps.trigger) {
      const maintenance = info.event.extendedProps.trigger.maintenance
      return (
        <button
          className={cn("group", { "opacity-75": maintenance.paused })}
          onClick={() => props.onSelectMaintenance(maintenance)}>
          <div className="flex items-center gap-1 rounded p-1">
            <div className="inline-flex items-center gap-0.5">
              {maintenance.paused && <Pause weight="fill" />}
              <Repeat size={14} />
            </div>

            <span className="min-w-0 truncate text-xs font-medium leading-none">
              {maintenance.name}
            </span>
          </div>
        </button>
      )
    }
    return null
  }

  return (
    <DataViewCalendar<IWorkOrderDataViewFragment>
      eventItem={eventItem}
      fetchEvents={_fetchEvents}
    />
  )
}
