import { Data } from "@elara/select"
import { PropsWithChildren } from "react"

import { useDataViewConfigContext } from "./data-view-config"
import { DataViewFilterContext } from "./data-view-filter/context"
import { useDataViewFilter } from "./data-view-filter/hooks"
import { Schema } from "./data-view-filter/schema"

export { useDataViewFilterContext } from "./data-view-filter/context"

export type DataViewFilterProps<D extends Data> = PropsWithChildren<{ schema: Schema<D> }>
export function DataViewFilter<D extends Data>(props: DataViewFilterProps<D>) {
  const config = useDataViewConfigContext()
  const filters = useDataViewFilter(
    config.config.filters ?? [],
    config.actions.updateFilters,
    {
      schema: props.schema,
      customConfigWhere: null,
      fixedFilters: [],
      hiddenFixedFilters: [],
      filterStickyness: "localStorage",
    }
  )

  return (
    <DataViewFilterContext.Provider value={filters}>
      {props.children}
    </DataViewFilterContext.Provider>
  )
}
