import Card from "@components/analytics/card"
import { AssetWithParents } from "@components/asset/asset-with-parents"
import { Button } from "@components/shared"
import { CurrencyValue } from "@components/shared/currency-value"
import DateRangePicker, { getRange } from "@components/shared/date-range-picker"
import { Select } from "@components/shared/select"
import Table, { TableHandle } from "@components/shared/table"
import WorkOrderTag from "@components/work-order/work-order-tag"
import { useBreakpoint } from "@contexts/breakpoints"
import { groupBy } from "@elara/select"
import { useConsumableAdjustmentsQuery } from "@graphql/documents/analytics.generated"
import {
  IAssetTagFragment,
  IConsumableLogFragment,
} from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { DownloadSimple } from "@phosphor-icons/react"
import {
  ColumnDef,
  createColumnHelper,
  getGroupedRowModel,
  Row,
  TableState,
} from "@tanstack/react-table"
import { DateRange, formatDate } from "@utils/date"
import { LinkWithBackgroundLocation } from "@utils/location"
import { startOfDay } from "date-fns"
import download from "downloadjs"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { parse } from "json2csv"
import React, { RefObject, useCallback, useImperativeHandle } from "react"
import { useMemo, useRef, useState } from "react"
import { useOutletContext } from "react-router-dom"

import { RefPayload } from ".."

const consumableLogHelper = createColumnHelper<IConsumableLogFragment>()

type AdjustmentFilter = "all" | "consumption" | "restock"

export const ConsumableAdjustments = () => {
  const bp = useBreakpoint()

  const [grouping, setGrouping] = useState<"none" | "consumable">("none")
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null)
  const [dateRange, setDateRange] = useState<DateRange>(getRange("last-30-days"))
  const [adjustmentFilter, setAdjustmentFilter] = useState<AdjustmentFilter>("all")

  const ref = useOutletContext<RefObject<RefPayload>>()
  const tableHandle = useRef<TableHandle<IConsumableLogFragment>>(null)

  useImperativeHandle(ref, () => ({ ref: containerRef, dateRange }), [
    containerRef,
    dateRange,
  ])

  const [query] = useConsumableAdjustmentsQuery({
    variables: {
      where: {
        ...(dateRange
          ? {
              adjustment_at: {
                _gte: dateRange?.start.toISOString(),
                _lte: dateRange?.end?.toISOString(),
              },
            }
          : { adjustment_at: { _is_null: false } }),
      },
    },
  })

  const adjustments = query.data?.consumable_log ?? []

  const data = adjustments.sort((a, b) =>
    a.adjustment_at.localeCompare(b.adjustment_at)
  ) as (IConsumableLogFragment & { quantity: { total: number; place: number | null } })[]

  const adjustmentFilterFn = useCallback(
    (row: Row<IConsumableLogFragment>) => {
      if (adjustmentFilter === "consumption") return row.original.adjustment < 0
      if (adjustmentFilter === "restock") return row.original.adjustment > 0
      return false
    },
    [adjustmentFilter]
  )

  const columns = useMemo(() => {
    const columns: ColumnDef<IConsumableLogFragment, any>[] = [
      consumableLogHelper.accessor("adjustment_at", {
        header: i18n.t("common:date"),
        enableGrouping: false,
        aggregatedCell: () => null,
        enableHiding: true,
        cell: (info) => {
          const timestamp = info.getValue()
          if (!timestamp) return null
          const date = new Date(timestamp)
          if (!data) return null
          return (
            <span>
              {formatDate(date, "P")}
              <span className="ml-1 text-gray-500">{formatDate(date, "p")}</span>
            </span>
          )
        },
      }),
      {
        id: "consumable.name",
        accessorKey: "consumable.name",
        header: i18n.t("common:consumable_one"),
        enableGrouping: true,
        aggregationFn: "unique",
        cell: (info) => {
          const consumable = info.row.original.consumable
          if (!consumable) return null
          return (
            <LinkWithBackgroundLocation to={"/consumable/" + consumable.id}>
              {consumable.name}
            </LinkWithBackgroundLocation>
          )
        },
        footer: (info) => {
          const count = info.table.getRowModel().rows.length
          return (
            <span>
              <span className="mr-1">{count}</span>
              <span className="text-gray-500">
                {i18n.t("common:consumable", { count })}
              </span>
            </span>
          )
        },
      },
      {
        id: "consumable.public_id",
        accessorKey: "consumable.public_id",
        header: i18n.t("consumables:fields.public_id"),
        enableGrouping: true,
        aggregationFn: "unique",
        cell: (info) => {
          const consumable = info.row.original.consumable
          if (!consumable) return null
          return <span className="ml-1 text-gray-500">{consumable.public_id}</span>
        },
      },
      consumableLogHelper.accessor("place", {
        header: i18n.t("common:location", { count: 1 }),
        cell: (info) => <div className="text-gray-500">{info.getValue()?.name ?? ""}</div>,
        enableGrouping: true,
      }),
      consumableLogHelper.accessor("adjustment", {
        header: i18n.t("consumables:fields.adjustment"),
        enableGrouping: true,
        aggregationFn: "sum",
        cell: (info) => (
          <div className="flex">
            <span className="shrink basis-[80px] text-right">
              <span className="inline-block w-2.5">{info.getValue() < 0 ? "−" : "+"}</span>
              <span>{Math.abs(info.getValue())}</span>
              <span className="ml-1 text-gray-500">
                {info.row.original.consumable?.unit}
              </span>
            </span>
          </div>
        ),
        filterFn: adjustmentFilterFn,
        footer: (info) => {
          const sums: Record<string, number> = {}
          for (const row of info.table.getRowModel().rows) {
            const consumable = row.original.consumable
            if (!consumable) continue
            if (!sums[consumable.unit]) sums[consumable.unit] = 0
            sums[consumable.unit] += row.original.adjustment
          }

          return (
            <div className="flex w-full">
              <div className="grid shrink basis-[80px] grid-cols-[auto_auto]">
                {Object.entries(sums).map(([unit, sum]) => {
                  return (
                    <React.Fragment key={unit}>
                      <span className="whitespace-nowrap text-right">
                        <span className="inline-block w-2.5">{sum < 0 ? "−" : "+"}</span>
                        {Math.abs(sum)}
                      </span>
                      <span className="ml-1 text-gray-500">{unit}</span>
                    </React.Fragment>
                  )
                })}
              </div>
            </div>
          )
        },
      }),
      consumableLogHelper.display({
        id: "cost",
        header: i18n.t("common:cost"),
        aggregationFn: "sum",
        cell: (info) => {
          const data = info.row.original
          return (
            <div className="flex">
              <CurrencyValue
                className="shrink basis-[80px] text-right"
                value={(data.cost_per_unit ?? 0) * Math.max(-(data.adjustment ?? 0), 0)}
              />
            </div>
          )
        },
        footer: (info) => {
          const cost = info.table.getRowModel().rows.reduce((sum, row) => {
            const data = row.original
            return sum + (data.cost_per_unit ?? 0) * Math.max(-(data.adjustment ?? 0), 0)
          }, 0)
          return (
            <div className="flex w-full">
              <CurrencyValue className="shrink basis-[80px] text-right" value={cost} />
            </div>
          )
        },
      }),
    ]
    columns.push(
      {
        id: "asset",
        accessorFn: (row) => row.asset,
        header: i18n.t("common:asset", { count: 1 }),
        cell: (info) =>
          info.getValue() ? (
            <div className="inline-block">
              <AssetWithParents
                withLink
                showAvatar
                asset={info.getValue() as IAssetTagFragment}
              />
            </div>
          ) : (
            <span className="px-2 text-gray-500">
              {i18n.t("consumables:messages.no_object_associated")}
            </span>
          ),
      },
      {
        id: "description",
        accessorFn: (row) => {
          return row
        },
        header: i18n.t("common:description"),
        cell: (info) => {
          const { task, description } = info.getValue()

          return (
            <>
              {task && <WorkOrderTag workOrder={task} hideStatus singleLine />}
              {description && (
                <span className="line-clamp-2 text-gray-700"> {description}</span>
              )}
            </>
          )
        },
      }
    )

    return columns
  }, [adjustmentFilterFn])

  const getTableState = () => {
    let state: Partial<TableState> = {}
    if (grouping === "consumable") {
      Object.assign(state, {
        grouping: ["consumable.name", "consumable.public_id"],
        columnVisibility: {
          adjustment_at: false,
          place: false,
          asset: false,
          description: false,
        },
      })
    }

    if (adjustmentFilter !== "all") {
      state.columnFilters = [{ id: "adjustment", value: null }]
    }

    return state
  }

  const adjustmentHistory = useMemo(() => {
    const result = groupBy(data, {
      groupId: (row) => formatDate(startOfDay(Date.parse(row.adjustment_at)), "PP"),
    }).map((group) => ({
      ...group,
      total_adjustment: group.items.reduce((acc, row) => acc + row.adjustment, 0),
    }))

    return result
  }, [data])

  const adjustmentHistoryChartOptions = useMemo<Highcharts.Options>(() => {
    const maxValue = Math.abs(
      Math.max(...adjustmentHistory.map((group) => group.total_adjustment))
    )

    return {
      chart: { type: "column" },
      legend: { enabled: false },
      title: { text: i18n.t("analytics:tabs.adjustment_history") },
      xAxis: {
        type: "datetime",
        crosshair: true,
        categories: adjustmentHistory.map((group) => group.groupId!),
      },
      yAxis: {
        min: maxValue * -1,
        max: maxValue,
        title: { text: i18n.t("analytics:tabs.consumable_adjustments") },
      },
      tooltip: {
        formatter: function () {
          const group = (this.point as any).payload as (typeof adjustmentHistory)[0]
          const breakdown = group.items
            .map((item) => `<li>${item.consumable?.name}: <b>${item.adjustment}</b></li>`)
            .join("<br/>")

          return `<p><p style="font-size: 12px; font-weight: 600">${group.groupId}</p><br/><ul>${breakdown}</ul></p>`
        },
      },
      series: [
        {
          type: "column",
          name: i18n.t("analytics:tabs.consumable_adjustments"),
          data: adjustmentHistory.map((group) => ({
            payload: group,
            y: group.total_adjustment,
          })),
        },
      ],
    } satisfies Highcharts.Options
  }, [adjustmentHistory])

  return (
    <div className="flex min-h-0 min-w-0 max-w-full flex-1 flex-col">
      <div className="p-4">
        <span className="mb-1 block text-xs font-medium">
          {i18n.t("analytics:metrics.consumables_adjustments.date_range")}
        </span>

        <div className="max-w-lg">
          <DateRangePicker range={dateRange} onRangeChange={setDateRange} />
        </div>
      </div>

      <div
        ref={setContainerRef}
        className="flex min-h-0 min-w-0 max-w-full flex-1 flex-col">
        <div className="px-4">
          <Card cardClassName="py-1 px-3">
            <HighchartsReact
              highcharts={Highcharts}
              options={adjustmentHistoryChartOptions}
            />
          </Card>
        </div>

        <hr className="my-4" />

        <div className="mb-3">
          <div className="flex items-center justify-between px-4 pb-3">
            <h3 className="font-semibold text-gray-700">
              {i18n.t("analytics:tabs.consumable_adjustments")}
            </h3>

            <Button
              type="secondary"
              icon={DownloadSimple}
              disabled={!data.length}
              onClick={() => {
                if (!tableHandle.current) return

                const { rows } = tableHandle.current.table.getRowModel()
                const data = rows.map((r) =>
                  Object.fromEntries(
                    r
                      .getVisibleCells()
                      .map((c) => [c.column.columnDef.header, c.getValue()])
                  )
                )
                const csv = parse(data)
                const filename = `elara-export-${new Date().toISOString().slice(0, 10)}.csv`
                download(csv, filename, "text/csv")
              }}>
              {i18n.t("common:actions.export_to_csv")}
            </Button>
          </div>

          <div className="flex space-x-3 px-4">
            <div className="inline-flex items-center rounded-md border bg-gray-50">
              <label className="px-2 text-sm font-medium text-gray-600">
                {i18n.t("analytics:consumables.grouping.label")}
              </label>
              <Select
                value={grouping}
                items={[
                  {
                    label: i18n.t("analytics:consumables.filters.grouping.none"),
                    value: "none",
                  },
                  {
                    label: i18n.t("common:consumable_one"),
                    value: "consumable",
                  },
                ]}
                className="-m-px w-40 rounded-l-none"
                onValueChange={(v) => setGrouping(v as "none" | "consumable")}
              />
            </div>
            <div className="inline-flex items-center rounded-md border bg-gray-50">
              <label className="px-2 text-sm font-medium text-gray-600">
                {i18n.t("analytics:consumables.filters.adjustment.label")}
              </label>
              <Select
                items={[
                  {
                    label: i18n.t("analytics:consumables.filters.adjustment.all"),
                    value: "all",
                  },
                  {
                    label: i18n.t("analytics:consumables.filters.adjustment.consumption"),
                    value: "consumption",
                  },
                  {
                    label: i18n.t("analytics:consumables.filters.adjustment.restock"),
                    value: "restock",
                  },
                ]}
                value={adjustmentFilter}
                className="-m-px w-32 rounded-l-none"
                onValueChange={(v) => setAdjustmentFilter(v as AdjustmentFilter)}
              />
            </div>
          </div>
        </div>

        <div className="mt-3 flex min-h-[300px] min-w-0 flex-1 flex-col overflow-hidden">
          <Table
            ref={tableHandle}
            key={bp.xl ? 1 : 0}
            className="flex min-h-0 min-w-0 max-w-full flex-1 flex-col"
            options={{
              columns,
              data: data,
              state: getTableState(),
              getGroupedRowModel: getGroupedRowModel(),
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default ConsumableAdjustments
