import { ActionMenu, Button } from "@components/shared"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { DotsThreeVertical, PencilSimpleLine, TrashSimple } from "@phosphor-icons/react"

type ConsumableGroupActionMenuProps = {
  onEdit: () => void
  onDelete: () => void
  inCreateMode?: boolean
}

const ConsumableGroupActionMenu = ({
  onEdit,
  onDelete,
  inCreateMode,
}: ConsumableGroupActionMenuProps) => {
  const editScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)
  const deleteScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderDelete)

  if (!editScope.hasScope && !deleteScope.hasScope) return null

  return (
    <ActionMenu
      items={[
        editScope.hasScope
          ? {
              key: "edit",
              icon: <PencilSimpleLine />,
              label: i18n.t("edit"),
              action: onEdit,
              disabled: inCreateMode,
            }
          : null,
        deleteScope.hasScope
          ? {
              key: "delete",
              icon: <TrashSimple />,
              label: i18n.t("common:delete"),
              action: onDelete,
            }
          : null,
      ]}>
      <Button
        size="small"
        type="tertiary"
        icon={DotsThreeVertical}
        className="!text-gray-600"
      />
    </ActionMenu>
  )
}

export default ConsumableGroupActionMenu
