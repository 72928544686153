import CreateEditConsumablePropertyFormDialog from "@components/consumable/dialogs/create-edit-consumable-property-form-dialog"
import SettingsPageLayout from "@components/settings/page-layout"
import { Button, UserMultiSelect } from "@components/shared"
import { alertDialog } from "@components/shared/alert-dialog-provider"
import { usePage } from "@contexts/page-context"
import { useUser } from "@contexts/user-context"
import { IPermissionScopeEnum, PropertyConfig, PropertyConfigs } from "@elara/db"
import { uuid } from "@elara/db"
import {
  ConsumableConfigDocument,
  useConsumableConfigQuery,
  useUpsertConsumableLowStockNotificationUserIdsMutation,
} from "@graphql/documents/consumable.generated"
import {
  ConsumableGroupsDocument,
  DeleteConsumableGroupDocument,
  IDeleteConsumableGroupMutation,
  IDeleteConsumableGroupMutationVariables,
} from "@graphql/documents/consumable-group.generated"
import { IConsumableGroupFragment } from "@graphql/documents/fragments.generated"
import { useDisclosure, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { PencilSimpleLine, Plus } from "@phosphor-icons/react"
import { useState } from "react"
import toast from "react-hot-toast"
import { useClient } from "urql"

import ConsumableGroupCard from "./consumable-groups/card"
import ConsumableGroupCreateEditForm from "./consumable-groups/create-edit-form"

type State = { type: "new" } | { type: "edit"; id: uuid } | null

const SettingsConsumableGroups = (props: {
  groups: IConsumableGroupFragment[]
  refreshGroups: () => Promise<void>
}) => {
  const client = useClient()
  const createScope = usePermissionScope(IPermissionScopeEnum.AppAssetCreate)
  const deleteScope = usePermissionScope(IPermissionScopeEnum.AppAssetArchiveDelete)

  const [state, setState] = useState<State>(null)

  const onDelete = (id: string) => {
    alertDialog({
      danger: true,
      title: i18n.t("common:delete_token", {
        token: i18n.t("consumables:fields.group", { count: 1 }),
      }),
      description: i18n.t("consumables:messages.group_delete_confirmation"),
      actionText: i18n.t("common:delete"),
      onAction: async () => {
        const res = await client
          .mutation<
            IDeleteConsumableGroupMutation,
            IDeleteConsumableGroupMutationVariables
          >(DeleteConsumableGroupDocument, { id }, deleteScope.context())
          .toPromise()

        if (!res) {
          toast.error(i18n.t("assets:messages.group_delete_failure"))
        } else {
          props.refreshGroups()
        }
      },
    })
  }

  return (
    <div className="mb-4">
      <div className="flex items-center justify-between">
        <h2 className="font-medium">{i18n.t("consumables:fields.group", { count: 2 })}</h2>
      </div>
      <p className="my-2 text-sm text-gray-500">
        {i18n.t("settings:consumable_groups.description")}
      </p>
      <div className="space-y-1">
        {props.groups.length > 0 || state?.type === "new" ? (
          <>
            {props.groups.map((group) => (
              <div key={group.id} className="list-none">
                <ConsumableGroupCard
                  consumableGroup={group}
                  isEditing={state?.type === "edit" && state.id === group.id}
                  onEdit={(id) => setState({ type: "edit", id })}
                  onDelete={() => onDelete(group.id)}
                  onCancel={() => setState(null)}
                />
              </div>
            ))}

            {state?.type === "new" && (
              <div className="flex h-16 cursor-pointer items-center truncate rounded border border-gray-100 bg-gray-50 px-4 text-sm">
                <ConsumableGroupCreateEditForm
                  onCancel={() => setState(null)}
                  afterSubmitSuccess={async () => {
                    await props.refreshGroups()
                    setState(null)
                  }}
                />
              </div>
            )}

            {state?.type !== "new" && (
              <Button
                onClick={() => setState({ type: "new" })}
                className=""
                disabled={!createScope.hasScope}
                disabledReason={i18n.t("common:missing_permission")}
                type="tertiary"
                icon={Plus}>
                {i18n.t("settings:consumables.actions.new_group")}
              </Button>
            )}
          </>
        ) : (
          state === null && (
            <button
              className="flex h-16 w-full items-center justify-center rounded-md border border-dashed p-3 text-sm text-gray-500"
              onClick={() => setState({ type: "new" })}
              disabled={!createScope.hasScope}>
              <span className="inline-flex items-center">
                <Plus size={16} weight="bold" />
                <span className="ml-2">
                  {i18n.t("settings:consumables.actions.add_first_group")}
                </span>
              </span>
            </button>
          )
        )}
      </div>
    </div>
  )
}

const SettingsProperties = (props: {
  properties: PropertyConfigs
  refreshProperties: () => Promise<void>
}) => {
  const [editedProperty, setEditedProperty] = useState<
    (PropertyConfig & { id: string }) | null
  >(null)

  const adminScope = usePermissionScope(IPermissionScopeEnum.AppAccountManagement)

  const { properties } = props

  const form = useDisclosure({
    onClose: () => {
      setEditedProperty(null)
      props.refreshProperties()
    },
  })

  return (
    <>
      <div className="flex items-center justify-between">
        <h2 className="font-medium">
          {i18n.t("consumables:fields.property", { count: 2 })}
        </h2>
      </div>
      <p className="my-2 text-sm text-gray-500">
        {i18n.t("settings:consumables:properties:description")}
      </p>
      <div>
        <div className="divide-y rounded border text-sm">
          {[
            { label: i18n.t("consumables:fields.public_id"), required: true },
            { label: i18n.t("consumables:fields.name"), required: true },
            { label: i18n.t("consumables:fields.unit"), required: true },
            { label: i18n.t("consumables:fields.min_quantity"), required: true },
            { label: i18n.t("consumables:fields.location"), required: false },
            { label: i18n.t("consumables:fields.description"), required: false },
          ].map(({ label, required }) => (
            <div className="flex items-center justify-between p-2">
              <div>
                <span className="font-medium">{label}</span>{" "}
                {required && (
                  <span className="ml-1 text-gray-500">
                    {i18n.t("common:forms.required")}
                  </span>
                )}
              </div>
            </div>
          ))}

          {Object.entries(properties)
            ?.sort((a, b) => a[1]?.name.localeCompare(b[1]?.name))
            .map(([id, consumableProperty]) => (
              <div key={id} className="flex items-center justify-between p-2">
                <div>
                  <span className="font-medium">{consumableProperty.name}</span>{" "}
                  {consumableProperty.required && (
                    <span className="ml-1 text-gray-500">
                      {i18n.t("common:forms.required")}
                    </span>
                  )}
                </div>

                <Button
                  type="tertiary"
                  className="-m-1"
                  icon={PencilSimpleLine}
                  onClick={() => {
                    setEditedProperty({ ...consumableProperty, id })
                    form.onOpen()
                  }}
                />
              </div>
            ))}
        </div>
        <Button
          onClick={form.onOpen}
          className="mt-1"
          disabled={!adminScope.hasScope}
          disabledReason={i18n.t("common:missing_permission")}
          type="tertiary"
          icon={Plus}>
          {i18n.t("settings:consumables.actions.new_property")}
        </Button>
      </div>

      <CreateEditConsumablePropertyFormDialog
        isOpen={form.isOpen}
        onOpenChange={form.changeOpen}
        initialValues={editedProperty}
      />
    </>
  )
}

const SettingsConsumables = () => {
  usePage({
    id: "settings/consumables",
    title: i18n.t("common:consumable", { count: 2 }),
    isSubPage: true,
  })

  const adminScope = usePermissionScope(IPermissionScopeEnum.AppAccountManagement)
  const { location } = useUser()
  const [, upsertNotificationUserIds] =
    useUpsertConsumableLowStockNotificationUserIdsMutation()
  const client = useClient()
  const [consumableConfigQueryRes] = useConsumableConfigQuery({
    variables: { locationId: location.id },
    requestPolicy: "cache-and-network",
  })

  const refetchConfig = async () => {
    await client
      .query(
        ConsumableConfigDocument,
        { locationId: location.id },
        { requestPolicy: "network-only" }
      )
      .toPromise()
  }

  const config = consumableConfigQueryRes.data?.consumable_config_by_pk
  const properties = (config?.properties ?? {}) as PropertyConfigs
  const groups = consumableConfigQueryRes.data?.consumable_group ?? []

  return (
    <SettingsPageLayout
      title={i18n.t("common:consumable", { count: 2 })}
      description={i18n.t("settings:consumables.description")}>
      <div>
        <SettingsConsumableGroups
          groups={groups}
          refreshGroups={async () => {
            await Promise.all([
              client
                .query(
                  ConsumableConfigDocument,
                  { locationId: location.id },
                  { requestPolicy: "network-only" }
                )
                .toPromise(),
              client.query(ConsumableGroupsDocument, {}).toPromise(),
            ])
          }}
        />

        <SettingsProperties properties={properties} refreshProperties={refetchConfig} />

        <div className="mt-8">
          <h2 className="font-medium">{i18n.t("common:notification", { count: 2 })}</h2>
          <p className="my-2 text-sm text-gray-600">
            {i18n.t("settings:consumables.notifications.description")}
          </p>
          <UserMultiSelect
            value={(config?.low_stock_notification_user_ids ?? []) as string[]}
            onChange={async (users) => {
              await upsertNotificationUserIds(
                { locationId: location.id, data: users },
                adminScope.context()
              )
              refetchConfig()
            }}
            config={{
              countLabel: i18n.t("common:employee", { count: 2 }),
              placeholder: i18n.t("common:search_token", {
                token: i18n.t("common:employee", { count: 1 }),
              }),
              noDataPlaceholder: i18n.t("common:no_token_found", {
                token: i18n.t("common:employee", { count: 2 }),
              }),
            }}
          />
        </div>
      </div>
    </SettingsPageLayout>
  )
}

export default SettingsConsumables
