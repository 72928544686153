import {
  DEFAULT_FAVORITE_ICON_NAME,
  DEFAULT_TEAM_ICON_COLOR,
  DEFAULT_TEAM_ICON_NAME,
  IconData,
  TeamIcon,
  teamIconColors,
  teamIcons,
} from "@components/shared/team-icons"
import i18n from "@i18n"
import classNames from "classnames"

import Button from "./button"
import { PopoverContent, PopoverRoot, PopoverTrigger } from "./popover"
import ScrollArea from "./scroll-area"

type Props = {
  section: string
  noBg?: boolean
  name?: string | null
  color?: string | null
  className?: string
  onChange: (iconData: IconData) => void
  showChangeButton?: boolean
}

const IconPicker = (props: Props) => {
  const name =
    props.name ??
    (props.section === "favorite" ? DEFAULT_FAVORITE_ICON_NAME : DEFAULT_TEAM_ICON_NAME)
  const color = props.color ?? DEFAULT_TEAM_ICON_COLOR

  const noBg = props.noBg ?? false

  return (
    <PopoverRoot>
      <PopoverTrigger asChild>
        <label className="flex items-center">
          <button
            type="button"
            className={classNames(
              "relative flex items-center justify-center w-8 h-8 border rounded hover:border-gray-100 hover:bg-gray-50",
              props.className,
              {
                "border-gray-100 bg-gray-50": !noBg,
                "border-transparent": noBg,
              }
            )}>
            <TeamIcon name={name} className="text-base" color={color} />
          </button>
          {props.showChangeButton && (
            <Button type="secondary" size="small" className="ml-2">
              {i18n.t("common:change")}
            </Button>
          )}
        </label>
      </PopoverTrigger>

      <PopoverContent
        className="mt-2 h-auto w-64 rounded border !border-gray-300 bg-white"
        side="bottom"
        align="start">
        {/* Colors */}
        <div className="flex gap-3 p-1.5">
          {teamIconColors.map((_color) => (
            <div
              key={_color}
              style={{ backgroundColor: _color }}
              onClick={() => {
                props.onChange({ name, color: _color })
              }}
              className={classNames(
                "w-[1em] h-[1em] rounded-full border cursor-pointer border-black/10 hover:opacity-75",
                { "border-2": color === _color }
              )}
            />
          ))}
        </div>
        <hr />

        <ScrollArea vertical type="auto" className="max-h-[140px] overflow-hidden">
          <div className="flex flex-wrap gap-3 p-1.5" style={{ color }}>
            {Object.entries(teamIcons).map(([name, Icon]) => (
              <div
                key={name}
                className="h-[1em] w-[1em] cursor-pointer text-base hover:opacity-75"
                onClick={() => props.onChange({ color, name })}>
                <Icon />
              </div>
            ))}
          </div>
        </ScrollArea>
      </PopoverContent>
    </PopoverRoot>
  )
}

export default IconPicker
