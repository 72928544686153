import i18n from "@i18n"
import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog"
import classNames from "classnames"
import { PropsWithChildren, ReactNode } from "react"

import Button from "./button"

export type AlertDialogContentProps = {
  title: string
  description: ReactNode
  cancelText?: string
  actionText?: string
  action?: ReactNode
  onAction?: () => void | Promise<void>
  onCancel?: () => void | Promise<void>
  cancelDisabled?: boolean | undefined
  danger?: boolean
}

export const AlertDialogTrigger = AlertDialogPrimitive.Trigger
export const AlertDialogRoot = AlertDialogPrimitive.Root
export const AlertDialogContent = (props: AlertDialogContentProps) => {
  return (
    <AlertDialogPrimitive.Portal>
      <AlertDialogPrimitive.Overlay className="fixed inset-0 bg-black/50" />
      <AlertDialogPrimitive.Content
        className={classNames(
          "fixed isolate",
          "w-[95vw] max-w-md rounded-lg p-6 md:w-full",
          "top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]",
          "bg-white ",
          "focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
        )}>
        <AlertDialogPrimitive.Title className="text-base font-medium text-gray-900">
          {props.title}
        </AlertDialogPrimitive.Title>
        <AlertDialogPrimitive.Description
          className="mt-2 text-sm font-normal text-gray-600 "
          asChild>
          <div>{props.description}</div>
        </AlertDialogPrimitive.Description>
        <div className="mt-4 flex justify-end space-x-4">
          {!props.cancelDisabled && (
            <AlertDialogPrimitive.Cancel asChild>
              <Button type="secondary" onClick={props.onCancel}>
                {props.cancelText ?? i18n.t("cancel")}
              </Button>
            </AlertDialogPrimitive.Cancel>
          )}
          <AlertDialogPrimitive.Action asChild>
            {props.action ?? (
              <Button
                type="primary"
                onClick={props.onAction}
                color={props.danger ? "red" : "blue"}>
                {props.actionText}
              </Button>
            )}
          </AlertDialogPrimitive.Action>
        </div>
      </AlertDialogPrimitive.Content>
    </AlertDialogPrimitive.Portal>
  )
}

export type AlertDialogProps = PropsWithChildren<{
  open?: boolean
  onOpenChange?: (open: boolean) => void
}> &
  AlertDialogContentProps

export const AlertDialog = ({ open, onOpenChange, ...props }: AlertDialogProps) => {
  return (
    <AlertDialogRoot open={open} onOpenChange={onOpenChange}>
      {props.children && <AlertDialogTrigger asChild>{props.children}</AlertDialogTrigger>}
      <AlertDialogContent {...props} />
    </AlertDialogRoot>
  )
}
