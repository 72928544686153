import {
  IMutationRootDeleteProjectByPkArgs,
  IMutationRootDeleteProjectXTeamByPkArgs,
  IMutationRootInsertProjectXTeamOneArgs,
  IMutationRootUpdateProjectByPkArgs,
} from "@elara/db"
import {
  IProjectFragment,
  ITeamFragment,
  ProjectFragmentDoc,
  TeamNameIconFragmentDoc,
} from "@graphql/documents/fragments.generated"
import { IProjectsQuery, ProjectsDocument } from "@graphql/documents/project.generated"
import { OptimisticMutationResolver, UpdateResolver } from "@urql/exchange-graphcache"

type Mutations = {
  [fieldName: string]: UpdateResolver
}
type Optimistic = {
  [fieldName: string]: OptimisticMutationResolver
}

const optimistic: Optimistic = {
  update_project_by_pk(vars: IMutationRootUpdateProjectByPkArgs, cache) {
    const project = cache.readFragment(ProjectFragmentDoc, { id: vars.pk_columns.id })
    return { __typename: "project", ...project, ...vars._set }
  },
  delete_project_x_team_by_pk(vars: IMutationRootDeleteProjectXTeamByPkArgs) {
    return { __typename: "project_x_team", ...vars }
  },
  insert_project_x_team_one(vars: IMutationRootInsertProjectXTeamOneArgs, cache) {
    const project = cache.readFragment(ProjectFragmentDoc, {
      id: vars.object.project_id!,
    }) as IProjectFragment | null
    project?.teams?.push({
      __typename: "project_x_team",
      team: {
        __typename: "team",
        ...cache.readFragment(TeamNameIconFragmentDoc, { id: vars.object.team_id! })!,
      } as ITeamFragment,
    })
    return {
      __typename: "project_x_team",
      team_id: vars.object.team_id!,
      project_id: vars.object.project_id!,
      project: { __typename: "project", ...project },
    }
  },
}

const mutations: Mutations = {
  delete_project_x_team_by_pk(
    _parent,
    args: IMutationRootDeleteProjectXTeamByPkArgs,
    cache
  ) {
    const project = cache.readFragment(ProjectFragmentDoc, {
      id: args.project_id!,
    }) as IProjectFragment | null
    if (project) {
      cache.writeFragment(ProjectFragmentDoc, {
        ...project,
        __typename: "project",
        teams: project?.teams?.filter((t) => t.team?.id !== args.team_id),
      })
    }
  },
  delete_project_by_pk(_parent, args: IMutationRootDeleteProjectByPkArgs, cache) {
    cache.updateQuery<IProjectsQuery>({ query: ProjectsDocument }, (data) => {
      if (data) {
        data.project = data.project.filter((p) => p.id !== args.id)
      }
      return data
    })
  },
}

export default {
  optimistic,
  mutations,
}
