import classNames from "classnames"

export type EmptyStateProps = {
  icon?: React.ReactNode
  title: string
  message?: string
  children?: string
  className?: string
  action?: React.ReactNode
}

const EmptyState: React.FC<EmptyStateProps> = ({
  icon,
  title,
  action,
  message,
  children,
  className,
}) => (
  <div
    className={classNames(
      "flex flex-col space-y-4 items-center max-w-lg text-center",
      className
    )}>
    {icon}
    <p className="font-medium">{title}</p>
    {children}
    {!children && message && (
      <p className="text-sm leading-relaxed text-gray-600">{message}</p>
    )}
    {action}
  </div>
)

export default EmptyState
