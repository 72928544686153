import {
  IUpsertContactMutation,
  IUpsertContactMutationVariables,
  UpsertContactDocument,
  useContactsQuery,
} from "@graphql/documents/contact.generated"
import { IContactFragment } from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { AddressBook } from "@phosphor-icons/react"
import { TreeLike } from "@utils/tree"
import { useCallback, useMemo } from "react"
import { useClient } from "urql"

import { Item } from "./combobox-select"
import {
  MultiSelectPopover,
  MultiSelectPopoverProps,
  MultiSelectSummary,
} from "./multi-select"
import { SelectPopover, SelectPopoverProps } from "./single-select"

const always = () => true

export function contactItems(
  contacts: IContactFragment[],
  contactIsSelectable: (contact: IContactFragment) => boolean = always
) {
  return contacts
    .filter((t) => t && contactIsSelectable(t))
    .map((item) => {
      return {
        value: item.id,
        label: (
          <span className="truncate">
            {item.company}
            <span className="ml-1 text-gray-500">{item.name}</span>
          </span>
        ),
        searchValue: `${item.company} ${item.name}`,
      }
    }, {})
    .sort((a, b) => a.searchValue.localeCompare(b.searchValue))
}

const useContactSelect = (
  contactIsSelectable?: (contact: IContactFragment) => boolean,
  summaryMode?: "count" | "summary"
) => {
  const [queryResult] = useContactsQuery({ requestPolicy: "cache-first" })

  const contacts = useMemo(
    () => (queryResult.data?.contact ?? []).filter(contactIsSelectable ?? always),
    [queryResult.data?.contact, contactIsSelectable]
  )

  const items = useMemo(
    () => contactItems(queryResult.data?.contact ?? [], contactIsSelectable),
    [contacts, contactIsSelectable]
  )

  const client = useClient()
  const onCreate = useCallback(
    async (name: string): Promise<string> => {
      const result = await client
        .mutation<IUpsertContactMutation, IUpsertContactMutationVariables>(
          UpsertContactDocument,
          { data: { company: name, name: i18n.t("common:unknown") } }
        )
        .toPromise()
      const id = result.data?.insert_contact_one?.id
      if (result.error || !id) throw result.error
      return id
    },
    [client]
  )

  return {
    items,
    valueToString: (s: string) => s,
    placeholder: i18n.t("common:select_token", {
      token: i18n.t("common:contact", { count: 1 }),
    }),
    noDataPlaceholder: i18n.t("common:no_token", {
      context: "plural",
      token: i18n.t("common:contact", { count: 2 }),
    }),
    size: "small" as "small" | "large",
    groupBy: false,
    onCreate,
    title: i18n.t("common:contact", { count: 1 }),
    renderCompactSelectedValues: (values: TreeLike<Item<string>>[]) => {
      const selectedContacts = contacts.filter((c) => values.some((v) => v.value === c.id))
      const firstContact = selectedContacts[0]

      return (
        <MultiSelectSummary
          limit={3}
          items={selectedContacts}
          itemsToIcons={() => (
            <AddressBook size={18} weight="fill" className="scale-[1.2] !text-gray-700" />
          )}
          firstItemLabel={`${firstContact?.company ?? ""}`}
          mode={summaryMode ?? "count"}
          countLabel={i18n.t("common:contact", { count: 2 })}
          iconBgClass="bg-gray-100"
          spaceBetweenIconsClass="-ml-1.5"
          iconBorderClass="border-gray-100"
        />
      )
    },
  }
}

export type ContactSingleSelectProps = Partial<SelectPopoverProps<string>> & {
  contactIsSelectable?: (contact: IContactFragment) => boolean
}

export function ContactSingleSelect({
  contactIsSelectable,
  ...props
}: ContactSingleSelectProps) {
  const selectProps = useContactSelect(contactIsSelectable)
  return <SelectPopover {...selectProps} {...props} />
}

export type ContactMultiSelectProps = Partial<MultiSelectPopoverProps<string>> & {
  contactIsSelectable?: (contact: IContactFragment) => boolean
  summaryMode?: "count" | "summary"
}

export function ContactMultiSelect({
  contactIsSelectable,
  summaryMode,
  ...props
}: ContactMultiSelectProps) {
  const selectProps = useContactSelect(contactIsSelectable, summaryMode)
  return <MultiSelectPopover {...selectProps} {...props} />
}
