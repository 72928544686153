import { AssetTag, InfoTagContainer } from "@components/shared"
import AlertBox from "@components/shared/alert-box"
import { useBreakpoint } from "@contexts/breakpoints"
import { IAssetTagFragment } from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { getFormattedDate } from "@utils"
import { Link } from "react-router-dom"

export type AssetArchivedReplacedTagProps = {
  archivedAt?: string | null
  replacedBy?: IAssetTagFragment | null
  replacementOf?: IAssetTagFragment | null
}
export const AssetArchivedReplacedTag = ({
  archivedAt,
  replacedBy,
  replacementOf,
}: AssetArchivedReplacedTagProps) => {
  const bp = useBreakpoint()
  const $size = bp.renderMobile ? "small" : "large"

  if (replacedBy) {
    return (
      <InfoTagContainer $size={$size}>
        <span className="text-sm text-gray-500">Ersetzt durch</span>
        <Link to={`/object/${replacedBy.id}`}>
          <AssetTag asset={replacedBy} />
        </Link>
      </InfoTagContainer>
    )
  }

  if (replacementOf) {
    return (
      <InfoTagContainer $size={$size}>
        <span className="text-sm text-gray-500">Nachfolgeanlage von</span>
        <Link to={`/object/${replacementOf.id}`}>
          <AssetTag asset={replacementOf} />
        </Link>
      </InfoTagContainer>
    )
  }

  if (archivedAt) {
    return (
      <AlertBox type="archive" className="mt-4 max-w-4xl">
        <span className="text-xs text-gray-600">
          {i18n.t("assets:fields.archived_at")}{" "}
          {getFormattedDate(new Date(archivedAt), { short: false })}
        </span>
      </AlertBox>
    )
  }

  return null
}

export default AssetArchivedReplacedTag
