import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { useMemo } from "react"

type Props = {
  data: { name: string; value: number }[]
}

export const ComponentDowntimeChart = ({ data }: Props) => {
  const options = useMemo<Highcharts.Options>(() => {
    return {
      chart: { type: "columns" },
      title: { text: "Component Downtime" },
      legend: { enabled: false },
      tooltip: { enabled: false },
      credits: { enabled: false },
      colors: ["#0284c7"],
      navigation: { buttonOptions: { enabled: false } },
      plotOptions: { series: { animation: false } },
      xAxis: { type: "category", categories: data.map((item) => item.name) },
      yAxis: {
        min: 0,
        showFirstLabel: false,
        title: { text: undefined },
        labels: { format: "{text} hours" },
      },
      series: [
        {
          type: "column",
          name: "Component Downtime",
          data: data.map((item) => item.value),
        },
      ],
    } satisfies Highcharts.Options
  }, [data])

  return <HighchartsReact highcharts={Highcharts} options={options} />
}
