import { Button } from "@components/shared"
import DateRangePicker from "@components/shared/date-range-picker"
import { getRange } from "@components/shared/date-range-picker"
import ScrollArea from "@components/shared/scroll-area"
import { useConsumableInsightsQuery } from "@graphql/documents/consumable.generated"
import { IConsumableFragment } from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { DownloadSimple } from "@phosphor-icons/react"
import { AreaChart, BarChart } from "@tremor/react"
import { DateRange, formatDate } from "@utils/date"
import download from "downloadjs"
import { parse } from "json2csv"
import { useState } from "react"

type InsightsPageProps = {
  consumable: IConsumableFragment
}

const InsightsPage = ({ consumable }: InsightsPageProps) => {
  const [range, setRange] = useState<DateRange>(() => getRange("last-30-days"))
  const [queryRes] = useConsumableInsightsQuery({
    variables: {
      id: consumable.id,
      startDate: range.start.toISOString().slice(0, 10),
      targetDate: (range.end ?? new Date()).toISOString().slice(0, 10),
    },
  })

  const quantities = (queryRes.data?.consumable_by_pk?.quantity_in_period ?? []) as {
    day: string
    quantity: number
    consumption: number
  }[]

  const unit = consumable.unit
  const historyData = quantities.map((d) => {
    return {
      date: formatDate(new Date(d.day), "P"),
      [unit]: d.quantity,
    }
  })
  const consumptionData = quantities.map((d) => {
    return {
      date: formatDate(new Date(d.day), "P"),
      [unit]: -d.consumption,
    }
  })

  const dataFormatter = (number: number) => {
    return `${number}`
  }

  return (
    <ScrollArea viewportAsChild vertical>
      <div className="space-y-6 p-4">
        <div className="inline-block">
          <div className="mb-1 whitespace-nowrap text-xs font-medium text-gray-600">
            {i18n.t("consumables:insights.period")}
          </div>
          <DateRangePicker range={range} onRangeChange={setRange} />
        </div>

        <div className="flex items-center justify-between">
          <h3 className="font-medium text-gray-700">
            {i18n.t("consumables:insights.quantity_history")}
          </h3>
          <Button
            color="gray"
            type="tertiary"
            icon={DownloadSimple}
            onClick={() => {
              const csv = parse(consumptionData, { delimiter: ";" })
              download(
                csv,
                `${consumable.public_id} - ${i18n.t(
                  "consumables:insights.quantity_history"
                )}.csv`,
                "text/csv"
              )
            }}>
            {i18n.t("common:export")}
          </Button>
        </div>

        <AreaChart
          className="h-72"
          data={historyData}
          index="date"
          categories={[unit]}
          showYAxis
          showLegend={false}
          colors={["blue"]}
          valueFormatter={dataFormatter}
        />
        <hr className="my-6 text-gray-200" />
        <div className="flex items-center justify-between">
          <h3 className="font-medium text-gray-700">
            {i18n.t("consumables:insights.consumption_history")}
          </h3>

          <Button
            color="gray"
            type="tertiary"
            icon={DownloadSimple}
            onClick={() => {
              const csv = parse(consumptionData, { delimiter: ";" })
              download(
                csv,
                `${consumable.public_id} - ${i18n.t(
                  "consumables:insights.consumption_history"
                )}.csv`,
                "text/csv"
              )
            }}>
            {i18n.t("common:export")}
          </Button>
        </div>

        <BarChart
          className=" h-72"
          data={consumptionData}
          index="date"
          categories={[unit]}
          showYAxis
          // showLegend={false}
          colors={["green"]}
          valueFormatter={dataFormatter}
        />
      </div>
    </ScrollArea>
  )
}

export default InsightsPage
