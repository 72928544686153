import "@sentry/tracing"

import { AuthenticationState } from "@models/authentication"
import * as Sentry from "@sentry/react"
import { authExchange } from "@urql/exchange-auth"
import { refocusExchange } from "@urql/exchange-refocus"
import { requestPolicyExchange } from "@urql/exchange-request-policy"
import { createClient as createWSClient } from "graphql-ws"
import jwtDecode, { JwtPayload } from "jwt-decode"
import {
  CombinedError,
  createClient,
  errorExchange,
  fetchExchange,
  Operation,
  subscriptionExchange,
} from "urql"

import { graphcache } from "./graphcache"

function reportGraphQLErrorToSentry(error: CombinedError, operation: Operation) {
  // Check if we have an error for an invalid refresh token and don't send it
  if ((operation?.query?.definitions[0] as any)?.name?.value === "RefreshAccessToken") {
    return
  }

  for (const err of error.graphQLErrors) {
    // Add scoped report details and send to Sentry
    Sentry.withScope((scope) => {
      // Annotate whether failing operation was query/mutation/subscription
      scope.setTag("kind", operation.kind)
      // Log query and variables as extras
      // (make sure to strip out sensitive data!)
      scope.setExtra("query", operation.query.loc?.source.body)
      scope.setExtra("variables", JSON.stringify(operation.variables, null, 2))
      scope.setExtra(
        "x-hasura-role",
        (operation.context.fetchOptions as any)?.header?.["x-hasura-role"] ?? "usr"
      )
      const path = (err.extensions as any).path
      scope.setExtra("path", path)
      if (path) {
        // We can also add the path as breadcrumb
        scope.addBreadcrumb({
          category: "path",
          message: path.replace(".", " < "),
          level: "debug",
        })
      }
      Sentry.captureException(err)
    })
  }
}

type MakeClientOptions = {
  getAuthState: () => AuthenticationState
}

async function waitIsAuthenticated(options: MakeClientOptions) {
  return new Promise<boolean>((resolve, reject) => {
    const authState = options.getAuthState()
    if (authState.state.stage !== "fully_authenticated") {
      // We are not yet authenticated, wait until keycloak
      // negogiates the authenticated state.
      // If a user it not logged in then they will
      // be redirected to another page and the timeout doesn't apply.
      const startTime = Date.now()
      // wait at most 30 seconds
      const maxWaitTime = 30 * 1000
      const id = setInterval(() => {
        const updatedAuthState = options.getAuthState()
        if (updatedAuthState.state.stage === "fully_authenticated") {
          clearInterval(id)
          resolve(true)
        } else if (Date.now() - startTime > maxWaitTime) {
          reject(false)
        }
      }, 500)
    } else {
      resolve(true)
    }
  })
}

function auth(options: MakeClientOptions) {
  return authExchange(async (utils) => {
    await waitIsAuthenticated(options)
    return {
      addAuthToOperation(operation) {
        const authState = options.getAuthState()
        if (authState.state.stage === "fully_authenticated") {
          return utils.appendHeaders(operation, {
            Authorization: `Bearer ${authState.getDataAccessToken()}`,
          })
        }
        return operation
      },
      didAuthError(err) {
        return err.graphQLErrors.some((e) => e?.extensions?.code === "invalid-jwt")
      },
      async refreshAuth() {
        const authState = options.getAuthState()
        await authState.fetchDataAccessToken()
      },
      willAuthError: () => {
        const authState = options.getAuthState()
        if (authState.state.stage !== "fully_authenticated") {
          return true
        }

        const payload = jwtDecode<JwtPayload>(authState.getDataAccessToken())
        const unixTimeExpiration = (payload.exp ?? 0) * 1000
        const expiresIn = unixTimeExpiration - Date.now()
        const isExpired = expiresIn < 15 * 1000

        return isExpired
      },
    }
  })
}

export const makeClient = (options: MakeClientOptions) => {
  const wsEndpoint = (
    (import.meta.env.VITE_HASURA_GRAPHQL_WEBSOCKET_ENDPOINT as string) ||
    window.location.origin
  )
    .replace(/^(https):\/\//, "wss://")
    .replace(/^(http):\/\//, "ws://")

  const wsClient = createWSClient({
    url: `${wsEndpoint}/v1/graphql`,
    connectionParams: () => {
      const authState = options.getAuthState()
      if (authState.state.stage === "fully_authenticated") {
        return { headers: { Authorization: `Bearer ${authState.getDataAccessToken()}` } }
      }
      return {}
    },
  })

  // VSCode TS and the TS watcher couldn't agree on the correct type, so I bailed...
  const sentryFetch = async (input: any, init?: RequestInit) => {
    const body = init?.body
    let type = ""
    let name = ""
    if (typeof body === "string") {
      const nameRegex = /"(query|mutation)\s*?([a-zA-Z0-9]+)/g
      const regRes = nameRegex.exec(body)
      type = regRes?.[1] ?? ""
      name = regRes?.[2] ?? ""
    }

    if (!name || !type) return fetch(input, init)

    let res: Response | undefined = undefined
    try {
      const authState = options.getAuthState().state
      const transaction = Sentry.startTransaction({
        op: "gql",
        name,
        tags: {
          location_id:
            authState.stage === "fully_authenticated" ? authState.dataLocationId : null,
          user_id: authState.stage === "fully_authenticated" ? authState.userInfo.id : null,
        },
      })
      transaction.setData("data", { type, body })
      res = await fetch(input, init)
      transaction.finish()
      return res
    } catch (err) {
    } finally {
      if (res) return res
      return fetch(input, init)
    }
  }

  const hasuraEndpoint =
    (import.meta.env.VITE_HASURA_GRAPHQL_ENDPOINT as string) || window.location.origin

  return createClient({
    url: `${hasuraEndpoint}/v1/graphql`,
    requestPolicy: "cache-and-network",
    maskTypename: true,
    fetch: sentryFetch,
    exchanges: [
      // devtoolsExchange,
      refocusExchange(),
      requestPolicyExchange({
        ttl: 60 * 1000,
        shouldUpgrade: (o) => {
          return o.context.requestPolicy !== "cache-only"
        },
      }),
      graphcache(),
      auth(options),
      errorExchange({
        onError(error, operation) {
          // Don't use VITE_ELARA_IN_PRODUCTION here to also get sentry
          // errors on test.elara.digital
          if (
            import.meta.env.VITE_ELARA_IN_PRODUCTION === "true" ||
            import.meta.env.VITE_ELARA_IN_TEST === "true"
          ) {
            reportGraphQLErrorToSentry(error, operation)
          } else {
            for (const err of error.graphQLErrors) {
              console.warn(err)
              console.warn(err.extensions)
              console.warn(operation.query?.loc?.source?.body)
            }
          }
        },
      }),
      fetchExchange,
      subscriptionExchange({
        forwardSubscription(request) {
          const input = { ...request, query: request.query || "" }
          return {
            subscribe(sink) {
              const unsubscribe = wsClient.subscribe(input, sink)
              return { unsubscribe }
            },
          }
        },
      }),
    ],
  })
}
