import { AssetWithParents } from "@components/asset/asset-with-parents"
import { HStack, VStack } from "@components/layout"
import { ScheduledEventItemById } from "@components/maintenance/components/scheduled-event-item-by-id"
import { Button, UserTag } from "@components/shared"
import { UserAvatar, UserAvatarPlaceholder } from "@components/shared/avatar"
import AvatarGroup from "@components/shared/avatar-group"
import CircularProgress from "@components/shared/circular-progress"
import { createSchema } from "@components/shared/data-view/data-view-filter.hooks"
import {
  DataViewFilterDatePicker,
  useDataViewFilterDatePicker,
} from "@components/shared/data-view/data-view-filter-date-picker"
import { OptionsMenuItem } from "@components/shared/data-view/data-view-options-menu"
import { MultiSelectSummary } from "@components/shared/multi-select"
import { TaskHoverCard } from "@components/shared/task-hover-card"
import { IconData, TeamIcon } from "@components/shared/team-icons"
import { TeamTag } from "@components/shared/team-tag"
import Toggle from "@components/shared/toggle"
import { Tooltip } from "@components/shared/tooltip"
import { useBreakpoint } from "@contexts/breakpoints"
import { openModal } from "@contexts/modal-context"
import { useUser } from "@contexts/user-context"
import {
  IBlockGroupTypeEnum,
  IPermissionScopeEnum,
  IViewDataTypeEnum,
  IWorkOrderStatusEnum,
  ProgressInfo,
  uuid,
} from "@elara/db"
import {
  IAssetGroupFragment,
  IWorkOrderDataViewFragment,
} from "@graphql/documents/fragments.generated"
import {
  useDeleteWorkOrderXWorkOrderCategoryMutation,
  useInsertWorkOrderXWorkOrderCategoryMutation,
  useUpdateWorkOrderPartiallyMutation,
} from "@graphql/documents/work-order.generated"
import { useCallbackRef, useDeepMemo, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import * as IDate from "@internationalized/date"
import { Plus, Rows, Shapes, Warning } from "@phosphor-icons/react"
import * as Dialog from "@radix-ui/react-dialog"
import Icons from "@resources/icons"
import { getFormattedDate, naturalCompare, parseDate } from "@utils"
import { formatFractionalHours, formatISODate, prettyDateFormat } from "@utils/date"
import { LinkWithBackgroundLocation, LocationState } from "@utils/location"
import { toZoned } from "@utils/tzdate"
import * as date from "date-fns"
import React, { useCallback, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { gql } from "urql"

import { Column } from "../new-data-view/data-view-types"
import {
  DataView,
  DataViewConfiguration,
  DataViewProps,
} from "../shared/data-view/data-view"
import { InitialFormValuesInput } from "./create-task-form.hooks"
import TaskEventItem from "./task-event-item"
import { WorkOrderQuickCompletedAt } from "./work_order-quick-completed-at"
import { WorkOrderAssignedEntities } from "./work-order-assigned-entities"
import { CategoryColor } from "./work-order-category-select"
import { WorkOrderCategoryTags } from "./work-order-category-tags"
import * as FilterConfiguration from "./work-order-data-view-filter-configuration"
import { WorkOrderDueDate, WorkOrderDueDatePopover } from "./work-order-due-date-popover"
import { WorkOrderNumber } from "./work-order-number"
import {
  priorityOrderValue,
  translateWorkOrderPriority,
  WorkOrderPriorityBadge,
  WorkOrderPriorityBadgeWithDropdown,
} from "./work-order-priority"
import { WorkOrderQuickAssigneeTags } from "./work-order-quick-assignee-tags"
import {
  isCompleted,
  statusIcon,
  statusOrderValue,
  translateWorkOrderStatus,
  WorkOrderStatusTag,
  WorkOrderStatusTagWithDropdown,
} from "./work-order-status"
import { WorkOrderSubtaskTag } from "./work-order-subtask"
import WorkOrderTag from "./work-order-tag"

export function getDueDateDatesGroupBy() {
  const today = date.startOfDay(new Date())
  // Later than all due dates since they start with the year
  const noDueDateOrderValue = "8888"
  const completedOrderValue = "9999"

  const dates = [
    { label: i18n.t("calendar:relative_dates.overdue"), end: today },
    { label: i18n.t("calendar:relative_dates.today"), end: date.endOfDay(today) },
    {
      label: i18n.t("calendar:relative_dates.tomorrow"),
      end: date.endOfDay(date.add(today, { days: 1 })),
    },
    { label: i18n.t("calendar:relative_dates.this_week"), end: date.endOfWeek(today) },
    {
      label: i18n.t("calendar:relative_dates.next_week"),
      end: date.endOfWeek(date.add(today, { weeks: 1 })),
    },
    { label: i18n.t("calendar:relative_dates.this_month"), end: date.endOfMonth(today) },
    {
      label: i18n.t("calendar:relative_dates.next_month"),
      end: date.endOfMonth(date.add(today, { months: 1 })),
    },
    {
      label: i18n.t("calendar:relative_dates.later"),
      end: date.endOfDay(date.add(today, { years: 100 })),
    },
    {
      label: i18n.t("common:without_token", { token: i18n.t("calendar:tokens.due_date") }),
      end: null,
    },
  ].map((x) => ({ ...x, orderValue: x.end?.toISOString() ?? noDueDateOrderValue }))

  const orderValues = dates.reduce((vals, d) => {
    vals[d.label] = d.orderValue
    return vals
  }, {} as Record<string, string>)

  const findGroup = (date: Date | null) => {
    const isoDate = date?.toISOString()
    if (!isoDate) return dates[dates.length - 1]
    for (let d of dates) {
      if (isoDate < d.orderValue) return d
    }
    return null
  }

  const groupBy: Column<
    IWorkOrderDataViewFragment,
    WorkOrderViewIds,
    WorkOrderViewOptions
  >["groupBy"] = {
    id: (row: IWorkOrderDataViewFragment) =>
      isCompleted(row.status)
        ? "completed"
        : findGroup(parseDate(row.due_date))?.label ?? null,
    label: (groupId) =>
      groupId === "completed"
        ? i18n.t("tasks:filters.completed.recently_completed")
        : dates.find((d) => d.label === groupId)?.label ??
          i18n.t("common:without_token", { token: i18n.t("calendar:tokens.due_date") }),
    labelText: (groupId) =>
      groupId === "completed"
        ? i18n.t("tasks:filters.completed.recently_completed")
        : dates.find((d) => d.label === groupId)?.label ??
          i18n.t("common:without_token", { token: i18n.t("calendar:tokens.due_date") }),
    orderValue: (groupId) => {
      if (groupId === "completed") {
        return completedOrderValue
      } else if (groupId) {
        return orderValues[groupId]
      } else {
        return noDueDateOrderValue
      }
    },
    defaultCollapsed: (groupId) =>
      groupId === i18n.t("calendar:relative_dates.overdue") ||
      groupId === i18n.t("calendar:relative_dates.later") ||
      groupId === "completed",
  }

  return groupBy
}

export type WorkOrderViewOptions = { shouldShowRecurringIcon?: boolean; logbook?: boolean }

export type WorkOrderViewIds =
  | "createdAt"
  | "createdBy"
  | "completedAt"
  | "updatedAt"
  | "completedBy"
  | "priority"
  | "name"
  | "workOrderNumber"
  | "task"
  | "dueDate"
  | "status"
  | "categories"
  | "assets"
  | "assetGroups"
  | "assignees"
  | "teams"
  | "entry"
  | "description"
  | "more"
  | "procedure_progress"
  | "project"
  | "total_hours_work_reports"
  | "parent_task"

export const columns: Column<
  IWorkOrderDataViewFragment,
  WorkOrderViewIds,
  WorkOrderViewOptions
>[] = [
  {
    id: "createdAt",
    key: "created_at",
    Header: i18n.t("tasks:fields.created_at"),
    Cell: (row) => getFormattedDate(parseDate(row.created_at), { includeTime: true }),
    toText: (row) => getFormattedDate(parseDate(row.created_at), { includeTime: true }),
    orderBy: (dir) => ({ created_at: dir }),
    calendarView: {
      event: (row) => ({
        id: row.id,
        title: row.name,
        start: new Date(row.created_at),
      }),
    },
    groupBy: {
      id: (row) => formatISODate(new Date(row.created_at)),
      label: (_groupId, row) => date.format(new Date(row!.created_at), prettyDateFormat),
      labelText: (_groupId, row) =>
        date.format(new Date(row!.created_at), prettyDateFormat),
      orderValue: (_groupId, row) => row!.created_at,
    },
  },
  {
    id: "completedAt",
    key: "completed_at",
    Header: i18n.t("tasks:fields.completed_at"),
    Cell: (row) => <WorkOrderQuickCompletedAt workOrder={row} />,
    toText: (row) => getFormattedDate(parseDate(row.completed_at), { includeTime: true }),
    orderBy: (dir) => ({ completed_at: dir }),
    defaultSortDirection: "desc",
    calendarView: {
      event: (row) => ({
        id: row.id,
        title: row.name,
        start: row.completed_at ? new Date(row.completed_at) : undefined,
      }),
    },
  },
  {
    id: "updatedAt",
    key: "last_update",
    Header: i18n.t("tasks:fields.updated_at"),
    Cell: (row) => getFormattedDate(parseDate(row.last_update), { includeTime: true }),
    toText: (row) => getFormattedDate(parseDate(row.last_update), { includeTime: true }),
    orderBy: (dir) => ({ last_update: dir }),
    defaultSortDirection: "desc",
    calendarView: {
      event: (row) => ({
        id: row.id,
        title: row.name,
        start: new Date(row.last_update),
      }),
    },
  },
  {
    id: "priority",
    key: "priority",
    Header: i18n.t("tasks:fields.priority"),
    Cell: (row) => <WorkOrderPriorityBadge priority={row.priority} hasLabel={false} />,
    toText: (row) => translateWorkOrderPriority(row.priority),
    orderBy: (dir) => ({ priority: { transform: priorityOrderValue, dir } }),
    groupBy: {
      id: (row) => row.priority ?? "no_priority",
      label: (_, row) => translateWorkOrderPriority(row?.priority ?? null),
      labelText: (_, row) => translateWorkOrderPriority(row?.priority ?? null),
      orderValue: (_, row) => `${priorityOrderValue(row?.priority ?? null) + 1}`,
    },
    defaultWidth: 80,
  },
  {
    id: "name",
    key: "name",
    Header: i18n.t("tasks:fields.task_name"),
    defaultWidth: 300,
    Cell: (row) => <span className="font-medium">{row.name}</span>,
    toText: (row) => row.name,
    orderBy: (dir) => ({ name: dir }),
  },

  {
    id: "workOrderNumber",
    key: "work_order_number",
    Header: i18n.t("tasks:fields.work_order_number"),
    defaultWidth: 80,
    Cell: (row) => <WorkOrderNumber workOrderNumber={row.work_order_number} />,
    toText: (row) => `#${row.work_order_number}`,
    orderBy: (dir) => ({ work_order_number: dir }),
  },
  {
    id: "dueDate",
    key: "due_date",
    Header: i18n.t("tasks:fields.due_date"),
    defaultWidth: 200,
    toText: (row) => getFormattedDate(parseDate(row.due_date), { includeTime: false }),
    Cell: (row) => (
      <div onClick={(e) => e.stopPropagation()} className="-my-1">
        <WorkOrderDueDatePopover
          workOrder={row}
          markAsOverdue={
            !(
              row.status === IWorkOrderStatusEnum.Done ||
              row.status === IWorkOrderStatusEnum.Canceled
            )
          }
          popoverContentProps={{
            side: "left",
            align: "start",
            alignOffset: -12,
            sideOffset: 4,
          }}
          showRecurrenceInfo
        />
      </div>
    ),
    orderBy: (dir) => ({ due_date: dir }),
    groupBy: getDueDateDatesGroupBy(),
    calendarView: {
      default: true,
      event: (row) => {
        let startDate: Date | undefined

        if (row.due_date && row.due_time) {
          startDate = toZoned(
            IDate.toCalendarDateTime(
              IDate.parseDate(row.due_date),
              IDate.parseTime(row.due_time)
            )
          ).toDate()
        } else if (row.due_date) {
          startDate = toZoned(
            IDate.toCalendarDateTime(IDate.parseDate(row.due_date))
          ).toDate()
        }

        return {
          id: row.id,
          title: row.name,
          start: startDate,
        }
      },
    },
    kanbanView: {
      disableDragging: true,
    },
  },
  {
    id: "status",
    key: "status",
    Header: i18n.t("tasks:fields.status"),
    defaultWidth: 100,
    Cell: (row) => <WorkOrderStatusTagWithDropdown status={row.status} workOrder={row} />,
    toText: (row) => translateWorkOrderStatus(row.status),
    orderBy: (dir) => ({ status: { transform: statusOrderValue, dir } }),
    groupBy: {
      id: (row) => row.status ?? null,
      label: (_id, row) =>
        row?.status ? (
          <WorkOrderStatusTag status={row.status} className="ml-2" />
        ) : (
          i18n.t("tasks:filters.status.none")
        ),
      labelText: (_id, row) => translateWorkOrderStatus(row?.status ?? null),
      orderValue: (_, row) => {
        return row?.status ? String(statusOrderValue(row.status)) : null
      },
    },
    groupBySummary: async (client, where) => {
      const agg = await client
        .query(
          gql`
            query GroupByAggregateStatus($where: work_order_bool_exp) {
              work_order_status(where: { value: { _neq: "inbox" } }) {
                work_orders_aggregate(where: $where) {
                  aggregate {
                    count
                  }
                }
                value
              }
            }
          `,
          { where }
        )
        .toPromise()
      if (!agg.data) return []
      return agg.data.work_order_status.map((s: any) => ({
        id: s.value,
        label: translateWorkOrderStatus(s.value),
        size: s.work_orders_aggregate.aggregate.count,
        where: { status: { _eq: s.value } },
      }))
    },
    kanbanView: {
      default: true,
    },
  },
  {
    id: "categories",
    key: "categories",
    Header: i18n.t("tasks:fields.category", { count: 2 }),
    defaultWidth: 160,
    Cell: (row) => <WorkOrderCategoryTags workOrder={row} />,
    toText: (row) => row.categories.map((c) => c.category.label).join(", "),
    groupBy: {
      id: (row) => row.categories.map((c) => c.work_order_category_id),
      label: (id, row) =>
        row?.categories?.find((r) => r.work_order_category_id === id)?.category?.label ??
        i18n.t("tasks:filters.category.none"),
      labelText: (id, row) =>
        row?.categories?.find((r) => r.work_order_category_id === id)?.category?.label ??
        i18n.t("tasks:filters.category.none"),
    },
  },
  {
    id: "assets",
    key: "assets",
    Header: i18n.t("common:asset", { count: 2 }),
    defaultWidth: 300,
    toText: (row) => row.assets.map((a) => a.asset.name).join(", "),
    Cell: (row) => {
      const assets = row.assets
        .map((a) => a.asset)
        .filter(Boolean)
        .sort((a, b) => naturalCompare(a.name, b.name))

      if (!assets.length) {
        return null
        // return (
        //   <div className="inline-flex min-w-0" onClick={(e) => e.stopPropagation()}>
        //     <WorkOrderQuickAssetSelect workOrder={row} compact />
        //   </div>
        // )
      }

      return (
        <>
          <LinkWithBackgroundLocation
            key={assets[0].id}
            to={`/object/${assets[0].id}`}
            onClick={(e) => e.stopPropagation()}
            className="flex cursor-pointer flex-wrap items-center break-all rounded bg-white px-2 py-0.5 ring-gray-300
          hover:text-gray-900 hover:ring-1">
            <AssetWithParents asset={assets[0]} showAvatar />
          </LinkWithBackgroundLocation>
          {assets.length > 1 && (
            <span className="ml-2 mt-1 text-xs text-gray-500">
              +{row.assets.length - 1} {i18n.t("common:more")}
            </span>
          )}
        </>
      )
    },
    groupBy: {
      id: (row) => row.assets.map((a) => a.asset?.id ?? ""),
      label: (id, row) =>
        row?.assets.find((r) => r.asset?.id === id)?.asset?.name ??
        i18n.t("tasks:filters.asset?.none"),
      labelText: (id, row) =>
        row?.assets.find((r) => r.asset?.id === id)?.asset?.name ??
        i18n.t("tasks:filters.asset?.none"),
    },
  },
  {
    id: "assetGroups",
    Header: i18n.t("assets:fields.group", { count: 2 }),
    disableSortBy: true,
    toText: (row) => row.assets.map((a) => a.asset.group?.name || "").join(", "),
    Cell: (row) => {
      // We want to show all existing groups, in ascending order without duplicates
      const groups = row.assets
        .map(({ asset }) => asset.group)
        .filter(Boolean) as IAssetGroupFragment[]
      // filter unique. Not the most efficient approach but the array is anyway very small
      const label = groups
        .filter(({ id }, i) => groups.findIndex((g) => g.id === id) === i)
        .sort((a, b) => naturalCompare(a.name, b.name))
        .map((g) => g.name)
        .join(", ")
      return label
    },
    groupBy: {
      id: (row) => row.assets.map((a) => a.asset?.group?.id).filter(Boolean) as string[],
      label: (id, row) =>
        id
          ? row?.assets.find((r) => r.asset?.group?.id === id)?.asset?.group?.name ?? ""
          : i18n.t("tasks:filters.asset_group.none"),
      labelText: (id, row) =>
        id
          ? row?.assets.find((r) => r.asset?.group?.id === id)?.asset?.group?.name ?? ""
          : i18n.t("tasks:filters.asset_group.none"),
    },
  },
  {
    id: "assignees",
    key: "assignees",
    Header: i18n.t("tasks:fields.assignee", { count: 2 }),
    defaultWidth: 240,
    Cell: (row) => <WorkOrderQuickAssigneeTags workOrder={row} />,
    toText: (row) =>
      row.assignees.map((a) => `${a.user.first_name} ${a.user.last_name}`).join(", "),
  },
  {
    id: "teams",
    key: "assigned_teams",
    Header: i18n.t("common:team", { count: 2 }),
    defaultWidth: 240,
    toText: (row) => row.assigned_teams.map((t) => t.team?.name).join(", "),
    Cell: (row) => (
      <WorkOrderAssignedEntities
        inline
        assigned_teams={row.assigned_teams}
        assignees={[]}
      />
      // <div
      //   className="inline-flex min-w-0"
      //   onClick={(e) => {
      //     row.assigned_teams.length && e.stopPropagation()
      //   }}>
      //   <WorkOrderQuickTeamSelect workOrder={row} compact isListView />
      // </div>
    ),
    groupBy: {
      id: (row) => row.assigned_teams.map((t) => `team_${t.team?.id}`),
      label: (id, row) => {
        if (id?.startsWith("team_")) {
          const teamId = id.substring(5)
          const team = row?.assigned_teams.find((r) => r.team?.id === teamId)?.team
          return team ? <TeamTag team={team} /> : null
        }

        return i18n.t("tasks:filters.team.none")
      },
      labelText: (id, row) => {
        if (id?.startsWith("team_")) {
          const teamId = id.substring(5)
          const team = row?.assigned_teams.find((r) => r.team?.id === teamId)?.team
          return team?.name ?? ""
        }

        return i18n.t("tasks:filters.team.none")
      },
      orderValue: (id, row) => {
        if (id?.startsWith("team_")) {
          const teamId = id.substring(5)
          const team = row?.assigned_teams.find((r) => r.team?.id === teamId)?.team
          return team?.name ?? null
        }

        return null
      },
    },
  },
  {
    id: "completedBy",
    key: "completed_by",
    Header: i18n.t("tasks:fields.completed_by"),
    toText: (row) =>
      row.completed_by
        ? `${row.completed_by.first_name} ${row.completed_by.last_name}`
        : "",
    Cell: (row) =>
      row.completed_by ? (
        <WorkOrderAssignedEntities
          inline
          assigned_teams={[]}
          assignees={[{ user: row.completed_by }]}
        />
      ) : null,
    groupBy: {
      id: (row) => row.completed_by?.id ?? null,
      label: (_id, row) => {
        const user = row?.completed_by
        if (user) return <UserTag user={user} />
        return i18n.t("tasks:filters.completed.none")
      },
      labelText: (_id, row) => {
        const user = row?.completed_by
        if (user) return `${user.first_name} ${user.last_name}`
        return i18n.t("tasks:filters.completed.none")
      },
    },
  },
  {
    id: "createdBy",
    key: "created_by",
    Header: i18n.t("tasks:fields.created_by"),
    toText: (row) => `${row.created_by.first_name} ${row.created_by.last_name}`,
    Cell: (row) => <UserTag user={row.created_by} />,
    groupBy: {
      id: (row) => row.created_by_id,
      label: (_id, row) => row && <UserTag user={row.created_by} />,
      labelText: (_id, row) => {
        const user = row?.created_by
        return user ? `${user.first_name} ${user.last_name}` : ""
      },
    },
  },
  {
    id: "description",
    key: "description",
    searchQuery: (input: string) => ({
      description: { _ilike: `%${input.toLowerCase()}%` },
    }),
    Header: i18n.t("tasks:fields.description"),
    defaultWidth: 300,
    toText: (row) => row.description ?? "",
    Cell: (row) =>
      row.description && (
        <div className="line-clamp-2">
          <div
            className="whitespace-normal"
            dangerouslySetInnerHTML={{ __html: row.description }}
          />
        </div>
      ),
  },
  {
    id: "total_hours_work_reports",
    key: "total_hours_work_reports",
    Header: i18n.t("tasks:fields.total_hours_work_reports"),
    defaultWidth: 300,
    toText: (row) => `${row.total_hours_work_reports ?? 0}`,
    Cell: (row) => <span>{row.total_hours_work_reports ?? 0}</span>,
    summationValue: (row) => row.total_hours_work_reports ?? 0,
    aggregate: async (client, where) => {
      const query = gql`
        query AggregateTotalHoursWorkReports($where: work_order_bool_exp) {
          work_order_aggregate(where: $where) {
            aggregate {
              sum {
                total_hours_work_reports
              }
            }
          }
        }
      `
      const agg = await client.query(query, { where }).toPromise()
      const total =
        agg.data?.work_order_aggregate?.aggregate?.sum?.total_hours_work_reports ?? 0
      return formatFractionalHours(total)
    },
  },
  {
    id: "procedure_progress",
    Header: i18n.t("tasks:fields.procedure_progress"),
    toText: (row) => {
      const progressInfo = (row.block_groups?.find(
        (g) => g.group_type === IBlockGroupTypeEnum.Procedure
      )?.progress_info ?? null) as ProgressInfo | null

      return progressInfo
        ? `${progressInfo.total_with_response} / ${progressInfo.total}`
        : ""
    },
    Cell: (row) => {
      const progressInfo = (row.block_groups?.find(
        (g) => g.group_type === IBlockGroupTypeEnum.Procedure
      )?.progress_info ?? null) as ProgressInfo | null

      return (
        progressInfo && (
          <div className="col-span-2 flex items-center">
            <Icons.Procedure className="mr-2 text-base" />
            {progressInfo.total_with_response} / {progressInfo.total}
          </div>
        )
      )
    },
    defaultWidth: 80,
  },
  {
    id: "task",
    Header: i18n.t("common:task", { count: 1 }),
    defaultWidth: 320,
    disableSortBy: true,
    summationValue: () => 1,
    toText: (row) => row.name,
    searchQuery: (input: string) => ({ name: { _ilike: `%${input}%` } }),
    Cell: (row, options) => {
      const progressInfo = (row.block_groups?.find(
        (g) => g.group_type === IBlockGroupTypeEnum.Procedure
      )?.progress_info ?? null) as ProgressInfo | null

      return (
        <TaskHoverCard task={row}>
          <div className="flex min-w-0 items-center gap-x-2">
            {!options.logbook && (
              <WorkOrderPriorityBadgeWithDropdown
                workOrder={row}
                priority={row.priority}
                hasLabel={false}
              />
            )}
            <WorkOrderStatusTagWithDropdown short status={row.status} workOrder={row} />
            <WorkOrderNumber workOrderNumber={row.work_order_number} />
            <span className="min-w-0 truncate font-medium">{row.name}</span>
            {progressInfo && progressInfo.total > 0 && !options.logbook && (
              <Tooltip
                content={i18n.t("tasks:checklist.status", {
                  count: progressInfo.total_with_response,
                  total: progressInfo.total,
                })}>
                <div className="inline-flex items-center rounded border py-1 pl-1 pr-1.5 text-xs">
                  <CircularProgress
                    size={16}
                    progress={progressInfo.percent_with_response / 100}
                  />
                  <span className="ml-1 text-gray-500">
                    {progressInfo.total_with_response}/{progressInfo.total}
                  </span>
                </div>
              </Tooltip>
            )}
            {progressInfo && progressInfo.inspection.flag && (
              <Tooltip content={i18n.t("tasks:messages.inspection_flag_warning")}>
                <Warning size={20} weight="duotone" className="text-yellow-400 " />
              </Tooltip>
            )}
            {progressInfo &&
              (progressInfo.inspection.fail || progressInfo.tolerance_check_failed) && (
                <Tooltip content={i18n.t("tasks:messages.inspection_flag_fail")}>
                  <Warning size={20} weight="duotone" className="text-red-400 " />
                </Tooltip>
              )}

            <WorkOrderSubtaskTag subtasks={row.subtasks} />
            {row.parent_task && (
              <span className="-ml-1 inline-flex min-w-0 shrink-[9999] items-center truncate text-gray-600">
                <Icons.RightNext className="mr-1 inline-block shrink-0" /> #
                <span className="min-w-0 truncate">
                  {row.parent_task?.work_order_number} {row.parent_task.name}
                </span>
              </span>
            )}
          </div>
        </TaskHoverCard>
      )
    },
    aggregate: async (client, where) => {
      const query = gql`
        query AggregateTasks($where: work_order_bool_exp) {
          work_order_aggregate(where: $where) {
            aggregate {
              count
            }
          }
        }
      `
      const agg = await client.query(query, { where }).toPromise()
      const count = agg.data?.work_order_aggregate?.aggregate?.count ?? 0
      return `${count} ${i18n.t("common:task", { count })}`
    },
  },
  {
    id: "parent_task",
    Cell: (row) =>
      row.parent_task && (
        <WorkOrderTag
          singleLine
          workOrder={{ ...row.parent_task, due_date: null, due_time: null }}
        />
      ),
    defaultWidth: 320,
    Header: i18n.t("tasks:fields.parent_task"),
    toText: (row) => row.parent_task?.name ?? "",
    groupBy: {
      id: (row) => row.parent_work_order_id,
      label: (_, row) => (
        <>{row?.parent_task?.name ?? i18n.t("tasks:messages.no_parent_task")}</>
      ),
    },
  },
]

type WODataViewProps = DataViewProps<
  IWorkOrderDataViewFragment,
  WorkOrderViewIds,
  WorkOrderViewOptions
>
type WorkOrderDataViewProps = Omit<
  WODataViewProps,
  | "columnDefinitions"
  | "countFromFilterState"
  | "filter"
  | "initialFilterState"
  | "defaultConfig"
  | "onSelect"
  | "whereFromFilterState"
  | "dataId"
  | "dataSearchValue"
  | "dataType"
> &
  Partial<Pick<WODataViewProps, "fixedFilters" | "dataType">> & {
    defaultConfig?: Partial<WODataViewProps["defaultConfig"]>
    initialValuesForCreate?: InitialFormValuesInput
  }

export const workOrderDataViewDefaultConfig = {
  columnOrder: ["task", "dueDate", "categories", "assets", "assignees", "teams"],
  orderBy: [{ id: "workOrderNumber", dir: "desc" }],
  groupBy: "status",
  historyFilter: {
    type: "select",
    id: "completedAt",
    selectedValues: [{ type: "relative", days: 7 }],
  },
} as DataViewConfiguration<WorkOrderViewIds>

export const useWorkOrderDataViewSchema = (promptForDate?: () => Promise<Date>) => {
  const user = useUser()

  return useMemo(
    () =>
      createSchema<IWorkOrderDataViewFragment>({
        status: FilterConfiguration.status("work_order"),
        priority: FilterConfiguration.priority(),
        category: FilterConfiguration.category(),
        assets: FilterConfiguration.assets(),
        assetGroups: FilterConfiguration.assetGroups(),
        assignees: FilterConfiguration.assignees(user.id),
        teams: FilterConfiguration.teams(),
        collaborator: FilterConfiguration.collaborator(user.id),
        dueDate: FilterConfiguration.dueDate(promptForDate),
        completedAt: FilterConfiguration.completedAt(promptForDate),
        createdBy: FilterConfiguration.createdBy(user.id),
        createdAt: FilterConfiguration.createdAt(promptForDate),
        lastUpdated: FilterConfiguration.lastUpdated(promptForDate),
        recurrence: FilterConfiguration.recurrence(),
      }),
    [user.id]
  )
}

const useFilterSchema = () => {
  const datePicker = useDataViewFilterDatePicker()
  const schema = useWorkOrderDataViewSchema(datePicker.promptForDate)

  return {
    schema,
    components: <DataViewFilterDatePicker dataViewFilterDaterPicker={datePicker} />,
  }
}

const WorkOrderDataViewCardItem = (row: IWorkOrderDataViewFragment) => (
  <div className="flex cursor-pointer flex-col gap-1 rounded bg-white p-2 shadow-sm transition-shadow hover:shadow">
    <div className="flex items-center gap-1">
      {statusIcon(row.status)}

      <span className="text-xs text-gray-500">#{row.work_order_number}</span>

      <div className="ml-auto">
        <AvatarGroup
          size={15}
          hideFirstName
          hideExtraCount
          items={row.assignees.map(({ user }) => ({
            id: user.id,
            avatar: user.avatar,
            label: `${user.first_name} ${user.last_name}`,
            placeholder: <UserAvatarPlaceholder user={user} />,
          }))}
        />
      </div>
    </div>
    <div className="flex min-w-0 items-center gap-2 truncate text-sm">
      <span className="min-w-0 truncate font-medium">{row.name}</span>
      {row.recurrence_info && <span>{<Icons.Repeat />}</span>}
    </div>
  </div>
)

const WorkOrderDataViewListItem = (row: IWorkOrderDataViewFragment) => {
  const bp = useBreakpoint()

  const assets = row.assets.sort((a, b) => naturalCompare(a.asset?.name, b.asset?.name))
  const progressInfo = (row.block_groups?.find(
    (g) => g.group_type === IBlockGroupTypeEnum.Procedure
  )?.progress_info ?? null) as ProgressInfo | null

  if (bp.mobile) {
    return (
      <VStack space={12} className="px-1 py-3">
        <VStack className="ml-[-1px]">
          {row.parent_task && (
            <div>
              <span className="inline-flex min-w-0 shrink-[9999] items-center truncate text-xs text-gray-600">
                <Icons.RightNext className="mr-1 inline-block shrink-0" /> #
                <span className="min-w-0 truncate">
                  {row.parent_task?.work_order_number} {row.parent_task.name}
                </span>
              </span>
            </div>
          )}
          <HStack space={4} className="truncate">
            <HStack className="truncate" space={2}>
              <WorkOrderPriorityBadge priority={row.priority} hasLabel={false} />
              <WorkOrderStatusTag status={row.status} short />
            </HStack>
            <span className="font-normal text-gray-500">#{row.work_order_number}</span>
            <span className="text-ellipsis font-medium text-gray-900">{row.name}</span>
          </HStack>
        </VStack>

        <VStack space={4}>
          <div className="h-5" style={{ marginLeft: 1 }}>
            <WorkOrderDueDate
              workOrder={row}
              iconSize={20}
              showRecurrenceInfo
              markAsOverdue={
                !(
                  row.status === IWorkOrderStatusEnum.Done ||
                  row.status === IWorkOrderStatusEnum.Canceled
                )
              }
              mode="static"
            />
          </div>

          {!!assets.length && (
            <div className="h-5">
              <MultiSelectSummary
                limit={3}
                mode="summary"
                firstItemLabel={
                  <React.Fragment>
                    <span className="text-gray-900">{assets[0].asset?.name} </span>
                    <span className="whitespace-nowrap text-gray-700">
                      {assets[0].asset?.place?.name}
                    </span>
                  </React.Fragment>
                }
                items={assets}
                itemsToIcons={(_) => <Shapes size={20} />}
                isStaticRow
              />
            </div>
          )}
          {!!row.assignees.length && (
            <div className="h-5">
              <MultiSelectSummary
                items={row.assignees}
                limit={3}
                itemsToIcons={(user) => (
                  <UserAvatar
                    user={user.user}
                    key={user?.user?.id}
                    className="border-2 !border-white"
                    size={20}
                  />
                )}
                mode="summary"
                firstItemLabel={`${row.assignees[0]?.user?.first_name ?? ""} ${
                  row.assignees[0]?.user?.last_name ?? ""
                }`}
                isStaticRow
              />
            </div>
          )}
          {!!row.assigned_teams.length && (
            <div className="h-5">
              <MultiSelectSummary
                items={row.assigned_teams}
                limit={3}
                itemsToIcons={({ team }) => {
                  const icon = team?.icon as IconData
                  return (
                    <TeamIcon
                      name={icon?.name}
                      color={icon?.color}
                      key={team?.id}
                      className="!h-5 !w-5"
                    />
                  )
                }}
                firstItemLabel={`${row.assigned_teams[0]?.team?.name ?? ""}`}
                mode="summary"
                isStaticRow
              />
            </div>
          )}
          {!!row.categories.length && (
            <div className="h-5 pl-1">
              <MultiSelectSummary
                items={row.categories}
                limit={3}
                itemsToIcons={(cat) => (
                  <CategoryColor
                    color={cat.category?.color}
                    key={cat.category?.label}
                    className="!h-4 !w-4 !border-2 border-transparent"
                  />
                )}
                mode="summary"
                className="ml-0.5"
                firstItemLabel={row.categories[0]?.category?.label}
                isStaticRow
              />
            </div>
          )}
        </VStack>

        {((progressInfo && progressInfo.total > 0) || row.subtasks.length > 0) && (
          <div className="grid grid-cols-2 pl-1">
            <HStack space={4} align="center">
              <span className="mr-2">{i18n.t("tasks:fields.checklist")}</span>
              {progressInfo && progressInfo.total > 0 ? (
                <Tooltip
                  content={i18n.t("tasks:checklist.status", {
                    count: progressInfo.total_with_response,
                    total: progressInfo.total,
                  })}>
                  <div className="inline-flex items-center rounded border py-1 pl-1 pr-1.5 text-xs">
                    <CircularProgress
                      size={18}
                      progress={progressInfo.percent_with_response / 100}
                    />
                    <span className="ml-1 text-gray-500">
                      {progressInfo.total_with_response}/{progressInfo.total}
                    </span>
                  </div>
                </Tooltip>
              ) : (
                <span className="text-gray-500">-</span>
              )}
            </HStack>

            <HStack space={4} align="center">
              <span className="mr-2">{i18n.t("common:subtask", { count: 2 })}</span>
              {row.subtasks.length > 0 ? (
                <WorkOrderSubtaskTag subtasks={row.subtasks} />
              ) : (
                <span className="text-gray-500">-</span>
              )}
            </HStack>
          </div>
        )}
      </VStack>
    )
  }

  return (
    <VStack space={12} className="py-3">
      <VStack className="ml-[-1px]">
        {row.parent_task && (
          <div>
            <span className="inline-flex min-w-0 shrink-[9999] items-center truncate text-xs text-gray-600">
              <Icons.RightNext className="mr-1 inline-block shrink-0" />
              <span className="min-w-0 truncate">
                #{row.parent_task?.work_order_number} {row.parent_task.name}
              </span>
            </span>
          </div>
        )}
        <HStack space={8} className="truncate">
          <HStack className="truncate" space={2}>
            <WorkOrderPriorityBadge priority={row.priority} hasLabel={false} />
            <WorkOrderStatusTag status={row.status} short />
          </HStack>
          <span className="font-normal text-gray-500">#{row.work_order_number}</span>
          <span className="text-ellipsis font-medium text-gray-900">{row.name}</span>
        </HStack>
      </VStack>

      <VStack space={4}>
        <div style={{ marginLeft: 1 }}>
          <WorkOrderDueDate
            workOrder={row}
            iconSize={20}
            markAsOverdue={
              !(
                row.status === IWorkOrderStatusEnum.Done ||
                row.status === IWorkOrderStatusEnum.Canceled
              )
            }
            mode="static"
            showRecurrenceInfo
          />
        </div>

        <HStack space={16}>
          {!!assets.length && (
            <MultiSelectSummary
              limit={3}
              mode="summary"
              firstItemLabel={
                <React.Fragment>
                  <span className="text-gray-900">{assets[0].asset?.name} </span>
                  <span className="whitespace-nowrap text-gray-700">
                    {assets[0].asset?.place?.name}
                  </span>
                </React.Fragment>
              }
              items={assets}
              itemsToIcons={(_) => <Shapes size={20} />}
              isStaticRow
            />
          )}

          {!!row.assignees.length && (
            <MultiSelectSummary
              items={row.assignees}
              limit={3}
              itemsToIcons={(user) => (
                <UserAvatar
                  user={user.user}
                  key={user?.user?.id}
                  className="!border-white"
                  size={20}
                />
              )}
              mode="summary"
              firstItemLabel={`${row.assignees[0]?.user?.first_name ?? ""} ${
                row.assignees[0]?.user?.last_name ?? ""
              }`}
              isStaticRow
            />
          )}

          {!!row.assigned_teams.length && (
            <MultiSelectSummary
              items={row.assigned_teams}
              limit={3}
              itemsToIcons={({ team }) => {
                const icon = team?.icon as IconData
                return (
                  <TeamIcon
                    name={icon?.name}
                    color={icon?.color}
                    key={team?.id}
                    className="!h-5 !w-5"
                  />
                )
              }}
              firstItemLabel={`${row.assigned_teams[0].team?.name ?? ""}`}
              mode="summary"
              isStaticRow
            />
          )}

          {!!row.categories.length && (
            <MultiSelectSummary
              items={row.categories}
              limit={3}
              itemsToIcons={(cat) => (
                <CategoryColor
                  color={cat.category.color}
                  key={cat.category.label}
                  className="!h-4 !w-4 !border-2 border-transparent"
                />
              )}
              mode="summary"
              firstItemLabel={row.categories[0]?.category.label}
              isStaticRow
            />
          )}
        </HStack>
      </VStack>

      {((progressInfo && progressInfo.total > 0) || row.subtasks.length > 0) && (
        <div className="flex flex-row gap-x-8">
          <HStack space={4} align="center">
            <span className="mr-2">{i18n.t("tasks:fields.checklist")}</span>
            {progressInfo && progressInfo.total > 0 ? (
              <Tooltip
                content={i18n.t("tasks:checklist.status", {
                  count: progressInfo.total_with_response,
                  total: progressInfo.total,
                })}>
                <div className="inline-flex items-center rounded border py-1 pl-1 pr-1.5 text-xs">
                  <CircularProgress
                    size={16}
                    progress={progressInfo.percent_with_response / 100}
                  />
                  <span className="ml-1 text-gray-500">
                    {progressInfo.total_with_response}/{progressInfo.total}
                  </span>
                </div>
              </Tooltip>
            ) : (
              <span className="text-gray-500">-</span>
            )}
          </HStack>

          <HStack space={4} align="center">
            <span className="mr-2">{i18n.t("common:subtask", { count: 2 })}</span>
            {row.subtasks.length > 0 ? (
              <WorkOrderSubtaskTag subtasks={row.subtasks} />
            ) : (
              <span className="text-gray-500">-</span>
            )}
          </HStack>
        </div>
      )}
    </VStack>
  )
}

const historyFilterItems = [
  {
    searchValue: i18n.t("common:none"),
    label: i18n.t("common:none"),
    value: { type: "relative", days: -2 },
  },
  {
    searchValue: i18n.t("calendar:relative.today"),
    label: i18n.t("calendar:relative.today"),
    value: { type: "relative", days: 0 },
  },
  {
    searchValue: i18n.t("calendar:relative.since_yesterday"),
    label: i18n.t("calendar:relative.since_yesterday"),
    value: { type: "relative", days: 1 },
  },
  {
    searchValue: i18n.t("calendar:relative.last_7_days"),
    label: i18n.t("calendar:relative.last_7_days"),
    value: { type: "relative", days: 7 },
  },
  {
    searchValue: i18n.t("calendar:relative.last_30_days"),
    label: i18n.t("calendar:relative.last_30_days"),
    value: { type: "relative", days: 30 },
  },
  {
    searchValue: i18n.t("calendar:relative.since_6_months"),
    label: i18n.t("calendar:relative.since_6_months"),
    value: { type: "relative", months: 6 },
  },
  {
    searchValue: i18n.t("calendar:relative.since_1_year"),
    label: i18n.t("calendar:relative.since_1_year"),
    value: { type: "relative", years: 1 },
  },
  {
    searchValue: i18n.t("common:all"),
    label: i18n.t("common:all"),
    value: { type: "relative", years: 100 },
  },
]

export const WorkOrderDataView = React.memo(
  ({ defaultConfig, ...props }: WorkOrderDataViewProps) => {
    const navigate = useNavigate()
    const location = useLocation()
    const filter = useFilterSchema()

    const createScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderCreate)
    const editScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)

    const [selectedWorkOrderId, setSelectedWorkOrderId] = useState<uuid | null>(null)
    const onSelect = useCallbackRef((data: IWorkOrderDataViewFragment) => {
      navigate(`/task/${data.id}`, {
        state: {
          backgroundLocation:
            (location.state as LocationState)?.backgroundLocation || location,
        },
      })
    })
    const dataId = useCallback((d: IWorkOrderDataViewFragment) => d.id, [])
    const dataSearchValue = useCallback(
      (d: IWorkOrderDataViewFragment) =>
        `#${d.work_order_number} ${d.name} ${d.assets.map((a) => a.asset?.name).join(" ")}`,
      []
    )

    const [, insertWorkOrderCategory] = useInsertWorkOrderXWorkOrderCategoryMutation()
    const [, deleteWorkOrderCategory] = useDeleteWorkOrderXWorkOrderCategoryMutation()
    const [, updateWorkOrder] = useUpdateWorkOrderPartiallyMutation()

    const dataViewDefaultConfig: DataViewConfiguration<WorkOrderViewIds> =
      useDeepMemo(() => {
        return {
          ...workOrderDataViewDefaultConfig,
          ...defaultConfig,
        }
      }, [defaultConfig])

    const createWorkOrder = (initialValues?: InitialFormValuesInput) => {
      openModal("select_template", { initialTaskValues: initialValues ?? {} })
    }

    const noData = useMemo(
      () =>
        props.noData ?? {
          icon: Rows,
          title: i18n.t("common:no_token_created_yet", {
            token: i18n.t("common:task", { count: 2 }),
          }),
          message: (
            <p style={{ marginTop: 4 }}>{i18n.t("tasks:messages.empty_state.content")}</p>
          ),
          actions: createScope.hasScope ? (
            <Button
              icon={Plus}
              onClick={() => createWorkOrder(props.initialValuesForCreate)}
              type="primary"
              size="small"
              className="mx-auto mt-3">
              {i18n.t("common:new_token", {
                context: "female",
                token: i18n.t("common:task", { count: 1 }),
              })}
            </Button>
          ) : null,
        },
      [props.noData]
    )

    if (!filter) return null

    const { schema, components } = filter

    return (
      <Dialog.Root
        modal={false}
        open={!!selectedWorkOrderId}
        onOpenChange={(open) => !open && setSelectedWorkOrderId(null)}>
        <div className="flex min-h-0 min-w-0 flex-1">
          {
            <div className="flex min-h-0 min-w-0 flex-1 flex-col">
              <DataView<IWorkOrderDataViewFragment, WorkOrderViewIds, {}>
                dataId={dataId}
                noData={noData}
                listItem={WorkOrderDataViewListItem}
                eventItem={(row, event) => {
                  if (row) return <TaskEventItem task={row} />

                  return (
                    <ScheduledEventItemById
                      id={event.event.groupId}
                      triggerId={event.event.extendedProps?.trigger?.id}
                      date={event.event.start}
                    />
                  )
                }}
                cardItem={WorkOrderDataViewCardItem}
                dataType={IViewDataTypeEnum.Workorder}
                defaultConfig={dataViewDefaultConfig}
                dataSearchValue={dataSearchValue}
                customDataConfigToWhere={(config) => {
                  if (config?.dataType !== IViewDataTypeEnum.Workorder) return null

                  if (config.hideSubtasks) {
                    return { parent_work_order_id: { _is_null: true } }
                  }

                  return null
                }}
                customDataConfigMenuOptions={(config, updateConfig) => (
                  <OptionsMenuItem>
                    <span className="flex-1 truncate">
                      {i18n.t("data-view:options.show_subtasks")}
                    </span>
                    <Toggle
                      checked={!config?.hideSubtasks}
                      onChange={(e) => {
                        const checked = e.target.checked
                        updateConfig({
                          dataType: IViewDataTypeEnum.Workorder,
                          hideSubtasks: !checked,
                        })
                      }}
                    />
                  </OptionsMenuItem>
                )}
                {...{
                  historyFilterLabel: i18n.t("tasks:completed_tasks"),
                  historyFilterItems,
                }}
                columnDefinitions={columns}
                filterSchema={schema}
                onSelect={onSelect}
                onKeyboardSelect={onSelect}
                onDateClick={
                  props.onDateClick || ((key, date) => createWorkOrder({ [key]: date }))
                }
                searchPlaceholder={i18n.t("common:search_token", {
                  token: i18n.t("common:task", { count: 1 }),
                })}
                onDateDragDrop={async (key, row, newDate) => {
                  await updateWorkOrder(
                    { id: row.id, changes: { [key]: formatISODate(newDate) } },
                    editScope.context()
                  )
                }}
                onDragDrop={async (key, row, sourceId, targetId) => {
                  if (key === "categories") {
                    await Promise.all([
                      deleteWorkOrderCategory(
                        {
                          work_order_id: row.id,
                          work_order_category_id: sourceId,
                        },
                        editScope.context()
                      ),
                      insertWorkOrderCategory(
                        {
                          work_order_id: row.id,
                          work_order_category_id: targetId,
                        },
                        editScope.context()
                      ),
                    ])
                  } else {
                    await updateWorkOrder(
                      { id: row.id, changes: { [key]: targetId } },
                      editScope.context()
                    )
                  }
                }}
                {...props}
                configId={props.configId ?? "work_order:data-view:v2"}
              />
              {components}
            </div>
          }
        </div>
      </Dialog.Root>
    )
  }
)
