import { HStack } from "@components/layout"
import { IAssetTagFragment } from "@graphql/documents/fragments.generated"
import { LinkWithBackgroundLocation } from "@utils/location"

import { AssetAvatar } from "./avatar"

export type AssetTagProps = {
  asset: IAssetTagFragment
  withLink?: boolean
}

export const AssetTag = ({ asset, withLink }: AssetTagProps) => {
  const tag = (
    <HStack align="center" space={8}>
      <AssetAvatar avatar={asset.avatar} />
      <span className="overflow-hidden text-ellipsis whitespace-nowrap text-sm">
        {asset.name}
        <span className="ml-1 text-gray-500">{asset.public_id}</span>
      </span>
    </HStack>
  )

  if (withLink) {
    return (
      <LinkWithBackgroundLocation
        to={`/object/${asset.id}`}
        className="-m-2 inline-flex rounded-lg p-2 hover:bg-gray-100">
        {tag}
      </LinkWithBackgroundLocation>
    )
  }
  return tag
}
