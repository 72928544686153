import { LoadingIndicator } from "@components/shared"
import { FormField } from "@components/shared/form/form-field"
import ScrollArea from "@components/shared/scroll-area"
import { Select } from "@components/shared/select"
import { TextInput } from "@components/shared/text-input"
import i18n from "@i18n"
import { useKeycloak } from "@react-keycloak/web"
import { LongLogo } from "@resources/logos"
import { Form, Formik } from "formik"
import { useState } from "react"
import { Trans } from "react-i18next"
import { Link } from "react-router-dom"
import { useLocaleOptions } from "src/i18n/languages"
import * as yup from "yup"

const registerSchema = yup.object().shape({
  orgName: yup.string().required(
    i18n.t("common:forms.is_required", {
      field: i18n.t("settings:profile.fields.company"),
    })
  ),
  firstName: yup.string().required(
    i18n.t("common:forms.is_required", {
      field: i18n.t("settings:profile.fields.first_name"),
    })
  ),
  lastName: yup.string().required(
    i18n.t("common:forms.is_required", {
      field: i18n.t("settings:profile.fields.last_name"),
    })
  ),
  email: yup.string().required(
    i18n.t("common:forms.is_required", {
      field: i18n.t("settings:profile.fields.email"),
    })
  ),
  password: yup
    .string()
    .required(
      i18n.t("common:forms.is_required", {
        field: i18n.t("settings:profile.fields.password"),
      })
    )
    .min(8, i18n.t("register:messages.password.min_length")),
})

const SignUpForm = () => {
  const { keycloak } = useKeycloak()
  const locales = useLocaleOptions()

  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)
  const [emailHint, setEmailHint] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  // Can't figure out how to auto login a user...
  // So we just make them login again.
  // A redirect without a message is confusiing, so let's add another button here
  if (success) {
    return (
      <div className="flex justify-center">
        <div>
          <div className="text-xl font-bold">
            {i18n.t("register:messages.account_create_success")}
          </div>

          <button
            type="submit"
            className="mx-auto mt-6 flex w-72 items-center rounded bg-blue-700 px-4 py-2 text-base font-medium text-white"
            onClick={() => {
              keycloak.login({ loginHint: emailHint, redirectUri: window.origin })
            }}>
            {i18n.t("register:messages.login_get_started")}
          </button>
        </div>
      </div>
    )
  }

  return (
    <Formik
      onSubmit={async (values) => {
        try {
          setIsLoading(true)

          const res = await fetch("/api/account/register-org", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(values),
          })
          const data = await res.json()
          if (res.ok) {
            setSuccess(true)
            setEmailHint(values.email)
          } else {
            const m = data.errors?.[0]?.message
            if (m?.includes("already exists")) {
              setError(i18n.t("register:messages.account_already_exists"))
            } else {
              setError(m)
            }
          }
        } finally {
          setIsLoading(false)
        }
      }}
      validationSchema={registerSchema}
      initialValues={{
        email: "",
        firstName: "",
        lastName: "",
        orgName: "",
        password: "",
        language: i18n.language ?? "de-DE",
      }}
      validateOnBlur>
      <Form className="w-full">
        <h2 className="mb-2 text-2xl font-bold tracking-tight">
          {i18n.t("common:create_token", { token: i18n.t("settings:account.title") })}
        </h2>

        <FormField
          name="language"
          label={i18n.t("settings:profile.fields.language")}
          className="mt-4 sm:mt-8">
          {({ field, helpers }) => (
            <Select
              items={locales}
              value={field.value}
              onValueChange={async (value) => {
                helpers.setValue(value)
              }}
            />
          )}
        </FormField>
        <FormField name="orgName" label={i18n.t("settings:profile.fields.company")}>
          <TextInput />
        </FormField>
        <FormField name="email" label={i18n.t("settings:profile.fields.email")}>
          <TextInput />
        </FormField>
        <FormField name="firstName" label={i18n.t("settings:profile.fields.first_name")}>
          <TextInput />
        </FormField>
        <FormField name="lastName" label={i18n.t("settings:profile.fields.last_name")}>
          <TextInput />
        </FormField>
        <FormField name="password" label={i18n.t("settings:profile.fields.password")}>
          <TextInput type="password" autoComplete="new-password" />
        </FormField>

        <div className="text-sm text-gray-700">
          <Trans i18n={i18n} i18nKey="register:messages.terms_and_conditions">
            Mit der Registrierung stimmen Sie unseren
            <a
              href="https://getelara.de/2021%20-%20Elara%20Digital%20GmbH%20-%20Allgemeine%20Gesch%C3%A4ftsbedingungen.pdf"
              target="_blank"
              className="text-blue-500"
              rel="noreferrer">
              AGB
            </a>
            zu.
          </Trans>
        </div>
        <div className="w-full">
          <button
            type="submit"
            className="mx-auto mt-6 flex w-72 items-center justify-center rounded bg-blue-700 px-4 py-2 text-base font-medium text-white">
            {isLoading ? (
              <>
                <LoadingIndicator size={20} className="mr-3" />
                {i18n.t("register:messages.account_is_creating")}
              </>
            ) : (
              <span>{i18n.t("register:actions.create_account")}</span>
            )}
          </button>
          {error && <div className="mt-2 text-sm text-red-500">{error}</div>}
        </div>
        <h3 className="mt-6 text-sm text-gray-500">
          {i18n.t("register:messages.already_have_account")}{" "}
          <Link to="/" className="mt-3 text-sm text-blue-500 hover:underline">
            {i18n.t("register:actions.login")}
          </Link>
        </h3>
      </Form>
    </Formik>
  )
}

const RegisterPage = () => (
  <ScrollArea vertical viewportAsChild>
    <div className="flex flex-1">
      <div className="box-border hidden max-w-md flex-1 flex-col bg-blue-700 p-8 text-white sm:flex">
        <div>
          <LongLogo className="text-white" width={244 * 0.75} height={44 * 0.75} />
        </div>

        <h2 className="mt-24 max-w-md text-2xl font-bold leading-loose tracking-tight text-white/95 xl:text-4xl">
          <Trans i18n={i18n} i18nKey="register:landing.title">
            Behalten Sie Ihre
            <br />
            Aufgaben und Objekte
            <br />
            im Blick!
          </Trans>
        </h2>
        <h3 className="mt-8 max-w-md leading-relaxed text-white/80">
          {i18n.t("register:landing.subtitle")}
        </h3>
        <div className="flex-1" />
        <div className="relative max-w-md rounded bg-blue-600/50 p-4">
          <div>
            <div className="text-sm text-white/90">
              {i18n.t("register:landing.testimonial.message")}
            </div>
            <div className="mb-1 mt-3 font-medium">Matthias Werther</div>
            <div className="text-sm">{i18n.t("register:landing.testimonial.position")}</div>
          </div>
        </div>
      </div>
      <div className="flex w-full flex-1 justify-center p-8 sm:items-center">
        <div className="w-full max-w-md">
          <LongLogo
            className="mb-4 text-blue-700 sm:mb-8 sm:hidden"
            width={244 * 0.75}
            height={44 * 0.75}
          />
          <SignUpForm />
        </div>
      </div>
    </div>
  </ScrollArea>
)

export default RegisterPage
