import { VStack } from "@components/layout"
import PictureUpload from "@components/shared/picture-upload"
import toast from "@components/shared/toast"
import { uuid } from "@elara/db"
import { IUploadDataFragment } from "@graphql/documents/upload.generated"
import { useFileUploads } from "@hooks/use-file-uploads"
import { usePermissionScope } from "@hooks/use-permission-scope"
import i18n from "@i18n"
import { Paperclip } from "@phosphor-icons/react"
import { default as classNames } from "classnames"
import React, { ChangeEvent, ReactNode, useEffect, useRef, useState } from "react"
import { Accept } from "react-dropzone"
import { Trans } from "react-i18next"
import { FileUpload } from "src/types"
import { v4 } from "uuid"

import Button, { ButtonProps } from "./button"
import { DocumentCard } from "./document-card"
import ImageGallery from "./gallery"

type NoUploadsPlaceholderType = "button" | "zone"

export type UploadsProps = {
  className?: string
  uploads: IUploadDataFragment[]
  onUploadFinished?: (
    upload: IUploadDataFragment,
    uploads: IUploadDataFragment[]
  ) => void | Promise<void>
  scope?: ReturnType<typeof usePermissionScope>
  accept?: Accept
  disabled?: boolean
  onDelete?: (id: uuid, uploads: IUploadDataFragment[]) => void
  onEditFileName?: (id: uuid, fileName: string, uploads: IUploadDataFragment[]) => void
  boundDropzoneToContainer?: boolean
  placeholderType?: NoUploadsPlaceholderType
  placeholderLabel?: ReactNode
  placeholderLabelContainerClass?: string
  addUploadButtonProps?: ButtonProps
  allowQuickDeleteOfPictures?: boolean
  confirmQuickDelete?: boolean
  onUploadsActivityChange?: (isUploading: boolean) => void
}

const Uploads = (props: React.PropsWithChildren<UploadsProps>) => {
  const [uploadId] = useState(() => v4())
  const { boundDropzoneToContainer, placeholderType = "button" } = props

  const { uploads, uploadFile, isUploading } = useFileUploads(props.uploads, {
    onUploadFinished: props.onUploadFinished,
  })

  const mounted = useRef(false)
  const inputFileRef: React.RefObject<HTMLInputElement> = useRef(null)

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return

    for (const file of Object.values(e.target.files)) {
      uploadFile(file)
    }

    // Clear input to allow uploading the same file twice in a row
    if (inputFileRef.current) {
      inputFileRef.current.value = ""
    }
  }

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      return
    }

    props.onUploadsActivityChange?.(isUploading)
  }, [isUploading])

  const images = uploads.filter((u) => u.data.mime_type.startsWith("image"))
  const documents = uploads.filter((u) => !u.data.mime_type.startsWith("image"))
  const fileInput = (
    <input
      multiple
      hidden
      type="file"
      // Set name in attempt to fix a bug in iOS Safari https://stackoverflow.com/a/32483207
      name={uploadId}
      id={uploadId}
      accept={Object.keys(props.accept ?? {}).join(",")}
      disabled={props.disabled}
      ref={inputFileRef}
      onChange={onChange}
    />
  )

  return (
    <div
      className={classNames(props.className, "w-full", {
        relative: boundDropzoneToContainer,
      })}>
      {props.scope?.hasScope && (
        <PictureUpload.Dropzone
          accept={props.accept}
          onDropFile={uploadFile}
          onFilesRejected={(err) => {
            if (err.length) {
              toast.error({
                title: "Dateiformat nicht unterstützt.",
                params: { duration: 5000 },
              })
            }
          }}
        />
      )}

      {!uploads.length && props.scope?.hasScope && (
        <>
          {placeholderType === "button" && (
            <label className="inline-block">
              {fileInput}
              {props.scope?.hasScope && (
                <Button
                  type="tertiary"
                  icon={() => <Paperclip size={18} />}
                  size="small"
                  {...props.addUploadButtonProps}
                  disabled={props.disabled || isUploading}
                  onClick={() => inputFileRef.current?.click()}>
                  {props.placeholderLabel ?? i18n.t("common:upload_files")}
                </Button>
              )}
            </label>
          )}
          {placeholderType === "zone" && (
            <label
              className={classNames(
                props.placeholderLabelContainerClass,
                "flex items-center justify-center p-4 text-sm text-gray-600 border border-gray-200 border-dashed rounded hover:bg-gray-50 hover:text-gray-700 hover:border-gray-400 print:hidden"
              )}>
              {fileInput}
              {props.scope?.hasScope && (
                <div className="inline-flex flex-col items-center">
                  <svg
                    className="mx-auto h-8 w-8 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true">
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {props.placeholderLabel ?? (
                    <span className="mt-2 max-w-xs text-center text-gray-500">
                      <Trans i18nKey="tasks:labels.upload_zone">
                        Klicken um <span className="font-medium">Fotos</span> oder
                        <span className="font-medium">Dateien</span> hinzufügen
                      </Trans>
                    </span>
                  )}
                </div>
              )}
            </label>
          )}
        </>
      )}
      {images.length > 0 && (
        <ImageGallery
          images={images}
          onDelete={(id) =>
            props.onDelete?.(
              id,
              uploads.map((u) => u.data)
            )
          }
          confirmQuickDelete={props.confirmQuickDelete}
          allowQuickDeleteOfPictures={props.allowQuickDeleteOfPictures}
        />
      )}
      {documents.length > 0 && (
        <VStack className="mt-3" space={12}>
          {documents.map((upload: FileUpload) => {
            return (
              <DocumentCard
                key={upload.data.id}
                document={upload.data}
                allowDelete={props.scope?.hasScope}
                sendDeleteToServer={false}
                onDelete={(id) =>
                  props.onDelete?.(
                    id,
                    uploads.map((u) => u.data)
                  )
                }
                onEditFileName={(id, newFilename) =>
                  props.onEditFileName?.(
                    id,
                    newFilename,
                    uploads.map((u) => u.data)
                  )
                }
                allowEdit={props.scope?.hasScope && !!props.onDelete}
                uploadProgress={upload.progress}
              />
            )
          })}
        </VStack>
      )}
      {uploads.length > 0 && props.scope?.hasScope && (
        <label className="mt-2 inline-block print:hidden">
          {fileInput}
          {props.scope?.hasScope && (
            <Button
              size="small"
              type="tertiary"
              icon={() => <Paperclip size={18} />}
              disabled={props.disabled || isUploading}
              {...props.addUploadButtonProps}
              onClick={() => inputFileRef.current?.click()}>
              {props.placeholderLabel ?? i18n.t("common:upload_files")}
            </Button>
          )}
        </label>
      )}
      {props.children}
    </div>
  )
}

export default Uploads
