import { Tooltip } from "@components/shared/tooltip"
import { ISubtaskTagFragment } from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { ClipboardText } from "@phosphor-icons/react"
import { useCallback } from "react"
import { useNavigate } from "react-router-dom"

import { WorkOrderNumber } from "./work-order-number"
import { WorkOrderStatusTag } from "./work-order-status"

type Props = {
  subtasks: ISubtaskTagFragment[]
}

export const WorkOrderSubtaskTag: React.FC<Props> = ({ subtasks }) => {
  const navigate = useNavigate()

  const SubTaskList = useCallback(() => {
    if (!subtasks || subtasks?.length === 0) return null

    const handleClick = (
      event: React.MouseEvent<HTMLDivElement>,
      subtask: ISubtaskTagFragment
    ) => {
      event.preventDefault()
      event.stopPropagation()

      navigate(`/task/${subtask.id}`)
    }

    return (
      <div className="flex flex-col gap-1 py-1">
        <div className="mb-1">
          <h6 className="inline-flex border-b pb-1 pr-3 text-xs font-medium">
            {i18n.t("common:subtask", { count: 2 })}
          </h6>
        </div>

        {subtasks.map((subtask) => (
          <div
            key={subtask.id}
            className="flex items-center gap-2 rounded p-2 hover:bg-gray-100"
            onClick={(e) => handleClick(e, subtask)}>
            <WorkOrderStatusTag short status={subtask.status} />
            <WorkOrderNumber workOrderNumber={subtask.work_order_number} />

            <span>{subtask.name}</span>
          </div>
        ))}
      </div>
    )
  }, [subtasks])

  if (!subtasks || subtasks?.length === 0) return null

  return (
    <Tooltip
      content={<SubTaskList />}
      disableHoverableContent={false}
      contentProps={{ className: "!bg-white", side: "top", align: "start" }}>
      <div className="flex items-center gap-1 rounded border p-1">
        <span className="text-xs">{subtasks.length}</span>
        <ClipboardText size={14} />
      </div>
    </Tooltip>
  )
}
