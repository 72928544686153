import { ConsumableQRCode } from "@components/consumable/consumable-qr-code"
import { StockBadge } from "@components/consumable/stock-badge"
import Button from "@components/shared/button"
import Currency from "@components/shared/currency"
import { DetectExternalLink } from "@components/shared/detect-external-link"
import ScrollArea from "@components/shared/scroll-area"
import { SectionHeader } from "@components/shared/section-header"
import { useBreakpoint } from "@contexts/breakpoints"
import { openModal } from "@contexts/modal-context"
import { Properties } from "@elara/db"
import { IConsumableFragment } from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { MapPin, PlusMinus, Warning } from "@phosphor-icons/react"
import { formatDate } from "@utils/date"
import { useConsumableProperties } from "@utils/properties"

import { TabProps } from "../detail"

type Metadata = {
  key: string
  value: string | number | null
}

const usePropertiesToDisplay = (consumable: IConsumableFragment | null) => {
  const properties = useConsumableProperties()

  if (!consumable) return []
  const metadata = (consumable?.metadata ?? []) as Metadata[]

  const propertiesToDisplay =
    metadata?.map(({ key, value }) => ({ value, label: key, key })) ?? []

  properties.forEach((property) => {
    const consumableProperties = (consumable?.properties ?? {}) as Properties
    const prop = consumableProperties[property.id]

    if (prop) {
      propertiesToDisplay.push({
        value: prop.value,
        label: property.name,
        key: property.id,
      })
    }
  })

  propertiesToDisplay.sort((a, b) => a!.label.localeCompare(b!.label))

  return propertiesToDisplay
}

const DetailsTab = ({ consumable }: TabProps) => {
  const properties = usePropertiesToDisplay(consumable)
  const bp = useBreakpoint()

  const hasInformationToShow =
    properties.length > 0 || consumable.description || consumable.group_id

  return (
    <ScrollArea vertical>
      <div className="mx-auto w-full max-w-screen-lg flex-1 p-3 md:p-4">
        <div className="">
          <SectionHeader className="mb-3">
            {i18n.t("consumables:labels.inventory")}{" "}
            <StockBadge
              quantity={consumable.quantity}
              minQuantity={consumable.min_quantity}
            />
          </SectionHeader>

          {consumable.low_stock_since && (
            <div className="mb-4 flex items-center rounded-md bg-yellow-100 px-3 py-2 text-sm font-medium text-yellow-700">
              <Warning
                weight="fill"
                className="mr-3 text-2xl text-yellow-500 sm:text-base"
              />
              {i18n.t("consumables:messages.low_stock_since")}{" "}
              {formatDate(new Date(consumable.low_stock_since), "Pp")}
            </div>
          )}

          <div className="mb-3 flex flex-wrap gap-x-8 gap-y-4 text-sm">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                {i18n.t("consumables:fields.total_quantity")}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {consumable.quantity}{" "}
                <span className="text-gray-500">{consumable.unit}</span>
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                {i18n.t("consumables:fields.min_quantity")}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {consumable.min_quantity}{" "}
                <span className="text-gray-500">{consumable.unit}</span>
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                {i18n.t("consumables:fields.cost_per_unit")}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {consumable.cost} <Currency className="text-gray-500" />
              </dd>
            </div>
          </div>

          {/* <div className="mb-2 text-base font-semibold text-gray-700"> */}
          <div className="mb-1 text-sm font-medium text-gray-500">
            {i18n.t("consumables:labels.storage_location", { count: 2 })}
          </div>

          {consumable.storage_locations?.length ? (
            bp.md ? (
              <div className=" rounded-lg bg-gray-50 px-3 py-1">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-2 pl-4 pr-3 text-left text-sm font-medium text-gray-500 sm:pl-6 md:pl-0">
                        {i18n.t("common:location", { count: 1 })}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-2 text-left text-sm font-medium text-gray-500">
                        {i18n.t("consumables:fields.area")}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-2 text-left text-sm font-medium text-gray-500">
                        {i18n.t("consumables:fields.quantity")}
                      </th>
                      <th
                        scope="col"
                        className="hidden px-3 py-2 text-left text-sm font-medium text-gray-500 lg:flex">
                        {i18n.t("common:status")}
                      </th>
                      <th scope="col" className="relative py-2 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">
                          {i18n.t("consumables:actions.adjust_quantity")}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {consumable.storage_locations.map((s) => (
                      <tr key={s.place.id}>
                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-700 sm:pl-6 md:pl-0">
                          {s.place.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                          {s.area}
                        </td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm tabular-nums text-gray-700">
                          {s.quantity}{" "}
                          <span className="text-gray-500">{consumable.unit}</span>
                        </td>
                        <td className="hidden items-center whitespace-nowrap px-3 py-2 text-sm text-gray-500 lg:flex">
                          <div className="flex h-9 items-center">
                            <StockBadge
                              quantity={s.quantity}
                              minQuantity={consumable.min_quantity}
                            />
                          </div>
                        </td>
                        <td className="py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <Button
                            type="secondary"
                            icon={PlusMinus}
                            onClick={() => {
                              openModal("material_restock", {
                                consumable_id: consumable.id,
                                initialValues: {
                                  place_id: s.place.id,
                                  cost_per_unit: consumable.cost,
                                },
                              })
                            }}>
                            {i18n.t("consumables:actions.adjust_quantity")}
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="space-y-2 text-sm">
                {consumable.storage_locations.map((s) => (
                  <div key={s.place.id} className="space-y-2 rounded-lg bg-gray-50 p-3">
                    <div className="flex items-start justify-between gap-x-3">
                      <div className="min-h-[24px] break-all align-middle">
                        <span className="font-medium">{s.place.name}</span>{" "}
                        <span className="text-gray-500">{s.area}</span>
                      </div>
                    </div>

                    <div className="flex items-center justify-between">
                      <span className="mr-3 font-medium">
                        {s.quantity} {consumable.unit}
                      </span>
                      <StockBadge
                        quantity={s.quantity}
                        minQuantity={consumable.min_quantity}
                      />
                    </div>
                    <Button
                      icon={PlusMinus}
                      type="secondary"
                      className="w-full"
                      onClick={() => {
                        openModal("material_restock", {
                          consumable_id: consumable.id,
                          initialValues: {
                            place_id: s.place.id,
                            cost_per_unit: consumable.cost,
                          },
                        })
                      }}>
                      {i18n.t("consumables:actions.adjust_quantity")}
                    </Button>
                  </div>
                ))}
              </div>
            )
          ) : (
            <button
              type="button"
              onClick={() => openModal("consumable", { initialValues: consumable })}
              className="flex w-full max-w-xs cursor-pointer items-center justify-center gap-2 rounded border border-dashed p-5 text-sm text-gray-500 hover:border-gray-400 hover:bg-gray-50 hover:text-gray-700 radix-state-open:border-gray-400 radix-state-open:bg-gray-50 radix-state-open:text-gray-700">
              <MapPin size={20} className="text-gray-400" weight="fill" />
              {i18n.t("consumables:actions.add_storage_location")}
            </button>
          )}
        </div>

        <hr className="my-4" />
        <div className="">
          <SectionHeader className="mb-3">{i18n.t("common:information")}</SectionHeader>

          {hasInformationToShow ? (
            <div className="grid gap-3 md:grid-cols-2 lg:grid-cols-3">
              {consumable.description && (
                <div className="sm:col-span-full">
                  <dt className="text-sm font-medium text-gray-500">
                    {i18n.t("consumables:fields.description")}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">{consumable.description}</dd>
                </div>
              )}
              {consumable.group && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    {i18n.t("consumables:fields.group", { count: 1 })}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">{consumable.group?.name}</dd>
                </div>
              )}
              {properties.map(({ key, label, value }) => {
                const excludedData = ["area"]
                if (excludedData.indexOf(label) > -1) return <></>
                return (
                  <div key={key}>
                    <dt className="text-sm font-medium text-gray-500">{label}</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <DetectExternalLink text={value} />
                    </dd>
                  </div>
                )
              })}
            </div>
          ) : (
            <div className="text-sm text-gray-500">
              {i18n.t("consumables:messages.no_information")}
            </div>
          )}
        </div>

        <hr className="my-4" />
        <div className="">
          <SectionHeader className="mb-3">{i18n.t("common:qr_code")}</SectionHeader>
          <ConsumableQRCode consumable={consumable} />
        </div>
      </div>
    </ScrollArea>
  )
}

export default DetailsTab
