import { cn } from "@utils"
import { forwardRef } from "react"

import Flex, { FlexProps } from "./flex"

type StackProps = {
  space?: 0 | 2 | 4 | 8 | 12 | 16 | 20 | 24
} & FlexProps

export const Stack = forwardRef<HTMLDivElement, StackProps>(
  ({ className, direction, space, ...props }, ref) => {
    const horizontal = direction === "row" || direction === "row-reverse"
    const vertical = !(direction === "row" || direction === "row-reverse")
    return (
      <Flex
        className={cn(
          {
            "space-x-0": horizontal && space === 0,
            "space-x-0.5": horizontal && space === 2,
            "space-x-1": horizontal && space === 4,
            "space-x-2": horizontal && space === 8,
            "space-x-3": horizontal && space === 12,
            "space-x-4": horizontal && space === 16,
            "space-x-5": horizontal && space === 20,
            "space-x-6": horizontal && space === 24,
            "space-y-0": vertical && space === 0,
            "space-y-0.5": vertical && space === 2,
            "space-y-1": vertical && space === 4,
            "space-y-2": vertical && space === 8,
            "space-y-3": vertical && space === 12,
            "space-y-4": vertical && space === 16,
            "space-y-5": vertical && space === 20,
            "space-y-6": vertical && space === 24,
          },
          className
        )}
        ref={ref as any}
        direction={direction}
        {...props}
      />
    )
  }
)

export const VStack = forwardRef<HTMLDivElement, Omit<StackProps, "direction">>(
  (props, ref) => <Stack {...props} ref={ref} direction="column" />
)
export const HStack = forwardRef<HTMLDivElement, Omit<StackProps, "direction">>(
  (props, ref) => <Stack align="center" {...props} ref={ref} direction="row" />
)
