import { LoadingIndicator } from "@components/shared"
import { DialogForm } from "@components/shared/dialog-form"
import { FormField } from "@components/shared/form/form-field"
import { SelectPopover } from "@components/shared/single-select"
import { TextInput } from "@components/shared/text-input"
import toast from "@components/shared/toast"
import { useUser } from "@contexts/user-context"
import { IUsersTableRowFragment } from "@graphql/documents/fragments.generated"
import { usePermissionRolesQuery } from "@graphql/documents/permissions.generated"
import {
  IUpdateUserEmailMutation,
  IUpdateUserEmailMutationVariables,
  UpdateUserEmailDocument,
  useCreateUserMutation,
  useEditUserProfileMutation,
} from "@graphql/documents/user.generated"
import { useUpdateUserPermissionRoleMutation } from "@graphql/documents/workforce.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import { useFileUploads } from "@hooks/use-file-uploads"
import i18n from "@i18n"
import { Upload } from "@phosphor-icons/react"
import classNames from "classnames"
import { useField } from "formik"
import { ChangeEvent, useMemo, useRef } from "react"
import { Trans } from "react-i18next"
import { Link } from "react-router-dom"
import { FileUpload } from "src/types"
import { gql, useClient } from "urql"
import * as yup from "yup"

const AvatarForm = () => {
  const [field, _meta, helpers] = useField({ name: "avatar" })

  const { uploads, uploadFile } = useFileUploads(field.value ? [field.value] : [], {
    onUploadFinished: (u) => helpers.setValue(u),
  })

  const fileInputRef = useRef<HTMLInputElement>(null)
  const onFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return

    for (const file of Object.values(e.target.files)) {
      uploadFile(file)
    }
    // Clear input to allow uploading the same file twice in a row
    if (fileInputRef.current) {
      fileInputRef.current.value = ""
    }
  }
  const currentUpload: FileUpload | null =
    uploads.sort((u, v) => v.data.created_at.localeCompare(u.data.created_at))[0] ?? null
  return currentUpload ? (
    <div>
      <div className="relative h-36 w-36 overflow-hidden rounded ring-1 ring-gray-300 ring-offset-2">
        {currentUpload.progress < 1 ? (
          <div className="absolute inset-0 z-10 flex items-center justify-center bg-white/70">
            <LoadingIndicator size={24} />
          </div>
        ) : null}
        <img src={currentUpload.data.url!} className="h-36 w-36 object-cover " />
      </div>
      <label className="text-sm">
        <div className="mt-2 inline-block select-none rounded border border-gray-300 px-2 py-1 font-medium text-gray-700 hover:border-gray-700">
          {i18n.t("common:change_token", {
            token: i18n.t("settings:profile.fields.avatar"),
          })}
        </div>
        <input
          hidden
          type="file"
          accept={"image/*"}
          ref={fileInputRef}
          onChange={onFileInputChange}
        />
      </label>
    </div>
  ) : (
    <label className="text-sm">
      <div className="flex h-36 w-36 flex-col items-center justify-center border border-dashed border-gray-300 text-gray-700 hover:border-gray-600">
        <Upload size={20} />
        <div className="mt-3 text-center text-sm">
          {i18n.t("common:upload_token", {
            token: i18n.t("settings:profile.fields.avatar"),
          })}
        </div>
      </div>
      <input
        hidden
        type="file"
        accept={"image/*"}
        ref={fileInputRef}
        onChange={onFileInputChange}
      />
    </label>
  )
}
function useUserCreateSchema() {
  const client = useClient()
  const account = usePermissionScope(IPermissionScopeEnum.AppAccountManagement)

  const schema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup.string().required(
          i18n.t("common:forms.is_required", {
            field: i18n.t("settings:profile.fields.first_name"),
          })
        ),
        lastName: yup.string().required(
          i18n.t("common:forms.is_required", {
            field: i18n.t("settings:profile.fields.last_name"),
          })
        ),
        email: yup
          .string()
          .email()
          .required(
            i18n.t("common:forms.is_required", {
              field: i18n.t("settings:profile.fields.email"),
            })
          )
          .test("unique", i18n.t("common:forms.email_already_exists"), async (value) => {
            if (!value) return true
            const { data } = await client
              .mutation(
                gql`
                  mutation DuplicateEmail($email: String!) {
                    checkDuplicateEmail(email: $email) {
                      isDuplicate
                    }
                  }
                `,
                { email: value },
                { context: account.context() }
              )
              .toPromise()
            if (!data?.checkDuplicateEmail) return false
            return !data.checkDuplicateEmail.isDuplicate
          }),
        permissionRoleId: yup
          .string()
          .uuid()
          .required(
            i18n.t("common:forms.is_required", {
              field: i18n.t("settings:profile.fields.role"),
            })
          ),
      }),
    [client, account.hasScope]
  )
  return schema
}

export const EditUserForm = (props: {
  isCreate: boolean
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  user?: IUsersTableRowFragment | null
  onCreateUser?: (newPassword: string) => void
}) => {
  const user = useUser()

  const [, editUserProfile] = useEditUserProfileMutation()
  const [, createUser] = useCreateUserMutation()

  const accountManagementScope = usePermissionScope(
    IPermissionScopeEnum.AppAccountManagement
  )

  const client = useClient()
  const [, updatePermissionRole] = useUpdateUserPermissionRoleMutation()

  const [permissionRolesQueryRes] = usePermissionRolesQuery({
    requestPolicy: "cache-first",
  })
  const permissionRoles = permissionRolesQueryRes?.data?.permission_role?.slice() ?? []
  const roleOptions = permissionRoles
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((r) => ({ value: r.id, label: r.name, searchValue: r.name }))

  const initialValues = {
    firstName: props.user?.user.first_name ?? "",
    lastName: props.user?.user.last_name ?? "",
    permissionRoleId: props.user?.permission_role?.id,
    avatar: props.user?.user?.avatar ?? null,
    email: props.user?.user.email ?? "",
  }

  const handleEditUser = async (values: typeof initialValues) => {
    if (!props.user) return

    const result = await editUserProfile(
      {
        id: props.user?.user_id!,
        set: {
          first_name: values.firstName,
          last_name: values.lastName,
          avatar_id: values.avatar?.id || null,
        },
      },
      accountManagementScope.context()
    )

    if (result.error || !result.data) {
      toast.error(
        i18n.t("common:messages.token_update_failure", {
          token: i18n.t("common:employee", { count: 1 }),
        })
      )
      throw result.error
    }

    if (accountManagementScope.hasScope && values.permissionRoleId) {
      const roleRes = await updatePermissionRole(
        {
          user_id: props.user?.user_id!,
          location_id: user.location.id,
          permissionRoleId: values.permissionRoleId,
        },
        accountManagementScope.context()
      )
      if (roleRes.error) {
        toast.error(
          i18n.t("common:messages.token_update_failure", {
            token: i18n.t("settings:profile.fields.role"),
          })
        )
        throw roleRes.error
      }
    }

    if (accountManagementScope.hasScope && values.email !== props.user.user.email) {
      await client
        .mutation<IUpdateUserEmailMutation, IUpdateUserEmailMutationVariables>(
          UpdateUserEmailDocument,
          {
            userId: props.user.user_id!,
            email: values.email,
          },
          accountManagementScope.context()
        )
        .toPromise()
    }

    return result.data
  }

  const handleCreateUser = async (values: typeof initialValues) => {
    const result = await createUser(
      {
        userData: {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
        },
        permissionRoleId: values.permissionRoleId!,
      },
      accountManagementScope.context()
    )
    if (result.error || !result.data) {
      toast.error(
        i18n.t("common:messages.token_create_failure", {
          token: i18n.t("common:employee", { count: 1 }),
        })
      )
    }
    if (result.data?.addUserToLocation?.password) {
      props.onCreateUser?.(result.data?.addUserToLocation?.password)
    }
  }

  const validationSchema = useUserCreateSchema()

  return (
    <DialogForm
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
      title={
        props.isCreate
          ? i18n.t("common:add_token", { token: i18n.t("common:employee", { count: 1 }) })
          : i18n.t("common:edit_token", { token: i18n.t("common:employee", { count: 1 }) })
      }
      okText={i18n.t("common:save")}
      cancelText={i18n.t("common:cancel")}
      formikConfig={{
        initialValues,
        onSubmit: props.isCreate ? handleCreateUser : handleEditUser,
        validateOnChange: false,
        validateOnBlur: true,
        validationSchema: props.isCreate && validationSchema,
      }}>
      {(formik) => (
        <div>
          {!props.isCreate && (
            <div className="my-4">
              <h3 className="mb-2 text-sm font-medium text-gray-600">
                {i18n.t("settings:profile.fields.avatar")}
              </h3>
              <AvatarForm />
            </div>
          )}
          <div className={classNames("py-0", { "!py-4": props.isCreate })}>
            <FormField
              name="firstName"
              label={i18n.t("settings:profile.fields.first_name")}>
              <TextInput />
            </FormField>
            <FormField name="lastName" label={i18n.t("settings:profile.fields.last_name")}>
              <TextInput />
            </FormField>
            {accountManagementScope.hasScope && (
              <FormField name="email" label={i18n.t("settings:profile.fields.email")}>
                <TextInput
                  type="email"
                  autoComplete="nofill"
                  placeholder={i18n.t("settings:profile.fields.placeholders.email")}
                />
              </FormField>
            )}
            <FormField
              name="permissionRoleId"
              label={i18n.t("settings:profile.fields.role")}>
              {({ field, helpers }) => (
                <SelectPopover
                  items={roleOptions}
                  value={field.value}
                  isClearable={false}
                  onChange={helpers.setValue}
                />
              )}
            </FormField>
          </div>
          {formik.errors.email &&
            formik.errors.email === i18n.t("common:forms.email_already_exists") && (
              <p className="text-sm text-gray-600">
                <Trans
                  i18n={i18n}
                  i18nKey={"settings:user_management.users.duplicate_email_invite_hint"}
                  components={{
                    inviteLink: <Link to="../invites" />,
                  }}
                />
              </p>
            )}
        </div>
      )}
    </DialogForm>
  )
}
