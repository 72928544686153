import { useLocation } from "@contexts/user-context"
import { cn } from "@utils"

export function CurrencyValue({
  value,
  ...props
}: { value: number } & React.HTMLAttributes<HTMLSpanElement>) {
  const {
    settings: { lang: locale, currency },
  } = useLocation()
  const CurrencyFormatter = Intl.NumberFormat(locale, {
    style: "currency",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: currency ?? "EUR",
  })

  return (
    <span {...props} className={cn("whitespace-nowrap", props.className)}>
      {CurrencyFormatter.format(value)}
    </span>
  )
}
