import { ComboboxSelect, Item } from "@components/shared/combobox-select"
import { PopoverContent, PopoverRoot, PopoverTrigger } from "@components/shared/popover"
import TouchTargetSize from "@components/shared/touch-target-size"
import { Data } from "@elara/select"
import { useDisclosure } from "@hooks"
import i18n from "@i18n"
import Icons from "@resources/icons"
import { findTreeList } from "@utils/tree"
import classNames from "classnames"
import { dequal } from "dequal"
import React, { useEffect, useState } from "react"
import { useClient } from "urql"

import { FilterState } from "../data-view-types"
import { FilterMenu } from "./filter-menu"
import { useDataViewFilterMaxListHeight } from "./hooks"
import { Schema, SelectSchemaStatement } from "./schema"

export type FilterBadgeProps<D extends Data> = {
  schema: Schema<D>
  state: FilterState
  onStateChange: (state: FilterState) => void
  onRemove: () => void
}

export const FilterBadge = <D extends Data>(props: FilterBadgeProps<D>) => {
  const { state, schema } = props

  const menu = useDisclosure()

  const schemaStatement = schema[state.id]

  const [items, setItems] = useState<Item<any>[] | null>(null)
  const client = useClient()
  useEffect(() => {
    const fetchItems = async () => {
      if (schemaStatement.type === "singleton") return
      const items = await schemaStatement.getItems?.(client)
      setItems(items)
    }
    fetchItems()
  }, [])

  const [listMaxHeight, ref] = useDataViewFilterMaxListHeight()

  return (
    <PopoverRoot open={menu.isOpen} onOpenChange={menu.changeOpen} modal>
      <div
        ref={ref}
        className="flex cursor-default flex-row items-stretch rounded border border-solid border-gray-300 bg-white pl-1 text-sm text-gray-700">
        <span className="inline-flex items-center px-1">
          {schemaStatement.icon ? (
            <span className="mr-1 text-gray-500">{schemaStatement.icon}</span>
          ) : null}
          {schemaStatement.type === "singleton"
            ? schemaStatement.badgeLabel
            : schemaStatement.label}
        </span>
        <SelectNegatedMenu
          multiple={
            state?.type === "select" &&
            !!state.selectedValues &&
            state.selectedValues.length > 1
          }
          operatorLabel={
            schemaStatement.operatorLabel &&
            ((negated: boolean, multiple: boolean) =>
              schemaStatement.operatorLabel!(negated, multiple, state as any))
          }
          isNegated={state.negated ?? false}
          onNegatedChange={(isNegated) => {
            props.onStateChange({ ...state, negated: isNegated })
          }}
        />
        <PopoverTrigger
          className={classNames("h-full px-1 cursor-default relative whitespace-nowrap", {
            "hover:bg-gray-100 hover:text-gray-900": state.type === "select",
          })}>
          <TouchTargetSize horizontal={false} />
          {state.type === "select" && schemaStatement.type === "select" ? (
            state.selectedValues?.length == 1 ? (
              findTreeList(items ?? [], (item) =>
                dequal(state.selectedValues[0], item.value)
              )?.label
            ) : (
              <span>
                {state.selectedValues?.length}{" "}
                {(schemaStatement as SelectSchemaStatement<any>).multiSelectedLabel}
              </span>
            )
          ) : (
            schemaStatement.label
          )}
        </PopoverTrigger>
        <div
          className="inline-flex shrink-0 items-center rounded-r p-1 text-lg text-gray-600 hover:bg-gray-100 hover:text-gray-800"
          onClick={props.onRemove}>
          <Icons.Close />
        </div>
      </div>

      <PopoverContent
        alignOffset={0}
        sideOffset={8}
        className="max-w-sm"
        dialog={{ onOpenAutoFocus: (e) => e.preventDefault() }}>
        {state.type === "select" && schemaStatement?.type === "select" && (
          <FilterMenu
            filterState={state}
            onFilterStateChange={(state, keepOpen) => {
              props.onStateChange(state)
              menu.changeOpen(keepOpen)
            }}
            onClose={menu.onClose}
            schema={schema}
            listMaxHeight={listMaxHeight}
          />
        )}
      </PopoverContent>
    </PopoverRoot>
  )
}

type SelectNegatedMenuProps = {
  operatorLabel?: (negated: boolean, multiple: boolean) => string
  multiple: boolean
  isNegated: boolean
  onNegatedChange: (isNegated: boolean) => void
}

function defaultOperatorLabel(isNegated: boolean, multiple: boolean) {
  if (multiple) {
    return isNegated
      ? i18n.t("data-view:filters.is_not")
      : i18n.t("data-view:filters.is_either")
  } else {
    return isNegated ? i18n.t("data-view:filters.is_not") : i18n.t("data-view:filters.is")
  }
}

const SelectNegatedMenu = (props: React.PropsWithChildren<SelectNegatedMenuProps>) => {
  const { operatorLabel = defaultOperatorLabel } = props
  const menu = useDisclosure()
  return (
    <PopoverRoot open={menu.isOpen} onOpenChange={menu.changeOpen} alwaysPopover>
      <PopoverTrigger className="relative h-full cursor-default px-1 text-gray-600 hover:bg-gray-100 hover:text-gray-900">
        <TouchTargetSize horizontal={false} />
        {operatorLabel(props.isNegated, props.multiple)}
      </PopoverTrigger>
      <PopoverContent sideOffset={12}>
        <ComboboxSelect
          items={[
            {
              label: operatorLabel(false, props.multiple),
              value: "eq",
              searchValue: operatorLabel(false, props.multiple),
            },
            {
              label: operatorLabel(true, props.multiple),
              value: "neq",
              searchValue: operatorLabel(true, props.multiple),
            },
          ]}
          valueToString={(v) => v}
          placeholder={i18n.t("common:select")}
          inputProps={{ hide: true }}
          value={props.isNegated ? "neq" : "eq"}
          onChange={(v) => {
            props.onNegatedChange(v === "neq")
            menu.onClose()
          }}
        />
      </PopoverContent>
    </PopoverRoot>
  )
}
