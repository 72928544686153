import {
  AssetActivityDataView,
  AssetActivityIds,
} from "@components/asset/asset-activity-data-view"
import { AssetDataView, AssetViewIds } from "@components/asset/asset-data-view"
import { MeterDataView, MeterViewIds } from "@components/asset/meter-data-view"
import ConsumableDataView, {
  ConsumableColumnIds,
} from "@components/consumable/consumable-data-view"
import ContactDataView, { ContactColumnIds } from "@components/contact/contact-data-view"
import { Center } from "@components/layout"
import MaintenanceDataView from "@components/maintenance/maintenance-data-view"
import { useServiceRequestScope } from "@components/service-request/hooks/use-service-request-scope"
import ServiceRequestDataView, {
  ServiceRequestColumnIds,
} from "@components/service-request/service-request-data-view"
import { LoadingIndicator } from "@components/shared"
import { DataViewConfiguration } from "@components/shared/data-view/data-view"
import { SelectPopover, SelectTrigger } from "@components/shared/single-select"
import {
  LogbookHistoryLength,
  logbookHistoryOptions,
  useLogbookData,
} from "@components/work-order/use-logbook-data"
import { useDataWorkOrderDataView } from "@components/work-order/use-task-data"
import {
  WorkOrderDataView,
  WorkOrderViewIds,
} from "@components/work-order/work-order-data-view"
import { IViewDataTypeEnum } from "@elara/db"
import { useAssetDataViewQuery } from "@graphql/documents/asset.generated"
import { useMeterDataViewQuery } from "@graphql/documents/asset-meter.generated"
import { useConsumablesQuery } from "@graphql/documents/consumable.generated"
import { useContactsQuery } from "@graphql/documents/contact.generated"
import {
  ICustomViewFragment,
  useCustomViewByIdQuery,
} from "@graphql/documents/custom-view.generated"
import { useServiceRequestsQuery } from "@graphql/documents/service-request.generated"
import { uuid } from "@graphql/scalars"
import { usePollQuery, useStickyState } from "@hooks"
import i18n from "@i18n"
import { Rows } from "@phosphor-icons/react"
import { ReactNode, useMemo } from "react"
import { Navigate } from "react-router-dom"

type CustomViewProps = {
  isEditView: boolean
  onEditViewFinish?: () => void
  view: ICustomViewFragment
  onCreateView?: (view: ICustomViewFragment) => void
  action?: ReactNode
}

export const AssetCustomView = (props: CustomViewProps) => {
  const { view } = props
  const [queryRes] = useAssetDataViewQuery({ requestPolicy: "cache-first" })

  return (
    <AssetDataView
      data={queryRes.data?.asset ?? null}
      defaultConfig={view.config as DataViewConfiguration<AssetViewIds>}
      customView={view}
      isEditView={props.isEditView}
      headerClassName="mx-3"
      configId={view.id}
      allowToSaveCustomView
      onEditViewFinish={props.onEditViewFinish}
      onCreateView={props.onCreateView}
      actions={props.action}
    />
  )
}

export const AssetActivityCustomView = (props: CustomViewProps) => {
  const { view } = props

  return (
    <AssetActivityDataView
      defaultConfig={view.config as DataViewConfiguration<AssetActivityIds>}
      customView={view}
      isEditView={props.isEditView}
      headerClassName="mx-3"
      configId={view.id}
      onEditViewFinish={props.onEditViewFinish}
      onCreateView={props.onCreateView}
      actions={props.action}
      allowToSaveCustomView
    />
  )
}

export const WorkOrderCustomView = (props: CustomViewProps) => {
  const { view } = props

  const noData = useMemo(
    () => ({
      icon: Rows,
      title: i18n.t("views:work_order.no_data.title"),
      message: <p style={{ marginTop: 4 }}>{i18n.t("views:work_order.no_data.message")}</p>,
      actions: null,
    }),
    []
  )

  return (
    <WorkOrderDataView
      data={null}
      useData={useDataWorkOrderDataView}
      defaultConfig={view.config as DataViewConfiguration<WorkOrderViewIds>}
      customView={view}
      configId={view.id}
      isEditView={props.isEditView}
      headerClassName="mx-3"
      allowToSaveCustomView
      onEditViewFinish={props.onEditViewFinish}
      onCreateView={props.onCreateView}
      actions={props.action}
      noData={noData}
    />
  )
}

export const MeterCustomView = (props: CustomViewProps) => {
  const { view } = props
  const [queryRes, fetchData] = useMeterDataViewQuery({
    requestPolicy: "cache-first",
  })
  const reexecute = () => fetchData({ requestPolicy: "network-only" })

  usePollQuery({
    reexecute,
    isFetching: queryRes.fetching,
    pollInterval: 5 * 60 * 1000,
  })

  return (
    <MeterDataView
      data={queryRes.data?.meter ?? null}
      defaultConfig={view.config as DataViewConfiguration<MeterViewIds>}
      customView={view}
      isEditView={props.isEditView}
      headerClassName="mx-3"
      configId={view.id}
      allowToSaveCustomView
      onEditViewFinish={props.onEditViewFinish}
      onCreateView={props.onCreateView}
      actions={props.action}
    />
  )
}

export const ConsumableCustomView = (props: CustomViewProps) => {
  const { view } = props
  const [queryRes, fetchData] = useConsumablesQuery()

  const reexecute = () => fetchData({ requestPolicy: "network-only" })

  usePollQuery({
    reexecute,
    isFetching: queryRes.fetching,
    pollInterval: 5 * 60 * 1000,
  })

  return (
    <ConsumableDataView
      data={queryRes.data?.consumable ?? null}
      defaultConfig={view.config as DataViewConfiguration<ConsumableColumnIds>}
      customView={view}
      isEditView={props.isEditView}
      headerClassName="mx-3"
      configId={view.id}
      allowToSaveCustomView
      onEditViewFinish={props.onEditViewFinish}
      onCreateView={props.onCreateView}
      actions={props.action}
    />
  )
}

export const ContactCustomView = (props: CustomViewProps) => {
  const { view } = props
  const [queryRes, fetchData] = useContactsQuery()

  const reexecute = () => fetchData({ requestPolicy: "network-only" })

  usePollQuery({
    reexecute,
    isFetching: queryRes.fetching,
    pollInterval: 5 * 60 * 1000,
  })

  return (
    <ContactDataView
      data={queryRes.data?.contact ?? null}
      defaultConfig={view.config as DataViewConfiguration<ContactColumnIds>}
      customView={view}
      isEditView={props.isEditView}
      headerClassName="mx-3"
      configId={view.id}
      allowToSaveCustomView
      onEditViewFinish={props.onEditViewFinish}
      onCreateView={props.onCreateView}
      actions={props.action}
    />
  )
}

export const ServiceRequestCustomView = (props: CustomViewProps) => {
  const { view } = props
  const context = useServiceRequestScope()
  const [queryRes, fetchData] = useServiceRequestsQuery({ context })

  const reexecute = () => fetchData({ requestPolicy: "network-only" })

  usePollQuery({
    reexecute,
    isFetching: queryRes.fetching,
    pollInterval: 5 * 60 * 1000,
  })

  return (
    <ServiceRequestDataView
      data={queryRes.data?.service_request ?? null}
      defaultConfig={view.config as DataViewConfiguration<ServiceRequestColumnIds>}
      customView={view}
      isEditView={props.isEditView}
      headerClassName="mx-3"
      configId={view.id}
      allowToSaveCustomView
      onEditViewFinish={props.onEditViewFinish}
      onCreateView={props.onCreateView}
      actions={props.action}
    />
  )
}

export const MaintenanceCustomView = (props: CustomViewProps) => (
  <MaintenanceDataView
    customView={props.view}
    isEditView={props.isEditView}
    headerClassName="mx-3"
    configId={props.view.id}
    allowToSaveCustomView
    onEditViewFinish={props.onEditViewFinish}
    onCreateView={props.onCreateView}
    actions={props.action}
  />
)

export const LogbookCustomView = (props: CustomViewProps) => {
  const { view } = props
  const [historyLength, setHistoryLength] = useStickyState<LogbookHistoryLength>(
    "1_month",
    `logbook:view:${view.id}`,
    "global"
  )
  const logbook = useLogbookData({ customView: props.view, historyLength })

  return (
    <div className="mt-3 flex min-h-0 flex-1 flex-col">
      <div className="my-3 flex justify-start px-3 sm:flex-row sm:items-center">
        <SelectPopover<LogbookHistoryLength>
          items={logbookHistoryOptions}
          value={historyLength}
          onChange={(v) => setHistoryLength(v as LogbookHistoryLength)}>
          <SelectTrigger className="rounded border px-2 py-1 text-sm text-gray-500">
            <span className="font-medium text-gray-700">
              {logbookHistoryOptions.find((v) => v.value === historyLength)?.label}
            </span>
          </SelectTrigger>
        </SelectPopover>
      </div>

      <WorkOrderDataView
        data={logbook.data}
        allowToSaveCustomView
        configId={view.id}
        defaultConfig={{
          groupBy: null,
          ...(view.config as DataViewConfiguration<WorkOrderViewIds>),
        }}
        customView={view}
        isEditView={props.isEditView}
        headerClassName="mx-3"
        onEditViewFinish={props.onEditViewFinish}
        onCreateView={props.onCreateView}
      />
    </div>
  )
}

export const CustomView = (props: {
  edit?: boolean
  viewId: uuid
  onEditViewFinish?: () => void
  onCreateView?: (view: ICustomViewFragment) => void
  action?: ReactNode
}) => {
  const { viewId } = props

  const [viewQueryRes] = useCustomViewByIdQuery({
    variables: { id: viewId! },
    pause: !viewId,
    requestPolicy: "cache-first",
  })

  if (viewQueryRes.fetching)
    return (
      <Center flex="1">
        <LoadingIndicator size={24} />
      </Center>
    )

  const data = viewQueryRes.data
  if (!data)
    return (
      <Center flex="1">
        <LoadingIndicator size={24} />
      </Center>
    )
  const view = data?.custom_view_by_pk
  if (!view) {
    return <Navigate to="/" replace />
  }

  return (
    <>
      {view.data_type === IViewDataTypeEnum.Asset && (
        <AssetCustomView
          view={view}
          key={view.id}
          isEditView={!!props.edit}
          onEditViewFinish={props.onEditViewFinish}
          onCreateView={props.onCreateView}
          action={props.action}
        />
      )}
      {view.data_type === IViewDataTypeEnum.AssetState && (
        <AssetActivityCustomView
          view={view}
          key={view.id}
          isEditView={!!props.edit}
          onEditViewFinish={props.onEditViewFinish}
          onCreateView={props.onCreateView}
          action={props.action}
        />
      )}
      {view.data_type === IViewDataTypeEnum.Logbook && (
        <LogbookCustomView
          view={view}
          key={view.id}
          isEditView={!!props.edit}
          onEditViewFinish={props.onEditViewFinish}
          onCreateView={props.onCreateView}
          action={props.action}
        />
      )}
      {view.data_type === IViewDataTypeEnum.Workorder && (
        <WorkOrderCustomView
          view={view}
          key={view.id}
          isEditView={!!props.edit}
          onEditViewFinish={props.onEditViewFinish}
          onCreateView={props.onCreateView}
          action={props.action}
        />
      )}
      {view.data_type === IViewDataTypeEnum.Meter && (
        <MeterCustomView
          view={view}
          key={view.id}
          isEditView={!!props.edit}
          onEditViewFinish={props.onEditViewFinish}
          onCreateView={props.onCreateView}
          action={props.action}
        />
      )}
      {view.data_type === IViewDataTypeEnum.Consumable && (
        <ConsumableCustomView
          view={view}
          key={view.id}
          isEditView={!!props.edit}
          onEditViewFinish={props.onEditViewFinish}
          onCreateView={props.onCreateView}
          action={props.action}
        />
      )}
      {view.data_type === IViewDataTypeEnum.Contact && (
        <ContactCustomView
          view={view}
          key={view.id}
          isEditView={!!props.edit}
          onEditViewFinish={props.onEditViewFinish}
          onCreateView={props.onCreateView}
          action={props.action}
        />
      )}
      {view.data_type === IViewDataTypeEnum.ServiceRequest && (
        <ServiceRequestCustomView
          view={view}
          key={view.id}
          isEditView={!!props.edit}
          onEditViewFinish={props.onEditViewFinish}
          onCreateView={props.onCreateView}
          action={props.action}
        />
      )}
      {view.data_type === IViewDataTypeEnum.Maintenance && (
        <MaintenanceCustomView
          view={view}
          key={view.id}
          isEditView={!!props.edit}
          onEditViewFinish={props.onEditViewFinish}
          onCreateView={props.onCreateView}
          action={props.action}
        />
      )}
    </>
  )
}

export default CustomView
