import { CopyClipboard } from "@components/shared/copy-clipboard"
import { DialogRootProps } from "@components/shared/dialog"
import { DialogForm } from "@components/shared/dialog-form"
import { FormField } from "@components/shared/form/form-field"
import { DateTimeNative } from "@components/shared/native-date-time-pickers"
import { TextInput } from "@components/shared/text-input"
import toast from "@components/shared/toast"
import { useUpdateWorkOrderPartiallyMutation } from "@graphql/documents/work-order.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { Prohibit, Share } from "@phosphor-icons/react"
import { formatDate } from "@utils/date"
import { formatISO } from "date-fns"
import * as Yup from "yup"

const FormSchema = Yup.object().shape({
  expiresIn: Yup.date().required(),
})

type FormValues = Yup.InferType<typeof FormSchema>

type Props = {
  taskId: string
  mode: "create" | "revoke"
  initialValues?: FormValues
} & Required<Pick<DialogRootProps, "isOpen" | "onOpenChange">>

const TaskPublicAccessDialog: React.FC<Props> = (props) => {
  const publicLink = window.location.origin + "/public/task/" + props.taskId

  const [, updateTask] = useUpdateWorkOrderPartiallyMutation()
  const editScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)

  const onSubmit = async (values: FormValues) => {
    if (props.mode === "create") {
      if (!values.expiresIn) return

      await updateTask(
        {
          id: props.taskId,
          changes: {
            id: props.taskId,
            public_view_expiration: formatISO(values.expiresIn),
          },
        },
        editScope.context()
      )

      navigator.clipboard.writeText(publicLink)
      toast.success(i18n.t("tasks:messages.link_copied_clipboard"))
    } else if (props.mode === "revoke") {
      await updateTask(
        {
          id: props.taskId,
          changes: {
            id: props.taskId,
            public_view_expiration: null,
          },
        },
        editScope.context()
      )
    }

    return
  }

  return (
    <DialogForm<FormValues>
      {...props}
      closeIcon
      title={
        props.mode === "create"
          ? i18n.t("tasks:dialogs.public_access.create.title")
          : i18n.t("tasks:dialogs.public_access.revoke.title")
      }
      okText={
        props.mode === "create"
          ? i18n.t("tasks:dialogs.public_access.create.action")
          : i18n.t("tasks:dialogs.public_access.revoke.action")
      }
      okProps={{
        type: "primary",
        icon: () => (props.mode === "create" ? <Share /> : <Prohibit />),
        color: props.mode === "create" ? "blue" : "red",
      }}
      formikConfig={{
        onSubmit,
        validationSchema: FormSchema,
        initialValues: props.initialValues ?? { expiresIn: new Date() },
      }}>
      {(_formik) => (
        <div className="space-y-4 text-sm">
          {props.mode === "create" ? (
            <>
              <p>
                Please indicate a future timeframe during which the task should be available
                for public access via this link.
              </p>

              <div className="flex items-center gap-2">
                <TextInput
                  disabled
                  type="text"
                  value={publicLink}
                  className="w-full bg-gray-100"
                />

                <CopyClipboard text={publicLink} />
              </div>

              <FormField
                name="expiresIn"
                label={i18n.t("tasks:dialogs.public_access.create.expires_in_label")}>
                {({ field, helpers }) => (
                  <DateTimeNative {...field} onChange={(val) => helpers.setValue(val)} />
                )}
              </FormField>
            </>
          ) : (
            <>
              {props.initialValues && (
                <p>
                  {i18n.t("tasks:dialogs.public_access.revoke.description", {
                    expiresIn: formatDate(props.initialValues.expiresIn, "PPPp"),
                  })}
                </p>
              )}

              <div className="flex items-center gap-2">
                <TextInput
                  disabled
                  type="text"
                  value={publicLink}
                  className="w-full bg-gray-100"
                />

                <CopyClipboard text={publicLink} />
              </div>
            </>
          )}
        </div>
      )}
    </DialogForm>
  )
}

export default TaskPublicAccessDialog
