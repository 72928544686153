import AssetAddEditMeterForm from "@components/asset/detail/meter/create-and-edit-meter-form"
import NewAddEditMeterForm from "@components/asset/detail/meter/new-create-and-edit-meter-form"
import { DataViewTabs as NewDataViewTabs } from "@components/new-data-view/data-view-tabs"
import { Button, Icons } from "@components/shared"
import { DataViewTabs } from "@components/shared/data-view/data-view-tabs"
import { useFeature } from "@contexts/feature-flag-context"
import { IViewDataTypeEnum } from "@elara/db"
import { useDisclosure } from "@hooks"
import i18n from "@i18n"

const MetersPage = () => {
  const meterModal = useDisclosure()
  const hasMaintenanceFeature = useFeature("maintenance")
  const hasDataViewFeature = useFeature("data_view")

  return (
    <>
      {hasDataViewFeature ? (
        <NewDataViewTabs
          dataType={IViewDataTypeEnum.Meter}
          title={i18n.t("common:meter", { count: 2 })}
          action={
            <Button onClick={meterModal.onOpen} icon={Icons.AddMeter}>
              {i18n.t("meters:actions.new_meter")}
            </Button>
          }
        />
      ) : (
        <DataViewTabs
          dataType={IViewDataTypeEnum.Meter}
          title={i18n.t("common:meter", { count: 2 })}
          action={
            <Button onClick={meterModal.onOpen} icon={Icons.AddMeter}>
              {i18n.t("meters:actions.new_meter")}
            </Button>
          }
        />
      )}

      {hasMaintenanceFeature ? (
        <NewAddEditMeterForm
          isOpen={meterModal.isOpen}
          onOpenChange={meterModal.changeOpen}
        />
      ) : (
        <AssetAddEditMeterForm
          isOpen={meterModal.isOpen}
          onOpenChange={meterModal.changeOpen}
        />
      )}
    </>
  )
}

export default MetersPage
