import SettingsPageLayout from "@components/settings/page-layout"
import Button from "@components/shared/button"
import { TeamCreateFormDialog } from "@components/shared/team-create-form-dialog"
import { IconData, TeamIcon } from "@components/shared/team-icons"
import { useUser } from "@contexts/user-context"
import { IPermissionScopeEnum } from "@elara/db"
import { orderBy } from "@elara/select"
import { useTeamsQuery } from "@graphql/documents/team.generated"
import { useDisclosure } from "@hooks"
import i18n from "@i18n"
import { LockSimple, PencilSimpleLine } from "@phosphor-icons/react"
import React from "react"
import { Link } from "react-router-dom"

const SettingsTeams = () => {
  const user = useUser()

  const isAdmin = user.allowedScopes.some(
    (s) => s.scope === IPermissionScopeEnum.AppAccountManagement
  )
  const [teamsRes] = useTeamsQuery({ requestPolicy: "cache-first" })

  const teams = orderBy(
    (teamsRes.data?.team ?? []).filter((t) => {
      if (t.archived_at) return false
      if (t.private) return isAdmin || t.members.some((m) => m.user_id === user.id)
      return true
    }),
    { name: "asc" }
  )
  const teamCreateDialog = useDisclosure()

  return (
    <SettingsPageLayout
      title={i18n.t("common:team", { count: 2 })}
      description={i18n.t("settings:teams.description")}
      buttonProps={{ onClick: teamCreateDialog.onOpen, disabled: !isAdmin }}
      buttonText={i18n.t("teams:actions.new_team")}>
      <div className="flex flex-col">
        {teams.map((t) => {
          const icon = t.icon as IconData

          return (
            <React.Fragment key={t.id}>
              <Link
                to={`/settings/team/${t.id}`}
                key={t.id}
                className="flex items-center rounded-lg px-1.5 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-700">
                <TeamIcon
                  name={icon?.name}
                  color={icon?.color}
                  className="mr-2 shrink-0 text-base"
                />
                <span className="min-w-0 truncate font-medium">{t.name}</span>
                {t.private && (
                  <LockSimple size={14} className=" ml-1 inline-block text-gray-500" />
                )}
                <span className="ml-2 flex-1 whitespace-nowrap text-gray-500">
                  {t.members.length} {i18n.t("common:member", { count: t.members.length })}
                </span>
                <Button
                  color="gray"
                  type="tertiary"
                  size="extra-small"
                  icon={PencilSimpleLine}>
                  {i18n.t("edit")}
                </Button>
              </Link>
              <hr className="my-2 border-gray-100 last:hidden" />
            </React.Fragment>
          )
        })}
      </div>

      <TeamCreateFormDialog
        title={i18n.t("common:create_token", {
          token: i18n.t("common:team", { count: 1 }),
        })}
        isOpen={teamCreateDialog.isOpen}
        onOpenChange={teamCreateDialog.changeOpen}
      />
    </SettingsPageLayout>
  )
}

export default SettingsTeams
