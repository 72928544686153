import { SelectPopover, SelectTrigger } from "@components/shared/single-select"
import { IconSpacer } from "@components/shared/spacers"
import toast from "@components/shared/toast"
import { IPermissionScopeEnum, IWorkOrderStatusEnum, ProgressInfo } from "@elara/db"
import { IWorkOrderDataViewFragment } from "@graphql/documents/fragments.generated"
import { useUpdateWorkOrderPartiallyMutation } from "@graphql/documents/work-order.generated"
import { usePermissionScope } from "@hooks"
import i18n from "@i18n"
import Icons from "@resources/icons"
import classNames from "classnames"
import React from "react"

export function isCompleted(status: IWorkOrderStatusEnum) {
  switch (status) {
    case IWorkOrderStatusEnum.Done:
    case IWorkOrderStatusEnum.Canceled:
      return true
    default:
      return false
  }
}

export function statusOrderValue(status: IWorkOrderStatusEnum) {
  switch (status) {
    case IWorkOrderStatusEnum.ReadyForApproval:
      return 0
    case IWorkOrderStatusEnum.InProgress:
      return 1
    case IWorkOrderStatusEnum.OnHold:
      return 2
    case IWorkOrderStatusEnum.Open:
      return 3
    case IWorkOrderStatusEnum.Planned:
      return 4
    case IWorkOrderStatusEnum.Done:
      return 5
    case IWorkOrderStatusEnum.Canceled:
      return 6
    default:
      return 7
  }
}
export function statusIcon(status: IWorkOrderStatusEnum) {
  switch (status) {
    case IWorkOrderStatusEnum.Planned:
      return <Icons.ProgressPlanned className="text-gray-500" />
    case IWorkOrderStatusEnum.Open:
      return <Icons.ProgressOpen className="text-gray-500" />
    case IWorkOrderStatusEnum.Done:
      return <Icons.ProgressDone className="text-green-500" />
    case IWorkOrderStatusEnum.InProgress:
      return <Icons.ProgressInProgress className="text-blue-500" />
    case IWorkOrderStatusEnum.OnHold:
      return <Icons.OnHold className="text-orange-400" />
    case IWorkOrderStatusEnum.Canceled:
      return <Icons.Rejected className="text-gray-500" />
    case IWorkOrderStatusEnum.ReadyForApproval:
      return <Icons.ProgressReady className="text-teal-500" />
    default:
      // case IWorkOrderStatusEnum.Inbox:
      return ""
  }
}

export function translateWorkOrderStatus(status: IWorkOrderStatusEnum | null) {
  switch (status) {
    case IWorkOrderStatusEnum.Done:
      return i18n.t("tasks:status.done")
    case IWorkOrderStatusEnum.InProgress:
      return i18n.t("tasks:status.in-progress")
    case IWorkOrderStatusEnum.OnHold:
      return i18n.t("tasks:status.on-hold")
    case IWorkOrderStatusEnum.Canceled:
      return i18n.t("tasks:status.cancelled")
    case IWorkOrderStatusEnum.ReadyForApproval:
      return i18n.t("tasks:status.in-review")
    case IWorkOrderStatusEnum.Planned:
      return i18n.t("tasks:status.planned")
    case IWorkOrderStatusEnum.Open:
      return i18n.t("tasks:status.open")
    default:
      return ""
  }
}

type WorkOrderStatusTagProps = {
  status: IWorkOrderStatusEnum
  iconSpacing?: number
  short?: boolean
  className?: string
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

export const WorkOrderStatusTag = ({
  status,
  short,
  className,
  iconSpacing,
  onClick,
}: WorkOrderStatusTagProps) => {
  return short ? (
    <div
      style={{ height: 20, width: 20 }}
      className={classNames(
        className,
        "inline-block text-base rounded text-gray-500 px-[2.1px] pt-0.5"
      )}
      onClick={onClick}>
      {statusIcon(status)}
    </div>
  ) : (
    <div className={classNames(className, "inline-flex items-center")} onClick={onClick}>
      <div className="text-base" style={{ marginRight: iconSpacing ?? 8 }}>
        {statusIcon(status)}
      </div>
      <span className="truncate">{translateWorkOrderStatus(status)}</span>
    </div>
  )
}

export const WorkOrderStatusTagWithDropdown = ({
  workOrder,
  ...props
}: WorkOrderStatusTagProps & { workOrder: IWorkOrderDataViewFragment }) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}>
      <WorkOrderStatusDropdown workOrder={workOrder} short={props.short} />
    </div>
  )
}

type WorkOrderStatusDropdownProps = {
  workOrder: Pick<IWorkOrderDataViewFragment, "id" | "status"> &
    Partial<IWorkOrderDataViewFragment>
  isOpen?: boolean
  onOpenChange?: (isOpen: boolean) => void
  short?: boolean
}

export const WorkOrderStatusDropdown = (props: WorkOrderStatusDropdownProps) => {
  const dataEntryScope = usePermissionScope(IPermissionScopeEnum.AppDataEntry)
  const approvalScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderApproval)
  const [, updateWorkOrderPartiallyMutation] = useUpdateWorkOrderPartiallyMutation()

  const { id: workOrderId, status: currentStatus } = props.workOrder
  const hasAnyScope = approvalScope.hasScope || dataEntryScope.hasScope
  if (!hasAnyScope) return <WorkOrderStatusTag status={currentStatus} />

  const onChange = async (status: IWorkOrderStatusEnum) => {
    const context = approvalScope.hasScope
      ? approvalScope.context()
      : dataEntryScope.context()
    if (
      status === IWorkOrderStatusEnum.Done &&
      props.workOrder?.block_groups?.some(
        (g) =>
          (g.progress_info as ProgressInfo | null)?.total_required !==
          (g.progress_info as ProgressInfo | null)?.total_required_with_response
      )
    ) {
      toast.error({
        title: "Checklisten-Schritt fehlt",
        body: "Sie können einen Aufgabe nicht abschließen, wenn ein erforderlicher Checklisten-Schritt fehlt.",
        params: {
          duration: 5000,
          position: "top-center",
        },
      })
      return
    }

    const result = await updateWorkOrderPartiallyMutation(
      {
        id: workOrderId,
        changes: {
          status,
        },
      },
      context
    )
    if (result.error) {
      toast.error("Auftragsstatus konnte nicht geändert werden.")
    }
  }

  const statusValues = [
    IWorkOrderStatusEnum.Planned,
    IWorkOrderStatusEnum.Open,
    IWorkOrderStatusEnum.InProgress,
    IWorkOrderStatusEnum.ReadyForApproval,
  ]
  if (approvalScope.hasScope) statusValues.push(IWorkOrderStatusEnum.Done)
  statusValues.push(IWorkOrderStatusEnum.OnHold, IWorkOrderStatusEnum.Canceled)

  return (
    <SelectPopover
      items={
        statusValues.map((value) => ({
          searchValue: value,
          label: <span>{translateWorkOrderStatus(value)}</span>,
          icon: statusIcon(value),
          value,
        })) ?? []
      }
      value={props.workOrder.status}
      onChange={(key) => onChange(key as IWorkOrderStatusEnum)}
      isClearable={false}
      popoverContentProps={{
        align: "start",
        side: "left",
        sideOffset: 8,
        alignOffset: -8,
      }}
      listMaxHeight={300}
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}>
      <SelectTrigger
        className={classNames(
          "flex items-center min-h-[32px] flex-1 font-medium rounded-lg cursor-pointer border border-transparent hover:border-gray-300 text-gray-700",
          { "!p-0 !ring-0 !ring-offset-0 ": props.short }
        )}>
        {props.short ? (
          <WorkOrderStatusTag status={props.workOrder.status} short />
        ) : (
          <IconSpacer preset="select" icon={statusIcon(props.workOrder.status)}>
            <span className="truncate">
              {translateWorkOrderStatus(props.workOrder.status)}
            </span>
          </IconSpacer>
        )}
      </SelectTrigger>
    </SelectPopover>
  )
}

export default WorkOrderStatusDropdown
