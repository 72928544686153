import { DialogForm } from "@components/shared/dialog-form"
import { FormField } from "@components/shared/form/form-field"
import { DateTimeNative } from "@components/shared/native-date-time-pickers"
import { TextArea } from "@components/shared/text-area"
import { TextInput } from "@components/shared/text-input"
import { TextInputWithUnit } from "@components/shared/text-input-with-unit"
import toast from "@components/shared/toast"
import Toggle from "@components/shared/toggle"
import {
  ToggleTabs,
  ToggleTabsContent,
  ToggleTabsList,
  ToggleTabsTrigger,
} from "@components/shared/toggle-tabs"
import { openModal } from "@contexts/modal-context"
import { uuid } from "@elara/db"
import {
  IUpsertAssetStateLogMutation,
  IUpsertAssetStateLogMutationVariables,
  UpsertAssetStateLogDocument,
} from "@graphql/documents/asset-state.generated"
import { IAssetStateLogFragment } from "@graphql/documents/fragments.generated"
import { WorkOrderByNumberDocument } from "@graphql/documents/work-order.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import {
  addHours,
  addMinutes,
  differenceInHours,
  differenceInMinutes,
  intervalToDuration,
  isAfter,
  startOfMinute,
} from "date-fns"
import { FormikProps } from "formik"
import { PropsWithChildren, ReactNode, useEffect, useMemo, useState } from "react"
import { useClient } from "urql"
import * as yup from "yup"

import { AssetStateVariantSelect } from "./asset-state-variant-select"

const ASSET_STATE_CHANGE_EVENT = "event:asset-state-change"

export function useAssetStateChangeListener(cb: (assetId: uuid) => void) {
  useEffect(() => {
    const handleAssetStateChange = (e: Event) => {
      if (e instanceof CustomEvent) {
        cb(e.detail.assetId)
      }
    }
    window.addEventListener(ASSET_STATE_CHANGE_EVENT, handleAssetStateChange)

    return () => {
      window.removeEventListener(ASSET_STATE_CHANGE_EVENT, handleAssetStateChange)
    }
  }, [])
}

type Fields = {
  asset_state_variant_id: uuid | null
  note: string | null
  started_at: Date
  ended_at: Date | null
  work_order_id: uuid | null
}

export function AssetStateFormContent<P extends Fields = Fields>(props: {
  withEndedAt?: boolean
  formik: FormikProps<P>
  assetId: uuid
  workOrderId?: string | null
}) {
  const [endedAtVariant, setEndedAtVariant] = useState("duration")
  const client = useClient()

  return (
    <div>
      <FormField
        name="asset_state_variant_id"
        label={i18n.t("assets:state.fields.variant")}>
        {({ field, helpers }) => (
          <AssetStateVariantSelect
            isClearable={false}
            value={field.value}
            onChange={(value) => {
              helpers.setValue(value)
            }}
          />
        )}
      </FormField>
      <FormField name="note" label={i18n.t("assets:state.fields.note")} optional>
        <TextArea name="note" />
      </FormField>
      <FormField label={i18n.t("assets:state.fields.started_at")} name="started_at">
        {({ field, helpers }) => (
          <DateTimeNative
            {...field}
            value={field.value}
            onChange={(val) => {
              if (val && endedAtVariant === "duration" && props.formik.values.ended_at) {
                const minutes = differenceInMinutes(
                  props.formik.values.ended_at,
                  props.formik.values.started_at
                )
                props.formik.setFieldValue("ended_at", addMinutes(val, minutes))
              }
              helpers.setValue(val)
            }}
            required
          />
        )}
      </FormField>

      {props.withEndedAt && (
        <ToggleTabs
          value={endedAtVariant}
          defaultValue="duration"
          onValueChange={(v) => {
            setEndedAtVariant(v)
            if (v === "ended_at" && !props.formik.values.ended_at) {
              props.formik.setFieldValue("ended_at", props.formik.values.started_at)
            }
          }}>
          <ToggleTabsList>
            <ToggleTabsTrigger value="duration">
              {i18n.t("assets:state.fields.duration")}
            </ToggleTabsTrigger>
            <ToggleTabsTrigger value="ended_at">
              {i18n.t("assets:state.fields.ended_at")}
            </ToggleTabsTrigger>
          </ToggleTabsList>
          <ToggleTabsContent
            value="duration"
            className="grid h-12 grid-cols-2 gap-x-3 pt-3">
            <div>
              <TextInputWithUnit
                unit={i18n.t("calendar:tokens.hour_other")}
                type="number"
                min="0"
                value={
                  props.formik.values.ended_at
                    ? differenceInHours(
                        props.formik.values.ended_at,
                        props.formik.values.started_at
                      )
                    : ""
                }
                onChange={(e) => {
                  let newHours = e.target.valueAsNumber
                  if (isNaN(newHours)) {
                    newHours = 0
                  }
                  if (props.formik.values.ended_at) {
                    const existingHours = differenceInHours(
                      props.formik.values.ended_at,
                      props.formik.values.started_at
                    )

                    props.formik.setFieldValue(
                      "ended_at",
                      addHours(
                        props.formik.values.ended_at,
                        newHours - (existingHours ?? 0)
                      )
                    )
                  } else {
                    props.formik.setFieldValue(
                      "ended_at",
                      addHours(props.formik.values.started_at, newHours)
                    )
                  }
                }}
              />
            </div>

            <TextInputWithUnit
              type="number"
              min="0"
              max="59"
              unit={i18n.t("calendar:tokens.minute_other")}
              value={
                props.formik.values.ended_at
                  ? intervalToDuration({
                      start: props.formik.values.started_at,
                      end: props.formik.values.ended_at,
                    }).minutes
                  : ""
              }
              onChange={(e) => {
                let newMinutes = e.target.valueAsNumber
                if (isNaN(newMinutes)) {
                  newMinutes = 0
                }
                if (props.formik.values.ended_at) {
                  const existingMinutes =
                    intervalToDuration({
                      start: props.formik.values.started_at,
                      end: props.formik.values.ended_at,
                    }).minutes ?? 0

                  props.formik.setFieldValue(
                    "ended_at",
                    addMinutes(props.formik.values.ended_at, newMinutes - existingMinutes)
                  )
                } else {
                  props.formik.setFieldValue(
                    "ended_at",
                    addMinutes(props.formik.values.started_at, newMinutes)
                  )
                }
              }}
            />
          </ToggleTabsContent>
          <ToggleTabsContent value="ended_at" className="h-12 pt-3">
            <FormField noStyle name="ended_at">
              {({ field, helpers }) => (
                <DateTimeNative
                  {...field}
                  required
                  value={field.value}
                  onChange={(val) => helpers.setValue(val)}
                />
              )}
            </FormField>
          </ToggleTabsContent>
          {typeof props.formik.errors.ended_at === "string" && (
            <div className="mt-1 text-xs text-red-500">{props.formik.errors.ended_at}</div>
          )}
        </ToggleTabs>
      )}
      {!props.workOrderId && (
        <FormField
          name="work_order_id"
          label={i18n.t("assets:state.fields.work_order_id")}
          optional>
          {({ helpers }) => (
            <TextInput
              onChange={async (e) => {
                const workOrderNumber = e.target.value.trim()
                if (!workOrderNumber) {
                  helpers.setValue(null)
                  return
                }
                const { data } = await client
                  .query(WorkOrderByNumberDocument, {
                    work_order_nr: workOrderNumber,
                    asset_id: props.assetId,
                  })
                  .toPromise()
                helpers.setValue(data?.work_order[0]?.id ?? workOrderNumber)
              }}
            />
          )}
        </FormField>
      )}
    </div>
  )
}

export const changeAssetStatusSchema = (endedAtRequired: boolean) =>
  yup.object().shape({
    asset_state_variant_id: yup
      .string()
      .uuid()
      .nullable()
      .required(i18n.t("tasks:checklist.state_change.messages.no_variant_selected")),
    started_at: yup.date().required(),
    ended_at: endedAtRequired
      ? yup
          .date()
          .required()
          .test(
            "after start",
            i18n.t("assets:state.change_state_dialog.ended_at_cannot_be_after_started_at"),
            (value, ctx) => {
              if (value && ctx.parent.withEndedAt) {
                return isAfter(value, ctx.parent.started_at)
              }
              return true
            }
          )
      : yup.date().nullable(),
    work_order_id: yup
      .string()
      //UUID is assigned based on work_order_number in work_order_id field's onChange
      .uuid(i18n.t("assets:state.change_state_dialog.must_be_existing_work_order_number"))
      .nullable(),
  })

export function AssetStateFormDialog(
  props: PropsWithChildren<{
    assetId: uuid
    isOpen?: boolean
    onOpenChange?: (open: boolean) => void
    withEndedAt?: boolean
    onSubmit?: () => void
    state?: IAssetStateLogFragment
    additionalContent?: ReactNode
    followUpOption?: boolean
  }>
) {
  const client = useClient()
  const scope = usePermissionScope(IPermissionScopeEnum.AppDataEntry)
  const validationSchema = useMemo(
    () => changeAssetStatusSchema(!!props.withEndedAt),
    [props.withEndedAt]
  )
  return (
    <DialogForm
      {...props}
      title={
        props.withEndedAt
          ? i18n.t("assets:state.add_downtime_dialog.title")
          : i18n.t("assets:state.change_state_dialog.title")
      }
      contentProps={{
        position: "top",
        positionOffset: 96,
      }}
      trigger={props.children}
      closeIcon
      formikConfig={{
        validationSchema: validationSchema,
        initialValues: {
          followUp: false,
          note: props.state?.note ?? "",
          withEndedAt: props.withEndedAt ?? false,
          started_at: props.state?.started_at
            ? new Date(props.state.started_at)
            : startOfMinute(new Date()),
          ended_at: props.state?.ended_at
            ? new Date(props.state.ended_at)
            : startOfMinute(new Date()),
          asset_state_variant_id: props.state?.asset_state_variant_id ?? null,
          work_order_id: props.state?.work_order_id ?? "",
        },
        onSubmit: async (values) => {
          const res = await client
            .mutation<IUpsertAssetStateLogMutation, IUpsertAssetStateLogMutationVariables>(
              UpsertAssetStateLogDocument,
              {
                data: {
                  id: props.state?.id ?? undefined,
                  asset_id: props.assetId,
                  note: values.note || null,
                  asset_state_variant_id: values.asset_state_variant_id,
                  started_at: values.started_at.toISOString(),
                  ended_at: props.withEndedAt ? values.ended_at?.toISOString() : null,
                  work_order_id: values.work_order_id || null,
                },
              },
              scope.context()
            )
            .toPromise()

          if (res.error) {
            toast.error(i18n.t("common:messages.create_failure"))
            throw new Error("err")
          } else {
            toast.success(
              props.withEndedAt
                ? i18n.t("assets:state.add_downtime_dialog.success_message")
                : i18n.t("assets:state.change_state_dialog.success_message")
            )
            props.onSubmit?.()
            window.dispatchEvent(
              new CustomEvent(ASSET_STATE_CHANGE_EVENT, { detail: props.assetId })
            )

            if (values.followUp) {
              openModal("select_template", {
                initialTaskValues: { asset_ids: [props.assetId] },
              })
            }
          }
        },
      }}>
      {(formik) => {
        return (
          <div className="py-4">
            <AssetStateFormContent
              formik={formik}
              withEndedAt={props.withEndedAt}
              assetId={props.assetId}
            />
            {props.additionalContent}
            {props.followUpOption && (
              <div>
                <label className="mt-2 flex items-center gap-3 self-start">
                  <FormField noStyle name="followUp">
                    {({ field, helpers }) => (
                      <Toggle
                        checked={field.value}
                        onChange={(e) => helpers.setValue(e.target.checked)}
                      />
                    )}
                  </FormField>
                  <p className="text-sm font-medium text-gray-700">
                    {i18n.t("assets:labels.create_followup_task")}
                  </p>
                </label>
              </div>
            )}
          </div>
        )
      }}
    </DialogForm>
  )
}

export default AssetStateFormDialog
