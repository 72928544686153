import { Button, useConfirmModal } from "@components/shared"
import { DialogRootProps } from "@components/shared/dialog"
import { DialogForm } from "@components/shared/dialog-form"
import toast from "@components/shared/toast"
import { InitialFormValuesInput } from "@components/work-order/create-task-form.hooks"
import {
  IMaintenanceTriggerTypeEnum,
  IPermissionScopeEnum,
  IWorkOrderInsertInput,
  IWorkOrderTypeEnum,
  TimeIntervalTriggerOptions,
} from "@elara/db"
import { IMaintenanceFragment } from "@graphql/documents/fragments.generated"
import {
  useDeleteMaintenanceMutation,
  useUpsertMaintenanceMutation,
} from "@graphql/documents/maintenance.generated"
import { usePermissionScope } from "@hooks/use-permission-scope"
import i18n from "@i18n"
import { PropsWithChildren } from "react"
import { useNavigate } from "react-router-dom"
import { toFormikValidationSchema } from "zod-formik-adapter"

import { MaintenanceInnerForm } from "../components/maintenance-inner-form"
import { MaintenanceSchema } from "../types"
import { prepareTimeIntervalTriggerPayload } from "../utils"

type FormValues = Partial<IMaintenanceFragment>

type Props = PropsWithChildren<{
  initialValues?: FormValues
  initialTaskValues?: InitialFormValuesInput
}> &
  Pick<DialogRootProps, "isOpen" | "onOpenChange">

const CreateEditMaintenanceFormDialog: React.FC<Props> = (props) => {
  const navigate = useNavigate()

  const [, upsertMaintenance] = useUpsertMaintenanceMutation()
  const [, deleteMaintenance] = useDeleteMaintenanceMutation()
  const workOrderCreateScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderCreate)
  const workOrderDeleteScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderDelete)

  const deleteModal = useConfirmModal({
    title: i18n.t("common:delete_token", {
      token: i18n.t("common:maintenance", { count: 1 }),
    }),
    content: i18n.t("contacts:dialogs.delete.content"),
    okText: i18n.t("common:delete"),
    cancelText: i18n.t("common:cancel"),
    onOk: async () => {
      if (props.initialValues && props.initialValues.id) {
        await deleteMaintenance(
          { id: props.initialValues.id },
          workOrderDeleteScope.context()
        )
        props.onOpenChange?.(false)
      }
    },
  })

  const onSubmit = async (values: FormValues) => {
    const triggersInput = (values.triggers ?? []).map((trigger) => {
      if (trigger.type === IMaintenanceTriggerTypeEnum.Time) {
        trigger.payload = prepareTimeIntervalTriggerPayload(
          trigger.payload as TimeIntervalTriggerOptions["payload"]
        )
      }

      return {
        ...trigger,
        maintenance_id: undefined,
        meter_last_reading: undefined,
        meter_id: undefined,
        meter: undefined,
      }
    })

    // Prepare Initial Task Values
    let templateTaskInput: IWorkOrderInsertInput = {
      name: values.name,
      description: values.description,
      type: IWorkOrderTypeEnum.Template,
    }

    if (props.initialTaskValues?.asset_ids) {
      templateTaskInput.assets = {
        data: props.initialTaskValues.asset_ids.map((asset_id) => ({ asset_id })),
      }
    } else if (values.triggers?.some((t) => t.type === IMaintenanceTriggerTypeEnum.Meter)) {
      const assetId = values.triggers?.find(
        (t) => t.type === IMaintenanceTriggerTypeEnum.Meter
        // Our typings are not correct. assetId is set in the form
        // @ts-ignore-expect-error
      )?.payload?.assetId
      if (assetId) {
        templateTaskInput.assets = { data: [{ asset_id: assetId }] }
      }
    }

    const maintenanceRes = await upsertMaintenance(
      {
        data: {
          id: values.id ?? undefined,
          name: values.name,
          description: values.description,
          create_individual_tasks_for_objects: values.create_individual_tasks_for_objects,
          template: { data: templateTaskInput },
          triggers: { data: triggersInput },
        },
      },
      workOrderCreateScope.context()
    )

    const maintenance = maintenanceRes.data?.insert_maintenance_one

    if (maintenance) {
      toast.success(i18n.t("maintenance:messages.create_success"))

      navigate(`/maintenance/${maintenance.id}/details`)
    } else {
      toast.error(i18n.t("generic_toast_error"))
    }
  }

  const onDelete = () => deleteModal.show()

  return (
    <>
      <DialogForm<FormValues>
        onOpenChange={props.onOpenChange}
        isOpen={props.isOpen}
        trigger={props.children}
        closeIcon
        position="top"
        positionOffset={40}
        contentProps={{ className: "!max-w-lg" }}
        footerActions={
          props.initialValues?.id ? (
            <Button
              color="red"
              type="tertiary"
              className="mr-auto"
              onClick={onDelete}
              disabled={!workOrderDeleteScope.hasScope}>
              {i18n.t("common:delete")}
            </Button>
          ) : null
        }
        title={
          props.initialValues?.id
            ? i18n.t("common:edit_token", { token: i18n.t("maintenance:fields.schedule") })
            : i18n.t("common:create_token", {
                token: i18n.t("maintenance:fields.schedule"),
              })
        }
        formikConfig={{
          onSubmit,
          initialValues: props.initialValues ?? {
            name: "",
            triggers: [],
            create_individual_tasks_for_objects: false,
          },
          validationSchema: toFormikValidationSchema(MaintenanceSchema),
        }}>
        {() => <MaintenanceInnerForm handleDelete={(_, cb) => cb()} />}
      </DialogForm>

      {deleteModal.component}
    </>
  )
}

export default CreateEditMaintenanceFormDialog
