import { Button } from "@components/shared"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@components/shared/dropdown"
import LoadingSpinner from "@components/shared/loading-spinner"
import toast from "@components/shared/toast"
import BlockGroup from "@components/work-order/block/block-group"
import BlockGroupForm from "@components/work-order/block/block-group-form"
import { BlockElementFragment } from "@components/work-order/block/elements/block-element-types"
import { IBlockGroupTypeEnum } from "@elara/db"
import { IWorkOrderFragment } from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import { EmptySection } from "@pages/new-task.$id.report/empty"
import { Copy, DotsThreeVertical, ListChecks, PencilSimple, X } from "@phosphor-icons/react"
import { useState } from "react"
import { useOutletContext } from "react-router-dom"

import { formatObjectToShortcode } from "../../components/work-order/block/shortcode/to-shortcode"

export default function TaskIdChecklist() {
  const { task } = useOutletContext<{ task: IWorkOrderFragment | null }>()
  const [edit, setEdit] = useState(false)

  if (!task) return <LoadingSpinner />

  const checklist = task.block_groups.find(
    (g) => g.group_type === IBlockGroupTypeEnum.Procedure
  )
  if ((!checklist || checklist.elements.length === 0) && !edit) {
    return (
      <div className="p-3">
        <div className="mx-auto max-w-screen-md ">
          <EmptySection
            title={i18n.t("tasks:checklist.empty.title")}
            message={i18n.t("tasks:checklist.empty.message")}
            onAction={() => setEdit(true)}
            actionText={i18n.t("tasks:checklist.empty.actionText")}
            icon={ListChecks}
          />
        </div>
      </div>
    )
  }
  const elements = (checklist?.elements ?? []) as BlockElementFragment[]

  const onCopyCode = () => {
    const codes = []

    for (const element of elements) {
      const object = { element_type: element.element_type, config: element.config }
      codes.push(formatObjectToShortcode(object))
    }

    navigator.clipboard.writeText(codes.join("\n"))

    toast.success(i18n.t("tasks:checklist.shortcodes.messages.copy_checklist_success"))
  }

  return (
    <div className="grow bg-gray-50 px-3 pb-8 print:bg-white">
      <div className="mx-auto max-w-screen-md pt-3">
        <div className="mb-3 flex items-center justify-between print:hidden">
          <h2 className="font-semibold">{i18n.t("tasks:fields.checklist")}</h2>
          <div className="flex items-center">
            <Button
              type="secondary"
              icon={edit ? X : PencilSimple}
              onClick={() => setEdit((e) => !e)}>
              {edit ? i18n.t("discard") : i18n.t("edit")}
            </Button>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button type="tertiary" icon={() => <DotsThreeVertical />} />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem onClick={onCopyCode}>
                  <Copy className="mr-2" />
                  {i18n.t("tasks:checklist:shortcodes:copy_code_action")}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
        {edit ? (
          <>
            <BlockGroupForm
              workOrderId={task.id}
              initialElements={elements}
              groupId={checklist?.id}
              groupType={IBlockGroupTypeEnum.Procedure}
              hideSubmitButton
              onSubmit={() => setEdit(false)}
            />
            <div className="sticky bottom-0 -mx-3 flex justify-end bg-gray-50/80 px-6 py-3 backdrop-blur-sm">
              <Button htmlType="submit" form="block-group">
                {i18n.t("common:save")}
              </Button>
            </div>
          </>
        ) : (
          <BlockGroup
            elements={elements}
            blockResponseUpdate={
              false
              // workOrder.type === IWorkOrderTypeEnum.Template ||
              // workOrder.status === IWorkOrderStatusEnum.Done
            }
          />
        )}
      </div>
    </div>
  )
}
