interface Navigator {
  userAgentData?: {
    platform?: string
  }
}

export enum OperatingSystem {
  Mac = "Mac",
  Linux = "Linux",
  Windows = "Win",
}

type UseOperatingSystemResult = OperatingSystem | "Unknown"

const useOperatingSystem = (): UseOperatingSystemResult => {
  const platform = (
    (navigator as Navigator)?.userAgentData?.platform ||
    navigator?.platform ||
    ""
  ).toLowerCase()

  if (platform.includes("win")) return OperatingSystem.Windows
  if (platform.includes("mac")) return OperatingSystem.Mac
  if (platform.includes("linux")) return OperatingSystem.Linux

  return "Unknown"
}

export default useOperatingSystem
