import useSWR from "swr"
import { z } from "zod"

import { useSubdomain } from "./use-subdomain"

const SSOInfoSchema = z.object({
  idp: z.string(),
  orgName: z.string(),
  oneSignalAppId: z.string(),
})
type SSOInfo = z.infer<typeof SSOInfoSchema>

async function fetchSSOInfo(subdomain: string): Promise<SSOInfo | null> {
  const res = await fetch(`/api/auth/sso/${subdomain}`)
  if (!res.ok) throw new Error("Could not fetch SSO info")
  const data = await res.json()
  return SSOInfoSchema.parse(data)
}

export function useSSOInfo() {
  const subdomain = useSubdomain()
  const query = useSWR(subdomain ? `sso_info/${subdomain}` : null, () =>
    fetchSSOInfo(subdomain!)
  )
  return query
}
