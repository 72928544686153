import { IWorkOrderDataViewFragment } from "@graphql/documents/fragments.generated"
import i18n from "@i18n"
import classNames from "classnames"

import { CategoryColorTag } from "./work-order-category-select"

type WorkOrderCategoryTagsProps = {
  workOrder: IWorkOrderDataViewFragment
  isListView?: boolean
}

export function WorkOrderCategoryTags(props: WorkOrderCategoryTagsProps) {
  if (props.workOrder.categories.length === 0) return null

  return (
    <div
      className={classNames(
        "flex flex-row flex-wrap gap-2 min-w-0 max-h-14 items-center",
        {}
      )}>
      {props.workOrder.categories.slice(0, 3).map((c) => (
        <CategoryColorTag
          {...c.category}
          key={c.work_order_category_id}
          className={classNames(
            "inline-flex min-w-0 overflow-hidden truncate rounded-md border border-gray-100 bg-gray-50 px-1.5 py-1 text-xs text-gray-600"
          )}
        />
      ))}
      {props.workOrder.categories.length > 3 && (
        <span className="tracking-tighter text-gray-500">
          + {props.workOrder.categories.length - 3} {i18n.t("more")}
        </span>
      )}
    </div>
  )
}
