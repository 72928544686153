import { AssetInformationFormDialog } from "@components/asset/asset-information-form-dialog"
import { useAssetManualSection } from "@components/asset/asset-manual-section"
import { Flex } from "@components/layout"
import { Button, DocumentCard } from "@components/shared"
import { SectionHeader } from "@components/shared/section-header"
import { IAssetDetailFragment } from "@graphql/documents/fragments.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { PencilSimpleLine } from "@phosphor-icons/react"
import { isLink, naturalCompare } from "@utils"
import { formatDate } from "@utils/date"
import React, { Fragment, ReactNode } from "react"
import { FileUpload } from "src/types"

type CustomField = {
  name: string
  value: string
}

export type AssetInformationProps = {
  asset: IAssetDetailFragment
}

const AssetInformation = ({ asset }: AssetInformationProps) => {
  const basicInfos: { label: string; value: ReactNode }[] = [
    { label: i18n.t("assets:fields.group", { count: 1 }), value: asset?.group?.name },
    { label: i18n.t("assets:fields.build_year"), value: asset?.year_of_purchase },
    {
      label: i18n.t("assets:fields.operating_since"),
      value: asset?.operating_since
        ? formatDate(new Date(asset.operating_since), "P")
        : null,
    },
    { label: i18n.t("assets:fields.manufacturer"), value: asset?.manufacturer?.name },
    { label: i18n.t("assets:fields.model_number"), value: asset?.model_number },
    { label: i18n.t("assets:fields.serial_number"), value: asset?.serial_number },
  ]

  const customInfos: { label: string; value: ReactNode }[] =
    (asset?.custom_fields as CustomField[] | null)?.map(
      (field: { name: string; value: string }) => ({
        label: field.name,
        value: field.value,
      })
    ) ?? []

  const infos = basicInfos
    .concat(customInfos)
    .filter((i) => !!i.value)
    .sort((a, b) => naturalCompare(a.label, b.label))
    .map((x) => {
      if (typeof x.value === "string" && isLink(x.value)) {
        return {
          ...x,
          value: (
            <a
              href={x.value}
              target="_blank"
              rel="noopener noreferrer"
              className="!underline">
              {x.value}
            </a>
          ),
        }
      }
      return x
    })

  const isEmpty = infos.length === 0 && !asset?.note
  const scope = usePermissionScope(IPermissionScopeEnum.AppAssetEdit)
  const manual = useAssetManualSection(asset)

  return (
    <div className="grid grid-cols-[auto_1fr] gap-2 @mobile/page:grid-cols-[auto_1fr_auto_1fr]">
      <Flex
        justify="space-between"
        align="center"
        row
        className="col-span-2 pb-1 @mobile/page:col-span-4">
        <SectionHeader>{i18n.t("common:property_other")}</SectionHeader>

        {scope.hasScope && (
          <AssetInformationFormDialog asset={asset}>
            <Button type="tertiary" color="gray" icon={PencilSimpleLine} size="small">
              {i18n.t("assets:actions.edit_properties")}
            </Button>
          </AssetInformationFormDialog>
        )}
      </Flex>
      {isEmpty && (
        <p className="text-sm text-gray-600">
          {i18n.t("assets:messages.no_additional_information")}
        </p>
      )}
      {infos.map(({ label, value }, index) => (
        <React.Fragment key={`${label}_${index}`}>
          <label
            className="mr-4 text-sm font-medium text-gray-900"
            style={{ overflowWrap: "anywhere", maxWidth: `${768 / 4}px` }}>
            {label}
          </label>
          <div className="text-sm text-gray-700">{value}</div>
        </React.Fragment>
      ))}
      {asset?.note && (
        <>
          <label
            className="col-start-1 mr-4 text-sm font-medium text-gray-900"
            style={{ overflowWrap: "anywhere" }}>
            {i18n.t("assets:fields.notes")}
          </label>
          <div className="text-sm text-gray-700 @mobile/page:col-span-3">{asset?.note}</div>
        </>
      )}
      {manual.uploads &&
        manual.uploads.length > 0 &&
        manual.uploads.map((upload: FileUpload) => (
          <Fragment key={upload.data.id}>
            <label
              className="col-start-1 mt-2 text-sm font-medium text-gray-900"
              style={{ overflowWrap: "anywhere" }}>
              {i18n.t("assets:fields.operating_manual")}
            </label>
            <DocumentCard
              className="col-span-2 @mobile/page:col-span-4"
              document={upload.data}
              allowEdit={manual.scope?.hasScope}
              onEditFileName={(id, fileName) => {
                manual.onEditManualName?.(id, fileName)
              }}
              allowDelete
              onDelete={(id) => manual.onDeleteManual?.(id)}
            />
          </Fragment>
        ))}
    </div>
  )
}

export default AssetInformation
