import { PopoverAnchor } from "@components/shared/popover"
import TouchTargetSize from "@components/shared/touch-target-size"
import i18n from "@i18n"
import { Plus } from "@phosphor-icons/react"
import { cn } from "@utils"
import classNames from "classnames"
import React from "react"

import {
  DataViewAddFilter,
  DataViewAddFilterContent,
  DataViewAddFilterTrigger,
} from "./add-filter"
import { FilterBadge } from "./badge"
import { useDataViewFilterContext } from "./context"

export const DataViewFilterBadges = (
  props: React.PropsWithChildren<{ className?: string; allowToAddToEmptyList?: boolean }>
) => {
  const dataViewFilter = useDataViewFilterContext()

  if (!dataViewFilter) return null

  const addedFilterFromCurrentOpenMenu =
    //  dataViewFilter.state.addedFilterFromCurrentOpenMenu
    false

  return (
    <DataViewAddFilter>
      <div
        className={classNames(
          "flex flex-row flex-wrap gap-x-2 gap-y-1 items-center",
          props.className
        )}>
        {dataViewFilter.filterStates.map((state, index) => {
          const isLastBadge = index === dataViewFilter.filterStates.length - 1
          const isSecondLastBadge = index === dataViewFilter.filterStates.length - 2
          const showAnchor =
            (isSecondLastBadge && addedFilterFromCurrentOpenMenu) ||
            (isLastBadge && !addedFilterFromCurrentOpenMenu)
          return (
            <div
              key={`${state.id}_${index}`}
              className="flex min-h-[2rem] flex-wrap gap-y-2">
              <FilterBadge
                schema={dataViewFilter.schema}
                state={state}
                onStateChange={(state) => {
                  dataViewFilter.dispatch({ type: "updateFilterState", state, index })
                }}
                onRemove={() =>
                  dataViewFilter.dispatch({ type: "removeFilterState", index })
                }
              />
              {showAnchor && (
                <PopoverAnchor
                  key="anchor"
                  data-id="anchor"
                  className={cn("bg-red-500 self-stretch")}
                />
              )}
              {isLastBadge && (
                <AddMore className="ml-2">
                  {i18n.t("data-view:filter", { count: 1 })}
                </AddMore>
              )}
            </div>
          )
        })}
        {dataViewFilter.filterStates.length === 0 && props.allowToAddToEmptyList && (
          <div className="flex">
            <AddMore>{i18n.t("data-view:filter", { count: 1 })}</AddMore>
          </div>
        )}
      </div>
      <DataViewAddFilterContent
        side="bottom"
        align="start"
        sideOffset={4}
        alignOffset={0}
      />
    </DataViewAddFilter>
  )
}

const AddMore = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<{
    className?: string
  }>
>((props, ref) => (
  <>
    <DataViewAddFilterTrigger
      className={classNames(
        "relative flex items-center justify-center text-gray-600 rounded min-w-[1.75rem] hover:text-gray-800 hover:bg-gray-100 ",

        { "text-sm px-1 -mx-1": !!props.children },
        props.className
      )}
      ref={ref}>
      <TouchTargetSize />
      <Plus className={classNames("shrink-0", props.children ? "mr-1" : "")} />{" "}
      {props.children}
    </DataViewAddFilterTrigger>
  </>
))
