import { HStack, VStack } from "@components/layout"
import { ActionMenu, Button, useConfirmModal } from "@components/shared"
import AlertBox from "@components/shared/alert-box"
import CollapsibleSection from "@components/shared/collapsible-section"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@components/shared/dropdown"
import { FormField } from "@components/shared/form/form-field"
import { RichTextFormField } from "@components/shared/form/rich-text-form-field"
import LoadingSpinner from "@components/shared/loading-spinner"
import ScrollArea from "@components/shared/scroll-area"
import { SectionHeader } from "@components/shared/section-header"
import { StaticRichText } from "@components/shared/static-rich-text"
import { TextInput } from "@components/shared/text-input"
import toast from "@components/shared/toast"
import { Tooltip } from "@components/shared/tooltip"
import BlockGroup from "@components/work-order/block/block-group"
import { BlockElementFragment } from "@components/work-order/block/elements/block-element-types"
import SubtaskForm, { SubtaskFormHandle } from "@components/work-order/subtask/subtask-form"
import SubtaskList from "@components/work-order/subtask/subtask-list"
import { WorkOrderActivitySection } from "@components/work-order/work-order-activity-section"
import WorkOrderCategoryQuickEdit from "@components/work-order/work-order-category-quick-edit"
import { WorkOrderCompletedEditForm } from "@components/work-order/work-order-form-v2/work-order-completed-edit-form"
import { WorkOrderIsAssignedProvider } from "@components/work-order/work-order-is-assigned"
import { WorkOrderQuickAssigneeSelect } from "@components/work-order/work-order-quick-assignee-select"
import { WorkOrderQuickCollaboratorSelect } from "@components/work-order/work-order-quick-collaborator-select"
import { WorkOrderQuickTeamSelect } from "@components/work-order/work-order-quick-team-select"
import {
  WorkOrderStatusDropdown,
  WorkOrderStatusTag,
} from "@components/work-order/work-order-status"
import { useBreakpoint } from "@contexts/breakpoints"
import { useDetectPrint, useTriggerPrint } from "@contexts/detect-print"
import { useFeature } from "@contexts/feature-flag-context"
import {
  IBlockGroupTypeEnum,
  IWorkOrderStatusEnum,
  IWorkOrderTypeEnum,
  MeterReadingTriggerOptions,
  uuid,
} from "@elara/db"
import {
  IWorkOrderDataViewFragment,
  IWorkOrderFragment,
} from "@graphql/documents/fragments.generated"
import { useMaintenanceTriggerTaskQuery } from "@graphql/documents/maintenance.generated"
import {
  IUpdateWorkOrderPartiallyMutation,
  IUpdateWorkOrderPartiallyMutationVariables,
  UpdateWorkOrderPartiallyDocument,
} from "@graphql/documents/work-order.generated"
import {
  IPermissionScopeEnum,
  useDisclosure,
  UseDisclosureReturnType,
  usePermissionScope,
} from "@hooks"
import { useElementSize } from "@hooks/use-element-size"
import i18n from "@i18n"
import {
  Backspace,
  CheckSquareOffset,
  Copy,
  DotsThreeVertical,
  Eye,
  EyeSlash,
  Globe,
  Link as LinkIcon,
  PencilSimpleLine,
  Plus,
  Printer,
  Swap,
  TrashSimple,
} from "@phosphor-icons/react"
import Icons from "@resources/icons"
import { CommandDef, useRegisterCommands } from "@stores/command"
import classNames from "classnames"
import { Form, FormikProvider } from "formik"
import posthog from "posthog-js"
import React, {
  Dispatch,
  ReactNode,
  RefObject,
  SetStateAction,
  useRef,
  useState,
} from "react"
import { Trans } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import { useClient } from "urql"

import BlockGroupForm, { BlockGroupFormHandle } from "./block/block-group-form"
import { formatObjectToShortcode } from "./block/shortcode/to-shortcode"
import WorkOrderDetailDocuments from "./detail/work-order-detail-documents"
import {
  useConvertTaskToTemplate,
  useDeleteWorkOrder,
  useWorkOrderDetail,
} from "./work-order-detail.hooks"
import { ConsumableSection as NewConsumableSection } from "./work-order-detail-consumables"
import { WorkOrderDetailObjectSection } from "./work-order-detail-object-section"
import { WorkOrderDueDatePopover } from "./work-order-due-date-popover"
import { WorkOrderPriorityInfoSelect } from "./work-order-priority-info-select"
import { translateEntryTypes } from "./work-order-type"

type SectionProps = {
  workOrder:
    | ({ detail: "detail" } & IWorkOrderFragment)
    | ({ detail: "data_view" } & IWorkOrderDataViewFragment)
}

const Options = (
  props: SectionProps & {
    isEditing: boolean
    onEdit: () => void
    onDelete?: () => void
    onCopyWorkOrder?: () => void
    onSetSubtaskToTask?: () => void
    onSetParentTask?: () => void
    onCreateShareLink?: () => void
  }
) => {
  const { workOrder } = props

  const bp = useBreakpoint()
  const client = useClient()
  const navigate = useNavigate()
  const hasPublicTaskFeature = useFeature("public_task")

  const convertToTemplate = useConvertTaskToTemplate()
  const isTemplate = workOrder.type === IWorkOrderTypeEnum.Template

  const deleteWorkOrder = useDeleteWorkOrder({
    workOrderId: workOrder.id,
    isTemplate,
    afterDelete: () => {
      if (props.onDelete) {
        props.onDelete()
      } else {
        workOrder.type === IWorkOrderTypeEnum.Template
          ? navigate("/settings/template")
          : navigate(-1)
      }
    },
  })

  const createWorkOrderScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderCreate)
  const deleteWorkOrderScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderDelete)
  const editWorkOrderScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)

  const handlePrint = useTriggerPrint()

  const handleCopyLinkToTask = () => {
    navigator.clipboard.writeText(window.location.origin + "/task/" + workOrder.id)
    toast.success(i18n.t("tasks:messages.link_copied_clipboard"))
  }

  const handleConvertTaskToTemplate = async () => {
    const template = await convertToTemplate(workOrder as IWorkOrderFragment)
    navigate(`/template/${template.id}`)
  }

  const handleSetParentTask = async (parentTaskId: string) => {
    await client
      .mutation<
        IUpdateWorkOrderPartiallyMutation,
        IUpdateWorkOrderPartiallyMutationVariables
      >(
        UpdateWorkOrderPartiallyDocument,
        { id: workOrder?.id!, changes: { parent_work_order_id: parentTaskId } },
        editWorkOrderScope.context()
      )
      .toPromise()
  }

  const handleToggleTaskVisibility = async () => {
    await client
      .mutation<
        IUpdateWorkOrderPartiallyMutation,
        IUpdateWorkOrderPartiallyMutationVariables
      >(
        UpdateWorkOrderPartiallyDocument,
        { id: workOrder.id, changes: { only_assigned: !workOrder.only_assigned } },
        editWorkOrderScope.context()
      )
      .toPromise()
  }

  useRegisterCommands(
    "1_work_order_options",
    [
      ...((editWorkOrderScope.hasScope
        ? [
            {
              type: "action",
              value: "edit_task",
              searchValue: i18n.t("common:edit"),
              action: props.onEdit,
              group: workOrder.name,
              icon: <PencilSimpleLine />,
              description: "Edit task",
            },
          ]
        : []) as CommandDef[]),
      {
        type: "action",
        value: "print_task",
        searchValue: i18n.t("tasks:context_menu.print"),
        action: handlePrint,
        group: workOrder.name,
        icon: <Printer />,
        description: "Print task",
      },
      ...((workOrder.type === IWorkOrderTypeEnum.WorkOrder
        ? [
            {
              type: "action",
              value: "copy_link_to_task",
              searchValue: i18n.t("tasks:context_menu.copy_link"),
              action: handleCopyLinkToTask,
              group: workOrder.name,
              icon: <LinkIcon />,
              description: "Print task",
            },
          ]
        : []) as CommandDef[]),
      ...((createWorkOrderScope.hasScope && workOrder.type === IWorkOrderTypeEnum.WorkOrder
        ? [
            {
              type: "action",
              value: "duplicate_task",
              searchValue: i18n.t("tasks:context_menu.duplicate_task"),
              action: props.onCopyWorkOrder,
              group: workOrder.name,
              icon: <Copy />,
              description: "Duplicate task",
            },
            {
              type: "action",
              value: "convert_to_template",
              searchValue: i18n.t("tasks:context_menu.convert_to_template"),
              action: handleConvertTaskToTemplate,
              group: workOrder.name,
              icon: <Swap />,
              description: "Convert task to template",
            },
            {
              type: "page",
              page: "select_task",
              value: "set_parent_task",
              searchValue: workOrder.parent_task
                ? i18n.t("tasks:context_menu.change_parent_task")
                : i18n.t("tasks:context_menu.set_parent_task"),
              group: workOrder.name,
              icon: <CheckSquareOffset />,
              description: "set/change parent task",
              subcommands: (searchResults) =>
                searchResults
                  .filter((c) => c.value.startsWith("open_task:"))
                  .map((c) => ({
                    ...c,
                    action: async () => {
                      if (c.meta?.task) {
                        await handleSetParentTask(c.meta.task.id)
                      }
                    },
                  })),
            },
          ]
        : []) as CommandDef[]),
      ...((editWorkOrderScope.hasScope && workOrder.parent_task
        ? [
            {
              type: "action",
              value: "remove_parent_task",
              searchValue: i18n.t("tasks:context_menu.remove_parent_task"),
              action: props.onSetSubtaskToTask,
              group: workOrder.name,
              icon: <Backspace />,
              description: "Remove parent task",
            },
          ]
        : []) as CommandDef[]),
      ...((editWorkOrderScope.hasScope && workOrder.type === IWorkOrderTypeEnum.WorkOrder
        ? [
            {
              type: "action",
              value: "mark_task_as_private",
              searchValue: !workOrder.only_assigned
                ? i18n.t("tasks:context_menu.set_private")
                : i18n.t("tasks:context_menu.set_public"),
              action: handleToggleTaskVisibility,
              group: workOrder.name,
              icon: !workOrder.only_assigned ? <EyeSlash /> : <Eye />,
              description: "Toggle task visibility",
            },
          ]
        : []) as CommandDef[]),
      ...((editWorkOrderScope.hasScope &&
      !workOrder.only_assigned &&
      hasPublicTaskFeature &&
      workOrder.type === IWorkOrderTypeEnum.WorkOrder
        ? [
            {
              type: "action",
              value: "public_access",
              searchValue: workOrder.public_view_expiration
                ? i18n.t("tasks:dialogs.public_access.revoke.title")
                : i18n.t("tasks:dialogs.public_access.create.title"),
              action: props.onCreateShareLink,
              group: workOrder.name,
              icon: <Globe />,
              description: "Toggle task visibility for people outside this organization",
            },
          ]
        : []) as CommandDef[]),
      ...((deleteWorkOrderScope.hasScope && workOrder.type === IWorkOrderTypeEnum.WorkOrder
        ? [
            {
              type: "action",
              value: "delete_task",
              searchValue: i18n.t("common:delete"),
              action: deleteWorkOrder.delete,
              group: workOrder.name,
              icon: <TrashSimple />,
              description: "Delete task",
            },
          ]
        : []) as CommandDef[]),
    ],
    [workOrder.last_update]
  )

  const actionMenuItems = [
    editWorkOrderScope.hasScope && bp.mobile
      ? {
          key: "edit",
          icon: <PencilSimpleLine />,
          label: i18n.t("common:edit"),
          action: props.onEdit,
        }
      : null,
    {
      key: "print",
      icon: <Printer />,
      label: i18n.t("tasks:context_menu.print"),
      action: handlePrint,
    },
    workOrder.type === IWorkOrderTypeEnum.WorkOrder
      ? {
          key: "copy_link",
          icon: <LinkIcon />,
          label: i18n.t("tasks:context_menu.copy_link"),
          action: handleCopyLinkToTask,
        }
      : null,
    createWorkOrderScope.hasScope && workOrder.type === IWorkOrderTypeEnum.WorkOrder
      ? {
          key: "duplicate_task",
          icon: <Copy />,
          label: i18n.t("tasks:context_menu.duplicate_task"),
          action: props.onCopyWorkOrder,
        }
      : null,
    createWorkOrderScope.hasScope && workOrder.type === IWorkOrderTypeEnum.WorkOrder
      ? {
          key: "convert_to_template",
          icon: <Swap />,
          label: i18n.t("tasks:context_menu.convert_to_template"),
          action: handleConvertTaskToTemplate,
        }
      : null,
    editWorkOrderScope.hasScope && workOrder.parent_task
      ? {
          key: "remove_parent_task",
          icon: <Backspace />,
          label: i18n.t("tasks:context_menu.remove_parent_task"),
          action: props.onSetSubtaskToTask,
        }
      : null,
    editWorkOrderScope.hasScope && workOrder.type == IWorkOrderTypeEnum.WorkOrder
      ? {
          key: "set_parent_task",
          icon: <CheckSquareOffset />,
          label: workOrder.parent_task
            ? i18n.t("tasks:context_menu.change_parent_task")
            : i18n.t("tasks:context_menu.set_parent_task"),
          action: props.onSetParentTask,
        }
      : null,
    editWorkOrderScope.hasScope && workOrder.type === IWorkOrderTypeEnum.WorkOrder
      ? {
          key: "mark_private",
          icon: workOrder.only_assigned ? (
            <Eye className="h-[1em] w-[1em]" />
          ) : (
            <EyeSlash />
          ),
          label: !workOrder.only_assigned
            ? i18n.t("tasks:context_menu.set_private")
            : i18n.t("tasks:context_menu.set_public"),
          action: handleToggleTaskVisibility,
        }
      : null,
    editWorkOrderScope.hasScope &&
    !workOrder.only_assigned &&
    hasPublicTaskFeature &&
    workOrder.type === IWorkOrderTypeEnum.WorkOrder
      ? {
          key: "public_access",
          icon: <Globe />,
          label: workOrder.public_view_expiration
            ? i18n.t("tasks:dialogs.public_access.revoke.title")
            : i18n.t("tasks:dialogs.public_access.create.title"),
          action: props.onCreateShareLink,
        }
      : null,
    deleteWorkOrderScope.hasScope && workOrder.type === IWorkOrderTypeEnum.WorkOrder
      ? {
          key: "delete",
          icon: <Icons.Delete />,
          label: i18n.t("common:delete"),
          action: deleteWorkOrder.delete,
        }
      : null,
  ]

  if (!actionMenuItems.filter(Boolean).length) return null

  return (
    <>
      <div className="flex justify-end">
        {editWorkOrderScope.hasScope && !bp.mobile && !props.isEditing && (
          <Button
            size="small"
            type="secondary"
            className="!mr-2"
            onClick={props.onEdit}
            icon={() => <PencilSimpleLine className="mr-1" />}>
            {i18n.t("common:edit")}
          </Button>
        )}
        <ActionMenu items={actionMenuItems}>
          <Button size="small" type="secondary" icon={DotsThreeVertical} />
        </ActionMenu>
        {deleteWorkOrder.deleteConfirmModal}
      </div>
    </>
  )
}

const InfoLabel = (props: React.PropsWithChildren<{ className?: string }>) => (
  <div className="flex min-h-[32px] w-full place-items-center">
    <span className={classNames(props.className, "text-sm font-medium text-gray-900")}>
      {props.children}
    </span>
  </div>
)

const InfoValue = (props: React.PropsWithChildren<{ className?: string }>) => (
  <div
    className={classNames(props.className, "w-full min-h-[32px] flex place-items-center")}>
    {props.children}
  </div>
)

const InfoSection = (
  props: SectionProps & {
    editCompletedAt: ReturnType<typeof useDisclosure>
    setEditAutoFocus: Dispatch<SetStateAction<string | undefined>>
    containerWidth?: number
    isInSidebar?: boolean
  }
) => {
  const { workOrder, containerWidth = 0 } = props

  let content: ReactNode = null

  const isTemplate = workOrder.type === IWorkOrderTypeEnum.Template

  if (
    workOrder.type === IWorkOrderTypeEnum.WorkOrder ||
    workOrder.type === IWorkOrderTypeEnum.ReportWork ||
    isTemplate
  ) {
    content = (
      <>
        <InfoLabel>{i18n.t("tasks:fields.status")}</InfoLabel>
        <InfoValue className="w-auto">
          <WorkOrderStatusDropdown workOrder={workOrder} />
        </InfoValue>
        <InfoLabel>{i18n.t("tasks:fields.category", { count: 1 })}</InfoLabel>
        <InfoValue>
          <WorkOrderCategoryQuickEdit
            workOrder={workOrder}
            popoverContentProps={{
              side: "left",
              sideOffset: 8,
              align: "start",
              alignOffset: -8,
            }}
          />
        </InfoValue>

        <InfoLabel>{i18n.t("tasks:fields.priority")}</InfoLabel>
        <InfoValue className="w-auto">
          <WorkOrderPriorityInfoSelect workOrder={workOrder} />
        </InfoValue>
        {!isTemplate && (
          <>
            <InfoLabel>{i18n.t("tasks:fields.due_date")}</InfoLabel>
            <InfoValue>
              <WorkOrderDueDatePopover
                workOrder={workOrder}
                textColor="lighter"
                showRecurrenceInfo
                popoverContentProps={{
                  side: "left",
                  sideOffset: 8,
                  align: "start",
                  alignOffset: -8,
                }}
                className={classNames("font-medium min-h-[32px]", {
                  "text-gray-700": workOrder.due_date,
                  "text-gray-500": !workOrder.due_date,
                })}
              />
            </InfoValue>
          </>
        )}

        <InfoLabel>{i18n.t("common:team", { count: 1 })}</InfoLabel>
        <InfoValue>
          <WorkOrderQuickTeamSelect
            workOrder={workOrder}
            popoverContentProps={{
              side: "left",
              sideOffset: 8,
              align: "start",
              alignOffset: -8,
            }}
          />
        </InfoValue>
        <InfoLabel>{i18n.t("tasks:fields.assignee", { count: 1 })}</InfoLabel>
        <InfoValue>
          <WorkOrderQuickAssigneeSelect
            workOrder={workOrder}
            popoverContentProps={{
              side: "left",
              sideOffset: 8,
              align: "start",
              alignOffset: -8,
            }}
          />
        </InfoValue>
        <InfoLabel>{i18n.t("tasks:fields.collaborator", { count: 1 })}</InfoLabel>
        <InfoValue>
          <WorkOrderQuickCollaboratorSelect
            workOrder={workOrder}
            popoverContentProps={{
              side: "left",
              sideOffset: 8,
              align: "start",
              alignOffset: -8,
            }}
          />
        </InfoValue>
        {workOrder.detail === "detail" && workOrder.previous_work_order?.id && (
          <>
            <InfoLabel>{i18n.t("tasks:previous_task")}</InfoLabel>
            <InfoValue>
              <Link
                to={`/task/` + workOrder.previous_work_order?.id}
                className="rounded px-2 py-1 font-medium text-gray-500 hover:border-gray-700 hover:bg-gray-100 hover:text-gray-700">
                <span>#{workOrder.previous_work_order.work_order_number}</span>{" "}
              </Link>
            </InfoValue>
          </>
        )}
        {workOrder.detail === "detail" && workOrder.next_work_order?.id && (
          <>
            <InfoLabel>{i18n.t("tasks:next_task")}</InfoLabel>
            <InfoValue>
              <Link
                to={`/task/` + workOrder.next_work_order?.id}
                className="rounded px-2 py-1 font-medium text-gray-500 hover:border-gray-700 hover:bg-gray-100 hover:text-gray-700">
                <span>#{workOrder.next_work_order.work_order_number}</span>
              </Link>
            </InfoValue>
          </>
        )}
        {workOrder.detail === "detail" && workOrder.maintenance && (
          <>
            <InfoLabel>{i18n.t("maintenance:fields.schedule")}</InfoLabel>
            <InfoValue>
              <Link
                to={`/maintenance/` + workOrder.maintenance.id}
                className="rounded px-2 py-1 font-medium text-gray-500 hover:border-gray-700 hover:bg-gray-100 hover:text-gray-700">
                <span>{workOrder.maintenance.name}</span>
              </Link>
            </InfoValue>
          </>
        )}
        {workOrder.detail === "detail" && workOrder.service_request && (
          <>
            <InfoLabel>{i18n.t("common:service_request", { count: 1 })}</InfoLabel>
            <InfoValue>
              <Link
                to={`/service-request/` + workOrder.service_request.id}
                className="rounded px-2 py-1 font-medium text-gray-500 hover:border-gray-700 hover:bg-gray-100 hover:text-gray-700">
                <span>{workOrder.service_request.title}</span>
              </Link>
            </InfoValue>
          </>
        )}
      </>
    )
  }

  return (
    <div
      className="grid place-content-center place-items-start gap-4 pt-1 text-sm"
      style={{
        gridTemplateColumns:
          containerWidth >= 640 && !props.isInSidebar
            ? "auto minmax(0, 1fr) auto minmax(0, 1fr)"
            : "auto minmax(0, 1fr)",
      }}>
      {content}
    </div>
  )
}

const InfoBlockGroupSection = ({ workOrder }: SectionProps) => {
  const edit = useDisclosure()

  const editScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)

  if (workOrder.detail === "data_view") return null

  const isTemplate = workOrder.type === IWorkOrderTypeEnum.Template
  const blockGroup = workOrder.block_groups?.find(
    (g) => g.group_type === IBlockGroupTypeEnum.Info
  )

  if (!isTemplate && !blockGroup) return null

  const elements = (blockGroup?.elements ?? []) as BlockElementFragment[]

  if (!blockGroup?.elements?.length && !edit.isOpen) {
    if (
      editScope.hasScope &&
      (isTemplate || workOrder.status !== IWorkOrderStatusEnum.Done)
    ) {
      return (
        <Button
          icon={Plus}
          size="small"
          type="tertiary"
          className="self-start"
          onClick={edit.onOpen}>
          {i18n.t("common:add_token", { token: i18n.t("common:metadata") })}
        </Button>
      )
    }

    return null
  }

  return (
    <CollapsibleSection
      title={i18n.t("common:metadata")}
      defaultOpen
      action={
        editScope.hasScope &&
        isTemplate && (
          <Button
            type="tertiary"
            icon={!edit.isOpen ? Icons.Procedure : Icons.Close}
            size="small"
            onClick={edit.toggle}>
            {!edit.isOpen
              ? i18n.t("common:customize_token", { token: i18n.t("common:metadata") })
              : i18n.t("common:discard_changes")}
          </Button>
        )
      }>
      {edit.isOpen && (
        <BlockGroupForm
          workOrderId={workOrder.id}
          initialElements={elements}
          groupId={blockGroup?.id}
          assignedAssetIds={workOrder.assets
            .filter((a) => a.asset?.id)
            .map((a) => a.asset.id)}
          groupType={IBlockGroupTypeEnum.Info}
          onSubmit={edit.onClose}
          placeholder={i18n.t("common:metadata_placeholder")}
        />
      )}
      {!edit.isOpen && (
        <BlockGroup
          hideResponseInfo
          elements={elements}
          blockResponseUpdate={isTemplate}
          scope={IPermissionScopeEnum.AppWorkOrderEdit}
          placeholder={i18n.t("common:metadata_empty")}
        />
      )}
    </CollapsibleSection>
  )
}

const ProcedureSection = ({
  workOrder,
  editBlockGroup,
  blockGroupFormHandle,
  discardChanges,
}: SectionProps & {
  editBlockGroup: UseDisclosureReturnType
  blockGroupFormHandle: RefObject<BlockGroupFormHandle>
  discardChanges: () => void
}) => {
  const editScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)

  if (workOrder.detail === "data_view") return null

  const blockGroup = workOrder.block_groups?.find(
    (g) => g.group_type === IBlockGroupTypeEnum.Procedure
  )
  const elements = (blockGroup?.elements ?? []) as BlockElementFragment[]

  if (!blockGroup?.elements?.length && !editBlockGroup.isOpen) {
    if (editScope.hasScope && workOrder.status !== IWorkOrderStatusEnum.Done) {
      return (
        <Button
          type="tertiary"
          icon={Icons.AddProcedure}
          size="small"
          className="self-start"
          onClick={editBlockGroup.onOpen}>
          {i18n.t("common:add_token", { token: i18n.t("tasks:fields.checklist") })}
        </Button>
      )
    } else {
      return null
    }
  }

  const onCopyCode = () => {
    const codes = []

    for (const element of elements) {
      const object = { element_type: element.element_type, config: element.config }
      codes.push(formatObjectToShortcode(object))
    }

    navigator.clipboard.writeText(codes.join("\n"))

    toast.success(i18n.t("tasks:checklist.shortcodes.messages.copy_checklist_success"))
  }

  return (
    <CollapsibleSection
      defaultOpen
      title={i18n.t("tasks:fields.checklist")}
      action={
        editScope.hasScope && (
          <div className="flex items-center">
            <Button
              type="tertiary"
              icon={!editBlockGroup.isOpen ? Icons.Procedure : Icons.Close}
              size="small"
              disabled={workOrder.status === IWorkOrderStatusEnum.Done}
              onClick={() => {
                if (editBlockGroup.isOpen) {
                  discardChanges()
                } else {
                  editBlockGroup.onOpen()
                }
              }}>
              {!editBlockGroup.isOpen
                ? i18n.t("common:customize_token", {
                    token: i18n.t("tasks:fields.checklist"),
                  })
                : i18n.t("common:discard_changes")}
            </Button>

            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button type="tertiary" icon={() => <DotsThreeVertical />} />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem onClick={onCopyCode}>
                  <Copy className="mr-2" />
                  Copy Code
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )
      }>
      {editBlockGroup.isOpen && (
        <BlockGroupForm
          ref={blockGroupFormHandle}
          workOrderId={workOrder.id}
          initialElements={elements}
          groupId={blockGroup?.id}
          assignedAssetIds={workOrder.assets.filter((a) => a.asset).map((a) => a.asset.id)}
          onSubmit={editBlockGroup.onClose}
          isTemplate={workOrder.type === IWorkOrderTypeEnum.Template}
        />
      )}
      {!editBlockGroup.isOpen && (
        <BlockGroup
          elements={elements}
          blockResponseUpdate={
            workOrder.type === IWorkOrderTypeEnum.Template ||
            workOrder.status === IWorkOrderStatusEnum.Done
          }
        />
      )}
    </CollapsibleSection>
  )
}

const SubtaskSection = ({
  workOrder,
  addSubtask,
  subtaskFormHandle,
  discardChanges,
}: SectionProps & {
  addSubtask: UseDisclosureReturnType
  subtaskFormHandle: RefObject<SubtaskFormHandle>
  discardChanges: () => void
}) => {
  const editScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)
  if (!workOrder.subtasks.length) {
    if (!editScope.hasScope) return null
    return (
      <div>
        <Button
          type="tertiary"
          icon={Icons.AddSubTask}
          size="small"
          onClick={addSubtask.onOpen}>
          {i18n.t("common:add_token", { token: i18n.t("common:subtask", { count: 1 }) })}
        </Button>
        {addSubtask.isOpen && (
          <div className="pt-2">
            <SubtaskForm
              ref={subtaskFormHandle}
              parent={workOrder}
              onCancel={discardChanges}
              onSubmit={addSubtask.onClose}
            />
          </div>
        )}
      </div>
    )
  }
  return (
    <div>
      <CollapsibleSection
        defaultOpen
        title={i18n.t("common:subtask", { count: 2 })}
        action={
          editScope.hasScope && (
            <Button
              type="tertiary"
              icon={Icons.AddSubTask}
              disabled={addSubtask.isOpen || workOrder.status === IWorkOrderStatusEnum.Done}
              onClick={addSubtask.onOpen}>
              {i18n.t("common:add_token", {
                token: i18n.t("common:subtask", { count: 1 }),
              })}
            </Button>
          )
        }>
        <SubtaskList workOrder={workOrder} />

        {addSubtask.isOpen && (
          <div className="pt-2">
            <SubtaskForm
              ref={subtaskFormHandle}
              parent={workOrder}
              onCancel={discardChanges}
              onSubmit={addSubtask.onClose}
            />
          </div>
        )}
      </CollapsibleSection>
    </div>
  )
}

type TriggeredByMeterReadingInfoBoxProps = {
  workOrderId: uuid
}

const TriggeredByMeterReadingInfoBox = ({
  workOrderId,
}: TriggeredByMeterReadingInfoBoxProps) => {
  const [maintenanceTriggerTaskRes] = useMaintenanceTriggerTaskQuery({
    variables: { taskId: workOrderId },
    requestPolicy: "cache-and-network",
  })
  const triggerTask = (maintenanceTriggerTaskRes.data?.maintenance_trigger_task ?? [])[0]
  const meterTrigger = triggerTask?.maintenance_trigger
    ?.payload as MeterReadingTriggerOptions["payload"]

  if (!triggerTask) return null

  if (meterTrigger.variant === "range") {
    return (
      <AlertBox type="info">
        <Trans
          i18n={i18n}
          i18nKey="maintenance:messages.task_trigger.meter_range"
          components={{ bold: <span className="font-medium" /> }}
          values={{
            reading: triggerTask.meter_reading_value,
            unit: triggerTask.meter?.unit,
            name: triggerTask.meter?.name,
            min: meterTrigger.min,
            max: meterTrigger.max,
          }}
        />
      </AlertBox>
    )
  }

  return (
    <AlertBox type="info">
      <Trans
        i18n={i18n}
        i18nKey="maintenance:messages.task_trigger.meter_interval"
        components={{ bold: <span className="font-medium" /> }}
        values={{
          reading: triggerTask.meter_reading_value,
          unit: triggerTask.meter?.unit,
          name: triggerTask.meter?.name,
          every: meterTrigger.every,
        }}
      />
    </AlertBox>
  )
}

type WorkOrderDetailProps = {
  workOrderId: uuid
  isTemplate?: boolean
  asPage?: boolean
  onDelete?: () => void
}
const WorkOrderDetail = React.memo((props: WorkOrderDetailProps) => {
  const { workOrderId } = props

  const bag = useWorkOrderDetail({
    workOrderId,
    asPage: props.asPage,
    isTemplate: props.isTemplate,
  })
  const { workOrder, isTemplate, isMaintenance, textValuesBag } = bag

  const editScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderEdit)

  const [editAutoFocus, setEditAutoFocus] = useState<string | undefined>(undefined)
  const editCompletedAt = useDisclosure({ onClose: () => setEditAutoFocus(undefined) })

  const editBlockGroup = useDisclosure()
  const blockGroupFormHandle = useRef<BlockGroupFormHandle>(null)

  const navigate = useNavigate()
  const isPrinting = useDetectPrint()

  const containerRef = useRef<HTMLDivElement>(null)

  const size = useElementSize(containerRef.current)
  const showSidebar = isPrinting ? false : (size?.width ?? 0) >= 1024

  const addSubtask = useDisclosure()
  const subtaskFormHandle = useRef<SubtaskFormHandle>(null)
  const hasMaterialsFeature = useFeature("materials")

  const confirmDiscardBlockGroupEdit = useConfirmModal({
    title: i18n.t("common:save_token", { token: i18n.t("tasks:fields.checklist") }),
    content: i18n.t("common:messages.unsaved_changes_confirmation"),
    okText: i18n.t("common:save"),
    cancelText: i18n.t("common:discard_changes"),
    onOk: async (afterClose?: () => void) => {
      const isValid = await blockGroupFormHandle.current?.submit()
      if (isValid) {
        posthog?.capture("edit_work_order")
        editBlockGroup.onClose()
        afterClose?.()
      }
    },
    okButtonProps: { autoFocus: true },
    onCancel: (afterClose?: () => void) => {
      editBlockGroup.onClose()
      afterClose?.()
    },
  })

  const confirmDiscardSubtaskAdd = useConfirmModal({
    title: i18n.t("common:save_token", { token: i18n.t("common:subtask", { count: 1 }) }),
    content: i18n.t("common:messages.unsaved_changes_confirmation"),
    okText: i18n.t("common:save"),
    cancelText: i18n.t("common:discard_changes"),
    onOk: async (afterClose?: () => void) => {
      await subtaskFormHandle.current?.submitForm()
      if (subtaskFormHandle.current?.isValid) {
        posthog?.capture("edit_work_order")
        addSubtask.onClose()
        afterClose?.()
      }
    },
    okButtonProps: { autoFocus: true },
    onCancel: (afterClose?: () => void) => {
      addSubtask.onClose()
      subtaskFormHandle.current?.resetForm()
      afterClose?.()
    },
  })
  const discardSubtaskAdd = (afterClose?: () => void) => {
    if (subtaskFormHandle.current?.dirty) {
      confirmDiscardSubtaskAdd.show(afterClose)
    } else {
      addSubtask.onClose()
      afterClose?.()
    }
  }

  const showLoadingSpinner =
    !workOrder &&
    // Since we have a local resolver the workOrderQueryRes is just marked stale and not as fetching
    (bag.workOrderQueryRes.fetching || bag.workOrderQueryRes.stale)

  if (showLoadingSpinner) {
    return <LoadingSpinner />
  }

  if (!workOrder) {
    return (
      <div className="absolute inset-0 flex items-center justify-center text-gray-600">
        <div className="flex flex-col items-center space-y-3 text-gray-600">
          <span>{i18n.t("tasks:messages.not_found")}</span>

          <Button
            type="primary"
            onClick={() => {
              navigate("/")
            }}>
            {i18n.t("navigation:error.back_to_homepage")}
          </Button>
        </div>
      </div>
    )
  }

  const pageContent = (
    <div className="mx-auto w-full max-w-screen-md !shrink p-3 pr-5">
      <div className="flex min-w-0 items-center justify-between bg-white pb-2">
        <span className="flex items-center text-sm text-gray-800">
          <span className="mr-1">
            {isMaintenance
              ? i18n.t("tasks:types.maintenance")
              : translateEntryTypes(workOrder.type)}
          </span>
          <span
            className="cursor-pointer select-auto font-medium"
            onClick={() => {
              const taskIdentifier = `#${workOrder.work_order_number} - ${workOrder.name}`
              window.navigator.clipboard.writeText(taskIdentifier)
              toast.success(i18n.t("tasks:messages.copy_task_identifier"))
            }}>{`#${workOrder.work_order_number}`}</span>
          {workOrder.only_assigned && (
            <Tooltip content={i18n.t("tasks:messages.only_assigned")}>
              <Icons.Lock className="ml-1 h-4 w-4 text-gray-400" />
            </Tooltip>
          )}
        </span>
        <Options
          onEdit={bag.onEdit}
          workOrder={workOrder}
          onDelete={props.onDelete}
          isEditing={bag.textValuesEdit.isOpen}
          onCopyWorkOrder={bag.onCopyWorkOrder}
          onSetParentTask={bag.parentTask.action}
          onCreateShareLink={bag.onCreateShareLink}
          onSetSubtaskToTask={bag.onSetSubtaskToTask}
        />
      </div>
      {workOrder.parent_task?.id && (
        <Link
          to={`/task/` + workOrder.parent_task.id}
          className="group mb-2 flex min-w-0 cursor-pointer items-center space-x-2 rounded border border-gray-300 px-2 py-1 text-sm text-gray-500 hover:border-gray-700 hover:text-gray-700">
          <WorkOrderStatusTag status={workOrder.parent_task.status} short />
          <span>#{workOrder.parent_task.work_order_number}</span>{" "}
          <span className="truncate font-medium text-gray-700 hover:text-gray-900">
            {workOrder.parent_task.name}
          </span>
          <Icons.RightNext className="inline h-[1em] w-[1em] group-hover:text-gray-700" />
        </Link>
      )}

      {isMaintenance && <TriggeredByMeterReadingInfoBox workOrderId={workOrderId} />}

      <VStack space={8} className="mt-4">
        <FormikProvider value={textValuesBag}>
          <Form>
            {bag.textValuesEdit.isOpen ? (
              <FormField name="name" hasErrorPlaceholder={false}>
                <TextInput
                  className="flex h-7 w-full flex-1 select-text items-center py-1 text-xl font-semibold"
                  placeholder={i18n.t("tasks:fields.title_placeholder")}
                />
              </FormField>
            ) : workOrder.name ? (
              <h2 className="flex select-text items-center text-xl font-semibold">
                {workOrder.name}
              </h2>
            ) : (
              <button
                type="button"
                className="text-xl font-semibold text-gray-800"
                onClick={editScope.hasScope ? bag.onEdit : undefined}>
                {i18n.t("tasks:fields.title_placeholder")}
              </button>
            )}

            {/* Description + Documents */}
            <div className="relative mt-2 select-text">
              {bag.textValuesEdit.isOpen ? (
                <RichTextFormField
                  noStyle
                  showToolbar
                  hasErrorPlaceholder={false}
                  name="description"
                  className="!shrink-0 !text-base text-gray-900"
                  placeholder={i18n.t("tasks:fields.description_placeholder")}
                  editorProps={{
                    attributes: {
                      class: "min-h-[2rem] focus-visible:outline-none ",
                    },
                  }}
                />
              ) : workOrder.description ? (
                <StaticRichText
                  content={workOrder.description}
                  className="prose mb-2 text-base text-gray-900"
                  style={{ wordBreak: "break-word" }}
                />
              ) : (
                <button
                  type="button"
                  className="mb-2 cursor-text text-gray-500"
                  onClick={editScope.hasScope ? bag.onEdit : undefined}>
                  {i18n.t("tasks:fields.description_placeholder")}
                </button>
              )}
            </div>
          </Form>
        </FormikProvider>

        {bag.textValuesEdit.isOpen && (
          <HStack
            space={12}
            inset={{ y: 12, x: 4 }}
            justify="flex-end"
            className="!sticky bottom-0 -m-1.5 bg-white">
            <Button
              type="tertiary"
              onClick={() => {
                textValuesBag.resetForm()
                bag.textValuesEdit.onClose()
              }}>
              {i18n.t("common:cancel")}
            </Button>
            <Button
              onClick={() => {
                bag.textValuesBag.submitForm()
                bag.textValuesEdit.onClose()
              }}>
              {i18n.t("common:save")}
            </Button>
          </HStack>
        )}

        <div className="mt-4">
          <WorkOrderDetailDocuments
            workOrderId={workOrder.id}
            allowQuickDeleteOfPictures={bag.textValuesEdit.isOpen}
            documents={workOrder.detail === "detail" ? workOrder.documents : []}
          />
        </div>

        {/* Object Cards */}
        <WorkOrderDetailObjectSection workOrder={workOrder} />

        {/* Info section (small width) */}
        {!showSidebar && (
          <CollapsibleSection
            action={null}
            title={i18n.t("common:information")}
            defaultOpen>
            <div className="min-w-0 pl-6">
              <InfoSection
                workOrder={workOrder}
                editCompletedAt={editCompletedAt}
                setEditAutoFocus={setEditAutoFocus}
                containerWidth={size?.width}
              />
            </div>
          </CollapsibleSection>
        )}

        {/* Additional Information */}
        <InfoBlockGroupSection workOrder={workOrder} />

        {workOrder.detail === "data_view" ? (
          <div className="relative h-32">
            <LoadingSpinner />
          </div>
        ) : (
          <React.Fragment key={workOrder.id}>
            {/* Subtasks */}
            {!isTemplate && (
              <SubtaskSection
                addSubtask={addSubtask}
                discardChanges={discardSubtaskAdd}
                subtaskFormHandle={subtaskFormHandle}
                workOrder={workOrder}
              />
            )}

            {/* {workOrder.detail == "detail" && (
          <BlockGroupForm
            ref={blockGroupFormHandle}
            workOrderId={workOrder.id}
            initialElements={
              workOrder.block_groups?.find(
                (g) => g.group_type === IBlockGroupTypeEnum.Procedure
              )?.elements ?? []
            }
            groupId={
              workOrder.block_groups?.find(
                (g) => g.group_type === IBlockGroupTypeEnum.Procedure
              )?.id
            }
            assignedAssetIds={workOrder.assets
              .filter((a) => a.asset)
              .map((a) => a.asset.id)}
            onSubmit={editBlockGroup.onClose}
            isTemplate={workOrder.type === IWorkOrderTypeEnum.Template}
          />
        )} */}

            {/* Procedure */}
            <ProcedureSection
              key={workOrder.id}
              editBlockGroup={bag.editBlockGroup}
              discardChanges={bag.discardBlockGroupChanges}
              blockGroupFormHandle={bag.blockGroupFormHandle}
              workOrder={workOrder}
            />

            {!isTemplate && hasMaterialsFeature && (
              <NewConsumableSection workOrder={workOrder} />
            )}

            {!isTemplate && (
              <div>
                <SectionHeader className="mb-3">
                  {i18n.t("common:activity", { count: 2 })}
                </SectionHeader>
                <WorkOrderActivitySection workOrder={workOrder} order="oldest-first" />
              </div>
            )}
          </React.Fragment>
        )}

        <div className="flex-1" />
      </VStack>
    </div>
  )

  return (
    <WorkOrderIsAssignedProvider workOrder={workOrder}>
      <div className="flex min-h-0 min-w-0 flex-1 flex-col" ref={containerRef}>
        <div className="flex min-h-0 min-w-0 flex-1">
          {isPrinting ? (
            pageContent
          ) : (
            <ScrollArea
              vertical
              type="auto"
              key="scrollarea"
              style={{ flexBasis: 760, flexGrow: 2 }}
              className="min-w-0 print:contents print:!overflow-visible"
              viewportAsChild>
              {pageContent}
            </ScrollArea>
          )}

          {showSidebar && (
            <div
              className={"flex min-h-0 min-w-0 grow flex-col border-l border-gray-200"}
              style={{ flexBasis: 356 }}>
              <ScrollArea vertical>
                <div className="px-8 py-4">
                  <div className="sticky top-0 bg-white pb-6 font-semibold">
                    {i18n.t("common:information")}
                  </div>
                  <InfoSection
                    isInSidebar
                    workOrder={workOrder}
                    editCompletedAt={editCompletedAt}
                    setEditAutoFocus={setEditAutoFocus}
                    containerWidth={size?.width}
                  />
                </div>
              </ScrollArea>
            </div>
          )}
        </div>
      </div>

      {workOrder.detail === "detail" && (
        <>
          {workOrder.completed_at && workOrder.completed_by?.id && (
            <WorkOrderCompletedEditForm
              isOpen={editCompletedAt.isOpen}
              onOpenChange={editCompletedAt.changeOpen}
              workOrderId={workOrder.id}
              completedAt={workOrder.completed_at}
              completedById={workOrder.completed_by?.id}
              autoFocus={editAutoFocus}
            />
          )}
        </>
      )}

      {confirmDiscardBlockGroupEdit.component}
      {confirmDiscardSubtaskAdd.component}
      {bag.confirmDiscardBlockGroupEdit.component}
      {bag.parentTask.component}
    </WorkOrderIsAssignedProvider>
  )
})

export default WorkOrderDetail
