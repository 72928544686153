// @ts-nocheck
import * as Types from '@elara/db';

import { uuid } from '@elara/db'
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type INotificationFragment = { __typename: 'notification', id: uuid, payload: {}, read_at: string | null, seen_at: string | null, created_at: string, type: Types.INotificationTypeEnum, work_order_id: uuid | null };

export type INotificationsSubscriptionResultFragment = { __typename: 'notification', id: uuid, payload: {}, read_at: string | null, seen_at: string | null, created_at: string, type: Types.INotificationTypeEnum, work_order_id: uuid | null };

export type INotificationsSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type INotificationsSubscription = { notification: Array<{ __typename: 'notification', id: uuid, payload: {}, read_at: string | null, seen_at: string | null, created_at: string, type: Types.INotificationTypeEnum, work_order_id: uuid | null }> };

export type IAllNotificationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IAllNotificationsQuery = { notification: Array<{ __typename: 'notification', id: uuid, payload: {}, read_at: string | null, seen_at: string | null, created_at: string, type: Types.INotificationTypeEnum, work_order_id: uuid | null }> };

export type IMarkNotificationAsReadMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['uuid']['input']> | Types.Scalars['uuid']['input'];
  now: Types.Scalars['timestamptz']['input'];
}>;


export type IMarkNotificationAsReadMutation = { update_notification: { __typename: 'notification_mutation_response', returning: Array<{ __typename: 'notification', id: uuid, read_at: string | null }> } | null };

export type IMarkNotificationAsUnreadMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['uuid']['input']> | Types.Scalars['uuid']['input'];
}>;


export type IMarkNotificationAsUnreadMutation = { update_notification: { __typename: 'notification_mutation_response', returning: Array<{ __typename: 'notification', id: uuid, read_at: string | null }> } | null };

export type IMarkAllNotificationAsReadMutationVariables = Types.Exact<{
  now: Types.Scalars['timestamptz']['input'];
}>;


export type IMarkAllNotificationAsReadMutation = { update_notification: { __typename: 'notification_mutation_response', returning: Array<{ __typename: 'notification', id: uuid, read_at: string | null }> } | null };

export type IMarkAllNotificationAsSeenMutationVariables = Types.Exact<{
  now: Types.Scalars['timestamptz']['input'];
}>;


export type IMarkAllNotificationAsSeenMutation = { update_notification: { __typename: 'notification_mutation_response', returning: Array<{ __typename: 'notification', id: uuid, seen_at: string | null }> } | null };

export type IDeleteNotificationMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type IDeleteNotificationMutation = { update_notification_by_pk: { __typename: 'notification', id: uuid } | null };

export type IDeleteReadNotificationsMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type IDeleteReadNotificationsMutation = { update_notification: { __typename: 'notification_mutation_response', returning: Array<{ __typename: 'notification', id: uuid }> } | null };

export const NotificationFragmentDoc = gql`
    fragment Notification on notification {
  id
  payload
  read_at
  seen_at
  created_at
  type
  work_order_id
}
    `;
export const NotificationsSubscriptionResultFragmentDoc = gql`
    fragment NotificationsSubscriptionResult on notification {
  ...Notification
}
    ${NotificationFragmentDoc}`;
export const NotificationsDocument = gql`
    subscription Notifications {
  notification(
    where: {type: {_neq: user_added_to_a_private_team}}
    order_by: {created_at: desc}
    limit: 1
  ) {
    ...Notification
  }
}
    ${NotificationFragmentDoc}`;

export function useNotificationsSubscription<TData = INotificationsSubscription>(options: Omit<Urql.UseSubscriptionArgs<INotificationsSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<INotificationsSubscription, TData>) {
  return Urql.useSubscription<INotificationsSubscription, TData, INotificationsSubscriptionVariables>({ query: NotificationsDocument, ...options }, handler);
};
export const AllNotificationsDocument = gql`
    query AllNotifications {
  notification(
    where: {type: {_neq: user_added_to_a_private_team}}
    order_by: {created_at: desc}
    limit: 300
  ) {
    ...Notification
  }
}
    ${NotificationFragmentDoc}`;

export function useAllNotificationsQuery(options?: Omit<Urql.UseQueryArgs<IAllNotificationsQueryVariables>, 'query'>) {
  return Urql.useQuery<IAllNotificationsQuery, IAllNotificationsQueryVariables>({ query: AllNotificationsDocument, ...options });
};
export const MarkNotificationAsReadDocument = gql`
    mutation MarkNotificationAsRead($ids: [uuid!]!, $now: timestamptz!) {
  update_notification(where: {id: {_in: $ids}}, _set: {read_at: $now}) {
    returning {
      id
      read_at
    }
  }
}
    `;

export function useMarkNotificationAsReadMutation() {
  return Urql.useMutation<IMarkNotificationAsReadMutation, IMarkNotificationAsReadMutationVariables>(MarkNotificationAsReadDocument);
};
export const MarkNotificationAsUnreadDocument = gql`
    mutation MarkNotificationAsUnread($ids: [uuid!]!) {
  update_notification(where: {id: {_in: $ids}}, _set: {read_at: null}) {
    returning {
      id
      read_at
    }
  }
}
    `;

export function useMarkNotificationAsUnreadMutation() {
  return Urql.useMutation<IMarkNotificationAsUnreadMutation, IMarkNotificationAsUnreadMutationVariables>(MarkNotificationAsUnreadDocument);
};
export const MarkAllNotificationAsReadDocument = gql`
    mutation MarkAllNotificationAsRead($now: timestamptz!) {
  update_notification(where: {read_at: {_is_null: true}}, _set: {read_at: $now}) {
    returning {
      id
      read_at
    }
  }
}
    `;

export function useMarkAllNotificationAsReadMutation() {
  return Urql.useMutation<IMarkAllNotificationAsReadMutation, IMarkAllNotificationAsReadMutationVariables>(MarkAllNotificationAsReadDocument);
};
export const MarkAllNotificationAsSeenDocument = gql`
    mutation MarkAllNotificationAsSeen($now: timestamptz!) {
  update_notification(_set: {seen_at: $now}, where: {}) {
    returning {
      id
      seen_at
    }
  }
}
    `;

export function useMarkAllNotificationAsSeenMutation() {
  return Urql.useMutation<IMarkAllNotificationAsSeenMutation, IMarkAllNotificationAsSeenMutationVariables>(MarkAllNotificationAsSeenDocument);
};
export const DeleteNotificationDocument = gql`
    mutation DeleteNotification($id: uuid!) {
  update_notification_by_pk(pk_columns: {id: $id}, _set: {deleted_at: "now()"}) {
    id
  }
}
    `;

export function useDeleteNotificationMutation() {
  return Urql.useMutation<IDeleteNotificationMutation, IDeleteNotificationMutationVariables>(DeleteNotificationDocument);
};
export const DeleteReadNotificationsDocument = gql`
    mutation DeleteReadNotifications {
  update_notification(
    where: {read_at: {_is_null: false}}
    _set: {deleted_at: "now()"}
  ) {
    returning {
      id
    }
  }
}
    `;

export function useDeleteReadNotificationsMutation() {
  return Urql.useMutation<IDeleteReadNotificationsMutation, IDeleteReadNotificationsMutationVariables>(DeleteReadNotificationsDocument);
};