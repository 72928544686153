import { IAssetStateVariant, IAssetStateVariantTypeEnum } from "@elara/db"
import i18n from "@i18n"
import { cn } from "@utils"
import { PropsWithChildren } from "react"

type Variant = Pick<IAssetStateVariant, "subtype" | "type" | "planned">

export function textForAssetStateVariantLabel(variant: Variant) {
  const txt =
    i18n.t(`assets:state.variant.${variant.type}`) +
    (variant.subtype ? ` &bull; ${variant.subtype}` : "")
  if (variant.type === IAssetStateVariantTypeEnum.Offline) {
    return (
      txt +
      ` - ${
        variant.planned
          ? i18n.t("assets:state.variant.planned")
          : i18n.t("assets:state.variant.unplanned")
      }`
    )
  }
  return txt
}

const typeValue = (v: IAssetStateVariantTypeEnum) => {
  if (v === IAssetStateVariantTypeEnum.Offline) return -3
  if (v === IAssetStateVariantTypeEnum.Online) return -2
  if (v === IAssetStateVariantTypeEnum.DoNotTrack) return -1
  return 0
}

export function compareAssetStateVariants(a: Variant, b: Variant) {
  const aVal = typeValue(a.type)
  const bVal = typeValue(b.type)
  if (aVal !== bVal) return aVal - bVal
  const comp = a.subtype.localeCompare(b.subtype)
  if (comp !== 0) return comp
  return a.planned ? 1 : -1
}

export function AssetStateVariantLabel(
  props: PropsWithChildren<{
    variant: Pick<IAssetStateVariant, "subtype" | "type" | "planned">
    className?: string
    size?: "small" | "default"
  }>
) {
  const { variant: v, size = "default" } = props
  return (
    <span
      className={cn(
        "px-2 py-1 rounded-lg text-sm font-medium isolate ring-1 inline-flex min-w-0 whitespace-nowrap",
        {
          "bg-green-100 text-green-800 ring-green-100": v.type === "online",
          "bg-gray-100 text-gray-800 ring-gray-100": v.type === "do_not_track",
          "bg-red-100 text-red-800 ring-red-100": v.type === "offline",
        },
        size === "small" && "text-xs px-1.5 py-0.5",
        props.className
      )}>
      <span className="truncate">{i18n.t(`assets:state.variant.${v.type}`)}</span>
      {v.subtype && <span className="ml-1 truncate"> &bull; {` ${v.subtype}`}</span>}
      {v.type === IAssetStateVariantTypeEnum.Offline && (
        <span className="ml-3 truncate text-red-800 opacity-60">
          {v.planned
            ? i18n.t("assets:state.variant.planned")
            : i18n.t("assets:state.variant.unplanned")}
        </span>
      )}
      {props.children}
    </span>
  )
}

export default AssetStateVariantLabel
