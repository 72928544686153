import classNames from "classnames"
import React, { PropsWithChildren } from "react"

type SVGWidthOrHeight = React.SVGProps<SVGElement>["width"]

export type EmptyStateProps = {
  icon?: React.FunctionComponent<{
    width?: SVGWidthOrHeight
    height?: SVGWidthOrHeight
    style?: React.CSSProperties
  }>
  message?: string | React.ReactNode
  title?: string | React.ReactNode
  actions?: React.ReactNode
  maxWidth?: number
}

export const EmptyState = (props: PropsWithChildren<EmptyStateProps>) => (
  <div
    className="flex flex-col items-center justify-center space-y-3 px-3"
    style={{ maxWidth: props.maxWidth ?? 500 }}>
    {props.icon && (
      <div className="flex h-8 w-8 items-center justify-center rounded-[50%] text-4xl text-gray-400">
        {React.createElement(props.icon, {
          width: 36,
          height: 36,
        })}
      </div>
    )}
    {props.title && (
      <h2
        className={classNames("font-medium text-gray-900 text-center leading-relaxed", {
          "self-center": !!(props.children || props.message),
        })}>
        {props.title}
      </h2>
    )}
    <div className="flex-1 self-center text-center text-sm text-gray-500">
      {props.children ?? props.message}
    </div>
    <div className="!mt-6">{props.actions}</div>
  </div>
)

export default EmptyState
