import React, { useInsertionEffect } from "react"

export function useMemoRef<T>(cb: T) {
  const ref = React.useRef(cb)
  useInsertionEffect(() => {
    ref.current = cb
  })

  return ref
}

export default useMemoRef
