import ScrollArea from "@components/shared/scroll-area"
import { useLocationTimeZone } from "@contexts/user-context"
import { Data, Where } from "@elara/select"
import { EventContentArg, EventInput, EventSourceFuncArg } from "@fullcalendar/core"
import deLocale from "@fullcalendar/core/locales/de"
import enLocale from "@fullcalendar/core/locales/en-au"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import listPlugin from "@fullcalendar/list"
import luxon2Plugin from "@fullcalendar/luxon2"
import multiMonthPlugin from "@fullcalendar/multimonth"
import FullCalendar from "@fullcalendar/react"
import rrulePlugin from "@fullcalendar/rrule"
import i18n from "@i18n"
import { cn } from "@utils"
import { useCallback, useRef } from "react"
import { unstable_serialize } from "swr"
import { Client, useClient } from "urql"

import { useDataViewConfigContext } from "./data-view-config"
import { useDataViewRefreshData, useDataViewWhere } from "./lib/hooks"

export function DataViewCalendar<D extends Data>(props: {
  eventItem: (info: EventContentArg) => React.ReactNode
  fetchEvents: (
    info: EventSourceFuncArg,
    client: Client,
    where: Where<D>
  ) => Promise<EventInput[]>
}) {
  const tz = useLocationTimeZone()
  const where = useDataViewWhere()
  const ctx = useDataViewConfigContext()
  const calendarConfig = ctx.config.calendarConfig || {}

  const client = useClient()

  const events = useCallback(
    (info: EventSourceFuncArg) => props.fetchEvents(info, client, where as Where<D>),
    [unstable_serialize(where), props.fetchEvents]
  )
  const currentViewType = calendarConfig?.viewType ?? "dayGridMonth"

  const fullCalendarRef = useRef<FullCalendar>(null)
  useDataViewRefreshData(() => {
    fullCalendarRef.current?.getApi().refetchEvents()
  })

  return (
    <div className="dataview flex min-h-0 flex-1 flex-col">
      <ScrollArea vertical className="px-3">
        <FullCalendar
          ref={fullCalendarRef}
          firstDay={1}
          events={events}
          viewClassNames="pb-3 overflow-auto"
          headerToolbar={{
            start: "prev,next today",
            center: "title",
            right: "dayGridMonth,dayGridWeek,listMonth",
          }}
          locale={i18n.language}
          eventInteractive={false}
          initialView={currentViewType}
          datesSet={(info) => {
            if (ctx.config.calendarConfig?.viewType !== info.view.type) {
              ctx.actions.updateCalendar({
                ...ctx.config.calendarConfig,
                viewType: info.view.type,
              })
            }
          }}
          eventStartEditable={true}
          locales={[deLocale, enLocale]}
          // height={`calc(100vh)`}
          defaultTimedEventDuration={{ second: 1 }}
          weekends={calendarConfig?.showWeekends ?? false}
          dayMaxEvents={
            ["dayGridMonth", "multiMonthYear"].includes(currentViewType) ? 3 : undefined
          }
          timeZone={tz}
          plugins={[
            luxon2Plugin,
            interactionPlugin,
            dayGridPlugin,
            listPlugin,
            rrulePlugin,
            multiMonthPlugin,
          ]}
          // dayCellContent={(info) => (
          //   <span className="text-sm font-medium text-gray-700">{info.dayNumberText}</span>
          // )}
          dayCellClassNames={cn({
            // "cursor-cell": calendarConfig.type === "dayGrid" && props.onDateClick,
          })}
          weekNumbers={
            calendarConfig?.type === "dayGrid" && calendarConfig?.range === "Month"
          }
          weekNumberContent={(info) =>
            i18n.t("calendar:relative_dates.week_number", { week: info.num })
          }
          // eventDrop={(info) => {
          //   const id = info.event.id
          //   const key = props.columnDef.key
          //   const newDate = info.event.start
          //   const item = props.dataView.treeList.find((item) => item.id === id)

          //   if (key && item && newDate && props.onDateDragDrop) {
          //     props.onDateDragDrop(key, item, newDate)
          //   }
          // }}
          // dateClick={(info) => {
          //   if (!props.columnDef.key) return null

          //   props.onDateClick?.(props.columnDef.key, info.date)
          // }}
          eventContent={(info) => {
            return props.eventItem(info)
          }}
        />
      </ScrollArea>
    </div>
  )
}
