import { Data, Where } from "@elara/select"
import useSWR from "swr"
import { Client, useClient } from "urql"

import { useDataViewConfigContext } from "./data-view-config"
import { useDataViewRefreshData, useDataViewWhere } from "./lib/hooks"

const DataViewTableCellAggregation = <D extends Data>(props: {
  id: string
  where: Where<D>
  getAggregatedValue: (client: Client, where: Where<D>) => Promise<string>
}) => {
  const client = useClient()

  const res = useSWR(["data-view", "aggregate", props.id, props.where], () => {
    return props.getAggregatedValue(client, props.where)
  })

  useDataViewRefreshData(() => res.mutate())

  return <span>{res?.data ?? null}</span>
}

export const DataViewTableFooter = () => {
  const ctx = useDataViewConfigContext()
  const where = useDataViewWhere()
  // const hasBatchSelectFeature = useFeature("batch_select")

  // if (!ctx.config.showSummation) return null

  return (
    <div className="sticky bottom-0 z-[1] flex h-10 ">
      {ctx.renderedColumns.map((c, index) => {
        return (
          <div
            key={c.id}
            className="relative flex shrink-0 cursor-pointer items-center overflow-hidden border-y border-gray-100 bg-gray-50 p-2 py-1.5 text-left text-sm font-medium first:rounded-tl first:border-l-0 last:rounded-tr"
            style={{
              width: `var(--col-${index}-width)`,
              flexGrow: index === ctx.renderedColumns.length - 1 ? 1 : 0,
              // borderLeftWidth: c.sumonValuemati ? 1 : 0,
            }}>
            {c.aggregate && (
              <DataViewTableCellAggregation<Data>
                getAggregatedValue={c.aggregate}
                id={`${ctx.id}:${c.id}`}
                where={where}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}
