import { Center } from "@components/layout"
import SettingsPageLayout from "@components/settings/page-layout"
import { LoadingIndicator } from "@components/shared"
import toast from "@components/shared/toast"
import { usePage } from "@contexts/page-context"
import { useOrderBy } from "@elara/select"
import { useWorkOrderCategoriesQuery } from "@graphql/documents/work-order.generated"
import { IPermissionScopeEnum, usePermissionScope } from "@hooks"
import i18n from "@i18n"
import { categoryColors, colors } from "@styles"
import { useState } from "react"

import { useCategoryEdit } from "./work-order-categories/category-action-menu"
import CategoryCard from "./work-order-categories/category-card"
import CategoryEditCreateForm from "./work-order-categories/category-edit-create-form"
import { transformColors } from "./work-order-categories/color-picker"

const SettingsWorkOrderCategories = () => {
  usePage({
    isSubPage: true,
    id: "settings/categories",
    title: i18n.t("tasks:fields.category", { count: 2 }),
  })
  const createCategoryScope = usePermissionScope(IPermissionScopeEnum.AppWorkOrderCreate)

  const [queryRes] = useWorkOrderCategoriesQuery({ requestPolicy: "cache-first" })
  const [data] = useOrderBy(queryRes.data?.work_order_category ?? [], { label: "asc" })

  const [color, setColor] = useState<string>(colors.dark)
  const createCategory = useCategoryEdit("")
  const [editing, setEditing] = useState<boolean>(false)
  const [creating, setCreating] = useState<boolean>(false)

  if (queryRes.fetching) {
    return (
      <Center flex="1">
        <LoadingIndicator size={24} />
      </Center>
    )
  }

  return (
    <SettingsPageLayout
      title={i18n.t("tasks:fields.category", { count: 2 })}
      description={i18n.t("settings:categories.description")}
      buttonProps={{
        disabled: !createCategoryScope.hasScope || editing,
        disabledReason: i18n.t("common:messages.lack_of_permissions"),
        onClick: () => {
          createCategory.startCreate()
          setCreating(true)
          setEditing(false)
        },
      }}
      buttonText={i18n.t("common:new_token", {
        context: "female",
        token: i18n.t("tasks:fields.category", { count: 1 }),
      })}>
      {creating && !editing && (
        <div className="mb-2 flex cursor-pointer items-center truncate rounded border border-gray-100 bg-gray-50 p-2 text-sm sm:px-4">
          <CategoryEditCreateForm
            colors={transformColors(categoryColors)}
            currentColor={color}
            onChangeColor={(color) => setColor(color)}
            label={createCategory.editedLabel}
            onChange={createCategory.onChange}
            inputRef={createCategory.inputRef}
            stopEditOrCreate={() => {
              setCreating(false)
              createCategory.stopCreate()
            }}
            onEditOrCreateCategory={() => {
              if (creating && createCategory.editedLabel.length === 0) {
                toast.warning(
                  i18n.t(
                    i18n.t("common:forms.is_required", {
                      field: i18n.t("tasks:fields.category", { count: 1 }),
                    })
                  )
                )
                setCreating(true)
                return
              }
              createCategory.onEditOrCreateCategory(color)
              setCreating(false)
            }}
          />
        </div>
      )}

      <ul role="list" className="list-none">
        {data?.map((category) => (
          <li key={category.id} className="list-none">
            <CategoryCard
              category={category}
              onEditMode={(editing) => {
                setEditing(editing)
                setCreating(editing)
              }}
            />
          </li>
        ))}
      </ul>
    </SettingsPageLayout>
  )
}

export default SettingsWorkOrderCategories
